// @ts-nocheck
import _ from 'lodash';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import React from 'react';
import { cx } from '@/util';
const CIRCLE_DIAMETER = 8;
const MARGIN_BETWEEN_CIRCLES = 16;

const WIDTH_PER_ITEM = CIRCLE_DIAMETER + 2 * MARGIN_BETWEEN_CIRCLES;

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'progressBar',
  mixins: [PureRenderMixin],
  propTypes: {
    current: PropTypes.number,
    total: PropTypes.number,
    onStepClick: PropTypes.func,
  },
  getDefaultProps() {
    return {
      current: 0,
      total: 0,
      onStepClick: _.noop,
    };
  },
  getInitialState() {
    return {
      steps: [],
      visitedSteps: {},
    };
  },
  getStyle() {
    return {
      minWidth: this.props.total * WIDTH_PER_ITEM,
    };
  },
  onStepClick(step) {
    if (this.state.visitedSteps[step.index]) {
      this.props.onStepClick(step.index);
    }
  },
  buildSteps(props) {
    const steps = [];
    for (let index = 0; index < props.total; index++) {
      steps.push({
        index,
        isVisited: this.state.visitedSteps[index],
        isCurrent: index === props.current,
      });
    }
    this.setState({ steps });
  },
  componentDidMount() {
    this.buildSteps(this.props);
  },
  visitStep(index) {
    const visitedSteps = _.clone(this.state.visitedSteps);
    visitedSteps[index] = true;
    this.setState({ visitedSteps });
  },
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.buildSteps(nextProps);
    this.visitStep(nextProps.current);
  },
  render() {
    return (
      <div style={this.getStyle()} className="progress-bar">
        {/* TODO: Fix this the next time the file is edited. */}
        {/* eslint-disable-next-line you-dont-need-lodash-underscore/map */}
        {_.map(this.state.steps, (step) => (
          <span
            key={step.index}
            onClick={() => {
              {
                this.onStepClick(step);
              }
            }}
            className={cx(
              {
                'progress-bar-step': true,
                visited: step.isVisited,
                current: step.isCurrent,
              },
              'progress-bar-step',
            )}
          >
            <div className="circle" />
          </span>
        ))}
      </div>
    );
  },
});
