import { translate } from '@/i18n';
import * as coreBi from '@/coreBi';
import constants from '@/constants';
import PropTypes from 'prop-types';
import React from 'react';
import { Composites, RichText, SectionDivider } from '@wix/wix-base-ui';

const { MAX_STATIC_PAGES_ALLOWED, WARNING_COUNTER_NUMBER_OF_STATIC_PAGES } =
  constants.STATIC_PAGES;

//TYPE WAS GENERATED, remove this line when reviewed
interface Props {
  sendBi: FunctionFixMe;
  openPanel: FunctionFixMe;
  staticPagesCount: number;
  notifyDataBindingAppTooManyPages: FunctionFixMe;
}

export default class extends React.Component<Props> {
  static displayName = 'TooManyPagesBanner';

  static propTypes = {
    sendBi: PropTypes.func.isRequired,
    openPanel: PropTypes.func.isRequired,
    staticPagesCount: PropTypes.number.isRequired,
    notifyDataBindingAppTooManyPages: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.sendBi(
      coreBi.events.pages.pagesPanel.too_many_pages_banner_shown,
      {
        pages_count: this.props.staticPagesCount,
      },
    );
  }

  handleTooManyPagesSuggestionClick = () => {
    this.props.openPanel(
      'wixData.dynamicPagesStartingPanel',
      {
        staticPagesCount: this.props.staticPagesCount,
        notifyDataBindingAppTooManyPages:
          this.props.notifyDataBindingAppTooManyPages,
        origin: `pages_panel_banner_#${this.props.staticPagesCount}`,
      },
      true,
    );
    this.props.sendBi(
      coreBi.events.pages.pagesPanel.too_many_pages_info_click,
      {
        origin: 'pages_panel',
        pages_count: this.props.staticPagesCount,
      },
    );
  };

  getLearnMoreText = () => {
    if (this.props.staticPagesCount >= MAX_STATIC_PAGES_ALLOWED) {
      return 'Pages_Menu_Heavy_Sites_Info_Link';
    }
    return 'Pages_Menu_Heavy_Sites_Checkpoint1_Info_Link';
  };

  getLabel = () => {
    if (this.props.staticPagesCount >= MAX_STATIC_PAGES_ALLOWED) {
      return translate('Pages_Menu_Heavy_Sites_Info_Text');
    } else if (
      this.props.staticPagesCount >= WARNING_COUNTER_NUMBER_OF_STATIC_PAGES
    ) {
      return translate('Pages_Menu_Heavy_Sites_Checkpoint3_Info_Text', {
        Page_Number: this.props.staticPagesCount,
      });
    }
    return translate('Pages_Menu_Heavy_Sites_Checkpoint1_Info_Text');
  };

  getClasses = () => {
    if (this.props.staticPagesCount >= MAX_STATIC_PAGES_ALLOWED) {
      return 'blocked';
    } else if (
      this.props.staticPagesCount >= WARNING_COUNTER_NUMBER_OF_STATIC_PAGES
    ) {
      return 'count';
    }
    return 'info';
  };

  render() {
    return (
      <div key="too-many-pages-banner" className="too-many-pages-banner">
        <Composites.BannerPremium>
          <SectionDivider className={this.getClasses()}>
            <Composites.RichText>
              <RichText>
                {`${this.getLabel()} `}
                <a
                  onClick={() => {
                    this.handleTooManyPagesSuggestionClick();
                  }}
                >
                  {translate(this.getLearnMoreText())}
                </a>
              </RichText>
            </Composites.RichText>
          </SectionDivider>
        </Composites.BannerPremium>
      </div>
    );
  }
}
