// @ts-nocheck
import * as addPanel from '@/oldAddPanel';

export default {
  getCategorySections() {
    return addPanel.dataUtil.mergeWithCommonSections(
      addPanel.sections.getSections().vectorArt,
      {},
    );
  },
};
