import type { Scope } from '@/sections';
import type { CompRef } from 'types/documentServices';
import { getPageSectionsWithLayout } from '../../api';
import { fedopsLogger } from '@/util';

export const fixSingleSectionOverheight = async (
  scope: Scope,
  pageRef: CompRef,
  isHomepage: boolean,
): Promise<boolean> => {
  const { editorAPI } = scope;

  const sectionsWithLayout = getPageSectionsWithLayout(scope, pageRef);
  if (sectionsWithLayout.length !== 1) {
    return false;
  }
  const [section] = sectionsWithLayout;
  const limits = editorAPI.components.layout.getLimits(section.ref);

  if (section.layout.height > limits.maxHeight && section.layout.y < 0) {
    fedopsLogger.interactionStarted('fix_section_overheight', {
      customParams: {
        pageId: pageRef.id,
        y: section.layout.y,
        height: section.layout.height,
        isHomepage,
      },
    });

    editorAPI.components.layout.update(
      section.ref,
      {
        height: limits.maxHeight,
        y: 0,
      },
      true,
    );

    const childrenWithLayout = editorAPI.components
      .getChildren(section.ref)
      .map((childRef) => ({
        ref: childRef,
        layout: editorAPI.components.layout.get(childRef),
      }));

    await editorAPI.waitForChangesAppliedAsync();

    childrenWithLayout.forEach((child) => {
      const expectedChildYPos = section.layout.y + child.layout.y;

      editorAPI.components.layout.update(
        child.ref,
        {
          y: expectedChildYPos,
        },
        true,
      );
    });

    await editorAPI.waitForChangesAppliedAsync();

    fedopsLogger.interactionEnded('fix_section_overheight');

    return true;
  }

  return false;
};
