import type { Shell } from '@/apilib';
import { EditorAPIKey, EditorParamsApiKey } from '@/apis';
import * as stateManagement from '@/stateManagement';
import * as util from '@/util';

export function createEditorUIApi(shell: Shell) {
  const editorAPI = shell.getAPI(EditorAPIKey);

  function getScroll() {
    return editorAPI.scroll.get();
  }

  function setScroll(scrollParams: AnyFixMe) {
    return editorAPI.scroll.scrollTo(scrollParams);
  }

  function animateScroll(
    position: AnyFixMe,
    duration: AnyFixMe,
    onComplete?: AnyFixMe,
  ) {
    editorAPI.scroll.animateScrollTo(position, duration, onComplete);
  }

  function addListener(listener: AnyFixMe) {
    return editorAPI.scroll.addScrollListener(listener);
  }

  function removeListener(listener: AnyFixMe) {
    return editorAPI.scroll.removeScrollListener(listener);
  }

  function getEditingAreaLayout() {
    return editorAPI.getEditingAreaPosition();
  }

  function getMobileFrameLayout() {
    return editorAPI.getMobileFramePosition();
  }

  function setOnEditor() {
    editorAPI.setFocus();
  }

  function shouldCutEditingArea() {
    const state = editorAPI.store.getState();
    const editorParams = editorAPI.host.getAPI(EditorParamsApiKey);
    return (
      !stateManagement.preview.selectors.getPreviewMode(state) &&
      (state.editorIsInit
        ? util.sections.isSectionsEnabled()
        : util.sections.predictIsSectionsEnabled(editorParams)) &&
      stateManagement.mobile.selectors.isDesktopEditor(state) &&
      !editorAPI.imageCrop.isCropMode() &&
      !editorAPI.imageCrop.isEnteringCropMode() &&
      !editorAPI.zoomMode.isInZoomMode() &&
      !editorAPI.dsRead?.pages.popupPages.isPopupOpened()
    );
  }

  return {
    scroll: {
      getScroll,
      setScroll,
      animateScroll,
      addListener,
      removeListener,
    },
    stage: {
      getEditingAreaLayout,
      getMobileFrameLayout,
      shouldCutEditingArea,
    },
    focus: {
      setOnEditor,
    },
  };
}
