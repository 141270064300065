// @ts-nocheck
import _ from 'lodash';
import * as tpaGfppData from 'tpaGfppData';

const getSuperAppsGfppData = function () {
  return tpaGfppData.getData();
};

const getSuperAppsAddPanelStyles = function (appDefId, presetName) {
  const path = `${appDefId}.${presetName}`;
  return _.get(tpaGfppData.getAddPanelData(), path);
};

export { getSuperAppsGfppData, getSuperAppsAddPanelStyles };
