import React, { type FunctionComponent } from 'react';

interface DropDownAnchorProps {
  nodeRef?: (ref: Node) => void;
}

const DropDownAnchor: FunctionComponent<DropDownAnchorProps> = (props) => (
  <div ref={props.nodeRef} className="top-bar-drop-down-anchor">
    {props.children}
  </div>
);

DropDownAnchor.displayName = 'DropDownAnchor';

export default DropDownAnchor;
