import constants from '@/constants';
import * as stateManagement from '@/stateManagement';
import AddPanelDragHandler, {
  type ViewerMouseCoordinates,
  type DragBoxStageLayout,
} from './AddPanelDragHandler';

import type { EditorState } from '@/stateManagement';

const { getStageRect } = stateManagement.domMeasurements.selectors;

const DELTA_Y = 100;
const SCROLL_DELTA = DELTA_Y * 5;
const SCROLL_ANIMATION_TIME = 0.5;

export class AddSectionDragHandler extends AddPanelDragHandler {
  private isScrolling = false;

  handleDragOutsidePanelArea(
    e: MouseEvent,
    state: EditorState,
    { mouseCoordinates }: { mouseCoordinates: ViewerMouseCoordinates },
  ) {
    const stageRect = getStageRect(state);

    if (!this.isScrolling) {
      const y = this.lastDragPosition.y;
      const diffTop = y - stageRect.top;
      const diffBottom = stageRect.bottom - y;

      if (diffBottom >= 0 && (diffTop < DELTA_Y || diffBottom < DELTA_Y)) {
        const sign = diffTop < DELTA_Y ? -1 : 1;

        this.editorAPI.ui.scroll.animateScroll(
          {
            y: stageRect.top + sign * SCROLL_DELTA,
          },
          SCROLL_ANIMATION_TIME,
          () => {
            this.isScrolling = false;
          },
        );

        this.isScrolling = true;
      }
    }

    super.handleDragOutsidePanelArea(e, state, { mouseCoordinates });
  }

  getDropContainer(dragBoxStageLayout: DragBoxStageLayout) {
    if (this.item?.structure?.componentType === constants.COMP_TYPES.SECTION) {
      return this.editorAPI.pages.getFocusedPage();
    }

    return super.getDropContainer(dragBoxStageLayout);
  }
}
