import experiment from 'experiment';
import { BaseEntryScope, createEntryPoint } from '@/apilib';
import { init } from './init';
import { HighlightResizeStore } from './store';
import {
  BaseResizeAndPushApiKey,
  EditorAPIKey,
  EditorCoreApiKey,
  WorkspaceApiKey,
} from '@/apis';
import { createResizeService } from './resizeService';
import { createResizeHandler } from './resizeHandler';

export class Scope extends BaseEntryScope {
  workspace = this.useDependency(WorkspaceApiKey);
  editorAPI = this.useDependency(EditorAPIKey);
  baseResizeAndPush = this.useDependency(BaseResizeAndPushApiKey);
  editorCoreAPI = this.useDependency(EditorCoreApiKey);

  resizeService = this.declareApi(createResizeService);
  resizeHandler = this.declareApi(createResizeHandler);

  store = this.declareStore(HighlightResizeStore);
}

export const HighlightResizeEntryPoint = createEntryPoint({
  name: 'HighlightResize',
  Scope,
  async initialize(scope) {
    await scope.editorCoreAPI.hooks.initReady.promise;

    if (experiment.isOpen('se_resizeBlockingElementIndication')) {
      init(scope);
    }
  },
});
