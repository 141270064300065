//eslint-disable-next-line @wix/santa-editor/scoped-imports
import {
  type SnapshotSectionDispatchProps,
  snapshotSectionMapDispatchToProps,
} from '@/addPanelData/mappers';
import { wrapWithPropsTransformer } from '../utils';

export interface SavedComponentsProps {
  fetchSavedComponentsCollection: SnapshotSectionDispatchProps['fetchCollection'];
  fetchSavedComponentPreview: SnapshotSectionDispatchProps['fetchItemPreview'];
  pasteSavedComponentItem: SnapshotSectionDispatchProps['pasteItem'];
  deleteSavedComponent: SnapshotSectionDispatchProps['deleteItem'];
  renameSavedComponent: SnapshotSectionDispatchProps['renameItem'];
}

export const savedComponentsMapper = wrapWithPropsTransformer<
  SnapshotSectionDispatchProps,
  SavedComponentsProps
>(snapshotSectionMapDispatchToProps, (props) => ({
  fetchSavedComponentsCollection: props.fetchCollection,
  fetchSavedComponentPreview: props.fetchItemPreview,
  pasteSavedComponentItem: props.pasteItem,
  renameSavedComponent: props.renameItem,
  deleteSavedComponent: props.deleteItem,
}));
