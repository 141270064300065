// @ts-nocheck
import * as coreBi from '@/coreBi';

function createCopyPasteBILogger(biLogger) {
  function getBIComponentField(components, fieldName) {
    if (!components || !components.length) {
      return;
    }
    if (components.length > 1) {
      return 'multiselect';
    }

    return components[0][fieldName];
  }

  function joinComponentIDs(compIDs) {
    if (!compIDs) {
      return;
    }
    return compIDs.join(',');
  }

  function clipboardError({
    action,
    isQuotaExceededError,
  }: {
    action: string;
    isQuotaExceededError?: boolean;
  }) {
    biLogger.event(coreBi.events.copyPaste.CLIPBOARD_ERROR, {
      action,
      error_type: isQuotaExceededError ? 'QuotaExceededError' : 'UnknownError',
    });
  }

  function pasteComponent({
    sourceMetaSiteId,
    sourceComponents,
    newComponentPointers,
    origin,
    success,
    error,
    shouldApplyTargetTheme,
    hasMediaContent,
  }) {
    const sourceComponentIds = Array.isArray(sourceComponents)
      ? sourceComponents.map((component) => component.originCompId)
      : undefined;
    const newComponentIds = Array.isArray(newComponentPointers)
      ? newComponentPointers.map((componentRef) => componentRef.id)
      : undefined;

    biLogger.event(coreBi.events.copyPaste.PASTE_COMPONENT, {
      origin_msid: sourceMetaSiteId,
      component_type: getBIComponentField(sourceComponents, 'componentType'),
      origin_component_id: joinComponentIDs(sourceComponentIds),
      component_id: joinComponentIDs(newComponentIds),
      paste_option: origin,
      success,
      error_type: error,
      theme: shouldApplyTargetTheme ? 'target' : 'origin',
      media_content:
        typeof hasMediaContent === 'boolean'
          ? String(hasMediaContent)
          : undefined,
    });
  }

  function copyPage({ pageId, origin }) {
    biLogger.event(coreBi.events.copyPaste.COPY_PAGE, {
      page_id: pageId,
      copy_option: origin,
    });
  }

  function pastePage({
    sourceMetaSiteId,
    sourcePageId,
    origin,
    success,
    errorType,
    shouldApplyTargetTheme,
    hasMediaContent,
  }) {
    biLogger.event(coreBi.events.copyPaste.PASTE_PAGE, {
      origin_msid: sourceMetaSiteId,
      origin_page_id: sourcePageId,
      paste_option: origin,
      success,
      error_type: errorType,
      theme: shouldApplyTargetTheme ? 'target' : 'origin',
      media_content:
        typeof hasMediaContent === 'boolean'
          ? String(hasMediaContent)
          : undefined,
    });
  }

  function unknownSource({ content }: { content: string }) {
    biLogger.event(coreBi.events.copyPaste.UNKNOWN_PASTE_ORIGIN, {
      content,
    });
  }

  return {
    clipboardError,
    pasteComponent,
    copyPage,
    pastePage,
    unknownSource,
  };
}

export default createCopyPasteBILogger;
