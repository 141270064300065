// @ts-nocheck
import _ from 'lodash';
import createReactClass from 'create-react-class';
import * as compPanelInfra from '@/compPanelInfra';
import wixappsDesignCustomizationPanel from './wixappsDesignCustomizationPanel';

import React from 'react';
import { Divider } from '@wix/wix-base-ui';
import * as baseUI from '@/baseUI';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'wixappsDesignSubComponentsPanel',
  mixins: [compPanelInfra.compPanelMixin, compPanelInfra.wixappsPanelMixin],
  getStyleCustomizationRules(comp) {
    const rules = this.getAvailableCustomizations('design');
    const format = this._getFormat();
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/filter
    return _.filter(rules, function (rule) {
      const isFormatMatch =
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/is-undefined
        _.isUndefined(rule.format) ||
        rule.format === format ||
        rule.format === '*';
      const isSameComp = !comp.rules
        ? rule.fieldId === (comp.compId || comp.id)
        : // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line you-dont-need-lodash-underscore/some
          _.some(comp.rules, { fieldId: rule.fieldId });
      const isStyleRule = rule.key === 'comp.style';
      return isFormatMatch && isSameComp && isStyleRule;
    });
  },
  getCompVisibilityCustomization(comp) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/find
    const visibilityCustomization = _.find(
      this.getAvailableCustomizations('design'),
      function (customization) {
        return (
          customization.relatedComp === comp.id &&
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line you-dont-need-lodash-underscore/includes
          _.includes(
            ['comp.hidden', 'showItemsCount', 'showPostCount'],
            customization.key,
          )
        );
      },
    );
    if (visibilityCustomization) {
      visibilityCustomization.value = this.linkedCustomization(
        visibilityCustomization,
      ).value;
    }
    return visibilityCustomization;
  },
  getComps() {
    const self = this;
    return this.getInnerComps()
      .filter(function (comp) {
        const shouldBeHidden = self.evaluateHiddenWhenCondition(
          comp.hiddenWhen,
        );
        return !shouldBeHidden;
      })
      .map(function (comp) {
        return {
          label: comp.label,
          id: comp.id,
          type: comp.type,
          customization: this.getCompVisibilityCustomization(comp),
          styleCustomizationRules: this.getStyleCustomizationRules(comp),
        };
      }, this);
  },
  isChecked(customization) {
    return customization.value === customization.input.trueVal;
  },
  openCustomizeDesign(comp) {
    this.props.switchPanelFunc(wixappsDesignCustomizationPanel, {
      styleCustomizationRules: comp.styleCustomizationRules,
      compType:
        this.getEditorAPI().dsRead.wixapps.classics.getComponentTypeByProxyName(
          comp.type,
        ),
      panelTitle: comp.label,
      panelStyle: this.props.style,
      customizeTheme: comp.type !== 'LabelWithoutTheme',
    });
  },
  updateCompValue(customization, value) {
    customization.value = String(value === customization.input.trueVal);
    const linkedValue = this.linkedCustomization(customization);
    linkedValue.requestChange(customization.value);
  },
  render() {
    return (
      <compPanelInfra.compPanelFrame
        title={`${this.getSelectedAppPartDisplayName()}_design`}
        helpId={this.getComponentHelpIdByType('design')}
        {...this.getFrameProps()}
      >
        {/* TODO: Fix this the next time the file is edited. */}
        {/* eslint-disable-next-line you-dont-need-lodash-underscore/map */}
        {_.map(this.getComps(), (comp, compIndex) => (
          <div>
            {comp.customization || comp.styleCustomizationRules.length ? (
              <div key={comp.label}>
                <baseUI.checkboxWithButton
                  label={comp.label}
                  shouldTranslate={true}
                  buttonLabel={'blog_design_panel_customize_design'}
                  value={
                    comp.customization
                      ? this.isChecked(comp.customization)
                      : true
                  }
                  onChange={(newValue) => {
                    this.updateCompValue(comp.customization, String(newValue));
                  }}
                  onButtonClick={this.openCustomizeDesign.bind(this, comp)}
                  isCheckboxDisabled={!comp.customization}
                  isButtonDisabled={!comp.styleCustomizationRules.length}
                  className="design-customization-checkbox"
                />

                {compIndex !== this.getComps().length - 1 ? (
                  <Divider key="divider-long" long={true} />
                ) : null}
              </div>
            ) : null}
          </div>
        ))}
      </compPanelInfra.compPanelFrame>
    );
  },
});
