import changeStyleMessage from './changeStyleMessage/changeStyleMessage';
import deleteLastPageMessage from './deleteLastPageMessage/deleteLastPageMessage';
import deletePageMessage from './deletePageMessage/deletePageMessage';
import deleteInteractionMessage from './deleteInteractionMessage/deleteInteractionMessage';
import exampleMessagePanel from './exampleMessagePanel';
import siteRevisionsPanel from './siteRevisionsPanel';
import switchToOldEditorConfirmationPanel from './switchToOldEditorConfirmationPanel';
import validationErrorPanel from './browserDeprecationMessage/validationErrorPanel';
import concurrentUserPanel from './concurrentUsers/concurrentUserPanel';
import autopilotUpdatePanel from './autopilotUpdate/autopilotUpdatePanel';
import widgetWarningMessage from './widgetWarningMessage/widgetWarningMessage';
import browserDeprecationMessage from './browserDeprecationMessage/browserDeprecationMessage';
import publishingBlockedForUser from './publishingBlocked/publishingBlockedForUser';
import switchWorkspaceModePanel from './switchWorkspaceModePanel';
import prohibitedCrossEditorPasteMessage from './prohibitedCrossEditorPasteMessage';
import prohibitedPasteFromFixedStageMessage from './prohibitedPasteFromFixedStageMessage';
import parentHomePageMessage from './parentHomePageMessage/parentHomePageMessage';

export {
  changeStyleMessage,
  deleteLastPageMessage,
  deletePageMessage,
  deleteInteractionMessage,
  exampleMessagePanel as exampleMessage,
  siteRevisionsPanel,
  switchToOldEditorConfirmationPanel,
  validationErrorPanel,
  concurrentUserPanel,
  autopilotUpdatePanel,
  widgetWarningMessage,
  browserDeprecationMessage,
  publishingBlockedForUser,
  switchWorkspaceModePanel,
  prohibitedCrossEditorPasteMessage,
  prohibitedPasteFromFixedStageMessage,
  parentHomePageMessage,
};
