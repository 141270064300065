import { layersPanel } from '@/stateManagement';
import { LAYERS } from '@/helpIds';

import {
  initTreeNodesContext,
  destroyTreeNodesContext,
  type TreeNodesContext,
} from './createNodeProps/nodesContext';
import type { ThunkAction } from 'types/redux';

export interface LayersPanelContext {
  treeNodesContext: TreeNodesContext;
}

export const initLayersPanel =
  ({ treeNodesContext }: LayersPanelContext): ThunkAction =>
  (dispatch, getState, { editorAPI }) => {
    initTreeNodesContext(editorAPI, treeNodesContext);
  };

export const destroyLayersPanel =
  ({ treeNodesContext }: LayersPanelContext): ThunkAction =>
  (dispatch, getState, { editorAPI }) => {
    destroyTreeNodesContext(editorAPI, treeNodesContext);
  };

export const openLayersPanelHelpCenter =
  (): ThunkAction =>
  (dispatch, getState, { editorAPI }) => {
    editorAPI.panelManager.openHelpCenter(LAYERS.LAYERS_PANEL);
  };

export const hideLayersPanel = layersPanel.actions.hideLayersPanel;

export type LayersPanelAction =
  | ReturnType<typeof initLayersPanel>
  | ReturnType<typeof destroyLayersPanel>
  | ReturnType<typeof openLayersPanelHelpCenter>
  | ReturnType<typeof hideLayersPanel>;
