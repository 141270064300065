// @ts-nocheck
import _ from 'lodash';
import constants from '@/constants';
import * as util from '@/util';
import experiment from 'experiment';

const { ACTIONS } = constants.ROOT_COMPS.GFPP;
const { getRootAppWidget, isAppWidget } = util.appStudioUtils;

function isWidgetPage(editorAPI) {
  return editorAPI.dsRead.pages.isWidgetPage(
    editorAPI.pages.getFocusedPageId(),
  );
}

const isConnectedToRoot = (editorAPI, compRef) => {
  const controllerRef =
    editorAPI.platform.controllers.connections.getPrimaryConnection(compRef)
      ?.controllerRef;

  return editorAPI.utils.isSameRef(controllerRef, getRootAppWidget(editorAPI));
};

export default function (editorAPI, compRef, compGfppData) {
  if (isWidgetPage(editorAPI)) {
    if (
      isAppWidget(editorAPI, compRef) &&
      !isConnectedToRoot(editorAPI, compRef[0])
    ) {
      delete compGfppData.presetActions[ACTIONS.DESIGN];
    }
    const shouldRemoveConnectButton = !experiment.isOpen(
      'se_enableDatabindingForAppStudio',
    );

    if (shouldRemoveConnectButton) {
      if (compGfppData.presetActions[ACTIONS.CONNECT]) {
        delete compGfppData.presetActions[ACTIONS.CONNECT];
      }
      compGfppData.enabledActions = _.reject(
        compGfppData.enabledActions,
        (action) => action === ACTIONS.CONNECT,
      );
    }

    delete compGfppData.presetActions[ACTIONS.INTERACTIONS];
  }
}
