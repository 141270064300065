'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')

module.exports = {
    "type": addPanelDataConsts.SECTIONS_TYPES.PRESET,
    "hide": false,
    "title": "add_section_label_columns_welcome",
    "subNavigationTitle": "add_section_label_columns_welcome",
    "presetTitle": "add_section_label_columns_welcome",
    "tooltipTitle": "add_section_label_columns_welcome",
    "automationId": "add-panel-section-columnsWelcomeSection",
    "subNavigationHide": false,
    "showSectionHeader": true,
    "additionalBehaviours": {
        "infoIcon": addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
        "hoverImageAction": addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
        "iconEnabledForComps": {}
    },
    "props": {
        "image": "addPanelData/sections/columnsWelcomeSection_en/columnsWelcomeSection_en.png",
        "imageHover": null,
        "items": [{
            "id": "Columns_ColumnsWelcome_1",
            "structure": {
                "type": "Container",
                "components": [{
                    "type": "Container",
                    "components": [{
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                        "layout": {
                            "width": 978,
                            "height": 124,
                            "x": 2,
                            "y": 255,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<h2 class=\"font_2\" style=\"font-size:80px; line-height:0.62em; text-align:center\"><span style=\"letter-spacing:0.4em\"><span style=\"color:#000000\"><span style=\"font-family:lulo-clean-w01-one-bold,sans-serif\"><span style=\"font-size:80px\">you</span></span></span></span><span style=\"letter-spacing:0.2em\"><span style=\"color:#000000\"><span style=\"font-family:lulo-clean-w01-one-bold,sans-serif\"><span style=\"font-size:80px\">r</span></span></span></span></h2>\n\n<h2 class=\"font_2\" style=\"font-size:80px; line-height:0.62em; text-align:center\"><span style=\"color:#DEDEDE;\"><span style=\"letter-spacing:0.4em\"><span style=\"font-family:lulo-clean-w01-one-bold,sans-serif\"><span style=\"font-size:80px\">bran</span></span></span><span style=\"letter-spacing:0em\"><span style=\"font-family:lulo-clean-w01-one-bold,sans-serif\"><span style=\"font-size:80px\">d</span></span></span></span></h2>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                            "layout": {
                                "width": 300,
                                "height": 69,
                                "x": 10,
                                "y": 42,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 class=\"font_2\" style=\"font-size:80px; line-height:0.62em; text-align:center\"><span style=\"letter-spacing:0.4em\"><span style=\"color:#000000\"><span style=\"font-family:lulo-clean-w01-one-bold,sans-serif\"><span style=\"font-size:80px\">you</span></span></span></span><span style=\"letter-spacing:0.2em\"><span style=\"color:#000000\"><span style=\"font-family:lulo-clean-w01-one-bold,sans-serif\"><span style=\"font-size:80px\">r</span></span></span></span></h2>\n\n<h2 class=\"font_2\" style=\"font-size:80px; line-height:0.62em; text-align:center\"><span style=\"color:#DEDEDE;\"><span style=\"letter-spacing:0.4em\"><span style=\"font-family:lulo-clean-w01-one-bold,sans-serif\"><span style=\"font-size:80px\">bran</span></span></span><span style=\"letter-spacing:0em\"><span style=\"font-family:lulo-clean-w01-one-bold,sans-serif\"><span style=\"font-size:80px\">d</span></span></span></span></h2>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.button.BasicButton",
                        "layout": {
                            "width": 166,
                            "height": 47,
                            "x": 408,
                            "y": 449,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.SiteButton",
                        "data": {
                            "type": "LinkableButton",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "label": "READ MORE"
                        },
                        "props": {
                            "type": "ButtonProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "align": "center",
                            "margin": 0
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "alpha-bg": "0",
                                    "alpha-bgh": "1",
                                    "alpha-brd": "1",
                                    "alpha-brdh": "1",
                                    "alpha-txt": "1",
                                    "alpha-txth": "1",
                                    "bg": "#727272",
                                    "bgh": "#FFFFFF",
                                    "boxShadowToggleOn-shd": "false",
                                    "brd": "#E8E8E8",
                                    "brdh": "#FFFFFF",
                                    "brw": "4",
                                    "fnt": "normal normal normal 15px/1.4em avenir-lt-w01_35-light1475496",
                                    "rd": "0px",
                                    "shd": "0 1px 4px rgba(0, 0, 0, 0.6);",
                                    "txt": "#DEDEDE",
                                    "txth": "#000000"
                                },
                                "propertiesSource": {
                                    "alpha-bg": "value",
                                    "bg": "value",
                                    "bgh": "value",
                                    "brd": "value",
                                    "brdh": "value",
                                    "brw": "value",
                                    "fnt": "value",
                                    "rd": "value",
                                    "shd": "value",
                                    "txt": "value",
                                    "txth": "value"
                                }
                            },
                            "componentClassName": "wysiwyg.viewer.components.SiteButton",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.button.BasicButton"
                        },
                        "mobileStructure": {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.button.BasicButton",
                            "layout": {
                                "width": 166,
                                "height": 47,
                                "x": 77,
                                "y": 163,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.SiteButton",
                            "data": {
                                "type": "LinkableButton",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "label": "READ MORE"
                            },
                            "props": {
                                "type": "ButtonProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "align": "center",
                                "margin": 0
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {
                                        "alpha-bg": "0",
                                        "alpha-bgh": "1",
                                        "alpha-brd": "1",
                                        "alpha-brdh": "1",
                                        "alpha-txt": "1",
                                        "alpha-txth": "1",
                                        "bg": "#727272",
                                        "bgh": "#FFFFFF",
                                        "boxShadowToggleOn-shd": "false",
                                        "brd": "#E8E8E8",
                                        "brdh": "#FFFFFF",
                                        "brw": "4",
                                        "fnt": "normal normal normal 15px/1.4em avenir-lt-w01_35-light1475496",
                                        "rd": "0px",
                                        "shd": "0 1px 4px rgba(0, 0, 0, 0.6);",
                                        "txt": "#DEDEDE",
                                        "txth": "#000000"
                                    },
                                    "propertiesSource": {
                                        "alpha-bg": "value",
                                        "bg": "value",
                                        "bgh": "value",
                                        "brd": "value",
                                        "brdh": "value",
                                        "brw": "value",
                                        "fnt": "value",
                                        "rd": "value",
                                        "shd": "value",
                                        "txt": "value",
                                        "txth": "value"
                                    }
                                },
                                "componentClassName": "wysiwyg.viewer.components.SiteButton",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.button.BasicButton"
                            }
                        }
                    }],
                    "layout": {
                        "width": 980,
                        "height": 750,
                        "x": 0,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.Column",
                    "props": {"type": "ColumnProperties", "alignment": 50},
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "mediaRef": {
                                "type": "WixVideo",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "title": "Urban Skaters",
                                "videoId": "video/11062b_9181e09668144a139d3b107a08acd708",
                                "qualities": [{
                                    "quality": "480p",
                                    "width": 1920,
                                    "height": 1080,
                                    "formats": ["mp4", "webm"]
                                }, {
                                    "quality": "720p",
                                    "width": 1920,
                                    "height": 1080,
                                    "formats": ["mp4", "webm"]
                                }, {"quality": "1080p", "width": 1920, "height": 1080, "formats": ["mp4", "webm"]}],
                                "posterImageRef": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "title": "",
                                    "uri": "11062b_9181e09668144a139d3b107a08acd708f000.jpg",
                                    "description": "public/8e256233-1752-4026-9341-54036e7f875e/d6b6b972-952c-4ab0-b46a-a6c05a0a94a5",
                                    "width": 1920,
                                    "height": 1080,
                                    "alt": ""
                                },
                                "opacity": 1,
                                "duration": 12,
                                "loop": true,
                                "autoplay": true,
                                "preload": "auto",
                                "mute": true,
                                "artist": {
                                    "id": "mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_vD252_6_128",
                                    "name": "dissolve"
                                },
                                "playbackSpeed": 1
                            },
                            "color": "#242323",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "#000000",
                            "colorOverlayOpacity": 0.31
                        }
                    },
                    "style": "mc1",
                    "mobileStructure": {
                        "type": "Container",
                        "layout": {
                            "width": 320,
                            "height": 308,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.Column",
                        "props": {"type": "ColumnProperties", "alignment": 50},
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "mediaRef": {
                                    "type": "WixVideo",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "title": "Urban Skaters",
                                    "videoId": "video/11062b_9181e09668144a139d3b107a08acd708",
                                    "qualities": [{
                                        "quality": "480p",
                                        "width": 1920,
                                        "height": 1080,
                                        "formats": ["mp4", "webm"]
                                    }, {
                                        "quality": "720p",
                                        "width": 1920,
                                        "height": 1080,
                                        "formats": ["mp4", "webm"]
                                    }, {
                                        "quality": "1080p",
                                        "width": 1920,
                                        "height": 1080,
                                        "formats": ["mp4", "webm"]
                                    }],
                                    "posterImageRef": {
                                        "type": "Image",
                                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                        "title": "",
                                        "uri": "11062b_9181e09668144a139d3b107a08acd708f000.jpg",
                                        "description": "public/8e256233-1752-4026-9341-54036e7f875e/d6b6b972-952c-4ab0-b46a-a6c05a0a94a5",
                                        "width": 1920,
                                        "height": 1080,
                                        "alt": ""
                                    },
                                    "opacity": 1,
                                    "duration": 12,
                                    "loop": true,
                                    "autoplay": true,
                                    "preload": "auto",
                                    "mute": true,
                                    "artist": {
                                        "id": "mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_vD252_6_128",
                                        "name": "dissolve"
                                    },
                                    "playbackSpeed": 1
                                },
                                "color": "#242323",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "#000000",
                                "colorOverlayOpacity": 0.55
                            }
                        },
                        "style": "mc1"
                    }
                }],
                "layout": {
                    "width": 980,
                    "height": 750,
                    "x": 0,
                    "y": 100,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.StripColumnsContainer",
                "props": {
                    "type": "StripColumnsContainerProperties",
                    "fullWidth": true,
                    "columnsMargin": 0,
                    "frameMargin": 0,
                    "rowMargin": 0,
                    "siteMargin": 0
                },
                "design": {
                    "type": "MediaContainerDesignData",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "background": {
                        "type": "BackgroundMedia",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "color": "#FFFFFF",
                        "colorOpacity": 0,
                        "alignType": "center",
                        "fittingType": "fill",
                        "scrollType": "none",
                        "colorOverlay": "",
                        "colorOverlayOpacity": 1
                    }
                },
                "style": {
                    "type": "TopLevelStyle",
                    "id": "strc1",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.viewer.components.StripColumnsContainer",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.viewer.skins.stripContainer.DefaultStripContainer"
                },
                "mobileStructure": {
                    "type": "Container",
                    "layout": {
                        "width": 320,
                        "height": 308,
                        "x": 0,
                        "y": 10,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.StripColumnsContainer",
                    "props": {
                        "type": "StripColumnsContainerProperties",
                        "fullWidth": true,
                        "columnsMargin": 0,
                        "frameMargin": 0,
                        "rowMargin": 0,
                        "siteMargin": 0
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "color": "#FFFFFF",
                            "colorOpacity": 0,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 1
                        }
                    },
                    "style": "strc1"
                },
                "activeModes": {},
                "id": "comp-ip3ty93o"
            },
            "preset": {
                "rect": {"width": 324, "height": 190, "x": -0.8, "y": 0},
                "label": "add_preset_columns_welcome_1",
                "tags": null
            }
        }, {
            "id": "Columns_ColumnsWelcome_2",
            "structure": {
                "type": "Container",
                "components": [{
                    "type": "Container",
                    "components": [{
                        "type": "Component",
                        "layout": {
                            "width": 490,
                            "height": 600,
                            "x": 490,
                            "y": 65,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WPhoto",
                        "data": {
                            "type": "Image",
                            "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                            "title": "",
                            "uri": "035244_a88c5c525ae24899a01da979bfc6484d~mv2_d_2125_1416_s_2.jpg",
                            "description": "",
                            "width": 2125,
                            "height": 1416,
                            "alt": ""
                        },
                        "props": {
                            "type": "WPhotoProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "displayMode": "fill",
                            "onClickBehavior": "goToLink",
                            "effectName": "none"
                        },
                        "style": "wp2",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 249,
                                "height": 271,
                                "x": 31,
                                "y": 33,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WPhoto",
                            "data": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                                "title": "",
                                "uri": "035244_a88c5c525ae24899a01da979bfc6484d~mv2_d_2125_1416_s_2.jpg",
                                "description": "",
                                "width": 2125,
                                "height": 1416,
                                "alt": ""
                            },
                            "props": {
                                "type": "WPhotoProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "displayMode": "fill",
                                "onClickBehavior": "goToLink",
                                "effectName": "none"
                            },
                            "style": "wp2"
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 592,
                            "height": 313,
                            "x": 184,
                            "y": 152,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<h2 class=\"font_2\" style=\"font-size:146px; line-height:1em; text-align:center\"><span style=\"font-size:146px\"><span style=\"color:#000000\"><span style=\"font-family:didot-w01-italic,serif\">Your </span></span></span></h2>\n\n<h2 class=\"font_2\" style=\"font-size:146px; line-height:1em; text-align:center\"><span style=\"font-size:146px\"><span style=\"color:#000000\"><span style=\"font-family:didot-w01-italic,serif\">Title.</span></span></span></h2>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 180,
                                "height": 133,
                                "x": -3,
                                "y": 16,
                                "scale": 1.2582651311414503,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 class=\"font_2\" style=\"font-size:146px; line-height:1em; text-align:center\"><span style=\"font-size:146px\"><span style=\"color:#000000\"><span style=\"font-family:didot-w01-italic,serif\">Your </span></span></span></h2>\n\n<h2 class=\"font_2\" style=\"font-size:146px; line-height:1em; text-align:center\"><span style=\"font-size:146px\"><span style=\"color:#000000\"><span style=\"font-family:didot-w01-italic,serif\">Title.</span></span></span></h2>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                        "layout": {
                            "width": 490,
                            "height": 22,
                            "x": 246,
                            "y": 595,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<h2 class=\"font_2\" style=\"font-size: 20px; line-height: 1em; text-align: center;\"><span style=\"font-size:20px;\"><span style=\"color:rgb(47, 46, 46); font-family:didot-w01-italic,serif\">scroll</span></span></h2>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                            "layout": {
                                "width": 72,
                                "height": 22,
                                "x": 123,
                                "y": 349,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 class=\"font_2\" style=\"font-size: 20px; line-height: 1em; text-align: center;\"><span style=\"font-size:20px;\"><span style=\"color:rgb(47, 46, 46); font-family:didot-w01-italic,serif\">scroll</span></span></h2>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 259,
                            "height": 51,
                            "x": 347,
                            "y": 465,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<p class=\"font_8\" style=\"font-size:15px; line-height:1.6em; text-align:center\"><span style=\"font-family:futura-lt-w01-light,sans-serif\"><span style=\"font-size:15px\"><span style=\"color:#000000\">I&rsquo;m a paragraph. Double click me&nbsp;or&nbsp;click Edit Text, it&#39;s easy.</span></span></span></p>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 123,
                                "height": 102,
                                "x": 20,
                                "y": 161,
                                "scale": 0.9974999999999999,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p class=\"font_8\" style=\"font-size:15px; line-height:1.6em; text-align:center\"><span style=\"font-family:futura-lt-w01-light,sans-serif\"><span style=\"font-size:15px\"><span style=\"color:#000000\">I&rsquo;m a paragraph. Double click me&nbsp;or&nbsp;click Edit Text, it&#39;s easy.</span></span></span></p>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "skin": "svgshape.v2.Svg_61ff849ad9a548f7bd6828909987ef06",
                        "layout": {
                            "width": 59,
                            "height": 9,
                            "x": 459,
                            "y": 642,
                            "scale": 1,
                            "rotationInDegrees": 270,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.svgshape.SvgShape",
                        "data": {
                            "type": "SvgShape",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                        },
                        "props": {
                            "type": "SvgShapeProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "maintainAspectRatio": true
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "alpha-fillcolor": "1",
                                    "alpha-stroke": "1",
                                    "fillcolor": "#342216",
                                    "stroke": "#ED1566",
                                    "strokewidth": "0px"
                                },
                                "propertiesSource": {
                                    "fillcolor": "value",
                                    "stroke": "value",
                                    "strokewidth": "value"
                                }
                            },
                            "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "svgshape.v2.Svg_61ff849ad9a548f7bd6828909987ef06"
                        },
                        "mobileStructure": {
                            "type": "Component",
                            "skin": "svgshape.v2.Svg_61ff849ad9a548f7bd6828909987ef06",
                            "layout": {
                                "width": 39,
                                "height": 7,
                                "x": 140,
                                "y": 320,
                                "scale": 1,
                                "rotationInDegrees": 270,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.svgshape.SvgShape",
                            "data": {
                                "type": "SvgShape",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                            },
                            "props": {
                                "type": "SvgShapeProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "maintainAspectRatio": true
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {
                                        "alpha-fillcolor": "1",
                                        "alpha-stroke": "1",
                                        "fillcolor": "#342216",
                                        "stroke": "#ED1566",
                                        "strokewidth": "0px"
                                    },
                                    "propertiesSource": {
                                        "fillcolor": "value",
                                        "stroke": "value",
                                        "strokewidth": "value"
                                    }
                                },
                                "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "svgshape.v2.Svg_61ff849ad9a548f7bd6828909987ef06"
                            }
                        }
                    }],
                    "layout": {
                        "width": 980,
                        "height": 731,
                        "x": 0,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.Column",
                    "props": {"type": "ColumnProperties", "alignment": 50},
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "color": "#FFEF98",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "style": "mc1",
                    "mobileStructure": {
                        "type": "Container",
                        "layout": {
                            "width": 320,
                            "height": 397,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.Column",
                        "props": {"type": "ColumnProperties", "alignment": 50},
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "#FFEF98",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            }
                        },
                        "style": "mc1"
                    }
                }],
                "layout": {
                    "width": 980,
                    "height": 731,
                    "x": 0,
                    "y": 1050,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.StripColumnsContainer",
                "props": {
                    "type": "StripColumnsContainerProperties",
                    "fullWidth": true,
                    "columnsMargin": 0,
                    "frameMargin": 0,
                    "rowMargin": 0,
                    "siteMargin": 0
                },
                "design": {
                    "type": "MediaContainerDesignData",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "background": {
                        "type": "BackgroundMedia",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "color": "#FFFFFF",
                        "colorOpacity": 0,
                        "alignType": "center",
                        "fittingType": "fill",
                        "scrollType": "none",
                        "colorOverlay": "",
                        "colorOverlayOpacity": 1
                    }
                },
                "style": {
                    "type": "TopLevelStyle",
                    "id": "strc1",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.viewer.components.StripColumnsContainer",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.viewer.skins.stripContainer.DefaultStripContainer"
                },
                "mobileStructure": {
                    "type": "Container",
                    "layout": {
                        "width": 320,
                        "height": 397,
                        "x": 0,
                        "y": 1347,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.StripColumnsContainer",
                    "props": {
                        "type": "StripColumnsContainerProperties",
                        "fullWidth": true,
                        "columnsMargin": 0,
                        "frameMargin": 0,
                        "rowMargin": 0,
                        "siteMargin": 0
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "color": "#FFFFFF",
                            "colorOpacity": 0,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 1
                        }
                    },
                    "style": "strc1"
                },
                "activeModes": {},
                "id": "comp-ip53xbw6"
            },
            "preset": {
                "rect": {"width": 324, "height": 186, "x": -0.8, "y": 190},
                "label": "add_preset_columns_welcome_2",
                "tags": null
            }
        }, {
            "id": "Columns_ColumnsWelcome_3",
            "structure": {
                "type": "Container",
                "components": [{
                    "type": "Container",
                    "components": [{
                        "type": "Container",
                        "components": [{
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                            "layout": {
                                "width": 398,
                                "height": 23,
                                "x": 57,
                                "y": 248,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 class=\"font_2\" style=\"font-size: 20px; line-height: 1em; text-align: center;\"><span style=\"font-size:20px;\"><span class=\"color_14\"><span style=\"letter-spacing:0.25em\"><span style=\"font-family:futura-lt-w01-book,sans-serif\">LOCAL CUISINE</span></span></span></span></h2>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew",
                            "mobileStructure": {
                                "type": "Component",
                                "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                                "layout": {
                                    "width": 208,
                                    "height": 20,
                                    "x": 36,
                                    "y": 139,
                                    "scale": 0.903954802259887,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "data": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<h2 class=\"font_2\" style=\"font-size: 20px; line-height: 1em; text-align: center;\"><span style=\"font-size:20px;\"><span class=\"color_14\"><span style=\"letter-spacing:0.25em\"><span style=\"font-family:futura-lt-w01-book,sans-serif\">LOCAL CUISINE</span></span></span></span></h2>\n",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "props": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                },
                                "style": "txtNew"
                            }
                        }, {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.button.TextOnlyButtonSkin",
                            "layout": {
                                "width": 226,
                                "height": 26,
                                "x": 147,
                                "y": 276,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.SiteButton",
                            "data": {
                                "type": "LinkableButton",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "label": "Order Online >"
                            },
                            "props": {
                                "type": "ButtonProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "align": "center",
                                "margin": 0
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {
                                        "fnt": "italic normal normal 15px/1.4em libre+baskerville",
                                        "txt": "#BC9B5D",
                                        "txtd": "#FFFFFF",
                                        "txth": "#605E5E"
                                    },
                                    "propertiesSource": {
                                        "fnt": "value",
                                        "txt": "value",
                                        "txtd": "value",
                                        "txth": "value"
                                    }
                                },
                                "componentClassName": "wysiwyg.viewer.components.SiteButton",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.button.TextOnlyButtonSkin"
                            },
                            "mobileStructure": {
                                "type": "Component",
                                "skin": "wysiwyg.viewer.skins.button.TextOnlyButtonSkin",
                                "layout": {
                                    "width": 226,
                                    "height": 26,
                                    "x": 26,
                                    "y": 162,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.SiteButton",
                                "data": {
                                    "type": "LinkableButton",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "label": "Order Online >"
                                },
                                "props": {
                                    "type": "ButtonProperties",
                                    "metaData": {"schemaVersion": "1.0"},
                                    "align": "center",
                                    "margin": 0
                                },
                                "style": {
                                    "type": "TopLevelStyle",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "style": {
                                        "groups": {},
                                        "properties": {
                                            "fnt": "italic normal normal 15px/1.4em libre+baskerville",
                                            "txt": "#BC9B5D",
                                            "txtd": "#FFFFFF",
                                            "txth": "#605E5E"
                                        },
                                        "propertiesSource": {
                                            "fnt": "value",
                                            "txt": "value",
                                            "txtd": "value",
                                            "txth": "value"
                                        }
                                    },
                                    "componentClassName": "wysiwyg.viewer.components.SiteButton",
                                    "pageId": "",
                                    "compId": "",
                                    "styleType": "custom",
                                    "skin": "wysiwyg.viewer.skins.button.TextOnlyButtonSkin"
                                }
                            }
                        }, {
                            "type": "Component",
                            "layout": {
                                "width": 526,
                                "height": 181,
                                "x": 1,
                                "y": 31,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 class=\"font_2\" style=\"font-size: 136px; line-height: 1.2em; text-align: center;\"><span style=\"font-size:136px;\"><span style=\"color:rgb(188, 155, 93); font-family:peaches-and-cream-regular-w00,script\">Fresh Eats</span></span></h2>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew",
                            "mobileStructure": {
                                "type": "Component",
                                "layout": {
                                    "width": 230,
                                    "height": 120,
                                    "x": 24,
                                    "y": 10,
                                    "scale": 1.7860627339992312,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "data": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<h2 class=\"font_2\" style=\"font-size: 136px; line-height: 1.2em; text-align: center;\"><span style=\"font-size:136px;\"><span style=\"color:rgb(188, 155, 93); font-family:peaches-and-cream-regular-w00,script\">Fresh Eats</span></span></h2>\n",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "props": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                },
                                "style": "txtNew"
                            }
                        }],
                        "skin": "wysiwyg.viewer.skins.area.RectangleArea",
                        "layout": {
                            "width": 528,
                            "height": 360,
                            "x": 227,
                            "y": 170,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "mobile.core.components.Container",
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {"alpha-bg": "1", "bg": "#FFFCF5"},
                                "propertiesSource": {"bg": "value"}
                            },
                            "componentClassName": "mobile.core.components.Container",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.area.RectangleArea"
                        },
                        "mobileStructure": {
                            "type": "Container",
                            "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                            "layout": {
                                "width": 278,
                                "height": 225,
                                "x": 21,
                                "y": 51,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "mobile.core.components.Container",
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {"alpha-bg": "1", "bg": "#FFFCF5"},
                                    "propertiesSource": {"bg": "value"}
                                },
                                "componentClassName": "mobile.core.components.Container",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.area.RectangleArea"
                            }
                        }
                    }],
                    "layout": {
                        "width": 980,
                        "height": 700,
                        "x": 0,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.Column",
                    "props": {"type": "ColumnProperties", "metaData": {"schemaVersion": "1.0"}, "alignment": 50},
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "mediaRef": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "title": "Food Preparation",
                                "uri": "8d1bc85d1bb14ed4aa0e0386d51b0786.jpg",
                                "description": "public/491df47b-5958-4dc7-9029-37f428755d45/32fe43d3-68f4-48fc-8f2d-461e1ddced53",
                                "width": 5700,
                                "height": 3800,
                                "alt": "",
                                "artist": {"id": "", "name": ""},
                                "opacity": 0.8
                            },
                            "color": "#000000",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "style": "mc1",
                    "mobileStructure": {
                        "type": "Container",
                        "layout": {
                            "width": 320,
                            "height": 325,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.Column",
                        "props": {
                            "type": "ColumnProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "alignment": 50
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "mediaRef": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "title": "Food Preparation",
                                    "uri": "8d1bc85d1bb14ed4aa0e0386d51b0786.jpg",
                                    "description": "public/491df47b-5958-4dc7-9029-37f428755d45/32fe43d3-68f4-48fc-8f2d-461e1ddced53",
                                    "width": 5700,
                                    "height": 3800,
                                    "alt": "",
                                    "artist": {"id": "", "name": ""},
                                    "opacity": 0.8
                                },
                                "color": "#000000",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            }
                        },
                        "style": "mc1"
                    }
                }],
                "layout": {
                    "width": 980,
                    "height": 700,
                    "x": 0,
                    "y": 1980,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.StripColumnsContainer",
                "props": {
                    "type": "StripColumnsContainerProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "fullWidth": true,
                    "columnsMargin": 0,
                    "frameMargin": 0,
                    "rowMargin": 0,
                    "siteMargin": 0
                },
                "design": {
                    "type": "MediaContainerDesignData",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "background": {
                        "type": "BackgroundMedia",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "color": "#FFFFFF",
                        "colorOpacity": 0,
                        "alignType": "center",
                        "fittingType": "fill",
                        "colorOverlay": "",
                        "colorOverlayOpacity": 1
                    },
                    "charas": "design-iv0xalu2"
                },
                "style": {
                    "type": "TopLevelStyle",
                    "id": "strc1",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.viewer.components.StripColumnsContainer",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.viewer.skins.stripContainer.DefaultStripContainer"
                },
                "mobileStructure": {
                    "type": "Container",
                    "layout": {
                        "width": 320,
                        "height": 325,
                        "x": 0,
                        "y": 390,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.StripColumnsContainer",
                    "props": {
                        "type": "StripColumnsContainerProperties",
                        "metaData": {"schemaVersion": "1.0"},
                        "fullWidth": true,
                        "columnsMargin": 0,
                        "frameMargin": 0,
                        "rowMargin": 0,
                        "siteMargin": 0
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "color": "#FFFFFF",
                            "colorOpacity": 0,
                            "alignType": "center",
                            "fittingType": "fill",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 1
                        },
                        "charas": "design-iv0xalu1"
                    },
                    "style": "strc1"
                },
                "activeModes": {},
                "id": "comp-inyax42q"
            },
            "preset": {
                "rect": {"width": 324, "height": 180, "x": -0.8, "y": 376},
                "label": "add_preset_columns_welcome_3",
                "tags": null
            }
        }, {
            "id": "Columns_ColumnsWelcome_4",
            "structure": {
                "type": "Container",
                "components": [{
                    "type": "Container",
                    "components": [{
                        "type": "Component",
                        "layout": {
                            "width": 980,
                            "height": 71,
                            "x": 0,
                            "y": 207,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<h2 class=\"font_2\" style=\"font-size: 60px; line-height: 1.1em; text-align: center;\"><span style=\"font-size:60px;\"><span style=\"letter-spacing:0.1em\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\"><span style=\"color:#FFFFFF\">COMBINED EXPERIENCE</span></span></span></span></h2>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 280,
                                "height": 87,
                                "x": 20,
                                "y": 70,
                                "scale": 0.9974999999999999,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 class=\"font_2\" style=\"font-size: 60px; line-height: 1.1em; text-align: center;\"><span style=\"font-size:60px;\"><span style=\"letter-spacing:0.1em\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\"><span style=\"color:#FFFFFF\">COMBINED EXPERIENCE</span></span></span></span></h2>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 980,
                            "height": 39,
                            "x": 0,
                            "y": 287,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<p class=\"font_8\" style=\"font-size:28px; line-height:1.3em; text-align:center\"><span style=\"font-size:28px\"><span style=\"letter-spacing:0em\"><span style=\"color:#FFFFFF\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\">We have the know-how&nbsp;you need.</span></span></span></span></p>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 251,
                                "height": 53,
                                "x": 34,
                                "y": 176,
                                "scale": 0.7699168689433591,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p class=\"font_8\" style=\"font-size:28px; line-height:1.3em; text-align:center\"><span style=\"font-size:28px\"><span style=\"letter-spacing:0em\"><span style=\"color:#FFFFFF\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\">We have the know-how&nbsp;you need.</span></span></span></span></p>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "skin": "svgshape.v2.Svg_4f4fbf1f8b7146f69dafa4814a22e5f3",
                        "layout": {
                            "width": 43,
                            "height": 16,
                            "x": 469,
                            "y": 524,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.svgshape.SvgShape",
                        "data": {
                            "type": "SvgShape",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                        },
                        "props": {
                            "type": "SvgShapeProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "maintainAspectRatio": true
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "alpha-fillcolor": "1",
                                    "alpha-stroke": "1",
                                    "fillcolor": "#FFFFFE",
                                    "stroke": "#FFFFFF",
                                    "strokewidth": "0px"
                                },
                                "propertiesSource": {
                                    "fillcolor": "value",
                                    "stroke": "value",
                                    "strokewidth": "value"
                                }
                            },
                            "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "svgshape.v2.Svg_4f4fbf1f8b7146f69dafa4814a22e5f3"
                        },
                        "mobileStructure": {
                            "type": "Component",
                            "skin": "svgshape.v2.Svg_4f4fbf1f8b7146f69dafa4814a22e5f3",
                            "layout": {
                                "width": 43,
                                "height": 16,
                                "x": 138,
                                "y": 322,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.svgshape.SvgShape",
                            "data": {
                                "type": "SvgShape",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false}
                            },
                            "props": {
                                "type": "SvgShapeProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "maintainAspectRatio": true
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {
                                        "alpha-fillcolor": "1",
                                        "alpha-stroke": "1",
                                        "fillcolor": "#FFFFFE",
                                        "stroke": "#FFFFFF",
                                        "strokewidth": "0px"
                                    },
                                    "propertiesSource": {
                                        "fillcolor": "value",
                                        "stroke": "value",
                                        "strokewidth": "value"
                                    }
                                },
                                "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "svgshape.v2.Svg_4f4fbf1f8b7146f69dafa4814a22e5f3"
                            }
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 530,
                            "height": 28,
                            "x": 226,
                            "y": 471,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<p class=\"font_8\" style=\"font-size: 22px; line-height: 1.2em; text-align: center;\"><span style=\"letter-spacing:0.1em;\"><span style=\"font-family:didot-w01-italic,serif\"><span style=\"color:#FFFFFF\"><span style=\"font-size:22px\">Scroll Down</span></span></span></span></p>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 240,
                                "height": 24,
                                "x": 40,
                                "y": 282,
                                "scale": 0.9025,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p class=\"font_8\" style=\"font-size: 22px; line-height: 1.2em; text-align: center;\"><span style=\"letter-spacing:0.1em;\"><span style=\"font-family:didot-w01-italic,serif\"><span style=\"color:#FFFFFF\"><span style=\"font-size:22px\">Scroll Down</span></span></span></span></p>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 980,
                            "height": 47,
                            "x": 1,
                            "y": 139,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<h2 class=\"font_2\" style=\"font-size: 40px; line-height: 1.1em; text-align: center;\"><span style=\"font-size:40px;\"><span style=\"font-family:didot-w01-italic,serif\"><span style=\"letter-spacing:0.1em\"><span style=\"color:#FFFFFF\">50 years of</span></span></span></span></h2>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 269,
                                "height": 26,
                                "x": 26,
                                "y": 30,
                                "scale": 0.7350918906249998,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 class=\"font_2\" style=\"font-size: 40px; line-height: 1.1em; text-align: center;\"><span style=\"font-size:40px;\"><span style=\"font-family:didot-w01-italic,serif\"><span style=\"letter-spacing:0.1em\"><span style=\"color:#FFFFFF\">50 years of</span></span></span></span></h2>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }],
                    "layout": {
                        "width": 980,
                        "height": 650,
                        "x": 0,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.Column",
                    "props": {"type": "ColumnProperties", "alignment": 50},
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "mediaRef": {
                                "type": "WixVideo",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "title": "Modern Hotel",
                                "videoId": "video/11062b_44bb4a27212341119eefcb9e27e03b39",
                                "qualities": [{
                                    "quality": "480p",
                                    "width": 854,
                                    "height": 480,
                                    "formats": ["mp4", "webm"]
                                }, {
                                    "quality": "720p",
                                    "width": 1280,
                                    "height": 720,
                                    "formats": ["mp4", "webm"]
                                }, {"quality": "1080p", "width": 1920, "height": 1080, "formats": ["mp4", "webm"]}],
                                "posterImageRef": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "title": "",
                                    "uri": "11062b_44bb4a27212341119eefcb9e27e03b39f000.jpg",
                                    "description": "public/8e256233-1752-4026-9341-54036e7f875e/db9e3e42-59d2-461f-b589-6cbbf7c2c06e",
                                    "width": 1920,
                                    "height": 1080,
                                    "alt": ""
                                },
                                "opacity": 1,
                                "duration": 15,
                                "loop": true,
                                "autoplay": true,
                                "preload": "auto",
                                "mute": true,
                                "artist": {"id": "", "name": ""}
                            },
                            "color": "{color_11}",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "style": "mc1",
                    "mobileStructure": {
                        "type": "Container",
                        "layout": {
                            "width": 320,
                            "height": 396,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.Column",
                        "props": {"type": "ColumnProperties", "alignment": 50},
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "mediaRef": {
                                    "type": "WixVideo",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "title": "Modern Hotel",
                                    "videoId": "video/11062b_44bb4a27212341119eefcb9e27e03b39",
                                    "qualities": [{
                                        "quality": "480p",
                                        "width": 854,
                                        "height": 480,
                                        "formats": ["mp4", "webm"]
                                    }, {
                                        "quality": "720p",
                                        "width": 1280,
                                        "height": 720,
                                        "formats": ["mp4", "webm"]
                                    }, {
                                        "quality": "1080p",
                                        "width": 1920,
                                        "height": 1080,
                                        "formats": ["mp4", "webm"]
                                    }],
                                    "posterImageRef": {
                                        "type": "Image",
                                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                        "title": "",
                                        "uri": "11062b_44bb4a27212341119eefcb9e27e03b39f000.jpg",
                                        "description": "public/8e256233-1752-4026-9341-54036e7f875e/db9e3e42-59d2-461f-b589-6cbbf7c2c06e",
                                        "width": 1920,
                                        "height": 1080,
                                        "alt": ""
                                    },
                                    "opacity": 1,
                                    "duration": 15,
                                    "loop": true,
                                    "autoplay": true,
                                    "preload": "auto",
                                    "mute": true,
                                    "artist": {"id": "", "name": ""}
                                },
                                "color": "{color_11}",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            }
                        },
                        "style": "mc1"
                    }
                }],
                "layout": {
                    "width": 980,
                    "height": 650,
                    "x": 0,
                    "y": 2880,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.StripColumnsContainer",
                "props": {
                    "type": "StripColumnsContainerProperties",
                    "fullWidth": true,
                    "columnsMargin": 0,
                    "frameMargin": 0,
                    "rowMargin": 0,
                    "siteMargin": 0
                },
                "design": {
                    "type": "MediaContainerDesignData",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "background": {
                        "type": "BackgroundMedia",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "color": "#FFFFFF",
                        "colorOpacity": 0,
                        "alignType": "center",
                        "fittingType": "fill",
                        "scrollType": "none",
                        "colorOverlay": "",
                        "colorOverlayOpacity": 1
                    }
                },
                "style": {
                    "type": "TopLevelStyle",
                    "id": "strc1",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.viewer.components.StripColumnsContainer",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.viewer.skins.stripContainer.DefaultStripContainer"
                },
                "mobileStructure": {
                    "type": "Container",
                    "layout": {
                        "width": 320,
                        "height": 396,
                        "x": 0,
                        "y": 1754,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.StripColumnsContainer",
                    "props": {
                        "type": "StripColumnsContainerProperties",
                        "fullWidth": true,
                        "columnsMargin": 0,
                        "frameMargin": 0,
                        "rowMargin": 0,
                        "siteMargin": 0
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "color": "#FFFFFF",
                            "colorOpacity": 0,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 1
                        }
                    },
                    "style": "strc1"
                },
                "activeModes": {},
                "id": "comp-iolc5wyl"
            },
            "preset": {
                "rect": {"width": 324, "height": 170, "x": -0.8, "y": 556},
                "label": "add_preset_columns_welcome_4",
                "tags": null
            }
        }, {
            "id": "Columns_ColumnsWelcome_5",
            "structure": {
                "type": "Container",
                "components": [{
                    "type": "Container",
                    "components": [{
                        "type": "Component",
                        "layout": {
                            "width": 812,
                            "height": 77,
                            "x": 83,
                            "y": 213,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<h1 style=\"text-align: center; font-size: 64px;\" class=\"font_0\"><span style=\"font-size:64px;\"><span style=\"color:#FFFFFE;\"><span style=\"font-weight:normal;\"><span style=\"font-style:normal;\"><span style=\"font-family:cookie,cursive;\">Welcome to</span></span></span></span></span></h1>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 240,
                                "height": 46,
                                "x": 40,
                                "y": 42,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h1 style=\"text-align: center; font-size: 64px;\" class=\"font_0\"><span style=\"font-size:64px;\"><span style=\"color:#FFFFFE;\"><span style=\"font-weight:normal;\"><span style=\"font-style:normal;\"><span style=\"font-family:cookie,cursive;\">Welcome to</span></span></span></span></span></h1>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 969,
                            "height": 133,
                            "x": 6,
                            "y": 320,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<h1 style=\"text-align: center; font-size: 90px;\" class=\"font_0\"><span style=\"color:#FFFFFE;\"><span style=\"font-size:90px;\"><span style=\"letter-spacing:0.1em;\"><span style=\"font-weight:normal;\"><span style=\"font-style:normal;\"><span style=\"font-family:anton,sans-serif;\">THE NEXT BIG THING</span></span></span></span></span></span></h1>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 260,
                                "height": 140,
                                "x": 30,
                                "y": 118,
                                "scale": 0.9925187343749999,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h1 style=\"text-align: center; font-size: 90px;\" class=\"font_0\"><span style=\"color:#FFFFFE;\"><span style=\"font-size:90px;\"><span style=\"letter-spacing:0.1em;\"><span style=\"font-weight:normal;\"><span style=\"font-style:normal;\"><span style=\"font-family:anton,sans-serif;\">THE NEXT BIG THING</span></span></span></span></span></span></h1>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.line.SolidLine",
                        "layout": {
                            "width": 62,
                            "height": 5,
                            "x": 459,
                            "y": 307,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.FiveGridLine",
                        "props": {
                            "type": "FiveGridLineProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "fullScreenModeOn": false
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {"alpha-brd": "1", "brd": "#FFFFFE", "lnw": "4"},
                                "propertiesSource": {"brd": "value", "lnw": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.line.SolidLine"
                        },
                        "mobileStructure": {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.line.SolidLine",
                            "layout": {
                                "width": 62,
                                "height": 5,
                                "x": 129,
                                "y": 105,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.FiveGridLine",
                            "props": {
                                "type": "FiveGridLineProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "fullScreenModeOn": false
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {"alpha-brd": "1", "brd": "#FFFFFE", "lnw": "4"},
                                    "propertiesSource": {"brd": "value", "lnw": "value"}
                                },
                                "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.line.SolidLine"
                            }
                        }
                    }],
                    "layout": {
                        "width": 980,
                        "height": 650,
                        "x": 0,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.Column",
                    "props": {"type": "ColumnProperties", "alignment": 50},
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "mediaRef": {
                                "type": "WixVideo",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "title": "Spinning Record",
                                "videoId": "video/11062b_0f1e951461494c1894d86c9ab85a11cf",
                                "qualities": [{
                                    "quality": "480p",
                                    "width": 854,
                                    "height": 480,
                                    "formats": ["mp4", "webm"]
                                }, {
                                    "quality": "720p",
                                    "width": 1280,
                                    "height": 720,
                                    "formats": ["mp4", "webm"]
                                }, {"quality": "1080p", "width": 1920, "height": 1080, "formats": ["mp4", "webm"]}],
                                "posterImageRef": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "title": "",
                                    "uri": "11062b_0f1e951461494c1894d86c9ab85a11cff000.jpg",
                                    "description": "public/8e256233-1752-4026-9341-54036e7f875e/305292cb-8a7a-4825-87ca-52882fbdf8ff",
                                    "width": 1920,
                                    "height": 1080,
                                    "alt": ""
                                },
                                "opacity": 1,
                                "duration": 10,
                                "loop": true,
                                "autoplay": true,
                                "preload": "auto",
                                "mute": true,
                                "artist": {"id": "mobile_v560888", "name": "clipcanvas"}
                            },
                            "color": "{color_33}",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "#360C0C",
                            "colorOverlayOpacity": 0.35
                        }
                    },
                    "style": "mc1",
                    "mobileStructure": {
                        "type": "Container",
                        "layout": {
                            "width": 320,
                            "height": 291,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.Column",
                        "props": {"type": "ColumnProperties", "alignment": 50},
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "mediaRef": {
                                    "type": "WixVideo",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "title": "Spinning Record",
                                    "videoId": "video/11062b_0f1e951461494c1894d86c9ab85a11cf",
                                    "qualities": [{
                                        "quality": "480p",
                                        "width": 854,
                                        "height": 480,
                                        "formats": ["mp4", "webm"]
                                    }, {
                                        "quality": "720p",
                                        "width": 1280,
                                        "height": 720,
                                        "formats": ["mp4", "webm"]
                                    }, {
                                        "quality": "1080p",
                                        "width": 1920,
                                        "height": 1080,
                                        "formats": ["mp4", "webm"]
                                    }],
                                    "posterImageRef": {
                                        "type": "Image",
                                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                        "title": "",
                                        "uri": "11062b_0f1e951461494c1894d86c9ab85a11cff000.jpg",
                                        "description": "public/8e256233-1752-4026-9341-54036e7f875e/305292cb-8a7a-4825-87ca-52882fbdf8ff",
                                        "width": 1920,
                                        "height": 1080,
                                        "alt": ""
                                    },
                                    "opacity": 1,
                                    "duration": 10,
                                    "loop": true,
                                    "autoplay": true,
                                    "preload": "auto",
                                    "mute": true,
                                    "artist": {"id": "v560888", "name": "clipcanvas"},
                                    "playbackSpeed": 1
                                },
                                "color": "{color_33}",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "#360C0C",
                                "colorOverlayOpacity": 0.4
                            }
                        },
                        "style": "mc1"
                    }
                }],
                "layout": {
                    "width": 980,
                    "height": 650,
                    "x": 0,
                    "y": 3730,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.StripColumnsContainer",
                "props": {
                    "type": "StripColumnsContainerProperties",
                    "fullWidth": true,
                    "columnsMargin": 0,
                    "frameMargin": 0,
                    "rowMargin": 0,
                    "siteMargin": 0
                },
                "design": {
                    "type": "MediaContainerDesignData",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "background": {
                        "type": "BackgroundMedia",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "color": "#FFFFFF",
                        "colorOpacity": 0,
                        "alignType": "center",
                        "fittingType": "fill",
                        "scrollType": "none",
                        "colorOverlay": "",
                        "colorOverlayOpacity": 1
                    }
                },
                "style": {
                    "type": "TopLevelStyle",
                    "id": "strc1",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.viewer.components.StripColumnsContainer",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.viewer.skins.stripContainer.DefaultStripContainer"
                },
                "mobileStructure": {
                    "type": "Container",
                    "layout": {
                        "width": 320,
                        "height": 291,
                        "x": 0,
                        "y": 1048,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.StripColumnsContainer",
                    "props": {
                        "type": "StripColumnsContainerProperties",
                        "fullWidth": true,
                        "columnsMargin": 0,
                        "frameMargin": 0,
                        "rowMargin": 0,
                        "siteMargin": 0
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "color": "#FFFFFF",
                            "colorOpacity": 0,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 1
                        }
                    },
                    "style": "strc1"
                },
                "activeModes": {},
                "id": "comp-iolc4507"
            },
            "preset": {
                "rect": {"width": 324, "height": 170, "x": -0.8, "y": 726},
                "label": "add_preset_columns_welcome_5",
                "tags": null
            }
        }, {
            "id": "Columns_ColumnsWelcome_6",
            "structure": {
                "type": "Container",
                "components": [{
                    "type": "Container",
                    "components": [{
                        "type": "Component",
                        "layout": {
                            "width": 980,
                            "height": 210,
                            "x": 0,
                            "y": 241,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<h2 class=\"font_2\" style=\"font-size:164px; line-height:1.2em; text-align:center\"><span style=\"color:#FFFFFF\"><span style=\"text-shadow:rgba(0, 0, 0, 0.4) 0px 4px 5px\"><span style=\"font-size:164px\"><span style=\"font-family:trend-sans-w00-four,sans-serif\">JOINT</span></span></span></span></h2>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 300,
                                "height": 98,
                                "x": 10,
                                "y": 176,
                                "scale": 1.5474498954385698,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 class=\"font_2\" style=\"font-size:164px; line-height:1.2em; text-align:center\"><span style=\"color:#FFFFFF\"><span style=\"text-shadow:rgba(0, 0, 0, 0.4) 0px 4px 5px\"><span style=\"font-size:164px\"><span style=\"font-family:trend-sans-w00-four,sans-serif\">JOINT</span></span></span></span></h2>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 980,
                            "height": 113,
                            "x": 0,
                            "y": 149,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<h2 class=\"font_2\" style=\"font-size:90px; line-height:1.2em; text-align:center\"><span style=\"font-size:90px\"><span style=\"text-shadow:rgba(0, 0, 0, 0.4) 0px 4px 5px\"><span style=\"font-family:niconne,fantasy\"><span style=\"font-style:italic\"><span style=\"color:#FFFCF5\">The</span></span></span></span></span></h2>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 162,
                                "height": 59,
                                "x": 79,
                                "y": 117,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 class=\"font_2\" style=\"font-size:90px; line-height:1.2em; text-align:center\"><span style=\"font-size:90px\"><span style=\"text-shadow:rgba(0, 0, 0, 0.4) 0px 4px 5px\"><span style=\"font-family:niconne,fantasy\"><span style=\"font-style:italic\"><span style=\"color:#FFFCF5\">The</span></span></span></span></span></h2>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }],
                    "layout": {
                        "width": 980,
                        "height": 600,
                        "x": 0,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.Column",
                    "props": {"type": "ColumnProperties", "metaData": {"schemaVersion": "1.0"}, "alignment": 50},
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "mediaRef": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "title": "July Forth Celebrations",
                                "uri": "5e00793450304441823b51d4d04d7e55.jpg",
                                "description": "public/491df47b-5958-4dc7-9029-37f428755d45/edfd0ae7-86e6-4adb-9332-7697307e9f1b",
                                "width": 4638,
                                "height": 3168,
                                "alt": "",
                                "artist": {
                                    "id": "mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_",
                                    "name": ""
                                },
                                "opacity": 0.66
                            },
                            "color": "#000000",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "style": "mc1",
                    "mobileStructure": {
                        "type": "Container",
                        "layout": {
                            "width": 320,
                            "height": 280,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.Column",
                        "props": {
                            "type": "ColumnProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "alignment": 50
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "mediaRef": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "title": "July Forth Celebrations",
                                    "uri": "5e00793450304441823b51d4d04d7e55.jpg",
                                    "description": "public/491df47b-5958-4dc7-9029-37f428755d45/edfd0ae7-86e6-4adb-9332-7697307e9f1b",
                                    "width": 4638,
                                    "height": 3168,
                                    "alt": "",
                                    "artist": {
                                        "id": "mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_",
                                        "name": ""
                                    },
                                    "opacity": 0.66
                                },
                                "color": "#000000",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            }
                        },
                        "style": "mc1"
                    }
                }],
                "layout": {
                    "width": 980,
                    "height": 600,
                    "x": 0,
                    "y": 4580,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.StripColumnsContainer",
                "props": {
                    "type": "StripColumnsContainerProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "fullWidth": true,
                    "columnsMargin": 0,
                    "frameMargin": 0,
                    "rowMargin": 0,
                    "siteMargin": 0
                },
                "design": {
                    "type": "MediaContainerDesignData",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "background": {
                        "type": "BackgroundMedia",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "color": "#FFFFFF",
                        "colorOpacity": 0,
                        "alignType": "center",
                        "fittingType": "fill",
                        "colorOverlay": "",
                        "colorOverlayOpacity": 1
                    },
                    "charas": "design-iv0xbpwt2"
                },
                "style": {
                    "type": "TopLevelStyle",
                    "id": "strc1",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.viewer.components.StripColumnsContainer",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.viewer.skins.stripContainer.DefaultStripContainer"
                },
                "mobileStructure": {
                    "type": "Container",
                    "layout": {
                        "width": 320,
                        "height": 280,
                        "x": 0,
                        "y": 727,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.StripColumnsContainer",
                    "props": {
                        "type": "StripColumnsContainerProperties",
                        "metaData": {"schemaVersion": "1.0"},
                        "fullWidth": true,
                        "columnsMargin": 0,
                        "frameMargin": 0,
                        "rowMargin": 0,
                        "siteMargin": 0
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "color": "#FFFFFF",
                            "colorOpacity": 0,
                            "alignType": "center",
                            "fittingType": "fill",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 1
                        },
                        "charas": "design-iv0xbpwt"
                    },
                    "style": "strc1"
                },
                "activeModes": {},
                "id": "comp-inybwoli"
            },
            "preset": {
                "rect": {"width": 324, "height": 160, "x": -0.8, "y": 896},
                "label": "add_preset_columns_welcome_6",
                "tags": null
            }
        }],
        "compTypes": ["wysiwyg.viewer.components.StripColumnsContainer"]
    },
    "help": {"hide": false, "text": "add_section_info_text_column"}
}
