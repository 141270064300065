import React, { useCallback, useRef } from 'react';
import _ from 'lodash';
import { Divider, RadioButtons, RichText, TextLabel } from '@wix/wix-base-ui';

import { frames } from '@/panels';
import { translate } from '@/i18n';
import { hoc, editorWixRecorder } from '@/util';
import * as stateManagement from '@/stateManagement';

import { TRANSLATIONS_MAP } from './translations';
import { AUTOMATION_IDS } from './automationIds';
import { HeaderCheckboxValues } from '../MenuManagePanel/Header/Header';
import { withMenuBi } from '../../bi/MenuBi';
import { cleanId, isMainMenu as checkIsMainMenu } from '../../utils/utils';

import type { StateMapperArgs } from 'types/redux';
import type { CompData, CompRef } from 'types/documentServices';
import type { EditorAPI } from '@/editorAPI';

import styles from './MenuSetsRadioPanel.scss';
import { createMenuApi } from '../../API/menuAPI';
import { menuBiLogger } from '../../bi/menuBiLogger';

const {
  connect,
  STORES: { EDITOR_API },
} = hoc;

const { ToolPanelFrame } = frames;

const { getData: getSelectedComponentData } =
  stateManagement.components.selectors;

interface IOption {
  value: HeaderCheckboxValues;
  label: string;
  description: string;
  automationId: string;
}

const LABELS = {
  DESKTOP: {
    MAIN: {
      LABEL: translate(TRANSLATIONS_MAP.OPTIONS.main),
      DESCRIPTION: translate(TRANSLATIONS_MAP.OPTIONS.mainDescription),
    },
    CUSTOM: {
      LABEL: translate(TRANSLATIONS_MAP.OPTIONS.custom),
      DESCRIPTION: translate(TRANSLATIONS_MAP.OPTIONS.customDescription),
    },
  },
  MOBILE: {
    MAIN: {
      LABEL: translate(TRANSLATIONS_MAP.MOBILE_OPTIONS.main),
      DESCRIPTION: translate(TRANSLATIONS_MAP.MOBILE_OPTIONS.mainDescription),
    },
    CUSTOM: {
      LABEL: translate(TRANSLATIONS_MAP.MOBILE_OPTIONS.custom),
      DESCRIPTION: translate(TRANSLATIONS_MAP.MOBILE_OPTIONS.customDescription),
    },
  },
} as const;

const getOptions = (isMobile: boolean) => {
  const labels = isMobile ? LABELS.MOBILE : LABELS.DESKTOP;
  return [
    {
      value: HeaderCheckboxValues.Main,
      label: labels.MAIN.LABEL,
      description: labels.MAIN.DESCRIPTION,
      automationId: AUTOMATION_IDS.RADIO_BUTTONS.radioButtonMain,
    },
    {
      value: HeaderCheckboxValues.Custom,
      label: labels.CUSTOM.LABEL,
      description: labels.CUSTOM.DESCRIPTION,
      automationId: AUTOMATION_IDS.RADIO_BUTTONS.radioButtonCustom,
    },
  ];
};

const template = ({ label, description, automationId }: IOption) => {
  return (
    <div className={styles.radio} data-hook={automationId}>
      <TextLabel
        value={label}
        type="T07"
        shouldTranslate={false}
        className={styles.radio__label}
      />
      <RichText type="T02" className={styles.radio__description}>
        <p>{description}</p>
      </RichText>
    </div>
  );
};

interface IOwnProps {
  panelName: string;
  top: string;
  left: string;
  selectedComponents: CompRef[];
}

type IStoreProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = ReturnType<typeof mapDispatchToProps>;

interface IMenuSetsRadioPanelProps
  extends IOwnProps,
    IStoreProps,
    IDispatchProps {}

export const MenuSetsRadioPanelComponent: React.FC<
  IMenuSetsRadioPanelProps
> = ({
  panelName,
  top,
  left,
  attachedMenuId,
  isMainMenu,
  mainMenuId,
  createCustomMenu,
  attachMenu,
  removeMenu,
  value,
  options,
}) => {
  const customMenuIdRef = useRef(isMainMenu ? null : attachedMenuId);

  const handlePanelClose = () => {
    if (isMainMenu && customMenuIdRef.current) {
      removeMenu(customMenuIdRef.current);
    }
  };

  const handleCheckBoxClick = useCallback(
    (value: HeaderCheckboxValues) => {
      editorWixRecorder.addLabel('custom_menus_radioButton_click');

      if (value === HeaderCheckboxValues.Main) {
        menuBiLogger.logMenuSetSelected(mainMenuId);
        attachMenu(mainMenuId);
        return;
      }

      if (!customMenuIdRef.current) {
        customMenuIdRef.current = createCustomMenu();
      }

      menuBiLogger.logMenuSetSelected(customMenuIdRef.current);
      attachMenu(customMenuIdRef.current);
    },
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [customMenuIdRef.current],
  );
  return (
    <ToolPanelFrame
      panelName={panelName}
      contentStyle={{
        top,
        left,
      }}
      lightHeader
      isBlockingLayerFullScreen={false}
      shouldBlockOuterScroll={false}
      headerTitle={translate(TRANSLATIONS_MAP.panelTitle)}
      automationId={AUTOMATION_IDS.PANEL}
      contentWrapperClass={styles.root}
      onClose={handlePanelClose}
    >
      <Divider long />
      <div className={styles.content}>
        <RadioButtons
          // @ts-expect-error
          labelTemplate={template}
          value={value}
          automationId={AUTOMATION_IDS.RADIO_BUTTONS.container}
          options={options}
          onChange={handleCheckBoxClick}
        />
      </div>
    </ToolPanelFrame>
  );
};
const mapStateToProps = (
  { editorAPI }: StateMapperArgs,
  ownProps: IOwnProps,
) => {
  const { selectedComponents } = ownProps;
  const menuAPI = createMenuApi(editorAPI);

  const compData: CompData = getSelectedComponentData(
    selectedComponents,
    editorAPI.dsRead,
  );
  const attachedMenuId = cleanId(compData.menuRef);
  const attachedMenu = menuAPI.getMenu(attachedMenuId);

  const isMainMenu = checkIsMainMenu(attachedMenu);
  const mainMenuId = menuAPI.getMainMenu()?.id;
  const value = isMainMenu
    ? HeaderCheckboxValues.Main
    : HeaderCheckboxValues.Custom;

  const isMobile = editorAPI.isMobileEditor();

  const options = getOptions(isMobile);

  return { attachedMenuId, isMainMenu, mainMenuId, value, options };
};

const mapDispatchToProps = (dispatch: AnyFixMe, ownProps: IOwnProps) => {
  const { selectedComponents } = ownProps;
  const [compRef] = selectedComponents;

  const getEditorAPI = (
    dispatch: AnyFixMe,
    getState: AnyFixMe,
    { editorAPI }: AnyFixMe,
  ) => editorAPI;

  const editorAPI: EditorAPI = dispatch(getEditorAPI);
  const menuAPI = createMenuApi(editorAPI);

  const { createCustomMenu } = menuAPI;
  const attachMenu = (menuId: string) => menuAPI.connect(menuId, compRef);
  const { removeMenu } = menuAPI;

  return { createCustomMenu, attachMenu, removeMenu };
};

export const MenuSetsRadioPanel = _.flow(
  connect(EDITOR_API, mapStateToProps, mapDispatchToProps),
  withMenuBi,
)(MenuSetsRadioPanelComponent);
