import type { PagesState } from './pagesReducer';

const getLoadingPageId = ({ pages }: { pages: PagesState }) =>
  pages.loadingPageId;

const getLoadingTrigger = ({ pages }: { pages: PagesState }) =>
  pages.loadingTrigger;

export const pagesSelectors = {
  getLoadingPageId,
  getLoadingTrigger,
};
