// @ts-nocheck
import React from 'react';
import _ from 'lodash';
import createReactClass from 'create-react-class';
import * as core from '@/core';
import * as bi from '../bi/bi';
import constants from '@/constants';
import * as tpaUtils from '../utils/tpaUtils';
import withOnCloseMessage from '../common/withOnCloseMessage';
import * as panels from '@/panels';
import * as util from '@/util';

function template() {
  return (
    <panels.frames.FocusPanelFrame
      title={this.props.title}
      shouldHideHeader={this.shouldHideHeader()}
      panelName="tpa.compModals.tpaSettingsModal"
      width={this.getPanelWidth()}
      height={this.getPanelHeight()}
      onHelpClicked={this.helpClicked}
      isBareMode={this.props.isBareMode}
      containerBackground={this.props.options?.background}
      overlayBackground={this.props.options?.overlay}
      shouldNotCloseOnBlur={
        this.props.isBareMode || this.props.shouldNotCloseOnBlur
      }
      className={this.getClassName()}
    >
      {this.getChildren()}
    </panels.frames.FocusPanelFrame>
  );
}

const PANEL_HEADER_HEIGHT = 54;

// eslint-disable-next-line react/prefer-es6-class
const tpaSettingsModal = createReactClass({
  mixins: [core.mixins.editorAPIMixin],
  displayName: 'settingsModal',
  getInitialState() {
    this.helpId = '';
    return {};
  },
  modalShouldNotHaveMargin() {
    return this.props.options?.margin === false;
  },
  getPanelHeight() {
    const containerHeight = tpaUtils.getWindow().innerHeight;
    let height = this.parsePercentToPixel(this.props.height, containerHeight);
    if (this.props.title) {
      height += PANEL_HEADER_HEIGHT;
    }

    let minimalGap = containerHeight * 0.05;
    if (this.modalShouldNotHaveMargin()) {
      minimalGap = util.topBar.getHeightConst();
    }
    return this.resizeToFitContainer(
      height,
      containerHeight,
      minimalGap,
    ).toString();
  },
  getPanelWidth() {
    const containerWidth = tpaUtils.getWindow().innerWidth;
    const width = this.parsePercentToPixel(this.props.width, containerWidth);
    let minimalGap = containerWidth * 0.02;
    if (this.modalShouldNotHaveMargin()) {
      minimalGap = 0;
    }
    return this.resizeToFitContainer(
      width,
      containerWidth,
      minimalGap,
    ).toString();
  },
  getClassName() {
    const className = _.template('tpa-settings-modal<%= c %>');

    if (this.modalShouldNotHaveMargin()) {
      return className({ c: ' no-margin' });
    } else if (this.props.isBareMode) {
      return 'tpa-settings-modal bare';
    }

    return className({ c: '' });
  },
  parsePercentToPixel(value, containerSize) {
    const isPercent = _.isString(value) && /^[0-9]+%$/.test(value);
    if (isPercent) {
      value = (containerSize * parseInt(value.replace('%', ''), 10)) / 100;
    } else if (!_.isNumber(value)) {
      value = parseInt(value.replace('px', ''), 10);
    }
    return value;
  },
  resizeToFitContainer(value, containerSize, minimalGap) {
    const maxSize = containerSize - minimalGap;
    if (value > maxSize) {
      return maxSize;
    }
    return value;
  },
  shouldHideHeader() {
    return this.props.isBareMode;
  },
  helpClicked() {
    const editorAPI = this.getEditorAPI();

    const appDefId = this.props.appDefinitionId;
    const helpId = this.helpId || this.props.helpId;
    const { widgetId } = this.props;

    const biEvent = bi.events.TPA_HELP_CLICK;
    const biParams = {
      widget_id: widgetId ? widgetId : null,
      app_id: appDefId,
      origin: 'settings_modal',
    };

    const helpPanelProps = {
      openPanelBiEvent: biEvent,
      openPanelBiParams: _.merge({ help_status: 'open' }, biParams),
      closePanelBiEvent: biEvent,
      closePanelBiParams: _.merge({ help_status: 'close' }, biParams),
      origin: biParams.origin,
    };

    const biHelpParams = {
      panel_name: this.props.panelName,
      origin: constants.BI.HELP.ORIGIN.PANEL,
      component: editorAPI.selection.getSelectedComponentType(),
      learn_more: false,
    };
    editorAPI.panelManager.openHelpCenter(helpId, helpPanelProps, biHelpParams);
  },
  getChildren() {
    return (
      this.props.children ||
      React.createElement('iframe', {
        id: 'settingsModalIframe',
        src: this.props.url,
        style: {
          width: '100%',
          height: '100%',
          border: '0px',
        },
      })
    );
  },
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.helpId = nextProps?.articleId;
  },
  UNSAFE_componentWillMount() {
    const editorAPI = this.getEditorAPI();
    editorAPI.account.openSiteSettingsBranchWarningIfNeeded({
      dashboardUrl: this.props.url,
    });
  },
  render: template,
});

export default withOnCloseMessage(tpaSettingsModal);
