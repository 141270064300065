import _ from 'lodash';
import { EditorAPIKey, SectionsApiKey } from '@/apis';
import constants from '@/constants';
import { designData } from '@/util';
import * as util from '@/util';
import type { EditorAPI } from '@/editorAPI';
import * as coreBi from '@/coreBi';
const columnsEvents = coreBi.events.columns;
import { components, interactions } from '@/stateManagement';
//eslint-disable-next-line @wix/santa-editor/scoped-imports
import * as serializedComponentPlugins from '@/rEditor/app/serializedComponentPlugins';
import experiment from 'experiment';

import type { CompLayoutUpdate, CompRef, Rect } from 'types/documentServices';
import type { Shell } from '@/apilib';
import type { ComponentsAddResult } from '@/components';

const shouldDisableColumnChildrenAutomaticResize = () =>
  experiment.isOpen('se_stopColumnChildrenResize');

const componentsSelectors = components.selectors;

const { hasDividersDesign } = designData;

const { transferStripInteractionToSingleColumn } = interactions.actions;

const { isInteractionModeAvailable } = interactions.selectors;

export function createColumnsApi(shell: Shell) {
  const editorAPI = shell.getAPI(EditorAPIKey);
  const sectionsAPI = shell.getAPI(SectionsApiKey);
  const MANAGE_COLUMNS_PANEL_NAME =
    'compPanels.panels.StripColumnsContainer.managePanel';
  const MAX_COLUMNS = 5;
  const FIVE_COLUMNS_PROPORTIONS = Array(MAX_COLUMNS).fill(1);

  const ALIGNMENT = {
    LEFT: 0,
    CENTER: 50,
    RIGHT: 100,
  };

  const COMPS_THAT_HANDLE_CHILDREN_RESIZE = {
    BoxSlideShow: true,
    StripContainerSlideShow: true,
  };

  const NON_DUPLICATEABLE_COMPONENT_TYPES = new Set([
    constants.COMP_TYPES.STRIP_COLUMNS_CONTAINER,
    constants.COMP_TYPES.TPA_WIDGET,
    constants.COMP_TYPES.TPA_MULTI_SECTION,
    constants.COMP_TYPES.TPA_SECTION,
    constants.COMP_TYPES.APP_WIDGET,
  ]);

  let isDuplicateLocked = false;

  const initialColumnChildrenLayoutsMap = new Map<string, Rect>();
  let initialProportions: number[] = [];

  function setInitialColumnChildrenLayouts(columnsContainerPointer: CompRef) {
    if (!shouldDisableColumnChildrenAutomaticResize()) {
      return;
    }

    initialProportions = getProportions(columnsContainerPointer);

    const columnsDescendants = editorAPI.components
      .getChildren(columnsContainerPointer)
      .map((column) => {
        initialColumnChildrenLayoutsMap.set(
          column.id,
          editorAPI.components.layout.get_rect(column),
        );
        return column;
      })
      .flatMap((child) => getCompDescendants(child));
    columnsDescendants.forEach((descendant) => {
      initialColumnChildrenLayoutsMap.set(
        descendant.id,
        editorAPI.components.layout.get_rect(descendant),
      );
    });
  }

  function clearInitialColumnChildrenLayouts() {
    initialColumnChildrenLayoutsMap.clear();
  }

  function removeFullWidthChildrenFromStructure(compStructure: AnyFixMe) {
    compStructure.components = _.reject(
      compStructure.components,
      editorAPI.components.is.fullWidthByStructure,
    );

    compStructure.components.forEach(removeFullWidthChildrenFromStructure);

    return compStructure;
  }

  function removeNonDuplicatableComponentsFromStructure(
    compStructure: AnyFixMe,
  ) {
    compStructure.components = compStructure.components.filter(
      (compStructure: AnyFixMe) =>
        !NON_DUPLICATEABLE_COMPONENT_TYPES.has(compStructure.componentType),
    );

    compStructure.components.forEach(
      removeNonDuplicatableComponentsFromStructure,
    );

    return compStructure;
  }

  function toPercentages(array: number[]) {
    const sum = _.sum(array);
    return array.map((item) => (100 * item) / sum);
  }

  function getColumnsWidthProportionsMap(columnsContainerPtr: CompRef) {
    const columns = editorAPI.components.getChildren(columnsContainerPtr);

    const res = _(columns)
      .keyBy('id')
      .mapValues(editorAPI.components.layout.get)
      .mapValues('width')
      .value();

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/reduce
    const arrSum = _.reduce(res, (acc, val) => acc + val, 0);

    return _.mapValues(res, (v) => Math.round((100 * v) / arrSum));
  }

  function get980Proportions(proportionItem: AnyFixMe) {
    const total = _.sum(proportionItem);
    const chunk = 980 / total;
    return proportionItem.map((proportion: AnyFixMe) => proportion * chunk);
  }

  function setProportions(
    columnsContainerPointer: AnyFixMe,
    proportionItem: AnyFixMe,
    dontAddToUndoStack?: boolean,
  ) {
    const previousProportion = getProportions(columnsContainerPointer);
    const children = editorAPI.components.getChildren(columnsContainerPointer);
    const dontAddToUndoRedoStack = true;
    proportionItem = get980Proportions(proportionItem);
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
    _.forEach(children, function (child, index) {
      editorAPI.components.layout.update(
        child,
        { width: proportionItem[index] },
        dontAddToUndoRedoStack,
      );
    });
    const currentProportions = toPercentages(proportionItem);
    setChildrenLayoutAccordingToProportion(
      children,
      previousProportion,
      currentProportions,
    );

    if (!dontAddToUndoStack) {
      editorAPI.history.add('Change Columns Proportions');
    }
  }

  function moveToIndex(
    compPointer: AnyFixMe,
    index: AnyFixMe,
    dontAddToUndoRedoStack: AnyFixMe,
  ) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/is-undefined
    if (!_.isUndefined(index)) {
      editorAPI.components.arrangement.moveToIndex(compPointer, index, {
        dontAddToUndoRedoStack,
      });
    }
  }

  function getProportions(columnsContainerPointer: AnyFixMe) {
    const children = editorAPI.components.getChildren(columnsContainerPointer);
    const widths = children.map(
      (child: AnyFixMe) => editorAPI.components.layout.get_size(child).width,
    );

    return toPercentages(widths);
  }

  function resetProportionsIfNeeded(
    columnsContainerPointer: AnyFixMe,
    dontAddToUndoStack: AnyFixMe,
  ) {
    if (
      editorAPI.components.getChildren(columnsContainerPointer).length ===
      MAX_COLUMNS
    ) {
      setProportions(
        columnsContainerPointer,
        FIVE_COLUMNS_PROPORTIONS,
        dontAddToUndoStack,
      );
    }
  }

  function flipProportions(columnsContainerPointer: AnyFixMe) {
    const currentProportions = getProportions(columnsContainerPointer);
    const reveresProportions = currentProportions.reverse();
    setProportions(columnsContainerPointer, reveresProportions);
  }

  function duplicateColumn(columnPointer: CompRef, origin: string) {
    const serializeWithOriginalLanguage = _.partialRight(
      editorAPI.components.serialize,
      null,
      null,
      null,
      null,
      null,
      true,
    );

    let columnStructure = serializeWithOriginalLanguage(columnPointer);

    columnStructure = removeFullWidthChildrenFromStructure(columnStructure);
    columnStructure =
      removeNonDuplicatableComponentsFromStructure(columnStructure);

    const columnsContainerPointer =
      editorAPI.components.getContainer(columnPointer);
    const columns = editorAPI.components.getChildren(columnsContainerPointer);

    if (columns.length === MAX_COLUMNS || isDuplicateLocked) {
      return;
    }

    isDuplicateLocked = true;

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/find-index
    const index = _.findIndex(columns, columnPointer) + 1;
    const previousProportion = getProportions(columnsContainerPointer);
    previousProportion.splice(index, 0, previousProportion[index - 1]);
    const currentProportion = toPercentages(previousProportion);

    columnStructure =
      serializedComponentPlugins.runCopyPluginOnSerializedComponent(
        columnStructure,
        columnPointer,
        editorAPI,
      );
    columnStructure =
      serializedComponentPlugins.runPastePluginOnSerializedComponent(
        columnStructure,
        editorAPI,
      );

    const compRefOrAddResult: ComponentsAddResult = editorAPI.components.add(
      columnsContainerPointer,
      columnStructure,
    );

    const onAppliedInner = async (duplicatedRef: CompRef) => {
      const dontAddToUndoRedoStack = true;
      moveToIndex(duplicatedRef, index, dontAddToUndoRedoStack);
      resetProportionsIfNeeded(columnsContainerPointer, true);

      const columnsAfterAdd = editorAPI.components.getChildren(
        columnsContainerPointer,
      );

      setChildrenLayoutAccordingToProportion(
        columnsAfterAdd,
        previousProportion,
        currentProportion,
      );
      isDuplicateLocked = false;

      await editorAPI.dsActions.waitForChangesAppliedAsync();

      editorAPI.history.add('added column', { isAddingComponent: true });
      sendAddColumnBI(editorAPI, duplicatedRef, origin);

      return duplicatedRef;
    };

    if (compRefOrAddResult && 'hooks' in compRefOrAddResult) {
      const { hooks } = compRefOrAddResult;

      return {
        hooks: {
          waitForCompRef: hooks.waitForCompRef,
          waitForChangesApplied:
            hooks.waitForChangesApplied.then(onAppliedInner),
        },
      };
    }

    editorAPI.dsActions.waitForChangesApplied(() => {
      onAppliedInner(compRefOrAddResult as CompRef);
    });

    return compRefOrAddResult as CompRef;
  }

  function addColumn(columnsContainerPointer: CompRef, origin: string) {
    const columns = editorAPI.components.getChildren(columnsContainerPointer);
    const lastColumn = columns[columns.length - 1];

    if (columns.length === MAX_COLUMNS) {
      return;
    }

    return duplicateColumn(lastColumn, origin);
  }

  function setColumnAlignment(columnPointer: CompRef, alignment: AnyFixMe) {
    editorAPI.components.properties.update(columnPointer, { alignment });
  }

  function getColumnAlignment(columnPointer: CompRef) {
    return editorAPI.components.properties.get(columnPointer).alignment;
  }

  function moveLeft(columnPointer: CompRef) {
    editorAPI.components.arrangement.moveBackward(columnPointer);
  }

  function moveRight(columnPointer: CompRef) {
    editorAPI.components.arrangement.moveForward(columnPointer);
  }

  function canAdd(columnsContainerPointer: CompRef) {
    return (
      editorAPI.components.getChildren(columnsContainerPointer).length <
      MAX_COLUMNS
    );
  }

  function isCompHandlingItsOwnChildrenResize(comp: CompRef) {
    const compType = componentsSelectors.getCompTypeSuffix(
      comp,
      editorAPI.dsRead,
    );
    return !!COMPS_THAT_HANDLE_CHILDREN_RESIZE[
      compType as keyof typeof COMPS_THAT_HANDLE_CHILDREN_RESIZE
    ];
  }

  function getCompDescendants(comp: CompRef): CompRef[] {
    const children =
      editorAPI.components.getChildren_DEPRECATED_BAD_PERFORMANCE(comp);

    return _(children)
      .reject(isCompHandlingItsOwnChildrenResize)
      .flatMap(getCompDescendants)
      .union(children)
      .value() as any as CompRef[];
  }

  function resizeCompVerticallyIfApplicable(
    columnRef: CompRef,
    compRef: CompRef,
    ratio: number,
  ) {
    const compParent = editorAPI.components.getContainerOrScopeOwner(compRef);
    const compIsDirectColumnChild = editorAPI.columns.isColumn(compParent);

    if (!compIsDirectColumnChild) {
      return;
    }

    const compLayoutBeforeResize = initialColumnChildrenLayoutsMap.get(
      compRef.id,
    );
    const columnLayoutBeforeResize = initialColumnChildrenLayoutsMap.get(
      columnRef.id,
    );

    const layoutChanges: CompLayoutUpdate = {
      width: compLayoutBeforeResize.width,
    };

    const newColumnWidth = columnLayoutBeforeResize.width * ratio;
    const columnWidthsDiff = newColumnWidth - columnLayoutBeforeResize.width;
    layoutChanges.x = compLayoutBeforeResize.x + columnWidthsDiff / 2;

    editorAPI.components.layout.update(compRef, layoutChanges, true);

    updateCompChildrenLayoutRecursively(compRef);
  }

  const updateCompChildrenLayoutRecursively = (compParent: CompRef) => {
    const compChildren = editorAPI.components.getChildren(compParent);
    if (compChildren.length) {
      compChildren.forEach((child) => {
        editorAPI.components.layout.update(
          child,
          initialColumnChildrenLayoutsMap.get(child.id),
        );
        updateCompChildrenLayoutRecursively(child);
      });
    }
  };

  function resizeCompVerticallyByRatio(
    columnLayoutRelativeToStructure: Rect,
    compRef: CompRef,
    ratio: number,
    alignment: number,
    column?: CompRef,
    ratioRelativeToInitialProportions?: number,
  ) {
    if (editorAPI.components.is.group(compRef)) {
      return;
    }
    const shouldKeepChildrenProportionsOnColumnResize =
      shouldDisableColumnChildrenAutomaticResize() &&
      column &&
      ratioRelativeToInitialProportions &&
      !!initialColumnChildrenLayoutsMap.size;
    if (shouldKeepChildrenProportionsOnColumnResize) {
      if (+ratioRelativeToInitialProportions.toFixed(1) >= 1) {
        resizeCompVerticallyIfApplicable(
          column,
          compRef,
          ratioRelativeToInitialProportions,
        );
        return;
      }
    }

    //TODO: consider margin and containers
    const compLayoutRelativeToStructure =
      editorAPI.components.layout.getRelativeToStructure(compRef);
    const compXRelativeToColumn =
      compLayoutRelativeToStructure.x - columnLayoutRelativeToStructure.x;
    const originalWidth = compLayoutRelativeToStructure.width;
    const isResizable = editorAPI.components.is.resizable(compRef);

    const currentWidth = isResizable ? originalWidth * ratio : originalWidth;
    const layoutChanges: CompLayoutUpdate = {
      width: currentWidth,
    };

    if (alignment === ALIGNMENT.LEFT) {
      layoutChanges.x = compXRelativeToColumn * ratio;
    } else if (alignment === ALIGNMENT.CENTER) {
      const originalCenter = compXRelativeToColumn + originalWidth / 2;
      const currentCenter = originalCenter * ratio;
      layoutChanges.x = currentCenter - currentWidth / 2;
    } else {
      const originalRight = compXRelativeToColumn + originalWidth;
      const currentRight = originalRight * ratio;
      layoutChanges.x = currentRight - currentWidth;
    }

    layoutChanges.x += columnLayoutRelativeToStructure.x;

    const dontAddToUndoRedoStack = true;
    editorAPI.components.layout.updateRelativeToStructure(
      compRef,
      layoutChanges,
      dontAddToUndoRedoStack,
    );
  }

  function getColumnResizeRatio(
    currentProportion: AnyFixMe,
    previousProportion: AnyFixMe,
    index: AnyFixMe,
  ) {
    const curr = currentProportion[index];
    const prev = previousProportion[index];

    return curr && prev ? curr / prev : null;
  }

  function setChildrenLayoutAccordingToProportion(
    columns: AnyFixMe,
    previousProportion: AnyFixMe,
    currentProportion: AnyFixMe,
  ) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
    _.forEach(columns, function (column, index) {
      const resizeRatio = getColumnResizeRatio(
        currentProportion,
        previousProportion,
        index,
      );

      if (resizeRatio) {
        const columnDescendants = getCompDescendants(column);
        const { alignment } = editorAPI.components.properties.get(column);
        const columnLayoutRelativeToStructure =
          editorAPI.components.layout.getRelativeToStructure(column);
        const ratioRelativeToInitialProportions =
          initialProportions.length &&
          getColumnResizeRatio(currentProportion, initialProportions, index);

        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
        _.forEach(columnDescendants, function (descendant) {
          resizeCompVerticallyByRatio(
            columnLayoutRelativeToStructure,
            descendant,
            resizeRatio,
            alignment,
            column,
            ratioRelativeToInitialProportions,
          );
        });
      }
    });
  }

  function removeColumn(
    compPtr: AnyFixMe,
    allowRemoveMessage: AnyFixMe,
    originRemove: AnyFixMe,
  ) {
    const removeFunction =
      originRemove || _.partial(editorAPI.dsActions.components.remove, compPtr);
    const columnsContainerPointer = editorAPI.components.getContainer(compPtr);
    const columns = editorAPI.components.getChildren(columnsContainerPointer);

    const oldColumnsWidthProportionsMap = getColumnsWidthProportionsMap(
      columnsContainerPointer,
    );

    removeFunction();

    return new Promise<void>((resolve) => {
      editorAPI.dsActions.waitForChangesApplied(function () {
        const newColumnsWidthProportionsMap = getColumnsWidthProportionsMap(
          columnsContainerPointer,
        );
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
        _.forEach(
          newColumnsWidthProportionsMap,
          function (widthProportion, columnId) {
            const resizeRatio =
              widthProportion / oldColumnsWidthProportionsMap[columnId];
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line you-dont-need-lodash-underscore/find
            const column = _.find(columns, { id: columnId });

            const columnDescendants = getCompDescendants(column);
            const { alignment } = editorAPI.components.properties.get(column);
            const columnLayoutRelativeToStructure =
              editorAPI.components.layout.getRelativeToStructure(column);

            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
            _.forEach(columnDescendants, function (descendant) {
              resizeCompVerticallyByRatio(
                columnLayoutRelativeToStructure,
                descendant,
                resizeRatio,
                alignment,
              );
            });
          },
        );

        if (
          Object.keys(newColumnsWidthProportionsMap).length === 1 &&
          !hasDividersDesign(editorAPI, columnsContainerPointer)
        ) {
          editorAPI.components.design.update(
            columnsContainerPointer,
            constants.COMPONENT_DESIGN.EMPTY_BACKGROUND,
            undefined,
            true,
          );
        }

        if (
          isSingleColumnStrip(columnsContainerPointer) &&
          isInteractionModeAvailable(editorAPI.dsRead)
        ) {
          transferStripInteractionToSingleColumn(
            editorAPI,
            columnsContainerPointer,
          );
          editorAPI.dsActions.waitForChangesApplied(() => {
            editorAPI.selection.selectComponentByCompRef(
              columnsContainerPointer,
            );
            resolve();
          });
          return;
        }
        editorAPI.selection.selectComponentByCompRef(columnsContainerPointer);
        resolve();
      });
    });
  }

  function isSingleColumnStrip(columnsContainerPtr: CompRef | CompRef[]) {
    const columns = editorAPI.components.getChildren(columnsContainerPtr);
    return columns.length === 1;
  }

  function getColumnIfStripIsSingleColumn(stripRef: CompRef): CompRef {
    const columns = editorAPI.components.getChildren(stripRef);
    if (columns.length === 1) {
      return columns[0];
    }
    return stripRef;
  }

  function isMultiColumnsStrip(columnsContainerPtr: CompRef | CompRef[]) {
    return !isSingleColumnStrip(columnsContainerPtr);
  }

  function isColumn(compPtr: CompRef | CompRef[]) {
    return (
      editorAPI.components.getType(compPtr) === constants.COMP_TYPES.COLUMN
    );
  }

  function isStrip(compPtr: CompRef | CompRef[]) {
    return (
      editorAPI.components.getType(compPtr) ===
      constants.COMP_TYPES.STRIP_COLUMNS_CONTAINER
    );
  }

  function setManagedColumn(columnPointer: CompRef, callback: AnyFixMe) {
    editorAPI.updateState({ managedColumnPointer: columnPointer }, callback);
  }

  function getManagedColumn() {
    const { managedColumnPointer } = editorAPI.store.getState();
    return managedColumnPointer &&
      editorAPI.components.is.exist(managedColumnPointer)
      ? managedColumnPointer
      : null;
  }

  function openManagePanel(
    columnsContainerPtr: CompRef,
    initialManagedColumn: CompRef | CompRef[],
  ) {
    if (Array.isArray(initialManagedColumn)) {
      initialManagedColumn = _.head(initialManagedColumn);
    }

    initialManagedColumn =
      initialManagedColumn ||
      editorAPI.components.getChildren(columnsContainerPtr)[0];

    editorAPI.columns.manage.setManagedColumn(
      initialManagedColumn,
      function () {
        editorAPI.selection.selectComponentByCompRef(columnsContainerPtr);
        editorAPI.panelManager.openComponentPanel(MANAGE_COLUMNS_PANEL_NAME, {
          selectedComponent: columnsContainerPtr,
        });
      },
    );
  }

  function closeManagePanel() {
    editorAPI.columns.manage.setManagedColumn(null, function () {
      editorAPI.panelManager.closePanelByName(MANAGE_COLUMNS_PANEL_NAME);
    });
  }

  function getAddColumnBIParams(
    editorAPI: EditorAPI,
    compRef: CompRef,
    origin: string,
  ): any {
    const containerRef = editorAPI.components.getContainer(compRef);
    const component_id = compRef?.id;
    const parent_component_id = containerRef?.id;

    return {
      num_of_columns_before:
        editorAPI.components.getChildren(containerRef).length - 1,
      origin,
      component_id,
      parent_component_id,
    };
  }

  function sendAddColumnBI(
    editorAPI: EditorAPI,
    compRef: CompRef,
    origin: string,
  ) {
    const biParams = getAddColumnBIParams(editorAPI, compRef, origin);
    const containerRef = editorAPI.components.getContainer(compRef);
    const target_component = editorAPI.components.getType(containerRef);
    editorAPI.bi.event(columnsEvents.COLUMNS_ADD_COLUMN_CLICK, biParams);

    editorAPI.bi.event(
      coreBi.events.addPanel.COMPONENT_ADDED_TO_STAGE,
      Object.assign({
        ...editorAPI.bi.getComponentsBIParams([compRef])[0],
        origin,
        component_id: compRef.id,
        component_name: constants.COMP_TYPES.COLUMN,
        adding_method: 'click',
        page_id: editorAPI.pages.getFocusedPageId(),
        target_component,
        target_component_id: containerRef?.id,
        non_page_top_parent_component_id: util.sections.isSectionsEnabled()
          ? sectionsAPI.getFocusedSection()?.id
          : editorAPI.pages.getFocusedPage().id,
      }),
    );
  }

  return {
    isColumn,
    isStrip,
    isMultiColumnsStrip,
    isSingleColumnStrip,
    addColumn,
    duplicateColumn,
    removeColumn,
    getColumnIfStripIsSingleColumn,
    setInitialColumnChildrenLayouts,
    clearInitialColumnChildrenLayouts,
    canAdd,
    proportions: {
      set: setProportions,
      get: getProportions,
      flip: flipProportions,
      toPercentages,
    },
    columnAlignment: {
      set: setColumnAlignment,
      get: getColumnAlignment,
    },
    move: {
      left: moveLeft,
      right: moveRight,
    },
    manage: {
      setManagedColumn,
      getManagedColumn,
      openPanel: openManagePanel,
      closePanel: closeManagePanel,
    },
  };
}
