// @ts-nocheck
import createReactClass from 'create-react-class';
import * as core from '@/core';
import React from 'react';
import * as baseUI from '@/baseUI';
import { translate } from '@/i18n';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'blogSinglePostInfo',
  render() {
    return (
      <div className="page-info tab-inner">
        <section>
          <p className="title">
            {translate('Pages_Blog_Post_Info_WhatisTitle')}
          </p>

          <p>{translate('Pages_Blog_Post_Info_WhatisText')}</p>
        </section>

        <section>
          <p className="title">{translate('Pages_Blog_Post_Info_HowTitle')}</p>

          <p>{translate('Pages_Blog_Post_Info_HowText')}</p>
        </section>

        <section>
          <p className="title">
            {translate('Pages_Blog_Post_Info_ChangeTitle')}
          </p>

          <p>
            <span>{`${translate('Pages_Blog_Post_Info_ChangeText')} `}</span>
            {this.isDesktop() ? (
              <span key="openBlogManagerButton">
                <baseUI.button
                  label="Pages_Blog_Post_Info_ChangeLink"
                  onClick={(e) => {
                    e.stopPropagation();
                    this.props.actions.openBlogManager();
                  }}
                  className="btn-text"
                />
                <span>.</span>
              </span>
            ) : null}
            {this.isMobile() ? (
              <span key="openBlogManagerText">
                {translate('Pages_Blog_Post_Info_ChangeLink')}.
              </span>
            ) : null}
          </p>
        </section>
      </div>
    );
  },
  mixins: [core.mixins.editorAPIMixin],
  isDesktop() {
    return (
      this.getEditorAPI().viewMode.get() ===
      this.getEditorAPI().dsRead.viewMode.VIEW_MODES.DESKTOP
    );
  },
  isMobile() {
    return (
      this.getEditorAPI().viewMode.get() ===
      this.getEditorAPI().dsRead.viewMode.VIEW_MODES.MOBILE
    );
  },
});
