export const enum TimingFunctionTypes {
  NONE = 'ease',
  EASE_IN = 'ease-in',
  EASE_OUT = 'ease-out',
  EASE_IN_OUT = 'ease-in-out',
  ELASTIC = 'elastic',
  LINEAR = 'linear',
}

export interface ITransitionValue {
  duration: number;
  delay: number;
  timingFunction: TimingFunctionTypes;
}

export interface ITimingOption {
  value: TimingFunctionTypes;
  isSelected?: boolean;
  label: string;
  illustrationDefault: string;
  illustrationHover: string;
  illustrationSelected: string;
  illustrationSelectedHover: string;
}
