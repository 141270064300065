// @ts-nocheck
import _ from 'lodash';
import * as packagePicker from '../packagePicker/packagePicker';
import * as dashboardAppService from '../services/dashboardAppService';
import * as superAppsGfppData from '../superApps/gfpp/superAppsGfppData';
import * as superApps from '../superApps/superApps';
import tpaConstants from '../constants/constants';
import * as bi from '../bi/bi';
import * as tpaUtils from '../utils/tpaUtils';
import * as settings from '../settings/settings';
import * as tpaHelpService from '../services/tpaHelpService';
import constants from '@/constants';
import * as helpIds from '@/helpIds';
import * as stateManagement from '@/stateManagement';
import * as util from '@/util';

import type { CompRef } from 'types/documentServices';

const openSettingsPanel = function (editorAPI, compRef) {
  fireProvisionBIEvents(editorAPI, compRef);
  settings.open(editorAPI, compRef);
};

function fireProvisionBIEvents(editorAPI, compRef) {
  const selectedComponentData = editorAPI.components.data.get(compRef) || {};
  const appData = editorAPI.dsRead.tpa.app.getData(
    selectedComponentData.applicationId,
  );
  if (appData?.appDefinitionId) {
    fireAppIntent(editorAPI, bi.events.APP_INTENT, appData.appDefinitionId);
  }
}

function fireAppIntent(editorAPI, biEvent, appDefId) {
  tpaUtils.bi.reportBIOnAppIntent(
    editorAPI,
    biEvent,
    appDefId,
    tpaConstants.BI.initiator.EDITOR,
    tpaConstants.BI.type.GFPP_SETTINGS,
  );
}

const defaultSettingsActionStructure = {
  label: 'gfpp_mainaction_tpa',
  automationId: 'gfpp-button-settings',
  isApplied: true,
  onClick: openSettingsPanel,
};

const openDashboardUrl = function (editorAPI, url) {
  if (url) {
    window.open(url);
    sendOpenDashboardClickBi(editorAPI);
  }
};

const dashboardMainActionStructureWithData = function (
  editorAPI,
  openInDashboardText,
) {
  return {
    label: openInDashboardText,
    isApplied: true,
    shouldTranslate: false,
    onClick() {
      dashboardAppService.getDashboardAppUrlAsync(
        editorAPI,
        undefined,
        openDashboardUrl.bind(null, editorAPI),
      );
    },
  };
};

function addAppIdToActions(actions, appData) {
  const appDefinitionId = appData?.appDefinitionId;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
  _.forEach(actions, function (action) {
    action.appId = appDefinitionId;
  });
}

function shouldShowSettingsInSecondaryLang(editorAPI) {
  const compData = editorAPI.selection.getSelectedComponentData();
  if (!compData) {
    return false;
  }

  const appData = editorAPI.dsRead.tpa.app.getData(compData.applicationId);
  return superAppsGfppData.shouldShowSettingsInSecondaryLang(
    appData.appDefinitionId,
    compData.widgetId,
  );
}

function shouldShowTranslate(editorAPI) {
  const compData = editorAPI.selection.getSelectedComponentData();
  const appData = editorAPI.dsRead.tpa.app.getData(compData.applicationId);
  return superAppsGfppData.shouldShowTranslate(
    appData.appDefinitionId,
    compData.widgetId,
  );
}

function addSettingsActionIfNeeded(editorAPI, actions) {
  if (isSecondaryLang(editorAPI)) {
    if (shouldShowSettingsInSecondaryLang(editorAPI)) {
      actions.push(defaultSettingsActionStructure);
    }
  } else {
    actions.push(defaultSettingsActionStructure);
  }
}

const mainActions = function (editorAPI, compRefs?: CompRef[]) {
  const [compRef] = util.array.asArray(compRefs || []);
  const actions = [];
  addSettingsActionIfNeeded(editorAPI, actions);
  addDashboardMainAction(editorAPI, actions);

  const selectedComponent =
    (compRef
      ? editorAPI.components.data.get(compRef)
      : editorAPI.selection.getSelectedComponentData()) || {};
  const appData = editorAPI.dsRead.tpa.app.getData(
    selectedComponent.applicationId,
  );

  if (appData && superAppsGfppData.hasCustomActions(appData.appDefinitionId)) {
    addCustomMainActions(
      editorAPI,
      appData.appDefinitionId,
      selectedComponent.widgetId,
      actions,
    );
  }

  addTranslationActionIfNeeded(editorAPI, actions);
  addAppIdToActions(actions, appData);

  return actions;
};

const mobileMainActions = function (editorAPI, compRefs?: CompRef[]) {
  const [compRef] = util.array.asArray(compRefs || []);
  const selectedComponentData =
    editorAPI.components.data.get(compRef) ||
    editorAPI.selection.getSelectedComponentData();
  const appData = editorAPI.dsRead.tpa.app.getData(
    selectedComponentData?.applicationId,
  );
  const isMobileSettingsEnabled =
    appData?.widgets?.[selectedComponentData.widgetId]?.componentFields
      ?.mobileSettingsEnabled ?? false;

  return isMobileSettingsEnabled === true
    ? [defaultSettingsActionStructure]
    : [];
};

const enabledActions = function (editorAPI, actions) {
  const result = [actions.HELP];
  const compPointer = editorAPI.selection.getSelectedComponentData();
  if (compPointer && shouldShowUpgrade(editorAPI, compPointer)) {
    result.unshift(actions.UPGRADE);
  }
  return result;
};

const presetActions = function (editorAPI) {
  const isAscendUpgrade = () => {
    const appData = getAppData(editorAPI);
    return appData && util.ascend.isAscendUpgrade(appData.appDefinitionId);
  };

  const actions = {
    help: {
      isSelected: false, // todo
      tooltip: 'gfpp_tooltip_help',
      onClick() {
        const appData = getAppData(editorAPI);
        const { widgetId } = editorAPI.selection.getSelectedComponentData();
        const biParams = {
          widget_id: widgetId,
          app_id: appData?.appDefinitionId,
          panel_name: editorAPI.selection.getSelectedComponentType(),
          origin: constants.BI.HELP.ORIGIN.GFPP,
        };
        const props = {
          openPanelBiEvent: bi.events.TPA_HELP_CLICK,
          openPanelBiParams: _.merge({ help_status: 'open' }, biParams),
          closePanelBiParams: _.merge({ help_status: 'close' }, biParams),
          closePanelBiEvent: bi.events.TPA_HELP_CLICK,
        };

        const getBiHelpParams = () => ({
          origin: constants.BI.HELP.ORIGIN.GFPP,
          panel_name: editorAPI.selection.getSelectedComponentType(),
          component: editorAPI.selection.getSelectedComponentType(),
          learn_more: false,
        });

        if (
          !stateManagement.multilingual.services.utils.currentIsOriginal(
            editorAPI,
          )
        ) {
          const biHelpParams = getBiHelpParams();
          editorAPI.panelManager.openHelpCenter(
            helpIds.MULTILINGUAL.GFPP,
            props,
            biHelpParams,
          );
          return;
        }
        tpaHelpService
          .getHelpId(
            editorAPI,
            appData?.appDefinitionId,
            widgetId,
            tpaHelpService.HELP_END_POINTS.WIDGET,
          )
          .then(function (helpId) {
            const biHelpParams = getBiHelpParams();
            editorAPI.panelManager.openHelpCenter(helpId, props, biHelpParams);
          });
      },
    },
    upgrade: {
      isSelected: false, // todo
      shouldTranslate() {
        return !dashboardAppService.getEditorGfppData(editorAPI).upgradeText;
      },
      tooltip() {
        return (
          dashboardAppService.getEditorGfppData(editorAPI).upgradeText ||
          (isAscendUpgrade()
            ? 'gfpp_tooltip_ascend_upgrade'
            : 'tpa_package_picker_upgrade_title')
        );
      },
      icon: () => (isAscendUpgrade() ? 'ascend_upgrade_gfpp_icon' : 'upgrade'),
      onClick() {
        const appData = getAppData(editorAPI);
        tpaUtils.bi.reportBIOnAppIntent(
          editorAPI,
          bi.events.APP_INTENT,
          appData?.appDefinitionId,
          tpaConstants.BI.initiator.EDITOR,
          tpaConstants.BI.type.GFPP_UPGRADE,
        );
        packagePicker.startUpgradeFlow(
          editorAPI,
          appData?.appDefinitionId,
          false,
          {
            origin: 'gfpp',
          },
        );
        const params = {
          pp_origin: 'gfpp',
          app_site_id: appData?.instanceId,
          app_id: appData?.appDefinitionId,
        };
        editorAPI.bi.event(bi.events.CLICK_UPGRADE_APP, params);
      },
    },
  };

  addAppIdToActions(actions, getAppData(editorAPI));
  return actions;
};

const sendOpenDashboardClickBi = function (editorAPI) {
  const selectedComp = editorAPI.selection.getSelectedComponentData();
  const appData =
    selectedComp &&
    editorAPI.dsRead.tpa.app.getData(selectedComp.applicationId);
  const params = {
    app_id: appData?.appDefinitionId,
    app_site_id: appData?.instanceId,
    instance_id: selectedComp.id,
    origin: 'gfpp',
  };
  editorAPI.bi.event(bi.events.OPEN_DASHBOARD_APP_CLICK, params);
};

const shouldShowUpgrade = function (editorAPI, selectedComponentData) {
  const appData = editorAPI.dsRead.tpa.app.getData(
    selectedComponentData.applicationId,
  );
  if (
    superAppsGfppData.hasExternalUpgrade(
      appData.appDefinitionId,
      selectedComponentData.widgetId,
    )
  ) {
    return true;
  }
  const canBeUpgraded = editorAPI.dsRead.tpa.app.hasPremiumOffering(
    selectedComponentData.applicationId,
  );

  if (canBeUpgraded) {
    const isFullyUpgraded = packagePicker.isFullyUpgraded(editorAPI, appData);
    return !isFullyUpgraded;
  }

  return false;
};

const getDashboardAction = function (editorAPI) {
  let dashboardAction;
  const gfppMarketData = dashboardAppService.getEditorGfppData(editorAPI);
  if (gfppMarketData.openInDashboard) {
    dashboardAction = dashboardMainActionStructureWithData(
      editorAPI,
      gfppMarketData.openInDashboardText,
    );
  }
  return dashboardAction;
};

const addCustomMainActions = function (
  editorAPI,
  appDefinitionId,
  widgetId,
  actions,
) {
  const customData = superAppsGfppData.getCustomMainActionsFor(
    editorAPI,
    appDefinitionId,
    widgetId,
  );
  if (!_.isEmpty(customData)) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
    _.forEach(customData.actions, function (action) {
      if (isSecondaryLang(editorAPI)) {
        return;
      }
      if (action.addAtBack) {
        addMainActionAtBack(action, actions);
      } else {
        addMainActionAtFront(action, actions);
      }
    });
  }
};

const getAppData = function (editorAPI) {
  const selectedComponentData = editorAPI.selection.getSelectedComponentData();
  return (
    selectedComponentData &&
    editorAPI.dsRead.tpa.app.getData(selectedComponentData.applicationId)
  );
};

const addDashboardMainAction = function (editorAPI, actions) {
  const dashboardAction = getDashboardAction(editorAPI);
  if (!_.isEmpty(dashboardAction)) {
    actions.push(dashboardAction);
  }
};

function isSecondaryLang(editorAPI) {
  const languageAPI = editorAPI.dsRead.language;
  return (
    languageAPI.multilingual.isEnabled() &&
    languageAPI.current.get() !== languageAPI.original.get()?.code
  );
}

const defaultTranslateActionStructure = {
  label: 'gfpp_mainaction_edit_translation',
  isApplied: true,
  onClick: (editorAPI, compRef) => {
    const selectedComponentData = editorAPI.components.data.get(compRef) || {};
    const appData =
      editorAPI.dsRead.tpa.app.getData(selectedComponentData.applicationId) ||
      {};
    superApps.openTranslationDashboardUrl(
      appData.appDefinitionId,
      {
        onClose: _.partial(
          superApps.refreshAppCompsForAppDefId,
          appData.appDefinitionId,
        ),
        shouldNotCloseOnBlur: false,
        widgetId: selectedComponentData.widgetId,
      },
      { type: tpaConstants.BI.type.GFPP_MANAGE },
    );
  },
};

const defaultDisabledTranslateActionStructure = {
  label: 'gfpp_mainaction_edit_translation',
  isApplied: true,
  onClick: _.noop,
  isDisabled: true,
  tooltip: 'gfpp_tooltip_multilingual_translate_disabled',
};

const addTranslationActionIfNeeded = function (editorAPI, actions) {
  if (isSecondaryLang(editorAPI)) {
    if (shouldShowTranslate(editorAPI)) {
      addMainActionAtFront(defaultTranslateActionStructure, actions);
    } else {
      addMainActionAtFront(defaultDisabledTranslateActionStructure, actions);
    }
  }
};

const addMainActionAtFront = function (action, actions) {
  if (!_.isEmpty(action)) {
    actions.unshift(action);
  }
};

const addMainActionAtBack = function (action, actions) {
  if (!_.isEmpty(action)) {
    actions.push(action);
  }
};

export { mainActions, mobileMainActions, enabledActions, presetActions };
