import React from 'react';
import { useTranslation } from 'react-i18next';
import { ActionItem, CustomScroll } from '@wix/wix-base-ui';
import { symbol as Symbol } from '@wix/santa-editor-symbols';
import { Section } from '../Section/Section';
import { Dividify } from '../Dividify/Dividify';
import { AppIcon } from '../AppIcon/AppIcon';
import { TRANSLATIONS } from '../../translations';
import {
  AUTOMATION_IDS,
  getUninstalledAppCardDataHook,
} from '../../automationIds';

import type { RegularAppData } from '../../services/appMarket/appMarket.types';

export interface MoreAppsProps {
  apps: RegularAppData[];
  onAddApp: (app: RegularAppData) => void;
  onOpenAppMarket: () => void;
}

export const MoreApps: React.FC<MoreAppsProps> = ({
  apps,
  onAddApp,
  onOpenAppMarket,
}) => {
  const [translate] = useTranslation();

  return (
    <CustomScroll flex="1">
      <Section
        heading={translate(TRANSLATIONS.MY_BUSINESS.MORE_APPS.SECTION_TITLE)}
        linkText={translate(TRANSLATIONS.MY_BUSINESS.MORE_APPS.APP_MARKET_LINK)}
        onLinkClick={onOpenAppMarket}
        dataHook={AUTOMATION_IDS.MY_BUSINESS.UNINSTALLED_APPS_CONTAINER}
      >
        <Dividify long>
          {apps.map((appData) => (
            <div
              data-hook={getUninstalledAppCardDataHook(appData.id)}
              key={appData.id}
            >
              <ActionItem
                shouldTranslate={false}
                title={appData.name}
                description={appData.description}
                illustration={<AppIcon src={appData.icon} />}
                onClick={() => onAddApp(appData)}
                actionIcon={<Symbol name="plus" />}
                dataHook={AUTOMATION_IDS.APP_CARD.CTA}
              />
            </div>
          ))}
        </Dividify>
      </Section>
    </CustomScroll>
  );
};
