import type { EditorAPI } from '@/editorAPI';
import { getBookingsEditorActionService } from '@/wixBookings';
import type { CompStructure } from 'types/documentServices';

import type { OnDropCallback } from '@/addPanelInfra';

interface AddBookingOnClickParams {
  widgetId: string;
  sectionTitle: string;
  tags: string;
  itemId: string;
}

export const addBookingOnClick = async (
  editorAPI: EditorAPI,
  { widgetId, sectionTitle, tags, itemId }: AddBookingOnClickParams,
) => {
  const bookingsActionService = getBookingsEditorActionService(editorAPI);

  const compRef = await bookingsActionService.onWidgetClick(
    {
      data: { widgetId },
    },
    sectionTitle,
    tags,
    itemId,
  );
  await editorAPI.components.hooks.componentAddedToStage.fire({
    type: 'click',
    origin: 'addPanel',
    compRef,
  });
};

export const addBookingOnDrop = (editorAPI: EditorAPI): OnDropCallback =>
  getBookingsEditorActionService(editorAPI).onWidgetDrop;

interface AddBookingButtonOnClickParams {
  compStructure: CompStructure;
  sectionTitle: string;
  tags: string;
  itemId: string;
  categoryId: string;
}

export const addBookingButtonOnClick = (
  editorAPI: EditorAPI,
  {
    compStructure,
    sectionTitle,
    tags,
    categoryId,
    itemId,
  }: AddBookingButtonOnClickParams,
) => {
  const bookingsActionService = getBookingsEditorActionService(editorAPI);

  return bookingsActionService.onRefWidgetClick(
    compStructure,
    sectionTitle,
    tags,
    itemId,
    editorAPI,
    {
      addComponentOptions: {
        category: categoryId,
      },
    },
  );
};

export const addBookingButtonOnDrop = (editorAPI: EditorAPI): OnDropCallback =>
  getBookingsEditorActionService(editorAPI).onRefWidgetDrop;
