import React from 'react';
import _ from 'lodash';

import { isResponsiveEditor } from '@wix/santa-editor-utils';

import * as tpa from '@/tpa';
import * as util from '@/util';

import {
  mapDispatchToProps,
  mapStateToProps,
} from './bookingsWelcomeSectionMapper';
import type {
  BookingsWelcomeDispatchProps,
  BookingsWelcomeOwnProps,
  BookingsWelcomeStateProps,
} from './types';

class BookingsWelcomeSection extends React.Component<
  BookingsWelcomeOwnProps &
    BookingsWelcomeStateProps &
    BookingsWelcomeDispatchProps
> {
  private isNewWorkspace: boolean = util.workspace.isNewWorkspaceEnabled();

  getMainAction = () => {
    if (_.isFunction(this.props.installAppOverride)) {
      return _.partial(
        this.props.installAppOverride,
        tpa.superApps.superAppsConstants.WIX_BOOKINGS_APP_DEF_ID,
      );
    }
    return this.props.installBookings;
  };

  getBannerImgSrc() {
    let { bannerImage: bannerImgSrc } = this.props;

    if (!bannerImgSrc) {
      bannerImgSrc = 'wixBookings/addPanel/add_panel_booking.png';

      if (this.isNewWorkspace) {
        bannerImgSrc = util.browserUtil.isRetina()
          ? 'wixBookings/addPanel/newWorkspace_add_panel_bookings@x2.png'
          : 'wixBookings/addPanel/newWorkspace_add_panel_bookings.png';
      }

      if (isResponsiveEditor()) {
        bannerImgSrc = 'wixBookings/addPanel/add_panel_bookings.v1.png';
      }
    }

    return util.media.getMediaUrl(bannerImgSrc);
  }

  render() {
    const WelcomeSection = this.props.welcomeSectionTemplate;

    return (
      <div>
        <WelcomeSection
          bannerImgSrc={this.getBannerImgSrc()}
          title="SiteApp_WixBookings_PromotionalFTUE_AddPanel_Title"
          subtitle="SiteApp_WixBookings_PromotionalFTUE_AddPanel_Subtitle"
          promotionalListItems={[
            'SiteApp_WixBookings_PromotionalFTUE_AddPanel_Bullet1',
            'SiteApp_WixBookings_PromotionalFTUE_AddPanel_Bullet2',
            'SiteApp_WixBookings_PromotionalFTUE_AddPanel_Bullet3',
          ]}
          mainActionLabel="tpa_add_panel_add_to_site"
          secondaryActionLabel="SiteApp_WixBookings_PromotionalFTUE_AddPanel_SeeInAppMarket_LinkCaption"
          onMainActionClick={this.getMainAction()}
          onSecondaryActionClick={this.props.openHelpSection}
        />
      </div>
    );
  }
}

export default util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(BookingsWelcomeSection);
