import addPanelDataConsts from '@/addPanelDataConsts';
import item1 from './interactionHoverPresetItem1';
import item2 from './interactionHoverPresetItem2';
import item3 from './interactionHoverPresetItem3';
import item4 from './interactionHoverPresetItem4';
import item5 from './interactionHoverPresetItem5';
import item6 from './interactionHoverPresetItem6';
import item7 from './interactionHoverPresetItem7';
import item8 from './interactionHoverPresetItem8';
import item9 from './interactionHoverPresetItem9';
import item10 from './interactionHoverPresetItem10';
import item11 from './interactionHoverPresetItem11';
import item12 from './interactionHoverPresetItem12';
import item13 from './interactionHoverPresetItem13';
import item14 from './interactionHoverPresetItem14';
import item15 from './interactionHoverPresetItem15';
import item16 from './interactionHoverPresetItem16';
import item17 from './interactionHoverPresetItem17';
import item18 from './interactionHoverPresetItem18';
import item19 from './interactionHoverPresetItem19';
import item20 from './interactionHoverPresetItem20';
import item21 from './interactionHoverPresetItem21';
import item22 from './interactionHoverPresetItem22';
import item23 from './interactionHoverPresetItem23';
import item24 from './interactionHoverPresetItem24';
import item25 from './interactionHoverPresetItem25';
import item26 from './interactionHoverPresetItem26';
import item27 from './interactionHoverPresetItem27';
import item28 from './interactionHoverPresetItem28';
import item29 from './interactionHoverPresetItem29';
import item30 from './interactionHoverPresetItem30';
import item31 from './interactionHoverPresetItem31';
import item32 from './interactionHoverPresetItem32';
import item33 from './interactionHoverPresetItem33';
import item34 from './interactionHoverPresetItem34';
import item35 from './interactionHoverPresetItem35';

export default {
  type: addPanelDataConsts.SECTIONS_TYPES.PRESET,
  hide: false,
  title: 'add_section_heading_interactions',
  subNavigationTitle: 'add_section_label_interactions',
  presetTitle: 'add_section_heading_interactions',
  tooltipTitle: 'add_section_heading_interactions',
  automationId: 'add-panel-section-interactionHoverSection',
  subNavigationHide: false,
  showSectionHeader: true,
  additionalBehaviours: {
    labelMode: addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
    infoIcon: addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
    hoverImageAction: addPanelDataConsts.HOVER_IMAGE_ACTIONS.IMAGE,
    iconEnabledForComps: {},
  },
  props: {
    image:
      'addPanelData/sections/interactions/container/interactionsContainerPresetsRegular_en.v3.png',
    imageHover:
      'addPanelData/sections/interactions/container/interactionsContainerPresetsHover_en.v3.png',
    items: [
      {
        // Blue developer
        id: 'Box_Interactions_1',
        structure: item1,
        preset: { rect: { width: 162, height: 210, x: 0, y: 10 }, tags: null },
      },
      {
        // Lady interview
        id: 'Box_Interactions_2',
        structure: item2,
        preset: {
          rect: { width: 162, height: 210, x: 162, y: 10 },
          tags: null,
        },
      },
      {
        // End of summer sale
        id: 'Box_Interactions_3',
        structure: item3,
        preset: { rect: { width: 324, height: 240, x: 0, y: 230 }, tags: null },
      },
      {
        // Orange circle
        id: 'Box_Interactions_4',
        structure: item4,
        preset: { rect: { width: 105, height: 100, x: 0, y: 490 }, tags: null },
      },
      {
        // Shop now
        id: 'Box_Interactions_5',
        structure: item5,
        preset: {
          rect: { width: 105, height: 100, x: 115, y: 490 },
          tags: null,
        },
      },
      {
        // Star farms
        id: 'Box_Interactions_6',
        structure: item6,
        preset: {
          rect: { width: 105, height: 100, x: 220, y: 490 },
          tags: null,
        },
      },
      {
        // Wine
        id: 'Box_Interactions_7',
        structure: item7,
        preset: {
          rect: { width: 160, height: 230, x: 0, y: 600 },
          tags: null,
        },
      },
      {
        // Shoes
        id: 'Box_Interactions_8',
        structure: item8,
        preset: {
          rect: { width: 160, height: 230, x: 164, y: 600 },
          tags: null,
        },
      },
      {
        // Blue summer sale
        id: 'Box_Interactions_9',
        structure: item9,
        preset: { rect: { width: 324, height: 220, x: 0, y: 830 }, tags: null },
      },
      {
        // Pop up show
        id: 'Box_Interactions_10',
        structure: item10,
        preset: {
          rect: { width: 324, height: 220, x: 0, y: 1055 },
          tags: null,
        },
      },
      {
        // Blue event
        id: 'Box_Interactions_11',
        structure: item11,
        preset: {
          rect: { width: 324, height: 150, x: 0, y: 1285 },
          tags: null,
        },
      },
      {
        // Ice cream
        id: 'Box_Interactions_12',
        structure: item12,
        preset: {
          rect: { width: 160, height: 280, x: 0, y: 1440 },
          tags: null,
        },
      },
      {
        // Plant
        id: 'Box_Interactions_13',
        structure: item13,
        preset: {
          rect: { width: 160, height: 280, x: 164, y: 1440 },
          tags: null,
        },
      },
      {
        // Lake
        id: 'Box_Interactions_14',
        structure: item14,
        preset: {
          rect: { width: 324, height: 160, x: 0, y: 1723 },
          tags: null,
        },
      },
      {
        // Hearts
        id: 'Box_Interactions_15',
        structure: item15,
        preset: {
          rect: { width: 80, height: 73, x: 0, y: 1890 },
          tags: null,
        },
      },
      {
        // eye
        id: 'Box_Interactions_16',
        structure: item16,
        preset: {
          rect: { width: 70, height: 73, x: 85, y: 1890 },
          tags: null,
        },
      },
      {
        // Hello
        id: 'Box_Interactions_17',
        structure: item17,
        preset: {
          rect: { width: 70, height: 73, x: 170, y: 1890 },
          tags: null,
        },
      },
      {
        // Sun
        id: 'Box_Interactions_18',
        structure: item18,
        preset: {
          rect: { width: 70, height: 73, x: 254, y: 1890 },
          tags: null,
        },
      },
      {
        // Beach
        id: 'Box_Interactions_19',
        structure: item19,
        preset: {
          rect: { width: 162, height: 152, x: 0, y: 1972 },
          tags: null,
        },
      },
      {
        // Black
        id: 'Box_Interactions_20',
        structure: item20,
        preset: {
          rect: { width: 162, height: 152, x: 162, y: 1972 },
          tags: null,
        },
      },
      {
        // Trees
        id: 'Box_Interactions_21',
        structure: item21,
        preset: {
          rect: { width: 324, height: 150, x: 0, y: 2134 },
          tags: null,
        },
      },
      {
        // Anna
        id: 'Box_Interactions_22',
        structure: item22,
        preset: {
          rect: { width: 162, height: 230, x: 0, y: 2290 },
          tags: null,
        },
      },
      {
        // Ice cream
        id: 'Box_Interactions_23',
        structure: item23,
        preset: {
          rect: { width: 162, height: 230, x: 162, y: 2290 },
          tags: null,
        },
      },
      {
        // Modern
        id: 'Box_Interactions_24',
        structure: item24,
        preset: {
          rect: { width: 324, height: 220, x: 0, y: 2530 },
          tags: null,
        },
      },
      {
        // Blue 50%
        id: 'Box_Interactions_25',
        structure: item25,
        preset: {
          rect: { width: 160, height: 180, x: 0, y: 2770 },
          tags: null,
        },
      },
      {
        // Come
        id: 'Box_Interactions_26',
        structure: item26,
        preset: {
          rect: { width: 164, height: 180, x: 160, y: 2770 },
          tags: null,
        },
      },
      {
        // Last big
        id: 'Box_Interactions_27',
        structure: item27,
        preset: {
          rect: { width: 324, height: 164, x: 0, y: 2950 },
          tags: null,
        },
      },
      {
        id: 'Box_Interactions_28',
        structure: item28,
        preset: {
          rect: { width: 82, height: 80, x: 0, y: 3130 },
          tags: null,
        },
      },
      {
        id: 'Box_Interactions_29',
        structure: item29,
        preset: {
          rect: { width: 82, height: 80, x: 82, y: 3130 },
          tags: null,
        },
      },
      {
        id: 'Box_Interactions_30',
        structure: item30,
        preset: {
          rect: { width: 82, height: 80, x: 164, y: 3130 },
          tags: null,
        },
      },
      {
        id: 'Box_Interactions_31',
        structure: item31,
        preset: {
          rect: { width: 82, height: 80, x: 246, y: 3130 },
          tags: null,
        },
      },
      {
        id: 'Box_Interactions_32',
        structure: item32,
        preset: {
          rect: { width: 82, height: 80, x: 0, y: 3210 },
          tags: null,
        },
      },
      {
        id: 'Box_Interactions_33',
        structure: item33,
        preset: {
          rect: { width: 82, height: 80, x: 82, y: 3210 },
          tags: null,
        },
      },
      {
        id: 'Box_Interactions_34',
        structure: item34,
        preset: {
          rect: { width: 82, height: 80, x: 164, y: 3210 },
          tags: null,
        },
      },
      {
        id: 'Box_Interactions_35',
        structure: item35,
        preset: {
          rect: { width: 82, height: 80, x: 246, y: 3210 },
          tags: null,
        },
      },
    ],
    compTypes: ['mobile.core.components.Container'],
  },
  help: { hide: false, text: 'add_section_info_text_box' },
};
