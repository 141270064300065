'use strict'

module.exports = {
    id: 'add_preset_popups_Subscribe_4',
    structure: {
        type: 'Page',
        id: 'd1u2m',
        componentTypeForBI: 'wysiwyg.viewer.components.PopupContainer',
        components: [
            {
                type: 'Container',
                id: 'comp-im0nhrlh',
                components: [
                    {
                        type: 'Container',
                        id: 'comp-im0njf2v',
                        components: [
                            {
                                type: 'Component',
                                id: 'comp-im0nhrlw',
                                skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
                                layout: {
                                    width: 518,
                                    height: 46,
                                    x: 46,
                                    y: 100,
                                    scale: 1,
                                    rotationInDegrees: 0,
                                    fixedPosition: false
                                },
                                componentType: 'wysiwyg.viewer.components.WRichText',
                                data: {
                                    type: 'StyledText',
                                    metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                                    text: '<p style="font-size: 34px; text-align: center;" class="font_8"><span style="font-size:34px;"><span style="letter-spacing:0.05em;"><span style="font-family:tahoma,tahoma-w01-regular,tahoma-w02-regular,tahoma-w10-regular,tahoma-w15--regular,tahoma-w99-regular,sans-serif;"><span style="color:#2A2A2A;"><span style="font-weight:bold;">NEVER MISS AN UPDATE</span></span></span></span></span></p>',
                                    stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
                                    linkList: [],
                                    id: 'dataItem-im0nhrlx'
                                },
                                props: {
                                    type: 'WRichTextProperties',
                                    metaData: {schemaVersion: '1.0'},
                                    brightness: 1,
                                    packed: true,
                                    id: 'propItem-ipf7qaun'
                                },
                                style: 'txtNew'
                            },
                            {
                                type: 'Component',
                                id: 'comp-im0nodan',
                                layout: {
                                    width: 402,
                                    height: 33,
                                    x: 79,
                                    y: 155,
                                    scale: 1,
                                    rotationInDegrees: 0,
                                    fixedPosition: false
                                },
                                componentType: 'wysiwyg.viewer.components.WRichText',
                                data: {
                                    type: 'StyledText',
                                    metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                                    text: '<p class="font_8" style="font-size:24px"><span style="color:#2A2A2A;"><span style="font-family:open sans,sans-serif">Get your name on the list.</span></span></p>\n',
                                    stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
                                    linkList: [],
                                    id: 'dataItem-im0nodaq'
                                },
                                props: {
                                    type: 'WRichTextProperties',
                                    metaData: {schemaVersion: '1.0'},
                                    brightness: 1,
                                    packed: true,
                                    id: 'propItem-im0nodar'
                                },
                                style: 'txtNew'
                            }
                        ],
                        skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
                        layout: {
                            width: 614,
                            height: 391,
                            x: 8,
                            y: 8,
                            scale: 1,
                            rotationInDegrees: 0,
                            fixedPosition: false
                        },
                        componentType: 'mobile.core.components.Container',
                        style: {
                            type: 'TopLevelStyle',
                            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                            style: {
                                groups: {},
                                properties: {
                                    'alpha-bg': '0',
                                    'alpha-brd': '1',
                                    bg: '#FFFFFF',
                                    'boxShadowToggleOn-shd': 'false',
                                    brd: '#2F2E2E',
                                    brw: '1px',
                                    rd: '0px',
                                    shd: '0px 1px 4px 0px rgba(0,0,0,0.6)'
                                },
                                propertiesSource: {
                                    'alpha-bg': 'value',
                                    bg: 'value',
                                    brd: 'value',
                                    brw: 'value',
                                    rd: 'value',
                                    shd: 'value'
                                }
                            },
                            componentClassName: 'mobile.core.components.Container',
                            pageId: '',
                            compId: '',
                            styleType: 'custom',
                            skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
                            id: 'style-iqgwplm5'
                        }
                    },
                    {
                        'type': 'Component',
                        'skin': 'svgshape.v2.Svg_001419cc721a440abc4ab12ed150b744',
                        'layout': {
                            'width': 30,
                            'height': 30,
                            'x': 576,
                            'y': 23,
                            'scale': 1,
                            'rotationInDegrees': 0,
                            'fixedPosition': false
                        },
                        'componentType': 'wysiwyg.viewer.components.PopupCloseIconButton',
                        'parent': 'comp-jv6nssev',
                        'metaData': {
                            'pageId': 'yusqk'
                        },
                        'data': {
                            'type': 'SvgShape',
                            'metaData': {
                                'isPreset': false,
                                'schemaVersion': '1.0',
                                'isHidden': false,
                                'pageId': 'yusqk'
                            }
                        },
                        'props': {
                            'type': 'SvgShapeProperties',
                            'metaData': {
                                'schemaVersion': '1.0',
                                'pageId': 'yusqk'
                            },
                            'maintainAspectRatio': true
                        },
                        'mobileHints': {
                            'type': 'MobileHints',
                            'author': 'duplicate',
                            'originalCompId': 'comp-isreyv2v',
                            'recommendedScale': 1,
                            'recommendedWidth': 20,
                            'recommendedHeight': 20,
                            'metaData': {
                                'pageId': 'yusqk'
                            }
                        },
                        'style': {
                            'type': 'ComponentStyle',
                            'metaData': {
                                'isPreset': false,
                                'schemaVersion': '1.0',
                                'isHidden': false,
                                'pageId': 'yusqk'
                            },
                            'style': {
                                'properties': {
                                    'alpha-fillcolor': '1',
                                    'alpha-stroke': '1',
                                    'fillcolor': '#000000',
                                    'stroke': '#FFFFFF',
                                    'strokewidth': '1'
                                },
                                'propertiesSource': {
                                    'alpha-fillcolor': 'value',
                                    'alpha-stroke': 'value',
                                    'fillcolor': 'value',
                                    'stroke': 'value',
                                    'strokewidth': 'value'
                                },
                                'groups': {}
                            },
                            'componentClassName': 'wysiwyg.viewer.components.PopupCloseIconButton',
                            'pageId': '',
                            'compId': '',
                            'styleType': 'custom',
                            'skin': 'svgshape.v2.Svg_001419cc721a440abc4ab12ed150b744'
                        },
                        'connections': {
                            'type': 'ConnectionList',
                            'items': [
                                {
                                    'type': 'WixCodeConnectionItem',
                                    'role': 'button1'
                                }
                            ],
                            'metaData': {
                                'pageId': 'yusqk'
                            }
                        },
                        'activeModes': {}
                    }
                ],
                skin: 'wysiwyg.viewer.skins.stripContainer.DefaultStripContainer',
                layout: {
                    width: 630,
                    height: 407,
                    x: 156,
                    y: 372,
                    scale: 1,
                    rotationInDegrees: 0,
                    fixedPosition: false
                },
                componentType: 'wysiwyg.viewer.components.PopupContainer',
                props: {
                    type: 'PopupContainerProperties',
                    metaData: {schemaVersion: '1.0'},
                    horizontalAlignment: 'center',
                    verticalAlignment: 'center',
                    alignmentType: 'nineGrid',
                    horizontalOffset: 0,
                    verticalOffset: 0,
                    id: 'propItem-im0nhrlk'
                },
                design: {
                    type: 'MediaContainerDesignData',
                    metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                    background: {
                        type: 'BackgroundMedia',
                        metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                        color: '#FFFFFF',
                        colorOpacity: 1,
                        alignType: 'center',
                        fittingType: 'fill',
                        scrollType: 'none',
                        colorOverlay: '',
                        colorOverlayOpacity: 0
                    },
                    id: 'dataItem-im0nhrlk1'
                },
                style: {
                    type: 'TopLevelStyle',
                    metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                    style: {
                        groups: {},
                        properties: {'alpha-bg': '1', bg: 'rgba(255,255,255,1)'},
                        propertiesSource: {'alpha-bg': 'value', bg: 'value'}
                    },
                    componentClassName: 'wysiwyg.viewer.components.PopupContainer',
                    pageId: '',
                    compId: '',
                    styleType: 'custom',
                    skin: 'wysiwyg.viewer.skins.stripContainer.DefaultStripContainer',
                    id: 'style-im0nhrll'
                },
                behaviors: {
                    type: 'ObsoleteBehaviorsList',
                    items: '[{"name":"FadeIn","delay":0.1,"duration":0.75,"action":"screenIn","targetId":"","params":{}},{"name":"FadeOut","delay":0.1,"duration":0.75,"action":"exit","targetId":"","params":{}}]',
                    id: 'behavior-iomlweds'
                }
            }
        ],

        skin: 'skins.core.InlineSkin',
        layout: {
            width: 980,
            height: 779,
            x: 0,
            y: 0,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false
        },
        componentType: 'mobile.core.components.Page',
        data: {
            type: 'Page',
            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
            title: 'add_preset_popups_Subscribe_4',
            hideTitle: true,
            icon: '',
            descriptionSEO: '',
            metaKeywordsSEO: '',
            pageTitleSEO: '',
            pageUriSEO: 'copy-of-contact-d1u2m',
            hidePage: true,
            underConstruction: false,
            pageSecurity: {requireLogin: false, passwordDigest: '', dialogLanguage: ''},
            isPopup: true,
            indexable: false,
            isLandingPage: false,
            pageBackgrounds: {
                desktop: {
                    ref: {
                        type: 'BackgroundMedia',
                        metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                        color: '#2A2A2A',
                        colorOpacity: 0.9,
                        alignType: 'center',
                        fittingType: 'fill',
                        scrollType: 'none',
                        colorOverlay: '',
                        colorOverlayOpacity: 0
                    }
                },
                mobile: {
                    custom: true,
                    ref: {
                        type: 'BackgroundMedia',
                        metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                        color: '#2A2A2A',
                        colorOpacity: 0.9,
                        alignType: 'center',
                        fittingType: 'fill',
                        scrollType: 'scroll',
                        colorOverlay: '',
                        colorOverlayOpacity: 0
                    },
                    isPreset: true
                }
            },
            ignoreBottomBottomAnchors: true
        },
        props: {
            type: 'PageProperties',
            metaData: {schemaVersion: '1.0'},
            desktop: {popup: {closeOnOverlayClick: true}},
            mobile: {popup: {closeOnOverlayClick: true}},
            id: 'd1u2m'
        },
        style: 'p1'
    },
    preset: {
        rect: {width: 305, height: 202, x: 10, y: 908},
        label: 'add_preset_popups_Subscribe_4',
        tags: null
    }
}
