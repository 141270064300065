// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

function template() {
  return (
    <div className={`top-bar-drop-panel ${this.props.dropPanelClass || ''}`}>
      <div className="drop-panel-left-side">{this.getLeftContent()}</div>
      {this.hasRightContent() ? (
        <div key="rightContent" className="drop-panel-right-side">
          {this.getRightContent()}
        </div>
      ) : null}
      {this.props.bottomContent ? (
        <div key="bottomContent" className="drop-panel-bottom-side">
          {this.props.bottomContent}
        </div>
      ) : null}
    </div>
  );
}

//TYPE WAS GENERATED, remove this line when reviewed
interface Props {
  leftContent: AnyFixMe;
  bottomContent?: AnyFixMe;
  rightContentOptions?: AnyFixMe;
  dropPanelClass?: string;
  closeHandle?: FunctionFixMe;
  hintContainerId?: string;
}

export default class extends React.Component<Props> {
  static displayName = 'topBarDropPanel';

  static propTypes = {
    leftContent: PropTypes.object.isRequired,
    bottomContent: PropTypes.object,
    rightContentOptions: PropTypes.object,
    dropPanelClass: PropTypes.string,
    closeHandle: PropTypes.func,
    hintContainerId: PropTypes.string,
  };

  state = {
    displayedRightContentKey: 'main',
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/keys
    const currentRightContentOptionsKeys = _.keys(
      this.props.rightContentOptions,
    );
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/keys
    const nextRightContentOptionsKeys = _.keys(nextProps.rightContentOptions);
    const rightContentOptionsIntersection = _.intersection(
      nextRightContentOptionsKeys,
      currentRightContentOptionsKeys,
    );

    if (
      rightContentOptionsIntersection.length !==
      currentRightContentOptionsKeys.length
    ) {
      this.setState({ displayedRightContentKey: 'main' });
    }
  }

  getLeftContent = () => {
    return React.cloneElement(this.props.leftContent, {
      onMenuItemHover: this.onSubMenuItemHover,
      closeDropDownHandle: this.props.closeHandle,
    });
  };

  getRightContent = () => {
    return this.props.rightContentOptions[this.state.displayedRightContentKey];
  };

  hasRightContent = () => {
    return !!this.props.rightContentOptions;
  };

  onSubMenuItemHover = (subMenuItemDef) => {
    if (subMenuItemDef.rightPanel) {
      this.setState({ displayedRightContentKey: subMenuItemDef.key });
    }
  };

  render() {
    return template.call(this);
  }
}
