import React from 'react';
import { TextButton } from '@wix/wix-base-ui';
import * as symbols from '@wix/santa-editor-symbols';
import styles from './newBlockButton.scss';

interface Props {
  onClick(): void;
}
export const NewBlockButton: React.FC<Props> = ({ onClick }) => (
  <div className={styles.newBlockButtonContainer}>
    <TextButton
      dataHook={'create-new-block-button'}
      size="medium"
      weight="bold"
      suffixIcon={<symbols.symbol name="newBlockButtonIcon" />}
      onClick={onClick}
    >
      blocks-private-apps.AppDiscovery_Sidebar_Create_New_CTA
    </TextButton>
  </div>
);
