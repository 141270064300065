import { BaseEntryScope, createEntryPoint } from '@/apilib';
import { init } from './init';
import { DebugLayoutPublicApi } from './api';
import { DebugLayoutApiKey } from './publicApiKey';
import {
  BiApiKey,
  EditorAPIKey,
  SelectionApiKey,
  EditorCoreApiKey,
} from '@/apis';

export class Scope extends BaseEntryScope {
  editorAPI = this.useDependency(EditorAPIKey);
  biApi = this.useDependency(BiApiKey);
  selectionApi = this.useDependency(SelectionApiKey);
  editorCoreApi = this.useDependency(EditorCoreApiKey);
}

export const DebugLayoutEntryPoint = createEntryPoint({
  name: 'DebugLayoutEntry',
  Scope,
  publicApi({ contributeApi }) {
    contributeApi(DebugLayoutApiKey, DebugLayoutPublicApi);
  },
  initialize(scope) {
    init(scope);
  },
});
