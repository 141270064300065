'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')
const util = require('@/util')
const i18n = require('@/i18n')

module.exports = {
    'type': addPanelDataConsts.SECTIONS_TYPES.PRESET,
    'hide': false,
    'title': 'add_preset_list_products_and_services',
    'showSectionHeader': true,
    'additionalBehaviours': {
        'labelMode': addPanelDataConsts.LABEL_BEHAVIOUR.HOVER,
        'infoIcon': addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
        'hoverImageAction': addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
        'iconEnabledForComps': {}
    },
    'props': {
        'image': `addPanelData/sections/listProductsAndServicesSection_${util.languages.getLanguageCode()}/listProductsAndServicesSection_${util.languages.getLanguageCode()}.png`,
        'imageHover': null,
        'items': [{
            'id': 'Lists_List_Products_And_Services_1',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.viewer.skins.AppPartSkin',
                'layout': {
                    'width': 567,
                    'height': 514,
                    'x': 36,
                    'y': 68,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wixapps.integration.components.AppPart2',
                'data': {
                    'type': 'AppBuilderComponent',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'appInnerID': '2',
                    'appPartName': 'appparti7g3m1dj9'
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'id': 'app1',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {'groups': {}, 'properties': {'rd': '7px'}, 'propertiesSource': {'rd': 'value'}},
                    'componentClassName': '',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'system',
                    'skin': 'wysiwyg.viewer.skins.AppPartSkin'
                },
                'custom': {
                    'template': {
                        'type': {
                            'name': 'Services_i6jcds0014',
                            'version': 0,
                            'baseTypes': [],
                            'displayName': i18n.translate('i7g3m1dq.structure.custom.template.type.displayName'),
                            'fields': [{
                                'displayName': i18n.translate('i7g3m1dq.structure.custom.template.type.fields.0.displayName'),
                                'name': 'title',
                                'searchable': false,
                                'type': 'String',
                                'defaultValue': i18n.translate('i7g3m1dq.structure.custom.template.type.fields.0.defaultValue'),
                                'computed': false
                            }, {
                                'name': 'i6kispsk',
                                'searchable': false,
                                'displayName': i18n.translate('i7g3m1dq.structure.custom.template.type.fields.1.displayName'),
                                'type': 'String',
                                'computed': false,
                                'defaultValue': i18n.translate('i7g3m1dq.structure.custom.template.type.fields.1.defaultValue')
                            }, {
                                'type': 'wix:Date',
                                'searchable': false,
                                'name': 'i92t9x5c',
                                'displayName': i18n.translate('i7g3m1dq.structure.custom.template.type.fields.2.displayName'),
                                'computed': false,
                                'defaultValue': {'iso': '2020-01-01T00:00:00.000Z', '_type': 'wix:Date'}
                            }, {
                                'displayName': i18n.translate('i7g3m1dq.structure.custom.template.type.fields.3.displayName'),
                                'name': 'wxRchTxt_sTxt4',
                                'defaultValue': {
                                    'version': 1,
                                    '_type': 'wix:RichText',
                                    'links': [],
                                    'text': i18n.translate('i7g3m1dq.structure.custom.template.type.fields.3.defaultValue')
                                },
                                'type': 'wix:RichText',
                                'searchable': false,
                                'computed': false
                            }, {
                                'metadata': {'showAsHint': 'AsPrice'},
                                'displayName': i18n.translate('i7g3m1dq.structure.custom.template.type.fields.4.displayName'),
                                'searchable': false,
                                'type': 'String',
                                'defaultValue': i18n.translate('i7g3m1dq.structure.custom.template.type.fields.4.defaultValue'),
                                'name': 'i92tfw6w',
                                'computed': false
                            }, {
                                'type': 'wix:Image',
                                'defaultValue': {
                                    'height': 1276,
                                    'src': 'c8381ce0e7a94ad09ceaada2fc64081d.jpg',
                                    'width': 1920,
                                    '_type': 'wix:Image',
                                    'title': ''
                                },
                                'displayName': i18n.translate('i7g3m1dq.structure.custom.template.type.fields.5.displayName'),
                                'searchable': false,
                                'name': 'image',
                                'computed': false
                            }, {
                                'name': 'i6lyw5qu',
                                'defaultValue': {
                                    'videoId': 'f36EEfI0yr8',
                                    '_type': 'wix:Video',
                                    'videoType': 'YOUTUBE'
                                },
                                'type': 'wix:Video',
                                'searchable': false,
                                'displayName': i18n.translate('i7g3m1dq.structure.custom.template.type.fields.6.displayName'),
                                'computed': false
                            }, {
                                'defaultValue': i18n.translate('i7g3m1dq.structure.custom.template.type.fields.7.defaultValue'),
                                'searchable': false,
                                'metadata': {'showAsHint': 'AsButton'},
                                'type': 'String',
                                'displayName': i18n.translate('i7g3m1dq.structure.custom.template.type.fields.7.displayName'),
                                'name': 'Strng_sBttn0-78w',
                                'computed': false
                            }, {
                                'name': 'links',
                                'type': 'wix:Map',
                                'searchable': false,
                                'displayName': '',
                                'defaultValue': {'_type': 'wix:Map'},
                                'computed': false
                            }]
                        },
                        'views': [{
                            'name': 'ServicesView_i6jcds0215_dup_i6kihtrp247_dup_i6qm3ozn324_dup_i6s04rdr361_dup_i70h8xoh185_dup_i7g3m1bo284',
                            'forType': 'Array',
                            'vars': {'itemSpacing': 50, 'paginationColor': 'color_15'},
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'paginatedlist',
                                    'comp': {
                                        'name': 'PaginatedList',
                                        'hidden': {'$expr': 'eq(Array.length(this), 0)'},
                                        'hidePagination': 'true',
                                        'itemsPerPage': '10',
                                        'templates': {
                                            'item': {
                                                'data': '$positionInParent',
                                                'comp': {
                                                    'name': 'SwitchBox',
                                                    'cases': {
                                                        'default': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'ServicesView_i6jcds0215_dup_i6kihtrp247_dup_i6qm3ozn324_dup_i6s04rdr361_dup_i70h8xoh185_dup_i7g3m1bo284'}
                                                        }, {
                                                            'id': 'footerSpacer',
                                                            'comp': {
                                                                'name': 'VSpacer',
                                                                'size': {'$expr': '$itemSpacing'}
                                                            }
                                                        }, {
                                                            'id': 'gutterLine',
                                                            'comp': {'name': 'HorizontalLine', 'hidden': 'true'},
                                                            'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                        }],
                                                        'last': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'ServicesView_i6jcds0215_dup_i6kihtrp247_dup_i6qm3ozn324_dup_i6s04rdr361_dup_i70h8xoh185_dup_i7g3m1bo284'}
                                                        }, {
                                                            'comp': {
                                                                'name': 'VBox',
                                                                'hidden': 'true',
                                                                'items': [{
                                                                    'id': 'footerSpacer',
                                                                    'comp': {
                                                                        'name': 'VSpacer',
                                                                        'size': {'$expr': '$itemSpacing'}
                                                                    }
                                                                }, {
                                                                    'id': 'gutterLine',
                                                                    'comp': {
                                                                        'name': 'HorizontalLine',
                                                                        'hidden': 'true'
                                                                    },
                                                                    'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                                }]
                                                            }, 'id': 'def_7'
                                                        }, {
                                                            'comp': {'name': 'VSpacer', 'size': '9'},
                                                            'id': 'def_10'
                                                        }, {
                                                            'id': 'paginationNavigationBox',
                                                            'comp': {
                                                                'name': 'HBox',
                                                                'hidden': {'$expr': '$hidePagination'},
                                                                'box-align': 'center',
                                                                'items': [{
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_12'
                                                                }, {
                                                                    'value': '◄',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationPrev',
                                                                        'events': {'dom:click': 'prevPageClicked'}
                                                                    },
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_13'
                                                                }, {
                                                                    'value': {'$expr': 'String.concat($currentPage,\' / \',$maxPage)'},
                                                                    'comp': {'name': 'Label'},
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_14'
                                                                }, {
                                                                    'value': '►',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationNext',
                                                                        'events': {'dom:click': 'nextPageClicked'}
                                                                    },
                                                                    'id': 'def_15'
                                                                }, {
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_16'
                                                                }]
                                                            },
                                                            'layout': {'position': 'relative'}
                                                        }]
                                                    },
                                                    'css': {'min-width': '100%'}
                                                },
                                                'id': '$positionInParent'
                                            }
                                        }
                                    }
                                }, {
                                    'id': 'noItemsLabel',
                                    'value': 'There are no items in this list',
                                    'comp': {'name': 'Label', 'hidden': {'$expr': 'gt(String.length(this), 0)'}}
                                }],
                                'editorData': {'isPaginated': true}
                            },
                            'stylesheet': [{
                                '.paginationPrev, .paginationNext': {
                                    'padding': '0 5px',
                                    'display': 'inline-block',
                                    'font-size': '14px',
                                    'cursor': 'pointer',
                                    '-webkit-touch-callout': 'none',
                                    '-webkit-user-select': 'none',
                                    '-khtml-user-select': 'none',
                                    '-moz-user-select': 'none',
                                    '-ms-user-select': 'none',
                                    'user-select': 'none'
                                }
                            }],
                            'customizations': [{
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.hidePagination',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Split your list across multiple pages',
                                    'defaultVal': 'true',
                                    'trueVal': 'false',
                                    'spaceAfter': '10'
                                }
                            }, {
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.itemsPerPage',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '120',
                                    'minVal': '1',
                                    'label': 'Items Per Page',
                                    'spaceAfter': '20',
                                    'hiddenwhen': {
                                        'fieldId': 'paginatedlist',
                                        'key': 'comp.hidePagination',
                                        'value': 'true'
                                    }
                                }
                            }, {
                                'priority': 100,
                                'fieldId': 'vars',
                                'key': 'itemSpacing',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '300',
                                    'minVal': '0',
                                    'label': 'Item Spacing',
                                    'spaceAfter': '20'
                                }
                            }, {
                                'priority': 95,
                                'fieldId': 'gutterLine',
                                'key': 'comp.hidden',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Show item separator',
                                    'defaultVal': 'false',
                                    'trueVal': 'false'
                                }
                            }, {
                                'priority': 90,
                                'fieldId': 'gutterLine',
                                'key': 'comp.style',
                                'input': {
                                    'name': 'changestyle',
                                    'showAs': 'link',
                                    'label': 'Change Item Separator Style',
                                    'spaceAfter': '20'
                                }
                            }],
                            'id': 'i7g3m1bo',
                            'format': 'Mobile'
                        }, {
                            'name': 'ServicesView_i6jcds0215_dup_i6kihtrp247_dup_i6qm3ozn324_dup_i6s04rdr361_dup_i70h8xoh185_dup_i7g3m1bo284',
                            'forType': 'Array',
                            'vars': {'itemSpacing': 60, 'paginationColor': 'color_15'},
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'paginatedlist',
                                    'comp': {
                                        'name': 'PaginatedList',
                                        'hidden': {'$expr': 'eq(Array.length(this), 0)'},
                                        'hidePagination': 'true',
                                        'itemsPerPage': '10',
                                        'templates': {
                                            'item': {
                                                'data': '$positionInParent',
                                                'comp': {
                                                    'name': 'SwitchBox',
                                                    'cases': {
                                                        'default': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'ServicesView_i6jcds0215_dup_i6kihtrp247_dup_i6qm3ozn324_dup_i6s04rdr361_dup_i70h8xoh185_dup_i7g3m1bo284'}
                                                        }, {
                                                            'id': 'footerSpacer',
                                                            'comp': {
                                                                'name': 'VSpacer',
                                                                'size': {'$expr': '$itemSpacing'}
                                                            }
                                                        }, {
                                                            'id': 'gutterLine',
                                                            'comp': {'name': 'HorizontalLine', 'hidden': 'true'},
                                                            'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                        }],
                                                        'last': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'ServicesView_i6jcds0215_dup_i6kihtrp247_dup_i6qm3ozn324_dup_i6s04rdr361_dup_i70h8xoh185_dup_i7g3m1bo284'}
                                                        }, {
                                                            'comp': {
                                                                'name': 'VBox',
                                                                'hidden': 'true',
                                                                'items': [{
                                                                    'id': 'footerSpacer',
                                                                    'comp': {
                                                                        'name': 'VSpacer',
                                                                        'size': {'$expr': '$itemSpacing'}
                                                                    }
                                                                }, {
                                                                    'id': 'gutterLine',
                                                                    'comp': {
                                                                        'name': 'HorizontalLine',
                                                                        'hidden': 'true'
                                                                    },
                                                                    'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                                }]
                                                            }, 'id': 'def_7'
                                                        }, {
                                                            'comp': {'name': 'VSpacer', 'size': '9'},
                                                            'id': 'def_10'
                                                        }, {
                                                            'id': 'paginationNavigationBox',
                                                            'comp': {
                                                                'name': 'HBox',
                                                                'hidden': {'$expr': '$hidePagination'},
                                                                'box-align': 'center',
                                                                'items': [{
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_12'
                                                                }, {
                                                                    'value': '◄',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationPrev',
                                                                        'events': {'dom:click': 'prevPageClicked'}
                                                                    },
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_13'
                                                                }, {
                                                                    'value': {'$expr': 'String.concat($currentPage,\' / \',$maxPage)'},
                                                                    'comp': {'name': 'Label'},
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_14'
                                                                }, {
                                                                    'value': '►',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationNext',
                                                                        'events': {'dom:click': 'nextPageClicked'}
                                                                    },
                                                                    'id': 'def_15'
                                                                }, {
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_16'
                                                                }]
                                                            },
                                                            'layout': {'position': 'relative'}
                                                        }]
                                                    },
                                                    'css': {'min-width': '100%'}
                                                },
                                                'id': '$positionInParent'
                                            }
                                        }
                                    }
                                }, {
                                    'id': 'noItemsLabel',
                                    'value': 'There are no items in this list',
                                    'comp': {'name': 'Label', 'hidden': {'$expr': 'gt(String.length(this), 0)'}}
                                }],
                                'editorData': {'isPaginated': true}
                            },
                            'stylesheet': [{
                                '.paginationPrev, .paginationNext': {
                                    'padding': '0 5px',
                                    'display': 'inline-block',
                                    'font-size': '14px',
                                    'cursor': 'pointer',
                                    '-webkit-touch-callout': 'none',
                                    '-webkit-user-select': 'none',
                                    '-khtml-user-select': 'none',
                                    '-moz-user-select': 'none',
                                    '-ms-user-select': 'none',
                                    'user-select': 'none'
                                }
                            }],
                            'customizations': [{
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.hidePagination',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Split your list across multiple pages',
                                    'defaultVal': 'true',
                                    'trueVal': 'false',
                                    'spaceAfter': '10'
                                }
                            }, {
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.itemsPerPage',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '120',
                                    'minVal': '1',
                                    'label': 'Items Per Page',
                                    'spaceAfter': '20',
                                    'hiddenwhen': {
                                        'fieldId': 'paginatedlist',
                                        'key': 'comp.hidePagination',
                                        'value': 'true'
                                    }
                                }
                            }, {
                                'priority': 100,
                                'fieldId': 'vars',
                                'key': 'itemSpacing',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '300',
                                    'minVal': '0',
                                    'label': 'Item Spacing',
                                    'spaceAfter': '20'
                                }
                            }, {
                                'priority': 95,
                                'fieldId': 'gutterLine',
                                'key': 'comp.hidden',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Show item separator',
                                    'defaultVal': 'false',
                                    'trueVal': 'false'
                                }
                            }, {
                                'priority': 90,
                                'fieldId': 'gutterLine',
                                'key': 'comp.style',
                                'input': {
                                    'name': 'changestyle',
                                    'showAs': 'link',
                                    'label': 'Change Item Separator Style',
                                    'spaceAfter': '20'
                                }
                            }],
                            'id': 'i7g3m1bo'
                        }, {
                            'name': 'ServicesView_i6jcds0215_dup_i6kihtrp247_dup_i6qm3ozn324_dup_i6s04rdr361_dup_i70h8xoh185_dup_i7g3m1bo284',
                            'forType': 'Services_i6jcds0014',
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'Top',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Top section',
                                            'alignment': 'center',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 3}
                                        },
                                        'items': []
                                    },
                                    'layout': {'min-width': 0}
                                }, {
                                    'id': 'topSpacer',
                                    'comp': {'name': 'VSpacer', 'size': '0'}
                                }, {
                                    'comp': {
                                        'name': 'HBox',
                                        'items': [{
                                            'id': 'Left',
                                            'comp': {
                                                'name': 'FieldBox',
                                                'orientation': 'horizontal',
                                                'items': [{
                                                    'id': 'image_1',
                                                    'data': 'image',
                                                    'comp': {
                                                        'name': 'Field',
                                                        'width': 124,
                                                        'items': [{
                                                            'id': 'image_1_proxy',
                                                            'data': 'image',
                                                            'comp': {
                                                                'name': 'Image',
                                                                'style': 'wp2',
                                                                'imageMode': 'fitWidth'
                                                            }
                                                        }],
                                                        'hidden': false,
                                                        'height': 124,
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 24,
                                                            'xax-before': 2,
                                                            'xax-after': 0
                                                        },
                                                        'box-align': 'start',
                                                        'heightMode': 'auto'
                                                    }
                                                }, {
                                                    'id': 'fld_i70l2tbw1080',
                                                    'data': 'i6lyw5qu',
                                                    'comp': {
                                                        'name': 'Field',
                                                        'width': 343,
                                                        'items': [{
                                                            'id': 'fld_i70l2tbw1080_proxy',
                                                            'data': 'i6lyw5qu',
                                                            'comp': {'name': 'Video', 'lightTheme': false},
                                                            'layout': {'position': 'relative'}
                                                        }],
                                                        'hidden': true,
                                                        'height': 225,
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 24,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'box-align': 'left'
                                                    }
                                                }],
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'alignment': 'left',
                                                    'displayName': 'Left section',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 0,
                                                        'xax-after': 10
                                                    }
                                                },
                                                'pack': 'center'
                                            },
                                            'layout': {'spacerAfter': 0, 'min-width': 0}
                                        }, {
                                            'id': 'leftToCenterSpacer',
                                            'comp': {'name': 'HSpacer', 'size': '0'}
                                        }, {
                                            'id': 'Center',
                                            'comp': {
                                                'name': 'FieldBox',
                                                'orientation': 'vertical',
                                                'pack': 'start',
                                                'items': [{
                                                    'id': 'fld_i92t9x6i33',
                                                    'data': 'i92t9x5c',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0',
                                                        'items': [{
                                                            'id': 'fld_i92t9x6i33_proxy',
                                                            'data': 'i92t9x5c',
                                                            'comp': {
                                                                'name': 'Date',
                                                                'format': 'dddd, mmmm dd, yyyy',
                                                                'color': 'color_14'
                                                            },
                                                            'layout': {'text-align': 'left'}
                                                        }],
                                                        'hidden': true,
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 4,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        }
                                                    },
                                                    'experiment': 'NewListFields'
                                                }, {
                                                    'id': 'text_1',
                                                    'data': 'title',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'items': [{
                                                            'id': 'text_1_proxy',
                                                            'data': 'title',
                                                            'comp': {
                                                                'name': 'Label',
                                                                'style': 'Heading S',
                                                                'bold': false,
                                                                'underline': false,
                                                                'italic': false,
                                                                'color': 'color_14'
                                                            },
                                                            'layout': {'text-align': 'left'}
                                                        }],
                                                        'hidden': false,
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 0,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'width-mode': 'auto',
                                                        'min-lines': 0,
                                                        'max-lines': 0,
                                                        'height-mode': 'auto'
                                                    }
                                                }, {
                                                    'id': 'fld_i70kwaon1040',
                                                    'data': 'i6kispsk',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'items': [{
                                                            'id': 'fld_i70kwaon1040_proxy',
                                                            'data': 'i6kispsk',
                                                            'comp': {
                                                                'name': 'Label',
                                                                'style': 'Body L',
                                                                'color': 'color_14'
                                                            },
                                                            'layout': {'text-align': 'left'}
                                                        }],
                                                        'hidden': true,
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 3,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0'
                                                    }
                                                }, {
                                                    'id': 'fld_hfxsv1z6',
                                                    'data': 'wxRchTxt_sTxt4',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'items': [{
                                                            'id': 'fld_hfxsv1z6_proxy',
                                                            'data': 'wxRchTxt_sTxt4',
                                                            'comp': {'name': 'Label', 'color': 'color_14'},
                                                            'layout': {'text-align': 'left'}
                                                        }],
                                                        'hidden': false,
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 9,
                                                            'after': 0,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0'
                                                    }
                                                }, {
                                                    'id': 'fld_i92tfw7w38',
                                                    'data': 'i92tfw6w',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'items': [{
                                                            'id': 'fld_i92tfw7w38_proxy',
                                                            'data': 'i92tfw6w',
                                                            'comp': {
                                                                'name': 'Label',
                                                                'style': 'Body L',
                                                                'color': 'color_14'
                                                            },
                                                            'layout': {'text-align': 'left'}
                                                        }],
                                                        'hidden': true,
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 6,
                                                            'after': 0,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0'
                                                    },
                                                    'experiment': 'NewListFields'
                                                }, {
                                                    'id': 'fld_i70kujk51028',
                                                    'data': 'Strng_sBttn0-78w',
                                                    'comp': {
                                                        'name': 'Field',
                                                        'width': 160,
                                                        'items': [{
                                                            'id': 'fld_i70kujk51028_proxy',
                                                            'data': 'Strng_sBttn0-78w',
                                                            'comp': {
                                                                'name': 'Button2',
                                                                'style': 'b1',
                                                                'align': 'center'
                                                            },
                                                            'layout': {'position': 'relative'}
                                                        }],
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 24,
                                                            'after': 0,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'hidden': true,
                                                        'height': '40',
                                                        'box-align': 'start'
                                                    }
                                                }],
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'alignment': 'center',
                                                    'displayName': 'Center section',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 25,
                                                        'xax-after': 0
                                                    }
                                                }
                                            },
                                            'layout': {'box-flex': 1, 'min-width': 0}
                                        }, {
                                            'id': 'centerToRightSpacer',
                                            'comp': {'name': 'HSpacer', 'size': '0'}
                                        }, {
                                            'id': 'Right',
                                            'comp': {
                                                'name': 'FieldBox',
                                                'orientation': 'vertical',
                                                'pack': 'center',
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'displayName': 'Right section',
                                                    'alignment': 'left',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 3,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'items': []
                                            },
                                            'layout': {'min-width': 0}
                                        }]
                                    }, 'id': 'def_3'
                                }, {
                                    'id': 'Bottom',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Bottom section',
                                            'alignment': 'center',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 3}
                                        },
                                        'items': []
                                    },
                                    'layout': {'box-flex-pack': 'start', 'min-width': 0}
                                }, {'id': 'bottomSpacer', 'comp': {'name': 'VSpacer', 'size': '0'}}]
                            },
                            'editorData': {'wasChanged': true},
                            'id': 'def_0'
                        }, {
                            'name': 'ServicesView_i6jcds0215_dup_i6kihtrp247_dup_i6qm3ozn324_dup_i6s04rdr361_dup_i70h8xoh185_dup_i7g3m1bo284',
                            'forType': 'Services_i6jcds0014',
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'Top',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Top section',
                                            'alignment': 'center',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 0}
                                        },
                                        'items': [{
                                            'id': 'image_1',
                                            'data': 'image',
                                            'comp': {
                                                'name': 'Field',
                                                'width': 80,
                                                'items': [{
                                                    'id': 'image_1_proxy',
                                                    'data': 'image',
                                                    'comp': {
                                                        'name': 'Image',
                                                        'style': 'wp2',
                                                        'imageMode': 'fitWidth'
                                                    }
                                                }],
                                                'hidden': false,
                                                'height': 80,
                                                'heightMode': 'auto',
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 0,
                                                    'after': 8,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'box-align': 'start'
                                            }
                                        }, {
                                            'id': 'fld_i70l2tbw1080',
                                            'data': 'i6lyw5qu',
                                            'comp': {
                                                'name': 'Field',
                                                'width': 280,
                                                'items': [{
                                                    'id': 'fld_i70l2tbw1080_proxy',
                                                    'data': 'i6lyw5qu',
                                                    'comp': {'name': 'Video', 'lightTheme': false},
                                                    'layout': {'position': 'relative'}
                                                }],
                                                'hidden': true,
                                                'height': 233,
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 0,
                                                    'after': 8,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'box-align': 'start'
                                            }
                                        }]
                                    },
                                    'layout': {'min-width': 0}
                                }, {
                                    'id': 'topSpacer',
                                    'comp': {'name': 'VSpacer', 'size': 0}
                                }, {
                                    'comp': {
                                        'name': 'VBox',
                                        'items': [{
                                            'id': 'def_3_0',
                                            'comp': {
                                                'name': 'HBox',
                                                'orientation': 'horizontal',
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'displayName': 'def_3_0',
                                                    'alignment': 'left',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 3,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'items': [{
                                                    'id': 'Left',
                                                    'comp': {
                                                        'name': 'VBox',
                                                        'orientation': 'vertical',
                                                        'items': [{
                                                            'id': 'Left_0',
                                                            'comp': {
                                                                'name': 'FieldBox',
                                                                'items': [],
                                                                'orientation': 'horizontal',
                                                                'editorData': {
                                                                    'labelPosition': 'none',
                                                                    'displayName': 'Left_0',
                                                                    'alignment': 'left',
                                                                    'spacers': {
                                                                        'before': 0,
                                                                        'after': 3,
                                                                        'xax-before': 3,
                                                                        'xax-after': 0
                                                                    }
                                                                }
                                                            },
                                                            'layout': {'min-width': 0}
                                                        }, {
                                                            'id': 'Left_1',
                                                            'comp': {
                                                                'name': 'FieldBox',
                                                                'orientation': 'horizontal',
                                                                'editorData': {
                                                                    'labelPosition': 'none',
                                                                    'displayName': 'Left_1',
                                                                    'alignment': 'left',
                                                                    'spacers': {
                                                                        'before': 0,
                                                                        'after': 3,
                                                                        'xax-before': 3,
                                                                        'xax-after': 0
                                                                    }
                                                                },
                                                                'items': []
                                                            },
                                                            'layout': {'min-width': 0}
                                                        }],
                                                        'editorData': {
                                                            'labelPosition': 'none',
                                                            'alignment': 'left',
                                                            'displayName': 'Left section',
                                                            'spacers': {
                                                                'before': 0,
                                                                'after': 3,
                                                                'xax-before': 0,
                                                                'xax-after': 0
                                                            }
                                                        },
                                                        'pack': 'center'
                                                    },
                                                    'layout': {'spacerAfter': 0, 'min-width': 0}
                                                }]
                                            },
                                            'layout': {'min-width': 0}
                                        }, {
                                            'id': 'def_3_1',
                                            'comp': {
                                                'name': 'HBox',
                                                'orientation': 'horizontal',
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'displayName': 'def_3_1',
                                                    'alignment': 'left',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 3,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'items': [{
                                                    'id': 'leftToCenterSpacer',
                                                    'comp': {'name': 'HSpacer', 'size': 0}
                                                }, {
                                                    'id': 'Center',
                                                    'comp': {
                                                        'name': 'FieldBox',
                                                        'orientation': 'vertical',
                                                        'pack': 'start',
                                                        'items': [{
                                                            'id': 'fld_i99ydv1i191',
                                                            'data': 'i92t9x5c',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': '200',
                                                                'width-mode': 'auto',
                                                                'min-lines': '0',
                                                                'max-lines': '0',
                                                                'items': [{
                                                                    'id': 'fld_i99ydv1i191_proxy',
                                                                    'data': 'i92t9x5c',
                                                                    'comp': {
                                                                        'name': 'Date',
                                                                        'format': 'mmmm dd, yyyy',
                                                                        'color': 'color_14'
                                                                    },
                                                                    'layout': {'text-align': 'left'}
                                                                }],
                                                                'hidden': true,
                                                                'labelPosition': 'none',
                                                                'spacers': {
                                                                    'before': 0,
                                                                    'after': 3,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                }
                                                            },
                                                            'experiment': 'NewListFields'
                                                        }, {
                                                            'id': 'text_1',
                                                            'data': 'title',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': 240,
                                                                'items': [{
                                                                    'id': 'text_1_proxy',
                                                                    'data': 'title',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'style': 'Heading S',
                                                                        'bold': false,
                                                                        'underline': false,
                                                                        'italic': false,
                                                                        'color': 'color_14'
                                                                    },
                                                                    'layout': {'text-align': 'left'}
                                                                }],
                                                                'hidden': false,
                                                                'labelPosition': 'none',
                                                                'spacers': {
                                                                    'before': 0,
                                                                    'after': 0,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                },
                                                                'width-mode': 'auto',
                                                                'min-lines': 0,
                                                                'max-lines': 0,
                                                                'height-mode': 'auto'
                                                            }
                                                        }, {
                                                            'id': 'fld_i70kwaon1040',
                                                            'data': 'i6kispsk',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': 240,
                                                                'items': [{
                                                                    'id': 'fld_i70kwaon1040_proxy',
                                                                    'data': 'i6kispsk',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'style': 'Body L',
                                                                        'color': 'color_14'
                                                                    },
                                                                    'layout': {'text-align': 'left'}
                                                                }],
                                                                'hidden': true,
                                                                'labelPosition': 'none',
                                                                'spacers': {
                                                                    'before': 0,
                                                                    'after': 3,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                },
                                                                'width-mode': 'auto',
                                                                'min-lines': '0',
                                                                'max-lines': '0'
                                                            }
                                                        }, {
                                                            'id': 'fld_hfxsv1z6',
                                                            'data': 'wxRchTxt_sTxt4',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': 240,
                                                                'items': [{
                                                                    'id': 'fld_hfxsv1z6_proxy',
                                                                    'data': 'wxRchTxt_sTxt4',
                                                                    'comp': {'name': 'Label', 'color': 'color_14'},
                                                                    'layout': {'text-align': 'left'}
                                                                }],
                                                                'hidden': false,
                                                                'labelPosition': 'none',
                                                                'spacers': {
                                                                    'before': 4,
                                                                    'after': 8,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                },
                                                                'width-mode': 'auto',
                                                                'min-lines': '0',
                                                                'max-lines': '0'
                                                            }
                                                        }, {
                                                            'id': 'fld_i99ye0l9200',
                                                            'data': 'i92tfw6w',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': '200',
                                                                'items': [{
                                                                    'id': 'fld_i99ye0l9200_proxy',
                                                                    'data': 'i92tfw6w',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'color': 'color_14',
                                                                        'style': 'Body L'
                                                                    },
                                                                    'layout': {'text-align': 'left'}
                                                                }],
                                                                'hidden': true,
                                                                'labelPosition': 'none',
                                                                'spacers': {
                                                                    'before': 0,
                                                                    'after': 3,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                },
                                                                'width-mode': 'auto',
                                                                'min-lines': '0',
                                                                'max-lines': '0'
                                                            },
                                                            'experiment': 'NewListFields'
                                                        }, {
                                                            'id': 'fld_i70kujk51028',
                                                            'data': 'Strng_sBttn0-78w',
                                                            'comp': {
                                                                'name': 'Field',
                                                                'width': 280,
                                                                'height': 44,
                                                                'items': [{
                                                                    'id': 'fld_i70kujk51028_proxy',
                                                                    'data': 'Strng_sBttn0-78w',
                                                                    'comp': {
                                                                        'name': 'Button2',
                                                                        'style': 'b1',
                                                                        'align': 'center'
                                                                    },
                                                                    'layout': {'position': 'relative'}
                                                                }],
                                                                'hidden': true,
                                                                'labelPosition': 'none',
                                                                'spacers': {
                                                                    'before': 12,
                                                                    'after': 0,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                },
                                                                'box-align': 'start'
                                                            }
                                                        }],
                                                        'editorData': {
                                                            'labelPosition': 'none',
                                                            'alignment': 'center',
                                                            'displayName': 'Center section',
                                                            'spacers': {
                                                                'before': 0,
                                                                'after': 3,
                                                                'xax-before': 0,
                                                                'xax-after': 0
                                                            }
                                                        }
                                                    },
                                                    'layout': {'box-flex': 1, 'min-width': 0}
                                                }]
                                            },
                                            'layout': {'min-width': 0}
                                        }, {
                                            'id': 'def_3_2',
                                            'comp': {
                                                'name': 'HBox',
                                                'orientation': 'horizontal',
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'displayName': 'def_3_2',
                                                    'alignment': 'left',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 3,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'items': [{
                                                    'id': 'centerToRightSpacer',
                                                    'comp': {'name': 'HSpacer', 'size': 0}
                                                }, {
                                                    'id': 'Right',
                                                    'comp': {
                                                        'name': 'FieldBox',
                                                        'orientation': 'vertical',
                                                        'pack': 'center',
                                                        'editorData': {
                                                            'labelPosition': 'none',
                                                            'displayName': 'Right section',
                                                            'alignment': 'left',
                                                            'spacers': {
                                                                'before': 0,
                                                                'after': 3,
                                                                'xax-before': 0,
                                                                'xax-after': 0
                                                            }
                                                        },
                                                        'items': []
                                                    },
                                                    'layout': {'min-width': 0}
                                                }]
                                            },
                                            'layout': {'min-width': 0}
                                        }],
                                        'orientation': 'vertical'
                                    }, 'id': 'def_3'
                                }, {
                                    'id': 'Bottom',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Bottom section',
                                            'alignment': 'center',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 0}
                                        },
                                        'items': []
                                    },
                                    'layout': {'box-flex-pack': 'start', 'min-width': 0}
                                }, {'id': 'bottomSpacer', 'comp': {'name': 'VSpacer', 'size': 0}}]
                            },
                            'editorData': {'wasChanged': true, 'originalWidth': 680},
                            'id': 'def_0',
                            'format': 'Mobile'
                        }],
                        'items': [{
                            'title': i18n.translate('i7g3m1dq.structure.custom.template.items.0.title'),
                            'image': {
                                '_type': 'wix:Image',
                                'title': '',
                                'src': '8d13be_78dec6cdbe5d4ec0931bab70672ffdb6.png',
                                'width': 180,
                                'height': 180,
                                'alt': ''
                            },
                            'wxRchTxt_sTxt4': {
                                'version': 1,
                                '_type': 'wix:RichText',
                                'links': [],
                                'text': i18n.translate('i7g3m1dq.structure.custom.template.items.0.wxRchTxt_sTxt4')
                            },
                            'links': {'_type': 'wix:Map'},
                            'Strng_sBttn0-78w': i18n.translate('i7g3m1dq.structure.custom.template.items.0.Strng_sBttn0-78w'),
                            'i6kispsk': i18n.translate('i7g3m1dq.structure.custom.template.items.0.i6kispsk'),
                            'i6lyw5qu': {
                                '_type': 'wix:Video',
                                'videoId': 'z1pSAwjC4JI',
                                'videoType': 'YOUTUBE',
                                'thumbnail': ''
                            },
                            'i92t9x5c': {'_type': 'wix:Date', 'iso': '2023-01-03T00:00:00.000Z'},
                            'i92tfw6w': i18n.translate('i7g3m1dq.structure.custom.template.items.0.i92tfw6w')
                        }, {
                            'title': i18n.translate('i7g3m1dq.structure.custom.template.items.1.title'),
                            'image': {
                                '_type': 'wix:Image',
                                'title': '',
                                'src': '8d13be_de4ffaa2eb434790a80964d6c9f4d8d2.png',
                                'width': 180,
                                'height': 180,
                                'alt': ''
                            },
                            'wxRchTxt_sTxt4': {
                                'version': 1,
                                '_type': 'wix:RichText',
                                'links': [],
                                'text': i18n.translate('i7g3m1dq.structure.custom.template.items.1.wxRchTxt_sTxt4')
                            },
                            'links': {'_type': 'wix:Map'},
                            'Strng_sBttn0-78w': i18n.translate('i7g3m1dq.structure.custom.template.items.1.Strng_sBttn0-78w'),
                            'i6kispsk': i18n.translate('i7g3m1dq.structure.custom.template.items.1.i6kispsk'),
                            'i6lyw5qu': {
                                '_type': 'wix:Video',
                                'videoId': 'f36EEfI0yr8',
                                'videoType': 'YOUTUBE',
                                'thumbnail': ''
                            },
                            'i92t9x5c': {'_type': 'wix:Date', 'iso': '2023-02-23T00:00:00.000Z'},
                            'i92tfw6w': i18n.translate('i7g3m1dq.structure.custom.template.items.1.i92tfw6w')
                        }, {
                            'title': i18n.translate('i7g3m1dq.structure.custom.template.items.2.title'),
                            'image': {
                                '_type': 'wix:Image',
                                'title': '',
                                'src': '8d13be_1bea7b1df6eb4c7e8d177a1778b9a8f9.png',
                                'width': 180,
                                'height': 180,
                                'alt': ''
                            },
                            'wxRchTxt_sTxt4': {
                                'version': 1,
                                '_type': 'wix:RichText',
                                'links': [],
                                'text': i18n.translate('i7g3m1dq.structure.custom.template.items.2.wxRchTxt_sTxt4')
                            },
                            'links': {'_type': 'wix:Map'},
                            'Strng_sBttn0-78w': i18n.translate('i7g3m1dq.structure.custom.template.items.2.Strng_sBttn0-78w'),
                            'i6kispsk': i18n.translate('i7g3m1dq.structure.custom.template.items.2.i6kispsk'),
                            'i6lyw5qu': {
                                '_type': 'wix:Video',
                                'videoId': 'wGOTBSlYkzU',
                                'videoType': 'YOUTUBE',
                                'thumbnail': ''
                            },
                            'i92t9x5c': {'_type': 'wix:Date', 'iso': '2023-05-28T00:00:00.000Z'},
                            'i92tfw6w': i18n.translate('i7g3m1dq.structure.custom.template.items.2.i92tfw6w')
                        }],
                        'displayName': i18n.translate('i7g3m1dq.structure.custom.template.displayName')
                    }
                },
                'id': 'i7g3m1dq'
            },
            'preset': {
                'rect': {'width': 162, 'height': 170, 'x': 0, 'y': 0},
                'label': 'i7g3m1dq.structure.custom.template.displayName',
                'tags': null
            }
        }, {
            'id': 'Lists_List_Products_And_Services_2',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.viewer.skins.AppPartSkin',
                'layout': {
                    'width': 382,
                    'height': 1040,
                    'x': 751,
                    'y': 101,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wixapps.integration.components.AppPart2',
                'data': {
                    'type': 'AppBuilderComponent',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'appInnerID': '2',
                    'appPartName': 'appparti7g3f4mr6'
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'id': 'app1',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {'groups': {}, 'properties': {'rd': '7px'}, 'propertiesSource': {'rd': 'value'}},
                    'componentClassName': '',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'system',
                    'skin': 'wysiwyg.viewer.skins.AppPartSkin'
                },
                'custom': {
                    'template': {
                        'type': {
                            'fields': [{
                                'displayName': i18n.translate('i7g3f4my.structure.custom.template.type.fields.0.displayName'),
                                'name': 'title',
                                'searchable': false,
                                'type': 'String',
                                'defaultValue': i18n.translate('i7g3f4my.structure.custom.template.type.fields.0.defaultValue'),
                                'computed': false
                            }, {
                                'name': 'i6cq8gyo',
                                'searchable': false,
                                'displayName': i18n.translate('i7g3f4my.structure.custom.template.type.fields.1.displayName'),
                                'type': 'String',
                                'computed': false,
                                'defaultValue': i18n.translate('i7g3f4my.structure.custom.template.type.fields.1.defaultValue')
                            }, {
                                'type': 'wix:Date',
                                'searchable': false,
                                'displayName': i18n.translate('i7g3f4my.structure.custom.template.type.fields.2.displayName'),
                                'name': 'i92u9lg2',
                                'computed': false,
                                'defaultValue': {'iso': '2020-01-01T00:00:00.000Z', '_type': 'wix:Date'}
                            }, {
                                'displayName': i18n.translate('i7g3f4my.structure.custom.template.type.fields.3.displayName'),
                                'name': 'wxRchTxt_sTxt4',
                                'defaultValue': {
                                    'version': 1,
                                    '_type': 'wix:RichText',
                                    'links': [],
                                    'text': i18n.translate('i7g3f4my.structure.custom.template.type.fields.3.defaultValue')
                                },
                                'type': 'wix:RichText',
                                'searchable': false,
                                'computed': false
                            }, {
                                'metadata': {'showAsHint': 'AsPrice'},
                                'name': 'i92ukb3b',
                                'displayName': i18n.translate('i7g3f4my.structure.custom.template.type.fields.4.displayName'),
                                'searchable': false,
                                'type': 'String',
                                'defaultValue': i18n.translate('i7g3f4my.structure.custom.template.type.fields.4.defaultValue'),
                                'computed': false
                            }, {
                                'type': 'wix:Image',
                                'defaultValue': {
                                    'height': 1276,
                                    'src': 'c8381ce0e7a94ad09ceaada2fc64081d.jpg',
                                    'width': 1920,
                                    '_type': 'wix:Image',
                                    'title': ''
                                },
                                'displayName': i18n.translate('i7g3f4my.structure.custom.template.type.fields.5.displayName'),
                                'searchable': false,
                                'name': 'image',
                                'computed': false
                            }, {
                                'defaultValue': {
                                    'videoId': 'f36EEfI0yr8',
                                    '_type': 'wix:Video',
                                    'videoType': 'YOUTUBE'
                                },
                                'type': 'wix:Video',
                                'searchable': false,
                                'displayName': i18n.translate('i7g3f4my.structure.custom.template.type.fields.6.displayName'),
                                'name': 'i6cq85m1',
                                'computed': false
                            }, {
                                'defaultValue': i18n.translate('i7g3f4my.structure.custom.template.type.fields.7.defaultValue'),
                                'searchable': false,
                                'metadata': {'showAsHint': 'AsButton'},
                                'type': 'String',
                                'displayName': i18n.translate('i7g3f4my.structure.custom.template.type.fields.7.displayName'),
                                'name': 'Strng_sBttn0-78w',
                                'computed': false
                            }, {
                                'name': 'links',
                                'type': 'wix:Map',
                                'searchable': false,
                                'displayName': '',
                                'defaultValue': {'_type': 'wix:Map'},
                                'computed': false
                            }],
                            'version': 0,
                            'baseTypes': [],
                            'name': 'Services_i6cq7p6u1',
                            'displayName': i18n.translate('i7g3f4my.structure.custom.template.type.displayName')
                        },
                        'views': [{
                            'name': 'ServicesView_i6cq7p6v2_dup_i6lywp1v454_dup_i6qm3p88331_dup_i6s04rfa362_dup_i70h8xpk186_dup_i7g3f4kw234',
                            'forType': 'Array',
                            'vars': {'itemSpacing': 60, 'paginationColor': 'color_15'},
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'paginatedlist',
                                    'comp': {
                                        'name': 'PaginatedList',
                                        'hidden': {'$expr': 'eq(Array.length(this), 0)'},
                                        'hidePagination': 'true',
                                        'itemsPerPage': '10',
                                        'templates': {
                                            'item': {
                                                'data': '$positionInParent',
                                                'comp': {
                                                    'name': 'SwitchBox',
                                                    'cases': {
                                                        'default': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'ServicesView_i6cq7p6v2_dup_i6lywp1v454_dup_i6qm3p88331_dup_i6s04rfa362_dup_i70h8xpk186_dup_i7g3f4kw234'}
                                                        }, {
                                                            'id': 'footerSpacer',
                                                            'comp': {
                                                                'name': 'VSpacer',
                                                                'size': {'$expr': '$itemSpacing'}
                                                            }
                                                        }, {
                                                            'id': 'gutterLine',
                                                            'comp': {'name': 'HorizontalLine', 'hidden': 'true'},
                                                            'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                        }],
                                                        'last': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'ServicesView_i6cq7p6v2_dup_i6lywp1v454_dup_i6qm3p88331_dup_i6s04rfa362_dup_i70h8xpk186_dup_i7g3f4kw234'}
                                                        }, {
                                                            'comp': {
                                                                'name': 'VBox',
                                                                'hidden': 'true',
                                                                'items': [{
                                                                    'id': 'footerSpacer',
                                                                    'comp': {
                                                                        'name': 'VSpacer',
                                                                        'size': {'$expr': '$itemSpacing'}
                                                                    }
                                                                }, {
                                                                    'id': 'gutterLine',
                                                                    'comp': {
                                                                        'name': 'HorizontalLine',
                                                                        'hidden': 'true'
                                                                    },
                                                                    'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                                }]
                                                            }, 'id': 'def_7'
                                                        }, {
                                                            'comp': {'name': 'VSpacer', 'size': '9'},
                                                            'id': 'def_10'
                                                        }, {
                                                            'id': 'paginationNavigationBox',
                                                            'comp': {
                                                                'name': 'HBox',
                                                                'hidden': {'$expr': '$hidePagination'},
                                                                'box-align': 'center',
                                                                'items': [{
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_12'
                                                                }, {
                                                                    'value': '◄',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationPrev',
                                                                        'events': {'dom:click': 'prevPageClicked'}
                                                                    },
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_13'
                                                                }, {
                                                                    'value': {'$expr': 'String.concat($currentPage,\' / \',$maxPage)'},
                                                                    'comp': {'name': 'Label'},
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_14'
                                                                }, {
                                                                    'value': '►',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationNext',
                                                                        'events': {'dom:click': 'nextPageClicked'}
                                                                    },
                                                                    'id': 'def_15'
                                                                }, {
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_16'
                                                                }]
                                                            },
                                                            'layout': {'position': 'relative'}
                                                        }]
                                                    },
                                                    'css': {'min-width': '100%'}
                                                },
                                                'id': '$positionInParent'
                                            }
                                        }
                                    }
                                }, {
                                    'id': 'noItemsLabel',
                                    'value': 'There are no items in this list',
                                    'comp': {'name': 'Label', 'hidden': {'$expr': 'gt(String.length(this), 0)'}}
                                }],
                                'editorData': {'isPaginated': true}
                            },
                            'stylesheet': [{
                                '.paginationPrev, .paginationNext': {
                                    'padding': '0 5px',
                                    'display': 'inline-block',
                                    'font-size': '14px',
                                    'cursor': 'pointer',
                                    '-webkit-touch-callout': 'none',
                                    '-webkit-user-select': 'none',
                                    '-khtml-user-select': 'none',
                                    '-moz-user-select': 'none',
                                    '-ms-user-select': 'none',
                                    'user-select': 'none'
                                }
                            }],
                            'customizations': [{
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.hidePagination',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Split your list across multiple pages',
                                    'defaultVal': 'true',
                                    'trueVal': 'false',
                                    'spaceAfter': '10'
                                }
                            }, {
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.itemsPerPage',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '120',
                                    'minVal': '1',
                                    'label': 'Items Per Page',
                                    'spaceAfter': '20',
                                    'hiddenwhen': {
                                        'fieldId': 'paginatedlist',
                                        'key': 'comp.hidePagination',
                                        'value': 'true'
                                    }
                                }
                            }, {
                                'priority': 100,
                                'fieldId': 'vars',
                                'key': 'itemSpacing',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '300',
                                    'minVal': '0',
                                    'label': 'Item Spacing',
                                    'spaceAfter': '20'
                                }
                            }, {
                                'priority': 95,
                                'fieldId': 'gutterLine',
                                'key': 'comp.hidden',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Show item separator',
                                    'defaultVal': 'false',
                                    'trueVal': 'false'
                                }
                            }, {
                                'priority': 90,
                                'fieldId': 'gutterLine',
                                'key': 'comp.style',
                                'input': {
                                    'name': 'changestyle',
                                    'showAs': 'link',
                                    'label': 'Change Item Separator Style',
                                    'spaceAfter': '20'
                                }
                            }],
                            'id': 'i7g3f4kx'
                        }, {
                            'name': 'ServicesView_i6cq7p6v2_dup_i6lywp1v454_dup_i6qm3p88331_dup_i6s04rfa362_dup_i70h8xpk186_dup_i7g3f4kw234',
                            'forType': 'Array',
                            'vars': {'itemSpacing': 50, 'paginationColor': 'color_15'},
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'paginatedlist',
                                    'comp': {
                                        'name': 'PaginatedList',
                                        'hidden': {'$expr': 'eq(Array.length(this), 0)'},
                                        'hidePagination': 'true',
                                        'itemsPerPage': '10',
                                        'templates': {
                                            'item': {
                                                'data': '$positionInParent',
                                                'comp': {
                                                    'name': 'SwitchBox',
                                                    'cases': {
                                                        'default': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'ServicesView_i6cq7p6v2_dup_i6lywp1v454_dup_i6qm3p88331_dup_i6s04rfa362_dup_i70h8xpk186_dup_i7g3f4kw234'}
                                                        }, {
                                                            'id': 'footerSpacer',
                                                            'comp': {
                                                                'name': 'VSpacer',
                                                                'size': {'$expr': '$itemSpacing'}
                                                            }
                                                        }, {
                                                            'id': 'gutterLine',
                                                            'comp': {'name': 'HorizontalLine', 'hidden': 'true'},
                                                            'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                        }],
                                                        'last': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'ServicesView_i6cq7p6v2_dup_i6lywp1v454_dup_i6qm3p88331_dup_i6s04rfa362_dup_i70h8xpk186_dup_i7g3f4kw234'}
                                                        }, {
                                                            'comp': {
                                                                'name': 'VBox',
                                                                'hidden': 'true',
                                                                'items': [{
                                                                    'id': 'footerSpacer',
                                                                    'comp': {
                                                                        'name': 'VSpacer',
                                                                        'size': {'$expr': '$itemSpacing'}
                                                                    }
                                                                }, {
                                                                    'id': 'gutterLine',
                                                                    'comp': {
                                                                        'name': 'HorizontalLine',
                                                                        'hidden': 'true'
                                                                    },
                                                                    'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                                }]
                                                            }, 'id': 'def_7'
                                                        }, {
                                                            'comp': {'name': 'VSpacer', 'size': '9'},
                                                            'id': 'def_10'
                                                        }, {
                                                            'id': 'paginationNavigationBox',
                                                            'comp': {
                                                                'name': 'HBox',
                                                                'hidden': {'$expr': '$hidePagination'},
                                                                'box-align': 'center',
                                                                'items': [{
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_12'
                                                                }, {
                                                                    'value': '◄',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationPrev',
                                                                        'events': {'dom:click': 'prevPageClicked'}
                                                                    },
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_13'
                                                                }, {
                                                                    'value': {'$expr': 'String.concat($currentPage,\' / \',$maxPage)'},
                                                                    'comp': {'name': 'Label'},
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_14'
                                                                }, {
                                                                    'value': '►',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationNext',
                                                                        'events': {'dom:click': 'nextPageClicked'}
                                                                    },
                                                                    'id': 'def_15'
                                                                }, {
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_16'
                                                                }]
                                                            },
                                                            'layout': {'position': 'relative'}
                                                        }]
                                                    },
                                                    'css': {'min-width': '100%'}
                                                },
                                                'id': '$positionInParent'
                                            }
                                        }
                                    }
                                }, {
                                    'id': 'noItemsLabel',
                                    'value': 'There are no items in this list',
                                    'comp': {'name': 'Label', 'hidden': {'$expr': 'gt(String.length(this), 0)'}}
                                }],
                                'editorData': {'isPaginated': true}
                            },
                            'stylesheet': [{
                                '.paginationPrev, .paginationNext': {
                                    'padding': '0 5px',
                                    'display': 'inline-block',
                                    'font-size': '14px',
                                    'cursor': 'pointer',
                                    '-webkit-touch-callout': 'none',
                                    '-webkit-user-select': 'none',
                                    '-khtml-user-select': 'none',
                                    '-moz-user-select': 'none',
                                    '-ms-user-select': 'none',
                                    'user-select': 'none'
                                }
                            }],
                            'customizations': [{
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.hidePagination',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Split your list across multiple pages',
                                    'defaultVal': 'true',
                                    'trueVal': 'false',
                                    'spaceAfter': '10'
                                }
                            }, {
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.itemsPerPage',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '120',
                                    'minVal': '1',
                                    'label': 'Items Per Page',
                                    'spaceAfter': '20',
                                    'hiddenwhen': {
                                        'fieldId': 'paginatedlist',
                                        'key': 'comp.hidePagination',
                                        'value': 'true'
                                    }
                                }
                            }, {
                                'priority': 100,
                                'fieldId': 'vars',
                                'key': 'itemSpacing',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '300',
                                    'minVal': '0',
                                    'label': 'Item Spacing',
                                    'spaceAfter': '20'
                                }
                            }, {
                                'priority': 95,
                                'fieldId': 'gutterLine',
                                'key': 'comp.hidden',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Show item separator',
                                    'defaultVal': 'false',
                                    'trueVal': 'false'
                                }
                            }, {
                                'priority': 90,
                                'fieldId': 'gutterLine',
                                'key': 'comp.style',
                                'input': {
                                    'name': 'changestyle',
                                    'showAs': 'link',
                                    'label': 'Change Item Separator Style',
                                    'spaceAfter': '20'
                                }
                            }],
                            'id': 'i7g3f4kx',
                            'format': 'Mobile'
                        }, {
                            'name': 'ServicesView_i6cq7p6v2_dup_i6lywp1v454_dup_i6qm3p88331_dup_i6s04rfa362_dup_i70h8xpk186_dup_i7g3f4kw234',
                            'forType': 'Services_i6cq7p6u1',
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'Top',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Top section',
                                            'alignment': 'center',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 3}
                                        },
                                        'items': [{
                                            'id': 'fld_i70lf8ys1190',
                                            'data': 'i6cq85m1',
                                            'comp': {
                                                'name': 'Field',
                                                'width': 370,
                                                'items': [{
                                                    'id': 'fld_i70lf8ys1190_proxy',
                                                    'data': 'i6cq85m1',
                                                    'comp': {'name': 'Video'},
                                                    'layout': {'position': 'relative'}
                                                }],
                                                'hidden': true,
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 0,
                                                    'after': 10,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'height': '220',
                                                'box-align': 'center'
                                            }
                                        }, {
                                            'id': 'image_1',
                                            'data': 'image',
                                            'comp': {
                                                'name': 'Field',
                                                'width': 100,
                                                'height': 100,
                                                'items': [{
                                                    'id': 'image_1_proxy',
                                                    'data': 'image',
                                                    'comp': {
                                                        'name': 'Image',
                                                        'style': 'wp2',
                                                        'imageMode': 'fitWidth'
                                                    }
                                                }],
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 0,
                                                    'after': 4,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'box-align': 'center',
                                                'hidden': false,
                                                'heightMode': 'auto'
                                            }
                                        }]
                                    },
                                    'layout': {'min-width': 0}
                                }, {
                                    'id': 'topSpacer',
                                    'comp': {'name': 'VSpacer', 'size': '0'}
                                }, {
                                    'comp': {
                                        'name': 'HBox',
                                        'items': [{
                                            'id': 'Left',
                                            'comp': {
                                                'name': 'FieldBox',
                                                'orientation': 'vertical',
                                                'items': [],
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'alignment': 'left',
                                                    'displayName': 'Left section',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 0,
                                                        'xax-after': 10
                                                    }
                                                }
                                            },
                                            'layout': {'spacerAfter': 0, 'min-width': 0}
                                        }, {
                                            'id': 'leftToCenterSpacer',
                                            'comp': {'name': 'HSpacer', 'size': '0'}
                                        }, {
                                            'id': 'Center',
                                            'comp': {
                                                'name': 'FieldBox',
                                                'orientation': 'vertical',
                                                'pack': 'center',
                                                'items': [{
                                                    'id': 'text_1',
                                                    'data': 'title',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'items': [{
                                                            'id': 'text_1_proxy',
                                                            'data': 'title',
                                                            'comp': {
                                                                'style': 'Heading S',
                                                                'name': 'Label',
                                                                'bold': false,
                                                                'underline': false,
                                                                'italic': false,
                                                                'color': 'color_14'
                                                            },
                                                            'layout': {'text-align': 'center'}
                                                        }],
                                                        'hidden': false,
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 10,
                                                            'after': 0,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'width-mode': 'auto',
                                                        'min-lines': 0,
                                                        'max-lines': 0,
                                                        'height-mode': 'auto'
                                                    }
                                                }, {
                                                    'id': 'fld_i70l9wpo1124',
                                                    'data': 'i6cq8gyo',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0',
                                                        'items': [{
                                                            'id': 'fld_i70l9wpo1124_proxy',
                                                            'data': 'i6cq8gyo',
                                                            'comp': {
                                                                'name': 'Label',
                                                                'style': 'Body L',
                                                                'color': 'color_14'
                                                            },
                                                            'layout': {'text-align': 'center'}
                                                        }],
                                                        'hidden': true,
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 0,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        }
                                                    }
                                                }, {
                                                    'id': 'fld_i92u9lgx92',
                                                    'data': 'i92u9lg2',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0',
                                                        'items': [{
                                                            'id': 'fld_i92u9lgx92_proxy',
                                                            'data': 'i92u9lg2',
                                                            'comp': {
                                                                'name': 'Date',
                                                                'format': 'mmmm dd, yyyy',
                                                                'style': 'Body M',
                                                                'color': 'color_14'
                                                            },
                                                            'layout': {'text-align': 'center'}
                                                        }],
                                                        'hidden': true,
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 3,
                                                            'after': 6,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        }
                                                    },
                                                    'experiment': 'NewListFields'
                                                }, {
                                                    'id': 'fld_i92ug8qp21',
                                                    'data': 'wxRchTxt_sTxt4',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0',
                                                        'items': [{
                                                            'id': 'fld_i92ug8qp21_proxy',
                                                            'data': 'wxRchTxt_sTxt4',
                                                            'comp': {'name': 'Label', 'color': 'color_14'},
                                                            'layout': {'text-align': 'center'}
                                                        }],
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 6,
                                                            'after': 0,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'hidden': false
                                                    }
                                                }, {
                                                    'experiment': 'NewListFields',
                                                    'id': 'fld_i92ukb4s67',
                                                    'data': 'i92ukb3b',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0',
                                                        'items': [{
                                                            'id': 'fld_i92ukb4s67_proxy',
                                                            'data': 'i92ukb3b',
                                                            'comp': {
                                                                'name': 'Label',
                                                                'color': 'color_14',
                                                                'style': 'Heading S'
                                                            },
                                                            'layout': {'text-align': 'center'}
                                                        }],
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 10,
                                                            'after': 0,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'hidden': true
                                                    }
                                                }],
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'alignment': 'center',
                                                    'displayName': 'Center section',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 25,
                                                        'xax-after': 0
                                                    }
                                                }
                                            },
                                            'layout': {'box-flex': 1, 'min-width': 0}
                                        }, {
                                            'id': 'centerToRightSpacer',
                                            'comp': {'name': 'HSpacer', 'size': '0'}
                                        }, {
                                            'id': 'Right',
                                            'comp': {
                                                'name': 'FieldBox',
                                                'orientation': 'vertical',
                                                'pack': 'center',
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'displayName': 'Right section',
                                                    'alignment': 'left',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 3,
                                                        'xax-after': 0
                                                    }
                                                }
                                            },
                                            'layout': {'min-width': 0}
                                        }]
                                    }, 'id': 'def_3'
                                }, {
                                    'id': 'Bottom',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Bottom section',
                                            'alignment': 'center',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 3}
                                        },
                                        'items': [{
                                            'id': 'fld_i6cr8b19211',
                                            'data': 'Strng_sBttn0-78w',
                                            'comp': {
                                                'name': 'Field',
                                                'width': 160,
                                                'items': [{
                                                    'id': 'fld_i6cr8b19211_proxy',
                                                    'data': 'Strng_sBttn0-78w',
                                                    'comp': {'name': 'Button2', 'style': 'b1', 'align': 'center'},
                                                    'layout': {'position': 'relative'}
                                                }],
                                                'hidden': false,
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 24,
                                                    'after': 0,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'height': 40,
                                                'box-align': 'center'
                                            }
                                        }]
                                    },
                                    'layout': {'box-flex-pack': 'start', 'min-width': 0}
                                }, {'id': 'bottomSpacer', 'comp': {'name': 'VSpacer', 'size': '0'}}]
                            },
                            'editorData': {'wasChanged': true},
                            'id': 'def_0'
                        }, {
                            'name': 'ServicesView_i6cq7p6v2_dup_i6lywp1v454_dup_i6qm3p88331_dup_i6s04rfa362_dup_i70h8xpk186_dup_i7g3f4kw234',
                            'forType': 'Services_i6cq7p6u1',
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'Top',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Top section',
                                            'alignment': 'center',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 0}
                                        },
                                        'items': [{
                                            'id': 'image_1',
                                            'data': 'image',
                                            'comp': {
                                                'name': 'Field',
                                                'width': 100,
                                                'items': [{
                                                    'id': 'image_1_proxy',
                                                    'data': 'image',
                                                    'comp': {
                                                        'name': 'Image',
                                                        'style': 'wp2',
                                                        'imageMode': 'fitWidth'
                                                    }
                                                }],
                                                'hidden': false,
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 0,
                                                    'after': 8,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'height': 100,
                                                'box-align': 'center',
                                                'heightMode': 'auto'
                                            }
                                        }, {
                                            'id': 'fld_i70lf8ys1190',
                                            'data': 'i6cq85m1',
                                            'comp': {
                                                'name': 'Field',
                                                'width': 280,
                                                'height': '220',
                                                'items': [{
                                                    'id': 'fld_i70lf8ys1190_proxy',
                                                    'data': 'i6cq85m1',
                                                    'comp': {'name': 'Video'},
                                                    'layout': {'position': 'relative'}
                                                }],
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 0,
                                                    'after': 10,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'box-align': 'center',
                                                'hidden': true
                                            }
                                        }]
                                    },
                                    'layout': {'min-width': 0}
                                }, {
                                    'id': 'topSpacer',
                                    'comp': {'name': 'VSpacer', 'size': 0}
                                }, {
                                    'comp': {
                                        'name': 'VBox',
                                        'items': [{
                                            'id': 'def_3_0',
                                            'comp': {
                                                'name': 'HBox',
                                                'orientation': 'horizontal',
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'displayName': 'def_3_0',
                                                    'alignment': 'left',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 3,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'items': [{
                                                    'id': 'Left',
                                                    'comp': {
                                                        'name': 'FieldBox',
                                                        'orientation': 'vertical',
                                                        'items': [],
                                                        'editorData': {
                                                            'labelPosition': 'none',
                                                            'alignment': 'left',
                                                            'displayName': 'Left section',
                                                            'spacers': {
                                                                'before': 0,
                                                                'after': 3,
                                                                'xax-before': 0,
                                                                'xax-after': 0
                                                            }
                                                        }
                                                    },
                                                    'layout': {'spacerAfter': 0, 'min-width': 0}
                                                }, {
                                                    'id': 'leftToCenterSpacer',
                                                    'comp': {'name': 'HSpacer', 'size': 0}
                                                }, {
                                                    'id': 'Center',
                                                    'comp': {
                                                        'name': 'FieldBox',
                                                        'orientation': 'vertical',
                                                        'pack': 'center',
                                                        'items': [{
                                                            'id': 'text_1',
                                                            'data': 'title',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': 240,
                                                                'items': [{
                                                                    'id': 'text_1_proxy',
                                                                    'data': 'title',
                                                                    'comp': {
                                                                        'style': 'Heading S',
                                                                        'name': 'Label',
                                                                        'bold': false,
                                                                        'underline': false,
                                                                        'italic': false,
                                                                        'color': 'color_14'
                                                                    },
                                                                    'layout': {'text-align': 'center'}
                                                                }],
                                                                'hidden': false,
                                                                'labelPosition': 'none',
                                                                'spacers': {
                                                                    'before': 10,
                                                                    'after': 0,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                },
                                                                'width-mode': 'auto',
                                                                'min-lines': 0,
                                                                'max-lines': 0,
                                                                'height-mode': 'auto'
                                                            }
                                                        }, {
                                                            'id': 'fld_i70l9wpo1124',
                                                            'data': 'i6cq8gyo',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': 240,
                                                                'width-mode': 'auto',
                                                                'min-lines': '0',
                                                                'max-lines': '0',
                                                                'items': [{
                                                                    'id': 'fld_i70l9wpo1124_proxy',
                                                                    'data': 'i6cq8gyo',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'style': 'Body L',
                                                                        'color': 'color_14'
                                                                    },
                                                                    'layout': {'text-align': 'center'}
                                                                }],
                                                                'hidden': true,
                                                                'labelPosition': 'none',
                                                                'spacers': {
                                                                    'before': 0,
                                                                    'after': 0,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                }
                                                            }
                                                        }, {
                                                            'id': 'fld_i99zez1d28',
                                                            'data': 'i92u9lg2',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': '200',
                                                                'width-mode': 'auto',
                                                                'min-lines': '0',
                                                                'max-lines': '0',
                                                                'items': [{
                                                                    'id': 'fld_i99zez1d28_proxy',
                                                                    'data': 'i92u9lg2',
                                                                    'comp': {
                                                                        'name': 'Date',
                                                                        'format': 'mmmm dd, yyyy',
                                                                        'color': 'color_14'
                                                                    },
                                                                    'layout': {'text-align': 'center'}
                                                                }],
                                                                'hidden': true,
                                                                'labelPosition': 'none',
                                                                'spacers': {
                                                                    'before': 4,
                                                                    'after': 0,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                }
                                                            },
                                                            'experiment': 'NewListFields'
                                                        }, {
                                                            'id': 'fld_hfxsv1z6',
                                                            'data': 'wxRchTxt_sTxt4',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': 240,
                                                                'width-mode': 'auto',
                                                                'min-lines': '0',
                                                                'max-lines': '0',
                                                                'items': [{
                                                                    'id': 'fld_hfxsv1z6_proxy',
                                                                    'data': 'wxRchTxt_sTxt4',
                                                                    'comp': {'name': 'Label', 'color': 'color_14'},
                                                                    'layout': {'text-align': 'center'}
                                                                }],
                                                                'hidden': false,
                                                                'labelPosition': 'none',
                                                                'spacers': {
                                                                    'before': 10,
                                                                    'after': 0,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                }
                                                            }
                                                        }, {
                                                            'experiment': 'NewListFields',
                                                            'id': 'fld_i99zf1hq37',
                                                            'data': 'i92ukb3b',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': '200',
                                                                'width-mode': 'auto',
                                                                'min-lines': '0',
                                                                'max-lines': '0',
                                                                'items': [{
                                                                    'id': 'fld_i99zf1hq37_proxy',
                                                                    'data': 'i92ukb3b',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'color': 'color_14',
                                                                        'style': 'Body L'
                                                                    },
                                                                    'layout': {'text-align': 'center'}
                                                                }],
                                                                'hidden': true,
                                                                'labelPosition': 'none',
                                                                'spacers': {
                                                                    'before': 10,
                                                                    'after': 0,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                }
                                                            }
                                                        }],
                                                        'editorData': {
                                                            'labelPosition': 'none',
                                                            'alignment': 'center',
                                                            'displayName': 'Center section',
                                                            'spacers': {
                                                                'before': 0,
                                                                'after': 3,
                                                                'xax-before': 0,
                                                                'xax-after': 0
                                                            }
                                                        }
                                                    },
                                                    'layout': {'box-flex': 1, 'min-width': 0}
                                                }]
                                            },
                                            'layout': {'min-width': 0}
                                        }, {
                                            'id': 'def_3_1',
                                            'comp': {
                                                'name': 'HBox',
                                                'orientation': 'horizontal',
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'displayName': 'def_3_1',
                                                    'alignment': 'left',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 3,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'items': [{
                                                    'id': 'centerToRightSpacer',
                                                    'comp': {'name': 'HSpacer', 'size': 0}
                                                }, {
                                                    'id': 'Right',
                                                    'comp': {
                                                        'name': 'FieldBox',
                                                        'orientation': 'vertical',
                                                        'pack': 'center',
                                                        'editorData': {
                                                            'labelPosition': 'none',
                                                            'displayName': 'Right section',
                                                            'alignment': 'left',
                                                            'spacers': {
                                                                'before': 0,
                                                                'after': 3,
                                                                'xax-before': 0,
                                                                'xax-after': 0
                                                            }
                                                        }
                                                    },
                                                    'layout': {'min-width': 0}
                                                }]
                                            },
                                            'layout': {'min-width': 0}
                                        }],
                                        'orientation': 'vertical'
                                    }, 'id': 'def_3'
                                }, {
                                    'id': 'Bottom',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Bottom section',
                                            'alignment': 'center',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 0}
                                        },
                                        'items': [{
                                            'id': 'fld_i6cr8b19211',
                                            'data': 'Strng_sBttn0-78w',
                                            'comp': {
                                                'name': 'Field',
                                                'width': 280,
                                                'items': [{
                                                    'id': 'fld_i6cr8b19211_proxy',
                                                    'data': 'Strng_sBttn0-78w',
                                                    'comp': {'name': 'Button2', 'style': 'b1', 'align': 'center'},
                                                    'layout': {'position': 'relative'}
                                                }],
                                                'hidden': false,
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 16,
                                                    'after': 0,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'height': 44,
                                                'box-align': 'center'
                                            }
                                        }]
                                    },
                                    'layout': {'box-flex-pack': 'start', 'min-width': 0}
                                }, {'id': 'bottomSpacer', 'comp': {'name': 'VSpacer', 'size': 0}}]
                            },
                            'editorData': {'wasChanged': true, 'originalWidth': 328},
                            'id': 'def_0',
                            'format': 'Mobile'
                        }],
                        'items': [{
                            'title': i18n.translate('i7g3f4my.structure.custom.template.items.0.title'),
                            'image': {
                                '_type': 'wix:Image',
                                'title': '',
                                'src': '8d13be_734b46f3a5ae4666a8bbf9ada6412995.png',
                                'width': 200,
                                'height': 200,
                                'alt': ''
                            },
                            'wxRchTxt_sTxt4': {
                                'version': 1,
                                '_type': 'wix:RichText',
                                'links': [],
                                'text': i18n.translate('i7g3f4my.structure.custom.template.items.0.wxRchTxt_sTxt4')
                            },
                            'links': {'_type': 'wix:Map'},
                            'Strng_sBttn0-78w': i18n.translate('i7g3f4my.structure.custom.template.items.0.Strng_sBttn0-78w'),
                            'i6cq85m1': {
                                '_type': 'wix:Video',
                                'videoId': 'z1pSAwjC4JI',
                                'videoType': 'YOUTUBE',
                                'thumbnail': ''
                            },
                            'i6cq8gyo': i18n.translate('i7g3f4my.structure.custom.template.items.0.i6cq8gyo'),
                            'i92u9lg2': {'_type': 'wix:Date', 'iso': '2023-01-03T00:00:00.000Z'},
                            'i92ukb3b': i18n.translate('i7g3f4my.structure.custom.template.items.0.i92ukb3b')
                        }, {
                            'title': i18n.translate('i7g3f4my.structure.custom.template.items.1.title'),
                            'image': {
                                '_type': 'wix:Image',
                                'title': '',
                                'src': '8d13be_7201d80ac0864d43bfd2524f764ed3f4.png',
                                'width': 200,
                                'height': 200,
                                'alt': ''
                            },
                            'wxRchTxt_sTxt4': {
                                'version': 1,
                                '_type': 'wix:RichText',
                                'links': [],
                                'text': i18n.translate('i7g3f4my.structure.custom.template.items.1.wxRchTxt_sTxt4')
                            },
                            'links': {'_type': 'wix:Map'},
                            'Strng_sBttn0-78w': i18n.translate('i7g3f4my.structure.custom.template.items.1.Strng_sBttn0-78w'),
                            'i6cq85m1': {
                                '_type': 'wix:Video',
                                'videoId': 'f36EEfI0yr8',
                                'videoType': 'YOUTUBE',
                                'thumbnail': ''
                            },
                            'i6cq8gyo': i18n.translate('i7g3f4my.structure.custom.template.items.1.i6cq8gyo'),
                            'i92u9lg2': {'_type': 'wix:Date', 'iso': '2023-02-23T00:00:00.000Z'},
                            'i92ukb3b': i18n.translate('i7g3f4my.structure.custom.template.items.1.i92ukb3b')
                        }, {
                            'title': i18n.translate('i7g3f4my.structure.custom.template.items.2.title'),
                            'image': {
                                '_type': 'wix:Image',
                                'title': '',
                                'src': '8d13be_da0c3dd776e4418cabcc63b564eb4a58.png',
                                'width': 200,
                                'height': 200,
                                'alt': ''
                            },
                            'wxRchTxt_sTxt4': {
                                'version': 1,
                                '_type': 'wix:RichText',
                                'links': [],
                                'text': i18n.translate('i7g3f4my.structure.custom.template.items.2.wxRchTxt_sTxt4')
                            },
                            'links': {'_type': 'wix:Map'},
                            'Strng_sBttn0-78w': i18n.translate('i7g3f4my.structure.custom.template.items.2.Strng_sBttn0-78w'),
                            'i6cq85m1': {
                                '_type': 'wix:Video',
                                'videoId': 'wGOTBSlYkzU',
                                'videoType': 'YOUTUBE',
                                'thumbnail': ''
                            },
                            'i6cq8gyo': i18n.translate('i7g3f4my.structure.custom.template.items.2.i6cq8gyo'),
                            'i92u9lg2': {'_type': 'wix:Date', 'iso': '2023-05-28T00:00:00.000Z'},
                            'i92ukb3b': i18n.translate('i7g3f4my.structure.custom.template.items.2.i92ukb3b')
                        }],
                        'displayName': i18n.translate('i7g3f4my.structure.custom.template.displayName')
                    }
                },
                'id': 'i7g3f4my'
            },
            'preset': {
                'rect': {'width': 162, 'height': 359, 'x': 162, 'y': 0},
                'label': 'i7g3f4my.structure.custom.template.displayName',
                'tags': null
            }
        }, {
            'id': 'Lists_List_Products_And_Services_3',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.viewer.skins.AppPartSkin',
                'layout': {
                    'width': 465,
                    'height': 607,
                    'x': 70,
                    'y': 744,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wixapps.integration.components.AppPart2',
                'data': {
                    'type': 'AppBuilderComponent',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'appInnerID': '2',
                    'appPartName': 'appparti7g3tkt43'
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'id': 'app1',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {'groups': {}, 'properties': {'rd': '7px'}, 'propertiesSource': {'rd': 'value'}},
                    'componentClassName': '',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'system',
                    'skin': 'wysiwyg.viewer.skins.AppPartSkin'
                },
                'custom': {
                    'template': {
                        'type': {
                            'displayName': i18n.translate('i7g3tkta.structure.custom.template.type.displayName'),
                            'version': 0,
                            'baseTypes': [],
                            'name': 'FaqList_i6t4l8ic1',
                            'fields': [{
                                'displayName': i18n.translate('i7g3tkta.structure.custom.template.type.fields.0.displayName'),
                                'name': 'title',
                                'searchable': false,
                                'type': 'String',
                                'defaultValue': i18n.translate('i7g3tkta.structure.custom.template.type.fields.0.defaultValue'),
                                'computed': false
                            }, {
                                'searchable': false,
                                'displayName': i18n.translate('i7g3tkta.structure.custom.template.type.fields.1.displayName'),
                                'type': 'String',
                                'name': 'i6t5fj7e',
                                'computed': false,
                                'defaultValue': i18n.translate('i7g3tkta.structure.custom.template.type.fields.1.defaultValue')
                            }, {
                                'type': 'wix:Date',
                                'searchable': false,
                                'name': 'i92v19ac',
                                'displayName': i18n.translate('i7g3tkta.structure.custom.template.type.fields.2.displayName'),
                                'computed': false,
                                'defaultValue': {'iso': '2020-01-01T00:00:00.000Z', '_type': 'wix:Date'}
                            }, {
                                'displayName': i18n.translate('i7g3tkta.structure.custom.template.type.fields.3.displayName'),
                                'defaultValue': {
                                    'version': 1,
                                    '_type': 'wix:RichText',
                                    'links': [],
                                    'text': i18n.translate('i7g3tkta.structure.custom.template.type.fields.3.defaultValue')
                                },
                                'type': 'wix:RichText',
                                'searchable': false,
                                'name': 'wxRchTxt_sTxt0',
                                'computed': false
                            }, {
                                'metadata': {'showAsHint': 'AsPrice'},
                                'displayName': i18n.translate('i7g3tkta.structure.custom.template.type.fields.4.displayName'),
                                'searchable': false,
                                'name': 'i92v67ta',
                                'type': 'String',
                                'defaultValue': i18n.translate('i7g3tkta.structure.custom.template.type.fields.4.defaultValue'),
                                'computed': false
                            }, {
                                'type': 'wix:Image',
                                'defaultValue': {
                                    'height': 1276,
                                    'src': 'c8381ce0e7a94ad09ceaada2fc64081d.jpg',
                                    'width': 1920,
                                    '_type': 'wix:Image',
                                    'title': ''
                                },
                                'displayName': i18n.translate('i7g3tkta.structure.custom.template.type.fields.5.displayName'),
                                'searchable': false,
                                'name': 'image',
                                'computed': false
                            }, {
                                'defaultValue': {
                                    'videoId': 'f36EEfI0yr8',
                                    '_type': 'wix:Video',
                                    'videoType': 'YOUTUBE'
                                },
                                'type': 'wix:Video',
                                'searchable': false,
                                'displayName': i18n.translate('i7g3tkta.structure.custom.template.type.fields.6.displayName'),
                                'name': 'i6t5g30u',
                                'computed': false
                            }, {
                                'defaultValue': i18n.translate('i7g3tkta.structure.custom.template.type.fields.7.defaultValue'),
                                'searchable': false,
                                'metadata': {'showAsHint': 'AsButton'},
                                'type': 'String',
                                'name': 'i70p00xv',
                                'displayName': i18n.translate('i7g3tkta.structure.custom.template.type.fields.7.displayName'),
                                'computed': false
                            }, {
                                'name': 'links',
                                'type': 'wix:Map',
                                'searchable': false,
                                'displayName': '',
                                'defaultValue': {'_type': 'wix:Map'},
                                'computed': false
                            }]
                        },
                        'views': [{
                            'name': 'FaqListView_i6t4l8id2_dup_i70h8xqe187_dup_i7g3tkrv18',
                            'forType': 'Array',
                            'vars': {'itemSpacing': 45, 'paginationColor': 'color_15'},
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'paginatedlist',
                                    'comp': {
                                        'name': 'PaginatedList',
                                        'hidden': {'$expr': 'eq(Array.length(this), 0)'},
                                        'hidePagination': 'true',
                                        'itemsPerPage': '10',
                                        'templates': {
                                            'item': {
                                                'data': '$positionInParent',
                                                'comp': {
                                                    'name': 'SwitchBox',
                                                    'cases': {
                                                        'default': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'FaqListView_i6t4l8id2_dup_i70h8xqe187_dup_i7g3tkrv18'}
                                                        }, {
                                                            'id': 'footerSpacer',
                                                            'comp': {
                                                                'name': 'VSpacer',
                                                                'size': {'$expr': '$itemSpacing'}
                                                            }
                                                        }, {
                                                            'id': 'gutterLine',
                                                            'comp': {'name': 'HorizontalLine', 'hidden': 'true'},
                                                            'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                        }],
                                                        'last': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'FaqListView_i6t4l8id2_dup_i70h8xqe187_dup_i7g3tkrv18'}
                                                        }, {
                                                            'comp': {
                                                                'name': 'VBox',
                                                                'hidden': 'true',
                                                                'items': [{
                                                                    'id': 'footerSpacer',
                                                                    'comp': {
                                                                        'name': 'VSpacer',
                                                                        'size': {'$expr': '$itemSpacing'}
                                                                    }
                                                                }, {
                                                                    'id': 'gutterLine',
                                                                    'comp': {
                                                                        'name': 'HorizontalLine',
                                                                        'hidden': 'true'
                                                                    },
                                                                    'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                                }]
                                                            }, 'id': 'def_7'
                                                        }, {
                                                            'comp': {'name': 'VSpacer', 'size': '9'},
                                                            'id': 'def_10'
                                                        }, {
                                                            'id': 'paginationNavigationBox',
                                                            'comp': {
                                                                'name': 'HBox',
                                                                'hidden': {'$expr': '$hidePagination'},
                                                                'box-align': 'center',
                                                                'items': [{
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_12'
                                                                }, {
                                                                    'value': '◄',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationPrev',
                                                                        'events': {'dom:click': 'prevPageClicked'}
                                                                    },
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_13'
                                                                }, {
                                                                    'value': {'$expr': 'String.concat($currentPage,\' / \',$maxPage)'},
                                                                    'comp': {'name': 'Label'},
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_14'
                                                                }, {
                                                                    'value': '►',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationNext',
                                                                        'events': {'dom:click': 'nextPageClicked'}
                                                                    },
                                                                    'id': 'def_15'
                                                                }, {
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_16'
                                                                }]
                                                            },
                                                            'layout': {'position': 'relative'}
                                                        }]
                                                    },
                                                    'css': {'min-width': '100%'}
                                                },
                                                'id': '$positionInParent'
                                            }
                                        }
                                    }
                                }, {
                                    'id': 'noItemsLabel',
                                    'value': 'There are no items in this list',
                                    'comp': {'name': 'Label', 'hidden': {'$expr': 'gt(String.length(this), 0)'}}
                                }],
                                'editorData': {'isPaginated': true}
                            },
                            'stylesheet': [{
                                '.paginationPrev, .paginationNext': {
                                    'padding': '0 5px',
                                    'display': 'inline-block',
                                    'font-size': '14px',
                                    'cursor': 'pointer',
                                    '-webkit-touch-callout': 'none',
                                    '-webkit-user-select': 'none',
                                    '-khtml-user-select': 'none',
                                    '-moz-user-select': 'none',
                                    '-ms-user-select': 'none',
                                    'user-select': 'none'
                                }
                            }],
                            'customizations': [{
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.hidePagination',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Split your list across multiple pages',
                                    'defaultVal': 'true',
                                    'trueVal': 'false',
                                    'spaceAfter': '10'
                                }
                            }, {
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.itemsPerPage',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '120',
                                    'minVal': '1',
                                    'label': 'Items Per Page',
                                    'spaceAfter': '20',
                                    'hiddenwhen': {
                                        'fieldId': 'paginatedlist',
                                        'key': 'comp.hidePagination',
                                        'value': 'true'
                                    }
                                }
                            }, {
                                'priority': 100,
                                'fieldId': 'vars',
                                'key': 'itemSpacing',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '300',
                                    'minVal': '0',
                                    'label': 'Item Spacing',
                                    'spaceAfter': '20'
                                }
                            }, {
                                'priority': 95,
                                'fieldId': 'gutterLine',
                                'key': 'comp.hidden',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Show item separator',
                                    'defaultVal': 'false',
                                    'trueVal': 'false'
                                }
                            }, {
                                'priority': 90,
                                'fieldId': 'gutterLine',
                                'key': 'comp.style',
                                'input': {
                                    'name': 'changestyle',
                                    'showAs': 'link',
                                    'label': 'Change Item Separator Style',
                                    'spaceAfter': '20'
                                }
                            }],
                            'id': 'i7g3tkrv',
                            'format': 'Mobile'
                        }, {
                            'name': 'FaqListView_i6t4l8id2_dup_i70h8xqe187_dup_i7g3tkrv18',
                            'forType': 'Array',
                            'vars': {'itemSpacing': 45, 'paginationColor': 'color_15'},
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'paginatedlist',
                                    'comp': {
                                        'name': 'PaginatedList',
                                        'hidden': {'$expr': 'eq(Array.length(this), 0)'},
                                        'hidePagination': 'true',
                                        'itemsPerPage': '10',
                                        'templates': {
                                            'item': {
                                                'data': '$positionInParent',
                                                'comp': {
                                                    'name': 'SwitchBox',
                                                    'cases': {
                                                        'default': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'FaqListView_i6t4l8id2_dup_i70h8xqe187_dup_i7g3tkrv18'}
                                                        }, {
                                                            'id': 'footerSpacer',
                                                            'comp': {
                                                                'name': 'VSpacer',
                                                                'size': {'$expr': '$itemSpacing'}
                                                            }
                                                        }, {
                                                            'id': 'gutterLine',
                                                            'comp': {'name': 'HorizontalLine', 'hidden': 'true'},
                                                            'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                        }],
                                                        'last': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'FaqListView_i6t4l8id2_dup_i70h8xqe187_dup_i7g3tkrv18'}
                                                        }, {
                                                            'comp': {
                                                                'name': 'VBox',
                                                                'hidden': 'true',
                                                                'items': [{
                                                                    'id': 'footerSpacer',
                                                                    'comp': {
                                                                        'name': 'VSpacer',
                                                                        'size': {'$expr': '$itemSpacing'}
                                                                    }
                                                                }, {
                                                                    'id': 'gutterLine',
                                                                    'comp': {
                                                                        'name': 'HorizontalLine',
                                                                        'hidden': 'true'
                                                                    },
                                                                    'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                                }]
                                                            }, 'id': 'def_7'
                                                        }, {
                                                            'comp': {'name': 'VSpacer', 'size': '9'},
                                                            'id': 'def_10'
                                                        }, {
                                                            'id': 'paginationNavigationBox',
                                                            'comp': {
                                                                'name': 'HBox',
                                                                'hidden': {'$expr': '$hidePagination'},
                                                                'box-align': 'center',
                                                                'items': [{
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_12'
                                                                }, {
                                                                    'value': '◄',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationPrev',
                                                                        'events': {'dom:click': 'prevPageClicked'}
                                                                    },
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_13'
                                                                }, {
                                                                    'value': {'$expr': 'String.concat($currentPage,\' / \',$maxPage)'},
                                                                    'comp': {'name': 'Label'},
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_14'
                                                                }, {
                                                                    'value': '►',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationNext',
                                                                        'events': {'dom:click': 'nextPageClicked'}
                                                                    },
                                                                    'id': 'def_15'
                                                                }, {
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_16'
                                                                }]
                                                            },
                                                            'layout': {'position': 'relative'}
                                                        }]
                                                    },
                                                    'css': {'min-width': '100%'}
                                                },
                                                'id': '$positionInParent'
                                            }
                                        }
                                    }
                                }, {
                                    'id': 'noItemsLabel',
                                    'value': 'There are no items in this list',
                                    'comp': {'name': 'Label', 'hidden': {'$expr': 'gt(String.length(this), 0)'}}
                                }],
                                'editorData': {'isPaginated': true}
                            },
                            'stylesheet': [{
                                '.paginationPrev, .paginationNext': {
                                    'padding': '0 5px',
                                    'display': 'inline-block',
                                    'font-size': '14px',
                                    'cursor': 'pointer',
                                    '-webkit-touch-callout': 'none',
                                    '-webkit-user-select': 'none',
                                    '-khtml-user-select': 'none',
                                    '-moz-user-select': 'none',
                                    '-ms-user-select': 'none',
                                    'user-select': 'none'
                                }
                            }],
                            'customizations': [{
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.hidePagination',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Split your list across multiple pages',
                                    'defaultVal': 'true',
                                    'trueVal': 'false',
                                    'spaceAfter': '10'
                                }
                            }, {
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.itemsPerPage',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '120',
                                    'minVal': '1',
                                    'label': 'Items Per Page',
                                    'spaceAfter': '20',
                                    'hiddenwhen': {
                                        'fieldId': 'paginatedlist',
                                        'key': 'comp.hidePagination',
                                        'value': 'true'
                                    }
                                }
                            }, {
                                'priority': 100,
                                'fieldId': 'vars',
                                'key': 'itemSpacing',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '300',
                                    'minVal': '0',
                                    'label': 'Item Spacing',
                                    'spaceAfter': '20'
                                }
                            }, {
                                'priority': 95,
                                'fieldId': 'gutterLine',
                                'key': 'comp.hidden',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Show item separator',
                                    'defaultVal': 'false',
                                    'trueVal': 'false'
                                }
                            }, {
                                'priority': 90,
                                'fieldId': 'gutterLine',
                                'key': 'comp.style',
                                'input': {
                                    'name': 'changestyle',
                                    'showAs': 'link',
                                    'label': 'Change Item Separator Style',
                                    'spaceAfter': '20'
                                }
                            }],
                            'id': 'i7g3tkrv'
                        }, {
                            'name': 'FaqListView_i6t4l8id2_dup_i70h8xqe187_dup_i7g3tkrv18',
                            'forType': 'FaqList_i6t4l8ic1',
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'Top',
                                    'comp': {
                                        'name': 'VBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Top section',
                                            'alignment': 'left',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 0}
                                        },
                                        'items': [{
                                            'id': 'Top_0',
                                            'comp': {
                                                'name': 'FieldBox',
                                                'orientation': 'vertical',
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'displayName': 'Top_0',
                                                    'alignment': 'left',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 3,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'items': [{
                                                    'id': 'fld_i71lsmoa155',
                                                    'data': 'i6t5g30u',
                                                    'comp': {
                                                        'name': 'Field',
                                                        'width': 280,
                                                        'items': [{
                                                            'id': 'fld_i71lsmoa155_proxy',
                                                            'data': 'i6t5g30u',
                                                            'comp': {'name': 'Video'},
                                                            'layout': {'position': 'relative'}
                                                        }],
                                                        'hidden': true,
                                                        'height': '220',
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 12,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'box-align': 'left'
                                                    }
                                                }]
                                            },
                                            'layout': {'min-width': 0}
                                        }, {
                                            'id': 'Top_1',
                                            'comp': {
                                                'name': 'FieldBox',
                                                'orientation': 'horizontal',
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'displayName': 'Top_1',
                                                    'alignment': 'left',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 3,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'items': [{
                                                    'id': 'image_1',
                                                    'data': 'image',
                                                    'comp': {
                                                        'name': 'Field',
                                                        'width': 50,
                                                        'items': [{
                                                            'id': 'image_1_proxy',
                                                            'data': 'image',
                                                            'comp': {
                                                                'name': 'Image',
                                                                'style': 'wp2',
                                                                'imageMode': 'fitWidth'
                                                            }
                                                        }],
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 10,
                                                            'xax-before': 0,
                                                            'xax-after': 8
                                                        },
                                                        'hidden': false,
                                                        'box-align': 'left',
                                                        'height': 50,
                                                        'heightMode': 'auto'
                                                    }
                                                }, {
                                                    'id': 'text_1',
                                                    'data': 'title',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': 240,
                                                        'items': [{
                                                            'id': 'text_1_proxy',
                                                            'data': 'title',
                                                            'comp': {
                                                                'name': 'Label',
                                                                'style': 'Body L',
                                                                'bold': false,
                                                                'italic': false,
                                                                'underline': false,
                                                                'color': 'color_14'
                                                            },
                                                            'layout': {'text-align': 'left'}
                                                        }],
                                                        'hidden': false,
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 0,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'box-align': 'center',
                                                        'width-mode': 'auto',
                                                        'min-lines': 0,
                                                        'max-lines': 0,
                                                        'height-mode': 'auto'
                                                    }
                                                }]
                                            },
                                            'layout': {'min-width': 0}
                                        }],
                                        'pack': 'start'
                                    },
                                    'layout': {'min-width': 0}
                                }, {
                                    'id': 'topSpacer',
                                    'comp': {'name': 'VSpacer', 'size': 0}
                                }, {
                                    'comp': {
                                        'name': 'VBox',
                                        'items': [{
                                            'id': 'def_3_0',
                                            'comp': {
                                                'name': 'HBox',
                                                'orientation': 'horizontal',
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'displayName': 'def_3_0',
                                                    'alignment': 'left',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 3,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'items': [{
                                                    'id': 'Left',
                                                    'comp': {
                                                        'name': 'FieldBox',
                                                        'orientation': 'vertical',
                                                        'pack': 'center',
                                                        'items': [],
                                                        'editorData': {
                                                            'labelPosition': 'none',
                                                            'alignment': 'left',
                                                            'displayName': 'Left section',
                                                            'spacers': {
                                                                'before': 0,
                                                                'after': 3,
                                                                'xax-before': 0,
                                                                'xax-after': 0
                                                            }
                                                        }
                                                    },
                                                    'layout': {'spacerAfter': 0, 'min-width': 0}
                                                }, {
                                                    'id': 'leftToCenterSpacer',
                                                    'comp': {'name': 'HSpacer', 'size': 0}
                                                }, {
                                                    'id': 'Center',
                                                    'comp': {
                                                        'name': 'FieldBox',
                                                        'orientation': 'vertical',
                                                        'pack': 'start',
                                                        'items': [{
                                                            'id': 'fld_i9a02qv061',
                                                            'data': 'i92v19ac',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': '200',
                                                                'items': [{
                                                                    'id': 'fld_i9a02qv061_proxy',
                                                                    'data': 'i92v19ac',
                                                                    'comp': {
                                                                        'name': 'Date',
                                                                        'format': 'mmmm dd, yyyy',
                                                                        'color': 'color_14'
                                                                    },
                                                                    'layout': {'text-align': 'left'}
                                                                }],
                                                                'hidden': true,
                                                                'labelPosition': 'none',
                                                                'spacers': {
                                                                    'before': 6,
                                                                    'after': 2,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                },
                                                                'width-mode': 'auto',
                                                                'min-lines': '0',
                                                                'max-lines': '0'
                                                            },
                                                            'experiment': 'NewListFields'
                                                        }, {
                                                            'id': 'fld_i70p1qgl125',
                                                            'data': 'i6t5fj7e',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': 240,
                                                                'width-mode': 'auto',
                                                                'min-lines': '0',
                                                                'max-lines': '0',
                                                                'items': [{
                                                                    'id': 'fld_i70p1qgl125_proxy',
                                                                    'data': 'i6t5fj7e',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'style': 'Body M',
                                                                        'color': 'color_14'
                                                                    },
                                                                    'layout': {'text-align': 'left'}
                                                                }],
                                                                'hidden': true,
                                                                'labelPosition': 'none',
                                                                'spacers': {
                                                                    'before': 0,
                                                                    'after': 0,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                }
                                                            }
                                                        }, {
                                                            'id': 'fld_hg285ar0',
                                                            'data': 'wxRchTxt_sTxt0',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': 240,
                                                                'items': [{
                                                                    'id': 'fld_hg285ar0_proxy',
                                                                    'data': 'wxRchTxt_sTxt0',
                                                                    'comp': {'name': 'Label', 'color': 'color_14'},
                                                                    'layout': {'text-align': 'left'}
                                                                }],
                                                                'hidden': false,
                                                                'labelPosition': 'none',
                                                                'spacers': {
                                                                    'before': 10,
                                                                    'after': 0,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                },
                                                                'width-mode': 'auto',
                                                                'min-lines': '0',
                                                                'max-lines': '0'
                                                            }
                                                        }, {
                                                            'id': 'fld_i9a02tsp70',
                                                            'data': 'i92v67ta',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': '200',
                                                                'items': [{
                                                                    'id': 'fld_i9a02tsp70_proxy',
                                                                    'data': 'i92v67ta',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'style': 'Body L',
                                                                        'color': 'color_14'
                                                                    },
                                                                    'layout': {'text-align': 'left'}
                                                                }],
                                                                'hidden': true,
                                                                'labelPosition': 'none',
                                                                'spacers': {
                                                                    'before': 10,
                                                                    'after': 0,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                },
                                                                'width-mode': 'auto',
                                                                'min-lines': '0',
                                                                'max-lines': '0'
                                                            },
                                                            'experiment': 'NewListFields'
                                                        }, {
                                                            'id': 'fld_i70p0116105',
                                                            'data': 'i70p00xv',
                                                            'comp': {
                                                                'name': 'Field',
                                                                'width': 280,
                                                                'items': [{
                                                                    'id': 'fld_i70p0116105_proxy',
                                                                    'data': 'i70p00xv',
                                                                    'comp': {
                                                                        'name': 'Button2',
                                                                        'style': 'b1',
                                                                        'align': 'center',
                                                                        'margin': 0
                                                                    },
                                                                    'layout': {'position': 'relative'}
                                                                }],
                                                                'hidden': true,
                                                                'labelPosition': 'none',
                                                                'spacers': {
                                                                    'before': 16,
                                                                    'after': 0,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                },
                                                                'height': 44,
                                                                'box-align': 'left'
                                                            }
                                                        }],
                                                        'editorData': {
                                                            'labelPosition': 'none',
                                                            'alignment': 'left',
                                                            'displayName': 'Center section',
                                                            'spacers': {
                                                                'before': 0,
                                                                'after': 3,
                                                                'xax-before': 0,
                                                                'xax-after': 0
                                                            }
                                                        }
                                                    },
                                                    'layout': {'box-flex': 1, 'min-width': 200}
                                                }]
                                            },
                                            'layout': {'min-width': 0}
                                        }, {
                                            'id': 'def_3_1',
                                            'comp': {
                                                'name': 'HBox',
                                                'orientation': 'horizontal',
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'displayName': 'def_3_1',
                                                    'alignment': 'left',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 3,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'items': [{
                                                    'id': 'centerToRightSpacer',
                                                    'comp': {'name': 'HSpacer', 'size': 0}
                                                }, {
                                                    'id': 'Right',
                                                    'comp': {
                                                        'name': 'FieldBox',
                                                        'orientation': 'vertical',
                                                        'pack': 'center',
                                                        'editorData': {
                                                            'labelPosition': 'none',
                                                            'displayName': 'Right section',
                                                            'alignment': 'left',
                                                            'spacers': {
                                                                'before': 0,
                                                                'after': 3,
                                                                'xax-before': 0,
                                                                'xax-after': 0
                                                            }
                                                        },
                                                        'items': []
                                                    },
                                                    'layout': {'min-width': 0}
                                                }]
                                            },
                                            'layout': {'min-width': 0}
                                        }],
                                        'orientation': 'vertical'
                                    }, 'id': 'def_3'
                                }, {
                                    'id': 'Bottom',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Bottom section',
                                            'alignment': 'left',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 0}
                                        },
                                        'items': []
                                    },
                                    'layout': {'box-flex-pack': 'start', 'min-width': 0}
                                }, {'id': 'bottomSpacer', 'comp': {'name': 'VSpacer', 'size': 0}}]
                            },
                            'id': 'def_0',
                            'editorData': {'originalWidth': 600, 'wasChanged': true},
                            'format': 'Mobile'
                        }, {
                            'name': 'FaqListView_i6t4l8id2_dup_i70h8xqe187_dup_i7g3tkrv18',
                            'forType': 'FaqList_i6t4l8ic1',
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'Top',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'horizontal',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Top section',
                                            'alignment': 'left',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 3}
                                        },
                                        'items': [{
                                            'id': 'image_1',
                                            'data': 'image',
                                            'comp': {
                                                'name': 'Field',
                                                'width': 71,
                                                'items': [{
                                                    'id': 'image_1_proxy',
                                                    'data': 'image',
                                                    'comp': {
                                                        'name': 'Image',
                                                        'style': 'wp2',
                                                        'imageMode': 'fitWidth'
                                                    }
                                                }],
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 0,
                                                    'after': 3,
                                                    'xax-before': 0,
                                                    'xax-after': 3
                                                },
                                                'hidden': false,
                                                'height': 171,
                                                'heightMode': 'auto',
                                                'box-align': 'left'
                                            }
                                        }, {
                                            'id': 'text_1',
                                            'data': 'title',
                                            'comp': {
                                                'name': 'TextField',
                                                'width': 1200,
                                                'items': [{
                                                    'id': 'text_1_proxy',
                                                    'data': 'title',
                                                    'comp': {
                                                        'name': 'Label',
                                                        'style': 'Heading S',
                                                        'bold': false,
                                                        'italic': false,
                                                        'underline': false,
                                                        'color': 'color_14'
                                                    },
                                                    'layout': {'text-align': 'left'}
                                                }],
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 10,
                                                    'after': 0,
                                                    'xax-before': 0,
                                                    'xax-after': 3
                                                },
                                                'hidden': false,
                                                'width-mode': 'auto',
                                                'min-lines': 0,
                                                'max-lines': 0,
                                                'height-mode': 'auto',
                                                'box-align': 'center'
                                            }
                                        }, {
                                            'experiment': 'NewListFields',
                                            'id': 'fld_i92v67uk126',
                                            'data': 'i92v67ta',
                                            'comp': {
                                                'name': 'TextField',
                                                'width': 200,
                                                'width-mode': 'auto',
                                                'min-lines': '0',
                                                'max-lines': '0',
                                                'items': [{
                                                    'id': 'fld_i92v67uk126_proxy',
                                                    'data': 'i92v67ta',
                                                    'comp': {
                                                        'name': 'Label',
                                                        'color': 'color_14',
                                                        'style': 'Heading S'
                                                    },
                                                    'layout': {'text-align': 'right'}
                                                }],
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 29,
                                                    'after': 3,
                                                    'xax-before': 0,
                                                    'xax-after': 3
                                                },
                                                'hidden': true,
                                                'box-align': 'center'
                                            }
                                        }],
                                        'pack': 'start'
                                    },
                                    'layout': {'min-width': 0}
                                }, {
                                    'id': 'topSpacer',
                                    'comp': {'name': 'VSpacer', 'size': '0'}
                                }, {
                                    'comp': {
                                        'name': 'HBox',
                                        'items': [{
                                            'id': 'Left',
                                            'comp': {
                                                'name': 'FieldBox',
                                                'orientation': 'vertical',
                                                'pack': 'center',
                                                'items': [],
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'alignment': 'left',
                                                    'displayName': 'Left section',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 0,
                                                        'xax-after': 5
                                                    }
                                                }
                                            },
                                            'layout': {'spacerAfter': 0, 'min-width': 0}
                                        }, {
                                            'id': 'leftToCenterSpacer',
                                            'comp': {'name': 'HSpacer', 'size': '0'}
                                        }, {
                                            'id': 'Center',
                                            'comp': {
                                                'name': 'FieldBox',
                                                'orientation': 'vertical',
                                                'pack': 'start',
                                                'items': [{
                                                    'id': 'fld_i92v19bs101',
                                                    'data': 'i92v19ac',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0',
                                                        'items': [{
                                                            'id': 'fld_i92v19bs101_proxy',
                                                            'data': 'i92v19ac',
                                                            'comp': {
                                                                'name': 'Date',
                                                                'color': 'color_14',
                                                                'format': 'mmm dd, yyyy'
                                                            },
                                                            'layout': {'text-align': 'left'}
                                                        }],
                                                        'hidden': true,
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 13,
                                                            'after': 0,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        }
                                                    },
                                                    'experiment': 'NewListFields'
                                                }, {
                                                    'id': 'fld_i70p1qgl125',
                                                    'data': 'i6t5fj7e',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0',
                                                        'items': [{
                                                            'id': 'fld_i70p1qgl125_proxy',
                                                            'data': 'i6t5fj7e',
                                                            'comp': {
                                                                'name': 'Label',
                                                                'color': 'color_14',
                                                                'style': 'Body M'
                                                            },
                                                            'layout': {'text-align': 'left'}
                                                        }],
                                                        'hidden': true,
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 3,
                                                            'after': 0,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        }
                                                    }
                                                }, {
                                                    'id': 'fld_hg285ar0',
                                                    'data': 'wxRchTxt_sTxt0',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'items': [{
                                                            'id': 'fld_hg285ar0_proxy',
                                                            'data': 'wxRchTxt_sTxt0',
                                                            'comp': {'name': 'Label', 'color': 'color_14'},
                                                            'layout': {'text-align': 'left'}
                                                        }],
                                                        'hidden': false,
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 10,
                                                            'after': 0,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0'
                                                    }
                                                }, {
                                                    'id': 'fld_i70p0116105',
                                                    'data': 'i70p00xv',
                                                    'comp': {
                                                        'name': 'Field',
                                                        'width': 160,
                                                        'items': [{
                                                            'id': 'fld_i70p0116105_proxy',
                                                            'data': 'i70p00xv',
                                                            'comp': {
                                                                'name': 'Button2',
                                                                'style': 'b1',
                                                                'align': 'center',
                                                                'margin': 0
                                                            },
                                                            'layout': {'position': 'relative'}
                                                        }],
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 24,
                                                            'after': 0,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'hidden': true,
                                                        'height': '40',
                                                        'box-align': 'left'
                                                    }
                                                }],
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'alignment': 'left',
                                                    'displayName': 'Center section',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 0,
                                                        'xax-after': 0
                                                    }
                                                }
                                            },
                                            'layout': {'box-flex': 1, 'min-width': 200}
                                        }, {
                                            'id': 'centerToRightSpacer',
                                            'comp': {'name': 'HSpacer', 'size': '0'}
                                        }, {
                                            'id': 'Right',
                                            'comp': {
                                                'name': 'FieldBox',
                                                'orientation': 'vertical',
                                                'pack': 'center',
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'displayName': 'Right section',
                                                    'alignment': 'left',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 3,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'items': [{
                                                    'id': 'fld_i71lsmoa155',
                                                    'data': 'i6t5g30u',
                                                    'comp': {
                                                        'name': 'Field',
                                                        'width': 330,
                                                        'height': '220',
                                                        'items': [{
                                                            'id': 'fld_i71lsmoa155_proxy',
                                                            'data': 'i6t5g30u',
                                                            'comp': {'name': 'Video'},
                                                            'layout': {'position': 'relative'}
                                                        }],
                                                        'hidden': true,
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 3,
                                                            'xax-before': 3,
                                                            'xax-after': 0
                                                        },
                                                        'box-align': 'left'
                                                    }
                                                }]
                                            },
                                            'layout': {'min-width': 0}
                                        }]
                                    }, 'id': 'def_3'
                                }, {
                                    'id': 'Bottom',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Bottom section',
                                            'alignment': 'left',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 3}
                                        },
                                        'items': []
                                    },
                                    'layout': {'box-flex-pack': 'start', 'min-width': 0}
                                }, {'id': 'bottomSpacer', 'comp': {'name': 'VSpacer', 'size': '0'}}]
                            },
                            'id': 'def_0',
                            'editorData': {'wasChanged': true}
                        }],
                        'items': [{
                            'title': i18n.translate('i7g3tkta.structure.custom.template.items.0.title'),
                            'image': {
                                '_type': 'wix:Image',
                                'title': '',
                                'src': '8d13be_513108a8493443b48b332b4cd5f7202f.png',
                                'width': 200,
                                'height': 200,
                                'alt': ''
                            },
                            'wxRchTxt_sTxt0': {
                                'version': 1,
                                '_type': 'wix:RichText',
                                'links': [],
                                'text': i18n.translate('i7g3tkta.structure.custom.template.items.0.wxRchTxt_sTxt0')
                            },
                            'links': {
                                '_type': 'wix:Map',
                                'i70p00xv': {
                                    'type': 'ExternalLink',
                                    'url': 'http://www.wix.com',
                                    'target': '_blank'
                                }
                            },
                            'i6t5fj7e': i18n.translate('i7g3tkta.structure.custom.template.items.0.i6t5fj7e'),
                            'i6t5g30u': {
                                '_type': 'wix:Video',
                                'videoId': 'z1pSAwjC4JI',
                                'videoType': 'YOUTUBE',
                                'thumbnail': ''
                            },
                            'i70p00xv': i18n.translate('i7g3tkta.structure.custom.template.items.0.i70p00xv'),
                            'i92v19ac': {'_type': 'wix:Date', 'iso': '2023-01-03T00:00:00.000Z'},
                            'i92v67ta': i18n.translate('i7g3tkta.structure.custom.template.items.0.i92v67ta')
                        }, {
                            'title': i18n.translate('i7g3tkta.structure.custom.template.items.1.title'),
                            'image': {
                                '_type': 'wix:Image',
                                'title': '',
                                'src': '8d13be_51ebfbd7308842ccbf9249f7c17ce057.png',
                                'width': 200,
                                'height': 200,
                                'alt': ''
                            },
                            'wxRchTxt_sTxt0': {
                                'version': 1,
                                '_type': 'wix:RichText',
                                'links': [],
                                'text': i18n.translate('i7g3tkta.structure.custom.template.items.1.wxRchTxt_sTxt0')
                            },
                            'links': {
                                '_type': 'wix:Map',
                                'i70p00xv': {
                                    'type': 'ExternalLink',
                                    'url': 'http://www.wix.com',
                                    'target': '_blank'
                                }
                            },
                            'i6t5fj7e': i18n.translate('i7g3tkta.structure.custom.template.items.1.i6t5fj7e'),
                            'i6t5g30u': {
                                '_type': 'wix:Video',
                                'videoId': 'f36EEfI0yr8',
                                'videoType': 'YOUTUBE',
                                'thumbnail': ''
                            },
                            'i70p00xv': i18n.translate('i7g3tkta.structure.custom.template.items.1.i70p00xv'),
                            'i92v19ac': {'_type': 'wix:Date', 'iso': '2023-02-23T00:00:00.000Z'},
                            'i92v67ta': i18n.translate('i7g3tkta.structure.custom.template.items.1.i92v67ta')
                        }, {
                            'title': i18n.translate('i7g3tkta.structure.custom.template.items.2.title'),
                            'image': {
                                '_type': 'wix:Image',
                                'title': '',
                                'src': '8d13be_20e1784524584f66a3d842fa6e62b5bd.png',
                                'width': 200,
                                'height': 200,
                                'alt': ''
                            },
                            'wxRchTxt_sTxt0': {
                                'version': 1,
                                '_type': 'wix:RichText',
                                'links': [],
                                'text': i18n.translate('i7g3tkta.structure.custom.template.items.2.wxRchTxt_sTxt0')
                            },
                            'links': {
                                '_type': 'wix:Map',
                                'i70p00xv': {
                                    'type': 'ExternalLink',
                                    'url': 'http://www.wix.com',
                                    'target': '_blank'
                                }
                            },
                            'i6t5fj7e': i18n.translate('i7g3tkta.structure.custom.template.items.2.i6t5fj7e'),
                            'i6t5g30u': {
                                '_type': 'wix:Video',
                                'videoId': 'wGOTBSlYkzU',
                                'videoType': 'YOUTUBE',
                                'thumbnail': ''
                            },
                            'i70p00xv': i18n.translate('i7g3tkta.structure.custom.template.items.2.i70p00xv'),
                            'i92v19ac': {'_type': 'wix:Date', 'iso': '2023-05-28T00:00:00.000Z'},
                            'i92v67ta': i18n.translate('i7g3tkta.structure.custom.template.items.2.i92v67ta')
                        }],
                        'displayName': i18n.translate('i7g3tkta.structure.custom.template.displayName')
                    }
                },
                'id': 'i7g3tkta'
            },
            'preset': {
                'rect': {'width': 162, 'height': 189, 'x': 0, 'y': 170},
                'label': 'i7g3tkta.structure.custom.template.displayName',
                'tags': null
            }
        }, {
            'id': 'Lists_List_Products_And_Services_4',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.viewer.skins.AppPartSkin',
                'layout': {
                    'width': 546,
                    'height': 1362,
                    'x': 51,
                    'y': 1491,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wixapps.integration.components.AppPart2',
                'data': {
                    'type': 'AppBuilderComponent',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'appInnerID': '2',
                    'appPartName': 'appparti7g5m6mz6'
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'id': 'app1',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {'groups': {}, 'properties': {'rd': '7px'}, 'propertiesSource': {'rd': 'value'}},
                    'componentClassName': '',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'system',
                    'skin': 'wysiwyg.viewer.skins.AppPartSkin'
                },
                'custom': {
                    'template': {
                        'type': {
                            'displayName': i18n.translate('i7g5m6n6.structure.custom.template.type.displayName'),
                            'version': 0,
                            'baseTypes': [],
                            'name': 'CenteredMenu_i6cr5e06103',
                            'fields': [{
                                'displayName': i18n.translate('i7g5m6n6.structure.custom.template.type.fields.0.displayName'),
                                'name': 'title',
                                'searchable': false,
                                'type': 'String',
                                'defaultValue': i18n.translate('i7g5m6n6.structure.custom.template.type.fields.0.defaultValue'),
                                'computed': false
                            }, {
                                'searchable': false,
                                'displayName': i18n.translate('i7g5m6n6.structure.custom.template.type.fields.1.displayName'),
                                'type': 'String',
                                'name': 'price',
                                'computed': false,
                                'defaultValue': i18n.translate('i7g5m6n6.structure.custom.template.type.fields.1.defaultValue')
                            }, {
                                'defaultValue': {'iso': '2020-01-01T00:00:00.000Z', '_type': 'wix:Date'},
                                'type': 'wix:Date',
                                'searchable': false,
                                'displayName': i18n.translate('i7g5m6n6.structure.custom.template.type.fields.2.displayName'),
                                'computed': false,
                                'name': 'i92w0tlq'
                            }, {
                                'displayName': i18n.translate('i7g5m6n6.structure.custom.template.type.fields.3.displayName'),
                                'name': 'description',
                                'type': 'wix:RichText',
                                'searchable': false,
                                'defaultValue': {
                                    'version': 1,
                                    '_type': 'wix:RichText',
                                    'links': [],
                                    'text': i18n.translate('i7g5m6n6.structure.custom.template.type.fields.3.defaultValue')
                                },
                                'computed': false
                            }, {
                                'metadata': {'showAsHint': 'AsPrice'},
                                'displayName': i18n.translate('i7g5m6n6.structure.custom.template.type.fields.4.displayName'),
                                'searchable': false,
                                'type': 'String',
                                'defaultValue': i18n.translate('i7g5m6n6.structure.custom.template.type.fields.4.defaultValue'),
                                'computed': false,
                                'name': 'i92vrt1n'
                            }, {
                                'name': 'i6cr6car',
                                'type': 'wix:Image',
                                'defaultValue': {
                                    'height': 1276,
                                    'src': 'c8381ce0e7a94ad09ceaada2fc64081d.jpg',
                                    'width': 1920,
                                    '_type': 'wix:Image',
                                    'title': ''
                                },
                                'displayName': i18n.translate('i7g5m6n6.structure.custom.template.type.fields.5.displayName'),
                                'searchable': false,
                                'computed': false
                            }, {
                                'defaultValue': {
                                    'videoId': 'f36EEfI0yr8',
                                    '_type': 'wix:Video',
                                    'videoType': 'YOUTUBE'
                                },
                                'type': 'wix:Video',
                                'searchable': false,
                                'displayName': i18n.translate('i7g5m6n6.structure.custom.template.type.fields.6.displayName'),
                                'name': 'i6cr7vjm',
                                'computed': false
                            }, {
                                'name': 'i70msebl',
                                'defaultValue': i18n.translate('i7g5m6n6.structure.custom.template.type.fields.7.defaultValue'),
                                'searchable': false,
                                'metadata': {'showAsHint': 'AsButton'},
                                'type': 'String',
                                'displayName': i18n.translate('i7g5m6n6.structure.custom.template.type.fields.7.displayName'),
                                'computed': false
                            }, {
                                'name': 'links',
                                'type': 'wix:Map',
                                'searchable': false,
                                'displayName': '',
                                'defaultValue': {
                                    '_type': 'wix:Map',
                                    'i6cr7oa2': {
                                        'protocol': 'http',
                                        '_type': 'wix:ExternalLink',
                                        'target': '_blank',
                                        'address': 'www.wix.com'
                                    }
                                },
                                'computed': false
                            }]
                        },
                        'views': [{
                            'name': 'CenteredMenuView_i6cr5e07104_dup_i6m439hk703_dup_i6qm3p97332_dup_i6tbn2nd69_dup_i70h8xrc188_dup_i7g5m6lt134',
                            'forType': 'Array',
                            'vars': {'itemSpacing': 50, 'paginationColor': 'color_15'},
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'paginatedlist',
                                    'comp': {
                                        'name': 'PaginatedList',
                                        'hidden': {'$expr': 'eq(Array.length(this), 0)'},
                                        'hidePagination': 'true',
                                        'itemsPerPage': '10',
                                        'templates': {
                                            'item': {
                                                'data': '$positionInParent',
                                                'comp': {
                                                    'name': 'SwitchBox',
                                                    'cases': {
                                                        'default': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'CenteredMenuView_i6cr5e07104_dup_i6m439hk703_dup_i6qm3p97332_dup_i6tbn2nd69_dup_i70h8xrc188_dup_i7g5m6lt134'}
                                                        }, {
                                                            'id': 'footerSpacer',
                                                            'comp': {
                                                                'name': 'VSpacer',
                                                                'size': {'$expr': '$itemSpacing'}
                                                            }
                                                        }, {
                                                            'id': 'gutterLine',
                                                            'comp': {'name': 'HorizontalLine', 'hidden': 'true'},
                                                            'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                        }],
                                                        'last': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'CenteredMenuView_i6cr5e07104_dup_i6m439hk703_dup_i6qm3p97332_dup_i6tbn2nd69_dup_i70h8xrc188_dup_i7g5m6lt134'}
                                                        }, {
                                                            'comp': {
                                                                'name': 'VBox',
                                                                'hidden': 'true',
                                                                'items': [{
                                                                    'id': 'footerSpacer',
                                                                    'comp': {
                                                                        'name': 'VSpacer',
                                                                        'size': {'$expr': '$itemSpacing'}
                                                                    }
                                                                }, {
                                                                    'id': 'gutterLine',
                                                                    'comp': {
                                                                        'name': 'HorizontalLine',
                                                                        'hidden': 'true'
                                                                    },
                                                                    'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                                }]
                                                            }, 'id': 'def_7'
                                                        }, {
                                                            'comp': {'name': 'VSpacer', 'size': '9'},
                                                            'id': 'def_10'
                                                        }, {
                                                            'id': 'paginationNavigationBox',
                                                            'comp': {
                                                                'name': 'HBox',
                                                                'hidden': {'$expr': '$hidePagination'},
                                                                'box-align': 'center',
                                                                'items': [{
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_12'
                                                                }, {
                                                                    'value': '◄',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationPrev',
                                                                        'events': {'dom:click': 'prevPageClicked'}
                                                                    },
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_13'
                                                                }, {
                                                                    'value': {'$expr': 'String.concat($currentPage,\' / \',$maxPage)'},
                                                                    'comp': {'name': 'Label'},
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_14'
                                                                }, {
                                                                    'value': '►',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationNext',
                                                                        'events': {'dom:click': 'nextPageClicked'}
                                                                    },
                                                                    'id': 'def_15'
                                                                }, {
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_16'
                                                                }]
                                                            },
                                                            'layout': {'position': 'relative'}
                                                        }]
                                                    },
                                                    'css': {'min-width': '100%'}
                                                },
                                                'id': '$positionInParent'
                                            }
                                        }
                                    }
                                }, {
                                    'id': 'noItemsLabel',
                                    'value': 'There are no items in this list',
                                    'comp': {'name': 'Label', 'hidden': {'$expr': 'gt(String.length(this), 0)'}}
                                }],
                                'editorData': {'isPaginated': true}
                            },
                            'stylesheet': [{
                                '.paginationPrev, .paginationNext': {
                                    'padding': '0 5px',
                                    'display': 'inline-block',
                                    'font-size': '14px',
                                    'cursor': 'pointer',
                                    '-webkit-touch-callout': 'none',
                                    '-webkit-user-select': 'none',
                                    '-khtml-user-select': 'none',
                                    '-moz-user-select': 'none',
                                    '-ms-user-select': 'none',
                                    'user-select': 'none'
                                }
                            }],
                            'customizations': [{
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.hidePagination',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Split your list across multiple pages',
                                    'defaultVal': 'true',
                                    'trueVal': 'false',
                                    'spaceAfter': '10'
                                }
                            }, {
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.itemsPerPage',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '120',
                                    'minVal': '1',
                                    'label': 'Items Per Page',
                                    'spaceAfter': '20',
                                    'hiddenwhen': {
                                        'fieldId': 'paginatedlist',
                                        'key': 'comp.hidePagination',
                                        'value': 'true'
                                    }
                                }
                            }, {
                                'priority': 100,
                                'fieldId': 'vars',
                                'key': 'itemSpacing',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '300',
                                    'minVal': '0',
                                    'label': 'Item Spacing',
                                    'spaceAfter': '20'
                                }
                            }, {
                                'priority': 95,
                                'fieldId': 'gutterLine',
                                'key': 'comp.hidden',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Show item separator',
                                    'defaultVal': 'false',
                                    'trueVal': 'false'
                                }
                            }, {
                                'priority': 90,
                                'fieldId': 'gutterLine',
                                'key': 'comp.style',
                                'input': {
                                    'name': 'changestyle',
                                    'showAs': 'link',
                                    'label': 'Change Item Separator Style',
                                    'spaceAfter': '20'
                                }
                            }],
                            'id': 'i7g5m6lu'
                        }, {
                            'name': 'CenteredMenuView_i6cr5e07104_dup_i6m439hk703_dup_i6qm3p97332_dup_i6tbn2nd69_dup_i70h8xrc188_dup_i7g5m6lt134',
                            'forType': 'Array',
                            'vars': {'itemSpacing': 50, 'paginationColor': 'color_15'},
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'paginatedlist',
                                    'comp': {
                                        'name': 'PaginatedList',
                                        'hidden': {'$expr': 'eq(Array.length(this), 0)'},
                                        'hidePagination': 'true',
                                        'itemsPerPage': '10',
                                        'templates': {
                                            'item': {
                                                'data': '$positionInParent',
                                                'comp': {
                                                    'name': 'SwitchBox',
                                                    'cases': {
                                                        'default': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'CenteredMenuView_i6cr5e07104_dup_i6m439hk703_dup_i6qm3p97332_dup_i6tbn2nd69_dup_i70h8xrc188_dup_i7g5m6lt134'}
                                                        }, {
                                                            'id': 'footerSpacer',
                                                            'comp': {
                                                                'name': 'VSpacer',
                                                                'size': {'$expr': '$itemSpacing'}
                                                            }
                                                        }, {
                                                            'id': 'gutterLine',
                                                            'comp': {'name': 'HorizontalLine', 'hidden': 'true'},
                                                            'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                        }],
                                                        'last': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'CenteredMenuView_i6cr5e07104_dup_i6m439hk703_dup_i6qm3p97332_dup_i6tbn2nd69_dup_i70h8xrc188_dup_i7g5m6lt134'}
                                                        }, {
                                                            'comp': {
                                                                'name': 'VBox',
                                                                'hidden': 'true',
                                                                'items': [{
                                                                    'id': 'footerSpacer',
                                                                    'comp': {
                                                                        'name': 'VSpacer',
                                                                        'size': {'$expr': '$itemSpacing'}
                                                                    }
                                                                }, {
                                                                    'id': 'gutterLine',
                                                                    'comp': {
                                                                        'name': 'HorizontalLine',
                                                                        'hidden': 'true'
                                                                    },
                                                                    'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                                }]
                                                            }, 'id': 'def_7'
                                                        }, {
                                                            'comp': {'name': 'VSpacer', 'size': '9'},
                                                            'id': 'def_10'
                                                        }, {
                                                            'id': 'paginationNavigationBox',
                                                            'comp': {
                                                                'name': 'HBox',
                                                                'hidden': {'$expr': '$hidePagination'},
                                                                'box-align': 'center',
                                                                'items': [{
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_12'
                                                                }, {
                                                                    'value': '◄',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationPrev',
                                                                        'events': {'dom:click': 'prevPageClicked'}
                                                                    },
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_13'
                                                                }, {
                                                                    'value': {'$expr': 'String.concat($currentPage,\' / \',$maxPage)'},
                                                                    'comp': {'name': 'Label'},
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_14'
                                                                }, {
                                                                    'value': '►',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationNext',
                                                                        'events': {'dom:click': 'nextPageClicked'}
                                                                    },
                                                                    'id': 'def_15'
                                                                }, {
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_16'
                                                                }]
                                                            },
                                                            'layout': {'position': 'relative'}
                                                        }]
                                                    },
                                                    'css': {'min-width': '100%'}
                                                },
                                                'id': '$positionInParent'
                                            }
                                        }
                                    }
                                }, {
                                    'id': 'noItemsLabel',
                                    'value': 'There are no items in this list',
                                    'comp': {'name': 'Label', 'hidden': {'$expr': 'gt(String.length(this), 0)'}}
                                }],
                                'editorData': {'isPaginated': true}
                            },
                            'stylesheet': [{
                                '.paginationPrev, .paginationNext': {
                                    'padding': '0 5px',
                                    'display': 'inline-block',
                                    'font-size': '14px',
                                    'cursor': 'pointer',
                                    '-webkit-touch-callout': 'none',
                                    '-webkit-user-select': 'none',
                                    '-khtml-user-select': 'none',
                                    '-moz-user-select': 'none',
                                    '-ms-user-select': 'none',
                                    'user-select': 'none'
                                }
                            }],
                            'customizations': [{
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.hidePagination',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Split your list across multiple pages',
                                    'defaultVal': 'true',
                                    'trueVal': 'false',
                                    'spaceAfter': '10'
                                }
                            }, {
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.itemsPerPage',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '120',
                                    'minVal': '1',
                                    'label': 'Items Per Page',
                                    'spaceAfter': '20',
                                    'hiddenwhen': {
                                        'fieldId': 'paginatedlist',
                                        'key': 'comp.hidePagination',
                                        'value': 'true'
                                    }
                                }
                            }, {
                                'priority': 100,
                                'fieldId': 'vars',
                                'key': 'itemSpacing',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '300',
                                    'minVal': '0',
                                    'label': 'Item Spacing',
                                    'spaceAfter': '20'
                                }
                            }, {
                                'priority': 95,
                                'fieldId': 'gutterLine',
                                'key': 'comp.hidden',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Show item separator',
                                    'defaultVal': 'false',
                                    'trueVal': 'false'
                                }
                            }, {
                                'priority': 90,
                                'fieldId': 'gutterLine',
                                'key': 'comp.style',
                                'input': {
                                    'name': 'changestyle',
                                    'showAs': 'link',
                                    'label': 'Change Item Separator Style',
                                    'spaceAfter': '20'
                                }
                            }],
                            'id': 'i7g5m6lu',
                            'format': 'Mobile'
                        }, {
                            'name': 'CenteredMenuView_i6cr5e07104_dup_i6m439hk703_dup_i6qm3p97332_dup_i6tbn2nd69_dup_i70h8xrc188_dup_i7g5m6lt134',
                            'forType': 'CenteredMenu_i6cr5e06103',
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'Top',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'pack': 'center',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Top section',
                                            'alignment': 'center',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 3}
                                        },
                                        'items': [{
                                            'id': 'fld_i70mxm3q269',
                                            'data': 'i6cr7vjm',
                                            'comp': {
                                                'name': 'Field',
                                                'width': 529,
                                                'height': 317,
                                                'items': [{
                                                    'id': 'fld_i70mxm3q269_proxy',
                                                    'data': 'i6cr7vjm',
                                                    'comp': {'name': 'Video'},
                                                    'layout': {'position': 'relative'}
                                                }],
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 0,
                                                    'after': 8,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'box-align': 'center',
                                                'hidden': true
                                            }
                                        }, {
                                            'id': 'fld_i6cr6ccz132',
                                            'data': 'i6cr6car',
                                            'comp': {
                                                'name': 'Field',
                                                'width': 529,
                                                'height': 264,
                                                'items': [{
                                                    'id': 'fld_i6cr6ccz132_proxy',
                                                    'data': 'i6cr6car',
                                                    'comp': {'name': 'Image', 'imageMode': 'fill', 'style': 'wp2'}
                                                }],
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 0,
                                                    'after': 8,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'box-align': 'center',
                                                'hidden': false,
                                                'heightMode': 'manual'
                                            }
                                        }]
                                    },
                                    'layout': {'min-width': 0}
                                }, {
                                    'id': 'Center',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'horizontal',
                                        'pack': 'center',
                                        'items': [{
                                            'id': 'text_1',
                                            'data': 'title',
                                            'comp': {
                                                'name': 'TextField',
                                                'width': 500,
                                                'width-mode': 'auto',
                                                'items': [{
                                                    'id': 'text_1_proxy',
                                                    'data': 'title',
                                                    'comp': {
                                                        'name': 'Label',
                                                        'style': 'Heading S',
                                                        'bold': false,
                                                        'underline': false,
                                                        'italic': false,
                                                        'color': 'color_14'
                                                    },
                                                    'layout': {'text-align': 'center'}
                                                }],
                                                'spacers': {
                                                    'before': 0,
                                                    'after': 10,
                                                    'xax-after': 0,
                                                    'xax-before': 0
                                                },
                                                'box-align': 'end'
                                            }
                                        }, {
                                            'id': 'fld_i92vrt2v249',
                                            'data': 'i92vrt1n',
                                            'comp': {
                                                'name': 'TextField',
                                                'width': '200',
                                                'width-mode': 'auto',
                                                'items': [{
                                                    'id': 'fld_i92vrt2v249_proxy',
                                                    'data': 'i92vrt1n',
                                                    'comp': {
                                                        'name': 'Label',
                                                        'color': 'color_14',
                                                        'style': 'Heading S'
                                                    },
                                                    'layout': {'text-align': 'left'}
                                                }],
                                                'spacers': {
                                                    'before': 0,
                                                    'after': 0,
                                                    'xax-after': 0,
                                                    'xax-before': 0
                                                },
                                                'min-lines': '0',
                                                'max-lines': '0',
                                                'hidden': false,
                                                'labelPosition': 'none'
                                            },
                                            'experiment': 'NewListFields'
                                        }],
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Center section',
                                            'alignment': 'center',
                                            'spacers': {'before': 3, 'after': 0, 'xax-before': 0, 'xax-after': 3}
                                        }
                                    },
                                    'layout': {'min-width': 0}
                                }, {
                                    'id': 'Bottom',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'pack': 'center',
                                        'items': [{
                                            'id': 'text_2',
                                            'data': 'price',
                                            'comp': {
                                                'name': 'TextField',
                                                'width': '500',
                                                'items': [{
                                                    'id': 'text_2_proxy',
                                                    'data': 'price',
                                                    'comp': {
                                                        'name': 'Label',
                                                        'style': 'Body L',
                                                        'bold': false,
                                                        'underline': false,
                                                        'italic': false,
                                                        'noWrap': false
                                                    },
                                                    'layout': {'text-align': 'center'}
                                                }],
                                                'spacers': {
                                                    'before': 0,
                                                    'after': 3,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'labelPosition': 'none',
                                                'hidden': true,
                                                'width-mode': 'auto'
                                            }
                                        }, {
                                            'id': 'text_3',
                                            'data': 'description',
                                            'comp': {
                                                'name': 'TextField',
                                                'width': '200',
                                                'items': [{
                                                    'id': 'text_3_proxy',
                                                    'data': 'description',
                                                    'comp': {'name': 'Label', 'style': 'Body M', 'bold': 'false'},
                                                    'layout': {'text-align': 'center'}
                                                }],
                                                'spacers': {
                                                    'before': 5,
                                                    'after': 3,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'labelPosition': 'none',
                                                'hidden': false,
                                                'width-mode': 'auto'
                                            }
                                        }, {
                                            'id': 'fld_i92w0tne288',
                                            'data': 'i92w0tlq',
                                            'comp': {
                                                'name': 'TextField',
                                                'width': '200',
                                                'items': [{
                                                    'id': 'fld_i92w0tne288_proxy',
                                                    'data': 'i92w0tlq',
                                                    'comp': {
                                                        'name': 'Date',
                                                        'format': 'mmmm dd, yyyy',
                                                        'color': 'color_14'
                                                    },
                                                    'layout': {'text-align': 'center'}
                                                }],
                                                'spacers': {
                                                    'before': 8,
                                                    'after': 0,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'labelPosition': 'none',
                                                'hidden': true,
                                                'width-mode': 'auto',
                                                'min-lines': '0',
                                                'max-lines': '0'
                                            },
                                            'experiment': 'NewListFields'
                                        }, {
                                            'id': 'fld_i70msedd180',
                                            'data': 'i70msebl',
                                            'comp': {
                                                'name': 'Field',
                                                'box-align': 'center',
                                                'width': 160,
                                                'items': [{
                                                    'id': 'fld_i70msedd180_proxy',
                                                    'data': 'i70msebl',
                                                    'comp': {'name': 'Button2', 'style': 'b1', 'align': 'center'},
                                                    'layout': {'position': 'relative'}
                                                }],
                                                'spacers': {
                                                    'before': 20,
                                                    'after': 0,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'height': '40',
                                                'labelPosition': 'none',
                                                'hidden': false
                                            }
                                        }],
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Bottom section',
                                            'alignment': 'center',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 3}
                                        }
                                    },
                                    'layout': {'min-width': 0}
                                }]
                            },
                            'id': 'def_0',
                            'editorData': {'wasChanged': true}
                        }, {
                            'name': 'CenteredMenuView_i6cr5e07104_dup_i6m439hk703_dup_i6qm3p97332_dup_i6tbn2nd69_dup_i70h8xrc188_dup_i7g5m6lt134',
                            'forType': 'CenteredMenu_i6cr5e06103',
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'Top',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'pack': 'center',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Top section',
                                            'alignment': 'center',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 0}
                                        },
                                        'items': [{
                                            'id': 'fld_i70mxm3q269',
                                            'data': 'i6cr7vjm',
                                            'comp': {
                                                'name': 'Field',
                                                'width': 280,
                                                'height': 220,
                                                'items': [{
                                                    'id': 'fld_i70mxm3q269_proxy',
                                                    'data': 'i6cr7vjm',
                                                    'comp': {'name': 'Video'},
                                                    'layout': {'position': 'relative'}
                                                }],
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 0,
                                                    'after': 8,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'box-align': 'center',
                                                'hidden': true
                                            }
                                        }, {
                                            'id': 'fld_i6cr6ccz132',
                                            'data': 'i6cr6car',
                                            'comp': {
                                                'name': 'Field',
                                                'width': 280,
                                                'height': 200,
                                                'items': [{
                                                    'id': 'fld_i6cr6ccz132_proxy',
                                                    'data': 'i6cr6car',
                                                    'comp': {'name': 'Image', 'imageMode': 'fill', 'style': 'wp2'}
                                                }],
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 0,
                                                    'after': 8,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'box-align': 'center',
                                                'hidden': false,
                                                'heightMode': 'manual'
                                            }
                                        }]
                                    },
                                    'layout': {'min-width': 0}
                                }, {
                                    'id': 'Center',
                                    'comp': {
                                        'name': 'VBox',
                                        'orientation': 'vertical',
                                        'pack': 'center',
                                        'items': [{
                                            'id': 'Center_0',
                                            'comp': {
                                                'name': 'FieldBox',
                                                'orientation': 'vertical',
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'displayName': 'Center_0',
                                                    'alignment': 'left',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 3,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'items': [{
                                                    'id': 'text_1',
                                                    'data': 'title',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': 280,
                                                        'width-mode': 'manual',
                                                        'items': [{
                                                            'id': 'text_1_proxy',
                                                            'data': 'title',
                                                            'comp': {
                                                                'name': 'Label',
                                                                'style': 'Heading S',
                                                                'bold': false,
                                                                'underline': false,
                                                                'italic': false,
                                                                'color': 'color_14'
                                                            },
                                                            'layout': {'text-align': 'center'}
                                                        }],
                                                        'hidden': true,
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 3,
                                                            'xax-before': 3,
                                                            'xax-after': 0
                                                        }
                                                    }
                                                }],
                                                'pack': 'center'
                                            },
                                            'layout': {'min-width': 0}
                                        }, {
                                            'id': 'Center_1',
                                            'comp': {
                                                'name': 'FieldBox',
                                                'orientation': 'horizontal',
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'displayName': 'Center_1',
                                                    'alignment': 'left',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 3,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'items': [{
                                                    'id': 'text_2',
                                                    'data': 'price',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': 280,
                                                        'width-mode': 'manual',
                                                        'items': [{
                                                            'id': 'text_2_proxy',
                                                            'data': 'price',
                                                            'comp': {
                                                                'name': 'Label',
                                                                'style': 'Body L',
                                                                'bold': false,
                                                                'underline': false,
                                                                'italic': false,
                                                                'noWrap': false,
                                                                'color': 'color_14'
                                                            },
                                                            'layout': {'text-align': 'center'}
                                                        }],
                                                        'hidden': false,
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 3,
                                                            'xax-before': 3,
                                                            'xax-after': 0
                                                        }
                                                    }
                                                }],
                                                'pack': 'center'
                                            },
                                            'layout': {'min-width': 0}
                                        }],
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Center section',
                                            'alignment': 'center',
                                            'spacers': {'before': 3, 'after': 0, 'xax-before': 0, 'xax-after': 0}
                                        }
                                    },
                                    'layout': {'min-width': 0}
                                }, {
                                    'id': 'Bottom',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'pack': 'center',
                                        'items': [{
                                            'id': 'fld_i9a37wet159',
                                            'data': 'i92vrt1n',
                                            'comp': {
                                                'name': 'TextField',
                                                'width': '200',
                                                'width-mode': 'auto',
                                                'items': [{
                                                    'id': 'fld_i9a37wet159_proxy',
                                                    'data': 'i92vrt1n',
                                                    'comp': {
                                                        'name': 'Label',
                                                        'style': 'Heading S',
                                                        'color': 'color_14'
                                                    },
                                                    'layout': {'text-align': 'center'}
                                                }],
                                                'spacers': {
                                                    'before': 0,
                                                    'after': 3,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'labelPosition': 'none',
                                                'hidden': false,
                                                'min-lines': '0',
                                                'max-lines': '0'
                                            },
                                            'experiment': 'NewListFields'
                                        }, {
                                            'id': 'text_3',
                                            'data': 'description',
                                            'comp': {
                                                'name': 'TextField',
                                                'width': 240,
                                                'items': [{
                                                    'id': 'text_3_proxy',
                                                    'data': 'description',
                                                    'comp': {'name': 'Label', 'style': 'Body M', 'bold': 'false'},
                                                    'layout': {'text-align': 'center'}
                                                }],
                                                'spacers': {
                                                    'before': 5,
                                                    'after': 3,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'labelPosition': 'none',
                                                'hidden': false,
                                                'width-mode': 'auto'
                                            }
                                        }, {
                                            'id': 'fld_i9a2xtl636',
                                            'data': 'i92w0tlq',
                                            'comp': {
                                                'name': 'TextField',
                                                'width': '200',
                                                'items': [{
                                                    'id': 'fld_i9a2xtl636_proxy',
                                                    'data': 'i92w0tlq',
                                                    'comp': {
                                                        'name': 'Date',
                                                        'color': 'color_14',
                                                        'format': 'mmmm dd, yyyy'
                                                    },
                                                    'layout': {'text-align': 'center'}
                                                }],
                                                'spacers': {
                                                    'before': 8,
                                                    'after': 0,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'labelPosition': 'none',
                                                'hidden': true,
                                                'width-mode': 'auto',
                                                'min-lines': '0',
                                                'max-lines': '0'
                                            },
                                            'experiment': 'NewListFields'
                                        }, {
                                            'id': 'fld_i70msedd180',
                                            'data': 'i70msebl',
                                            'comp': {
                                                'name': 'Field',
                                                'width': 280,
                                                'items': [{
                                                    'id': 'fld_i70msedd180_proxy',
                                                    'data': 'i70msebl',
                                                    'comp': {'name': 'Button2', 'style': 'b1', 'align': 'center'},
                                                    'layout': {'position': 'relative'}
                                                }],
                                                'spacers': {
                                                    'before': 20,
                                                    'after': 0,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'labelPosition': 'none',
                                                'hidden': false,
                                                'box-align': 'center',
                                                'height': 44
                                            }
                                        }],
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Bottom section',
                                            'alignment': 'center',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 0}
                                        }
                                    },
                                    'layout': {'min-width': 0}
                                }]
                            },
                            'id': 'def_0',
                            'editorData': {'wasChanged': true, 'originalWidth': 318},
                            'format': 'Mobile'
                        }],
                        'items': [{
                            'title': i18n.translate('i7g5m6n6.structure.custom.template.items.0.title'),
                            'price': i18n.translate('i7g5m6n6.structure.custom.template.items.0.price'),
                            'description': {
                                'version': 1,
                                '_type': 'wix:RichText',
                                'links': [],
                                'text': i18n.translate('i7g5m6n6.structure.custom.template.items.0.description')
                            },
                            'links': {
                                '_type': 'wix:Map',
                                'i6cr7oa2': {
                                    'type': 'ExternalLink',
                                    'url': 'http://www.wix.com',
                                    'target': '_blank'
                                },
                                'i70msebl': {
                                    'type': 'ExternalLink',
                                    'url': 'http://www.wix.com',
                                    'target': '_blank'
                                }
                            },
                            'i6cr6car': {
                                '_type': 'wix:Image',
                                'title': '',
                                'src': '8d13be_64e809138eef4d6598ae071ab4c3c1db.jpg',
                                'width': 700,
                                'height': 463,
                                'alt': ''
                            },
                            'i6cr7vjm': {
                                '_type': 'wix:Video',
                                'videoId': 'z1pSAwjC4JI',
                                'videoType': 'YOUTUBE',
                                'thumbnail': ''
                            },
                            'i70msebl': i18n.translate('i7g5m6n6.structure.custom.template.items.0.i70msebl'),
                            'i92vrt1n': i18n.translate('i7g5m6n6.structure.custom.template.items.0.i92vrt1n'),
                            'i92w0tlq': {'_type': 'wix:Date', 'iso': '2023-01-03T00:00:00.000Z'}
                        }, {
                            'title': i18n.translate('i7g5m6n6.structure.custom.template.items.1.title'),
                            'price': i18n.translate('i7g5m6n6.structure.custom.template.items.1.price'),
                            'description': {
                                'version': 1,
                                '_type': 'wix:RichText',
                                'links': [],
                                'text': i18n.translate('i7g5m6n6.structure.custom.template.items.1.description')
                            },
                            'links': {
                                '_type': 'wix:Map',
                                'i6cr7oa2': {
                                    'type': 'ExternalLink',
                                    'url': 'http://www.wix.com',
                                    'target': '_blank'
                                },
                                'i70msebl': {
                                    'type': 'ExternalLink',
                                    'url': 'http://www.wix.com',
                                    'target': '_blank'
                                }
                            },
                            'i6cr6car': {
                                '_type': 'wix:Image',
                                'title': '',
                                'src': 'c42b4817cc2d461b5eaa9ca1b78b8719.jpg',
                                'width': 929,
                                'height': 617,
                                'alt': ''
                            },
                            'i6cr7vjm': {
                                '_type': 'wix:Video',
                                'videoId': 'f36EEfI0yr8',
                                'videoType': 'YOUTUBE',
                                'thumbnail': ''
                            },
                            'i70msebl': i18n.translate('i7g5m6n6.structure.custom.template.items.1.i70msebl'),
                            'i92vrt1n': i18n.translate('i7g5m6n6.structure.custom.template.items.1.i92vrt1n'),
                            'i92w0tlq': {'_type': 'wix:Date', 'iso': '2023-02-23T00:00:00.000Z'}
                        }, {
                            'title': i18n.translate('i7g5m6n6.structure.custom.template.items.2.title'),
                            'price': i18n.translate('i7g5m6n6.structure.custom.template.items.2.price'),
                            'description': {
                                'version': 1,
                                '_type': 'wix:RichText',
                                'links': [],
                                'text': i18n.translate('i7g5m6n6.structure.custom.template.items.2.description')
                            },
                            'links': {
                                '_type': 'wix:Map',
                                'i6cr7oa2': {
                                    'type': 'ExternalLink',
                                    'url': 'http://www.wix.com',
                                    'target': '_blank'
                                },
                                'i70msebl': {
                                    'type': 'ExternalLink',
                                    'url': 'http://www.wix.com',
                                    'target': '_blank'
                                }
                            },
                            'i6cr6car': {
                                '_type': 'wix:Image',
                                'title': '',
                                'src': '8d13be_1a368e42d8e94278bc9bec1ec316a270.jpg',
                                'width': 700,
                                'height': 469,
                                'alt': ''
                            },
                            'i6cr7vjm': {
                                '_type': 'wix:Video',
                                'videoId': 'wGOTBSlYkzU',
                                'videoType': 'YOUTUBE',
                                'thumbnail': ''
                            },
                            'i70msebl': i18n.translate('i7g5m6n6.structure.custom.template.items.2.i70msebl'),
                            'i92vrt1n': i18n.translate('i7g5m6n6.structure.custom.template.items.2.i92vrt1n'),
                            'i92w0tlq': {'_type': 'wix:Date', 'iso': '2023-05-28T00:00:00.000Z'}
                        }],
                        'displayName': i18n.translate('i7g5m6n6.structure.custom.template.displayName')
                    }
                },
                'id': 'i7g5m6n6'
            },
            'preset': {
                'rect': {'width': 162, 'height': 374, 'x': 0, 'y': 359},
                'label': 'i7g5m6n6.structure.custom.template.displayName',
                'tags': null
            }
        }, {
            'id': 'Lists_List_Products_And_Services_5',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.viewer.skins.AppPartSkin',
                'layout': {
                    'width': 754,
                    'height': 674,
                    'x': 665,
                    'y': 1482,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wixapps.integration.components.AppPart2',
                'data': {
                    'type': 'AppBuilderComponent',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'appInnerID': '2',
                    'appPartName': 'appparti7g5p5vw9'
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'id': 'app1',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {'groups': {}, 'properties': {'rd': '7px'}, 'propertiesSource': {'rd': 'value'}},
                    'componentClassName': '',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'system',
                    'skin': 'wysiwyg.viewer.skins.AppPartSkin'
                },
                'custom': {
                    'template': {
                        'type': {
                            'displayName': i18n.translate('i7g5p5w1.structure.custom.template.type.displayName'),
                            'version': 0,
                            'baseTypes': [],
                            'fields': [{
                                'displayName': i18n.translate('i7g5p5w1.structure.custom.template.type.fields.0.displayName'),
                                'name': 'title',
                                'searchable': false,
                                'type': 'String',
                                'defaultValue': i18n.translate('i7g5p5w1.structure.custom.template.type.fields.0.defaultValue'),
                                'computed': false
                            }, {
                                'name': 'Strng_sTxt1',
                                'searchable': false,
                                'displayName': i18n.translate('i7g5p5w1.structure.custom.template.type.fields.1.displayName'),
                                'type': 'String',
                                'computed': false,
                                'defaultValue': i18n.translate('i7g5p5w1.structure.custom.template.type.fields.1.defaultValue')
                            }, {
                                'type': 'wix:Date',
                                'searchable': false,
                                'name': 'i93vl483',
                                'displayName': i18n.translate('i7g5p5w1.structure.custom.template.type.fields.2.displayName'),
                                'computed': false,
                                'defaultValue': {'iso': '2020-01-01T00:00:00.000Z', '_type': 'wix:Date'}
                            }, {
                                'displayName': i18n.translate('i7g5p5w1.structure.custom.template.type.fields.3.displayName'),
                                'defaultValue': {
                                    'version': 1,
                                    '_type': 'wix:RichText',
                                    'links': [],
                                    'text': i18n.translate('i7g5p5w1.structure.custom.template.type.fields.3.defaultValue')
                                },
                                'type': 'wix:RichText',
                                'searchable': false,
                                'name': 'wxRchTxt_sTxt0',
                                'computed': false
                            }, {
                                'metadata': {'showAsHint': 'AsPrice'},
                                'displayName': i18n.translate('i7g5p5w1.structure.custom.template.type.fields.4.displayName'),
                                'searchable': false,
                                'name': 'i93vf7ld',
                                'type': 'String',
                                'defaultValue': i18n.translate('i7g5p5w1.structure.custom.template.type.fields.4.defaultValue'),
                                'computed': false
                            }, {
                                'type': 'wix:Image',
                                'defaultValue': {
                                    'height': 1276,
                                    'src': 'c8381ce0e7a94ad09ceaada2fc64081d.jpg',
                                    'width': 1920,
                                    '_type': 'wix:Image',
                                    'title': ''
                                },
                                'displayName': i18n.translate('i7g5p5w1.structure.custom.template.type.fields.5.displayName'),
                                'searchable': false,
                                'name': 'image',
                                'computed': false
                            }, {
                                'defaultValue': {
                                    'videoId': 'f36EEfI0yr8',
                                    '_type': 'wix:Video',
                                    'videoType': 'YOUTUBE'
                                },
                                'type': 'wix:Video',
                                'searchable': false,
                                'displayName': i18n.translate('i7g5p5w1.structure.custom.template.type.fields.6.displayName'),
                                'name': 'i9a27wwq',
                                'computed': false
                            }, {
                                'name': 'Strng_sBttn0-v1c',
                                'defaultValue': i18n.translate('i7g5p5w1.structure.custom.template.type.fields.7.defaultValue'),
                                'searchable': false,
                                'metadata': {'showAsHint': 'AsButton'},
                                'type': 'String',
                                'displayName': i18n.translate('i7g5p5w1.structure.custom.template.type.fields.7.displayName'),
                                'computed': false
                            }, {
                                'name': 'links',
                                'type': 'wix:Map',
                                'searchable': false,
                                'displayName': '',
                                'defaultValue': {'_type': 'wix:Map'},
                                'computed': false
                            }],
                            'name': 'NewsPosts_i6m78132489'
                        },
                        'views': [{
                            'name': 'NewsPostsView_i6m78132490_dup_i6qm3pea337_dup_i6tbtsmw87_dup_i70h8xsg189_dup_i7g5p5vd171',
                            'forType': 'Array',
                            'vars': {'itemSpacing': 40, 'paginationColor': 'color_15'},
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'paginatedlist',
                                    'comp': {
                                        'name': 'PaginatedList',
                                        'hidden': {'$expr': 'eq(Array.length(this), 0)'},
                                        'hidePagination': 'true',
                                        'itemsPerPage': '10',
                                        'templates': {
                                            'item': {
                                                'data': '$positionInParent',
                                                'comp': {
                                                    'name': 'SwitchBox',
                                                    'cases': {
                                                        'default': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'NewsPostsView_i6m78132490_dup_i6qm3pea337_dup_i6tbtsmw87_dup_i70h8xsg189_dup_i7g5p5vd171'}
                                                        }, {
                                                            'id': 'footerSpacer',
                                                            'comp': {
                                                                'name': 'VSpacer',
                                                                'size': {'$expr': '$itemSpacing'}
                                                            }
                                                        }, {
                                                            'id': 'gutterLine',
                                                            'comp': {'name': 'HorizontalLine', 'hidden': 'true'},
                                                            'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                        }],
                                                        'last': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'NewsPostsView_i6m78132490_dup_i6qm3pea337_dup_i6tbtsmw87_dup_i70h8xsg189_dup_i7g5p5vd171'}
                                                        }, {
                                                            'comp': {
                                                                'name': 'VBox',
                                                                'hidden': 'true',
                                                                'items': [{
                                                                    'id': 'footerSpacer',
                                                                    'comp': {
                                                                        'name': 'VSpacer',
                                                                        'size': {'$expr': '$itemSpacing'}
                                                                    }
                                                                }, {
                                                                    'id': 'gutterLine',
                                                                    'comp': {
                                                                        'name': 'HorizontalLine',
                                                                        'hidden': 'true'
                                                                    },
                                                                    'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                                }]
                                                            }, 'id': 'def_7'
                                                        }, {
                                                            'comp': {'name': 'VSpacer', 'size': '9'},
                                                            'id': 'def_10'
                                                        }, {
                                                            'id': 'paginationNavigationBox',
                                                            'comp': {
                                                                'name': 'HBox',
                                                                'hidden': {'$expr': '$hidePagination'},
                                                                'box-align': 'center',
                                                                'items': [{
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_12'
                                                                }, {
                                                                    'value': '◄',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationPrev',
                                                                        'events': {'dom:click': 'prevPageClicked'}
                                                                    },
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_13'
                                                                }, {
                                                                    'value': {'$expr': 'String.concat($currentPage,\' / \',$maxPage)'},
                                                                    'comp': {'name': 'Label'},
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_14'
                                                                }, {
                                                                    'value': '►',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationNext',
                                                                        'events': {'dom:click': 'nextPageClicked'}
                                                                    },
                                                                    'id': 'def_15'
                                                                }, {
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_16'
                                                                }]
                                                            },
                                                            'layout': {'position': 'relative'}
                                                        }]
                                                    },
                                                    'css': {'min-width': '100%'}
                                                },
                                                'id': '$positionInParent'
                                            }
                                        }
                                    }
                                }, {
                                    'id': 'noItemsLabel',
                                    'value': 'There are no items in this list',
                                    'comp': {'name': 'Label', 'hidden': {'$expr': 'gt(String.length(this), 0)'}}
                                }],
                                'editorData': {'isPaginated': true}
                            },
                            'stylesheet': [{
                                '.paginationPrev, .paginationNext': {
                                    'padding': '0 5px',
                                    'display': 'inline-block',
                                    'font-size': '14px',
                                    'cursor': 'pointer',
                                    '-webkit-touch-callout': 'none',
                                    '-webkit-user-select': 'none',
                                    '-khtml-user-select': 'none',
                                    '-moz-user-select': 'none',
                                    '-ms-user-select': 'none',
                                    'user-select': 'none'
                                }
                            }],
                            'customizations': [{
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.hidePagination',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Split your list across multiple pages',
                                    'defaultVal': 'true',
                                    'trueVal': 'false',
                                    'spaceAfter': '10'
                                }
                            }, {
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.itemsPerPage',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '120',
                                    'minVal': '1',
                                    'label': 'Items Per Page',
                                    'spaceAfter': '20',
                                    'hiddenwhen': {
                                        'fieldId': 'paginatedlist',
                                        'key': 'comp.hidePagination',
                                        'value': 'true'
                                    }
                                }
                            }, {
                                'priority': 100,
                                'fieldId': 'vars',
                                'key': 'itemSpacing',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '300',
                                    'minVal': '0',
                                    'label': 'Item Spacing',
                                    'spaceAfter': '20'
                                }
                            }, {
                                'priority': 95,
                                'fieldId': 'gutterLine',
                                'key': 'comp.hidden',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Show item separator',
                                    'defaultVal': 'false',
                                    'trueVal': 'false'
                                }
                            }, {
                                'priority': 90,
                                'fieldId': 'gutterLine',
                                'key': 'comp.style',
                                'input': {
                                    'name': 'changestyle',
                                    'showAs': 'link',
                                    'label': 'Change Item Separator Style',
                                    'spaceAfter': '20'
                                }
                            }],
                            'id': 'i7g5p5ve'
                        }, {
                            'name': 'NewsPostsView_i6m78132490_dup_i6qm3pea337_dup_i6tbtsmw87_dup_i70h8xsg189_dup_i7g5p5vd171',
                            'forType': 'Array',
                            'vars': {'itemSpacing': 50, 'paginationColor': 'color_15'},
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'paginatedlist',
                                    'comp': {
                                        'name': 'PaginatedList',
                                        'hidden': {'$expr': 'eq(Array.length(this), 0)'},
                                        'hidePagination': 'true',
                                        'itemsPerPage': '10',
                                        'templates': {
                                            'item': {
                                                'data': '$positionInParent',
                                                'comp': {
                                                    'name': 'SwitchBox',
                                                    'cases': {
                                                        'default': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'NewsPostsView_i6m78132490_dup_i6qm3pea337_dup_i6tbtsmw87_dup_i70h8xsg189_dup_i7g5p5vd171'}
                                                        }, {
                                                            'id': 'footerSpacer',
                                                            'comp': {
                                                                'name': 'VSpacer',
                                                                'size': {'$expr': '$itemSpacing'}
                                                            }
                                                        }, {
                                                            'id': 'gutterLine',
                                                            'comp': {'name': 'HorizontalLine', 'hidden': 'true'},
                                                            'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                        }],
                                                        'last': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'NewsPostsView_i6m78132490_dup_i6qm3pea337_dup_i6tbtsmw87_dup_i70h8xsg189_dup_i7g5p5vd171'}
                                                        }, {
                                                            'comp': {
                                                                'name': 'VBox',
                                                                'hidden': 'true',
                                                                'items': [{
                                                                    'id': 'footerSpacer',
                                                                    'comp': {
                                                                        'name': 'VSpacer',
                                                                        'size': {'$expr': '$itemSpacing'}
                                                                    }
                                                                }, {
                                                                    'id': 'gutterLine',
                                                                    'comp': {
                                                                        'name': 'HorizontalLine',
                                                                        'hidden': 'true'
                                                                    },
                                                                    'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                                }]
                                                            }, 'id': 'def_7'
                                                        }, {
                                                            'comp': {'name': 'VSpacer', 'size': '9'},
                                                            'id': 'def_10'
                                                        }, {
                                                            'id': 'paginationNavigationBox',
                                                            'comp': {
                                                                'name': 'HBox',
                                                                'hidden': {'$expr': '$hidePagination'},
                                                                'box-align': 'center',
                                                                'items': [{
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_12'
                                                                }, {
                                                                    'value': '◄',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationPrev',
                                                                        'events': {'dom:click': 'prevPageClicked'}
                                                                    },
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_13'
                                                                }, {
                                                                    'value': {'$expr': 'String.concat($currentPage,\' / \',$maxPage)'},
                                                                    'comp': {'name': 'Label'},
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_14'
                                                                }, {
                                                                    'value': '►',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationNext',
                                                                        'events': {'dom:click': 'nextPageClicked'}
                                                                    },
                                                                    'id': 'def_15'
                                                                }, {
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_16'
                                                                }]
                                                            },
                                                            'layout': {'position': 'relative'}
                                                        }]
                                                    },
                                                    'css': {'min-width': '100%'}
                                                },
                                                'id': '$positionInParent'
                                            }
                                        }
                                    }
                                }, {
                                    'id': 'noItemsLabel',
                                    'value': 'There are no items in this list',
                                    'comp': {'name': 'Label', 'hidden': {'$expr': 'gt(String.length(this), 0)'}}
                                }],
                                'editorData': {'isPaginated': true}
                            },
                            'stylesheet': [{
                                '.paginationPrev, .paginationNext': {
                                    'padding': '0 5px',
                                    'display': 'inline-block',
                                    'font-size': '14px',
                                    'cursor': 'pointer',
                                    '-webkit-touch-callout': 'none',
                                    '-webkit-user-select': 'none',
                                    '-khtml-user-select': 'none',
                                    '-moz-user-select': 'none',
                                    '-ms-user-select': 'none',
                                    'user-select': 'none'
                                }
                            }],
                            'customizations': [{
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.hidePagination',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Split your list across multiple pages',
                                    'defaultVal': 'true',
                                    'trueVal': 'false',
                                    'spaceAfter': '10'
                                }
                            }, {
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.itemsPerPage',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '120',
                                    'minVal': '1',
                                    'label': 'Items Per Page',
                                    'spaceAfter': '20',
                                    'hiddenwhen': {
                                        'fieldId': 'paginatedlist',
                                        'key': 'comp.hidePagination',
                                        'value': 'true'
                                    }
                                }
                            }, {
                                'priority': 100,
                                'fieldId': 'vars',
                                'key': 'itemSpacing',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '300',
                                    'minVal': '0',
                                    'label': 'Item Spacing',
                                    'spaceAfter': '20'
                                }
                            }, {
                                'priority': 95,
                                'fieldId': 'gutterLine',
                                'key': 'comp.hidden',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Show item separator',
                                    'defaultVal': 'false',
                                    'trueVal': 'false'
                                }
                            }, {
                                'priority': 90,
                                'fieldId': 'gutterLine',
                                'key': 'comp.style',
                                'input': {
                                    'name': 'changestyle',
                                    'showAs': 'link',
                                    'label': 'Change Item Separator Style',
                                    'spaceAfter': '20'
                                }
                            }],
                            'id': 'i7g5p5ve',
                            'format': 'Mobile'
                        }, {
                            'name': 'NewsPostsView_i6m78132490_dup_i6qm3pea337_dup_i6tbtsmw87_dup_i70h8xsg189_dup_i7g5p5vd171',
                            'forType': 'NewsPosts_i6m78132489',
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'Top',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Top section',
                                            'alignment': 'left',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 0}
                                        },
                                        'items': [{
                                            'id': 'image_1',
                                            'data': 'image',
                                            'comp': {
                                                'name': 'Field',
                                                'width': 280,
                                                'items': [{
                                                    'id': 'image_1_proxy',
                                                    'data': 'image',
                                                    'comp': {'name': 'Image', 'style': 'wp2'}
                                                }],
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 0,
                                                    'after': 12,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'hidden': false,
                                                'height': 170,
                                                'box-align': 'left'
                                            }
                                        }, {
                                            'id': 'fld_i9a2bhb2176',
                                            'data': 'i9a27wwq',
                                            'comp': {
                                                'name': 'Field',
                                                'width': 280,
                                                'height': '220',
                                                'items': [{
                                                    'id': 'fld_i9a2bhb2176_proxy',
                                                    'data': 'i9a27wwq',
                                                    'comp': {'name': 'Video'},
                                                    'layout': {'position': 'relative'}
                                                }],
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 0,
                                                    'after': 12,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'box-align': 'left',
                                                'hidden': true
                                            }
                                        }]
                                    },
                                    'layout': {'min-width': 0}
                                }, {
                                    'id': 'topSpacer',
                                    'comp': {'name': 'VSpacer', 'size': 0}
                                }, {
                                    'comp': {
                                        'name': 'VBox',
                                        'items': [{
                                            'id': 'def_3_0',
                                            'comp': {
                                                'name': 'HBox',
                                                'orientation': 'horizontal',
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'displayName': 'def_3_0',
                                                    'alignment': 'left',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 3,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'items': [{
                                                    'id': 'Left',
                                                    'comp': {
                                                        'name': 'VBox',
                                                        'orientation': 'vertical',
                                                        'items': [{
                                                            'id': 'Left_0',
                                                            'comp': {
                                                                'name': 'FieldBox',
                                                                'items': [],
                                                                'orientation': 'horizontal',
                                                                'editorData': {
                                                                    'labelPosition': 'none',
                                                                    'displayName': 'Left_0',
                                                                    'alignment': 'left',
                                                                    'spacers': {
                                                                        'before': 0,
                                                                        'after': 3,
                                                                        'xax-before': 3,
                                                                        'xax-after': 0
                                                                    }
                                                                }
                                                            },
                                                            'layout': {'min-width': 0}
                                                        }, {
                                                            'id': 'Left_1',
                                                            'comp': {
                                                                'name': 'FieldBox',
                                                                'orientation': 'horizontal',
                                                                'editorData': {
                                                                    'labelPosition': 'none',
                                                                    'displayName': 'Left_1',
                                                                    'alignment': 'left',
                                                                    'spacers': {
                                                                        'before': 0,
                                                                        'after': 3,
                                                                        'xax-before': 3,
                                                                        'xax-after': 0
                                                                    }
                                                                },
                                                                'items': []
                                                            },
                                                            'layout': {'min-width': 0}
                                                        }],
                                                        'editorData': {
                                                            'labelPosition': 'none',
                                                            'alignment': 'left',
                                                            'displayName': 'Left section',
                                                            'spacers': {
                                                                'before': 0,
                                                                'after': 3,
                                                                'xax-before': 0,
                                                                'xax-after': 0
                                                            }
                                                        }
                                                    },
                                                    'layout': {'spacerAfter': 0, 'min-width': 0}
                                                }]
                                            },
                                            'layout': {'min-width': 0}
                                        }, {
                                            'id': 'def_3_1',
                                            'comp': {
                                                'name': 'HBox',
                                                'orientation': 'horizontal',
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'displayName': 'def_3_1',
                                                    'alignment': 'left',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 3,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'items': [{
                                                    'id': 'leftToCenterSpacer',
                                                    'comp': {'name': 'HSpacer', 'size': 0}
                                                }, {
                                                    'id': 'Center',
                                                    'comp': {
                                                        'name': 'FieldBox',
                                                        'orientation': 'vertical',
                                                        'items': [{
                                                            'id': 'fld_i9a1uy4249',
                                                            'data': 'i93vl483',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': '200',
                                                                'width-mode': 'auto',
                                                                'min-lines': '0',
                                                                'max-lines': '0',
                                                                'items': [{
                                                                    'id': 'fld_i9a1uy4249_proxy',
                                                                    'data': 'i93vl483',
                                                                    'comp': {
                                                                        'name': 'Date',
                                                                        'format': 'mmm dd, yyyy'
                                                                    },
                                                                    'layout': {'text-align': 'left'}
                                                                }],
                                                                'spacers': {
                                                                    'before': 0,
                                                                    'after': 3,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                },
                                                                'hidden': true,
                                                                'labelPosition': 'none'
                                                            },
                                                            'experiment': 'NewListFields'
                                                        }, {
                                                            'id': 'text_1',
                                                            'data': 'title',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': 240,
                                                                'width-mode': 'auto',
                                                                'min-lines': 0,
                                                                'max-lines': 0,
                                                                'items': [{
                                                                    'id': 'text_1_proxy',
                                                                    'data': 'title',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'style': 'Heading S',
                                                                        'bold': false,
                                                                        'italic': false
                                                                    },
                                                                    'layout': {'text-align': 'left'}
                                                                }],
                                                                'spacers': {
                                                                    'before': 0,
                                                                    'after': 0,
                                                                    'xax-before': 0
                                                                },
                                                                'box-align': 'start',
                                                                'height-mode': 'auto'
                                                            }
                                                        }, {
                                                            'id': 'fld_hfnxgxqo',
                                                            'data': 'Strng_sTxt1',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': 240,
                                                                'items': [{
                                                                    'id': 'fld_hfnxgxqo_proxy',
                                                                    'data': 'Strng_sTxt1',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'style': 'Body L',
                                                                        'bold': false,
                                                                        'italic': false,
                                                                        'underline': false,
                                                                        'color': 'color_14'
                                                                    },
                                                                    'layout': {'text-align': 'left'}
                                                                }],
                                                                'spacers': {
                                                                    'before': 0,
                                                                    'after': 3,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                },
                                                                'width-mode': 'auto',
                                                                'min-lines': '0',
                                                                'max-lines': '0',
                                                                'hidden': true,
                                                                'labelPosition': 'none'
                                                            }
                                                        }, {
                                                            'id': 'fld_i9a1uztg58',
                                                            'data': 'i93vf7ld',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': '200',
                                                                'items': [{
                                                                    'id': 'fld_i9a1uztg58_proxy',
                                                                    'data': 'i93vf7ld',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'style': 'Heading S',
                                                                        'color': 'color_14'
                                                                    },
                                                                    'layout': {'text-align': 'left'}
                                                                }],
                                                                'spacers': {
                                                                    'before': 0,
                                                                    'after': 3,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                },
                                                                'width-mode': 'auto',
                                                                'min-lines': '0',
                                                                'max-lines': '0',
                                                                'labelPosition': 'none',
                                                                'hidden': false
                                                            },
                                                            'experiment': 'NewListFields'
                                                        }, {
                                                            'id': 'fld_hfxs75de',
                                                            'data': 'wxRchTxt_sTxt0',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': 240,
                                                                'items': [{
                                                                    'id': 'fld_hfxs75de_proxy',
                                                                    'data': 'wxRchTxt_sTxt0',
                                                                    'comp': {'name': 'Label'},
                                                                    'layout': {'text-align': 'left'}
                                                                }],
                                                                'hidden': false,
                                                                'labelPosition': 'none',
                                                                'spacers': {
                                                                    'before': 10,
                                                                    'after': 0,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                },
                                                                'width-mode': 'auto',
                                                                'min-lines': '0',
                                                                'max-lines': '0'
                                                            }
                                                        }, {
                                                            'id': 'fld_i70mkmcs53',
                                                            'data': 'Strng_sBttn0-v1c',
                                                            'comp': {
                                                                'name': 'Field',
                                                                'width': 280,
                                                                'height': 44,
                                                                'items': [{
                                                                    'id': 'fld_i70mkmcs53_proxy',
                                                                    'data': 'Strng_sBttn0-v1c',
                                                                    'comp': {
                                                                        'name': 'Button2',
                                                                        'style': 'b1',
                                                                        'align': 'center'
                                                                    },
                                                                    'layout': {'position': 'relative'}
                                                                }],
                                                                'hidden': false,
                                                                'labelPosition': 'none',
                                                                'spacers': {
                                                                    'before': 23,
                                                                    'after': 0,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                },
                                                                'box-align': 'left'
                                                            }
                                                        }],
                                                        'editorData': {
                                                            'labelPosition': 'none',
                                                            'alignment': 'left',
                                                            'displayName': 'Center section',
                                                            'spacers': {
                                                                'before': 0,
                                                                'after': 3,
                                                                'xax-before': 0,
                                                                'xax-after': 0
                                                            }
                                                        },
                                                        'pack': 'start'
                                                    },
                                                    'layout': {'box-flex': 1, 'min-width': 0}
                                                }]
                                            },
                                            'layout': {'min-width': 0}
                                        }, {
                                            'id': 'def_3_2',
                                            'comp': {
                                                'name': 'HBox',
                                                'orientation': 'horizontal',
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'displayName': 'def_3_2',
                                                    'alignment': 'left',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 3,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'items': [{
                                                    'id': 'centerToRightSpacer',
                                                    'comp': {'name': 'HSpacer', 'size': 3}
                                                }, {
                                                    'id': 'Right',
                                                    'comp': {
                                                        'name': 'FieldBox',
                                                        'orientation': 'vertical',
                                                        'editorData': {
                                                            'labelPosition': 'none',
                                                            'displayName': 'Right section',
                                                            'alignment': 'left',
                                                            'spacers': {
                                                                'before': 0,
                                                                'after': 3,
                                                                'xax-before': 0,
                                                                'xax-after': 0
                                                            }
                                                        },
                                                        'items': []
                                                    },
                                                    'layout': {'min-width': 0}
                                                }]
                                            },
                                            'layout': {'min-width': 0}
                                        }],
                                        'orientation': 'vertical'
                                    }, 'id': 'def_3'
                                }, {
                                    'id': 'Bottom',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Bottom section',
                                            'alignment': 'left',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 0}
                                        }
                                    },
                                    'layout': {'box-flex-pack': 'start', 'min-width': 0}
                                }, {'id': 'bottomSpacer', 'comp': {'name': 'VSpacer', 'size': 0}}]
                            },
                            'id': 'def_0',
                            'editorData': {'wasChanged': true, 'originalWidth': 601},
                            'format': 'Mobile'
                        }, {
                            'name': 'NewsPostsView_i6m78132490_dup_i6qm3pea337_dup_i6tbtsmw87_dup_i70h8xsg189_dup_i7g5p5vd171',
                            'forType': 'NewsPosts_i6m78132489',
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'Top',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Top section',
                                            'alignment': 'left',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 3}
                                        },
                                        'items': []
                                    },
                                    'layout': {'min-width': 0}
                                }, {
                                    'id': 'topSpacer',
                                    'comp': {'name': 'VSpacer', 'size': '0'}
                                }, {
                                    'comp': {
                                        'name': 'HBox',
                                        'items': [{
                                            'id': 'Left',
                                            'comp': {
                                                'name': 'FieldBox',
                                                'orientation': 'horizontal',
                                                'items': [{
                                                    'id': 'image_1',
                                                    'data': 'image',
                                                    'comp': {
                                                        'name': 'Field',
                                                        'box-align': 'start',
                                                        'width': 170,
                                                        'height': 170,
                                                        'items': [{
                                                            'id': 'image_1_proxy',
                                                            'data': 'image',
                                                            'comp': {'name': 'Image', 'style': 'wp2'}
                                                        }],
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 30,
                                                            'xax-before': 0,
                                                            'xax-after': 24
                                                        },
                                                        'hidden': false
                                                    }
                                                }, {
                                                    'id': 'fld_i9a27wy1140',
                                                    'data': 'i9a27wwq',
                                                    'comp': {
                                                        'name': 'Field',
                                                        'width': 360,
                                                        'height': '220',
                                                        'items': [{
                                                            'id': 'fld_i9a27wy1140_proxy',
                                                            'data': 'i9a27wwq',
                                                            'comp': {'name': 'Video'},
                                                            'layout': {'position': 'relative'}
                                                        }],
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 28,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'box-align': 'left',
                                                        'hidden': true
                                                    }
                                                }],
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'alignment': 'left',
                                                    'displayName': 'Left section',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 0,
                                                        'xax-after': 20
                                                    }
                                                }
                                            },
                                            'layout': {'spacerAfter': 0, 'min-width': 0}
                                        }, {
                                            'id': 'leftToCenterSpacer',
                                            'comp': {'name': 'HSpacer', 'size': '0'}
                                        }, {
                                            'id': 'Center',
                                            'comp': {
                                                'name': 'FieldBox',
                                                'orientation': 'vertical',
                                                'items': [{
                                                    'id': 'fld_i93vl49b409',
                                                    'data': 'i93vl483',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0',
                                                        'items': [{
                                                            'id': 'fld_i93vl49b409_proxy',
                                                            'data': 'i93vl483',
                                                            'comp': {
                                                                'name': 'Date',
                                                                'format': 'mmm dd, yyyy',
                                                                'color': 'color_14'
                                                            },
                                                            'layout': {'text-align': 'left'}
                                                        }],
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 3,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'hidden': true,
                                                        'labelPosition': 'none'
                                                    },
                                                    'experiment': 'NewListFields'
                                                }, {
                                                    'id': 'text_1',
                                                    'data': 'title',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'width-mode': 'auto',
                                                        'min-lines': 0,
                                                        'max-lines': 0,
                                                        'items': [{
                                                            'id': 'text_1_proxy',
                                                            'data': 'title',
                                                            'comp': {
                                                                'name': 'Label',
                                                                'style': 'Heading S',
                                                                'bold': false,
                                                                'italic': false,
                                                                'color': 'color_14'
                                                            },
                                                            'layout': {'text-align': 'left'}
                                                        }],
                                                        'spacers': {'before': 0, 'after': 0, 'xax-before': 0},
                                                        'box-align': 'start',
                                                        'height-mode': 'auto'
                                                    }
                                                }, {
                                                    'id': 'fld_hfnxgxqo',
                                                    'data': 'Strng_sTxt1',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'items': [{
                                                            'id': 'fld_hfnxgxqo_proxy',
                                                            'data': 'Strng_sTxt1',
                                                            'comp': {
                                                                'name': 'Label',
                                                                'style': 'Body L',
                                                                'bold': false,
                                                                'italic': false,
                                                                'underline': false,
                                                                'color': 'color_14'
                                                            },
                                                            'layout': {'text-align': 'left'}
                                                        }],
                                                        'hidden': true,
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 3,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0'
                                                    }
                                                }, {
                                                    'id': 'fld_hfxs75de',
                                                    'data': 'wxRchTxt_sTxt0',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'items': [{
                                                            'id': 'fld_hfxs75de_proxy',
                                                            'data': 'wxRchTxt_sTxt0',
                                                            'comp': {'name': 'Label', 'color': 'color_14'},
                                                            'layout': {'text-align': 'left'}
                                                        }],
                                                        'spacers': {
                                                            'before': 10,
                                                            'after': 0,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0',
                                                        'labelPosition': 'none',
                                                        'hidden': false
                                                    }
                                                }, {
                                                    'id': 'fld_i70mkmcs53',
                                                    'data': 'Strng_sBttn0-v1c',
                                                    'comp': {
                                                        'name': 'Field',
                                                        'width': 160,
                                                        'items': [{
                                                            'id': 'fld_i70mkmcs53_proxy',
                                                            'data': 'Strng_sBttn0-v1c',
                                                            'comp': {
                                                                'name': 'Button2',
                                                                'style': 'b1',
                                                                'align': 'center'
                                                            },
                                                            'layout': {'position': 'relative'}
                                                        }],
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 23,
                                                            'after': 0,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'hidden': false,
                                                        'height': 40,
                                                        'box-align': 'left'
                                                    }
                                                }],
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'alignment': 'left',
                                                    'displayName': 'Center section',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 0,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'pack': 'start'
                                            },
                                            'layout': {'box-flex': 1, 'min-width': 0}
                                        }, {
                                            'id': 'centerToRightSpacer',
                                            'comp': {'name': 'HSpacer', 'size': '3'}
                                        }, {
                                            'id': 'Right',
                                            'comp': {
                                                'name': 'FieldBox',
                                                'orientation': 'vertical',
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'displayName': 'Right section',
                                                    'alignment': 'left',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 3,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'items': [{
                                                    'id': 'fld_i93vf7m8376',
                                                    'data': 'i93vf7ld',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': 136,
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0',
                                                        'items': [{
                                                            'id': 'fld_i93vf7m8376_proxy',
                                                            'data': 'i93vf7ld',
                                                            'comp': {
                                                                'name': 'Label',
                                                                'style': 'Heading S',
                                                                'color': 'color_14'
                                                            },
                                                            'layout': {'text-align': 'left'}
                                                        }],
                                                        'hidden': false,
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 0,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        }
                                                    },
                                                    'experiment': 'NewListFields'
                                                }]
                                            },
                                            'layout': {'min-width': 0}
                                        }]
                                    }, 'id': 'def_3'
                                }, {
                                    'id': 'Bottom',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Bottom section',
                                            'alignment': 'left',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 3}
                                        }
                                    },
                                    'layout': {'box-flex-pack': 'start', 'min-width': 0}
                                }, {'id': 'bottomSpacer', 'comp': {'name': 'VSpacer', 'size': '0'}}]
                            },
                            'id': 'def_0',
                            'editorData': {'wasChanged': true}
                        }],
                        'items': [{
                            'title': i18n.translate('i7g5p5w1.structure.custom.template.items.0.title'),
                            'image': {
                                '_type': 'wix:Image',
                                'title': '',
                                'src': '8d13be_64e809138eef4d6598ae071ab4c3c1db.jpg',
                                'width': 700,
                                'height': 463,
                                'alt': ''
                            },
                            'Strng_sTxt1': i18n.translate('i7g5p5w1.structure.custom.template.items.0.Strng_sTxt1'),
                            'wxRchTxt_sTxt0': {
                                'version': 1,
                                '_type': 'wix:RichText',
                                'links': [],
                                'text': i18n.translate('i7g5p5w1.structure.custom.template.items.0.wxRchTxt_sTxt0')
                            },
                            'links': {'_type': 'wix:Map'},
                            'Strng_sBttn0-v1c': i18n.translate('i7g5p5w1.structure.custom.template.items.0.Strng_sBttn0-v1c'),
                            'i93vf7ld': i18n.translate('i7g5p5w1.structure.custom.template.items.0.i93vf7ld'),
                            'i93vl483': {'_type': 'wix:Date', 'iso': '2023-01-03T00:00:00.000Z'},
                            'i9a27wwq': {
                                '_type': 'wix:Video',
                                'videoId': 'z1pSAwjC4JI',
                                'videoType': 'YOUTUBE',
                                'thumbnail': ''
                            }
                        }, {
                            'title': i18n.translate('i7g5p5w1.structure.custom.template.items.1.title'),
                            'image': {
                                '_type': 'wix:Image',
                                'title': '',
                                'src': '8d9f0b6eba677b00ab7a6e681d24a532.jpg',
                                'width': 800,
                                'height': 531,
                                'alt': ''
                            },
                            'Strng_sTxt1': i18n.translate('i7g5p5w1.structure.custom.template.items.1.Strng_sTxt1'),
                            'wxRchTxt_sTxt0': {
                                'version': 1,
                                '_type': 'wix:RichText',
                                'links': [],
                                'text': i18n.translate('i7g5p5w1.structure.custom.template.items.1.wxRchTxt_sTxt0')
                            },
                            'links': {'_type': 'wix:Map'},
                            'Strng_sBttn0-v1c': i18n.translate('i7g5p5w1.structure.custom.template.items.1.Strng_sBttn0-v1c'),
                            'i93vf7ld': i18n.translate('i7g5p5w1.structure.custom.template.items.1.i93vf7ld'),
                            'i93vl483': {'_type': 'wix:Date', 'iso': '2023-02-23T00:00:00.000Z'},
                            'i9a27wwq': {
                                '_type': 'wix:Video',
                                'videoId': 'f36EEfI0yr8',
                                'videoType': 'YOUTUBE',
                                'thumbnail': ''
                            }
                        }, {
                            'title': i18n.translate('i7g5p5w1.structure.custom.template.items.2.title'),
                            'image': {
                                '_type': 'wix:Image',
                                'title': 'shutterstock_63434107_size.jpg',
                                'src': '8d13be_1a368e42d8e94278bc9bec1ec316a270.jpg',
                                'width': 700,
                                'height': 469,
                                'alt': ''
                            },
                            'Strng_sTxt1': i18n.translate('i7g5p5w1.structure.custom.template.items.2.Strng_sTxt1'),
                            'wxRchTxt_sTxt0': {
                                'version': 1,
                                '_type': 'wix:RichText',
                                'links': [],
                                'text': i18n.translate('i7g5p5w1.structure.custom.template.items.2.wxRchTxt_sTxt0')
                            },
                            'links': {'_type': 'wix:Map'},
                            'Strng_sBttn0-v1c': i18n.translate('i7g5p5w1.structure.custom.template.items.2.Strng_sBttn0-v1c'),
                            'i93vf7ld': i18n.translate('i7g5p5w1.structure.custom.template.items.2.i93vf7ld'),
                            'i93vl483': {'_type': 'wix:Date', 'iso': '2023-05-28T00:00:00.000Z'},
                            'i9a27wwq': {
                                '_type': 'wix:Video',
                                'videoId': 'wGOTBSlYkzU',
                                'videoType': 'YOUTUBE',
                                'thumbnail': ''
                            }
                        }],
                        'displayName': i18n.translate('i7g5p5w1.structure.custom.template.displayName')
                    }
                },
                'id': 'i7g5p5w1'
            },
            'preset': {
                'rect': {'width': 162, 'height': 172, 'x': 162, 'y': 359},
                'label': 'i7g5p5w1.structure.custom.template.displayName',
                'tags': null
            }
        }, {
            'id': 'Lists_List_Products_And_Services_6',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.viewer.skins.AppPartSkin',
                'layout': {
                    'width': 577,
                    'height': 697,
                    'x': 704,
                    'y': 2271,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wixapps.integration.components.AppPart2',
                'data': {
                    'type': 'AppBuilderComponent',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'appInnerID': '2',
                    'appPartName': 'appparti7g5gc823'
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'id': 'app1',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {'groups': {}, 'properties': {'rd': '7px'}, 'propertiesSource': {'rd': 'value'}},
                    'componentClassName': '',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'system',
                    'skin': 'wysiwyg.viewer.skins.AppPartSkin'
                },
                'custom': {
                    'template': {
                        'type': {
                            'version': 0,
                            'baseTypes': [],
                            'name': 'NewsPosts_i7ezjf6v55',
                            'fields': [{
                                'displayName': i18n.translate('i7g5gc88.structure.custom.template.type.fields.0.displayName'),
                                'name': 'title',
                                'searchable': false,
                                'type': 'String',
                                'defaultValue': i18n.translate('i7g5gc88.structure.custom.template.type.fields.0.defaultValue'),
                                'computed': false
                            }, {
                                'name': 'Strng_sTxt1',
                                'searchable': false,
                                'displayName': i18n.translate('i7g5gc88.structure.custom.template.type.fields.1.displayName'),
                                'type': 'String',
                                'computed': false,
                                'defaultValue': i18n.translate('i7g5gc88.structure.custom.template.type.fields.1.defaultValue')
                            }, {
                                'name': 'i941kkez',
                                'type': 'wix:Date',
                                'searchable': false,
                                'displayName': i18n.translate('i7g5gc88.structure.custom.template.type.fields.2.displayName'),
                                'computed': false,
                                'defaultValue': {'iso': '2020-01-01T00:00:00.000Z', '_type': 'wix:Date'}
                            }, {
                                'displayName': i18n.translate('i7g5gc88.structure.custom.template.type.fields.3.displayName'),
                                'type': 'wix:RichText',
                                'searchable': false,
                                'defaultValue': {
                                    'version': 1,
                                    '_type': 'wix:RichText',
                                    'links': [],
                                    'text': i18n.translate('i7g5gc88.structure.custom.template.type.fields.3.defaultValue')
                                },
                                'name': 'wxRchTxt_sTxt0',
                                'computed': false
                            }, {
                                'metadata': {'showAsHint': 'AsPrice'},
                                'name': 'i941fskf',
                                'displayName': i18n.translate('i7g5gc88.structure.custom.template.type.fields.4.displayName'),
                                'searchable': false,
                                'type': 'String',
                                'defaultValue': i18n.translate('i7g5gc88.structure.custom.template.type.fields.4.defaultValue'),
                                'computed': false
                            }, {
                                'type': 'wix:Image',
                                'defaultValue': {
                                    'height': 1276,
                                    'src': 'c8381ce0e7a94ad09ceaada2fc64081d.jpg',
                                    'width': 1920,
                                    '_type': 'wix:Image',
                                    'title': ''
                                },
                                'displayName': i18n.translate('i7g5gc88.structure.custom.template.type.fields.5.displayName'),
                                'searchable': false,
                                'name': 'image',
                                'computed': false
                            }, {
                                'defaultValue': {
                                    'videoId': 'f36EEfI0yr8',
                                    '_type': 'wix:Video',
                                    'videoType': 'YOUTUBE'
                                },
                                'name': 'i7g5dhlx',
                                'type': 'wix:Video',
                                'searchable': false,
                                'displayName': i18n.translate('i7g5gc88.structure.custom.template.type.fields.6.displayName'),
                                'computed': false
                            }, {
                                'name': 'Strng_sBttn0-v1c',
                                'defaultValue': i18n.translate('i7g5gc88.structure.custom.template.type.fields.7.defaultValue'),
                                'searchable': false,
                                'metadata': {'showAsHint': 'AsButton'},
                                'type': 'String',
                                'displayName': i18n.translate('i7g5gc88.structure.custom.template.type.fields.7.displayName'),
                                'computed': false
                            }, {
                                'name': 'links',
                                'type': 'wix:Map',
                                'searchable': false,
                                'displayName': '',
                                'defaultValue': {'_type': 'wix:Map'},
                                'computed': false
                            }],
                            'displayName': i18n.translate('i7g5gc88.structure.custom.template.type.displayName')
                        },
                        'views': [{
                            'name': 'NewsPostsView_i7ezjf6w56_dup_i7g5gc7h79',
                            'forType': 'Array',
                            'vars': {'itemSpacing': 50, 'paginationColor': 'color_15'},
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'paginatedlist',
                                    'comp': {
                                        'name': 'PaginatedList',
                                        'hidden': {'$expr': 'eq(Array.length(this), 0)'},
                                        'hidePagination': 'true',
                                        'itemsPerPage': '10',
                                        'templates': {
                                            'item': {
                                                'data': '$positionInParent',
                                                'comp': {
                                                    'name': 'SwitchBox',
                                                    'cases': {
                                                        'default': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'NewsPostsView_i7ezjf6w56_dup_i7g5gc7h79'}
                                                        }, {
                                                            'id': 'footerSpacer',
                                                            'comp': {
                                                                'name': 'VSpacer',
                                                                'size': {'$expr': '$itemSpacing'}
                                                            }
                                                        }, {
                                                            'id': 'gutterLine',
                                                            'comp': {'name': 'HorizontalLine', 'hidden': 'true'},
                                                            'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                        }],
                                                        'last': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'NewsPostsView_i7ezjf6w56_dup_i7g5gc7h79'}
                                                        }, {
                                                            'comp': {
                                                                'name': 'VBox',
                                                                'hidden': 'true',
                                                                'items': [{
                                                                    'id': 'footerSpacer',
                                                                    'comp': {
                                                                        'name': 'VSpacer',
                                                                        'size': {'$expr': '$itemSpacing'}
                                                                    }
                                                                }, {
                                                                    'id': 'gutterLine',
                                                                    'comp': {
                                                                        'name': 'HorizontalLine',
                                                                        'hidden': 'true'
                                                                    },
                                                                    'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                                }]
                                                            }, 'id': 'def_7'
                                                        }, {
                                                            'comp': {'name': 'VSpacer', 'size': '9'},
                                                            'id': 'def_10'
                                                        }, {
                                                            'id': 'paginationNavigationBox',
                                                            'comp': {
                                                                'name': 'HBox',
                                                                'hidden': {'$expr': '$hidePagination'},
                                                                'box-align': 'center',
                                                                'items': [{
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_12'
                                                                }, {
                                                                    'value': '◄',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationPrev',
                                                                        'events': {'dom:click': 'prevPageClicked'}
                                                                    },
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_13'
                                                                }, {
                                                                    'value': {'$expr': 'String.concat($currentPage,\' / \',$maxPage)'},
                                                                    'comp': {'name': 'Label'},
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_14'
                                                                }, {
                                                                    'value': '►',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationNext',
                                                                        'events': {'dom:click': 'nextPageClicked'}
                                                                    },
                                                                    'id': 'def_15'
                                                                }, {
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_16'
                                                                }]
                                                            },
                                                            'layout': {'position': 'relative'}
                                                        }]
                                                    },
                                                    'css': {'min-width': '100%'}
                                                },
                                                'id': '$positionInParent'
                                            }
                                        }
                                    }
                                }, {
                                    'id': 'noItemsLabel',
                                    'value': 'There are no items in this list',
                                    'comp': {'name': 'Label', 'hidden': {'$expr': 'gt(String.length(this), 0)'}}
                                }],
                                'editorData': {'isPaginated': true}
                            },
                            'stylesheet': [{
                                '.paginationPrev, .paginationNext': {
                                    'padding': '0 5px',
                                    'display': 'inline-block',
                                    'font-size': '14px',
                                    'cursor': 'pointer',
                                    '-webkit-touch-callout': 'none',
                                    '-webkit-user-select': 'none',
                                    '-khtml-user-select': 'none',
                                    '-moz-user-select': 'none',
                                    '-ms-user-select': 'none',
                                    'user-select': 'none'
                                }
                            }],
                            'customizations': [{
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.hidePagination',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Split your list across multiple pages',
                                    'defaultVal': 'true',
                                    'trueVal': 'false',
                                    'spaceAfter': '10'
                                }
                            }, {
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.itemsPerPage',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '120',
                                    'minVal': '1',
                                    'label': 'Items Per Page',
                                    'spaceAfter': '20',
                                    'hiddenwhen': {
                                        'fieldId': 'paginatedlist',
                                        'key': 'comp.hidePagination',
                                        'value': 'true'
                                    }
                                }
                            }, {
                                'priority': 100,
                                'fieldId': 'vars',
                                'key': 'itemSpacing',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '300',
                                    'minVal': '0',
                                    'label': 'Item Spacing',
                                    'spaceAfter': '20'
                                }
                            }, {
                                'priority': 95,
                                'fieldId': 'gutterLine',
                                'key': 'comp.hidden',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Show item separator',
                                    'defaultVal': 'false',
                                    'trueVal': 'false'
                                }
                            }, {
                                'priority': 90,
                                'fieldId': 'gutterLine',
                                'key': 'comp.style',
                                'input': {
                                    'name': 'changestyle',
                                    'showAs': 'link',
                                    'label': 'Change Item Separator Style',
                                    'spaceAfter': '20'
                                }
                            }],
                            'id': 'i7g5gc7i',
                            'format': 'Mobile'
                        }, {
                            'name': 'NewsPostsView_i7ezjf6w56_dup_i7g5gc7h79',
                            'forType': 'Array',
                            'vars': {'itemSpacing': 40, 'paginationColor': 'color_15'},
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'paginatedlist',
                                    'comp': {
                                        'name': 'PaginatedList',
                                        'hidden': {'$expr': 'eq(Array.length(this), 0)'},
                                        'hidePagination': 'true',
                                        'itemsPerPage': '10',
                                        'templates': {
                                            'item': {
                                                'data': '$positionInParent',
                                                'comp': {
                                                    'name': 'SwitchBox',
                                                    'cases': {
                                                        'default': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'NewsPostsView_i7ezjf6w56_dup_i7g5gc7h79'}
                                                        }, {
                                                            'id': 'footerSpacer',
                                                            'comp': {
                                                                'name': 'VSpacer',
                                                                'size': {'$expr': '$itemSpacing'}
                                                            }
                                                        }, {
                                                            'id': 'gutterLine',
                                                            'comp': {'name': 'HorizontalLine', 'hidden': 'true'},
                                                            'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                        }],
                                                        'last': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'NewsPostsView_i7ezjf6w56_dup_i7g5gc7h79'}
                                                        }, {
                                                            'comp': {
                                                                'name': 'VBox',
                                                                'hidden': 'true',
                                                                'items': [{
                                                                    'id': 'footerSpacer',
                                                                    'comp': {
                                                                        'name': 'VSpacer',
                                                                        'size': {'$expr': '$itemSpacing'}
                                                                    }
                                                                }, {
                                                                    'id': 'gutterLine',
                                                                    'comp': {
                                                                        'name': 'HorizontalLine',
                                                                        'hidden': 'true'
                                                                    },
                                                                    'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                                }]
                                                            }, 'id': 'def_7'
                                                        }, {
                                                            'comp': {'name': 'VSpacer', 'size': '9'},
                                                            'id': 'def_10'
                                                        }, {
                                                            'id': 'paginationNavigationBox',
                                                            'comp': {
                                                                'name': 'HBox',
                                                                'hidden': {'$expr': '$hidePagination'},
                                                                'box-align': 'center',
                                                                'items': [{
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_12'
                                                                }, {
                                                                    'value': '◄',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationPrev',
                                                                        'events': {'dom:click': 'prevPageClicked'}
                                                                    },
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_13'
                                                                }, {
                                                                    'value': {'$expr': 'String.concat($currentPage,\' / \',$maxPage)'},
                                                                    'comp': {'name': 'Label'},
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_14'
                                                                }, {
                                                                    'value': '►',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationNext',
                                                                        'events': {'dom:click': 'nextPageClicked'}
                                                                    },
                                                                    'id': 'def_15'
                                                                }, {
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_16'
                                                                }]
                                                            },
                                                            'layout': {'position': 'relative'}
                                                        }]
                                                    },
                                                    'css': {'min-width': '100%'}
                                                },
                                                'id': '$positionInParent'
                                            }
                                        }
                                    }
                                }, {
                                    'id': 'noItemsLabel',
                                    'value': 'There are no items in this list',
                                    'comp': {'name': 'Label', 'hidden': {'$expr': 'gt(String.length(this), 0)'}}
                                }],
                                'editorData': {'isPaginated': true}
                            },
                            'stylesheet': [{
                                '.paginationPrev, .paginationNext': {
                                    'padding': '0 5px',
                                    'display': 'inline-block',
                                    'font-size': '14px',
                                    'cursor': 'pointer',
                                    '-webkit-touch-callout': 'none',
                                    '-webkit-user-select': 'none',
                                    '-khtml-user-select': 'none',
                                    '-moz-user-select': 'none',
                                    '-ms-user-select': 'none',
                                    'user-select': 'none'
                                }
                            }],
                            'customizations': [{
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.hidePagination',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Split your list across multiple pages',
                                    'defaultVal': 'true',
                                    'trueVal': 'false',
                                    'spaceAfter': '10'
                                }
                            }, {
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.itemsPerPage',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '120',
                                    'minVal': '1',
                                    'label': 'Items Per Page',
                                    'spaceAfter': '20',
                                    'hiddenwhen': {
                                        'fieldId': 'paginatedlist',
                                        'key': 'comp.hidePagination',
                                        'value': 'true'
                                    }
                                }
                            }, {
                                'priority': 100,
                                'fieldId': 'vars',
                                'key': 'itemSpacing',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '300',
                                    'minVal': '0',
                                    'label': 'Item Spacing',
                                    'spaceAfter': '20'
                                }
                            }, {
                                'priority': 95,
                                'fieldId': 'gutterLine',
                                'key': 'comp.hidden',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Show item separator',
                                    'defaultVal': 'false',
                                    'trueVal': 'false'
                                }
                            }, {
                                'priority': 90,
                                'fieldId': 'gutterLine',
                                'key': 'comp.style',
                                'input': {
                                    'name': 'changestyle',
                                    'showAs': 'link',
                                    'label': 'Change Item Separator Style',
                                    'spaceAfter': '20'
                                }
                            }],
                            'id': 'i7g5gc7i'
                        }, {
                            'name': 'NewsPostsView_i7ezjf6w56_dup_i7g5gc7h79',
                            'forType': 'NewsPosts_i7ezjf6v55',
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'Top',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Top section',
                                            'alignment': 'left',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 0}
                                        },
                                        'items': [{
                                            'id': 'image_1',
                                            'data': 'image',
                                            'comp': {
                                                'name': 'Field',
                                                'width': 280,
                                                'height': 199,
                                                'items': [{
                                                    'id': 'image_1_proxy',
                                                    'data': 'image',
                                                    'comp': {'name': 'Image', 'style': 'wp2'}
                                                }],
                                                'hidden': false,
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 0,
                                                    'after': 10,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'box-align': 'left'
                                            }
                                        }, {
                                            'id': 'fld_i7g5dhmy51',
                                            'data': 'i7g5dhlx',
                                            'comp': {
                                                'name': 'Field',
                                                'width': 280,
                                                'height': '220',
                                                'items': [{
                                                    'id': 'fld_i7g5dhmy51_proxy',
                                                    'data': 'i7g5dhlx',
                                                    'comp': {'name': 'Video'},
                                                    'layout': {'position': 'relative'}
                                                }],
                                                'hidden': true,
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 0,
                                                    'after': 10,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'box-align': 'left'
                                            }
                                        }]
                                    },
                                    'layout': {'min-width': 0}
                                }, {
                                    'id': 'topSpacer',
                                    'comp': {'name': 'VSpacer', 'size': 0}
                                }, {
                                    'comp': {
                                        'name': 'VBox',
                                        'items': [{
                                            'id': 'def_3_0',
                                            'comp': {
                                                'name': 'HBox',
                                                'orientation': 'horizontal',
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'displayName': 'def_3_0',
                                                    'alignment': 'left',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 3,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'items': [{
                                                    'id': 'Left',
                                                    'comp': {
                                                        'name': 'FieldBox',
                                                        'orientation': 'vertical',
                                                        'items': [],
                                                        'editorData': {
                                                            'labelPosition': 'none',
                                                            'alignment': 'left',
                                                            'displayName': 'Left section',
                                                            'spacers': {
                                                                'before': 0,
                                                                'after': 3,
                                                                'xax-before': 0,
                                                                'xax-after': 0
                                                            }
                                                        }
                                                    },
                                                    'layout': {'spacerAfter': 0, 'min-width': 0}
                                                }, {
                                                    'id': 'leftToCenterSpacer',
                                                    'comp': {'name': 'HSpacer', 'size': 0}
                                                }, {
                                                    'id': 'Center',
                                                    'comp': {
                                                        'name': 'FieldBox',
                                                        'orientation': 'vertical',
                                                        'items': [{
                                                            'id': 'fld_i9b2zk1p70',
                                                            'data': 'i941kkez',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': '200',
                                                                'width-mode': 'auto',
                                                                'min-lines': '0',
                                                                'max-lines': '0',
                                                                'items': [{
                                                                    'id': 'fld_i9b2zk1p70_proxy',
                                                                    'data': 'i941kkez',
                                                                    'comp': {
                                                                        'name': 'Date',
                                                                        'format': 'mmmm dd, yyyy',
                                                                        'color': 'color_14'
                                                                    },
                                                                    'layout': {'text-align': 'left'}
                                                                }],
                                                                'spacers': {
                                                                    'before': 0,
                                                                    'after': 3,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                },
                                                                'hidden': true,
                                                                'labelPosition': 'none'
                                                            },
                                                            'experiment': 'NewListFields'
                                                        }, {
                                                            'id': 'text_1',
                                                            'data': 'title',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': 240,
                                                                'width-mode': 'auto',
                                                                'min-lines': 0,
                                                                'max-lines': 0,
                                                                'items': [{
                                                                    'id': 'text_1_proxy',
                                                                    'data': 'title',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'style': 'Body L',
                                                                        'bold': false,
                                                                        'italic': false
                                                                    },
                                                                    'layout': {'text-align': 'left'}
                                                                }],
                                                                'spacers': {
                                                                    'before': 0,
                                                                    'after': 2,
                                                                    'xax-before': 0
                                                                },
                                                                'box-align': 'start',
                                                                'height-mode': 'auto'
                                                            }
                                                        }, {
                                                            'id': 'fld_i9b2zo9s79',
                                                            'data': 'i941fskf',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': '200',
                                                                'width-mode': 'auto',
                                                                'min-lines': '0',
                                                                'max-lines': '0',
                                                                'items': [{
                                                                    'id': 'fld_i9b2zo9s79_proxy',
                                                                    'data': 'i941fskf',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'style': 'Heading S',
                                                                        'color': 'color_14'
                                                                    },
                                                                    'layout': {'text-align': 'left'}
                                                                }],
                                                                'hidden': false,
                                                                'labelPosition': 'none',
                                                                'spacers': {
                                                                    'before': 0,
                                                                    'after': 3,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                }
                                                            },
                                                            'experiment': 'NewListFields'
                                                        }, {
                                                            'id': 'fld_hfnxgxqo',
                                                            'data': 'Strng_sTxt1',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': 240,
                                                                'items': [{
                                                                    'id': 'fld_hfnxgxqo_proxy',
                                                                    'data': 'Strng_sTxt1',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'style': 'Body M',
                                                                        'bold': false,
                                                                        'italic': false,
                                                                        'underline': false,
                                                                        'color': 'color_14'
                                                                    },
                                                                    'layout': {'text-align': 'left'}
                                                                }],
                                                                'hidden': true,
                                                                'labelPosition': 'none',
                                                                'spacers': {
                                                                    'before': 0,
                                                                    'after': 0,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                },
                                                                'width-mode': 'auto',
                                                                'min-lines': '0',
                                                                'max-lines': '0'
                                                            }
                                                        }, {
                                                            'id': 'fld_hfxs75de',
                                                            'data': 'wxRchTxt_sTxt0',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': 240,
                                                                'items': [{
                                                                    'id': 'fld_hfxs75de_proxy',
                                                                    'data': 'wxRchTxt_sTxt0',
                                                                    'comp': {'name': 'Label'},
                                                                    'layout': {'text-align': 'left'}
                                                                }],
                                                                'hidden': false,
                                                                'labelPosition': 'none',
                                                                'spacers': {
                                                                    'before': 11,
                                                                    'after': 0,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                },
                                                                'width-mode': 'auto',
                                                                'min-lines': '0',
                                                                'max-lines': '0'
                                                            }
                                                        }, {
                                                            'id': 'fld_hhq9fct831',
                                                            'data': 'Strng_sBttn0-v1c',
                                                            'comp': {
                                                                'name': 'Field',
                                                                'width': 280,
                                                                'items': [{
                                                                    'id': 'fld_hhq9fct831_proxy',
                                                                    'data': 'Strng_sBttn0-v1c',
                                                                    'comp': {'name': 'Button2', 'style': 'b1'},
                                                                    'layout': {'position': 'relative'}
                                                                }],
                                                                'hidden': false,
                                                                'height': 44,
                                                                'labelPosition': 'none',
                                                                'spacers': {
                                                                    'before': 19,
                                                                    'after': 0,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                },
                                                                'box-align': 'left'
                                                            }
                                                        }],
                                                        'editorData': {
                                                            'labelPosition': 'none',
                                                            'alignment': 'left',
                                                            'displayName': 'Center section',
                                                            'spacers': {
                                                                'before': 0,
                                                                'after': 3,
                                                                'xax-before': 0,
                                                                'xax-after': 0
                                                            }
                                                        },
                                                        'pack': 'start'
                                                    },
                                                    'layout': {'box-flex': 1, 'min-width': 177}
                                                }]
                                            },
                                            'layout': {'min-width': 0}
                                        }, {
                                            'id': 'def_3_1',
                                            'comp': {
                                                'name': 'HBox',
                                                'orientation': 'horizontal',
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'displayName': 'def_3_1',
                                                    'alignment': 'left',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 3,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'items': [{
                                                    'id': 'centerToRightSpacer',
                                                    'comp': {'name': 'HSpacer', 'size': 3}
                                                }]
                                            },
                                            'layout': {'min-width': 0}
                                        }, {
                                            'id': 'def_3_2',
                                            'comp': {
                                                'name': 'HBox',
                                                'orientation': 'horizontal',
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'displayName': 'def_3_2',
                                                    'alignment': 'left',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 3,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'items': [{
                                                    'id': 'Right',
                                                    'comp': {
                                                        'name': 'VBox',
                                                        'orientation': 'vertical',
                                                        'editorData': {
                                                            'labelPosition': 'none',
                                                            'displayName': 'Right section',
                                                            'alignment': 'left',
                                                            'spacers': {
                                                                'before': 0,
                                                                'after': 3,
                                                                'xax-before': 0,
                                                                'xax-after': 0
                                                            }
                                                        },
                                                        'items': [{
                                                            'id': 'Right_0',
                                                            'comp': {
                                                                'name': 'FieldBox',
                                                                'orientation': 'horizontal',
                                                                'editorData': {
                                                                    'labelPosition': 'none',
                                                                    'displayName': 'Right_0',
                                                                    'alignment': 'left',
                                                                    'spacers': {
                                                                        'before': 0,
                                                                        'after': 3,
                                                                        'xax-before': 3,
                                                                        'xax-after': 0
                                                                    }
                                                                },
                                                                'items': []
                                                            },
                                                            'layout': {'min-width': 0}
                                                        }, {
                                                            'id': 'Right_1',
                                                            'comp': {
                                                                'name': 'FieldBox',
                                                                'orientation': 'horizontal',
                                                                'editorData': {
                                                                    'labelPosition': 'none',
                                                                    'displayName': 'Right_1',
                                                                    'alignment': 'left',
                                                                    'spacers': {
                                                                        'before': 0,
                                                                        'after': 3,
                                                                        'xax-before': 3,
                                                                        'xax-after': 0
                                                                    }
                                                                },
                                                                'items': []
                                                            },
                                                            'layout': {'min-width': 0}
                                                        }]
                                                    },
                                                    'layout': {'min-width': 0}
                                                }]
                                            },
                                            'layout': {'min-width': 0}
                                        }],
                                        'orientation': 'vertical'
                                    }, 'id': 'def_3'
                                }, {
                                    'id': 'Bottom',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Bottom section',
                                            'alignment': 'left',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 0}
                                        },
                                        'items': []
                                    },
                                    'layout': {'box-flex-pack': 'start', 'min-width': 0}
                                }, {'id': 'bottomSpacer', 'comp': {'name': 'VSpacer', 'size': 0}}]
                            },
                            'id': 'def_0',
                            'editorData': {'originalWidth': 601, 'wasChanged': true},
                            'format': 'Mobile'
                        }, {
                            'name': 'NewsPostsView_i7ezjf6w56_dup_i7g5gc7h79',
                            'forType': 'NewsPosts_i7ezjf6v55',
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'Top',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Top section',
                                            'alignment': 'left',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 3}
                                        },
                                        'items': []
                                    },
                                    'layout': {'min-width': 0}
                                }, {
                                    'id': 'topSpacer',
                                    'comp': {'name': 'VSpacer', 'size': '0'}
                                }, {
                                    'comp': {
                                        'name': 'HBox',
                                        'items': [{
                                            'id': 'Left',
                                            'comp': {
                                                'name': 'FieldBox',
                                                'orientation': 'vertical',
                                                'items': [],
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'alignment': 'left',
                                                    'displayName': 'Left section',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 0,
                                                        'xax-after': 20
                                                    }
                                                }
                                            },
                                            'layout': {'spacerAfter': 0, 'min-width': 0}
                                        }, {
                                            'id': 'leftToCenterSpacer',
                                            'comp': {'name': 'HSpacer', 'size': '0'}
                                        }, {
                                            'id': 'Center',
                                            'comp': {
                                                'name': 'FieldBox',
                                                'orientation': 'vertical',
                                                'items': [{
                                                    'id': 'fld_i941kkgc94',
                                                    'data': 'i941kkez',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0',
                                                        'items': [{
                                                            'id': 'fld_i941kkgc94_proxy',
                                                            'data': 'i941kkez',
                                                            'comp': {
                                                                'name': 'Date',
                                                                'style': 'Body M',
                                                                'format': 'mmm dd, yyyy',
                                                                'color': 'color_14'
                                                            },
                                                            'layout': {'text-align': 'left'}
                                                        }],
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 3,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'hidden': true,
                                                        'labelPosition': 'none'
                                                    },
                                                    'experiment': 'NewListFields'
                                                }, {
                                                    'id': 'text_1',
                                                    'data': 'title',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'width-mode': 'auto',
                                                        'min-lines': 0,
                                                        'max-lines': 0,
                                                        'items': [{
                                                            'id': 'text_1_proxy',
                                                            'data': 'title',
                                                            'comp': {
                                                                'name': 'Label',
                                                                'style': 'Body L',
                                                                'bold': false,
                                                                'italic': false,
                                                                'color': 'color_14'
                                                            },
                                                            'layout': {'text-align': 'left'}
                                                        }],
                                                        'spacers': {'before': 0, 'after': 2, 'xax-before': 0},
                                                        'box-align': 'start',
                                                        'height-mode': 'auto'
                                                    }
                                                }, {
                                                    'id': 'fld_hfnxgxqo',
                                                    'data': 'Strng_sTxt1',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0',
                                                        'items': [{
                                                            'id': 'fld_hfnxgxqo_proxy',
                                                            'data': 'Strng_sTxt1',
                                                            'comp': {
                                                                'name': 'Label',
                                                                'style': 'Body M',
                                                                'bold': false,
                                                                'italic': false,
                                                                'underline': false,
                                                                'color': 'color_14'
                                                            },
                                                            'layout': {'text-align': 'left'}
                                                        }],
                                                        'hidden': true,
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 0,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        }
                                                    }
                                                }, {
                                                    'id': 'fld_i941fslt61',
                                                    'data': 'i941fskf',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'items': [{
                                                            'id': 'fld_i941fslt61_proxy',
                                                            'data': 'i941fskf',
                                                            'comp': {
                                                                'name': 'Label',
                                                                'style': 'Heading S',
                                                                'color': 'color_14'
                                                            },
                                                            'layout': {'text-align': 'left'}
                                                        }],
                                                        'hidden': false,
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 3,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0'
                                                    },
                                                    'experiment': 'NewListFields'
                                                }, {
                                                    'id': 'fld_hfxs75de',
                                                    'data': 'wxRchTxt_sTxt0',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'items': [{
                                                            'id': 'fld_hfxs75de_proxy',
                                                            'data': 'wxRchTxt_sTxt0',
                                                            'comp': {'name': 'Label', 'color': 'color_14'},
                                                            'layout': {'text-align': 'left'}
                                                        }],
                                                        'hidden': false,
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 11,
                                                            'after': 0,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0'
                                                    }
                                                }, {
                                                    'id': 'fld_hhq9fct831',
                                                    'data': 'Strng_sBttn0-v1c',
                                                    'comp': {
                                                        'name': 'Field',
                                                        'width': 160,
                                                        'items': [{
                                                            'id': 'fld_hhq9fct831_proxy',
                                                            'data': 'Strng_sBttn0-v1c',
                                                            'comp': {'name': 'Button2', 'style': 'b1'},
                                                            'layout': {'position': 'relative'}
                                                        }],
                                                        'hidden': false,
                                                        'height': 40,
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 19,
                                                            'after': 0,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'box-align': 'left'
                                                    }
                                                }],
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'alignment': 'left',
                                                    'displayName': 'Center section',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 0,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'pack': 'start'
                                            },
                                            'layout': {'box-flex': 1, 'min-width': 177}
                                        }, {
                                            'id': 'centerToRightSpacer',
                                            'comp': {'name': 'HSpacer', 'size': '3'}
                                        }, {
                                            'id': 'Right',
                                            'comp': {
                                                'name': 'FieldBox',
                                                'orientation': 'horizontal',
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'displayName': 'Right section',
                                                    'alignment': 'left',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 3,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'items': [{
                                                    'id': 'image_1',
                                                    'data': 'image',
                                                    'comp': {
                                                        'name': 'Field',
                                                        'width': 200,
                                                        'height': 199,
                                                        'items': [{
                                                            'id': 'image_1_proxy',
                                                            'data': 'image',
                                                            'comp': {'name': 'Image', 'style': 'wp2'}
                                                        }],
                                                        'hidden': false,
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 30,
                                                            'after': 0,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'box-align': 'left'
                                                    }
                                                }, {
                                                    'id': 'fld_i7g5dhmy51',
                                                    'data': 'i7g5dhlx',
                                                    'comp': {
                                                        'name': 'Field',
                                                        'width': 330,
                                                        'height': '220',
                                                        'items': [{
                                                            'id': 'fld_i7g5dhmy51_proxy',
                                                            'data': 'i7g5dhlx',
                                                            'comp': {'name': 'Video'},
                                                            'layout': {'position': 'relative'}
                                                        }],
                                                        'hidden': true,
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 30,
                                                            'after': 0,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'box-align': 'left'
                                                    }
                                                }]
                                            },
                                            'layout': {'min-width': 0}
                                        }]
                                    }, 'id': 'def_3'
                                }, {
                                    'id': 'Bottom',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Bottom section',
                                            'alignment': 'left',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 3}
                                        },
                                        'items': []
                                    },
                                    'layout': {'box-flex-pack': 'start', 'min-width': 0}
                                }, {'id': 'bottomSpacer', 'comp': {'name': 'VSpacer', 'size': '0'}}]
                            },
                            'id': 'def_0',
                            'editorData': {'wasChanged': true}
                        }],
                        'items': [{
                            'title': i18n.translate('i7g5gc88.structure.custom.template.items.0.title'),
                            'image': {
                                'width': 700,
                                'src': '8d13be_64e809138eef4d6598ae071ab4c3c1db.jpg',
                                'height': 463,
                                '_type': 'wix:Image',
                                'title': '',
                                'alt': ''
                            },
                            'Strng_sTxt1': i18n.translate('i7g5gc88.structure.custom.template.items.0.Strng_sTxt1'),
                            'wxRchTxt_sTxt0': {
                                'version': 1,
                                '_type': 'wix:RichText',
                                'links': [],
                                'text': i18n.translate('i7g5gc88.structure.custom.template.items.0.wxRchTxt_sTxt0')
                            },
                            'links': {'_type': 'wix:Map'},
                            'Strng_sBttn0-v1c': i18n.translate('i7g5gc88.structure.custom.template.items.0.Strng_sBttn0-v1c'),
                            'i7g5dhlx': {
                                '_type': 'wix:Video',
                                'videoId': 'z1pSAwjC4JI',
                                'videoType': 'YOUTUBE',
                                'thumbnail': ''
                            },
                            'i941fskf': i18n.translate('i7g5gc88.structure.custom.template.items.0.i941fskf'),
                            'i941kkez': {'_type': 'wix:Date', 'iso': '2023-01-03T00:00:00.000Z'}
                        }, {
                            'title': i18n.translate('i7g5gc88.structure.custom.template.items.1.title'),
                            'image': {
                                'width': 800,
                                'src': '8d9f0b6eba677b00ab7a6e681d24a532.jpg',
                                'height': 531,
                                '_type': 'wix:Image',
                                'title': '',
                                'alt': ''
                            },
                            'Strng_sTxt1': i18n.translate('i7g5gc88.structure.custom.template.items.1.Strng_sTxt1'),
                            'wxRchTxt_sTxt0': {
                                'version': 1,
                                '_type': 'wix:RichText',
                                'links': [],
                                'text': i18n.translate('i7g5gc88.structure.custom.template.items.1.wxRchTxt_sTxt0')
                            },
                            'links': {'_type': 'wix:Map'},
                            'Strng_sBttn0-v1c': i18n.translate('i7g5gc88.structure.custom.template.items.1.Strng_sBttn0-v1c'),
                            'i7g5dhlx': {
                                '_type': 'wix:Video',
                                'videoId': 'f36EEfI0yr8',
                                'videoType': 'YOUTUBE',
                                'thumbnail': ''
                            },
                            'i941fskf': i18n.translate('i7g5gc88.structure.custom.template.items.1.i941fskf'),
                            'i941kkez': {'_type': 'wix:Date', 'iso': '2023-02-23T00:00:00.000Z'}
                        }, {
                            'title': i18n.translate('i7g5gc88.structure.custom.template.items.2.title'),
                            'image': {
                                'width': 700,
                                'src': '8d13be_1a368e42d8e94278bc9bec1ec316a270.jpg',
                                'height': 469,
                                '_type': 'wix:Image',
                                'title': '',
                                'alt': ''
                            },
                            'Strng_sTxt1': i18n.translate('i7g5gc88.structure.custom.template.items.2.Strng_sTxt1'),
                            'wxRchTxt_sTxt0': {
                                'version': 1,
                                '_type': 'wix:RichText',
                                'links': [],
                                'text': i18n.translate('i7g5gc88.structure.custom.template.items.2.wxRchTxt_sTxt0')
                            },
                            'links': {'_type': 'wix:Map'},
                            'Strng_sBttn0-v1c': i18n.translate('i7g5gc88.structure.custom.template.items.2.Strng_sBttn0-v1c'),
                            'i7g5dhlx': {
                                '_type': 'wix:Video',
                                'videoId': 'wGOTBSlYkzU',
                                'videoType': 'YOUTUBE',
                                'thumbnail': ''
                            },
                            'i941fskf': i18n.translate('i7g5gc88.structure.custom.template.items.2.i941fskf'),
                            'i941kkez': {'_type': 'wix:Date', 'iso': '2023-05-28T00:00:00.000Z'}
                        }],
                        'displayName': i18n.translate('i7g5gc88.structure.custom.template.displayName')
                    }
                },
                'id': 'i7g5gc88'
            },
            'preset': {
                'rect': {'width': 162, 'height': 202, 'x': 162, 'y': 531.25},
                'label': 'i7g5gc88.structure.custom.template.displayName',
                'tags': null
            }
        }, {
            'id': 'Lists_List_Products_And_Services_7',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.viewer.skins.AppPartSkin',
                'layout': {
                    'width': 502,
                    'height': 1086,
                    'x': 73,
                    'y': 3032,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wixapps.integration.components.AppPart2',
                'data': {
                    'type': 'AppBuilderComponent',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'appInnerID': '2',
                    'appPartName': 'appparti7nby08g3'
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'id': 'app1',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {'groups': {}, 'properties': {'rd': '7px'}, 'propertiesSource': {'rd': 'value'}},
                    'componentClassName': '',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'system',
                    'skin': 'wysiwyg.viewer.skins.AppPartSkin'
                },
                'custom': {
                    'template': {
                        'type': {
                            'fields': [{
                                'displayName': i18n.translate('i7nby08k.structure.custom.template.type.fields.0.displayName'),
                                'name': 'title',
                                'searchable': false,
                                'type': 'String',
                                'defaultValue': i18n.translate('i7nby08k.structure.custom.template.type.fields.0.defaultValue'),
                                'computed': false
                            }, {
                                'name': 'i7nc9s23',
                                'searchable': false,
                                'displayName': i18n.translate('i7nby08k.structure.custom.template.type.fields.1.displayName'),
                                'type': 'String',
                                'computed': false,
                                'defaultValue': i18n.translate('i7nby08k.structure.custom.template.type.fields.1.defaultValue')
                            }, {
                                'name': 'i942w801',
                                'type': 'wix:Date',
                                'searchable': false,
                                'displayName': i18n.translate('i7nby08k.structure.custom.template.type.fields.2.displayName'),
                                'computed': false,
                                'defaultValue': {'iso': '2020-01-01T00:00:00.000Z', '_type': 'wix:Date'}
                            }, {
                                'displayName': i18n.translate('i7nby08k.structure.custom.template.type.fields.3.displayName'),
                                'name': 'wxRchTxt_sTxt4',
                                'defaultValue': {
                                    'version': 1,
                                    '_type': 'wix:RichText',
                                    'links': [],
                                    'text': i18n.translate('i7nby08k.structure.custom.template.type.fields.3.defaultValue')
                                },
                                'type': 'wix:RichText',
                                'searchable': false,
                                'computed': false
                            }, {
                                'metadata': {'showAsHint': 'AsPrice'},
                                'displayName': i18n.translate('i7nby08k.structure.custom.template.type.fields.4.displayName'),
                                'name': 'i942twrg',
                                'searchable': false,
                                'type': 'String',
                                'defaultValue': i18n.translate('i7nby08k.structure.custom.template.type.fields.4.defaultValue'),
                                'computed': false
                            }, {
                                'type': 'wix:Image',
                                'defaultValue': {
                                    'height': 1276,
                                    'src': 'c8381ce0e7a94ad09ceaada2fc64081d.jpg',
                                    'width': 1920,
                                    '_type': 'wix:Image',
                                    'title': ''
                                },
                                'displayName': i18n.translate('i7nby08k.structure.custom.template.type.fields.5.displayName'),
                                'searchable': false,
                                'name': 'image',
                                'computed': false
                            }, {
                                'name': 'i7nckuvb',
                                'defaultValue': {
                                    'videoId': 'f36EEfI0yr8',
                                    '_type': 'wix:Video',
                                    'videoType': 'YOUTUBE'
                                },
                                'type': 'wix:Video',
                                'searchable': false,
                                'displayName': i18n.translate('i7nby08k.structure.custom.template.type.fields.6.displayName'),
                                'computed': false
                            }, {
                                'defaultValue': i18n.translate('i7nby08k.structure.custom.template.type.fields.7.defaultValue'),
                                'searchable': false,
                                'metadata': {'showAsHint': 'AsButton'},
                                'type': 'String',
                                'displayName': i18n.translate('i7nby08k.structure.custom.template.type.fields.7.displayName'),
                                'name': 'Strng_sBttn0-78w',
                                'computed': false
                            }, {
                                'name': 'links',
                                'type': 'wix:Map',
                                'searchable': false,
                                'displayName': '',
                                'defaultValue': {'_type': 'wix:Map'},
                                'computed': false
                            }],
                            'version': 0,
                            'baseTypes': [],
                            'displayName': i18n.translate('i7nby08k.structure.custom.template.type.displayName'),
                            'name': 'Services_i7nby06y1'
                        },
                        'views': [{
                            'name': 'ServicesView_i7nby06z2',
                            'forType': 'Array',
                            'vars': {'itemSpacing': 40, 'paginationColor': 'color_15'},
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'paginatedlist',
                                    'comp': {
                                        'name': 'PaginatedList',
                                        'hidden': {'$expr': 'eq(Array.length(this), 0)'},
                                        'hidePagination': 'true',
                                        'itemsPerPage': '10',
                                        'templates': {
                                            'item': {
                                                'data': '$positionInParent',
                                                'comp': {
                                                    'name': 'SwitchBox',
                                                    'cases': {
                                                        'default': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'ServicesView_i7nby06z2'}
                                                        }, {
                                                            'id': 'footerSpacer',
                                                            'comp': {
                                                                'name': 'VSpacer',
                                                                'size': {'$expr': '$itemSpacing'}
                                                            }
                                                        }, {
                                                            'id': 'gutterLine',
                                                            'comp': {'name': 'HorizontalLine', 'hidden': 'true'},
                                                            'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                        }],
                                                        'last': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'ServicesView_i7nby06z2'}
                                                        }, {
                                                            'comp': {
                                                                'name': 'VBox',
                                                                'hidden': 'true',
                                                                'items': [{
                                                                    'id': 'footerSpacer',
                                                                    'comp': {
                                                                        'name': 'VSpacer',
                                                                        'size': {'$expr': '$itemSpacing'}
                                                                    }
                                                                }, {
                                                                    'id': 'gutterLine',
                                                                    'comp': {
                                                                        'name': 'HorizontalLine',
                                                                        'hidden': 'true'
                                                                    },
                                                                    'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                                }]
                                                            }, 'id': 'def_7'
                                                        }, {
                                                            'comp': {'name': 'VSpacer', 'size': '9'},
                                                            'id': 'def_10'
                                                        }, {
                                                            'id': 'paginationNavigationBox',
                                                            'comp': {
                                                                'name': 'HBox',
                                                                'hidden': {'$expr': '$hidePagination'},
                                                                'box-align': 'center',
                                                                'items': [{
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_12'
                                                                }, {
                                                                    'value': '◄',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationPrev',
                                                                        'events': {'dom:click': 'prevPageClicked'}
                                                                    },
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_13'
                                                                }, {
                                                                    'value': {'$expr': 'String.concat($currentPage,\' / \',$maxPage)'},
                                                                    'comp': {'name': 'Label'},
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_14'
                                                                }, {
                                                                    'value': '►',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationNext',
                                                                        'events': {'dom:click': 'nextPageClicked'}
                                                                    },
                                                                    'id': 'def_15'
                                                                }, {
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_16'
                                                                }]
                                                            },
                                                            'layout': {'position': 'relative'}
                                                        }]
                                                    },
                                                    'css': {'min-width': '100%'}
                                                },
                                                'id': '$positionInParent'
                                            }
                                        }
                                    }
                                }, {
                                    'id': 'noItemsLabel',
                                    'value': 'There are no items in this list',
                                    'comp': {'name': 'Label', 'hidden': {'$expr': 'gt(String.length(this), 0)'}}
                                }],
                                'editorData': {'isPaginated': true}
                            },
                            'stylesheet': [{
                                '.paginationPrev, .paginationNext': {
                                    'padding': '0 5px',
                                    'display': 'inline-block',
                                    'font-size': '14px',
                                    'cursor': 'pointer',
                                    '-webkit-touch-callout': 'none',
                                    '-webkit-user-select': 'none',
                                    '-khtml-user-select': 'none',
                                    '-moz-user-select': 'none',
                                    '-ms-user-select': 'none',
                                    'user-select': 'none'
                                }
                            }],
                            'customizations': [{
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.hidePagination',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Split your list across multiple pages',
                                    'defaultVal': 'true',
                                    'trueVal': 'false',
                                    'spaceAfter': '10'
                                }
                            }, {
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.itemsPerPage',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '120',
                                    'minVal': '1',
                                    'label': 'Items Per Page',
                                    'spaceAfter': '20',
                                    'hiddenwhen': {
                                        'fieldId': 'paginatedlist',
                                        'key': 'comp.hidePagination',
                                        'value': 'true'
                                    }
                                }
                            }, {
                                'priority': 100,
                                'fieldId': 'vars',
                                'key': 'itemSpacing',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '300',
                                    'minVal': '0',
                                    'label': 'Item Spacing',
                                    'spaceAfter': '20'
                                }
                            }, {
                                'priority': 95,
                                'fieldId': 'gutterLine',
                                'key': 'comp.hidden',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Show item separator',
                                    'defaultVal': 'false',
                                    'trueVal': 'false'
                                }
                            }, {
                                'priority': 90,
                                'fieldId': 'gutterLine',
                                'key': 'comp.style',
                                'input': {
                                    'name': 'changestyle',
                                    'showAs': 'link',
                                    'label': 'Change Item Separator Style',
                                    'spaceAfter': '20'
                                }
                            }],
                            'id': 'def_0',
                            'format': 'Mobile'
                        }, {
                            'name': 'ServicesView_i7nby06z2',
                            'forType': 'Array',
                            'vars': {'itemSpacing': 40, 'paginationColor': 'color_15'},
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'paginatedlist',
                                    'comp': {
                                        'name': 'PaginatedList',
                                        'hidden': {'$expr': 'eq(Array.length(this), 0)'},
                                        'hidePagination': 'true',
                                        'itemsPerPage': '10',
                                        'templates': {
                                            'item': {
                                                'data': '$positionInParent',
                                                'comp': {
                                                    'name': 'SwitchBox',
                                                    'cases': {
                                                        'default': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'ServicesView_i7nby06z2'}
                                                        }, {
                                                            'id': 'footerSpacer',
                                                            'comp': {
                                                                'name': 'VSpacer',
                                                                'size': {'$expr': '$itemSpacing'}
                                                            }
                                                        }, {
                                                            'id': 'gutterLine',
                                                            'comp': {'name': 'HorizontalLine', 'hidden': 'true'},
                                                            'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                        }],
                                                        'last': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'ServicesView_i7nby06z2'}
                                                        }, {
                                                            'comp': {
                                                                'name': 'VBox',
                                                                'hidden': 'true',
                                                                'items': [{
                                                                    'id': 'footerSpacer',
                                                                    'comp': {
                                                                        'name': 'VSpacer',
                                                                        'size': {'$expr': '$itemSpacing'}
                                                                    }
                                                                }, {
                                                                    'id': 'gutterLine',
                                                                    'comp': {
                                                                        'name': 'HorizontalLine',
                                                                        'hidden': 'true'
                                                                    },
                                                                    'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                                }]
                                                            }, 'id': 'def_7'
                                                        }, {
                                                            'comp': {'name': 'VSpacer', 'size': '9'},
                                                            'id': 'def_10'
                                                        }, {
                                                            'id': 'paginationNavigationBox',
                                                            'comp': {
                                                                'name': 'HBox',
                                                                'hidden': {'$expr': '$hidePagination'},
                                                                'box-align': 'center',
                                                                'items': [{
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_12'
                                                                }, {
                                                                    'value': '◄',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationPrev',
                                                                        'events': {'dom:click': 'prevPageClicked'}
                                                                    },
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_13'
                                                                }, {
                                                                    'value': {'$expr': 'String.concat($currentPage,\' / \',$maxPage)'},
                                                                    'comp': {'name': 'Label'},
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_14'
                                                                }, {
                                                                    'value': '►',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationNext',
                                                                        'events': {'dom:click': 'nextPageClicked'}
                                                                    },
                                                                    'id': 'def_15'
                                                                }, {
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_16'
                                                                }]
                                                            },
                                                            'layout': {'position': 'relative'}
                                                        }]
                                                    },
                                                    'css': {'min-width': '100%'}
                                                },
                                                'id': '$positionInParent'
                                            }
                                        }
                                    }
                                }, {
                                    'id': 'noItemsLabel',
                                    'value': 'There are no items in this list',
                                    'comp': {'name': 'Label', 'hidden': {'$expr': 'gt(String.length(this), 0)'}}
                                }],
                                'editorData': {'isPaginated': true}
                            },
                            'stylesheet': [{
                                '.paginationPrev, .paginationNext': {
                                    'padding': '0 5px',
                                    'display': 'inline-block',
                                    'font-size': '14px',
                                    'cursor': 'pointer',
                                    '-webkit-touch-callout': 'none',
                                    '-webkit-user-select': 'none',
                                    '-khtml-user-select': 'none',
                                    '-moz-user-select': 'none',
                                    '-ms-user-select': 'none',
                                    'user-select': 'none'
                                }
                            }],
                            'customizations': [{
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.hidePagination',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Split your list across multiple pages',
                                    'defaultVal': 'true',
                                    'trueVal': 'false',
                                    'spaceAfter': '10'
                                }
                            }, {
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.itemsPerPage',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '120',
                                    'minVal': '1',
                                    'label': 'Items Per Page',
                                    'spaceAfter': '20',
                                    'hiddenwhen': {
                                        'fieldId': 'paginatedlist',
                                        'key': 'comp.hidePagination',
                                        'value': 'true'
                                    }
                                }
                            }, {
                                'priority': 100,
                                'fieldId': 'vars',
                                'key': 'itemSpacing',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '300',
                                    'minVal': '0',
                                    'label': 'Item Spacing',
                                    'spaceAfter': '20'
                                }
                            }, {
                                'priority': 95,
                                'fieldId': 'gutterLine',
                                'key': 'comp.hidden',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Show item separator',
                                    'defaultVal': 'false',
                                    'trueVal': 'false'
                                }
                            }, {
                                'priority': 90,
                                'fieldId': 'gutterLine',
                                'key': 'comp.style',
                                'input': {
                                    'name': 'changestyle',
                                    'showAs': 'link',
                                    'label': 'Change Item Separator Style',
                                    'spaceAfter': '20'
                                }
                            }],
                            'id': 'def_0'
                        }, {
                            'name': 'ServicesView_i7nby06z2',
                            'forType': 'Services_i7nby06y1',
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'Top',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Top section',
                                            'alignment': 'center',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 0}
                                        },
                                        'items': [{
                                            'id': 'image_1',
                                            'data': 'image',
                                            'comp': {
                                                'name': 'Field',
                                                'width': 280,
                                                'items': [{
                                                    'id': 'image_1_proxy',
                                                    'data': 'image',
                                                    'comp': {
                                                        'name': 'Image',
                                                        'style': 'wp2',
                                                        'imageMode': 'fitWidth'
                                                    }
                                                }],
                                                'hidden': false,
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 0,
                                                    'after': 8,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'height': 180,
                                                'box-align': 'center',
                                                'heightMode': 'auto'
                                            }
                                        }, {
                                            'id': 'fld_i9b9oh7624',
                                            'data': 'i7nckuvb',
                                            'comp': {
                                                'name': 'Field',
                                                'width': 280,
                                                'height': '220',
                                                'items': [{
                                                    'id': 'fld_i9b9oh7624_proxy',
                                                    'data': 'i7nckuvb',
                                                    'comp': {'name': 'Video'},
                                                    'layout': {'position': 'relative'}
                                                }],
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 0,
                                                    'after': 8,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'box-align': 'center',
                                                'hidden': true
                                            }
                                        }, {
                                            'id': 'text_1',
                                            'data': 'title',
                                            'comp': {
                                                'name': 'TextField',
                                                'width': 180,
                                                'width-mode': 'auto',
                                                'min-lines': 0,
                                                'max-lines': 0,
                                                'items': [{
                                                    'id': 'text_1_proxy',
                                                    'data': 'title',
                                                    'comp': {
                                                        'name': 'Label',
                                                        'color': 'color_14',
                                                        'style': 'Body L',
                                                        'bold': false,
                                                        'underline': false,
                                                        'italic': false
                                                    },
                                                    'layout': {'text-align': 'left'}
                                                }],
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 0,
                                                    'after': 3,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'hidden': false,
                                                'height-mode': 'auto'
                                            }
                                        }, {
                                            'experiment': 'NewListFields',
                                            'id': 'fld_i9b9okoh41',
                                            'data': 'i942twrg',
                                            'comp': {
                                                'name': 'TextField',
                                                'width': 150,
                                                'width-mode': 'auto',
                                                'min-lines': '0',
                                                'max-lines': '0',
                                                'items': [{
                                                    'id': 'fld_i9b9okoh41_proxy',
                                                    'data': 'i942twrg',
                                                    'comp': {
                                                        'name': 'Label',
                                                        'color': 'color_14',
                                                        'style': 'Heading S'
                                                    },
                                                    'layout': {'text-align': 'left'}
                                                }],
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 0,
                                                    'after': 3,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'hidden': false
                                            }
                                        }, {
                                            'experiment': 'NewListFields',
                                            'id': 'fld_i9b9ojk932',
                                            'data': 'i942w801',
                                            'comp': {
                                                'name': 'TextField',
                                                'width': '200',
                                                'width-mode': 'auto',
                                                'min-lines': '0',
                                                'max-lines': '0',
                                                'items': [{
                                                    'id': 'fld_i9b9ojk932_proxy',
                                                    'data': 'i942w801',
                                                    'comp': {
                                                        'name': 'Date',
                                                        'format': 'mmmm dd, yyyy',
                                                        'color': 'color_14'
                                                    },
                                                    'layout': {'text-align': 'left'}
                                                }],
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 0,
                                                    'after': 3,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'hidden': true
                                            }
                                        }]
                                    },
                                    'layout': {'min-width': 0}
                                }, {
                                    'id': 'topSpacer',
                                    'comp': {'name': 'VSpacer', 'size': 0}
                                }, {
                                    'comp': {
                                        'name': 'HBox',
                                        'items': [{
                                            'id': 'Left',
                                            'comp': {
                                                'name': 'FieldBox',
                                                'orientation': 'vertical',
                                                'items': [],
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'alignment': 'left',
                                                    'displayName': 'Left section',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 0,
                                                        'xax-after': 10
                                                    }
                                                }
                                            },
                                            'layout': {'spacerAfter': 0, 'min-width': 0}
                                        }, {
                                            'id': 'leftToCenterSpacer',
                                            'comp': {'name': 'HSpacer', 'size': 0}
                                        }, {
                                            'id': 'Center',
                                            'comp': {
                                                'name': 'VBox',
                                                'orientation': 'vertical',
                                                'pack': 'center',
                                                'items': [{
                                                    'id': 'Center_0',
                                                    'comp': {
                                                        'name': 'FieldBox',
                                                        'items': [],
                                                        'orientation': 'horizontal',
                                                        'editorData': {
                                                            'labelPosition': 'none',
                                                            'displayName': 'Center_0',
                                                            'alignment': 'left',
                                                            'spacers': {
                                                                'before': 0,
                                                                'after': 3,
                                                                'xax-before': 3,
                                                                'xax-after': 0
                                                            }
                                                        },
                                                        'pack': 'start'
                                                    },
                                                    'layout': {'min-width': 0}
                                                }, {
                                                    'id': 'Center_1',
                                                    'comp': {
                                                        'name': 'FieldBox',
                                                        'orientation': 'horizontal',
                                                        'editorData': {
                                                            'labelPosition': 'none',
                                                            'displayName': 'Center_1',
                                                            'alignment': 'left',
                                                            'spacers': {
                                                                'before': 0,
                                                                'after': 3,
                                                                'xax-before': 3,
                                                                'xax-after': 0
                                                            }
                                                        },
                                                        'items': [],
                                                        'pack': 'center'
                                                    },
                                                    'layout': {'min-width': 0}
                                                }],
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'alignment': 'center',
                                                    'displayName': 'Center section',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 25,
                                                        'xax-after': 0
                                                    }
                                                }
                                            },
                                            'layout': {'box-flex': 1, 'min-width': 200}
                                        }, {
                                            'id': 'centerToRightSpacer',
                                            'comp': {'name': 'HSpacer', 'size': 0}
                                        }, {
                                            'id': 'Right',
                                            'comp': {
                                                'name': 'FieldBox',
                                                'orientation': 'vertical',
                                                'pack': 'center',
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'displayName': 'Right section',
                                                    'alignment': 'left',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 3,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'items': []
                                            },
                                            'layout': {'min-width': 0}
                                        }]
                                    }, 'id': 'def_3'
                                }, {
                                    'id': 'Bottom',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Bottom section',
                                            'alignment': 'center',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 0}
                                        },
                                        'items': [{
                                            'id': 'fld_i7nc9s5082',
                                            'data': 'i7nc9s23',
                                            'comp': {
                                                'name': 'TextField',
                                                'width': 214,
                                                'items': [{
                                                    'id': 'fld_i7nc9s5082_proxy',
                                                    'data': 'i7nc9s23',
                                                    'comp': {
                                                        'name': 'Label',
                                                        'style': 'Body M',
                                                        'color': 'color_14'
                                                    },
                                                    'layout': {'text-align': 'left'}
                                                }],
                                                'hidden': true,
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 0,
                                                    'after': 0,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'width-mode': 'auto',
                                                'min-lines': '0',
                                                'max-lines': '0'
                                            }
                                        }, {
                                            'id': 'fld_hfxsv1z6',
                                            'data': 'wxRchTxt_sTxt4',
                                            'comp': {
                                                'name': 'TextField',
                                                'width': 300,
                                                'items': [{
                                                    'id': 'fld_hfxsv1z6_proxy',
                                                    'data': 'wxRchTxt_sTxt4',
                                                    'comp': {'name': 'Label', 'color': 'color_14'},
                                                    'layout': {'text-align': 'left'}
                                                }],
                                                'hidden': false,
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 8,
                                                    'after': 3,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'width-mode': 'auto',
                                                'min-lines': '0',
                                                'max-lines': '0'
                                            }
                                        }, {
                                            'id': 'fld_hhqcd82d63',
                                            'data': 'Strng_sBttn0-78w',
                                            'comp': {
                                                'name': 'Field',
                                                'width': 280,
                                                'items': [{
                                                    'id': 'fld_hhqcd82d63_proxy',
                                                    'data': 'Strng_sBttn0-78w',
                                                    'comp': {'name': 'Button2', 'style': 'b1'},
                                                    'layout': {'position': 'relative'}
                                                }],
                                                'hidden': false,
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 24,
                                                    'after': 0,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'height': 44,
                                                'box-align': 'center'
                                            }
                                        }]
                                    },
                                    'layout': {'box-flex-pack': 'start', 'min-width': 0}
                                }, {'id': 'bottomSpacer', 'comp': {'name': 'VSpacer', 'size': 0}}]
                            },
                            'editorData': {'wasChanged': true, 'originalWidth': 275},
                            'id': 'def_0',
                            'format': 'Mobile'
                        }, {
                            'name': 'ServicesView_i7nby06z2',
                            'forType': 'Services_i7nby06y1',
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'Top',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Top section',
                                            'alignment': 'center',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 3}
                                        },
                                        'items': [{
                                            'id': 'image_1',
                                            'data': 'image',
                                            'comp': {
                                                'name': 'Field',
                                                'width': 223,
                                                'items': [{
                                                    'id': 'image_1_proxy',
                                                    'data': 'image',
                                                    'comp': {
                                                        'name': 'Image',
                                                        'style': 'wp2',
                                                        'imageMode': 'fitWidth'
                                                    }
                                                }],
                                                'hidden': false,
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 0,
                                                    'after': 0,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'height': 223,
                                                'box-align': 'center',
                                                'heightMode': 'auto'
                                            }
                                        }, {
                                            'id': 'fld_i7nckuw7116',
                                            'data': 'i7nckuvb',
                                            'comp': {
                                                'name': 'Field',
                                                'width': 330,
                                                'height': '220',
                                                'items': [{
                                                    'id': 'fld_i7nckuw7116_proxy',
                                                    'data': 'i7nckuvb',
                                                    'comp': {'name': 'Video'},
                                                    'layout': {'position': 'relative'}
                                                }],
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 16,
                                                    'after': 3,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'box-align': 'center',
                                                'hidden': true
                                            }
                                        }, {
                                            'experiment': 'NewListFields',
                                            'id': 'fld_i942w81u123',
                                            'data': 'i942w801',
                                            'comp': {
                                                'name': 'TextField',
                                                'width': '200',
                                                'width-mode': 'auto',
                                                'min-lines': '0',
                                                'max-lines': '0',
                                                'items': [{
                                                    'id': 'fld_i942w81u123_proxy',
                                                    'data': 'i942w801',
                                                    'comp': {
                                                        'name': 'Date',
                                                        'format': 'mmmm dd, yyyy',
                                                        'style': 'Body M',
                                                        'color': 'color_14'
                                                    },
                                                    'layout': {'text-align': 'center'}
                                                }],
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 0,
                                                    'after': 0,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'hidden': true
                                            }
                                        }]
                                    },
                                    'layout': {'min-width': 0}
                                }, {
                                    'id': 'topSpacer',
                                    'comp': {'name': 'VSpacer', 'size': '0'}
                                }, {
                                    'comp': {
                                        'name': 'HBox',
                                        'items': [{
                                            'id': 'Left',
                                            'comp': {
                                                'name': 'FieldBox',
                                                'orientation': 'vertical',
                                                'items': [],
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'alignment': 'left',
                                                    'displayName': 'Left section',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 0,
                                                        'xax-after': 10
                                                    }
                                                }
                                            },
                                            'layout': {'spacerAfter': 0, 'min-width': 0}
                                        }, {
                                            'id': 'leftToCenterSpacer',
                                            'comp': {'name': 'HSpacer', 'size': '0'}
                                        }, {
                                            'id': 'Center',
                                            'comp': {
                                                'name': 'FieldBox',
                                                'orientation': 'horizontal',
                                                'pack': 'center',
                                                'items': [{
                                                    'id': 'text_1',
                                                    'data': 'title',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': 700,
                                                        'items': [{
                                                            'id': 'text_1_proxy',
                                                            'data': 'title',
                                                            'comp': {
                                                                'style': 'Heading S',
                                                                'name': 'Label',
                                                                'bold': false,
                                                                'underline': false,
                                                                'italic': false,
                                                                'color': 'color_14'
                                                            },
                                                            'layout': {'text-align': 'center'}
                                                        }],
                                                        'hidden': false,
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 25,
                                                            'xax-before': 10,
                                                            'xax-after': 0
                                                        },
                                                        'width-mode': 'auto',
                                                        'min-lines': 0,
                                                        'max-lines': 0,
                                                        'height-mode': 'auto',
                                                        'box-align': 'end'
                                                    }
                                                }, {
                                                    'id': 'fld_i942twsf92',
                                                    'data': 'i942twrg',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'items': [{
                                                            'id': 'fld_i942twsf92_proxy',
                                                            'data': 'i942twrg',
                                                            'comp': {
                                                                'name': 'Label',
                                                                'style': 'Heading S',
                                                                'color': 'color_14'
                                                            },
                                                            'layout': {'text-align': 'right'}
                                                        }],
                                                        'hidden': false,
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 3,
                                                            'xax-before': 10,
                                                            'xax-after': 0
                                                        },
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0'
                                                    },
                                                    'experiment': 'NewListFields'
                                                }],
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'alignment': 'center',
                                                    'displayName': 'Center section',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 25,
                                                        'xax-after': 0
                                                    }
                                                }
                                            },
                                            'layout': {'box-flex': 1, 'min-width': 200}
                                        }, {
                                            'id': 'centerToRightSpacer',
                                            'comp': {'name': 'HSpacer', 'size': '0'}
                                        }, {
                                            'id': 'Right',
                                            'comp': {
                                                'name': 'FieldBox',
                                                'orientation': 'vertical',
                                                'pack': 'center',
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'displayName': 'Right section',
                                                    'alignment': 'left',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 3,
                                                        'xax-after': 0
                                                    }
                                                }
                                            },
                                            'layout': {'min-width': 0}
                                        }]
                                    }, 'id': 'def_3'
                                }, {
                                    'id': 'Bottom',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Bottom section',
                                            'alignment': 'center',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 3}
                                        },
                                        'items': [{
                                            'id': 'fld_i7nc9s5082',
                                            'data': 'i7nc9s23',
                                            'comp': {
                                                'name': 'TextField',
                                                'width': 221,
                                                'width-mode': 'auto',
                                                'min-lines': '0',
                                                'max-lines': '0',
                                                'items': [{
                                                    'id': 'fld_i7nc9s5082_proxy',
                                                    'data': 'i7nc9s23',
                                                    'comp': {
                                                        'name': 'Label',
                                                        'style': 'Body L',
                                                        'color': 'color_14'
                                                    },
                                                    'layout': {'text-align': 'center'}
                                                }],
                                                'hidden': true,
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 0,
                                                    'after': 3,
                                                    'xax-before': 0,
                                                    'xax-after': 3
                                                }
                                            }
                                        }, {
                                            'id': 'fld_hfxsv1z6',
                                            'data': 'wxRchTxt_sTxt4',
                                            'comp': {
                                                'name': 'TextField',
                                                'width': '200',
                                                'items': [{
                                                    'id': 'fld_hfxsv1z6_proxy',
                                                    'data': 'wxRchTxt_sTxt4',
                                                    'comp': {'name': 'Label'},
                                                    'layout': {'text-align': 'center'}
                                                }],
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 8,
                                                    'after': 0,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'hidden': true,
                                                'width-mode': 'auto',
                                                'min-lines': '0',
                                                'max-lines': '0'
                                            }
                                        }, {
                                            'id': 'fld_hhqcd82d63',
                                            'data': 'Strng_sBttn0-78w',
                                            'comp': {
                                                'name': 'Field',
                                                'width': 160,
                                                'height': 40,
                                                'items': [{
                                                    'id': 'fld_hhqcd82d63_proxy',
                                                    'data': 'Strng_sBttn0-78w',
                                                    'comp': {'name': 'Button2', 'style': 'b1'},
                                                    'layout': {'position': 'relative'}
                                                }],
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 24,
                                                    'after': 0,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'box-align': 'center',
                                                'hidden': false
                                            }
                                        }]
                                    },
                                    'layout': {'box-flex-pack': 'start', 'min-width': 0}
                                }, {'id': 'bottomSpacer', 'comp': {'name': 'VSpacer', 'size': '0'}}]
                            },
                            'editorData': {'wasChanged': true},
                            'id': 'def_0'
                        }],
                        'items': [{
                            'title': i18n.translate('i7nby08k.structure.custom.template.items.0.title'),
                            'image': {
                                '_type': 'wix:Image',
                                'title': '',
                                'src': '8d13be_db9cace8eb634836a8973e67ae4f0304.jpg',
                                'width': 1000,
                                'height': 1000,
                                'alt': ''
                            },
                            'wxRchTxt_sTxt4': {
                                'version': 1,
                                '_type': 'wix:RichText',
                                'links': [],
                                'text': i18n.translate('i7nby08k.structure.custom.template.items.0.wxRchTxt_sTxt4')
                            },
                            'links': {'_type': 'wix:Map'},
                            'Strng_sBttn0-78w': i18n.translate('i7nby08k.structure.custom.template.items.0.Strng_sBttn0-78w'),
                            'i7nc9s23': i18n.translate('i7nby08k.structure.custom.template.items.0.i7nc9s23'),
                            'i7nckuvb': {
                                '_type': 'wix:Video',
                                'videoId': 'z1pSAwjC4JI',
                                'videoType': 'YOUTUBE',
                                'thumbnail': ''
                            },
                            'i942twrg': i18n.translate('i7nby08k.structure.custom.template.items.0.i942twrg'),
                            'i942w801': {'_type': 'wix:Date', 'iso': '2023-01-03T00:00:00.000Z'}
                        }, {
                            'title': i18n.translate('i7nby08k.structure.custom.template.items.1.title'),
                            'image': {
                                '_type': 'wix:Image',
                                'title': '',
                                'src': '8d13be_4574176bf37e40859c77e7986e78b48e.jpg',
                                'width': 1000,
                                'height': 1000,
                                'alt': ''
                            },
                            'wxRchTxt_sTxt4': {
                                'version': 1,
                                '_type': 'wix:RichText',
                                'links': [],
                                'text': i18n.translate('i7nby08k.structure.custom.template.items.1.wxRchTxt_sTxt4')
                            },
                            'links': {'_type': 'wix:Map'},
                            'Strng_sBttn0-78w': i18n.translate('i7nby08k.structure.custom.template.items.1.Strng_sBttn0-78w'),
                            'i7nc9s23': i18n.translate('i7nby08k.structure.custom.template.items.1.i7nc9s23'),
                            'i7nckuvb': {
                                '_type': 'wix:Video',
                                'videoId': 'f36EEfI0yr8',
                                'videoType': 'YOUTUBE',
                                'thumbnail': ''
                            },
                            'i942twrg': i18n.translate('i7nby08k.structure.custom.template.items.1.i942twrg'),
                            'i942w801': {'_type': 'wix:Date', 'iso': '2023-02-23T00:00:00.000Z'}
                        }, {
                            'title': i18n.translate('i7nby08k.structure.custom.template.items.2.title'),
                            'image': {
                                '_type': 'wix:Image',
                                'title': '',
                                'src': '8d13be_f22f1f7181c941d7807760f2f57c896a.jpg',
                                'width': 1000,
                                'height': 1000,
                                'alt': ''
                            },
                            'wxRchTxt_sTxt4': {
                                'version': 1,
                                '_type': 'wix:RichText',
                                'links': [],
                                'text': i18n.translate('i7nby08k.structure.custom.template.items.2.wxRchTxt_sTxt4')
                            },
                            'links': {'_type': 'wix:Map'},
                            'Strng_sBttn0-78w': i18n.translate('i7nby08k.structure.custom.template.items.2.Strng_sBttn0-78w'),
                            'i7nc9s23': i18n.translate('i7nby08k.structure.custom.template.items.2.i7nc9s23'),
                            'i7nckuvb': {
                                '_type': 'wix:Video',
                                'videoId': 'wGOTBSlYkzU',
                                'videoType': 'YOUTUBE',
                                'thumbnail': ''
                            },
                            'i942twrg': i18n.translate('i7nby08k.structure.custom.template.items.2.i942twrg'),
                            'i942w801': {'_type': 'wix:Date', 'iso': '2023-05-28T00:00:00.000Z'}
                        }],
                        'displayName': i18n.translate('i7nby08k.structure.custom.template.displayName')
                    }
                },
                'id': 'i7nby08k'
            },
            'preset': {
                'rect': {'width': 162, 'height': 290, 'x': 0, 'y': 733.25},
                'label': 'i7nby08k.structure.custom.template.displayName',
                'tags': null
            }
        }, {
            'id': 'Lists_List_Products_And_Services_8',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.viewer.skins.AppPartSkin',
                'layout': {
                    'width': 330,
                    'height': 1348,
                    'x': 822,
                    'y': 3068,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wixapps.integration.components.AppPart2',
                'data': {
                    'type': 'AppBuilderComponent',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'appInnerID': '2',
                    'appPartName': 'appparti7g43bbu9'
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'id': 'app1',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {'groups': {}, 'properties': {'rd': '7px'}, 'propertiesSource': {'rd': 'value'}},
                    'componentClassName': '',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'system',
                    'skin': 'wysiwyg.viewer.skins.AppPartSkin'
                },
                'custom': {
                    'template': {
                        'type': {
                            'fields': [{
                                'displayName': i18n.translate('i7g43bc0.structure.custom.template.type.fields.0.displayName'),
                                'name': 'title',
                                'searchable': false,
                                'type': 'String',
                                'defaultValue': i18n.translate('i7g43bc0.structure.custom.template.type.fields.0.defaultValue'),
                                'computed': false
                            }, {
                                'searchable': false,
                                'displayName': i18n.translate('i7g43bc0.structure.custom.template.type.fields.1.displayName'),
                                'type': 'String',
                                'name': 'Strng_sTxt0',
                                'computed': false,
                                'defaultValue': i18n.translate('i7g43bc0.structure.custom.template.type.fields.1.defaultValue')
                            }, {
                                'type': 'wix:Date',
                                'searchable': false,
                                'displayName': i18n.translate('i7g43bc0.structure.custom.template.type.fields.2.displayName'),
                                'name': 'i943bmnv',
                                'computed': false,
                                'defaultValue': {'iso': '2020-01-01T00:00:00.000Z', '_type': 'wix:Date'}
                            }, {
                                'displayName': i18n.translate('i7g43bc0.structure.custom.template.type.fields.3.displayName'),
                                'name': 'wxRchTxt_sTxt4',
                                'defaultValue': {
                                    'version': 1,
                                    '_type': 'wix:RichText',
                                    'links': [],
                                    'text': i18n.translate('i7g43bc0.structure.custom.template.type.fields.3.defaultValue')
                                },
                                'type': 'wix:RichText',
                                'searchable': false,
                                'computed': false
                            }, {
                                'name': 'i9438kb2',
                                'metadata': {'showAsHint': 'AsPrice'},
                                'displayName': i18n.translate('i7g43bc0.structure.custom.template.type.fields.4.displayName'),
                                'searchable': false,
                                'type': 'String',
                                'defaultValue': i18n.translate('i7g43bc0.structure.custom.template.type.fields.4.defaultValue'),
                                'computed': false
                            }, {
                                'type': 'wix:Image',
                                'defaultValue': {
                                    'height': 1276,
                                    'src': 'c8381ce0e7a94ad09ceaada2fc64081d.jpg',
                                    'width': 1920,
                                    '_type': 'wix:Image',
                                    'title': ''
                                },
                                'displayName': i18n.translate('i7g43bc0.structure.custom.template.type.fields.5.displayName'),
                                'searchable': false,
                                'name': 'image',
                                'computed': false
                            }, {
                                'defaultValue': {
                                    'videoId': 'f36EEfI0yr8',
                                    '_type': 'wix:Video',
                                    'videoType': 'YOUTUBE'
                                },
                                'type': 'wix:Video',
                                'searchable': false,
                                'name': 'i6lykv5b',
                                'displayName': i18n.translate('i7g43bc0.structure.custom.template.type.fields.6.displayName'),
                                'computed': false
                            }, {
                                'defaultValue': i18n.translate('i7g43bc0.structure.custom.template.type.fields.7.defaultValue'),
                                'searchable': false,
                                'metadata': {'showAsHint': 'AsButton'},
                                'type': 'String',
                                'displayName': i18n.translate('i7g43bc0.structure.custom.template.type.fields.7.displayName'),
                                'name': 'Strng_sBttn0-78w',
                                'computed': false
                            }, {
                                'name': 'links',
                                'type': 'wix:Map',
                                'searchable': false,
                                'displayName': '',
                                'defaultValue': {'_type': 'wix:Map'},
                                'computed': false
                            }],
                            'version': 0,
                            'baseTypes': [],
                            'name': 'ProductList_i6f9ythw558',
                            'displayName': i18n.translate('i7g43bc0.structure.custom.template.type.displayName')
                        },
                        'views': [{
                            'name': 'ProductListView_i6f9ythx559_dup_i6ly2b6518_dup_i6qm3p6y330_dup_i6uhpph11_dup_i70h8xw2193_dup_i7g43ba3147',
                            'forType': 'Array',
                            'vars': {'itemSpacing': 50, 'paginationColor': 'color_15'},
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'paginatedlist',
                                    'comp': {
                                        'name': 'PaginatedList',
                                        'hidden': {'$expr': 'eq(Array.length(this), 0)'},
                                        'hidePagination': 'true',
                                        'itemsPerPage': '10',
                                        'templates': {
                                            'item': {
                                                'data': '$positionInParent',
                                                'comp': {
                                                    'name': 'SwitchBox',
                                                    'cases': {
                                                        'default': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'ProductListView_i6f9ythx559_dup_i6ly2b6518_dup_i6qm3p6y330_dup_i6uhpph11_dup_i70h8xw2193_dup_i7g43ba3147'}
                                                        }, {
                                                            'id': 'footerSpacer',
                                                            'comp': {
                                                                'name': 'VSpacer',
                                                                'size': {'$expr': '$itemSpacing'}
                                                            }
                                                        }, {
                                                            'id': 'gutterLine',
                                                            'comp': {'name': 'HorizontalLine', 'hidden': 'true'},
                                                            'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                        }],
                                                        'last': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'ProductListView_i6f9ythx559_dup_i6ly2b6518_dup_i6qm3p6y330_dup_i6uhpph11_dup_i70h8xw2193_dup_i7g43ba3147'}
                                                        }, {
                                                            'comp': {
                                                                'name': 'VBox',
                                                                'hidden': 'true',
                                                                'items': [{
                                                                    'id': 'footerSpacer',
                                                                    'comp': {
                                                                        'name': 'VSpacer',
                                                                        'size': {'$expr': '$itemSpacing'}
                                                                    }
                                                                }, {
                                                                    'id': 'gutterLine',
                                                                    'comp': {
                                                                        'name': 'HorizontalLine',
                                                                        'hidden': 'true'
                                                                    },
                                                                    'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                                }]
                                                            }, 'id': 'def_7'
                                                        }, {
                                                            'comp': {'name': 'VSpacer', 'size': '9'},
                                                            'id': 'def_10'
                                                        }, {
                                                            'id': 'paginationNavigationBox',
                                                            'comp': {
                                                                'name': 'HBox',
                                                                'hidden': {'$expr': '$hidePagination'},
                                                                'box-align': 'center',
                                                                'items': [{
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_12'
                                                                }, {
                                                                    'value': '◄',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationPrev',
                                                                        'events': {'dom:click': 'prevPageClicked'}
                                                                    },
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_13'
                                                                }, {
                                                                    'value': {'$expr': 'String.concat($currentPage,\' / \',$maxPage)'},
                                                                    'comp': {'name': 'Label'},
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_14'
                                                                }, {
                                                                    'value': '►',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationNext',
                                                                        'events': {'dom:click': 'nextPageClicked'}
                                                                    },
                                                                    'id': 'def_15'
                                                                }, {
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_16'
                                                                }]
                                                            },
                                                            'layout': {'position': 'relative'}
                                                        }]
                                                    },
                                                    'css': {'min-width': '100%'}
                                                },
                                                'id': '$positionInParent'
                                            }
                                        }
                                    }
                                }, {
                                    'id': 'noItemsLabel',
                                    'value': 'There are no items in this list',
                                    'comp': {'name': 'Label', 'hidden': {'$expr': 'gt(String.length(this), 0)'}}
                                }],
                                'editorData': {'isPaginated': true}
                            },
                            'stylesheet': [{
                                '.paginationPrev, .paginationNext': {
                                    'padding': '0 5px',
                                    'display': 'inline-block',
                                    'font-size': '14px',
                                    'cursor': 'pointer',
                                    '-webkit-touch-callout': 'none',
                                    '-webkit-user-select': 'none',
                                    '-khtml-user-select': 'none',
                                    '-moz-user-select': 'none',
                                    '-ms-user-select': 'none',
                                    'user-select': 'none'
                                }
                            }],
                            'customizations': [{
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.hidePagination',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Split your list across multiple pages',
                                    'defaultVal': 'true',
                                    'trueVal': 'false',
                                    'spaceAfter': '10'
                                }
                            }, {
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.itemsPerPage',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '120',
                                    'minVal': '1',
                                    'label': 'Items Per Page',
                                    'spaceAfter': '20',
                                    'hiddenwhen': {
                                        'fieldId': 'paginatedlist',
                                        'key': 'comp.hidePagination',
                                        'value': 'true'
                                    }
                                }
                            }, {
                                'priority': 100,
                                'fieldId': 'vars',
                                'key': 'itemSpacing',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '300',
                                    'minVal': '0',
                                    'label': 'Item Spacing',
                                    'spaceAfter': '20'
                                }
                            }, {
                                'priority': 95,
                                'fieldId': 'gutterLine',
                                'key': 'comp.hidden',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Show item separator',
                                    'defaultVal': 'false',
                                    'trueVal': 'false'
                                }
                            }, {
                                'priority': 90,
                                'fieldId': 'gutterLine',
                                'key': 'comp.style',
                                'input': {
                                    'name': 'changestyle',
                                    'showAs': 'link',
                                    'label': 'Change Item Separator Style',
                                    'spaceAfter': '20'
                                }
                            }],
                            'id': 'i7g43ba4',
                            'format': 'Mobile'
                        }, {
                            'name': 'ProductListView_i6f9ythx559_dup_i6ly2b6518_dup_i6qm3p6y330_dup_i6uhpph11_dup_i70h8xw2193_dup_i7g43ba3147',
                            'forType': 'Array',
                            'vars': {'itemSpacing': 50, 'paginationColor': 'color_15'},
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'paginatedlist',
                                    'comp': {
                                        'name': 'PaginatedList',
                                        'hidden': {'$expr': 'eq(Array.length(this), 0)'},
                                        'hidePagination': 'true',
                                        'itemsPerPage': '10',
                                        'templates': {
                                            'item': {
                                                'data': '$positionInParent',
                                                'comp': {
                                                    'name': 'SwitchBox',
                                                    'cases': {
                                                        'default': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'ProductListView_i6f9ythx559_dup_i6ly2b6518_dup_i6qm3p6y330_dup_i6uhpph11_dup_i70h8xw2193_dup_i7g43ba3147'}
                                                        }, {
                                                            'id': 'footerSpacer',
                                                            'comp': {
                                                                'name': 'VSpacer',
                                                                'size': {'$expr': '$itemSpacing'}
                                                            }
                                                        }, {
                                                            'id': 'gutterLine',
                                                            'comp': {'name': 'HorizontalLine', 'hidden': 'true'},
                                                            'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                        }],
                                                        'last': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'ProductListView_i6f9ythx559_dup_i6ly2b6518_dup_i6qm3p6y330_dup_i6uhpph11_dup_i70h8xw2193_dup_i7g43ba3147'}
                                                        }, {
                                                            'comp': {
                                                                'name': 'VBox',
                                                                'hidden': 'true',
                                                                'items': [{
                                                                    'id': 'footerSpacer',
                                                                    'comp': {
                                                                        'name': 'VSpacer',
                                                                        'size': {'$expr': '$itemSpacing'}
                                                                    }
                                                                }, {
                                                                    'id': 'gutterLine',
                                                                    'comp': {
                                                                        'name': 'HorizontalLine',
                                                                        'hidden': 'true'
                                                                    },
                                                                    'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                                }]
                                                            }, 'id': 'def_7'
                                                        }, {
                                                            'comp': {'name': 'VSpacer', 'size': '9'},
                                                            'id': 'def_10'
                                                        }, {
                                                            'id': 'paginationNavigationBox',
                                                            'comp': {
                                                                'name': 'HBox',
                                                                'hidden': {'$expr': '$hidePagination'},
                                                                'box-align': 'center',
                                                                'items': [{
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_12'
                                                                }, {
                                                                    'value': '◄',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationPrev',
                                                                        'events': {'dom:click': 'prevPageClicked'}
                                                                    },
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_13'
                                                                }, {
                                                                    'value': {'$expr': 'String.concat($currentPage,\' / \',$maxPage)'},
                                                                    'comp': {'name': 'Label'},
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_14'
                                                                }, {
                                                                    'value': '►',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationNext',
                                                                        'events': {'dom:click': 'nextPageClicked'}
                                                                    },
                                                                    'id': 'def_15'
                                                                }, {
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_16'
                                                                }]
                                                            },
                                                            'layout': {'position': 'relative'}
                                                        }]
                                                    },
                                                    'css': {'min-width': '100%'}
                                                },
                                                'id': '$positionInParent'
                                            }
                                        }
                                    }
                                }, {
                                    'id': 'noItemsLabel',
                                    'value': 'There are no items in this list',
                                    'comp': {'name': 'Label', 'hidden': {'$expr': 'gt(String.length(this), 0)'}}
                                }],
                                'editorData': {'isPaginated': true}
                            },
                            'stylesheet': [{
                                '.paginationPrev, .paginationNext': {
                                    'padding': '0 5px',
                                    'display': 'inline-block',
                                    'font-size': '14px',
                                    'cursor': 'pointer',
                                    '-webkit-touch-callout': 'none',
                                    '-webkit-user-select': 'none',
                                    '-khtml-user-select': 'none',
                                    '-moz-user-select': 'none',
                                    '-ms-user-select': 'none',
                                    'user-select': 'none'
                                }
                            }],
                            'customizations': [{
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.hidePagination',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Split your list across multiple pages',
                                    'defaultVal': 'true',
                                    'trueVal': 'false',
                                    'spaceAfter': '10'
                                }
                            }, {
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.itemsPerPage',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '120',
                                    'minVal': '1',
                                    'label': 'Items Per Page',
                                    'spaceAfter': '20',
                                    'hiddenwhen': {
                                        'fieldId': 'paginatedlist',
                                        'key': 'comp.hidePagination',
                                        'value': 'true'
                                    }
                                }
                            }, {
                                'priority': 100,
                                'fieldId': 'vars',
                                'key': 'itemSpacing',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '300',
                                    'minVal': '0',
                                    'label': 'Item Spacing',
                                    'spaceAfter': '20'
                                }
                            }, {
                                'priority': 95,
                                'fieldId': 'gutterLine',
                                'key': 'comp.hidden',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Show item separator',
                                    'defaultVal': 'false',
                                    'trueVal': 'false'
                                }
                            }, {
                                'priority': 90,
                                'fieldId': 'gutterLine',
                                'key': 'comp.style',
                                'input': {
                                    'name': 'changestyle',
                                    'showAs': 'link',
                                    'label': 'Change Item Separator Style',
                                    'spaceAfter': '20'
                                }
                            }],
                            'id': 'i7g43ba4'
                        }, {
                            'name': 'ProductListView_i6f9ythx559_dup_i6ly2b6518_dup_i6qm3p6y330_dup_i6uhpph11_dup_i70h8xw2193_dup_i7g43ba3147',
                            'forType': 'ProductList_i6f9ythw558',
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'Top',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Top section',
                                            'alignment': 'center',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 3}
                                        },
                                        'items': [{
                                            'id': 'image_1',
                                            'data': 'image',
                                            'comp': {
                                                'name': 'Field',
                                                'width': 183,
                                                'items': [{
                                                    'id': 'image_1_proxy',
                                                    'data': 'image',
                                                    'comp': {'name': 'Image', 'style': 'wp2', 'imageMode': 'fill'}
                                                }],
                                                'hidden': false,
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 0,
                                                    'after': 8,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'height': 198,
                                                'box-align': 'start',
                                                'heightMode': 'manual'
                                            }
                                        }, {
                                            'id': 'fld_i71mpxy0575',
                                            'data': 'i6lykv5b',
                                            'comp': {
                                                'name': 'Field',
                                                'width': 330,
                                                'items': [{
                                                    'id': 'fld_i71mpxy0575_proxy',
                                                    'data': 'i6lykv5b',
                                                    'comp': {'name': 'Video'},
                                                    'layout': {'position': 'relative'}
                                                }],
                                                'hidden': true,
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 0,
                                                    'after': 8,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'height': '220',
                                                'box-align': 'start'
                                            }
                                        }, {
                                            'experiment': 'NewListFields',
                                            'id': 'fld_i943bmp0209',
                                            'data': 'i943bmnv',
                                            'comp': {
                                                'name': 'TextField',
                                                'width': '200',
                                                'width-mode': 'auto',
                                                'min-lines': '0',
                                                'max-lines': '0',
                                                'items': [{
                                                    'id': 'fld_i943bmp0209_proxy',
                                                    'data': 'i943bmnv',
                                                    'comp': {
                                                        'name': 'Date',
                                                        'format': 'mmm dd, yyyy',
                                                        'color': 'color_14'
                                                    },
                                                    'layout': {'text-align': 'left'}
                                                }],
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 0,
                                                    'after': 3,
                                                    'xax-before': 0,
                                                    'xax-after': 3
                                                },
                                                'hidden': true
                                            }
                                        }]
                                    },
                                    'layout': {'min-width': 0}
                                }, {
                                    'id': 'topSpacer',
                                    'comp': {'name': 'VSpacer', 'size': '0'}
                                }, {
                                    'comp': {
                                        'name': 'HBox',
                                        'items': [{
                                            'id': 'Left',
                                            'comp': {
                                                'name': 'FieldBox',
                                                'orientation': 'vertical',
                                                'items': [],
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'alignment': 'left',
                                                    'displayName': 'Left section',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 0,
                                                        'xax-after': 10
                                                    }
                                                }
                                            },
                                            'layout': {'spacerAfter': 0, 'min-width': 0}
                                        }, {
                                            'id': 'leftToCenterSpacer',
                                            'comp': {'name': 'HSpacer', 'size': '0'}
                                        }, {
                                            'id': 'Center',
                                            'comp': {
                                                'name': 'FieldBox',
                                                'orientation': 'vertical',
                                                'pack': 'center',
                                                'items': [{
                                                    'id': 'text_1',
                                                    'data': 'title',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'width-mode': 'auto',
                                                        'min-lines': 0,
                                                        'max-lines': 0,
                                                        'items': [{
                                                            'id': 'text_1_proxy',
                                                            'data': 'title',
                                                            'comp': {
                                                                'name': 'Label',
                                                                'style': 'Heading S',
                                                                'bold': false,
                                                                'italic': false,
                                                                'underline': false,
                                                                'color': 'color_14'
                                                            },
                                                            'layout': {'text-align': 'left'}
                                                        }],
                                                        'hidden': false,
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 3,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'height-mode': 'auto'
                                                    }
                                                }, {
                                                    'id': 'fld_i6uqj4gs19',
                                                    'data': 'Strng_sTxt0',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0',
                                                        'items': [{
                                                            'id': 'fld_i6uqj4gs19_proxy',
                                                            'data': 'Strng_sTxt0',
                                                            'comp': {
                                                                'name': 'Label',
                                                                'style': 'Body L',
                                                                'color': 'color_14'
                                                            },
                                                            'layout': {'text-align': 'left'}
                                                        }],
                                                        'hidden': true,
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 3,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        }
                                                    }
                                                }, {
                                                    'id': 'fld_i9438kbz182',
                                                    'data': 'i9438kb2',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0',
                                                        'items': [{
                                                            'id': 'fld_i9438kbz182_proxy',
                                                            'data': 'i9438kb2',
                                                            'comp': {
                                                                'name': 'Label',
                                                                'style': 'Body L',
                                                                'color': 'color_14'
                                                            },
                                                            'layout': {'text-align': 'left'}
                                                        }],
                                                        'hidden': false,
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 3,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        }
                                                    },
                                                    'experiment': 'NewListFields'
                                                }, {
                                                    'id': 'fld_hfxsv1z6',
                                                    'data': 'wxRchTxt_sTxt4',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0',
                                                        'items': [{
                                                            'id': 'fld_hfxsv1z6_proxy',
                                                            'data': 'wxRchTxt_sTxt4',
                                                            'comp': {'name': 'Label', 'color': 'color_14'},
                                                            'layout': {'text-align': 'left'}
                                                        }],
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 3,
                                                            'after': 3,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'hidden': false
                                                    }
                                                }],
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'alignment': 'center',
                                                    'displayName': 'Center section',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 25,
                                                        'xax-after': 0
                                                    }
                                                }
                                            },
                                            'layout': {'box-flex': 1, 'min-width': 0}
                                        }, {
                                            'id': 'centerToRightSpacer',
                                            'comp': {'name': 'HSpacer', 'size': '0'}
                                        }, {
                                            'id': 'Right',
                                            'comp': {
                                                'name': 'FieldBox',
                                                'orientation': 'vertical',
                                                'pack': 'center',
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'displayName': 'Right section',
                                                    'alignment': 'left',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 3,
                                                        'xax-after': 0
                                                    }
                                                }
                                            },
                                            'layout': {'min-width': 0}
                                        }]
                                    }, 'id': 'def_3'
                                }, {
                                    'id': 'Bottom',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Bottom section',
                                            'alignment': 'center',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 3}
                                        },
                                        'items': [{
                                            'id': 'fld_hhqcd82d63',
                                            'data': 'Strng_sBttn0-78w',
                                            'comp': {
                                                'name': 'Field',
                                                'width': 160,
                                                'items': [{
                                                    'id': 'fld_hhqcd82d63_proxy',
                                                    'data': 'Strng_sBttn0-78w',
                                                    'comp': {'name': 'Button2', 'style': 'b1', 'align': 'center'},
                                                    'layout': {'position': 'relative'}
                                                }],
                                                'hidden': false,
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 27,
                                                    'after': 0,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'height': 40,
                                                'box-align': 'start'
                                            }
                                        }]
                                    },
                                    'layout': {'box-flex-pack': 'start', 'min-width': 0}
                                }, {'id': 'bottomSpacer', 'comp': {'name': 'VSpacer', 'size': '0'}}]
                            },
                            'id': 'def_0',
                            'editorData': {'wasChanged': true}
                        }, {
                            'name': 'ProductListView_i6f9ythx559_dup_i6ly2b6518_dup_i6qm3p6y330_dup_i6uhpph11_dup_i70h8xw2193_dup_i7g43ba3147',
                            'forType': 'ProductList_i6f9ythw558',
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'Top',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Top section',
                                            'alignment': 'center',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 0}
                                        },
                                        'items': [{
                                            'id': 'image_1',
                                            'data': 'image',
                                            'comp': {
                                                'name': 'Field',
                                                'width': 280,
                                                'items': [{
                                                    'id': 'image_1_proxy',
                                                    'data': 'image',
                                                    'comp': {
                                                        'name': 'Image',
                                                        'style': 'wp2',
                                                        'imageMode': 'fitWidth'
                                                    }
                                                }],
                                                'hidden': false,
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 0,
                                                    'after': 10,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'height': 198,
                                                'box-align': 'start',
                                                'heightMode': 'auto'
                                            }
                                        }, {
                                            'id': 'fld_i71mpxy0575',
                                            'data': 'i6lykv5b',
                                            'comp': {
                                                'name': 'Field',
                                                'width': 280,
                                                'items': [{
                                                    'id': 'fld_i71mpxy0575_proxy',
                                                    'data': 'i6lykv5b',
                                                    'comp': {'name': 'Video'},
                                                    'layout': {'position': 'relative'}
                                                }],
                                                'hidden': true,
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 0,
                                                    'after': 10,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'height': '220',
                                                'box-align': 'start'
                                            }
                                        }]
                                    },
                                    'layout': {'min-width': 0}
                                }, {
                                    'id': 'topSpacer',
                                    'comp': {'name': 'VSpacer', 'size': 0}
                                }, {
                                    'comp': {
                                        'name': 'VBox',
                                        'items': [{
                                            'id': 'def_3_0',
                                            'comp': {
                                                'name': 'HBox',
                                                'orientation': 'horizontal',
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'displayName': 'def_3_0',
                                                    'alignment': 'left',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 3,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'items': [{
                                                    'id': 'Left',
                                                    'comp': {
                                                        'name': 'FieldBox',
                                                        'orientation': 'vertical',
                                                        'items': [],
                                                        'editorData': {
                                                            'labelPosition': 'none',
                                                            'alignment': 'left',
                                                            'displayName': 'Left section',
                                                            'spacers': {
                                                                'before': 0,
                                                                'after': 3,
                                                                'xax-before': 0,
                                                                'xax-after': 0
                                                            }
                                                        }
                                                    },
                                                    'layout': {'spacerAfter': 0, 'min-width': 0}
                                                }, {
                                                    'id': 'leftToCenterSpacer',
                                                    'comp': {'name': 'HSpacer', 'size': 0}
                                                }, {
                                                    'id': 'Center',
                                                    'comp': {
                                                        'name': 'FieldBox',
                                                        'orientation': 'vertical',
                                                        'pack': 'center',
                                                        'items': [{
                                                            'id': 'fld_i9bcjcth91',
                                                            'data': 'i943bmnv',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': '200',
                                                                'width-mode': 'auto',
                                                                'min-lines': '0',
                                                                'max-lines': '0',
                                                                'items': [{
                                                                    'id': 'fld_i9bcjcth91_proxy',
                                                                    'data': 'i943bmnv',
                                                                    'comp': {
                                                                        'name': 'Date',
                                                                        'format': 'mmmm dd, yyyy',
                                                                        'color': 'color_14'
                                                                    },
                                                                    'layout': {'text-align': 'left'}
                                                                }],
                                                                'hidden': true,
                                                                'labelPosition': 'none',
                                                                'spacers': {
                                                                    'before': 0,
                                                                    'after': 3,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                }
                                                            },
                                                            'experiment': 'NewListFields'
                                                        }, {
                                                            'id': 'text_1',
                                                            'data': 'title',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': 240,
                                                                'width-mode': 'auto',
                                                                'min-lines': 0,
                                                                'max-lines': 0,
                                                                'items': [{
                                                                    'id': 'text_1_proxy',
                                                                    'data': 'title',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'style': 'Heading S',
                                                                        'bold': false,
                                                                        'italic': false,
                                                                        'underline': false,
                                                                        'color': 'color_14'
                                                                    },
                                                                    'layout': {'text-align': 'left'}
                                                                }],
                                                                'hidden': false,
                                                                'labelPosition': 'none',
                                                                'spacers': {
                                                                    'before': 0,
                                                                    'after': 6,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                },
                                                                'height-mode': 'auto'
                                                            }
                                                        }, {
                                                            'id': 'fld_i6uqj4gs19',
                                                            'data': 'Strng_sTxt0',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': 240,
                                                                'width-mode': 'auto',
                                                                'min-lines': '0',
                                                                'max-lines': '0',
                                                                'items': [{
                                                                    'id': 'fld_i6uqj4gs19_proxy',
                                                                    'data': 'Strng_sTxt0',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'style': 'Body L',
                                                                        'color': 'color_14'
                                                                    },
                                                                    'layout': {'text-align': 'left'}
                                                                }],
                                                                'hidden': true,
                                                                'labelPosition': 'none',
                                                                'spacers': {
                                                                    'before': 0,
                                                                    'after': 3,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                }
                                                            }
                                                        }, {
                                                            'id': 'fld_hfxsv1z6',
                                                            'data': 'wxRchTxt_sTxt4',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': 240,
                                                                'width-mode': 'auto',
                                                                'min-lines': '0',
                                                                'max-lines': '0',
                                                                'items': [{
                                                                    'id': 'fld_hfxsv1z6_proxy',
                                                                    'data': 'wxRchTxt_sTxt4',
                                                                    'comp': {'name': 'Label', 'color': 'color_14'},
                                                                    'layout': {'text-align': 'left'}
                                                                }],
                                                                'hidden': false,
                                                                'labelPosition': 'none',
                                                                'spacers': {
                                                                    'before': 0,
                                                                    'after': 3,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                }
                                                            }
                                                        }, {
                                                            'experiment': 'NewListFields',
                                                            'id': 'fld_i9bcxjwk182',
                                                            'data': 'i9438kb2',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': '200',
                                                                'width-mode': 'auto',
                                                                'min-lines': '0',
                                                                'max-lines': '0',
                                                                'items': [{
                                                                    'id': 'fld_i9bcxjwk182_proxy',
                                                                    'data': 'i9438kb2',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'color': 'color_14',
                                                                        'style': 'Heading S'
                                                                    },
                                                                    'layout': {'text-align': 'left'}
                                                                }],
                                                                'hidden': false,
                                                                'labelPosition': 'none',
                                                                'spacers': {
                                                                    'before': 7,
                                                                    'after': 0,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                }
                                                            }
                                                        }],
                                                        'editorData': {
                                                            'labelPosition': 'none',
                                                            'alignment': 'center',
                                                            'displayName': 'Center section',
                                                            'spacers': {
                                                                'before': 0,
                                                                'after': 3,
                                                                'xax-before': 0,
                                                                'xax-after': 0
                                                            }
                                                        }
                                                    },
                                                    'layout': {'box-flex': 1, 'min-width': 0}
                                                }]
                                            },
                                            'layout': {'min-width': 0}
                                        }, {
                                            'id': 'def_3_1',
                                            'comp': {
                                                'name': 'HBox',
                                                'orientation': 'horizontal',
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'displayName': 'def_3_1',
                                                    'alignment': 'left',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 3,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'items': [{
                                                    'id': 'centerToRightSpacer',
                                                    'comp': {'name': 'HSpacer', 'size': 0}
                                                }, {
                                                    'id': 'Right',
                                                    'comp': {
                                                        'name': 'FieldBox',
                                                        'orientation': 'vertical',
                                                        'pack': 'center',
                                                        'editorData': {
                                                            'labelPosition': 'none',
                                                            'displayName': 'Right section',
                                                            'alignment': 'left',
                                                            'spacers': {
                                                                'before': 0,
                                                                'after': 3,
                                                                'xax-before': 0,
                                                                'xax-after': 0
                                                            }
                                                        }
                                                    },
                                                    'layout': {'min-width': 0}
                                                }]
                                            },
                                            'layout': {'min-width': 0}
                                        }],
                                        'orientation': 'vertical'
                                    }, 'id': 'def_3'
                                }, {
                                    'id': 'Bottom',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Bottom section',
                                            'alignment': 'center',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 0}
                                        },
                                        'items': [{
                                            'id': 'fld_hhqcd82d63',
                                            'data': 'Strng_sBttn0-78w',
                                            'comp': {
                                                'name': 'Field',
                                                'width': 280,
                                                'items': [{
                                                    'id': 'fld_hhqcd82d63_proxy',
                                                    'data': 'Strng_sBttn0-78w',
                                                    'comp': {'name': 'Button2', 'style': 'b1', 'align': 'center'},
                                                    'layout': {'position': 'relative'}
                                                }],
                                                'hidden': false,
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 20,
                                                    'after': 0,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'height': 44,
                                                'box-align': 'start'
                                            }
                                        }]
                                    },
                                    'layout': {'box-flex-pack': 'start', 'min-width': 0}
                                }, {'id': 'bottomSpacer', 'comp': {'name': 'VSpacer', 'size': 0}}]
                            },
                            'id': 'def_0',
                            'editorData': {'wasChanged': true, 'originalWidth': 346},
                            'format': 'Mobile'
                        }],
                        'items': [{
                            'title': i18n.translate('i7g43bc0.structure.custom.template.items.0.title'),
                            'image': {
                                '_type': 'wix:Image',
                                'title': '',
                                'src': '8d13be_db9cace8eb634836a8973e67ae4f0304.jpg',
                                'width': 1000,
                                'height': 1000,
                                'alt': ''
                            },
                            'Strng_sTxt0': i18n.translate('i7g43bc0.structure.custom.template.items.0.Strng_sTxt0'),
                            'wxRchTxt_sTxt4': {
                                'version': 1,
                                '_type': 'wix:RichText',
                                'links': [],
                                'text': i18n.translate('i7g43bc0.structure.custom.template.items.0.wxRchTxt_sTxt4')
                            },
                            'links': {'_type': 'wix:Map'},
                            'Strng_sBttn0-78w': i18n.translate('i7g43bc0.structure.custom.template.items.0.Strng_sBttn0-78w'),
                            'i6lykv5b': {
                                '_type': 'wix:Video',
                                'videoId': 'z1pSAwjC4JI',
                                'videoType': 'YOUTUBE',
                                'thumbnail': ''
                            },
                            'i9438kb2': i18n.translate('i7g43bc0.structure.custom.template.items.0.i9438kb2'),
                            'i943bmnv': {'_type': 'wix:Date', 'iso': '2023-01-03T00:00:00.000Z'}
                        }, {
                            'title': i18n.translate('i7g43bc0.structure.custom.template.items.1.title'),
                            'image': {
                                '_type': 'wix:Image',
                                'title': '',
                                'src': '8d13be_4574176bf37e40859c77e7986e78b48e.jpg',
                                'width': 1000,
                                'height': 1000,
                                'alt': ''
                            },
                            'Strng_sTxt0': i18n.translate('i7g43bc0.structure.custom.template.items.1.Strng_sTxt0'),
                            'wxRchTxt_sTxt4': {
                                'version': 1,
                                '_type': 'wix:RichText',
                                'links': [],
                                'text': i18n.translate('i7g43bc0.structure.custom.template.items.1.wxRchTxt_sTxt4')
                            },
                            'links': {'_type': 'wix:Map'},
                            'Strng_sBttn0-78w': i18n.translate('i7g43bc0.structure.custom.template.items.1.Strng_sBttn0-78w'),
                            'i6lykv5b': {
                                '_type': 'wix:Video',
                                'videoId': 'f36EEfI0yr8',
                                'videoType': 'YOUTUBE',
                                'thumbnail': ''
                            },
                            'i9438kb2': i18n.translate('i7g43bc0.structure.custom.template.items.1.i9438kb2'),
                            'i943bmnv': {'_type': 'wix:Date', 'iso': '2023-02-23T00:00:00.000Z'}
                        }, {
                            'title': i18n.translate('i7g43bc0.structure.custom.template.items.2.title'),
                            'image': {
                                '_type': 'wix:Image',
                                'title': '',
                                'src': '8d13be_f22f1f7181c941d7807760f2f57c896a.jpg',
                                'width': 1000,
                                'height': 1000,
                                'alt': ''
                            },
                            'Strng_sTxt0': i18n.translate('i7g43bc0.structure.custom.template.items.2.Strng_sTxt0'),
                            'wxRchTxt_sTxt4': {
                                'version': 1,
                                '_type': 'wix:RichText',
                                'links': [],
                                'text': i18n.translate('i7g43bc0.structure.custom.template.items.2.wxRchTxt_sTxt4')
                            },
                            'links': {'_type': 'wix:Map'},
                            'Strng_sBttn0-78w': i18n.translate('i7g43bc0.structure.custom.template.items.2.Strng_sBttn0-78w'),
                            'i6lykv5b': {
                                '_type': 'wix:Video',
                                'videoId': 'wGOTBSlYkzU',
                                'videoType': 'YOUTUBE',
                                'thumbnail': ''
                            },
                            'i9438kb2': i18n.translate('i7g43bc0.structure.custom.template.items.2.i9438kb2'),
                            'i943bmnv': {'_type': 'wix:Date', 'iso': '2023-05-28T00:00:00.000Z'}
                        }],
                        'displayName': i18n.translate('i7g43bc0.structure.custom.template.displayName')
                    }
                },
                'id': 'i7g43bc0'
            },
            'preset': {
                'rect': {'width': 162, 'height': 498, 'x': 162, 'y': 733.25},
                'label': 'i7g43bc0.structure.custom.template.displayName',
                'tags': null
            }
        }, {
            'id': 'Lists_List_Products_And_Services_9',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.viewer.skins.AppPartSkin',
                'layout': {
                    'width': 586,
                    'height': 743,
                    'x': 31,
                    'y': 4160,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wixapps.integration.components.AppPart2',
                'data': {
                    'type': 'AppBuilderComponent',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'appInnerID': '2',
                    'appPartName': 'appparti7ga2glc3'
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'id': 'app1',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {'groups': {}, 'properties': {'rd': '7px'}, 'propertiesSource': {'rd': 'value'}},
                    'componentClassName': '',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'system',
                    'skin': 'wysiwyg.viewer.skins.AppPartSkin'
                },
                'custom': {
                    'template': {
                        'type': {
                            'name': 'ProductCatalog_i6fdllk5424',
                            'fields': [{
                                'displayName': i18n.translate('i7ga2glj.structure.custom.template.type.fields.0.displayName'),
                                'name': 'title',
                                'searchable': false,
                                'type': 'String',
                                'defaultValue': i18n.translate('i7ga2glj.structure.custom.template.type.fields.0.defaultValue'),
                                'computed': false
                            }, {
                                'searchable': false,
                                'displayName': i18n.translate('i7ga2glj.structure.custom.template.type.fields.1.displayName'),
                                'type': 'String',
                                'computed': false,
                                'name': 'Strng_sTxt2-equ',
                                'defaultValue': i18n.translate('i7ga2glj.structure.custom.template.type.fields.1.defaultValue')
                            }, {
                                'type': 'wix:Date',
                                'searchable': false,
                                'displayName': i18n.translate('i7ga2glj.structure.custom.template.type.fields.2.displayName'),
                                'name': 'i945wxna',
                                'computed': false,
                                'defaultValue': {'iso': '2020-01-01T00:00:00.000Z', '_type': 'wix:Date'}
                            }, {
                                'displayName': i18n.translate('i7ga2glj.structure.custom.template.type.fields.3.displayName'),
                                'defaultValue': {
                                    'version': 1,
                                    '_type': 'wix:RichText',
                                    'links': [],
                                    'text': i18n.translate('i7ga2glj.structure.custom.template.type.fields.3.defaultValue')
                                },
                                'type': 'wix:RichText',
                                'searchable': false,
                                'name': 'wxRchTxt_sTxt4-ut6',
                                'computed': false
                            }, {
                                'metadata': {'showAsHint': 'AsPrice'},
                                'displayName': i18n.translate('i7ga2glj.structure.custom.template.type.fields.4.displayName'),
                                'searchable': false,
                                'type': 'String',
                                'defaultValue': i18n.translate('i7ga2glj.structure.custom.template.type.fields.4.defaultValue'),
                                'name': 'i945zsr5',
                                'computed': false
                            }, {
                                'name': 'wxmg_smg0-f9w',
                                'type': 'wix:Image',
                                'defaultValue': {
                                    'height': 1276,
                                    'src': 'c8381ce0e7a94ad09ceaada2fc64081d.jpg',
                                    'width': 1920,
                                    '_type': 'wix:Image',
                                    'title': ''
                                },
                                'displayName': i18n.translate('i7ga2glj.structure.custom.template.type.fields.5.displayName'),
                                'searchable': false,
                                'computed': false
                            }, {
                                'name': 'i6lyk2m4',
                                'defaultValue': {
                                    'videoId': 'f36EEfI0yr8',
                                    '_type': 'wix:Video',
                                    'videoType': 'YOUTUBE'
                                },
                                'type': 'wix:Video',
                                'searchable': false,
                                'displayName': i18n.translate('i7ga2glj.structure.custom.template.type.fields.6.displayName'),
                                'computed': false
                            }, {
                                'name': 'Strng_sBttn0-iqs',
                                'defaultValue': i18n.translate('i7ga2glj.structure.custom.template.type.fields.7.defaultValue'),
                                'searchable': false,
                                'metadata': {'showAsHint': 'AsButton'},
                                'type': 'String',
                                'displayName': i18n.translate('i7ga2glj.structure.custom.template.type.fields.7.displayName'),
                                'computed': false
                            }, {
                                'name': 'links',
                                'type': 'wix:Map',
                                'searchable': false,
                                'displayName': '',
                                'defaultValue': {'_type': 'wix:Map'},
                                'computed': false
                            }],
                            'version': 0,
                            'baseTypes': [],
                            'displayName': i18n.translate('i7ga2glj.structure.custom.template.type.displayName')
                        },
                        'views': [{
                            'name': 'ProductCatalogView_i6fdllk6425_dup_i6kohqa1456_dup_i6qm3p4g328_dup_i6umfbex226_dup_i70h8xy2195_dup_i7ga2gjy88',
                            'forType': 'Array',
                            'vars': {'itemSpacing': 50, 'paginationColor': 'color_15'},
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'paginatedlist',
                                    'comp': {
                                        'name': 'PaginatedList',
                                        'hidden': {'$expr': 'eq(Array.length(this), 0)'},
                                        'hidePagination': 'true',
                                        'itemsPerPage': '10',
                                        'templates': {
                                            'item': {
                                                'data': '$positionInParent',
                                                'comp': {
                                                    'name': 'SwitchBox',
                                                    'cases': {
                                                        'default': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'ProductCatalogView_i6fdllk6425_dup_i6kohqa1456_dup_i6qm3p4g328_dup_i6umfbex226_dup_i70h8xy2195_dup_i7ga2gjy88'}
                                                        }, {
                                                            'id': 'footerSpacer',
                                                            'comp': {
                                                                'name': 'VSpacer',
                                                                'size': {'$expr': '$itemSpacing'}
                                                            }
                                                        }, {
                                                            'id': 'gutterLine',
                                                            'comp': {'name': 'HorizontalLine', 'hidden': 'true'},
                                                            'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                        }],
                                                        'last': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'ProductCatalogView_i6fdllk6425_dup_i6kohqa1456_dup_i6qm3p4g328_dup_i6umfbex226_dup_i70h8xy2195_dup_i7ga2gjy88'}
                                                        }, {
                                                            'comp': {
                                                                'name': 'VBox',
                                                                'hidden': 'true',
                                                                'items': [{
                                                                    'id': 'footerSpacer',
                                                                    'comp': {
                                                                        'name': 'VSpacer',
                                                                        'size': {'$expr': '$itemSpacing'}
                                                                    }
                                                                }, {
                                                                    'id': 'gutterLine',
                                                                    'comp': {
                                                                        'name': 'HorizontalLine',
                                                                        'hidden': 'true'
                                                                    },
                                                                    'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                                }]
                                                            }, 'id': 'def_7'
                                                        }, {
                                                            'comp': {'name': 'VSpacer', 'size': '9'},
                                                            'id': 'def_10'
                                                        }, {
                                                            'id': 'paginationNavigationBox',
                                                            'comp': {
                                                                'name': 'HBox',
                                                                'hidden': {'$expr': '$hidePagination'},
                                                                'box-align': 'center',
                                                                'items': [{
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_12'
                                                                }, {
                                                                    'value': '◄',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationPrev',
                                                                        'events': {'dom:click': 'prevPageClicked'}
                                                                    },
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_13'
                                                                }, {
                                                                    'value': {'$expr': 'String.concat($currentPage,\' / \',$maxPage)'},
                                                                    'comp': {'name': 'Label'},
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_14'
                                                                }, {
                                                                    'value': '►',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationNext',
                                                                        'events': {'dom:click': 'nextPageClicked'}
                                                                    },
                                                                    'id': 'def_15'
                                                                }, {
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_16'
                                                                }]
                                                            },
                                                            'layout': {'position': 'relative'}
                                                        }]
                                                    },
                                                    'css': {'min-width': '100%'}
                                                },
                                                'id': '$positionInParent'
                                            }
                                        }
                                    }
                                }, {
                                    'id': 'noItemsLabel',
                                    'value': 'There are no items in this list',
                                    'comp': {'name': 'Label', 'hidden': {'$expr': 'gt(String.length(this), 0)'}}
                                }],
                                'editorData': {'isPaginated': true}
                            },
                            'stylesheet': [{
                                '.paginationPrev, .paginationNext': {
                                    'padding': '0 5px',
                                    'display': 'inline-block',
                                    'font-size': '14px',
                                    'cursor': 'pointer',
                                    '-webkit-touch-callout': 'none',
                                    '-webkit-user-select': 'none',
                                    '-khtml-user-select': 'none',
                                    '-moz-user-select': 'none',
                                    '-ms-user-select': 'none',
                                    'user-select': 'none'
                                }
                            }],
                            'customizations': [{
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.hidePagination',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Split your list across multiple pages',
                                    'defaultVal': 'true',
                                    'trueVal': 'false',
                                    'spaceAfter': '10'
                                }
                            }, {
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.itemsPerPage',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '120',
                                    'minVal': '1',
                                    'label': 'Items Per Page',
                                    'spaceAfter': '20',
                                    'hiddenwhen': {
                                        'fieldId': 'paginatedlist',
                                        'key': 'comp.hidePagination',
                                        'value': 'true'
                                    }
                                }
                            }, {
                                'priority': 100,
                                'fieldId': 'vars',
                                'key': 'itemSpacing',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '300',
                                    'minVal': '0',
                                    'label': 'Item Spacing',
                                    'spaceAfter': '20'
                                }
                            }, {
                                'priority': 95,
                                'fieldId': 'gutterLine',
                                'key': 'comp.hidden',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Show item separator',
                                    'defaultVal': 'false',
                                    'trueVal': 'false'
                                }
                            }, {
                                'priority': 90,
                                'fieldId': 'gutterLine',
                                'key': 'comp.style',
                                'input': {
                                    'name': 'changestyle',
                                    'showAs': 'link',
                                    'label': 'Change Item Separator Style',
                                    'spaceAfter': '20'
                                }
                            }],
                            'id': 'i7ga2gjy'
                        }, {
                            'name': 'ProductCatalogView_i6fdllk6425_dup_i6kohqa1456_dup_i6qm3p4g328_dup_i6umfbex226_dup_i70h8xy2195_dup_i7ga2gjy88',
                            'forType': 'Array',
                            'vars': {'itemSpacing': 50, 'paginationColor': 'color_15'},
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'paginatedlist',
                                    'comp': {
                                        'name': 'PaginatedList',
                                        'hidden': {'$expr': 'eq(Array.length(this), 0)'},
                                        'hidePagination': 'true',
                                        'itemsPerPage': '10',
                                        'templates': {
                                            'item': {
                                                'data': '$positionInParent',
                                                'comp': {
                                                    'name': 'SwitchBox',
                                                    'cases': {
                                                        'default': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'ProductCatalogView_i6fdllk6425_dup_i6kohqa1456_dup_i6qm3p4g328_dup_i6umfbex226_dup_i70h8xy2195_dup_i7ga2gjy88'}
                                                        }, {
                                                            'id': 'footerSpacer',
                                                            'comp': {
                                                                'name': 'VSpacer',
                                                                'size': {'$expr': '$itemSpacing'}
                                                            }
                                                        }, {
                                                            'id': 'gutterLine',
                                                            'comp': {'name': 'HorizontalLine', 'hidden': 'true'},
                                                            'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                        }],
                                                        'last': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'ProductCatalogView_i6fdllk6425_dup_i6kohqa1456_dup_i6qm3p4g328_dup_i6umfbex226_dup_i70h8xy2195_dup_i7ga2gjy88'}
                                                        }, {
                                                            'comp': {
                                                                'name': 'VBox',
                                                                'hidden': 'true',
                                                                'items': [{
                                                                    'id': 'footerSpacer',
                                                                    'comp': {
                                                                        'name': 'VSpacer',
                                                                        'size': {'$expr': '$itemSpacing'}
                                                                    }
                                                                }, {
                                                                    'id': 'gutterLine',
                                                                    'comp': {
                                                                        'name': 'HorizontalLine',
                                                                        'hidden': 'true'
                                                                    },
                                                                    'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                                }]
                                                            }, 'id': 'def_7'
                                                        }, {
                                                            'comp': {'name': 'VSpacer', 'size': '9'},
                                                            'id': 'def_10'
                                                        }, {
                                                            'id': 'paginationNavigationBox',
                                                            'comp': {
                                                                'name': 'HBox',
                                                                'hidden': {'$expr': '$hidePagination'},
                                                                'box-align': 'center',
                                                                'items': [{
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_12'
                                                                }, {
                                                                    'value': '◄',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationPrev',
                                                                        'events': {'dom:click': 'prevPageClicked'}
                                                                    },
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_13'
                                                                }, {
                                                                    'value': {'$expr': 'String.concat($currentPage,\' / \',$maxPage)'},
                                                                    'comp': {'name': 'Label'},
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_14'
                                                                }, {
                                                                    'value': '►',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationNext',
                                                                        'events': {'dom:click': 'nextPageClicked'}
                                                                    },
                                                                    'id': 'def_15'
                                                                }, {
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_16'
                                                                }]
                                                            },
                                                            'layout': {'position': 'relative'}
                                                        }]
                                                    },
                                                    'css': {'min-width': '100%'}
                                                },
                                                'id': '$positionInParent'
                                            }
                                        }
                                    }
                                }, {
                                    'id': 'noItemsLabel',
                                    'value': 'There are no items in this list',
                                    'comp': {'name': 'Label', 'hidden': {'$expr': 'gt(String.length(this), 0)'}}
                                }],
                                'editorData': {'isPaginated': true}
                            },
                            'stylesheet': [{
                                '.paginationPrev, .paginationNext': {
                                    'padding': '0 5px',
                                    'display': 'inline-block',
                                    'font-size': '14px',
                                    'cursor': 'pointer',
                                    '-webkit-touch-callout': 'none',
                                    '-webkit-user-select': 'none',
                                    '-khtml-user-select': 'none',
                                    '-moz-user-select': 'none',
                                    '-ms-user-select': 'none',
                                    'user-select': 'none'
                                }
                            }],
                            'customizations': [{
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.hidePagination',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Split your list across multiple pages',
                                    'defaultVal': 'true',
                                    'trueVal': 'false',
                                    'spaceAfter': '10'
                                }
                            }, {
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.itemsPerPage',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '120',
                                    'minVal': '1',
                                    'label': 'Items Per Page',
                                    'spaceAfter': '20',
                                    'hiddenwhen': {
                                        'fieldId': 'paginatedlist',
                                        'key': 'comp.hidePagination',
                                        'value': 'true'
                                    }
                                }
                            }, {
                                'priority': 100,
                                'fieldId': 'vars',
                                'key': 'itemSpacing',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '300',
                                    'minVal': '0',
                                    'label': 'Item Spacing',
                                    'spaceAfter': '20'
                                }
                            }, {
                                'priority': 95,
                                'fieldId': 'gutterLine',
                                'key': 'comp.hidden',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Show item separator',
                                    'defaultVal': 'false',
                                    'trueVal': 'false'
                                }
                            }, {
                                'priority': 90,
                                'fieldId': 'gutterLine',
                                'key': 'comp.style',
                                'input': {
                                    'name': 'changestyle',
                                    'showAs': 'link',
                                    'label': 'Change Item Separator Style',
                                    'spaceAfter': '20'
                                }
                            }],
                            'id': 'i7ga2gjy',
                            'format': 'Mobile'
                        }, {
                            'name': 'ProductCatalogView_i6fdllk6425_dup_i6kohqa1456_dup_i6qm3p4g328_dup_i6umfbex226_dup_i70h8xy2195_dup_i7ga2gjy88',
                            'forType': 'ProductCatalog_i6fdllk5424',
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'Top',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Top section',
                                            'alignment': 'left',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 3}
                                        },
                                        'items': []
                                    },
                                    'layout': {'min-width': 0}
                                }, {
                                    'id': 'topSpacer',
                                    'comp': {'name': 'VSpacer', 'size': '0'}
                                }, {
                                    'comp': {
                                        'name': 'HBox',
                                        'items': [{
                                            'id': 'Left',
                                            'comp': {
                                                'name': 'FieldBox',
                                                'orientation': 'vertical',
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'alignment': 'left',
                                                    'displayName': 'Left section',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 0,
                                                        'xax-after': 10
                                                    }
                                                },
                                                'items': [{
                                                    'id': 'fld_i71mg5ik477',
                                                    'data': 'i6lyk2m4',
                                                    'comp': {
                                                        'name': 'Field',
                                                        'width': 330,
                                                        'height': '220',
                                                        'items': [{
                                                            'id': 'fld_i71mg5ik477_proxy',
                                                            'data': 'i6lyk2m4',
                                                            'comp': {'name': 'Video'},
                                                            'layout': {'position': 'relative'}
                                                        }],
                                                        'hidden': true,
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 0,
                                                            'xax-before': 0,
                                                            'xax-after': 24
                                                        },
                                                        'box-align': 'left'
                                                    }
                                                }]
                                            },
                                            'layout': {'spacerAfter': 0, 'min-width': 0}
                                        }, {
                                            'id': 'leftToCenterSpacer',
                                            'comp': {'name': 'HSpacer', 'size': '0'}
                                        }, {
                                            'id': 'Center',
                                            'comp': {
                                                'name': 'FieldBox',
                                                'orientation': 'vertical',
                                                'items': [{
                                                    'id': 'fld_i945wxow181',
                                                    'data': 'i945wxna',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0',
                                                        'items': [{
                                                            'id': 'fld_i945wxow181_proxy',
                                                            'data': 'i945wxna',
                                                            'comp': {
                                                                'name': 'Date',
                                                                'style': 'Body M',
                                                                'format': 'mmm dd, yyyy',
                                                                'color': 'color_14'
                                                            },
                                                            'layout': {'text-align': 'left'}
                                                        }],
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 3,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'hidden': true
                                                    },
                                                    'experiment': 'NewListFields'
                                                }, {
                                                    'id': 'fld_hh7pi5bs104',
                                                    'data': 'title',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0',
                                                        'items': [{
                                                            'id': 'fld_hh7pi5bs104_proxy',
                                                            'data': 'title',
                                                            'comp': {
                                                                'name': 'Label',
                                                                'style': 'Heading S',
                                                                'bold': false,
                                                                'underline': false,
                                                                'italic': false,
                                                                'color': 'color_14'
                                                            },
                                                            'layout': {'text-align': 'left'}
                                                        }],
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 0,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'hidden': false
                                                    }
                                                }, {
                                                    'id': 'fld_hh7qc5na194',
                                                    'data': 'Strng_sTxt2-equ',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0',
                                                        'items': [{
                                                            'id': 'fld_hh7qc5na194_proxy',
                                                            'data': 'Strng_sTxt2-equ',
                                                            'comp': {
                                                                'name': 'Label',
                                                                'style': 'Body L',
                                                                'bold': false,
                                                                'underline': false,
                                                                'italic': false,
                                                                'color': 'color_14'
                                                            },
                                                            'layout': {'text-align': 'left'}
                                                        }],
                                                        'hidden': true,
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 0,
                                                            'xax-before': 0,
                                                            'xax-after': 20
                                                        }
                                                    }
                                                }, {
                                                    'id': 'fld_i945zss1214',
                                                    'data': 'i945zsr5',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'items': [{
                                                            'id': 'fld_i945zss1214_proxy',
                                                            'data': 'i945zsr5',
                                                            'comp': {
                                                                'name': 'Label',
                                                                'style': 'Body L',
                                                                'color': 'color_14'
                                                            },
                                                            'layout': {'text-align': 'left'}
                                                        }],
                                                        'hidden': false,
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 3,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0'
                                                    },
                                                    'experiment': 'NewListFields'
                                                }, {
                                                    'id': 'fld_hh7pj3mn111',
                                                    'data': 'wxRchTxt_sTxt4-ut6',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'items': [{
                                                            'id': 'fld_hh7pj3mn111_proxy',
                                                            'data': 'wxRchTxt_sTxt4-ut6',
                                                            'comp': {'name': 'Label', 'color': 'color_14'},
                                                            'layout': {'text-align': 'left'}
                                                        }],
                                                        'hidden': false,
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 10,
                                                            'after': 33,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0'
                                                    }
                                                }, {
                                                    'id': 'fld_hh7q16nc163',
                                                    'data': 'Strng_sBttn0-iqs',
                                                    'comp': {
                                                        'name': 'Field',
                                                        'width': 160,
                                                        'height': 40,
                                                        'items': [{
                                                            'id': 'fld_hh7q16nc163_proxy',
                                                            'data': 'Strng_sBttn0-iqs',
                                                            'comp': {
                                                                'name': 'Button2',
                                                                'style': 'b1',
                                                                'align': 'center'
                                                            },
                                                            'layout': {'position': 'relative'}
                                                        }],
                                                        'hidden': false,
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 0,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'box-align': 'left'
                                                    }
                                                }],
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'alignment': 'left',
                                                    'displayName': 'Center section',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 0,
                                                        'xax-after': 0
                                                    }
                                                }
                                            },
                                            'layout': {'box-flex': 1, 'min-width': 0}
                                        }, {
                                            'id': 'centerToRightSpacer',
                                            'comp': {'name': 'HSpacer', 'size': '0'}
                                        }, {
                                            'id': 'Right',
                                            'comp': {
                                                'name': 'FieldBox',
                                                'orientation': 'vertical',
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'displayName': 'Right section',
                                                    'alignment': 'left',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 20,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'items': [{
                                                    'id': 'fld_hh7p9ta689',
                                                    'data': 'wxmg_smg0-f9w',
                                                    'comp': {
                                                        'name': 'Field',
                                                        'width': 210,
                                                        'height': 210,
                                                        'items': [{
                                                            'id': 'fld_hh7p9ta689_proxy',
                                                            'data': 'wxmg_smg0-f9w',
                                                            'comp': {'name': 'Image', 'style': 'wp2'}
                                                        }],
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 0,
                                                            'xax-before': 20,
                                                            'xax-after': 0
                                                        },
                                                        'box-align': 'left',
                                                        'hidden': false
                                                    }
                                                }]
                                            },
                                            'layout': {'min-width': 0}
                                        }]
                                    }, 'id': 'def_3'
                                }, {
                                    'id': 'Bottom',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Bottom section',
                                            'alignment': 'left',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 3}
                                        },
                                        'items': []
                                    },
                                    'layout': {'box-flex-pack': 'start', 'min-width': 0}
                                }, {'id': 'bottomSpacer', 'comp': {'name': 'VSpacer', 'size': '0'}}]
                            },
                            'id': 'def_0',
                            'editorData': {'wasChanged': true}
                        }, {
                            'name': 'ProductCatalogView_i6fdllk6425_dup_i6kohqa1456_dup_i6qm3p4g328_dup_i6umfbex226_dup_i70h8xy2195_dup_i7ga2gjy88',
                            'forType': 'ProductCatalog_i6fdllk5424',
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'Top',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Top section',
                                            'alignment': 'left',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 0}
                                        },
                                        'items': [{
                                            'id': 'fld_hh7p9ta689',
                                            'data': 'wxmg_smg0-f9w',
                                            'comp': {
                                                'name': 'Field',
                                                'width': 280,
                                                'height': 220,
                                                'items': [{
                                                    'id': 'fld_hh7p9ta689_proxy',
                                                    'data': 'wxmg_smg0-f9w',
                                                    'comp': {
                                                        'name': 'Image',
                                                        'style': 'wp2',
                                                        'imageMode': 'fitWidth'
                                                    }
                                                }],
                                                'hidden': false,
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 0,
                                                    'after': 10,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'box-align': 'left',
                                                'heightMode': 'auto'
                                            }
                                        }, {
                                            'id': 'fld_i71mg5ik477',
                                            'data': 'i6lyk2m4',
                                            'comp': {
                                                'name': 'Field',
                                                'width': 280,
                                                'items': [{
                                                    'id': 'fld_i71mg5ik477_proxy',
                                                    'data': 'i6lyk2m4',
                                                    'comp': {'name': 'Video'},
                                                    'layout': {'position': 'relative'}
                                                }],
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 0,
                                                    'after': 3,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'hidden': true,
                                                'height': '220',
                                                'box-align': 'left'
                                            }
                                        }, {
                                            'experiment': 'NewListFields',
                                            'id': 'fld_i9bb48wl146',
                                            'data': 'i945wxna',
                                            'comp': {
                                                'name': 'TextField',
                                                'width': '200',
                                                'width-mode': 'auto',
                                                'min-lines': '0',
                                                'max-lines': '0',
                                                'items': [{
                                                    'id': 'fld_i9bb48wl146_proxy',
                                                    'data': 'i945wxna',
                                                    'comp': {
                                                        'name': 'Date',
                                                        'format': 'mmmm dd, yyyy',
                                                        'color': 'color_14'
                                                    },
                                                    'layout': {'text-align': 'left'}
                                                }],
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 0,
                                                    'after': 3,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'hidden': true
                                            }
                                        }]
                                    },
                                    'layout': {'min-width': 0}
                                }, {
                                    'id': 'topSpacer',
                                    'comp': {'name': 'VSpacer', 'size': 0}
                                }, {
                                    'comp': {
                                        'name': 'VBox',
                                        'items': [{
                                            'id': 'def_3_0',
                                            'comp': {
                                                'name': 'HBox',
                                                'orientation': 'horizontal',
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'displayName': 'def_3_0',
                                                    'alignment': 'left',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 3,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'items': [{
                                                    'id': 'Left',
                                                    'comp': {
                                                        'name': 'FieldBox',
                                                        'orientation': 'vertical',
                                                        'editorData': {
                                                            'labelPosition': 'none',
                                                            'alignment': 'left',
                                                            'displayName': 'Left section',
                                                            'spacers': {
                                                                'before': 0,
                                                                'after': 3,
                                                                'xax-before': 0,
                                                                'xax-after': 0
                                                            }
                                                        },
                                                        'items': []
                                                    },
                                                    'layout': {'spacerAfter': 0, 'min-width': 0}
                                                }]
                                            },
                                            'layout': {'min-width': 0}
                                        }, {
                                            'id': 'def_3_1',
                                            'comp': {
                                                'name': 'HBox',
                                                'orientation': 'horizontal',
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'displayName': 'def_3_1',
                                                    'alignment': 'left',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 3,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'items': [{
                                                    'id': 'leftToCenterSpacer',
                                                    'comp': {'name': 'HSpacer', 'size': 0}
                                                }, {
                                                    'id': 'Center',
                                                    'comp': {
                                                        'name': 'FieldBox',
                                                        'orientation': 'vertical',
                                                        'editorData': {
                                                            'labelPosition': 'none',
                                                            'displayName': 'Center section',
                                                            'alignment': 'left',
                                                            'spacers': {
                                                                'before': 0,
                                                                'after': 3,
                                                                'xax-before': 0,
                                                                'xax-after': 0
                                                            }
                                                        },
                                                        'items': [{
                                                            'id': 'fld_hh7pi5bs104',
                                                            'data': 'title',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': 240,
                                                                'items': [{
                                                                    'id': 'fld_hh7pi5bs104_proxy',
                                                                    'data': 'title',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'style': 'Body L',
                                                                        'bold': false,
                                                                        'underline': false,
                                                                        'italic': false,
                                                                        'color': 'color_14'
                                                                    },
                                                                    'layout': {'text-align': 'left'}
                                                                }],
                                                                'labelPosition': 'none',
                                                                'spacers': {
                                                                    'before': 0,
                                                                    'after': 0,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                },
                                                                'hidden': false,
                                                                'width-mode': 'auto',
                                                                'min-lines': '0',
                                                                'max-lines': '0'
                                                            }
                                                        }, {
                                                            'id': 'fld_hh7qc5na194',
                                                            'data': 'Strng_sTxt2-equ',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': 240,
                                                                'width-mode': 'auto',
                                                                'min-lines': '0',
                                                                'max-lines': '0',
                                                                'items': [{
                                                                    'id': 'fld_hh7qc5na194_proxy',
                                                                    'data': 'Strng_sTxt2-equ',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'style': 'Body L',
                                                                        'bold': false,
                                                                        'underline': false,
                                                                        'italic': false,
                                                                        'color': 'color_14'
                                                                    },
                                                                    'layout': {'text-align': 'left'}
                                                                }],
                                                                'labelPosition': 'none',
                                                                'spacers': {
                                                                    'before': 0,
                                                                    'after': 0,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                },
                                                                'hidden': true
                                                            }
                                                        }, {
                                                            'id': 'fld_i9bc393a55',
                                                            'data': 'i945zsr5',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': '200',
                                                                'width-mode': 'auto',
                                                                'min-lines': '0',
                                                                'max-lines': '0',
                                                                'items': [{
                                                                    'id': 'fld_i9bc393a55_proxy',
                                                                    'data': 'i945zsr5',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'color': 'color_14',
                                                                        'style': 'Heading S'
                                                                    },
                                                                    'layout': {'text-align': 'left'}
                                                                }],
                                                                'hidden': false,
                                                                'labelPosition': 'none',
                                                                'spacers': {
                                                                    'before': 4,
                                                                    'after': 0,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                }
                                                            },
                                                            'experiment': 'NewListFields'
                                                        }, {
                                                            'id': 'fld_hh7pj3mn111',
                                                            'data': 'wxRchTxt_sTxt4-ut6',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': 240,
                                                                'items': [{
                                                                    'id': 'fld_hh7pj3mn111_proxy',
                                                                    'data': 'wxRchTxt_sTxt4-ut6',
                                                                    'comp': {'name': 'Label', 'color': 'color_14'},
                                                                    'layout': {'text-align': 'left'}
                                                                }],
                                                                'hidden': false,
                                                                'labelPosition': 'none',
                                                                'spacers': {
                                                                    'before': 10,
                                                                    'after': 24,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                },
                                                                'width-mode': 'auto',
                                                                'min-lines': '0',
                                                                'max-lines': '0'
                                                            }
                                                        }, {
                                                            'id': 'fld_hh7q16nc163',
                                                            'data': 'Strng_sBttn0-iqs',
                                                            'comp': {
                                                                'name': 'Field',
                                                                'width': 280,
                                                                'height': 44,
                                                                'items': [{
                                                                    'id': 'fld_hh7q16nc163_proxy',
                                                                    'data': 'Strng_sBttn0-iqs',
                                                                    'comp': {
                                                                        'name': 'Button2',
                                                                        'style': 'b1',
                                                                        'align': 'center'
                                                                    },
                                                                    'layout': {'position': 'relative'}
                                                                }],
                                                                'hidden': false,
                                                                'labelPosition': 'none',
                                                                'spacers': {
                                                                    'before': 0,
                                                                    'after': 0,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                },
                                                                'box-align': 'start'
                                                            }
                                                        }]
                                                    },
                                                    'layout': {'min-width': 0, 'box-flex': 1}
                                                }]
                                            },
                                            'layout': {'min-width': 0}
                                        }, {
                                            'id': 'def_3_2',
                                            'comp': {
                                                'name': 'HBox',
                                                'orientation': 'horizontal',
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'displayName': 'def_3_2',
                                                    'alignment': 'left',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 3,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'items': [{
                                                    'id': 'centerToRightSpacer',
                                                    'comp': {'name': 'HSpacer', 'size': 0}
                                                }, {
                                                    'id': 'Right',
                                                    'comp': {
                                                        'name': 'FieldBox',
                                                        'orientation': 'vertical',
                                                        'editorData': {
                                                            'labelPosition': 'none',
                                                            'displayName': 'Right section',
                                                            'alignment': 'left',
                                                            'spacers': {
                                                                'before': 0,
                                                                'after': 3,
                                                                'xax-before': 0,
                                                                'xax-after': 0
                                                            }
                                                        },
                                                        'items': []
                                                    },
                                                    'layout': {'min-width': 0}
                                                }]
                                            },
                                            'layout': {'min-width': 0}
                                        }],
                                        'orientation': 'vertical'
                                    }, 'id': 'def_3'
                                }, {
                                    'id': 'Bottom',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Bottom section',
                                            'alignment': 'left',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 0}
                                        },
                                        'items': []
                                    },
                                    'layout': {'box-flex-pack': 'start', 'min-width': 0}
                                }, {'id': 'bottomSpacer', 'comp': {'name': 'VSpacer', 'size': 0}}]
                            },
                            'id': 'def_0',
                            'editorData': {'originalWidth': 679, 'wasChanged': true},
                            'format': 'Mobile'
                        }],
                        'items': [{
                            'links': {'_type': 'wix:Map'},
                            'wxmg_smg0-f9w': {
                                '_type': 'wix:Image',
                                'title': '',
                                'src': '8d13be_db9cace8eb634836a8973e67ae4f0304.jpg',
                                'width': 1000,
                                'height': 1000,
                                'alt': ''
                            },
                            'title': i18n.translate('i7ga2glj.structure.custom.template.items.0.title'),
                            'wxRchTxt_sTxt4-ut6': {
                                'version': 1,
                                '_type': 'wix:RichText',
                                'links': [],
                                'text': i18n.translate('i7ga2glj.structure.custom.template.items.0.wxRchTxt_sTxt4-ut6')
                            },
                            'Strng_sBttn0-iqs': i18n.translate('i7ga2glj.structure.custom.template.items.0.Strng_sBttn0-iqs'),
                            'Strng_sTxt2-equ': i18n.translate('i7ga2glj.structure.custom.template.items.0.Strng_sTxt2-equ'),
                            'i6lyk2m4': {
                                '_type': 'wix:Video',
                                'videoId': 'z1pSAwjC4JI',
                                'videoType': 'YOUTUBE',
                                'thumbnail': ''
                            },
                            'i945wxna': {'_type': 'wix:Date', 'iso': '2023-01-03T00:00:00.000Z'},
                            'i945zsr5': i18n.translate('i7ga2glj.structure.custom.template.items.0.i945zsr5')
                        }, {
                            'links': {'_type': 'wix:Map'},
                            'wxmg_smg0-f9w': {
                                '_type': 'wix:Image',
                                'title': '',
                                'src': '8d13be_4574176bf37e40859c77e7986e78b48e.jpg',
                                'width': 1000,
                                'height': 1000,
                                'alt': ''
                            },
                            'title': i18n.translate('i7ga2glj.structure.custom.template.items.1.title'),
                            'wxRchTxt_sTxt4-ut6': {
                                'version': 1,
                                '_type': 'wix:RichText',
                                'links': [],
                                'text': i18n.translate('i7ga2glj.structure.custom.template.items.1.wxRchTxt_sTxt4-ut6')
                            },
                            'Strng_sBttn0-iqs': i18n.translate('i7ga2glj.structure.custom.template.items.1.Strng_sBttn0-iqs'),
                            'Strng_sTxt2-equ': i18n.translate('i7ga2glj.structure.custom.template.items.1.Strng_sTxt2-equ'),
                            'i6lyk2m4': {
                                '_type': 'wix:Video',
                                'videoId': 'f36EEfI0yr8',
                                'videoType': 'YOUTUBE',
                                'thumbnail': ''
                            },
                            'i945wxna': {'_type': 'wix:Date', 'iso': '2023-02-23T00:00:00.000Z'},
                            'i945zsr5': i18n.translate('i7ga2glj.structure.custom.template.items.1.i945zsr5')
                        }, {
                            'links': {'_type': 'wix:Map'},
                            'wxmg_smg0-f9w': {
                                '_type': 'wix:Image',
                                'title': '',
                                'src': '8d13be_f22f1f7181c941d7807760f2f57c896a.jpg',
                                'width': 1000,
                                'height': 1000,
                                'alt': ''
                            },
                            'title': i18n.translate('i7ga2glj.structure.custom.template.items.2.title'),
                            'wxRchTxt_sTxt4-ut6': {
                                'version': 1,
                                '_type': 'wix:RichText',
                                'links': [],
                                'text': i18n.translate('i7ga2glj.structure.custom.template.items.2.wxRchTxt_sTxt4-ut6')
                            },
                            'Strng_sBttn0-iqs': i18n.translate('i7ga2glj.structure.custom.template.items.2.Strng_sBttn0-iqs'),
                            'Strng_sTxt2-equ': i18n.translate('i7ga2glj.structure.custom.template.items.2.Strng_sTxt2-equ'),
                            'i6lyk2m4': {
                                '_type': 'wix:Video',
                                'videoId': 'wGOTBSlYkzU',
                                'videoType': 'YOUTUBE',
                                'thumbnail': ''
                            },
                            'i945wxna': {'_type': 'wix:Date', 'iso': '2023-05-28T00:00:00.000Z'},
                            'i945zsr5': i18n.translate('i7ga2glj.structure.custom.template.items.2.i945zsr5')
                        }],
                        'displayName': i18n.translate('i7ga2glj.structure.custom.template.displayName')
                    }
                },
                'id': 'i7ga2glj'
            },
            'preset': {
                'rect': {'width': 162, 'height': 208, 'x': 0, 'y': 1023.25},
                'label': 'i7ga2glj.structure.custom.template.displayName',
                'tags': null
            }
        }, {
            'id': 'Lists_List_Products_And_Services_10',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.viewer.skins.AppPartSkin',
                'layout': {
                    'width': 371,
                    'height': 291,
                    'x': 107,
                    'y': 5044,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wixapps.integration.components.AppPart2',
                'data': {
                    'type': 'AppBuilderComponent',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'appInnerID': '2',
                    'appPartName': 'appparti7gcdg3b6'
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'id': 'app1',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {'groups': {}, 'properties': {'rd': '7px'}, 'propertiesSource': {'rd': 'value'}},
                    'componentClassName': '',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'system',
                    'skin': 'wysiwyg.viewer.skins.AppPartSkin'
                },
                'custom': {
                    'template': {
                        'type': {
                            'version': 0,
                            'baseTypes': [],
                            'fields': [{
                                'displayName': i18n.translate('i7gcdg3i.structure.custom.template.type.fields.0.displayName'),
                                'name': 'title',
                                'searchable': false,
                                'type': 'String',
                                'defaultValue': i18n.translate('i7gcdg3i.structure.custom.template.type.fields.0.defaultValue'),
                                'computed': false
                            }, {
                                'name': 'Strng_sTxt1',
                                'searchable': false,
                                'displayName': i18n.translate('i7gcdg3i.structure.custom.template.type.fields.1.displayName'),
                                'type': 'String',
                                'computed': false,
                                'defaultValue': i18n.translate('i7gcdg3i.structure.custom.template.type.fields.1.defaultValue')
                            }, {
                                'name': 'i946vvwv',
                                'type': 'wix:Date',
                                'searchable': false,
                                'displayName': i18n.translate('i7gcdg3i.structure.custom.template.type.fields.2.displayName'),
                                'computed': false,
                                'defaultValue': {'iso': '2020-01-01T00:00:00.000Z', '_type': 'wix:Date'}
                            }, {
                                'displayName': i18n.translate('i7gcdg3i.structure.custom.template.type.fields.3.displayName'),
                                'defaultValue': {
                                    'version': 1,
                                    '_type': 'wix:RichText',
                                    'links': [],
                                    'text': i18n.translate('i7gcdg3i.structure.custom.template.type.fields.3.defaultValue')
                                },
                                'name': 'wxRchTxt_sTxt2',
                                'type': 'wix:RichText',
                                'searchable': false,
                                'computed': false
                            }, {
                                'metadata': {'showAsHint': 'AsPrice'},
                                'displayName': i18n.translate('i7gcdg3i.structure.custom.template.type.fields.4.displayName'),
                                'searchable': false,
                                'type': 'String',
                                'defaultValue': i18n.translate('i7gcdg3i.structure.custom.template.type.fields.4.defaultValue'),
                                'name': 'i946tse6',
                                'computed': false
                            }, {
                                'name': 'i7gbz8ku',
                                'type': 'wix:Image',
                                'defaultValue': {
                                    'height': 1276,
                                    'src': 'c8381ce0e7a94ad09ceaada2fc64081d.jpg',
                                    'width': 1920,
                                    '_type': 'wix:Image',
                                    'title': ''
                                },
                                'displayName': i18n.translate('i7gcdg3i.structure.custom.template.type.fields.5.displayName'),
                                'searchable': false,
                                'computed': false
                            }, {
                                'defaultValue': {
                                    'videoId': 'f36EEfI0yr8',
                                    '_type': 'wix:Video',
                                    'videoType': 'YOUTUBE'
                                },
                                'name': 'i7gc7wyf',
                                'type': 'wix:Video',
                                'searchable': false,
                                'displayName': i18n.translate('i7gcdg3i.structure.custom.template.type.fields.6.displayName'),
                                'computed': false
                            }, {
                                'defaultValue': i18n.translate('i7gcdg3i.structure.custom.template.type.fields.7.defaultValue'),
                                'name': 'i7gc0zfm',
                                'searchable': false,
                                'metadata': {'showAsHint': 'AsButton'},
                                'type': 'String',
                                'displayName': i18n.translate('i7gcdg3i.structure.custom.template.type.fields.7.displayName'),
                                'computed': false
                            }, {
                                'name': 'links',
                                'type': 'wix:Map',
                                'searchable': false,
                                'defaultValue': {
                                    '_type': 'wix:Map',
                                    'i7gc0zfm': {
                                        'protocol': 'http',
                                        '_type': 'wix:ExternalLink',
                                        'target': '_blank',
                                        'address': 'www.wix.com'
                                    }
                                },
                                'displayName': '',
                                'computed': false
                            }],
                            'name': 'EventList_i7gbrm0b16',
                            'displayName': i18n.translate('i7gcdg3i.structure.custom.template.type.displayName')
                        },
                        'views': [{
                            'name': 'EventListView_i7gbrm0c17_dup_i7gcdg2m296',
                            'forType': 'Array',
                            'vars': {'itemSpacing': 40, 'paginationColor': 'color_15'},
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'paginatedlist',
                                    'comp': {
                                        'name': 'PaginatedList',
                                        'hidden': {'$expr': 'eq(Array.length(this), 0)'},
                                        'hidePagination': 'true',
                                        'itemsPerPage': '10',
                                        'templates': {
                                            'item': {
                                                'data': '$positionInParent',
                                                'comp': {
                                                    'name': 'SwitchBox',
                                                    'cases': {
                                                        'default': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'EventListView_i7gbrm0c17_dup_i7gcdg2m296'}
                                                        }, {
                                                            'id': 'footerSpacer',
                                                            'comp': {
                                                                'name': 'VSpacer',
                                                                'size': {'$expr': '$itemSpacing'}
                                                            }
                                                        }, {
                                                            'id': 'gutterLine',
                                                            'comp': {'name': 'HorizontalLine', 'hidden': 'true'},
                                                            'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                        }],
                                                        'last': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'EventListView_i7gbrm0c17_dup_i7gcdg2m296'}
                                                        }, {
                                                            'comp': {
                                                                'name': 'VBox',
                                                                'hidden': 'true',
                                                                'items': [{
                                                                    'id': 'footerSpacer',
                                                                    'comp': {
                                                                        'name': 'VSpacer',
                                                                        'size': {'$expr': '$itemSpacing'}
                                                                    }
                                                                }, {
                                                                    'id': 'gutterLine',
                                                                    'comp': {
                                                                        'name': 'HorizontalLine',
                                                                        'hidden': 'true'
                                                                    },
                                                                    'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                                }]
                                                            }, 'id': 'def_7'
                                                        }, {
                                                            'comp': {'name': 'VSpacer', 'size': '9'},
                                                            'id': 'def_10'
                                                        }, {
                                                            'id': 'paginationNavigationBox',
                                                            'comp': {
                                                                'name': 'HBox',
                                                                'hidden': {'$expr': '$hidePagination'},
                                                                'box-align': 'center',
                                                                'items': [{
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_12'
                                                                }, {
                                                                    'value': '◄',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationPrev',
                                                                        'events': {'dom:click': 'prevPageClicked'}
                                                                    },
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_13'
                                                                }, {
                                                                    'value': {'$expr': 'String.concat($currentPage,\' / \',$maxPage)'},
                                                                    'comp': {'name': 'Label'},
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_14'
                                                                }, {
                                                                    'value': '►',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationNext',
                                                                        'events': {'dom:click': 'nextPageClicked'}
                                                                    },
                                                                    'id': 'def_15'
                                                                }, {
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_16'
                                                                }]
                                                            },
                                                            'layout': {'position': 'relative'}
                                                        }]
                                                    },
                                                    'css': {'min-width': '100%'}
                                                },
                                                'id': '$positionInParent'
                                            }
                                        }
                                    }
                                }, {
                                    'id': 'noItemsLabel',
                                    'value': 'There are no items in this list',
                                    'comp': {'name': 'Label', 'hidden': {'$expr': 'gt(String.length(this), 0)'}}
                                }],
                                'editorData': {'isPaginated': true}
                            },
                            'stylesheet': [{
                                '.paginationPrev, .paginationNext': {
                                    'padding': '0 5px',
                                    'display': 'inline-block',
                                    'font-size': '14px',
                                    'cursor': 'pointer',
                                    '-webkit-touch-callout': 'none',
                                    '-webkit-user-select': 'none',
                                    '-khtml-user-select': 'none',
                                    '-moz-user-select': 'none',
                                    '-ms-user-select': 'none',
                                    'user-select': 'none'
                                }
                            }],
                            'customizations': [{
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.hidePagination',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Split your list across multiple pages',
                                    'defaultVal': 'true',
                                    'trueVal': 'false',
                                    'spaceAfter': '10'
                                }
                            }, {
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.itemsPerPage',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '120',
                                    'minVal': '1',
                                    'label': 'Items Per Page',
                                    'spaceAfter': '20',
                                    'hiddenwhen': {
                                        'fieldId': 'paginatedlist',
                                        'key': 'comp.hidePagination',
                                        'value': 'true'
                                    }
                                }
                            }, {
                                'priority': 100,
                                'fieldId': 'vars',
                                'key': 'itemSpacing',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '300',
                                    'minVal': '0',
                                    'label': 'Item Spacing',
                                    'spaceAfter': '20'
                                }
                            }, {
                                'priority': 95,
                                'fieldId': 'gutterLine',
                                'key': 'comp.hidden',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Show item separator',
                                    'defaultVal': 'false',
                                    'trueVal': 'false'
                                }
                            }, {
                                'priority': 90,
                                'fieldId': 'gutterLine',
                                'key': 'comp.style',
                                'input': {
                                    'name': 'changestyle',
                                    'showAs': 'link',
                                    'label': 'Change Item Separator Style',
                                    'spaceAfter': '20'
                                }
                            }],
                            'id': 'i7gcdg2m'
                        }, {
                            'name': 'EventListView_i7gbrm0c17_dup_i7gcdg2m296',
                            'forType': 'Array',
                            'vars': {'itemSpacing': 40, 'paginationColor': 'color_15'},
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'paginatedlist',
                                    'comp': {
                                        'name': 'PaginatedList',
                                        'hidden': {'$expr': 'eq(Array.length(this), 0)'},
                                        'hidePagination': 'true',
                                        'itemsPerPage': '10',
                                        'templates': {
                                            'item': {
                                                'data': '$positionInParent',
                                                'comp': {
                                                    'name': 'SwitchBox',
                                                    'cases': {
                                                        'default': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'EventListView_i7gbrm0c17_dup_i7gcdg2m296'}
                                                        }, {
                                                            'id': 'footerSpacer',
                                                            'comp': {
                                                                'name': 'VSpacer',
                                                                'size': {'$expr': '$itemSpacing'}
                                                            }
                                                        }, {
                                                            'id': 'gutterLine',
                                                            'comp': {'name': 'HorizontalLine', 'hidden': 'true'},
                                                            'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                        }],
                                                        'last': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'EventListView_i7gbrm0c17_dup_i7gcdg2m296'}
                                                        }, {
                                                            'comp': {
                                                                'name': 'VBox',
                                                                'hidden': 'true',
                                                                'items': [{
                                                                    'id': 'footerSpacer',
                                                                    'comp': {
                                                                        'name': 'VSpacer',
                                                                        'size': {'$expr': '$itemSpacing'}
                                                                    }
                                                                }, {
                                                                    'id': 'gutterLine',
                                                                    'comp': {
                                                                        'name': 'HorizontalLine',
                                                                        'hidden': 'true'
                                                                    },
                                                                    'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                                }]
                                                            }, 'id': 'def_7'
                                                        }, {
                                                            'comp': {'name': 'VSpacer', 'size': '9'},
                                                            'id': 'def_10'
                                                        }, {
                                                            'id': 'paginationNavigationBox',
                                                            'comp': {
                                                                'name': 'HBox',
                                                                'hidden': {'$expr': '$hidePagination'},
                                                                'box-align': 'center',
                                                                'items': [{
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_12'
                                                                }, {
                                                                    'value': '◄',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationPrev',
                                                                        'events': {'dom:click': 'prevPageClicked'}
                                                                    },
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_13'
                                                                }, {
                                                                    'value': {'$expr': 'String.concat($currentPage,\' / \',$maxPage)'},
                                                                    'comp': {'name': 'Label'},
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_14'
                                                                }, {
                                                                    'value': '►',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationNext',
                                                                        'events': {'dom:click': 'nextPageClicked'}
                                                                    },
                                                                    'id': 'def_15'
                                                                }, {
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_16'
                                                                }]
                                                            },
                                                            'layout': {'position': 'relative'}
                                                        }]
                                                    },
                                                    'css': {'min-width': '100%'}
                                                },
                                                'id': '$positionInParent'
                                            }
                                        }
                                    }
                                }, {
                                    'id': 'noItemsLabel',
                                    'value': 'There are no items in this list',
                                    'comp': {'name': 'Label', 'hidden': {'$expr': 'gt(String.length(this), 0)'}}
                                }],
                                'editorData': {'isPaginated': true}
                            },
                            'stylesheet': [{
                                '.paginationPrev, .paginationNext': {
                                    'padding': '0 5px',
                                    'display': 'inline-block',
                                    'font-size': '14px',
                                    'cursor': 'pointer',
                                    '-webkit-touch-callout': 'none',
                                    '-webkit-user-select': 'none',
                                    '-khtml-user-select': 'none',
                                    '-moz-user-select': 'none',
                                    '-ms-user-select': 'none',
                                    'user-select': 'none'
                                }
                            }],
                            'customizations': [{
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.hidePagination',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Split your list across multiple pages',
                                    'defaultVal': 'true',
                                    'trueVal': 'false',
                                    'spaceAfter': '10'
                                }
                            }, {
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.itemsPerPage',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '120',
                                    'minVal': '1',
                                    'label': 'Items Per Page',
                                    'spaceAfter': '20',
                                    'hiddenwhen': {
                                        'fieldId': 'paginatedlist',
                                        'key': 'comp.hidePagination',
                                        'value': 'true'
                                    }
                                }
                            }, {
                                'priority': 100,
                                'fieldId': 'vars',
                                'key': 'itemSpacing',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '300',
                                    'minVal': '0',
                                    'label': 'Item Spacing',
                                    'spaceAfter': '20'
                                }
                            }, {
                                'priority': 95,
                                'fieldId': 'gutterLine',
                                'key': 'comp.hidden',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Show item separator',
                                    'defaultVal': 'false',
                                    'trueVal': 'false'
                                }
                            }, {
                                'priority': 90,
                                'fieldId': 'gutterLine',
                                'key': 'comp.style',
                                'input': {
                                    'name': 'changestyle',
                                    'showAs': 'link',
                                    'label': 'Change Item Separator Style',
                                    'spaceAfter': '20'
                                }
                            }],
                            'id': 'i7gcdg2m',
                            'format': 'Mobile'
                        }, {
                            'name': 'EventListView_i7gbrm0c17_dup_i7gcdg2m296',
                            'forType': 'EventList_i7gbrm0b16',
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'Top',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Top section',
                                            'alignment': 'left',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 0}
                                        },
                                        'items': [{
                                            'id': 'fld_i7gc7wzz234',
                                            'data': 'i7gc7wyf',
                                            'comp': {
                                                'name': 'Field',
                                                'width': 280,
                                                'height': '220',
                                                'items': [{
                                                    'id': 'fld_i7gc7wzz234_proxy',
                                                    'data': 'i7gc7wyf',
                                                    'comp': {'name': 'Video'},
                                                    'layout': {'position': 'relative'}
                                                }],
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 0,
                                                    'after': 10,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'box-align': 'center',
                                                'hidden': true
                                            }
                                        }, {
                                            'id': 'fld_i7gbz8lj147',
                                            'data': 'i7gbz8ku',
                                            'comp': {
                                                'name': 'Field',
                                                'width': 280,
                                                'height': 220,
                                                'items': [{
                                                    'id': 'fld_i7gbz8lj147_proxy',
                                                    'data': 'i7gbz8ku',
                                                    'comp': {'name': 'Image', 'style': 'wp2'}
                                                }],
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 0,
                                                    'after': 10,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'box-align': 'center',
                                                'hidden': true
                                            }
                                        }, {
                                            'experiment': 'NewListFields',
                                            'id': 'fld_i9bdxglv100',
                                            'data': 'i946vvwv',
                                            'comp': {
                                                'name': 'TextField',
                                                'width': '200',
                                                'width-mode': 'auto',
                                                'min-lines': '0',
                                                'max-lines': '0',
                                                'items': [{
                                                    'id': 'fld_i9bdxglv100_proxy',
                                                    'data': 'i946vvwv',
                                                    'comp': {
                                                        'name': 'Date',
                                                        'color': 'color_14',
                                                        'format': 'mmm dd, yyyy'
                                                    },
                                                    'layout': {'text-align': 'center'}
                                                }],
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 0,
                                                    'after': 3,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'hidden': true
                                            }
                                        }]
                                    },
                                    'layout': {'min-width': 0}
                                }, {
                                    'id': 'topSpacer',
                                    'comp': {'name': 'VSpacer', 'size': 0}
                                }, {
                                    'comp': {
                                        'name': 'VBox',
                                        'items': [{
                                            'id': 'def_3_0',
                                            'comp': {
                                                'name': 'HBox',
                                                'orientation': 'horizontal',
                                                'items': [{
                                                    'id': 'Left',
                                                    'comp': {
                                                        'name': 'FieldBox',
                                                        'orientation': 'vertical',
                                                        'items': [],
                                                        'editorData': {
                                                            'labelPosition': 'none',
                                                            'alignment': 'left',
                                                            'displayName': 'Left section',
                                                            'spacers': {
                                                                'before': 0,
                                                                'after': 3,
                                                                'xax-before': 0,
                                                                'xax-after': 0
                                                            }
                                                        }
                                                    },
                                                    'layout': {'spacerAfter': 0, 'min-width': 0}
                                                }, {
                                                    'id': 'leftToCenterSpacer',
                                                    'comp': {'name': 'HSpacer', 'size': 0}
                                                }, {
                                                    'id': 'Center',
                                                    'comp': {
                                                        'name': 'FieldBox',
                                                        'orientation': 'vertical',
                                                        'pack': 'center',
                                                        'items': [{
                                                            'id': 'fld_hfxscm3b',
                                                            'data': 'title',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': 240,
                                                                'width-mode': 'auto',
                                                                'min-lines': '0',
                                                                'max-lines': '0',
                                                                'items': [{
                                                                    'id': 'fld_hfxscm3b_proxy',
                                                                    'data': 'title',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'style': 'Body L',
                                                                        'bold': false,
                                                                        'italic': false,
                                                                        'underline': false,
                                                                        'color': 'color_14'
                                                                    },
                                                                    'layout': {'text-align': 'center'}
                                                                }],
                                                                'labelPosition': 'none',
                                                                'spacers': {
                                                                    'before': 0,
                                                                    'after': 0,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                },
                                                                'hidden': false
                                                            }
                                                        }, {
                                                            'id': 'fld_hfwrbaju',
                                                            'data': 'Strng_sTxt1',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': 240,
                                                                'width-mode': 'auto',
                                                                'min-lines': '0',
                                                                'max-lines': '0',
                                                                'items': [{
                                                                    'id': 'fld_hfwrbaju_proxy',
                                                                    'data': 'Strng_sTxt1',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'style': 'Body M',
                                                                        'color': 'color_14'
                                                                    },
                                                                    'layout': {'text-align': 'center'}
                                                                }],
                                                                'labelPosition': 'none',
                                                                'spacers': {
                                                                    'before': 0,
                                                                    'after': 0,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                },
                                                                'hidden': true
                                                            }
                                                        }, {
                                                            'id': 'fld_i9be4ebl45',
                                                            'data': 'i946tse6',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': '200',
                                                                'width-mode': 'auto',
                                                                'min-lines': '0',
                                                                'max-lines': '0',
                                                                'items': [{
                                                                    'id': 'fld_i9be4ebl45_proxy',
                                                                    'data': 'i946tse6',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'color': 'color_14',
                                                                        'style': 'Heading S'
                                                                    },
                                                                    'layout': {'text-align': 'center'}
                                                                }],
                                                                'hidden': false,
                                                                'labelPosition': 'none',
                                                                'spacers': {
                                                                    'before': 4,
                                                                    'after': 0,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                }
                                                            },
                                                            'experiment': 'NewListFields'
                                                        }, {
                                                            'id': 'fld_hfxsg737',
                                                            'data': 'wxRchTxt_sTxt2',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': 240,
                                                                'width-mode': 'auto',
                                                                'min-lines': '0',
                                                                'max-lines': '0',
                                                                'items': [{
                                                                    'id': 'fld_hfxsg737_proxy',
                                                                    'data': 'wxRchTxt_sTxt2',
                                                                    'comp': {'name': 'Label', 'color': 'color_14'},
                                                                    'layout': {'text-align': 'center'}
                                                                }],
                                                                'hidden': true,
                                                                'labelPosition': 'none',
                                                                'spacers': {
                                                                    'before': 8,
                                                                    'after': 0,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                }
                                                            }
                                                        }],
                                                        'editorData': {
                                                            'labelPosition': 'none',
                                                            'alignment': 'left',
                                                            'displayName': 'Center section',
                                                            'spacers': {
                                                                'before': 0,
                                                                'after': 3,
                                                                'xax-before': 0,
                                                                'xax-after': 0
                                                            }
                                                        }
                                                    },
                                                    'layout': {'box-flex': 1, 'min-width': 200}
                                                }],
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'alignment': 'left',
                                                    'displayName': 'def_3_0',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 3,
                                                        'xax-after': 0
                                                    }
                                                }
                                            },
                                            'layout': {'min-width': 0}
                                        }, {
                                            'id': 'def_3_1',
                                            'comp': {
                                                'name': 'HBox',
                                                'orientation': 'horizontal',
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'displayName': 'def_3_1',
                                                    'alignment': 'left',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 3,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'items': [{
                                                    'id': 'centerToRightSpacer',
                                                    'comp': {'name': 'HSpacer', 'size': 0}
                                                }, {
                                                    'id': 'Right',
                                                    'comp': {
                                                        'name': 'FieldBox',
                                                        'orientation': 'vertical',
                                                        'pack': 'center',
                                                        'editorData': {
                                                            'labelPosition': 'none',
                                                            'displayName': 'Right section',
                                                            'alignment': 'left',
                                                            'spacers': {
                                                                'before': 0,
                                                                'after': 3,
                                                                'xax-before': 0,
                                                                'xax-after': 0
                                                            }
                                                        }
                                                    },
                                                    'layout': {'min-width': 0}
                                                }]
                                            },
                                            'layout': {'min-width': 0}
                                        }],
                                        'orientation': 'vertical'
                                    }, 'id': 'def_3'
                                }, {
                                    'id': 'Bottom',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Bottom section',
                                            'alignment': 'left',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 0}
                                        },
                                        'items': [{
                                            'id': 'fld_i7gc0zgq202',
                                            'data': 'i7gc0zfm',
                                            'comp': {
                                                'name': 'Field',
                                                'width': 280,
                                                'height': 44,
                                                'items': [{
                                                    'id': 'fld_i7gc0zgq202_proxy',
                                                    'data': 'i7gc0zfm',
                                                    'comp': {'name': 'Button2', 'style': 'b1', 'align': 'center'},
                                                    'layout': {'position': 'relative'}
                                                }],
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 18,
                                                    'after': 0,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'box-align': 'center',
                                                'hidden': true
                                            }
                                        }]
                                    },
                                    'layout': {'box-flex-pack': 'start', 'min-width': 0}
                                }, {'id': 'bottomSpacer', 'comp': {'name': 'VSpacer', 'size': 0}}]
                            },
                            'id': 'def_0',
                            'editorData': {'originalWidth': 270, 'wasChanged': true},
                            'format': 'Mobile'
                        }, {
                            'name': 'EventListView_i7gbrm0c17_dup_i7gcdg2m296',
                            'forType': 'EventList_i7gbrm0b16',
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'Top',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Top section',
                                            'alignment': 'left',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 3}
                                        },
                                        'items': [{
                                            'id': 'fld_i7gc7wzz234',
                                            'data': 'i7gc7wyf',
                                            'comp': {
                                                'name': 'Field',
                                                'width': 330,
                                                'height': '220',
                                                'items': [{
                                                    'id': 'fld_i7gc7wzz234_proxy',
                                                    'data': 'i7gc7wyf',
                                                    'comp': {'name': 'Video'},
                                                    'layout': {'position': 'relative'}
                                                }],
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 0,
                                                    'after': 10,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'box-align': 'center',
                                                'hidden': true
                                            }
                                        }, {
                                            'id': 'fld_i7gbz8lj147',
                                            'data': 'i7gbz8ku',
                                            'comp': {
                                                'name': 'Field',
                                                'width': 330,
                                                'height': 229,
                                                'items': [{
                                                    'id': 'fld_i7gbz8lj147_proxy',
                                                    'data': 'i7gbz8ku',
                                                    'comp': {'name': 'Image', 'style': 'wp2'}
                                                }],
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 0,
                                                    'after': 10,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'box-align': 'center',
                                                'hidden': true
                                            }
                                        }]
                                    },
                                    'layout': {'min-width': 0}
                                }, {
                                    'id': 'topSpacer',
                                    'comp': {'name': 'VSpacer', 'size': '0'}
                                }, {
                                    'comp': {
                                        'name': 'HBox',
                                        'items': [{
                                            'id': 'Left',
                                            'comp': {
                                                'name': 'FieldBox',
                                                'orientation': 'vertical',
                                                'items': [],
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'alignment': 'left',
                                                    'displayName': 'Left section',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 0,
                                                        'xax-after': 10
                                                    }
                                                }
                                            },
                                            'layout': {'spacerAfter': 0, 'min-width': 0}
                                        }, {
                                            'id': 'leftToCenterSpacer',
                                            'comp': {'name': 'HSpacer', 'size': '0'}
                                        }, {
                                            'id': 'Center',
                                            'comp': {
                                                'name': 'FieldBox',
                                                'orientation': 'vertical',
                                                'pack': 'center',
                                                'items': [{
                                                    'id': 'fld_i946vvyd133',
                                                    'data': 'i946vvwv',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0',
                                                        'items': [{
                                                            'id': 'fld_i946vvyd133_proxy',
                                                            'data': 'i946vvwv',
                                                            'comp': {
                                                                'name': 'Date',
                                                                'format': 'mmm dd, yyyy',
                                                                'color': 'color_14'
                                                            },
                                                            'layout': {'text-align': 'center'}
                                                        }],
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 3,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'hidden': true
                                                    },
                                                    'experiment': 'NewListFields'
                                                }, {
                                                    'id': 'fld_hfxscm3b',
                                                    'data': 'title',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0',
                                                        'items': [{
                                                            'id': 'fld_hfxscm3b_proxy',
                                                            'data': 'title',
                                                            'comp': {
                                                                'name': 'Label',
                                                                'color': 'color_14',
                                                                'style': 'Body L',
                                                                'bold': false,
                                                                'italic': false,
                                                                'underline': false
                                                            },
                                                            'layout': {'text-align': 'center'}
                                                        }],
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 0,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'hidden': false
                                                    }
                                                }, {
                                                    'id': 'fld_hfwrbaju',
                                                    'data': 'Strng_sTxt1',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0',
                                                        'items': [{
                                                            'id': 'fld_hfwrbaju_proxy',
                                                            'data': 'Strng_sTxt1',
                                                            'comp': {
                                                                'name': 'Label',
                                                                'style': 'Body M',
                                                                'color': 'color_14'
                                                            },
                                                            'layout': {'text-align': 'center'}
                                                        }],
                                                        'hidden': true,
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 0,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        }
                                                    }
                                                }, {
                                                    'id': 'fld_i946tsey100',
                                                    'data': 'i946tse6',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0',
                                                        'items': [{
                                                            'id': 'fld_i946tsey100_proxy',
                                                            'data': 'i946tse6',
                                                            'comp': {
                                                                'name': 'Label',
                                                                'style': 'Heading S',
                                                                'color': 'color_14'
                                                            },
                                                            'layout': {'text-align': 'center'}
                                                        }],
                                                        'hidden': false,
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 3,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        }
                                                    },
                                                    'experiment': 'NewListFields'
                                                }, {
                                                    'id': 'fld_hfxsg737',
                                                    'data': 'wxRchTxt_sTxt2',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0',
                                                        'items': [{
                                                            'id': 'fld_hfxsg737_proxy',
                                                            'data': 'wxRchTxt_sTxt2',
                                                            'comp': {'name': 'Label', 'color': 'color_14'},
                                                            'layout': {'text-align': 'center'}
                                                        }],
                                                        'hidden': true,
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 8,
                                                            'after': 0,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        }
                                                    }
                                                }],
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'alignment': 'left',
                                                    'displayName': 'Center section',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 0,
                                                        'xax-after': 0
                                                    }
                                                }
                                            },
                                            'layout': {'box-flex': 1, 'min-width': 200}
                                        }, {
                                            'id': 'centerToRightSpacer',
                                            'comp': {'name': 'HSpacer', 'size': '0'}
                                        }, {
                                            'id': 'Right',
                                            'comp': {
                                                'name': 'FieldBox',
                                                'orientation': 'vertical',
                                                'pack': 'center',
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'displayName': 'Right section',
                                                    'alignment': 'left',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 3,
                                                        'xax-after': 0
                                                    }
                                                }
                                            },
                                            'layout': {'min-width': 0}
                                        }]
                                    }, 'id': 'def_3'
                                }, {
                                    'id': 'Bottom',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Bottom section',
                                            'alignment': 'left',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 3}
                                        },
                                        'items': [{
                                            'id': 'fld_i7gc0zgq202',
                                            'data': 'i7gc0zfm',
                                            'comp': {
                                                'name': 'Field',
                                                'width': 160,
                                                'height': '40',
                                                'items': [{
                                                    'id': 'fld_i7gc0zgq202_proxy',
                                                    'data': 'i7gc0zfm',
                                                    'comp': {'name': 'Button2', 'style': 'b1', 'align': 'center'},
                                                    'layout': {'position': 'relative'}
                                                }],
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 18,
                                                    'after': 0,
                                                    'xax-before': 0,
                                                    'xax-after': 3
                                                },
                                                'box-align': 'center',
                                                'hidden': true
                                            }
                                        }]
                                    },
                                    'layout': {'box-flex-pack': 'start', 'min-width': 0}
                                }, {'id': 'bottomSpacer', 'comp': {'name': 'VSpacer', 'size': '0'}}]
                            },
                            'id': 'def_0',
                            'editorData': {'wasChanged': true}
                        }],
                        'items': [{
                            'Strng_sTxt1': i18n.translate('i7gcdg3i.structure.custom.template.items.0.Strng_sTxt1'),
                            'title': i18n.translate('i7gcdg3i.structure.custom.template.items.0.title'),
                            'wxRchTxt_sTxt2': {
                                'version': 1,
                                '_type': 'wix:RichText',
                                'links': [],
                                'text': i18n.translate('i7gcdg3i.structure.custom.template.items.0.wxRchTxt_sTxt2')
                            },
                            'links': {
                                '_type': 'wix:Map',
                                'i7gc0zfm': {
                                    'type': 'ExternalLink',
                                    'url': 'http://www.wix.com',
                                    'target': '_blank'
                                }
                            },
                            'i7gbz8ku': {
                                '_type': 'wix:Image',
                                'title': '',
                                'src': 'c7ad2b64020342d0ae1a1feace158fe9.jpg',
                                'width': 1920,
                                'height': 1280,
                                'alt': ''
                            },
                            'i7gc0zfm': i18n.translate('i7gcdg3i.structure.custom.template.items.0.i7gc0zfm'),
                            'i7gc7wyf': {
                                '_type': 'wix:Video',
                                'videoId': 'z1pSAwjC4JI',
                                'videoType': 'YOUTUBE',
                                'thumbnail': ''
                            },
                            'i946tse6': i18n.translate('i7gcdg3i.structure.custom.template.items.0.i946tse6'),
                            'i946vvwv': {'_type': 'wix:Date', 'iso': '2023-01-03T00:00:00.000Z'}
                        }, {
                            'Strng_sTxt1': i18n.translate('i7gcdg3i.structure.custom.template.items.1.Strng_sTxt1'),
                            'title': i18n.translate('i7gcdg3i.structure.custom.template.items.1.title'),
                            'wxRchTxt_sTxt2': {
                                'version': 1,
                                '_type': 'wix:RichText',
                                'links': [],
                                'text': i18n.translate('i7gcdg3i.structure.custom.template.items.1.wxRchTxt_sTxt2')
                            },
                            'links': {
                                '_type': 'wix:Map',
                                'i7gc0zfm': {
                                    'type': 'ExternalLink',
                                    'url': 'http://www.wix.com',
                                    'target': '_blank'
                                }
                            },
                            'i7gbz8ku': {
                                '_type': 'wix:Image',
                                'title': '',
                                'src': 'd967ba93f0314c78924edc8a8c8cfa15.jpg',
                                'width': 1920,
                                'height': 1275,
                                'alt': ''
                            },
                            'i7gc0zfm': i18n.translate('i7gcdg3i.structure.custom.template.items.1.i7gc0zfm'),
                            'i7gc7wyf': {
                                '_type': 'wix:Video',
                                'videoId': 'f36EEfI0yr8',
                                'videoType': 'YOUTUBE',
                                'thumbnail': ''
                            },
                            'i946tse6': i18n.translate('i7gcdg3i.structure.custom.template.items.1.i946tse6'),
                            'i946vvwv': {'_type': 'wix:Date', 'iso': '2023-02-23T00:00:00.000Z'}
                        }, {
                            'Strng_sTxt1': i18n.translate('i7gcdg3i.structure.custom.template.items.2.Strng_sTxt1'),
                            'title': i18n.translate('i7gcdg3i.structure.custom.template.items.2.title'),
                            'wxRchTxt_sTxt2': {
                                'version': 1,
                                '_type': 'wix:RichText',
                                'links': [],
                                'text': i18n.translate('i7gcdg3i.structure.custom.template.items.2.wxRchTxt_sTxt2')
                            },
                            'links': {
                                '_type': 'wix:Map',
                                'i7gc0zfm': {
                                    'type': 'ExternalLink',
                                    'url': 'http://www.wix.com',
                                    'target': '_blank'
                                }
                            },
                            'i7gbz8ku': {
                                '_type': 'wix:Image',
                                'title': '',
                                'src': 'bef2ef0f0a3343aba2fa7e746baa781c.jpg',
                                'width': 4434,
                                'height': 3729,
                                'alt': ''
                            },
                            'i7gc0zfm': i18n.translate('i7gcdg3i.structure.custom.template.items.2.i7gc0zfm'),
                            'i7gc7wyf': {
                                '_type': 'wix:Video',
                                'videoId': 'wGOTBSlYkzU',
                                'videoType': 'YOUTUBE',
                                'thumbnail': ''
                            },
                            'i946tse6': i18n.translate('i7gcdg3i.structure.custom.template.items.2.i946tse6'),
                            'i946vvwv': {'_type': 'wix:Date', 'iso': '2023-05-28T00:00:00.000Z'}
                        }],
                        'displayName': i18n.translate('i7gcdg3i.structure.custom.template.displayName')
                    }
                },
                'id': 'i7gcdg3i'
            },
            'preset': {
                'rect': {'width': 162, 'height': 196, 'x': 0, 'y': 1231.25},
                'label': 'i7gcdg3i.structure.custom.template.displayName',
                'tags': null
            }
        }, {
            'id': 'Lists_List_Products_And_Services_11',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.viewer.skins.AppPartSkin',
                'layout': {
                    'width': 562,
                    'height': 235,
                    'x': 709,
                    'y': 5019,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wixapps.integration.components.AppPart2',
                'data': {
                    'type': 'AppBuilderComponent',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'appInnerID': '2',
                    'appPartName': 'appparti7g48h9q12'
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'id': 'app1',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {'groups': {}, 'properties': {'rd': '7px'}, 'propertiesSource': {'rd': 'value'}},
                    'componentClassName': '',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'system',
                    'skin': 'wysiwyg.viewer.skins.AppPartSkin'
                },
                'custom': {
                    'template': {
                        'type': {
                            'displayName': i18n.translate('i7g48h9x.structure.custom.template.type.displayName'),
                            'version': 0,
                            'baseTypes': [],
                            'fields': [{
                                'displayName': i18n.translate('i7g48h9x.structure.custom.template.type.fields.0.displayName'),
                                'name': 'title',
                                'searchable': false,
                                'type': 'String',
                                'defaultValue': i18n.translate('i7g48h9x.structure.custom.template.type.fields.0.defaultValue'),
                                'computed': false
                            }, {
                                'searchable': false,
                                'displayName': i18n.translate('i7g48h9x.structure.custom.template.type.fields.1.displayName'),
                                'type': 'String',
                                'name': 'price',
                                'computed': false,
                                'defaultValue': i18n.translate('i7g48h9x.structure.custom.template.type.fields.1.defaultValue')
                            }, {
                                'name': 'i947gn7s',
                                'type': 'wix:Date',
                                'searchable': false,
                                'displayName': i18n.translate('i7g48h9x.structure.custom.template.type.fields.2.displayName'),
                                'computed': false,
                                'defaultValue': {'iso': '2020-01-01T00:00:00.000Z', '_type': 'wix:Date'}
                            }, {
                                'displayName': i18n.translate('i7g48h9x.structure.custom.template.type.fields.3.displayName'),
                                'defaultValue': {
                                    'version': 1,
                                    '_type': 'wix:RichText',
                                    'links': [],
                                    'text': i18n.translate('i7g48h9x.structure.custom.template.type.fields.3.defaultValue')
                                },
                                'type': 'wix:RichText',
                                'searchable': false,
                                'name': 'i947bhyp',
                                'computed': false
                            }, {
                                'metadata': {'showAsHint': 'AsPrice'},
                                'displayName': i18n.translate('i7g48h9x.structure.custom.template.type.fields.4.displayName'),
                                'searchable': false,
                                'type': 'String',
                                'defaultValue': i18n.translate('i7g48h9x.structure.custom.template.type.fields.4.defaultValue'),
                                'name': 'i9479gl7',
                                'computed': false
                            }, {
                                'name': 'i7elyjd2',
                                'type': 'wix:Image',
                                'defaultValue': {
                                    'height': 1276,
                                    'src': 'c8381ce0e7a94ad09ceaada2fc64081d.jpg',
                                    'width': 1920,
                                    '_type': 'wix:Image',
                                    'title': ''
                                },
                                'displayName': i18n.translate('i7g48h9x.structure.custom.template.type.fields.5.displayName'),
                                'searchable': false,
                                'computed': false
                            }, {
                                'defaultValue': {
                                    'videoId': 'f36EEfI0yr8',
                                    '_type': 'wix:Video',
                                    'videoType': 'YOUTUBE'
                                },
                                'name': 'i7em0l5v',
                                'type': 'wix:Video',
                                'searchable': false,
                                'displayName': i18n.translate('i7g48h9x.structure.custom.template.type.fields.6.displayName'),
                                'computed': false
                            }, {
                                'name': 'i9bfdsxb',
                                'defaultValue': i18n.translate('i7g48h9x.structure.custom.template.type.fields.7.defaultValue'),
                                'searchable': false,
                                'metadata': {'showAsHint': 'AsButton'},
                                'type': 'String',
                                'displayName': i18n.translate('i7g48h9x.structure.custom.template.type.fields.7.displayName'),
                                'computed': false
                            }, {
                                'name': 'links',
                                'type': 'wix:Map',
                                'searchable': false,
                                'displayName': '',
                                'defaultValue': {'_type': 'wix:Map'},
                                'computed': false
                            }],
                            'name': 'PriceList_i6w1i8b886'
                        },
                        'views': [{
                            'name': 'PriceListView_i6w1i8b887_dup_i7g48h8s233',
                            'forType': 'Array',
                            'vars': {'itemSpacing': 40, 'paginationColor': 'color_15'},
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'paginatedlist',
                                    'comp': {
                                        'name': 'PaginatedList',
                                        'hidden': {'$expr': 'eq(Array.length(this), 0)'},
                                        'hidePagination': 'true',
                                        'itemsPerPage': '10',
                                        'templates': {
                                            'item': {
                                                'data': '$positionInParent',
                                                'comp': {
                                                    'name': 'SwitchBox',
                                                    'cases': {
                                                        'default': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'PriceListView_i6w1i8b887_dup_i7g48h8s233'}
                                                        }, {
                                                            'id': 'footerSpacer',
                                                            'comp': {
                                                                'name': 'VSpacer',
                                                                'size': {'$expr': '$itemSpacing'}
                                                            }
                                                        }, {
                                                            'id': 'gutterLine',
                                                            'comp': {'name': 'HorizontalLine', 'hidden': 'true'},
                                                            'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                        }],
                                                        'last': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'PriceListView_i6w1i8b887_dup_i7g48h8s233'}
                                                        }, {
                                                            'comp': {
                                                                'name': 'VBox',
                                                                'hidden': 'true',
                                                                'items': [{
                                                                    'id': 'footerSpacer',
                                                                    'comp': {
                                                                        'name': 'VSpacer',
                                                                        'size': {'$expr': '$itemSpacing'}
                                                                    }
                                                                }, {
                                                                    'id': 'gutterLine',
                                                                    'comp': {
                                                                        'name': 'HorizontalLine',
                                                                        'hidden': 'true'
                                                                    },
                                                                    'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                                }]
                                                            }, 'id': 'def_7'
                                                        }, {
                                                            'comp': {'name': 'VSpacer', 'size': '9'},
                                                            'id': 'def_10'
                                                        }, {
                                                            'id': 'paginationNavigationBox',
                                                            'comp': {
                                                                'name': 'HBox',
                                                                'hidden': {'$expr': '$hidePagination'},
                                                                'box-align': 'center',
                                                                'items': [{
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_12'
                                                                }, {
                                                                    'value': '◄',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationPrev',
                                                                        'events': {'dom:click': 'prevPageClicked'}
                                                                    },
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_13'
                                                                }, {
                                                                    'value': {'$expr': 'String.concat($currentPage,\' / \',$maxPage)'},
                                                                    'comp': {'name': 'Label'},
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_14'
                                                                }, {
                                                                    'value': '►',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationNext',
                                                                        'events': {'dom:click': 'nextPageClicked'}
                                                                    },
                                                                    'id': 'def_15'
                                                                }, {
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_16'
                                                                }]
                                                            },
                                                            'layout': {'position': 'relative'}
                                                        }]
                                                    },
                                                    'css': {'min-width': '100%'}
                                                },
                                                'id': '$positionInParent'
                                            }
                                        }
                                    }
                                }, {
                                    'id': 'noItemsLabel',
                                    'value': 'There are no items in this list',
                                    'comp': {'name': 'Label', 'hidden': {'$expr': 'gt(String.length(this), 0)'}}
                                }],
                                'editorData': {'isPaginated': true}
                            },
                            'stylesheet': [{
                                '.paginationPrev, .paginationNext': {
                                    'padding': '0 5px',
                                    'display': 'inline-block',
                                    'font-size': '14px',
                                    'cursor': 'pointer',
                                    '-webkit-touch-callout': 'none',
                                    '-webkit-user-select': 'none',
                                    '-khtml-user-select': 'none',
                                    '-moz-user-select': 'none',
                                    '-ms-user-select': 'none',
                                    'user-select': 'none'
                                }
                            }],
                            'customizations': [{
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.hidePagination',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Split your list across multiple pages',
                                    'defaultVal': 'true',
                                    'trueVal': 'false',
                                    'spaceAfter': '10'
                                }
                            }, {
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.itemsPerPage',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '120',
                                    'minVal': '1',
                                    'label': 'Items Per Page',
                                    'spaceAfter': '20',
                                    'hiddenwhen': {
                                        'fieldId': 'paginatedlist',
                                        'key': 'comp.hidePagination',
                                        'value': 'true'
                                    }
                                }
                            }, {
                                'priority': 100,
                                'fieldId': 'vars',
                                'key': 'itemSpacing',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '300',
                                    'minVal': '0',
                                    'label': 'Item Spacing',
                                    'spaceAfter': '20'
                                }
                            }, {
                                'priority': 95,
                                'fieldId': 'gutterLine',
                                'key': 'comp.hidden',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Show item separator',
                                    'defaultVal': 'false',
                                    'trueVal': 'false'
                                }
                            }, {
                                'priority': 90,
                                'fieldId': 'gutterLine',
                                'key': 'comp.style',
                                'input': {
                                    'name': 'changestyle',
                                    'showAs': 'link',
                                    'label': 'Change Item Separator Style',
                                    'spaceAfter': '20'
                                }
                            }],
                            'id': 'i7g48h8s',
                            'format': 'Mobile'
                        }, {
                            'name': 'PriceListView_i6w1i8b887_dup_i7g48h8s233',
                            'forType': 'Array',
                            'vars': {'itemSpacing': 30, 'paginationColor': 'color_15'},
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'paginatedlist',
                                    'comp': {
                                        'name': 'PaginatedList',
                                        'hidden': {'$expr': 'eq(Array.length(this), 0)'},
                                        'hidePagination': 'true',
                                        'itemsPerPage': '10',
                                        'templates': {
                                            'item': {
                                                'data': '$positionInParent',
                                                'comp': {
                                                    'name': 'SwitchBox',
                                                    'cases': {
                                                        'default': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'PriceListView_i6w1i8b887_dup_i7g48h8s233'}
                                                        }, {
                                                            'id': 'footerSpacer',
                                                            'comp': {
                                                                'name': 'VSpacer',
                                                                'size': {'$expr': '$itemSpacing'}
                                                            }
                                                        }, {
                                                            'id': 'gutterLine',
                                                            'comp': {'name': 'HorizontalLine', 'hidden': 'true'},
                                                            'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                        }],
                                                        'last': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'PriceListView_i6w1i8b887_dup_i7g48h8s233'}
                                                        }, {
                                                            'comp': {
                                                                'name': 'VBox',
                                                                'hidden': 'true',
                                                                'items': [{
                                                                    'id': 'footerSpacer',
                                                                    'comp': {
                                                                        'name': 'VSpacer',
                                                                        'size': {'$expr': '$itemSpacing'}
                                                                    }
                                                                }, {
                                                                    'id': 'gutterLine',
                                                                    'comp': {
                                                                        'name': 'HorizontalLine',
                                                                        'hidden': 'true'
                                                                    },
                                                                    'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                                }]
                                                            }, 'id': 'def_7'
                                                        }, {
                                                            'comp': {'name': 'VSpacer', 'size': '9'},
                                                            'id': 'def_10'
                                                        }, {
                                                            'id': 'paginationNavigationBox',
                                                            'comp': {
                                                                'name': 'HBox',
                                                                'hidden': {'$expr': '$hidePagination'},
                                                                'box-align': 'center',
                                                                'items': [{
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_12'
                                                                }, {
                                                                    'value': '◄',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationPrev',
                                                                        'events': {'dom:click': 'prevPageClicked'}
                                                                    },
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_13'
                                                                }, {
                                                                    'value': {'$expr': 'String.concat($currentPage,\' / \',$maxPage)'},
                                                                    'comp': {'name': 'Label'},
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_14'
                                                                }, {
                                                                    'value': '►',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationNext',
                                                                        'events': {'dom:click': 'nextPageClicked'}
                                                                    },
                                                                    'id': 'def_15'
                                                                }, {
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_16'
                                                                }]
                                                            },
                                                            'layout': {'position': 'relative'}
                                                        }]
                                                    },
                                                    'css': {'min-width': '100%'}
                                                },
                                                'id': '$positionInParent'
                                            }
                                        }
                                    }
                                }, {
                                    'id': 'noItemsLabel',
                                    'value': 'There are no items in this list',
                                    'comp': {'name': 'Label', 'hidden': {'$expr': 'gt(String.length(this), 0)'}}
                                }],
                                'editorData': {'isPaginated': true}
                            },
                            'stylesheet': [{
                                '.paginationPrev, .paginationNext': {
                                    'padding': '0 5px',
                                    'display': 'inline-block',
                                    'font-size': '14px',
                                    'cursor': 'pointer',
                                    '-webkit-touch-callout': 'none',
                                    '-webkit-user-select': 'none',
                                    '-khtml-user-select': 'none',
                                    '-moz-user-select': 'none',
                                    '-ms-user-select': 'none',
                                    'user-select': 'none'
                                }
                            }],
                            'customizations': [{
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.hidePagination',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Split your list across multiple pages',
                                    'defaultVal': 'true',
                                    'trueVal': 'false',
                                    'spaceAfter': '10'
                                }
                            }, {
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.itemsPerPage',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '120',
                                    'minVal': '1',
                                    'label': 'Items Per Page',
                                    'spaceAfter': '20',
                                    'hiddenwhen': {
                                        'fieldId': 'paginatedlist',
                                        'key': 'comp.hidePagination',
                                        'value': 'true'
                                    }
                                }
                            }, {
                                'priority': 100,
                                'fieldId': 'vars',
                                'key': 'itemSpacing',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '300',
                                    'minVal': '0',
                                    'label': 'Item Spacing',
                                    'spaceAfter': '20'
                                }
                            }, {
                                'priority': 95,
                                'fieldId': 'gutterLine',
                                'key': 'comp.hidden',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Show item separator',
                                    'defaultVal': 'false',
                                    'trueVal': 'false'
                                }
                            }, {
                                'priority': 90,
                                'fieldId': 'gutterLine',
                                'key': 'comp.style',
                                'input': {
                                    'name': 'changestyle',
                                    'showAs': 'link',
                                    'label': 'Change Item Separator Style',
                                    'spaceAfter': '20'
                                }
                            }],
                            'id': 'i7g48h8s'
                        }, {
                            'name': 'PriceListView_i6w1i8b887_dup_i7g48h8s233',
                            'forType': 'PriceList_i6w1i8b886',
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'Top',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Top section',
                                            'alignment': 'left',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 0}
                                        },
                                        'items': [{
                                            'id': 'fld_i7em0l6q192',
                                            'data': 'i7em0l5v',
                                            'comp': {
                                                'name': 'Field',
                                                'width': 280,
                                                'height': '220',
                                                'items': [{
                                                    'id': 'fld_i7em0l6q192_proxy',
                                                    'data': 'i7em0l5v',
                                                    'comp': {'name': 'Video'},
                                                    'layout': {'position': 'relative'}
                                                }],
                                                'hidden': true,
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 0,
                                                    'after': 12,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'box-align': 'left'
                                            }
                                        }, {
                                            'id': 'fld_i7elyjdv156',
                                            'data': 'i7elyjd2',
                                            'comp': {
                                                'name': 'Field',
                                                'width': 280,
                                                'height': 220,
                                                'items': [{
                                                    'id': 'fld_i7elyjdv156_proxy',
                                                    'data': 'i7elyjd2',
                                                    'comp': {'name': 'Image', 'style': 'wp2'}
                                                }],
                                                'hidden': true,
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 0,
                                                    'after': 12,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'box-align': 'left'
                                            }
                                        }, {
                                            'id': 'fld_i9bfazwh108',
                                            'data': 'i947gn7s',
                                            'comp': {
                                                'name': 'TextField',
                                                'width': '200',
                                                'width-mode': 'auto',
                                                'min-lines': '0',
                                                'max-lines': '0',
                                                'items': [{
                                                    'id': 'fld_i9bfazwh108_proxy',
                                                    'data': 'i947gn7s',
                                                    'comp': {
                                                        'name': 'Date',
                                                        'format': 'mmmm dd, yyyy',
                                                        'color': 'color_14'
                                                    },
                                                    'layout': {'text-align': 'left'}
                                                }],
                                                'hidden': true,
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 0,
                                                    'after': 3,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                }
                                            },
                                            'experiment': 'NewListFields'
                                        }]
                                    },
                                    'layout': {'min-width': 0}
                                }, {
                                    'id': 'topSpacer',
                                    'comp': {'name': 'VSpacer', 'size': 0}
                                }, {
                                    'comp': {
                                        'name': 'VBox',
                                        'items': [{
                                            'id': 'def_3_0',
                                            'comp': {
                                                'name': 'HBox',
                                                'orientation': 'horizontal',
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'displayName': 'def_3_0',
                                                    'alignment': 'left',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 3,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'items': [{
                                                    'id': 'Left',
                                                    'comp': {
                                                        'name': 'VBox',
                                                        'orientation': 'vertical',
                                                        'pack': 'center',
                                                        'items': [{
                                                            'id': 'Left_0',
                                                            'comp': {
                                                                'name': 'FieldBox',
                                                                'items': [],
                                                                'orientation': 'horizontal',
                                                                'editorData': {
                                                                    'labelPosition': 'none',
                                                                    'displayName': 'Left_0',
                                                                    'alignment': 'left',
                                                                    'spacers': {
                                                                        'before': 0,
                                                                        'after': 3,
                                                                        'xax-before': 3,
                                                                        'xax-after': 0
                                                                    }
                                                                },
                                                                'pack': 'end'
                                                            },
                                                            'layout': {'min-width': 0}
                                                        }, {
                                                            'id': 'Left_1',
                                                            'comp': {
                                                                'name': 'FieldBox',
                                                                'orientation': 'vertical',
                                                                'editorData': {
                                                                    'labelPosition': 'none',
                                                                    'displayName': 'Left_1',
                                                                    'alignment': 'left',
                                                                    'spacers': {
                                                                        'before': 0,
                                                                        'after': 3,
                                                                        'xax-before': 3,
                                                                        'xax-after': 0
                                                                    }
                                                                },
                                                                'items': []
                                                            },
                                                            'layout': {'min-width': 0}
                                                        }],
                                                        'editorData': {
                                                            'labelPosition': 'none',
                                                            'alignment': 'left',
                                                            'displayName': 'Left section',
                                                            'spacers': {
                                                                'before': 0,
                                                                'after': 3,
                                                                'xax-before': 0,
                                                                'xax-after': 0
                                                            }
                                                        }
                                                    },
                                                    'layout': {'spacerAfter': 0, 'min-width': 0}
                                                }]
                                            },
                                            'layout': {'min-width': 0}
                                        }, {
                                            'id': 'def_3_1',
                                            'comp': {
                                                'name': 'HBox',
                                                'orientation': 'horizontal',
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'displayName': 'def_3_1',
                                                    'alignment': 'left',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 3,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'items': [{
                                                    'id': 'leftToCenterSpacer',
                                                    'comp': {'name': 'HSpacer', 'size': 0}
                                                }, {
                                                    'id': 'Center',
                                                    'comp': {
                                                        'name': 'FieldBox',
                                                        'orientation': 'horizontal',
                                                        'pack': 'start',
                                                        'items': [],
                                                        'editorData': {
                                                            'labelPosition': 'none',
                                                            'alignment': 'left',
                                                            'displayName': 'Center section',
                                                            'spacers': {
                                                                'before': 0,
                                                                'after': 3,
                                                                'xax-before': 0,
                                                                'xax-after': 0
                                                            }
                                                        }
                                                    },
                                                    'layout': {'box-flex': 1, 'min-width': 0}
                                                }]
                                            },
                                            'layout': {'min-width': 0}
                                        }, {
                                            'id': 'def_3_2',
                                            'comp': {
                                                'name': 'HBox',
                                                'orientation': 'horizontal',
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'displayName': 'def_3_2',
                                                    'alignment': 'left',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 3,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'items': [{
                                                    'id': 'centerToRightSpacer',
                                                    'comp': {'name': 'HSpacer', 'size': 0}
                                                }, {
                                                    'id': 'Right',
                                                    'comp': {
                                                        'name': 'FieldBox',
                                                        'orientation': 'vertical',
                                                        'pack': 'start',
                                                        'editorData': {
                                                            'labelPosition': 'none',
                                                            'displayName': 'Right section',
                                                            'alignment': 'left',
                                                            'spacers': {
                                                                'before': 0,
                                                                'after': 3,
                                                                'xax-before': 0,
                                                                'xax-after': 0
                                                            }
                                                        },
                                                        'items': []
                                                    },
                                                    'layout': {'min-width': 0}
                                                }]
                                            },
                                            'layout': {'min-width': 0}
                                        }],
                                        'orientation': 'vertical'
                                    }, 'id': 'def_3'
                                }, {
                                    'id': 'Bottom',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Bottom section',
                                            'alignment': 'left',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 0}
                                        },
                                        'items': [{
                                            'id': 'text_1',
                                            'data': 'title',
                                            'comp': {
                                                'name': 'TextField',
                                                'items': [{
                                                    'id': 'text_1_proxy',
                                                    'data': 'title',
                                                    'comp': {
                                                        'name': 'Label',
                                                        'color': 'color_14',
                                                        'style': 'Body L',
                                                        'bold': false,
                                                        'italic': false,
                                                        'underline': false
                                                    },
                                                    'layout': {'text-align': 'left'}
                                                }],
                                                'hidden': false,
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 0,
                                                    'after': 0,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'width-mode': 'auto',
                                                'min-lines': 0,
                                                'max-lines': 0,
                                                'width': 174,
                                                'height-mode': 'auto'
                                            }
                                        }, {
                                            'id': 'fld_hdbrtpx5',
                                            'data': 'price',
                                            'comp': {
                                                'name': 'TextField',
                                                'items': [{
                                                    'id': 'fld_hdbrtpx5_proxy',
                                                    'data': 'price',
                                                    'comp': {
                                                        'name': 'Label',
                                                        'color': 'color_14',
                                                        'style': 'Body M',
                                                        'bold': false,
                                                        'italic': false,
                                                        'underline': false
                                                    },
                                                    'layout': {'text-align': 'left'}
                                                }],
                                                'hidden': false,
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 0,
                                                    'after': 2,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'width-mode': 'auto',
                                                'min-lines': 0,
                                                'max-lines': 0,
                                                'height-mode': 'auto'
                                            }
                                        }, {
                                            'id': 'fld_i9bfsyrr49',
                                            'data': 'i9479gl7',
                                            'comp': {
                                                'name': 'TextField',
                                                'items': [{
                                                    'id': 'fld_i9bfsyrr49_proxy',
                                                    'data': 'i9479gl7',
                                                    'comp': {
                                                        'name': 'Label',
                                                        'color': 'color_14',
                                                        'style': 'Heading S'
                                                    },
                                                    'layout': {'text-align': 'left'}
                                                }],
                                                'hidden': false,
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 0,
                                                    'after': 3,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'width-mode': 'auto',
                                                'min-lines': '0',
                                                'max-lines': '0',
                                                'width': 200
                                            },
                                            'experiment': 'NewListFields'
                                        }, {
                                            'id': 'fld_i9bft2fz57',
                                            'data': 'i947bhyp',
                                            'comp': {
                                                'name': 'TextField',
                                                'width': '200',
                                                'items': [{
                                                    'id': 'fld_i9bft2fz57_proxy',
                                                    'data': 'i947bhyp',
                                                    'comp': {'name': 'Label', 'color': 'color_14'},
                                                    'layout': {'text-align': 'left'}
                                                }],
                                                'hidden': true,
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 0,
                                                    'after': 3,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'width-mode': 'auto',
                                                'min-lines': '0',
                                                'max-lines': '0'
                                            }
                                        }, {
                                            'id': 'fld_i9bft40s66',
                                            'data': 'i9bfdsxb',
                                            'comp': {
                                                'name': 'Field',
                                                'width': 280,
                                                'height': 44,
                                                'items': [{
                                                    'id': 'fld_i9bft40s66_proxy',
                                                    'data': 'i9bfdsxb',
                                                    'comp': {'name': 'Button2', 'align': 'center', 'style': 'b1'},
                                                    'layout': {'position': 'relative'}
                                                }],
                                                'hidden': true,
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 20,
                                                    'after': 0,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'box-align': 'left'
                                            }
                                        }]
                                    },
                                    'layout': {'box-flex-pack': 'start', 'min-width': 0}
                                }, {'id': 'bottomSpacer', 'comp': {'name': 'VSpacer', 'size': 0}}]
                            },
                            'id': 'def_0',
                            'editorData': {'originalWidth': 600, 'wasChanged': true},
                            'format': 'Mobile'
                        }, {
                            'name': 'PriceListView_i6w1i8b887_dup_i7g48h8s233',
                            'forType': 'PriceList_i6w1i8b886',
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'Top',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Top section',
                                            'alignment': 'left',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 3}
                                        },
                                        'items': []
                                    },
                                    'layout': {'min-width': 0}
                                }, {
                                    'id': 'topSpacer',
                                    'comp': {'name': 'VSpacer', 'size': '0'}
                                }, {
                                    'comp': {
                                        'name': 'HBox',
                                        'items': [{
                                            'id': 'Left',
                                            'comp': {
                                                'name': 'FieldBox',
                                                'orientation': 'horizontal',
                                                'pack': 'center',
                                                'items': [{
                                                    'id': 'fld_i7em0l6q192',
                                                    'data': 'i7em0l5v',
                                                    'comp': {
                                                        'name': 'Field',
                                                        'width': 330,
                                                        'height': '220',
                                                        'items': [{
                                                            'id': 'fld_i7em0l6q192_proxy',
                                                            'data': 'i7em0l5v',
                                                            'comp': {'name': 'Video'},
                                                            'layout': {'position': 'relative'}
                                                        }],
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 24,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'box-align': 'left',
                                                        'hidden': true
                                                    }
                                                }, {
                                                    'id': 'fld_i7elyjdv156',
                                                    'data': 'i7elyjd2',
                                                    'comp': {
                                                        'name': 'Field',
                                                        'width': 330,
                                                        'height': 220,
                                                        'items': [{
                                                            'id': 'fld_i7elyjdv156_proxy',
                                                            'data': 'i7elyjd2',
                                                            'comp': {'name': 'Image', 'style': 'wp2'}
                                                        }],
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 24,
                                                            'xax-before': 0,
                                                            'xax-after': 10
                                                        },
                                                        'box-align': 'left',
                                                        'hidden': true
                                                    }
                                                }],
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'alignment': 'left',
                                                    'displayName': 'Left section',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 0,
                                                        'xax-after': 10
                                                    }
                                                }
                                            },
                                            'layout': {'spacerAfter': 0, 'min-width': 0}
                                        }, {
                                            'id': 'leftToCenterSpacer',
                                            'comp': {'name': 'HSpacer', 'size': '0'}
                                        }, {
                                            'id': 'Center',
                                            'comp': {
                                                'name': 'FieldBox',
                                                'orientation': 'vertical',
                                                'pack': 'start',
                                                'items': [{
                                                    'id': 'fld_i947gn90316',
                                                    'data': 'i947gn7s',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0',
                                                        'items': [{
                                                            'id': 'fld_i947gn90316_proxy',
                                                            'data': 'i947gn7s',
                                                            'comp': {
                                                                'name': 'Date',
                                                                'format': 'mmmm dd, yyyy',
                                                                'color': 'color_14'
                                                            },
                                                            'layout': {'text-align': 'left'}
                                                        }],
                                                        'hidden': true,
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 3,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        }
                                                    },
                                                    'experiment': 'NewListFields'
                                                }, {
                                                    'id': 'text_1',
                                                    'data': 'title',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width-mode': 'auto',
                                                        'min-lines': 0,
                                                        'max-lines': 0,
                                                        'items': [{
                                                            'id': 'text_1_proxy',
                                                            'data': 'title',
                                                            'comp': {
                                                                'name': 'Label',
                                                                'style': 'Body L',
                                                                'bold': false,
                                                                'italic': false,
                                                                'underline': false,
                                                                'color': 'color_14'
                                                            },
                                                            'layout': {'text-align': 'left'}
                                                        }],
                                                        'hidden': false,
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 0,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'height-mode': 'auto',
                                                        'width': 100
                                                    }
                                                }, {
                                                    'id': 'fld_hdbrtpx5',
                                                    'data': 'price',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'items': [{
                                                            'id': 'fld_hdbrtpx5_proxy',
                                                            'data': 'price',
                                                            'comp': {
                                                                'name': 'Label',
                                                                'style': 'Body M',
                                                                'bold': false,
                                                                'italic': false,
                                                                'underline': false,
                                                                'color': 'color_14'
                                                            },
                                                            'layout': {'text-align': 'left'}
                                                        }],
                                                        'hidden': false,
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 6,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'width-mode': 'auto',
                                                        'min-lines': 0,
                                                        'max-lines': 0,
                                                        'height-mode': 'auto'
                                                    }
                                                }, {
                                                    'id': 'fld_i947bhzy257',
                                                    'data': 'i947bhyp',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'items': [{
                                                            'id': 'fld_i947bhzy257_proxy',
                                                            'data': 'i947bhyp',
                                                            'comp': {'name': 'Label', 'color': 'color_14'},
                                                            'layout': {'text-align': 'left'}
                                                        }],
                                                        'hidden': true,
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 3,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0'
                                                    }
                                                }, {
                                                    'id': 'fld_i9bfdsz5179',
                                                    'data': 'i9bfdsxb',
                                                    'comp': {
                                                        'name': 'Field',
                                                        'width': 160,
                                                        'height': '40',
                                                        'items': [{
                                                            'id': 'fld_i9bfdsz5179_proxy',
                                                            'data': 'i9bfdsxb',
                                                            'comp': {
                                                                'name': 'Button2',
                                                                'style': 'b1',
                                                                'align': 'center'
                                                            },
                                                            'layout': {'position': 'relative'}
                                                        }],
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 24,
                                                            'after': 0,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'box-align': 'start',
                                                        'hidden': true
                                                    }
                                                }],
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'alignment': 'left',
                                                    'displayName': 'Center section',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 0,
                                                        'xax-after': 0
                                                    }
                                                }
                                            },
                                            'layout': {'box-flex': 1, 'min-width': 0}
                                        }, {
                                            'id': 'centerToRightSpacer',
                                            'comp': {'name': 'HSpacer', 'size': '0'}
                                        }, {
                                            'id': 'Right',
                                            'comp': {
                                                'name': 'FieldBox',
                                                'orientation': 'vertical',
                                                'pack': 'start',
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'displayName': 'Right section',
                                                    'alignment': 'left',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 20,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'items': [{
                                                    'id': 'fld_i9479gmg217',
                                                    'data': 'i9479gl7',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0',
                                                        'items': [{
                                                            'id': 'fld_i9479gmg217_proxy',
                                                            'data': 'i9479gl7',
                                                            'comp': {
                                                                'name': 'Label',
                                                                'style': 'Heading M',
                                                                'color': 'color_14',
                                                                'bold': false,
                                                                'underline': false,
                                                                'italic': false
                                                            },
                                                            'layout': {'text-align': 'left'}
                                                        }],
                                                        'hidden': false,
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 3,
                                                            'xax-before': 20,
                                                            'xax-after': 0
                                                        },
                                                        'width': '200'
                                                    },
                                                    'experiment': 'NewListFields'
                                                }]
                                            },
                                            'layout': {'min-width': 0}
                                        }]
                                    }, 'id': 'def_3'
                                }, {
                                    'id': 'Bottom',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Bottom section',
                                            'alignment': 'left',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 3}
                                        }
                                    },
                                    'layout': {'box-flex-pack': 'start', 'min-width': 0}
                                }, {'id': 'bottomSpacer', 'comp': {'name': 'VSpacer', 'size': '0'}}]
                            },
                            'id': 'def_0',
                            'editorData': {'wasChanged': true}
                        }],
                        'items': [{
                            'title': i18n.translate('i7g48h9x.structure.custom.template.items.0.title'),
                            'price': i18n.translate('i7g48h9x.structure.custom.template.items.0.price'),
                            'links': {
                                '_type': 'wix:Map',
                                'i9bfdsxb': {
                                    'type': 'ExternalLink',
                                    'url': 'http://www.wix.com',
                                    'target': '_blank'
                                }
                            },
                            'i7elyjd2': {
                                '_type': 'wix:Image',
                                'title': '',
                                'src': 'c7ad2b64020342d0ae1a1feace158fe9.jpg',
                                'width': 1920,
                                'height': 1280,
                                'alt': ''
                            },
                            'i7em0l5v': {
                                '_type': 'wix:Video',
                                'videoId': 'z1pSAwjC4JI',
                                'videoType': 'YOUTUBE',
                                'thumbnail': ''
                            },
                            'i9479gl7': i18n.translate('i7g48h9x.structure.custom.template.items.0.i9479gl7'),
                            'i947gn7s': {'_type': 'wix:Date', 'iso': '2023-01-03T00:00:00.000Z'},
                            'i947bhyp': {
                                'version': 1,
                                '_type': 'wix:RichText',
                                'links': [],
                                'text': i18n.translate('i7g48h9x.structure.custom.template.items.0.i947bhyp')
                            },
                            'i9bfdsxb': i18n.translate('i7g48h9x.structure.custom.template.items.0.i9bfdsxb')
                        }, {
                            'title': i18n.translate('i7g48h9x.structure.custom.template.items.1.title'),
                            'price': i18n.translate('i7g48h9x.structure.custom.template.items.1.price'),
                            'links': {
                                '_type': 'wix:Map',
                                'i9bfdsxb': {
                                    'type': 'ExternalLink',
                                    'url': 'http://www.wix.com',
                                    'target': '_blank'
                                }
                            },
                            'i7elyjd2': {
                                '_type': 'wix:Image',
                                'title': '',
                                'src': 'd967ba93f0314c78924edc8a8c8cfa15.jpg',
                                'width': 1920,
                                'height': 1275,
                                'alt': ''
                            },
                            'i7em0l5v': {
                                '_type': 'wix:Video',
                                'videoId': 'f36EEfI0yr8',
                                'videoType': 'YOUTUBE',
                                'thumbnail': ''
                            },
                            'i9479gl7': i18n.translate('i7g48h9x.structure.custom.template.items.1.i9479gl7'),
                            'i947gn7s': {'_type': 'wix:Date', 'iso': '2023-02-23T00:00:00.000Z'},
                            'i947bhyp': {
                                'version': 1,
                                '_type': 'wix:RichText',
                                'links': [],
                                'text': i18n.translate('i7g48h9x.structure.custom.template.items.1.i947bhyp')
                            },
                            'i9bfdsxb': i18n.translate('i7g48h9x.structure.custom.template.items.1.i9bfdsxb')
                        }, {
                            'title': i18n.translate('i7g48h9x.structure.custom.template.items.2.title'),
                            'price': i18n.translate('i7g48h9x.structure.custom.template.items.2.price'),
                            'links': {
                                '_type': 'wix:Map',
                                'i9bfdsxb': {
                                    'type': 'ExternalLink',
                                    'url': 'http://www.wix.com',
                                    'target': '_blank'
                                }
                            },
                            'i7elyjd2': {
                                '_type': 'wix:Image',
                                'title': '',
                                'src': 'bef2ef0f0a3343aba2fa7e746baa781c.jpg',
                                'width': 4434,
                                'height': 3729,
                                'alt': ''
                            },
                            'i7em0l5v': {
                                '_type': 'wix:Video',
                                'videoId': 'wGOTBSlYkzU',
                                'videoType': 'YOUTUBE',
                                'thumbnail': ''
                            },
                            'i9479gl7': i18n.translate('i7g48h9x.structure.custom.template.items.2.i9479gl7'),
                            'i947gn7s': {'_type': 'wix:Date', 'iso': '2023-05-28T00:00:00.000Z'},
                            'i947bhyp': {
                                'version': 1,
                                '_type': 'wix:RichText',
                                'links': [],
                                'text': i18n.translate('i7g48h9x.structure.custom.template.items.2.i947bhyp')
                            },
                            'i9bfdsxb': i18n.translate('i7g48h9x.structure.custom.template.items.2.i9bfdsxb')
                        }],
                        'displayName': i18n.translate('i7g48h9x.structure.custom.template.displayName')
                    }
                },
                'id': 'i7g48h9x'
            },
            'preset': {
                'rect': {'width': 162, 'height': 111, 'x': 162, 'y': 1231.25},
                'label': 'i7g48h9x.structure.custom.template.displayName',
                'tags': null
            }
        }, {
            'id': 'Lists_List_Products_And_Services_12',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.viewer.skins.AppPartSkin',
                'layout': {
                    'width': 466,
                    'height': 204,
                    'x': 709,
                    'y': 5423,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wixapps.integration.components.AppPart2',
                'data': {
                    'type': 'AppBuilderComponent',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'appInnerID': '2',
                    'appPartName': 'appparti7g4tsfe15'
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'id': 'app1',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {'groups': {}, 'properties': {'rd': '7px'}, 'propertiesSource': {'rd': 'value'}},
                    'componentClassName': '',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'system',
                    'skin': 'wysiwyg.viewer.skins.AppPartSkin'
                },
                'custom': {
                    'template': {
                        'type': {
                            'version': 0,
                            'baseTypes': [],
                            'displayName': i18n.translate('i7g4tsfm.structure.custom.template.type.displayName'),
                            'fields': [{
                                'displayName': i18n.translate('i7g4tsfm.structure.custom.template.type.fields.0.displayName'),
                                'name': 'title',
                                'searchable': false,
                                'type': 'String',
                                'defaultValue': i18n.translate('i7g4tsfm.structure.custom.template.type.fields.0.defaultValue'),
                                'computed': false
                            }, {
                                'searchable': false,
                                'displayName': i18n.translate('i7g4tsfm.structure.custom.template.type.fields.1.displayName'),
                                'type': 'String',
                                'name': 'price',
                                'computed': false,
                                'defaultValue': i18n.translate('i7g4tsfm.structure.custom.template.type.fields.1.defaultValue')
                            }, {
                                'defaultValue': {'iso': '2020-01-01T00:00:00.000Z', '_type': 'wix:Date'},
                                'type': 'wix:Date',
                                'searchable': false,
                                'displayName': i18n.translate('i7g4tsfm.structure.custom.template.type.fields.2.displayName'),
                                'computed': false,
                                'name': 'i948kd8k'
                            }, {
                                'displayName': i18n.translate('i7g4tsfm.structure.custom.template.type.fields.3.displayName'),
                                'defaultValue': {
                                    'version': 1,
                                    '_type': 'wix:RichText',
                                    'links': [],
                                    'text': i18n.translate('i7g4tsfm.structure.custom.template.type.fields.3.defaultValue')
                                },
                                'type': 'wix:RichText',
                                'searchable': false,
                                'computed': false,
                                'name': 'i949n6fg'
                            }, {
                                'metadata': {'showAsHint': 'AsPrice'},
                                'displayName': i18n.translate('i7g4tsfm.structure.custom.template.type.fields.4.displayName'),
                                'searchable': false,
                                'type': 'String',
                                'defaultValue': i18n.translate('i7g4tsfm.structure.custom.template.type.fields.4.defaultValue'),
                                'name': 'i948h3ky',
                                'computed': false
                            }, {
                                'type': 'wix:Image',
                                'defaultValue': {
                                    'height': 1276,
                                    'src': 'c8381ce0e7a94ad09ceaada2fc64081d.jpg',
                                    'width': 1920,
                                    '_type': 'wix:Image',
                                    'title': ''
                                },
                                'displayName': i18n.translate('i7g4tsfm.structure.custom.template.type.fields.5.displayName'),
                                'searchable': false,
                                'name': 'i7g4fg1v',
                                'computed': false
                            }, {
                                'name': 'i7g4kibe',
                                'defaultValue': {
                                    'videoId': 'f36EEfI0yr8',
                                    '_type': 'wix:Video',
                                    'videoType': 'YOUTUBE'
                                },
                                'type': 'wix:Video',
                                'searchable': false,
                                'displayName': i18n.translate('i7g4tsfm.structure.custom.template.type.fields.6.displayName'),
                                'computed': false
                            }, {
                                'defaultValue': i18n.translate('i7g4tsfm.structure.custom.template.type.fields.7.defaultValue'),
                                'name': 'i948smva',
                                'searchable': false,
                                'metadata': {'showAsHint': 'AsButton'},
                                'type': 'String',
                                'displayName': i18n.translate('i7g4tsfm.structure.custom.template.type.fields.7.displayName'),
                                'computed': false
                            }, {
                                'name': 'links',
                                'type': 'wix:Map',
                                'searchable': false,
                                'displayName': '',
                                'defaultValue': {'_type': 'wix:Map'},
                                'computed': false
                            }],
                            'name': 'PriceList_i6w1hipo81'
                        },
                        'views': [{
                            'name': 'PriceListView_i6w1hipp82_dup_i721w88j483_dup_i7g4tsek358',
                            'forType': 'Array',
                            'vars': {'itemSpacing': 40, 'paginationColor': 'color_15'},
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'paginatedlist',
                                    'comp': {
                                        'name': 'PaginatedList',
                                        'hidden': {'$expr': 'eq(Array.length(this), 0)'},
                                        'hidePagination': 'true',
                                        'itemsPerPage': '10',
                                        'templates': {
                                            'item': {
                                                'data': '$positionInParent',
                                                'comp': {
                                                    'name': 'SwitchBox',
                                                    'cases': {
                                                        'default': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'PriceListView_i6w1hipp82_dup_i721w88j483_dup_i7g4tsek358'}
                                                        }, {
                                                            'id': 'footerSpacer',
                                                            'comp': {
                                                                'name': 'VSpacer',
                                                                'size': {'$expr': '$itemSpacing'}
                                                            }
                                                        }, {
                                                            'id': 'gutterLine',
                                                            'comp': {'name': 'HorizontalLine', 'hidden': 'true'},
                                                            'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                        }],
                                                        'last': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'PriceListView_i6w1hipp82_dup_i721w88j483_dup_i7g4tsek358'}
                                                        }, {
                                                            'comp': {
                                                                'name': 'VBox',
                                                                'hidden': 'true',
                                                                'items': [{
                                                                    'id': 'footerSpacer',
                                                                    'comp': {
                                                                        'name': 'VSpacer',
                                                                        'size': {'$expr': '$itemSpacing'}
                                                                    }
                                                                }, {
                                                                    'id': 'gutterLine',
                                                                    'comp': {
                                                                        'name': 'HorizontalLine',
                                                                        'hidden': 'true'
                                                                    },
                                                                    'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                                }]
                                                            }, 'id': 'def_7'
                                                        }, {
                                                            'comp': {'name': 'VSpacer', 'size': '9'},
                                                            'id': 'def_10'
                                                        }, {
                                                            'id': 'paginationNavigationBox',
                                                            'comp': {
                                                                'name': 'HBox',
                                                                'hidden': {'$expr': '$hidePagination'},
                                                                'box-align': 'center',
                                                                'items': [{
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_12'
                                                                }, {
                                                                    'value': '◄',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationPrev',
                                                                        'events': {'dom:click': 'prevPageClicked'}
                                                                    },
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_13'
                                                                }, {
                                                                    'value': {'$expr': 'String.concat($currentPage,\' / \',$maxPage)'},
                                                                    'comp': {'name': 'Label'},
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_14'
                                                                }, {
                                                                    'value': '►',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationNext',
                                                                        'events': {'dom:click': 'nextPageClicked'}
                                                                    },
                                                                    'id': 'def_15'
                                                                }, {
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_16'
                                                                }]
                                                            },
                                                            'layout': {'position': 'relative'}
                                                        }]
                                                    },
                                                    'css': {'min-width': '100%'}
                                                },
                                                'id': '$positionInParent'
                                            }
                                        }
                                    }
                                }, {
                                    'id': 'noItemsLabel',
                                    'value': 'There are no items in this list',
                                    'comp': {'name': 'Label', 'hidden': {'$expr': 'gt(String.length(this), 0)'}}
                                }],
                                'editorData': {'isPaginated': true}
                            },
                            'stylesheet': [{
                                '.paginationPrev, .paginationNext': {
                                    'padding': '0 5px',
                                    'display': 'inline-block',
                                    'font-size': '14px',
                                    'cursor': 'pointer',
                                    '-webkit-touch-callout': 'none',
                                    '-webkit-user-select': 'none',
                                    '-khtml-user-select': 'none',
                                    '-moz-user-select': 'none',
                                    '-ms-user-select': 'none',
                                    'user-select': 'none'
                                }
                            }],
                            'customizations': [{
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.hidePagination',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Split your list across multiple pages',
                                    'defaultVal': 'true',
                                    'trueVal': 'false',
                                    'spaceAfter': '10'
                                }
                            }, {
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.itemsPerPage',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '120',
                                    'minVal': '1',
                                    'label': 'Items Per Page',
                                    'spaceAfter': '20',
                                    'hiddenwhen': {
                                        'fieldId': 'paginatedlist',
                                        'key': 'comp.hidePagination',
                                        'value': 'true'
                                    }
                                }
                            }, {
                                'priority': 100,
                                'fieldId': 'vars',
                                'key': 'itemSpacing',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '300',
                                    'minVal': '0',
                                    'label': 'Item Spacing',
                                    'spaceAfter': '20'
                                }
                            }, {
                                'priority': 95,
                                'fieldId': 'gutterLine',
                                'key': 'comp.hidden',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Show item separator',
                                    'defaultVal': 'false',
                                    'trueVal': 'false'
                                }
                            }, {
                                'priority': 90,
                                'fieldId': 'gutterLine',
                                'key': 'comp.style',
                                'input': {
                                    'name': 'changestyle',
                                    'showAs': 'link',
                                    'label': 'Change Item Separator Style',
                                    'spaceAfter': '20'
                                }
                            }],
                            'id': 'i7g4tsel'
                        }, {
                            'name': 'PriceListView_i6w1hipp82_dup_i721w88j483_dup_i7g4tsek358',
                            'forType': 'Array',
                            'vars': {'itemSpacing': 40, 'paginationColor': 'color_15'},
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'paginatedlist',
                                    'comp': {
                                        'name': 'PaginatedList',
                                        'hidden': {'$expr': 'eq(Array.length(this), 0)'},
                                        'hidePagination': 'true',
                                        'itemsPerPage': '10',
                                        'templates': {
                                            'item': {
                                                'data': '$positionInParent',
                                                'comp': {
                                                    'name': 'SwitchBox',
                                                    'cases': {
                                                        'default': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'PriceListView_i6w1hipp82_dup_i721w88j483_dup_i7g4tsek358'}
                                                        }, {
                                                            'id': 'footerSpacer',
                                                            'comp': {
                                                                'name': 'VSpacer',
                                                                'size': {'$expr': '$itemSpacing'}
                                                            }
                                                        }, {
                                                            'id': 'gutterLine',
                                                            'comp': {'name': 'HorizontalLine', 'hidden': 'true'},
                                                            'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                        }],
                                                        'last': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'PriceListView_i6w1hipp82_dup_i721w88j483_dup_i7g4tsek358'}
                                                        }, {
                                                            'comp': {
                                                                'name': 'VBox',
                                                                'hidden': 'true',
                                                                'items': [{
                                                                    'id': 'footerSpacer',
                                                                    'comp': {
                                                                        'name': 'VSpacer',
                                                                        'size': {'$expr': '$itemSpacing'}
                                                                    }
                                                                }, {
                                                                    'id': 'gutterLine',
                                                                    'comp': {
                                                                        'name': 'HorizontalLine',
                                                                        'hidden': 'true'
                                                                    },
                                                                    'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                                }]
                                                            }, 'id': 'def_7'
                                                        }, {
                                                            'comp': {'name': 'VSpacer', 'size': '9'},
                                                            'id': 'def_10'
                                                        }, {
                                                            'id': 'paginationNavigationBox',
                                                            'comp': {
                                                                'name': 'HBox',
                                                                'hidden': {'$expr': '$hidePagination'},
                                                                'box-align': 'center',
                                                                'items': [{
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_12'
                                                                }, {
                                                                    'value': '◄',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationPrev',
                                                                        'events': {'dom:click': 'prevPageClicked'}
                                                                    },
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_13'
                                                                }, {
                                                                    'value': {'$expr': 'String.concat($currentPage,\' / \',$maxPage)'},
                                                                    'comp': {'name': 'Label'},
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_14'
                                                                }, {
                                                                    'value': '►',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationNext',
                                                                        'events': {'dom:click': 'nextPageClicked'}
                                                                    },
                                                                    'id': 'def_15'
                                                                }, {
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_16'
                                                                }]
                                                            },
                                                            'layout': {'position': 'relative'}
                                                        }]
                                                    },
                                                    'css': {'min-width': '100%'}
                                                },
                                                'id': '$positionInParent'
                                            }
                                        }
                                    }
                                }, {
                                    'id': 'noItemsLabel',
                                    'value': 'There are no items in this list',
                                    'comp': {'name': 'Label', 'hidden': {'$expr': 'gt(String.length(this), 0)'}}
                                }],
                                'editorData': {'isPaginated': true}
                            },
                            'stylesheet': [{
                                '.paginationPrev, .paginationNext': {
                                    'padding': '0 5px',
                                    'display': 'inline-block',
                                    'font-size': '14px',
                                    'cursor': 'pointer',
                                    '-webkit-touch-callout': 'none',
                                    '-webkit-user-select': 'none',
                                    '-khtml-user-select': 'none',
                                    '-moz-user-select': 'none',
                                    '-ms-user-select': 'none',
                                    'user-select': 'none'
                                }
                            }],
                            'customizations': [{
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.hidePagination',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Split your list across multiple pages',
                                    'defaultVal': 'true',
                                    'trueVal': 'false',
                                    'spaceAfter': '10'
                                }
                            }, {
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.itemsPerPage',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '120',
                                    'minVal': '1',
                                    'label': 'Items Per Page',
                                    'spaceAfter': '20',
                                    'hiddenwhen': {
                                        'fieldId': 'paginatedlist',
                                        'key': 'comp.hidePagination',
                                        'value': 'true'
                                    }
                                }
                            }, {
                                'priority': 100,
                                'fieldId': 'vars',
                                'key': 'itemSpacing',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '300',
                                    'minVal': '0',
                                    'label': 'Item Spacing',
                                    'spaceAfter': '20'
                                }
                            }, {
                                'priority': 95,
                                'fieldId': 'gutterLine',
                                'key': 'comp.hidden',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Show item separator',
                                    'defaultVal': 'false',
                                    'trueVal': 'false'
                                }
                            }, {
                                'priority': 90,
                                'fieldId': 'gutterLine',
                                'key': 'comp.style',
                                'input': {
                                    'name': 'changestyle',
                                    'showAs': 'link',
                                    'label': 'Change Item Separator Style',
                                    'spaceAfter': '20'
                                }
                            }],
                            'id': 'i7g4tsel',
                            'format': 'Mobile'
                        }, {
                            'name': 'PriceListView_i6w1hipp82_dup_i721w88j483_dup_i7g4tsek358',
                            'forType': 'PriceList_i6w1hipo81',
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'Top',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Top section',
                                            'alignment': 'left',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 0}
                                        },
                                        'items': [{
                                            'id': 'fld_i7g4fg2u318',
                                            'data': 'i7g4fg1v',
                                            'comp': {
                                                'name': 'Field',
                                                'width': 280,
                                                'height': 220,
                                                'items': [{
                                                    'id': 'fld_i7g4fg2u318_proxy',
                                                    'data': 'i7g4fg1v',
                                                    'comp': {'name': 'Image', 'style': 'wp2'}
                                                }],
                                                'hidden': true,
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 0,
                                                    'after': 8,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'box-align': 'center'
                                            }
                                        }, {
                                            'id': 'fld_i7g4kick343',
                                            'data': 'i7g4kibe',
                                            'comp': {
                                                'name': 'Field',
                                                'width': 280,
                                                'height': 220,
                                                'items': [{
                                                    'id': 'fld_i7g4kick343_proxy',
                                                    'data': 'i7g4kibe',
                                                    'comp': {'name': 'Video'},
                                                    'layout': {'position': 'relative'}
                                                }],
                                                'hidden': true,
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 0,
                                                    'after': 8,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'box-align': 'center'
                                            }
                                        }]
                                    },
                                    'layout': {'min-width': 0}
                                }, {
                                    'id': 'topSpacer',
                                    'comp': {'name': 'VSpacer', 'size': 0}
                                }, {
                                    'comp': {
                                        'name': 'VBox',
                                        'items': [{
                                            'id': 'def_3_0',
                                            'comp': {
                                                'name': 'HBox',
                                                'orientation': 'horizontal',
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'displayName': 'def_3_0',
                                                    'alignment': 'left',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 3,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'items': [{
                                                    'id': 'Left',
                                                    'comp': {
                                                        'name': 'VBox',
                                                        'orientation': 'vertical',
                                                        'pack': 'center',
                                                        'items': [{
                                                            'id': 'Left_0',
                                                            'comp': {
                                                                'name': 'FieldBox',
                                                                'orientation': 'horizontal',
                                                                'editorData': {
                                                                    'labelPosition': 'none',
                                                                    'displayName': 'Left_0',
                                                                    'alignment': 'left',
                                                                    'spacers': {
                                                                        'before': 0,
                                                                        'after': 3,
                                                                        'xax-before': 3,
                                                                        'xax-after': 0
                                                                    }
                                                                },
                                                                'items': []
                                                            },
                                                            'layout': {'min-width': 0}
                                                        }, {
                                                            'id': 'Left_1',
                                                            'comp': {
                                                                'name': 'FieldBox',
                                                                'orientation': 'horizontal',
                                                                'editorData': {
                                                                    'labelPosition': 'none',
                                                                    'displayName': 'Left_1',
                                                                    'alignment': 'left',
                                                                    'spacers': {
                                                                        'before': 0,
                                                                        'after': 3,
                                                                        'xax-before': 3,
                                                                        'xax-after': 0
                                                                    }
                                                                },
                                                                'items': []
                                                            },
                                                            'layout': {'min-width': 0}
                                                        }],
                                                        'editorData': {
                                                            'labelPosition': 'none',
                                                            'alignment': 'left',
                                                            'displayName': 'Left section',
                                                            'spacers': {
                                                                'before': 0,
                                                                'after': 3,
                                                                'xax-before': 0,
                                                                'xax-after': 0
                                                            }
                                                        }
                                                    },
                                                    'layout': {'spacerAfter': 0, 'min-width': 0}
                                                }]
                                            },
                                            'layout': {'min-width': 0}
                                        }, {
                                            'id': 'def_3_1',
                                            'comp': {
                                                'name': 'HBox',
                                                'orientation': 'horizontal',
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'displayName': 'def_3_1',
                                                    'alignment': 'left',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 3,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'items': [{
                                                    'id': 'leftToCenterSpacer',
                                                    'comp': {'name': 'HSpacer', 'size': 0}
                                                }, {
                                                    'id': 'Center',
                                                    'comp': {
                                                        'name': 'FieldBox',
                                                        'orientation': 'vertical',
                                                        'pack': 'start',
                                                        'editorData': {
                                                            'labelPosition': 'none',
                                                            'displayName': 'Center section',
                                                            'alignment': 'left',
                                                            'spacers': {
                                                                'before': 0,
                                                                'after': 3,
                                                                'xax-before': 0,
                                                                'xax-after': 0
                                                            }
                                                        },
                                                        'items': [{
                                                            'id': 'fld_i9bip7wj51',
                                                            'data': 'i948kd8k',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width-mode': 'auto',
                                                                'min-lines': '0',
                                                                'max-lines': '0',
                                                                'items': [{
                                                                    'id': 'fld_i9bip7wj51_proxy',
                                                                    'data': 'i948kd8k',
                                                                    'comp': {
                                                                        'name': 'Date',
                                                                        'format': 'mmmm dd, yyyy',
                                                                        'color': 'color_14'
                                                                    },
                                                                    'layout': {'text-align': 'center'}
                                                                }],
                                                                'spacers': {
                                                                    'before': 0,
                                                                    'after': 0,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                },
                                                                'width': '200',
                                                                'hidden': true,
                                                                'labelPosition': 'none'
                                                            },
                                                            'experiment': 'NewListFields'
                                                        }, {
                                                            'id': 'text_1',
                                                            'data': 'title',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width-mode': 'auto',
                                                                'min-lines': 0,
                                                                'max-lines': 0,
                                                                'items': [{
                                                                    'id': 'text_1_proxy',
                                                                    'data': 'title',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'style': 'Body L',
                                                                        'bold': false,
                                                                        'italic': false,
                                                                        'underline': false,
                                                                        'color': 'color_14'
                                                                    },
                                                                    'layout': {'text-align': 'center'}
                                                                }],
                                                                'spacers': {
                                                                    'before': 0,
                                                                    'after': 0,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                },
                                                                'height-mode': 'auto',
                                                                'box-align': 'start',
                                                                'width': 240
                                                            }
                                                        }, {
                                                            'id': 'fld_hdbrtpx5',
                                                            'data': 'price',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width-mode': 'auto',
                                                                'min-lines': 0,
                                                                'max-lines': 0,
                                                                'items': [{
                                                                    'id': 'fld_hdbrtpx5_proxy',
                                                                    'data': 'price',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'style': 'Body M',
                                                                        'bold': false,
                                                                        'italic': false,
                                                                        'underline': false,
                                                                        'color': 'color_14'
                                                                    },
                                                                    'layout': {'text-align': 'center'}
                                                                }],
                                                                'hidden': true,
                                                                'labelPosition': 'none',
                                                                'spacers': {
                                                                    'before': 0,
                                                                    'after': 3,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                },
                                                                'height-mode': 'auto'
                                                            }
                                                        }, {
                                                            'id': 'fld_i9bip9h158',
                                                            'data': 'i948h3ky',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': '200',
                                                                'width-mode': 'auto',
                                                                'min-lines': '0',
                                                                'max-lines': '0',
                                                                'items': [{
                                                                    'id': 'fld_i9bip9h158_proxy',
                                                                    'data': 'i948h3ky',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'color': 'color_14',
                                                                        'style': 'Body L'
                                                                    },
                                                                    'layout': {'text-align': 'center'}
                                                                }],
                                                                'hidden': false,
                                                                'labelPosition': 'none',
                                                                'spacers': {
                                                                    'before': 0,
                                                                    'after': 3,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                }
                                                            },
                                                            'experiment': 'NewListFields'
                                                        }, {
                                                            'id': 'fld_i9bipe6075',
                                                            'data': 'i949n6fg',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': '200',
                                                                'width-mode': 'auto',
                                                                'min-lines': '0',
                                                                'max-lines': '0',
                                                                'items': [{
                                                                    'id': 'fld_i9bipe6075_proxy',
                                                                    'data': 'i949n6fg',
                                                                    'comp': {'name': 'Label', 'color': 'color_14'},
                                                                    'layout': {'text-align': 'center'}
                                                                }],
                                                                'hidden': true,
                                                                'labelPosition': 'none',
                                                                'spacers': {
                                                                    'before': 0,
                                                                    'after': 3,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                }
                                                            }
                                                        }]
                                                    },
                                                    'layout': {'min-width': 200, 'box-flex': 1}
                                                }]
                                            },
                                            'layout': {'min-width': 0}
                                        }, {
                                            'id': 'def_3_2',
                                            'comp': {
                                                'name': 'HBox',
                                                'orientation': 'horizontal',
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'displayName': 'def_3_2',
                                                    'alignment': 'left',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 3,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'items': [{
                                                    'id': 'centerToRightSpacer',
                                                    'comp': {'name': 'HSpacer', 'size': 0}
                                                }, {
                                                    'id': 'Right',
                                                    'comp': {
                                                        'name': 'FieldBox',
                                                        'orientation': 'vertical',
                                                        'pack': 'center',
                                                        'editorData': {
                                                            'labelPosition': 'none',
                                                            'displayName': 'Right section',
                                                            'alignment': 'left',
                                                            'spacers': {
                                                                'before': 0,
                                                                'after': 3,
                                                                'xax-before': 0,
                                                                'xax-after': 0
                                                            }
                                                        },
                                                        'items': []
                                                    },
                                                    'layout': {'min-width': 100}
                                                }]
                                            },
                                            'layout': {'min-width': 0}
                                        }],
                                        'orientation': 'vertical'
                                    }, 'id': 'def_3'
                                }, {
                                    'id': 'Bottom',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Bottom section',
                                            'alignment': 'left',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 0}
                                        },
                                        'items': [{
                                            'id': 'fld_i9biv9nd26',
                                            'data': 'i948smva',
                                            'comp': {
                                                'name': 'Field',
                                                'width': 280,
                                                'height': 44,
                                                'items': [{
                                                    'id': 'fld_i9biv9nd26_proxy',
                                                    'data': 'i948smva',
                                                    'comp': {'name': 'Button2', 'align': 'center', 'style': 'b1'},
                                                    'layout': {'position': 'relative'}
                                                }],
                                                'hidden': true,
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 20,
                                                    'after': 0,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'box-align': 'center'
                                            }
                                        }]
                                    },
                                    'layout': {'box-flex-pack': 'start', 'min-width': 0}
                                }, {'id': 'bottomSpacer', 'comp': {'name': 'VSpacer', 'size': 0}}]
                            },
                            'id': 'def_0',
                            'editorData': {'originalWidth': 600, 'wasChanged': true},
                            'format': 'Mobile'
                        }, {
                            'name': 'PriceListView_i6w1hipp82_dup_i721w88j483_dup_i7g4tsek358',
                            'forType': 'PriceList_i6w1hipo81',
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'Top',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Top section',
                                            'alignment': 'left',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 3}
                                        },
                                        'items': [{
                                            'id': 'fld_i948qws537',
                                            'data': 'i7g4kibe',
                                            'comp': {
                                                'name': 'Field',
                                                'width': 465,
                                                'height': 295,
                                                'items': [{
                                                    'id': 'fld_i948qws537_proxy',
                                                    'data': 'i7g4kibe',
                                                    'comp': {'name': 'Video'},
                                                    'layout': {'position': 'relative'}
                                                }],
                                                'hidden': true,
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 0,
                                                    'after': 14,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'box-align': 'center'
                                            }
                                        }, {
                                            'id': 'fld_i948qxx241',
                                            'data': 'i7g4fg1v',
                                            'comp': {
                                                'name': 'Field',
                                                'width': 465,
                                                'height': 300,
                                                'items': [{
                                                    'id': 'fld_i948qxx241_proxy',
                                                    'data': 'i7g4fg1v',
                                                    'comp': {'name': 'Image', 'style': 'wp2'}
                                                }],
                                                'hidden': true,
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 0,
                                                    'after': 14,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'box-align': 'center'
                                            }
                                        }]
                                    },
                                    'layout': {'min-width': 0}
                                }, {
                                    'id': 'topSpacer',
                                    'comp': {'name': 'VSpacer', 'size': '0'}
                                }, {
                                    'comp': {
                                        'name': 'HBox',
                                        'items': [{
                                            'id': 'Left',
                                            'comp': {
                                                'name': 'FieldBox',
                                                'orientation': 'horizontal',
                                                'pack': 'center',
                                                'items': [{
                                                    'id': 'fld_i7g4fg2u318',
                                                    'data': 'i7g4fg1v',
                                                    'comp': {
                                                        'name': 'Field',
                                                        'width': 220,
                                                        'items': [{
                                                            'id': 'fld_i7g4fg2u318_proxy',
                                                            'data': 'i7g4fg1v',
                                                            'comp': {'name': 'Image', 'style': 'wp2'}
                                                        }],
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 20,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'hidden': true,
                                                        'height': 220,
                                                        'box-align': 'left'
                                                    }
                                                }, {
                                                    'id': 'fld_i7g4kick343',
                                                    'data': 'i7g4kibe',
                                                    'comp': {
                                                        'name': 'Field',
                                                        'width': 330,
                                                        'height': 220,
                                                        'items': [{
                                                            'id': 'fld_i7g4kick343_proxy',
                                                            'data': 'i7g4kibe',
                                                            'comp': {'name': 'Video'},
                                                            'layout': {'position': 'relative'}
                                                        }],
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 20,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'box-align': 'left',
                                                        'hidden': true
                                                    }
                                                }],
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'alignment': 'left',
                                                    'displayName': 'Left section',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 0,
                                                        'xax-after': 10
                                                    }
                                                }
                                            },
                                            'layout': {'spacerAfter': 0, 'min-width': 0}
                                        }, {
                                            'id': 'leftToCenterSpacer',
                                            'comp': {'name': 'HSpacer', 'size': '0'}
                                        }, {
                                            'id': 'Center',
                                            'comp': {
                                                'name': 'FieldBox',
                                                'orientation': 'vertical',
                                                'pack': 'start',
                                                'items': [{
                                                    'id': 'fld_i948kd9p136',
                                                    'data': 'i948kd8k',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0',
                                                        'items': [{
                                                            'id': 'fld_i948kd9p136_proxy',
                                                            'data': 'i948kd8k',
                                                            'comp': {
                                                                'name': 'Date',
                                                                'color': 'color_14',
                                                                'format': 'mmm dd, yyyy'
                                                            },
                                                            'layout': {'text-align': 'left'}
                                                        }],
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 3,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'hidden': true,
                                                        'labelPosition': 'none'
                                                    },
                                                    'experiment': 'NewListFields'
                                                }, {
                                                    'id': 'fld_i948q68j21',
                                                    'data': 'title',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'items': [{
                                                            'id': 'fld_i948q68j21_proxy',
                                                            'data': 'title',
                                                            'comp': {
                                                                'name': 'Label',
                                                                'color': 'color_14',
                                                                'style': 'Body L'
                                                            },
                                                            'layout': {'text-align': 'left'}
                                                        }],
                                                        'hidden': false,
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 3,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'width': '200',
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0'
                                                    }
                                                }, {
                                                    'id': 'fld_i948qcuk25',
                                                    'data': 'price',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'items': [{
                                                            'id': 'fld_i948qcuk25_proxy',
                                                            'data': 'price',
                                                            'comp': {'name': 'Label', 'color': 'color_14'},
                                                            'layout': {'text-align': 'left'}
                                                        }],
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 3,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'hidden': true,
                                                        'labelPosition': 'none',
                                                        'width': '200',
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0'
                                                    }
                                                }, {
                                                    'id': 'fld_i949n6gs58',
                                                    'data': 'i949n6fg',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0',
                                                        'items': [{
                                                            'id': 'fld_i949n6gs58_proxy',
                                                            'data': 'i949n6fg',
                                                            'comp': {'name': 'Label', 'color': 'color_14'},
                                                            'layout': {'text-align': 'left'}
                                                        }],
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 8,
                                                            'after': 0,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'hidden': true
                                                    }
                                                }],
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'alignment': 'left',
                                                    'displayName': 'Center section',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 0,
                                                        'xax-after': 0
                                                    }
                                                }
                                            },
                                            'layout': {'box-flex': 1, 'min-width': 200}
                                        }, {
                                            'id': 'centerToRightSpacer',
                                            'comp': {'name': 'HSpacer', 'size': '0'}
                                        }, {
                                            'id': 'Right',
                                            'comp': {
                                                'name': 'FieldBox',
                                                'orientation': 'vertical',
                                                'pack': 'start',
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'displayName': 'Right section',
                                                    'alignment': 'left',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 20,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'items': [{
                                                    'id': 'fld_i948h3m8109',
                                                    'data': 'i948h3ky',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0',
                                                        'items': [{
                                                            'id': 'fld_i948h3m8109_proxy',
                                                            'data': 'i948h3ky',
                                                            'comp': {
                                                                'name': 'Label',
                                                                'color': 'color_14',
                                                                'style': 'Heading S'
                                                            },
                                                            'layout': {'text-align': 'right'}
                                                        }],
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 3,
                                                            'xax-before': 20,
                                                            'xax-after': 0
                                                        },
                                                        'labelPosition': 'none',
                                                        'hidden': false,
                                                        'width': '200'
                                                    },
                                                    'experiment': 'NewListFields'
                                                }]
                                            },
                                            'layout': {'min-width': 100}
                                        }]
                                    }, 'id': 'def_3'
                                }, {
                                    'id': 'Bottom',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Bottom section',
                                            'alignment': 'left',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 3}
                                        },
                                        'items': [{
                                            'id': 'fld_i948smxu82',
                                            'data': 'i948smva',
                                            'comp': {
                                                'name': 'Field',
                                                'width': 160,
                                                'items': [{
                                                    'id': 'fld_i948smxu82_proxy',
                                                    'data': 'i948smva',
                                                    'comp': {'name': 'Button2', 'style': 'b1', 'align': 'center'},
                                                    'layout': {'position': 'relative'}
                                                }],
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 22,
                                                    'after': 0,
                                                    'xax-before': 0,
                                                    'xax-after': 3
                                                },
                                                'hidden': true,
                                                'height': '40',
                                                'box-align': 'start'
                                            }
                                        }]
                                    },
                                    'layout': {'box-flex-pack': 'start', 'min-width': 0}
                                }, {'id': 'bottomSpacer', 'comp': {'name': 'VSpacer', 'size': '0'}}]
                            },
                            'id': 'def_0',
                            'editorData': {'wasChanged': true}
                        }],
                        'items': [{
                            'title': i18n.translate('i7g4tsfm.structure.custom.template.items.0.title'),
                            'price': i18n.translate('i7g4tsfm.structure.custom.template.items.0.price'),
                            'links': {
                                '_type': 'wix:Map',
                                'i948smva': {
                                    'type': 'ExternalLink',
                                    'url': 'http://www.wix.com',
                                    'target': '_blank'
                                }
                            },
                            'i7g4fg1v': {
                                '_type': 'wix:Image',
                                'title': '',
                                'src': 'c7ad2b64020342d0ae1a1feace158fe9.jpg',
                                'width': 1920,
                                'height': 1280,
                                'alt': ''
                            },
                            'i7g4kibe': {
                                '_type': 'wix:Video',
                                'videoId': 'z1pSAwjC4JI',
                                'videoType': 'YOUTUBE',
                                'thumbnail': ''
                            },
                            'i948h3ky': i18n.translate('i7g4tsfm.structure.custom.template.items.0.i948h3ky'),
                            'i948kd8k': {'_type': 'wix:Date', 'iso': '2023-01-03T00:00:00.000Z'},
                            'i948smva': i18n.translate('i7g4tsfm.structure.custom.template.items.0.i948smva'),
                            'i949n6fg': {
                                'version': 1,
                                '_type': 'wix:RichText',
                                'links': [],
                                'text': i18n.translate('i7g4tsfm.structure.custom.template.items.0.i949n6fg')
                            }
                        }, {
                            'title': i18n.translate('i7g4tsfm.structure.custom.template.items.1.title'),
                            'price': i18n.translate('i7g4tsfm.structure.custom.template.items.1.price'),
                            'links': {
                                '_type': 'wix:Map',
                                'i948smva': {
                                    'type': 'ExternalLink',
                                    'url': 'http://www.wix.com',
                                    'target': '_blank'
                                }
                            },
                            'i7g4fg1v': {
                                '_type': 'wix:Image',
                                'title': '',
                                'src': 'd967ba93f0314c78924edc8a8c8cfa15.jpg',
                                'width': 1920,
                                'height': 1275,
                                'alt': ''
                            },
                            'i7g4kibe': {
                                '_type': 'wix:Video',
                                'videoId': 'f36EEfI0yr8',
                                'videoType': 'YOUTUBE',
                                'thumbnail': ''
                            },
                            'i948h3ky': i18n.translate('i7g4tsfm.structure.custom.template.items.1.i948h3ky'),
                            'i948kd8k': {'_type': 'wix:Date', 'iso': '2023-02-23T00:00:00.000Z'},
                            'i948smva': i18n.translate('i7g4tsfm.structure.custom.template.items.1.i948smva'),
                            'i949n6fg': {
                                'version': 1,
                                '_type': 'wix:RichText',
                                'links': [],
                                'text': i18n.translate('i7g4tsfm.structure.custom.template.items.1.i949n6fg')
                            }
                        }, {
                            'title': i18n.translate('i7g4tsfm.structure.custom.template.items.2.title'),
                            'price': i18n.translate('i7g4tsfm.structure.custom.template.items.2.price'),
                            'links': {
                                '_type': 'wix:Map',
                                'i948smva': {
                                    'type': 'ExternalLink',
                                    'url': 'http://www.wix.com',
                                    'target': '_blank'
                                }
                            },
                            'i7g4fg1v': {
                                '_type': 'wix:Image',
                                'title': '',
                                'src': 'bef2ef0f0a3343aba2fa7e746baa781c.jpg',
                                'width': 4434,
                                'height': 3729,
                                'alt': ''
                            },
                            'i7g4kibe': {
                                '_type': 'wix:Video',
                                'videoId': 'wGOTBSlYkzU',
                                'videoType': 'YOUTUBE',
                                'thumbnail': ''
                            },
                            'i948h3ky': i18n.translate('i7g4tsfm.structure.custom.template.items.2.i948h3ky'),
                            'i948kd8k': {'_type': 'wix:Date', 'iso': '2023-05-28T00:00:00.000Z'},
                            'i948smva': i18n.translate('i7g4tsfm.structure.custom.template.items.2.i948smva'),
                            'i949n6fg': {
                                'version': 1,
                                '_type': 'wix:RichText',
                                'links': [],
                                'text': i18n.translate('i7g4tsfm.structure.custom.template.items.2.i949n6fg')
                            }
                        }],
                        'displayName': i18n.translate('i7g4tsfm.structure.custom.template.displayName')
                    }
                },
                'id': 'i7g4tsfm'
            },
            'preset': {
                'rect': {'width': 162, 'height': 85, 'x': 162, 'y': 1342.25},
                'label': 'i7g4tsfm.structure.custom.template.displayName',
                'tags': null
            }
        }],
        'compTypes': ['wixapps.integration.components.AppPart2']
    },
    'help': {'hide': false, 'text': 'add_section_info_text_productservices'}
}
