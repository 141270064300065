import { ToursApiKey } from './api';
import { createNewWorkspaceFirstTimeTour } from './tours/newWorkspaceFirstTimeTour.conf';

import type { EditorAPI } from '@/editorAPI';

export const startNewWorkspaceTour = async (editorAPI: EditorAPI) => {
  // TODO: when we have panels and topbar api's
  // should be mooved from (editorAPI) => ... to its own package / api
  const tourApi = editorAPI.host.getAPI(ToursApiKey);
  const welcomeTour = tourApi.createTour(createNewWorkspaceFirstTimeTour);

  return await tourApi.startTour(welcomeTour);
};

export { createNewWorkspaceFirstTimeTour };
