import _ from 'lodash';
import * as util from '@/util';
import { LEGACY_WIX_APPS_NAMES } from '@/constants';
import type { EditorAPI } from '@/editorAPI';

import type {
  SectionItem,
  NewsItem,
  ItemRef,
  AppPartComponent,
  FetchListResult,
} from './types';

import { PART_NAME_TO_DATASTORE_ID } from './constants';
import { isNewsAppPartName } from './utils';
import { convertNewsItems } from './news/convertNewsItems';
import { convertFAQItems } from './faq/convertFAQItems';
import { convertMenuItems } from './menus/convertMenuItems';

export const createListFetcher =
  <TItem, TReferencedItem>(dataStoreId: string) =>
  ({
    collectionId,
    itemId,
  }: ItemRef): Promise<FetchListResult<TItem, TReferencedItem>> => {
    return util.http.fetchJson(
      'https://editor.wix.com/apps/lists/1/ReadItem?consistentRead=true',
      {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json; charset=UTF-8',
        }),
        body: JSON.stringify({
          autoDereferenceLevel: 3,
          collectionId,
          itemId,
          storeId: dataStoreId,
        }),
      },
    );
  };

const getUniqueAppPartComps = (comps: AppPartComponent[]): ItemRef[] => {
  return (
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/map
    _(comps)
      // eslint-disable-next-line lodash/prop-shorthand
      .map((comp) => comp.appLogicParams)
      .filter(({ collectionId, itemId }) => Boolean(collectionId && itemId))
      .map(({ collectionId, itemId }) => ({
        collectionId: collectionId.value,
        itemId: itemId.value,
      }))
      .uniqBy(({ collectionId, itemId }) => `${collectionId}/${itemId}`)
      .value()
  );
};

export const getMenuItems = async (editorAPI: EditorAPI) => {
  const fetchList = createListFetcher<any, any>(
    editorAPI.wixapps.classics.getDataStoreId(
      PART_NAME_TO_DATASTORE_ID[LEGACY_WIX_APPS_NAMES.RESTAURANTS_MENUS],
    ),
  );

  const allAppPartComps =
    editorAPI.wixapps.classics.getAllAppPartComps() as AppPartComponent[];

  const comps = allAppPartComps.filter(
    (comp) => comp.appPartName === LEGACY_WIX_APPS_NAMES.RESTAURANTS_MENUS,
  );

  const uniqueCompsByDataSource = getUniqueAppPartComps(comps);

  const fetchResults = await Promise.all(
    uniqueCompsByDataSource.map(fetchList),
  );

  return convertMenuItems(fetchResults.filter((req) => req.success));
};

export const getNewsItems = async (editorAPI: EditorAPI) => {
  const fetchList = createListFetcher<SectionItem, NewsItem>(
    editorAPI.wixapps.classics.getDataStoreId(
      PART_NAME_TO_DATASTORE_ID[LEGACY_WIX_APPS_NAMES.NEWS_A],
    ),
  );

  const allAppPartComps =
    editorAPI.wixapps.classics.getAllAppPartComps() as AppPartComponent[];

  const comps = allAppPartComps.filter((comp) =>
    isNewsAppPartName(comp.appPartName),
  );

  const uniqueCompsByDataSource = getUniqueAppPartComps(comps);

  const fetchResults = await Promise.all(
    uniqueCompsByDataSource.map(fetchList),
  );

  return convertNewsItems(fetchResults.filter((req) => req.success));
};

export const getFAQItems = async (editorAPI: EditorAPI) => {
  const { getDataStoreId } = editorAPI.wixapps.classics;
  const { appLogicParams } = editorAPI.selection.getSelectedComponentData();
  const collectionId = appLogicParams.collectionId?.value;
  const itemId = appLogicParams.itemId?.value;
  const dataStoreId = getDataStoreId(
    PART_NAME_TO_DATASTORE_ID[LEGACY_WIX_APPS_NAMES.FAQ],
  );
  const fetchList = createListFetcher<any, any>(dataStoreId);
  const result = await fetchList({ collectionId, itemId });

  if (!result.success) {
    return null;
  }

  return convertFAQItems(result);
};
