import type { Scope } from './customAuthenticationEntryPoint';
import { BasePublicApi } from '@/apilib';
import { LIGHTBOX_DEF, RESPONSIVE_LAYOUT } from './customSignup';
import { isResponsiveEditor } from '@wix/santa-editor-utils';

function getLightboxStructure() {
  const structure = LIGHTBOX_DEF;

  if (isResponsiveEditor()) {
    // add responsive editor structure
    // @ts-expect-error responsive layout
    LIGHTBOX_DEF.components[0].layouts = RESPONSIVE_LAYOUT.popup;
    LIGHTBOX_DEF.components[0].skin = RESPONSIVE_LAYOUT.popupContainerSkin;
    // @ts-expect-error responsive layout
    LIGHTBOX_DEF.components[0].components[0].layouts =
      RESPONSIVE_LAYOUT.closeButton;
    // @ts-expect-error responsive layout
    LIGHTBOX_DEF.components[0].components[1].layouts =
      RESPONSIVE_LAYOUT.appWidget;
  }
  return structure;
}

export class CustomAuthenticationApi extends BasePublicApi<Scope> {
  getLightboxStructure = this.bindScope(getLightboxStructure);
}
