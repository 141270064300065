import React from 'react';
import { symbol as Symbol } from '@wix/santa-editor-symbols';
import {
  ContextMenu,
  ContextMenuAction,
  ContextMenuContent,
  TextLabel,
} from '@wix/wix-base-ui';
import { ContextMenu as ContextMenuIcon } from '@wix/wix-ui-icons-common/classic-editor';

import type {
  ContextMenuConfig,
  ContextMenuItem,
} from '../workspaceRightPanelApi';

export interface HeaderContextMenuProps {
  contextMenuConfig: ContextMenuConfig;
}

function getContextMenuKey(items: ContextMenuItem[]) {
  return items.map(({ id }) => id).join(',');
}

const HeaderContextMenu: React.FC<HeaderContextMenuProps> = ({
  contextMenuConfig,
}) => {
  const { onContextMenuOpen, items } = contextMenuConfig;
  return (
    <ContextMenu
      // HACK: in base ui, the context menu doesn't update dropdown width when updating items without re-render.
      key={getContextMenuKey(items)}
      onToggle={(wasClosed: boolean) => {
        if (typeof onContextMenuOpen === 'function' && wasClosed) {
          onContextMenuOpen();
        }
      }}
      customButton={<ContextMenuIcon />}
    >
      <ContextMenuContent>
        {items.map(({ id, onClick, icon, label }) => (
          <ContextMenuAction
            automationId={id}
            key={id}
            className="add-state-action"
            onClick={onClick}
          >
            <Symbol name={icon} />
            <TextLabel enableEllipsis={false} value={label} />
          </ContextMenuAction>
        ))}
      </ContextMenuContent>
    </ContextMenu>
  );
};

export default HeaderContextMenu;
