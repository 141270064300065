// @ts-nocheck
import _ from 'lodash';
import constants from '@/constants';
import gfppDataUtils from '../../utils/gfppDataUtils';

const { ACTIONS } = constants.ROOT_COMPS.GFPP;

function openAviaryPanel(editorAPI, compRef) {
  const compData = editorAPI.components.data.get(compRef);
  const panelData = {
    value: compData.background.mediaRef, //Assume data and image
    requestChange(editedImageData) {
      const newData = editorAPI.components.data.get(compRef);
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      _.assign(newData.background.mediaRef, editedImageData);
      delete newData.background.mediaRef.id;
      if (editedImageData) {
        editorAPI.components.data.update(compRef, newData);
      }
    },
  };

  editorAPI.panelHelpers.openAviaryPanel(panelData);
}

export default {
  untranslatable: true,
  mainActions: [
    {
      label: 'gfpp_mainaction_stripContainer',
      isApplied: true,
      isSelected: gfppDataUtils.getPanelStateFn(ACTIONS.SETTINGS),
      onClick: gfppDataUtils.getTogglePanelFn(ACTIONS.SETTINGS),
    },
  ],
  enabledActions() {
    return [ACTIONS.HELP];
  },
  mobileEnabledActions: [ACTIONS.HIDE, ACTIONS.HELP],
  presetActions: {
    aviary: {
      onClick: openAviaryPanel,
    },
    help: {
      helpId: '51b03690-c92e-4b67-8ba0-5606199aab84',
    },
  },
};
