import { BasePublicApi } from '@/apilib';
import type { Scope } from './scope';
import type React from 'react';
import type { ConditionalContributionFunction } from '@wix/wix-code-editor-adapter';
import type { ComponentType } from 'react';

const NullComponent: ComponentType = () => null;

function contributeVeloTreeComponent(
  scope: Scope,
  item: React.ComponentType<{}>,
  conditionOverrideFunction: ConditionalContributionFunction,
) {
  scope.slots.veloTreeComponents.contribute(item, () =>
    conditionOverrideFunction(() => true),
  );
}

function contributeWixCodeLoaderComponent(
  scope: Scope,
  item: React.ComponentType<{}>,
) {
  scope.slots.wixCodeLoaderComponents.contribute(item);
}

function contributeConsoleComponent(
  scope: Scope,
  item: React.ComponentType<{}>,
  conditionOverrideFunction: ConditionalContributionFunction,
) {
  scope.slots.consoleComponents.contribute(item, () =>
    // TODO: what originalConditionFunction should be passed here? In edix it's this - https://github.com/wix-private/editorx-santa-editor-bridge/blob/master/packages/responsive-wix-code-editor-adapter/src/createResponsiveWixCodeEditorAdapterAPI.tsx#L114-L117
    conditionOverrideFunction(() => true),
  );
}

function contributeCodeEditorComponent(
  scope: Scope,
  item: React.ComponentType<{}>,
  conditionOverrideFunction: ConditionalContributionFunction,
) {
  scope.slots.codeEditorComponents.contribute(item, () =>
    // TODO: what originalConditionFunction should be passed here? In edix it's this - https://github.com/wix-private/editorx-santa-editor-bridge/blob/master/packages/responsive-wix-code-editor-adapter/src/createResponsiveWixCodeEditorAdapterAPI.tsx#L114-L117
    conditionOverrideFunction(() => true),
  );
}

function getVeloTreeComponent(scope: Scope) {
  return (
    scope.slots.veloTreeComponents.getItems()[0]?.contribution || NullComponent
  );
}

function getWixCodeLoaderComponent(scope: Scope) {
  return (
    scope.slots.wixCodeLoaderComponents.getItems()[0]?.contribution ||
    NullComponent
  );
}

function getConsoleComponent(scope: Scope) {
  return (
    scope.slots.consoleComponents.getItems()[0]?.contribution || NullComponent
  );
}

function getCodeEditorComponent(scope: Scope) {
  return (
    scope.slots.codeEditorComponents.getItems()[0]?.contribution ||
    NullComponent
  );
}

function setIsInLocalDevMode(scope: Scope, isInLocalDevMode: boolean) {
  scope.store.setIsInLocalDevMode(isInLocalDevMode);
}

function getIsInLocalDevMode(scope: Scope) {
  return scope.store.isInLocalDevMode();
}

export class WixCodeWorkspaceApi extends BasePublicApi<Scope> {
  contributeVeloTreeComponent = this.bindScope(contributeVeloTreeComponent);
  contributeWixCodeLoaderComponent = this.bindScope(
    contributeWixCodeLoaderComponent,
  );
  contributeConsoleComponent = this.bindScope(contributeConsoleComponent);
  contributeCodeEditorComponent = this.bindScope(contributeCodeEditorComponent);

  getVeloTreeComponent = this.bindScope(getVeloTreeComponent);
  getWixCodeLoaderComponent = this.bindScope(getWixCodeLoaderComponent);
  getConsoleComponent = this.bindScope(getConsoleComponent);
  getCodeEditorComponent = this.bindScope(getCodeEditorComponent);

  setIsInLocalDevMode = this.bindScope(setIsInLocalDevMode);
  getIsInLocalDevMode = this.bindScope(getIsInLocalDevMode);

  hooks = this.scope.hooks;
}
