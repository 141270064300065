'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')

module.exports = {
    'type': addPanelDataConsts.SECTIONS_TYPES.PAGE,
    'hide': false,
    'title': 'add_section_label_add_page',
    'showSectionHeader': false,
    'additionalBehaviours': {
        'labelMode': addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
        'infoIcon': addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
        'hoverImageAction': addPanelDataConsts.HOVER_IMAGE_ACTIONS.NONE,
        'iconEnabledForComps': {}
    },
    'props': {
        items: [
            {
                title: 'add_section_add_page_blankpage_label',
                desc: 'add_section_add_page_pagepreset_description',
                tooltip: 'add_section_add_page_blankpage_tooltip',
                image: 'addPanelData/sections/addPagesSection/addBlankPage.png',
                disabledImage: 'addPanelData/sections/addPagesSection/addBlankPageDisabled.png',
                action: addPanelDataConsts.CLICK_ACTION.ADD_BLANK_PAGE,
                symbol: 'plusBig',
                id: addPanelDataConsts.CATEGORIES_ID.PAGE
            }
        ],
        'compTypes': ['mobile.core.components.Page'],
        'class': 'no-space-before-section invisible-header'
    }
}
