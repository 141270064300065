import type { EditorAPI } from '@/editorAPI';
import type { Dispatch, ThunkAction } from 'types/redux';
import { events } from '@/coreBi';
import _ from 'lodash';

const getEditorAPI: ThunkAction<EditorAPI> = (
  _dispatch,
  _getState,
  { editorAPI },
) => editorAPI;

import { REL_ATTR_HELP_ID } from '../../constants';

export const mapDispatchToProps = (dispatch: Dispatch) => {
  const editorAPI: EditorAPI = dispatch(getEditorAPI);

  return {
    openHelpCenter() {
      return editorAPI.panelManager.openHelpCenter(REL_ATTR_HELP_ID, null, {
        panel_name: 'linkPanel',
      });
    },
    sendExpandBI(
      category: string,
      subcategory: string,
      origin: string,
      isOpen: boolean,
    ) {
      return (
        isOpen &&
        editorAPI.bi.event(events.linkPanel.link_panel_drop_down_open, {
          category,
          subcategory,
          origin,
        })
      );
    },
    sendToggleRelValuesBI(
      category: string,
      prevValue: string[],
      newValue: string[],
      origin: string,
    ) {
      const addedValue = _.difference(newValue, prevValue);
      const removedValue = _.difference(prevValue, newValue);
      const rel_value = !!addedValue.length;
      const rel_name = addedValue.length ? addedValue[0] : removedValue[0];
      return editorAPI.bi.event(events.linkPanel.link_panel_drop_down_click, {
        category,
        rel_name,
        rel_value,
        origin,
      });
    },
  };
};
