export const createEnforcementService = () => {
  let isEnforcementEnabled = true;

  return {
    setEnforcementEnabled(_isEnforcementEnabled: boolean) {
      isEnforcementEnabled = _isEnforcementEnabled;
    },
    isEnforcementEnabled() {
      return isEnforcementEnabled;
    },
  };
};
