import type { Feature } from '@wix/deeplink-core';

import { deeplink } from '@/rEditor';

async function check(
  editorAPI: unknown,
  appData: unknown,
  token: unknown,
  feature: Feature,
) {
  return deeplink.check(feature);
}

async function show(
  editorAPI: unknown,
  appData: unknown,
  token: unknown,
  feature: Feature,
) {
  return deeplink.show(feature);
}

export { show, check };
