import pageSettingsDesktop from './panels/settings/desktop/pageSettingsDesktop';
import pageSettingsMobile from './panels/settings/mobile/pageSettingsMobile';
import {
  signUpSettingsDesktop,
  signInSettingsDesktop,
} from './panels/settings/desktop/signupSettingsDesktop';
import * as groupsApi from './utils/groupsApi';
import { createJoinCommunityStatusApi } from './utils/joinCommunityStatusApi';

const settingsPanels = {
  main: pageSettingsDesktop,
  mobile: pageSettingsMobile,
  signup: signUpSettingsDesktop,
  signIn: signInSettingsDesktop,
};

const utils = {
  groupsApi,
  createJoinCommunityStatusApi,
};

export { settingsPanels, utils };
