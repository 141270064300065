import { isEligible } from '@wix/ambassador-premium-features-manager-v1-feature/http';
import { HttpClient } from '@wix/http-client';
import constants from '@/constants';

import type { EditorAPI } from '@/editorAPI';

export const fetchIsStudioAccount = async (
  editorAPI: EditorAPI,
): Promise<boolean> => {
  const httpClient = new HttpClient({
    getAppToken: () =>
      editorAPI.dsRead.platform.getAppDataByApplicationId(
        constants.APPLICATIONS.META_SITE_APPLICATION_ID,
      )?.instance || '',
  });
  const {
    data: { isEligible: isStudioAccount },
  } = await httpClient.request(
    isEligible({
      uniqueName: 'wix_studio',
      includeParentFeature: false,
    }),
  );

  return isStudioAccount;
};
