import _ from 'lodash';
import type { StylableConfigGetter } from '@wix/editor-component-types';

import {
  createSelectorSetHideConfig,
  createSelectorSetFoldIntoConfig,
} from './utils';

export const MenuMode = {
  Wrap: 'wrap',
  Scroll: 'scroll',
};

export const SubmenuMode = {
  ColumnMenu: 'columnMenu',
  ColumnStretched: 'columnStretched',
  Flyout: 'flyout',
};

export const Selectors = {
  nav: '.root',
  menu: '.root::menu',
  menuItem: '.root::menuItem',
  menuItemLabel: '.root::menuItem::label',
  scrollButton: '.root::scrollButton',
  scrollButtonIcon: '.root::scrollButton::icon',
  submenu: '.root::columnsLayout',
  submenuItem: '.root::columnsLayout::menuItem',
  submenuItemLabel: '.root::columnsLayout::menuItem::label',
  submenuHeading: '.root::columnsLayout::heading',
  submenuHeadingLabel: '.root::columnsLayout::heading::label',
};

export const stylableHorizontalMenuStylableConfigOverride: StylableConfigGetter =
  ({ editorAPI, compData, compProperties }: any) => {
    const { menuMode, submenuMode } = compProperties as any;
    const { items: menuItems } = editorAPI.dsRead.menu.getById(
      compData.menuRef.replace('#', ''),
    );
    const hasSubmenuItems = menuItems.some(
      (menuItem: any) => menuItem.items.length > 0,
    );

    const hasSubSubmenuItems = menuItems.some((menuItem: any) =>
      menuItem.items.some((submenuItem: any) => submenuItem.items.length > 0),
    );

    const hasContainerItems = menuItems.some((menuItem: any) => menuItem.slot);

    const shouldShowSubSubConfiguration =
      hasSubSubmenuItems && submenuMode !== SubmenuMode.Flyout;

    const scrollButtonSelectorHidden = createSelectorSetHideConfig(
      Selectors.scrollButton,
      menuMode === MenuMode.Wrap,
    );

    const submenuSelectorHidden = createSelectorSetHideConfig(
      Selectors.submenu,
      !hasSubmenuItems && !hasContainerItems,
    );

    const submenuItemsSelectorHidden = createSelectorSetHideConfig(
      Selectors.submenuItem,
      !hasSubmenuItems,
    );

    const headingSelectorHidden = createSelectorSetHideConfig(
      Selectors.submenuHeading,
      !shouldShowSubSubConfiguration,
    );

    const menuItemLabelFoldIntoConfig = createSelectorSetFoldIntoConfig(
      Selectors.menuItemLabel,
      true,
    );

    const scrollButtonIconFoldIntoConfig = createSelectorSetFoldIntoConfig(
      Selectors.scrollButtonIcon,
      true,
    );

    const submenuItemLabelFoldIntoConfig = createSelectorSetFoldIntoConfig(
      Selectors.submenuItemLabel,
      true,
    );

    const submenuHeadingLabelFoldIntoConfig = createSelectorSetFoldIntoConfig(
      Selectors.submenuHeadingLabel,
      true,
    );

    return _.merge(
      headingSelectorHidden,
      scrollButtonSelectorHidden,
      submenuSelectorHidden,
      submenuItemsSelectorHidden,
      menuItemLabelFoldIntoConfig,
      scrollButtonIconFoldIntoConfig,
      submenuItemLabelFoldIntoConfig,
      submenuHeadingLabelFoldIntoConfig,
    );
  };
