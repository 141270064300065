'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')
const util = require('@/util')
const languageCode = util.languages.getLanguageCode()

module.exports = {
    'type': addPanelDataConsts.SECTIONS_TYPES.PRESET,
    'hide': false,
    'title': 'add_section_label_itunes',
    'subNavigationTitle': 'add_section_label_itunes',
    'presetTitle': 'add_section_label_itunes',
    'tooltipTitle': 'add_section_label_itunes',
    "automationId": "add-panel-section-iTunesDownloadButtonSection",
    'subNavigationHide': false,
    'showSectionHeader': true,
    'additionalBehaviours': {
        'labelMode': addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
        'infoIcon': addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
        'hoverImageAction': addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
        'iconEnabledForComps': {}
    },
    'props': {
        'image': `addPanelData/sections/iTunesDownloadButtonSection_${languageCode}/iTunesDownloadButtonSection_${languageCode}.png`,
        'imageHover': null,
        'items': [{
            'id': 'Music_iTunes_Download_Button_1',
            'structure': {
                'type': 'Component',
                'skin': 'skins.viewer.itunesbutton.ItunesButtonSkin',
                'layout': {
                    'width': 110,
                    'height': 40,
                    'x': 10,
                    'y': 15,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.viewer.components.ItunesButton',
                'data': {
                    'type': 'ItunesButton',
                    'metaData': {'isPreset': true, 'schemaVersion': '1.0', 'isHidden': false},
                    'downloadUrl': ''
                },
                'props': {
                    'type': 'ItunesButtonProperties',
                    'metaData': {'schemaVersion': '1.0'},
                    'language': 'en',
                    'openIn': '_blank'
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'id': 'ib1',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {'groups': {}, 'properties': {}, 'propertiesSource': {}},
                    'componentClassName': '',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'system',
                    'skin': 'skins.viewer.itunesbutton.ItunesButtonSkin'
                },
                'id': 'i6g5hd3t'
            },
            'preset': {'rect': {'width': 140, 'height': 68, 'x': 0, 'y': 0}, 'label': '', 'tags': null}
        }],
        'compTypes': ['wysiwyg.viewer.components.ItunesButton']
    },
    'help': {'hide': false, 'text': 'add_section_info_text_itunes'}
}
