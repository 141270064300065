'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')

module.exports = {
    "type": addPanelDataConsts.SECTIONS_TYPES.PRESET,
    "hide": false,
    "title": "Popups_Add_Category_Welcome_Title",
    "subNavigationTitle": "Popups_Add_Category_Welcome_Title",
    "presetTitle": "Popups_Add_Category_Welcome_Title",
    "tooltipTitle": "Popups_Add_Category_Welcome_Title",
    "automationId": "add-panel-section-popupWelcomeSection",
    "subNavigationHide": false,
    "showSectionHeader": true,
    "additionalBehaviours": {
        "labelMode": addPanelDataConsts.LABEL_BEHAVIOUR.HOVER,
        "infoIcon": addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
        "hoverImageAction": addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
        "iconEnabledForComps": {}
    },
    "props": {
        "image": "addPanelData/sections/popups_en/popups_welcome.v2.png",
        "imageHover": null,
        "items": [{
            "id": "add_preset_popups_Welcome_1",
            "structure": {
                "type": "Page",
                "id": "ncx68",
                "componentTypeForBI": "wysiwyg.viewer.components.PopupContainer",
                "components": [{
                    "type": "Container",
                    "id": "comp-im0b4ykv",
                    "components": [{
                        "type": "Component",
                        "id": "comp-im0b4yl8",
                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                        "layout": {
                            "width": 799,
                            "height": 178,
                            "x": 10,
                            "y": 8,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<h2 style=\"text-align: center; font-size: 70px; line-height: 1.1em;\" class=\"font_2\"><span style=\"font-family:lulo-clean-w01-one-bold,sans-serif;\"><span style=\"line-height:1.1em;\"><span style=\"color:#07DBD9;\"><span style=\"font-size:70px;\"><span style=\"letter-spacing:-0.1em;\">Welcome </span></span></span></span></span></h2><h2 style=\"text-align: center; font-size: 70px; line-height: 1.1em;\" class=\"font_2\"><span style=\"font-family:lulo-clean-w01-one-bold,sans-serif;\"><span style=\"line-height:1.1em;\"><span style=\"color:#07DBD9;\"><span style=\"font-size:70px;\"><span style=\"letter-spacing:-0.1em;\">to </span></span></span></span></span><span style=\"font-family:lulo-clean-w01-one-bold,sans-serif;\"><span style=\"line-height:1.1em;\"><span style=\"color:#07DBD9;\"><span style=\"font-size:70px;\"><span style=\"letter-spacing:-0.1em;\">My Site!</span></span></span></span></span></h2>",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": [],
                            "id": "dataItem-im0b4yl9"
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true,
                            "id": "propItem-ipf7vbiv"
                        },
                        "style": "txtNew"
                    }, {
                        "type": "Component",
                        "id": "comp-im0by1vv",
                        "layout": {
                            "width": 547,
                            "height": 60,
                            "x": 139,
                            "y": 217,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<p class=\"font_8\" style=\"font-size:20px; line-height:1.4em; text-align:center\"><span style=\"color:#FFFFFE;\"><span style=\"font-family:futura-lt-w01-light,sans-serif\"><span style=\"font-size:20px\">Add catchy text here to give your visitors a </span></span></span></p>\n\n<p class=\"font_8\" style=\"font-size:20px; line-height:1.4em; text-align:center\"><span style=\"color:#FFFFFE;\"><span style=\"font-family:futura-lt-w01-light,sans-serif\"><span style=\"font-size:20px\">friendly first impression.</span></span></span></p>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": [],
                            "id": "dataItem-im0by1vy"
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true,
                            "id": "propItem-im0by1vz"
                        },
                        "style": "txtNew"
                    }, {
                        "type": "Component",
                        "id": "comp-imerh1uo",
                        "skin": "wysiwyg.viewer.skins.button.BasicButton",
                        "layout": {
                            "width": 175,
                            "height": 40,
                            "x": 325,
                            "y": 332,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.PopupCloseTextButton",
                        "data": {
                            "type": "LinkableButton",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "label": "ENTER SITE",
                            "id": "dataItem-imerh1us"
                        },
                        "props": {
                            "type": "ButtonProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "align": "center",
                            "margin": 0,
                            "id": "propItem-imerh1ut"
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "alpha-bg": "1",
                                    "alpha-bgh": "1",
                                    "alpha-brd": "1",
                                    "alpha-brdh": "0",
                                    "alpha-txt": "1",
                                    "alpha-txth": "1",
                                    "bg": "#18C1C0",
                                    "bgh": "#36D9D8",
                                    "boxShadowToggleOn-shd": "false",
                                    "brd": "#BD1515",
                                    "brdh": "#B08484",
                                    "brw": "0",
                                    "fnt": "normal normal normal 15px/1.4em raleway",
                                    "rd": "0px",
                                    "shd": "0 1px 4px rgba(0, 0, 0, 0.6);",
                                    "txt": "#FFFFFF",
                                    "txth": "#FFFFFF"
                                },
                                "propertiesSource": {
                                    "alpha-brdh": "value",
                                    "bg": "value",
                                    "bgh": "value",
                                    "brd": "value",
                                    "brdh": "value",
                                    "brw": "value",
                                    "fnt": "value",
                                    "rd": "value",
                                    "shd": "value",
                                    "txt": "value",
                                    "txth": "value"
                                }
                            },
                            "componentClassName": "wysiwyg.viewer.components.PopupCloseTextButton",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.button.BasicButton",
                            "id": "style-imkqf1em"
                        }
                    }, {
                        "type": "Component",
                        "skin": "svgshape.v2.Svg_001419cc721a440abc4ab12ed150b744",
                        "layout": {
                            "width": 44,
                            "height": 44,
                            "x": 922,
                            "y": 50,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": true,
                            "docked": {
                                "top": {
                                    "px": 50
                                },
                                "right": {
                                    "px": 60
                                }
                            }
                        },
                        "componentType": "wysiwyg.viewer.components.PopupCloseIconButton",
                        "data": {
                            "type": "SvgShape",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "1.0",
                                "isHidden": false
                            }
                        },
                        "props": {
                            "type": "SvgShapeProperties",
                            "metaData": {
                                "schemaVersion": "1.0"
                            },
                            "maintainAspectRatio": true
                        },
                        "style": {
                            "type": "ComponentStyle",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "1.0",
                                "isHidden": false
                            },
                            "style": {
                                "properties": {
                                    "alpha-fillcolor": "1",
                                    "alpha-stroke": "1",
                                    "fillcolor": "#FFFFFF",
                                    "stroke": "#2E2525",
                                    "strokewidth": "1"
                                },
                                "propertiesSource": {
                                    "alpha-fillcolor": "value",
                                    "alpha-stroke": "value",
                                    "fillcolor": "value",
                                    "stroke": "value",
                                    "strokewidth": "value"
                                },
                                "groups": {}
                            },
                            "componentClassName": "wysiwyg.viewer.components.PopupCloseIconButton",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "svgshape.v2.Svg_001419cc721a440abc4ab12ed150b744"
                        },
                        "connections": {
                            "type": "ConnectionList",
                            "items": [
                                {
                                    "type": "WixCodeConnectionItem",
                                    "role": "button1"
                                }
                            ]
                        },
                        "mobileStructure": {
                            "layout": {
                                "width": 28,
                                "height": 28,
                                "x": 263,
                                "y": -43,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "metaData": {
                                "originalCompId": "comp-isrf0jhp",
                                "author": "duplicate"
                            }
                        },
                        "activeModes": {}
                    }],
                    "skin": "wysiwyg.viewer.skins.stripContainer.DefaultStripContainer",
                    "layout": {
                        "width": 826,
                        "height": 470,
                        "x": 77,
                        "y": 154,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.PopupContainer",
                    "props": {
                        "type": "PopupContainerProperties",
                        "metaData": {"schemaVersion": "1.0"},
                        "horizontalAlignment": "center",
                        "verticalAlignment": "center",
                        "alignmentType": "nineGrid",
                        "horizontalOffset": 0,
                        "verticalOffset": 0,
                        "id": "propItem-im0b4ykz"
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "color": "#FFFFFE",
                            "colorOpacity": 0,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        },
                        "id": "dataItem-im0b4yl0"
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {"alpha-bg": "1", "bg": "rgba(255,255,255,1)"},
                            "propertiesSource": {"alpha-bg": "value", "bg": "value"}
                        },
                        "componentClassName": "wysiwyg.viewer.components.PopupContainer",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.stripContainer.DefaultStripContainer",
                        "id": "style-im0b4yl1"
                    },
                    "behaviors": {
                        "type": "ObsoleteBehaviorsList",
                        "items": "[{\"name\":\"FadeIn\",\"delay\":0.1,\"duration\":0.75,\"action\":\"screenIn\",\"targetId\":\"\",\"params\":{}},{\"name\":\"FadeOut\",\"delay\":0.1,\"duration\":0.75,\"action\":\"exit\",\"targetId\":\"\",\"params\":{}}]",
                        "id": "behavior-iomlwee2"
                    }
                }],

                "skin": "skins.core.InlineSkin",
                "layout": {
                    "width": 980,
                    "height": 624,
                    "x": 0,
                    "y": 0,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "mobile.core.components.Page",
                "data": {
                    "type": "Page",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "title": "add_preset_popups_Welcome_1",
                    "hideTitle": true,
                    "icon": "",
                    "descriptionSEO": "",
                    "metaKeywordsSEO": "",
                    "pageTitleSEO": "",
                    "pageUriSEO": "blank-ncx68",
                    "hidePage": true,
                    "underConstruction": false,
                    "tpaApplicationId": 0,
                    "pageSecurity": {"requireLogin": false, "passwordDigest": "", "dialogLanguage": ""},
                    "isPopup": true,
                    "indexable": false,
                    "isLandingPage": false,
                    "pageBackgrounds": {
                        "desktop": {
                            "ref": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "#000000",
                                "colorOpacity": 0.8,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            }
                        },
                        "mobile": {
                            "custom": true,
                            "ref": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "#000000",
                                "colorOpacity": 0.8,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "scroll",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            },
                            "isPreset": true
                        }
                    },
                    "ignoreBottomBottomAnchors": true
                },
                "props": {
                    "type": "PageProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "desktop": {"popup": {"closeOnOverlayClick": true}},
                    "mobile": {"popup": {"closeOnOverlayClick": true}},
                    "id": "ncx68"
                },
                "style": "p1",
                "tpaComps": []
            },
            "preset": {
                "rect": {"width": 305, "height": 202, "x": 10, "y": 20},
                "label": "add_preset_popups_Welcome_1",
                "tags": null
            }
        }, {
            "id": "add_preset_popups_Welcome_3",
            "structure": {
                "type": "Page",
                "id": "wtthx",
                "componentTypeForBI": "wysiwyg.viewer.components.PopupContainer",
                "components": [{
                    "type": "Container",
                    "id": "comp-im0cplv2",
                    "components": [{
                        "type": "Container",
                        "id": "comp-im0cplv8",
                        "components": [{
                            "type": "Component",
                            "id": "comp-im0d1mfs",
                            "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                            "layout": {
                                "width": 589,
                                "height": 124,
                                "x": 0,
                                "y": 38,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 class=\"font_2\" style=\"font-size:51px; line-height:1.1em; text-align:center\"><span style=\"color:#000000;\"><span style=\"font-family:spinnaker,sans-serif\"><span style=\"font-size:51px\">WELCOME TO</span></span></span></h2>\n\n<h2 class=\"font_2\" style=\"font-size:51px; line-height:1.1em; text-align:center\"><span style=\"color:#000000;\"><span style=\"font-family:spinnaker,sans-serif\"><span style=\"font-size:51px\">MY SITE!</span></span></span></h2>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": [],
                                "id": "dataItem-im0d1mfx"
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "brightness": 1,
                                "packed": true,
                                "id": "propItem-ipf84yjc"
                            },
                            "style": "txtNew"
                        }, {
                            "type": "Component",
                            "id": "comp-im0dgfqy",
                            "layout": {
                                "width": 589,
                                "height": 46,
                                "x": 0,
                                "y": 176,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p class=\"font_8\" style=\"font-size:16px; line-height:1.4em; text-align:center\"><span style=\"color:#000000;\"><span style=\"font-family:spinnaker,sans-serif\">Hey there. Start the conversation with your users </span></span></p>\n\n<p class=\"font_8\" style=\"font-size:16px; line-height:1.4em; text-align:center\"><span style=\"color:#000000;\"><span style=\"font-family:spinnaker,sans-serif\">right here in this opening paragraph.</span></span></p>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": [],
                                "id": "dataItem-im0dgfr2"
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "brightness": 1,
                                "packed": true,
                                "id": "propItem-im0dgfr4"
                            },
                            "style": "txtNew"
                        }, {
                            "type": "Component",
                            "id": "comp-imerxa8c",
                            "skin": "wysiwyg.viewer.skins.button.BasicButton",
                            "layout": {
                                "width": 174,
                                "height": 44,
                                "x": 207,
                                "y": 249,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.PopupCloseTextButton",
                            "data": {
                                "type": "LinkableButton",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "label": "Go To Site",
                                "id": "dataItem-imerxa8e"
                            },
                            "props": {
                                "type": "ButtonProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "align": "center",
                                "margin": 0,
                                "id": "propItem-imerxa8f"
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {
                                        "alpha-bg": "1",
                                        "alpha-bgh": "1",
                                        "alpha-brd": "1",
                                        "alpha-brdh": "1",
                                        "alpha-txt": "1",
                                        "alpha-txth": "1",
                                        "bg": "#000000",
                                        "bgh": "#3D3D3D",
                                        "boxShadowToggleOn-shd": "false",
                                        "brd": "#BD1515",
                                        "brdh": "#B08484",
                                        "brw": "0",
                                        "fnt": "normal normal normal 15px/1.4em spinnaker",
                                        "rd": "0px",
                                        "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                        "txt": "#FFFFFF",
                                        "txth": "#FFFFFF"
                                    },
                                    "propertiesSource": {
                                        "bg": "value",
                                        "bgh": "value",
                                        "brd": "value",
                                        "brdh": "value",
                                        "brw": "value",
                                        "fnt": "value",
                                        "rd": "value",
                                        "shd": "value",
                                        "txt": "value",
                                        "txth": "value"
                                    }
                                },
                                "componentClassName": "wysiwyg.viewer.components.PopupCloseTextButton",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.button.BasicButton",
                                "id": "style-it8dvrwk"
                            }
                        }],
                        "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                        "layout": {
                            "width": 589,
                            "height": 343,
                            "x": 39,
                            "y": 41,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "mobile.core.components.Container",
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "alpha-bg": "1",
                                    "alpha-brd": "1",
                                    "bg": "#FDDB51",
                                    "boxShadowToggleOn-shd": "false",
                                    "brd": "#660658",
                                    "brw": "0",
                                    "rd": "0px",
                                    "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                                },
                                "propertiesSource": {
                                    "bg": "value",
                                    "brd": "value",
                                    "brw": "value",
                                    "rd": "value",
                                    "shd": "value"
                                }
                            },
                            "componentClassName": "mobile.core.components.Container",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                            "id": "style-im0dczjq"
                        }
                    }, {
                        "type": "Component",
                        "skin": "svgshape.v2.Svg_001419cc721a440abc4ab12ed150b744",
                        "layout": {
                            "width": 40,
                            "height": 40,
                            "x": 947,
                            "y": 40,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": true,
                            "docked": {
                                "top": {
                                    "px": 40
                                },
                                "right": {
                                    "px": 40
                                }
                            }
                        },
                        "componentType": "wysiwyg.viewer.components.PopupCloseIconButton",
                        "data": {
                            "type": "SvgShape",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "1.0",
                                "isHidden": false
                            }
                        },
                        "props": {
                            "type": "SvgShapeProperties",
                            "metaData": {
                                "schemaVersion": "1.0"
                            },
                            "maintainAspectRatio": true
                        },
                        "style": {
                            "type": "ComponentStyle",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "1.0",
                                "isHidden": false
                            },
                            "style": {
                                "properties": {
                                    "alpha-fillcolor": "1",
                                    "alpha-stroke": "1",
                                    "fillcolor": "#000000",
                                    "stroke": "#EDE5E5",
                                    "strokewidth": "1"
                                },
                                "propertiesSource": {
                                    "alpha-fillcolor": "value",
                                    "alpha-stroke": "value",
                                    "fillcolor": "value",
                                    "stroke": "value",
                                    "strokewidth": "value"
                                },
                                "groups": {}
                            },
                            "componentClassName": "wysiwyg.viewer.components.PopupCloseIconButton",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "svgshape.v2.Svg_001419cc721a440abc4ab12ed150b744"
                        },
                        "connections": {
                            "type": "ConnectionList",
                            "items": [
                                {
                                    "type": "WixCodeConnectionItem",
                                    "role": "button1"
                                }
                            ]
                        },
                        "mobileStructure": {
                            "layout": {
                                "width": 26,
                                "height": 26,
                                "x": 285,
                                "y": -35,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "metaData": {
                                "originalCompId": "comp-isrf74zw",
                                "author": "duplicate"
                            }
                        },
                        "activeModes": {}
                    }],
                    "skin": "wysiwyg.viewer.skins.stripContainer.DefaultStripContainer",
                    "layout": {
                        "width": 667,
                        "height": 424,
                        "x": 156,
                        "y": 34,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.PopupContainer",
                    "props": {
                        "type": "PopupContainerProperties",
                        "metaData": {"schemaVersion": "1.0"},
                        "horizontalAlignment": "center",
                        "verticalAlignment": "center",
                        "alignmentType": "nineGrid",
                        "horizontalOffset": 0,
                        "verticalOffset": 0,
                        "id": "propItem-im0cplv3"
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "mediaRef": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "title": "Pop Art",
                                "uri": "338bcf1ccf184340a38279c8b9025f8d.jpg",
                                "description": "public/6d05b962-a2eb-42f1-afe4-7981bca4bff1/ea934aee-5729-4c5e-aed1-ac25f5489914",
                                "width": 1920,
                                "height": 1280,
                                "alt": "",
                                "artist": {"id": "", "name": ""}
                            },
                            "color": "#FFFFFE",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        },
                        "id": "dataItem-im0cplv4"
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {"alpha-bg": "1", "bg": "rgba(255,255,255,1)"},
                            "propertiesSource": {"alpha-bg": "value", "bg": "value"}
                        },
                        "componentClassName": "wysiwyg.viewer.components.PopupContainer",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.stripContainer.DefaultStripContainer",
                        "id": "style-im0cplv6"
                    },
                    "behaviors": {
                        "type": "ObsoleteBehaviorsList",
                        "items": "[{\"name\":\"FadeIn\",\"delay\":0.1,\"duration\":0.75,\"action\":\"screenIn\",\"targetId\":\"\",\"params\":{}},{\"name\":\"FadeOut\",\"delay\":0.1,\"duration\":0.75,\"action\":\"exit\",\"targetId\":\"\",\"params\":{}}]",
                        "id": "behavior-iomlweei"
                    }
                }],

                "skin": "skins.core.InlineSkin",
                "layout": {
                    "width": 980,
                    "height": 458,
                    "x": 0,
                    "y": 0,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "mobile.core.components.Page",
                "data": {
                    "type": "Page",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "title": "add_preset_popups_Welcome_3",
                    "hideTitle": true,
                    "icon": "",
                    "descriptionSEO": "",
                    "metaKeywordsSEO": "",
                    "pageTitleSEO": "",
                    "pageUriSEO": "copy-of-sale3-wtthx",
                    "hidePage": true,
                    "underConstruction": false,
                    "tpaApplicationId": 0,
                    "pageSecurity": {"requireLogin": false, "passwordDigest": "", "dialogLanguage": ""},
                    "isPopup": true,
                    "indexable": false,
                    "isLandingPage": false,
                    "pageBackgrounds": {
                        "desktop": {
                            "ref": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "#EDEDED",
                                "colorOpacity": 0.85,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            }
                        },
                        "mobile": {
                            "custom": true,
                            "ref": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "#EDEDED",
                                "colorOpacity": 0.85,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "scroll",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            },
                            "isPreset": true
                        }
                    },
                    "ignoreBottomBottomAnchors": true
                },
                "props": {
                    "type": "PageProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "desktop": {"popup": {"closeOnOverlayClick": true}},
                    "mobile": {"popup": {"closeOnOverlayClick": true}},
                    "id": "wtthx"
                },
                "style": "p1",
                "tpaComps": []
            },
            "preset": {
                "rect": {"width": 305, "height": 202, "x": 10, "y": 242},
                "label": "add_preset_popups_Welcome_3",
                "tags": null
            }
        }, {
            "id": "add_preset_popups_Welcome_2",
            "structure": {
                "type": "Page",
                "id": "tdfc1",
                "componentTypeForBI": "wysiwyg.viewer.components.PopupContainer",
                "components": [{
                    "type": "Container",
                    "id": "comp-im0cclw2",
                    "components": [{
                        "type": "Component",
                        "id": "comp-im0cclwa",
                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                        "layout": {
                            "width": 547,
                            "height": 122,
                            "x": 37,
                            "y": 28,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<h2 class=\"font_2\" style=\"font-size:175px; line-height:0.6em; text-align:center\"><span style=\"color:#FFFFFE;\"><span style=\"font-size:175px\"><span style=\"font-family:spinnaker,sans-serif\">as</span></span></span></h2>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": [],
                            "id": "dataItem-im0cclwb"
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true,
                            "id": "propItem-ipf7znz8"
                        },
                        "style": "txtNew"
                    }, {
                        "type": "Component",
                        "id": "comp-im0cclwc",
                        "layout": {
                            "width": 547,
                            "height": 60,
                            "x": 37,
                            "y": 200,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<p class=\"font_8\" style=\"font-size:20px; line-height:1.4em; text-align:center\"><span style=\"color:#FFFFFE;\"><span style=\"font-size:20px\"><span style=\"font-family:spinnaker,sans-serif\">Just add catchy text to introduce your site and give visitors an awesome&nbsp;first impression.</span></span></span></p>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": [],
                            "id": "dataItem-im0cclwc1"
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true,
                            "id": "propItem-im0cclwd"
                        },
                        "style": "txtNew"
                    }, {
                        "type": "Component",
                        "id": "comp-im0citwf",
                        "skin": "wysiwyg.viewer.skins.line.SolidLine",
                        "layout": {
                            "width": 208,
                            "height": 9,
                            "x": 207,
                            "y": 84,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.FiveGridLine",
                        "props": {
                            "type": "FiveGridLineProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "fullScreenModeOn": false,
                            "id": "propItem-im0citwj"
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {"alpha-brd": "1", "brd": "#FFFFFF", "lnw": "9"},
                                "propertiesSource": {"brd": "value", "lnw": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.line.SolidLine",
                            "id": "style-im0ciy1d"
                        }
                    }, {
                        "type": "Component",
                        "id": "comp-imerkdqo",
                        "skin": "wysiwyg.viewer.skins.button.BasicButton",
                        "layout": {
                            "width": 175,
                            "height": 45,
                            "x": 223,
                            "y": 320,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.PopupCloseTextButton",
                        "data": {
                            "type": "LinkableButton",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "label": "DIVE IN",
                            "id": "dataItem-imerkdqq"
                        },
                        "props": {
                            "type": "ButtonProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "align": "center",
                            "margin": 0,
                            "id": "propItem-imerkdqq1"
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "alpha-bg": "1",
                                    "alpha-bgh": "1",
                                    "alpha-brd": "0.97",
                                    "alpha-brdh": "1",
                                    "alpha-txt": "1",
                                    "alpha-txth": "1",
                                    "bg": "#FFFFFF",
                                    "bgh": "#EDEDED",
                                    "boxShadowToggleOn-shd": "false",
                                    "brd": "#FFFFFF",
                                    "brdh": "#EDEDED",
                                    "brw": "0",
                                    "fnt": "normal normal normal 16px/1.4em spinnaker",
                                    "rd": "0px",
                                    "shd": "0 1px 4px rgba(0, 0, 0, 0.6);",
                                    "txt": "#000000",
                                    "txth": "#BD1515"
                                },
                                "propertiesSource": {
                                    "alpha-brd": "value",
                                    "bg": "value",
                                    "bgh": "value",
                                    "brd": "value",
                                    "brdh": "value",
                                    "brw": "value",
                                    "fnt": "value",
                                    "rd": "value",
                                    "shd": "value",
                                    "txt": "value",
                                    "txth": "value"
                                }
                            },
                            "componentClassName": "wysiwyg.viewer.components.PopupCloseTextButton",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.button.BasicButton",
                            "id": "style-imerl6v6"
                        }
                    }, {
                        "type": "Component",
                        "skin": "svgshape.v1.svg_7d72932f870a315ce1b030fcc29ba4e5.XMark",
                        "layout": {
                            "width": 42,
                            "height": 42,
                            "x": 944,
                            "y": 40,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": true,
                            "docked": {
                                "top": {
                                    "px": 40
                                },
                                "right": {
                                    "px": 40
                                }
                            }
                        },
                        "componentType": "wysiwyg.viewer.components.PopupCloseIconButton",
                        "data": {
                            "type": "SvgShape",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "1.0",
                                "isHidden": false
                            }
                        },
                        "props": {
                            "type": "SvgShapeProperties",
                            "metaData": {
                                "schemaVersion": "1.0"
                            },
                            "maintainAspectRatio": true
                        },
                        "style": {
                            "type": "ComponentStyle",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "1.0",
                                "isHidden": false
                            },
                            "style": {
                                "properties": {
                                    "alpha-fillcolor": "1",
                                    "alpha-stroke": "1",
                                    "fillcolor": "#FFFFFF",
                                    "stroke": "#2688B8",
                                    "strokewidth": "1"
                                },
                                "propertiesSource": {
                                    "alpha-fillcolor": "value",
                                    "alpha-stroke": "value",
                                    "fillcolor": "value",
                                    "stroke": "value",
                                    "strokewidth": "value"
                                },
                                "groups": {}
                            },
                            "componentClassName": "wysiwyg.viewer.components.PopupCloseIconButton",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "svgshape.v1.svg_7d72932f870a315ce1b030fcc29ba4e5.XMark"
                        },
                        "connections": {
                            "type": "ConnectionList",
                            "items": [
                                {
                                    "type": "WixCodeConnectionItem",
                                    "role": "button1"
                                }
                            ]
                        },
                        "mobileStructure": {
                            "layout": {
                                "width": 30,
                                "height": 30,
                                "x": 260,
                                "y": -46,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "metaData": {
                                "originalCompId": "comp-isrf445s",
                                "author": "duplicate"
                            }
                        },
                        "activeModes": {}
                    }],
                    "skin": "wysiwyg.viewer.skins.stripContainer.DefaultStripContainer",
                    "layout": {
                        "width": 622,
                        "height": 510,
                        "x": 179,
                        "y": 134,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.PopupContainer",
                    "props": {
                        "type": "PopupContainerProperties",
                        "metaData": {"schemaVersion": "1.0"},
                        "horizontalAlignment": "center",
                        "verticalAlignment": "center",
                        "alignmentType": "nineGrid",
                        "horizontalOffset": 0,
                        "verticalOffset": 0,
                        "id": "propItem-im0cclw4"
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "color": "#FFFFFE",
                            "colorOpacity": 0,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        },
                        "id": "dataItem-im0cclw5"
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {"alpha-bg": "1", "bg": "rgba(255,255,255,1)"},
                            "propertiesSource": {"alpha-bg": "value", "bg": "value"}
                        },
                        "componentClassName": "wysiwyg.viewer.components.PopupContainer",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.stripContainer.DefaultStripContainer",
                        "id": "style-im0cclw52"
                    }
                }],

                "skin": "skins.core.InlineSkin",
                "layout": {
                    "width": 980,
                    "height": 644,
                    "x": 0,
                    "y": 0,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "mobile.core.components.Page",
                "data": {
                    "type": "Page",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "title": "add_preset_popups_Welcome_2",
                    "hideTitle": true,
                    "icon": "",
                    "descriptionSEO": "",
                    "metaKeywordsSEO": "",
                    "pageTitleSEO": "",
                    "pageUriSEO": "copy-of-welcome-tdfc1",
                    "hidePage": true,
                    "underConstruction": false,
                    "tpaApplicationId": 0,
                    "pageSecurity": {"requireLogin": false, "passwordDigest": "", "dialogLanguage": ""},
                    "isPopup": true,
                    "indexable": false,
                    "isLandingPage": false,
                    "pageBackgrounds": {
                        "desktop": {
                            "ref": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "#0480B8",
                                "colorOpacity": 0.85,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            }
                        },
                        "mobile": {
                            "custom": true,
                            "ref": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "#0480B8",
                                "colorOpacity": 0.85,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "scroll",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            },
                            "isPreset": true
                        }
                    },
                    "ignoreBottomBottomAnchors": true
                },
                "props": {
                    "type": "PageProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "desktop": {"popup": {"closeOnOverlayClick": true}},
                    "mobile": {"popup": {"closeOnOverlayClick": true}},
                    "id": "tdfc1"
                },
                "style": "p1",
                "tpaComps": []
            },
            "preset": {
                "rect": {"width": 305, "height": 202, "x": 10, "y": 464},
                "label": "add_preset_popups_Welcome_2",
                "tags": null
            }
        }, {
            "id": "add_preset_popups_Welcome_6",
            "structure": {
                "type": "Page",
                "id": "yusqh",
                "componentTypeForBI": "wysiwyg.viewer.components.PopupContainer",
                "components": [{
                    "type": "Container",
                    "id": "comp-ipfe25b7",
                    "components": [{
                        "type": "Component",
                        "id": "comp-ipfe42rc",
                        "skin": "wysiwyg.viewer.skins.video.VideoDefault",
                        "layout": {
                            "width": 980,
                            "height": 560,
                            "x": 1,
                            "y": 69,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.Video",
                        "data": {
                            "type": "Video",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "videoId": "135171560",
                            "videoType": "VIMEO",
                            "id": "dataItem-ipfe42sb"
                        },
                        "props": {
                            "type": "VideoProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "autoplay": true,
                            "loop": false,
                            "showinfo": false,
                            "lightTheme": false,
                            "showControls": "always_show",
                            "id": "propItem-ipfe42sc"
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "alpha-brd": "1",
                                    "boxShadowToggleOn-shd": "true",
                                    "brd": "#00E3AA",
                                    "brw": "0",
                                    "rd": "0",
                                    "shd": "0 1px 4px rgba(0, 0, 0, 0.6)"
                                },
                                "propertiesSource": {
                                    "alpha-brd": "value",
                                    "boxShadowToggleOn-shd": "value",
                                    "brd": "value",
                                    "brw": "value",
                                    "rd": "value",
                                    "shd": "value"
                                }
                            },
                            "componentClassName": "wysiwyg.viewer.components.Video",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.video.VideoDefault",
                            "id": "style-im1t1gsp"
                        }
                    }, {
                        "type": "Component",
                        "skin": "svgshape.v2.Svg_001419cc721a440abc4ab12ed150b744",
                        "layout": {
                            "width": 36,
                            "height": 36,
                            "x": 947,
                            "y": 4,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.PopupCloseIconButton",
                        "data": {
                            "type": "SvgShape",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "1.0",
                                "isHidden": false
                            }
                        },
                        "props": {
                            "type": "SvgShapeProperties",
                            "metaData": {
                                "schemaVersion": "1.0"
                            },
                            "maintainAspectRatio": true
                        },
                        "style": {
                            "type": "ComponentStyle",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "1.0",
                                "isHidden": false
                            },
                            "style": {
                                "properties": {
                                    "alpha-fillcolor": "1",
                                    "alpha-stroke": "1",
                                    "fillcolor": "#FFFFFF",
                                    "stroke": "#000000",
                                    "strokewidth": "1"
                                },
                                "propertiesSource": {
                                    "alpha-fillcolor": "value",
                                    "alpha-stroke": "value",
                                    "fillcolor": "value",
                                    "stroke": "value",
                                    "strokewidth": "value"
                                },
                                "groups": {}
                            },
                            "componentClassName": "wysiwyg.viewer.components.PopupCloseIconButton",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "svgshape.v2.Svg_001419cc721a440abc4ab12ed150b744"
                        },
                        "connections": {
                            "type": "ConnectionList",
                            "items": [
                                {
                                    "type": "WixCodeConnectionItem",
                                    "role": "button1"
                                }
                            ]
                        },
                        "mobileStructure": {
                            "layout": {
                                "width": 26,
                                "height": 26,
                                "x": 268,
                                "y": 10,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "metaData": {
                                "originalCompId": "comp-isrf9y8e",
                                "author": "duplicate"
                            }
                        },
                        "activeModes": {}
                    }],
                    "skin": "wysiwyg.viewer.skins.stripContainer.DefaultStripContainer",
                    "layout": {
                        "width": 982,
                        "height": 645,
                        "x": -1,
                        "y": 66,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.PopupContainer",
                    "props": {
                        "type": "PopupContainerProperties",
                        "metaData": {"schemaVersion": "1.0"},
                        "horizontalAlignment": "center",
                        "verticalAlignment": "center",
                        "alignmentType": "nineGrid",
                        "horizontalOffset": 0,
                        "verticalOffset": 0,
                        "id": "propItem-ipfe25b9"
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "color": "#FFFFFE",
                            "colorOpacity": 0,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        },
                        "id": "dataItem-ipfe25b91"
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {"alpha-bg": "1", "bg": "rgba(255,255,255,1)"},
                            "propertiesSource": {"alpha-bg": "value", "bg": "value"}
                        },
                        "componentClassName": "wysiwyg.viewer.components.PopupContainer",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.stripContainer.DefaultStripContainer",
                        "id": "style-ipfe25bq"
                    },
                    "behaviors": {
                        "type": "ObsoleteBehaviorsList",
                        "items": "[{\"name\":\"FadeIn\",\"delay\":0.1,\"duration\":0.75,\"action\":\"screenIn\",\"targetId\":\"\",\"params\":{}},{\"name\":\"FadeOut\",\"delay\":0.1,\"duration\":0.75,\"action\":\"exit\",\"targetId\":\"\",\"params\":{}}]",
                        "id": "behavior-iomlwee2"
                    }
                }],

                "skin": "skins.core.InlineSkin",
                "layout": {
                    "width": 980,
                    "height": 711,
                    "x": 0,
                    "y": 0,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "mobile.core.components.Page",
                "data": {
                    "type": "Page",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "title": "add_preset_popups_Welcome_6",
                    "hideTitle": true,
                    "icon": "",
                    "descriptionSEO": "",
                    "metaKeywordsSEO": "",
                    "pageTitleSEO": "",
                    "pageUriSEO": "copy-of-welcome-yusqh",
                    "hidePage": true,
                    "underConstruction": false,
                    "tpaApplicationId": 0,
                    "pageSecurity": {"requireLogin": false, "passwordDigest": "", "dialogLanguage": ""},
                    "isPopup": true,
                    "indexable": false,
                    "isLandingPage": false,
                    "pageBackgrounds": {
                        "desktop": {
                            "ref": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "#000000",
                                "colorOpacity": 0.9,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            }
                        },
                        "mobile": {
                            "custom": true,
                            "ref": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "#000000",
                                "colorOpacity": 0.9,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "scroll",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            },
                            "isPreset": true
                        }
                    },
                    "translationData": {"uriSEOTranslated": false},
                    "ignoreBottomBottomAnchors": true
                },
                "props": {
                    "type": "PageProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "desktop": {"popup": {"closeOnOverlayClick": true}},
                    "mobile": {"popup": {"closeOnOverlayClick": true}},
                    "id": "yusqh"
                },
                "style": "p1",
                "tpaComps": []
            },
            "preset": {
                "rect": {"width": 305, "height": 202, "x": 10, "y": 686},
                "label": "add_preset_popups_Welcome_6",
                "tags": null
            }
        }, {
            "id": "add_preset_popups_Welcome_4",
            "structure": {
                "type": "Page",
                "id": "m4jm7",
                "componentTypeForBI": "wysiwyg.viewer.components.PopupContainer",
                "components": [{
                    "type": "Container",
                    "id": "comp-im21sru9",
                    "components": [{
                        "type": "Component",
                        "id": "comp-im21veq5",
                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                        "layout": {
                            "width": 316,
                            "height": 60,
                            "x": 13,
                            "y": 111,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<h2 class=\"font_2\" style=\"font-size:23px; line-height:1.2em; text-align:center\"><span style=\"color:#000000;\"><span style=\"font-size:23px\"><span style=\"font-family:spinnaker,sans-serif\">You&#39;re in&nbsp;the</span></span></span></h2>\n\n<h2 class=\"font_2\" style=\"font-size:23px; line-height:1.2em; text-align:center\"><span style=\"color:#000000;\"><span style=\"font-size:23px\"><span style=\"font-family:spinnaker,sans-serif\">Right Place.</span></span></span></h2>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": [],
                            "id": "dataItem-im21veq8"
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true,
                            "id": "propItem-ipf8csrv"
                        },
                        "style": "txtNew"
                    }, {
                        "type": "Component",
                        "id": "comp-im21y9gu",
                        "skin": "wysiwyg.viewer.skins.video.VideoDefault",
                        "layout": {
                            "width": 476,
                            "height": 263,
                            "x": 359,
                            "y": 17,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.Video",
                        "data": {
                            "type": "Video",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "videoId": "135171560",
                            "videoType": "VIMEO",
                            "id": "dataItem-im21y9gy"
                        },
                        "props": {
                            "type": "VideoProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "autoplay": false,
                            "loop": false,
                            "showinfo": false,
                            "lightTheme": false,
                            "showControls": "always_show",
                            "id": "propItem-im21y9h0"
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "alpha-brd": "1",
                                    "boxShadowToggleOn-shd": "false",
                                    "brd": "#00E3AA",
                                    "brw": "0",
                                    "rd": "0",
                                    "shd": "0 1px 4px rgba(0, 0, 0, 0.6)"
                                },
                                "propertiesSource": {
                                    "alpha-brd": "value",
                                    "boxShadowToggleOn-shd": "value",
                                    "brd": "value",
                                    "brw": "value",
                                    "rd": "value",
                                    "shd": "value"
                                }
                            },
                            "componentClassName": "wysiwyg.viewer.components.Video",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.video.VideoDefault",
                            "id": "style-im21yj7x"
                        }
                    }, {
                        "type": "Component",
                        "id": "comp-im222dfz",
                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                        "layout": {
                            "width": 320,
                            "height": 69,
                            "x": 18,
                            "y": 21,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<h2 class=\"font_2\" style=\"font-size:99px; line-height:0.6em; text-align:center\"><span style=\"color:#000000;\"><span style=\"font-family:spinnaker,sans-serif\"><span style=\"font-size:99px\">as</span></span></span></h2>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": [],
                            "id": "dataItem-im222dg4"
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true,
                            "id": "propItem-ipf8csub"
                        },
                        "style": "txtNew"
                    }, {
                        "type": "Component",
                        "id": "comp-im222dgc",
                        "skin": "wysiwyg.viewer.skins.line.SolidLine",
                        "layout": {
                            "width": 120,
                            "height": 8,
                            "x": 120,
                            "y": 52,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.FiveGridLine",
                        "props": {
                            "type": "FiveGridLineProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "fullScreenModeOn": false,
                            "id": "propItem-im222dh0"
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {"alpha-brd": "1", "brd": "#000000", "lnw": "8"},
                                "propertiesSource": {"brd": "value", "lnw": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.line.SolidLine",
                            "id": "style-it8dx2xt"
                        }
                    }, {
                        "type": "Component",
                        "id": "comp-imes1jxn",
                        "skin": "wysiwyg.viewer.skins.button.BasicButton",
                        "layout": {
                            "width": 174,
                            "height": 45,
                            "x": 93,
                            "y": 201,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.PopupCloseTextButton",
                        "data": {
                            "type": "LinkableButton",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "label": "ENTER SITE",
                            "id": "dataItem-imes1jxs"
                        },
                        "props": {
                            "type": "ButtonProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "align": "center",
                            "margin": 0,
                            "id": "propItem-imes1jxt"
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "alpha-bg": "1",
                                    "alpha-bgh": "1",
                                    "alpha-brd": "1",
                                    "alpha-brdh": "1",
                                    "alpha-txt": "1",
                                    "alpha-txth": "1",
                                    "bg": "#000000",
                                    "bgh": "#4D4D4D",
                                    "boxShadowToggleOn-shd": "false",
                                    "brd": "#BD1515",
                                    "brdh": "#B08484",
                                    "brw": "0",
                                    "fnt": "normal normal normal 16px/1.4em spinnaker",
                                    "rd": "0px",
                                    "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                    "txt": "#FFFFFF",
                                    "txth": "#FFFFFF"
                                },
                                "propertiesSource": {
                                    "bg": "value",
                                    "bgh": "value",
                                    "brd": "value",
                                    "brdh": "value",
                                    "brw": "value",
                                    "fnt": "value",
                                    "rd": "value",
                                    "shd": "value",
                                    "txt": "value",
                                    "txth": "value"
                                }
                            },
                            "componentClassName": "wysiwyg.viewer.components.PopupCloseTextButton",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.button.BasicButton",
                            "id": "style-it8dx8cg"
                        }
                    }, {
                        "type": "Component",
                        "skin": "svgshape.v2.Svg_001419cc721a440abc4ab12ed150b744",
                        "layout": {
                            "width": 40,
                            "height": 40,
                            "x": 927,
                            "y": 60,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": true,
                            "docked": {
                                "top": {
                                    "px": 60
                                },
                                "right": {
                                    "px": 60
                                }
                            }
                        },
                        "componentType": "wysiwyg.viewer.components.PopupCloseIconButton",
                        "data": {
                            "type": "SvgShape",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "1.0",
                                "isHidden": false
                            }
                        },
                        "props": {
                            "type": "SvgShapeProperties",
                            "metaData": {
                                "schemaVersion": "1.0"
                            },
                            "maintainAspectRatio": true
                        },
                        "style": {
                            "type": "ComponentStyle",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "1.0",
                                "isHidden": false
                            },
                            "style": {
                                "properties": {
                                    "alpha-fillcolor": "1",
                                    "alpha-stroke": "1",
                                    "fillcolor": "#000000",
                                    "stroke": "#E32C30",
                                    "strokewidth": "1"
                                },
                                "propertiesSource": {
                                    "alpha-fillcolor": "value",
                                    "alpha-stroke": "value",
                                    "fillcolor": "value",
                                    "stroke": "value",
                                    "strokewidth": "value"
                                },
                                "groups": {}
                            },
                            "componentClassName": "wysiwyg.viewer.components.PopupCloseIconButton",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "svgshape.v2.Svg_001419cc721a440abc4ab12ed150b744"
                        },
                        "connections": {
                            "type": "ConnectionList",
                            "items": [
                                {
                                    "type": "WixCodeConnectionItem",
                                    "role": "button1"
                                }
                            ]
                        },
                        "mobileStructure": {
                            "layout": {
                                "width": 28,
                                "height": 28,
                                "x": 272,
                                "y": -52,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "metaData": {
                                "originalCompId": "comp-isrfbt5w",
                                "author": "duplicate"
                            }
                        },
                        "activeModes": {}
                    }],
                    "skin": "wysiwyg.viewer.skins.stripContainer.DefaultStripContainer",
                    "layout": {
                        "width": 853,
                        "height": 297,
                        "x": 63,
                        "y": 240,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.PopupContainer",
                    "props": {
                        "type": "PopupContainerProperties",
                        "metaData": {"schemaVersion": "1.0"},
                        "horizontalAlignment": "center",
                        "verticalAlignment": "center",
                        "alignmentType": "nineGrid",
                        "horizontalOffset": 0,
                        "verticalOffset": 0,
                        "id": "propItem-im21srub"
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "color": "#FFFFFE",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        },
                        "id": "dataItem-im21sruc"
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {"alpha-bg": "1", "bg": "rgba(255,255,255,1)"},
                            "propertiesSource": {"alpha-bg": "value", "bg": "value"}
                        },
                        "componentClassName": "wysiwyg.viewer.components.PopupContainer",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.stripContainer.DefaultStripContainer",
                        "id": "style-im21srue"
                    },
                    "behaviors": {
                        "type": "ObsoleteBehaviorsList",
                        "items": "[{\"name\":\"FadeIn\",\"delay\":0.1,\"duration\":0.75,\"action\":\"screenIn\",\"targetId\":\"\",\"params\":{}},{\"name\":\"FadeOut\",\"delay\":0.1,\"duration\":0.75,\"action\":\"exit\",\"targetId\":\"\",\"params\":{}}]",
                        "id": "behavior-iomlwedj"
                    }
                }],

                "skin": "skins.core.InlineSkin",
                "layout": {
                    "width": 980,
                    "height": 537,
                    "x": 0,
                    "y": 0,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "mobile.core.components.Page",
                "data": {
                    "type": "Page",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "title": "add_preset_popups_Welcome_4",
                    "hideTitle": true,
                    "icon": "",
                    "descriptionSEO": "",
                    "metaKeywordsSEO": "",
                    "pageTitleSEO": "",
                    "pageUriSEO": "copy-of-welcome3",
                    "hidePage": true,
                    "underConstruction": false,
                    "tpaApplicationId": 0,
                    "pageSecurity": {"requireLogin": false, "passwordDigest": "", "dialogLanguage": ""},
                    "isPopup": true,
                    "indexable": false,
                    "isLandingPage": false,
                    "pageBackgrounds": {
                        "desktop": {
                            "ref": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "#E21C21",
                                "colorOpacity": 0.9,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            }
                        },
                        "mobile": {
                            "custom": true,
                            "ref": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "#E21C21",
                                "colorOpacity": 0.9,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "scroll",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            },
                            "isPreset": true
                        }
                    },
                    "ignoreBottomBottomAnchors": true
                },
                "props": {
                    "type": "PageProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "desktop": {"popup": {"closeOnOverlayClick": true}},
                    "mobile": {"popup": {"closeOnOverlayClick": true}},
                    "id": "m4jm7"
                },
                "style": "p1",
                "tpaComps": []
            },
            "preset": {
                "rect": {"width": 305, "height": 202, "x": 10, "y": 908},
                "label": "add_preset_popups_Welcome_4",
                "tags": null
            }
        }],
        "compTypes": ["mobile.core.components.Page"]
    },
    "help": {"hide": false, "text": "Popups_Add_Category_Welcome_Text"}
}
