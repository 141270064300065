import type { Dispatch, StateMapperArgs } from 'types/redux';
import type { EditorState } from '@/stateManagement';
import type { EditorAPI } from '@/editorAPI';

import { ExternalPublishPipelineAPIKey } from '@wix/wix-code-external-apis';

export interface PublishErrorPanelDispatchProps {
  closePanel: () => void;
  openBuildLog: (deploymentId: string) => void;
}

export interface PublishPipelineFailure {
  deploymentId: string;
  pipelineId: string;
  tasks: { task: string; status: string }[];
}

export interface PublishErrorPanelStateProps {
  error: PublishPipelineFailure;
  isDevMode: boolean;
}

export interface PublishErrorPanelStateProps {
  isDevMode: boolean;
  error: PublishPipelineFailure;
}

export interface PublishPipelineFailure {
  deploymentId: string;
  pipelineId: string;
  tasks: { task: string; status: string }[];
}

const PANEL_NAME = 'savePublish.panels.publish.publishErrorPanel';

const getEditorAPI = (
  dispatch: Dispatch,
  getState: () => EditorState,
  { editorAPI }: { editorAPI: EditorAPI },
): EditorAPI => editorAPI;

function mapDispatchToProps(
  dispatch: Dispatch,
): PublishErrorPanelDispatchProps {
  const editorAPI = dispatch(getEditorAPI);

  return {
    closePanel: () => editorAPI.panelManager.closePanelByName(PANEL_NAME),
    openBuildLog: (deploymentId) =>
      editorAPI.host
        .getAPI(ExternalPublishPipelineAPIKey)
        .openBuildLog(deploymentId),
  };
}

function mapStateToProps(
  { editorAPI }: StateMapperArgs,
  error: PublishPipelineFailure,
): PublishErrorPanelStateProps {
  const isDevMode = editorAPI.developerMode.isEnabled();

  return {
    isDevMode,
    error,
  };
}

export { mapStateToProps, mapDispatchToProps };
