'use strict'

const contactFormsSection = require('../sections/contact/contactFormsSection')
const dynamicContactFormsSection = require('../sections/contact/dynamicContactFormsSection')
const subscribeFormsSection = require('../sections/contact/subscribeFormsSection')
const subscribeFormsStudioSection = require('../sections/contact/subscribeFormsStudioSection')
const googleMapsSection = require('../sections/contact/googleMapsSection')
const googleMapsSquareSection = require('../sections/contact/googleMapsSquareSection')
const googleMapsStripSection = require('../sections/contact/googleMapsStripSection')
const googleMapsGenericSection = require('../sections/contact/googleMapsGenericSection')
const googleMapsDesignPanelSection = require('../sections/contact/googleMapsDesignPanelSection')
const lineSection = require('../sections/social/lineSection')

module.exports = {
    contactFormsSection,
    dynamicContactFormsSection,
    subscribeFormsSection,
    subscribeFormsStudioSection,


    lineSection,

    // TODO remove googleMapsSection after googleMapsDesigns experiment is merged
    googleMapsSection,
    googleMapsDesignPanelSection,
    googleMapsSquareSection,
    googleMapsStripSection,
    googleMapsGenericSection
}
