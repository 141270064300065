import { useState, useEffect, useMemo } from 'react';
import { createConnectorsService } from '../services/externalDB/wixDataConnectorSettings';
import type { ConnectorData } from '@wix/wix-data-connector-settings-client';
import type { Schema } from '@wix/wix-data-schema-types';

export const useAllConnectors = (instance: string, collections: Schema[]) => {
  const [allConnectors, setAllConnectors] = useState<ConnectorData[]>([]);

  const connectorsService = useMemo(
    () => createConnectorsService(instance),
    [instance],
  );

  useEffect(() => {
    connectorsService
      .getAllConnectors()
      .then((connectors: ConnectorData[]) => {
        setAllConnectors(connectors);
      })
      .catch(() => setAllConnectors([]));
  }, [instance, collections, connectorsService]);

  return allConnectors;
};
