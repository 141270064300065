// @ts-nocheck
import React from 'react';
import { translate } from '@/i18n';
import { sections } from '@/util';
import pageSettingsConstants from '../../../utils/constants';
import PageSettingsFrame from '../pageSettingsFrame';
import TabMobileLayout from '../../../settings/tabs/tabMobileLayout';
import PageSettingsLayoutTab from '../../../settings/tabs/PageSettingsLayoutTab/PageSettingsLayoutTab';

function template() {
  const TabContent = sections.isSectionsEnabled()
    ? PageSettingsLayoutTab
    : TabMobileLayout;
  return (
    <PageSettingsFrame
      helpId={this.getHelpId()}
      displayTitle={this.getPageTitle()}
      closeSettingsPanel={this.props.closeSettingsPanel}
      {...this.props}
    >
      <div>
        <section className="tabs top page-settings-tabs">
          <div className="tab-content">
            <TabContent {...this.props} />
          </div>
        </section>
      </div>
    </PageSettingsFrame>
  );
}

class PageSettingsMobile extends React.Component {
  constructor(props) {
    super(props);

    this.getPageTitle = () =>
      `${translate('Mobile_Pages_Settings_Header_Text')} (${
        this.props.menuItem.label
      })`;

    this.getHelpId = () =>
      pageSettingsConstants.STATIC_PAGES_HELP_IDS.mobile_layout;
  }
  render() {
    return template.call(this);
  }
}

PageSettingsMobile.displayName = 'PageSettingsMobile';

export default PageSettingsMobile;
