// @ts-nocheck
import React from 'react';
import createReactClass from 'create-react-class';
import * as core from '@/core';
import * as stateManagement from '@/stateManagement';
import _ from 'lodash';
import designPanelMixin from '../core/designPanelMixin';
import {
  Divider,
  HorizontalTabs,
  TextLabel,
  ThumbnailColorInput,
  Thumbnails,
  Tooltip,
} from '@wix/wix-base-ui';
import * as symbols from '@wix/santa-editor-symbols';
import coreUtilsLib from 'coreUtilsLib';
import * as util from '@/util';
import { translate } from '@/i18n';
import * as coreBi from '@/coreBi';

import DesignPanel from '../core/designPanel';
import type {
  ImageTransformOptions,
  ImageTransformSource,
  ImageTransformTarget,
} from '@util';

const { panelPosition } = stateManagement.domMeasurements.hoc;
const OPTIONS = [
  { value: 'MAP_DESIGN', label: 'Google_Maps_Design_Map_Design_Tab' },
  { value: 'PIN_DESIGN', label: 'Google_Maps_Design_Pin_Design_Tab' },
];

const DEFAULT_PIN_COLOR = '#2A3688';
const DEFAULT_PIN_URL = util.media.getMediaUrl(
  'designPanel/panelTypes/google_maps_pin_icon.png',
);
const DEFAULT_THUMBNAIL_VALUE = 'default_pin_icon';
const CUSTOM_THUMBNAIL_VALUE = 'my_uploads_images';
const DEFAULT_GOOGLE_MAP_SKIN = 'wysiwyg.viewer.skins.GoogleMapSkin';
const MEDIA_MANAGER_OPEN_SOURCE = 'google_maps_upload_custom_pin';
const EMPTY_GOOGLE_MAP_STYLE = {
  groups: {},
  properties: {},
  propertiesSource: {},
};

// eslint-disable-next-line react/prefer-es6-class
const ChangeGoogleMapDesignPanel = createReactClass({
  displayName: 'changeGoogleMapDesignPanel',
  mixins: [designPanelMixin, core.mixins.editorAPIMixin],

  getStateFromProps(props) {
    const editorAPI = this.getEditorAPI();
    const { selectedComponent } = props;
    const data = editorAPI.components.data.get(selectedComponent);
    const properties = editorAPI.components.properties.get(selectedComponent);

    return {
      mapStyle: data?.mapStyle ?? [],
      mapType: properties.mapType,
      originalStyleDef: editorAPI.components.style.get(selectedComponent),
      isPermanentStateChanged: false,
      selectedTab: 'MAP_DESIGN',
      pinColor: DEFAULT_PIN_COLOR,
    };
  },
  updateComponent(compDef) {
    const editorAPI = this.getEditorAPI();
    const { selectedComponent } = this.props;
    const { data } = compDef;
    const properties = compDef.props;
    let shouldUpdateState = false;

    if (data && properties) {
      shouldUpdateState = true;
      const modifiedCompDef = editorAPI.components.style.get(
        this.props.selectedComponent,
      );
      modifiedCompDef.style = compDef?.style?.style ?? EMPTY_GOOGLE_MAP_STYLE;

      editorAPI.components.data.update(
        selectedComponent,
        _.pick(data, 'mapStyle'),
        true,
      );
      editorAPI.components.properties.update(
        selectedComponent,
        _.pick(properties, 'mapType'),
        true,
      );
      editorAPI.components.style.update(
        this.props.selectedComponent,
        modifiedCompDef,
      );

      this.changeSkin(modifiedCompDef, DEFAULT_GOOGLE_MAP_SKIN);
    }

    return shouldUpdateState;
  },
  changeSkin(styleDataItem, skinName) {
    if (skinName === styleDataItem.skin) {
      return;
    }

    styleDataItem.skin = skinName;
    this.getEditorAPI().components.style.update(
      this.props.selectedComponent,
      styleDataItem,
    );
  },
  changePermanentState(compDef) {
    const shouldUpdateState = this.updateComponent(compDef);
    if (shouldUpdateState) {
      this.setState({
        mapStyle: compDef.data.mapStyle,
        mapType: compDef.props.mapType,
        isPermanentStateChanged: true,
      });
    }
  },
  changeTempState(compDef) {
    this.updateComponent(compDef);
  },
  maintainOriginalState() {
    const editorAPI = this.getEditorAPI();
    const { selectedComponent } = this.props;
    const { mapStyle } = this.state;
    const { mapType } = this.state;

    if (mapStyle && mapType) {
      editorAPI.components.data.update(selectedComponent, { mapStyle }, true);
      editorAPI.components.properties.update(
        selectedComponent,
        { mapType },
        true,
      );

      if (!this.state.isPermanentStateChanged) {
        editorAPI.components.style.update(
          selectedComponent,
          this.state.originalStyleDef,
        );
      }
    }
  },

  createIllustration(symbolName) {
    return React.createElement(symbols.symbol, {
      name: symbolName,
      style: { fill: this.state.pinColor },
    });
  },

  selectTab() {
    const isMapDesign = this.isMapDesignPanel();
    const tab = isMapDesign ? 'PIN_DESIGN' : 'MAP_DESIGN';
    this.setState({
      selectedTab: tab,
    });
    if (isMapDesign) {
      const compRef =
        _.head(this.props.selectedComponents) ||
        _.head(this.props.selectedComponent);
      this.getEditorAPI().bi.event(
        coreBi.events.GOOGLE_MAPS.MOVE_TABS_TO_PINS_TAB_NEW,
        {
          component_id: compRef.id,
          tab,
        },
      );
    }
  },

  isMapDesignPanel() {
    return this.state.selectedTab === 'MAP_DESIGN';
  },

  getPropsForMapsDesignPanel() {
    const designPanelHeader = React.createElement(HorizontalTabs, {
      arrowed: true,
      value: this.state.selectedTab,
      onChange: this.selectTab,
      options: OPTIONS,
    });

    const designPanelTitle = React.createElement(TextLabel, {
      value: 'Google_Maps_Design_Choose_Design_Label',
    });

    const getSections = () => {
      const mapsSection =
        this.getEditorAPI().addPanel.getSectionsByComponentType(
          this.props.compType,
          this.props.selectedComponent,
        );

      return [{ ..._.head(mapsSection), showSectionHeader: false }];
    };

    const defaultDesignPanelProps = this.getPropsForDesignPanel();

    const mapDesignPanelProps = {
      ...defaultDesignPanelProps,
      hideComponentSections: false,
      designPanelHeader,
      designPanelTitle,
      getSections,
    };

    const pinDesignPanelProps = {
      ...defaultDesignPanelProps,
      hideComponentSections: true,
      designPanelHeader,
    };

    return this.isMapDesignPanel() ? mapDesignPanelProps : pinDesignPanelProps;
  },

  openColorPicker() {
    const { top, left } = this.props.style;
    this.getEditorAPI().openColorPicker(
      this.state.pinColor,
      { top, left },
      {
        onChange: this.onColorChange,
        enableHistory: false,
        previewOnHover: true,
      },
    );
  },

  onColorChange(newColor, { isHover }) {
    this.setState({ pinColor: this.getEditorAPI().theme.colors.get(newColor) });
    if (!isHover) {
      this.updateLocations(
        symbols.pathMap[this.getThumbnailsValue()],
        this.state.pinColor,
      );
    }
  },

  getColorInputOptions() {
    return [{ color: this.state.pinColor, onClick: this.openColorPicker }];
  },

  getCurrentLocations() {
    return this.getEditorAPI().components.data.get(this.props.selectedComponent)
      .locations;
  },

  getComponentId() {
    return this.getEditorAPI().components.data.get(this.props.selectedComponent)
      .id;
  },

  updateLocations(newPinIcon, newPinColor) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/map
    const newLocations = _.map(this.getCurrentLocations(), (location) =>
      Object.assign({}, location, {
        pinIcon: newPinIcon,
        pinColor: newPinColor,
      }),
    );
    this.getEditorAPI().components.data.update(this.props.selectedComponent, {
      locations: newLocations,
    });
  },

  getThumbnailsValue() {
    if (this.isCustomPinSelected()) {
      return CUSTOM_THUMBNAIL_VALUE;
    }
    return (
      _.findKey(
        symbols.pathMap,
        (value) => value === _.head(this.getCurrentLocations()).pinIcon,
      ) || DEFAULT_THUMBNAIL_VALUE
    );
  },

  openMediaManager() {
    const editorAPI = this.getEditorAPI();
    const { mediaServices } = editorAPI;
    const { mediaManager } = mediaServices;
    const translation = {
      submitButton: translate('MMGR_submitbutton_gallery_custommarker_add'),
    };
    const compRef = Array.isArray(this.props.selectedComponent)
      ? this.props.selectedComponent[0]
      : this.props.selectedComponent;

    editorAPI.bi.event(coreBi.events.GOOGLE_MAPS.UPLOAD_PIN_DESIGN_CLICK_NEW, {
      component_id: compRef.id,
    });

    mediaManager.open(
      mediaManager.categories.IMAGE,
      MEDIA_MANAGER_OPEN_SOURCE,
      {
        multiSelect: false,
        translation,
        callback: (image) => {
          if (image) {
            this.onImageSelection(_.head(image));
            mediaServices.setFocusToEditor();
          }
        },
      },
    );
  },

  getImageURL(uri) {
    return `${util.serviceTopology.staticMediaUrl}/${uri}`;
  },

  onImageSelection(image) {
    const url = this.getImageURL(
      util.imageTransform.getData(
        util.imageTransform.fittingTypes.SCALE_TO_FIT,
        {
          id: image.uri,
          width: image.width,
          height: image.height,
        } as ImageTransformSource,
        {
          width: 34,
          height: 34,
          htmlTag: 'img',
        } as ImageTransformTarget,
        {
          // isSEOBot will keep the original file extension in the final url
          // (prevent the auto .webp optimization which causes this bug: BOLT-1673)
          // this solution is exception here, do not use it elsewhere
          isSEOBot: true,
        } as ImageTransformOptions,
      ).uri,
    );

    this.updateLocations(url, '');
    this.getEditorAPI().store.dispatch(
      stateManagement.userPreferences.actions.setSiteUserPreferences(
        `customPinUrl_${this.getComponentId()}`,
        url,
      ),
    );
  },

  shouldApplyCustomPin(value) {
    return value === CUSTOM_THUMBNAIL_VALUE;
  },

  shouldApplyDefaultPin(value) {
    return value === DEFAULT_THUMBNAIL_VALUE;
  },

  getCustomPinUrl() {
    return stateManagement.userPreferences.selectors.getSiteUserPreferences(
      `customPinUrl_${this.getComponentId()}`,
    )(this.getEditorAPI().store.getState());
  },

  isExternalUrl() {
    const currentPin = _.head(this.getCurrentLocations()).pinIcon;
    return coreUtilsLib.urlUtils.isExternalUrl(currentPin);
  },

  isCustomPinSelected() {
    const currentPin = _.head(this.getCurrentLocations()).pinIcon;
    return this.isExternalUrl() && currentPin !== DEFAULT_PIN_URL;
  },

  onThumbnailChange(newVal) {
    if (this.shouldApplyCustomPin(newVal)) {
      this.updateLocations(this.getCustomPinUrl(), '');
    } else if (this.shouldApplyDefaultPin(newVal)) {
      this.updateLocations(DEFAULT_PIN_URL, '');
    } else {
      this.updateLocations(symbols.pathMap[newVal], this.state.pinColor);
    }
  },

  getThumbnailOptions() {
    const defaultThumbnail = [
      {
        value: 'default_pin_icon',
        illustration: React.createElement('img', { src: DEFAULT_PIN_URL }),
      },
    ];
    const thumbnailOptions = defaultThumbnail.concat(
      _.times(18, (index) => ({
        value: `Pin_Icon_${index + 2}`,
        illustration: this.createIllustration(`Pin_Icon_${index + 2}`),
      })),
    );
    const customPinIcon = {
      value: CUSTOM_THUMBNAIL_VALUE,
      illustration: this.createIllustration(CUSTOM_THUMBNAIL_VALUE),
    };

    const lastThumbnail = this.getCustomPinUrl() ? customPinIcon : [];
    return thumbnailOptions.concat(lastThumbnail);
  },

  getAddThumbnailProps() {
    return { onAddThumbnailClick: this.openMediaManager, label: '' };
  },

  render() {
    return (
      <DesignPanel ref="designPanel" {...this.getPropsForMapsDesignPanel()}>
        {!this.isMapDesignPanel() ? (
          <div key="pinDesignPanelContent" className="pin-design-panel-content">
            <div key="pinThumbnailsWrapper" className="pin-thumbnails-wrapper">
              <TextLabel value="Google_Maps_Design_Choose_Shape_Label" />
              <Thumbnails
                value={this.getThumbnailsValue()}
                options={this.getThumbnailOptions()}
                onChange={this.onThumbnailChange}
                addThumbnailProps={
                  this.getCustomPinUrl() ? null : this.getAddThumbnailProps()
                }
              />
              {this.getCustomPinUrl() ? (
                <div
                  key="changeCustomPin"
                  onClick={this.openMediaManager}
                  className="change-custom-pin"
                >
                  <Tooltip
                    shouldTranslate={true}
                    closeOnMouseClick={true}
                    content="CROP_MODE_SHAPES_PANEL_CUSTOM_REPLACE_TOOLTIP"
                  >
                    <symbols.symbol name="video-player-change-poster" />
                  </Tooltip>
                </div>
              ) : null}
            </div>
            {!this.isExternalUrl() ? (
              <Divider key="pinDesignPanelDivider" long={true} />
            ) : null}
            {!this.isExternalUrl() ? (
              <div key="pinColorsWrapper" className="pin-colors-wrapper">
                <TextLabel value="Google_Maps_Design_Choose_Colors_Label" />
                <ThumbnailColorInput
                  options={this.getColorInputOptions()}
                  maxThumbsPerRow={4}
                />
              </div>
            ) : null}
          </div>
        ) : null}
      </DesignPanel>
    );
  },
});
const WrappedPanel = panelPosition()(ChangeGoogleMapDesignPanel);
WrappedPanel.pure = ChangeGoogleMapDesignPanel;
export default WrappedPanel;
