'use strict'

module.exports = {
    id: 'Columns_ColumnsContact_5',
    structure: {
        type: 'Container',
        components: [
            {
                type: 'Container',
                components: [
                    {
                        type: 'Component',
                        skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
                        layout: {
                            width: 700,
                            height: 72,
                            x: 144,
                            y: 95,
                            scale: 1,
                            rotationInDegrees: 0,
                            fixedPosition: false
                        },
                        componentType: 'wysiwyg.viewer.components.WRichText',
                        data: {
                            type: 'StyledText',
                            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                            text: '<h2 class="font_2" style="font-size:56px; text-align:center"><span style="color:#221133"><span style="font-size:56px"><span style="letter-spacing:0.15em"><span style="font-family:didot-w01-italic,serif">Contact Me</span></span></span></span></h2>\n',
                            stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
                            linkList: []
                        },
                        props: {
                            type: 'WRichTextProperties',
                            metaData: {schemaVersion: '1.0'},
                            brightness: 1,
                            packed: true
                        },
                        style: 'txtNew',
                        mobileStructure: {
                            type: 'Component',
                            skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
                            layout: {
                                width: 280,
                                height: 47,
                                x: 20,
                                y: 17,
                                scale: 1,
                                rotationInDegrees: 0,
                                fixedPosition: false
                            },
                            componentType: 'wysiwyg.viewer.components.WRichText',
                            data: {
                                type: 'StyledText',
                                metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                                text: '<h2 class="font_2" style="font-size:56px; text-align:center"><span style="color:#221133"><span style="font-size:56px"><span style="letter-spacing:0.15em"><span style="font-family:didot-w01-italic,serif">Contact Me</span></span></span></span></h2>\n',
                                stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
                                linkList: []
                            },
                            props: {
                                type: 'WRichTextProperties',
                                metaData: {schemaVersion: '1.0', autoGenerated: false},
                                brightness: 1,
                                packed: true
                            },
                            style: 'txtNew'
                        }
                    },
                    {
                        type: 'Component',
                        layout: {
                            width: 381,
                            height: 58,
                            x: 300,
                            y: 189,
                            scale: 1,
                            rotationInDegrees: 0,
                            fixedPosition: false
                        },
                        componentType: 'wysiwyg.viewer.components.WRichText',
                        data: {
                            type: 'StyledText',
                            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                            text: '<p class="font_8" style="font-size:16px; line-height:1.7em; text-align:center"><span style="font-family:futura-lt-w01-light,sans-serif"><span style="font-size:16px"><span style="color:#110A2B">500 Terry Francois Street, San Francisco, CA 94158 info@mysite.com &nbsp;| &nbsp;Tel: 123-456-7890</span></span></span></p>\n',
                            stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
                            linkList: []
                        },
                        props: {
                            type: 'WRichTextProperties',
                            metaData: {schemaVersion: '1.0'},
                            brightness: 1,
                            packed: true
                        },
                        style: 'txtNew',
                        mobileStructure: {
                            type: 'Component',
                            layout: {
                                width: 280,
                                height: 111,
                                x: 20,
                                y: 67,
                                scale: 0.8145062499999999,
                                rotationInDegrees: 0,
                                fixedPosition: false
                            },
                            componentType: 'wysiwyg.viewer.components.WRichText',
                            data: {
                                type: 'StyledText',
                                metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                                text: '<p class="font_8" style="font-size:16px; line-height:1.7em; text-align:center"><span style="font-family:futura-lt-w01-light,sans-serif"><span style="font-size:16px"><span style="color:#110A2B">500 Terry Francois Street, San Francisco, CA 94158 info@mysite.com &nbsp;| &nbsp;Tel: 123-456-7890</span></span></span></p>\n',
                                stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
                                linkList: []
                            },
                            props: {
                                type: 'WRichTextProperties',
                                metaData: {schemaVersion: '1.0', autoGenerated: false},
                                brightness: 1,
                                packed: true
                            },
                            style: 'txtNew'
                        }
                    },
                    {
                        type: 'Component',
                        skin: 'wysiwyg.viewer.skins.LinkBarNoBGSkin',
                        layout: {
                            width: 106,
                            height: 29,
                            x: 437,
                            y: 270,
                            scale: 1,
                            rotationInDegrees: 0,
                            fixedPosition: false
                        },
                        componentType: 'wysiwyg.viewer.components.LinkBar',
                        data: {
                            type: 'ImageList',
                            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                            items: [
                                {
                                    type: 'Image',
                                    metaData: {isPreset: false, schemaVersion: '2.0', isHidden: false},
                                    title: 'Black Facebook Icon',
                                    uri: '0fdef751204647a3bbd7eaa2827ed4f9.png',
                                    description: '',
                                    width: 200,
                                    height: 200,
                                    alt: '',
                                    link: {
                                        type: 'ExternalLink',
                                        metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                                        url: 'http://www.facebook.com/wix',
                                        target: '_blank'
                                    }
                                },
                                {
                                    type: 'Image',
                                    metaData: {isPreset: false, schemaVersion: '2.0', isHidden: false},
                                    title: 'Black Twitter Icon',
                                    uri: 'c7d035ba85f6486680c2facedecdcf4d.png',
                                    description: '',
                                    width: 200,
                                    height: 200,
                                    alt: '',
                                    link: {
                                        type: 'ExternalLink',
                                        metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                                        url: 'http://www.twitter.com/wix',
                                        target: '_blank'
                                    }
                                },
                                {
                                    type: 'Image',
                                    metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                                    title: 'Black Instagram Icon',
                                    uri: '04b15d1b030049d1b3746f8ef5a72955.png',
                                    description: '',
                                    width: 200,
                                    height: 200,
                                    alt: '',
                                    link: {
                                        type: 'ExternalLink',
                                        metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                                        url: 'https://instagram.com/wix/',
                                        target: '_blank'
                                    }
                                }
                            ]
                        },
                        props: {
                            type: 'LinkBarProperties',
                            metaData: {schemaVersion: '1.0'},
                            gallery: 'social_icons',
                            iconSize: 29,
                            spacing: 9,
                            bgScale: 1,
                            orientation: 'HORIZ'
                        },
                        style: 'lb1',
                        mobileStructure: {
                            type: 'Component',
                            skin: 'wysiwyg.viewer.skins.LinkBarNoBGSkin',
                            layout: {
                                width: 134,
                                height: 34,
                                x: 91,
                                y: 122,
                                scale: 1,
                                rotationInDegrees: 0,
                                fixedPosition: false
                            },
                            componentType: 'wysiwyg.viewer.components.LinkBar',
                            data: {
                                type: 'ImageList',
                                metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                                items: [
                                    {
                                        type: 'Image',
                                        metaData: {isPreset: false, schemaVersion: '2.0', isHidden: false},
                                        title: 'Black Facebook Icon',
                                        uri: '0fdef751204647a3bbd7eaa2827ed4f9.png',
                                        description: '',
                                        width: 200,
                                        height: 200,
                                        alt: '',
                                        link: {
                                            type: 'ExternalLink',
                                            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                                            url: 'http://www.facebook.com/wix',
                                            target: '_blank'
                                        }
                                    },
                                    {
                                        type: 'Image',
                                        metaData: {isPreset: false, schemaVersion: '2.0', isHidden: false},
                                        title: 'Black Twitter Icon',
                                        uri: 'c7d035ba85f6486680c2facedecdcf4d.png',
                                        description: '',
                                        width: 200,
                                        height: 200,
                                        alt: '',
                                        link: {
                                            type: 'ExternalLink',
                                            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                                            url: 'http://www.twitter.com/wix',
                                            target: '_blank'
                                        }
                                    },
                                    {
                                        type: 'Image',
                                        metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                                        title: 'Black Instagram Icon',
                                        uri: '04b15d1b030049d1b3746f8ef5a72955.png',
                                        description: '',
                                        width: 200,
                                        height: 200,
                                        alt: '',
                                        link: {
                                            type: 'ExternalLink',
                                            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                                            url: 'https://instagram.com/wix/',
                                            target: '_blank'
                                        }
                                    }
                                ]
                            },
                            props: {
                                type: 'LinkBarProperties',
                                metaData: {schemaVersion: '1.0', autoGenerated: true},
                                gallery: 'social_icons',
                                iconSize: 35,
                                spacing: 15,
                                bgScale: 1,
                                orientation: 'HORIZ'
                            },
                            style: 'lb1'
                        }
                    }
                ],
                layout: {
                    width: 980,
                    height: 750,
                    x: 0,
                    y: 0,
                    scale: 1,
                    rotationInDegrees: 0,
                    fixedPosition: false
                },
                componentType: 'wysiwyg.viewer.components.Column',
                props: {type: 'ColumnProperties', alignment: 50},
                design: {
                    type: 'MediaContainerDesignData',
                    metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                    background: {
                        type: 'BackgroundMedia',
                        metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                        color: '#FFEF98',
                        colorOpacity: 1,
                        alignType: 'center',
                        fittingType: 'fill',
                        scrollType: 'none',
                        colorOverlay: '',
                        colorOverlayOpacity: 0
                    }
                },
                style: 'mc1',
                mobileStructure: {
                    type: 'Container',
                    layout: {
                        width: 320,
                        height: 657,
                        x: 0,
                        y: 0,
                        scale: 1,
                        rotationInDegrees: 0,
                        fixedPosition: false
                    },
                    componentType: 'wysiwyg.viewer.components.Column',
                    props: {type: 'ColumnProperties', alignment: 50},
                    design: {
                        type: 'MediaContainerDesignData',
                        metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                        background: {
                            type: 'BackgroundMedia',
                            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                            color: '#FFEF98',
                            colorOpacity: 1,
                            alignType: 'center',
                            fittingType: 'fill',
                            scrollType: 'none',
                            colorOverlay: '',
                            colorOverlayOpacity: 0
                        }
                    },
                    style: 'mc1'
                }
            }
        ],
        layout: {
            width: 980,
            height: 750,
            x: 0,
            y: 3277,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false
        },
        componentType: 'wysiwyg.viewer.components.StripColumnsContainer',
        props: {
            type: 'StripColumnsContainerProperties',
            fullWidth: true,
            columnsMargin: 0,
            frameMargin: 0,
            rowMargin: 0,
            siteMargin: 0
        },
        design: {
            type: 'MediaContainerDesignData',
            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
            background: {
                type: 'BackgroundMedia',
                metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                color: '#FFFFFF',
                colorOpacity: 0,
                alignType: 'center',
                fittingType: 'fill',
                scrollType: 'none',
                colorOverlay: '',
                colorOverlayOpacity: 1
            }
        },
        style: {
            type: 'TopLevelStyle',
            id: 'strc1',
            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
            style: {groups: {}, properties: {}, propertiesSource: {}},
            componentClassName: 'wysiwyg.viewer.components.StripColumnsContainer',
            pageId: '',
            compId: '',
            styleType: 'system',
            skin: 'wysiwyg.viewer.skins.stripContainer.DefaultStripContainer'
        },
        mobileStructure: {
            type: 'Container',
            layout: {
                width: 320,
                height: 657,
                x: 0,
                y: 4625,
                scale: 1,
                rotationInDegrees: 0,
                fixedPosition: false
            },
            componentType: 'wysiwyg.viewer.components.StripColumnsContainer',
            props: {
                type: 'StripColumnsContainerProperties',
                fullWidth: true,
                columnsMargin: 0,
                frameMargin: 0,
                rowMargin: 0,
                siteMargin: 0
            },
            design: {
                type: 'MediaContainerDesignData',
                metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                background: {
                    type: 'BackgroundMedia',
                    metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                    color: '#FFFFFF',
                    colorOpacity: 0,
                    alignType: 'center',
                    fittingType: 'fill',
                    scrollType: 'none',
                    colorOverlay: '',
                    colorOverlayOpacity: 1
                }
            },
            style: 'strc1'
        },
        activeModes: {},
        id: 'comp-iok4tley'
    },
    preset: {
        rect: {width: 324, height: 190, x: -0.8, y: 635.4000000000001},
        label: 'add_preset_columns_contact_5',
        tags: null
    }
}
