// @ts-nocheck
import ReactLinkedStateMixin from 'react-addons-linked-state-mixin';
import _ from 'lodash';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import * as util from '@/util';
import { translate } from '@/i18n';
import pageSettingsTabMixin from './pageSettingsTabMixin';
import * as pageUtils from '../../utils/pageUtils';
import experiment from 'experiment';
import * as coreBi from '@/coreBi';
import React from 'react';
import * as baseUI from '@/baseUI';
import GooglePreviewExpandable from './googlePreviewExpandable';
import * as pageMenuItemHelpers from '../../utils/pageMenuItemHelpers';
import CONSTANTS from '../../utils/constants';
import SeoUrlSettings from './seoUrlSettings';
import {
  Composites,
  CustomScroll,
  Illustration,
  RichText,
  TextLabel,
  ToggleSwitch,
} from '@wix/wix-base-ui';
import * as symbols from '@wix/santa-editor-symbols';
import { cx } from '@/util';

function getValidators(editorAPI, pageId) {
  const initialChecks = [
    {
      callback: util.validate.notEmptyString,
      message: 'Pages_Info_URL_Error_Tooltip',
    },
    {
      callback: util.validate.byInvalidCharacters(
        util.validationExpressions.invalidUrlCharacters,
      ),
      message: 'Pages_Info_URL_Error_Tooltip',
    },
  ];
  if (editorAPI.dsRead.generalInfo.urlFormat.isSlash()) {
    return initialChecks.concat([
      {
        callback: _.negate(
          editorAPI.pages.data.pageUriSEO.isForbidden.bind(null, pageId),
        ),
        message: 'Pages_Info_Forbidden_URI_SEO_Error_Tooltip',
      },
      {
        callback: _.negate(
          editorAPI.pages.data.pageUriSEO.isDuplicate.bind(null, pageId),
        ),
        message: 'Pages_Info_Duplicate_URI_SEO_Error_Tooltip',
      },
    ]);
  }
  return initialChecks;
}

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'tabSEO',
  mixins: [pageSettingsTabMixin, ReactLinkedStateMixin], //eslint-disable-line react/no-deprecated
  render() {
    return (
      <div className="tab tab-seo">
        <CustomScroll>
          <div
            className={cx({
              'seo-google': true,
              'tab-inner': true,
              'google-preview-expanded': this.state.isGooglePreviewExpanded,
            })}
          >
            <section key="seo-section-seo-wiz" className="seo-section-seo-wiz">
              <div className="seo-section-seo-wiz-inner-div">
                <Composites.RichTextWithIllustration>
                  <Illustration>
                    <symbols.symbol name="getFoundOnGoogle" />
                  </Illustration>
                  <Composites.RichTextLabeled>
                    <TextLabel value="Pages_SEO_Wizard_Title" type="T03" />
                    <RichText>
                      <p>
                        {translate('Pages_SEO_Wizard_Description')}{' '}
                        <a
                          href={this.getWixSEOWizURL()}
                          target="_blank"
                          onClick={this.reportSeoWizardLinkClicked}
                        >
                          {translate('Pages_SEO_Wizard_Button_Label')}
                        </a>
                      </p>
                    </RichText>
                  </Composites.RichTextLabeled>
                </Composites.RichTextWithIllustration>
              </div>
            </section>
            <section className="seo-section-title">
              <baseUI.textInput
                label="Pages_SEO_Title_Caption"
                placeholder="Pages_SEO_Title_Placeholder"
                maxLength={CONSTANTS.TITLE_SEO_MAX_LENGTH}
                validator={[util.validate.noHTMLTags, util.validate.noEmoji]}
                invalidMessage="Pages_SEO_Title_Error_Tooltip"
                valueLink={this.linkGooglePreviewProperty('pageTitleSEO')}
                infoText="Pages_SEO_Title_Tooltip"
                onBlur={this.onPagesSEOTitleBlur}
                className="control-text-input"
              />
            </section>
            <section className="seo-section-description">
              <baseUI.textInput
                isMultiLine={true}
                label="Pages_SEO_Description_Caption"
                placeholder={'Pages_SEO_Description_Placeholder'}
                maxLength={CONSTANTS.DESCRIPTION_SEO_MAX_LENGTH}
                validator={[util.validate.noHTMLTags, util.validate.noEmoji]}
                invalidMessage="Pages_SEO_Description_Error_Tooltip"
                valueLink={this.linkGooglePreviewProperty('descriptionSEO')}
                infoText="Pages_SEO_Description_Tooltip"
                onBlur={this.onPagesSEODescBlur}
                className="control-text-input"
              />
            </section>
            <section>
              <ToggleSwitch
                value={!this.getValueFromPageData('indexable')}
                label="Pages_SEO_Hide_Toggle"
                onChange={this.onHidePageChanged}
              />
            </section>

            {!experiment.isOpen('se_newPageSEOSection') ? (
              <section
                key="section-page-address"
                className="seo-section-page-address-old"
              >
                <baseUI.textInput
                  label="Pages_Info_Address_Title"
                  maxLength={CONSTANTS.USI_SEO_MAX_LENGTH}
                  validator={this.getPageUriSEOValidators()}
                  invalidMessage={this.getPageUriSEOInvalidMessages()}
                  valueLink={this.linkUrlProperty('pageUriSEO')}
                  onBlur={this.onPageAddressBlur}
                  infoText="Pages_Info_Address_Tooltip"
                  className="control-text-input"
                />
                <span className="page-address-prefix">https://www.../</span>
                {this.getEditorAPI().dsRead.generalInfo.isSitePublished() ? (
                  <a
                    key="page-web-address"
                    href={pageMenuItemHelpers.getPublicUrl(
                      this.getEditorAPI(),
                      this.props.pageData,
                    )}
                    onClick={this.onGoToUrlClicked}
                    target="_blank"
                  >
                    <baseUI.button
                      label="Pages_SEO_Goto_URL"
                      className="btn-text"
                    />
                  </a>
                ) : null}
                {this.isUriSEOTranslated() ? (
                  <span key="googleAttribution" className="translated-by">
                    {translate('Pages_Info_URL_Popup_Google')}{' '}
                    <baseUI.symbol name="google-logo" />
                  </span>
                ) : null}
              </section>
            ) : null}
            <section
              key="seo_tab_keywords_bottom"
              className="seo-section-keywords"
            >
              <baseUI.textInput
                label="Pages_SEO_Keywords_Caption"
                placeholder="Pages_SEO_Keywords_Placeholder"
                maxLength={CONSTANTS.KEYWORD_SEO_MAX_LENGTH}
                validator={[
                  util.validate.rangeOfWords(
                    0,
                    CONSTANTS.KEYWORD_SEO_MAX_WORDS,
                    ',',
                  ),
                  util.validate.noEmoji,
                ]}
                invalidMessage="Pages_SEO_Keywords_Error_Tooltip"
                valueLink={this.linkPageProperty('metaKeywordsSEO')}
                infoText="Pages_SEO_Keywords_New_Tooltip"
                validateOnBlurOnly={true}
                onBlur={this.onPagesSEOKeywordsBlur}
                className="control-text-input"
              />
            </section>
            {experiment.isOpen('se_newPageSEOSection') ? (
              <SeoUrlSettings
                key="seoTabPageAddressSection"
                pageData={this.props.pageData}
                shouldShowLinkToPage={true}
              />
            ) : null}
          </div>
        </CustomScroll>

        <GooglePreviewExpandable
          isExpandedLink={this.linkState('isGooglePreviewExpanded')}
          pageData={this.props.pageData}
        />
      </div>
    );
  },
  propTypes: {
    pageData: PropTypes.object.isRequired,
  },

  getInitialStateFromPageData() {
    return {
      isGooglePreviewExpanded: false,
    };
  },

  getInitialState() {
    return this.getInitialStateFromPageData();
  },

  componentWillReceiveProps(nextProps) {
    if (this.props.pageData.id !== nextProps.pageData.id) {
      this.setState(this.getInitialStateFromPageData());
    }
  },

  componentWillMount() {
    this.debouncedUpdateEditorAPI = _.debounce(function (
      editorAPI,
      pageId,
      newObjValue,
    ) {
      editorAPI.pages.data.update(pageId, newObjValue);
    }, 20);
    this.seoInfo = {
      pageTitleSEO: this.getValueFromPageData('pageTitleSEO'),
      pageDescSeo: this.getValueFromPageData('descriptionSEO'),
      pageKeywordsSEO: this.getValueFromPageData('metaKeywordsSEO'),
    };
  },

  linkGooglePreviewProperty(prop) {
    const pageId = this.props.pageData.id;
    const editorAPI = this.getEditorAPI();
    const newObjValue = {};

    return {
      value: this.getValueFromPageData(prop),
      requestChange: function (newValue) {
        if (!this.state.isGooglePreviewExpanded) {
          this.setState({ isGooglePreviewExpanded: true });
        }
        newObjValue[prop] = newValue;
        this.debouncedUpdateEditorAPI(editorAPI, pageId, newObjValue);
      }.bind(this),
    };
  },

  onPagesSEOTitleBlur() {
    const previousPageTitleSeo = this.seoInfo.pageTitleSEO;
    const newPageTitleSeo = this.getValueFromPageData('pageTitleSEO');
    if (newPageTitleSeo !== previousPageTitleSeo) {
      const editorAPI = this.getEditorAPI();
      editorAPI.bi.event(
        coreBi.events.topbar.pages.top_bar_PAGES_settings_seo_title_set,
        {
          field: 'page title',
        },
      );
      editorAPI.history.add('SEO title updated');
      this.seoInfo.pageTitleSEO = newPageTitleSeo;
    }
  },
  onPageAddressBlur() {
    const previousPageAddress = this.seoInfo.pageUriSEO;
    const newPageAddress = this.getValueFromPageData('pageUriSEO');
    if (newPageAddress !== previousPageAddress) {
      const editorAPI = this.getEditorAPI();
      editorAPI.bi.event(
        coreBi.events.topbar.pages.top_bar_PAGES_settings_seo_title_set,
        {
          field: 'page url',
        },
      );
      editorAPI.history.add('SEO address updated');
      this.seoInfo.pageUriSEO = newPageAddress;
    }
  },

  onPagesSEODescBlur() {
    const previousPageDescSeo = this.seoInfo.pageDescSeo;
    const newPageDescSeo = this.getValueFromPageData('descriptionSEO');
    if (newPageDescSeo !== previousPageDescSeo) {
      const editorAPI = this.getEditorAPI();
      editorAPI.bi.event(
        coreBi.events.topbar.pages.top_bar_PAGES_settings_seo_description_set,
      );
      editorAPI.history.add('SEO description updated');
      this.seoInfo.pageDescSeo = newPageDescSeo;
    }
  },

  onPagesSEOKeywordsBlur() {
    const previousPageKeywordsSeo = this.seoInfo.pageKeywordsSEO;
    const newPageKeywordsSEO = this.getValueFromPageData('metaKeywordsSEO');
    if (newPageKeywordsSEO !== previousPageKeywordsSeo) {
      const editorAPI = this.getEditorAPI();
      editorAPI.bi.event(
        coreBi.events.topbar.pages.top_bar_PAGES_settings_seo_keywords_set,
      );
      editorAPI.history.add('SEO keywords updated');
      this.seoInfo.pageKeywordsSEO = newPageKeywordsSEO;
    }
  },

  linkUrlProperty(prop) {
    const pageId = this.getPageId();
    const editorAPI = this.getEditorAPI();
    const newObjValue = {};
    const converter = editorAPI.dsRead.generalInfo.urlFormat.isSlash()
      ? editorAPI.pages.data.pageUriSEO
      : pageUtils;

    return {
      value: this.getValueFromPageData(prop),
      requestChange: function (newValue) {
        newObjValue[prop] =
          newValue && converter.convertPageNameToUrl(newValue);
        this.debouncedUpdateEditorAPI(editorAPI, pageId, newObjValue);
      }.bind(this),
    };
  },
  isUriSEOTranslated() {
    const translationData = this.getValueFromPageData('translationData');
    return (
      experiment.isOpen('urlFormatGoogleTranslate') &&
      translationData &&
      translationData.uriSEOTranslated
    );
  },
  getPageUriSEOValidators() {
    const validators = getValidators(this.getEditorAPI(), this.getPageId());
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/map
    return _.map(validators, 'callback');
  },
  getPageUriSEOInvalidMessages() {
    const validators = getValidators(this.getEditorAPI(), this.getPageId());
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/map
    return _.map(validators, 'message');
  },
  getWixSEOWizURL() {
    const editorAPI = this.getEditorAPI();
    return editorAPI.account.getWixSEOWizURL('EDITOR_PAGES');
  },
  reportSeoWizardLinkClicked() {
    this.getEditorAPI().bi.event(
      coreBi.events.topbar.pages.top_bar_PAGES_settings_seo_wiz_lets_go_click,
    );
  },
  onGoToUrlClicked() {
    this.getEditorAPI().bi.event(
      coreBi.events.topbar.pages.top_bar_PAGES_settings_seo_go_to_url_click,
    );
  },
  onHidePageChanged(value) {
    const editorAPI = this.getEditorAPI();
    const pageId = this.getPageId();
    const newObj = { indexable: !value };
    this.debouncedUpdateEditorAPI(editorAPI, pageId, newObj);
    editorAPI.bi.event(
      coreBi.events.topbar.pages.top_bar_PAGES_settings_hide_page_clicked,
      { title: this.getPageData().title, toggle: value },
    );
  },
});
