import React, { useEffect } from 'react';
import { Text } from '@wix/wix-base-ui';
import constants from '@/constants';
import { hoc, cx, snapToUtils } from '@/util';
import { stateMapperArgsSelectors, highlights } from '@/stateManagement';
import styles from './LayoutWidthIndicator.scss';

import type { EditorAPI } from '@/editorAPI';
import type {
  MapStateToProps,
  StateMapperArgs,
  MapDispatchToProps,
  ThunkAction,
  Dispatch,
} from 'types/redux';

const {
  mouseSels: { isResizingSel },
} = stateMapperArgsSelectors;

type DirectionName = (typeof constants.RESIZE_DIRECTIONS)[number];

type AllowedDirectionName = Exclude<DirectionName, 'top' | 'bottom'>;

interface LayoutWidthIndicatorOwnProps {}

interface LayoutWidthIndicatorStateProps {
  height: number;
  relativeWidth: number;
  direction: AllowedDirectionName;
  isOutsideParentBoundingBox: boolean;
}

interface LayoutWidthIndicatorDispatchProps {
  addParentHighlight: () => void;
  removeParentHighlight: () => void;
}

interface LayoutWidthIndicatorProps
  extends LayoutWidthIndicatorStateProps,
    LayoutWidthIndicatorDispatchProps,
    LayoutWidthIndicatorOwnProps {}

const width = 54;
const margin = 7;

const isLeftDirection = (direction: AllowedDirectionName) =>
  direction.toLowerCase().endsWith('left');

const LayoutWidthIndicator: React.FC<LayoutWidthIndicatorProps> = ({
  addParentHighlight,
  removeParentHighlight,
  direction,
  isOutsideParentBoundingBox,
  height,
  relativeWidth,
}) => {
  useEffect(() => {
    addParentHighlight();

    return () => {
      removeParentHighlight();
    };
  }, [removeParentHighlight, addParentHighlight]);

  if (relativeWidth > 100) return null;

  const displayWidth = `W: ${relativeWidth}%`;

  return (
    <div
      className={cx(styles.container, {
        [styles.left]: isLeftDirection(direction),
        [styles.outside]: isOutsideParentBoundingBox,
      })}
      style={
        {
          '--component-height': `${height}px`,
          '--indicator-width': `${width}px`,
          '--indicator-margin': `${margin}px`,
        } as React.CSSProperties
      }
    >
      <Text shouldTranslate={false} size="tiny" weight="bold">
        <span className={styles.text}>{displayWidth}</span>
      </Text>
    </div>
  );
};

const mapStateToProps: MapStateToProps<
  LayoutWidthIndicatorStateProps,
  LayoutWidthIndicatorOwnProps
> = ({ editorAPI }) => {
  const [comp] = editorAPI.selection.getSelectedComponents();
  const parent = editorAPI.components.getContainerOrScopeOwner(comp);
  const compLayout =
    editorAPI.components.layout.stage.getRelativeToScreen(comp);
  const parentLayout =
    editorAPI.components.layout.stage.getRelativeToScreen(parent);
  const relativeWidth = Math.round(
    compLayout.width / (parentLayout.width / 100),
  );
  const { directionName }: { directionName: AllowedDirectionName } =
    editorAPI.mouseActions.getRegisteredMouseMoveAction().params;

  const componentWidthWithPaddings = 2 * margin + width;
  return {
    height: compLayout.height,
    relativeWidth,
    isOutsideParentBoundingBox:
      compLayout.width + componentWidthWithPaddings >= parentLayout.width,
    direction: directionName,
  };
};

const getEditorAPI: ThunkAction = (d, gs, { editorAPI }): EditorAPI =>
  editorAPI;

const mapDispatchToProps: MapDispatchToProps<
  LayoutWidthIndicatorDispatchProps,
  LayoutWidthIndicatorOwnProps
> = (dispatch: Dispatch) => {
  const editorAPI: EditorAPI = dispatch(getEditorAPI);

  return {
    addParentHighlight: () => {
      const [comp] = editorAPI.selection.getSelectedComponents();
      const parent = editorAPI.components.getContainerOrScopeOwner(comp);

      if (editorAPI.sections.isSectionLike(parent)) return;

      dispatch(
        highlights.actions.addCompsToHighlights(
          [parent],
          constants.UI.HIGHLIGHTS.TYPES.NORMAL,
        ),
      );
    },
    removeParentHighlight: () => {
      dispatch(highlights.actions.clearHighlights());
    },
  };
};

const showLayoutWidthIndicatorSel = (stateMapperArgs: StateMapperArgs) => {
  const { editorAPI } = stateMapperArgs;

  if (!isResizingSel(stateMapperArgs)) return;

  const { directionName }: { directionName: DirectionName } =
    editorAPI.mouseActions.getRegisteredMouseMoveAction().params;

  if (
    directionName === 'top' ||
    directionName === 'bottom' ||
    directionName === undefined
  )
    return;

  const [comp] = editorAPI.selection.getSelectedComponents();

  return snapToUtils.isSnappedToContainerHorizontally(editorAPI, comp);
};

const ConnectedComponent = hoc.connect(
  hoc.STORES.MOUSE_OPS,
  mapStateToProps,
  mapDispatchToProps,
)(LayoutWidthIndicator);

export default hoc.withConditionalRender(
  hoc.STORES.EDITOR_API,
  showLayoutWidthIndicatorSel,
)(ConnectedComponent);
