import _ from 'lodash';
import imagesMap from './imagesMap';
import { ImageSources, type PlatformAppItemDefinition } from './types';

const validateImage = (
  image: string,
  imageSource: keyof typeof ImageSources,
) => {
  if (!image) {
    return true;
  }
  switch (imageSource) {
    case ImageSources.IMAGE_LIST:
      return image in imagesMap;
    case ImageSources.URL:
      return true;
    default:
      return false;
  }
};

const isNonEmptyString = (val: string) => _.isString(val) && !_.isEmpty(val);

const validateSectionDefinition = ({
  title,
  image,
  imageSource,
}: Partial<PlatformAppItemDefinition> = {}) =>
  isNonEmptyString(title) && validateImage(image, imageSource);

export { validateSectionDefinition as validate };
