import type { CompStructure, FontStringsMap } from 'types/documentServices';
import type { ComponentSwitchTypeItem, Size } from './types';
import type { EditorAPI } from '@/editorAPI';
import * as util from '@/util';
import { ComponentTypeMapper } from './typesMapper';
import { ComponentCategory, ParsedLayout } from './parsedLayout';
import { getText } from '../switchLayoutUtil';
import { getTextComponentArea } from '../switchLayoutTextUtils';

export class LayoutParser {
  static Parse(
    content: CompStructure,
    editorAPI: EditorAPI,
    textClassifications: any,
  ): ParsedLayout {
    const layout = new ParsedLayout();
    let componentArray: CompStructure[];

    if (!Array.isArray(content)) {
      componentArray = [content];
    } else {
      componentArray = content;
    }

    for (const comp of componentArray) {
      if (comp.hasOwnProperty('type')) {
        const component = comp as CompStructure;
        LayoutParser.harvestComponent(
          layout,
          component,
          editorAPI,
          textClassifications,
        );
      }
    }

    return layout;
  }

  static ensureComponentId(component: CompStructure): void {
    if (!component.hasOwnProperty('slGeneratedId')) {
      (component as any).slGeneratedId = util.guidUtils.getGUID();
    }
  }

  static harvestComponent(
    layout: ParsedLayout,
    component: CompStructure,
    editorAPI: EditorAPI,
    textClassifications: any,
  ): void {
    LayoutParser.ensureComponentId(component);
    // eslint-disable-next-line @wix/santa-editor/deprecatedFontsApi
    const fonts = editorAPI.theme.fonts.getAll();
    const componentCategory = ComponentTypeMapper.GetComponentCategory(
      component,
      textClassifications,
    );

    const item: ComponentSwitchTypeItem = {
      id: component.id,
      parent: component.parent,
      slGeneratedId: (component as any).slGeneratedId,
      componentCategory,
      componentType: component.componentType,
    };

    switch (componentCategory) {
      case ComponentCategory.Title:
      case ComponentCategory.Subtitle:
      case ComponentCategory.Paragraph: {
        const componentElement: HTMLElement = //@ts-expect-error
          window.frames.preview?.contentDocument.getElementById(component.id);
        const text = getText(
          editorAPI,
          editorAPI.components.get.byId(component.id),
        );
        const componentAreaScale = editorAPI.zoomMode.isInZoomMode() ? 2 : 1;
        item.textArea = getTextComponentArea(
          window.document,
          componentElement,
          text,
          componentAreaScale,
        );
        item.fontSize = LayoutParser.getTextFontSize(
          component.data.text,
          fonts,
        );
        break;
      }
      case ComponentCategory.Image: {
        item.imageSize = LayoutParser.getSourceImageSize(component);
        break;
      }
    }

    if (component.type === 'Container') {
      for (const child of component.components) {
        LayoutParser.harvestComponent(
          layout,
          child as CompStructure,
          editorAPI,
          textClassifications,
        );
      }
    }
    layout.AddComponent(item, componentCategory);
  }

  static getTextFontSize(text: string, fonts: FontStringsMap): number {
    let fontSize = 0;
    let fontSizeMatch = text.match('font-size:(.*?)px');
    if (!fontSizeMatch) {
      fontSizeMatch = text.match('class="(.*?)"');
      if (fontSizeMatch) {
        const theme = (fonts as any)[fontSizeMatch[1].trim()];
        if (theme) {
          fontSize = +theme.split(' ')[3].split('px')[0];
        }
      }
    } else {
      fontSize = +fontSizeMatch[1]?.trim();
    }
    return fontSize;
  }

  static getSourceImageSize(imageComponent: CompStructure): Size {
    if (imageComponent === undefined) {
      return { width: 0, height: 0 };
    }

    return {
      width: imageComponent.data?.width
        ? imageComponent.data.width
        : imageComponent.layout.width,
      height: imageComponent.data?.height
        ? imageComponent.data.height
        : imageComponent.layout.height,
      cropped: imageComponent.data?.crop
        ? {
            width: imageComponent.data.crop?.width,
            height: imageComponent.data.crop?.height,
          }
        : undefined,
    };
  }
}
