import React from 'react';
import { hoc } from '@/util';
import * as UI from '@/baseUI';
import { createStructuredSelector } from '../selectors';
import { boundingStyleSel, shouldShowPreloaderSel } from '../selectors';

interface LayoutBoundingBoxPreloaderOwnProps {}

interface LayoutBoundingBoxPreloaderStateProps {
  shouldShowPreloader: boolean;
  boundingStyle: React.CSSProperties;
}

interface LayoutBoundingBoxPreloaderProps
  extends LayoutBoundingBoxPreloaderOwnProps,
    LayoutBoundingBoxPreloaderStateProps {}

const LayoutBoundingBoxPreloader: React.FC<LayoutBoundingBoxPreloaderProps> = (
  props,
) => {
  if (!props.shouldShowPreloader) {
    return null;
  }

  return (
    <div style={props.boundingStyle} className="preloader">
      <UI.preloader />
    </div>
  );
};

const layoutBoundingBoxPreloaderPropsSel =
  createStructuredSelector<LayoutBoundingBoxPreloaderStateProps>({
    shouldShowPreloader: shouldShowPreloaderSel,
    boundingStyle: boundingStyleSel,
  });

export default hoc.connect(
  hoc.STORES.MOUSE_OPS,
  layoutBoundingBoxPreloaderPropsSel,
)(LayoutBoundingBoxPreloader);
