// @ts-nocheck
import _ from 'lodash';

function run(_editorAPI, _pageId) {
  const args = arguments;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
  _.forEach(this.list, function (plugin) {
    plugin.apply(null, args);
  });
}

export default {
  setHomePagePlugins: {
    list: [],
    run,
  },
};
