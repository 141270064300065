export default {
  getDesignSections() {
    return {
      titles: ['design_section_label_explore_signature_input'],
      sections: [
        {
          type: 'SECTIONS_TYPES_PRESET',
          hide: false,
          title: 'add_section_label_custom',
          showSectionHeader: true,
          additionalBehaviours: {
            labelMode: 'none',
            infoIcon: 'none',
            hoverImageAction: 'none',
            iconEnabledForComps: {},
          },
          props: {
            image:
              'addPanelData/sections/developer_en/signatureInputDesignPanelSection_en.png',
            imageHover: null as AnyFixMe,
            items: [
              {
                id: 'none_1',
                structure: {
                  type: 'Component',
                  skin: 'wixui.skins.SignatureInput',
                  layout: {
                    width: 304,
                    height: 130,
                    x: 10,
                    y: 12,
                    scale: 1,
                    rotationInDegrees: 0,
                    fixedPosition: false,
                  },
                  componentType: 'wixui.SignatureInput',
                  parent: 'comp-k4tloptu',
                  metaData: {
                    pageId: 'k60a9',
                  },
                  data: {
                    type: 'SignatureInput',
                    metaData: {
                      isPreset: false,
                      schemaVersion: '1.0',
                      isHidden: false,
                      pageId: 'k60a9',
                    },
                    titleText: 'Your Signature',
                    clearButtonText: 'Clear',
                  },
                  props: {
                    type: 'SignatureInputProperties',
                    metaData: {
                      schemaVersion: '1.0',
                      autoGenerated: false,
                      pageId: 'k60a9',
                    },
                    direction: 'ltr',
                    required: false,
                    titlePaddingStart: 1,
                    titleMarginBottom: 8,
                  },
                  mobileHints: {
                    type: 'MobileHints',
                    author: 'duplicate',
                    originalCompId: 'comp-k0wiiosg',
                    recommendedScale: 1,
                    recommendedWidth: 263,
                    recommendedHeight: 112,
                    metaData: {
                      pageId: 'k60a9',
                    },
                  },
                  style: {
                    type: 'ComponentStyle',
                    metaData: {
                      isPreset: false,
                      schemaVersion: '1.0',
                      isHidden: false,
                      pageId: 'k60a9',
                    },
                    style: {
                      groups: {},
                      properties: {
                        'boxShadowToggleOn-padBoxShadow': 'false',
                        clearButtonColor: '#999997',
                        clearButtonColorDisabled: '#CCCCCC',
                        clearButtonColorFocus: '#000000',
                        clearButtonFont:
                          'normal normal normal 13px/1.4em avenir-lt-w01_35-light1475496',
                        clearButtonFontDisabled:
                          'normal normal normal 13px/1.4em avenir-lt-w01_35-light1475496',
                        padBackground: '#FFFFFF',
                        padBackgroundHover: '#F2FAF9',
                        padBorderColor: '#919191',
                        padBorderColorDisabled: '#DBDBDB',
                        padBorderColorError: '#FF4040',
                        padBorderColorFocus: '#000000',
                        padBorderColorHover: '#5C5C5C',
                        padBorderRadius: '0px 0px 0px 0px',
                        padBorderWidth: '2',
                        padBorderWidthDisabled: '2px',
                        padBorderWidthFocus: '2',
                        padBorderWidthHover: '2',
                        padBoxShadow: '0px 1px 4px 0px rgba(0,0,0,0.6)',
                        padLineColor: '#000000',
                        padLineColorDisabled: '#CCCCCC',
                        padLineColorError: '#919191',
                        padLineColorFocus: '#000000',
                        padLineColorHover: '#000000',
                        padLineWidth: '1',
                        padLineWidthDisabled: '1',
                        padLineWidthError: '1',
                        padLineWidthFocus: '1',
                        padLineWidthHover: '1',
                        signatureColor: '#000000',
                        signatureSize: '1',
                        titleAsteriskColor: '#7F808A',
                        titleAsteriskColorDisabled: '#CCCCCC',
                        titleColor: '#7F808A',
                        titleColorDisabled: '#CCCCCC',
                        titleFont:
                          'normal normal normal 14px/1.4em avenir-lt-w01_35-light1475496',
                        titleFontDisabled:
                          'normal normal normal 14px/1.4em avenir-lt-w01_35-light1475496',
                      },
                      propertiesSource: {
                        'boxShadowToggleOn-padBoxShadow': 'value',
                        clearButtonColor: 'value',
                        clearButtonColorDisabled: 'value',
                        clearButtonColorFocus: 'value',
                        clearButtonFont: 'value',
                        clearButtonFontDisabled: 'value',
                        padBackground: 'value',
                        padBackgroundHover: 'value',
                        padBorderColor: 'value',
                        padBorderColorDisabled: 'value',
                        padBorderColorError: 'value',
                        padBorderColorFocus: 'value',
                        padBorderColorHover: 'value',
                        padBorderRadius: 'value',
                        padBorderWidth: 'value',
                        padBorderWidthDisabled: 'value',
                        padBorderWidthFocus: 'value',
                        padBorderWidthHover: 'value',
                        padBoxShadow: 'value',
                        padLineColor: 'value',
                        padLineColorDisabled: 'value',
                        padLineColorError: 'value',
                        padLineColorFocus: 'value',
                        padLineColorHover: 'value',
                        padLineWidth: 'value',
                        padLineWidthDisabled: 'value',
                        padLineWidthError: 'value',
                        padLineWidthFocus: 'value',
                        padLineWidthHover: 'value',
                        signatureColor: 'value',
                        signatureSize: 'value',
                        titleAsteriskColor: 'value',
                        titleAsteriskColorDisabled: 'value',
                        titleColor: 'value',
                        titleColorDisabled: 'value',
                        titleFont: 'value',
                        titleFontDisabled: 'value',
                      },
                    },
                    componentClassName: 'wixui.SignatureInput',
                    pageId: '',
                    compId: '',
                    styleType: 'custom',
                    skin: 'wixui.skins.SignatureInput',
                  },
                  activeModes: {},
                  id: 'comp-k4tlopu7',
                },
                preset: {
                  rect: {
                    width: 324,
                    height: 148,
                    x: 0,
                    y: 0,
                  },
                  tags: null as AnyFixMe,
                },
              },
              {
                id: 'none_2',
                structure: {
                  type: 'Component',
                  skin: 'wixui.skins.SignatureInput',
                  layout: {
                    width: 304,
                    height: 130,
                    x: 10,
                    y: 156,
                    scale: 1,
                    rotationInDegrees: 0,
                    fixedPosition: false,
                  },
                  componentType: 'wixui.SignatureInput',
                  parent: 'comp-k4tloptu',
                  metaData: {
                    pageId: 'k60a9',
                  },
                  data: {
                    type: 'SignatureInput',
                    metaData: {
                      isPreset: false,
                      schemaVersion: '1.0',
                      isHidden: false,
                      pageId: 'k60a9',
                    },
                    titleText: 'Your Signature',
                    clearButtonText: 'Clear',
                  },
                  props: {
                    type: 'SignatureInputProperties',
                    metaData: {
                      schemaVersion: '1.0',
                      autoGenerated: false,
                      pageId: 'k60a9',
                    },
                    direction: 'ltr',
                    required: false,
                    titlePaddingStart: 1,
                    titleMarginBottom: 8,
                  },
                  mobileHints: {
                    type: 'MobileHints',
                    author: 'duplicate',
                    originalCompId: 'comp-k0wiiosy',
                    recommendedScale: 1,
                    recommendedWidth: 260,
                    recommendedHeight: 111,
                    metaData: {
                      pageId: 'k60a9',
                    },
                  },
                  style: {
                    type: 'ComponentStyle',
                    metaData: {
                      isPreset: false,
                      schemaVersion: '1.0',
                      isHidden: false,
                      pageId: 'k60a9',
                    },
                    style: {
                      groups: {},
                      properties: {
                        'boxShadowToggleOn-padBoxShadow': 'false',
                        clearButtonColor: '#999997',
                        clearButtonColorFocus: '#000000',
                        clearButtonFont:
                          'normal normal normal 13px/1.4em avenir-lt-w01_35-light1475496',
                        clearButtonFontDisabled:
                          'normal normal normal 13px/1.4em avenir-lt-w01_35-light1475496',
                        padBackground: '#FFFFFF',
                        padBackgroundDisabled: '#FFFFFF',
                        padBackgroundFocus: '#FFFFFF',
                        padBackgroundHover: '#FEFAFF',
                        padBorderColor: '#8C84FA',
                        padBorderColorDisabled: '#DBDBDB',
                        padBorderColorError: '#FF4040',
                        padBorderColorFocus: '#6055E6',
                        padBorderColorHover: '#8C84FA',
                        padBorderRadius: '0px 0px 0px 0px',
                        padBorderWidth: '1',
                        padBorderWidthDisabled: '2',
                        padBorderWidthFocus: '1',
                        padBorderWidthHover: '1',
                        padBoxShadow: '0px 1px 4px 0px rgba(0,0,0,0.6)',
                        padLineColor: '#8C84FA',
                        padLineColorDisabled: '#CCCCCC',
                        padLineColorError: '#8C84FA',
                        padLineColorFocus: '#8C84FA',
                        padLineColorHover: '#8C84FA',
                        padLineWidth: '1',
                        padLineWidthDisabled: '1',
                        padLineWidthError: '1',
                        padLineWidthFocus: '1',
                        padLineWidthHover: '1',
                        signatureColor: '#5C5C5C',
                        signatureSize: '1',
                        titleAsteriskColor: '#8C84FA',
                        titleAsteriskColorDisabled: '#CCCCCC',
                        titleColor: '#8C84FA',
                        titleColorDisabled: '#CCCCCC',
                        titleFont:
                          'normal normal normal 15px/1.4em futura-lt-w01-light',
                        titleFontDisabled:
                          'normal normal normal 15px/1.4em futura-lt-w01-light',
                      },
                      propertiesSource: {
                        'boxShadowToggleOn-padBoxShadow': 'value',
                        clearButtonColor: 'value',
                        clearButtonColorFocus: 'value',
                        clearButtonFont: 'value',
                        clearButtonFontDisabled: 'value',
                        padBackground: 'value',
                        padBackgroundDisabled: 'value',
                        padBackgroundFocus: 'value',
                        padBackgroundHover: 'value',
                        padBorderColor: 'value',
                        padBorderColorDisabled: 'value',
                        padBorderColorError: 'value',
                        padBorderColorFocus: 'value',
                        padBorderColorHover: 'value',
                        padBorderRadius: 'value',
                        padBorderWidth: 'value',
                        padBorderWidthDisabled: 'value',
                        padBorderWidthFocus: 'value',
                        padBorderWidthHover: 'value',
                        padBoxShadow: 'value',
                        padLineColor: 'value',
                        padLineColorDisabled: 'value',
                        padLineColorError: 'value',
                        padLineColorFocus: 'value',
                        padLineColorHover: 'value',
                        padLineWidth: 'value',
                        padLineWidthDisabled: 'value',
                        padLineWidthError: 'value',
                        padLineWidthFocus: 'value',
                        padLineWidthHover: 'value',
                        signatureColor: 'value',
                        signatureSize: 'value',
                        titleAsteriskColor: 'value',
                        titleAsteriskColorDisabled: 'value',
                        titleColor: 'value',
                        titleColorDisabled: 'value',
                        titleFont: 'value',
                        titleFontDisabled: 'value',
                      },
                    },
                    componentClassName: 'wixui.SignatureInput',
                    pageId: '',
                    compId: '',
                    styleType: 'custom',
                    skin: 'wixui.skins.SignatureInput',
                  },
                  activeModes: {},
                  id: 'comp-k4tlopva',
                },
                preset: {
                  rect: {
                    width: 324,
                    height: 148,
                    x: 0,
                    y: 148,
                  },
                  tags: null as AnyFixMe,
                },
              },
              {
                id: 'none_3',
                structure: {
                  type: 'Component',
                  skin: 'wixui.skins.SignatureInput',
                  layout: {
                    width: 304,
                    height: 130,
                    x: 10,
                    y: 304,
                    scale: 1,
                    rotationInDegrees: 0,
                    fixedPosition: false,
                  },
                  componentType: 'wixui.SignatureInput',
                  parent: 'comp-k4tloptu',
                  metaData: {
                    pageId: 'k60a9',
                  },
                  data: {
                    type: 'SignatureInput',
                    metaData: {
                      isPreset: false,
                      schemaVersion: '1.0',
                      isHidden: false,
                      pageId: 'k60a9',
                    },
                    titleText: 'Your Signature',
                    clearButtonText: 'Clear',
                  },
                  props: {
                    type: 'SignatureInputProperties',
                    metaData: {
                      schemaVersion: '1.0',
                      autoGenerated: false,
                      pageId: 'k60a9',
                    },
                    direction: 'ltr',
                    required: false,
                    titlePaddingStart: 1,
                    titleMarginBottom: 8,
                  },
                  mobileHints: {
                    type: 'MobileHints',
                    author: 'duplicate',
                    originalCompId: 'comp-k0wiiotf',
                    recommendedScale: 1,
                    recommendedWidth: 260,
                    recommendedHeight: 111,
                    metaData: {
                      pageId: 'k60a9',
                    },
                  },
                  style: {
                    type: 'ComponentStyle',
                    metaData: {
                      isPreset: false,
                      schemaVersion: '1.0',
                      isHidden: false,
                      pageId: 'k60a9',
                    },
                    style: {
                      groups: {},
                      properties: {
                        'boxShadowToggleOn-padBoxShadow': 'true',
                        clearButtonColor: '#999997',
                        clearButtonColorDisabled: '#CCCCCC',
                        clearButtonColorFocus: '#000000',
                        clearButtonFont:
                          'normal normal normal 13px/1.4em avenir-lt-w01_35-light1475496',
                        clearButtonFontDisabled:
                          'normal normal normal 13px/1.4em avenir-lt-w01_35-light1475496',
                        padBackground: '#FFFFFF',
                        padBackgroundHover: '#FAFAFA',
                        padBorderColor: '#67D6D6',
                        padBorderColorDisabled: '#DBDBDB',
                        padBorderColorError: '#FF4040',
                        padBorderColorFocus: '#408F8F',
                        padBorderColorHover: '#59BABA',
                        padBorderRadius: '0px 0px 0px 0px',
                        padBorderWidth: '1',
                        padBorderWidthDisabled: '2',
                        padBorderWidthFocus: '1',
                        padBorderWidthHover: '2',
                        padBoxShadow:
                          '0.00px 2.00px 0px 0px rgba(103,214,214,1)',
                        padLineColor: '#A3E6E6',
                        padLineColorDisabled: '#CCCCCC',
                        padLineColorError: '#A3E6E6',
                        padLineColorFocus: '#67D6D6',
                        padLineColorHover: '#67D6D6',
                        padLineWidth: '1',
                        padLineWidthDisabled: '1',
                        padLineWidthError: '1',
                        padLineWidthFocus: '1',
                        padLineWidthHover: '1',
                        signatureColor: '#5C5C5C',
                        signatureSize: '2',
                        titleAsteriskColor: '#49D6D6',
                        titleAsteriskColorDisabled: '#CCCCCC',
                        titleColor: '#49D6D6',
                        titleColorDisabled: '#CCCCCC',
                        titleFont: 'italic normal normal 16px/1.4em georgia',
                        titleFontDisabled:
                          'italic normal normal 16px/1.4em georgia',
                      },
                      propertiesSource: {
                        'boxShadowToggleOn-padBoxShadow': 'value',
                        clearButtonColor: 'value',
                        clearButtonColorDisabled: 'value',
                        clearButtonColorFocus: 'value',
                        clearButtonFont: 'value',
                        clearButtonFontDisabled: 'value',
                        padBackground: 'value',
                        padBackgroundHover: 'value',
                        padBorderColor: 'value',
                        padBorderColorDisabled: 'value',
                        padBorderColorError: 'value',
                        padBorderColorFocus: 'value',
                        padBorderColorHover: 'value',
                        padBorderRadius: 'value',
                        padBorderWidth: 'value',
                        padBorderWidthDisabled: 'value',
                        padBorderWidthFocus: 'value',
                        padBorderWidthHover: 'value',
                        padBoxShadow: 'value',
                        padLineColor: 'value',
                        padLineColorDisabled: 'value',
                        padLineColorError: 'value',
                        padLineColorFocus: 'value',
                        padLineColorHover: 'value',
                        padLineWidth: 'value',
                        padLineWidthDisabled: 'value',
                        padLineWidthError: 'value',
                        padLineWidthFocus: 'value',
                        padLineWidthHover: 'value',
                        signatureColor: 'value',
                        signatureSize: 'value',
                        titleAsteriskColor: 'value',
                        titleAsteriskColorDisabled: 'value',
                        titleColor: 'value',
                        titleColorDisabled: 'value',
                        titleFont: 'value',
                        titleFontDisabled: 'value',
                      },
                    },
                    componentClassName: 'wixui.SignatureInput',
                    pageId: '',
                    compId: '',
                    styleType: 'custom',
                    skin: 'wixui.skins.SignatureInput',
                  },
                  activeModes: {},
                  id: 'comp-k4tlopw9',
                },
                preset: {
                  rect: {
                    width: 324,
                    height: 144,
                    x: 0,
                    y: 295,
                  },
                  tags: null,
                },
              },
              {
                id: 'none_4',
                structure: {
                  type: 'Component',
                  skin: 'wixui.skins.SignatureInput',
                  layout: {
                    width: 304,
                    height: 130,
                    x: 10,
                    y: 443,
                    scale: 1,
                    rotationInDegrees: 0,
                    fixedPosition: false,
                  },
                  componentType: 'wixui.SignatureInput',
                  parent: 'comp-k4tloptu',
                  metaData: {
                    pageId: 'k60a9',
                  },
                  data: {
                    type: 'SignatureInput',
                    metaData: {
                      isPreset: false,
                      schemaVersion: '1.0',
                      isHidden: false,
                      pageId: 'k60a9',
                    },
                    titleText: 'Your Signature',
                    clearButtonText: 'Clear',
                  },
                  props: {
                    type: 'SignatureInputProperties',
                    metaData: {
                      schemaVersion: '1.0',
                      autoGenerated: false,
                      pageId: 'k60a9',
                    },
                    direction: 'ltr',
                    required: false,
                    titlePaddingStart: 4,
                    titleMarginBottom: 8,
                  },
                  mobileHints: {
                    type: 'MobileHints',
                    author: 'duplicate',
                    originalCompId: 'comp-k0wiiotv',
                    recommendedScale: 1,
                    recommendedWidth: 260,
                    recommendedHeight: 111,
                    metaData: {
                      pageId: 'k60a9',
                    },
                  },
                  style: {
                    type: 'ComponentStyle',
                    metaData: {
                      isPreset: false,
                      schemaVersion: '1.0',
                      isHidden: false,
                      pageId: 'k60a9',
                    },
                    style: {
                      groups: {},
                      properties: {
                        'boxShadowToggleOn-padBoxShadow': 'false',
                        clearButtonColor: '#999997',
                        clearButtonColorDisabled: '#CCCCCC',
                        clearButtonColorFocus: '#000000',
                        clearButtonFont:
                          'normal normal normal 13px/1.4em avenir-lt-w01_35-light1475496',
                        clearButtonFontDisabled:
                          'normal normal normal 13px/1.4em avenir-lt-w01_35-light1475496',
                        padBackground: '#4BDB7B',
                        padBackgroundDisabled: '#DBDBDB',
                        padBackgroundError: '#FF4040',
                        padBackgroundFocus: '#4BDB7B',
                        padBackgroundHover: '#5EE08A',
                        padBorderColor: '#FFFFFF',
                        padBorderColorDisabled: '#DBDBDB',
                        padBorderColorError: '#FF4040',
                        padBorderColorFocus: '#FFFFFF',
                        padBorderColorHover: '#FFFFFF',
                        padBorderRadius: '6px 6px 6px 6px',
                        padBorderWidth: '0',
                        padBorderWidthDisabled: '0',
                        padBorderWidthError: '0',
                        padBorderWidthFocus: '0',
                        padBorderWidthHover: '0',
                        padBoxShadow: '0px 1px 4px 0px rgba(0,0,0,0.6)',
                        padLineColor: '#FFFFFF',
                        padLineColorDisabled: '#FFFFFF',
                        padLineColorError: '#FFFFFF',
                        padLineColorFocus: '#FFFFFF',
                        padLineColorHover: '#FFFFFF',
                        padLineWidth: '1',
                        padLineWidthDisabled: '1',
                        padLineWidthError: '1',
                        padLineWidthFocus: '1',
                        padLineWidthHover: '1',
                        signatureColor: '#FFFFFF',
                        signatureSize: '2',
                        titleAsteriskColor: '#4BDB7B',
                        titleAsteriskColorDisabled: '#CCCCCC',
                        titleColor: '#4BDB7B',
                        titleColorDisabled: '#CCCCCC',
                        titleFont:
                          'normal normal normal 14px/1.4em bree-w01-thin-oblique',
                        titleFontDisabled:
                          'normal normal normal 14px/1.4em bree-w01-thin-oblique',
                      },
                      propertiesSource: {
                        'boxShadowToggleOn-padBoxShadow': 'value',
                        clearButtonColor: 'value',
                        clearButtonColorDisabled: 'value',
                        clearButtonColorFocus: 'value',
                        clearButtonFont: 'value',
                        clearButtonFontDisabled: 'value',
                        padBackground: 'value',
                        padBackgroundDisabled: 'value',
                        padBackgroundError: 'value',
                        padBackgroundFocus: 'value',
                        padBackgroundHover: 'value',
                        padBorderColor: 'value',
                        padBorderColorDisabled: 'value',
                        padBorderColorError: 'value',
                        padBorderColorFocus: 'value',
                        padBorderColorHover: 'value',
                        padBorderRadius: 'value',
                        padBorderWidth: 'value',
                        padBorderWidthDisabled: 'value',
                        padBorderWidthError: 'value',
                        padBorderWidthFocus: 'value',
                        padBorderWidthHover: 'value',
                        padBoxShadow: 'value',
                        padLineColor: 'value',
                        padLineColorDisabled: 'value',
                        padLineColorError: 'value',
                        padLineColorFocus: 'value',
                        padLineColorHover: 'value',
                        padLineWidth: 'value',
                        padLineWidthDisabled: 'value',
                        padLineWidthError: 'value',
                        padLineWidthFocus: 'value',
                        padLineWidthHover: 'value',
                        signatureColor: 'value',
                        signatureSize: 'value',
                        titleAsteriskColor: 'value',
                        titleAsteriskColorDisabled: 'value',
                        titleColor: 'value',
                        titleColorDisabled: 'value',
                        titleFont: 'value',
                        titleFontDisabled: 'value',
                      },
                    },
                    componentClassName: 'wixui.SignatureInput',
                    pageId: '',
                    compId: '',
                    styleType: 'custom',
                    skin: 'wixui.skins.SignatureInput',
                  },
                  activeModes: {},
                  id: 'comp-k4tlopxc',
                },
                preset: {
                  rect: {
                    width: 324,
                    height: 139,
                    x: 0,
                    y: 439,
                  },
                  tags: null as AnyFixMe,
                },
              },
              {
                id: 'none_5',
                structure: {
                  type: 'Component',
                  skin: 'wixui.skins.SignatureInput',
                  layout: {
                    width: 304,
                    height: 130,
                    x: 10,
                    y: 585,
                    scale: 1,
                    rotationInDegrees: 0,
                    fixedPosition: false,
                  },
                  componentType: 'wixui.SignatureInput',
                  parent: 'comp-k4tloptu',
                  metaData: {
                    pageId: 'k60a9',
                  },
                  data: {
                    type: 'SignatureInput',
                    metaData: {
                      isPreset: false,
                      schemaVersion: '1.0',
                      isHidden: false,
                      pageId: 'k60a9',
                    },
                    titleText: 'Your Signature',
                    clearButtonText: 'Clear',
                  },
                  props: {
                    type: 'SignatureInputProperties',
                    metaData: {
                      schemaVersion: '1.0',
                      autoGenerated: false,
                      pageId: 'k60a9',
                    },
                    direction: 'ltr',
                    required: false,
                    titlePaddingStart: 1,
                    titleMarginBottom: 8,
                  },
                  mobileHints: {
                    type: 'MobileHints',
                    author: 'duplicate',
                    originalCompId: 'comp-k0wiioua',
                    recommendedScale: 1,
                    recommendedWidth: 260,
                    recommendedHeight: 111,
                    metaData: {
                      pageId: 'k60a9',
                    },
                  },
                  style: {
                    type: 'ComponentStyle',
                    metaData: {
                      isPreset: false,
                      schemaVersion: '1.0',
                      isHidden: false,
                      pageId: 'k60a9',
                    },
                    style: {
                      groups: {},
                      properties: {
                        'boxShadowToggleOn-padBoxShadow': 'true',
                        clearButtonColor: '#999997',
                        clearButtonColorDisabled: '#CCCCCC',
                        clearButtonColorFocus: '#000000',
                        clearButtonFont:
                          'normal normal normal 13px/1.4em avenir-lt-w01_35-light1475496',
                        clearButtonFontDisabled:
                          'normal normal normal 13px/1.4em avenir-lt-w01_35-light1475496',
                        padBackground: '#FFFFFF',
                        padBackgroundDisabled: '#CCCCCC',
                        padBackgroundError: '#FFFFFF',
                        padBackgroundFocus: '#FFFFFF',
                        padBackgroundHover: '#FFFFFF',
                        padBorderColor: '#F5F5F5',
                        padBorderColorDisabled: '#A3D9F6',
                        padBorderColorError: '#FF4040',
                        padBorderColorFocus: '#F5F5F5',
                        padBorderColorHover: '#F5F5F5',
                        padBorderRadius: '6px 6px 6px 6px',
                        padBorderWidth: '0',
                        padBorderWidthDisabled: '1',
                        padBorderWidthFocus: '0px',
                        padBorderWidthHover: '0px',
                        padBoxShadow: '0.00px 3.00px 19px 1px rgba(0,0,0,0.08)',
                        padLineColor: '#919191',
                        padLineColorDisabled: '#FEFAFF',
                        padLineColorError: '#919191',
                        padLineColorFocus: '#919191',
                        padLineColorHover: '#919191',
                        padLineWidth: '2',
                        padLineWidthDisabled: '1',
                        padLineWidthError: '1',
                        padLineWidthFocus: '1',
                        padLineWidthHover: '2',
                        signatureColor: '#000000',
                        signatureSize: '2',
                        titleAsteriskColor: '#919191',
                        titleAsteriskColorDisabled: '#CCCCCC',
                        titleColor: '#919191',
                        titleColorDisabled: '#CCCCCC',
                        titleFont:
                          'normal normal normal 12px/1.4em lulo-clean-w01-one-bold',
                        titleFontDisabled:
                          'normal normal normal 12px/1.4em lulo-clean-w01-one-bold',
                      },
                      propertiesSource: {
                        'boxShadowToggleOn-padBoxShadow': 'value',
                        clearButtonColor: 'value',
                        clearButtonColorDisabled: 'value',
                        clearButtonColorFocus: 'value',
                        clearButtonFont: 'value',
                        clearButtonFontDisabled: 'value',
                        padBackground: 'value',
                        padBackgroundDisabled: 'value',
                        padBackgroundError: 'value',
                        padBackgroundFocus: 'value',
                        padBackgroundHover: 'value',
                        padBorderColor: 'value',
                        padBorderColorDisabled: 'value',
                        padBorderColorError: 'value',
                        padBorderColorFocus: 'value',
                        padBorderColorHover: 'value',
                        padBorderRadius: 'value',
                        padBorderWidth: 'value',
                        padBorderWidthDisabled: 'value',
                        padBorderWidthFocus: 'value',
                        padBorderWidthHover: 'value',
                        padBoxShadow: 'value',
                        padLineColor: 'value',
                        padLineColorDisabled: 'value',
                        padLineColorError: 'value',
                        padLineColorFocus: 'value',
                        padLineColorHover: 'value',
                        padLineWidth: 'value',
                        padLineWidthDisabled: 'value',
                        padLineWidthError: 'value',
                        padLineWidthFocus: 'value',
                        padLineWidthHover: 'value',
                        signatureColor: 'value',
                        signatureSize: 'value',
                        titleAsteriskColor: 'value',
                        titleAsteriskColorDisabled: 'value',
                        titleColor: 'value',
                        titleColorDisabled: 'value',
                        titleFont: 'value',
                        titleFontDisabled: 'value',
                      },
                    },
                    componentClassName: 'wixui.SignatureInput',
                    pageId: '',
                    compId: '',
                    styleType: 'custom',
                    skin: 'wixui.skins.SignatureInput',
                  },
                  activeModes: {},
                  id: 'comp-k4tlopyi',
                },
                preset: {
                  rect: {
                    width: 324,
                    height: 143,
                    x: 0,
                    y: 578,
                  },
                  tags: null as AnyFixMe,
                },
              },
              {
                id: 'none_6',
                structure: {
                  type: 'Component',
                  skin: 'wixui.skins.SignatureInput',
                  layout: {
                    width: 304,
                    height: 130,
                    x: 10,
                    y: 723,
                    scale: 1,
                    rotationInDegrees: 0,
                    fixedPosition: false,
                  },
                  componentType: 'wixui.SignatureInput',
                  parent: 'comp-k4tloptu',
                  metaData: {
                    pageId: 'k60a9',
                  },
                  data: {
                    type: 'SignatureInput',
                    metaData: {
                      isPreset: false,
                      schemaVersion: '1.0',
                      isHidden: false,
                      pageId: 'k60a9',
                    },
                    titleText: 'Your Signature',
                    clearButtonText: 'Clear',
                  },
                  props: {
                    type: 'SignatureInputProperties',
                    metaData: {
                      schemaVersion: '1.0',
                      autoGenerated: false,
                      pageId: 'k60a9',
                    },
                    direction: 'ltr',
                    required: false,
                    titlePaddingStart: 1,
                    titleMarginBottom: 8,
                  },
                  mobileHints: {
                    type: 'MobileHints',
                    author: 'duplicate',
                    originalCompId: 'comp-k0wiiour',
                    recommendedScale: 1,
                    recommendedWidth: 260,
                    recommendedHeight: 111,
                    metaData: {
                      pageId: 'k60a9',
                    },
                  },
                  style: {
                    type: 'ComponentStyle',
                    metaData: {
                      isPreset: false,
                      schemaVersion: '1.0',
                      isHidden: false,
                      pageId: 'k60a9',
                    },
                    style: {
                      groups: {},
                      properties: {
                        'boxShadowToggleOn-padBoxShadow': 'false',
                        clearButtonColor: '#999997',
                        clearButtonColorDisabled: '#CCCCCC',
                        clearButtonColorFocus: '#000000',
                        clearButtonFont:
                          'normal normal normal 13px/1.4em avenir-lt-w01_35-light1475496',
                        clearButtonFontDisabled:
                          'normal normal normal 13px/1.4em avenir-lt-w01_35-light1475496',
                        padBackground: '#FFFFFF',
                        padBackgroundDisabled: '#FFFFFF',
                        padBackgroundError: '#FFFFFF',
                        padBackgroundFocus: '#FFFFFF',
                        padBackgroundHover: '#EAF7FF',
                        padBorderColor: '#C6E2F7',
                        padBorderColorDisabled: '#DBDBDB',
                        padBorderColorError: '#FF4040',
                        padBorderColorFocus: '#00A6FF',
                        padBorderColorHover: '#C6E2F7',
                        padBorderRadius: '6px 6px 6px 6px',
                        padBorderWidth: '1',
                        padBorderWidthDisabled: '1',
                        padBorderWidthFocus: '1',
                        padBorderWidthHover: '0',
                        padBoxShadow: '0px 1px 4px 0px rgba(0,0,0,0.6)',
                        padLineColor: '#B6C1CD',
                        padLineColorDisabled: '#CCCCCC',
                        padLineColorError: '#B6C1CD',
                        padLineColorFocus: '#B6C1CD',
                        padLineColorHover: '#B6C1CD',
                        padLineWidth: '1',
                        padLineWidthDisabled: '1',
                        padLineWidthError: '1',
                        padLineWidthFocus: '1',
                        padLineWidthHover: '1',
                        signatureColor: '#5C5C5C',
                        signatureSize: '1',
                        titleAsteriskColor: '#3D9BE9',
                        titleAsteriskColorDisabled: '#CCCCCC',
                        titleColor: '#3D9BE9',
                        titleColorDisabled: '#CCCCCC',
                        titleFont:
                          'normal normal normal 14px/1.4em helvetica-w01-light',
                        titleFontDisabled:
                          'normal normal normal 14px/1.4em helvetica-w01-light',
                      },
                      propertiesSource: {
                        'boxShadowToggleOn-padBoxShadow': 'value',
                        clearButtonColor: 'value',
                        clearButtonColorDisabled: 'value',
                        clearButtonColorFocus: 'value',
                        clearButtonFont: 'value',
                        clearButtonFontDisabled: 'value',
                        padBackground: 'value',
                        padBackgroundDisabled: 'value',
                        padBackgroundError: 'value',
                        padBackgroundFocus: 'value',
                        padBackgroundHover: 'value',
                        padBorderColor: 'value',
                        padBorderColorDisabled: 'value',
                        padBorderColorError: 'value',
                        padBorderColorFocus: 'value',
                        padBorderColorHover: 'value',
                        padBorderRadius: 'value',
                        padBorderWidth: 'value',
                        padBorderWidthDisabled: 'value',
                        padBorderWidthFocus: 'value',
                        padBorderWidthHover: 'value',
                        padBoxShadow: 'value',
                        padLineColor: 'value',
                        padLineColorDisabled: 'value',
                        padLineColorError: 'value',
                        padLineColorFocus: 'value',
                        padLineColorHover: 'value',
                        padLineWidth: 'value',
                        padLineWidthDisabled: 'value',
                        padLineWidthError: 'value',
                        padLineWidthFocus: 'value',
                        padLineWidthHover: 'value',
                        signatureColor: 'value',
                        signatureSize: 'value',
                        titleAsteriskColor: 'value',
                        titleAsteriskColorDisabled: 'value',
                        titleColor: 'value',
                        titleColorDisabled: 'value',
                        titleFont: 'value',
                        titleFontDisabled: 'value',
                      },
                    },
                    componentClassName: 'wixui.SignatureInput',
                    pageId: '',
                    compId: '',
                    styleType: 'custom',
                    skin: 'wixui.skins.SignatureInput',
                  },
                  activeModes: {},
                  id: 'comp-k4tlopzv',
                },
                preset: {
                  rect: {
                    width: 324,
                    height: 148,
                    x: 0,
                    y: 721,
                  },
                  tags: null,
                },
              },
            ],
            compTypes: ['wixui.SignatureInput'],
          },
          help: {
            hide: true,
            text: '',
          },
        },
      ],
    };
  },
};
