import * as feedbackData from './feedbackData';
import experiment from 'experiment';
const { FEEDBACK_EVENT_TYPES, feedbackObjs } = feedbackData;

function showFeedback(
  feedbackEventType: AnyFixMe,
  compType: AnyFixMe,
  openFeedbackDialog: AnyFixMe,
) {
  if ((feedbackObjs as AnyFixMe)[feedbackEventType]?.[compType]) {
    const { feedbackObj, experimentName } = (feedbackObjs as AnyFixMe)[
      feedbackEventType
    ][compType];

    if (feedbackObj && (!experimentName || experiment.isOpen(experimentName))) {
      openFeedbackDialog(feedbackObj);
    }
  }
}

export { FEEDBACK_EVENT_TYPES, showFeedback };
