import constants from '@/constants';

import type { MapDispatchToProps } from 'types/redux';
import type { OutOfGridMobilePanelOwnProps } from './outOfGridMobilePanel';

const HELP_ID = '769b8807-30b7-4ee4-8a7b-93674099732f';

export interface OutOfGridMobilePanelDispatchProps {
  onLearnMoreClick: () => void;
}

export const mapDispatchToProps: MapDispatchToProps<
  OutOfGridMobilePanelDispatchProps,
  OutOfGridMobilePanelOwnProps
> = (dispatch, ownProps) => ({
  onLearnMoreClick: () => {
    const editorAPI = dispatch(
      (_dispatch: AnyFixMe, _getState: AnyFixMe, { editorAPI }: AnyFixMe) =>
        editorAPI,
    );
    const biHelpParams = {
      component: editorAPI.selection.getSelectedComponentType(),
      panel_name: ownProps.panelName,
      origin: constants.BI.HELP.ORIGIN.PANEL,
      learn_more: true,
    };

    editorAPI.panelManager.openHelpCenter(HELP_ID, null, biHelpParams);
  },
});
