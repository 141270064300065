:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._no-search-results-wrapper_b20xt_7 {
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 1;
  max-width: 292px;
  margin: 10px auto auto;
  padding: 24px;
  box-sizing: border-box;
  text-align: center; }

._no-search-results-title_b20xt_19 {
  margin-top: 18px; }

._no-search-results-text_b20xt_22 {
  margin-top: 10px;
  color: #868aa5; }
