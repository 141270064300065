// @ts-nocheck
import React from 'react';
import { Preloader } from '@wix/wix-base-ui';
import { cx, hoc } from '@/util';

import mapper from './languageChangeOverlayMapper';

const {
  connect,
  STORES: { EDITOR_API },
} = hoc;

class LanguageChangeOverlay extends React.Component {
  static displayName = 'languageChangeOverlay';

  state = {
    isCompletingLanguageChange: false,
  };

  handleTransitionEnd = () => {
    this.setState({
      isCompletingLanguageChange: this.props.isLanguageChanging,
    });
    if (!this.props.nextLanguage) {
      return;
    }
    this.props.setCurrentLanguage(this.props.nextLanguage);
    this.props.completeLanguageChange();
  };

  render() {
    return (
      <div
        key="language-change-overlay"
        onTransitionEnd={this.handleTransitionEnd}
        className={cx('language-change-overlay', {
          active: this.props.isLanguageChanging,
          animated:
            this.props.isLanguageChanging ||
            this.state.isCompletingLanguageChange,
        })}
      >
        <div className="preloader">
          <Preloader className="medium" />
        </div>
      </div>
    );
  }
}

const ConnectedComponent = connect(
  EDITOR_API,
  mapper.mapStateToProps,
  mapper.mapDispatchToProps,
)(LanguageChangeOverlay);
ConnectedComponent.pure = LanguageChangeOverlay;
export default ConnectedComponent;
