// @ts-nocheck
import _ from 'lodash';
import * as closeButtonsQueries from '../queries/closeButtonsQueries';
import * as openBehaviorsQueries from '../queries/openBehaviorsQueries';

export interface PopupDesktopPanelProps {
  delay: number;
  hasIconButton: boolean;
  hasTextButton: boolean;
  isMasterPageSelected: boolean;
  selectedPageIds: string[];
  startISODate: string;
  endISODate: string;
  scheduleTimeZone: string;
}

export default function getDesktopPanelProps(
  editorAPI,
): PopupDesktopPanelProps {
  const popupPointer = editorAPI.pages.popupPages.getCurrentPopup();
  const allOpenBehaviors =
    editorAPI.pages.popupPages.getOpenBehaviorsForPopups();
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/filter
  const currentOpenBehaviors = _.filter(allOpenBehaviors, {
    popupId: popupPointer.id,
  });
  const closeButtons = closeButtonsQueries.getCloseButtonsInfo(
    popupPointer,
    editorAPI,
  );

  const selectedPageIds = currentOpenBehaviors.reduce(
    (selectedPagesIds, behavior) => {
      const {
        params: { openInDesktop },
      } = behavior;

      if (openInDesktop) {
        selectedPagesIds.push(behavior.pageId);
      }
      return selectedPagesIds;
    },
    [],
  );

  return {
    delay: openBehaviorsQueries.getDelay(currentOpenBehaviors, editorAPI),
    startISODate: openBehaviorsQueries.getStartISODate(
      currentOpenBehaviors,
      editorAPI,
    ),
    endISODate: openBehaviorsQueries.getEndISODate(
      currentOpenBehaviors,
      editorAPI,
    ),
    scheduleTimeZone: openBehaviorsQueries.getScheduleTimeZone(
      currentOpenBehaviors,
      editorAPI,
    ),
    hasIconButton: closeButtons.has.icon,
    hasTextButton: closeButtons.has.text,
    isMasterPageSelected: openBehaviorsQueries.isMasterPageSelected(
      editorAPI,
      currentOpenBehaviors,
    ),
    selectedPageIds,
  };
}
