// @ts-nocheck
import _ from 'lodash';
import constants from '@/constants';
import * as pageUtils from './pageUtils';
import * as util from '@/util';
import { translate } from '@/i18n';
import * as coreBi from '@/coreBi';
import { isCustomMenusEnabled } from '@/util';

const MAX_TITLE_LENGTH = 40;

function renameMenuItem(editorAPI, menuItem, newLabel) {
  if (!newLabel || menuItem.label === newLabel) {
    return;
  }

  editorAPI.dsActions.mainMenu.setItemLabel(menuItem.id, newLabel);
  editorAPI.history.debouncedAdd('Rename Menu Item');
}

function performPageUpdate(editorAPI, pageId, data) {
  editorAPI.pages.data.update(pageId, data);
  editorAPI.history.debouncedAdd('Rename Page');
}

function renamePage(editorAPI, pageData, newLabel) {
  const data = { title: newLabel };

  if (!newLabel || pageData.title === newLabel) {
    return;
  }

  if (!pageData.isPopup) {
    if (shouldOverridePageUriSEO(editorAPI, pageData.title)) {
      const converter = editorAPI.dsRead.generalInfo.urlFormat.isSlash()
        ? editorAPI.pages.data.pageUriSEO
        : pageUtils;
      let convertedPageUriSEO = converter.convertPageNameToUrl(newLabel);
      if (convertedPageUriSEO) {
        data.pageUriSEO = convertedPageUriSEO;
        performPageUpdate(editorAPI, pageData.id, data);
      } else {
        util.googleTranslate(newLabel, function (translation) {
          convertedPageUriSEO = converter.convertPageNameToUrl(translation);
          if (convertedPageUriSEO) {
            data.pageUriSEO = convertedPageUriSEO;
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line you-dont-need-lodash-underscore/assign
            data.translationData = _.assign({} || data.translationData, {
              uriSEOTranslated: true,
            });
          }
          performPageUpdate(editorAPI, pageData.id, data);
        });
      }
    } else {
      performPageUpdate(editorAPI, pageData.id, data);
    }
  } else {
    performPageUpdate(editorAPI, pageData.id, data);
  }
}

function canDuplicate(editorAPI, data) {
  if (data.type.isPage && data.pageData) {
    return pageUtils.canDuplicate(editorAPI, data.pageData.id);
  }

  return data.type.isDropdown || data.type.isLink;
}

function generateDuplicatedTitle(title) {
  const regExp = new RegExp(/copy\s(\d+\s|)of\s/i);
  let count;

  if (regExp.test(title)) {
    if (RegExp.$1) {
      count = parseInt(RegExp.$1, 10) + 1;
    } else {
      count = 2;
    }
    return title.replace(regExp, `Copy ${count} of `);
  }

  title = `${translate('Pages_Actions_Duplicate_CopyOf')} ${title}`;

  if (title.length > MAX_TITLE_LENGTH) {
    title = `${title.substr(0, MAX_TITLE_LENGTH - 3)}...`;
  }

  return title;
}

function duplicatePageInternal(editorAPI, pageData, isPopup) {
  const newPage = isPopup
    ? editorAPI.pages.popupPages.duplicate(pageData.id)
    : editorAPI.pages.duplicate(pageData.id);
  const newTitle = generateDuplicatedTitle(pageData.title);

  const pageUriSEO = (
    editorAPI.dsRead.generalInfo.urlFormat.isSlash()
      ? editorAPI.pages.data.pageUriSEO
      : pageUtils
  ).convertPageNameToUrl(newTitle);

  editorAPI.pages.data.update(newPage.id, {
    title: newTitle,
    pageUriSEO,
  });

  return newPage.id;
}

function duplicatePopup(editorAPI, pageData, duplicatedCallback) {
  const pageId = editorAPI.dsRead.pages.getFocusedPageId();
  const newPopupId = duplicatePageInternal(editorAPI, pageData, true);
  editorAPI.pages.navigateTo(newPopupId);
  editorAPI.history.add('popup page was duplicated', { currentPage: pageId });

  editorAPI.dsActions.waitForChangesApplied(function () {
    if (_.isFunction(duplicatedCallback)) {
      duplicatedCallback(newPopupId);
    }
  });
}

function duplicatePageAndMoveAfterMenuItem(
  editorAPI,
  pageData,
  precedingMenuItemId,
  callback,
) {
  const pageId = editorAPI.dsRead.pages.getFocusedPageId();
  const newPageId = duplicatePageInternal(editorAPI, pageData, false);
  editorAPI.dsActions.waitForChangesApplied(function () {
    const newMenuItem = getMenuItemByPageId(`#${newPageId}`, editorAPI);
    pasteMenuItemAfterSelected(editorAPI, newMenuItem.id, precedingMenuItemId);
    editorAPI.pages.navigateTo(newPageId);
    editorAPI.history.add('duplicating a page', { currentPage: pageId });

    editorAPI.dsActions.waitForChangesApplied(function () {
      if (_.isFunction(callback)) {
        callback(newMenuItem.id, newPageId);
      }
    });
  });
}

function duplicateLink(editorAPI, menuItem, callback) {
  const linkData = editorAPI.data.getById(menuItem.id).link;
  const newTitle = generateDuplicatedTitle(menuItem.label);
  const newMenuItemId = editorAPI.dsActions.mainMenu.addLinkItem(
    linkData,
    newTitle,
    null,
    !menuItem.isVisible,
    !menuItem.isVisibleMobile,
  );

  pasteMenuItemAfterSelected(editorAPI, newMenuItemId, menuItem.id);

  editorAPI.history.add('duplicating a link');

  editorAPI.dsActions.waitForChangesApplied(function () {
    callback(newMenuItemId);
  });
}

function duplicateDropdown(editorAPI, menuItem, callback) {
  const newTitle = generateDuplicatedTitle(menuItem.label);
  const newMenuItemId = editorAPI.dsActions.mainMenu.addHeaderItem(
    newTitle,
    null,
    !menuItem.isVisible,
    !menuItem.isVisibleMobile,
  );
  pasteMenuItemAfterSelected(editorAPI, newMenuItemId, menuItem.id);

  editorAPI.history.add('duplicating a dropdown');

  editorAPI.dsActions.waitForChangesApplied(function () {
    callback(newMenuItemId);
  });
}

function duplicateMenuItem(editorAPI, menuItem, callback) {
  if (menuItem.type.isPage) {
    duplicatePageAndMoveAfterMenuItem(
      editorAPI,
      menuItem.pageData,
      menuItem.id,
      callback,
    );
  } else if (menuItem.type.isDropdown) {
    duplicateDropdown(editorAPI, menuItem, callback);
  } else if (menuItem.type.isLink) {
    duplicateLink(editorAPI, menuItem, callback);
  } else {
    console.error('not supported menu type');
  }
}

function canHide(editorAPI, data) {
  // TODO: remove UI block from nodeContentUniversalRenderer.tsx when merging the experiment
  if (isCustomMenusEnabled()) {
    return false;
  }

  const viewMode = editorAPI.dsRead.viewMode.get();

  if (viewMode === editorAPI.dsRead.viewMode.VIEW_MODES.DESKTOP) {
    return data.isVisible;
  }

  if (viewMode === editorAPI.dsRead.viewMode.VIEW_MODES.MOBILE) {
    return data.isVisibleMobile;
  }

  return false;
}

function canUnhide(editorAPI, data) {
  // TODO: remove UI block from nodeContentUniversalRenderer.tsx when merging the experiment
  if (isCustomMenusEnabled()) {
    return false;
  }
  return !canHide(editorAPI, data);
}

/**
 * Sets page hidden on desktop and mobile.
 * Non-boolean flags are ignored and replaced with current values.
 * @param {core.EditorAPI} editorAPI
 * @param {MenuItemsRepositoryItem} data - data returned from menuItemsRepository
 * @param {boolean} isHiddenDesktop - set hidden on desktop,
 * @param {boolean} isHiddenMobile - The author of the book.
 */
function setHidden(editorAPI, data, isHiddenDesktop, isHiddenMobile?) {
  const updatedFields = {};
  if (typeof isHiddenDesktop === 'boolean') {
    updatedFields.hidePage = isHiddenDesktop;
  } else {
    isHiddenDesktop = !data.isVisible;
  }
  if (typeof isHiddenMobile === 'boolean') {
    updatedFields.mobileHidePage = isHiddenMobile;
  } else {
    isHiddenMobile = !data.isVisibleMobile;
  }

  if (data.pageData) {
    editorAPI.pages.data.update(data.pageData.id, updatedFields);
  } else {
    editorAPI.dsActions.mainMenu.setItemVisibility(
      data.id,
      isHiddenDesktop,
      isHiddenMobile,
    );
  }

  editorAPI.history.add('hiding a page');
}

function canSubpage(data) {
  const isFirstPage = data.position === 0;

  return !data.parent && !isFirstPage;
}

function subpage(editorAPI, data) {
  let futureParent, position;

  if (canSubpage(data)) {
    futureParent =
      editorAPI.mainMenu.getMenuItemsTreeForMainMenu()[data.position - 1];
    position = futureParent.items.length;

    moveChildrenTo(editorAPI, data, futureParent.id, position);
    editorAPI.dsActions.waitForChangesApplied(function () {
      editorAPI.dsActions.mainMenu.moveItem(data.id, futureParent.id, position);
      editorAPI.bi.event(
        coreBi.events.topbar.pages.pages_menu_sub_page_created,
        { origin: 'quick actions' },
      );
    });
  }
  editorAPI.history.add('subpaging a page');
}

function moveChildrenTo(editorAPI, data, parentId, position) {
  _.forEachRight(data.items, function (menuItem) {
    editorAPI.dsActions.waitForChangesApplied(function () {
      editorAPI.dsActions.mainMenu.moveItem(menuItem.id, parentId, position);
    });
  });
}

function canRemoveMenuItem(editorAPI, menuItem) {
  if (menuItem.pageData) {
    return pageUtils.canRemove(editorAPI, menuItem.pageData.id);
  }

  return true;
}

function removeMenuItem(editorAPI, menuItem) {
  if (!menuItem.pageData) {
    editorAPI.pages.navigateTo(editorAPI.homePage.get(), function () {
      editorAPI.dsActions.mainMenu.removeItem(menuItem.id);
      editorAPI.history.add('removing menu item');
    });
  } else if (editorAPI.mainMenu.hasOnlyLastPageMenuItem()) {
    editorAPI.panelManager.openPanel(
      'panels.messagePanels.deleteLastPageMessage',
      {
        isLastPage: true,
      },
      true,
    );
  } else {
    const pageId = editorAPI.dsRead.pages.getFocusedPageId();
    editorAPI.pages.remove(menuItem.pageData.id, function () {
      editorAPI.history.add('removing a page', {
        currentPage: pageId,
      });
    });
  }
}

function removePopup(editorAPI, popupId) {
  editorAPI.dsActions.waitForChangesApplied(function () {
    editorAPI.pages.remove(
      popupId,
      _.partial(editorAPI.history.add, 'removed popup'),
    );
  });
}

function canMoveUp(data) {
  return data.parent !== null;
}

function moveUp(editorAPI, data) {
  if (canMoveUp(data)) {
    const grandParentId = data.parent.parent?.id;
    const position = data.parent.position + 1;

    editorAPI.dsActions.mainMenu.moveItem(data.id, grandParentId, position);
    editorAPI.bi.event(
      coreBi.events.topbar.pages.top_bar_PAGES_menu_user_reverted_to_main_page,
      { origin: 'quick actions' },
    );
  }
  editorAPI.history.add('moving a page out of its parent');
}

function addPage(editorAPI, title, pageData, selectedMenuItemId, doneCB) {
  let newMenuItem;
  const newPage = editorAPI.pages.add(title, pageData);

  editorAPI.dsActions.waitForChangesApplied(function () {
    newMenuItem = getMenuItemByPageId(`#${newPage.id}`, editorAPI);
    pasteMenuItemAfterSelected(editorAPI, newMenuItem.id, selectedMenuItemId);
    editorAPI.history.add('adding new page', {
      actionType: constants.DS_ACTIONS.ADD_PAGE,
      nextPage: newPage.id,
    });
    editorAPI.pages.navigateTo(newPage.id);
    doneCB(newPage.id, newMenuItem.id);
  });

  return newPage;
}

function addLink(editorAPI, linkData, title, selectedMenuItemId, doneCB) {
  const newMenuItemId = editorAPI.dsActions.mainMenu.addLinkItem(
    linkData,
    title,
  );
  pasteMenuItemAfterSelected(editorAPI, newMenuItemId, selectedMenuItemId);

  editorAPI.dsActions.waitForChangesApplied(function () {
    doneCB(newMenuItemId);
    editorAPI.history.add('adding new page link');
  });
}

function updateLink(editorAPI, menuItemId, linkData) {
  editorAPI.dsActions.mainMenu.updateLinkItem(menuItemId, linkData);
  editorAPI.history.add('updating menu link');
}

function addDropdown(editorAPI, title, selectedMenuItemId, doneCB) {
  const newMenuItemId = editorAPI.dsActions.mainMenu.addHeaderItem(title);
  pasteMenuItemAfterSelected(editorAPI, newMenuItemId, selectedMenuItemId);

  editorAPI.dsActions.waitForChangesApplied(function () {
    doneCB(newMenuItemId);
    editorAPI.history.add('adding new page header');
  });
}

function getMenuItemByPageId(pageId, editorAPI) {
  const mainMenu = editorAPI.dsRead.mainMenu.getMenu();
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/find
  return _.find(
    mainMenu,
    (menuItem) => menuItem.link && menuItem.link.pageId === pageId,
  );
}

function pasteMenuItemAfterSelected(editorAPI, menuItemId, selectedMenuItemId) {
  const menuItemPositionInfo = getMenuItemPositionInfo(
    editorAPI.mainMenu.getMenuItemsTreeForMainMenu(),
    selectedMenuItemId,
    null,
  );

  editorAPI.dsActions.mainMenu.moveItem(
    menuItemId,
    menuItemPositionInfo.parentId,
    menuItemPositionInfo.itemIndex + 1,
  );
}

function getMenuItemPositionInfo(mainMenu, menuItemId, parentId) {
  const positionInfo = {
    itemIndex: null,
    parentId,
  };

  for (let i = 0; i < mainMenu.length; i++) {
    const item = mainMenu[i];

    if (item.id === menuItemId) {
      positionInfo.itemIndex = i;

      return positionInfo;
    }

    if (item.items?.length) {
      const subItemPositionInfo = getMenuItemPositionInfo(
        item.items,
        menuItemId,
        item.id,
      );

      if (subItemPositionInfo.itemIndex !== null) {
        return subItemPositionInfo;
      }
    }
  }

  return positionInfo;
}

function getPublicUrl(editorAPI, pageData) {
  const baseUrl = editorAPI.dsRead.generalInfo.getPublicUrl();
  const defaultSiteName =
    editorAPI.dsRead.generalInfo.isFirstSave() ||
    editorAPI.dsRead.generalInfo.isDraft()
      ? `${baseUrl}your-site-name`
      : '';

  return editorAPI.pages.getPageUrl(pageData.id, defaultSiteName);
}

function shouldOverridePageUriSEO(editorAPI, title) {
  return (
    !editorAPI.dsRead.generalInfo.urlFormat.isSlash() ||
    !editorAPI.dsRead.generalInfo.isSitePublished() ||
    title === translate('Pages_Menu_Add_New_Page')
  );
}

function canBeSetAsDynamicPage(editorAPI, menuItem) {
  const pageId = menuItem.pageData?.id;
  if (!pageId) {
    return false;
  }

  return editorAPI.dsRead.routers.pages.isConnectablePage(pageId);
}

export {
  renamePage,
  renameMenuItem,
  duplicatePopup,
  duplicateMenuItem,
  setHidden,
  removeMenuItem,
  removePopup,
  subpage,
  moveUp,
  addPage,
  addLink,
  updateLink,
  addDropdown,
  canBeSetAsDynamicPage,
  canDuplicate,
  canUnhide,
  canHide,
  canRemoveMenuItem,
  canSubpage,
  canMoveUp,
  getPublicUrl,
};
