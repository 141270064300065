import React from 'react';
import * as symbols from '@wix/santa-editor-symbols';
import type { ITimingOption } from './transitionPanelTypes';
import { TimingFunctionTypes } from './transitionPanelTypes';

export const DURATION_SETTING = {
  min: 0,
  max: 4,
  step: 0.1,
};

export const DELAY_SETTING = {
  min: 0,
  max: 8,
  step: 0.1,
};

export const THUMBNAIL_IMAGE_SIZE = 72;

export const noneThumbnailProps = {
  value: TimingFunctionTypes.NONE,
  label: 'interactions_timing_easing_none',
  illustration: React.createElement(symbols.symbol, {
    name: 'thumb-none',
  }),
};

export const TIMING_OPTIONS: ITimingOption[] = [
  {
    value: TimingFunctionTypes.EASE_IN,
    label: 'interactions_timing_easing_ease_in',
    illustrationDefault: 'easeInDefault.png',
    illustrationHover: 'easeInHover.gif',
    illustrationSelected: 'easeInSelected.png',
    illustrationSelectedHover: 'easeInSelectedHover.gif',
  },
  {
    value: TimingFunctionTypes.EASE_OUT,
    label: 'interactions_timing_easing_ease_out',
    illustrationDefault: 'easeOutDefault.png',
    illustrationHover: 'easeOutHover.gif',
    illustrationSelected: 'easeOutSelected.png',
    illustrationSelectedHover: 'easeOutSelectedHover.gif',
  },
  {
    value: TimingFunctionTypes.EASE_IN_OUT,
    label: 'interactions_timing_easing_ease_in_out',
    illustrationDefault: 'easeInOutDefault.png',
    illustrationHover: 'easeInOutHover.gif',
    illustrationSelected: 'easeInOutSelected.png',
    illustrationSelectedHover: 'easeInOutSelectedHover.gif',
  },
  {
    value: TimingFunctionTypes.LINEAR,
    label: 'interactions_timing_easing_linear',
    illustrationDefault: 'linearDefault.png',
    illustrationHover: 'linearHover.gif',
    illustrationSelected: 'linearSelected.png',
    illustrationSelectedHover: 'linearSelectedHover.gif',
  },
];
