import * as stateManagement from '@/stateManagement';
import constants from '@/constants';

import type { EditorAPI } from '@/editorAPI';
import type { CompRef } from 'types/documentServices';

export type HighlightType = ValueOf<typeof constants.UI.HIGHLIGHTS.TYPES>;

function createHighlightsStore(store: EditorAPI['store']) {
  const HighlightsType = constants.UI.HIGHLIGHTS.TYPES;
  const highlightsActions = stateManagement.highlights.actions;

  function addCompsToHighlights(
    compRefs: CompRef[],
    highlightType: HighlightType,
  ) {
    store.dispatch(
      highlightsActions.addCompsToHighlights(compRefs, highlightType),
    );
  }

  function addExternalHighlight(compRef: CompRef) {
    store.dispatch(highlightsActions.addExternalHighlight(compRef));
  }

  function clearHighlights() {
    store.dispatch(highlightsActions.clearOverlayHighlights());
  }

  function clearExternalHighlights() {
    store.dispatch(highlightsActions.clearExternalHighlights());
  }

  function removeFromHighlightsByHighlightType(
    types: ValueOf<typeof HighlightsType>[],
  ) {
    store.dispatch(
      highlightsActions.removeFromHighlightsByHighlightType(types),
    );
  }

  return {
    addCompsToHighlights,
    addExternalHighlight,
    clearHighlights,
    clearExternalHighlights,
    removeFromHighlightsByHighlightType,
  };
}

export function createHighlightsApi({ editorAPI }: { editorAPI: EditorAPI }) {
  const highlightsStore = createHighlightsStore(editorAPI.store);
  const HighlightsType = constants.UI.HIGHLIGHTS.TYPES;

  return {
    addCompsToHighlights: highlightsStore.addCompsToHighlights,
    addExternalHighlight: highlightsStore.addExternalHighlight,
    clearHighlights: highlightsStore.clearHighlights,
    clearExternalHighlights: highlightsStore.clearExternalHighlights,
    removeFromHighlightsByHighlightType:
      highlightsStore.removeFromHighlightsByHighlightType,
    HighlightsType,
  };
}

export type HighlightsApi = ReturnType<typeof createHighlightsApi>;
