import { biLogger } from '@/util';
import constants from '@/constants';
import {
  helpClick,
  sitePasswordErrorEvent,
  sitePasswordSettingsChanged,
} from '@wix/bi-logger-editor/v2';
import { SiteAccessPermission } from '@/constants';

export enum ClosePanelOrigin {
  HeaderCloseButton = 'header_close_button',
  FooterSaveButton = 'footer_save_button',
  FooterCancelButton = 'footer_cancel_button',
  Keyboard = 'keyboard',
}

export enum HelpClickOrigin {
  General = 'site_access/general',
  EveryoneState = 'site_access/everyone_state',
  HoldersState = 'site_access/password_holders_state',
}

const PANEL_NAME = constants.PANELS.SITE_ACCESS.PANEL_NAME;

interface SiteAccessPanelBi {
  onLearnMoreClicked: (helpId: string, origin: HelpClickOrigin) => void;
  onSaveFailed: (permission: SiteAccessPermission) => void;
  onSaveSuccess: (permission: SiteAccessPermission) => void;
  onPasswordChanged: (isValid: boolean) => void;
  onPermissionChanged: (permission: SiteAccessPermission) => void;
  onPasswordMarkedInvalid: (errorMessage: string) => void;
}

const onLearnMoreClicked = (helpId: string, origin: HelpClickOrigin) => {
  biLogger.report(
    helpClick({
      origin,
      helpId,
      learn_more: true,
      panel_name: PANEL_NAME,
    }),
  );
};

const onPermissionChanged = (permission: SiteAccessPermission) => {
  biLogger.report(
    sitePasswordSettingsChanged({
      action: 'state_selected',
      value: permission,
    }),
  );
};

const onPasswordChanged = (isValid: boolean) => {
  biLogger.report(
    sitePasswordSettingsChanged({
      action: 'site_password_changed',
      isSuccess: isValid,
    }),
  );
};

const onSaveSuccess = (permission: SiteAccessPermission) => {
  biLogger.report(
    sitePasswordSettingsChanged({
      action: 'click_save',
      value: permission,
      isSuccess: true,
    }),
  );
};

const onSaveFailed = (permission: SiteAccessPermission) => {
  biLogger.report(
    sitePasswordSettingsChanged({
      action: 'click_save',
      value: permission,
      isSuccess: false,
    }),
  );
};

const onPasswordMarkedInvalid = (errorMessage: string) => {
  biLogger.report(
    sitePasswordErrorEvent({
      errorMessage,
    }),
  );
};

const siteAccessPanelBi: SiteAccessPanelBi = {
  onSaveFailed,
  onSaveSuccess,
  onPasswordChanged,
  onLearnMoreClicked,
  onPermissionChanged,
  onPasswordMarkedInvalid,
};

export default siteAccessPanelBi;
