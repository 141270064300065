import type { ReactElement } from 'react';
import type { AddPresetScope } from '../../../../addPresetApiEntry';
import { getPreviewerPreviewMaxHeight } from '../../../../addPresetUtil';
import { ADD_SECTION_PANEL_CONTENT_WIDTH } from '../../../../addPresetConsts';
import type { CompStructure } from 'types/documentServices';
import type { SectionPresetDefinition } from '../../../../types';
import type { ResultsDisplayOwnProps } from './resultsDisplay';
import { biLogger } from '@/util';
import { addSectionPhaseFinished } from '@wix/bi-logger-editor/v2';
import { getBusinessData } from '../utils';

export interface ResultsDisplayDispatchProps {
  getPreviewerComponents: (
    sectionDefinitions: SectionPresetDefinition[],
    onPresetReady: (sectionId: string) => void,
  ) => Promise<ReactElement[]>;
  getBlankSectionStructure: ({
    height,
    width,
    y,
  }: {
    height: number;
    width: number;
    y: number;
  }) => CompStructure;
  sendSectionPhaseFinishedEvent: (phaseName: string, duration: number) => void;
}

export const mapDispatchToProps = (
  scope: AddPresetScope,
  { categoryId }: ResultsDisplayOwnProps,
): ResultsDisplayDispatchProps => ({
  getPreviewerComponents: (sectionDefinitions, onPresetReady) => {
    return scope.previewerApi.createPreviewComponents({
      previewsData: sectionDefinitions.map((section) => ({
        structure: section?.injectedStructure,
        displayWidth: ADD_SECTION_PANEL_CONTENT_WIDTH,
        targetDefaultHeight: getPreviewerPreviewMaxHeight(section),
        onReady: () => onPresetReady(section._id),
      })),
    });
  },
  getBlankSectionStructure: scope.sectionsAPI.getBlankSectionStructure,
  sendSectionPhaseFinishedEvent: (phaseName: string, duration: number) => {
    const { businessType: business_type } = getBusinessData(scope);

    biLogger.report(
      addSectionPhaseFinished({
        phaseName,
        duration,
        business_type,
        correlation_id: `panelOpenCount_${scope.store.getSectionPanelOpenCount()}`,
        category: categoryId,
      }),
    );
  },
});
