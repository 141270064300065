// @ts-nocheck
import wixFormsConstants from '../constants';
import * as wixFormsUtils from '../utils';
import addPanelDataConsts from '@/addPanelDataConsts';

const title = 'add_section_label_subscribersforms';
const presetTitle = 'add_section_label_subscribersforms_preset';
const tooltipTitle = 'add_section_label_subscribersforms_tooltip_title';
const subNavigationTitle = 'add_section_label_subscribersforms_sidebar';
const helpText = 'add_section_label_subscribersforms_tooltip_description';

const getSubscribersSection = (editorAPI) => ({
  subscribersSection: {
    type: addPanelDataConsts.SECTIONS_TYPES.PRESET,
    hide: false,
    title,
    topTitle: title,
    tooltipTitle,
    sectionName: 'subscribersSection',
    automationId: 'add-panel-section-subscribersSection',
    appDefinitionId: wixFormsConstants.FORM_BUILDER_APP_ID,
    showSectionHeader: true,
    subNavigationTitle,
    subNavigationHide: false,
    presetTitle,
    additionalBehaviours: {
      labelMode: 'none',
      infoIcon: 'none',
      hoverImageAction: 'scale',
      iconEnabledForComps: {},
    },
    props: {
      image:
        'addPanelData/sections/formsSubscribersSection_en/formsSubscribersSection_en.v7.png',
      imageStyle: wixFormsConstants.imageStyle,
      imageHover: null,
      items: [
        {
          id: 'Subscribers_Forms_1',
          onDrop: wixFormsUtils.onDrop(
            editorAPI,
            wixFormsConstants.FormPreset.SUBSCRIBERS07,
          ),
          onClick: wixFormsUtils.onClick(
            editorAPI,
            wixFormsConstants.FormPreset.SUBSCRIBERS07,
          ),
          structure: wixFormsUtils.getFormWidget({
            layout: {
              width: 544,
              height: 189,
              x: 185,
              y: 15,
              scale: 1.0,
              rotationInDegrees: 0.0,
              fixedPosition: false,
            },
            controllerType: wixFormsConstants.CONTROLLER_TYPES.GET_SUBSCRIBERS,
          }),
          preset: {
            rect: { width: 324, height: 61, x: 0, y: 0 },
            tags: null,
          },
        },
        {
          id: 'Subscribers_Forms_2',
          onDrop: wixFormsUtils.onDrop(
            editorAPI,
            wixFormsConstants.FormPreset.SUBSCRIBERS08,
          ),
          onClick: wixFormsUtils.onClick(
            editorAPI,
            wixFormsConstants.FormPreset.SUBSCRIBERS08,
          ),
          structure: wixFormsUtils.getFormWidget({
            layout: {
              width: 593,
              height: 216,
              x: 185,
              y: 15,
              scale: 1.0,
              rotationInDegrees: 0.0,
              fixedPosition: false,
            },
            controllerType: wixFormsConstants.CONTROLLER_TYPES.GET_SUBSCRIBERS,
          }),
          preset: {
            rect: { width: 324, height: 65, x: 0, y: 61 },
            tags: null,
          },
        },
        {
          id: 'Subscribers_Forms_3',
          onDrop: wixFormsUtils.onDrop(
            editorAPI,
            wixFormsConstants.FormPreset.SUBSCRIBERS09,
          ),
          onClick: wixFormsUtils.onClick(
            editorAPI,
            wixFormsConstants.FormPreset.SUBSCRIBERS09,
          ),
          structure: wixFormsUtils.getFormWidget({
            layout: {
              width: 634,
              height: 164,
              x: 185,
              y: 15,
              scale: 1.0,
              rotationInDegrees: 0.0,
              fixedPosition: false,
            },
            controllerType: wixFormsConstants.CONTROLLER_TYPES.GET_SUBSCRIBERS,
          }),
          preset: {
            rect: { width: 324, height: 57, x: 0, y: 61 + 65 },
            tags: null,
          },
        },
        {
          id: 'Subscribers_Forms_4',
          onDrop: wixFormsUtils.onDrop(
            editorAPI,
            wixFormsConstants.FormPreset.SUBSCRIBERS10,
          ),
          onClick: wixFormsUtils.onClick(
            editorAPI,
            wixFormsConstants.FormPreset.SUBSCRIBERS10,
          ),
          structure: wixFormsUtils.getFormWidget({
            layout: {
              width: 785,
              height: 288,
              x: 185,
              y: 15,
              scale: 1.0,
              rotationInDegrees: 0.0,
              fixedPosition: false,
            },
            controllerType: wixFormsConstants.CONTROLLER_TYPES.GET_SUBSCRIBERS,
          }),
          preset: {
            rect: { width: 324, height: 90, x: 0, y: 61 + 65 + 57 },
            tags: null,
          },
        },
        {
          id: 'Subscribers_Forms_5',
          onDrop: wixFormsUtils.onDrop(
            editorAPI,
            wixFormsConstants.FormPreset.SUBSCRIBERS11,
          ),
          onClick: wixFormsUtils.onClick(
            editorAPI,
            wixFormsConstants.FormPreset.SUBSCRIBERS11,
          ),
          structure: wixFormsUtils.getFormWidget({
            layout: {
              width: 806,
              height: 229,
              x: 185,
              y: 15,
              scale: 1.0,
              rotationInDegrees: 0.0,
              fixedPosition: false,
            },
            controllerType: wixFormsConstants.CONTROLLER_TYPES.GET_SUBSCRIBERS,
          }),
          preset: {
            rect: { width: 324, height: 79, x: 0, y: 61 + 65 + 57 + 90 },
            tags: null,
          },
        },
      ],
      compTypes: [wixFormsConstants.componentType],
    },
    help: {
      hide: false,
      text: helpText,
    },
  },
});

export default (editorAPI) => getSubscribersSection(editorAPI);
