// @ts-nocheck
import addPanelDataConsts from '@/addPanelDataConsts';
import * as wixStoreEditorActionsService from '../../wixStoreEditorActionsService';
import * as utils from '@wix/santa-editor-utils';

export default {
  type: addPanelDataConsts.SECTIONS_TYPES.PRESET,
  id: 'wixStoresShoppingBagWidget',
  hide: false,
  title: 'add_section_label_shoppingcart',
  subNavigationTitle: 'add_section_label_shoppingcart',
  presetTitle: 'add_section_label_shoppingcart',
  tooltipTitle: 'add_section_label_shoppingcart',
  sectionName: 'shoppingBagWidget',
  subNavigationHide: false,
  showSectionHeader: true,
  additionalBehaviours: {
    labelMode: addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
    infoIcon: addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
    hoverImageAction: addPanelDataConsts.HOVER_IMAGE_ACTIONS.NONE,
    scaleProportion: addPanelDataConsts.DEFAULT_SCALE_FOR_PRESET,
    iconEnabledForComps: [],
  },
  props: {
    image: utils.isOnWixStudio()
      ? 'wixStore/sections/studio/shoppingCart.png'
      : 'wixStore/sections/shoppingBag.png',
    imageHover: null,
    onDrop: wixStoreEditorActionsService.onWidgetDrop(),
    onClick: wixStoreEditorActionsService.onWidgetClick(),
    items: [
      {
        id: 'Wix_Store_Shopping_Bag_1',
        structure: {
          skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
          layout: {
            width: 28,
            height: 34,
            x: 20,
            y: 20,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          componentType: 'wysiwyg.viewer.components.tpapps.TPAWidget',
          data: {
            type: 'TPAWidget',
            widgetId: '1380bbc4-1485-9d44-4616-92e36b1ead6b',
          },
          style: {
            type: 'TopLevelStyle',
            id: 'tpaw0',
            style: { groups: {}, properties: {}, propertiesSource: {} },
            componentClassName: '',
            pageId: '',
            compId: '',
            styleType: 'system',
            skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
          },
        },
        preset: { rect: { width: 51, height: 58, x: 0, y: 0 }, label: '' },
      },
    ],
    compTypes: ['wysiwyg.viewer.components.tpapps.TPAWidget'],
  },
};
