:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._panel_l7miw_7 {
  position: relative;
  height: 100%;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.12), 0 8px 10px 0 rgba(0, 0, 0, 0.08), 0 5px 20px 0 rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  background: #fff; }
  .opened-panels ._panel_l7miw_7 {
    display: none; }
  ._panel_l7miw_7._withHeaderPanel_l7miw_16 {
    border-top: 1px solid #dfe5eb; }

._header_l7miw_19 {
  background: #fff;
  padding: 24px 15px 24px 24px;
  display: flex;
  flex: 0 0 auto;
  border-bottom: 1px solid #f7f8f8; }
  ._header_l7miw_19 .control-label {
    color: #000624; }
  ._header_l7miw_19 ._headerTitle_l7miw_27 {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    color: #000624; }
  ._header_l7miw_19 ._headerClose_l7miw_32 {
    width: 24px;
    height: 24px;
    background-color: transparent;
    color: #116dff; }
    ._header_l7miw_19 ._headerClose_l7miw_32:hover {
      color: #5999ff; }
    ._header_l7miw_19 ._headerClose_l7miw_32.control-icon-button .symbol path {
      fill: currentColor; }
