// @ts-nocheck
import * as util from '@/util';
import { translate } from '@/i18n';
import PropTypes from 'prop-types';
import constants from '@/constants';
import * as stateManagement from '@/stateManagement';
import * as coreBi from '@/coreBi';
import React from 'react';
import * as baseUI from '@/baseUI';
import * as symbols from '@wix/santa-editor-symbols';
import { CustomScroll, TextLabel } from '@wix/wix-base-ui';

const { USER_SET_MOBILE_LANDING_PAGE } = constants.USER_PREFS.MOBILE_EDITOR;

//TYPE WAS GENERATED, remove this line when reviewed
interface TabMobileLayoutProps {
  sendBi: FunctionFixMe;
  pageData: AnyFixMe;
  addHistory: FunctionFixMe;
  updatePageData: FunctionFixMe;
  openHelpCenter: FunctionFixMe;
  setSiteUserPreferences: FunctionFixMe;
}

class TabMobileLayout extends React.Component<TabMobileLayoutProps> {
  static displayName = 'tabMobileLayout';

  static propTypes = {
    sendBi: PropTypes.func.isRequired,
    pageData: PropTypes.object.isRequired,
    addHistory: PropTypes.func.isRequired,
    updatePageData: PropTypes.func.isRequired,
    openHelpCenter: PropTypes.func.isRequired,
    setSiteUserPreferences: PropTypes.func.isRequired,
  };

  linkMobileLandingPage = () => {
    const self = this;
    const pageId = this.props.pageData.id;
    return {
      value: this.props.pageData.isMobileLandingPage,
      requestChange(newValue) {
        self.props.updatePageData(pageId, {
          isMobileLandingPage: newValue,
        });
        self.props.addHistory('page property change');
        self.props.sendBi(
          coreBi.events.pages.pagesPanel.layout_toggle_landing,
          {
            category: newValue ? 'no_header_and_footer' : 'standard',
          },
        );
        self.props.setSiteUserPreferences(
          `${USER_SET_MOBILE_LANDING_PAGE}_${pageId}`,
          true,
        );
      },
    };
  };

  openLearnMore = (e) => {
    e.stopPropagation();
    this.props.openHelpCenter('daaeb4e0-d9e8-4e5a-9894-b5ade0f8bd1a', null, {
      panel_name: 'tab_mobile_layout',
      origin: constants.BI.HELP.ORIGIN.PAGES,
      learn_more: true,
    });
  };

  render() {
    return (
      <CustomScroll>
        <div className="layout tab-inner layout-mobile">
          <baseUI.tabs.holder
            valueLink={this.linkMobileLandingPage()}
            className="left radio mobile-layout-content-wrapper"
          >
            <baseUI.tabs.header>
              <baseUI.tabs.label htmlFor={false}>
                <symbols.symbol name="layoutStandardMobileSelected" />
                <symbols.symbol name="layoutStandardMobileNotSelected" />
                <div>
                  <h3>{translate('Mobile_Pages_Layouts_Standard_Title')}</h3>

                  <p>{translate('Mobile_Pages_Layouts_Standard_Text')}</p>
                </div>
              </baseUI.tabs.label>

              <baseUI.tabs.label htmlFor={true}>
                <symbols.symbol name="layoutLandingMobileSelected" />
                <symbols.symbol name="layoutLandingMobileNotSelected" />
                <div>
                  <h3>{translate('Mobile_Pages_Layouts_Landing_Title')}</h3>

                  <p>
                    {translate('Mobile_Pages_Layouts_Landing_Text')}
                    <br />
                    <span onClick={this.openLearnMore}>
                      {translate('Mobile_Pages_Layouts_Landing_Learn')}
                    </span>
                  </p>
                </div>
              </baseUI.tabs.label>
            </baseUI.tabs.header>
          </baseUI.tabs.holder>
          <div className="tab-mobile-layout-footer">
            <TextLabel
              value="Mobile_Pages_Settings_Info_Text"
              ellipsisProps={{ disabled: true }}
              type="T15"
              className="tab-mobile-layout-footer-text"
            />
          </div>
        </div>
      </CustomScroll>
    );
  }
}

const mapStateToProps = ({ editorAPI }, props) => ({
  pageData: props.menuItem.pageData,
  updatePageData: editorAPI.pages.data.update,
  openHelpCenter: editorAPI.panelManager.openHelpCenter,
  addHistory: editorAPI.history.add,
  sendBi: editorAPI.bi.event,
});

const mapDispatchToProps = (dispatch) => ({
  setSiteUserPreferences: (key, value) =>
    dispatch(
      stateManagement.userPreferences.actions.setSiteUserPreferences(
        key,
        value,
      ),
    ),
});

const Connected = util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(TabMobileLayout);

export default Connected;
