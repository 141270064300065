import { constants as textControlsConstants } from '@/textControls';
import { createThemedAction } from '../utils/themedAction';
import type { ThemedActionCreationData } from '../utils/textAction';
import { colorUtils } from '@/textControls';

const foreColorActionCreationData: Readonly<
  ThemedActionCreationData<string | undefined>
> = {
  execCommand: (textManager, foreColor) => {
    if (foreColor === undefined) {
      textManager.commands.foreColor(textControlsConstants.CK_OFF);
    } else {
      textManager.commands.foreColor(foreColor);
    }
  },
  getWixRichTextCommandState: (textManager) => {
    const foreColor = textManager.getCommandsStates().foreColor;
    return foreColor === '' ? undefined : foreColor;
  },
  execRevertCommand: (textManager) =>
    textManager.commands.foreColor(textControlsConstants.CK_OFF),
  createUpdatedStyleProperties: (foreColor) => ({ color: foreColor }),
  getPropertyValue: (styleProperties) => styleProperties.color,
  getThemeValue: (theme) => colorUtils.trimThemeColorValue(theme?.color),
};

export const foreColorAction = createThemedAction(foreColorActionCreationData);
