._empty-state_18db5_1 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 18px; }
  ._empty-state_18db5_1 div {
    white-space: inherit !important; }

._selected-row_18db5_9 {
  scroll-margin-top: 10px; }
