//eslint-disable-next-line @wix/santa-editor/scoped-imports
import {
  type GroupsWelcomeDispatchProps,
  mapDispatchToGroupsWelcomeSectionProps,
} from '@/addPanelData/mappers';
import { wrapWithPropsTransformer } from '../utils';

export interface GroupsWelcomeProps {
  groupsMainAction: () => void;
}

export const groupsWelcomeMapper = wrapWithPropsTransformer<
  GroupsWelcomeDispatchProps,
  GroupsWelcomeProps
>(mapDispatchToGroupsWelcomeSectionProps, (props) => ({
  groupsMainAction: props.installGroupsApp,
}));
