import * as closeButtonsActions from './actions/closeButtonsActions';
import * as openBehaviorsActions from './actions/openBehaviorsActions';
import getDesktopPanelProps from './mappers/getDesktopPanelProps';
import type { PopupDesktopPanelProps } from './mappers/getDesktopPanelProps';
import getMobilePanelProps from './mappers/getMobilePanelProps';
import { getPageSelectorPanelProps } from './mappers/getPageSelectorPanelProps';

const actions = {
  closeButtons: closeButtonsActions,
  openBehaviors: openBehaviorsActions,
};

const mappers = {
  desktopPanel: getDesktopPanelProps,
  mobilePanel: getMobilePanelProps,
  pagesSelectorPanel: getPageSelectorPanelProps,
};

export { actions, mappers, type PopupDesktopPanelProps };
