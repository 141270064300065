import React, { useCallback, useMemo } from 'react';
import { SortByDragListItemRow, InfoIcon } from '@wix/wix-base-ui';
import { cx, isMainMenuFlowEnabled } from '@/util';
import { translate } from '@/i18n';
import { symbol as Symbol } from '@wix/santa-editor-symbols';

import { isMainMenu as checkIsMainMenu } from '../../../utils/utils';

import { getMenuActions } from './getMenuActions';
import { TRANSLATIONS_MAP } from '../utils/translations';

import type { MenuData } from 'types/documentServices';
import { AUTOMATION_IDS } from '../utils/automationIds';

const isValid = (value: string) => value.trim().length > 0;

const validators = [
  {
    validator: isValid,
    invalidMessage: TRANSLATIONS_MAP.ITEM.tooShort,
  },
];

const menuItemCommonProps = {
  draggable: false,
  withDragHandle: false,
  labelValidators: validators,
  submitEditLabelButtonText: translate(TRANSLATIONS_MAP.ITEM.renameButton),
  labelPlaceholder: translate(TRANSLATIONS_MAP.ITEM.inputPlaceholder),
  shouldTranslate: false,
  automationId: AUTOMATION_IDS.ITEM.SELF,
};

interface IMainMenuHelpIcon {
  onLinkClick: () => void;
}

const MainMenuHelpIcon: React.FC<IMainMenuHelpIcon> = ({ onLinkClick }) => (
  <InfoIcon
    text={TRANSLATIONS_MAP.MAIN_MENU.TOOLTIP.text}
    linkText={TRANSLATIONS_MAP.MAIN_MENU.TOOLTIP.linkTitle}
    onLinkClick={onLinkClick}
    customSymbol={<Symbol name="mainMenuFlag" />}
    automationId={AUTOMATION_IDS.ITEM.INFO_ICON}
  />
);

enum EDIT_MODES {
  NONE = 'NONE',
  EDIT_LABEL = 'EDIT_LABEL',
}

type MenuId = string;

interface IMenuItemProps {
  menu: MenuData;
  isSelected: boolean;
  isEditing: boolean;
  allowDelete: boolean;
  onEdit(id: MenuId): void;
  onDuplicate(menuId: MenuId, expectedTitle: string): void;
  onDelete(id: MenuId): void;
  onSelect(id: MenuId): void;
  onRename(id: MenuId, name: string): void;
  onContextMenuOpen(menu: MenuData): void;
  onMainMenuHelpClick(): void;
}

export const MenuItem: React.FC<IMenuItemProps> = (props) => {
  const {
    menu,
    isEditing,
    isSelected,
    allowDelete,
    onDuplicate,
    onEdit,
    onDelete,
    onSelect,
    onRename,
    onContextMenuOpen,
    onMainMenuHelpClick,
  } = props;

  const label = menu.name;
  const isMainMenu = useMemo(() => checkIsMainMenu(menu), [menu]);

  const editMode = isEditing ? EDIT_MODES.EDIT_LABEL : EDIT_MODES.NONE;

  const contentAfter = useMemo(
    () => {
      return isMainMenuFlowEnabled() && isMainMenu ? (
        <MainMenuHelpIcon onLinkClick={onMainMenuHelpClick} />
      ) : undefined;
    }, // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isMainMenu],
  );

  const classNames = cx('menu-sets-panel__item', {
    'menu-sets-panel__item_main-menu': isMainMenu,
  });

  const menuActions = getMenuActions({
    menu,
    onDuplicate,
    onEdit,
    onDelete,
    allowDelete,
  });

  const handleContextMenuToggle = useCallback(
    (isOpen: boolean) => {
      if (isOpen) {
        onContextMenuOpen(menu);
      }
    },
    [onContextMenuOpen, menu],
  );

  const handleSelect = useCallback(
    () => {
      onSelect(menu.id);
    }, // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [menu],
  );

  const handleRename = useCallback(
    ({ label }: { label: string }) => {
      onRename(menu.id, label);
    },
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [menu],
  );

  return (
    <SortByDragListItemRow
      value={{
        label,
      }}
      menuActions={menuActions}
      select={handleSelect}
      isSelected={isSelected}
      className={classNames}
      editMode={editMode}
      onContextMenuToggle={handleContextMenuToggle}
      onChange={handleRename}
      contentAfter={contentAfter}
      {...menuItemCommonProps}
    />
  );
};
