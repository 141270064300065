import _ from 'lodash';
import popupOverlay from './popupOverlay/popupOverlay';
import {
  editorDataFunction,
  componentInstanceData,
} from '@wix/editor-component-data-resolver';

const pageMetaData = {
  pinnable: false,
  selectable: editorDataFunction(
    componentInstanceData('isWidgetPage'),
    _.identity,
  ),
  allowConnectToDB: true,
  skipInQuickEditPanel: true,
};

export default Object.assign(pageMetaData, popupOverlay);
