import _ from 'lodash';
import type { EditorAPI } from '@/editorAPI';
import constants from '@/constants';
const { ACTIONS } = constants.ROOT_COMPS.GFPP;
import type { GFPPData } from '@wix/editor-component-types';
import type * as DS from '@wix/document-services-types';
import experiment from 'experiment';
import * as util from '@/util';
import { DataBindingApiKey } from '@/apis';

function isReferredDatasetComponent(editorAPI: EditorAPI, compRef: DS.CompRef) {
  const dataBindingApi = editorAPI.host.getAPI(DataBindingApiKey);

  return (
    editorAPI.components.refComponents.isReferredComponent(compRef) &&
    dataBindingApi.isDatasetController(compRef)
  );
}

export default function (
  editorAPI: EditorAPI,
  compRef: DS.CompRef | DS.CompRef[],
  compGfppData: GFPPData,
) {
  if (experiment.isOpen('se_enableDatabindingForAppStudio')) {
    const compRefs = util.array.asArray(compRef);

    if (
      compRefs.some((compRef) => isReferredDatasetComponent(editorAPI, compRef))
    ) {
      _.remove(compGfppData.mainActions, { id: ACTIONS.SETTINGS });
    }
  }
}
