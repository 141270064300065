'use strict'

const addPanelDataConsts = require('@/addPanelDataConsts')

//TODO get rid of it when AddPanel Test Driver will be able to find not only component-items
const structure = {
    componentType: 'wysiwyg.viewer.components.HtmlComponent'
}

const items = [{
    id: 'Other_Corvid_Dev_Mode',
    title: 'add_section_heading_corvid_corvid_dev_mode_title',
    desc: 'add_section_heading_corvid_corvid_dev_mode_description',
    image: 'addPanelData/sections/veloDevModeSection/veloDevMode.png',
    symbol: 'arrowWithStates',
    tooltip: 'add_section_heading_corvid_corvid_dev_mode_hover_tooltip',
    action: addPanelDataConsts.CLICK_ACTION.TOGGLE_CORVID_DEV_MODE,
    toggledState: {
        tooltip: 'add_section_heading_corvid_corvid_dev_mode_hover_tooltip_status',
        symbol: 'inputValidationSuccess'
    },
    structure
}]

module.exports = {
    type: addPanelDataConsts.SECTIONS_TYPES.TOGGLES_LIST,
    hide: false,
    title: 'add_section_heading_corvid',
    presetTitle: 'add_section_heading_corvid',
    subNavigationTitle: 'add_section_subnav_label_corvid_dev_mode',
    automationId: 'add-panel-section-corvidDevModeSection',
    subNavigationHide: false,
    showSectionHeader: true,
    props: {
        items
    }
}
