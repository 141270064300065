._container_63jor_1 {
  display: flex;
  align-items: center; }

._illustration_63jor_5 {
  flex: none;
  display: inline-flex; }

._description_63jor_9 {
  margin: 0 18px;
  min-width: 0; }

._action_63jor_13 {
  flex: none;
  margin-left: auto;
  min-width: 0; }
