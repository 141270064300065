import type { EditorAPI } from '@/editorAPI';
import type { FlattenedObject } from './types';
import constants from '@/constants';

import { HttpClient } from '@wix/http-client';
import {
  recommendTemplateInjectionImage,
  suggestPhotos,
} from '@wix/ambassador-genie-v1-image-recommendation/http';
import type { CompRef, CompData } from '@wix/document-services-types';
import { ImageTypes } from './constants';

interface ImageData {
  ref: CompRef;
  data?: CompData;
  parentData?: CompData;
  newUrl?: string;
  origin?: string;
}

interface ImageRecommendation {
  id?: string;
  source?: SuggestPhotosRequestSource;
  url?: string | null;
  width?: number;
  height?: number;
  score?: number;
}

export enum SuggestPhotosRequestSource {
  UNKNOWN = 'UNKNOWN',
  PUBLIC_MEDIA = 'PUBLIC_MEDIA',
  UNSPLASH = 'UNSPLASH',
  CAAS = 'CAAS',
}

export const flattenObject = (obj: any): FlattenedObject => {
  const flattened: FlattenedObject = {};

  const flattenRecursive = (cur: any, prop = ''): void => {
    if (typeof cur === 'object' && cur !== null) {
      Object.entries(cur).forEach(([key, value]) => {
        flattenRecursive(value, key);
      });
    } else {
      flattened[prop] = cur;
    }
  };

  flattenRecursive(obj);

  return flattened;
};

const MAX_IMAGE_WIDTH = 200;
const MAX_IMAGE_HEIGHT = 200;
const IMAGE_TYPE = 'Image';

const composeImageUrl = (uri: string): string =>
  uri.includes('http') ? uri : `https://static.wixstatic.com/media/${uri}`;

const getImageToReplace = async (
  httpClient: HttpClient,
  image: ImageData,
): Promise<ImageData> => {
  const { data, parentData } = image;
  const result = await httpClient.request(
    recommendTemplateInjectionImage({
      imageUrl: composeImageUrl(data.uri),
      imageProperties: {
        width: data.width.toString(),
        height: data.height.toString(),
        sectionName: parentData.sectionName,
        description: data.title,
        alt: data.alt,
      },
    }),
  );

  return {
    ...image,
    newUrl: result.data.image.url,
  };
};

const getSuggestedPhotosInSection = async (
  httpClient: HttpClient,
  query: string,
  limit: number,
): Promise<AnyFixMe> => {
  let suggestedPhotos: any = [];
  const requestCount = Math.ceil(limit / 5);
  let requestLimit = limit;

  for (let i = 0; i < requestCount; i++) {
    const newImages = await httpClient.request(
      suggestPhotos({
        query,
        limit: requestLimit > 5 ? 5 : requestLimit,
        source: SuggestPhotosRequestSource.PUBLIC_MEDIA,
      }),
    );
    suggestedPhotos = [...suggestedPhotos, newImages];
    requestLimit -= 5;
  }
  return await suggestedPhotos.flatMap(
    (response: AnyFixMe) => response.data.images,
  );
};

const isImageReplacable = (image: { data: Record<string, any> }): boolean =>
  image.data.width >= MAX_IMAGE_WIDTH && image.data.height >= MAX_IMAGE_HEIGHT;

const isRelevantImageSize = (
  imageRef: CompRef,
  editorAPI: EditorAPI,
): boolean => {
  const { width, height } = editorAPI.components.layout.get_size(imageRef);
  return width >= 200 && height >= 100;
};

const populateImagesWithParentData = (
  editorAPI: EditorAPI,
  images: AnyFixMe[],
): AnyFixMe[] => {
  return images.map((image) => {
    const parentSection = editorAPI.sections.getClosestSection(image.ref);
    const page = editorAPI.pages.getPageTitle(
      editorAPI.pages.getCurrentPageId(),
    );
    const parentName = parentSection
      ? `${editorAPI.sections.getName(parentSection)} section`
      : `${page} page`;

    return {
      ...image,
      parentData: {
        parentName,
      },
    };
  });
};

export const replaceImagesWithFunnelSuggestions = async (
  editorAPI: EditorAPI,
): Promise<void> => {
  const imageToReplace: ImageData[] = editorAPI.components.get
    .byType(constants.COMP_TYPES.PHOTO)
    .map((imageRef) => {
      const data = editorAPI.components.data.get(imageRef);
      return {
        ref: imageRef,
        data,
      };
    })
    .filter(isImageReplacable);

  if (imageToReplace.length === 0) return;

  const imagesWithParentData: ImageData[] = populateImagesWithParentData(
    editorAPI,
    imageToReplace,
  );

  const httpClient = new HttpClient({
    getAppToken: () =>
      editorAPI.documentServices.platform.getAppDataByApplicationId('-666')
        ?.instance,
  });

  const populatedImagesData = await Promise.all(
    imagesWithParentData.map((image) => getImageToReplace(httpClient, image)),
  );

  populatedImagesData.forEach(({ ref, newUrl }) => {
    editorAPI.components.data.update(ref, {
      uri: newUrl,
    });
  });

  return;
};

const updateImageInTemplates = async (
  editorAPI: EditorAPI,
  imageList: ImageData[],
  newImageList: ImageRecommendation[],
) => {
  await Promise.all(
    imageList.map(async (image: ImageData, index: number) => {
      const newImageData = newImageList[index];

      switch (image.origin) {
        case ImageTypes.Image:
          await editorAPI.components.data.update(image.ref, {
            uri: newImageData.url,
          });

          break;

        case ImageTypes.PageBackground:
          const newPageBackgroundData = image.data;
          const newPageBackgroundDataUri = newImageData.url.split('/').pop();
          const pageBackgroundMediaRef = newPageBackgroundData.ref.mediaRef;

          if (pageBackgroundMediaRef.type === constants.MEDIA_TYPES.IMAGE) {
            pageBackgroundMediaRef.uri = newPageBackgroundDataUri;
          } else {
            pageBackgroundMediaRef.posterImageRef.uri =
              newPageBackgroundDataUri;
          }

          await editorAPI.pages.background.update(
            image.data.ref.metaData.pageId,
            newPageBackgroundData,
            'desktop',
          );

          break;

        case ImageTypes.StripBackground:
        case ImageTypes.SectionBackground:
          const newBackgroundData = image.data;
          const newBackgroundDataUri = newImageData.url.split('/').pop();
          const backgroundMediaRef = newBackgroundData.background.mediaRef;

          backgroundMediaRef.uri = newBackgroundDataUri;

          await editorAPI.components.design.update(
            image.ref,
            newBackgroundData,
            false,
          );

          break;

        default:
          throw new Error('Unknown strategy');
      }
    }),
  );
};

const getImagesToReplace = (editorAPI: EditorAPI) => {
  const imageToReplace: ImageData[] = editorAPI.components.get
    .byType(constants.COMP_TYPES.PHOTO)
    .filter((compRef) => isRelevantImageSize(compRef, editorAPI))
    .map((compRef) => {
      const data = editorAPI.components.data.get(compRef);
      return {
        ref: compRef,
        data,
        origin: ImageTypes.Image,
      };
    });

  const pageBackgroundsToReplace = editorAPI.pages
    .getPagesData()
    .map((page) => editorAPI.dsRead.pages.background.get(page.id, 'desktop'))
    .filter((compData) => compData.ref?.mediaRef)
    .map((compData) => {
      return {
        data: compData,
        ref: { id: compData.ref.id, type: compData.ref.type },
        origin: ImageTypes.PageBackground,
      };
    });

  const getStripBackgrounds = () => {
    const stripBackgroundsToReplace: {
      data: CompData;
      ref: CompRef;
      origin: string;
    }[] = [];

    editorAPI.components.get
      .byType('wysiwyg.viewer.components.StripColumnsContainer')
      .map((compRef) => editorAPI.components.getChildren(compRef))
      .map((compRef) => {
        return compRef.map((comp) => {
          return {
            compData: editorAPI.components.design.get(comp),
            parentRef: comp,
          };
        });
      })
      .map((comps) =>
        comps.forEach(({ compData, parentRef }) => {
          if (compData.background?.mediaRef?.type !== IMAGE_TYPE) return;
          stripBackgroundsToReplace.push({
            data: compData,
            ref: parentRef,
            origin: ImageTypes.StripBackground,
          });
        }),
      );

    return stripBackgroundsToReplace;
  };
  const sectionBackgrounds = editorAPI.sections
    .getAllSections()
    .map((section) => {
      return {
        data: editorAPI.components.design.get(section),
        parentRef: section,
        origin: ImageTypes.SectionBackground,
      };
    })
    .filter(
      (compData) => compData.data?.background?.mediaRef?.type === IMAGE_TYPE,
    );

  const allImages = [
    imageToReplace,
    pageBackgroundsToReplace,
    getStripBackgrounds(),
    sectionBackgrounds,
  ].flat();

  return allImages;
};

export const replaceImagesInTemplates = async (
  editorAPI: EditorAPI,
): Promise<void> => {
  const imagesToReplace: AnyFixMe[] = getImagesToReplace(editorAPI);
  if (imagesToReplace.length === 0) return;

  const imagesWithParentData: AnyFixMe[] = populateImagesWithParentData(
    editorAPI,
    imagesToReplace,
  );

  const httpClient = new HttpClient({
    getAppToken: () =>
      editorAPI.documentServices.platform.getAppDataByApplicationId('-666')
        ?.instance,
  });

  const listOfImagesByType: AnyFixMe = {};

  imagesWithParentData.forEach((image) => {
    const parentName = image.parentData.parentName;
    if (listOfImagesByType[parentName]) {
      listOfImagesByType[parentName] = [
        ...listOfImagesByType[parentName],
        image,
      ];
    } else {
      listOfImagesByType[parentName] = [image];
    }
  });

  await Promise.all(
    Object.keys(listOfImagesByType).map(async (parentName) => {
      const query = `image for the ${parentName}`;
      const limit = listOfImagesByType[parentName].length;
      const newImages = await Promise.all(
        await getSuggestedPhotosInSection(httpClient, query, limit),
      );
      await updateImageInTemplates(
        editorAPI,
        listOfImagesByType[parentName],
        newImages,
      );
    }),
  );

  return null;
};
