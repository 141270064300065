//@ts-nocheck
import { array } from '@/util';
import wixFormsConstants from '../../contact/wixForms/constants';
import * as wixFormsUtils from '../../contact/wixForms/utils';

const { immutableSplice } = array;

const addForm = (structure) => {
  const form = wixFormsUtils.getFormWidget({
    layout: {
      width: 500,
      height: 102,
      x: 68,
      y: 259,
      scale: 1,
      rotationInDegrees: 0,
      anchors: [
        {
          distance: 52,
          originalValue: 382,
          type: 'BOTTOM_TOP',
          locked: true,
          targetComponent: 'comp-imer9nyg',
        },
      ],
      fixedPosition: false,
    },
    id: 'comp-im0mde4p',
    controllerType: wixFormsConstants.CONTROLLER_TYPES.GET_SUBSCRIBERS,
  });

  // add form to
  // components: [
  //     component_0: {
  //         components: [
  //             component_00: {
  //                components: [
  //                    form,
  //                    ...
  //                ]
  //            },
  //         ]
  //     },
  // ]
  return {
    ...structure,
    components: immutableSplice(structure.components, 0, 1, {
      ...structure.components[0],
      components: immutableSplice(structure.components[0].components, 0, 1, {
        ...structure.components[0].components[0],
        components: immutableSplice(
          structure.components[0].components[0].components,
          0,
          0,
          form,
        ),
      }),
    }),
  };
};

export default (onDrop, onClick) => (item) => {
  onDrop = onDrop(wixFormsConstants.FormPreset.LIGHTBOX_PROMOTION01);
  onClick = onClick(wixFormsConstants.FormPreset.LIGHTBOX_PROMOTION01);

  return {
    ...item,
    onDrop,
    onClick,
    structure: addForm(item.structure),
  };
};
