// @ts-nocheck
import constants from '@/constants';
import * as helpIds from '@/helpIds';
import gfppDataUtils from '../../utils/gfppDataUtils';
import type { EditorAPI } from '@/editorAPI';
import type { CompRef } from '@wix/document-services-types';

const { ACTIONS } = constants.ROOT_COMPS.GFPP;

const manageColumnsPanelName = 'compPanels.panels.Grid.columnManagerPanel';

const manageColumns = {
  label: 'GFPP_MAIN_ACTION_MANAGE_TABLE',
  isSelected: gfppDataUtils.getPanelStateFn(manageColumnsPanelName),
  onClick(editorAPI: EditorAPI, compRef: CompRef, origin: string) {
    gfppDataUtils.toggleComponentPanel(editorAPI, manageColumnsPanelName, {
      selectedComponent: compRef,
      origin,
    });
  },
};

export default {
  untranslatable: true,
  mainActions: [manageColumns],
  enabledActions: [
    ACTIONS.SETTINGS,
    ACTIONS.LAYOUT,
    ACTIONS.DESIGN,
    ACTIONS.HELP,
  ],
  mobileMainActions: [manageColumns],
  mobileEnabledActions: [ACTIONS.LAYOUT, ACTIONS.HIDE, ACTIONS.HELP],
  presetActions: {
    help: {
      helpId: helpIds.GFPP.GRID,
      mobileHelpId: helpIds.GFPP.GRID_MOBILE,
    },
  },
};
