'use strict'

const addPanelDataConsts = require('@/addPanelDataConsts')
const allHeaders = require('./headerFooter/allHeaders')

module.exports = {
    'type': addPanelDataConsts.SECTIONS_TYPES.PRESET,
    'hide': false,
    'title': 'design_section_label_explore_header',
    'showSectionHeader': true,
    'additionalBehaviours': {
        'labelMode': addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
        'infoIcon': addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
        'hoverImageAction': addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
        'scaleProportion': addPanelDataConsts.DEFAULT_SCALE_FOR_PRESET,
        'iconEnabledForComps': []
    },
    'props': {
        'compTypes': ['wysiwyg.viewer.components.HeaderContainer'],
        'image': 'addPanelData/sections/headerStyleSection_en/headerStyleSection_en.png',
        'items': allHeaders
    }
}
