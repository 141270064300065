import React from 'react';
import { Composites, Preloader, TextLabel } from '@wix/wix-base-ui';
import styles from './searchLoader.scss';

interface SearchLoaderProps {
  label: string;
}

export const SearchLoader: React.FC<SearchLoaderProps> = ({ label }) => (
  <div
    key="preloader"
    data-hook={'media-manager-panel-preloader'}
    className={styles.mediaManagerPanelPreloader}
  >
    <Composites.Preloader>
      <Preloader className="medium" />
      <TextLabel value={label} />
    </Composites.Preloader>
  </div>
);
