import { createAppHost, ConsoleHostLogger, type HostLogger } from '@/apilib';
import experiment from 'experiment';
import { createEditorApiEntryPoint } from '../app/EditorApiEntryPoint';
import { EditorMainViewEntryPoint } from './EditorMainViewEntryPoint';
import { LayoutEntrypoint } from '@/layout';
import {
  MeasureComponentDragAndDropEntryPoint,
  MeasureFrameRateEntrypoint,
} from '@/measuring';
import { BlocksEntryPoint } from '@/blocks';
import { ComponentsEntrypoint } from '@/components';
import { ComponentsStageModesEntrypoint } from '@/componentsStageModes';
import { AscendEntrypoint } from '@/ascend';
import { PromoteEntryPoint } from '@/promote';
import { ClipboardEntrypoint } from '@/clipboard';
import { PagesEntrypoint } from '@/pages';
import { HistoryEntrypoint } from '@/history';
import { MenuEntrypoint, AdvancedMenuEntryPoint } from '@/menu';
import { DeveloperModeEntrypoint } from '@/developerMode';
import { MouseActionsEntrypoint } from '../app/APISections/mouseActionsEntry';
import { ConcurrentUsersEntrypoint } from '@/concurrentUsers';
import { SelectedComponentEntrypoint } from '../app/APISections/selectedComponentEntry';
import { EditorUIEntrypoint } from '@/editorUI';
import { AccountEntrypoint } from '@/account';
import { AddPresetApiEntryPoint } from '@/addPreset';
import { ComponentFocusModeApiEntryPoint } from '@/componentFocusMode';
import { SwitchLayoutEntryPoint } from '@/switchLayout';
import { PreviewerEntryPoint } from '@/previewer';
import { WizardEntryPoint } from '@/wizard';
import { QuickEditEntryPoint } from '@/quickEdit';
import { QuickEditEngineEntryPoint } from '@/quickEditEngine';
import { TextClassificationApiEntryPoint } from '@/textClassification';
import { ListClassificationApiEntryPoint } from '@/listClassification';
import { WorkspaceRightPanelEntryPoint } from '@/workspaceRightPanel';
import { ThemeEntrypoint } from '@/theme';
import { AiEntryPoint } from '@/ai';
import { AiWriterPanelEntryPoint } from '@/aiWriterPanel';
import { BiEntrypoint } from '@/bi';
import { SiteEntrypoint } from '@/site';
import { PlatformEntrypoint } from '@/platform';
import { FeedbackEntrypoint } from '@/feedback';
import { CampaignsApiEntryPoint } from '@/campaigns';
import { SavePublishEntryPoint } from '@/savePublish';
import { PanelManagerApiEntryPoint } from '@/panelManager';
import { PanelHelpersApiEntryPoint } from '@/panelHelpers';
import { MediaServicesEntrypoint } from '@/mediaServices';
import { GTMServiceEntrypoint } from '@/gtmService';
import { ColumnsEntrypoint } from '@/columns';
import { CopyPasteEntrypoint } from '@/copyPaste';
import { PreviewEntrypoint } from '@/preview';
import { SelectionEntrypoint } from '@/selection';
import { PinModeEntrypoint } from '@/pinMode';
import { MobileEditorEntrypoint } from '@/mobileEditor';
import { MaskCropEntrypoint } from '@/maskCrop';
import { ScrollApiEntryPoint } from '../app/APISections/scroll';
import { TextEditorApiEntryPoint } from '@/textEditor';
import { HomePageApiEntryPoint } from '../app/APISections/homePage';
import { MainMenuApiEntryPoint } from '@/mainMenu';
import { BaseDragEntrypoint } from '../utils/mouseMoveActions/baseDrag/baseDragEntry';
import { BaseResizeAndPushEntrypoint } from '../utils/mouseMoveActions/baseResizeAndPushEntry';
import { PromotionsEntrypoint } from '@/promotions';
import { SavedComponentsEntrypoint } from '@/savedComponents';
import { MediaEntrypoint } from '../app/APISections/mediaEntry';
import { A11yApiEntryPoint } from '@/accessibility';
import {
  WixCodeWorkspaceEntrypoint,
  WixCodePackageLoaderEntryPoint,
} from '@/wixCodePackageLoader';
import { SchoolModeApiEntryPoint } from '@/schoolMode';
import { TpaEntrypoint } from '@/tpa';
import { LayoutersEntrypoint } from '../app/APISections/layoutersEntry';
import { FloatingBubbleEntrypoint } from '@/floatingBubble';
import { TabIndicationEntrypoint } from '../app/APISections/tabIndicationStateEntry';
import { PluginServiceEntrypoint } from '../app/pluginServiceEntry';
import { EditorEventRegistryEntrypoint } from '@/editorEventRegistry';
import { SectionizerEntryPoint } from '@/sectionizer';
import {
  SectionsApiEntryPoint,
  ClassicSectionResizeAndPushEntryPoint,
} from '@/sections';
import { SectionsMigrationEntryPoint } from '@/sectionsMigration';
import { WixCodeEntrypoint } from '../app/APISections/wixCode';
import { EditorToursEntryPoint } from '@/editorTours';
import { createEditorCoreEntrypoint } from '@/editorCore';
import { EditorWelcomeScreensEntrypoint } from '@/editorWelcomeScreens';
import { LanguagePickerEntrypoint } from '@/languagePicker';
import {
  type EditorParams,
  createEditorParamsEntryPoint,
} from '@/editorParams';
import { createEditorPermissionsEntryPoint } from '@/editorPermissions';
import { EditorRestrictionsEntryPoint } from '@/editorRestrictions';
import { WorkspaceEntrypoint } from '@/workspace';
import { EditorAPIKey } from '@/apis';
import { JiraIssueCollectorEntryPoint } from '@/jiraIssueCollector';
import { getApisObjectForEditorAPI } from '../app/setApisToEditorAPI';
import * as platformHostIngration from '@wix/editor-platform-host-integration/repluggable';
import { DocumentServicesEntry } from '@/documentServices';
import { EditorPaasApiEntryPoint } from '@/editorPaas';
import { DebugLayoutEntryPoint } from '@/debugLayout';
import { ZoomModeEntryPoint } from '@/zoomMode';
import { CustomAuthenticationEntryPoint } from '@/customAuthentication';
import { SpotlightStageApiEntryPoint } from '@/spotlightStage';
import { ComponentPanelPreloaderEntrypoint } from '@/componentPanelPreloader';
import { SeoAutoRedirectEntryPoint } from '@/seoAutoRedirect';
import { WixDataEntryPoint } from '@/wixData';
import { AppsPanelSectionApiEntryPoint } from '@/appsPanelSections';
import { PrivateAppsEntryPoint } from '@/privateAppsPanel';
import { ContentInjectionEntryPoint } from '@/contentInjection';
import { DataFixersEntryPoint } from '@/dataFixers';
import { SiteGlobalDataEntryPoint } from '@/siteGlobalData';
import { TopBarEntryPoint } from '@/topBar';
import { WorkspaceModesEntryPoint } from '@/workspaceModes';
import { WixStoreEntryPoint } from '@/wixStore';
import { ImageUploadToStageEntryPoint } from '@/imageUploadToStage';
import { ExperimentsEntrypoint } from '@/experiments';
import { SiteWidthMigrationEntryPoint } from '@/siteWidthMigration';
import { FixedStageEntryPoint } from '@/fixedStage';
import { ImageToBackgroundEntryPoint } from '@/imageToBackground';
import { SiteSegmentMediaBackgroundEntryPoint } from '@/siteSegmentMediaBackground';

import type { APILayer, EntryPointOrPackage } from '@/apilib';
import type { EditorPermissions } from '@/editorPermissions';
import type { EditorBaseHooks } from '@/editorCore';
import { DataBindingEntrypoint } from '@/dataBinding';
import { AnimationsEntrypoint } from '@/animations';
import { HighlightResizeEntryPoint } from '@/highlightResize';
import { TabsEntryPoint } from '@/tabs';

const initDesignerPanelsApi = () =>
  import('@/designerPanels').then((m) => m.DesignerPanelsApiEntryPoint);

const layers: APILayer[] = [
  {
    level: 0,
    name: 'REPLUGGABLE',
  },
  {
    level: 10,
    name: 'SITE',
  },
  {
    level: 20,
    name: 'EDITOR_INFRA',
  },
  {
    level: 30,
    name: 'DATA_SERVICE',
  },

  {
    level: 40,
    name: 'DERIVATIVE_STATE',
  },
  {
    level: 50,
    name: 'FLOWS',
  },
  {
    level: 60,
    name: 'UI',
  },
  {
    level: 70,
    name: 'VERTICAL',
  },
  {
    level: 80,
    name: 'EXTERNAL',
  },
];

export function createEditorAppHost({
  editorParams,
  globalServices,
  debugMode,
  editorPermissions,
  editorBaseHooks,
}: {
  editorParams: EditorParams;
  globalServices: unknown;
  debugMode?: boolean;
  editorPermissions: EditorPermissions;
  editorBaseHooks?: EditorBaseHooks;
}) {
  const urlParams = new URLSearchParams(window.location.search);

  const enablePerformance = urlParams.has('enablePerformance');
  const disableMemoization = urlParams.has('disableMemoization');
  const debugMemoization = urlParams.has('debugMemoization');
  const disableLayersValidation = urlParams.has('disableLayersValidation');
  const disableCustomHostLogger = urlParams.has('disableCustomHostLogger');

  const errorBoundaryParam =
    urlParams.get('stickyErrorBoundary') || `${debugMode}`;
  const enableStickyErrorBoundaries =
    errorBoundaryParam.toLowerCase() !== 'false';

  const EditorPermissionsEntryPoint =
    createEditorPermissionsEntryPoint(editorPermissions);
  const EditorParamsEntryPoint = createEditorParamsEntryPoint(editorParams);
  const EditorApiEntryPoint = createEditorApiEntryPoint({
    editorParams,
    globalServices,
  });

  const externalRepluggableDescriptors = [
    platformHostIngration.bundleDescriptor,
  ];

  const entryList: EntryPointOrPackage[] = [
    BlocksEntryPoint,
    DocumentServicesEntry,
    EditorPaasApiEntryPoint,
    LanguagePickerEntrypoint,
    EditorPermissionsEntryPoint,
    WorkspaceEntrypoint,
    EditorRestrictionsEntryPoint,
    EditorParamsEntryPoint,
    EditorApiEntryPoint,
    TopBarEntryPoint,
    EditorMainViewEntryPoint,
    LayoutEntrypoint,
    ComponentsEntrypoint,
    ComponentsStageModesEntrypoint,
    AscendEntrypoint,
    MeasureComponentDragAndDropEntryPoint,
    ClipboardEntrypoint,
    PagesEntrypoint,
    MenuEntrypoint,
    AdvancedMenuEntryPoint,
    MaskCropEntrypoint,
    CustomAuthenticationEntryPoint,
    HistoryEntrypoint,
    MobileEditorEntrypoint,
    PinModeEntrypoint,
    ComponentPanelPreloaderEntrypoint,
    SelectionEntrypoint,
    PreviewEntrypoint,
    CopyPasteEntrypoint,
    ColumnsEntrypoint,
    SavePublishEntryPoint,
    ThemeEntrypoint,
    AccountEntrypoint,
    AddPresetApiEntryPoint,
    ComponentFocusModeApiEntryPoint,
    SwitchLayoutEntryPoint,
    PreviewerEntryPoint,
    QuickEditEntryPoint,
    QuickEditEngineEntryPoint,
    PromoteEntryPoint,
    ImageUploadToStageEntryPoint,
    WorkspaceModesEntryPoint,
    TextClassificationApiEntryPoint,
    ListClassificationApiEntryPoint,
    WizardEntryPoint,
    WorkspaceRightPanelEntryPoint,
    SelectedComponentEntrypoint,
    SiteEntrypoint,
    ConcurrentUsersEntrypoint,
    MouseActionsEntrypoint,
    DeveloperModeEntrypoint,
    createEditorCoreEntrypoint({
      editorBaseHooks,
    }),
    EditorWelcomeScreensEntrypoint,
    AiEntryPoint,
    AiWriterPanelEntryPoint,
    BiEntrypoint,
    TextEditorApiEntryPoint,
    HomePageApiEntryPoint,
    MainMenuApiEntryPoint,
    ScrollApiEntryPoint,
    SpotlightStageApiEntryPoint,
    PanelManagerApiEntryPoint,
    CampaignsApiEntryPoint,
    PanelHelpersApiEntryPoint,
    BaseDragEntrypoint,
    BaseResizeAndPushEntrypoint,
    EditorUIEntrypoint,
    SchoolModeApiEntryPoint,
    FeedbackEntrypoint,
    MediaServicesEntrypoint,
    GTMServiceEntrypoint,
    PlatformEntrypoint,
    PromotionsEntrypoint,
    SavedComponentsEntrypoint,
    MediaEntrypoint,
    TpaEntrypoint,
    LayoutersEntrypoint,
    FloatingBubbleEntrypoint,
    TabIndicationEntrypoint,
    PluginServiceEntrypoint,
    EditorEventRegistryEntrypoint,
    WixCodeEntrypoint,
    WixCodeWorkspaceEntrypoint,
    WixCodePackageLoaderEntryPoint,
    EditorToursEntryPoint,
    SectionsApiEntryPoint,
    SectionizerEntryPoint,
    ClassicSectionResizeAndPushEntryPoint,
    SectionsMigrationEntryPoint,
    A11yApiEntryPoint,
    JiraIssueCollectorEntryPoint,
    ZoomModeEntryPoint,
    DebugLayoutEntryPoint,
    SeoAutoRedirectEntryPoint,
    WixDataEntryPoint,
    AppsPanelSectionApiEntryPoint,
    PrivateAppsEntryPoint,
    ContentInjectionEntryPoint,
    DataFixersEntryPoint,
    SiteGlobalDataEntryPoint,
    ExperimentsEntrypoint,
    WixStoreEntryPoint,
    SiteWidthMigrationEntryPoint,
    FixedStageEntryPoint,
    DataBindingEntrypoint,
    ...externalRepluggableDescriptors.flatMap((descriptor) =>
      Object.values(descriptor.packages),
    ),
    ImageToBackgroundEntryPoint,
    AnimationsEntrypoint,
    HighlightResizeEntryPoint,
    TabsEntryPoint,
    SiteSegmentMediaBackgroundEntryPoint,
  ];

  if (editorParams.shouldMeasureFrameRate) {
    entryList.push(MeasureFrameRateEntrypoint);
  }

  const CustomHostLogger: HostLogger = {
    ...ConsoleHostLogger,
    log: (severity, ...args) => {
      if (severity === 'debug') {
        return;
      }
      return ConsoleHostLogger.log(severity, ...args);
    },
  };

  const logger: HostLogger =
    debugMode && disableCustomHostLogger ? undefined : CustomHostLogger;

  //saving original repluggableAppDebug because editor X repluggable is writing it to window
  //@ts-expect-error
  const { repluggableAppDebug } = window;

  const host = createAppHost(
    entryList.map((entry) => {
      if (typeof entry === 'function') {
        return (entry as any)(); //because createEntrypoint in apilib returns factory
      }
      return entry;
    }),
    {
      logger,
      layers,
      monitoring: {
        // NOTE: this is required, because for monitoring, repluggable clones the initial object, so `host.getAPI(EditorAPI)` !== createEditorApi()
        // on preview ready, DS is merged to the editorApi, so same reference is critical
        // same for all apis
        disableMonitoring: true,
        enablePerformance,
        disableMemoization,
        debugMemoization,
      },
      disableLayersValidation,
      enableStickyErrorBoundaries,
      disableCheckCircularDependencies: !debugMode,
      enableReduxDevtoolsExtension: debugMode,
    },
  );

  //saving our repluggableAppDebug, which was written in createAppHost
  //@ts-expect-error
  const seRepluggableAppDebug = window.repluggableAppDebug;
  //@ts-expect-error
  window.repluggableAppDebug = repluggableAppDebug; //restoring original debug object for responsive (used in e2e tests)
  //saving our repluggableAppDebug in different variable, so we can use it to debug repluggable
  //@ts-expect-error
  window.seRepluggableAppDebug = seRepluggableAppDebug;

  const editorAPI = host.getAPI(EditorAPIKey) as any;
  editorAPI.host = host;

  Object.assign(editorAPI, getApisObjectForEditorAPI(host));

  if (
    experiment.isOpen('se_addDesignerPage') ||
    experiment.isOpen('se_addDesignerSection')
  ) {
    void initDesignerPanelsApi().then((pkg) => {
      host.addShells([pkg()]);
    });
  }

  if (editorParams.isDebug) {
    // NOTE: base initialization of Editor API is finished, so disable `debugNotExistedPropProxy` (see EditorAPI entrypoint for the details)
    editorAPI.__debugNotExistedPropProxyEnabled = false;
  }
  return host;
}
