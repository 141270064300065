import { ErrorReporter } from '@wix/editor-error-reporter';
import { DealerActionType } from '../dealer/dealer.types';
import { tryShow } from './deeplinkHelper';

import type {
  AppSetupStep,
  AppSetupStepStatus,
  AppSetupStepAction,
  SetupStepDealerDataWithSkipFlag,
  SetupCallbacks,
} from './types';

export class AppSetup {
  private readonly _steps: AppSetupStep[];

  constructor(
    stepsData: SetupStepDealerDataWithSkipFlag[],
    callbacks: SetupCallbacks,
  ) {
    const { checkPermissions, openBusinessManager, setSkipped } = callbacks;
    this._steps = stepsData.map((d) => ({
      ...d,
      id: d.metadata.offerId,
      isPermitted: d.permissions
        ? checkPermissions(d.permissions.split(/\s*,\s*/))
        : true,
      status: stepStatus(d),
      action: stepAction(d, openBusinessManager),
      skip: () => setSkipped(d.metadata.offerId, true),
      unskip: () => setSkipped(d.metadata.offerId, false),
      metadata: d.metadata,
    }));
  }

  get totalStepsCount() {
    return this._steps.length;
  }

  get completedStepsCount() {
    return this._steps.filter(
      (step) => step.status === 'completed' || step.status === 'skipped',
    ).length;
  }

  get isComplete() {
    return this.totalStepsCount === this.completedStepsCount;
  }

  get nextStep() {
    return this._steps.find(
      (step) => step.isPermitted && step.status === 'todo',
    );
  }

  get steps() {
    return this._steps;
  }
}

function stepStatus(step: SetupStepDealerDataWithSkipFlag): AppSetupStepStatus {
  if (step.dynamicParams.completedAt) {
    return 'completed';
  }

  if (step.isSkipped) {
    return 'skipped';
  }

  return 'todo';
}

function stepAction(
  step: SetupStepDealerDataWithSkipFlag,
  openBusinessManager: SetupCallbacks['openBusinessManager'],
): AppSetupStepAction {
  let execute: () => void;
  switch (step.mainCta.type) {
    case DealerActionType.BMLink:
      execute = () => {
        openBusinessManager(step.appDefId, step.mainCta.value.value);
      };
      break;
    case DealerActionType.EditorDeepLink:
      execute = () => {
        try {
          tryShow(step.mainCta.value.value);
        } catch (err) {
          ErrorReporter.captureException(err, {
            tags: {
              operation: 'execute-app-setup-step-action',
            },
            extra: {
              dealerOfferId: step.metadata.offerId,
            },
          });
        }
      };

      break;
    default:
      throw new Error(
        `Cannot create setup step action executor; type "${step.mainCta.type}" is invalid`,
      );
  }
  return {
    label: step.mainCta.value.key,
    execute,
  };
}
