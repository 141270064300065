import _ from 'lodash';

import { isSection, PartialMigrationError } from '../../../utils';
import {
  flattenMobileChild,
  hasForbiddenBehavior,
} from './removeRedundantNesting';

import type { DocumentServicesObject, CompRef } from 'types/documentServices';
import type {
  SectionDescription,
  MobileComponent,
  DesktopComponent,
} from '../../types';

function getSectionBackground(
  documentServices: DocumentServicesObject,
  compRef: CompRef,
) {
  const { background } = documentServices.components.design.get(compRef);
  return background;
}

function getSectionBehaviors(
  documentServices: DocumentServicesObject,
  compRef: CompRef,
) {
  return documentServices.components.behaviors.get(compRef) ?? [];
}

function getSectionProperties(
  documentServices: DocumentServicesObject,
  compRef: CompRef,
) {
  const compProperties = documentServices.components.properties.get(compRef);

  return _.pick(compProperties, ['isCollapsed', 'isHidden']);
}

function flattenLegacySectionToNewSection(
  documentServices: DocumentServicesObject,
  compRef: CompRef,
  section: SectionDescription,
): SectionDescription {
  if (hasForbiddenBehavior(documentServices, compRef)) {
    throw new PartialMigrationError(
      'Section cannot be migrated through partial migration',
    );
  }

  const nestedChildren = documentServices.components.getChildren(compRef);
  const nestedBackground = getSectionBackground(documentServices, compRef);
  const nestedBehaviors = getSectionBehaviors(documentServices, compRef);
  const nestedProperties = getSectionProperties(documentServices, compRef);
  const flattenSectionDescription = { ...section };

  flattenSectionDescription.children = section.children.reduce(
    (children: CompRef[], child: CompRef) => {
      if (child.id === compRef.id) {
        children.push(...nestedChildren);
      } else {
        children.push(child);
      }

      return children;
    },
    [],
  );

  flattenSectionDescription.design = {
    ...section.design,
    background: nestedBackground,
  };

  flattenSectionDescription.properties = {
    ...section.properties,
    ...nestedProperties,
  };

  flattenSectionDescription.behaviors = [
    ...section.behaviors,
    ...nestedBehaviors,
  ];

  flattenSectionDescription.childrenToRemove = [
    ...section.childrenToRemove,
    compRef,
  ];

  flattenSectionDescription.childrenToKeepDesktopLayout = [
    ...section.childrenToKeepDesktopLayout,
    ...nestedChildren.map(
      (ref): DesktopComponent => ({
        ref,
        x: documentServices.components.layout.get(ref)?.x,
      }),
    ),
  ];

  return flattenMobileLegacySectionToNewSection(
    documentServices,
    compRef,
    flattenSectionDescription,
  );
}

function flattenMobileLegacySectionToNewSection(
  documentServices: DocumentServicesObject,
  compRef: CompRef,
  section: SectionDescription,
) {
  if (isSectionDesktopOnly(section, compRef)) {
    return section;
  }

  const {
    mobile: { children: mobileSectionChildren },
  } = section;

  const mobileCompRef = documentServices.components.getMobileRef(compRef);
  const mobileCompIndex = mobileSectionChildren.findIndex(
    (mobileChild) => mobileChild.ref.id === mobileCompRef.id,
  );
  const mobileCompDescription = mobileSectionChildren[mobileCompIndex];

  const flattenMobileStripChildren: MobileComponent[] =
    documentServices.components
      .getChildren(mobileCompRef)
      .map((mobileCompChild) =>
        flattenMobileChild(
          documentServices,
          mobileCompChild,
          mobileCompDescription,
        ),
      )
      .filter((item) => Boolean(item));

  const flattenMobileSectionChildren = [...section.mobile.children];

  flattenMobileSectionChildren.splice(
    mobileCompIndex,
    1,
    ...flattenMobileStripChildren,
  );

  return {
    ...section,
    mobile: {
      ...section.mobile,
      children: flattenMobileSectionChildren,
    },
  };
}

function flattenLegacySection(
  documentServices: DocumentServicesObject,
  section: SectionDescription,
): SectionDescription {
  const { children } = section;

  return children.reduce((flattenedSection, compRef) => {
    const couldBeFlattened = isSection(documentServices, compRef);

    return couldBeFlattened
      ? flattenLegacySectionToNewSection(
          documentServices,
          compRef,
          flattenedSection,
        )
      : flattenedSection;
  }, section);
}

function isSectionDesktopOnly(section: SectionDescription, compRef: CompRef) {
  if (!section.mobile) {
    return true;
  }

  const isMobileSectionHasChildRef = section.mobile.children.some(
    (child) => child.ref.id === compRef.id,
  );

  if (!isMobileSectionHasChildRef) {
    return true;
  }

  return false;
}

export function removeLegacySections(
  documentServices: DocumentServicesObject,
  sections: SectionDescription[],
): SectionDescription[] {
  return sections.map((section) =>
    flattenLegacySection(documentServices, section),
  );
}
