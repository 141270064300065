import React, { PureComponent } from 'react';
import _ from 'lodash';
import * as util from '@/util';
import { translate } from '@/i18n';
import { fedopsLogger, editorWixRecorder } from '@/util';
import * as coreBi from '@/coreBi';
import constants from '@/constants';
import type { ProgressStatus } from '@/constants';
import type { PublishInteractionStartedSource } from 'types/fedops/publishInteraction';

import withSendBi, { type WithSendBiProps } from '../../hocs/withSendBi';
import withOpenDropPanel from '../../hocs/withOpenDropPanel';
import withDropDown, {
  type WithDropDownOnHoverDropDownProps,
  type ComponentWithDropDownOnHoverProps,
} from '../../hocs/withDropDownOnHover';
import withCustomDropPanel from '../../hocs/withCustomDropPanel';
import withDropPanelBiBehavior from './withDropPanelBiBehavior';
import withPublishDropPanel from './withDropPanel';

import { mapStateToProps, mapDispatchToProps } from './publishMappers';

import TopBarButton from '../../topBarButton/topBarButton';
import ActionSuccessSymbol from '../../actionSuccess/actionSuccessSymbol';

import type { PublishOptions, SaveAndPublishOptions } from '@/savePublish';

const { BUTTONS } = constants.ROOT_COMPS.TOPBAR;

const isInProgress = (status: ProgressStatus) =>
  status === constants.PROGRESS_STATUS.IN_PROGRESS;
const isDoneSuccessfully = (status: ProgressStatus) =>
  status === constants.PROGRESS_STATUS.DONE_SUCCESS;

export interface PublishButtonOwnProps {
  className?: string;
  isFirstManualPublish?: boolean;
  isPremium?: boolean;
}

export interface PublishButtonStateProps {
  status: ProgressStatus;
  disabled: boolean;
  shouldSaveBeforePublish: boolean;
  isMobileEditor?: boolean;
  isInPreviewMode?: boolean;
  isSitePublished?: boolean;
  numberOfManualPublish: number;
  topBarStateBIParamValue: string;
}

export interface PublishButtonDispatchProps {
  publish: (options: PublishOptions) => void;
  saveAndPublish: (options: SaveAndPublishOptions) => void;
}

type PublishButtonProps = PublishButtonOwnProps &
  PublishButtonStateProps &
  PublishButtonDispatchProps &
  WithSendBiProps &
  ComponentWithDropDownOnHoverProps;

class PublishButton extends PureComponent<
  PublishButtonProps & WithSendBiProps
> {
  handleClick = () => {
    const sourceOfStart: PublishInteractionStartedSource =
      'topBar_publishButton';

    // NOTE: if "shouldSaveBeforePublish", instead of publish, there will save and save success panel. Then user should click "publish" click on the panel.
    if (!this.props.shouldSaveBeforePublish) {
      // NOTE: https://github.com/wix-private/santa-editor/issues/37029
      fedopsLogger.interactionStarted(fedopsLogger.INTERACTIONS.PUBLISH, {
        paramsOverrides: {
          sourceOfStart,
        },
      });

      this.props.publish({
        sourceOfStart,
      });
    } else {
      this.props.saveAndPublish({
        sourceOfStart,
        origin: 'top_bar_publish',
      });
    }
    editorWixRecorder.addLabel('publish top bar clicked');
    this.props.sendBi(coreBi.events.topbar.top_bar_click, {
      category: 'publish',
      origin: this.props.isInPreviewMode ? 'preview' : 'editor',
      isPreview: this.props.isInPreviewMode,
      is_published: this.props.isSitePublished,
      state: this.props.topBarStateBIParamValue,
    });

    const currentViewMode = util.publishUtil.getPublishViewMode(
      this.props.isMobileEditor,
      this.props.isInPreviewMode,
    );

    this.props.sendBi(coreBi.events.publish.PUBLISH_CLICK, {
      origin: 'topbar',
      viewModeSource: currentViewMode,
      is_first_manual_publish: this.props.isFirstManualPublish,
      isPremium: this.props.isPremium,
      numberOfManualPublish: this.props.numberOfManualPublish
        ? this.props.numberOfManualPublish + 1
        : 1,
    });
  };

  render() {
    const doneSuccessfully = isDoneSuccessfully(this.props.status);

    const className = util.cx({
      'top-bar-publish-btn': true,
      'top-bar-btn-in-progress-indicator': isInProgress(this.props.status),
      'top-bar-btn-done-successfully': doneSuccessfully,
      [this.props.className]: true,
    });

    const customSymbol = doneSuccessfully
      ? React.createElement(ActionSuccessSymbol)
      : null;

    const label = translate('TOPBAR_Site_Publish');
    return (
      <TopBarButton
        automationId="top-bar-button-publish"
        disabled={this.props.disabled}
        label={label}
        onClick={this.handleClick}
        className={className}
        customSymbol={customSymbol}
        onMouseEnter={this.props.onMouseEnter}
        onMouseLeave={this.props.onMouseLeave}
      />
    );
  }
}

const PublishButtonWithDropDown = _.flow(
  util.hoc.connect(
    util.hoc.STORES.EDITOR_API,
    mapStateToProps,
    mapDispatchToProps,
  ),
  withDropDown,
  withDropPanelBiBehavior,
  withCustomDropPanel(BUTTONS.PUBLISH),
  withPublishDropPanel,
  withOpenDropPanel,
  withSendBi,
)(PublishButton) as React.ComponentType<
  PublishButtonOwnProps & WithDropDownOnHoverDropDownProps
>;

PublishButtonWithDropDown.defaultProps = {
  panelTopMargin: 11,
};

(PublishButtonWithDropDown as AnyFixMe).pure = PublishButton;

export default PublishButtonWithDropDown;
