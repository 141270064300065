import { BaseEntryScope, createEntryPoint } from '@/apilib';
import {
  EditorAPIKey,
  EditorCoreApiKey,
  EditorParamsApiKey,
  WorkspaceApiKey,
  PreviewApiKey,
  SectionsApiKey,
  AddPresetApiKey,
  AutosaveManagerApiKey,
  EditorPermissionsApiKey,
  EditorRestrictionsApiKey,
  WorkspaceModesApiKey,
} from '@/apis';
import { ZoomModeApi } from './api';
import { ZoomModeApiKey } from './publicApiKey';
import { Store } from './store';
import { createDeferred } from './deferred';
import { bootstrap } from './bootstrap';

export class Scope extends BaseEntryScope {
  editorAPI = this.useDependency(EditorAPIKey);
  editorCoreAPI = this.useDependency(EditorCoreApiKey);
  editorParamsAPI = this.useDependency(EditorParamsApiKey);
  addPresetAPI = this.useDependency(AddPresetApiKey);
  previewAPI = this.useDependency(PreviewApiKey);
  sectionsAPI = this.useDependency(SectionsApiKey);
  autosaveAPI = this.useDependency(AutosaveManagerApiKey);
  workspaceAPI = this.useDependency(WorkspaceApiKey);
  store = this.declareStore(Store);
  zoomTransitionDeferred = this.declareApi(createDeferred);
  editorPermissionsApi = this.useDependency(EditorPermissionsApiKey);
  editorRestrictionsApi = this.useDependency(EditorRestrictionsApiKey);
  workspaceModesApi = this.useDependency(WorkspaceModesApiKey);
}

export const ZoomModeEntryPoint = createEntryPoint({
  name: 'ZoomModeEntrypoint',
  publicApi({ contributeApi }) {
    contributeApi(ZoomModeApiKey, ZoomModeApi);
  },
  Scope,
  async initialize(scope) {
    await scope.editorCoreAPI.hooks.initReady.promise;
    await bootstrap(scope);
    scope.editorCoreAPI.hooks.zoomModeInit.resolve();
  },
});
