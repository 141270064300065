import _ from 'lodash';
import constants from '@/constants';
import { arrayUtils, controlsUtils } from '@/util';
import { gfppModel } from '@/gfppData';
import * as platform from '@/platform';

import type { EditorAPI } from '@/editorAPI';
import type { CompRef } from 'types/documentServices';

const { GFPP_BUTTON_CLICK } = constants.COMP_META_DATA.CONTROLS;

function doesCompHavePlatformOverrideData(
  editorAPI: EditorAPI,
  compRef: AnyFixMe,
) {
  if (!compRef) {
    return false;
  }
  const editorMode = editorAPI.isMobileEditor() ? 'mobile' : 'desktop';
  const { mainActionNames } = platform.constants.GFPP;
  const componentStageData =
    editorAPI.platform.controllers.getConnectedComponentStageData(
      compRef,
    ) as any;
  const data =
    componentStageData?.gfpp?.[editorMode]?.[mainActionNames.MAIN_ACTION1];
  return !!data;
}

function doubleClick(
  editorAPI: EditorAPI,
  params: {
    evt: MouseEvent;
    comp: CompRef[];
  },
) {
  const compType = editorAPI.components.getType(params.comp);
  const doubleClickPlugin = editorAPI.pluginService.getPlugin(
    editorAPI.pluginService.pluginConstants.DOUBLE_CLICK,
    compType,
  );
  if (
    _.isFunction(doubleClickPlugin) &&
    !doesCompHavePlatformOverrideData(editorAPI, params.comp[0])
  ) {
    doubleClickPlugin(editorAPI, params);
  } else {
    const compMainAction = gfppModel.getComponentMainActionClick(
      editorAPI,
      params.comp,
    );
    if (_.isFunction(compMainAction)) {
      const selectedComponents = arrayUtils
        .asArray(params.comp)
        .map((selectedComponent) =>
          controlsUtils.getFirstControllableComponent(
            editorAPI,
            selectedComponent,
            GFPP_BUTTON_CLICK,
          ),
        );
      compMainAction(
        editorAPI,
        selectedComponents,
        constants.USER_ACTIONS.ORIGIN.DOUBLE_CLICK,
      );
    }
  }
}

export { doubleClick };
