import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import {
  menuAddMenuNewPageClick,
  menuAddMenuNewPageDone,
} from '@wix/bi-logger-editor/v2';
import { MEMBERS_AREA_V2 } from '@wix/app-definition-ids';

import * as stateManagement from '@/stateManagement';
import {
  fedopsLogger,
  hoc,
  link,
  editorWixRecorder,
  isMainMenuFlowEnabled,
  isAdvancedMenuOpen,
  biLogger,
} from '@/util';
import { ErrorReporter } from '@wix/editor-error-reporter';
import { translate } from '@/i18n';
import * as compPanelInfra from '@/compPanelInfra';
import * as helpIds from '@/helpIds';
import { CustomScroll, Divider } from '@wix/wix-base-ui';
import constants from '@/constants';

import { Header } from './Header/Header';
import { Footer, MobileDisclaimerFooter } from './Footer/Footer';
import { createMenuApi } from '../../API/menuAPI';
import { TRANSLATIONS_MAP } from './utils/translations';
import {
  cleanId,
  getLinkPanelPages,
  isMembersLoginMenuId,
  isMembersMenuId,
  isMenuItemRenamable,
} from '../../utils/utils';
import {
  MENU_SETS_MANAGE_PANEL_NAME,
  MENU_SETS_RADIO_MANAGE_PANEL_NAME,
  MULTISELECT_PANEL_NAME,
  PAGES_MENU_ID,
} from '../../constants';
import {
  showEditMenuNotification,
  showRenameMenuItemNotification,
} from './notifications/notifications';
import { getMenuItemActions } from './menuItemActions';
import { ConnectedMenuList } from './ConnectedMenuList/ConnectedMenuList';
import { getMenuItemLocalizations } from './utils/translationHelpers';
import {
  menuBiLogger,
  ADD_NEW_PAGE_BI_CATEGORY,
  ADD_NEW_PAGE_BI_ORIGIN,
} from '../../bi/menuBiLogger';
import { withMenuBi } from '../../bi/MenuBi';
import { AUTOMATION_IDS } from './utils/automationIds';
import {
  getPagesMultiSelectPanelData,
  handleMultiSelectSubmit,
} from './multiselectPanelData';
import {
  getAddMenuItemsActions,
  type GetActionsOptions,
  type MenuAddItemAction,
} from './menuActions';
import { getCheckCanDrop } from './utils/canDrop';

import type { CompRef, PagesData, Link } from 'types/documentServices';
import type {
  IMultiselectPanelOwnProps,
  IMultiselectPanelItem,
  IMultiselectPanelSection,
} from '@/panels';
import type { StateMapperArgs } from 'types/redux';
import type { EditorAPI } from '@/editorAPI';
import type { IAction, IMenuItem, ITreeItem } from '@/baseUI';
import type { IMenuConfig } from './utils/config.types';

const {
  components: {
    hoc: { compPanel },
  },
  panels: {
    actions: { updateOrOpenPanel, openHelpCenter: openHelpCenterAction },
  },
} = stateManagement;

const {
  LinkTypes,
  getPagesForLinkPanel,
  linkTypeValidators: { isPageLink },
} = link;
const {
  connect,
  STORES: { EDITOR_API },
} = hoc;

type IMenuId = string;
type IMenuItemId = string;

type IStoreProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = ReturnType<typeof mapDispatchToProps>;

const biOrigin = 'manage_menu';

interface IOwnProps {
  menuId?: IMenuId;
  frameProps: any;
  compData: any;
  selectedComponents: CompRef[];
  style: {
    left: number;
    top: number;
  };
  isMobileEditor: boolean;
  config?: IMenuConfig;
}

interface IMenuManagePanelProps
  extends IOwnProps,
    IStoreProps,
    IDispatchProps {}

const MenuManagePanelComponent = (props: IMenuManagePanelProps) => {
  const [editingId, setEditingId] = useState<IMenuItemId>(null);
  const {
    frameProps,
    attachedMenu,
    openMenuSetsPanel,
    openMenuSetsRadioPanel,
    openPagesMultiselectPanel,
    openMigrationPromotionalPanelIfNeeded,
    openShowMenuItemsArticle,
    isMobileEditor: isMobile,
    openLinkPanel,
    addLink,
    createDropdown,
    createContainer,
    linkItem,
    editorAPI,
    onRename,
    isItemRenamable,
    showHeader,
    showItemsList,
    showFooter,
    showMobileDisclaimerFooter,
    numberOfCustomMenus,
    fixInvalidConnectedMenuId,
    getAddItemsActions,
    panelHelpId,
    openMobileDisclaimerArticle,
    compData,
    openUpgradeMenuContainerPanel,
    config,
    isMembersMenu,
  } = props;

  useEffect(
    () => {
      fixInvalidConnectedMenuId();
      if (!isMembersMenu) {
        openMigrationPromotionalPanelIfNeeded();
      }
    }, // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const isMainMenuFlow = isMainMenuFlowEnabled();

  useEffect(() => {
    editorWixRecorder.addLabel('custom_menus');
    editorWixRecorder.addLabel('manage_panel_open');
    editorWixRecorder.addCustomAttribute(
      'custom_menus_number',
      String(numberOfCustomMenus),
    );
  }, [numberOfCustomMenus]);

  const attachedMenuId = attachedMenu.id;

  const setEdit = (id: IMenuItemId = null) => {
    setEditingId(id);

    if (id) {
      fedopsLogger.interactionStarted(
        fedopsLogger.INTERACTIONS.MANAGE_MENU.RENAME_ITEM,
      );
    }
  };

  const handleNewItemSubmit = async (link: Link) => {
    editorWixRecorder.addLabel('custom_menus_add_item');

    const menuItemId = await addLink(link);

    if (!isPageLink(link)) {
      setEdit(menuItemId);
    }
  };
  const handleAddPageClick = () => {
    menuBiLogger.logAddSitePageButtonClick();

    openPagesMultiselectPanel();
  };

  const handleAddLinkButtonClick = () => {
    if (isMainMenuFlow) {
      menuBiLogger.logAddLinkButtonClick();
    } else {
      menuBiLogger.logAddLinkClick();
    }

    const isMembersLoginMenu = isMembersLoginMenuId(attachedMenuId);
    const isMembersAreaV2Context = getIsMembersAreaV2Context(
      editorAPI,
      isMembersLoginMenu,
    );

    openLinkPanel({
      defaultLinkType: isMembersAreaV2Context
        ? LinkTypes.PAGE_LINK
        : LinkTypes.EXTERNAL_LINK,
      onSubmit: handleNewItemSubmit,
    });
  };

  const handleAddAnchorClick = () => {
    menuBiLogger.logAddAnchorButtonClick();
    openLinkPanel({
      defaultLinkType: LinkTypes.ANCHOR_LINK,
      onSubmit: handleNewItemSubmit,
    });
  };

  const handleAddSectionClick = () => {
    menuBiLogger.logAddSectionButtonClick();
    openLinkPanel({
      defaultLinkType: LinkTypes.ANCHOR_LINK,
      onSubmit: handleNewItemSubmit,
    });
  };

  const handleAddDynamicPage = () => {
    menuBiLogger.logAddDynamicPageButtonClick();
    openLinkPanel({
      defaultLinkType: LinkTypes.PAGE_LINK,
      onSubmit: handleNewItemSubmit,
    });
  };

  const handleAddDropdownButtonClick = async () => {
    if (isMainMenuFlow) {
      menuBiLogger.logAddSubmenuButtonClick();
    } else {
      menuBiLogger.logAddDropdownClick();
    }
    fedopsLogger.interactionStarted(
      fedopsLogger.INTERACTIONS.MANAGE_MENU.ADD_DROPDOWN,
    );

    const newDropdownId = await createDropdown();

    if (newDropdownId) {
      setEdit(newDropdownId);
      fedopsLogger.interactionEnded(
        fedopsLogger.INTERACTIONS.MANAGE_MENU.ADD_DROPDOWN,
      );
    }
  };

  const handleAddContainerButtonClick = async () => {
    fedopsLogger.interactionStarted(
      fedopsLogger.INTERACTIONS.MANAGE_MENU.ADD_CONTAINER,
    );

    const newContainerId = await createContainer();
    menuBiLogger.logAddContainerButtonClick(newContainerId);

    setEdit(newContainerId);
    fedopsLogger.interactionEnded(
      fedopsLogger.INTERACTIONS.MANAGE_MENU.ADD_CONTAINER,
    );
  };

  const handleLinkItem = (itemId: IMenuItemId) => {
    openLinkPanel({
      showAllPages: true,
      onSubmit: (newLink) => {
        linkItem(itemId, newLink);
        if (!isPageLink(newLink)) {
          setEdit(itemId);
        }
      },
    });
  };

  const handleChangeLink = (itemId: IMenuItemId, currentLink: Link) => {
    openLinkPanel({
      link: currentLink,
      onSubmit: (link) => {
        linkItem(itemId, link);
        if (!isPageLink(link)) {
          setEdit(itemId);
        }
      },
    });
  };

  const handleHeaderButtonClick = () => {
    openMenuSetsPanel();
    menuBiLogger.logChangeMenuSetClicked();
  };

  const handleMainMenuFlowHeaderButtonClick = () => {
    openMenuSetsRadioPanel();
    menuBiLogger.logChangeMenuSetClicked();
  };

  const handleItemDoubleClick = useCallback(
    (id: IMenuItemId) => {
      if (isItemRenamable(id, attachedMenuId)) {
        setEdit(id);
      }
    },
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [attachedMenuId],
  );

  const getEditContainerClickHandler = () => {
    return (menuItem: IMenuItem) => {
      menuBiLogger.logEditContainerClick(menuItem);
      return config?.features?.containers.enterEditMode(
        menuItem,
        attachedMenuId,
      );
    };
  };

  const getItemsActions = (item: ITreeItem<IMenuItem>): IAction[] => {
    const { item: itemData } = item;

    return getMenuItemActions({
      editorAPI,
      config,
      item,
      menuId: attachedMenuId,
      onRename: () => {
        setEdit(item.id);
        menuBiLogger.logContextMenuRenameClick(itemData);
      },
      onLinkItem: () => {
        handleLinkItem(item.id);
        menuBiLogger.logContextMenuLinkClick(itemData);
      },
      onUnlinkItem: () => {
        setEdit(item.id);
        menuBiLogger.logContextMenuUnlinkClick(itemData);
      },
      onChangeLink: () => {
        handleChangeLink(item.id, itemData.link);
        menuBiLogger.logContextMenuChangeClick(itemData);
      },
      onSeoSettingsClick: () => {
        menuBiLogger.logContextMenuSettingsClick(itemData);
      },
      onMoveToDropdownClick: (isSubSubCase: boolean) => {
        return isSubSubCase
          ? menuBiLogger.logContextMenuMoveToDropdownSubSubClick(itemData)
          : menuBiLogger.logContextMenuMoveToDropdownClick(itemData);
      },
      onMoveOutOfDropdownClick: (isSubSubCase: boolean) => {
        return isSubSubCase
          ? menuBiLogger.logContextMenuMoveOutOfDropdownSubSubClick(itemData)
          : menuBiLogger.logContextMenuMoveOutOfDropdownClick(itemData);
      },
      onRemoveClick: () => {
        menuBiLogger.logContextMenuRemoveClick(itemData);
      },
    });
  };

  useEffect(() => {
    fedopsLogger.interactionEnded(
      fedopsLogger.INTERACTIONS.MANAGE_MENU.OPEN_PANEL,
    );
  }, []);

  const headerProps = {
    menuId: attachedMenuId,
    isMobile,
    isTiny: compData.id === 'TINY_MENU',
    onButtonClick: isMainMenuFlow
      ? handleMainMenuFlowHeaderButtonClick
      : handleHeaderButtonClick,
    showMenuItemsCaption: showItemsList,
    onInfoIconLinkClick: openShowMenuItemsArticle,
    openUpgradeMenuContainerPanel,
  };

  const footerHeight = showFooter ? 72 : 0;

  return (
    <compPanelInfra.compPanelFrame
      title={
        isMainMenuFlow
          ? TRANSLATIONS_MAP.MAIN_MENU_FLOW.PANEL_TITLE
          : TRANSLATIONS_MAP.panelTitle
      }
      helpId={panelHelpId}
      automationId={AUTOMATION_IDS.PANEL}
      contentClass="menu-manage-panel__content"
      className="menu-manage-panel"
      {...frameProps}
      useNativeScroll
    >
      <CustomScroll flex="1">
        <div
          className="menu-manage-panel__scroll-wrapper"
          style={
            {
              '--footer-height': footerHeight,
              '--header-height': showHeader ? 130 : 0,
            } as React.CSSProperties
          }
        >
          {showHeader && <Header {...headerProps} />}
          {showHeader && showItemsList && (
            <div className="menu-manage-panel__divider">
              <Divider long />
            </div>
          )}
          {showItemsList && (
            <div className="menu-manage-panel__items-list">
              <ConnectedMenuList
                menuId={attachedMenuId}
                checkCanDrop={getCheckCanDrop(attachedMenu, editorAPI)}
                getActions={getItemsActions}
                setEditingId={setEdit}
                editingId={editingId}
                onRename={onRename}
                onItemDoubleClick={handleItemDoubleClick}
                getItemLocalizations={getMenuItemLocalizations}
                maxItemNameLength={40}
                editContainer={getEditContainerClickHandler()}
              />
            </div>
          )}
        </div>
      </CustomScroll>
      <div className="menu-manage-panel__divider">
        <Divider long />
      </div>
      {showMobileDisclaimerFooter && (
        <MobileDisclaimerFooter
          openMobileDisclaimerArticle={openMobileDisclaimerArticle}
        />
      )}
      {showFooter && (
        <Footer
          key={`menu-manage-panel-footer-${attachedMenuId}`}
          addActions={getAddItemsActions(
            {
              onAddLinkClick: handleAddLinkButtonClick,
              onAddDynamicClick: handleAddDynamicPage,
              onAddAnchorClick: handleAddAnchorClick,
              onAddSectionClick: handleAddSectionClick,
              onAddDropdownClick: handleAddDropdownButtonClick,
              onAddContainerClick: handleAddContainerButtonClick,
              onAddPageClick: handleAddPageClick,
            },
            config,
          )}
          onMainButtonClick={menuBiLogger.logAddMainButtonClick}
        />
      )}
    </compPanelInfra.compPanelFrame>
  );
};

const getEditorAPI = (
  dispatch: AnyFixMe,
  getState: AnyFixMe,
  { editorAPI }: AnyFixMe,
) => editorAPI;

const defaultLinkLabel = translate(TRANSLATIONS_MAP.ITEM.LINK.newLinkText);

const getLinkPanelVisibleSections = (
  linkPanelPages: PagesData[],
  isMembersMenu: boolean,
  isMembersAreaV2Context: boolean,
) => {
  const withoutNoLinkSections = { [LinkTypes.NO_LINK]: false };

  if (isMembersAreaV2Context) {
    return withoutNoLinkSections;
  }

  const defaultSections = {
    ...withoutNoLinkSections,
    [LinkTypes.PAGE_LINK]: linkPanelPages.length > 0,
  };

  const onlyPageSection = _.fromPairs(
    Object.values(LinkTypes).map((type) => [
      type,
      type === LinkTypes.PAGE_LINK,
    ]),
  );

  return isMembersMenu ? onlyPageSection : defaultSections;
};

export type IOpenLinkPanel = (options: {
  defaultLinkType?: string;
  link?: Link;
  onSubmit(link: Link): void;
  showAllPages?: boolean;
  biOrigin?: string;
}) => void;

const shouldShowHeader = ({
  isInMultiLingualFlow,
  isMembersMenu,
  isMobileEditor,
  numberOfCustomMenus,
  numberOfComponents,
}: AnyFixMe) => {
  if (isInMultiLingualFlow || isMembersMenu || isAdvancedMenuOpen()) {
    return false;
  }

  if (isMobileEditor) {
    return true;
  }

  return numberOfCustomMenus > 1 || numberOfComponents > 1;
};

const getIsMembersAreaV2Context = (
  editorAPI: EditorAPI,
  isMembersLoginMenu: boolean,
) => isMembersLoginMenu && editorAPI.tpa.app.isInstalled(MEMBERS_AREA_V2);

const mapStateToProps = (
  { editorAPI }: StateMapperArgs,
  ownProps: IOwnProps,
) => {
  const isMainMenuFlow = isMainMenuFlowEnabled();

  const { compData, isMobileEditor } = ownProps;
  const menuAPI = createMenuApi(editorAPI);
  const customMenus = menuAPI.getCustomMenus();
  const menuComponents = menuAPI.getMenuComponentsWithCustomMenus();
  const attachedMenuId = cleanId(compData.menuRef || ownProps.menuId);

  const isMembersMenu = isMembersMenuId(attachedMenuId);
  const isMembersLoginMenu = isMembersLoginMenuId(attachedMenuId);
  const attachedMenu = menuAPI.getMenu(attachedMenuId);
  const isInMultiLingualFlow =
    editorAPI.language.multilingual.isEnabled() &&
    !stateManagement.multilingual.services.utils.currentIsOriginal(editorAPI);
  const mainMenuId: string | undefined = menuAPI.getMainMenu()?.id;

  const isMainMenu = mainMenuId === attachedMenu.id;

  const showHeader = shouldShowHeader({
    isInMultiLingualFlow,
    isMembersMenu,
    isMobileEditor,
    numberOfCustomMenus: customMenus.length,
    numberOfComponents: menuComponents.length,
  });

  let showItemsList;

  if (isMainMenuFlow || isAdvancedMenuOpen()) {
    showItemsList = !(isMobileEditor && isMainMenu);
  } else {
    showItemsList = !isMobileEditor || customMenus.length > 1;
  }

  const showMobileDisclaimerFooter = isMainMenu && isMobileEditor;
  const showFooter = !showMobileDisclaimerFooter;
  const isMembersAreaV2Context = getIsMembersAreaV2Context(
    editorAPI,
    isMembersLoginMenu,
  );
  const showDynamicPages = getLinkPanelPages(editorAPI, false).length > 0;

  let panelHelpId = helpIds.MENU_PANEL.MANAGE_MENU;

  if (isMobileEditor) {
    panelHelpId = helpIds.MENU_PANEL.MANAGE_MENU_MOBILE;
  } else if (isMembersMenu) {
    panelHelpId = helpIds.MENU_PANEL.MANAGE_MENU_MEMBERS;
  } else if (isMembersLoginMenu) {
    panelHelpId = helpIds.MENU_PANEL.MANAGE_MENU_MEMBERS_LOGIN;
  }

  type GetAddItemsActions = (
    options: Omit<
      GetActionsOptions,
      | 'isInMultiLingualFlow'
      | 'isMembersLoginMenu'
      | 'isMembersMenu'
      | 'showDynamicPages'
      | 'isMembersAreaV2Context'
    >,
    config?: IMenuConfig,
  ) => MenuAddItemAction[];

  const getAddItemsActions: GetAddItemsActions = (option, config) =>
    getAddMenuItemsActions(
      {
        ...option,
        isMembersAreaV2Context,
        isInMultiLingualFlow,
        isMembersLoginMenu,
        isMembersMenu,
        showDynamicPages,
      },
      config,
    );

  return {
    showHeader,
    showItemsList,
    showMobileDisclaimerFooter,
    showFooter,
    attachedMenu,
    isInMultiLingualFlow,
    mainMenuId,
    editorAPI,
    showDynamicPages,
    numberOfCustomMenus: customMenus.length,
    panelHelpId,
    getAddItemsActions,
    isMembersMenu,
  };
};

const flattenMultiselectItems = (items: IMultiselectPanelSection[]) =>
  _.uniqBy(
    items.reduce((acc: IMultiselectPanelItem[], section) => {
      section.groups.map((group) => acc.push(...group.items));
      return acc;
    }, []),
    'id',
  );

const MENU_ITEM_ADD = 'menu item add';

const mapDispatchToProps = (dispatch: AnyFixMe, ownProps: IOwnProps) => {
  const isMainMenuFlow = isMainMenuFlowEnabled();
  const editorAPI: EditorAPI = dispatch(getEditorAPI);
  const menuAPI = createMenuApi(editorAPI, ownProps.config);
  const {
    compData,
    style: panelPosition,
    selectedComponents,
    isMobileEditor,
  } = ownProps;

  const attachedMenuId = cleanId(compData.menuRef || ownProps.menuId);

  const createDropdown = () => {
    dispatch(showEditMenuNotification(attachedMenuId));

    const id = menuAPI.addDropdown(attachedMenuId);
    editorAPI.history.add(MENU_ITEM_ADD);

    return id;
  };

  const createContainer = () => {
    dispatch(showEditMenuNotification(attachedMenuId));

    const id = menuAPI.addContainer(attachedMenuId);
    editorAPI.history.add(MENU_ITEM_ADD);

    return id;
  };

  const addLink = async (newLink: Link) => {
    const label = (await menuAPI.getNewLinkLabel(newLink)) || defaultLinkLabel;

    dispatch(showEditMenuNotification(attachedMenuId));
    const id = menuAPI.addItem(attachedMenuId, { link: newLink, label });
    editorAPI.history.add(MENU_ITEM_ADD);

    return id;
  };

  const linkItem = (id: IMenuItemId, link: Link) => {
    dispatch(showEditMenuNotification(attachedMenuId));

    menuAPI.linkItem(attachedMenuId, id, link);

    editorAPI.history.add(MENU_ITEM_ADD);
  };

  const openLinkPanel: IOpenLinkPanel = ({
    link,
    defaultLinkType,
    onSubmit,
    showAllPages = false,
  }) => {
    fedopsLogger.interactionStarted(
      fedopsLogger.INTERACTIONS.MANAGE_MENU.ADD_OR_CHANGE_FROM_LINK_PANEL,
    );
    const isMembersMenu = isMembersMenuId(attachedMenuId);
    const isMembersLoginMenu = isMembersLoginMenuId(attachedMenuId);
    const isMembersAreaV2Context = getIsMembersAreaV2Context(
      editorAPI,
      isMembersLoginMenu,
    );

    const defaultLinkPanelPages: PagesData[] = getPagesForLinkPanel(editorAPI);
    const linkPanelPages = getLinkPanelPages(editorAPI, showAllPages);
    const visibleSections = getLinkPanelVisibleSections(
      linkPanelPages,
      isMembersMenu,
      isMembersAreaV2Context,
    );

    editorAPI.openLinkPanel({
      visibleSections,
      defaultLinkType,
      link,
      biOrigin,
      showAllPopups: true,
      pagesForAnchors: defaultLinkPanelPages,
      pages: isMembersAreaV2Context ? null : linkPanelPages,
      origin: 'manage-menu',
      callback: (link: Link) => {
        onSubmit(link);
        fedopsLogger.interactionEnded(
          fedopsLogger.INTERACTIONS.MANAGE_MENU.ADD_OR_CHANGE_FROM_LINK_PANEL,
        );
      },
      onCancelCallback: () => {
        fedopsLogger.interactionEnded(
          fedopsLogger.INTERACTIONS.MANAGE_MENU.ADD_OR_CHANGE_FROM_LINK_PANEL,
        );
      },
    });
  };

  const openMigrationPromotionalPanelIfNeeded = () =>
    editorAPI.temporaryAdvancedMenuAPI.openMigrationPanelIfNeeded(
      'manage-menu-gfpp',
    );

  const RELATIVE_TOP_POSITION = 72;
  // panel width
  const RELATIVE_LEFT_POSITION = -250;
  const { top = 0, left = 0 } = panelPosition || {};
  const openPanelPosition = {
    top: top + RELATIVE_TOP_POSITION,
    left:
      left +
      RELATIVE_LEFT_POSITION * (left + RELATIVE_LEFT_POSITION < 0 ? -1 : 1),
  };

  const openMenuSetsPanel = () => {
    editorWixRecorder.addLabel('manage_menu_set_panel_open');

    const leavePanelsOpen = true;

    fedopsLogger.interactionStarted(
      fedopsLogger.INTERACTIONS.MANAGE_MENU.OPEN_MENU_SETS_PANEL,
    );

    dispatch(
      updateOrOpenPanel(
        MENU_SETS_MANAGE_PANEL_NAME,
        openPanelPosition,
        leavePanelsOpen,
      ),
    );
  };

  const openMenuSetsRadioPanel = () => {
    editorWixRecorder.addLabel('manage_menu_set_radio_panel_open');

    const leavePanelsOpen = true;

    fedopsLogger.interactionStarted(
      fedopsLogger.INTERACTIONS.MANAGE_MENU.OPEN_MENU_SETS_RADIO_PANEL,
    );

    dispatch(
      updateOrOpenPanel(
        MENU_SETS_RADIO_MANAGE_PANEL_NAME,
        openPanelPosition,
        leavePanelsOpen,
      ),
    );
  };

  const openPagesMultiselectPanel = () => {
    fedopsLogger.interactionStarted(
      fedopsLogger.INTERACTIONS.MANAGE_MENU.OPEN_CHANGE_PAGES_PANEL,
    );

    const items = getPagesMultiSelectPanelData(editorAPI, attachedMenuId);

    const props: IMultiselectPanelOwnProps = {
      ...openPanelPosition,
      header: translate(TRANSLATIONS_MAP.FOOTER.SHOW_PAGES.PANEL.header),
      submitLabel: translate(
        TRANSLATIONS_MAP.FOOTER.SHOW_PAGES.PANEL.submitLabel,
      ),
      helpId: '5bcf3483-8e2a-4bb5-b7ef-61abdf75e103',
      selectAllLabel: translate(
        TRANSLATIONS_MAP.FOOTER.SHOW_PAGES.PANEL.selectAll,
      ),
      items,
      onSubmit: ({ selectedIds }) => {
        fedopsLogger.interactionStarted(
          fedopsLogger.INTERACTIONS.MANAGE_MENU.CHANGE_MENU_PAGES,
        );

        handleMultiSelectSubmit(editorAPI, attachedMenuId, selectedIds);

        menuBiLogger.logMultiselectApply(selectedIds.length);
        editorAPI.history.add('menu pages change');
      },
      onSelectAll: (checkAll) => {
        menuBiLogger.logMultiselectSelectAll(checkAll);
      },
      onSelectItem: (id, value, sectionId) => {
        menuBiLogger.logMultiselectSelectItem(id, value, sectionId);
      },
      onMount: () => {
        fedopsLogger.interactionEnded(
          fedopsLogger.INTERACTIONS.MANAGE_MENU.OPEN_CHANGE_PAGES_PANEL,
        );
      },
      onAddPage: () => {
        biLogger.report(
          menuAddMenuNewPageClick({
            origin: 'site_pages',
            source: 'advanced_menu',
            category: 'page',
          }),
        );

        const flattenedPagesMultiselectData = flattenMultiselectItems(items);
        const checkedPagesIds = flattenedPagesMultiselectData.reduce(
          (acc: string[], { checked, id }) => {
            if (checked) acc.push(id);
            return acc;
          },
          [],
        );

        const pageAddedCallback = () => {
          biLogger.report(
            menuAddMenuNewPageDone({
              origin: 'site_pages',
              source: 'advanced_menu',
              category: 'page',
            }),
          );

          const newPagesMultiselectData = getPagesMultiSelectPanelData(
            editorAPI,
            attachedMenuId,
          );
          const newPages = _.differenceBy(
            flattenMultiselectItems(newPagesMultiselectData),
            flattenedPagesMultiselectData,
            'id',
          );

          handleMultiSelectSubmit(editorAPI, attachedMenuId, [
            ...checkedPagesIds,
            ...newPages.map(({ id }) => id),
          ]);

          dispatch(
            stateManagement.notifications.actions.showUserActionNotification({
              message: 'custom_menu_manage_menu_new_page_notification',
              type: constants.NOTIFICATIONS.TYPES.INFO,
              origin: 'advanced_menu_add_page',
              link: {
                caption: 'custom_menu_manage_menu_new_page_notification_cta',
                onNotificationLinkClick: () =>
                  editorAPI.pages.navigateTo(newPages[0].id),
              },
            }),
          );
        };

        fedopsLogger.interactionStarted(
          fedopsLogger.INTERACTIONS.ADD_PAGE_PANEL_OPEN,
        );

        editorAPI.panelManager.openPanel(
          constants.PANEL_NAMES.ADD_PAGES_PANEL,
          {
            origin: ADD_NEW_PAGE_BI_ORIGIN,
            biCategory: ADD_NEW_PAGE_BI_CATEGORY,
            isAdvancedMenuFlow: true,
            pageAddedCallback,
          },
          true,
        );
      },
    };

    editorWixRecorder.addLabel('manage_menu_multislect_open');
    editorAPI.panelManager.openPanel(MULTISELECT_PANEL_NAME, props, true);
  };

  const openHelpCenter = (id: string, biParams?: object) =>
    dispatch(openHelpCenterAction(id, undefined, biParams));

  const MENU_CONTAINER_UPGRADE_PANEL =
    'panels.focusPanels.menuContainerUpgradePanel';
  const openUpgradeMenuContainerPanel = () =>
    dispatch(
      updateOrOpenPanel(MENU_CONTAINER_UPGRADE_PANEL, {
        biEventOrigin: 'selection',
      }),
    );

  const openShowMenuItemsArticle = () => {
    let helpId = helpIds.MENU_PANEL.SHOW_MENU_ITEMS_FROM;

    if (isMainMenuFlow) {
      helpId = isMobileEditor
        ? helpIds.MENU_PANEL.MOBILE_CREATE_MENU
        : helpIds.MENU_PANEL.MAIN_MENU;
    }

    openHelpCenter(helpId, {
      panel_name: 'manage_menu_panel_menu_to_display',
    });
  };

  const openMobileDisclaimerArticle = () =>
    openHelpCenter(helpIds.MENU_PANEL.MOBILE_CREATE_MENU);

  const { renameItem } = menuAPI;

  const onRename = (itemId: IMenuItemId, newName: string) => {
    const item = menuAPI.getItem(attachedMenuId, itemId);

    // @ts-expect-error
    menuBiLogger.logMenuItemRename(item, item.label, newName);

    renameItem(attachedMenuId, item.id, newName);

    editorAPI.store.dispatch(
      // @ts-expect-error
      showRenameMenuItemNotification(attachedMenuId, item),
    );

    editorAPI.history.add('menu item rename');
  };

  const isItemRenamable = (itemId: IMenuItemId, menuId: string) =>
    isMenuItemRenamable(editorAPI, menuId, itemId);

  const [compRef] = selectedComponents;

  const fixInvalidConnectedMenuId = () => {
    if (!attachedMenuId || attachedMenuId === PAGES_MENU_ID) {
      const [firstCustomMenu] = menuAPI.getCustomMenus();

      if (!firstCustomMenu) {
        ErrorReporter.captureException(
          new Error('No custom menus in menu manager'),
          {
            tags: {
              'custom-menus': 'menu-manager',
            },
          },
        );
      } else {
        menuAPI.connect(firstCustomMenu.id, compRef);
      }
    }
  };

  return {
    openLinkPanel,
    createDropdown,
    createContainer,
    openMenuSetsPanel,
    openMenuSetsRadioPanel,
    openPagesMultiselectPanel,
    openMobileDisclaimerArticle,
    openUpgradeMenuContainerPanel,
    openMigrationPromotionalPanelIfNeeded,
    addLink,
    linkItem,
    openShowMenuItemsArticle,
    onRename,
    isItemRenamable,
    fixInvalidConnectedMenuId,
  };
};

export const MenuManagePanel = _.flow(
  connect(EDITOR_API, mapStateToProps, mapDispatchToProps),
  withMenuBi,
  compPanel,
)(MenuManagePanelComponent);
