// based on santa-editor/packages/compPanels/panels/mediaPlayer/mediaPlayerDesignPanel/mediaPlayerDesignPanelMapper.ts

import _ from 'lodash';
import * as stateManagement from '@/stateManagement';
import type { EditorAPI } from '@/editorAPI';
import type { EditorState } from '@/stateManagement';
import type {
  Dispatch,
  MapStateToProps,
  MapDispatchToProps,
} from 'types/redux';
import type {
  CoverImageOwnProps,
  Poster,
  CoverImageStateProps,
  CoverImageDispatchProps,
} from './types';

const { setSessionUserPreferences } = stateManagement.userPreferences.actions;
const { getSessionUserPreferences } = stateManagement.userPreferences.selectors;

const makeCompCoverId = (compId: string) => `media-player-comp-cover-${compId}`;
const makeVideoCoverId = (videoId: string) =>
  `media-player-video-cover-${videoId}`;

export const mapStateToProps: MapStateToProps<
  CoverImageStateProps,
  CoverImageOwnProps
> = ({ editorAPI, state }, props) => {
  const compRef = Array.isArray(props.selectedComponent)
    ? props.selectedComponent[0]
    : props.selectedComponent;

  const compData = editorAPI.components.data.get(compRef);
  const compDesign = editorAPI.components.design.get(compRef);
  const compProperties = editorAPI.components.properties.get(compRef);

  const compId = compRef.id;
  const videoId =
    compData?.videoRef?.videoId || compDesign?.background?.mediaRef?.videoId;

  const mediaFeatures = compDesign?.background?.mediaRef?.mediaFeatures || [];
  const hasAlpha = mediaFeatures.includes('alpha');

  const mediaPlayerCompCover = getSessionUserPreferences(
    makeCompCoverId(compId),
  )(state) as { uri: string };

  const mediaPlayerVideoCover = getSessionUserPreferences(
    makeVideoCoverId(videoId),
  )(state) as { uri: string };

  const posterUri = compData?.videoRef?.posterImageRef?.uri;

  const getPosterMMGRPath = getSessionUserPreferences(posterUri)(
    state,
  ) as string;

  return {
    compData,
    compDesign,
    compProperties,
    hasAlpha,
    mediaPlayerCompCover,
    mediaPlayerVideoCover,
    getPosterMMGRPath,
    mediaManager: editorAPI.mediaServices.mediaManager,
    setFocusToEditorFunc: editorAPI.mediaServices.setFocusToEditor,
  };
};

const getEditorAPI = (
  dispatch: Dispatch,
  getState: () => EditorState,
  { editorAPI }: { editorAPI: EditorAPI },
) => editorAPI;

export const mapDispatchToProps: MapDispatchToProps<
  CoverImageDispatchProps,
  CoverImageStateProps & CoverImageOwnProps
> = (dispatch: Dispatch, props) => {
  const editorAPI = dispatch(getEditorAPI);
  const compRef = Array.isArray(props.selectedComponent)
    ? props.selectedComponent[0]
    : props.selectedComponent;

  const compId = compRef.id;
  const videoId = props.compData?.videoRef?.videoId;

  return {
    setPosterMMGRPath: (uri: string, mediaManagerPath: string) =>
      dispatch(setSessionUserPreferences(uri, mediaManagerPath)),

    setMediaPlayerCompCover: (mediaObj: Poster) =>
      dispatch(setSessionUserPreferences(makeCompCoverId(compId), mediaObj)),
    setMediaPlayerVideoCover: (mediaObj: Poster) =>
      dispatch(setSessionUserPreferences(makeVideoCoverId(videoId), mediaObj)),

    updatePartialData: (path, newData) => {
      const compData = _.cloneDeep(props.compData);
      _.set(compData, path, newData);
      editorAPI.components.data.update(compRef, compData);
    },
    updatePartialProperties(path, newPropValue) {
      const compProps = _.cloneDeep(props.compProperties);
      _.set(compProps, path, newPropValue);
      props.updateProperties(compProps);
    },
  };
};
