import {
  ComponentsApiKey,
  BaseResizeAndPushApiKey,
  MouseActionsApiKey,
  EditorAPIKey,
} from '@/apis';
import type { ComponentsApi } from '@/components';
import type { MouseEvent } from 'react';
import type { CompRef } from 'types/documentServices';
import type { ThunkAction } from 'types/redux';

import { sections as sectionsUtils } from '@/util';
import { resizeAndPushHooks } from '@/layoutResize';

const ANIMATION_DURATION = 0.75;

function getComponentsToResize(
  components: CompRef[],
  { componentsApi }: { componentsApi: ComponentsApi },
): CompRef[] {
  if (componentsApi.is.verticallyResizableByChildren(components)) {
    return componentsApi.getChildren(components);
  }

  if (componentsApi.is.verticallyResizableByContainer(components)) {
    return [componentsApi.getContainer(components)];
  }

  return components;
}

export const startResizeAndPush =
  (event: MouseEvent, components: CompRef[]): ThunkAction =>
  (dispatch, getState, { host }) => {
    const componentsApi = host.getAPI(ComponentsApiKey);
    const baseResizeAndPushApi = host.getAPI(BaseResizeAndPushApiKey);
    const mouseActionsApi = host.getAPI(MouseActionsApiKey);
    const editorAPI = host.getAPI(EditorAPIKey);

    const componentsToResizeInitial = getComponentsToResize(components, {
      componentsApi,
    });
    const interceptorResult =
      resizeAndPushHooks.getComponentsToResizeInterceptor.intercept({
        compsToResize: componentsToResizeInitial,
      });
    const componentsToResize = interceptorResult.data.compsToResize;

    mouseActionsApi.registerMouseMoveAction(
      {
        ...baseResizeAndPushApi,
        on: (event: MouseEvent) => {
          baseResizeAndPushApi.on(event);
          if (sectionsUtils.isSectionsEnabled()) return; // because there is a logic in the sections/bootstrap package instead of inlining it here

          if (componentsToResize[0].id === 'SITE_FOOTER') {
            if (
              event.clientY >=
              window.innerHeight - editorAPI.editorConfig.extraSiteHeight
            ) {
              editorAPI.scroll.animateScrollTo(
                { y: editorAPI.site.getHeight() },
                ANIMATION_DURATION,
                editorAPI.scroll.clearScrollTo,
              );
            }
          }
        },
      },
      {
        comps: componentsToResize,
        evt: event,
      },
    );

    event.stopPropagation();
  };
