import * as util from '@/util';

const {
  connect,
  STORES: { EDITOR_API },
} = util.hoc;

const connectWithEditor = (
  mapStateToProps: AnyFixMe,
  mapDispatchToProps: AnyFixMe,
) => connect(EDITOR_API, mapStateToProps, mapDispatchToProps);

export default connectWithEditor;
