import * as stateManagement from '@/stateManagement';

import type { EditorAPI } from '@/editorAPI';

import type { ConditionStructure, ConditionTranslations } from '../types';

const METADATA_KEY = 'isMultilingualRequired';
const TRANSLATION_KEY = 'isMultilingualCondition';

export interface MultilingualRequiredCondition {
  extras: {
    [METADATA_KEY]?: boolean;
  };
  translations: {
    [TRANSLATION_KEY]?: ConditionTranslations;
  };
}

export const getMultilingualConditionStructure = (
  editorAPI: EditorAPI,
): ConditionStructure<
  typeof METADATA_KEY,
  typeof TRANSLATION_KEY,
  MultilingualRequiredCondition
> => ({
  metadataKey: METADATA_KEY,
  translationsKey: TRANSLATION_KEY,
  check: () => !editorAPI.dsRead.language.multilingual.isEnabled(),
  getAction: () => () => {
    editorAPI.store.dispatch(
      stateManagement.multilingual.actions.notifyMultilingualAppToOpen(),
    );
  },
});
