import blogLayoutsSection from './sections/blogLayoutsSection';
import archiveSection from './sections/archiveSection';
import customFeedSection from './sections/customFeedSection';
import featuredPostsSection from './sections/featuredPostsSection';
import postGallerySection from './sections/postGallerySection';
import postListSection from './sections/postListSection';
import recentPostsSection from './sections/recentPostsSection';
import rssButtonSection from './sections/rssButtonSection';
import tagCloudSection from './sections/tagCloudSection';
import tickerSection from './sections/tickerSection';
import facebookCommentsSection from './sections/facebookCommentsSection';
import disqusSection from './sections/disqusSection';
import categoriesSection from './sections/categoriesSection';
import relatedPostsSection from './sections/relatedPostsSection';
import heroImageSection from './sections/heroImageSection';
import experiment from 'experiment';

const componentSections = [];
componentSections.push(recentPostsSection);
componentSections.push(featuredPostsSection);
componentSections.push(archiveSection);
componentSections.push(categoriesSection);
componentSections.push(tagCloudSection);
componentSections.push(rssButtonSection);
componentSections.push(customFeedSection);
componentSections.push(tickerSection);
componentSections.push(postGallerySection);
componentSections.push(relatedPostsSection);

componentSections.push(postListSection);
if (experiment.isOpen('se_blogStudioExperiment')) {
  componentSections.push(heroImageSection);
}
componentSections.push(facebookCommentsSection);
componentSections.push(disqusSection);

const siteDoesNotHaveBlog = [blogLayoutsSection];

export { componentSections as siteHasOldBlog, siteDoesNotHaveBlog };
