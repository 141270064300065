import { isDebugMode } from '@/util';
import {
  getCeType,
  getCompRefsIncludingParent,
  getSectionContentType,
  addComponentDataToCompRef,
  filterContentTaggedComponents,
} from './scanUtils';
import { REGULAR, LIST, SLIDESHOW, REPEATER } from './scanConsts';
import {
  getRegularInjectableMap,
  getListInjectableMap,
  getRepeaterInjectableMap,
  getSlideShowInjectableMap,
} from './scanSection';

import type { EditorAPI } from '@/editorAPI';
import type { ComponentsAPI } from './scanTypes';
import type { CompRef } from 'types/documentServices';
import type {
  ComponentDataForContentInjection,
  ContentInjectionMapOutput,
} from './scanTypes';

export function getPageContentInjectionMap(
  editorAPI: EditorAPI,
  pageRef: CompRef,
): ContentInjectionMapOutput[] {
  const { sections, components } = editorAPI;
  const sectionsCompRefs = sections.getPageSections(pageRef);
  return sectionsCompRefs.map((compRef: CompRef) =>
    getSectionInjectableMap(components, compRef),
  );
}

export function getSectionInjectableMap(
  componentsAPI: ComponentsAPI,
  sectionRef: CompRef,
): ContentInjectionMapOutput {
  const ceType = getCeType(componentsAPI, sectionRef);
  const contentTag = componentsAPI.code.getNickname(sectionRef);

  if (!contentTag) {
    if (isDebugMode()) {
      console.log('No content tag found for section', sectionRef);
    }
    return { ceType, isList: false, injectable: {} };
  }

  const categoryFromContentTag = getSectionContentType(contentTag);
  const compRefList = getCompRefsIncludingParent(componentsAPI, sectionRef);
  const componentsWithData = addComponentDataToCompRef(
    componentsAPI,
    compRefList,
  );
  const TaggedComponentsWithData =
    filterContentTaggedComponents(componentsWithData);

  return getSectionContentMapByCategory(
    componentsAPI,
    categoryFromContentTag,
    ceType,
    TaggedComponentsWithData,
  );
}

function getSectionContentMapByCategory(
  componentsAPI: ComponentsAPI,
  category: string,
  ceType: string,
  TaggedComponentsWithData: ComponentDataForContentInjection[],
) {
  const sectionInjectableMap: ContentInjectionMapOutput = {
    ceType,
    isList: false,
  };

  switch (category) {
    case REGULAR:
      sectionInjectableMap.injectable = getRegularInjectableMap(
        componentsAPI,
        TaggedComponentsWithData,
      );
      break;
    case LIST:
      sectionInjectableMap.injectable = getListInjectableMap(
        componentsAPI,
        TaggedComponentsWithData,
      );
      sectionInjectableMap.isList = true;
      break;
    case REPEATER:
      sectionInjectableMap.injectable = getRepeaterInjectableMap(
        componentsAPI,
        TaggedComponentsWithData,
      );
      sectionInjectableMap.isList = true;
      break;
    case SLIDESHOW:
      sectionInjectableMap.injectable = getSlideShowInjectableMap(
        componentsAPI,
        TaggedComponentsWithData,
      );
      sectionInjectableMap.isList = true;
      break;
    default:
      sectionInjectableMap.injectable = {};
  }
  return sectionInjectableMap;
}
