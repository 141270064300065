import { createEditorCoreApi } from './api';
import { EditorCoreApiKey } from './publicApiKey';

import type { EntryPoint } from '@/apilib';
import type { EditorBaseHooks as EntryHooks } from './types';

export const createEditorCoreEntrypoint = ({
  editorBaseHooks,
}: {
  editorBaseHooks: EntryHooks;
}): EntryPoint => ({
  name: 'EditorCoreApi',
  declareAPIs: () => [EditorCoreApiKey],
  getDependencyAPIs: () => [],
  attach(shell) {
    shell.contributeAPI(EditorCoreApiKey, () =>
      createEditorCoreApi({ editorBaseHooks }),
    );
  },
});

export type EditorBaseHooks = EntryHooks;
export type EditorCoreApi = ReturnType<typeof createEditorCoreApi>;
export type { InstallAppsHookFn } from './types';
