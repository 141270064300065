:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._contextMenu_bg7jf_7 {
  position: absolute;
  top: 6px;
  right: 6px; }
  ._contextMenu_bg7jf_7 .context-menu-button {
    background: #fff; }

._contextMenuSymbol_bg7jf_14 path {
  fill: #3b4057 !important; }
