import _ from 'lodash';
import type { EditorAPI } from '@/editorAPI';
import type { CompRef } from 'types/documentServices';
import * as stateManagement from '@/stateManagement';

const { getViewPort } = stateManagement.domMeasurements.selectors;

const isRootComponent = (editorAPI: EditorAPI, compPointer: CompRef) => {
  return (
    compPointer.id === 'SITE_FOOTER' ||
    compPointer.id === 'SITE_HEADER' ||
    compPointer.id === editorAPI.dsRead.pages.getFocusedPageId()
  );
};

function isBottom(
  editorAPI: EditorAPI,
  comp: CompRef,
  inputDistanceFromTop = 0,
) {
  if (!comp) {
    return false;
  }
  const compLayout = editorAPI.components.layout.getRelativeToScreen(comp);
  const relativeToScroll = !compLayout.fixedPosition;

  const viewPort = getViewPort(
    editorAPI.store.getState(),
    relativeToScroll,
    editorAPI.getLeftBarButtonsBoundingRect(),
  );
  const isLarge = isRootComponent(editorAPI, comp);
  const isLargeDistance = isLarge ? 20 : 16;
  const distanceFromTop = inputDistanceFromTop
    ? inputDistanceFromTop
    : isLargeDistance;
  const shouldDisplayLabelBottom =
    compLayout.y - distanceFromTop <= viewPort.scrollTop;
  return shouldDisplayLabelBottom;
}

export const getIsIndicatorBottom = (
  editorAPI: EditorAPI,
  selectedComponents: CompRef[],
) => {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/is-undefined
  if (_.isUndefined(_.head(selectedComponents))) {
    return false;
  }
  return isBottom(editorAPI, _.head(selectedComponents), 32);
};

export const getIsLabelBottomEdit = (
  editorAPI: EditorAPI,
  selectedComponents: CompRef[],
) => {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/is-undefined
  if (_.isUndefined(_.head(selectedComponents))) {
    return false;
  }

  return isBottom(editorAPI, _.head(selectedComponents));
};

export const getIsLabelBottom = (
  editorAPI: EditorAPI,
  compPointer: CompRef,
) => {
  return isBottom(editorAPI, compPointer);
};
