import ReactDOM from 'reactDOM';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as util from '@/util';
import constants from '@/constants';
import * as mediaManagerPanelTypes from '../../mediaManagerPanelTypes/mediaManagerPanelTypes';
import * as mediaManagerUtils from '../../mediaManagerPanelUtils/mediaManagerPanelUtils';
import React from 'react';
import { TextLabel } from '@wix/wix-base-ui';
import { cx, SafeInjectHtml } from '@/util';
import { isItemPurchasable } from '../../mediaManagerPanelUtils/mediaManagerPanelPurchaseUtils';
import { withTranslation, type WithTranslation } from 'react-i18next';

const { imageTransform } = util;
const mediaManagerMediaTypes = constants.MEDIA_MANAGER_MEDIA_TYPES;

const MAX_WIDTH = 228;
const MIN_WIDTH = 120;
const MAX_HEIGHT = 342;
const MIN_HEIGHT = 120;

export interface TaxSettings {
  showPricesWithTax: boolean;
  displayTaxType: string;
}

//TYPE WAS GENERATED, remove this line when reviewed
interface Props {
  item: AnyFixMe;
  isBackgroundBlue?: boolean;
  priceInfo: {
    value: number;
    symbol: string;
    taxSettings: TaxSettings;
  };
}

class MediaPreview extends React.Component<WithTranslation & Props> {
  static displayName = 'MediaManagerPanelMediaPreview';

  static propTypes = {
    item: mediaManagerPanelTypes.item.isRequired,
    isBackgroundBlue: PropTypes.bool,
  };

  state = {
    dimension: mediaManagerUtils.getPreviewDimension(
      this.props.item,
      MIN_WIDTH,
      MAX_WIDTH,
      MIN_HEIGHT,
      MAX_HEIGHT,
    ),
  };

  componentWillUnmount() {
    if (this.isVideoItemPreview()) {
      this.unmountVideoSafely();
    }
  }

  unmountVideoSafely = () => {
    const video = this.getVideoInstance();

    video.pause();
    video.src = '';
    video.load();
  };

  getVideoInstance = () => {
    return ReactDOM.findDOMNode(this.refs.videoInstance);
  };

  getName = () => {
    return this.props.item.name;
  };

  getDimension = () => {
    return this.state.dimension;
  };

  getPriceInfo = () => {
    return this.props.priceInfo;
  };

  getContentStyle = () => {
    const { width, height } = this.getDimension();

    return {
      width: Math.max(width, MIN_WIDTH),
      height,
    };
  };

  getPicturePreviewStyle = () => {
    const { width, height } = this.getDimension();

    return {
      backgroundImage: `url('${this.getPicturePreviewUrl()}')`,
      width,
      height,
    };
  };

  hasItemPrice = () => {
    return isItemPurchasable(this.props.item);
  };

  getItemPriceTaxType = () => {
    if (!this.props.priceInfo) {
      return null;
    }
    const { taxSettings } = this.props.priceInfo;
    return taxSettings?.showPricesWithTax && taxSettings?.displayTaxType;
  };

  getItemPrice = () => {
    const { value, symbol } = this.props.priceInfo;
    return (
      <span>
        <SafeInjectHtml tag="span" html={symbol} />
        {value}
      </span>
    );
  };

  getItemMediaType = () => {
    return this.props.item.mediaType;
  };

  getFooterStyle = () => {
    return {
      maxWidth: `${Math.max(this.getDimension().width, MIN_WIDTH)}px`,
    };
  };

  getTitleTextWrapperStyle = () => {
    return this.getFooterStyle();
  };

  isBackgroundBlue = () => {
    return this.props.isBackgroundBlue;
  };

  isVideoItemPreview = () => {
    return mediaManagerUtils.isItemOfType(
      this.props.item,
      mediaManagerMediaTypes.VIDEO,
    );
  };

  isPictureItemPreview = () => {
    return mediaManagerUtils.isItemOneOfTypes(this.props.item, [
      mediaManagerMediaTypes.PICTURE,
      mediaManagerMediaTypes.SHAPE,
    ]);
  };

  getPicturePreviewUrl = () => {
    const { width, height } = this.getDimension();

    return mediaManagerUtils.generatePicturePreviewUrl(
      this.props.item,
      width,
      height,
      imageTransform.fittingTypes.SCALE_TO_FILL,
    );
  };

  getVideoPreviewUrl = (): string => {
    return _.head(this.props.item.videoPreviewUrls) || '';
  };

  getDuration = () => {
    const duration = this.props.item?.fileInput?.duration ?? null;

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/is-null
    if (_.isNull(duration)) {
      return duration;
    }

    return mediaManagerUtils.getReadableDuration(duration);
  };

  render() {
    const { t } = this.props;
    const priceIncludesTax = t(
      'Media_Panel_Shutterstock_item_price_includes_tax',
      {
        taxType: t(
          `Media_Panel_Shutterstock_tax_${this.getItemPriceTaxType()}`,
        ),
      },
    );
    return (
      <div className="media-manager-media-preview">
        {this.hasItemPrice() && (
          <div className="media-manager-media-preview__price">
            {this.getItemPrice()}
            {this.getItemPriceTaxType() && <span>{priceIncludesTax}</span>}
          </div>
        )}

        <div
          style={this.getContentStyle()}
          className={cx('media-manager-media-preview__content', {
            'with-blue-background': this.isBackgroundBlue(),
          })}
        >
          {this.isPictureItemPreview() ? (
            <div
              style={this.getPicturePreviewStyle()}
              key="picture"
              className={`media-manager-media-preview__${this.getItemMediaType()}`}
            />
          ) : null}

          {this.isVideoItemPreview() ? (
            <video
              src={this.getVideoPreviewUrl()}
              autoPlay
              muted
              loop
              ref="videoInstance"
              key="video"
              className="media-manager-media-preview__video"
            />
          ) : null}
        </div>

        <div
          style={this.getFooterStyle()}
          className="media-manager-media-preview__footer"
        >
          <div style={this.getTitleTextWrapperStyle()}>
            <div className="media-manager-media-preview__title-text">
              <TextLabel
                value={this.getName()}
                type="T07"
                shouldTranslate={false}
                enableEllipsis={false}
              />
            </div>
          </div>
          <div className="media-manager-media-preview__duration">
            <TextLabel
              value={this.getDuration()}
              type="T07"
              shouldTranslate={false}
              enableEllipsis={false}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(MediaPreview);
