'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')
const newGalleries = [{
    'id': 'Gallery_Slider_Galleries_1',
    'structure': {
        "type": "Component",
        "skin": "wysiwyg.viewer.skins.gallery.SlideShowTextOverlay",
        "layout": {
            "width": 568,
            "height": 368,
            "x": 0,
            "y": 0,
            "scale": 1,
            "rotationInDegrees": 0,
            "fixedPosition": false
        },
        "componentType": "wysiwyg.viewer.components.SlideShowGallery",
        "data": {
            "type": "ImageList",
            "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false
            },
            "items": [
                {
                    "type": "Image",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "2.0",
                        "isHidden": false
                    },
                    "title": "Add a Title",
                    "uri": "b25591_3352e742a2e5467aad7df422106fc278~mv2_d_3000_2000_s_2.jpg",
                    "description": "Describe your image",
                    "width": 3000,
                    "height": 2000,
                    "alt": ""
                },
                {
                    "type": "Image",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "2.0",
                        "isHidden": false
                    },
                    "title": "Add a Title",
                    "uri": "b25591_2790fd3a23db403793dbf2c3c1c62723~mv2_d_3000_1946_s_2.jpg",
                    "description": "Describe your image",
                    "width": 3000,
                    "height": 1946,
                    "alt": ""
                },
                {
                    "type": "Image",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "2.0",
                        "isHidden": false
                    },
                    "title": "Add a Title",
                    "uri": "b25591_24a35ec53c4a4d41a0da9c7b7cd6d2d3~mv2_d_3000_1965_s_2.jpg",
                    "description": "Describe your image",
                    "width": 3000,
                    "height": 1965,
                    "alt": ""
                },
                {
                    "type": "Image",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "2.0",
                        "isHidden": false
                    },
                    "title": "Add a Title",
                    "uri": "b25591_f1ab68320b444a4ca3cc0b7dd2284bf5~mv2_d_3000_4500_s_4_2.jpg",
                    "description": "Describe your image",
                    "width": 3000,
                    "height": 4500,
                    "alt": ""
                },
                {
                    "type": "Image",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "2.0",
                        "isHidden": false
                    },
                    "title": "Add a Title",
                    "uri": "b25591_a2db3b22be0a4790a1eda133c543fb3c~mv2_d_2869_3586_s_4_2.jpg",
                    "description": "Describe your image",
                    "width": 2869,
                    "height": 3586,
                    "alt": ""
                },
                {
                    "type": "Image",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "2.0",
                        "isHidden": false
                    },
                    "title": "Add a Title",
                    "uri": "b25591_23ba699582354676ba0ab793abd1a23f~mv2_d_8192_7754_s_4_2.jpg",
                    "description": "Describe your image",
                    "width": 8192,
                    "height": 7754,
                    "alt": ""
                },
                {
                    "type": "Image",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "2.0",
                        "isHidden": false
                    },
                    "title": "Add a Title",
                    "uri": "b25591_99f5cd6c9b6b4899aef6c2e486e291c7~mv2_d_3000_2930_s_4_2.jpg",
                    "description": "Describe your image",
                    "width": 3000,
                    "height": 2930,
                    "alt": ""
                },
                {
                    "type": "Image",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "2.0",
                        "isHidden": false
                    },
                    "title": "Add a Title",
                    "uri": "b25591_daeb574706844015abbd510c472010a8~mv2_d_3000_2000_s_2.jpg",
                    "description": "Describe your image",
                    "width": 3000,
                    "height": 2000,
                    "alt": ""
                },
                {
                    "type": "Image",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "2.0",
                        "isHidden": false
                    },
                    "title": "Add a Title",
                    "uri": "b25591_89a9544354da4226913c3727baf2a742~mv2_d_3000_1688_s_2.jpg",
                    "description": "Describe your image",
                    "width": 3000,
                    "height": 1688,
                    "alt": ""
                },
                {
                    "type": "Image",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "2.0",
                        "isHidden": false
                    },
                    "title": "Add a Title",
                    "uri": "b25591_6a8a65a12f0e48c8bf74c70634ed2e9a~mv2_d_3000_2000_s_2.jpg",
                    "description": "Describe your image",
                    "width": 3000,
                    "height": 2000,
                    "alt": ""
                },
                {
                    "type": "Image",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "2.0",
                        "isHidden": false
                    },
                    "title": "Add a Title",
                    "uri": "b25591_bbd372e536434c859bf040766757df4a~mv2_d_3000_3000_s_4_2.jpg",
                    "description": "Describe your image",
                    "width": 3000,
                    "height": 3000,
                    "alt": ""
                },
                {
                    "type": "Image",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "2.0",
                        "isHidden": false
                    },
                    "title": "Add a Title",
                    "uri": "b25591_bc3841f1dee44abf840a9a1b33591002~mv2_d_3000_1995_s_2.jpg",
                    "description": "Describe your image",
                    "width": 3000,
                    "height": 1995,
                    "alt": ""
                }
            ]
        },
        "props": {
            "type": "SlideShowGalleryProperties",
            "metaData": {
                "schemaVersion": "1.0",
                "autoGenerated": false
            },
            "expandEnabled": true,
            "galleryImageOnClickAction": "zoomMode",
            "goToLinkText": "Go to link",
            "imageMode": "clipImage",
            "transition": "swipeHorizontal",
            "autoplayInterval": 2,
            "autoplay": false,
            "transDuration": 1,
            "bidirectional": true,
            "reverse": false,
            "showAutoplay": false,
            "showNavigation": true,
            "showCounter": true,
            "showExpand": false,
            "showSocial": false,
            "alignText": "left"
        },
        "style": {
            "type": "TopLevelStyle",
            "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false
            },
            "style": {
                "properties": {
                    "alpha-bg": "0.7",
                    "alpha-brd": "1",
                    "alpha-txt": "1",
                    "bg": "color_11",
                    "boxShadowToggleOn-shd": "false",
                    "brd": "#2F2E2E",
                    "brw": "0px",
                    "fntds": "normal normal normal 14px/1.4em futura-lt-w01-light",
                    "fntt": "normal normal normal 16px/1.4em futura-lt-w01-book",
                    "rd": "0px",
                    "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                    "txt": "color_15"
                },
                "propertiesSource": {
                    "alpha-bg": "value",
                    "bg": "theme",
                    "brd": "value",
                    "brw": "value",
                    "fntds": "value",
                    "fntt": "value",
                    "rd": "value",
                    "shd": "value",
                    "txt": "theme"
                },
                "groups": {}
            },
            "componentClassName": "wysiwyg.viewer.components.SlideShowGallery",
            "pageId": "",
            "compId": "",
            "styleType": "custom",
            "skin": "wysiwyg.viewer.skins.gallery.SlideShowTextOverlay"
        },
        "mobileStructure": {
            "layout": {
                "width": 280,
                "height": 200,
                "x": 20,
                "y": 10,
                "scale": 1,
                "rotationInDegrees": 0,
                "fixedPosition": false
            },
            "metaData": {
                "originalCompId": "comp-jawjutlq",
                "author": "duplicate"
            }
        },
        "activeModes": {},
        'id': 'i5fdo20v_1'
    },
    'preset': {
        'rect': {'width': 162, 'height': 132, 'x': 0, 'y': 0},
        'label': 'add_preset_galleries_slider_arrows_label',
        'tags': null
    }
}, {
    'id': 'Gallery_Slider_Galleries_2',
    'structure': {
        "type": "Component",
        "skin": "skins.viewer.gallery.SlideShowCleanAndSimple",
        "layout": {
            "width": 568,
            "height": 368,
            "x": 0,
            "y": 0,
            "scale": 1,
            "rotationInDegrees": 0,
            "fixedPosition": false
        },
        "componentType": "wysiwyg.viewer.components.SlideShowGallery",
        "data": {
            "type": "ImageList",
            "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false
            },
            "items": [
                {
                    "type": "Image",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "2.0",
                        "isHidden": false
                    },
                    "title": "Add a Title",
                    "uri": "b25591_99f5cd6c9b6b4899aef6c2e486e291c7~mv2_d_3000_2930_s_4_2.jpg",
                    "description": "Describe your image",
                    "width": 3000,
                    "height": 2930,
                    "alt": ""
                },
                {
                    "type": "Image",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "2.0",
                        "isHidden": false
                    },
                    "title": "Add a Title",
                    "uri": "b25591_2790fd3a23db403793dbf2c3c1c62723~mv2_d_3000_1946_s_2.jpg",
                    "description": "Describe your image",
                    "width": 3000,
                    "height": 1946,
                    "alt": ""
                },
                {
                    "type": "Image",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "2.0",
                        "isHidden": false
                    },
                    "title": "Add a Title",
                    "uri": "b25591_a2db3b22be0a4790a1eda133c543fb3c~mv2_d_2869_3586_s_4_2.jpg",
                    "description": "Describe your image",
                    "width": 2869,
                    "height": 3586,
                    "alt": ""
                },
                {
                    "type": "Image",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "2.0",
                        "isHidden": false
                    },
                    "title": "Add a Title",
                    "uri": "b25591_bbd372e536434c859bf040766757df4a~mv2_d_3000_3000_s_4_2.jpg",
                    "description": "Describe your image",
                    "width": 3000,
                    "height": 3000,
                    "alt": ""
                }
            ]
        },
        "props": {
            "type": "SlideShowGalleryProperties",
            "metaData": {
                "schemaVersion": "1.0",
                "autoGenerated": false
            },
            "expandEnabled": true,
            "galleryImageOnClickAction": "zoomMode",
            "goToLinkText": "Go to link",
            "imageMode": "clipImage",
            "transition": "crossfade",
            "autoplayInterval": 2,
            "autoplay": true,
            "transDuration": 1,
            "bidirectional": true,
            "reverse": false,
            "showAutoplay": false,
            "showNavigation": false,
            "showCounter": true,
            "showExpand": false,
            "showSocial": false,
            "alignText": "left"
        },
        "style": {
            "type": "TopLevelStyle",
            "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false
            },
            "style": {
                "properties": {
                    "abg": "color_11",
                    "abgh": "color_15",
                    "alpha-abg": "1",
                    "alpha-abgh": "1",
                    "alpha-bg": "0.8",
                    "alpha-brd": "0",
                    "bg": "color_11",
                    "boxShadowToggleOn-shd": "false",
                    "brd": "color_15",
                    "brw": "0",
                    "fntds": "font_8",
                    "fntt": "normal normal normal 17px/1.4em avenir-lt-w01_85-heavy1475544",
                    "rd": "0",
                    "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                    "txt": "color_15"
                },
                "propertiesSource": {
                    "abg": "theme",
                    "abgh": "theme",
                    "alpha-abg": "value",
                    "alpha-abgh": "value",
                    "alpha-bg": "value",
                    "alpha-brd": "value",
                    "bg": "theme",
                    "boxShadowToggleOn-shd": "value",
                    "brd": "theme",
                    "brw": "value",
                    "fntds": "theme",
                    "fntt": "value",
                    "rd": "value",
                    "shd": "value",
                    "txt": "theme"
                },
                "groups": {}
            },
            "componentClassName": "wysiwyg.viewer.components.SlideShowGallery",
            "pageId": "",
            "compId": "",
            "styleType": "custom",
            "skin": "skins.viewer.gallery.SlideShowCleanAndSimple"
        },
        "mobileStructure": {
            "layout": {
                "width": 280,
                "height": 200,
                "x": 20,
                "y": 10,
                "scale": 1,
                "rotationInDegrees": 0,
                "fixedPosition": false
            },
            "metaData": {
                "originalCompId": "comp-jawjv6eg",
                "author": "duplicate"
            }
        },
        "activeModes": {},
        'id': 'i5fdo211'
    },
    'preset': {
        'rect': {'width': 162, 'height': 132, 'x': 162, 'y': 0},
        'label': 'add_preset_galleries_slider_postcard_label',
        'tags': null
    }
}, {
    'id': 'Gallery_Slider_Galleries_3',
    'structure': {
        "type": "Component",
        "skin": "wysiwyg.viewer.skins.galleryslider.SliderGalleryCircleSkin",
        "layout": {
            "width": 1216,
            "height": 368,
            "x": -118,
            "y": 88,
            "scale": 1,
            "rotationInDegrees": 0,
            "fixedPosition": false
        },
        "componentType": "wysiwyg.viewer.components.SliderGallery",
        "data": {
            "type": "ImageList",
            "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false
            },
            "items": [
                {
                    "type": "Image",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "2.0",
                        "isHidden": false
                    },
                    "title": "Add a Title",
                    "uri": "b25591_2790fd3a23db403793dbf2c3c1c62723~mv2_d_3000_1946_s_2.jpg",
                    "description": "Describe your image",
                    "width": 3000,
                    "height": 1946,
                    "alt": ""
                },
                {
                    "type": "Image",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "2.0",
                        "isHidden": false
                    },
                    "title": "Add a Title",
                    "uri": "b25591_89a9544354da4226913c3727baf2a742~mv2_d_3000_1688_s_2.jpg",
                    "description": "Describe your image",
                    "width": 3000,
                    "height": 1688,
                    "alt": ""
                },
                {
                    "type": "Image",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "2.0",
                        "isHidden": false
                    },
                    "title": "Add a Title",
                    "uri": "b25591_24a35ec53c4a4d41a0da9c7b7cd6d2d3~mv2_d_3000_1965_s_2.jpg",
                    "description": "Describe your image",
                    "width": 3000,
                    "height": 1965,
                    "alt": ""
                },
                {
                    "type": "Image",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "2.0",
                        "isHidden": false
                    },
                    "title": "Add a Title",
                    "uri": "b25591_f1ab68320b444a4ca3cc0b7dd2284bf5~mv2_d_3000_4500_s_4_2.jpg",
                    "description": "Describe your image",
                    "width": 3000,
                    "height": 4500,
                    "alt": ""
                }
            ]
        },
        "props": {
            "type": "SliderGalleryProperties",
            "metaData": {
                "schemaVersion": "1.0"
            },
            "expandEnabled": true,
            "galleryImageOnClickAction": "zoomMode",
            "goToLinkText": "Go to link",
            "imageMode": "clipImage",
            "margin": 30,
            "maxSpeed": 3,
            "aspectRatio": 1,
            "aspectRatioPreset": "1:1",
            "loop": true,
            "showCounter": true,
            "autoplayInterval": 0,
            "showAutoplay": false,
            "autoplay": false,
            "transDuration": 0
        },
        "style": {
            "type": "TopLevelStyle",
            "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false
            },
            "style": {
                "properties": {
                    "alpha-bg": "0.8",
                    "alpha-brd": "1",
                    "alpha-txt": "1",
                    "bg": "#FFFFFF",
                    "brd": "#FFFFFF",
                    "brw": "0px",
                    "fntds": "normal normal normal 15px/1.4em din-next-w01-light {color_14}",
                    "txt": "#2F2E2E"
                },
                "propertiesSource": {
                    "bg": "value",
                    "brd": "value",
                    "brw": "value",
                    "fntds": "value",
                    "txt": "value"
                },
                "groups": {}
            },
            "componentClassName": "wysiwyg.viewer.components.SliderGallery",
            "pageId": "",
            "compId": "",
            "styleType": "custom",
            "skin": "wysiwyg.viewer.skins.galleryslider.SliderGalleryCircleSkin"
        },
        "mobileStructure": {
            "layout": {
                "width": 280,
                "height": 320,
                "x": 20,
                "y": 10,
                "scale": 1,
                "rotationInDegrees": 0,
                "fixedPosition": false
            },
            "metaData": {
                "originalCompId": "comp-jawjvotw",
                "author": "duplicate"
            }
        },
        "activeModes": {},
        'id': 'i5fdo215'
    },
    'preset': {
        'rect': {'width': 324, 'height': 132, 'x': 0, 'y': 132},
        'label': 'add_preset_galleries_slider_spheres_label',
        'tags': null
    }
}]

module.exports = {
    'type': addPanelDataConsts.SECTIONS_TYPES.PRESET,
    'hide': false,
    'title': 'add_section_label_slidergalleries',
    'subNavigationTitle': 'add_section_label_slidergalleries',
    'presetTitle': 'add_section_label_slidergalleries',
    'tooltipTitle': 'add_section_label_slidergalleries',
    "automationId": "add-panel-section-sliderGalleriesSection",
    'subNavigationHide': false,
    'showSectionHeader': true,
    'additionalBehaviours': {
        'labelMode': addPanelDataConsts.LABEL_BEHAVIOUR.HOVER,
        'infoIcon': addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
        'hoverImageAction': addPanelDataConsts.HOVER_IMAGE_ACTIONS.VIDEO,
        'iconEnabledForComps': {}
    },
    'props': {
        'image': 'addPanelData/sections/sliderGalleriesSection_en/sliderGalleriesSectionNew_en.png',
        "retinaImage": {
            "src": "addPanelData/sections/sliderGalleriesSection_en/sliderGalleriesSection_en-@2x.png",
            "dimensions": {
                "width": 648,
                "height": 528
            }
        },
        'imageHover': null,
        'items': newGalleries,
        'compTypes': ['wysiwyg.viewer.components.SlideShowGallery', 'wysiwyg.viewer.components.SliderGallery', 'tpa.viewer.components.Accordion', 'tpa.viewer.components.Impress', 'tpa.viewer.components.Thumbnails']
    },
    'help': {'hide': false, 'text': 'add_section_info_text_slidergalleries'}
}
