// @ts-nocheck
import * as textControls from '@/textControls';
import * as stateManagement from '@/stateManagement';

const textEditorActions = stateManagement.text.actions;

const mapStateToProps = ({ editorAPI }) => {
  const comps = editorAPI.selection.getSelectedComponents();

  return {
    comps,
    layout:
      comps.length && editorAPI.components.layout.getRelativeToScreen(comps),
  };
};

const getFonts =
  (...args) =>
  (dispatch, getState, { editorAPI }) =>
    textControls.fontUtils.getCurrentSelectableFontsWithParams(
      editorAPI,
      false,
      true,
      ...args,
    );
const loadTextEditingAPI =
  (manager) =>
  (dispatch, getState, { editorAPI }) =>
    editorAPI.store.dispatch(
      textEditorActions.invisibleTextEditor.load(manager),
    );
const unloadTextEditingAPI =
  () =>
  (dispatch, getState, { editorAPI }) =>
    editorAPI.store.dispatch(textEditorActions.invisibleTextEditor.unload());

const loadLinksHelper =
  (linksHelper) =>
  (dispatch, getState, { editorAPI }) =>
    editorAPI.store.dispatch(
      textEditorActions.invisibleTextEditor.loadLinksHelper(linksHelper),
    );
const unloadLinksHelper =
  () =>
  (dispatch, getState, { editorAPI }) =>
    editorAPI.store.dispatch(
      textEditorActions.invisibleTextEditor.unloadLinksHelper(),
    );

const mapDispatchToProps = (dispatch) => ({
  getFonts(...args) {
    return dispatch(getFonts(...args));
  },
  loadTextEditingAPI(manager) {
    return dispatch(loadTextEditingAPI(manager));
  },
  unloadTextEditingAPI() {
    return dispatch(unloadTextEditingAPI());
  },
  loadLinksHelper(linksHelper) {
    return dispatch(loadLinksHelper(linksHelper));
  },
  unloadLinksHelper() {
    return dispatch(unloadLinksHelper());
  },
});

export { mapStateToProps, mapDispatchToProps };
