._top_e4mlu_1,
._bottom_e4mlu_2,
._left_e4mlu_3,
._right_e4mlu_4 {
  background: #53cd76;
  position: fixed;
  z-index: 9999; }

._left_e4mlu_3,
._right_e4mlu_4 {
  top: 0;
  bottom: 0;
  width: 4px; }

._top_e4mlu_1,
._bottom_e4mlu_2 {
  left: 0;
  right: 0;
  height: 4px; }

._left_e4mlu_3 {
  left: 0; }

._right_e4mlu_4 {
  right: 0; }

._top_e4mlu_1 {
  top: 0; }

._bottom_e4mlu_2 {
  bottom: 0; }
