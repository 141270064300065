import { BaseEntryScope, createEntryPoint } from '@/apilib';
import { EditorAPIKey, EditorCoreApiKey, EditorParamsApiKey } from '@/apis';
import { ToursApiKey } from '@/editorTours';
import { SectionsMigrationApiKey } from './publicApiKey';
import { SectionsMigrationPublicApi } from './api';
import { initMigration } from './init';
import { createMigrationStatus } from './createMigrationStatus';

export class SectionsMigrationScope extends BaseEntryScope {
  editorAPI = this.useDependency(EditorAPIKey);
  editorCoreAPI = this.useDependency(EditorCoreApiKey);
  editorParamsAPI = this.useDependency(EditorParamsApiKey);
  tourApi = this.useDependency(ToursApiKey);
  migrationStatus = this.declareApi(createMigrationStatus);
}

export const SectionsMigrationEntryPoint = createEntryPoint({
  name: 'SectionsMigration',
  Scope: SectionsMigrationScope,
  publicApi({ contributeApi }) {
    contributeApi(SectionsMigrationApiKey, SectionsMigrationPublicApi);
  },
  async initialize(scope: SectionsMigrationScope) {
    await scope.editorCoreAPI.hooks._dsIsReady.promise;
    await initMigration(scope);
  },
});
