import _ from 'lodash';
import experiment from 'experiment';

import { platformConnectedGfpp, upgradeUtils, appController } from '@/platform';
import * as stateManagement from '@/stateManagement';
import gfppDataUtils from '../../utils/gfppDataUtils';
import constants from '@/constants';

import type { EditorAPI } from '@/editorAPI';
import type { CompRef } from 'types/documentServices';
import type { GFPPData } from '@wix/editor-component-types';

const GFPP_ACTIONS = constants.ROOT_COMPS.GFPP.ACTIONS;

const getTpaGfppDataFromManifest = (
  editorAPI: AnyFixMe,
  compRefs: CompRef[],
  compGfppData: GFPPData,
) => {
  const componentRef = _.head(compRefs);
  const gfppDataFromApp =
    editorAPI.dsRead.platform.controllers.getStageData(componentRef)?.gfpp;

  const deviceType = stateManagement.mobile.selectors.getDeviceType(
    editorAPI.store.getState(),
  );
  const resolveFunction = gfppDataUtils.getGfppDataResolver(
    editorAPI,
    compRefs,
  );

  if (experiment.isOpen('se_widgetPlugins')) {
    compGfppData.enabledActions = resolveFunction(compGfppData.enabledActions);
    compGfppData.enabledActions.push(GFPP_ACTIONS.WIDGET_PLUGINS);
  }

  if (gfppDataFromApp) {
    const { appDefinitionId } = editorAPI.components.data.get(componentRef);
    const notifyGfppButtonClicked = (
      { actionId, calleeRef }: AnyFixMe,
      origin: AnyFixMe,
    ) =>
      appController.notifyWidgetGfppClicked(
        { actionId, calleeRef, origin },
        editorAPI,
        compRefs,
      );

    const upgradeType = gfppDataFromApp[deviceType]?.iconButtons?.upgrade;
    compGfppData.presetActions = _.isFunction(compGfppData.presetActions)
      ? compGfppData.presetActions(editorAPI)
      : compGfppData.presetActions || {};
    _.set(
      compGfppData.presetActions,
      'upgrade',
      upgradeUtils.getPremiumGfpp(appDefinitionId, upgradeType),
    );

    if (deviceType === 'desktop') {
      compGfppData.enabledActions = compGfppData.enabledActions || [];
      compGfppData.mainActions = platformConnectedGfpp.generateMainActions(
        deviceType,
        gfppDataFromApp,
        {
          ...compGfppData,
          mainActions: resolveFunction(compGfppData.mainActions),
          mobileMainActions: resolveFunction(compGfppData.mobileMainActions),
        },
        notifyGfppButtonClicked,
        componentRef,
        editorAPI,
      );
      compGfppData.enabledActions =
        platformConnectedGfpp.generateEnabledActions(
          deviceType,
          gfppDataFromApp,
          resolveFunction(compGfppData.enabledActions),
        );
    } else {
      compGfppData.mobileMainActions =
        platformConnectedGfpp.generateMainActions(
          deviceType,
          gfppDataFromApp,
          {
            ...compGfppData,
            mainActions: resolveFunction(compGfppData.mainActions),
            mobileMainActions: resolveFunction(compGfppData.mobileMainActions),
          },
          notifyGfppButtonClicked,
          componentRef,
          editorAPI,
        );
      compGfppData.mobileEnabledActions =
        platformConnectedGfpp.generateEnabledActions(
          deviceType,
          gfppDataFromApp,
          resolveFunction(compGfppData.mobileEnabledActions),
        );
    }
    compGfppData.presetActionsData =
      platformConnectedGfpp.generateIconButtonsOverride(
        deviceType,
        gfppDataFromApp,
        compGfppData,
        notifyGfppButtonClicked,
        componentRef,
        editorAPI,
      );
  }

  return compGfppData;
};

export default function (
  editorAPI: EditorAPI,
  compRefs: CompRef[],
  compGfppData: GFPPData,
) {
  if (
    editorAPI.dsRead.tpa.isTpaByCompType(
      editorAPI.components.getType(_.head(compRefs)),
    )
  ) {
    return getTpaGfppDataFromManifest(editorAPI, compRefs, compGfppData);
  }
}
