import _ from 'lodash';

import type {
  CompRef,
  CompVariantPointer,
  ComponentEffect,
  Pointer,
} from '@wix/document-services-types';
import type { EditorAPI } from '@/editorAPI';

import type { EffectData, Reaction } from '../types';

export interface AnimationAPI {
  findExistingAnimation(compRef: CompRef): Pointer;
  getEffectData(compRef: CompRef): EffectData;
  setAnimation(compRef: CompRef, effectObj: EffectData): void;
  removeAnimation(compRef: CompRef): Promise<void>;
  previewAnimation(compRef: CompRef, effectObj: EffectData): void;
  stopPreviewAnimation(compRef?: CompRef): void;
}

export abstract class BaseAnimationAPI implements AnimationAPI {
  private previewIds = new Map<string, number | null>();
  // protected desktopCompRef: CompVariantPointer;
  // protected mobileCompRef: CompVariantPointer;

  constructor(
    protected editorAPI: EditorAPI,
    // protected compRef: CompRef,
    protected onCreate?: (
      compRef: CompRef,
      effectRef: Pointer,
    ) => Promise<void>,
    protected beforeRemove?: (compRef: CompRef) => Promise<void>,
  ) {}

  protected abstract getTriggerParams(compRef?: CompRef): {
    trigger: string;
    params?: any;
  };

  protected abstract getTriggerType(compRef?: CompRef): string | string[];

  abstract get reactionParams(): {
    type: string;
    [key: string]: any;
  };

  protected get emptyEffectObj() {
    return {
      type: 'TimeAnimation',
      name: '',
      value: {
        type: 'TimeAnimationOptions',
        namedEffect: null as null,
      },
    };
  }

  isMobileComp(compRef: CompRef) {
    return (
      compRef.type === 'MOBILE' ||
      compRef.variants?.find((variant) => variant.id === 'MOBILE-VARIANT')
    );
  }

  /* UTILITIES */

  private getCompRefs(compRef: CompRef) {
    const isMobileOnlyComp =
      this.editorAPI.mobile.mobileOnlyComponents.isMobileOnlyComponent(
        compRef.id,
      );
    const desktopCompRef = {
      id: compRef.id,
      type: isMobileOnlyComp ? 'MOBILE' : 'DESKTOP',
    } as CompVariantPointer;
    const mobileCompRef = this.editorAPI.components.variants.getPointer(
      desktopCompRef,
      [this.editorAPI.mobile.getMobileVariant()],
    );

    return { desktopCompRef, mobileCompRef };
  }

  private setEmptyEffect(compRef: CompVariantPointer, effectRef?: Pointer) {
    if (effectRef) {
      this.editorAPI.components.effects.update(
        compRef,
        effectRef,
        this.emptyEffectObj,
      );
      return effectRef;
    }

    return this.editorAPI.components.effects.add(compRef, this.emptyEffectObj);
  }

  protected isEmptyAnimation(effectRef: Pointer, compRef: CompRef) {
    const effect = this.editorAPI.components.effects.get(compRef, effectRef);

    return effect?.value?.namedEffect === null;
  }

  protected async createTriggerAndReaction(
    compRef: CompRef,
    effectRef: Pointer,
    triggerParams = this.getTriggerParams(compRef),
  ) {
    const triggerRef =
      this.editorAPI.components.triggers
        .getAll(compRef)
        .find(
          (ref) =>
            this.editorAPI.components.triggers.get(compRef, ref).trigger ===
            triggerParams.trigger,
        ) ?? this.editorAPI.components.triggers.add(compRef, triggerParams);

    this.editorAPI.components.reactions.add(compRef, triggerRef, {
      effect: effectRef,
      ...this.reactionParams,
    });

    await this.editorAPI.waitForChangesAppliedAsync();
  }

  findReaction(compRef: CompRef, triggerType = this.getTriggerType(compRef)) {
    const reactions = this.editorAPI.components.reactions.get(compRef);

    return reactions?.find(
      (reaction: Reaction) => reaction.triggerType === triggerType,
    );
  }

  protected isTriggerInUseInAnotherBp(compRef: CompRef, trigger: string) {
    const { mobileCompRef, desktopCompRef } = this.getCompRefs(compRef);

    const compRefInAnotherBp = this.isMobileComp(compRef)
      ? desktopCompRef
      : mobileCompRef;
    const anotherBpReactions =
      this.editorAPI.components.reactions.get(compRefInAnotherBp);

    return anotherBpReactions?.some(
      ({ triggerType }: { triggerType: string }) => trigger === triggerType,
    );
  }

  protected findTrigger(
    compRef: CompRef,
    triggerType = this.getTriggerType(compRef),
  ) {
    const allTriggers = this.editorAPI.components.triggers.getAll(compRef);

    return allTriggers.find(
      (ref) =>
        triggerType ===
        this.editorAPI.components.triggers.get(compRef, ref).trigger,
    );
  }

  protected async removeReactionAndTrigger(
    compRef: CompRef,
    triggerType = this.getTriggerType(compRef),
  ) {
    const allReactions = this.editorAPI.components.reactions.get(compRef);
    const relevantReaction = this.findReaction(compRef, triggerType);
    const relevantTrigger = this.findTrigger(compRef, triggerType);

    if (this.isMobileComp(compRef) && allReactions?.length === 1) {
      this.editorAPI.components.reactions.disable(compRef, relevantTrigger);
    } else {
      this.editorAPI.components.reactions.remove(
        compRef,
        relevantTrigger,
        relevantReaction.pointer,
      );
      if (
        !this.isTriggerInUseInAnotherBp(compRef, relevantReaction.triggerType)
      ) {
        this.editorAPI.components.triggers.remove(compRef, relevantTrigger);
      }
    }

    await this.editorAPI.waitForChangesAppliedAsync();
  }

  /* DESKTOP */

  private async createDesktopAnimation(
    desktopCompRef: CompVariantPointer,
    mobileCompRef: CompVariantPointer,
    effectObj: ComponentEffect,
  ) {
    let effectRef: Pointer;

    const mobileReaction = this.getMobileOverride(mobileCompRef);

    if (mobileReaction) {
      effectRef = mobileReaction.effect;

      this.editorAPI.components.effects.update(
        desktopCompRef,
        mobileReaction.effect,
        effectObj,
      );
    } else {
      effectRef = this.editorAPI.components.effects.add(
        desktopCompRef,
        effectObj,
      );

      if (this.isSplit(mobileCompRef)) {
        this.setEmptyEffect(mobileCompRef, effectRef);
      }
    }

    await this.createTriggerAndReaction(desktopCompRef, effectRef);

    await this.onCreate?.(desktopCompRef, effectRef);
  }

  private removeDesktopAnimation(
    desktopCompRef: CompVariantPointer,
    mobileCompRef: CompVariantPointer,
    reaction: Reaction,
  ) {
    this.removeReactionAndTrigger(desktopCompRef);

    if (this.getMobileOverride(mobileCompRef)) {
      this.setEmptyEffect(desktopCompRef, reaction.effect);
    } else {
      this.editorAPI.components.effects.remove(desktopCompRef, reaction.effect);
    }
  }

  /* MOBILE */

  private getMobileOverride(mobileCompRef: CompVariantPointer) {
    return this.isSplit(mobileCompRef) && this.findReaction(mobileCompRef);
  }

  private async split(
    mobileCompRef: CompVariantPointer,
    desktopCompRef: CompVariantPointer,
  ) {
    const desktopReactions =
      this.editorAPI.components.reactions.get(desktopCompRef);

    if (!desktopReactions) return;

    for (const reaction of desktopReactions) {
      const trigger = this.editorAPI.components.triggers
        .getAll(mobileCompRef)
        .find(
          (triggerRef) =>
            reaction.triggerType ===
            this.editorAPI.components.triggers.get(mobileCompRef, triggerRef)
              .trigger,
        );

      const reactionParams = _.omit(reaction, [
        'component',
        'pointer',
        'triggerType',
      ]);

      this.editorAPI.components.reactions.add(
        mobileCompRef,
        trigger,
        reactionParams,
      );
      // @TODO probably should update effect here as well
    }

    await this.editorAPI.waitForChangesAppliedAsync();
  }

  private isSplit(mobileCompRef: CompVariantPointer) {
    return !!this.editorAPI.components.reactions.get(mobileCompRef);
  }

  private async createMobileAnimation(
    mobileCompRef: CompVariantPointer,
    desktopCompRef: CompVariantPointer,
    effectObj: EffectData,
  ) {
    const desktopEffect =
      this.findReaction(desktopCompRef)?.effect ??
      this.setEmptyEffect(desktopCompRef);

    this.editorAPI.components.effects.update(
      mobileCompRef,
      desktopEffect,
      effectObj,
    );

    await this.createTriggerAndReaction(mobileCompRef, desktopEffect);

    await this.onCreate?.(mobileCompRef, desktopEffect);
  }

  private async removeMobileAnimation(
    mobileCompRef: CompVariantPointer,
    desktopCompRef: CompVariantPointer,
  ) {
    const mobileReaction = this.findReaction(mobileCompRef);

    if (mobileReaction) {
      await this.beforeRemove?.(mobileCompRef);

      this.removeReactionAndTrigger(mobileCompRef);

      this.setEmptyEffect(mobileCompRef, mobileReaction.effect);
      return;
    }

    const desktopReaction = this.findReaction(desktopCompRef);

    if (desktopReaction) {
      this.setEmptyEffect(mobileCompRef, desktopReaction.effect);
    }
  }

  /* EXTERNAL API */

  findExistingAnimation(compRef: CompRef) {
    const { mobileCompRef, desktopCompRef } = this.getCompRefs(compRef);

    const foundMobileReaction = this.findReaction(mobileCompRef);
    const foundDesktopReaction = this.findReaction(desktopCompRef);

    if (!this.isMobileComp(compRef)) {
      return foundDesktopReaction;
    }

    if (!this.isSplit(mobileCompRef)) {
      return foundDesktopReaction;
    }

    if (
      !foundMobileReaction &&
      foundDesktopReaction &&
      this.isEmptyAnimation(foundDesktopReaction.effect, mobileCompRef)
    ) {
      return null;
    }

    return foundMobileReaction;
  }

  getEffectData(compRef: CompRef): EffectData {
    const { mobileCompRef, desktopCompRef } = this.getCompRefs(compRef);
    const animation = this.findExistingAnimation(compRef);

    if (!animation) {
      return null;
    }

    const compRefWithData =
      this.isMobileComp(compRef) && this.isSplit(mobileCompRef)
        ? mobileCompRef
        : desktopCompRef;

    return this.editorAPI.components.effects.get(
      compRefWithData,
      animation.effect,
    );
  }

  getMobileOverrideEffectData(compRef: CompRef): EffectData {
    const { mobileCompRef } = this.getCompRefs(compRef);
    const mobileReaction = this.findReaction(mobileCompRef);

    if (!this.isSplit(mobileCompRef)) {
      return undefined;
    }

    if (!mobileReaction) {
      return null;
    }

    return this.editorAPI.components.effects.get(
      mobileCompRef,
      mobileReaction.effect,
    );
  }

  async setAnimation(compRef: CompRef, effectObj: EffectData) {
    const { mobileCompRef, desktopCompRef } = this.getCompRefs(compRef);

    if (compRef.type === 'MOBILE') {
      if (!this.isSplit(mobileCompRef)) {
        await this.split(mobileCompRef, desktopCompRef);
      }

      const existingReaction = this.findReaction(mobileCompRef);

      if (existingReaction) {
        this.editorAPI.components.effects.update(
          mobileCompRef,
          existingReaction.effect,
          effectObj,
        );
      } else {
        await this.createMobileAnimation(
          mobileCompRef,
          desktopCompRef,
          effectObj,
        );
      }
    } else {
      const existingReaction = this.findReaction(desktopCompRef);
      if (existingReaction) {
        this.editorAPI.components.effects.update(
          desktopCompRef,
          existingReaction.effect,
          effectObj,
        );
      } else {
        await this.createDesktopAnimation(
          desktopCompRef,
          mobileCompRef,
          effectObj,
        );
      }
    }

    await this.editorAPI.waitForChangesAppliedAsync();
  }

  async removeAnimation(compRef: CompRef) {
    const { mobileCompRef, desktopCompRef } = this.getCompRefs(compRef);

    if (compRef.type === 'MOBILE') {
      if (!this.isSplit(mobileCompRef)) {
        await this.split(mobileCompRef, desktopCompRef);
      }

      await this.removeMobileAnimation(mobileCompRef, desktopCompRef);
    } else {
      const existingAnimation = this.findReaction(desktopCompRef);
      if (!existingAnimation) return;

      await this.beforeRemove?.(desktopCompRef);

      this.removeDesktopAnimation(
        desktopCompRef,
        mobileCompRef,
        existingAnimation,
      );
    }

    await this.editorAPI.waitForChangesAppliedAsync();
  }

  async cleanAllAnimations(compRef: CompRef) {
    const { mobileCompRef, desktopCompRef } = this.getCompRefs(compRef);
    const triggerTypes = [
      'viewport-enter',
      'animation-end',
      'page-visible',
      'view-progress',
    ];

    const desktopReactions =
      this.editorAPI.components.reactions
        .get(desktopCompRef)
        ?.filter(({ triggerType }: Reaction) =>
          triggerTypes.includes(triggerType),
        ) ?? [];

    const mobileReactions =
      this.editorAPI.components.reactions
        .get(mobileCompRef)
        ?.filter(({ triggerType }: Reaction) =>
          triggerTypes.includes(triggerType),
        ) ?? [];

    const allEffects = _.uniqBy(
      [...desktopReactions, ...mobileReactions].map(({ effect }) => effect),
      ({ id }) => id,
    );

    const desktopTriggers =
      this.editorAPI.components.triggers.getAll(desktopCompRef);
    const relevantDesktopTriggers = desktopTriggers?.filter((ref) =>
      triggerTypes.includes(
        this.editorAPI.components.triggers.get(compRef, ref).trigger,
      ),
    );
    relevantDesktopTriggers?.forEach((trigger) =>
      this.editorAPI.components.triggers.remove(desktopCompRef, trigger),
    );

    await this.editorAPI.waitForChangesAppliedAsync();

    // There might be triggers created with mobile variant that are not accessible from desktop
    const mobileTriggers =
      this.editorAPI.components.triggers.getAll(mobileCompRef);
    const relevantMobileTriggers = mobileTriggers?.filter((ref) =>
      triggerTypes.includes(
        this.editorAPI.components.triggers.get(mobileCompRef, ref).trigger,
      ),
    );

    relevantMobileTriggers?.forEach((trigger) =>
      this.editorAPI.components.triggers.remove(mobileCompRef, trigger),
    );

    await this.editorAPI.waitForChangesAppliedAsync();

    allEffects?.forEach((effect) =>
      this.editorAPI.components.effects.remove(desktopCompRef, effect),
    );

    await this.editorAPI.waitForChangesAppliedAsync();
  }

  previewAnimation(compRef: CompRef, effectObj: EffectData) {
    const previewId = this.editorAPI.components.behaviors.previewAnimation(
      compRef,
      {
        ...effectObj.value,
        params: effectObj.value.namedEffect,
      },
      () => {
        this.previewIds.delete(compRef.id);
      },
    );

    this.previewIds.set(compRef.id, previewId);
  }

  stopPreviewAnimation(compRef: CompRef) {
    this.editorAPI.components.behaviors.stopPreviewAnimation(
      this.previewIds.get(compRef.id),
      1,
    );
  }
}
