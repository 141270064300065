import _ from 'lodash';
import React from 'react';
import { Tooltip } from '@wix/wix-base-ui';

import constants from '@/constants';
import * as baseUI from '@/baseUI';
import { translate } from '@/i18n';
import * as coreBi from '@/coreBi';
import * as helpIds from '@/helpIds';
import * as UI from '@/baseUI';
import * as interactions from '@/interactions';
import { hoc, sections, snapToUtils } from '@/util';
import { isSectionsOnStageEnabled } from '@/sectionsOnStage';
import { withRestrictions } from '@/editorRestrictions';

import * as editBoxMapper from './editBoxMapper';
import applyModeFromClipboardSuggestion from '../applyModeFromClipboardSuggestion';
import GroupSelectionFrame from '../groupSelectionFrame';
import ComponentLabels from '../componentLabels/componentLabels';
import KnobsBox from './knobsBox';
import EditBoxSubComps from './subComps';

import LayoutBox from './layout/components/LayoutBox';
import LayoutBoundingBoxPreloader from './layout/components/LayoutBoundingBoxPreloader';
import LayoutBoundingBox from './layout/components/LayoutBoundingBox';
import LayoutPositionIndicator from './layout/components/LayourPositionIndicator';
import LayoutSizeIndicator from './layout/components/LayoutSizeIndicator';
import LayoutRotationIndicator from './layout/components/LayoutRotationIndicator';
import LayoutMoveToFooter from './layout/components/LayoutMoveToFooter';
import LayoutWidthIndicator from './layout/components/LayoutWidthIndicator';

import type { EditBoxProps } from './editBoxTypes';

const { InteractionNavControls } = interactions.controls;

const ATTACH_TO_FOOTER_NOTIFICATION_RATE = 4;

const CONTROLLER_COMPONENT_TYPE = 'platform.components.AppController';

interface EditBoxState {
  shouldShowMoveToFooterButton: boolean;
}

class EditBox extends React.Component<EditBoxProps, EditBoxState> {
  static displayName = 'editBox';

  state: EditBoxState = { shouldShowMoveToFooterButton: false };

  UNSAFE_componentWillReceiveProps(newProps: AnyFixMe) {
    const selectedComponentsChanged = !newProps.isSameRef(
      this.props.comps,
      newProps.comps,
    );

    if (
      this.props.isResizing ||
      this.props.isRotating ||
      this.props.isPinMode ||
      selectedComponentsChanged
    ) {
      this.setState({ shouldShowMoveToFooterButton: false });
    } else if (newProps.isDragging) {
      this.setState({ shouldShowMoveToFooterButton: true });
    }
  }

  onDoubleClick = (event: AnyFixMe) => {
    if (this.props.isMultiSelectKeyPressed(event)) {
      return;
    }

    const params = {
      evt: event,
      comp: this.props.comps,
    };
    this.props.doubleClick(params);
  };

  moveToFooter = (event: AnyFixMe) => {
    this.props.moveToFooter(this.props.compsWithoutSelectedAncestors);
    this.props.biEvent(coreBi.events.editBox.MOVE_TO_FOOTER, {
      component_type: this.props.compType,
    });
    this.setState({ shouldShowMoveToFooterButton: false });
    event.stopPropagation();
    let dragToFooterCount =
      this.props.getSessionUserPreferences(
        constants.USER_PREFS.DRAG_TO_FOOTER.DRAG_COUNT,
      )(this.props.getState()) || 0;

    if (dragToFooterCount % ATTACH_TO_FOOTER_NOTIFICATION_RATE === 0) {
      const onNotificationLinkClick = () => {
        this.props.openHelpCenter(helpIds.NOTIFICATIONS.ATTACH_TO_FOOTER);
      };
      this.props.showUserActionNotification({
        message: 'Notifications_Attached_Footer_Text',
        title: 'Notifications_Attached_Footer_Text',
        type: 'info',
        link: {
          caption: 'Notifications_Learn_More_Link',
          onNotificationLinkClick,
        },
      });
    }
    dragToFooterCount += 1;
    this.props.setSessionUserPreferences(
      constants.USER_PREFS.DRAG_TO_FOOTER.DRAG_COUNT,
      dragToFooterCount,
    );
  };

  getLinkedToTooltipId = (compData: AnyFixMe) => {
    if (!compData) {
      return 'linkedTo';
    }
    return `linkedTo-${compData.id}`;
  };

  getLinkDescriptionFromData = (compData: AnyFixMe) => {
    if (!compData) {
      return '';
    }
    const linkedToPrefix = translate('mobile_link_notification_tooltip');
    const linkedToValue = this.props.compLinkValue;

    return React.createElement(baseUI.popoverTemplates.iconAndText, {
      text: `${linkedToPrefix} ${linkedToValue}`,
      icon: 'mobileCompWithLink',
      shouldTranslate: false,
    });
  };

  shouldShowLinkedToTooltip = () => {
    const hasLink = !!(this.props.compsData && this.props.compsData.link);
    const addingElementInProgress =
      this.props.reAddedHiddenElement?.addingPhase !==
      constants.LEFT_BAR.MOBILE_HIDDEN_ELEMENTS.PROGRESS.IDLE;

    return (
      this.props.isMobile &&
      !this.props.isDuringMouseAction &&
      !this.props.isMultiselect &&
      hasLink &&
      !addingElementInProgress
    );
  };

  getApplyModeFromClipboardSuggestion = () => {
    const props = {
      text: 'HOVER_BOX_PASTE_FROM_CLIPBOARD_MSG_TEXT',
      linkActionText: 'HOVER_BOX_PASTE_FROM_CLIPBOARD_MSG_BUTTON',
    };
    return React.createElement(applyModeFromClipboardSuggestion, props);
  };

  shouldShowDragBounding = () => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/every
    const areSelectedComponentsControllers = _.every(
      this.props.compTypes,
      function (type) {
        return type === CONTROLLER_COMPONENT_TYPE;
      },
    );
    return (
      ((this.props.parentRestrictions as AnyFixMe)?.movable ||
        this.props.possibleLayoutActionsMap.movable) &&
      !areSelectedComponentsControllers
    );
  };

  shouldShowConnectionIndication = () => {
    return this.props.isConnectedToDataBinding && !this.props.isDragging;
  };

  isSiteHeaderSelected = (compPointer: AnyFixMe) => {
    return compPointer.id === 'SITE_HEADER';
  };

  shouldShowMobileReAddedBubble = () => {
    const compId = this.props.reAddedHiddenElement?.compRef?.id;
    const isCompSelected = !!compId && _.head(this.props.comps)?.id === compId;
    const addingElementFinished =
      this.props.reAddedHiddenElement?.addingPhase ===
      constants.LEFT_BAR.MOBILE_HIDDEN_ELEMENTS.PROGRESS.FINISH;

    return this.props.isMobileEditor && isCompSelected && addingElementFinished;
  };

  getNicknameDisplay = (nickname: AnyFixMe) => {
    return nickname ? `#${nickname}` : null;
  };

  getComponentNickname = () => {
    return this.getNicknameDisplay(this.props.compNickname);
  };

  getSecondaryNickname = () => {
    return this.getNicknameDisplay(this.props.compSecondaryNickname);
  };

  getInnerNickname = () => {
    return this.getNicknameDisplay(this.props.compInnerNickname);
  };

  getComponentLabelsInlineStyles = () => {
    if (this.props.isSiteSegmentComponent) {
      return {
        left: this.props.siteSegmentsLabelsOffset,
      };
    }
    if (this.props.hasLabelOffset) {
      return {
        left: this.props.labelsOffset,
      };
    }
  };

  getTooltipProps = () => {
    if (this.shouldShowMobileReAddedBubble()) {
      return {
        id: 'reAddedMobileCompRef',
        key: 'reAddedMobileCompRef',
        content: 'mobile_hidden_items_show_success_tooltip',
        alignment: 'RIGHT',
        isOpen: true,
        openOnMouseEnter: false,
        closeOnMouseLeave: false,
      };
    }

    if (this.props.applyModeFromClipboardSuggestion) {
      const isOpen = this.props.isSameRef(
        this.props.comps,
        this.props.applyModeFromClipboardSuggestion.selectedComponents,
      );
      return {
        id: 'applyModeFromClipboardSuggestion',
        key: 'applyModeFromClipboardSuggestion',
        content: this.getApplyModeFromClipboardSuggestion(),
        onOuterClick: this.props.hideApplyModeFromClipboardSuggestion,
        maxWidth: 320,
        isOpen,
        alignment: 'LEFT',
      };
    }

    return {
      isOpen: false,
      openOnMouseEnter: false,
    };
  };

  shouldAddContainerInteraction = (): boolean => {
    return (
      this.props.compInteraction &&
      this.props.compTypes[0] === constants.COMP_TYPES.CONTAINER
    );
  };

  isRootComponent = (editorAPI: AnyFixMe, compPointer: AnyFixMe) => {
    return (
      compPointer.id === 'SITE_FOOTER' ||
      compPointer.id === 'SITE_HEADER' ||
      compPointer.id === editorAPI.dsRead.pages.getFocusedPageId()
    );
  };

  shouldRenderInteractionNavs = (): boolean => {
    return (
      !this.props.isDragging &&
      !this.props.isResizing &&
      !this.props.isMobile &&
      this.props.comps?.length === 1 &&
      !this.props.isFocusBoxOverSelected &&
      !!this.props.compInteraction
    );
  };

  shouldRenderSubComps() {
    const { isDragging, isResizing } = this.props;

    if (isSectionsOnStageEnabled() || sections.isSectionsEnabled()) {
      return !(isDragging || isResizing);
    }

    return !isDragging;
  }

  render() {
    const selectedComponents = this.props.comps || [];
    const selectedComponentsData = this.props.compsData;
    const shouldRenderInteractionNavs = this.shouldRenderInteractionNavs();

    if (selectedComponents.length === 0) {
      return null;
    }

    const shouldShowPositionIndicator = this.shouldShowDragBounding();
    const shouldShowSizeIndicator =
      this.props.possibleLayoutActionsMap.resizable ||
      this.props.possibleLayoutActionsMap.resizeOnlyProportionally;

    return (
      <div
        onContextMenu={this.props.onContextMenu}
        onDoubleClick={this.onDoubleClick}
        className={this.props.editBoxClasses}
      >
        {this.shouldRenderSubComps() && <EditBoxSubComps />}

        <GroupSelectionFrame />
        {/* <EditBoxPadding/> */}

        <LayoutBoundingBoxPreloader />

        <Tooltip {...(this.getTooltipProps() as AnyFixMe)}>
          <LayoutBoundingBox componentUIColor={this.props.componentUIColor}>
            {shouldShowPositionIndicator && <LayoutPositionIndicator />}
            {shouldShowSizeIndicator && <LayoutSizeIndicator />}
            {snapToUtils.isNewStageGuidesEnabled() && <LayoutWidthIndicator />}
          </LayoutBoundingBox>
        </Tooltip>

        {!this.props.isDragging && (
          <UI.tooltip
            id={this.getLinkedToTooltipId(selectedComponentsData)}
            key={this.getLinkedToTooltipId(selectedComponentsData)}
            value={this.getLinkDescriptionFromData(selectedComponentsData)}
            alignment="right"
            delay="500"
            width="260px"
            marginsFromElement={9}
            disabled={!this.shouldShowLinkedToTooltip()}
            shouldTranslate={false}
            openTriggers={[
              constants.UI.TOOLTIP.TRIGGERS.IMMEDIATE,
              constants.UI.TOOLTIP.TRIGGERS.MOUSE_ENTER,
            ]}
          >
            <LayoutBox
              componentUIColor={this.props.componentUIColor}
              isSiteSegmentComponent={this.props.isSiteSegmentComponent}
              isSectionLikeBehaviour={this.props.isSectionLikeBehaviour}
              shouldAddContainerInteraction={this.shouldAddContainerInteraction()}
            >
              {selectedComponents.length === 1 &&
                !shouldRenderInteractionNavs &&
                !this.props.isFocusBoxHasViewState &&
                !this.props.isSectionLikeBehaviour && (
                  <ComponentLabels
                    key="compLabels"
                    isMobileOnlyNonNativeComponent={
                      this.props.isMobileOnlyNonNativeComponent
                    }
                    openHelpCenter={(...args: AnyFixMe[]) =>
                      this.props.openHelpCenter(...args)
                    }
                    showComponentType={this.props.shouldShowCompDisplayName}
                    shouldShowConnectionIndication={this.shouldShowConnectionIndication()}
                    showComponentNickname={
                      this.props.isDeveloperModeEnabled &&
                      !this.props.isDuringMouseAction
                    }
                    infoText="PLATFORM_ONSTAGE_CONNECTED_ELEMENT_LABEL"
                    isLabelLarge={this.props.isSiteSegmentComponent}
                    isLabelBelowLine={this.isSiteHeaderSelected(
                      selectedComponents[0],
                    )}
                    canDragFromDisplayNameLabel={this.props.draggable}
                    onDisplayNameLabelMouseDown={
                      this.props.onDisplayNameLabelMouseDown
                    }
                    componentUIColor={this.props.componentUIColor}
                    componentUITheme={constants.COMPONENT_UI_THEMES.DARK}
                    componentType={this.props.compDisplayName}
                    componentNickname={this.getComponentNickname()}
                    secondaryNickname={this.getSecondaryNickname()}
                    innerNickname={this.getInnerNickname()}
                    labelInlineStyles={this.getComponentLabelsInlineStyles()}
                    isDimmed={this.props.dimCompLabels}
                    isLabelBottom={this.props.isLabelBottom}
                  />
                )}

              {shouldRenderInteractionNavs && (
                <InteractionNavControls
                  compRef={selectedComponents[0]}
                  componentUIColor={this.props.componentUIColor}
                  considerCompControlsPosition={this.shouldAddContainerInteraction()}
                  handleDragMouseDown={this.props.onDisplayNameLabelMouseDown}
                  compInteraction={this.props.compInteraction}
                />
              )}

              <KnobsBox
                isResizing={this.props.isResizing}
                isDragging={this.props.isDragging}
                isDuringMouseAction={this.props.isDuringMouseAction}
              />

              {this.props.possibleLayoutActionsMap.rotatable && (
                <LayoutRotationIndicator />
              )}
              {this.props.editBoxComponents.map((slot) => (
                <slot.contribution key={slot.uniqueId} />
              ))}
            </LayoutBox>
          </UI.tooltip>
        )}

        {this.state.shouldShowMoveToFooterButton && ( //make relative to top comp
          <LayoutMoveToFooter onMouseDown={this.moveToFooter} />
        )}
      </div>
    );
  }
}

const ConnectedComponent = _.flow(
  withRestrictions('rEditor_edit-box'),
  hoc.connect(
    hoc.STORES.MOUSE_OPS,
    editBoxMapper.mapStateToProps,
    editBoxMapper.mapDispatchToProps,
  ),
)(EditBox);

ConnectedComponent.pure = EditBox;

export default ConnectedComponent;
