import React, { Component, type ComponentType } from 'react';
import ReactDOM from 'react-dom';
import _ from 'lodash';

import type { PositionStyle } from '../dropDownTypes';

const ARROW_HEIGHT = 8;

function toCSSProps(position: Position): PositionStyle {
  return _.mapValues(position, (val) => `${val}px`);
}

interface Position {
  top: number;
  left: number;
}

interface NodeRect {
  top: number;
  bottom: number;
  left: number;
  right: number;
  height: number;
  width: number;
  x: number;
  y: number;
}

interface ViewportRect {
  width: number;
  height: number;
}

interface WithCalcPanelPositionBehaviorProps {
  isOpen: boolean;

  alignPanelLeft?: boolean;
  alignPanelRight?: boolean;

  panelLeftShift?: number;

  panelTopMargin?: number;
  panelRightMargin?: number;
  panelLeftMargin?: number;
}

interface ComponentWithCalcPanelPositionBehaviorProps {
  anchorRef: (node: Node) => void;
  panelRef: (node: Node) => void;
  panelPositionStyle: PositionStyle;
  arrowPositionStyle: PositionStyle;
  contentPositionStyle: PositionStyle;
}

const withCalcPanelPositionBehavior = <
  P extends WithCalcPanelPositionBehaviorProps,
>(
  WrappedComponent: ComponentType<
    P & ComponentWithCalcPanelPositionBehaviorProps
  >,
) => {
  class WithCalcPanelPositionBehavior extends Component<WithCalcPanelPositionBehaviorProps> {
    static defaultProps = {
      panelLeftShift: 70,
      panelTopMargin: 4,
      panelRightMargin: 12,
      panelLeftMargin: 12,
    };

    state = {
      arrowPosition: undefined as AnyFixMe,
      contentPosition: undefined as AnyFixMe,
      panelPosition: undefined as AnyFixMe,
    };

    componentDidMount() {
      if (this.props.isOpen) {
        this.recalcPositions();
      }
    }

    componentDidUpdate(
      prevProps: Readonly<WithCalcPanelPositionBehaviorProps>,
    ) {
      if (this.props.isOpen && this.props.isOpen !== prevProps.isOpen) {
        this.recalcPositions();
      }
    }

    anchor: AnyFixMe = null;
    panel: AnyFixMe = null;

    panelRef = (node: AnyFixMe) => (this.panel = node);
    anchorRef = (node: AnyFixMe) => (this.anchor = node);

    recalcPositions = () => {
      if (!this.anchor || !this.panel) {
        return;
      }

      const panelPosition = this.calcPanelPosition();
      const arrowPosition = this.calcArrowPosition(panelPosition);
      const contentPosition = this.calcContentPosition();

      this.setState({
        arrowPosition,
        contentPosition,
        panelPosition,
      });
    };

    calcArrowPosition(panelPosition: Position): Position {
      const { panelTopMargin } = this.props;
      const { left: panelX } = panelPosition;
      const { width: anchorWidth, x: anchorX } = this.getAnchorRect();

      return {
        top: panelTopMargin + ARROW_HEIGHT,
        left: anchorX - panelX + anchorWidth / 2,
      };
    }

    calcContentPosition(): Position {
      const { panelTopMargin } = this.props;

      return {
        top: panelTopMargin + ARROW_HEIGHT,
        left: 0,
      };
    }

    calcPanelPosition(): Position {
      const { height: anchorHeight, y: anchorY } = this.getAnchorRect();

      // panel should be right under the anchor
      const top = anchorY + anchorHeight;
      const left = this.calcPanelPositionLeft();

      return {
        top,
        left,
      };
    }

    calcPanelPositionLeft(): number {
      const { width: anchorWidth, x: anchorX } = this.getAnchorRect();
      const { width: panelWidth } = this.getPanelRect();
      const { width: viewportWidth } = this.getViewportRect();
      const {
        panelLeftShift,
        panelRightMargin,
        panelLeftMargin,
        alignPanelLeft,
        alignPanelRight,
      } = this.props;

      let left = anchorX;

      if (alignPanelLeft) {
        // align panel with left edge of the anchor
        left += 0;
      } else if (alignPanelRight) {
        // align panel with right edge of the anchor
        left += anchorWidth - panelWidth;
      } else {
        // shift panel relative to the center of the anchor
        left += anchorWidth / 2 - panelLeftShift;
      }

      if (left + panelWidth >= viewportWidth) {
        // panel doesn't fit by right viewport border
        left = viewportWidth - (panelWidth + panelRightMargin);
      } else if (left <= 0) {
        // panel doesn't fit by left viewport border
        left = panelLeftMargin;
      }

      return left;
    }

    getAnchorRect = (): NodeRect => {
      const anchorDOMNode = ReactDOM.findDOMNode(this.anchor);
      // @ts-expect-error
      return anchorDOMNode.getBoundingClientRect();
    };

    getPanelRect = (): NodeRect => {
      const panelDOMNode = ReactDOM.findDOMNode(this.panel);
      // @ts-expect-error
      return panelDOMNode.getBoundingClientRect();
    };

    getViewportRect(): ViewportRect {
      return {
        width: document.documentElement.clientWidth,
        height: document.documentElement.clientHeight,
      };
    }

    render() {
      const { props } = this;
      const { panelPosition, arrowPosition, contentPosition } = this.state;

      return React.createElement(
        WrappedComponent,
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/assign
        _.assign({}, props as P, {
          anchorRef: this.anchorRef,
          panelRef: this.panelRef,
          panelPositionStyle: panelPosition && toCSSProps(panelPosition),
          arrowPositionStyle: arrowPosition && toCSSProps(arrowPosition),
          contentPositionStyle: contentPosition && toCSSProps(contentPosition),
          recalcPositions: () => this.recalcPositions(),
        }),
      );
    }
  }

  return WithCalcPanelPositionBehavior;
};

export default withCalcPanelPositionBehavior;
