// @ts-nocheck
import * as core from '@/core';
import { EditorAPIKey } from '@/apis';
import * as util from '@/util';
import * as stateManagement from '@/stateManagement';
import _ from 'lodash';
import constants from '@/constants';
import type { Shell } from '@/apilib';

const domainSelectors = stateManagement.domain.selectors;
const HAS_BEEN_ADI_KEY = 'hasSiteBeenADI';

export function createSiteApi(shell: Shell) {
  const editorAPI = shell.getAPI(EditorAPIKey);
  const { store } = editorAPI;
  const revisionsUrl =
    'https://editor.wix.com/_api/site-revisions-server/revisions/';

  function fixExperiments(allExperimentsString) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/split
    const experiments = _(allExperimentsString)
      .split(';')
      .filter(function (experimentsString) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/starts-with
        return _.startsWith(experimentsString, 'viewer');
      })
      .map(function (experimentsString) {
        return experimentsString.split('viewer:')[1];
      })
      .join(',');
    return experiments ? `viewer:${experiments}` : '';
  }

  function addParameters(url, parameterNames) {
    const urlUtils = util.url;
    parameterNames.forEach(function (parameterName) {
      let parameterValue = urlUtils.getParameterByName(parameterName);
      if (
        parameterValue &&
        (parameterName === 'experiments' ||
          parameterName === 'experimentsoff' ||
          parameterName === 'experimentsOff') &&
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/includes
        _.includes(parameterValue, ':')
      ) {
        parameterValue = fixExperiments(parameterValue);
      }
      url = parameterValue
        ? urlUtils.setUrlParam(url, parameterName, parameterValue)
        : url;
    });
    return url;
  }

  function getCurrentSiteDomain() {
    const windowLocationHostName = window.location.hostname;
    const domainMatch = windowLocationHostName.match(/editor\.(.*)/);

    // If could not extract domain, return default domain
    return domainMatch ? `www.${domainMatch[1]}` : 'www.wix.com';
  }

  function setSEOSiteName(value) {
    return editorAPI.dsActions.seo.title.set(value);
  }

  function setSEOSiteDescription(value) {
    return editorAPI.dsActions.seo.description.set(value);
  }

  function getSEOSiteKeywords() {
    return editorAPI.dsRead.seo.keywords.get();
  }

  function setSEOSiteKeywords(value) {
    return editorAPI.dsActions.seo.keywords.set(value);
  }

  function getMetaSiteId() {
    return editorAPI.dsRead.generalInfo.getMetaSiteId();
  }

  function isPremium() {
    return (
      _.chain(editorAPI.dsRead)
        .invoke('premiumFeatures.get')
        .get('length', 0)
        .value() > 0
    );
  }

  function getXPos() {
    return editorAPI.components.layout.getRelativeToScreen(
      editorAPI.dsRead.siteSegments.getPagesContainer(),
    ).x;
  }

  /**
   * DO NOT USE THIS METHOD WITHOUT APPROVAL - it is a premium services API that mustn't be used regularly during an editing session
   * @param onSuccess
   * @param onFail
   */
  function getSiteDomainInfo(
    onSuccess: (info: core.premiumServicesAPI.SiteDomainInfo) => void,
    onFail: (error?: unknown) => void,
  ) {
    const siteId = editorAPI.dsRead.generalInfo.getMetaSiteId();
    core.premiumServicesAPI.getSiteDomainInfo(siteId, onSuccess, onFail);
  }

  /**
   * @returns {number} the Premium Package code if the site is Premium, -1 otherwise.
   */
  function getSitePremiumPackage() {
    return store.getState().site.premiumPackage || -1;
  }

  function getSitePublicUrl() {
    if (!editorAPI.dsRead) {
      return null;
    }
    const state = store.getState();
    let url =
      editorAPI.dsRead.generalInfo.getPublicUrl() ||
      domainSelectors.getFreeDomainPrefix(state, editorAPI.dsRead) +
        editorAPI.siteName.get();
    url = url.replace(
      /^https?/i,
      domainSelectors.getSiteProtocol(state, editorAPI.dsRead),
    );
    const isSchoolMode = stateManagement.schoolMode.selectors.isEnabled(
      editorAPI.store.getState(),
    );
    const paramsToAdd = isSchoolMode
      ? ['ReactSource', 'viewerexp']
      : [
          'ReactSource',
          'experiments',
          'experimentsoff',
          'experimentsOff',
          'viewerexp',
        ];

    return addParameters(url, paramsToAdd);
  }

  function getSiteWidth() {
    const siteBoundingRect = window.document
      .getElementById(constants.ROOT_COMPS.SELECTOR_ID.PREVIEW)
      .getBoundingClientRect();
    return siteBoundingRect.width;
  }

  function hasSiteBeenADI(): boolean {
    if (!editorAPI.dsRead) {
      return false;
    }
    const hasBeenADI =
      stateManagement.userPreferences.selectors.getSiteUserPreferences(
        HAS_BEEN_ADI_KEY,
      )(store.getState());
    return (
      hasBeenADI ||
      editorAPI.dsRead.generalInfo.isSiteFromOnBoarding() ||
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/is-undefined
      !_.isUndefined(util.editorModel.onboardingEditorUrl)
    );
  }

  function updateStateIfNeeded(canRestoreToAdi) {
    if (store.getState().canRestoreToAdi !== canRestoreToAdi) {
      editorAPI.updateState({
        canRestoreToAdi,
      });
    }
  }

  function init() {
    if (hasSiteBeenADI()) {
      const url = `${revisionsUrl}can-restore-to-adi/${editorAPI.dsRead.generalInfo.getSiteId()}/`;
      util.http.fetchJson(url).then((response) => {
        updateStateIfNeeded(response.canRestoreToAdi);
      });
    } else {
      updateStateIfNeeded(false);
    }
  }

  function canRestoreToADI() {
    return store.getState().canRestoreToAdi;
  }

  function restoreToADI() {
    const url = `${revisionsUrl}restore-to-latest-adi/${editorAPI.dsRead.generalInfo.getSiteId()}/`;
    return util.http.fetchJson(url, { method: 'POST' });
  }

  function renameRevision(revisionNumber: number, name: string) {
    const metaSiteInstance =
      editorAPI.dsRead.platform.getAppDataByApplicationId(
        constants.APPLICATIONS.META_SITE_APPLICATION_ID,
      ).instance;

    const url = `${revisionsUrl}rename/${editorAPI.dsRead.generalInfo.getSiteId()}/${revisionNumber}-editor`;

    return util.http.fetch(url, {
      method: 'POST',
      headers: new Headers({
        'content-type': 'application/json; charset=utf-8',
        Authorization: metaSiteInstance,
      }),
      body: JSON.stringify({ name }),
    });
  }

  return {
    getCurrentSiteDomain,
    setSEOSiteName,
    setSEOSiteDescription,
    getSEOSiteKeywords,
    setSEOSiteKeywords,
    getMetaSiteId,
    isPremium,
    getXPos,
    getSiteDomainInfo,
    getSitePremiumPackage,
    getSitePublicUrl,
    getSiteWidth,
    hasSiteBeenADI,
    init,
    canRestoreToADI,
    restoreToADI,
    renameRevision,
  };
}
