import React, { useState, useRef, useEffect } from 'react';
import {
  SortByDragList,
  SortByDragListCustomItemRow,
  CollapseLabel,
  Divider,
  Text,
} from '@wix/wix-base-ui';
import type { AppData } from '../privateAppsProvider/privateAppsProvider';
import { GreyLabel } from '../helperComponents/helperComponents';
import { useSelectedApp } from '../selectedAppContext/selectedAppContext';
import { TEST_VERSION } from '../../utils/appDetailsUtils';
import styles from './appList.scss';
import * as util from '@/util';

interface AppListProps {
  apps: AppData[];
  renderActions: (app: AppData) => React.ReactElement;
  label: string;
  emptyStateLabel?: string;
  dataHook: string;
  className?: string;
  topDivider?: boolean;
  rowParams?: (
    app: AppData,
  ) => Partial<React.ComponentProps<typeof SortByDragListCustomItemRow>>;
}

export const AppList: React.FC<AppListProps> = ({
  dataHook,
  label,
  emptyStateLabel,
  apps,
  renderActions,
  topDivider,
  className,
  rowParams = () => ({}),
}) => {
  const [listIsOpen, setListIsOpen] = useState(true);
  const { selectedApp, setSelectedApp } = useSelectedApp();

  const selectedAppRef = useRef(null);
  const isRefElementExist = !!selectedAppRef.current;

  useEffect(() => {
    selectedAppRef.current?.scrollIntoView?.();
  }, [isRefElementExist]);

  const onAppSelection = (selectableApps: any[]) => {
    const { id } = selectableApps.find(({ selected }: any) => selected);
    const app = apps.find(({ appDefinitionId }) => id === appDefinitionId);

    setSelectedApp(
      app,
      app?.appFields?.installedVersion === TEST_VERSION ||
        !util.appStudioUtils.isAppMajorVersionBuilt(app.latestVersion)
        ? TEST_VERSION
        : app.version,
    );
  };

  const getRowRef = (app: AppData) =>
    app.appDefinitionId === selectedApp?.app?.appDefinitionId
      ? selectedAppRef
      : null;

  return (
    <div className={className}>
      {topDivider && <Divider long={true} />}
      <CollapseLabel
        divider="none"
        dataHook={dataHook}
        label=""
        prefix={<Text weight="bold">{label}</Text>}
        shouldTranslate={false}
        isOpen={listIsOpen}
        customToggle={() => setListIsOpen(!listIsOpen)}
      >
        {apps.length ? (
          <SortByDragList
            stopPropagationOnContextMenuToggle={true}
            menuActionsOverrides={{
              toggleDefault: {
                enable: false,
              },
              duplicate: {
                enable: false,
              },
              toggleEditLabel: {
                enable: false,
              },
              toggleEditValue: {
                enable: false,
              },
              delete: {
                enable: false,
              },
            }}
            draggable={false}
            selectable={true}
            onChange={onAppSelection}
          >
            {apps.map((app) => (
              <SortByDragListCustomItemRow
                id={app.appDefinitionId}
                key={app.appDefinitionId}
                label={app.appDefinitionName}
                draggable={false}
                isSelected={
                  app.appDefinitionId === selectedApp.app?.appDefinitionId
                }
                {...rowParams(app)}
                customAction={
                  <>
                    <div className={styles.selectedRow} ref={getRowRef(app)} />
                    {renderActions(app)}
                  </>
                }
              />
            ))}
          </SortByDragList>
        ) : (
          <div>
            <Divider long />
            <div className={styles.emptyState}>
              <GreyLabel shouldTranslate={true}>{emptyStateLabel}</GreyLabel>
            </div>
            <Divider long />
          </div>
        )}
      </CollapseLabel>
    </div>
  );
};
