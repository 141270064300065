'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')

module.exports = {
    'type': addPanelDataConsts.SECTIONS_TYPES.PRESET,
    'hide': false,
    'title': 'add_section_label_document',
    'subNavigationTitle': 'add_section_label_document',
    'presetTitle': 'add_section_label_document',
    'tooltipTitle': 'add_section_label_document',
    "automationId": "add-panel-section-fileDownloadButtonsSection",
    'subNavigationHide': false,
    'showSectionHeader': true,
    'additionalBehaviours': {
        'labelMode': addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
        'infoIcon': addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
        'hoverImageAction': addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
        'iconEnabledForComps': {}
    },
    'props': {
        'image': 'addPanelData/sections/fileDownloadButtonsSection_en/fileDownloadButtonsSection_en.png',
        'imageHover': null,
        'items': [{
            'id': 'Other_File_Download_Buttons_1',
            'structure': {
                'type': 'Component',
                'skin': 'skins.viewer.documentmedia.DocumentMediaSkin',
                'layout': {
                    'width': 90,
                    'height': 132,
                    'x': 4,
                    'y': 12,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.viewer.components.documentmedia.DocumentMedia',
                'data': {
                    'type': 'Image',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'title': 'Templates PDF.pdf',
                    'uri': 'b105ec4f619343f1b5d7349c89796f07.png',
                    'description': '',
                    'width': 102,
                    'height': 168,
                    'alt': ''
                },
                'props': {
                    'type': 'DocumentMediaProperties',
                    'metaData': {'schemaVersion': '1.0'},
                    'showTitle': false
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'id': 'dm1',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {
                            'fnt': 'normal normal normal 15px/1.4em din-next-w01-light {color_14}',
                            'txt': '#2F2E2E',
                            'txth': '#A0A09F'
                        },
                        'propertiesSource': {'fnt': 'value', 'txt': 'value', 'txth': 'value'}
                    },
                    'componentClassName': '',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'system',
                    'skin': 'skins.viewer.documentmedia.DocumentMediaSkin'
                },
                'id': 'i6j7gjb2'
            },
            'preset': {
                'rect': {'width': 54, 'height': 86, 'x': 0, 'y': 0},
                'label': 'add_preset_Other_File_Download_Buttons_1',
                'tags': null
            }
        }, {
            'id': 'Other_File_Download_Buttons_2',
            'structure': {
                'type': 'Component',
                'skin': 'skins.viewer.documentmedia.DocumentMediaSkin',
                'layout': {
                    'width': 90,
                    'height': 140,
                    'x': 57,
                    'y': 10,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.viewer.components.documentmedia.DocumentMedia',
                'data': {
                    'type': 'Image',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'title': 'Templates PDF.pdf',
                    'uri': '6f26d0a4c07b48b49c11af4c5894db17.png',
                    'description': '',
                    'width': 102,
                    'height': 168,
                    'alt': ''
                },
                'props': {
                    'type': 'DocumentMediaProperties',
                    'metaData': {'schemaVersion': '1.0'},
                    'showTitle': false
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'id': 'dm1',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {
                            'fnt': 'normal normal normal 15px/1.4em din-next-w01-light {color_14}',
                            'txt': '#2F2E2E',
                            'txth': '#A0A09F'
                        },
                        'propertiesSource': {'fnt': 'value', 'txt': 'value', 'txth': 'value'}
                    },
                    'componentClassName': '',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'system',
                    'skin': 'skins.viewer.documentmedia.DocumentMediaSkin'
                },
                'id': 'i8sj4aab'
            },
            'preset': {
                'rect': {'width': 54, 'height': 86, 'x': 54, 'y': 0},
                'label': 'add_preset_Other_File_Download_Buttons_2',
                'tags': null
            }
        }, {
            'id': 'Other_File_Download_Buttons_3',
            'structure': {
                'type': 'Component',
                'skin': 'skins.viewer.documentmedia.DocumentMediaSkin',
                'layout': {
                    'width': 90,
                    'height': 132,
                    'x': 111,
                    'y': 12,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.viewer.components.documentmedia.DocumentMedia',
                'data': {
                    'type': 'Image',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'title': 'Templates PDF.pdf',
                    'uri': '3d897403347a458f995222f848201128.png',
                    'description': '',
                    'width': 102,
                    'height': 169,
                    'alt': ''
                },
                'props': {
                    'type': 'DocumentMediaProperties',
                    'metaData': {'schemaVersion': '1.0'},
                    'showTitle': false
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'id': 'dm1',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {
                            'fnt': 'normal normal normal 15px/1.4em din-next-w01-light {color_14}',
                            'txt': '#2F2E2E',
                            'txth': '#A0A09F'
                        },
                        'propertiesSource': {'fnt': 'value', 'txt': 'value', 'txth': 'value'}
                    },
                    'componentClassName': '',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'system',
                    'skin': 'skins.viewer.documentmedia.DocumentMediaSkin'
                },
                'id': 'i8sjaiw5'
            },
            'preset': {
                'rect': {'width': 54, 'height': 86, 'x': 108, 'y': 0},
                'label': 'add_preset_Other_File_Download_Buttons_3',
                'tags': null
            }
        }, {
            'id': 'Other_File_Download_Buttons_4',
            'structure': {
                'type': 'Component',
                'skin': 'skins.viewer.documentmedia.DocumentMediaSkin',
                'layout': {
                    'width': 90,
                    'height': 140,
                    'x': 167,
                    'y': 10,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.viewer.components.documentmedia.DocumentMedia',
                'data': {
                    'type': 'Image',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'title': 'Templates PDF.pdf',
                    'uri': '530aa01d8faf4d58a3e08e8a24c32836.png',
                    'description': '',
                    'width': 102,
                    'height': 168,
                    'alt': ''
                },
                'props': {
                    'type': 'DocumentMediaProperties',
                    'metaData': {'schemaVersion': '1.0'},
                    'showTitle': false
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'id': 'dm1',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {
                            'fnt': 'normal normal normal 15px/1.4em din-next-w01-light {color_14}',
                            'txt': '#2F2E2E',
                            'txth': '#A0A09F'
                        },
                        'propertiesSource': {'fnt': 'value', 'txt': 'value', 'txth': 'value'}
                    },
                    'componentClassName': '',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'system',
                    'skin': 'skins.viewer.documentmedia.DocumentMediaSkin'
                },
                'id': 'i8sjbr8p'
            },
            'preset': {
                'rect': {'width': 54, 'height': 86, 'x': 162, 'y': 0},
                'label': 'add_preset_Other_File_Download_Buttons_4',
                'tags': null
            }
        }, {
            'id': 'Other_File_Download_Buttons_5',
            'structure': {
                'type': 'Component',
                'skin': 'skins.viewer.documentmedia.DocumentMediaSkin',
                'layout': {
                    'width': 90,
                    'height': 138,
                    'x': 220,
                    'y': 11,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.viewer.components.documentmedia.DocumentMedia',
                'data': {
                    'type': 'Image',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'title': 'Templates PDF.pdf',
                    'uri': '22cfa12a6a8049fbadd58ac23b86ba3e.png',
                    'description': '',
                    'width': 102,
                    'height': 168,
                    'alt': ''
                },
                'props': {
                    'type': 'DocumentMediaProperties',
                    'metaData': {'schemaVersion': '1.0'},
                    'showTitle': false
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'id': 'dm1',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {
                            'fnt': 'normal normal normal 15px/1.4em din-next-w01-light {color_14}',
                            'txt': '#2F2E2E',
                            'txth': '#A0A09F'
                        },
                        'propertiesSource': {'fnt': 'value', 'txt': 'value', 'txth': 'value'}
                    },
                    'componentClassName': '',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'system',
                    'skin': 'skins.viewer.documentmedia.DocumentMediaSkin'
                },
                'id': 'i8sjgfrm'
            },
            'preset': {
                'rect': {'width': 54, 'height': 86, 'x': 216, 'y': 0},
                'label': 'add_preset_Other_File_Download_Buttons_5',
                'tags': null
            }
        }, {
            'id': 'Other_File_Download_Buttons_6',
            'structure': {
                'type': 'Component',
                'skin': 'skins.viewer.documentmedia.DocumentMediaSkin',
                'layout': {
                    'width': 90,
                    'height': 128,
                    'x': 273,
                    'y': 13,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.viewer.components.documentmedia.DocumentMedia',
                'data': {
                    'type': 'Image',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'title': 'Templates PDF.pdf',
                    'uri': '34579583b5104d7ab995142d5a799bcc.png',
                    'description': '',
                    'width': 102,
                    'height': 168,
                    'alt': ''
                },
                'props': {
                    'type': 'DocumentMediaProperties',
                    'metaData': {'schemaVersion': '1.0'},
                    'showTitle': false
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'id': 'dm1',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {
                            'fnt': 'normal normal normal 15px/1.4em din-next-w01-light {color_14}',
                            'txt': '#2F2E2E',
                            'txth': '#A0A09F'
                        },
                        'propertiesSource': {'fnt': 'value', 'txt': 'value', 'txth': 'value'}
                    },
                    'componentClassName': '',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'system',
                    'skin': 'skins.viewer.documentmedia.DocumentMediaSkin'
                },
                'id': 'i8sjghvh'
            },
            'preset': {
                'rect': {'width': 54, 'height': 86, 'x': 270, 'y': 0},
                'label': 'add_preset_Other_File_Download_Buttons_6',
                'tags': null
            }
        }, {
            'id': 'Other_File_Download_Buttons_7',
            'structure': {
                'type': 'Component',
                'skin': 'skins.viewer.documentmedia.DocumentMediaSkin',
                'layout': {
                    'width': 90,
                    'height': 140,
                    'x': 4,
                    'y': 91,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.viewer.components.documentmedia.DocumentMedia',
                'data': {
                    'type': 'Image',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'title': 'Templates PDF.pdf',
                    'uri': 'b8964aa7650d463fac3b77e0d00e8f12.png',
                    'description': '',
                    'width': 105,
                    'height': 141,
                    'alt': ''
                },
                'props': {
                    'type': 'DocumentMediaProperties',
                    'metaData': {'schemaVersion': '1.0'},
                    'showTitle': false
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'id': 'dm1',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {
                            'fnt': 'normal normal normal 15px/1.4em din-next-w01-light {color_14}',
                            'txt': '#2F2E2E',
                            'txth': '#A0A09F'
                        },
                        'propertiesSource': {'fnt': 'value', 'txt': 'value', 'txth': 'value'}
                    },
                    'componentClassName': '',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'system',
                    'skin': 'skins.viewer.documentmedia.DocumentMediaSkin'
                },
                'id': 'i8sk7tkq'
            },
            'preset': {
                'rect': {'width': 54, 'height': 77, 'x': 0, 'y': 86},
                'label': 'add_preset_Other_File_Download_Buttons_7',
                'tags': null
            }
        }, {
            'id': 'Other_File_Download_Buttons_8',
            'structure': {
                'type': 'Component',
                'skin': 'skins.viewer.documentmedia.DocumentMediaSkin',
                'layout': {
                    'width': 90,
                    'height': 140,
                    'x': 57,
                    'y': 91,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.viewer.components.documentmedia.DocumentMedia',
                'data': {
                    'type': 'Image',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'title': 'Templates PDF.pdf',
                    'uri': '757aeccaa7f4409b93913c55e36a194d.png',
                    'description': '',
                    'width': 105,
                    'height': 141,
                    'alt': ''
                },
                'props': {
                    'type': 'DocumentMediaProperties',
                    'metaData': {'schemaVersion': '1.0'},
                    'showTitle': false
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'id': 'dm1',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {
                            'fnt': 'normal normal normal 15px/1.4em din-next-w01-light {color_14}',
                            'txt': '#2F2E2E',
                            'txth': '#A0A09F'
                        },
                        'propertiesSource': {'fnt': 'value', 'txt': 'value', 'txth': 'value'}
                    },
                    'componentClassName': '',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'system',
                    'skin': 'skins.viewer.documentmedia.DocumentMediaSkin'
                },
                'id': 'i8sk3e8u'
            },
            'preset': {
                'rect': {'width': 54, 'height': 77, 'x': 54, 'y': 86},
                'label': 'add_preset_Other_File_Download_Buttons_8',
                'tags': null
            }
        }, {
            'id': 'Other_File_Download_Buttons_9',
            'structure': {
                'type': 'Component',
                'skin': 'skins.viewer.documentmedia.DocumentMediaSkin',
                'layout': {
                    'width': 90,
                    'height': 140,
                    'x': 111,
                    'y': 91,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.viewer.components.documentmedia.DocumentMedia',
                'data': {
                    'type': 'Image',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'title': 'Templates PDF.pdf',
                    'uri': 'a41192b40f324972a1d919a8350965f8.png',
                    'description': '',
                    'width': 105,
                    'height': 141,
                    'alt': ''
                },
                'props': {
                    'type': 'DocumentMediaProperties',
                    'metaData': {'schemaVersion': '1.0'},
                    'showTitle': false
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'id': 'dm1',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {
                            'fnt': 'normal normal normal 15px/1.4em din-next-w01-light {color_14}',
                            'txt': '#2F2E2E',
                            'txth': '#A0A09F'
                        },
                        'propertiesSource': {'fnt': 'value', 'txt': 'value', 'txth': 'value'}
                    },
                    'componentClassName': '',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'system',
                    'skin': 'skins.viewer.documentmedia.DocumentMediaSkin'
                },
                'id': 'i8sk21o3'
            },
            'preset': {
                'rect': {'width': 54, 'height': 77, 'x': 108, 'y': 86},
                'label': 'add_preset_Other_File_Download_Buttons_9',
                'tags': null
            }
        }, {
            'id': 'Other_File_Download_Buttons_10',
            'structure': {
                'type': 'Component',
                'skin': 'skins.viewer.documentmedia.DocumentMediaSkin',
                'layout': {
                    'width': 90,
                    'height': 140,
                    'x': 167,
                    'y': 91,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.viewer.components.documentmedia.DocumentMedia',
                'data': {
                    'type': 'Image',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'title': 'Templates PDF.pdf',
                    'uri': '40d80b8089c8418a961cb75d96d491f7.png',
                    'description': '',
                    'width': 105,
                    'height': 141,
                    'alt': ''
                },
                'props': {
                    'type': 'DocumentMediaProperties',
                    'metaData': {'schemaVersion': '1.0'},
                    'showTitle': false
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'id': 'dm1',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {
                            'fnt': 'normal normal normal 15px/1.4em din-next-w01-light {color_14}',
                            'txt': '#2F2E2E',
                            'txth': '#A0A09F'
                        },
                        'propertiesSource': {'fnt': 'value', 'txt': 'value', 'txth': 'value'}
                    },
                    'componentClassName': '',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'system',
                    'skin': 'skins.viewer.documentmedia.DocumentMediaSkin'
                },
                'id': 'i8sk40a9'
            },
            'preset': {
                'rect': {'width': 54, 'height': 77, 'x': 162, 'y': 86},
                'label': 'add_preset_Other_File_Download_Buttons_10',
                'tags': null
            }
        }, {
            'id': 'Other_File_Download_Buttons_11',
            'structure': {
                'type': 'Component',
                'skin': 'skins.viewer.documentmedia.DocumentMediaSkin',
                'layout': {
                    'width': 90,
                    'height': 140,
                    'x': 220,
                    'y': 91,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.viewer.components.documentmedia.DocumentMedia',
                'data': {
                    'type': 'Image',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'title': 'Templates PDF.pdf',
                    'uri': 'ac1fbe0592434c58889947de5714b3c7.png',
                    'description': '',
                    'width': 105,
                    'height': 141,
                    'alt': ''
                },
                'props': {
                    'type': 'DocumentMediaProperties',
                    'metaData': {'schemaVersion': '1.0'},
                    'showTitle': false
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'id': 'dm1',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {
                            'fnt': 'normal normal normal 15px/1.4em din-next-w01-light {color_14}',
                            'txt': '#2F2E2E',
                            'txth': '#A0A09F'
                        },
                        'propertiesSource': {'fnt': 'value', 'txt': 'value', 'txth': 'value'}
                    },
                    'componentClassName': '',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'system',
                    'skin': 'skins.viewer.documentmedia.DocumentMediaSkin'
                },
                'id': 'i8sk4iap'
            },
            'preset': {
                'rect': {'width': 54, 'height': 77, 'x': 216, 'y': 86},
                'label': 'add_preset_Other_File_Download_Buttons_11',
                'tags': null
            }
        }, {
            'id': 'Other_File_Download_Buttons_12',
            'structure': {
                'type': 'Component',
                'skin': 'skins.viewer.documentmedia.DocumentMediaSkin',
                'layout': {
                    'width': 90,
                    'height': 140,
                    'x': 272,
                    'y': 91,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.viewer.components.documentmedia.DocumentMedia',
                'data': {
                    'type': 'Image',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'title': 'Templates PDF.pdf',
                    'uri': '046956f7d55f4143a30b3b54669ee243.png',
                    'description': '',
                    'width': 105,
                    'height': 141,
                    'alt': ''
                },
                'props': {
                    'type': 'DocumentMediaProperties',
                    'metaData': {'schemaVersion': '1.0'},
                    'showTitle': false
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'id': 'dm1',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {
                            'fnt': 'normal normal normal 15px/1.4em din-next-w01-light {color_14}',
                            'txt': '#2F2E2E',
                            'txth': '#A0A09F'
                        },
                        'propertiesSource': {'fnt': 'value', 'txt': 'value', 'txth': 'value'}
                    },
                    'componentClassName': '',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'system',
                    'skin': 'skins.viewer.documentmedia.DocumentMediaSkin'
                },
                'id': 'i8sk57hv'
            },
            'preset': {
                'rect': {'width': 54, 'height': 77, 'x': 270, 'y': 86},
                'label': 'add_preset_Other_File_Download_Buttons_12',
                'tags': null
            }
        }, {
            'id': 'Other_File_Download_Buttons_13',
            'structure': {
                'type': 'Component',
                'skin': 'skins.viewer.documentmedia.DocumentMediaSkin',
                'layout': {
                    'width': 90,
                    'height': 90,
                    'x': 4,
                    'y': 177,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.viewer.components.documentmedia.DocumentMedia',
                'data': {
                    'type': 'Image',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'title': 'Templates PDF.pdf',
                    'uri': '868668fcb82649729f2ba1f915c117c2.png',
                    'description': '',
                    'width': 126,
                    'height': 126,
                    'alt': ''
                },
                'props': {
                    'type': 'DocumentMediaProperties',
                    'metaData': {'schemaVersion': '1.0'},
                    'showTitle': false
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'id': 'dm1',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {
                            'fnt': 'normal normal normal 15px/1.4em din-next-w01-light {color_14}',
                            'txt': '#2F2E2E',
                            'txth': '#A0A09F'
                        },
                        'propertiesSource': {'fnt': 'value', 'txt': 'value', 'txth': 'value'}
                    },
                    'componentClassName': '',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'system',
                    'skin': 'skins.viewer.documentmedia.DocumentMediaSkin'
                },
                'id': 'i8skb5fe'
            },
            'preset': {
                'rect': {'width': 54, 'height': 72, 'x': 0, 'y': 163},
                'label': 'add_preset_Other_File_Download_Buttons_13',
                'tags': null
            }
        }, {
            'id': 'Other_File_Download_Buttons_14',
            'structure': {
                'type': 'Component',
                'skin': 'skins.viewer.documentmedia.DocumentMediaSkin',
                'layout': {
                    'width': 90,
                    'height': 90,
                    'x': 57,
                    'y': 177,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.viewer.components.documentmedia.DocumentMedia',
                'data': {
                    'type': 'Image',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'title': 'Templates PDF.pdf',
                    'uri': '92c4dd28a56f40a19f8955528c2c93e7.png',
                    'description': '',
                    'width': 126,
                    'height': 126,
                    'alt': ''
                },
                'props': {
                    'type': 'DocumentMediaProperties',
                    'metaData': {'schemaVersion': '1.0'},
                    'showTitle': false
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'id': 'dm1',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {
                            'fnt': 'normal normal normal 15px/1.4em din-next-w01-light {color_14}',
                            'txt': '#2F2E2E',
                            'txth': '#A0A09F'
                        },
                        'propertiesSource': {'fnt': 'value', 'txt': 'value', 'txth': 'value'}
                    },
                    'componentClassName': '',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'system',
                    'skin': 'skins.viewer.documentmedia.DocumentMediaSkin'
                },
                'id': 'i8skcwox'
            },
            'preset': {
                'rect': {'width': 54, 'height': 72, 'x': 54, 'y': 163},
                'label': 'add_preset_Other_File_Download_Buttons_14',
                'tags': null
            }
        }, {
            'id': 'Other_File_Download_Buttons_15',
            'structure': {
                'type': 'Component',
                'skin': 'skins.viewer.documentmedia.DocumentMediaSkin',
                'layout': {
                    'width': 90,
                    'height': 90,
                    'x': 111,
                    'y': 177,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.viewer.components.documentmedia.DocumentMedia',
                'data': {
                    'type': 'Image',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'title': 'Templates PDF.pdf',
                    'uri': 'e138b4462c764c1590351d72f5c679e2.png',
                    'description': '',
                    'width': 126,
                    'height': 126,
                    'alt': ''
                },
                'props': {
                    'type': 'DocumentMediaProperties',
                    'metaData': {'schemaVersion': '1.0'},
                    'showTitle': false
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'id': 'dm1',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {
                            'fnt': 'normal normal normal 15px/1.4em din-next-w01-light {color_14}',
                            'txt': '#2F2E2E',
                            'txth': '#A0A09F'
                        },
                        'propertiesSource': {'fnt': 'value', 'txt': 'value', 'txth': 'value'}
                    },
                    'componentClassName': '',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'system',
                    'skin': 'skins.viewer.documentmedia.DocumentMediaSkin'
                },
                'id': 'i8skdqqr'
            },
            'preset': {
                'rect': {'width': 54, 'height': 72, 'x': 108, 'y': 163},
                'label': 'add_preset_Other_File_Download_Buttons_15',
                'tags': null
            }
        }, {
            'id': 'Other_File_Download_Buttons_16',
            'structure': {
                'type': 'Component',
                'skin': 'skins.viewer.documentmedia.DocumentMediaSkin',
                'layout': {
                    'width': 90,
                    'height': 90,
                    'x': 167,
                    'y': 177,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.viewer.components.documentmedia.DocumentMedia',
                'data': {
                    'type': 'Image',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'title': 'Templates PDF.pdf',
                    'uri': '8af818da3a0948ad87ebae6703d85917.png',
                    'description': '',
                    'width': 126,
                    'height': 126,
                    'alt': ''
                },
                'props': {
                    'type': 'DocumentMediaProperties',
                    'metaData': {'schemaVersion': '1.0'},
                    'showTitle': false
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'id': 'dm1',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {
                            'fnt': 'normal normal normal 15px/1.4em din-next-w01-light {color_14}',
                            'txt': '#2F2E2E',
                            'txth': '#A0A09F'
                        },
                        'propertiesSource': {'fnt': 'value', 'txt': 'value', 'txth': 'value'}
                    },
                    'componentClassName': '',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'system',
                    'skin': 'skins.viewer.documentmedia.DocumentMediaSkin'
                },
                'id': 'i8skexd4'
            },
            'preset': {
                'rect': {'width': 54, 'height': 72, 'x': 162, 'y': 163},
                'label': 'add_preset_Other_File_Download_Buttons_16',
                'tags': null
            }
        }, {
            'id': 'Other_File_Download_Buttons_17',
            'structure': {
                'type': 'Component',
                'skin': 'skins.viewer.documentmedia.DocumentMediaSkin',
                'layout': {
                    'width': 90,
                    'height': 90,
                    'x': 221,
                    'y': 177,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.viewer.components.documentmedia.DocumentMedia',
                'data': {
                    'type': 'Image',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'title': 'Templates PDF.pdf',
                    'uri': '9b634a7196314e4294d6c6437b64e6e5.png',
                    'description': '',
                    'width': 126,
                    'height': 126,
                    'alt': ''
                },
                'props': {
                    'type': 'DocumentMediaProperties',
                    'metaData': {'schemaVersion': '1.0'},
                    'showTitle': false
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'id': 'dm1',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {
                            'fnt': 'normal normal normal 15px/1.4em din-next-w01-light {color_14}',
                            'txt': '#2F2E2E',
                            'txth': '#A0A09F'
                        },
                        'propertiesSource': {'fnt': 'value', 'txt': 'value', 'txth': 'value'}
                    },
                    'componentClassName': '',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'system',
                    'skin': 'skins.viewer.documentmedia.DocumentMediaSkin'
                },
                'id': 'i8skf9w1'
            },
            'preset': {
                'rect': {'width': 54, 'height': 72, 'x': 216, 'y': 163},
                'label': 'add_preset_Other_File_Download_Buttons_17',
                'tags': null
            }
        }, {
            'id': 'Other_File_Download_Buttons_18',
            'structure': {
                'type': 'Component',
                'skin': 'skins.viewer.documentmedia.DocumentMediaSkin',
                'layout': {
                    'width': 90,
                    'height': 90,
                    'x': 272,
                    'y': 177,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.viewer.components.documentmedia.DocumentMedia',
                'data': {
                    'type': 'Image',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'title': 'Templates PDF.pdf',
                    'uri': '122dd3c4a1014a45862b59dd37ecbfd6.png',
                    'description': '',
                    'width': 126,
                    'height': 126,
                    'alt': ''
                },
                'props': {
                    'type': 'DocumentMediaProperties',
                    'metaData': {'schemaVersion': '1.0'},
                    'showTitle': false
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'id': 'dm1',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {
                            'fnt': 'normal normal normal 15px/1.4em din-next-w01-light {color_14}',
                            'txt': '#2F2E2E',
                            'txth': '#A0A09F'
                        },
                        'propertiesSource': {'fnt': 'value', 'txt': 'value', 'txth': 'value'}
                    },
                    'componentClassName': '',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'system',
                    'skin': 'skins.viewer.documentmedia.DocumentMediaSkin'
                },
                'id': 'i8skfpve'
            },
            'preset': {
                'rect': {'width': 54, 'height': 72, 'x': 270, 'y': 163},
                'label': 'add_preset_Other_File_Download_Buttons_18',
                'tags': null
            }
        }, {
            'id': 'Other_File_Download_Buttons_19',
            'structure': {
                'type': 'Component',
                'skin': 'skins.viewer.documentmedia.DocumentMediaSkin',
                'layout': {
                    'width': 98,
                    'height': 104,
                    'x': 4,
                    'y': 248,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.viewer.components.documentmedia.DocumentMedia',
                'data': {
                    'type': 'Image',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'title': 'Templates PDF.pdf',
                    'uri': '01e41f7b44864204b0360e2f07b85885.png',
                    'description': '',
                    'width': 132,
                    'height': 132,
                    'alt': ''
                },
                'props': {
                    'type': 'DocumentMediaProperties',
                    'metaData': {'schemaVersion': '1.0'},
                    'showTitle': false
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'id': 'dm1',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {
                            'fnt': 'normal normal normal 15px/1.4em din-next-w01-light {color_14}',
                            'txt': '#2F2E2E',
                            'txth': '#A0A09F'
                        },
                        'propertiesSource': {'fnt': 'value', 'txt': 'value', 'txth': 'value'}
                    },
                    'componentClassName': '',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'system',
                    'skin': 'skins.viewer.documentmedia.DocumentMediaSkin'
                },
                'id': 'i8skrva5'
            },
            'preset': {
                'rect': {'width': 54, 'height': 74, 'x': 0, 'y': 235},
                'label': 'add_preset_Other_File_Download_Buttons_19',
                'tags': null
            }
        }, {
            'id': 'Other_File_Download_Buttons_20',
            'structure': {
                'type': 'Component',
                'skin': 'skins.viewer.documentmedia.DocumentMediaSkin',
                'layout': {
                    'width': 98,
                    'height': 104,
                    'x': 57,
                    'y': 248,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.viewer.components.documentmedia.DocumentMedia',
                'data': {
                    'type': 'Image',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'title': 'Templates PDF.pdf',
                    'uri': 'b6e1bb43bb02466b8ee47d53002a46c9.png',
                    'description': '',
                    'width': 132,
                    'height': 132,
                    'alt': ''
                },
                'props': {
                    'type': 'DocumentMediaProperties',
                    'metaData': {'schemaVersion': '1.0'},
                    'showTitle': false
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'id': 'dm1',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {
                            'fnt': 'normal normal normal 15px/1.4em din-next-w01-light {color_14}',
                            'txt': '#2F2E2E',
                            'txth': '#A0A09F'
                        },
                        'propertiesSource': {'fnt': 'value', 'txt': 'value', 'txth': 'value'}
                    },
                    'componentClassName': '',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'system',
                    'skin': 'skins.viewer.documentmedia.DocumentMediaSkin'
                },
                'id': 'i8skrylt'
            },
            'preset': {
                'rect': {'width': 54, 'height': 74, 'x': 54, 'y': 235},
                'label': 'add_preset_Other_File_Download_Buttons_20',
                'tags': null
            }
        }, {
            'id': 'Other_File_Download_Buttons_21',
            'structure': {
                'type': 'Component',
                'skin': 'skins.viewer.documentmedia.DocumentMediaSkin',
                'layout': {
                    'width': 98,
                    'height': 104,
                    'x': 110,
                    'y': 248,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.viewer.components.documentmedia.DocumentMedia',
                'data': {
                    'type': 'Image',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'title': 'Templates PDF.pdf',
                    'uri': '48b375b096664c66bfa9e3c85ac8a649.png',
                    'description': '',
                    'width': 132,
                    'height': 132,
                    'alt': ''
                },
                'props': {
                    'type': 'DocumentMediaProperties',
                    'metaData': {'schemaVersion': '1.0'},
                    'showTitle': false
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'id': 'dm1',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {
                            'fnt': 'normal normal normal 15px/1.4em din-next-w01-light {color_14}',
                            'txt': '#2F2E2E',
                            'txth': '#A0A09F'
                        },
                        'propertiesSource': {'fnt': 'value', 'txt': 'value', 'txth': 'value'}
                    },
                    'componentClassName': '',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'system',
                    'skin': 'skins.viewer.documentmedia.DocumentMediaSkin'
                },
                'id': 'i8skrj1y'
            },
            'preset': {
                'rect': {'width': 54, 'height': 74, 'x': 108, 'y': 235},
                'label': 'add_preset_Other_File_Download_Buttons_21',
                'tags': null
            }
        }, {
            'id': 'Other_File_Download_Buttons_22',
            'structure': {
                'type': 'Component',
                'skin': 'skins.viewer.documentmedia.DocumentMediaSkin',
                'layout': {
                    'width': 98,
                    'height': 104,
                    'x': 163,
                    'y': 248,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.viewer.components.documentmedia.DocumentMedia',
                'data': {
                    'type': 'Image',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'title': 'Templates PDF.pdf',
                    'uri': 'f6b4ac956a6e4672949bbb24228440a8.png',
                    'description': '',
                    'width': 132,
                    'height': 132,
                    'alt': ''
                },
                'props': {
                    'type': 'DocumentMediaProperties',
                    'metaData': {'schemaVersion': '1.0'},
                    'showTitle': false
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'id': 'dm1',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {
                            'fnt': 'normal normal normal 15px/1.4em din-next-w01-light {color_14}',
                            'txt': '#2F2E2E',
                            'txth': '#A0A09F'
                        },
                        'propertiesSource': {'fnt': 'value', 'txt': 'value', 'txth': 'value'}
                    },
                    'componentClassName': '',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'system',
                    'skin': 'skins.viewer.documentmedia.DocumentMediaSkin'
                },
                'id': 'i8sks4z7'
            },
            'preset': {
                'rect': {'width': 54, 'height': 74, 'x': 162, 'y': 235},
                'label': 'add_preset_Other_File_Download_Buttons_22',
                'tags': null
            }
        }, {
            'id': 'Other_File_Download_Buttons_23',
            'structure': {
                'type': 'Component',
                'skin': 'skins.viewer.documentmedia.DocumentMediaSkin',
                'layout': {
                    'width': 98,
                    'height': 104,
                    'x': 216,
                    'y': 248,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.viewer.components.documentmedia.DocumentMedia',
                'data': {
                    'type': 'Image',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'title': 'Templates PDF.pdf',
                    'uri': '5ca40c7a51984a89800474220884a70b.png',
                    'description': '',
                    'width': 132,
                    'height': 132,
                    'alt': ''
                },
                'props': {
                    'type': 'DocumentMediaProperties',
                    'metaData': {'schemaVersion': '1.0'},
                    'showTitle': false
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'id': 'dm1',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {
                            'fnt': 'normal normal normal 15px/1.4em din-next-w01-light {color_14}',
                            'txt': '#2F2E2E',
                            'txth': '#A0A09F'
                        },
                        'propertiesSource': {'fnt': 'value', 'txt': 'value', 'txth': 'value'}
                    },
                    'componentClassName': '',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'system',
                    'skin': 'skins.viewer.documentmedia.DocumentMediaSkin'
                },
                'id': 'i8sks1jr'
            },
            'preset': {
                'rect': {'width': 54, 'height': 74, 'x': 216, 'y': 235},
                'label': 'add_preset_Other_File_Download_Buttons_23',
                'tags': null
            }
        }, {
            'id': 'Other_File_Download_Buttons_24',
            'structure': {
                'type': 'Component',
                'skin': 'skins.viewer.documentmedia.DocumentMediaSkin',
                'layout': {
                    'width': 98,
                    'height': 104,
                    'x': 269,
                    'y': 248,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.viewer.components.documentmedia.DocumentMedia',
                'data': {
                    'type': 'Image',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'title': 'Templates PDF.pdf',
                    'uri': '014973cdd416464cb7734db762b69aba.png',
                    'description': '',
                    'width': 132,
                    'height': 132,
                    'alt': ''
                },
                'props': {
                    'type': 'DocumentMediaProperties',
                    'metaData': {'schemaVersion': '1.0'},
                    'showTitle': false
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'id': 'dm1',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {
                            'fnt': 'normal normal normal 15px/1.4em din-next-w01-light {color_14}',
                            'txt': '#2F2E2E',
                            'txth': '#A0A09F'
                        },
                        'propertiesSource': {'fnt': 'value', 'txt': 'value', 'txth': 'value'}
                    },
                    'componentClassName': '',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'system',
                    'skin': 'skins.viewer.documentmedia.DocumentMediaSkin'
                },
                'id': 'i8sks7bw'
            },
            'preset': {
                'rect': {'width': 54, 'height': 74, 'x': 270, 'y': 235},
                'label': 'add_preset_Other_File_Download_Buttons_24',
                'tags': null
            }
        }],
        'compTypes': ['wysiwyg.viewer.components.documentmedia.DocumentMedia']
    },
    'help': {'hide': false, 'text': 'add_section_info_text_document'}
}
