import type { EntryPoint } from '@/apilib';
import {
  EditorAPIKey,
  EditorPermissionsApiKey,
  EditorRestrictionsApiKey,
} from '@/apis';
import { createMenuApi } from './menuWrapper';
import { MenuApiKey } from './publicApiKey';

export const MenuEntrypoint: EntryPoint = {
  name: 'MenuApi',
  declareAPIs: () => [MenuApiKey],
  getDependencyAPIs: () => [
    EditorAPIKey,
    EditorPermissionsApiKey,
    EditorRestrictionsApiKey,
  ],
  attach(shell) {
    shell.contributeAPI(MenuApiKey, () => createMenuApi(shell));
  },
  extend(shell) {
    const editorPermissionsApi = shell.getAPI(EditorPermissionsApiKey);
    const editorRestrictionsApi = shell.getAPI(EditorRestrictionsApiKey);

    editorRestrictionsApi.set(
      [
        'menu-panel_add-item.interactive',
        'menu-panel_menu-seo-settings.visible',
      ],
      () => editorPermissionsApi.has('CLASSIC-EDITOR.EDIT-DESIGN'),
    );
  },
};
