// @ts-nocheck
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as util from '@/util';
import { cx } from '@/util';
import experiment from 'experiment';
import * as core from '@/core';
import * as helpIds from '@/helpIds';

import React from 'react';
import * as compPanelInfra from '@/compPanelInfra';
import { translate } from '@/i18n';
import {
  NotificationBanner,
  TextButton,
  Divider,
  Text,
} from '@wix/wix-base-ui';
import { DesignPanelContent } from './designPanelContent';
import style from './designPanel.scss';

function verifyUniqueTitle(props) {
  if (props.title) {
    if (props.getTitle) {
      return new Error('Cannot provide both title and getTitle');
    }
    if (!_.isString(props.title)) {
      return new Error("Warning: Invalid prop 'title', expected a string.");
    }
  }
}

const panelType = 'designPanel';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'designPanel',
  mixins: [core.mixins.editorAPIMixin, util.addPanelHoverMixin],
  propTypes: {
    panelName: PropTypes.string.isRequired,
    style: PropTypes.object.isRequired,
    selectedComponent: PropTypes.array.isRequired,
    compType: PropTypes.string.isRequired,
    changePermanentState: PropTypes.func.isRequired,
    changeTempState: PropTypes.func,
    maintainOriginalState: PropTypes.func.isRequired,
    hideComponentSections: PropTypes.bool,
    getTitle: PropTypes.func,
    title: verifyUniqueTitle,
    getSections: PropTypes.func,
    getAdditionalElementProps: PropTypes.func,
    shouldHideContentOverflow: PropTypes.bool,
    withAdditionalBehaviors: PropTypes.bool,
    multiSelectedComponents: PropTypes.array,
    contentOnly: PropTypes.bool,
    hideCustomCSSSDivider: PropTypes.bool,
    hideCustomCSSSection: PropTypes.bool,
  },
  getTitle() {
    const editorAPI = this.getEditorAPI();
    if (this.props.getTitle) {
      return this.props.getTitle();
    }
    if (this.props.title) {
      return this.props.title;
    }
    return editorAPI.addPanel.getDesignPanelTitleByComp(
      this.props.compType,
      this.props.selectedComponent,
    );
  },
  getHelpId() {
    const editorAPI = this.getEditorAPI();

    if (editorAPI.selection.getSelectedComponents().length > 0) {
      const componentType = editorAPI.components.getType(
        editorAPI.selection.getSelectedComponents()[0],
      );
      const translatedHelpId = helpIds.UTILS.getComponentHelpId({
        panelType,
        componentType,
        isMobileEditor: editorAPI.isMobileEditor(),
      });

      if (translatedHelpId) {
        return translatedHelpId;
      }
    }

    if (this.props.helpId) {
      return this.props.helpId;
    }

    if (this.props.isCustomizeDesign) {
      return helpIds.UTILS.getCustomizeDesignHelpId(this.props.compType);
    }
    return helpIds.UTILS.getDesignHelpId(this.props.compType);
  },
  getDesignPanelContent() {
    return (
      <>
        {this.renderNotificationBannerIfNeeded()}
        <DesignPanelContent
          {...this.props}
          editorAPI={this.getEditorAPI()}
          enter={this.enter}
          leave={this.leave}
        />
        {this.renderCustomCSSSectionIfNeeded()}
      </>
    );
  },

  renderCustomCSSSectionIfNeeded() {
    const isCssEditingExperimentIsOpen = experiment.isOpen(
      'specs.wixcode.CSSEditingSupport',
    );
    const langCode = util.languages.getLanguageCode();
    const shouldRenderWarningBanner = this.hasCSSFiles();
    if (
      !shouldRenderWarningBanner &&
      isCssEditingExperimentIsOpen &&
      !this.props.hideCustomCSSSection
    ) {
      return (
        <>
          {!this.props.hideCustomCSSSDivider && <Divider long />}
          <div className={style.cssContainer}>
            <Text size="small" shouldTranslate={false}>
              {translate('DesignPanel_Custom_Designs_CSS_Label')}
            </Text>
            <TextButton
              as="a"
              href={`https://support.wix.com/${langCode}/article/editor-x-about-css-editing`}
              target="_black"
              size="small"
              shouldTranslate={false}
              underline="none"
            >
              {translate('DesignPanel_Custom_Designs_CSS_CTA')}
            </TextButton>
          </div>
        </>
      );
    }
    return null;
  },

  hasCSSFiles() {
    const CSS_APP_ID = '081cfa21-cdf6-4f50-9a1b-0f3722acfd8b';
    const PAGE = 'masterPage';
    return this.props.isCustomizeDesign &&
      this.getEditorAPI().platform.pageHasPlatformApp
      ? this.getEditorAPI().platform.pageHasPlatformApp(PAGE, CSS_APP_ID)
      : false;
  },

  renderNotificationBannerIfNeeded() {
    const shouldRenderWarningBanner = this.hasCSSFiles();
    if (shouldRenderWarningBanner) {
      return (
        <NotificationBanner
          text={translate('DesignPanel_Temporary_CSS_Notification')}
          skin="warning"
          shouldTranslate={false}
          multiline
        />
      );
    }
    return null;
  },

  render() {
    return this.props.contentOnly ? (
      this.getDesignPanelContent()
    ) : (
      <compPanelInfra.compPanelFrame
        id={panelType}
        title={this.getTitle()}
        shouldHideContentOverflow={this.props.shouldHideContentOverflow}
        helpId={this.getHelpId()}
        {...util.panelUtils.getFrameProps(this.props)}
        className={cx(
          this.props.class,
          util.panelUtils.getFrameProps(this.props)?.className,
        )}
      >
        {this.getDesignPanelContent()}
      </compPanelInfra.compPanelFrame>
    );
  },
});
