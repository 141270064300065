import React from 'react';
import { Composites, Preloader, TextLabel } from '@wix/wix-base-ui';

interface PreloaderProps {
  title: string;
}

const PreloaderComponent = ({ title }: PreloaderProps) => (
  <div className="preloader" data-hook="preloader">
    <Composites.Preloader height={300}>
      <Preloader className="medium" />
      <TextLabel type="T02" value={title} dataHook="preloader-title" />
    </Composites.Preloader>
  </div>
);

export default PreloaderComponent;
