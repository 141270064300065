import type { SectionPresetDefinition } from '../../../types';

export enum InnerRoutes {
  PromptCreator,
  Loader,
  Results,
}

export interface SectionCreatorPromptParams {
  categoryId: string;
  description: string;
  businessType: string;
  businessName: string;
}

export interface SectionCreatorCachedData
  extends Partial<SectionCreatorPromptParams> {
  sections?: SectionPresetDefinition[];
}

export interface SectionCreatorSuccessItem {
  createdSection: SectionPresetDefinition;
  queryOutput: SectionAiCreationQueryOutput;
}

export interface SectionCreatorSuccessList {
  createdSections: SectionPresetDefinition[];
  queryOutputs: SectionAiCreationQueryOutput[];
}

// TODO: reuse type from @/ai package
export interface TokenUsage {
  prompt_tokens: number;
  completion_tokens: number;
  total_tokens: number;
}

export interface SectionAiCreationQueryOutput {
  completionMetadata: {
    gptParamsVersion: string;
    promptsVersion: string;
    tokenUsage: TokenUsage[];
  };
  idMap: object;
  originalOutline: object;
  outline: object;
  retries: number;
  validationResults: object;
}

export enum InitialPanelStates {
  Settings = 'settings',
  Results = 'results',
}
