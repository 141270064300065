import * as stateManagement from '@/stateManagement';
import { SectionsMigrationApiKey } from '@/apis';
import { HELP_ID, PANEL_NAME } from './constants';

import type { MapDispatchToProps, ThunkAction } from 'types/redux';

export interface DispatchProps {
  closePanel: () => void;
  openHelpCenter: () => void;
  forceMigrate: () => void;
}

export const mapDispatchToProps: MapDispatchToProps<DispatchProps, any> = {
  closePanel: (): ThunkAction => (dispatch) => {
    dispatch(stateManagement.panels.actions.closePanelByName(PANEL_NAME));
  },
  openHelpCenter: (): ThunkAction => (dispatch) =>
    dispatch(stateManagement.panels.actions.openHelpCenter(HELP_ID)),
  forceMigrate:
    (): ThunkAction =>
    (dispatch, getState, { host }) => {
      const sectionsMigrationApi = host.getAPI(SectionsMigrationApiKey);
      void sectionsMigrationApi.refreshWithForceMigration();
    },
};
