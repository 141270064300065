// @ts-nocheck
import _ from 'lodash';

let displayTime;

export default {
  componentDidMount() {
    displayTime = Date.now();
  },

  startNow() {
    this.sendPanelClosedBIEvent('startNow');
    this.getEditorAPI().panelManager.closePanelByName(this.props.panelName);
    this.onVideoClosed();
  },

  getContentClass() {
    return 'only-new';
  },

  sendBIEventWhenClosedByX() {
    this.sendPanelClosedBIEvent('xButton');
    this.onVideoClosed();
  },

  sendBIEventWhenClosedByESC() {
    this.sendPanelClosedBIEvent('ESC');
    this.onVideoClosed();
  },

  onVideoClosed() {
    if (_.isFunction(this.openTooltip)) {
      this.openTooltip();
    }
  },

  sendPanelClosedBIEvent(origin) {
    const editorAPI = this.getEditorAPI();
    const params = {
      site_id: editorAPI.dsRead.generalInfo.getSiteId(),
      duration: Date.now() - displayTime,
      origin,
      video_url: this.props.linkedVideoData,
      panel_name: this.constructor.displayName,
    };
    editorAPI.bi.event(this.getBIEvent(), params);
  },
};
