import { EditorAPIKey } from '@/apis';
import type { EditorAPI } from '@/editorAPI';
import type { Shell } from '@/apilib';

const GTM_EVENT_INTERVAL_HRS = 12;
const GTM_EVENT_NAME = 'Open Editor - Editor';
const GTM_STORAGE_KEY = 'gtmEditorLastEventTimeStamp';

declare global {
  interface Window {
    dataLayer?: { event: string }[];
  }
}

async function handleGtm(editorAPI: EditorAPI) {
  if (shouldSendGtmEvent(editorAPI)) {
    window.dataLayer.push({ event: GTM_EVENT_NAME });
    const userId = editorAPI.dsRead.generalInfo.getUserInfo().userId;
    localStorage.setItem(
      GTM_STORAGE_KEY,
      JSON.stringify({ [userId]: Date.now() }),
    );
  }
}

function shouldSendGtmEvent(editorAPI: EditorAPI) {
  if (!window.dataLayer) {
    return false;
  }
  const cachedValues = localStorage.getItem(GTM_STORAGE_KEY);
  if (!cachedValues) {
    return true;
  }
  const userId = editorAPI.dsRead.generalInfo.getUserInfo().userId;
  const userValue = JSON.parse(cachedValues)[userId];
  if (!userValue) {
    return true;
  }
  return Date.now() - userValue > GTM_EVENT_INTERVAL_HRS * 60 * 60 * 1000;
}

export const createGTMServiceApi = (shell: Shell) => {
  const editorAPI = shell.getAPI(EditorAPIKey);
  return {
    handleGtm: () => {
      handleGtm(editorAPI);
    },
  };
};
