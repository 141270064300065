const i18n = require("@/i18n");

module.exports = {
  id: "Stylable_StylableButton_4",
  preset: { rect: { width: 162, height: 70, x: 0, y: 150 }, tags: null },
  structure: {
    parent: "comp-knzvqsjj",
    style: {
      styleType: "custom",
      componentClassName: "wixui.StylableButton",
      style: {
        groups: {},
        properties: {
          "$st-css":
            ":import{\n    -st-from: 'wix-ui-santa/index.st.css';\n    -st-named: StylableButton\n}\n.root{\n    -st-extends: StylableButton;\n    transition: all 0.2s ease, visibility 0s;\n    background: rgba(0,0,0,0)\n}\n.root:disabled{\n    background: rgba(226,226,226,0)\n}\n.root:disabled::label{\n    color: #8F8F8F\n}\n.root:disabled::icon{\n    fill: #8F8F8F\n}\n.root::container{\n    transition: inherit\n}\n.root::label{\n    transition: inherit;\n    font-style: normal;\n    font-family: futura-lt-w01-book,sans-serif;\n    color: #221F7B;\n    font-size: 16px;\n    letter-spacing: 0.15em;\n    font-weight: 700\n}\n.root::icon{\n    transition: inherit;\n    width: 10px;\n    height: 10px;\n    fill: value(site_1_1);\n    display: none\n}\n.root:hover::label {\n    text-decoration-line: underline;\n}",
        },
        propertiesSource: { "$st-css": "value" },
      },
      pageId: "",
      compId: "",
      type: "ComponentStyle",
      metaData: {
        isHidden: false,
        sig: "7ud-80",
        pageId: "c1dmp",
        schemaVersion: "1.0",
        isPreset: false,
      },
      skin: "wixui.skins.Skinless",
    },
    activeModes: {},
    data: {
      type: "StylableButton",
      metaData: {
        isHidden: false,
        sig: "7ud-67",
        pageId: "c1dmp",
        schemaVersion: "1.0",
        isPreset: false,
      },
      label: i18n.translate("buttons_text_preset_view_all"),
      svgId: "b861b040274141de9c08999ec3b76edf.svg",
    },
    components: [],
    componentType: "wixui.StylableButton",
    id: "comp-kpmfescy",
    layout: {
      x: 11,
      fixedPosition: false,
      y: 166,
      scale: 1,
      height: 40,
      rotationInDegrees: 0,
      width: 142,
    },
    type: "Component",
    props: {
      type: "StylableButtonProperties",
      metaData: { schemaVersion: "1.0", sig: "7ud-33", pageId: "c1dmp" },
    },
    metaData: { sig: "5vr-2130", pageId: "c1dmp" },
    skin: "wixui.skins.Skinless",
  },
};
