:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._addSectionPanelCategoryAdditionalInfo_1og03_7 {
  width: 100%;
  height: 115px;
  background-color: #fff;
  display: flex;
  align-items: center;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 100;
  margin-bottom: 20px;
  box-shadow: 0 2px 7px rgba(23, 25, 28, 0.17); }
  ._addSectionPanelCategoryAdditionalInfo_noBottomIndent_1og03_19 {
    margin-bottom: 0; }

._addSectionPanelCategoryInfoText_1og03_22 {
  display: flex;
  flex-direction: column;
  max-width: 235px; }

._addSectionPanelCategoryInfoIcon_1og03_27 {
  width: 42px;
  height: 42px;
  margin-right: 18px;
  margin-left: 18px; }
  ._addSectionPanelCategoryInfoIcon_1og03_27 img {
    width: 100%;
    height: 100%;
    border-radius: 4px; }
