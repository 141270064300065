import React, { type FC } from 'react';
import { cx } from '@/util';
import { StageLayerSlotPlacement } from '@/extensionSlots';

import styles from './stageLayerExtensionSlot.scss';

export const StageLayerExtensionSlot: FC<{ isMobile: boolean }> = ({
  isMobile,
}) => {
  return (
    <StageLayerSlotPlacement>
      {(integrationProps) => (
        <div
          {...integrationProps}
          className={cx(styles.container, {
            [styles.mobile]: isMobile,
          })}
        />
      )}
    </StageLayerSlotPlacement>
  );
};
