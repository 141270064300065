// @ts-nocheck
import experiment from 'experiment';

import React from 'react';
import { Checkbox, Composites, InfoIcon } from '@wix/wix-base-ui';

export default class extends React.Component {
  static displayName = 'CheckboxCustomization';

  //remove once sv_blogLikeCounters is merged (also from rt-if)
  hasNeededExperiment = () => {
    if (this.props.experimentNeeded === undefined) {
      return true;
    }
    return experiment.isOpen(this.props.experimentNeeded);
  };

  createBooleanValueLink = () => {
    const { valueLink } = this.props;
    const invertedValue = {
      true: this.props.trueVal,
      false: this.props.falseVal,
    }[valueLink.value];
    const booleanValue = { true: true, false: false }[invertedValue];
    return {
      value: booleanValue,
      requestChange: function (newVal) {
        valueLink.requestChange(String(newVal) === String(this.props.trueVal));
      }.bind(this),
    };
  };

  render() {
    return this.hasNeededExperiment() ? (
      <Composites.Checkboxes key="toggleKey">
        <Composites.CheckboxWithInfoIcon>
          {this.props.infoText ? (
            <InfoIcon
              key="checkboxInfo"
              title={this.props.infoTitle}
              text={this.props.infoText}
            />
          ) : null}
          <Checkbox
            labelAfterSymbol={true}
            label={this.props.label}
            shouldTranslate={this.props.shouldTranslateLabel}
            valueLink={this.createBooleanValueLink()}
          />
        </Composites.CheckboxWithInfoIcon>
      </Composites.Checkboxes>
    ) : null;
  }
}
