import React from 'react';
import type { Scope } from './scope';
import { resizeHooks } from '@/layoutResize';
import { Component } from './resizeHighlight';
import { ResizeType } from './types';

export function init(scope: Scope) {
  const { workspace, resizeHandler, baseResizeAndPush } = scope;

  resizeHooks.onResizeStart.tap(resizeHandler.onResizeStart);
  resizeHooks.onResize.tap(() =>
    resizeHandler.onResizeMove({ resizeType: ResizeType.Resize }),
  );
  resizeHooks.onResizeEnd.tap(resizeHandler.onResizeEnd);

  baseResizeAndPush.hooks.resizeAndPushStart.tap(resizeHandler.onResizeStart);
  baseResizeAndPush.hooks.resizeAndPushOn.tap(() =>
    resizeHandler.onResizeMove({ resizeType: ResizeType.Push }),
  );
  baseResizeAndPush.hooks.resizeAndPushEnd.tap(resizeHandler.onResizeEnd);

  workspace.contributeStageComponent(() => <Component />);
}
