import { BaseEntryScope, createEntryPoint } from '@/apilib';
import { CustomAuthenticationApiKey } from './publicApiKey';
import { CustomAuthenticationApi } from './customAuthenticationAPI';

export class Scope extends BaseEntryScope {}

export const CustomAuthenticationEntryPoint = createEntryPoint({
  name: 'CustomAuthenticationEntryPoint',
  Scope,
  publicApi({ contributeApi }) {
    contributeApi(CustomAuthenticationApiKey, CustomAuthenticationApi);
  },
  async initialize() {},
});
