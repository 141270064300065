import React, { type FC } from 'react';
import { Composites, Illustration, RichText } from '@wix/wix-base-ui';
import { translate } from '@/i18n';
import { symbol as Symbol } from '@/baseUI';
import { workspace } from '@/util';
import { frames } from '@/panels';
import DeletePageInHierarchyMessage from './deletePageInHierarchyMessage';

const { MessagePanelFrame, OldMessagePanelFrame } = frames;

interface DeletePageMessageProps {
  panelName: string;
  onConfirm: () => void;
  onCancel: () => void;
  isPopup: boolean;
  pageTitle: string;
  isParentPage?: boolean;
  sitePublicUrl?: string;
  childPageTitles?: string[];
  hasDomain?: boolean;
}

const NewWorkspaceDeletePageMessage: React.FC<DeletePageMessageProps> = (
  props,
) => {
  const title = translate(
    props.isPopup
      ? 'Pages_Settings_Delete_Popup_Title'
      : 'Pages_Settings_Delete_Page_Title2',
  );
  const message = props.isPopup
    ? `${translate('Pages_Settings_Delete_Popup_Message')} ${props.pageTitle}`
    : translate('Pages_Settings_Delete_Page_Text', {
        page_name: props.pageTitle,
      });
  return props.isParentPage ? (
    <DeletePageInHierarchyMessage
      panelName={props.panelName}
      onConfirm={props.onConfirm}
      onCancel={props.onCancel}
      pageTitle={props.pageTitle}
      sitePublicUrl={props.sitePublicUrl}
      childPageTitles={props.childPageTitles}
      hasDomain={props.hasDomain}
    />
  ) : (
    <MessagePanelFrame
      panelName={props.panelName}
      dataHook="delete-page-message"
      theme="destructive"
      title={title}
      illustration={<Symbol name="deleteGeneralIcon_NewWorkspace" />}
      confirmLabel={translate('Pages_Settings_Delete_Page_Delete_Button')}
      cancelLabel={translate('Pages_Settings_Delete_Page_Cancel_Button')}
      onConfirm={props.onConfirm}
      onCancel={props.onCancel}
      confirmOnEnterPress
    >
      {message}
    </MessagePanelFrame>
  );
};

const DeletePageMessage: FC<DeletePageMessageProps> = ({
  isPopup,
  onCancel,
  onConfirm,
  pageTitle,
  panelName,
}) => (
  <OldMessagePanelFrame
    panelName={panelName}
    onConfirm={onConfirm}
    onCancel={onCancel}
    dataHook="delete-page-message"
    confirmLabel="Pages_Settings_Delete_Page_Delete"
    cancelLabel="Pages_Settings_Delete_Page_Cancel"
    type="danger"
    panelTitle={translate(
      isPopup
        ? 'Pages_Settings_Delete_Popup_Title'
        : 'Pages_Settings_Delete_Page_Title',
    )}
    className="delete-page-message"
  >
    <Composites.RichTextWithIllustration>
      <Illustration>
        <Symbol name="deleteGeneralIcon" />
      </Illustration>
      <Composites.RichText>
        <RichText type="T01">
          {`${translate(
            isPopup
              ? 'Pages_Settings_Delete_Popup_Message'
              : 'Pages_Settings_Delete_Page_Message',
          )} ${pageTitle}${translate(
            'Pages_Settings_Delete_Page_Message_End',
          )}`}
        </RichText>
      </Composites.RichText>
    </Composites.RichTextWithIllustration>
  </OldMessagePanelFrame>
);

const DeletePageMessageComponent = workspace.isNewWorkspaceEnabled()
  ? NewWorkspaceDeletePageMessage
  : DeletePageMessage;

export default DeletePageMessageComponent;
