import _ from 'lodash';

const biCancelOriginMapping = {
  header_close_button: 'X',
  escape_press: 'ESC',
};

// This function extracts the URL to be sent as origin.
// There are two types of settings URL's:
// * https://manage.wix.com/dashboard/external?appDefId=anAppDefId
// * https://manage.wix.com/dashboard/uuid/manage-website
// We send one or two versions falling back to sending the full settings URL.

export const extractOriginFromDashboardUrlForBi = (dashboardUrl: AnyFixMe) => {
  const url = new URL(dashboardUrl);
  const re = /dashboard\/[^\/]+\/([^\/|\?]+)/;
  return (
    url.searchParams.get('appDefId') ||
    _.get(re.exec(url.pathname), '[1]', dashboardUrl)
  );
};

export const mapBiOrigin = (origin: string) =>
  biCancelOriginMapping[origin as keyof typeof biCancelOriginMapping] || origin;
