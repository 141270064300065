// @ts-nocheck
import _ from 'lodash';
import * as stateManagement from '@/stateManagement';
import * as pageUtils from '../../../utils/pageUtils';
import constants from '../../../utils/constants';
import * as permissions from '../../../utils/permissions';
import * as util from '@/util';
const getEditorAPI = (dispatch, getState, { editorAPI }) => editorAPI;
const getPageData = (props) => props.pageData || props.menuItem.pageData;
const updatePage = (editorAPI, pageId, newPageData) =>
  editorAPI.pages.data.update(pageId, newPageData);
const getStaticSiteStructure = (editorAPI) =>
  editorAPI.pages.hierarchy.getSiteStructure();
const getPageId = (props) => {
  const pageData = getPageData(props);
  return pageData ? pageData.id : null;
};
const setTitle = (editorAPI, pageData, newTitle) => {
  const pageId = pageData.id;
  const newObjValue = { pageTitleSEO: newTitle };
  updatePage(editorAPI, pageId, newObjValue);
};
const setDescription = (editorAPI, pageData, newDesc) => {
  const pageId = pageData.id;
  const newObjValue = { descriptionSEO: newDesc };
  updatePage(editorAPI, pageId, newObjValue);
};
const isUriInputDisabled = (editorAPI, language) => {
  if (editorAPI.dsRead.multilingual?.isMLSlugExperimentOpen?.()) {
    return false;
  }
  const currentLanguage = language?.current;
  const originalLanguage = language?.original;
  return currentLanguage !== null && currentLanguage !== originalLanguage;
};
const debouncedUpdatePage = (editorAPI) =>
  _.debounce(function (pageId, newObjValue) {
    updatePage(editorAPI, pageId, newObjValue);
  }, 20);
const setIndexEnabled = (editorAPI, props, index) => {
  const pageId = getPageId(props);
  const newObj = { indexable: index };
  debouncedUpdatePage(editorAPI)(pageId, newObj);
};
const getValueFromPageData = (props, field) => {
  const pageData = getPageData(props);
  return pageData?.[field];
};
const isIndexEnabledFromPage = (props) =>
  getValueFromPageData(props, 'indexable');
const isIndexEnabledFromSite = () => util.editorModel.metaSiteData?.indexable;
const setUri = (editorAPI, props, value) => {
  const pageId = getPageId(props);
  const newObj = { pageUriSEO: value };
  debouncedUpdatePage(editorAPI)(pageId, newObj);
};
const getUri = (props) => getValueFromPageData(props, 'pageUriSEO');
const getDescriptionSEO = (props) =>
  getValueFromPageData(props, 'descriptionSEO');
const getPageName = (props) => getValueFromPageData(props, 'title');
const getOGImageFromPage = (props) => getValueFromPageData(props, 'ogImageRef');
const getOGImageFromSite = () => util.editorModel.metaSiteData?.ogImage;
const getSeoData = (editorAPI, props, lang) => {
  if (lang) {
    const pagePtr = editorAPI.pages.getCurrentPage();

    const translation = editorAPI.dsRead.multilingual.components.data.get(
      lang,
      pagePtr,
    );

    return translation?.advancedSeoData;
  }

  return getValueFromPageData(props, 'advancedSeoData') || '{}'; //TODO: Remove the default empty object
};
const isUriForbidden = (editorAPI, props) => (uri) =>
  editorAPI.pages.data.pageUriSEO.isForbidden(getPageId(props), uri);
const isUriDuplicate = (editorAPI, props) => (uri) =>
  editorAPI.pages.data.pageUriSEO.isDuplicate(getPageId(props), uri);
const getPageKeywordsSEO = (props) =>
  getValueFromPageData(props, 'metaKeywordsSEO');

const updateOGImage = (editorAPI, pageData, newOGImage) => {
  const pageId = pageData.id;
  const newObjValue = { ogImageRef: newOGImage };
  updatePage(editorAPI, pageId, newObjValue);
};

const updateData = (editorAPI, pageData, advancedSeoData /*, lang*/) => {
  const pageId = pageData.id;
  const newObjValue = { advancedSeoData };
  debouncedUpdatePage(editorAPI)(pageId, newObjValue);
  editorAPI.history.add('SEO data changed');
};

const updateParentPageId = (editorAPI, pageData, parentPageId) => {
  const pageId = pageData.id;
  const newObjValue = { parentPageId };
  debouncedUpdatePage(editorAPI)(pageId, newObjValue);
};

const updatePageKeywordsSEO = (editorAPI, pageData, metaKeywordsSEO) => {
  const pageId = pageData.id;
  const newObjValue = { metaKeywordsSEO };
  debouncedUpdatePage(editorAPI)(pageId, newObjValue);
};

const getHomePageTitle = (editorAPI) => {
  const homePageId = editorAPI.dsRead.homePage.get();
  return editorAPI.pages.data.get(homePageId).pageTitleSEO;
};
const getReducedAppPages = (editorAPI) => {
  return editorAPI.dsRead.pages.getPagesData().map((page) => ({
    tpaPageId: page.tpaPageId,
    pageUriSEO: page.pageUriSEO,
    title: page.title,
    managingAppDefId: page.managingAppDefId,
  }));
};
const getPrivacyType = (editorAPI, pageData) => {
  const privacyType = permissions.getProtectionType(editorAPI, pageData);
  switch (privacyType) {
    case constants.PERMISSION_STATES.PASSWORD: {
      return 'password';
    }
    case constants.PERMISSION_STATES.MEMBERS: {
      return 'members';
    }
    case constants.PERMISSION_STATES.NONE: {
      return 'everyone';
    }
  }
  return 'everyone';
};

const mapDispatchToProps = (dispatch, props) => {
  const editorAPI = dispatch(getEditorAPI);
  const pageData = getPageData(props);
  const currentPageId = editorAPI.dsRead.pages.getFocusedPageId();
  return {
    isUriForbidden: isUriForbidden(editorAPI, props),
    isUriDuplicate: isUriDuplicate(editorAPI, props),
    openHelpCenter: (helpItem) =>
      editorAPI.panelManager.openHelpCenter(helpItem, null, {}),
    openMediaStudio: (uri, callback) =>
      editorAPI.mediaServices.openMediaStudio(
        {
          initiator: 'editor-seo-social-panel',
          imageFileId: uri,
        },
        callback,
      ),
    getPageDataById: (pageId) => editorAPI.pages.data.get(pageId),
    updatePage: (pageId, newPageData) =>
      updatePage(editorAPI, pageId, newPageData),
    sendBI: (eventId, params) => editorAPI.bi.event(eventId, params),
    setMediaServicesFocusToEditor: () =>
      editorAPI.mediaServices.setFocusToEditor(),
    setTitle: (newTitle) => setTitle(editorAPI, pageData, newTitle),
    setDescription: (newDesc) => setDescription(editorAPI, pageData, newDesc),
    setIndexEnabled: (isEnabled) =>
      setIndexEnabled(editorAPI, props, isEnabled),
    setUri: (uri) => setUri(editorAPI, props, uri),
    setOGImage: (ogImage) => updateOGImage(editorAPI, pageData, ogImage),
    setData: (data) => updateData(editorAPI, pageData, data),
    setParentPageId: (parentPageId) =>
      updateParentPageId(editorAPI, pageData, parentPageId),
    getDataByCurrLang: () => getSeoData(editorAPI, props),
    getDataByLang: (lang) => getSeoData(editorAPI, props, lang),
    convertPageNameToUrl: editorAPI.pages.data.pageUriSEO.convertPageNameToUrl,
    setPageKeywordsSEO: (metaKeywordsSEO) =>
      updatePageKeywordsSEO(editorAPI, pageData, metaKeywordsSEO),
    sessionUserPreferencesuserPref:
      stateManagement.userPreferences.selectors.getSessionUserPreferences(
        `bg_panel_last_media_path_${currentPageId}`,
      )(editorAPI.store.getState()) || {},
  };
};
const mapStateToProps = ({ editorAPI, dsRead }, ownProps) => {
  const pageId = editorAPI.dsRead.pages.getFocusedPageId();
  const lang = dsRead.language.original.get();

  const language = {
    current: dsRead.language.current.get(),
    original: lang?.languageCode || lang,
  };
  const isSlashFormat = editorAPI.dsRead.generalInfo.urlFormat.isSlash();
  const pageData = getPageData(ownProps);
  const routerUrlPrefix =
    editorAPI.routers.getRouterDataForPageIfExist(pageId)?.prefix || undefined;

  return {
    pageId,
    language,
    title: dsRead.seo.title.get(),
    homePageTitle: getHomePageTitle(editorAPI),
    uri: getUri(ownProps),
    routerUrlPrefix,
    description: getDescriptionSEO(ownProps),
    pageName: getPageName(ownProps),
    isUriInputDisabled: isUriInputDisabled(editorAPI, language),
    isIndexEnabled: isIndexEnabledFromPage(ownProps),
    isIndexEnabledFromSite: isIndexEnabledFromSite(),
    isHomePage: editorAPI.pages.isHomePage(getPageId(ownProps)),
    appPages: getReducedAppPages(editorAPI),
    siteName: editorAPI.siteName.get(),
    siteDisplayName: editorAPI.store.getState().siteDisplayName,
    isSitePublished: editorAPI.dsRead.generalInfo.isSitePublished(),
    msid: editorAPI.dsRead.generalInfo.getMetaSiteId(),
    metasiteInstance: dsRead.platform.getAppDataByApplicationId(-666).instance,
    constants: {
      titleSeoMaxLength: constants.TITLE_SEO_MAX_LENGTH,
      uriSeoMaxLength: constants.USI_SEO_MAX_LENGTH,
      descriptionSeoMaxLength: constants.DESCRIPTION_SEO_MAX_LENGTH,
    },
    validators: {
      noHTMLTags: util.validate.noHTMLTags,
      noEmoji: util.validate.noEmoji,
      notEmptyString: util.validate.notEmptyString,
      invalidUrlCharacters: (url) =>
        editorAPI.dsRead.pages.data.pageUriSEO.isValid(pageId, url),
    },
    wixSeoUrl: editorAPI.account.getWixSEOWizURL('EDITOR_PAGES'),
    siteDashboardURL: editorAPI.account.getSiteDashboardURL(),
    mediaManager: editorAPI.mediaServices.mediaManager,
    publicURL: pageUtils.getPublicUrl(editorAPI, pageData),
    isSlashFormat,
    ogImage: getOGImageFromPage(ownProps),
    ogImageFromSite: getOGImageFromSite(),
    locale: util.editorModel.languageCode,
    pageKeywordsSEO: getPageKeywordsSEO(ownProps),
    staticsBaseUrl:
      util.serviceTopology.scriptsLocationMap['advanced-seo-client'],
    numPages: editorAPI.pages.getPageIdList().length,
    permission: getPrivacyType(editorAPI, pageData),
    staticSiteStructure: getStaticSiteStructure(editorAPI),
    isMLSlugExperimentOpen:
      editorAPI.dsRead.multilingual.isMLSlugExperimentOpen(),
    subscribeAfterPublish: (callback) =>
      editorAPI.publishManager.hooks.afterPublish.tap(() => callback()),
  };
};

export { mapStateToProps, mapDispatchToProps };
