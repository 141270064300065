import { createEntryPoint } from '@/apilib';

import { Scope } from './scope';
import { init } from './init';

export const EditorWelcomeScreensEntrypoint = createEntryPoint({
  name: 'EditorWelcomeScreensAPI',
  Scope,
  async initialize(scope) {
    await init(scope);
  },
});
