import * as stateManagement from '@/stateManagement';
import * as util from '@/util';
import { translate } from '@/i18n';

import * as galleriesData from './galleriesData';

import type { EditorAPI } from '@/editorAPI';
import type { CompRef } from 'types/documentServices';

function getGalleryDisplayName(
  editorAPI: EditorAPI,
  compRef: CompRef,
  compType: string,
): string {
  if (!galleriesData.map.hasOwnProperty(compType)) {
    return;
  }

  const compSkin = stateManagement.components.selectors.getSkin(
    compRef,
    editorAPI.dsRead,
  );

  const compSkinsMap =
    galleriesData.map[compType as keyof typeof galleriesData.map];
  const compSkinDisplayName =
    compSkin &&
    (Array.isArray(compSkinsMap)
      ? compSkinsMap.find((skins) => !!skins[compSkin])
      : compSkinsMap[compSkin as keyof typeof compSkinsMap])?.[compSkin];

  return compSkinDisplayName && translate(compSkinDisplayName);
}

function getPlatformDisplayName(editorAPI: EditorAPI, compRef: CompRef) {
  return stateManagement.components.selectors.getPlatformDisplayName(
    compRef,
    editorAPI.dsRead,
  );
}

export function getDisplayName(
  editorAPI: EditorAPI,
  compRef: CompRef,
  compTypeOverride?: string,
): string {
  const dsRead = editorAPI.dsRead;

  const mainCompRef = util.controlsUtils.getFirstControllableComponent(
    editorAPI,
    compRef,
  );
  const mainCompType: string =
    compTypeOverride || dsRead.components.getType(mainCompRef);

  const displayName =
    getGalleryDisplayName(editorAPI, mainCompRef, mainCompType) ||
    getPlatformDisplayName(editorAPI, mainCompRef);

  if (displayName) {
    return displayName;
  }

  if (typeof editorAPI.compNamePlugins[mainCompType] === 'function') {
    return editorAPI.compNamePlugins[mainCompType](mainCompRef);
  }

  const compDefinition = dsRead.components.getDefinition(mainCompType);

  return (
    // @ts-expect-error
    compDefinition?.translatedLabelDisplayName ||
    translate(
      compDefinition?.labelDisplayName ||
        `component_label_${mainCompType.split('.').pop()}`,
    )
  );
}
