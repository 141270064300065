// @ts-nocheck
import _ from 'lodash';
import tpaConstants from '../../constants/constants';
import * as addPanelAddCompService from '../addPanelAddCompService';

const SECTION_CATEGORY = 'members';

const registerSectionFactory = function (sectionGetter) {
  return function (editorAPI) {
    editorAPI.addPanel.registerSectionAtStart(
      SECTION_CATEGORY,
      _.partial(sectionGetter, editorAPI),
    );
  };
};

const onClickFactory = function (appDefinitionId, widgetId) {
  return function (
    compStructure,
    sectionTitle,
    tags,
    id,
    editorAPI,
    addOptions,
  ) {
    return addPanelAddCompService
      .addCompOnClick(
        editorAPI,
        appDefinitionId,
        widgetId,
        compStructure,
        tags,
        id,
        { biOrigin: tpaConstants.BI.type.ADD_WIDGET },
        addOptions,
      )
      .then((compRef) => {
        return compRef;
      });
  };
};

const onDropFactory = function (appDefinitionId, widgetId) {
  return function (layoutParams, compPreset, parentComponent, editorAPI) {
    return addPanelAddCompService.addCompOnDrop(
      editorAPI,
      appDefinitionId,
      widgetId,
      layoutParams,
      compPreset,
      parentComponent,
      () => {},
      { biOrigin: tpaConstants.BI.type.ADD_WIDGET },
    );
  };
};

export { registerSectionFactory, onClickFactory, onDropFactory };
