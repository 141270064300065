import React from 'react';
import type { ListCompItem } from '../interactionsEditBoxMapper';

const selectedBorderWidth = 1;

// eslint-disable-next-line react/display-name
export const SelectedOriginalLayout = React.forwardRef<
  HTMLDivElement,
  { selected: ListCompItem }
>(({ selected }, ref) => {
  const { calculatedLayout } = selected;
  const selectedOriginalLayoutStyle: React.CSSProperties = {
    top: calculatedLayout.y - selectedBorderWidth,
    left: calculatedLayout.x - selectedBorderWidth,
    width: calculatedLayout.width,
    height: calculatedLayout.height,
    transform: `rotate(${calculatedLayout.rotationInDegrees}deg)`,
  };

  return (
    <div
      ref={ref}
      className={'selected-original-layout'}
      style={selectedOriginalLayoutStyle}
    />
  );
});
