import constants from '@/constants';
import { workspace } from '@/util';

const isNewWorkspace = workspace.isNewWorkspaceEnabled();

export function getInteractionsLeftBarOverrides(isCotainable: boolean) {
  const overrides = [
    {
      key: constants.ROOT_COMPS.LEFTBAR.INTERACRIONS_HIDDEN_COMPONENTS,
      panelName:
        constants.ROOT_COMPS.LEFTBAR.HIDDEN_VARIANTS_ELEMENTS_PANEL_NAME,
      panelProps: {},
      label: constants.ROOT_COMPS.LEFTBAR.INTERACRIONS_HIDDEN_COMPONENTS,
      className: 'interactions-hidden-components',
      symbolName: isNewWorkspace ? 'leftBarButtonHidden' : 'hiddenComp',
      hintProps: {
        id: 'InteractionHiddenComponents',
      },
      __isExtraItem: true,
    },
  ];

  return isCotainable
    ? [
        {
          key: constants.ROOT_COMPS.LEFTBAR.ADD_ID,
          label: constants.ROOT_COMPS.LEFTBAR.INTERACRIONS_ADD_PANEL,
        },
        ...overrides,
      ]
    : overrides;
}
