import type { EntryPoint } from '@/apilib';
import { EditorAPIKey, SelectedComponentApiKey } from '@/apis';
import { createSelectedComponentApi } from './selectedComponentWrapper';

export const SelectedComponentEntrypoint: EntryPoint = {
  name: 'SelectedComponentApi',
  declareAPIs: () => [SelectedComponentApiKey],
  getDependencyAPIs: () => [EditorAPIKey],
  attach(shell) {
    shell.contributeAPI(SelectedComponentApiKey, () =>
      createSelectedComponentApi(shell),
    );
  },
};
