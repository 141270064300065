import React, { Component, type ComponentType } from 'react';
import _ from 'lodash';

import withDropDownOnHover from './withDropDownOnHover';
import withCustomDropPanel from './withCustomDropPanel';
import withOpenDropPanel from './withOpenDropPanel';

const withCustomDropPanelBehavior =
  (handlerKey: string) =>
  <P extends {}>(WrappedComponent: ComponentType<P>) => {
    class WithCustomDropPanelBehavior extends Component {
      render() {
        return React.createElement(WrappedComponent, this.props as P);
      }
    }

    return _.flow(
      withDropDownOnHover,
      withCustomDropPanel(handlerKey),
      withOpenDropPanel,
    )(WithCustomDropPanelBehavior);
  };

export default withCustomDropPanelBehavior;
