import type { EntryPoint } from '@/apilib';
import { EditorEventRegistryApiKey, EditorAPIKey } from '@/apis';
import { createPlatformApi } from './platformWrapper';
import { PlatformApiKey } from './publicApiKey';

export const PlatformEntrypoint: EntryPoint = {
  name: 'PlatformApi',
  declareAPIs: () => [PlatformApiKey],
  getDependencyAPIs: () => [EditorAPIKey, EditorEventRegistryApiKey],
  attach(shell) {
    shell.contributeAPI(PlatformApiKey, () => createPlatformApi(shell));
  },
};
