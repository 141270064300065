import _ from 'lodash';
import * as stateManagement from '@/stateManagement';
import * as coreBi from '@/coreBi';
import * as spotlight from './createNodeProps/spotlight';
import * as util from '@/util';
import { NOTIFICATIONS } from '@/helpIds';
import {
  hasComponentChildrenToShowInLayersPanel,
  getComponentChildrenToShowInLayersPanel_GENERATOR,
} from './createNodeProps/nodeChildrenGenerator';

import type { EditorAPI } from '@/editorAPI';
import type { CompRef, CompLayout } from 'types/documentServices';
import type { NodeProps } from './node';
import type { TreeNodesContext } from './createNodeProps/nodesContext';
import {
  getAllNodesEmptyStateContent,
  scrollToCompAndSelect,
  isComponentWithinPage,
} from './createNodeProps/createNodeProps.utils';
import experiment from 'experiment';

interface OutOfGridComps {
  [key: string]: boolean;
}

const moveToIndex = (
  editorAPI: EditorAPI,
  children: CompRef[],
  biData: { label: string },
  from: number,
  to: number,
) => {
  const state = editorAPI.store.getState();
  const panel_origin = stateManagement.layersPanel.selectors.getOrigin(state);
  editorAPI.components.arrangement.moveToIndex(children[from], to);

  editorAPI.store.dispatch(
    stateManagement.bi.actions.event(coreBi.events.layers.layer_drag, {
      previous_position_index: from,
      current_position_index: to,
      layer_name: biData.label,
      panel_origin,
      out_of_grid:
        !!editorAPI.components.layout.shouldShowOutOfGridlinesIndication(
          children[from],
        ),
    }),
  );
};

const toggleVisibility = (
  editorAPI: EditorAPI,
  compRef: CompRef,
  changeVisibilityFunc: Function,
) => {
  changeVisibilityFunc(compRef);
  editorAPI.dsRead.components.get
    .byAncestor(compRef)
    .forEach((childCompRef) => {
      changeVisibilityFunc(childCompRef);
    });

  spotlight.setSpotlightComponent(editorAPI, null);
};

const getComponentDisplayName = (
  editorAPI: EditorAPI,
  compRef: CompRef,
  level: number,
): string => {
  if (level === 0) {
    return editorAPI.components.getDisplayName(compRef);
  }

  const codeId =
    editorAPI.developerMode.isEnabled() &&
    editorAPI.components.getNickname(compRef);

  if (codeId) {
    return codeId;
  }

  return (
    util.componentLabel.getComponentLabel(editorAPI, compRef) ||
    editorAPI.components.getDisplayName(compRef)
  );
};

const isCompDescendantOfMobileMenu = (comp: CompRef, editorAPI: EditorAPI) => {
  const mobileMenuCompRef = editorAPI.components.get.byId('MENU_AS_CONTAINER');
  const isDescendantOfComp = editorAPI.components.isDescendantOfComp(
    comp,
    mobileMenuCompRef,
  );
  const isSameId = mobileMenuCompRef?.id === comp.id;
  return isDescendantOfComp || isSameId;
};

const handleMobileMenu = (editorAPI: EditorAPI, comp: CompRef) => {
  const menuIconCompRef = editorAPI.components.get.byId(
    'MENU_AS_CONTAINER_TOGGLE',
  );

  const target = util.inlinePopupUtils.getToggleTarget(
    editorAPI,
    menuIconCompRef,
  );
  const isOpen = target && util.inlinePopupUtils.isOpen(editorAPI, target);
  if (isCompDescendantOfMobileMenu(comp, editorAPI)) {
    if (!isOpen) {
      editorAPI.mobile.toggleMobileMenu(target);
    }
  } else if (isOpen) {
    editorAPI.mobile.toggleMobileMenu(target);
  }
};

const onNodeClick = (
  editorAPI: EditorAPI,
  comp: CompRef,
  biData = { label: '' },
) => {
  if (editorAPI.isMobileEditor()) {
    handleMobileMenu(editorAPI, comp);
  }
  const state = editorAPI.store.getState();
  const panel_origin = stateManagement.layersPanel.selectors.getOrigin(state);
  scrollToCompAndSelect(editorAPI, comp);
  editorAPI.store.dispatch(
    stateManagement.bi.actions.event(coreBi.events.layers.layer_click, {
      layer_name: biData.label,
      component_type: editorAPI.dsRead.components.getType(comp),
      parent_component_type: editorAPI.dsRead.components.getType(
        editorAPI.components.getContainerOrScopeOwner(comp),
      ),
      panel_origin,
      is_landing_page: editorAPI.isCurrentPageLandingPage(),
      is_popup: editorAPI.dsRead.pages.popupPages.isPopupOpened(),
      out_of_grid:
        !!editorAPI.components.layout.shouldShowOutOfGridlinesIndication(comp),
    }),
  );

  editorAPI.panelManager.closeAllPanels();
};

const onToggleVisibility = (
  editorAPI: EditorAPI,
  comp: CompRef,
  biData = { label: '' },
) => {
  const { renderPlugins } = editorAPI.dsActions;
  const isHidden = editorAPI.dsRead.renderPlugins.isCompHidden(comp);
  const state = editorAPI.store.getState();
  const panel_origin = stateManagement.layersPanel.selectors.getOrigin(state);
  toggleVisibility(
    editorAPI,
    comp,
    isHidden ? renderPlugins.showComp : renderPlugins.hideComp,
  );
  editorAPI.store.dispatch(
    stateManagement.bi.actions.event(coreBi.events.layers.layer_show_hide, {
      state: isHidden ? '1' : '0',
      layer_name: biData.label,
      panel_origin,
      out_of_grid:
        !!editorAPI.components.layout.shouldShowOutOfGridlinesIndication(comp),
    }),
  );
};

const onToggleSpotlight = (
  editorAPI: EditorAPI,
  comp: CompRef,
  biData = { label: '' },
) => {
  const isComponentSpotlighted = spotlight.isComponentSpotlighted(
    editorAPI,
    comp,
  );
  if (!isComponentSpotlighted) {
    scrollToCompAndSelect(editorAPI, comp);
  }
  const state = isComponentSpotlighted ? '0' : '1';
  const appState = editorAPI.store.getState();
  const panel_origin =
    stateManagement.layersPanel.selectors.getOrigin(appState);
  spotlight.toggleSpotlight(editorAPI, comp);
  editorAPI.store.dispatch(
    stateManagement.bi.actions.event(coreBi.events.layers.layer_spotlight, {
      state,
      layer_name: biData.label,
      panel_origin,
      out_of_grid:
        !!editorAPI.components.layout.shouldShowOutOfGridlinesIndication(comp),
    }),
  );
};

const isDevModeEnabled = (editorAPI: EditorAPI) => {
  return editorAPI.developerMode.isEnabled();
};

let outOfGridComps: OutOfGridComps = {};
let isMobileNode = false;

const shouldShowWarningIcon = (comp: CompRef) => {
  return outOfGridComps[comp.id];
};

const getParentLayout = (editorAPI: EditorAPI, comp: CompRef) => {
  const parentRef = editorAPI.components.getContainer(comp);
  return editorAPI.components.layout.getRelativeToStructure(parentRef);
};

const getComponentNewPosition = (
  compLayout: CompLayout,
  parentLayout: CompLayout,
) => ({
  x: parentLayout.x + parentLayout.width / 2 - compLayout.width / 2,
  y: parentLayout.y + parentLayout.height / 2 - compLayout.height / 2,
});

const handleRepositionComponentInParent = (
  editorAPI: EditorAPI,
  comp: CompRef,
) => {
  const compLayout = editorAPI.components.layout.getRelativeToStructure(comp);
  const parentLayout = getParentLayout(editorAPI, comp);
  const componentNewPosition = getComponentNewPosition(
    compLayout,
    parentLayout,
  );

  editorAPI.components.layout.updateRelativeToStructure(
    comp,
    componentNewPosition,
  );
  editorAPI.dsActions.waitForChangesAppliedAsync().then(() => {
    editorAPI.components.arrangement.moveToFront(comp);
    scrollToCompAndSelect(editorAPI, comp);
  });
};

interface CreateNodePropsOptions {
  isSOAP?: boolean;
  level?: number;
  parent?: NodeProps;
}

const createNodeProps = (
  editorAPI: EditorAPI,
  comp: CompRef,
  { isSOAP = false, level = 0, parent = null }: CreateNodePropsOptions = {},
  treeNodesContext: TreeNodesContext,
): NodeProps => {
  const isSpotlight = spotlight.isComponentSpotlighted(editorAPI, comp);
  const label = getComponentDisplayName(editorAPI, comp, level);

  const [selectedComp] = editorAPI.selection.getSelectedComponents();
  const hasChildNodes = hasComponentChildrenToShowInLayersPanel(
    editorAPI,
    comp,
  );
  const allNodesEmptyStateContent = getAllNodesEmptyStateContent(
    editorAPI,
    comp,
    hasChildNodes,
  );

  const emptyStateData =
    experiment.isOpen('se_emptyHeaderFooterInLayers') &&
    allNodesEmptyStateContent[
      comp.id as keyof typeof allNodesEmptyStateContent
    ];

  const areChildNodesExpandedByDefault = () =>
    level < 1 ||
    (selectedComp &&
      editorAPI.components.isAncestorOfCompOrCompScope(comp, selectedComp));
  const areChildNodesExpanded =
    (hasChildNodes || emptyStateData) &&
    (treeNodesContext.isNodeExpanded(comp.id) ??
      areChildNodesExpandedByDefault());

  if (isMobileNode !== editorAPI.isMobileEditor()) {
    isMobileNode = editorAPI.isMobileEditor();
    outOfGridComps = {};
  }

  const setOutOfGridComps = _.throttle(
    (editorAPI: EditorAPI, comp: CompRef) => {
      const isDragging =
        !!editorAPI.mouseActions.getRegisteredMouseMoveAction();
      const compIsDescendantOfSelected =
        selectedComp?.id === comp.id ||
        editorAPI.components.isDescendantOfComp(comp, selectedComp);
      const shouldSetOutOfGridComps =
        outOfGridComps[comp.id] === undefined ||
        (!isDragging && compIsDescendantOfSelected);

      if (
        shouldSetOutOfGridComps &&
        !isCompDescendantOfMobileMenu(comp, editorAPI)
      ) {
        outOfGridComps[comp.id] =
          editorAPI.components.layout.shouldShowOutOfGridlinesIndication(comp);
      }
    },
    100,
    { trailing: true },
  );

  setOutOfGridComps(editorAPI, comp);

  const nodeProps: NodeProps = {
    parent,
    id: comp.id,
    level,
    label,
    isSelected: editorAPI.utils.isSameRef(comp, selectedComp),
    isHidden: editorAPI.documentServices.renderPlugins.isCompHidden(comp),
    onClick: onNodeClick.bind(null, editorAPI, comp, { label }),
    onToggleVisibility: onToggleVisibility.bind(null, editorAPI, comp, {
      label,
    }),
    onToggleSpotlight: onToggleSpotlight.bind(null, editorAPI, comp, { label }),
    shouldShowWarningIcon: shouldShowWarningIcon(comp),
    icon: editorAPI.components.getIconInfo(comp, null, 'layers'),
    isSpotlight,
    selectedCompId: selectedComp?.id,
    isSOAP,
    isDevModeEnabled: isDevModeEnabled(editorAPI),
    sendBi: editorAPI.bi.event,
    componentType: editorAPI.components.getType(comp),
    isLandingPage: editorAPI.isCurrentPageLandingPage(),
    isLightBoxMode: editorAPI.dsRead.pages.popupPages.isPopupOpened(),
    openOutOfGridHelpCenter: () => {
      editorAPI.panelManager.openHelpCenter(NOTIFICATIONS.OUT_OF_GRIDLINES);
      editorAPI.store.dispatch(
        stateManagement.bi.actions.event(coreBi.events.help.HELP_CLICK, {
          origin: 'layers_panel',
        }),
      );
    },
    hasChildNodes,
    areChildNodesExpanded,
    setChildNodesExpanded: (nextIsExpanded: boolean) => {
      treeNodesContext.setIsNodeExpanded(comp.id, nextIsExpanded);
    },
    emptyStateData,
    isComponentWithinPage: isComponentWithinPage(editorAPI, comp),
    repositionComponentInParent: () => {
      handleRepositionComponentInParent(editorAPI, comp);
    },
  };

  if (nodeProps.hasChildNodes && nodeProps.areChildNodesExpanded) {
    const filteredChildren = Array.from(
      getComponentChildrenToShowInLayersPanel_GENERATOR(editorAPI, comp),
    );

    nodeProps.childNodes = filteredChildren.map((childCompRef) =>
      createNodeProps(
        editorAPI,
        childCompRef,
        { isSOAP, level: level + 1, parent: nodeProps },
        treeNodesContext,
      ),
    );

    nodeProps.moveToIndex = moveToIndex.bind(
      null,
      editorAPI,
      filteredChildren,
      { label },
    );
  }

  return nodeProps;
};

export default createNodeProps;
