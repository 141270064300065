import React from 'react';
import styles from './thumbnailDragBox.scss';
import type { ExtraPreviewProps } from '../types';
export interface ThumbnailDragBoxProps extends ExtraPreviewProps {
  boxSize: {
    width?: number;
    height?: number;
  };
  coords: {
    left?: number;
    top?: number;
  };
  boxOffset: {
    left?: number;
    top?: number;
  };
  renderPreview: (props: ExtraPreviewProps) => JSX.Element;
}

const ThumbnailDragBox: React.FC<ThumbnailDragBoxProps> = ({
  coords,
  boxSize,
  boxOffset,
  renderPreview,
  isDragging,
  setIsActive,
  isAddingSection,
  setIsAddingSection,
  height,
  width,
}: ThumbnailDragBoxProps) => {
  const transform = `translate(${boxOffset.left * 100}%, ${
    boxOffset.top * 100
  }%)`;

  const previewProps: ExtraPreviewProps = {
    isDragging,
    setIsActive,
    isAddingSection,
    setIsAddingSection,
    height,
    width,
  };

  return (
    <div
      className={styles.htmlThumbnailDragBox}
      style={{
        ...boxSize,
        top: coords.top,
        WebkitTransform: transform,
        transform,
        left: coords.left,
        height,
      }}
    >
      {renderPreview(previewProps)}
    </div>
  );
};

export default ThumbnailDragBox;
