import type { EditorAPI } from '@/editorAPI';
import * as util from '@/util';
import { integrateAddPanelToSearch } from './addPanelIntegration';
import { integrateAppMarketToSearch } from './appMarketIntegration';
import { integrateBusinessManagerToSearch } from './businessManagerIntegration';
import { integrateHelpCenterToSearch } from './helpCenterIntegration';
import { integrateLeftBarToSearch } from './leftBarIntegration';
import { integrateMobileToolsToSearch } from './mobileToolsIntegration';
import {
  integrateNewAddPanelDesktopToSearch,
  integrateNewAddPanelMobileToSearch,
} from './newAddPanelIntegration';
import { integratePagesPanelToSearch } from './pagesPanelIntegration';
import { integrateQuickActions } from './quickActionsIntegration';

export const applyIntegrations = (editorAPI: EditorAPI) => {
  integratePagesPanelToSearch(editorAPI);
  integrateHelpCenterToSearch(editorAPI);
  integrateAppMarketToSearch(editorAPI);
  integrateMobileToolsToSearch(editorAPI);
  integrateLeftBarToSearch(editorAPI);
  if (util.addPanel.isNewPanelEnabled()) {
    integrateNewAddPanelDesktopToSearch(editorAPI);
    integrateNewAddPanelMobileToSearch(editorAPI);
  } else {
    integrateAddPanelToSearch(editorAPI);
  }
  integrateQuickActions(editorAPI);
  integrateBusinessManagerToSearch(editorAPI);
};
