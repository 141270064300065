import type { TextManager } from 'types/data';
import type { Style, StyleRef } from 'types/documentServices';
import type { TextThemeStyleMap } from './textThemes';

export interface RichTextAction<TActionState> {
  execCommand: (
    textManager: TextManager,
    actionInput: TActionState,
  ) => string | void;
  execRevertCommand: (textManager: TextManager) => void;
  getWixRichTextCommandState: (
    textManager: TextManager,
  ) => TActionState | undefined;
}

export interface ActionCreationData<TActionState>
  extends RichTextAction<TActionState> {
  createUpdatedStyleProperties: (
    actionInput: TActionState | undefined,
  ) => Style['properties'];
  getPropertyValue: (
    styleProperties: Style['properties'],
  ) => TActionState | undefined;
}

export interface ThemedActionCreationData<TActionState>
  extends ActionCreationData<TActionState> {
  getThemeValue: (theme: TextThemeStyleMap) => TActionState;
}

export interface NonThemedActionCreationData<TActionState>
  extends ActionCreationData<TActionState> {
  getDefaultValue: () => TActionState;
}

export interface ThemedAction<TActionState>
  extends ThemedActionCreationData<TActionState> {
  type: 'themed';
  getState: (
    textManager: TextManager,
    styleProperties: Style['properties'],
    theme: TextThemeStyleMap,
  ) => TActionState;
}
export interface NonThemedAction<TActionState>
  extends NonThemedActionCreationData<TActionState> {
  type: 'nonThemed';
  getState: (
    textManager: TextManager,
    styleProperties: StyleRef,
    ignoreTextManager?: boolean,
  ) => TActionState;
}

export interface NonStyleAction<TActionState>
  extends RichTextAction<TActionState> {
  getWixRichTextCommandState: (
    textManager: TextManager,
  ) => TActionState | undefined;
}
