'use strict'

module.exports = {
    "id": "add_preset_popups_Sale_5",
    "structure": {
        "type": "Page",
        "id": "dfiyq",
        "componentTypeForBI": "wysiwyg.viewer.components.PopupContainer",
        "components": [{
            "type": "Container",
            "id": "comp-im1wa5fa",
            "components": [{
                "type": "Component",
                "id": "comp-im1we8cl",
                "layout": {
                    "width": 351,
                    "height": 376,
                    "x": 279,
                    "y": 65,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WPhoto",
                "data": {
                    "type": "Image",
                    "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                    "title": "Multicolored Framed Shades",
                    "uri": "ed8c6ccfc59544948fccd8c02f2bb6b5.jpg",
                    "description": "",
                    "width": 1000,
                    "height": 1000,
                    "alt": "",
                    "crop": {"x": 71, "y": 3, "width": 929, "height": 995},
                    "id": "dataItem-im1we8co"
                },
                "props": {
                    "type": "WPhotoProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "displayMode": "fill",
                    "onClickBehavior": "goToLink",
                    "effectName": "none",
                    "id": "propItem-im1we8co1"
                },
                "style": "wp2"
            }, {
                "type": "Component",
                "id": "comp-im1wgqz8",
                "layout": {
                    "width": 205,
                    "height": 140,
                    "x": 54,
                    "y": 70,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "text": "<h1 style=\"font-size: 61px; line-height: 1em;\" class=\"font_0\"><span style=\"font-size:61px;\"><span style=\"line-height:1em;\"><span style=\"letter-spacing:0.15em;\"><span style=\"color:#2A2A2A;\"><span style=\"font-family:anton,sans-serif;\">HEY YOU!</span></span></span></span></span></h1>",
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": [],
                    "id": "dataItem-im1wgqz9"
                },
                "props": {
                    "type": "WRichTextProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "brightness": 1,
                    "packed": true,
                    "id": "propItem-im1wgqza"
                },
                "style": "txtNew"
            }, {
                "type": "Component",
                "id": "comp-im1wiufs",
                "skin": "wysiwyg.viewer.skins.line.SolidLine",
                "layout": {
                    "width": 49,
                    "height": 8,
                    "x": 59,
                    "y": 228,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.FiveGridLine",
                "props": {
                    "type": "FiveGridLineProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "fullScreenModeOn": false,
                    "id": "propItem-im1wiufu"
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {"alpha-brd": "1", "brd": "#DBB89A", "lnw": "8"},
                        "propertiesSource": {"brd": "value", "lnw": "value"}
                    },
                    "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.line.SolidLine",
                    "id": "style-it8esn95"
                }
            }, {
                "type": "Component",
                "id": "comp-im1wlx2x",
                "layout": {
                    "width": 220,
                    "height": 48,
                    "x": 59,
                    "y": 262,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "text": "<p class=\"font_8\" style=\"font-size:16px; line-height:1.4em\"><span style=\"color:#2A2A2A;\"><span style=\"font-size:16px\"><span style=\"font-family:open sans,sans-serif\">Catch our Summer Sale with up to 50% off.</span></span></span></p>\n",
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": [],
                    "id": "dataItem-im1wlx31"
                },
                "props": {
                    "type": "WRichTextProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "brightness": 1,
                    "packed": true,
                    "id": "propItem-im1wlx32"
                },
                "style": "txtNew"
            }, {
                "type": "Component",
                "id": "comp-imesys43",
                "skin": "wysiwyg.viewer.skins.button.BasicButton",
                "layout": {
                    "width": 205,
                    "height": 45,
                    "x": 59,
                    "y": 347,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.PopupCloseTextButton",
                "data": {
                    "type": "LinkableButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "label": "CONTINUE TO SITE",
                    "id": "dataItem-imesys47"
                },
                "props": {
                    "type": "ButtonProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "align": "center",
                    "margin": 0,
                    "id": "propItem-imesys49"
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-bg": "1",
                            "alpha-bgh": "1",
                            "alpha-brd": "1",
                            "alpha-brdh": "1",
                            "alpha-txt": "1",
                            "alpha-txth": "1",
                            "bg": "#000000",
                            "bgh": "#3D3D3D",
                            "boxShadowToggleOn-shd": "false",
                            "brd": "#BD1515",
                            "brdh": "#B08484",
                            "brw": "0",
                            "fnt": "normal normal normal 15px/1.4em spinnaker",
                            "rd": "0px",
                            "shd": "0 1px 4px rgba(0, 0, 0, 0.6);",
                            "txt": "#FFFFFF",
                            "txth": "#FFFFFF"
                        },
                        "propertiesSource": {
                            "bg": "value",
                            "bgh": "value",
                            "brd": "value",
                            "brdh": "value",
                            "brw": "value",
                            "fnt": "value",
                            "rd": "value",
                            "shd": "value",
                            "txt": "value",
                            "txth": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.PopupCloseTextButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.button.BasicButton",
                    "id": "style-imknio2c"
                }
            }, {
                "type": "Component",
                "skin": "svgshape.v2.Svg_001419cc721a440abc4ab12ed150b744",
                "layout": {
                    "width": 30,
                    "height": 30,
                    "x": 580,
                    "y": 20,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.PopupCloseIconButton",
                "data": {
                    "type": "SvgShape",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    }
                },
                "props": {
                    "type": "SvgShapeProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "maintainAspectRatio": true
                },
                "style": {
                    "type": "ComponentStyle",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "style": {
                        "properties": {
                            "alpha-fillcolor": "1",
                            "alpha-stroke": "1",
                            "fillcolor": "#424242",
                            "stroke": "#FFFFFF",
                            "strokewidth": "1"
                        },
                        "propertiesSource": {
                            "alpha-fillcolor": "value",
                            "alpha-stroke": "value",
                            "fillcolor": "value",
                            "stroke": "value",
                            "strokewidth": "value"
                        },
                        "groups": {}
                    },
                    "componentClassName": "wysiwyg.viewer.components.PopupCloseIconButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "svgshape.v2.Svg_001419cc721a440abc4ab12ed150b744"
                },
                "connections": {
                    "type": "ConnectionList",
                    "items": [
                        {
                            "type": "WixCodeConnectionItem",
                            "role": "button1"
                        }
                    ]
                },
                "mobileStructure": {
                    "layout": {
                        "width": 24,
                        "height": 24,
                        "x": 261,
                        "y": 16,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "metaData": {
                        "originalCompId": "comp-israzxqa",
                        "author": "duplicate"
                    }
                },
                "activeModes": {}
            }],
            "skin": "wysiwyg.viewer.skins.stripContainer.DefaultStripContainer",
            "layout": {
                "width": 630,
                "height": 463,
                "x": 175,
                "y": 157,
                "scale": 1,
                "rotationInDegrees": 0,
                "fixedPosition": false
            },
            "componentType": "wysiwyg.viewer.components.PopupContainer",
            "props": {
                "type": "PopupContainerProperties",
                "metaData": {"schemaVersion": "1.0"},
                "horizontalAlignment": "center",
                "verticalAlignment": "center",
                "alignmentType": "nineGrid",
                "horizontalOffset": 0,
                "verticalOffset": 0,
                "id": "propItem-im1wa5fc"
            },
            "design": {
                "type": "MediaContainerDesignData",
                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                "background": {
                    "type": "BackgroundMedia",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "color": "#FCFEFD",
                    "colorOpacity": 1,
                    "alignType": "center",
                    "fittingType": "fill",
                    "scrollType": "none",
                    "colorOverlay": "",
                    "colorOverlayOpacity": 0
                },
                "id": "dataItem-im1wa5fd"
            },
            "style": {
                "type": "TopLevelStyle",
                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                "style": {
                    "groups": {},
                    "properties": {"alpha-bg": "1", "bg": "rgba(255,255,255,1)"},
                    "propertiesSource": {"alpha-bg": "value", "bg": "value"}
                },
                "componentClassName": "wysiwyg.viewer.components.PopupContainer",
                "pageId": "",
                "compId": "",
                "styleType": "custom",
                "skin": "wysiwyg.viewer.skins.stripContainer.DefaultStripContainer",
                "id": "style-im1wa5ff"
            },
            "behaviors": {
                "type": "ObsoleteBehaviorsList",
                "items": "[{\"name\":\"FadeIn\",\"delay\":0.1,\"duration\":0.75,\"action\":\"screenIn\",\"targetId\":\"\",\"params\":{}},{\"name\":\"FadeOut\",\"delay\":0.1,\"duration\":0.75,\"action\":\"exit\",\"targetId\":\"\",\"params\":{}}]",
                "id": "behavior-iomlwee1"
            }
        }],

        "skin": "skins.core.InlineSkin",
        "layout": {
            "width": 980,
            "height": 620,
            "x": 0,
            "y": 0,
            "scale": 1,
            "rotationInDegrees": 0,
            "fixedPosition": false
        },
        "componentType": "mobile.core.components.Page",
        "data": {
            "type": "Page",
            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
            "title": "add_preset_popups_Sale_5",
            "hideTitle": true,
            "icon": "",
            "descriptionSEO": "",
            "metaKeywordsSEO": "",
            "pageTitleSEO": "",
            "pageUriSEO": "copy-of-welcome4",
            "hidePage": true,
            "underConstruction": false,
            "tpaApplicationId": 0,
            "pageSecurity": {"requireLogin": false, "passwordDigest": "", "dialogLanguage": ""},
            "isPopup": true,
            "indexable": false,
            "isLandingPage": false,
            "pageBackgrounds": {
                "desktop": {
                    "ref": {
                        "type": "BackgroundMedia",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "color": "#E66363",
                        "colorOpacity": 0.77,
                        "alignType": "center",
                        "fittingType": "fill",
                        "scrollType": "none",
                        "colorOverlay": "",
                        "colorOverlayOpacity": 0
                    }
                },
                "mobile": {
                    "custom": true,
                    "ref": {
                        "type": "BackgroundMedia",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "color": "#E66363",
                        "colorOpacity": 0.77,
                        "alignType": "center",
                        "fittingType": "fill",
                        "scrollType": "scroll",
                        "colorOverlay": "",
                        "colorOverlayOpacity": 0
                    },
                    "isPreset": true
                }
            },
            "ignoreBottomBottomAnchors": true
        },
        "props": {
            "type": "PageProperties",
            "metaData": {"schemaVersion": "1.0"},
            "desktop": {"popup": {"closeOnOverlayClick": true}},
            "mobile": {"popup": {"closeOnOverlayClick": true}},
            "id": "dfiyq"
        },
        "style": "p1",
        "tpaComps": []
    },
    "preset": {
        "rect": {"width": 305, "height": 202, "x": 10, "y": 686},
        "label": "add_preset_popups_Sale_5",
        "tags": null
    }
}
