import React, { Component } from 'react';
import { cx } from '@/util';

interface MenuBarItemProps {
  title: string;
  className?: string;
  automationId?: string;
  isActive?: boolean;
  hasNotification?: boolean;
  prefix?: React.Component;
  getCounter?: () => number;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  onClick?: () => void;
}

class MenuBarItem extends Component<MenuBarItemProps> {
  static defaultProps = {
    className: '',
    isActive: false,
  };

  render() {
    const counter = this.props.getCounter && this.props.getCounter();
    const { hasNotification } = this.props;
    const className = cx('top-bar-menu-bar-item', this.props.className, {
      active: this.props.isActive,
    });

    return (
      <div
        data-aid={this.props.automationId}
        onMouseEnter={this.props.onMouseEnter}
        onMouseLeave={this.props.onMouseLeave}
        className={className}
        onClick={this.props.onClick}
      >
        {this.props.prefix && (
          <div className="top-bar-menu-bar-item-prefix">
            {this.props.prefix}
          </div>
        )}
        {counter ? (
          <div key="counter" className="top-bar-menu-bar-item-counter">
            {counter}
          </div>
        ) : null}
        {hasNotification ? (
          <span className="top-bar-menu-bar-item-notification" />
        ) : null}
        {this.props.title}
      </div>
    );
  }
}

export default MenuBarItem;
