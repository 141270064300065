import { biLogger } from '@/util';

import type { Scope } from '../scope';
import type { MigrationFlow } from '../types';
interface ComponentXCoordMapping {
  [pageId: string]: { [componentID: string]: number };
}

const sleep = (timeout = 0) =>
  new Promise((resolve) => setTimeout(resolve, timeout));

const getComponentsXCoordMapping = async (scope: Scope) => {
  const componentsXCoordMapping: ComponentXCoordMapping = {};
  const allPages = scope.editorAPI.pages.getPagesData();

  for (let i = 0; i < allPages.length; i++) {
    const pageId = allPages[i].id;

    const pageComponents = scope.editorAPI.components.getAllComponents(pageId);

    componentsXCoordMapping[pageId] = pageComponents.reduce(
      (acc, comp) => {
        acc[comp.id] = scope.editorAPI.components.layout.get_position(comp)?.x;
        return acc;
      },
      {} as Record<string, number>,
    );

    await sleep(0);
  }

  return componentsXCoordMapping;
};

const getComponentsXDiff = async (
  scope: Scope,
  compsBefore: ComponentXCoordMapping,
) => {
  const component_id: string[] = [];
  const page_id: string[] = [];
  const x_previous: number[] = [];

  // eslint-disable-next-line guard-for-in
  for (const pageId in compsBefore) {
    const pageCompsBefore = compsBefore[pageId];

    // eslint-disable-next-line guard-for-in
    for (const compId in pageCompsBefore) {
      const compRef = scope.editorAPI.components.get.byId(
        compId,
        pageId,
        scope.editorAPI.dsRead.viewMode.VIEW_MODES.DESKTOP,
      );
      const compXBefore = pageCompsBefore[compId];
      const compXAfter = scope.components.layout.get_position(compRef)?.x;

      if (compXBefore !== compXAfter) {
        page_id.push(pageId);
        component_id.push(compId);
        x_previous.push(compXAfter);
      }
    }

    await sleep(0);
  }

  return {
    component_id: component_id.join(','),
    page_id: page_id.join(','),
    x_previous: x_previous.join(','),
  };
};

export const createBIService = (scope: Scope) => {
  const revision = scope.editorAPI.documentServices.generalInfo.getRevision();
  let componentsBeforeMigration: ComponentXCoordMapping = null;
  let startTime = 0;

  return {
    setStartTime: (start: number) => {
      startTime = start;
    },
    beforeMigration: async (flow: MigrationFlow) => {
      componentsBeforeMigration = await getComponentsXCoordMapping(scope);

      biLogger.log({
        evid: 1502,
        revision,
        flow,
      });
    },
    afterSuccessMigration: async (endTime: number) => {
      const flow = scope.migrationService.getFlow();
      const duration = Math.round(endTime - startTime);
      const componentsChangedByMigration = await getComponentsXDiff(
        scope,
        componentsBeforeMigration,
      );

      biLogger.log({
        evid: 1503,
        revision,
        flow,
        duration,
        components_changed: JSON.stringify(componentsChangedByMigration),
      });

      componentsBeforeMigration = null;
    },
    afterFailedMigration: (err: Error) => {
      biLogger.log({
        evid: 1505,
        revision,
        flow: scope.migrationService.getFlow(),
        error_message: `Migration error ${err.message}`,
      });
    },
  };
};
