import type { Scope } from './baseDragScope';
import * as util from '@/util';
import constants from '@/constants';
import * as coreBi from '@/coreBi';

export function getKeyboardContextApi(scope: Scope) {
  const { editorAPI } = scope;
  let lastShortcutContext: string | undefined;

  const dragShortcutContextName = util.keyboardShortcuts.CONTEXTS.DRAG;

  let cancelDrag: () => void;
  const setCancelDrag = (_cancelDrag: () => void) => {
    cancelDrag = _cancelDrag;
  };

  const dragContext = {
    esc: () => {
      if (cancelDrag) {
        cancelDrag();
      }
      unsetContext();
      editorAPI.bi.event(coreBi.events.shortcuts.esc_during_action, {
        action: constants.MOUSE_ACTION_TYPES.DRAG,
      });
    },
  };

  util.keyboardShortcuts.registerContext(dragShortcutContextName, dragContext);
  const setContext = () => {
    if (!lastShortcutContext) {
      lastShortcutContext = util.keyboardShortcuts.getContext();
      util.keyboardShortcuts.setContext(dragShortcutContextName);
      util.fedopsLogger.interactionStarted(
        util.fedopsLogger.INTERACTIONS.SET_DRAG_SHORTCUT_CONTEXT,
      );
    }
  };

  const unsetContext = () => {
    if (lastShortcutContext) {
      util.keyboardShortcuts.setContext(lastShortcutContext);
      lastShortcutContext = null;
      util.fedopsLogger.interactionEnded(
        util.fedopsLogger.INTERACTIONS.SET_DRAG_SHORTCUT_CONTEXT,
      );
    }
  };

  return { setContext, unsetContext, setCancelDrag };
}
