import {
  registry,
  type IEditorComponentLoader,
  type IEditorComponentModel,
} from '@wix/editor-elements-registry/2.0/editor';
import {
  getComponentsLibraries,
  type IServiceTopology,
} from '@wix/editor-elements-registry/2.0/toolbox';
import {
  isComponentPartAllowed,
  isNewStaticsEnabled,
  isMobileLibraryEnabled,
} from './componentPartAllowed';
import * as Sentry from '@sentry/browser';

const componentLoaders: Map<string, IEditorComponentLoader> = new Map();
let registryAPIPromise: Promise<void>;

export interface ComponentRegistryConfig {
  serviceTopology: IServiceTopology;
}

export type ComponentPartName = keyof IEditorComponentModel;

export type ComponentPanelType =
  | 'settingsPanel'
  | 'layoutPanel'
  | 'stylablePanel';

async function initComponentsRegistry({
  serviceTopology,
}: ComponentRegistryConfig) {
  registryAPIPromise = (async () => {
    const registryAPI = await registry({
      mode: 'lazy',
      options: {
        //eslint-disable-next-line
        useEsmLoader: SANTA_EDITOR_BUILD_TYPE === 'esbuild',
      },
      libraries: getComponentsLibraries({
        serviceTopology,
        url: window.location.href,
        useNewStatics: isNewStaticsEnabled(),
        experimentalMobileLibrary: isMobileLibraryEnabled(),
      }),
      getSentryClient: (sentryOptions: any) =>
        new Sentry.Hub(new Sentry.BrowserClient(sentryOptions)),
    });

    const componentLoadersObj = registryAPI.getComponentsLoaders();

    Object.entries(componentLoadersObj).forEach(([componentType, loader]) => {
      componentLoaders.set(componentType, loader);
    });
  })();

  return registryAPIPromise;
}

async function onRegistryLoaded() {
  if (!registryAPIPromise) {
    throw new Error(
      'Function called before registry is initialized.\nEnsure `initComponentsRegistry` is called.',
    );
  }

  return registryAPIPromise;
}

function hasComponentPart<TPartName extends ComponentPartName>(
  componentType: string,
  componentPartName: TPartName | string,
): componentPartName is TPartName {
  return (
    (isComponentPartAllowed(componentType, componentPartName) &&
      componentLoaders.get(componentType)?.isPartExist(componentPartName)) ??
    false
  );
}

async function loadComponentPart<TPartName extends ComponentPartName>(
  componentType: string,
  componentPartName: TPartName,
): Promise<IEditorComponentModel[TPartName]> {
  await onRegistryLoaded();

  const componentPartLoader = componentLoaders.get(componentType);
  const componentPart = componentPartLoader
    ? await componentPartLoader(componentPartName)
    : undefined;

  return componentPart as IEditorComponentModel[TPartName];
}

async function loadComponentsPart<TPartName extends ComponentPartName>(
  componentPartName: TPartName | string,
): Promise<Map<string, IEditorComponentModel[TPartName]>> {
  await onRegistryLoaded();

  const componentTypes = Array.from(componentLoaders.keys());

  const data = await Promise.all(
    componentTypes
      .filter((componentType) =>
        hasComponentPart(componentType, componentPartName),
      )
      .map((componentType) =>
        Promise.all([
          componentType,
          loadComponentPart(componentType, componentPartName as TPartName),
        ]),
      ),
  );

  return new Map(data);
}

export {
  initComponentsRegistry,
  hasComponentPart,
  loadComponentPart,
  loadComponentsPart,
};
