import { isMainMenuFlowEnabled } from '@/util';

import { createMenuSetFromPagesPanelData } from './utils';
import { MAIN_MENU_TYPE, PAGES_MENU_ID } from '../../constants';
import { createMenuApi } from '../../API/menuAPI';

import type { EditorAPI } from '@/editorAPI';

export const migrateDesktopMenu = (editorAPI: EditorAPI): Promise<void> => {
  const menuAPI = createMenuApi(editorAPI);

  const isMainMenuFlow = isMainMenuFlowEnabled();

  const menuId = createMenuSetFromPagesPanelData(editorAPI, {
    syncWithPages: isMainMenuFlow,
    menuType: isMainMenuFlow ? MAIN_MENU_TYPE : undefined,
  });

  const menuComponentsWithPagesMenuSet =
    menuAPI.getDesktopMenuComponentsByMenuId(PAGES_MENU_ID);

  menuComponentsWithPagesMenuSet.forEach((menuComponent) => {
    menuAPI.connect(menuId, menuComponent);
  });

  return new Promise((resolve) => editorAPI.waitForChangesApplied(resolve));
};
