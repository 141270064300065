:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._topBarButtonSectionsZoom_seku0_7 {
  padding: 0 14px 0 8px !important; }
  ._topBarButtonSectionsZoom_seku0_7 ._symbol_seku0_9 {
    display: flex;
    color: #000624; }
  ._topBarButtonSectionsZoom_seku0_7 ._label_seku0_12 {
    width: 40px;
    margin-left: 2px;
    font-size: 14px;
    color: #000624 !important; }
  ._topBarButtonSectionsZoom_seku0_7:hover ._symbol_seku0_9 {
    color: #116dff; }
  ._topBarButtonSectionsZoom_seku0_7._disabledTopBarButtonSectionsZoom_seku0_19 ._symbol_seku0_9,
  ._topBarButtonSectionsZoom_seku0_7._disabledTopBarButtonSectionsZoom_seku0_19 ._label_seku0_12 {
    color: #868aa5 !important; }
