import * as stateManagement from '@/stateManagement';
import experiment from 'experiment';
import type { EditorAPI } from '@/editorAPI';
import type { CompRef, CompLayout } from 'types/documentServices';
import constants from '@/constants';
import type { CSSProperties } from 'react';
import type { EditBoxProps } from './editBoxTypes';
import _ from 'lodash';
import * as selectionBoxCommons from '../selectionBoxCommons';
import * as util from '@/util';

const componentsSelectors = stateManagement.components.selectors;

const { getPreviewPosition } = stateManagement.domMeasurements.selectors;

const { getSelectedCompsRefs, getSelectedCompRestrictions } =
  stateManagement.selection.selectors;

const { isInInteractionMode, isShownOnlyOnVariant, componentHasInteraction } =
  stateManagement.interactions.selectors;

const COLUMNS_CONTAINER = 'StripColumnsContainer';

const fixShownOnlyInVariantXPositionByParentTypes = new Set<string>([
  constants.COMP_TYPES.COLUMN,
]);

export function getLayout(
  editorAPI: EditorAPI,
  comps: CompRef | CompRef[],
): CompLayout {
  const { components, dsRead } = editorAPI;
  // TODO: remove next block
  // This is a temp fix for WEED-21277 until we'll have proper fix
  // Edit box or any compControl shouldn't even render if we have null as compRef | compRefs
  if (!comps || (Array.isArray(comps) && !comps.length)) {
    return {
      x: 0,
      y: 0,
      width: 0,
      height: 0,
    };
  }

  const state = editorAPI.store.getState();

  if (
    isInInteractionMode(state) &&
    (!Array.isArray(comps) || (Array.isArray(comps) && comps.length === 1)) &&
    isShownOnlyOnVariant(editorAPI, (comps as AnyFixMe)[0])
  ) {
    const layout = components.layout.get(comps);
    const parent = components.getAncestors((comps as AnyFixMe)[0])[0];
    if (
      fixShownOnlyInVariantXPositionByParentTypes.has(
        dsRead.components.getType(parent),
      )
    ) {
      layout.x =
        components.layout.stage.getRelativeToScreen(comps).x -
        components.layout.stage.getRelativeToScreen(parent).x;
    }
    return layout;
  }

  let layout = editorAPI.components.layout.stage.getRelativeToScreen(comps);

  if (components.layout.isShowOnFixedPosition(comps)) {
    const previewPosition = getPreviewPosition(state);
    layout = {
      ...layout,
      y: layout.y + previewPosition.top,
      x: layout.x + previewPosition.left,
      bounding: {
        ...layout.bounding,
        y: layout.bounding.y + previewPosition.top,
        x: layout.bounding.x + previewPosition.left,
      },
    };
  }

  return layout;
}

export function getComponentUIColor(
  editorAPI: EditorAPI,
  comps: CompRef | CompRef[],
) {
  if (
    editorAPI.components.isShowOnAllPages(comps) ||
    editorAPI.components.isShowOnSomePages(comps)
  ) {
    return constants.COMPONENT_UI_COLORS.ORANGE;
  }

  return constants.COMPONENT_UI_COLORS.BLUE;
}

function shouldPositionLayoutForInteractionContainer(
  editorAPI: EditorAPI,
  compRefs: CompRef[],
) {
  return (
    compRefs.length === 1 &&
    editorAPI.components.getType(compRefs[0]) ===
      constants.COMP_TYPES.CONTAINER &&
    componentHasInteraction(editorAPI, compRefs[0])
  );
}

export function isComponentBoxRotated({
  compLayout,
  compRestrictions,
}: {
  compLayout: Pick<CompLayout, 'rotationInDegrees'>;
  compRestrictions?: { rotatable?: boolean; rotatableByType?: boolean };
}) {
  return experiment.isOpen('se_editBoxRotation')
    ? compLayout.rotationInDegrees !== 0 && compRestrictions.rotatableByType
    : compLayout.rotationInDegrees !== 0 && compRestrictions.rotatable;
}

export const getLayoutStyle = (editorAPI: EditorAPI, layout: CompLayout) => {
  const editorState = editorAPI.store.getState();
  const comps: CompRef[] = getSelectedCompsRefs(editorState);

  const isShowOnFixedPosition =
    editorAPI.components.layout.isShowOnFixedPosition(comps);
  const position = selectionBoxCommons.getPosition(isShowOnFixedPosition);
  const compType = componentsSelectors.getCompTypeSuffix(
    comps,
    editorAPI.dsRead,
  );

  const compRestrictions = getCompRestrictions(editorAPI, comps);
  const rotateValue = isComponentBoxRotated({
    compLayout: layout,
    compRestrictions,
  })
    ? `rotate(${layout.rotationInDegrees}deg)`
    : '';

  const isSiteSegmentComponent = getIsSiteSegmentComponent(editorAPI);

  const layoutStyle: CSSProperties & { height: number } = {
    position,
    top: layout.y - 1,
    left: layout.x - 1,
    width: layout.width,
    height: layout.height,
    transform: rotateValue,
    WebkitTransform: rotateValue,
  };

  if (shouldPositionLayoutForInteractionContainer(editorAPI, comps)) {
    layoutStyle.top = layout.y - 3;
    layoutStyle.left = layout.x - 3;
  }

  if (compType === COLUMNS_CONTAINER) {
    layoutStyle.boxSizing = 'border-box';
    layoutStyle.height += 1;
  }

  if (isSiteSegmentComponent) {
    layoutStyle.top = layout.y - 3;
    layoutStyle.left = layout.x - 3;
  }

  layoutStyle.visibility =
    stateManagement.selection.selectors.shouldHideSelectionWhenStageActionsHovered(
      editorAPI,
    )
      ? 'hidden'
      : 'visible';

  return layoutStyle;
};

export const getCompRestrictions = (
  editorAPI: EditorAPI,
  comps?: CompRef[],
) => {
  const editorState = editorAPI.store.getState();

  comps ??= getSelectedCompsRefs(editorState);

  const isPage = editorAPI.components.is.page(comps);
  const pagesContainer = editorAPI.dsRead.siteSegments.getPagesContainer();
  const compRestrictions = isPage
    ? editorAPI.getCompRestrictions(pagesContainer)
    : getSelectedCompRestrictions(editorState);

  return compRestrictions;
};

export const getIsSiteSegmentComponent = (editorAPI: EditorAPI) => {
  const editorState = editorAPI.store.getState();

  const comps: CompRef[] = getSelectedCompsRefs(editorState);
  if (_.isEmpty(comps)) {
    return false;
  }
  const singleComp = _.head(comps);
  const isMultiselect = util.array.isMultiselect(comps);
  const isPage = editorAPI.components.is.page(comps);

  const isSiteSegmentComponent = isMultiselect
    ? false
    : singleComp.id === 'SITE_FOOTER' ||
      singleComp.id === 'SITE_HEADER' ||
      isPage;

  return isSiteSegmentComponent;
};

type OwnPropsKeys =
  | 'isDragging'
  | 'isResizing'
  | 'isRotating'
  | 'isDuringMouseAction'
  | 'isPinMode'
  | 'isDim'
  // | 'selectedComponents'
  // | 'performingMouseMoveAction'
  | 'onContextMenu'
  | 'applyModeFromClipboardSuggestion'
  | 'labelsOffset'
  | 'isLabelBottom';

export type OwnProps = Pick<EditBoxProps, OwnPropsKeys>;
