import _ from 'lodash';
import * as coreBi from '@/coreBi';
import * as stateManagement from '@/stateManagement';
import * as platformEvents from 'platformEvents';
import constants from '@/constants';
import * as util from '@/util';
import { layoutTransitionsUtil } from '@/layoutUtils';

import type { MouseEvent } from 'react';
import type { EditorAPI } from '@/editorAPI';
import type { CompLayout, CompRef, Rect } from 'types/documentServices';

const { getPreviewPosition } = stateManagement.domMeasurements.selectors;
const { translateToViewerCoordinates } =
  stateManagement.domMeasurements.selectors;
const { isInInteractionMode } = stateManagement.interactions.selectors;

let isShiftKeyDown = false;
let initDegreesToIncrement: number;
let rotateTransition: layoutTransitionsUtil.Rotate;
let selectedComp: CompRef[];
let editorAPI: EditorAPI;
let initLayoutRelativeToScreen: Rect;
let lastShortcutContext: string | null = null;

const SNAP_VALUE = 3;
const SNAP_DEGREES = 90;
const ROTATE_INTERVAL = 15;
const rotateShortcutContextName = util.keyboardShortcuts.CONTEXTS.ROTATE;

const rotateContext = {
  esc: () => {
    editorAPI.mouseActions.turnOffMouseEvents();
    for (let i = 0; i < selectedComp.length; i++) {
      editorAPI.components.layout.updateRelativeToScreen(
        selectedComp,
        initLayoutRelativeToScreen,
        true,
      );
    }
    unsetContext();
    editorAPI.bi.event(coreBi.events.shortcuts.esc_during_action, {
      action: constants.MOUSE_ACTION_TYPES.ROTATE,
    });
  },
};

util.keyboardShortcuts.registerContext(
  rotateShortcutContextName,
  rotateContext,
);
const setContext = () => {
  if (!lastShortcutContext) {
    lastShortcutContext = util.keyboardShortcuts.getContext();
    util.keyboardShortcuts.setContext(rotateShortcutContextName);
    util.fedopsLogger.interactionStarted(
      util.fedopsLogger.INTERACTIONS.SET_ROTATE_SHORTCUT_CONTEXT,
    );
  }
};

const unsetContext = () => {
  if (lastShortcutContext) {
    util.keyboardShortcuts.setContext(lastShortcutContext);
    lastShortcutContext = null;
    util.fedopsLogger.interactionEnded(
      util.fedopsLogger.INTERACTIONS.SET_ROTATE_SHORTCUT_CONTEXT,
    );
  }
};

function rotateWithSnap(layout: CompLayout) {
  snapToRotateDirection(layout);

  editorAPI.components.layout.updateRelativeToScreen(
    selectedComp,
    layout,
    true,
  );
}

function snapToRotateDirection(layout: CompLayout) {
  if (layout.rotationInDegrees < 0) {
    layout.rotationInDegrees += 360;
  }
  const angleFromMainDirection = layout.rotationInDegrees % SNAP_DEGREES;
  if (angleFromMainDirection <= SNAP_VALUE) {
    layout.rotationInDegrees -= angleFromMainDirection;
  } else if (angleFromMainDirection >= SNAP_DEGREES - SNAP_VALUE) {
    layout.rotationInDegrees += SNAP_DEGREES - angleFromMainDirection;
  }
}

function incrementalRotate(layout: CompLayout) {
  const initRotationInDegrees = Math.floor(
    initDegreesToIncrement < 0
      ? initDegreesToIncrement + 360
      : initDegreesToIncrement,
  );
  const newRotationInDegrees = Math.floor(
    layout.rotationInDegrees < 0
      ? layout.rotationInDegrees + 360
      : layout.rotationInDegrees,
  );

  const degreesDiff = newRotationInDegrees - initRotationInDegrees;
  const incrementDegreesDiff = Math.floor(degreesDiff / ROTATE_INTERVAL);

  layout.rotationInDegrees =
    initDegreesToIncrement + incrementDegreesDiff * ROTATE_INTERVAL;
  editorAPI.components.layout.updateRelativeToScreen(
    selectedComp,
    layout,
    true,
  );
}

function startRotate(
  _editorAPI: EditorAPI,
  params: {
    comps: CompRef[];
    evt: MouseEvent;
  },
) {
  selectedComp = params.comps;
  editorAPI = _editorAPI;

  editorAPI.selection.setIsMouseUpSelectionEnabled(false);
  const state = editorAPI.store.getState();

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/map
  const selectedCompIds = _.map(selectedComp, 'id');
  const currentEditorState = editorAPI.store.getState();
  const isInteractionMode = isInInteractionMode(currentEditorState);
  const isInComponentFocusMode = editorAPI.componentFocusMode.isEnabled();
  if (!isInteractionMode && !isInComponentFocusMode) {
    editorAPI.dsActions.renderPlugins.setCompsToShowOnTop(selectedCompIds);
  }

  initLayoutRelativeToScreen =
    editorAPI.components.layout.getRelativeToScreen(selectedComp);
  const previewPosition = getPreviewPosition(state);
  const initAbsoluteLayout = _.defaults(
    {
      y: initLayoutRelativeToScreen.y + previewPosition.top,
      x: initLayoutRelativeToScreen.x + previewPosition.left,
    },
    initLayoutRelativeToScreen,
  );

  const mouseCoordinates = translateToViewerCoordinates(editorAPI, params.evt);
  const initMouse = { x: mouseCoordinates.pageX, y: mouseCoordinates.pageY };

  const selectedCompChildren = editorAPI.components.getChildren(selectedComp);
  if (!isInteractionMode && !isInComponentFocusMode) {
    editorAPI.dsActions.renderPlugins.setCompsToShowWithOpacity(
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/map
      _.map(selectedCompChildren, 'id'),
      0.01,
    );
  }

  rotateTransition = new layoutTransitionsUtil.Rotate(
    initLayoutRelativeToScreen,
    initMouse,
    initAbsoluteLayout,
  );
}

function rotate(event: MouseEvent) {
  const mouseCoordinates = translateToViewerCoordinates(editorAPI, event);
  const mouse = { x: mouseCoordinates.pageX, y: mouseCoordinates.pageY };
  const layout = rotateTransition.getLayout(mouse);

  if (event.shiftKey) {
    if (!isShiftKeyDown) {
      initDegreesToIncrement =
        editorAPI.components.layout.getRelativeToScreen(
          selectedComp,
        ).rotationInDegrees;
    }

    incrementalRotate(layout);
  } else {
    rotateWithSnap(layout);
  }

  isShiftKeyDown = event.shiftKey;
}

function endRotate() {
  const compRef = selectedComp[0];
  const compType = editorAPI.components.getType(compRef);
  editorAPI.dsActions.waitForChangesApplied(function () {
    const rotationInDegrees =
      editorAPI.components.layout.get_rotationInDegrees(compRef);

    editorAPI.bi.event(coreBi.events.editBox.ROTATE_COMPONENT, {
      // @ts-expect-error
      value: parseInt(rotationInDegrees, 10),
      origin: 'editBox',
      component_type: compType,
      status:
        compType == constants.COMP_TYPES.MOBILE_BACK_TO_TOP_BUTTON
          ? 'linked_to_top'
          : 'unlinked',
    });
  });
  const currentEditorState = editorAPI.store.getState();
  const isInteractionMode = isInInteractionMode(currentEditorState);
  const isCompFocusMode = editorAPI.componentFocusMode.isEnabled();
  if (!isInteractionMode && !isCompFocusMode) {
    editorAPI.dsActions.renderPlugins.setCompsToShowOnTop(null);
    editorAPI.dsActions.renderPlugins.setCompsToShowWithOpacity([]);
  }
  editorAPI.history.add('Rotate component');

  editorAPI.dsActions.platform.notifyAppsOnCustomEvent(
    platformEvents.factory.componentRotateEnded({ compRef }),
  );
}

const moduleToExport = {
  start: startRotate,
  on: rotate,
  end: endRotate,
  type: constants.MOUSE_ACTION_TYPES.ROTATE,
};

_.merge(moduleToExport, {
  setContext,
  unsetContext,
});

export default moduleToExport;
