// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Bubble } from '@wix/wix-base-ui';
import PanelContent from './dynamicPanel';

function template() {
  return (
    <Bubble
      onMouseEnter={this.props.onMouseEnter}
      onMouseLeave={this.props.onMouseLeave}
      arrowAlignment="top"
      contentStyle={this.bubbleContentStyle}
      arrowStyle={this.bubbleArrowStyle}
      maxWidth={750}
      className="dynamic-drop-panel-top-bar"
    >
      {this.props.panelPath ? (
        <PanelContent panelPath={this.props.panelPath} />
      ) : null}
    </Bubble>
  );
}

const BUBBLE_ARROW_STYLE = {
  position: 'absolute',
  right: 'calc(50% - 8px)',
  top: '53px',
};

const BUBBLE_CONTENT_STYLE = {
  position: 'absolute',
  display: 'block',
  right: 'calc(50% - 120px)',
  top: '60px',
};

class DynamicDropPanel extends React.Component {
  constructor(props) {
    super(props);

    this.bubbleContentStyle = _.defaults(
      {},
      props?.bubbleStyleOverrides?.content,
      BUBBLE_CONTENT_STYLE,
    );
    this.bubbleArrowStyle = _.defaults(
      {},
      props?.bubbleStyleOverrides?.arrow,
      BUBBLE_ARROW_STYLE,
    );
  }

  render() {
    return template.call(this);
  }
}

DynamicDropPanel.displayName = 'DynamicDropPanel';

DynamicDropPanel.propTypes = {
  panelPath: PropTypes.string.isRequired,
  bubbleStyleOverrides: PropTypes.shape({
    content: PropTypes.object,
    arrow: PropTypes.object,
  }),
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
};

export default DynamicDropPanel;
