'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')

module.exports = {
    "type": addPanelDataConsts.SECTIONS_TYPES.PRESET,
    "hide": false,
    "title": "add_section_heading_checkbox_group",
    "subNavigationTitle": "add_section_label_checkbox_category",
    "topTitle": "add_section_title_checkbox_group",
    "presetTitle": "add_section_heading_checkbox_group",
    "tooltipTitle": "add_section_label_checkbox_group_tooltip_title",
    "subNavigationHide": false,
    "automationId": "add-panel-section-checkboxGroupSection",
    "showSectionHeader": true,
    "additionalBehaviours": {
        "labelMode": addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
        "infoIcon": addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
        "hoverImageAction": addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
        "iconEnabledForComps": {}
    },
    'props': {
        'image': 'addPanelData/sections/checkboxGroupSection_en/checkboxGroupSection_en.png',
        'imageHover': null,
        'items': [{
            'id': 'User_Input_CheckboxGroup_1',
            'structure': {
                'type': 'Component',
                'skin': 'skins.input.CheckboxGroupDefaultSkin',
                'layout': {
                    'width': 136,
                    'height': 118,
                    'x': 17,
                    'y': 22,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.viewer.components.inputs.CheckboxGroup',
                'data': {
                    'type': 'CheckboxGroup',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'options': [{
                        'type': 'CheckboxInput',
                        'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                        'value': 'Pepperoni',
                        'checked': true,
                        'label': 'Pepperoni'
                    }, {
                        'type': 'CheckboxInput',
                        'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                        'value': 'Mushrooms',
                        'checked': false,
                        'label': 'Mushrooms'
                    }, {
                        'type': 'CheckboxInput',
                        'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                        'value': 'Onions',
                        'label': 'Onions'
                    }],
                    'label': 'Pizza toppings:'
                },
                'props': {
                    'type': 'CheckboxGroupProperties',
                    'metaData': {'schemaVersion': '1.0', 'autoGenerated': false},
                    'alignment': 'left',
                    'layout': 'vertical',
                    'buttonsMargin': 17,
                    'spacing': 16,
                    'buttonSize': 18,
                    'labelMargin': 16
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'properties': {
                            'alpha-bg': '1',
                            'alpha-bg2h': '0',
                            'alpha-bg3': '0.55',
                            'alpha-bg3e': '0',
                            'alpha-bg3h': '1',
                            'alpha-bgd': '0',
                            'alpha-bgf': '1',
                            'alpha-bgh': '1',
                            'alpha-border_color_focus': '1',
                            'alpha-brd': '1',
                            'alpha-brdf': '1',
                            'alpha-brdh': '0.55',
                            'alpha-btn_brd': '0.55',
                            'bg': 'color_11',
                            'bg2': 'color_18',
                            'bg2d': '#919191',
                            'bg2h': 'color_18',
                            'bg3': 'color_15',
                            'bg3e': '#6EB7FD',
                            'bg3f': '#00A6FF',
                            'bg3h': 'color_18',
                            'bgc': '#49D6D6',
                            'bgcd': 'color_18',
                            'bgch': '#49D6D6',
                            'bgd': '#FFFFFF',
                            'bge': '#f60419',
                            'bgf': 'color_11',
                            'bgh': 'color_11',
                            'border_color_focus': 'color_18',
                            'boxShadowToggleOn-shd': 'false',
                            'brd': '#49D6D6',
                            'brde': '#FF4040',
                            'brdf': '#49D6D6',
                            'brdh': '#49D6D6',
                            'brw': '2',
                            'brwd': '1',
                            'brwe': '2',
                            'brwf': '2',
                            'brwh': '2',
                            'btn_brd': 'color_15',
                            'btn_brw': '1',
                            'btn_fnt': 'font_8',
                            'fnt': 'italic normal normal 14px/1.4em georgia',
                            'fnt2': 'italic normal normal 14px/1.4em georgia',
                            'rd': '0pxpx',
                            'shd': '0px 1px 4px 0px rgba(0,0,0,0.6)',
                            'txt': '#000000',
                            'txt-placeholder': 'color_14',
                            'txt2': '#000000',
                            'txtlblrq': '#000000'
                        }
                    },
                    'componentClassName': 'wysiwyg.viewer.components.inputs.CheckboxGroup',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'custom',
                    'skin': 'skins.input.CheckboxGroupDefaultSkin'
                },
                'activeModes': {},
                'id': 'comp-jhui6vng'
            },
            'preset': {'rect': {'width': 166, 'height': 160, 'x': 2, 'y': 1}, 'tags': null}
        }, {
            'id': 'User_Input_CheckboxGroup_2',
            'structure': {
                'type': 'Component',
                'skin': 'skins.input.CheckboxGroupDefaultSkin',
                'layout': {
                    'width': 107,
                    'height': 118,
                    'x': 196,
                    'y': 22,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.viewer.components.inputs.CheckboxGroup',
                'data': {
                    'type': 'CheckboxGroup',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'options': [{
                        'type': 'CheckboxInput',
                        'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                        'value': 'Football',
                        'checked': true,
                        'label': 'Football'
                    }, {
                        'type': 'CheckboxInput',
                        'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                        'value': 'Running',
                        'checked': true,
                        'label': 'Running'
                    }, {
                        'type': 'CheckboxInput',
                        'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                        'value': 'Sailing',
                        'label': 'Sailing'
                    }],
                    'label': 'Sport interests:'
                },
                'props': {
                    'type': 'CheckboxGroupProperties',
                    'metaData': {'schemaVersion': '1.0', 'autoGenerated': false},
                    'alignment': 'left',
                    'layout': 'vertical',
                    'buttonsMargin': 17,
                    'spacing': 14,
                    'buttonSize': 18,
                    'labelMargin': 16
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'properties': {
                            'alpha-bg': '1',
                            'alpha-bg2h': '0',
                            'alpha-bg3': '0.55',
                            'alpha-bg3e': '0',
                            'alpha-bg3h': '1',
                            'alpha-bgd': '0',
                            'alpha-bgf': '1',
                            'alpha-bgh': '1',
                            'alpha-border_color_focus': '1',
                            'alpha-brd': '1',
                            'alpha-brdf': '1',
                            'alpha-brdh': '1',
                            'alpha-btn_brd': '0.55',
                            'bg': 'color_11',
                            'bg2': 'color_18',
                            'bg2d': '#919191',
                            'bg2h': 'color_18',
                            'bg3': 'color_15',
                            'bg3e': '#6EB7FD',
                            'bg3f': '#00A6FF',
                            'bg3h': 'color_18',
                            'bgc': '#737373',
                            'bgcd': 'color_18',
                            'bgch': '#737373',
                            'bgd': '#FFFFFF',
                            'bge': '#f60419',
                            'bgf': 'color_11',
                            'bgh': 'color_11',
                            'border_color_focus': 'color_18',
                            'boxShadowToggleOn-shd': 'false',
                            'brd': '#919191',
                            'brde': '#FF4040',
                            'brdf': '#919191',
                            'brdh': '#A6A6A6',
                            'brw': '2',
                            'brwd': '1',
                            'brwe': '2',
                            'brwf': '2',
                            'brwh': '2',
                            'btn_brd': 'color_15',
                            'btn_brw': '1',
                            'btn_fnt': 'font_8',
                            'fnt': 'normal normal normal 14px/1.4em avenir-lt-w01_35-light1475496',
                            'fnt2': 'normal normal normal 14px/1.4em avenir-lt-w01_35-light1475496',
                            'rd': '0pxpx',
                            'shd': '0px 1px 4px 0px rgba(0,0,0,0.6)',
                            'txt': '#525252',
                            'txt-placeholder': 'color_14',
                            'txt2': '#525252',
                            'txtlblrq': '#525252'
                        }
                    },
                    'componentClassName': 'wysiwyg.viewer.components.inputs.CheckboxGroup',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'custom',
                    'skin': 'skins.input.CheckboxGroupDefaultSkin'
                },
                'activeModes': {},
                'id': 'comp-jhui6vnu'
            },
            'preset': {'rect': {'width': 156, 'height': 160, 'x': 168, 'y': 1}, 'tags': null}
        }, {
            'id': 'User_Input_CheckboxGroup_3',
            'structure': {
                'type': 'Component',
                'skin': 'skins.input.CheckboxGroupDefaultSkin',
                'layout': {
                    'width': 136,
                    'height': 117,
                    'x': 17,
                    'y': 179,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.viewer.components.inputs.CheckboxGroup',
                'data': {
                    'type': 'CheckboxGroup',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'options': [{
                        'type': 'CheckboxInput',
                        'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                        'value': 'Birthday card',
                        'checked': true,
                        'label': 'Birthday card'
                    }, {
                        'type': 'CheckboxInput',
                        'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                        'value': 'Gift wrap',
                        'checked': true,
                        'label': 'Gift wrap'
                    }, {
                        'type': 'CheckboxInput',
                        'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                        'value': 'Express delivery',
                        'label': 'Express delivery'
                    }],
                    'label': 'Add to purchase:'
                },
                'props': {
                    'type': 'CheckboxGroupProperties',
                    'metaData': {'schemaVersion': '1.0', 'autoGenerated': false},
                    'alignment': 'left',
                    'layout': 'vertical',
                    'buttonsMargin': 18,
                    'spacing': 15,
                    'buttonSize': 16,
                    'labelMargin': 18
                },
                'mobileHints': {
                    'type': 'MobileHints',
                    'author': 'duplicate',
                    'originalCompId': 'comp-jhuaafm3',
                    'recommendedScale': 1,
                    'recommendedWidth': 280,
                    'recommendedHeight': 137
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'properties': {
                            'alpha-bg': '1',
                            'alpha-bg2h': '0',
                            'alpha-bg3': '0.55',
                            'alpha-bg3e': '0',
                            'alpha-bg3h': '1',
                            'alpha-bgd': '0',
                            'alpha-bgf': '1',
                            'alpha-bgh': '1',
                            'alpha-border_color_focus': '1',
                            'alpha-brd': '1',
                            'alpha-brdf': '1',
                            'alpha-brdh': '1',
                            'alpha-btn_brd': '0.55',
                            'bg': 'color_11',
                            'bg2': 'color_18',
                            'bg2d': '#919191',
                            'bg2h': 'color_18',
                            'bg3': 'color_15',
                            'bg3e': '#6EB7FD',
                            'bg3f': '#00A6FF',
                            'bg3h': 'color_18',
                            'bgc': '#8C84FA',
                            'bgcd': 'color_18',
                            'bgch': '#8C84FA',
                            'bgd': '#FFFFFF',
                            'bge': '#f60419',
                            'bgf': 'color_11',
                            'bgh': 'color_11',
                            'border_color_focus': 'color_18',
                            'boxShadowToggleOn-shd': 'false',
                            'brd': '#8C84FA',
                            'brde': '#FF4040',
                            'brdf': '#8C84FA',
                            'brdh': '#8C84FA',
                            'brw': '1',
                            'brwd': '1',
                            'brwe': '1',
                            'brwf': '1',
                            'brwh': '1',
                            'btn_brd': 'color_15',
                            'btn_brw': '1',
                            'btn_fnt': 'font_8',
                            'fnt': 'normal normal normal 15px/1.4em futura-lt-w01-light',
                            'fnt2': 'normal normal normal 15px/1.4em futura-lt-w01-light',
                            'rd': '0pxpx',
                            'shd': '0px 1px 4px 0px rgba(0,0,0,0.6)',
                            'txt': '#000000',
                            'txt-placeholder': 'color_14',
                            'txt2': '#000000',
                            'txtlblrq': '#000000'
                        }
                    },
                    'componentClassName': 'wysiwyg.viewer.components.inputs.CheckboxGroup',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'custom',
                    'skin': 'skins.input.CheckboxGroupDefaultSkin'
                },
                'activeModes': {},
                'id': 'comp-jhui6vo8'
            },
            'preset': {'rect': {'width': 166, 'height': 153, 'x': 2, 'y': 161}, 'tags': null}
        }, {
            'id': 'User_Input_CheckboxGroup_4',
            'structure': {
                'type': 'Component',
                'skin': 'skins.input.CheckboxGroupDefaultSkin',
                'layout': {
                    'width': 107,
                    'height': 119,
                    'x': 196,
                    'y': 179,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.viewer.components.inputs.CheckboxGroup',
                'data': {
                    'type': 'CheckboxGroup',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'options': [{
                        'type': 'CheckboxInput',
                        'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                        'value': 'WiFi',
                        'checked': true,
                        'label': 'WiFi'
                    }, {
                        'type': 'CheckboxInput',
                        'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                        'value': 'A/C',
                        'checked': true,
                        'label': 'A/C'
                    }, {
                        'type': 'CheckboxInput',
                        'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                        'value': 'Iron',
                        'label': 'Iron'
                    }],
                    'label': 'Room facilities:'
                },
                'props': {
                    'type': 'CheckboxGroupProperties',
                    'metaData': {'schemaVersion': '1.0', 'autoGenerated': false},
                    'alignment': 'left',
                    'layout': 'vertical',
                    'buttonsMargin': 17,
                    'spacing': 14,
                    'buttonSize': 18,
                    'labelMargin': 16
                },
                'mobileHints': {
                    'type': 'MobileHints',
                    'author': 'duplicate',
                    'originalCompId': 'comp-jhudb8b5',
                    'recommendedScale': 1,
                    'recommendedWidth': 280,
                    'recommendedHeight': 139
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'properties': {
                            'alpha-bg': '1',
                            'alpha-bg2h': '0',
                            'alpha-bg3': '0.55',
                            'alpha-bg3e': '0',
                            'alpha-bg3h': '1',
                            'alpha-bgd': '0',
                            'alpha-bgf': '1',
                            'alpha-bgh': '1',
                            'alpha-border_color_focus': '1',
                            'alpha-brd': '1',
                            'alpha-brdf': '1',
                            'alpha-brdh': '1',
                            'alpha-btn_brd': '0.55',
                            'bg': '#5EE08A',
                            'bg2': 'color_18',
                            'bg2d': '#919191',
                            'bg2h': 'color_18',
                            'bg3': 'color_15',
                            'bg3e': '#6EB7FD',
                            'bg3f': '#00A6FF',
                            'bg3h': 'color_18',
                            'bgc': 'color_11',
                            'bgcd': 'color_18',
                            'bgch': 'color_11',
                            'bgd': '#FFFFFF',
                            'bge': '#FF4040',
                            'bgf': '#5EE08A',
                            'bgh': '#5EE08A',
                            'border_color_focus': 'color_18',
                            'boxShadowToggleOn-shd': 'false',
                            'brd': '#5EE08A',
                            'brde': '#FF4040',
                            'brdf': '#5EE08A',
                            'brdh': '#5EE08A',
                            'brw': '0',
                            'brwd': '1',
                            'brwe': '0',
                            'brwf': '0',
                            'brwh': '0',
                            'btn_brd': 'color_15',
                            'btn_brw': '1',
                            'btn_fnt': 'font_8',
                            'fnt': 'normal normal normal 15px/1.4em bree-w01-thin-oblique',
                            'fnt2': 'normal normal normal 15px/1.4em bree-w01-thin-oblique',
                            'rd': '0pxpx',
                            'shd': '0px 1px 4px 0px rgba(0,0,0,0.6)',
                            'txt': '#26D15F',
                            'txt-placeholder': 'color_14',
                            'txt2': '#26D15F',
                            'txtlblrq': '#26D15F'
                        }
                    },
                    'componentClassName': 'wysiwyg.viewer.components.inputs.CheckboxGroup',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'custom',
                    'skin': 'skins.input.CheckboxGroupDefaultSkin'
                },
                'activeModes': {},
                'id': 'comp-jhui6vom'
            },
            'preset': {'rect': {'width': 156, 'height': 153, 'x': 168, 'y': 161}, 'tags': null}
        }, {
            'id': 'User_Input_CheckboxGroup_5',
            'structure': {
                'type': 'Component',
                'skin': 'skins.input.CheckboxGroupDefaultSkin',
                'layout': {
                    'width': 150,
                    'height': 116,
                    'x': 17,
                    'y': 334,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.viewer.components.inputs.CheckboxGroup',
                'data': {
                    'type': 'CheckboxGroup',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'options': [{
                        'type': 'CheckboxInput',
                        'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                        'value': 'Rome',
                        'checked': true,
                        'label': 'Rome'
                    }, {
                        'type': 'CheckboxInput',
                        'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                        'value': 'Berlin',
                        'checked': false,
                        'label': 'Berlin'
                    }, {
                        'type': 'CheckboxInput',
                        'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                        'value': 'Paris',
                        'checked': true,
                        'label': 'Paris'
                    }],
                    'label': 'Cities visited:'
                },
                'props': {
                    'type': 'CheckboxGroupProperties',
                    'metaData': {'schemaVersion': '1.0', 'autoGenerated': false},
                    'alignment': 'left',
                    'layout': 'vertical',
                    'buttonsMargin': 17,
                    'spacing': 15,
                    'buttonSize': 18,
                    'labelMargin': 16
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'properties': {
                            'alpha-bg': '1',
                            'alpha-bg2h': '0',
                            'alpha-bg3': '0.55',
                            'alpha-bg3e': '0',
                            'alpha-bg3h': '1',
                            'alpha-bgd': '0',
                            'alpha-bgf': '1',
                            'alpha-bgh': '1',
                            'alpha-border_color_focus': '1',
                            'alpha-brd': '0',
                            'alpha-brde': '0',
                            'alpha-brdf': '0',
                            'alpha-brdh': '0',
                            'alpha-btn_brd': '0.55',
                            'bg': 'color_11',
                            'bg2': 'color_18',
                            'bg2d': '#919191',
                            'bg2h': 'color_18',
                            'bg3': 'color_15',
                            'bg3e': '#6EB7FD',
                            'bg3f': '#00A6FF',
                            'bg3h': 'color_18',
                            'bgc': '#000000',
                            'bgcd': 'color_18',
                            'bgch': '#000000',
                            'bgd': '#FFFFFF',
                            'bge': '#FF4040',
                            'bgf': 'color_11',
                            'bgh': 'color_11',
                            'border_color_focus': 'color_18',
                            'boxShadowToggleOn-shd': 'true',
                            'brd': 'color_15',
                            'brdf': 'color_15',
                            'brdh': 'color_15',
                            'brw': '1',
                            'brwd': '1',
                            'brwe': '0',
                            'brwf': '0',
                            'brwh': '0',
                            'btn_brd': 'color_15',
                            'btn_brw': '1',
                            'btn_fnt': 'font_8',
                            'fnt': 'normal normal normal 12px/1.4em lulo-clean-w01-one-bold',
                            'fnt2': 'normal normal normal 12px/1.4em lulo-clean-w01-one-bold',
                            'rd': '2px 2px 2px 2px',
                            'shd': '0.00px 3.00px 9px 1px rgba(0,0,0,0.1)',
                            'txt': 'color_15',
                            'txt-placeholder': 'color_14',
                            'txt2': '#000000',
                            'txtlblrq': '#000000'
                        }
                    },
                    'componentClassName': 'wysiwyg.viewer.components.inputs.CheckboxGroup',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'custom',
                    'skin': 'skins.input.CheckboxGroupDefaultSkin'
                },
                'activeModes': {},
                'id': 'comp-jhui6vpl'
            },
            'preset': {'rect': {'width': 167, 'height': 159, 'x': 1, 'y': 314}, 'tags': null}
        }, {
            'id': 'User_Input_CheckboxGroup_6',
            'structure': {
                'type': 'Component',
                'skin': 'skins.input.CheckboxGroupDefaultSkin',
                'layout': {
                    'width': 111,
                    'height': 120,
                    'x': 196,
                    'y': 332,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.viewer.components.inputs.CheckboxGroup',
                'data': {
                    'type': 'CheckboxGroup',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'options': [{
                        'type': 'CheckboxInput',
                        'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                        'value': 'Tent',
                        'checked': true,
                        'label': 'Tent'
                    }, {
                        'type': 'CheckboxInput',
                        'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                        'value': 'Air bed',
                        'checked': true,
                        'label': 'Air bed'
                    }, {
                        'type': 'CheckboxInput',
                        'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                        'value': 'Lamp',
                        'label': 'Lamp'
                    }],
                    'label': 'Camping gear:'
                },
                'props': {
                    'type': 'CheckboxGroupProperties',
                    'metaData': {'schemaVersion': '1.0', 'autoGenerated': false},
                    'alignment': 'left',
                    'layout': 'vertical',
                    'buttonsMargin': 17,
                    'spacing': 16,
                    'buttonSize': 18,
                    'labelMargin': 16
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'properties': {
                            'alpha-bg': '1',
                            'alpha-bg2h': '0',
                            'alpha-bg3': '0.55',
                            'alpha-bg3e': '0',
                            'alpha-bg3h': '1',
                            'alpha-bgd': '0',
                            'alpha-bgf': '1',
                            'alpha-bgh': '1',
                            'alpha-border_color_focus': '1',
                            'alpha-brd': '1',
                            'alpha-brdf': '1',
                            'alpha-brdh': '1',
                            'alpha-btn_brd': '0.55',
                            'bg': 'color_11',
                            'bg2': 'color_18',
                            'bg2d': '#919191',
                            'bg2h': 'color_18',
                            'bg3': 'color_15',
                            'bg3e': '#6EB7FD',
                            'bg3f': '#00A6FF',
                            'bg3h': 'color_18',
                            'bgc': '#00A6FF',
                            'bgcd': 'color_18',
                            'bgch': '#00A6FF',
                            'bgd': '#FFFFFF',
                            'bge': '#FF4040',
                            'bgf': 'color_11',
                            'bgh': '#E6F3FC',
                            'border_color_focus': 'color_18',
                            'boxShadowToggleOn-shd': 'false',
                            'brd': '#00A6FF',
                            'brde': '#FF4040',
                            'brdf': '#3D9BE9',
                            'brdh': '#00A6FF',
                            'brw': '1',
                            'brwd': '1',
                            'brwe': '0',
                            'brwf': '1',
                            'brwh': '1',
                            'btn_brd': 'color_15',
                            'btn_brw': '1',
                            'btn_fnt': 'font_8',
                            'fnt': 'normal normal normal 16px/1.4em helvetica-w01-light',
                            'fnt2': 'normal normal normal 16px/1.4em helvetica-w01-light',
                            'rd': '0pxpx',
                            'shd': '0px 1px 4px 0px rgba(0,0,0,0.6)',
                            'txt': '#2B5672',
                            'txt-placeholder': 'color_14',
                            'txt2': '#2B5672',
                            'txtlblrq': '#2B5672'
                        }
                    },
                    'componentClassName': 'wysiwyg.viewer.components.inputs.CheckboxGroup',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'custom',
                    'skin': 'skins.input.CheckboxGroupDefaultSkin'
                },
                'activeModes': {},
                'id': 'comp-jhui6vp5'
            },
            'preset': {'rect': {'width': 156, 'height': 159, 'x': 168, 'y': 314}, 'tags': null}
        }],
        'compTypes': ['wysiwyg.viewer.components.inputs.CheckboxGroup']
    },
    "help": {"hide": false, "text": "add_section_info_checkbox_group"}
}
