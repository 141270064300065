// @ts-nocheck
import ReactDOM from 'reactDOM';
import React from 'react';
import _ from 'lodash';

/**
 * This mixin allows creating a panel that switches its content.
 * In order to use it you * MUST IMPLEMENT * the method "getInitialPanelClass" that returns that panel that should be rendered first.
 *
 * Each panel rendered via the mixin will receive 2 props that can be used to change it with another panel:
 * - "switchPanelFunc" - will call the mixin's "switchPanel" function
 * - "onBackClick" - will call the mixin's "previousPanel" function
 *
 */

function fixPanelProps(switcherProps, panelProps) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/assign
  return _.assign({}, switcherProps, panelProps, {
    switchPanelFunc: this.switchPanel,
    ref: 'currentPanel',
    style: switcherProps.style,
    panelName: switcherProps.panelName, // required in order to close the root panel
  });
}

function getCurrentPanelPosition() {
  const currentPanelNode =
    this.refs.currentPanel && ReactDOM.findDOMNode(this.refs.currentPanel);
  return (
    currentPanelNode && {
      top: currentPanelNode.style.top,
      left: currentPanelNode.style.left,
    }
  );
}

function getPropsForNextPanel(requestedPanelProps) {
  const nextPanelProps = fixPanelProps.call(
    this,
    this.props,
    requestedPanelProps,
  );
  nextPanelProps.onBackClick = this.previousPanel;
  nextPanelProps.backButtonLabel = this.props.backButtonLabel;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/assign
  nextPanelProps.style = _.assign(
    nextPanelProps.style || {},
    getCurrentPanelPosition.call(this),
  );
  return nextPanelProps;
}

function getUpdatedPreviousState() {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/last
  const lastState = _.clone(_.last(this.state.previousStates));
  lastState.currentPanelProps = _.clone(lastState.currentPanelProps);
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/assign
  lastState.currentPanelProps.style = _.assign(
    lastState.currentPanelProps.style || {},
    getCurrentPanelPosition.call(this),
  );
  return lastState;
}

export default {
  getInitialState() {
    return {
      previousStates: [],
      currentPanelClass: this.getInitialPanelClass(), // not implemented via getDefaultProps to allow usage of instance methods
      currentPanelProps: fixPanelProps.call(this, this.props),
    };
  },
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      currentPanelProps: fixPanelProps.call(
        this,
        nextProps,
        this.state.currentPanelProps,
      ),
    });
  },
  isInitialPanel() {
    return _.isEmpty(this.state) || _.isEmpty(this.state.previousStates);
  },
  switchPanel(nextPanelClass, nextPanelProps) {
    const previousStates = this.state.previousStates.concat(
      _.clone(this.state),
    );
    this.setState({
      previousStates,
      currentPanelClass: nextPanelClass,
      currentPanelProps: getPropsForNextPanel.call(this, nextPanelProps),
    });
  },
  previousPanel() {
    if (!this.isInitialPanel()) {
      const backToState = getUpdatedPreviousState.call(this);
      this.setState(backToState);
      if (this.onPreviousPanel) {
        this.onPreviousPanel(backToState.currentPanelClass);
      }
    }
  },
  getCurrentPanel() {
    return this.refs.currentPanel;
  },
  render() {
    //eslint-disable-line react/display-name
    return React.createElement(
      this.state.currentPanelClass,
      this.state.currentPanelProps,
    );
  },
};
