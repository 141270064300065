// @ts-nocheck
import _ from 'lodash';
import addPanelDataConsts from '@/addPanelDataConsts';
import triggerBiEvent from './triggerElementAddedBiEvent';
import * as addPanelAddCompService from '../addPanelAddCompService';
import constants from '../../constants/constants';
import superAppsConstants from '../../superApps/superAppsConstants';
import * as superAppsCustomData from '../../superApps/superAppsCustomData';

const APP_DEF_ID = superAppsConstants.NEW_BLOG_APP_DEF_ID;
const WIDGET_ID = 'c0a125b8-2311-451e-99c5-89b6bba02b22';

const BI_PARAMS = {
  element_type: 'tag_cloud',
};

const onClick = function (
  compStructure,
  sectionTitle,
  tags,
  itemId,
  editorAPI,
  addOptions,
) {
  triggerBiEvent(editorAPI, BI_PARAMS);
  return addPanelAddCompService.addCompOnClick(
    editorAPI,
    APP_DEF_ID,
    WIDGET_ID,
    compStructure,
    tags,
    itemId,
    { origin: constants.BI.type.ADD_WIDGET },
    addOptions,
  );
};

const onDrop = function (layoutParams, compPreset, parentComponent, editorAPI) {
  triggerBiEvent(editorAPI, BI_PARAMS);
  return addPanelAddCompService.addCompOnDrop(
    editorAPI,
    APP_DEF_ID,
    WIDGET_ID,
    layoutParams,
    compPreset,
    parentComponent,
    _.noop,
    { origin: constants.BI.type.ADD_WIDGET },
  );
};

function getSection() {
  return {
    type: addPanelDataConsts.SECTIONS_TYPES.PRESET,
    hide: false,
    title: 'add_section_label_newblog_tagcloud',
    subNavigationTitle: 'add_section_label_newblog_tagcloud',
    presetTitle: 'add_section_label_newblog_tagcloud',
    tooltipTitle: 'add_section_label_newblog_tagcloud',
    automationId: 'add-panel-section-newBlog-tagCloud',
    sectionName: 'tagCloudSection',
    subNavigationHide: false,
    showSectionHeader: true,
    additionalBehaviours: {
      labelMode: addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
      infoIcon: addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
      hoverImageAction: addPanelDataConsts.HOVER_IMAGE_ACTIONS.NONE,
      iconEnabledForComps: {},
    },
    props: {
      onClick,
      onDrop,
      image:
        'addPanelData/sections/blogTagCloudSection_en/blogTagCloudSection_en.png',
      imageHover: null,
      items: [
        {
          id: 'tagCloudButtonLayout',
          structure: {
            layout: {
              x: 0,
              y: 0,
              fixedPosition: false,
              width: 254,
              height: 74,
              scale: 1,
              rotationInDegrees: 0,
            },
            type: 'Component',
            skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
            componentType: 'wysiwyg.viewer.components.tpapps.TPAWidget',
            data: {
              widgetId: WIDGET_ID,
              appDefinitionId: APP_DEF_ID,
              type: 'TPAWidget',
              metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false,
              },
              referenceId: '',
            },
            mobileHints: {
              type: 'MobileHints',
              hidden: true,
              metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false,
              },
            },
            style: {
              type: 'TopLevelStyle',
              metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false,
              },
              style: superAppsCustomData.getSuperAppsAddPanelStyles(
                APP_DEF_ID,
                'tagCloudButtonLayout',
              ),
              componentClassName: 'wysiwyg.viewer.components.tpapps.TPAWidget',
              pageId: '',
              compId: '',
              styleType: 'custom',
              skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
            },
            connections: {
              type: 'ConnectionList',
              items: [],
              metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false,
              },
            },
            activeModes: {},
          },
          preset: {
            rect: {
              width: 162,
              height: 99,
              x: 0,
              y: 0,
            },
            label: '',
            tags: null,
          },
        },
        {
          id: 'tagCloudTextLayout',
          structure: {
            layout: {
              x: 0,
              y: 0,
              fixedPosition: false,
              width: 254,
              height: 74,
              scale: 1,
              rotationInDegrees: 0,
            },
            type: 'Component',
            skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
            componentType: 'wysiwyg.viewer.components.tpapps.TPAWidget',
            data: {
              widgetId: WIDGET_ID,
              appDefinitionId: APP_DEF_ID,
              type: 'TPAWidget',
              metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false,
              },
              referenceId: '',
            },
            mobileHints: {
              type: 'MobileHints',
              hidden: true,
              metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false,
              },
            },
            style: {
              type: 'TopLevelStyle',
              metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false,
              },
              style: superAppsCustomData.getSuperAppsAddPanelStyles(
                APP_DEF_ID,
                'tagCloudTextLayout',
              ),
              componentClassName: 'wysiwyg.viewer.components.tpapps.TPAWidget',
              pageId: '',
              compId: '',
              styleType: 'custom',
              skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
            },
            connections: {
              type: 'ConnectionList',
              items: [],
              metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false,
              },
            },
            activeModes: {},
          },
          preset: {
            rect: {
              width: 162,
              height: 99,
              x: 162,
              y: 0,
            },
            label: '',
            tags: null,
          },
        },
      ],
      compTypes: ['wysiwyg.viewer.components.tpapps.TPAWidget'],
    },
    help: { hide: false, text: 'add_section_info_text_newblog_tagcloud' },
  };
}

export default {
  registerSection(editorAPI) {
    editorAPI.addPanel.registerSectionAtStart(
      addPanelDataConsts.CATEGORIES_ID.NEW_BLOG,
      getSection,
    );
  },
};
