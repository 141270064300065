import * as core from '@/core';
import * as stateManagement from '@/stateManagement';
import constants from '@/constants';
import * as helpIds from '@/helpIds';
import React from 'react';

const { getSessionUserPreferences } = stateManagement.userPreferences.selectors;

interface PinModeProps {
  style: React.CSSProperties;
}

export default class PinMode extends React.Component<PinModeProps> {
  static displayName: 'pinMode';

  static contextTypes = core.mixins.editorAPIMixin.contextTypes;
  getEditorAPI = core.mixins.editorAPIMixin.getEditorAPI.bind(this);

  render() {
    return (
      <div className="pin-mode">
        <div style={this.props.style} className="preview-rect" />
        <div onClick={this.exitPinMode} className="blocking-layer" />
      </div>
    );
  }

  exitPinMode = () => {
    const editorAPI = this.getEditorAPI();
    const pinToScreenCount =
      getSessionUserPreferences(constants.USER_PREFS.PIN_TO_SCREEN.PIN_COUNT)(
        editorAPI.store.getState(),
      ) || 0;
    if (pinToScreenCount === 0) {
      const onNotificationLinkClick = () => {
        editorAPI.panelManager.openHelpCenter(
          helpIds.NOTIFICATIONS.PIN_TO_SCREEN,
        );
      };
      editorAPI.store.dispatch(
        stateManagement.notifications.actions.showUserActionNotification({
          message: 'Notifications_Pinned_Mobile_Text',
          title: 'Notifications_Pinned_Mobile_Text',
          type: 'info',
          link: {
            caption: 'Notifications_Pinned_Mobile_Link',
            onNotificationLinkClick,
          },
        }),
      );
      editorAPI.store.dispatch(
        stateManagement.userPreferences.actions.setSessionUserPreferences(
          constants.USER_PREFS.PIN_TO_SCREEN.PIN_COUNT,
          -4,
        ),
      );
    }

    editorAPI.pinMode.closeAndCheckSizeRestrictions();
  };
}
