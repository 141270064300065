// @ts-nocheck
import _ from 'lodash';
import addPanelDataConsts from '@/addPanelDataConsts';
import * as styles from '@/styles';

import React from 'react';
import * as addPanelInfra from '@/addPanelInfra';
import * as baseUI from '@/baseUI';

import type { WidgetDesignProps } from './types';
import * as symbols from '@wix/santa-editor-symbols';
import { PresetSnapshot } from './presetSnapshot/presetSnapshot';

const advancedStyleConstants =
  styles.advancedStyle.advancedStyleConstants.multiComponents;

class WidgetDesignPanelPresetSection extends React.Component<WidgetDesignProps> {
  getSectionWrapperStyle = (): React.CSSProperties => {
    const style: React.CSSProperties = {};
    if (!this.props.disableFixedHeight) {
      style.height = '453px'; // No idea where this magic number came from
    }
    return style;
  };

  getSectionWrapperProps = () => {
    return _.merge(advancedStyleConstants.sectionWrapperProps, {
      title: this.props.presetsTitle,
      type: addPanelDataConsts.SECTIONS_TYPES.PRESET,
      disableInfoIcon: true,
    });
  };

  onPresetClicked = (id) => {
    this.props.onPresetChanged(id);
    this.props.waitForPresetChanged().then(() => {
      this.props.fetchCurrentPresetId();
    });
  };

  getPresets = () =>
    Array.isArray(this.props.presets)
      ? this.props.presets
      : Object.keys(this.props.presets).map((id) => ({
          id,
          ...this.props.presets[id],
        }));

  render() {
    return (
      <addPanelInfra.sectionWrapper
        key="presetsPanelClass"
        {...this.getSectionWrapperProps()}
        style={this.getSectionWrapperStyle()}
      >
        <div className="presets-wrapper">
          {this.getPresets().map((preset, index) => {
            return (
              <div
                onClick={() => this.onPresetClicked(preset.id)}
                key={preset.id}
                id={preset.id}
                className="preset-image"
                data-aid={`preset-index-${index.toString()}`}
              >
                <PresetSnapshot preset={preset} />
                {this.props.currentPresetId === preset.id ? (
                  <symbols.symbol
                    key="selectedItem"
                    name="selectedItemDesignPanel"
                    className="design-panel-selected-preset"
                  />
                ) : null}
              </div>
            );
          })}
        </div>
        <div key="customizeDesignBtn" className="button-wrapper">
          <baseUI.button
            ref="customizeDesign"
            label="design_customize_button"
            onClick={this.props.onCustomizeButtonClick}
            icon="customize_design_drop_light"
            className="button"
          />
        </div>
      </addPanelInfra.sectionWrapper>
    );
  }
}

export default WidgetDesignPanelPresetSection;
