import _ from 'lodash';
import React, { type MouseEventHandler } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  CustomScroll,
  PanelHeader,
  Tooltip,
  TextButton,
} from '@wix/wix-base-ui';
import * as symbols from '@wix/santa-editor-symbols';
import * as platform from '@/platform';
import * as platformPanels from '@/platformPanels';
import * as util from '@/util';
import Page from './page/page';
import GroupTitle from './groupTitle/groupTitle';
import { NewWorkspaceContentHeader, AddPageButton } from '../../utils';
import * as baseUI from '@/baseUI';
import { cx } from '@/util';
import type { BiEventDefinition, BiEventFields } from '../../../../types/bi';
import ReactDOM from 'react-dom';
import type { IAppPage, IAppPageAction } from '@/pages';
import { translate } from '@/i18n';

export interface PagesViewProps {
  title: string;
  pages: IAppPage[];
  onClose: MouseEventHandler;
  onHelp: MouseEventHandler;
  navigateTo: Function;
  onSelectPageItem: (pageId: string, hasSettingsAction?: boolean) => void;
  selectedPageId?: string;
  titleAdd?: string;
  onClickAdd?: (origin?: AddPageButton) => void;
  tooltipAdd?: string;
  renamedItemId?: string;
  renameEnabled: boolean;
  biEvent: (event: BiEventDefinition, parameters: BiEventFields) => void;
  biCategory: string;
  secondaryActionTooltipContent?: string;
  secondaryActionSymbol?: any;
  secondaryActionSymbolName?: string;
  onSecondaryActionClick?: () => void;
  appDefinitionId?: string;
  emptyStateUrl?: string;
  pagesGroups?: Array<any>;
  setInPrefIscollapsedMenuItems?: (
    menuItemId: string,
    isCollapsed: boolean,
  ) => void;
  collapsionMap?: Record<string, boolean>;
  getCollapserActions?: (pageData: IAppPagesCollapser) => IAppPageAction[];
  secondaryButtonData?: { title: string; onClick: () => void };
  loadingPageId?: boolean;
}
type ReplacersTypes = 'replacers' | 'variants';
type IAppPagesCollapser = Omit<IAppPage, ReplacersTypes> & {
  replacers?: IAppPage[];
  variants?: IAppPage[];
};

class PagesView extends React.Component<PagesViewProps> {
  static displayName = 'PagesView';

  static propTypes = {
    title: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    onHelp: PropTypes.func.isRequired,
    navigateTo: PropTypes.func.isRequired,
    onSelectPageItem: PropTypes.func.isRequired,
    appData: PropTypes.object.isRequired,
    pages: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        info: PropTypes.string,
        actions: PropTypes.arrayOf(
          PropTypes.shape({
            title: PropTypes.string.isRequired,
            onClick: PropTypes.func.isRequired,
            symbolName: PropTypes.string,
            symbol: PropTypes.func,
            isRename: PropTypes.bool,
          }),
        ),
        typeSymbol: PropTypes.string,
        permissionSymbol: PropTypes.string,
        replacerOf: PropTypes.string,
        inBracketsTitle: PropTypes.string,
        isDeactivated: PropTypes.bool,
      }),
    ).isRequired,
    pagesGroups: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        pages: PropTypes.arrayOf(PropTypes.string),
        groupActions: PropTypes.arrayOf(
          PropTypes.shape({
            title: PropTypes.string.isRequired,
            symbolName: PropTypes.string.isRequired,
            onClick: PropTypes.func.isRequired,
          }),
        ),
      }),
    ),
    selectedPageId: PropTypes.string,
    titleAdd: PropTypes.string,
    onClickAdd: PropTypes.func,
    tooltipAdd: PropTypes.string,
    renamedItemId: PropTypes.string,
    renameEnabled: PropTypes.bool.isRequired,
    biEvent: PropTypes.func.isRequired,
    biCategory: PropTypes.string.isRequired,
    secondaryActionTitle: PropTypes.string,
    secondaryActionSymbol: PropTypes.elementType,
    secondaryActionSymbolName: PropTypes.string,
    onSecondaryActionClick: PropTypes.func,
    appDefinitionId: PropTypes.string,
    emptyStateUrl: PropTypes.string,
    setInPrefIscollapsedMenuItems: PropTypes.func.isRequired,
    collapsionMap: PropTypes.object,
    loadingPageId: PropTypes.bool,
  };

  constructor(props: AnyFixMe) {
    super(props);
    this.handleClickItem = (pageId) => {
      this.props.navigateTo(pageId);
      this.props.onSelectPageItem(pageId);
    };

    this.scrollToSelectedNode = false;
  }

  scrollToSelectedNode;

  private handleClickItem: (pageId: string) => void;

  handleToggle = (node: AnyFixMe, isCollapsed: boolean) => {
    const pageItemId = node.props.dataSource.id;
    this.props.setInPrefIscollapsedMenuItems(pageItemId, isCollapsed);
  };

  generatePanelToken() {
    return platform.tokenService.generatePanelToken(this.props.appDefinitionId);
  }

  getPageWithReplacersItem(page: IAppPagesCollapser, managingAppDefId: string) {
    const id = `${page.id}_replacersCollapser`;
    const replacers =
      page?.replacers?.map((replacer) => this.getPageItem(replacer)) ?? [];
    const variants =
      page?.variants?.map((variant) => this.getPageItem(variant)) ?? [];
    return {
      id,
      title: translate('PLATFORM_flow_customization_pages_panel_folder_label', {
        page_name: page.title,
      }),
      type: {
        isDropdown: true,
        isPage: false,
      },
      onClick: _.noop,
      managingAppDefId,
      actions: this.props.getCollapserActions?.(page),
      biEvent: this.props.biEvent,
      isCollapsed: this.props.collapsionMap?.[id],
      typeSymbol: 'pagesFolder',
      items: [...[this.getPageItem(page)], ...replacers, ...variants],
    };
  }

  getPageItem(page: IAppPage | IAppPagesCollapser) {
    return {
      ...page,
      mountInRenameMode: this.props.renamedItemId === page.id,
      biEvent: this.props.biEvent,
      biCategory: this.props.biCategory,
      renameEnabled: this.props.renameEnabled,
      isSelected: this.props.selectedPageId === page.id,
      onClick: this.handleClickItem,
      type: {
        isDropdown: false,
        isPage: true,
      },
    };
  }

  getPagesItem(pages: IAppPage[]) {
    const replacers = pages.filter((page) => page.replacerOf);
    const dataSource = pages
      .map((page) => {
        const pageData = _.clone(page) as unknown as IAppPagesCollapser;
        if (pageData.replacers) {
          pageData.replacers = page?.replacers?.map((replacerId) =>
            replacers.find((replacer) => replacer.id === replacerId),
          );
        }
        if (pageData.variants) {
          pageData.variants = page?.variants?.map((variantId) =>
            replacers.find((replacer) => replacer.id === variantId),
          );
        }
        return pageData;
      })
      .filter(
        ({ id, managingAppDefId }) =>
          (managingAppDefId === this.props.appDefinitionId &&
            !replacers.find((replacer) => replacer.id === id)) ||
          !replacers.find((replacer) => replacer.id === id),
      )
      .map((page) =>
        page.replacers || page.variants
          ? this.getPageWithReplacersItem(page, this.props.appDefinitionId)
          : this.getPageItem(page),
      );

    return (
      <baseUI.treeDocker
        onNodeMoved={_.noop}
        treeClass="pages-tree"
        treeNodeClass="pages-tree-node"
        treeNodeLabelClass="menu-item-pp"
        emptyDropdownClass="empty-dropdown"
        draggedClass="rotated"
        previewClass="shadow"
        isDragAvailable={false}
        scrollTopGetter={_.noop}
        collapsedClass="tree-collapsed"
        className={cx({
          'pages-tree-docker': true,
          'pages-tree-shrinked': false,
        })}
      >
        <baseUI.treeView
          nodeContent={Page}
          dataSource={dataSource}
          isRoot={true}
          onClick={this.handleClickItem}
          biEvent={this.props.biEvent}
          biCategory={this.props.biCategory}
          className="pages-tree"
          collapsedSelector="isCollapsed"
          onNodeToggled={this.handleToggle}
          onNodeContextMenu={_.noop}
          loadingPageId={this.props.loadingPageId}
        />
      </baseUI.treeDocker>
    );
  }

  getPages() {
    const { pagesGroups } = this.props;
    if (_.isEmpty(pagesGroups)) {
      return this.getPagesItem(this.props.pages);
    }
    const pagesIdsToPagesMap = _.keyBy(this.props.pages, 'id');
    return pagesGroups.map((pagesGroup) => {
      const groupTitle = (
        <li key={`group-id-${pagesGroup.groupId}`} className="group-title">
          <GroupTitle
            title={pagesGroup.title}
            actions={pagesGroup.groupActions}
            biEvent={this.props.biEvent}
            appDefinitionId={this.props.appDefinitionId}
          />
        </li>
      );
      return [groupTitle].concat(
        this.getPagesItem(
          pagesGroup.pages.map((pageId: string) => pagesIdsToPagesMap[pageId]),
        ),
      );
    });
  }

  addPage = (origin?: AddPageButton) => () => {
    return this.props.onClickAdd?.(origin);
  };

  //@ts-expect-error Property 'getScrollTop' does not exist on type 'Element'
  getScrollTop = () => this.refs.customScroll.getScrollTop();

  doScrollToSelectedNode = () => {
    let menuTreeNodeRect;
    let selectedRect;
    let newScrollTop = NaN;

    if (!this.scrollToSelectedNode) {
      return;
    }

    this.scrollToSelectedNode = false;

    //is that right? - no - need to apply ref to the selected
    const rootNode = ReactDOM.findDOMNode(this) as Element;
    const menuTreeNode = rootNode.querySelector('.pages-tree-container');
    const selectedNode = rootNode.querySelector('.menu-item-pp.selected');

    if (selectedNode) {
      menuTreeNodeRect = menuTreeNode.getBoundingClientRect();
      selectedRect = selectedNode.getBoundingClientRect();

      if (selectedRect.top < menuTreeNodeRect.top) {
        newScrollTop =
          this.getScrollTop() + (selectedRect.top - menuTreeNodeRect.top);
      } else if (selectedRect.bottom > menuTreeNodeRect.bottom) {
        newScrollTop =
          this.getScrollTop() + (selectedRect.bottom - menuTreeNodeRect.bottom);
      }

      if (!isNaN(newScrollTop)) {
        //@ts-expect-error Property 'updateScrollPosition' does not exist on type 'ReactInstance'
        this.refs.customScroll.updateScrollPosition(Math.max(0, newScrollTop));
      }
    }
  };

  renderOldPanelHeader() {
    return (
      <PanelHeader
        onClose={this.props.onClose}
        onHelp={this.props.onHelp}
        className="header light"
      >
        <span className="title-pages-view-pp">{this.props.title}</span>
      </PanelHeader>
    );
  }

  renderNewPanelHeader = () => {
    const showAddOptions = this.props.onClickAdd && !this.props.emptyStateUrl;

    return (
      <NewWorkspaceContentHeader
        onAddPage={this.addPage(AddPageButton.Header)}
        title={this.props.title}
        tooltipContent={this.props.titleAdd}
        showAddOptions={showAddOptions}
      />
    );
  };

  getActionSymbol = () => {
    if (
      this.props.secondaryActionSymbol &&
      this.props.secondaryActionSymbolName
    ) {
      return (
        <this.props.secondaryActionSymbol
          className="symbol"
          data-hook={this.props.secondaryActionSymbolName}
        />
      );
    }
    if (this.props.secondaryActionSymbolName) {
      return <symbols.symbol name={this.props.secondaryActionSymbolName} />;
    }
    return null;
  };

  render() {
    const viewWithFooter = this.props.onClickAdd && !this.props.emptyStateUrl;
    const {
      secondaryActionTooltipContent,
      secondaryActionSymbolName,
      onSecondaryActionClick,
      tooltipAdd,
    } = this.props;

    const hasSecondaryAction =
      secondaryActionTooltipContent &&
      secondaryActionSymbolName &&
      onSecondaryActionClick;

    const isNewWorkspace = util.workspace.isNewWorkspaceEnabled();

    const showSecondaryActionRow =
      this.props.secondaryButtonData &&
      !!this.props.secondaryButtonData.title &&
      !!this.props.secondaryButtonData.onClick;

    return (
      <div className="pages-view-pp menu-view-pp">
        {isNewWorkspace
          ? this.renderNewPanelHeader()
          : this.renderOldPanelHeader()}
        <div
          className={util.cx({
            'pages-tree-container': true,
            'with-footer': viewWithFooter,
          })}
        >
          <CustomScroll ref="customScroll">
            {this.props.pages.length > 0 ? (
              <ul key="pages-container" className="pages-container-outer">
                {this.getPages()}
              </ul>
            ) : null}
            {this.props.emptyStateUrl ? (
              <platformPanels.PlatformPanelApplicationFrame
                token={this.generatePanelToken()}
                key="emptyStatePanel"
                url={this.props.emptyStateUrl}
                panelClass="appEmptyState"
              />
            ) : null}
          </CustomScroll>
        </div>
        {viewWithFooter ? (
          <footer key="add-container" className="add-container">
            <div className="main-action-row">
              <Tooltip
                content={tooltipAdd}
                shouldTranslate={false}
                className="tooltip-add-action"
                disabled={!tooltipAdd}
              >
                <Button
                  onClick={this.addPage(AddPageButton.Main)}
                  className="add-button"
                >
                  <symbols.symbol name="addPage" />
                  <span>{this.props.titleAdd}</span>
                </Button>
              </Tooltip>

              {hasSecondaryAction ? (
                <Tooltip
                  content={secondaryActionTooltipContent}
                  shouldTranslate={false}
                  className="secondary-action-container"
                >
                  <Button
                    onClick={onSecondaryActionClick}
                    className="secondary-action"
                  >
                    {this.getActionSymbol()}
                  </Button>
                </Tooltip>
              ) : null}
            </div>
            {showSecondaryActionRow && (
              <div className="secondary-action-row">
                <TextButton
                  onClick={this.props.secondaryButtonData.onClick}
                  shouldTranslate={false}
                >
                  {this.props.secondaryButtonData.title}
                </TextButton>
              </div>
            )}
          </footer>
        ) : null}
      </div>
    );
  }
}

export default PagesView;
