'use strict'

const singleTrackPlayerSection = require('../sections/music/singleTrackPlayerSection')
const singleTrackPlayerSection2 = require('../sections/music/singleTrackPlayerSection2')
const iTunesDownloadButtonSection = require('../sections/music/iTunesDownloadButtonSection')

module.exports = {
    singleTrackPlayerSection,
    singleTrackPlayerSection2,
    iTunesDownloadButtonSection
}
