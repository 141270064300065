let fetchPricesPromise: AnyFixMe;

export const loadShutterstockPrices = (loadFn: AnyFixMe) => {
  if (fetchPricesPromise) {
    return fetchPricesPromise;
  }

  fetchPricesPromise = loadFn();

  return fetchPricesPromise;
};
