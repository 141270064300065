'use strict'

const iTunesDownloadButtonSection = require('../sections/music/iTunesDownloadButtonSection')

const videoBoxSection = require('../sections/video/videoBoxSection')
const videoBoxTransparentSection = require('../sections/video/videoBoxTransparentSection')
const videoBoxMaskedSection = require('../sections/video/videoBoxMaskedSection')
const socialVideoSection = require('../sections/video/socialVideoSection')

module.exports = {
    socialVideoSection,
    videoBoxTransparentSection,
    videoBoxMaskedSection,
    videoBoxSection,
    iTunesDownloadButtonSection
}
