const i18n = require("@/i18n");

module.exports = {
  id: "Stylable_StylableButton_28",
  preset: { rect: { width: 135, height: 147, x: 0, y: 816 }, tags: null },
  structure: {
    parent: "comp-knzvqsjj",
    style: {
      styleType: "custom",
      componentClassName: "wixui.StylableButton",
      style: {
        properties: {
          "$st-css":
            ":import{\n    -st-from: 'wix-ui-santa/index.st.css';\n    -st-named: StylableButton\n}\n.root{\n    -st-extends: StylableButton;\n    transition: all 0.2s ease, visibility 0s;\n    border: 0px solid rgb(174, 99, 63);\n    border-radius: 200px;\n    background: no-repeat padding-box border-box 46% 53%/cover scroll wixMediaUrl(a3c153_8d1a945d2515486c824c292509617008~mv2.jpg,1920,1165)\n}\n.root:hover {\n    background: repeat padding-box border-box 0% 0%/auto auto scroll linear-gradient(180deg, rgba(40, 38, 38, 0.07) 0%, rgba(40, 38, 38, 0.07) 100%), no-repeat padding-box border-box 46% 53%/cover scroll wixMediaUrl(a3c153_8d1a945d2515486c824c292509617008~mv2.jpg,1920,1165);\n}\n.root:disabled {\n    background: #DBDBDB;\n}\n.root[disabled]{\n    background: #E2E2E2\n}\n.root[disabled]::label{\n    color: #8F8F8F\n}\n.root[disabled]::icon{\n    fill: #8F8F8F\n}\n.root::container{\n    transition: inherit\n}\n.root::label{\n    transition: inherit;\n    display: initial;\n    font-size: 20px;\n    font-weight: 400;\n    font-family: futura-lt-w01-light,sans-serif;\n    color: #282626;\n    letter-spacing: 0.2em\n}\n.root::icon{\n    transition: inherit;\n    fill: #FFFFFF;\n    width: 23px;\n    height: 23px;\n    display: none\n}",
        },
        propertiesSource: { "$st-css": "value" },
      },
      pageId: "",
      compId: "",
      type: "ComponentStyle",
      metaData: {
        isHidden: false,
        sig: "4ie-538",
        pageId: "c1dmp",
        schemaVersion: "1.0",
        isPreset: false,
      },
      skin: "wixui.skins.Skinless",
    },
    activeModes: {},
    data: {
      type: "StylableButton",
      metaData: {
        isHidden: false,
        sig: "x5x-22",
        pageId: "c1dmp",
        schemaVersion: "1.0",
        isPreset: false,
      },
      label: i18n.translate("buttons_text_preset_new"),
      svgId: "11062b_a9f863f00acf4ed6a7113f0eb05f6884.svg",
    },
    components: [],
    componentType: "wixui.StylableButton",
    id: "comp-kmdi3eb6",
    layout: {
      x: 14,
      fixedPosition: false,
      y: 828,
      scale: 1,
      height: 115,
      rotationInDegrees: 0,
      width: 115,
    },
    type: "Component",
    props: {
      type: "StylableButtonProperties",
      metaData: { schemaVersion: "1.0", sig: "hmj-1522", pageId: "c1dmp" },
    },
    metaData: { sig: "inq-343", pageId: "c1dmp" },
    skin: "wixui.skins.Skinless",
  },
};
