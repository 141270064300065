'use strict'
let sections = require('../../addPanelSectionsEN/addPanelSectionsEN')

function getSections() {
    return sections
}

// function setSections(s) {
//     sections = s;
// }

function applyTranslatedSections(languageSpecificAddPanelSections) {
    sections = require('./merge').merge(languageSpecificAddPanelSections)
}

module.exports = {
    getSections,
    applyTranslatedSections
    // setSections: setSections,
    // merge: require('./merge').merge
}
