// import * as commonKeyboardActions from '../util/commonKeyboardActions';
import experiment from 'experiment';
import keyboardShortcutsActionMap from '@/keyboardShortcuts';
import * as coreBi from '@/coreBi';
import { bi } from '@/stateManagement';
import type { EditorAPI } from '@/editorAPI';

const ADD_SECTION_PANEL_SHORTCUTS_CONFIG = {};
const keyboardMap = keyboardShortcutsActionMap.editor;

function addAddSectionPanelModeShortcuts(editorAPI: EditorAPI) {
  const keysToMerge: Record<string, Function> = {};
  const stageSizeExperimentIsOpen = experiment.isOpen('se_stageSize');

  keysToMerge[keyboardMap.CLOSE_PANELS.key] = () => {
    const shouldZoomBeFixed = stageSizeExperimentIsOpen
      ? editorAPI.zoomMode.isZoomedByUser()
      : false;

    if (shouldZoomBeFixed) {
      editorAPI.zoomMode.exitZoomMode({
        zoomScale: 0.5,
        biParams: { origin: 'keyboard' },
        clickedByUser: editorAPI.zoomMode.isZoomedByUser(),
      });
    } else {
      editorAPI.zoomMode.exitZoomMode({ biParams: { origin: 'keyboard' } });
    }

    editorAPI.store.dispatch(
      bi.actions.event(coreBi.events.editor.ESCAPE_BUTTON_CLICKED, {
        mode: 'add_section_panel',
      }),
    );
  };
  Object.assign(ADD_SECTION_PANEL_SHORTCUTS_CONFIG, keysToMerge);
}

export default {
  init(editorAPI: EditorAPI) {
    addAddSectionPanelModeShortcuts(editorAPI);
  },
  shortcuts: ADD_SECTION_PANEL_SHORTCUTS_CONFIG,
};
