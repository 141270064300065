import * as stateManagement from '@/stateManagement';
import { BaseDragApiKey, EditorAPIKey, EditorCoreApiKey } from '@/apis';
import type { EntryPoint } from '@/apilib';
import { BaseEntryScope, createEntryPoint } from '@/apilib';
import { measureAndReportFrameRate } from './measuring';
import { MeasureFrameRateStore } from './measureFrameRateStore';
import type { EditorAPI } from '@/editorAPI';
import type { EditorCoreApi } from '@/editorCore';
import type { BaseDragApi } from '@/rEditor';
import { subscribeStateToViewerChanges } from './subscribeStateToViewerChanges';

const { getViewerRendersCounter } = stateManagement.services.selectors;
const { isPerformingMouseMoveAction } = stateManagement.mouseActions.selectors;

export interface FrameMeasuringScope {
  editorAPI: EditorAPI;
  baseDragApi: BaseDragApi;
  editorCoreApi: EditorCoreApi;
  store: Scope['store'];
}

export class Scope extends BaseEntryScope {
  editorAPI: EditorAPI = this.useDependency(EditorAPIKey);
  baseDragApi: BaseDragApi = this.useDependency(BaseDragApiKey);
  editorCoreApi: EditorCoreApi = this.useDependency(EditorCoreApiKey);

  store = this.declareStore(MeasureFrameRateStore);
}

export const MeasureFrameRateEntrypoint: EntryPoint = createEntryPoint({
  name: 'MeasureFrameRateApi',
  Scope,
  async initialize(scope: Scope) {
    await scope.editorCoreApi.hooks.initReady.promise;

    subscribeStateToViewerChanges(scope);

    measureAndReportFrameRate(
      scope,
      getViewerRendersCounter,
      isPerformingMouseMoveAction,
      stateManagement.bi.actions.event,
      2,
    );
  },
});
