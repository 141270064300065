import React from 'react';
import {
  Composites,
  Divider,
  RadioButtons,
  TextLabel,
  Text,
} from '@wix/wix-base-ui';

import { hoc } from '@/util';
import { translate } from '@/i18n';
import type { PagesData, TpaInnerRouteObject } from 'types/documentServices';

import { convertPageIdToLinkPageId } from '../../utils';
import { LINK_TYPES } from '../../constants';
import { PageSelection } from '../PageSelection/PageSelection';
import { mapStateToProps, mapDispatchToProps } from './PageLink.mapper';
import { RelAttribute } from '../RelAttribute/RelAttribute';
import type { TPageLink } from '../../types';
import { isResponsiveEditor } from '@wix/santa-editor-utils';

const {
  connect,
  STORES: { EDITOR_API },
} = hoc;

export type PageLinkComponentProps = {
  hideLinkTarget?: boolean;
  pages: PagesData[];
  pageTabPageDropDownTitle?: string;
  link: TPageLink | null;
  onValidationUpdate(isValid: boolean, doneBtnTooltip?: string): void;
  onLinkChange(link: TPageLink): void;
  onCreateButtonClick(): void;
  origin: string;
} & ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>;

interface PageLinkComponentState {
  prevLink: TPageLink;
  link: TPageLink;
  shouldDisableButton?: boolean;
  isRouteLoading: boolean;
  isFetchingPage: boolean;
}

export class PageLinkComponent extends React.Component<
  PageLinkComponentProps,
  PageLinkComponentState
> {
  constructor(props: PageLinkComponentProps) {
    super(props);

    const link = props.link || props.createDefaultData('PageLink');
    if (!link.rel) {
      link.rel = [];
    }

    if (link?.routerId) {
      this.state = {
        prevLink: link,
        link,
        isRouteLoading: true,
        isFetchingPage: true,
      };
      this.fetchAndUpdateLinkState(link);
    } else {
      this.state = {
        prevLink: link,
        link,
        isRouteLoading: false,
        isFetchingPage: false,
      };
    }
  }

  static getDerivedStateFromProps(
    props: PageLinkComponentProps,
    state: PageLinkComponentState,
  ) {
    const prevLink = state.prevLink || ({} as TPageLink);
    const nextLink =
      prevLink?.pageId !== props.link?.pageId ? props.link : state.link;

    return {
      prevLink: props.link,
      link: nextLink,
    };
  }

  componentDidMount() {
    this.props.onLinkChange(this.state.link);
    this.props.onValidationUpdate(
      !this.state.isRouteLoading &&
        !this.state.isFetchingPage &&
        Boolean(this.state.link?.pageId || this.state.link?.routerId),
    );
  }

  componentDidUpdate(_prevProps: AnyFixMe, prevState: AnyFixMe) {
    const { onValidationUpdate, onLinkChange } = this.props;
    if (prevState.link !== this.state.link) {
      onLinkChange(this.state.link);
    }
    if (
      prevState.isRouteLoading !== this.state.isRouteLoading ||
      prevState.isFetchingPage !== this.state.isFetchingPage ||
      prevState.link !== this.state.link
    ) {
      if (this.state.shouldDisableButton) {
        onValidationUpdate(
          !this.state.shouldDisableButton,
          'LINK_PANEL_PAGE_INNER_ROUTES_DROP_DOWN_EMPTY_DISABLED_BUTTON_TOOLTIP',
        );
      } else {
        onValidationUpdate(
          !this.state.isRouteLoading &&
            !this.state.isFetchingPage &&
            Boolean(this.state.link?.pageId || this.state.link?.routerId),
        );
      }
    }
  }

  private handleInitialInnerRoutingFetch = () => {
    this.setState({
      isRouteLoading: false,
    });
  };

  private async getPageFromInnerRoute(link: TPageLink) {
    const { routerId, innerRoute: preDefinedInnerRoute } = link;
    const { getPageFromInnerRoute } = this.props;

    return await getPageFromInnerRoute(routerId, preDefinedInnerRoute);
  }

  private async fetchAndUpdateLinkState(link: TPageLink) {
    const { createDefaultData } = this.props;
    const { pageId, shouldDisableButton, innerRoute } =
      await this.getPageFromInnerRoute(link);

    if (pageId) {
      this.setState({
        link: {
          ...link,
          ...(innerRoute ? { innerRoute } : {}),
          pageId: convertPageIdToLinkPageId(pageId),
        },
        shouldDisableButton,
        isFetchingPage: false,
      });
    } else {
      this.setState({
        link: createDefaultData('PageLink'),
        shouldDisableButton,
        isFetchingPage: false,
      });
    }
  }

  private setPage = (page: PagesData) => {
    const { link } = this.state;
    const pageId = convertPageIdToLinkPageId(page?.id);

    this.setState({
      shouldDisableButton: false,
      isRouteLoading: false,
      link: {
        target: link.target,
        type: 'PageLink',
        pageId,
        rel: link.rel,
      },
    });
  };

  private setTarget = (target: string) => {
    const { link } = this.state;
    this.setState({
      shouldDisableButton: false,
      link: {
        ...link,
        target,
      },
    });
  };

  private setTPAPage = (page: PagesData, innerRoute?: TpaInnerRouteObject) => {
    const { link } = this.state;
    const pageId = convertPageIdToLinkPageId(page?.id);

    this.setState({
      shouldDisableButton: false,
      isRouteLoading: true,
      link: {
        type: 'TpaPageLink',
        pageId,
        appDefinitionId: page.managingAppDefId,
        rel: link.rel,
        itemTypeIdentifier: innerRoute?.itemTypeIdentifier,
        itemId: innerRoute?.itemId,
        path: innerRoute?.path,
      },
    });
  };

  private setTPAInnerRoute = (innerRoute: TpaInnerRouteObject) => {
    this.setState(({ link }) => ({
      shouldDisableButton: false,
      isRouteLoading: false,
      link: {
        ...link,
        itemTypeIdentifier: innerRoute.itemTypeIdentifier,
        itemId: innerRoute.itemId,
        path: innerRoute.path,
      },
    }));
  };

  private setDynamicPage = async (page: PagesData, routerId: string) => {
    const { link } = this.state;
    const pageId = convertPageIdToLinkPageId(page?.id);
    const newLink = {
      target: link.target,
      routerId,
      type: 'DynamicPageLink',
      rel: link.rel,
      pageId,
      innerRoute: '/',
    };
    const { shouldDisableButton } = await this.getPageFromInnerRoute(newLink);

    this.setState({
      shouldDisableButton,
      isRouteLoading: false,
      link: newLink,
    });
  };

  private setInnerRoute = (innerRoute: string) => {
    const { link } = this.state;
    this.setState({
      isRouteLoading: false,
      link: {
        ...link,
        innerRoute,
      },
    });
  };

  private handleSelectionAddPageClick = () => {
    this.props.addPage('linkPanelAddPageClick', this.state.link.target);
  };

  private onRelChange = (newRelValue: string[]) => {
    this.setState({
      link: {
        ...this.state.link,
        rel: newRelValue,
      },
    });
  };

  private renderRelAttribute = () => {
    return (
      <>
        <Divider key="externalTargetDivider" />
        <RelAttribute
          origin={this.props.origin}
          rel={this.state.link?.rel}
          onChange={this.onRelChange}
          category={LINK_TYPES.ExternalLink}
        />
      </>
    );
  };

  render() {
    const { isFetchingPage } = this.state;
    const { hideLinkTarget, pageTabPageDropDownTitle, origin, link } =
      this.props;
    // Hide target for dynamic and TPA links because currently their DM schemas don't support it
    const shouldHideLinkTarget =
      hideLinkTarget || link?.routerId || link?.appDefinitionId;

    if (isFetchingPage) {
      return null;
    }

    return (
      <section>
        <PageSelection
          origin={origin}
          pages={this.props.pages}
          link={this.state.link}
          onPageSelect={this.setPage}
          onDynamicPageSelect={this.setDynamicPage}
          onTpaPageSelect={this.setTPAPage}
          onTpaInnerRouteSelect={this.setTPAInnerRoute}
          onInnerRouteSelect={this.setInnerRoute}
          onInitialInnerRoutingFetch={this.handleInitialInnerRoutingFetch}
          pageDropDownTitle={translate(
            pageTabPageDropDownTitle || 'LINK_PANEL_PAGE_DROP_DOWN_LABEL',
          )}
          addNewItemTitle={
            !isResponsiveEditor() && !this.props.isMobileEditor
              ? 'LINK_PANEL_PAGE_DROPDOWN_ADD_NEW_PAGE'
              : undefined
          }
          onAddNewItemClick={
            !isResponsiveEditor() && !this.props.isMobileEditor
              ? this.handleSelectionAddPageClick
              : undefined
          }
        />
        {!shouldHideLinkTarget && (
          <Composites.RadioButtonsLabeled key="pageTargetRadio">
            <TextLabel value="LINK_PANEL_WEB_ADDRESS_OPTIONS_LABEL" />
            <RadioButtons
              automationId="page-link-select-target-radio-buttons"
              dataHook="select-target-radio-buttons"
              value={this.state.link?.target}
              onChange={this.setTarget}
              options={[
                {
                  value: '_blank',
                  label: 'LINK_PANEL_WEB_ADDRESS_OPTION1',
                },
                {
                  value: '_self',
                  label: 'LINK_PANEL_WEB_ADDRESS_OPTION2',
                },
              ]}
              className="open-target-radio-buttons"
            />
          </Composites.RadioButtonsLabeled>
        )}

        {!shouldHideLinkTarget && this.state.link?.target === '_blank' && (
          <div className="link-page-description">
            <Text size="small" key="linkDisclaimer" enableEllipsis={false}>
              LINK_PANEL_PAGE_DESCRIPTION
            </Text>
          </div>
        )}

        {this.renderRelAttribute()}
      </section>
    );
  }
}

export const PageLink = connect(
  EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(PageLinkComponent);

PageLink.pure = PageLinkComponent;
