import React from 'react';
import constants from '@/constants';
import { cx } from '@/util';

interface PaginationControllerProps {
  label: string;
  className?: string;
  onNext: () => void;
  onPrev: () => void;
  selectFocusedContainer: () => void;
  componentUIColor: ValueOf<typeof constants.COMPONENT_UI_COLORS>;
  componentUITheme: ValueOf<typeof constants.COMPONENT_UI_THEMES>;
}

export default class extends React.Component<PaginationControllerProps> {
  static displayName = 'paginationController';

  render() {
    return (
      <div
        className={cx(
          this.props.className,
          this.getPaginationControllerClasses(),
        )}
      >
        <span onClick={this.props.onPrev} className="arrow prev-arrow" />
        <span
          onClick={this.props.selectFocusedContainer}
          className="middle-label"
        >
          {this.props.label}
        </span>
        <span onClick={this.props.onNext} className="arrow next-arrow" />
      </div>
    );
  }

  getPaginationControllerClasses() {
    return {
      'pagination-controller': true,
      'components-ui-color-orange':
        this.props.componentUIColor === constants.COMPONENT_UI_COLORS.ORANGE,
      'components-ui-theme-light':
        this.props.componentUITheme === constants.COMPONENT_UI_THEMES.LIGHT,
    };
  }
}
