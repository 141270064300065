'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')
const i18n = require('@/i18n')

module.exports = {
    'type': addPanelDataConsts.SECTIONS_TYPES.PRESET,
    'hide': false,
    'title': 'add_section_label_time_input',
    'subNavigationTitle': 'add_section_label_time_input',
    'topTitle': "add_section_label_time_input",
    'presetTitle': "add_section_label_time_input",
    'tooltipTitle': "add_section_label_time_input",
    "automationId": "add-panel-section-timePickerSection",
    'showSectionHeader': true,
    'subNavigationHide': true,
    'additionalBehaviours': {
        'labelMode': addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
        'infoIcon': addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
        'hoverImageAction': addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
        'iconEnabledForComps': {}
    },
    'props': {
        'image': 'addPanelData/sections/developer_en/timePickerSection_en.png',
        'imageHover': null,
        'items': [{
            'id': 'User_Input_TimePicker_1',
            'structure': {
                'type': 'Component',
                'skin': 'wixui.skins.TimePicker',
                'layout': {
                    'width': 142,
                    'height': 50,
                    'x': 10,
                    'y': 21,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wixui.TimePicker',
                'data': {
                    'type': 'TimePickerData',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'value': '14:30:00.000',
                    'step': 15,
                    'placeholder': i18n.translate('time_input_settings_show_text_placeholder_default_text')
                },
                'props': {
                    'type': 'TimePickerProperties',
                    'metaData': {'schemaVersion': '1.0', 'autoGenerated': false},
                    'alignment': 'center',
                    'useAmPmFormat': false,
                    'controller': 'text',
                    'initialTime': 'value',
                    'margin': 15
                },
                'mobileHints': {
                    'type': 'MobileHints',
                    'recommendedScale': 1,
                    'recommendedWidth': 280,
                    'recommendedHeight': 40
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {
                            'backgroundColor': '#FFFFFF',
                            'borderColor': '#919191',
                            'borderRadius': '0px',
                            'borderWidth': '2',
                            'boxShadow': '0px 1px 4px 0px rgba(0,0,0,0.6)',
                            'boxShadowToggleOn-boxShadow': 'false',
                            'disabledBackgroundColor': '#FFFFFF',
                            'disabledBorderColor': '#DBDBDB',
                            'disabledBorderWidth': '1px',
                            'disabledTextColor': '#D4D4D4',
                            'errorBackgroundColor': '#FFFFFF',
                            'errorBorderColor': '#F64D43',
                            'errorBorderWidth': '1px',
                            'focusBackgroundColor': '#FFFFFF',
                            'focusBorderColor': '#000000',
                            'focusBorderWidth': '2',
                            'hoverBackgroundColor': '#F2FAF9',
                            'hoverBorderColor': '#000000',
                            'hoverBorderWidth': '2',
                            'iconColor': '#000000',
                            'placeholderTextColor': '#525252',
                            'textColor': '#000000',
                            'textFont': 'normal normal normal 14px/1.4em avenir-lt-w01_35-light1475496',
                            'labelFont': 'normal normal normal 14px/1.4em avenir-lt-w01_35-light1475496',
                            'txtlbl': '#000000',
                            'txtlblrq': '#000000'
                        },
                        'propertiesSource': {
                            'backgroundColor': 'value',
                            'borderColor': 'value',
                            'borderWidth': 'value',
                            'disabledBackgroundColor': 'value',
                            'disabledBorderColor': 'value',
                            'disabledTextColor': 'value',
                            'errorBorderColor': 'value',
                            'focusBackgroundColor': 'value',
                            'focusBorderColor': 'value',
                            'focusBorderWidth': 'value',
                            'hoverBackgroundColor': 'value',
                            'hoverBorderColor': 'value',
                            'hoverBorderWidth': 'value',
                            'iconColor': 'value',
                            'textFont': 'value',
                            'labelFont': 'value',
                            'txtlbl': 'value',
                            'txtlblrq': 'value'
                        }
                    },
                    'componentClassName': 'wixui.TimePicker',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'custom',
                    'skin': 'wixui.skins.TimePicker'
                },
                'activeModes': {},
                'id': 'comp-jlm4vfeu'
            },
            'preset': {'rect': {'width': 162, 'height': 85, 'x': 0, 'y': 0}, 'tags': null}
        }, {
            'id': 'User_Input_TimePicker_2',
            'structure': {
                'type': 'Component',
                'skin': 'wixui.skins.TimePicker',
                'layout': {
                    'width': 142,
                    'height': 50,
                    'x': 172,
                    'y': 21,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wixui.TimePicker',
                'data': {
                    'type': 'TimePickerData',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'value': '14:30:00.000',
                    'step': 15,
                    'placeholder': i18n.translate('time_input_settings_show_text_placeholder_default_text')
                },
                'props': {
                    'type': 'TimePickerProperties',
                    'metaData': {'schemaVersion': '1.0', 'autoGenerated': false},
                    'alignment': 'left',
                    'useAmPmFormat': true,
                    'controller': 'stepper',
                    'initialTime': 'value',
                    'margin': 15
                },
                'mobileHints': {
                    'type': 'MobileHints',
                    'recommendedScale': 1,
                    'recommendedWidth': 280,
                    'recommendedHeight': 40
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {
                            'backgroundColor': '#FFFFFF',
                            'borderColor': '#FFFFFF',
                            'borderRadius': '0px',
                            'borderWidth': '0',
                            'boxShadow': '0.00px 2.00px 0px 0px rgba(140,132,250,1)',
                            'boxShadowToggleOn-boxShadow': 'true',
                            'disabledBackgroundColor': '#FFFFFF',
                            'disabledBorderColor': '#DBDBDB',
                            'disabledBorderWidth': '0',
                            'disabledTextColor': '#DBDBDB',
                            'errorBackgroundColor': '#FFFFFF',
                            'errorBorderColor': '#F64D43',
                            'errorBorderWidth': '1px',
                            'focusBackgroundColor': '#FFFFFF',
                            'focusBorderColor': '#FFFFFF',
                            'focusBorderWidth': '0',
                            'hoverBackgroundColor': '#FFFFFF',
                            'hoverBorderColor': '#FFFFFF',
                            'hoverBorderWidth': '0',
                            'iconColor': '#8C84FA',
                            'placeholderTextColor': '#A9A9A9',
                            'textColor': '#8C84FA',
                            'textFont': 'normal normal normal 14px/1.4em futura-lt-w01-light',
                            'fntlbl': 'normal normal normal 14px/1.4em futura-lt-w01-light',
                            'txtlbl': '#8C84FA',
                            'txtlblrq': '#8C84FA'
                        },
                        'propertiesSource': {
                            'borderColor': 'value',
                            'borderWidth': 'value',
                            'boxShadow': 'value',
                            'disabledBorderWidth': 'value',
                            'disabledTextColor': 'value',
                            'errorBorderColor': 'value',
                            'focusBackgroundColor': 'value',
                            'focusBorderColor': 'value',
                            'focusBorderWidth': 'value',
                            'hoverBackgroundColor': 'value',
                            'hoverBorderColor': 'value',
                            'hoverBorderWidth': 'value',
                            'iconColor': 'value',
                            'textColor': 'value',
                            'fntlbl': 'value',
                            'txtlbl': 'value',
                            'txtlblrq': 'value'
                        }
                    },
                    'componentClassName': 'wixui.TimePicker',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'custom',
                    'skin': 'wixui.skins.TimePicker'
                },
                'activeModes': {},
                'id': 'comp-jlm4vfd9'
            },
            'preset': {'rect': {'width': 162, 'height': 85, 'x': 162, 'y': 0}, 'tags': null}
        }, {
            'id': 'User_Input_TimePicker_3',
            'structure': {
                'type': 'Component',
                'skin': 'wixui.skins.TimePicker',
                'layout': {
                    'width': 142,
                    'height': 50,
                    'x': 10,
                    'y': 101,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wixui.TimePicker',
                'data': {
                    'type': 'TimePickerData',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'value': '14:30:00.000',
                    'step': 15,
                    'placeholder': i18n.translate('time_input_settings_show_text_placeholder_default_text')
                },
                'props': {
                    'type': 'TimePickerProperties',
                    'metaData': {'schemaVersion': '1.0', 'autoGenerated': false},
                    'alignment': 'left',
                    'useAmPmFormat': true,
                    'controller': 'dropdown',
                    'initialTime': 'value',
                    'margin': 15
                },
                'mobileHints': {
                    'type': 'MobileHints',
                    'recommendedScale': 1,
                    'recommendedWidth': 280,
                    'recommendedHeight': 40
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {
                            'backgroundColor': '#FFFFFF',
                            'borderColor': '#42CCCC',
                            'borderRadius': '0px',
                            'borderWidth': '1px',
                            'boxShadow': '0.00px 2.00px 0px 0px rgba(66,204,204,1)',
                            'boxShadowToggleOn-boxShadow': 'true',
                            'disabledBackgroundColor': '#FFFFFF',
                            'disabledBorderColor': '#DBDBDB',
                            'disabledBorderWidth': '1px',
                            'disabledTextColor': '#D4D4D4',
                            'errorBackgroundColor': '#FFFFFF',
                            'errorBorderColor': '#F64D43',
                            'errorBorderWidth': '1px',
                            'focusBackgroundColor': '#FFFFFF',
                            'focusBorderColor': '#408F8F',
                            'focusBorderWidth': '1px',
                            'hoverBackgroundColor': '#FAFAFA',
                            'hoverBorderColor': '#42CCCC',
                            'hoverBorderWidth': '1px',
                            'iconColor': '#67D6D6',
                            'placeholderTextColor': '#A3E6E6',
                            'textColor': '#42CCCC',
                            'textFont': 'normal normal 700 14px/1.4em georgia',
                            'fntlbl': 'normal normal 700 14px/1.4em georgia',
                            'txtlbl': '#42CCCC',
                            'txtlblrq': '#42CCCC'
                        },
                        'propertiesSource': {
                            'borderColor': 'value',
                            'boxShadow': 'value',
                            'disabledTextColor': 'value',
                            'errorBorderColor': 'value',
                            'focusBorderColor': 'value',
                            'hoverBackgroundColor': 'value',
                            'hoverBorderColor': 'value',
                            'iconColor': 'value',
                            'placeholderTextColor': 'value',
                            'textColor': 'value',
                            'textFont': 'value',
                            'fntlbl': 'value',
                            'txtlbl': 'value',
                            'txtlblrq': 'value'
                        }
                    },
                    'componentClassName': 'wixui.TimePicker',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'custom',
                    'skin': 'wixui.skins.TimePicker'
                },
                'activeModes': {},
                'id': 'comp-jlm4vfeb'
            },
            'preset': {'rect': {'width': 162, 'height': 80, 'x': 0, 'y': 85}, 'tags': null}
        }, {
            'id': 'User_Input_TimePicker_4',
            'structure': {
                'type': 'Component',
                'skin': 'wixui.skins.TimePicker',
                'layout': {
                    'width': 142,
                    'height': 50,
                    'x': 172,
                    'y': 101,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wixui.TimePicker',
                'data': {
                    'type': 'TimePickerData',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'value': '14:30:00.000',
                    'step': 15,
                    'placeholder': i18n.translate('time_input_settings_show_text_placeholder_default_text')
                },
                'props': {
                    'type': 'TimePickerProperties',
                    'metaData': {'schemaVersion': '1.0', 'autoGenerated': false},
                    'alignment': 'left',
                    'useAmPmFormat': false,
                    'controller': 'stepper',
                    'initialTime': 'value',
                    'margin': 15
                },
                'mobileHints': {
                    'type': 'MobileHints',
                    'recommendedScale': 1,
                    'recommendedWidth': 280,
                    'recommendedHeight': 40
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {
                            'backgroundColor': '#4BDB7B',
                            'borderColor': '#4BDB7B',
                            'borderRadius': '6px 6px 6px 6px',
                            'borderWidth': '0',
                            'boxShadow': '0px 1px 4px 0px rgba(0,0,0,0.6)',
                            'boxShadowToggleOn-boxShadow': 'false',
                            'disabledBackgroundColor': '#DBDBDB',
                            'disabledBorderColor': '#DBDBDB',
                            'disabledBorderWidth': '0',
                            'disabledTextColor': '#FFFFFF',
                            'errorBackgroundColor': '#F64D43',
                            'errorBorderColor': '#ff4040',
                            'errorBorderWidth': '0',
                            'focusBackgroundColor': '#4BDB7B',
                            'focusBorderColor': '#4BDB7B',
                            'focusBorderWidth': '0',
                            'hoverBackgroundColor': '#5EE08A',
                            'hoverBorderColor': '#5EE08A',
                            'hoverBorderWidth': '0',
                            'iconColor': '#FFFFFF',
                            'placeholderTextColor': '#FFFFFF',
                            'textColor': '#FFFFFF',
                            'textFont': 'normal normal normal 14px/1.4em bree-w01-thin-oblique',
                            'fntlbl': 'normal normal normal 14px/1.4em bree-w01-thin-oblique',
                            'txtlbl': '#4BDB7B',
                            'txtlblrq': '#4BDB7B'
                        },
                        'propertiesSource': {
                            'backgroundColor': 'value',
                            'borderColor': 'value',
                            'borderRadius': 'value',
                            'borderWidth': 'value',
                            'disabledBackgroundColor': 'value',
                            'disabledBorderWidth': 'value',
                            'disabledTextColor': 'value',
                            'errorBackgroundColor': 'value',
                            'errorBorderWidth': 'value',
                            'focusBackgroundColor': 'value',
                            'focusBorderColor': 'value',
                            'focusBorderWidth': 'value',
                            'hoverBackgroundColor': 'value',
                            'hoverBorderColor': 'value',
                            'hoverBorderWidth': 'value',
                            'iconColor': 'value',
                            'textColor': 'value',
                            'textFont': 'value',
                            'fntlbl': 'value',
                            'txtlbl': 'value',
                            'txtlblrq': 'value'
                        }
                    },
                    'componentClassName': 'wixui.TimePicker',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'custom',
                    'skin': 'wixui.skins.TimePicker'
                },
                'activeModes': {},
                'id': 'comp-jlm4vfco'
            },
            'preset': {'rect': {'width': 162, 'height': 80, 'x': 162, 'y': 85}, 'tags': null}
        }, {
            'id': 'User_Input_TimePicker_5',
            'structure': {
                'type': 'Component',
                'skin': 'wixui.skins.TimePicker',
                'layout': {
                    'width': 142,
                    'height': 50,
                    'x': 10,
                    'y': 181,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wixui.TimePicker',
                'data': {
                    'type': 'TimePickerData',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'value': '14:30:00.000',
                    'step': 15,
                    'placeholder': i18n.translate('time_input_settings_show_text_placeholder_default_text')
                },
                'props': {
                    'type': 'TimePickerProperties',
                    'metaData': {'schemaVersion': '1.0', 'autoGenerated': false},
                    'alignment': 'center',
                    'useAmPmFormat': false,
                    'controller': 'text',
                    'initialTime': 'value',
                    'margin': 15
                },
                'mobileHints': {
                    'type': 'MobileHints',
                    'recommendedScale': 1,
                    'recommendedWidth': 280,
                    'recommendedHeight': 40
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {
                            'backgroundColor': '#FFFFFF',
                            'borderColor': '#FFFFFF',
                            'borderRadius': '4px 4px 4px 4px',
                            'borderWidth': '0',
                            'boxShadow': '0.00px 3.00px 10px 1px rgba(0,0,0,0.08)',
                            'boxShadowToggleOn-boxShadow': 'true',
                            'disabledBackgroundColor': '#FFFFFF',
                            'disabledBorderColor': '#CCCCCC',
                            'disabledBorderWidth': '2',
                            'disabledTextColor': '#CCCCCC',
                            'errorBackgroundColor': '#FFFFFF',
                            'errorBorderColor': '#F64D43',
                            'errorBorderWidth': '2',
                            'focusBackgroundColor': '#FFFFFF',
                            'focusBorderColor': '#E8E8E8',
                            'focusBorderWidth': '2',
                            'hoverBackgroundColor': '#FFFFFF',
                            'hoverBorderColor': '#F5F5F5',
                            'hoverBorderWidth': '1px',
                            'iconColor': '#000000',
                            'placeholderTextColor': '#000000',
                            'textColor': '#000000',
                            'textFont': 'normal normal normal 12px/1.4em lulo-clean-w01-one-bold',
                            'fntlbl': 'normal normal normal 12px/1.4em lulo-clean-w01-one-bold',
                            'txtlbl': '#000000',
                            'txtlblrq': '#000000'
                        },
                        'propertiesSource': {
                            'borderColor': 'value',
                            'borderRadius': 'value',
                            'borderWidth': 'value',
                            'boxShadow': 'value',
                            'disabledBorderColor': 'value',
                            'disabledBorderWidth': 'value',
                            'disabledTextColor': 'value',
                            'errorBorderColor': 'value',
                            'errorBorderWidth': 'value',
                            'focusBorderColor': 'value',
                            'focusBorderWidth': 'value',
                            'hoverBackgroundColor': 'value',
                            'hoverBorderColor': 'value',
                            'textFont': 'value',
                            'fntlbl': 'value',
                            'txtlbl': 'value',
                            'txtlblrq': 'value'
                        }
                    },
                    'componentClassName': 'wixui.TimePicker',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'custom',
                    'skin': 'wixui.skins.TimePicker'
                },
                'activeModes': {},
                'id': 'comp-jlm4vfdr'
            },
            'preset': {'rect': {'width': 162, 'height': 85, 'x': 0, 'y': 163}, 'tags': null}
        }, {
            'id': 'User_Input_TimePicker_6',
            'structure': {
                'type': 'Component',
                'skin': 'wixui.skins.TimePicker',
                'layout': {
                    'width': 142,
                    'height': 50,
                    'x': 172,
                    'y': 181,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wixui.TimePicker',
                'data': {
                    'type': 'TimePickerData',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'value': '14:30:00.000',
                    'step': 15,
                    'placeholder': i18n.translate('time_input_settings_show_text_placeholder_default_text')
                },
                'props': {
                    'type': 'TimePickerProperties',
                    'metaData': {'schemaVersion': '1.0', 'autoGenerated': false},
                    'alignment': 'center',
                    'useAmPmFormat': true,
                    'controller': 'text',
                    'initialTime': 'value',
                    'margin': 15
                },
                'mobileHints': {
                    'type': 'MobileHints',
                    'recommendedScale': 1,
                    'recommendedWidth': 280,
                    'recommendedHeight': 40
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {
                            'backgroundColor': '#FFFFFF',
                            'borderColor': '#C6E2F7',
                            'borderRadius': '6px 6px 6px 6px',
                            'borderWidth': '1px',
                            'boxShadow': '0px 1px 4px 0px rgba(0,0,0,0.6)',
                            'boxShadowToggleOn-boxShadow': 'false',
                            'disabledBackgroundColor': '#FFFFFF',
                            'disabledBorderColor': '#D4D4D4',
                            'disabledBorderWidth': '2',
                            'disabledTextColor': '#D4D4D4',
                            'errorBackgroundColor': '#FFFFFF',
                            'errorBorderColor': '#F64D43',
                            'errorBorderWidth': '1px',
                            'focusBackgroundColor': '#FFFFFF',
                            'focusBorderColor': '#00A6FF',
                            'focusBorderWidth': '1px',
                            'hoverBackgroundColor': '#F1FAFF',
                            'hoverBorderColor': '#00A6FF',
                            'hoverBorderWidth': '1px',
                            'iconColor': '#C6E2F7',
                            'placeholderTextColor': '#00A6FF',
                            'textColor': '#3899EC',
                            'textFont': 'normal normal normal 15px/1.4em helvetica-w01-light',
                            'fntlbl': 'normal normal normal 15px/1.4em helvetica-w01-light',
                            'txtlbl': '#3899EC',
                            'txtlblrq': '#3899EC'
                        },
                        'propertiesSource': {
                            'borderColor': 'value',
                            'borderRadius': 'value',
                            'disabledBorderColor': 'value',
                            'disabledBorderWidth': 'value',
                            'disabledTextColor': 'value',
                            'errorBorderColor': 'value',
                            'focusBorderColor': 'value',
                            'hoverBackgroundColor': 'value',
                            'hoverBorderColor': 'value',
                            'iconColor': 'value',
                            'textColor': 'value',
                            'textFont': 'value',
                            'fntlbl': 'value',
                            'txtlbl': 'value',
                            'txtlblrq': 'value'
                        }
                    },
                    'componentClassName': 'wixui.TimePicker',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'custom',
                    'skin': 'wixui.skins.TimePicker'
                },
                'activeModes': {},
                'id': 'comp-jlm4vfc4'
            },
            'preset': {'rect': {'width': 162, 'height': 85, 'x': 162, 'y': 163}, 'tags': null}
        }],
        'compTypes': ['wixui.TimePicker']
    },
    'help': {'hide': false, 'text': 'add_section_label_time_input_tooltip_text'}
}
