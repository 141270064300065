import * as util from '@/util';
import type { AppData } from '../components/privateAppsProvider/privateAppsProvider';

export const TEST_VERSION = 'latest';

export const editInBlocks = (appDefinitionId: string, httpReferrer: string) => {
  window.open(
    util.appStudioUtils.createEditAppURL(
      {
        appDefinitionId,
      },
      httpReferrer,
    ),
    '_blank',
  );
};

export const hasCodePackage = ({ components }: AppData) =>
  components?.some(({ type }) => type === 'CODE_PACKAGE');

export const isTestVersion = ({ appFields }: AppData) =>
  appFields.installedVersion === TEST_VERSION;

export const hasMajorUpdate = ({ version, latestVersion }: AppData) =>
  latestVersion && util.appStudioUtils.isMajorUpdate(version, latestVersion);

export const hasUpdates = ({ version, latestVersion }: AppData) =>
  latestVersion && latestVersion !== version;
