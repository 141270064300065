'use strict'

module.exports = {
    "id": "add_preset_popups_Sale_2",
    "structure": {
        "type": "Page",
        "id": "h2jd7",
        "componentTypeForBI": "wysiwyg.viewer.components.PopupContainer",
        "components": [{
            "type": "Container",
            "id": "comp-ilwc05eg",
            "components": [{
                "type": "Component",
                "id": "comp-ilwcn47c",
                "skin": "wysiwyg.viewer.skins.line.SolidLine",
                "layout": {
                    "width": 90,
                    "height": 5,
                    "x": 587,
                    "y": 124,
                    "scale": 1,
                    "rotationInDegrees": 90,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.FiveGridLine",
                "props": {
                    "type": "FiveGridLineProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "fullScreenModeOn": false,
                    "id": "propItem-ilwcn47g"
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {"alpha-brd": "1", "brd": "#000000", "lnw": "1"},
                        "propertiesSource": {"brd": "value", "lnw": "value"}
                    },
                    "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.line.SolidLine",
                    "id": "style-ilwcrnfk"
                }
            }, {
                "type": "Component",
                "id": "comp-im0ao03f",
                "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                "layout": {
                    "width": 497,
                    "height": 120,
                    "x": 71,
                    "y": 64,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "text": "<h2 class=\"font_2\" style=\"font-size:40px\"><span style=\"font-family:anton,sans-serif\"><span style=\"color:#2F2E2E\"><span style=\"font-size:40px\"><span style=\"letter-spacing:0.2em\">50% OFF</span></span></span><span class=\"color_11\"><span style=\"font-size:40px\"><span style=\"letter-spacing:0.2em\"> </span></span></span><span style=\"color:#FFFFFE;\"><span style=\"font-size:40px\"><span style=\"letter-spacing:0.2em\">ALL SUMMER COLLECTION</span></span></span></span></h2>\n",
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": [],
                    "id": "dataItem-im0ao03i"
                },
                "props": {
                    "type": "WRichTextProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "brightness": 1,
                    "packed": true,
                    "id": "propItem-ipf49460"
                },
                "style": "txtNew"
            }, {
                "type": "Component",
                "id": "comp-im0ar6yw",
                "layout": {
                    "width": 265,
                    "height": 40,
                    "x": 663,
                    "y": 73,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "text": "<p class=\"font_8\" style=\"font-size:15px; line-height:1.3em\"><span style=\"color:#FFFFFE\"><span style=\"font-size:15px\"><span style=\"font-family:raleway,sans-serif\">Check out our amazing offers. </span></span></span></p>\n\n<p class=\"font_8\" style=\"font-size:15px; line-height:1.3em\"><span style=\"color:#FFFFFE\"><span style=\"font-size:15px\"><span style=\"font-family:raleway,sans-serif\">They&rsquo;re too good to miss!</span></span></span></p>\n",
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": [],
                    "id": "dataItem-im0ar6z2"
                },
                "props": {
                    "type": "WRichTextProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "brightness": 1,
                    "packed": true,
                    "id": "propItem-im0ar6z4"
                },
                "style": "txtNew"
            }, {
                "type": "Component",
                "id": "comp-imer65wh",
                "skin": "wysiwyg.viewer.skins.button.BasicButton",
                "layout": {
                    "width": 183,
                    "height": 40,
                    "x": 663,
                    "y": 129,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.PopupCloseTextButton",
                "data": {
                    "type": "LinkableButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "label": "Shop Now",
                    "id": "dataItem-imer65wo"
                },
                "props": {
                    "type": "ButtonProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "align": "center",
                    "margin": 0,
                    "id": "propItem-imer65wq"
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-bg": "1",
                            "alpha-bgh": "1",
                            "alpha-brd": "1",
                            "alpha-brdh": "1",
                            "alpha-txt": "1",
                            "alpha-txth": "1",
                            "bg": "#2F2E2E",
                            "bgh": "#4D4D4D",
                            "boxShadowToggleOn-shd": "false",
                            "brd": "#BD1515",
                            "brdh": "#B08484",
                            "brw": "0",
                            "fnt": "normal normal normal 15px/1.4em raleway",
                            "rd": "0px",
                            "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                            "txt": "#FFFFFF",
                            "txth": "#FFFFFF"
                        },
                        "propertiesSource": {
                            "bg": "value",
                            "bgh": "value",
                            "brd": "value",
                            "brdh": "value",
                            "brw": "value",
                            "fnt": "value",
                            "rd": "value",
                            "shd": "value",
                            "txt": "value",
                            "txth": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.PopupCloseTextButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.button.BasicButton",
                    "id": "style-it8en5rg"
                }
            }, {
                "type": "Component",
                "skin": "svgshape.v2.Svg_001419cc721a440abc4ab12ed150b744",
                "layout": {
                    "width": 34,
                    "height": 34,
                    "x": 947,
                    "y": 22,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.PopupCloseIconButton",
                "data": {
                    "type": "SvgShape",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    }
                },
                "props": {
                    "type": "SvgShapeProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "maintainAspectRatio": true
                },
                "style": {
                    "type": "ComponentStyle",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "style": {
                        "properties": {
                            "alpha-fillcolor": "1",
                            "alpha-stroke": "1",
                            "fillcolor": "#000000",
                            "stroke": "#FFC940",
                            "strokewidth": "1"
                        },
                        "propertiesSource": {
                            "alpha-fillcolor": "value",
                            "alpha-stroke": "value",
                            "fillcolor": "value",
                            "stroke": "value",
                            "strokewidth": "value"
                        },
                        "groups": {}
                    },
                    "componentClassName": "wysiwyg.viewer.components.PopupCloseIconButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "svgshape.v2.Svg_001419cc721a440abc4ab12ed150b744"
                },
                "connections": {
                    "type": "ConnectionList",
                    "items": [
                        {
                            "type": "WixCodeConnectionItem",
                            "role": "button1"
                        }
                    ]
                },
                "mobileStructure": {
                    "layout": {
                        "width": 24,
                        "height": 24,
                        "x": 283,
                        "y": 14,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "metaData": {
                        "originalCompId": "comp-isre5aw2",
                        "author": "duplicate"
                    }
                },
                "activeModes": {}
            }],
            "skin": "wysiwyg.viewer.skins.stripContainer.DefaultStripContainer",
            "layout": {
                "width": 1425,
                "height": 243,
                "x": 0,
                "y": 535,
                "scale": 1,
                "rotationInDegrees": 0,
                "fixedPosition": false
            },
            "componentType": "wysiwyg.viewer.components.PopupContainer",
            "props": {
                "type": "PopupContainerProperties",
                "metaData": {"schemaVersion": "1.0"},
                "horizontalAlignment": "center",
                "verticalAlignment": "bottom",
                "alignmentType": "fullWidth",
                "horizontalOffset": 0,
                "verticalOffset": 0,
                "id": "propItem-ilwc05eh"
            },
            "design": {
                "type": "MediaContainerDesignData",
                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                "background": {
                    "type": "BackgroundMedia",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "color": "#FFC940",
                    "colorOpacity": 1,
                    "alignType": "center",
                    "fittingType": "fill",
                    "scrollType": "none",
                    "colorOverlay": "",
                    "colorOverlayOpacity": 0
                },
                "id": "dataItem-ilwc05eh1"
            },
            "style": {
                "type": "TopLevelStyle",
                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                "style": {
                    "groups": {},
                    "properties": {
                        "alpha-bg": "1",
                        "alpha-brd": "1",
                        "bg": "61,155,233,1",
                        "boxShadowToggleOn-shd": "false",
                        "brd": "#324158",
                        "brw": "0px",
                        "rd": "0px",
                        "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                    },
                    "propertiesSource": {
                        "bg": "value",
                        "brd": "value",
                        "brw": "value",
                        "rd": "value",
                        "shd": "value"
                    }
                },
                "componentClassName": "wysiwyg.viewer.components.PopupContainer",
                "pageId": "",
                "compId": "i5zemfji_0",
                "styleType": "custom",
                "skin": "wysiwyg.viewer.skins.stripContainer.DefaultStripContainer",
                "id": "style-ilwc05ei"
            },
            "behaviors": {
                "type": "ObsoleteBehaviorsList",
                "items": "[{\"name\":\"FlyIn\",\"delay\":0,\"duration\":0.55,\"params\":{\"direction\":\"bottom\"},\"action\":\"screenIn\",\"targetId\":\"\"},{\"name\":\"FlyOut\",\"delay\":0,\"duration\":0.55,\"params\":{\"direction\":\"bottom\"},\"action\":\"exit\",\"targetId\":\"\"}]",
                "id": "behavior-iomlwee9"
            }
        }],

        "skin": "skins.core.InlineSkin",
        "layout": {
            "width": 980,
            "height": 778,
            "x": 0,
            "y": 0,
            "scale": 1,
            "rotationInDegrees": 0,
            "fixedPosition": false
        },
        "componentType": "mobile.core.components.Page",
        "data": {
            "type": "Page",
            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
            "title": "add_preset_popups_Sale_2",
            "hideTitle": true,
            "icon": "",
            "descriptionSEO": "",
            "metaKeywordsSEO": "",
            "pageTitleSEO": "",
            "pageUriSEO": "copy-of-popup1",
            "hidePage": true,
            "underConstruction": false,
            "tpaApplicationId": 0,
            "pageSecurity": {"requireLogin": false, "passwordDigest": "", "dialogLanguage": ""},
            "isPopup": true,
            "indexable": false,
            "isLandingPage": false,
            "pageBackgrounds": {
                "desktop": {
                    "ref": {
                        "type": "BackgroundMedia",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "color": "#000000",
                        "colorOpacity": 0.8,
                        "alignType": "center",
                        "fittingType": "fill",
                        "scrollType": "none",
                        "colorOverlay": "",
                        "colorOverlayOpacity": 0
                    }
                },
                "mobile": {
                    "custom": true,
                    "ref": {
                        "type": "BackgroundMedia",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "color": "#000000",
                        "colorOpacity": 0.8,
                        "alignType": "center",
                        "fittingType": "fill",
                        "scrollType": "scroll",
                        "colorOverlay": "",
                        "colorOverlayOpacity": 0
                    },
                    "isPreset": true
                }
            },
            "ignoreBottomBottomAnchors": true
        },
        "props": {
            "type": "PageProperties",
            "metaData": {"schemaVersion": "1.0"},
            "desktop": {"popup": {"closeOnOverlayClick": true}},
            "mobile": {"popup": {"closeOnOverlayClick": true}},
            "id": "h2jd7"
        },
        "style": "p1",
        "tpaComps": []
    },
    "preset": {
        "rect": {"width": 305, "height": 202, "x": 10, "y": 908},
        "label": "add_preset_popups_Sale_2",
        "tags": null
    }
}
