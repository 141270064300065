import type { EntryPoint } from '@/apilib';
import { EditorAPIKey } from '@/apis';
import { createGTMServiceApi } from './api';
import { GTMServiceApiKey } from './publicApiKey';

export const GTMServiceEntrypoint: EntryPoint = {
  name: 'GTMServiceApi',
  declareAPIs: () => [GTMServiceApiKey],
  getDependencyAPIs: () => [EditorAPIKey],
  attach(shell) {
    shell.contributeAPI(GTMServiceApiKey, () => createGTMServiceApi(shell));
  },
};
