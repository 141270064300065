import type { MapDispatchToProps } from 'types/redux';

import type { OpenAccountSettingsOptions } from './accountSettingsListSectionType';
import type {
  AccountSettingsListSectionDispatchProps,
  AccountSettingsListSectionOwnProps,
} from './accountSettingsListSection';

export const mapStateToProps: null = null;

export const mapDispatchToProps: MapDispatchToProps<
  AccountSettingsListSectionDispatchProps,
  AccountSettingsListSectionOwnProps
> = {
  openAccountSettings:
    (options: OpenAccountSettingsOptions) =>
    (dispatch: AnyFixMe, getState: AnyFixMe, { editorAPI }: AnyFixMe) => {
      editorAPI.account.openSettings(options);
    },
};
