import React from 'react';
import { Button, Text } from '@wix/wix-base-ui';
import * as symbols from '@wix/santa-editor-symbols';
import { translate } from '@/i18n';
import { connectWithScope, type InferComponentProps } from '@/apilib';
import { Bullets } from '../common/bullets';
import { MarketingPanel } from '../common/marketingPanel';
import promoteBi from '../common/bi/promoteBi';
import { PromoteScope } from '../../../scope';

export interface OwnProps {}

type SeoPanelProps = InferComponentProps<
  typeof mapStateToProps,
  typeof mapDispatchToProps,
  OwnProps
>;

const TAB_NAME = 'SeoPanel';
const PRODUCT_NAME = 'Seo';

const SeoPanel = React.memo<SeoPanelProps>((props: SeoPanelProps) => {
  React.useEffect(() => {
    props.sendBi(promoteBi.events.TAB_VIEW, {
      ...props.biBaseParams,
      tab_name: TAB_NAME,
      product_name: PRODUCT_NAME,
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onPressCTA = () => {
    props.sendBi(promoteBi.events.PRODUCT_CLICK, {
      ...props.biBaseParams,
      tab_name: TAB_NAME,
      product_name: PRODUCT_NAME,
    });
    props.navigateToSeo();
  };

  return (
    <MarketingPanel
      logo={<symbols.symbol name="promoteSeoPanelLogo" className="logo" />}
      title={
        <Text skin="standard" size="large" weight="bold" enableEllipsis={false}>
          {'Promote_SEO_Title'}
        </Text>
      }
      body={
        <Bullets
          bullets={[
            'Promote_SEO_Bullet1',
            'Promote_SEO_Bullet2',
            'Promote_SEO_Bullet3',
          ]}
        />
      }
      actions={
        <Button onClick={onPressCTA} className="btn-md">
          {translate('Promote_SEO_Main_Button')}
        </Button>
      }
    />
  );
});

const mapStateToProps = (scope: PromoteScope) => {
  const biBaseParams = promoteBi.biUtils.getBiBaseParams(scope);

  return {
    biBaseParams,
    msid: scope.editorAPI.dsRead.generalInfo.getMetaSiteId(),
  };
};
const mapDispatchToProps = (scope: PromoteScope) => {
  return {
    navigateToSeo: () => scope.editorAPI.account.openSettings({ tab: 'SEO' }),
    sendBi: (event: ValueOf<typeof promoteBi.events>, parameters: object) => {
      promoteBi.report(event, parameters);
    },
  };
};

SeoPanel.displayName = 'SeoPanel';

export default connectWithScope(
  () => PromoteScope,
  SeoPanel,
  mapStateToProps,
  mapDispatchToProps,
);
