._wrapper_1v3lb_1 {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
  transition: background-color 350ms cubic-bezier(0.4, 0, 1, 1); }
  ._wrapper_1v3lb_1 ._preview_1v3lb_8 {
    transform-origin: 0 0;
    opacity: 1;
    transition: opacity 350ms cubic-bezier(0.4, 0, 1, 1); }
  ._wrapper_1v3lb_1._loading_1v3lb_12 {
    background-color: #f0f3f5; }
    ._wrapper_1v3lb_1._loading_1v3lb_12 ._preview_1v3lb_8 {
      opacity: 0; }
