import React, { Component, type ComponentType } from 'react';
import _ from 'lodash';
import * as util from '@/util';

import type { ThunkAction } from 'types/redux';

import deriveCustomPanelsFromProps from '../topBarComponent/mapTopBarPropsToCustomDropPanels';
import CustomPanel from '../dropPanel/customPanel';

const {
  connect,
  STORES: { EDITOR_API },
} = util.hoc;

interface WithCustomDropPanelProps {
  handlerKey: string;
  openedDropPanel?: string;
  openRevisions?: () => void;
}

interface ComponentWithCustomDropPanelProps {
  dropPanelContent?: Component | null;
  dropPanelKey?: string;
  handlerKey: string;
}

type GetHandlerKey = (props: any) => string;

//TODO the openRevisions property is needed for deriving CustomPanels,
// should be moved from here to derive function
const mapDispatchToProps = {
  openRevisions:
    (): ThunkAction =>
    (dispatch, getState, { editorAPI }) =>
      editorAPI.account.openRevisions(),
};

const initialState: AnyFixMe = {
  dropPanelKey: undefined,
  dropPanelContent: undefined,
};

const withCustomDropPanel =
  (handlerKey: string | GetHandlerKey) =>
  <P extends WithCustomDropPanelProps>(
    WrappedComponent: ComponentType<P & ComponentWithCustomDropPanelProps>,
  ) => {
    const getHandlerKey: GetHandlerKey = _.isFunction(handlerKey)
      ? handlerKey
      : () => handlerKey;

    class WithCustomDropPanel extends Component<WithCustomDropPanelProps> {
      state = initialState;

      static getDerivedStateFromProps(props: AnyFixMe) {
        const { openedDropPanel } = props;
        const handlerKey = getHandlerKey(props);

        if (!openedDropPanel) {
          return initialState;
        }

        const panel = deriveCustomPanelsFromProps(props).find(
          ({ key, targetButtonKey }: AnyFixMe) =>
            key === openedDropPanel && targetButtonKey === handlerKey,
        );

        if (!panel) {
          return initialState;
        }

        return {
          dropPanelKey: openedDropPanel,
          dropPanelContent: (
            <CustomPanel
              panelData={panel.dropPanelData}
              panelPath={panel.dynamicPanelPath}
            />
          ),
        };
      }

      render() {
        const { props } = this;
        const { dropPanelKey, dropPanelContent } = this.state;
        const handlerKey = getHandlerKey(props);
        return React.createElement(
          WrappedComponent,
          _.defaults(
            { handlerKey, dropPanelKey, dropPanelContent },
            props as P,
          ),
        );
      }
    }

    return connect(EDITOR_API, null, mapDispatchToProps)(WithCustomDropPanel);
  };

export default withCustomDropPanel;
