:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._container_11vxi_7 {
  display: flex;
  position: absolute;
  left: 24px;
  top: 8px; }
  ._container_11vxi_7._bottomPage_11vxi_12 {
    bottom: 8px;
    top: unset; }

._tooltip_11vxi_16 {
  display: flex;
  cursor: pointer; }

._icon_11vxi_20 {
  fill: #868aa5;
  margin-right: 8px; }

._tooltipContent_11vxi_24 {
  display: flex; }
