import { DATA_BINDING } from '@wix/app-definition-ids';
import type { EditorAPI } from '@/editorAPI';
import type { PagesData } from 'types/documentServices';

export const getHasDynamicPages = (
  editorAPI: EditorAPI,
  pagesWithManagingAppDefId: boolean = false,
) => {
  const routers = editorAPI.dsRead.routers.pages.getDynamicPagesList();
  const dataBindingRouters = routers.filter(
    (router) => router.appDefinitionId === DATA_BINDING,
  );

  return dataBindingRouters.some((router) =>
    (router.pages as PagesData[]).some((page) =>
      pagesWithManagingAppDefId
        ? page.managingAppDefId === DATA_BINDING
        : page.managingAppDefId === DATA_BINDING ||
          page.managingAppDefId === undefined,
    ),
  );
};
