import { AssistantAction, type IAssistantAction } from '@wix/tour-maker';
import _ from 'lodash';
import type { EditorAPI } from '@/editorAPI';

// When tour is started from help-menu we need to not show helpAction
// workaround for tour-maker's inability to have conditions
// eg .onClickGoTo(x) and .completeOnClick() cant be applied conditionally
export function skipAction(
  actions: IAssistantAction[],
  predicate: (action: IAssistantAction) => boolean,
) {
  const fakeLastAction = new AssistantAction('fakeLastAction').build();

  return actions.map((a) => (predicate(a) ? fakeLastAction : a));
}

export const createActions = (
  listOfTourActionCreators: AnyFixMe,
  length: number,
): IAssistantAction[] =>
  listOfTourActionCreators.map((item: AnyFixMe, index: number) => {
    return item(index, length);
  });

const loadedPromise = (
  registerCallback: (
    resolve: (value: boolean) => void,
    reject: (reason?: undefined) => void,
  ) => void,
) => new Promise(registerCallback);

export const editorLoaded = async (
  editorAPI: EditorAPI,
  editorCoreAPI: any,
) => {
  await loadedPromise(editorAPI.registerInitUserPrefsCallback);
  await editorCoreAPI.hooks.__previewIsReady_TEMPORARY.promise;
  await editorCoreAPI.hooks.stageIsReadyAndVisible.promise;
  await Promise.all(editorAPI.prerequisiteForWelcomeScreen);
};

export const manageAnimation = (
  elementSelector: string,
  animationClass: string,
  animationDuration = 500,
) => {
  const card = document.querySelector(elementSelector);

  if (card?.classList.contains(animationClass)) return;

  card?.classList.add(animationClass);

  setTimeout(() => {
    card?.classList.remove(animationClass);
  }, animationDuration);
};

const clickListener = (selector: string, callback: () => void) => ({
  add() {
    const element = document.querySelector(selector);
    element?.addEventListener('click', callback);
  },
  remove() {
    const element = document.querySelector(selector);
    element?.removeEventListener('click', callback);
  },
});

export const getClickListener =
  (selector: string) =>
  (callback: (name?: string) => void, panelName?: string) =>
    clickListener(selector, _.partial(callback, panelName));

export const waitForElementIsInDOM = (selector: string, timer: number) =>
  new Promise((resolve, reject) => {
    const observer = new MutationObserver(() => {
      const target = document.querySelector(selector);
      if (target) {
        observer.disconnect();
        resolve(target);
      }
    });
    observer.observe(document.body, { childList: true, subtree: true });
    setTimeout(() => {
      observer.disconnect();
      reject('Target failed to load');
    }, timer);
  });
