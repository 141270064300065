import React, { useCallback, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import type { CSSTransitionClassNames } from 'react-transition-group/CSSTransition';

import { cx, hoc, sections } from '@/util';
import { attachCandidate } from '@/stateManagement';

import { mapDispatchToProps, mapStateToProps } from './attachHighlightMapper';
import { AUTOMATION_IDS } from './constants';
import styles from './attachHighlight.scss';

import type {
  AttachHighlightDispatchProps,
  AttachHighlightStateProps,
} from './attachHighlightMapper';

const { selectAttachCandidateComponent } = attachCandidate.selectors;

const {
  connect,
  withConditionalRender,
  STORES: { MOUSE_OPS },
} = hoc;

export interface AttachHighlightOwnProps {}

interface AttachHighlightProps
  extends AttachHighlightOwnProps,
    AttachHighlightStateProps,
    AttachHighlightDispatchProps {}

const FADE_IN_OUT_DURATION = 1000;

const animationClassNames: CSSTransitionClassNames = {
  appear: styles.newContainerAppear,
  appearActive: styles.newContainerAppearActive,
  appearDone: styles.newContainerAppearDone,
  exit: styles.newContainerExit,
  exitActive: styles.newContainerExitActive,
  exitDone: styles.newContainerExitDone,
};

const AttachHighlight: React.FC<AttachHighlightProps> = ({
  attachCandidateRef,
  position,
  isMaster,
  label,
  useInOutAnimation,
  clearAttachCandidate,
  isAttachingToSection,
}) => {
  const [isVisible, setIsVisible] = useState(true);

  const handleEntered = useCallback(() => {
    setIsVisible(false);
  }, []);

  return (
    <CSSTransition
      unmountOnExit
      timeout={FADE_IN_OUT_DURATION}
      in={isVisible}
      enter={useInOutAnimation}
      exit={useInOutAnimation}
      appear={useInOutAnimation}
      classNames={animationClassNames}
      onEntered={handleEntered}
      onExited={clearAttachCandidate}
    >
      <div
        data-comp-id={attachCandidateRef.id}
        data-hook={AUTOMATION_IDS.CONTAINER}
        style={
          {
            ...position,
            '--animation-duration': `${FADE_IN_OUT_DURATION}`,
          } as React.CSSProperties
        }
        className={cx(styles.highlightContainer, {
          [styles.master]: isMaster,
          [styles.isSection]: isAttachingToSection,
          [styles.newContainer]: sections.isSectionsEnabled(),
        })}
      >
        <label data-hook={AUTOMATION_IDS.LABEL} className={styles.label}>
          {label}
        </label>
      </div>
    </CSSTransition>
  );
};

const ConnectedComponent = connect(
  MOUSE_OPS,
  mapStateToProps,
  mapDispatchToProps,
)(AttachHighlight);

const FinalComponent = withConditionalRender(MOUSE_OPS, ({ state }) =>
  Boolean(selectAttachCandidateComponent(state)),
)(ConnectedComponent);

FinalComponent.pure = AttachHighlight;

export default FinalComponent;
