import type { EditorAPI } from '@/editorAPI';
import { getAppState } from './appState';

export const getDataMode = (editorAPI: EditorAPI) =>
  getAppState(editorAPI).dataMode;

export const isDataModeOn = (editorAPI: EditorAPI) => !!getDataMode(editorAPI);

export const subscribeToDataModeChange = (
  editorAPI: EditorAPI,
  cb: (dataMode: boolean, prevDataMode: boolean) => void,
) => {
  let dataMode = getDataMode(editorAPI);

  return editorAPI.store.subscribe(() => {
    const newDataMode = getDataMode(editorAPI);
    if (dataMode !== newDataMode) {
      const prevDataMode = dataMode;
      dataMode = newDataMode;

      cb(dataMode, prevDataMode);
    }
  });
};

export const onceDataModeInitialized = (
  editorAPI: EditorAPI,
): Promise<boolean> => {
  return new Promise((resolve) => {
    const dataMode = getDataMode(editorAPI);
    if (typeof dataMode !== 'undefined') {
      resolve(dataMode);
      return;
    }

    const unsubscribe = subscribeToDataModeChange(
      editorAPI,
      (nextDataMode: AnyFixMe) => {
        unsubscribe();
        resolve(nextDataMode);
      },
    );
  });
};
