import { translate } from '@/i18n';

const FEEDBACK_EVENT_TYPES = {
  COMPONENT_REMOVED: 'component-removed',
};

const feedbackObjs = {
  [FEEDBACK_EVENT_TYPES.COMPONENT_REMOVED]: {
    'wysiwyg.viewer.components.Repeater': {
      feedbackObj: {
        questionId: 'editor-repeater-remove',
        categoryId: 'editor',
        triggerEvent: 'repeater-remove',
        customContext: {
          layout: 'repeaterLayout',
        },
        thankYouPage: {
          title: translate('FeedbackPopup_Repeaters_ThankYou_Title'),
          subTitle: translate('FeedbackPopup_Repeaters_ThankYou_Text'),
        },
        documentationLink:
          'https://support.wix.com/en/widget/cb6480f3-768b-4737-ab8f-10fe2e4fa2ab/article/b55d738e-9fbf-49a2-8170-e4d97f6d5a94',
        feedbackModel: {
          feedbackType: 'question',
          question: {
            title: translate('FeedbackPopup_Repeaters_Title'),
            subTitle: translate('FeedbackPopup_Repeaters_Subtitle'),
          },
          answer: {
            firstLevelType: 'firstlevel-multichoice',
            options: [
              {
                label: translate('FeedbackPopup_Repeaters_Option1'),
                choice: 'not-relevant',
              },
              {
                label: translate('FeedbackPopup_Repeaters_Option2'),
                choice: 'too-hard',
                children: {
                  type: 'free-text',
                  title: translate('FeedbackPopup_Repeaters_HardToUse_Label'),
                  choice: 'too-hard-text',
                  submitButtonLabel: 'Send',
                  placeholder: translate(
                    'FeedbackPopup_Repeaters_WhatsMissing_Placeholder',
                  ),
                },
              },
              {
                label: translate('FeedbackPopup_Repeaters_Option3'),
                choice: 'missing-features',
                children: {
                  type: 'free-text',
                  title: translate(
                    'FeedbackPopup_Repeaters_WhatsMissing_Label',
                  ),
                  choice: 'missing-features-text',
                  submitButtonLabel: 'Send',
                  placeholder: translate(
                    'FeedbackPopup_Repeaters_WhatsMissing_Placeholder',
                  ),
                },
              },
              {
                label: translate('FeedbackPopup_Repeaters_Option4'),
                choice: 'dismiss',
              },
            ],
          },
        },
      },
      experimentName: 'se_removeRepeaterFeedback',
    },
  },
};

export { FEEDBACK_EVENT_TYPES, feedbackObjs };
