import type { EntryPoint } from '@/apilib';
import { EditorAPIKey } from '@/apis';
import { createColumnsApi } from './columnsWrapper';
import { ColumnsApiKey } from './publicApiKey';
import { SectionsApiKey } from '@/apis';

export const ColumnsEntrypoint: EntryPoint = {
  name: 'ColumnsApi',
  declareAPIs: () => [ColumnsApiKey],
  getDependencyAPIs: () => [EditorAPIKey, SectionsApiKey],
  attach(shell) {
    shell.contributeAPI(ColumnsApiKey, () => createColumnsApi(shell));
  },
};
