import { Button, TextLabel } from '@wix/wix-base-ui';
import React from 'react';
import ProductList from './productList';
import type { Product } from './types';

export interface TitleWithListTemplateProps {
  title: string;
  subtitle: string;
  buttonText?: string;
  onButtonClicked?: any;
  items: Product[];
  sectionTitle?: JSX.Element;
}

const TitleWithListTemplate = ({
  buttonText,
  items,
  onButtonClicked,
  sectionTitle,
  subtitle,
  title,
}: TitleWithListTemplateProps) => (
  <div className="super-app-extra-panel">
    <div className="scrollable-wrapper">
      <div className="title-with-list-panel">
        <div className="description-and-button">
          <div className="title">
            <TextLabel value={title} type="T09" enableEllipsis={false} />
          </div>
          <div className="subtitle">
            <TextLabel value={subtitle} type="T07" enableEllipsis={false} />
          </div>
          {buttonText && (
            <div className="button">
              <Button onClick={onButtonClicked}>{buttonText}</Button>
            </div>
          )}
        </div>
        <ProductList items={items} sectionTitle={sectionTitle} />
      </div>
    </div>
  </div>
);

export default TitleWithListTemplate;
