// @ts-nocheck
import _ from 'lodash';
import addPanelDataConsts from '@/addPanelDataConsts';
import liveComponentSection from './liveComponentSection';
import designPanelLiveComponentSection from './designPanelLiveComponentSection';

import React from 'react';

const DEFAULT_SECTION = liveComponentSection;

const sections = {};
sections[addPanelDataConsts.PANEL_TYPES.ADD_PANEL] = liveComponentSection;
sections[addPanelDataConsts.PANEL_TYPES.DESIGN_PANEL] =
  designPanelLiveComponentSection;

export default {
  getInitialState() {
    return {
      stylesForSection: this.getStylesForSection(),
    };
  },
  getLiveSection() {
    return sections[this.props.parentType] || DEFAULT_SECTION;
  },
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_.isEqual(this.props.props.compTypes, nextProps.props.compTypes)) {
      this.setState({
        stylesForSection: this.getStylesForSection(),
      });
    }
  },
  getPropsForLiveSection() {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    return _.assign(
      { stylesForSection: this.state.stylesForSection },
      this.props,
    );
  },
  render() {
    const LiveSection = this.getLiveSection();

    return (
      <LiveSection
        key={this.props.id}
        ref={this.props.id}
        {...this.getPropsForLiveSection()}
      />
    );
  },
};
