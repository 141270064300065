:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._panel_ubc2l_7 {
  position: absolute;
  bottom: 38px;
  right: 288px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  text-align: center;
  padding: 12px 37px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 360px;
  height: 60px;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 2px 10px 0 rgba(0, 0, 0, 0.08), 0 1px 20px 0 rgba(0, 0, 0, 0.08); }
