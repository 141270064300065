'use strict'

const addPanelDataConsts = require('@/addPanelDataConsts')

const firstItem = require('./firstItem')
const secondItem = require('./secondItem')
const thirdItem = require('./thirdItem')
const fourthItem = require('./fourthItem')
const fifthItem = require('./fifthItem')

const sectionTitle = 'Popups_Add_Category_Contact_Title'

module.exports = {
    type: addPanelDataConsts.SECTIONS_TYPES.PRESET,
    hide: false,
    title: sectionTitle,
    subNavigationTitle: sectionTitle,
    presetTitle: sectionTitle,
    tooltipTitle: sectionTitle,
    automationId: 'add-panel-section-popupContactSection',
    subNavigationHide: false,
    showSectionHeader: true,
    additionalBehaviours: {
        labelMode: addPanelDataConsts.LABEL_BEHAVIOUR.HOVER,
        infoIcon: addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
        hoverImageAction: addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
        iconEnabledForComps: {}
    },
    props: {
        image: 'addPanelData/sections/popups_en/popups_contact.v2.png',
        imageHover: null,
        items: [firstItem, secondItem, thirdItem, fourthItem, fifthItem],
        compTypes: ['mobile.core.components.Page']
    },
    help: {hide: false, text: 'Popups_Add_Category_Contact_Text'}
}
