// @ts-nocheck
import ReactDOM from 'reactDOM';
import PropTypes from 'prop-types';
import * as core from '@/core';
import _ from 'lodash';
import addPanelDataConsts from '@/addPanelDataConsts';
import componentsDefaultData from '../../addPanel/componentsDefaultData';
import * as componentCreationUtil from './componentCreationUtil';
import * as liveComponentStyleAdjuster from './liveComponentStyleAdjuster';
import { prepareCompData } from './liveComponentUtils';

let compCounter = 0;

function prepareDataForPreview(
  compsByLines,
  compDef,
  previewJson,
  compStylesObj,
  maxFontSize,
  index,
  verticalMargin,
  scalingY,
  editorAPI,
  categoryId,
  langCode,
) {
  addDataAndPropsToJSON(previewJson, compDef);
  const currStyleId = compStylesObj.styleId;
  const compDataAndLine = findCompDataAndLineByIndex(compsByLines, index);
  if (!compDataAndLine) {
    return;
  }
  const compLayout = {
    position: compDataAndLine.compData.compPosition,
    size: {
      width: compDef.panelLayout.width,
      height: compDef.panelLayout.height,
    },
  };
  const currStyle = compStylesObj.styleDef;
  const bordersWithScaleNotDividedInScale =
    liveComponentStyleAdjuster.changeBordersNotDividingInScale(
      currStyle,
      scalingY,
    );
  const shadowWithLimitOnAddPanel =
    liveComponentStyleAdjuster.limitShadowOnAddPanel(currStyle);
  const colorNeededToBeReplaced =
    liveComponentStyleAdjuster.changeColorsNotSuitableFotAddPanel(
      currStyle,
      categoryId,
    );
  const fontsExceedsFromDefault =
    currStyleId !== 'txtNew'
      ? liveComponentStyleAdjuster.getFontExceedingFromMaxSize(
          currStyle,
          maxFontSize,
          editorAPI.theme,
        )
      : liveComponentStyleAdjuster.handleExceedingFontForTextComp(
          categoryId,
          maxFontSize,
          compDef,
          previewJson,
          editorAPI.theme,
          langCode,
        );
  const needsCustomStyle =
    !_.isEmpty(fontsExceedsFromDefault) ||
    !_.isEmpty(shadowWithLimitOnAddPanel) ||
    !_.isEmpty(bordersWithScaleNotDividedInScale) ||
    !_.isEmpty(colorNeededToBeReplaced);

  const newCustomStyle = needsCustomStyle
    ? liveComponentStyleAdjuster.generateCustomStyle(
        currStyle,
        fontsExceedsFromDefault,
        maxFontSize,
        bordersWithScaleNotDividedInScale,
        shadowWithLimitOnAddPanel,
        colorNeededToBeReplaced,
      )
    : // #DM-1948 something ignores `styleId` and uses only `customStyle` to render miniSites
      {
        id: currStyle.id,
        skin: currStyle.skin,
        style: currStyle.style,
      };

  let styleId = currStyleId;
  if (needsCustomStyle) {
    styleId = newCustomStyle.id;
  }
  const compFullStructure = componentCreationUtil.getComponentDef(
    styleId,
    compDataAndLine.compData.compId,
    compDef.dataId,
    compDef.propId,
    compDef.componentType,
    compLayout,
    compDef.isContainer,
    currStyleId,
  );
  previewJson.comps.push({
    styleId,
    customStyle: newCustomStyle,
    compFullStructure,
  });
}

function getDataForDic(compId, compStylesObj, compDef, compPosition, index) {
  const { styleDef } = compStylesObj;
  const isSystemStyle = styleDef.styleType === 'system';
  return {
    key: compStylesObj.compType,
    compType: compDef.componentType,
    styleId: compStylesObj.styleId,
    style: isSystemStyle ? compStylesObj.styleId : compStylesObj.styleDef,
    compPosition,
    compId,
    height: compDef.panelLayout.height,
    width: compDef.panelLayout.width,
    index,
    compDef,
    compPtr: compStylesObj.compPtr,
  };
}

function getComponentByXY(
  clickX,
  clickY,
  elementsPositionInSection,
  scaleProportion,
  parentType,
) {
  for (const key in elementsPositionInSection) {
    if (elementsPositionInSection.hasOwnProperty(key)) {
      const compDef = componentsDefaultData.getComponentDefault(
        elementsPositionInSection[key].key,
        parentType,
      );
      const startX =
        elementsPositionInSection[key].compPosition.x * scaleProportion.x;
      const startY =
        elementsPositionInSection[key].compPosition.y * scaleProportion.y;
      const endX =
        startX + elementsPositionInSection[key].width * scaleProportion.x;
      const endY =
        startY + elementsPositionInSection[key].height * scaleProportion.y;
      const clickInsideCompHorizontalArea =
        clickX >= startX - (compDef.addClickArea || 0) &&
        clickX <= endX + (compDef.addClickArea || 0);
      const clickInsideCompVerticalArea =
        clickY >= startY - (compDef.addClickArea || 0) &&
        clickY <= endY + (compDef.addClickArea || 0);
      if (clickInsideCompHorizontalArea && clickInsideCompVerticalArea) {
        return elementsPositionInSection[key];
      }
    }
  }
  return null;
}

function addDataAndPropsToJSON(previewJson, compDef) {
  if (compDef.propId) {
    previewJson.props[compDef.propId] = compDef.props;
  }
  if (compDef.dataId) {
    previewJson.data[compDef.dataId] = compDef.data;
  }
  if (compDef.additionalData) {
    previewJson.additionalData = previewJson.additionalData.concat(
      compDef.additionalData,
    );
  }
  if (compDef.additionalMatserPageData) {
    previewJson.additionalMatserPageData =
      previewJson.additionalMatserPageData.concat(
        compDef.additionalMatserPageData,
      );
  }
}

function findCompDataAndLineByIndex(compsByLines, index) {
  for (const row in compsByLines) {
    if (compsByLines.hasOwnProperty(row)) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/find
      const found = _.find(compsByLines[row], { index });
      if (found) {
        return {
          compData: found,
          line: row,
        };
      }
    }
  }
}

function getMaxLineHeight(compsByLine, currLine) {
  return _.maxBy(compsByLine[currLine], 'compDef.panelLayout.height').compDef
    .panelLayout.height;
}

export default {
  UNSAFE_componentWillMount() {
    this.elementsPositionInSection = {};
    this.compsByLines = {};
    this.currentRowNum = 0;
    this.currentRowWidth = 0;
    this.compsByLines[this.currentRowWidth] = [];
    this.totalSectionHeight = 0;
    this.calculateCompsPosition();
    this.setState({
      sectionSiteHeight: this.totalSectionHeight,
      layoutDone: false,
    });
  },
  getChildContext() {
    return {
      documentServices: this.getEditorAPI().documentServices,
      styleManager: core.styleManager,
    };
  },
  childContextTypes: {
    documentServices: PropTypes.object.isRequired,
    styleManager: PropTypes.object.isRequired,
  },
  calculateCompsPosition() {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
    _.forEach(
      this.props.stylesForSection,
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/bind
      _.bind(function (compStylesObj, index) {
        compCounter++;
        const compDef = componentsDefaultData.getComponentDefault(
          compStylesObj.compType,
          this.props.parentType,
        );
        const compId = `Comp${compCounter}`;
        this.locatePositionInGrid(compDef, index, compStylesObj, compId);
      }, this),
    );
  },
  getRelevantConstantData() {
    return this.props.parentType === addPanelDataConsts.PANEL_TYPES.ADD_PANEL
      ? addPanelDataConsts.ADD_PANEL
      : addPanelDataConsts.DESIGN_PANEL;
  },
  getStyleProps() {
    return {
      width:
        this.getRelevantConstantData().LIVE_COMPONENT_CATEGORY_CONTENT /
        this.props.props.scaleProportion.x,
      WebkitTransform: `scale(${this.props.props.scaleProportion.x},${this.props.props.scaleProportion.y})`,
      transform: `scale(${this.props.props.scaleProportion.x},${this.props.props.scaleProportion.y})`,
      WebkitTransformOrigin: '0 0',
      transformOrigin: '0 0',
      WebkitBackfaceVisibility: 'hidden',
      backfaceVisibility: 'hidden',
    };
  },
  getCompDataByItemKey(itemKey) {
    let compData = this.elementsPositionInSection[itemKey];
    if (compData) {
      compData = prepareCompData(compData, this.props.parentType);

      return compData;
    }

    return null;
  },
  getCompDataByXY(x, y) {
    const sectionBoundingRect =
      ReactDOM.findDOMNode(this).getBoundingClientRect();
    const { scaleProportion } = this.props.props;

    const absoluteSectionPos = {
      x:
        sectionBoundingRect.left +
        this.getRelevantConstantData().LIVE_COMPONENT_SECTION_MARGIN,
      y:
        sectionBoundingRect.top +
        (this.props.showSectionHeader
          ? this.getRelevantConstantData().SECTION_HEADER_HEIGHT
          : 0) +
        (this.props.showSectionHeader
          ? this.getRelevantConstantData().LIVE_COMPONENT_SECTION_MARGIN
          : 0),
    };

    let compData = getComponentByXY(
      x - absoluteSectionPos.x,
      y - absoluteSectionPos.y,
      this.elementsPositionInSection,
      scaleProportion,
      this.props.parentType,
    );
    if (compData) {
      const compRect = {
        left:
          compData.compPosition.x * scaleProportion.x + absoluteSectionPos.x,
        top: compData.compPosition.y * scaleProportion.y + absoluteSectionPos.y,
        width: compData.width * scaleProportion.x,
        height: compData.height * scaleProportion.y,
      };

      compData = prepareCompData(compData, this.props.parentType);

      return {
        rect: compRect,
        structure: compData,
        sectionTitle: this.props.title,
        categoryId: this.props.categoryId,
      };
    }

    return null;
  },
  onSiteReadyUpdateLayout(sectionHeightAfterLayout, measureMap) {
    this.setState(
      { sectionSiteHeight: sectionHeightAfterLayout },
      function () {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
        _.forEach(
          this.elementsPositionInSection,
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line you-dont-need-lodash-underscore/bind
          _.bind(function (compInSection) {
            const { compId } = compInSection;
            compInSection.compPosition.y = measureMap.top[compId];
            compInSection.height = measureMap.height[compId];
          }, this),
        );
        if (this.props.notifyLayoutDone) {
          this.props.notifyLayoutDone();
        }
        this.setState({ layoutDone: true });
      }.bind(this),
    );
  },
  calculateTotalSectionHeight() {
    const totalHeightWithScaling = Math.ceil(
      this.state.sectionSiteHeight * this.props.props.scaleProportion.y +
        (this.props.showSectionHeader
          ? this.getRelevantConstantData().SECTION_HEADER_HEIGHT
          : 0) +
        this.getRelevantConstantData().LIVE_COMPONENT_SECTION_MARGIN,
    );
    return totalHeightWithScaling;
  },
  updateRowLineAndAddToDic(
    additionToWidth,
    compDef,
    compPosition,
    compStylesObj,
    index,
    compId,
  ) {
    this.currentRowWidth += additionToWidth;
    const compData = getDataForDic(
      compId,
      compStylesObj,
      compDef,
      compPosition,
      index,
    );
    this.elementsPositionInSection[
      `${compStylesObj.compType}_${compStylesObj.styleId}`
    ] = compData;
    this.compsByLines[this.currentRowNum].push(compData);
  },
  locatePositionInGrid(compDef, index, compStylesObj, compId) {
    const compPosition = {};
    const { scaleProportion } = this.props.props;
    const compWidth = compDef.panelLayout.width;
    const compHorizontalMarginWithScaling =
      this.props.props.horizontalMargin * (1 / scaleProportion.x);
    const compVerticalMarginWithScaling =
      this.props.props.verticalMargin * (1 / scaleProportion.y);
    const sectionWidthWidthScaling =
      this.getRelevantConstantData().LIVE_COMPONENT_CATEGORY_CONTENT /
      scaleProportion.x;
    if (this.currentRowWidth === 0 && compWidth <= sectionWidthWidthScaling) {
      //first element in row
      compPosition.x = this.currentRowWidth;
      compPosition.y = this.totalSectionHeight;
      this.updateRowLineAndAddToDic(
        compWidth,
        compDef,
        compPosition,
        compStylesObj,
        index,
        compId,
      );
    } else if (
      this.currentRowWidth + compHorizontalMarginWithScaling + compWidth <=
      sectionWidthWidthScaling
    ) {
      //additional element in the same row
      compPosition.x = this.currentRowWidth + compHorizontalMarginWithScaling;
      compPosition.y = this.totalSectionHeight;
      this.updateRowLineAndAddToDic(
        compWidth + compHorizontalMarginWithScaling,
        compDef,
        compPosition,
        compStylesObj,
        index,
        compId,
      );
    } else {
      //new Line
      const maxHeightInLine = getMaxLineHeight(
        this.compsByLines,
        this.currentRowNum,
      );
      this.currentRowWidth = 0;
      this.currentRowNum++;
      this.totalSectionHeight =
        this.totalSectionHeight +
        maxHeightInLine +
        compVerticalMarginWithScaling;
      this.compsByLines[this.currentRowNum] = [];
      compPosition.x = this.currentRowWidth;
      compPosition.y = this.totalSectionHeight;
      this.updateRowLineAndAddToDic(
        compWidth,
        compDef,
        compPosition,
        compStylesObj,
        index,
        compId,
      );
    }
    if (this.props.stylesForSection.length - 1 === index) {
      const maxHeightLine = getMaxLineHeight(
        this.compsByLines,
        this.currentRowNum,
      );
      this.totalSectionHeight += maxHeightLine;
    }
  },
  getLiveCompsComponentsDef(pageId, langCode) {
    const previewJson = {
      props: {},
      data: {},
      comps: [],
      additionalData: [],
      additionalMatserPageData: [],
      actionsForPreviewExtension: {},
      siteId: _.uniqueId('siteID_'),
    };
    previewJson.actionsForPreviewExtension = {
      labelMode: this.props.additionalBehaviours.labelMode,
      infoIcon: this.props.additionalBehaviours.infoIcon,
      iconEnabledForComps: this.props.additionalBehaviours.iconEnabledForComps,
      scaleProportion: this.props.props.scaleProportion,
      fontSize:
        addPanelDataConsts.LABEL_FONT_SIZE *
        (1 / this.props.props.scaleProportion.y),
      compTypesLabels: {},
    };
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
    _.forEach(
      this.props.stylesForSection,
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/bind
      _.bind(function (compStylesObj, index) {
        const compDef = componentsDefaultData.getComponentDefault(
          compStylesObj.compType,
          this.props.parentType,
          previewJson.siteId,
        );
        previewJson.actionsForPreviewExtension.compTypesLabels[
          compDef.componentType
        ] = compDef.label;
        prepareDataForPreview(
          this.compsByLines,
          compDef,
          previewJson,
          compStylesObj,
          this.props.props.maxFontSize,
          index,
          this.props.props.verticalMargin,
          this.props.props.scaleProportion.y,
          this.getEditorAPI(),
          this.props.categoryId,
          langCode,
        );
      }, this),
    );
    previewJson.totalSectionHeight = this.totalSectionHeight;
    previewJson.siteWidth =
      this.getRelevantConstantData().LIVE_COMPONENT_CATEGORY_CONTENT /
      this.props.props.scaleProportion.x;
    previewJson.compTypes = this.props.props.compTypes;
    previewJson.categoryId = this.props.categoryId;
    previewJson.maxFontSize = this.props.props.maxFontSize;
    previewJson.compTypes = _.uniq(
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/map
      _.map(previewJson.comps, 'compFullStructure.componentType'),
    );
    return previewJson;
  },
};
