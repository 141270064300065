:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._container_11tnb_7 {
  display: flex;
  flex: auto;
  height: 100%; }

._sidebar_11tnb_12 {
  --vertical-tabs-custom-accent-color: $sidebar-bg-color;
  flex: none;
  width: 210px;
  background: #f7f8f8;
  padding: 18px 12px 0 12px;
  box-sizing: border-box;
  border-right: 1px solid #dfe5eb; }
  ._sidebar_11tnb_12 ._sidebarTabs_11tnb_20 {
    padding: 0 0 12px 0; }
  ._sidebar_11tnb_12 ._sidebarDivider_11tnb_22 {
    margin: 0 12px 18px 12px; }
  ._sidebar_11tnb_12 .control-vertical-tabs {
    height: auto; }

._content_11tnb_27 {
  flex: auto;
  display: flex;
  flex-direction: column;
  min-width: 0; }

._preloader_11tnb_33 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }
