import React from 'react';
import { useTranslation } from 'react-i18next';
import { ProgressRing } from '../ProgressRing/ProgressRing';
import { BusinessAppHeaderLayout } from '../BusinessAppHeaderLayout/BusinessAppHeaderLayout';
import { AUTOMATION_IDS } from '../../automationIds';
import { TRANSLATIONS } from '../../translations';

export interface BusinessAppSetupHeaderProps {
  appDefId: string;
  appName: string;
  stepsCompleted: number;
  stepsTotal: number;
}

export const BusinessAppSetupHeader: React.FC<BusinessAppSetupHeaderProps> = ({
  appDefId,
  appName,
  stepsCompleted,
  stepsTotal,
}) => {
  const [t] = useTranslation();
  // TODO: make the fallback value translated
  const translationKeys = TRANSLATIONS.MY_BUSINESS.SETUP_HEADER[appDefId] || {
    TITLE: `Start Using ${appName}`,
    SUBTITLE: 'Complete the steps below',
  };
  const progress = (stepsCompleted / stepsTotal) * 100;
  return (
    <BusinessAppHeaderLayout
      illustration={
        <ProgressRing
          progress={progress}
          label={`${stepsCompleted}/${stepsTotal}`}
        />
      }
      title={t(translationKeys.TITLE)}
      subtitle={t(translationKeys.SUBTITLE)}
      dataHook={AUTOMATION_IDS.MY_BUSINESS.APP_SETUP.HEADER}
    />
  );
};
