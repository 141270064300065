import * as util from '@/util';

import type {
  Dispatch,
  MapStateToProps,
  MapDispatchToProps,
} from 'types/redux';
import type { SiteAccessPanelOwnProps } from './siteAccessPanel';
import type { EditorAPI } from '@/editorAPI';
import type { EditorState } from '@/stateManagement';
import type { ClosePanelOrigin, HelpClickOrigin } from './siteAccessPanelBi';

export interface SiteAccessPanelStateProps {}

export interface SiteAccessPanelDispatchProps {
  updateSitePassword: (password: string) => void;
  removeSitePassword: () => void;
  openHelpCenter: (
    helpId: string,
    origin: HelpClickOrigin,
    isLearnMore?: boolean,
  ) => void;
  closePanel: (origin: ClosePanelOrigin) => void;
}

const mapStateToProps: MapStateToProps<
  SiteAccessPanelStateProps,
  SiteAccessPanelOwnProps
> = () => {
  return {};
};

const getEditorAPI = (
  dispatch: Dispatch,
  getState: () => EditorState,
  { editorAPI }: { editorAPI: EditorAPI },
): EditorAPI => editorAPI;

const mapDispatchToProps: MapDispatchToProps<
  SiteAccessPanelDispatchProps,
  SiteAccessPanelOwnProps
> = (dispatch: Dispatch, props: SiteAccessPanelOwnProps) => {
  const editorAPI = dispatch(getEditorAPI);

  return {
    openHelpCenter: (
      helpId: string,
      origin: HelpClickOrigin,
      isLearnMore = false,
    ) =>
      editorAPI.panelManager.openHelpCenter(
        helpId,
        {},
        {
          panelName: props.panelName,
          learn_more: isLearnMore,
          origin,
        },
      ),
    closePanel: (origin: ClosePanelOrigin) =>
      editorAPI.panelManager.closePanelByName(props.panelName, origin),
    updateSitePassword: (password: string) => {
      if (!password) {
        throw new Error('Cannot set empty password');
      }

      editorAPI.pages.permissions.updatePassword(
        editorAPI.pages.getMasterPageId(),
        password,
      );
    },
    removeSitePassword: () => {
      editorAPI.pages.permissions.removePassword(
        editorAPI.pages.getMasterPageId(),
      );
    },
  };
};

export default util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
);
