import type { MediaManagerShape } from '@/mediaServices';
import type { CompStructure, StyleRef } from 'types/documentServices';

// CompDef generation code was copied from https://github.com/wix-private/santa-editor/blob/master/editor-add-panel/addPanelSectionsEN/sections/vectorArt/moreBehaviorHelper.js

export const getShapeCompDef = (item: MediaManagerShape): CompStructure => ({
  layout: {
    width: 120,
    height: 120,
    scale: 1,
    rotationInDegrees: 0,
    anchors: [],
    fixedPosition: false,
  },
  props: {
    type: 'VectorImageProperties',
    metaData: {
      schemaVersion: '1.0',
    },
    displayMode: 'fit',
  },
  skin: 'skins.viewer.VectorImageSkin',
  componentType: 'wysiwyg.viewer.components.VectorImage',
  data: {
    type: 'VectorImage',
    metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
    svgId: item.uri,
  },
  design: {
    type: 'VectorImageDesignData',
    metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
    shapeStyle: {
      opacity: 1,
      strokeOpacity: 1,
      stroke: '#5E97FF',
      strokeWidth: 4,
      enableStroke: false,
    },
    overrideColors: null,
  },
  style: {
    type: 'TopLevelStyle',
    metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
    style: {
      groups: {},
      properties: {},
      propertiesSource: {},
    },
    componentClassName: 'wysiwyg.viewer.components.VectorImage',
    styleType: 'custom',
    skin: 'skins.viewer.VectorImageSkin',
  } as StyleRef,
});
