import React from 'react';
import { AvailableAppActions } from './availableAppActions';
import { useSelectedApp } from '../selectedAppContext/selectedAppContext';

export const AvailableAppActionsButton = () => {
  const { app } = useSelectedApp().selectedApp;
  return (
    <AvailableAppActions
      app={app}
      origin={'app details page'}
      contextMenuProps={{
        alignment: 'RIGHT',
        direction: 'MIDDLE',
      }}
    />
  );
};
