// @ts-nocheck
import constants from '@/constants';
import * as helpIds from '@/helpIds';
import gfppDataUtils from '../../utils/gfppDataUtils';
import type { EditorAPI } from '@/editorAPI';
import type { CompRef } from '@wix/document-services-types';

const { ACTIONS } = constants.ROOT_COMPS.GFPP;
const SETTINGS_PANEL_NAME =
  'compPanels.panels.StripContainerSlideShow.settingsPanel';
const BOX_SLIDE_SHOW_MANAGES_SLIDES_PANEL_NAME =
  'rEditor.panels.boxSlideShowManageSlidesPanel';

export default {
  untranslatable: true,
  mainActions: [
    {
      label:
        constants.BOX_SLIDE_SHOW.GFPP.ACTIONS.LABELS
          .REGULAR_SLIDESHOW_SETTINGS_MANAGE_SLIDES,
      isApplied: true,
      isSelected: gfppDataUtils.getPanelStateFn(
        BOX_SLIDE_SHOW_MANAGES_SLIDES_PANEL_NAME,
      ),
      onClick(editorAPI: EditorAPI, compRef: CompRef, origin: string) {
        const panelProps = {
          selectedComponent: compRef,
          origin,
        };
        gfppDataUtils.toggleComponentPanel(
          editorAPI,
          BOX_SLIDE_SHOW_MANAGES_SLIDES_PANEL_NAME,
          panelProps,
        );
      },
    },
    {
      label: 'gfpp_main_action_customize_current_slide',
      isApplied: true,
      isSelected: gfppDataUtils.getPanelStateFn(ACTIONS.BACKGROUND),
      onClick: gfppDataUtils.getTogglePanelFn(ACTIONS.BACKGROUND),
      automationId: 'gfpp-button-background',
    },
  ],
  enabledActions: [
    ACTIONS.SETTINGS,
    ACTIONS.LAYOUT,
    ACTIONS.DESIGN,
    ACTIONS.HELP,
  ],
  mobileEnabledActions: [
    ACTIONS.SETTINGS,
    ACTIONS.LAYOUT,
    ACTIONS.HIDE,
    ACTIONS.HELP,
    ACTIONS.MOBILE_BACKGROUND_SETTINGS,
    ACTIONS.ANIMATION,
  ],
  presetActions: {
    help: {
      helpId: helpIds.GFPP.STRIP_CONTAINER_SLIDE_SHOW,
      mobileHelpId: 'c87be5fd-fa3a-4eb5-887e-13ae266ad306',
    },
    settings: {
      isSelected(editorAPI) {
        return gfppDataUtils.findPanel(
          editorAPI,
          SETTINGS_PANEL_NAME,
          'wysiwyg.viewer.components.StripContainerSlideShow',
        );
      },
      onClick(editorAPI: EditorAPI, compRef: CompRef, origin: string) {
        const compType = editorAPI.components.getType(compRef);
        const panelProps = {
          selectedComponent: compRef,
          compType,
          origin,
        };
        gfppDataUtils.toggleComponentPanel(
          editorAPI,
          SETTINGS_PANEL_NAME,
          panelProps,
          compType,
        );
      },
    },
  },
};
