import React from 'react';
import { translate } from '@/i18n';
import { Tooltip, TextLabel, RichText } from '@wix/wix-base-ui';

import { symbol as Symbol } from '@wix/santa-editor-symbols';

interface Props {
  text?: string;
  actionLabel?: string;
  onActionClick?: () => void;
}

const UnsupportedMenuWarning: React.FC<Props> = ({
  text,
  actionLabel,
  onActionClick,
}) => {
  if (!text) {
    return <span />;
  }

  const tooltipContent = (
    <div>
      <TextLabel value={text} enableEllipsis={false} />
      <RichText>
        <a onClick={onActionClick}>{translate(actionLabel)}</a>
      </RichText>
    </div>
  );

  return (
    <Tooltip
      className="menu-manage-panel__header-menu-title__warning"
      content={tooltipContent}
      interactive
    >
      <Symbol name="warning" />
    </Tooltip>
  );
};

export default UnsupportedMenuWarning;
