{
  "headerScrollPanel": {
    "change_scroll_effect": {
      "evid": 732,
      "fields": {
        "site_id": "guid",
        "scroll_type": "string",
        "header_height": "numeric"
      }
    }
  },
  "MAX_SCROLL_PANEL": {
    "evid": 795,
    "fields": {
      "max_scroll": 	"numeric",
      "panel_total_scroll_px": 	"numeric",
      "has_scroll":	"bool",
      "panel_name": "string"
    }
  }
}
