//eslint-disable-next-line @wix/santa-editor/scoped-imports
import {
  type BlogWelcomeDispatchProps,
  mapDispatchToBlogWelcomeSectionProps,
} from '@/addPanelData/mappers';
import { helpArticles as blogHelpArticles } from '@/blog';
import { wrapWithPropsTransformer } from '../utils';

export interface BlogWelcomeProps {
  blogMainAction: () => void;
  blogSecondaryAction: () => void;
}

export const blogWelcomeMapper = wrapWithPropsTransformer<
  BlogWelcomeDispatchProps,
  BlogWelcomeProps
>(mapDispatchToBlogWelcomeSectionProps, (props) => {
  const getBlogMainAction = () => {
    if (props.siteHasOldBlog && props.isNewBlogMagicMigrationEnabled) {
      return props.magicMigrateOldBlog;
    }

    if (props.siteHasOldBlog && props.isNewBlogQuickMigrationEnabled) {
      return props.migrateOldBlog;
    }

    return props.installBlogApp;
  };

  const getBlogSecondaryAction = () => {
    return props.siteHasOldBlog &&
      (props.isNewBlogQuickMigrationEnabled ||
        props.isNewBlogMagicMigrationEnabled)
      ? () => props.openHelpCenter(blogHelpArticles.switchToNewBlog)
      : props.openAppMarket;
  };

  return {
    blogMainAction: getBlogMainAction(),
    blogSecondaryAction: getBlogSecondaryAction(),
  };
});
