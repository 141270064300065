import { EditorAPIKey, EditorCoreApiKey } from '@/apis';
import { BaseEntryScope, createEntryPoint } from '@/apilib';
import { SiteGlobalDataStore } from './siteGlobalDataStore';
import { SiteGlobalDataApiKey } from './publicApiKey';
import {
  initializeSiteGlobalData,
  SiteGlobalDataApi,
} from './siteGlobalDataApi';

export class Scope extends BaseEntryScope {
  editorAPI = this.useDependency(EditorAPIKey);
  store = this.declareStore(SiteGlobalDataStore);
  editorCoreAPI = this.useDependency(EditorCoreApiKey);
}

export const SiteGlobalDataEntryPoint = createEntryPoint({
  name: 'SiteGlobalData',
  Scope,
  publicApi({ contributeApi }) {
    contributeApi(SiteGlobalDataApiKey, SiteGlobalDataApi);
  },
  async initialize(scope: Scope) {
    // TODO: check for stageIsReady VS dsIsReady
    await scope.editorCoreAPI.hooks.stageIsReady.promise;
    initializeSiteGlobalData(scope);
  },
});
