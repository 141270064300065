import React, { type ComponentType, useEffect, useRef } from 'react';
import { constants as textControlsConstants } from '@/textControls';
import * as textControls from '@/textControls';
import { hoc } from '@/util';
import _ from 'lodash';
import { type EditorState, selection } from '@/stateManagement';
import type { StateMapperArgs } from 'types/redux';
import type { FontOption } from 'types/documentServices';
import type { CkOff } from '@/textControls';
import type { TextManager } from 'types/data';
import type { StyleRef, CompRef } from '@wix/document-services-types';
import type { TextThemeStyleMap } from '../utils/textThemes';
import type { NonThemedAction, ThemedAction } from '../utils/textAction';
import type { CKEditorInjectedProps } from '@/textControls';
import * as stateManagement from '@/stateManagement';

interface CommandOptions {
  execWithoutFocus?: boolean;
  execWithoutHistory?: boolean;
}

const PROPERTIES_FROM_THEME = [
  ...textControls.constants.STYLE_COMMANDS,
  'color',
  'fontWeight',
  'fontStyle',
];

const getFontMap = (fonts: FontOption[]) => {
  return _.keyBy(
    fonts.flatMap((fontOption) =>
      fontOption.fonts.map((font) => ({
        ...font,
        lang: fontOption.lang,
        key: font.cssFontFamily,
      })),
    ),
    'cssFontFamily',
  );
};

const getThemedActionState = <T extends {}>(
  textManager: TextManager,
  action: ThemedAction<T>,
  compStyle: StyleRef,
  theme: TextThemeStyleMap,
) => {
  return action.getState(textManager, compStyle, theme);
};

const getNonThemedActionState = <State extends {}>(
  textManager: TextManager,
  action: NonThemedAction<State>,
  compStyle: StyleRef,
) => {
  return action.getState(textManager, compStyle);
};

const getComponentsForStyleActions = (
  state: EditorState,
  widgetDesignSelectedComponents: CompRef[],
) => {
  const selectedComponent = selection.selectors.getSelectedCompsRefs(state)[0];
  return widgetDesignSelectedComponents || [selectedComponent];
};

const mapStateToProps = (
  { editorAPI, state }: StateMapperArgs,
  ownProps: WithTextStyleActionsOwnProps,
) => {
  const getFormatBlockCss = () =>
    ownProps.cmdState.formatBlock?.class || 'font_8';

  const widgetDesignSelectedComponents =
    stateManagement.text.selectors.getWidgetDesignSelectedComponents(state);

  const componentsForStyleUpdate = getComponentsForStyleActions(
    state,
    widgetDesignSelectedComponents,
  );
  const compStyle = editorAPI.components.style.get(
    _.head(componentsForStyleUpdate),
  );
  const theme = ownProps.themeMap[getFormatBlockCss()];

  const shouldEditStyleProperties =
    !!editorAPI.documentServices.components.responsiveLayout?.get(
      _.head(componentsForStyleUpdate),
    );
  interface StyleActions {
    backColor: (
      colorValue: string | CkOff,
      cmdOptions?: any,
      isMouseOut?: boolean,
    ) => void;
    foreColor: (
      colorValue: string | CkOff,
      cmdOptions?: any,
      isMouseOut?: boolean,
    ) => void;
    lineHeight: (
      lineHeight: string | CkOff,
      cmdOptions?: CommandOptions,
    ) => void;
    justify: (
      value: textControls.actions.CmdDefToStyleDef,
      cmdOptions?: CommandOptions,
    ) => void;
    letterSpacing: (spacing: string, cmdOptions?: CommandOptions) => void;
    fontFamily: (fontFamily: string, cmdOptions?: CommandOptions) => void;
    bold: (value: string, cmdOptions?: CommandOptions) => void;
    italic: (value: string, cmdOptions?: CommandOptions) => void;
    underline: (value: string, cmdOptions?: CommandOptions) => void;
    fontSize: (fontSize: string, cmdOptions?: CommandOptions) => void;
    resetThemeStyleProperties: () => void;
  }

  const getActionsHandlers = (): StyleActions => ({
    justify: (value, cmdOptions?) => {
      ownProps.execCommand(value, cmdOptions);
    },

    backColor: (colorValue, cmdOptions?, isMouseOut?) => {
      ownProps.execCommand('backColor', colorValue, cmdOptions, isMouseOut);
    },

    foreColor: (colorValue, cmdOptions?, isMouseOut?) => {
      ownProps.execCommand('foreColor', colorValue, cmdOptions, isMouseOut);
    },

    lineHeight: (lineHeight, cmdOptions?) => {
      ownProps.execCommand('lineHeight', lineHeight, cmdOptions);
    },

    letterSpacing: (spacing, cmdOptions?) => {
      ownProps.execCommand('letterSpacing', spacing, cmdOptions);
    },

    fontFamily: (fontFamily, cmdOptions?) => {
      ownProps.execCommand('fontFamily', fontFamily, cmdOptions);
    },

    bold: (value, cmdOptions?) => {
      ownProps.execCommand('bold', { value }, cmdOptions);
    },

    italic: (value, cmdOptions?) => {
      ownProps.execCommand('italic', { value }, cmdOptions);
    },

    underline: (value, cmdOptions?) => {
      ownProps.execCommand('underline', { value }, cmdOptions);
    },

    fontSize: (fontSize, cmdOptions?) => {
      ownProps.execCommand('fontSize', fontSize, cmdOptions);
    },

    resetThemeStyleProperties: () => {},
  });

  const applyActionValue = <T extends {}>(
    action: ThemedAction<T> | NonThemedAction<T>,
    value: T,
  ) => {
    if (widgetDesignSelectedComponents) {
      editorAPI.store.dispatch(
        stateManagement.text.actions.multiselect.execStylePropertyCmd(
          action,
          value,
          theme,
          textControls,
        ),
      );
      return;
    }

    if (action.type === 'themed') {
      textControls.themedActions.applyThemedAction(
        _.head(componentsForStyleUpdate),
        action,
        value,
        compStyle,
        theme,
        editorAPI.components.style.update,
        ownProps.textManager,
      );
      return;
    }

    textControls.nonThemedAction.applyNonThemedAction(
      _.head(componentsForStyleUpdate),
      action,
      value,
      compStyle,
      editorAPI.components.style.update,
      ownProps.textManager,
    );
  };

  const getActionsHandlersConsideringStyleProperties = (): StyleActions => ({
    justify: (value) => {
      applyActionValue(
        textControls.actions.getJustifyAction(ownProps.textManager),
        textControls.actions.CmdDefToStyleDef[value],
      );
    },

    backColor: (value) => {
      applyActionValue(
        textControls.actions.backColorAction,
        value === textControlsConstants.CK_OFF ? '' : value,
      );
    },

    foreColor: (value) => {
      applyActionValue(
        textControls.actions.foreColorAction,
        value === textControlsConstants.CK_OFF ? '' : value,
      );
    },

    lineHeight: (lineHeight) => {
      applyActionValue(textControls.actions.lineHeightAction, lineHeight);
    },

    letterSpacing: (value) => {
      applyActionValue(textControls.actions.letterSpacingAction, value);
    },

    fontFamily: (value) => {
      applyActionValue(
        textControls.actions.createFontFamilyAction(getFontMap(ownProps.fonts)),
        value,
      );
    },

    bold: (value) => {
      applyActionValue(
        textControls.actions.boldAction,
        value ? 'bold' : 'normal',
      );
    },

    italic: (value) => {
      applyActionValue(
        textControls.actions.italicAction,
        value ? 'italic' : 'normal',
      );
    },

    underline: (value) => {
      applyActionValue(
        textControls.actions.underlineAction,
        value ? 'underline' : 'normal',
      );
    },

    fontSize: (value) => {
      applyActionValue(textControls.actions.fontSizeAction, value);
    },

    resetThemeStyleProperties: () => {
      componentsForStyleUpdate.forEach((compRef: CompRef) => {
        const compStyleToReset = editorAPI.components.style.get(compRef);
        compStyleToReset.style.properties = _.omit(
          compStyleToReset.style.properties,
          PROPERTIES_FROM_THEME,
        );
        editorAPI.components.style.update(compRef, compStyleToReset);
      });
    },
  });

  const actionValues = {
    justify: Object.values(textControlsConstants.ALIGNMENT_TYPES).find(
      (alignmentTypeCandidate) =>
        (ownProps.cmdState as any)[alignmentTypeCandidate],
    ),
  };

  const getActionValuesConsideringStyleProperties = () => {
    return {
      justify:
        textControls.actions.TextAlign[
          getNonThemedActionState(
            ownProps.textManager,
            textControls.actions.getJustifyAction(ownProps.textManager),
            compStyle,
          )
        ],

      backColor: getNonThemedActionState(
        ownProps.textManager,
        textControls.actions.backColorAction,
        compStyle,
      ),

      foreColor: getThemedActionState(
        ownProps.textManager,
        textControls.actions.foreColorAction,
        compStyle,
        theme,
      ),

      lineHeight: getThemedActionState(
        ownProps.textManager,
        textControls.actions.lineHeightAction,
        compStyle,
        theme,
      ),

      letterSpacing: getThemedActionState(
        ownProps.textManager,
        textControls.actions.letterSpacingAction,
        compStyle,
        theme,
      ),

      fontFamily: textControls.fontUtils.getOnlyFontFamily(
        getThemedActionState(
          ownProps.textManager,
          textControls.actions.createFontFamilyAction(
            getFontMap(ownProps.fonts),
          ),
          compStyle,
          theme,
        ),
      ),

      bold:
        getThemedActionState(
          ownProps.textManager,
          textControls.actions.boldAction,
          compStyle,
          theme,
        ) === 'bold',

      italic:
        getThemedActionState(
          ownProps.textManager,
          textControls.actions.italicAction,
          compStyle,
          theme,
        ) === 'italic',

      underline:
        getNonThemedActionState(
          ownProps.textManager,
          textControls.actions.underlineAction,
          compStyle,
        ) === 'underline',

      fontSize: Number(
        getThemedActionState(
          ownProps.textManager,
          textControls.actions.fontSizeAction,
          compStyle,
          theme,
        ).replace('px', ''),
      ),
    };
  };

  return {
    styleProperties: compStyle,
    isEditStyleProperties: shouldEditStyleProperties,
    cmdState: {
      ...ownProps.cmdState,
      ...(!shouldEditStyleProperties
        ? actionValues
        : getActionValuesConsideringStyleProperties()),
    },
    selectedCmdState: {
      ...ownProps.selectedCmdState,
      ...(!shouldEditStyleProperties
        ? actionValues
        : getActionValuesConsideringStyleProperties()),
    },
    actionsHandlers: !shouldEditStyleProperties
      ? getActionsHandlers()
      : getActionsHandlersConsideringStyleProperties(),
  };
};

export type WithTextStyleActionsConnectProps = ReturnType<
  typeof mapStateToProps
>;

export type WithTextStyleActionsOwnProps = {
  themeMap: any;
  fonts: FontOption[];
} & CKEditorInjectedProps;

export const withTextStyleActions = <T extends WithTextStyleActionsOwnProps>(
  Component: ComponentType<T>,
) => {
  const WithTextStyleActions: React.FC<
    WithTextStyleActionsConnectProps & WithTextStyleActionsOwnProps
  > = (props) => {
    const styleProperties = props.styleProperties;
    const refreshPropsCollector = props.refreshPropsCollector;

    const prevStyleRef = useRef(styleProperties);

    useEffect(() => {
      if (!_.isEqual(prevStyleRef.current, styleProperties)) {
        prevStyleRef.current = styleProperties;
        refreshPropsCollector();
      }
    });

    return props.textManager ? (
      <Component {...(props as T & WithTextStyleActionsConnectProps)} />
    ) : null;
  };

  return _.flow(
    hoc.connect(hoc.STORES.EDITOR_API, mapStateToProps),
    textControls.withCKEditor,
  )(WithTextStyleActions);
};
