import type {
  StudioComponent,
  AppDataComponent,
  AppData,
} from '@wix/document-services-types';

export const isWidgetPluginComponent = (comp: AppDataComponent): boolean =>
  comp.type === 'WIDGET_PLUGIN';

export const isStudioComponent = (
  comp: AppDataComponent,
): comp is StudioComponent => comp.type === 'STUDIO';

export const shouldShowInAddPanel = (app: AppData) => {
  const studioComponent = app.components.find(isStudioComponent);
  return studioComponent && !studioComponent.data.hideInAddPanel;
};
