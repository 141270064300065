import { createReduxStore } from '@/apilib';

interface WixCodeWorkspaceState {
  isInLocalDevMode: boolean;
}

const getInitialState = (): WixCodeWorkspaceState => ({
  isInLocalDevMode: false,
});

export const wixCodeWorkspaceStore = createReduxStore({
  getInitialState,
  selectors: {
    isInLocalDevMode: (state: WixCodeWorkspaceState) => state.isInLocalDevMode,
  },
  actions: {
    setIsInLocalDevMode: (
      state: WixCodeWorkspaceState,
      isInLocalDevMode: WixCodeWorkspaceState['isInLocalDevMode'],
    ) => {
      return {
        ...state,
        isInLocalDevMode,
      };
    },
  },
});
