import type { Scope } from '../../switchLayoutEntryPoint';
import type { ScanSwitchLayoutPresetData } from './scanSwitchLayoutPresetTypes';
import {
  submitSectionScan,
  getSectionScan,
} from './scanSwitchLayoutPresetUtil';

export const mapStateToProps = (scope: Scope) => {
  const { editorAPI, sectionsAPI } = scope;
  return {
    // Used only in designers scan flow
    // eslint-disable-next-line @wix/santa-editor/dsReadSerializeIsTooExpensive
    selectedSectionSerialized: editorAPI.components.serialize(
      sectionsAPI.getFocusedSection(),
    ),
    pageId: editorAPI.pages.getCurrentPageId(),
    sectionId: sectionsAPI.getFocusedSection().id,
    metaSiteId: editorAPI.dsRead.generalInfo.getMetaSiteId(),
    selectedSection: sectionsAPI.getSelectedSection(),
  };
};

export const mapDispatchToProps = (scope: Scope) => {
  return {
    handleScannedSection: async (
      sectionScanData: ScanSwitchLayoutPresetData,
    ) => {
      return await submitSectionScan(scope, sectionScanData);
    },
    getSectionScanData: async (pageId: string, sectionId: string) => {
      return await getSectionScan({
        pageId,
        sectionId,
      });
    },
  };
};
