import React from 'react';
import { popoverTemplates } from '@/baseUI';
import { browserUtil } from '@/util';

const NAMES_WITH_COMPONENT_TOOLTIP_SET = new Set([
  'bold',
  'italic',
  'underline',
]);

export const getTextTooltip = (name: string, key?: string) => {
  const label = `text_editor_buttons_tooltip_${name}`;

  if (NAMES_WITH_COMPONENT_TOOLTIP_SET.has(name)) {
    const shortcut = `${browserUtil.getKeyboardSpecialKey().label} + ${key}`;
    return (
      <popoverTemplates.keyboardShortcutTooltip
        label={label}
        shortcut={shortcut}
      />
    );
  }

  return label;
};
