export const AddSectionBiOrigin = 'add_section';
export const SelectSectionBiOrigin = 'section_click';

export const AUTOMATION_IDS = {
  BUTTON: 'add-section-button',
  SECTION_BOX_SELECTED: 'section-box-Selected',
  BUTTON_CONTAINER: 'add-section-button-container',
  RESIZE_HANDLE_CONTAINER: 'section-resize-handle-container',
  EMPTY_STATE: {
    CONTAINER: 'section-empty-state',
    ADD_ELEMENT: 'section-empty-state-add-element',
    ADD_SECTION: 'section-empty-state-add-section',
  },
};

export const enum ButtonAlignment {
  TOP = 'TOP',
  BOTTOM = 'BOTTOM',
}
