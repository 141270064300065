import type { MapDispatchToProps, MapStateToProps } from 'types/redux';
import * as stateManagement from '@/stateManagement';
import constants from '@/constants';
import { promiseUtils } from '@/util';

const { LABELS } = constants.ROOT_COMPS.TOPBAR;
const FIRST_TIME_ON_BRANCH_SHOW_INDICATION_DELAY = 2000;

const { setSiteUserPreferences, registerSitePreferencesCallbacks } =
  stateManagement.userPreferences.actions;
const { getSiteUserPreferences } = stateManagement.userPreferences.selectors;

export interface BranchIndicatorStateProps {
  branchName?: string;
  isVisible: boolean;
  shouldFetchBranchData: boolean;
}

export interface BranchIndicatorDispatchProps {
  fetchAndAssignBranchesData(): Promise<void>;
}

const getUserPrefBranchesIndicatorPanelDontShowAgain = (
  dispatch: AnyFixMe,
  getState: AnyFixMe,
) =>
  getSiteUserPreferences(
    constants.USER_PREFS.BRANCHES.INDICATOR_PANEL_DONT_SHOW_AGAIN,
  )(getState());

export const mapDispatchToProps: MapDispatchToProps<
  BranchIndicatorDispatchProps,
  any
> = (dispatch, ownProps) => {
  const showBranchIndicationPanelIfFirstTimeOnBranches = () => {
    dispatch(
      registerSitePreferencesCallbacks(async (success) => {
        if (success) {
          const branchesIndicatorPanelDontShowAgain = dispatch(
            getUserPrefBranchesIndicatorPanelDontShowAgain,
          );
          const showPopupBranchIndicatorPanel =
            !branchesIndicatorPanelDontShowAgain;

          if (showPopupBranchIndicatorPanel) {
            await promiseUtils.waitFor(
              FIRST_TIME_ON_BRANCH_SHOW_INDICATION_DELAY,
            );
            ownProps.openDropPanel(LABELS.VERSION_INDICATOR);
            dispatch(
              setSiteUserPreferences(
                constants.USER_PREFS.BRANCHES.INDICATOR_PANEL_DONT_SHOW_AGAIN,
                true,
              ),
            );
          }
        }
      }),
    );
  };

  const fetchAndAssignBranchesData = () =>
    dispatch(
      stateManagement.branches.actions.fetchAndAssignBranchesData(
        ownProps.branchId,
      ),
    ).then(({ hasBranches }: AnyFixMe) => {
      return hasBranches && showBranchIndicationPanelIfFirstTimeOnBranches();
    });

  return {
    fetchAndAssignBranchesData,
  };
};

export const mapStateToProps: MapStateToProps<
  BranchIndicatorStateProps,
  any
> = ({ state }) => ({
  branchName: stateManagement.branches.selectors.getCurrentBranchName(state),
  isVisible:
    stateManagement.branches.selectors.getCurrentBranchName(state) &&
    stateManagement.branches.selectors.getHasBranches(state),
  shouldFetchBranchData:
    !stateManagement.branches.selectors.getWasBranchesListFetched(state),
});
