// @ts-nocheck
import _ from 'lodash';
import * as superApps from '../superApps';
import * as superAppsCustomData from '../superAppsCustomData';
import constants from '../../constants/constants';
import * as experiments from '../../experiments/experiments';

let customMainActions = {};
const getCustomMainActions = function () {
  return customMainActions;
};

const init = function (panelManager) {
  const json = superAppsCustomData.getSuperAppsGfppData();
  customMainActions = parseSupperAppsCustomDataJson(panelManager, json);
};

const parseSupperAppsCustomDataJson = function (panelManager, json) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
  _.forEach(json, function (app) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
    _.forEach(app, function (widget) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/filter
      widget.actions = _.filter(widget.actions, function (action) {
        return parseExperiment(action) && parseOnClick(panelManager, action);
      });
    });
  });
  return json;
};

const parseOnClick = function (panelManager, action) {
  const onClickFunctions = {
    openDashboardUrl,
    openSuperAppsModal: superApps.openSuperAppsModal,
    openPanel: panelManager.openPanel,
  };

  if (action?.onClick?.name && action?.onClick?.params) {
    const fn = onClickFunctions[action.onClick.name];
    if (_.isFunction(fn)) {
      action.onClick = fn.bind.apply(fn, [null].concat(action.onClick.params));
      return true;
    }
    return false;
  }

  return false;
};

const parseExperiment = function (action) {
  if (action.experiment) {
    const fn = experiments[action.experiment];
    if (_.isFunction(fn) && fn()) {
      delete action.experiment;
      return true;
    }
  } else {
    return true;
  }
};

const openDashboardUrl = function (params) {
  params = params || {};
  superApps.openDashboardUrl(
    params.appDefId,
    {
      state: params.state,
      title: params.title,
      origin: params.origin,
      widgetId: params.widgetId,
      onClose: superApps.refreshAppCompsForAppDefId.bind(
        undefined,
        params.appDefId,
      ),
      shouldNotCloseOnBlur: params.shouldNotCloseOnBlur,
      isBareMode: params.isBareMode,
      options: params.options,
      widgetless: params.widgetless,
    },
    { type: constants.BI.type.GFPP_MANAGE },
  );
};

const clearCustomMainActions = function () {
  customMainActions = {};
};

export { init, getCustomMainActions, clearCustomMainActions };
