import React, { type FunctionComponent, type ReactElement } from 'react';
import { Tooltip } from '@wix/wix-base-ui';
import TopBarButton, {
  type TopBarButtonProps,
} from '../topBarButton/topBarButton';

interface TopBarButtonWithTooltipProps {
  tooltip?: ReactElement | string;
  tooltipMarginBottom?: number;
  tooltipMarginRight?: number;
  tooltipMaxWidth?: number;
  tooltipOpenDelay?: number;
  ref?: () => void;
}

const TopBarButtonWithTooltip: FunctionComponent<
  TopBarButtonWithTooltipProps & TopBarButtonProps
> = (props) => {
  const { tooltip, ...restProps } = props;

  return props.tooltip ? (
    <Tooltip
      content={props.tooltip}
      marginBottom={props.tooltipMarginBottom}
      marginRight={props.tooltipMarginRight}
      maxWidth={props.tooltipMaxWidth}
      openDelay={props.tooltipOpenDelay}
      closeOnMouseClick={true}
    >
      <TopBarButton {...restProps} />
    </Tooltip>
  ) : (
    <TopBarButton {...restProps} />
  );
};

TopBarButtonWithTooltip.defaultProps = {
  tooltipMarginBottom: 6,
  tooltipMaxWidth: 220,
};
TopBarButtonWithTooltip.displayName = 'TopBarButtonWithTooltip';

export default TopBarButtonWithTooltip;
