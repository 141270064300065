import { PAGE_SECTIONS_EDITOR_DATA_NAMESPACE } from '../constants';

import { getAllPages, hasPageLabellingData } from './page';
import { isSectionizerEnabled } from './specs';

import type { DocumentServicesObject, CompRef } from 'types/documentServices';

function cleanupLabellingEditorData(
  documentServices: DocumentServicesObject,
  pageRef: CompRef,
) {
  const isTemplate = documentServices.generalInfo.isTemplate?.();
  const isStudio = documentServices.generalInfo.getUserInfo()?.isStudio; // redundant, but lets exclude studio from migration

  if (
    isStudio ||
    isTemplate ||
    isSectionizerEnabled() ||
    !hasPageLabellingData(documentServices, pageRef)
  ) {
    return;
  }

  documentServices.components.features.remove(
    pageRef,
    PAGE_SECTIONS_EDITOR_DATA_NAMESPACE,
  );
}

export function cleanupEditorData(documentServices: DocumentServicesObject) {
  const pageRefList = getAllPages(documentServices);

  pageRefList.forEach((pageRef) => {
    cleanupLabellingEditorData(documentServices, pageRef);
  });
}
