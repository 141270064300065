import { DealerOffersServingService } from '@wix/ambassador-dealer-offers-serving-service/http';
import type { EditorAPI } from '@/editorAPI';
import constants from '@/constants';

const DEALER_OFFERS_SERVIING_SERVICE_URL =
  '/_api/dealer-offers-serving-service';
const feedbackPanelEstateId = '356826b3-eaca-427e-bca3-444696521bbf'; // https://bo.wix.com/dealer/placements/356826b3-eaca-427e-bca3-444696521bbf
const FETCH_TIMEOUT = 2000;

function sleep(timeout: number) {
  return new Promise((resolve) => setTimeout(resolve, timeout));
}

function withTimeout<T extends Promise<any>>(promise: T, ms: number) {
  const timeoutPromise = sleep(ms).then(() => Promise.reject('timeout error'));

  return Promise.race([promise, timeoutPromise]);
}

let cachedPromise: Promise<boolean>;

async function fetchDealerData(metaSiteInstance: string) {
  const dealerService = DealerOffersServingService(
    DEALER_OFFERS_SERVIING_SERVICE_URL,
  ).DealerOffersServing();

  const { offers } = await dealerService({
    Authorization: metaSiteInstance,
  }).listOffers({ realEstateId: feedbackPanelEstateId });

  return offers;
}

export async function prefetchFeedbackPanelDealerData(
  editorAPI: EditorAPI,
): Promise<boolean> {
  if (cachedPromise) return cachedPromise;

  const metaSiteInstance = editorAPI.dsRead?.platform.getAppDataByApplicationId(
    constants.APPLICATIONS.META_SITE_APPLICATION_ID,
  )?.instance;

  if (!metaSiteInstance) return;

  cachedPromise = withTimeout(fetchDealerData(metaSiteInstance), FETCH_TIMEOUT)
    .then((result) => result.length > 0)
    .catch(() => false);

  return cachedPromise;
}

export async function shouldBeShownFromDealer(
  editorAPI: EditorAPI,
): Promise<boolean> {
  return prefetchFeedbackPanelDealerData(editorAPI).then((value) =>
    Boolean(value),
  );
}
