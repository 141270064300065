// @ts-nocheck
import _ from 'lodash';

const mapStateToProps = ({ editorAPI }, props) => {
  const getIsCustomErrorPage = () => {
    const pageId = props.menuItem.pageData.id;
    return editorAPI.pages.isCustomErrorPage(pageId);
  };
  const isDeveloperModeEnabled = editorAPI.developerMode.isEnabled();
  const applicationTabs =
    editorAPI.pages.settings.getApplicationPageSettingsTabs(
      props.menuItem.pageData.id,
    );
  const isApplicationOverridingSettingTab = !_.isEmpty(applicationTabs);
  const isCustomErrorPage = getIsCustomErrorPage();

  return {
    isDeveloperModeEnabled,
    isApplicationOverridingSettingTab,
    applicationTabs,
    isCustomErrorPage,
  };
};

const getRouterDataForPageIfExist =
  (...args) =>
  (dispatch, getState, { editorAPI }) =>
    editorAPI.dsRead.routers.getRouterDataForPageIfExist(...args);

const sendBi =
  (eventType, params) =>
  (dispatch, getState, { editorAPI }) => {
    editorAPI.bi.event(eventType, params);
  };

const mapDispatchToProps = (dispatch) => ({
  sendBi(eventType, params) {
    dispatch(sendBi(eventType, params));
  },
  getRouterDataForPageIfExist(...args) {
    return dispatch(getRouterDataForPageIfExist(...args));
  },
});

export { mapStateToProps, mapDispatchToProps };
