import { translate } from '@/i18n';
export default {
  type: 'Container',
  components: [
    {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
      layout: {
        width: 110,
        height: 16,
        x: 51,
        y: 60,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.WRichText',
      metaData: {
        pageId: 'svx46',
      },
      parent: 'comp-kgujn6rm',
      data: {
        type: 'StyledText',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'svx46',
        },
        text: `<h2 class="font_2" style="font-size:13px; line-height:1.2em; text-align:center"><span style="font-size:13px"><span style="color:#000000"><span style="letter-spacing:0.35em"><span style="font-family:futura-lt-w01-book,sans-serif">${translate(
          'interactions_preset_circle_about_text_1',
        )}</span></span></span></span></h2>`,
        stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
        linkList: [],
      },
      props: {
        type: 'WRichTextProperties',
        metaData: {
          schemaVersion: '1.0',
          autoGenerated: false,
          pageId: 'svx46',
        },
        brightness: 1,
        packed: true,
        verticalText: false,
      },
      style: 'txtNew',
      mobileStructure: {
        layout: {
          width: 102,
          height: 18,
          x: 74,
          y: 52,
          scale: 1,
          rotationInDegrees: 0,
          fixedPosition: false,
        },
        props: {
          type: 'WRichTextProperties',
          metaData: {
            schemaVersion: '1.0',
            autoGenerated: false,
            pageId: 'svx46',
          },
          brightness: 1,
          packed: true,
          verticalText: false,
        },
        metaData: {
          originalCompId: 'comp-kgujn6rx',
          author: 'studio',
        },
      },
    },
    {
      type: 'Component',
      skin: 'skins.viewer.VectorImageSkin',
      layout: {
        width: 213,
        height: 213,
        x: 0,
        y: 0,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.VectorImage',
      metaData: {
        pageId: 'svx46',
      },
      parent: 'comp-kgujn6rm',
      data: {
        type: 'VectorImage',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'svx46',
        },
        svgId: '8463f60718194af748c49dddbe45b668_svgshape.v1.HollowCircle.svg',
      },
      props: {
        type: 'VectorImageProperties',
        metaData: {
          schemaVersion: '1.0',
          pageId: 'svx46',
        },
        displayMode: 'stretch',
        flip: 'none',
      },
      design: {
        type: 'VectorImageDesignData',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'svx46',
        },
        overrideColors: {
          color1: '#FFCB7D',
        },
        shapeStyle: {
          opacity: 1,
          strokeWidth: 1,
          stroke: 'color_15',
          strokeOpacity: 1,
          enableStroke: false,
        },
      },
      transitions: {
        type: 'TransitionData',
        'timing-function': 'ease-in-out',
        duration: 0.4,
        delay: 0,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'svx46',
        },
      },
      transformations: {
        type: 'TransformData',
        hidden: true,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'svx46',
        },
      },
      scopedTransformations: {
        'variants-kgujn6rv': {
          type: 'TransformData',
          hidden: false,
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'svx46',
          },
          rotate: 0,
        },
      },
      style: {
        type: 'ComponentStyle',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'svx46',
        },
        style: {
          properties: {},
          propertiesSource: {},
          groups: {},
        },
        componentClassName: 'wysiwyg.viewer.components.VectorImage',
        pageId: '',
        compId: 'i8yjldkb_0',
        styleType: 'custom',
        skin: 'skins.viewer.VectorImageSkin',
      },
      mobileStructure: {
        layout: {
          width: 150,
          height: 150,
          x: 31,
          y: 0,
          scale: 1,
          rotationInDegrees: 0,
          fixedPosition: false,
        },
        metaData: {
          originalCompId: 'comp-kgujn6ry1',
          author: 'studio',
        },
      },
    },
    {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
      layout: {
        width: 164,
        height: 44,
        x: 23,
        y: 82,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.WRichText',
      metaData: {
        pageId: 'svx46',
      },
      parent: 'comp-kgujn6rm',
      data: {
        type: 'StyledText',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'svx46',
        },
        text: `<h2 class="font_2" style="font-size: 32px; text-align: center; color: #000000;"><span style="font-size:32px;"><span style="letter-spacing:0em"><span style="font-family:playfairdisplay-bold,playfair display,serif">${translate(
          'interactions_preset_circle_about_text_2',
        )}</span></span></span></h2>`,
        stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
        linkList: [],
      },
      props: {
        type: 'WRichTextProperties',
        metaData: {
          schemaVersion: '1.0',
          autoGenerated: false,
          pageId: 'svx46',
        },
        brightness: 1,
        packed: false,
        minHeight: 44,
        verticalText: false,
      },
      transitions: {
        type: 'TransitionData',
        'timing-function': 'ease-out',
        duration: 0.2,
        delay: 0,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'svx46',
        },
      },
      scopedTransformations: {
        'variants-kgujn6rv': {
          type: 'TransformData',
          hidden: true,
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'svx46',
          },
        },
      },
      style: 'txtNew',
      mobileStructure: {
        layout: {
          width: 183,
          height: 49,
          x: 41,
          y: 87,
          scale: 1.3125,
          rotationInDegrees: 0,
          fixedPosition: false,
        },
        props: {
          type: 'WRichTextProperties',
          metaData: {
            schemaVersion: '1.0',
            autoGenerated: false,
            pageId: 'svx46',
          },
          brightness: 1,
          packed: true,
          verticalText: false,
        },
        metaData: {
          originalCompId: 'comp-kgujn6s12',
          author: 'studio',
        },
      },
    },
    {
      type: 'Component',
      skin: 'wixui.skins.Skinless',
      layout: {
        width: 147,
        height: 44,
        x: 33,
        y: 143,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'wixui.StylableButton',
      metaData: {
        pageId: 'svx46',
      },
      parent: 'comp-kgujn6rm',
      data: {
        type: 'StylableButton',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'svx46',
        },
        label: translate('interactions_preset_circle_about_link_text'),
        svgId: '74f558_505f67cd20e24e3bb18ef62da3dc4092.svg',
      },
      props: {
        type: 'StylableButtonProperties',
        metaData: {
          schemaVersion: '1.0',
          pageId: 'svx46',
        },
      },
      transitions: {
        type: 'TransitionData',
        'timing-function': 'ease-in-out',
        duration: 0.4,
        delay: 0,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'svx46',
        },
      },
      scopedTransformations: {
        'variants-kgujn6rv': {
          type: 'TransformData',
          rotate: 0,
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'svx46',
          },
          translate: {
            x: {
              type: 'px',
              value: 0,
            },
            y: {
              type: 'px',
              value: 12,
            },
          },
        },
      },
      style: {
        type: 'ComponentStyle',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'svx46',
        },
        style: {
          properties: {
            '$st-css':
              ":import{-st-from: 'wix-ui-santa/index.st.css';-st-named: StylableButton}.root{-st-extends: StylableButton;transition: all 0.2s ease, visibility 0s;background: rgba(255,255,255,0);border: 1px solid rgb(0, 0, 0);border-left: 0px solid rgb(0, 0, 0);border-right: 0px solid rgb(0, 0, 0);border-bottom: 0px solid rgb(0, 0, 0);border-top: 0px solid rgb(0, 0, 0)}.root:hover{\n    background: rgba(0,0,0,0);\n    border: 0px solid rgb(0, 0, 0)}.root[disabled]{background: #E2E2E2}.root[disabled]::label{color: #8F8F8F}.root[disabled]::icon{fill: #8F8F8F}.root::container{transition: inherit}.root::label{transition: inherit;font-size: 14px;color: #000000;font-family: futura-lt-w01-book,sans-serif;margin: 0px 6px 0px 0px}.root::icon{transition: inherit;fill: #000000;width: 10px;height: 10px;margin: 0px 0px 0px 6px;display: none}.root:hover::label{\n    color: #000000}",
          },
          propertiesSource: {
            '$st-css': 'value',
          },
        },
        componentClassName: 'wixui.StylableButton',
        pageId: '',
        compId: '',
        styleType: 'custom',
        skin: 'wixui.skins.Skinless',
      },
      mobileStructure: {
        layout: {
          width: 147,
          height: 44,
          x: 52,
          y: 166,
          scale: 1,
          rotationInDegrees: 0,
          fixedPosition: false,
        },
        metaData: {
          originalCompId: 'comp-kgujn6s33',
          author: 'studio',
        },
      },
    },
    {
      type: 'Component',
      skin: 'skins.viewer.VectorImageSkin',
      layout: {
        width: 99,
        height: 125,
        x: 56,
        y: 26,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.VectorImage',
      metaData: {
        pageId: 'svx46',
      },
      parent: 'comp-kgujn6rm',
      data: {
        type: 'VectorImage',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'svx46',
        },
        alt: '',
        svgId: '11062b_fbea04eec7fa432c88dd3c7b041dc684.svg',
        title: 'Girl  ',
      },
      props: {
        type: 'VectorImageProperties',
        metaData: {
          schemaVersion: '1.0',
          autoGenerated: false,
          pageId: 'svx46',
        },
        displayMode: 'fit',
        flip: 'none',
      },
      design: {
        type: 'VectorImageDesignData',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'svx46',
        },
        shapeStyle: {
          opacity: 1,
        },
      },
      transitions: {
        type: 'TransitionData',
        'timing-function': 'ease-in-out',
        duration: 0.4,
        delay: 0,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'svx46',
        },
      },
      transformations: {
        type: 'TransformData',
        hidden: true,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'svx46',
        },
      },
      scopedTransformations: {
        'variants-kgujn6rv': {
          type: 'TransformData',
          hidden: false,
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'svx46',
          },
          rotate: 0,
        },
      },
      style: {
        type: 'ComponentStyle',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'svx46',
        },
        style: {
          properties: {},
          propertiesSource: {},
          groups: {},
        },
        componentClassName: 'wysiwyg.viewer.components.VectorImage',
        pageId: '',
        compId: '',
        styleType: 'custom',
        skin: 'skins.viewer.VectorImageSkin',
      },
      mobileStructure: {
        layout: {
          width: 70,
          height: 88,
          x: 87,
          y: 26,
          scale: 1,
          rotationInDegrees: 0,
          fixedPosition: false,
        },
        metaData: {
          originalCompId: 'comp-kgujn6sa',
          author: 'studio',
        },
      },
    },
  ],
  skin: 'wysiwyg.viewer.skins.area.CircleArea',
  layout: {
    width: 213,
    height: 220,
    x: 383,
    y: 140,
    scale: 1,
    rotationInDegrees: 0,
    fixedPosition: false,
  },
  componentType: 'mobile.core.components.Container',
  metaData: {
    pageId: 'svx46',
  },
  parent: 'svx46',
  variants: {
    'variants-kgujn6rv': {
      componentId: 'comp-kgujn6rm',
      type: 'Hover',
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        pageId: 'svx46',
      },
    },
  },
  scopedTransformations: {
    'variants-kgujn6rv': {
      type: 'TransformData',
      zLayer: 'BELOW_PINNED',
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        pageId: 'svx46',
      },
      rotate: 0,
    },
  },
  style: {
    type: 'ComponentStyle',
    metaData: {
      isPreset: false,
      schemaVersion: '1.0',
      isHidden: false,
      pageId: 'svx46',
    },
    style: {
      properties: {
        'alpha-bg': '1',
        'alpha-brd': '1',
        bg: 'rgba(255,203,125,1)',
        'boxShadowToggleOn-shd': 'false',
        brd: '#FFDE5F',
        brw: '0',
        shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
      },
      propertiesSource: {
        'alpha-bg': 'value',
        'alpha-brd': 'value',
        bg: 'value',
        'boxShadowToggleOn-shd': 'value',
        brd: 'value',
        brw: 'value',
        shd: 'value',
      },
      groups: {},
    },
    componentClassName: 'mobile.core.components.Container',
    pageId: '',
    compId: '',
    styleType: 'custom',
    skin: 'wysiwyg.viewer.skins.area.CircleArea',
  },
  mobileStructure: {
    layout: {
      width: 251,
      height: 251,
      x: 27,
      y: 10,
      scale: 1,
      rotationInDegrees: 0,
      fixedPosition: false,
    },
    metaData: {
      originalCompId: 'comp-kgujn6rm',
      author: 'studio',
    },
  },
  activeModes: {},
};
