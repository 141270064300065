import React from 'react';
import { Tooltip } from '@wix/wix-base-ui';
import * as util from '@/util';
import { translate } from '@/i18n';

interface LanguageRowSmallProps {
  isSelected: boolean;
  flagUrl: string;
  languageName: string;
  isPrimary: boolean;
  published: boolean;
  onSelect(): void;
}

const LanguageRowSmall: React.FC<LanguageRowSmallProps> = ({
  isSelected,
  flagUrl,
  languageName,
  isPrimary,
  published,
  onSelect,
}) => {
  let subtitle: string;
  if (isPrimary) {
    subtitle = 'SiteLanguages_Panel_Label_MainLanguage';
  } else {
    subtitle = published
      ? 'SiteLanguages_Panel_Label_Visible'
      : 'SiteLanguages_Panel_Label_Hidden';
  }

  return (
    <div
      onClick={onSelect}
      className={util.cx('languageRow', 'languageRowSmall', {
        languageRowSelected: isSelected,
      })}
    >
      <div className="languageRowFlag">
        <div className="flag-icon">
          <img src={flagUrl} />
        </div>
      </div>

      <Tooltip
        content={translate('Multilingual_Mode_LangMenu_Activate_Tooltip', {
          Lang_Name: languageName,
        })}
        shouldTranslate={false}
        disabled={published || isPrimary}
      >
        <div className="languageRowTextContainer">
          <div>{languageName}</div>
          <div className="languageRowSubtitle">{translate(subtitle)}</div>
        </div>
      </Tooltip>
    </div>
  );
};

export default LanguageRowSmall;
