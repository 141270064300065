import _ from 'lodash';

import { dispatchEditorAPI } from '../../../topBarComponent/topBarMapper';
import type {
  MapDispatchToPropsFunction,
  MapStateToProps,
  ThunkAction,
} from 'types/redux';
import type {
  CodeMenuAPIOwnProps,
  CodeMenuAPIStateProps,
  CodeMenuAPIDispatchProps,
} from './codeMenuAPI';
import * as util from '@/util';
import * as wixData from '@/wixData';
import * as coreBi from '@/coreBi';
import type { EditorAPI } from '@/editorAPI';
import constants from '@/constants';

const mapStateToProps: MapStateToProps<
  CodeMenuAPIStateProps,
  CodeMenuAPIOwnProps
> = ({ editorAPI }) => {
  const isFirstSave = editorAPI.dsRead?.generalInfo.isFirstSave();
  const isDraftMode = editorAPI.dsRead?.generalInfo.isDraft();
  return {
    isFirstSave,
    isDraftMode,
  };
};

const getEditorAPI: ThunkAction<EditorAPI> = (
  dispatch,
  getState,
  { editorAPI },
) => editorAPI;

const mapDispatchToProps: MapDispatchToPropsFunction<
  CodeMenuAPIDispatchProps,
  CodeMenuAPIOwnProps
> = (dispatch) => ({
  enableDeveloperMode: dispatchEditorAPI(dispatch, (editorAPI, options) => {
    editorAPI.bi.event(coreBi.events.topbar.top_bar_CODE_menu_click, {
      category: 'dev_mode',
      origin: 'manual',
      status: !editorAPI.developerMode.isEnabled(),
      is_data_on: wixData.isDataModeOn(editorAPI),
      is_first: !_.invoke(editorAPI, 'dsRead.generalInfo.isFirstSave') || false,
    });
    util.fedopsLogger.interactionStarted(
      util.fedopsLogger.INTERACTIONS.CODE_TOP_BAR_TOGGLE,
    );
    editorAPI.developerMode.toggle(options);
  }),
  addACodeSnippet: () => {
    const editorAPI = dispatch(getEditorAPI);
    const baseDomain = util.serviceTopology.businessManagerUrl;
    const metaSiteId = editorAPI.dsRead.generalInfo.getMetaSiteId();
    const tab = 'embeds';
    const path = `${baseDomain}/${metaSiteId}/manage-website/${tab}?referralInfo=settings-lobby`;
    window.open(path);
  },
  embedHTML: dispatchEditorAPI(dispatch, (editorAPI) =>
    editorAPI.panelManager.openPanel(
      constants.ROOT_COMPS.LEFTBAR.NEW_ADD_PANEL_NAME,
      {
        category: 'embeds',
        origin: 'topBarCodeMenuEmbedHTML',
      },
    ),
  ),
});

export { mapStateToProps, mapDispatchToProps };
