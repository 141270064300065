// @ts-nocheck
import ReactDOM from 'reactDOM';
import _ from 'lodash';
import $ from 'zepto';
import createReactClass from 'create-react-class';
import * as stateManagement from '@/stateManagement';
import * as core from '@/core';
import * as panelUtils from '@/panelUtils';
import * as controls from '../lib/controls';
import consts from '../lib/consts';
import * as util from '@/util';
import { translate } from '@/i18n';

import React from 'react';
import * as baseUI from '@/baseUI';
import {
  Composites,
  CustomScroll,
  Divider,
  ToggleSwitch,
} from '@wix/wix-base-ui';

const { getViewPort } = stateManagement.domMeasurements.selectors;
const typeTabsData = [
  {
    value: 'cssBorder',
    symbolName: 'borderDesign',
    text: translate('CustomDesign_TabLabel_Borders'),
  },
  {
    value: 'cssBorderRadius',
    symbolName: 'cornersDesign',
    text: translate('CustomDesign_TabLabel_Corners'),
  },
  {
    value: 'cssBoxShadow',
    symbolName: 'shadowDesign',
    text: translate('CustomDesign_TabLabel_Shadow'),
  },
];

const { designDataExtensionDefualts } = consts;

// eslint-disable-next-line react/prefer-es6-class
const DesignDataPanel = createReactClass({
  displayName: 'customize-design-data-panel',
  mixins: [core.mixins.editorAPIMixin, panelUtils.linkColorPickerMixin],

  getInitialState() {
    controls.setThemeAPI(this.getEditorAPI().theme);
    const editableDesignData = this.getEditableData();
    return {
      editableDesignData,
      selectedCategory: this.props.selectedCategory,
      controlsClosed: !this.props.selectedCategory,
    };
  },

  UNSAFE_componentWillMount() {
    const listenerId =
      this.getEditorAPI().dsActions.theme.events.onChange.addListener(
        function () {
          this.updateEditableDataState();
        }.bind(this),
      );
    this.setState({ themeChangeListenerId: listenerId });
    this.initCategoriesTogglableState();
  },

  UNSAFE_componentWillReceiveProps() {
    this.updateDesignOnModeChange();
  },

  componentWillUnmount() {
    this.getEditorAPI().theme.events.onChange.removeListener(
      this.state.themeChangeListenerId,
    );
  },

  updateDesignOnModeChange() {
    const compPointer = this.props.selectedComponent[0];
    const compModes =
      this.getEditorAPI().components.modes.getComponentActiveModeIds(
        compPointer,
      );
    if (!_.isEqual(this.state.compModes, compModes)) {
      this.setState({ compModes });
      this.initCategoriesTogglableState();
      this.updateEditableDataState();
    }
  },

  updateEditableDataState() {
    this.setState({ editableDesignData: this.getEditableData() });
  },

  initCategoriesTogglableState() {
    const designData = this.getDesignData();
    const state = {
      disabledCategoriesData: {
        cssStyle: {},
        panelExtensions: designDataExtensionDefualts,
      },
      categoryEnableStates: {},
    };
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
    _.forEach(consts.togglableCategories, function (category) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/is-nil
      const enabled = !_.isNil(designData.cssStyle[category]);
      state.categoryEnableStates[category] = enabled;
      if (!enabled) {
        state.disabledCategoriesData.cssStyle[category] = _.cloneDeep(
          consts.categoryDefaults[category],
        );
      }
    });
    this.setState(state);
  },

  getEditableData() {
    const designData = this.getDesignData();
    const editableData = {};
    editableData.cssStyle = this.getComponentCssStyle();
    editableData.themeMappings = designData.themeMappings;
    editableData.panelExtensions = this.getDesignDataExtensions();
    return editableData;
  },

  getComponentCssStyle() {
    const designData = this.getDesignData();
    const cssStyleData = {};
    const isTogglable = this.isCategoryTogglable;
    const cssStylesCategories = _.difference(
      consts.editableTypes,
      this.props.disabledCategories,
    );

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
    _.forEach(cssStylesCategories, function (category) {
      const value = designData.cssStyle[category];
      if (isTogglable(category) || value) {
        cssStyleData[category] = value;
      } else {
        cssStyleData[category] = consts.categoryDefaults[category];
      }
    });
    return cssStyleData;
  },

  getDesignDataExtensions() {
    if (this.state && this.state.designDataExtensions) {
      return this.state.designDataExtensions;
    }
    return designDataExtensionDefualts;
  },

  getDataManipulators(controlKey) {
    const category = this.state.selectedCategory;
    let data;
    if (this.isCategoryDisabled()) {
      data = this.state.disabledCategoriesData;
    } else {
      data = this.state.editableDesignData;
    }
    return controls.getDataManipulators(data, category, controlKey);
  },

  updateComponentDesignData(newDesignData) {
    this.getEditorAPI().components.design.update(
      this.props.selectedComponent,
      newDesignData,
    );
  },

  updateDesignDataExtension(extensionsData) {
    this.setState({ designDataExtensions: extensionsData });
  },

  getValueLink(controlKey) {
    const dataManipulatorFuncs = this.getDataManipulators(controlKey);
    return {
      value: dataManipulatorFuncs.getLinkableValue(),
      requestChange: function (newValue) {
        const newDesignData = dataManipulatorFuncs.linkedValueToData(newValue);
        this.setState({ editableDesignData: newDesignData });
        this.updateComponentDesignData(newDesignData);
        this.updateDesignDataExtension(newDesignData.panelExtensions);
      }.bind(this),
    };
  },

  getRequiredProps(controlDescriptor) {
    const props = _.clone(controlDescriptor.props);
    if (controlDescriptor.controlType === 'colorPickerInputWithOpacity') {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      _.assign(props, {
        openColorPicker: this.openColorPicker,
        colorResolver: this.resolveColor,
        isSmallStepper: true,
      });
    }
    return props;
  },

  linkSelectedCategory() {
    return {
      value: this.state.selectedCategory,
      requestChange: function (newCategory) {
        if (this.state.selectedCategory !== newCategory) {
          this.setState({
            selectedCategory: newCategory,
            controlsClosed: false,
          });
        }
      }.bind(this),
    };
  },

  getCategoriesRadioValues() {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/keys
    const editableDesignDataTypes = _.keys(
      this.state.editableDesignData.cssStyle,
    );
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/reduce
    return _.reduce(
      typeTabsData,
      function (result, typeTabData) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/includes
        if (_.includes(editableDesignDataTypes, typeTabData.value)) {
          result.push(typeTabData);
        }
        return result;
      },
      [],
    );
  },

  getHeight() {
    const editorAPI = this.getEditorAPI();
    const viewPortHeight = getViewPort(editorAPI.store.getState()).height;
    if (viewPortHeight < consts.MIN_VIEW_PORT_HEIGHT) {
      return consts.HEIGHT_SMALL;
    }
    return consts.HEIGHT_BIG;
  },

  getDesignData() {
    return this.getEditorAPI().components.design.get(
      this.props.selectedComponent,
    );
  },

  categoryChanged() {
    this.scrollContainerToTop();
  },

  scrollContainerToTop() {
    const node = ReactDOM.findDOMNode(this);
    $(node).find('.inner-container').scrollTop(0);
  },

  getCategoryClassName() {
    return `category-${this.state.selectedCategory}`;
  },

  getSelectedCategoryTranslatedText() {
    const { selectedCategory } = this.state;
    return selectedCategory
      ? // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/find
        _.find(typeTabsData, { value: selectedCategory }).text
      : '';
  },

  getSelectedCategoryControls() {
    return controls.categoryControlsMap[this.state.selectedCategory];
  },

  isLastControlInCategory(index) {
    return index === this.getSelectedCategoryControls().length - 1;
  },

  isCategoryDisabled() {
    return this.isCategoryTogglable() && !this.isCategoryEnabled();
  },

  isCategoryTogglable(category) {
    category = category || this.state.selectedCategory;
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/includes
    return _.includes(consts.togglableCategories, category);
  },

  getCategoryToggleLable() {
    return consts.categoryToggleLabels[this.state.selectedCategory];
  },

  isCategoryEnabled() {
    return this.state.categoryEnableStates[this.state.selectedCategory];
  },

  toggleCategory() {
    const state = {};
    const category = this.state.selectedCategory;
    const enabled = !this.isCategoryEnabled();
    const editableDesignData = _.cloneDeep(this.state.editableDesignData);
    state.categoryEnableStates = _.cloneDeep(this.state.categoryEnableStates);
    if (enabled) {
      const disabledData = this.state.disabledCategoriesData.cssStyle[category];
      editableDesignData.cssStyle[category] = disabledData;
    } else {
      state.disabledCategoriesData = _.cloneDeep(
        this.state.disabledCategoriesData,
      );
      state.disabledCategoriesData.cssStyle[category] =
        editableDesignData.cssStyle[category];
      editableDesignData.cssStyle[category] = null;
    }
    state.editableDesignData = editableDesignData;
    this.updateComponentDesignData(editableDesignData);
    state.categoryEnableStates[this.state.selectedCategory] = enabled;
    this.setState(state);
  },

  onLoaded: _.noop,
  render() {
    return (
      <div
        style={{
          height: this.getHeight(),
        }}
        className="design-data-panel flex-column"
      >
        <div className="design-data-panel-body flex-column flex-child">
          <div key="paramEditBox" className="content-container flex-child">
            <baseUI.verticalTabs
              valueLink={this.linkSelectedCategory()}
              tabClicked={this.categoryChanged}
              options={this.getCategoriesRadioValues()}
              closed={this.state.controlsClosed}
            />
            {!this.state.controlsClosed ? (
              <div
                key="content"
                data-aid={this.state.selectedCategory}
                className={`content ${this.getCategoryClassName()}`}
              >
                <CustomScroll heightRelativeToParent="100%">
                  <div className="scrolled-content">
                    <span className="tab-text">
                      {this.getSelectedCategoryTranslatedText()}
                    </span>
                    <div className="section">
                      {this.isCategoryTogglable() ? (
                        <div key="categoryToggle">
                          <Composites.ToggleSwitch>
                            <ToggleSwitch
                              value={this.isCategoryEnabled()}
                              label={this.getCategoryToggleLable()}
                              onChange={this.toggleCategory}
                            />
                          </Composites.ToggleSwitch>
                          <Divider long={false} key="divider" />
                        </div>
                      ) : null}

                      {!this.isCategoryTogglable() || this.isCategoryEnabled()
                        ? this.getSelectedCategoryControls().map(
                            (control, controlIndex) => {
                              const ControlType = baseUI[control.controlType];
                              const valueLink = this.getValueLink(control.key);
                              const automationId = `${this.state.selectedCategory}-${control.key}`;

                              return (
                                <div key={control.key}>
                                  <ControlType
                                    automationId={automationId}
                                    value={valueLink.value}
                                    valueLink={valueLink}
                                    disabled={this.isCategoryDisabled()}
                                    {...this.getRequiredProps(control)}
                                  />
                                  {!this.isLastControlInCategory(
                                    controlIndex,
                                  ) ? (
                                    <Divider long={false} key="divider" />
                                  ) : null}
                                </div>
                              );
                            },
                          )
                        : null}
                    </div>
                  </div>
                </CustomScroll>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  },
});

export default util.hoc.connect(
  util.hoc.STORES.STATE_ONLY,
  _.constant({}),
  undefined,
  undefined,
  false,
)(DesignDataPanel);
