import React from 'react';
import * as symbols from '@wix/santa-editor-symbols';
import { Composites, Thumbnails, PanelHeader } from '@wix/wix-base-ui';
import * as util from '@/util';
import { PAGE_CATEGORIES, NewWorkspaceContentHeader } from '../../utils';

const createIllustration = (symbolName: AnyFixMe) =>
  React.createElement(symbols.symbol, { name: symbolName });

const transitions = [
  {
    value: 'none',
    label: 'Pages_Menu_Transitions_None',
    illustration: createIllustration('transition-none'),
  },
  {
    value: 'swipeHorizontalFullScreen',
    label: 'Pages_Menu_Transitions_SwipeH_Selected',
    illustration: createIllustration('transition-pages-horizontal'),
  },
  {
    value: 'swipeVerticalFullScreen',
    label: 'Pages_Menu_Transitions_SwipeV_Popup',
    illustration: createIllustration('transition-pages-vertical'),
  },
  {
    value: 'crossfade',
    label: 'Pages_Menu_Transitions_CrossFade',
    illustration: createIllustration('transition-pages-crossfade'),
  },
  {
    value: 'outIn',
    label: 'Pages_Menu_Transitions_OutIn',
    illustration: createIllustration('transition-pages-inout'),
  },
];

interface TransitionsViewProps {
  onClickTransition: FunctionFixMe;
  title: string;
  onClose: FunctionFixMe;
  onHelp: FunctionFixMe;
  selectedTransition: string;
}

class TransitionsView extends React.Component<TransitionsViewProps> {
  readonly isNewWorkspace = util.workspace.isNewWorkspaceEnabled();
  getTransitions = () => transitions;

  renderOldPanelHeader() {
    return (
      <PanelHeader
        // @ts-expect-error
        onClose={this.props.onClose}
        onHelp={() => {
          this.props.onHelp(PAGE_CATEGORIES.TRANSITIONS.helpId);
        }}
        className="header light"
      >
        <span>{this.props.title}</span>
      </PanelHeader>
    );
  }

  renderNewPanelHeader = () => {
    return (
      <NewWorkspaceContentHeader
        title={this.props.title}
        showAddOptions={false}
      />
    );
  };

  render() {
    return (
      <div className="transitions-view-pp">
        {this.isNewWorkspace
          ? this.renderNewPanelHeader()
          : this.renderOldPanelHeader()}
        <Composites.Thumbnails>
          <Thumbnails
            value={this.props.selectedTransition}
            onChange={this.props.onClickTransition}
            maxThumbsPerRow={3}
            options={this.getTransitions()}
          />
        </Composites.Thumbnails>
      </div>
    );
  }
}

export default TransitionsView;
