export function createSchoolModeApi() {
  const openDashboard = () => {
    window.open(`https://wix.com/education`, '_blank');
  };

  return {
    openDashboard,
  };
}

export type SchoolModeApi = ReturnType<typeof createSchoolModeApi>;
