'use strict'

module.exports = {
    'header': {
        'id': 'site_header_17',
        'structure': {
            'type': 'Container',
            'components': [],
            'skin': 'wysiwyg.viewer.skins.screenwidthcontainer.IronScreen',
            'layout': {
                'width': 980,
                'height': 180,
                'x': 0,
                'y': 0,
                'scale': 1,
                'rotationInDegrees': 0,
                'anchors': [{
                    'distance': 0,
                    'topToTop': 180,
                    'originalValue': 180,
                    'type': 'BOTTOM_TOP',
                    'locked': true,
                    'targetComponent': 'PAGES_CONTAINER'
                }],
                'fixedPosition': false
            },
            'componentType': 'wysiwyg.viewer.components.HeaderContainer',
            'style': {
                'type': 'TopLevelStyle',
                'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                'style': {
                    'properties': {
                        'alpha-bg': '1',
                        'bg': '#FFFFFF',
                        'boxShadowToggleOn-shd': 'false',
                        'shd': '0px 0px 5px 0px rgba(0,0,0,0.7)'
                    }, 'propertiesSource': {'bg': 'value', 'shd': 'value'}, 'groups': {}
                },
                'componentClassName': 'wysiwyg.viewer.components.HeaderContainer',
                'pageId': '',
                'compId': 'SITE_HEADER',
                'styleType': 'custom',
                'skin': 'wysiwyg.viewer.skins.screenwidthcontainer.IronScreen'
            }
        },
        'preset': {'rect': {'width': 324, 'height': 90, 'x': 0, 'y': 1440}}
    },
    'footer': {
        'id': 'site_footer_17',
        'structure': {
            'type': 'Container',
            'components': [],
            'skin': 'wysiwyg.viewer.skins.screenwidthcontainer.IronScreen',
            'layout': {
                'width': 980,
                'height': 180,
                'x': 0,
                'y': 681,
                'scale': 1,
                'rotationInDegrees': 0,
                'anchors': [{
                    'distance': 0,
                    'topToTop': 681,
                    'originalValue': 0,
                    'type': 'BOTTOM_PARENT',
                    'locked': true,
                    'targetComponent': 'masterPage'
                }],
                'fixedPosition': false
            },
            'componentType': 'wysiwyg.viewer.components.FooterContainer',
            'style': {
                'type': 'TopLevelStyle',
                'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                'style': {
                    'properties': {
                        'alpha-bg': '1',
                        'bg': '#FFFFFF',
                        'boxShadowToggleOn-shd': 'false',
                        'shd': '0px 0px 5px 0px rgba(0,0,0,0.7)'
                    }, 'propertiesSource': {'bg': 'value', 'shd': 'value'}, 'groups': {}
                },
                'componentClassName': 'wysiwyg.viewer.components.FooterContainer',
                'pageId': '',
                'compId': 'SITE_FOOTER',
                'styleType': 'custom',
                'skin': 'wysiwyg.viewer.skins.screenwidthcontainer.IronScreen'
            }
        },
        'preset': {'rect': {'width': 324, 'height': 90, 'x': 0, 'y': 1440}}
    },
    'index': 17
}
