import _ from 'lodash';

/**
 * Please ctrl/cmd + click on biEvents to see the schema :)
 * @type {Object.<String, biEvent>}
 */
const events = {
  ASCEND_PANEL_VIEW: {
    evid: 3001,
    src: 38,
    endpoint: 'ascend-panel',
    fields: {
      ascend_package: 'string',
      is_premium: 'bool',
      is_publish: 'bool',
      msid: 'guid',
    },
  },
  INTRO_VIEW: {
    evid: 3002,
    src: 38,
    endpoint: 'ascend-panel',
    fields: {
      ascend_package: 'string',
      is_premium: 'bool',
      is_publish: 'bool',
      msid: 'guid',
    },
  },
  INTRO_CTA_CLICK: {
    evid: 3003,
    src: 38,
    endpoint: 'ascend-panel',
    fields: {
      ascend_package: 'string',
      is_premium: 'bool',
      is_publish: 'bool',
      msid: 'guid',
    },
  },
  TAB_VIEW: {
    evid: 3004,
    src: 38,
    endpoint: 'ascend-panel',
    fields: {
      ascend_package: 'string',
      is_premium: 'bool',
      is_publish: 'bool',
      msid: 'guid',
      tab_name: 'string',
    },
  },
  PRODUCT_CLICK: {
    evid: 3005,
    src: 38,
    endpoint: 'ascend-panel',
    fields: {
      ascend_package: 'string',
      is_premium: 'bool',
      is_publish: 'bool',
      msid: 'guid',
      tab_name: 'string',
      product_name: 'string',
    },
  },
  ASCEND_UPGRADE_CLICK: {
    evid: 3006,
    src: 38,
    endpoint: 'ascend-panel',
    fields: {
      ascend_package: 'string',
      is_premium: 'bool',
      is_publish: 'bool',
      msid: 'guid',
      tab_name: 'string',
    },
  },
  ASCEND_HOVER_VIDEO_ICON: {
    evid: 3007,
    src: 38,
    endpoint: 'ascend-panel',
    fields: {
      ascend_package: 'string',
      is_premium: 'bool',
      is_publish: 'bool',
      msid: 'guid',
      tab_name: 'string',
    },
  },
  ASCEND_PLAY_HELP_VIDEO: {
    evid: 3008,
    src: 38,
    endpoint: 'ascend-panel',
    fields: {
      ascend_package: 'string',
      is_premium: 'bool',
      is_publish: 'bool',
      msid: 'guid',
      tab_name: 'string',
    },
  },
} as const;

export default {
  ascendEvents: events,
  registerEvents: _.noop,
};
