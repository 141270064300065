import React, { type ComponentType } from 'react';
import _ from 'lodash';

import constants from '@/addPanelDataConsts';
import * as tpa from '@/tpa';
import * as util from '@/util';

import {
  mapDispatchToProps,
  mapStateToProps,
} from './membersWelcomeSectionMapper';
import type {
  MembersWelcomeDispatchProps,
  MembersWelcomeOwnProps,
  MembersWelcomeStateProps,
} from './types';

class MembersWelcomeSection extends React.Component<
  MembersWelcomeOwnProps &
    MembersWelcomeStateProps &
    MembersWelcomeDispatchProps
> {
  private isNewWorkspace: boolean = util.workspace.isNewWorkspaceEnabled();

  getMainAction = () => {
    if (_.isFunction(this.props.installAppOverride)) {
      return _.partial(
        this.props.installAppOverride,
        tpa.superApps.superAppsConstants.MEMBERS.APP_DEF_ID,
        constants.APP_TYPE.PLATFORM,
      );
    }
    return this.props.installMembersApp;
  };

  getBannerImgSrc() {
    let { bannerImage } = this.props;

    if (!bannerImage) {
      if (this.isNewWorkspace) {
        bannerImage = util.browserUtil.isRetina()
          ? 'addPanelData/sections/membersWelcomeSection_en/newWorkspace_membersWelcomeSection_en@x2.png'
          : 'addPanelData/sections/membersWelcomeSection_en/newWorkspace_membersWelcomeSection_en.png';
      } else {
        bannerImage =
          'addPanelData/sections/membersWelcomeSection_en/membersWelcomeSection_en.png';
      }
    }

    return util.media.getMediaUrl(bannerImage);
  }

  render() {
    const WelcomeSection = this.props.welcomeSectionTemplate;

    return (
      <div>
        <WelcomeSection
          bannerImgSrc={this.getBannerImgSrc()}
          title="SiteApp_MemberArea_PromotionalFTUE_Step1_Title"
          subtitle="SiteApp_MemberArea_PromotionalFTUE_Step1_SubTitle"
          promotionalListItems={[
            'SiteApp_MemberArea_PromotionalFTUE_Step1_Bullet1',
            'SiteApp_MemberArea_PromotionalFTUE_Step1_Bullet2',
            'SiteApp_MemberArea_PromotionalFTUE_Step1_Bullet4',
          ]}
          mainActionLabel="tpa_add_panel_add_to_site"
          secondaryActionLabel="SiteApp_MemberArea_PromotionalFTUE_Step1_SeeInAppMarket_LinkCaption"
          onMainActionClick={this.getMainAction()}
          onSecondaryActionClick={this.props.openMembersHelpSection}
        />
      </div>
    );
  }
}

export default util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
  undefined,
  false,
)(
  util.hoc.renderWhenMutated(MembersWelcomeSection),
) as ComponentType<MembersWelcomeOwnProps>;
