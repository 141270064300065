// @ts-nocheck
import createReactClass from 'create-react-class';
import _ from 'lodash';
import * as core from '@/core';
import * as higherOrderComponents from '@/higherOrderComponents';
import PropTypes from 'prop-types';

import React from 'react';
import DesignPanel from '../core/designPanel';
import * as baseUI from '@/baseUI';
import * as advancedStylePanel from '@/advancedStylePanel';

// eslint-disable-next-line react/prefer-es6-class
const ChangeDataCustomizeDesignPanel = createReactClass({
  displayName: 'changeDataCustomizeDesignPanel',
  mixins: [core.mixins.editorAPIMixin],
  render() {
    return (
      <DesignPanel
        panelName={this.props.panelName}
        {...this.getDesignPanelProps()}
      >
        {this.shouldLinkToAdvancedStyling() ? (
          <div key="customizeDesignBtn" className="button-wrapper">
            <baseUI.button
              label="design_customize_button"
              onClick={this.props.openCustomizeDesign}
              icon="customize_design_drop_light"
              className="button"
            />
          </div>
        ) : null}

        {this.props.advancedStyling ? (
          <div key="advancedStylePanel">
            <advancedStylePanel.panel {...this.props.advancedStylingProps} />
          </div>
        ) : null}
      </DesignPanel>
    );
  },
  propTypes: {
    selectedComponent: PropTypes.oneOfType([
      PropTypes.object.isRequired,
      PropTypes.array.isRequired,
    ]),
    advancedStyling: PropTypes.bool,
    propsForDesignPanel: PropTypes.object,
  },
  getInitialState() {
    return {
      currentDataItem: this.getEditorAPI().components.data.get(
        this.props.selectedComponent,
      ),
    };
  },

  changePermanentState(compDef) {
    if (compDef.data) {
      const updateFn = this.getEditorAPI().components.data.update;

      if (this.props.multiSelectedComponents) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
        _.forEach(this.props.multiSelectedComponents, (compRef) => {
          updateFn(compRef, compDef.data);
        });
        this.setState({ currentDataItem: compDef.data });
        return;
      }
      updateFn(this.props.selectedComponent, compDef.data);
      this.setState({ currentDataItem: compDef.data });
    }
  },

  changeTempState(compDef) {
    if (compDef.data) {
      const updateFn = this.getEditorAPI().components.data.update;
      if (this.props.multiSelectedComponents) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
        _.forEach(this.props.multiSelectedComponents, (compRef) => {
          updateFn(compRef, compDef.data);
        });
        return;
      }
      updateFn(this.props.selectedComponent, compDef.data);
    }
  },

  maintainOriginalState() {
    if (this.state.currentDataItem) {
      this.getEditorAPI().components.data.update(
        this.props.selectedComponent,
        this.state.currentDataItem,
      );
    }
  },

  isSelectedItem(compDef) {
    return compDef.data.svgId === this.state.currentDataItem.svgId;
  },

  shouldLinkToAdvancedStyling() {
    return !this.props.advancedStyling;
  },

  getDesignPanelProps() {
    return _.defaults(
      {
        changePermanentState: this.changePermanentState,
        changeTempState: this.changeTempState,
        maintainOriginalState: this.maintainOriginalState,
        isSelectedItem: this.isSelectedItem,
      },
      this.props.propsForDesignPanel,
    );
  },
});

export default higherOrderComponents.designPanel(
  ChangeDataCustomizeDesignPanel,
);
