// @ts-nocheck
import * as addPanel from '@/oldAddPanel';
import section from './live/section2';

export default {
  getCategorySections() {
    const addPanelTextSection = addPanel.sections.getSections().text;
    if (addPanelTextSection.textMaskSection) {
      addPanelTextSection.textMaskSection =
        addPanel.dataUtil.mergeSectionVideos(
          addPanelTextSection.textMaskSection,
          addPanelTextSection.textMaskSectionVideos,
        );
    }

    return addPanel.dataUtil.mergeWithCommonSections(addPanelTextSection, {
      section_1: section,
    });
  },
};
