// @ts-nocheck
import _ from 'lodash';
import React from 'react';
import constants from '@/constants';
import OverlayWithHoleClass from './OverlayWithHole';
import OverlayWithQuickTipClass from './OverlayWithQuickTip';
import {
  environment,
  editorDataFunction,
  componentInstanceData,
} from '@wix/editor-component-data-resolver';

function getQuickTipProps(editorAPI) {
  return {
    delayBeforeShow: 500,
    delayBeforeNext: 1500,
    quickTipSession: {
      id: 'popupOverlayQuickTipShownFor-',
      maxNum: 4,
    },
    shouldNotHideOnMouseLeaveTarget: false,
    templateInfo: {
      text: 'POPUPS_OVERLAY_HOVER_TOOLTIP',
      symbol: 'idea-bulb',
    },
    propsForBubble: {
      noArrow: true,
      shake: false,
      behindPopUps: true,
      alignment: constants.UI.TOOLTIP.ALIGNMENT.RIGHT,
      closeTriggers: [constants.UI.TOOLTIP.TRIGGERS.CLICK],
      onMouseLeave: editorAPI.overlay.enableUpdate,
      onMouseEnter: editorAPI.overlay.disableUpdate,
    },
  };
}

export default {
  pinnable: false,
  shouldBeOverlayed: editorDataFunction(
    componentInstanceData('isPopup'),
    environment('isMobileEditor'),
    (isPopup, isMobileEditor) => !isMobileEditor && isPopup,
  ),
  getOverlay(editorAPI, compRef) {
    const overlayWithHole = React.createElement(OverlayWithHoleClass, {
      hole: editorAPI.pages.popupPages.getPopupContainer(),
      background: 'rgba(127, 204, 247, 0.4)',
    });

    const overlayWithQuickTip = React.createElement(
      OverlayWithQuickTipClass,
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      _.assign({ hoveredComp: compRef }, getQuickTipProps(editorAPI)),
      overlayWithHole,
    );

    return overlayWithQuickTip;
  },
  getOverlayProperties: {
    enter: {
      duration: 300,
      delay: 500,
    },
    leave: {
      duration: 300,
    },
  },
};
