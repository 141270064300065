import React, { type ReactChild } from 'react';
import {
  Composites,
  TextLabel,
  RichText,
  Illustration,
} from '@wix/wix-base-ui';
import { symbol as Symbol } from '@wix/santa-editor-symbols';
import styles from './EmptyState.scss';

export interface EmptyStateProps {
  symbolName: string;
  title: string;
  text: string;
  action?: ReactChild;
  dataHook?: string;
}

export const EmptyState: React.FC<EmptyStateProps> = ({
  symbolName,
  title,
  text,
  action,
  dataHook,
}) => {
  return (
    <div data-hook={dataHook} className={styles.container}>
      <Composites.RichTextWithIllustrationVertical>
        <Illustration>
          <Symbol name={symbolName} />
        </Illustration>
        <Composites.RichTextLabeled className={styles.text}>
          <TextLabel
            value={title}
            type="T09"
            enableEllipsis={false}
            shouldTranslate={false}
          />
          <RichText type="T07">
            <p className={styles.paragraph}>{text}</p>
            {action ? action : null}
          </RichText>
        </Composites.RichTextLabeled>
      </Composites.RichTextWithIllustrationVertical>
    </div>
  );
};
