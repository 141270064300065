export interface PublishInnerPanelType {
  name: string;
  userPref?: boolean;
}

export default {
  EMPTY: {
    name: 'EMPTY',
  },
  SHOUTOUT: {
    name: 'SHOUTOUT',
    userPref: true,
  },
  CONNECT_DOMAIN: {
    name: 'CONNECT_DOMAIN',
    userPref: true,
  },
  GROW_BUSINESS: {
    name: 'GROW_BUSINESS',
    userPref: true,
  },
} as const;
