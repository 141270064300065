import { translate } from '@/i18n';

export const getAiSectionCreatorFeedbackModel = (
  triggerEvent: string = 'ai-section-removed',
) => ({
  questionId: 'editor-aisection-remove',
  categoryId: 'editor',
  triggerEvent,
  thankYouPage: {
    title: translate('add_section_ai_section_feedback_loop_success_title'),
    subTitle: translate(
      'add_section_ai_section_feedback_loop_success_subtitle',
    ),
  },
  feedbackModel: {
    feedbackType: 'question',
    question: {
      title: translate('add_section_ai_section_feedback_loop_title'),
      subTitle: translate('add_section_ai_section_feedback_loop_subtitle'),
    },
    answer: {
      firstLevelType: 'firstlevel-multichoice',
      options: [
        {
          label: translate('add_section_ai_section_feedback_loop_negative'),
          choice: 'underdelivered',
          children: {
            type: 'free-text',
            title: translate(
              'add_section_ai_section_feedback_loop_negative_title',
            ),
            choice: 'underdelivered-text',
            submitButtonLabel: translate(
              'add_section_ai_section_feedback_loop_send_CTA',
            ),
            placeholder: translate(
              'add_section_ai_section_feedback_loop_negative_placeholder',
            ),
          },
        },
        {
          label: translate('add_section_ai_section_feedback_loop_positive'),
          choice: 'great',
          children: {
            type: 'free-text',
            title: translate(
              'add_section_ai_section_feedback_loop_positive_title',
            ),
            choice: 'great-text',
            submitButtonLabel: translate(
              'add_section_ai_section_feedback_loop_send_CTA',
            ),
            placeholder: translate(
              'add_section_ai_section_feedback_loop_positive_placeholder',
            ),
          },
        },
        {
          label: translate('add_section_ai_section_feedback_loop_neutral'),
          choice: 'missing-features',
        },
      ],
    },
  },
});
