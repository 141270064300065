import _ from 'lodash';
import { DATA_BINDING } from '@wix/app-definition-ids';
import * as util from '@/util';
import {
  PAGE_CATEGORIES as pageCategories,
  AddPageButton,
  sendNavigateToPageBiEvent,
} from '../../../utils';
import * as actions from '../../../api/actions/actions';
import * as coreBi from '@/coreBi';
import pagesView from '../pagesView';
import { isDataModeOn } from '@/wixData';
import type { EditorAPI } from '@/editorAPI';
import type { IPagesPanelViewProps } from '../../pagesPanel';
import type {
  RouterAction,
  RouterViewActions,
  MainRouterViewAction,
  PageAction,
  PageItem,
  OnSettingsChangeHandler,
} from '../../types';
import type { PagesViewProps } from '../pagesView';
import type { ThunkAction } from 'types/redux';
import type { DynamicPage } from '../../types';
import type { DynamicPagesCountProps } from './hoc/withDynamicPageCount';
import { withDynamicPageCount } from './hoc/withDynamicPageCount';
import type { RouterPageData } from '../../../api/routerPages';
import pagesAPI from '../../../api/api';
import type { IAppPage } from '@/pages';

const {
  connect,
  renderWhenMutated,
  STORES: { EDITOR_API },
} = util.hoc;
const { addPageRouterActionUtils } = util;

const symbolTypes: AnyFixMe = {};
const developerModeAppsDefIds: AnyFixMe = {};

const getCanShowViewActions = (
  editorAPI: EditorAPI,
  isDesktop: boolean,
  appDefinitionId: string,
  actions: RouterAction[],
  viewActions: RouterViewActions,
) => {
  const hasActions = Boolean(actions?.length || viewActions);

  if (!hasActions || !isDesktop) {
    return false;
  }

  const developerModeAndDataModeAreOff =
    !editorAPI.developerMode.isEnabled() && !isDataModeOn(editorAPI);

  if (
    developerModeAndDataModeAreOff &&
    developerModeAppsDefIds[appDefinitionId]
  ) {
    return false;
  }

  return true;
};

const getMainAction = (
  actions: RouterAction[],
  viewActions: RouterViewActions,
) => {
  const mainRouterViewAction = viewActions?.mainAction;

  return (
    mainRouterViewAction ||
    addPageRouterActionUtils.findAddPageAction(actions) ||
    null
  );
};

const getSecondaryAction = (viewActions: RouterViewActions) => {
  const secondaryActions = viewActions?.secondaryActions || [];
  return _.head(secondaryActions) || null;
};

const onActionClick = (
  pagesData: RouterPageData[],
  event: string,
  editorAPI: EditorAPI,
  prefix: string,
  biSource: string,
  biCategory: string = 'dynamic page',
  clickOrigin?: AddPageButton,
) => {
  editorAPI.bi.event(coreBi.events.pages.pagesPanel.add_page_selection_click, {
    origin: 'dynamicPagesView',
    category: biCategory,
    source: biSource || 'router',
    button: clickOrigin,
  });

  const pageRef = editorAPI.dsRead.pages.getReference(pagesData[0].id);
  const routerRef = editorAPI.dsRead.routers.getRouterRef.byPage(pageRef);
  const { appDefinitionId } = editorAPI.dsRead.routers.get.byRef(routerRef);
  const applicationId =
    editorAPI.dsRead.platform.getAppDataByAppDefId(appDefinitionId)
      ?.applicationId;

  return actions.getOnClickApplicationNotification(
    editorAPI,
    applicationId,
    event,
    { prefix, routerRef },
  )();
};

const onMainActionClick =
  (
    pagesData: RouterPageData[],
    actions: RouterAction[],
    viewActions: RouterViewActions,
    prefix: string,
    biSource: string,
    clickOrigin?: AddPageButton,
  ): ThunkAction =>
  (dispatch, getState, { editorAPI }) => {
    const action = getMainAction(actions, viewActions);
    const biCategory = (action as MainRouterViewAction).biActionName;

    return (
      action &&
      onActionClick(
        pagesData,
        action.event,
        editorAPI,
        prefix,
        biSource,
        biCategory,
        clickOrigin,
      )
    );
  };

const onSecondaryActionClick =
  (
    pagesData: RouterPageData[],
    viewActions: RouterViewActions,
    prefix: string,
    biSource: string,
  ): ThunkAction =>
  (dispatch, getState, { editorAPI }) => {
    const action = getSecondaryAction(viewActions);

    return (
      action &&
      onActionClick(
        pagesData,
        action.event,
        editorAPI,
        prefix,
        biSource,
        action.biActionName,
      )
    );
  };

const initSymbolTypes = _.once((editorAPI) => {
  symbolTypes[editorAPI.dsRead.platform.editorApps.SANTA_MEMBERS.appDefId] =
    'membersAction';
  symbolTypes[editorAPI.dsRead.platform.editorApps.DYNAMIC_PAGES.appDefId] =
    'routerPageType';
  symbolTypes[DATA_BINDING] = 'dynamicPageType';
});

const initAppDataThatShouldMoveToManifest = _.once((editorAPI) => {
  developerModeAppsDefIds[
    editorAPI.dsRead.platform.editorApps.DYNAMIC_PAGES.appDefId
  ] = true;
  developerModeAppsDefIds[DATA_BINDING] = true;
});

const getDynamicPages = (
  editorAPI: EditorAPI,
  pagesData: RouterPageData[],
  appDefinitionId: string,
): DynamicPage[] =>
  pagesData.map(({ id, label, permissionSymbol }) => ({
    id,
    title: label,
    permissionSymbol: permissionSymbol || null,
    typeSymbol: symbolTypes[appDefinitionId],
  }));

interface MapStateToProps
  extends DynamicPagesCountProps,
    Pick<
      PagesViewProps,
      | 'title'
      | 'renameEnabled'
      | 'titleAdd'
      | 'secondaryActionTooltipContent'
      | 'secondaryActionSymbol'
      | 'secondaryActionSymbolName'
      | 'biCategory'
      | 'onSelectPageItem'
    > {
  canShowViewActions: boolean;
  pages: DynamicPage[];
}

const mapStateToProps = (
  { editorAPI }: AnyFixMe,
  { router, isDesktop, onSelectPageItem }: IPagesPanelViewProps,
): MapStateToProps => {
  const { title, pagesData, biCategory, actions, viewActions } = router;

  initSymbolTypes(editorAPI);
  initAppDataThatShouldMoveToManifest(editorAPI);
  const pageRef = editorAPI.dsRead.pages.getReference(pagesData[0].id);
  const routerRef = editorAPI.dsRead.routers.getRouterRef.byPage(pageRef);
  const { appDefinitionId } = editorAPI.dsRead.routers.get.byRef(routerRef);

  const canShowViewActions = getCanShowViewActions(
    editorAPI,
    isDesktop,
    appDefinitionId,
    actions,
    viewActions,
  );

  const mainAction = canShowViewActions
    ? getMainAction(actions, viewActions)
    : null;

  const secondaryAction = canShowViewActions
    ? getSecondaryAction(viewActions)
    : null;

  return {
    title,
    pages: getDynamicPages(editorAPI, pagesData, appDefinitionId),
    renameEnabled: isDesktop,
    titleAdd: mainAction?.title || null,
    secondaryActionTooltipContent: secondaryAction?.tooltipContent || null,
    secondaryActionSymbol: secondaryAction?.symbol || null,
    secondaryActionSymbolName: secondaryAction?.symbolName || null,
    biCategory: biCategory || pageCategories.ROUTER_PAGES.biCategory,
    canShowViewActions,
    onSelectPageItem,
    router,
    appDefinitionId,
  };
};

const getHelpId =
  (isDesktop: AnyFixMe, pagesData: AnyFixMe, onHelp: AnyFixMe) =>
  (dispatch: AnyFixMe, getState: AnyFixMe, { editorAPI }: AnyFixMe) => {
    const pageRef = editorAPI.dsRead.pages.getReference(pagesData[0].id);
    const routerRef = editorAPI.dsRead.routers.getRouterRef.byPage(pageRef);
    const { appDefinitionId } = editorAPI.dsRead.routers.get.byRef(routerRef);

    if (appDefinitionId === DATA_BINDING) {
      return isDesktop
        ? _.partial(onHelp, pageCategories.DYNAMIC_PAGES.helpId)
        : _.partial(onHelp, pageCategories.DYNAMIC_PAGES.mobileHelpId);
    } else if (
      appDefinitionId ===
      editorAPI.dsRead.platform.editorApps.SANTA_MEMBERS.appDefId
    ) {
      return _.partial(onHelp, pageCategories.MEMBERS_PAGES.helpId);
    }

    return isDesktop
      ? _.partial(onHelp, pageCategories.ROUTER_PAGES.helpId)
      : _.partial(onHelp, pageCategories.ROUTER_PAGES.mobileHelpId);
  };

const getNavigateTo =
  (panelName: AnyFixMe) =>
  (dispatch: AnyFixMe, getState: AnyFixMe, { editorAPI }: AnyFixMe) =>
  (pageId: AnyFixMe) => {
    const focusedPageId = editorAPI.dsRead.pages.getFocusedPageId();

    if (focusedPageId !== pageId) {
      editorAPI.pages.navigateTo(pageId);
      sendNavigateToPageBiEvent(editorAPI, {
        pageId,
        panelName,
        biCategory: pageCategories.ROUTER_PAGES.biCategory,
      });
    }
  };

const sendBiEvent =
  (...args: AnyFixMe[]) =>
  (dispatch: AnyFixMe, getState: AnyFixMe, { editorAPI }: AnyFixMe) =>
    editorAPI.bi.event(...args);

type PageActionLookup = Record<string, PageAction[]>;

const getDynamicPagesActions =
  (
    pagesData: RouterPageData[],
    onShowSettings: OnSettingsChangeHandler,
    isDesktop: boolean,
    biCategory: string,
  ): ThunkAction<PageActionLookup> | RouterPageData[] =>
  (dispatch, getState, { editorAPI }) => {
    initSymbolTypes(editorAPI);
    initAppDataThatShouldMoveToManifest(editorAPI);
    return pagesAPI.routers.getActions(
      editorAPI,
      pagesData,
      onShowSettings,
      isDesktop,
      biCategory,
    );
  };

interface MapDispatchToProps
  extends Pick<
    PagesViewProps,
    | 'onHelp'
    | 'navigateTo'
    | 'biEvent'
    | 'onClickAdd'
    | 'onSecondaryActionClick'
  > {
  dynamicPagesActions: PageActionLookup;
}

const mapDispatchToProps = (
  dispatch: AnyFixMe,
  {
    router: { pagesData, biCategory, actions, viewActions, prefix },
    onShowSettings,
    isDesktop,
    onHelp,
    biPanelName,
  }: IPagesPanelViewProps,
): MapDispatchToProps => ({
  onHelp: dispatch(getHelpId(isDesktop, pagesData, onHelp)),
  navigateTo: dispatch(getNavigateTo(biPanelName)),
  biEvent: (...args: AnyFixMe[]) => dispatch(sendBiEvent(...args)),

  dynamicPagesActions: dispatch(
    getDynamicPagesActions(pagesData, onShowSettings, isDesktop, biCategory),
  ),

  onClickAdd: (clickOrigin?: AddPageButton) =>
    dispatch(
      onMainActionClick(
        pagesData,
        actions,
        viewActions,
        prefix,
        biCategory,
        clickOrigin,
      ),
    ),

  onSecondaryActionClick: () =>
    dispatch(
      onSecondaryActionClick(pagesData, viewActions, prefix, biCategory),
    ),
});

const mergeProps = (
  stateProps: MapStateToProps,
  dispatchProps: MapDispatchToProps,
  { onClose, renamedItemId, selectedPageId }: IPagesPanelViewProps,
): PagesViewProps => {
  const { pages, canShowViewActions, ...restStateProps } = stateProps;
  const {
    onClickAdd,
    onSecondaryActionClick: secondaryActionClick,
    dynamicPagesActions,
    ...restDispatchProps
  } = dispatchProps;

  return {
    onClose,
    renamedItemId,
    selectedPageId,
    ...restStateProps,
    ...restDispatchProps,
    onClickAdd: canShowViewActions ? onClickAdd : null,
    onSecondaryActionClick: canShowViewActions ? secondaryActionClick : null,
    // TODO calculate actions per page and not for all in advance
    pages: pages.map(
      (page): PageItem => ({
        ...page,
        actions: dynamicPagesActions[page.id],
      }),
    ) as IAppPage[],
  };
};

export default _.flowRight(
  connect(EDITOR_API, mapStateToProps, mapDispatchToProps, mergeProps),
  withDynamicPageCount,
  renderWhenMutated,
)(pagesView);
