._container_ho9n5_1 {
  flex: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box; }

._headerContainer_ho9n5_8 {
  position: relative;
  padding: 18px 24px; }

._sectionContainer_ho9n5_12 {
  overflow: hidden;
  position: relative; }

._setupHeaderContainer_ho9n5_16 {
  padding: 18px 24px 0; }

._setupDivider_ho9n5_19 {
  width: 100%;
  height: 12px;
  margin: 0;
  margin-top: 4px;
  background: #f7f8f8;
  border: none; }

._section_ho9n5_12 {
  padding: 18px 24px 14px; }

._sectionContent_ho9n5_30 {
  border: 1px solid #e1e3e6;
  border-radius: 4px; }
  ._sectionContent_ho9n5_30:not(:first-child) {
    margin-top: 12px; }

._successAnimation_ho9n5_36 {
  position: absolute;
  inset: 0 0 0 0; }

._fadeIn_ho9n5_40 {
  opacity: 0; }

._fadeInActive_ho9n5_43 {
  opacity: 1;
  transition: opacity 250ms; }

._fadeOut_ho9n5_47 {
  opacity: 1; }

._fadeOutActive_ho9n5_50 {
  opacity: 0;
  transition: opacity 250ms; }

._slideOutActive_ho9n5_54 {
  /* !important is needed to override the value from inline style set in onExit */
  height: 0 !important;
  transition: height 250ms; }
