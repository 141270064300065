import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { hoc } from '@/util';
import { Text, CustomScroll, Divider } from '@wix/wix-base-ui';
import { useTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';
import { BusinessAppActionItem } from '../../components/BusinessAppActionItem/BusinessAppActionItem';
import { Dividify } from '../../components/Dividify/Dividify';
import { Banner } from '../../components/Banner/Banner';
import { AppSetupStep } from '../../components/AppSetupStep/AppSetupStep';
import { BusinessAppSetupHeader } from '../../components/BusinessAppSetupHeader/BusinessAppSetupHeader';
import { BusinessAppHeader } from '../../components/BusinessAppHeader/BusinessAppHeader';
import { SetupSuccessAnimation } from '../../components/SetupSuccessAnimation/SetupSuccessAnimation';
import { RecommendedTools } from '../../components/RecommendedTools/RecommendedTools';
import { AUTOMATION_IDS, getBusinessAppDataHook } from '../../automationIds';
import {
  SETUP_SUCCESS_ANIMATION_DURATION,
  DEFAULT_TRANSITION_TIMEOUT,
} from '../../constants';
import { BI_EVENTS_IDS } from '../../biEvents';
import { usePrevious } from '../../hooks/usePrevious';

import {
  mapStateToProps,
  mapDispatchToProps,
  type BusinessAppStateProps,
  type BusinessAppDispatchProps,
} from './businessAppMapper';
import styles from './businessApp.scss';

import type { RegularAppData } from '../../services/appMarket/appMarket.types';
import type { AppSetup } from '../../services/setup/AppSetup';
import type { RecommendedTool } from '../../services/recommendedTools/RecommendedTools';
import type { AppAction } from '../../services/platform/platform.types';
import type {
  DealerAppPageAction,
  DealerAppPageData,
} from '../../services/dealer/dealer.types';

export interface BusinessAppOwnProps {
  app: RegularAppData;
  appSetup: AppSetup;
  recommendedTools: RecommendedTool[];
  dealerData?: DealerAppPageData;
}

interface BusinessAppProps
  extends BusinessAppOwnProps,
    BusinessAppStateProps,
    BusinessAppDispatchProps {}

const BusinessAppComponent: React.FC<BusinessAppProps> = ({
  app,
  appSetup,
  recommendedTools,
  appActions,
  premiumBannerInfo,
  dealerData,
  sendBi,
}) => {
  const [t] = useTranslation();
  const manageActions = useMemo(() => {
    const { primary, secondary } = appActions;
    const primaryWithOverrides = mergePrimaryActions(
      primary,
      dealerData?.primaryAction1,
      dealerData?.primaryAction2,
    );
    return primaryWithOverrides.concat(secondary);
  }, [appActions, dealerData]);

  const [isSuccessAnimationComplete, setIsSuccessAnimationComplete] = useState(
    appSetup.isComplete,
  );

  const dismissSuccessAnimation = useCallback(
    () => setIsSuccessAnimationComplete(true),
    [],
  );

  const onSuccessAnimationAppeared = useCallback(() => {
    setTimeout(dismissSuccessAnimation, SETUP_SUCCESS_ANIMATION_DURATION);
  }, [dismissSuccessAnimation]);

  const addTransitionEndListener = useCallback((node, done) => {
    node.addEventListener('transitionend', done, false);
  }, []);

  const onHeaderContextMenuOpen = useCallback(
    () =>
      sendBi(BI_EVENTS_IDS.APP_ACTION_CLICKED, {
        tab_name: app.id,
        action_type: 'general',
        action_id: 'three dots menu',
      }),
    [sendBi, app],
  );

  const onHeaderContextMenuActionClick = useCallback(
    (action) => {
      action.onClick();
      sendBi(BI_EVENTS_IDS.APP_ACTION_CLICKED, {
        tab_name: app.id,
        action_type: 'general',
        action_id: action.id,
      });
    },
    [sendBi, app],
  );

  const onPremiumBannerUpgradeClick = useCallback(() => {
    premiumBannerInfo.upgrade();
    sendBi(BI_EVENTS_IDS.APP_ACTION_CLICKED, {
      tab_name: app.id,
      action_type: 'general',
      action_id: 'upgrade',
    });
  }, [premiumBannerInfo, sendBi, app]);

  const onManageActionClick = useCallback(
    (action) => {
      action.onClick();
      sendBi(BI_EVENTS_IDS.APP_ACTION_CLICKED, {
        tab_name: app.id,
        action_type: 'quick',
        action_id: action.id,
      });
    },
    [sendBi, app],
  );

  const nextSetupStep = appSetup.nextStep;

  const prevIsComplete = usePrevious(appSetup.isComplete);
  useEffect(() => {
    if (appSetup.isComplete && appSetup.isComplete !== prevIsComplete) {
      sendBi(BI_EVENTS_IDS.SETUP_COMPLETED, { app_id: app.id });
    }
  }, [app, appSetup, sendBi, prevIsComplete]);

  return (
    <div
      className={styles.container}
      data-hook={getBusinessAppDataHook(app.id)}
    >
      <div className={styles.headerContainer}>
        <BusinessAppHeader
          title={dealerData?.title || app.name}
          description={dealerData?.description || app.description}
          imageSrc={dealerData?.imageUrl || app.icon}
          generalAppActions={appActions.general}
          onContextMenuOpen={onHeaderContextMenuOpen}
          onContextMenuActionClick={onHeaderContextMenuActionClick}
        />
      </div>
      <Divider long />
      {premiumBannerInfo ? (
        <Banner
          type="premium"
          text={premiumBannerInfo.text}
          link={{
            label: premiumBannerInfo.linkText,
            onClick: onPremiumBannerUpgradeClick,
          }}
        />
      ) : null}
      <CustomScroll flex="1">
        <CSSTransition
          in={!isSuccessAnimationComplete}
          addEndListener={addTransitionEndListener}
          timeout={DEFAULT_TRANSITION_TIMEOUT}
          classNames={{
            exitActive: styles.slideOutActive,
          }}
          unmountOnExit
          onExit={(node) => (node.style.height = `${node.offsetHeight}px`)}
        >
          <div className={styles.sectionContainer}>
            <>
              <CSSTransition
                in={!appSetup.isComplete}
                timeout={DEFAULT_TRANSITION_TIMEOUT}
                addEndListener={addTransitionEndListener}
                classNames={{
                  exit: styles.fadeOut,
                  exitActive: styles.fadeOutActive,
                }}
                unmountOnExit
                onExit={(node) => {
                  // preserve section's height after setup steps disappear,
                  // so that animation element takes exactly the same space
                  node.parentElement.style.height = `${node.parentElement.offsetHeight}px`;
                }}
              >
                <div>
                  <div className={styles.setupHeaderContainer}>
                    <BusinessAppSetupHeader
                      appDefId={app.id}
                      appName={app.name}
                      stepsCompleted={appSetup.completedStepsCount}
                      stepsTotal={appSetup.totalStepsCount}
                    />
                  </div>
                  <BusinessAppSection>
                    <Dividify long>
                      {appSetup.steps.map((step, index) => (
                        <AppSetupStep
                          key={step.id}
                          step={step}
                          isPriority={step === nextSetupStep}
                          order={index}
                          sendBi={sendBi}
                        />
                      ))}
                    </Dividify>
                  </BusinessAppSection>
                  <hr className={styles.setupDivider} />
                </div>
              </CSSTransition>
              <CSSTransition
                in={appSetup.isComplete}
                timeout={DEFAULT_TRANSITION_TIMEOUT}
                addEndListener={addTransitionEndListener}
                mountOnEnter
                classNames={{
                  enter: styles.fadeIn,
                  enterActive: styles.fadeInActive,
                }}
                onEntered={onSuccessAnimationAppeared}
              >
                <SetupSuccessAnimation
                  appName={app.name}
                  className={styles.successAnimation}
                  dataHook={
                    AUTOMATION_IDS.MY_BUSINESS.APP_SETUP.SUCCESS_ANIMATION
                  }
                  onDismiss={dismissSuccessAnimation}
                />
              </CSSTransition>
            </>
          </div>
        </CSSTransition>
        <BusinessAppSection
          heading={t('App_Manager_Ecomm_Segment_Main_Panel_Section2_Header')}
        >
          <Dividify long>
            {manageActions.map((action) => (
              <BusinessAppActionItem
                key={action.id}
                title={action.title}
                iconName={action.iconName}
                iconUrl={action.iconUrl}
                onClick={() => onManageActionClick(action)}
                dataHook={AUTOMATION_IDS.MY_BUSINESS.APP_MANAGE_ACTION}
                textDataHook={action.dataHook}
              />
            ))}
          </Dividify>
        </BusinessAppSection>
        {recommendedTools.length > 0 ? (
          <RecommendedTools tools={recommendedTools} sendBi={sendBi} />
        ) : null}
      </CustomScroll>
    </div>
  );
};

const BusinessAppSection: React.FC<{
  heading?: string;
  children: React.ReactNode;
}> = ({ heading, children }) => (
  <div className={styles.section}>
    {heading ? (
      <Text skin="standard" size="small" weight="bold" shouldTranslate={false}>
        {heading}
      </Text>
    ) : null}
    <div className={styles.sectionContent}>{children}</div>
  </div>
);

interface AppActionWithOverrides extends AppAction {
  iconUrl?: string;
}

function mergeDefaultAndDealerAction(
  defaultAction: AppAction,
  dealerAction: DealerAppPageAction,
): AppActionWithOverrides {
  if (!dealerAction) {
    return defaultAction;
  }

  if (!dealerAction && !defaultAction) {
    return undefined;
  }

  return {
    id: defaultAction.id,
    iconName: defaultAction.iconName,
    iconUrl: dealerAction?.iconUrl,
    title: defaultAction.title,
    onClick: dealerAction.onClick || defaultAction.onClick,
    dataHook: defaultAction.dataHook,
  };
}

function mergePrimaryActions(
  rawActions: AppAction[],
  dealerAction1: DealerAppPageAction,
  dealerAction2: DealerAppPageAction,
): AppActionWithOverrides[] {
  return [
    mergeDefaultAndDealerAction(rawActions[0], dealerAction1),
    mergeDefaultAndDealerAction(rawActions[1], dealerAction2),
  ].filter(Boolean);
}

export const BusinessApp = hoc.connect(
  hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(BusinessAppComponent);
