import { arrayUtils } from '@/util';
import { isMeshLayoutEnabled } from '@/layout';
import type { CompRef } from 'types/documentServices';
import type { EditorAPI } from '@/editorAPI';

export function createComponentsGroupApi({
  editorAPI,
}: {
  editorAPI: EditorAPI;
}) {
  async function group(compRefs: CompRef[]) {
    if (editorAPI.components.is.groupedComponent(compRefs)) {
      return;
    }

    editorAPI.selection.deselectComponents();

    const groupRef = isMeshLayoutEnabled()
      ? await editorAPI.components.layout.__mesh.groupTransaction(compRefs)
      : editorAPI.dsActions.components.groupComponents(compRefs);

    editorAPI.history.add('group components', { isAddingComponent: true });
    editorAPI.closeCompPanelIfExists();

    editorAPI.dsActions.waitForChangesApplied(async () => {
      editorAPI.selection.selectComponentByCompRef(groupRef);
    });

    return groupRef;
  }

  async function ungroup(groupRefOrRefs: CompRef | CompRef[]) {
    const [groupRef] = arrayUtils.asArray(groupRefOrRefs);

    if (!editorAPI.components.is.exist(groupRef)) {
      return;
    }

    if (isMeshLayoutEnabled()) {
      await editorAPI.components.layout.__mesh.ungroupTransaction(groupRef);
    } else {
      editorAPI.dsActions.components.ungroup(groupRef);
    }

    editorAPI.history.add('ungroup');
  }

  return {
    group,
    ungroup,
  };
}
