import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { translate } from '@/i18n';

import {
  Composites,
  FontFamilyPicker,
  InfoIcon,
  TextLabel,
  Tooltip,
} from '@wix/wix-base-ui';
import { symbol as Symbol } from '@wix/santa-editor-symbols';
import * as util from '@/util';

import {
  isUploadedFont,
  getFont,
  getOnlyFontFamily,
} from '../../utils/fontUtils';
import constants from '../../utils/constants';

import type { FontOption } from 'types/documentServices';

const { FONT_GROUPS } = constants;

interface FooterLink {
  label: string;
  onClick: () => void;
  className?: string;
  symbol?: string;
}

interface FontFamilyOwnProps {
  shouldTranslate?: boolean;
  shouldAddAsteriskToSelectedFont?: boolean;
  infoText?: string;
  label?: string;
  onClose?: () => void;
  onToggle?: (isOpen: boolean) => void;
  value?: string;
  onChange?: (fontFamily: string) => void;
  doNotFocus?: boolean;
  footerLinks?: FooterLink[];
  fonts: FontOption[];
  valueLink?: {
    value: string;
    requestChange: (value: string) => void;
  };
  automationId?: string;
  tooltipValue?: string | React.ReactElement;
  toolbarMode?: boolean;
  onArrowKeyDown?: (e: React.ReactElement) => void;
  onOptionMouseEnter?: (e: React.ReactElement) => void;
}

interface FontFamilyProps extends FontFamilyOwnProps {}

class FontFamily extends React.Component<FontFamilyProps> {
  getUploadedFontName = (fontFamily: string = '') => {
    let originalFontName = fontFamily.split(',')[2] || fontFamily;

    originalFontName = originalFontName.replace(
      util.fontsManagerUtils.constants.ORIGINAL_FONT_PREFIX,
      '',
    );
    originalFontName = _.startCase(originalFontName);
    return originalFontName;
  };

  getMissingFontName = () => {
    if (this.isMissingFont()) {
      if (isUploadedFont(this.props.value)) {
        return this.getUploadedFontName(this.props.value);
      }
      if (this.props.value && getFont(this.props.value)) {
        return getFont(this.props.value).displayName;
      }
      return '';
    }
  };

  translateIfNeeded = (text: string) => {
    if (
      _.isBoolean(this.props.shouldTranslate) &&
      !this.props.shouldTranslate
    ) {
      return text;
    }

    return translate(text);
  };

  getFooterLinks = () => {
    if (!this.props.footerLinks) {
      return;
    }

    return this.props.footerLinks.map((footerLink) => ({
      ...footerLink,
      label: this.translateIfNeeded(footerLink.label),
      symbol: footerLink.symbol && <Symbol name={footerLink.symbol} />,
    }));
  };

  isMissingFont = () => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/map
    const allFonts = _(this.props.fonts).map('fonts').flattenDeep().value();

    return !allFonts.find((font) => font?.fontFamily === this.props.value);
  };

  handleChange = (value: string) => {
    return this.props.onChange
      ? this.props.onChange(value)
      : this.props.valueLink.requestChange(value);
  };

  getFontGroupsNamesMap = () => {
    return Object.keys(FONT_GROUPS).reduce(
      (map, key) => {
        map[key as keyof typeof FONT_GROUPS] = translate(
          FONT_GROUPS[key as keyof typeof FONT_GROUPS],
        );
        return map;
      },
      {} as Record<keyof typeof FONT_GROUPS, string>,
    );
  };

  addAsteriskToSelectedFont = (fontOptions: FontOption[]) => {
    // TODO: add this to base UI;
    const fontOptionsToReturn = _.cloneDeep(fontOptions);

    for (const fontOption of fontOptionsToReturn) {
      for (const font of fontOption.fonts) {
        if (font.fontFamily === this.props.value) {
          font.displayName = `${font.displayName}*`;

          return fontOptionsToReturn;
        }
      }
    }

    return fontOptionsToReturn;
  };

  render() {
    const { fonts } = this.props;
    const fontGroupsNamesMap = this.getFontGroupsNamesMap();
    const fontPickerValue = this.props.value || this.props.valueLink?.value;
    return (
      <Tooltip
        content={this.props.tooltipValue}
        disabled={!this.props.tooltipValue}
      >
        <Composites.FontFamilyPickerLabeled>
          {this.props.infoText ? (
            <InfoIcon text={this.props.infoText} shouldTranslate={true} />
          ) : null}
          {this.props.label ? (
            <TextLabel value={this.props.label} shouldTranslate={true} />
          ) : null}
          <FontFamilyPicker
            fonts={
              this.props.shouldAddAsteriskToSelectedFont
                ? this.addAsteriskToSelectedFont(fonts)
                : fonts
            }
            fontGroupsNamesMap={fontGroupsNamesMap}
            value={getOnlyFontFamily(fontPickerValue)}
            shouldTranslate={false}
            onToggle={this.props.onToggle}
            onChange={this.handleChange}
            onArrowKeyDown={(e) =>
              this.props.onArrowKeyDown &&
              this.props.onArrowKeyDown(e as React.ReactElement)
            }
            onOptionMouseEnter={(e) =>
              this.props.onOptionMouseEnter &&
              this.props.onOptionMouseEnter(e as React.ReactElement)
            }
            // @ts-expect-error
            optionsContainerClassName="fonts-dropdown"
            getMissingFontName={this.getMissingFontName}
            toolbarMode={this.props.toolbarMode}
            footerLinks={this.getFooterLinks()}
            automationId={this.props.automationId}
            shouldUseSelectedFontFamily={true}
          />
        </Composites.FontFamilyPickerLabeled>
      </Tooltip>
    );
  }

  static propTypes = {
    shouldTranslate: PropTypes.bool,
    infoText: PropTypes.string,
    label: PropTypes.string,
    onClose: PropTypes.func,
    onToggle: PropTypes.func,
    onChange: PropTypes.func,
    doNotFocus: PropTypes.bool,
    footerLinks: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired,
      }),
    ),
    fonts: PropTypes.arrayOf(
      PropTypes.shape({
        fonts: PropTypes.array,
        lang: PropTypes.string.isRequired,
      }).isRequired,
    ),
    value: PropTypes.string,
    valueLink: PropTypes.shape({
      value: PropTypes.object,
      requestChange: PropTypes.func.isRequired,
    }),
    automationId: PropTypes.string,
    // type inferring from proptypes breaks type resolving with i18n. We have props interfaces, so we can add any here
  } as any;

  static defaultProps = {
    onToggle: _.noop,
  };
}

export default FontFamily;
