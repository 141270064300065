import React from 'react';
import { Text } from '@wix/wix-base-ui';

interface ITooltipContent {
  tooltipData?: {
    title?: string;
    text: string;
    disableTranslate?: boolean;
  };
  customTooltip?: {
    content?: string;
  };
}

const TooltipContent: React.FunctionComponent<ITooltipContent> = (props) => {
  const className =
    'top-bar-tooltip-content-wrapper top-bar-tooltip-content-wrapper_se-madefor-font';
  const customContent = props?.customTooltip?.content;
  if (customContent) {
    return <div className={className}>{customContent}</div>;
  }

  const { tooltipData } = props;
  if (!tooltipData) {
    return null;
  }

  const title = (
    <Text
      skin="secondary"
      shouldTranslate={!tooltipData.disableTranslate}
      enableEllipsis={false}
      size="medium"
      weight="bold"
    >
      {tooltipData.title}
    </Text>
  );

  const text = (
    <Text
      skin="secondary"
      shouldTranslate={!tooltipData.disableTranslate}
      enableEllipsis={false}
      size="small"
    >
      {tooltipData.text}
    </Text>
  );

  if (!tooltipData.title) {
    return text;
  }

  return (
    <div className={className}>
      {title}
      {text}
    </div>
  );
};

export default TooltipContent;
