// @ts-nocheck
import _ from 'lodash';
import constants from '@/constants';
import * as helpIds from '@/helpIds';
import gfppDataUtils from '../../utils/gfppDataUtils';
import type { EditorAPI } from '@/editorAPI';
import type { CompRef } from '@wix/document-services-types';

const { ACTIONS } = constants.ROOT_COMPS.GFPP;
const changeBigPlayPanel = 'compPanels.panels.MediaOverlayControls.changePanel';

export default {
  untranslatable: true,
  mainActions: [
    {
      label: 'gfpp_mainaction_MediaOverlayControls',
      isSelected: gfppDataUtils.getPanelStateFn(changeBigPlayPanel),
      onClick(editorAPI: EditorAPI, compRef: CompRef, origin: string) {
        gfppDataUtils.toggleComponentPanel(editorAPI, changeBigPlayPanel, {
          selectedComponent: compRef,
          origin,
        });
      },
    },
  ],
  enabledActions: [ACTIONS.DESIGN, ACTIONS.HELP],
  mobileEnabledActions: [ACTIONS.HIDE, ACTIONS.HELP],
  presetActions: {
    help: {
      helpId(editorAPI, compRef) {
        const playerId = editorAPI.components.properties.get(compRef)?.playerId;
        const mediaFeatures = editorAPI.components.design.get({
          id: playerId,
          type: 'DESKTOP',
        })?.background?.mediaRef?.mediaFeatures;
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/includes
        return _.includes(mediaFeatures, 'alpha')
          ? helpIds.GFPP.MEDIA_BIG_PLAY_ALPHA
          : helpIds.GFPP.MEDIA_BIG_PLAY;
      },
      mobileHelpId(editorAPI, compRef) {
        const playerId = editorAPI.components.properties.get(compRef)?.playerId;
        const mediaFeatures = editorAPI.components.design.get({
          id: playerId,
          type: 'DESKTOP',
        })?.background?.mediaRef?.mediaFeatures;
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/includes
        return _.includes(mediaFeatures, 'alpha')
          ? helpIds.GFPP_MOBILE.MEDIA_BIG_PLAY_ALPHA
          : helpIds.GFPP_MOBILE.MEDIA_BIG_PLAY;
      },
    },
  },
};
