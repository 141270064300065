'use strict'

module.exports = {
    id: 'Columns_ColumnsContact_6',
    structure: {
        type: 'Container',
        components: [
            {
                type: 'Container',
                components: [
                    {
                        type: 'Component',
                        skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
                        layout: {
                            width: 328,
                            height: 89,
                            x: 4,
                            y: 109,
                            scale: 1,
                            rotationInDegrees: 0,
                            fixedPosition: false
                        },
                        componentType: 'wysiwyg.viewer.components.WRichText',
                        data: {
                            type: 'StyledText',
                            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                            text: '<h2 class="font_2" style="font-size: 38px; line-height: 1em; text-align: center;"><span style="font-size:38px;"><span style="color:#4BD1A0"><span style="letter-spacing:0.1em"><span style="font-family:lulo-clean-w01-one-bold,sans-serif">CONTACT</span></span></span></span></h2>\n\n<h2 class="font_2" style="font-size: 38px; line-height: 1em; text-align: center;"><span style="font-size:38px;"><span class="color_11"><span style="letter-spacing:0.1em"><span style="font-family:lulo-clean-w01-one-bold,sans-serif">US</span></span></span></span></h2>\n',
                            stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
                            linkList: []
                        },
                        props: {
                            type: 'WRichTextProperties',
                            metaData: {schemaVersion: '1.0'},
                            brightness: 1,
                            packed: true
                        },
                        style: 'txtNew',
                        mobileStructure: {
                            type: 'Component',
                            skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
                            layout: {
                                width: 280,
                                height: 62,
                                x: 20,
                                y: 51,
                                scale: 0.9025,
                                rotationInDegrees: 0,
                                fixedPosition: false
                            },
                            componentType: 'wysiwyg.viewer.components.WRichText',
                            data: {
                                type: 'StyledText',
                                metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                                text: '<h2 class="font_2" style="font-size: 38px; line-height: 1em; text-align: center;"><span style="font-size:38px;"><span style="color:#4BD1A0"><span style="letter-spacing:0.1em"><span style="font-family:lulo-clean-w01-one-bold,sans-serif">CONTACT</span></span></span></span></h2>\n\n<h2 class="font_2" style="font-size: 38px; line-height: 1em; text-align: center;"><span style="font-size:38px;"><span class="color_11"><span style="letter-spacing:0.1em"><span style="font-family:lulo-clean-w01-one-bold,sans-serif">US</span></span></span></span></h2>\n',
                                stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
                                linkList: []
                            },
                            props: {
                                type: 'WRichTextProperties',
                                metaData: {schemaVersion: '1.0', autoGenerated: false},
                                brightness: 1,
                                packed: true
                            },
                            style: 'txtNew'
                        }
                    },
                    {
                        type: 'Component',
                        skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
                        layout: {
                            width: 248,
                            height: 124,
                            x: 40,
                            y: 265,
                            scale: 1,
                            rotationInDegrees: 0,
                            fixedPosition: false
                        },
                        componentType: 'wysiwyg.viewer.components.WRichText',
                        data: {
                            type: 'StyledText',
                            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                            text: '<h2 class="font_2" style="font-size:17px; line-height:1.5em; text-align:center"><span style="letter-spacing:0.02em"><span style="font-size:17px"><span class="color_11"><span style="font-family:avenir-lt-w01_35-light1475496,sans-serif">Tel. 123-456-7890</span></span></span></span></h2>\n\n<h2 class="font_2" style="font-size:17px; line-height:1.5em; text-align:center"><span style="letter-spacing:0.02em"><span style="font-size:17px"><span class="color_11"><span style="font-family:avenir-lt-w01_35-light1475496,sans-serif">Fax. 123-456-7890</span></span></span></span></h2>\n\n<h2 class="font_2" style="font-size:17px; line-height:1.5em; text-align:center"><span style="letter-spacing:0.02em"><span style="font-size:17px"><span class="color_11"><span style="font-family:avenir-lt-w01_35-light1475496,sans-serif">500 Terry Francois Street,&nbsp;<br />\nSan Francisco, CA 94158</span></span></span></span></h2>\n\n<p class="font_8" style="line-height:1.5em; text-align:center"><span class="wixGuard">​</span></p>\n',
                            stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
                            linkList: []
                        },
                        props: {
                            type: 'WRichTextProperties',
                            metaData: {schemaVersion: '1.0'},
                            brightness: 1,
                            packed: true
                        },
                        style: 'txtNew',
                        mobileStructure: {
                            type: 'Component',
                            skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
                            layout: {
                                width: 246,
                                height: 167,
                                x: 38,
                                y: 171,
                                scale: 0.9025,
                                rotationInDegrees: 0,
                                fixedPosition: false
                            },
                            componentType: 'wysiwyg.viewer.components.WRichText',
                            data: {
                                type: 'StyledText',
                                metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                                text: '<h2 class="font_2" style="font-size:17px; line-height:1.5em; text-align:center"><span style="letter-spacing:0.02em"><span style="font-size:17px"><span class="color_11"><span style="font-family:avenir-lt-w01_35-light1475496,sans-serif">Tel. 123-456-7890</span></span></span></span></h2>\n\n<h2 class="font_2" style="font-size:17px; line-height:1.5em; text-align:center"><span style="letter-spacing:0.02em"><span style="font-size:17px"><span class="color_11"><span style="font-family:avenir-lt-w01_35-light1475496,sans-serif">Fax. 123-456-7890</span></span></span></span></h2>\n\n<h2 class="font_2" style="font-size:17px; line-height:1.5em; text-align:center"><span style="letter-spacing:0.02em"><span style="font-size:17px"><span class="color_11"><span style="font-family:avenir-lt-w01_35-light1475496,sans-serif">500 Terry Francois Street,&nbsp;<br />\nSan Francisco, CA 94158</span></span></span></span></h2>\n\n<p class="font_8" style="line-height:1.5em; text-align:center"><span class="wixGuard">​</span></p>\n',
                                stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
                                linkList: []
                            },
                            props: {
                                type: 'WRichTextProperties',
                                metaData: {schemaVersion: '1.0', autoGenerated: false},
                                brightness: 1,
                                packed: true
                            },
                            style: 'txtNew'
                        }
                    },
                    {
                        type: 'Component',
                        skin: 'wysiwyg.viewer.skins.line.SolidLine',
                        layout: {
                            width: 42,
                            height: 7.77778,
                            x: 146,
                            y: 226,
                            scale: 1,
                            rotationInDegrees: 0,
                            fixedPosition: false
                        },
                        componentType: 'wysiwyg.viewer.components.FiveGridLine',
                        props: {
                            type: 'FiveGridLineProperties',
                            metaData: {schemaVersion: '1.0'},
                            fullScreenModeOn: false
                        },
                        style: {
                            type: 'TopLevelStyle',
                            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                            style: {
                                groups: {},
                                properties: {'alpha-brd': '1', brd: '#FFFFFF', lnw: '8'},
                                propertiesSource: {brd: 'value', lnw: 'value'}
                            },
                            componentClassName: 'wysiwyg.viewer.components.FiveGridLine',
                            pageId: '',
                            compId: '',
                            styleType: 'custom',
                            skin: 'wysiwyg.viewer.skins.line.SolidLine'
                        },
                        mobileStructure: {
                            type: 'Component',
                            skin: 'wysiwyg.viewer.skins.line.SolidLine',
                            layout: {
                                width: 42,
                                height: 7.77778,
                                x: 139,
                                y: 138,
                                scale: 1,
                                rotationInDegrees: 0,
                                fixedPosition: false
                            },
                            componentType: 'wysiwyg.viewer.components.FiveGridLine',
                            props: {
                                type: 'FiveGridLineProperties',
                                metaData: {schemaVersion: '1.0'},
                                fullScreenModeOn: false
                            },
                            style: {
                                type: 'TopLevelStyle',
                                metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                                style: {
                                    groups: {},
                                    properties: {'alpha-brd': '1', brd: '#FFFFFF', lnw: '8'},
                                    propertiesSource: {brd: 'value', lnw: 'value'}
                                },
                                componentClassName: 'wysiwyg.viewer.components.FiveGridLine',
                                pageId: '',
                                compId: '',
                                styleType: 'custom',
                                skin: 'wysiwyg.viewer.skins.line.SolidLine'
                            }
                        }
                    }
                ],
                layout: {
                    width: 332,
                    height: 500,
                    x: -119,
                    y: 0,
                    scale: 1,
                    rotationInDegrees: 0,
                    fixedPosition: false
                },
                componentType: 'wysiwyg.viewer.components.Column',
                props: {type: 'ColumnProperties', metaData: {schemaVersion: '1.0'}, alignment: 50},
                design: {
                    type: 'MediaContainerDesignData',
                    metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                    background: {
                        type: 'BackgroundMedia',
                        metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                        color: '#000000',
                        colorOpacity: 1,
                        alignType: 'center',
                        fittingType: 'fill',
                        scrollType: 'none',
                        colorOverlay: '',
                        colorOverlayOpacity: 0
                    }
                },
                style: 'mc1',
                mobileStructure: {
                    type: 'Container',
                    layout: {
                        width: 320,
                        height: 368,
                        x: 0,
                        y: 0,
                        scale: 1,
                        rotationInDegrees: 0,
                        fixedPosition: false
                    },
                    componentType: 'wysiwyg.viewer.components.Column',
                    props: {
                        type: 'ColumnProperties',
                        metaData: {schemaVersion: '1.0'},
                        alignment: 50
                    },
                    design: {
                        type: 'MediaContainerDesignData',
                        metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                        background: {
                            type: 'BackgroundMedia',
                            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                            color: '#000000',
                            colorOpacity: 1,
                            alignType: 'center',
                            fittingType: 'fill',
                            scrollType: 'none',
                            colorOverlay: '',
                            colorOverlayOpacity: 0
                        }
                    },
                    style: 'mc1'
                }
            },
            {
                type: 'Container',
                components: [
                    {
                        type: 'Component',
                        skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
                        layout: {
                            width: 328,
                            height: 89,
                            x: 1,
                            y: 109,
                            scale: 1,
                            rotationInDegrees: 0,
                            fixedPosition: false
                        },
                        componentType: 'wysiwyg.viewer.components.WRichText',
                        data: {
                            type: 'StyledText',
                            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                            text: '<h2 class="font_2" style="font-size: 38px; line-height: 1em; text-align: center;"><span style="font-size:38px;"><span style="color:#4BD1A0"><span style="letter-spacing:0.1em"><span style="font-family:lulo-clean-w01-one-bold,sans-serif">VISIT</span></span></span></span></h2>\n\n<h2 class="font_2" style="font-size: 38px; line-height: 1em; text-align: center;"><span style="font-size:38px;"><span style="color:#FFFFFF"><span style="letter-spacing:0.1em"><span style="font-family:lulo-clean-w01-one-bold,sans-serif">US</span></span></span></span></h2>\n',
                            stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
                            linkList: []
                        },
                        props: {
                            type: 'WRichTextProperties',
                            metaData: {schemaVersion: '1.0'},
                            brightness: 1,
                            packed: true
                        },
                        style: 'txtNew',
                        mobileStructure: {
                            type: 'Component',
                            skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
                            layout: {
                                width: 220,
                                height: 62,
                                x: 50,
                                y: 53,
                                scale: 0.9025,
                                rotationInDegrees: 0,
                                fixedPosition: false
                            },
                            componentType: 'wysiwyg.viewer.components.WRichText',
                            data: {
                                type: 'StyledText',
                                metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                                text: '<h2 class="font_2" style="font-size: 38px; line-height: 1em; text-align: center;"><span style="font-size:38px;"><span style="color:#4BD1A0"><span style="letter-spacing:0.1em"><span style="font-family:lulo-clean-w01-one-bold,sans-serif">VISIT</span></span></span></span></h2>\n\n<h2 class="font_2" style="font-size: 38px; line-height: 1em; text-align: center;"><span style="font-size:38px;"><span style="color:#FFFFFF"><span style="letter-spacing:0.1em"><span style="font-family:lulo-clean-w01-one-bold,sans-serif">US</span></span></span></span></h2>\n',
                                stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
                                linkList: []
                            },
                            props: {
                                type: 'WRichTextProperties',
                                metaData: {schemaVersion: '1.0', autoGenerated: false},
                                brightness: 1,
                                packed: true
                            },
                            style: 'txtNew'
                        }
                    },
                    {
                        type: 'Component',
                        skin: 'wysiwyg.viewer.skins.line.SolidLine',
                        layout: {
                            width: 42,
                            height: 7.77778,
                            x: 141,
                            y: 226,
                            scale: 1,
                            rotationInDegrees: 0,
                            fixedPosition: false
                        },
                        componentType: 'wysiwyg.viewer.components.FiveGridLine',
                        props: {
                            type: 'FiveGridLineProperties',
                            metaData: {schemaVersion: '1.0'},
                            fullScreenModeOn: false
                        },
                        style: {
                            type: 'TopLevelStyle',
                            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                            style: {
                                groups: {},
                                properties: {'alpha-brd': '1', brd: '#FFFFFF', lnw: '8'},
                                propertiesSource: {brd: 'value', lnw: 'value'}
                            },
                            componentClassName: 'wysiwyg.viewer.components.FiveGridLine',
                            pageId: '',
                            compId: '',
                            styleType: 'custom',
                            skin: 'wysiwyg.viewer.skins.line.SolidLine'
                        },
                        mobileStructure: {
                            type: 'Component',
                            skin: 'wysiwyg.viewer.skins.line.SolidLine',
                            layout: {
                                width: 42,
                                height: 7.77778,
                                x: 139,
                                y: 135,
                                scale: 1,
                                rotationInDegrees: 0,
                                fixedPosition: false
                            },
                            componentType: 'wysiwyg.viewer.components.FiveGridLine',
                            props: {
                                type: 'FiveGridLineProperties',
                                metaData: {schemaVersion: '1.0'},
                                fullScreenModeOn: false
                            },
                            style: {
                                type: 'TopLevelStyle',
                                metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                                style: {
                                    groups: {},
                                    properties: {'alpha-brd': '1', brd: '#FFFFFF', lnw: '8'},
                                    propertiesSource: {brd: 'value', lnw: 'value'}
                                },
                                componentClassName: 'wysiwyg.viewer.components.FiveGridLine',
                                pageId: '',
                                compId: '',
                                styleType: 'custom',
                                skin: 'wysiwyg.viewer.skins.line.SolidLine'
                            }
                        }
                    },
                    {
                        type: 'Component',
                        skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
                        layout: {
                            width: 248,
                            height: 106,
                            x: 39,
                            y: 265,
                            scale: 1,
                            rotationInDegrees: 0,
                            fixedPosition: false
                        },
                        componentType: 'wysiwyg.viewer.components.WRichText',
                        data: {
                            type: 'StyledText',
                            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                            text: '<p class="font_8" style="font-size:17px; line-height:1.6em; text-align:center"><span style="font-size:17px"><span class="color_11"><span style="font-family:avenir-lt-w01_35-light1475496,sans-serif">Monday - Friday 11:00 - 18:30</span></span></span></p>\n\n<p class="font_8" style="font-size:17px; line-height:1.6em; text-align:center"><span style="font-size:17px"><span class="color_11"><span style="font-family:avenir-lt-w01_35-light1475496,sans-serif">Saturday 11:00 - 17:00</span></span></span></p>\n\n<p class="font_8" style="font-size:17px; line-height:1.6em; text-align:center"><span style="font-size:17px"><span class="color_11"><span style="font-family:avenir-lt-w01_35-light1475496,sans-serif">Sunday 12:30 - 16:30&nbsp;</span></span></span></p>\n\n<p class="font_8" style="line-height:1.5em; text-align:center">&nbsp;</p>\n',
                            stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
                            linkList: []
                        },
                        props: {
                            type: 'WRichTextProperties',
                            metaData: {schemaVersion: '1.0'},
                            brightness: 1,
                            packed: true
                        },
                        style: 'txtNew',
                        mobileStructure: {
                            type: 'Component',
                            skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
                            layout: {
                                width: 272,
                                height: 198,
                                x: 24,
                                y: 165,
                                scale: 0.8552315624999999,
                                rotationInDegrees: 0,
                                fixedPosition: false
                            },
                            componentType: 'wysiwyg.viewer.components.WRichText',
                            data: {
                                type: 'StyledText',
                                metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                                text: '<p class="font_8" style="font-size:17px; line-height:1.6em; text-align:center"><span style="font-size:17px"><span class="color_11"><span style="font-family:avenir-lt-w01_35-light1475496,sans-serif">Monday - Friday 11:00 - 18:30</span></span></span></p>\n\n<p class="font_8" style="font-size:17px; line-height:1.6em; text-align:center"><span style="font-size:17px"><span class="color_11"><span style="font-family:avenir-lt-w01_35-light1475496,sans-serif">Saturday 11:00 - 17:00</span></span></span></p>\n\n<p class="font_8" style="font-size:17px; line-height:1.6em; text-align:center"><span style="font-size:17px"><span class="color_11"><span style="font-family:avenir-lt-w01_35-light1475496,sans-serif">Sunday 12:30 - 16:30&nbsp;</span></span></span></p>\n\n<p class="font_8" style="line-height:1.5em; text-align:center">&nbsp;</p>\n',
                                stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
                                linkList: []
                            },
                            props: {
                                type: 'WRichTextProperties',
                                metaData: {schemaVersion: '1.0', autoGenerated: false},
                                brightness: 1,
                                packed: true
                            },
                            style: 'txtNew'
                        }
                    }
                ],
                layout: {
                    width: 322,
                    height: 500,
                    x: 336,
                    y: 0,
                    scale: 1,
                    rotationInDegrees: 0,
                    fixedPosition: false
                },
                componentType: 'wysiwyg.viewer.components.Column',
                props: {type: 'ColumnProperties', metaData: {schemaVersion: '1.0'}, alignment: 50},
                design: {
                    type: 'MediaContainerDesignData',
                    metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                    background: {
                        type: 'BackgroundMedia',
                        metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                        color: '{color_14}',
                        colorOpacity: 1,
                        alignType: 'center',
                        fittingType: 'fill',
                        scrollType: 'none',
                        colorOverlay: '',
                        colorOverlayOpacity: 0
                    }
                },
                style: 'mc1',
                mobileStructure: {
                    type: 'Container',
                    layout: {
                        width: 320,
                        height: 402,
                        x: 0,
                        y: 368,
                        scale: 1,
                        rotationInDegrees: 0,
                        fixedPosition: false
                    },
                    componentType: 'wysiwyg.viewer.components.Column',
                    props: {
                        type: 'ColumnProperties',
                        metaData: {schemaVersion: '1.0'},
                        alignment: 50
                    },
                    design: {
                        type: 'MediaContainerDesignData',
                        metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                        background: {
                            type: 'BackgroundMedia',
                            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                            color: '{color_14}',
                            colorOpacity: 1,
                            alignType: 'center',
                            fittingType: 'fill',
                            scrollType: 'none',
                            colorOverlay: '',
                            colorOverlayOpacity: 0
                        }
                    },
                    style: 'mc1'
                }
            },
            {
                type: 'Container',
                components: [
                    {
                        type: 'Component',
                        skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
                        layout: {
                            width: 328,
                            height: 89,
                            x: 4,
                            y: 109,
                            scale: 1,
                            rotationInDegrees: 0,
                            fixedPosition: false
                        },
                        componentType: 'wysiwyg.viewer.components.WRichText',
                        data: {
                            type: 'StyledText',
                            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                            text: '<h2 class="font_2" style="font-size: 38px; line-height: 1em; text-align: center;"><span style="font-size:38px;"><span style="color:#000000"><span style="letter-spacing:0.1em"><span style="font-family:lulo-clean-w01-one-bold,sans-serif">TELL</span></span></span></span></h2>\n\n<h2 class="font_2" style="font-size: 38px; line-height: 1em; text-align: center;"><span style="font-size:38px;"><span class="color_11"><span style="letter-spacing:0.1em"><span style="font-family:lulo-clean-w01-one-bold,sans-serif">US</span></span></span></span></h2>\n',
                            stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
                            linkList: []
                        },
                        props: {
                            type: 'WRichTextProperties',
                            metaData: {schemaVersion: '1.0'},
                            brightness: 1,
                            packed: true
                        },
                        style: 'txtNew',
                        mobileStructure: {
                            type: 'Component',
                            skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
                            layout: {
                                width: 193,
                                height: 62,
                                x: 63,
                                y: 49,
                                scale: 0.9025,
                                rotationInDegrees: 0,
                                fixedPosition: false
                            },
                            componentType: 'wysiwyg.viewer.components.WRichText',
                            data: {
                                type: 'StyledText',
                                metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                                text: '<h2 class="font_2" style="font-size: 38px; line-height: 1em; text-align: center;"><span style="font-size:38px;"><span style="color:#000000"><span style="letter-spacing:0.1em"><span style="font-family:lulo-clean-w01-one-bold,sans-serif">TELL</span></span></span></span></h2>\n\n<h2 class="font_2" style="font-size: 38px; line-height: 1em; text-align: center;"><span style="font-size:38px;"><span class="color_11"><span style="letter-spacing:0.1em"><span style="font-family:lulo-clean-w01-one-bold,sans-serif">US</span></span></span></span></h2>\n',
                                stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
                                linkList: []
                            },
                            props: {
                                type: 'WRichTextProperties',
                                metaData: {schemaVersion: '1.0', autoGenerated: false},
                                brightness: 1,
                                packed: true
                            },
                            style: 'txtNew'
                        }
                    },
                    {
                        type: 'Component',
                        skin: 'wysiwyg.viewer.skins.line.SolidLine',
                        layout: {
                            width: 42,
                            height: 7.77778,
                            x: 142,
                            y: 226,
                            scale: 1,
                            rotationInDegrees: 0,
                            fixedPosition: false
                        },
                        componentType: 'wysiwyg.viewer.components.FiveGridLine',
                        props: {
                            type: 'FiveGridLineProperties',
                            metaData: {schemaVersion: '1.0'},
                            fullScreenModeOn: false
                        },
                        style: {
                            type: 'TopLevelStyle',
                            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                            style: {
                                groups: {},
                                properties: {'alpha-brd': '1', brd: '#FFFFFF', lnw: '8'},
                                propertiesSource: {brd: 'value', lnw: 'value'}
                            },
                            componentClassName: 'wysiwyg.viewer.components.FiveGridLine',
                            pageId: '',
                            compId: '',
                            styleType: 'custom',
                            skin: 'wysiwyg.viewer.skins.line.SolidLine'
                        },
                        mobileStructure: {
                            type: 'Component',
                            skin: 'wysiwyg.viewer.skins.line.SolidLine',
                            layout: {
                                width: 42,
                                height: 7.77778,
                                x: 139,
                                y: 130,
                                scale: 1,
                                rotationInDegrees: 0,
                                fixedPosition: false
                            },
                            componentType: 'wysiwyg.viewer.components.FiveGridLine',
                            props: {
                                type: 'FiveGridLineProperties',
                                metaData: {schemaVersion: '1.0'},
                                fullScreenModeOn: false
                            },
                            style: {
                                type: 'TopLevelStyle',
                                metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                                style: {
                                    groups: {},
                                    properties: {'alpha-brd': '1', brd: '#FFFFFF', lnw: '8'},
                                    propertiesSource: {brd: 'value', lnw: 'value'}
                                },
                                componentClassName: 'wysiwyg.viewer.components.FiveGridLine',
                                pageId: '',
                                compId: '',
                                styleType: 'custom',
                                skin: 'wysiwyg.viewer.skins.line.SolidLine'
                            }
                        }
                    }
                ],
                layout: {
                    width: 323,
                    height: 500,
                    x: 778,
                    y: 0,
                    scale: 1,
                    rotationInDegrees: 0,
                    fixedPosition: false
                },
                componentType: 'wysiwyg.viewer.components.Column',
                props: {type: 'ColumnProperties', metaData: {schemaVersion: '1.0'}, alignment: 50},
                design: {
                    type: 'MediaContainerDesignData',
                    metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                    background: {
                        type: 'BackgroundMedia',
                        metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                        color: '#4BD1A0',
                        colorOpacity: 1,
                        alignType: 'center',
                        fittingType: 'fill',
                        scrollType: 'none',
                        colorOverlay: '',
                        colorOverlayOpacity: 0
                    }
                },
                style: 'mc1',
                mobileStructure: {
                    type: 'Container',
                    layout: {
                        width: 320,
                        height: 592,
                        x: 0,
                        y: 770,
                        scale: 1,
                        rotationInDegrees: 0,
                        fixedPosition: false
                    },
                    componentType: 'wysiwyg.viewer.components.Column',
                    props: {
                        type: 'ColumnProperties',
                        metaData: {schemaVersion: '1.0'},
                        alignment: 50
                    },
                    design: {
                        type: 'MediaContainerDesignData',
                        metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                        background: {
                            type: 'BackgroundMedia',
                            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                            color: '#4BD1A0',
                            colorOpacity: 1,
                            alignType: 'center',
                            fittingType: 'fill',
                            scrollType: 'none',
                            colorOverlay: '',
                            colorOverlayOpacity: 0
                        }
                    },
                    style: 'mc1'
                }
            }
        ],
        layout: {
            width: 980,
            height: 500,
            x: 0,
            y: 4227,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false
        },
        componentType: 'wysiwyg.viewer.components.StripColumnsContainer',
        props: {
            type: 'StripColumnsContainerProperties',
            metaData: {schemaVersion: '1.0'},
            fullWidth: true,
            columnsMargin: 0,
            frameMargin: 0,
            rowMargin: 0,
            siteMargin: 0
        },
        design: {
            type: 'MediaContainerDesignData',
            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
            background: {
                type: 'BackgroundMedia',
                metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                color: '{color_14}',
                colorOpacity: 1,
                alignType: 'center',
                fittingType: 'fill',
                scrollType: 'none',
                colorOverlay: '',
                colorOverlayOpacity: 0
            }
        },
        style: {
            type: 'TopLevelStyle',
            id: 'strc1',
            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
            style: {groups: {}, properties: {}, propertiesSource: {}},
            componentClassName: 'wysiwyg.viewer.components.StripColumnsContainer',
            pageId: '',
            compId: '',
            styleType: 'system',
            skin: 'wysiwyg.viewer.skins.stripContainer.DefaultStripContainer'
        },
        mobileStructure: {
            type: 'Container',
            layout: {
                width: 320,
                height: 1362,
                x: 0,
                y: 10,
                scale: 1,
                rotationInDegrees: 0,
                fixedPosition: false
            },
            componentType: 'wysiwyg.viewer.components.StripColumnsContainer',
            props: {
                type: 'StripColumnsContainerProperties',
                metaData: {schemaVersion: '1.0'},
                fullWidth: true,
                columnsMargin: 0,
                frameMargin: 0,
                rowMargin: 0,
                siteMargin: 0
            },
            design: {
                type: 'MediaContainerDesignData',
                metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                background: {
                    type: 'BackgroundMedia',
                    metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                    color: '{color_14}',
                    colorOpacity: 1,
                    alignType: 'center',
                    fittingType: 'fill',
                    scrollType: 'none',
                    colorOverlay: '',
                    colorOverlayOpacity: 0
                }
            },
            style: 'strc1'
        },
        activeModes: {},
        id: 'comp-ioimgv3f'
    },
    preset: {
        rect: {width: 324, height: 140, x: -0.8, y: 825.4000000000001},
        label: 'add_preset_columns_contact_6',
        tags: null
    }
}
