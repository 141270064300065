export const getSectionDividerLabelForCustomization = (
  customization: AnyFixMe,
) => {
  let id;
  if (customization.relatedComp) {
    id = customization.relatedComp.toLowerCase();
  } else if (
    customization.fieldId === 'OlderPosts' ||
    customization.fieldId === 'NewerPosts' ||
    customization.fieldId === 'paginationNavigationBox'
  ) {
    id = 'pagination';
  } else if (customization.key === 'monthsToShow') {
    id = 'month_range';
  } else if (
    customization.key === 'comp.autoplayInterval' ||
    customization.key === 'comp.transition' ||
    customization.key === 'comp.autoplay'
  ) {
    id = 'animation';
  } else if (customization.fieldId === 'pagination') {
    id = 'pagination_buttons';
  }
  return id ? `blog_divider_${id}` : '';
};
