import _ from 'lodash';
import React from 'react';

import { hoc, cx } from '@/util';
import {
  mapStateToProps,
  mapDispatchToProps,
  type HighlightsLayerStateProps,
  type HighlightsLayerDispatchProps,
  type HighlightsLayerOwnProps,
  type Highlight,
} from './highlightsLayerMapper';
import HoveredComponentLabels from '../selectionBox/hoveredComponentLabels/hoveredComponentLabels';

const {
  connect,
  STORES: { EDITOR_API },
} = hoc;

interface HighlightsLayerProps
  extends HighlightsLayerStateProps,
    HighlightsLayerDispatchProps,
    HighlightsLayerOwnProps {}
class HighlightsLayer extends React.Component<HighlightsLayerProps> {
  static displayName = 'highlightsLayer';

  getHighlightClass = (highlight: Highlight) => {
    return cx('highlightFrame', highlight.type, {
      'show-on-all-pages': highlight.isShownOnAllPages,
      [highlight.compType]: Boolean(highlight.compType),
    });
  };

  stopEventPropagation: React.MouseEventHandler = (e) => {
    e.stopPropagation();
  };

  render() {
    return (
      <div>
        {/* TODO: Fix this the next time the file is edited. */}
        {/* eslint-disable-next-line you-dont-need-lodash-underscore/map */}
        {_.map(this.props.highlights, (highlight, highlightIndex) => (
          <div
            key={`highlight${highlightIndex}`}
            style={highlight.style}
            className={this.getHighlightClass(highlight)}
            data-aid="highlight-frame"
          >
            {highlight.shouldRenderLabels && (
              <HoveredComponentLabels
                key={`highlight-label${highlightIndex}`}
                automationId="highlight-labels"
                compPointer={highlight.compRef}
                displayNameOverride={highlight.displayNameOverride}
                calculateIsLabelBottom={this.props.calculateIsLabelBottom}
              />
            )}
          </div>
        ))}
      </div>
    );
  }
}

const Connected = connect(
  EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(HighlightsLayer);

Connected.pure = HighlightsLayer;

export default Connected;
