'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')
module.exports = {
    "type": addPanelDataConsts.SECTIONS_TYPES.PRESET,
    "hide": false,
    "title": "add_section_label_slider",
    "subNavigationTitle": "add_section_label_slider",
    "topTitle": "add_section_title_slider",
    "presetTitle": "add_section_heading_slider",
    "tooltipTitle": "add_section_label_slider_tooltip_title",
    "automationId": "add-panel-section-sliderSection",
    "subNavigationHide": false,
    "showSectionHeader": true,
    "additionalBehaviours": {
        "labelMode": addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
        "infoIcon": addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
        "hoverImageAction": addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
        "iconEnabledForComps": {}
    },
    "props": {
        "image": "addPanelData/sections/developer_en/sliderSection_en.v4.png",
        "imageHover": null,
        "items": [{
            "id": "User_Input_Slider_1",
            "structure": {
                "type": "Component",
                "skin": "wixui.skins.Slider",
                "layout": {
                    "width": 142,
                    "height": 22,
                    "x": 10,
                    "y": 30,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wixui.Slider",
                "data": {
                    "type": "Slider",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "min": 1,
                    "max": 12,
                    "value": 3,
                    "step": 1,
                    "stepType": "value",
                    "disabled": false,
                    "readOnly": false
                },
                "props": {
                    "type": "SliderProperties",
                    "metaData": {
                        "schemaVersion": "1.0",
                        "autoGenerated": false
                    },
                    "trackSize": 9,
                    "orientation": "horizontal",
                    "thumbShape": "circle",
                    "tickMarksShape": "none",
                    "tooltipVisibility": "hover",
                    "tooltipPosition": "normal",
                    "tooltipPrefix": "",
                    "tooltipSuffix": " Bedrooms",
                    "tickMarksPosition": "middle",
                    "dir": "ltr"
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-tooltipBorderColor": "1",
                            "alpha-trackBackgroundHover": "1",
                            "alpha-trackBorderColorHover": "1",
                            "alpha-trackFill": "1",
                            "alpha-trackFillHover": "1",
                            "boxShadowToggleOn-thumbBoxShadow": "false",
                            "boxShadowToggleOn-tooltipBoxShadow": "false",
                            "boxShadowToggleOn-trackBoxShadow": "false",
                            "thumbBackground": "#000000",
                            "thumbBackgroundDisabled": "#D8D8D8",
                            "thumbBackgroundFocus": "#000000",
                            "thumbBackgroundHover": "#FFFFFF",
                            "thumbBorderColor": "#414141",
                            "thumbBorderColorDisabled": "#D8D8D8",
                            "thumbBorderColorFocus": "#000000",
                            "thumbBorderColorHover": "#000000",
                            "thumbBorderWidth": "1",
                            "thumbBorderWidthDisabled": "0",
                            "thumbBorderWidthFocus": "1",
                            "thumbBorderWidthHover": "2",
                            "thumbBoxShadow": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                            "tickMarkColor": "#000000",
                            "tooltipBackground": "#000000",
                            "tooltipBorderWidth": "0",
                            "tooltipBoxShadow": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                            "trackBackground": "#C7C7C7",
                            "trackBackgroundDisabled": "#D8D8D8",
                            "trackBackgroundFocus": "#C7C7C7",
                            "trackBackgroundHover": "#C7C7C7",
                            "trackBorderColor": "#C7C7C7",
                            "trackBorderColorDisabled": "#D8D8D8",
                            "trackBorderColorHover": "#000000",
                            "trackBorderWidth": "0",
                            "trackBorderWidthDisabled": "1",
                            "trackBorderWidthFocus": "0",
                            "trackBorderWidthHover": "0",
                            "trackBoxShadow": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                            "trackFill": "#000000",
                            "trackFillDisabled": "#D8D8D8",
                            "trackFillFocus": "#000000",
                            "trackFillHover": "#000000"
                        },
                        "propertiesSource": {
                            "alpha-tooltipBorderColor": "value",
                            "alpha-trackBackgroundHover": "value",
                            "alpha-trackBorderColorHover": "value",
                            "alpha-trackFill": "value",
                            "alpha-trackFillHover": "value",
                            "thumbBackground": "value",
                            "thumbBackgroundDisabled": "value",
                            "thumbBackgroundFocus": "value",
                            "thumbBackgroundHover": "value",
                            "thumbBorderColor": "value",
                            "thumbBorderColorDisabled": "value",
                            "thumbBorderColorFocus": "value",
                            "thumbBorderColorHover": "value",
                            "thumbBorderWidth": "value",
                            "thumbBorderWidthDisabled": "value",
                            "thumbBorderWidthFocus": "value",
                            "thumbBorderWidthHover": "value",
                            "tickMarkColor": "value",
                            "tooltipBackground": "value",
                            "tooltipBorderWidth": "value",
                            "trackBackground": "value",
                            "trackBackgroundDisabled": "value",
                            "trackBackgroundFocus": "value",
                            "trackBackgroundHover": "value",
                            "trackBorderColor": "value",
                            "trackBorderColorDisabled": "value",
                            "trackBorderColorHover": "value",
                            "trackBorderWidth": "value",
                            "trackBorderWidthDisabled": "value",
                            "trackBorderWidthFocus": "value",
                            "trackBorderWidthHover": "value",
                            "trackFill": "value",
                            "trackFillDisabled": "value",
                            "trackFillFocus": "value",
                            "trackFillHover": "value"
                        }
                    },
                    "componentClassName": "wixui.Slider",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wixui.skins.Slider"
                },
                "mobileStructure": {
                    "type": "Component",
                    "skin": "wixui.skins.Slider",
                    "layout": {
                        "width": 142,
                        "height": 22,
                        "x": 10,
                        "y": 17,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wixui.Slider",
                    "data": {
                        "type": "Slider",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "min": 1,
                        "max": 12,
                        "value": 3,
                        "step": 1,
                        "stepType": "value",
                        "disabled": false,
                        "readOnly": false
                    },
                    "props": {
                        "type": "SliderProperties",
                        "metaData": {
                            "schemaVersion": "1.0",
                            "autoGenerated": true
                        },
                        "trackSize": 9,
                        "orientation": "horizontal",
                        "thumbShape": "circle",
                        "tickMarksShape": "dot",
                        "tooltipVisibility": "hover",
                        "tooltipPosition": "normal",
                        "tooltipPrefix": "",
                        "tooltipSuffix": " Bedrooms",
                        "tickMarksPosition": "middle",
                        "dir": "ltr"
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-tooltipBorderColor": "1",
                                "alpha-trackBackgroundHover": "1",
                                "alpha-trackBorderColorHover": "1",
                                "alpha-trackFill": "1",
                                "alpha-trackFillHover": "1",
                                "boxShadowToggleOn-thumbBoxShadow": "false",
                                "boxShadowToggleOn-tooltipBoxShadow": "false",
                                "boxShadowToggleOn-trackBoxShadow": "false",
                                "thumbBackground": "#000000",
                                "thumbBackgroundDisabled": "#D8D8D8",
                                "thumbBackgroundFocus": "#000000",
                                "thumbBackgroundHover": "#FFFFFF",
                                "thumbBorderColor": "#414141",
                                "thumbBorderColorDisabled": "#D8D8D8",
                                "thumbBorderColorFocus": "#000000",
                                "thumbBorderColorHover": "#000000",
                                "thumbBorderWidth": "1",
                                "thumbBorderWidthDisabled": "0",
                                "thumbBorderWidthFocus": "1",
                                "thumbBorderWidthHover": "2",
                                "thumbBoxShadow": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                "tickMarkColor": "#000000",
                                "tooltipBackground": "#000000",
                                "tooltipBorderWidth": "0",
                                "tooltipBoxShadow": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                "trackBackground": "#C7C7C7",
                                "trackBackgroundDisabled": "#D8D8D8",
                                "trackBackgroundFocus": "#C7C7C7",
                                "trackBackgroundHover": "#C7C7C7",
                                "trackBorderColor": "#C7C7C7",
                                "trackBorderColorDisabled": "#D8D8D8",
                                "trackBorderColorHover": "#000000",
                                "trackBorderWidth": "0",
                                "trackBorderWidthDisabled": "1",
                                "trackBorderWidthFocus": "0",
                                "trackBorderWidthHover": "0",
                                "trackBoxShadow": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                "trackFill": "#000000",
                                "trackFillDisabled": "#D8D8D8",
                                "trackFillFocus": "#000000",
                                "trackFillHover": "#000000"
                            },
                            "propertiesSource": {
                                "alpha-tooltipBorderColor": "value",
                                "alpha-trackBackgroundHover": "value",
                                "alpha-trackBorderColorHover": "value",
                                "alpha-trackFill": "value",
                                "alpha-trackFillHover": "value",
                                "thumbBackground": "value",
                                "thumbBackgroundDisabled": "value",
                                "thumbBackgroundFocus": "value",
                                "thumbBackgroundHover": "value",
                                "thumbBorderColor": "value",
                                "thumbBorderColorDisabled": "value",
                                "thumbBorderColorFocus": "value",
                                "thumbBorderColorHover": "value",
                                "thumbBorderWidth": "value",
                                "thumbBorderWidthDisabled": "value",
                                "thumbBorderWidthFocus": "value",
                                "thumbBorderWidthHover": "value",
                                "tickMarkColor": "value",
                                "tooltipBackground": "value",
                                "tooltipBorderWidth": "value",
                                "trackBackground": "value",
                                "trackBackgroundDisabled": "value",
                                "trackBackgroundFocus": "value",
                                "trackBackgroundHover": "value",
                                "trackBorderColor": "value",
                                "trackBorderColorDisabled": "value",
                                "trackBorderColorHover": "value",
                                "trackBorderWidth": "value",
                                "trackBorderWidthDisabled": "value",
                                "trackBorderWidthFocus": "value",
                                "trackBorderWidthHover": "value",
                                "trackFill": "value",
                                "trackFillDisabled": "value",
                                "trackFillFocus": "value",
                                "trackFillHover": "value"
                            }
                        },
                        "componentClassName": "wixui.Slider",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wixui.skins.Slider"
                    },
                    "connections": {
                        "type": "ConnectionList",
                        "items": [{
                            "type": "WixCodeConnectionItem",
                            "role": "slider1"
                        }]
                    }
                },
                "activeModes": {},
                "id": "comp-jggk335j"
            },
            "preset": {
                "rect": {
                    "width": 162,
                    "height": 55,
                    "x": 0,
                    "y": 13
                }
            }
        }, {
            "id": "User_Input_Slider_2",
            "structure": {
                "type": "Component",
                "skin": "wixui.skins.Slider",
                "layout": {
                    "width": 142,
                    "height": 22,
                    "x": 172,
                    "y": 30,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wixui.Slider",
                "data": {
                    "type": "Slider",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "min": 0,
                    "max": 50,
                    "value": 10,
                    "step": 5,
                    "stepType": "value",
                    "disabled": false,
                    "readOnly": false
                },
                "props": {
                    "type": "SliderProperties",
                    "metaData": {
                        "schemaVersion": "1.0",
                        "autoGenerated": false
                    },
                    "trackSize": 14,
                    "orientation": "horizontal",
                    "thumbShape": "square",
                    "tickMarksShape": "none",
                    "tooltipVisibility": "hover",
                    "tooltipPosition": "normal",
                    "tooltipPrefix": "Up to $",
                    "tooltipSuffix": "",
                    "tickMarksPosition": "normal",
                    "dir": "ltr"
                },
                "mobileHints": {
                    "type": "MobileHints",
                    "author": "duplicate",
                    "originalCompId": "comp-jggk335j",
                    "recommendedScale": 1,
                    "recommendedWidth": 280,
                    "recommendedHeight": 6
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-thumbBackgroundDisabled": "1",
                            "alpha-thumbBorderColorHover": "1",
                            "alpha-tooltipBorderColor": "1",
                            "alpha-trackBackground": "1",
                            "alpha-trackBorderColor": "1",
                            "alpha-trackFill": "1",
                            "alpha-trackFillHover": "1",
                            "boxShadowToggleOn-thumbBoxShadow": "false",
                            "boxShadowToggleOn-tooltipBoxShadow": "false",
                            "boxShadowToggleOn-trackBoxShadow": "false",
                            "thumbBackground": "#4BDB7B",
                            "thumbBackgroundDisabled": "#FFFFFF",
                            "thumbBackgroundFocus": "#FFFFFF",
                            "thumbBackgroundHover": "#65E891",
                            "thumbBorderColor": "#414141",
                            "thumbBorderColorDisabled": "#D8D8D8",
                            "thumbBorderColorFocus": "#4BDB7B",
                            "thumbBorderColorHover": "#000000",
                            "thumbBorderRadius": "4px 4px 4px 4px",
                            "thumbBorderWidth": "0",
                            "thumbBorderWidthDisabled": "3",
                            "thumbBorderWidthFocus": "3",
                            "thumbBorderWidthHover": "0",
                            "thumbBoxShadow": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                            "tickMarkColor": "#4BDB7B",
                            "tooltipBackground": "#4BDB7B",
                            "tooltipBorderRadius": "6px 6px 6px 6px",
                            "tooltipBorderWidth": "0",
                            "tooltipBoxShadow": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                            "trackBackground": "#D2F6DE",
                            "trackBackgroundDisabled": "#D8D8D8",
                            "trackBackgroundFocus": "#D2F6DE",
                            "trackBackgroundHover": "#D2F6DE",
                            "trackBorderColor": "#C7C7C7",
                            "trackBorderColorDisabled": "#D8D8D8",
                            "trackBorderColorHover": "#C7C7C7",
                            "trackBorderWidth": "0",
                            "trackBorderWidthDisabled": "0",
                            "trackBorderWidthFocus": "0",
                            "trackBorderWidthHover": "0",
                            "trackBoxShadow": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                            "trackFill": "#4BDB7B",
                            "trackFillDisabled": "#D8D8D8",
                            "trackFillFocus": "#4BDB7B",
                            "trackFillHover": "#65E891"
                        },
                        "propertiesSource": {
                            "alpha-thumbBackgroundDisabled": "value",
                            "alpha-thumbBorderColorHover": "value",
                            "alpha-tooltipBorderColor": "value",
                            "alpha-trackBackground": "value",
                            "alpha-trackBorderColor": "value",
                            "alpha-trackFill": "value",
                            "alpha-trackFillHover": "value",
                            "thumbBackground": "value",
                            "thumbBackgroundDisabled": "value",
                            "thumbBackgroundFocus": "value",
                            "thumbBackgroundHover": "value",
                            "thumbBorderColor": "value",
                            "thumbBorderColorDisabled": "value",
                            "thumbBorderColorFocus": "value",
                            "thumbBorderColorHover": "value",
                            "thumbBorderRadius": "value",
                            "thumbBorderWidth": "value",
                            "thumbBorderWidthDisabled": "value",
                            "thumbBorderWidthFocus": "value",
                            "thumbBorderWidthHover": "value",
                            "tickMarkColor": "value",
                            "tooltipBackground": "value",
                            "tooltipBorderRadius": "value",
                            "tooltipBorderWidth": "value",
                            "trackBackground": "value",
                            "trackBackgroundDisabled": "value",
                            "trackBackgroundFocus": "value",
                            "trackBackgroundHover": "value",
                            "trackBorderColor": "value",
                            "trackBorderColorDisabled": "value",
                            "trackBorderColorHover": "value",
                            "trackBorderWidth": "value",
                            "trackBorderWidthDisabled": "value",
                            "trackBorderWidthFocus": "value",
                            "trackBorderWidthHover": "value",
                            "trackFill": "value",
                            "trackFillDisabled": "value",
                            "trackFillFocus": "value",
                            "trackFillHover": "value"
                        }
                    },
                    "componentClassName": "wixui.Slider",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wixui.skins.Slider"
                },
                "mobileStructure": {
                    "type": "Component",
                    "skin": "wixui.skins.Slider",
                    "layout": {
                        "width": 142,
                        "height": 22,
                        "x": 10,
                        "y": 17,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wixui.Slider",
                    "data": {
                        "type": "Slider",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "min": 0,
                        "max": 50,
                        "value": 10,
                        "step": 5,
                        "stepType": "value",
                        "disabled": false,
                        "readOnly": false
                    },
                    "props": {
                        "type": "SliderProperties",
                        "metaData": {
                            "schemaVersion": "1.0",
                            "autoGenerated": true
                        },
                        "trackSize": 9,
                        "orientation": "horizontal",
                        "thumbShape": "circle",
                        "tickMarksShape": "dot",
                        "tooltipVisibility": "hover",
                        "tooltipPosition": "normal",
                        "tooltipPrefix": "",
                        "tooltipSuffix": " Bedrooms",
                        "tickMarksPosition": "middle",
                        "dir": "ltr"
                    },
                    "mobileHints": {
                        "type": "MobileHints",
                        "author": "duplicate",
                        "originalCompId": "comp-jggk335j",
                        "recommendedScale": 1,
                        "recommendedWidth": 280,
                        "recommendedHeight": 6
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-thumbBackgroundDisabled": "1",
                                "alpha-thumbBorderColorHover": "1",
                                "alpha-tooltipBorderColor": "1",
                                "alpha-trackBackground": "1",
                                "alpha-trackBorderColor": "1",
                                "alpha-trackFill": "1",
                                "alpha-trackFillHover": "1",
                                "boxShadowToggleOn-thumbBoxShadow": "false",
                                "boxShadowToggleOn-tooltipBoxShadow": "false",
                                "boxShadowToggleOn-trackBoxShadow": "false",
                                "thumbBackground": "#4BDB7B",
                                "thumbBackgroundDisabled": "#FFFFFF",
                                "thumbBackgroundFocus": "#FFFFFF",
                                "thumbBackgroundHover": "#65E891",
                                "thumbBorderColor": "#414141",
                                "thumbBorderColorDisabled": "#D8D8D8",
                                "thumbBorderColorFocus": "#4BDB7B",
                                "thumbBorderColorHover": "#000000",
                                "thumbBorderRadius": "4px 4px 4px 4px",
                                "thumbBorderWidth": "0",
                                "thumbBorderWidthDisabled": "3",
                                "thumbBorderWidthFocus": "3",
                                "thumbBorderWidthHover": "0",
                                "thumbBoxShadow": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                "tickMarkColor": "#4BDB7B",
                                "tooltipBackground": "#4BDB7B",
                                "tooltipBorderRadius": "6px 6px 6px 6px",
                                "tooltipBorderWidth": "0",
                                "tooltipBoxShadow": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                "trackBackground": "#D2F6DE",
                                "trackBackgroundDisabled": "#D8D8D8",
                                "trackBackgroundFocus": "#D2F6DE",
                                "trackBackgroundHover": "#D2F6DE",
                                "trackBorderColor": "#C7C7C7",
                                "trackBorderColorDisabled": "#D8D8D8",
                                "trackBorderColorHover": "#C7C7C7",
                                "trackBorderWidth": "0",
                                "trackBorderWidthDisabled": "0",
                                "trackBorderWidthFocus": "0",
                                "trackBorderWidthHover": "0",
                                "trackBoxShadow": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                "trackFill": "#4BDB7B",
                                "trackFillDisabled": "#D8D8D8",
                                "trackFillFocus": "#4BDB7B",
                                "trackFillHover": "#65E891"
                            },
                            "propertiesSource": {
                                "alpha-thumbBackgroundDisabled": "value",
                                "alpha-thumbBorderColorHover": "value",
                                "alpha-tooltipBorderColor": "value",
                                "alpha-trackBackground": "value",
                                "alpha-trackBorderColor": "value",
                                "alpha-trackFill": "value",
                                "alpha-trackFillHover": "value",
                                "thumbBackground": "value",
                                "thumbBackgroundDisabled": "value",
                                "thumbBackgroundFocus": "value",
                                "thumbBackgroundHover": "value",
                                "thumbBorderColor": "value",
                                "thumbBorderColorDisabled": "value",
                                "thumbBorderColorFocus": "value",
                                "thumbBorderColorHover": "value",
                                "thumbBorderRadius": "value",
                                "thumbBorderWidth": "value",
                                "thumbBorderWidthDisabled": "value",
                                "thumbBorderWidthFocus": "value",
                                "thumbBorderWidthHover": "value",
                                "tickMarkColor": "value",
                                "tooltipBackground": "value",
                                "tooltipBorderRadius": "value",
                                "tooltipBorderWidth": "value",
                                "trackBackground": "value",
                                "trackBackgroundDisabled": "value",
                                "trackBackgroundFocus": "value",
                                "trackBackgroundHover": "value",
                                "trackBorderColor": "value",
                                "trackBorderColorDisabled": "value",
                                "trackBorderColorHover": "value",
                                "trackBorderWidth": "value",
                                "trackBorderWidthDisabled": "value",
                                "trackBorderWidthFocus": "value",
                                "trackBorderWidthHover": "value",
                                "trackFill": "value",
                                "trackFillDisabled": "value",
                                "trackFillFocus": "value",
                                "trackFillHover": "value"
                            }
                        },
                        "componentClassName": "wixui.Slider",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wixui.skins.Slider"
                    },
                    "connections": {
                        "type": "ConnectionList",
                        "items": [{
                            "type": "WixCodeConnectionItem",
                            "role": "slider2"
                        }]
                    }
                },
                "activeModes": {},
                "id": "comp-jgknm61s"
            },
            "preset": {
                "rect": {
                    "width": 162,
                    "height": 55,
                    "x": 162,
                    "y": 13
                }
            }
        }, {
            "id": "User_Input_Slider_3",
            "structure": {
                "type": "Component",
                "skin": "wixui.skins.Slider",
                "layout": {
                    "width": 142,
                    "height": 22,
                    "x": 10,
                    "y": 85,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wixui.Slider",
                "data": {
                    "type": "Slider",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "min": 0,
                    "max": 100,
                    "value": 20,
                    "step": 5,
                    "stepType": "value",
                    "disabled": false,
                    "readOnly": false
                },
                "props": {
                    "type": "SliderProperties",
                    "metaData": {
                        "schemaVersion": "1.0",
                        "autoGenerated": false
                    },
                    "trackSize": 100,
                    "orientation": "horizontal",
                    "thumbShape": "circle",
                    "tickMarksShape": "none",
                    "tooltipVisibility": "hover",
                    "tooltipPosition": "normal",
                    "tooltipPrefix": "",
                    "tooltipSuffix": "%",
                    "tickMarksPosition": "across",
                    "dir": "ltr"
                },
                "mobileHints": {
                    "type": "MobileHints",
                    "author": "duplicate",
                    "originalCompId": "comp-jggk335j",
                    "recommendedScale": 1,
                    "recommendedWidth": 280,
                    "recommendedHeight": 6
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-thumbBackgroundHover": "1",
                            "alpha-tooltipBackground": "0",
                            "alpha-tooltipBorderColor": "1",
                            "alpha-trackBackground": "1",
                            "alpha-trackFill": "1",
                            "alpha-trackFillHover": "1",
                            "boxShadowToggleOn-thumbBoxShadow": "false",
                            "boxShadowToggleOn-tooltipBoxShadow": "false",
                            "boxShadowToggleOn-trackBoxShadow": "false",
                            "thumbBackground": "#FFFFFF",
                            "thumbBackgroundDisabled": "#FFFFFF",
                            "thumbBackgroundFocus": "#FFFFFF",
                            "thumbBackgroundHover": "#FFFFFF",
                            "thumbBorderColor": "#8C84FA",
                            "thumbBorderColorDisabled": "#D8D8D8",
                            "thumbBorderColorFocus": "#6055E6",
                            "thumbBorderColorHover": "#7D74F2",
                            "thumbBorderRadius": "4px 4px 4px 4px",
                            "thumbBorderWidth": "1",
                            "thumbBorderWidthDisabled": "1",
                            "thumbBorderWidthFocus": "1",
                            "thumbBorderWidthHover": "1",
                            "thumbBoxShadow": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                            "tickMarkColor": "#7D74F2",
                            "tooltipBackground": "#8C84FA",
                            "tooltipBorderColor": "#FFFFFF",
                            "tooltipBorderRadius": "0px 0px 0px 0px",
                            "tooltipBorderWidth": "0",
                            "tooltipBoxShadow": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                            "tooltipFontColor": "#7D74F2",
                            "tooltipFontFamily": "futura-lt-w01-light",
                            "trackBackground": "#FFFFFF",
                            "trackBackgroundDisabled": "#FFFFFF",
                            "trackBackgroundFocus": "#FEFAFF",
                            "trackBackgroundHover": "#FEFAFF",
                            "trackBorderColor": "#8C84FA",
                            "trackBorderColorDisabled": "#D8D8D8",
                            "trackBorderColorFocus": "#6055E6",
                            "trackBorderColorHover": "#7D74F2",
                            "trackBorderRadius": "12px 12px 12px 12px",
                            "trackBorderWidth": "1",
                            "trackBorderWidthDisabled": "1",
                            "trackBorderWidthFocus": "1",
                            "trackBorderWidthHover": "1",
                            "trackBoxShadow": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                            "trackFill": "#8C84FA",
                            "trackFillDisabled": "#D8D8D8",
                            "trackFillFocus": "#6055E6",
                            "trackFillHover": "#7D74F2"
                        },
                        "propertiesSource": {
                            "alpha-thumbBackgroundHover": "value",
                            "alpha-tooltipBackground": "value",
                            "alpha-tooltipBorderColor": "value",
                            "alpha-trackBackground": "value",
                            "alpha-trackFill": "value",
                            "alpha-trackFillHover": "value",
                            "thumbBackground": "value",
                            "thumbBackgroundDisabled": "value",
                            "thumbBackgroundFocus": "value",
                            "thumbBackgroundHover": "value",
                            "thumbBorderColor": "value",
                            "thumbBorderColorDisabled": "value",
                            "thumbBorderColorFocus": "value",
                            "thumbBorderColorHover": "value",
                            "thumbBorderRadius": "value",
                            "thumbBorderWidth": "value",
                            "thumbBorderWidthDisabled": "value",
                            "thumbBorderWidthFocus": "value",
                            "thumbBorderWidthHover": "value",
                            "tickMarkColor": "value",
                            "tooltipBackground": "value",
                            "tooltipBorderColor": "value",
                            "tooltipBorderRadius": "value",
                            "tooltipBorderWidth": "value",
                            "tooltipFontColor": "value",
                            "tooltipFontFamily": "value",
                            "trackBackground": "value",
                            "trackBackgroundDisabled": "value",
                            "trackBackgroundFocus": "value",
                            "trackBackgroundHover": "value",
                            "trackBorderColor": "value",
                            "trackBorderColorDisabled": "value",
                            "trackBorderColorFocus": "value",
                            "trackBorderColorHover": "value",
                            "trackBorderRadius": "value",
                            "trackBorderWidth": "value",
                            "trackBorderWidthDisabled": "value",
                            "trackBorderWidthFocus": "value",
                            "trackBorderWidthHover": "value",
                            "trackFill": "value",
                            "trackFillDisabled": "value",
                            "trackFillFocus": "value",
                            "trackFillHover": "value"
                        }
                    },
                    "componentClassName": "wixui.Slider",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wixui.skins.Slider"
                },
                "mobileStructure": {
                    "type": "Component",
                    "skin": "wixui.skins.Slider",
                    "layout": {
                        "width": 142,
                        "height": 22,
                        "x": 10,
                        "y": 17,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wixui.Slider",
                    "data": {
                        "type": "Slider",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "min": 0,
                        "max": 100,
                        "value": 20,
                        "step": 5,
                        "stepType": "value",
                        "disabled": false,
                        "readOnly": false
                    },
                    "props": {
                        "type": "SliderProperties",
                        "metaData": {
                            "schemaVersion": "1.0",
                            "autoGenerated": true
                        },
                        "trackSize": 9,
                        "orientation": "horizontal",
                        "thumbShape": "circle",
                        "tickMarksShape": "dot",
                        "tooltipVisibility": "hover",
                        "tooltipPosition": "normal",
                        "tooltipPrefix": "",
                        "tooltipSuffix": " Bedrooms",
                        "tickMarksPosition": "middle",
                        "dir": "ltr"
                    },
                    "mobileHints": {
                        "type": "MobileHints",
                        "author": "duplicate",
                        "originalCompId": "comp-jggk335j",
                        "recommendedScale": 1,
                        "recommendedWidth": 280,
                        "recommendedHeight": 6
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-thumbBackgroundHover": "1",
                                "alpha-tooltipBackground": "0",
                                "alpha-tooltipBorderColor": "1",
                                "alpha-trackBackground": "1",
                                "alpha-trackFill": "1",
                                "alpha-trackFillHover": "1",
                                "boxShadowToggleOn-thumbBoxShadow": "false",
                                "boxShadowToggleOn-tooltipBoxShadow": "false",
                                "boxShadowToggleOn-trackBoxShadow": "false",
                                "thumbBackground": "#FFFFFF",
                                "thumbBackgroundDisabled": "#FFFFFF",
                                "thumbBackgroundFocus": "#FFFFFF",
                                "thumbBackgroundHover": "#FFFFFF",
                                "thumbBorderColor": "#8C84FA",
                                "thumbBorderColorDisabled": "#D8D8D8",
                                "thumbBorderColorFocus": "#6055E6",
                                "thumbBorderColorHover": "#7D74F2",
                                "thumbBorderRadius": "4px 4px 4px 4px",
                                "thumbBorderWidth": "1",
                                "thumbBorderWidthDisabled": "1",
                                "thumbBorderWidthFocus": "1",
                                "thumbBorderWidthHover": "1",
                                "thumbBoxShadow": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                "tickMarkColor": "#7D74F2",
                                "tooltipBackground": "#8C84FA",
                                "tooltipBorderColor": "#FFFFFF",
                                "tooltipBorderRadius": "0px 0px 0px 0px",
                                "tooltipBorderWidth": "0",
                                "tooltipBoxShadow": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                "tooltipFontColor": "#7D74F2",
                                "tooltipFontFamily": "futura-lt-w01-light",
                                "trackBackground": "#FFFFFF",
                                "trackBackgroundDisabled": "#FFFFFF",
                                "trackBackgroundFocus": "#FEFAFF",
                                "trackBackgroundHover": "#FEFAFF",
                                "trackBorderColor": "#8C84FA",
                                "trackBorderColorDisabled": "#D8D8D8",
                                "trackBorderColorFocus": "#6055E6",
                                "trackBorderColorHover": "#7D74F2",
                                "trackBorderRadius": "12px 12px 12px 12px",
                                "trackBorderWidth": "1",
                                "trackBorderWidthDisabled": "1",
                                "trackBorderWidthFocus": "1",
                                "trackBorderWidthHover": "1",
                                "trackBoxShadow": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                "trackFill": "#8C84FA",
                                "trackFillDisabled": "#D8D8D8",
                                "trackFillFocus": "#6055E6",
                                "trackFillHover": "#7D74F2"
                            },
                            "propertiesSource": {
                                "alpha-thumbBackgroundHover": "value",
                                "alpha-tooltipBackground": "value",
                                "alpha-tooltipBorderColor": "value",
                                "alpha-trackBackground": "value",
                                "alpha-trackFill": "value",
                                "alpha-trackFillHover": "value",
                                "thumbBackground": "value",
                                "thumbBackgroundDisabled": "value",
                                "thumbBackgroundFocus": "value",
                                "thumbBackgroundHover": "value",
                                "thumbBorderColor": "value",
                                "thumbBorderColorDisabled": "value",
                                "thumbBorderColorFocus": "value",
                                "thumbBorderColorHover": "value",
                                "thumbBorderRadius": "value",
                                "thumbBorderWidth": "value",
                                "thumbBorderWidthDisabled": "value",
                                "thumbBorderWidthFocus": "value",
                                "thumbBorderWidthHover": "value",
                                "tickMarkColor": "value",
                                "tooltipBackground": "value",
                                "tooltipBorderColor": "value",
                                "tooltipBorderRadius": "value",
                                "tooltipBorderWidth": "value",
                                "tooltipFontColor": "value",
                                "tooltipFontFamily": "value",
                                "trackBackground": "value",
                                "trackBackgroundDisabled": "value",
                                "trackBackgroundFocus": "value",
                                "trackBackgroundHover": "value",
                                "trackBorderColor": "value",
                                "trackBorderColorDisabled": "value",
                                "trackBorderColorFocus": "value",
                                "trackBorderColorHover": "value",
                                "trackBorderRadius": "value",
                                "trackBorderWidth": "value",
                                "trackBorderWidthDisabled": "value",
                                "trackBorderWidthFocus": "value",
                                "trackBorderWidthHover": "value",
                                "trackFill": "value",
                                "trackFillDisabled": "value",
                                "trackFillFocus": "value",
                                "trackFillHover": "value"
                            }
                        },
                        "componentClassName": "wixui.Slider",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wixui.skins.Slider"
                    },
                    "connections": {
                        "type": "ConnectionList",
                        "items": [{
                            "type": "WixCodeConnectionItem",
                            "role": "slider3"
                        }]
                    }
                },
                "activeModes": {},
                "id": "comp-jgkntrma"
            },
            "preset": {
                "rect": {
                    "width": 162,
                    "height": 55,
                    "x": 0,
                    "y": 68
                }
            }
        }, {
            "id": "User_Input_Slider_4",
            "structure": {
                "type": "Component",
                "skin": "wixui.skins.Slider",
                "layout": {
                    "width": 142,
                    "height": 24,
                    "x": 172,
                    "y": 85,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wixui.Slider",
                "data": {
                    "type": "Slider",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "min": 0,
                    "max": 15,
                    "value": 3,
                    "step": 5,
                    "stepType": "count",
                    "disabled": false,
                    "readOnly": false
                },
                "props": {
                    "type": "SliderProperties",
                    "metaData": {
                        "schemaVersion": "1.0",
                        "autoGenerated": false
                    },
                    "trackSize": 30,
                    "orientation": "horizontal",
                    "thumbShape": "circle",
                    "tickMarksShape": "none",
                    "tooltipVisibility": "hover",
                    "tooltipPosition": "normal",
                    "tooltipPrefix": "$",
                    "tooltipSuffix": " per month",
                    "tickMarksPosition": "normal",
                    "dir": "ltr"
                },
                "mobileHints": {
                    "type": "MobileHints",
                    "author": "duplicate",
                    "originalCompId": "comp-jggk335j",
                    "recommendedScale": 1,
                    "recommendedWidth": 280,
                    "recommendedHeight": 6
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-tooltipBackground": "1",
                            "alpha-tooltipBorderColor": "1",
                            "alpha-trackBackground": "1",
                            "alpha-trackFill": "1",
                            "alpha-trackFillHover": "1",
                            "boxShadowToggleOn-thumbBoxShadow": "false",
                            "boxShadowToggleOn-tooltipBoxShadow": "false",
                            "boxShadowToggleOn-trackBoxShadow": "false",
                            "thumbBackground": "#00A6FF",
                            "thumbBackgroundDisabled": "#FFFFFF",
                            "thumbBackgroundFocus": "#FFFFFF",
                            "thumbBackgroundHover": "#EAF7FF",
                            "thumbBorderColor": "#00A6FF",
                            "thumbBorderColorDisabled": "#D8D8D8",
                            "thumbBorderColorFocus": "#00A6FF",
                            "thumbBorderColorHover": "#4EB7F5",
                            "thumbBorderRadius": "4px 4px 4px 4px",
                            "thumbBorderWidth": "0",
                            "thumbBorderWidthDisabled": "1",
                            "thumbBorderWidthFocus": "1",
                            "thumbBorderWidthHover": "1",
                            "thumbBoxShadow": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                            "tooltipBackground": "#00A6FF",
                            "tooltipBorderRadius": "6px 6px 6px 6px",
                            "tooltipBorderWidth": "0",
                            "tooltipBoxShadow": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                            "tooltipFontColor": "#FFFFFF",
                            "tooltipFontFamily": "helvetica-w01-light",
                            "trackBackground": "#EAF7FF",
                            "trackBackgroundDisabled": "#D8D8D8",
                            "trackBackgroundFocus": "#FFFFFF",
                            "trackBackgroundHover": "#EAF7FF",
                            "trackBorderColor": "#00A6FF",
                            "trackBorderColorDisabled": "#D8D8D8",
                            "trackBorderColorFocus": "#00A6FF",
                            "trackBorderColorHover": "#4EB7F5",
                            "trackBorderRadius": "12px 12px 12px 12px",
                            "trackBorderWidth": "0",
                            "trackBorderWidthDisabled": "0",
                            "trackBorderWidthFocus": "1",
                            "trackBorderWidthHover": "1",
                            "trackBoxShadow": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                            "trackFill": "#00A6FF",
                            "trackFillDisabled": "#D8D8D8",
                            "trackFillFocus": "#00A6FF",
                            "trackFillHover": "#4EB7F5"
                        },
                        "propertiesSource": {
                            "alpha-tooltipBackground": "value",
                            "alpha-tooltipBorderColor": "value",
                            "alpha-trackBackground": "value",
                            "alpha-trackFill": "value",
                            "alpha-trackFillHover": "value",
                            "thumbBackground": "value",
                            "thumbBackgroundDisabled": "value",
                            "thumbBackgroundFocus": "value",
                            "thumbBackgroundHover": "value",
                            "thumbBorderColor": "value",
                            "thumbBorderColorDisabled": "value",
                            "thumbBorderColorFocus": "value",
                            "thumbBorderColorHover": "value",
                            "thumbBorderRadius": "value",
                            "thumbBorderWidth": "value",
                            "thumbBorderWidthDisabled": "value",
                            "thumbBorderWidthFocus": "value",
                            "thumbBorderWidthHover": "value",
                            "tooltipBackground": "value",
                            "tooltipBorderRadius": "value",
                            "tooltipBorderWidth": "value",
                            "tooltipFontColor": "value",
                            "tooltipFontFamily": "value",
                            "trackBackground": "value",
                            "trackBackgroundDisabled": "value",
                            "trackBackgroundFocus": "value",
                            "trackBackgroundHover": "value",
                            "trackBorderColor": "value",
                            "trackBorderColorDisabled": "value",
                            "trackBorderColorFocus": "value",
                            "trackBorderColorHover": "value",
                            "trackBorderRadius": "value",
                            "trackBorderWidth": "value",
                            "trackBorderWidthDisabled": "value",
                            "trackBorderWidthFocus": "value",
                            "trackBorderWidthHover": "value",
                            "trackFill": "value",
                            "trackFillDisabled": "value",
                            "trackFillFocus": "value",
                            "trackFillHover": "value"
                        }
                    },
                    "componentClassName": "wixui.Slider",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wixui.skins.Slider"
                },
                "mobileStructure": {
                    "type": "Component",
                    "skin": "wixui.skins.Slider",
                    "layout": {
                        "width": 123,
                        "height": 21,
                        "x": 19,
                        "y": 14,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wixui.Slider",
                    "data": {
                        "type": "Slider",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "min": 0,
                        "max": 15,
                        "value": 3,
                        "step": 5,
                        "stepType": "count",
                        "disabled": false,
                        "readOnly": false
                    },
                    "props": {
                        "type": "SliderProperties",
                        "metaData": {
                            "schemaVersion": "1.0",
                            "autoGenerated": true
                        },
                        "trackSize": 30,
                        "orientation": "horizontal",
                        "thumbShape": "circle",
                        "tickMarksShape": "line",
                        "tooltipVisibility": "hover",
                        "tooltipPosition": "normal",
                        "tooltipPrefix": "$",
                        "tooltipSuffix": " per month",
                        "tickMarksPosition": "normal",
                        "dir": "ltr"
                    },
                    "mobileHints": {
                        "type": "MobileHints",
                        "author": "duplicate",
                        "originalCompId": "comp-jggk335j",
                        "recommendedScale": 1,
                        "recommendedWidth": 280,
                        "recommendedHeight": 6
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-tooltipBackground": "1",
                                "alpha-tooltipBorderColor": "1",
                                "alpha-trackBackground": "1",
                                "alpha-trackFill": "1",
                                "alpha-trackFillHover": "1",
                                "boxShadowToggleOn-thumbBoxShadow": "false",
                                "boxShadowToggleOn-tooltipBoxShadow": "false",
                                "boxShadowToggleOn-trackBoxShadow": "false",
                                "thumbBackground": "#00A6FF",
                                "thumbBackgroundDisabled": "#FFFFFF",
                                "thumbBackgroundFocus": "#FFFFFF",
                                "thumbBackgroundHover": "#EAF7FF",
                                "thumbBorderColor": "#00A6FF",
                                "thumbBorderColorDisabled": "#D8D8D8",
                                "thumbBorderColorFocus": "#00A6FF",
                                "thumbBorderColorHover": "#4EB7F5",
                                "thumbBorderRadius": "4px 4px 4px 4px",
                                "thumbBorderWidth": "0",
                                "thumbBorderWidthDisabled": "1",
                                "thumbBorderWidthFocus": "1",
                                "thumbBorderWidthHover": "1",
                                "thumbBoxShadow": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                "tooltipBackground": "#00A6FF",
                                "tooltipBorderRadius": "6px 6px 6px 6px",
                                "tooltipBorderWidth": "0",
                                "tooltipBoxShadow": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                "tooltipFontColor": "#FFFFFF",
                                "tooltipFontFamily": "helvetica-w01-light",
                                "trackBackground": "#EAF7FF",
                                "trackBackgroundDisabled": "#D8D8D8",
                                "trackBackgroundFocus": "#FFFFFF",
                                "trackBackgroundHover": "#EAF7FF",
                                "trackBorderColor": "#00A6FF",
                                "trackBorderColorDisabled": "#D8D8D8",
                                "trackBorderColorFocus": "#00A6FF",
                                "trackBorderColorHover": "#4EB7F5",
                                "trackBorderRadius": "12px 12px 12px 12px",
                                "trackBorderWidth": "0",
                                "trackBorderWidthDisabled": "0",
                                "trackBorderWidthFocus": "1",
                                "trackBorderWidthHover": "1",
                                "trackBoxShadow": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                "trackFill": "#00A6FF",
                                "trackFillDisabled": "#D8D8D8",
                                "trackFillFocus": "#00A6FF",
                                "trackFillHover": "#4EB7F5"
                            },
                            "propertiesSource": {
                                "alpha-tooltipBackground": "value",
                                "alpha-tooltipBorderColor": "value",
                                "alpha-trackBackground": "value",
                                "alpha-trackFill": "value",
                                "alpha-trackFillHover": "value",
                                "thumbBackground": "value",
                                "thumbBackgroundDisabled": "value",
                                "thumbBackgroundFocus": "value",
                                "thumbBackgroundHover": "value",
                                "thumbBorderColor": "value",
                                "thumbBorderColorDisabled": "value",
                                "thumbBorderColorFocus": "value",
                                "thumbBorderColorHover": "value",
                                "thumbBorderRadius": "value",
                                "thumbBorderWidth": "value",
                                "thumbBorderWidthDisabled": "value",
                                "thumbBorderWidthFocus": "value",
                                "thumbBorderWidthHover": "value",
                                "tooltipBackground": "value",
                                "tooltipBorderRadius": "value",
                                "tooltipBorderWidth": "value",
                                "tooltipFontColor": "value",
                                "tooltipFontFamily": "value",
                                "trackBackground": "value",
                                "trackBackgroundDisabled": "value",
                                "trackBackgroundFocus": "value",
                                "trackBackgroundHover": "value",
                                "trackBorderColor": "value",
                                "trackBorderColorDisabled": "value",
                                "trackBorderColorFocus": "value",
                                "trackBorderColorHover": "value",
                                "trackBorderRadius": "value",
                                "trackBorderWidth": "value",
                                "trackBorderWidthDisabled": "value",
                                "trackBorderWidthFocus": "value",
                                "trackBorderWidthHover": "value",
                                "trackFill": "value",
                                "trackFillDisabled": "value",
                                "trackFillFocus": "value",
                                "trackFillHover": "value"
                            }
                        },
                        "componentClassName": "wixui.Slider",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wixui.skins.Slider"
                    },
                    "connections": {
                        "type": "ConnectionList",
                        "items": [{
                            "type": "WixCodeConnectionItem",
                            "role": "slider6"
                        }]
                    }
                },
                "activeModes": {},
                "id": "comp-jgkv3mib"
            },
            "preset": {
                "rect": {
                    "width": 162,
                    "height": 55,
                    "x": 162,
                    "y": 68
                }
            }
        }, {
            "id": "User_Input_Slider_5",
            "structure": {
                "type": "Component",
                "skin": "wixui.skins.Slider",
                "layout": {
                    "width": 142,
                    "height": 24,
                    "x": 10,
                    "y": 137,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wixui.Slider",
                "data": {
                    "type": "Slider",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "min": 0,
                    "max": 50,
                    "value": 10,
                    "step": 5,
                    "stepType": "value",
                    "disabled": false,
                    "readOnly": false
                },
                "props": {
                    "type": "SliderProperties",
                    "metaData": {
                        "schemaVersion": "1.0",
                        "autoGenerated": false
                    },
                    "trackSize": 15,
                    "orientation": "horizontal",
                    "thumbShape": "circle",
                    "tickMarksShape": "none",
                    "tooltipVisibility": "hover",
                    "tooltipPosition": "normal",
                    "tooltipPrefix": "",
                    "tooltipSuffix": " miles",
                    "tickMarksPosition": "normal",
                    "dir": "ltr"
                },
                "mobileHints": {
                    "type": "MobileHints",
                    "author": "duplicate",
                    "originalCompId": "comp-jggk335j",
                    "recommendedScale": 1,
                    "recommendedWidth": 280,
                    "recommendedHeight": 6
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-thumbBorderColor": "1",
                            "alpha-thumbBorderColorFocus": "1",
                            "alpha-tooltipBackground": "0",
                            "alpha-tooltipBorderColor": "1",
                            "alpha-trackFill": "1",
                            "alpha-trackFillFocus": "1",
                            "alpha-trackFillHover": "1",
                            "boxShadowToggleOn-thumbBoxShadow": "true",
                            "boxShadowToggleOn-tooltipBoxShadow": "false",
                            "boxShadowToggleOn-trackBoxShadow": "false",
                            "thumbBackground": "#49D6D6",
                            "thumbBackgroundDisabled": "#D8D8D8",
                            "thumbBackgroundFocus": "#49D6D6",
                            "thumbBackgroundHover": "#49D6D6",
                            "thumbBorderColor": "#FFFFFF",
                            "thumbBorderColorDisabled": "#D8D8D8",
                            "thumbBorderColorFocus": "#49D6D6",
                            "thumbBorderColorHover": "#49D6D6",
                            "thumbBorderRadius": "2px 2px 2px 2px",
                            "thumbBorderWidth": "3",
                            "thumbBorderWidthDisabled": "0",
                            "thumbBorderWidthFocus": "3",
                            "thumbBorderWidthHover": "3",
                            "thumbBoxShadow": "0.00px 0.00px 0px 2px rgba(73,214,214,1)",
                            "tickMarkColor": "#49D6D6",
                            "tooltipBackground": "#FFFFFF",
                            "tooltipBorderWidth": "0",
                            "tooltipBoxShadow": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                            "tooltipFontColor": "#49D6D6",
                            "tooltipFontFamily": "georgia",
                            "tooltipFontSize": "14",
                            "trackBackground": "#D8D8D8",
                            "trackBackgroundDisabled": "#D8D8D8",
                            "trackBackgroundFocus": "#D8D8D8",
                            "trackBackgroundHover": "#D8D8D8",
                            "trackBorderColor": "#D8D8D8",
                            "trackBorderColorDisabled": "#D8D8D8",
                            "trackBorderColorFocus": "#58BAB6",
                            "trackBorderColorHover": "#D8D8D8",
                            "trackBorderRadius": "2px 2px 2px 2px",
                            "trackBorderWidth": "0",
                            "trackBorderWidthDisabled": "0",
                            "trackBorderWidthFocus": "0",
                            "trackBorderWidthHover": "0",
                            "trackBoxShadow": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                            "trackFill": "#49D6D6",
                            "trackFillDisabled": "#D8D8D8",
                            "trackFillFocus": "#49D6D6",
                            "trackFillHover": "#49D6D6"
                        },
                        "propertiesSource": {
                            "alpha-thumbBorderColor": "value",
                            "alpha-thumbBorderColorFocus": "value",
                            "alpha-tooltipBackground": "value",
                            "alpha-tooltipBorderColor": "value",
                            "alpha-trackFill": "value",
                            "alpha-trackFillFocus": "value",
                            "alpha-trackFillHover": "value",
                            "thumbBackground": "value",
                            "thumbBackgroundDisabled": "value",
                            "thumbBackgroundFocus": "value",
                            "thumbBackgroundHover": "value",
                            "thumbBorderColor": "value",
                            "thumbBorderColorDisabled": "value",
                            "thumbBorderColorFocus": "value",
                            "thumbBorderColorHover": "value",
                            "thumbBorderRadius": "value",
                            "thumbBorderWidth": "value",
                            "thumbBorderWidthDisabled": "value",
                            "thumbBorderWidthFocus": "value",
                            "thumbBorderWidthHover": "value",
                            "thumbBoxShadow": "value",
                            "tickMarkColor": "value",
                            "tooltipBackground": "value",
                            "tooltipBorderWidth": "value",
                            "tooltipFontColor": "value",
                            "tooltipFontFamily": "value",
                            "tooltipFontSize": "value",
                            "trackBackground": "value",
                            "trackBackgroundDisabled": "value",
                            "trackBackgroundFocus": "value",
                            "trackBackgroundHover": "value",
                            "trackBorderColor": "value",
                            "trackBorderColorDisabled": "value",
                            "trackBorderColorFocus": "value",
                            "trackBorderColorHover": "value",
                            "trackBorderRadius": "value",
                            "trackBorderWidth": "value",
                            "trackBorderWidthDisabled": "value",
                            "trackBorderWidthFocus": "value",
                            "trackBorderWidthHover": "value",
                            "trackFill": "value",
                            "trackFillDisabled": "value",
                            "trackFillFocus": "value",
                            "trackFillHover": "value"
                        }
                    },
                    "componentClassName": "wixui.Slider",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wixui.skins.Slider"
                },
                "mobileStructure": {
                    "type": "Component",
                    "skin": "wixui.skins.Slider",
                    "layout": {
                        "width": 123,
                        "height": 21,
                        "x": 19,
                        "y": 14,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wixui.Slider",
                    "data": {
                        "type": "Slider",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "min": 0,
                        "max": 50,
                        "value": 10,
                        "step": 5,
                        "stepType": "value",
                        "disabled": false,
                        "readOnly": false
                    },
                    "props": {
                        "type": "SliderProperties",
                        "metaData": {
                            "schemaVersion": "1.0",
                            "autoGenerated": true
                        },
                        "trackSize": 15,
                        "orientation": "horizontal",
                        "thumbShape": "circle",
                        "tickMarksShape": "line",
                        "tooltipVisibility": "hover",
                        "tooltipPosition": "normal",
                        "tooltipPrefix": "",
                        "tooltipSuffix": " miles",
                        "tickMarksPosition": "normal",
                        "dir": "ltr"
                    },
                    "mobileHints": {
                        "type": "MobileHints",
                        "author": "duplicate",
                        "originalCompId": "comp-jggk335j",
                        "recommendedScale": 1,
                        "recommendedWidth": 280,
                        "recommendedHeight": 6
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-thumbBorderColor": "1",
                                "alpha-thumbBorderColorFocus": "1",
                                "alpha-tooltipBackground": "0",
                                "alpha-tooltipBorderColor": "1",
                                "alpha-trackFill": "1",
                                "alpha-trackFillFocus": "1",
                                "alpha-trackFillHover": "1",
                                "boxShadowToggleOn-thumbBoxShadow": "true",
                                "boxShadowToggleOn-tooltipBoxShadow": "false",
                                "boxShadowToggleOn-trackBoxShadow": "false",
                                "thumbBackground": "#49D6D6",
                                "thumbBackgroundDisabled": "#D8D8D8",
                                "thumbBackgroundFocus": "#49D6D6",
                                "thumbBackgroundHover": "#49D6D6",
                                "thumbBorderColor": "#FFFFFF",
                                "thumbBorderColorDisabled": "#D8D8D8",
                                "thumbBorderColorFocus": "#49D6D6",
                                "thumbBorderColorHover": "#49D6D6",
                                "thumbBorderRadius": "2px 2px 2px 2px",
                                "thumbBorderWidth": "3",
                                "thumbBorderWidthDisabled": "0",
                                "thumbBorderWidthFocus": "3",
                                "thumbBorderWidthHover": "3",
                                "thumbBoxShadow": "0.00px 0.00px 0px 2px rgba(73,214,214,1)",
                                "tickMarkColor": "#49D6D6",
                                "tooltipBackground": "#FFFFFF",
                                "tooltipBorderWidth": "0",
                                "tooltipBoxShadow": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                "tooltipFontColor": "#49D6D6",
                                "tooltipFontFamily": "georgia",
                                "tooltipFontSize": "14",
                                "trackBackground": "#D8D8D8",
                                "trackBackgroundDisabled": "#D8D8D8",
                                "trackBackgroundFocus": "#D8D8D8",
                                "trackBackgroundHover": "#D8D8D8",
                                "trackBorderColor": "#D8D8D8",
                                "trackBorderColorDisabled": "#D8D8D8",
                                "trackBorderColorFocus": "#58BAB6",
                                "trackBorderColorHover": "#D8D8D8",
                                "trackBorderRadius": "2px 2px 2px 2px",
                                "trackBorderWidth": "0",
                                "trackBorderWidthDisabled": "0",
                                "trackBorderWidthFocus": "0",
                                "trackBorderWidthHover": "0",
                                "trackBoxShadow": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                "trackFill": "#49D6D6",
                                "trackFillDisabled": "#D8D8D8",
                                "trackFillFocus": "#49D6D6",
                                "trackFillHover": "#49D6D6"
                            },
                            "propertiesSource": {
                                "alpha-thumbBorderColor": "value",
                                "alpha-thumbBorderColorFocus": "value",
                                "alpha-tooltipBackground": "value",
                                "alpha-tooltipBorderColor": "value",
                                "alpha-trackFill": "value",
                                "alpha-trackFillFocus": "value",
                                "alpha-trackFillHover": "value",
                                "thumbBackground": "value",
                                "thumbBackgroundDisabled": "value",
                                "thumbBackgroundFocus": "value",
                                "thumbBackgroundHover": "value",
                                "thumbBorderColor": "value",
                                "thumbBorderColorDisabled": "value",
                                "thumbBorderColorFocus": "value",
                                "thumbBorderColorHover": "value",
                                "thumbBorderRadius": "value",
                                "thumbBorderWidth": "value",
                                "thumbBorderWidthDisabled": "value",
                                "thumbBorderWidthFocus": "value",
                                "thumbBorderWidthHover": "value",
                                "thumbBoxShadow": "value",
                                "tickMarkColor": "value",
                                "tooltipBackground": "value",
                                "tooltipBorderWidth": "value",
                                "tooltipFontColor": "value",
                                "tooltipFontFamily": "value",
                                "tooltipFontSize": "value",
                                "trackBackground": "value",
                                "trackBackgroundDisabled": "value",
                                "trackBackgroundFocus": "value",
                                "trackBackgroundHover": "value",
                                "trackBorderColor": "value",
                                "trackBorderColorDisabled": "value",
                                "trackBorderColorFocus": "value",
                                "trackBorderColorHover": "value",
                                "trackBorderRadius": "value",
                                "trackBorderWidth": "value",
                                "trackBorderWidthDisabled": "value",
                                "trackBorderWidthFocus": "value",
                                "trackBorderWidthHover": "value",
                                "trackFill": "value",
                                "trackFillDisabled": "value",
                                "trackFillFocus": "value",
                                "trackFillHover": "value"
                            }
                        },
                        "componentClassName": "wixui.Slider",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wixui.skins.Slider"
                    },
                    "connections": {
                        "type": "ConnectionList",
                        "items": [{
                            "type": "WixCodeConnectionItem",
                            "role": "slider5"
                        }]
                    }
                },
                "activeModes": {},
                "id": "comp-jgku5571"
            },
            "preset": {
                "rect": {
                    "width": 162,
                    "height": 55,
                    "x": 0,
                    "y": 123
                }
            }
        }, {
            "id": "User_Input_Slider_6",
            "structure": {
                "type": "Component",
                "skin": "wixui.skins.Slider",
                "layout": {
                    "width": 142,
                    "height": 22,
                    "x": 172,
                    "y": 138,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wixui.Slider",
                "data": {
                    "type": "Slider",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "min": 0,
                    "max": 60,
                    "value": 15,
                    "step": 5,
                    "stepType": "value",
                    "disabled": false,
                    "readOnly": false
                },
                "props": {
                    "type": "SliderProperties",
                    "metaData": {
                        "schemaVersion": "1.0",
                        "autoGenerated": false
                    },
                    "trackSize": 9,
                    "orientation": "horizontal",
                    "thumbShape": "bar",
                    "tickMarksShape": "none",
                    "tooltipVisibility": "hover",
                    "tooltipPosition": "normal",
                    "tooltipPrefix": "",
                    "tooltipSuffix": " MINUTES",
                    "tickMarksPosition": "normal",
                    "dir": "ltr"
                },
                "mobileHints": {
                    "type": "MobileHints",
                    "author": "duplicate",
                    "originalCompId": "comp-jggk335j",
                    "recommendedScale": 1,
                    "recommendedWidth": 280,
                    "recommendedHeight": 6
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-thumbBorderColor": "1",
                            "alpha-tooltipBackground": "0",
                            "alpha-tooltipBorderColor": "1",
                            "alpha-trackFill": "1",
                            "alpha-trackFillHover": "1",
                            "boxShadowToggleOn-thumbBoxShadow": "true",
                            "boxShadowToggleOn-tooltipBoxShadow": "false",
                            "boxShadowToggleOn-trackBoxShadow": "false",
                            "thumbBackground": "#FFFFFF",
                            "thumbBackgroundDisabled": "#FFFFFF",
                            "thumbBackgroundFocus": "#FFFFFF",
                            "thumbBackgroundHover": "#FFFFFF",
                            "thumbBorderColor": "#414141",
                            "thumbBorderColorDisabled": "#D8D8D8",
                            "thumbBorderColorFocus": "#E8E8E8",
                            "thumbBorderColorHover": "#F5F5F5",
                            "thumbBorderRadius": "2px 2px 2px 2px",
                            "thumbBorderWidth": "0",
                            "thumbBorderWidthDisabled": "2",
                            "thumbBorderWidthFocus": "2",
                            "thumbBorderWidthHover": "1",
                            "thumbBoxShadow": "0.00px 3.00px 10px 1px rgba(0,0,0,0.08)",
                            "tickMarkColor": "#000000",
                            "tooltipBackground": "#000000",
                            "tooltipBorderWidth": "0",
                            "tooltipBoxShadow": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                            "tooltipFontColor": "#000000",
                            "tooltipFontFamily": "lulo-clean-w01-one-bold",
                            "trackBackground": "#D8D8D8",
                            "trackBackgroundDisabled": "#D8D8D8",
                            "trackBackgroundFocus": "#C7C7C7",
                            "trackBackgroundHover": "#C7C7C7",
                            "trackBorderColor": "#C7C7C7",
                            "trackBorderColorDisabled": "#D8D8D8",
                            "trackBorderColorHover": "#C7C7C7",
                            "trackBorderRadius": "2px 2px 2px 2px",
                            "trackBorderWidth": "0",
                            "trackBorderWidthDisabled": "0",
                            "trackBorderWidthFocus": "0",
                            "trackBorderWidthHover": "0",
                            "trackBoxShadow": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                            "trackFill": "#000000",
                            "trackFillDisabled": "#D8D8D8",
                            "trackFillFocus": "#000000",
                            "trackFillHover": "#000000"
                        },
                        "propertiesSource": {
                            "alpha-thumbBorderColor": "value",
                            "alpha-tooltipBackground": "value",
                            "alpha-tooltipBorderColor": "value",
                            "alpha-trackFill": "value",
                            "alpha-trackFillHover": "value",
                            "thumbBackground": "value",
                            "thumbBackgroundDisabled": "value",
                            "thumbBackgroundFocus": "value",
                            "thumbBackgroundHover": "value",
                            "thumbBorderColor": "value",
                            "thumbBorderColorDisabled": "value",
                            "thumbBorderColorFocus": "value",
                            "thumbBorderColorHover": "value",
                            "thumbBorderRadius": "value",
                            "thumbBorderWidth": "value",
                            "thumbBorderWidthDisabled": "value",
                            "thumbBorderWidthFocus": "value",
                            "thumbBorderWidthHover": "value",
                            "thumbBoxShadow": "value",
                            "tickMarkColor": "value",
                            "tooltipBackground": "value",
                            "tooltipBorderWidth": "value",
                            "tooltipFontColor": "value",
                            "tooltipFontFamily": "value",
                            "trackBackground": "value",
                            "trackBackgroundDisabled": "value",
                            "trackBackgroundFocus": "value",
                            "trackBackgroundHover": "value",
                            "trackBorderColor": "value",
                            "trackBorderColorDisabled": "value",
                            "trackBorderColorHover": "value",
                            "trackBorderRadius": "value",
                            "trackBorderWidth": "value",
                            "trackBorderWidthDisabled": "value",
                            "trackBorderWidthFocus": "value",
                            "trackBorderWidthHover": "value",
                            "trackFill": "value",
                            "trackFillDisabled": "value",
                            "trackFillFocus": "value",
                            "trackFillHover": "value"
                        }
                    },
                    "componentClassName": "wixui.Slider",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wixui.skins.Slider"
                },
                "mobileStructure": {
                    "type": "Component",
                    "skin": "wixui.skins.Slider",
                    "layout": {
                        "width": 142,
                        "height": 22,
                        "x": 10,
                        "y": 15,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wixui.Slider",
                    "data": {
                        "type": "Slider",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "min": 0,
                        "max": 60,
                        "value": 15,
                        "step": 5,
                        "stepType": "value",
                        "disabled": false,
                        "readOnly": false
                    },
                    "props": {
                        "type": "SliderProperties",
                        "metaData": {
                            "schemaVersion": "1.0",
                            "autoGenerated": true
                        },
                        "trackSize": 9,
                        "orientation": "horizontal",
                        "thumbShape": "circle",
                        "tickMarksShape": "dot",
                        "tooltipVisibility": "hover",
                        "tooltipPosition": "normal",
                        "tooltipPrefix": "",
                        "tooltipSuffix": " Bedrooms",
                        "tickMarksPosition": "middle",
                        "dir": "ltr"
                    },
                    "mobileHints": {
                        "type": "MobileHints",
                        "author": "duplicate",
                        "originalCompId": "comp-jggk335j",
                        "recommendedScale": 1,
                        "recommendedWidth": 280,
                        "recommendedHeight": 6
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-thumbBorderColor": "1",
                                "alpha-tooltipBackground": "0",
                                "alpha-tooltipBorderColor": "1",
                                "alpha-trackFill": "1",
                                "alpha-trackFillHover": "1",
                                "boxShadowToggleOn-thumbBoxShadow": "true",
                                "boxShadowToggleOn-tooltipBoxShadow": "false",
                                "boxShadowToggleOn-trackBoxShadow": "false",
                                "thumbBackground": "#FFFFFF",
                                "thumbBackgroundDisabled": "#FFFFFF",
                                "thumbBackgroundFocus": "#FFFFFF",
                                "thumbBackgroundHover": "#FFFFFF",
                                "thumbBorderColor": "#414141",
                                "thumbBorderColorDisabled": "#D8D8D8",
                                "thumbBorderColorFocus": "#E8E8E8",
                                "thumbBorderColorHover": "#F5F5F5",
                                "thumbBorderRadius": "2px 2px 2px 2px",
                                "thumbBorderWidth": "0",
                                "thumbBorderWidthDisabled": "2",
                                "thumbBorderWidthFocus": "2",
                                "thumbBorderWidthHover": "1",
                                "thumbBoxShadow": "0.00px 3.00px 10px 1px rgba(0,0,0,0.08)",
                                "tickMarkColor": "#000000",
                                "tooltipBackground": "#000000",
                                "tooltipBorderWidth": "0",
                                "tooltipBoxShadow": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                "tooltipFontColor": "#000000",
                                "tooltipFontFamily": "lulo-clean-w01-one-bold",
                                "trackBackground": "#D8D8D8",
                                "trackBackgroundDisabled": "#D8D8D8",
                                "trackBackgroundFocus": "#C7C7C7",
                                "trackBackgroundHover": "#C7C7C7",
                                "trackBorderColor": "#C7C7C7",
                                "trackBorderColorDisabled": "#D8D8D8",
                                "trackBorderColorHover": "#C7C7C7",
                                "trackBorderRadius": "2px 2px 2px 2px",
                                "trackBorderWidth": "0",
                                "trackBorderWidthDisabled": "0",
                                "trackBorderWidthFocus": "0",
                                "trackBorderWidthHover": "0",
                                "trackBoxShadow": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                "trackFill": "#000000",
                                "trackFillDisabled": "#D8D8D8",
                                "trackFillFocus": "#000000",
                                "trackFillHover": "#000000"
                            },
                            "propertiesSource": {
                                "alpha-thumbBorderColor": "value",
                                "alpha-tooltipBackground": "value",
                                "alpha-tooltipBorderColor": "value",
                                "alpha-trackFill": "value",
                                "alpha-trackFillHover": "value",
                                "thumbBackground": "value",
                                "thumbBackgroundDisabled": "value",
                                "thumbBackgroundFocus": "value",
                                "thumbBackgroundHover": "value",
                                "thumbBorderColor": "value",
                                "thumbBorderColorDisabled": "value",
                                "thumbBorderColorFocus": "value",
                                "thumbBorderColorHover": "value",
                                "thumbBorderRadius": "value",
                                "thumbBorderWidth": "value",
                                "thumbBorderWidthDisabled": "value",
                                "thumbBorderWidthFocus": "value",
                                "thumbBorderWidthHover": "value",
                                "thumbBoxShadow": "value",
                                "tickMarkColor": "value",
                                "tooltipBackground": "value",
                                "tooltipBorderWidth": "value",
                                "tooltipFontColor": "value",
                                "tooltipFontFamily": "value",
                                "trackBackground": "value",
                                "trackBackgroundDisabled": "value",
                                "trackBackgroundFocus": "value",
                                "trackBackgroundHover": "value",
                                "trackBorderColor": "value",
                                "trackBorderColorDisabled": "value",
                                "trackBorderColorHover": "value",
                                "trackBorderRadius": "value",
                                "trackBorderWidth": "value",
                                "trackBorderWidthDisabled": "value",
                                "trackBorderWidthFocus": "value",
                                "trackBorderWidthHover": "value",
                                "trackFill": "value",
                                "trackFillDisabled": "value",
                                "trackFillFocus": "value",
                                "trackFillHover": "value"
                            }
                        },
                        "componentClassName": "wixui.Slider",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wixui.skins.Slider"
                    },
                    "connections": {
                        "type": "ConnectionList",
                        "items": [{
                            "type": "WixCodeConnectionItem",
                            "role": "slider4"
                        }]
                    }
                },
                "activeModes": {},
                "id": "comp-jgko8szi"
            },
            "preset": {
                "rect": {
                    "width": 162,
                    "height": 55,
                    "x": 162,
                    "y": 123
                }
            }
        }],
        "compTypes": ["wixui.Slider"]
    },
    "help": {
        "hide": false,
        "text": "add_section_label_slider_tooltip_description"
    }
}
