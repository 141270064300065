'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')
const newGalleries = [{
    'id': 'Gallery_Grid_Galleries_1',
    'structure': {
        "type": "Component",
        "skin": "wysiwyg.viewer.skins.TPAMasonrySkin",
        "layout": {
            "width": 576,
            "height": 400,
            "x": -3,
            "y": 9,
            "scale": 1,
            "rotationInDegrees": 0,
            "fixedPosition": false
        },
        "componentType": "tpa.viewer.components.Masonry",
        "data": {
            "type": "ImageList",
            "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false
            },
            "items": [
                {
                    "type": "Image",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "2.0",
                        "isHidden": false
                    },
                    "title": "Add a Title",
                    "uri": "b25591_daeb574706844015abbd510c472010a8~mv2_d_3000_2000_s_2.jpg",
                    "description": "Describe your image",
                    "width": 3000,
                    "height": 2000,
                    "alt": ""
                },
                {
                    "type": "Image",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "2.0",
                        "isHidden": false
                    },
                    "title": "Add a Title",
                    "uri": "b25591_89a9544354da4226913c3727baf2a742~mv2_d_3000_1688_s_2.jpg",
                    "description": "Describe your image",
                    "width": 3000,
                    "height": 1688,
                    "alt": ""
                },
                {
                    "type": "Image",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "2.0",
                        "isHidden": false
                    },
                    "title": "Add a Title",
                    "uri": "b25591_e642e63607944440bc13a8a2ea61aefe~mv2_d_3000_1941_s_2.jpg",
                    "description": "Describe your image",
                    "width": 3000,
                    "height": 1941,
                    "alt": ""
                },
                {
                    "type": "Image",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "2.0",
                        "isHidden": false
                    },
                    "title": "Add a Title",
                    "uri": "b25591_2790fd3a23db403793dbf2c3c1c62723~mv2_d_3000_1946_s_2.jpg",
                    "description": "Describe your image",
                    "width": 3000,
                    "height": 1946,
                    "alt": ""
                },
                {
                    "type": "Image",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "2.0",
                        "isHidden": false
                    },
                    "title": "Add a Title",
                    "uri": "b25591_6a8a65a12f0e48c8bf74c70634ed2e9a~mv2_d_3000_2000_s_2.jpg",
                    "description": "Describe your image",
                    "width": 3000,
                    "height": 2000,
                    "alt": ""
                },
                {
                    "type": "Image",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "2.0",
                        "isHidden": false
                    },
                    "title": "Add a Title",
                    "uri": "b25591_bc3841f1dee44abf840a9a1b33591002~mv2_d_3000_1995_s_2.jpg",
                    "description": "Describe your image",
                    "width": 3000,
                    "height": 1995,
                    "alt": ""
                }
            ]
        },
        "props": {
            "type": "MasonryProperties",
            "metaData": {
                "schemaVersion": "1.0"
            },
            "font": "arial",
            "galleryImageOnClickAction": "zoomMode",
            "numCols": 3,
            "margin": 13,
            "textMode": "descriptionOnly",
            "alignText": "center",
            "boxShadowIsOn": true,
            "boxShadow": "0px 1px 3px 0px rgba(0,0,0,0.33)"
        },
        "style": {
            "type": "TopLevelStyle",
            "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false
            },
            "style": {
                "properties": {
                    "alpha-color1": "1",
                    "alpha-color2": "1",
                    "alpha-color3": "1",
                    "alpha-color4": "0.8",
                    "alpha-color5": "1",
                    "color1": "#000000",
                    "color2": "#000000",
                    "color3": "#ffffff",
                    "color4": "#FFFFFF",
                    "color5": "#2F2E2E",
                    "version": "2"
                },
                "propertiesSource": {
                    "color1": "value",
                    "color2": "value",
                    "color3": "value",
                    "color4": "value",
                    "color5": "value",
                    "version": "value"
                },
                "groups": {}
            },
            "componentClassName": "tpa.viewer.components.Masonry",
            "pageId": "",
            "compId": "",
            "styleType": "custom",
            "skin": "wysiwyg.viewer.skins.TPAMasonrySkin"
        },
        "mobileStructure": {
            "layout": {
                "width": 280,
                "height": 283,
                "x": 20,
                "y": 10,
                "scale": 1,
                "rotationInDegrees": 0,
                "fixedPosition": false
            },
            "metaData": {
                "originalCompId": "comp-jawjnihh",
                "author": "duplicate"
            }
        },
        "activeModes": {},
        'id': 'i7t7tyrv'
    },
    'preset': {
        'rect': {'width': 162, 'height': 176, 'x': 0, 'y': 0},
        'label': 'add_preset_galleries_grid_masonry_label',
        'tags': null
    }
}, {
    'id': 'Gallery_Grid_Galleries_2',
    'structure': {
        "type": "Component",
        "skin": "wysiwyg.viewer.skins.paginatedgrid.PaginatedGridOverlay",
        "layout": {
            "width": 570,
            "height": 552,
            "x": 0,
            "y": 2,
            "scale": 1,
            "rotationInDegrees": 0,
            "fixedPosition": false
        },
        "componentType": "wysiwyg.viewer.components.PaginatedGridGallery",
        "data": {
            "type": "ImageList",
            "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false
            },
            "items": [
                {
                    "type": "Image",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "2.0",
                        "isHidden": false
                    },
                    "title": "i'm an image title",
                    "uri": "b25591_3352e742a2e5467aad7df422106fc278~mv2_d_3000_2000_s_2.jpg",
                    "width": 3000,
                    "height": 2000,
                    "alt": ""
                },
                {
                    "type": "Image",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "2.0",
                        "isHidden": false
                    },
                    "title": "i'm an image title",
                    "uri": "b25591_2790fd3a23db403793dbf2c3c1c62723~mv2_d_3000_1946_s_2.jpg",
                    "width": 3000,
                    "height": 1946,
                    "alt": ""
                },
                {
                    "type": "Image",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "2.0",
                        "isHidden": false
                    },
                    "title": "i'm an image title",
                    "uri": "b25591_bc3841f1dee44abf840a9a1b33591002~mv2_d_3000_1995_s_2.jpg",
                    "width": 3000,
                    "height": 1995,
                    "alt": ""
                },
                {
                    "type": "Image",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "2.0",
                        "isHidden": false
                    },
                    "title": "i'm an image title",
                    "uri": "b25591_89a9544354da4226913c3727baf2a742~mv2_d_3000_1688_s_2.jpg",
                    "width": 3000,
                    "height": 1688,
                    "alt": ""
                },
                {
                    "type": "Image",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "2.0",
                        "isHidden": false
                    },
                    "title": "i'm an image title",
                    "uri": "b25591_f1ab68320b444a4ca3cc0b7dd2284bf5~mv2_d_3000_4500_s_4_2.jpg",
                    "width": 3000,
                    "height": 4500,
                    "alt": ""
                },
                {
                    "type": "Image",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "2.0",
                        "isHidden": false
                    },
                    "title": "i'm an image title",
                    "uri": "b25591_bbd372e536434c859bf040766757df4a~mv2_d_3000_3000_s_4_2.jpg",
                    "width": 3000,
                    "height": 3000,
                    "alt": ""
                },
                {
                    "type": "Image",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "2.0",
                        "isHidden": false
                    },
                    "title": "i'm an image title",
                    "uri": "b25591_23ba699582354676ba0ab793abd1a23f~mv2_d_8192_7754_s_4_2.jpg",
                    "width": 8192,
                    "height": 7754,
                    "alt": ""
                },
                {
                    "type": "Image",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "2.0",
                        "isHidden": false
                    },
                    "title": "i'm an image title",
                    "uri": "b25591_a2db3b22be0a4790a1eda133c543fb3c~mv2_d_2869_3586_s_4_2.jpg",
                    "width": 2869,
                    "height": 3586,
                    "alt": ""
                },
                {
                    "type": "Image",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "2.0",
                        "isHidden": false
                    },
                    "title": "i'm an image title",
                    "uri": "b25591_99f5cd6c9b6b4899aef6c2e486e291c7~mv2_d_3000_2930_s_4_2.jpg",
                    "width": 3000,
                    "height": 2930,
                    "alt": ""
                }
            ]
        },
        "props": {
            "type": "PaginatedGridGalleryProperties",
            "metaData": {
                "schemaVersion": "1.0",
                "autoGenerated": false
            },
            "expandEnabled": true,
            "galleryImageOnClickAction": "zoomMode",
            "goToLinkText": "Go to link",
            "imageMode": "clipImage",
            "numCols": 3,
            "maxRows": 3,
            "margin": 15,
            "transition": "seq_crossFade_All",
            "transDuration": 1,
            "autoplayInterval": 3,
            "autoplay": false,
            "showAutoplay": false,
            "showNavigation": false,
            "showCounter": true
        },
        "style": {
            "type": "TopLevelStyle",
            "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false
            },
            "style": {
                "properties": {
                    "alpha-bg": "0.8",
                    "alpha-bgh": "0",
                    "bg": "color_11",
                    "bgh": "#000000",
                    "fntds": "normal normal normal 0px/1.4em raleway",
                    "fntt": "normal normal normal 0px/1.4em avenir-lt-w01_35-light1475496",
                    "linkColor": "#2F2E2E",
                    "txt": "color_11"
                },
                "propertiesSource": {
                    "alpha-bg": "value",
                    "alpha-bgh": "value",
                    "bg": "theme",
                    "bgh": "value",
                    "fntds": "value",
                    "fntt": "value",
                    "linkColor": "value",
                    "txt": "theme"
                },
                "groups": {}
            },
            "componentClassName": "wysiwyg.viewer.components.PaginatedGridGallery",
            "pageId": "",
            "compId": "",
            "styleType": "custom",
            "skin": "wysiwyg.viewer.skins.paginatedgrid.PaginatedGridOverlay"
        },
        "mobileStructure": {
            "layout": {
                "width": 280,
                "height": 561,
                "x": 20,
                "y": 10,
                "scale": 1,
                "rotationInDegrees": 0,
                "fixedPosition": false
            },
            "metaData": {
                "originalCompId": "comp-jawjsnmq",
                "author": "duplicate"
            }
        },
        "activeModes": {},
        'id': 'i7t7tyqm_0'
    },
    'preset': {
        'rect': {'width': 162, 'height': 176, 'x': 162, 'y': 0},
        'label': 'add_preset_galleries_grid_portrait_label',
        'tags': null
    }
}, {
    'id': 'Gallery_Grid_Galleries_3',
    'structure': {
        "type": "Component",
        "skin": "wysiwyg.common.components.matrixgallery.viewer.skins.MatrixGallerySeparateTextBoxSkin",
        "layout": {
            "width": 570,
            "height": 592,
            "x": 0,
            "y": 1,
            "scale": 1,
            "rotationInDegrees": 0,
            "fixedPosition": false
        },
        "componentType": "wysiwyg.viewer.components.MatrixGallery",
        "data": {
            "type": "ImageList",
            "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false
            },
            "items": [
                {
                    "type": "Image",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "2.0",
                        "isHidden": false
                    },
                    "title": "Add a Title",
                    "uri": "b25591_2790fd3a23db403793dbf2c3c1c62723~mv2_d_3000_1946_s_2.jpg",
                    "width": 3000,
                    "height": 1946,
                    "alt": ""
                },
                {
                    "type": "Image",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "2.0",
                        "isHidden": false
                    },
                    "title": "Add a Title",
                    "uri": "b25591_bc3841f1dee44abf840a9a1b33591002~mv2_d_3000_1995_s_2.jpg",
                    "width": 3000,
                    "height": 1995,
                    "alt": ""
                },
                {
                    "type": "Image",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "2.0",
                        "isHidden": false
                    },
                    "title": "Add a Title",
                    "uri": "b25591_bbd372e536434c859bf040766757df4a~mv2_d_3000_3000_s_4_2.jpg",
                    "width": 3000,
                    "height": 3000,
                    "alt": ""
                },
                {
                    "type": "Image",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "2.0",
                        "isHidden": false
                    },
                    "title": "Add a Title",
                    "uri": "b25591_24a35ec53c4a4d41a0da9c7b7cd6d2d3~mv2_d_3000_1965_s_2.jpg",
                    "width": 3000,
                    "height": 1965,
                    "alt": ""
                },
                {
                    "type": "Image",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "2.0",
                        "isHidden": false
                    },
                    "title": "Add a Title",
                    "uri": "b25591_e642e63607944440bc13a8a2ea61aefe~mv2_d_3000_1941_s_2.jpg",
                    "width": 3000,
                    "height": 1941,
                    "alt": ""
                },
                {
                    "type": "Image",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "2.0",
                        "isHidden": false
                    },
                    "title": "Add a Title",
                    "uri": "b25591_99f5cd6c9b6b4899aef6c2e486e291c7~mv2_d_3000_2930_s_4_2.jpg",
                    "width": 3000,
                    "height": 2930,
                    "alt": ""
                },
                {
                    "type": "Image",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "2.0",
                        "isHidden": false
                    },
                    "title": "Add a Title",
                    "uri": "b25591_23ba699582354676ba0ab793abd1a23f~mv2_d_8192_7754_s_4_2.jpg",
                    "width": 8192,
                    "height": 7754,
                    "alt": ""
                },
                {
                    "type": "Image",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "2.0",
                        "isHidden": false
                    },
                    "title": "Add a Title",
                    "uri": "b25591_a2db3b22be0a4790a1eda133c543fb3c~mv2_d_2869_3586_s_4_2.jpg",
                    "width": 2869,
                    "height": 3586,
                    "alt": ""
                },
                {
                    "type": "Image",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "2.0",
                        "isHidden": false
                    },
                    "title": "Add a Title",
                    "uri": "b25591_89a9544354da4226913c3727baf2a742~mv2_d_3000_1688_s_2.jpg",
                    "width": 3000,
                    "height": 1688,
                    "alt": ""
                }
            ]
        },
        "props": {
            "type": "MatrixGalleryProperties",
            "metaData": {
                "schemaVersion": "1.0"
            },
            "expandEnabled": true,
            "galleryImageOnClickAction": "zoomMode",
            "goToLinkText": "Go to link",
            "imageMode": "clipImage",
            "numCols": 3,
            "maxRows": 3,
            "incRows": 2,
            "margin": 15,
            "showMoreLabel": "Show More",
            "alignText": "center"
        },
        "style": {
            "type": "TopLevelStyle",
            "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false
            },
            "style": {
                "properties": {
                    "alpha-bghClr": "0.8",
                    "alpha-boxbg": "1",
                    "alpha-brd": "1",
                    "alpha-lnkClr": "1",
                    "alpha-showMoreClr": "1",
                    "alpha-ttl2": "1",
                    "alpha-txt2": "1",
                    "bghClr": "color_11",
                    "boxRd": "0px",
                    "boxbg": "color_12",
                    "brd": "#2F2E2E",
                    "brw": "0px",
                    "fntds": "normal normal normal 14px/1.4em avenir-lt-w01_35-light1475496",
                    "fntt": "normal normal normal 16px/1.4em avenir-lt-w01_35-light1475496",
                    "imgHeightDiff": "52px",
                    "lnkClr": "#2F2E2E",
                    "paddingSize": "14px",
                    "rd": "0px",
                    "showMoreClr": "#2F2E2E",
                    "topPadding": "0px",
                    "ttl2": "color_14",
                    "txt2": "color_11"
                },
                "propertiesSource": {
                    "alpha-bghClr": "value",
                    "alpha-boxbg": "value",
                    "bghClr": "theme",
                    "boxRd": "value",
                    "boxbg": "theme",
                    "brd": "value",
                    "brw": "value",
                    "fntds": "value",
                    "fntt": "value",
                    "imgHeightDiff": "value",
                    "lnkClr": "value",
                    "paddingSize": "value",
                    "rd": "value",
                    "showMoreClr": "value",
                    "topPadding": "value",
                    "ttl2": "theme",
                    "txt2": "theme"
                },
                "groups": {}
            },
            "componentClassName": "wysiwyg.viewer.components.MatrixGallery",
            "pageId": "",
            "compId": "",
            "styleType": "custom",
            "skin": "wysiwyg.common.components.matrixgallery.viewer.skins.MatrixGallerySeparateTextBoxSkin"
        },
        "mobileStructure": {
            "layout": {
                "width": 280,
                "height": 601,
                "x": 20,
                "y": 10,
                "scale": 1,
                "rotationInDegrees": 0,
                "fixedPosition": false
            },
            "metaData": {
                "originalCompId": "comp-jawjsco2",
                "author": "duplicate"
            }
        },
        "activeModes": {},
        'id': 'i7t7tyqv'
    },
    'preset': {
        'rect': {'width': 162, 'height': 176, 'x': 0, 'y': 176},
        'label': 'add_preset_galleries_grid_polaroid_label',
        'tags': null
    }
}, {
    'id': 'Gallery_Grid_Galleries_4',
    'structure': {
        "type": "Component",
        "skin": "wysiwyg.viewer.skins.gallerymatrix.MatrixGalleryTransparentSkin",
        "layout": {
            "width": 570,
            "height": 577,
            "x": 0,
            "y": 2,
            "scale": 1,
            "rotationInDegrees": 0,
            "fixedPosition": false
        },
        "componentType": "wysiwyg.viewer.components.MatrixGallery",
        "data": {
            "type": "ImageList",
            "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false
            },
            "items": [
                {
                    "type": "Image",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "2.0",
                        "isHidden": false
                    },
                    "title": "Add a Title",
                    "uri": "b25591_2790fd3a23db403793dbf2c3c1c62723~mv2_d_3000_1946_s_2.jpg",
                    "description": "Describe your image",
                    "width": 3000,
                    "height": 1946,
                    "alt": ""
                },
                {
                    "type": "Image",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "2.0",
                        "isHidden": false
                    },
                    "title": "Add a Title",
                    "uri": "b25591_3352e742a2e5467aad7df422106fc278~mv2_d_3000_2000_s_2.jpg",
                    "description": "Describe your image",
                    "width": 3000,
                    "height": 2000,
                    "alt": ""
                },
                {
                    "type": "Image",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "2.0",
                        "isHidden": false
                    },
                    "title": "Add a Title",
                    "uri": "b25591_24a35ec53c4a4d41a0da9c7b7cd6d2d3~mv2_d_3000_1965_s_2.jpg",
                    "description": "Describe your image",
                    "width": 3000,
                    "height": 1965,
                    "alt": ""
                },
                {
                    "type": "Image",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "2.0",
                        "isHidden": false
                    },
                    "title": "Add a Title",
                    "uri": "b25591_bc3841f1dee44abf840a9a1b33591002~mv2_d_3000_1995_s_2.jpg",
                    "description": "Describe your image",
                    "width": 3000,
                    "height": 1995,
                    "alt": ""
                },
                {
                    "type": "Image",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "2.0",
                        "isHidden": false
                    },
                    "title": "Add a Title",
                    "uri": "b25591_23ba699582354676ba0ab793abd1a23f~mv2_d_8192_7754_s_4_2.jpg",
                    "description": "Describe your image",
                    "width": 8192,
                    "height": 7754,
                    "alt": ""
                },
                {
                    "type": "Image",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "2.0",
                        "isHidden": false
                    },
                    "title": "Add a Title",
                    "uri": "b25591_bbd372e536434c859bf040766757df4a~mv2_d_3000_3000_s_4_2.jpg",
                    "description": "Describe your image",
                    "width": 3000,
                    "height": 3000,
                    "alt": ""
                },
                {
                    "type": "Image",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "2.0",
                        "isHidden": false
                    },
                    "title": "Add a Title",
                    "uri": "b25591_e642e63607944440bc13a8a2ea61aefe~mv2_d_3000_1941_s_2.jpg",
                    "description": "Describe your image",
                    "width": 3000,
                    "height": 1941,
                    "alt": ""
                },
                {
                    "type": "Image",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "2.0",
                        "isHidden": false
                    },
                    "title": "Add a Title",
                    "uri": "b25591_99f5cd6c9b6b4899aef6c2e486e291c7~mv2_d_3000_2930_s_4_2.jpg",
                    "description": "Describe your image",
                    "width": 3000,
                    "height": 2930,
                    "alt": ""
                },
                {
                    "type": "Image",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "2.0",
                        "isHidden": false
                    },
                    "title": "Add a Title",
                    "uri": "b25591_a2db3b22be0a4790a1eda133c543fb3c~mv2_d_2869_3586_s_4_2.jpg",
                    "description": "Describe your image",
                    "width": 2869,
                    "height": 3586,
                    "alt": ""
                }
            ]
        },
        "props": {
            "type": "MatrixGalleryProperties",
            "metaData": {
                "schemaVersion": "1.0"
            },
            "expandEnabled": true,
            "galleryImageOnClickAction": "zoomMode",
            "goToLinkText": "Go to link",
            "imageMode": "clipImage",
            "numCols": 3,
            "maxRows": 3,
            "incRows": 2,
            "margin": 15,
            "showMoreLabel": "Show More",
            "alignText": "center"
        },
        "style": {
            "type": "TopLevelStyle",
            "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false
            },
            "style": {
                "properties": {
                    "alpha-brdTrans": "1",
                    "alpha-showMoreColor": "1",
                    "alpha-ttlTrans": "1",
                    "alpha-ttlhTrans": "0",
                    "alpha-txtTrans": "1",
                    "alpha-txthTrans": "0",
                    "boxShadowToggleOn-shd": "false",
                    "brdTrans": "color_15",
                    "brw": "2",
                    "fntdsTrans": "normal normal normal 12px/1.4em futura-lt-w01-book",
                    "fntt": "italic normal normal 16px/1.4em playfair+display",
                    "rd": "0px",
                    "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                    "showMoreColor": "#2F2E2E",
                    "ttlTrans": "#000000",
                    "ttlhTrans": "color_15",
                    "txtTrans": "#605E5E",
                    "txthTrans": "color_14"
                },
                "propertiesSource": {
                    "brdTrans": "theme",
                    "brw": "value",
                    "fntdsTrans": "value",
                    "fntt": "value",
                    "rd": "value",
                    "shd": "value",
                    "showMoreColor": "value",
                    "ttlTrans": "value",
                    "ttlhTrans": "theme",
                    "txtTrans": "value",
                    "txthTrans": "theme"
                },
                "groups": {}
            },
            "componentClassName": "wysiwyg.viewer.components.MatrixGallery",
            "pageId": "",
            "compId": "",
            "styleType": "custom",
            "skin": "wysiwyg.viewer.skins.gallerymatrix.MatrixGalleryTransparentSkin"
        },
        "mobileStructure": {
            "layout": {
                "width": 280,
                "height": 586,
                "x": 20,
                "y": 10,
                "scale": 1,
                "rotationInDegrees": 0,
                "fixedPosition": false
            },
            "metaData": {
                "originalCompId": "comp-jawju6wv",
                "author": "duplicate"
            }
        },
        "activeModes": {},
        'id': 'i7t7tyqr'
    },
    'preset': {
        'rect': {'width': 162, 'height': 176, 'x': 162, 'y': 176},
        'label': 'add_preset_galleries_grid_rollover_label',
        'tags': null
    }
}]

module.exports = {
    'type': addPanelDataConsts.SECTIONS_TYPES.PRESET,
    'hide': false,
    'title': 'add_section_label_gridgalleries',
    'subNavigationTitle': 'add_section_label_gridgalleries',
    'presetTitle': 'add_section_label_gridgalleries',
    'tooltipTitle': 'add_section_label_gridgalleries',
    'subNavigationHide': false,
    'showSectionHeader': true,
    'additionalBehaviours': {
        'labelMode': addPanelDataConsts.LABEL_BEHAVIOUR.HOVER,
        'infoIcon': addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
        'hoverImageAction': addPanelDataConsts.HOVER_IMAGE_ACTIONS.VIDEO,
        'iconEnabledForComps': {}
    },
    'props': {
        'image': 'addPanelData/sections/gridGalleriesSection_en/gridGalleriesSectionNew_en.png',
        "retinaImage": {
            "src": 'addPanelData/sections/gridGalleriesSection_en/gridGalleriesSection_en-@2x.png',
            "dimensions": {
                "width": 648,
                "height": 704
            }
        },
        'imageHover': null,
        'items': newGalleries,
        'compTypes': ['wysiwyg.viewer.components.PaginatedGridGallery', 'tpa.viewer.components.Honeycomb', 'tpa.viewer.components.Masonry', 'tpa.viewer.components.Collage', 'wysiwyg.viewer.components.MatrixGallery']
    },
    'help': {'hide': false, 'text': 'add_section_info_text_gridgalleries'}
}
