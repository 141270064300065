// @ts-nocheck
export default {
  404: {
    panelName: '404-errorPanel',
    title: 'DYNAMIC_PAGES_404_ERROR_TITLE',
    symbol: '404_Error_message_illustration',
    errorBodyTop: 'DYNAMIC_Pages_404_Error_Body_1',
    routerAddress: 'https://www.wix.com/error-pages-samples/error404',
    errorBodyBottom: 'DYNAMIC_Pages_404_Error_Body_2',
    btnViewErrorPage: 'DYNAMIC_Pages_Error_View_Error_Page_Button',
    btnClose: 'DYNAMIC_Pages_Error_Close_Button',
  },
  500: {
    panelName: '500-errorPanel',
    title: 'DYNAMIC_PAGES_500_ERROR_TITLE',
    symbol: '500_Error_message_illustration',
    errorBodyTop: 'DYNAMIC_Pages_500_Error_Body_1',
    routerAddress: 'https://www.wix.com/error-pages-samples/error500',
    errorBodyBottom: 'DYNAMIC_Pages_500_Error_Body_2',
    btnViewErrorPage: 'DYNAMIC_Pages_Error_View_Error_Page_Button',
    btnClose: 'DYNAMIC_Pages_Error_Close_Button',
  },
  403: {
    panelName: '403-errorPanel',
    title: 'DYNAMIC_PAGES_403_ERROR_TITLE',
    symbol: '403_page_forbidden_message_illustration',
    errorBodyTop: 'DYNAMIC_Pages_403_Error_Body_1',
    routerAddress: 'https://www.wix.com/error-pages-samples/error403',
    errorBodyBottom: 'DYNAMIC_Pages_403_Error_Body_2',
    btnViewErrorPage: 'DYNAMIC_Pages_Error_View_Error_Page_Button',
    btnClose: 'DYNAMIC_Pages_Error_Close_Button',
  },
  other: {
    panelName: 'other-errorPanel',
    title: 'DYNAMIC_PAGES_GENERAL_ERROR_TITLE',
    symbol: 'general_error_message_illustration',
    errorBodyTop: 'DYNAMIC_Pages_General_Error_Body_1',
    routerAddress: 'https://www.wix.com/error-pages-samples/error',
    errorBodyBottom: 'DYNAMIC_Pages_General_Error_Body_2',
    btnViewErrorPage: 'DYNAMIC_Pages_Error_View_Error_Page_Button',
    btnClose: 'DYNAMIC_Pages_Error_Close_Button',
  },
};
