:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._top-bar-device-switch-with-resize_rwfxw_7 {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding: 0 5px; }

._device-switch-slider_rwfxw_14 {
  padding: 4px;
  width: 78px;
  transition: width 0.2s, padding 0.2s, margin 0.2s;
  overflow: hidden; }

._hidden_rwfxw_20 {
  width: 0;
  padding: 0;
  margin: 0 3px; }

._fluid-preview-controls_rwfxw_25 {
  display: flex;
  align-items: center;
  border-left: 1px solid rgba(217, 225, 232, 0.6);
  border-right: 1px solid rgba(217, 225, 232, 0.6);
  padding: 0 5px;
  margin: 0 5px; }

._top-bar-btn-device_rwfxw_33 {
  min-width: 36px; }
  ._top-bar-btn-device_rwfxw_33.top-bar-btn {
    padding: 0 4px; }
  ._top-bar-btn-device_rwfxw_33 .top-bar-btn-content-wrapper:after {
    display: none; }
  ._top-bar-btn-device_rwfxw_33.toggled::after {
    --hover-size: 34px;
    z-index: 0;
    content: '';
    width: var(--hover-size);
    height: var(--hover-size);
    border-radius: 4px;
    pointer-events: none;
    position: absolute;
    background-color: #116dff;
    opacity: 0.1;
    left: calc(50% - var(--hover-size) / 2);
    top: calc(50% - var(--hover-size) / 2); }
