// @ts-nocheck
import createReactClass from 'create-react-class';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { PanelHeader } from '@wix/wix-base-ui';

import * as stateManagement from '@/stateManagement';
import constants from '@/constants';
import * as util from '@/util';

const { panelFrame, panelDragLimits } = stateManagement.panels.hoc;

// eslint-disable-next-line react/prefer-es6-class
const BackgroundPanelFrame = createReactClass({
  displayName: constants.PANEL_TYPES.BG_PANEL,
  mixins: [util.draggableMixin],
  PropTypes: {
    label: PropTypes.string,
    onClose(props, propName) {
      if (props.title && typeof props[propName] !== 'function') {
        return new Error('onClose prop must be of type a function');
      }
    },
    openPanelBiEvent: PropTypes.object,
    getPanelDragLimits: PropTypes.func.isRequired,
    openPanelBiParams: PropTypes.object,
  },
  render() {
    return (
      <div className="background-panel-frame">
        {this.props.label ? (
          <PanelHeader
            key="header"
            onMouseDown={(e) => {
              this.startDrag(e, this.props.getPanelDragLimits());
            }}
            onClose={this.props.close}
            onHelp={this.props.openHelpCenter}
            noHelpBtn={!this.props.helpId && !this.props.onHelpClicked}
            className="dragger dark"
          >
            <span>{this.props.label}</span>
          </PanelHeader>
        ) : null}
        <div id={this.props.id} className={this.props.className || ''}>
          {this.props.children}
        </div>
      </div>
    );
  },
});
const WrappedFrame = _.flow(
  panelFrame(constants.PANEL_TYPES.BG_PANEL),
  panelDragLimits(),
)(BackgroundPanelFrame);
WrappedFrame.pure = BackgroundPanelFrame;
export default WrappedFrame;
