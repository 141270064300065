import { symbols } from '@/util';

import type {
  MenuItem as MenuItemType,
  PagesData,
  RoutersDefinition,
} from 'types/documentServices';

import { cleanId, isDropdown } from '../../utils/utils';

export const getSymbolName = (
  item: MenuItemType,
  homepageId: string,
  pageData?: PagesData,
  routerData?: RoutersDefinition,
): string => {
  // pageItemCustom404, oldBlogPageType are removed because check requires separated request to DS

  if (isDropdown(item)) {
    return 'menuFolder';
  }

  if (routerData?.group === 'members') {
    return 'membersAction';
  }

  if (pageData) {
    // For some reason editorAPI.dsRead.homePage.get() returns id without #
    if (cleanId(pageData.id) === cleanId(homepageId)) {
      return 'homePageType';
    }

    if (pageData.isPopup) {
      return 'popupPageType';
    }

    // todo: fix
    if (pageData?.tpaPageId === 'members') {
      return 'membersAction';
    }

    if (pageData.tpaApplicationId > 0) {
      return symbols.getTpaSymbolName(pageData.managingAppDefId);
    }

    return 'regularPageType';
  }

  if (item.link) {
    return symbols.getLinkSymbolName(item.link);
  }

  return 'regularPageType';
};
