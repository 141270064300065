'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')

const items = [{
    'id': 'Other_HTML_&_Flash_1',
    'structure': {
        'type': 'Component',
        'skin': 'wysiwyg.viewer.skins.HtmlComponentSkin',
        'layout': {
            'width': 230,
            'height': 190,
            'x': 51,
            'y': 41,
            'scale': 1,
            'rotationInDegrees': 0,
            'fixedPosition': false
        },
        'componentType': 'wysiwyg.viewer.components.HtmlComponent',
        'componentTypeForBI': 'EmbedSite',
        'data': {
            'type': 'HtmlComponent',
            'metaData': {'isPreset': true, 'schemaVersion': '1.0', 'isHidden': false},
            'url': '',
            'sourceType': 'external',
            'freezeFrame': false
        },
        'style': {
            'type': 'TopLevelStyle',
            'id': 'htco1',
            'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
            'style': {'groups': {}, 'properties': {}, 'propertiesSource': {}},
            'componentClassName': '',
            'pageId': '',
            'compId': '',
            'styleType': 'system',
            'skin': 'wysiwyg.viewer.skins.HtmlComponentSkin'
        },
        'id': 'i5rznih1'
    },
    'preset': {
        'rect': {'width': 108, 'height': 95, 'x': 108, 'y': 0},
        'label': 'add_preset_Other_HTML_Code_1',
        'tags': null
    }
}, {
    'id': 'Other_HTML_&_Flash_2',
    'structure': {
        'type': 'Component',
        'skin': 'wysiwyg.viewer.skins.HtmlComponentSkin',
        'layout': {
            'width': 230,
            'height': 190,
            'x': 267,
            'y': 41,
            'scale': 1,
            'rotationInDegrees': 0,
            'fixedPosition': false
        },
        'componentType': 'wysiwyg.viewer.components.HtmlComponent',
        'componentTypeForBI': 'HtmlCode',
        'data': {
            'type': 'HtmlComponent',
            'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
            'url': '',
            'sourceType': 'htmlEmbedded',
            'freezeFrame': false
        },
        'style': {
            'type': 'TopLevelStyle',
            'id': 'htco1',
            'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
            'style': {'groups': {}, 'properties': {}, 'propertiesSource': {}},
            'componentClassName': '',
            'pageId': '',
            'compId': '',
            'styleType': 'system',
            'skin': 'wysiwyg.viewer.skins.HtmlComponentSkin'
        },
        'id': 'i5rznih2'
    },
    'preset': {
        'rect': {'width': 108, 'height': 95, 'x': 0, 'y': 0},
        'label': 'add_preset_Other_HTML_Code_2',
        'tags': null
    }
}, {
    'id': 'Other_HTML_&_Flash_4',
    'structure': {
        'layout': {'width': 636, 'height': 384, 'x': 172, 'y': 112},
        'componentType': 'wixui.CustomElementComponent',
        'data': {
            url: 'https://static.parastorage.com/services/wix-ui-santa/1.1117.0/assets/CustomElement/wixDefaultCustomElement.js',
            tagName: 'wix-default-custom-element',
            type: 'CustomElement'
        },
        'style': {}
    },
    'preset': {
        'rect': {'width': 108, 'height': 95, 'x': 216, 'y': 0},
        'label': 'add_section_label_web_components_tooltip_title',
        'tags': null
    }
}, {
    'id': 'Other_HTML_&_Flash_3',
    'structure': {
        'type': 'Component',
        'skin': 'wysiwyg.viewer.skins.FlashComponentSkin',
        'layout': {
            'width': 128,
            'height': 128,
            'x': 476,
            'y': 26,
            'scale': 1,
            'rotationInDegrees': 0,
            'fixedPosition': false
        },
        'componentType': 'wysiwyg.viewer.components.FlashComponent',
        'data': {
            'type': 'LinkableFlashComponent',
            'metaData': {'isPreset': true, 'schemaVersion': '1.0', 'isHidden': false},
            'uri': '',
            'placeHolderImage': {
                'type': 'Image',
                'metaData': {'isPreset': false, 'schemaVersion': '2.0', 'isHidden': false},
                'title': 'Default Flash',
                'uri': '7ff659c252dfd1ca5c76533695cb9611.wix_mp',
                'description': 'Some description here',
                'width': 128,
                'height': 128,
                'alt': ''
            }
        },
        'props': {
            'type': 'FlashComponentProperties',
            'metaData': {'schemaVersion': '1.0'},
            'displayMode': 'original'
        },
        'style': {
            'type': 'TopLevelStyle',
            'id': 'swf1',
            'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
            'style': {'groups': {}, 'properties': {}, 'propertiesSource': {}},
            'componentClassName': '',
            'pageId': '',
            'compId': '',
            'styleType': 'system',
            'skin': 'wysiwyg.viewer.skins.FlashComponentSkin'
        },
        'id': 'i5rznigy_0'
    },
    'preset': {
        'rect': {'width': 108, 'height': 95, 'x': 0, 'y': 105},
        'label': 'add_preset_Other_HTML_Code_3',
        'tags': null
    }
}]

const compTypes = ['wysiwyg.viewer.components.HtmlComponent', 'wixui.CustomElementComponent', 'wysiwyg.viewer.components.FlashComponent']

module.exports = {
    'type': addPanelDataConsts.SECTIONS_TYPES.PRESET,
    'hide': false,
    'title': 'add_section_label_embeds',
    'subNavigationTitle': 'add_section_label_custom_embed',
    'presetTitle': 'add_section_label_embeds',
    'tooltipTitle': 'add_section_label_embeds',
    "automationId": "add-panel-section-htmlFlashSection",
    'subNavigationHide': false,
    'showSectionHeader': true,
    'additionalBehaviours': {
        'labelMode': addPanelDataConsts.LABEL_BEHAVIOUR.ALWAYS,
        'infoIcon': addPanelDataConsts.INFO_ICON_BEHAVIOUR.HOVER,
        'hoverImageAction': addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
        'iconEnabledForComps': {
            'wysiwyg.viewer.components.FlashComponent': {
                'title': 'preset_info_title_flash',
                'text': 'preset_info_text_flash',
                'helpId': 'preset_info_helpid_flash',
                'learnMoreText': 'preset_info_link_flash'
            },
            'wysiwyg.viewer.components.HtmlComponent': function (data) {
                if (data.sourceType === 'external') {
                    return {
                        'title': 'preset_info_title_embed_site',
                        'text': 'preset_info_text_embed_site'
                    }
                }
                return {
                    'title': 'preset_info_title_html_iframe',
                    'text': 'preset_info_text_html_iframe',
                    'helpId': 'preset_info_helpid_html_iframe',
                    'learnMoreText': 'preset_info_link_html_iframe'
                }
            },
            'wixui.CustomElementComponent': {
                'title': 'add_section_label_web_components_tooltip_title',
                'text': 'add_section_label_web_compontents_tooltip_body'
            }
        }
    },
    'props': {
        'image': 'addPanelData/sections/htmlFlashSection_en/htmlFlashSection_en.png',
        'imageHover': null,
        items,
        compTypes
    },
    'help': {'hide': false, 'text': 'add_section_info_text_html'}
}
