'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')

const skyscrapers = {
    type: 'WixVideo',
    metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false
    },
    title: 'Skyscrapers.mp4',
    videoId: '11062b_1134a743c90247bd95970e575baa005c',
    qualities: [
        {
            quality: '480p',
            width: 854,
            height: 480,
            url: 'video/11062b_1134a743c90247bd95970e575baa005c/480p/mp4/file.mp4'
        },
        {
            quality: '720p',
            width: 1280,
            height: 720,
            url: 'video/11062b_1134a743c90247bd95970e575baa005c/720p/mp4/file.mp4'
        },
        {
            quality: '1080p',
            width: 1920,
            height: 1080,
            url: 'video/11062b_1134a743c90247bd95970e575baa005c/1080p/mp4/file.mp4'
        },
        {
            quality: 'storyboard',
            width: 256,
            height: 144,
            url:
          'video/11062b_1134a743c90247bd95970e575baa005c/storyboard/144p/mp4/file.mp4'
        }
    ],
    posterImageRef: {
        type: 'Image',
        metaData: {
            isPreset: false,
            schemaVersion: '2.0',
            isHidden: false
        },
        title: '',
        uri: '11062b_1134a743c90247bd95970e575baa005cf000.jpg',
        description: 'private/allMedia_video',
        width: 1920,
        height: 1080,
        alt: ''
    },
    generatedPosters: [
        '11062b_1134a743c90247bd95970e575baa005cf000.jpg',
        '11062b_1134a743c90247bd95970e575baa005cf001.jpg',
        '11062b_1134a743c90247bd95970e575baa005cf002.jpg',
        '11062b_1134a743c90247bd95970e575baa005cf003.jpg'
    ],
    opacity: 1,
    duration: 24.64,
    preload: 'auto',
    artist: {id: '', name: ''},
    hasAudio: true,
    fps: '30',
    adaptiveVideo: [
        {
            format: 'hls',
            url: 'files/video/11062b_1134a743c90247bd95970e575baa005c/repackage/hls'
        }
    ]
}

const unitedVideoPlayerProps = {
    'image': 'addPanelData/sections/videoSection_en/videoSectionSocialPlayers_en.v6.png',
    'imageHover': null,
    'items': [{
        'id': 'videoPlayer',
        'structure': {
            'type': 'Component',
            'skin': 'wixui.skins.VideoPlayer',
            'layout': {
                'width': 480,
                'height': 270,
                'x': 171,
                'y': 15,
                'scale': 1,
                'rotationInDegrees': 0,
                'fixedPosition': false
            },
            'componentType': 'wixui.VideoPlayer',
            'data': {
                'type': 'VideoPlayer',
                'metaData': {
                    'isPreset': false,
                    'schemaVersion': '1.0',
                    'isHidden': false
                },
                'videoType': 'file',
                'videoRef': skyscrapers,
                'videoTitle': 'Your Video Title',
                'description': ''
            },
            'props': {
                'type': 'VideoPlayerProperties',
                'metaData': {
                    'schemaVersion': '1.0'
                },
                'showVideoTitle': false,
                'autoplay': false,
                'loop': false,
                'share': true,
                'controlsVisibility': 'hover'
            },
            'mobileHints': {
                'type': 'MobileHints',
                'author': 'duplicate',
                'originalCompId': 'comp-jj5xpz2m',
                'recommendedScale': 1,
                'recommendedWidth': 280,
                'recommendedHeight': 158
            },
            'style': {
                'type': 'TopLevelStyle',
                'metaData': {
                    'isPreset': false,
                    'schemaVersion': '1.0',
                    'isHidden': false
                },
                'style': {
                    'groups': {},
                    'properties': {
                        'alpha-backgroundColor': '0.25',
                        'backgroundColor': '#000000',
                        'font': 'normal normal normal 40px/1.4em proxima-n-w01-reg',
                        'fontColor': '#FFFFFF',
                        'playButtonSize': '50px',
                        'playButtonMobileSize': '30px'
                    },
                    'propertiesSource': {
                        'alpha-backgroundColor': 'value',
                        'backgroundColor': 'value',
                        'font': 'value',
                        'fontColor': 'value',
                        'playButtonSize': 'value',
                        'playButtonMobileSize': 'value'
                    }
                },
                'componentClassName': 'wixui.VideoPlayer',
                'pageId': '',
                'compId': '',
                'styleType': 'custom',
                'skin': 'wixui.skins.VideoPlayer'
            },
            'activeModes': {},
            'id': 'comp-jj5y0uih'
        },
        'preset': {
            'rect': {x: 5, y: 10, width: 314, height: 205},
            'label': 'add_preset_social_player_name_video_player',
            'tags': null
        }
    }, {
        'id': 'youtube',
        'structure': {
            'type': 'Component',
            'skin': 'wixui.skins.VideoPlayer',
            'layout': {
                'width': 480,
                'height': 270,
                'x': -63,
                'y': 15,
                'scale': 1,
                'rotationInDegrees': 0,
                'fixedPosition': false
            },
            'componentType': 'wixui.VideoPlayer',
            'data': {
                'type': 'VideoPlayer',
                'metaData': {
                    'isPreset': false,
                    'schemaVersion': '1.0',
                    'isHidden': false
                },
                'videoType': 'youtube',
                'videoUrl': 'https://www.youtube.com/watch?v=TrnLCFsN5i8'
            },
            'props': {
                'type': 'VideoPlayerProperties',
                'metaData': {
                    'schemaVersion': '1.0'
                },
                'showVideoTitle': false,
                'autoplay': false,
                'loop': false,
                'share': true,
                'controlsVisibility': 'hover'
            },
            'style': {
                'type': 'TopLevelStyle',
                'metaData': {
                    'isPreset': false,
                    'schemaVersion': '1.0',
                    'isHidden': false
                },
                'style': {
                    'groups': {},
                    'properties': {
                        'alpha-backgroundColor': '0.25',
                        'backgroundColor': '#000000',
                        'font': 'normal normal normal 40px/1.4em proxima-n-w01-reg',
                        'fontColor': '#FFFFFF',
                        'playButtonSize': '50px',
                        'playButtonMobileSize': '30px'
                    },
                    'propertiesSource': {
                        'alpha-backgroundColor': 'value',
                        'backgroundColor': 'value',
                        'font': 'value',
                        'fontColor': 'value',
                        'playButtonSize': 'value',
                        'playButtonMobileSize': 'value'
                    }
                },
                'componentClassName': 'wixui.VideoPlayer',
                'pageId': '',
                'compId': '',
                'styleType': 'custom',
                'skin': 'wixui.skins.VideoPlayer'
            },
            'id': 'ic4hnf37'
        },
        'preset': {
            'rect': {x: 5, y: 225, width: 158, height: 110},
            'label': 'add_preset_social_player_name_youtube',
            'tags': null
        }
    }, {
        'id': 'vimeo',
        'structure': {
            'type': 'Component',
            'skin': 'wixui.skins.VideoPlayer',
            'layout': {
                'width': 480,
                'height': 270,
                'x': 171,
                'y': 15,
                'scale': 1,
                'rotationInDegrees': 0,
                'fixedPosition': false
            },
            'componentType': 'wixui.VideoPlayer',
            'data': {
                'type': 'VideoPlayer',
                'metaData': {
                    'isPreset': false,
                    'schemaVersion': '1.0',
                    'isHidden': false
                },
                'videoType': 'vimeo',
                'videoUrl': 'https://vimeo.com/461456109'
            },
            'props': {
                'type': 'VideoPlayerProperties',
                'metaData': {
                    'schemaVersion': '1.0'
                },
                'showVideoTitle': false,
                'autoplay': false,
                'loop': false,
                'share': true,
                'controlsVisibility': 'hover'
            },
            'style': {
                'type': 'TopLevelStyle',
                'metaData': {
                    'isPreset': false,
                    'schemaVersion': '1.0',
                    'isHidden': false
                },
                'style': {
                    'groups': {},
                    'properties': {
                        'alpha-backgroundColor': '0.25',
                        'backgroundColor': '#000000',
                        'font': 'normal normal normal 40px/1.4em proxima-n-w01-reg',
                        'fontColor': '#FFFFFF',
                        'playButtonSize': '50px',
                        'playButtonMobileSize': '30px'
                    },
                    'propertiesSource': {
                        'alpha-backgroundColor': 'value',
                        'backgroundColor': 'value',
                        'font': 'value',
                        'fontColor': 'value',
                        'playButtonSize': 'value',
                        'playButtonMobileSize': 'value'
                    }
                },
                'componentClassName': 'wixui.VideoPlayer',
                'pageId': '',
                'compId': '',
                'styleType': 'custom',
                'skin': 'wixui.skins.VideoPlayer'
            },
            'id': 'ic4h4tx7'
        },
        'preset': {
            'rect': {x: 163, y: 225, width: 158, height: 110},
            'label': 'add_preset_social_player_name_vimeo',
            'tags': null
        }
    }, {
        'id': 'dailymotion',
        'structure': {
            'type': 'Component',
            'skin': 'wixui.skins.VideoPlayer',
            'layout': {
                'width': 480,
                'height': 270,
                'x': 171,
                'y': 15,
                'scale': 1,
                'rotationInDegrees': 0,
                'fixedPosition': false
            },
            'componentType': 'wixui.VideoPlayer',
            'data': {
                'type': 'VideoPlayer',
                'metaData': {
                    'isPreset': false,
                    'schemaVersion': '1.0',
                    'isHidden': false
                },
                'videoType': 'dailymotion',
                'videoUrl': 'https://dai.ly/k3jznaTlT1RbXjwkuYx'
            },
            'props': {
                'type': 'VideoPlayerProperties',
                'metaData': {
                    'schemaVersion': '1.0'
                },
                'showVideoTitle': false,
                'autoplay': false,
                'loop': false,
                'share': true,
                'controlsVisibility': 'hover'
            },
            'style': {
                'type': 'TopLevelStyle',
                'metaData': {
                    'isPreset': false,
                    'schemaVersion': '1.0',
                    'isHidden': false
                },
                'style': {
                    'groups': {},
                    'properties': {
                        'alpha-backgroundColor': '0.25',
                        'backgroundColor': '#000000',
                        'font': 'normal normal normal 40px/1.4em proxima-n-w01-reg',
                        'fontColor': '#FFFFFF',
                        'playButtonSize': '50px',
                        'playButtonMobileSize': '30px'
                    },
                    'propertiesSource': {
                        'alpha-backgroundColor': 'value',
                        'backgroundColor': 'value',
                        'font': 'value',
                        'fontColor': 'value',
                        'playButtonSize': 'value',
                        'playButtonMobileSize': 'value'
                    }
                },
                'componentClassName': 'wixui.VideoPlayer',
                'pageId': '',
                'compId': '',
                'styleType': 'custom',
                'skin': 'wixui.skins.VideoPlayer'
            },
            'id': 'ic4h4tx7'
        },
        'preset': {
            'rect': {x: 163, y: 345, width: 158, height: 110},
            'label': 'add_preset_social_player_name_dailymotion',
            'tags': null
        }
    },
    {
        'id': 'facebook',
        'structure': {
            'type': 'Component',
            'skin': 'wixui.skins.VideoPlayer',
            'layout': {
                'width': 480,
                'height': 270,
                'x': 171,
                'y': 15,
                'scale': 1,
                'rotationInDegrees': 0,
                'fixedPosition': false
            },
            'componentType': 'wixui.VideoPlayer',
            'data': {
                'type': 'VideoPlayer',
                'metaData': {
                    'isPreset': false,
                    'schemaVersion': '1.0',
                    'isHidden': false
                },
                'videoType': 'facebook',
                'videoUrl': 'https://www.facebook.com/wix/videos/2334614643329887'
            },
            'props': {
                'type': 'VideoPlayerProperties',
                'metaData': {
                    'schemaVersion': '1.0'
                },
                'showVideoTitle': false,
                'autoplay': false,
                'loop': false,
                'share': true,
                'controlsVisibility': 'hover'
            },
            'style': {
                'type': 'TopLevelStyle',
                'metaData': {
                    'isPreset': false,
                    'schemaVersion': '1.0',
                    'isHidden': false
                },
                'style': {
                    'groups': {},
                    'properties': {
                        'alpha-backgroundColor': '0.25',
                        'backgroundColor': '#000000',
                        'font': 'normal normal normal 40px/1.4em proxima-n-w01-reg',
                        'fontColor': '#FFFFFF',
                        'playButtonSize': '50px',
                        'playButtonMobileSize': '30px'
                    },
                    'propertiesSource': {
                        'alpha-backgroundColor': 'value',
                        'backgroundColor': 'value',
                        'font': 'value',
                        'fontColor': 'value',
                        'playButtonSize': 'value',
                        'playButtonMobileSize': 'value'
                    }
                },
                'componentClassName': 'wixui.VideoPlayer',
                'pageId': '',
                'compId': '',
                'styleType': 'custom',
                'skin': 'wixui.skins.VideoPlayer'
            },
            'id': 'ic4h4tx7'
        },
        'preset': {
            'rect': {x: 5, y: 345, width: 158, height: 110},
            'label': 'add_preset_social_player_name_facebook',
            'tags': null
        }
    }],
    'compTypes': ['wixui.VideoPlayer']
}

module.exports = {
    'type': addPanelDataConsts.SECTIONS_TYPES.PRESET,
    'hide': false,
    'title': 'add_section_social_player_title',
    'subNavigationTitle': 'add_section_social_player_title',
    'presetTitle': 'add_section_social_player_title',
    'tooltipTitle': 'add_section_social_player_title',
    "automationId": "add-panel-section-socialVideoSection",
    'subNavigationHide': false,
    'sectionNumber': 0,
    'showSectionHeader': true,
    'additionalBehaviours': {
        'labelMode': addPanelDataConsts.LABEL_BEHAVIOUR.ALWAYS,
        'infoIcon': addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
        'hoverImageAction': addPanelDataConsts.HOVER_IMAGE_ACTIONS.NONE,
        'iconEnabledForComps': {}
    },
    'props': unitedVideoPlayerProps,
    'help': {
        'hide': false,
        'text': 'add_section_social_player_tooltip'
    }
}
