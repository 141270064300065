import _ from 'lodash';
import { TEXT_EDITOR_OVERRIDE_STYLE, CKEDITOR_SELECTOR } from '../consts';
import { LinksHelper, TextLinksManager } from '@/textControls';
import { TextEditorLocation } from '@/constants';
import {
  reportTextChanged,
  reportTextComponentStartEditing,
} from '../quickEditBi';
import { fedopsLogger, browserUtil } from '@/util';

import type { RefObject } from 'react';
import type { LinksHelperType } from '@/textControls';
import type { QuickEditScope } from '../quickEditEntryPoint';
import type { TextManager } from 'types/data';
import type { TextEditorWrapperOwnProps } from './textEditorWrapper';
import type { ComponentValueRecord } from '@/quickEditEngine';
import type { CompRef } from '@wix/document-services-types';
import type { EditorAPI } from '@/editorAPI';

const { QUICKEDIT } = TextEditorLocation;

let textManager: TextManager;
let linksHelper: LinksHelperType;
let linksManager: typeof TextLinksManager;
export const getLinksHelper = (): LinksHelperType => {
  if (!linksHelper) {
    linksHelper = new (LinksHelper as AnyFixMe)([]);
  }
  return linksHelper;
};
const getLinksManager = (): typeof TextLinksManager => {
  if (!linksManager) {
    linksManager = new (TextLinksManager as AnyFixMe)(getLinksHelper());
  }
  return linksManager;
};

const resetVerticalTextIfNeeded = (
  editorAPI: EditorAPI,
  manager: TextManager,
  compRef: CompRef,
) => {
  const compProps = editorAPI.components.properties.get(compRef);
  const verticalText = compProps?.verticalText;
  if (verticalText) {
    manager.execCommand('verticalText', false, {
      execWithoutFocus: true,
      execWithoutHistory: true,
    });
  }
};

const addTransitionListener = (
  element: HTMLDivElement,
  callback: () => void,
) => {
  // This is a temp fix for Firefox, until we'll fix not in time loading of text manager
  if (
    browserUtil.isChrome() ||
    browserUtil.isSafari() ||
    browserUtil.isEdge()
  ) {
    element
      .querySelector(CKEDITOR_SELECTOR)
      .addEventListener('transitionstart', callback, { once: true });
  } else {
    setTimeout(callback, 500);
  }
};

const setCurrentLinks = (editorAPI: EditorAPI, compRef: CompRef) => {
  const { linkList } = editorAPI.text.getValue(compRef);
  getLinksHelper().setCurrentLinks(linkList);
};

const overrideCkeStyle = (manager: TextManager) =>
  manager.execCommand('wixCss', {
    id: 'quickEditTextEditor',
    value: TEXT_EDITOR_OVERRIDE_STYLE,
    isLink: false,
    force: false,
  });

export const mapDispatchToProps = (
  { editorAPI, store }: QuickEditScope,
  { onTextManagerReady, compRef, compText }: TextEditorWrapperOwnProps,
) => {
  const reportStartEditing = () => {
    fedopsLogger.interactionEnded(
      fedopsLogger.INTERACTIONS.QUICK_EDIT_PANEL.TEXT_EDITOR_LOAD,
    );
    reportTextComponentStartEditing(store, compRef);
  };

  const startTextEditing = (manager: TextManager, ckeElement: HTMLDivElement) =>
    addTransitionListener(ckeElement, async () => {
      onTextManagerReady(manager);
      await editorAPI.text.runOldTextMigration(compRef);
      manager.setData(compText, {
        validateHtml: false,
        cursorLocation: 'all',
        removeNestedStyles: true,
        callback: () => resetVerticalTextIfNeeded(editorAPI, manager, compRef),
      });
      manager.focus();
      manager.resetUndo();
      reportStartEditing();
    });

  return {
    getLinksManager,
    getLinksHelper,
    documentServices: editorAPI.documentServices,
    onDataChange: (newText: string) => {
      const { text: oldText } = editorAPI.text.getValue(compRef);
      if (newText !== oldText) {
        editorAPI.text.handleDataChange(
          textManager,
          compRef,
          newText,
          1,
          getLinksHelper().getAllLinks(),
        );
      }
    },
    onTextManagerReady: (manager: TextManager, ckeElement: HTMLDivElement) => {
      textManager = manager;
      editorAPI.text.setCKEditorDM();
      editorAPI.text.setCurrentTextEditor(manager, QUICKEDIT);
      overrideCkeStyle(manager);
      setCurrentLinks(editorAPI, compRef);
      startTextEditing(manager, ckeElement);
    },
    reportTextChangedIfNeeded: (
      valueRecordRef: RefObject<ComponentValueRecord>,
    ) => {
      const { initial, last } = valueRecordRef.current;
      if (_.isEqual(initial, last)) return;
      reportTextChanged(editorAPI, store, initial, last, compRef);
    },
  };
};
