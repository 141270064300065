import React from 'react';
import {
  Composites,
  Divider,
  InfoIcon,
  TextInput,
  TextLabel,
} from '@wix/wix-base-ui';

import * as baseUI from '@/baseUI';
import { translate } from '@/i18n';
import { hoc, validate } from '@/util';
import constants from '@/constants';

import { mapDispatchToProps } from './WhatsAppLink.mapper';

const {
  connect,
  STORES: { EDITOR_API },
} = hoc;

interface TPhoneLink {
  type: string;
  phoneNumber?: string;
}

type WhatsAppLinkComponentProps = {
  panelName: string;
  link: TPhoneLink;
  onValidationUpdate(isValid: boolean): void;
  onLinkChange(link: TPhoneLink): void;
} & ReturnType<typeof mapDispatchToProps>;

interface WhatsAppLinkComponentState {
  link: TPhoneLink;
  isPhoneNumberValid: boolean;
}

const isValidInternationalPhone = (phoneNumber: string) => {
  return validate.internationalPhone(phoneNumber);
};

class WhatsAppLinkComponent extends React.Component<
  WhatsAppLinkComponentProps,
  WhatsAppLinkComponentState
> {
  constructor(props: AnyFixMe) {
    super(props);
    const link = props.link || props.createDefaultData('WhatsAppLink');
    this.state = {
      link,
      isPhoneNumberValid: !!link.phoneNumber,
    };
  }

  componentDidMount() {
    this.props.onLinkChange(this.state.link);
    this.props.onValidationUpdate(this.state.isPhoneNumberValid);
  }

  componentDidUpdate(_prevProps: AnyFixMe, prevState: AnyFixMe) {
    if (prevState.link !== this.state.link) {
      this.props.onLinkChange(this.state.link);
    }
    if (this.state.isPhoneNumberValid !== prevState.isPhoneNumberValid) {
      this.props.onValidationUpdate(this.state.isPhoneNumberValid);
    }
  }

  private setPhoneNumber = (phoneNumber: AnyFixMe) => {
    const { link } = this.state;
    this.setState({
      link: {
        ...link,
        phoneNumber,
      },
    });
  };

  private handlePhoneNumberValidation = (isValid: AnyFixMe) => {
    this.setState({
      isPhoneNumberValid: isValid,
    });
  };

  private openLearnMore = () => {
    const biHelpParams = {
      panel_name: this.props.panelName,
      origin: constants.BI.HELP.ORIGIN.PANEL,
      learn_more: true,
    };
    const helpItem = '25e5d809-cd6e-4966-9515-92175603e433';
    this.props.openHelpCenter(helpItem, null, biHelpParams);
  };

  render() {
    return (
      <>
        <Composites.TextInputLabeled>
          <InfoIcon text="LINK_PANEL_PHONE_DESCRIPTION_TOOLTIP" />
          <TextLabel
            value="LINK_PANEL_PHONE_INPUT_LABEL"
            automationId="link-panel-whatsapp-number-label"
          />
          <TextInput
            key="whatsappNumber"
            validator={isValidInternationalPhone}
            invalidMessage="Validation_V1_Phone"
            placeholder="LINK_PANEL_PHONE_INPUT_DEFAULT"
            validateInitialValue={false}
            maxLength={100}
            onChange={this.setPhoneNumber}
            value={this.state.link.phoneNumber}
            onValidationStatus={this.handlePhoneNumberValidation}
            focus={true}
          />
        </Composites.TextInputLabeled>
        <Divider />

        <p className="extra-info">
          {translate('LINK_PANEL_PHONE_WHATSAPP_DESCRIPTION')}&nbsp;
          <baseUI.button
            label="LINK_PANEL_PHONE_WHATSAPP_LINK"
            onClick={this.openLearnMore}
            className="btn-text inline-link-button"
          />
        </p>
      </>
    );
  }
}

export const WhatsAppLink = connect(
  EDITOR_API,
  null,
  mapDispatchToProps,
)(WhatsAppLinkComponent);

WhatsAppLink.pure = WhatsAppLinkComponent;
