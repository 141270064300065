export const delay = () => {
  let timer: ReturnType<typeof setTimeout>;

  return {
    set: (func: () => void, time: number): void => {
      timer = setTimeout(func, time);
    },
    abort: (): void => {
      clearTimeout(timer);
    },
  };
};
