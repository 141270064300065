import _ from 'lodash';
import constants from '@/constants';
import * as coreBi from '@/coreBi';
import {
  startNewWorkspaceLeftBarHighlightTour,
  startWelcomeTour,
  TourStartOrigin,
} from '@/editorTours';
import { languageSelectorButton } from '@/newAddPanelAPI';
// eslint-disable-next-line @wix/santa-editor/scoped-imports
import { openAccessibilityWizard } from '@/platform/api/editor/accessibility/accessibility';
import * as stateManagement from '@/stateManagement';
import {
  editorModel,
  editorWixRecorder,
  fedopsLogger,
  languages,
  multilingual,
  sections,
  topBar,
  workspace,
} from '@/util';

import type { EditorAPI } from '@/editorAPI';
import type { BiEventDefinition, BiEventFields } from 'types/bi';
import type { SwitchEditorModeInteractionStartedSource } from 'types/fedops/mobileEditorInteraction';
import type {
  MapDispatchToPropsFunction,
  MapStateToProps,
  ThunkAction,
} from 'types/redux';
import type {
  TopBarDispatchProps,
  TopBarOwnProps,
  TopBarStateProps,
} from './topBar';

const { getSiteUserPreferences } = stateManagement.userPreferences.selectors;
const { getDeviceType } = stateManagement.leftBar.selectors;
const { openDockedSections } = stateManagement.sectionedPanel.selectors;
const { selectors: savePublishSelectors, actions: savePublishActions } =
  stateManagement.savePublish;
const { getOverriddenExternalLinks } = stateManagement.externalLinks.selectors;
const { isInInteractionMode } = stateManagement.interactions.selectors;
const { exitInteractionModeIfNeeded } = stateManagement.interactions.actions;

const shouldShowLanguageSelect = (props: AnyFixMe) => {
  const { translationLanguages, isMultilingualSite, inInteractionMode } = props;
  const hasTranslatedLanguages = translationLanguages?.length;
  return isMultilingualSite && hasTranslatedLanguages && !inInteractionMode;
};

const getCountryCode = (props: AnyFixMe) => {
  const { currentLanguageCode, originalLanguage, translationLanguages } = props;

  const locales = []
    .concat(originalLanguage, translationLanguages)
    .filter(_.identity)
    .reduce((acc, val) => ({ ...acc, [val.languageCode]: val }), {});

  return locales[currentLanguageCode]
    ? locales[currentLanguageCode].countryCode
    : undefined;
};

const mapStateToProps: MapStateToProps<TopBarStateProps, TopBarOwnProps> = ({
  editorAPI,
  state,
  dsRead,
}) => {
  const {
    savingStatus,
    previewingStatus,
    publishingStatus,
    testSiteStatus,
    shouldHideToolsBtnBlink,
    hideTools,
    imageCrop,
  } = state;

  const isSectionsExperience = sections.isSectionsEnabled();

  const isHidden = isSectionsExperience ? false : hideTools;

  const { getOverrideGetters } = stateManagement.topBar.selectors;
  const isFirstSave =
    _.invoke(editorAPI, 'dsRead.generalInfo.isFirstSave') || false;
  const isDraftMode =
    _.invoke(editorAPI, 'dsRead.generalInfo.isDraft') || false;
  const overrideGetters = getOverrideGetters(state);
  const getADITooltipVisibility = () => {
    const hasSiteBeenADI = editorAPI.site.hasSiteBeenADI();
    const hasSeenBackToAdiTooltip =
      stateManagement.userPreferences.selectors.getSiteUserPreferences(
        'hasSeenBackToAdiTooltip',
      )(editorAPI.store.getState());
    return hasSiteBeenADI && !hasSeenBackToAdiTooltip;
  };
  const userPermissions: string[] =
    _.invoke(editorAPI, 'generalInfo.getUserPermissions') || [];
  const showTopBarBanner =
    stateManagement.topBar.selectors.getBannerVisibility(state);

  const isZoomMode = editorAPI.zoomMode.isInZoomMode();

  const props: TopBarStateProps = {
    savingStatus,
    previewingStatus,
    publishingStatus,
    testSiteStatus,
    shouldHideToolsBtnBlink,
    isHidden,
    imageCrop,
    openPanels: stateManagement.panels.selectors.selectOpenPanels(state),
    isEditorSearchPanelOpen:
      stateManagement.editorSearch.selectors.isPanelOpen(state),
    inInteractionMode: isInInteractionMode(state),
    currentPageId: dsRead.pages ? dsRead.pages.getFocusedPageId() || '' : '',
    areToolsHiddenAndStagePositionMaintained:
      editorAPI.toolsControl.areToolsHiddenAndStagePositionMaintained() ||
      editorAPI.mobile.mobileWizard.isEnabled(),
    isMobileEditor: editorAPI.isMobileEditor(),
    hasUserSeenMobileEditor: editorAPI.mobile.mobileEditor.wasSeenBefore(),
    isPreviewMode: editorAPI.preview.isInPreviewMode(),
    isZoomMode,
    isMinimized: isSectionsExperience ? false : isZoomMode,
    idePaneState: editorAPI.developerMode.ui.idePane.getState(),
    isPublishInProgress: editorAPI.savePublish.isPublishInProgress(),
    isBuildInProgress:
      stateManagement.savePublish.selectors.getIsBuildInProgress(state),
    isSaveInProgress: editorAPI.savePublish.isSaveInProgress(),
    isFirstSave,
    isDraftMode,
    isSiteFromOnBoarding:
      _.invoke(editorAPI, 'dsRead.generalInfo.isSiteFromOnBoarding') || false,
    isSitePublished:
      _.invoke(editorAPI, 'dsRead.generalInfo.isSitePublished') || false,
    siteId: _.invoke(editorAPI, 'dsRead.generalInfo.getSiteId'),
    metaSiteId:
      _.invoke(editorAPI, 'dsRead.generalInfo.getMetaSiteId') ||
      editorModel?.metaSiteId,
    metaSiteInstance: _.invoke(
      editorAPI,
      'dsRead.platform.getAppDataByApplicationId',
      constants.APPLICATIONS.META_SITE_APPLICATION_ID,
    )?.instance,
    sitePublicUrl: editorAPI.site.getSitePublicUrl() || '',
    canUserPublish: editorAPI.savePublish.canUserPublish(),
    isMobileOptimized: editorAPI.isMobileOptimizedSite(),
    isSavedInCurrentSession: editorAPI.isSavedInCurrentSession,
    deviceType: getDeviceType(state),
    shouldSaveBeforePublish:
      isFirstSave ||
      isDraftMode ||
      savePublishSelectors.getOverridingSaveAndPublish(
        editorAPI.store.getState(),
      ),
    isToolBarEnabled: Boolean(editorAPI.toolbar.isToolbarEnabled()),
    isLayersPanelDisplayed:
      stateManagement.layersPanel.selectors.isLayersPanelDisplayed(state),
    isRulersEnabled: Boolean(
      stateManagement.rulers.selectors.isRulersEnabled(state),
    ),
    isGuidelinesEnabled: Boolean(
      editorAPI.grid.guidelines.isGuidelinesEnabled(),
    ),
    isSnapToEnabled: Boolean(editorAPI.grid.snapTo.isSnapToEnabled()),
    isDeveloperModeEnabled: Boolean(editorAPI.developerMode.isEnabled()),
    isWixCodeProvisioned: Boolean(_.invoke(editorAPI, 'wixCode.isProvisioned')),
    isDeveloperToolBarEnabled: Boolean(
      editorAPI.developerToolBar.isEnabled() ||
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/some
        _.some(
          openDockedSections(state),
          (sectionName) =>
            sectionName === constants.DOCKED_PANEL_SECTIONS.PROPERTIES,
        ),
    ),
    isDeveloperToolbarInCodePanel: Boolean(
      editorAPI.developerToolBar.isInCodePanel(),
    ),
    isDeveloperHiddenComponentsEnabled: Boolean(
      editorAPI.developerHiddenComponents.isEnabled(),
    ),
    isTemplate: _.result(editorAPI, 'dsRead.generalInfo.isTemplate'),
    isUserOwner: editorAPI.account.isUserOwner(),
    overriddenMenus: _.invoke(overrideGetters, 'getMenuOverrides', state),
    overriddenSiteMenu: _.invoke(
      overrideGetters,
      'getSiteMenuOverrides',
      state,
    ),
    overriddenSettingsMenu: _.invoke(
      overrideGetters,
      'getSettingsMenuOverrides',
      state,
    ),
    overriddenToolsMenu: _.invoke(
      overrideGetters,
      'getToolsMenuOverrides',
      state,
    ),
    overriddenHelpMenu: _.invoke(
      overrideGetters,
      'getHelpMenuOverrides',
      state,
    ),
    overriddenCodeMenu: _.invoke(
      overrideGetters,
      'getCodeMenuOverrides',
      state,
    ),
    overriddenLeftButtons: _.invoke(
      overrideGetters,
      'getLeftButtonsOverrides',
      state,
      editorAPI,
    ),
    overriddenRightButtons: _.invoke(
      overrideGetters,
      'getRightButtonsOverrides',
      state,
      editorAPI,
    ),
    overriddenCustomDropPanels: _.invoke(
      overrideGetters,
      'getCustomDropPanelsOverrides',
      state,
    ),
    overriddenQuickNavigation:
      stateManagement.topBar.selectors.getOverriddenQuickNavigation(state),
    overriddenExternalLinks: getOverriddenExternalLinks(state),
    hasNewReleasesNotification:
      stateManagement.newReleases.selectors.hasNotification(state),
    isActivePersonalSaleCampaign:
      editorAPI.campaigns.personalSale.isActiveCampaign(),
    personalSaleCampaignAssetPath:
      editorAPI.campaigns.personalSale.getAssetPath(),
    personalSaleCampaignDiscountValue:
      editorAPI.campaigns.personalSale.isActiveCampaign()
        ? editorAPI.campaigns.personalSale.getDiscountValue()
        : null,
    developerModeFirstTime:
      stateManagement.userPreferences.selectors.getSiteUserPreferences<boolean>(
        constants.USER_PREFS.DEVELOPER_MODE.FIRST_TIME,
      )(state) || false,
    openedDropPanel: editorAPI.topBarMenuBar.getOpenedDropDown(),
    languageCode: languages.getLanguageCode(),
    hasSiteBeenADI: editorAPI.site.hasSiteBeenADI(),
    canSiteBeRestoredToADI: editorAPI.site.canRestoreToADI(),
    autosaveDisabledOnDS:
      _.invoke(editorAPI, 'dsRead.getAutoSaveInfo')?.shouldAutoSave === false,
    autosaveEnabled: !!editorAPI.autosaveManager.isAutosaveEnabled(),
    forceOpenPagesQuickNavigationEventCounter:
      editorAPI.getForceOpenPagesQuickNavigationEventCounter(),
    wixReactDealerViewer: editorAPI.wixReactDealerViewer,
    wixDealerClientApi: editorAPI.wixDealerClientApi,
    isDealerCssLoaded: editorAPI.isDealerCssLoaded,
    showTopBarBanner: showTopBarBanner && !isHidden,
    topBarBannerTitle: stateManagement.topBar.selectors.getBannerTitle(state),
    shouldShowADITooltip: getADITooltipVisibility(),
    hasSeenBackToAdiTooltip:
      stateManagement.userPreferences.selectors.arePreferencesEmpty('site')
        ? false
        : stateManagement.userPreferences.selectors.getSiteUserPreferences<boolean>(
            'hasSeenBackToAdiTooltip',
          )(editorAPI.store.getState()),
    didPassWelcomeToEditorPanel:
      stateManagement.userPreferences.selectors.arePreferencesEmpty('global')
        ? false
        : stateManagement.userPreferences.selectors.getGlobalUserPreferences<boolean>(
            'didPassCameFromADIVideo',
          )(editorAPI.store.getState()),
    getBackToAdiTooltipVisibility:
      stateManagement.topBar.selectors.getBackToAdiTooltipVisibility(
        editorAPI.store.getState(),
      ) && editorAPI.getOpenPanels().length === 0,
    isChatLoaded: stateManagement.unifiedChat.selectors.isChatLoaded(state),
    isMac: state.topBar.isMac,
    isChatVisible: stateManagement.unifiedChat.selectors.isChatVisible(state),
    userPermissions,
    branchId: editorAPI.dsRead?.generalInfo?.getBranchId(),
    undoLastSnapshotParams:
      editorAPI.documentServices?.history.getUndoLastSnapshotParams(),
    redoLastSnapshotParams:
      editorAPI.documentServices?.history.getRedoLastSnapshotParams(),
    numberOfManualPublish: getSiteUserPreferences('numberOfManualPublish')(
      editorAPI.store.getState(),
    ) as number,
  };

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/is-nil
  if (!_.isNil(editorAPI.dsRead)) {
    const { language } = editorAPI.dsRead;
    props.currentLanguageCode = language.current.get();
    props.isMultilingualSite = language.multilingual.isEnabled();
    props.originalLanguage = language.original.get();
    props.translationLanguages = language.get();
    const currentLanguage = props.translationLanguages.find(
      (language) => language.languageCode === props.currentLanguageCode,
    );
    props.shouldCurrentLanguageInvisible =
      language.isCurrentLanguageSecondary() &&
      multilingual.languages.shouldLanguageBeInvisible(
        props.isPreviewMode,
        currentLanguage,
      );
  } else {
    props.currentLanguageCode = null;
    props.isMultilingualSite = false;
    props.supportedLanguages = [];
    props.shouldCurrentLanguageInvisible = false;
  }

  props.tpaMlState = stateManagement.appsStore.selectors.getAppStore(
    state,
    constants.MULTILINGUAL.linguistDefId,
  );
  props.showLanguageSelect = shouldShowLanguageSelect(props);
  props.currentCountryCode = getCountryCode(props);

  return props;
};

const dispatchEditorAPI =
  <TArgs extends any[]>(
    dispatch: AnyFixMe,
    f: (editorAPI: EditorAPI, ...args: TArgs) => any,
  ) =>
  (...args: TArgs) =>
    dispatch(
      (_dispatch: AnyFixMe, getState: AnyFixMe, { editorAPI }: AnyFixMe) =>
        f(editorAPI, ...args),
    );

const getSendBi = (dispatch: AnyFixMe) =>
  dispatchEditorAPI(
    dispatch,
    (
      editorAPI,
      eventType: BiEventDefinition,
      params: BiEventFields,
      paramsBuilder?: (editorAPI: EditorAPI) => BiEventFields,
    ) => {
      const biParams = paramsBuilder ? paramsBuilder(editorAPI) : params;
      dispatch(stateManagement.bi.actions.event(eventType, biParams));
    },
  );

const getEditorAPI: ThunkAction<EditorAPI> = (
  dispatch,
  getState,
  { editorAPI },
) => editorAPI;

const mapDispatchToProps: MapDispatchToPropsFunction<
  TopBarDispatchProps,
  TopBarOwnProps
> = (dispatch) => ({
  getInvalidLoggedUser: async (): Promise<string | null> => {
    const editorAPI = dispatch(getEditorAPI);
    return editorAPI.account.getUserInvalidLoggedStatus();
  },
  handleInvalidLoggedUser: (invalidStatus: string): void => {
    const editorAPI = dispatch(getEditorAPI);
    return editorAPI.account.handleInvalidLoggedUser(invalidStatus);
  },
  sendBiForLoginRelatedError: (): void => {
    const editorAPI = dispatch(getEditorAPI);
    editorAPI.bi.event(coreBi.events.panels.login_related_error_message_show);
  },
  siteSaveAndPublish: (options) =>
    dispatch(savePublishActions.saveAndPublish(options)),
  sitePublish: (options) => dispatch(savePublishActions.publish(options)),
  publish: (origin) => dispatch(savePublishActions.publish({ origin })),
  loadSupportedLanguages() {
    const editorAPI = dispatch(getEditorAPI);
    const mlSelectors = stateManagement.multilingual.selectors;
    const state = editorAPI.store.getState();
    if (
      !mlSelectors.loading(state).isLoading &&
      _.isEmpty(mlSelectors.supportedLanguages(state))
    ) {
      dispatch(
        stateManagement.multilingual.actions.loadSupportedLanguagesThunk(),
      );
    }
  },
  closeBackToAdiTooltip: () =>
    dispatch(stateManagement.topBarTooltip.actions.closeBackToAdiTooltip()),
  showBackToAdiTooltip: () =>
    dispatch(stateManagement.topBarTooltip.actions.showBackToAdiTooltip()),
  openPanel(panelName, panelProps, leavePanelsOpen) {
    dispatch(
      stateManagement.panels.actions.updateOrOpenPanel(
        panelName,
        panelProps,
        leavePanelsOpen,
      ),
    );
  },
  isAllowed: () => true,
  openHelpCenter(helpId, props, biHelpParams) {
    dispatch(
      stateManagement.panels.actions.openHelpCenter(
        helpId,
        props,
        biHelpParams,
      ),
    );
  },
  openHelpCenterHome(type, props, biHelpParams) {
    dispatch(
      stateManagement.panels.actions.openHelpCenterHome(
        type,
        props,
        biHelpParams,
      ),
    );
  },
  openNewReleasesFeed() {
    dispatch(
      stateManagement.panels.actions.openRightPanel(
        'rEditor.NewReleasesFeedPanel',
      ),
    );
  },
  openHelpHome() {
    fedopsLogger.interactionStarted(fedopsLogger.INTERACTIONS.HELP_HOME_OPEN);
    dispatch(
      stateManagement.panels.actions.openRightPanel('rEditor.HelpHomePanel', {
        origin: 'help_menu',
      }),
    );
  },
  undo() {
    dispatch(stateManagement.history.actions.undo());
  },
  redo() {
    dispatch(stateManagement.history.actions.redo());
  },
  openSearchPanel(origin) {
    dispatch(
      stateManagement.editorSearch.actions.openEditorSearchPanel({ origin }),
    );
  },
  setTopBarSiteHistoryUsed() {
    dispatch(
      stateManagement.userPreferences.actions.setSiteUserPreferences(
        'topBarSiteHistoryUsed',
        true,
      ),
    );
  },
  setPanelType(
    panelName,
    panelType,
    singleInstance,
    closeWithUserIntent,
    isHidden,
  ) {
    dispatch(
      stateManagement.panels.actions.setPanelType(
        panelName,
        panelType,
        singleInstance,
        closeWithUserIntent,
        isHidden,
      ),
    );
  },
  openConnectDomain: dispatchEditorAPI(
    dispatch,
    (editorAPI: EditorAPI, flowId, isFirstPublish) =>
      editorAPI.account.openConnectDomain(flowId, isFirstPublish),
  ),
  saveAndPublish: (origin) =>
    dispatch(savePublishActions.saveAndPublish({ origin })),
  publishRC: dispatchEditorAPI(dispatch, (editorAPI, origin) => {
    if (!editorAPI.savePublish.isSavePublishLocked()) {
      void editorAPI.publishManager.publishRC(origin);
    }
  }),
  save: dispatchEditorAPI(dispatch, (editorAPI, origin, sourceOfStart) =>
    editorAPI.saveManager.save({ origin, sourceOfStart }),
  ),
  saveSite: dispatch(getEditorAPI).saveManager.save,
  upgrade: dispatchEditorAPI(dispatch, (editorAPI, referralAdditionalInfo) =>
    editorAPI.account.upgrade(referralAdditionalInfo),
  ),
  sendBi: getSendBi(dispatch),
  toggleToolBar: dispatchEditorAPI(dispatch, (editorAPI) => {
    editorAPI.toolbar.toggleToolbar();
    dispatch(
      stateManagement.userPreferences.actions.setSiteUserPreferences(
        'topBarCloseToolsUsed',
        true,
      ),
    );
  }),
  toggleLayersPanel: dispatchEditorAPI(dispatch, (editorAPI) => {
    const state = editorAPI.store.getState();
    const isLayersPanelDisplayed =
      stateManagement.layersPanel.selectors.isLayersPanelDisplayed(state);

    if (isLayersPanelDisplayed) {
      dispatch(stateManagement.layersPanel.actions.hideLayersPanel());
    } else {
      const origin = 'tools menu';
      editorWixRecorder.addLabel('panels.toolPanels.layersPanel panel opened');
      editorAPI.bi.event(coreBi.events.panels.PANEL_OPENED, {
        panel_name: 'panels.toolPanels.layersPanel',
        origin,
      });
      dispatch(stateManagement.layersPanel.actions.showLayersPanel(origin));
    }
  }),
  toggleHideTools: dispatchEditorAPI(dispatch, (editorAPI) => {
    const areToolsHidden = editorAPI.toolsControl.toggleHideTools();

    dispatch(
      stateManagement.userPreferences.actions.setSessionUserPreferences(
        'hideTools',
        areToolsHidden,
      ),
    );

    const eventName = areToolsHidden
      ? coreBi.events.editor.hide_tools.hide_controls
      : coreBi.events.editor.hide_tools.show_controls;

    dispatch(
      stateManagement.bi.actions.event(eventName, {
        origin: 'handle',
      }),
    );
  }),
  togglePreviewSimple: dispatchEditorAPI(dispatch, (editorAPI) =>
    editorAPI.preview.togglePreview(undefined, {
      biParams: { origin: constants.BI.PREVIEW.ORIGIN.TOP_BAR },
    }),
  ),

  enterZoomMode: dispatchEditorAPI(dispatch, (editorAPI) => {
    editorAPI.zoomMode.enterZoomMode({ biParams: { origin: 'topbar' } });
    editorWixRecorder.addLabel('zoomout top bar clicked');
    dispatch(
      stateManagement.bi.actions.event(coreBi.events.topbar.top_bar_click, {
        category: 'zoomout',
        is_published: editorAPI.dsRead?.generalInfo.isSitePublished(),
      }),
    );
  }),

  exitZoomMode: () => {
    const editorAPI: EditorAPI = dispatch(getEditorAPI);
    editorAPI.zoomMode.exitZoomMode({ biParams: { origin: 'topbar' } });
  },

  togglePreview: dispatchEditorAPI(dispatch, (editorAPI) => {
    if (!editorAPI.savePublish.isSavePublishLocked()) {
      editorAPI.savePublish.lockSavePublish();
      editorAPI.preview.togglePreview(undefined, {
        biParams: { origin: constants.BI.PREVIEW.ORIGIN.TOP_BAR },
      });
      window.setTimeout(editorAPI.savePublish.unlockSavePublish, 500);
    }
  }),
  setEditorMode: dispatchEditorAPI(
    dispatch,
    (
      editorAPI,
      isMobile: boolean,
      sourceOfStart: SwitchEditorModeInteractionStartedSource,
      preventUndoRedoStack?: boolean,
    ) => editorAPI.setEditorMode(isMobile, sourceOfStart, preventUndoRedoStack),
  ),
  toggleRulers() {
    dispatch(stateManagement.rulers.actions.toggleRulers());
  },
  toggleGuidelines: dispatchEditorAPI(
    dispatch,
    (editorAPI, onSuccess, onFail) =>
      editorAPI.grid.guidelines.toggleGuidelines(onSuccess, onFail),
  ),

  toggleSnapTo: dispatchEditorAPI(dispatch, (editorAPI, onSuccess, onFail) =>
    editorAPI.grid.snapTo.toggleSnapTo(onSuccess, onFail),
  ),
  toggleDeveloperMode: dispatchEditorAPI(dispatch, (editorAPI, options) =>
    editorAPI.developerMode.toggle(options),
  ),
  toggleDeveloperToolBar: dispatchEditorAPI(dispatch, (editorAPI) =>
    editorAPI.developerToolBar.toggle(),
  ),
  toggleDeveloperHiddenComponents: dispatchEditorAPI(dispatch, (editorAPI) =>
    editorAPI.developerHiddenComponents.toggle(),
  ),
  getEditorVersionsInfo: dispatchEditorAPI(dispatch, (editorAPI) =>
    editorAPI.account.getEditorVersionsInfo(),
  ),
  openWixSEOWiz: dispatchEditorAPI(dispatch, (editorAPI) =>
    editorAPI.account.openWixSEOWiz('EDITOR_MENU'),
  ),
  manage: dispatchEditorAPI(dispatch, (editorAPI) =>
    editorAPI.account.manage(),
  ),
  openSettings: dispatchEditorAPI(dispatch, (editorAPI, tab, referrer) =>
    editorAPI.account.openSettings({ tab, referrer }),
  ),
  openRevisions: dispatchEditorAPI(dispatch, (editorAPI) =>
    editorAPI.account.openRevisions(),
  ),
  openMoveSiteToTrashConfirmation: dispatchEditorAPI(
    dispatch,
    (editorAPI, panelName, panelProps?, leavePanelsOpen?) =>
      editorAPI.panelManager.openPanel(panelName, panelProps, leavePanelsOpen),
  ),
  openReleaseManager: dispatchEditorAPI(dispatch, (editorAPI) =>
    editorAPI.account.openReleaseManager(),
  ),
  openSiteBranchesManager: dispatchEditorAPI(dispatch, (editorAPI) =>
    editorAPI.account.openSiteBranchesManager({ inNewTab: true }),
  ),
  myAccountAction: dispatchEditorAPI(dispatch, (editorAPI, action) =>
    editorAPI.account.myAccountAction(action),
  ),
  openPremiumSettings: dispatchEditorAPI(dispatch, (editorAPI, tab) =>
    editorAPI.account.openPremiumSettings(tab),
  ),
  exitEditor: dispatchEditorAPI(dispatch, (editorAPI) =>
    editorAPI.exitEditor(),
  ),
  accountUpgrade: dispatchEditorAPI(
    dispatch,
    (editorAPI, referralAdditionalInfo) =>
      editorAPI.account.upgrade(referralAdditionalInfo),
  ),
  setMobileOptimizedSite: dispatchEditorAPI(
    dispatch,
    (editorAPI, isOptimized) => editorAPI.setMobileOptimizedSite(isOptimized),
  ),
  waitForChangesApplied: dispatchEditorAPI(
    dispatch,
    (editorAPI, callback, onlyChangesAlreadyRegistered?) => {
      if (editorAPI.dsActions) {
        editorAPI.dsActions.waitForChangesApplied(
          callback,
          onlyChangesAlreadyRegistered,
        );
      }
    },
  ),
  exitInteractionModeIfNeeded: dispatchEditorAPI(dispatch, (editorAPI) => {
    exitInteractionModeIfNeeded(editorAPI);
  }),

  collapseMobileMenu() {
    dispatch(stateManagement.leftBar.actions.collapseMobileMenu());
  },
  getFeedbackNewCommentsCounter: dispatchEditorAPI(dispatch, (editorAPI) => {
    const newCommentsCounter = editorAPI.feedback.getNewCommentsCounter();
    return newCommentsCounter > 99 ? '99+' : newCommentsCounter;
  }),
  closeAllPanels: dispatchEditorAPI(dispatch, (editorAPI) =>
    editorAPI.panelManager.closeAllPanels(),
  ),
  openDropPanel: dispatchEditorAPI(
    dispatch,
    (
      editorAPI,
      dropPanelName: string,
      dropPanelActiveItem?: string,
      shouldRemainOpen?: boolean,
    ) =>
      editorAPI.topBarMenuBar.openDropDown(
        dropPanelName,
        dropPanelActiveItem,
        shouldRemainOpen,
      ),
  ),
  closeDropPanel: dispatchEditorAPI(
    dispatch,
    (editorAPI, dropPanelName?: string) =>
      editorAPI.topBarMenuBar.closeDropDown(dropPanelName),
  ),
  openMobileElementsPanel: dispatchEditorAPI(dispatch, (editorAPI) =>
    editorAPI.panelHelpers.openMobileElementsPanel(),
  ),
  toggleRightSection: (sectionName) =>
    dispatch(stateManagement.sectionedPanel.actions.toggleSection(sectionName)),
  onMultilingualUpdated: topBar.isNewTopBarEnabled()
    ? () => {
        // Its here because of https://github.com/wix-private/santa-editor/pull/22652
        // I think it should not be here but it turned out to be rather difficult to refactor
        // TODO: Should be done by appBuilder

        dispatch(
          stateManagement.multilingual.actions.dataUpdated(
            languageSelectorButton,
          ),
        );
      }
    : (languageSelectorPreset) =>
        dispatch(
          stateManagement.multilingual.actions.dataUpdated(
            languageSelectorPreset,
          ),
        ),
  onSyncFlagButton: () =>
    dispatch(
      stateManagement.multilingual.actions.syncIsTranslatingStateThunk(),
    ),
  showOrHideChat: (visibility) =>
    dispatch(stateManagement.unifiedChat.actions.showOrHideChat(visibility)),
  openAccessibilityWizard: dispatchEditorAPI(dispatch, openAccessibilityWizard),
  startWelcomeTour: dispatchEditorAPI(
    dispatch,
    (editorAPI, startOrigin?: TourStartOrigin) => {
      return workspace.isNewWorkspaceFTETourEnabled()
        ? startNewWorkspaceLeftBarHighlightTour(editorAPI, startOrigin)
        : startWelcomeTour(editorAPI, startOrigin);
    },
  ),
});

export { mapStateToProps, mapDispatchToProps, getSendBi, dispatchEditorAPI };
