import * as util from '@/util';
import { translate } from '@/i18n';

import type { SectionsMigrationScope as Scope } from '../scope';

export function renameRevision(scope: Scope, name: string) {
  const revision = scope.editorAPI.documentServices.generalInfo.getRevision();
  const locale = util.languages.getLanguageCode();
  const dateTimeFormat = new Intl.DateTimeFormat(locale, {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  }).format(Date.now());

  scope.editorAPI.site.renameRevision(
    revision,
    `${dateTimeFormat} | ${translate(name)}`,
  );
}
