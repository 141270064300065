'use strict'

const _ = require('lodash')
const addPanelDataConsts = require('@/addPanelDataConsts')
const tinyMenuStructure = require('./tinyMenu/tinyMenuStructure')
const tinyMenuStyles = require('./tinyMenu/tinyMenuStyles')

const ITEMS_PER_ROW = 4
const ITEM_WIDTH = 324 / 4
const ITEM_HEIGHT = 277 / 3

function getItem(structureChanges, index) {
    const col = index % ITEMS_PER_ROW
    const row = Math.floor(index / ITEMS_PER_ROW)

    return {
        id: `Special_Tiny_Menu_Style_${index}`,
        preset: {
            rect: {width: ITEM_WIDTH, height: ITEM_HEIGHT, x: ITEM_WIDTH * col, y: row * ITEM_HEIGHT},
            label: ''
        },
        structure: _.merge({}, tinyMenuStructure, structureChanges)
    }
}

module.exports = {
    'type': addPanelDataConsts.SECTIONS_TYPES.PRESET,
    'hide': false,
    'title': 'mobilemenu_design_section_explore',
    'showSectionHeader': true,
    'additionalBehaviours': {
        'labelMode': addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
        'infoIcon': addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
        'hoverImageAction': addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
        'iconEnabledForComps': {}
    },
    'props': {
        'image': 'addPanelData/sections/tinyMenuStyleSection_en/tinyMenuStyleSection_agadir_en.png',
        'imageHover': null,
        'items': _.map(tinyMenuStyles, getItem),
        'compTypes': ['wysiwyg.viewer.components.mobile.TinyMenu']
    }
}
