// @ts-nocheck
import ReactDOM from 'reactDOM';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import * as util from '@/util';
import constants from '@/constants';
import * as core from '@/core';
import { superAppsConstants, appMarketTabService } from '@/tpa';
import _ from 'lodash';
import * as coreBi from '@/coreBi';
import * as stateManagement from '@/stateManagement';
import {
  getSettingsIframeUrl,
  isWixMultilingualClicked,
} from '../../../utils/siteSettingsUtil';

import React from 'react';
import * as BaseUI from '@/baseUI';
import * as Panels from '@/panels';
import { cx } from '@/util';

const MESSAGES = {
  CLOSE: 'CLOSE',
  OPEN_HELP: 'OPEN_HELP',
  SET_METATAGS: 'SET_METATAGS',
  VALIDATE_METATAGS: 'VALIDATE_METATAGS',
  GET_METATAGS: 'GET_METATAGS',
  NOTIFY_SITENAME_CHANGED: 'NOTIFY_SITENAME_CHANGED',
  CLOSE_AND_REDIRECT: 'CLOSE_AND_REDIRECT',
  NOTIFY_REDIRECT_MANAGER_IS_READY: 'NOTIFY_REDIRECT_MANAGER_IS_READY',
  GET_AUTO_REDIRECT_RESULTS: 'GET_AUTO_REDIRECT_RESULTS',
  AUTO_REDIRECT_RESULTS: 'AUTO_REDIRECT_RESULTS',
  AUTO_REDIRECT_RESULTS_PAYLOAD: 'AUTO_REDIRECT_RESULTS_PAYLOAD',
};
const VALID_ORIGINS = [
  'https://www.wix.com',
  'http://www.wix.com',
  'https://manage.wix.com',
  'https://editor.wix.com',
  'https://manage.editorx.com',
];

function isValidPostMessageData(message) {
  return (
    _.isObject(message) &&
    !_.isEmpty(message.action) &&
    _.isString(message.action)
  );
}

function opneAppsPanelOnSlug(slug: string, editorAPI) {
  return appMarketTabService.openAppMarketTab(
    {
      urlParams: {
        slug,
      },
    },
    editorAPI.panelManager.openPanel,
  );
}

function sendPostMessageToFrame(message) {
  const frame = ReactDOM.findDOMNode(this.refs.siteSettingsFrame);
  frame.contentWindow.postMessage(JSON.stringify(message), '*');
}

function notifyIsValid(message, isValid) {
  sendPostMessageToFrame.call(this, { isValid, callId: message.callId });
}

function handlePostMessageAction(message, editorAPI) {
  const { action } = message;

  switch (action) {
    case MESSAGES.NOTIFY_REDIRECT_MANAGER_IS_READY:
      const getRedirectResultsMessage = {
        action: MESSAGES.GET_AUTO_REDIRECT_RESULTS,
        data: {},
      };
      window.postMessage(JSON.stringify(getRedirectResultsMessage), '*');
      break;
    case MESSAGES.AUTO_REDIRECT_RESULTS: {
      sendPostMessageToFrame.call(this, {
        action: MESSAGES.AUTO_REDIRECT_RESULTS_PAYLOAD,
        payload: message.data.redirectResults,
      });
      break;
    }
    case MESSAGES.GET_METATAGS:
      sendPostMessageToFrame.call(this, {
        payload: editorAPI.dsRead.seo.headTags.get(),
        callId: message.callId,
      });
      break;
    case MESSAGES.SET_METATAGS:
      editorAPI.dsActions.seo.headTags.set(message.value, _.noop, _.noop);
      break;
    case MESSAGES.VALIDATE_METATAGS:
      editorAPI.dsRead.seo.headTags.clientSideValidation(
        message.value,
        notifyIsValid.bind(this, message, true),
        notifyIsValid.bind(this, message, false),
      );
      break;
    case MESSAGES.NOTIFY_SITENAME_CHANGED:
      editorAPI.siteName.setAsync(message.value, _.noop, _.noop, false);
      break;
    case MESSAGES.CLOSE:
      this.props.close();
      break;
    case MESSAGES.OPEN_HELP:
      this.props.openHelpCenter();
      break;
    case MESSAGES.CLOSE_AND_REDIRECT:
      this.props.close();
      opneAppsPanelOnSlug(
        superAppsConstants.MULTI_LINGUAL_APP_DEF_ID,
        editorAPI,
      );
      break;
  }
}

// eslint-disable-next-line react/prefer-es6-class
const SiteSettingsPanelSimple = createReactClass({
  displayName: constants.PANEL_TYPES.SITE_SETTINGS,
  render() {
    return (
      <div>
        <Panels.frames.FocusPanelFrame
          shouldNotCloseOnBlur={true}
          panelName={this.props.panelName}
          width="95%"
          height="95%"
          shouldHideHeader={true}
          className="site-settings-panel"
          automationId="site-settings-panel"
        >
          <iframe
            ref="siteSettingsFrame"
            width="100%"
            height="100%"
            src={this.state.iframeUrl}
            frameBorder="0"
          />
          <BaseUI.preloader
            className={cx({ hidden: !this.state.showLoader })}
          />
        </Panels.frames.FocusPanelFrame>
      </div>
    );
  },
  mixins: [core.mixins.editorAPIMixin],
  propTypes: {
    extraParams: PropTypes.any,
    category: PropTypes.string,
    tab: PropTypes.string,
    excludeCategory: PropTypes.string,
    referrer: PropTypes.string,
    path: PropTypes.string,
  },
  getInitialState() {
    return {
      showLoader: true,
      iframeUrl: null,
    };
  },
  getDefaultProps() {
    return {
      category: 'manage-website',
      tab: 'overview',
      excludeCategory: false,
    };
  },
  UNSAFE_componentWillMount() {
    window.addEventListener('message', this.handlePostMessage, false);
    const editorAPI = this.getEditorAPI();
    const baseDomain = util.serviceTopology.businessManagerUrl;
    const metaSiteId = editorAPI.dsRead.generalInfo.getMetaSiteId();
    const iframeUrl = getSettingsIframeUrl({
      baseDomain,
      metaSiteId,
      category: this.props.category,
      excludeCategory: this.props.excludeCategory,
      tab: this.props.tab,
      extraParams: this.props.extraParams,
      path: this.props.path,
    });
    this.setState({
      iframeUrl,
    });
    editorAPI.account.openSiteSettingsBranchWarningIfNeeded({
      dashboardUrl: iframeUrl,
    });
  },
  componentWillUnmount() {
    window.removeEventListener('message', this.handlePostMessage, false);
  },
  handlePostMessage(event) {
    const editorAPI = this.getEditorAPI();
    if (
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/includes
      _.includes(VALID_ORIGINS, event.origin) &&
      !_.isEmpty(event.data) &&
      _.isString(event.data)
    ) {
      try {
        this.hideLoader();
        const message =
          typeof event.data === 'string' && JSON.parse(event.data);
        if (isWixMultilingualClicked(message)) {
          const newMessage = {
            ...message,
            action: MESSAGES.CLOSE_AND_REDIRECT,
          };
          handlePostMessageAction.call(this, newMessage, editorAPI);
        }
        if (message && isValidPostMessageData(message)) {
          handlePostMessageAction.call(this, message, editorAPI);
        }
      } catch (e) {
        editorAPI.dsActions.bi.error(
          coreBi.errors.SITE_SETTINGS_JSON_PARSE_ERROR,
          {
            dsc: `message: "${e.message}" data: "${event.data}"`,
            errscp: this.constructor.displayName,
          },
        );
      }
    }
  },
  hideLoader() {
    if (this.state.showLoader) {
      this.setState({
        showLoader: false,
      });
    }
  },
});

export default stateManagement.panels.hoc.panelFrame(
  constants.PANEL_TYPES.SITE_SETTINGS,
)(SiteSettingsPanelSimple);
