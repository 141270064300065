import React from 'react';
import { PromotionalModal } from '@wix/wix-base-ui';

import PanelFrameContainer, {
  type PanelFrameContainerProps,
} from '../panelFrameContainer/panelFrameContainer';

export interface PromotionalPanelFrameProps
  extends Omit<PanelFrameContainerProps, 'overlayDataHook'> {
  dataHook?: string;
  panelName: string;
  onCloseButtonClick: () => void;
  onHelpButtonClick?: () => void;
  title?: string;
  subtitle?: string;
  onPrimaryButtonClick: () => void;
  onSecondaryButtonClick?: () => void;
  primaryButtonText: string;
  primaryButtonProps?: any;
  secondaryButtonText?: string;
  footnote?: React.ReactNode;
  illustration: string | React.ReactNode;
  theme?: 'standard' | 'premium';
}

export const PromotionalPanelFrame: React.FC<PromotionalPanelFrameProps> = (
  props,
) => {
  return (
    <PanelFrameContainer
      className={props.className}
      onEscKeyPress={props.onEscKeyPress}
      keyboardShortcuts={props.keyboardShortcuts}
      onOuterClick={props.onOuterClick}
      overlayDataHook="PromotionalPanelFrame-overlay"
    >
      <PromotionalModal
        dataHook={props.dataHook}
        theme={props.theme}
        title={props.title}
        subtitle={props.subtitle}
        illustration={props.illustration}
        footnote={props.footnote}
        onCloseButtonClick={props.onCloseButtonClick}
        onHelpButtonClick={props.onHelpButtonClick}
        primaryButtonOnClick={props.onPrimaryButtonClick}
        secondaryButtonOnClick={props.onSecondaryButtonClick}
        primaryButtonText={props.primaryButtonText}
        primaryButtonProps={props.primaryButtonProps}
        secondaryButtonText={props.secondaryButtonText}
      >
        {props.children}
      </PromotionalModal>
    </PanelFrameContainer>
  );
};

PromotionalPanelFrame.defaultProps = {
  dataHook: 'PromotionalPanelFrame',
};

export default PromotionalPanelFrame;
