import _ from 'lodash';
import { ErrorReporter } from '@wix/editor-error-reporter';
import * as MediaImageStudio from '@wix/media-image-studio-opener';
import experiment from 'experiment';
import constants from '@/constants';
import * as util from '@/util';
import * as i18n from '@/i18n';
import * as stateManagement from '@/stateManagement';
import * as platform from '@/platform';
import * as tpa from '@/tpa';
import * as blog from '@/blog';
import * as wixBookings from '@/wixBookings';
import * as wixStore from '@/wixStore';
import * as wixData from '@/wixData';
import * as compPanelInfra from '@/compPanelInfra';
import * as menu from '@/menu';
import * as coreBi from '@/coreBi';
import * as componentDeprecation from '@/componentDeprecation';
import * as boxSlideshow from '@/boxSlideshow';
import { gfppModel, registerComponentGfppData } from '@/gfppData';
import { searchModule } from '@/editorSearch';
import {
  initSectionsOnStage,
  isSectionsOnStageEnabled,
} from '@/sectionsOnStage';
import {
  getHeadlessInstallAppsFn,
  getSilentInstallAppsFn,
} from './appsInstallationEntry';
import { init as initAppManager } from '@/appManager';
import {
  EditorCoreApiKey,
  EditorParamsApiKey,
  SectionsMigrationApiKey,
} from '@/apis';

import * as AddPanelAPI from '../APISections/addPanelAPI';
import * as compFactoryService from '../services/compFactoryService';
import * as bi from '../../bi/bi';
import * as deeplink from '../deeplink';

import { fontLicense } from './fontLicense';
import { initStage } from './initStage';

import type { Shell } from '@/apilib';
import type { EditorAPI } from '@/editorAPI';
import type { RegisterToSiteChangedCallback } from 'types/documentServices';

const { setChatIsLoaded, setChatVisibility } =
  stateManagement.unifiedChat.actions;
const { showErrorNotification } = stateManagement.notifications.actions;
const { fetchDomainSuggestions, getSiteBusinessNameForDomainSuggestion } =
  stateManagement.domainSuggestions.actions;
const { isDomainConnected } = stateManagement.domain.selectors;
const { getIsSessionInitializedWithWizard } =
  stateManagement.siteCreation.selectors;
const { setSessionLoadedAsDraft } = stateManagement.savePublish.actions;

const { DEVELOPER_MODE_ENABLED } = constants.USER_PREFS.VIEW_TOOLS;

const BLOG_MIGRATION_URL_PARAM = '&blogMigration';
const _noop = (): undefined => undefined;

function isDraftMode(editorAPI: EditorAPI) {
  return editorAPI.dsRead.generalInfo.isDraft();
}

function reportAddPanelLoaded(editorAPI: EditorAPI): void {
  editorAPI.bi.event(coreBi.events.editor.Add_PANEL_LOADED, {});
}

/**
 * Sets global params to fedops.
 * DON'T add functions with heavy operations !! Will harm performance.
 */
function initFedopsGlobalParams(editorAPI: EditorAPI) {
  const fedopsGlobalParams = {
    pages_count: () => editorAPI.pages.getPageIdList().length,
    esi: util.editorModel.editorSessionId,
  };
  util.fedopsLogger.updateBiGlobalParams(fedopsGlobalParams);
}

function initMediaManager(editorAPI: EditorAPI): void {
  const mediaManagerConfig = {
    siteMediaToken: editorAPI.dsRead.generalInfo.media.getSiteUploadToken(),
    version: 'G6',
    languageCode: util.editorModel.languageCode,
  };
  editorAPI.store.dispatch(
    stateManagement.uploadedFonts.actions.loadMediaManagerSdk(
      editorAPI.dsRead.generalInfo.media.getUserUploadToken(),
    ),
  );
  editorAPI.mediaServices.initMediaManager(mediaManagerConfig);
  editorAPI.savePublish.registerFirstSaveCallback(() => {
    editorAPI.mediaServices.mediaManager.updateSiteMediaToken(
      editorAPI.dsRead.generalInfo.media.getSiteUploadToken(),
    );
  });
}

function initUserFeedback(editorAPI: EditorAPI): void {
  editorAPI.mediaServices.initUserFeedback({
    siteToken: editorAPI.dsRead.generalInfo.media.getSiteUploadToken(),
    metasiteId: editorAPI.dsRead.generalInfo.getMetaSiteId(),

    initiator: 'editor',
    origin: 'EDITOR',
  });
}

function initMediaStudio(editorAPI: EditorAPI): void {
  editorAPI.mediaServices.initMediaStudio({
    siteToken: editorAPI.dsRead.generalInfo.media.getSiteUploadToken(),
    metasiteId: editorAPI.dsRead.generalInfo.getMetaSiteId(),
    saveStrategy: MediaImageStudio.SaveStrategy.Upload,

    initiator: 'editor',
  });
}

async function initLeftBarHighlighting(editorAPI: EditorAPI): Promise<void> {
  const isFirstSave = editorAPI.dsRead.generalInfo.isFirstSave();
  const isDraftModeOn = isDraftMode(editorAPI);
  const { isSiteCreationEligible } = util.editorModel;

  if (
    util.workspace.isNewWorkspaceEnabled() &&
    !util.workspace.isNewWorkspaceFTETourEnabled() &&
    (isFirstSave || isDraftModeOn || isSiteCreationEligible)
  ) {
    editorAPI.store.dispatch(stateManagement.leftBar.actions.highlightMenu());
  } else if (!isFirstSave && !isDraftModeOn) {
    editorAPI.store.dispatch(stateManagement.leftBar.actions.collapseMenu());
  }
}

/**
 * Initializes and loads the stylable editor service
 * @param {Object} editorAPI - EditorAPI object
 */
function initStylableEditor(editorAPI: EditorAPI): void {
  const stylableEditorActions = stateManagement.stylableEditor.actions;

  const stylableEditor =
    editorAPI.documentServices.components.stylable.getEditorInstance();

  editorAPI.store.dispatch(stylableEditorActions.load(stylableEditor));
}

function showDeprecatedBrowserPanel(editorAPI: EditorAPI): void {
  editorAPI.panelManager.openPanel(
    'panels.messagePanels.browserDeprecationMessage',
    {
      openHelpCenter: editorAPI.panelManager.openHelpCenter,
      browser: util.browserUtil.getDeprecatedBrowser(),
    },
    true,
  );
}

function getDevModeBIType(editorAPI: EditorAPI): string {
  const state = editorAPI.store.getState();
  const wixCodeEnabled =
    stateManagement.userPreferences.selectors.getSiteUserPreferences(
      DEVELOPER_MODE_ENABLED,
    )(state);
  if (wixCodeEnabled) {
    return 'devmode';
  } else if (wixData.isDataModeOn(editorAPI)) {
    return 'datamode';
  }
  return 'none';
}

function updateUserProfileOnPreviewReady(
  editorAPI: EditorAPI,
  fetchUserProfile: FetchUserProfileType,
) {
  return fetchUserProfile()
    .then((result) => {
      const fields = result.payload?.fields;
      editorAPI.store.dispatch(
        stateManagement.userProfile.actions.setUserProfile(
          _.keyBy(fields, 'name'),
        ),
      );
    })
    .catch((e) => {
      console.error(e);
    });
}

function initVideoMaker(editorAPI: EditorAPI): void {
  editorAPI.mediaServices.initVideoMaker();
}

function initDealer(editorAPI: EditorAPI): void {
  editorAPI.store.dispatch(stateManagement.dealer.actions.initDealer());
}

function handleGTMService(editorAPI: EditorAPI): void {
  editorAPI.GTMService.handleGtm();
}

function initDesktopMobileSwitchCallbacks(editorAPI: EditorAPI): void {
  editorAPI.registerDeviceTypeChangeCallbacks(
    boxSlideshow.utils.boxSlideShowUtils.mobileSwitchCallback.bind(
      null,
      editorAPI,
    ),
  );
}

function initSavedComponents(editorAPI: EditorAPI): void {
  const savedComponentsActions = stateManagement.savedComponents.actions;

  editorAPI.store.dispatch(savedComponentsActions.fetchCollection());
}

function initNewReleasesFeedNotification(editorAPI: EditorAPI): void {
  editorAPI.store.dispatch(
    stateManagement.newReleases.actions.getNotification(),
  );
}

/**
 *
 * @returns {boolean} Should return true to cancel navigation event
 */

const createHandleUpgradeToPremiumNavigation =
  (editorAPI: EditorAPI) => (): boolean => {
    const isFirstSave = editorAPI.dsRead.generalInfo.isFirstSave();

    if (!isFirstSave && !isDraftMode(editorAPI)) {
      return false;
    }
    editorAPI.panelHelpers.openFirstSaveSitePanel();
    return true;
  };

async function loadAddPanel(editorAPI: EditorAPI) {
  const addPanelAPI = await AddPanelAPI.loadAndCreate(editorAPI);

  attachAddPanelAPI(editorAPI, addPanelAPI);
}

function initPlatform(editorAPI: EditorAPI): void {
  platform.init(
    editorAPI,
    gfppModel.registerGfppActions,
    registerComponentGfppData,
  );
  editorAPI.store.dispatch(
    stateManagement.platform.actions.fetchFreshAppsData(),
  );
}

function registerToBusinessManagerCustomEvent(editorAPI: EditorAPI): void {
  window.addEventListener('message', (event) => {
    const data = _.get(event, 'data') || {};
    const shouldHandleMessage =
      event.origin === util.serviceTopology.businessManagerDomain &&
      !_.isEmpty(data) &&
      data.type === 'BusinessManagerUpdate';
    if (shouldHandleMessage) {
      switch (data.action) {
        case 'SEO_UPDATE':
          const { advancedSeoData, pageTitleSEO, descriptionSEO, pageId } =
            data.params;
          const dataToUpdate = _.omitBy(
            {
              advancedSeoData,
              descriptionSEO,
              pageTitleSEO,
            },
            _.isNil,
          );
          if (pageId && !_.isEmpty(dataToUpdate)) {
            editorAPI.pages.data.update(pageId, dataToUpdate);
          }
          break;

        default:
      }
    }
  });
}

function attachAddPanelAPI(editorAPI: EditorAPI, addPanelAPI: AnyFixMe) {
  editorAPI.addPanel = addPanelAPI;
}

async function loadAndInitSecondPhaseDependencies(
  editorAPI: EditorAPI,
): Promise<void> {
  const secondPhaseDependencies = [
    import('@/compPanels'),
    util.appStudioUtils.isAppStudio() ? import('@/appStudio') : null,
  ] as const;

  return Promise.all(secondPhaseDependencies).then(
    //eslint-disable-next-line @typescript-eslint/no-unused-vars
    ([compPanels, appStudio]) => {
      if (util.appStudioUtils.isAppStudio()) {
        editorAPI.pluginService.registerPlugin(
          editorAPI.pluginService.pluginConstants.ADD_PANEL_DROP,
          'widgetPage',
          appStudio.plugins.addPanelDropWidgetPagePlugin,
        );
        editorAPI.pluginService.registerPlugin(
          editorAPI.pluginService.pluginConstants
            .ADD_PANEL_ADD_BY_CLICK_CONTAINER,
          'widgetPageClick',
          appStudio.plugins.addPanelAddByClickWidgetPagePlugin,
        );
        editorAPI.pluginService.registerPlugin(
          editorAPI.pluginService.pluginConstants.ALLOWED_TO_PASTE,
          'pasteToWidgetPage',
          appStudio.plugins.allowToPastePlugin,
        );
      }
    },
  );
}

function initMobileWelcomeScreen(editorAPI: EditorAPI): void {
  // welcome screen configuration fetching
  editorAPI.store.dispatch(
    stateManagement.welcomeScreen.actions.getRemoteWelcomeScreenConfig(),
  );

  // register welcome screen publish to publish callbacks
  editorAPI.registerSitePublishedCallbacks(() => {
    const state = editorAPI.store.getState();
    const config =
      stateManagement.welcomeScreen.selectors.getWelcomeScreenConfig(state);

    if (!config?.error) {
      editorAPI.store.dispatch(
        stateManagement.welcomeScreen.actions.publishRemoteWelcomeScreenConfig(),
      );
    }
  });
}

function onAddPanelLoaded(editorAPI: EditorAPI): void {
  platform.onAddPanelLoaded();
  tpa.onPreviewReady(editorAPI);
  wixBookings.onPreviewReady(editorAPI);
  wixStore.onPreviewReady(editorAPI);
  blog.onPreviewReady(editorAPI);
  wixData.onPreviewReady(editorAPI);
  compPanelInfra.onPreviewReady(editorAPI);
  util.automationObserver.onPreviewReady(editorAPI);
  deeplink.onPreviewReady(editorAPI);

  reportAddPanelLoaded(editorAPI);

  const eventName =
    editorAPI.editorEventRegistry.constants.events.ADD_PANEL_LOADED;
  editorAPI.editorEventRegistry.dispatch(eventName, editorAPI);
}

export type OnPreviewReadyType = (
  ds: AnyFixMe,
  createSantaPreview: AnyFixMe,
  compFactory: AnyFixMe,
) => void;
type FetchUserProfileType = () => Promise<any>;

export const createOnPreviewReady =
  (
    editorAPI: EditorAPI,
    {
      shell,
      fetchUserProfile,
    }: {
      shell: Shell;
      fetchUserProfile: FetchUserProfileType;
    },
  ): OnPreviewReadyType =>
  // eslint-disable-next-line max-statements
  (ds, createSantaPreview, compFactory) => {
    const editorParamsApi = shell.getAPI(EditorParamsApiKey);
    const editorCoreApi = shell.getAPI(EditorCoreApiKey);
    const editorInitReadyPromise = editorCoreApi.hooks.initReady.promise;
    util.fedopsLogger.appLoadingPhaseStart('editor-fully-interactive');

    // TODO: what is font license ?
    fontLicense();

    componentDeprecation.onPreviewReady(editorAPI);

    editorAPI.store.dispatch(
      stateManagement.services.actions.setState({
        previewReady: true,
      }),
    );

    if (ds) {
      //for unit tests
      editorAPI.setDocumentServices(ds);
    }

    if (editorAPI.generalInfo.isDraft()) {
      editorAPI.store.dispatch(setSessionLoadedAsDraft(true));
    }

    if (experiment.isOpen('se_showDmErrorNotification')) {
      ds.registerToErrorThrown(({ error, methodName }: AnyFixMe) => {
        editorAPI.store.dispatch(showErrorNotification({ error, methodName }));
      });
    }

    initFedopsGlobalParams(editorAPI);

    const isSiteSaved =
      !editorAPI.dsRead.generalInfo.isFirstSave() &&
      !editorAPI.dsRead.generalInfo.isDraft();
    editorAPI.store.dispatch(
      stateManagement.leavePopup.actions.setLeavePopup(!isSiteSaved),
    );

    const handleUpgradeToPremiumNavigation =
      createHandleUpgradeToPremiumNavigation(editorAPI);
    editorAPI.dsActions.renderPlugins.setPremiumNavigationHandler(
      handleUpgradeToPremiumNavigation,
    );

    const siteBusinessName = getSiteBusinessNameForDomainSuggestion(editorAPI);

    const dispatchDomainSuggestionsWrapper = () => {
      if (
        siteBusinessName &&
        !isDomainConnected(editorAPI.documentServices) &&
        !editorParamsApi.siteCreationWizard &&
        util.fakeBrowserUtils.isFakeBrowserEnabled()
      ) {
        editorAPI.store.dispatch(fetchDomainSuggestions(siteBusinessName));
      }
    };

    editorInitReadyPromise.finally(dispatchDomainSuggestionsWrapper);

    if (util.appStudioUtils.isAppStudio()) {
      util.fedopsLogger.interactionStarted(
        util.fedopsLogger.INTERACTIONS.APP_STUDIO_INIT,
      );

      editorAPI.store
        .dispatch(
          stateManagement.applicationStudio.actions.openApplicationStudio(),
        )
        .then(() => {
          util.fedopsLogger.interactionEnded(
            util.fedopsLogger.INTERACTIONS.APP_STUDIO_INIT,
          );
        })
        .catch((e: AnyFixMe) => {
          ErrorReporter.captureException(e, {
            tags: { failedOnOpenAppStudio: true },
          });
        });
    }
    editorAPI.store.dispatch(
      stateManagement.schoolMode.actions.schoolSafeCheck(),
    );

    if (util.localModeUtils.isLocalModeEnabled()) {
      editorAPI.store.dispatch(
        stateManagement.localMode.actions.openLocalMode(),
      );
    }

    editorAPI.store.dispatch(
      stateManagement.schoolMode.actions.activateModeIfNeeded(),
    );

    if (createSantaPreview) {
      editorAPI.setSantaPreviewCreator(createSantaPreview);
    }

    if (compFactory) {
      editorAPI.store.dispatch(
        stateManagement.services.actions.loadService(
          'compFactoryService',
          compFactoryService.create(compFactory),
        ),
      );
    }

    editorAPI.prepareDocumentForPreviewMode(false, true);
    editorAPI.onViewerChanged();

    updateUserProfileOnPreviewReady(editorAPI, fetchUserProfile);
    editorAPI.store.dispatch(
      stateManagement.platform.actions.loadSilentInstallBiProfile(),
    );

    registerToBusinessManagerCustomEvent(editorAPI);
    util.fedopsLogger.appLoadingPhaseStart('editor-load-user-preferences');
    const userPreferencesPromise =
      editorAPI.updateUserPreferencesOnPreviewReady();
    editorCoreApi.hooks.userPreferencesLoaded.promise = userPreferencesPromise;

    userPreferencesPromise.then(() => {
      util.fedopsLogger.appLoadingPhaseFinish('editor-load-user-preferences');
    });

    const isSessionInitializedWithSiteCreationWizard =
      getIsSessionInitializedWithWizard(editorAPI.store.getState());

    editorInitReadyPromise.then(() => {
      initPlatform(editorAPI);
      tpa.initEditor(editorAPI);

      editorAPI.dsActions.documentMode.enableShouldUpdateJsonFromMeasureMap(
        true,
      );
    });

    const preInstallPromise = Promise.all([
      userPreferencesPromise,
      editorInitReadyPromise,
      editorCoreApi.hooks.zoomModeInit.promise,
      editorCoreApi.hooks.workspaceModesInit.promise,
    ]).then(() => {
      if (
        editorParamsApi.siteCreationWizard ||
        editorParamsApi.siteGenerationWizard
      ) {
        if (isSessionInitializedWithSiteCreationWizard) {
          editorCoreApi.hooks.appsSilentInstallationReady.resolve();
        } else {
          editorCoreApi.hooks.createInstallAppsHooks.promise.then(
            ({ setSilentInstallAppsFn, setHeadlessInstallAppsFn }) => {
              setSilentInstallAppsFn.resolve({
                silentInstallAppsFn: getSilentInstallAppsFn(editorAPI),
              });
              setHeadlessInstallAppsFn.resolve({
                headlessInstallAppsFn: getHeadlessInstallAppsFn(editorAPI),
              });
            },
          );
        }
      } else {
        platform
          .silentInstallApps(editorAPI)
          .catch(_noop)
          .finally(() => {
            editorCoreApi.hooks.appsSilentInstallationReady.resolve();
          });
      }
    });
    editorAPI.registerPrerequisiteForWelcomeScreen(preInstallPromise);

    initMobileWelcomeScreen(editorAPI);

    initMediaManager(editorAPI);
    initUserFeedback(editorAPI);
    initMediaStudio(editorAPI);
    initVideoMaker(editorAPI);
    initDealer(editorAPI);
    searchModule.init(editorAPI);
    handleGTMService(editorAPI);
    if (isSectionsOnStageEnabled()) {
      initSectionsOnStage(editorAPI);
    }
    initAppManager(editorAPI);

    initDesktopMobileSwitchCallbacks(editorAPI);

    if (!util.appStudioUtils.isAppStudio()) {
      const appData =
        editorAPI.documentServices.platform.getAppDataByApplicationId(
          constants.APPLICATIONS.META_SITE_APPLICATION_ID,
        );
      const metaSiteId = editorAPI.dsRead.generalInfo.getMetaSiteId();
      const initUnifiedChatState = (visible: AnyFixMe) => {
        if (visible) {
          editorAPI.store.dispatch(setChatIsLoaded());
          editorAPI.store.dispatch(setChatVisibility(visible));
        }
      };

      util.chatManager.initUnifiedChatWidget(
        appData,
        metaSiteId,
        initUnifiedChatState,
      );
    }

    initSavedComponents(editorAPI);
    initStylableEditor(editorAPI);
    initNewReleasesFeedNotification(editorAPI);

    if (experiment.isOpen('se_tooManyPagesLimitation')) {
      const staticPagesCount = editorAPI.pages.getStaticPagesCount();
      const isEditorLoadedWithMoreThanMaxStaticPages =
        staticPagesCount > constants.STATIC_PAGES.MAX_STATIC_PAGES_ALLOWED;
      editorAPI.store.dispatch(
        stateManagement.pages.actions.setIsEditorLoadedWithMoreThanMaxStaticPages(
          isEditorLoadedWithMoreThanMaxStaticPages,
        ),
      );
      if (isEditorLoadedWithMoreThanMaxStaticPages) {
        editorAPI.bi.event(
          coreBi.events.pages.pagesPanel
            .editor_loaded_with_more_than_max_static_pages,
        );
      }
    }

    editorAPI.store.dispatch(
      stateManagement.dynamicPages.actions.preFetchAndCacheInnerRoutes(),
    );

    // TODO: rewrite i18n init to the repluggable module with isolated init (after editorAPI.dsRead.bi is ready)
    i18n.init(editorAPI);

    editorAPI.setEditorMode(
      editorAPI.dsRead?.viewMode.get() ===
        editorAPI.dsRead?.viewMode.VIEW_MODES.MOBILE,
      'previewFrame',
      true,
    ); //want to initialize editor in mobile, non-undoable

    editorAPI.concurrentUsers.checkForConcurrentUsers();

    const initStagePromise = initStage({ editorAPI });

    Promise.all([
      userPreferencesPromise,
      editorInitReadyPromise,
      preInstallPromise,
      initStagePromise,
    ]).then(() => {
      editorAPI.autosaveManager.init();
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/includes
      if (_.includes(window?.location?.href, BLOG_MIGRATION_URL_PARAM)) {
        editorAPI.panelManager.openPanel('blog.newMenuPanel');
      }

      if (
        !isSiteSaved &&
        !isSessionInitializedWithSiteCreationWizard &&
        siteBusinessName
      ) {
        editorAPI.siteName.setAsync(siteBusinessName, _noop, _noop);
      }

      const devmod_type = getDevModeBIType(editorAPI);
      const sectionsMigrationApi = editorAPI.host.getAPI(
        SectionsMigrationApiKey,
      );

      editorAPI.bi.event(bi.events.EDITOR_READY, {
        is_premium: editorAPI.site.isPremium(),
        is_template: editorAPI.dsRead.generalInfo.isFirstSave(),
        window_width: window.innerWidth,
        window_height: window.innerHeight,
        open_method: isSessionInitializedWithSiteCreationWizard
          ? constants.SITE_CREATION.OPEN_METHOD_NAME
          : null,
        devmod_type,
        is_section_migration: sectionsMigrationApi.hasMigrationProcessed(),
      });
    });

    Promise.all([preInstallPromise, editorInitReadyPromise])
      .then(async () => {
        if (util.browserUtil.isDeprecatedBrowser()) {
          editorAPI.registerInitUserPrefsCallback(() =>
            showDeprecatedBrowserPanel(editorAPI),
          );
        }

        editorAPI.components.onPreviewReady();
        editorAPI.synchronizeSiteSEOToMainPageSEO();
        editorAPI.saveManager.setTimerForSaveReminder();

        editorAPI.feedback.init();
        editorAPI.site.init();

        editorAPI.dsActions.registerToSiteChanged(
          editorAPI.notifyViewerUpdate as RegisterToSiteChangedCallback,
        );

        editorAPI.dsActions.documentMode.enableAction('screenIn', false);
        editorAPI.dsActions.documentMode.enableAction('scrollScrub', false);
        editorAPI.dsActions.documentMode.enableAction('viewportEnter', false);
        editorAPI.dsActions.documentMode.enableAction('viewportLeave', false);

        editorAPI.dsActions.documentMode.enableAction('modeChange', false);

        editorAPI.dsActions.documentMode.enableAction('load', false);
        editorAPI.dsActions.documentMode.enableAction('exit', false);

        initLeftBarHighlighting(editorAPI);

        coreBi.registerEvents(editorAPI);

        editorAPI.initPlugins();

        editorAPI.campaigns.personalSale.fetchInfo();
        editorAPI.campaigns.personalSale.fetchSaleEndDate();
        if (
          editorAPI.dsRead.generalInfo.isFirstSave() ||
          isDraftMode(editorAPI)
        ) {
          editorAPI.initTemplateCharacterSet();
        }

        const multilingualPromise = editorAPI.store
          .dispatch(
            stateManagement.multilingual.actions.checkLanguageStatusThunk(),
          )
          .then(async ({ payload: { status } }: AnyFixMe) => {
            if (status) {
              util.fedopsLogger.appLoadingPhaseStart(
                'editor-load-multilingual',
              );

              await editorAPI.store.dispatch(
                stateManagement.multilingual.actions.loadLanguagesThunk(),
              );
              util.fedopsLogger.appLoadingPhaseFinish(
                'editor-load-multilingual',
              );
            }
          });

        // Await for preInstall to not clear the undo stack
        const migrateMenuPromise = Promise.all([
          userPreferencesPromise,
          preInstallPromise,
          multilingualPromise,
        ]).then(async () => {
          /**
           * se_support_fixPagesMenu - workaround in order to fix https://jira.wixpress.com/browse/WEED-22089
           * add &debug=1&experiments=se_support_fixPagesMenu
           * run in console window.__addMissingPagesForMenu___(editorAPI, true)
           * */
          if (experiment.isOpen('se_support_fixPagesMenu')) {
            // @ts-expect-error
            window.__addMissingPagesForMenu___ =
              menu.fixers.__addMissingPagesForMenu;
          }

          // supress error because it was ignored before;
          await menu.setupMenus(editorAPI).catch((err) => {
            // eslint-disable-next-line
            console.error(err);
            return;
          });
        });

        const addPanelPromise = Promise.all([
          multilingualPromise,
          //at least wixStore need userPreferences settings (onAddPanelLoaded call)
          userPreferencesPromise,
        ]).then(async () => {
          util.fedopsLogger.appLoadingPhaseStart('editor-load-add-panel');

          await loadAddPanel(editorAPI);

          onAddPanelLoaded(editorAPI);

          tpa.navigateAndOpenAppService.navigateAndOpenPanelsByURLIfNeeded(
            editorAPI,
          );

          util.fedopsLogger.appLoadingPhaseFinish('editor-load-add-panel');
        });

        editorAPI.store.dispatch(stateManagement.uploadedFonts.actions.init());
        fetchUserProfile()
          .then(editorAPI.promotions.initPromotions)
          .catch((e) => {
            console.error(e);
          });

        editorAPI.store.dispatch(
          stateManagement.services.actions.onPreviewReady(),
        );

        editorAPI.dsActions.waitForChangesApplied(() => {
          editorAPI.store.dispatch(
            stateManagement.inlinePopup.actions.closeAll(true),
          );
        });

        editorAPI.store.dispatch(
          stateManagement.businessManager.actions.prefetchBizMgr(),
        );

        await Promise.all([
          initStagePromise,
          userPreferencesPromise,
          multilingualPromise,
          addPanelPromise,
          migrateMenuPromise,
        ]);

        if (editorAPI.promotions.helpUkraine.isActive()) {
          editorAPI.promotions.helpUkraine.display();
        }

        editorCoreApi.hooks.__previewIsReady_TEMPORARY.resolve();

        util.fedopsLogger.appLoadingPhaseFinish('editor-fully-interactive');

        util.fedopsLogger.appLoadingPhaseStart(
          'editor-load-second-phase-dependencies',
        );

        await loadAndInitSecondPhaseDependencies(editorAPI);

        util.fedopsLogger.appLoadingPhaseFinish(
          'editor-load-second-phase-dependencies',
        );

        util.fedopsLogger.appLoaded();

        ErrorReporter.setTags({ editorLoaded: true });

        // added so stylable-panel will lazily run after the editor loads
        import('stylable-panel');
      })
      .catch(function (e) {
        // eslint-disable-next-line no-console
        console.error('onPreviewReady failed', e);
        ErrorReporter.captureException(e, {
          tags: { failedOnPreviewReady: true },
        });
      });
  };
