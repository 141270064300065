{
  "ECOM_MISSING_PAGES": {
    "errorName": "ECOM_MISSING_PAGES",
    "errorCode": 300,
    "severity": "fatal"
  },
  "APP_IS_NOT_RESPONSIVE": {
      "errorName": "APP_IS_NOT_RESPONSIVE",
      "errorCode": 302,
      "desc": "monitoring apps that are down",
      "severity": "error",
      "params": {
          "p1": "endpoint",
          "p2": "app_id"
        }
    }
}
