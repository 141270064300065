import { EditorPlatformHostIntegrationAPI } from '@wix/editor-platform-host-integration-apis';
import { getRefComponentRootChild, isRefComponent } from '@/documentServices';
import type { ComponentRef } from '@wix/platform-editor-sdk';
import type { EditorAPI } from '@/editorAPI';
import type { DsItem } from 'types/documentServices';

function getAppDefinitionId(data?: DsItem) {
  return (data?.appDefinitionId ?? data?.applicationId) as string;
}

export function getAppDataAndCompByCompRef(
  editorAPI: EditorAPI,
  componentRef: ComponentRef,
) {
  const compRef = isRefComponent(editorAPI.dsRead, componentRef)
    ? getRefComponentRootChild(editorAPI.dsRead, componentRef)
    : componentRef;

  const data = compRef && editorAPI.dsRead.components.data.get(compRef);

  const appDefinitionId = getAppDefinitionId(data);

  if (!appDefinitionId) {
    return {
      appData: null,
      compRef,
    };
  }

  return {
    appData: editorAPI.platform.getAppDataByAppDefId(appDefinitionId),
    compRef,
  };
}

export function buildAppGFPPWithViewStateGFPP(
  editorAPI: EditorAPI,
  componentRef: ComponentRef,
) {
  const stageData = editorAPI.platform.controllers.getStageData(componentRef);
  const gfppDataFromApp = stageData?.gfpp;

  /**
   * NOTE: temporary workaround until we fix appWidgetGfpp.spec.ts
   */
  if (!stageData?.viewStates) {
    return gfppDataFromApp;
  }

  const { appData, compRef } = getAppDataAndCompByCompRef(
    editorAPI,
    componentRef,
  );

  if (!appData) {
    return gfppDataFromApp;
  }

  const api = editorAPI.host.getAPI(EditorPlatformHostIntegrationAPI);
  const currentViewState = api.viewState.getViewState(
    // there is type issue between DM and platform appData
    appData as any,
    compRef,
  );

  const viewStateGFPP = stageData.viewStates?.[currentViewState]?.gfpp;

  if (!viewStateGFPP) {
    return gfppDataFromApp;
  }

  return {
    desktop: {
      ...gfppDataFromApp?.desktop,
      ...viewStateGFPP?.desktop,
    },
    mobile: {
      ...gfppDataFromApp?.mobile,
      ...viewStateGFPP?.mobile,
    },
  };
}
