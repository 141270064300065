'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')
const util = require('@/util')

module.exports = {
    'type': addPanelDataConsts.SECTIONS_TYPES.PRESET,
    'hide': false,
    'title': 'add_section_label_paypal',
    'subNavigationTitle': 'add_section_label_paypal',
    'presetTitle': 'add_section_label_paypal',
    'tooltipTitle': 'add_section_label_paypal',
    "automationId": "add-panel-section-paypalButtonsSection",
    'subNavigationHide': false,
    'showSectionHeader': true,
    'additionalBehaviours': {
        'labelMode': addPanelDataConsts.LABEL_BEHAVIOUR.ALWAYS,
        'infoIcon': addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
        'hoverImageAction': addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
        'iconEnabledForComps': {}
    },
    'props': {
        'image': `addPanelData/sections/paypalButtonsSection_${util.languages.getLanguageCode()}/paypalButtonsSection_${util.languages.getLanguageCode()}.png`,
        'imageHover': null,
        'items': [{
            'id': 'Other_Paypal_Buttons_1',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.viewer.skins.PayPalButtonSkin',
                'layout': {
                    'width': 171,
                    'height': 47,
                    'x': 77,
                    'y': 67,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.viewer.components.PayPalButton',
                'data': {
                    'type': 'PayPalButton',
                    'metaData': {'isPreset': true, 'schemaVersion': '1.0', 'isHidden': false},
                    'merchantID': ''
                },
                'props': {
                    'type': 'PayPalButtonProperties',
                    'metaData': {'schemaVersion': '1.0'},
                    'buttonType': 'buy',
                    'itemName': '',
                    'itemID': '',
                    'organizationName': '',
                    'organizationID': '',
                    'amount': '',
                    'currencyCode': 'USD',
                    'target': '_blank',
                    'showCreditCards': true,
                    'smallButton': false,
                    'language': util.languages.getLanguageCode()
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'id': 'ppb0',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {'alpha-clr': '1', 'clr': '#FFFFFF'},
                        'propertiesSource': {'clr': 'value'}
                    },
                    'componentClassName': '',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'system',
                    'skin': 'wysiwyg.viewer.skins.PayPalButtonSkin'
                },
                'id': 'i5rzo7md_0'
            },
            'preset': {
                'rect': {'width': 162, 'height': 95, 'x': 0, 'y': 0},
                'label': 'add_preset_Other_Paypal_Buttons_1',
                'tags': null
            }
        }, {
            'id': 'Other_Paypal_Buttons_2',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.viewer.skins.PayPalButtonSkin',
                'layout': {
                    'width': 92,
                    'height': 26,
                    'x': 444,
                    'y': 77,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.viewer.components.PayPalButton',
                'data': {
                    'type': 'PayPalButton',
                    'metaData': {'isPreset': true, 'schemaVersion': '1.0', 'isHidden': false},
                    'merchantID': ''
                },
                'props': {
                    'type': 'PayPalButtonProperties',
                    'metaData': {'schemaVersion': '1.0'},
                    'buttonType': 'donate',
                    'itemName': '',
                    'itemID': '',
                    'organizationName': '',
                    'organizationID': '',
                    'amount': '',
                    'currencyCode': 'USD',
                    'target': '_blank',
                    'showCreditCards': false,
                    'smallButton': false,
                    'language': util.languages.getLanguageCode()
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'id': 'ppb0',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {'alpha-clr': '1', 'clr': '#FFFFFF'},
                        'propertiesSource': {'clr': 'value'}
                    },
                    'componentClassName': '',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'system',
                    'skin': 'wysiwyg.viewer.skins.PayPalButtonSkin'
                },
                'id': 'i5rzo7md_1'
            },
            'preset': {
                'rect': {'width': 162, 'height': 95, 'x': 162, 'y': 0},
                'label': 'add_preset_Other_Paypal_Buttons_2',
                'tags': null
            }
        }],
        'compTypes': ['wysiwyg.viewer.components.PayPalButton']
    },
    'help': {'hide': false, 'text': 'add_section_info_text_paypal'}
}
