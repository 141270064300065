import refComponent from './advancedCustomActions/refComponent';
import tpaWidget from './advancedCustomActions/tpaWidget';

import type { RCMAction } from './types';

const advancedCustomActions: Record<string, RCMAction[]> = {
  'wysiwyg.viewer.components.RefComponent': refComponent,
  'wysiwyg.viewer.components.tpapps.TPAWidget': tpaWidget,
};

export default advancedCustomActions;
