import React from 'react';

import { cx } from '@/util';

import { APP_ICON_SIZE } from '../../constants';

import styles from './AppIcon.scss';

export interface AppIconProps {
  src: string;
  alt?: string;
  size?: number;
  className?: string;
  withBorder?: boolean;
  withDot?: boolean;
}

export const AppIcon: React.FC<AppIconProps> = ({
  src,
  alt,
  withBorder,
  withDot,
  className,
}) => (
  <div
    className={cx(styles.container, className, {
      [styles.withDot]: withDot,
    })}
  >
    <img
      style={{ '--size': APP_ICON_SIZE } as React.CSSProperties}
      src={src}
      alt={alt}
      className={cx(styles.icon, {
        [styles.withBorder]: withBorder,
      })}
    />
  </div>
);
