export const STYLABLE_DELIMITER = '__';
export const STYLABLE_VARIABLE_DELIMITER = '-';

/**
 * Generates a Stylable class name to connect Stylable with the current
 * component
 * @param {string} styleId - Style ID of the current component
 * @param {string} className - Class name to generate
 */
export const generateStylableClass = (styleId: string, className: string) =>
  `${styleId}${STYLABLE_DELIMITER}${className}`;

/**
 * Generates a Stylable variable name
 * @param {string} styleId - Style ID of the current component
 * @param {string} variableName - Variable name to generate
 */
export const generateStylableVariable = (
  styleId: string,
  variableName: string,
) => `--${styleId}${STYLABLE_VARIABLE_DELIMITER}${variableName}`;

/**
 * Creates a StylableEditor configuration for setting the 'hidden' property of
 * a specified component element selector state
 * @param {string} selector - The element selector to set
 * @param {string} state - The state to set
 * @param {boolean} hidden - The value to set
 */
export const createStateSetHiddenConfig = (
  selector: string,
  state: string,
  hidden: boolean,
) => ({
  selectorConfiguration: {
    [selector]: {
      stateNameOverrides: {
        [state]: { hidden },
      },
    },
  },
});

/**
 * Creates a StylableEditor configuration for setting the 'hide' property of
 * a specified component element selector
 * @param {string} selector - The element selector to set
 * @param {boolean} hide - The value to set
 */
export const createSelectorSetHideConfig = (
  selector: string,
  hide: boolean,
) => ({
  selectorConfiguration: {
    [selector]: { hide },
  },
});

/**
 * Creates a StylableEditor configuration for setting the 'nameKey' property of
 * a specified component element selector
 * @param {string} selector - The element selector to set
 * @param {string} nameKey - The translation key to set
 */
export const changeSelectorNameKeyConfig = (
  selector: string,
  nameKey: string,
) => ({
  selectorConfiguration: {
    [selector]: { nameKey },
  },
});

/**
 * Creates a StylableEditor configuration for setting the specific 'controllerData' property of
 * a specified component element selector
 * @param {string} selector - The element selector to set
 * @param {object} controllerData - The data that needs to be set
 */
export const changeSelectorControllerDataConfig = (
  selector: string,
  controllerData: any,
) => ({
  selectorConfiguration: {
    [selector]: {
      controllerData,
    },
  },
});

/**
 * Creates a StylableEditor configuration for setting the 'foldInto' property of
 * a specified component element selector
 * @param {string} selector - The element selector to set
 * @param {boolean} foldInto - The value to set
 */
export const createSelectorSetFoldIntoConfig = (
  selector: string,
  foldInto: boolean,
) => ({
  selectorConfiguration: {
    [selector]: { foldInto },
  },
});
