import type { MapDispatchToProps } from 'types/redux';
import type { DraggableItem } from '../../../addPanel/dragToStage/AddPanelDragHandler';

export interface DragMapperProps {
  reportAddMenuDrag: (
    params: Pick<DraggableItem, 'itemId' | 'categoryId' | 'sectionTitle'>,
  ) => void;
}

export const dragMapper: MapDispatchToProps<DragMapperProps, any> = (
  dispatch,
) => {
  const editorAPI = dispatch(
    (dispatch: AnyFixMe, getState: AnyFixMe, { editorAPI }: AnyFixMe) =>
      editorAPI,
  );

  return {
    reportAddMenuDrag: ({ itemId, categoryId, sectionTitle }) => {
      editorAPI.reportAddMenuDrag({
        preset_id: itemId,
        category: categoryId,
        section: sectionTitle,
      });
    },
  };
};
