import React, { type MouseEvent, useEffect } from 'react';
import _ from 'lodash';

import { TextLabel } from '@wix/wix-base-ui';
import * as util from '@/util';
import constants from '@/constants';
import {
  mapDispatchToProps,
  mapStateToProps,
  type BranchIndicatorStateProps,
  type BranchIndicatorDispatchProps,
} from './branchIndicatorMapper';

import withSendBi, { type WithSendBiProps } from '../../hocs/withSendBi';
import * as coreBi from '@/coreBi';
import withCustomDropPanelBehavior from '../../hocs/withCustomDropPanelBehavior';
import type { ComponentWithDropDownOnHoverProps } from '../../hocs/withDropDownOnHover';
import { withBranchIndicatorDropPanel } from './withBranchIndicatorDropPanel';

import styles from './branchIndicator.scss';

const { LABELS } = constants.ROOT_COMPS.TOPBAR;

interface BranchIndicatorOwnProps {
  branchId: string;
}

type BranchIndicatorProps = BranchIndicatorOwnProps &
  BranchIndicatorStateProps &
  BranchIndicatorDispatchProps &
  WithSendBiProps &
  ComponentWithDropDownOnHoverProps;

const BranchIndicator: React.FC<BranchIndicatorProps> = ({
  onMouseEnter,
  onMouseLeave,
  sendBi,
  branchId,
  branchName,
  isVisible,
  shouldFetchBranchData,
  fetchAndAssignBranchesData,
}: BranchIndicatorProps) => {
  useEffect(() => {
    if (shouldFetchBranchData) {
      fetchAndAssignBranchesData();
    }
  }, [shouldFetchBranchData, fetchAndAssignBranchesData]);

  const onMouseEnterWithBi = (event: MouseEvent): void => {
    onMouseEnter(event);
    sendBi(coreBi.events.topbar.top_bar_branch_indicator.brach_name_hover, {
      site_branch_id: branchId,
      site_branch_name: branchName,
    });
  };
  if (isVisible) {
    return (
      <div
        onMouseEnter={onMouseEnterWithBi}
        onMouseLeave={onMouseLeave}
        className={styles.branchIndicatorWrapper}
        data-automation-id={'version-indicator'}
      >
        <TextLabel
          type="T04"
          className={styles.versionName}
          value={branchName}
          shouldTranslate={false}
        />
      </div>
    );
  }
  return null;
};

const ConnectedBranchIndicator = _.flow(
  util.hoc.connect(
    util.hoc.STORES.EDITOR_API,
    mapStateToProps,
    mapDispatchToProps,
  ),
  withCustomDropPanelBehavior(LABELS.VERSION_INDICATOR),
  withBranchIndicatorDropPanel,
  withSendBi,
)(BranchIndicator) as React.ComponentType<BranchIndicatorOwnProps>;

export {
  ConnectedBranchIndicator as BranchIndicator,
  BranchIndicator as BranchIndicatorPure,
};
