import React from 'react';
import { translate } from '@/i18n';
import { frames } from '@/panels';
import { Text } from '@wix/wix-base-ui';
import PageHierarchyUrlPreview from '../../commonControls/pageHierarchyUrlPreview/pageHierarchyUrlPreview';
import PageHierarchyTitleWithChildPages from '../../commonControls/PageHierarchyTitleWithChildPages/PageHierarchyTitleWithChildPages';

const { MessagePanelFrame } = frames;

interface DeletePageInHierarchyMessageProps {
  panelName: string;
  onConfirm: () => void;
  onCancel: () => void;
  pageTitle: string;
  sitePublicUrl?: string;
  hasDomain?: boolean;
  childPageTitles?: string[];
}

const DeletePageInHierarchyMessage: React.FC<
  DeletePageInHierarchyMessageProps
> = (props) => {
  const messageContent = props.hasDomain
    ? translate('Pages_Settings_Delete_Page_SiteHierarchy_Message_WithDomain')
    : translate('Pages_Settings_Delete_Page_SiteHierarchy_Message_NoDomain');
  return (
    <MessagePanelFrame
      panelName={props.panelName}
      dataHook="delete-page-message"
      theme="destructive"
      title={translate('Pages_Settings_Delete_Page_SiteHierarchy_Title', {
        page_name: props.pageTitle,
      })}
      illustration={null}
      confirmLabel={translate(
        'Pages_Settings_Delete_Page_SiteHierarchy_Delete_Button',
      )}
      cancelLabel={translate(
        'Pages_Settings_Delete_Page_SiteHierarchy_Cancel_Button',
      )}
      onConfirm={props.onConfirm}
      onCancel={props.onCancel}
      confirmOnEnterPress
    >
      <PageHierarchyTitleWithChildPages
        translationKey={
          'Pages_Settings_Delete_Page_SiteHierarchy_NumberofChildrenMessage'
        }
        childPageTitles={props.childPageTitles}
      />
      <Text
        size="medium"
        weight="thin"
        skin="secondary"
        enableEllipsis={false}
        shouldTranslate={false}
      >
        {messageContent}
      </Text>

      <PageHierarchyUrlPreview
        label={translate('Pages_Settings_Delete_Page_SiteHierarchy_OldURL')}
        paths={[
          {
            path: translate('Pages_Settings_Delete_Page_SiteHierarchy_Parent'),
            skin: 'alert',
          },
          { path: translate('Pages_Settings_Delete_Page_SiteHierarchy_Child') },
        ]}
      />
      <PageHierarchyUrlPreview
        label={translate('Pages_Settings_Delete_Page_SiteHierarchy_NewURL')}
        sitePublicUrl={props.sitePublicUrl}
        paths={[
          { path: translate('Pages_Settings_Delete_Page_SiteHierarchy_Child') },
        ]}
      />
    </MessagePanelFrame>
  );
};

export default DeletePageInHierarchyMessage;
