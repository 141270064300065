import constants from '@/constants';
import type { MapStateToProps, MapDispatchToProps } from 'types/redux';
import {
  Action,
  type TestSiteButtonDispatchProps,
  type TestSiteButtonOwnProps,
  type TestSiteButtonStateProps,
} from './testSite';
import togglePreview from '../../thunks/togglePreview';
import * as stateManagement from '@/stateManagement';
import { translate } from '@/i18n';
import { fixedStage } from '@/util';

export const mapStateToProps: MapStateToProps<
  TestSiteButtonStateProps,
  TestSiteButtonOwnProps
> = ({ editorAPI, state }) => {
  const {
    selectors: { getSiteUserPreferences },
    actions: { setSiteUserPreferences },
  } = stateManagement.userPreferences;
  const disabled =
    editorAPI.savePublish.isTestSiteInProgress() ||
    !!(state.previewingStatus === constants.PROGRESS_STATUS.IN_PROGRESS);
  const { publishRC } = editorAPI.publishManager;
  const testSiteState =
    state.previewingStatus === constants.PROGRESS_STATUS.IN_PROGRESS ||
    state.testSiteStatus === constants.PROGRESS_STATUS.IN_PROGRESS
      ? constants.PROGRESS_STATUS.IN_PROGRESS
      : constants.PROGRESS_STATUS.IDLE;
  const actionChoice = getSiteUserPreferences(
    constants.USER_PREFS.TEST_SITE.TEST_SITE_BUTTON_TITLE,
  )(editorAPI.store.getState());
  if (!actionChoice) {
    editorAPI.store.dispatch(
      setSiteUserPreferences(
        constants.USER_PREFS.TEST_SITE.TEST_SITE_BUTTON_TITLE,
        Action.TEST_SITE,
      ),
    );
  }
  const translatedButtonLabel =
    !actionChoice || (actionChoice as string) === 'Test Site'
      ? translate('TopBar_DevMode_Preview_TestSiteCTA_Label')
      : translate('TopBar_DevMode_Preview_PreviewCTA_Label');
  return {
    disabled,
    status: testSiteState,
    isSitePublished: editorAPI.dsRead?.generalInfo.isSitePublished(),
    isDevModeOn: editorAPI.getViewTools().developerModeEnabled,
    publishRC,
    actionChoice: actionChoice as string,
    buttonLabel: translatedButtonLabel,
    topBarStateBIParamValue: fixedStage.getTopBarStateBiParamValue(editorAPI),
  };
};

export const mapDispatchToProps: MapDispatchToProps<
  TestSiteButtonDispatchProps,
  TestSiteButtonOwnProps
> = {
  togglePreview,
};
