'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')
const experiment = require('experiment')
const util = require('@/util')
const languageCode = util.languages.getLanguageCode()

module.exports = {
    'type': addPanelDataConsts.SECTIONS_TYPES.PRESET,
    'hide': false,
    'title': 'add_section_label_facebook',
    'subNavigationTitle': 'add_section_label_facebook',
    'presetTitle': 'add_section_label_facebook',
    'tooltipTitle': 'add_section_label_facebook',
    "automationId": "add-panel-section-facebookSection",
    'subNavigationHide': false,
    'showSectionHeader': true,
    'additionalBehaviours': {
        'labelMode': addPanelDataConsts.LABEL_BEHAVIOUR.HOVER,
        'infoIcon': addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
        'hoverImageAction': addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
        'iconEnabledForComps': {}
    },
    'props': experiment.isOpen('se_newSocialCategoryAddPanel') && languageCode === 'en' ? {
        "image": `addPanelData/sections/facebookSection_${languageCode}/facebookSection_${languageCode}.png`,
        "imageHover": null,
        "items": [{
            "id": "Social_Facebook_1",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.facebooklikebox.viewer.skins.FacebookLikeBoxSkin",
                "layout": {
                    "width": 315,
                    "height": 575,
                    "x": 166,
                    "y": 315,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.facebooklikebox.viewer.FacebookLikeBox",
                "data": {
                    "type": "FacebookLikeBox",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "facebookPageId": "wix",
                    "colorScheme": "light",
                    "showFaces": true,
                    "showStream": true,
                    "showBorder": true,
                    "showHeader": true
                },
                "props": {
                    "type": "FacebookLikeBoxProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "transparentBg": false
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.facebooklikebox.viewer.FacebookLikeBox",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.facebooklikebox.viewer.skins.FacebookLikeBoxSkin"
                },
                "activeModes": {},
                "id": "comp-jorc37fe"
            },
            "preset": {
                "rect": {"width": 324, "height": 579.5, "x": 0, "y": 0},
                "label": "add_preset_Social_Facebook_3",
                "tags": null
            }
        }, {
            "id": "Social_Facebook_2",
            "structure": {
                "type": "Component",
                "skin": "skins.core.FacebookCommentSkin",
                "layout": {
                    "width": 400,
                    "height": 202,
                    "x": 126,
                    "y": 1246,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WFacebookComment",
                "data": {
                    "type": "WFacebookComment",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "urlFormat": "slash",
                    "isHttpsEnabled": true
                },
                "props": {
                    "type": "WFacebookCommentProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "numPosts": 1,
                    "width": 400,
                    "colorScheme": "light",
                    "canBeShownOnAllPagesBug": true
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": true, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "skins.core.FacebookCommentSkin"
                },
                "activeModes": {},
                "id": "comp-jorcz0st"
            },
            "preset": {
                "rect": {"width": 324, "height": 189, "x": 0, "y": 579.5},
                "label": "add_preset_Social_Facebook_5",
                "tags": null
            }
        }, {
            "id": "Social_Facebook_3",
            "structure": {
                "type": "Component",
                "skin": "skins.viewer.facebookshare.FacebookShareSkin",
                "layout": {
                    "width": 71,
                    "height": 24,
                    "x": 43,
                    "y": 1584,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.FacebookShare",
                "data": {
                    "type": "FacebookShareButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "urlChoice": "Current page",
                    "label": "Share",
                    "urlFormat": "slash",
                    "isHttpsEnabled": true
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.viewer.components.FacebookShare",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "skins.viewer.facebookshare.FacebookShareSkin"
                },
                "activeModes": {},
                "id": "comp-jp0zzcn0"
            },
            "preset": {
                "rect": {"width": 85, "height": 59, "x": 0, "y": 768},
                "label": "add_preset_Social_Facebook_2",
                "tags": null,
                "labelClasses": "align-left"
            }
        }, {
            "id": "Social_Facebook_4",
            "structure": {
                "type": "Component",
                "skin": "skins.core.FacebookLikeSkin",
                "layout": {
                    "width": 121,
                    "height": 20,
                    "x": 246,
                    "y": 1586,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WFacebookLike",
                "data": {
                    "type": "WFacebookLike",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "urlFormat": "slash",
                    "isHttpsEnabled": false
                },
                "props": {
                    "type": "WFacebookLikeProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "layout": "button_count",
                    "send": false,
                    "show_faces": false,
                    "width": 225,
                    "action": "recommend",
                    "font": "helvetica",
                    "colorScheme": "light",
                    "language": "en"
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": true, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "skins.core.FacebookLikeSkin"
                },
                "activeModes": {},
                "id": "comp-jp0zztq8"
            },
            "preset": {
                "rect": {"width": 145, "height": 59, "x": 85, "y": 768.5},
                "label": "add_preset_Social_Facebook_4",
                "tags": null,
                "labelClasses": "align-left"
            }
        }, {
            "id": "Social_Facebook_5",
            "structure": {
                "type": "Component",
                "skin": "skins.core.FacebookLikeSkin",
                "layout": {
                    "width": 77,
                    "height": 20,
                    "x": 517,
                    "y": 1586,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WFacebookLike",
                "data": {
                    "type": "WFacebookLike",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "urlFormat": "slash",
                    "isHttpsEnabled": true
                },
                "props": {
                    "type": "WFacebookLikeProperties",
                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                    "layout": "button_count",
                    "send": false,
                    "show_faces": false,
                    "width": 225,
                    "action": "like",
                    "font": "helvetica",
                    "colorScheme": "light",
                    "language": "en"
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": true, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "skins.core.FacebookLikeSkin"
                },
                "activeModes": {},
                "id": "comp-jp1007ta"
            },
            "preset": {
                "rect": {"width": 94, "height": 59, "x": 230, "y": 768.5},
                "label": "add_preset_Social_Facebook_1",
                "tags": null,
                "labelClasses": "align-left"
            }
        }],
        "compTypes": ["wysiwyg.viewer.components.FacebookShare", "wysiwyg.viewer.components.WFacebookLike", "wysiwyg.viewer.components.WFacebookComment", "wysiwyg.common.components.facebooklikebox.viewer.FacebookLikeBox"]
    } : {
        'image': languageCode === 'en' ? `addPanelData/sections/facebookSection_en/facebookSection_en.v3.png` :
            `addPanelData/sections/facebookSection_${languageCode}/facebookSection_${languageCode}.png`,
        'imageHover': null,
        'items': [{
            'id': 'Social_Facebook_1',
            'structure': {
                'type': 'Component',
                'skin': 'skins.core.FacebookLikeSkin',
                'layout': {
                    'width': 250,
                    'height': 40,
                    'x': 10,
                    'y': 20,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.viewer.components.WFacebookLike',
                'data': {
                    'type': 'WFacebookLike',
                    'urlFormat': 'hashBang'
                },
                'props': {
                    'type': 'WFacebookLikeProperties',
                    'metaData': {'schemaVersion': '1.0'},
                    'layout': 'standard',
                    'send': false,
                    'show_faces': false,
                    'width': 225,
                    'action': 'like',
                    'font': 'helvetica',
                    'colorScheme': 'light',
                    'language': 'en'
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'id': 'fbl1',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'componentClassName': '',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'system',
                    'skin': 'skins.core.FacebookLikeSkin'
                },
                'id': 'ic204el8'
            },
            'preset': {
                'rect': {'width': 324, 'height': 60, 'x': 0, 'y': 0},
                'label': 'add_preset_Social_Facebook_1',
                'tags': null,
                'labelClasses': 'align-left'
            }
        }, {
            'id': 'Social_Facebook_2',
            'structure': {
                'type': 'Component',
                'skin': 'skins.viewer.facebookshare.FacebookShareSkin',
                'layout': {
                    'width': 71,
                    'height': 30,
                    'x': 10,
                    'y': 80,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.viewer.components.FacebookShare',
                'data': {
                    'type': 'FacebookShareButton',
                    'metaData': {'isPreset': true, 'schemaVersion': '1.0', 'isHidden': false},
                    'urlChoice': 'Current page',
                    'urlFormat': 'hashBang',
                    'label': 'Share'
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'id': 'fs1',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {'groups': {}, 'properties': {}, 'propertiesSource': {}},
                    'componentClassName': '',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'system',
                    'skin': 'skins.viewer.facebookshare.FacebookShareSkin'
                },
                'id': 'ic204wqu'
            },
            'preset': {
                'rect': {'width': 324, 'height': 64, 'x': 0, 'y': 60},
                'label': 'add_preset_Social_Facebook_2',
                'tags': null,
                'labelClasses': 'align-left'
            }
        }, {
            'id': 'Social_Facebook_3',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.common.components.facebooklikebox.viewer.skins.FacebookLikeBoxSkin',
                'layout': {
                    'width': 314,
                    'height': 591,
                    'x': 5,
                    'y': 143,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.common.components.facebooklikebox.viewer.FacebookLikeBox',
                'data': {
                    'type': 'FacebookLikeBox',
                    'metaData': {'isPreset': true, 'schemaVersion': '1.0', 'isHidden': false},
                    'facebookPageId': 'wix',
                    'colorScheme': 'light',
                    'showFaces': true,
                    'showStream': true,
                    'showBorder': true,
                    'showHeader': true
                },
                'props': {'type': 'FacebookLikeBoxProperties', 'metaData': {'schemaVersion': '1.0'}},
                'style': {
                    'type': 'TopLevelStyle',
                    'id': 'FacebookLikeBox_1',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {'groups': {}, 'properties': {}, 'propertiesSource': {}},
                    'componentClassName': '',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'system',
                    'skin': 'wysiwyg.common.components.facebooklikebox.viewer.skins.FacebookLikeBoxSkin'
                },
                'id': 'ic205d9e'
            },
            'preset': {
                'rect': {'width': 324, 'height': 640, 'x': 0, 'y': 124},
                'label': 'add_preset_Social_Facebook_3',
                'tags': null
            }
        }, {
            'id': 'Social_Facebook_4',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.common.components.facebooklikebox.viewer.skins.FacebookLikeBoxSkin',
                'layout': {
                    'width': 304,
                    'height': 130,
                    'x': 10,
                    'y': 783,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.common.components.facebooklikebox.viewer.FacebookLikeBox',
                'data': {
                    'type': 'FacebookLikeBox',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'facebookPageId': 'wix',
                    'colorScheme': 'light',
                    'showFaces': false,
                    'showStream': false,
                    'showBorder': true,
                    'showHeader': true
                },
                'props': {'type': 'FacebookLikeBoxProperties', 'metaData': {'schemaVersion': '1.0'}},
                'style': {
                    'type': 'TopLevelStyle',
                    'id': 'FacebookLikeBox_1',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {'groups': {}, 'properties': {}, 'propertiesSource': {}},
                    'componentClassName': '',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'system',
                    'skin': 'wysiwyg.common.components.facebooklikebox.viewer.skins.FacebookLikeBoxSkin'
                },
                'id': 'ic20687x'
            },
            'preset': {
                'rect': {'width': 324, 'height': 170, 'x': 0, 'y': 764},
                'label': 'add_preset_Social_Facebook_4',
                'tags': null
            }
        }, {
            'id': 'Social_Facebook_5',
            'structure': {
                'type': 'Component',
                'skin': 'skins.core.FacebookCommentSkin',
                'layout': {
                    'width': 400,
                    'height': 176,
                    'x': 21,
                    'y': 945,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.viewer.components.WFacebookComment',
                'data': {
                    'type': 'WFacebookComment',
                    'urlFormat': 'hashBang'
                },
                'props': {
                    'type': 'WFacebookCommentProperties',
                    'metaData': {'schemaVersion': '1.0'},
                    'numPosts': 2,
                    'width': 400,
                    'colorScheme': 'light',
                    'canBeShownOnAllPagesBug': true
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'id': 'fbc1',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'componentClassName': '',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'system',
                    'skin': 'skins.core.FacebookCommentSkin'
                },
                'id': 'ic205pko'
            },
            'preset': {
                'rect': {'width': 324, 'height': 208, 'x': 0, 'y': 934},
                'label': 'add_preset_Social_Facebook_5',
                'tags': null
            }
        }],
        'compTypes': ['wysiwyg.viewer.components.FacebookShare', 'wysiwyg.viewer.components.WFacebookLike', 'wysiwyg.viewer.components.WFacebookComment', 'wysiwyg.common.components.facebooklikebox.viewer.FacebookLikeBox']
    }
}
