'use strict'

const popupSaleSection = require('../sections/popup/popupSaleSection')
const popupWelcomeSection = require('../sections/popup/popupWelcomeSection')
const popUpsCloseTextButtonsSection = require('../sections/popup/popUpsCloseTextButtonsSection')
const popUpsCloseIconButtonsSection = require('../sections/popup/popUpsCloseIconButtonsSection')
const popupContactSection = require('../sections/popup/popupContactSection')
const popupSubscribeSection = require('../sections/popup/popupSubscribeSection')

module.exports = {
    popupContactSection,
    popupSubscribeSection,
    popupSaleSection,
    popupWelcomeSection,
    popUpsCloseTextButtonsSection,
    popUpsCloseIconButtonsSection
}
