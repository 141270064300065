const TOP_BAR = 90;

// calculate a polygon with a rectangular hole inside
export const calcPolygon = (
  top: number,
  left: number,
  width: number,
  height: number,
): string => {
  if (top < 0) {
    top = TOP_BAR;
    height = height - TOP_BAR + top;
  } else if (top < TOP_BAR) {
    top = TOP_BAR;
    height = height - TOP_BAR + top;
  }

  return (
    'polygon(' +
    '0% 0%, ' +
    '0% 100%, ' +
    `${left}px 100%, ` +
    `${left}px ${top}px, ` +
    `${left + width}px ${top}px, ` +
    `${left + width}px ${top + height}px, ` +
    `${left}px ${top + height}px, ` +
    `${left}px 100%, ` +
    '100% 100%, ' +
    '100% 0%' +
    ')'
  );
};
