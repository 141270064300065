import React from 'react';
import { Text, IconButton } from '@wix/wix-base-ui';
import { symbol as Symbol } from '@wix/santa-editor-symbols';

import styles from './BusinessAppActionItem.scss';

export interface BusinessAppActionItemProps {
  title: string;
  onClick: () => void;
  iconName: string;
  iconUrl?: string;
  dataHook: string;
  textDataHook: string;
}

export const BusinessAppActionItem: React.FC<BusinessAppActionItemProps> = ({
  title,
  onClick,
  iconName,
  iconUrl,
  dataHook,
  textDataHook,
}) => (
  <div className={styles.container} data-hook={dataHook} onClick={onClick}>
    <div className={styles.illustration}>
      {iconUrl ? (
        <img
          role="presentation"
          src={iconUrl}
          alt={title}
          className={styles.illustrationImage}
        />
      ) : (
        <Symbol
          name={iconName}
          className={styles.illustrationIcon}
          customDimensions={{ width: 20, height: 20 }}
        />
      )}
    </div>
    <div className={styles.description} data-hook={textDataHook}>
      <Text
        size="small"
        skin="standard"
        weight="normal"
        shouldTranslate={false}
      >
        {title}
      </Text>
    </div>
    <div className={styles.action}>
      <IconButton skin="standard" priority="primary">
        <Symbol
          name="arrowRightThin"
          customDimensions={{ width: 20, height: 20 }}
        />
      </IconButton>
    </div>
  </div>
);
