import { sections as sectionsUtils } from '@/util';

import { Scope } from '../scope';
import {
  isForcedSiteWidthMigration,
  isSiteWidthMigrationEnabled,
} from '../utils/experiments';
import { isCurrentSiteWidthEqualsToNew } from '../utils';

export function shouldRunMigration(scope: Scope, isNewSite: boolean) {
  const { documentServices } = scope.editorAPI;
  if (isForcedSiteWidthMigration()) return true;
  if (!isSiteWidthMigrationEnabled()) return false;

  return (
    isNewSite &&
    sectionsUtils.isSectionsEnabled() &&
    !isCurrentSiteWidthEqualsToNew(documentServices)
  );
}
