:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._addSectionPanel_n6lxj_7 {
  display: flex;
  height: calc( 100vh - var(--g-top-bar-height) - 64px); }

._addSectionPanelContentWrapper_n6lxj_11 {
  overflow-y: scroll;
  scrollbar-width: thin;
  -ms-overflow-style: -ms-autohiding-scrollbar; }
  ._addSectionPanelContentWrapper_n6lxj_11::-webkit-scrollbar {
    background-color: #f7f8f8;
    width: 6px; }
  ._addSectionPanelContentWrapper_n6lxj_11::-webkit-scrollbar-track {
    background-color: #f7f8f8; }
  ._addSectionPanelContentWrapper_n6lxj_11::-webkit-scrollbar-thumb {
    background-color: #dfe5eb;
    border-radius: 16px;
    opacity: 0.3; }
  ._addSectionPanelContentWrapper_n6lxj_11::-webkit-scrollbar-button {
    display: none; }
  ._addSectionPanelContentWrapper_n6lxj_11:hover::-webkit-scrollbar-thumb {
    opacity: 0.5; }
  ._addSectionPanelContentWrapper_noScroll_n6lxj_28 {
    overflow-y: visible; }

._addSectionPanelContent_n6lxj_11 {
  padding: 0 24px 24px 24px;
  height: calc(100% - 24px); }
  ._addSectionPanelContent_topIndent_n6lxj_34 {
    padding-top: 20px;
    height: calc(100% - 44px); }

._sectionCategoryAdditionalInfoWrapper_n6lxj_38 {
  display: flex;
  overflow-x: clip; }
