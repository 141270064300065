import { EditorPermissionsApiKey } from '@/apis';
import togglePreview from '../../../thunks/togglePreview';
import toggleMobileEditor from '../../../thunks/toggleMobileEditor';

import type { MapStateToProps, MapDispatchToProps } from 'types/redux';
import type {
  BackToEditorFromPreviewButtonOwnProps as OwnProps,
  BackToEditorFromPreviewButtonStateProps as StateProps,
  BackToEditorFromPreviewButtonDispatchProps as DispatchProps,
} from './backToEditorFromPreview';

export const mapStateToProps: MapStateToProps<StateProps, OwnProps> = ({
  state,
  editorAPI,
}) => ({
  hasUserSeenMobileEditor: editorAPI.mobile.mobileEditor.wasSeenBefore(),
  isMobileEditor: editorAPI.isMobileEditor(),
  status: state.previewingStatus,
  hasDesignPermissions: editorAPI.host
    .getAPI(EditorPermissionsApiKey)
    .has('CLASSIC-EDITOR.EDIT-DESIGN'),
});

export const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = {
  togglePreview,
  toggleMobileEditor,
};
