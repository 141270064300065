import experiment from 'experiment';
import { BaseEntryScope, createEntryPoint } from '@/apilib';
import { EditorAPIKey, EditorCoreApiKey, SiteGlobalDataApiKey } from '@/apis';
import { sections as sectionsUtils } from '@/util';
import { events } from '@/coreBi';
import * as stateManagement from '@/stateManagement';

import { AiApiKey } from './publicApiKey';
import { AiPublicApi } from './api';
import { INJECTION_BLACK_LIST } from './injectionBlackList';

export class AiScope extends BaseEntryScope {
  editorAPI = this.useDependency(EditorAPIKey);
  editorCoreAPI = this.useDependency(EditorCoreApiKey);
  siteGlobalDataAPI = this.useDependency(SiteGlobalDataApiKey);
}

export const AiEntryPoint = createEntryPoint({
  name: 'AiApi',
  Scope: AiScope,
  publicApi({ contributeApi }) {
    contributeApi(AiApiKey, AiPublicApi);
  },
  async initialize({ editorCoreAPI, editorAPI, siteGlobalDataAPI }: AiScope) {
    await editorCoreAPI.hooks._dsIsReady.promise;

    // check new site before silent installation
    const isNewSite =
      editorAPI.generalInfo.isFirstSave() || editorAPI.generalInfo.isDraft();

    await editorCoreAPI.hooks.initReady.promise;
    await editorCoreAPI.hooks.stageIsReadyAndVisible.promise;
    await siteGlobalDataAPI.getIsSiteGlobalDataReadyPromise();
    await editorCoreAPI.hooks.appsSilentInstallationReady.promise;

    const isBlackListed = INJECTION_BLACK_LIST.has(
      editorAPI.generalInfo.getSiteOriginalTemplateId(),
    );
    const isSchoolMode = stateManagement.schoolMode.selectors.isEnabled(
      editorAPI.store.getState(),
    );
    if (
      ((experiment.isOpen('se_aiInjectionIntoTemplate') ||
        experiment.isOpen('se_aiInjectionIntoTemplateAllPages')) &&
        isNewSite &&
        !isSchoolMode &&
        !isBlackListed &&
        sectionsUtils.isSectionsEnabled()) ||
      experiment.isOpen('se_forceAiInjectionIntoTemplate')
    ) {
      editorAPI.bi.event(events.aiTextGenerator.panelOpenIntent, {
        panelType: 'injection to site',
        is_intend: true,
      });

      editorAPI.panelManager.openPanel('ai.panels.welcomePanel');
    } else {
      editorAPI.bi.event(events.aiTextGenerator.panelOpenIntent, {
        panelType: 'injection to site',
        is_intend: false,
      });
    }
  },
});
