import { isContentInjectionLogUrlParam } from '../contentInjectionUtil';
import {
  DEFAULT_NUMBER_OF_CONTENT_ITEMS,
  DEFAULT_NUMBER_OF_MEDIA_ITEMS_PER_CATEGORY,
  MAX_NUMBER_OF_LIST_CONTENT_ITEMS,
} from './injectConsts';

import type {
  CeType,
  ContentItem,
  MediaItem,
  ListContentItem,
  ISectionBrief,
  IPageDescription,
  ContentManager,
} from '@/presetApi';
import type {
  RegularContentInjectionValues,
  ListContentInjectionValues,
} from '../scan/scanTypes';

export function getCeTypeSuggestionList(
  contentManager: ContentManager,
  ceType: string,
  isList: boolean,
): ISectionBrief[] {
  const caasPageSuggestions: IPageDescription[] = contentManager.getPages();
  return caasPageSuggestions
    .reduce<ISectionBrief[]>(
      (caasSectionSuggestionList, caasPageSuggestion) => [
        ...caasSectionSuggestionList,
        ...caasPageSuggestion.sections,
      ],
      [],
    )
    .filter(
      (caasSectionSuggestion: ISectionBrief) =>
        caasSectionSuggestion.ceType === ceType &&
        filterSectionBriefListIfNeeded(caasSectionSuggestion, isList),
    );
}

export function filterSectionBriefListIfNeeded(
  caasSectionSuggestion: ISectionBrief,
  isList: boolean,
): boolean {
  return isList
    ? caasSectionSuggestion.cardinality > 1
    : caasSectionSuggestion.cardinality === 1;
}

export function generateContentFromContentManager(
  contentManager: ContentManager,
  type: CeType | ISectionBrief,
  isList: boolean,
  countOfItemsFormInjectionMap: number,
): ContentItem[] | ListContentItem[] {
  return isList
    ? contentManager.generateListContentItems(
        type,
        countOfItemsFormInjectionMap,
        countOfItemsFormInjectionMap,
      )
    : contentManager.generateContentItems(type, countOfItemsFormInjectionMap);
}

export function generateContentToInject(
  contentManager: ContentManager,
  ceType: string,
  isList: boolean,
  presetInjectableMap:
    | RegularContentInjectionValues
    | ListContentInjectionValues,
  filterMedia: boolean = true,
) {
  const sectionBriefList = getCeTypeSuggestionList(
    contentManager,
    ceType,
    isList,
  );

  const generateContentType = sectionBriefList[0] || (ceType as CeType);
  const countOfItemsFormInjectionMap = isList
    ? getCountOfItemsFormInjectableMap(
        presetInjectableMap as ListContentInjectionValues,
      )
    : DEFAULT_NUMBER_OF_CONTENT_ITEMS;
  const contentManagerContentToInject = generateContentFromContentManager(
    contentManager,
    generateContentType,
    isList,
    countOfItemsFormInjectionMap,
  );

  // TODO: for dev purposes - check CAAS API data and types
  if (isContentInjectionLogUrlParam()) {
    console.log(
      'injectable CeType: ',
      ceType,
      'sectionBriefList: ',
      sectionBriefList,
    );
    console.log(
      'contentManagerContentToInject: ',
      contentManagerContentToInject,
    );
  }

  const contentInjectionMaxRandomNumber =
    contentManagerContentToInject.length || DEFAULT_NUMBER_OF_CONTENT_ITEMS;
  let contentToInject = selectRandomCaasContent(
    contentManagerContentToInject,
    contentInjectionMaxRandomNumber,
  );
  if (filterMedia) {
    contentToInject = getContentItemFilteredByMedia(contentToInject, isList);
  }
  return contentToInject;
}

export function selectRandomCaasContent(
  contentManagerContentToInject: ContentItem[] | ListContentItem[],
  maxRandomNumber: number,
) {
  const randomNumber = Math.floor(Math.random() * maxRandomNumber);
  return contentManagerContentToInject[randomNumber];
}

export function getContentItemFilteredByMedia(
  contentItem: ContentItem | ListContentItem,
  isList: boolean,
): ContentItem {
  if (!isList) {
    // @ts-expect-error
    contentItem.media = getContentItemFilteredMediaByTypeAmountRestriction(
      (contentItem as ContentItem).media,
    );
  }
  // @ts-expect-error
  return contentItem;
}

export function getCountOfItemsFormInjectableMap(
  injectableMap: ListContentInjectionValues,
) {
  if (injectableMap.list.length > MAX_NUMBER_OF_LIST_CONTENT_ITEMS) {
    return injectableMap.list.length;
  }
  return MAX_NUMBER_OF_LIST_CONTENT_ITEMS;
}

export function getContentItemFilteredMediaByTypeAmountRestriction(
  mediaItems: MediaItem[],
): any[] {
  const itemsTypeCounts: any = {};
  return mediaItems.filter((mediaItem: MediaItem) => {
    if (!itemsTypeCounts[mediaItem.mediaType]) {
      itemsTypeCounts[mediaItem.mediaType] = 0;
    }
    itemsTypeCounts[mediaItem.mediaType]++;
    return (
      itemsTypeCounts[mediaItem.mediaType] <
      DEFAULT_NUMBER_OF_MEDIA_ITEMS_PER_CATEGORY
    );
  });
}
