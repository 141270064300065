import * as util from '@/util';
import { translate } from '@/i18n';
import * as stateManagement from '@/stateManagement';
import React from 'react';
import _ from 'lodash';
import * as baseUI from '@/baseUI';
import { CustomScroll } from '@wix/wix-base-ui';
import * as symbols from '@wix/santa-editor-symbols';

//TYPE WAS GENERATED, remove this line when reviewed
interface BlogPromoSlideProps {
  panelName: string;
  title: string;
  subTitle: string;
  learnMoreLabel: string;
  pros: Array<string>;
  buttons: Array<AnyFixMe>;
  helpArticleId: string;
  onClose?: FunctionFixMe;
  onCloseClick?: FunctionFixMe;
  onButtonClick?: FunctionFixMe;
  onLearnMoreClick?: FunctionFixMe;
  illustration?: JSX.Element;
  closeButtonClass?: string;
  openHelpCenter?: FunctionFixMe;
}

class BlogPromoSlide extends React.Component<BlogPromoSlideProps> {
  static displayName = 'BlogPromoSlide';

  onCloseClick = () => {
    if (this.props.onCloseClick) {
      this.props.onCloseClick();
    }
    this.props.onClose();
  };

  onLearnMoreClick = () => {
    if (this.props.onLearnMoreClick) {
      this.props.onLearnMoreClick();
    }
    this.props.openHelpCenter(this.props.helpArticleId);
  };

  render() {
    return (
      <div className="blog-promo-slide">
        <CustomScroll heightRelativeToParent="100%">
          <div
            onClick={this.onCloseClick}
            className={`btn-close ${this.props.closeButtonClass || ''}`}
          >
            <symbols.symbol name="headerCloseButton" />
          </div>

          {this.props.illustration || (
            <img
              src={util.media.getMediaUrl(
                'wixBlog/appMenuPanel/firstTimeTour.png',
              )}
              className="banner"
            />
          )}

          <div className="content">
            <h1 className="title">{translate(this.props.title)} </h1>

            <p className="subTitle">{translate(this.props.subTitle)}</p>

            <ul className="pros">
              {/* TODO: Fix this the next time the file is edited. */}
              {/* eslint-disable-next-line you-dont-need-lodash-underscore/map */}
              {_.map(this.props.pros, (pro) => (
                <li key={pro} className="bullet">
                  <span className="bullet-symbol">
                    <symbols.symbol name="cyclePickerV" />
                  </span>
                  <p className="bullet-text">{translate(pro)}</p>
                </li>
              ))}
            </ul>

            <div className="buttons">
              {/* TODO: Fix this the next time the file is edited. */}
              {/* eslint-disable-next-line you-dont-need-lodash-underscore/map */}
              {_.map(this.props.buttons, (btn) => (
                <baseUI.button
                  label={btn.label}
                  onClick={() => this.props.onButtonClick(btn.key)}
                  key={btn.key}
                  className={btn.className}
                />
              ))}
            </div>

            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                this.onLearnMoreClick();
              }}
            >
              {translate(this.props.learnMoreLabel)}
            </a>
          </div>
        </CustomScroll>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: AnyFixMe) => ({
  openHelpCenter: (helpId: AnyFixMe) =>
    dispatch(stateManagement.panels.actions.openHelpCenter(helpId)),
});

const ConnectedComponent = util.hoc.connect(
  util.hoc.STORES.STATE_AND_DS,
  null,
  mapDispatchToProps,
)(BlogPromoSlide);
ConnectedComponent.pure = BlogPromoSlide;

export default ConnectedComponent;
