import {
  leftBar,
  userPreferences,
  bi,
  mobile,
  panels,
} from '@/stateManagement';

import type { EditorAPI } from '@/editorAPI';
import type { BiEventFields, BiEventDefinition } from 'types/bi';

export const mapStateToProps = ({ editorAPI, state }: AnyFixMe) => {
  const device = leftBar.selectors.getDeviceType(state);
  const pageId = editorAPI.dsRead.pages.getPrimaryPageId();
  const bgData = editorAPI.dsRead.pages.background.get(pageId, device) || {
    ref: {},
    mediaRef: null,
  };

  const isMobileEditor = mobile.selectors.isMobileEditor(state);

  return {
    bgData,
    isMobileEditor,
  };
};

const getEditorAPI = (
  dispatch: AnyFixMe,
  getState: AnyFixMe,
  { editorAPI }: AnyFixMe,
) => editorAPI;

export const mapDispatchToProps = (dispatch: AnyFixMe, ownProps: AnyFixMe) => {
  const editorAPI: EditorAPI = dispatch(getEditorAPI);

  const sendBi = (biEvent: BiEventDefinition, biParams: BiEventFields) => {
    dispatch(bi.actions.event(biEvent, biParams));
  };

  const markDataChange = () => {
    const pageId = editorAPI.dsRead.pages.getPrimaryPageId();

    dispatch(
      userPreferences.actions.setSessionUserPreferences(
        `bg_image_settings_data_changed_${pageId}`,
        true,
      ),
    );
  };

  const closePanel = () => {
    dispatch(panels.actions.closePanelByName(ownProps.panelName));
  };

  return {
    closePanel,
    sendBi,
    markDataChange,
  };
};
