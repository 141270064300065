/*eslint max-lines: [2, { "max": 1300, "skipComments": true, "skipBlankLines": true}]*/
import _ from 'lodash';
import * as util from '@/util';
import { translate } from '@/i18n';
import * as failedPanelUtil from '../utils/failedPanelUtil';
import * as baseUI from '@/baseUI';
import * as stateManagement from '@/stateManagement';
import { dealer, type SitePremiumState } from '@/stateManagement';
import savePublishErrorPanelData, {
  type SavePublishErrorPanelData,
} from '../constants/savePublishErrorPanelData';
import * as coreBi from '@/coreBi';
import * as core from '@/core';
import constants, { type ProgressStatus } from '@/constants';
import experiment from 'experiment';
import {
  SiteWasSavedPanelCloseSource,
  type SiteWasSavedPanelProps,
  type SiteWasSavedPanelResult,
} from '../panels/save/siteWasSavedPanel';

import type { EditorAPI } from '@/editorAPI';
import type { Logger } from 'types/core';
import type {
  DocumentServiceError,
  DocumentServiceErrorType,
  PagesBackgroundObject,
} from 'types/documentServices';
import type { SaveInteractionStartedSource } from 'types/fedops/saveInteraction';
import type { SectionizerPublicApi } from '@/sectionizer';

import type { FeedbackPanelResult } from '../panels/save/feedbackPanel/feedbackPanel';
import { feedbackPanelFlows } from '../panels/save/feedbackPanel/feedbackPanel.flows';
import { prefetchFeedbackPanelDealerData } from '../panels/save/feedbackPanel/feedbackPanel.dealer';

interface SaveOptions {
  origin?: string;
  isPublish?: boolean;
  onBoarding?: boolean;
  forceOBMigration?: boolean;
  getBackToADI?: boolean;
  keepOriginEditorUrl?: boolean;
  preventSiteSavedPanel?: boolean;
  overrideTitle?: string;
  overrideSubtitle?: string;
  overrideActionButtonLabel?: string;
  overrideFreeDomainRadioButtonLabel?: string;
  shouldConnectDomain?: boolean;
  sourceOfStart?: SaveInteractionStartedSource;
  preventSiteWasSavedPanel?: boolean;
}

export type SaveInBackgroundOptions = SaveOptions & {
  shouldConnectDomainAfterSilentFirstSave?: boolean;
  preventConnectDomain?: boolean;
  isSilent?: boolean;
};

interface SaveInnerResult {
  preventSiteWasSavedPanel?: boolean;
  postFirstSaveProcess?: boolean;
  origin?: string;
}

export type SaveResult = SaveInnerResult & {
  siteWasSavedPanelDontShowAgain?: boolean;
  siteWasSavedPanelResult?: SiteWasSavedPanelResult;
};

export interface SaveResultError {
  error: true;
}

const domainSelectors = stateManagement.domain.selectors;
const savePublishSelectors = stateManagement.savePublish.selectors;
const { getConcurrentUsers, isConcurrentUsersEditing } =
  stateManagement.concurrentUsers.selectors;
const { getIsSessionInitializedWithWizard, isSiteCreationFullyDone } =
  stateManagement.siteCreation.selectors;
const { turnOnDomainSuggestion } = stateManagement.domainSuggestions.actions;

const { getSiteUserPreferences } = stateManagement.userPreferences.selectors;
const { setSiteUserPreferences } = stateManagement.userPreferences.actions;

function create(
  editorAPI: EditorAPI,
  sectionizerApi: SectionizerPublicApi,
  log: Logger,
) {
  const { store } = editorAPI;
  const monthInMs = 2592000000;

  const PanelNames = {
    ON_BOARDING_MIGRATION:
      'savePublish.panels.save.onBoardingMigrationConfirmationPanel',
    CHOOSE_DOMAIN: 'savePublish.panels.save.chooseDomainPanel',
    SITE_WAS_SAVED: 'savePublish.panels.save.siteWasSavedPanel',
    FEEDBACK: 'savePublish.panels.save.feedbackPanel',
    SAVE_FAILED: 'savePublish.panels.common.failPanel',
    VALIDATION_ERROR: 'panels.messagePanels.validationErrorPanel',
    CONCURRENT_SAVE: 'savePublish.panels.save.concurrentSavePanel',
    CONCURRENT_USER_SAVE: 'panels.messagePanels.concurrentUserPanel',
    SAVE_REMINDER: 'savePublish.panels.save.saveReminderPanel',
  } as const;

  const TranslationKeys = {
    SITE_SAVED_WELCOME_BACK_TITLE: 'SAVE_SITE_SAVED_WELCOME_BACK_TITLE',
    SITE_SAVED_WELCOME_BACK_SUBTITLE: 'SAVE_SITE_SAVED_WELCOME_BACK_SUBTITLE',
  } as const;

  const EDITOR_URL_TEMPLATE = `${
    util.serviceTopology.editorServerRoot || '//editor.wix.com/html/editor/web'
  }/renderer/edit/<%= siteId %>`;

  const MAX_SITE_NAME_LENGTH = 20;

  const DEFAULT_SITE_NAME_CANDIDATE = 'mysite';

  let failedPanelDueToValidationErrorIntervalId: AnyFixMe;

  // Helper Methods
  function getSanitizedSiteNameCandidate() {
    const overridingDefaultName =
      savePublishSelectors.getOverridingSiteDefaultName(store.getState());
    const defaultName = translate(
      overridingDefaultName || 'Save_Publish_Site_Default_Name',
    );

    return (
      editorAPI.siteName.sanitize(
        defaultName
          .slice(0, MAX_SITE_NAME_LENGTH)
          .replace(/\-$/, '')
          .replace(/_/g, '-'),
      ) || DEFAULT_SITE_NAME_CANDIDATE
    );
  }

  async function getUniqueSiteName(): Promise<string> {
    try {
      const sanitizedSiteNameCandidate = getSanitizedSiteNameCandidate();

      if (!(getUniqueSiteName as AnyFixMe).result) {
        (getUniqueSiteName as AnyFixMe).result = await new Promise(
          (resolve, reject) => {
            (editorAPI.siteName as AnyFixMe).generate(
              sanitizedSiteNameCandidate,
              resolve,
              reject,
            );
          },
        );
      }
      return (getUniqueSiteName as AnyFixMe).result;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  function generateUniqueSiteName(): Promise<string> {
    return getUniqueSiteName();
  }

  function displayFailurePanelMatchingAnErrorType(
    error: DocumentServiceError,
    errorType?: DocumentServiceErrorType,
    origin?: string,
  ) {
    const siteCreationIsRunning =
      getIsSessionInitializedWithWizard(store.getState()) &&
      !isSiteCreationFullyDone(store.getState());
    if (siteCreationIsRunning) return;

    if (!window.navigator.onLine) {
      openFailPanel(error, savePublishErrorPanelData.SAVE_CONNECTION_OFFLINE);
      return;
    }

    const dsSaveErrorTypes = editorAPI.dsRead.errors.save;

    switch (errorType) {
      case dsSaveErrorTypes.TOO_MANY_SITES_FOR_FREE_USER:
        disableLeavePopup();
        redirectToLimitReachedErrorPage();
        break;
      case dsSaveErrorTypes.CONCURRENT_SAVE:
      case dsSaveErrorTypes.CONCURRENT_AUTO_SAVE:
      case dsSaveErrorTypes.SITE_STALE_STATE_FROM_AUTO_SAVE:
        openConcurrentSavePanel(origin);
        break;
      case dsSaveErrorTypes.SITE_NAME_TAKEN:
        openFailPanel(
          error,
          savePublishErrorPanelData.SITE_NAME_TAKEN_PANEL_DATA,
        );
        break;
      case dsSaveErrorTypes.SITE_DELETED:
        openFailPanel(error, savePublishErrorPanelData.SITE_DELETED);
        break;
      case dsSaveErrorTypes.SAVE_PUBLISH_DISABLED_ON_SERVER:
        openFailPanel(
          error,
          savePublishErrorPanelData.SAVE_DISABLED_ON_SERVER_PANEL_DATA,
        );
        break;
      case dsSaveErrorTypes.NOT_LOGGED_IN:
        openFailPanel(error, savePublishErrorPanelData.SAVE_NOT_LOGGED_IN);
        break;
      case dsSaveErrorTypes.USER_NOT_AUTHORIZED_FOR_SITE:
        openFailPanel(
          error,
          savePublishErrorPanelData.SAVE_USER_NOT_AUTHORIZED_FOR_SITE,
        );
        break;
      case dsSaveErrorTypes.SESSION_EXPIRED:
        openFailPanel(error, savePublishErrorPanelData.SESSION_EXPIRED);
        break;
      case dsSaveErrorTypes.SAVE_IN_PROGRESS:
        break;
      case dsSaveErrorTypes.DUPLICATE_COMPONENTS:
      case dsSaveErrorTypes.DATA_REFERENCE_MISMATCH:
      case dsSaveErrorTypes.MISSING_CONTAINERS:
      case dsSaveErrorTypes.APP_CONTROLLER_REFERENCE_MISMATCH:
      case dsSaveErrorTypes.BEHAVIOR_REFERENCE_MISMATHCHES:
      case dsSaveErrorTypes.SITE_DESERIALIZATION_ERROR:
      case dsSaveErrorTypes.DESIGN_REFERENCE_MISMATCHES:
      case dsSaveErrorTypes.PROPERTY_REFERENCE_MISMATCHES:
      case dsSaveErrorTypes.CONNECTION_LIST_REFERENCE_MISMATCHES:
      case dsSaveErrorTypes.STYLE_REFERENCE_MISMATCHES:
      case dsSaveErrorTypes.PRESAVE_OPERATIONS_REJECTED:
        const openPanel = _.partial(
          openFailPanelDueToValidationError,
          error,
          errorType,
        );
        failedPanelDueToValidationErrorIntervalId = window.setInterval(
          openPanel,
          constants.SAVE_PUBLISH.VALIDATION_ERROR_POPUP_INTERVAL,
        );
        // TODO: check partial types
        // @ts-expect-error
        openPanel(errorType);
        break;
      case dsSaveErrorTypes.SAVE_DISABLED_IN_DOCUMENT_SERVICES:
        //eslint-disable-next-line no-alert,no-undef
        alert(
          'SAVE IS DISABLED.\nIn order to prevent accidental changes for user sites, your editor has been opened with the save/autosave disabled.',
        );
        break;
      default:
        openFailPanel(
          error,
          savePublishErrorPanelData.SAVE_ERROR_DEFAULT_PANEL_DATA,
        );
        break;
    }
  }

  function openFailPanelDueToValidationError(
    error: DocumentServiceError,
    errorType: DocumentServiceErrorType,
  ) {
    if (
      areRelevantPanelsAlreadyOpen([
        PanelNames.VALIDATION_ERROR,
        PanelNames.SAVE_FAILED,
      ])
    ) {
      return;
    }
    editorAPI.bi.event(coreBi.events.DATA_CORRUPTION_MESSAGE, {
      date_corruption_type: errorType,
    });
    const errorCode = error && failedPanelUtil.extractDocumentErrorCode(error);
    const onConfirm = util.windowInteractionUtils.reloadPageWithoutQuestion;
    const openHelpCenter = editorAPI.help.openHelpCenter;
    const finalPanelData = Object.assign(
      { errorCode },
      savePublishErrorPanelData.VALIDATION_ERROR,
      { onConfirm, openHelpCenter, errorType },
    );
    editorAPI.panelManager.openPanel(
      PanelNames.VALIDATION_ERROR,
      finalPanelData,
      true,
    );
  }

  function openConcurrentSavePanel(origin: string) {
    const state = editorAPI.store.getState();
    const concurrentTabsProps = {
      origin,
    };
    const concurrentUsers = getConcurrentUsers(state);
    const anyConcurrentUsers: boolean = isConcurrentUsersEditing(state);
    const panelProps = anyConcurrentUsers
      ? concurrentUsers
      : concurrentTabsProps;

    editorAPI.panelManager.openPanel(
      anyConcurrentUsers
        ? PanelNames.CONCURRENT_USER_SAVE
        : PanelNames.CONCURRENT_SAVE,
      panelProps,
      true,
    );
  }

  function openSaveReminderPanel(
    mainText?: string,
    headerText?: string,
    buttonText?: string,
    leavePanelsOpen: boolean = true,
  ): void {
    editorAPI.panelManager.openPanel(
      PanelNames.SAVE_REMINDER,
      {
        saveMethod: _.partial(save, editorAPI as AnyFixMe, {
          origin: 'remember_to_save_active_save_popup',
        }),
        mainText,
        headerText,
        buttonText,
      },
      leavePanelsOpen,
    );
  }

  function openFailPanel(
    error: DocumentServiceError,
    panelData: SavePublishErrorPanelData,
  ) {
    const saveFailEvents = coreBi.events.save.saveFailed;
    const isFirstSave = editorAPI.savePublish.getSiteState()?.isFirstSave;
    const errorCode = error && failedPanelUtil.extractDocumentErrorCode(error);
    const errorType = error && failedPanelUtil.extractDocumentErrorType(error);

    if (!errorCode) {
      panelData = savePublishErrorPanelData.DEFAULT_PANEL_DATA_NO_ERROR_CODE;
    }

    const additionalFailPanelData = {
      errorType,
      errorCode,
      biConfirm: isFirstSave
        ? saveFailEvents.First_Save_error_work_not_saved_error_ok_click
        : null,
      biHelp: isFirstSave
        ? saveFailEvents.First_Save_error_work_not_saved_error_help_center_link_click
        : null,
      biCancel: isFirstSave
        ? saveFailEvents.First_Save_error_work_not_saved_error_close_click
        : null,
      biOrigin: 'Save',
    };

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    const finalPanelData = _({})
      .assign(panelData)
      .assign(additionalFailPanelData)
      .value();

    editorAPI.panelManager.openPanel(
      PanelNames.SAVE_FAILED,
      finalPanelData,
      true,
    );
  }

  function openSiteSettingsWithDomainPage(result: SaveInnerResult) {
    return new Promise((res) => {
      editorAPI.account.openSettings({
        path: `/add-domain`,
        onPanelClosed: () => {
          res(result);
        },
      });
    });
  }

  function openSiteWasSavedPanel({
    overridingSiteState,
    overrideTitle,
    overrideSubtitle,
    sourceOfStart,
  }: {
    overridingSiteState?: SitePremiumState;
    overrideTitle?: string;
    overrideSubtitle?: string;
    sourceOfStart?: SaveInteractionStartedSource;
  } = {}): Promise<undefined | SiteWasSavedPanelResult> {
    return new Promise<undefined | SiteWasSavedPanelResult>((resolve) => {
      const siteWasSavedPanelOverrides =
        savePublishSelectors.getOverridingSiteWasSavedPanel(store.getState());
      if (
        isUserPermittedToSeeSaveSuccessPanel() &&
        !(siteWasSavedPanelOverrides as AnyFixMe)?.disabled
      ) {
        const panelProps: SiteWasSavedPanelProps = {
          ...editorAPI.savePublish.getSiteState(),
          ...overridingSiteState,
          overrideTitle,
          overrideSubtitle,
          callback: resolve,
          shouldDisplayInnerPanel:
            !stateManagement.schoolMode.selectors.isEnabled(store.getState()),
          dealerViewer: savePublishSelectors.getIsDealerPostsaveLoaded(
            store.getState(),
          )
            ? editorAPI.wixReactDealerViewer.DealerViewer
            : undefined,
          sourceOfStart,
        };

        editorAPI.panelManager.openPanel(
          PanelNames.SITE_WAS_SAVED,
          panelProps,
          true,
        );
      } else {
        resolve(undefined);
      }
    });
  }

  function isUserPermittedToSeeSaveSuccessPanel(): boolean {
    return editorAPI.account.isUserOwner();
  }

  async function openChooseDomainPanel(
    saveOptions: SaveOptions,
    isFirstSave: boolean,
    isDraftMode: boolean,
    shouldConnectDomainAfterSilentFirstSave?: boolean,
  ): Promise<SaveResult> {
    const defaultSiteName = isFirstSave
      ? await generateUniqueSiteName()
      : editorAPI.siteName.get();

    return new Promise<SaveResult>((resolve, reject) => {
      const domainPanelOptions = _(saveOptions)
        .pick([
          'overrideTitle',
          'overrideSubtitle',
          'overrideOnCancel',
          'overrideActionButtonLabel',
          'origin',
          'overrideFreeDomainRadioButtonLabel',
          'overrideFreeDomainPrefix',
        ])
        .assign({
          defaultSiteName,
          shouldDisplayConnectDomainOption:
            !stateManagement.schoolMode.selectors.isEnabled(store.getState()),
          freeDomainPrefix: domainSelectors.getFreeDomainPrefix(
            store.getState(),
            editorAPI.dsRead,
          ),
          connectDomainHandler: () =>
            connectDomainHandler(
              saveOptions,
              resolve,
              reject,
              isFirstSave,
              isDraftMode,
              shouldConnectDomainAfterSilentFirstSave,
            ),
          freeDomainHandler: (chosenSiteName: string) =>
            applyFreeDomainHandler(
              saveOptions,
              resolve,
              reject,
              isFirstSave,
              isDraftMode,
              chosenSiteName,
              shouldConnectDomainAfterSilentFirstSave,
            ),
        })
        .value();

      const overridingDomainPanel =
        savePublishSelectors.getOverridingDomainPanel(store.getState());

      const overridingDomainPanelName =
        (overridingDomainPanel as AnyFixMe)?.panelName ?? null;

      editorAPI.panelManager.openPanel(
        overridingDomainPanelName || PanelNames.CHOOSE_DOMAIN,
        domainPanelOptions,
        true,
      );
    });
  }

  function getPanelOptions(result: SaveInnerResult) {
    const siteState = editorAPI.savePublish.getSiteState();
    const defaultOptions = {
      title: 'SAVE_SITE_SAVED_TITLE_TITLE',
      subtitle: 'PREMIUM_TITLE_SUBTITLE',
      isFirstSave: siteState.isFirstSave,
      isFirstPublish: !siteState.isSitePublished,
      isPremium: siteState.isSitePremium,
      flowId: constants.CONNECT_DOMAIN.FLOWS.SAVE,
      origin: constants.CONNECT_DOMAIN.FLOWS.SAVE,
    };
    if (result?.postFirstSaveProcess) {
      return {
        ...defaultOptions,
        title: 'SITE_SAVED_CONNECT_DOMAIN_AFTER_ADD_PAGE_TITLE',
        subtitle: 'SITE_SAVED_CONNECT_DOMAIN_AFTER_ADD_PAGE_SUBTITLE',
      };
    }
    if (
      result?.origin === constants.CONNECT_DOMAIN.FLOWS.FIRST_MANUAL_PUBLISH
    ) {
      return {
        ...defaultOptions,
        title: 'PUBLISH_CHOOSE_DOMAIN_AFTER_ADD_PAGE_TITLE_TITLE',
        flowId: constants.CONNECT_DOMAIN.FLOWS.FIRST_MANUAL_PUBLISH,
        origin: result?.origin,
      };
    }
    return defaultOptions;
  }

  function openConnectDomainPanel(result: SaveInnerResult) {
    return new Promise<SaveInnerResult>((resolve) => {
      const onConnectDomainFlowEnded = _.partial(resolve, result);
      const panelOptions = {
        onConnectDomainFlowEnded,
        ...getPanelOptions(result),
      };

      editorAPI.panelManager.openPanel(
        'savePublish.panels.connectDomain',
        panelOptions,
        true,
      );
    });
  }

  // Domain Handlers
  function applyFreeDomainHandler(
    saveOptions: SaveOptions,
    onSuccess: (result: SaveResult) => void,
    onError: (error: unknown) => void,
    isFirstSave: boolean,
    isDraftMode: boolean,
    chosenSiteName: string,
    shouldConnectDomainAfterSilentFirstSave?: boolean,
  ): Promise<SaveResult | SaveResultError> {
    return preformSaveWithDomainChange(
      chosenSiteName,
      saveOptions.origin,
      saveOptions,
      isFirstSave,
      isDraftMode,
    )
      .then(async ({ preventSiteWasSavedPanel }) => {
        if (
          preventSiteWasSavedPanel ||
          saveOptions.preventSiteWasSavedPanel ||
          shouldConnectDomainAfterSilentFirstSave
        ) {
          return {
            preventSiteWasSavedPanel,
          };
        }
        const siteWasSavedPanelResult = await openSiteWasSavedPanel({
          sourceOfStart: saveOptions.sourceOfStart,
        });

        return {
          preventSiteWasSavedPanel,
          siteWasSavedPanelResult,
        };
      })
      .then((result) => {
        onSuccess(result);
        return result;
      })
      .catch((error) => {
        onError(error);
        return { error: true };
      });
  }

  function onConnectDomainFlowEnded(
    result: SaveInnerResult,
    saveOptions: SaveOptions,
  ): Promise<SaveResult> {
    return new Promise((resolve, reject) => {
      function updateInternalAndViewerStatesOnPremiumChanges(
        premiumState: core.premiumServicesAPI.SiteDomainInfo,
      ) {
        const newState: SitePremiumState = {
          isDomainConnected: premiumState.isDomainConnected,
          isSitePremium: premiumState.isSitePremium,
        };
        if (
          premiumState.isDomainConnected &&
          premiumState.isSitePremium &&
          premiumState.sitePublishedDomain
        ) {
          newState.domain = premiumState.sitePublishedDomain;
          editorAPI.dsActions.generalInfo.setPublicUrl(
            premiumState.sitePublishedDomain,
          );
        }
        editorAPI.savePublish.setSitePremiumState(newState);
        if (result.preventSiteWasSavedPanel) {
          resolve(result);
        } else {
          openSiteWasSavedPanel({
            overridingSiteState: newState,
            overrideTitle: TranslationKeys.SITE_SAVED_WELCOME_BACK_TITLE,
            overrideSubtitle: TranslationKeys.SITE_SAVED_WELCOME_BACK_SUBTITLE,
            sourceOfStart: saveOptions.sourceOfStart,
          })
            .then((siteWasSavedPanelResult) => {
              resolve({
                ...result,
                siteWasSavedPanelResult,
              });
            })
            .catch(reject);
        }
      }

      function onError() {
        if (result.preventSiteWasSavedPanel) {
          resolve(result);
        } else {
          openSiteWasSavedPanel({
            sourceOfStart: saveOptions.sourceOfStart,
          }).then((siteWasSavedPanelResult) => {
            resolve({
              ...result,
              siteWasSavedPanelResult,
            });
          });
        }
      }

      editorAPI.site.getSiteDomainInfo(
        updateInternalAndViewerStatesOnPremiumChanges,
        onError,
      );
    });
  }

  function connectDomainHandler(
    saveOptions: SaveOptions,
    onSuccess: (result: SaveResult) => void,
    onError: (error: unknown) => void,
    isFirstSave: boolean,
    isDraftMode: boolean,
    shouldConnectDomainAfterSilentFirstSave?: boolean,
  ): void {
    generateUniqueSiteName()
      .then((siteName) => {
        if (shouldConnectDomainAfterSilentFirstSave) {
          return openConnectDomainPanel({
            preventSiteWasSavedPanel: true,
            postFirstSaveProcess: true,
          })
            .then((result) => onConnectDomainFlowEnded(result, saveOptions))
            .then(onSuccess);
        }
        return preformSaveWithDomainChange(
          siteName,
          saveOptions.origin,
          saveOptions,
          isFirstSave,
          isDraftMode,
        )
          .then(() =>
            openSiteSettingsWithDomainPage({
              origin: saveOptions.origin,
              preventSiteWasSavedPanel: saveOptions.preventSiteWasSavedPanel,
            }),
          )
          .then((result) => onConnectDomainFlowEnded(result, saveOptions))
          .then(onSuccess);
      })
      .catch(onError);
  }

  // Save
  function callDocumentServicesSave(
    origin: string,
    options?: SaveOptions,
  ): Promise<SaveInnerResult | undefined> {
    options = options || {};
    if (editorAPI.savePublish.isSaveInProgress()) {
      return Promise.reject();
    }

    editorAPI.savePublish.setSaveProgress(true, true);

    function unlockSave(saveSuccess: boolean, e?: any) {
      editorAPI.savePublish.setSaveProgress(false, true);
      const saveDoneCallbacks =
        savePublishSelectors.getWaitingForSaveDoneCallbacks(store.getState());
      saveDoneCallbacks.forEach((cb) => cb(saveSuccess, e));
      editorAPI.savePublish.clearSaveCallbacksOnSaveComplete();
      editorAPI.bi.tryFlushingConditionedEvents();
    }

    util.fedopsLogger.interactionStarted(
      util.fedopsLogger.INTERACTIONS.SAVE_SITE,
    );

    window.clearInterval(failedPanelDueToValidationErrorIntervalId);
    return editorAPI.savePublish
      .save(false, origin, options)
      .then(function (result) {
        unlockSave(true);

        util.fedopsLogger.interactionEnded(
          util.fedopsLogger.INTERACTIONS.SAVE_SITE,
        );

        if (options.preventSiteSavedPanel) {
          return { preventSiteWasSavedPanel: true };
        }
        // @ts-expect-error
        return result || {};
      })
      .catch(function (error) {
        unlockSave(false, error);
        const errorType = failedPanelUtil.extractDocumentErrorType(error);

        // avoid double failure panels in save and publish flows
        if (!options.isPublish) {
          displayFailurePanelMatchingAnErrorType(error, errorType, origin);
        }

        throw error;
      });
  }

  function disableLeavePopup() {
    editorAPI.store.dispatch(
      stateManagement.leavePopup.actions.setLeavePopup(false),
    );
  }

  function redirectToLimitReachedErrorPage() {
    const LIMIT_OF_FREE_SITES_REACHED_ERROR_PAGE =
      'https://wix-platform.wix.com/limit-reached';
    window.location.assign(LIMIT_OF_FREE_SITES_REACHED_ERROR_PAGE);
  }

  async function preformSaveWithDomainChange(
    siteName: string,
    origin: string,
    options: SaveOptions,
    isFirstSave: boolean,
    isDraftMode: boolean,
  ): Promise<SaveInnerResult> {
    if (!editorAPI.savePublish.isSaveInProgress()) {
      if (isFirstSave || isDraftMode) {
        await new Promise((resolve, reject) => {
          editorAPI.siteName.setAsync(
            siteName,
            resolve,
            reject,
            !(isDraftMode && siteName === editorAPI.siteName.get()),
          );
        });
      } else {
        editorAPI.siteName.rename(siteName);
      }

      return callDocumentServicesSave(origin, options).then(
        function (result) {
          if (!options.keepOriginEditorUrl) {
            updateEditorUrl();
          }
          refreshApps();
          return result;
        },
        function (error) {
          if (isUsedMetaSiteNameError(error)) {
            return null;
          }
          throw error;
        },
      );
    }

    return Promise.reject();
  }

  function updateEditorUrl() {
    const newSiteId = editorAPI.dsRead.generalInfo.getSiteId();
    const newMetaSiteId = editorAPI.dsRead.generalInfo.getMetaSiteId();

    const urlParams = util.url.parseUrl(window.location.href).query;
    delete urlParams.siteId;
    urlParams.metaSiteId = newMetaSiteId;

    let editorEditURL = _.template(EDITOR_URL_TEMPLATE)({
      siteId: newSiteId,
    });
    editorEditURL = util.url.setUrlParams(editorEditURL, urlParams);
    // TODO: check replaceState arguments
    // @ts-expect-error
    util.windowInteractionUtils.replaceState({}, '', editorEditURL);
  }

  function refreshApps() {
    const blogAppName = 'blog';
    if (
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/size
      _.size(
        (editorAPI.dsRead.wixapps.classics as AnyFixMe).getAllAppPartComps(
          blogAppName,
        ),
      ) > 0
    ) {
      (editorAPI.dsRead.wixapps.classics as AnyFixMe).reloadApp(blogAppName);
    }
  }

  function isUsedMetaSiteNameError(error: AnyFixMe) {
    const dsSaveErrorTypes = editorAPI.dsRead.errors.save;

    return (
      dsSaveErrorTypes.SITE_NAME_TAKEN ===
      failedPanelUtil.extractDocumentErrorType(error)
    );
  }

  function displayOnBoardingMigrationPanel() {
    return new Promise(function (resolve) {
      editorAPI.panelManager.openPanel(
        PanelNames.ON_BOARDING_MIGRATION,
        {
          onConfirm: resolve,
          onCancel: _.partial(resolve, 'back_to_ADI'),
        },
        true,
      );
    });
  }

  function shouldDisplayOnBoardingMigrationPanel(
    isFromBackToADIPanel: boolean,
  ): boolean {
    const isDraftMode = editorAPI.dsRead.generalInfo.isDraft();
    return (
      !isFromBackToADIPanel &&
      editorAPI.dsRead.generalInfo.isSiteFromOnBoarding() &&
      !editorAPI.dsRead.generalInfo.isTemplate() &&
      !isDraftMode
    );
  }

  function isFeedbackRelevantOrigin(origin: string) {
    return (
      experiment.isOpen('se_feedbackPanelOnSave') &&
      ['topbar', 'top_bar_site_menu', 'keyboard'].includes(origin)
    );
  }

  function getFeedbackRelevantSavesNumber() {
    return (
      getSiteUserPreferences<number>(
        constants.USER_PREFS.SAVE.FEEDBACK_RELEVANT_SAVES_NUMBER,
      )(editorAPI.store.getState()) ?? 0
    );
  }

  async function increaseFeedbackSavesCounter() {
    await store
      .dispatch(
        setSiteUserPreferences<number>(
          constants.USER_PREFS.SAVE.FEEDBACK_RELEVANT_SAVES_NUMBER,
          getFeedbackRelevantSavesNumber() + 1,
        ),
      )
      .catch(() => {
        // silent is fine in this case
      });
  }

  async function openFeedbackPanelIfNeeded(): Promise<FeedbackPanelResult | void> {
    if (experiment.isOpen('se_feedbackPanelOnSave')) {
      const manualSavesNumber = getFeedbackRelevantSavesNumber();

      for (const flow of feedbackPanelFlows) {
        const shouldShow = await flow.check(editorAPI, {
          manualSavesNumber,
        });

        if (shouldShow) {
          return flow.show(editorAPI);
        }
      }
    }
  }

  async function openFeedbackPanelAfterSiteSavedPanel(
    siteWasSavedPanelResult: SiteWasSavedPanelResult,
  ): Promise<FeedbackPanelResult | void> {
    if (experiment.isOpen('se_feedbackPanelOnSave')) {
      const isIrrelevantSavePanelResult =
        !siteWasSavedPanelResult ||
        siteWasSavedPanelResult.closeSource ===
          SiteWasSavedPanelCloseSource.InnerPanelPublishPublishLink ||
        siteWasSavedPanelResult.closeSource ===
          SiteWasSavedPanelCloseSource.InnerPanelConnectDomainActionLink;

      if (isIrrelevantSavePanelResult) {
        return;
      }

      return openFeedbackPanelIfNeeded();
    }
  }

  function setUserMadeManualSave() {
    store.dispatch(
      setSiteUserPreferences(
        constants.USER_PREFS.SAVE.USER_MADE_MANUAL_SAVE,
        true,
      ),
    );
    store.dispatch(turnOnDomainSuggestion());
  }

  function getPostSaveUserPrefs(): boolean {
    const userPrefs = getSiteUserPreferences<number>(
      constants.USER_PREFS.SAVE.SITE_SAVED_DONT_SHOW_AGAIN,
    )(editorAPI.store.getState());
    return monthInMs > Date.now() - userPrefs;
  }

  const startSaveInteraction = (options: AnyFixMe) => {
    if (options.origin !== 'publish') {
      util.fedopsLogger.interactionStarted('ui_save', {
        paramsOverrides: {
          origin: options.origin,
          ...(options.sourceOfStart
            ? { sourceOfStart: options.sourceOfStart }
            : {}),
        },
      });
    }
  };

  /**
   *
   * @param {Object} options
   * @param [options.overrideTitle]
   * @param [options.overrideSubtitle]
   * @param {function} [options.callback]
   * @param [options.origin]
   */
  function save(options?: SaveOptions): Promise<void | SaveResult> {
    options = options || {};

    const origin = options.origin || '';

    const currentEditorState = store.getState();
    if (savePublishSelectors.getIsForceSaveInBackground(currentEditorState)) {
      return saveInBackground(origin, options);
    }

    if (!editorAPI.savePublish.canSaveOrPublish()) {
      log.error('Site is being saved or published!');
      return Promise.resolve();
    }

    if (
      !options?.forceOBMigration &&
      shouldDisplayOnBoardingMigrationPanel(options.getBackToADI)
    ) {
      return displayOnBoardingMigrationPanel().then(function (res) {
        if (res === 'back_to_ADI') {
          return;
        }
        options.onBoarding = false;
        return save(_.defaults({ forceOBMigration: true }, options));
      });
    }

    dealer.actions.fetchDealerPostSave(editorAPI);

    if (experiment.isOpen('se_feedbackPanelOnSave')) {
      prefetchFeedbackPanelDealerData(editorAPI);
    }

    startSaveInteraction(options);

    const siteState = editorAPI.savePublish.getSiteState();
    const isDraftMode = editorAPI.dsRead.generalInfo.isDraft();
    const isFirstManualSave = !getSiteUserPreferences(
      constants.USER_PREFS.SAVE.USER_MADE_MANUAL_SAVE,
    )(currentEditorState);
    const isDomainConnected = domainSelectors.isDomainConnected(
      editorAPI.dsRead,
    );
    const shouldConnectDomainAfterFirstManualSave =
      !isDomainConnected &&
      isFirstManualSave &&
      util.sections.isSectionsEnabled() &&
      !util.url.isQA();

    if (
      shouldConnectDomainAfterFirstManualSave ||
      siteState?.isFirstSave ||
      isDraftMode ||
      options.shouldConnectDomain
    ) {
      return openChooseDomainPanel(
        options,
        siteState.isFirstSave,
        isDraftMode,
      ).then(async (saveResult) => {
        setUserMadeManualSave();
        if (isFeedbackRelevantOrigin(options.origin)) {
          await increaseFeedbackSavesCounter();
        }

        await openFeedbackPanelAfterSiteSavedPanel(
          saveResult.siteWasSavedPanelResult,
        );

        return saveResult;
      });
    }

    const openNextPanelIfNeeded = async ({
      preventSiteWasSavedPanel,
    }: SaveInnerResult) => {
      setUserMadeManualSave();
      if (isFeedbackRelevantOrigin(options.origin)) {
        await increaseFeedbackSavesCounter();
      }
      const siteWasSavedPanelDontShowAgain = getPostSaveUserPrefs();

      if (siteWasSavedPanelDontShowAgain || preventSiteWasSavedPanel) {
        if (!preventSiteWasSavedPanel) {
          await openFeedbackPanelIfNeeded();
        }

        util.fedopsLogger.interactionEnded('ui_save', {
          paramsOverrides: {
            sourceOfStart: options.sourceOfStart,
            sourceOfEnd: 'saveManager',
          },
        });
        return {
          siteWasSavedPanelDontShowAgain,
          preventSiteWasSavedPanel,
        };
      }

      const siteWasSavedPanelResult = await openSiteWasSavedPanel({
        overridingSiteState: {},
        overrideTitle: 'SAVE_SITE_SAVED_SMALL_TITLE_TITLE',
        overrideSubtitle: 'SAVE_SITE_SAVED_SMALL_TITLE_SUBTITLE',
        sourceOfStart: options.sourceOfStart,
      });

      await openFeedbackPanelAfterSiteSavedPanel(siteWasSavedPanelResult);

      return { siteWasSavedPanelResult };
    };

    return callDocumentServicesSave(options.origin, options).then(
      openNextPanelIfNeeded,
    );
  }

  //
  function saveInBackground(
    origin: string,
    options?: SaveInBackgroundOptions,
  ): Promise<void | SaveInnerResult> {
    if (
      util.url.getParameterByName('disableSave') === 'true' &&
      util.url.getParameterByName('isqa') === 'true'
    ) {
      //eslint-disable-next-line no-console
      console.warn(
        'saveInBackground called but ignored, disableSave and isqa are true',
      );
      return Promise.resolve();
    }
    options = options || {};
    options.sourceOfStart = options.sourceOfStart || 'unknown_bgSave';

    if (options?.preventConnectDomain) {
      return callDocumentServicesSave(origin, options);
    }

    if (
      !options?.forceOBMigration &&
      shouldDisplayOnBoardingMigrationPanel(options.getBackToADI)
    ) {
      return displayOnBoardingMigrationPanel().then(function (res) {
        if (res === 'back_to_ADI') {
          return;
        }
        options.onBoarding = false;
        return saveInBackground(
          origin,
          _.defaults({ forceOBMigration: true }, options),
        );
      });
    }

    if (options.getBackToADI) {
      options.onBoarding = true;
    }

    const siteState = editorAPI.savePublish.getSiteState();
    const isDraftMode = editorAPI.dsRead.generalInfo.isDraft();
    if (siteState?.isFirstSave) {
      return generateUniqueSiteName().then((siteName) => {
        return preformSaveWithDomainChange(
          siteName,
          origin,
          options,
          true,
          isDraftMode,
        ).then(() => {
          if (options.shouldConnectDomainAfterSilentFirstSave) {
            return openChooseDomainPanel(
              options,
              siteState.isFirstSave,
              isDraftMode,
              options.shouldConnectDomainAfterSilentFirstSave,
            );
          }
        });
      });
    }

    if (isDraftMode) {
      return preformSaveWithDomainChange(
        editorAPI.siteName.get(),
        origin,
        options,
        true,
        isDraftMode,
      ).then(() => {
        if (options.shouldConnectDomainAfterSilentFirstSave) {
          return openChooseDomainPanel(
            options,
            siteState.isFirstSave,
            isDraftMode,
            options.shouldConnectDomainAfterSilentFirstSave,
          );
        }
      });
    }

    if (options.shouldConnectDomainAfterSilentFirstSave) {
      return openChooseDomainPanel(
        options,
        siteState.isFirstSave,
        isDraftMode,
        options.shouldConnectDomainAfterSilentFirstSave,
      ).then(function () {});
    }

    return callDocumentServicesSave(origin, options);
  }

  function setTimerForSaveReminder() {
    const relevantPanels = [PanelNames.SAVE_REMINDER, PanelNames.CHOOSE_DOMAIN];
    window.setTimeout(
      remindUserToSaveIfNeeded,
      constants.SAVE_PUBLISH.SAVE_REMINDER_TIMEOUT,
    );
    let tooltipTimeout = window.setTimeout(
      showSaveTooltipIfNeeded,
      constants.SAVE_PUBLISH.SAVE_TOOLTIP_TIMEOUT,
    );
    showOnBoardingSaveTooltipIfNeeded();

    function remindUserToSaveIfNeeded() {
      const currentEditorState = store.getState();
      const isFirstManualSave =
        experiment.isOpen('se_forceSavePanelBeforeFirstManualSave') &&
        !getSiteUserPreferences(
          constants.USER_PREFS.SAVE.USER_MADE_MANUAL_SAVE,
        )(currentEditorState);
      if (
        isFirstManualSave ||
        ((editorAPI.dsRead.generalInfo.isFirstSave() ||
          editorAPI.dsRead.generalInfo.isDraft()) &&
          editorAPI.savePublish.isSaveReminderPanelEnabled())
      ) {
        const canSaveOrPublish = editorAPI.savePublish.canSaveOrPublish();
        const alreadyOpen = areRelevantPanelsAlreadyOpen(relevantPanels);
        const inZoomMode = editorAPI.zoomMode.isInZoomMode();
        const isMobileWizardEnabled = editorAPI.mobile.mobileWizard.isEnabled();

        if (
          canSaveOrPublish &&
          !alreadyOpen &&
          !inZoomMode &&
          !isMobileWizardEnabled
        ) {
          openSaveReminderPanel();
          window.clearTimeout(tooltipTimeout);
        }
        window.setTimeout(
          remindUserToSaveIfNeeded,
          constants.SAVE_PUBLISH.SAVE_REMINDER_TIMEOUT,
        );
      }
    }

    function showSaveTooltipIfNeeded() {
      if (
        editorAPI.dsRead.generalInfo.isFirstSave() ||
        editorAPI.dsRead.generalInfo.isDraft()
      ) {
        if (
          editorAPI.savePublish.canSaveOrPublish() &&
          !areRelevantPanelsAlreadyOpen(relevantPanels) &&
          !editorAPI.zoomMode.isInZoomMode()
        ) {
          baseUI.tooltipManager.showForDuration(
            'save-button-tooltip',
            constants.SAVE_PUBLISH.SAVE_TOOLTIP_DURATION,
          );
          editorAPI.bi.event(
            coreBi.events.save.saveReminder.REMINDER_DISPLAYED,
            {
              origin: 'save tool-tip',
            },
          );
        }
        tooltipTimeout = window.setTimeout(
          showSaveTooltipIfNeeded,
          constants.SAVE_PUBLISH.SAVE_TOOLTIP_TIMEOUT,
        );
      }
    }

    function showOnBoardingSaveTooltipIfNeeded() {
      if (_.invoke(editorAPI, 'dsRead.generalInfo.isSiteFromOnBoarding')) {
        window.setTimeout(function () {
          baseUI.tooltipManager.showForDuration(
            'save-button-tooltip',
            constants.SAVE_PUBLISH.SAVE_TOOLTIP_DURATION,
          );
        }, 2000);
      }
    }
  }

  function areRelevantPanelsAlreadyOpen(panels: AnyFixMe) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/some
    return _.some(
      editorAPI.panelManager.getOpenPanels(),
      function (panelDescriptor) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/includes
        return _.includes(panels, panelDescriptor.name);
      },
    );
  }

  async function saveAsTemplate(onSuccess: () => void, onError?: () => void) {
    const isStudio =
      editorAPI.dsRead?.generalInfo &&
      editorAPI.dsRead.generalInfo.getUserInfo().isStudio;
    if (!isStudio) {
      log.info(
        'Failed saving as template. User has no permissions to save as template',
      );
      return;
    }

    try {
      await sectionizerApi.saveSectionizedTemplatePromt();
    } catch (e) {
      console.error(e);
      if (onError) {
        onError();
      }
      return;
    }

    if (editorAPI.savePublish.isSaveInProgress()) {
      console.error('Save is already in progress');
      if (onError) {
        onError(); //"onError is not a function"! (undefined) This should handle the error somehow, then return!
      }
      return;
    }

    editorAPI.savePublish.setSaveProgress(true, true);
    editorAPI.savePublish.setPublishProgress(true);

    setPagesBackgroundsAsPresets();
    if (experiment.isOpen('se_siteCompletionSourceData')) {
      core.utils.componentSourceFeatureUtils.updateAllChildrenSourceData(
        editorAPI,
        {
          source: 'template',
          changedOverride: false,
        },
      );
    }

    function unlockSave(
      handler?: (result?: unknown) => void,
      result?: unknown,
    ) {
      editorAPI.savePublish.setSaveProgress(false, true);
      editorAPI.savePublish.setPublishProgress(false);
      if (handler) {
        handler(result);
      }
    }

    editorAPI.savePublish.saveAsTemplate(
      _.partial(unlockSave, onSuccess),
      _.partial(unlockSave, onError),
    );
  }

  function setPagesBackgroundsAsPresets() {
    const pagesIdsInSite = editorAPI.dsRead.pages.getPageIdList();
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
    _.forEach(pagesIdsInSite, function (pageId) {
      setPresetOnBackgroundDataItem(pageId, 'desktop');
      setPresetOnBackgroundDataItem(pageId, 'mobile');
    });
  }

  function setPresetOnBackgroundDataItem(pageId: string, device: AnyFixMe) {
    // TODO: fix when PagesBackgroundObject is moved to DS
    const bgDataItemOnDevice = (
      editorAPI.dsRead.pages as unknown as {
        background: PagesBackgroundObject;
      }
    ).background.get(pageId, device);
    if (bgDataItemOnDevice && !bgDataItemOnDevice.isPreset) {
      bgDataItemOnDevice.isPreset = true;
      editorAPI.pages.background.update(pageId, bgDataItemOnDevice, device);
    }
  }

  function getSavingStatus(): ProgressStatus {
    return editorAPI.store.getState().savingStatus;
  }

  function handleSaveError(error: AnyFixMe) {
    const errorType = failedPanelUtil.extractDocumentErrorType(error);
    displayFailurePanelMatchingAnErrorType(error, errorType);
  }

  return {
    save,
    saveInBackground,
    saveAsTemplate,
    setTimerForSaveReminder: _.once(setTimerForSaveReminder),
    displayFailurePanelMatchingAnErrorType,
    openSaveReminderPanel,
    getSavingStatus,
    handleSaveError,
    openChooseDomainPanel,
  };
}

export default create;
