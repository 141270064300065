import React from 'react';
import { PanelHeader } from '@wix/wix-base-ui';
import * as symbols from '@wix/santa-editor-symbols';

interface ColorPickerPanelContentHeaderProps {
  onMouseDown: () => void;
  onClose: () => void;
  title: string;
}

export const ColorPickerPanelContentHeader: React.FC<
  ColorPickerPanelContentHeaderProps
> = (props) => {
  return (
    <div
      onMouseDown={props.onMouseDown}
      className="panel-section color-picker-header"
    >
      <div className="color-picker-header-content">
        <span>{props.title}</span>
      </div>
      <span
        onClick={props.onClose}
        className="close-panel-button"
        data-hook="color-picker-panel-header-close-button"
      >
        <symbols.symbol name="headerCloseButton" />
      </span>
    </div>
  );
};

export const NewWorkspaceColorPickerPanelContentHeader: React.FC<
  ColorPickerPanelContentHeaderProps
> = (props) => {
  return (
    <div onMouseDown={props.onMouseDown}>
      <PanelHeader
        dataHook="color-picker-panel-header"
        onClose={props.onClose}
        noHelpBtn
        removeStripe
      >
        {props.title}
      </PanelHeader>
    </div>
  );
};
