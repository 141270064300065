import { bootstrapCorePlugins } from './core/';
import { bootstrapResizeAndPushPlugins } from './resizeAndPush';
import { bootstrapCompPlugins } from './compPlugins';
import { bootstrapSectionCompPlugins } from './sections';

import type { Scope } from '@/sections';

const bootstrapPlugins = (scope: Scope) => {
  bootstrapResizeAndPushPlugins(scope);
  bootstrapCompPlugins(scope);
  bootstrapSectionCompPlugins(scope);
};

export { bootstrapCorePlugins, bootstrapPlugins };
