import type { EntryPoint } from '@/apilib';
import { ComponentsApiKey, EditorAPIKey } from '@/apis';
import * as languagePickerRemovePlugin from './languagePickerRemovePlugin';

export const LanguagePickerEntrypoint: EntryPoint = {
  name: 'LanguagePickerApi',
  declareAPIs: () => [],
  getDependencyAPIs: () => [EditorAPIKey, ComponentsApiKey],
  extend(shell) {
    const editorAPI = shell.getAPI(EditorAPIKey);
    const componentsApi = shell.getAPI(ComponentsApiKey);

    componentsApi.hooks.wouldShowModal.tap(({ components }) => {
      return languagePickerRemovePlugin.shouldShowModal(shell, components);
    });

    editorAPI.registerRemovePlugin(
      'wysiwyg.viewer.components.LanguageSelector',
      (compRef, allowRemoveMessage, originRemoveAction) =>
        languagePickerRemovePlugin.plugin(editorAPI, originRemoveAction),
    );
  },
};
