export default function (
  editorAPI: AnyFixMe,
  compRefs: AnyFixMe,
  compGfppData: AnyFixMe,
) {
  if (
    compRefs.length &&
    editorAPI.components.getType(compRefs[0]) === 'wixui.OldBlogDeprecation'
  ) {
    compGfppData.mainActions[0].onClick = () => {
      editorAPI.panelManager.openPanel('blog.panels.blogDeprecationPanel');
    };
  }
}
