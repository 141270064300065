import React from 'react';
import PropTypes from 'prop-types';
import * as util from '@/util';
import { translate } from '@/i18n';
import ProgressBarPanel from './progressBarPanel';
import _ from 'lodash';

const tasksDisplayNameArray = [
  'Installation_Popup_Loader_Text1',
  'Installation_Popup_Loader_Text2',
  'Installation_Popup_Loader_Text3',
  'Installation_Popup_Loader_Text4',
  'Installation_Popup_Loader_Text5',
];

interface FakeProgressBarPanelProps {
  panelName: string;
  isDone: boolean;
  appName: string;
  progressMax: number;
  isError?: boolean;
}

interface FakeProgressBarPanelState {
  step: number;
  taskDisplayName: string;
}

class FakeProgressBarPanel extends React.Component<
  FakeProgressBarPanelProps,
  FakeProgressBarPanelState
> {
  static propTypes = {
    isDone: PropTypes.bool.isRequired,
    isError: PropTypes.bool,
    appName: PropTypes.string.isRequired,
  };

  static defaultProps = {
    isError: false,
    progressMax: 5,
  };

  private interval: number;

  constructor(props: FakeProgressBarPanelProps) {
    super(props);
    this.state = {
      step: 0,
      taskDisplayName: '',
    };
  }

  componentDidMount() {
    this.interval = window.setInterval(() => this.updateProgressState(), 2000);
  }

  getTitle() {
    return translate('Installation_Popup_Loader_Header', {
      App_name: this.props.appName,
    });
  }

  updateProgressState() {
    if (this.state.step < 4) {
      this.setState({
        step: this.state.step + 1,
        taskDisplayName: translate(tasksDisplayNameArray[this.state.step]),
      });
    }
    if (this.props.isDone && !this.props.isError) {
      clearInterval(this.interval);
      this.onSuccess();
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  onSuccess() {
    this.setState({
      step: this.props.progressMax,
      taskDisplayName: translate(
        tasksDisplayNameArray[this.props.progressMax - 1],
      ),
    });
  }

  render() {
    return (
      <ProgressBarPanel
        panelName={this.props.panelName}
        progressMax={this.props.progressMax}
        step={this.state.step}
        taskDisplayName={this.state.taskDisplayName}
        isError={this.props.isError}
        isDone={this.props.isDone}
        title={this.getTitle()}
        panelClosed={_.noop}
      />
    );
  }
}

export default util.hoc.connect(
  util.hoc.STORES.STATE_AND_DS,
  null,
  null,
)(FakeProgressBarPanel);
