import React from 'react';
import { useTranslation } from 'react-i18next';
import { EmptyState } from '../EmptyState/EmptyState';

import { TRANSLATIONS } from '../../translations';
import { AUTOMATION_IDS } from '../../automationIds';

import type { AmbassadorHTTPError } from '@wix/ambassador/runtime/http';
import type { DealerSegmentContent } from '../../services/dealer/dealer.segmentContent';

export interface ErrorStateProps {
  segmentContent: DealerSegmentContent;
  onHelpClick(): void;
  error: AmbassadorHTTPError<any> | unknown;
}

export const ErrorState: React.FC<ErrorStateProps> = ({
  segmentContent,
  error,
  onHelpClick,
}) => {
  const [translate] = useTranslation();

  const getTranslationKeys = () => {
    if ((error as AmbassadorHTTPError).httpStatus === -1) {
      return TRANSLATIONS.ERROR_STATE.CONNECTION_ISSUES;
    }

    return {
      ...TRANSLATIONS.ERROR_STATE.GENERAL,
      TITLE:
        segmentContent?.errorMessageTitle ||
        TRANSLATIONS.ERROR_STATE.GENERAL.TITLE,
    };
  };

  const TRANSLATION_KEYS = getTranslationKeys();

  return (
    <EmptyState
      dataHook={AUTOMATION_IDS.ERROR_STATE_CONTAINER}
      symbolName="appManager_errorStateIllustration"
      title={translate(TRANSLATION_KEYS.TITLE)}
      text={translate(TRANSLATION_KEYS.TEXT)}
      action={
        <a href="#" onClick={onHelpClick}>
          {translate(TRANSLATIONS.ERROR_STATE.LINK)}
        </a>
      }
    />
  );
};
