import pageCategories from '../utils/pageCategories';
import * as actions from './actions/actions';
import type {
  BasicAction,
  OnItemAddedHandler,
  OnRenameHandler,
} from '../panel/types';
import type { EditorAPI } from '@/editorAPI';

export interface PopupAction extends BasicAction {
  isSettings?: boolean;
  isRename?: boolean;
  isDisabled?: boolean;
}

export interface IPopup {
  id: string;
  title: string;
  managingAppDefId: string | undefined;
  biCategory: string;
}

const customSignUpAppDefId = '5f4fa4f1-5afd-4ac0-8a85-e5ac1d2b9b7d';
const customSignInAppDefId = 'bbe1406a-31f5-4f3f-9e0a-b39dfd25274f';
const customSignApps = [customSignUpAppDefId, customSignInAppDefId];

const getPopups = (
  editorAPI: EditorAPI,
  pagesAppDefId: Record<string, boolean>,
): IPopup[] =>
  (editorAPI.pages.popupPages.getDataListOrdered() as AnyFixMe)
    .map(({ id, title, managingAppDefId }: AnyFixMe) => ({
      id,
      title,
      managingAppDefId,
      biCategory: pageCategories.POPUPS.biCategory,
    }))
    .filter(
      ({ managingAppDefId }: AnyFixMe) =>
        !managingAppDefId ||
        (!pagesAppDefId[managingAppDefId] &&
          !customSignApps.includes(managingAppDefId)),
    );

const getPopupActions = (
  editorAPI: EditorAPI,
  isDesktop: boolean,
  onItemAddedHandler: OnItemAddedHandler,
  onRenameHandler: OnRenameHandler,
): PopupAction[] =>
  actions
    .popup(editorAPI, isDesktop, onItemAddedHandler, onRenameHandler)
    .filter((action) => !action.isDisabled) || [];

export default {
  getPopupActions,
  getPopups,
};
