import _ from 'lodash';
import type { EditorAPI } from '@/editorAPI';
import type { AddPanelSection, AddPanelCategory } from './types';
import * as addPanelData from '@/addPanelData';

interface CategoriesData {
  [categoryId: string]: {
    [sectionName: string]: AddPanelSection;
  };
}

const getCategorySections = (category: AddPanelCategory): AddPanelSection[] => {
  // eslint-disable-next-line lodash/prop-shorthand
  return _.flatMap(category.items, (item) => item.sections);
};

const getCategoriesData = (editorAPI: EditorAPI): CategoriesData => {
  const categories: AddPanelCategory[] = addPanelData.getCategories(editorAPI);
  const categoriesById = _.keyBy(categories, (category) => category.id);
  return _.mapValues(categoriesById, (category) => {
    const sections = getCategorySections(category);
    return _.keyBy(sections, (section) => section.sectionName);
  });
};

const fallbackSectionDescriptors = {
  'savedComponentsWelcome.savedComponentsWelcomeSection': {
    categoryId: 'savedComponents',
    sectionName: 'savedComponentsSection',
  },
};

export const createDataHelpers = (editorAPI: EditorAPI) => {
  let categoriesData: CategoriesData;

  const getSectionData = (
    categoryId: string,
    sectionName: string,
  ): AddPanelSection & { categoryId: string } => {
    if (!categoriesData) {
      categoriesData = getCategoriesData(editorAPI);
    }

    if (categoriesData?.[categoryId]?.[sectionName]) {
      return {
        ...categoriesData[categoryId][sectionName],
        categoryId,
      };
    }

    const fallback =
      fallbackSectionDescriptors[
        `${categoryId}.${sectionName}` as keyof typeof fallbackSectionDescriptors
      ];

    if (fallback) {
      return getSectionData(fallback.categoryId, fallback.sectionName);
    }

    // eslint-disable-next-line no-console
    console.warn(`Can't find ${sectionName} in category ${categoryId}`);
  };

  const resetSectionData = () => {
    categoriesData = null;
  };

  return {
    getSectionData,
    resetSectionData,
  };
};
