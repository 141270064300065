import { getAiPageToolsList } from './aiPageToolsConfiguration';

import type { AiPageToolsCategory } from './aiPageToolsConfiguration';
import type { MapStateToProps } from 'types/redux';

export interface AiPageToolsPanelStateProps {
  categories: AiPageToolsCategory[];
}

export interface AiPageToolsPanelDispatchProps {
  openHelpCenter: () => void;
}

const mapStateToProps: MapStateToProps<AiPageToolsPanelStateProps> = ({
  editorAPI,
}) => {
  const categories = getAiPageToolsList(editorAPI);

  return {
    categories,
  };
};

export { mapStateToProps };
