import { selection } from '@/stateManagement';

import type { CompRef } from 'types/documentServices';
import type { EditorAPI } from '@/editorAPI';
import type { EditorStore } from '@/stateManagement';

export function createSelectionStore({
  store,
  editorAPI,
}: {
  store: EditorStore;
  editorAPI: EditorAPI;
}) {
  const selectionActions = selection.actions;
  const selectionSelectors = selection.selectors;

  function selectComponents(
    compRefs: CompRef[],
    restrictions: Record<string, boolean>,
  ) {
    store.dispatch(selectionActions.selectComponents(compRefs, restrictions));
  }

  function setPrevComponentsAction(previousSelectedComps: CompRef[]) {
    store.dispatch(
      selectionActions.setPrevComponentsAction(previousSelectedComps),
    );
  }

  function setAncestorOfSelectedWithInteraction(compRef: CompRef) {
    store.dispatch(
      selectionActions.setAncestorOfSelectedWithInteraction(compRef),
    );
  }

  // TODO: review pos type
  function setLastClickType(clickType: 'left' | 'right'): void {
    store.dispatch(selectionActions.setLastClickType(clickType));
  }

  function setAppContainer(compRef: CompRef): void {
    store.dispatch(selectionActions.setAppContainer(compRef));
  }

  function setLastSelectionClickPosition(pos: any): void {
    store.dispatch(selectionActions.setLastSelectionClickPos(pos));
  }

  function getSelectedComponents(): CompRef[] {
    return selectionSelectors.getSelectedCompsRefs(store.getState());
  }

  function isComponentSelectedSingle(compRef: CompRef): boolean {
    const selectedCompRefs = getSelectedComponents();

    if (!compRef) {
      // NOTE: Is not clear why we check if there are selected components
      // The condition copied from the old code[1], and without it the test[2] fails.
      // [1]: https://github.com/wix-private/santa-editor/blob/3f9c868da1312973bc86326eb667dbdbf08bf4c2/packages/rEditor/src/main/app/EditorAPI.js#L550
      // [2]: https://github.com/wix-private/santa-editor/blob/b8e36b6954409e0dea0efc1799f0d201187e0cbb/santa-editor/packages/rEditor/test/rootComps/mouseCatcher.spec.ts#L185
      return selectedCompRefs.length > 0;
    }

    return selectedCompRefs.some((selectedCompRef) =>
      editorAPI.utils.isSameRef(selectedCompRef, compRef),
    );
  }

  function isComponentSelected(compRefOrRefs?: CompRef | CompRef[]): boolean {
    if (Array.isArray(compRefOrRefs) && compRefOrRefs.length > 1) {
      return compRefOrRefs.every((compRef) =>
        isComponentSelectedSingle(compRef),
      );
    }

    return isComponentSelectedSingle(
      Array.isArray(compRefOrRefs) ? compRefOrRefs[0] : compRefOrRefs,
    );
  }

  function areSameAsSelectedComponents(compRefs: CompRef[]): boolean {
    const selectedCompRefs = getSelectedComponents();
    return (
      Array.isArray(compRefs) &&
      compRefs.length === selectedCompRefs.length &&
      compRefs.every((compRef, index) =>
        editorAPI.utils.isSameRef(compRef, selectedCompRefs[index]),
      )
    );
  }

  return {
    selectComponents,
    setPrevComponentsAction,
    setLastSelectionClickPosition,
    setAncestorOfSelectedWithInteraction,
    setAppContainer,
    setLastClickType,
    getSelectedComponents,
    isComponentSelected,
    areSameAsSelectedComponents,
  };
}

export type SelectionStore = ReturnType<typeof createSelectionStore>;
