/*
not full list here for now
section names can be added if needed
 */
const ADD_PANEL_CATEGORIES = {
  TEXT: 'text',
  IMAGE: 'image',
  BUTTON: 'button',
  MEDIA: 'media',
  PAYMENT: 'payment',
  DECORATIVE: 'decorative',
  BOX: 'box',
  BOXSLIDESHOW: 'boxSlideShow',
  GALLERY: 'gallery',
  NAVIGATION: 'navigation',
  CONTACT: 'contact',
  SOCIAL: 'social',
  EMBEDS: 'embeds',
  REPEATER: 'repeater',
  DEVELOPER: 'developer',
  DATABASE: 'database',
};
export const ADD_PANEL_SECTION_NAMES: Record<
  string,
  { sectionName: string; category: string }
> = {
  // text
  THEMED_TEXT: {
    sectionName: 'richTextSection',
    category: ADD_PANEL_CATEGORIES.TEXT,
  },
  TITLES: { sectionName: 'titlesSection', category: ADD_PANEL_CATEGORIES.TEXT },
  PARAGRAPHS: {
    sectionName: 'paragraphsSection',
    category: ADD_PANEL_CATEGORIES.TEXT,
  },
  COLLAPSIBLE_TEXT: {
    sectionName: 'collapsibleTextParagraphSection',
    category: ADD_PANEL_CATEGORIES.TEXT,
  },
  // images
  MY_UPLOADED_IMAGES: {
    sectionName: 'myUploadsSection',
    category: ADD_PANEL_CATEGORIES.IMAGE,
  },
  IMAGES_COLLECTIONS: {
    sectionName: 'imageCollectionsSection',
    category: ADD_PANEL_CATEGORIES.IMAGE,
  },
  MY_SOCIAL_IMAGES: {
    sectionName: 'mySocialImagesSection',
    category: ADD_PANEL_CATEGORIES.IMAGE,
  },
  FREE_WIX_ILLUSTRATION_IMAGES: {
    sectionName: 'freeWixIllustrationImages',
    category: ADD_PANEL_CATEGORIES.IMAGE,
  },
  FREE_WIX_IMAGES: {
    sectionName: 'freeWixImages',
    category: ADD_PANEL_CATEGORIES.IMAGE,
  },
  IMAGE_IMPORT: {
    sectionName: 'imageImport',
    category: ADD_PANEL_CATEGORIES.IMAGE,
  },
  SITE_IMAGES: {
    sectionName: 'siteImages',
    category: ADD_PANEL_CATEGORIES.IMAGE,
  },
  IMAGE_UPLOAD: {
    sectionName: 'imageUpload',
    category: ADD_PANEL_CATEGORIES.IMAGE,
  },

  // buttons
  THEMED_BUTTONS: {
    sectionName: 'stylableButtonTheme',
    category: ADD_PANEL_CATEGORIES.BUTTON,
  },
  STYLABLE_BUTTONS: {
    sectionName: 'stylableButtonSection',
    category: ADD_PANEL_CATEGORIES.BUTTON,
  },
  ICON_BUTTONS: {
    sectionName: 'iconButtonsSection',
    category: ADD_PANEL_CATEGORIES.BUTTON,
  },
  IMAGE_BUTTONS: {
    sectionName: 'imageButton_redesign',
    category: ADD_PANEL_CATEGORIES.BUTTON,
  },
  DOCUMENT_BUTTONS: {
    sectionName: 'fileDownloadButtonsSection',
    category: ADD_PANEL_CATEGORIES.BUTTON,
  },
  ITUNES_BUTTON: {
    sectionName: 'iTunesDownloadButtonSection',
    category: ADD_PANEL_CATEGORIES.BUTTON,
  },
  PAY_BUTTONS: {
    sectionName: 'payButtonSection',
    category: ADD_PANEL_CATEGORIES.PAYMENT,
  },
  PAYPAL_BUTTONS: {
    sectionName: 'paypalButtonsSection',
    category: ADD_PANEL_CATEGORIES.PAYMENT,
  },
  // boxes
  MY_BOXES: {
    sectionName: 'personalizedBoxesSection',
    category: ADD_PANEL_CATEGORIES.BOX,
  },
  THEMED_BOXES: {
    sectionName: 'themedBoxesSection',
    category: ADD_PANEL_CATEGORIES.BOX,
  },
  CONTAINER_BOXES: {
    sectionName: 'containerBoxesSection',
    category: ADD_PANEL_CATEGORIES.BOX,
  },
  MULTI_STATE_BOXES: {
    sectionName: 'stateBoxSection',
    category: ADD_PANEL_CATEGORIES.BOXSLIDESHOW,
  },
  // decorative
  MY_LINES: {
    sectionName: 'myLinesSection',
    category: ADD_PANEL_CATEGORIES.DECORATIVE,
  },
  THEMED_LINES: {
    sectionName: 'themedLinesSection',
    category: ADD_PANEL_CATEGORIES.DECORATIVE,
  },
  BASIC_SHAPES: {
    sectionName: 'basicShapesSection',
    category: ADD_PANEL_CATEGORIES.DECORATIVE,
  },
  ARROWS: {
    sectionName: 'arrowsSection',
    category: ADD_PANEL_CATEGORIES.DECORATIVE,
  },
  HORIZONTAL_LINES: {
    sectionName: 'horizontalLinesSection',
    category: ADD_PANEL_CATEGORIES.DECORATIVE,
  },
  VERTICAL_LINES: {
    sectionName: 'verticalLinesSection',
    category: ADD_PANEL_CATEGORIES.DECORATIVE,
  },
  FEATURED: {
    sectionName: 'featuredSection',
    category: ADD_PANEL_CATEGORIES.DECORATIVE,
  },
  ICONS: {
    sectionName: 'iconsSection',
    category: ADD_PANEL_CATEGORIES.DECORATIVE,
  },
  ANIMAL_NATURE: {
    sectionName: 'animalNatureSection',
    category: ADD_PANEL_CATEGORIES.DECORATIVE,
  },
  LOGOS_AND_BADGES: {
    sectionName: 'logosBadgesSection',
    category: ADD_PANEL_CATEGORIES.DECORATIVE,
  },
  CHARACTERS: {
    sectionName: 'charactersSection',
    category: ADD_PANEL_CATEGORIES.DECORATIVE,
  },
  TRANSPARENT_VIDEO: {
    sectionName: 'videoBoxTransparentSection',
    category: ADD_PANEL_CATEGORIES.MEDIA,
  },
  // galleries
  GRID_GALLERIES: {
    sectionName: 'gridGalleriesSection',
    category: ADD_PANEL_CATEGORIES.GALLERY,
  },
  SLIDER_GALLERIES: {
    sectionName: 'sliderGalleriesSection',
    category: ADD_PANEL_CATEGORIES.GALLERY,
  },
  MORE_GALLERIES: {
    sectionName: 'moreGalleriesSection',
    category: ADD_PANEL_CATEGORIES.GALLERY,
  },
  // media
  SOCIAL_VIDEO: {
    sectionName: 'socialVideoSection',
    category: ADD_PANEL_CATEGORIES.EMBEDS,
  },
  VIDEO_BOX: {
    sectionName: 'videoBoxSection',
    category: ADD_PANEL_CATEGORIES.MEDIA,
  },
  MUSIC_THEME: {
    sectionName: 'musicThemeSection',
    category: ADD_PANEL_CATEGORIES.MEDIA,
  },
  SPOTIFY_PLAYER: {
    sectionName: 'spotifyPlayerSection',
    category: ADD_PANEL_CATEGORIES.MEDIA,
  },
  ITUNES_DOWNLOAD_BUTTON: {
    sectionName: 'iTunesDownloadButtonSection',
    category: ADD_PANEL_CATEGORIES.MEDIA,
  },
  // menu
  THEMED_MENUS: {
    sectionName: 'menuThemeSection',
    category: ADD_PANEL_CATEGORIES.NAVIGATION,
  },
  STYLABLE_HORIZONTAL_MENUS: {
    sectionName: 'stylableHorizontalMenuSection',
    category: ADD_PANEL_CATEGORIES.NAVIGATION,
  },
  VERTICAL_MENUS: {
    sectionName: 'verticalMenusSection',
    category: ADD_PANEL_CATEGORIES.NAVIGATION,
  },
  ANCHORS_MENUS: {
    sectionName: 'verticalAnchorsMenuSection',
    category: ADD_PANEL_CATEGORIES.NAVIGATION,
  },
  // contacts and forms
  CONTACT: {
    sectionName: 'contactSection',
    category: ADD_PANEL_CATEGORIES.CONTACT,
  },
  PAYMENT: {
    sectionName: 'paymentSection',
    category: ADD_PANEL_CATEGORIES.PAYMENT,
  },
  DONATIONS: {
    sectionName: 'donationsSection',
    category: ADD_PANEL_CATEGORIES.PAYMENT,
  },
  JOB_APPLICATION: {
    sectionName: 'jobApplicationSection',
    category: ADD_PANEL_CATEGORIES.CONTACT,
  },
  FEEDBACK: {
    sectionName: 'feedbackSection',
    category: ADD_PANEL_CATEGORIES.CONTACT,
  },
  REQUEST: {
    sectionName: 'requestSection',
    category: ADD_PANEL_CATEGORIES.CONTACT,
  },
  WAIVER: {
    sectionName: 'waiverSection',
    category: ADD_PANEL_CATEGORIES.CONTACT,
  },
  SUBSCRIBERS: {
    sectionName: 'subscribersSection',
    category: ADD_PANEL_CATEGORIES.CONTACT,
  },
  GOOGLE_MAPS_SQUARE: {
    sectionName: 'googleMapsSquareSection',
    category: ADD_PANEL_CATEGORIES.CONTACT,
  },
  GOOGLE_MAPS_GENERIC: {
    sectionName: 'googleMapsGenericSection',
    category: ADD_PANEL_CATEGORIES.CONTACT,
  },
  GOOGLE_MAPS_STRIP: {
    sectionName: 'googleMapsStripSection',
    category: ADD_PANEL_CATEGORIES.CONTACT,
  },
  LINE_CHAT: {
    sectionName: 'lineSection',
    category: ADD_PANEL_CATEGORIES.SOCIAL,
  },
  // interactive
  BOX_SLIDESHOWS: {
    sectionName: 'boxSlideShowSection',
    category: ADD_PANEL_CATEGORIES.BOXSLIDESHOW,
  },
  SLIDESHOW_980: {
    sectionName: 'boxSlideShow980Section',
    category: ADD_PANEL_CATEGORIES.BOXSLIDESHOW,
  },
  REPEATERS_INTERACTIVE: {
    sectionName: 'repeatersInteractiveSection',
    category: ADD_PANEL_CATEGORIES.REPEATER,
  },
  PAGINATION: {
    sectionName: 'paginationSection',
    category: ADD_PANEL_CATEGORIES.BOXSLIDESHOW,
  },
  PROGRESS_BAR: {
    sectionName: 'progressBarSection',
    category: ADD_PANEL_CATEGORIES.BOXSLIDESHOW,
  },
  TABS: {
    sectionName: 'tabsSection',
    category: ADD_PANEL_CATEGORIES.BOXSLIDESHOW,
  },
  // lists
  REPEATERS: {
    sectionName: 'repeaterSection',
    category: ADD_PANEL_CATEGORIES.REPEATER,
  },
  REPEATERS_BLANK: {
    sectionName: 'repeatersBlankSection',
    category: ADD_PANEL_CATEGORIES.REPEATER,
  },
  GRID: { sectionName: 'gridSection', category: ADD_PANEL_CATEGORIES.REPEATER },
  // social
  SOCIAL_BAR: {
    sectionName: 'socialBarSection',
    category: ADD_PANEL_CATEGORIES.SOCIAL,
  },
  FACEBOOK: {
    sectionName: 'facebookSection',
    category: ADD_PANEL_CATEGORIES.SOCIAL,
  },
  TWITTER: {
    sectionName: 'twitterSection',
    category: ADD_PANEL_CATEGORIES.SOCIAL,
  },
  PINTEREST: {
    sectionName: 'pinterestSection',
    category: ADD_PANEL_CATEGORIES.SOCIAL,
  },
  VK: { sectionName: 'vkSection', category: ADD_PANEL_CATEGORIES.SOCIAL },
  LINE: {
    sectionName: 'lineSection',
    category: ADD_PANEL_CATEGORIES.DECORATIVE,
  },
  YOUTUBE_SOCIAL: {
    sectionName: 'youtubeSocialSection',
    category: ADD_PANEL_CATEGORIES.SOCIAL,
  },
  // inputs
  TEXT_INPUT: {
    sectionName: 'textInputSection',
    category: ADD_PANEL_CATEGORIES.DEVELOPER,
  },
  TEXT_AREA: {
    sectionName: 'textAreaSection',
    category: ADD_PANEL_CATEGORIES.DEVELOPER,
  },
  RICH_TEXT: {
    sectionName: 'richTextBoxSection',
    category: ADD_PANEL_CATEGORIES.DEVELOPER,
  },
  RADIO_GROUP: {
    sectionName: 'radioGroupSection',
    category: ADD_PANEL_CATEGORIES.DEVELOPER,
  },
  CHECKBOX_GROUP: {
    sectionName: 'checkboxGroupSection',
    category: ADD_PANEL_CATEGORIES.DEVELOPER,
  },
  CHECKBOX: {
    sectionName: 'checkboxSection',
    category: ADD_PANEL_CATEGORIES.DEVELOPER,
  },
  SELECTION: {
    sectionName: 'selectionSection',
    category: ADD_PANEL_CATEGORIES.DEVELOPER,
  },
  SELECTION_TAGS: {
    sectionName: 'selectionTagsListSection',
    category: ADD_PANEL_CATEGORIES.DEVELOPER,
  },
  DATE_PICKER: {
    sectionName: 'datePickerSection',
    category: ADD_PANEL_CATEGORIES.DEVELOPER,
  },
  TIME_PICKER: {
    sectionName: 'timePickerSection',
    category: ADD_PANEL_CATEGORIES.DEVELOPER,
  },
  SLIDER: {
    sectionName: 'sliderSection',
    category: ADD_PANEL_CATEGORIES.DEVELOPER,
  },
  RANGE_SLIDER: {
    sectionName: 'rangeSlider',
    category: ADD_PANEL_CATEGORIES.DEVELOPER,
  },
  FILE_UPLOADER: {
    sectionName: 'fileUploaderSection',
    category: ADD_PANEL_CATEGORIES.DEVELOPER,
  },
  RATINGS_INPUT: {
    sectionName: 'ratingsInputSection',
    category: ADD_PANEL_CATEGORIES.DEVELOPER,
  },
  RATINGS_DISPLAY: {
    sectionName: 'ratingsDisplaySection',
    category: ADD_PANEL_CATEGORIES.DEVELOPER,
  },
  ADDRESS_INPUT: {
    sectionName: 'addressInputSection',
    category: ADD_PANEL_CATEGORIES.DEVELOPER,
  },
  TOGGLE_SWITCH: {
    sectionName: 'toggleSwitchSection',
    category: ADD_PANEL_CATEGORIES.DEVELOPER,
  },
  SIGNATURE_INPUT: {
    sectionName: 'signatureInputSection',
    category: ADD_PANEL_CATEGORIES.DEVELOPER,
  },
  CAPTCHA: {
    sectionName: 'captchaSection',
    category: ADD_PANEL_CATEGORIES.DEVELOPER,
  },
  // embed
  LOTTIE: { sectionName: 'lottieEmbed', category: ADD_PANEL_CATEGORIES.EMBEDS },
  // content
  DATABASE_WELCOME: {
    sectionName: 'databaseWelcomeSection',
    category: ADD_PANEL_CATEGORIES.DATABASE,
  },
  PRESETS: {
    sectionName: 'presetsSection',
    category: ADD_PANEL_CATEGORIES.DATABASE,
  },
  COLLECTIONS: {
    sectionName: 'collectionsSection',
    category: ADD_PANEL_CATEGORIES.DATABASE,
  },
  DATA_BINDING: {
    sectionName: 'dataBindingSection',
    category: ADD_PANEL_CATEGORIES.DATABASE,
  },
  RICH_CONTENT: {
    sectionName: 'richContent',
    category: ADD_PANEL_CATEGORIES.DATABASE,
  },
};
