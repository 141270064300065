import { EditorAPIKey } from '@/apis';
import * as stateManagement from '@/stateManagement';

import type { Shell } from '@/apilib';

export function createAscendApi(shell: Shell) {
  const editorAPI = shell.getAPI(EditorAPIKey);
  const getPlan = () =>
    stateManagement.ascend.selectors.getAscendPlan(editorAPI.store.getState());

  return { getPlan };
}

export type AscendApi = ReturnType<typeof createAscendApi>;
