import { createCollectionsApi } from '@wix/wix-code-collections-api';

import { VFSTransport } from './VFSTransport';

import type { FileSystem } from 'types/documentServices';

export {
  createSchema,
  updateSchema,
  removeSchema,
  restoreSchema,
  setDisplayField,
  setDisplayName,
  setNamespace,
  setPermissions,
  addField,
  updateField,
  markFieldDeleted,
  restoreField,
  deleteFieldPermanently,
  ROLES,
  PRESETS,
  PERMISSIONS_BY_PRESET,
  getPermissionsByPreset,
  findPresetByPermissions,
} from '@wix/wix-code-collections-api';

export const createSchemasAPI = (fileSystem: FileSystem) => {
  return createCollectionsApi({
    transportLayer: new VFSTransport(fileSystem),
  });
};
