import * as stateManagement from '@/stateManagement';
import { fedopsLogger } from '@/util';

import type { Scope } from '@/sections';
import type { CompRef } from 'types/documentServices';

const { getViewPort } = stateManagement.domMeasurements.selectors;

const onStretchedCompAddedToSection = (
  { editorAPI, components }: Scope,
  compRef: CompRef,
  viewPort: stateManagement.Viewport,
) => {
  const SCROLL_DURATION = 1.5;
  const DANIELS_MAGIC_GAP_SIZE = 60;
  const compLayoutRelativeToScreen =
    components.layout.getRelativeToScreen(compRef);
  let scrollPosition = {
    y: compLayoutRelativeToScreen.y - DANIELS_MAGIC_GAP_SIZE,
    x: 0,
  };

  if (compLayoutRelativeToScreen.height < viewPort.height) {
    const compCenterYRelativeToScreen =
      compLayoutRelativeToScreen.y + compLayoutRelativeToScreen.height / 2;
    scrollPosition = {
      y: compCenterYRelativeToScreen - viewPort.height / 2 - viewPort.marginTop,
      x: 0,
    };
  }

  return new Promise<void>((resolve) =>
    editorAPI.scroll.animateScrollTo(scrollPosition, SCROLL_DURATION, resolve),
  );
};

export const handleCompAttachedToSection = async (
  scope: Scope,
  compRef: CompRef,
) => {
  const { editorAPI } = scope;
  const isFullWidth = editorAPI.components.is.fullWidth(compRef);

  if (isFullWidth) {
    const viewPort = getViewPort(editorAPI.store.getState(), true);
    await onStretchedCompAddedToSection(scope, compRef, viewPort);
  }

  fedopsLogger.interactionEnded(
    fedopsLogger.INTERACTIONS.CLASSIC_SECTIONS.COMP_ATTACHED_TO_SECTION,
  );
};
