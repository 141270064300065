:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._right-panels_1l8vd_7 {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  z-index: 1451; }
