import {
  WIX_BLOG,
  WIX_BOOKINGS,
  WIX_ECOM,
  WIX_NEW_STORES,
  WIX_PRO_GALLERY,
} from '@wix/app-definition-ids';

export const SCANNING_WIDTH = 1520;
export const PREVIEW_BASE_WIDTH = 270;
export const NOTIFICATION_HEIGHT = 42;
export const SCAN_EXTRA_HEIGHT = 24;
export const MODAL_SIZE_RATIO = 0.7;
export const MAX_SCALE_RATIO = 0.35;
export const REPLACE_PAGE_NOTIFICATION_TIMEOUT = 30000;
export const ADD_SECTION_PANEL_CONTENT_WIDTH = 304;
export const TRANSPARENT_BOX = 'TransparentBox';
const ADD_PRESET_MP_ID = 'wixmp-92eb3b984feffc0bcefd53d5';
export const ADD_PRESET_MP_APP_DOMAIN = `${ADD_PRESET_MP_ID}.appspot.com`;
export const ADD_PRESET_MP_BASE_URL = `https://${ADD_PRESET_MP_ID}.wixmp.com`;
export const ADD_PRESET_SERVERLESS_BASE_URL =
  'https://editor.wix.com/_serverless/editor-presets-bundle/';
export const DEFAULT_LANGUAGE = 'en';
export const TPA_CLONE_DATA_APPS = new Set([WIX_PRO_GALLERY]);
export const COLORS_NOT_TO_APPLY_ON_INJECT_THEME = new Set([
  'color_0',
  'color_1',
  'color_2',
  'color_3',
  'color_4',
  'color_5',
  'color_6',
  'color_7',
  'color_8',
  'color_9',
  'color_10',
]);
export const ALLOWED_SILENT_INSTALLATION_APPS = new Set([
  WIX_BLOG,
  WIX_ECOM,
  WIX_BOOKINGS,
  WIX_NEW_STORES,
]);

export const dimensionsCutRegex = /fill\/w_[0-9]+,h_[0-9]+/g;
export const endingWebpDoubleQuoteRegex = /\.webp"/g;
export const endingWebpSingleQuoteRegex = /\.webp'/g;

export enum PresetConditions {
  SECTIONS_ENABLED = 'sectionsEnabled',
  SECTIONS_DISABLED = 'sectionsDisabled',
  WORKSPACE_MODE_FULL = 'workspaceModeFull',
}

/**
 * These are props that we don;t need to keep in preset because all this properties will
 * be taken compStructure.mobile structure what is actually dump of mobile component itself,
 * but we still need author, hidden, other props to be in mobile hints
 */
export const BLACK_LISTED_MOBILE_HINTS_PROPS = [
  'recommendedHeight',
  'recommendedWidth',
  'recommendedScale',
  'originalCompId',
  'offsetX',
  'offsetY',
  'offsetOrigin',
  'orderIndex',
  'modifiedByUser',
  'shouldBeForceConverted',
  'recommendedComponentsOrder',
];

export const SECTIONS_SCOPE_PREFIX = {
  SectionCategories: 'sections',
  AISectionCategories: 'ai_sections',
};

export const AI_SECTIONS_COLLECTION_ID = 'AISectionCategories';
export const AI_SECTION_FEEDBACK_ALREADY_SHOWN =
  'createSectionWithAI.feedbackModalAlreadyShown';
