// @ts-nocheck
import * as util from '@/util';

const keyboardShiftKey = util.browserUtil.getShiftKey();
const SHIFT_KEY_VALUE = keyboardShiftKey.value;
const SHIFT_KEY_LABEL = keyboardShiftKey.label;

const keyboardSpecialKey = util.browserUtil.getKeyboardSpecialKey();
const SPECIAL_KEY_VALUE = keyboardSpecialKey.value;
const SPECIAL_KEY_LABEL = keyboardSpecialKey.label;

const altKeyboardSpecialKey = util.browserUtil.getAltSpecialKey();
const ALT_SPECIAL_KEY_VALUE = altKeyboardSpecialKey.value;
const ALT_SPECIAL_KEY_LABEL = altKeyboardSpecialKey.label;

export default {
  editor: {
    AUTOSAVE_ON_OFF: {
      key: [
        `${SPECIAL_KEY_VALUE} + ${SHIFT_KEY_VALUE} + ${ALT_SPECIAL_KEY_VALUE} + a`,
        `${SPECIAL_KEY_VALUE} + ${ALT_SPECIAL_KEY_VALUE} + a`,
      ].join(','),
      label: `${SPECIAL_KEY_LABEL} + ${SHIFT_KEY_LABEL} + ${ALT_SPECIAL_KEY_LABEL} + A`,
    },
    SELECT_ALL: {
      key: `${SPECIAL_KEY_VALUE} + a`,
      label: '',
    },
    GROUP_COMPONENTS: {
      key: `${SPECIAL_KEY_VALUE} + g`,
      label: '',
    },
    UNGROUP_COMPONENTS: {
      key: `${SPECIAL_KEY_VALUE} + ${SHIFT_KEY_VALUE} + g`,
      label: '',
    },
    COPY: {
      key: `${SPECIAL_KEY_VALUE} + c`,
      label: `${SPECIAL_KEY_LABEL} + C`,
    },
    PASTE: {
      key: `${SPECIAL_KEY_VALUE} + v`,
      label: `${SPECIAL_KEY_LABEL} + V`,
    },
    PASTE_AND_APPLY_THEME: {
      key: `${SPECIAL_KEY_VALUE} + ${SHIFT_KEY_VALUE} + v`,
      label: `${SPECIAL_KEY_LABEL} + ${SHIFT_KEY_LABEL} + V`,
    },
    CUT: {
      key: `${SPECIAL_KEY_VALUE} + x`,
      label: `${SPECIAL_KEY_LABEL} + X`,
    },
    DUPLICATE: {
      key: `${SPECIAL_KEY_VALUE} + d`,
      label: `${SPECIAL_KEY_LABEL} + D`,
    },
    UNDO: {
      key: `${SPECIAL_KEY_VALUE} + z`,
      label: `${SPECIAL_KEY_LABEL} + Z`,
    },
    REDO: {
      key: [
        `${SPECIAL_KEY_VALUE} + y`,
        `${SPECIAL_KEY_VALUE} + ${SHIFT_KEY_VALUE} + z`,
      ].join(','),
      label: `${SPECIAL_KEY_LABEL} + Y`,
    },
    DELETE: {
      key: ['del', 'backspace'].join(','),
      label: 'Del',
    },
    SAVE: {
      key: `${SPECIAL_KEY_VALUE} + s`,
      label: `${SPECIAL_KEY_LABEL} + S`,
    },
    SAVE_AS_TEMPLATE: {
      key: `${SPECIAL_KEY_VALUE} + ${ALT_SPECIAL_KEY_VALUE} + t`,
      label: '',
    },
    SELECT_NEXT_COMP: {
      key: `${SPECIAL_KEY_VALUE} + m`,
      label: '',
    },
    SELECT_NEXT_COMP_TAB: {
      key: 'tab',
      label: 'tab',
    },
    SELECT_PREV_COMP: {
      key: `${SPECIAL_KEY_VALUE} + ${SHIFT_KEY_VALUE} + m`,
      label: '',
    },
    SELECT_PREV_COMP_TAB: {
      key: `${SHIFT_KEY_VALUE} + tab`,
      label: `${SHIFT_KEY_LABEL} + tab`,
    },
    CLOSE_PANELS: {
      key: 'esc',
      label: '',
    },
    MOVE_RIGHT: {
      key: 'right',
      label: '',
    },
    SHIFT_RIGHT: {
      key: `${SHIFT_KEY_VALUE} + right`,
      label: '',
    },
    MOVE_LEFT: {
      key: 'left',
      label: '',
    },
    SHIFT_LEFT: {
      key: `${SHIFT_KEY_VALUE} + left`,
      label: '',
    },
    MOVE_UP: {
      key: 'up',
      label: '',
    },
    SHIFT_UP: {
      key: `${SHIFT_KEY_VALUE} + up`,
      label: '',
    },
    MOVE_DOWN: {
      key: 'down',
      label: '',
    },
    SHIFT_DOWN: {
      key: `${SHIFT_KEY_VALUE} + down`,
      label: '',
    },

    SEND_FORWARD: {
      key: `${SPECIAL_KEY_VALUE} + up`,
      label: `${SPECIAL_KEY_LABEL} + ↑`,
    },
    SEND_TO_FRONT: {
      key: `${SPECIAL_KEY_VALUE} + ${SHIFT_KEY_VALUE} + up`,
      label: `${SPECIAL_KEY_LABEL} + ${SHIFT_KEY_LABEL} + ↑`,
    },
    SEND_BACKWARD: {
      key: `${SPECIAL_KEY_VALUE} + down`,
      label: `${SPECIAL_KEY_LABEL} + ↓`,
    },
    SEND_TO_BACK: {
      key: `${SPECIAL_KEY_VALUE} + ${SHIFT_KEY_VALUE} + down`,
      label: `${SPECIAL_KEY_LABEL} + ${SHIFT_KEY_LABEL} + ↓`,
    },
    TOGGLE_MOBILE: {
      key: `${SPECIAL_KEY_VALUE} + j`,
      label: `${SPECIAL_KEY_LABEL} + j`,
    },
    TOGGLE_PREVIEW: {
      key: `${SPECIAL_KEY_VALUE} + p`,
      label: `${SPECIAL_KEY_LABEL} + P`,
    },
    PUBLISH_RC: {
      key: `${SHIFT_KEY_VALUE} + ${ALT_SPECIAL_KEY_VALUE} +  t`,
      label: `${SHIFT_KEY_VALUE} + ${ALT_SPECIAL_KEY_VALUE} + T`,
    },
    PUBLISH_SITE: {
      key: `${SPECIAL_KEY_VALUE} + ${SHIFT_KEY_VALUE} + p`,
      label: `${SPECIAL_KEY_LABEL} + ${SHIFT_KEY_LABEL} + P`,
    },

    HIDE_TOOLS: {
      key: `${SHIFT_KEY_VALUE} + f`,
      label: `${SHIFT_KEY_LABEL} + F`,
    },
    TOGGLE_RULERS: {
      key: `${SHIFT_KEY_VALUE} + r`,
      label: `${SHIFT_KEY_LABEL} + R`,
    },
    PLATFORM_APP_CONFIGURATION: {
      key: `${SPECIAL_KEY_VALUE}+ ${SHIFT_KEY_VALUE} + e`,
      label: `${SPECIAL_KEY_LABEL}+ shift + e`,
    },
    ADD_APPLICATIVE_MODE: {
      key: `${SPECIAL_KEY_VALUE}+ ${SHIFT_KEY_VALUE} + a`,
      label: `${SPECIAL_KEY_LABEL}+ ${SHIFT_KEY_LABEL} + e`,
    },
    OPEN_QUICK_NAVIGATION: {
      key: '/',
      value: '/',
    },
    TOGGLE_TOOLBAR: {
      key: `${SHIFT_KEY_VALUE} + t`,
      label: `${SHIFT_KEY_LABEL} + T`,
    },
    OPEN_HELP_CENTER: {
      key: `${SHIFT_KEY_VALUE} + /`,
      label: '?',
    },
    NEW_PAGE: {
      key: `${ALT_SPECIAL_KEY_VALUE} + n`,
      label: `${ALT_SPECIAL_KEY_LABEL} + N`,
    },
    NEW_BLANK_SECTION: {
      key: `${ALT_SPECIAL_KEY_VALUE} + b`,
      label: `${ALT_SPECIAL_KEY_LABEL} + B`,
    },
    OPEN_SELECTED_COMP_SETTINGS_PANEL: {
      key: `${SHIFT_KEY_VALUE} + 1`,
      label: `${SHIFT_KEY_LABEL} + 1`,
    },
    OPEN_SELECTED_COMP_LAYOUT_PANEL: {
      key: `${SHIFT_KEY_VALUE} + 2`,
      label: `${SHIFT_KEY_LABEL} + 2`,
    },
    OPEN_SELECTED_COMP_DESIGN_PANEL: {
      key: `${SHIFT_KEY_VALUE} + 3`,
      label: `${SHIFT_KEY_LABEL} + 3`,
    },
    TRIGGER_SELECTED_COP_MAIN_ACTION: {
      key: 'enter',
      label: 'enter',
    },
    TOGGLE_SEARCH_PANEL: {
      key: `${SPECIAL_KEY_VALUE} + /`,
      label: `${SPECIAL_KEY_VALUE} + /`,
    },
    TOGGLE_DEVELOPER_TOOLBAR: {
      key: `${SPECIAL_KEY_VALUE} + e`,
      label: `${SPECIAL_KEY_LABEL} + E`,
    },
  },
  preview: {
    EXIT_PREVIEW: {
      key: 'esc',
      label: '',
    },
    TOGGLE_MOBILE: {
      key: `${SPECIAL_KEY_VALUE} + j`,
      label: `${SPECIAL_KEY_LABEL} + j`,
    },
    TOGGLE_PREVIEW: {
      key: `${SPECIAL_KEY_VALUE} + p`,
      label: `${SPECIAL_KEY_LABEL} + P`,
    },
    PUBLISH_SITE: {
      key: `${SPECIAL_KEY_VALUE} + ${SHIFT_KEY_VALUE} + p`,
      label: `${SPECIAL_KEY_LABEL} + ${SHIFT_KEY_LABEL} + P`,
    },
  },
  zoomMode: {
    EXIT_ZOOM_MODE: {
      key: 'esc',
      label: '',
    },
  },
  topBar: {
    CLOSE_DROP_PANEL: {
      key: 'esc',
      label: '',
    },
    AUTOSAVE_ON_OFF: {
      key: `${SPECIAL_KEY_VALUE} + ${ALT_SPECIAL_KEY_VALUE} + a`,
      label: `${SPECIAL_KEY_LABEL} + ${ALT_SPECIAL_KEY_VALUE} + A`,
    },
  },
  imageCrop: {
    APPLY: {
      key: 'enter',
      label: 'Enter',
    },
    CANCEL: {
      key: 'esc',
      label: 'Esc',
    },
  },
  interactions: {
    EXIT_INTERACTION: {
      key: 'esc',
      label: '',
    },
    DELETE: {
      key: ['del', 'backspace'].join(','),
      label: '',
    },
  },
  pagesPanel: {
    NEW_FOLDER: {
      key: '',
      label: '',
      //Shortcut would be added in the different task
      // key: `${SPECIAL_KEY_VALUE} + ${SHIFT_KEY_VALUE} + n`,
      // label: `${SPECIAL_KEY_LABEL} + ${SHIFT_KEY_LABEL} + n`,
    },
  },
};
