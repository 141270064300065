import { ErrorReporter } from '@wix/editor-error-reporter';

import type { EditorAPI } from '@/editorAPI';
import type { Scope } from '@/sections';

type BeforePageNavigateHook = Parameters<
  GetFieldType<EditorAPI, 'pages.hooks.beforePageNavigate.tap'>
>[0];

export const createBeforePageNavigateEnforcement = (
  scope: Scope,
): BeforePageNavigateHook => {
  const hook: BeforePageNavigateHook = async ({ pageId }) => {
    const { editorAPI } = scope;
    try {
      if (!pageId) return;
      const pageRef = editorAPI.pages.getReference(pageId);
      if (!pageRef) return;

      await scope.enforceSectionOnPageService.run(pageRef);
    } catch (error: MaybeError) {
      error.name = `[SectionsBeforeNavigateEnforcement]: ${error.name}`;
      ErrorReporter.captureException(error);
    }
  };

  return hook;
};
