/* eslint-disable you-dont-need-lodash-underscore/find */
import _ from 'lodash';
import { createThemedAction } from '../utils/themedAction';
import type { ThemedActionCreationData } from '../utils/textAction';
import type { FontOption } from 'types/documentServices';

export type FontWithKey = FontOption['fonts'][0] & { key: string };
export type FontsMap = Record<string, FontWithKey>;

const createFontFamilyActionCreationData = (
  fontMapping: FontsMap,
): Readonly<ThemedActionCreationData<string | undefined>> => ({
  execCommand: (textManager, fontKey) => {
    if (fontKey === undefined) {
      textManager.commands.fontFamily(undefined);
    } else {
      textManager.commands.fontFamily(fontKey);
    }
  },
  getWixRichTextCommandState: (textManager) => {
    const fontFamily = textManager.getCommandsStates().fontFamily;
    return fontFamily && _.find(fontMapping, { fontFamily })?.key;
  },
  execRevertCommand: (textManager) =>
    textManager.commands.fontFamily(undefined),
  createUpdatedStyleProperties: (fontKey) => ({
    fontFamily: _.find(fontMapping, { fontFamily: fontKey })?.cssFontFamily,
  }),
  getPropertyValue: (styleProperties) => {
    if (styleProperties && styleProperties.fontFamily) {
      const font = fontMapping[styleProperties && styleProperties.fontFamily];
      return font?.key;
    }
  },
  getThemeValue: (theme) => {
    if (theme && theme.family) {
      const font = fontMapping[theme.fontWithFallbacks];
      return font?.key;
    }
  },
});

export const createFontFamilyAction = (fontMapping: FontsMap) =>
  createThemedAction(createFontFamilyActionCreationData(fontMapping));
