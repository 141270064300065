:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._actionBar_og2km_7 {
  width: 36px;
  border-radius: 4px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 2px 10px 0 rgba(0, 0, 0, 0.08), 0 1px 20px 0 rgba(0, 0, 0, 0.08);
  padding: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden; }

._legacy_og2km_16,
._legacySoap_og2km_17 {
  --group-border-color: #dfe5eb;
  background: #ffffff; }
  ._legacy_og2km_16 ._action_og2km_7,
  ._legacySoap_og2km_17 ._action_og2km_7 {
    padding-top: 0; }

._primary_og2km_24 {
  background: #6f56f9;
  --group-border-color: #dfe5eb; }

._secondary_og2km_28 {
  background: #ffffff;
  width: 34px;
  border: 1px solid #6f56f9;
  --group-border-color: rgba(111, 86, 249, 0.5); }

._primarySoap_og2km_34 {
  background: #fe620f;
  --group-border-color: #fee8dc; }

._secondarySoap_og2km_38 {
  background: #ffffff;
  width: 34px;
  border: 1px solid #fe620f;
  --group-border-color: #fee8dc; }

._notRequired_og2km_44 {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  overflow: hidden;
  flex-shrink: 1; }

._group_og2km_51 {
  width: 100%; }
  ._group_og2km_51:empty {
    display: none; }

._notRequired_og2km_44 ._group_og2km_51 + ._action_og2km_7 {
  padding-top: 6px; }

._action_og2km_7 {
  padding-top: 3px;
  padding-bottom: 3px;
  position: relative; }
  ._action_og2km_7:first-child {
    padding-top: 6px; }
  ._action_og2km_7:last-child {
    padding-bottom: 7px; }
    ._action_og2km_7:last-child:after {
      content: '';
      width: 36px;
      height: 1px;
      background: var(--group-border-color);
      position: absolute;
      bottom: 0; }

._notCollapsibleGroup_og2km_75 {
  flex-shrink: 0; }
  ._notCollapsibleGroup_og2km_75 ._action_og2km_7:last-child {
    padding-bottom: 6px; }
    ._notCollapsibleGroup_og2km_75 ._action_og2km_7:last-child:after {
      content: none; }
  ._notCollapsibleGroup_og2km_75 ._action_og2km_7:first-child {
    padding-top: 6px; }
    ._notCollapsibleGroup_og2km_75 ._action_og2km_7:first-child:after {
      content: '';
      bottom: unset;
      top: -1px; }
