import React from 'react';
import { Preloader } from '@wix/wix-base-ui';
import styles from './siteCreationBottomSection.scss';
import { symbol as Symbol } from '@wix/santa-editor-symbols';

interface SiteCreationBottomSectionProps {
  isLoaderVisible?: boolean;
  actionsDisabled?: boolean;
  symbol?: string;
  mainActionLabel?: string;
  onMainActionClick?: () => void;
  mainActionDisabled?: boolean;
  secondaryActionLabel?: string;
  onSecondaryActionClick?: () => void;
  wrapperRef?: React.RefObject<HTMLDivElement>;
  hideButtons?: boolean;
}

const SiteCreationBottomSection: React.FC<SiteCreationBottomSectionProps> = ({
  wrapperRef,
  actionsDisabled,
  symbol,
  mainActionLabel,
  onMainActionClick,
  secondaryActionLabel,
  onSecondaryActionClick,
  isLoaderVisible,
  mainActionDisabled,
}) => {
  return (
    <div className={styles.siteCreationBottomSection} ref={wrapperRef}>
      {!isLoaderVisible &&
      secondaryActionLabel &&
      onSecondaryActionClick &&
      !actionsDisabled ? (
        <div
          className={styles.nonPrimaryButtons}
          onClick={onSecondaryActionClick}
          data-hook="site-creation-secondary-action"
        >
          <span>{secondaryActionLabel}</span>
        </div>
      ) : null}
      {!isLoaderVisible && mainActionLabel && onMainActionClick ? (
        <div
          className={`${styles.nextButton} ${
            actionsDisabled || mainActionDisabled ? styles.disabled : ''
          }`}
          onClick={onMainActionClick}
        >
          <span data-hook="next-button">{mainActionLabel}</span>
          {symbol && <Symbol name={symbol} />}
        </div>
      ) : null}
      {isLoaderVisible ? <Preloader className={styles.preLoader} /> : null}
    </div>
  );
};

export default SiteCreationBottomSection;
