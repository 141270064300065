import { createEntryPoint } from '@/apilib';
import { DataBindingScope, DataBindingApi } from './dataBinding';

import { DataBindingApiKey } from '@/apis';

export const DataBindingEntrypoint = createEntryPoint({
  name: 'DataBindingEntry',
  Scope: DataBindingScope,
  publicApi({ contributeApi }) {
    contributeApi(DataBindingApiKey, DataBindingApi);
  },
  async initialize() {},
});
