import React, { type ComponentType } from 'react';
import { hoc, promiseUtils, type CancelablePromise } from '@/util';
import * as UA from '@/uiAnimations';
import { loadComponentByPath } from '@/lazyComponent';

import styles from './rightPanels.scss';

const { makeCancelablePromise } = promiseUtils;

import {
  mapStateToProps,
  type OwnProps,
  type StateProps,
} from './openedRightPanelsMapper';

interface Panel {
  PanelComponent: React.ComponentType;
  panelName: string;
  panelProps: AnyFixMe;
}

interface OpenedRightPanelsProps extends OwnProps, StateProps {}

interface OpenedRightPanelsState extends Panel {}

const emptyPanel: Panel = {
  PanelComponent: null,
  panelName: '',
  panelProps: undefined,
};

export class OpenedRightPanels extends React.Component<
  OpenedRightPanelsProps,
  OpenedRightPanelsState
> {
  state: OpenedRightPanelsState = emptyPanel;

  componentDidMount() {
    this.loadPanelComponent();
  }

  componentDidUpdate(prevProps: OpenedRightPanelsProps) {
    const { openedPanelName } = this.props;

    if (prevProps.openedPanelName !== openedPanelName) {
      this.resetPanelComponent();

      this.loadPanelComponent();
    }
  }

  componentWillUnmount() {
    this._loadComponentPromise?.cancel();
  }

  _loadComponentPromise: CancelablePromise<ComponentType> | undefined;

  loadPanelComponent() {
    const { openedPanelName, openedPanelProps } = this.props;

    if (!openedPanelName) {
      return;
    }

    this._loadComponentPromise = makeCancelablePromise(
      loadComponentByPath(openedPanelName),
    );

    this._loadComponentPromise.promise
      .then((PanelComponent) => {
        this.setPanelComponent({
          PanelComponent,
          panelName: openedPanelName,
          panelProps: openedPanelProps,
        });
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('LazyRightPanels', error);
      });
  }

  setPanelComponent(panel: Panel) {
    this.setState(panel);
  }

  resetPanelComponent() {
    this.setPanelComponent(emptyPanel);
  }

  render() {
    const { PanelComponent, panelName, panelProps } = this.state;

    return (
      <div className={styles.rightPanels}>
        <UA.contentSlide direction="right" duration={0.5}>
          {PanelComponent ? (
            <PanelComponent key={panelName} {...panelProps} />
          ) : null}
        </UA.contentSlide>
      </div>
    );
  }
}

export default hoc.connect(
  hoc.STORES.EDITOR_API,
  mapStateToProps,
)(OpenedRightPanels) as React.ComponentType<OwnProps>;
