import React, { useEffect } from 'react';
import * as panels from '@/panels';
import { Illustration, Composites, RichText } from '@wix/wix-base-ui';
import { symbol as Symbol } from '@wix/santa-editor-symbols';
import { translate } from '@/i18n';
import { fedopsLogger } from '@/util';
import { TRANSLATIONS } from './utils/translations';

export interface IDeleteMenuSetPanelOwnProps {
  menuNameToDelete: string;
  menuNameToSubstituteWith: string;

  onConfirm(): void;
  onCancel?(): void;
}

interface IDeleteMenuSetPanelProps extends IDeleteMenuSetPanelOwnProps {
  panelName: string; // it's provided on panel opening
}

export const DeleteMenuSetPanel: React.FC<IDeleteMenuSetPanelProps> = ({
  panelName,
  onConfirm,
  onCancel,
  menuNameToDelete,
  menuNameToSubstituteWith,
}) => {
  useEffect(() => {
    fedopsLogger.interactionEnded(
      fedopsLogger.INTERACTIONS.MANAGE_MENU.OPEN_DELETE_MENU_SET_PANEL,
    );
  }, []);

  return (
    <panels.frames.OldMessagePanelFrame
      panelName={panelName}
      panelTitle={translate(TRANSLATIONS.PANEL_TITLE, {
        menu_name_to_delete: menuNameToDelete,
      })}
      confirmLabel={TRANSLATIONS.CONFIRM_LABEL}
      cancelLabel={TRANSLATIONS.CANCEL_LABEL}
      onConfirm={onConfirm}
      onCancel={onCancel}
      type="danger"
    >
      <Composites.RichTextWithIllustration>
        <Illustration>
          <Symbol name="delete_trash_illustration" />
        </Illustration>
        <Composites.RichText>
          <RichText type="T01">
            <p>
              {translate(TRANSLATIONS.DESCRIPTION_1, {
                menu_name_to_delete: menuNameToDelete,
                menu_name_to_substitute: menuNameToSubstituteWith,
              })}
            </p>
            <p>{translate(TRANSLATIONS.DESCRIPTION_2)}</p>
          </RichText>
        </Composites.RichText>
      </Composites.RichTextWithIllustration>
    </panels.frames.OldMessagePanelFrame>
  );
};
