export const CURRENT_INNER_ROUTE = 'CURRENT_INNER_ROUTE';
export const PAGINATION_RANGE = 100;
export enum LINK_TYPES {
  DynamicPageLink = 'DynamicPageLink',
  PageLink = 'PageLink',
  AnchorLink = 'AnchorLink',
  ExternalLink = 'ExternalLink',
  EmailLink = 'EmailLink',
  PhoneLink = 'PhoneLink',
  WhatsAppLink = 'WhatsAppLink',
  DocumentLink = 'DocumentLink',
  PopupLink = 'PopupLink',
  AddressLink = 'AddressLink',
  EdgeAnchorLinks = 'EdgeAnchorLinks',
  NoLink = 'NoLink',
  LoginToWixLink = 'LoginToWixLink',
}

export const REL_ATTR_HELP_ID = '8c2d7f29-1a14-4d9c-8772-3196195eefbc';
