import { fedopsLogger } from '@/util';
import { MIGRATION_FLOWS } from '../constants';

const migrationFlowsFedops = {
  [MIGRATION_FLOWS.SECTIONS_MIGRATION]: fedopsLogger.mapInteraction(
    fedopsLogger.INTERACTIONS.SITE_WIDTH_MIGRATION.SECTIONS_MIGRATION_FLOW,
  ),
  [MIGRATION_FLOWS.SITE_CREATION]: fedopsLogger.mapInteraction(
    fedopsLogger.INTERACTIONS.SITE_WIDTH_MIGRATION.SITE_CREATION_FLOW,
  ),
};

export { migrationFlowsFedops };
