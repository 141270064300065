import React, { type FunctionComponent } from 'react';

import TopBarFlagButton from '../topBarFlagButton/topBarFlagButton';
import TooltipContent from '../tooltipContent';
import DropDown from '../dropDown/dropDown';
import LanguagePanel from '../dropPanel/languagePanel';

const TOOLTIP_DATA = {
  title: 'SiteLanguages_Tooltip_Title',
  text: 'SiteLanguages_Tooltip_Text',
};

interface LanguageSelectProps {
  isOpened: boolean;
  delayedFadeOut?: boolean;
  countryCode: string;
  languageCode: string;
  shouldCurrentLanguageInvisible: boolean;
  shouldHideLanguageIcon: boolean;
  onButtonClick: () => void;
  onPanelMouseLeave?: () => void;
  onPanelMouseEnter?: () => void;
  closePanelHandle?: () => void;
}

const LanguageSelect: FunctionComponent<LanguageSelectProps> = (props) => {
  const tooltipContent = props.isOpened ? null : (
    <TooltipContent tooltipData={TOOLTIP_DATA} />
  );
  const dropDownContent = (
    <LanguagePanel
      biOrigin="top_bar"
      key="languageDropPanel"
      closeHandle={props.closePanelHandle}
      onMouseLeave={props.onPanelMouseLeave}
    />
  );

  return (
    <DropDown
      isOpen={props.isOpened}
      panelContent={dropDownContent}
      onPanelMouseEnter={props.onPanelMouseEnter}
      onPanelMouseLeave={props.onPanelMouseLeave}
      panelClassName={'drop-panel-language-bubble'}
      delayedFadeOut={props.delayedFadeOut}
    >
      <TopBarFlagButton
        className="top-bar-button-language"
        automationId="top-bar-button-language"
        onClick={props.onButtonClick}
        countryCode={props.countryCode}
        languageCode={props.languageCode}
        toolTip={tooltipContent}
        shouldCurrentLanguageInvisible={props.shouldCurrentLanguageInvisible}
        shouldHideLanguageIcon={props.shouldHideLanguageIcon}
      />
    </DropDown>
  );
};

export default LanguageSelect;
