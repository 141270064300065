:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._switch-layout-panel_ln44b_7 {
  height: 100%;
  background-color: #fff;
  overflow-y: scroll; }
  ._switch-layout-panel_ln44b_7 ._switch-layout-header_ln44b_11 {
    display: flex;
    justify-content: space-between;
    padding: 18px 0 6px;
    margin: 0 24px 12px; }
  ._switch-layout-panel_ln44b_7 ._result-thumbnail_ln44b_16 {
    border: 1px solid #dfe5eb;
    box-shadow: 0px 0px 0px 3px transparent;
    border-radius: 2px;
    position: relative;
    margin: 0px 24px 24px;
    transition: border 250ms ease, box-shadow 250ms ease;
    cursor: pointer; }
    ._switch-layout-panel_ln44b_7 ._result-thumbnail_ln44b_16 ._selected-check_ln44b_24 {
      width: 18px;
      height: 18px;
      border-radius: 18px;
      background-color: #116dff;
      border: 2px solid #fff;
      padding: 0px;
      position: absolute;
      top: -10px;
      right: -10px;
      text-align: center;
      z-index: 1;
      opacity: 0;
      transition: opacity 250ms ease;
      pointer-events: none; }
    ._switch-layout-panel_ln44b_7 ._result-thumbnail_ln44b_16 ._result-preview_ln44b_39 {
      pointer-events: none;
      overflow: hidden; }
      ._switch-layout-panel_ln44b_7 ._result-thumbnail_ln44b_16 ._result-preview_ln44b_39 ._preset-preview-wrapper_ln44b_42 * {
        box-sizing: border-box; }
    ._switch-layout-panel_ln44b_7 ._result-thumbnail_ln44b_16 ._snap-shot-preview_ln44b_44 {
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      transform-origin: 0 0; }
    ._switch-layout-panel_ln44b_7 ._result-thumbnail_ln44b_16 ._previewer-preview_ln44b_47 {
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      transform-origin: 0 0; }
    ._switch-layout-panel_ln44b_7 ._result-thumbnail_ln44b_16:hover {
      border: 1px solid #fff;
      box-shadow: 0px 0px 0px 1px #fff, 0px 0px 0px 3px #80b1ff; }
    ._switch-layout-panel_ln44b_7 ._result-thumbnail_ln44b_16._is-selected_ln44b_53 {
      border: 1px solid #fff;
      border-radius: 2px;
      box-shadow: 0px 0px 0px 1px #fff, 0px 0px 0px 3px #5999ff;
      background-color: transparent;
      z-index: 2;
      cursor: default; }
      ._switch-layout-panel_ln44b_7 ._result-thumbnail_ln44b_16._is-selected_ln44b_53 ._selected-check_ln44b_24 {
        opacity: 1; }
      ._switch-layout-panel_ln44b_7 ._result-thumbnail_ln44b_16._is-selected_ln44b_53:hover {
        border: 1px solid #fff;
        box-shadow: 0px 0px 0px 1px #fff, 0px 0px 0px 3px #5999ff; }
    ._switch-layout-panel_ln44b_7 ._result-thumbnail_ln44b_16._is-selecting_ln44b_65 {
      cursor: default; }
      ._switch-layout-panel_ln44b_7 ._result-thumbnail_ln44b_16._is-selecting_ln44b_65 ._selected-check_ln44b_24 {
        opacity: 0; }
    ._switch-layout-panel_ln44b_7 ._result-thumbnail_ln44b_16:last-child {
      margin-bottom: 18px; }
  ._switch-layout-panel_ln44b_7 ._preloader_ln44b_71 {
    position: relative;
    height: auto;
    top: 185px; }
