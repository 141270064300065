import React from 'react';
import { RichText } from '@wix/wix-base-ui';

import styles from './linksList.scss';

interface LinkItem {
  text: string;
}

interface LinksListProps {
  items: LinkItem[];
  symbol: React.ReactNode;
  navigateToLink: (item: LinkItem) => void;
}

const LinksList: React.FunctionComponent<LinksListProps> = (props) => {
  return (
    <div className={styles.linkslist}>
      {props.items.map((item, itemIndex) => (
        <div key={`item${itemIndex}`} className={styles.linksListItem}>
          <div
            onClick={() => props.navigateToLink(item)}
            className={styles.symbolContainer}
          >
            {props.symbol}
          </div>
          <div className={styles.text}>
            <RichText type="T02">
              <a onClick={() => props.navigateToLink(item)}>{item.text}</a>
            </RichText>
          </div>
        </div>
      ))}
    </div>
  );
};

export default LinksList;
