import React, { Component, type ComponentType } from 'react';

const DROP_PANEL_DELAY = 500;

interface WithDelayedFadeOutBehaviorProps {
  openedDropPanel: string;
  openDropPanel: (panelName: string) => void;
  closeDropPanel: (panelName?: string) => void;
}

export interface ComponentWithDelayedFadeOutBehaviorProps {
  delayedFadeOut: boolean;
  openDropPanel: (panelName: string) => void;
  closeDropPanel: (immediate?: boolean) => void;
}

const withDelayedFadeOutBehavior = <P extends WithDelayedFadeOutBehaviorProps>(
  WrappedComponent: ComponentType<P & ComponentWithDelayedFadeOutBehaviorProps>,
) => {
  class WithDelayedFadeOutBehavior extends Component<WithDelayedFadeOutBehaviorProps> {
    state = { delayedFadeOut: false };

    componentWillUnmount() {
      this.stopDropPanelTimer();
    }

    private dropPanelTimer: number;

    stopDropPanelTimer = () => {
      window.clearTimeout(this.dropPanelTimer);
    };

    openDropPanel = (nextOpenedDropPanel: string) => {
      this.stopDropPanelTimer();

      if (this.props.openedDropPanel !== nextOpenedDropPanel) {
        this.setState({ delayedFadeOut: false });
        this.props.openDropPanel(nextOpenedDropPanel);
      }
    };

    closeDropPanel = (immediately?: boolean) => {
      this.stopDropPanelTimer();

      if (immediately) {
        this.setState({ delayedFadeOut: false });
        this.props.closeDropPanel();
        return;
      }

      this.setState({ delayedFadeOut: true });
      this.dropPanelTimer = window.setTimeout(
        () => this.props.closeDropPanel(),
        DROP_PANEL_DELAY,
      );
    };

    render() {
      const { props } = this;

      return React.createElement(
        WrappedComponent,
        Object.assign({}, props as P, {
          delayedFadeOut: this.state.delayedFadeOut,
          closeDropPanel: this.closeDropPanel,
          openDropPanel: this.openDropPanel,
        }),
      );
    }
  }

  return WithDelayedFadeOutBehavior;
};

export default withDelayedFadeOutBehavior;
