export const origin = 'data_panel';
export const contentManagerButtonOrigin = 'content_manager_6th_btn';
export const contentManagerMainTab = 'content_manager_6th_btn_main_tab';
export const EVENTS = {
  componentConnectButtonClicked: 'componentConnectButtonClicked',
  controllerSettingsButtonClicked: 'controllerSettingsButtonClicked',
  databasePanelCollectionSelected: 'databasePanelCollectionSelected',
  addDynamicPageClicked: 'addDynamicPageClicked',
  createCollectionButtonClicked: 'openCreateNewCollection',
  addExternalDbDriverClicked: 'addExternalDbDriverClicked',
  editExternalDbDriverClicked: 'editExternalDbDriverClicked',
  removeExternalDbDriverClicked: 'removeExternalDbDriverClicked',
  enableDataModeClicked: 'enableDataModeClicked',
  openAddPreset: 'openAddPreset',
  openContentManagerCollectionsList: 'openContentManagerCollectionsList',
  openContentManagerAdvancedSettings: 'openContentManagerAdvancedSettings',
  openNewDynamicPagePanel: 'openNewDynamicPagePanel',
  openConnectionsListClicked: 'openConnectionsListClicked',
};
