import type { EditorAPI } from '@/editorAPI';
import { DATA_BINDING } from '@wix/app-definition-ids';

export interface NotifyApplicationParams {
  eventType: string;
  eventPayload: {
    pageRef?: any;
    origin?: string;
  };
}

const getAppData = (editorAPI: EditorAPI, appDefinitionId: string) => {
  return editorAPI.dsRead.platform.getAppDataByAppDefId(appDefinitionId);
};

export const notifyApplication = (
  editorAPI: EditorAPI,
  appDefinitionId: string,
  params: NotifyApplicationParams,
) => {
  const { applicationId } = getAppData(editorAPI, appDefinitionId);

  editorAPI.dsActions.platform.notifyApplication(applicationId, params);
};

export const notifyDataBindingApplication = (
  editorAPI: EditorAPI,
  params: NotifyApplicationParams,
) => {
  return notifyApplication(editorAPI, DATA_BINDING, params);
};
