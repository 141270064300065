import React, { useCallback, useState } from 'react';
import type { WidgetDesignPreset } from '@wix/platform-editor-sdk';
import * as util from '@/util';
import styles from './presetSnapshot.scss';

const fallback = util.media.getMediaUrl(
  'advancedStylePanel/widgetDesign/presetPlaceholder.svg',
);

export const PresetSnapshot = (props: { preset: WidgetDesignPreset }) => {
  const [hasError, setError] = useState<boolean>(false);

  const handleError = useCallback(() => {
    setError(true);
  }, [setError]);

  const imgSrc = props.preset.src && !hasError ? props.preset.src : fallback;

  return (
    <div className={styles.presetSnapshotWarpper}>
      <img
        src={imgSrc}
        onError={handleError}
        alt={props.preset.id}
        className={styles.presetSnapshotImage}
      />
    </div>
  );
};
