import React from 'react';
import { translate } from '@/i18n';

export default class extends React.Component {
  static displayName = 'tpaThankYouPageSeo';

  render() {
    return (
      <div className="seo-google tab-inner empty">
        <p>{translate('Pages_Store_Cart_SEO_Text')}</p>
        <p>
          <span>{`${translate('Pages_Store_Thankyou_Tracking_Text')} `}</span>
          <a
            href="https://support.wix.com/en/article/tracking-sales-in-wix-stores-using-your-thank-you-page-url"
            target="_blank"
          >
            {translate('Pages_Store_Thankyou_Tracking_Link')}
          </a>
        </p>
      </div>
    );
  }
}
