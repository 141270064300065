import React from 'react';
import { mapDispatchToProps, mapStateToProps } from './TextMask.mapper';
import { TextMaskDesignPanelClassic } from '@wix/text-mask-panels';
import * as compPanelInfra from '@/compPanelInfra';
import { hoc, panelUtils } from '@/util';
import type { TextMaskDesignPanelProps } from './TextMask.types';
import { helpId } from './TextMask.constants';
import { useTranslation } from 'react-i18next';

export const TextMaskDesignPanel = (props: TextMaskDesignPanelProps) => {
  const [translate] = useTranslation();

  return (
    <compPanelInfra.compPanelFrame
      title={'TextMask.Design.Panel.Title'}
      panelName={'Text Mask Name'}
      helpId={helpId}
      id="TextMaskDesignPanel"
      {...panelUtils.getFrameProps(props)}
    >
      <TextMaskDesignPanelClassic {...props} translate={translate} />
    </compPanelInfra.compPanelFrame>
  );
};

export default hoc.connect(
  hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(TextMaskDesignPanel);
