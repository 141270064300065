const addPanelDataConsts = require("@/addPanelDataConsts")
const {languages} = require("@/util")

const preset1 = require("./presets/item1")
const preset2 = require("./presets/item2")
const preset3 = require("./presets/item3")
const preset4 = require("./presets/item4")
const preset5 = require("./presets/item5")
const preset6 = require("./presets/item6")
const preset7 = require("./presets/item7")
const preset8 = require("./presets/item8")
const preset9 = require("./presets/item9")
const preset10 = require("./presets/item10")
const preset11 = require("./presets/item11")

const languageCode = languages.getLanguageCode()
const stylableHorizontalMenuImageUrl = `addPanelData/sections/stylableHorizontalMenuSections/stylableHorizontalMenuSection_${languageCode}/stylableHorizontalMenuSection_${languageCode}.v6.jpg`
const stylableHorizontalMenuImageUrlRetina = `addPanelData/sections/stylableHorizontalMenuSections/stylableHorizontalMenuSection_${languageCode}/stylableHorizontalMenuSection_${languageCode}-@2.v6.jpg`

module.exports = {
    type: addPanelDataConsts.SECTIONS_TYPES.PRESET,
    hide: false,
    title: "add_section_label_Hmenu",
    subNavigationHide: false,
    subNavigationTitle: "add_section_label_Hmenu",
    presetTitle: "add_section_label_Hmenu",
    automationId: "add-panel-section-stylableHorizontalMenuSection",
    tooltipTitle: "add_section_label_Hmenu",
    showSectionHeader: true,
    additionalBehaviours: {
        labelMode: addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
        infoIcon: addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
        hoverImageAction: addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
        scaleProportion: addPanelDataConsts.DEFAULT_SCALE_FOR_PRESET,
        iconEnabledForComps: {}
    },
    props: {
        image: stylableHorizontalMenuImageUrl,
        retinaImage: {
            src: stylableHorizontalMenuImageUrlRetina,
            dimensions: {
                width: 648,
                height: 1400
            }
        },
        imageHover: null,
        items: [
            {
                id: "Stylable_StylableHorizontalMenu_1",
                structure: preset1,
                preset: {
                    rect: {
                        width: 324,
                        height: 64,
                        x: 0,
                        y: 4
                    },
                    tags: null
                }
            },
            {
                id: "Stylable_StylableHorizontalMenu_2",
                structure: preset2,
                preset: {
                    rect: {
                        width: 324,
                        height: 64,
                        x: 0,
                        y: 68
                    },
                    tags: null
                }
            },
            {
                id: "Stylable_StylableHorizontalMenu_3",
                structure: preset3,
                preset: {
                    rect: {
                        width: 324,
                        height: 64,
                        x: 0,
                        y: 132
                    },
                    tags: null
                }
            },
            {
                id: "Stylable_StylableHorizontalMenu_4",
                structure: preset4,
                preset: {
                    rect: {
                        width: 324,
                        height: 62,
                        x: 0,
                        y: 196
                    },
                    tags: null
                }
            },
            {
                id: "Stylable_StylableHorizontalMenu_5",
                structure: preset5,
                preset: {
                    rect: {
                        width: 324,
                        height: 58,
                        x: 0,
                        y: 258
                    },
                    tags: null
                }
            },
            {
                id: "Stylable_StylableHorizontalMenu_6",
                structure: preset6,
                preset: {
                    rect: {
                        width: 324,
                        height: 64,
                        x: 0,
                        y: 316
                    },
                    tags: null
                }
            },
            {
                id: "Stylable_StylableHorizontalMenu_7",
                structure: preset7,
                preset: {
                    rect: {
                        width: 324,
                        height: 66,
                        x: 0,
                        y: 380
                    },
                    tags: null
                }
            },
            {
                id: "Stylable_StylableHorizontalMenu_8",
                structure: preset8,
                preset: {
                    rect: {
                        width: 324,
                        height: 64,
                        x: 0,
                        y: 446
                    },
                    tags: null
                }
            },
            {
                id: "Stylable_StylableHorizontalMenu_9",
                structure: preset9,
                preset: {
                    rect: {
                        width: 324,
                        height: 58,
                        x: 0,
                        y: 510
                    },
                    tags: null
                }
            },
            {
                id: "Stylable_StylableHorizontalMenu_10",
                structure: preset10,
                preset: {
                    rect: {
                        width: 324,
                        height: 60,
                        x: 0,
                        y: 568
                    },
                    tags: null
                }
            },
            {
                id: "Stylable_StylableHorizontalMenu_11",
                structure: preset11,
                preset: {
                    rect: {
                        width: 324,
                        height: 72,
                        x: 0,
                        y: 628
                    },
                    tags: null
                }
            }
        ],
        compTypes: ["wixui.StylableHorizontalMenu"]
    },
    help: {
        hide: false,
        text: "add_section_info_custom"
    }
}
