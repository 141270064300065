import React from 'react';
import styles from './appReadme.scss';
import markDownStyles from './markdown.scss';
import { useSelectedApp } from '../selectedAppContext/selectedAppContext';
import { BoldText } from '../helperComponents/helperComponents';
import ReactMarkdown from 'react-markdown';

export const AppReadme: React.FC = () => {
  const { readme } = useSelectedApp().selectedApp;

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <BoldText>
          blocks-private-apps.AppDiscovery_App_Details_Readme_Title
        </BoldText>
      </div>
      <div className={styles.content}>
        <ReactMarkdown className={markDownStyles.markdown}>
          {readme}
        </ReactMarkdown>
      </div>
    </div>
  );
};
