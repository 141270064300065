'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')
const experiment = require('experiment')
const i18n = require('@/i18n')

module.exports = {
    'type': addPanelDataConsts.SECTIONS_TYPES.PRESET,
    'hide': false,
    'title': 'add_section_label_pinterest',
    'subNavigationTitle': 'add_section_label_pinterest',
    'presetTitle': 'add_section_label_pinterest',
    'tooltipTitle': 'add_section_label_pinterest',
    "automationId": "add-panel-section-pinterestSection",
    'subNavigationHide': false,
    'showSectionHeader': true,
    'additionalBehaviours': {
        'labelMode': addPanelDataConsts.LABEL_BEHAVIOUR.HOVER,
        'infoIcon': addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
        'hoverImageAction': addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
        'iconEnabledForComps': {}
    },
    'props': experiment.isOpen('se_newSocialCategoryAddPanel') ? {
        "image": "addPanelData/sections/pinterestSection_en/pinterestSection_en.png",
        "imageHover": null,
        "items": [{
            "id": "Social_Pinterest_1",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.pinitpinwidget.viewer.skins.PinItPinWidgetSkin",
                "layout": {
                    "width": 236,
                    "height": 525,
                    "x": 209,
                    "y": 172,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.pinitpinwidget.viewer.PinItPinWidget",
                "data": {
                    "type": "PinItPinWidget",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "pinId": "https://www.pinterest.com/pin/204421270569913516/"
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.pinitpinwidget.viewer.PinItPinWidget",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.pinitpinwidget.viewer.skins.PinItPinWidgetSkin"
                },
                "activeModes": {},
                "id": "comp-jor9phdr"
            },
            "preset": {
                "rect": {"width": 324, "height": 428, "x": 0, "y": 0},
                "label": "add_preset_Social_Pinterest_3",
                "tags": null
            }
        }, {
            "id": "Social_Pinterest_2",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.common.components.pinterestpinit.viewer.skins.PinterestPinItSkin",
                "layout": {
                    "width": 50,
                    "height": 20,
                    "x": 49,
                    "y": 911,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.pinterestpinit.viewer.PinterestPinIt",
                "data": {
                    "type": "PinterestPinIt",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "uri": "",
                    "description": i18n.translate("Pinterest_Pin_It_Settings_Pin_Preview_Message"),
                    "urlFormat": "slash",
                    "isHttpsEnabled": true
                },
                "props": {
                    "type": "PinterestPinItProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "size": "small",
                    "color": "gray",
                    "counterPosition": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.common.components.pinterestpinit.viewer.PinterestPinIt",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.common.components.pinterestpinit.viewer.skins.PinterestPinItSkin"
                },
                "activeModes": {},
                "id": "comp-jor9r2ak"
            },
            "preset": {
                "rect": {"width": 70, "height": 62, "x": 0, "y": 428},
                "label": "add_preset_Social_Pinterest_1",
                "tags": null,
                "labelClasses": "align-left"
            }
        }, {
            "id": "Social_Pinterest_3",
            "structure": {
                "type": "Component",
                "skin": "skins.viewer.pinterestfollow.PinterestFollowSkin",
                "layout": {
                    "width": 76,
                    "height": 20,
                    "x": 198,
                    "y": 911,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.PinterestFollow",
                "data": {
                    "type": "PinterestFollow",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "urlChoice": "www.pinterest.com/wixcom/",
                    "label": "Pinterest"
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "wysiwyg.viewer.components.PinterestFollow",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "skins.viewer.pinterestfollow.PinterestFollowSkin"
                },
                "activeModes": {},
                "id": "comp-jor9r95z"
            },
            "preset": {
                "rect": {"width": 98, "height": 62, "x": 70, "y": 428},
                "label": "add_preset_Social_Pinterest_2",
                "tags": null,
                "labelClasses": "align-left"
            }
        }],
        "compTypes": ["wysiwyg.common.components.pinterestpinit.viewer.PinterestPinIt", "wysiwyg.viewer.components.PinterestFollow", "wysiwyg.common.components.pinitpinwidget.viewer.PinItPinWidget"]
    } : {
        'image': 'addPanelData/sections/pinterestSection_en/pinterestSection_en.v2.png',
        'imageHover': null,
        'items': [{
            'id': 'Social_Pinterest_1',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.common.components.pinterestpinit.viewer.skins.PinterestPinItSkin',
                'layout': {
                    'width': 40,
                    'height': 20,
                    'x': 11,
                    'y': 20,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.common.components.pinterestpinit.viewer.PinterestPinIt',
                'data': {
                    'type': 'PinterestPinIt',
                    'metaData': {'isPreset': true, 'schemaVersion': '1.0', 'isHidden': false},
                    'uri': '',
                    'urlFormat': 'hashBang',
                    'description': i18n.translate('Pinterest_Pin_It_Settings_Pin_Preview_Message')
                },
                'props': {
                    'type': 'PinterestPinItProperties',
                    'metaData': {'schemaVersion': '1.0'},
                    'size': 'small',
                    'color': 'gray',
                    'counterPosition': 'none'
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'id': 'PinterestPinIt_1',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {'groups': {}, 'properties': {}, 'propertiesSource': {}},
                    'componentClassName': '',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'system',
                    'skin': 'wysiwyg.common.components.pinterestpinit.viewer.skins.PinterestPinItSkin'
                },
                'id': 'ic20a4v1'
            },
            'preset': {
                'rect': {'width': 324, 'height': 60, 'x': 0, 'y': 0},
                'label': 'add_preset_Social_Pinterest_1',
                'tags': null,
                'labelClasses': 'align-left'
            }
        }, {
            'id': 'Social_Pinterest_2',
            'structure': {
                'type': 'Component',
                'skin': 'skins.viewer.pinterestfollow.PinterestFollowSkin',
                'layout': {
                    'width': 75,
                    'height': 20,
                    'x': 11,
                    'y': 80,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.viewer.components.PinterestFollow',
                'data': {
                    'type': 'PinterestFollow',
                    'metaData': {'isPreset': true, 'schemaVersion': '1.0', 'isHidden': false},
                    'urlChoice': 'www.pinterest.com/wixcom/',
                    'label': 'Pinterest'
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'id': 'pf1',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {'groups': {}, 'properties': {}, 'propertiesSource': {}},
                    'componentClassName': '',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'system',
                    'skin': 'skins.viewer.pinterestfollow.PinterestFollowSkin'
                },
                'id': 'ic209w9i'
            },
            'preset': {
                'rect': {'width': 324, 'height': 60, 'x': 0, 'y': 60},
                'label': 'add_preset_Social_Pinterest_2',
                'tags': null,
                'labelClasses': 'align-left'
            }
        }, {
            'id': 'Social_Pinterest_3',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.common.components.pinitpinwidget.viewer.skins.PinItPinWidgetSkin',
                'layout': {
                    'width': 237,
                    'height': 377,
                    'x': 34,
                    'y': 142,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.common.components.pinitpinwidget.viewer.PinItPinWidget',
                'data': {
                    'type': 'PinItPinWidget',
                    'metaData': {'isPreset': true, 'schemaVersion': '1.0', 'isHidden': false},
                    'pinId': 'https://www.pinterest.com/pin/204421270569913516/'
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'id': 'PinItPinWidget_1',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {'groups': {}, 'properties': {}, 'propertiesSource': {}},
                    'componentClassName': '',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'system',
                    'skin': 'wysiwyg.common.components.pinitpinwidget.viewer.skins.PinItPinWidgetSkin'
                },
                'id': 'ic209l6f'
            },
            'preset': {
                'rect': {'width': 324, 'height': 523, 'x': 0, 'y': 120},
                'label': 'add_preset_Social_Pinterest_3',
                'tags': null
            }
        }],
        'compTypes': ['wysiwyg.common.components.pinterestpinit.viewer.PinterestPinIt', 'wysiwyg.viewer.components.PinterestFollow', 'wysiwyg.common.components.pinitpinwidget.viewer.PinItPinWidget']
    }
}
