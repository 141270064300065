import PageItem from './components/pageItem';
import nodeContentCommon from './components/nodeContentCommon';
import nodeContentUniversalRenderer from './components/mixedList/nodeContentUniversalRenderer';
import * as PageMenuItemHelpers from './utils/pageMenuItemHelpers';
import * as pageUtils from './utils/pageUtils';
import * as quickActionsUtils from './utils/quickActionsUtils';
import constants from './utils/constants';
import quickActionRenameMixin from './components/quickActionRenameMixin';
import pageSettingsDesktop from './panels/settings/desktop/pageSettingsDesktop';
import pageSettingsDesktopSubpage from './panels/settings/desktop/pageSettingsDesktopSubpage';
import pageSettingsMobile from './panels/settings/mobile/pageSettingsMobile';
import pageSettingsMobileSubpage from './panels/settings/mobile/pageSettingsMobileSubpage';
import menuLogic from './specialPages/menuLogic';
import blogPages from './specialPages/blogPages';
import tpaPages from './specialPages/tpaPages';

const settingsPanels = {
  desktop: {
    mainMenu: pageSettingsDesktop,
    subpage: pageSettingsDesktopSubpage,
  },
  mobile: {
    mainMenu: pageSettingsMobile,
    subpage: pageSettingsMobileSubpage,
  },
};

const mixedList = {
  nodeContentUniversalRenderer,
};

const mixins = {
  quickActionRenameMixin,
};

const utils = {
  convertPageNameToUrl: pageUtils.convertPageNameToUrl,
  quickActions: quickActionsUtils,
  constants,
};

const specialPages = {
  menuLogic,
  blog: blogPages,
  tpa: tpaPages,
};

export {
  PageItem as pageItem,
  nodeContentCommon,
  PageMenuItemHelpers as pageMenuItemHelpers,
  settingsPanels,
  mixedList,
  mixins,
  utils,
  specialPages,
};
