// @ts-nocheck
import constants from '@/constants';

export default {
  automationIds: {
    UPGRADE_PLAN_BUTTON: 'custom-error-page-upgrade-plan-button',
    SET_PAGE_BUTTON: 'custom-error-page-set-page-button',
    OPEN_PAGE_BUTTON: 'custom-error-page-open-page-button',
    HELP_LINK: 'custom-error-page-help-link',
    PREVIEW_LINK: 'custom-error-page-preview-link',
  },
  dataHooks: {
    PRIMARY_BUTTON: 'custom-error-page-primary-button',
    SECONDARY_BUTTON: 'custom-error-page-secondary-button',
  },
  actions: {
    UPGRADE_PLAN: 'edhtml_Error404_SiteSettingsFlow',
  },
  displayName: 'CustomErrorPagePanel',
  defaultUrl: constants.CUSTOM_ERROR_PAGE.DEFAULT_URL,
};
