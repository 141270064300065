import { ErrorReporter } from '@wix/editor-error-reporter';
import { fedopsLogger, editorModel } from '@/util';
import { addResources } from '@/i18n';

import { loadComponentsPart } from './componentRegistry';
import { registerComponentMetaData } from './componentsMetaData/componentsMetaData';
import { registerComponentGfppDefinition } from '@/gfppData';

export default async function loadComponentsInitialStageModel() {
  fedopsLogger.appLoadingPhaseStart(
    'editor-elements-registry-load-initial-stage-model',
  );
  const [metaDataMap, gfppMap, translationsMap] = await Promise.all([
    loadComponentsPart('metadata'),
    loadComponentsPart('gfpp'),
    loadComponentsPart('translations'),
  ]);

  fedopsLogger.appLoadingPhaseFinish(
    'editor-elements-registry-load-initial-stage-model',
  );

  fedopsLogger.appLoadingPhaseStart(
    'editor-elements-registry-load-translations',
  );
  await Promise.all(
    Array.from(translationsMap.entries()).map(
      async ([componentType, translation]) => {
        try {
          (await translation(editorModel.languageCode)).forEach(addResources);
        } catch (error) {
          console.error(
            `[editor-elements-registry]: load translation failed for ${componentType}`,
            error,
          );
          ErrorReporter.captureException(error, {
            tags: { operation: 'editor-elements-registry' },
          });
        }
      },
    ),
  );
  fedopsLogger.appLoadingPhaseFinish(
    'editor-elements-registry-load-translations',
  );

  fedopsLogger.appLoadingPhaseStart(
    'editor-elements-registry-register-metadata',
  );
  metaDataMap.forEach((componentMetaDataDefinition, componentType) => {
    registerComponentMetaData(componentType, componentMetaDataDefinition);
  });
  fedopsLogger.appLoadingPhaseFinish(
    'editor-elements-registry-register-metadata',
  );

  fedopsLogger.appLoadingPhaseStart('editor-elements-registry-register-gfpp');
  gfppMap.forEach((componentGfppDefinition, componentType) => {
    registerComponentGfppDefinition(componentType, componentGfppDefinition);
  });
  fedopsLogger.appLoadingPhaseFinish('editor-elements-registry-register-gfpp');
}
