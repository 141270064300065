import type { EditorAPI } from '@/editorAPI';
import type { CompRef } from 'types/documentServices';

import * as componentDeprecationUtils from './utils/componentDeprecationUtils';
import ComponentDeprecationPanel from './componentDeprecationPanel/componentDeprecationPanel';

function registerCompName(editorAPI: EditorAPI) {
  editorAPI.registerCompNamePlugin(
    'wixui.UnsupportedComponent',
    (compRef: CompRef) =>
      componentDeprecationUtils.getCompName(editorAPI, compRef),
  );
}

function onPreviewReady(editorAPI: EditorAPI) {
  registerCompName(editorAPI);
}

export const showComponentDeprecationPopup = (
  editorAPI: EditorAPI,
  ...props: AnyFixMe[]
) => {
  editorAPI.panelManager.openPanel(
    'componentDeprecation.panels.componentDeprecationPanel',
    {
      ...props,
    },
  );
};

export {
  siteHasDeprecatedComponents,
  getModalToOpenByComponentType,
  getHelpIdByComponentType,
  isAnyDeprecationSiteExperimentOpen,
  getModalToOpenOverridesByComponentType,
} from './utils/componentDeprecationUtils';

export { onPreviewReady };

export const panels = {
  componentDeprecationPanel: ComponentDeprecationPanel,
};
