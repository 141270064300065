import type { CompRef, Pointer } from '@wix/document-services-types';

import { BaseAnimationAPI } from './baseAnimationsApi';
import type { OngoingEffectData } from '../types';

const ANIMATION_END = 'animation-end';
const PAGE_VISIBLE = 'page-visible';

export class OngoingAnimationAPI extends BaseAnimationAPI {
  previewAnimation: (compRef: CompRef, effectData: OngoingEffectData) => void;
  findDependentEffect: (compRef: CompRef) => Pointer | null;

  private getCurrentTriggerType(hasDependentEffect: boolean) {
    return hasDependentEffect ? ANIMATION_END : PAGE_VISIBLE;
  }

  private getCurrentTriggerParams(dependentEffect: Pointer | null) {
    return dependentEffect
      ? {
          trigger: ANIMATION_END,
          params: { effect: dependentEffect },
        }
      : { trigger: PAGE_VISIBLE };
  }

  protected getTriggerType(compRef: CompRef) {
    const dependentEffect = this.findDependentEffect(compRef);
    return this.getCurrentTriggerParams(dependentEffect).trigger;
  }

  protected getTriggerParams(compRef: CompRef) {
    const dependentEffect = this.findDependentEffect(compRef);
    return this.getCurrentTriggerParams(dependentEffect);
  }

  get reactionParams() {
    return {
      type: 'Play',
    };
  }

  async setFindDependentEffect(callback: (compRef: CompRef) => Pointer | null) {
    this.findDependentEffect = callback;
  }

  async updateTrigger(compRef: CompRef, dependentEffect?: Pointer) {
    const oldTrigger = this.getCurrentTriggerType(!dependentEffect);
    const currentAnimation = this.findReaction(compRef, oldTrigger);

    if (!currentAnimation) {
      return;
    }

    await this.removeReactionAndTrigger(compRef, oldTrigger);

    await this.createTriggerAndReaction(
      compRef,
      currentAnimation.effect,
      this.getCurrentTriggerParams(dependentEffect),
    );

    await this.editorAPI.waitForChangesAppliedAsync();
  }
}
