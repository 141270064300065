import _ from 'lodash';
import * as util from '@/util';
import * as SingleCompHandler from './componentsMetaData';
import type { CompRef } from 'types/documentServices';

const {
  validateForAll,
  checkSingleAndValidate,
  validateForSome,
  applyForFirstAndThrowIfMulti,
  asArray,
} = util.array;

/* eslint-disable max-statements */
function create(editorAPI: any) {
  //TODO FIX EDITOR_API
  const singleCompHandler = SingleCompHandler.create(editorAPI);

  function controlledByParent(compRefs: AnyFixMe) {
    return (
      !_.isEmpty(compRefs) &&
      validateForAll(singleCompHandler.is.controlledByParent, compRefs)
    );
  }

  function fixed(compRefs: AnyFixMe) {
    return (
      !_.isEmpty(compRefs) &&
      validateForAll(singleCompHandler.is.fixed, compRefs)
    );
  }

  function exist(compRefs: AnyFixMe) {
    return (
      !_.isEmpty(compRefs) &&
      validateForAll(singleCompHandler.is.exist, compRefs)
    );
  }

  function container(compRefs: AnyFixMe) {
    return checkSingleAndValidate(singleCompHandler.is.container, compRefs);
  }

  function selectable(compRefs: AnyFixMe) {
    const compsArr = asArray(compRefs);
    return (
      compsArr.length > 1 || singleCompHandler.is.selectable(_.head(compsArr))
    );
  }

  function multiselectable(compRefs: AnyFixMe) {
    return (
      !_.isEmpty(compRefs) &&
      validateForAll(singleCompHandler.is.multiselectable, compRefs)
    );
  }

  function siteSegment(compRefs: CompRef | CompRef[]): boolean {
    return checkSingleAndValidate(singleCompHandler.is.siteSegment, compRefs);
  }

  function siblings(compRefs: AnyFixMe) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/includes
    if (_.includes(compRefs, undefined)) {
      return false;
    }
    const firstComp = compRefs.pop();
    const firstCompSiblings = editorAPI.components.getSiblings(firstComp);

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/every
    return _.every(compRefs, function isCompSiblingOfFirstComp(compRef) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/find
      return _.find(firstCompSiblings, compRef);
    });
  }

  function removable(compRefs: AnyFixMe) {
    return validateForAll(singleCompHandler.is.removable, compRefs);
  }

  function duplicatable(compRefs: AnyFixMe, potentialParent: AnyFixMe) {
    return (
      !_.isEmpty(compRefs) &&
      validateForAll(
        (compRef: AnyFixMe) =>
          singleCompHandler.is.duplicatable(compRef, potentialParent),
        compRefs,
      )
    );
  }

  function crossSiteDuplicatableByStructure(compStructures: AnyFixMe) {
    return (
      !_.isEmpty(compStructures) &&
      validateForAll(
        (compStructure: AnyFixMe) =>
          singleCompHandler.is.crossSiteDuplicatableByStructure(compStructure),
        compStructures,
      )
    );
  }

  function allowedAddToSavedComponent(
    compRefs: AnyFixMe,
    potentialParent?: AnyFixMe,
  ) {
    return validateForSome(
      (compRef: AnyFixMe) =>
        singleCompHandler.is.allowedAddToSavedComponent(
          compRef,
          potentialParent,
        ),
      compRefs,
    );
  }

  function allowConnectToDB(compRefs: AnyFixMe) {
    return checkSingleAndValidate(
      singleCompHandler.is.allowConnectToDB,
      compRefs,
    );
  }

  function allowedMobileOnlyByType(compType: string): boolean {
    return singleCompHandler.is.allowedMobileOnlyByType(compType);
  }

  function showMarginsIndicator(compRefs: AnyFixMe) {
    return checkSingleAndValidate(
      singleCompHandler.is.showMarginsIndicator,
      compRefs,
    );
  }

  function skipInQuickEditPanel(compRef: CompRef) {
    return checkSingleAndValidate(
      singleCompHandler.is.skipInQuickEditPanel,
      compRef,
    );
  }

  function skipInLayersPanel(compRefs: AnyFixMe) {
    return checkSingleAndValidate(
      singleCompHandler.is.skipInLayersPanel,
      compRefs,
    );
  }
  function shouldShowChildrenInLayersPanel(compRef: CompRef) {
    return checkSingleAndValidate(
      singleCompHandler.is.shouldShowChildrenInLayersPanel,
      compRef,
    );
  }

  function showLegacyFixedPosition(compRefs: AnyFixMe) {
    return checkSingleAndValidate(
      singleCompHandler.is.showLegacyFixedPosition,
      compRefs,
    );
  }

  function containable(compRefs: AnyFixMe, potentialParent: AnyFixMe) {
    return (
      !_.isEmpty(compRefs) &&
      validateForAll(
        (compRef: AnyFixMe) =>
          singleCompHandler.is.containable(compRef, potentialParent),
        compRefs,
      )
    );
  }

  function containableByStructure(
    compStructures: AnyFixMe,
    potentialParent: AnyFixMe,
  ) {
    return validateForAll(
      (compStructure: AnyFixMe) =>
        singleCompHandler.is.containableByStructure(
          compStructure,
          potentialParent,
        ),
      compStructures,
    );
  }

  function styleCanBeApplied(compRefs: AnyFixMe) {
    return checkSingleAndValidate(
      singleCompHandler.is.styleCanBeApplied,
      compRefs,
    );
  }

  function movable(compRefs: AnyFixMe) {
    return (
      !_.isEmpty(compRefs) &&
      validateForAll(singleCompHandler.is.movable, compRefs)
    );
  }

  function verticallyMovable(compRefs: AnyFixMe) {
    return (
      !_.isEmpty(compRefs) &&
      validateForAll(singleCompHandler.is.verticallyMovable, compRefs)
    );
  }

  function horizontallyMovable(compRefs: AnyFixMe) {
    return (
      !_.isEmpty(compRefs) &&
      validateForAll(singleCompHandler.is.horizontallyMovable, compRefs)
    );
  }

  function fullWidth(compRefs: AnyFixMe) {
    return validateForAll(singleCompHandler.is.fullWidth, compRefs);
  }

  function resizable(compRefs: AnyFixMe) {
    return checkSingleAndValidate(singleCompHandler.is.resizable, compRefs);
  }

  function horizontallyResizable(compRefs: AnyFixMe) {
    return validateForAll(singleCompHandler.is.horizontallyResizable, compRefs);
  }

  function verticallyResizable(compRefs: AnyFixMe) {
    return validateForAll(singleCompHandler.is.verticallyResizable, compRefs);
  }

  function verticallyResizableByContainer(compRefs: CompRef[]): boolean {
    return validateForAll(
      singleCompHandler.is.verticallyResizableByContainer,
      compRefs,
    );
  }

  function verticallyResizableByChildren(compRefs: CompRef[]): boolean {
    return validateForAll(
      singleCompHandler.is.verticallyResizableByChildren,
      compRefs,
    );
  }

  function proportionallyResizable(compRefs: AnyFixMe) {
    const compsArr = asArray(compRefs);
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/every
    return _.every(
      compsArr,
      singleCompHandler.is.proportionallyResizable.bind(editorAPI),
    );
  }

  function repeaterItem(compRefs: AnyFixMe) {
    return checkSingleAndValidate(singleCompHandler.is.repeaterItem, compRefs);
  }

  function descendantOfRepeaterItem(compRefs: AnyFixMe) {
    return validateForSome(
      singleCompHandler.is.descendantOfRepeaterItem,
      compRefs,
    );
  }

  function resizeOnlyProportionally(compRefs: AnyFixMe) {
    return validateForAll(
      singleCompHandler.is.resizeOnlyProportionally,
      compRefs,
    );
  }

  function overrideProportionalResize(compRefs: AnyFixMe) {
    const compsArray = asArray(compRefs);
    if (compsArray.length !== 1) {
      return null;
    }

    return singleCompHandler.is.overrideProportionalResize(_.head(compsArray));
  }

  function group(compRefs: AnyFixMe) {
    return checkSingleAndValidate(singleCompHandler.is.group, compRefs);
  }

  function groupable(compRefs: AnyFixMe) {
    return validateForAll(singleCompHandler.is.groupable, compRefs);
  }

  function page(compRefs: AnyFixMe) {
    return checkSingleAndValidate(
      (compRef) => editorAPI.pages.isPageExist(compRef.id),
      compRefs,
    );
  }

  function topMost(compRefs: AnyFixMe, compRefArray: AnyFixMe) {
    return validateForSome(
      (compRef: AnyFixMe) =>
        singleCompHandler.is.topMost(compRef, compRefArray),
      compRefs,
    );
  }

  function leftMost(compRefs: AnyFixMe, compRefArray: AnyFixMe) {
    return validateForSome(
      (compRef: AnyFixMe) =>
        singleCompHandler.is.leftMost(compRef, compRefArray),
      compRefs,
    );
  }

  function groupedComponent(compRefs: AnyFixMe) {
    return validateForAll(singleCompHandler.is.groupedComponent, compRefs);
  }

  function rotatable(compRefs: AnyFixMe) {
    return checkSingleAndValidate(singleCompHandler.is.rotatable, compRefs);
  }

  function rotatableByType(compRefs: AnyFixMe) {
    return validateForAll(singleCompHandler.is.rotatableByType, compRefs);
  }

  function flippable(compRefs: AnyFixMe) {
    return checkSingleAndValidate(singleCompHandler.is.flippable, compRefs);
  }

  function animatable(compRefs: AnyFixMe, actionName: AnyFixMe) {
    return validateForAll(
      (compRef: AnyFixMe) =>
        singleCompHandler.is.animatable(compRef, actionName),
      compRefs,
    );
  }

  function pinnable(compRefs: AnyFixMe) {
    return checkSingleAndValidate(singleCompHandler.is.pinnable, compRefs);
  }

  function stretchable(compRefs: AnyFixMe) {
    return checkSingleAndValidate(singleCompHandler.is.stretchable, compRefs);
  }

  function focusable(compRefs: AnyFixMe) {
    return checkSingleAndValidate(singleCompHandler.is.focusable, compRefs);
  }

  function slidesContainer(compRefs: AnyFixMe) {
    return checkSingleAndValidate(
      singleCompHandler.is.slidesContainer,
      compRefs,
    );
  }

  function exposesSlideAsInnerComponent(compRefs: AnyFixMe) {
    return checkSingleAndValidate(
      singleCompHandler.is.exposesSlideAsInnerComponent,
      compRefs,
    );
  }

  function slideContainer(compRefs: AnyFixMe) {
    return checkSingleAndValidate(
      singleCompHandler.is.slideContainer,
      compRefs,
    );
  }

  function hoverBox(compRefs: AnyFixMe) {
    return checkSingleAndValidate(singleCompHandler.is.hoverBox, compRefs);
  }

  function alignable(compRefs: AnyFixMe) {
    return validateForAll(singleCompHandler.is.alignable, compRefs);
  }

  function draggable(compRefs: AnyFixMe) {
    return validateForAll(singleCompHandler.is.draggable, compRefs);
  }

  function allowLassoOnContainer(compRefs: AnyFixMe) {
    return checkSingleAndValidate(
      singleCompHandler.is.allowLassoOnContainer,
      compRefs,
    );
  }

  function anchorableFrom(compRefs: AnyFixMe) {
    const res = validateForSome(singleCompHandler.is.anchorableFrom, compRefs);
    const compsArr = asArray(compRefs);
    if (compsArr.length > 1) {
      return res && editorAPI.components.layout.containsSiblingsOnly(compsArr);
    }
    return res;
  }

  function anchorableTo(compRefs: AnyFixMe) {
    const res = validateForSome(singleCompHandler.is.anchorableTo, compRefs);
    const compsArr = asArray(compRefs);
    if (compsArr.length > 1) {
      return res && editorAPI.components.layout.containsSiblingsOnly(compsArr);
    }
    return res;
  }

  function potentialContainerForScreenWidthComp(compRefs: AnyFixMe) {
    return checkSingleAndValidate(
      editorAPI.dsRead.components.is.potentialContainerForScreenWidthComp,
      compRefs,
    );
  }

  function selectedBeforeDescendants(
    compRefs: AnyFixMe,
    descendantPtr: AnyFixMe,
  ) {
    return checkSingleAndValidate(
      (compRef) =>
        singleCompHandler.is.selectedBeforeDescendants(compRef, descendantPtr),
      compRefs,
    );
  }

  function disabledKnobs(compRefs: AnyFixMe) {
    const compsArr = asArray(compRefs);
    if (compsArr.length === 1) {
      return singleCompHandler.is.disabledKnobs(_.head(compsArr));
    }
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/filter
    const compsWithDisabledKnobs = _.filter(
      compsArr,
      (compRef) => !_.isEmpty(singleCompHandler.is.disabledKnobs(compRef)),
    );
    return compsWithDisabledKnobs.length
      ? // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/values
        _.values(editorAPI.dsRead.components.layout.RESIZE_SIDES)
      : [];
  }

  function skinParamsToIgnore(compRefs: AnyFixMe) {
    return applyForFirstAndThrowIfMulti(
      'skinParamsToIgnore',
      singleCompHandler.is.skinParamsToIgnore,
      compRefs,
    );
  }

  function shouldBeOverlayed(compRefs: AnyFixMe) {
    return singleCompHandler.is.shouldBeOverlayed(compRefs);
  }

  function getOverlay(compRefs: AnyFixMe) {
    return singleCompHandler.is.getOverlay(compRefs);
  }

  function getOverlayProperties(compRefs: AnyFixMe) {
    return singleCompHandler.is.getOverlayProperties(compRefs);
  }

  function getDataEditOptions(compRefs: AnyFixMe) {
    return checkSingleAndValidate(
      singleCompHandler.is.getDataEditOptions,
      compRefs,
    );
  }

  function gridLinesVisible(compRefs: AnyFixMe) {
    return singleCompHandler.is.gridLinesVisible(compRefs);
  }

  function resizableWithAnchors(compRefs: AnyFixMe) {
    return singleCompHandler.is.resizableWithAnchors(compRefs);
  }

  function resizableDiagonally(compRefs: AnyFixMe) {
    return singleCompHandler.is.resizableDiagonally(compRefs);
  }

  function allowedToContainMoreChildren(compRefs: AnyFixMe) {
    return singleCompHandler.is.allowedToContainMoreChildren.call(
      editorAPI,
      compRefs,
    );
  }

  function gfppMargins(compRefs: AnyFixMe) {
    return singleCompHandler.is.gfppMargins(compRefs);
  }

  function nestedBackgroundDesign(compRefs: AnyFixMe) {
    return singleCompHandler.is.nestedBackgroundDesign(compRefs);
  }

  function customizeTranslate(compRefs: AnyFixMe) {
    return singleCompHandler.is.customizeTranslate(compRefs);
  }

  function canReparent(compRefs: AnyFixMe) {
    return validateForAll(singleCompHandler.is.canReparent, compRefs);
  }

  function spotlightStageContainer(compRefs: AnyFixMe) {
    //@ts-expect-error
    if (_.isEmpty(compRefs) || _(compRefs).castArray().get('length') !== 1) {
      return false;
    }
    return singleCompHandler.is.spotlightStageContainer(compRefs);
  }

  function inlinePopup(compRefs: AnyFixMe) {
    if (_.isEmpty(compRefs)) {
      return false;
    }
    return singleCompHandler.is.inlinePopup(compRefs);
  }

  function selectWhenDeselectingChildren(compRefs: AnyFixMe) {
    return singleCompHandler.is.selectWhenDeselectingChildren(compRefs);
  }

  function fixedNonPinned(compRefs: AnyFixMe) {
    return validateForSome(singleCompHandler.is.fixedNonPinned, compRefs);
  }

  function arrangeable(compRefs: AnyFixMe) {
    return singleCompHandler.is.arrangeable(compRefs);
  }

  function a11yConfigurable(compRefs: AnyFixMe) {
    return singleCompHandler.is.a11yConfigurable(compRefs);
  }

  function overridesWhenReferred(compRefs: AnyFixMe) {
    return singleCompHandler.is.overridesWhenReferred(compRefs);
  }

  function editDirectChildProperties(
    compRefs: AnyFixMe,
    firstChildRef: AnyFixMe,
  ) {
    return singleCompHandler.is.editDirectChildProperties(
      compRefs,
      firstChildRef,
    );
  }

  function delegateControlsToFirstChild(compRefs: AnyFixMe, source: AnyFixMe) {
    return singleCompHandler.is.delegateControlsToFirstChild(compRefs, source);
  }

  function canSnapTo(compRefs: AnyFixMe) {
    return singleCompHandler.is.canSnapTo(compRefs);
  }

  const gfppVisible = (comps: CompRef[]) =>
    singleCompHandler.is.gfppVisible(comps);

  return {
    controlledByParent,
    fixed,
    canSnapTo,
    exist,
    container,
    selectable,
    multiselectable,
    siteSegment,
    siblings,
    removable,
    duplicatable,
    crossSiteDuplicatableByStructure,
    allowedAddToSavedComponent,
    allowConnectToDB,
    allowedMobileOnlyByType,
    showMarginsIndicator,
    skipInLayersPanel,
    skipInQuickEditPanel,
    showLegacyFixedPosition,
    containable,
    containableByStructure,
    styleCanBeApplied,
    movable,
    verticallyMovable,
    horizontallyMovable,
    fullWidth,
    resizable,
    horizontallyResizable,
    verticallyResizable,
    verticallyResizableByContainer,
    verticallyResizableByChildren,
    proportionallyResizable,
    repeaterItem,
    descendantOfRepeaterItem,
    resizeOnlyProportionally,
    overrideProportionalResize,
    group,
    groupable,
    page,
    topMost,
    leftMost,
    groupedComponent,
    rotatable,
    rotatableByType,
    flippable,
    animatable,
    pinnable,
    stretchable,
    focusable,
    slidesContainer,
    slideContainer,
    hoverBox,
    alignable,
    draggable,
    allowLassoOnContainer,
    anchorableFrom,
    anchorableTo,
    potentialContainerForScreenWidthComp,
    selectedBeforeDescendants,
    disabledKnobs,
    shouldBeOverlayed,
    getOverlay,
    getOverlayProperties,
    getDataEditOptions,
    gridLinesVisible,
    resizableWithAnchors,
    resizableDiagonally,
    skinParamsToIgnore,
    allowedToContainMoreChildren,
    gfppMargins,
    nestedBackgroundDesign,
    customizeTranslate,
    canReparent,
    spotlightStageContainer,
    inlinePopup,
    selectWhenDeselectingChildren,
    fixedNonPinned,
    exposesSlideAsInnerComponent,
    arrangeable,
    a11yConfigurable,
    overridesWhenReferred,
    editDirectChildProperties,
    delegateControlsToFirstChild,
    gfppVisible,
    shouldShowChildrenInLayersPanel,
  };
}

export { create };
