'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')
const util = require('@/util')
const i18n = require('@/i18n')
const languageCode = util.languages.getLanguageCode()
module.exports = {
    "type": addPanelDataConsts.SECTIONS_TYPES.PRESET,
    "hide": false,
    "title": "add_section_label_textbutton",
    "subNavigationTitle": "add_section_label_textbutton",
    "presetTitle": "add_section_label_textbutton",
    "tooltipTitle": "add_section_label_textbutton",
    "automationId": "add-panel-section-textButtonsSection",
    "subNavigationHide": false,
    "showSectionHeader": true,
    "additionalBehaviours": {
        "labelMode": addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
        "infoIcon": addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
        "hoverImageAction": addPanelDataConsts.HOVER_IMAGE_ACTIONS.IMAGE,
        "iconEnabledForComps": {}
    },
    "props": {
        "image": `addPanelData/sections/textButtonsSectionLocalization/textButtonsSection_${languageCode}.png`,
        "imageHover": `addPanelData/sections/textButtonsSectionLocalization/textButtonsSection_hover_${languageCode}.png`,
        "items": [
            {
                "id": "Button_Text_Buttons_1",
                "structure": {
                    "type": "Component",
                    "skin": "wysiwyg.viewer.skins.button.BasicButton",
                    "layout": {
                        "width": 151,
                        "height": 40,
                        "x": 10,
                        "y": 30,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.SiteButton",
                    "data": {
                        "type": "LinkableButton",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "label": i18n.translate('comp-jav6yln3.label')
                    },
                    "props": {
                        "type": "ButtonProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "align": "center",
                        "margin": 0
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg": "1",
                                "alpha-bgh": "1",
                                "alpha-brd": "1",
                                "alpha-brdh": "1",
                                "alpha-txt": "1",
                                "alpha-txth": "1",
                                "bg": "#323232",
                                "bgh": "#000000",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "#323232",
                                "brdh": "#323232",
                                "brw": "0px",
                                "fnt": "normal normal normal 16px/1.4em eb+garamond",
                                "rd": "0px",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                "txt": "#FFFFFF",
                                "txth": "#FFFFFF"
                            },
                            "propertiesSource": {
                                "alpha-bgh": "value",
                                "bg": "value",
                                "bgh": "value",
                                "brd": "value",
                                "brdh": "value",
                                "brw": "value",
                                "fnt": "value",
                                "rd": "value",
                                "shd": "value",
                                "txt": "value",
                                "txth": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.viewer.components.SiteButton",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.button.BasicButton"
                    },
                    "activeModes": {},
                    "id": "comp-jav6yln3"
                },
                "preset": {
                    "rect": {
                        "width": 173,
                        "height": 84,
                        "x": 0,
                        "y": 0
                    },
                    "tags": "View More"
                }
            },
            {
                "id": "Button_Text_Buttons_2",
                "structure": {
                    "type": "Component",
                    "skin": "wysiwyg.viewer.skins.button.BasicButton",
                    "layout": {
                        "width": 132,
                        "height": 40,
                        "x": 182,
                        "y": 30,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.SiteButton",
                    "data": {
                        "type": "LinkableButton",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "label": i18n.translate('comp-jav6ylrx.label')
                    },
                    "props": {
                        "type": "ButtonProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "align": "center",
                        "margin": 0
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg": "0",
                                "alpha-bgh": "1",
                                "alpha-brd": "1",
                                "alpha-brdh": "0",
                                "alpha-txt": "1",
                                "alpha-txth": "1",
                                "bg": "#FFFFFF",
                                "bgh": "#323232",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "#323232",
                                "brdh": "#323232",
                                "brw": "2",
                                "fnt": "normal normal normal 13px/1.4em avenir-lt-w01_85-heavy1475544",
                                "rd": "20px",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                "txt": "#323232",
                                "txth": "#FFFFFF"
                            },
                            "propertiesSource": {
                                "alpha-bg": "value",
                                "alpha-brdh": "value",
                                "bg": "value",
                                "bgh": "value",
                                "brd": "value",
                                "brdh": "value",
                                "brw": "value",
                                "fnt": "value",
                                "rd": "value",
                                "shd": "value",
                                "txt": "value",
                                "txth": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.viewer.components.SiteButton",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.button.BasicButton"
                    },
                    "activeModes": {},
                    "id": "comp-jav6ylrx"
                },
                "preset": {
                    "rect": {
                        "width": 151,
                        "height": 84,
                        "x": 173,
                        "y": 0
                    },
                    "tags": "Back to Top"
                }
            },
            {
                "id": "Button_Text_Buttons_3",
                "structure": {
                    "type": "Component",
                    "skin": "wysiwyg.viewer.skins.button.BasicButton",
                    "layout": {
                        "width": 177,
                        "height": 40,
                        "x": 10,
                        "y": 100,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.SiteButton",
                    "data": {
                        "type": "LinkableButton",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "label": i18n.translate('comp-jav6yln8.label')
                    },
                    "props": {
                        "type": "ButtonProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "align": "center",
                        "margin": 0
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg": "1",
                                "alpha-bgh": "1",
                                "alpha-brd": "1",
                                "alpha-brdh": "0",
                                "alpha-txt": "1",
                                "alpha-txth": "1",
                                "bg": "#B6F3E8",
                                "bgh": "#CEF0EA",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "#B6F3E8",
                                "brdh": "#FFFFFF",
                                "brw": "0px",
                                "fnt": "normal normal normal 14px/1.4em avenir-lt-w01_85-heavy1475544",
                                "rd": "5px",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                "txt": "#323232",
                                "txth": "#000000"
                            },
                            "propertiesSource": {
                                "alpha-brdh": "value",
                                "bg": "value",
                                "bgh": "value",
                                "brd": "value",
                                "brdh": "value",
                                "brw": "value",
                                "fnt": "value",
                                "rd": "value",
                                "shd": "value",
                                "txt": "value",
                                "txth": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.viewer.components.SiteButton",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.button.BasicButton"
                    },
                    "activeModes": {},
                    "id": "comp-jav6yln8"
                },
                "preset": {
                    "rect": {
                        "width": 198,
                        "height": 70,
                        "x": 0,
                        "y": 84
                    },
                    "tags": "Discover More"
                }
            },
            {
                "id": "Button_Text_Buttons_4",
                "structure": {
                    "type": "Component",
                    "skin": "wysiwyg.viewer.skins.button.BasicButton",
                    "layout": {
                        "width": 105,
                        "height": 39,
                        "x": 208,
                        "y": 100,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.SiteButton",
                    "data": {
                        "type": "LinkableButton",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "label": i18n.translate('comp-jav6yln6.label')
                    },
                    "props": {
                        "type": "ButtonProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "align": "center",
                        "margin": 0
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg": "1",
                                "alpha-bgh": "1",
                                "alpha-brd": "1",
                                "alpha-brdh": "1",
                                "alpha-txt": "1",
                                "alpha-txth": "1",
                                "bg": "#B6F3E8",
                                "bgh": "#81DECD",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "#B6F3E8",
                                "brdh": "#3D9BE9",
                                "brw": "0px",
                                "fnt": "normal normal 700 12px/1.4em tahoma",
                                "rd": "100px",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                "txt": "#323232",
                                "txth": "#323232"
                            },
                            "propertiesSource": {
                                "bg": "value",
                                "bgh": "value",
                                "brd": "value",
                                "brdh": "value",
                                "brw": "value",
                                "fnt": "value",
                                "rd": "value",
                                "shd": "value",
                                "txt": "value",
                                "txth": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.viewer.components.SiteButton",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.button.BasicButton"
                    },
                    "activeModes": {},
                    "id": "comp-jav6yln6"
                },
                "preset": {
                    "rect": {
                        "width": 126,
                        "height": 70,
                        "x": 198,
                        "y": 84
                    },
                    "tags": "CALL US"
                }
            },
            {
                "id": "Button_Text_Buttons_5",
                "structure": {
                    "type": "Component",
                    "skin": "wysiwyg.viewer.skins.button.BasicButton",
                    "layout": {
                        "width": 144,
                        "height": 40,
                        "x": 10,
                        "y": 170,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.SiteButton",
                    "data": {
                        "type": "LinkableButton",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "label": i18n.translate('comp-jav6ylnc.label')
                    },
                    "props": {
                        "type": "ButtonProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "align": "center",
                        "margin": 0
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg": "0",
                                "alpha-bgh": "1",
                                "alpha-brd": "1",
                                "alpha-brdh": "0",
                                "alpha-txt": "1",
                                "alpha-txth": "1",
                                "bg": "#FFFFFF",
                                "bgh": "#1D2CF3",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "#1D2CF3",
                                "brdh": "#1D2CF3",
                                "brw": "2",
                                "fnt": "normal normal 700 14px/1.4em futura-lt-w01-book",
                                "rd": "0px",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                "txt": "#1D2CF3",
                                "txth": "#FFFFFF"
                            },
                            "propertiesSource": {
                                "alpha-bg": "value",
                                "alpha-brdh": "value",
                                "bg": "value",
                                "bgh": "value",
                                "brd": "value",
                                "brdh": "value",
                                "brw": "value",
                                "fnt": "value",
                                "rd": "value",
                                "shd": "value",
                                "txt": "value",
                                "txth": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.viewer.components.SiteButton",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.button.BasicButton"
                    },
                    "activeModes": {},
                    "id": "comp-jav6ylnc"
                },
                "preset": {
                    "rect": {
                        "width": 164,
                        "height": 70,
                        "x": 0,
                        "y": 154
                    },
                    "tags": "Contact Us"
                }
            },
            {
                "id": "Button_Text_Buttons_6",
                "structure": {
                    "type": "Component",
                    "skin": "wysiwyg.viewer.skins.button.BasicButton",
                    "layout": {
                        "width": 81,
                        "height": 40,
                        "x": 173,
                        "y": 170,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.SiteButton",
                    "data": {
                        "type": "LinkableButton",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "label": i18n.translate('comp-jav6ylna.label')
                    },
                    "props": {
                        "type": "ButtonProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "align": "center",
                        "margin": 0
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg": "1",
                                "alpha-bgh": "1",
                                "alpha-brd": "1",
                                "alpha-brdh": "1",
                                "alpha-txt": "1",
                                "alpha-txth": "1",
                                "bg": "#1D2CF3",
                                "bgh": "#4E59F2",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "#3AB5CD",
                                "brdh": "#FFFFFF",
                                "brw": "0px",
                                "fnt": "normal normal normal 14px/1.4em georgia",
                                "rd": "8px",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                "txt": "#FFFFFF",
                                "txth": "#FFFFFF"
                            },
                            "propertiesSource": {
                                "bg": "value",
                                "bgh": "value",
                                "brd": "value",
                                "brdh": "value",
                                "brw": "value",
                                "fnt": "value",
                                "rd": "value",
                                "shd": "value",
                                "txt": "value",
                                "txth": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.viewer.components.SiteButton",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.button.BasicButton"
                    },
                    "activeModes": {},
                    "id": "comp-jav6ylna"
                },
                "preset": {
                    "rect": {
                        "width": 103,
                        "height": 70,
                        "x": 164,
                        "y": 154
                    },
                    "tags": "SHOP"
                }
            },
            {
                "id": "Button_Text_Buttons_7",
                "structure": {
                    "type": "Component",
                    "skin": "wysiwyg.viewer.skins.button.CircleButton",
                    "layout": {
                        "width": 40,
                        "height": 40,
                        "x": 273,
                        "y": 170,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.SiteButton",
                    "data": {
                        "type": "LinkableButton",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "label": i18n.translate('comp-jav6ylne.label')
                    },
                    "props": {
                        "type": "ButtonProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "align": "center",
                        "margin": 0
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg": "1",
                                "alpha-bgd": "1",
                                "alpha-bgh": "1",
                                "alpha-brd": "1",
                                "alpha-brdd": "1",
                                "alpha-brdh": "1",
                                "bg": "#1D2CF3",
                                "bgd": "#CCCCCC",
                                "bgh": "#212A9E",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "#3AB5CD",
                                "brdd": "#CCCCCC",
                                "brdh": "#323232",
                                "brw": "0",
                                "fnt": "normal normal normal 15px/1.4em arial+black",
                                "rd": "50%",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                "txtd": "#FFFFFF",
                                "txt": "#FFFFFF"
                            },
                            "propertiesSource": {
                                "alpha-bg": "value",
                                "alpha-bgd": "value",
                                "alpha-bgh": "value",
                                "alpha-brd": "value",
                                "alpha-brdd": "value",
                                "alpha-brdh": "value",
                                "bg": "value",
                                "bgd": "value",
                                "bgh": "value",
                                "boxShadowToggleOn-shd": "value",
                                "brd": "value",
                                "brdd": "value",
                                "brdh": "value",
                                "brw": "value",
                                "fnt": "value",
                                "rd": "value",
                                "shd": "value",
                                "txt": "value",
                                "txtd": "value",
                                "txth": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.viewer.components.SiteButton",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.button.CircleButton"
                    },
                    "activeModes": {},
                    "id": "comp-jav6ylne"
                },
                "preset": {
                    "rect": {
                        "width": 57,
                        "height": 70,
                        "x": 267,
                        "y": 154
                    },
                    "tags": "GO"
                }
            },
            {
                "id": "Button_Text_Buttons_8",
                "structure": {
                    "type": "Component",
                    "skin": "wysiwyg.viewer.skins.button.CircleButton",
                    "layout": {
                        "width": 40,
                        "height": 40,
                        "x": 10,
                        "y": 240,
                        "scale": 1,
                        "rotationInDegrees": 270,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.SiteButton",
                    "data": {
                        "type": "LinkableButton",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "label": ">"
                    },
                    "props": {
                        "type": "ButtonProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "align": "center",
                        "margin": 0
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg": "1",
                                "alpha-bgd": "1",
                                "alpha-bgh": "1",
                                "alpha-brd": "1",
                                "alpha-brdd": "1",
                                "alpha-brdh": "1",
                                "bg": "#566FB8",
                                "bgd": "#CCCCCC",
                                "bgh": "#3E569E",
                                "boxShadowToggleOn-shd": "false",
                                "brdd": "#CCCCCC",
                                "brdh": "#536EB7",
                                "brw": "0",
                                "fnt": "normal normal normal 18px/1.4em proxima-n-w01-reg",
                                "rd": "50%",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                "txtd": "#FFFFFF",
                                "txt": "#FFFFFF"
                            },
                            "propertiesSource": {
                                "alpha-bg": "value",
                                "alpha-bgd": "value",
                                "alpha-bgh": "value",
                                "alpha-brd": "value",
                                "alpha-brdd": "value",
                                "alpha-brdh": "value",
                                "bg": "value",
                                "bgd": "value",
                                "bgh": "value",
                                "boxShadowToggleOn-shd": "value",
                                "brd": "value",
                                "brdd": "value",
                                "brdh": "value",
                                "brw": "value",
                                "fnt": "value",
                                "rd": "value",
                                "shd": "value",
                                "txt": "value",
                                "txtd": "value",
                                "txth": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.viewer.components.SiteButton",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.button.CircleButton"
                    },
                    "activeModes": {},
                    "id": "comp-jav6ylnl"
                },
                "preset": {
                    "rect": {
                        "width": 58,
                        "height": 70,
                        "x": 0,
                        "y": 224
                    },
                    "tags": ">"
                }
            },
            {
                "id": "Button_Text_Buttons_9",
                "structure": {
                    "type": "Component",
                    "skin": "wysiwyg.viewer.skins.button.BasicButton",
                    "layout": {
                        "width": 128,
                        "height": 40,
                        "x": 70,
                        "y": 240,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.SiteButton",
                    "data": {
                        "type": "LinkableButton",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "label": i18n.translate('comp-jav6ylnn.label')
                    },
                    "props": {
                        "type": "ButtonProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "align": "center",
                        "margin": 0
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg": "1",
                                "alpha-bgh": "1",
                                "alpha-brd": "1",
                                "alpha-brdh": "1",
                                "bg": "#566FB8",
                                "bgh": "#3E569E",
                                "boxShadowToggleOn-shd": "true",
                                "brd": "#2B689C",
                                "brdh": "#536EB7",
                                "brw": "0px",
                                "fnt": "normal normal normal 14px/1.4em clarendon-w01-medium-692107",
                                "rd": "20px",
                                "shd": "0.00px 3.00px 0px 0px rgba(86,111,184,0.6)",
                                "txt": "#FFFFFF",
                                "txth": "#FFFFFF"
                            },
                            "propertiesSource": {
                                "alpha-bg": "value",
                                "alpha-bgh": "value",
                                "alpha-brd": "value",
                                "alpha-brdh": "value",
                                "bg": "value",
                                "bgh": "value",
                                "boxShadowToggleOn-shd": "value",
                                "brd": "value",
                                "brdh": "value",
                                "brw": "value",
                                "fnt": "value",
                                "rd": "value",
                                "shd": "value",
                                "txt": "value",
                                "txth": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.viewer.components.SiteButton",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.button.BasicButton"
                    },
                    "activeModes": {},
                    "id": "comp-jav6ylnn"
                },
                "preset": {
                    "rect": {
                        "width": 146,
                        "height": 70,
                        "x": 58,
                        "y": 224
                    },
                    "tags": "Be in Touch"
                }
            },
            {
                "id": "Button_Text_Buttons_10",
                "structure": {
                    "type": "Component",
                    "skin": "wysiwyg.viewer.skins.button.TextOnlyButtonSkin",
                    "layout": {
                        "width": 100,
                        "height": 40,
                        "x": 214,
                        "y": 240,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.SiteButton",
                    "data": {
                        "type": "LinkableButton",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "label": i18n.translate('comp-jav6ylnh.label')
                    },
                    "props": {
                        "type": "ButtonProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "align": "center",
                        "margin": 0
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {
                                "fnt": "normal normal normal 15px/1.4em lulo-clean-w01-one-bold",
                                "txt": "#566FB8",
                                "txtd": "#FFFFFF",
                                "txth": "#243E85"
                            },
                            "propertiesSource": {
                                "fnt": "value",
                                "txt": "value",
                                "txtd": "value",
                                "txth": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.viewer.components.SiteButton",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.button.TextOnlyButtonSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jav6ylnh"
                },
                "preset": {
                    "rect": {
                        "width": 120,
                        "height": 70,
                        "x": 204,
                        "y": 224
                    },
                    "tags": "JOIN US"
                }
            },
            {
                "id": "Button_Text_Buttons_11",
                "structure": {
                    "type": "Component",
                    "skin": "wysiwyg.viewer.skins.button.BasicButton",
                    "layout": {
                        "width": 171,
                        "height": 40,
                        "x": 10,
                        "y": 310,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.SiteButton",
                    "data": {
                        "type": "LinkableButton",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "label": i18n.translate('comp-jav6ylnq.label')
                    },
                    "props": {
                        "type": "ButtonProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "align": "center",
                        "margin": 0
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg": "1",
                                "alpha-bgh": "1",
                                "alpha-brd": "1",
                                "alpha-brdh": "0",
                                "alpha-txt": "1",
                                "alpha-txth": "1",
                                "bg": "#A2A3E9",
                                "bgh": "#8B8CD6",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "#FFFFFF",
                                "brdh": "#FFFFFF",
                                "brw": "0",
                                "fnt": "normal normal normal 14px/1.4em georgia",
                                "rd": "0px",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                "txt": "#FFFFFF",
                                "txth": "#FFFFFF"
                            },
                            "propertiesSource": {
                                "alpha-bgh": "value",
                                "alpha-brdh": "value",
                                "bg": "value",
                                "bgh": "value",
                                "brd": "value",
                                "brdh": "value",
                                "brw": "value",
                                "fnt": "value",
                                "rd": "value",
                                "shd": "value",
                                "txt": "value",
                                "txth": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.viewer.components.SiteButton",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.button.BasicButton"
                    },
                    "activeModes": {},
                    "id": "comp-jav6ylnq"
                },
                "preset": {
                    "rect": {
                        "width": 193,
                        "height": 70,
                        "x": 0,
                        "y": 294
                    },
                    "tags": "Order Now"
                }
            },
            {
                "id": "Button_Text_Buttons_12",
                "structure": {
                    "type": "Component",
                    "skin": "wysiwyg.viewer.skins.button.BasicButton",
                    "layout": {
                        "width": 112,
                        "height": 40,
                        "x": 202,
                        "y": 310,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.SiteButton",
                    "data": {
                        "type": "LinkableButton",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "label": i18n.translate('comp-jav6ylnt.label')
                    },
                    "props": {
                        "type": "ButtonProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "align": "center",
                        "margin": 0
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg": "1",
                                "alpha-bgh": "1",
                                "alpha-brd": "1",
                                "alpha-brdh": "0",
                                "alpha-txt": "1",
                                "alpha-txth": "1",
                                "bg": "#A2A3E9",
                                "bgh": "#8B8CD6",
                                "boxShadowToggleOn-shd": "true",
                                "brd": "#183981",
                                "brdh": "#8B8CD6",
                                "brw": "0px",
                                "fnt": "normal normal normal 13px/1.4em raleway",
                                "rd": "20px",
                                "shd": "0.00px 3.00px 9px 0px rgba(0,0,0,0.23)",
                                "txt": "#FFFFFF",
                                "txth": "#FFFFFF"
                            },
                            "propertiesSource": {
                                "alpha-bgh": "value",
                                "alpha-brdh": "value",
                                "bg": "value",
                                "bgh": "value",
                                "brd": "value",
                                "brdh": "value",
                                "brw": "value",
                                "fnt": "value",
                                "rd": "value",
                                "shd": "value",
                                "txt": "value",
                                "txth": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.viewer.components.SiteButton",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.button.BasicButton"
                    },
                    "activeModes": {},
                    "id": "comp-jav6ylnt"
                },
                "preset": {
                    "rect": {
                        "width": 131,
                        "height": 70,
                        "x": 193,
                        "y": 294
                    },
                    "tags": "SEND"
                }
            },
            {
                "id": "Button_Text_Buttons_13",
                "structure": {
                    "type": "Component",
                    "skin": "wysiwyg.viewer.skins.button.BasicButton",
                    "layout": {
                        "width": 143,
                        "height": 40,
                        "x": 10,
                        "y": 379,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.SiteButton",
                    "data": {
                        "type": "LinkableButton",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "label": i18n.translate('comp-jav6yln3.label')
                    },
                    "props": {
                        "type": "ButtonProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "align": "center",
                        "margin": 0
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg": "1",
                                "alpha-bgh": "1",
                                "alpha-brd": "1",
                                "alpha-brdh": "1",
                                "alpha-txt": "1",
                                "alpha-txth": "1",
                                "bg": "#B45AD3",
                                "bgh": "#8E3AAB",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "#38C68B",
                                "brdh": "#38C68B",
                                "brw": "0px",
                                "fnt": "normal normal normal 14px/1.4em helvetica-w01-light",
                                "rd": "8px",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                "txt": "#FFFFFF",
                                "txth": "#FFFFFF"
                            },
                            "propertiesSource": {
                                "bg": "value",
                                "bgh": "value",
                                "brd": "value",
                                "brdh": "value",
                                "brw": "value",
                                "fnt": "value",
                                "rd": "value",
                                "shd": "value",
                                "txt": "value",
                                "txth": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.viewer.components.SiteButton",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.button.BasicButton"
                    },
                    "activeModes": {},
                    "id": "comp-jav6ylp9"
                },
                "preset": {
                    "rect": {
                        "width": 164,
                        "height": 70,
                        "x": 0,
                        "y": 364
                    },
                    "tags": "View More"
                }
            },
            {
                "id": "Button_Text_Buttons_14",
                "structure": {
                    "type": "Component",
                    "skin": "wysiwyg.viewer.skins.button.BasicButton",
                    "layout": {
                        "width": 40,
                        "height": 40,
                        "x": 174,
                        "y": 379,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.SiteButton",
                    "data": {
                        "type": "LinkableButton",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "label": i18n.translate('comp-jav6ylpc.label')
                    },
                    "props": {
                        "type": "ButtonProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "align": "center",
                        "margin": 0
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg": "1",
                                "alpha-bgh": "1",
                                "alpha-brd": "1",
                                "alpha-brdh": "1",
                                "alpha-txt": "1",
                                "alpha-txth": "1",
                                "bg": "#B45AD3",
                                "bgh": "#8E3AAB",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "#B45AD3",
                                "brdh": "#8E3AAB",
                                "brw": "0px",
                                "fnt": "normal normal normal 14px/1.4em arial",
                                "rd": "0px",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                "txt": "#FFFFFF",
                                "txth": "#FFFFFF"
                            },
                            "propertiesSource": {
                                "bg": "value",
                                "bgh": "value",
                                "brd": "value",
                                "brdh": "value",
                                "brw": "value",
                                "fnt": "value",
                                "rd": "value",
                                "shd": "value",
                                "txt": "value",
                                "txth": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.viewer.components.SiteButton",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.button.BasicButton"
                    },
                    "activeModes": {},
                    "id": "comp-jav6ylpc"
                },
                "preset": {
                    "rect": {
                        "width": 61,
                        "height": 70,
                        "x": 163,
                        "y": 364
                    },
                    "tags": "X"
                }
            },
            {
                "id": "Button_Text_Buttons_15",
                "structure": {
                    "type": "Component",
                    "skin": "wysiwyg.viewer.skins.button.BasicButton",
                    "layout": {
                        "width": 180,
                        "height": 80,
                        "x": 184,
                        "y": 430,
                        "scale": 1,
                        "rotationInDegrees": 270,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.SiteButton",
                    "data": {
                        "type": "LinkableButton",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "label": i18n.translate('comp-jav6ylnv.label')
                    },
                    "props": {
                        "type": "ButtonProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "align": "center",
                        "margin": 0
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg": "1",
                                "alpha-bgh": "1",
                                "alpha-brd": "1",
                                "alpha-brdh": "1",
                                "alpha-txt": "1",
                                "alpha-txth": "1",
                                "bg": "#FFD9E4",
                                "bgh": "#EBABBE",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "#AEEFC5",
                                "brdh": "#3D9BE9",
                                "brw": "0px",
                                "fnt": "normal normal normal 30px/1.4em futura-lt-w01-book",
                                "rd": "0px",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                "txt": "#000000",
                                "txth": "#FFFFFF"
                            },
                            "propertiesSource": {
                                "bg": "value",
                                "bgh": "value",
                                "brd": "value",
                                "brdh": "value",
                                "brw": "value",
                                "fnt": "value",
                                "rd": "value",
                                "shd": "value",
                                "txt": "value",
                                "txth": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.viewer.components.SiteButton",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.button.BasicButton"
                    },
                    "activeModes": {},
                    "id": "comp-jav6ylnv"
                },
                "preset": {
                    "rect": {
                        "width": 100,
                        "height": 210,
                        "x": 224,
                        "y": 364
                    },
                    "tags": "S A L E"
                }
            },
            {
                "id": "Button_Text_Buttons_16",
                "structure": {
                    "type": "Component",
                    "skin": "wysiwyg.viewer.skins.button.BasicButton",
                    "layout": {
                        "width": 92,
                        "height": 40,
                        "x": 10,
                        "y": 449,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.SiteButton",
                    "data": {
                        "type": "LinkableButton",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "label": i18n.translate('comp-jav6ylny.label')
                    },
                    "props": {
                        "type": "ButtonProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "align": "center",
                        "margin": 0
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg": "1",
                                "alpha-bgh": "1",
                                "alpha-brd": "1",
                                "alpha-brdh": "1",
                                "alpha-txt": "1",
                                "alpha-txth": "1",
                                "bg": "#532563",
                                "bgh": "#783C8C",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "#07919C",
                                "brdh": "#1D517D",
                                "brw": "0px",
                                "fnt": "normal normal normal 13px/1.4em helvetica-w01-light",
                                "rd": "0px",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                "txt": "#FFFFFF",
                                "txth": "#FFFFFF"
                            },
                            "propertiesSource": {
                                "bg": "value",
                                "bgh": "value",
                                "brd": "value",
                                "brdh": "value",
                                "brw": "value",
                                "fnt": "value",
                                "rd": "value",
                                "shd": "value",
                                "txt": "value",
                                "txth": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.viewer.components.SiteButton",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.button.BasicButton"
                    },
                    "activeModes": {},
                    "id": "comp-jav6ylny"
                },
                "preset": {
                    "rect": {
                        "width": 113,
                        "height": 70,
                        "x": 0,
                        "y": 434
                    },
                    "tags": "PICK ME"
                }
            },
            {
                "id": "Button_Text_Buttons_17",
                "structure": {
                    "type": "Component",
                    "skin": "wysiwyg.viewer.skins.button.BasicButton",
                    "layout": {
                        "width": 92,
                        "height": 40,
                        "x": 122,
                        "y": 449,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.SiteButton",
                    "data": {
                        "type": "LinkableButton",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "label": i18n.translate('comp-jav6ylp6.label')
                    },
                    "props": {
                        "type": "ButtonProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "align": "center",
                        "margin": 0
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg": "0",
                                "alpha-bgh": "1",
                                "alpha-brd": "1",
                                "alpha-brdh": "0",
                                "alpha-txt": "1",
                                "alpha-txth": "1",
                                "bg": "#FFFFFF",
                                "bgh": "#532563",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "#532563",
                                "brdh": "#328297",
                                "brw": "1",
                                "fnt": "normal normal normal 15px/1.4em arial",
                                "rd": "0px",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                "txt": "#532563",
                                "txth": "#FFFFFF"
                            },
                            "propertiesSource": {
                                "alpha-bg": "value",
                                "alpha-brdh": "value",
                                "bg": "value",
                                "bgh": "value",
                                "brd": "value",
                                "brdh": "value",
                                "brw": "value",
                                "fnt": "value",
                                "rd": "value",
                                "shd": "value",
                                "txt": "value",
                                "txth": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.viewer.components.SiteButton",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.button.BasicButton"
                    },
                    "activeModes": {},
                    "id": "comp-jav6ylp6"
                },
                "preset": {
                    "rect": {
                        "width": 111,
                        "height": 70,
                        "x": 113,
                        "y": 434
                    },
                    "tags": "RSVP"
                }
            },
            {
                "id": "Button_Text_Buttons_18",
                "structure": {
                    "type": "Component",
                    "skin": "wysiwyg.viewer.skins.button.BasicButton",
                    "layout": {
                        "width": 204,
                        "height": 40,
                        "x": 10,
                        "y": 519,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.SiteButton",
                    "data": {
                        "type": "LinkableButton",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "label": i18n.translate('comp-jav6ylrs.label')
                    },
                    "props": {
                        "type": "ButtonProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "align": "center",
                        "margin": 0
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg": "1",
                                "alpha-bgh": "1",
                                "alpha-brd": "1",
                                "alpha-brdh": "1",
                                "bg": "#E43292",
                                "bgh": "#ED45A0",
                                "boxShadowToggleOn-shd": "true",
                                "brd": "#AEEFC5",
                                "brdh": "#38C68B",
                                "brw": "0px",
                                "fnt": "italic normal normal 17px/1.4em times+new+roman",
                                "rd": "20px",
                                "shd": "0.00px 4.00px 0px 0px rgba(228,50,146,0.5)",
                                "txt": "#FFFFFF",
                                "txth": "#FFFFFF"
                            },
                            "propertiesSource": {
                                "alpha-bg": "value",
                                "alpha-bgh": "value",
                                "alpha-brd": "value",
                                "alpha-brdh": "value",
                                "bg": "value",
                                "bgh": "value",
                                "boxShadowToggleOn-shd": "value",
                                "brd": "value",
                                "brdh": "value",
                                "brw": "value",
                                "fnt": "value",
                                "rd": "value",
                                "shd": "value",
                                "txt": "value",
                                "txth": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.viewer.components.SiteButton",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.button.BasicButton"
                    },
                    "activeModes": {},
                    "id": "comp-jav6ylrs"
                },
                "preset": {
                    "rect": {
                        "width": 224,
                        "height": 70,
                        "x": 0,
                        "y": 504
                    },
                    "tags": "Say Hello"
                }
            },
            {
                "id": "Button_Text_Buttons_19",
                "structure": {
                    "type": "Component",
                    "skin": "wysiwyg.viewer.skins.button.BasicButton",
                    "layout": {
                        "width": 106,
                        "height": 40,
                        "x": 10,
                        "y": 590,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.SiteButton",
                    "data": {
                        "type": "LinkableButton",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "label": i18n.translate('comp-jav6ylpm.label')
                    },
                    "props": {
                        "type": "ButtonProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "align": "center",
                        "margin": 0
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {
                                "fnt": "normal normal normal 16px/1.4em avenir-lt-w01_85-heavy1475544",
                                "txt": "#594E48",
                                "txtd": "#FFFFFF",
                                "txth": "#82756D"
                            },
                            "propertiesSource": {
                                "fnt": "value",
                                "txt": "value",
                                "txtd": "value",
                                "txth": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.viewer.components.SiteButton",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.button.TextOnlyButtonSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jav6ylpm"
                },
                "preset": {
                    "rect": {
                        "width": 124,
                        "height": 70,
                        "x": 0,
                        "y": 574
                    },
                    "tags": "DOWNLOAD"
                }
            },
            {
                "id": "Button_Text_Buttons_20",
                "structure": {
                    "type": "Component",
                    "skin": "wysiwyg.viewer.skins.button.BasicButton",
                    "layout": {
                        "width": 177,
                        "height": 40,
                        "x": 137,
                        "y": 590,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.SiteButton",
                    "data": {
                        "type": "LinkableButton",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "label": i18n.translate('comp-jav6ylpf.label')
                    },
                    "props": {
                        "type": "ButtonProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "align": "center",
                        "margin": 0
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg": "1",
                                "alpha-bgh": "1",
                                "alpha-brd": "1",
                                "alpha-brdh": "0",
                                "alpha-txt": "1",
                                "alpha-txth": "1",
                                "bg": "#FDF733",
                                "bgh": "#000000",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "#FDF733",
                                "brdh": "#FFFFFF",
                                "brw": "0",
                                "fnt": "normal normal 700 15px/1.4em raleway",
                                "rd": "0px",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                "txt": "#000000",
                                "txth": "#FDF733"
                            },
                            "propertiesSource": {
                                "alpha-brdh": "value",
                                "bg": "value",
                                "bgh": "value",
                                "brd": "value",
                                "brdh": "value",
                                "brw": "value",
                                "fnt": "value",
                                "rd": "value",
                                "shd": "value",
                                "txt": "value",
                                "txth": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.viewer.components.SiteButton",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.button.BasicButton"
                    },
                    "activeModes": {},
                    "id": "comp-jav6ylpf"
                },
                "preset": {
                    "rect": {
                        "width": 200,
                        "height": 70,
                        "x": 124,
                        "y": 574
                    },
                    "tags": "Let’s Talk"
                }
            },
            {
                "id": "Button_Text_Buttons_21",
                "structure": {
                    "type": "Component",
                    "skin": "wysiwyg.viewer.skins.button.BasicButton",
                    "layout": {
                        "width": 154,
                        "height": 40,
                        "x": 10,
                        "y": 660,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.SiteButton",
                    "data": {
                        "type": "LinkableButton",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "label": i18n.translate('comp-jav6ylpp.label')
                    },
                    "props": {
                        "type": "ButtonProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "align": "center",
                        "margin": 0
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg": "1",
                                "alpha-bgh": "1",
                                "alpha-brd": "1",
                                "alpha-brdh": "1",
                                "alpha-txt": "1",
                                "alpha-txth": "1",
                                "bg": "#FFD45A",
                                "bgh": "#FFE18C",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "#FFD45A",
                                "brdh": "#733787",
                                "brw": "0px",
                                "fnt": "italic normal normal 15px/1.4em georgia",
                                "rd": "8px",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                "txt": "#353131",
                                "txth": "#353131"
                            },
                            "propertiesSource": {
                                "bg": "value",
                                "bgh": "value",
                                "brd": "value",
                                "brdh": "value",
                                "brw": "value",
                                "fnt": "value",
                                "rd": "value",
                                "shd": "value",
                                "txt": "value",
                                "txth": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.viewer.components.SiteButton",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.button.BasicButton"
                    },
                    "activeModes": {},
                    "id": "comp-jav6ylpp"
                },
                "preset": {
                    "rect": {
                        "width": 174,
                        "height": 70,
                        "x": 0,
                        "y": 644
                    },
                    "tags": "Find out More"
                }
            },
            {
                "id": "Button_Text_Buttons_22",
                "structure": {
                    "type": "Component",
                    "skin": "wysiwyg.viewer.skins.button.BasicButton",
                    "layout": {
                        "width": 128,
                        "height": 40,
                        "x": 185,
                        "y": 660,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.SiteButton",
                    "data": {
                        "type": "LinkableButton",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "label": i18n.translate('comp-jav6ylps.label')
                    },
                    "props": {
                        "type": "ButtonProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "align": "center",
                        "margin": 0
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg": "0",
                                "alpha-bgh": "1",
                                "alpha-brd": "1",
                                "alpha-brdh": "0",
                                "alpha-txt": "1",
                                "alpha-txth": "1",
                                "bg": "#FFFFFF",
                                "bgh": "#FFD45A",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "#FFD45A",
                                "brdh": "#FFD45A",
                                "brw": "3",
                                "fnt": "normal normal 700 14px/1.4em avenir-lt-w01_85-heavy1475544",
                                "rd": "0px",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                "txt": "#353131",
                                "txth": "#353131"
                            },
                            "propertiesSource": {
                                "alpha-bg": "value",
                                "alpha-brdh": "value",
                                "bg": "value",
                                "bgh": "value",
                                "brd": "value",
                                "brdh": "value",
                                "brw": "value",
                                "fnt": "value",
                                "rd": "value",
                                "shd": "value",
                                "txt": "value",
                                "txth": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.viewer.components.SiteButton",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.button.BasicButton"
                    },
                    "activeModes": {},
                    "id": "comp-jav6ylps"
                },
                "preset": {
                    "rect": {
                        "width": 150,
                        "height": 70,
                        "x": 174,
                        "y": 644
                    },
                    "tags": "Load More"
                }
            },
            {
                "id": "Button_Text_Buttons_23",
                "structure": {
                    "type": "Component",
                    "skin": "wysiwyg.viewer.skins.button.BasicButton",
                    "layout": {
                        "width": 111,
                        "height": 111,
                        "x": 10,
                        "y": 731,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.SiteButton",
                    "data": {
                        "type": "LinkableButton",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "label": i18n.translate('comp-jav6ylna.label')
                    },
                    "props": {
                        "type": "ButtonProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "align": "center",
                        "margin": 0
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg": "1",
                                "alpha-bgh": "1",
                                "alpha-brd": "1",
                                "alpha-brdh": "0",
                                "alpha-txt": "1",
                                "alpha-txth": "1",
                                "bg": "#FE6161",
                                "bgh": "#ED4545",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "#FE6161",
                                "brdh": "#FFFFFF",
                                "brw": "0px",
                                "fnt": "normal normal 700 14px/1.4em lulo-clean-w01-one-bold",
                                "rd": "100px",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                "txt": "#FFFFFF",
                                "txth": "#FFFFFF"
                            },
                            "propertiesSource": {
                                "alpha-brdh": "value",
                                "bg": "value",
                                "bgh": "value",
                                "brd": "value",
                                "brdh": "value",
                                "brw": "value",
                                "fnt": "value",
                                "rd": "value",
                                "shd": "value",
                                "txt": "value",
                                "txth": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.viewer.components.SiteButton",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.button.BasicButton"
                    },
                    "activeModes": {},
                    "id": "comp-jav6ylpz"
                },
                "preset": {
                    "rect": {
                        "width": 132,
                        "height": 140,
                        "x": 0,
                        "y": 714
                    },
                    "tags": "SHOP"
                }
            },
            {
                "id": "Button_Text_Buttons_24",
                "structure": {
                    "type": "Component",
                    "skin": "wysiwyg.viewer.skins.button.BasicButton",
                    "layout": {
                        "width": 173,
                        "height": 40,
                        "x": 141,
                        "y": 731,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.SiteButton",
                    "data": {
                        "type": "LinkableButton",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "label": i18n.translate('comp-jav6ylpw.label')
                    },
                    "props": {
                        "type": "ButtonProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "align": "center",
                        "margin": 0
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg": "1",
                                "alpha-bgh": "1",
                                "alpha-brd": "1",
                                "alpha-brdh": "0",
                                "alpha-txt": "1",
                                "alpha-txth": "1",
                                "bg": "#FF8C62",
                                "bgh": "#FF8052",
                                "boxShadowToggleOn-shd": "true",
                                "brd": "#000000",
                                "brdh": "#FFFFFF",
                                "brw": "0px",
                                "fnt": "normal normal normal 12px/1.4em helvetica-w01-light",
                                "rd": "50px",
                                "shd": "0.00px 3.00px 0px 0px rgba(255,140,98,0.35)",
                                "txt": "#FFFFFF",
                                "txth": "#FFFFFF"
                            },
                            "propertiesSource": {
                                "alpha-brdh": "value",
                                "bg": "value",
                                "bgh": "value",
                                "brd": "value",
                                "brdh": "value",
                                "brw": "value",
                                "fnt": "value",
                                "rd": "value",
                                "shd": "value",
                                "txt": "value",
                                "txth": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.viewer.components.SiteButton",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.button.BasicButton"
                    },
                    "activeModes": {},
                    "id": "comp-jav6ylpw"
                },
                "preset": {
                    "rect": {
                        "width": 192,
                        "height": 70,
                        "x": 132,
                        "y": 714
                    },
                    "tags": "READ  MORE"
                }
            },
            {
                "id": "Button_Text_Buttons_25",
                "structure": {
                    "type": "Component",
                    "skin": "wysiwyg.viewer.skins.button.BasicButton",
                    "layout": {
                        "width": 69,
                        "height": 40,
                        "x": 141,
                        "y": 802,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.SiteButton",
                    "data": {
                        "type": "LinkableButton",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "label": i18n.translate('comp-jav6ylq4.label')
                    },
                    "props": {
                        "type": "ButtonProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "align": "center",
                        "margin": 0
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg": "1",
                                "alpha-bgd": "1",
                                "alpha-bgh": "1",
                                "alpha-brd": "1",
                                "alpha-brdd": "1",
                                "alpha-brdh": "0",
                                "bg": "#FFCBAA",
                                "bgd": "#CCCCCC",
                                "bgh": "#FFDEC9",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "#FFCBAA",
                                "brdd": "#CCCCCC",
                                "brdh": "#FFFFFF",
                                "brw": "0",
                                "fnt": "normal normal normal 15px/1.4em baskervillemtw01-smbdit",
                                "rd": "100px",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                "txt": "#000000",
                                "txtd": "#FFFFFF",
                                "txth": "#000000"
                            },
                            "propertiesSource": {
                                "alpha-bg": "value",
                                "alpha-bgd": "value",
                                "alpha-bgh": "value",
                                "alpha-brd": "value",
                                "alpha-brdd": "value",
                                "alpha-brdh": "value",
                                "bg": "value",
                                "bgd": "value",
                                "bgh": "value",
                                "boxShadowToggleOn-shd": "value",
                                "brd": "value",
                                "brdd": "value",
                                "brdh": "value",
                                "brw": "value",
                                "fnt": "value",
                                "rd": "value",
                                "shd": "value",
                                "txt": "value",
                                "txtd": "value",
                                "txth": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.viewer.components.SiteButton",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.button.BasicButton"
                    },
                    "activeModes": {},
                    "id": "comp-jav6ylq4"
                },
                "preset": {
                    "rect": {
                        "width": 87,
                        "height": 70,
                        "x": 132,
                        "y": 784
                    },
                    "tags": "Scroll"
                }
            },
            {
                "id": "Button_Text_Buttons_26",
                "structure": {
                    "type": "Component",
                    "skin": "wysiwyg.viewer.skins.button.BasicButton",
                    "layout": {
                        "width": 84,
                        "height": 40,
                        "x": 230,
                        "y": 802,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.SiteButton",
                    "data": {
                        "type": "LinkableButton",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "label": i18n.translate('comp-jav6ylq7.label')
                    },
                    "props": {
                        "type": "ButtonProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "align": "center",
                        "margin": 0
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg": "1",
                                "alpha-bgh": "1",
                                "alpha-brd": "1",
                                "alpha-brdh": "0",
                                "alpha-txt": "1",
                                "alpha-txth": "1",
                                "bg": "#FFCBAA",
                                "bgh": "#FFB98C",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "#FFCBAA",
                                "brdh": "#FFFFFF",
                                "brw": "0px",
                                "fnt": "normal normal normal 17px/1.4em dinneuzeitgroteskltw01-_812426",
                                "rd": "0px",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                "txt": "#000000",
                                "txth": "#000000"
                            },
                            "propertiesSource": {
                                "alpha-bgh": "value",
                                "alpha-brdh": "value",
                                "bg": "value",
                                "bgh": "value",
                                "brd": "value",
                                "brdh": "value",
                                "brw": "value",
                                "fnt": "value",
                                "rd": "value",
                                "shd": "value",
                                "txt": "value",
                                "txth": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.viewer.components.SiteButton",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.button.BasicButton"
                    },
                    "activeModes": {},
                    "id": "comp-jav6ylq7"
                },
                "preset": {
                    "rect": {
                        "width": 105,
                        "height": 70,
                        "x": 219,
                        "y": 784
                    },
                    "tags": "Try Me"
                }
            },
            {
                "id": "Button_Text_Buttons_27",
                "structure": {
                    "type": "Component",
                    "skin": "wysiwyg.viewer.skins.button.BasicButton",
                    "layout": {
                        "width": 92,
                        "height": 40,
                        "x": 11,
                        "y": 872,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.SiteButton",
                    "data": {
                        "type": "LinkableButton",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "label": i18n.translate('comp-jav6ylqb.label')
                    },
                    "props": {
                        "type": "ButtonProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "align": "center",
                        "margin": 0
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg": "1",
                                "alpha-bgh": "1",
                                "alpha-brd": "1",
                                "alpha-brdh": "0",
                                "alpha-txt": "1",
                                "alpha-txth": "1",
                                "bg": "#7B736F",
                                "bgh": "#59514D",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "#7B736F",
                                "brdh": "#FFFFFF",
                                "brw": "0",
                                "fnt": "normal normal normal 13px/1.4em futura-lt-w01-book",
                                "rd": "100px",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                "txt": "#FFFFFF",
                                "txth": "#FFFFFF"
                            },
                            "propertiesSource": {
                                "alpha-brdh": "value",
                                "bg": "value",
                                "bgh": "value",
                                "brd": "value",
                                "brdh": "value",
                                "brw": "value",
                                "fnt": "value",
                                "rd": "value",
                                "shd": "value",
                                "txt": "value",
                                "txth": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.viewer.components.SiteButton",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.button.BasicButton"
                    },
                    "activeModes": {},
                    "id": "comp-jav6ylqb"
                },
                "preset": {
                    "rect": {
                        "width": 113,
                        "height": 70,
                        "x": 0,
                        "y": 854
                    },
                    "tags": "Go Up"
                }
            },
            {
                "id": "Button_Text_Buttons_28",
                "structure": {
                    "type": "Component",
                    "skin": "wysiwyg.viewer.skins.button.BasicButton",
                    "layout": {
                        "width": 190,
                        "height": 40,
                        "x": 124,
                        "y": 872,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.SiteButton",
                    "data": {
                        "type": "LinkableButton",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "label": i18n.translate('comp-jav6ylqf.label')
                    },
                    "props": {
                        "type": "ButtonProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "align": "center",
                        "margin": 0
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg": "0",
                                "alpha-bgh": "1",
                                "alpha-brd": "1",
                                "alpha-brdh": "0",
                                "alpha-txt": "1",
                                "alpha-txth": "1",
                                "bg": "#FFFFFF",
                                "bgh": "#7B736F",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "#7B736F",
                                "brdh": "#4A3F37",
                                "brw": "2",
                                "fnt": "normal normal normal 10px/1.4em lulo-clean-w01-one-bold",
                                "rd": "0px",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                "txt": "#59514D",
                                "txth": "#FFFFFF"
                            },
                            "propertiesSource": {
                                "alpha-bg": "value",
                                "alpha-brdh": "value",
                                "bg": "value",
                                "bgh": "value",
                                "brd": "value",
                                "brdh": "value",
                                "brw": "value",
                                "fnt": "value",
                                "rd": "value",
                                "shd": "value",
                                "txt": "value",
                                "txth": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.viewer.components.SiteButton",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.button.BasicButton"
                    },
                    "activeModes": {},
                    "id": "comp-jav6ylqf"
                },
                "preset": {
                    "rect": {
                        "width": 211,
                        "height": 70,
                        "x": 113,
                        "y": 854
                    },
                    "tags": "VIEW OUR WORK"
                }
            },
            {
                "id": "Button_Text_Buttons_29",
                "structure": {
                    "type": "Component",
                    "skin": "wysiwyg.viewer.skins.button.CircleButton",
                    "layout": {
                        "width": 40,
                        "height": 40,
                        "x": 11,
                        "y": 942,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.SiteButton",
                    "data": {
                        "type": "LinkableButton",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "label": "+"
                    },
                    "props": {
                        "type": "ButtonProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "align": "center",
                        "margin": 0
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg": "1",
                                "alpha-bgd": "1",
                                "alpha-bgh": "1",
                                "alpha-brd": "1",
                                "alpha-brdd": "1",
                                "alpha-brdh": "1",
                                "bg": "#E6DECA",
                                "bgd": "#CCCCCC",
                                "bgh": "#F2EDDF",
                                "boxShadowToggleOn-shd": "true",
                                "brd": "#7B736F",
                                "brdd": "#CCCCCC",
                                "brdh": "#4A3F37",
                                "brw": "0",
                                "fnt": "normal normal normal 18px/1.4em times+new+roman",
                                "rd": "50%",
                                "shd": "0.00px 2.00px 4px 0px rgba(0,0,0,0.2)",
                                "txt": "#000000",
                                "txtd": "#FFFFFF",
                                "txth": "#59514D"
                            },
                            "propertiesSource": {
                                "alpha-bg": "value",
                                "alpha-bgd": "value",
                                "alpha-bgh": "value",
                                "alpha-brd": "value",
                                "alpha-brdd": "value",
                                "alpha-brdh": "value",
                                "bg": "value",
                                "bgd": "value",
                                "bgh": "value",
                                "boxShadowToggleOn-shd": "value",
                                "brd": "value",
                                "brdd": "value",
                                "brdh": "value",
                                "brw": "value",
                                "fnt": "value",
                                "rd": "value",
                                "shd": "value",
                                "txt": "value",
                                "txtd": "value",
                                "txth": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.viewer.components.SiteButton",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.button.CircleButton"
                    },
                    "activeModes": {},
                    "id": "comp-jav6ylqo"
                },
                "preset": {
                    "rect": {
                        "width": 63,
                        "height": 74,
                        "x": 0,
                        "y": 924
                    },
                    "tags": "+"
                }
            },
            {
                "id": "Button_Text_Buttons_30",
                "structure": {
                    "type": "Component",
                    "skin": "wysiwyg.viewer.skins.button.ShinyButtonInverted",
                    "layout": {
                        "width": 240,
                        "height": 40,
                        "x": 73,
                        "y": 942,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.SiteButton",
                    "data": {
                        "type": "LinkableButton",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "label": i18n.translate('comp-jav6ylqj.label')
                    },
                    "props": {
                        "type": "ButtonProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "align": "center",
                        "margin": 0
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg": "1",
                                "alpha-bgd": "1",
                                "alpha-bgh": "1",
                                "alpha-brd": "1",
                                "alpha-brdd": "1",
                                "alpha-brdh": "0",
                                "bgd": "#CCCCCC",
                                "bg": "#E6DECA",
                                "bgh": "#CCC3AD",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "#7B736F",
                                "brdd": "#CCCCCC",
                                "brw": "0",
                                "fnt": "normal normal normal 16px/1.4em clarendon-w01-medium-692107",
                                "rd": "0",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                "txt": "#000000",
                                "txtd": "#FFFFFF",
                                "txth": "#000000"
                            },
                            "propertiesSource": {
                                "alpha-bg": "value",
                                "alpha-bgd": "value",
                                "alpha-bgh": "value",
                                "alpha-brd": "value",
                                "alpha-brdd": "value",
                                "alpha-brdh": "value",
                                "bg": "value",
                                "bgd": "value",
                                "bgh": "value",
                                "boxShadowToggleOn-shd": "value",
                                "brd": "value",
                                "brdd": "value",
                                "brdh": "value",
                                "brw": "value",
                                "fnt": "value",
                                "rd": "value",
                                "shd": "value",
                                "txt": "value",
                                "txtd": "value",
                                "txth": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.viewer.components.SiteButton",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.button.BasicButton"
                    },
                    "activeModes": {},
                    "id": "comp-jav6ylqj"
                },
                "preset": {
                    "rect": {
                        "width": 261,
                        "height": 74,
                        "x": 63,
                        "y": 924
                    },
                    "tags": "See Our Services"
                }
            },
            {
                "id": "Button_Text_Buttons_31",
                "structure": {
                    "type": "Component",
                    "skin": "wysiwyg.viewer.skins.button.BasicButton",
                    "layout": {
                        "width": 170,
                        "height": 42,
                        "x": 10,
                        "y": 1012,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.SiteButton",
                    "data": {
                        "type": "LinkableButton",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "label": i18n.translate('comp-jav6ylqw.label')
                    },
                    "props": {
                        "type": "ButtonProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "align": "center",
                        "margin": 0
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg": "1",
                                "alpha-bgh": "1",
                                "alpha-brd": "1",
                                "alpha-brdh": "1",
                                "alpha-txt": "1",
                                "alpha-txth": "1",
                                "bg": "#20CE88",
                                "bgh": "#1DB578",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "#20CE88",
                                "brdh": "#1DB578",
                                "brw": "0px",
                                "fnt": "normal normal normal 14px/1.4em arial",
                                "rd": "0px",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                "txt": "#FFFFFF",
                                "txth": "#FFFFFF"
                            },
                            "propertiesSource": {
                                "bg": "value",
                                "bgh": "value",
                                "brd": "value",
                                "brdh": "value",
                                "brw": "value",
                                "fnt": "value",
                                "rd": "value",
                                "shd": "value",
                                "txt": "value",
                                "txth": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.viewer.components.SiteButton",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.button.BasicButton"
                    },
                    "activeModes": {},
                    "id": "comp-jav6ylqw"
                },
                "preset": {
                    "rect": {
                        "width": 191,
                        "height": 70,
                        "x": 0,
                        "y": 998
                    },
                    "tags": "Donate Now!"
                }
            },
            {
                "id": "Button_Text_Buttons_32",
                "structure": {
                    "type": "Component",
                    "skin": "wysiwyg.viewer.skins.button.BasicButton",
                    "layout": {
                        "width": 112,
                        "height": 112,
                        "x": 201,
                        "y": 1012,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.SiteButton",
                    "data": {
                        "type": "LinkableButton",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "label": i18n.translate('comp-jav6ylqs.label')
                    },
                    "props": {
                        "type": "ButtonProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "align": "center",
                        "margin": 0
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg": "1",
                                "alpha-bgh": "1",
                                "alpha-brd": "1",
                                "alpha-brdh": "0",
                                "alpha-txt": "1",
                                "alpha-txth": "1",
                                "bg": "#C7F7D8",
                                "bgh": "#A9EBC0",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "#C7F7D8",
                                "brdh": "#BCF5D0",
                                "brw": "0px",
                                "fnt": "normal normal normal 17px/1.4em dinneuzeitgroteskltw01-_812426",
                                "rd": "0px",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                "txt": "#000000",
                                "txth": "#000000"
                            },
                            "propertiesSource": {
                                "alpha-brdh": "value",
                                "bg": "value",
                                "bgh": "value",
                                "brd": "value",
                                "brdh": "value",
                                "brw": "value",
                                "fnt": "value",
                                "rd": "value",
                                "shd": "value",
                                "txt": "value",
                                "txth": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.viewer.components.SiteButton",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.button.BasicButton"
                    },
                    "activeModes": {},
                    "id": "comp-jav6ylqs"
                },
                "preset": {
                    "rect": {
                        "width": 133,
                        "height": 154,
                        "x": 191,
                        "y": 998
                    },
                    "tags": "S T A R T"
                }
            },
            {
                "id": "Button_Text_Buttons_33",
                "structure": {
                    "type": "Component",
                    "skin": "wysiwyg.viewer.skins.button.BasicButton",
                    "layout": {
                        "width": 170,
                        "height": 40,
                        "x": 10,
                        "y": 1084,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.SiteButton",
                    "data": {
                        "type": "LinkableButton",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "label": i18n.translate('comp-jav6ylr0.label')
                    },
                    "props": {
                        "type": "ButtonProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "align": "center",
                        "margin": 0
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-bg": "0",
                                "alpha-bgh": "1",
                                "alpha-brd": "1",
                                "alpha-brdh": "1",
                                "alpha-txt": "1",
                                "alpha-txth": "1",
                                "bg": "#FFFFFF",
                                "bgh": "#20CE88",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "#20CE88",
                                "brdh": "#20CE88",
                                "brw": "2",
                                "fnt": "normal normal normal 13px/1.4em proxima-n-w01-reg",
                                "rd": "10px",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                "txt": "#1DB578",
                                "txth": "#FFFFFF"
                            },
                            "propertiesSource": {
                                "alpha-bg": "value",
                                "bg": "value",
                                "bgh": "value",
                                "brd": "value",
                                "brdh": "value",
                                "brw": "value",
                                "fnt": "value",
                                "rd": "value",
                                "shd": "value",
                                "txt": "value",
                                "txth": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.viewer.components.SiteButton",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.button.BasicButton"
                    },
                    "activeModes": {},
                    "id": "comp-jav6ylr0"
                },
                "preset": {
                    "rect": {
                        "width": 191,
                        "height": 82,
                        "x": 0,
                        "y": 1069
                    },
                    "tags": "Take a Tour"
                }
            }
        ],
        "compTypes": ["wysiwyg.viewer.components.SiteButton"]
    },
    "help": {"hide": false, "text": "add_section_info_text_textbutton"}
}
