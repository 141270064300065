'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')
const util = require('@/util')

module.exports = {
    "type": addPanelDataConsts.SECTIONS_TYPES.PRESET,
    "hide": false,
    "title": "ADD_SECTION_label_table",
    "subNavigationTitle": "ADD_SECTION_label_table",
    "topTitle": "add_section_title_table",
    "presetTitle": "add_section_heading_table",
    "tooltipTitle": "add_section_label_table_tooltip_title",
    "automationId": "add-panel-section-gridSection",
    "subNavigationHide": false,
    "showSectionHeader": true,
    "additionalBehaviours": {
        "labelMode": addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
        "infoIcon": addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
        "hoverImageAction": addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
        "iconEnabledForComps": {}
    },
    "props": {
        "image": `addPanelData/sections/developer_${util.languages.getLanguageCode()}/gridSection_${util.languages.getLanguageCode()}.png`,
        "imageHover": null,
        "items": [{
            "id": "User_Input_Grid_1",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.GridRightHeaderSkin",
                "layout": {
                    "width": 809,
                    "height": 207,
                    "x": 85,
                    "y": 80,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.Grid",
                "data": {
                    "type": "Grid",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "dataSource": {
                        "type": "static",
                        "revision": 0
                    },
                    "revision": 0,
                    "rows": [{
                        "tour_dates": "Fri., June 20",
                        "location": "The Academy, L.A",
                        "tickets": "Book It"
                    },
                    {
                        "tour_dates": "Thu., June 19",
                        "location": "Bamboo, Santa Barbara",
                        "tickets": "Book It"
                    },
                    {
                        "tour_dates": "Sat., June 28",
                        "location": "Cheers, Santa Cruz",
                        "tickets": "Book It"
                    },
                    {
                        "tour_dates": "Wed., July 6",
                        "location": "The Roxy, San Francisco",
                        "tickets": "Book It"
                    }]
                },
                "props": {
                    "type": "GridProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "isHidden": false,
                    "isCollapsed": false,
                    "columns": [{
                        "id": "columnId_a30b84db-1c0f-4c4c-8593-295364f32064",
                        "dataPath": "tour_dates",
                        "label": "2016 Tour Dates",
                        "width": 100,
                        "visible": true,
                        "type": "string"
                    }, {
                        "id": "columnId_7f1fa6d0-1c2f-4fb8-b742-23c58812cb01",
                        "dataPath": "location",
                        "label": "Location",
                        "width": 100,
                        "visible": true,
                        "type": "string"
                    }, {
                        "id": "column_iwooe4gr",
                        "dataPath": "tickets",
                        "linkPath": "tickets",
                        "label": "Tickets",
                        "width": 100,
                        "visible": true,
                        "type": "string"
                    }],
                    "headerHeight": 53,
                    "rowHeight": 50,
                    "allowUserSorting": true,
                    "allowUserFiltering": true,
                    "allowUserEditing": true,
                    "pagination": {
                        "type": "virtual",
                        "rowsPerPage": 200
                    },
                    "userSelectionType": "row",
                    "dateFormat": "dd/mm/yyyy",
                    "linkTarget": "_blank",
                    "heightLayout": "manual",
                    "showHeader": true,
                    "columnLayout": "equal",
                    "horizontalAlignment": "center"
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-columnDivider": 1,
                            "alpha-containerBg": 1,
                            "alpha-containerBrd": 1,
                            "alpha-headerBg": 1,
                            "alpha-headerColumnBg": 0.1,
                            "alpha-headerDivider": 0,
                            "alpha-row2Bg": 0,
                            "alpha-rowBg": 0,
                            "alpha-rowDivider": 0,
                            "alpha-rowOrCellHoverBg": 0.1,
                            "alpha-selection": 0.2,
                            "boxShadowToggleOn-shd": "false",
                            "cellPadding": "0",
                            "columnDivider": "#7E60DF",
                            "columnDivider_w": "2",
                            "containerBg": "#FFFFFF",
                            "containerBrd": "#7E60DF",
                            "containerBrw": "2",
                            "headerBg": "#7E60DF",
                            "headerColumnBg": "#7E60DF",
                            "headerColumnFont": "normal normal normal 15px/1.4em futura-lt-w01-light",
                            "headerColumnText": "#000000",
                            "headerDivider": "#FFFFFF",
                            "headerDivider_w": "0",
                            "headerFont": "normal normal normal 16px/1.4em futura-lt-w01-book",
                            "headerText": "#FFFFFF",
                            "linkColor": "#7E60DF",
                            "linkHoverColor": "#331fDD",
                            "row2Bg": "#FFFFFF",
                            "rowBg": "#FFFFFF",
                            "rowDivider": "#FFFFFF",
                            "rowDivider_w": "0",
                            "rowFont": "normal normal normal 14px/1.4em futura-lt-w01-light",
                            "rowOrCellHoverBg": "#7E60DF",
                            "rowText": "#000000",
                            "selection": "#7E60DF",
                            "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                        },
                        "propertiesSource": {
                            "alpha-columnDivider": "value",
                            "alpha-headerColumnBg": "value",
                            "alpha-headerDivider": "value",
                            "alpha-row2Bg": "value",
                            "alpha-rowBg": "value",
                            "alpha-rowOrCellHoverBg": "value",
                            "alpha-selection": "value",
                            "cellPadding": "value",
                            "columnDivider": "value",
                            "columnDivider_w": "value",
                            "containerBg": "value",
                            "containerBrd": "value",
                            "containerBrw": "value",
                            "headerBg": "value",
                            "headerColumnBg": "value",
                            "headerColumnFont": "value",
                            "headerColumnText": "value",
                            "headerDivider": "value",
                            "headerDivider_w": "value",
                            "headerFont": "value",
                            "headerText": "value",
                            "linkColor": "value",
                            "linkHoverColor": "value",
                            "paginationColor": "value",
                            "row2Bg": "value",
                            "rowDivider": "value",
                            "rowDivider_w": "value",
                            "rowFont": "value",
                            "rowText": "value",
                            "selection": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.Grid",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.GridRightHeaderSkin"
                },
                "activeModes": {},
                "id": "comp-iy2u0c9u"
            },
            "preset": {
                "rect": {
                    "width": 324,
                    "height": 155,
                    "x": 0,
                    "y": 0
                },
                "tags": null
            }
        },
        {
            "id": "User_Input_Grid_2",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.GridSkin",
                "layout": {
                    "width": 800,
                    "height": 210,
                    "x": 90,
                    "y": 461,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.Grid",
                "data": {
                    "type": "Grid",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "dataSource": {
                        "type": "static",
                        "revision": 0
                    },
                    "revision": 0,
                    "rows": [{
                        "name": "Katie",
                        "pickup_time": "4:30pm",
                        "allergies": "Kale",
                        "age": "3"
                    },
                    {
                        "name": "Nicki",
                        "age": "2",
                        "allergies": "-",
                        "pickup_time": "4:00pm"
                    },
                    {
                        "name": "Dwayne",
                        "age": "2",
                        "allergies": "Eggs & Gluten",
                        "pickup_time": "3:30pm"
                    },
                    {
                        "name": "Bob",
                        "age": "3",
                        "allergies": "-",
                        "pickup_time": "4:30pm"
                    }]
                },
                "props": {
                    "type": "GridProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "columns": [{
                        "id": "columnId_336e8cf5-9ba5-4276-8164-132e32256dca",
                        "dataPath": "name",
                        "label": "KID'S NAME",
                        "visible": true,
                        "type": "string"
                    }, {
                        "id": "columnId_7f600ee7-c616-4484-8121-c931f34f0c99",
                        "dataPath": "age",
                        "label": "AGE",
                        "visible": true,
                        "type": "string"
                    }, {
                        "id": "columnId_3ae207e4-420b-4d2a-a60e-581f9de97b6b",
                        "dataPath": "allergies",
                        "label": "ALLERGIES   ",
                        "visible": true,
                        "type": "string"
                    }, {
                        "id": "columnId_2a8bc81f-d220-42e7-9a83-89c6e54d7d0c",
                        "dataPath": "pickup_time",
                        "label": "PICKUP TIME ",
                        "visible": true,
                        "type": "string"
                    }],
                    "headerHeight": 58,
                    "rowHeight": 50,
                    "allowUserSorting": true,
                    "allowUserFiltering": true,
                    "allowUserEditing": true,
                    "pagination": {
                        "type": "virtual",
                        "rowsPerPage": 200
                    },
                    "userSelectionType": "row",
                    "dateFormat": "dd/mm/yyyy",
                    "linkTarget": "_blank",
                    "heightLayout": "manual",
                    "showHeader": true,
                    "columnLayout": "equal",
                    "horizontalAlignment": "center"
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-columnDivider": 0.5,
                            "alpha-containerBg": 1,
                            "alpha-containerBrd": 0,
                            "alpha-headerBg": 0,
                            "alpha-headerDivider": 0.5,
                            "alpha-row2Bg": 0,
                            "alpha-rowBg": 0,
                            "alpha-rowDivider": 0,
                            "alpha-rowOrCellHoverBg": 0.1,
                            "alpha-selection": 0.3,
                            "boxShadowToggleOn-shd": "false",
                            "columnDivider": "#FFFFFF",
                            "columnDivider_w": "2",
                            "containerBg": "#2BD664",
                            "containerBrd": "#FFFFFF",
                            "containerBrw": "0",
                            "headerBg": "#000000",
                            "headerDivider": "#FFFFFF",
                            "headerDivider_w": "2",
                            "headerFont": "normal normal normal 18px/1.4em dinneuzeitgroteskltw01-_812426",
                            "headerText": "#FFFFFF",
                            "rd": "12px",
                            "row2Bg": "#FFFFFF",
                            "rowBg": "#FFFFFF",
                            "rowDivider": "#000000",
                            "rowDivider_w": "0",
                            "rowFont": "normal normal normal 14px/1.4em bree-w01-thin-oblique",
                            "rowOrCellHoverBg": "#FFFFFF",
                            "rowText": "#FFFFFF",
                            "linkColor": "#FFFFFF",
                            "linkHoverColor": "#E5FAD1",
                            "selection": "#FFFFFF",
                            "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                        },
                        "propertiesSource": {
                            "alpha-columnDivider": "value",
                            "alpha-containerBrd": "value",
                            "alpha-headerBg": "value",
                            "alpha-headerDivider": "value",
                            "alpha-row2Bg": "value",
                            "alpha-rowBg": "value",
                            "alpha-rowDivider": "value",
                            "alpha-rowOrCellHoverBg": "value",
                            "alpha-selection": "value",
                            "columnDivider": "value",
                            "columnDivider_w": "value",
                            "containerBg": "value",
                            "containerBrd": "value",
                            "containerBrw": "value",
                            "headerBg": "value",
                            "headerDivider": "value",
                            "headerDivider_w": "value",
                            "headerFont": "value",
                            "headerText": "value",
                            "rd": "value",
                            "row2Bg": "value",
                            "rowDivider": "value",
                            "rowDivider_w": "value",
                            "rowFont": "value",
                            "rowText": "value",
                            "selection": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.Grid",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.GridSkin"
                },
                "activeModes": {},
                "id": "comp-iwors1eo"
            },
            "preset": {
                "rect": {
                    "width": 324,
                    "height": 125,
                    "x": 0,
                    "y": 156
                },
                "tags": null
            }
        },
        {
            "id": "User_Input_Grid_3",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.GridSkin",
                "layout": {
                    "width": 800,
                    "height": 192,
                    "x": 90,
                    "y": 843,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.Grid",
                "data": {
                    "type": "Grid",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "revision": 0,
                    "dataSource": {
                        "type": "static",
                        "revision": 0
                    },
                    "rows": [{
                        "event": "ZUMBA - JOANNE GREEN",
                        "location": "STUDIO 2",
                        "date": "TUE. AT 7:00 PM"
                    },
                    {
                        "event": "BALLET - KAREN KING",
                        "location": "STUDIO 1",
                        "date": "TUE. AT 6:00 PM"
                    },
                    {
                        "event": "HIP HOP - RON JAKE",
                        "location": "STUDIO 2",
                        "date": "TUE. AT 5:00 PM"
                    },
                    {
                        "event": "JAZZ - ACE SILVERSTEIN ",
                        "location": "STUDIO 1",
                        "date": "TUE. AT 4:30 PM"
                    }]
                },
                "props": {
                    "type": "GridProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "columns": [{
                        "id": "columnId_0d42e032-617e-4779-96f9-946415294e82",
                        "dataPath": "event",
                        "label": "Event",
                        "visible": true,
                        "type": "string"
                    }, {
                        "id": "columnId_1f6a66f9-5b68-4f98-902e-53c3de9443e9",
                        "dataPath": "location",
                        "label": "Location",
                        "visible": true,
                        "type": "string"
                    }, {
                        "id": "columnId_1c3065cd-db1f-42cd-ac57-916ba5feba68",
                        "dataPath": "date",
                        "label": "Date",
                        "visible": true,
                        "type": "string"
                    }],
                    "headerHeight": 58,
                    "rowHeight": 64,
                    "allowUserSorting": true,
                    "allowUserFiltering": true,
                    "allowUserEditing": true,
                    "pagination": {
                        "type": "virtual",
                        "rowsPerPage": 200
                    },
                    "userSelectionType": "row",
                    "dateFormat": "dd/mm/yyyy",
                    "linkTarget": "_blank",
                    "heightLayout": "manual",
                    "showHeader": false,
                    "columnLayout": "equal",
                    "horizontalAlignment": "center"
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-columnDivider": 0,
                            "alpha-containerBg": 1,
                            "alpha-containerBrd": 0,
                            "alpha-headerBg": 0,
                            "alpha-headerDivider": 0,
                            "alpha-row2Bg": 0,
                            "alpha-rowBg": 1,
                            "alpha-rowDivider": 0,
                            "alpha-rowOrCellHoverBg": 0.1,
                            "alpha-selection": 0.3,
                            "boxShadowToggleOn-shd": "false",
                            "columnDivider": "#FFFFFF",
                            "columnDivider_w": "2",
                            "containerBg": "#E67A83",
                            "containerBrd": "#FFFFFF",
                            "containerBrw": "0",
                            "headerBg": "#000000",
                            "headerDivider": "#FFFFFF",
                            "headerDivider_w": "2",
                            "headerFont": "normal normal normal 16px/1.4em brandon-grot-w01-light",
                            "headerText": "#FFFFFF",
                            "rd": "0px",
                            "row2Bg": "#FFFFFF",
                            "rowBg": "#E94D5B",
                            "rowDivider": "#000000",
                            "rowDivider_w": "0",
                            "rowFont": "normal normal normal 12px/1.4em brandon-grot-w01-light",
                            "rowOrCellHoverBg": "#FFFFFF",
                            "rowText": "#FFFFFF",
                            "linkColor": "#FFFFFF",
                            "linkHoverColor": "#FFE0E3",
                            "selection": "#FFFFFF",
                            "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                        },
                        "propertiesSource": {
                            "alpha-columnDivider": "value",
                            "alpha-containerBrd": "value",
                            "alpha-headerBg": "value",
                            "alpha-headerDivider": "value",
                            "alpha-row2Bg": "value",
                            "alpha-rowBg": "value",
                            "alpha-rowDivider": "value",
                            "alpha-rowOrCellHoverBg": "value",
                            "alpha-selection": "value",
                            "columnDivider": "value",
                            "columnDivider_w": "value",
                            "containerBg": "value",
                            "containerBrd": "value",
                            "containerBrw": "value",
                            "headerBg": "value",
                            "headerDivider": "value",
                            "headerDivider_w": "value",
                            "headerFont": "value",
                            "headerText": "value",
                            "rd": "value",
                            "row2Bg": "value",
                            "rowBg": "value",
                            "rowDivider": "value",
                            "rowDivider_w": "value",
                            "rowFont": "value",
                            "rowText": "value",
                            "selection": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.Grid",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.GridSkin"
                },
                "activeModes": {},
                "id": "comp-iwoxm93g"
            },
            "preset": {
                "rect": {
                    "width": 324,
                    "height": 152,
                    "x": 0,
                    "y": 279
                },
                "tags": null
            }
        },
        {
            "id": "User_Input_Grid_4",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.GridSkin",
                "layout": {
                    "width": 800,
                    "height": 304,
                    "x": 90,
                    "y": 1221,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.Grid",
                "data": {
                    "type": "Grid",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "dataSource": {
                        "type": "static",
                        "revision": 0
                    },
                    "revision": 0,
                    "rows": [{
                        "profile_pic": "image://v1/035244_e6cfd4f752c34a3dac642adef1d78d64~mv2.jpg/49_48/1.jpg",
                        "candidate": "Robert Zanny",
                        "applied": "12/16",
                        "num_of_positions": "2",
                        "email": "robert@gmail.com"
                    },
                    {
                        "profile_pic": "image://v1/035244_ef63ed9945c1456b86285231f3445e23~mv2.jpg/49_48/4.jpg",
                        "candidate": "Dana Marks",
                        "applied": "09/16",
                        "num_of_positions": "1",
                        "email": "dana@gmail.com"
                    },
                    {
                        "profile_pic": "image://v1/035244_26db63958af94c2ea8cfa3b7068b2e00~mv2.jpg/49_48/2.jpg",
                        "candidate": "Robert Zanny",
                        "applied": "10/15",
                        "num_of_positions": "3",
                        "email": "betty@gmail.com"
                    }]
                },
                "props": {
                    "type": "GridProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "isHidden": false,
                    "isCollapsed": false,
                    "columns": [{
                        "id": "columnId_1ec34d9f-4dd4-4505-ac93-c6ac3067fdd6",
                        "dataPath": "profile_pic",
                        "label": "Profile Pic ",
                        "visible": true,
                        "type": "image"
                    }, {
                        "id": "columnId_bf173e9d-7400-45ec-ba03-6913ea248c16",
                        "dataPath": "candidate",
                        "label": "Candidate",
                        "visible": true,
                        "type": "string"
                    }, {
                        "id": "columnId_efcaec2b-83a9-47a6-a336-05aa5990876c",
                        "dataPath": "applied",
                        "label": "Applied",
                        "visible": true,
                        "type": "string"
                    }, {
                        "id": "column_iwovrgb3",
                        "dataPath": "num_of_positions",
                        "label": "# of Positions ",
                        "width": 100,
                        "visible": true,
                        "type": "string"
                    }, {
                        "id": "column_iwovrqc6",
                        "dataPath": "email",
                        "label": "Email",
                        "width": 100,
                        "visible": true,
                        "type": "string"
                    }],
                    "headerHeight": 60,
                    "rowHeight": 80,
                    "allowUserSorting": true,
                    "allowUserFiltering": true,
                    "allowUserEditing": true,
                    "pagination": {
                        "type": "virtual",
                        "rowsPerPage": 200
                    },
                    "userSelectionType": "row",
                    "dateFormat": "dd/mm/yyyy",
                    "linkTarget": "_blank",
                    "heightLayout": "manual",
                    "showHeader": true,
                    "columnLayout": "equal",
                    "horizontalAlignment": "center"
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-columnDivider": 0,
                            "alpha-containerBg": 1,
                            "alpha-containerBrd": 1,
                            "alpha-headerBg": 1,
                            "alpha-headerDivider": 0,
                            "alpha-row2Bg": 0,
                            "alpha-rowBg": 0,
                            "alpha-rowDivider": 0,
                            "alpha-rowOrCellHoverBg": 0.05,
                            "alpha-selection": 0.1,
                            "boxShadowToggleOn-shd": "false",
                            "columnDivider": "#000000",
                            "columnDivider_w": "2",
                            "containerBg": "#FFFFFF",
                            "containerBrd": "#000000",
                            "containerBrw": "2",
                            "headerBg": "#000000",
                            "headerDivider": "#000000",
                            "headerDivider_w": "0",
                            "headerFont": "normal normal normal 14px/1.4em helvetica-w01-light",
                            "headerText": "#FFFFFF",
                            "row2Bg": "#FFFFFF",
                            "rowBg": "#FFFFFF",
                            "rowDivider": "#FFFFFF",
                            "rowDivider_w": "0",
                            "rowFont": "normal normal normal 14px/1.4em helvetica-w01-light",
                            "rowOrCellHoverBg": "#000000",
                            "rowText": "#000000",
                            "selection": "#000000",
                            "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                        },
                        "propertiesSource": {
                            "alpha-columnDivider": "value",
                            "alpha-headerDivider": "value",
                            "alpha-row2Bg": "value",
                            "alpha-rowBg": "value",
                            "alpha-rowDivider": "value",
                            "alpha-rowOrCellHoverBg": "value",
                            "alpha-selection": "value",
                            "columnDivider": "value",
                            "columnDivider_w": "value",
                            "containerBg": "value",
                            "containerBrd": "value",
                            "containerBrw": "value",
                            "headerBg": "value",
                            "headerDivider": "value",
                            "headerDivider_w": "value",
                            "headerFont": "value",
                            "headerText": "value",
                            "row2Bg": "value",
                            "rowDivider": "value",
                            "rowDivider_w": "value",
                            "rowFont": "value",
                            "rowOrCellHoverBg": "value",
                            "rowText": "value",
                            "selection": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.Grid",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.GridSkin"
                },
                "activeModes": {},
                "id": "comp-iwovk6a8"
            },
            "preset": {
                "rect": {
                    "width": 324,
                    "height": 150,
                    "x": 0,
                    "y": 430
                },
                "tags": null
            }
        },
        {
            "id": "User_Input_Grid_5",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.GridSkin",
                "layout": {
                    "width": 800,
                    "height": 248,
                    "x": 90,
                    "y": 1645,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.Grid",
                "data": {
                    "type": "Grid",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "dataSource": {
                        "type": "static",
                        "revision": 0
                    },
                    "revision": 0,
                    "rows": [{
                        "city": "Sydney",
                        "temperature": "25°",
                        "weather": "image://v1/035244_70de022d08714fbbb4a96d5d7c5eb141~mv2.png/50_39/icon-02.png",
                        "humidity": "65%"
                    }, {
                        "city": "New York",
                        "temperature": "14°",
                        "weather": "image://v1/035244_70170dc564c44b809a0229c83e124755~mv2.png/40_24/icon-03.png",
                        "humidity": "30%"
                    }, {
                        "city": "Prague",
                        "temperature": "-2°",
                        "weather": "image://v1/035244_fc84e38d112e4ec3bafe957406eda213~mv2.png/40_35/icon-01.png",
                        "humidity": "15%"
                    }]
                },
                "props": {
                    "type": "GridProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "columns": [{
                        "id": "columnId_5eee86a6-405f-4c9a-802d-3051916315eb",
                        "dataPath": "city",
                        "label": "City",
                        "visible": true,
                        "type": "string"
                    }, {
                        "id": "columnId_b2b026e3-d62f-4a88-87d9-495bb9a21250",
                        "dataPath": "temperature",
                        "label": "Temperature",
                        "visible": true,
                        "type": "string"
                    }, {
                        "id": "columnId_06d9dfb1-84a7-4640-bda9-8a2caa2326e9",
                        "dataPath": "weather",
                        "label": "Weather",
                        "visible": true,
                        "type": "image"
                    }, {
                        "id": "columnId_15c5d9d6-103e-4a1c-a01a-e41d2d9af645",
                        "dataPath": "humidity",
                        "label": "Humidity",
                        "visible": true,
                        "type": "string"
                    }],
                    "headerHeight": 62,
                    "rowHeight": 62,
                    "allowUserSorting": true,
                    "allowUserFiltering": true,
                    "allowUserEditing": true,
                    "pagination": {
                        "type": "virtual",
                        "rowsPerPage": 200
                    },
                    "userSelectionType": "row",
                    "dateFormat": "dd/mm/yyyy",
                    "linkTarget": "_blank",
                    "heightLayout": "manual",
                    "showHeader": true,
                    "columnLayout": "equal",
                    "horizontalAlignment": "center"
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-columnDivider": 0.2,
                            "alpha-containerBg": 1,
                            "alpha-containerBrd": 0,
                            "alpha-headerBg": 0,
                            "alpha-headerDivider": 0,
                            "alpha-row2Bg": 0,
                            "alpha-rowBg": 0,
                            "alpha-rowDivider": 0,
                            "alpha-rowOrCellHoverBg": 0.1,
                            "alpha-selection": 0.2,
                            "boxShadowToggleOn-shd": "false",
                            "columnDivider": "#FFFFFF",
                            "columnDivider_w": "2",
                            "containerBg": "#0066FF",
                            "containerBrd": "#FFFFFF",
                            "containerBrw": "0",
                            "headerBg": "#000000",
                            "headerDivider": "#FFFFFF",
                            "headerDivider_w": "0",
                            "headerFont": "normal normal normal 16px/1.4em avenir-lt-w01_85-heavy1475544",
                            "headerText": "#FFC233",
                            "linkColor": "#FFC233",
                            "linkHoverColor": "#FFE199",
                            "rd": "0px",
                            "row2Bg": "#FFFFFF",
                            "rowBg": "#FFFFFF",
                            "rowDivider": "#000000",
                            "rowDivider_w": "0",
                            "rowFont": "normal normal normal 14px/1.4em avenir-lt-w01_35-light1475496",
                            "rowOrCellHoverBg": "#FFFFFF",
                            "rowText": "#FFFFFF",
                            "selection": "#FFFFFF",
                            "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                        },
                        "propertiesSource": {
                            "alpha-columnDivider": "value",
                            "alpha-containerBrd": "value",
                            "alpha-headerBg": "value",
                            "alpha-headerDivider": "value",
                            "alpha-row2Bg": "value",
                            "alpha-rowBg": "value",
                            "alpha-rowDivider": "value",
                            "alpha-rowOrCellHoverBg": "value",
                            "alpha-selection": "value",
                            "columnDivider": "value",
                            "columnDivider_w": "value",
                            "containerBg": "value",
                            "containerBrd": "value",
                            "containerBrw": "value",
                            "headerBg": "value",
                            "headerDivider": "value",
                            "headerDivider_w": "value",
                            "headerFont": "value",
                            "headerText": "value",
                            "rd": "value",
                            "row2Bg": "value",
                            "rowDivider": "value",
                            "rowDivider_w": "value",
                            "rowFont": "value",
                            "rowText": "value",
                            "selection": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.Grid",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.GridSkin"
                },
                "activeModes": {},
                "id": "comp-iwoyqt68"
            },
            "preset": {
                "rect": {
                    "width": 324,
                    "height": 141,
                    "x": 0,
                    "y": 579
                },
                "tags": null
            }
        }, {
            "id": "User_Input_Grid_6",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.GridSkin",
                "layout": {
                    "width": 700,
                    "height": 276,
                    "x": 140,
                    "y": 2014,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.Grid",
                "data": {
                    "type": "Grid",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "dataSource": {
                        "type": "static",
                        "revision": 0
                    },
                    "revision": 0,
                    "rows": [{
                        "gold": "47",
                        "silver": "37",
                        "bronze": "38",
                        "total": "121",
                        "state": "image://v1/035244_8fc38cfebc2f4f18842dc5a13cbef609~mv2.png/57_35/icon-04.png"
                    },
                    {
                        "gold": "27",
                        "silver": "23",
                        "bronze": "17",
                        "total": "67",
                        "state": "image://v1/035244_f768fef3fd9b491aa3d73fee2f97297f~mv2.png/57_35/icon-05.png"
                    },
                    {
                        "gold": "26",
                        "silver": "18",
                        "bronze": "26",
                        "total": "70",
                        "state": "image://v1/035244_abe7ae419f464801ba31cc8e4144b1d5~mv2.png/57_35/icon-06.png"
                    },
                    {
                        "gold": "19",
                        "silver": "18",
                        "bronze": "19",
                        "total": "56",
                        "state": "image://v1/035244_ab6e1f6f1722403e833b48894e9b3ffc~mv2.png/57_35/icon_Artboard 9.png"
                    },
                    {
                        "gold": "17",
                        "silver": "10",
                        "bronze": "15",
                        "total": "42",
                        "state": "image://v1/035244_bc75d7c349d24676a7332902743fa091~mv2.png/57_35/icon_Artboard 9 copy.png"
                    }]
                },
                "props": {
                    "type": "GridProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "isHidden": false,
                    "isCollapsed": false,
                    "columns": [{
                        "id": "columnId_abb1d93e-02d3-42b0-b46e-1052466acfa8",
                        "dataPath": "state",
                        "label": "STATE",
                        "visible": true,
                        "type": "image"
                    }, {
                        "id": "columnId_3750db7c-7629-43a2-87da-9343c2f7e4f5",
                        "dataPath": "gold",
                        "label": "GOLD",
                        "visible": true,
                        "type": "string"
                    }, {
                        "id": "columnId_085200ad-0dfe-429e-8a59-19e5ec2b06cb",
                        "dataPath": "silver",
                        "label": "SILVER",
                        "visible": true,
                        "type": "string"
                    }, {
                        "id": "columnId_2fac1bee-516c-4f17-a563-fc85e88d6089",
                        "dataPath": "bronze",
                        "label": "BRONZE",
                        "visible": true,
                        "type": "string"
                    }, {
                        "id": "columnId_ad1e4ba0-07e1-452c-a120-70d37642fa43",
                        "dataPath": "total",
                        "label": "TOTAL",
                        "visible": true,
                        "type": "string"
                    }],
                    "headerHeight": 68,
                    "rowHeight": 68,
                    "allowUserSorting": true,
                    "allowUserFiltering": true,
                    "allowUserEditing": true,
                    "pagination": {
                        "type": "virtual",
                        "rowsPerPage": 200
                    },
                    "userSelectionType": "row",
                    "dateFormat": "dd/mm/yyyy",
                    "linkTarget": "_blank",
                    "heightLayout": "manual",
                    "showHeader": true,
                    "columnLayout": "equal",
                    "horizontalAlignment": "center"
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-columnDivider": 0,
                            "alpha-containerBg": 1,
                            "alpha-containerBrd": 0,
                            "alpha-headerBg": 0,
                            "alpha-headerDivider": 0,
                            "alpha-row2Bg": 0,
                            "alpha-rowBg": 0,
                            "alpha-rowDivider": 0,
                            "alpha-rowOrCellHoverBg": 0.05,
                            "alpha-selection": 0.1,
                            "boxShadowToggleOn-shd": "true",
                            "columnDivider": "#000000",
                            "columnDivider_w": "2",
                            "containerBg": "#FFFFFF",
                            "containerBrd": "#000000",
                            "containerBrw": "2",
                            "headerBg": "#000000",
                            "headerDivider": "#000000",
                            "headerDivider_w": "0",
                            "headerFont": "normal normal normal 14px/1.4em clarendon-w01-medium-692107",
                            "headerText": "#000000",
                            "rd": "2px",
                            "row2Bg": "#FFFFFF",
                            "rowBg": "#FFFFFF",
                            "rowDivider": "#FFFFFF",
                            "rowDivider_w": "0",
                            "rowFont": "normal normal normal 14px/1.4em clarendon-w01-medium-692107",
                            "rowOrCellHoverBg": "#000000",
                            "rowText": "#000000",
                            "selection": "#000000",
                            "shd": "0.00px 3.00px 20px 3px rgba(0,0,0,0.06)"
                        },
                        "propertiesSource": {
                            "alpha-columnDivider": "value",
                            "alpha-containerBrd": "value",
                            "alpha-headerBg": "value",
                            "alpha-headerDivider": "value",
                            "alpha-row2Bg": "value",
                            "alpha-rowBg": "value",
                            "alpha-rowDivider": "value",
                            "alpha-rowOrCellHoverBg": "value",
                            "alpha-selection": "value",
                            "columnDivider": "value",
                            "columnDivider_w": "value",
                            "containerBg": "value",
                            "containerBrd": "value",
                            "containerBrw": "value",
                            "headerBg": "value",
                            "headerDivider": "value",
                            "headerDivider_w": "value",
                            "headerFont": "value",
                            "headerText": "value",
                            "rd": "value",
                            "row2Bg": "value",
                            "rowDivider": "value",
                            "rowDivider_w": "value",
                            "rowFont": "value",
                            "rowOrCellHoverBg": "value",
                            "rowText": "value",
                            "selection": "value",
                            "shd": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.Grid",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.GridSkin"
                },
                "activeModes": {},
                "id": "comp-iwp30rh6"
            },
            "preset": {
                "rect": {
                    "width": 324,
                    "height": 191,
                    "x": 0,
                    "y": 719
                },
                "tags": null
            }
        }, {
            "id": "User_Input_Grid_7",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.GridSkin",
                "layout": {
                    "width": 620,
                    "height": 304,
                    "x": 180,
                    "y": 2548,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.Grid",
                "data": {
                    "type": "Grid",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "dataSource": {
                        "type": "static",
                        "revision": 0
                    },
                    "revision": 0,
                    "rows": [{
                        "nickname": "Ninja",
                        "place": "1st",
                        "points": "4,500,000",
                        "pic": "image://v1/035244_443fc138322f480f90f2889afcf1ad36~mv2.png/63_63/icon-09.png"
                    }, {
                        "nickname": "Ursula",
                        "place": "2nd",
                        "points": "5,750,000",
                        "pic": "image://v1/035244_542369b8a388433fbfe39cf00095730c~mv2.png/63_63/icon_Artboard 14.png"
                    }, {
                        "nickname": "Thor",
                        "place": "3rd",
                        "points": "2,000,000",
                        "pic": "image://v1/035244_07c32e9d6fa3414da983710fc2d1d932~mv2.png/63_63/icon_Artboard 15.png"
                    }]
                },
                "props": {
                    "type": "GridProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "isHidden": false,
                    "isCollapsed": false,
                    "columns": [{
                        "id": "columnId_7b395ab9-8ad5-4597-8395-5a156dc6c552",
                        "dataPath": "pic",
                        "label": "Pic",
                        "visible": true,
                        "type": "image"
                    }, {
                        "id": "columnId_02171a9f-641c-4e9c-b88a-242a40669e93",
                        "dataPath": "nickname",
                        "label": "Nickname",
                        "visible": true,
                        "type": "string"
                    }, {
                        "id": "columnId_bfa53b95-ed6d-4a1d-bbaa-4e92672b67fd",
                        "dataPath": "place",
                        "label": "Place",
                        "visible": true,
                        "type": "string"
                    }, {
                        "id": "columnId_d5c8bfb7-e3e6-409f-8edf-c99dabc6adbb",
                        "dataPath": "points",
                        "label": "Points",
                        "visible": true,
                        "type": "string"
                    }],
                    "headerHeight": 60,
                    "rowHeight": 100,
                    "allowUserSorting": true,
                    "allowUserFiltering": true,
                    "allowUserEditing": true,
                    "pagination": {
                        "type": "virtual",
                        "rowsPerPage": 200
                    },
                    "userSelectionType": "row",
                    "dateFormat": "dd/mm/yyyy",
                    "linkTarget": "_blank",
                    "heightLayout": "manual",
                    "showHeader": false,
                    "columnLayout": "equal",
                    "horizontalAlignment": "center"
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-columnDivider": 0,
                            "alpha-containerBg": 1,
                            "alpha-containerBrd": 0,
                            "alpha-headerBg": 0,
                            "alpha-headerDivider": 0,
                            "alpha-row2Bg": 0,
                            "alpha-rowBg": 0,
                            "alpha-rowDivider": 0,
                            "alpha-rowOrCellHoverBg": 0.05,
                            "alpha-selection": 0.1,
                            "boxShadowToggleOn-shd": "false",
                            "columnDivider": "#000000",
                            "columnDivider_w": "2",
                            "containerBg": "#343434",
                            "containerBrd": "#000000",
                            "containerBrw": "2",
                            "headerBg": "#3B3747",
                            "headerDivider": "#000000",
                            "headerDivider_w": "0",
                            "headerFont": "normal normal normal 14px/1.4em din-next-w01-light",
                            "headerText": "#00B6B0",
                            "linkColor": "#5CE6E1",
                            "linkHoverColor": "#80FFFA",
                            "rd": "16px",
                            "row2Bg": "#FFFFFF",
                            "rowBg": "#FFFFFF",
                            "rowDivider": "#FFFFFF",
                            "rowDivider_w": "0",
                            "rowFont": "normal normal normal 16px/1.4em din-next-w01-light",
                            "rowOrCellHoverBg": "#FFFFFF",
                            "rowText": "#00B6B0",
                            "selection": "#FFFFFF",
                            "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                        },
                        "propertiesSource": {
                            "alpha-columnDivider": "value",
                            "alpha-containerBrd": "value",
                            "alpha-headerBg": "value",
                            "alpha-headerDivider": "value",
                            "alpha-row2Bg": "value",
                            "alpha-rowBg": "value",
                            "alpha-rowDivider": "value",
                            "alpha-rowOrCellHoverBg": "value",
                            "alpha-selection": "value",
                            "columnDivider": "value",
                            "columnDivider_w": "value",
                            "containerBg": "value",
                            "containerBrd": "value",
                            "containerBrw": "value",
                            "headerBg": "value",
                            "headerDivider": "value",
                            "headerDivider_w": "value",
                            "headerFont": "value",
                            "headerText": "value",
                            "rd": "value",
                            "row2Bg": "value",
                            "rowDivider": "value",
                            "rowDivider_w": "value",
                            "rowFont": "value",
                            "rowOrCellHoverBg": "value",
                            "rowText": "value",
                            "selection": "value",
                            "shd": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.Grid",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.GridSkin"
                },
                "activeModes": {},
                "id": "comp-iwq8ilx1"
            },
            "preset": {
                "rect": {
                    "width": 324,
                    "height": 165,
                    "x": 0,
                    "y": 909
                },
                "tags": null
            }
        }, {
            "id": "User_Input_Grid_8",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.GridSkin",
                "layout": {
                    "width": 761,
                    "height": 240,
                    "x": 109,
                    "y": 2971,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.Grid",
                "data": {
                    "type": "Grid",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "dataSource": {
                        "type": "static",
                        "revision": 0
                    },
                    "revision": 0,
                    "rows": [{
                        "address": "28 BEDFORD AVE, BROOKLYN, NY 12345",
                        "rooms": "2 BEDROOMS",
                        "price": "$930,000",
                        "photo": "image://v1/035244_460603ea2eb4404db28bb0764cd38901~mv2.jpg/153_89/building2.jpg"
                    }, {
                        "address": "20 S 9TH ST, BROOKLYN, NY 12345",
                        "rooms": "2 BEDROOMS",
                        "price": "$750,000",
                        "photo": "image://v1/035244_dd928a16a2f249298219ad7e318befc9~mv2.jpg/153_89/building1.jpg"
                    }, {
                        "address": "72 MARCY AVE, BROOKLYN, NY 12345",
                        "rooms": "4 BEDROOMS",
                        "price": "$700,000",
                        "photo": "image://v1/035244_b9d4168900be429bbfb48095078e7b9a~mv2.jpg/153_89/building3.jpg"
                    }]
                },
                "props": {
                    "type": "GridProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "columns": [{
                        "id": "columnId_1e1b563c-970a-4b62-8e97-f88979361412",
                        "dataPath": "photo",
                        "label": "Photo",
                        "width": 140,
                        "visible": true,
                        "type": "image"
                    }, {
                        "id": "columnId_dc3d151b-a1e7-4e89-b683-cb0f24fdd1a4",
                        "dataPath": "address",
                        "label": "Address",
                        "width": 340,
                        "visible": true,
                        "type": "string"
                    }, {
                        "id": "columnId_8294051b-cbce-4653-b21a-70ac3cec2b6d",
                        "dataPath": "rooms",
                        "label": "Rooms",
                        "width": 170,
                        "visible": true,
                        "type": "string"
                    }, {
                        "id": "columnId_e507739a-286c-4878-aa80-5cc51dedb583",
                        "dataPath": "price",
                        "label": "Price",
                        "width": 110,
                        "visible": true,
                        "type": "string"
                    }],
                    "headerHeight": 58,
                    "rowHeight": 74,
                    "allowUserSorting": true,
                    "allowUserFiltering": true,
                    "allowUserEditing": true,
                    "pagination": {
                        "type": "virtual",
                        "rowsPerPage": 200
                    },
                    "userSelectionType": "row",
                    "dateFormat": "dd/mm/yyyy",
                    "linkTarget": "_blank",
                    "heightLayout": "manual",
                    "showHeader": false,
                    "columnLayout": "manual",
                    "horizontalAlignment": "left"
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-columnDivider": 0,
                            "alpha-containerBg": 1,
                            "alpha-containerBrd": 0,
                            "alpha-headerBg": 0,
                            "alpha-headerDivider": 0,
                            "alpha-row2Bg": 0,
                            "alpha-rowBg": 0,
                            "alpha-rowDivider": 1,
                            "alpha-rowOrCellHoverBg": 0.05,
                            "alpha-selection": 0.1,
                            "boxShadowToggleOn-shd": "false",
                            "cellPadding": "0",
                            "columnDivider": "#FFFFFF",
                            "columnDivider_w": "0",
                            "containerBg": "#ECF2F5",
                            "containerBrd": "#FFFFFF",
                            "containerBrw": "0",
                            "headerBg": "#000000",
                            "headerDivider": "#FFFFFF",
                            "headerDivider_w": "2",
                            "headerFont": "normal normal normal 18px/1.4em brandon-grot-w01-light",
                            "headerText": "#000000",
                            "rd": "0px",
                            "row2Bg": "#FFFFFF",
                            "rowBg": "#FFFFFF",
                            "rowDivider": "#FFFFFF",
                            "rowDivider_w": "6",
                            "rowFont": "normal normal normal 12px/1.4em brandon-grot-w01-light",
                            "rowOrCellHoverBg": "#000000",
                            "rowText": "#000000",
                            "selection": "#000000",
                            "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                        },
                        "propertiesSource": {
                            "alpha-columnDivider": "value",
                            "alpha-containerBrd": "value",
                            "alpha-headerBg": "value",
                            "alpha-headerDivider": "value",
                            "alpha-row2Bg": "value",
                            "alpha-rowBg": "value",
                            "alpha-rowDivider": "value",
                            "alpha-rowOrCellHoverBg": "value",
                            "alpha-selection": "value",
                            "columnDivider": "value",
                            "columnDivider_w": "value",
                            "containerBg": "value",
                            "containerBrd": "value",
                            "containerBrw": "value",
                            "headerBg": "value",
                            "headerDivider": "value",
                            "headerDivider_w": "value",
                            "headerFont": "value",
                            "headerText": "value",
                            "rd": "value",
                            "row2Bg": "value",
                            "rowBg": "value",
                            "rowDivider": "value",
                            "rowDivider_w": "value",
                            "rowFont": "value",
                            "rowText": "value",
                            "selection": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.Grid",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.GridSkin"
                },
                "activeModes": {},
                "id": "comp-iwqb2ot7"
            },
            "preset": {
                "rect": {
                    "width": 324,
                    "height": 142,
                    "x": 0,
                    "y": 1073
                },
                "tags": null
            }
        }, {
            "id": "User_Input_Grid_9",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.GridLeftHeaderSkin",
                "layout": {
                    "width": 808,
                    "height": 208,
                    "x": 86,
                    "y": 3326,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.Grid",
                "data": {
                    "type": "Grid",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "dataSource": {
                        "type": "static",
                        "revision": 0
                    },
                    "revision": 0,
                    "rows": [{
                        "amenities": "4K TV",
                        "deluxe": "image://v1/035244_00ea41dbadc04173bd945219fd997d28~mv2.png/14_11/icon-12.png",
                        "executive": "image://v1/035244_00ea41dbadc04173bd945219fd997d28~mv2.png/14_11/icon-12.png",
                        "presidential": "image://v1/035244_00ea41dbadc04173bd945219fd997d28~mv2.png/14_11/icon-12.png"
                    }, {
                        "amenities": "Gourmet Breakfast",
                        "deluxe": "image://v1/035244_00ea41dbadc04173bd945219fd997d28~mv2.png/14_11/icon-12.png",
                        "executive": "image://v1/035244_00ea41dbadc04173bd945219fd997d28~mv2.png/14_11/icon-12.png",
                        "presidential": "image://v1/035244_00ea41dbadc04173bd945219fd997d28~mv2.png/14_11/icon-12.png"
                    }, {
                        "amenities": "Spa Package",
                        "deluxe": "",
                        "executive": "image://v1/035244_00ea41dbadc04173bd945219fd997d28~mv2.png/14_11/icon-12.png",
                        "presidential": "image://v1/035244_00ea41dbadc04173bd945219fd997d28~mv2.png/14_11/icon-12.png"
                    }, {
                        "amenities": "Private Pool",
                        "deluxe": "",
                        "executive": "",
                        "presidential": "image://v1/035244_00ea41dbadc04173bd945219fd997d28~mv2.png/14_11/icon-12.png"
                    }]
                },
                "props": {
                    "type": "GridProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "isHidden": false,
                    "isCollapsed": false,
                    "columns": [{
                        "id": "columnId_0d7987c9-a20f-4ae8-85d7-442ff16379e0",
                        "dataPath": "amenities",
                        "label": "Amenities",
                        "width": 100,
                        "visible": true,
                        "type": "string"
                    }, {
                        "id": "columnId_6b5263d7-9560-4121-9798-3cec51ce71dd",
                        "dataPath": "deluxe",
                        "label": "Deluxe",
                        "width": 100,
                        "visible": true,
                        "type": "image"
                    }, {
                        "id": "columnId_0146beba-3dd5-42a0-bf06-da91a05a932d",
                        "dataPath": "executive",
                        "label": "Executive",
                        "width": 100,
                        "visible": true,
                        "type": "image"
                    }, {
                        "id": "columnId_4432a3ff-6057-43a6-be69-db5a373f0b3f",
                        "dataPath": "presidential",
                        "label": "Presidential",
                        "width": 100,
                        "visible": true,
                        "type": "image"
                    }],
                    "headerHeight": 52,
                    "rowHeight": 50,
                    "allowUserSorting": true,
                    "allowUserFiltering": true,
                    "allowUserEditing": true,
                    "pagination": {
                        "type": "virtual",
                        "rowsPerPage": 200
                    },
                    "userSelectionType": "row",
                    "dateFormat": "dd/mm/yyyy",
                    "linkTarget": "_blank",
                    "heightLayout": "manual",
                    "showHeader": true,
                    "columnLayout": "equal",
                    "horizontalAlignment": "center"
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-columnDivider": 1,
                            "alpha-containerBg": 1,
                            "alpha-containerBrd": 1,
                            "alpha-headerBg": 1,
                            "alpha-headerColumnBg": 0.1,
                            "alpha-headerDivider": 1,
                            "alpha-paginationColor": 1,
                            "alpha-row2Bg": 0,
                            "alpha-rowBg": 0,
                            "alpha-rowDivider": 0,
                            "alpha-rowOrCellHoverBg": 0.1,
                            "alpha-selection": 0.2,
                            "boxShadowToggleOn-shd": "false",
                            "cellPadding": "0",
                            "columnDivider": "#00D9AE",
                            "columnDivider_w": "2",
                            "containerBg": "#FFFFFF",
                            "containerBrd": "#00D9AE",
                            "containerBrw": "3",
                            "headerBg": "#00D9AE",
                            "headerColumnBg": "#00D9AE",
                            "headerColumnFont": "normal normal normal 14px/1.4em open+sans",
                            "headerColumnText": "#00B6B0",
                            "headerDivider": "#00D9AE",
                            "headerDivider_w": "0",
                            "headerFont": "normal normal normal 16px/1.4em open+sans",
                            "headerText": "#FFFFFF",
                            "linkColor": "#00B6B0",
                            "linkHoverColor": "#331fDD",
                            "paginationColor": "#00D9AE",
                            "row2Bg": "#FFFFFF",
                            "rowBg": "#FFFFFF",
                            "rowDivider": "#FFFFFF",
                            "rowDivider_w": "0",
                            "rowOrCellHoverBg": "#00D9AE",
                            "rowText": "#00B6B0",
                            "selection": "#00D9AE",
                            "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                        },
                        "propertiesSource": {
                            "alpha-columnDivider": "value",
                            "alpha-headerColumnBg": "value",
                            "alpha-headerDivider": "value",
                            "alpha-paginationColor": "value",
                            "alpha-row2Bg": "value",
                            "alpha-rowBg": "value",
                            "alpha-rowOrCellHoverBg": "value",
                            "alpha-selection": "value",
                            "cellPadding": "value",
                            "columnDivider": "value",
                            "columnDivider_w": "value",
                            "containerBg": "value",
                            "containerBrd": "value",
                            "containerBrw": "value",
                            "headerBg": "value",
                            "headerColumnBg": "value",
                            "headerColumnFont": "value",
                            "headerColumnText": "value",
                            "headerDivider": "value",
                            "headerDivider_w": "value",
                            "headerFont": "value",
                            "headerText": "value",
                            "linkColor": "value",
                            "linkHoverColor": "value",
                            "paginationColor": "value",
                            "row2Bg": "value",
                            "rowBg": "value",
                            "rowDivider": "value",
                            "rowDivider_w": "value",
                            "rowText": "value",
                            "selection": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.Grid",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.GridLeftHeaderSkin"
                },
                "activeModes": {},
                "id": "comp-iy2sgifu"
            },
            "preset": {
                "rect": {
                    "width": 324,
                    "height": 155,
                    "x": 0,
                    "y": 1214
                },
                "tags": null
            }
        }, {
            "id": "User_Input_Grid_10",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.GridSkin",
                "layout": {
                    "width": 600,
                    "height": 184,
                    "x": 190,
                    "y": 3710,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.Grid",
                "data": {
                    "type": "Grid",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "dataSource": {
                        "type": "static",
                        "revision": 0
                    },
                    "revision": 0,
                    "rows": [{
                        "title": "Aunt Bette's Homemade Pecan Pie",
                        "make_it": "Make It"
                    }, {
                        "title": "Rockin’ Rocky Road Ice Cream",
                        "make_it": "Make It"
                    }, {
                        "title": "Tom’s Heavenly Apple Strudel ",
                        "make_it": "Make It"
                    }, {
                        "title": "Joe’s Divine Butter Tarts",
                        "make_it": "Make It"
                    }]
                },
                "props": {
                    "type": "GridProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "columns": [{
                        "id": "columnId_5ab9159f-082a-4ff0-853b-5698a3cd867f",
                        "dataPath": "title",
                        "label": "Title",
                        "width": 400,
                        "visible": true,
                        "type": "string"
                    }, {
                        "id": "columnId_4694030a-ffb9-4fe4-9e2b-c443388a9637",
                        "dataPath": "make_it",
                        "label": "Make It",
                        "width": 200,
                        "visible": true,
                        "type": "string"
                    }],
                    "headerHeight": 58,
                    "rowHeight": 60,
                    "allowUserSorting": true,
                    "allowUserFiltering": true,
                    "allowUserEditing": true,
                    "pagination": {
                        "type": "virtual",
                        "rowsPerPage": 200
                    },
                    "userSelectionType": "row",
                    "dateFormat": "dd/mm/yyyy",
                    "linkTarget": "_blank",
                    "heightLayout": "manual",
                    "showHeader": false,
                    "columnLayout": "manual",
                    "horizontalAlignment": "left"
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-columnDivider": 0,
                            "alpha-containerBg": 1,
                            "alpha-containerBrd": 1,
                            "alpha-headerBg": 1,
                            "alpha-headerDivider": 0,
                            "alpha-row2Bg": 0.4,
                            "alpha-rowBg": 1,
                            "alpha-rowDivider": 1,
                            "alpha-rowOrCellHoverBg": 0.3,
                            "alpha-selection": 1,
                            "boxShadowToggleOn-shd": "false",
                            "cellPadding": "40",
                            "columnDivider": "#000000",
                            "containerBg": "#FFFFFF",
                            "containerBrd": "#6E6E6E",
                            "containerBrw": "2",
                            "headerBg": "#000000",
                            "headerDivider": "#6E6E6E",
                            "headerFont": "normal normal normal 15px/1.4em spinnaker",
                            "headerText": "#FFFFFF",
                            "row2Bg": "#FFFFFF",
                            "rowBg": "#FFFFFF",
                            "rowDivider": "#6E6E6E",
                            "rowFont": "normal normal normal 14px/1.4em spinnaker",
                            "rowOrCellHoverBg": "#E6E6E6",
                            "rowText": "#000000",
                            "selection": "#E6E6E6",
                            "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                        },
                        "propertiesSource": {
                            "alpha-columnDivider": "value",
                            "alpha-rowDivider": "value",
                            "alpha-rowOrCellHoverBg": "value",
                            "columnDivider": "value",
                            "containerBg": "value",
                            "containerBrd": "value",
                            "headerBg": "value",
                            "headerDivider": "value",
                            "headerFont": "value",
                            "headerText": "value",
                            "row2Bg": "value",
                            "rowDivider": "value",
                            "rowFont": "value",
                            "rowOrCellHoverBg": "value",
                            "rowText": "value",
                            "selection": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.Grid",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.GridSkin"
                },
                "activeModes": {},
                "id": "comp-iwucrb2b"
            },
            "preset": {
                "rect": {
                    "width": 324,
                    "height": 126,
                    "x": 0,
                    "y": 1368
                },
                "tags": null
            }
        }],
        "compTypes": ["wysiwyg.viewer.components.Grid"]
    },
    "help": {
        "hide": false,
        "text": "add_section_info_text_table"
    }
}
