:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._overlay_7yzc7_7 {
  position: fixed;
  z-index: 1821;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

._container_7yzc7_15 {
  position: fixed;
  top: calc(12px + var(--g-top-bar-height));
  right: 12px;
  z-index: 1822;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 14px 26px 0 rgba(22, 45, 61, 0.26), 0 2px 6px 0 rgba(22, 45, 61, 0.2);
  background: none; }

.editor-layout-wrapper ._container_7yzc7_15 {
  position: absolute;
  top: 12px; }

.classic-facelift-skin ._container_7yzc7_15 .input-container:not(.is-disabled) > .input:focus {
  border-color: transparent; }
