import constants from '@/constants';
import type {
  ClipboardItem,
  ClipboardPageItem,
  ClipboardCompItem,
} from './types';

const isCompClipboardItem = (item: ClipboardItem): item is ClipboardCompItem =>
  'type' in item && item.type === constants.CLIPBOARD_ITEM_TYPE.COMPONENT;

const isPageClipboardItem = (item: ClipboardItem): item is ClipboardPageItem =>
  'type' in item && item.type === constants.CLIPBOARD_ITEM_TYPE.PAGE;

export { isCompClipboardItem, isPageClipboardItem };
