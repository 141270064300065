'use strict'

module.exports = {
    "id": "add_preset_popups_Sale_6",
    "structure": {
        "type": "Page",
        "id": "cas3f",
        "componentTypeForBI": "wysiwyg.viewer.components.PopupContainer",
        "components": [{
            "type": "Container",
            "id": "comp-imgd531k",
            "components": [{
                "type": "Component",
                "id": "comp-imgd532u",
                "layout": {
                    "width": 667,
                    "height": 157,
                    "x": 0,
                    "y": 122,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "text": "<h2 style=\"text-align: center; font-size: 112px;\" class=\"font_2\"><span style=\"font-weight:bold;\"><span style=\"color:#0FE3FF;\"><span style=\"font-size:112px;\"><span style=\"font-family:futura-lt-w01-book,sans-serif;\">20% OFF</span></span></span></span></h2>",
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": [],
                    "id": "dataItem-imgd532v"
                },
                "props": {
                    "type": "WRichTextProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "brightness": 1,
                    "packed": true,
                    "id": "propItem-imgd532v1"
                },
                "style": "txtNew"
            }, {
                "type": "Component",
                "id": "comp-imgd532j",
                "layout": {
                    "width": 667,
                    "height": 30,
                    "x": 0,
                    "y": 280,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "text": "<h2 class=\"font_2\" style=\"line-height:1.1em; text-align:center\"><span style=\"color:#4D4D4D;\"><span style=\"font-family:futura-lt-w01-light,sans-serif\"><span style=\"letter-spacing:2.5px\">ALL SUMMER COLLECTION</span></span></span></h2>\n",
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": [],
                    "id": "dataItem-imgd532k"
                },
                "props": {
                    "type": "WRichTextProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "brightness": 1,
                    "packed": true,
                    "id": "propItem-imgd532l"
                },
                "style": "txtNew"
            }, {
                "type": "Component",
                "id": "comp-imgdml4l",
                "layout": {
                    "width": 667,
                    "height": 34,
                    "x": 0,
                    "y": 95,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "text": "<h2 class=\"font_2\" style=\"text-align:center\"><span style=\"color:#4D4D4D;\"><span style=\"letter-spacing:0.05em\"><span style=\"font-family:futura-lt-w01-light,sans-serif\">UP TO</span></span></span></h2>\n",
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": [],
                    "id": "dataItem-imgdml4p"
                },
                "props": {
                    "type": "WRichTextProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "brightness": 1,
                    "packed": true,
                    "id": "propItem-imgdml4p1"
                },
                "style": "txtNew"
            }, {
                "type": "Component",
                "id": "comp-imgesy0k",
                "skin": "wysiwyg.viewer.skins.button.BasicButton",
                "layout": {
                    "width": 199,
                    "height": 40,
                    "x": 234,
                    "y": 358,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.PopupCloseTextButton",
                "data": {
                    "type": "LinkableButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "label": "CHECK IT OUT",
                    "id": "dataItem-imgesy0p"
                },
                "props": {
                    "type": "ButtonProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "align": "center",
                    "margin": 0,
                    "id": "propItem-imgesy0r"
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-bg": "1",
                            "alpha-bgh": "1",
                            "alpha-brd": "0.97",
                            "alpha-brdh": "1",
                            "alpha-txt": "1",
                            "alpha-txth": "1",
                            "bg": "#0FE3FF",
                            "bgh": "#69EDFF",
                            "boxShadowToggleOn-shd": "false",
                            "brd": "#FFFFFF",
                            "brdh": "#EDEDED",
                            "brw": "0",
                            "fnt": "normal normal normal 16px/1.4em futura-lt-w01-book",
                            "rd": "0px",
                            "shd": "0 1px 4px rgba(0, 0, 0, 0.6);",
                            "txt": "#FFFFFF",
                            "txth": "#FFFFFF"
                        },
                        "propertiesSource": {
                            "alpha-brd": "value",
                            "bg": "value",
                            "bgh": "value",
                            "brd": "value",
                            "brdh": "value",
                            "brw": "value",
                            "fnt": "value",
                            "rd": "value",
                            "shd": "value",
                            "txt": "value",
                            "txth": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.PopupCloseTextButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.button.BasicButton",
                    "id": "style-imgewugr"
                }
            }, {
                "type": "Component",
                "skin": "svgshape.v2.Svg_001419cc721a440abc4ab12ed150b744",
                "layout": {
                    "width": 30,
                    "height": 30,
                    "x": 617,
                    "y": 20,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.PopupCloseIconButton",
                "data": {
                    "type": "SvgShape",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    }
                },
                "props": {
                    "type": "SvgShapeProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "maintainAspectRatio": true
                },
                "style": {
                    "componentClassName": "wysiwyg.viewer.components.PopupCloseIconButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "style": {
                        "properties": {
                            "alpha-fillcolor": "1",
                            "alpha-stroke": "1",
                            "fillcolor": "#424242",
                            "stroke": "#FFFFFF",
                            "strokewidth": "2"
                        },
                        "propertiesSource": {
                            "alpha-fillcolor": "value",
                            "alpha-stroke": "value",
                            "fillcolor": "value",
                            "stroke": "value",
                            "strokewidth": "value"
                        },
                        "groups": {}
                    },
                    "type": "ComponentStyle",
                    "skin": "svgshape.v2.Svg_001419cc721a440abc4ab12ed150b744"
                },
                "connections": {
                    "type": "ConnectionList",
                    "items": [
                        {
                            "type": "WixCodeConnectionItem",
                            "role": "button1"
                        }
                    ]
                },
                "mobileStructure": {
                    "layout": {
                        "width": 24,
                        "height": 24,
                        "x": 236,
                        "y": 21,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "metaData": {
                        "originalCompId": "comp-isre9qho",
                        "author": "duplicate"
                    }
                },
                "activeModes": {}
            }],
            "skin": "wysiwyg.viewer.skins.stripContainer.DefaultStripContainer",
            "layout": {
                "width": 667,
                "height": 495,
                "x": 156,
                "y": 141,
                "scale": 1,
                "rotationInDegrees": 0,
                "fixedPosition": false
            },
            "componentType": "wysiwyg.viewer.components.PopupContainer",
            "props": {
                "type": "PopupContainerProperties",
                "metaData": {"schemaVersion": "1.0"},
                "horizontalAlignment": "center",
                "verticalAlignment": "center",
                "alignmentType": "nineGrid",
                "horizontalOffset": 0,
                "verticalOffset": 0,
                "id": "propItem-imgd531m"
            },
            "design": {
                "type": "MediaContainerDesignData",
                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                "background": {
                    "type": "BackgroundMedia",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "mediaRef": {
                        "type": "WixVideo",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "title": "Falling shapes White",
                        "videoId": "video/11062b_2ed9e9ea388343a59c387bccf3539098",
                        "qualities": [{
                            "quality": "480p",
                            "width": 854,
                            "height": 480,
                            "formats": ["mp4", "webm"]
                        }, {
                            "quality": "720p",
                            "width": 1280,
                            "height": 720,
                            "formats": ["mp4", "webm"]
                        }, {"quality": "1080p", "width": 1920, "height": 1080, "formats": ["mp4", "webm"]}],
                        "posterImageRef": {
                            "type": "Image",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "title": "",
                            "uri": "11062b_2ed9e9ea388343a59c387bccf3539098f000.jpg",
                            "description": "public/8e256233-1752-4026-9341-54036e7f875e/d6b6b972-952c-4ab0-b46a-a6c05a0a94a5",
                            "width": 1920,
                            "height": 1080,
                            "alt": ""
                        },
                        "opacity": 1,
                        "duration": 28,
                        "loop": true,
                        "autoplay": true,
                        "preload": "auto",
                        "mute": true,
                        "artist": {"id": "", "name": ""}
                    },
                    "color": "#FFFFFF",
                    "colorOpacity": 1,
                    "alignType": "center",
                    "fittingType": "fill",
                    "scrollType": "none",
                    "colorOverlay": "",
                    "colorOverlayOpacity": 0
                },
                "id": "dataItem-imgd531m1"
            },
            "style": {
                "type": "TopLevelStyle",
                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                "style": {
                    "groups": {},
                    "properties": {"alpha-bg": "1", "bg": "rgba(255,255,255,1)"},
                    "propertiesSource": {"alpha-bg": "value", "bg": "value"}
                },
                "componentClassName": "wysiwyg.viewer.components.PopupContainer",
                "pageId": "",
                "compId": "",
                "styleType": "custom",
                "skin": "wysiwyg.viewer.skins.stripContainer.DefaultStripContainer",
                "id": "style-imgd531s"
            },
            "behaviors": {
                "type": "ObsoleteBehaviorsList",
                "items": "[{\"name\":\"FadeIn\",\"delay\":0.1,\"duration\":0.75,\"action\":\"screenIn\",\"targetId\":\"\",\"params\":{}},{\"name\":\"FadeOut\",\"delay\":0.1,\"duration\":0.75,\"action\":\"exit\",\"targetId\":\"\",\"params\":{}}]",
                "id": "behavior-iomlweda"
            }
        }],

        "skin": "skins.core.InlineSkin",
        "layout": {
            "width": 980,
            "height": 636,
            "x": 0,
            "y": 0,
            "scale": 1,
            "rotationInDegrees": 0,
            "fixedPosition": false
        },
        "componentType": "mobile.core.components.Page",
        "data": {
            "type": "Page",
            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
            "title": "add_preset_popups_Sale_6",
            "hideTitle": true,
            "icon": "",
            "descriptionSEO": "",
            "metaKeywordsSEO": "",
            "pageTitleSEO": "",
            "pageUriSEO": "copy-of-sale3-cas3f",
            "hidePage": true,
            "underConstruction": false,
            "tpaApplicationId": 0,
            "pageSecurity": {"requireLogin": false, "passwordDigest": "", "dialogLanguage": ""},
            "isPopup": true,
            "indexable": false,
            "isLandingPage": false,
            "pageBackgrounds": {
                "desktop": {
                    "ref": {
                        "type": "BackgroundMedia",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "color": "#0FE3FF",
                        "colorOpacity": 0.7,
                        "alignType": "center",
                        "fittingType": "fill",
                        "scrollType": "none",
                        "colorOverlay": "",
                        "colorOverlayOpacity": 0
                    }
                },
                "mobile": {
                    "custom": true,
                    "ref": {
                        "type": "BackgroundMedia",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "color": "#0FE3FF",
                        "colorOpacity": 0.7,
                        "alignType": "center",
                        "fittingType": "fill",
                        "scrollType": "scroll",
                        "colorOverlay": "",
                        "colorOverlayOpacity": 0
                    },
                    "isPreset": true
                }
            },
            "ignoreBottomBottomAnchors": true
        },
        "props": {
            "type": "PageProperties",
            "metaData": {"schemaVersion": "1.0"},
            "desktop": {"popup": {"closeOnOverlayClick": true}},
            "mobile": {"popup": {"closeOnOverlayClick": true}},
            "id": "cas3f"
        },
        "style": "p1",
        "tpaComps": []
    },
    "preset": {
        "rect": {"width": 305, "height": 202, "x": 10, "y": 1130},
        "label": "add_preset_popups_Sale_6",
        "tags": null
    }
}
