:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._panelWrapper_1deq0_7 {
  height: 100%;
  overflow-y: hidden;
  position: absolute;
  right: 0;
  top: 0;
  transition: transform ease 300ms; }

._quickEditPanel_1deq0_15 {
  width: 288px;
  background: #fff;
  height: 100%;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  visibility: hidden;
  opacity: 0;
  transition: opacity 250ms ease-in; }
  ._quickEditPanel_1deq0_15._visible_1deq0_25 {
    opacity: 1;
    visibility: visible; }
  ._quickEditPanel_1deq0_15 ._preloader_1deq0_28 {
    position: relative;
    height: auto;
    top: 185px; }
  ._quickEditPanel_1deq0_15 ._footer_1deq0_32 {
    height: 36px;
    background: #fff; }
