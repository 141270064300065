import { translate } from '@/i18n';
import { biLogger } from '@/util';
import {
  aiTextGeneratorErrorEvent,
  aiTextGeneratorPanelOpen,
  aiTextGeneratorRequestOutput,
} from '@wix/bi-logger-editor/v2';
import { SiteGlobalDataApiKey } from '@/apis';
import type { AddPresetScope } from '../../../addPresetApiEntry';
import { getAddSectionPanelLanguage } from '../../addSectionPanelUtil';
import {
  createQueryOutputBiData,
  getBusinessData,
  getCategoryNameById,
  getSectionsWithAIContent,
} from './utils';
import type {
  SectionAiCreationQueryOutput,
  SectionCreatorPromptParams,
  SectionCreatorSuccessList,
} from './aiSectionCreatorTypes';
import {
  AI_SECTION_CREATOR_PANEL_TYPE,
  ERROR_STATE_PANEL_PATH,
} from './aiSectionCreatorConsts';

export interface AISectionCreatorStateProps {
  language: string;
  defaultFormState: SectionCreatorPromptParams;
}

export interface AISectionCreatorDispatchProps {
  getSectionWithContent: (
    params: SectionCreatorPromptParams,
  ) => Promise<SectionCreatorSuccessList>;
  showFailStateModal: (
    query: { categoryId: string; description: string; tokens: string },
    error: any,
  ) => void;
  sendPanelOpenedBiEvent: (
    sessionId: string,
    query: SectionCreatorPromptParams,
    origin?: string,
    panelState?: string,
  ) => void;
  sendQueryOutputBiEvent: (
    sessionId: string,
    query: SectionCreatorPromptParams,
    queryOutputs: SectionAiCreationQueryOutput[],
    duration: number,
  ) => void;
}

export const mapStateToProps = (
  scope: AddPresetScope,
): AISectionCreatorStateProps => ({
  language: getAddSectionPanelLanguage(),
  defaultFormState: {
    ...getBusinessData(scope),
    description: '',
    categoryId: scope.store.getSectionsAiCategories()[0]?.id,
  },
});

export const mapDispatchToProps = (
  scope: AddPresetScope,
): AISectionCreatorDispatchProps => ({
  getSectionWithContent: ({
    categoryId,
    description,
    businessName,
    businessType,
  }) => {
    const language = getAddSectionPanelLanguage();
    const categoryNameKey = scope.store
      .getSectionsCategories()
      .find((category) => category.id === categoryId)?.name;
    const categoryName = translate(categoryNameKey);

    return getSectionsWithAIContent(scope, {
      categoryId,
      categoryName,
      language,
      businessName,
      businessType,
      description,
    });
  },
  showFailStateModal: (
    { categoryId, tokens, ...query },
    errorMessage: string,
  ) => {
    const siteGlobalDataApi = scope.editorAPI.host.getAPI(SiteGlobalDataApiKey);
    const businessType = siteGlobalDataApi.getBusinessType()?.displayName ?? '';
    const businessName = siteGlobalDataApi.getBusinessName() ?? '';

    const categoryIdName = getCategoryNameById(scope, categoryId);

    biLogger.report(
      aiTextGeneratorErrorEvent({
        query: JSON.stringify({
          ...query,
          categoryId: categoryIdName,
          businessType,
          businessName,
        }),
        panelType: AI_SECTION_CREATOR_PANEL_TYPE,
        errorMessage,
        tokens,
      }),
    );

    scope.editorAPI.panelManager.openPanel(
      ERROR_STATE_PANEL_PATH,
      {
        closePanel: () =>
          scope.editorAPI.panelManager.closePanelByName(ERROR_STATE_PANEL_PATH),
      },
      true,
    );
  },
  sendPanelOpenedBiEvent: (sessionId, query, origin, panelState) => {
    const { categoryId, ...queryData } = query;
    biLogger.report(
      aiTextGeneratorPanelOpen({
        sessionId,
        query: JSON.stringify({
          ...queryData,
          categoryId: getCategoryNameById(scope, categoryId),
        }),
        panelType: AI_SECTION_CREATOR_PANEL_TYPE,
        origin,
        panelState,
      }),
    );
  },
  sendQueryOutputBiEvent: (sessionId, queryInput, queryOutputs, duration) => {
    const additionalBiData = createQueryOutputBiData(queryOutputs);
    const query = JSON.stringify({
      ...queryInput,
      categoryId: getCategoryNameById(scope, queryInput.categoryId),
    });

    biLogger.report(
      aiTextGeneratorRequestOutput({
        duration,
        sessionId,
        query,
        panelType: AI_SECTION_CREATOR_PANEL_TYPE,
        ...additionalBiData,
      }),
    );
  },
});
