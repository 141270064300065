import React, { type FC } from 'react';
import { translate } from '@/i18n';
import { parseAppVersion } from '../../../utils/utils';
import { Preloader, Tooltip } from '@wix/wix-base-ui';
import styles from './updateInProgressIndication.scss';

const UpdateInProgressIndication: FC<{ version: string }> = ({ version }) => {
  return (
    <Tooltip
      className={styles.updateInProgress}
      content={translate(
        'blocks-private-apps.AppDiscovery_App_List_Update_In_Progress_Loader_Tooltip',
        { versionNumber: parseAppVersion(version) },
      )}
      shouldTranslate={false}
    >
      <Preloader className="tiny" />
    </Tooltip>
  );
};

export default UpdateInProgressIndication;
