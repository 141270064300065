// @ts-nocheck
import _ from 'lodash';
import gfppDataUtils from '../../utils/gfppDataUtils';
import constants from '@/constants';

const { ACTIONS } = constants.ROOT_COMPS.GFPP;
const defaultHelpId = 'de578d88-d1fe-4a98-b671-a355ee8d7113';
const defaultMobileHelpId = 'b6d9a270-f9c5-4837-995b-c3818b13a643';

function getDefaultGalleryData() {
  return {
    mainActions: [
      {
        label(editorAPI, compRef) {
          return editorAPI.components.data.get(compRef)?.items?.length
            ? 'gfpp_mainaction_gallery'
            : 'gfpp_mainaction_gallery_empty';
        },
        isApplied: gfppDataUtils.getDataDefinedFn('items'),
        onClick(editorAPI, compRef) {
          editorAPI.panelHelpers.openOrganizeImagesPanel(
            function (newImageDataList) {
              const data = editorAPI.components.data.get(compRef);
              data.items = newImageDataList;
              editorAPI.components.data.update(compRef, data);
            },
          );
        },
      },
    ],
    presetActions: {
      help: {
        helpId: defaultHelpId,
        mobileHelpId: defaultMobileHelpId,
      },
    },
  };
}

function getGalleryDataByType(galleryTypeData) {
  return {
    presetActions: getPresetActions(
      galleryTypeData.helpId,
      galleryTypeData.mobileHelpId,
    ),
    enabledActions: getDesktopEnabledAction(
      galleryTypeData.hasDesktopLayoutAction,
    ),
    mobileEnabledActions: getMobileEnabledAction(
      galleryTypeData.hasMobileLayoutAction,
    ),
  };
}

function getPresetActions(helpId, mobileHelpId) {
  return {
    help: {
      helpId: helpId ? helpId : defaultHelpId,
      mobileHelpId: mobileHelpId ? mobileHelpId : defaultMobileHelpId,
    },
  };
}

function getMobileEnabledAction(hasLayout) {
  const actions = [ACTIONS.SETTINGS, ACTIONS.HIDE, ACTIONS.HELP];
  if (hasLayout) {
    actions.splice(1, 0, ACTIONS.LAYOUT);
  }
  return actions;
}

function getDesktopEnabledAction(hasLayout) {
  const actions = [
    ACTIONS.SETTINGS,
    ACTIONS.DESIGN,
    ACTIONS.ANIMATION,
    ACTIONS.HELP,
  ];
  if (hasLayout) {
    actions.splice(2, 0, ACTIONS.LAYOUT);
  }

  return actions;
}

function getGfppDataByType(gfppDatamap, componentType) {
  const gfppDatamapValue = gfppDatamap[componentType];

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/is-undefined
  if (_.isUndefined(gfppDatamapValue)) {
    return null;
  }

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/assign
  return _.assign(
    getDefaultGalleryData(),
    getGalleryDataByType(gfppDatamapValue),
  );
}

export default {
  getGfppDataByType,
};
