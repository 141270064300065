import React from 'react';
import GeneralContent from '../generalContent';
import type { InnerPanelProps } from '../types';
import { withConnect } from './seoDealerFallback.mapper';

const SeoDealerFallback: React.FunctionComponent<InnerPanelProps> = ({
  className,
  openWixSEOWiz,
}) => {
  const getClassName = `publish-site-content${
    className ? ` ${className}` : ''
  }`;

  const onPublishSiteClick = () => {
    openWixSEOWiz('EDITOR_POSTSAVE_PREMIUM');
  };

  return (
    <GeneralContent
      className={getClassName}
      symbolName="settingsFoundOnGoogle_NewWorkspace"
      mainTitle="SAVE_SITE_SAVED_WHATS_NEXT_LABEL"
      title="SAVE_SITE_SAVED_PREMIUM_DEALER_FALLBACK_SEO_TITLE"
      subtitle="SAVE_SITE_SAVED_PREMIUM_DEALER_FALLBACK_SEO_BODY"
      actionLabel="SAVE_SITE_SAVED_PREMIUM_DEALER_FALLBACK_SEO_CTA"
      actionCallback={onPublishSiteClick}
    />
  );
};

export default withConnect(SeoDealerFallback);
