import { sections as sectionsUtils } from '@/util';

import {
  getCurrentEditorMode,
  getSectionLikeSelectionType,
  pickSkinFromSchema,
} from '../skins/skins.utils';

import type {
  RegularSkinGetter,
  RegularSkinSchema,
} from '../skins/skins.types';

const sectionSchema: RegularSkinSchema = {
  desktop: {
    hovered: 'secondary',
    selected: 'primary',
    focused: 'secondary',
  },
  desktopZoomOut: {
    hovered: 'secondary',
    selected: 'primary',
  },
  desktopRightShrinked: {
    hovered: 'secondary',
    selected: 'secondary',
  },
};

export const getAddSectionButtonSkin: RegularSkinGetter = (
  editorAPI,
  compRef,
  selectionData,
) => {
  if (!sectionsUtils.isSectionsControlsRedesignEnabled()) return 'legacy';

  const mode = getCurrentEditorMode(editorAPI);
  const selectionType = getSectionLikeSelectionType(
    editorAPI,
    compRef,
    selectionData,
  );

  return pickSkinFromSchema(sectionSchema, mode, selectionType);
};
