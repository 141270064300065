._container_x1lkf_1 {
  display: flex;
  flex-direction: column;
  height: calc(100% - 14px); }

._messages_x1lkf_6 {
  padding: 12px 18px 32px 18px;
  background: #edf1f5;
  flex-grow: 1;
  overflow-y: auto; }

._input_x1lkf_12 {
  padding: 18px;
  border-radius: 8px 8px 0px 0px;
  background: white;
  box-shadow: 0px -1px 3px 0px rgba(0, 6, 36, 0.12);
  position: relative;
  z-index: 5;
  flex-shrink: 0; }
