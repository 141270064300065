// @ts-nocheck
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import { Checkbox, CustomScroll } from '@wix/wix-base-ui';

import * as core from '@/core';
import * as util from '@/util';
import { translate } from '@/i18n';
import * as coreBi from '@/coreBi';
import * as stateManagement from '@/stateManagement';
import * as Panels from '@/panels';
import * as helpIds from '@/helpIds';
import { cx } from '@/util';

import StateItem from './stateBoxBgApplyPanelItem';

const {
  connect,
  STORES: { EDITOR_API },
} = util.hoc;
const { getAllSlides, findCurrentSlide } =
  stateManagement.boxSlideShow.selectors;
const { getStateTitle } = stateManagement.stateBox.selectors;

function applyStateBgData(bg, statesToApply, editorAPI) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
  _.forEach(statesToApply, ({ state, checked, original }) =>
    editorAPI.components.design.update(
      state,
      createBgData(
        _.cloneDeep({ background: checked ? bg : original }),
        original,
      ),
    ),
  );
}

function createBgData(currentBgData, oldBgData) {
  //same media type
  if (
    currentBgData.mediaRef &&
    oldBgData &&
    oldBgData.mediaRef &&
    currentBgData.mediaRef.type === oldBgData.mediaRef.type
  ) {
    currentBgData.mediaRef.id = oldBgData.mediaRef.id;
    if (currentBgData.mediaRef.type === 'WixVideo') {
      currentBgData.mediaRef.posterImageRef.id =
        oldBgData.mediaRef.posterImageRef.id;
      if (currentBgData.imageOverlay) {
        currentBgData.imageOverlay.id = oldBgData.imageOverlay?.id;
      }
    }
    // not the same media type
  } else if (currentBgData.mediaRef) {
    delete currentBgData.mediaRef.id;
    if (currentBgData.mediaRef.type === 'WixVideo') {
      delete currentBgData.mediaRef.posterImageRef.id;
      if (currentBgData.imageOverlay) {
        delete currentBgData.imageOverlay.id;
      }
    }
  } else {
    currentBgData.mediaRef = null;
    currentBgData.colorOverlay = '';
    currentBgData.imageOverlay = null;
  }

  if (oldBgData) {
    currentBgData.id = oldBgData.id;
  }

  return currentBgData;
}

// eslint-disable-next-line react/prefer-es6-class
const StateBoxBgApplyPanel = createReactClass({
  displayName: 'stateBoxBgApplyPanel',
  mixins: [core.mixins.editorAPIMixin],
  propTypes: {
    stateBox: PropTypes.object,
    getStateTitle: PropTypes.func,
  },
  getAllStates() {
    const editorAPI = this.getEditorAPI();
    return getAllSlides(editorAPI.dsRead, this.props.stateBox);
  },

  getInitialState() {
    const editorAPI = this.getEditorAPI();
    this.selectedItems = [];
    this.appliedStates = [];
    this.origin = this.props.origin;
    this.currentState = findCurrentSlide(editorAPI.dsRead, this.props.stateBox);
    this.currentStateBgData = this.getBgData(this.currentState);
    this.allStates = this.getAllStates();
    this.itemsProps = this.getItemsProps();

    return {
      markAllStatesDisplay: false,
      indeterminate: false,
    };
  },

  getBgData(state) {
    return this.getEditorAPI().components.design.get(state).background;
  },

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.state.markAllStatesDisplay !== nextState.markAllStatesDisplay ||
      this.state.indeterminate !== nextState.indeterminate ||
      this.appliedStates.length > 0
    );
  },

  handleSelectAllChange(checked) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
    _.forEach(
      this.itemsProps,
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/bind
      _.bind(function (item) {
        const { state } = item;
        const stateId = state.id;
        if (stateId === this.currentState.id) {
          return;
        }
        if (checked) {
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line you-dont-need-lodash-underscore/includes
          if (!_.includes(this.selectedItems, stateId)) {
            this.selectedItems.push(stateId);
            this.appliedStates.push({
              checked,
              original: item.itemBgData,
              state,
            });
          }
          // eslint-disable-next-line you-dont-need-lodash-underscore/includes
        } else if (_.includes(this.selectedItems, stateId)) {
          _.pull(this.selectedItems, stateId);
          this.appliedStates.push({
            checked,
            original: item.itemBgData,
            state,
          });
        }
      }, this),
    );
    this.setState(
      { markAllStatesDisplay: checked, indeterminate: false },
      this.updateStates,
    );
  },

  getItemsProps() {
    const itemsProps = [];
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
    _.forEach(
      this.allStates,
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/bind
      _.bind(function (state) {
        itemsProps.push({
          state,
          stateTitle: this.props.getStateTitle(state),
          currentStateBgData: this.currentStateBgData,
          itemBgData: this.getBgData(state),
          isCurrentState: this.currentState.id === state.id,
        });
      }, this),
    );
    return itemsProps;
  },

  getStateProps(state) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/find
    const stateProps = _.find(this.itemsProps, { state: { id: state.id } });
    stateProps.origin = this.origin;
    return stateProps;
  },

  updateStates() {
    const editorAPI = this.getEditorAPI();
    applyStateBgData(
      this.currentStateBgData,
      this.appliedStates,
      editorAPI,
      this.origin,
    );
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    const biParams = _.assign(
      {
        total_states: this.getAllStates().length,
        num_selected_states: this.appliedStates.length,
        all_states_selected: this.state.markAllStatesDisplay,
      },
      editorAPI.components.getDefaultBiParams(this.props.stateBox),
    );
    editorAPI.bi.event(
      coreBi.events.boxSlideShow.APPLY_ON_OTHER_SLIDES_SELECTED_SLIDES,
      biParams,
    );
    this.appliedStates = [];
  },

  statesStateChange() {
    let value = null;
    if (this.allStates.length === this.selectedItems.length + 1) {
      value = true;
    }

    if (this.selectedItems.length === 0) {
      value = false;
    }

    return {
      value,
      requestChange: function (checked, state, original) {
        const stateId = state.id;

        if (checked && !(stateId === this.currentState.id)) {
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line you-dont-need-lodash-underscore/includes
          if (!_.includes(this.selectedItems, stateId)) {
            this.selectedItems.push(stateId);
          }
        } else {
          _.pull(this.selectedItems, stateId);
        }
        this.appliedStates.push({ checked, original, state });
        const markAllStatesDisplay =
          this.allStates.length === this.selectedItems.length + 1;
        const indeterminate =
          !markAllStatesDisplay && this.selectedItems.length > 0;
        if (
          markAllStatesDisplay !== this.state.markAllStatesDisplay ||
          indeterminate !== this.state.indeterminate
        ) {
          const newState = {
            markAllStatesDisplay,
            indeterminate,
          };
          this.setState(newState, this.updateStates);
        } else {
          this.updateStates();
        }
      }.bind(this),
    };
  },

  render() {
    return (
      <Panels.frames.BgSubPanelFrame
        helpId={helpIds.EXTERNAL.STATE_BOX_BG_APPLY}
        label="multiStateBox_ChangeBackground_ApplyOther_Button"
        {..._.omit(this.props, 'children')}
        className={cx(
          _.omit(this.props, 'children')?.className,
          'slide-show-apply-panel',
        )}
      >
        <div className="slide-show-apply-panel-wrapper">
          <div className="slide-show-apply-panel-description">
            {translate('multiStateBox_ApplyOtherStates_ApplyBackground_Label')}
          </div>

          <div className="slide-show-apply-panel-all">
            <Checkbox
              label="multiStateBox_ApplyOtherStates_AllStatesCheckbox_Label"
              value={this.state.markAllStatesDisplay}
              onChange={this.handleSelectAllChange}
              indeterminate={this.state.indeterminate}
              labelAfterSymbol={true}
            />
          </div>

          <CustomScroll>
            <div className="slide-show-apply-panel-scrollbox slide-show-settings-panel-scrollbox">
              <ul className="slide-show-apply-panel-scrollbox-list">
                {/* TODO: Fix this the next time the file is edited. */}
                {/* eslint-disable-next-line you-dont-need-lodash-underscore/map */}
                {_.map(this.allStates, (state, stateIndex) => (
                  <li key={`state${stateIndex}`}>
                    <StateItem
                      valueLink={this.statesStateChange()}
                      ref={`state${stateIndex}`}
                      {...this.getStateProps(state)}
                    />
                  </li>
                ))}
              </ul>
            </div>
          </CustomScroll>
        </div>
      </Panels.frames.BgSubPanelFrame>
    );
  },
});

const mapEditorAPItoProps = ({ editorAPI, dsRead }, ownProps) => {
  const stateBox =
    ownProps.stateBox || _.head(editorAPI.selection.getSelectedComponents());
  return _.defaults(
    {
      stateBox,
      getStateTitle: (statePointer) =>
        getStateTitle(dsRead, stateBox, statePointer),
    },
    ownProps,
  );
};

export default connect(EDITOR_API, mapEditorAPItoProps)(StateBoxBgApplyPanel);
