// @ts-nocheck
import constants from '@/constants';
import * as stateManagement from '@/stateManagement';

const mapStateToProps = ({ editorAPI }) => ({
  doNotShowAgain:
    !!stateManagement.userPreferences.selectors.getSiteUserPreferences(
      constants.USER_PREFS.SOSP.PANELS.DETACH_DO_NOT_SHOW_AGAIN,
    )(editorAPI.store.getState()),
});

const getEditorAPI = (dispatch, getState, { editorAPI }) => editorAPI;

const mapDispatchToProps = (dispatch) => {
  const editorAPI = dispatch(getEditorAPI);

  return {
    closePanel: editorAPI.panelManager.closePanelByName,
    openHelpCenter: editorAPI.panelManager.openHelpCenter,
    setDoNotShowAgain: () => {
      dispatch(
        stateManagement.userPreferences.actions.setSiteUserPreferences(
          constants.USER_PREFS.SOSP.PANELS.DETACH_DO_NOT_SHOW_AGAIN,
          true,
        ),
      );
    },
  };
};

export { mapStateToProps, mapDispatchToProps };
