// @ts-nocheck
import { array } from '@/util';
import wixFormsConstants from '../../contact/wixForms/constants';
import * as wixFormsUtils from '../../contact/wixForms/utils';

const addForm = (structure) => {
  const form = wixFormsUtils.getFormWidget({
    layout: {
      width: 647,
      height: 153,
      x: 31,
      y: 209,
      scale: 1,
      rotationInDegrees: 0,
      anchors: [
        {
          distance: -35,
          originalValue: 382,
          type: 'BOTTOM_PARENT',
          locked: true,
          targetComponent: 'comp-im0lsb4n',
        },
      ],
      fixedPosition: false,
    },
    id: 'comp-im0mde4p',
    mobileHints: {
      type: 'MobileHints',
      author: 'duplicate',
      originalCompId: 'comp-im0mde4p',
      offsetY: -335,
      offsetX: 28,
      offsetOrigin: 'leftTop',
      orderIndex: 3,
      recommendedWidth: 245,
    },
  });

  // add form to
  // components: [
  //     component_0: {
  //         components: [
  //             component_00
  //             component_01,
  //             component_02,
  //             component_03,
  //             form,
  //             ...
  //         ]
  //     },
  // ]
  return {
    ...structure,
    components: array.immutableSplice(structure.components, 0, 1, {
      ...structure.components[0],
      components: array.immutableSplice(
        structure.components[0].components,
        4,
        0,
        form,
      ),
    }),
  };
};

export default (onDrop, onClick) => (item) => {
  onDrop = onDrop(wixFormsConstants.FormPreset.LIGHTBOX_CONTACT01);
  onClick = onClick(wixFormsConstants.FormPreset.LIGHTBOX_CONTACT01);

  return {
    ...item,
    onDrop,
    onClick,
    structure: addForm(item.structure),
  };
};
