'use strict'

const hbPreset1 = {
    "id": "hoverBoxPreset1",
    "structure": {
        "type": "Container",
        "components": [
            {
                "type": "Component",
                "modes": {
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iw6ip0of-mode-iw6ip0ou1"
                            ],
                            "layout": {
                                "width": 368,
                                "height": 43,
                                "x": 120,
                                "y": 135,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            }
                        },
                        {
                            "modeIds": [
                                "comp-iw6ip0of-mode-iw6ip0ou"
                            ],
                            "layout": {
                                "width": 368,
                                "height": 54,
                                "x": 120,
                                "y": 151,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "isHiddenByModes": true
                        }
                    ],
                    "isHiddenByModes": false
                },
                "layout": {
                    "width": 368,
                    "height": 56,
                    "x": 120,
                    "y": 151,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "text": "<h6 class=\"font_6\" style=\"font-size:32px; text-align:center\"><a dataquery=\"#textLink_iw55uev9\"><span style=\"letter-spacing:0.15em\"><span style=\"color:#FFFFFF\"><span style=\"font-size:32px\"><span style=\"font-family:futura-lt-w01-book,sans-serif\">Digital Prints</span></span></span></span></a></h6>\n",
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": [
                        {
                            "type": "PageLink",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "1.0",
                                "isHidden": false
                            },
                            "pageId": "#c1dmp"
                        }
                    ]
                },
                "props": {
                    "type": "WRichTextProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "brightness": 1,
                    "packed": true
                },
                "style": "txtNew",
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"action\":\"modeChange\",\"name\":\"ModesMotion\",\"delay\":0,\"duration\":0.8,\"params\":{\"timingFunction\":\"EaseInOut\"},\"targetId\":\"\"},{\"name\":\"FloatIn\",\"delay\":0,\"duration\":0.5,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iw6ip0of-mode-iw6ip0ou1\"]},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"FloatOut\",\"delay\":0,\"duration\":0.5,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iw6ip0of-mode-iw6ip0ou1\"]},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                },
                "custom": {
                    "linkList": [
                        {
                            "type": "PageLink",
                            "id": "dataItem-iw6ip0p12",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "1.0",
                                "isHidden": false
                            },
                            "pageId": "#c1dmp"
                        }
                    ]
                },
                "mobileStructure": {
                    "type": "Component",
                    "layout": {
                        "width": 260,
                        "height": 33,
                        "x": 10,
                        "y": 61,
                        "scale": 0.9025,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.WRichText",
                    "data": {
                        "type": "StyledText",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "text": "<h6 class=\"font_6\" style=\"font-size:32px; text-align:center\"><a dataquery=\"#textLink_iw55uev9\"><span style=\"letter-spacing:0.15em\"><span style=\"color:#FFFFFF\"><span style=\"font-size:32px\"><span style=\"font-family:futura-lt-w01-book,sans-serif\">Digital Prints</span></span></span></span></a></h6>\n",
                        "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                        "linkList": [
                            {
                                "type": "PageLink",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "pageId": "#c1dmp"
                            }
                        ]
                    },
                    "props": {
                        "type": "WRichTextProperties",
                        "metaData": {
                            "schemaVersion": "1.0",
                            "autoGenerated": false
                        },
                        "brightness": 1,
                        "packed": true
                    },
                    "style": "txtNew",
                    "behaviors": {
                        "type": "ObsoleteBehaviorsList",
                        "items": "[{\"action\":\"modeChange\",\"name\":\"ModesMotion\",\"delay\":0,\"duration\":0.8,\"params\":{\"timingFunction\":\"EaseInOut\"},\"targetId\":\"\"},{\"name\":\"FloatIn\",\"delay\":0,\"duration\":0.5,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iw6ip0of-mode-iw6ip0ou1\"]},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"FloatOut\",\"delay\":0,\"duration\":0.5,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iw6ip0of-mode-iw6ip0ou1\"]},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                    },
                    "custom": {
                        "linkList": [
                            {
                                "type": "PageLink",
                                "id": "dataItem-iw6ip0p12",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "pageId": "#c1dmp"
                            }
                        ]
                    }
                }
            },
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iw6ip0of-mode-iw6ip0ou1"
                            ],
                            "layout": {
                                "width": 304,
                                "height": 18,
                                "x": 152,
                                "y": 219,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "isHiddenByModes": false
                        }
                    ],
                    "isHiddenByModes": true
                },
                "layout": {
                    "width": 304,
                    "height": 40,
                    "x": 151,
                    "y": 203,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "text": "<p class=\"font_8\" style=\"font-size:14px; text-align:center\"><a dataquery=\"#textLink_iw55u824\"><span style=\"letter-spacing:0.25em\"><span style=\"font-family:futura-lt-w01-light,sans-serif\"><span style=\"font-size:14px\"><span style=\"color:#FFFFFF\">VIEW ALL</span></span></span></span></a></p>\n",
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": [
                        {
                            "type": "PageLink",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "1.0",
                                "isHidden": false
                            },
                            "pageId": "#c1dmp"
                        }
                    ]
                },
                "props": {
                    "type": "WRichTextProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "brightness": 1,
                    "packed": true
                },
                "style": "txtNew",
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"name\":\"FloatIn\",\"delay\":0,\"duration\":0.5,\"params\":{\"direction\":\"top\",\"modeIds\":[\"comp-iw6ip0of-mode-iw6ip0ou1\"]},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"FloatOut\",\"delay\":0,\"duration\":0.5,\"params\":{\"direction\":\"top\",\"modeIds\":[\"comp-iw6ip0of-mode-iw6ip0ou1\"]},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                },
                "custom": {
                    "linkList": [
                        {
                            "type": "PageLink",
                            "id": "dataItem-iw6ip0p51",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "1.0",
                                "isHidden": false
                            },
                            "pageId": "#c1dmp"
                        }
                    ]
                },
                "mobileStructure": {
                    "type": "Component",
                    "modes": {
                        "definitions": []
                    },
                    "layout": {
                        "width": 260,
                        "height": 18,
                        "x": 10,
                        "y": 125,
                        "scale": 0.95,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.WRichText",
                    "data": {
                        "type": "StyledText",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "text": "<p class=\"font_8\" style=\"font-size:14px; text-align:center\"><a dataquery=\"#textLink_iw55u824\"><span style=\"letter-spacing:0.25em\"><span style=\"font-family:futura-lt-w01-light,sans-serif\"><span style=\"font-size:14px\"><span style=\"color:#FFFFFF\">VIEW ALL</span></span></span></span></a></p>\n",
                        "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                        "linkList": [
                            {
                                "type": "PageLink",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "pageId": "#c1dmp"
                            }
                        ]
                    },
                    "props": {
                        "type": "WRichTextProperties",
                        "metaData": {
                            "schemaVersion": "1.0",
                            "autoGenerated": false
                        },
                        "brightness": 1,
                        "packed": true
                    },
                    "style": "txtNew",
                    "behaviors": {
                        "type": "ObsoleteBehaviorsList",
                        "items": "[{\"name\":\"FloatIn\",\"delay\":0,\"duration\":0.5,\"params\":{\"direction\":\"top\",\"modeIds\":[\"comp-iw6ip0of-mode-iw6ip0ou1\"]},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"FloatOut\",\"delay\":0,\"duration\":0.5,\"params\":{\"direction\":\"top\",\"modeIds\":[\"comp-iw6ip0of-mode-iw6ip0ou1\"]},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                    },
                    "custom": {
                        "linkList": [
                            {
                                "type": "PageLink",
                                "id": "dataItem-iw6ip0p51",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "pageId": "#c1dmp"
                            }
                        ]
                    }
                }
            },
            {
                "type": "Component",
                "modes": {
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iw6ip0of-mode-iw6ip0ou1"
                            ],
                            "layout": {
                                "width": 454,
                                "height": 5,
                                "x": 77,
                                "y": 196,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "isHiddenByModes": false,
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "style": {
                                    "properties": {
                                        "alpha-brd": "0.6",
                                        "brd": "#FFFFFF",
                                        "lnw": "1px"
                                    },
                                    "propertiesSource": {
                                        "alpha-brd": "value",
                                        "brd": "value",
                                        "lnw": "value"
                                    },
                                    "groups": {}
                                },
                                "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.line.SolidLine"
                            }
                        }
                    ],
                    "isHiddenByModes": true
                },
                "skin": "wysiwyg.viewer.skins.line.SolidLine",
                "layout": {
                    "width": 550,
                    "height": 5,
                    "x": 18,
                    "y": 36,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.FiveGridLine",
                "props": {
                    "type": "FiveGridLineProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "fullScreenModeOn": false
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "style": {
                        "properties": {
                            "alpha-brd": "1",
                            "brd": "#FFFFFF",
                            "lnw": "1px"
                        },
                        "propertiesSource": {
                            "brd": "value",
                            "lnw": "value"
                        },
                        "groups": {}
                    },
                    "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.line.SolidLine"
                },
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"name\":\"ExpandIn\",\"delay\":0,\"duration\":0.3,\"params\":{\"direction\":\"right\",\"modeIds\":[\"comp-iw6ip0of-mode-iw6ip0ou1\"]},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"CollapseOut\",\"delay\":0,\"duration\":0.3,\"params\":{\"direction\":\"right\",\"modeIds\":[\"comp-iw6ip0of-mode-iw6ip0ou1\"]},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                },
                "mobileStructure": {
                    "type": "Component",
                    "modes": {
                        "definitions": []
                    },
                    "skin": "wysiwyg.viewer.skins.line.SolidLine",
                    "layout": {
                        "width": 216,
                        "height": 5,
                        "x": 32,
                        "y": 107,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.FiveGridLine",
                    "props": {
                        "type": "FiveGridLineProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "fullScreenModeOn": false
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "properties": {
                                "alpha-brd": "0.6",
                                "brd": "#FFFFFF",
                                "lnw": "1px"
                            },
                            "propertiesSource": {
                                "alpha-brd": "value",
                                "brd": "value",
                                "lnw": "value"
                            },
                            "groups": {}
                        },
                        "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.line.SolidLine"
                    },
                    "behaviors": {
                        "type": "ObsoleteBehaviorsList",
                        "items": "[{\"name\":\"ExpandIn\",\"delay\":0,\"duration\":0.3,\"params\":{\"direction\":\"right\",\"modeIds\":[\"comp-iw6ip0of-mode-iw6ip0ou1\"]},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"CollapseOut\",\"delay\":0,\"duration\":0.3,\"params\":{\"direction\":\"right\",\"modeIds\":[\"comp-iw6ip0of-mode-iw6ip0ou1\"]},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                    }
                }
            }
        ],
        "modes": {
            "definitions": [
                {
                    "modeId": "comp-iw6ip0of-mode-iw6ip0ou",
                    "type": "DEFAULT",
                    "label": "MODE_LABEL_FROM_PRESET",
                    "params": ""
                },
                {
                    "modeId": "comp-iw6ip0of-mode-iw6ip0ou1",
                    "type": "HOVER",
                    "label": "MODE_LABEL_FROM_PRESET",
                    "params": ""
                }
            ],
            "overrides": [
                {
                    "modeIds": [
                        "comp-iw6ip0of-mode-iw6ip0ou1"
                    ],
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "1.0",
                                "isHidden": false
                            },
                            "mediaRef": {
                                "type": "Image",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "title": "Fold_template.jpg",
                                "uri": "035244_5ccc32cedc134ef6804d5351bd24c551~mv2.jpg",
                                "description": "private/",
                                "width": 1920,
                                "height": 1080,
                                "alt": "",
                                "artist": {
                                    "id": "mobile_designItem_ivc6i69v2",
                                    "name": ""
                                },
                                "opacity": 1
                            },
                            "color": "#000000",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0,
                            "showOverlayForMediaType": "WixVideo",
                            "mediaTransforms": {
                                "scale": 1.1
                            }
                        },
                        "cssStyle": {},
                        "themeMappings": {},
                        "charas": "design-iwff836w",
                        "dataChangeBehaviors": [
                            {
                                "trigger": "in",
                                "type": "animation",
                                "part": "media",
                                "name": "Scale",
                                "params": {
                                    "delay": 0,
                                    "duration": 0.5
                                }
                            },
                            {
                                "trigger": "out",
                                "type": "animation",
                                "part": "media",
                                "name": "Scale",
                                "params": {
                                    "delay": 0,
                                    "duration": 0.5
                                }
                            }
                        ]
                    }
                }
            ],
            "isHiddenByModes": false
        },
        "layout": {
            "width": 608,
            "height": 360,
            "x": 0,
            "y": 0,
            "scale": 1,
            "rotationInDegrees": 0,
            "fixedPosition": false
        },
        "componentType": "wysiwyg.viewer.components.HoverBox",
        "props": {
            "type": "HoverBoxProperties",
            "metaData": {
                "schemaVersion": "1.0"
            },
            "mobileDisplayedModeId": "comp-iw6ip0of-mode-iw6ip0ou1"
        },
        "design": {
            "type": "MediaContainerDesignData",
            "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false
            },
            "background": {
                "type": "BackgroundMedia",
                "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false
                },
                "mediaRef": {
                    "type": "Image",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "title": "Fold_template.jpg",
                    "uri": "035244_5ccc32cedc134ef6804d5351bd24c551~mv2.jpg",
                    "description": "private/",
                    "width": 1920,
                    "height": 1080,
                    "alt": "",
                    "artist": {
                        "id": "mobile_designItem_ivc6i69v2",
                        "name": ""
                    },
                    "opacity": 1
                },
                "color": "#000000",
                "colorOpacity": 1,
                "alignType": "center",
                "fittingType": "fill",
                "scrollType": "none",
                "colorOverlay": "",
                "colorOverlayOpacity": 0,
                "showOverlayForMediaType": "WixVideo",
                "mediaTransforms": {
                    "scale": 1
                }
            },
            "cssStyle": {},
            "themeMappings": {},
            "charas": "design-iw3c35is",
            "dataChangeBehaviors": [
                {
                    "trigger": "in",
                    "type": "animation",
                    "part": "media",
                    "name": "Scale",
                    "params": {
                        "delay": 0,
                        "duration": 0.5
                    }
                },
                {
                    "trigger": "out",
                    "type": "animation",
                    "part": "media",
                    "name": "Scale",
                    "params": {
                        "delay": 0,
                        "duration": 0.5
                    }
                }
            ]
        },
        "style": {
            "type": "TopLevelStyle",
            "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false
            },
            "style": {
                "properties": {
                    "alpha-bg": "1",
                    "alpha-brd": "1",
                    "bg": "206,224,193,1",
                    "boxShadowToggleOn-shd": "false",
                    "brd": "#324158",
                    "brw": "0px",
                    "rd": "0px",
                    "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                },
                "propertiesSource": {
                    "bg": "value",
                    "brd": "value",
                    "brw": "value",
                    "rd": "value",
                    "shd": "value"
                },
                "groups": {}
            },
            "componentClassName": "wysiwyg.viewer.components.HoverBox",
            "pageId": "",
            "compId": "",
            "styleType": "custom",
            "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer"
        },
        "mobileStructure": {
            "type": "Container",
            "modes": {
                "definitions": [
                    {
                        "modeId": "comp-iw6ip0of-mode-iw6ip0ou",
                        "type": "DEFAULT",
                        "label": "MODE_LABEL_FROM_PRESET",
                        "params": ""
                    },
                    {
                        "modeId": "comp-iw6ip0of-mode-iw6ip0ou1",
                        "type": "HOVER",
                        "label": "MODE_LABEL_FROM_PRESET",
                        "params": ""
                    }
                ]
            },
            "layout": {
                "width": 280,
                "height": 200,
                "x": 20,
                "y": 10,
                "scale": 1,
                "rotationInDegrees": 0,
                "fixedPosition": false
            },
            "componentType": "wysiwyg.viewer.components.HoverBox",
            "props": {
                "type": "HoverBoxProperties",
                "metaData": {
                    "schemaVersion": "1.0"
                },
                "mobileDisplayedModeId": "comp-iw6ip0of-mode-iw6ip0ou1"
            },
            "design": {
                "type": "MediaContainerDesignData",
                "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false
                },
                "background": {
                    "type": "BackgroundMedia",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "mediaRef": {
                        "type": "Image",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "title": "Fold_template.jpg",
                        "uri": "035244_5ccc32cedc134ef6804d5351bd24c551~mv2.jpg",
                        "description": "private/",
                        "width": 1920,
                        "height": 1080,
                        "alt": "",
                        "artist": {
                            "id": "mobile_designItem_ivc6i69v2",
                            "name": ""
                        },
                        "opacity": 1
                    },
                    "color": "#000000",
                    "colorOpacity": 1,
                    "alignType": "center",
                    "fittingType": "fill",
                    "scrollType": "none",
                    "colorOverlay": "",
                    "colorOverlayOpacity": 0,
                    "showOverlayForMediaType": "WixVideo",
                    "mediaTransforms": {
                        "scale": 1.1
                    }
                },
                "cssStyle": {},
                "themeMappings": {},
                "charas": "design-iwff836w",
                "dataChangeBehaviors": [
                    {
                        "trigger": "in",
                        "type": "animation",
                        "part": "media",
                        "name": "Scale",
                        "params": {
                            "delay": 0,
                            "duration": 0.5
                        }
                    },
                    {
                        "trigger": "out",
                        "type": "animation",
                        "part": "media",
                        "name": "Scale",
                        "params": {
                            "delay": 0,
                            "duration": 0.5
                        }
                    }
                ]
            },
            "style": {
                "type": "TopLevelStyle",
                "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false
                },
                "style": {
                    "properties": {
                        "alpha-bg": "1",
                        "alpha-brd": "1",
                        "bg": "206,224,193,1",
                        "boxShadowToggleOn-shd": "false",
                        "brd": "#324158",
                        "brw": "0px",
                        "rd": "0px",
                        "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                    },
                    "propertiesSource": {
                        "bg": "value",
                        "brd": "value",
                        "brw": "value",
                        "rd": "value",
                        "shd": "value"
                    },
                    "groups": {}
                },
                "componentClassName": "wysiwyg.viewer.components.HoverBox",
                "pageId": "",
                "compId": "",
                "styleType": "custom",
                "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer"
            }
        },
        "activeModes": {}
    },
    "preset": {
        "rect": {
            "width": 324,
            "height": 221,
            "x": -1,
            "y": 0
        }
    }
}
const hbPreset2 = {
    "id": "hoverBoxPreset2",
    "structure": {
        "type": "Container",
        "components": [
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwavmy8d-mode-iwavmy8w"
                            ],
                            "isHiddenByModes": true
                        },
                        {
                            "modeIds": [
                                "comp-iwavmy8d-mode-iwavmy8w1"
                            ],
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "style": {
                                    "properties": {
                                        "alpha-fillcolor": "1",
                                        "alpha-stroke": "1",
                                        "fillcolor": "#000000",
                                        "stroke": "#3D9BE9",
                                        "strokewidth": "0px"
                                    },
                                    "propertiesSource": {
                                        "fillcolor": "value",
                                        "stroke": "value",
                                        "strokewidth": "value"
                                    },
                                    "groups": {}
                                },
                                "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "svgshape.v1.svg_162470084018985920a0c4cfb0f50d5e.Square"
                            }
                        }
                    ],
                    "isHiddenByModes": false
                },
                "skin": "svgshape.v1.svg_162470084018985920a0c4cfb0f50d5e.Square",
                "layout": {
                    "width": 238,
                    "height": 238,
                    "x": 23,
                    "y": 23,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.svgshape.SvgShape",
                "data": {
                    "type": "SvgShape",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    }
                },
                "props": {
                    "type": "SvgShapeProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "maintainAspectRatio": false
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "style": {
                        "properties": {
                            "alpha-fillcolor": "1",
                            "alpha-stroke": "1",
                            "fillcolor": "#000000",
                            "stroke": "#3D9BE9",
                            "strokewidth": "0px"
                        },
                        "propertiesSource": {
                            "fillcolor": "value",
                            "stroke": "value",
                            "strokewidth": "value"
                        },
                        "groups": {}
                    },
                    "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "svgshape.v1.svg_162470084018985920a0c4cfb0f50d5e.Square"
                },
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"name\":\"FadeIn\",\"delay\":0,\"duration\":0.25,\"action\":\"modeIn\",\"params\":{\"modeIds\":[\"comp-iwavmy8d-mode-iwavmy8w1\"]},\"targetId\":\"\"},{\"name\":\"FadeOut\",\"delay\":0,\"duration\":0.25,\"action\":\"modeOut\",\"params\":{\"modeIds\":[\"comp-iwavmy8d-mode-iwavmy8w1\"]},\"targetId\":\"\"}]"
                },
                "mobileStructure": {
                    "type": "Component",
                    "modes": {
                        "definitions": []
                    },
                    "skin": "svgshape.v1.svg_162470084018985920a0c4cfb0f50d5e.Square",
                    "layout": {
                        "width": 240,
                        "height": 240,
                        "x": 20,
                        "y": 18,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.svgshape.SvgShape",
                    "data": {
                        "type": "SvgShape",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        }
                    },
                    "props": {
                        "type": "SvgShapeProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "maintainAspectRatio": false
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "properties": {
                                "alpha-fillcolor": "1",
                                "alpha-stroke": "1",
                                "fillcolor": "#000000",
                                "stroke": "#3D9BE9",
                                "strokewidth": "0px"
                            },
                            "propertiesSource": {
                                "fillcolor": "value",
                                "stroke": "value",
                                "strokewidth": "value"
                            },
                            "groups": {}
                        },
                        "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "svgshape.v1.svg_162470084018985920a0c4cfb0f50d5e.Square"
                    },
                    "behaviors": {
                        "type": "ObsoleteBehaviorsList",
                        "items": "[{\"name\":\"FadeIn\",\"delay\":0,\"duration\":0.25,\"action\":\"modeIn\",\"params\":{\"modeIds\":[\"comp-iwavmy8d-mode-iwavmy8w1\"]},\"targetId\":\"\"},{\"name\":\"FadeOut\",\"delay\":0,\"duration\":0.25,\"action\":\"modeOut\",\"params\":{\"modeIds\":[\"comp-iwavmy8d-mode-iwavmy8w1\"]},\"targetId\":\"\"}]"
                    }
                }
            },
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwavmy8d-mode-iwavmy8w1"
                            ],
                            "isHiddenByModes": true
                        },
                        {
                            "modeIds": [
                                "comp-iwavmy8d-mode-iwavmy8w"
                            ],
                            "layout": {
                                "width": 201,
                                "height": 27,
                                "x": 48,
                                "y": 87,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            }
                        }
                    ],
                    "isHiddenByModes": false
                },
                "layout": {
                    "width": 201,
                    "height": 27,
                    "x": 48,
                    "y": 87,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "text": "<h6 class=\"font_6\" style=\"font-size:23px; line-height:1.2em; text-align:center\"><span style=\"color:#000000;\"><span style=\"font-size:23px\"><span style=\"letter-spacing:0.55em\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\">ABOUT</span></span></span></span></h6>\n",
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": []
                },
                "props": {
                    "type": "WRichTextProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "brightness": 1,
                    "packed": true
                },
                "style": "txtNew",
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"action\":\"modeIn\",\"name\":\"FadeIn\",\"delay\":0,\"duration\":0.35},{\"action\":\"modeOut\",\"name\":\"FadeOut\",\"delay\":0,\"duration\":0.35}]"
                }
            },
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwavmy8d-mode-iwavmy8w"
                            ],
                            "layout": {
                                "width": 201,
                                "height": 126,
                                "x": 33,
                                "y": 95,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            }
                        },
                        {
                            "modeIds": [
                                "comp-iwavmy8d-mode-iwavmy8w1"
                            ],
                            "layout": {
                                "width": 201,
                                "height": 126,
                                "x": 32,
                                "y": 148,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "isHiddenByModes": true
                        }
                    ],
                    "isHiddenByModes": false
                },
                "layout": {
                    "width": 201,
                    "height": 48,
                    "x": 32,
                    "y": 148,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "text": "<h6 class=\"font_6\" style=\"font-size:100px; line-height:1.2em; text-align:center\"><span style=\"color:#FFFFFF;\"><span style=\"font-size:100px\"><span style=\"font-family:belinda-w00-regular,script\"><span style=\"letter-spacing:0em\">Me</span></span></span></span></h6>\n",
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": []
                },
                "props": {
                    "type": "WRichTextProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "brightness": 1,
                    "packed": true
                },
                "style": "txtNew",
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"action\":\"modeIn\",\"name\":\"FadeIn\",\"delay\":0,\"duration\":0.35},{\"action\":\"modeOut\",\"name\":\"FadeOut\",\"delay\":0,\"duration\":0.35}]"
                }
            },
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwavmy8d-mode-iwavmy8w"
                            ],
                            "layout": {
                                "width": 128,
                                "height": 5,
                                "x": 79,
                                "y": 56,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "style": {
                                    "properties": {
                                        "alpha-brd": "1",
                                        "brd": "#000000",
                                        "lnw": "1"
                                    },
                                    "propertiesSource": {
                                        "brd": "value",
                                        "lnw": "value"
                                    },
                                    "groups": {}
                                },
                                "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.line.SolidLine"
                            }
                        },
                        {
                            "modeIds": [
                                "comp-iwavmy8d-mode-iwavmy8w1"
                            ],
                            "isHiddenByModes": true
                        }
                    ],
                    "isHiddenByModes": false
                },
                "skin": "wysiwyg.viewer.skins.line.SolidLine",
                "layout": {
                    "width": 128,
                    "height": 5,
                    "x": 78,
                    "y": 65,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.FiveGridLine",
                "props": {
                    "type": "FiveGridLineProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "fullScreenModeOn": false
                },
                "style": "hl3",
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"action\":\"modeIn\",\"name\":\"FadeIn\",\"delay\":0,\"duration\":0.35},{\"action\":\"modeOut\",\"name\":\"FadeOut\",\"delay\":0,\"duration\":0.35}]"
                }
            },
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwavmy8d-mode-iwavmy8w"
                            ],
                            "layout": {
                                "width": 128,
                                "height": 5,
                                "x": 79,
                                "y": 230,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            }
                        },
                        {
                            "modeIds": [
                                "comp-iwavmy8d-mode-iwavmy8w1"
                            ],
                            "layout": {
                                "width": 128,
                                "height": 5,
                                "x": 70,
                                "y": 277,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "isHiddenByModes": true
                        }
                    ],
                    "isHiddenByModes": false
                },
                "skin": "wysiwyg.viewer.skins.line.SolidLine",
                "layout": {
                    "width": 128,
                    "height": 5,
                    "x": 70,
                    "y": 239,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.FiveGridLine",
                "props": {
                    "type": "FiveGridLineProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "fullScreenModeOn": false
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "style": {
                        "properties": {
                            "alpha-brd": "1",
                            "brd": "#000000",
                            "lnw": "1"
                        },
                        "propertiesSource": {
                            "brd": "value",
                            "lnw": "value"
                        },
                        "groups": {}
                    },
                    "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.line.SolidLine"
                },
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"action\":\"modeIn\",\"name\":\"FadeIn\",\"delay\":0,\"duration\":0.35},{\"action\":\"modeOut\",\"name\":\"FadeOut\",\"delay\":0,\"duration\":0.35}]"
                }
            },
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwavmy8d-mode-iwavmy8w1"
                            ],
                            "layout": {
                                "width": 183,
                                "height": 125,
                                "x": 50,
                                "y": 79,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "isHiddenByModes": false,
                            "style": "txtNew",
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {
                                    "schemaVersion": "1.0"
                                },
                                "brightness": 1,
                                "packed": true
                            }
                        }
                    ],
                    "isHiddenByModes": true
                },
                "layout": {
                    "width": 201,
                    "height": 27,
                    "x": 51,
                    "y": 63,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "text": "<p class=\"font_8\" style=\"font-size: 18px; line-height: 1.4em; text-align: center;\"><span style=\"font-family:brandon-grot-w01-light,sans-serif\"><span style=\"color:#FFFFFF\"><span style=\"font-size:18px\">This is a great place to tell your story and give people more insight into who you are, what you do, and why it&rsquo;s all about you. </span></span></span></p>\n",
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": []
                },
                "props": {
                    "type": "WRichTextProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "brightness": 1,
                    "packed": true
                },
                "style": "txtNew",
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"name\":\"FadeIn\",\"delay\":0,\"duration\":0.25,\"action\":\"modeIn\",\"params\":{\"modeIds\":[\"comp-iwavmy8d-mode-iwavmy8w1\"]},\"targetId\":\"\"},{\"name\":\"FadeOut\",\"delay\":0,\"duration\":0.25,\"action\":\"modeOut\",\"params\":{\"modeIds\":[\"comp-iwavmy8d-mode-iwavmy8w1\"]},\"targetId\":\"\"}]"
                },
                "mobileStructure": {
                    "type": "Component",
                    "modes": {
                        "definitions": []
                    },
                    "layout": {
                        "width": 197,
                        "height": 130,
                        "x": 41,
                        "y": 75,
                        "scale": 1.05,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.WRichText",
                    "data": {
                        "type": "StyledText",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "text": "<p class=\"font_8\" style=\"font-size: 18px; line-height: 1.4em; text-align: center;\"><span style=\"font-family:brandon-grot-w01-light,sans-serif\"><span style=\"color:#FFFFFF\"><span style=\"font-size:18px\">This is a great place to tell your story and give people more insight into who you are, what you do, and why it&rsquo;s all about you. </span></span></span></p>\n",
                        "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                        "linkList": []
                    },
                    "props": {
                        "type": "WRichTextProperties",
                        "metaData": {
                            "schemaVersion": "1.0",
                            "autoGenerated": false
                        },
                        "brightness": 1,
                        "packed": true
                    },
                    "style": "txtNew",
                    "behaviors": {
                        "type": "ObsoleteBehaviorsList",
                        "items": "[{\"name\":\"FadeIn\",\"delay\":0,\"duration\":0.25,\"action\":\"modeIn\",\"params\":{\"modeIds\":[\"comp-iwavmy8d-mode-iwavmy8w1\"]},\"targetId\":\"\"},{\"name\":\"FadeOut\",\"delay\":0,\"duration\":0.25,\"action\":\"modeOut\",\"params\":{\"modeIds\":[\"comp-iwavmy8d-mode-iwavmy8w1\"]},\"targetId\":\"\"}]"
                    }
                }
            }
        ],
        "modes": {
            "definitions": [
                {
                    "modeId": "comp-iwavmy8d-mode-iwavmy8w",
                    "type": "DEFAULT",
                    "label": "MODE_LABEL_FROM_PRESET",
                    "params": ""
                },
                {
                    "modeId": "comp-iwavmy8d-mode-iwavmy8w1",
                    "type": "HOVER",
                    "label": "MODE_LABEL_FROM_PRESET",
                    "params": ""
                }
            ],
            "overrides": [],
            "isHiddenByModes": false
        },
        "layout": {
            "width": 284,
            "height": 284,
            "x": 0,
            "y": 0,
            "scale": 1,
            "rotationInDegrees": 0,
            "fixedPosition": false
        },
        "componentType": "wysiwyg.viewer.components.HoverBox",
        "props": {
            "type": "HoverBoxProperties",
            "metaData": {
                "schemaVersion": "1.0"
            },
            "mobileDisplayedModeId": "comp-iwavmy8d-mode-iwavmy8w1"
        },
        "design": {
            "type": "MediaContainerDesignData",
            "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false
            },
            "background": {
                "type": "BackgroundMedia",
                "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false
                },
                "color": "#36D1B5",
                "colorOpacity": 1,
                "alignType": "center",
                "fittingType": "fill",
                "scrollType": "none",
                "colorOverlay": "",
                "colorOverlayOpacity": 0,
                "showOverlayForMediaType": "all",
                "mediaTransforms": {
                    "scale": 1
                }
            },
            "cssStyle": {
                "cssBorder": {
                    "width": {
                        "top": {
                            "value": 0,
                            "unit": "px"
                        },
                        "right": {
                            "value": 0,
                            "unit": "px"
                        },
                        "bottom": {
                            "value": 0,
                            "unit": "px"
                        },
                        "left": {
                            "value": 0,
                            "unit": "px"
                        }
                    },
                    "style": {
                        "top": "solid",
                        "right": "solid",
                        "left": "solid",
                        "bottom": "solid"
                    },
                    "color": {
                        "top": {
                            "red": 176,
                            "green": 169,
                            "blue": 134,
                            "alpha": 1
                        },
                        "right": {
                            "red": 176,
                            "green": 169,
                            "blue": 134,
                            "alpha": 1
                        },
                        "left": {
                            "red": 176,
                            "green": 169,
                            "blue": 134,
                            "alpha": 1
                        },
                        "bottom": {
                            "red": 176,
                            "green": 169,
                            "blue": 134,
                            "alpha": 1
                        }
                    }
                },
                "cssBorderRadius": {
                    "topLeft": {
                        "value": 0,
                        "unit": "px"
                    },
                    "topRight": {
                        "value": 0,
                        "unit": "px"
                    },
                    "bottomLeft": {
                        "value": 0,
                        "unit": "px"
                    },
                    "bottomRight": {
                        "value": 0,
                        "unit": "px"
                    }
                }
            },
            "themeMappings": {},
            "charas": "design-ivwdmh2u",
            "dataChangeBehaviors": []
        },
        "style": {
            "type": "TopLevelStyle",
            "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false
            },
            "style": {
                "properties": {
                    "alpha-bg": "1",
                    "alpha-brd": "1",
                    "bg": "206,224,193,1",
                    "boxShadowToggleOn-shd": "false",
                    "brd": "#324158",
                    "brw": "0px",
                    "rd": "0px",
                    "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                },
                "propertiesSource": {
                    "bg": "value",
                    "brd": "value",
                    "brw": "value",
                    "rd": "value",
                    "shd": "value"
                },
                "groups": {}
            },
            "componentClassName": "wysiwyg.viewer.components.HoverBox",
            "pageId": "",
            "compId": "",
            "styleType": "custom",
            "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer"
        },
        "mobileStructure": {
            "type": "Container",
            "modes": {
                "definitions": [
                    {
                        "modeId": "comp-iwavmy8d-mode-iwavmy8w",
                        "type": "DEFAULT",
                        "label": "MODE_LABEL_FROM_PRESET",
                        "params": ""
                    },
                    {
                        "modeId": "comp-iwavmy8d-mode-iwavmy8w1",
                        "type": "HOVER",
                        "label": "MODE_LABEL_FROM_PRESET",
                        "params": ""
                    }
                ]
            },
            "layout": {
                "width": 280,
                "height": 280,
                "x": 20,
                "y": 10,
                "scale": 1,
                "rotationInDegrees": 0,
                "fixedPosition": false
            },
            "componentType": "wysiwyg.viewer.components.HoverBox",
            "props": {
                "type": "HoverBoxProperties",
                "metaData": {
                    "schemaVersion": "1.0"
                },
                "mobileDisplayedModeId": "comp-iwavmy8d-mode-iwavmy8w1"
            },
            "design": {
                "type": "MediaContainerDesignData",
                "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false
                },
                "background": {
                    "type": "BackgroundMedia",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "color": "#36D1B5",
                    "colorOpacity": 1,
                    "alignType": "center",
                    "fittingType": "fill",
                    "scrollType": "none",
                    "colorOverlay": "",
                    "colorOverlayOpacity": 0,
                    "showOverlayForMediaType": "all",
                    "mediaTransforms": {
                        "scale": 1
                    }
                },
                "cssStyle": {
                    "cssBorder": {
                        "width": {
                            "top": {
                                "value": 0,
                                "unit": "px"
                            },
                            "right": {
                                "value": 0,
                                "unit": "px"
                            },
                            "bottom": {
                                "value": 0,
                                "unit": "px"
                            },
                            "left": {
                                "value": 0,
                                "unit": "px"
                            }
                        },
                        "style": {
                            "top": "solid",
                            "right": "solid",
                            "left": "solid",
                            "bottom": "solid"
                        },
                        "color": {
                            "top": {
                                "red": 176,
                                "green": 169,
                                "blue": 134,
                                "alpha": 1
                            },
                            "right": {
                                "red": 176,
                                "green": 169,
                                "blue": 134,
                                "alpha": 1
                            },
                            "left": {
                                "red": 176,
                                "green": 169,
                                "blue": 134,
                                "alpha": 1
                            },
                            "bottom": {
                                "red": 176,
                                "green": 169,
                                "blue": 134,
                                "alpha": 1
                            }
                        }
                    },
                    "cssBorderRadius": {
                        "topLeft": {
                            "value": 0,
                            "unit": "px"
                        },
                        "topRight": {
                            "value": 0,
                            "unit": "px"
                        },
                        "bottomLeft": {
                            "value": 0,
                            "unit": "px"
                        },
                        "bottomRight": {
                            "value": 0,
                            "unit": "px"
                        }
                    }
                },
                "themeMappings": {},
                "charas": "design-ivwdmh2u",
                "dataChangeBehaviors": [
                    {
                        "trigger": "in",
                        "type": "animation",
                        "part": "media",
                        "name": "Scale",
                        "params": {
                            "delay": 0,
                            "duration": 0.5
                        }
                    },
                    {
                        "trigger": "out",
                        "type": "animation",
                        "part": "media",
                        "name": "Scale",
                        "params": {
                            "delay": 0,
                            "duration": 0.5
                        }
                    }
                ]
            },
            "style": {
                "type": "TopLevelStyle",
                "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false
                },
                "style": {
                    "properties": {
                        "alpha-bg": "1",
                        "alpha-brd": "1",
                        "bg": "206,224,193,1",
                        "boxShadowToggleOn-shd": "false",
                        "brd": "#324158",
                        "brw": "0px",
                        "rd": "0px",
                        "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                    },
                    "propertiesSource": {
                        "bg": "value",
                        "brd": "value",
                        "brw": "value",
                        "rd": "value",
                        "shd": "value"
                    },
                    "groups": {}
                },
                "componentClassName": "wysiwyg.viewer.components.HoverBox",
                "pageId": "",
                "compId": "",
                "styleType": "custom",
                "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer"
            }
        },
        "activeModes": {}
    },
    "preset": {
        "rect": {
            "width": 162,
            "height": 178,
            "x": -1,
            "y": 221
        }
    }
}
const hbPreset3 = {
    "id": "hoverBoxPreset3",
    "structure": {
        "type": "Container",
        "components": [
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwavjabs-mode-iwavjaf5"
                            ],
                            "layout": {
                                "width": 216,
                                "height": 36,
                                "x": 34,
                                "y": 189,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "isHiddenByModes": false
                        }
                    ],
                    "isHiddenByModes": true
                },
                "layout": {
                    "width": 216,
                    "height": 26,
                    "x": 34,
                    "y": 179,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "text": "<h6 class=\"font_6\" style=\"font-size:34px; line-height:1em; text-align:center\"><span style=\"color:#303F4D\"><span style=\"font-size:34px\"><span style=\"font-family:belinda-w00-regular,script\">Name, Title</span></span></span></h6>\n",
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": []
                },
                "props": {
                    "type": "WRichTextProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "brightness": 1,
                    "packed": true
                },
                "style": "txtNew",
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"action\":\"modeIn\",\"name\":\"FadeIn\",\"delay\":0,\"duration\":0.35},{\"action\":\"modeOut\",\"name\":\"FadeOut\",\"delay\":0,\"duration\":0.35}]"
                }
            },
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwavjabs-mode-iwavjaf5"
                            ],
                            "layout": {
                                "width": 271,
                                "height": 26,
                                "x": 6,
                                "y": 232,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "isHiddenByModes": false
                        }
                    ],
                    "isHiddenByModes": true
                },
                "layout": {
                    "width": 271,
                    "height": 25,
                    "x": 13,
                    "y": 228,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "text": "<p class=\"font_8\" style=\"line-height:1.7em; text-align:center\"><span style=\"color:#FFFFFF\"><span style=\"letter-spacing:0.04em\"><span style=\"font-family:futura-lt-w01-light,sans-serif\">Why We&#39;re Great &gt;</span></span></span></p>\n",
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": []
                },
                "props": {
                    "type": "WRichTextProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "brightness": 1,
                    "packed": true
                },
                "style": "txtNew",
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"action\":\"modeIn\",\"name\":\"FadeIn\",\"delay\":0,\"duration\":0.35},{\"action\":\"modeOut\",\"name\":\"FadeOut\",\"delay\":0,\"duration\":0.35}]"
                }
            },
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwavjabs-mode-iwavjaf51"
                            ],
                            "layout": {
                                "width": 52,
                                "height": 53,
                                "x": 116,
                                "y": 52,
                                "scale": 1,
                                "rotationInDegrees": 180,
                                "fixedPosition": false
                            },
                            "isHiddenByModes": false,
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "style": {
                                    "properties": {
                                        "alpha-fillcolor": "1",
                                        "alpha-stroke": "1",
                                        "fillcolor": "#FFC65C",
                                        "stroke": "#ED1566",
                                        "strokewidth": "0"
                                    },
                                    "propertiesSource": {
                                        "fillcolor": "value",
                                        "stroke": "value",
                                        "strokewidth": "value"
                                    },
                                    "groups": {}
                                },
                                "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "svgshape.v2.Svg_d056319704ff4681ace04da1b153c69a"
                            }
                        }
                    ],
                    "isHiddenByModes": true
                },
                "skin": "svgshape.v2.Svg_d056319704ff4681ace04da1b153c69a",
                "layout": {
                    "width": 34,
                    "height": 32,
                    "x": 125,
                    "y": 43,
                    "scale": 1,
                    "rotationInDegrees": 180,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.svgshape.SvgShape",
                "data": {
                    "type": "SvgShape",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    }
                },
                "props": {
                    "type": "SvgShapeProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "maintainAspectRatio": true
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "style": {
                        "properties": {
                            "alpha-fillcolor": "1",
                            "alpha-stroke": "1",
                            "fillcolor": "#F27979",
                            "stroke": "#ED1566",
                            "strokewidth": "0"
                        },
                        "propertiesSource": {
                            "fillcolor": "value",
                            "stroke": "value",
                            "strokewidth": "value"
                        },
                        "groups": {}
                    },
                    "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "svgshape.v2.Svg_d056319704ff4681ace04da1b153c69a"
                },
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"action\":\"modeIn\",\"name\":\"FadeIn\",\"delay\":0,\"duration\":0.35,\"params\":{\"modeIds\":[\"comp-iwavjabs-mode-iwavjaf51\"]},\"targetId\":\"\"},{\"action\":\"modeOut\",\"name\":\"FadeOut\",\"delay\":0,\"duration\":0.35,\"params\":{\"modeIds\":[\"comp-iwavjabs-mode-iwavjaf51\"]},\"targetId\":\"\"}]"
                },
                "mobileStructure": {
                    "type": "Component",
                    "modes": {
                        "definitions": []
                    },
                    "skin": "svgshape.v2.Svg_d056319704ff4681ace04da1b153c69a",
                    "layout": {
                        "width": 52,
                        "height": 53,
                        "x": 114,
                        "y": 50,
                        "scale": 1,
                        "rotationInDegrees": 180,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.svgshape.SvgShape",
                    "data": {
                        "type": "SvgShape",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        }
                    },
                    "props": {
                        "type": "SvgShapeProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "maintainAspectRatio": true
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "properties": {
                                "alpha-fillcolor": "1",
                                "alpha-stroke": "1",
                                "fillcolor": "#FFC65C",
                                "stroke": "#ED1566",
                                "strokewidth": "0"
                            },
                            "propertiesSource": {
                                "fillcolor": "value",
                                "stroke": "value",
                                "strokewidth": "value"
                            },
                            "groups": {}
                        },
                        "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "svgshape.v2.Svg_d056319704ff4681ace04da1b153c69a"
                    },
                    "behaviors": {
                        "type": "ObsoleteBehaviorsList",
                        "items": "[{\"action\":\"modeIn\",\"name\":\"FadeIn\",\"delay\":0,\"duration\":0.35,\"params\":{\"modeIds\":[\"comp-iwavjabs-mode-iwavjaf51\"]},\"targetId\":\"\"},{\"action\":\"modeOut\",\"name\":\"FadeOut\",\"delay\":0,\"duration\":0.35,\"params\":{\"modeIds\":[\"comp-iwavjabs-mode-iwavjaf51\"]},\"targetId\":\"\"}]"
                    }
                }
            },
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwavjabs-mode-iwavjaf5"
                            ],
                            "layout": {
                                "width": 150,
                                "height": 150,
                                "x": 67,
                                "y": 30,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "isHiddenByModes": false,
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "style": {
                                    "properties": {
                                        "alpha-brd": "0",
                                        "boxShadowToggleOn-shd": "false",
                                        "brd": "#303F4D",
                                        "brw": "0",
                                        "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                                    },
                                    "propertiesSource": {
                                        "alpha-brd": "value",
                                        "brd": "value",
                                        "brw": "value",
                                        "shd": "value"
                                    },
                                    "groups": {}
                                },
                                "componentClassName": "wysiwyg.viewer.components.WPhoto",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.photo.CirclePhoto"
                            }
                        }
                    ],
                    "isHiddenByModes": true
                },
                "skin": "wysiwyg.viewer.skins.photo.CirclePhoto",
                "layout": {
                    "width": 130,
                    "height": 130,
                    "x": 75,
                    "y": 20,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WPhoto",
                "data": {
                    "type": "Image",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "2.0",
                        "isHidden": false
                    },
                    "title": "",
                    "uri": "035244_49fa1767a96f459f8a97bf165f96784b~mv2_d_4676_4871_s_4_2.jpg",
                    "description": "",
                    "width": 4676,
                    "height": 4871,
                    "alt": ""
                },
                "props": {
                    "type": "WPhotoProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "displayMode": "fill",
                    "onClickBehavior": "goToLink",
                    "effectName": "kennedy"
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "style": {
                        "properties": {
                            "alpha-brd": "0",
                            "boxShadowToggleOn-shd": "false",
                            "brd": "#FF6161",
                            "brw": "0px",
                            "shd": "0px 1px 3px 0px rgba(0,0,0,0.5)"
                        },
                        "propertiesSource": {
                            "alpha-brd": "value",
                            "brd": "value",
                            "brw": "value",
                            "shd": "value"
                        },
                        "groups": {}
                    },
                    "componentClassName": "wysiwyg.viewer.components.WPhoto",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.photo.CirclePhoto"
                },
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"action\":\"modeIn\",\"name\":\"FadeIn\",\"delay\":0,\"duration\":0.35},{\"action\":\"modeOut\",\"name\":\"FadeOut\",\"delay\":0,\"duration\":0.35}]"
                }
            },
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwavjabs-mode-iwavjaf5"
                            ],
                            "layout": {
                                "width": 42,
                                "height": 48,
                                "x": 182,
                                "y": 33,
                                "scale": 1,
                                "rotationInDegrees": 180,
                                "fixedPosition": false
                            },
                            "isHiddenByModes": false,
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "style": {
                                    "properties": {
                                        "alpha-fillcolor": "1",
                                        "alpha-stroke": "1",
                                        "fillcolor": "#303F4D",
                                        "stroke": "#ED1566",
                                        "strokewidth": "0"
                                    },
                                    "propertiesSource": {
                                        "fillcolor": "value",
                                        "stroke": "value",
                                        "strokewidth": "value"
                                    },
                                    "groups": {}
                                },
                                "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "svgshape.v2.Svg_d056319704ff4681ace04da1b153c69a"
                            }
                        }
                    ],
                    "isHiddenByModes": true
                },
                "skin": "svgshape.v2.Svg_d056319704ff4681ace04da1b153c69a",
                "layout": {
                    "width": 52,
                    "height": 53,
                    "x": 189,
                    "y": 50,
                    "scale": 1,
                    "rotationInDegrees": 180,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.svgshape.SvgShape",
                "data": {
                    "type": "SvgShape",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    }
                },
                "props": {
                    "type": "SvgShapeProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "maintainAspectRatio": true
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "style": {
                        "properties": {
                            "alpha-fillcolor": "1",
                            "alpha-stroke": "1",
                            "fillcolor": "#F27979",
                            "stroke": "#ED1566",
                            "strokewidth": "0"
                        },
                        "propertiesSource": {
                            "fillcolor": "value",
                            "stroke": "value",
                            "strokewidth": "value"
                        },
                        "groups": {}
                    },
                    "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "svgshape.v2.Svg_d056319704ff4681ace04da1b153c69a"
                },
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"action\":\"modeIn\",\"name\":\"FadeIn\",\"delay\":0,\"duration\":0.35},{\"action\":\"modeOut\",\"name\":\"FadeOut\",\"delay\":0,\"duration\":0.35}]"
                }
            },
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwavjabs-mode-iwavjaf51"
                            ],
                            "layout": {
                                "width": 206,
                                "height": 100,
                                "x": 39,
                                "y": 129,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "isHiddenByModes": false
                        }
                    ],
                    "isHiddenByModes": true
                },
                "layout": {
                    "width": 206,
                    "height": 100,
                    "x": 366,
                    "y": 165,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "text": "<p class=\"font_8\" style=\"line-height:1.7em; text-align:center\"><span style=\"font-family:futura-lt-w01-light,sans-serif;\"><span style=\"color:#FFFFFF\">Share the amazing things customers are saying about your business. Double click, or click Edit Text to make it yours.</span></span></p>\n",
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": []
                },
                "props": {
                    "type": "WRichTextProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "brightness": 1,
                    "packed": true
                },
                "style": "txtNew",
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"action\":\"modeIn\",\"name\":\"FadeIn\",\"delay\":0,\"duration\":0.35,\"params\":{\"modeIds\":[\"comp-iwavjabs-mode-iwavjaf51\"]},\"targetId\":\"\"},{\"action\":\"modeOut\",\"name\":\"FadeOut\",\"delay\":0,\"duration\":0.35,\"params\":{\"modeIds\":[\"comp-iwavjabs-mode-iwavjaf51\"]},\"targetId\":\"\"}]"
                },
                "mobileStructure": {
                    "type": "Component",
                    "modes": {
                        "definitions": []
                    },
                    "layout": {
                        "width": 218,
                        "height": 108,
                        "x": 31,
                        "y": 127,
                        "scale": 1.05,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.WRichText",
                    "data": {
                        "type": "StyledText",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "text": "<p class=\"font_8\" style=\"line-height:1.7em; text-align:center\"><span style=\"font-family:futura-lt-w01-light,sans-serif;\"><span style=\"color:#FFFFFF\">Share the amazing things customers are saying about your business. Double click, or click Edit Text to make it yours.</span></span></p>\n",
                        "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                        "linkList": []
                    },
                    "props": {
                        "type": "WRichTextProperties",
                        "metaData": {
                            "schemaVersion": "1.0",
                            "autoGenerated": false
                        },
                        "brightness": 1,
                        "packed": true
                    },
                    "style": "txtNew",
                    "behaviors": {
                        "type": "ObsoleteBehaviorsList",
                        "items": "[{\"action\":\"modeIn\",\"name\":\"FadeIn\",\"delay\":0,\"duration\":0.35,\"params\":{\"modeIds\":[\"comp-iwavjabs-mode-iwavjaf51\"]},\"targetId\":\"\"},{\"action\":\"modeOut\",\"name\":\"FadeOut\",\"delay\":0,\"duration\":0.35,\"params\":{\"modeIds\":[\"comp-iwavjabs-mode-iwavjaf51\"]},\"targetId\":\"\"}]"
                    }
                }
            }
        ],
        "modes": {
            "definitions": [
                {
                    "modeId": "comp-iwavjabs-mode-iwavjaf5",
                    "type": "DEFAULT",
                    "label": "MODE_LABEL_FROM_PRESET",
                    "params": ""
                },
                {
                    "modeId": "comp-iwavjabs-mode-iwavjaf51",
                    "type": "HOVER",
                    "label": "MODE_LABEL_FROM_PRESET",
                    "params": ""
                }
            ],
            "overrides": [
                {
                    "modeIds": [
                        "comp-iwavjabs-mode-iwavjaf51"
                    ],
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "1.0",
                                "isHidden": false
                            },
                            "color": "#303F4D",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0,
                            "showOverlayForMediaType": "WixVideo",
                            "mediaTransforms": {
                                "scale": 1
                            }
                        },
                        "cssStyle": {
                            "cssBorder": {
                                "width": {
                                    "top": {
                                        "value": 0,
                                        "unit": "px"
                                    },
                                    "right": {
                                        "value": 0,
                                        "unit": "px"
                                    },
                                    "bottom": {
                                        "value": 0,
                                        "unit": "px"
                                    },
                                    "left": {
                                        "value": 0,
                                        "unit": "px"
                                    }
                                },
                                "style": {
                                    "top": "solid",
                                    "right": "solid",
                                    "left": "solid",
                                    "bottom": "solid"
                                },
                                "color": {
                                    "top": {
                                        "red": 48,
                                        "green": 63,
                                        "blue": 77,
                                        "alpha": 1
                                    },
                                    "right": {
                                        "red": 48,
                                        "green": 63,
                                        "blue": 77,
                                        "alpha": 1
                                    },
                                    "left": {
                                        "red": 48,
                                        "green": 63,
                                        "blue": 77,
                                        "alpha": 1
                                    },
                                    "bottom": {
                                        "red": 48,
                                        "green": 63,
                                        "blue": 77,
                                        "alpha": 1
                                    }
                                }
                            },
                            "cssBorderRadius": {
                                "topLeft": {
                                    "value": 0,
                                    "unit": "px"
                                },
                                "topRight": {
                                    "value": 0,
                                    "unit": "px"
                                },
                                "bottomLeft": {
                                    "value": 0,
                                    "unit": "px"
                                },
                                "bottomRight": {
                                    "value": 0,
                                    "unit": "px"
                                }
                            }
                        },
                        "themeMappings": {},
                        "charas": "design-iwavcu30",
                        "dataChangeBehaviors": []
                    }
                },
                {
                    "modeIds": [
                        "comp-iwavjabs-mode-iwavjaf5"
                    ],
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "1.0",
                                "isHidden": false
                            },
                            "color": "#FFC65C",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0,
                            "showOverlayForMediaType": "WixVideo",
                            "mediaTransforms": {
                                "scale": 1
                            }
                        },
                        "cssStyle": {
                            "cssBorder": {
                                "width": {
                                    "top": {
                                        "value": 0,
                                        "unit": "px"
                                    },
                                    "right": {
                                        "value": 0,
                                        "unit": "px"
                                    },
                                    "bottom": {
                                        "value": 0,
                                        "unit": "px"
                                    },
                                    "left": {
                                        "value": 0,
                                        "unit": "px"
                                    }
                                },
                                "style": {
                                    "top": "solid",
                                    "right": "solid",
                                    "left": "solid",
                                    "bottom": "solid"
                                },
                                "color": {
                                    "top": {
                                        "red": 48,
                                        "green": 63,
                                        "blue": 77,
                                        "alpha": 1
                                    },
                                    "right": {
                                        "red": 48,
                                        "green": 63,
                                        "blue": 77,
                                        "alpha": 1
                                    },
                                    "left": {
                                        "red": 48,
                                        "green": 63,
                                        "blue": 77,
                                        "alpha": 1
                                    },
                                    "bottom": {
                                        "red": 48,
                                        "green": 63,
                                        "blue": 77,
                                        "alpha": 1
                                    }
                                }
                            },
                            "cssBorderRadius": {
                                "topLeft": {
                                    "value": 0,
                                    "unit": "px"
                                },
                                "topRight": {
                                    "value": 0,
                                    "unit": "px"
                                },
                                "bottomLeft": {
                                    "value": 0,
                                    "unit": "px"
                                },
                                "bottomRight": {
                                    "value": 0,
                                    "unit": "px"
                                }
                            }
                        },
                        "themeMappings": {},
                        "charas": "design-iwff9jxv",
                        "dataChangeBehaviors": []
                    }
                }
            ],
            "isHiddenByModes": false
        },
        "layout": {
            "width": 284,
            "height": 285,
            "x": 0,
            "y": 0,
            "scale": 1,
            "rotationInDegrees": 0,
            "fixedPosition": false
        },
        "componentType": "wysiwyg.viewer.components.HoverBox",
        "props": {
            "type": "HoverBoxProperties",
            "metaData": {
                "schemaVersion": "1.0"
            },
            "mobileDisplayedModeId": "comp-iwavjabs-mode-iwavjaf51"
        },
        "design": {
            "type": "MediaContainerDesignData",
            "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false
            },
            "background": {
                "type": "BackgroundMedia",
                "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false
                },
                "color": "#FFC65C",
                "colorOpacity": 1,
                "alignType": "center",
                "fittingType": "fill",
                "scrollType": "none",
                "colorOverlay": "",
                "colorOverlayOpacity": 0,
                "showOverlayForMediaType": "all",
                "mediaTransforms": {
                    "scale": 1
                }
            },
            "cssStyle": {
                "cssBorder": {
                    "width": {
                        "top": {
                            "value": 0,
                            "unit": "px"
                        },
                        "right": {
                            "value": 0,
                            "unit": "px"
                        },
                        "bottom": {
                            "value": 0,
                            "unit": "px"
                        },
                        "left": {
                            "value": 0,
                            "unit": "px"
                        }
                    },
                    "style": {
                        "top": "solid",
                        "right": "solid",
                        "left": "solid",
                        "bottom": "solid"
                    },
                    "color": {
                        "top": {
                            "red": 48,
                            "green": 63,
                            "blue": 77,
                            "alpha": 1
                        },
                        "right": {
                            "red": 48,
                            "green": 63,
                            "blue": 77,
                            "alpha": 1
                        },
                        "left": {
                            "red": 48,
                            "green": 63,
                            "blue": 77,
                            "alpha": 1
                        },
                        "bottom": {
                            "red": 48,
                            "green": 63,
                            "blue": 77,
                            "alpha": 1
                        }
                    }
                },
                "cssBorderRadius": {
                    "topLeft": {
                        "value": 0,
                        "unit": "px"
                    },
                    "topRight": {
                        "value": 0,
                        "unit": "px"
                    },
                    "bottomLeft": {
                        "value": 0,
                        "unit": "px"
                    },
                    "bottomRight": {
                        "value": 0,
                        "unit": "px"
                    }
                }
            },
            "themeMappings": {},
            "charas": "design-iw3agbvu",
            "dataChangeBehaviors": []
        },
        "style": {
            "type": "TopLevelStyle",
            "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false
            },
            "style": {
                "properties": {
                    "alpha-bg": "1",
                    "alpha-brd": "1",
                    "bg": "206,224,193,1",
                    "boxShadowToggleOn-shd": "false",
                    "brd": "#324158",
                    "brw": "0px",
                    "rd": "0px",
                    "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                },
                "propertiesSource": {
                    "bg": "value",
                    "brd": "value",
                    "brw": "value",
                    "rd": "value",
                    "shd": "value"
                },
                "groups": {}
            },
            "componentClassName": "wysiwyg.viewer.components.HoverBox",
            "pageId": "",
            "compId": "",
            "styleType": "custom",
            "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer"
        },
        "mobileStructure": {
            "type": "Container",
            "modes": {
                "definitions": [
                    {
                        "modeId": "comp-iwavjabs-mode-iwavjaf5",
                        "type": "DEFAULT",
                        "label": "MODE_LABEL_FROM_PRESET",
                        "params": ""
                    },
                    {
                        "modeId": "comp-iwavjabs-mode-iwavjaf51",
                        "type": "HOVER",
                        "label": "MODE_LABEL_FROM_PRESET",
                        "params": ""
                    }
                ]
            },
            "layout": {
                "width": 280,
                "height": 280,
                "x": 20,
                "y": 10,
                "scale": 1,
                "rotationInDegrees": 0,
                "fixedPosition": false
            },
            "componentType": "wysiwyg.viewer.components.HoverBox",
            "props": {
                "type": "HoverBoxProperties",
                "metaData": {
                    "schemaVersion": "1.0"
                },
                "mobileDisplayedModeId": "comp-iwavjabs-mode-iwavjaf51"
            },
            "design": {
                "type": "MediaContainerDesignData",
                "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false
                },
                "background": {
                    "type": "BackgroundMedia",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "color": "#303F4D",
                    "colorOpacity": 1,
                    "alignType": "center",
                    "fittingType": "fill",
                    "scrollType": "none",
                    "colorOverlay": "",
                    "colorOverlayOpacity": 0,
                    "showOverlayForMediaType": "WixVideo",
                    "mediaTransforms": {
                        "scale": 1
                    }
                },
                "cssStyle": {
                    "cssBorder": {
                        "width": {
                            "top": {
                                "value": 0,
                                "unit": "px"
                            },
                            "right": {
                                "value": 0,
                                "unit": "px"
                            },
                            "bottom": {
                                "value": 0,
                                "unit": "px"
                            },
                            "left": {
                                "value": 0,
                                "unit": "px"
                            }
                        },
                        "style": {
                            "top": "solid",
                            "right": "solid",
                            "left": "solid",
                            "bottom": "solid"
                        },
                        "color": {
                            "top": {
                                "red": 48,
                                "green": 63,
                                "blue": 77,
                                "alpha": 1
                            },
                            "right": {
                                "red": 48,
                                "green": 63,
                                "blue": 77,
                                "alpha": 1
                            },
                            "left": {
                                "red": 48,
                                "green": 63,
                                "blue": 77,
                                "alpha": 1
                            },
                            "bottom": {
                                "red": 48,
                                "green": 63,
                                "blue": 77,
                                "alpha": 1
                            }
                        }
                    },
                    "cssBorderRadius": {
                        "topLeft": {
                            "value": 0,
                            "unit": "px"
                        },
                        "topRight": {
                            "value": 0,
                            "unit": "px"
                        },
                        "bottomLeft": {
                            "value": 0,
                            "unit": "px"
                        },
                        "bottomRight": {
                            "value": 0,
                            "unit": "px"
                        }
                    }
                },
                "themeMappings": {},
                "charas": "design-iwavcu30",
                "dataChangeBehaviors": []
            },
            "style": {
                "type": "TopLevelStyle",
                "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false
                },
                "style": {
                    "properties": {
                        "alpha-bg": "1",
                        "alpha-brd": "1",
                        "bg": "206,224,193,1",
                        "boxShadowToggleOn-shd": "false",
                        "brd": "#324158",
                        "brw": "0px",
                        "rd": "0px",
                        "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                    },
                    "propertiesSource": {
                        "bg": "value",
                        "brd": "value",
                        "brw": "value",
                        "rd": "value",
                        "shd": "value"
                    },
                    "groups": {}
                },
                "componentClassName": "wysiwyg.viewer.components.HoverBox",
                "pageId": "",
                "compId": "",
                "styleType": "custom",
                "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer"
            }
        },
        "activeModes": {}
    },
    "preset": {
        "rect": {
            "width": 162,
            "height": 178,
            "x": 161,
            "y": 221
        }
    }
}
const hbPreset4 = {
    "id": "hoverBoxPreset4",
    "structure": {
        "type": "Container",
        "components": [
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iw6jk42f-mode-iw6jk45r1"
                            ],
                            "layout": {
                                "width": 368,
                                "height": 47,
                                "x": 43,
                                "y": 156,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            }
                        },
                        {
                            "modeIds": [
                                "comp-iw6jk42f-mode-iw6jk45r"
                            ],
                            "layout": {
                                "width": 368,
                                "height": 47,
                                "x": 120,
                                "y": 156,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            }
                        }
                    ],
                    "isHiddenByModes": false
                },
                "layout": {
                    "width": 368,
                    "height": 47,
                    "x": 120,
                    "y": 156,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "text": "<h6 class=\"font_6\" style=\"text-align: center; font-size: 40px;\"><span style=\"color:#FFFFFF;\"><span style=\"font-weight:bold;\"><span style=\"letter-spacing:0.05em;\"><span style=\"font-size:40px;\"><span style=\"font-family:raleway,sans-serif;\">DISCOVER</span></span></span></span></span></h6>\n",
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": []
                },
                "props": {
                    "type": "WRichTextProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "brightness": 1,
                    "packed": true
                },
                "style": "txtNew",
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"name\":\"FloatIn\",\"delay\":0,\"duration\":0.2,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iw6jk42f-mode-iw6jk45r1\"]},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"FloatOut\",\"delay\":0,\"duration\":0.2,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iw6jk42f-mode-iw6jk45r1\"]},\"action\":\"modeOut\",\"targetId\":\"\"},{\"action\":\"modeChange\",\"name\":\"ModesMotion\",\"delay\":0,\"duration\":0.35,\"params\":{\"timingFunction\":\"EaseInOut\",\"modeIds\":[\"comp-iw6jk42f-mode-iw6jk45r1\"]},\"targetId\":\"\"},{\"action\":\"modeIn\",\"name\":\"FadeIn\",\"delay\":0,\"duration\":0.35},{\"action\":\"modeOut\",\"name\":\"FadeOut\",\"delay\":0,\"duration\":0.35}]"
                },
                "mobileStructure": {
                    "type": "Component",
                    "modes": {
                        "definitions": []
                    },
                    "layout": {
                        "width": 260,
                        "height": 44,
                        "x": 10,
                        "y": 38,
                        "scale": 1.245825,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.WRichText",
                    "data": {
                        "type": "StyledText",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "text": "<h6 class=\"font_6\" style=\"text-align: center; font-size: 40px;\"><span style=\"color:#FFFFFF;\"><span style=\"font-weight:bold;\"><span style=\"letter-spacing:0.05em;\"><span style=\"font-size:40px;\"><span style=\"font-family:raleway,sans-serif;\">DISCOVER</span></span></span></span></span></h6>\n",
                        "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                        "linkList": []
                    },
                    "props": {
                        "type": "WRichTextProperties",
                        "metaData": {
                            "schemaVersion": "1.0",
                            "autoGenerated": false
                        },
                        "brightness": 1,
                        "packed": true
                    },
                    "style": "txtNew",
                    "behaviors": {
                        "type": "ObsoleteBehaviorsList",
                        "items": "[{\"name\":\"FloatIn\",\"delay\":0,\"duration\":0.2,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iw6jk42f-mode-iw6jk45r1\"]},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"FloatOut\",\"delay\":0,\"duration\":0.2,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iw6jk42f-mode-iw6jk45r1\"]},\"action\":\"modeOut\",\"targetId\":\"\"},{\"action\":\"modeChange\",\"name\":\"ModesMotion\",\"delay\":0,\"duration\":0.35,\"params\":{\"timingFunction\":\"EaseInOut\",\"modeIds\":[\"comp-iw6jk42f-mode-iw6jk45r1\"]},\"targetId\":\"\"},{\"action\":\"modeIn\",\"name\":\"FadeIn\",\"delay\":0,\"duration\":0.35},{\"action\":\"modeOut\",\"name\":\"FadeOut\",\"delay\":0,\"duration\":0.35}]"
                    }
                }
            },
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iw6jk42f-mode-iw6jk45r1"
                            ],
                            "layout": {
                                "width": 16,
                                "height": 28,
                                "x": 476,
                                "y": 166,
                                "scale": 1,
                                "rotationInDegrees": 180,
                                "fixedPosition": false
                            },
                            "isHiddenByModes": false,
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "style": {
                                    "properties": {
                                        "alpha-fillcolor": "1",
                                        "alpha-stroke": "1",
                                        "fillcolor": "color_11",
                                        "stroke": "color_11",
                                        "strokewidth": "1"
                                    },
                                    "propertiesSource": {
                                        "fillcolor": "theme",
                                        "stroke": "theme",
                                        "strokewidth": "value"
                                    },
                                    "groups": {}
                                },
                                "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "svgshape.v2.Svg_2ac06149d42a4d698b6e0a0dd0e629f8"
                            }
                        }
                    ],
                    "isHiddenByModes": true
                },
                "skin": "svgshape.v2.Svg_2ac06149d42a4d698b6e0a0dd0e629f8",
                "layout": {
                    "width": 33,
                    "height": 102,
                    "x": 304,
                    "y": 181,
                    "scale": 1,
                    "rotationInDegrees": 180,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.svgshape.SvgShape",
                "data": {
                    "type": "SvgShape",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "link": {
                        "type": "PageLink",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "pageId": "#c1dmp"
                    }
                },
                "props": {
                    "type": "SvgShapeProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "maintainAspectRatio": true
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "style": {
                        "properties": {
                            "alpha-fillcolor": "1",
                            "alpha-stroke": "1",
                            "fillcolor": "#01B1AF",
                            "stroke": "#ED1566",
                            "strokewidth": "0px"
                        },
                        "propertiesSource": {
                            "fillcolor": "value",
                            "stroke": "value",
                            "strokewidth": "value"
                        },
                        "groups": {}
                    },
                    "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "svgshape.v2.Svg_2ac06149d42a4d698b6e0a0dd0e629f8"
                },
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"name\":\"FloatIn\",\"delay\":0,\"duration\":0.35,\"params\":{\"direction\":\"left\",\"modeIds\":[\"comp-iw6jk42f-mode-iw6jk45r1\"]},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"FloatOut\",\"delay\":0,\"duration\":0.35,\"params\":{\"direction\":\"left\",\"modeIds\":[\"comp-iw6jk42f-mode-iw6jk45r1\"]},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                },
                "mobileStructure": {
                    "type": "Component",
                    "modes": {
                        "definitions": []
                    },
                    "skin": "svgshape.v2.Svg_2ac06149d42a4d698b6e0a0dd0e629f8",
                    "layout": {
                        "width": 16,
                        "height": 26,
                        "x": 202,
                        "y": 92,
                        "scale": 1,
                        "rotationInDegrees": 180,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.svgshape.SvgShape",
                    "data": {
                        "type": "SvgShape",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "link": {
                            "type": "PageLink",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "1.0",
                                "isHidden": false
                            },
                            "pageId": "#c1dmp"
                        }
                    },
                    "props": {
                        "type": "SvgShapeProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "maintainAspectRatio": true
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "properties": {
                                "alpha-fillcolor": "1",
                                "alpha-stroke": "1",
                                "fillcolor": "color_11",
                                "stroke": "color_11",
                                "strokewidth": "1"
                            },
                            "propertiesSource": {
                                "fillcolor": "theme",
                                "stroke": "theme",
                                "strokewidth": "value"
                            },
                            "groups": {}
                        },
                        "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "svgshape.v2.Svg_2ac06149d42a4d698b6e0a0dd0e629f8"
                    },
                    "behaviors": {
                        "type": "ObsoleteBehaviorsList",
                        "items": "[{\"name\":\"FloatIn\",\"delay\":0,\"duration\":0.35,\"params\":{\"direction\":\"left\",\"modeIds\":[\"comp-iw6jk42f-mode-iw6jk45r1\"]},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"FloatOut\",\"delay\":0,\"duration\":0.35,\"params\":{\"direction\":\"left\",\"modeIds\":[\"comp-iw6jk42f-mode-iw6jk45r1\"]},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                    }
                }
            },
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iw6jk42f-mode-iw6jk45r1"
                            ],
                            "layout": {
                                "width": 157,
                                "height": 47,
                                "x": 329,
                                "y": 156,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "isHiddenByModes": false,
                            "style": "txtNew",
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {
                                    "schemaVersion": "1.0"
                                },
                                "brightness": 1,
                                "packed": true
                            }
                        }
                    ],
                    "isHiddenByModes": true
                },
                "layout": {
                    "width": 157,
                    "height": 47,
                    "x": 275,
                    "y": 156,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "text": "<h6 class=\"font_6\" style=\"font-size:40px; text-align:center\"><a dataquery=\"#textLink_iw55rp14\"><span style=\"font-family:raleway,sans-serif\"><span style=\"letter-spacing:0.05em\"><span style=\"color:#FFFFFF\"><span style=\"font-size:40px\">MORE</span></span></span></span></a></h6>\n",
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": [
                        {
                            "type": "PageLink",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "1.0",
                                "isHidden": false
                            },
                            "pageId": "#c1dmp"
                        }
                    ]
                },
                "props": {
                    "type": "WRichTextProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "brightness": 1,
                    "packed": true
                },
                "style": "txtNew",
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"name\":\"FloatIn\",\"delay\":0,\"duration\":0.35,\"params\":{\"direction\":\"left\",\"modeIds\":[\"comp-iw6jk42f-mode-iw6jk45r1\"]},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"FloatOut\",\"delay\":0,\"duration\":0.35,\"params\":{\"direction\":\"left\",\"modeIds\":[\"comp-iw6jk42f-mode-iw6jk45r1\"]},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                },
                "custom": {
                    "linkList": [
                        {
                            "type": "PageLink",
                            "id": "dataItem-iw6jk4622",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "1.0",
                                "isHidden": false
                            },
                            "pageId": "#c1dmp"
                        }
                    ]
                },
                "mobileStructure": {
                    "type": "Component",
                    "modes": {
                        "definitions": []
                    },
                    "layout": {
                        "width": 260,
                        "height": 45,
                        "x": 10,
                        "y": 82,
                        "scale": 1.25,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.WRichText",
                    "data": {
                        "type": "StyledText",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "text": "<h6 class=\"font_6\" style=\"font-size:40px; text-align:center\"><a dataquery=\"#textLink_iw55rp14\"><span style=\"font-family:raleway,sans-serif\"><span style=\"letter-spacing:0.05em\"><span style=\"color:#FFFFFF\"><span style=\"font-size:40px\">MORE</span></span></span></span></a></h6>\n",
                        "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                        "linkList": [
                            {
                                "type": "PageLink",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "pageId": "#c1dmp"
                            }
                        ]
                    },
                    "props": {
                        "type": "WRichTextProperties",
                        "metaData": {
                            "schemaVersion": "1.0",
                            "autoGenerated": false
                        },
                        "brightness": 1,
                        "packed": true
                    },
                    "style": "txtNew",
                    "behaviors": {
                        "type": "ObsoleteBehaviorsList",
                        "items": "[{\"name\":\"FloatIn\",\"delay\":0,\"duration\":0.35,\"params\":{\"direction\":\"left\",\"modeIds\":[\"comp-iw6jk42f-mode-iw6jk45r1\"]},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"FloatOut\",\"delay\":0,\"duration\":0.35,\"params\":{\"direction\":\"left\",\"modeIds\":[\"comp-iw6jk42f-mode-iw6jk45r1\"]},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                    },
                    "custom": {
                        "linkList": [
                            {
                                "type": "PageLink",
                                "id": "dataItem-iw6jk4622",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "pageId": "#c1dmp"
                            }
                        ]
                    }
                }
            },
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iw6jk42f-mode-iw6jk45r1"
                            ],
                            "layout": {
                                "width": 550,
                                "height": 24,
                                "x": 29,
                                "y": 218,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "isHiddenByModes": false,
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {
                                    "schemaVersion": "1.0"
                                },
                                "brightness": 1,
                                "packed": true
                            }
                        }
                    ],
                    "isHiddenByModes": true
                },
                "layout": {
                    "width": 304,
                    "height": 32,
                    "x": 160,
                    "y": 241,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "text": "<p class=\"font_8\" style=\"font-size:16px; line-height:1.5em; text-align:center\"><a dataquery=\"#textLink_iw55suon\"><span style=\"letter-spacing:0.05em\"><span style=\"font-size:16px\"><span style=\"font-family:raleway,sans-serif\"><span class=\"color_11\">Tell people what you do and give them a reason to click.</span></span></span></span></a></p>\n",
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": [
                        {
                            "type": "PageLink",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "1.0",
                                "isHidden": false
                            },
                            "pageId": "#c1dmp"
                        }
                    ]
                },
                "props": {
                    "type": "WRichTextProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "brightness": 1,
                    "packed": false
                },
                "style": "txtNew",
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"name\":\"FloatIn\",\"delay\":0,\"duration\":0.35,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iw6jk42f-mode-iw6jk45r1\"]},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"FloatOut\",\"delay\":0,\"duration\":0.35,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iw6jk42f-mode-iw6jk45r1\"]},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                },
                "custom": {
                    "linkList": [
                        {
                            "type": "PageLink",
                            "id": "dataItem-iw6jk4642",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "1.0",
                                "isHidden": false
                            },
                            "pageId": "#c1dmp"
                        }
                    ]
                },
                "mobileStructure": {
                    "type": "Component",
                    "modes": {
                        "definitions": []
                    },
                    "layout": {
                        "width": 241,
                        "height": 44,
                        "x": 19,
                        "y": 175,
                        "scale": 0.94,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.WRichText",
                    "data": {
                        "type": "StyledText",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "text": "<p class=\"font_8\" style=\"font-size:16px; line-height:1.5em; text-align:center\"><a dataquery=\"#textLink_iw55suon\"><span style=\"letter-spacing:0.05em\"><span style=\"font-size:16px\"><span style=\"font-family:raleway,sans-serif\"><span class=\"color_11\">Tell people what you do and give them a reason to click.</span></span></span></span></a></p>\n",
                        "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                        "linkList": [
                            {
                                "type": "PageLink",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "pageId": "#c1dmp"
                            }
                        ]
                    },
                    "props": {
                        "type": "WRichTextProperties",
                        "metaData": {
                            "schemaVersion": "1.0",
                            "autoGenerated": false
                        },
                        "brightness": 1,
                        "packed": true
                    },
                    "style": "txtNew",
                    "behaviors": {
                        "type": "ObsoleteBehaviorsList",
                        "items": "[{\"name\":\"FloatIn\",\"delay\":0,\"duration\":0.35,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iw6jk42f-mode-iw6jk45r1\"]},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"FloatOut\",\"delay\":0,\"duration\":0.35,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iw6jk42f-mode-iw6jk45r1\"]},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                    },
                    "custom": {
                        "linkList": [
                            {
                                "type": "PageLink",
                                "id": "dataItem-iw6jk4642",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "pageId": "#c1dmp"
                            }
                        ]
                    }
                }
            }
        ],
        "modes": {
            "definitions": [
                {
                    "modeId": "comp-iw6jk42f-mode-iw6jk45r",
                    "type": "DEFAULT",
                    "label": "MODE_LABEL_FROM_PRESET",
                    "params": ""
                },
                {
                    "modeId": "comp-iw6jk42f-mode-iw6jk45r1",
                    "type": "HOVER",
                    "label": "MODE_LABEL_FROM_PRESET",
                    "params": ""
                }
            ],
            "overrides": [],
            "isHiddenByModes": false
        },
        "layout": {
            "width": 608,
            "height": 360,
            "x": 0,
            "y": 0,
            "scale": 1,
            "rotationInDegrees": 0,
            "fixedPosition": false
        },
        "componentType": "wysiwyg.viewer.components.HoverBox",
        "props": {
            "type": "HoverBoxProperties",
            "metaData": {
                "schemaVersion": "1.0"
            },
            "mobileDisplayedModeId": "comp-iw6jk42f-mode-iw6jk45r1"
        },
        "design": {
            "type": "MediaContainerDesignData",
            "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false
            },
            "background": {
                "type": "BackgroundMedia",
                "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false
                },
                "mediaRef": {
                    "type": "Image",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "title": "leaf01.jpg",
                    "uri": "035244_ad6514b8cd2546ce8186c2017beb3981~mv2.jpg",
                    "description": "private/allMedia_picture",
                    "width": 1920,
                    "height": 1080,
                    "alt": "",
                    "artist": {
                        "id": "mobile_designItem_ivrsdpud2",
                        "name": ""
                    }
                },
                "color": "#F1BCAA",
                "colorOpacity": 1,
                "alignType": "center",
                "fittingType": "fill",
                "scrollType": "none",
                "colorOverlay": "",
                "colorOverlayOpacity": 0,
                "showOverlayForMediaType": "all",
                "mediaTransforms": {
                    "scale": 1
                }
            },
            "cssStyle": {
                "cssBorder": {
                    "width": {
                        "top": {
                            "value": 0,
                            "unit": "px"
                        },
                        "right": {
                            "value": 0,
                            "unit": "px"
                        },
                        "bottom": {
                            "value": 0,
                            "unit": "px"
                        },
                        "left": {
                            "value": 0,
                            "unit": "px"
                        }
                    },
                    "style": {
                        "top": "solid",
                        "right": "solid",
                        "left": "solid",
                        "bottom": "solid"
                    },
                    "color": {
                        "top": {
                            "red": 252,
                            "green": 217,
                            "blue": 40,
                            "alpha": 1
                        },
                        "right": {
                            "red": 252,
                            "green": 217,
                            "blue": 40,
                            "alpha": 1
                        },
                        "left": {
                            "red": 252,
                            "green": 217,
                            "blue": 40,
                            "alpha": 1
                        },
                        "bottom": {
                            "red": 252,
                            "green": 217,
                            "blue": 40,
                            "alpha": 1
                        }
                    }
                },
                "cssBorderRadius": {
                    "topLeft": {
                        "value": 0,
                        "unit": "px"
                    },
                    "topRight": {
                        "value": 0,
                        "unit": "px"
                    },
                    "bottomLeft": {
                        "value": 0,
                        "unit": "px"
                    },
                    "bottomRight": {
                        "value": 0,
                        "unit": "px"
                    }
                }
            },
            "themeMappings": {},
            "charas": "design-ivrsdpum",
            "dataChangeBehaviors": [
                {
                    "trigger": "in",
                    "type": "animation",
                    "part": "media",
                    "name": "Scale",
                    "params": {
                        "delay": 0,
                        "duration": 0.5
                    }
                },
                {
                    "trigger": "out",
                    "type": "animation",
                    "part": "media",
                    "name": "Scale",
                    "params": {
                        "delay": 0,
                        "duration": 0.5
                    }
                }
            ]
        },
        "style": {
            "type": "TopLevelStyle",
            "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false
            },
            "style": {
                "properties": {
                    "alpha-bg": "1",
                    "alpha-brd": "1",
                    "bg": "206,224,193,1",
                    "boxShadowToggleOn-shd": "false",
                    "brd": "#324158",
                    "brw": "0px",
                    "rd": "0px",
                    "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                },
                "propertiesSource": {
                    "bg": "value",
                    "brd": "value",
                    "brw": "value",
                    "rd": "value",
                    "shd": "value"
                },
                "groups": {}
            },
            "componentClassName": "wysiwyg.viewer.components.HoverBox",
            "pageId": "",
            "compId": "",
            "styleType": "custom",
            "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer"
        },
        "mobileStructure": {
            "type": "Container",
            "modes": {
                "definitions": [
                    {
                        "modeId": "comp-iw6jk42f-mode-iw6jk45r",
                        "type": "DEFAULT",
                        "label": "MODE_LABEL_FROM_PRESET",
                        "params": ""
                    },
                    {
                        "modeId": "comp-iw6jk42f-mode-iw6jk45r1",
                        "type": "HOVER",
                        "label": "MODE_LABEL_FROM_PRESET",
                        "params": ""
                    }
                ]
            },
            "layout": {
                "width": 280,
                "height": 260,
                "x": 20,
                "y": 10,
                "scale": 1,
                "rotationInDegrees": 0,
                "fixedPosition": false
            },
            "componentType": "wysiwyg.viewer.components.HoverBox",
            "props": {
                "type": "HoverBoxProperties",
                "metaData": {
                    "schemaVersion": "1.0"
                },
                "mobileDisplayedModeId": "comp-iw6jk42f-mode-iw6jk45r1"
            },
            "design": {
                "type": "MediaContainerDesignData",
                "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false
                },
                "background": {
                    "type": "BackgroundMedia",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "mediaRef": {
                        "type": "Image",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "title": "leaf01.jpg",
                        "uri": "035244_ad6514b8cd2546ce8186c2017beb3981~mv2.jpg",
                        "description": "private/allMedia_picture",
                        "width": 1920,
                        "height": 1080,
                        "alt": "",
                        "artist": {
                            "id": "mobile_designItem_ivrsdpud2",
                            "name": ""
                        }
                    },
                    "color": "#F1BCAA",
                    "colorOpacity": 1,
                    "alignType": "center",
                    "fittingType": "fill",
                    "scrollType": "none",
                    "colorOverlay": "",
                    "colorOverlayOpacity": 0,
                    "showOverlayForMediaType": "all",
                    "mediaTransforms": {
                        "scale": 1
                    }
                },
                "cssStyle": {
                    "cssBorder": {
                        "width": {
                            "top": {
                                "value": 0,
                                "unit": "px"
                            },
                            "right": {
                                "value": 0,
                                "unit": "px"
                            },
                            "bottom": {
                                "value": 0,
                                "unit": "px"
                            },
                            "left": {
                                "value": 0,
                                "unit": "px"
                            }
                        },
                        "style": {
                            "top": "solid",
                            "right": "solid",
                            "left": "solid",
                            "bottom": "solid"
                        },
                        "color": {
                            "top": {
                                "red": 252,
                                "green": 217,
                                "blue": 40,
                                "alpha": 1
                            },
                            "right": {
                                "red": 252,
                                "green": 217,
                                "blue": 40,
                                "alpha": 1
                            },
                            "left": {
                                "red": 252,
                                "green": 217,
                                "blue": 40,
                                "alpha": 1
                            },
                            "bottom": {
                                "red": 252,
                                "green": 217,
                                "blue": 40,
                                "alpha": 1
                            }
                        }
                    },
                    "cssBorderRadius": {
                        "topLeft": {
                            "value": 0,
                            "unit": "px"
                        },
                        "topRight": {
                            "value": 0,
                            "unit": "px"
                        },
                        "bottomLeft": {
                            "value": 0,
                            "unit": "px"
                        },
                        "bottomRight": {
                            "value": 0,
                            "unit": "px"
                        }
                    }
                },
                "themeMappings": {},
                "charas": "design-ivrsdpum",
                "dataChangeBehaviors": [
                    {
                        "trigger": "in",
                        "type": "animation",
                        "part": "media",
                        "name": "Scale",
                        "params": {
                            "delay": 0,
                            "duration": 0.5
                        }
                    },
                    {
                        "trigger": "out",
                        "type": "animation",
                        "part": "media",
                        "name": "Scale",
                        "params": {
                            "delay": 0,
                            "duration": 0.5
                        }
                    }
                ]
            },
            "style": {
                "type": "TopLevelStyle",
                "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false
                },
                "style": {
                    "properties": {
                        "alpha-bg": "1",
                        "alpha-brd": "1",
                        "bg": "206,224,193,1",
                        "boxShadowToggleOn-shd": "false",
                        "brd": "#324158",
                        "brw": "0px",
                        "rd": "0px",
                        "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                    },
                    "propertiesSource": {
                        "bg": "value",
                        "brd": "value",
                        "brw": "value",
                        "rd": "value",
                        "shd": "value"
                    },
                    "groups": {}
                },
                "componentClassName": "wysiwyg.viewer.components.HoverBox",
                "pageId": "",
                "compId": "",
                "styleType": "custom",
                "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer"
            }
        },
        "activeModes": {}
    },
    "preset": {
        "rect": {
            "width": 324,
            "height": 219,
            "x": -1,
            "y": 399
        }
    }
}
const hbPreset5 = {
    "id": "hoverBoxPreset5",
    "structure": {
        "type": "Container",
        "components": [
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwaz2mk1-mode-iwaz2mne1"
                            ],
                            "layout": {
                                "width": 245,
                                "height": 22,
                                "x": 19,
                                "y": 100,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "isHiddenByModes": false,
                            "style": "txtNew",
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {
                                    "schemaVersion": "1.0"
                                },
                                "brightness": 1,
                                "packed": true
                            }
                        }
                    ],
                    "isHiddenByModes": true
                },
                "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                "layout": {
                    "width": 246,
                    "height": 37,
                    "x": 19,
                    "y": 170,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "text": "<h6 class=\"font_6\" style=\"font-size:18px; text-align:center\"><span style=\"letter-spacing:0.25em\"><span style=\"color:#FFFFFF\"><span style=\"font-size:18px\"><span style=\"font-family:avenir-lt-w01_85-heavy1475544,sans-serif\">THE BOSS</span></span></span></span></h6>\n",
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": []
                },
                "props": {
                    "type": "WRichTextProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "brightness": 1,
                    "packed": true
                },
                "style": "txtNew",
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"name\":\"FloatIn\",\"delay\":0,\"duration\":0.35,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iwaz2mk1-mode-iwaz2mne1\"]},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"FloatOut\",\"delay\":0,\"duration\":0.35,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iwaz2mk1-mode-iwaz2mne1\"]},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                },
                "mobileStructure": {
                    "type": "Component",
                    "modes": {
                        "definitions": []
                    },
                    "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                    "layout": {
                        "width": 260,
                        "height": 24,
                        "x": 10,
                        "y": 63,
                        "scale": 1.1025,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.WRichText",
                    "data": {
                        "type": "StyledText",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "text": "<h6 class=\"font_6\" style=\"font-size:18px; text-align:center\"><span style=\"letter-spacing:0.25em\"><span style=\"color:#FFFFFF\"><span style=\"font-size:18px\"><span style=\"font-family:avenir-lt-w01_85-heavy1475544,sans-serif\">THE BOSS</span></span></span></span></h6>\n",
                        "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                        "linkList": []
                    },
                    "props": {
                        "type": "WRichTextProperties",
                        "metaData": {
                            "schemaVersion": "1.0",
                            "autoGenerated": false
                        },
                        "brightness": 1,
                        "packed": true
                    },
                    "style": "txtNew",
                    "behaviors": {
                        "type": "ObsoleteBehaviorsList",
                        "items": "[{\"name\":\"FloatIn\",\"delay\":0,\"duration\":0.35,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iwaz2mk1-mode-iwaz2mne1\"]},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"FloatOut\",\"delay\":0,\"duration\":0.35,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iwaz2mk1-mode-iwaz2mne1\"]},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                    }
                }
            },
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwaz2mk1-mode-iwaz2mne1"
                            ],
                            "layout": {
                                "width": 205,
                                "height": 110,
                                "x": 39,
                                "y": 172,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "isHiddenByModes": false,
                            "style": "txtNew",
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {
                                    "schemaVersion": "1.0"
                                },
                                "brightness": 1,
                                "packed": true
                            }
                        }
                    ],
                    "isHiddenByModes": true
                },
                "layout": {
                    "width": 246,
                    "height": 120,
                    "x": 19,
                    "y": 221,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "text": "<p class=\"font_8\" style=\"line-height:1.5em; text-align:center\"><span style=\"color:#FFFFFF\"><span style=\"font-family:futura-lt-w01-light,sans-serif\">This is a great place to tell people more about yourself and peak their interest. </span></span></p>\n\n<p class=\"font_8\" style=\"line-height:1.5em; text-align:center\"><span style=\"color:#FFFFFF\"><span style=\"font-family:futura-lt-w01-light,sans-serif\">For more info, they can follow you on social in a click.</span></span></p>\n",
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": []
                },
                "props": {
                    "type": "WRichTextProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "brightness": 1,
                    "packed": true
                },
                "style": "txtNew",
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"name\":\"FloatIn\",\"delay\":0,\"duration\":0.35,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iwaz2mk1-mode-iwaz2mne1\"]},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"FloatOut\",\"delay\":0,\"duration\":0.35,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iwaz2mk1-mode-iwaz2mne1\"]},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                },
                "mobileStructure": {
                    "type": "Component",
                    "modes": {
                        "definitions": []
                    },
                    "layout": {
                        "width": 202,
                        "height": 120,
                        "x": 39,
                        "y": 133,
                        "scale": 1.07,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.WRichText",
                    "data": {
                        "type": "StyledText",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "text": "<p class=\"font_8\" style=\"line-height:1.5em; text-align:center\"><span style=\"color:#FFFFFF\"><span style=\"font-family:futura-lt-w01-light,sans-serif\">This is a great place to tell people more about yourself and peak their interest. </span></span></p>\n\n<p class=\"font_8\" style=\"line-height:1.5em; text-align:center\"><span style=\"color:#FFFFFF\"><span style=\"font-family:futura-lt-w01-light,sans-serif\">For more info, they can follow you on social in a click.</span></span></p>\n",
                        "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                        "linkList": []
                    },
                    "props": {
                        "type": "WRichTextProperties",
                        "metaData": {
                            "schemaVersion": "1.0",
                            "autoGenerated": false
                        },
                        "brightness": 1,
                        "packed": true
                    },
                    "style": "txtNew",
                    "behaviors": {
                        "type": "ObsoleteBehaviorsList",
                        "items": "[{\"name\":\"FloatIn\",\"delay\":0,\"duration\":0.35,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iwaz2mk1-mode-iwaz2mne1\"]},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"FloatOut\",\"delay\":0,\"duration\":0.35,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iwaz2mk1-mode-iwaz2mne1\"]},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                    }
                }
            },
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwaz2mk1-mode-iwaz2mne1"
                            ],
                            "layout": {
                                "width": 45,
                                "height": 5,
                                "x": 119,
                                "y": 148,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "isHiddenByModes": false,
                            "style": "hl1",
                            "props": {
                                "type": "FiveGridLineProperties",
                                "metaData": {
                                    "schemaVersion": "1.0"
                                },
                                "fullScreenModeOn": false
                            }
                        }
                    ],
                    "isHiddenByModes": true
                },
                "layout": {
                    "width": 156,
                    "height": 5,
                    "x": 64,
                    "y": 320,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.FiveGridLine",
                "props": {
                    "type": "FiveGridLineProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "fullScreenModeOn": false
                },
                "style": "hl1",
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"name\":\"FloatIn\",\"delay\":0,\"duration\":0.35,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iwaz2mk1-mode-iwaz2mne1\"]},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"FloatOut\",\"delay\":0,\"duration\":0.35,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iwaz2mk1-mode-iwaz2mne1\"]},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                },
                "mobileStructure": {
                    "type": "Component",
                    "modes": {
                        "definitions": []
                    },
                    "layout": {
                        "width": 45,
                        "height": 5,
                        "x": 117,
                        "y": 111,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.FiveGridLine",
                    "props": {
                        "type": "FiveGridLineProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "fullScreenModeOn": false
                    },
                    "style": "hl1",
                    "behaviors": {
                        "type": "ObsoleteBehaviorsList",
                        "items": "[{\"name\":\"FloatIn\",\"delay\":0,\"duration\":0.35,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iwaz2mk1-mode-iwaz2mne1\"]},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"FloatOut\",\"delay\":0,\"duration\":0.35,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iwaz2mk1-mode-iwaz2mne1\"]},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                    }
                }
            },
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwaz2mk1-mode-iwaz2mne1"
                            ],
                            "layout": {
                                "width": 96,
                                "height": 22,
                                "x": 94,
                                "y": 306,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "isHiddenByModes": false,
                            "style": "lb1",
                            "props": {
                                "type": "LinkBarProperties",
                                "metaData": {
                                    "schemaVersion": "1.0"
                                },
                                "gallery": "social_icons",
                                "iconSize": 22,
                                "spacing": 15,
                                "bgScale": 1,
                                "orientation": "HORIZ"
                            }
                        }
                    ],
                    "isHiddenByModes": true
                },
                "skin": "wysiwyg.viewer.skins.LinkBarNoBGSkin",
                "layout": {
                    "width": 140,
                    "height": 42,
                    "x": 72,
                    "y": 318,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.LinkBar",
                "data": {
                    "type": "ImageList",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "items": [
                        {
                            "type": "Image",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "2.0",
                                "isHidden": false
                            },
                            "title": "White LinkedIn Icon",
                            "uri": "7528824071724d12a3e6c31eee0b40d4.png",
                            "width": 200,
                            "height": 200,
                            "alt": "",
                            "link": {
                                "type": "ExternalLink",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "url": "https://www.linkedin.com/company/wix-com",
                                "target": "_blank"
                            }
                        },
                        {
                            "type": "Image",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "2.0",
                                "isHidden": false
                            },
                            "title": "White Twitter Icon",
                            "uri": "01ab6619093f45388d66736ec22e5885.png",
                            "description": "",
                            "width": 200,
                            "height": 200,
                            "alt": "",
                            "link": {
                                "type": "ExternalLink",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "url": "http://www.twitter.com/wix",
                                "target": "_blank"
                            }
                        },
                        {
                            "type": "Image",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "2.0",
                                "isHidden": false
                            },
                            "title": "White Google+ Icon",
                            "uri": "eb9767422f0e4c41a507d3e67c4ebac8.png",
                            "width": 200,
                            "height": 200,
                            "alt": "",
                            "link": {
                                "type": "ExternalLink",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "url": "http://plus.google.com/+Wix/posts",
                                "target": "_blank"
                            }
                        }
                    ]
                },
                "props": {
                    "type": "LinkBarProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "gallery": "social_icons",
                    "iconSize": 22,
                    "spacing": 15,
                    "bgScale": 1,
                    "orientation": "HORIZ"
                },
                "style": "lb1",
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"name\":\"FloatIn\",\"delay\":0,\"duration\":0.35,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iwaz2mk1-mode-iwaz2mne1\"]},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"FloatOut\",\"delay\":0,\"duration\":0.35,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iwaz2mk1-mode-iwaz2mne1\"]},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                },
                "mobileStructure": {
                    "type": "Component",
                    "modes": {
                        "definitions": []
                    },
                    "skin": "wysiwyg.viewer.skins.LinkBarNoBGSkin",
                    "layout": {
                        "width": 105,
                        "height": 25,
                        "x": 87,
                        "y": 274,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.LinkBar",
                    "data": {
                        "type": "ImageList",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "items": [
                            {
                                "type": "Image",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "2.0",
                                    "isHidden": false
                                },
                                "title": "White LinkedIn Icon",
                                "uri": "7528824071724d12a3e6c31eee0b40d4.png",
                                "width": 200,
                                "height": 200,
                                "alt": "",
                                "link": {
                                    "type": "ExternalLink",
                                    "metaData": {
                                        "isPreset": false,
                                        "schemaVersion": "1.0",
                                        "isHidden": false
                                    },
                                    "url": "https://www.linkedin.com/company/wix-com",
                                    "target": "_blank"
                                }
                            },
                            {
                                "type": "Image",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "2.0",
                                    "isHidden": false
                                },
                                "title": "White Twitter Icon",
                                "uri": "01ab6619093f45388d66736ec22e5885.png",
                                "description": "",
                                "width": 200,
                                "height": 200,
                                "alt": "",
                                "link": {
                                    "type": "ExternalLink",
                                    "metaData": {
                                        "isPreset": false,
                                        "schemaVersion": "1.0",
                                        "isHidden": false
                                    },
                                    "url": "http://www.twitter.com/wix",
                                    "target": "_blank"
                                }
                            },
                            {
                                "type": "Image",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "2.0",
                                    "isHidden": false
                                },
                                "title": "White Google+ Icon",
                                "uri": "eb9767422f0e4c41a507d3e67c4ebac8.png",
                                "width": 200,
                                "height": 200,
                                "alt": "",
                                "link": {
                                    "type": "ExternalLink",
                                    "metaData": {
                                        "isPreset": false,
                                        "schemaVersion": "1.0",
                                        "isHidden": false
                                    },
                                    "url": "http://plus.google.com/+Wix/posts",
                                    "target": "_blank"
                                }
                            }
                        ]
                    },
                    "props": {
                        "type": "LinkBarProperties",
                        "metaData": {
                            "schemaVersion": "1.0",
                            "autoGenerated": false
                        },
                        "gallery": "social_icons",
                        "iconSize": 25,
                        "spacing": 15,
                        "bgScale": 1,
                        "orientation": "HORIZ"
                    },
                    "style": "lb1",
                    "behaviors": {
                        "type": "ObsoleteBehaviorsList",
                        "items": "[{\"name\":\"FloatIn\",\"delay\":0,\"duration\":0.35,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iwaz2mk1-mode-iwaz2mne1\"]},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"FloatOut\",\"delay\":0,\"duration\":0.35,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iwaz2mk1-mode-iwaz2mne1\"]},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                    }
                }
            }
        ],
        "modes": {
            "definitions": [
                {
                    "modeId": "comp-iwaz2mk1-mode-iwaz2mne",
                    "type": "DEFAULT",
                    "label": "MODE_LABEL_FROM_PRESET",
                    "params": ""
                },
                {
                    "modeId": "comp-iwaz2mk1-mode-iwaz2mne1",
                    "type": "HOVER",
                    "label": "MODE_LABEL_FROM_PRESET",
                    "params": ""
                }
            ],
            "overrides": [
                {
                    "modeIds": [
                        "comp-iwaz2mk1-mode-iwaz2mne1"
                    ],
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "1.0",
                                "isHidden": false
                            },
                            "mediaRef": {
                                "type": "Image",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "title": "Man with Checkered Shirt",
                                "uri": "6268ca5330094b6ca284ca58ec68b9fc.jpg",
                                "description": "public/491df47b-5958-4dc7-9029-37f428755d45/08616385-e843-431b-bd64-b8b845ff47bb",
                                "width": 2128,
                                "height": 2743,
                                "alt": "",
                                "artist": {
                                    "id": "",
                                    "name": ""
                                },
                                "opacity": 1
                            },
                            "color": "#FFFFFF",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "#2D7AED",
                            "colorOverlayOpacity": 0.85,
                            "showOverlayForMediaType": "all",
                            "mediaTransforms": {
                                "scale": 1
                            }
                        },
                        "cssStyle": {},
                        "themeMappings": {},
                        "charas": "design-iw4sd595",
                        "dataChangeBehaviors": [
                            {
                                "trigger": "in",
                                "type": "animation",
                                "part": "overlay",
                                "name": "FadeIn",
                                "params": {
                                    "delay": 0,
                                    "duration": 0.5
                                }
                            }
                        ]
                    }
                },
                {
                    "modeIds": [
                        "comp-iwaz2mk1-mode-iwaz2mne"
                    ],
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "1.0",
                                "isHidden": false
                            },
                            "mediaRef": {
                                "type": "Image",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "title": "Man with Checkered Shirt",
                                "uri": "6268ca5330094b6ca284ca58ec68b9fc.jpg",
                                "description": "public/491df47b-5958-4dc7-9029-37f428755d45/08616385-e843-431b-bd64-b8b845ff47bb",
                                "width": 2128,
                                "height": 2743,
                                "alt": "",
                                "artist": {
                                    "id": "",
                                    "name": ""
                                },
                                "opacity": 1
                            },
                            "color": "#FFFFFF",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0,
                            "showOverlayForMediaType": "WixVideo",
                            "mediaTransforms": {
                                "scale": 1
                            }
                        },
                        "cssStyle": {},
                        "themeMappings": {},
                        "charas": "design-iw4sc1v2",
                        "dataChangeBehaviors": []
                    }
                }
            ],
            "isHiddenByModes": false
        },
        "layout": {
            "width": 284,
            "height": 420,
            "x": 0,
            "y": 0,
            "scale": 1,
            "rotationInDegrees": 0,
            "fixedPosition": false
        },
        "componentType": "wysiwyg.viewer.components.HoverBox",
        "props": {
            "type": "HoverBoxProperties",
            "metaData": {
                "schemaVersion": "1.0"
            },
            "mobileDisplayedModeId": "comp-iwaz2mk1-mode-iwaz2mne1"
        },
        "design": {
            "type": "MediaContainerDesignData",
            "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false
            },
            "background": {
                "type": "BackgroundMedia",
                "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false
                },
                "mediaRef": {
                    "type": "Image",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "title": "Man with Beard.jpg",
                    "uri": "035244_4180659bd6cc4765a7fd2de12894c7af~mv2_d_2301_2966_s_2.jpg",
                    "description": "private/",
                    "width": 2301,
                    "height": 2966,
                    "alt": "",
                    "artist": {
                        "id": "",
                        "name": ""
                    },
                    "opacity": 1
                },
                "color": "#FFFFFF",
                "colorOpacity": 1,
                "alignType": "center",
                "fittingType": "fill",
                "scrollType": "none",
                "colorOverlay": "",
                "colorOverlayOpacity": 0,
                "showOverlayForMediaType": "WixVideo",
                "mediaTransforms": {
                    "scale": 1
                }
            },
            "cssStyle": {},
            "themeMappings": {},
            "charas": "design-iw4s3o1l",
            "dataChangeBehaviors": []
        },
        "style": {
            "type": "TopLevelStyle",
            "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false
            },
            "style": {
                "properties": {
                    "alpha-bg": "1",
                    "alpha-brd": "1",
                    "bg": "206,224,193,1",
                    "boxShadowToggleOn-shd": "false",
                    "brd": "#324158",
                    "brw": "0px",
                    "rd": "0px",
                    "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                },
                "propertiesSource": {
                    "bg": "value",
                    "brd": "value",
                    "brw": "value",
                    "rd": "value",
                    "shd": "value"
                },
                "groups": {}
            },
            "componentClassName": "wysiwyg.viewer.components.HoverBox",
            "pageId": "",
            "compId": "",
            "styleType": "custom",
            "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer"
        },
        "mobileStructure": {
            "type": "Container",
            "modes": {
                "definitions": [
                    {
                        "modeId": "comp-iwaz2mk1-mode-iwaz2mne",
                        "type": "DEFAULT",
                        "label": "MODE_LABEL_FROM_PRESET",
                        "params": ""
                    },
                    {
                        "modeId": "comp-iwaz2mk1-mode-iwaz2mne1",
                        "type": "HOVER",
                        "label": "MODE_LABEL_FROM_PRESET",
                        "params": ""
                    }
                ]
            },
            "layout": {
                "width": 280,
                "height": 360,
                "x": 20,
                "y": 10,
                "scale": 1,
                "rotationInDegrees": 0,
                "fixedPosition": false
            },
            "componentType": "wysiwyg.viewer.components.HoverBox",
            "props": {
                "type": "HoverBoxProperties",
                "metaData": {
                    "schemaVersion": "1.0"
                },
                "mobileDisplayedModeId": "comp-iwaz2mk1-mode-iwaz2mne1"
            },
            "design": {
                "type": "MediaContainerDesignData",
                "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false
                },
                "background": {
                    "type": "BackgroundMedia",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "mediaRef": {
                        "type": "Image",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "title": "Man with Checkered Shirt",
                        "uri": "6268ca5330094b6ca284ca58ec68b9fc.jpg",
                        "description": "public/491df47b-5958-4dc7-9029-37f428755d45/08616385-e843-431b-bd64-b8b845ff47bb",
                        "width": 2128,
                        "height": 2743,
                        "alt": "",
                        "artist": {
                            "id": "",
                            "name": ""
                        },
                        "opacity": 1
                    },
                    "color": "#FFFFFF",
                    "colorOpacity": 1,
                    "alignType": "center",
                    "fittingType": "fill",
                    "scrollType": "none",
                    "colorOverlay": "#2D7AED",
                    "colorOverlayOpacity": 0.85,
                    "showOverlayForMediaType": "all",
                    "mediaTransforms": {
                        "scale": 1
                    }
                },
                "cssStyle": {},
                "themeMappings": {},
                "charas": "design-iw4sd595",
                "dataChangeBehaviors": [
                    {
                        "trigger": "in",
                        "type": "animation",
                        "part": "overlay",
                        "name": "FadeIn",
                        "params": {
                            "delay": 0,
                            "duration": 0.5
                        }
                    }
                ]
            },
            "style": {
                "type": "TopLevelStyle",
                "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false
                },
                "style": {
                    "properties": {
                        "alpha-bg": "1",
                        "alpha-brd": "1",
                        "bg": "206,224,193,1",
                        "boxShadowToggleOn-shd": "false",
                        "brd": "#324158",
                        "brw": "0px",
                        "rd": "0px",
                        "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                    },
                    "propertiesSource": {
                        "bg": "value",
                        "brd": "value",
                        "brw": "value",
                        "rd": "value",
                        "shd": "value"
                    },
                    "groups": {}
                },
                "componentClassName": "wysiwyg.viewer.components.HoverBox",
                "pageId": "",
                "compId": "",
                "styleType": "custom",
                "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer"
            }
        },
        "activeModes": {}
    },
    "preset": {
        "rect": {
            "width": 162,
            "height": 251,
            "x": -1,
            "y": 618
        }
    }
}
const hbPreset6 = {
    "id": "hoverBoxPreset6",
    "structure": {
        "type": "Container",
        "components": [
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwaz1u25-mode-iwaz1u5o1"
                            ],
                            "layout": {
                                "width": 284,
                                "height": 130,
                                "x": 0,
                                "y": 290,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "style": {
                                    "properties": {
                                        "alpha-fillcolor": "1",
                                        "alpha-stroke": "1",
                                        "fillcolor": "#274C61",
                                        "stroke": "#274C61",
                                        "strokewidth": "0px"
                                    },
                                    "propertiesSource": {
                                        "alpha-fillcolor": "value",
                                        "fillcolor": "value",
                                        "stroke": "value",
                                        "strokewidth": "value"
                                    },
                                    "groups": {}
                                },
                                "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "svgshape.v2.Svg_06f469158f5346a3be7e4100e38370cd"
                            }
                        },
                        {
                            "modeIds": [
                                "comp-iwaz1u25-mode-iwaz1u5o"
                            ],
                            "layout": {
                                "width": 284,
                                "height": 70,
                                "x": 0,
                                "y": 350,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "style": {
                                    "properties": {
                                        "alpha-fillcolor": "1",
                                        "alpha-stroke": "1",
                                        "fillcolor": "#274C61",
                                        "stroke": "#FFFFFF",
                                        "strokewidth": "0px"
                                    },
                                    "propertiesSource": {
                                        "alpha-fillcolor": "value",
                                        "fillcolor": "value",
                                        "stroke": "value",
                                        "strokewidth": "value"
                                    },
                                    "groups": {}
                                },
                                "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "svgshape.v2.Svg_06f469158f5346a3be7e4100e38370cd"
                            }
                        }
                    ],
                    "isHiddenByModes": false
                },
                "skin": "svgshape.v2.Svg_06f469158f5346a3be7e4100e38370cd",
                "layout": {
                    "width": 284,
                    "height": 61,
                    "x": 0,
                    "y": 359,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.svgshape.SvgShape",
                "data": {
                    "type": "SvgShape",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    }
                },
                "props": {
                    "type": "SvgShapeProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "maintainAspectRatio": false
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "style": {
                        "properties": {
                            "alpha-fillcolor": "1",
                            "alpha-stroke": "1",
                            "fillcolor": "#695A5F",
                            "stroke": "#ED1566",
                            "strokewidth": "0px"
                        },
                        "propertiesSource": {
                            "alpha-fillcolor": "value",
                            "fillcolor": "value",
                            "stroke": "value",
                            "strokewidth": "value"
                        },
                        "groups": {}
                    },
                    "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "svgshape.v2.Svg_06f469158f5346a3be7e4100e38370cd"
                },
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"action\":\"modeChange\",\"name\":\"ModesMotion\",\"delay\":0,\"duration\":0.3,\"params\":{\"timingFunction\":\"EaseInOut\",\"modeIds\":[\"comp-iwaz1u25-mode-iwaz1u5o1\"]},\"targetId\":\"\"}]"
                },
                "mobileStructure": {
                    "type": "Component",
                    "modes": {
                        "definitions": []
                    },
                    "skin": "svgshape.v2.Svg_06f469158f5346a3be7e4100e38370cd",
                    "layout": {
                        "width": 280,
                        "height": 119,
                        "x": 0,
                        "y": 261,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.svgshape.SvgShape",
                    "data": {
                        "type": "SvgShape",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        }
                    },
                    "props": {
                        "type": "SvgShapeProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "maintainAspectRatio": false
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "properties": {
                                "alpha-fillcolor": "1",
                                "alpha-stroke": "1",
                                "fillcolor": "#274C61",
                                "stroke": "#274C61",
                                "strokewidth": "0px"
                            },
                            "propertiesSource": {
                                "alpha-fillcolor": "value",
                                "fillcolor": "value",
                                "stroke": "value",
                                "strokewidth": "value"
                            },
                            "groups": {}
                        },
                        "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "svgshape.v2.Svg_06f469158f5346a3be7e4100e38370cd"
                    },
                    "behaviors": {
                        "type": "ObsoleteBehaviorsList",
                        "items": "[{\"action\":\"modeChange\",\"name\":\"ModesMotion\",\"delay\":0,\"duration\":0.3,\"params\":{\"timingFunction\":\"EaseInOut\",\"modeIds\":[\"comp-iwaz1u25-mode-iwaz1u5o1\"]},\"targetId\":\"\"}]"
                    }
                }
            },
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwaz1u25-mode-iwaz1u5o1"
                            ],
                            "layout": {
                                "width": 149,
                                "height": 21,
                                "x": 24,
                                "y": 382,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "isHiddenByModes": false,
                            "style": "txtNew",
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {
                                    "schemaVersion": "1.0"
                                },
                                "brightness": 1,
                                "packed": true
                            }
                        }
                    ],
                    "isHiddenByModes": true
                },
                "layout": {
                    "width": 205,
                    "height": 120,
                    "x": 34,
                    "y": 306,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "text": "<p class=\"font_8\" style=\"font-size: 15px; line-height: 1.4em;\"><span style=\"font-size:15px;\"><span style=\"color:#FFFFFF\"><span style=\"font-family:futura-lt-w01-light,sans-serif\">T. 123-456-7890</span></span></span></p>\n",
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": []
                },
                "props": {
                    "type": "WRichTextProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "brightness": 1,
                    "packed": true
                },
                "style": "txtNew",
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"name\":\"FlyIn\",\"delay\":0,\"duration\":0.35,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iwaz1u25-mode-iwaz1u5o1\"]},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"FlyOut\",\"delay\":0,\"duration\":0.35,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iwaz1u25-mode-iwaz1u5o1\"]},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                },
                "mobileStructure": {
                    "type": "Component",
                    "modes": {
                        "definitions": []
                    },
                    "layout": {
                        "width": 133,
                        "height": 21,
                        "x": 23,
                        "y": 337,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.WRichText",
                    "data": {
                        "type": "StyledText",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "text": "<p class=\"font_8\" style=\"font-size: 15px; line-height: 1.4em;\"><span style=\"font-size:15px;\"><span style=\"color:#FFFFFF\"><span style=\"font-family:futura-lt-w01-light,sans-serif\">T. 123-456-7890</span></span></span></p>\n",
                        "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                        "linkList": []
                    },
                    "props": {
                        "type": "WRichTextProperties",
                        "metaData": {
                            "schemaVersion": "1.0",
                            "autoGenerated": false
                        },
                        "brightness": 1,
                        "packed": true
                    },
                    "style": "txtNew",
                    "behaviors": {
                        "type": "ObsoleteBehaviorsList",
                        "items": "[{\"name\":\"FlyIn\",\"delay\":0,\"duration\":0.35,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iwaz1u25-mode-iwaz1u5o1\"]},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"FlyOut\",\"delay\":0,\"duration\":0.35,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iwaz1u25-mode-iwaz1u5o1\"]},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                    }
                }
            },
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwaz1u25-mode-iwaz1u5o"
                            ],
                            "layout": {
                                "width": 222,
                                "height": 62,
                                "x": -187,
                                "y": 487,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            }
                        },
                        {
                            "modeIds": [
                                "comp-iwaz1u25-mode-iwaz1u5o1"
                            ],
                            "isHiddenByModes": true
                        }
                    ],
                    "isHiddenByModes": false
                },
                "skin": "svgshape.v2.Svg_06f469158f5346a3be7e4100e38370cd",
                "layout": {
                    "width": 256,
                    "height": 156,
                    "x": 14,
                    "y": 239,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.svgshape.SvgShape",
                "data": {
                    "type": "SvgShape",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    }
                },
                "props": {
                    "type": "SvgShapeProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "maintainAspectRatio": false
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "style": {
                        "properties": {
                            "alpha-fillcolor": "1",
                            "alpha-stroke": "1",
                            "fillcolor": "color_14",
                            "stroke": "#ED1566",
                            "strokewidth": "0px"
                        },
                        "propertiesSource": {
                            "alpha-fillcolor": "value",
                            "fillcolor": "theme",
                            "stroke": "value",
                            "strokewidth": "value"
                        },
                        "groups": {}
                    },
                    "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "svgshape.v2.Svg_06f469158f5346a3be7e4100e38370cd"
                },
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"action\":\"modeIn\",\"name\":\"FadeIn\",\"delay\":0,\"duration\":0.35},{\"action\":\"modeOut\",\"name\":\"FadeOut\",\"delay\":0,\"duration\":0.35}]"
                }
            },
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwaz1u25-mode-iwaz1u5o1"
                            ],
                            "layout": {
                                "width": 235,
                                "height": 5,
                                "x": 23,
                                "y": 361,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "isHiddenByModes": false,
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "style": {
                                    "properties": {
                                        "alpha-brd": "0.5",
                                        "brd": "#FFFFFF",
                                        "lnw": "1px"
                                    },
                                    "propertiesSource": {
                                        "alpha-brd": "value",
                                        "brd": "value",
                                        "lnw": "value"
                                    },
                                    "groups": {}
                                },
                                "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.line.SolidLine"
                            }
                        }
                    ],
                    "isHiddenByModes": true
                },
                "skin": "wysiwyg.viewer.skins.line.SolidLine",
                "layout": {
                    "width": 209,
                    "height": 5,
                    "x": 24,
                    "y": 350,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.FiveGridLine",
                "props": {
                    "type": "FiveGridLineProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "fullScreenModeOn": false
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "style": {
                        "properties": {
                            "alpha-brd": "1",
                            "brd": "#FFFFFF",
                            "lnw": "1px"
                        },
                        "propertiesSource": {
                            "brd": "value",
                            "lnw": "value"
                        },
                        "groups": {}
                    },
                    "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.line.SolidLine"
                },
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"name\":\"FlyIn\",\"delay\":0,\"duration\":0.35,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iwaz1u25-mode-iwaz1u5o1\"]},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"FlyOut\",\"delay\":0,\"duration\":0.35,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iwaz1u25-mode-iwaz1u5o1\"]},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                },
                "mobileStructure": {
                    "type": "Component",
                    "modes": {
                        "definitions": []
                    },
                    "skin": "wysiwyg.viewer.skins.line.SolidLine",
                    "layout": {
                        "width": 232,
                        "height": 5,
                        "x": 23,
                        "y": 318,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.FiveGridLine",
                    "props": {
                        "type": "FiveGridLineProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "fullScreenModeOn": false
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "properties": {
                                "alpha-brd": "0.5",
                                "brd": "#FFFFFF",
                                "lnw": "1px"
                            },
                            "propertiesSource": {
                                "alpha-brd": "value",
                                "brd": "value",
                                "lnw": "value"
                            },
                            "groups": {}
                        },
                        "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.line.SolidLine"
                    },
                    "behaviors": {
                        "type": "ObsoleteBehaviorsList",
                        "items": "[{\"name\":\"FlyIn\",\"delay\":0,\"duration\":0.35,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iwaz1u25-mode-iwaz1u5o1\"]},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"FlyOut\",\"delay\":0,\"duration\":0.35,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iwaz1u25-mode-iwaz1u5o1\"]},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                    }
                }
            },
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwaz1u25-mode-iwaz1u5o"
                            ],
                            "layout": {
                                "width": 241,
                                "height": 27,
                                "x": 23,
                                "y": 372,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            }
                        },
                        {
                            "modeIds": [
                                "comp-iwaz1u25-mode-iwaz1u5o1"
                            ],
                            "layout": {
                                "width": 213,
                                "height": 27,
                                "x": 23,
                                "y": 316,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            }
                        }
                    ],
                    "isHiddenByModes": false
                },
                "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                "layout": {
                    "width": 213,
                    "height": 24,
                    "x": 23,
                    "y": 316,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "text": "<h6 class=\"font_6\" style=\"font-size:22px\"><span style=\"color:#FFFFFF;\"><span style=\"font-size:22px\"><span style=\"letter-spacing:0.2em\"><span style=\"font-family:avenir-lt-w01_85-heavy1475544,sans-serif\">JESSICA LAKE</span></span></span></span></h6>\n",
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": []
                },
                "props": {
                    "type": "WRichTextProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "brightness": 1,
                    "packed": true
                },
                "style": "txtNew",
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"action\":\"modeChange\",\"name\":\"ModesMotion\",\"delay\":0,\"duration\":0.3,\"params\":{\"timingFunction\":\"EaseInOut\",\"modeIds\":[\"comp-iwaz1u25-mode-iwaz1u5o1\"]},\"targetId\":\"\"}]"
                },
                "mobileStructure": {
                    "type": "Component",
                    "modes": {
                        "definitions": []
                    },
                    "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                    "layout": {
                        "width": 204,
                        "height": 24,
                        "x": 23,
                        "y": 283,
                        "scale": 0.9132042253521127,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.WRichText",
                    "data": {
                        "type": "StyledText",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "text": "<h6 class=\"font_6\" style=\"font-size:22px\"><span style=\"color:#FFFFFF;\"><span style=\"font-size:22px\"><span style=\"letter-spacing:0.2em\"><span style=\"font-family:avenir-lt-w01_85-heavy1475544,sans-serif\">JESSICA LAKE</span></span></span></span></h6>\n",
                        "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                        "linkList": []
                    },
                    "props": {
                        "type": "WRichTextProperties",
                        "metaData": {
                            "schemaVersion": "1.0",
                            "autoGenerated": false
                        },
                        "brightness": 1,
                        "packed": true
                    },
                    "style": "txtNew",
                    "behaviors": {
                        "type": "ObsoleteBehaviorsList",
                        "items": "[{\"action\":\"modeChange\",\"name\":\"ModesMotion\",\"delay\":0,\"duration\":0.3,\"params\":{\"timingFunction\":\"EaseInOut\",\"modeIds\":[\"comp-iwaz1u25-mode-iwaz1u5o1\"]},\"targetId\":\"\"}]"
                    }
                }
            },
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwaz1u25-mode-iwaz1u5o1"
                            ],
                            "layout": {
                                "width": 86,
                                "height": 22,
                                "x": 174,
                                "y": 380,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "isHiddenByModes": false,
                            "props": {
                                "type": "LinkBarProperties",
                                "metaData": {
                                    "schemaVersion": "1.0"
                                },
                                "gallery": "social_icons",
                                "iconSize": 22,
                                "spacing": 10,
                                "bgScale": 1,
                                "orientation": "HORIZ"
                            }
                        }
                    ],
                    "isHiddenByModes": true
                },
                "skin": "wysiwyg.viewer.skins.LinkBarNoBGSkin",
                "layout": {
                    "width": 96,
                    "height": 22,
                    "x": 121,
                    "y": 376,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.LinkBar",
                "data": {
                    "type": "ImageList",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "items": [
                        {
                            "type": "Image",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "2.0",
                                "isHidden": false
                            },
                            "title": "White LinkedIn Icon",
                            "uri": "7528824071724d12a3e6c31eee0b40d4.png",
                            "width": 200,
                            "height": 200,
                            "alt": "",
                            "link": {
                                "type": "ExternalLink",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "url": "https://www.linkedin.com/company/wix-com",
                                "target": "_blank"
                            }
                        },
                        {
                            "type": "Image",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "2.0",
                                "isHidden": false
                            },
                            "title": "White Google+ Icon",
                            "uri": "eb9767422f0e4c41a507d3e67c4ebac8.png",
                            "width": 200,
                            "height": 200,
                            "alt": "",
                            "link": {
                                "type": "ExternalLink",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "url": "http://plus.google.com/+Wix/posts",
                                "target": "_blank"
                            }
                        },
                        {
                            "type": "Image",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "2.0",
                                "isHidden": false
                            },
                            "title": "White Twitter Icon",
                            "uri": "01ab6619093f45388d66736ec22e5885.png",
                            "description": "",
                            "width": 200,
                            "height": 200,
                            "alt": "",
                            "link": {
                                "type": "ExternalLink",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "url": "http://www.twitter.com/wix",
                                "target": "_blank"
                            }
                        }
                    ]
                },
                "props": {
                    "type": "LinkBarProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "gallery": "social_icons",
                    "iconSize": 22,
                    "spacing": 15,
                    "bgScale": 1,
                    "orientation": "HORIZ"
                },
                "style": "lb1",
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"name\":\"FlyIn\",\"delay\":0,\"duration\":0.35,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iwaz1u25-mode-iwaz1u5o1\"]},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"FlyOut\",\"delay\":0,\"duration\":0.35,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iwaz1u25-mode-iwaz1u5o1\"]},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                },
                "mobileStructure": {
                    "type": "Component",
                    "modes": {
                        "definitions": []
                    },
                    "skin": "wysiwyg.viewer.skins.LinkBarNoBGSkin",
                    "layout": {
                        "width": 105,
                        "height": 25,
                        "x": 156,
                        "y": 332,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.LinkBar",
                    "data": {
                        "type": "ImageList",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "items": [
                            {
                                "type": "Image",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "2.0",
                                    "isHidden": false
                                },
                                "title": "White LinkedIn Icon",
                                "uri": "7528824071724d12a3e6c31eee0b40d4.png",
                                "width": 200,
                                "height": 200,
                                "alt": "",
                                "link": {
                                    "type": "ExternalLink",
                                    "metaData": {
                                        "isPreset": false,
                                        "schemaVersion": "1.0",
                                        "isHidden": false
                                    },
                                    "url": "https://www.linkedin.com/company/wix-com",
                                    "target": "_blank"
                                }
                            },
                            {
                                "type": "Image",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "2.0",
                                    "isHidden": false
                                },
                                "title": "White Google+ Icon",
                                "uri": "eb9767422f0e4c41a507d3e67c4ebac8.png",
                                "width": 200,
                                "height": 200,
                                "alt": "",
                                "link": {
                                    "type": "ExternalLink",
                                    "metaData": {
                                        "isPreset": false,
                                        "schemaVersion": "1.0",
                                        "isHidden": false
                                    },
                                    "url": "http://plus.google.com/+Wix/posts",
                                    "target": "_blank"
                                }
                            },
                            {
                                "type": "Image",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "2.0",
                                    "isHidden": false
                                },
                                "title": "White Twitter Icon",
                                "uri": "01ab6619093f45388d66736ec22e5885.png",
                                "description": "",
                                "width": 200,
                                "height": 200,
                                "alt": "",
                                "link": {
                                    "type": "ExternalLink",
                                    "metaData": {
                                        "isPreset": false,
                                        "schemaVersion": "1.0",
                                        "isHidden": false
                                    },
                                    "url": "http://www.twitter.com/wix",
                                    "target": "_blank"
                                }
                            }
                        ]
                    },
                    "props": {
                        "type": "LinkBarProperties",
                        "metaData": {
                            "schemaVersion": "1.0",
                            "autoGenerated": false
                        },
                        "gallery": "social_icons",
                        "iconSize": 25,
                        "spacing": 15,
                        "bgScale": 1,
                        "orientation": "HORIZ"
                    },
                    "style": "lb1",
                    "behaviors": {
                        "type": "ObsoleteBehaviorsList",
                        "items": "[{\"name\":\"FlyIn\",\"delay\":0,\"duration\":0.35,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iwaz1u25-mode-iwaz1u5o1\"]},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"FlyOut\",\"delay\":0,\"duration\":0.35,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iwaz1u25-mode-iwaz1u5o1\"]},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                    }
                }
            },
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwaz1u25-mode-iwaz1u5o1"
                            ],
                            "layout": {
                                "width": 18,
                                "height": 12,
                                "x": 240,
                                "y": 323,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            }
                        },
                        {
                            "modeIds": [
                                "comp-iwaz1u25-mode-iwaz1u5o"
                            ],
                            "layout": {
                                "width": 19,
                                "height": 12,
                                "x": 246,
                                "y": 379,
                                "scale": 1,
                                "rotationInDegrees": 270,
                                "fixedPosition": false
                            }
                        }
                    ],
                    "isHiddenByModes": false
                },
                "skin": "svgshape.v2.Svg_4f4fbf1f8b7146f69dafa4814a22e5f3",
                "layout": {
                    "width": 18,
                    "height": 12,
                    "x": 246,
                    "y": 379,
                    "scale": 1,
                    "rotationInDegrees": 270,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.svgshape.SvgShape",
                "data": {
                    "type": "SvgShape",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    }
                },
                "props": {
                    "type": "SvgShapeProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "maintainAspectRatio": true
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "style": {
                        "properties": {
                            "alpha-fillcolor": "1",
                            "alpha-stroke": "1",
                            "fillcolor": "#FFFFFF",
                            "stroke": "#FFFFFF",
                            "strokewidth": "0px"
                        },
                        "propertiesSource": {
                            "fillcolor": "value",
                            "stroke": "value",
                            "strokewidth": "value"
                        },
                        "groups": {}
                    },
                    "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "svgshape.v2.Svg_4f4fbf1f8b7146f69dafa4814a22e5f3"
                },
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"action\":\"modeChange\",\"name\":\"ModesMotion\",\"delay\":0,\"duration\":0.35,\"params\":{\"timingFunction\":\"EaseInOut\",\"modeIds\":[\"comp-iwaz1u25-mode-iwaz1u5o1\"]},\"targetId\":\"\"}]"
                },
                "mobileStructure": {
                    "type": "Component",
                    "modes": {
                        "definitions": []
                    },
                    "skin": "svgshape.v2.Svg_4f4fbf1f8b7146f69dafa4814a22e5f3",
                    "layout": {
                        "width": 20,
                        "height": 12,
                        "x": 235,
                        "y": 288,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.svgshape.SvgShape",
                    "data": {
                        "type": "SvgShape",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        }
                    },
                    "props": {
                        "type": "SvgShapeProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "maintainAspectRatio": true
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "properties": {
                                "alpha-fillcolor": "1",
                                "alpha-stroke": "1",
                                "fillcolor": "#FFFFFF",
                                "stroke": "#FFFFFF",
                                "strokewidth": "0px"
                            },
                            "propertiesSource": {
                                "fillcolor": "value",
                                "stroke": "value",
                                "strokewidth": "value"
                            },
                            "groups": {}
                        },
                        "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "svgshape.v2.Svg_4f4fbf1f8b7146f69dafa4814a22e5f3"
                    },
                    "behaviors": {
                        "type": "ObsoleteBehaviorsList",
                        "items": "[{\"action\":\"modeChange\",\"name\":\"ModesMotion\",\"delay\":0,\"duration\":0.35,\"params\":{\"timingFunction\":\"EaseInOut\",\"modeIds\":[\"comp-iwaz1u25-mode-iwaz1u5o1\"]},\"targetId\":\"\"}]"
                    }
                }
            }
        ],
        "modes": {
            "definitions": [
                {
                    "modeId": "comp-iwaz1u25-mode-iwaz1u5o",
                    "type": "DEFAULT",
                    "label": "MODE_LABEL_FROM_PRESET",
                    "params": ""
                },
                {
                    "modeId": "comp-iwaz1u25-mode-iwaz1u5o1",
                    "type": "HOVER",
                    "label": "MODE_LABEL_FROM_PRESET",
                    "params": ""
                }
            ],
            "overrides": [],
            "isHiddenByModes": false
        },
        "layout": {
            "width": 284,
            "height": 420.5,
            "x": 0,
            "y": 0,
            "scale": 1,
            "rotationInDegrees": 0,
            "fixedPosition": false
        },
        "componentType": "wysiwyg.viewer.components.HoverBox",
        "props": {
            "type": "HoverBoxProperties",
            "metaData": {
                "schemaVersion": "1.0"
            },
            "mobileDisplayedModeId": "comp-iwaz1u25-mode-iwaz1u5o1"
        },
        "design": {
            "type": "MediaContainerDesignData",
            "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false
            },
            "background": {
                "type": "BackgroundMedia",
                "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false
                },
                "mediaRef": {
                    "type": "Image",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "title": "Woman with Patterned Shirt.jpg",
                    "uri": "035244_8a7c6a0a840649149393660a12d4acdc~mv2_d_2364_3049_s_2.jpg",
                    "description": "private/allMedia_picture",
                    "width": 2364,
                    "height": 3049,
                    "alt": "",
                    "artist": {
                        "id": "",
                        "name": ""
                    }
                },
                "color": "#FAFAEB",
                "colorOpacity": 1,
                "alignType": "center",
                "fittingType": "fill",
                "scrollType": "none",
                "colorOverlay": "",
                "colorOverlayOpacity": 0,
                "showOverlayForMediaType": "WixVideo",
                "mediaTransforms": {
                    "scale": 1
                }
            },
            "cssStyle": {},
            "themeMappings": {},
            "charas": "design-iw4s3vik",
            "dataChangeBehaviors": []
        },
        "style": {
            "type": "TopLevelStyle",
            "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false
            },
            "style": {
                "properties": {
                    "alpha-bg": "1",
                    "alpha-brd": "1",
                    "bg": "206,224,193,1",
                    "boxShadowToggleOn-shd": "false",
                    "brd": "#324158",
                    "brw": "0px",
                    "rd": "0px",
                    "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                },
                "propertiesSource": {
                    "bg": "value",
                    "brd": "value",
                    "brw": "value",
                    "rd": "value",
                    "shd": "value"
                },
                "groups": {}
            },
            "componentClassName": "wysiwyg.viewer.components.HoverBox",
            "pageId": "",
            "compId": "",
            "styleType": "custom",
            "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer"
        },
        "mobileStructure": {
            "type": "Container",
            "modes": {
                "definitions": [
                    {
                        "modeId": "comp-iwaz1u25-mode-iwaz1u5o",
                        "type": "DEFAULT",
                        "label": "MODE_LABEL_FROM_PRESET",
                        "params": ""
                    },
                    {
                        "modeId": "comp-iwaz1u25-mode-iwaz1u5o1",
                        "type": "HOVER",
                        "label": "MODE_LABEL_FROM_PRESET",
                        "params": ""
                    }
                ]
            },
            "layout": {
                "width": 280,
                "height": 380,
                "x": 20,
                "y": 10,
                "scale": 1,
                "rotationInDegrees": 0,
                "fixedPosition": false
            },
            "componentType": "wysiwyg.viewer.components.HoverBox",
            "props": {
                "type": "HoverBoxProperties",
                "metaData": {
                    "schemaVersion": "1.0"
                },
                "mobileDisplayedModeId": "comp-iwaz1u25-mode-iwaz1u5o1"
            },
            "design": {
                "type": "MediaContainerDesignData",
                "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false
                },
                "background": {
                    "type": "BackgroundMedia",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "mediaRef": {
                        "type": "Image",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "title": "Woman with Patterned Shirt.jpg",
                        "uri": "035244_8a7c6a0a840649149393660a12d4acdc~mv2_d_2364_3049_s_2.jpg",
                        "description": "private/allMedia_picture",
                        "width": 2364,
                        "height": 3049,
                        "alt": "",
                        "artist": {
                            "id": "",
                            "name": ""
                        }
                    },
                    "color": "#FAFAEB",
                    "colorOpacity": 1,
                    "alignType": "center",
                    "fittingType": "fill",
                    "scrollType": "none",
                    "colorOverlay": "",
                    "colorOverlayOpacity": 0,
                    "showOverlayForMediaType": "WixVideo",
                    "mediaTransforms": {
                        "scale": 1
                    }
                },
                "cssStyle": {},
                "themeMappings": {},
                "charas": "design-iw4s3vik",
                "dataChangeBehaviors": []
            },
            "style": {
                "type": "TopLevelStyle",
                "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false
                },
                "style": {
                    "properties": {
                        "alpha-bg": "1",
                        "alpha-brd": "1",
                        "bg": "206,224,193,1",
                        "boxShadowToggleOn-shd": "false",
                        "brd": "#324158",
                        "brw": "0px",
                        "rd": "0px",
                        "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                    },
                    "propertiesSource": {
                        "bg": "value",
                        "brd": "value",
                        "brw": "value",
                        "rd": "value",
                        "shd": "value"
                    },
                    "groups": {}
                },
                "componentClassName": "wysiwyg.viewer.components.HoverBox",
                "pageId": "",
                "compId": "",
                "styleType": "custom",
                "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer"
            }
        },
        "activeModes": {}
    },
    "preset": {
        "rect": {
            "width": 162,
            "height": 251,
            "x": 161,
            "y": 618
        }
    }
}
const hbPreset7 = {
    "id": "hoverBoxPreset7",
    "structure": {
        "type": "Container",
        "components": [
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwayr56l-mode-iwayr577"
                            ],
                            "layout": {
                                "width": 510,
                                "height": 144,
                                "x": 49,
                                "y": 57,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {
                                    "schemaVersion": "1.0"
                                },
                                "brightness": 1,
                                "packed": true
                            }
                        },
                        {
                            "modeIds": [
                                "comp-iwayr56l-mode-iwayr5771"
                            ],
                            "layout": {
                                "width": 304,
                                "height": 316,
                                "x": 181,
                                "y": 75,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "isHiddenByModes": true,
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {
                                    "schemaVersion": "1.0"
                                },
                                "brightness": 1,
                                "packed": true
                            }
                        }
                    ],
                    "isHiddenByModes": false
                },
                "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                "layout": {
                    "width": 304,
                    "height": 113,
                    "x": 180,
                    "y": 75,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "text": "<h6 class=\"font_6\" style=\"text-align: center; font-size: 110px;\"><span style=\"color:#5E5E5E;\"><span style=\"font-size:110px;\"><span style=\"font-family:trend-sans-w00-four,sans-serif;\">SALE!</span></span></span></h6>\n",
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": []
                },
                "style": "txtNew",
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"action\":\"modeIn\",\"name\":\"FadeIn\",\"delay\":0,\"duration\":0.35},{\"action\":\"modeOut\",\"name\":\"FadeOut\",\"delay\":0,\"duration\":0.35}]"
                }
            },
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwayr56l-mode-iwayr5771"
                            ],
                            "layout": {
                                "width": 465,
                                "height": 85,
                                "x": 71,
                                "y": 74,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "isHiddenByModes": false,
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {
                                    "schemaVersion": "1.0"
                                },
                                "brightness": 1,
                                "packed": true
                            }
                        }
                    ],
                    "isHiddenByModes": true
                },
                "layout": {
                    "width": 304,
                    "height": 24,
                    "x": 143,
                    "y": 118,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "text": "<h6 class=\"font_6\" style=\"font-size: 70px; line-height: 1.2em; text-align: center;\"><span style=\"color:#5E5E5E\"><span style=\"font-size:70px\"><span style=\"font-family:trend-sans-w00-four,sans-serif\"><a dataquery=\"#textLink_iwayqf3f\"><span style=\"color:#5E5E5E\"><span style=\"font-size:70px\"><span style=\"font-family:trend-sans-w00-four,sans-serif\"><span style=\"letter-spacing:-0.05em\">50%</span><span style=\"letter-spacing:-0.05em\"> </span>OFF</span></span></span></a></span></span></span></h6>\n",
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": []
                },
                "props": {
                    "type": "WRichTextProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "brightness": 1,
                    "packed": false
                },
                "style": "txtNew",
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"name\":\"FloatIn\",\"delay\":0,\"duration\":0.5,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iwayr56l-mode-iwayr5771\"]},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"FloatOut\",\"delay\":0,\"duration\":0.5,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iwayr56l-mode-iwayr5771\"]},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                },
                "mobileStructure": {
                    "type": "Component",
                    "modes": {
                        "definitions": []
                    },
                    "layout": {
                        "width": 212,
                        "height": 218,
                        "x": 34,
                        "y": 31,
                        "scale": 2.2578522048372247,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.WRichText",
                    "data": {
                        "type": "StyledText",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "text": "<h6 class=\"font_6\" style=\"font-size: 70px; line-height: 1.2em; text-align: center;\"><span style=\"color:#5E5E5E\"><span style=\"font-size:70px\"><span style=\"font-family:trend-sans-w00-four,sans-serif\"><a dataquery=\"#textLink_iwayqf3f\"><span style=\"color:#5E5E5E\"><span style=\"font-size:70px\"><span style=\"font-family:trend-sans-w00-four,sans-serif\"><span style=\"letter-spacing:-0.05em\">50%</span><span style=\"letter-spacing:-0.05em\"> </span>OFF</span></span></span></a></span></span></span></h6>\n",
                        "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                        "linkList": []
                    },
                    "props": {
                        "type": "WRichTextProperties",
                        "metaData": {
                            "schemaVersion": "1.0",
                            "autoGenerated": false
                        },
                        "brightness": 1,
                        "packed": true
                    },
                    "style": "txtNew",
                    "behaviors": {
                        "type": "ObsoleteBehaviorsList",
                        "items": "[{\"name\":\"FloatIn\",\"delay\":0,\"duration\":0.5,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iwayr56l-mode-iwayr5771\"]},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"FloatOut\",\"delay\":0,\"duration\":0.5,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iwayr56l-mode-iwayr5771\"]},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                    }
                }
            },
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwayr56l-mode-iwayr5771"
                            ],
                            "layout": {
                                "width": 300,
                                "height": 20,
                                "x": 154,
                                "y": 168,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "isHiddenByModes": false,
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {
                                    "schemaVersion": "1.0"
                                },
                                "brightness": 1,
                                "packed": true
                            }
                        }
                    ],
                    "isHiddenByModes": true
                },
                "layout": {
                    "width": 304,
                    "height": 36,
                    "x": 192,
                    "y": 199,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "text": "<p class=\"font_8\" style=\"font-size: 17px; text-align: center;\"><span style=\"font-size:17px;\"><a dataquery=\"#textLink_iw5680vv\"><span style=\"color:#5E5E5E\"><span style=\"font-family:spinnaker,sans-serif\"><span style=\"letter-spacing:0.2em\">BUY NOW</span></span></span></a></span></p>\n",
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": []
                },
                "props": {
                    "type": "WRichTextProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "brightness": 1,
                    "packed": false
                },
                "style": "txtNew",
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"name\":\"FloatIn\",\"delay\":0,\"duration\":0.5,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iwayr56l-mode-iwayr5771\"]},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"FloatOut\",\"delay\":0,\"duration\":0.5,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iwayr56l-mode-iwayr5771\"]},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                },
                "mobileStructure": {
                    "type": "Component",
                    "modes": {
                        "definitions": []
                    },
                    "layout": {
                        "width": 260,
                        "height": 20,
                        "x": 10,
                        "y": 132,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.WRichText",
                    "data": {
                        "type": "StyledText",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "text": "<p class=\"font_8\" style=\"font-size: 17px; text-align: center;\"><span style=\"font-size:17px;\"><a dataquery=\"#textLink_iw5680vv\"><span style=\"color:#5E5E5E\"><span style=\"font-family:spinnaker,sans-serif\"><span style=\"letter-spacing:0.2em\">BUY NOW</span></span></span></a></span></p>\n",
                        "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                        "linkList": []
                    },
                    "props": {
                        "type": "WRichTextProperties",
                        "metaData": {
                            "schemaVersion": "1.0",
                            "autoGenerated": false
                        },
                        "brightness": 1,
                        "packed": true
                    },
                    "style": "txtNew",
                    "behaviors": {
                        "type": "ObsoleteBehaviorsList",
                        "items": "[{\"name\":\"FloatIn\",\"delay\":0,\"duration\":0.5,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iwayr56l-mode-iwayr5771\"]},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"FloatOut\",\"delay\":0,\"duration\":0.5,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iwayr56l-mode-iwayr5771\"]},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                    }
                }
            }
        ],
        "modes": {
            "definitions": [
                {
                    "modeId": "comp-iwayr56l-mode-iwayr577",
                    "type": "DEFAULT",
                    "label": "MODE_LABEL_FROM_PRESET",
                    "params": ""
                },
                {
                    "modeId": "comp-iwayr56l-mode-iwayr5771",
                    "type": "HOVER",
                    "label": "MODE_LABEL_FROM_PRESET",
                    "params": ""
                }
            ],
            "overrides": [],
            "isHiddenByModes": false
        },
        "layout": {
            "width": 608,
            "height": 259,
            "x": 0,
            "y": 0,
            "scale": 1,
            "rotationInDegrees": 0,
            "fixedPosition": false
        },
        "componentType": "wysiwyg.viewer.components.HoverBox",
        "props": {
            "type": "HoverBoxProperties",
            "metaData": {
                "schemaVersion": "1.0"
            }
        },
        "design": {
            "type": "MediaContainerDesignData",
            "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false
            },
            "background": {
                "type": "BackgroundMedia",
                "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false
                },
                "mediaRef": {
                    "type": "Image",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "title": "Hover_gifs_1B.gif",
                    "uri": "035244_5ba24f65c0e64718ac18353aefe03107~mv2.gif",
                    "description": "private/491df47b-5958-4dc7-9029-37f428755d45/1f0bcd55-6ccc-49e7-a743-30179f6f3783",
                    "width": 1920,
                    "height": 1080,
                    "alt": "",
                    "artist": {
                        "id": "",
                        "name": ""
                    }
                },
                "color": "#86F7C0",
                "colorOpacity": 1,
                "alignType": "center",
                "fittingType": "fill",
                "scrollType": "none",
                "colorOverlay": "",
                "colorOverlayOpacity": 0,
                "showOverlayForMediaType": "all",
                "mediaTransforms": {
                    "scale": 1
                }
            },
            "cssStyle": {},
            "themeMappings": {},
            "charas": "design-ivgugapx",
            "dataChangeBehaviors": []
        },
        "style": {
            "type": "TopLevelStyle",
            "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false
            },
            "style": {
                "properties": {
                    "alpha-bg": "1",
                    "alpha-brd": "1",
                    "bg": "206,224,193,1",
                    "boxShadowToggleOn-shd": "false",
                    "brd": "#324158",
                    "brw": "0px",
                    "rd": "0px",
                    "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                },
                "propertiesSource": {
                    "bg": "value",
                    "brd": "value",
                    "brw": "value",
                    "rd": "value",
                    "shd": "value"
                },
                "groups": {}
            },
            "componentClassName": "wysiwyg.viewer.components.HoverBox",
            "pageId": "",
            "compId": "",
            "styleType": "custom",
            "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer"
        },
        "mobileStructure": {
            "type": "Container",
            "modes": {
                "definitions": [
                    {
                        "modeId": "comp-iwayr56l-mode-iwayr577",
                        "type": "DEFAULT",
                        "label": "MODE_LABEL_FROM_PRESET",
                        "params": ""
                    },
                    {
                        "modeId": "comp-iwayr56l-mode-iwayr5771",
                        "type": "HOVER",
                        "label": "MODE_LABEL_FROM_PRESET",
                        "params": ""
                    }
                ]
            },
            "layout": {
                "width": 280,
                "height": 280,
                "x": 20,
                "y": 10,
                "scale": 1,
                "rotationInDegrees": 0,
                "fixedPosition": false
            },
            "componentType": "wysiwyg.viewer.components.HoverBox",
            "props": {
                "type": "HoverBoxProperties",
                "metaData": {
                    "schemaVersion": "1.0"
                }
            },
            "design": {
                "type": "MediaContainerDesignData",
                "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false
                },
                "background": {
                    "type": "BackgroundMedia",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "mediaRef": {
                        "type": "Image",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "title": "Hover_gifs_1B.gif",
                        "uri": "035244_5ba24f65c0e64718ac18353aefe03107~mv2.gif",
                        "description": "private/491df47b-5958-4dc7-9029-37f428755d45/1f0bcd55-6ccc-49e7-a743-30179f6f3783",
                        "width": 1920,
                        "height": 1080,
                        "alt": "",
                        "artist": {
                            "id": "",
                            "name": ""
                        }
                    },
                    "color": "#86F7C0",
                    "colorOpacity": 1,
                    "alignType": "center",
                    "fittingType": "fill",
                    "scrollType": "none",
                    "colorOverlay": "",
                    "colorOverlayOpacity": 0,
                    "showOverlayForMediaType": "all",
                    "mediaTransforms": {
                        "scale": 1
                    }
                },
                "cssStyle": {},
                "themeMappings": {},
                "charas": "design-ivgugapx",
                "dataChangeBehaviors": []
            },
            "style": {
                "type": "TopLevelStyle",
                "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false
                },
                "style": {
                    "properties": {
                        "alpha-bg": "1",
                        "alpha-brd": "1",
                        "bg": "206,224,193,1",
                        "boxShadowToggleOn-shd": "false",
                        "brd": "#324158",
                        "brw": "0px",
                        "rd": "0px",
                        "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                    },
                    "propertiesSource": {
                        "bg": "value",
                        "brd": "value",
                        "brw": "value",
                        "rd": "value",
                        "shd": "value"
                    },
                    "groups": {}
                },
                "componentClassName": "wysiwyg.viewer.components.HoverBox",
                "pageId": "",
                "compId": "",
                "styleType": "custom",
                "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer"
            }
        },
        "activeModes": {}
    },
    "preset": {
        "rect": {
            "width": 324,
            "height": 174,
            "x": -1,
            "y": 869
        }
    }
}
const hbPreset9 = {
    "id": "hoverBoxPreset9",
    "structure": {
        "type": "Container",
        "components": [
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iw6l0bz7-mode-iw6l0c2l1"
                            ],
                            "layout": {
                                "width": 236,
                                "height": 353,
                                "x": 24,
                                "y": 23,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "style": {
                                    "properties": {
                                        "alpha-fillcolor": "0",
                                        "alpha-stroke": "1",
                                        "fillcolor": "#FFFFFF",
                                        "stroke": "#FFFFFF",
                                        "strokewidth": "1"
                                    },
                                    "propertiesSource": {
                                        "alpha-fillcolor": "value",
                                        "alpha-stroke": "value",
                                        "fillcolor": "value",
                                        "stroke": "value",
                                        "strokewidth": "value"
                                    },
                                    "groups": {}
                                },
                                "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "svgshape.v2.Svg_06f469158f5346a3be7e4100e38370cd"
                            }
                        },
                        {
                            "modeIds": [
                                "comp-iw6l0bz7-mode-iw6l0c2l"
                            ],
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "style": {
                                    "properties": {
                                        "alpha-fillcolor": "0",
                                        "alpha-stroke": "1",
                                        "fillcolor": "#FFFFFF",
                                        "stroke": "#FFFFFF",
                                        "strokewidth": "1"
                                    },
                                    "propertiesSource": {
                                        "alpha-fillcolor": "value",
                                        "alpha-stroke": "value",
                                        "fillcolor": "value",
                                        "stroke": "value",
                                        "strokewidth": "value"
                                    },
                                    "groups": {}
                                },
                                "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "svgshape.v2.Svg_06f469158f5346a3be7e4100e38370cd"
                            }
                        }
                    ],
                    "isHiddenByModes": false
                },
                "skin": "svgshape.v2.Svg_06f469158f5346a3be7e4100e38370cd",
                "layout": {
                    "width": 246,
                    "height": 363,
                    "x": 19,
                    "y": 18,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.svgshape.SvgShape",
                "data": {
                    "type": "SvgShape",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    }
                },
                "props": {
                    "type": "SvgShapeProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "maintainAspectRatio": false
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "style": {
                        "properties": {
                            "alpha-fillcolor": "0",
                            "alpha-stroke": "1",
                            "fillcolor": "#FFFFFF",
                            "stroke": "#FFFFFF",
                            "strokewidth": "2"
                        },
                        "propertiesSource": {
                            "alpha-fillcolor": "value",
                            "alpha-stroke": "value",
                            "fillcolor": "value",
                            "stroke": "value",
                            "strokewidth": "value"
                        },
                        "groups": {}
                    },
                    "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "svgshape.v2.Svg_06f469158f5346a3be7e4100e38370cd"
                },
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"action\":\"modeIn\",\"name\":\"FadeIn\",\"delay\":0,\"duration\":0.35},{\"action\":\"modeOut\",\"name\":\"FadeOut\",\"delay\":0,\"duration\":0.35},{\"action\":\"modeChange\",\"name\":\"ModesMotion\",\"delay\":0,\"duration\":0.3,\"params\":{\"timingFunction\":\"EaseInOut\",\"modeIds\":[\"comp-iw6l0bz7-mode-iw6l0c2l1\"]},\"targetId\":\"\"}]"
                },
                "mobileStructure": {
                    "type": "Component",
                    "modes": {
                        "definitions": []
                    },
                    "skin": "svgshape.v2.Svg_06f469158f5346a3be7e4100e38370cd",
                    "layout": {
                        "width": 243,
                        "height": 341,
                        "x": 18,
                        "y": 19,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.svgshape.SvgShape",
                    "data": {
                        "type": "SvgShape",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        }
                    },
                    "props": {
                        "type": "SvgShapeProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "maintainAspectRatio": false
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "properties": {
                                "alpha-fillcolor": "0",
                                "alpha-stroke": "1",
                                "fillcolor": "#FFFFFF",
                                "stroke": "#FFFFFF",
                                "strokewidth": "1"
                            },
                            "propertiesSource": {
                                "alpha-fillcolor": "value",
                                "alpha-stroke": "value",
                                "fillcolor": "value",
                                "stroke": "value",
                                "strokewidth": "value"
                            },
                            "groups": {}
                        },
                        "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "svgshape.v2.Svg_06f469158f5346a3be7e4100e38370cd"
                    },
                    "behaviors": {
                        "type": "ObsoleteBehaviorsList",
                        "items": "[{\"action\":\"modeIn\",\"name\":\"FadeIn\",\"delay\":0,\"duration\":0.35},{\"action\":\"modeOut\",\"name\":\"FadeOut\",\"delay\":0,\"duration\":0.35},{\"action\":\"modeChange\",\"name\":\"ModesMotion\",\"delay\":0,\"duration\":0.3,\"params\":{\"timingFunction\":\"EaseInOut\",\"modeIds\":[\"comp-iw6l0bz7-mode-iw6l0c2l1\"]},\"targetId\":\"\"}]"
                    }
                }
            },
            {
                "type": "Component",
                "modes": {
                    "overrides": [],
                    "isHiddenByModes": false
                },
                "layout": {
                    "width": 229,
                    "height": 56,
                    "x": 27,
                    "y": 175,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "text": "<h6 class=\"font_6\" style=\"font-size: 50px; line-height: 1.1em; text-align: center;\"><span style=\"font-size:50px;\"><span style=\"letter-spacing:0em\"><span style=\"font-family:didot-w01-italic,serif\"><span style=\"color:#FFFFFF\">SALE</span></span></span></span></h6>\n",
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": []
                },
                "props": {
                    "type": "WRichTextProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "brightness": 1,
                    "packed": true
                },
                "style": "txtNew",
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"action\":\"modeChange\",\"name\":\"ModesMotion\",\"delay\":0,\"duration\":0.25,\"params\":{\"timingFunction\":\"EaseInOut\"},\"targetId\":\"\"}]"
                },
                "mobileStructure": {
                    "type": "Component",
                    "modes": {
                        "definitions": []
                    },
                    "layout": {
                        "width": 243,
                        "height": 64,
                        "x": 18,
                        "y": 169,
                        "scale": 1.7011465603190428,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.WRichText",
                    "data": {
                        "type": "StyledText",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "text": "<h6 class=\"font_6\" style=\"font-size: 50px; line-height: 1.1em; text-align: center;\"><span style=\"font-size:50px;\"><span style=\"letter-spacing:0em\"><span style=\"font-family:didot-w01-italic,serif\"><span style=\"color:#FFFFFF\">SALE</span></span></span></span></h6>\n",
                        "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                        "linkList": []
                    },
                    "props": {
                        "type": "WRichTextProperties",
                        "metaData": {
                            "schemaVersion": "1.0",
                            "autoGenerated": false
                        },
                        "brightness": 1,
                        "packed": true
                    },
                    "style": "txtNew",
                    "behaviors": {
                        "type": "ObsoleteBehaviorsList",
                        "items": "[{\"action\":\"modeChange\",\"name\":\"ModesMotion\",\"delay\":0,\"duration\":0.25,\"params\":{\"timingFunction\":\"EaseInOut\"},\"targetId\":\"\"}]"
                    }
                }
            },
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iw6l0bz7-mode-iw6l0c2l1"
                            ],
                            "layout": {
                                "width": 206,
                                "height": 27,
                                "x": 39,
                                "y": 326,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "isHiddenByModes": false,
                            "style": "txtNew",
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {
                                    "schemaVersion": "1.0"
                                },
                                "brightness": 1,
                                "packed": true
                            }
                        }
                    ],
                    "isHiddenByModes": true
                },
                "layout": {
                    "width": 206,
                    "height": 24,
                    "x": 30,
                    "y": 70,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "text": "<h6 class=\"font_6\" style=\"font-size:20px; text-align:center\"><a dataquery=\"#textLink_iw57pzrf\"><span style=\"letter-spacing:0.1em\"><span style=\"font-size:20px\"><span style=\"font-family:futura-lt-w01-book,sans-serif\"><span style=\"color:#FFFFFF\">Save 50%</span></span></span></span></a></h6>\n",
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": [
                        {
                            "type": "PageLink",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "1.0",
                                "isHidden": false
                            },
                            "pageId": "#c1dmp"
                        }
                    ]
                },
                "props": {
                    "type": "WRichTextProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "brightness": 1,
                    "packed": true
                },
                "style": "txtNew",
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"name\":\"SlideIn\",\"delay\":0,\"duration\":0.3,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iw6l0bz7-mode-iw6l0c2l1\"]},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"SlideOut\",\"delay\":0,\"duration\":0.3,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iw6l0bz7-mode-iw6l0c2l1\"]},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                },
                "custom": {
                    "linkList": [
                        {
                            "type": "PageLink",
                            "id": "dataItem-iw6l0c311",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "1.0",
                                "isHidden": false
                            },
                            "pageId": "#c1dmp"
                        }
                    ]
                },
                "mobileStructure": {
                    "type": "Component",
                    "modes": {
                        "definitions": []
                    },
                    "layout": {
                        "width": 116,
                        "height": 27,
                        "x": 82,
                        "y": 317,
                        "scale": 0.994279661016949,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.WRichText",
                    "data": {
                        "type": "StyledText",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "text": "<h6 class=\"font_6\" style=\"font-size:20px; text-align:center\"><a dataquery=\"#textLink_iw57pzrf\"><span style=\"letter-spacing:0.1em\"><span style=\"font-size:20px\"><span style=\"font-family:futura-lt-w01-book,sans-serif\"><span style=\"color:#FFFFFF\">Save 50%</span></span></span></span></a></h6>\n",
                        "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                        "linkList": [
                            {
                                "type": "PageLink",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "pageId": "#c1dmp"
                            }
                        ]
                    },
                    "props": {
                        "type": "WRichTextProperties",
                        "metaData": {
                            "schemaVersion": "1.0",
                            "autoGenerated": false
                        },
                        "brightness": 1,
                        "packed": true
                    },
                    "style": "txtNew",
                    "behaviors": {
                        "type": "ObsoleteBehaviorsList",
                        "items": "[{\"name\":\"SlideIn\",\"delay\":0,\"duration\":0.3,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iw6l0bz7-mode-iw6l0c2l1\"]},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"SlideOut\",\"delay\":0,\"duration\":0.3,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iw6l0bz7-mode-iw6l0c2l1\"]},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                    },
                    "custom": {
                        "linkList": [
                            {
                                "type": "PageLink",
                                "id": "dataItem-iw6l0c311",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "pageId": "#c1dmp"
                            }
                        ]
                    }
                }
            },
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iw6l0bz7-mode-iw6l0c2l1"
                            ],
                            "layout": {
                                "width": 206,
                                "height": 20,
                                "x": 39,
                                "y": 43,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "isHiddenByModes": false,
                            "style": "txtNew",
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {
                                    "schemaVersion": "1.0"
                                },
                                "brightness": 1,
                                "packed": true
                            }
                        }
                    ],
                    "isHiddenByModes": true
                },
                "layout": {
                    "width": 206,
                    "height": 27,
                    "x": 39,
                    "y": 40,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "text": "<h6 class=\"font_6\" style=\"font-size:15px; text-align:center\"><span style=\"font-size:15px\"><span style=\"font-family:futura-lt-w01-light,sans-serif\"><span style=\"letter-spacing:0.1em\"><span style=\"color:#FFFFFF\">18-16/11</span></span></span></span></h6>\n",
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": []
                },
                "props": {
                    "type": "WRichTextProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "brightness": 1,
                    "packed": true
                },
                "style": "txtNew",
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"name\":\"SlideIn\",\"delay\":0,\"duration\":0.3,\"params\":{\"direction\":\"top\",\"modeIds\":[\"comp-iw6l0bz7-mode-iw6l0c2l1\"]},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"SlideOut\",\"delay\":0,\"duration\":0.3,\"params\":{\"direction\":\"top\",\"modeIds\":[\"comp-iw6l0bz7-mode-iw6l0c2l1\"]},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                },
                "mobileStructure": {
                    "type": "Component",
                    "modes": {
                        "definitions": []
                    },
                    "layout": {
                        "width": 142,
                        "height": 21,
                        "x": 69,
                        "y": 41,
                        "scale": 1.07,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.WRichText",
                    "data": {
                        "type": "StyledText",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "text": "<h6 class=\"font_6\" style=\"font-size:15px; text-align:center\"><span style=\"font-size:15px\"><span style=\"font-family:futura-lt-w01-light,sans-serif\"><span style=\"letter-spacing:0.1em\"><span style=\"color:#FFFFFF\">18-16/11</span></span></span></span></h6>\n",
                        "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                        "linkList": []
                    },
                    "props": {
                        "type": "WRichTextProperties",
                        "metaData": {
                            "schemaVersion": "1.0",
                            "autoGenerated": false
                        },
                        "brightness": 1,
                        "packed": true
                    },
                    "style": "txtNew",
                    "behaviors": {
                        "type": "ObsoleteBehaviorsList",
                        "items": "[{\"name\":\"SlideIn\",\"delay\":0,\"duration\":0.3,\"params\":{\"direction\":\"top\",\"modeIds\":[\"comp-iw6l0bz7-mode-iw6l0c2l1\"]},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"SlideOut\",\"delay\":0,\"duration\":0.3,\"params\":{\"direction\":\"top\",\"modeIds\":[\"comp-iw6l0bz7-mode-iw6l0c2l1\"]},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                    }
                }
            }
        ],
        "modes": {
            "definitions": [
                {
                    "modeId": "comp-iw6l0bz7-mode-iw6l0c2l",
                    "type": "DEFAULT",
                    "label": "MODE_LABEL_FROM_PRESET",
                    "params": ""
                },
                {
                    "modeId": "comp-iw6l0bz7-mode-iw6l0c2l1",
                    "type": "HOVER",
                    "label": "MODE_LABEL_FROM_PRESET",
                    "params": ""
                }
            ],
            "overrides": [],
            "isHiddenByModes": false
        },
        "layout": {
            "width": 284,
            "height": 400,
            "x": 0,
            "y": 0,
            "scale": 1,
            "rotationInDegrees": 0,
            "fixedPosition": false
        },
        "componentType": "wysiwyg.viewer.components.HoverBox",
        "props": {
            "type": "HoverBoxProperties",
            "metaData": {
                "schemaVersion": "1.0"
            },
            "mobileDisplayedModeId": "comp-iw6l0bz7-mode-iw6l0c2l1"
        },
        "design": {
            "type": "MediaContainerDesignData",
            "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false
            },
            "background": {
                "type": "BackgroundMedia",
                "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false
                },
                "mediaRef": {
                    "type": "Image",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "title": "Couple in Shades",
                    "uri": "477b686b4f8749ec87ad608360762b96.jpg",
                    "description": "public/491df47b-5958-4dc7-9029-37f428755d45/c2a37dfc-36cf-4bfd-b940-55bac7bdc9e6",
                    "width": 1920,
                    "height": 1280,
                    "alt": "",
                    "artist": {
                        "id": "",
                        "name": ""
                    },
                    "opacity": 0.7
                },
                "color": "#000000",
                "colorOpacity": 1,
                "alignType": "center",
                "fittingType": "fill",
                "scrollType": "none",
                "colorOverlay": "",
                "colorOverlayOpacity": 0,
                "showOverlayForMediaType": "WixVideo",
                "mediaTransforms": {
                    "scale": 1
                }
            },
            "cssStyle": {},
            "themeMappings": {},
            "charas": "design-ivkysp7u"
        },
        "style": {
            "type": "TopLevelStyle",
            "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false
            },
            "style": {
                "properties": {
                    "alpha-bg": "1",
                    "alpha-brd": "1",
                    "bg": "206,224,193,1",
                    "boxShadowToggleOn-shd": "false",
                    "brd": "#324158",
                    "brw": "0px",
                    "rd": "0px",
                    "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                },
                "propertiesSource": {
                    "bg": "value",
                    "brd": "value",
                    "brw": "value",
                    "rd": "value",
                    "shd": "value"
                },
                "groups": {}
            },
            "componentClassName": "wysiwyg.viewer.components.HoverBox",
            "pageId": "",
            "compId": "",
            "styleType": "custom",
            "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer"
        },
        "mobileStructure": {
            "type": "Container",
            "modes": {
                "definitions": [
                    {
                        "modeId": "comp-iw6l0bz7-mode-iw6l0c2l",
                        "type": "DEFAULT",
                        "label": "MODE_LABEL_FROM_PRESET",
                        "params": ""
                    },
                    {
                        "modeId": "comp-iw6l0bz7-mode-iw6l0c2l1",
                        "type": "HOVER",
                        "label": "MODE_LABEL_FROM_PRESET",
                        "params": ""
                    }
                ]
            },
            "layout": {
                "width": 280,
                "height": 380,
                "x": 20,
                "y": 10,
                "scale": 1,
                "rotationInDegrees": 0,
                "fixedPosition": false
            },
            "componentType": "wysiwyg.viewer.components.HoverBox",
            "props": {
                "type": "HoverBoxProperties",
                "metaData": {
                    "schemaVersion": "1.0"
                },
                "mobileDisplayedModeId": "comp-iw6l0bz7-mode-iw6l0c2l1"
            },
            "design": {
                "type": "MediaContainerDesignData",
                "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false
                },
                "background": {
                    "type": "BackgroundMedia",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "mediaRef": {
                        "type": "Image",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "title": "Couple in Shades",
                        "uri": "477b686b4f8749ec87ad608360762b96.jpg",
                        "description": "public/491df47b-5958-4dc7-9029-37f428755d45/c2a37dfc-36cf-4bfd-b940-55bac7bdc9e6",
                        "width": 1920,
                        "height": 1280,
                        "alt": "",
                        "artist": {
                            "id": "",
                            "name": ""
                        },
                        "opacity": 0.7
                    },
                    "color": "#000000",
                    "colorOpacity": 1,
                    "alignType": "center",
                    "fittingType": "fill",
                    "scrollType": "none",
                    "colorOverlay": "",
                    "colorOverlayOpacity": 0,
                    "showOverlayForMediaType": "WixVideo",
                    "mediaTransforms": {
                        "scale": 1
                    }
                },
                "cssStyle": {},
                "themeMappings": {},
                "charas": "design-ivkysp7u"
            },
            "style": {
                "type": "TopLevelStyle",
                "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false
                },
                "style": {
                    "properties": {
                        "alpha-bg": "1",
                        "alpha-brd": "1",
                        "bg": "206,224,193,1",
                        "boxShadowToggleOn-shd": "false",
                        "brd": "#324158",
                        "brw": "0px",
                        "rd": "0px",
                        "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                    },
                    "propertiesSource": {
                        "bg": "value",
                        "brd": "value",
                        "brw": "value",
                        "rd": "value",
                        "shd": "value"
                    },
                    "groups": {}
                },
                "componentClassName": "wysiwyg.viewer.components.HoverBox",
                "pageId": "",
                "compId": "",
                "styleType": "custom",
                "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer"
            }
        },
        "activeModes": {}
    },
    "preset": {
        "rect": {
            "width": 162,
            "height": 240,
            "x": -1,
            "y": 1043
        }
    }
}
const hbPreset10 = {
    "id": "hoverBoxPreset10",
    "structure": {
        "type": "Container",
        "components": [
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iw6l4vz1-mode-iw6l4w2t1"
                            ],
                            "layout": {
                                "width": 236,
                                "height": 353,
                                "x": 24,
                                "y": 23,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            }
                        }
                    ],
                    "isHiddenByModes": false
                },
                "skin": "svgshape.v2.Svg_06f469158f5346a3be7e4100e38370cd",
                "layout": {
                    "width": 246,
                    "height": 363,
                    "x": 19,
                    "y": 18,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.svgshape.SvgShape",
                "data": {
                    "type": "SvgShape",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    }
                },
                "props": {
                    "type": "SvgShapeProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "maintainAspectRatio": false
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "style": {
                        "properties": {
                            "alpha-fillcolor": "0",
                            "alpha-stroke": "1",
                            "fillcolor": "#FFFFFF",
                            "stroke": "#FFFFFF",
                            "strokewidth": "1"
                        },
                        "propertiesSource": {
                            "alpha-fillcolor": "value",
                            "alpha-stroke": "value",
                            "fillcolor": "value",
                            "stroke": "value",
                            "strokewidth": "value"
                        },
                        "groups": {}
                    },
                    "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "svgshape.v2.Svg_06f469158f5346a3be7e4100e38370cd"
                },
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"action\":\"modeIn\",\"name\":\"FadeIn\",\"delay\":0,\"duration\":0.35},{\"action\":\"modeOut\",\"name\":\"FadeOut\",\"delay\":0,\"duration\":0.35},{\"action\":\"modeChange\",\"name\":\"ModesMotion\",\"delay\":0,\"duration\":0.3,\"params\":{\"timingFunction\":\"EaseInOut\",\"modeIds\":[\"comp-iw6l4vz1-mode-iw6l4w2t1\"]},\"targetId\":\"\"}]"
                },
                "mobileStructure": {
                    "type": "Component",
                    "modes": {
                        "definitions": []
                    },
                    "skin": "svgshape.v2.Svg_06f469158f5346a3be7e4100e38370cd",
                    "layout": {
                        "width": 243,
                        "height": 343,
                        "x": 18,
                        "y": 19,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.svgshape.SvgShape",
                    "data": {
                        "type": "SvgShape",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        }
                    },
                    "props": {
                        "type": "SvgShapeProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "maintainAspectRatio": false
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "properties": {
                                "alpha-fillcolor": "0",
                                "alpha-stroke": "1",
                                "fillcolor": "#FFFFFF",
                                "stroke": "#FFFFFF",
                                "strokewidth": "1"
                            },
                            "propertiesSource": {
                                "alpha-fillcolor": "value",
                                "alpha-stroke": "value",
                                "fillcolor": "value",
                                "stroke": "value",
                                "strokewidth": "value"
                            },
                            "groups": {}
                        },
                        "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "svgshape.v2.Svg_06f469158f5346a3be7e4100e38370cd"
                    },
                    "behaviors": {
                        "type": "ObsoleteBehaviorsList",
                        "items": "[{\"action\":\"modeIn\",\"name\":\"FadeIn\",\"delay\":0,\"duration\":0.35},{\"action\":\"modeOut\",\"name\":\"FadeOut\",\"delay\":0,\"duration\":0.35},{\"action\":\"modeChange\",\"name\":\"ModesMotion\",\"delay\":0,\"duration\":0.3,\"params\":{\"timingFunction\":\"EaseInOut\",\"modeIds\":[\"comp-iw6l4vz1-mode-iw6l4w2t1\"]},\"targetId\":\"\"}]"
                    }
                }
            },
            {
                "type": "Component",
                "modes": {
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iw6l4vz1-mode-iw6l4w2t1"
                            ],
                            "layout": {
                                "width": 229,
                                "height": 68,
                                "x": 27,
                                "y": 140,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "isHiddenByModes": true
                        },
                        {
                            "modeIds": [
                                "comp-iw6l4vz1-mode-iw6l4w2t"
                            ],
                            "layout": {
                                "width": 229,
                                "height": 50,
                                "x": 27,
                                "y": 175,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            }
                        }
                    ],
                    "isHiddenByModes": false
                },
                "layout": {
                    "width": 229,
                    "height": 68,
                    "x": 27,
                    "y": 166,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "text": "<h6 class=\"font_6\" style=\"font-size:45px; line-height:1.1em; text-align:center\"><span style=\"letter-spacing:0.05em\"><span style=\"font-size:45px\"><span style=\"font-family:didot-w01-italic,serif\"><span style=\"color:#FFFFFF\">MENS</span></span></span></span></h6>\n",
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": []
                },
                "props": {
                    "type": "WRichTextProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "brightness": 1,
                    "packed": true
                },
                "style": "txtNew",
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"action\":\"modeChange\",\"name\":\"ModesMotion\",\"delay\":0,\"duration\":0.25,\"params\":{\"timingFunction\":\"EaseInOut\"},\"targetId\":\"\"},{\"action\":\"modeIn\",\"name\":\"FadeIn\",\"delay\":0,\"duration\":0.35},{\"action\":\"modeOut\",\"name\":\"FadeOut\",\"delay\":0,\"duration\":0.35}]"
                }
            },
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iw6l4vz1-mode-iw6l4w2t1"
                            ],
                            "layout": {
                                "width": 206,
                                "height": 29,
                                "x": 39,
                                "y": 184,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "isHiddenByModes": false,
                            "style": "txtNew",
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {
                                    "schemaVersion": "1.0"
                                },
                                "brightness": 1,
                                "packed": true
                            }
                        }
                    ],
                    "isHiddenByModes": true
                },
                "layout": {
                    "width": 206,
                    "height": 24,
                    "x": 30,
                    "y": 70,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "text": "<h6 class=\"font_6\" style=\"font-size:22px; text-align:center\"><span style=\"font-size:22px\"><a dataquery=\"#textLink_iw57psgm\"><span style=\"font-family:futura-lt-w01-book,sans-serif\"><span style=\"letter-spacing:0.1em\"><span style=\"color:#FFFFFF\">SHOP MENS</span></span></span></a></span></h6>\n",
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": [
                        {
                            "type": "PageLink",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "1.0",
                                "isHidden": false
                            },
                            "pageId": "#c1dmp"
                        }
                    ]
                },
                "props": {
                    "type": "WRichTextProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "brightness": 1,
                    "packed": true
                },
                "style": "txtNew",
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"name\":\"FadeIn\",\"delay\":0,\"duration\":0.3,\"action\":\"modeIn\",\"params\":{\"modeIds\":[\"comp-iw6l4vz1-mode-iw6l4w2t1\"]},\"targetId\":\"\"},{\"name\":\"FadeOut\",\"delay\":0,\"duration\":0.3,\"action\":\"modeOut\",\"params\":{\"modeIds\":[\"comp-iw6l4vz1-mode-iw6l4w2t1\"]},\"targetId\":\"\"}]"
                },
                "custom": {
                    "linkList": [
                        {
                            "type": "PageLink",
                            "id": "dataItem-iw6l4w372",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "1.0",
                                "isHidden": false
                            },
                            "pageId": "#c1dmp"
                        }
                    ]
                },
                "mobileStructure": {
                    "type": "Component",
                    "modes": {
                        "definitions": []
                    },
                    "layout": {
                        "width": 224,
                        "height": 32,
                        "x": 28,
                        "y": 174,
                        "scale": 1.1016949152542372,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.WRichText",
                    "data": {
                        "type": "StyledText",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "text": "<h6 class=\"font_6\" style=\"font-size:22px; text-align:center\"><span style=\"font-size:22px\"><a dataquery=\"#textLink_iw57psgm\"><span style=\"font-family:futura-lt-w01-book,sans-serif\"><span style=\"letter-spacing:0.1em\"><span style=\"color:#FFFFFF\">SHOP MENS</span></span></span></a></span></h6>\n",
                        "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                        "linkList": [
                            {
                                "type": "PageLink",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "pageId": "#c1dmp"
                            }
                        ]
                    },
                    "props": {
                        "type": "WRichTextProperties",
                        "metaData": {
                            "schemaVersion": "1.0",
                            "autoGenerated": false
                        },
                        "brightness": 1,
                        "packed": true
                    },
                    "style": "txtNew",
                    "behaviors": {
                        "type": "ObsoleteBehaviorsList",
                        "items": "[{\"name\":\"FadeIn\",\"delay\":0,\"duration\":0.3,\"action\":\"modeIn\",\"params\":{\"modeIds\":[\"comp-iw6l4vz1-mode-iw6l4w2t1\"]},\"targetId\":\"\"},{\"name\":\"FadeOut\",\"delay\":0,\"duration\":0.3,\"action\":\"modeOut\",\"params\":{\"modeIds\":[\"comp-iw6l4vz1-mode-iw6l4w2t1\"]},\"targetId\":\"\"}]"
                    },
                    "custom": {
                        "linkList": [
                            {
                                "type": "PageLink",
                                "id": "dataItem-iw6l4w372",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "pageId": "#c1dmp"
                            }
                        ]
                    }
                }
            }
        ],
        "modes": {
            "definitions": [
                {
                    "modeId": "comp-iw6l4vz1-mode-iw6l4w2t",
                    "type": "DEFAULT",
                    "label": "MODE_LABEL_FROM_PRESET",
                    "params": ""
                },
                {
                    "modeId": "comp-iw6l4vz1-mode-iw6l4w2t1",
                    "type": "HOVER",
                    "label": "MODE_LABEL_FROM_PRESET",
                    "params": ""
                }
            ],
            "overrides": [],
            "isHiddenByModes": false
        },
        "layout": {
            "width": 284,
            "height": 400,
            "x": 0,
            "y": 0,
            "scale": 1,
            "rotationInDegrees": 0,
            "fixedPosition": false
        },
        "componentType": "wysiwyg.viewer.components.HoverBox",
        "props": {
            "type": "HoverBoxProperties",
            "metaData": {
                "schemaVersion": "1.0"
            }
        },
        "design": {
            "type": "MediaContainerDesignData",
            "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false
            },
            "background": {
                "type": "BackgroundMedia",
                "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false
                },
                "mediaRef": {
                    "type": "Image",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "title": "Fashion Portrait of a Bearded Man",
                    "uri": "83524445ca90408594501cacb2567307.jpg",
                    "description": "public/491df47b-5958-4dc7-9029-37f428755d45/08616385-e843-431b-bd64-b8b845ff47bb",
                    "width": 4044,
                    "height": 3639,
                    "alt": "",
                    "artist": {
                        "id": "mobile_designItem_ivl229j21",
                        "name": ""
                    },
                    "opacity": 0.7
                },
                "color": "#000000",
                "colorOpacity": 1,
                "alignType": "center",
                "fittingType": "fill",
                "scrollType": "none",
                "colorOverlay": "",
                "colorOverlayOpacity": 0,
                "showOverlayForMediaType": "WixVideo",
                "mediaTransforms": {
                    "scale": 1
                }
            },
            "cssStyle": {},
            "themeMappings": {},
            "charas": "design-ivl229j7"
        },
        "style": {
            "type": "TopLevelStyle",
            "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false
            },
            "style": {
                "properties": {
                    "alpha-bg": "1",
                    "alpha-brd": "1",
                    "bg": "206,224,193,1",
                    "boxShadowToggleOn-shd": "false",
                    "brd": "#324158",
                    "brw": "0px",
                    "rd": "0px",
                    "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                },
                "propertiesSource": {
                    "bg": "value",
                    "brd": "value",
                    "brw": "value",
                    "rd": "value",
                    "shd": "value"
                },
                "groups": {}
            },
            "componentClassName": "wysiwyg.viewer.components.HoverBox",
            "pageId": "",
            "compId": "",
            "styleType": "custom",
            "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer"
        },
        "mobileStructure": {
            "type": "Container",
            "modes": {
                "definitions": [
                    {
                        "modeId": "comp-iw6l4vz1-mode-iw6l4w2t",
                        "type": "DEFAULT",
                        "label": "MODE_LABEL_FROM_PRESET",
                        "params": ""
                    },
                    {
                        "modeId": "comp-iw6l4vz1-mode-iw6l4w2t1",
                        "type": "HOVER",
                        "label": "MODE_LABEL_FROM_PRESET",
                        "params": ""
                    }
                ]
            },
            "layout": {
                "width": 280,
                "height": 380,
                "x": 20,
                "y": 10,
                "scale": 1,
                "rotationInDegrees": 0,
                "fixedPosition": false
            },
            "componentType": "wysiwyg.viewer.components.HoverBox",
            "props": {
                "type": "HoverBoxProperties",
                "metaData": {
                    "schemaVersion": "1.0"
                }
            },
            "design": {
                "type": "MediaContainerDesignData",
                "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false
                },
                "background": {
                    "type": "BackgroundMedia",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "mediaRef": {
                        "type": "Image",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "title": "Fashion Portrait of a Bearded Man",
                        "uri": "83524445ca90408594501cacb2567307.jpg",
                        "description": "public/491df47b-5958-4dc7-9029-37f428755d45/08616385-e843-431b-bd64-b8b845ff47bb",
                        "width": 4044,
                        "height": 3639,
                        "alt": "",
                        "artist": {
                            "id": "mobile_designItem_ivl229j21",
                            "name": ""
                        },
                        "opacity": 0.7
                    },
                    "color": "#000000",
                    "colorOpacity": 1,
                    "alignType": "center",
                    "fittingType": "fill",
                    "scrollType": "none",
                    "colorOverlay": "",
                    "colorOverlayOpacity": 0,
                    "showOverlayForMediaType": "WixVideo",
                    "mediaTransforms": {
                        "scale": 1
                    }
                },
                "cssStyle": {},
                "themeMappings": {},
                "charas": "design-ivl229j3"
            },
            "style": {
                "type": "TopLevelStyle",
                "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false
                },
                "style": {
                    "properties": {
                        "alpha-bg": "1",
                        "alpha-brd": "1",
                        "bg": "206,224,193,1",
                        "boxShadowToggleOn-shd": "false",
                        "brd": "#324158",
                        "brw": "0px",
                        "rd": "0px",
                        "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                    },
                    "propertiesSource": {
                        "bg": "value",
                        "brd": "value",
                        "brw": "value",
                        "rd": "value",
                        "shd": "value"
                    },
                    "groups": {}
                },
                "componentClassName": "wysiwyg.viewer.components.HoverBox",
                "pageId": "",
                "compId": "",
                "styleType": "custom",
                "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer"
            }
        },
        "activeModes": {}
    },
    "preset": {
        "rect": {
            "width": 162,
            "height": 240,
            "x": 161,
            "y": 1043
        }
    }
}
const hbPreset11 = {
    "id": "hoverBoxPreset11",
    "structure": {
        "type": "Container",
        "components": [
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [],
                    "isHiddenByModes": false
                },
                "skin": "svgshape.v2.Svg_06f469158f5346a3be7e4100e38370cd",
                "layout": {
                    "width": 468,
                    "height": 130,
                    "x": 70,
                    "y": 60,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.svgshape.SvgShape",
                "data": {
                    "type": "SvgShape",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    }
                },
                "props": {
                    "type": "SvgShapeProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "maintainAspectRatio": false
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "style": {
                        "properties": {
                            "alpha-fillcolor": "1",
                            "alpha-stroke": "1",
                            "fillcolor": "#2B2929",
                            "stroke": "#AB9E5E",
                            "strokewidth": "1"
                        },
                        "propertiesSource": {
                            "fillcolor": "value",
                            "stroke": "value",
                            "strokewidth": "value"
                        },
                        "groups": {}
                    },
                    "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "svgshape.v2.Svg_06f469158f5346a3be7e4100e38370cd"
                },
                "mobileStructure": {
                    "type": "Component",
                    "modes": {
                        "definitions": []
                    },
                    "skin": "svgshape.v2.Svg_06f469158f5346a3be7e4100e38370cd",
                    "layout": {
                        "width": 216,
                        "height": 101,
                        "x": 32,
                        "y": 30,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.svgshape.SvgShape",
                    "data": {
                        "type": "SvgShape",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        }
                    },
                    "props": {
                        "type": "SvgShapeProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "maintainAspectRatio": false
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "properties": {
                                "alpha-fillcolor": "1",
                                "alpha-stroke": "1",
                                "fillcolor": "#2B2929",
                                "stroke": "#AB9E5E",
                                "strokewidth": "1"
                            },
                            "propertiesSource": {
                                "fillcolor": "value",
                                "stroke": "value",
                                "strokewidth": "value"
                            },
                            "groups": {}
                        },
                        "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "svgshape.v2.Svg_06f469158f5346a3be7e4100e38370cd"
                    }
                }
            },
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwawrs28-mode-iwawrs5n"
                            ],
                            "layout": {
                                "width": 468,
                                "height": 40,
                                "x": 70,
                                "y": 111,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {
                                    "schemaVersion": "1.0"
                                },
                                "brightness": 1,
                                "packed": true
                            }
                        },
                        {
                            "modeIds": [
                                "comp-iwawrs28-mode-iwawrs5n1"
                            ],
                            "layout": {
                                "width": 304,
                                "height": 80,
                                "x": 145,
                                "y": 124,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "isHiddenByModes": true,
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {
                                    "schemaVersion": "1.0"
                                },
                                "brightness": 1,
                                "packed": true
                            }
                        }
                    ],
                    "isHiddenByModes": false
                },
                "layout": {
                    "width": 304,
                    "height": 24,
                    "x": 145,
                    "y": 124,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "text": "<h6 class=\"font_6\" style=\"font-size:33px; text-align:center\"><span style=\"color:#AB9E5E\"><span style=\"font-size:33px\"><span style=\"font-family:geotica-w01-four-open,fantasy\"><span style=\"letter-spacing:0.1em\">THE PARTY&rsquo;S ON!</span></span></span></span></h6>\n",
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": []
                },
                "props": {
                    "type": "WRichTextProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "brightness": 1,
                    "packed": false
                },
                "style": "txtNew",
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"action\":\"modeIn\",\"name\":\"FadeIn\",\"delay\":0,\"duration\":0.35},{\"action\":\"modeOut\",\"name\":\"FadeOut\",\"delay\":0,\"duration\":0.35}]"
                }
            },
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwawrs28-mode-iwawrs5n1"
                            ],
                            "layout": {
                                "width": 468,
                                "height": 32,
                                "x": 70,
                                "y": 111,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "isHiddenByModes": false,
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {
                                    "schemaVersion": "1.0"
                                },
                                "brightness": 1,
                                "packed": true
                            }
                        }
                    ],
                    "isHiddenByModes": true
                },
                "layout": {
                    "width": 304,
                    "height": 25,
                    "x": 165,
                    "y": 122,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "text": "<h6 class=\"font_6\" style=\"font-size:28px; text-align:center\"><a dataquery=\"#textLink_iw3egii6\"><span style=\"font-size:28px\"><span style=\"font-family:josefin slab,serif\"><span style=\"color:#AB9E5E\">Don&rsquo;t Miss Out. <span style=\"font-weight:bold\">Book It Now</span></span></span></span></a></h6>\n",
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": [
                        {
                            "type": "PageLink",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "1.0",
                                "isHidden": false
                            },
                            "pageId": "#c1dmp"
                        }
                    ]
                },
                "props": {
                    "type": "WRichTextProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "brightness": 1,
                    "packed": false
                },
                "style": "txtNew",
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"name\":\"FadeIn\",\"delay\":0,\"duration\":0.5,\"action\":\"modeIn\",\"params\":{\"modeIds\":[\"comp-iwawrs28-mode-iwawrs5n1\"]},\"targetId\":\"\"},{\"name\":\"FadeOut\",\"delay\":0,\"duration\":0.5,\"action\":\"modeOut\",\"params\":{\"modeIds\":[\"comp-iwawrs28-mode-iwawrs5n1\"]},\"targetId\":\"\"}]"
                },
                "custom": {
                    "linkList": [
                        {
                            "type": "PageLink",
                            "id": "dataItem-iwawrs621",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "1.0",
                                "isHidden": false
                            },
                            "pageId": "#c1dmp"
                        }
                    ]
                },
                "mobileStructure": {
                    "type": "Component",
                    "modes": {
                        "definitions": []
                    },
                    "layout": {
                        "width": 193,
                        "height": 60,
                        "x": 44,
                        "y": 51,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.WRichText",
                    "data": {
                        "type": "StyledText",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "text": "<h6 class=\"font_6\" style=\"font-size:28px; text-align:center\"><a dataquery=\"#textLink_iw3egii6\"><span style=\"font-size:28px\"><span style=\"font-family:josefin slab,serif\"><span style=\"color:#AB9E5E\">Don&rsquo;t Miss Out. <span style=\"font-weight:bold\">Book It Now</span></span></span></span></a></h6>\n",
                        "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                        "linkList": [
                            {
                                "type": "PageLink",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "pageId": "#c1dmp"
                            }
                        ]
                    },
                    "props": {
                        "type": "WRichTextProperties",
                        "metaData": {
                            "schemaVersion": "1.0",
                            "autoGenerated": false
                        },
                        "brightness": 1,
                        "packed": true
                    },
                    "style": "txtNew",
                    "behaviors": {
                        "type": "ObsoleteBehaviorsList",
                        "items": "[{\"name\":\"FadeIn\",\"delay\":0,\"duration\":0.5,\"action\":\"modeIn\",\"params\":{\"modeIds\":[\"comp-iwawrs28-mode-iwawrs5n1\"]},\"targetId\":\"\"},{\"name\":\"FadeOut\",\"delay\":0,\"duration\":0.5,\"action\":\"modeOut\",\"params\":{\"modeIds\":[\"comp-iwawrs28-mode-iwawrs5n1\"]},\"targetId\":\"\"}]"
                    },
                    "custom": {
                        "linkList": [
                            {
                                "type": "PageLink",
                                "id": "dataItem-iwawrs621",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "pageId": "#c1dmp"
                            }
                        ]
                    }
                }
            },
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwawrs28-mode-iwawrs5n1"
                            ],
                            "layout": {
                                "width": 14,
                                "height": 8,
                                "x": 478,
                                "y": 126,
                                "scale": 1,
                                "rotationInDegrees": 270,
                                "fixedPosition": false
                            },
                            "isHiddenByModes": false,
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "style": {
                                    "properties": {
                                        "alpha-fillcolor": "1",
                                        "alpha-stroke": "1",
                                        "fillcolor": "#AB9E5E",
                                        "stroke": "#000000",
                                        "strokewidth": "0px"
                                    },
                                    "propertiesSource": {
                                        "fillcolor": "value",
                                        "stroke": "value",
                                        "strokewidth": "value"
                                    },
                                    "groups": {}
                                },
                                "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "svgshape.v2.Svg_4f4fbf1f8b7146f69dafa4814a22e5f3"
                            }
                        }
                    ],
                    "isHiddenByModes": true
                },
                "skin": "svgshape.v2.Svg_4f4fbf1f8b7146f69dafa4814a22e5f3",
                "layout": {
                    "width": 102,
                    "height": 60,
                    "x": 466,
                    "y": 97,
                    "scale": 1,
                    "rotationInDegrees": 180,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.svgshape.SvgShape",
                "data": {
                    "type": "SvgShape",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    }
                },
                "props": {
                    "type": "SvgShapeProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "maintainAspectRatio": true
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "style": {
                        "properties": {
                            "alpha-fillcolor": "1",
                            "alpha-stroke": "1",
                            "fillcolor": "#B7121B",
                            "stroke": "#ED1566",
                            "strokewidth": "0px"
                        },
                        "propertiesSource": {
                            "fillcolor": "value",
                            "stroke": "value",
                            "strokewidth": "value"
                        },
                        "groups": {}
                    },
                    "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "svgshape.v2.Svg_4f4fbf1f8b7146f69dafa4814a22e5f3"
                },
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"name\":\"FloatIn\",\"delay\":0,\"duration\":0.5,\"params\":{\"direction\":\"left\",\"modeIds\":[\"comp-iwawrs28-mode-iwawrs5n1\"]},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"FloatOut\",\"delay\":0,\"duration\":0.5,\"params\":{\"direction\":\"left\",\"modeIds\":[\"comp-iwawrs28-mode-iwawrs5n1\"]},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                },
                "mobileStructure": {
                    "type": "Component",
                    "modes": {
                        "definitions": []
                    },
                    "skin": "svgshape.v2.Svg_4f4fbf1f8b7146f69dafa4814a22e5f3",
                    "layout": {
                        "width": 11,
                        "height": 13,
                        "x": 213,
                        "y": 92,
                        "scale": 1,
                        "rotationInDegrees": 270,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.svgshape.SvgShape",
                    "data": {
                        "type": "SvgShape",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        }
                    },
                    "props": {
                        "type": "SvgShapeProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "maintainAspectRatio": true
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "properties": {
                                "alpha-fillcolor": "1",
                                "alpha-stroke": "1",
                                "fillcolor": "#AB9E5E",
                                "stroke": "#000000",
                                "strokewidth": "0px"
                            },
                            "propertiesSource": {
                                "fillcolor": "value",
                                "stroke": "value",
                                "strokewidth": "value"
                            },
                            "groups": {}
                        },
                        "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "svgshape.v2.Svg_4f4fbf1f8b7146f69dafa4814a22e5f3"
                    },
                    "behaviors": {
                        "type": "ObsoleteBehaviorsList",
                        "items": "[{\"name\":\"FloatIn\",\"delay\":0,\"duration\":0.5,\"params\":{\"direction\":\"left\",\"modeIds\":[\"comp-iwawrs28-mode-iwawrs5n1\"]},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"FloatOut\",\"delay\":0,\"duration\":0.5,\"params\":{\"direction\":\"left\",\"modeIds\":[\"comp-iwawrs28-mode-iwawrs5n1\"]},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                    }
                }
            }
        ],
        "modes": {
            "definitions": [
                {
                    "modeId": "comp-iwawrs28-mode-iwawrs5n",
                    "type": "DEFAULT",
                    "label": "MODE_LABEL_FROM_PRESET",
                    "params": ""
                },
                {
                    "modeId": "comp-iwawrs28-mode-iwawrs5n1",
                    "type": "HOVER",
                    "label": "MODE_LABEL_FROM_PRESET",
                    "params": ""
                }
            ],
            "overrides": [
                {
                    "modeIds": [
                        "comp-iwawrs28-mode-iwawrs5n"
                    ],
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "1.0",
                                "isHidden": false
                            },
                            "mediaRef": {
                                "type": "Image",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "title": "Particles_Final_700px.jpg",
                                "uri": "035244_257ed5e146d64042bf444af357418e18~mv2.jpg",
                                "description": "private/allMedia_picture",
                                "width": 700,
                                "height": 300,
                                "alt": "",
                                "artist": {
                                    "id": "",
                                    "name": ""
                                }
                            },
                            "color": "#FFF9EB",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0,
                            "showOverlayForMediaType": "WixVideo",
                            "mediaTransforms": {
                                "scale": 1
                            }
                        },
                        "cssStyle": {},
                        "themeMappings": {},
                        "charas": "design-iwg2wrc6",
                        "dataChangeBehaviors": []
                    }
                }
            ],
            "isHiddenByModes": false
        },
        "layout": {
            "width": 608,
            "height": 250,
            "x": 0,
            "y": 0,
            "scale": 1,
            "rotationInDegrees": 0,
            "fixedPosition": false
        },
        "componentType": "wysiwyg.viewer.components.HoverBox",
        "props": {
            "type": "HoverBoxProperties",
            "metaData": {
                "schemaVersion": "1.0"
            },
            "mobileDisplayedModeId": "comp-iwawrs28-mode-iwawrs5n1"
        },
        "design": {
            "type": "MediaContainerDesignData",
            "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false
            },
            "background": {
                "type": "BackgroundMedia",
                "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false
                },
                "mediaRef": {
                    "type": "Image",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "title": "Particles_final.gif",
                    "uri": "035244_377be021ff9e4044aa29ddf8bfabb7ff~mv2.gif",
                    "description": "private/allMedia_picture",
                    "width": 700,
                    "height": 300,
                    "alt": "",
                    "artist": {
                        "id": "",
                        "name": ""
                    }
                },
                "color": "#FFF9EB",
                "colorOpacity": 1,
                "alignType": "center",
                "fittingType": "fill",
                "scrollType": "none",
                "colorOverlay": "",
                "colorOverlayOpacity": 0,
                "showOverlayForMediaType": "WixVideo",
                "mediaTransforms": {
                    "scale": 1
                }
            },
            "cssStyle": {},
            "themeMappings": {},
            "charas": "design-iwaw5zpa",
            "dataChangeBehaviors": []
        },
        "style": {
            "type": "TopLevelStyle",
            "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false
            },
            "style": {
                "properties": {
                    "alpha-bg": "1",
                    "alpha-brd": "1",
                    "bg": "206,224,193,1",
                    "boxShadowToggleOn-shd": "false",
                    "brd": "#324158",
                    "brw": "0px",
                    "rd": "0px",
                    "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                },
                "propertiesSource": {
                    "bg": "value",
                    "brd": "value",
                    "brw": "value",
                    "rd": "value",
                    "shd": "value"
                },
                "groups": {}
            },
            "componentClassName": "wysiwyg.viewer.components.HoverBox",
            "pageId": "",
            "compId": "",
            "styleType": "custom",
            "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer"
        },
        "mobileStructure": {
            "type": "Container",
            "modes": {
                "definitions": [
                    {
                        "modeId": "comp-iwawrs28-mode-iwawrs5n",
                        "type": "DEFAULT",
                        "label": "MODE_LABEL_FROM_PRESET",
                        "params": ""
                    },
                    {
                        "modeId": "comp-iwawrs28-mode-iwawrs5n1",
                        "type": "HOVER",
                        "label": "MODE_LABEL_FROM_PRESET",
                        "params": ""
                    }
                ]
            },
            "layout": {
                "width": 280,
                "height": 160,
                "x": 20,
                "y": 10,
                "scale": 1,
                "rotationInDegrees": 0,
                "fixedPosition": false
            },
            "componentType": "wysiwyg.viewer.components.HoverBox",
            "props": {
                "type": "HoverBoxProperties",
                "metaData": {
                    "schemaVersion": "1.0"
                },
                "mobileDisplayedModeId": "comp-iwawrs28-mode-iwawrs5n1"
            },
            "design": {
                "type": "MediaContainerDesignData",
                "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false
                },
                "background": {
                    "type": "BackgroundMedia",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "mediaRef": {
                        "type": "Image",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "title": "Particles_final.gif",
                        "uri": "035244_377be021ff9e4044aa29ddf8bfabb7ff~mv2.gif",
                        "description": "private/allMedia_picture",
                        "width": 700,
                        "height": 300,
                        "alt": "",
                        "artist": {
                            "id": "",
                            "name": ""
                        }
                    },
                    "color": "#FFF9EB",
                    "colorOpacity": 1,
                    "alignType": "center",
                    "fittingType": "fill",
                    "scrollType": "none",
                    "colorOverlay": "",
                    "colorOverlayOpacity": 0,
                    "showOverlayForMediaType": "WixVideo",
                    "mediaTransforms": {
                        "scale": 1
                    }
                },
                "cssStyle": {},
                "themeMappings": {},
                "charas": "design-iwaw5zpa",
                "dataChangeBehaviors": []
            },
            "style": {
                "type": "TopLevelStyle",
                "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false
                },
                "style": {
                    "properties": {
                        "alpha-bg": "1",
                        "alpha-brd": "1",
                        "bg": "206,224,193,1",
                        "boxShadowToggleOn-shd": "false",
                        "brd": "#324158",
                        "brw": "0px",
                        "rd": "0px",
                        "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                    },
                    "propertiesSource": {
                        "bg": "value",
                        "brd": "value",
                        "brw": "value",
                        "rd": "value",
                        "shd": "value"
                    },
                    "groups": {}
                },
                "componentClassName": "wysiwyg.viewer.components.HoverBox",
                "pageId": "",
                "compId": "",
                "styleType": "custom",
                "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer"
            }
        },
        "activeModes": {}
    },
    "preset": {
        "rect": {
            "width": 324,
            "height": 164,
            "x": -1,
            "y": 1283
        }
    }
}
const hbPreset12 = {
    "id": "hoverBoxPreset12",
    "structure": {
        "type": "Container",
        "components": [
            {
                "type": "Component",
                "modes": {
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iw2dsivk-mode-iw2dsiz91"
                            ],
                            "layout": {
                                "width": 562,
                                "height": 104,
                                "x": 22,
                                "y": 66,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "isHiddenByModes": true
                        },
                        {
                            "modeIds": [
                                "comp-iw2dsivk-mode-iw2dsiz9"
                            ],
                            "layout": {
                                "width": 521,
                                "height": 104,
                                "x": 46,
                                "y": 45,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            }
                        }
                    ],
                    "isHiddenByModes": false
                },
                "layout": {
                    "width": 521,
                    "height": 104,
                    "x": 46,
                    "y": 45,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "text": "<h6 class=\"font_6\" style=\"font-size:35px\"><span style=\"color:#F0A330;\"><span style=\"font-size:35px\"><span style=\"font-family:lulo-clean-w01-one-bold,sans-serif\">NEW SINGLE</span></span></span></h6>\n\n<h6 class=\"font_6\" style=\"font-size:35px\"><span style=\"color:#FFFFFF\"><span style=\"font-size:35px\"><span style=\"font-family:lulo-clean-w01-one-bold,sans-serif\">OUT NOW</span></span></span></h6>\n",
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": []
                },
                "props": {
                    "type": "WRichTextProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "brightness": 1,
                    "packed": true
                },
                "style": "txtNew",
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"action\":\"modeChange\",\"name\":\"ModesMotion\",\"delay\":0,\"duration\":0.25,\"params\":{\"timingFunction\":\"EaseInOut\"},\"targetId\":\"\"},{\"action\":\"modeIn\",\"name\":\"FadeIn\",\"delay\":0,\"duration\":0.35},{\"action\":\"modeOut\",\"name\":\"FadeOut\",\"delay\":0,\"duration\":0.35}]"
                }
            },
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iw2dsivk-mode-iw2dsiz9"
                            ],
                            "layout": {
                                "width": 304,
                                "height": 21,
                                "x": 46,
                                "y": 296,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {
                                    "schemaVersion": "1.0"
                                },
                                "brightness": 1,
                                "packed": true
                            }
                        },
                        {
                            "modeIds": [
                                "comp-iw2dsivk-mode-iw2dsiz91"
                            ],
                            "layout": {
                                "width": 304,
                                "height": 21,
                                "x": 147,
                                "y": 270,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "isHiddenByModes": true,
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {
                                    "schemaVersion": "1.0"
                                },
                                "brightness": 1,
                                "packed": true
                            }
                        }
                    ],
                    "isHiddenByModes": false
                },
                "layout": {
                    "width": 304,
                    "height": 21,
                    "x": 46,
                    "y": 296,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "text": "<p class=\"font_8\" style=\"font-size:17px\"><span style=\"letter-spacing:0.25em\"><span style=\"font-size:17px\"><span style=\"color:#FFFFFF\"><span style=\"font-family:helvetica-w01-light,helvetica-w02-light,sans-serif\">Get It Here</span></span></span></span></p>\n",
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": []
                },
                "props": {
                    "type": "WRichTextProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "brightness": 1,
                    "packed": true
                },
                "style": "txtNew",
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"action\":\"modeIn\",\"name\":\"FadeIn\",\"delay\":0,\"duration\":0.35},{\"action\":\"modeOut\",\"name\":\"FadeOut\",\"delay\":0,\"duration\":0.35}]"
                }
            },
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iw2dsivk-mode-iw2dsiz9"
                            ],
                            "layout": {
                                "width": 16,
                                "height": 8,
                                "x": 181,
                                "y": 303,
                                "scale": 1,
                                "rotationInDegrees": 270,
                                "fixedPosition": false
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "style": {
                                    "properties": {
                                        "alpha-fillcolor": "1",
                                        "alpha-stroke": "1",
                                        "fillcolor": "#FFFFFF",
                                        "stroke": "#FFFFFF",
                                        "strokewidth": "0px"
                                    },
                                    "propertiesSource": {
                                        "fillcolor": "value",
                                        "stroke": "value",
                                        "strokewidth": "value"
                                    },
                                    "groups": {}
                                },
                                "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "svgshape.v2.Svg_4f4fbf1f8b7146f69dafa4814a22e5f3"
                            }
                        },
                        {
                            "modeIds": [
                                "comp-iw2dsivk-mode-iw2dsiz91"
                            ],
                            "layout": {
                                "width": 102,
                                "height": 60,
                                "x": 103,
                                "y": 225,
                                "scale": 1,
                                "rotationInDegrees": 180,
                                "fixedPosition": false
                            },
                            "isHiddenByModes": true
                        }
                    ],
                    "isHiddenByModes": false
                },
                "skin": "svgshape.v2.Svg_4f4fbf1f8b7146f69dafa4814a22e5f3",
                "layout": {
                    "width": 16,
                    "height": 8,
                    "x": 181,
                    "y": 303,
                    "scale": 1,
                    "rotationInDegrees": 270,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.svgshape.SvgShape",
                "data": {
                    "type": "SvgShape",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    }
                },
                "props": {
                    "type": "SvgShapeProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "maintainAspectRatio": true
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "style": {
                        "properties": {
                            "alpha-fillcolor": "1",
                            "alpha-stroke": "1",
                            "fillcolor": "#FFFFFF",
                            "stroke": "#FFFFFF",
                            "strokewidth": "0px"
                        },
                        "propertiesSource": {
                            "fillcolor": "value",
                            "stroke": "value",
                            "strokewidth": "value"
                        },
                        "groups": {}
                    },
                    "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "svgshape.v2.Svg_4f4fbf1f8b7146f69dafa4814a22e5f3"
                },
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"action\":\"modeIn\",\"name\":\"FadeIn\",\"delay\":0,\"duration\":0.35},{\"action\":\"modeOut\",\"name\":\"FadeOut\",\"delay\":0,\"duration\":0.35}]"
                }
            },
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iw2dsivk-mode-iw2dsiz9"
                            ],
                            "isHiddenByModes": true
                        }
                    ],
                    "isHiddenByModes": false
                },
                "skin": "wysiwyg.common.components.singleaudioplayer.viewer.skins.SingleAudioPlayerSkin",
                "layout": {
                    "width": 540,
                    "height": 60,
                    "x": 34,
                    "y": 263,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.common.components.singleaudioplayer.viewer.SingleAudioPlayer",
                "data": {
                    "type": "SingleAudioPlayer",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "uri": "035244_629273b085a947378118920886d6be82.mp3",
                    "originalFileName": "Zack Hemsey - The Way (Instrumental).mp3",
                    "artist": "Artist Name",
                    "track": "New Single"
                },
                "props": {
                    "type": "SingleAudioPlayerProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "volume": 50,
                    "autoplay": false,
                    "loop": false,
                    "showArtist": true,
                    "showTrack": true
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "style": {
                        "properties": {
                            "alpha-bg": "0",
                            "alpha-brd": "1",
                            "audioIcons": "color_11",
                            "audioText": "color_11",
                            "bg": "#FFFFFF",
                            "boxShadowToggleOn-shd": "false",
                            "brd": "color_15",
                            "brw": "0",
                            "fnt": "font_9",
                            "fnts": "font_10",
                            "prog": "color_11",
                            "rd": "0",
                            "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                            "vol": "color_11"
                        },
                        "propertiesSource": {
                            "alpha-bg": "value",
                            "alpha-brd": "value",
                            "audioIcons": "theme",
                            "audioText": "theme",
                            "bg": "value",
                            "boxShadowToggleOn-shd": "value",
                            "brd": "theme",
                            "brw": "value",
                            "fnt": "theme",
                            "fnts": "theme",
                            "prog": "theme",
                            "rd": "value",
                            "shd": "value",
                            "vol": "theme"
                        },
                        "groups": {}
                    },
                    "componentClassName": "wysiwyg.common.components.singleaudioplayer.viewer.SingleAudioPlayer",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.common.components.singleaudioplayer.viewer.skins.SingleAudioPlayerSkin"
                },
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"name\":\"FloatIn\",\"delay\":0,\"duration\":0.3,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iw2dsivk-mode-iw2dsiz91\"]},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"FloatOut\",\"delay\":0,\"duration\":0.3,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iw2dsivk-mode-iw2dsiz91\"]},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                },
                "mobileStructure": {
                    "type": "Component",
                    "modes": {
                        "definitions": []
                    },
                    "skin": "wysiwyg.common.components.singleaudioplayer.viewer.skins.SingleAudioPlayerSkin",
                    "layout": {
                        "width": 260,
                        "height": 76,
                        "x": 10,
                        "y": 109,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.common.components.singleaudioplayer.viewer.SingleAudioPlayer",
                    "data": {
                        "type": "SingleAudioPlayer",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "uri": "035244_629273b085a947378118920886d6be82.mp3",
                        "originalFileName": "Zack Hemsey - The Way (Instrumental).mp3",
                        "artist": "Artist Name",
                        "track": "New Single"
                    },
                    "props": {
                        "type": "SingleAudioPlayerProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "volume": 50,
                        "autoplay": false,
                        "loop": false,
                        "showArtist": true,
                        "showTrack": true
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "properties": {
                                "alpha-bg": "0",
                                "alpha-brd": "1",
                                "audioIcons": "color_11",
                                "audioText": "color_11",
                                "bg": "#FFFFFF",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "color_15",
                                "brw": "0",
                                "fnt": "font_9",
                                "fnts": "font_10",
                                "prog": "color_11",
                                "rd": "0",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                "vol": "color_11"
                            },
                            "propertiesSource": {
                                "alpha-bg": "value",
                                "alpha-brd": "value",
                                "audioIcons": "theme",
                                "audioText": "theme",
                                "bg": "value",
                                "boxShadowToggleOn-shd": "value",
                                "brd": "theme",
                                "brw": "value",
                                "fnt": "theme",
                                "fnts": "theme",
                                "prog": "theme",
                                "rd": "value",
                                "shd": "value",
                                "vol": "theme"
                            },
                            "groups": {}
                        },
                        "componentClassName": "wysiwyg.common.components.singleaudioplayer.viewer.SingleAudioPlayer",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.singleaudioplayer.viewer.skins.SingleAudioPlayerSkin"
                    },
                    "behaviors": {
                        "type": "ObsoleteBehaviorsList",
                        "items": "[{\"name\":\"FloatIn\",\"delay\":0,\"duration\":0.3,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iw2dsivk-mode-iw2dsiz91\"]},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"FloatOut\",\"delay\":0,\"duration\":0.3,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iw2dsivk-mode-iw2dsiz91\"]},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                    }
                }
            }
        ],
        "modes": {
            "definitions": [
                {
                    "modeId": "comp-iw2dsivk-mode-iw2dsiz9",
                    "type": "DEFAULT",
                    "label": "MODE_LABEL_FROM_PRESET",
                    "params": ""
                },
                {
                    "modeId": "comp-iw2dsivk-mode-iw2dsiz91",
                    "type": "HOVER",
                    "label": "MODE_LABEL_FROM_PRESET",
                    "params": ""
                }
            ],
            "overrides": [
                {
                    "modeIds": [
                        "comp-iw2dsivk-mode-iw2dsiz91"
                    ],
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "1.0",
                                "isHidden": false
                            },
                            "mediaRef": {
                                "type": "Image",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "title": "Final_Image_Strip.jpg",
                                "uri": "035244_7fc6f489da854275beed101a6b9f29f2~mv2_d_1736_1200_s_2.jpg",
                                "description": "private/allMedia_picture",
                                "width": 1736,
                                "height": 1200,
                                "alt": "",
                                "artist": {
                                    "id": "",
                                    "name": ""
                                },
                                "opacity": 1
                            },
                            "color": "#000000",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "#000000",
                            "colorOverlayOpacity": 0.4,
                            "showOverlayForMediaType": "all",
                            "mediaTransforms": {
                                "scale": 1
                            }
                        },
                        "cssStyle": {},
                        "themeMappings": {},
                        "charas": "design-iw3mse9o",
                        "dataChangeBehaviors": [
                            {
                                "trigger": "in",
                                "type": "animation",
                                "part": "overlay",
                                "name": "FadeIn",
                                "params": {
                                    "delay": 0,
                                    "duration": 0.5
                                }
                            }
                        ]
                    }
                }
            ],
            "isHiddenByModes": false
        },
        "layout": {
            "width": 608,
            "height": 360,
            "x": 0,
            "y": 0,
            "scale": 1,
            "rotationInDegrees": 0,
            "fixedPosition": false
        },
        "componentType": "wysiwyg.viewer.components.HoverBox",
        "props": {
            "type": "HoverBoxProperties",
            "metaData": {
                "schemaVersion": "1.0"
            },
            "mobileDisplayedModeId": "comp-iw2dsivk-mode-iw2dsiz91"
        },
        "design": {
            "type": "MediaContainerDesignData",
            "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false
            },
            "background": {
                "type": "BackgroundMedia",
                "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false
                },
                "mediaRef": {
                    "type": "Image",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "title": "Final_Image_Strip.jpg",
                    "uri": "035244_7fc6f489da854275beed101a6b9f29f2~mv2_d_1736_1200_s_2.jpg",
                    "description": "private/allMedia_picture",
                    "width": 1736,
                    "height": 1200,
                    "alt": "",
                    "artist": {
                        "id": "",
                        "name": ""
                    },
                    "opacity": 0.8
                },
                "color": "#000000",
                "colorOpacity": 1,
                "alignType": "center",
                "fittingType": "fill",
                "scrollType": "none",
                "colorOverlay": "",
                "colorOverlayOpacity": 0,
                "showOverlayForMediaType": "WixVideo",
                "mediaTransforms": {
                    "scale": 1
                }
            },
            "cssStyle": {},
            "themeMappings": {},
            "charas": "design-ivjljzeg"
        },
        "style": {
            "type": "TopLevelStyle",
            "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false
            },
            "style": {
                "properties": {
                    "alpha-bg": "1",
                    "alpha-brd": "1",
                    "bg": "206,224,193,1",
                    "boxShadowToggleOn-shd": "false",
                    "brd": "#324158",
                    "brw": "0px",
                    "rd": "0px",
                    "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                },
                "propertiesSource": {
                    "bg": "value",
                    "brd": "value",
                    "brw": "value",
                    "rd": "value",
                    "shd": "value"
                },
                "groups": {}
            },
            "componentClassName": "wysiwyg.viewer.components.HoverBox",
            "pageId": "",
            "compId": "",
            "styleType": "custom",
            "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer"
        },
        "mobileStructure": {
            "type": "Container",
            "modes": {
                "definitions": [
                    {
                        "modeId": "comp-iw2dsivk-mode-iw2dsiz9",
                        "type": "DEFAULT",
                        "label": "MODE_LABEL_FROM_PRESET",
                        "params": ""
                    },
                    {
                        "modeId": "comp-iw2dsivk-mode-iw2dsiz91",
                        "type": "HOVER",
                        "label": "MODE_LABEL_FROM_PRESET",
                        "params": ""
                    }
                ]
            },
            "layout": {
                "width": 280,
                "height": 200,
                "x": 20,
                "y": 10,
                "scale": 1,
                "rotationInDegrees": 0,
                "fixedPosition": false
            },
            "componentType": "wysiwyg.viewer.components.HoverBox",
            "props": {
                "type": "HoverBoxProperties",
                "metaData": {
                    "schemaVersion": "1.0"
                },
                "mobileDisplayedModeId": "comp-iw2dsivk-mode-iw2dsiz91"
            },
            "design": {
                "type": "MediaContainerDesignData",
                "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false
                },
                "background": {
                    "type": "BackgroundMedia",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "mediaRef": {
                        "type": "Image",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "title": "Final_Image_Strip.jpg",
                        "uri": "035244_7fc6f489da854275beed101a6b9f29f2~mv2_d_1736_1200_s_2.jpg",
                        "description": "private/allMedia_picture",
                        "width": 1736,
                        "height": 1200,
                        "alt": "",
                        "artist": {
                            "id": "",
                            "name": ""
                        },
                        "opacity": 1
                    },
                    "color": "#000000",
                    "colorOpacity": 1,
                    "alignType": "center",
                    "fittingType": "fill",
                    "scrollType": "none",
                    "colorOverlay": "#000000",
                    "colorOverlayOpacity": 0.4,
                    "showOverlayForMediaType": "all",
                    "mediaTransforms": {
                        "scale": 1
                    }
                },
                "cssStyle": {},
                "themeMappings": {},
                "charas": "design-iw3mse9o",
                "dataChangeBehaviors": [
                    {
                        "trigger": "in",
                        "type": "animation",
                        "part": "overlay",
                        "name": "FadeIn",
                        "params": {
                            "delay": 0,
                            "duration": 0.5
                        }
                    }
                ]
            },
            "style": {
                "type": "TopLevelStyle",
                "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false
                },
                "style": {
                    "properties": {
                        "alpha-bg": "1",
                        "alpha-brd": "1",
                        "bg": "206,224,193,1",
                        "boxShadowToggleOn-shd": "false",
                        "brd": "#324158",
                        "brw": "0px",
                        "rd": "0px",
                        "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                    },
                    "propertiesSource": {
                        "bg": "value",
                        "brd": "value",
                        "brw": "value",
                        "rd": "value",
                        "shd": "value"
                    },
                    "groups": {}
                },
                "componentClassName": "wysiwyg.viewer.components.HoverBox",
                "pageId": "",
                "compId": "",
                "styleType": "custom",
                "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer"
            }
        }
    },
    "preset": {
        "rect": {
            "width": 324,
            "height": 217,
            "x": -1,
            "y": 1447
        }
    }
}
const hbPreset13 = {
    "id": "hoverBoxPreset13",
    "structure": {
        "type": "Container",
        "components": [
            {
                "type": "Component",
                "modes": {
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwas49om-mode-iwas49pl1"
                            ],
                            "layout": {
                                "width": 1,
                                "height": 308,
                                "x": 580,
                                "y": 25,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "isHiddenByModes": false,
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "style": {
                                    "properties": {
                                        "alpha-brd": "1",
                                        "brd": "#FFFFFF",
                                        "lnw": "1px"
                                    },
                                    "propertiesSource": {
                                        "brd": "value",
                                        "lnw": "value"
                                    },
                                    "groups": {}
                                },
                                "componentClassName": "wysiwyg.viewer.components.VerticalLine",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.line.VerticalSolidLine"
                            }
                        }
                    ],
                    "isHiddenByModes": true
                },
                "skin": "wysiwyg.viewer.skins.line.VerticalSolidLine",
                "layout": {
                    "width": 1,
                    "height": 308,
                    "x": 555,
                    "y": 1,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.VerticalLine",
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "style": {
                        "properties": {
                            "alpha-brd": "1",
                            "brd": "#FFFFFF",
                            "lnw": "1px"
                        },
                        "propertiesSource": {
                            "brd": "value",
                            "lnw": "value"
                        },
                        "groups": {}
                    },
                    "componentClassName": "wysiwyg.viewer.components.VerticalLine",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.line.VerticalSolidLine"
                },
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"name\":\"ExpandIn\",\"delay\":0,\"duration\":0.3,\"params\":{\"direction\":\"right\",\"modeIds\":[\"comp-iwas49om-mode-iwas49pl1\"]},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"CollapseOut\",\"delay\":0,\"duration\":0.3,\"params\":{\"direction\":\"right\",\"modeIds\":[\"comp-iwas49om-mode-iwas49pl1\"]},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                }
            },
            {
                "type": "Component",
                "modes": {
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwas49om-mode-iwas49pl1"
                            ],
                            "layout": {
                                "width": 1,
                                "height": 308,
                                "x": 26,
                                "y": 25,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "isHiddenByModes": false,
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "style": {
                                    "properties": {
                                        "alpha-brd": "1",
                                        "brd": "#FFFFFF",
                                        "lnw": "1px"
                                    },
                                    "propertiesSource": {
                                        "brd": "value",
                                        "lnw": "value"
                                    },
                                    "groups": {}
                                },
                                "componentClassName": "wysiwyg.viewer.components.VerticalLine",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.line.VerticalSolidLine"
                            }
                        }
                    ],
                    "isHiddenByModes": true
                },
                "skin": "wysiwyg.viewer.skins.line.VerticalSolidLine",
                "layout": {
                    "width": 1,
                    "height": 308,
                    "x": 1,
                    "y": 1,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.VerticalLine",
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "style": {
                        "properties": {
                            "alpha-brd": "1",
                            "brd": "#FFFFFF",
                            "lnw": "1px"
                        },
                        "propertiesSource": {
                            "brd": "value",
                            "lnw": "value"
                        },
                        "groups": {}
                    },
                    "componentClassName": "wysiwyg.viewer.components.VerticalLine",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.line.VerticalSolidLine"
                },
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"name\":\"ExpandIn\",\"delay\":0,\"duration\":0.3,\"params\":{\"direction\":\"right\",\"modeIds\":[\"comp-iwas49om-mode-iwas49pl1\"]},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"CollapseOut\",\"delay\":0,\"duration\":0.3,\"params\":{\"direction\":\"right\",\"modeIds\":[\"comp-iwas49om-mode-iwas49pl1\"]},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                }
            },
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwas49om-mode-iwas49pl1"
                            ],
                            "layout": {
                                "width": 555,
                                "height": 5,
                                "x": 26,
                                "y": 332,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "isHiddenByModes": false,
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "style": {
                                    "properties": {
                                        "alpha-brd": "1",
                                        "brd": "#FFFFFF",
                                        "lnw": "1px"
                                    },
                                    "propertiesSource": {
                                        "brd": "value",
                                        "lnw": "value"
                                    },
                                    "groups": {}
                                },
                                "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.line.SolidLine"
                            },
                            "props": {
                                "type": "FiveGridLineProperties",
                                "metaData": {
                                    "schemaVersion": "1.0"
                                },
                                "fullScreenModeOn": false
                            }
                        }
                    ],
                    "isHiddenByModes": true
                },
                "skin": "wysiwyg.viewer.skins.line.SolidLine",
                "layout": {
                    "width": 555,
                    "height": 5,
                    "x": 0,
                    "y": 309,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.FiveGridLine",
                "props": {
                    "type": "FiveGridLineProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "fullScreenModeOn": false
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "style": {
                        "properties": {
                            "alpha-brd": "1",
                            "brd": "#FFFFFF",
                            "lnw": "1px"
                        },
                        "propertiesSource": {
                            "brd": "value",
                            "lnw": "value"
                        },
                        "groups": {}
                    },
                    "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.line.SolidLine"
                },
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"name\":\"ExpandIn\",\"delay\":0,\"duration\":0.3,\"params\":{\"direction\":\"right\",\"modeIds\":[\"comp-iwas49om-mode-iwas49pl1\"]},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"CollapseOut\",\"delay\":0,\"duration\":0.3,\"params\":{\"direction\":\"right\",\"modeIds\":[\"comp-iwas49om-mode-iwas49pl1\"]},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                }
            },
            {
                "type": "Component",
                "modes": {
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwas49om-mode-iwas49pl1"
                            ],
                            "layout": {
                                "width": 555,
                                "height": 5,
                                "x": 26,
                                "y": 24,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "isHiddenByModes": false,
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "style": {
                                    "properties": {
                                        "alpha-brd": "1",
                                        "brd": "#FFFFFF",
                                        "lnw": "1px"
                                    },
                                    "propertiesSource": {
                                        "brd": "value",
                                        "lnw": "value"
                                    },
                                    "groups": {}
                                },
                                "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.line.SolidLine"
                            },
                            "props": {
                                "type": "FiveGridLineProperties",
                                "metaData": {
                                    "schemaVersion": "1.0"
                                },
                                "fullScreenModeOn": false
                            }
                        }
                    ],
                    "isHiddenByModes": true
                },
                "skin": "wysiwyg.viewer.skins.line.SolidLine",
                "layout": {
                    "width": 555,
                    "height": 5,
                    "x": 0,
                    "y": 0,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.FiveGridLine",
                "props": {
                    "type": "FiveGridLineProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "fullScreenModeOn": false
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "style": {
                        "properties": {
                            "alpha-brd": "1",
                            "brd": "#FFFFFF",
                            "lnw": "1px"
                        },
                        "propertiesSource": {
                            "brd": "value",
                            "lnw": "value"
                        },
                        "groups": {}
                    },
                    "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.line.SolidLine"
                },
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"name\":\"ExpandIn\",\"delay\":0,\"duration\":0.3,\"params\":{\"direction\":\"right\",\"modeIds\":[\"comp-iwas49om-mode-iwas49pl1\"]},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"CollapseOut\",\"delay\":0,\"duration\":0.3,\"params\":{\"direction\":\"right\",\"modeIds\":[\"comp-iwas49om-mode-iwas49pl1\"]},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                }
            },
            {
                "type": "Component",
                "modes": {
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwas49om-mode-iwas49pl1"
                            ],
                            "layout": {
                                "width": 368,
                                "height": 54,
                                "x": 120,
                                "y": 125,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "isHiddenByModes": true
                        },
                        {
                            "modeIds": [
                                "comp-iwas49om-mode-iwas49pl"
                            ],
                            "layout": {
                                "width": 542,
                                "height": 54,
                                "x": 32,
                                "y": 153,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            }
                        }
                    ],
                    "isHiddenByModes": false
                },
                "layout": {
                    "width": 368,
                    "height": 56,
                    "x": 120,
                    "y": 151,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "text": "<h6 class=\"font_6\" style=\"text-align: center; font-size: 40px;\"><span style=\"color:#FFFFFF;\"><span style=\"letter-spacing:0.15em;\"><span style=\"font-size:40px;\"><span style=\"font-family:futura-lt-w01-light,sans-serif;\">IT&rsquo;S&nbsp;YOUR </span><span style=\"font-family:futura-lt-w01-book,sans-serif;\"><span style=\"font-weight:bold;\">STORY</span></span></span></span></span></h6>\n",
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": []
                },
                "props": {
                    "type": "WRichTextProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "brightness": 1,
                    "packed": true
                },
                "style": "txtNew",
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"action\":\"modeChange\",\"name\":\"ModesMotion\",\"delay\":0,\"duration\":0.8,\"params\":{\"timingFunction\":\"EaseInOut\"},\"targetId\":\"\"},{\"action\":\"modeIn\",\"name\":\"FadeIn\",\"delay\":0,\"duration\":0.35},{\"action\":\"modeOut\",\"name\":\"FadeOut\",\"delay\":0,\"duration\":0.35}]"
                },
                "mobileStructure": {
                    "type": "Component",
                    "modes": {
                        "definitions": []
                    },
                    "layout": {
                        "width": 260,
                        "height": 81,
                        "x": 10,
                        "y": 89,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.WRichText",
                    "data": {
                        "type": "StyledText",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "text": "<h6 class=\"font_6\" style=\"text-align: center; font-size: 40px;\"><span style=\"color:#FFFFFF;\"><span style=\"letter-spacing:0.15em;\"><span style=\"font-size:40px;\"><span style=\"font-family:futura-lt-w01-light,sans-serif;\">IT&rsquo;S&nbsp;YOUR </span><span style=\"font-family:futura-lt-w01-book,sans-serif;\"><span style=\"font-weight:bold;\">STORY</span></span></span></span></span></h6>\n",
                        "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                        "linkList": []
                    },
                    "props": {
                        "type": "WRichTextProperties",
                        "metaData": {
                            "schemaVersion": "1.0",
                            "autoGenerated": false
                        },
                        "brightness": 1,
                        "packed": true
                    },
                    "style": "txtNew",
                    "behaviors": {
                        "type": "ObsoleteBehaviorsList",
                        "items": "[{\"action\":\"modeChange\",\"name\":\"ModesMotion\",\"delay\":0,\"duration\":0.8,\"params\":{\"timingFunction\":\"EaseInOut\"},\"targetId\":\"\"},{\"action\":\"modeIn\",\"name\":\"FadeIn\",\"delay\":0,\"duration\":0.35},{\"action\":\"modeOut\",\"name\":\"FadeOut\",\"delay\":0,\"duration\":0.35}]"
                    }
                }
            }
        ],
        "modes": {
            "definitions": [
                {
                    "modeId": "comp-iwas49om-mode-iwas49pl",
                    "type": "DEFAULT",
                    "label": "MODE_LABEL_FROM_PRESET",
                    "params": ""
                },
                {
                    "modeId": "comp-iwas49om-mode-iwas49pl1",
                    "type": "HOVER",
                    "label": "MODE_LABEL_FROM_PRESET",
                    "params": ""
                }
            ],
            "overrides": [
                {
                    "modeIds": [
                        "comp-iwas49om-mode-iwas49pl"
                    ],
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "1.0",
                                "isHidden": false
                            },
                            "mediaRef": {
                                "type": "WixVideo",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "title": "Desert Hula Hooping",
                                "videoId": "video/11062b_52ec68fd3fdb4ec895a76b0564686fa3",
                                "qualities": [
                                    {
                                        "quality": "480p",
                                        "width": 854,
                                        "height": 480,
                                        "formats": [
                                            "mp4",
                                            "webm"
                                        ]
                                    },
                                    {
                                        "quality": "720p",
                                        "width": 1280,
                                        "height": 720,
                                        "formats": [
                                            "mp4",
                                            "webm"
                                        ]
                                    },
                                    {
                                        "quality": "1080p",
                                        "width": 1920,
                                        "height": 1080,
                                        "formats": [
                                            "mp4",
                                            "webm"
                                        ]
                                    }
                                ],
                                "posterImageRef": {
                                    "type": "Image",
                                    "metaData": {
                                        "isPreset": false,
                                        "schemaVersion": "1.0",
                                        "isHidden": false
                                    },
                                    "title": "",
                                    "uri": "11062b_52ec68fd3fdb4ec895a76b0564686fa3f000.jpg",
                                    "description": "public/8e256233-1752-4026-9341-54036e7f875e/834e0f2d-9836-40c8-a73a-2a4627a84ca2",
                                    "width": 1920,
                                    "height": 1080,
                                    "alt": ""
                                },
                                "opacity": 1,
                                "duration": 18,
                                "loop": true,
                                "autoplay": false,
                                "preload": "auto",
                                "mute": true,
                                "artist": {
                                    "id": "",
                                    "name": ""
                                }
                            },
                            "color": "#000000",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "#000000",
                            "colorOverlayOpacity": 0.2,
                            "showOverlayForMediaType": "WixVideo",
                            "mediaTransforms": {
                                "scale": 1
                            }
                        },
                        "cssStyle": {},
                        "themeMappings": {},
                        "charas": "design-iw564fcz"
                    }
                },
                {
                    "modeIds": [
                        "comp-iwas49om-mode-iwas49pl1"
                    ],
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "1.0",
                                "isHidden": false
                            },
                            "mediaRef": {
                                "type": "WixVideo",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "title": "Desert Hula Hooping",
                                "videoId": "video/11062b_52ec68fd3fdb4ec895a76b0564686fa3",
                                "qualities": [
                                    {
                                        "quality": "480p",
                                        "width": 854,
                                        "height": 480,
                                        "formats": [
                                            "mp4",
                                            "webm"
                                        ]
                                    },
                                    {
                                        "quality": "720p",
                                        "width": 1280,
                                        "height": 720,
                                        "formats": [
                                            "mp4",
                                            "webm"
                                        ]
                                    },
                                    {
                                        "quality": "1080p",
                                        "width": 1920,
                                        "height": 1080,
                                        "formats": [
                                            "mp4",
                                            "webm"
                                        ]
                                    }
                                ],
                                "posterImageRef": {
                                    "type": "Image",
                                    "metaData": {
                                        "isPreset": false,
                                        "schemaVersion": "1.0",
                                        "isHidden": false
                                    },
                                    "title": "",
                                    "uri": "11062b_52ec68fd3fdb4ec895a76b0564686fa3f000.jpg",
                                    "description": "public/8e256233-1752-4026-9341-54036e7f875e/834e0f2d-9836-40c8-a73a-2a4627a84ca2",
                                    "width": 1920,
                                    "height": 1080,
                                    "alt": ""
                                },
                                "opacity": 1,
                                "duration": 18,
                                "loop": true,
                                "autoplay": true,
                                "preload": "auto",
                                "mute": true,
                                "artist": {
                                    "id": "",
                                    "name": ""
                                }
                            },
                            "color": "#000000",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "#000000",
                            "colorOverlayOpacity": 0.2,
                            "showOverlayForMediaType": "WixVideo",
                            "mediaTransforms": {
                                "scale": 1
                            }
                        },
                        "cssStyle": {},
                        "themeMappings": {},
                        "charas": "design-iw3q33zt"
                    }
                }
            ],
            "isHiddenByModes": false
        },
        "layout": {
            "width": 608,
            "height": 360,
            "x": 0,
            "y": 0,
            "scale": 1,
            "rotationInDegrees": 0,
            "fixedPosition": false
        },
        "componentType": "wysiwyg.viewer.components.HoverBox",
        "props": {
            "type": "HoverBoxProperties",
            "metaData": {
                "schemaVersion": "1.0"
            },
            "mobileDisplayedModeId": "comp-iwas49om-mode-iwas49pl"
        },
        "design": {
            "type": "MediaContainerDesignData",
            "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false
            },
            "background": {
                "type": "BackgroundMedia",
                "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false
                },
                "mediaRef": {
                    "type": "WixVideo",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "title": "Desert Hula Hooping",
                    "videoId": "video/11062b_52ec68fd3fdb4ec895a76b0564686fa3",
                    "qualities": [
                        {
                            "quality": "480p",
                            "width": 854,
                            "height": 480,
                            "formats": [
                                "mp4",
                                "webm"
                            ]
                        },
                        {
                            "quality": "720p",
                            "width": 1280,
                            "height": 720,
                            "formats": [
                                "mp4",
                                "webm"
                            ]
                        },
                        {
                            "quality": "1080p",
                            "width": 1920,
                            "height": 1080,
                            "formats": [
                                "mp4",
                                "webm"
                            ]
                        }
                    ],
                    "posterImageRef": {
                        "type": "Image",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "title": "",
                        "uri": "11062b_52ec68fd3fdb4ec895a76b0564686fa3f000.jpg",
                        "description": "public/8e256233-1752-4026-9341-54036e7f875e/834e0f2d-9836-40c8-a73a-2a4627a84ca2",
                        "width": 1920,
                        "height": 1080,
                        "alt": ""
                    },
                    "opacity": 1,
                    "duration": 18,
                    "loop": true,
                    "autoplay": true,
                    "preload": "auto",
                    "mute": true,
                    "artist": {
                        "id": "",
                        "name": ""
                    }
                },
                "color": "#000000",
                "colorOpacity": 1,
                "alignType": "center",
                "fittingType": "fill",
                "scrollType": "none",
                "colorOverlay": "",
                "colorOverlayOpacity": 0,
                "showOverlayForMediaType": "all",
                "mediaTransforms": {
                    "scale": 1
                }
            },
            "cssStyle": {},
            "themeMappings": {},
            "charas": "design-ivch5otz"
        },
        "style": {
            "type": "TopLevelStyle",
            "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false
            },
            "style": {
                "properties": {
                    "alpha-bg": "1",
                    "alpha-brd": "1",
                    "bg": "206,224,193,1",
                    "boxShadowToggleOn-shd": "false",
                    "brd": "#324158",
                    "brw": "0px",
                    "rd": "0px",
                    "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                },
                "propertiesSource": {
                    "bg": "value",
                    "brd": "value",
                    "brw": "value",
                    "rd": "value",
                    "shd": "value"
                },
                "groups": {}
            },
            "componentClassName": "wysiwyg.viewer.components.HoverBox",
            "pageId": "",
            "compId": "",
            "styleType": "custom",
            "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer"
        },
        "mobileStructure": {
            "type": "Container",
            "modes": {
                "definitions": [
                    {
                        "modeId": "comp-iwas49om-mode-iwas49pl",
                        "type": "DEFAULT",
                        "label": "MODE_LABEL_FROM_PRESET",
                        "params": ""
                    },
                    {
                        "modeId": "comp-iwas49om-mode-iwas49pl1",
                        "type": "HOVER",
                        "label": "MODE_LABEL_FROM_PRESET",
                        "params": ""
                    }
                ]
            },
            "layout": {
                "width": 280,
                "height": 250,
                "x": 20,
                "y": 10,
                "scale": 1,
                "rotationInDegrees": 0,
                "fixedPosition": false
            },
            "componentType": "wysiwyg.viewer.components.HoverBox",
            "props": {
                "type": "HoverBoxProperties",
                "metaData": {
                    "schemaVersion": "1.0"
                },
                "mobileDisplayedModeId": "comp-iwas49om-mode-iwas49pl"
            },
            "design": {
                "type": "MediaContainerDesignData",
                "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false
                },
                "background": {
                    "type": "BackgroundMedia",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "mediaRef": {
                        "type": "WixVideo",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "title": "Desert Hula Hooping",
                        "videoId": "video/11062b_52ec68fd3fdb4ec895a76b0564686fa3",
                        "qualities": [
                            {
                                "quality": "480p",
                                "width": 854,
                                "height": 480,
                                "formats": [
                                    "mp4",
                                    "webm"
                                ]
                            },
                            {
                                "quality": "720p",
                                "width": 1280,
                                "height": 720,
                                "formats": [
                                    "mp4",
                                    "webm"
                                ]
                            },
                            {
                                "quality": "1080p",
                                "width": 1920,
                                "height": 1080,
                                "formats": [
                                    "mp4",
                                    "webm"
                                ]
                            }
                        ],
                        "posterImageRef": {
                            "type": "Image",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "1.0",
                                "isHidden": false
                            },
                            "title": "",
                            "uri": "11062b_52ec68fd3fdb4ec895a76b0564686fa3f000.jpg",
                            "description": "public/8e256233-1752-4026-9341-54036e7f875e/834e0f2d-9836-40c8-a73a-2a4627a84ca2",
                            "width": 1920,
                            "height": 1080,
                            "alt": ""
                        },
                        "opacity": 1,
                        "duration": 18,
                        "loop": true,
                        "autoplay": false,
                        "preload": "auto",
                        "mute": true,
                        "artist": {
                            "id": "",
                            "name": ""
                        }
                    },
                    "color": "#000000",
                    "colorOpacity": 1,
                    "alignType": "center",
                    "fittingType": "fill",
                    "scrollType": "none",
                    "colorOverlay": "#000000",
                    "colorOverlayOpacity": 0.2,
                    "showOverlayForMediaType": "WixVideo",
                    "mediaTransforms": {
                        "scale": 1
                    }
                },
                "cssStyle": {},
                "themeMappings": {},
                "charas": "design-iw564fcz"
            },
            "style": {
                "type": "TopLevelStyle",
                "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false
                },
                "style": {
                    "properties": {
                        "alpha-bg": "1",
                        "alpha-brd": "1",
                        "bg": "206,224,193,1",
                        "boxShadowToggleOn-shd": "false",
                        "brd": "#324158",
                        "brw": "0px",
                        "rd": "0px",
                        "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                    },
                    "propertiesSource": {
                        "bg": "value",
                        "brd": "value",
                        "brw": "value",
                        "rd": "value",
                        "shd": "value"
                    },
                    "groups": {}
                },
                "componentClassName": "wysiwyg.viewer.components.HoverBox",
                "pageId": "",
                "compId": "",
                "styleType": "custom",
                "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer"
            }
        },
        "activeModes": {}
    },
    "preset": {
        "rect": {
            "width": 324,
            "height": 220,
            "x": -1,
            "y": 1664
        }
    }
}
const hbPreset14 = {
    "id": "hoverBoxPreset14",
    "structure": {
        "type": "Container",
        "components": [
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwat71au-mode-iwat71em1"
                            ],
                            "layout": {
                                "width": 520,
                                "height": 520,
                                "x": 44,
                                "y": 44,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            }
                        },
                        {
                            "modeIds": [
                                "comp-iwat71au-mode-iwat71em"
                            ],
                            "layout": {
                                "width": 500,
                                "height": 500,
                                "x": 54,
                                "y": 54,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            }
                        }
                    ],
                    "isHiddenByModes": false
                },
                "skin": "svgshape.v1.svg_162470084018985920a0c4cfb0f50d5e.Square",
                "layout": {
                    "width": 500,
                    "height": 500,
                    "x": 54,
                    "y": 54,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.svgshape.SvgShape",
                "data": {
                    "type": "SvgShape",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    }
                },
                "props": {
                    "type": "SvgShapeProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "maintainAspectRatio": false
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "style": {
                        "properties": {
                            "alpha-fillcolor": "0",
                            "alpha-stroke": "1",
                            "fillcolor": "#FFFFFF",
                            "stroke": "#FFFFFF",
                            "strokewidth": "4"
                        },
                        "propertiesSource": {
                            "alpha-fillcolor": "value",
                            "fillcolor": "value",
                            "stroke": "value",
                            "strokewidth": "value"
                        },
                        "groups": {}
                    },
                    "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "svgshape.v1.svg_162470084018985920a0c4cfb0f50d5e.Square"
                },
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"action\":\"modeChange\",\"name\":\"ModesMotion\",\"delay\":0,\"duration\":0.25,\"params\":{\"timingFunction\":\"EaseInOut\",\"modeIds\":[\"comp-iwat71au-mode-iwat71em1\"]},\"targetId\":\"\"}]"
                },
                "mobileStructure": {
                    "type": "Component",
                    "modes": {
                        "definitions": []
                    },
                    "skin": "svgshape.v1.svg_162470084018985920a0c4cfb0f50d5e.Square",
                    "layout": {
                        "width": 242,
                        "height": 242,
                        "x": 19,
                        "y": 19,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.svgshape.SvgShape",
                    "data": {
                        "type": "SvgShape",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        }
                    },
                    "props": {
                        "type": "SvgShapeProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "maintainAspectRatio": false
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "properties": {
                                "alpha-fillcolor": "0",
                                "alpha-stroke": "1",
                                "fillcolor": "#FFFFFF",
                                "stroke": "#FFFFFF",
                                "strokewidth": "4"
                            },
                            "propertiesSource": {
                                "alpha-fillcolor": "value",
                                "fillcolor": "value",
                                "stroke": "value",
                                "strokewidth": "value"
                            },
                            "groups": {}
                        },
                        "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "svgshape.v1.svg_162470084018985920a0c4cfb0f50d5e.Square"
                    },
                    "behaviors": {
                        "type": "ObsoleteBehaviorsList",
                        "items": "[{\"action\":\"modeChange\",\"name\":\"ModesMotion\",\"delay\":0,\"duration\":0.25,\"params\":{\"timingFunction\":\"EaseInOut\",\"modeIds\":[\"comp-iwat71au-mode-iwat71em1\"]},\"targetId\":\"\"}]"
                    }
                }
            },
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwat71au-mode-iwat71em"
                            ],
                            "layout": {
                                "width": 426,
                                "height": 160,
                                "x": 91,
                                "y": 258,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            }
                        }
                    ],
                    "isHiddenByModes": false
                },
                "layout": {
                    "width": 426,
                    "height": 159,
                    "x": 91,
                    "y": 258,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "text": "<h6 class=\"font_6\" style=\"font-size:45px; text-align:center\"><span style=\"font-size:45px\"><span style=\"color:#FFFFFF\"><span style=\"font-weight:bold\"><span style=\"font-family:droid-serif-w01-regular,droid-serif-w02-regular,droid-serif-w10-regular,serif\">5 Stunning&nbsp;Places Every Jetsetter Should Visit</span></span></span></span></h6>\n",
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": []
                },
                "props": {
                    "type": "WRichTextProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "brightness": 1,
                    "packed": true
                },
                "style": "txtNew",
                "mobileStructure": {
                    "type": "Component",
                    "modes": {
                        "definitions": []
                    },
                    "layout": {
                        "width": 223,
                        "height": 87,
                        "x": 29,
                        "y": 118,
                        "scale": 0.78,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.WRichText",
                    "data": {
                        "type": "StyledText",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "text": "<h6 class=\"font_6\" style=\"font-size:45px; text-align:center\"><span style=\"font-size:45px\"><span style=\"color:#FFFFFF\"><span style=\"font-weight:bold\"><span style=\"font-family:droid-serif-w01-regular,droid-serif-w02-regular,droid-serif-w10-regular,serif\">5 Stunning&nbsp;Places Every Jetsetter Should Visit</span></span></span></span></h6>\n",
                        "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                        "linkList": []
                    },
                    "props": {
                        "type": "WRichTextProperties",
                        "metaData": {
                            "schemaVersion": "1.0",
                            "autoGenerated": false
                        },
                        "brightness": 1,
                        "packed": true
                    },
                    "style": "txtNew"
                }
            },
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [],
                    "isHiddenByModes": false
                },
                "skin": "wysiwyg.viewer.skins.line.SolidLine",
                "layout": {
                    "width": 40,
                    "height": 5,
                    "x": 284,
                    "y": 231,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.FiveGridLine",
                "props": {
                    "type": "FiveGridLineProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "fullScreenModeOn": false
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "style": {
                        "properties": {
                            "alpha-brd": "1",
                            "brd": "#FFFFFF",
                            "lnw": "2"
                        },
                        "propertiesSource": {
                            "alpha-brd": "value",
                            "brd": "value",
                            "lnw": "value"
                        },
                        "groups": {}
                    },
                    "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.line.SolidLine"
                },
                "mobileStructure": {
                    "type": "Component",
                    "modes": {
                        "definitions": []
                    },
                    "skin": "wysiwyg.viewer.skins.line.SolidLine",
                    "layout": {
                        "width": 35,
                        "height": 5,
                        "x": 123,
                        "y": 98,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.FiveGridLine",
                    "props": {
                        "type": "FiveGridLineProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "fullScreenModeOn": false
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "properties": {
                                "alpha-brd": "1",
                                "brd": "#FFFFFF",
                                "lnw": "2"
                            },
                            "propertiesSource": {
                                "alpha-brd": "value",
                                "brd": "value",
                                "lnw": "value"
                            },
                            "groups": {}
                        },
                        "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.line.SolidLine"
                    }
                }
            },
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [],
                    "isHiddenByModes": false
                },
                "layout": {
                    "width": 561,
                    "height": 21,
                    "x": 23,
                    "y": 185,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "text": "<h5 class=\"font_5\" style=\"font-size:17px; text-align:center\"><span style=\"font-size:17px\"><span style=\"letter-spacing:0em\"><span style=\"font-style:italic\"><span style=\"font-family:libre baskerville,serif\"><span style=\"color:#FFFFFF\">Wanderlust</span></span></span></span></span></h5>\n",
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": []
                },
                "props": {
                    "type": "WRichTextProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "brightness": 1,
                    "packed": true
                },
                "style": "txtNew",
                "mobileStructure": {
                    "type": "Component",
                    "modes": {
                        "definitions": []
                    },
                    "layout": {
                        "width": 202,
                        "height": 19,
                        "x": 39,
                        "y": 65,
                        "scale": 0.88,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.WRichText",
                    "data": {
                        "type": "StyledText",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "text": "<h5 class=\"font_5\" style=\"font-size:17px; text-align:center\"><span style=\"font-size:17px\"><span style=\"letter-spacing:0em\"><span style=\"font-style:italic\"><span style=\"font-family:libre baskerville,serif\"><span style=\"color:#FFFFFF\">Wanderlust</span></span></span></span></span></h5>\n",
                        "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                        "linkList": []
                    },
                    "props": {
                        "type": "WRichTextProperties",
                        "metaData": {
                            "schemaVersion": "1.0",
                            "autoGenerated": false
                        },
                        "brightness": 1,
                        "packed": true
                    },
                    "style": "txtNew"
                }
            }
        ],
        "modes": {
            "definitions": [
                {
                    "modeId": "comp-iwat71au-mode-iwat71em",
                    "type": "DEFAULT",
                    "label": "MODE_LABEL_FROM_PRESET",
                    "params": ""
                },
                {
                    "modeId": "comp-iwat71au-mode-iwat71em1",
                    "type": "HOVER",
                    "label": "MODE_LABEL_FROM_PRESET",
                    "params": ""
                }
            ],
            "overrides": [
                {
                    "modeIds": [
                        "comp-iwat71au-mode-iwat71em1"
                    ],
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "1.0",
                                "isHidden": false
                            },
                            "mediaRef": {
                                "type": "Image",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "title": "Boats in the Bay",
                                "uri": "4b9ac2a8513d4218b4e7211e83b1bdbd.jpg",
                                "description": "public/491df47b-5958-4dc7-9029-37f428755d45/1f0bcd55-6ccc-49e7-a743-30179f6f3783",
                                "width": 1920,
                                "height": 1280,
                                "alt": "",
                                "artist": {
                                    "id": "mobile_designItem_ivjl5bus3",
                                    "name": ""
                                },
                                "opacity": 0.8
                            },
                            "color": "#000000",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "#000000",
                            "colorOverlayOpacity": 0.2,
                            "showOverlayForMediaType": "all",
                            "mediaTransforms": {
                                "scale": 1
                            }
                        },
                        "cssStyle": {},
                        "themeMappings": {},
                        "charas": "design-iw3mmudn",
                        "dataChangeBehaviors": [
                            {
                                "trigger": "in",
                                "type": "animation",
                                "part": "overlay",
                                "name": "FadeIn",
                                "params": {
                                    "delay": 0,
                                    "duration": 0.5
                                }
                            }
                        ]
                    }
                },
                {
                    "modeIds": [
                        "comp-iwat71au-mode-iwat71em"
                    ],
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "1.0",
                                "isHidden": false
                            },
                            "mediaRef": {
                                "type": "Image",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "title": "Boats in the Bay",
                                "uri": "4b9ac2a8513d4218b4e7211e83b1bdbd.jpg",
                                "description": "public/491df47b-5958-4dc7-9029-37f428755d45/1f0bcd55-6ccc-49e7-a743-30179f6f3783",
                                "width": 1920,
                                "height": 1280,
                                "alt": "",
                                "artist": {
                                    "id": "mobile_designItem_ivjl5gka3",
                                    "name": ""
                                },
                                "opacity": 0.8
                            },
                            "color": "#000000",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0,
                            "showOverlayForMediaType": "WixVideo",
                            "mediaTransforms": {
                                "scale": 1
                            }
                        },
                        "cssStyle": {},
                        "themeMappings": {},
                        "charas": "design-ivjl5gkj"
                    }
                }
            ],
            "isHiddenByModes": false
        },
        "layout": {
            "width": 608,
            "height": 609,
            "x": 0,
            "y": 0,
            "scale": 1,
            "rotationInDegrees": 0,
            "fixedPosition": false
        },
        "componentType": "wysiwyg.viewer.components.HoverBox",
        "props": {
            "type": "HoverBoxProperties",
            "metaData": {
                "schemaVersion": "1.0"
            },
            "mobileDisplayedModeId": "comp-iwat71au-mode-iwat71em1"
        },
        "design": {
            "type": "MediaContainerDesignData",
            "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false
            },
            "background": {
                "type": "BackgroundMedia",
                "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false
                },
                "mediaRef": {
                    "type": "Image",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "title": "Sunrise over the Wheat Field",
                    "uri": "ef3eacff722648c79da25c362882a4bb.jpg",
                    "description": "public/491df47b-5958-4dc7-9029-37f428755d45/1f0bcd55-6ccc-49e7-a743-30179f6f3783",
                    "width": 5472,
                    "height": 3078,
                    "alt": "",
                    "artist": {
                        "id": "",
                        "name": ""
                    },
                    "opacity": 0.8
                },
                "color": "#000000",
                "colorOpacity": 1,
                "alignType": "center",
                "fittingType": "fill",
                "scrollType": "none",
                "colorOverlay": "",
                "colorOverlayOpacity": 0,
                "showOverlayForMediaType": "WixVideo",
                "mediaTransforms": {
                    "scale": 1
                }
            },
            "cssStyle": {},
            "themeMappings": {},
            "charas": "design-ivjhyn8f"
        },
        "style": {
            "type": "TopLevelStyle",
            "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false
            },
            "style": {
                "properties": {
                    "alpha-bg": "1",
                    "alpha-brd": "1",
                    "bg": "206,224,193,1",
                    "boxShadowToggleOn-shd": "false",
                    "brd": "#324158",
                    "brw": "0px",
                    "rd": "0px",
                    "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                },
                "propertiesSource": {
                    "bg": "value",
                    "brd": "value",
                    "brw": "value",
                    "rd": "value",
                    "shd": "value"
                },
                "groups": {}
            },
            "componentClassName": "wysiwyg.viewer.components.HoverBox",
            "pageId": "",
            "compId": "",
            "styleType": "custom",
            "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer"
        },
        "mobileStructure": {
            "type": "Container",
            "modes": {
                "definitions": [
                    {
                        "modeId": "comp-iwat71au-mode-iwat71em",
                        "type": "DEFAULT",
                        "label": "MODE_LABEL_FROM_PRESET",
                        "params": ""
                    },
                    {
                        "modeId": "comp-iwat71au-mode-iwat71em1",
                        "type": "HOVER",
                        "label": "MODE_LABEL_FROM_PRESET",
                        "params": ""
                    }
                ]
            },
            "layout": {
                "width": 280,
                "height": 280,
                "x": 20,
                "y": 10,
                "scale": 1,
                "rotationInDegrees": 0,
                "fixedPosition": false
            },
            "componentType": "wysiwyg.viewer.components.HoverBox",
            "props": {
                "type": "HoverBoxProperties",
                "metaData": {
                    "schemaVersion": "1.0"
                },
                "mobileDisplayedModeId": "comp-iwat71au-mode-iwat71em1"
            },
            "design": {
                "type": "MediaContainerDesignData",
                "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false
                },
                "background": {
                    "type": "BackgroundMedia",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "mediaRef": {
                        "type": "Image",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "title": "Boats in the Bay",
                        "uri": "4b9ac2a8513d4218b4e7211e83b1bdbd.jpg",
                        "description": "public/491df47b-5958-4dc7-9029-37f428755d45/1f0bcd55-6ccc-49e7-a743-30179f6f3783",
                        "width": 1920,
                        "height": 1280,
                        "alt": "",
                        "artist": {
                            "id": "mobile_designItem_ivjl5bus3",
                            "name": ""
                        },
                        "opacity": 0.8
                    },
                    "color": "#000000",
                    "colorOpacity": 1,
                    "alignType": "center",
                    "fittingType": "fill",
                    "scrollType": "none",
                    "colorOverlay": "#000000",
                    "colorOverlayOpacity": 0.2,
                    "showOverlayForMediaType": "all",
                    "mediaTransforms": {
                        "scale": 1
                    }
                },
                "cssStyle": {},
                "themeMappings": {},
                "charas": "design-iw3mmudn",
                "dataChangeBehaviors": [
                    {
                        "trigger": "in",
                        "type": "animation",
                        "part": "overlay",
                        "name": "FadeIn",
                        "params": {
                            "delay": 0,
                            "duration": 0.5
                        }
                    }
                ]
            },
            "style": {
                "type": "TopLevelStyle",
                "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false
                },
                "style": {
                    "properties": {
                        "alpha-bg": "1",
                        "alpha-brd": "1",
                        "bg": "206,224,193,1",
                        "boxShadowToggleOn-shd": "false",
                        "brd": "#324158",
                        "brw": "0px",
                        "rd": "0px",
                        "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                    },
                    "propertiesSource": {
                        "bg": "value",
                        "brd": "value",
                        "brw": "value",
                        "rd": "value",
                        "shd": "value"
                    },
                    "groups": {}
                },
                "componentClassName": "wysiwyg.viewer.components.HoverBox",
                "pageId": "",
                "compId": "",
                "styleType": "custom",
                "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer"
            }
        },
        "activeModes": {}
    },
    "preset": {
        "rect": {
            "width": 324,
            "height": 343,
            "x": -1,
            "y": 1884
        }
    }
}
const hbPreset15 = {
    "id": "hoverBoxPreset15",
    "structure": {
        "type": "Container",
        "components": [
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [],
                    "isHiddenByModes": false
                },
                "skin": "svgshape.v2.Svg_06f469158f5346a3be7e4100e38370cd",
                "layout": {
                    "width": 284,
                    "height": 76,
                    "x": 0,
                    "y": 386,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.svgshape.SvgShape",
                "data": {
                    "type": "SvgShape",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    }
                },
                "props": {
                    "type": "SvgShapeProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "maintainAspectRatio": false
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "style": {
                        "properties": {
                            "alpha-fillcolor": "1",
                            "alpha-stroke": "1",
                            "fillcolor": "#FFFFFF",
                            "stroke": "#FFFFFF",
                            "strokewidth": "0px"
                        },
                        "propertiesSource": {
                            "fillcolor": "value",
                            "stroke": "value",
                            "strokewidth": "value"
                        },
                        "groups": {}
                    },
                    "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "svgshape.v2.Svg_06f469158f5346a3be7e4100e38370cd"
                },
                "mobileStructure": {
                    "type": "Component",
                    "modes": {
                        "definitions": []
                    },
                    "skin": "svgshape.v2.Svg_06f469158f5346a3be7e4100e38370cd",
                    "layout": {
                        "width": 280,
                        "height": 80,
                        "x": 0,
                        "y": 299,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.svgshape.SvgShape",
                    "data": {
                        "type": "SvgShape",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        }
                    },
                    "props": {
                        "type": "SvgShapeProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "maintainAspectRatio": false
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "properties": {
                                "alpha-fillcolor": "1",
                                "alpha-stroke": "1",
                                "fillcolor": "#FFFFFF",
                                "stroke": "#FFFFFF",
                                "strokewidth": "0px"
                            },
                            "propertiesSource": {
                                "fillcolor": "value",
                                "stroke": "value",
                                "strokewidth": "value"
                            },
                            "groups": {}
                        },
                        "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "svgshape.v2.Svg_06f469158f5346a3be7e4100e38370cd"
                    }
                }
            },
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwat7q3o-mode-iwat7q7l1"
                            ],
                            "layout": {
                                "width": 248,
                                "height": 147,
                                "x": 18,
                                "y": 291,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            }
                        }
                    ],
                    "isHiddenByModes": false
                },
                "skin": "svgshape.v2.Svg_06f469158f5346a3be7e4100e38370cd",
                "layout": {
                    "width": 248,
                    "height": 147,
                    "x": 18,
                    "y": 309,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.svgshape.SvgShape",
                "data": {
                    "type": "SvgShape",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "link": {
                        "type": "PageLink",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "pageId": "#c1dmp"
                    }
                },
                "props": {
                    "type": "SvgShapeProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "maintainAspectRatio": false
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "style": {
                        "properties": {
                            "alpha-fillcolor": "1",
                            "alpha-stroke": "1",
                            "fillcolor": "#FFFFFF",
                            "stroke": "#000000",
                            "strokewidth": "1"
                        },
                        "propertiesSource": {
                            "fillcolor": "value",
                            "stroke": "value",
                            "strokewidth": "value"
                        },
                        "groups": {}
                    },
                    "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "svgshape.v2.Svg_06f469158f5346a3be7e4100e38370cd"
                },
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"action\":\"modeChange\",\"name\":\"ModesMotion\",\"delay\":0,\"duration\":0.15,\"params\":{\"timingFunction\":\"EaseInOut\",\"modeIds\":[\"comp-iwat7q3o-mode-iwat7q7l1\"]},\"targetId\":\"\"}]"
                },
                "mobileStructure": {
                    "type": "Component",
                    "modes": {
                        "definitions": []
                    },
                    "skin": "svgshape.v2.Svg_06f469158f5346a3be7e4100e38370cd",
                    "layout": {
                        "width": 242,
                        "height": 140,
                        "x": 19,
                        "y": 232,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.svgshape.SvgShape",
                    "data": {
                        "type": "SvgShape",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "link": {
                            "type": "PageLink",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "1.0",
                                "isHidden": false
                            },
                            "pageId": "#c1dmp"
                        }
                    },
                    "props": {
                        "type": "SvgShapeProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "maintainAspectRatio": false
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "properties": {
                                "alpha-fillcolor": "1",
                                "alpha-stroke": "1",
                                "fillcolor": "#FFFFFF",
                                "stroke": "#000000",
                                "strokewidth": "1"
                            },
                            "propertiesSource": {
                                "fillcolor": "value",
                                "stroke": "value",
                                "strokewidth": "value"
                            },
                            "groups": {}
                        },
                        "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "svgshape.v2.Svg_06f469158f5346a3be7e4100e38370cd"
                    },
                    "behaviors": {
                        "type": "ObsoleteBehaviorsList",
                        "items": "[{\"action\":\"modeChange\",\"name\":\"ModesMotion\",\"delay\":0,\"duration\":0.15,\"params\":{\"timingFunction\":\"EaseInOut\",\"modeIds\":[\"comp-iwat7q3o-mode-iwat7q7l1\"]},\"targetId\":\"\"}]"
                    }
                }
            },
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwat7q3o-mode-iwat7q7l1"
                            ],
                            "layout": {
                                "width": 187,
                                "height": 18,
                                "x": 48,
                                "y": 316,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            }
                        },
                        {
                            "modeIds": [
                                "comp-iwat7q3o-mode-iwat7q7l"
                            ],
                            "layout": {
                                "width": 187,
                                "height": 17,
                                "x": 48,
                                "y": 334,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            }
                        }
                    ],
                    "isHiddenByModes": false
                },
                "layout": {
                    "width": 187,
                    "height": 18,
                    "x": 48,
                    "y": 334,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "text": "<h6 class=\"font_6\" style=\"font-size:13px; text-align:center\"><span style=\"color:#969696\"><span style=\"font-family:futura-lt-w01-book,sans-serif\"><span style=\"letter-spacing:0.4em\"><span style=\"font-size:13px\">SWEETS</span></span></span></span></h6>\n",
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": []
                },
                "props": {
                    "type": "WRichTextProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "brightness": 1,
                    "packed": true
                },
                "style": "txtNew",
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"action\":\"modeChange\",\"name\":\"ModesMotion\",\"delay\":0,\"duration\":0.15,\"params\":{\"timingFunction\":\"EaseInOut\",\"modeIds\":[\"comp-iwat7q3o-mode-iwat7q7l1\"]},\"targetId\":\"\"}]"
                },
                "mobileStructure": {
                    "type": "Component",
                    "modes": {
                        "definitions": []
                    },
                    "layout": {
                        "width": 167,
                        "height": 17,
                        "x": 56,
                        "y": 258,
                        "scale": 0.8501006036217305,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.WRichText",
                    "data": {
                        "type": "StyledText",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "text": "<h6 class=\"font_6\" style=\"font-size:13px; text-align:center\"><span style=\"color:#969696\"><span style=\"font-family:futura-lt-w01-book,sans-serif\"><span style=\"letter-spacing:0.4em\"><span style=\"font-size:13px\">SWEETS</span></span></span></span></h6>\n",
                        "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                        "linkList": []
                    },
                    "props": {
                        "type": "WRichTextProperties",
                        "metaData": {
                            "schemaVersion": "1.0",
                            "autoGenerated": false
                        },
                        "brightness": 1,
                        "packed": true
                    },
                    "style": "txtNew",
                    "behaviors": {
                        "type": "ObsoleteBehaviorsList",
                        "items": "[{\"action\":\"modeChange\",\"name\":\"ModesMotion\",\"delay\":0,\"duration\":0.15,\"params\":{\"timingFunction\":\"EaseInOut\",\"modeIds\":[\"comp-iwat7q3o-mode-iwat7q7l1\"]},\"targetId\":\"\"}]"
                    }
                }
            },
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwat7q3o-mode-iwat7q7l1"
                            ],
                            "layout": {
                                "width": 206,
                                "height": 66,
                                "x": 39,
                                "y": 343,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            }
                        },
                        {
                            "modeIds": [
                                "comp-iwat7q3o-mode-iwat7q7l"
                            ],
                            "layout": {
                                "width": 206,
                                "height": 67,
                                "x": 39,
                                "y": 362,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            }
                        }
                    ],
                    "isHiddenByModes": false
                },
                "layout": {
                    "width": 206,
                    "height": 66,
                    "x": 39,
                    "y": 361,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "text": "<h6 class=\"font_6\" style=\"font-size:25px; text-align:center\"><a dataquery=\"#textLink_iwaxzbkm\"><span style=\"font-weight:bold\"><span style=\"color:#000000\"><span style=\"letter-spacing:0em\"><span style=\"font-size:25px\"><span style=\"font-family:playfair display,serif\">5 Yummy Ways to Serve Ice Cream</span></span></span></span></span></a></h6>\n",
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": [
                        {
                            "type": "PageLink",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "1.0",
                                "isHidden": false
                            },
                            "pageId": "#c1dmp"
                        }
                    ]
                },
                "props": {
                    "type": "WRichTextProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "brightness": 1,
                    "packed": true
                },
                "style": "txtNew",
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"action\":\"modeChange\",\"name\":\"ModesMotion\",\"delay\":0,\"duration\":0.15,\"params\":{\"timingFunction\":\"EaseInOut\",\"modeIds\":[\"comp-iwat7q3o-mode-iwat7q7l1\"]},\"targetId\":\"\"}]"
                },
                "custom": {
                    "linkList": [
                        {
                            "type": "PageLink",
                            "id": "textLink_iwaxzbkm",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "1.0",
                                "isHidden": false
                            },
                            "pageId": "#c1dmp"
                        }
                    ]
                },
                "mobileStructure": {
                    "type": "Component",
                    "modes": {
                        "definitions": []
                    },
                    "layout": {
                        "width": 260,
                        "height": 66,
                        "x": 10,
                        "y": 281,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.WRichText",
                    "data": {
                        "type": "StyledText",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "text": "<h6 class=\"font_6\" style=\"font-size:25px; text-align:center\"><a dataquery=\"#textLink_iwaxzbkm\"><span style=\"font-weight:bold\"><span style=\"color:#000000\"><span style=\"letter-spacing:0em\"><span style=\"font-size:25px\"><span style=\"font-family:playfair display,serif\">5 Yummy Ways to Serve Ice Cream</span></span></span></span></span></a></h6>\n",
                        "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                        "linkList": [
                            {
                                "type": "PageLink",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "pageId": "#c1dmp"
                            }
                        ]
                    },
                    "props": {
                        "type": "WRichTextProperties",
                        "metaData": {
                            "schemaVersion": "1.0",
                            "autoGenerated": false
                        },
                        "brightness": 1,
                        "packed": true
                    },
                    "style": "txtNew",
                    "behaviors": {
                        "type": "ObsoleteBehaviorsList",
                        "items": "[{\"action\":\"modeChange\",\"name\":\"ModesMotion\",\"delay\":0,\"duration\":0.15,\"params\":{\"timingFunction\":\"EaseInOut\",\"modeIds\":[\"comp-iwat7q3o-mode-iwat7q7l1\"]},\"targetId\":\"\"}]"
                    },
                    "custom": {
                        "linkList": [
                            {
                                "type": "PageLink",
                                "id": "textLink_iwaxzbkm",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "pageId": "#c1dmp"
                            }
                        ]
                    }
                }
            },
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwat7q3o-mode-iwat7q7l1"
                            ],
                            "layout": {
                                "width": 201,
                                "height": 5,
                                "x": 40,
                                "y": 375,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "isHiddenByModes": false,
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "style": {
                                    "properties": {
                                        "alpha-brd": "1",
                                        "brd": "#FFA3A3",
                                        "lnw": "2"
                                    },
                                    "propertiesSource": {
                                        "brd": "value",
                                        "lnw": "value"
                                    },
                                    "groups": {}
                                },
                                "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.line.SolidLine"
                            },
                            "props": {
                                "type": "FiveGridLineProperties",
                                "metaData": {
                                    "schemaVersion": "1.0"
                                },
                                "fullScreenModeOn": false
                            }
                        }
                    ],
                    "isHiddenByModes": true
                },
                "skin": "wysiwyg.viewer.skins.line.SolidLine",
                "layout": {
                    "width": 170,
                    "height": 5,
                    "x": 57,
                    "y": 384,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.FiveGridLine",
                "props": {
                    "type": "FiveGridLineProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "fullScreenModeOn": false
                },
                "style": "hl3",
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"name\":\"FloatIn\",\"delay\":0,\"duration\":0.15,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iwat7q3o-mode-iwat7q7l1\"]},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"FloatOut\",\"delay\":0,\"duration\":0.15,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iwat7q3o-mode-iwat7q7l1\"]},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                },
                "mobileStructure": {
                    "type": "Component",
                    "modes": {
                        "definitions": []
                    },
                    "skin": "wysiwyg.viewer.skins.line.SolidLine",
                    "layout": {
                        "width": 198,
                        "height": 5,
                        "x": 41,
                        "y": 312,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.FiveGridLine",
                    "props": {
                        "type": "FiveGridLineProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "fullScreenModeOn": false
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "properties": {
                                "alpha-brd": "1",
                                "brd": "#FFA3A3",
                                "lnw": "2"
                            },
                            "propertiesSource": {
                                "brd": "value",
                                "lnw": "value"
                            },
                            "groups": {}
                        },
                        "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.line.SolidLine"
                    },
                    "behaviors": {
                        "type": "ObsoleteBehaviorsList",
                        "items": "[{\"name\":\"FloatIn\",\"delay\":0,\"duration\":0.15,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iwat7q3o-mode-iwat7q7l1\"]},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"FloatOut\",\"delay\":0,\"duration\":0.15,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iwat7q3o-mode-iwat7q7l1\"]},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                    }
                }
            },
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwat7q3o-mode-iwat7q7l1"
                            ],
                            "layout": {
                                "width": 187,
                                "height": 5,
                                "x": 48,
                                "y": 407,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "isHiddenByModes": false,
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "style": {
                                    "properties": {
                                        "alpha-brd": "1",
                                        "brd": "#FFA3A3",
                                        "lnw": "2"
                                    },
                                    "propertiesSource": {
                                        "brd": "value",
                                        "lnw": "value"
                                    },
                                    "groups": {}
                                },
                                "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.line.SolidLine"
                            },
                            "props": {
                                "type": "FiveGridLineProperties",
                                "metaData": {
                                    "schemaVersion": "1.0"
                                },
                                "fullScreenModeOn": false
                            }
                        }
                    ],
                    "isHiddenByModes": true
                },
                "skin": "wysiwyg.viewer.skins.line.SolidLine",
                "layout": {
                    "width": 175,
                    "height": 5,
                    "x": 54,
                    "y": 415,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.FiveGridLine",
                "props": {
                    "type": "FiveGridLineProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "fullScreenModeOn": false
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "style": {
                        "properties": {
                            "alpha-brd": "1",
                            "brd": "#FFBDBD",
                            "lnw": "2"
                        },
                        "propertiesSource": {
                            "brd": "value",
                            "lnw": "value"
                        },
                        "groups": {}
                    },
                    "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.line.SolidLine"
                },
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"name\":\"FloatIn\",\"delay\":0,\"duration\":0.15,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iwat7q3o-mode-iwat7q7l1\"]},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"FloatOut\",\"delay\":0,\"duration\":0.15,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iwat7q3o-mode-iwat7q7l1\"]},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                },
                "mobileStructure": {
                    "type": "Component",
                    "modes": {
                        "definitions": []
                    },
                    "skin": "wysiwyg.viewer.skins.line.SolidLine",
                    "layout": {
                        "width": 187,
                        "height": 5,
                        "x": 46,
                        "y": 345,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.FiveGridLine",
                    "props": {
                        "type": "FiveGridLineProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "fullScreenModeOn": false
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "properties": {
                                "alpha-brd": "1",
                                "brd": "#FFA3A3",
                                "lnw": "2"
                            },
                            "propertiesSource": {
                                "brd": "value",
                                "lnw": "value"
                            },
                            "groups": {}
                        },
                        "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.line.SolidLine"
                    },
                    "behaviors": {
                        "type": "ObsoleteBehaviorsList",
                        "items": "[{\"name\":\"FloatIn\",\"delay\":0,\"duration\":0.15,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iwat7q3o-mode-iwat7q7l1\"]},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"FloatOut\",\"delay\":0,\"duration\":0.15,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iwat7q3o-mode-iwat7q7l1\"]},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                    }
                }
            }
        ],
        "modes": {
            "definitions": [
                {
                    "modeId": "comp-iwat7q3o-mode-iwat7q7l",
                    "type": "DEFAULT",
                    "label": "MODE_LABEL_FROM_PRESET",
                    "params": ""
                },
                {
                    "modeId": "comp-iwat7q3o-mode-iwat7q7l1",
                    "type": "HOVER",
                    "label": "MODE_LABEL_FROM_PRESET",
                    "params": ""
                }
            ],
            "overrides": [
                {
                    "modeIds": [
                        "comp-iwat7q3o-mode-iwat7q7l1"
                    ],
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "1.0",
                                "isHidden": false
                            },
                            "mediaRef": {
                                "type": "Image",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "title": "Ice Cream Party ",
                                "uri": "df88a311915f4d9aad62fb50c572e819.jpg",
                                "description": "public/491df47b-5958-4dc7-9029-37f428755d45/32fe43d3-68f4-48fc-8f2d-461e1ddced53",
                                "width": 5472,
                                "height": 3648,
                                "alt": "",
                                "artist": {
                                    "id": "mobile_designItem_ivm3n29u3",
                                    "name": ""
                                },
                                "opacity": 1
                            },
                            "color": "#000000",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "#000000",
                            "colorOverlayOpacity": 0.17,
                            "showOverlayForMediaType": "all",
                            "mediaTransforms": {
                                "scale": 1
                            }
                        },
                        "cssStyle": {},
                        "themeMappings": {},
                        "charas": "design-iwaxxtts",
                        "dataChangeBehaviors": [
                            {
                                "trigger": "in",
                                "type": "animation",
                                "part": "overlay",
                                "name": "FadeIn",
                                "params": {
                                    "delay": 0,
                                    "duration": 0.5
                                }
                            }
                        ]
                    }
                },
                {
                    "modeIds": [
                        "comp-iwat7q3o-mode-iwat7q7l"
                    ],
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "1.0",
                                "isHidden": false
                            },
                            "mediaRef": {
                                "type": "Image",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "title": "Ice Cream Party ",
                                "uri": "df88a311915f4d9aad62fb50c572e819.jpg",
                                "description": "public/491df47b-5958-4dc7-9029-37f428755d45/32fe43d3-68f4-48fc-8f2d-461e1ddced53",
                                "width": 5472,
                                "height": 3648,
                                "alt": "",
                                "artist": {
                                    "id": "mobile_designItem_ivm3jw7t2",
                                    "name": ""
                                }
                            },
                            "color": "#FFFFFF",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0,
                            "showOverlayForMediaType": "all",
                            "mediaTransforms": {
                                "scale": 1
                            }
                        },
                        "cssStyle": {},
                        "themeMappings": {},
                        "charas": "design-ivm3jw8i",
                        "dataChangeBehaviors": []
                    }
                }
            ],
            "isHiddenByModes": false
        },
        "layout": {
            "width": 284,
            "height": 463,
            "x": 0,
            "y": 0,
            "scale": 1,
            "rotationInDegrees": 0,
            "fixedPosition": false
        },
        "componentType": "wysiwyg.viewer.components.HoverBox",
        "props": {
            "type": "HoverBoxProperties",
            "metaData": {
                "schemaVersion": "1.0"
            },
            "mobileDisplayedModeId": "comp-iwat7q3o-mode-iwat7q7l1"
        },
        "design": {
            "type": "MediaContainerDesignData",
            "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false
            },
            "background": {
                "type": "BackgroundMedia",
                "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false
                },
                "color": "#FFFFFF",
                "colorOpacity": 1,
                "alignType": "center",
                "fittingType": "fill",
                "scrollType": "none",
                "colorOverlay": "",
                "colorOverlayOpacity": 0,
                "showOverlayForMediaType": "all",
                "mediaTransforms": {
                    "scale": 1
                }
            },
            "cssStyle": {},
            "themeMappings": {},
            "charas": "design-ivl4sh45",
            "dataChangeBehaviors": []
        },
        "style": {
            "type": "TopLevelStyle",
            "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false
            },
            "style": {
                "properties": {
                    "alpha-bg": "1",
                    "alpha-brd": "1",
                    "bg": "206,224,193,1",
                    "boxShadowToggleOn-shd": "false",
                    "brd": "#324158",
                    "brw": "0px",
                    "rd": "0px",
                    "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                },
                "propertiesSource": {
                    "bg": "value",
                    "brd": "value",
                    "brw": "value",
                    "rd": "value",
                    "shd": "value"
                },
                "groups": {}
            },
            "componentClassName": "wysiwyg.viewer.components.HoverBox",
            "pageId": "",
            "compId": "",
            "styleType": "custom",
            "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer"
        },
        "mobileStructure": {
            "type": "Container",
            "modes": {
                "definitions": [
                    {
                        "modeId": "comp-iwat7q3o-mode-iwat7q7l",
                        "type": "DEFAULT",
                        "label": "MODE_LABEL_FROM_PRESET",
                        "params": ""
                    },
                    {
                        "modeId": "comp-iwat7q3o-mode-iwat7q7l1",
                        "type": "HOVER",
                        "label": "MODE_LABEL_FROM_PRESET",
                        "params": ""
                    }
                ]
            },
            "layout": {
                "width": 280,
                "height": 380,
                "x": 20,
                "y": 0,
                "scale": 1,
                "rotationInDegrees": 0,
                "fixedPosition": false
            },
            "componentType": "wysiwyg.viewer.components.HoverBox",
            "props": {
                "type": "HoverBoxProperties",
                "metaData": {
                    "schemaVersion": "1.0"
                },
                "mobileDisplayedModeId": "comp-iwat7q3o-mode-iwat7q7l1"
            },
            "design": {
                "type": "MediaContainerDesignData",
                "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false
                },
                "background": {
                    "type": "BackgroundMedia",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "mediaRef": {
                        "type": "Image",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "title": "Ice Cream Party ",
                        "uri": "df88a311915f4d9aad62fb50c572e819.jpg",
                        "description": "public/491df47b-5958-4dc7-9029-37f428755d45/32fe43d3-68f4-48fc-8f2d-461e1ddced53",
                        "width": 5472,
                        "height": 3648,
                        "alt": "",
                        "artist": {
                            "id": "mobile_designItem_ivm3n29u3",
                            "name": ""
                        },
                        "opacity": 1
                    },
                    "color": "#000000",
                    "colorOpacity": 1,
                    "alignType": "center",
                    "fittingType": "fill",
                    "scrollType": "none",
                    "colorOverlay": "#000000",
                    "colorOverlayOpacity": 0.17,
                    "showOverlayForMediaType": "all",
                    "mediaTransforms": {
                        "scale": 1
                    }
                },
                "cssStyle": {},
                "themeMappings": {},
                "charas": "design-iwaxxtts",
                "dataChangeBehaviors": [
                    {
                        "trigger": "in",
                        "type": "animation",
                        "part": "overlay",
                        "name": "FadeIn",
                        "params": {
                            "delay": 0,
                            "duration": 0.5
                        }
                    }
                ]
            },
            "style": {
                "type": "TopLevelStyle",
                "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false
                },
                "style": {
                    "properties": {
                        "alpha-bg": "1",
                        "alpha-brd": "1",
                        "bg": "206,224,193,1",
                        "boxShadowToggleOn-shd": "false",
                        "brd": "#324158",
                        "brw": "0px",
                        "rd": "0px",
                        "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                    },
                    "propertiesSource": {
                        "bg": "value",
                        "brd": "value",
                        "brw": "value",
                        "rd": "value",
                        "shd": "value"
                    },
                    "groups": {}
                },
                "componentClassName": "wysiwyg.viewer.components.HoverBox",
                "pageId": "",
                "compId": "",
                "styleType": "custom",
                "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer"
            }
        },
        "activeModes": {}
    },
    "preset": {
        "rect": {
            "width": 162,
            "height": 268,
            "x": -1,
            "y": 2227
        }
    }
}
const hbPreset16 = {
    "id": "hoverBoxPreset16",
    "structure": {
        "type": "Container",
        "components": [
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwbx910f-mode-iwbx9145"
                            ],
                            "layout": {
                                "width": 286,
                                "height": 76,
                                "x": 0,
                                "y": 385,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            }
                        }
                    ],
                    "isHiddenByModes": false
                },
                "skin": "svgshape.v2.Svg_06f469158f5346a3be7e4100e38370cd",
                "layout": {
                    "width": 285,
                    "height": 76,
                    "x": 0,
                    "y": 384,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.svgshape.SvgShape",
                "data": {
                    "type": "SvgShape",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    }
                },
                "props": {
                    "type": "SvgShapeProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "maintainAspectRatio": false
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "style": {
                        "properties": {
                            "alpha-fillcolor": "1",
                            "alpha-stroke": "1",
                            "fillcolor": "#FFFFFF",
                            "stroke": "#FFFFFF",
                            "strokewidth": "0"
                        },
                        "propertiesSource": {
                            "fillcolor": "value",
                            "stroke": "value",
                            "strokewidth": "value"
                        },
                        "groups": {}
                    },
                    "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "svgshape.v2.Svg_06f469158f5346a3be7e4100e38370cd"
                },
                "mobileStructure": {
                    "type": "Component",
                    "modes": {
                        "definitions": []
                    },
                    "skin": "svgshape.v2.Svg_06f469158f5346a3be7e4100e38370cd",
                    "layout": {
                        "width": 280,
                        "height": 80,
                        "x": 0,
                        "y": 300,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.svgshape.SvgShape",
                    "data": {
                        "type": "SvgShape",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        }
                    },
                    "props": {
                        "type": "SvgShapeProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "maintainAspectRatio": false
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "properties": {
                                "alpha-fillcolor": "1",
                                "alpha-stroke": "1",
                                "fillcolor": "#FFFFFF",
                                "stroke": "#FFFFFF",
                                "strokewidth": "0"
                            },
                            "propertiesSource": {
                                "fillcolor": "value",
                                "stroke": "value",
                                "strokewidth": "value"
                            },
                            "groups": {}
                        },
                        "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "svgshape.v2.Svg_06f469158f5346a3be7e4100e38370cd"
                    }
                }
            },
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [],
                    "isHiddenByModes": false
                },
                "skin": "svgshape.v2.Svg_06f469158f5346a3be7e4100e38370cd",
                "layout": {
                    "width": 248,
                    "height": 143,
                    "x": 18,
                    "y": 313,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.svgshape.SvgShape",
                "data": {
                    "type": "SvgShape",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "link": {
                        "type": "PageLink",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "pageId": "#c1dmp"
                    }
                },
                "props": {
                    "type": "SvgShapeProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "maintainAspectRatio": false
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "style": {
                        "properties": {
                            "alpha-fillcolor": "1",
                            "alpha-stroke": "1",
                            "fillcolor": "#000000",
                            "stroke": "#FFFFFF",
                            "strokewidth": "0"
                        },
                        "propertiesSource": {
                            "fillcolor": "value",
                            "stroke": "value",
                            "strokewidth": "value"
                        },
                        "groups": {}
                    },
                    "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "svgshape.v2.Svg_06f469158f5346a3be7e4100e38370cd"
                },
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"action\":\"modeChange\",\"name\":\"ModesMotion\",\"delay\":0,\"duration\":0.2,\"params\":{\"timingFunction\":\"EaseInOut\",\"modeIds\":[\"comp-iwbx910f-mode-iwbx91451\"]},\"targetId\":\"\"}]"
                },
                "mobileStructure": {
                    "type": "Component",
                    "modes": {
                        "definitions": []
                    },
                    "skin": "svgshape.v2.Svg_06f469158f5346a3be7e4100e38370cd",
                    "layout": {
                        "width": 242,
                        "height": 140,
                        "x": 19,
                        "y": 235,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.svgshape.SvgShape",
                    "data": {
                        "type": "SvgShape",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "link": {
                            "type": "PageLink",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "1.0",
                                "isHidden": false
                            },
                            "pageId": "#c1dmp"
                        }
                    },
                    "props": {
                        "type": "SvgShapeProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "maintainAspectRatio": false
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "properties": {
                                "alpha-fillcolor": "1",
                                "alpha-stroke": "1",
                                "fillcolor": "#000000",
                                "stroke": "#FFFFFF",
                                "strokewidth": "0"
                            },
                            "propertiesSource": {
                                "fillcolor": "value",
                                "stroke": "value",
                                "strokewidth": "value"
                            },
                            "groups": {}
                        },
                        "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "svgshape.v2.Svg_06f469158f5346a3be7e4100e38370cd"
                    },
                    "behaviors": {
                        "type": "ObsoleteBehaviorsList",
                        "items": "[{\"action\":\"modeChange\",\"name\":\"ModesMotion\",\"delay\":0,\"duration\":0.2,\"params\":{\"timingFunction\":\"EaseInOut\",\"modeIds\":[\"comp-iwbx910f-mode-iwbx91451\"]},\"targetId\":\"\"}]"
                    }
                }
            },
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwbx910f-mode-iwbx91451"
                            ],
                            "layout": {
                                "width": 187,
                                "height": 17,
                                "x": 48,
                                "y": 336,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            }
                        },
                        {
                            "modeIds": [
                                "comp-iwbx910f-mode-iwbx9145"
                            ],
                            "layout": {
                                "width": 187,
                                "height": 18,
                                "x": 48,
                                "y": 356,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            }
                        }
                    ],
                    "isHiddenByModes": false
                },
                "layout": {
                    "width": 187,
                    "height": 17,
                    "x": 48,
                    "y": 364,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "text": "<h6 class=\"font_6\" style=\"font-size:13px; text-align:center\"><span style=\"font-family:futura-lt-w01-light,sans-serif\"><span style=\"color:#FFFFFF\"><span style=\"letter-spacing:0.4em\"><span style=\"font-size:13px\">TRAVEL</span></span></span></span></h6>\n",
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": []
                },
                "props": {
                    "type": "WRichTextProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "brightness": 1,
                    "packed": true
                },
                "style": "txtNew",
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"action\":\"modeChange\",\"name\":\"ModesMotion\",\"delay\":0,\"duration\":0.2,\"params\":{\"timingFunction\":\"EaseInOut\",\"modeIds\":[\"comp-iwbx910f-mode-iwbx91451\"]},\"targetId\":\"\"}]"
                },
                "mobileStructure": {
                    "type": "Component",
                    "modes": {
                        "definitions": []
                    },
                    "layout": {
                        "width": 199,
                        "height": 16,
                        "x": 40,
                        "y": 261,
                        "scale": 0.8471177944862155,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.WRichText",
                    "data": {
                        "type": "StyledText",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "text": "<h6 class=\"font_6\" style=\"font-size:13px; text-align:center\"><span style=\"font-family:futura-lt-w01-light,sans-serif\"><span style=\"color:#FFFFFF\"><span style=\"letter-spacing:0.4em\"><span style=\"font-size:13px\">TRAVEL</span></span></span></span></h6>\n",
                        "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                        "linkList": []
                    },
                    "props": {
                        "type": "WRichTextProperties",
                        "metaData": {
                            "schemaVersion": "1.0",
                            "autoGenerated": false
                        },
                        "brightness": 1,
                        "packed": true
                    },
                    "style": "txtNew",
                    "behaviors": {
                        "type": "ObsoleteBehaviorsList",
                        "items": "[{\"action\":\"modeChange\",\"name\":\"ModesMotion\",\"delay\":0,\"duration\":0.2,\"params\":{\"timingFunction\":\"EaseInOut\",\"modeIds\":[\"comp-iwbx910f-mode-iwbx91451\"]},\"targetId\":\"\"}]"
                    }
                }
            },
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwbx910f-mode-iwbx91451"
                            ],
                            "layout": {
                                "width": 206,
                                "height": 33,
                                "x": 39,
                                "y": 362,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            }
                        },
                        {
                            "modeIds": [
                                "comp-iwbx910f-mode-iwbx9145"
                            ],
                            "layout": {
                                "width": 206,
                                "height": 33,
                                "x": 39,
                                "y": 382,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            }
                        }
                    ],
                    "isHiddenByModes": false
                },
                "layout": {
                    "width": 206,
                    "height": 33,
                    "x": 39,
                    "y": 389,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "text": "<h6 class=\"font_6\" style=\"font-size:25px; text-align:center\"><span style=\"letter-spacing:0.02em\"><span style=\"color:#FFFFFF\"><span style=\"font-size:25px\"><span style=\"font-family:playfair display,serif\">Gilligan&#39;s Island</span></span></span></span></h6>\n",
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": []
                },
                "props": {
                    "type": "WRichTextProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "brightness": 1,
                    "packed": true
                },
                "style": "txtNew",
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"action\":\"modeChange\",\"name\":\"ModesMotion\",\"delay\":0,\"duration\":0.2,\"params\":{\"timingFunction\":\"EaseInOut\",\"modeIds\":[\"comp-iwbx910f-mode-iwbx91451\"]},\"targetId\":\"\"}]"
                },
                "mobileStructure": {
                    "type": "Component",
                    "modes": {
                        "definitions": []
                    },
                    "layout": {
                        "width": 218,
                        "height": 31,
                        "x": 31,
                        "y": 287,
                        "scale": 0.9122807017543859,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.WRichText",
                    "data": {
                        "type": "StyledText",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "text": "<h6 class=\"font_6\" style=\"font-size:25px; text-align:center\"><span style=\"letter-spacing:0.02em\"><span style=\"color:#FFFFFF\"><span style=\"font-size:25px\"><span style=\"font-family:playfair display,serif\">Gilligan&#39;s Island</span></span></span></span></h6>\n",
                        "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                        "linkList": []
                    },
                    "props": {
                        "type": "WRichTextProperties",
                        "metaData": {
                            "schemaVersion": "1.0",
                            "autoGenerated": false
                        },
                        "brightness": 1,
                        "packed": true
                    },
                    "style": "txtNew",
                    "behaviors": {
                        "type": "ObsoleteBehaviorsList",
                        "items": "[{\"action\":\"modeChange\",\"name\":\"ModesMotion\",\"delay\":0,\"duration\":0.2,\"params\":{\"timingFunction\":\"EaseInOut\",\"modeIds\":[\"comp-iwbx910f-mode-iwbx91451\"]},\"targetId\":\"\"}]"
                    }
                }
            },
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwbx910f-mode-iwbx91451"
                            ],
                            "layout": {
                                "width": 206,
                                "height": 33,
                                "x": 39,
                                "y": 400,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "isHiddenByModes": false
                        }
                    ],
                    "isHiddenByModes": true
                },
                "layout": {
                    "width": 206,
                    "height": 66,
                    "x": 29,
                    "y": 384,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "text": "<h6 class=\"font_6\" style=\"font-size:25px; text-align:center\"><a dataquery=\"#textLink_iwaxyirf\"><span style=\"letter-spacing:0.02em\"><span style=\"color:#FFFFFF\"><span style=\"font-weight:bold\"><span style=\"font-size:25px\"><span style=\"font-family:playfair display,serif\">Book Now!</span></span></span></span></span></a></h6>\n",
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": [
                        {
                            "type": "PageLink",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "1.0",
                                "isHidden": false
                            },
                            "pageId": "#c1dmp"
                        }
                    ]
                },
                "props": {
                    "type": "WRichTextProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "brightness": 1,
                    "packed": true
                },
                "style": "txtNew",
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"name\":\"FloatIn\",\"delay\":0,\"duration\":0.2,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iwbx910f-mode-iwbx91451\"]},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"FloatOut\",\"delay\":0,\"duration\":0.2,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iwbx910f-mode-iwbx91451\"]},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                },
                "custom": {
                    "linkList": [
                        {
                            "type": "PageLink",
                            "id": "dataItem-iwbx915a2",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "1.0",
                                "isHidden": false
                            },
                            "pageId": "#c1dmp"
                        }
                    ]
                },
                "mobileStructure": {
                    "type": "Component",
                    "modes": {
                        "definitions": []
                    },
                    "layout": {
                        "width": 242,
                        "height": 31,
                        "x": 19,
                        "y": 320,
                        "scale": 0.9122807017543859,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.WRichText",
                    "data": {
                        "type": "StyledText",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "text": "<h6 class=\"font_6\" style=\"font-size:25px; text-align:center\"><a dataquery=\"#textLink_iwaxyirf\"><span style=\"letter-spacing:0.02em\"><span style=\"color:#FFFFFF\"><span style=\"font-weight:bold\"><span style=\"font-size:25px\"><span style=\"font-family:playfair display,serif\">Book Now!</span></span></span></span></span></a></h6>\n",
                        "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                        "linkList": [
                            {
                                "type": "PageLink",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "pageId": "#c1dmp"
                            }
                        ]
                    },
                    "props": {
                        "type": "WRichTextProperties",
                        "metaData": {
                            "schemaVersion": "1.0",
                            "autoGenerated": false
                        },
                        "brightness": 1,
                        "packed": true
                    },
                    "style": "txtNew",
                    "behaviors": {
                        "type": "ObsoleteBehaviorsList",
                        "items": "[{\"name\":\"FloatIn\",\"delay\":0,\"duration\":0.2,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iwbx910f-mode-iwbx91451\"]},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"FloatOut\",\"delay\":0,\"duration\":0.2,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iwbx910f-mode-iwbx91451\"]},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                    },
                    "custom": {
                        "linkList": [
                            {
                                "type": "PageLink",
                                "id": "dataItem-iwbx915a2",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "pageId": "#c1dmp"
                            }
                        ]
                    }
                }
            }
        ],
        "modes": {
            "definitions": [
                {
                    "modeId": "comp-iwbx910f-mode-iwbx9145",
                    "type": "DEFAULT",
                    "label": "MODE_LABEL_FROM_PRESET",
                    "params": ""
                },
                {
                    "modeId": "comp-iwbx910f-mode-iwbx91451",
                    "type": "HOVER",
                    "label": "MODE_LABEL_FROM_PRESET",
                    "params": ""
                }
            ],
            "overrides": [],
            "isHiddenByModes": false
        },
        "layout": {
            "width": 285,
            "height": 461,
            "x": 0,
            "y": 0,
            "scale": 1,
            "rotationInDegrees": 0,
            "fixedPosition": false
        },
        "componentType": "wysiwyg.viewer.components.HoverBox",
        "props": {
            "type": "HoverBoxProperties",
            "metaData": {
                "schemaVersion": "1.0"
            },
            "mobileDisplayedModeId": "comp-iwbx910f-mode-iwbx91451"
        },
        "design": {
            "type": "MediaContainerDesignData",
            "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false
            },
            "background": {
                "type": "BackgroundMedia",
                "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false
                },
                "mediaRef": {
                    "type": "Image",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "title": "Palm Trees",
                    "uri": "3cd1de924697419088c1e033bb3384ef.jpg",
                    "description": "public/491df47b-5958-4dc7-9029-37f428755d45/c2a37dfc-36cf-4bfd-b940-55bac7bdc9e6",
                    "width": 1920,
                    "height": 1280,
                    "alt": "",
                    "artist": {
                        "id": "",
                        "name": ""
                    }
                },
                "color": "#FFFFFF",
                "colorOpacity": 1,
                "alignType": "center",
                "fittingType": "fill",
                "scrollType": "none",
                "colorOverlay": "",
                "colorOverlayOpacity": 0,
                "showOverlayForMediaType": "WixVideo",
                "mediaTransforms": {
                    "scale": 1
                }
            },
            "cssStyle": {},
            "themeMappings": {},
            "charas": "design-iwaxdgh7",
            "dataChangeBehaviors": []
        },
        "style": {
            "type": "TopLevelStyle",
            "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false
            },
            "style": {
                "properties": {
                    "alpha-bg": "1",
                    "alpha-brd": "1",
                    "bg": "206,224,193,1",
                    "boxShadowToggleOn-shd": "false",
                    "brd": "#324158",
                    "brw": "0px",
                    "rd": "0px",
                    "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                },
                "propertiesSource": {
                    "bg": "value",
                    "brd": "value",
                    "brw": "value",
                    "rd": "value",
                    "shd": "value"
                },
                "groups": {}
            },
            "componentClassName": "wysiwyg.viewer.components.HoverBox",
            "pageId": "",
            "compId": "",
            "styleType": "custom",
            "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer"
        },
        "mobileStructure": {
            "type": "Container",
            "modes": {
                "definitions": [
                    {
                        "modeId": "comp-iwbx910f-mode-iwbx9145",
                        "type": "DEFAULT",
                        "label": "MODE_LABEL_FROM_PRESET",
                        "params": ""
                    },
                    {
                        "modeId": "comp-iwbx910f-mode-iwbx91451",
                        "type": "HOVER",
                        "label": "MODE_LABEL_FROM_PRESET",
                        "params": ""
                    }
                ]
            },
            "layout": {
                "width": 280,
                "height": 380,
                "x": 20,
                "y": 0,
                "scale": 1,
                "rotationInDegrees": 0,
                "fixedPosition": false
            },
            "componentType": "wysiwyg.viewer.components.HoverBox",
            "props": {
                "type": "HoverBoxProperties",
                "metaData": {
                    "schemaVersion": "1.0"
                },
                "mobileDisplayedModeId": "comp-iwbx910f-mode-iwbx91451"
            },
            "design": {
                "type": "MediaContainerDesignData",
                "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false
                },
                "background": {
                    "type": "BackgroundMedia",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "mediaRef": {
                        "type": "Image",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "title": "Palm Trees",
                        "uri": "3cd1de924697419088c1e033bb3384ef.jpg",
                        "description": "public/491df47b-5958-4dc7-9029-37f428755d45/c2a37dfc-36cf-4bfd-b940-55bac7bdc9e6",
                        "width": 1920,
                        "height": 1280,
                        "alt": "",
                        "artist": {
                            "id": "",
                            "name": ""
                        }
                    },
                    "color": "#FFFFFF",
                    "colorOpacity": 1,
                    "alignType": "center",
                    "fittingType": "fill",
                    "scrollType": "none",
                    "colorOverlay": "",
                    "colorOverlayOpacity": 0,
                    "showOverlayForMediaType": "WixVideo",
                    "mediaTransforms": {
                        "scale": 1
                    }
                },
                "cssStyle": {},
                "themeMappings": {},
                "charas": "design-iwaxdgh7",
                "dataChangeBehaviors": []
            },
            "style": {
                "type": "TopLevelStyle",
                "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false
                },
                "style": {
                    "properties": {
                        "alpha-bg": "1",
                        "alpha-brd": "1",
                        "bg": "206,224,193,1",
                        "boxShadowToggleOn-shd": "false",
                        "brd": "#324158",
                        "brw": "0px",
                        "rd": "0px",
                        "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                    },
                    "propertiesSource": {
                        "bg": "value",
                        "brd": "value",
                        "brw": "value",
                        "rd": "value",
                        "shd": "value"
                    },
                    "groups": {}
                },
                "componentClassName": "wysiwyg.viewer.components.HoverBox",
                "pageId": "",
                "compId": "",
                "styleType": "custom",
                "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer"
            }
        },
        "activeModes": {}
    },
    "preset": {
        "rect": {
            "width": 162,
            "height": 268,
            "x": 160,
            "y": 2227
        }
    }
}
const hbPreset17 = {
    "id": "hoverBoxPreset17",
    "structure": {
        "type": "Container",
        "components": [
            {
                "type": "Component",
                "modes": {
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwas6dfv-mode-iwas6dh21"
                            ],
                            "layout": {
                                "width": 453,
                                "height": 50,
                                "x": 76,
                                "y": 110,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            }
                        },
                        {
                            "modeIds": [
                                "comp-iwas6dfv-mode-iwas6dh2"
                            ],
                            "layout": {
                                "width": 453,
                                "height": 49,
                                "x": 77,
                                "y": 155,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            }
                        }
                    ],
                    "isHiddenByModes": false
                },
                "layout": {
                    "width": 453,
                    "height": 50,
                    "x": 77,
                    "y": 155,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "text": "<h5 class=\"font_5\" style=\"font-size:40px; text-align:center\"><span style=\"color:#FFFFFF\"><span style=\"font-size:40px\"><span style=\"letter-spacing:0em\"><span style=\"font-family:didot-w01-italic,serif\">MY BIG EVENT</span></span></span></span></h5>\n",
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": []
                },
                "props": {
                    "type": "WRichTextProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "brightness": 1,
                    "packed": true
                },
                "style": "txtNew",
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"action\":\"modeIn\",\"name\":\"FadeIn\",\"delay\":0,\"duration\":0.35},{\"action\":\"modeOut\",\"name\":\"FadeOut\",\"delay\":0,\"duration\":0.35},{\"action\":\"modeChange\",\"name\":\"ModesMotion\",\"delay\":0,\"duration\":0.5,\"params\":{\"timingFunction\":\"EaseInOut\",\"modeIds\":[\"comp-iwas6dfv-mode-iwas6dh21\"]},\"targetId\":\"\"}]"
                },
                "mobileStructure": {
                    "type": "Component",
                    "modes": {
                        "definitions": []
                    },
                    "layout": {
                        "width": 234,
                        "height": 32,
                        "x": 23,
                        "y": 142,
                        "scale": 0.85,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.WRichText",
                    "data": {
                        "type": "StyledText",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "text": "<h5 class=\"font_5\" style=\"font-size:40px; text-align:center\"><span style=\"color:#FFFFFF\"><span style=\"font-size:40px\"><span style=\"letter-spacing:0em\"><span style=\"font-family:didot-w01-italic,serif\">MY BIG EVENT</span></span></span></span></h5>\n",
                        "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                        "linkList": []
                    },
                    "props": {
                        "type": "WRichTextProperties",
                        "metaData": {
                            "schemaVersion": "1.0",
                            "autoGenerated": false
                        },
                        "brightness": 1,
                        "packed": true
                    },
                    "style": "txtNew",
                    "behaviors": {
                        "type": "ObsoleteBehaviorsList",
                        "items": "[{\"action\":\"modeIn\",\"name\":\"FadeIn\",\"delay\":0,\"duration\":0.35},{\"action\":\"modeOut\",\"name\":\"FadeOut\",\"delay\":0,\"duration\":0.35},{\"action\":\"modeChange\",\"name\":\"ModesMotion\",\"delay\":0,\"duration\":0.5,\"params\":{\"timingFunction\":\"EaseInOut\",\"modeIds\":[\"comp-iwas6dfv-mode-iwas6dh21\"]},\"targetId\":\"\"}]"
                    }
                }
            },
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwas6dfv-mode-iwas6dh21"
                            ],
                            "layout": {
                                "width": 411,
                                "height": 42,
                                "x": 98,
                                "y": 178,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "isHiddenByModes": false
                        }
                    ],
                    "isHiddenByModes": true
                },
                "layout": {
                    "width": 304,
                    "height": 40,
                    "x": 151,
                    "y": 203,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "text": "<p class=\"font_8\" style=\"font-size:16px; text-align:center\"><span style=\"color:#FFFFFF\"><span style=\"font-size:16px\"><span style=\"font-family:futura-lt-w01-light,sans-serif\">This is a great place to tell people more about your special day and get them excited to celebrate.</span></span></span></p>\n",
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": []
                },
                "props": {
                    "type": "WRichTextProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "brightness": 1,
                    "packed": true
                },
                "style": "txtNew",
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"name\":\"FloatIn\",\"delay\":0,\"duration\":0.5,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iwas6dfv-mode-iwas6dh21\"]},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"FloatOut\",\"delay\":0,\"duration\":0.5,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iwas6dfv-mode-iwas6dh21\"]},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                },
                "mobileStructure": {
                    "type": "Component",
                    "modes": {
                        "definitions": []
                    },
                    "layout": {
                        "width": 196,
                        "height": 84,
                        "x": 42,
                        "y": 186,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.WRichText",
                    "data": {
                        "type": "StyledText",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "text": "<p class=\"font_8\" style=\"font-size:16px; text-align:center\"><span style=\"color:#FFFFFF\"><span style=\"font-size:16px\"><span style=\"font-family:futura-lt-w01-light,sans-serif\">This is a great place to tell people more about your special day and get them excited to celebrate.</span></span></span></p>\n",
                        "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                        "linkList": []
                    },
                    "props": {
                        "type": "WRichTextProperties",
                        "metaData": {
                            "schemaVersion": "1.0",
                            "autoGenerated": false
                        },
                        "brightness": 1,
                        "packed": true
                    },
                    "style": "txtNew",
                    "behaviors": {
                        "type": "ObsoleteBehaviorsList",
                        "items": "[{\"name\":\"FloatIn\",\"delay\":0,\"duration\":0.5,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iwas6dfv-mode-iwas6dh21\"]},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"FloatOut\",\"delay\":0,\"duration\":0.5,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iwas6dfv-mode-iwas6dh21\"]},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                    }
                }
            },
            {
                "type": "Component",
                "modes": {
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwas6dfv-mode-iwas6dh21"
                            ],
                            "layout": {
                                "width": 1,
                                "height": 310,
                                "x": 565,
                                "y": 25,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "isHiddenByModes": false
                        }
                    ],
                    "isHiddenByModes": true
                },
                "skin": "wysiwyg.viewer.skins.line.VerticalSolidLine",
                "layout": {
                    "width": 1,
                    "height": 284,
                    "x": 552,
                    "y": 24,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.VerticalLine",
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "style": {
                        "properties": {
                            "alpha-brd": "1",
                            "brd": "#FFFFFF",
                            "lnw": "1px"
                        },
                        "propertiesSource": {
                            "brd": "value",
                            "lnw": "value"
                        },
                        "groups": {}
                    },
                    "componentClassName": "wysiwyg.viewer.components.VerticalLine",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.line.VerticalSolidLine"
                },
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"name\":\"SlideIn\",\"delay\":0,\"duration\":0.6,\"params\":{\"direction\":\"top\"},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"SlideOut\",\"delay\":0,\"duration\":0.6,\"params\":{\"direction\":\"top\"},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                },
                "mobileStructure": {
                    "type": "Component",
                    "modes": {
                        "definitions": []
                    },
                    "skin": "wysiwyg.viewer.skins.line.VerticalSolidLine",
                    "layout": {
                        "width": 1,
                        "height": 334,
                        "x": 22,
                        "y": 13,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VerticalLine",
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "properties": {
                                "alpha-brd": "1",
                                "brd": "#FFFFFF",
                                "lnw": "1px"
                            },
                            "propertiesSource": {
                                "brd": "value",
                                "lnw": "value"
                            },
                            "groups": {}
                        },
                        "componentClassName": "wysiwyg.viewer.components.VerticalLine",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.line.VerticalSolidLine"
                    },
                    "behaviors": {
                        "type": "ObsoleteBehaviorsList",
                        "items": "[{\"name\":\"SlideIn\",\"delay\":0,\"duration\":0.6,\"params\":{\"direction\":\"top\"},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"SlideOut\",\"delay\":0,\"duration\":0.6,\"params\":{\"direction\":\"top\"},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                    }
                }
            },
            {
                "type": "Component",
                "modes": {
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwas6dfv-mode-iwas6dh21"
                            ],
                            "layout": {
                                "width": 1,
                                "height": 310,
                                "x": 42,
                                "y": 25,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "isHiddenByModes": false
                        }
                    ],
                    "isHiddenByModes": true
                },
                "skin": "wysiwyg.viewer.skins.line.VerticalSolidLine",
                "layout": {
                    "width": 1,
                    "height": 315,
                    "x": 49,
                    "y": 24,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.VerticalLine",
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "style": {
                        "properties": {
                            "alpha-brd": "1",
                            "brd": "#FFFFFF",
                            "lnw": "1px"
                        },
                        "propertiesSource": {
                            "brd": "value",
                            "lnw": "value"
                        },
                        "groups": {}
                    },
                    "componentClassName": "wysiwyg.viewer.components.VerticalLine",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.line.VerticalSolidLine"
                },
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"name\":\"SlideIn\",\"delay\":0,\"duration\":0.6,\"params\":{\"direction\":\"bottom\"},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"SlideOut\",\"delay\":0,\"duration\":0.6,\"params\":{\"direction\":\"bottom\"},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                },
                "mobileStructure": {
                    "type": "Component",
                    "modes": {
                        "definitions": []
                    },
                    "skin": "wysiwyg.viewer.skins.line.VerticalSolidLine",
                    "layout": {
                        "width": 1,
                        "height": 334,
                        "x": 259,
                        "y": 13,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VerticalLine",
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "properties": {
                                "alpha-brd": "1",
                                "brd": "#FFFFFF",
                                "lnw": "1px"
                            },
                            "propertiesSource": {
                                "brd": "value",
                                "lnw": "value"
                            },
                            "groups": {}
                        },
                        "componentClassName": "wysiwyg.viewer.components.VerticalLine",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.line.VerticalSolidLine"
                    },
                    "behaviors": {
                        "type": "ObsoleteBehaviorsList",
                        "items": "[{\"name\":\"SlideIn\",\"delay\":0,\"duration\":0.6,\"params\":{\"direction\":\"bottom\"},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"SlideOut\",\"delay\":0,\"duration\":0.6,\"params\":{\"direction\":\"bottom\"},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                    }
                }
            },
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwas6dfv-mode-iwas6dh21"
                            ],
                            "layout": {
                                "width": 547,
                                "height": 5,
                                "x": 32,
                                "y": 322,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "isHiddenByModes": false
                        }
                    ],
                    "isHiddenByModes": true
                },
                "skin": "wysiwyg.viewer.skins.line.SolidLine",
                "layout": {
                    "width": 284,
                    "height": 7,
                    "x": 300,
                    "y": 316,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.FiveGridLine",
                "props": {
                    "type": "FiveGridLineProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "fullScreenModeOn": false
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "style": {
                        "properties": {
                            "alpha-brd": "1",
                            "brd": "#FFFFFF",
                            "lnw": "1px"
                        },
                        "propertiesSource": {
                            "brd": "value",
                            "lnw": "value"
                        },
                        "groups": {}
                    },
                    "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.line.SolidLine"
                },
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"name\":\"SlideIn\",\"delay\":0,\"duration\":0.6,\"params\":{\"direction\":\"right\"},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"SlideOut\",\"delay\":0,\"duration\":0.6,\"params\":{\"direction\":\"right\"},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                },
                "mobileStructure": {
                    "type": "Component",
                    "modes": {
                        "definitions": []
                    },
                    "skin": "wysiwyg.viewer.skins.line.SolidLine",
                    "layout": {
                        "width": 260,
                        "height": 5,
                        "x": 10,
                        "y": 336,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.FiveGridLine",
                    "props": {
                        "type": "FiveGridLineProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "fullScreenModeOn": false
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "properties": {
                                "alpha-brd": "1",
                                "brd": "#FFFFFF",
                                "lnw": "1px"
                            },
                            "propertiesSource": {
                                "brd": "value",
                                "lnw": "value"
                            },
                            "groups": {}
                        },
                        "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.line.SolidLine"
                    },
                    "behaviors": {
                        "type": "ObsoleteBehaviorsList",
                        "items": "[{\"name\":\"SlideIn\",\"delay\":0,\"duration\":0.6,\"params\":{\"direction\":\"right\"},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"SlideOut\",\"delay\":0,\"duration\":0.6,\"params\":{\"direction\":\"right\"},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                    }
                }
            },
            {
                "type": "Component",
                "modes": {
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwas6dfv-mode-iwas6dh21"
                            ],
                            "layout": {
                                "width": 550,
                                "height": 5,
                                "x": 29,
                                "y": 38,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "isHiddenByModes": false
                        }
                    ],
                    "isHiddenByModes": true
                },
                "skin": "wysiwyg.viewer.skins.line.SolidLine",
                "layout": {
                    "width": 550,
                    "height": 5,
                    "x": 18,
                    "y": 36,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.FiveGridLine",
                "props": {
                    "type": "FiveGridLineProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "fullScreenModeOn": false
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "style": {
                        "properties": {
                            "alpha-brd": "1",
                            "brd": "#FFFFFF",
                            "lnw": "1px"
                        },
                        "propertiesSource": {
                            "brd": "value",
                            "lnw": "value"
                        },
                        "groups": {}
                    },
                    "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.line.SolidLine"
                },
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"name\":\"SlideIn\",\"delay\":0,\"duration\":0.6,\"params\":{\"direction\":\"left\"},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"SlideOut\",\"delay\":0,\"duration\":0.6,\"params\":{\"direction\":\"left\"},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                },
                "mobileStructure": {
                    "type": "Component",
                    "skin": "wysiwyg.viewer.skins.line.SolidLine",
                    "layout": {
                        "width": 260,
                        "height": 5,
                        "x": 10,
                        "y": 22,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.FiveGridLine",
                    "props": {
                        "type": "FiveGridLineProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "fullScreenModeOn": false
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "properties": {
                                "alpha-brd": "1",
                                "brd": "#FFFFFF",
                                "lnw": "1px"
                            },
                            "propertiesSource": {
                                "brd": "value",
                                "lnw": "value"
                            },
                            "groups": {}
                        },
                        "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.line.SolidLine"
                    },
                    "behaviors": {
                        "type": "ObsoleteBehaviorsList",
                        "items": "[{\"name\":\"SlideIn\",\"delay\":0,\"duration\":0.6,\"params\":{\"direction\":\"left\"},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"SlideOut\",\"delay\":0,\"duration\":0.6,\"params\":{\"direction\":\"left\"},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                    }
                }
            },
            {
                "type": "Component",
                "modes": {
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwas6dfv-mode-iwas6dh21"
                            ],
                            "layout": {
                                "width": 142,
                                "height": 40,
                                "x": 226,
                                "y": 237,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "isHiddenByModes": false,
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "style": {
                                    "properties": {
                                        "alpha-bg": "0",
                                        "alpha-bgd": "1",
                                        "alpha-bgh": "0",
                                        "alpha-brd": "0.4",
                                        "alpha-brdd": "1",
                                        "alpha-brdh": "1",
                                        "bg": "color_11",
                                        "bgd": "#CCCCCC",
                                        "bgh": "color_11",
                                        "boxShadowToggleOn-shd": "false",
                                        "brd": "color_11",
                                        "brdd": "#CCCCCC",
                                        "brdh": "color_11",
                                        "brw": "0",
                                        "fnt": "normal normal normal 15px/1.4em didot-w01-italic",
                                        "rd": "0",
                                        "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                        "txt": "#FFFFFF",
                                        "txtd": "#FFFFFF",
                                        "txth": "color_11"
                                    },
                                    "propertiesSource": {
                                        "alpha-bg": "value",
                                        "alpha-bgd": "value",
                                        "alpha-bgh": "value",
                                        "alpha-brd": "value",
                                        "alpha-brdd": "value",
                                        "alpha-brdh": "value",
                                        "bg": "theme",
                                        "bgd": "value",
                                        "bgh": "theme",
                                        "boxShadowToggleOn-shd": "value",
                                        "brd": "theme",
                                        "brdd": "value",
                                        "brdh": "theme",
                                        "brw": "value",
                                        "fnt": "value",
                                        "rd": "value",
                                        "shd": "value",
                                        "txt": "value",
                                        "txtd": "value",
                                        "txth": "theme"
                                    },
                                    "groups": {}
                                },
                                "componentClassName": "wysiwyg.viewer.components.SiteButton",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.button.BasicButton"
                            }
                        }
                    ],
                    "isHiddenByModes": true
                },
                "skin": "wysiwyg.viewer.skins.button.BasicButton",
                "layout": {
                    "width": 142,
                    "height": 40,
                    "x": 226,
                    "y": 253,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.SiteButton",
                "data": {
                    "type": "LinkableButton",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "label": "More Info >"
                },
                "props": {
                    "type": "ButtonProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "align": "center",
                    "margin": 0
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "style": {
                        "properties": {
                            "alpha-bg": "0",
                            "alpha-bgd": "1",
                            "alpha-bgh": "0",
                            "alpha-brd": "0.4",
                            "alpha-brdd": "1",
                            "alpha-brdh": "1",
                            "bg": "color_11",
                            "bgd": "#CCCCCC",
                            "bgh": "color_11",
                            "boxShadowToggleOn-shd": "false",
                            "brd": "color_11",
                            "brdd": "#CCCCCC",
                            "brdh": "color_11",
                            "brw": "1",
                            "fnt": "normal normal normal 14px/1.4em avenir-lt-w01_35-light1475496",
                            "rd": "0",
                            "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                            "txt": "#FFFFFF",
                            "txtd": "#FFFFFF",
                            "txth": "color_11"
                        },
                        "propertiesSource": {
                            "alpha-bg": "value",
                            "alpha-bgd": "value",
                            "alpha-bgh": "value",
                            "alpha-brd": "value",
                            "alpha-brdd": "value",
                            "alpha-brdh": "value",
                            "bg": "theme",
                            "bgd": "value",
                            "bgh": "theme",
                            "boxShadowToggleOn-shd": "value",
                            "brd": "theme",
                            "brdd": "value",
                            "brdh": "theme",
                            "brw": "value",
                            "fnt": "value",
                            "rd": "value",
                            "shd": "value",
                            "txt": "value",
                            "txtd": "value",
                            "txth": "theme"
                        },
                        "groups": {}
                    },
                    "componentClassName": "wysiwyg.viewer.components.SiteButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.button.BasicButton"
                },
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"name\":\"FloatIn\",\"delay\":0,\"duration\":0.5,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iwas6dfv-mode-iwas6dh21\"]},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"FloatOut\",\"delay\":0,\"duration\":0.5,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iwas6dfv-mode-iwas6dh21\"]},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                },
                "mobileStructure": {
                    "type": "Component",
                    "modes": {
                        "definitions": []
                    },
                    "skin": "wysiwyg.viewer.skins.button.BasicButton",
                    "layout": {
                        "width": 191,
                        "height": 34,
                        "x": 44,
                        "y": 280,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.SiteButton",
                    "data": {
                        "type": "LinkableButton",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "label": "More Info >"
                    },
                    "props": {
                        "type": "ButtonProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "align": "center",
                        "margin": 0
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "properties": {
                                "alpha-bg": "0",
                                "alpha-bgd": "1",
                                "alpha-bgh": "0",
                                "alpha-brd": "0.4",
                                "alpha-brdd": "1",
                                "alpha-brdh": "1",
                                "bg": "color_11",
                                "bgd": "#CCCCCC",
                                "bgh": "color_11",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "color_11",
                                "brdd": "#CCCCCC",
                                "brdh": "color_11",
                                "brw": "0",
                                "fnt": "normal normal normal 15px/1.4em didot-w01-italic",
                                "rd": "0",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                "txt": "#FFFFFF",
                                "txtd": "#FFFFFF",
                                "txth": "color_11"
                            },
                            "propertiesSource": {
                                "alpha-bg": "value",
                                "alpha-bgd": "value",
                                "alpha-bgh": "value",
                                "alpha-brd": "value",
                                "alpha-brdd": "value",
                                "alpha-brdh": "value",
                                "bg": "theme",
                                "bgd": "value",
                                "bgh": "theme",
                                "boxShadowToggleOn-shd": "value",
                                "brd": "theme",
                                "brdd": "value",
                                "brdh": "theme",
                                "brw": "value",
                                "fnt": "value",
                                "rd": "value",
                                "shd": "value",
                                "txt": "value",
                                "txtd": "value",
                                "txth": "theme"
                            },
                            "groups": {}
                        },
                        "componentClassName": "wysiwyg.viewer.components.SiteButton",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.button.BasicButton"
                    },
                    "behaviors": {
                        "type": "ObsoleteBehaviorsList",
                        "items": "[{\"name\":\"FloatIn\",\"delay\":0,\"duration\":0.5,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iwas6dfv-mode-iwas6dh21\"]},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"FloatOut\",\"delay\":0,\"duration\":0.5,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iwas6dfv-mode-iwas6dh21\"]},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                    }
                }
            }
        ],
        "modes": {
            "definitions": [
                {
                    "modeId": "comp-iwas6dfv-mode-iwas6dh2",
                    "type": "DEFAULT",
                    "label": "MODE_LABEL_FROM_PRESET",
                    "params": ""
                },
                {
                    "modeId": "comp-iwas6dfv-mode-iwas6dh21",
                    "type": "HOVER",
                    "label": "MODE_LABEL_FROM_PRESET",
                    "params": ""
                }
            ],
            "overrides": [],
            "isHiddenByModes": false
        },
        "layout": {
            "width": 608,
            "height": 360,
            "x": 0,
            "y": 0,
            "scale": 1,
            "rotationInDegrees": 0,
            "fixedPosition": false
        },
        "componentType": "wysiwyg.viewer.components.HoverBox",
        "props": {
            "type": "HoverBoxProperties",
            "metaData": {
                "schemaVersion": "1.0"
            },
            "mobileDisplayedModeId": "comp-iwas6dfv-mode-iwas6dh21"
        },
        "design": {
            "type": "MediaContainerDesignData",
            "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false
            },
            "background": {
                "type": "BackgroundMedia",
                "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false
                },
                "mediaRef": {
                    "type": "Image",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "title": "Bridal Bouquet",
                    "uri": "5bf671afe0964c6bbac1e5ac63ce7e0e.jpg",
                    "description": "public/491df47b-5958-4dc7-9029-37f428755d45/a594d9d2-5fc4-43ad-bc05-6b6136d9e08f",
                    "width": 2600,
                    "height": 1733,
                    "alt": "",
                    "artist": {
                        "id": "",
                        "name": ""
                    },
                    "opacity": 0.8
                },
                "color": "#000000",
                "colorOpacity": 1,
                "alignType": "center",
                "fittingType": "fill",
                "scrollType": "none",
                "colorOverlay": "",
                "colorOverlayOpacity": 0,
                "showOverlayForMediaType": "all",
                "mediaTransforms": {
                    "scale": 1
                }
            },
            "cssStyle": {},
            "themeMappings": {},
            "charas": "design-ivchyzno"
        },
        "style": {
            "type": "TopLevelStyle",
            "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false
            },
            "style": {
                "properties": {
                    "alpha-bg": "1",
                    "alpha-brd": "1",
                    "bg": "206,224,193,1",
                    "boxShadowToggleOn-shd": "false",
                    "brd": "#324158",
                    "brw": "0px",
                    "rd": "0px",
                    "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                },
                "propertiesSource": {
                    "bg": "value",
                    "brd": "value",
                    "brw": "value",
                    "rd": "value",
                    "shd": "value"
                },
                "groups": {}
            },
            "componentClassName": "wysiwyg.viewer.components.HoverBox",
            "pageId": "",
            "compId": "",
            "styleType": "custom",
            "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer"
        },
        "mobileStructure": {
            "type": "Container",
            "modes": {
                "definitions": [
                    {
                        "modeId": "comp-iwas6dfv-mode-iwas6dh2",
                        "type": "DEFAULT",
                        "label": "MODE_LABEL_FROM_PRESET",
                        "params": ""
                    },
                    {
                        "modeId": "comp-iwas6dfv-mode-iwas6dh21",
                        "type": "HOVER",
                        "label": "MODE_LABEL_FROM_PRESET",
                        "params": ""
                    }
                ]
            },
            "layout": {
                "width": 280,
                "height": 360,
                "x": 20,
                "y": 10,
                "scale": 1,
                "rotationInDegrees": 0,
                "fixedPosition": false
            },
            "componentType": "wysiwyg.viewer.components.HoverBox",
            "props": {
                "type": "HoverBoxProperties",
                "metaData": {
                    "schemaVersion": "1.0"
                },
                "mobileDisplayedModeId": "comp-iwas6dfv-mode-iwas6dh21"
            },
            "design": {
                "type": "MediaContainerDesignData",
                "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false
                },
                "background": {
                    "type": "BackgroundMedia",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "mediaRef": {
                        "type": "Image",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "title": "Bridal Bouquet",
                        "uri": "5bf671afe0964c6bbac1e5ac63ce7e0e.jpg",
                        "description": "public/491df47b-5958-4dc7-9029-37f428755d45/a594d9d2-5fc4-43ad-bc05-6b6136d9e08f",
                        "width": 2600,
                        "height": 1733,
                        "alt": "",
                        "artist": {
                            "id": "",
                            "name": ""
                        },
                        "opacity": 0.8
                    },
                    "color": "#000000",
                    "colorOpacity": 1,
                    "alignType": "center",
                    "fittingType": "fill",
                    "scrollType": "none",
                    "colorOverlay": "",
                    "colorOverlayOpacity": 0,
                    "showOverlayForMediaType": "all",
                    "mediaTransforms": {
                        "scale": 1
                    }
                },
                "cssStyle": {},
                "themeMappings": {},
                "charas": "design-ivchyzno"
            },
            "style": {
                "type": "TopLevelStyle",
                "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false
                },
                "style": {
                    "properties": {
                        "alpha-bg": "1",
                        "alpha-brd": "1",
                        "bg": "206,224,193,1",
                        "boxShadowToggleOn-shd": "false",
                        "brd": "#324158",
                        "brw": "0px",
                        "rd": "0px",
                        "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                    },
                    "propertiesSource": {
                        "bg": "value",
                        "brd": "value",
                        "brw": "value",
                        "rd": "value",
                        "shd": "value"
                    },
                    "groups": {}
                },
                "componentClassName": "wysiwyg.viewer.components.HoverBox",
                "pageId": "",
                "compId": "",
                "styleType": "custom",
                "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer"
            }
        },
        "activeModes": {}
    },
    "preset": {
        "rect": {
            "width": 324,
            "height": 220,
            "x": -1,
            "y": 2495
        }
    }
}
const hbPreset18 = {
    "id": "hoverBoxPreset18",
    "structure": {
        "type": "Container",
        "components": [
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwas9rfi-mode-iwas9rjf1"
                            ],
                            "layout": {
                                "width": 2,
                                "height": 37,
                                "x": -781,
                                "y": 247,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "isHiddenByModes": false,
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "style": {
                                    "properties": {
                                        "alpha-brd": "0.8",
                                        "brd": "#FFFFFF",
                                        "lnw": "2"
                                    },
                                    "propertiesSource": {
                                        "alpha-brd": "value",
                                        "brd": "value",
                                        "lnw": "value"
                                    },
                                    "groups": {}
                                },
                                "componentClassName": "wysiwyg.viewer.components.VerticalLine",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.line.VerticalSolidLine"
                            }
                        }
                    ],
                    "isHiddenByModes": true
                },
                "skin": "wysiwyg.viewer.skins.line.VerticalSolidLine",
                "layout": {
                    "width": 3,
                    "height": 60,
                    "x": 142,
                    "y": 224,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.VerticalLine",
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "style": {
                        "properties": {
                            "alpha-brd": "1",
                            "brd": "#3D9BE9",
                            "lnw": "3px"
                        },
                        "propertiesSource": {
                            "brd": "value",
                            "lnw": "value"
                        },
                        "groups": {}
                    },
                    "componentClassName": "wysiwyg.viewer.components.VerticalLine",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.line.VerticalSolidLine"
                },
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"name\":\"Reveal\",\"delay\":0,\"duration\":0.2,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iwas9rfi-mode-iwas9rjf1\"]},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"Conceal\",\"delay\":0,\"duration\":0.2,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iwas9rfi-mode-iwas9rjf1\"]},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                },
                "mobileStructure": {
                    "type": "Component",
                    "modes": {
                        "definitions": []
                    },
                    "skin": "wysiwyg.viewer.skins.line.VerticalSolidLine",
                    "layout": {
                        "width": 2,
                        "height": 37,
                        "x": 138,
                        "y": 243,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VerticalLine",
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "properties": {
                                "alpha-brd": "0.8",
                                "brd": "#FFFFFF",
                                "lnw": "2"
                            },
                            "propertiesSource": {
                                "alpha-brd": "value",
                                "brd": "value",
                                "lnw": "value"
                            },
                            "groups": {}
                        },
                        "componentClassName": "wysiwyg.viewer.components.VerticalLine",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.line.VerticalSolidLine"
                    },
                    "behaviors": {
                        "type": "ObsoleteBehaviorsList",
                        "items": "[{\"name\":\"Reveal\",\"delay\":0,\"duration\":0.2,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iwas9rfi-mode-iwas9rjf1\"]},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"Conceal\",\"delay\":0,\"duration\":0.2,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iwas9rfi-mode-iwas9rjf1\"]},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                    }
                }
            },
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwas9rfi-mode-iwas9rjf1"
                            ],
                            "layout": {
                                "width": 12,
                                "height": 12,
                                "x": 134,
                                "y": 226,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "isHiddenByModes": false,
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "style": {
                                    "properties": {
                                        "alpha-fillcolor": "1",
                                        "alpha-stroke": "1",
                                        "fillcolor": "color_11",
                                        "stroke": "color_11",
                                        "strokewidth": "1"
                                    },
                                    "propertiesSource": {
                                        "alpha-stroke": "value",
                                        "fillcolor": "theme",
                                        "stroke": "theme",
                                        "strokewidth": "value"
                                    },
                                    "groups": {}
                                },
                                "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "svgshape.v2.Svg_0427a2c7292d424cad99c7f2a0a8c55f"
                            },
                            "props": {
                                "type": "SvgShapeProperties",
                                "metaData": {
                                    "schemaVersion": "1.0"
                                },
                                "maintainAspectRatio": true
                            }
                        }
                    ],
                    "isHiddenByModes": true
                },
                "skin": "svgshape.v2.Svg_0427a2c7292d424cad99c7f2a0a8c55f",
                "layout": {
                    "width": 11,
                    "height": 11,
                    "x": 136,
                    "y": 226,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.svgshape.SvgShape",
                "data": {
                    "type": "SvgShape",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "link": {
                        "type": "PageLink",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "pageId": "#c1dmp"
                    }
                },
                "props": {
                    "type": "SvgShapeProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "maintainAspectRatio": true
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "style": {
                        "properties": {
                            "alpha-fillcolor": "1",
                            "alpha-stroke": "1",
                            "fillcolor": "#000000",
                            "stroke": "#000000",
                            "strokewidth": "1"
                        },
                        "propertiesSource": {
                            "alpha-stroke": "value",
                            "fillcolor": "value",
                            "stroke": "value",
                            "strokewidth": "value"
                        },
                        "groups": {}
                    },
                    "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "svgshape.v2.Svg_0427a2c7292d424cad99c7f2a0a8c55f"
                },
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"name\":\"GlideIn\",\"delay\":0,\"duration\":0.2,\"params\":{\"angle\":180,\"distance\":72,\"modeIds\":[\"comp-iwas9rfi-mode-iwas9rjf1\"]},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"GlideOut\",\"delay\":0,\"duration\":0.2,\"params\":{\"angle\":180,\"distance\":72,\"modeIds\":[\"comp-iwas9rfi-mode-iwas9rjf1\"]},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                },
                "mobileStructure": {
                    "type": "Component",
                    "modes": {
                        "definitions": []
                    },
                    "skin": "svgshape.v2.Svg_0427a2c7292d424cad99c7f2a0a8c55f",
                    "layout": {
                        "width": 12,
                        "height": 12,
                        "x": 132,
                        "y": 219,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.svgshape.SvgShape",
                    "data": {
                        "type": "SvgShape",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "link": {
                            "type": "PageLink",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "1.0",
                                "isHidden": false
                            },
                            "pageId": "#c1dmp"
                        }
                    },
                    "props": {
                        "type": "SvgShapeProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "maintainAspectRatio": true
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "properties": {
                                "alpha-fillcolor": "1",
                                "alpha-stroke": "1",
                                "fillcolor": "color_11",
                                "stroke": "color_11",
                                "strokewidth": "1"
                            },
                            "propertiesSource": {
                                "alpha-stroke": "value",
                                "fillcolor": "theme",
                                "stroke": "theme",
                                "strokewidth": "value"
                            },
                            "groups": {}
                        },
                        "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "svgshape.v2.Svg_0427a2c7292d424cad99c7f2a0a8c55f"
                    },
                    "behaviors": {
                        "type": "ObsoleteBehaviorsList",
                        "items": "[{\"name\":\"GlideIn\",\"delay\":0,\"duration\":0.2,\"params\":{\"angle\":180,\"distance\":72,\"modeIds\":[\"comp-iwas9rfi-mode-iwas9rjf1\"]},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"GlideOut\",\"delay\":0,\"duration\":0.2,\"params\":{\"angle\":180,\"distance\":72,\"modeIds\":[\"comp-iwas9rfi-mode-iwas9rjf1\"]},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                    }
                }
            },
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwas9rfi-mode-iwas9rjf"
                            ],
                            "layout": {
                                "width": 1,
                                "height": 36,
                                "x": 141,
                                "y": 244,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "isHiddenByModes": true
                        },
                        {
                            "modeIds": [
                                "comp-iwas9rfi-mode-iwas9rjf1"
                            ],
                            "layout": {
                                "width": 1,
                                "height": 36,
                                "x": 140,
                                "y": 248,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            }
                        }
                    ],
                    "isHiddenByModes": false
                },
                "skin": "wysiwyg.viewer.skins.line.VerticalSolidLine",
                "layout": {
                    "width": 1,
                    "height": 36,
                    "x": 141,
                    "y": 244,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.VerticalLine",
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "style": {
                        "properties": {
                            "alpha-brd": "1",
                            "brd": "#FFFFFF",
                            "lnw": "1px"
                        },
                        "propertiesSource": {
                            "brd": "value",
                            "lnw": "value"
                        },
                        "groups": {}
                    },
                    "componentClassName": "wysiwyg.viewer.components.VerticalLine",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.line.VerticalSolidLine"
                },
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"name\":\"GlideIn\",\"delay\":0,\"duration\":0.2,\"params\":{\"angle\":180,\"distance\":72,\"modeIds\":[\"comp-iwas9rfi-mode-iwas9rjf1\"]},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"GlideOut\",\"delay\":0,\"duration\":0.2,\"params\":{\"angle\":180,\"distance\":72,\"modeIds\":[\"comp-iwas9rfi-mode-iwas9rjf1\"]},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                }
            },
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwas9rfi-mode-iwas9rjf"
                            ],
                            "layout": {
                                "width": 232,
                                "height": 24,
                                "x": 26,
                                "y": 115,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            }
                        }
                    ],
                    "isHiddenByModes": false
                },
                "layout": {
                    "width": 233,
                    "height": 24,
                    "x": 25,
                    "y": 115,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "text": "<h6 class=\"font_6\" style=\"text-align:center\"><span style=\"letter-spacing:0.15em\"><span style=\"color:#FFFFFF\">GROUP TOURS</span></span></h6>\n",
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": []
                },
                "props": {
                    "type": "WRichTextProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "brightness": 1,
                    "packed": true
                },
                "style": "txtNew",
                "mobileStructure": {
                    "type": "Component",
                    "modes": {
                        "definitions": []
                    },
                    "layout": {
                        "width": 260,
                        "height": 26,
                        "x": 10,
                        "y": 107,
                        "scale": 1.1025,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.WRichText",
                    "data": {
                        "type": "StyledText",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "text": "<h6 class=\"font_6\" style=\"text-align:center\"><span style=\"letter-spacing:0.15em\"><span style=\"color:#FFFFFF\">GROUP TOURS</span></span></h6>\n",
                        "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                        "linkList": []
                    },
                    "props": {
                        "type": "WRichTextProperties",
                        "metaData": {
                            "schemaVersion": "1.0",
                            "autoGenerated": false
                        },
                        "brightness": 1,
                        "packed": true
                    },
                    "style": "txtNew"
                }
            },
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwas9rfi-mode-iwas9rjf"
                            ],
                            "layout": {
                                "width": 201,
                                "height": 19,
                                "x": 41,
                                "y": 154,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            }
                        }
                    ],
                    "isHiddenByModes": false
                },
                "layout": {
                    "width": 201,
                    "height": 18,
                    "x": 41,
                    "y": 154,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "text": "<p class=\"font_8\" style=\"text-align: center; font-size: 16px;\"><span style=\"font-size:16px;\"><a dataquery=\"#link-iwas9rju\"><span style=\"letter-spacing:0.05em\"><span style=\"font-style:italic\"><span style=\"font-family:times new roman,times,serif\"><span style=\"color:#FFFFFF\">Book Now</span></span></span></span></a></span></p>\n",
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": [
                        {
                            "type": "PageLink",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "1.0",
                                "isHidden": false
                            },
                            "pageId": "#c1dmp"
                        }
                    ]
                },
                "props": {
                    "type": "WRichTextProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "brightness": 1,
                    "packed": true
                },
                "style": "txtNew",
                "custom": {
                    "linkList": [
                        {
                            "type": "PageLink",
                            "id": "link-iwas9rju",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "1.0",
                                "isHidden": false
                            },
                            "pageId": "#c1dmp"
                        }
                    ]
                },
                "mobileStructure": {
                    "type": "Component",
                    "modes": {
                        "definitions": []
                    },
                    "layout": {
                        "width": 260,
                        "height": 21,
                        "x": 10,
                        "y": 143,
                        "scale": 1.1025,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.WRichText",
                    "data": {
                        "type": "StyledText",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "text": "<p class=\"font_8\" style=\"text-align: center; font-size: 16px;\"><span style=\"font-size:16px;\"><a dataquery=\"#link-iwas9rju\"><span style=\"letter-spacing:0.05em\"><span style=\"font-style:italic\"><span style=\"font-family:times new roman,times,serif\"><span style=\"color:#FFFFFF\">Book Now</span></span></span></span></a></span></p>\n",
                        "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                        "linkList": [
                            {
                                "type": "PageLink",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "pageId": "#c1dmp"
                            }
                        ]
                    },
                    "props": {
                        "type": "WRichTextProperties",
                        "metaData": {
                            "schemaVersion": "1.0",
                            "autoGenerated": false
                        },
                        "brightness": 1,
                        "packed": true
                    },
                    "style": "txtNew",
                    "custom": {
                        "linkList": [
                            {
                                "type": "PageLink",
                                "id": "link-iwas9rju",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "pageId": "#c1dmp"
                            }
                        ]
                    }
                }
            }
        ],
        "modes": {
            "definitions": [
                {
                    "modeId": "comp-iwas9rfi-mode-iwas9rjf",
                    "type": "DEFAULT",
                    "label": "MODE_LABEL_FROM_PRESET",
                    "params": ""
                },
                {
                    "modeId": "comp-iwas9rfi-mode-iwas9rjf1",
                    "type": "HOVER",
                    "label": "MODE_LABEL_FROM_PRESET",
                    "params": ""
                }
            ],
            "overrides": [
                {
                    "modeIds": [
                        "comp-iwas9rfi-mode-iwas9rjf1"
                    ],
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "1.0",
                                "isHidden": false
                            },
                            "mediaRef": {
                                "type": "Image",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "title": "Hotel exterior",
                                "uri": "cfe7a6d922f04c67903627252e29daf0.jpg",
                                "description": "public/491df47b-5958-4dc7-9029-37f428755d45/c2a37dfc-36cf-4bfd-b940-55bac7bdc9e6",
                                "width": 1300,
                                "height": 905,
                                "alt": "",
                                "artist": {
                                    "id": "",
                                    "name": ""
                                },
                                "opacity": 0.7
                            },
                            "color": "#000000",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0,
                            "showOverlayForMediaType": "WixVideo",
                            "mediaTransforms": {
                                "scale": 1.1
                            }
                        },
                        "cssStyle": {
                            "cssBorder": {
                                "width": {
                                    "top": {
                                        "value": 0,
                                        "unit": "px"
                                    },
                                    "right": {
                                        "value": 0,
                                        "unit": "px"
                                    },
                                    "bottom": {
                                        "value": 0,
                                        "unit": "px"
                                    },
                                    "left": {
                                        "value": 0,
                                        "unit": "px"
                                    }
                                },
                                "style": {
                                    "top": "solid",
                                    "right": "solid",
                                    "left": "solid",
                                    "bottom": "solid"
                                },
                                "color": {
                                    "top": {
                                        "red": 176,
                                        "green": 169,
                                        "blue": 134,
                                        "alpha": 1
                                    },
                                    "right": {
                                        "red": 176,
                                        "green": 169,
                                        "blue": 134,
                                        "alpha": 1
                                    },
                                    "left": {
                                        "red": 176,
                                        "green": 169,
                                        "blue": 134,
                                        "alpha": 1
                                    },
                                    "bottom": {
                                        "red": 176,
                                        "green": 169,
                                        "blue": 134,
                                        "alpha": 1
                                    }
                                }
                            },
                            "cssBorderRadius": {
                                "topLeft": {
                                    "value": 0,
                                    "unit": "px"
                                },
                                "topRight": {
                                    "value": 0,
                                    "unit": "px"
                                },
                                "bottomLeft": {
                                    "value": 0,
                                    "unit": "px"
                                },
                                "bottomRight": {
                                    "value": 0,
                                    "unit": "px"
                                }
                            }
                        },
                        "themeMappings": {},
                        "charas": "design-iwffec4x",
                        "dataChangeBehaviors": [
                            {
                                "trigger": "in",
                                "type": "animation",
                                "part": "media",
                                "name": "Scale",
                                "params": {
                                    "delay": 0,
                                    "duration": 0.5
                                }
                            },
                            {
                                "trigger": "out",
                                "type": "animation",
                                "part": "media",
                                "name": "Scale",
                                "params": {
                                    "delay": 0,
                                    "duration": 0.5
                                }
                            }
                        ]
                    }
                }
            ],
            "isHiddenByModes": false
        },
        "layout": {
            "width": 284,
            "height": 284,
            "x": 0,
            "y": 0,
            "scale": 1,
            "rotationInDegrees": 0,
            "fixedPosition": false
        },
        "componentType": "wysiwyg.viewer.components.HoverBox",
        "props": {
            "type": "HoverBoxProperties",
            "metaData": {
                "schemaVersion": "1.0"
            },
            "mobileDisplayedModeId": "comp-iwas9rfi-mode-iwas9rjf1"
        },
        "design": {
            "type": "MediaContainerDesignData",
            "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false
            },
            "background": {
                "type": "BackgroundMedia",
                "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false
                },
                "mediaRef": {
                    "type": "Image",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "title": "Hotel exterior",
                    "uri": "cfe7a6d922f04c67903627252e29daf0.jpg",
                    "description": "public/491df47b-5958-4dc7-9029-37f428755d45/c2a37dfc-36cf-4bfd-b940-55bac7bdc9e6",
                    "width": 1300,
                    "height": 905,
                    "alt": "",
                    "artist": {
                        "id": "",
                        "name": ""
                    },
                    "opacity": 0.7
                },
                "color": "#000000",
                "colorOpacity": 1,
                "alignType": "center",
                "fittingType": "fill",
                "scrollType": "none",
                "colorOverlay": "",
                "colorOverlayOpacity": 0,
                "showOverlayForMediaType": "WixVideo",
                "mediaTransforms": {
                    "scale": 1
                }
            },
            "cssStyle": {
                "cssBorder": {
                    "width": {
                        "top": {
                            "value": 0,
                            "unit": "px"
                        },
                        "right": {
                            "value": 0,
                            "unit": "px"
                        },
                        "bottom": {
                            "value": 0,
                            "unit": "px"
                        },
                        "left": {
                            "value": 0,
                            "unit": "px"
                        }
                    },
                    "style": {
                        "top": "solid",
                        "right": "solid",
                        "left": "solid",
                        "bottom": "solid"
                    },
                    "color": {
                        "top": {
                            "red": 176,
                            "green": 169,
                            "blue": 134,
                            "alpha": 1
                        },
                        "right": {
                            "red": 176,
                            "green": 169,
                            "blue": 134,
                            "alpha": 1
                        },
                        "left": {
                            "red": 176,
                            "green": 169,
                            "blue": 134,
                            "alpha": 1
                        },
                        "bottom": {
                            "red": 176,
                            "green": 169,
                            "blue": 134,
                            "alpha": 1
                        }
                    }
                },
                "cssBorderRadius": {
                    "topLeft": {
                        "value": 0,
                        "unit": "px"
                    },
                    "topRight": {
                        "value": 0,
                        "unit": "px"
                    },
                    "bottomLeft": {
                        "value": 0,
                        "unit": "px"
                    },
                    "bottomRight": {
                        "value": 0,
                        "unit": "px"
                    }
                }
            },
            "themeMappings": {},
            "charas": "design-iw55ms30",
            "dataChangeBehaviors": []
        },
        "style": {
            "type": "TopLevelStyle",
            "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false
            },
            "style": {
                "properties": {
                    "alpha-bg": "1",
                    "alpha-brd": "1",
                    "bg": "206,224,193,1",
                    "boxShadowToggleOn-shd": "false",
                    "brd": "#324158",
                    "brw": "0px",
                    "rd": "0px",
                    "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                },
                "propertiesSource": {
                    "bg": "value",
                    "brd": "value",
                    "brw": "value",
                    "rd": "value",
                    "shd": "value"
                },
                "groups": {}
            },
            "componentClassName": "wysiwyg.viewer.components.HoverBox",
            "pageId": "",
            "compId": "",
            "styleType": "custom",
            "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer"
        },
        "mobileStructure": {
            "type": "Container",
            "modes": {
                "definitions": [
                    {
                        "modeId": "comp-iwas9rfi-mode-iwas9rjf",
                        "type": "DEFAULT",
                        "label": "MODE_LABEL_FROM_PRESET",
                        "params": ""
                    },
                    {
                        "modeId": "comp-iwas9rfi-mode-iwas9rjf1",
                        "type": "HOVER",
                        "label": "MODE_LABEL_FROM_PRESET",
                        "params": ""
                    }
                ]
            },
            "layout": {
                "width": 280,
                "height": 280,
                "x": 20,
                "y": 10,
                "scale": 1,
                "rotationInDegrees": 0,
                "fixedPosition": false
            },
            "componentType": "wysiwyg.viewer.components.HoverBox",
            "props": {
                "type": "HoverBoxProperties",
                "metaData": {
                    "schemaVersion": "1.0"
                },
                "mobileDisplayedModeId": "comp-iwas9rfi-mode-iwas9rjf1"
            },
            "design": {
                "type": "MediaContainerDesignData",
                "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false
                },
                "background": {
                    "type": "BackgroundMedia",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "mediaRef": {
                        "type": "Image",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "title": "Hotel exterior",
                        "uri": "cfe7a6d922f04c67903627252e29daf0.jpg",
                        "description": "public/491df47b-5958-4dc7-9029-37f428755d45/c2a37dfc-36cf-4bfd-b940-55bac7bdc9e6",
                        "width": 1300,
                        "height": 905,
                        "alt": "",
                        "artist": {
                            "id": "",
                            "name": ""
                        },
                        "opacity": 0.7
                    },
                    "color": "#000000",
                    "colorOpacity": 1,
                    "alignType": "center",
                    "fittingType": "fill",
                    "scrollType": "none",
                    "colorOverlay": "",
                    "colorOverlayOpacity": 0,
                    "showOverlayForMediaType": "WixVideo",
                    "mediaTransforms": {
                        "scale": 1.1
                    }
                },
                "cssStyle": {
                    "cssBorder": {
                        "width": {
                            "top": {
                                "value": 0,
                                "unit": "px"
                            },
                            "right": {
                                "value": 0,
                                "unit": "px"
                            },
                            "bottom": {
                                "value": 0,
                                "unit": "px"
                            },
                            "left": {
                                "value": 0,
                                "unit": "px"
                            }
                        },
                        "style": {
                            "top": "solid",
                            "right": "solid",
                            "left": "solid",
                            "bottom": "solid"
                        },
                        "color": {
                            "top": {
                                "red": 176,
                                "green": 169,
                                "blue": 134,
                                "alpha": 1
                            },
                            "right": {
                                "red": 176,
                                "green": 169,
                                "blue": 134,
                                "alpha": 1
                            },
                            "left": {
                                "red": 176,
                                "green": 169,
                                "blue": 134,
                                "alpha": 1
                            },
                            "bottom": {
                                "red": 176,
                                "green": 169,
                                "blue": 134,
                                "alpha": 1
                            }
                        }
                    },
                    "cssBorderRadius": {
                        "topLeft": {
                            "value": 0,
                            "unit": "px"
                        },
                        "topRight": {
                            "value": 0,
                            "unit": "px"
                        },
                        "bottomLeft": {
                            "value": 0,
                            "unit": "px"
                        },
                        "bottomRight": {
                            "value": 0,
                            "unit": "px"
                        }
                    }
                },
                "themeMappings": {},
                "charas": "design-iwffec4x",
                "dataChangeBehaviors": [
                    {
                        "trigger": "in",
                        "type": "animation",
                        "part": "media",
                        "name": "Scale",
                        "params": {
                            "delay": 0,
                            "duration": 0.5
                        }
                    },
                    {
                        "trigger": "out",
                        "type": "animation",
                        "part": "media",
                        "name": "Scale",
                        "params": {
                            "delay": 0,
                            "duration": 0.5
                        }
                    }
                ]
            },
            "style": {
                "type": "TopLevelStyle",
                "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false
                },
                "style": {
                    "properties": {
                        "alpha-bg": "1",
                        "alpha-brd": "1",
                        "bg": "206,224,193,1",
                        "boxShadowToggleOn-shd": "false",
                        "brd": "#324158",
                        "brw": "0px",
                        "rd": "0px",
                        "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                    },
                    "propertiesSource": {
                        "bg": "value",
                        "brd": "value",
                        "brw": "value",
                        "rd": "value",
                        "shd": "value"
                    },
                    "groups": {}
                },
                "componentClassName": "wysiwyg.viewer.components.HoverBox",
                "pageId": "",
                "compId": "",
                "styleType": "custom",
                "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer"
            }
        },
        "activeModes": {}
    },
    "preset": {
        "rect": {
            "width": 162,
            "height": 183,
            "x": -1,
            "y": 2715
        }
    }
}
const hbPreset19 = {
    "id": "hoverBoxPreset19",
    "structure": {
        "type": "Container",
        "components": [
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwasau5q-mode-iwasau9u1"
                            ],
                            "layout": {
                                "width": 201,
                                "height": 24,
                                "x": 27,
                                "y": 36,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            }
                        },
                        {
                            "modeIds": [
                                "comp-iwasau5q-mode-iwasau9u"
                            ],
                            "layout": {
                                "width": 201,
                                "height": 24,
                                "x": 27,
                                "y": 205,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            }
                        }
                    ],
                    "isHiddenByModes": false
                },
                "layout": {
                    "width": 201,
                    "height": 24,
                    "x": 27,
                    "y": 205,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "text": "<h6 class=\"font_6\"><span style=\"letter-spacing:0.15em\"><span style=\"color:#FFFFFF\">LUXURY SPA</span></span></h6>\n",
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": []
                },
                "props": {
                    "type": "WRichTextProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "brightness": 1,
                    "packed": true
                },
                "style": "txtNew",
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"action\":\"modeChange\",\"name\":\"ModesMotion\",\"delay\":0,\"duration\":0.3,\"params\":{\"timingFunction\":\"EaseInOut\",\"modeIds\":[\"comp-iwasau5q-mode-iwasau9u1\"]},\"targetId\":\"\"}]"
                },
                "mobileStructure": {
                    "type": "Component",
                    "modes": {
                        "definitions": []
                    },
                    "layout": {
                        "width": 232,
                        "height": 25,
                        "x": 28,
                        "y": 31,
                        "scale": 1.05,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.WRichText",
                    "data": {
                        "type": "StyledText",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "text": "<h6 class=\"font_6\"><span style=\"letter-spacing:0.15em\"><span style=\"color:#FFFFFF\">LUXURY SPA</span></span></h6>\n",
                        "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                        "linkList": []
                    },
                    "props": {
                        "type": "WRichTextProperties",
                        "metaData": {
                            "schemaVersion": "1.0",
                            "autoGenerated": false
                        },
                        "brightness": 1,
                        "packed": true
                    },
                    "style": "txtNew",
                    "behaviors": {
                        "type": "ObsoleteBehaviorsList",
                        "items": "[{\"action\":\"modeChange\",\"name\":\"ModesMotion\",\"delay\":0,\"duration\":0.3,\"params\":{\"timingFunction\":\"EaseInOut\",\"modeIds\":[\"comp-iwasau5q-mode-iwasau9u1\"]},\"targetId\":\"\"}]"
                    }
                }
            },
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwasau5q-mode-iwasau9u1"
                            ],
                            "layout": {
                                "width": 207,
                                "height": 50,
                                "x": 27,
                                "y": 101,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "isHiddenByModes": false,
                            "style": "txtNew",
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {
                                    "schemaVersion": "1.0"
                                },
                                "brightness": 1,
                                "packed": true
                            }
                        }
                    ],
                    "isHiddenByModes": true
                },
                "layout": {
                    "width": 170,
                    "height": 66,
                    "x": 27,
                    "y": 100,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "text": "<p class=\"font_8\" style=\"font-size:17px; line-height:1.5em\"><span style=\"font-size:17px\"><span style=\"font-family:brandon-grot-w01-light,sans-serif\"><span style=\"color:#FFFFFF\"><span style=\"letter-spacing:0em\">Use catchy, fun text to tell people what you offer. </span></span></span></span></p>\n",
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": []
                },
                "props": {
                    "type": "WRichTextProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "brightness": 1,
                    "packed": true
                },
                "style": "txtNew",
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"name\":\"FloatIn\",\"delay\":0,\"duration\":0.3,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iwasau5q-mode-iwasau9u1\"]},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"FloatOut\",\"delay\":0,\"duration\":0.3,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iwasau5q-mode-iwasau9u1\"]},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                },
                "mobileStructure": {
                    "type": "Component",
                    "modes": {
                        "definitions": []
                    },
                    "layout": {
                        "width": 204,
                        "height": 50,
                        "x": 28,
                        "y": 94,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.WRichText",
                    "data": {
                        "type": "StyledText",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "text": "<p class=\"font_8\" style=\"font-size:17px; line-height:1.5em\"><span style=\"font-size:17px\"><span style=\"font-family:brandon-grot-w01-light,sans-serif\"><span style=\"color:#FFFFFF\"><span style=\"letter-spacing:0em\">Use catchy, fun text to tell people what you offer. </span></span></span></span></p>\n",
                        "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                        "linkList": []
                    },
                    "props": {
                        "type": "WRichTextProperties",
                        "metaData": {
                            "schemaVersion": "1.0",
                            "autoGenerated": false
                        },
                        "brightness": 1,
                        "packed": true
                    },
                    "style": "txtNew",
                    "behaviors": {
                        "type": "ObsoleteBehaviorsList",
                        "items": "[{\"name\":\"FloatIn\",\"delay\":0,\"duration\":0.3,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iwasau5q-mode-iwasau9u1\"]},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"FloatOut\",\"delay\":0,\"duration\":0.3,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iwasau5q-mode-iwasau9u1\"]},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                    }
                }
            },
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwasau5q-mode-iwasau9u1"
                            ],
                            "layout": {
                                "width": 45,
                                "height": 5,
                                "x": 27,
                                "y": 80,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            }
                        },
                        {
                            "modeIds": [
                                "comp-iwasau5q-mode-iwasau9u"
                            ],
                            "layout": {
                                "width": 48,
                                "height": 5,
                                "x": 27,
                                "y": 247,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            }
                        }
                    ],
                    "isHiddenByModes": false
                },
                "layout": {
                    "width": 45,
                    "height": 5,
                    "x": 27,
                    "y": 247,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.FiveGridLine",
                "props": {
                    "type": "FiveGridLineProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "fullScreenModeOn": false
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "style": {
                        "properties": {
                            "alpha-brd": "1",
                            "brd": "#FFFFFF",
                            "lnw": "2"
                        },
                        "propertiesSource": {
                            "brd": "value",
                            "lnw": "value"
                        },
                        "groups": {}
                    },
                    "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.line.SolidLine"
                },
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"action\":\"modeChange\",\"name\":\"ModesMotion\",\"delay\":0,\"duration\":0.3,\"params\":{\"timingFunction\":\"EaseInOut\",\"modeIds\":[\"comp-iwasau5q-mode-iwasau9u1\"]},\"targetId\":\"\"}]"
                },
                "mobileStructure": {
                    "type": "Component",
                    "modes": {
                        "definitions": []
                    },
                    "layout": {
                        "width": 39,
                        "height": 5,
                        "x": 28,
                        "y": 76,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.FiveGridLine",
                    "props": {
                        "type": "FiveGridLineProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "fullScreenModeOn": false
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "properties": {
                                "alpha-brd": "1",
                                "brd": "#FFFFFF",
                                "lnw": "2"
                            },
                            "propertiesSource": {
                                "brd": "value",
                                "lnw": "value"
                            },
                            "groups": {}
                        },
                        "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.line.SolidLine"
                    },
                    "behaviors": {
                        "type": "ObsoleteBehaviorsList",
                        "items": "[{\"action\":\"modeChange\",\"name\":\"ModesMotion\",\"delay\":0,\"duration\":0.3,\"params\":{\"timingFunction\":\"EaseInOut\",\"modeIds\":[\"comp-iwasau5q-mode-iwasau9u1\"]},\"targetId\":\"\"}]"
                    }
                }
            },
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwasau5q-mode-iwasau9u1"
                            ],
                            "layout": {
                                "width": 207,
                                "height": 24,
                                "x": 27,
                                "y": 166,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "isHiddenByModes": false
                        }
                    ],
                    "isHiddenByModes": true
                },
                "layout": {
                    "width": 207,
                    "height": 75,
                    "x": 62,
                    "y": 170,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "text": "<p class=\"font_8\" style=\"font-size:16px; line-height:1.5em\"><a dataquery=\"#textLink_iw55utim\"><span style=\"letter-spacing:0em\"><span style=\"font-size:16px\"><span style=\"font-family:brandon-grot-w01-light,sans-serif\"><span style=\"color:#FFFFFF\">Read More &gt;</span></span></span></span></a></p>\n",
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": [
                        {
                            "type": "PageLink",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "1.0",
                                "isHidden": false
                            },
                            "pageId": "#c1dmp"
                        }
                    ]
                },
                "props": {
                    "type": "WRichTextProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "brightness": 1,
                    "packed": true
                },
                "style": "txtNew",
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"name\":\"FloatIn\",\"delay\":0,\"duration\":0.3,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iwasau5q-mode-iwasau9u1\"]},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"FloatOut\",\"delay\":0,\"duration\":0.3,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iwasau5q-mode-iwasau9u1\"]},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                },
                "custom": {
                    "linkList": [
                        {
                            "type": "PageLink",
                            "id": "dataItem-iwasaua92",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "1.0",
                                "isHidden": false
                            },
                            "pageId": "#c1dmp"
                        }
                    ]
                },
                "mobileStructure": {
                    "type": "Component",
                    "modes": {
                        "definitions": []
                    },
                    "layout": {
                        "width": 232,
                        "height": 24,
                        "x": 28,
                        "y": 164,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.WRichText",
                    "data": {
                        "type": "StyledText",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "text": "<p class=\"font_8\" style=\"font-size:16px; line-height:1.5em\"><a dataquery=\"#textLink_iw55utim\"><span style=\"letter-spacing:0em\"><span style=\"font-size:16px\"><span style=\"font-family:brandon-grot-w01-light,sans-serif\"><span style=\"color:#FFFFFF\">Read More &gt;</span></span></span></span></a></p>\n",
                        "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                        "linkList": [
                            {
                                "type": "PageLink",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "pageId": "#c1dmp"
                            }
                        ]
                    },
                    "props": {
                        "type": "WRichTextProperties",
                        "metaData": {
                            "schemaVersion": "1.0",
                            "autoGenerated": false
                        },
                        "brightness": 1,
                        "packed": true
                    },
                    "style": "txtNew",
                    "behaviors": {
                        "type": "ObsoleteBehaviorsList",
                        "items": "[{\"name\":\"FloatIn\",\"delay\":0,\"duration\":0.3,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iwasau5q-mode-iwasau9u1\"]},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"FloatOut\",\"delay\":0,\"duration\":0.3,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iwasau5q-mode-iwasau9u1\"]},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                    },
                    "custom": {
                        "linkList": [
                            {
                                "type": "PageLink",
                                "id": "dataItem-iwasaua92",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "pageId": "#c1dmp"
                            }
                        ]
                    }
                }
            }
        ],
        "modes": {
            "definitions": [
                {
                    "modeId": "comp-iwasau5q-mode-iwasau9u",
                    "type": "DEFAULT",
                    "label": "MODE_LABEL_FROM_PRESET",
                    "params": ""
                },
                {
                    "modeId": "comp-iwasau5q-mode-iwasau9u1",
                    "type": "HOVER",
                    "label": "MODE_LABEL_FROM_PRESET",
                    "params": ""
                }
            ],
            "overrides": [
                {
                    "modeIds": [
                        "comp-iwasau5q-mode-iwasau9u1"
                    ],
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "1.0",
                                "isHidden": false
                            },
                            "mediaRef": {
                                "type": "Image",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "title": "Spa Facilities",
                                "uri": "94eabf9c7a044328996d8dfe07af9979.jpg",
                                "description": "public/491df47b-5958-4dc7-9029-37f428755d45/c2a37dfc-36cf-4bfd-b940-55bac7bdc9e6",
                                "width": 6148,
                                "height": 4099,
                                "alt": "",
                                "artist": {
                                    "id": "",
                                    "name": ""
                                },
                                "opacity": 0.75
                            },
                            "color": "#000000",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "#000000",
                            "colorOverlayOpacity": 0.5,
                            "showOverlayForMediaType": "all",
                            "mediaTransforms": {
                                "scale": 1
                            }
                        },
                        "cssStyle": {
                            "cssBorder": {
                                "width": {
                                    "top": {
                                        "value": 0,
                                        "unit": "px"
                                    },
                                    "right": {
                                        "value": 0,
                                        "unit": "px"
                                    },
                                    "bottom": {
                                        "value": 0,
                                        "unit": "px"
                                    },
                                    "left": {
                                        "value": 0,
                                        "unit": "px"
                                    }
                                },
                                "style": {
                                    "top": "solid",
                                    "right": "solid",
                                    "left": "solid",
                                    "bottom": "solid"
                                },
                                "color": {
                                    "top": {
                                        "red": 176,
                                        "green": 169,
                                        "blue": 134,
                                        "alpha": 1
                                    },
                                    "right": {
                                        "red": 176,
                                        "green": 169,
                                        "blue": 134,
                                        "alpha": 1
                                    },
                                    "left": {
                                        "red": 176,
                                        "green": 169,
                                        "blue": 134,
                                        "alpha": 1
                                    },
                                    "bottom": {
                                        "red": 176,
                                        "green": 169,
                                        "blue": 134,
                                        "alpha": 1
                                    }
                                }
                            },
                            "cssBorderRadius": {
                                "topLeft": {
                                    "value": 0,
                                    "unit": "px"
                                },
                                "topRight": {
                                    "value": 0,
                                    "unit": "px"
                                },
                                "bottomLeft": {
                                    "value": 0,
                                    "unit": "px"
                                },
                                "bottomRight": {
                                    "value": 0,
                                    "unit": "px"
                                }
                            }
                        },
                        "themeMappings": {},
                        "charas": "design-iw3q4p3a",
                        "dataChangeBehaviors": [
                            {
                                "trigger": "in",
                                "type": "animation",
                                "part": "overlay",
                                "name": "FadeIn",
                                "params": {
                                    "delay": 0,
                                    "duration": 0.5
                                }
                            }
                        ]
                    }
                },
                {
                    "modeIds": [
                        "comp-iwasau5q-mode-iwasau9u"
                    ],
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "1.0",
                                "isHidden": false
                            },
                            "mediaRef": {
                                "type": "Image",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "title": "Spa Facilities",
                                "uri": "94eabf9c7a044328996d8dfe07af9979.jpg",
                                "description": "public/491df47b-5958-4dc7-9029-37f428755d45/c2a37dfc-36cf-4bfd-b940-55bac7bdc9e6",
                                "width": 6148,
                                "height": 4099,
                                "alt": "",
                                "artist": {
                                    "id": "",
                                    "name": ""
                                },
                                "opacity": 0.75
                            },
                            "color": "#000000",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0,
                            "showOverlayForMediaType": "WixVideo",
                            "mediaTransforms": {
                                "scale": 1
                            }
                        },
                        "cssStyle": {
                            "cssBorder": {
                                "width": {
                                    "top": {
                                        "value": 0,
                                        "unit": "px"
                                    },
                                    "right": {
                                        "value": 0,
                                        "unit": "px"
                                    },
                                    "bottom": {
                                        "value": 0,
                                        "unit": "px"
                                    },
                                    "left": {
                                        "value": 0,
                                        "unit": "px"
                                    }
                                },
                                "style": {
                                    "top": "solid",
                                    "right": "solid",
                                    "left": "solid",
                                    "bottom": "solid"
                                },
                                "color": {
                                    "top": {
                                        "red": 176,
                                        "green": 169,
                                        "blue": 134,
                                        "alpha": 1
                                    },
                                    "right": {
                                        "red": 176,
                                        "green": 169,
                                        "blue": 134,
                                        "alpha": 1
                                    },
                                    "left": {
                                        "red": 176,
                                        "green": 169,
                                        "blue": 134,
                                        "alpha": 1
                                    },
                                    "bottom": {
                                        "red": 176,
                                        "green": 169,
                                        "blue": 134,
                                        "alpha": 1
                                    }
                                }
                            },
                            "cssBorderRadius": {
                                "topLeft": {
                                    "value": 0,
                                    "unit": "px"
                                },
                                "topRight": {
                                    "value": 0,
                                    "unit": "px"
                                },
                                "bottomLeft": {
                                    "value": 0,
                                    "unit": "px"
                                },
                                "bottomRight": {
                                    "value": 0,
                                    "unit": "px"
                                }
                            }
                        },
                        "themeMappings": {},
                        "charas": "design-iw3q3tyt",
                        "dataChangeBehaviors": []
                    }
                }
            ],
            "isHiddenByModes": false
        },
        "layout": {
            "width": 284,
            "height": 284,
            "x": 0,
            "y": 0,
            "scale": 1,
            "rotationInDegrees": 0,
            "fixedPosition": false
        },
        "componentType": "wysiwyg.viewer.components.HoverBox",
        "props": {
            "type": "HoverBoxProperties",
            "metaData": {
                "schemaVersion": "1.0"
            },
            "mobileDisplayedModeId": "comp-iwasau5q-mode-iwasau9u1"
        },
        "design": {
            "type": "MediaContainerDesignData",
            "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false
            },
            "background": {
                "type": "BackgroundMedia",
                "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false
                },
                "mediaRef": {
                    "type": "Image",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "title": "Spa Facilities",
                    "uri": "94eabf9c7a044328996d8dfe07af9979.jpg",
                    "description": "public/491df47b-5958-4dc7-9029-37f428755d45/c2a37dfc-36cf-4bfd-b940-55bac7bdc9e6",
                    "width": 6148,
                    "height": 4099,
                    "alt": "",
                    "artist": {
                        "id": "",
                        "name": ""
                    },
                    "opacity": 0.7
                },
                "color": "#000000",
                "colorOpacity": 1,
                "alignType": "center",
                "fittingType": "fill",
                "scrollType": "none",
                "colorOverlay": "#000000",
                "colorOverlayOpacity": 0.35,
                "showOverlayForMediaType": "all",
                "mediaTransforms": {
                    "scale": 1
                }
            },
            "cssStyle": {
                "cssBorder": {
                    "width": {
                        "top": {
                            "value": 0,
                            "unit": "px"
                        },
                        "right": {
                            "value": 0,
                            "unit": "px"
                        },
                        "bottom": {
                            "value": 0,
                            "unit": "px"
                        },
                        "left": {
                            "value": 0,
                            "unit": "px"
                        }
                    },
                    "style": {
                        "top": "solid",
                        "right": "solid",
                        "left": "solid",
                        "bottom": "solid"
                    },
                    "color": {
                        "top": {
                            "red": 176,
                            "green": 169,
                            "blue": 134,
                            "alpha": 1
                        },
                        "right": {
                            "red": 176,
                            "green": 169,
                            "blue": 134,
                            "alpha": 1
                        },
                        "left": {
                            "red": 176,
                            "green": 169,
                            "blue": 134,
                            "alpha": 1
                        },
                        "bottom": {
                            "red": 176,
                            "green": 169,
                            "blue": 134,
                            "alpha": 1
                        }
                    }
                },
                "cssBorderRadius": {
                    "topLeft": {
                        "value": 0,
                        "unit": "px"
                    },
                    "topRight": {
                        "value": 0,
                        "unit": "px"
                    },
                    "bottomLeft": {
                        "value": 0,
                        "unit": "px"
                    },
                    "bottomRight": {
                        "value": 0,
                        "unit": "px"
                    }
                }
            },
            "themeMappings": {},
            "charas": "design-iw2cm3f2",
            "dataChangeBehaviors": []
        },
        "style": {
            "type": "TopLevelStyle",
            "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false
            },
            "style": {
                "properties": {
                    "alpha-bg": "1",
                    "alpha-brd": "1",
                    "bg": "206,224,193,1",
                    "boxShadowToggleOn-shd": "false",
                    "brd": "#324158",
                    "brw": "0px",
                    "rd": "0px",
                    "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                },
                "propertiesSource": {
                    "bg": "value",
                    "brd": "value",
                    "brw": "value",
                    "rd": "value",
                    "shd": "value"
                },
                "groups": {}
            },
            "componentClassName": "wysiwyg.viewer.components.HoverBox",
            "pageId": "",
            "compId": "",
            "styleType": "custom",
            "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer"
        },
        "mobileStructure": {
            "type": "Container",
            "modes": {
                "definitions": [
                    {
                        "modeId": "comp-iwasau5q-mode-iwasau9u",
                        "type": "DEFAULT",
                        "label": "MODE_LABEL_FROM_PRESET",
                        "params": ""
                    },
                    {
                        "modeId": "comp-iwasau5q-mode-iwasau9u1",
                        "type": "HOVER",
                        "label": "MODE_LABEL_FROM_PRESET",
                        "params": ""
                    }
                ]
            },
            "layout": {
                "width": 280,
                "height": 280,
                "x": 20,
                "y": 10,
                "scale": 1,
                "rotationInDegrees": 0,
                "fixedPosition": false
            },
            "componentType": "wysiwyg.viewer.components.HoverBox",
            "props": {
                "type": "HoverBoxProperties",
                "metaData": {
                    "schemaVersion": "1.0"
                },
                "mobileDisplayedModeId": "comp-iwasau5q-mode-iwasau9u1"
            },
            "design": {
                "type": "MediaContainerDesignData",
                "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false
                },
                "background": {
                    "type": "BackgroundMedia",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "mediaRef": {
                        "type": "Image",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "title": "Spa Facilities",
                        "uri": "94eabf9c7a044328996d8dfe07af9979.jpg",
                        "description": "public/491df47b-5958-4dc7-9029-37f428755d45/c2a37dfc-36cf-4bfd-b940-55bac7bdc9e6",
                        "width": 6148,
                        "height": 4099,
                        "alt": "",
                        "artist": {
                            "id": "",
                            "name": ""
                        },
                        "opacity": 0.75
                    },
                    "color": "#000000",
                    "colorOpacity": 1,
                    "alignType": "center",
                    "fittingType": "fill",
                    "scrollType": "none",
                    "colorOverlay": "#000000",
                    "colorOverlayOpacity": 0.5,
                    "showOverlayForMediaType": "all",
                    "mediaTransforms": {
                        "scale": 1
                    }
                },
                "cssStyle": {
                    "cssBorder": {
                        "width": {
                            "top": {
                                "value": 0,
                                "unit": "px"
                            },
                            "right": {
                                "value": 0,
                                "unit": "px"
                            },
                            "bottom": {
                                "value": 0,
                                "unit": "px"
                            },
                            "left": {
                                "value": 0,
                                "unit": "px"
                            }
                        },
                        "style": {
                            "top": "solid",
                            "right": "solid",
                            "left": "solid",
                            "bottom": "solid"
                        },
                        "color": {
                            "top": {
                                "red": 176,
                                "green": 169,
                                "blue": 134,
                                "alpha": 1
                            },
                            "right": {
                                "red": 176,
                                "green": 169,
                                "blue": 134,
                                "alpha": 1
                            },
                            "left": {
                                "red": 176,
                                "green": 169,
                                "blue": 134,
                                "alpha": 1
                            },
                            "bottom": {
                                "red": 176,
                                "green": 169,
                                "blue": 134,
                                "alpha": 1
                            }
                        }
                    },
                    "cssBorderRadius": {
                        "topLeft": {
                            "value": 0,
                            "unit": "px"
                        },
                        "topRight": {
                            "value": 0,
                            "unit": "px"
                        },
                        "bottomLeft": {
                            "value": 0,
                            "unit": "px"
                        },
                        "bottomRight": {
                            "value": 0,
                            "unit": "px"
                        }
                    }
                },
                "themeMappings": {},
                "charas": "design-iw3q4p3a",
                "dataChangeBehaviors": [
                    {
                        "trigger": "in",
                        "type": "animation",
                        "part": "overlay",
                        "name": "FadeIn",
                        "params": {
                            "delay": 0,
                            "duration": 0.5
                        }
                    }
                ]
            },
            "style": {
                "type": "TopLevelStyle",
                "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false
                },
                "style": {
                    "properties": {
                        "alpha-bg": "1",
                        "alpha-brd": "1",
                        "bg": "206,224,193,1",
                        "boxShadowToggleOn-shd": "false",
                        "brd": "#324158",
                        "brw": "0px",
                        "rd": "0px",
                        "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                    },
                    "propertiesSource": {
                        "bg": "value",
                        "brd": "value",
                        "brw": "value",
                        "rd": "value",
                        "shd": "value"
                    },
                    "groups": {}
                },
                "componentClassName": "wysiwyg.viewer.components.HoverBox",
                "pageId": "",
                "compId": "",
                "styleType": "custom",
                "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer"
            }
        },
        "activeModes": {}
    },
    "preset": {
        "rect": {
            "width": 162,
            "height": 183,
            "x": 161,
            "y": 2715
        }
    }
}
const hbPreset20 = {
    "id": "hoverBoxPreset20",
    "structure": {
        "type": "Container",
        "components": [
            {
                "type": "Component",
                "modes": {
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwknyt0s-mode-iwknyt1a1"
                            ],
                            "layout": {
                                "width": 136,
                                "height": 53,
                                "x": 821,
                                "y": 118,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "isHiddenByModes": false
                        }
                    ],
                    "isHiddenByModes": true
                },
                "layout": {
                    "width": 435,
                    "height": 96,
                    "x": 86,
                    "y": 151,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "text": "<p class=\"font_8\" style=\"font-size:26px; line-height:1.3em\"><span style=\"font-size:26px\"><span style=\"font-family:avenir-lt-w01_85-heavy1475544,sans-serif\"><span style=\"color:#FFFFFF\">3 cups</span></span></span></p>\n\n<p class=\"font_7\" style=\"font-size:16px; line-height:1.3em\"><span style=\"font-size:16px\"><span style=\"letter-spacing:0.3em\"><span style=\"font-family:brandon-grot-w01-light,sans-serif\"><span style=\"color:#FFFFFF\">Blueberries</span></span></span></span></p>\n",
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": []
                },
                "props": {
                    "type": "WRichTextProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "brightness": 1,
                    "packed": true
                },
                "style": "txtNew",
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"name\":\"Reveal\",\"delay\":0.1,\"duration\":0.25,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iwknyt0s-mode-iwknyt1a1\"]},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"Conceal\",\"delay\":0.1,\"duration\":0.25,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iwknyt0s-mode-iwknyt1a1\"]},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                }
            },
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwknyt0s-mode-iwknyt1a1"
                            ],
                            "layout": {
                                "width": 1,
                                "height": 123,
                                "x": 459,
                                "y": 427,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "isHiddenByModes": false,
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "style": {
                                    "properties": {
                                        "alpha-brd": "1",
                                        "brd": "#FFFFFF",
                                        "lnw": "1px"
                                    },
                                    "propertiesSource": {
                                        "brd": "value",
                                        "lnw": "value"
                                    },
                                    "groups": {}
                                },
                                "componentClassName": "wysiwyg.viewer.components.VerticalLine",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.line.VerticalSolidLine"
                            }
                        }
                    ],
                    "isHiddenByModes": true
                },
                "skin": "wysiwyg.viewer.skins.line.VerticalSolidLine",
                "layout": {
                    "width": 1,
                    "height": 169,
                    "x": 364,
                    "y": 377,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.VerticalLine",
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "style": {
                        "properties": {
                            "alpha-brd": "1",
                            "brd": "#FFFFFF",
                            "lnw": "1px"
                        },
                        "propertiesSource": {
                            "brd": "value",
                            "lnw": "value"
                        },
                        "groups": {}
                    },
                    "componentClassName": "wysiwyg.viewer.components.VerticalLine",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.line.VerticalSolidLine"
                },
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"name\":\"Reveal\",\"delay\":0,\"duration\":0.25,\"params\":{\"direction\":\"top\",\"modeIds\":[\"comp-iwknyt0s-mode-iwknyt1a1\"]},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"Conceal\",\"delay\":0,\"duration\":0.25,\"params\":{\"direction\":\"top\",\"modeIds\":[\"comp-iwknyt0s-mode-iwknyt1a1\"]},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                }
            },
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwknyt0s-mode-iwknyt1a1"
                            ],
                            "layout": {
                                "width": 184,
                                "height": 5,
                                "x": 755,
                                "y": 180,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "isHiddenByModes": false,
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "style": {
                                    "properties": {
                                        "alpha-brd": "1",
                                        "brd": "#FFFFFF",
                                        "lnw": "1px"
                                    },
                                    "propertiesSource": {
                                        "brd": "value",
                                        "lnw": "value"
                                    },
                                    "groups": {}
                                },
                                "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.line.SolidLine"
                            },
                            "props": {
                                "type": "FiveGridLineProperties",
                                "metaData": {
                                    "schemaVersion": "1.0"
                                },
                                "fullScreenModeOn": false
                            }
                        }
                    ],
                    "isHiddenByModes": true
                },
                "skin": "wysiwyg.viewer.skins.line.SolidLine",
                "layout": {
                    "width": 185,
                    "height": 5,
                    "x": 704,
                    "y": 401,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.FiveGridLine",
                "props": {
                    "type": "FiveGridLineProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "fullScreenModeOn": false
                },
                "style": "hl1",
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"name\":\"Reveal\",\"delay\":0,\"duration\":0.25,\"params\":{\"direction\":\"left\",\"modeIds\":[\"comp-iwknyt0s-mode-iwknyt1a1\"]},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"Conceal\",\"delay\":0,\"duration\":0.25,\"params\":{\"direction\":\"left\",\"modeIds\":[\"comp-iwknyt0s-mode-iwknyt1a1\"]},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                }
            },
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwknyt0s-mode-iwknyt1a1"
                            ],
                            "layout": {
                                "width": 159,
                                "height": 5,
                                "x": 63,
                                "y": 193,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "isHiddenByModes": false,
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "style": {
                                    "properties": {
                                        "alpha-brd": "1",
                                        "brd": "#FFFFFF",
                                        "lnw": "1px"
                                    },
                                    "propertiesSource": {
                                        "brd": "value",
                                        "lnw": "value"
                                    },
                                    "groups": {}
                                },
                                "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.line.SolidLine"
                            },
                            "props": {
                                "type": "FiveGridLineProperties",
                                "metaData": {
                                    "schemaVersion": "1.0"
                                },
                                "fullScreenModeOn": false
                            }
                        }
                    ],
                    "isHiddenByModes": true
                },
                "skin": "wysiwyg.viewer.skins.line.SolidLine",
                "layout": {
                    "width": 186,
                    "height": 5,
                    "x": 69,
                    "y": 378,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.FiveGridLine",
                "props": {
                    "type": "FiveGridLineProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "fullScreenModeOn": false
                },
                "style": "hl1",
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"name\":\"Reveal\",\"delay\":0,\"duration\":0.25,\"params\":{\"direction\":\"right\",\"modeIds\":[\"comp-iwknyt0s-mode-iwknyt1a1\"]},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"Conceal\",\"delay\":0,\"duration\":0.25,\"params\":{\"direction\":\"right\",\"modeIds\":[\"comp-iwknyt0s-mode-iwknyt1a1\"]},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                }
            },
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwknyt0s-mode-iwknyt1a1"
                            ],
                            "layout": {
                                "width": 136,
                                "height": 53,
                                "x": 473,
                                "y": 501,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "isHiddenByModes": false,
                            "style": "txtNew",
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {
                                    "schemaVersion": "1.0"
                                },
                                "brightness": 1,
                                "packed": true
                            }
                        }
                    ],
                    "isHiddenByModes": true
                },
                "layout": {
                    "width": 136,
                    "height": 58,
                    "x": 605,
                    "y": 370,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "text": "<p class=\"font_8\" style=\"font-size:26px; line-height:1.3em\"><span style=\"font-size:26px\"><span style=\"font-family:avenir-lt-w01_85-heavy1475544,sans-serif\"><span style=\"color:#FFFFFF\">3 cups</span></span></span></p>\n\n<p class=\"font_7\" style=\"font-size:16px; line-height:1.3em\"><span style=\"font-size:16px\"><span style=\"letter-spacing:0.3em\"><span style=\"font-family:brandon-grot-w01-light,sans-serif\"><span style=\"color:#FFFFFF\">Flour</span></span></span></span></p>\n",
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": []
                },
                "props": {
                    "type": "WRichTextProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "brightness": 1,
                    "packed": true
                },
                "style": "txtNew",
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"name\":\"Reveal\",\"delay\":0.1,\"duration\":0.25,\"params\":{\"direction\":\"left\",\"modeIds\":[\"comp-iwknyt0s-mode-iwknyt1a1\"]},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"Conceal\",\"delay\":0.1,\"duration\":0.25,\"params\":{\"direction\":\"left\",\"modeIds\":[\"comp-iwknyt0s-mode-iwknyt1a1\"]},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                }
            },
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwknyt0s-mode-iwknyt1a1"
                            ],
                            "layout": {
                                "width": 136,
                                "height": 53,
                                "x": 63,
                                "y": 130,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "isHiddenByModes": false,
                            "style": "txtNew",
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {
                                    "schemaVersion": "1.0"
                                },
                                "brightness": 1,
                                "packed": true
                            }
                        }
                    ],
                    "isHiddenByModes": true
                },
                "layout": {
                    "width": 136,
                    "height": 58,
                    "x": 118,
                    "y": 86,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "text": "<p class=\"font_8\" style=\"font-size:26px; line-height:1.3em\"><span style=\"font-size:26px\"><span style=\"font-family:avenir-lt-w01_85-heavy1475544,sans-serif\"><span style=\"color:#FFFFFF\">2 tsp.</span></span></span></p>\n\n<p class=\"font_7\" style=\"font-size:16px; line-height:1.3em\"><span style=\"font-size:16px\"><span style=\"letter-spacing:0.3em\"><span style=\"font-family:brandon-grot-w01-light,sans-serif\"><span style=\"color:#FFFFFF\">Cinnamon</span></span></span></span></p>\n",
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": []
                },
                "props": {
                    "type": "WRichTextProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "brightness": 1,
                    "packed": true
                },
                "style": "txtNew",
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"name\":\"Reveal\",\"delay\":0.1,\"duration\":0.25,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iwknyt0s-mode-iwknyt1a1\"]},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"Conceal\",\"delay\":0.1,\"duration\":0.25,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iwknyt0s-mode-iwknyt1a1\"]},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                }
            },
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwknyt0s-mode-iwknyt1a1"
                            ],
                            "layout": {
                                "width": 1,
                                "height": 122,
                                "x": 483,
                                "y": 69,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "isHiddenByModes": false,
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "style": {
                                    "properties": {
                                        "alpha-brd": "1",
                                        "brd": "#FFFFFF",
                                        "lnw": "1px"
                                    },
                                    "propertiesSource": {
                                        "brd": "value",
                                        "lnw": "value"
                                    },
                                    "groups": {}
                                },
                                "componentClassName": "wysiwyg.viewer.components.VerticalLine",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.line.VerticalSolidLine"
                            }
                        }
                    ],
                    "isHiddenByModes": true
                },
                "skin": "wysiwyg.viewer.skins.line.VerticalSolidLine",
                "layout": {
                    "width": 1,
                    "height": 141,
                    "x": 482,
                    "y": 63,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.VerticalLine",
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "style": {
                        "properties": {
                            "alpha-brd": "1",
                            "brd": "#FFFFFF",
                            "lnw": "1px"
                        },
                        "propertiesSource": {
                            "brd": "value",
                            "lnw": "value"
                        },
                        "groups": {}
                    },
                    "componentClassName": "wysiwyg.viewer.components.VerticalLine",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.line.VerticalSolidLine"
                },
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"name\":\"Reveal\",\"delay\":0,\"duration\":0.25,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iwknyt0s-mode-iwknyt1a1\"]},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"Conceal\",\"delay\":0,\"duration\":0.25,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iwknyt0s-mode-iwknyt1a1\"]},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                }
            },
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwknyt0s-mode-iwknyt1a1"
                            ],
                            "layout": {
                                "width": 187,
                                "height": 53,
                                "x": 496,
                                "y": 63,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "isHiddenByModes": false,
                            "style": "txtNew",
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {
                                    "schemaVersion": "1.0"
                                },
                                "brightness": 1,
                                "packed": true
                            }
                        }
                    ],
                    "isHiddenByModes": true
                },
                "layout": {
                    "width": 136,
                    "height": 58,
                    "x": 494,
                    "y": 43,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "text": "<p class=\"font_8\" style=\"font-size:26px; line-height:1.3em\"><span style=\"font-size:26px\"><span style=\"font-family:avenir-lt-w01_85-heavy1475544,sans-serif\"><span style=\"color:#FFFFFF\">1 cup</span></span></span></p>\n\n<p class=\"font_7\" style=\"font-size:16px; line-height:1.3em\"><span style=\"font-size:16px\"><span style=\"letter-spacing:0.3em\"><span style=\"font-family:brandon-grot-w01-light,sans-serif\"><span style=\"color:#FFFFFF\">Sugar</span></span></span></span></p>\n",
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": []
                },
                "props": {
                    "type": "WRichTextProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "brightness": 1,
                    "packed": true
                },
                "style": "txtNew",
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"name\":\"Reveal\",\"delay\":0.1,\"duration\":0.25,\"params\":{\"direction\":\"left\",\"modeIds\":[\"comp-iwknyt0s-mode-iwknyt1a1\"]},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"Conceal\",\"delay\":0.1,\"duration\":0.25,\"params\":{\"direction\":\"left\",\"modeIds\":[\"comp-iwknyt0s-mode-iwknyt1a1\"]},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                }
            },
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwknyt0s-mode-iwknyt1a1"
                            ],
                            "layout": {
                                "width": 1,
                                "height": 119,
                                "x": 323,
                                "y": 44,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "isHiddenByModes": false,
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "style": {
                                    "properties": {
                                        "alpha-brd": "1",
                                        "brd": "#FFFFFF",
                                        "lnw": "1px"
                                    },
                                    "propertiesSource": {
                                        "brd": "value",
                                        "lnw": "value"
                                    },
                                    "groups": {}
                                },
                                "componentClassName": "wysiwyg.viewer.components.VerticalLine",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.line.VerticalSolidLine"
                            }
                        }
                    ],
                    "isHiddenByModes": true
                },
                "skin": "wysiwyg.viewer.skins.line.VerticalSolidLine",
                "layout": {
                    "width": 1,
                    "height": 149,
                    "x": 324,
                    "y": 110,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.VerticalLine",
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "style": {
                        "properties": {
                            "alpha-brd": "1",
                            "brd": "#FFFFFF",
                            "lnw": "1px"
                        },
                        "propertiesSource": {
                            "brd": "value",
                            "lnw": "value"
                        },
                        "groups": {}
                    },
                    "componentClassName": "wysiwyg.viewer.components.VerticalLine",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.line.VerticalSolidLine"
                },
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"name\":\"Reveal\",\"delay\":0,\"duration\":0.25,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iwknyt0s-mode-iwknyt1a1\"]},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"Conceal\",\"delay\":0,\"duration\":0.25,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iwknyt0s-mode-iwknyt1a1\"]},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                }
            },
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwknyt0s-mode-iwknyt1a1"
                            ],
                            "layout": {
                                "width": 80,
                                "height": 53,
                                "x": 335,
                                "y": 39,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "isHiddenByModes": false,
                            "style": "txtNew",
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {
                                    "schemaVersion": "1.0"
                                },
                                "brightness": 1,
                                "packed": true
                            }
                        }
                    ],
                    "isHiddenByModes": true
                },
                "layout": {
                    "width": 187,
                    "height": 58,
                    "x": 146,
                    "y": 37,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "text": "<p class=\"font_8\" style=\"font-size: 26px; line-height: 1.3em;\"><span style=\"font-size:26px\"><span style=\"font-family:avenir-lt-w01_85-heavy1475544,sans-serif\"><span style=\"color:#FFFFFF\">2 tsp.</span></span></span></p>\n\n<p class=\"font_7\" style=\"font-size: 16px; line-height: 1.3em;\"><span style=\"font-size:16px\"><span style=\"letter-spacing:0.3em\"><span style=\"font-family:brandon-grot-w01-light,sans-serif\"><span style=\"color:#FFFFFF\">Oil</span></span></span></span></p>\n",
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": []
                },
                "props": {
                    "type": "WRichTextProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "brightness": 1,
                    "packed": true
                },
                "style": "txtNew",
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"name\":\"Reveal\",\"delay\":0.1,\"duration\":0.25,\"params\":{\"direction\":\"right\",\"modeIds\":[\"comp-iwknyt0s-mode-iwknyt1a1\"]},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"Conceal\",\"delay\":0.1,\"duration\":0.25,\"params\":{\"direction\":\"right\",\"modeIds\":[\"comp-iwknyt0s-mode-iwknyt1a1\"]},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                }
            },
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwknyt0s-mode-iwknyt1a1"
                            ],
                            "layout": {
                                "width": 136,
                                "height": 53,
                                "x": 42,
                                "y": 282,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "isHiddenByModes": false,
                            "style": "txtNew",
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {
                                    "schemaVersion": "1.0"
                                },
                                "brightness": 1,
                                "packed": true
                            }
                        }
                    ],
                    "isHiddenByModes": true
                },
                "layout": {
                    "width": 136,
                    "height": 58,
                    "x": 48,
                    "y": 271,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "text": "<p class=\"font_8\" style=\"font-size:26px; line-height:1.3em\"><span style=\"font-size:26px\"><span style=\"font-family:avenir-lt-w01_85-heavy1475544,sans-serif\"><span style=\"color:#FFFFFF\">2 tbsp.</span></span></span></p>\n\n<p class=\"font_7\" style=\"font-size:16px; line-height:1.3em\"><span style=\"font-size:16px\"><span style=\"letter-spacing:0.3em\"><span style=\"font-family:brandon-grot-w01-light,sans-serif\"><span style=\"color:#FFFFFF\">Salt</span></span></span></span></p>\n",
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": []
                },
                "props": {
                    "type": "WRichTextProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "brightness": 1,
                    "packed": true
                },
                "style": "txtNew",
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"name\":\"Reveal\",\"delay\":0.1,\"duration\":0.25,\"params\":{\"direction\":\"top\",\"modeIds\":[\"comp-iwknyt0s-mode-iwknyt1a1\"]},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"Conceal\",\"delay\":0.1,\"duration\":0.25,\"params\":{\"direction\":\"top\",\"modeIds\":[\"comp-iwknyt0s-mode-iwknyt1a1\"]},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                }
            },
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwknyt0s-mode-iwknyt1a1"
                            ],
                            "layout": {
                                "width": 1,
                                "height": 113,
                                "x": 242,
                                "y": 411,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "isHiddenByModes": false,
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "style": {
                                    "properties": {
                                        "alpha-brd": "1",
                                        "brd": "#FFFFFF",
                                        "lnw": "1px"
                                    },
                                    "propertiesSource": {
                                        "brd": "value",
                                        "lnw": "value"
                                    },
                                    "groups": {}
                                },
                                "componentClassName": "wysiwyg.viewer.components.VerticalLine",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.line.VerticalSolidLine"
                            }
                        }
                    ],
                    "isHiddenByModes": true
                },
                "skin": "wysiwyg.viewer.skins.line.VerticalSolidLine",
                "layout": {
                    "width": 1,
                    "height": 95,
                    "x": 286,
                    "y": 371,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.VerticalLine",
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "style": {
                        "properties": {
                            "alpha-brd": "1",
                            "brd": "#FFFFFF",
                            "lnw": "1px"
                        },
                        "propertiesSource": {
                            "brd": "value",
                            "lnw": "value"
                        },
                        "groups": {}
                    },
                    "componentClassName": "wysiwyg.viewer.components.VerticalLine",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.line.VerticalSolidLine"
                },
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"name\":\"Reveal\",\"delay\":0,\"duration\":0.25,\"params\":{\"direction\":\"top\",\"modeIds\":[\"comp-iwknyt0s-mode-iwknyt1a1\"]},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"Conceal\",\"delay\":0,\"duration\":0.25,\"params\":{\"direction\":\"top\",\"modeIds\":[\"comp-iwknyt0s-mode-iwknyt1a1\"]},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                }
            },
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwknyt0s-mode-iwknyt1a1"
                            ],
                            "layout": {
                                "width": 136,
                                "height": 53,
                                "x": 95,
                                "y": 476,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "isHiddenByModes": false,
                            "style": "txtNew",
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {
                                    "schemaVersion": "1.0"
                                },
                                "brightness": 1,
                                "packed": true
                            }
                        }
                    ],
                    "isHiddenByModes": true
                },
                "layout": {
                    "width": 136,
                    "height": 58,
                    "x": 137,
                    "y": 495,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "text": "<p class=\"font_8\" style=\"font-size:26px; line-height:1.3em; text-align:right\"><span style=\"font-size:26px\"><span style=\"font-family:avenir-lt-w01_85-heavy1475544,sans-serif\"><span style=\"color:#FFFFFF\">1&frac12; cups</span></span></span></p>\n\n<p class=\"font_7\" style=\"font-size:16px; line-height:1.3em; text-align:right\"><span style=\"font-size:16px\"><span style=\"letter-spacing:0.3em\"><span style=\"font-family:brandon-grot-w01-light,sans-serif\"><span style=\"color:#FFFFFF\">Butter</span></span></span></span></p>\n",
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": []
                },
                "props": {
                    "type": "WRichTextProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "brightness": 1,
                    "packed": true
                },
                "style": "txtNew",
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"name\":\"Reveal\",\"delay\":0.1,\"duration\":0.25,\"params\":{\"direction\":\"right\",\"modeIds\":[\"comp-iwknyt0s-mode-iwknyt1a1\"]},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"Conceal\",\"delay\":0.1,\"duration\":0.25,\"params\":{\"direction\":\"right\",\"modeIds\":[\"comp-iwknyt0s-mode-iwknyt1a1\"]},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                }
            },
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwknyt0s-mode-iwknyt1a1"
                            ],
                            "layout": {
                                "width": 284,
                                "height": 5,
                                "x": 42,
                                "y": 271,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "isHiddenByModes": false,
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "style": {
                                    "properties": {
                                        "alpha-brd": "1",
                                        "brd": "#FFFFFF",
                                        "lnw": "1px"
                                    },
                                    "propertiesSource": {
                                        "brd": "value",
                                        "lnw": "value"
                                    },
                                    "groups": {}
                                },
                                "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.line.SolidLine"
                            },
                            "props": {
                                "type": "FiveGridLineProperties",
                                "metaData": {
                                    "schemaVersion": "1.0"
                                },
                                "fullScreenModeOn": false
                            }
                        }
                    ],
                    "isHiddenByModes": true
                },
                "skin": "wysiwyg.viewer.skins.line.SolidLine",
                "layout": {
                    "width": 155,
                    "height": 5,
                    "x": 171,
                    "y": 280,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.FiveGridLine",
                "props": {
                    "type": "FiveGridLineProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "fullScreenModeOn": false
                },
                "style": "hl1",
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"name\":\"Reveal\",\"delay\":0,\"duration\":0.25,\"params\":{\"direction\":\"right\",\"modeIds\":[\"comp-iwknyt0s-mode-iwknyt1a1\"]},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"Conceal\",\"delay\":0,\"duration\":0.25,\"params\":{\"direction\":\"right\",\"modeIds\":[\"comp-iwknyt0s-mode-iwknyt1a1\"]},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                }
            }
        ],
        "modes": {
            "definitions": [
                {
                    "modeId": "comp-iwknyt0s-mode-iwknyt1a",
                    "type": "DEFAULT",
                    "label": "MODE_LABEL_FROM_PRESET",
                    "params": ""
                },
                {
                    "modeId": "comp-iwknyt0s-mode-iwknyt1a1",
                    "type": "HOVER",
                    "label": "MODE_LABEL_FROM_PRESET",
                    "params": ""
                }
            ],
            "overrides": [],
            "isHiddenByModes": false
        },
        "layout": {
            "width": 980,
            "height": 600,
            "x": 0,
            "y": 0,
            "scale": 1,
            "rotationInDegrees": 0,
            "fixedPosition": false
        },
        "componentType": "wysiwyg.viewer.components.HoverBox",
        "props": {
            "type": "HoverBoxProperties",
            "metaData": {
                "schemaVersion": "1.0"
            },
            "mobileDisplayedModeId": "comp-iwknyt0s-mode-iwknyt1a"
        },
        "design": {
            "type": "MediaContainerDesignData",
            "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false
            },
            "background": {
                "type": "BackgroundMedia",
                "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false
                },
                "mediaRef": {
                    "type": "Image",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "title": "Ingredients_F.jpg",
                    "uri": "035244_6ebb274489524b648def32ab5e6c3a3c~mv2_d_5632_3748_s_4_2.jpg",
                    "description": "private/",
                    "width": 5632,
                    "height": 3748,
                    "alt": "",
                    "artist": {
                        "id": "",
                        "name": ""
                    }
                },
                "color": "#000000",
                "colorOpacity": 1,
                "alignType": "center",
                "fittingType": "fill",
                "scrollType": "none",
                "colorOverlay": "",
                "colorOverlayOpacity": 0,
                "showOverlayForMediaType": "WixVideo",
                "mediaTransforms": {
                    "scale": 1
                }
            },
            "cssStyle": {},
            "themeMappings": {},
            "charas": "design-iwc2iye2",
            "dataChangeBehaviors": []
        },
        "style": {
            "type": "TopLevelStyle",
            "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false
            },
            "style": {
                "properties": {
                    "alpha-bg": "1",
                    "alpha-brd": "1",
                    "bg": "206,224,193,1",
                    "boxShadowToggleOn-shd": "false",
                    "brd": "#324158",
                    "brw": "0px",
                    "rd": "0px",
                    "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                },
                "propertiesSource": {
                    "bg": "value",
                    "brd": "value",
                    "brw": "value",
                    "rd": "value",
                    "shd": "value"
                },
                "groups": {}
            },
            "componentClassName": "wysiwyg.viewer.components.HoverBox",
            "pageId": "",
            "compId": "",
            "styleType": "custom",
            "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer"
        },
        "activeModes": {}
    },
    "preset": {
        "rect": {
            "width": 324,
            "height": 225,
            "x": -1,
            "y": 2898
        }
    }
}
const hbPreset22 = {
    "id": "hoverBoxPreset22",
    "structure": {
        "type": "Container",
        "components": [
            {
                "type": "Component",
                "modes": {
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwasn0gb-mode-iwasn0h81"
                            ],
                            "layout": {
                                "width": 268,
                                "height": 33,
                                "x": 8,
                                "y": 93,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            }
                        },
                        {
                            "modeIds": [
                                "comp-iwasn0gb-mode-iwasn0h8"
                            ],
                            "layout": {
                                "width": 268,
                                "height": 32,
                                "x": 8,
                                "y": 134,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            }
                        }
                    ],
                    "isHiddenByModes": false
                },
                "layout": {
                    "width": 268,
                    "height": 33,
                    "x": 8,
                    "y": 134,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "text": "<h1 class=\"font_0\" style=\"font-size: 22px; text-align: center;\"><span style=\"font-size:22px;\"><span style=\"color:#FFFFFF\"><span style=\"font-family:lulo-clean-w01-one-bold,sans-serif\">follow US</span></span></span></h1>\n",
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": []
                },
                "props": {
                    "type": "WRichTextProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "brightness": 1,
                    "packed": true
                },
                "style": "txtNew",
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"action\":\"modeIn\",\"name\":\"FadeIn\",\"delay\":0,\"duration\":0.35},{\"action\":\"modeOut\",\"name\":\"FadeOut\",\"delay\":0,\"duration\":0.35},{\"action\":\"modeChange\",\"name\":\"ModesMotion\",\"delay\":0,\"duration\":0.25,\"params\":{\"timingFunction\":\"EaseInOut\",\"modeIds\":[\"comp-iwasn0gb-mode-iwasn0h81\"]},\"targetId\":\"\"}]"
                },
                "mobileStructure": {
                    "type": "Component",
                    "layout": {
                        "width": 260,
                        "height": 33,
                        "x": 10,
                        "y": 90,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.WRichText",
                    "data": {
                        "type": "StyledText",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "text": "<h1 class=\"font_0\" style=\"font-size: 22px; text-align: center;\"><span style=\"font-size:22px;\"><span style=\"color:#FFFFFF\"><span style=\"font-family:lulo-clean-w01-one-bold,sans-serif\">follow US</span></span></span></h1>\n",
                        "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                        "linkList": []
                    },
                    "props": {
                        "type": "WRichTextProperties",
                        "metaData": {
                            "schemaVersion": "1.0",
                            "autoGenerated": false
                        },
                        "brightness": 1,
                        "packed": true
                    },
                    "style": "txtNew",
                    "behaviors": {
                        "type": "ObsoleteBehaviorsList",
                        "items": "[{\"action\":\"modeIn\",\"name\":\"FadeIn\",\"delay\":0,\"duration\":0.35},{\"action\":\"modeOut\",\"name\":\"FadeOut\",\"delay\":0,\"duration\":0.35},{\"action\":\"modeChange\",\"name\":\"ModesMotion\",\"delay\":0,\"duration\":0.25,\"params\":{\"timingFunction\":\"EaseInOut\",\"modeIds\":[\"comp-iwasn0gb-mode-iwasn0h81\"]},\"targetId\":\"\"}]"
                    }
                }
            },
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwasn0gb-mode-iwasn0h81"
                            ],
                            "layout": {
                                "width": 156,
                                "height": 30,
                                "x": 60,
                                "y": 171,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "isHiddenByModes": false,
                            "style": "lb1",
                            "props": {
                                "type": "LinkBarProperties",
                                "metaData": {
                                    "schemaVersion": "1.0"
                                },
                                "gallery": "social_icons",
                                "iconSize": 30,
                                "spacing": 12,
                                "bgScale": 1,
                                "orientation": "HORIZ"
                            }
                        }
                    ],
                    "isHiddenByModes": true
                },
                "skin": "wysiwyg.viewer.skins.LinkBarNoBGSkin",
                "layout": {
                    "width": 189,
                    "height": 42,
                    "x": 54,
                    "y": 154,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.LinkBar",
                "data": {
                    "type": "ImageList",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "items": [
                        {
                            "type": "Image",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "2.0",
                                "isHidden": false
                            },
                            "title": "White Facebook Icon",
                            "uri": "23fd2a2be53141ed810f4d3dcdcd01fa.png",
                            "description": "",
                            "width": 200,
                            "height": 200,
                            "alt": "",
                            "link": {
                                "type": "ExternalLink",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "url": "http://www.facebook.com/wix",
                                "target": "_blank"
                            }
                        },
                        {
                            "type": "Image",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "2.0",
                                "isHidden": false
                            },
                            "title": "White Twitter Icon",
                            "uri": "01ab6619093f45388d66736ec22e5885.png",
                            "description": "",
                            "width": 200,
                            "height": 200,
                            "alt": "",
                            "link": {
                                "type": "ExternalLink",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "url": "http://www.twitter.com/wix",
                                "target": "_blank"
                            }
                        },
                        {
                            "type": "Image",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "1.0",
                                "isHidden": false
                            },
                            "title": "White Pinterest Icon",
                            "uri": "9c486556465843c5850fabfd68dfae49.png",
                            "description": "",
                            "width": 200,
                            "height": 200,
                            "alt": "",
                            "link": {
                                "type": "ExternalLink",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "url": "https://www.pinterest.com/wixcom/",
                                "target": "_blank"
                            }
                        },
                        {
                            "type": "Image",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "1.0",
                                "isHidden": false
                            },
                            "title": "White Instagram Icon",
                            "uri": "81af6121f84c41a5b4391d7d37fce12a.png",
                            "description": "",
                            "width": 200,
                            "height": 200,
                            "alt": "",
                            "link": {
                                "type": "ExternalLink",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "url": "https://instagram.com/wix/",
                                "target": "_blank"
                            }
                        }
                    ]
                },
                "props": {
                    "type": "LinkBarProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "gallery": "social_icons",
                    "iconSize": 30,
                    "spacing": 10,
                    "bgScale": 1,
                    "orientation": "HORIZ"
                },
                "style": "lb1",
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"name\":\"FloatIn\",\"delay\":0,\"duration\":0.25,\"params\":{\"direction\":\"top\",\"modeIds\":[\"comp-iwasn0gb-mode-iwasn0h81\"]},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"FloatOut\",\"delay\":0,\"duration\":0.25,\"params\":{\"direction\":\"top\",\"modeIds\":[\"comp-iwasn0gb-mode-iwasn0h81\"]},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                },
                "mobileStructure": {
                    "type": "Component",
                    "modes": {
                        "definitions": []
                    },
                    "skin": "wysiwyg.viewer.skins.LinkBarNoBGSkin",
                    "layout": {
                        "width": 185,
                        "height": 35,
                        "x": 47,
                        "y": 158,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.LinkBar",
                    "data": {
                        "type": "ImageList",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "items": [
                            {
                                "type": "Image",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "2.0",
                                    "isHidden": false
                                },
                                "title": "White Facebook Icon",
                                "uri": "23fd2a2be53141ed810f4d3dcdcd01fa.png",
                                "description": "",
                                "width": 200,
                                "height": 200,
                                "alt": "",
                                "link": {
                                    "type": "ExternalLink",
                                    "metaData": {
                                        "isPreset": false,
                                        "schemaVersion": "1.0",
                                        "isHidden": false
                                    },
                                    "url": "http://www.facebook.com/wix",
                                    "target": "_blank"
                                }
                            },
                            {
                                "type": "Image",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "2.0",
                                    "isHidden": false
                                },
                                "title": "White Twitter Icon",
                                "uri": "01ab6619093f45388d66736ec22e5885.png",
                                "description": "",
                                "width": 200,
                                "height": 200,
                                "alt": "",
                                "link": {
                                    "type": "ExternalLink",
                                    "metaData": {
                                        "isPreset": false,
                                        "schemaVersion": "1.0",
                                        "isHidden": false
                                    },
                                    "url": "http://www.twitter.com/wix",
                                    "target": "_blank"
                                }
                            },
                            {
                                "type": "Image",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "title": "White Pinterest Icon",
                                "uri": "9c486556465843c5850fabfd68dfae49.png",
                                "description": "",
                                "width": 200,
                                "height": 200,
                                "alt": "",
                                "link": {
                                    "type": "ExternalLink",
                                    "metaData": {
                                        "isPreset": false,
                                        "schemaVersion": "1.0",
                                        "isHidden": false
                                    },
                                    "url": "https://www.pinterest.com/wixcom/",
                                    "target": "_blank"
                                }
                            },
                            {
                                "type": "Image",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "title": "White Instagram Icon",
                                "uri": "81af6121f84c41a5b4391d7d37fce12a.png",
                                "description": "",
                                "width": 200,
                                "height": 200,
                                "alt": "",
                                "link": {
                                    "type": "ExternalLink",
                                    "metaData": {
                                        "isPreset": false,
                                        "schemaVersion": "1.0",
                                        "isHidden": false
                                    },
                                    "url": "https://instagram.com/wix/",
                                    "target": "_blank"
                                }
                            }
                        ]
                    },
                    "props": {
                        "type": "LinkBarProperties",
                        "metaData": {
                            "schemaVersion": "1.0",
                            "autoGenerated": true
                        },
                        "gallery": "social_icons",
                        "iconSize": 35,
                        "spacing": 15,
                        "bgScale": 1,
                        "orientation": "HORIZ"
                    },
                    "style": "lb1",
                    "behaviors": {
                        "type": "ObsoleteBehaviorsList",
                        "items": "[{\"name\":\"FloatIn\",\"delay\":0,\"duration\":0.25,\"params\":{\"direction\":\"top\",\"modeIds\":[\"comp-iwasn0gb-mode-iwasn0h81\"]},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"FloatOut\",\"delay\":0,\"duration\":0.25,\"params\":{\"direction\":\"top\",\"modeIds\":[\"comp-iwasn0gb-mode-iwasn0h81\"]},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                    }
                }
            },
            {
                "type": "Component",
                "modes": {
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwasn0gb-mode-iwasn0h81"
                            ],
                            "layout": {
                                "width": 224,
                                "height": 5,
                                "x": 30,
                                "y": 146,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "isHiddenByModes": false,
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "style": {
                                    "properties": {
                                        "alpha-brd": "1",
                                        "brd": "#FFFFFF",
                                        "lnw": "1px"
                                    },
                                    "propertiesSource": {
                                        "brd": "value",
                                        "lnw": "value"
                                    },
                                    "groups": {}
                                },
                                "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.line.SolidLine"
                            },
                            "props": {
                                "type": "FiveGridLineProperties",
                                "metaData": {
                                    "schemaVersion": "1.0"
                                },
                                "fullScreenModeOn": false
                            }
                        }
                    ],
                    "isHiddenByModes": true
                },
                "layout": {
                    "width": 216,
                    "height": 5,
                    "x": 34,
                    "y": 100,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.FiveGridLine",
                "props": {
                    "type": "FiveGridLineProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "fullScreenModeOn": false
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "style": {
                        "properties": {
                            "alpha-brd": "1",
                            "brd": "#FFFFFF",
                            "lnw": "1px"
                        },
                        "propertiesSource": {
                            "brd": "value",
                            "lnw": "value"
                        },
                        "groups": {}
                    },
                    "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.line.SolidLine"
                },
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"name\":\"ExpandIn\",\"delay\":0,\"duration\":0.2,\"params\":{\"direction\":\"right\",\"modeIds\":[\"comp-iwasn0gb-mode-iwasn0h81\"]},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"CollapseOut\",\"delay\":0,\"duration\":0.2,\"params\":{\"direction\":\"right\",\"modeIds\":[\"comp-iwasn0gb-mode-iwasn0h81\"]},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                },
                "mobileStructure": {
                    "type": "Component",
                    "layout": {
                        "width": 240,
                        "height": 5,
                        "x": 20,
                        "y": 140,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.FiveGridLine",
                    "props": {
                        "type": "FiveGridLineProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "fullScreenModeOn": false
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "properties": {
                                "alpha-brd": "1",
                                "brd": "#FFFFFF",
                                "lnw": "1px"
                            },
                            "propertiesSource": {
                                "brd": "value",
                                "lnw": "value"
                            },
                            "groups": {}
                        },
                        "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.line.SolidLine"
                    },
                    "behaviors": {
                        "type": "ObsoleteBehaviorsList",
                        "items": "[{\"name\":\"ExpandIn\",\"delay\":0,\"duration\":0.2,\"params\":{\"direction\":\"right\",\"modeIds\":[\"comp-iwasn0gb-mode-iwasn0h81\"]},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"CollapseOut\",\"delay\":0,\"duration\":0.2,\"params\":{\"direction\":\"right\",\"modeIds\":[\"comp-iwasn0gb-mode-iwasn0h81\"]},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                    }
                }
            }
        ],
        "modes": {
            "definitions": [
                {
                    "modeId": "comp-iwasn0gb-mode-iwasn0h8",
                    "type": "DEFAULT",
                    "label": "MODE_LABEL_FROM_PRESET",
                    "params": ""
                },
                {
                    "modeId": "comp-iwasn0gb-mode-iwasn0h81",
                    "type": "HOVER",
                    "label": "MODE_LABEL_FROM_PRESET",
                    "params": ""
                }
            ],
            "overrides": [
                {
                    "modeIds": [
                        "comp-iwasn0gb-mode-iwasn0h81"
                    ],
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "1.0",
                                "isHidden": false
                            },
                            "mediaRef": {
                                "type": "WixVideo",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "title": "Office",
                                "videoId": "video/11062b_0fcc5b5d1613468aad7802063185e9d1",
                                "qualities": [
                                    {
                                        "quality": "480p",
                                        "width": 854,
                                        "height": 480,
                                        "formats": [
                                            "mp4",
                                            "webm"
                                        ]
                                    },
                                    {
                                        "quality": "720p",
                                        "width": 1280,
                                        "height": 720,
                                        "formats": [
                                            "mp4",
                                            "webm"
                                        ]
                                    },
                                    {
                                        "quality": "1080p",
                                        "width": 1920,
                                        "height": 1080,
                                        "formats": [
                                            "mp4",
                                            "webm"
                                        ]
                                    }
                                ],
                                "posterImageRef": {
                                    "type": "Image",
                                    "metaData": {
                                        "isPreset": false,
                                        "schemaVersion": "1.0",
                                        "isHidden": false
                                    },
                                    "title": "",
                                    "uri": "11062b_0fcc5b5d1613468aad7802063185e9d1f000.jpg",
                                    "description": "public/8e256233-1752-4026-9341-54036e7f875e/d6b6b972-952c-4ab0-b46a-a6c05a0a94a5",
                                    "width": 1920,
                                    "height": 1080,
                                    "alt": ""
                                },
                                "opacity": 1,
                                "duration": 12,
                                "loop": true,
                                "autoplay": true,
                                "preload": "auto",
                                "mute": true,
                                "artist": {
                                    "id": "",
                                    "name": ""
                                }
                            },
                            "color": "#5ADBA6",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "#0751E0",
                            "colorOverlayOpacity": 1,
                            "showOverlayForMediaType": "WixVideo"
                        },
                        "cssStyle": {
                            "cssBorder": {
                                "width": {
                                    "top": {
                                        "value": 0,
                                        "unit": "px"
                                    },
                                    "right": {
                                        "value": 0,
                                        "unit": "px"
                                    },
                                    "bottom": {
                                        "value": 0,
                                        "unit": "px"
                                    },
                                    "left": {
                                        "value": 0,
                                        "unit": "px"
                                    }
                                },
                                "style": {
                                    "top": "solid",
                                    "right": "solid",
                                    "left": "solid",
                                    "bottom": "solid"
                                },
                                "color": {
                                    "top": {
                                        "red": 0,
                                        "green": 0,
                                        "blue": 0,
                                        "alpha": 1
                                    },
                                    "right": {
                                        "red": 0,
                                        "green": 0,
                                        "blue": 0,
                                        "alpha": 1
                                    },
                                    "left": {
                                        "red": 0,
                                        "green": 0,
                                        "blue": 0,
                                        "alpha": 1
                                    },
                                    "bottom": {
                                        "red": 0,
                                        "green": 0,
                                        "blue": 0,
                                        "alpha": 1
                                    }
                                }
                            },
                            "cssBorderRadius": {
                                "topLeft": {
                                    "value": 600,
                                    "unit": "px"
                                },
                                "topRight": {
                                    "value": 600,
                                    "unit": "px"
                                },
                                "bottomLeft": {
                                    "value": 600,
                                    "unit": "px"
                                },
                                "bottomRight": {
                                    "value": 600,
                                    "unit": "px"
                                }
                            }
                        },
                        "themeMappings": {},
                        "charas": "design-iw3n3qi6"
                    }
                }
            ],
            "isHiddenByModes": false
        },
        "layout": {
            "width": 284,
            "height": 284,
            "x": 0,
            "y": 0,
            "scale": 1,
            "rotationInDegrees": 0,
            "fixedPosition": false
        },
        "componentType": "wysiwyg.viewer.components.HoverBox",
        "props": {
            "type": "HoverBoxProperties",
            "metaData": {
                "schemaVersion": "1.0"
            },
            "mobileDisplayedModeId": "comp-iwasn0gb-mode-iwasn0h81"
        },
        "design": {
            "type": "MediaContainerDesignData",
            "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false
            },
            "background": {
                "type": "BackgroundMedia",
                "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false
                },
                "mediaRef": {
                    "type": "WixVideo",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "title": "Office",
                    "videoId": "video/11062b_0fcc5b5d1613468aad7802063185e9d1",
                    "qualities": [
                        {
                            "quality": "480p",
                            "width": 854,
                            "height": 480,
                            "formats": [
                                "mp4",
                                "webm"
                            ]
                        },
                        {
                            "quality": "720p",
                            "width": 1280,
                            "height": 720,
                            "formats": [
                                "mp4",
                                "webm"
                            ]
                        },
                        {
                            "quality": "1080p",
                            "width": 1920,
                            "height": 1080,
                            "formats": [
                                "mp4",
                                "webm"
                            ]
                        }
                    ],
                    "posterImageRef": {
                        "type": "Image",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "title": "",
                        "uri": "11062b_0fcc5b5d1613468aad7802063185e9d1f000.jpg",
                        "description": "public/8e256233-1752-4026-9341-54036e7f875e/d6b6b972-952c-4ab0-b46a-a6c05a0a94a5",
                        "width": 1920,
                        "height": 1080,
                        "alt": ""
                    },
                    "opacity": 1,
                    "duration": 12,
                    "loop": true,
                    "autoplay": true,
                    "preload": "auto",
                    "mute": true,
                    "artist": {
                        "id": "",
                        "name": ""
                    }
                },
                "color": "#5ADBA6",
                "colorOpacity": 1,
                "alignType": "center",
                "fittingType": "fill",
                "scrollType": "none",
                "colorOverlay": "",
                "colorOverlayOpacity": 0,
                "showOverlayForMediaType": "all"
            },
            "cssStyle": {
                "cssBorder": {
                    "width": {
                        "top": {
                            "value": 0,
                            "unit": "px"
                        },
                        "right": {
                            "value": 0,
                            "unit": "px"
                        },
                        "bottom": {
                            "value": 0,
                            "unit": "px"
                        },
                        "left": {
                            "value": 0,
                            "unit": "px"
                        }
                    },
                    "style": {
                        "top": "solid",
                        "right": "solid",
                        "left": "solid",
                        "bottom": "solid"
                    },
                    "color": {
                        "top": {
                            "red": 0,
                            "green": 0,
                            "blue": 0,
                            "alpha": 1
                        },
                        "right": {
                            "red": 0,
                            "green": 0,
                            "blue": 0,
                            "alpha": 1
                        },
                        "left": {
                            "red": 0,
                            "green": 0,
                            "blue": 0,
                            "alpha": 1
                        },
                        "bottom": {
                            "red": 0,
                            "green": 0,
                            "blue": 0,
                            "alpha": 1
                        }
                    }
                },
                "cssBorderRadius": {
                    "topLeft": {
                        "value": 600,
                        "unit": "px"
                    },
                    "topRight": {
                        "value": 600,
                        "unit": "px"
                    },
                    "bottomLeft": {
                        "value": 600,
                        "unit": "px"
                    },
                    "bottomRight": {
                        "value": 600,
                        "unit": "px"
                    }
                }
            },
            "themeMappings": {},
            "charas": "design-ivqwabeu"
        },
        "style": {
            "type": "TopLevelStyle",
            "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false
            },
            "style": {
                "properties": {
                    "alpha-bg": "1",
                    "alpha-brd": "1",
                    "bg": "206,224,193,1",
                    "boxShadowToggleOn-shd": "false",
                    "brd": "#324158",
                    "brw": "0px",
                    "rd": "0px",
                    "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                },
                "propertiesSource": {
                    "bg": "value",
                    "brd": "value",
                    "brw": "value",
                    "rd": "value",
                    "shd": "value"
                },
                "groups": {}
            },
            "componentClassName": "wysiwyg.viewer.components.HoverBox",
            "pageId": "",
            "compId": "",
            "styleType": "custom",
            "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer"
        },
        "mobileStructure": {
            "type": "Container",
            "modes": {
                "definitions": [
                    {
                        "modeId": "comp-iwasn0gb-mode-iwasn0h8",
                        "type": "DEFAULT",
                        "label": "MODE_LABEL_FROM_PRESET",
                        "params": ""
                    },
                    {
                        "modeId": "comp-iwasn0gb-mode-iwasn0h81",
                        "type": "HOVER",
                        "label": "MODE_LABEL_FROM_PRESET",
                        "params": ""
                    }
                ]
            },
            "layout": {
                "width": 280,
                "height": 280,
                "x": 20,
                "y": 0,
                "scale": 1,
                "rotationInDegrees": 0,
                "fixedPosition": false
            },
            "componentType": "wysiwyg.viewer.components.HoverBox",
            "props": {
                "type": "HoverBoxProperties",
                "metaData": {
                    "schemaVersion": "1.0"
                },
                "mobileDisplayedModeId": "comp-iwasn0gb-mode-iwasn0h81"
            },
            "design": {
                "type": "MediaContainerDesignData",
                "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false
                },
                "background": {
                    "type": "BackgroundMedia",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "mediaRef": {
                        "type": "WixVideo",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "title": "Office",
                        "videoId": "video/11062b_0fcc5b5d1613468aad7802063185e9d1",
                        "qualities": [
                            {
                                "quality": "480p",
                                "width": 854,
                                "height": 480,
                                "formats": [
                                    "mp4",
                                    "webm"
                                ]
                            },
                            {
                                "quality": "720p",
                                "width": 1280,
                                "height": 720,
                                "formats": [
                                    "mp4",
                                    "webm"
                                ]
                            },
                            {
                                "quality": "1080p",
                                "width": 1920,
                                "height": 1080,
                                "formats": [
                                    "mp4",
                                    "webm"
                                ]
                            }
                        ],
                        "posterImageRef": {
                            "type": "Image",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "1.0",
                                "isHidden": false
                            },
                            "title": "",
                            "uri": "11062b_0fcc5b5d1613468aad7802063185e9d1f000.jpg",
                            "description": "public/8e256233-1752-4026-9341-54036e7f875e/d6b6b972-952c-4ab0-b46a-a6c05a0a94a5",
                            "width": 1920,
                            "height": 1080,
                            "alt": ""
                        },
                        "opacity": 1,
                        "duration": 12,
                        "loop": true,
                        "autoplay": true,
                        "preload": "auto",
                        "mute": true,
                        "artist": {
                            "id": "",
                            "name": ""
                        }
                    },
                    "color": "#5ADBA6",
                    "colorOpacity": 1,
                    "alignType": "center",
                    "fittingType": "fill",
                    "scrollType": "none",
                    "colorOverlay": "#0751E0",
                    "colorOverlayOpacity": 1,
                    "showOverlayForMediaType": "WixVideo"
                },
                "cssStyle": {
                    "cssBorder": {
                        "width": {
                            "top": {
                                "value": 0,
                                "unit": "px"
                            },
                            "right": {
                                "value": 0,
                                "unit": "px"
                            },
                            "bottom": {
                                "value": 0,
                                "unit": "px"
                            },
                            "left": {
                                "value": 0,
                                "unit": "px"
                            }
                        },
                        "style": {
                            "top": "solid",
                            "right": "solid",
                            "left": "solid",
                            "bottom": "solid"
                        },
                        "color": {
                            "top": {
                                "red": 0,
                                "green": 0,
                                "blue": 0,
                                "alpha": 1
                            },
                            "right": {
                                "red": 0,
                                "green": 0,
                                "blue": 0,
                                "alpha": 1
                            },
                            "left": {
                                "red": 0,
                                "green": 0,
                                "blue": 0,
                                "alpha": 1
                            },
                            "bottom": {
                                "red": 0,
                                "green": 0,
                                "blue": 0,
                                "alpha": 1
                            }
                        }
                    },
                    "cssBorderRadius": {
                        "topLeft": {
                            "value": 600,
                            "unit": "px"
                        },
                        "topRight": {
                            "value": 600,
                            "unit": "px"
                        },
                        "bottomLeft": {
                            "value": 600,
                            "unit": "px"
                        },
                        "bottomRight": {
                            "value": 600,
                            "unit": "px"
                        }
                    }
                },
                "themeMappings": {},
                "charas": "design-iw3n3qi6"
            },
            "style": {
                "type": "TopLevelStyle",
                "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false
                },
                "style": {
                    "properties": {
                        "alpha-bg": "1",
                        "alpha-brd": "1",
                        "bg": "206,224,193,1",
                        "boxShadowToggleOn-shd": "false",
                        "brd": "#324158",
                        "brw": "0px",
                        "rd": "0px",
                        "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                    },
                    "propertiesSource": {
                        "bg": "value",
                        "brd": "value",
                        "brw": "value",
                        "rd": "value",
                        "shd": "value"
                    },
                    "groups": {}
                },
                "componentClassName": "wysiwyg.viewer.components.HoverBox",
                "pageId": "",
                "compId": "",
                "styleType": "custom",
                "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer"
            }
        },
        "activeModes": {}
    },
    "preset": {
        "rect": {
            "width": 162,
            "height": 181,
            "x": -1,
            "y": 3123
        }
    }
}
const hbPreset23 = {
    "id": "hoverBoxPreset23",
    "structure": {
        "type": "Container",
        "components": [
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwasnlkk-mode-iwasnloi1"
                            ],
                            "layout": {
                                "width": 150,
                                "height": 150,
                                "x": 67,
                                "y": 67,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "isHiddenByModes": false,
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "style": {
                                    "properties": {
                                        "alpha-fillcolor": "1",
                                        "alpha-stroke": "1",
                                        "fillcolor": "#2C2E54",
                                        "stroke": "#DBC067",
                                        "strokewidth": "0px"
                                    },
                                    "propertiesSource": {
                                        "alpha-stroke": "value",
                                        "fillcolor": "value",
                                        "stroke": "value",
                                        "strokewidth": "value"
                                    },
                                    "groups": {}
                                },
                                "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "svgshape.v1.svg_8463f60718194af748c49dddbe45b668.HollowCircle"
                            }
                        }
                    ],
                    "isHiddenByModes": true
                },
                "skin": "svgshape.v1.svg_8463f60718194af748c49dddbe45b668.HollowCircle",
                "layout": {
                    "width": 102,
                    "height": 102,
                    "x": 93,
                    "y": 102,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.svgshape.SvgShape",
                "data": {
                    "type": "SvgShape",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    }
                },
                "props": {
                    "type": "SvgShapeProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "maintainAspectRatio": false
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "style": {
                        "properties": {
                            "alpha-fillcolor": "1",
                            "alpha-stroke": "1",
                            "fillcolor": "#002E5D",
                            "stroke": "#ED1566",
                            "strokewidth": "0px"
                        },
                        "propertiesSource": {
                            "fillcolor": "value",
                            "stroke": "value",
                            "strokewidth": "value"
                        },
                        "groups": {}
                    },
                    "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "svgshape.v1.svg_8463f60718194af748c49dddbe45b668.HollowCircle"
                },
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"name\":\"FadeIn\",\"delay\":0,\"duration\":0.2,\"action\":\"modeIn\",\"params\":{\"modeIds\":[\"comp-iwasnlkk-mode-iwasnloi1\"]},\"targetId\":\"\"},{\"name\":\"FadeOut\",\"delay\":0,\"duration\":0.2,\"action\":\"modeOut\",\"params\":{\"modeIds\":[\"comp-iwasnlkk-mode-iwasnloi1\"]},\"targetId\":\"\"}]"
                },
                "mobileStructure": {
                    "type": "Component",
                    "modes": {
                        "definitions": []
                    },
                    "skin": "svgshape.v1.svg_8463f60718194af748c49dddbe45b668.HollowCircle",
                    "layout": {
                        "width": 160,
                        "height": 160,
                        "x": 60,
                        "y": 60,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.svgshape.SvgShape",
                    "data": {
                        "type": "SvgShape",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        }
                    },
                    "props": {
                        "type": "SvgShapeProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "maintainAspectRatio": false
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "properties": {
                                "alpha-fillcolor": "1",
                                "alpha-stroke": "1",
                                "fillcolor": "#2C2E54",
                                "stroke": "#DBC067",
                                "strokewidth": "0px"
                            },
                            "propertiesSource": {
                                "alpha-stroke": "value",
                                "fillcolor": "value",
                                "stroke": "value",
                                "strokewidth": "value"
                            },
                            "groups": {}
                        },
                        "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "svgshape.v1.svg_8463f60718194af748c49dddbe45b668.HollowCircle"
                    },
                    "behaviors": {
                        "type": "ObsoleteBehaviorsList",
                        "items": "[{\"name\":\"FadeIn\",\"delay\":0,\"duration\":0.2,\"action\":\"modeIn\",\"params\":{\"modeIds\":[\"comp-iwasnlkk-mode-iwasnloi1\"]},\"targetId\":\"\"},{\"name\":\"FadeOut\",\"delay\":0,\"duration\":0.2,\"action\":\"modeOut\",\"params\":{\"modeIds\":[\"comp-iwasnlkk-mode-iwasnloi1\"]},\"targetId\":\"\"}]"
                    }
                }
            },
            {
                "type": "Component",
                "modes": {
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwasnlkk-mode-iwasnloi1"
                            ],
                            "layout": {
                                "width": 119,
                                "height": 58,
                                "x": 82,
                                "y": 114,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "isHiddenByModes": false,
                            "style": "txtNew",
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {
                                    "schemaVersion": "1.0"
                                },
                                "brightness": 1,
                                "packed": true
                            }
                        }
                    ],
                    "isHiddenByModes": true
                },
                "layout": {
                    "width": 119,
                    "height": 58,
                    "x": 82,
                    "y": 113,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "text": "<h1 class=\"font_0\" style=\"font-size:22px; text-align:center\"><span style=\"color:#FFFFFF\"><span style=\"font-size:22px\"><span style=\"letter-spacing:0.1em\"><span style=\"font-family:futura-lt-w01-light,sans-serif\">BUY<br />\nNOW</span></span></span></span></h1>\n",
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": []
                },
                "props": {
                    "type": "WRichTextProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "brightness": 1,
                    "packed": true
                },
                "style": "txtNew",
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"name\":\"FadeIn\",\"delay\":0,\"duration\":0.2,\"action\":\"modeIn\",\"params\":{\"modeIds\":[\"comp-iwasnlkk-mode-iwasnloi1\"]},\"targetId\":\"\"},{\"name\":\"FadeOut\",\"delay\":0,\"duration\":0.2,\"action\":\"modeOut\",\"params\":{\"modeIds\":[\"comp-iwasnlkk-mode-iwasnloi1\"]},\"targetId\":\"\"}]"
                },
                "mobileStructure": {
                    "type": "Component",
                    "layout": {
                        "width": 118,
                        "height": 80,
                        "x": 81,
                        "y": 100,
                        "scale": 1.36,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.WRichText",
                    "data": {
                        "type": "StyledText",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "text": "<h1 class=\"font_0\" style=\"font-size:22px; text-align:center\"><span style=\"color:#FFFFFF\"><span style=\"font-size:22px\"><span style=\"letter-spacing:0.1em\"><span style=\"font-family:futura-lt-w01-light,sans-serif\">BUY<br />\nNOW</span></span></span></span></h1>\n",
                        "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                        "linkList": []
                    },
                    "props": {
                        "type": "WRichTextProperties",
                        "metaData": {
                            "schemaVersion": "1.0",
                            "autoGenerated": false
                        },
                        "brightness": 1,
                        "packed": true
                    },
                    "style": "txtNew",
                    "behaviors": {
                        "type": "ObsoleteBehaviorsList",
                        "items": "[{\"name\":\"FadeIn\",\"delay\":0,\"duration\":0.2,\"action\":\"modeIn\",\"params\":{\"modeIds\":[\"comp-iwasnlkk-mode-iwasnloi1\"]},\"targetId\":\"\"},{\"name\":\"FadeOut\",\"delay\":0,\"duration\":0.2,\"action\":\"modeOut\",\"params\":{\"modeIds\":[\"comp-iwasnlkk-mode-iwasnloi1\"]},\"targetId\":\"\"}]"
                    }
                }
            }
        ],
        "modes": {
            "definitions": [
                {
                    "modeId": "comp-iwasnlkk-mode-iwasnloi",
                    "type": "DEFAULT",
                    "label": "MODE_LABEL_FROM_PRESET",
                    "params": ""
                },
                {
                    "modeId": "comp-iwasnlkk-mode-iwasnloi1",
                    "type": "HOVER",
                    "label": "MODE_LABEL_FROM_PRESET",
                    "params": ""
                }
            ],
            "overrides": [
                {
                    "modeIds": [
                        "comp-iwasnlkk-mode-iwasnloi1"
                    ],
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "1.0",
                                "isHidden": false
                            },
                            "mediaRef": {
                                "type": "Image",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "title": "fabric1.1.jpg",
                                "uri": "035244_668d963a59ca40ae9617b75ad504f683~mv2.jpg",
                                "description": "private/allMedia_picture",
                                "width": 600,
                                "height": 600,
                                "alt": "",
                                "artist": {
                                    "id": "mobile_designItem_ivrqhksz2",
                                    "name": ""
                                }
                            },
                            "color": "#243C6B",
                            "colorOpacity": 0,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0,
                            "showOverlayForMediaType": "all",
                            "mediaTransforms": {
                                "scale": 1.1
                            }
                        },
                        "cssStyle": {
                            "cssBorder": {
                                "width": {
                                    "top": {
                                        "value": 0,
                                        "unit": "px"
                                    },
                                    "right": {
                                        "value": 0,
                                        "unit": "px"
                                    },
                                    "bottom": {
                                        "value": 0,
                                        "unit": "px"
                                    },
                                    "left": {
                                        "value": 0,
                                        "unit": "px"
                                    }
                                },
                                "style": {
                                    "top": "solid",
                                    "right": "solid",
                                    "left": "solid",
                                    "bottom": "solid"
                                },
                                "color": {
                                    "top": {
                                        "red": 36,
                                        "green": 60,
                                        "blue": 107,
                                        "alpha": 1
                                    },
                                    "right": {
                                        "red": 36,
                                        "green": 60,
                                        "blue": 107,
                                        "alpha": 1
                                    },
                                    "left": {
                                        "red": 36,
                                        "green": 60,
                                        "blue": 107,
                                        "alpha": 1
                                    },
                                    "bottom": {
                                        "red": 36,
                                        "green": 60,
                                        "blue": 107,
                                        "alpha": 1
                                    }
                                }
                            },
                            "cssBorderRadius": {
                                "topLeft": {
                                    "value": 600,
                                    "unit": "px"
                                },
                                "topRight": {
                                    "value": 600,
                                    "unit": "px"
                                },
                                "bottomLeft": {
                                    "value": 600,
                                    "unit": "px"
                                },
                                "bottomRight": {
                                    "value": 600,
                                    "unit": "px"
                                }
                            }
                        },
                        "themeMappings": {},
                        "charas": "design-iwffo23p",
                        "dataChangeBehaviors": [
                            {
                                "trigger": "in",
                                "type": "animation",
                                "part": "media",
                                "name": "Scale",
                                "params": {
                                    "delay": 0,
                                    "duration": 0.5
                                }
                            },
                            {
                                "trigger": "out",
                                "type": "animation",
                                "part": "media",
                                "name": "Scale",
                                "params": {
                                    "delay": 0,
                                    "duration": 0.5
                                }
                            }
                        ]
                    }
                }
            ],
            "isHiddenByModes": false
        },
        "layout": {
            "width": 284,
            "height": 284,
            "x": 0,
            "y": 0,
            "scale": 1,
            "rotationInDegrees": 0,
            "fixedPosition": false
        },
        "componentType": "wysiwyg.viewer.components.HoverBox",
        "props": {
            "type": "HoverBoxProperties",
            "metaData": {
                "schemaVersion": "1.0"
            }
        },
        "design": {
            "type": "MediaContainerDesignData",
            "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false
            },
            "background": {
                "type": "BackgroundMedia",
                "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false
                },
                "mediaRef": {
                    "type": "Image",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "title": "fabric1.1.jpg",
                    "uri": "035244_668d963a59ca40ae9617b75ad504f683~mv2.jpg",
                    "description": "private/allMedia_picture",
                    "width": 600,
                    "height": 600,
                    "alt": "",
                    "artist": {
                        "id": "mobile_designItem_ivrqhksz2",
                        "name": ""
                    }
                },
                "color": "#243C6B",
                "colorOpacity": 0,
                "alignType": "center",
                "fittingType": "fill",
                "scrollType": "none",
                "colorOverlay": "",
                "colorOverlayOpacity": 0,
                "showOverlayForMediaType": "all",
                "mediaTransforms": {
                    "scale": 1
                }
            },
            "cssStyle": {
                "cssBorder": {
                    "width": {
                        "top": {
                            "value": 0,
                            "unit": "px"
                        },
                        "right": {
                            "value": 0,
                            "unit": "px"
                        },
                        "bottom": {
                            "value": 0,
                            "unit": "px"
                        },
                        "left": {
                            "value": 0,
                            "unit": "px"
                        }
                    },
                    "style": {
                        "top": "solid",
                        "right": "solid",
                        "left": "solid",
                        "bottom": "solid"
                    },
                    "color": {
                        "top": {
                            "red": 36,
                            "green": 60,
                            "blue": 107,
                            "alpha": 1
                        },
                        "right": {
                            "red": 36,
                            "green": 60,
                            "blue": 107,
                            "alpha": 1
                        },
                        "left": {
                            "red": 36,
                            "green": 60,
                            "blue": 107,
                            "alpha": 1
                        },
                        "bottom": {
                            "red": 36,
                            "green": 60,
                            "blue": 107,
                            "alpha": 1
                        }
                    }
                },
                "cssBorderRadius": {
                    "topLeft": {
                        "value": 600,
                        "unit": "px"
                    },
                    "topRight": {
                        "value": 600,
                        "unit": "px"
                    },
                    "bottomLeft": {
                        "value": 600,
                        "unit": "px"
                    },
                    "bottomRight": {
                        "value": 600,
                        "unit": "px"
                    }
                }
            },
            "themeMappings": {},
            "charas": "design-iwffnswc",
            "dataChangeBehaviors": []
        },
        "style": {
            "type": "TopLevelStyle",
            "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false
            },
            "style": {
                "properties": {
                    "alpha-bg": "1",
                    "alpha-brd": "1",
                    "bg": "206,224,193,1",
                    "boxShadowToggleOn-shd": "false",
                    "brd": "#324158",
                    "brw": "0px",
                    "rd": "0px",
                    "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                },
                "propertiesSource": {
                    "bg": "value",
                    "brd": "value",
                    "brw": "value",
                    "rd": "value",
                    "shd": "value"
                },
                "groups": {}
            },
            "componentClassName": "wysiwyg.viewer.components.HoverBox",
            "pageId": "",
            "compId": "",
            "styleType": "custom",
            "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer"
        },
        "mobileStructure": {
            "type": "Container",
            "modes": {
                "definitions": [
                    {
                        "modeId": "comp-iwasnlkk-mode-iwasnloi",
                        "type": "DEFAULT",
                        "label": "MODE_LABEL_FROM_PRESET",
                        "params": ""
                    },
                    {
                        "modeId": "comp-iwasnlkk-mode-iwasnloi1",
                        "type": "HOVER",
                        "label": "MODE_LABEL_FROM_PRESET",
                        "params": ""
                    }
                ]
            },
            "layout": {
                "width": 280,
                "height": 280,
                "x": 20,
                "y": 0,
                "scale": 1,
                "rotationInDegrees": 0,
                "fixedPosition": false
            },
            "componentType": "wysiwyg.viewer.components.HoverBox",
            "props": {
                "type": "HoverBoxProperties",
                "metaData": {
                    "schemaVersion": "1.0"
                }
            },
            "design": {
                "type": "MediaContainerDesignData",
                "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false
                },
                "background": {
                    "type": "BackgroundMedia",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "mediaRef": {
                        "type": "Image",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "title": "fabric1.1.jpg",
                        "uri": "035244_668d963a59ca40ae9617b75ad504f683~mv2.jpg",
                        "description": "private/allMedia_picture",
                        "width": 600,
                        "height": 600,
                        "alt": "",
                        "artist": {
                            "id": "mobile_designItem_ivrqhksz2",
                            "name": ""
                        }
                    },
                    "color": "#243C6B",
                    "colorOpacity": 0,
                    "alignType": "center",
                    "fittingType": "fill",
                    "scrollType": "none",
                    "colorOverlay": "",
                    "colorOverlayOpacity": 0,
                    "showOverlayForMediaType": "all",
                    "mediaTransforms": {
                        "scale": 1.1
                    }
                },
                "cssStyle": {
                    "cssBorder": {
                        "width": {
                            "top": {
                                "value": 0,
                                "unit": "px"
                            },
                            "right": {
                                "value": 0,
                                "unit": "px"
                            },
                            "bottom": {
                                "value": 0,
                                "unit": "px"
                            },
                            "left": {
                                "value": 0,
                                "unit": "px"
                            }
                        },
                        "style": {
                            "top": "solid",
                            "right": "solid",
                            "left": "solid",
                            "bottom": "solid"
                        },
                        "color": {
                            "top": {
                                "red": 36,
                                "green": 60,
                                "blue": 107,
                                "alpha": 1
                            },
                            "right": {
                                "red": 36,
                                "green": 60,
                                "blue": 107,
                                "alpha": 1
                            },
                            "left": {
                                "red": 36,
                                "green": 60,
                                "blue": 107,
                                "alpha": 1
                            },
                            "bottom": {
                                "red": 36,
                                "green": 60,
                                "blue": 107,
                                "alpha": 1
                            }
                        }
                    },
                    "cssBorderRadius": {
                        "topLeft": {
                            "value": 600,
                            "unit": "px"
                        },
                        "topRight": {
                            "value": 600,
                            "unit": "px"
                        },
                        "bottomLeft": {
                            "value": 600,
                            "unit": "px"
                        },
                        "bottomRight": {
                            "value": 600,
                            "unit": "px"
                        }
                    }
                },
                "themeMappings": {},
                "charas": "design-iwffo23p",
                "dataChangeBehaviors": [
                    {
                        "trigger": "in",
                        "type": "animation",
                        "part": "media",
                        "name": "Scale",
                        "params": {
                            "delay": 0,
                            "duration": 0.5
                        }
                    },
                    {
                        "trigger": "out",
                        "type": "animation",
                        "part": "media",
                        "name": "Scale",
                        "params": {
                            "delay": 0,
                            "duration": 0.5
                        }
                    }
                ]
            },
            "style": {
                "type": "TopLevelStyle",
                "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false
                },
                "style": {
                    "properties": {
                        "alpha-bg": "1",
                        "alpha-brd": "1",
                        "bg": "206,224,193,1",
                        "boxShadowToggleOn-shd": "false",
                        "brd": "#324158",
                        "brw": "0px",
                        "rd": "0px",
                        "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                    },
                    "propertiesSource": {
                        "bg": "value",
                        "brd": "value",
                        "brw": "value",
                        "rd": "value",
                        "shd": "value"
                    },
                    "groups": {}
                },
                "componentClassName": "wysiwyg.viewer.components.HoverBox",
                "pageId": "",
                "compId": "",
                "styleType": "custom",
                "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer"
            }
        },
        "activeModes": {}
    },
    "preset": {
        "rect": {
            "width": 162,
            "height": 181,
            "x": 161,
            "y": 3123
        }
    }
}
const hbPreset24 = {
    "id": "hoverBoxPreset24",
    "structure": {
        "type": "Container",
        "components": [
            {
                "type": "Component",
                "modes": {
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwatb3ui-mode-iwatb3yi"
                            ],
                            "layout": {
                                "width": 167,
                                "height": 34,
                                "x": 6,
                                "y": 33,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            }
                        }
                    ],
                    "isHiddenByModes": false
                },
                "layout": {
                    "width": 167,
                    "height": 34,
                    "x": 5,
                    "y": 33,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "text": "<h6 class=\"font_6\" style=\"font-size:23px; text-align:center\"><span style=\"color:#000000;\"><span style=\"font-size:23px\"><span style=\"font-family:lulo-clean-w01-one-bold,sans-serif\">CONTACT</span></span></span></h6>\n",
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": []
                },
                "props": {
                    "type": "WRichTextProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "brightness": 1,
                    "packed": true
                },
                "style": "txtNew",
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"action\":\"modeChange\",\"name\":\"ModesMotion\",\"delay\":0,\"duration\":0.2,\"params\":{\"timingFunction\":\"EaseInOut\"},\"targetId\":\"\"}]"
                },
                "mobileStructure": {
                    "type": "Component",
                    "layout": {
                        "width": 260,
                        "height": 34,
                        "x": 10,
                        "y": 23,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.WRichText",
                    "data": {
                        "type": "StyledText",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "text": "<h6 class=\"font_6\" style=\"font-size:23px; text-align:center\"><span style=\"color:#000000;\"><span style=\"font-size:23px\"><span style=\"font-family:lulo-clean-w01-one-bold,sans-serif\">CONTACT</span></span></span></h6>\n",
                        "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                        "linkList": []
                    },
                    "props": {
                        "type": "WRichTextProperties",
                        "metaData": {
                            "schemaVersion": "1.0",
                            "autoGenerated": false
                        },
                        "brightness": 1,
                        "packed": true
                    },
                    "style": "txtNew",
                    "behaviors": {
                        "type": "ObsoleteBehaviorsList",
                        "items": "[{\"action\":\"modeChange\",\"name\":\"ModesMotion\",\"delay\":0,\"duration\":0.2,\"params\":{\"timingFunction\":\"EaseInOut\"},\"targetId\":\"\"}]"
                    }
                }
            },
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwatb3ui-mode-iwatb3yi1"
                            ],
                            "layout": {
                                "width": 161,
                                "height": 5,
                                "x": 5,
                                "y": 20,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "isHiddenByModes": false,
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "style": {
                                    "properties": {
                                        "alpha-brd": "1",
                                        "brd": "#60DFFF",
                                        "lnw": "3"
                                    },
                                    "propertiesSource": {
                                        "brd": "value",
                                        "lnw": "value"
                                    },
                                    "groups": {}
                                },
                                "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.line.SolidLine"
                            },
                            "props": {
                                "type": "FiveGridLineProperties",
                                "metaData": {
                                    "schemaVersion": "1.0"
                                },
                                "fullScreenModeOn": false
                            }
                        }
                    ],
                    "isHiddenByModes": true
                },
                "skin": "wysiwyg.viewer.skins.line.SolidLine",
                "layout": {
                    "width": 104,
                    "height": 5,
                    "x": 36,
                    "y": 30,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.FiveGridLine",
                "props": {
                    "type": "FiveGridLineProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "fullScreenModeOn": false
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "style": {
                        "properties": {
                            "alpha-brd": "1",
                            "brd": "#63FF9F",
                            "lnw": "3"
                        },
                        "propertiesSource": {
                            "brd": "value",
                            "lnw": "value"
                        },
                        "groups": {}
                    },
                    "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.line.SolidLine"
                },
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"name\":\"FloatIn\",\"delay\":0,\"duration\":0.15,\"params\":{\"direction\":\"top\",\"modeIds\":[\"comp-iwatb3ui-mode-iwatb3yi1\"]},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"FloatOut\",\"delay\":0,\"duration\":0.15,\"params\":{\"direction\":\"top\",\"modeIds\":[\"comp-iwatb3ui-mode-iwatb3yi1\"]},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                },
                "mobileStructure": {
                    "type": "Component",
                    "modes": {
                        "definitions": []
                    },
                    "skin": "wysiwyg.viewer.skins.line.SolidLine",
                    "layout": {
                        "width": 159,
                        "height": 5,
                        "x": 57,
                        "y": 10,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.FiveGridLine",
                    "props": {
                        "type": "FiveGridLineProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "fullScreenModeOn": false
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "properties": {
                                "alpha-brd": "1",
                                "brd": "#60DFFF",
                                "lnw": "3"
                            },
                            "propertiesSource": {
                                "brd": "value",
                                "lnw": "value"
                            },
                            "groups": {}
                        },
                        "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.line.SolidLine"
                    },
                    "behaviors": {
                        "type": "ObsoleteBehaviorsList",
                        "items": "[{\"name\":\"FloatIn\",\"delay\":0,\"duration\":0.15,\"params\":{\"direction\":\"top\",\"modeIds\":[\"comp-iwatb3ui-mode-iwatb3yi1\"]},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"FloatOut\",\"delay\":0,\"duration\":0.15,\"params\":{\"direction\":\"top\",\"modeIds\":[\"comp-iwatb3ui-mode-iwatb3yi1\"]},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                    }
                }
            },
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwatb3ui-mode-iwatb3yi1"
                            ],
                            "layout": {
                                "width": 162,
                                "height": 5,
                                "x": 4,
                                "y": 77,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "isHiddenByModes": false,
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "style": {
                                    "properties": {
                                        "alpha-brd": "1",
                                        "brd": "#60DFFF",
                                        "lnw": "3"
                                    },
                                    "propertiesSource": {
                                        "brd": "value",
                                        "lnw": "value"
                                    },
                                    "groups": {}
                                },
                                "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.line.SolidLine"
                            },
                            "props": {
                                "type": "FiveGridLineProperties",
                                "metaData": {
                                    "schemaVersion": "1.0"
                                },
                                "fullScreenModeOn": false
                            }
                        }
                    ],
                    "isHiddenByModes": true
                },
                "skin": "wysiwyg.viewer.skins.line.SolidLine",
                "layout": {
                    "width": 104,
                    "height": 5,
                    "x": 36,
                    "y": 78,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.FiveGridLine",
                "props": {
                    "type": "FiveGridLineProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "fullScreenModeOn": false
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "style": {
                        "properties": {
                            "alpha-brd": "1",
                            "brd": "#63FF9F",
                            "lnw": "3"
                        },
                        "propertiesSource": {
                            "brd": "value",
                            "lnw": "value"
                        },
                        "groups": {}
                    },
                    "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.line.SolidLine"
                },
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"name\":\"FloatIn\",\"delay\":0,\"duration\":0.15,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iwatb3ui-mode-iwatb3yi1\"]},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"FloatOut\",\"delay\":0,\"duration\":0.15,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iwatb3ui-mode-iwatb3yi1\"]},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                },
                "mobileStructure": {
                    "type": "Component",
                    "modes": {
                        "definitions": []
                    },
                    "skin": "wysiwyg.viewer.skins.line.SolidLine",
                    "layout": {
                        "width": 159,
                        "height": 5,
                        "x": 57,
                        "y": 65,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.FiveGridLine",
                    "props": {
                        "type": "FiveGridLineProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "fullScreenModeOn": false
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "properties": {
                                "alpha-brd": "1",
                                "brd": "#60DFFF",
                                "lnw": "3"
                            },
                            "propertiesSource": {
                                "brd": "value",
                                "lnw": "value"
                            },
                            "groups": {}
                        },
                        "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.line.SolidLine"
                    },
                    "behaviors": {
                        "type": "ObsoleteBehaviorsList",
                        "items": "[{\"name\":\"FloatIn\",\"delay\":0,\"duration\":0.15,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iwatb3ui-mode-iwatb3yi1\"]},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"FloatOut\",\"delay\":0,\"duration\":0.15,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iwatb3ui-mode-iwatb3yi1\"]},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                    }
                }
            }
        ],
        "modes": {
            "definitions": [
                {
                    "modeId": "comp-iwatb3ui-mode-iwatb3yi",
                    "type": "DEFAULT",
                    "label": "MODE_LABEL_FROM_PRESET",
                    "params": ""
                },
                {
                    "modeId": "comp-iwatb3ui-mode-iwatb3yi1",
                    "type": "HOVER",
                    "label": "MODE_LABEL_FROM_PRESET",
                    "params": ""
                }
            ],
            "overrides": []
        },
        "layout": {
            "width": 176,
            "height": 100,
            "x": 0,
            "y": 0,
            "scale": 1,
            "rotationInDegrees": 0,
            "fixedPosition": false
        },
        "componentType": "wysiwyg.viewer.components.HoverBox",
        "props": {
            "type": "HoverBoxProperties",
            "metaData": {
                "schemaVersion": "1.0"
            },
            "mobileDisplayedModeId": "comp-iwatb3ui-mode-iwatb3yi1"
        },
        "design": {
            "type": "MediaContainerDesignData",
            "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false
            },
            "background": {
                "type": "BackgroundMedia",
                "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false
                },
                "color": "#FFFFFF",
                "colorOpacity": 1,
                "alignType": "center",
                "fittingType": "fill",
                "scrollType": "none",
                "colorOverlay": "",
                "colorOverlayOpacity": 0
            },
            "cssStyle": {},
            "themeMappings": {},
            "charas": "HoverBoxThemePreset_#8"
        },
        "style": {
            "type": "TopLevelStyle",
            "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false
            },
            "style": {
                "properties": {
                    "alpha-bg": "1",
                    "alpha-brd": "1",
                    "bg": "206,224,193,1",
                    "boxShadowToggleOn-shd": "false",
                    "brd": "#324158",
                    "brw": "0px",
                    "rd": "0px",
                    "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                },
                "propertiesSource": {
                    "bg": "value",
                    "brd": "value",
                    "brw": "value",
                    "rd": "value",
                    "shd": "value"
                },
                "groups": {}
            },
            "componentClassName": "wysiwyg.viewer.components.HoverBox",
            "pageId": "",
            "compId": "",
            "styleType": "custom",
            "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer"
        },
        "mobileStructure": {
            "type": "Container",
            "modes": {
                "definitions": [
                    {
                        "modeId": "comp-iwatb3ui-mode-iwatb3yi",
                        "type": "DEFAULT",
                        "label": "MODE_LABEL_FROM_PRESET",
                        "params": ""
                    },
                    {
                        "modeId": "comp-iwatb3ui-mode-iwatb3yi1",
                        "type": "HOVER",
                        "label": "MODE_LABEL_FROM_PRESET",
                        "params": ""
                    }
                ]
            },
            "layout": {
                "width": 280,
                "height": 80,
                "x": 20,
                "y": 0,
                "scale": 1,
                "rotationInDegrees": 0,
                "fixedPosition": false
            },
            "componentType": "wysiwyg.viewer.components.HoverBox",
            "props": {
                "type": "HoverBoxProperties",
                "metaData": {
                    "schemaVersion": "1.0"
                },
                "mobileDisplayedModeId": "comp-iwatb3ui-mode-iwatb3yi1"
            },
            "design": {
                "type": "MediaContainerDesignData",
                "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false
                },
                "background": {
                    "type": "BackgroundMedia",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "color": "#FFFFFF",
                    "colorOpacity": 1,
                    "alignType": "center",
                    "fittingType": "fill",
                    "scrollType": "none",
                    "colorOverlay": "",
                    "colorOverlayOpacity": 0
                },
                "cssStyle": {},
                "themeMappings": {},
                "charas": "HoverBoxThemePreset_#8"
            },
            "style": {
                "type": "TopLevelStyle",
                "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false
                },
                "style": {
                    "properties": {
                        "alpha-bg": "1",
                        "alpha-brd": "1",
                        "bg": "206,224,193,1",
                        "boxShadowToggleOn-shd": "false",
                        "brd": "#324158",
                        "brw": "0px",
                        "rd": "0px",
                        "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                    },
                    "propertiesSource": {
                        "bg": "value",
                        "brd": "value",
                        "brw": "value",
                        "rd": "value",
                        "shd": "value"
                    },
                    "groups": {}
                },
                "componentClassName": "wysiwyg.viewer.components.HoverBox",
                "pageId": "",
                "compId": "",
                "styleType": "custom",
                "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer"
            }
        },
        "activeModes": {}
    },
    "preset": {
        "rect": {
            "width": 108,
            "height": 91,
            "x": 0,
            "y": 3304
        }
    }
}
const hbPreset25 = {
    "id": "hoverBoxPreset25",
    "structure": {
        "type": "Container",
        "components": [
            {
                "type": "Component",
                "modes": {
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwatd3f2-mode-iwatd3gg1"
                            ],
                            "layout": {
                                "width": 176,
                                "height": 34,
                                "x": 0,
                                "y": 25,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            }
                        }
                    ],
                    "isHiddenByModes": false
                },
                "layout": {
                    "width": 176,
                    "height": 34,
                    "x": 0,
                    "y": 33,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "text": "<h6 class=\"font_6\" style=\"font-size:23px; text-align:center\"><span style=\"color:#000000\"><span style=\"font-size:23px\"><span style=\"font-family:lulo-clean-w01-one-bold,sans-serif\">JOIN!</span></span></span></h6>\n",
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": []
                },
                "props": {
                    "type": "WRichTextProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "brightness": 1,
                    "packed": true
                },
                "style": "txtNew",
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"action\":\"modeChange\",\"name\":\"ModesMotion\",\"delay\":0,\"duration\":0.15,\"params\":{\"timingFunction\":\"EaseInOut\",\"modeIds\":[\"comp-iwatd3f2-mode-iwatd3gg1\"]},\"targetId\":\"\"}]"
                },
                "mobileStructure": {
                    "type": "Component",
                    "modes": {
                        "definitions": []
                    },
                    "layout": {
                        "width": 260,
                        "height": 34,
                        "x": 10,
                        "y": 14,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.WRichText",
                    "data": {
                        "type": "StyledText",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "text": "<h6 class=\"font_6\" style=\"font-size:23px; text-align:center\"><span style=\"color:#000000\"><span style=\"font-size:23px\"><span style=\"font-family:lulo-clean-w01-one-bold,sans-serif\">JOIN!</span></span></span></h6>\n",
                        "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                        "linkList": []
                    },
                    "props": {
                        "type": "WRichTextProperties",
                        "metaData": {
                            "schemaVersion": "1.0",
                            "autoGenerated": false
                        },
                        "brightness": 1,
                        "packed": true
                    },
                    "style": "txtNew",
                    "behaviors": {
                        "type": "ObsoleteBehaviorsList",
                        "items": "[{\"action\":\"modeChange\",\"name\":\"ModesMotion\",\"delay\":0,\"duration\":0.15,\"params\":{\"timingFunction\":\"EaseInOut\",\"modeIds\":[\"comp-iwatd3f2-mode-iwatd3gg1\"]},\"targetId\":\"\"}]"
                    }
                }
            },
            {
                "type": "Component",
                "modes": {
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwatd3f2-mode-iwatd3gg1"
                            ],
                            "layout": {
                                "width": 91,
                                "height": 5,
                                "x": 39,
                                "y": 57,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "isHiddenByModes": false,
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "style": {
                                    "properties": {
                                        "alpha-brd": "1",
                                        "brd": "#B685E8",
                                        "lnw": "3"
                                    },
                                    "propertiesSource": {
                                        "alpha-brd": "value",
                                        "brd": "value",
                                        "lnw": "value"
                                    },
                                    "groups": {}
                                },
                                "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.line.SolidLine"
                            }
                        }
                    ],
                    "isHiddenByModes": true
                },
                "skin": "wysiwyg.viewer.skins.line.SolidLine",
                "layout": {
                    "width": 118,
                    "height": 5,
                    "x": 29,
                    "y": 60,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.FiveGridLine",
                "props": {
                    "type": "FiveGridLineProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "fullScreenModeOn": false
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "style": {
                        "properties": {
                            "alpha-brd": "1",
                            "brd": "#FF45EC",
                            "lnw": "3"
                        },
                        "propertiesSource": {
                            "brd": "value",
                            "lnw": "value"
                        },
                        "groups": {}
                    },
                    "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.line.SolidLine"
                },
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"name\":\"SlideIn\",\"delay\":0,\"duration\":0.15,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iwatd3f2-mode-iwatd3gg1\"]},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"SlideOut\",\"delay\":0,\"duration\":0.15,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iwatd3f2-mode-iwatd3gg1\"]},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                },
                "mobileStructure": {
                    "type": "Component",
                    "modes": {
                        "definitions": []
                    },
                    "skin": "wysiwyg.viewer.skins.line.SolidLine",
                    "layout": {
                        "width": 91,
                        "height": 5,
                        "x": 92,
                        "y": 48,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.FiveGridLine",
                    "props": {
                        "type": "FiveGridLineProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "fullScreenModeOn": false
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "properties": {
                                "alpha-brd": "1",
                                "brd": "#B685E8",
                                "lnw": "3"
                            },
                            "propertiesSource": {
                                "alpha-brd": "value",
                                "brd": "value",
                                "lnw": "value"
                            },
                            "groups": {}
                        },
                        "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.line.SolidLine"
                    },
                    "behaviors": {
                        "type": "ObsoleteBehaviorsList",
                        "items": "[{\"name\":\"SlideIn\",\"delay\":0,\"duration\":0.15,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iwatd3f2-mode-iwatd3gg1\"]},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"SlideOut\",\"delay\":0,\"duration\":0.15,\"params\":{\"direction\":\"bottom\",\"modeIds\":[\"comp-iwatd3f2-mode-iwatd3gg1\"]},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                    }
                }
            }
        ],
        "modes": {
            "definitions": [
                {
                    "modeId": "comp-iwatd3f2-mode-iwatd3gg",
                    "type": "DEFAULT",
                    "label": "MODE_LABEL_FROM_PRESET",
                    "params": ""
                },
                {
                    "modeId": "comp-iwatd3f2-mode-iwatd3gg1",
                    "type": "HOVER",
                    "label": "MODE_LABEL_FROM_PRESET",
                    "params": ""
                }
            ],
            "overrides": []
        },
        "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer",
        "layout": {
            "width": 176,
            "height": 100,
            "x": 0,
            "y": 0,
            "scale": 1,
            "rotationInDegrees": 0,
            "fixedPosition": false
        },
        "componentType": "wysiwyg.viewer.components.HoverBox",
        "props": {
            "type": "HoverBoxProperties",
            "metaData": {
                "schemaVersion": "1.0"
            },
            "mobileDisplayedModeId": "comp-iwatd3f2-mode-iwatd3gg1"
        },
        "design": {
            "type": "MediaContainerDesignData",
            "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false
            },
            "background": {
                "type": "BackgroundMedia",
                "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false
                },
                "color": "#FFFFFF",
                "colorOpacity": 1,
                "alignType": "center",
                "fittingType": "fill",
                "scrollType": "none",
                "colorOverlay": "",
                "colorOverlayOpacity": 0
            },
            "cssStyle": {},
            "themeMappings": {},
            "charas": "HoverBoxThemePreset_#7"
        },
        "style": {
            "type": "TopLevelStyle",
            "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false
            },
            "style": {
                "properties": {
                    "alpha-bg": "1",
                    "alpha-brd": "1",
                    "bg": "206,224,193,1",
                    "boxShadowToggleOn-shd": "false",
                    "brd": "#324158",
                    "brw": "0px",
                    "rd": "0px",
                    "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                },
                "propertiesSource": {
                    "bg": "value",
                    "brd": "value",
                    "brw": "value",
                    "rd": "value",
                    "shd": "value"
                },
                "groups": {}
            },
            "componentClassName": "wysiwyg.viewer.components.HoverBox",
            "pageId": "",
            "compId": "",
            "styleType": "custom",
            "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer"
        },
        "mobileStructure": {
            "type": "Container",
            "modes": {
                "definitions": [
                    {
                        "modeId": "comp-iwatd3f2-mode-iwatd3gg",
                        "type": "DEFAULT",
                        "label": "MODE_LABEL_FROM_PRESET",
                        "params": ""
                    },
                    {
                        "modeId": "comp-iwatd3f2-mode-iwatd3gg1",
                        "type": "HOVER",
                        "label": "MODE_LABEL_FROM_PRESET",
                        "params": ""
                    }
                ]
            },
            "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer",
            "layout": {
                "width": 270,
                "height": 70,
                "x": 20,
                "y": 0,
                "scale": 1,
                "rotationInDegrees": 0,
                "fixedPosition": false
            },
            "componentType": "wysiwyg.viewer.components.HoverBox",
            "props": {
                "type": "HoverBoxProperties",
                "metaData": {
                    "schemaVersion": "1.0"
                },
                "mobileDisplayedModeId": "comp-iwatd3f2-mode-iwatd3gg1"
            },
            "design": {
                "type": "MediaContainerDesignData",
                "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false
                },
                "background": {
                    "type": "BackgroundMedia",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "color": "#FFFFFF",
                    "colorOpacity": 1,
                    "alignType": "center",
                    "fittingType": "fill",
                    "scrollType": "none",
                    "colorOverlay": "",
                    "colorOverlayOpacity": 0
                },
                "cssStyle": {},
                "themeMappings": {},
                "charas": "HoverBoxThemePreset_#7"
            },
            "style": {
                "type": "TopLevelStyle",
                "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false
                },
                "style": {
                    "properties": {
                        "alpha-bg": "1",
                        "alpha-brd": "1",
                        "bg": "206,224,193,1",
                        "boxShadowToggleOn-shd": "false",
                        "brd": "#324158",
                        "brw": "0px",
                        "rd": "0px",
                        "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                    },
                    "propertiesSource": {
                        "bg": "value",
                        "brd": "value",
                        "brw": "value",
                        "rd": "value",
                        "shd": "value"
                    },
                    "groups": {}
                },
                "componentClassName": "wysiwyg.viewer.components.HoverBox",
                "pageId": "",
                "compId": "",
                "styleType": "custom",
                "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer"
            }
        },
        "activeModes": {}
    },
    "preset": {
        "rect": {
            "width": 107,
            "height": 91,
            "x": 107,
            "y": 3304
        }
    }
}
const hbPreset26 = {
    "id": "hoverBoxPreset26",
    "structure": {
        "type": "Container",
        "components": [
            {
                "type": "Component",
                "modes": {
                    "overrides": [],
                    "isHiddenByModes": false
                },
                "layout": {
                    "width": 160,
                    "height": 34,
                    "x": 8,
                    "y": 33,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "text": "<h6 class=\"font_6\" style=\"font-size:23px; text-align:center\"><span style=\"color:#000000\"><span style=\"font-size:23px\"><span style=\"font-family:lulo-clean-w01-one-bold,sans-serif\">ABOUT</span></span></span></h6>\n",
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": []
                },
                "props": {
                    "type": "WRichTextProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "brightness": 1,
                    "packed": true
                },
                "style": "txtNew",
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"action\":\"modeChange\",\"name\":\"ModesMotion\",\"delay\":0,\"duration\":0.2,\"params\":{\"timingFunction\":\"EaseInOut\"},\"targetId\":\"\"}]"
                },
                "mobileStructure": {
                    "type": "Component",
                    "layout": {
                        "width": 260,
                        "height": 34,
                        "x": 10,
                        "y": 13,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.WRichText",
                    "data": {
                        "type": "StyledText",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "text": "<h6 class=\"font_6\" style=\"font-size:23px; text-align:center\"><span style=\"color:#000000\"><span style=\"font-size:23px\"><span style=\"font-family:lulo-clean-w01-one-bold,sans-serif\">ABOUT</span></span></span></h6>\n",
                        "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                        "linkList": []
                    },
                    "props": {
                        "type": "WRichTextProperties",
                        "metaData": {
                            "schemaVersion": "1.0",
                            "autoGenerated": false
                        },
                        "brightness": 1,
                        "packed": true
                    },
                    "style": "txtNew",
                    "behaviors": {
                        "type": "ObsoleteBehaviorsList",
                        "items": "[{\"action\":\"modeChange\",\"name\":\"ModesMotion\",\"delay\":0,\"duration\":0.2,\"params\":{\"timingFunction\":\"EaseInOut\"},\"targetId\":\"\"}]"
                    }
                }
            },
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwatdk99-mode-iwatdka9"
                            ],
                            "layout": {
                                "width": 82,
                                "height": 5,
                                "x": 45,
                                "y": 32,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "isHiddenByModes": true,
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "style": {
                                    "properties": {
                                        "alpha-brd": "1",
                                        "brd": "#3DF5FF",
                                        "lnw": "2"
                                    },
                                    "propertiesSource": {
                                        "brd": "value",
                                        "lnw": "value"
                                    },
                                    "groups": {}
                                },
                                "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.line.SolidLine"
                            }
                        },
                        {
                            "modeIds": [
                                "comp-iwatdk99-mode-iwatdka91"
                            ],
                            "layout": {
                                "width": 115,
                                "height": 5,
                                "x": 28,
                                "y": 66,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "style": {
                                    "properties": {
                                        "alpha-brd": "1",
                                        "brd": "#FFD56B",
                                        "lnw": "3"
                                    },
                                    "propertiesSource": {
                                        "brd": "value",
                                        "lnw": "value"
                                    },
                                    "groups": {}
                                },
                                "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.line.SolidLine"
                            }
                        }
                    ],
                    "isHiddenByModes": false
                },
                "skin": "wysiwyg.viewer.skins.line.SolidLine",
                "layout": {
                    "width": 82,
                    "height": 5,
                    "x": 44,
                    "y": 75,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.FiveGridLine",
                "props": {
                    "type": "FiveGridLineProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "fullScreenModeOn": false
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "style": {
                        "properties": {
                            "alpha-brd": "1",
                            "brd": "#3DF5FF",
                            "lnw": "5"
                        },
                        "propertiesSource": {
                            "brd": "value",
                            "lnw": "value"
                        },
                        "groups": {}
                    },
                    "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.line.SolidLine"
                },
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"action\":\"modeChange\",\"name\":\"ModesMotion\",\"delay\":0,\"duration\":0.2,\"params\":{\"timingFunction\":\"EaseInOut\",\"modeIds\":[\"comp-iwatdk99-mode-iwatdka91\"]},\"targetId\":\"\"},{\"name\":\"SlideIn\",\"delay\":0,\"duration\":0.1,\"params\":{\"direction\":\"right\",\"modeIds\":[\"comp-iwatdk99-mode-iwatdka91\"]},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"SlideOut\",\"delay\":0,\"duration\":0.1,\"params\":{\"direction\":\"right\",\"modeIds\":[\"comp-iwatdk99-mode-iwatdka91\"]},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                },
                "mobileStructure": {
                    "type": "Component",
                    "modes": {
                        "definitions": []
                    },
                    "skin": "wysiwyg.viewer.skins.line.SolidLine",
                    "layout": {
                        "width": 115,
                        "height": 5,
                        "x": 79,
                        "y": 48,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.FiveGridLine",
                    "props": {
                        "type": "FiveGridLineProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "fullScreenModeOn": false
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "properties": {
                                "alpha-brd": "1",
                                "brd": "#FFD56B",
                                "lnw": "3"
                            },
                            "propertiesSource": {
                                "brd": "value",
                                "lnw": "value"
                            },
                            "groups": {}
                        },
                        "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.line.SolidLine"
                    },
                    "behaviors": {
                        "type": "ObsoleteBehaviorsList",
                        "items": "[{\"action\":\"modeChange\",\"name\":\"ModesMotion\",\"delay\":0,\"duration\":0.2,\"params\":{\"timingFunction\":\"EaseInOut\",\"modeIds\":[\"comp-iwatdk99-mode-iwatdka91\"]},\"targetId\":\"\"},{\"name\":\"SlideIn\",\"delay\":0,\"duration\":0.1,\"params\":{\"direction\":\"right\",\"modeIds\":[\"comp-iwatdk99-mode-iwatdka91\"]},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"SlideOut\",\"delay\":0,\"duration\":0.1,\"params\":{\"direction\":\"right\",\"modeIds\":[\"comp-iwatdk99-mode-iwatdka91\"]},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                    }
                }
            }
        ],
        "modes": {
            "definitions": [
                {
                    "modeId": "comp-iwatdk99-mode-iwatdka9",
                    "type": "DEFAULT",
                    "label": "MODE_LABEL_FROM_PRESET",
                    "params": ""
                },
                {
                    "modeId": "comp-iwatdk99-mode-iwatdka91",
                    "type": "HOVER",
                    "label": "MODE_LABEL_FROM_PRESET",
                    "params": ""
                }
            ],
            "overrides": [],
            "isHiddenByModes": false
        },
        "layout": {
            "width": 176,
            "height": 100,
            "x": 0,
            "y": 0,
            "scale": 1,
            "rotationInDegrees": 0,
            "fixedPosition": false
        },
        "componentType": "wysiwyg.viewer.components.HoverBox",
        "props": {
            "type": "HoverBoxProperties",
            "metaData": {
                "schemaVersion": "1.0"
            }
        },
        "design": {
            "type": "MediaContainerDesignData",
            "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false
            },
            "background": {
                "type": "BackgroundMedia",
                "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false
                },
                "color": "#FFFFFF",
                "colorOpacity": 1,
                "alignType": "center",
                "fittingType": "fill",
                "scrollType": "none",
                "colorOverlay": "",
                "colorOverlayOpacity": 0
            },
            "cssStyle": {},
            "themeMappings": {},
            "charas": "HoverBoxThemePreset_#8"
        },
        "style": {
            "type": "TopLevelStyle",
            "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false
            },
            "style": {
                "properties": {
                    "alpha-bg": "1",
                    "alpha-brd": "1",
                    "bg": "206,224,193,1",
                    "boxShadowToggleOn-shd": "false",
                    "brd": "#324158",
                    "brw": "0px",
                    "rd": "0px",
                    "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                },
                "propertiesSource": {
                    "bg": "value",
                    "brd": "value",
                    "brw": "value",
                    "rd": "value",
                    "shd": "value"
                },
                "groups": {}
            },
            "componentClassName": "wysiwyg.viewer.components.HoverBox",
            "pageId": "",
            "compId": "",
            "styleType": "custom",
            "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer"
        },
        "mobileStructure": {
            "type": "Container",
            "modes": {
                "definitions": [
                    {
                        "modeId": "comp-iwatdk99-mode-iwatdka9",
                        "type": "DEFAULT",
                        "label": "MODE_LABEL_FROM_PRESET",
                        "params": ""
                    },
                    {
                        "modeId": "comp-iwatdk99-mode-iwatdka91",
                        "type": "HOVER",
                        "label": "MODE_LABEL_FROM_PRESET",
                        "params": ""
                    }
                ]
            },
            "layout": {
                "width": 280,
                "height": 70,
                "x": 20,
                "y": 0,
                "scale": 1,
                "rotationInDegrees": 0,
                "fixedPosition": false
            },
            "componentType": "wysiwyg.viewer.components.HoverBox",
            "props": {
                "type": "HoverBoxProperties",
                "metaData": {
                    "schemaVersion": "1.0"
                }
            },
            "design": {
                "type": "MediaContainerDesignData",
                "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false
                },
                "background": {
                    "type": "BackgroundMedia",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "color": "#FFFFFF",
                    "colorOpacity": 1,
                    "alignType": "center",
                    "fittingType": "fill",
                    "scrollType": "none",
                    "colorOverlay": "",
                    "colorOverlayOpacity": 0
                },
                "cssStyle": {},
                "themeMappings": {},
                "charas": "HoverBoxThemePreset_#8"
            },
            "style": {
                "type": "TopLevelStyle",
                "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false
                },
                "style": {
                    "properties": {
                        "alpha-bg": "1",
                        "alpha-brd": "1",
                        "bg": "206,224,193,1",
                        "boxShadowToggleOn-shd": "false",
                        "brd": "#324158",
                        "brw": "0px",
                        "rd": "0px",
                        "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                    },
                    "propertiesSource": {
                        "bg": "value",
                        "brd": "value",
                        "brw": "value",
                        "rd": "value",
                        "shd": "value"
                    },
                    "groups": {}
                },
                "componentClassName": "wysiwyg.viewer.components.HoverBox",
                "pageId": "",
                "compId": "",
                "styleType": "custom",
                "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer"
            }
        },
        "activeModes": {}
    },
    "preset": {
        "rect": {
            "width": 108,
            "height": 91,
            "x": 215,
            "y": 3304
        }
    }
}
const hbPreset27 = {
    "id": "hoverBoxPreset27",
    "structure": {
        "type": "Container",
        "components": [
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [],
                    "isHiddenByModes": false
                },
                "skin": "svgshape.v2.Svg_c2d8db9f7eb140a49e069f974e4d1b6d",
                "layout": {
                    "width": 64,
                    "height": 67,
                    "x": 29,
                    "y": 24,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.svgshape.SvgShape",
                "data": {
                    "type": "SvgShape",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    }
                },
                "props": {
                    "type": "SvgShapeProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "maintainAspectRatio": true
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "style": {
                        "properties": {
                            "alpha-fillcolor": "1",
                            "alpha-stroke": "1",
                            "fillcolor": "#FFFFFF",
                            "stroke": "#000000",
                            "strokewidth": "0"
                        },
                        "propertiesSource": {
                            "fillcolor": "theme",
                            "stroke": "theme",
                            "strokewidth": "value"
                        },
                        "groups": {}
                    },
                    "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "svgshape.v2.Svg_c2d8db9f7eb140a49e069f974e4d1b6d"
                },
                "mobileStructure": {
                    "type": "Component",
                    "modes": {
                        "definitions": []
                    },
                    "skin": "svgshape.v2.Svg_c2d8db9f7eb140a49e069f974e4d1b6d",
                    "layout": {
                        "width": 64,
                        "height": 67,
                        "x": 29,
                        "y": 24,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.svgshape.SvgShape",
                    "data": {
                        "type": "SvgShape",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        }
                    },
                    "props": {
                        "type": "SvgShapeProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "maintainAspectRatio": true
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "properties": {
                                "alpha-fillcolor": "1",
                                "alpha-stroke": "1",
                                "fillcolor": "#FFFFFF",
                                "stroke": "#000000",
                                "strokewidth": "0"
                            },
                            "propertiesSource": {
                                "fillcolor": "theme",
                                "stroke": "theme",
                                "strokewidth": "value"
                            },
                            "groups": {}
                        },
                        "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "svgshape.v2.Svg_c2d8db9f7eb140a49e069f974e4d1b6d"
                    }
                }
            },
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwatek7h-mode-iwatek8h1"
                            ],
                            "layout": {
                                "width": 110,
                                "height": 110,
                                "x": 6,
                                "y": 6,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "isHiddenByModes": false,
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "style": {
                                    "properties": {
                                        "alpha-fillcolor": "0",
                                        "alpha-stroke": "1",
                                        "fillcolor": "#72CCA7",
                                        "stroke": "#FFFFFF",
                                        "strokewidth": "2"
                                    },
                                    "propertiesSource": {
                                        "alpha-fillcolor": "value",
                                        "fillcolor": "value",
                                        "stroke": "value",
                                        "strokewidth": "value"
                                    },
                                    "groups": {}
                                },
                                "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "svgshape.v1.svg_8463f60718194af748c49dddbe45b668.HollowCircle"
                            },
                            "props": {
                                "type": "SvgShapeProperties",
                                "metaData": {
                                    "schemaVersion": "1.0"
                                },
                                "maintainAspectRatio": false
                            }
                        }
                    ],
                    "isHiddenByModes": true
                },
                "skin": "svgshape.v1.svg_8463f60718194af748c49dddbe45b668.HollowCircle",
                "layout": {
                    "width": 115,
                    "height": 115,
                    "x": 3,
                    "y": 0,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.svgshape.SvgShape",
                "data": {
                    "type": "SvgShape",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    }
                },
                "props": {
                    "type": "SvgShapeProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "maintainAspectRatio": false
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "style": {
                        "properties": {
                            "alpha-fillcolor": "0",
                            "alpha-stroke": "1",
                            "fillcolor": "#72CCA7",
                            "stroke": "#72CCA7",
                            "strokewidth": "3"
                        },
                        "propertiesSource": {
                            "alpha-fillcolor": "value",
                            "fillcolor": "value",
                            "stroke": "value",
                            "strokewidth": "value"
                        },
                        "groups": {}
                    },
                    "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "svgshape.v1.svg_8463f60718194af748c49dddbe45b668.HollowCircle"
                },
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"name\":\"FadeIn\",\"delay\":0,\"duration\":0.2,\"action\":\"modeIn\",\"params\":{\"modeIds\":[\"comp-iwatek7h-mode-iwatek8h1\"]},\"targetId\":\"\"},{\"name\":\"FadeOut\",\"delay\":0,\"duration\":0.2,\"action\":\"modeOut\",\"params\":{\"modeIds\":[\"comp-iwatek7h-mode-iwatek8h1\"]},\"targetId\":\"\"}]"
                },
                "mobileStructure": {
                    "type": "Component",
                    "modes": {
                        "definitions": [],
                        "overrides": [
                            {
                                "modeIds": [
                                    "comp-iwatek7h-mode-iwatek8h1"
                                ],
                                "layout": {
                                    "width": 110,
                                    "height": 110,
                                    "x": 6,
                                    "y": 6,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                }
                            }
                        ]
                    },
                    "skin": "svgshape.v1.svg_8463f60718194af748c49dddbe45b668.HollowCircle",
                    "layout": {
                        "width": 110,
                        "height": 110,
                        "x": 6,
                        "y": 6,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.svgshape.SvgShape",
                    "data": {
                        "type": "SvgShape",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        }
                    },
                    "props": {
                        "type": "SvgShapeProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "maintainAspectRatio": false
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "properties": {
                                "alpha-fillcolor": "0",
                                "alpha-stroke": "1",
                                "fillcolor": "#72CCA7",
                                "stroke": "#FFFFFF",
                                "strokewidth": "2"
                            },
                            "propertiesSource": {
                                "alpha-fillcolor": "value",
                                "fillcolor": "value",
                                "stroke": "value",
                                "strokewidth": "value"
                            },
                            "groups": {}
                        },
                        "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "svgshape.v1.svg_8463f60718194af748c49dddbe45b668.HollowCircle"
                    },
                    "behaviors": {
                        "type": "ObsoleteBehaviorsList",
                        "items": "[{\"name\":\"FadeIn\",\"delay\":0,\"duration\":0.2,\"action\":\"modeIn\",\"params\":{\"modeIds\":[\"comp-iwatek7h-mode-iwatek8h1\"]},\"targetId\":\"\"},{\"name\":\"FadeOut\",\"delay\":0,\"duration\":0.2,\"action\":\"modeOut\",\"params\":{\"modeIds\":[\"comp-iwatek7h-mode-iwatek8h1\"]},\"targetId\":\"\"}]"
                    }
                }
            }
        ],
        "modes": {
            "definitions": [
                {
                    "modeId": "comp-iwatek7h-mode-iwatek8h",
                    "type": "DEFAULT",
                    "label": "MODE_LABEL_FROM_PRESET",
                    "params": ""
                },
                {
                    "modeId": "comp-iwatek7h-mode-iwatek8h1",
                    "type": "HOVER",
                    "label": "MODE_LABEL_FROM_PRESET",
                    "params": ""
                }
            ],
            "overrides": [
                {
                    "modeIds": [
                        "comp-iwatek7h-mode-iwatek8h1"
                    ],
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "1.0",
                                "isHidden": false
                            },
                            "color": "#2B4A5C",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0,
                            "showOverlayForMediaType": "WixVideo"
                        },
                        "cssStyle": {
                            "cssBorder": {
                                "width": {
                                    "top": {
                                        "value": 0,
                                        "unit": "px"
                                    },
                                    "right": {
                                        "value": 0,
                                        "unit": "px"
                                    },
                                    "bottom": {
                                        "value": 0,
                                        "unit": "px"
                                    },
                                    "left": {
                                        "value": 0,
                                        "unit": "px"
                                    }
                                },
                                "style": {
                                    "top": "solid",
                                    "right": "solid",
                                    "left": "solid",
                                    "bottom": "solid"
                                },
                                "color": {
                                    "top": {
                                        "red": 0,
                                        "green": 0,
                                        "blue": 0,
                                        "alpha": 1
                                    },
                                    "right": {
                                        "red": 0,
                                        "green": 0,
                                        "blue": 0,
                                        "alpha": 1
                                    },
                                    "left": {
                                        "red": 0,
                                        "green": 0,
                                        "blue": 0,
                                        "alpha": 1
                                    },
                                    "bottom": {
                                        "red": 0,
                                        "green": 0,
                                        "blue": 0,
                                        "alpha": 1
                                    }
                                }
                            },
                            "cssBorderRadius": {
                                "topLeft": {
                                    "value": 500,
                                    "unit": "px"
                                },
                                "topRight": {
                                    "value": 500,
                                    "unit": "px"
                                },
                                "bottomLeft": {
                                    "value": 500,
                                    "unit": "px"
                                },
                                "bottomRight": {
                                    "value": 500,
                                    "unit": "px"
                                }
                            }
                        },
                        "themeMappings": {},
                        "charas": "design-ivuzxce4"
                    }
                },
                {
                    "modeIds": [
                        "comp-iwatek7h-mode-iwatek8h"
                    ],
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "1.0",
                                "isHidden": false
                            },
                            "color": "#33576B",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0,
                            "showOverlayForMediaType": "WixVideo"
                        },
                        "cssStyle": {
                            "cssBorder": {
                                "width": {
                                    "top": {
                                        "value": 0,
                                        "unit": "px"
                                    },
                                    "right": {
                                        "value": 0,
                                        "unit": "px"
                                    },
                                    "bottom": {
                                        "value": 0,
                                        "unit": "px"
                                    },
                                    "left": {
                                        "value": 0,
                                        "unit": "px"
                                    }
                                },
                                "style": {
                                    "top": "solid",
                                    "right": "solid",
                                    "left": "solid",
                                    "bottom": "solid"
                                },
                                "color": {
                                    "top": {
                                        "red": 0,
                                        "green": 0,
                                        "blue": 0,
                                        "alpha": 1
                                    },
                                    "right": {
                                        "red": 0,
                                        "green": 0,
                                        "blue": 0,
                                        "alpha": 1
                                    },
                                    "left": {
                                        "red": 0,
                                        "green": 0,
                                        "blue": 0,
                                        "alpha": 1
                                    },
                                    "bottom": {
                                        "red": 0,
                                        "green": 0,
                                        "blue": 0,
                                        "alpha": 1
                                    }
                                }
                            },
                            "cssBorderRadius": {
                                "topLeft": {
                                    "value": 500,
                                    "unit": "px"
                                },
                                "topRight": {
                                    "value": 500,
                                    "unit": "px"
                                },
                                "bottomLeft": {
                                    "value": 500,
                                    "unit": "px"
                                },
                                "bottomRight": {
                                    "value": 500,
                                    "unit": "px"
                                }
                            }
                        },
                        "themeMappings": {},
                        "charas": "design-ivuzw1eb"
                    }
                }
            ],
            "isHiddenByModes": false
        },
        "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer",
        "layout": {
            "width": 122,
            "height": 122,
            "x": 0,
            "y": 0,
            "scale": 1,
            "rotationInDegrees": 0,
            "fixedPosition": false
        },
        "componentType": "wysiwyg.viewer.components.HoverBox",
        "props": {
            "type": "HoverBoxProperties",
            "metaData": {
                "schemaVersion": "1.0"
            },
            "mobileDisplayedModeId": "comp-iwatek7h-mode-iwatek8h1"
        },
        "design": {
            "type": "MediaContainerDesignData",
            "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false
            },
            "background": {
                "type": "BackgroundMedia",
                "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false
                },
                "color": "#72CCA7",
                "colorOpacity": 1,
                "alignType": "center",
                "fittingType": "fill",
                "scrollType": "none",
                "colorOverlay": "",
                "colorOverlayOpacity": 0,
                "showOverlayForMediaType": "WixVideo"
            },
            "cssStyle": {
                "cssBorder": {
                    "width": {
                        "top": {
                            "value": 0,
                            "unit": "px"
                        },
                        "right": {
                            "value": 0,
                            "unit": "px"
                        },
                        "bottom": {
                            "value": 0,
                            "unit": "px"
                        },
                        "left": {
                            "value": 0,
                            "unit": "px"
                        }
                    },
                    "style": {
                        "top": "solid",
                        "right": "solid",
                        "left": "solid",
                        "bottom": "solid"
                    },
                    "color": {
                        "top": {
                            "red": 0,
                            "green": 0,
                            "blue": 0,
                            "alpha": 1
                        },
                        "right": {
                            "red": 0,
                            "green": 0,
                            "blue": 0,
                            "alpha": 1
                        },
                        "left": {
                            "red": 0,
                            "green": 0,
                            "blue": 0,
                            "alpha": 1
                        },
                        "bottom": {
                            "red": 0,
                            "green": 0,
                            "blue": 0,
                            "alpha": 1
                        }
                    }
                },
                "cssBorderRadius": {
                    "topLeft": {
                        "value": 500,
                        "unit": "px"
                    },
                    "topRight": {
                        "value": 500,
                        "unit": "px"
                    },
                    "bottomLeft": {
                        "value": 500,
                        "unit": "px"
                    },
                    "bottomRight": {
                        "value": 500,
                        "unit": "px"
                    }
                }
            },
            "themeMappings": {},
            "charas": "design-ivunx9q7"
        },
        "style": {
            "type": "TopLevelStyle",
            "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false
            },
            "style": {
                "properties": {
                    "alpha-bg": "1",
                    "alpha-brd": "1",
                    "bg": "206,224,193,1",
                    "boxShadowToggleOn-shd": "false",
                    "brd": "#324158",
                    "brw": "0px",
                    "rd": "0px",
                    "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                },
                "propertiesSource": {
                    "bg": "value",
                    "brd": "value",
                    "brw": "value",
                    "rd": "value",
                    "shd": "value"
                },
                "groups": {}
            },
            "componentClassName": "wysiwyg.viewer.components.HoverBox",
            "pageId": "",
            "compId": "",
            "styleType": "custom",
            "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer"
        },
        "mobileStructure": {
            "type": "Container",
            "modes": {
                "definitions": [
                    {
                        "modeId": "comp-iwatek7h-mode-iwatek8h",
                        "type": "DEFAULT",
                        "label": "MODE_LABEL_FROM_PRESET",
                        "params": ""
                    },
                    {
                        "modeId": "comp-iwatek7h-mode-iwatek8h1",
                        "type": "HOVER",
                        "label": "MODE_LABEL_FROM_PRESET",
                        "params": ""
                    }
                ]
            },
            "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer",
            "layout": {
                "width": 122,
                "height": 122,
                "x": 99,
                "y": 0,
                "scale": 1,
                "rotationInDegrees": 0,
                "fixedPosition": false
            },
            "componentType": "wysiwyg.viewer.components.HoverBox",
            "props": {
                "type": "HoverBoxProperties",
                "metaData": {
                    "schemaVersion": "1.0"
                },
                "mobileDisplayedModeId": "comp-iwatek7h-mode-iwatek8h1"
            },
            "design": {
                "type": "MediaContainerDesignData",
                "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false
                },
                "background": {
                    "type": "BackgroundMedia",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "color": "#2B4A5C",
                    "colorOpacity": 1,
                    "alignType": "center",
                    "fittingType": "fill",
                    "scrollType": "none",
                    "colorOverlay": "",
                    "colorOverlayOpacity": 0,
                    "showOverlayForMediaType": "WixVideo"
                },
                "cssStyle": {
                    "cssBorder": {
                        "width": {
                            "top": {
                                "value": 0,
                                "unit": "px"
                            },
                            "right": {
                                "value": 0,
                                "unit": "px"
                            },
                            "bottom": {
                                "value": 0,
                                "unit": "px"
                            },
                            "left": {
                                "value": 0,
                                "unit": "px"
                            }
                        },
                        "style": {
                            "top": "solid",
                            "right": "solid",
                            "left": "solid",
                            "bottom": "solid"
                        },
                        "color": {
                            "top": {
                                "red": 0,
                                "green": 0,
                                "blue": 0,
                                "alpha": 1
                            },
                            "right": {
                                "red": 0,
                                "green": 0,
                                "blue": 0,
                                "alpha": 1
                            },
                            "left": {
                                "red": 0,
                                "green": 0,
                                "blue": 0,
                                "alpha": 1
                            },
                            "bottom": {
                                "red": 0,
                                "green": 0,
                                "blue": 0,
                                "alpha": 1
                            }
                        }
                    },
                    "cssBorderRadius": {
                        "topLeft": {
                            "value": 500,
                            "unit": "px"
                        },
                        "topRight": {
                            "value": 500,
                            "unit": "px"
                        },
                        "bottomLeft": {
                            "value": 500,
                            "unit": "px"
                        },
                        "bottomRight": {
                            "value": 500,
                            "unit": "px"
                        }
                    }
                },
                "themeMappings": {},
                "charas": "design-ivuzxce4"
            },
            "style": {
                "type": "TopLevelStyle",
                "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false
                },
                "style": {
                    "properties": {
                        "alpha-bg": "1",
                        "alpha-brd": "1",
                        "bg": "206,224,193,1",
                        "boxShadowToggleOn-shd": "false",
                        "brd": "#324158",
                        "brw": "0px",
                        "rd": "0px",
                        "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                    },
                    "propertiesSource": {
                        "bg": "value",
                        "brd": "value",
                        "brw": "value",
                        "rd": "value",
                        "shd": "value"
                    },
                    "groups": {}
                },
                "componentClassName": "wysiwyg.viewer.components.HoverBox",
                "pageId": "",
                "compId": "",
                "styleType": "custom",
                "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer"
            }
        },
        "activeModes": {}
    },
    "preset": {
        "rect": {
            "width": 80,
            "height": 106,
            "x": 0,
            "y": 3395
        }
    }
}
const hbPreset28 = {
    "id": "hoverBoxPreset28",
    "structure": {
        "type": "Container",
        "components": [
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwatf5yc-mode-iwatf6281"
                            ],
                            "layout": {
                                "width": 110,
                                "height": 110,
                                "x": 6,
                                "y": 6,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "isHiddenByModes": false,
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "style": {
                                    "properties": {
                                        "alpha-fillcolor": "1",
                                        "alpha-stroke": "0",
                                        "fillcolor": "#FFFFFF",
                                        "stroke": "#FFFFFF",
                                        "strokewidth": "5"
                                    },
                                    "propertiesSource": {
                                        "alpha-fillcolor": "value",
                                        "alpha-stroke": "value",
                                        "fillcolor": "value",
                                        "stroke": "value",
                                        "strokewidth": "value"
                                    },
                                    "groups": {}
                                },
                                "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "svgshape.v1.svg_8463f60718194af748c49dddbe45b668.HollowCircle"
                            },
                            "props": {
                                "type": "SvgShapeProperties",
                                "metaData": {
                                    "schemaVersion": "1.0"
                                },
                                "maintainAspectRatio": false
                            }
                        }
                    ],
                    "isHiddenByModes": true
                },
                "skin": "svgshape.v1.svg_8463f60718194af748c49dddbe45b668.HollowCircle",
                "layout": {
                    "width": 115,
                    "height": 115,
                    "x": 3,
                    "y": 0,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.svgshape.SvgShape",
                "data": {
                    "type": "SvgShape",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    }
                },
                "props": {
                    "type": "SvgShapeProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "maintainAspectRatio": false
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "style": {
                        "properties": {
                            "alpha-fillcolor": "0",
                            "alpha-stroke": "1",
                            "fillcolor": "#72CCA7",
                            "stroke": "#72CCA7",
                            "strokewidth": "3"
                        },
                        "propertiesSource": {
                            "alpha-fillcolor": "value",
                            "fillcolor": "value",
                            "stroke": "value",
                            "strokewidth": "value"
                        },
                        "groups": {}
                    },
                    "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "svgshape.v1.svg_8463f60718194af748c49dddbe45b668.HollowCircle"
                },
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"name\":\"ExpandIn\",\"delay\":0,\"duration\":0.1,\"params\":{\"direction\":\"right\",\"modeIds\":[\"comp-iwatf5yc-mode-iwatf6281\"]},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"CollapseOut\",\"delay\":0,\"duration\":0.1,\"params\":{\"direction\":\"right\",\"modeIds\":[\"comp-iwatf5yc-mode-iwatf6281\"]},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                },
                "mobileStructure": {
                    "type": "Component",
                    "modes": {
                        "definitions": [],
                        "overrides": [
                            {
                                "modeIds": [
                                    "comp-iwatf5yc-mode-iwatf6281"
                                ],
                                "layout": {
                                    "width": 110,
                                    "height": 110,
                                    "x": 6,
                                    "y": 6,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                }
                            }
                        ]
                    },
                    "skin": "svgshape.v1.svg_8463f60718194af748c49dddbe45b668.HollowCircle",
                    "layout": {
                        "width": 110,
                        "height": 110,
                        "x": 6,
                        "y": 6,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.svgshape.SvgShape",
                    "data": {
                        "type": "SvgShape",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        }
                    },
                    "props": {
                        "type": "SvgShapeProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "maintainAspectRatio": false
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "properties": {
                                "alpha-fillcolor": "1",
                                "alpha-stroke": "0",
                                "fillcolor": "#FFFFFF",
                                "stroke": "#FFFFFF",
                                "strokewidth": "5"
                            },
                            "propertiesSource": {
                                "alpha-fillcolor": "value",
                                "alpha-stroke": "value",
                                "fillcolor": "value",
                                "stroke": "value",
                                "strokewidth": "value"
                            },
                            "groups": {}
                        },
                        "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "svgshape.v1.svg_8463f60718194af748c49dddbe45b668.HollowCircle"
                    },
                    "behaviors": {
                        "type": "ObsoleteBehaviorsList",
                        "items": "[{\"name\":\"ExpandIn\",\"delay\":0,\"duration\":0.1,\"params\":{\"direction\":\"right\",\"modeIds\":[\"comp-iwatf5yc-mode-iwatf6281\"]},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"CollapseOut\",\"delay\":0,\"duration\":0.1,\"params\":{\"direction\":\"right\",\"modeIds\":[\"comp-iwatf5yc-mode-iwatf6281\"]},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                    }
                }
            },
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwatf5yc-mode-iwatf628"
                            ],
                            "layout": {
                                "width": 110,
                                "height": 53,
                                "x": 6,
                                "y": 34,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "style": {
                                    "properties": {
                                        "alpha-fillcolor": "1",
                                        "alpha-stroke": "1",
                                        "fillcolor": "#FFFFFF",
                                        "stroke": "#000000",
                                        "strokewidth": "0"
                                    },
                                    "propertiesSource": {
                                        "fillcolor": "value",
                                        "stroke": "theme",
                                        "strokewidth": "value"
                                    },
                                    "groups": {}
                                },
                                "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "svgshape.v2.Svg_4871da1a477a4329948662076bb515ec"
                            }
                        },
                        {
                            "modeIds": [
                                "comp-iwatf5yc-mode-iwatf6281"
                            ],
                            "isHiddenByModes": true
                        }
                    ],
                    "isHiddenByModes": false
                },
                "skin": "svgshape.v2.Svg_4871da1a477a4329948662076bb515ec",
                "layout": {
                    "width": 50,
                    "height": 53,
                    "x": 36,
                    "y": 34,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.svgshape.SvgShape",
                "data": {
                    "type": "SvgShape",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    }
                },
                "props": {
                    "type": "SvgShapeProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "maintainAspectRatio": true
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "style": {
                        "properties": {
                            "alpha-fillcolor": "1",
                            "alpha-stroke": "1",
                            "fillcolor": "#28B0B0",
                            "stroke": "#000000",
                            "strokewidth": "0"
                        },
                        "propertiesSource": {
                            "fillcolor": "value",
                            "stroke": "theme",
                            "strokewidth": "value"
                        },
                        "groups": {}
                    },
                    "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "svgshape.v2.Svg_4871da1a477a4329948662076bb515ec"
                },
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"action\":\"modeIn\",\"name\":\"FadeIn\",\"delay\":0,\"duration\":0.35},{\"action\":\"modeOut\",\"name\":\"FadeOut\",\"delay\":0,\"duration\":0.35}]"
                }
            },
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwatf5yc-mode-iwatf6281"
                            ],
                            "layout": {
                                "width": 50,
                                "height": 53,
                                "x": 36,
                                "y": 34,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "isHiddenByModes": false,
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "style": {
                                    "properties": {
                                        "alpha-fillcolor": "1",
                                        "alpha-stroke": "1",
                                        "fillcolor": "#28B0B0",
                                        "stroke": "#000000",
                                        "strokewidth": "0"
                                    },
                                    "propertiesSource": {
                                        "fillcolor": "value",
                                        "stroke": "theme",
                                        "strokewidth": "value"
                                    },
                                    "groups": {}
                                },
                                "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "svgshape.v2.Svg_4871da1a477a4329948662076bb515ec"
                            },
                            "props": {
                                "type": "SvgShapeProperties",
                                "metaData": {
                                    "schemaVersion": "1.0"
                                },
                                "maintainAspectRatio": true
                            }
                        }
                    ],
                    "isHiddenByModes": true
                },
                "skin": "svgshape.v2.Svg_4871da1a477a4329948662076bb515ec",
                "layout": {
                    "width": 50,
                    "height": 53,
                    "x": 36,
                    "y": 38,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.svgshape.SvgShape",
                "data": {
                    "type": "SvgShape",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    }
                },
                "props": {
                    "type": "SvgShapeProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "maintainAspectRatio": true
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "style": {
                        "properties": {
                            "alpha-fillcolor": "1",
                            "alpha-stroke": "1",
                            "fillcolor": "#28B0B0",
                            "stroke": "#000000",
                            "strokewidth": "0"
                        },
                        "propertiesSource": {
                            "fillcolor": "value",
                            "stroke": "theme",
                            "strokewidth": "value"
                        },
                        "groups": {}
                    },
                    "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "svgshape.v2.Svg_4871da1a477a4329948662076bb515ec"
                },
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"name\":\"FadeIn\",\"delay\":0,\"duration\":0.15,\"action\":\"modeIn\",\"params\":{\"modeIds\":[\"comp-iwatf5yc-mode-iwatf6281\"]},\"targetId\":\"\"},{\"name\":\"FadeOut\",\"delay\":0,\"duration\":0.15,\"action\":\"modeOut\",\"params\":{\"modeIds\":[\"comp-iwatf5yc-mode-iwatf6281\"]},\"targetId\":\"\"}]"
                },
                "mobileStructure": {
                    "type": "Component",
                    "modes": {
                        "definitions": []
                    },
                    "skin": "svgshape.v2.Svg_4871da1a477a4329948662076bb515ec",
                    "layout": {
                        "width": 50,
                        "height": 53,
                        "x": 36,
                        "y": 34,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.svgshape.SvgShape",
                    "data": {
                        "type": "SvgShape",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        }
                    },
                    "props": {
                        "type": "SvgShapeProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "maintainAspectRatio": true
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "properties": {
                                "alpha-fillcolor": "1",
                                "alpha-stroke": "1",
                                "fillcolor": "#28B0B0",
                                "stroke": "#000000",
                                "strokewidth": "0"
                            },
                            "propertiesSource": {
                                "fillcolor": "value",
                                "stroke": "theme",
                                "strokewidth": "value"
                            },
                            "groups": {}
                        },
                        "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "svgshape.v2.Svg_4871da1a477a4329948662076bb515ec"
                    },
                    "behaviors": {
                        "type": "ObsoleteBehaviorsList",
                        "items": "[{\"name\":\"FadeIn\",\"delay\":0,\"duration\":0.15,\"action\":\"modeIn\",\"params\":{\"modeIds\":[\"comp-iwatf5yc-mode-iwatf6281\"]},\"targetId\":\"\"},{\"name\":\"FadeOut\",\"delay\":0,\"duration\":0.15,\"action\":\"modeOut\",\"params\":{\"modeIds\":[\"comp-iwatf5yc-mode-iwatf6281\"]},\"targetId\":\"\"}]"
                    }
                }
            }
        ],
        "modes": {
            "definitions": [
                {
                    "modeId": "comp-iwatf5yc-mode-iwatf628",
                    "type": "DEFAULT",
                    "label": "MODE_LABEL_FROM_PRESET",
                    "params": ""
                },
                {
                    "modeId": "comp-iwatf5yc-mode-iwatf6281",
                    "type": "HOVER",
                    "label": "MODE_LABEL_FROM_PRESET",
                    "params": ""
                }
            ],
            "overrides": [],
            "isHiddenByModes": false
        },
        "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer",
        "layout": {
            "width": 122,
            "height": 122,
            "x": 0,
            "y": 0,
            "scale": 1,
            "rotationInDegrees": 0,
            "fixedPosition": false
        },
        "componentType": "wysiwyg.viewer.components.HoverBox",
        "props": {
            "type": "HoverBoxProperties",
            "metaData": {
                "schemaVersion": "1.0"
            },
            "mobileDisplayedModeId": "comp-iwatf5yc-mode-iwatf6281"
        },
        "design": {
            "type": "MediaContainerDesignData",
            "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false
            },
            "background": {
                "type": "BackgroundMedia",
                "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false
                },
                "color": "#28B0B0",
                "colorOpacity": 1,
                "alignType": "center",
                "fittingType": "fill",
                "scrollType": "none",
                "colorOverlay": "",
                "colorOverlayOpacity": 0,
                "showOverlayForMediaType": "WixVideo"
            },
            "cssStyle": {
                "cssBorder": {
                    "width": {
                        "top": {
                            "value": 0,
                            "unit": "px"
                        },
                        "right": {
                            "value": 0,
                            "unit": "px"
                        },
                        "bottom": {
                            "value": 0,
                            "unit": "px"
                        },
                        "left": {
                            "value": 0,
                            "unit": "px"
                        }
                    },
                    "style": {
                        "top": "solid",
                        "right": "solid",
                        "left": "solid",
                        "bottom": "solid"
                    },
                    "color": {
                        "top": {
                            "red": 0,
                            "green": 0,
                            "blue": 0,
                            "alpha": 1
                        },
                        "right": {
                            "red": 0,
                            "green": 0,
                            "blue": 0,
                            "alpha": 1
                        },
                        "left": {
                            "red": 0,
                            "green": 0,
                            "blue": 0,
                            "alpha": 1
                        },
                        "bottom": {
                            "red": 0,
                            "green": 0,
                            "blue": 0,
                            "alpha": 1
                        }
                    }
                },
                "cssBorderRadius": {
                    "topLeft": {
                        "value": 500,
                        "unit": "px"
                    },
                    "topRight": {
                        "value": 500,
                        "unit": "px"
                    },
                    "bottomLeft": {
                        "value": 500,
                        "unit": "px"
                    },
                    "bottomRight": {
                        "value": 500,
                        "unit": "px"
                    }
                }
            },
            "themeMappings": {},
            "charas": "design-ivuzyccr"
        },
        "style": {
            "type": "TopLevelStyle",
            "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false
            },
            "style": {
                "properties": {
                    "alpha-bg": "1",
                    "alpha-brd": "1",
                    "bg": "206,224,193,1",
                    "boxShadowToggleOn-shd": "false",
                    "brd": "#324158",
                    "brw": "0px",
                    "rd": "0px",
                    "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                },
                "propertiesSource": {
                    "bg": "value",
                    "brd": "value",
                    "brw": "value",
                    "rd": "value",
                    "shd": "value"
                },
                "groups": {}
            },
            "componentClassName": "wysiwyg.viewer.components.HoverBox",
            "pageId": "",
            "compId": "",
            "styleType": "custom",
            "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer"
        },
        "mobileStructure": {
            "type": "Container",
            "modes": {
                "definitions": [
                    {
                        "modeId": "comp-iwatf5yc-mode-iwatf628",
                        "type": "DEFAULT",
                        "label": "MODE_LABEL_FROM_PRESET",
                        "params": ""
                    },
                    {
                        "modeId": "comp-iwatf5yc-mode-iwatf6281",
                        "type": "HOVER",
                        "label": "MODE_LABEL_FROM_PRESET",
                        "params": ""
                    }
                ]
            },
            "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer",
            "layout": {
                "width": 122,
                "height": 122,
                "x": 99,
                "y": 0,
                "scale": 1,
                "rotationInDegrees": 0,
                "fixedPosition": false
            },
            "componentType": "wysiwyg.viewer.components.HoverBox",
            "props": {
                "type": "HoverBoxProperties",
                "metaData": {
                    "schemaVersion": "1.0"
                },
                "mobileDisplayedModeId": "comp-iwatf5yc-mode-iwatf6281"
            },
            "design": {
                "type": "MediaContainerDesignData",
                "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false
                },
                "background": {
                    "type": "BackgroundMedia",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "color": "#28B0B0",
                    "colorOpacity": 1,
                    "alignType": "center",
                    "fittingType": "fill",
                    "scrollType": "none",
                    "colorOverlay": "",
                    "colorOverlayOpacity": 0,
                    "showOverlayForMediaType": "WixVideo"
                },
                "cssStyle": {
                    "cssBorder": {
                        "width": {
                            "top": {
                                "value": 0,
                                "unit": "px"
                            },
                            "right": {
                                "value": 0,
                                "unit": "px"
                            },
                            "bottom": {
                                "value": 0,
                                "unit": "px"
                            },
                            "left": {
                                "value": 0,
                                "unit": "px"
                            }
                        },
                        "style": {
                            "top": "solid",
                            "right": "solid",
                            "left": "solid",
                            "bottom": "solid"
                        },
                        "color": {
                            "top": {
                                "red": 0,
                                "green": 0,
                                "blue": 0,
                                "alpha": 1
                            },
                            "right": {
                                "red": 0,
                                "green": 0,
                                "blue": 0,
                                "alpha": 1
                            },
                            "left": {
                                "red": 0,
                                "green": 0,
                                "blue": 0,
                                "alpha": 1
                            },
                            "bottom": {
                                "red": 0,
                                "green": 0,
                                "blue": 0,
                                "alpha": 1
                            }
                        }
                    },
                    "cssBorderRadius": {
                        "topLeft": {
                            "value": 500,
                            "unit": "px"
                        },
                        "topRight": {
                            "value": 500,
                            "unit": "px"
                        },
                        "bottomLeft": {
                            "value": 500,
                            "unit": "px"
                        },
                        "bottomRight": {
                            "value": 500,
                            "unit": "px"
                        }
                    }
                },
                "themeMappings": {},
                "charas": "design-ivuzyccr"
            },
            "style": {
                "type": "TopLevelStyle",
                "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false
                },
                "style": {
                    "properties": {
                        "alpha-bg": "1",
                        "alpha-brd": "1",
                        "bg": "206,224,193,1",
                        "boxShadowToggleOn-shd": "false",
                        "brd": "#324158",
                        "brw": "0px",
                        "rd": "0px",
                        "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                    },
                    "propertiesSource": {
                        "bg": "value",
                        "brd": "value",
                        "brw": "value",
                        "rd": "value",
                        "shd": "value"
                    },
                    "groups": {}
                },
                "componentClassName": "wysiwyg.viewer.components.HoverBox",
                "pageId": "",
                "compId": "",
                "styleType": "custom",
                "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer"
            }
        },
        "activeModes": {}
    },
    "preset": {
        "rect": {
            "width": 80,
            "height": 106,
            "x": 80,
            "y": 3395
        }
    }
}
const hbPreset29 = {
    "id": "hoverBoxPreset29",
    "structure": {
        "type": "Container",
        "components": [
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwatgmr7-mode-iwatgmsa1"
                            ],
                            "isHiddenByModes": true
                        }
                    ],
                    "isHiddenByModes": false
                },
                "skin": "svgshape.v2.Svg_58bb40ed125a42be911d795d88a3d570",
                "layout": {
                    "width": 60,
                    "height": 69,
                    "x": 31,
                    "y": 34,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.svgshape.SvgShape",
                "data": {
                    "type": "SvgShape",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    }
                },
                "props": {
                    "type": "SvgShapeProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "maintainAspectRatio": true
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "style": {
                        "properties": {
                            "alpha-fillcolor": "1",
                            "alpha-stroke": "1",
                            "fillcolor": "#FFFFFF",
                            "stroke": "#ED1566",
                            "strokewidth": "0px"
                        },
                        "propertiesSource": {
                            "fillcolor": "value",
                            "stroke": "value",
                            "strokewidth": "value"
                        },
                        "groups": {}
                    },
                    "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "svgshape.v2.Svg_58bb40ed125a42be911d795d88a3d570"
                },
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"action\":\"modeIn\",\"name\":\"FadeIn\",\"delay\":0,\"duration\":0.35},{\"action\":\"modeOut\",\"name\":\"FadeOut\",\"delay\":0,\"duration\":0.35}]"
                }
            },
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwatgmr7-mode-iwatgmsa1"
                            ],
                            "layout": {
                                "width": 60,
                                "height": 69,
                                "x": 31,
                                "y": 34,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "isHiddenByModes": false,
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "style": {
                                    "properties": {
                                        "alpha-fillcolor": "1",
                                        "alpha-stroke": "1",
                                        "fillcolor": "#72CCA7",
                                        "stroke": "#ED1566",
                                        "strokewidth": "0px"
                                    },
                                    "propertiesSource": {
                                        "fillcolor": "value",
                                        "stroke": "value",
                                        "strokewidth": "value"
                                    },
                                    "groups": {}
                                },
                                "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "svgshape.v2.Svg_58bb40ed125a42be911d795d88a3d570"
                            },
                            "props": {
                                "type": "SvgShapeProperties",
                                "metaData": {
                                    "schemaVersion": "1.0"
                                },
                                "maintainAspectRatio": true
                            }
                        }
                    ],
                    "isHiddenByModes": true
                },
                "skin": "svgshape.v2.Svg_58bb40ed125a42be911d795d88a3d570",
                "layout": {
                    "width": 60,
                    "height": 69,
                    "x": 31,
                    "y": 34,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.svgshape.SvgShape",
                "data": {
                    "type": "SvgShape",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    }
                },
                "props": {
                    "type": "SvgShapeProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "maintainAspectRatio": true
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "style": {
                        "properties": {
                            "alpha-fillcolor": "1",
                            "alpha-stroke": "1",
                            "fillcolor": "#72CCA7",
                            "stroke": "#ED1566",
                            "strokewidth": "0px"
                        },
                        "propertiesSource": {
                            "fillcolor": "value",
                            "stroke": "value",
                            "strokewidth": "value"
                        },
                        "groups": {}
                    },
                    "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "svgshape.v2.Svg_58bb40ed125a42be911d795d88a3d570"
                },
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"name\":\"FlyIn\",\"delay\":0,\"duration\":0.4,\"params\":{\"direction\":\"left\",\"modeIds\":[\"comp-iwatgmr7-mode-iwatgmsa1\"]},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"FlyOut\",\"delay\":0,\"duration\":0.4,\"params\":{\"direction\":\"left\",\"modeIds\":[\"comp-iwatgmr7-mode-iwatgmsa1\"]},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                },
                "mobileStructure": {
                    "type": "Component",
                    "modes": {
                        "definitions": []
                    },
                    "skin": "svgshape.v2.Svg_58bb40ed125a42be911d795d88a3d570",
                    "layout": {
                        "width": 60,
                        "height": 69,
                        "x": 31,
                        "y": 32,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.svgshape.SvgShape",
                    "data": {
                        "type": "SvgShape",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        }
                    },
                    "props": {
                        "type": "SvgShapeProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "maintainAspectRatio": true
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "properties": {
                                "alpha-fillcolor": "1",
                                "alpha-stroke": "1",
                                "fillcolor": "#72CCA7",
                                "stroke": "#ED1566",
                                "strokewidth": "0px"
                            },
                            "propertiesSource": {
                                "fillcolor": "value",
                                "stroke": "value",
                                "strokewidth": "value"
                            },
                            "groups": {}
                        },
                        "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "svgshape.v2.Svg_58bb40ed125a42be911d795d88a3d570"
                    },
                    "behaviors": {
                        "type": "ObsoleteBehaviorsList",
                        "items": "[{\"name\":\"FlyIn\",\"delay\":0,\"duration\":0.4,\"params\":{\"direction\":\"left\",\"modeIds\":[\"comp-iwatgmr7-mode-iwatgmsa1\"]},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"FlyOut\",\"delay\":0,\"duration\":0.4,\"params\":{\"direction\":\"left\",\"modeIds\":[\"comp-iwatgmr7-mode-iwatgmsa1\"]},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                    }
                }
            }
        ],
        "modes": {
            "definitions": [
                {
                    "modeId": "comp-iwatgmr7-mode-iwatgmsa",
                    "type": "DEFAULT",
                    "label": "MODE_LABEL_FROM_PRESET",
                    "params": ""
                },
                {
                    "modeId": "comp-iwatgmr7-mode-iwatgmsa1",
                    "type": "HOVER",
                    "label": "MODE_LABEL_FROM_PRESET",
                    "params": ""
                }
            ],
            "overrides": [
                {
                    "modeIds": [
                        "comp-iwatgmr7-mode-iwatgmsa"
                    ],
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "1.0",
                                "isHidden": false
                            },
                            "color": "#72CCA7",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0,
                            "showOverlayForMediaType": "WixVideo"
                        },
                        "cssStyle": {
                            "cssBorder": {
                                "width": {
                                    "top": {
                                        "value": 0,
                                        "unit": "px"
                                    },
                                    "right": {
                                        "value": 0,
                                        "unit": "px"
                                    },
                                    "bottom": {
                                        "value": 0,
                                        "unit": "px"
                                    },
                                    "left": {
                                        "value": 0,
                                        "unit": "px"
                                    }
                                },
                                "style": {
                                    "top": "solid",
                                    "right": "solid",
                                    "left": "solid",
                                    "bottom": "solid"
                                },
                                "color": {
                                    "top": {
                                        "red": 114,
                                        "green": 204,
                                        "blue": 167,
                                        "alpha": 1
                                    },
                                    "right": {
                                        "red": 114,
                                        "green": 204,
                                        "blue": 167,
                                        "alpha": 1
                                    },
                                    "left": {
                                        "red": 114,
                                        "green": 204,
                                        "blue": 167,
                                        "alpha": 1
                                    },
                                    "bottom": {
                                        "red": 114,
                                        "green": 204,
                                        "blue": 167,
                                        "alpha": 1
                                    }
                                }
                            },
                            "cssBorderRadius": {
                                "topLeft": {
                                    "value": 500,
                                    "unit": "px"
                                },
                                "topRight": {
                                    "value": 500,
                                    "unit": "px"
                                },
                                "bottomLeft": {
                                    "value": 500,
                                    "unit": "px"
                                },
                                "bottomRight": {
                                    "value": 500,
                                    "unit": "px"
                                }
                            }
                        },
                        "themeMappings": {},
                        "charas": "design-ivv06dnn"
                    }
                },
                {
                    "modeIds": [
                        "comp-iwatgmr7-mode-iwatgmsa1"
                    ],
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "1.0",
                                "isHidden": false
                            },
                            "color": "#FFFFFF",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0,
                            "showOverlayForMediaType": "WixVideo"
                        },
                        "cssStyle": {
                            "cssBorder": {
                                "width": {
                                    "top": {
                                        "value": 5,
                                        "unit": "px"
                                    },
                                    "right": {
                                        "value": 5,
                                        "unit": "px"
                                    },
                                    "bottom": {
                                        "value": 5,
                                        "unit": "px"
                                    },
                                    "left": {
                                        "value": 5,
                                        "unit": "px"
                                    }
                                },
                                "style": {
                                    "top": "solid",
                                    "right": "solid",
                                    "left": "solid",
                                    "bottom": "solid"
                                },
                                "color": {
                                    "top": {
                                        "red": 114,
                                        "green": 204,
                                        "blue": 167,
                                        "alpha": 1
                                    },
                                    "right": {
                                        "red": 114,
                                        "green": 204,
                                        "blue": 167,
                                        "alpha": 1
                                    },
                                    "left": {
                                        "red": 114,
                                        "green": 204,
                                        "blue": 167,
                                        "alpha": 1
                                    },
                                    "bottom": {
                                        "red": 114,
                                        "green": 204,
                                        "blue": 167,
                                        "alpha": 1
                                    }
                                }
                            },
                            "cssBorderRadius": {
                                "topLeft": {
                                    "value": 500,
                                    "unit": "px"
                                },
                                "topRight": {
                                    "value": 500,
                                    "unit": "px"
                                },
                                "bottomLeft": {
                                    "value": 500,
                                    "unit": "px"
                                },
                                "bottomRight": {
                                    "value": 500,
                                    "unit": "px"
                                }
                            }
                        },
                        "themeMappings": {},
                        "charas": "design-ivv3ylvs"
                    }
                }
            ],
            "isHiddenByModes": false
        },
        "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer",
        "layout": {
            "width": 122,
            "height": 122,
            "x": 0,
            "y": 0,
            "scale": 1,
            "rotationInDegrees": 0,
            "fixedPosition": false
        },
        "componentType": "wysiwyg.viewer.components.HoverBox",
        "props": {
            "type": "HoverBoxProperties",
            "metaData": {
                "schemaVersion": "1.0"
            },
            "mobileDisplayedModeId": "comp-iwatgmr7-mode-iwatgmsa1"
        },
        "design": {
            "type": "MediaContainerDesignData",
            "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false
            },
            "background": {
                "type": "BackgroundMedia",
                "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false
                },
                "color": "#FFFFFF",
                "colorOpacity": 1,
                "alignType": "center",
                "fittingType": "fill",
                "scrollType": "none",
                "colorOverlay": "",
                "colorOverlayOpacity": 0,
                "showOverlayForMediaType": "WixVideo"
            },
            "cssStyle": {
                "cssBorder": {
                    "width": {
                        "top": {
                            "value": 3,
                            "unit": "px"
                        },
                        "right": {
                            "value": 3,
                            "unit": "px"
                        },
                        "bottom": {
                            "value": 3,
                            "unit": "px"
                        },
                        "left": {
                            "value": 3,
                            "unit": "px"
                        }
                    },
                    "style": {
                        "top": "solid",
                        "right": "solid",
                        "left": "solid",
                        "bottom": "solid"
                    },
                    "color": {
                        "top": {
                            "red": 114,
                            "green": 204,
                            "blue": 167,
                            "alpha": 1
                        },
                        "right": {
                            "red": 114,
                            "green": 204,
                            "blue": 167,
                            "alpha": 1
                        },
                        "left": {
                            "red": 114,
                            "green": 204,
                            "blue": 167,
                            "alpha": 1
                        },
                        "bottom": {
                            "red": 114,
                            "green": 204,
                            "blue": 167,
                            "alpha": 1
                        }
                    }
                },
                "cssBorderRadius": {
                    "topLeft": {
                        "value": 500,
                        "unit": "px"
                    },
                    "topRight": {
                        "value": 500,
                        "unit": "px"
                    },
                    "bottomLeft": {
                        "value": 500,
                        "unit": "px"
                    },
                    "bottomRight": {
                        "value": 500,
                        "unit": "px"
                    }
                }
            },
            "themeMappings": {},
            "charas": "design-ivuyuron"
        },
        "style": {
            "type": "TopLevelStyle",
            "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false
            },
            "style": {
                "properties": {
                    "alpha-bg": "1",
                    "alpha-brd": "1",
                    "bg": "206,224,193,1",
                    "boxShadowToggleOn-shd": "false",
                    "brd": "#324158",
                    "brw": "0px",
                    "rd": "0px",
                    "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                },
                "propertiesSource": {
                    "bg": "value",
                    "brd": "value",
                    "brw": "value",
                    "rd": "value",
                    "shd": "value"
                },
                "groups": {}
            },
            "componentClassName": "wysiwyg.viewer.components.HoverBox",
            "pageId": "",
            "compId": "",
            "styleType": "custom",
            "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer"
        },
        "mobileStructure": {
            "type": "Container",
            "modes": {
                "definitions": [
                    {
                        "modeId": "comp-iwatgmr7-mode-iwatgmsa",
                        "type": "DEFAULT",
                        "label": "MODE_LABEL_FROM_PRESET",
                        "params": ""
                    },
                    {
                        "modeId": "comp-iwatgmr7-mode-iwatgmsa1",
                        "type": "HOVER",
                        "label": "MODE_LABEL_FROM_PRESET",
                        "params": ""
                    }
                ]
            },
            "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer",
            "layout": {
                "width": 122,
                "height": 122,
                "x": 99,
                "y": 0,
                "scale": 1,
                "rotationInDegrees": 0,
                "fixedPosition": false
            },
            "componentType": "wysiwyg.viewer.components.HoverBox",
            "props": {
                "type": "HoverBoxProperties",
                "metaData": {
                    "schemaVersion": "1.0"
                },
                "mobileDisplayedModeId": "comp-iwatgmr7-mode-iwatgmsa1"
            },
            "design": {
                "type": "MediaContainerDesignData",
                "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false
                },
                "background": {
                    "type": "BackgroundMedia",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "color": "#FFFFFF",
                    "colorOpacity": 1,
                    "alignType": "center",
                    "fittingType": "fill",
                    "scrollType": "none",
                    "colorOverlay": "",
                    "colorOverlayOpacity": 0,
                    "showOverlayForMediaType": "WixVideo"
                },
                "cssStyle": {
                    "cssBorder": {
                        "width": {
                            "top": {
                                "value": 5,
                                "unit": "px"
                            },
                            "right": {
                                "value": 5,
                                "unit": "px"
                            },
                            "bottom": {
                                "value": 5,
                                "unit": "px"
                            },
                            "left": {
                                "value": 5,
                                "unit": "px"
                            }
                        },
                        "style": {
                            "top": "solid",
                            "right": "solid",
                            "left": "solid",
                            "bottom": "solid"
                        },
                        "color": {
                            "top": {
                                "red": 114,
                                "green": 204,
                                "blue": 167,
                                "alpha": 1
                            },
                            "right": {
                                "red": 114,
                                "green": 204,
                                "blue": 167,
                                "alpha": 1
                            },
                            "left": {
                                "red": 114,
                                "green": 204,
                                "blue": 167,
                                "alpha": 1
                            },
                            "bottom": {
                                "red": 114,
                                "green": 204,
                                "blue": 167,
                                "alpha": 1
                            }
                        }
                    },
                    "cssBorderRadius": {
                        "topLeft": {
                            "value": 500,
                            "unit": "px"
                        },
                        "topRight": {
                            "value": 500,
                            "unit": "px"
                        },
                        "bottomLeft": {
                            "value": 500,
                            "unit": "px"
                        },
                        "bottomRight": {
                            "value": 500,
                            "unit": "px"
                        }
                    }
                },
                "themeMappings": {},
                "charas": "design-ivv3ylvs"
            },
            "style": {
                "type": "TopLevelStyle",
                "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false
                },
                "style": {
                    "properties": {
                        "alpha-bg": "1",
                        "alpha-brd": "1",
                        "bg": "206,224,193,1",
                        "boxShadowToggleOn-shd": "false",
                        "brd": "#324158",
                        "brw": "0px",
                        "rd": "0px",
                        "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                    },
                    "propertiesSource": {
                        "bg": "value",
                        "brd": "value",
                        "brw": "value",
                        "rd": "value",
                        "shd": "value"
                    },
                    "groups": {}
                },
                "componentClassName": "wysiwyg.viewer.components.HoverBox",
                "pageId": "",
                "compId": "",
                "styleType": "custom",
                "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer"
            }
        },
        "activeModes": {}
    },
    "preset": {
        "rect": {
            "width": 80,
            "height": 106,
            "x": 161,
            "y": 3395
        }
    }
}
const hbPreset30 = {
    "id": "hoverBoxPreset30",
    "structure": {
        "type": "Container",
        "components": [
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwathdcp-mode-iwathdh61"
                            ],
                            "isHiddenByModes": true,
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "style": {
                                    "properties": {
                                        "alpha-fillcolor": "1",
                                        "alpha-stroke": "1",
                                        "fillcolor": "#F8E36C",
                                        "stroke": "#000000",
                                        "strokewidth": "0"
                                    },
                                    "propertiesSource": {
                                        "fillcolor": "value",
                                        "stroke": "theme",
                                        "strokewidth": "value"
                                    },
                                    "groups": {}
                                },
                                "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "svgshape.v2.Svg_bc90a45319854d6088e34efb347342e0"
                            }
                        }
                    ],
                    "isHiddenByModes": false
                },
                "skin": "svgshape.v2.Svg_bc90a45319854d6088e34efb347342e0",
                "layout": {
                    "width": 60,
                    "height": 57,
                    "x": 31,
                    "y": 36,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.svgshape.SvgShape",
                "data": {
                    "type": "SvgShape",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    }
                },
                "props": {
                    "type": "SvgShapeProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "maintainAspectRatio": true
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "style": {
                        "properties": {
                            "alpha-fillcolor": "1",
                            "alpha-stroke": "1",
                            "fillcolor": "#FFFFFF",
                            "stroke": "#000000",
                            "strokewidth": "0"
                        },
                        "propertiesSource": {
                            "fillcolor": "value",
                            "stroke": "theme",
                            "strokewidth": "value"
                        },
                        "groups": {}
                    },
                    "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "svgshape.v2.Svg_bc90a45319854d6088e34efb347342e0"
                },
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"action\":\"modeIn\",\"name\":\"FadeIn\",\"delay\":0,\"duration\":0.35},{\"action\":\"modeOut\",\"name\":\"FadeOut\",\"delay\":0,\"duration\":0.35}]"
                }
            },
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwathdcp-mode-iwathdh61"
                            ],
                            "layout": {
                                "width": 60,
                                "height": 57,
                                "x": 31,
                                "y": 35,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "isHiddenByModes": false,
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "style": {
                                    "properties": {
                                        "alpha-fillcolor": "1",
                                        "alpha-stroke": "1",
                                        "fillcolor": "#E0E86D",
                                        "stroke": "#000000",
                                        "strokewidth": "0"
                                    },
                                    "propertiesSource": {
                                        "fillcolor": "value",
                                        "stroke": "theme",
                                        "strokewidth": "value"
                                    },
                                    "groups": {}
                                },
                                "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "svgshape.v2.Svg_bc90a45319854d6088e34efb347342e0"
                            },
                            "props": {
                                "type": "SvgShapeProperties",
                                "metaData": {
                                    "schemaVersion": "1.0"
                                },
                                "maintainAspectRatio": true
                            }
                        }
                    ],
                    "isHiddenByModes": true
                },
                "skin": "svgshape.v2.Svg_bc90a45319854d6088e34efb347342e0",
                "layout": {
                    "width": 70,
                    "height": 62,
                    "x": 26,
                    "y": 34,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.svgshape.SvgShape",
                "data": {
                    "type": "SvgShape",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    }
                },
                "props": {
                    "type": "SvgShapeProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "maintainAspectRatio": true
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "style": {
                        "properties": {
                            "alpha-fillcolor": "1",
                            "alpha-stroke": "1",
                            "fillcolor": "#F8E36C",
                            "stroke": "#000000",
                            "strokewidth": "0"
                        },
                        "propertiesSource": {
                            "fillcolor": "value",
                            "stroke": "theme",
                            "strokewidth": "value"
                        },
                        "groups": {}
                    },
                    "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "svgshape.v2.Svg_bc90a45319854d6088e34efb347342e0"
                },
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"name\":\"Reveal\",\"delay\":0,\"duration\":0.3,\"params\":{\"direction\":\"left\",\"modeIds\":[\"comp-iwathdcp-mode-iwathdh61\"]},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"Conceal\",\"delay\":0,\"duration\":0.3,\"params\":{\"direction\":\"left\",\"modeIds\":[\"comp-iwathdcp-mode-iwathdh61\"]},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                },
                "mobileStructure": {
                    "type": "Component",
                    "modes": {
                        "definitions": []
                    },
                    "skin": "svgshape.v2.Svg_bc90a45319854d6088e34efb347342e0",
                    "layout": {
                        "width": 60,
                        "height": 57,
                        "x": 31,
                        "y": 35,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.svgshape.SvgShape",
                    "data": {
                        "type": "SvgShape",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        }
                    },
                    "props": {
                        "type": "SvgShapeProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "maintainAspectRatio": true
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "properties": {
                                "alpha-fillcolor": "1",
                                "alpha-stroke": "1",
                                "fillcolor": "#E0E86D",
                                "stroke": "#000000",
                                "strokewidth": "0"
                            },
                            "propertiesSource": {
                                "fillcolor": "value",
                                "stroke": "theme",
                                "strokewidth": "value"
                            },
                            "groups": {}
                        },
                        "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "svgshape.v2.Svg_bc90a45319854d6088e34efb347342e0"
                    },
                    "behaviors": {
                        "type": "ObsoleteBehaviorsList",
                        "items": "[{\"name\":\"Reveal\",\"delay\":0,\"duration\":0.3,\"params\":{\"direction\":\"left\",\"modeIds\":[\"comp-iwathdcp-mode-iwathdh61\"]},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"Conceal\",\"delay\":0,\"duration\":0.3,\"params\":{\"direction\":\"left\",\"modeIds\":[\"comp-iwathdcp-mode-iwathdh61\"]},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                    }
                }
            }
        ],
        "modes": {
            "definitions": [
                {
                    "modeId": "comp-iwathdcp-mode-iwathdh6",
                    "type": "DEFAULT",
                    "label": "MODE_LABEL_FROM_PRESET",
                    "params": ""
                },
                {
                    "modeId": "comp-iwathdcp-mode-iwathdh61",
                    "type": "HOVER",
                    "label": "MODE_LABEL_FROM_PRESET",
                    "params": ""
                }
            ],
            "overrides": [
                {
                    "modeIds": [
                        "comp-iwathdcp-mode-iwathdh6"
                    ],
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "1.0",
                                "isHidden": false
                            },
                            "color": "#E0E86D",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0,
                            "showOverlayForMediaType": "WixVideo"
                        },
                        "cssStyle": {
                            "cssBorder": {
                                "width": {
                                    "top": {
                                        "value": 0,
                                        "unit": "px"
                                    },
                                    "right": {
                                        "value": 0,
                                        "unit": "px"
                                    },
                                    "bottom": {
                                        "value": 0,
                                        "unit": "px"
                                    },
                                    "left": {
                                        "value": 0,
                                        "unit": "px"
                                    }
                                },
                                "style": {
                                    "top": "solid",
                                    "right": "solid",
                                    "left": "solid",
                                    "bottom": "solid"
                                },
                                "color": {
                                    "top": {
                                        "red": 224,
                                        "green": 232,
                                        "blue": 109,
                                        "alpha": 1
                                    },
                                    "right": {
                                        "red": 224,
                                        "green": 232,
                                        "blue": 109,
                                        "alpha": 1
                                    },
                                    "left": {
                                        "red": 224,
                                        "green": 232,
                                        "blue": 109,
                                        "alpha": 1
                                    },
                                    "bottom": {
                                        "red": 224,
                                        "green": 232,
                                        "blue": 109,
                                        "alpha": 1
                                    }
                                }
                            },
                            "cssBorderRadius": {
                                "topLeft": {
                                    "value": 500,
                                    "unit": "px"
                                },
                                "topRight": {
                                    "value": 500,
                                    "unit": "px"
                                },
                                "bottomLeft": {
                                    "value": 500,
                                    "unit": "px"
                                },
                                "bottomRight": {
                                    "value": 500,
                                    "unit": "px"
                                }
                            }
                        },
                        "themeMappings": {},
                        "charas": "design-ivw571ge"
                    }
                }
            ],
            "isHiddenByModes": false
        },
        "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer",
        "layout": {
            "width": 122,
            "height": 122,
            "x": 0,
            "y": 0,
            "scale": 1,
            "rotationInDegrees": 0,
            "fixedPosition": false
        },
        "componentType": "wysiwyg.viewer.components.HoverBox",
        "props": {
            "type": "HoverBoxProperties",
            "metaData": {
                "schemaVersion": "1.0"
            }
        },
        "design": {
            "type": "MediaContainerDesignData",
            "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false
            },
            "background": {
                "type": "BackgroundMedia",
                "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false
                },
                "color": "#FFFFFF",
                "colorOpacity": 1,
                "alignType": "center",
                "fittingType": "fill",
                "scrollType": "none",
                "colorOverlay": "",
                "colorOverlayOpacity": 0,
                "showOverlayForMediaType": "WixVideo"
            },
            "cssStyle": {
                "cssBorder": {
                    "width": {
                        "top": {
                            "value": 5,
                            "unit": "px"
                        },
                        "right": {
                            "value": 5,
                            "unit": "px"
                        },
                        "bottom": {
                            "value": 5,
                            "unit": "px"
                        },
                        "left": {
                            "value": 5,
                            "unit": "px"
                        }
                    },
                    "style": {
                        "top": "solid",
                        "right": "solid",
                        "left": "solid",
                        "bottom": "solid"
                    },
                    "color": {
                        "top": {
                            "red": 224,
                            "green": 232,
                            "blue": 109,
                            "alpha": 1
                        },
                        "right": {
                            "red": 224,
                            "green": 232,
                            "blue": 109,
                            "alpha": 1
                        },
                        "left": {
                            "red": 224,
                            "green": 232,
                            "blue": 109,
                            "alpha": 1
                        },
                        "bottom": {
                            "red": 224,
                            "green": 232,
                            "blue": 109,
                            "alpha": 1
                        }
                    }
                },
                "cssBorderRadius": {
                    "topLeft": {
                        "value": 500,
                        "unit": "px"
                    },
                    "topRight": {
                        "value": 500,
                        "unit": "px"
                    },
                    "bottomLeft": {
                        "value": 500,
                        "unit": "px"
                    },
                    "bottomRight": {
                        "value": 500,
                        "unit": "px"
                    }
                }
            },
            "themeMappings": {},
            "charas": "design-ivw565lh1"
        },
        "style": {
            "type": "TopLevelStyle",
            "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false
            },
            "style": {
                "properties": {
                    "alpha-bg": "1",
                    "alpha-brd": "1",
                    "bg": "206,224,193,1",
                    "boxShadowToggleOn-shd": "false",
                    "brd": "#324158",
                    "brw": "0px",
                    "rd": "0px",
                    "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                },
                "propertiesSource": {
                    "bg": "value",
                    "brd": "value",
                    "brw": "value",
                    "rd": "value",
                    "shd": "value"
                },
                "groups": {}
            },
            "componentClassName": "wysiwyg.viewer.components.HoverBox",
            "pageId": "",
            "compId": "",
            "styleType": "custom",
            "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer"
        },
        "mobileStructure": {
            "type": "Container",
            "modes": {
                "definitions": [
                    {
                        "modeId": "comp-iwathdcp-mode-iwathdh6",
                        "type": "DEFAULT",
                        "label": "MODE_LABEL_FROM_PRESET",
                        "params": ""
                    },
                    {
                        "modeId": "comp-iwathdcp-mode-iwathdh61",
                        "type": "HOVER",
                        "label": "MODE_LABEL_FROM_PRESET",
                        "params": ""
                    }
                ]
            },
            "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer",
            "layout": {
                "width": 122,
                "height": 122,
                "x": 99,
                "y": 0,
                "scale": 1,
                "rotationInDegrees": 0,
                "fixedPosition": false
            },
            "componentType": "wysiwyg.viewer.components.HoverBox",
            "props": {
                "type": "HoverBoxProperties",
                "metaData": {
                    "schemaVersion": "1.0"
                }
            },
            "design": {
                "type": "MediaContainerDesignData",
                "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false
                },
                "background": {
                    "type": "BackgroundMedia",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "color": "#FFFFFF",
                    "colorOpacity": 1,
                    "alignType": "center",
                    "fittingType": "fill",
                    "scrollType": "none",
                    "colorOverlay": "",
                    "colorOverlayOpacity": 0,
                    "showOverlayForMediaType": "WixVideo"
                },
                "cssStyle": {
                    "cssBorder": {
                        "width": {
                            "top": {
                                "value": 5,
                                "unit": "px"
                            },
                            "right": {
                                "value": 5,
                                "unit": "px"
                            },
                            "bottom": {
                                "value": 5,
                                "unit": "px"
                            },
                            "left": {
                                "value": 5,
                                "unit": "px"
                            }
                        },
                        "style": {
                            "top": "solid",
                            "right": "solid",
                            "left": "solid",
                            "bottom": "solid"
                        },
                        "color": {
                            "top": {
                                "red": 224,
                                "green": 232,
                                "blue": 109,
                                "alpha": 1
                            },
                            "right": {
                                "red": 224,
                                "green": 232,
                                "blue": 109,
                                "alpha": 1
                            },
                            "left": {
                                "red": 224,
                                "green": 232,
                                "blue": 109,
                                "alpha": 1
                            },
                            "bottom": {
                                "red": 224,
                                "green": 232,
                                "blue": 109,
                                "alpha": 1
                            }
                        }
                    },
                    "cssBorderRadius": {
                        "topLeft": {
                            "value": 500,
                            "unit": "px"
                        },
                        "topRight": {
                            "value": 500,
                            "unit": "px"
                        },
                        "bottomLeft": {
                            "value": 500,
                            "unit": "px"
                        },
                        "bottomRight": {
                            "value": 500,
                            "unit": "px"
                        }
                    }
                },
                "themeMappings": {},
                "charas": "design-ivw565lh1"
            },
            "style": {
                "type": "TopLevelStyle",
                "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false
                },
                "style": {
                    "properties": {
                        "alpha-bg": "1",
                        "alpha-brd": "1",
                        "bg": "206,224,193,1",
                        "boxShadowToggleOn-shd": "false",
                        "brd": "#324158",
                        "brw": "0px",
                        "rd": "0px",
                        "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                    },
                    "propertiesSource": {
                        "bg": "value",
                        "brd": "value",
                        "brw": "value",
                        "rd": "value",
                        "shd": "value"
                    },
                    "groups": {}
                },
                "componentClassName": "wysiwyg.viewer.components.HoverBox",
                "pageId": "",
                "compId": "",
                "styleType": "custom",
                "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer"
            }
        },
        "activeModes": {}
    },
    "preset": {
        "rect": {
            "width": 80,
            "height": 106,
            "x": 243,
            "y": 3395
        }
    }
}
const hbPreset31 = {
    "id": "hoverBoxPreset31",
    "structure": {
        "type": "Container",
        "components": [
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwathsaz-mode-iwathsez1"
                            ],
                            "layout": {
                                "width": 108,
                                "height": 108,
                                "x": 6,
                                "y": 7,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "isHiddenByModes": false,
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "style": {
                                    "properties": {
                                        "alpha-fillcolor": "1",
                                        "alpha-stroke": "0",
                                        "fillcolor": "#605170",
                                        "stroke": "#FFFFFF",
                                        "strokewidth": "0"
                                    },
                                    "propertiesSource": {
                                        "alpha-fillcolor": "value",
                                        "alpha-stroke": "value",
                                        "fillcolor": "value",
                                        "stroke": "value",
                                        "strokewidth": "value"
                                    },
                                    "groups": {}
                                },
                                "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "svgshape.v1.svg_8463f60718194af748c49dddbe45b668.HollowCircle"
                            },
                            "props": {
                                "type": "SvgShapeProperties",
                                "metaData": {
                                    "schemaVersion": "1.0"
                                },
                                "maintainAspectRatio": false
                            }
                        }
                    ],
                    "isHiddenByModes": true
                },
                "skin": "svgshape.v1.svg_8463f60718194af748c49dddbe45b668.HollowCircle",
                "layout": {
                    "width": 115,
                    "height": 115,
                    "x": 3,
                    "y": 0,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.svgshape.SvgShape",
                "data": {
                    "type": "SvgShape",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    }
                },
                "props": {
                    "type": "SvgShapeProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "maintainAspectRatio": false
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "style": {
                        "properties": {
                            "alpha-fillcolor": "0",
                            "alpha-stroke": "1",
                            "fillcolor": "#72CCA7",
                            "stroke": "#72CCA7",
                            "strokewidth": "3"
                        },
                        "propertiesSource": {
                            "alpha-fillcolor": "value",
                            "fillcolor": "value",
                            "stroke": "value",
                            "strokewidth": "value"
                        },
                        "groups": {}
                    },
                    "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "svgshape.v1.svg_8463f60718194af748c49dddbe45b668.HollowCircle"
                },
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"name\":\"DropIn\",\"delay\":0,\"duration\":0.15,\"action\":\"modeIn\",\"params\":{\"modeIds\":[\"comp-iwathsaz-mode-iwathsez1\"]},\"targetId\":\"\"},{\"name\":\"PopOut\",\"delay\":0,\"duration\":0.15,\"action\":\"modeOut\",\"params\":{\"modeIds\":[\"comp-iwathsaz-mode-iwathsez1\"]},\"targetId\":\"\"}]"
                },
                "mobileStructure": {
                    "type": "Component",
                    "modes": {
                        "definitions": []
                    },
                    "skin": "svgshape.v1.svg_8463f60718194af748c49dddbe45b668.HollowCircle",
                    "layout": {
                        "width": 108,
                        "height": 108,
                        "x": 6,
                        "y": 7,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.svgshape.SvgShape",
                    "data": {
                        "type": "SvgShape",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        }
                    },
                    "props": {
                        "type": "SvgShapeProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "maintainAspectRatio": false
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "properties": {
                                "alpha-fillcolor": "1",
                                "alpha-stroke": "0",
                                "fillcolor": "#605170",
                                "stroke": "#FFFFFF",
                                "strokewidth": "0"
                            },
                            "propertiesSource": {
                                "alpha-fillcolor": "value",
                                "alpha-stroke": "value",
                                "fillcolor": "value",
                                "stroke": "value",
                                "strokewidth": "value"
                            },
                            "groups": {}
                        },
                        "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "svgshape.v1.svg_8463f60718194af748c49dddbe45b668.HollowCircle"
                    },
                    "behaviors": {
                        "type": "ObsoleteBehaviorsList",
                        "items": "[{\"name\":\"DropIn\",\"delay\":0,\"duration\":0.15,\"action\":\"modeIn\",\"params\":{\"modeIds\":[\"comp-iwathsaz-mode-iwathsez1\"]},\"targetId\":\"\"},{\"name\":\"PopOut\",\"delay\":0,\"duration\":0.15,\"action\":\"modeOut\",\"params\":{\"modeIds\":[\"comp-iwathsaz-mode-iwathsez1\"]},\"targetId\":\"\"}]"
                    }
                }
            },
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwathsaz-mode-iwathsez"
                            ],
                            "layout": {
                                "width": 109,
                                "height": 53,
                                "x": 6,
                                "y": 36,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "style": {
                                    "properties": {
                                        "alpha-fillcolor": "1",
                                        "alpha-stroke": "1",
                                        "fillcolor": "#605170",
                                        "stroke": "#000000",
                                        "strokewidth": "0"
                                    },
                                    "propertiesSource": {
                                        "fillcolor": "value",
                                        "stroke": "theme",
                                        "strokewidth": "value"
                                    },
                                    "groups": {}
                                },
                                "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "svgshape.v2.Svg_0a88206e5da6422e8e0b0148b23f1944"
                            }
                        },
                        {
                            "modeIds": [
                                "comp-iwathsaz-mode-iwathsez1"
                            ],
                            "isHiddenByModes": true
                        }
                    ],
                    "isHiddenByModes": false
                },
                "skin": "svgshape.v2.Svg_0a88206e5da6422e8e0b0148b23f1944",
                "layout": {
                    "width": 57,
                    "height": 53,
                    "x": 30,
                    "y": 37,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.svgshape.SvgShape",
                "data": {
                    "type": "SvgShape",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    }
                },
                "props": {
                    "type": "SvgShapeProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "maintainAspectRatio": true
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "style": {
                        "properties": {
                            "alpha-fillcolor": "1",
                            "alpha-stroke": "1",
                            "fillcolor": "#FFFFFF",
                            "stroke": "#000000",
                            "strokewidth": "0"
                        },
                        "propertiesSource": {
                            "fillcolor": "theme",
                            "stroke": "theme",
                            "strokewidth": "value"
                        },
                        "groups": {}
                    },
                    "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "svgshape.v2.Svg_0a88206e5da6422e8e0b0148b23f1944"
                },
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"action\":\"modeIn\",\"name\":\"FadeIn\",\"delay\":0,\"duration\":0.35},{\"action\":\"modeOut\",\"name\":\"FadeOut\",\"delay\":0,\"duration\":0.35}]"
                }
            },
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwathsaz-mode-iwathsez1"
                            ],
                            "layout": {
                                "width": 57,
                                "height": 53,
                                "x": 31,
                                "y": 37,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "isHiddenByModes": false,
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "style": {
                                    "properties": {
                                        "alpha-fillcolor": "1",
                                        "alpha-stroke": "1",
                                        "fillcolor": "#FFFFFF",
                                        "stroke": "#000000",
                                        "strokewidth": "0"
                                    },
                                    "propertiesSource": {
                                        "fillcolor": "theme",
                                        "stroke": "theme",
                                        "strokewidth": "value"
                                    },
                                    "groups": {}
                                },
                                "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "svgshape.v2.Svg_0a88206e5da6422e8e0b0148b23f1944"
                            },
                            "props": {
                                "type": "SvgShapeProperties",
                                "metaData": {
                                    "schemaVersion": "1.0"
                                },
                                "maintainAspectRatio": true
                            }
                        }
                    ],
                    "isHiddenByModes": true
                },
                "skin": "svgshape.v2.Svg_0a88206e5da6422e8e0b0148b23f1944",
                "layout": {
                    "width": 57,
                    "height": 53,
                    "x": 34,
                    "y": 40,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.svgshape.SvgShape",
                "data": {
                    "type": "SvgShape",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    }
                },
                "props": {
                    "type": "SvgShapeProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "maintainAspectRatio": true
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "style": {
                        "properties": {
                            "alpha-fillcolor": "1",
                            "alpha-stroke": "1",
                            "fillcolor": "#FFFFFF",
                            "stroke": "#000000",
                            "strokewidth": "0"
                        },
                        "propertiesSource": {
                            "fillcolor": "theme",
                            "stroke": "theme",
                            "strokewidth": "value"
                        },
                        "groups": {}
                    },
                    "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "svgshape.v2.Svg_0a88206e5da6422e8e0b0148b23f1944"
                },
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"name\":\"FadeIn\",\"delay\":0,\"duration\":0.25,\"action\":\"modeIn\",\"params\":{\"modeIds\":[\"comp-iwathsaz-mode-iwathsez1\"]},\"targetId\":\"\"},{\"name\":\"FadeOut\",\"delay\":0,\"duration\":0.25,\"action\":\"modeOut\",\"params\":{\"modeIds\":[\"comp-iwathsaz-mode-iwathsez1\"]},\"targetId\":\"\"}]"
                },
                "mobileStructure": {
                    "type": "Component",
                    "modes": {
                        "definitions": []
                    },
                    "skin": "svgshape.v2.Svg_0a88206e5da6422e8e0b0148b23f1944",
                    "layout": {
                        "width": 57,
                        "height": 53,
                        "x": 31,
                        "y": 37,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.svgshape.SvgShape",
                    "data": {
                        "type": "SvgShape",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        }
                    },
                    "props": {
                        "type": "SvgShapeProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "maintainAspectRatio": true
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "properties": {
                                "alpha-fillcolor": "1",
                                "alpha-stroke": "1",
                                "fillcolor": "#FFFFFF",
                                "stroke": "#000000",
                                "strokewidth": "0"
                            },
                            "propertiesSource": {
                                "fillcolor": "theme",
                                "stroke": "theme",
                                "strokewidth": "value"
                            },
                            "groups": {}
                        },
                        "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "svgshape.v2.Svg_0a88206e5da6422e8e0b0148b23f1944"
                    },
                    "behaviors": {
                        "type": "ObsoleteBehaviorsList",
                        "items": "[{\"name\":\"FadeIn\",\"delay\":0,\"duration\":0.25,\"action\":\"modeIn\",\"params\":{\"modeIds\":[\"comp-iwathsaz-mode-iwathsez1\"]},\"targetId\":\"\"},{\"name\":\"FadeOut\",\"delay\":0,\"duration\":0.25,\"action\":\"modeOut\",\"params\":{\"modeIds\":[\"comp-iwathsaz-mode-iwathsez1\"]},\"targetId\":\"\"}]"
                    }
                }
            }
        ],
        "modes": {
            "definitions": [
                {
                    "modeId": "comp-iwathsaz-mode-iwathsez",
                    "type": "DEFAULT",
                    "label": "MODE_LABEL_FROM_PRESET",
                    "params": ""
                },
                {
                    "modeId": "comp-iwathsaz-mode-iwathsez1",
                    "type": "HOVER",
                    "label": "MODE_LABEL_FROM_PRESET",
                    "params": ""
                }
            ],
            "overrides": [],
            "isHiddenByModes": false
        },
        "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer",
        "layout": {
            "width": 120,
            "height": 122,
            "x": 0,
            "y": 0,
            "scale": 1,
            "rotationInDegrees": 0,
            "fixedPosition": false
        },
        "componentType": "wysiwyg.viewer.components.HoverBox",
        "props": {
            "type": "HoverBoxProperties",
            "metaData": {
                "schemaVersion": "1.0"
            }
        },
        "design": {
            "type": "MediaContainerDesignData",
            "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false
            },
            "background": {
                "type": "BackgroundMedia",
                "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false
                },
                "color": "#FFFFFF",
                "colorOpacity": 1,
                "alignType": "center",
                "fittingType": "fill",
                "scrollType": "none",
                "colorOverlay": "",
                "colorOverlayOpacity": 0,
                "showOverlayForMediaType": "WixVideo"
            },
            "cssStyle": {
                "cssBorder": {
                    "width": {
                        "top": {
                            "value": 3,
                            "unit": "px"
                        },
                        "right": {
                            "value": 3,
                            "unit": "px"
                        },
                        "bottom": {
                            "value": 3,
                            "unit": "px"
                        },
                        "left": {
                            "value": 3,
                            "unit": "px"
                        }
                    },
                    "style": {
                        "top": "solid",
                        "right": "solid",
                        "left": "solid",
                        "bottom": "solid"
                    },
                    "color": {
                        "top": {
                            "red": 96,
                            "green": 81,
                            "blue": 112,
                            "alpha": 1
                        },
                        "right": {
                            "red": 96,
                            "green": 81,
                            "blue": 112,
                            "alpha": 1
                        },
                        "left": {
                            "red": 96,
                            "green": 81,
                            "blue": 112,
                            "alpha": 1
                        },
                        "bottom": {
                            "red": 96,
                            "green": 81,
                            "blue": 112,
                            "alpha": 1
                        }
                    }
                },
                "cssBorderRadius": {
                    "topLeft": {
                        "value": 500,
                        "unit": "px"
                    },
                    "topRight": {
                        "value": 500,
                        "unit": "px"
                    },
                    "bottomLeft": {
                        "value": 500,
                        "unit": "px"
                    },
                    "bottomRight": {
                        "value": 500,
                        "unit": "px"
                    }
                }
            },
            "themeMappings": {},
            "charas": "design-ivw4z4sy1"
        },
        "style": {
            "type": "TopLevelStyle",
            "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false
            },
            "style": {
                "properties": {
                    "alpha-bg": "1",
                    "alpha-brd": "1",
                    "bg": "206,224,193,1",
                    "boxShadowToggleOn-shd": "false",
                    "brd": "#324158",
                    "brw": "0px",
                    "rd": "0px",
                    "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                },
                "propertiesSource": {
                    "bg": "value",
                    "brd": "value",
                    "brw": "value",
                    "rd": "value",
                    "shd": "value"
                },
                "groups": {}
            },
            "componentClassName": "wysiwyg.viewer.components.HoverBox",
            "pageId": "",
            "compId": "",
            "styleType": "custom",
            "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer"
        },
        "mobileStructure": {
            "type": "Container",
            "modes": {
                "definitions": [
                    {
                        "modeId": "comp-iwathsaz-mode-iwathsez",
                        "type": "DEFAULT",
                        "label": "MODE_LABEL_FROM_PRESET",
                        "params": ""
                    },
                    {
                        "modeId": "comp-iwathsaz-mode-iwathsez1",
                        "type": "HOVER",
                        "label": "MODE_LABEL_FROM_PRESET",
                        "params": ""
                    }
                ]
            },
            "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer",
            "layout": {
                "width": 120,
                "height": 122,
                "x": 100,
                "y": 0,
                "scale": 1,
                "rotationInDegrees": 0,
                "fixedPosition": false
            },
            "componentType": "wysiwyg.viewer.components.HoverBox",
            "props": {
                "type": "HoverBoxProperties",
                "metaData": {
                    "schemaVersion": "1.0"
                }
            },
            "design": {
                "type": "MediaContainerDesignData",
                "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false
                },
                "background": {
                    "type": "BackgroundMedia",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "color": "#FFFFFF",
                    "colorOpacity": 1,
                    "alignType": "center",
                    "fittingType": "fill",
                    "scrollType": "none",
                    "colorOverlay": "",
                    "colorOverlayOpacity": 0,
                    "showOverlayForMediaType": "WixVideo"
                },
                "cssStyle": {
                    "cssBorder": {
                        "width": {
                            "top": {
                                "value": 3,
                                "unit": "px"
                            },
                            "right": {
                                "value": 3,
                                "unit": "px"
                            },
                            "bottom": {
                                "value": 3,
                                "unit": "px"
                            },
                            "left": {
                                "value": 3,
                                "unit": "px"
                            }
                        },
                        "style": {
                            "top": "solid",
                            "right": "solid",
                            "left": "solid",
                            "bottom": "solid"
                        },
                        "color": {
                            "top": {
                                "red": 96,
                                "green": 81,
                                "blue": 112,
                                "alpha": 1
                            },
                            "right": {
                                "red": 96,
                                "green": 81,
                                "blue": 112,
                                "alpha": 1
                            },
                            "left": {
                                "red": 96,
                                "green": 81,
                                "blue": 112,
                                "alpha": 1
                            },
                            "bottom": {
                                "red": 96,
                                "green": 81,
                                "blue": 112,
                                "alpha": 1
                            }
                        }
                    },
                    "cssBorderRadius": {
                        "topLeft": {
                            "value": 500,
                            "unit": "px"
                        },
                        "topRight": {
                            "value": 500,
                            "unit": "px"
                        },
                        "bottomLeft": {
                            "value": 500,
                            "unit": "px"
                        },
                        "bottomRight": {
                            "value": 500,
                            "unit": "px"
                        }
                    }
                },
                "themeMappings": {},
                "charas": "design-ivw4z4sy1"
            },
            "style": {
                "type": "TopLevelStyle",
                "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false
                },
                "style": {
                    "properties": {
                        "alpha-bg": "1",
                        "alpha-brd": "1",
                        "bg": "206,224,193,1",
                        "boxShadowToggleOn-shd": "false",
                        "brd": "#324158",
                        "brw": "0px",
                        "rd": "0px",
                        "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                    },
                    "propertiesSource": {
                        "bg": "value",
                        "brd": "value",
                        "brw": "value",
                        "rd": "value",
                        "shd": "value"
                    },
                    "groups": {}
                },
                "componentClassName": "wysiwyg.viewer.components.HoverBox",
                "pageId": "",
                "compId": "",
                "styleType": "custom",
                "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer"
            }
        },
        "activeModes": {}
    },
    "preset": {
        "rect": {
            "width": 80,
            "height": 104,
            "x": 0,
            "y": 3501
        }
    }
}
const hbPreset32 = {
    "id": "hoverBoxPreset32",
    "structure": {
        "type": "Container",
        "components": [
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwati7qh-mode-iwati7uk"
                            ],
                            "layout": {
                                "width": 40,
                                "height": 52,
                                "x": 41,
                                "y": 31,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "style": {
                                    "properties": {
                                        "alpha-fillcolor": "1",
                                        "alpha-stroke": "1",
                                        "fillcolor": "#FFFFFF",
                                        "stroke": "#000000",
                                        "strokewidth": "0"
                                    },
                                    "propertiesSource": {
                                        "fillcolor": "value",
                                        "stroke": "theme",
                                        "strokewidth": "value"
                                    },
                                    "groups": {}
                                },
                                "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "svgshape.v2.Svg_fb3725a18279401e96aedb5c794ba1dc"
                            }
                        },
                        {
                            "modeIds": [
                                "comp-iwati7qh-mode-iwati7uj"
                            ],
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "style": {
                                    "properties": {
                                        "alpha-fillcolor": "1",
                                        "alpha-stroke": "1",
                                        "fillcolor": "#FF937D",
                                        "stroke": "#000000",
                                        "strokewidth": "0"
                                    },
                                    "propertiesSource": {
                                        "fillcolor": "value",
                                        "stroke": "theme",
                                        "strokewidth": "value"
                                    },
                                    "groups": {}
                                },
                                "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "svgshape.v2.Svg_fb3725a18279401e96aedb5c794ba1dc"
                            }
                        }
                    ],
                    "isHiddenByModes": false
                },
                "skin": "svgshape.v2.Svg_fb3725a18279401e96aedb5c794ba1dc",
                "layout": {
                    "width": 40,
                    "height": 52,
                    "x": 41,
                    "y": 35,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.svgshape.SvgShape",
                "data": {
                    "type": "SvgShape",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    }
                },
                "props": {
                    "type": "SvgShapeProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "maintainAspectRatio": true
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "style": {
                        "properties": {
                            "alpha-fillcolor": "1",
                            "alpha-stroke": "1",
                            "fillcolor": "#F8E36C",
                            "stroke": "#000000",
                            "strokewidth": "0"
                        },
                        "propertiesSource": {
                            "fillcolor": "value",
                            "stroke": "theme",
                            "strokewidth": "value"
                        },
                        "groups": {}
                    },
                    "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "svgshape.v2.Svg_fb3725a18279401e96aedb5c794ba1dc"
                },
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"action\":\"modeIn\",\"name\":\"FadeIn\",\"delay\":0,\"duration\":0.35},{\"action\":\"modeOut\",\"name\":\"FadeOut\",\"delay\":0,\"duration\":0.35},{\"action\":\"modeChange\",\"name\":\"ModesMotion\",\"delay\":0,\"duration\":0.1,\"params\":{\"timingFunction\":\"EaseInOut\",\"modeIds\":[\"comp-iwati7qh-mode-iwati7uk\"]},\"targetId\":\"\"}]"
                },
                "mobileStructure": {
                    "type": "Component",
                    "modes": {
                        "definitions": []
                    },
                    "skin": "svgshape.v2.Svg_fb3725a18279401e96aedb5c794ba1dc",
                    "layout": {
                        "width": 40,
                        "height": 52,
                        "x": 41,
                        "y": 35,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.svgshape.SvgShape",
                    "data": {
                        "type": "SvgShape",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        }
                    },
                    "props": {
                        "type": "SvgShapeProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "maintainAspectRatio": true
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "properties": {
                                "alpha-fillcolor": "1",
                                "alpha-stroke": "1",
                                "fillcolor": "#FFFFFF",
                                "stroke": "#000000",
                                "strokewidth": "0"
                            },
                            "propertiesSource": {
                                "fillcolor": "value",
                                "stroke": "theme",
                                "strokewidth": "value"
                            },
                            "groups": {}
                        },
                        "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "svgshape.v2.Svg_fb3725a18279401e96aedb5c794ba1dc"
                    },
                    "behaviors": {
                        "type": "ObsoleteBehaviorsList",
                        "items": "[{\"action\":\"modeIn\",\"name\":\"FadeIn\",\"delay\":0,\"duration\":0.35},{\"action\":\"modeOut\",\"name\":\"FadeOut\",\"delay\":0,\"duration\":0.35},{\"action\":\"modeChange\",\"name\":\"ModesMotion\",\"delay\":0,\"duration\":0.1,\"params\":{\"timingFunction\":\"EaseInOut\",\"modeIds\":[\"comp-iwati7qh-mode-iwati7uk\"]},\"targetId\":\"\"}]"
                    }
                }
            }
        ],
        "modes": {
            "definitions": [
                {
                    "modeId": "comp-iwati7qh-mode-iwati7uj",
                    "type": "DEFAULT",
                    "label": "MODE_LABEL_FROM_PRESET",
                    "params": ""
                },
                {
                    "modeId": "comp-iwati7qh-mode-iwati7uk",
                    "type": "HOVER",
                    "label": "MODE_LABEL_FROM_PRESET",
                    "params": ""
                }
            ],
            "overrides": [
                {
                    "modeIds": [
                        "comp-iwati7qh-mode-iwati7uk"
                    ],
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "1.0",
                                "isHidden": false
                            },
                            "color": "#FF937D",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0,
                            "showOverlayForMediaType": "WixVideo"
                        },
                        "cssStyle": {
                            "cssBorder": {
                                "width": {
                                    "top": {
                                        "value": 3,
                                        "unit": "px"
                                    },
                                    "right": {
                                        "value": 3,
                                        "unit": "px"
                                    },
                                    "bottom": {
                                        "value": 3,
                                        "unit": "px"
                                    },
                                    "left": {
                                        "value": 3,
                                        "unit": "px"
                                    }
                                },
                                "style": {
                                    "top": "solid",
                                    "right": "solid",
                                    "left": "solid",
                                    "bottom": "solid"
                                },
                                "color": {
                                    "top": {
                                        "red": 255,
                                        "green": 147,
                                        "blue": 125,
                                        "alpha": 1
                                    },
                                    "right": {
                                        "red": 255,
                                        "green": 147,
                                        "blue": 125,
                                        "alpha": 1
                                    },
                                    "left": {
                                        "red": 255,
                                        "green": 147,
                                        "blue": 125,
                                        "alpha": 1
                                    },
                                    "bottom": {
                                        "red": 255,
                                        "green": 147,
                                        "blue": 125,
                                        "alpha": 1
                                    }
                                }
                            },
                            "cssBorderRadius": {
                                "topLeft": {
                                    "value": 500,
                                    "unit": "px"
                                },
                                "topRight": {
                                    "value": 500,
                                    "unit": "px"
                                },
                                "bottomLeft": {
                                    "value": 500,
                                    "unit": "px"
                                },
                                "bottomRight": {
                                    "value": 500,
                                    "unit": "px"
                                }
                            }
                        },
                        "themeMappings": {},
                        "charas": "design-ivw51eaz"
                    }
                },
                {
                    "modeIds": [
                        "comp-iwati7qh-mode-iwati7uj"
                    ],
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "1.0",
                                "isHidden": false
                            },
                            "color": "#FFFFFF",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0,
                            "showOverlayForMediaType": "WixVideo"
                        },
                        "cssStyle": {
                            "cssBorder": {
                                "width": {
                                    "top": {
                                        "value": 3,
                                        "unit": "px"
                                    },
                                    "right": {
                                        "value": 3,
                                        "unit": "px"
                                    },
                                    "bottom": {
                                        "value": 3,
                                        "unit": "px"
                                    },
                                    "left": {
                                        "value": 3,
                                        "unit": "px"
                                    }
                                },
                                "style": {
                                    "top": "solid",
                                    "right": "solid",
                                    "left": "solid",
                                    "bottom": "solid"
                                },
                                "color": {
                                    "top": {
                                        "red": 255,
                                        "green": 147,
                                        "blue": 125,
                                        "alpha": 1
                                    },
                                    "right": {
                                        "red": 255,
                                        "green": 147,
                                        "blue": 125,
                                        "alpha": 1
                                    },
                                    "left": {
                                        "red": 255,
                                        "green": 147,
                                        "blue": 125,
                                        "alpha": 1
                                    },
                                    "bottom": {
                                        "red": 255,
                                        "green": 147,
                                        "blue": 125,
                                        "alpha": 1
                                    }
                                }
                            },
                            "cssBorderRadius": {
                                "topLeft": {
                                    "value": 500,
                                    "unit": "px"
                                },
                                "topRight": {
                                    "value": 500,
                                    "unit": "px"
                                },
                                "bottomLeft": {
                                    "value": 500,
                                    "unit": "px"
                                },
                                "bottomRight": {
                                    "value": 500,
                                    "unit": "px"
                                }
                            }
                        },
                        "themeMappings": {},
                        "charas": "design-ivw50xp2"
                    }
                }
            ],
            "isHiddenByModes": false
        },
        "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer",
        "layout": {
            "width": 122,
            "height": 122,
            "x": 0,
            "y": 0,
            "scale": 1,
            "rotationInDegrees": 0,
            "fixedPosition": false
        },
        "componentType": "wysiwyg.viewer.components.HoverBox",
        "props": {
            "type": "HoverBoxProperties",
            "metaData": {
                "schemaVersion": "1.0"
            }
        },
        "design": {
            "type": "MediaContainerDesignData",
            "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false
            },
            "background": {
                "type": "BackgroundMedia",
                "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false
                },
                "color": "#FFFFFF",
                "colorOpacity": 1,
                "alignType": "center",
                "fittingType": "fill",
                "scrollType": "none",
                "colorOverlay": "",
                "colorOverlayOpacity": 0,
                "showOverlayForMediaType": "WixVideo"
            },
            "cssStyle": {
                "cssBorder": {
                    "width": {
                        "top": {
                            "value": 3,
                            "unit": "px"
                        },
                        "right": {
                            "value": 3,
                            "unit": "px"
                        },
                        "bottom": {
                            "value": 3,
                            "unit": "px"
                        },
                        "left": {
                            "value": 3,
                            "unit": "px"
                        }
                    },
                    "style": {
                        "top": "solid",
                        "right": "solid",
                        "left": "solid",
                        "bottom": "solid"
                    },
                    "color": {
                        "top": {
                            "red": 248,
                            "green": 227,
                            "blue": 108,
                            "alpha": 1
                        },
                        "right": {
                            "red": 248,
                            "green": 227,
                            "blue": 108,
                            "alpha": 1
                        },
                        "left": {
                            "red": 248,
                            "green": 227,
                            "blue": 108,
                            "alpha": 1
                        },
                        "bottom": {
                            "red": 248,
                            "green": 227,
                            "blue": 108,
                            "alpha": 1
                        }
                    }
                },
                "cssBorderRadius": {
                    "topLeft": {
                        "value": 500,
                        "unit": "px"
                    },
                    "topRight": {
                        "value": 500,
                        "unit": "px"
                    },
                    "bottomLeft": {
                        "value": 500,
                        "unit": "px"
                    },
                    "bottomRight": {
                        "value": 500,
                        "unit": "px"
                    }
                }
            },
            "themeMappings": {},
            "charas": "design-ivv5f98s"
        },
        "style": {
            "type": "TopLevelStyle",
            "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false
            },
            "style": {
                "properties": {
                    "alpha-bg": "1",
                    "alpha-brd": "1",
                    "bg": "206,224,193,1",
                    "boxShadowToggleOn-shd": "false",
                    "brd": "#324158",
                    "brw": "0px",
                    "rd": "0px",
                    "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                },
                "propertiesSource": {
                    "bg": "value",
                    "brd": "value",
                    "brw": "value",
                    "rd": "value",
                    "shd": "value"
                },
                "groups": {}
            },
            "componentClassName": "wysiwyg.viewer.components.HoverBox",
            "pageId": "",
            "compId": "",
            "styleType": "custom",
            "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer"
        },
        "mobileStructure": {
            "type": "Container",
            "modes": {
                "definitions": [
                    {
                        "modeId": "comp-iwati7qh-mode-iwati7uj",
                        "type": "DEFAULT",
                        "label": "MODE_LABEL_FROM_PRESET",
                        "params": ""
                    },
                    {
                        "modeId": "comp-iwati7qh-mode-iwati7uk",
                        "type": "HOVER",
                        "label": "MODE_LABEL_FROM_PRESET",
                        "params": ""
                    }
                ]
            },
            "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer",
            "layout": {
                "width": 122,
                "height": 122,
                "x": 99,
                "y": 0,
                "scale": 1,
                "rotationInDegrees": 0,
                "fixedPosition": false
            },
            "componentType": "wysiwyg.viewer.components.HoverBox",
            "props": {
                "type": "HoverBoxProperties",
                "metaData": {
                    "schemaVersion": "1.0"
                }
            },
            "design": {
                "type": "MediaContainerDesignData",
                "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false
                },
                "background": {
                    "type": "BackgroundMedia",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "color": "#FF937D",
                    "colorOpacity": 1,
                    "alignType": "center",
                    "fittingType": "fill",
                    "scrollType": "none",
                    "colorOverlay": "",
                    "colorOverlayOpacity": 0,
                    "showOverlayForMediaType": "WixVideo"
                },
                "cssStyle": {
                    "cssBorder": {
                        "width": {
                            "top": {
                                "value": 3,
                                "unit": "px"
                            },
                            "right": {
                                "value": 3,
                                "unit": "px"
                            },
                            "bottom": {
                                "value": 3,
                                "unit": "px"
                            },
                            "left": {
                                "value": 3,
                                "unit": "px"
                            }
                        },
                        "style": {
                            "top": "solid",
                            "right": "solid",
                            "left": "solid",
                            "bottom": "solid"
                        },
                        "color": {
                            "top": {
                                "red": 255,
                                "green": 147,
                                "blue": 125,
                                "alpha": 1
                            },
                            "right": {
                                "red": 255,
                                "green": 147,
                                "blue": 125,
                                "alpha": 1
                            },
                            "left": {
                                "red": 255,
                                "green": 147,
                                "blue": 125,
                                "alpha": 1
                            },
                            "bottom": {
                                "red": 255,
                                "green": 147,
                                "blue": 125,
                                "alpha": 1
                            }
                        }
                    },
                    "cssBorderRadius": {
                        "topLeft": {
                            "value": 500,
                            "unit": "px"
                        },
                        "topRight": {
                            "value": 500,
                            "unit": "px"
                        },
                        "bottomLeft": {
                            "value": 500,
                            "unit": "px"
                        },
                        "bottomRight": {
                            "value": 500,
                            "unit": "px"
                        }
                    }
                },
                "themeMappings": {},
                "charas": "design-ivw51eaz"
            },
            "style": {
                "type": "TopLevelStyle",
                "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false
                },
                "style": {
                    "properties": {
                        "alpha-bg": "1",
                        "alpha-brd": "1",
                        "bg": "206,224,193,1",
                        "boxShadowToggleOn-shd": "false",
                        "brd": "#324158",
                        "brw": "0px",
                        "rd": "0px",
                        "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                    },
                    "propertiesSource": {
                        "bg": "value",
                        "brd": "value",
                        "brw": "value",
                        "rd": "value",
                        "shd": "value"
                    },
                    "groups": {}
                },
                "componentClassName": "wysiwyg.viewer.components.HoverBox",
                "pageId": "",
                "compId": "",
                "styleType": "custom",
                "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer"
            }
        },
        "activeModes": {}
    },
    "preset": {
        "rect": {
            "width": 80,
            "height": 104,
            "x": 80,
            "y": 3501
        }
    }
}
const hbPreset33 = {
    "id": "hoverBoxPreset33",
    "structure": {
        "type": "Container",
        "components": [
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwatild8-mode-iwatilh81"
                            ],
                            "layout": {
                                "width": 110,
                                "height": 59,
                                "x": 6,
                                "y": 29,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "style": {
                                    "properties": {
                                        "alpha-fillcolor": "1",
                                        "alpha-stroke": "1",
                                        "fillcolor": "#FFFFFF",
                                        "stroke": "#FF9D47",
                                        "strokewidth": "1"
                                    },
                                    "propertiesSource": {
                                        "fillcolor": "value",
                                        "stroke": "value",
                                        "strokewidth": "value"
                                    },
                                    "groups": {}
                                },
                                "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "svgshape.v2.Svg_4cce29ae75f34e18b6f5c8233ee49fd3"
                            }
                        },
                        {
                            "modeIds": [
                                "comp-iwatild8-mode-iwatilh8"
                            ],
                            "layout": {
                                "width": 88,
                                "height": 46,
                                "x": 17,
                                "y": 38,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            }
                        }
                    ],
                    "isHiddenByModes": false
                },
                "skin": "svgshape.v2.Svg_4cce29ae75f34e18b6f5c8233ee49fd3",
                "layout": {
                    "width": 88,
                    "height": 46,
                    "x": 17,
                    "y": 38,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.svgshape.SvgShape",
                "data": {
                    "type": "SvgShape",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    }
                },
                "props": {
                    "type": "SvgShapeProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "maintainAspectRatio": true
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "style": {
                        "properties": {
                            "alpha-fillcolor": "1",
                            "alpha-stroke": "1",
                            "fillcolor": "#FF9D47",
                            "stroke": "#000000",
                            "strokewidth": "0"
                        },
                        "propertiesSource": {
                            "fillcolor": "value",
                            "stroke": "theme",
                            "strokewidth": "value"
                        },
                        "groups": {}
                    },
                    "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "svgshape.v2.Svg_4cce29ae75f34e18b6f5c8233ee49fd3"
                },
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"action\":\"modeIn\",\"name\":\"FadeIn\",\"delay\":0,\"duration\":0.35},{\"action\":\"modeOut\",\"name\":\"FadeOut\",\"delay\":0,\"duration\":0.35},{\"action\":\"modeChange\",\"name\":\"ModesMotion\",\"delay\":0,\"duration\":0.2,\"params\":{\"timingFunction\":\"EaseInOut\",\"modeIds\":[\"comp-iwatild8-mode-iwatilh81\"]},\"targetId\":\"\"}]"
                },
                "mobileStructure": {
                    "type": "Component",
                    "modes": {
                        "definitions": []
                    },
                    "skin": "svgshape.v2.Svg_4cce29ae75f34e18b6f5c8233ee49fd3",
                    "layout": {
                        "width": 110,
                        "height": 59,
                        "x": 6,
                        "y": 31,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.svgshape.SvgShape",
                    "data": {
                        "type": "SvgShape",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        }
                    },
                    "props": {
                        "type": "SvgShapeProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "maintainAspectRatio": true
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "properties": {
                                "alpha-fillcolor": "1",
                                "alpha-stroke": "1",
                                "fillcolor": "#FFFFFF",
                                "stroke": "#FF9D47",
                                "strokewidth": "1"
                            },
                            "propertiesSource": {
                                "fillcolor": "value",
                                "stroke": "value",
                                "strokewidth": "value"
                            },
                            "groups": {}
                        },
                        "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "svgshape.v2.Svg_4cce29ae75f34e18b6f5c8233ee49fd3"
                    },
                    "behaviors": {
                        "type": "ObsoleteBehaviorsList",
                        "items": "[{\"action\":\"modeIn\",\"name\":\"FadeIn\",\"delay\":0,\"duration\":0.35},{\"action\":\"modeOut\",\"name\":\"FadeOut\",\"delay\":0,\"duration\":0.35},{\"action\":\"modeChange\",\"name\":\"ModesMotion\",\"delay\":0,\"duration\":0.2,\"params\":{\"timingFunction\":\"EaseInOut\",\"modeIds\":[\"comp-iwatild8-mode-iwatilh81\"]},\"targetId\":\"\"}]"
                    }
                }
            }
        ],
        "modes": {
            "definitions": [
                {
                    "modeId": "comp-iwatild8-mode-iwatilh8",
                    "type": "DEFAULT",
                    "label": "MODE_LABEL_FROM_PRESET",
                    "params": ""
                },
                {
                    "modeId": "comp-iwatild8-mode-iwatilh81",
                    "type": "HOVER",
                    "label": "MODE_LABEL_FROM_PRESET",
                    "params": ""
                }
            ],
            "overrides": [
                {
                    "modeIds": [
                        "comp-iwatild8-mode-iwatilh81"
                    ],
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "1.0",
                                "isHidden": false
                            },
                            "color": "#FF9D47",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0,
                            "showOverlayForMediaType": "WixVideo"
                        },
                        "cssStyle": {
                            "cssBorder": {
                                "width": {
                                    "top": {
                                        "value": 3,
                                        "unit": "px"
                                    },
                                    "right": {
                                        "value": 3,
                                        "unit": "px"
                                    },
                                    "bottom": {
                                        "value": 3,
                                        "unit": "px"
                                    },
                                    "left": {
                                        "value": 3,
                                        "unit": "px"
                                    }
                                },
                                "style": {
                                    "top": "solid",
                                    "right": "solid",
                                    "left": "solid",
                                    "bottom": "solid"
                                },
                                "color": {
                                    "top": {
                                        "red": 255,
                                        "green": 157,
                                        "blue": 71,
                                        "alpha": 1
                                    },
                                    "right": {
                                        "red": 255,
                                        "green": 157,
                                        "blue": 71,
                                        "alpha": 1
                                    },
                                    "left": {
                                        "red": 255,
                                        "green": 157,
                                        "blue": 71,
                                        "alpha": 1
                                    },
                                    "bottom": {
                                        "red": 255,
                                        "green": 157,
                                        "blue": 71,
                                        "alpha": 1
                                    }
                                }
                            },
                            "cssBorderRadius": {
                                "topLeft": {
                                    "value": 500,
                                    "unit": "px"
                                },
                                "topRight": {
                                    "value": 500,
                                    "unit": "px"
                                },
                                "bottomLeft": {
                                    "value": 500,
                                    "unit": "px"
                                },
                                "bottomRight": {
                                    "value": 500,
                                    "unit": "px"
                                }
                            }
                        },
                        "themeMappings": {},
                        "charas": "design-ivw66wll"
                    }
                },
                {
                    "modeIds": [
                        "comp-iwatild8-mode-iwatilh8"
                    ],
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "1.0",
                                "isHidden": false
                            },
                            "color": "#FFFFFF",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0,
                            "showOverlayForMediaType": "WixVideo"
                        },
                        "cssStyle": {
                            "cssBorder": {
                                "width": {
                                    "top": {
                                        "value": 3,
                                        "unit": "px"
                                    },
                                    "right": {
                                        "value": 3,
                                        "unit": "px"
                                    },
                                    "bottom": {
                                        "value": 3,
                                        "unit": "px"
                                    },
                                    "left": {
                                        "value": 3,
                                        "unit": "px"
                                    }
                                },
                                "style": {
                                    "top": "solid",
                                    "right": "solid",
                                    "left": "solid",
                                    "bottom": "solid"
                                },
                                "color": {
                                    "top": {
                                        "red": 255,
                                        "green": 157,
                                        "blue": 71,
                                        "alpha": 1
                                    },
                                    "right": {
                                        "red": 255,
                                        "green": 157,
                                        "blue": 71,
                                        "alpha": 1
                                    },
                                    "left": {
                                        "red": 255,
                                        "green": 157,
                                        "blue": 71,
                                        "alpha": 1
                                    },
                                    "bottom": {
                                        "red": 255,
                                        "green": 157,
                                        "blue": 71,
                                        "alpha": 1
                                    }
                                }
                            },
                            "cssBorderRadius": {
                                "topLeft": {
                                    "value": 500,
                                    "unit": "px"
                                },
                                "topRight": {
                                    "value": 500,
                                    "unit": "px"
                                },
                                "bottomLeft": {
                                    "value": 500,
                                    "unit": "px"
                                },
                                "bottomRight": {
                                    "value": 500,
                                    "unit": "px"
                                }
                            }
                        },
                        "themeMappings": {},
                        "charas": "design-ivw52ib8"
                    }
                }
            ],
            "isHiddenByModes": false
        },
        "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer",
        "layout": {
            "width": 122,
            "height": 126,
            "x": 0,
            "y": 0,
            "scale": 1,
            "rotationInDegrees": 0,
            "fixedPosition": false
        },
        "componentType": "wysiwyg.viewer.components.HoverBox",
        "props": {
            "type": "HoverBoxProperties",
            "metaData": {
                "schemaVersion": "1.0"
            }
        },
        "design": {
            "type": "MediaContainerDesignData",
            "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false
            },
            "background": {
                "type": "BackgroundMedia",
                "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false
                },
                "color": "#FFFFFF",
                "colorOpacity": 1,
                "alignType": "center",
                "fittingType": "fill",
                "scrollType": "none",
                "colorOverlay": "",
                "colorOverlayOpacity": 0,
                "showOverlayForMediaType": "WixVideo"
            },
            "cssStyle": {
                "cssBorder": {
                    "width": {
                        "top": {
                            "value": 3,
                            "unit": "px"
                        },
                        "right": {
                            "value": 3,
                            "unit": "px"
                        },
                        "bottom": {
                            "value": 3,
                            "unit": "px"
                        },
                        "left": {
                            "value": 3,
                            "unit": "px"
                        }
                    },
                    "style": {
                        "top": "solid",
                        "right": "solid",
                        "left": "solid",
                        "bottom": "solid"
                    },
                    "color": {
                        "top": {
                            "red": 248,
                            "green": 227,
                            "blue": 108,
                            "alpha": 1
                        },
                        "right": {
                            "red": 248,
                            "green": 227,
                            "blue": 108,
                            "alpha": 1
                        },
                        "left": {
                            "red": 248,
                            "green": 227,
                            "blue": 108,
                            "alpha": 1
                        },
                        "bottom": {
                            "red": 248,
                            "green": 227,
                            "blue": 108,
                            "alpha": 1
                        }
                    }
                },
                "cssBorderRadius": {
                    "topLeft": {
                        "value": 500,
                        "unit": "px"
                    },
                    "topRight": {
                        "value": 500,
                        "unit": "px"
                    },
                    "bottomLeft": {
                        "value": 500,
                        "unit": "px"
                    },
                    "bottomRight": {
                        "value": 500,
                        "unit": "px"
                    }
                }
            },
            "themeMappings": {},
            "charas": "design-ivv5f98s"
        },
        "style": {
            "type": "TopLevelStyle",
            "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false
            },
            "style": {
                "properties": {
                    "alpha-bg": "1",
                    "alpha-brd": "1",
                    "bg": "206,224,193,1",
                    "boxShadowToggleOn-shd": "false",
                    "brd": "#324158",
                    "brw": "0px",
                    "rd": "0px",
                    "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                },
                "propertiesSource": {
                    "bg": "value",
                    "brd": "value",
                    "brw": "value",
                    "rd": "value",
                    "shd": "value"
                },
                "groups": {}
            },
            "componentClassName": "wysiwyg.viewer.components.HoverBox",
            "pageId": "",
            "compId": "",
            "styleType": "custom",
            "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer"
        },
        "mobileStructure": {
            "type": "Container",
            "modes": {
                "definitions": [
                    {
                        "modeId": "comp-iwatild8-mode-iwatilh8",
                        "type": "DEFAULT",
                        "label": "MODE_LABEL_FROM_PRESET",
                        "params": ""
                    },
                    {
                        "modeId": "comp-iwatild8-mode-iwatilh81",
                        "type": "HOVER",
                        "label": "MODE_LABEL_FROM_PRESET",
                        "params": ""
                    }
                ]
            },
            "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer",
            "layout": {
                "width": 122,
                "height": 122,
                "x": 99,
                "y": 0,
                "scale": 1,
                "rotationInDegrees": 0,
                "fixedPosition": false
            },
            "componentType": "wysiwyg.viewer.components.HoverBox",
            "props": {
                "type": "HoverBoxProperties",
                "metaData": {
                    "schemaVersion": "1.0"
                }
            },
            "design": {
                "type": "MediaContainerDesignData",
                "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false
                },
                "background": {
                    "type": "BackgroundMedia",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "color": "#FF9D47",
                    "colorOpacity": 1,
                    "alignType": "center",
                    "fittingType": "fill",
                    "scrollType": "none",
                    "colorOverlay": "",
                    "colorOverlayOpacity": 0,
                    "showOverlayForMediaType": "WixVideo"
                },
                "cssStyle": {
                    "cssBorder": {
                        "width": {
                            "top": {
                                "value": 3,
                                "unit": "px"
                            },
                            "right": {
                                "value": 3,
                                "unit": "px"
                            },
                            "bottom": {
                                "value": 3,
                                "unit": "px"
                            },
                            "left": {
                                "value": 3,
                                "unit": "px"
                            }
                        },
                        "style": {
                            "top": "solid",
                            "right": "solid",
                            "left": "solid",
                            "bottom": "solid"
                        },
                        "color": {
                            "top": {
                                "red": 255,
                                "green": 157,
                                "blue": 71,
                                "alpha": 1
                            },
                            "right": {
                                "red": 255,
                                "green": 157,
                                "blue": 71,
                                "alpha": 1
                            },
                            "left": {
                                "red": 255,
                                "green": 157,
                                "blue": 71,
                                "alpha": 1
                            },
                            "bottom": {
                                "red": 255,
                                "green": 157,
                                "blue": 71,
                                "alpha": 1
                            }
                        }
                    },
                    "cssBorderRadius": {
                        "topLeft": {
                            "value": 500,
                            "unit": "px"
                        },
                        "topRight": {
                            "value": 500,
                            "unit": "px"
                        },
                        "bottomLeft": {
                            "value": 500,
                            "unit": "px"
                        },
                        "bottomRight": {
                            "value": 500,
                            "unit": "px"
                        }
                    }
                },
                "themeMappings": {},
                "charas": "design-ivw66wll"
            },
            "style": {
                "type": "TopLevelStyle",
                "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false
                },
                "style": {
                    "properties": {
                        "alpha-bg": "1",
                        "alpha-brd": "1",
                        "bg": "206,224,193,1",
                        "boxShadowToggleOn-shd": "false",
                        "brd": "#324158",
                        "brw": "0px",
                        "rd": "0px",
                        "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                    },
                    "propertiesSource": {
                        "bg": "value",
                        "brd": "value",
                        "brw": "value",
                        "rd": "value",
                        "shd": "value"
                    },
                    "groups": {}
                },
                "componentClassName": "wysiwyg.viewer.components.HoverBox",
                "pageId": "",
                "compId": "",
                "styleType": "custom",
                "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer"
            }
        },
        "activeModes": {}
    },
    "preset": {
        "rect": {
            "width": 80,
            "height": 104,
            "x": 161,
            "y": 3501
        }
    }
}
const hbPreset34 = {
    "id": "hoverBoxPreset34",
    "structure": {
        "type": "Container",
        "components": [
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwatnluq-mode-iwatnlzd1"
                            ],
                            "layout": {
                                "width": 110,
                                "height": 110,
                                "x": 6,
                                "y": 6,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "isHiddenByModes": false,
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "style": {
                                    "properties": {
                                        "alpha-fillcolor": "0",
                                        "alpha-stroke": "1",
                                        "fillcolor": "#FFFFFF",
                                        "stroke": "#FFFFFF",
                                        "strokewidth": "2"
                                    },
                                    "propertiesSource": {
                                        "alpha-fillcolor": "value",
                                        "fillcolor": "value",
                                        "stroke": "value",
                                        "strokewidth": "value"
                                    },
                                    "groups": {}
                                },
                                "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "svgshape.v1.svg_8463f60718194af748c49dddbe45b668.HollowCircle"
                            },
                            "props": {
                                "type": "SvgShapeProperties",
                                "metaData": {
                                    "schemaVersion": "1.0"
                                },
                                "maintainAspectRatio": false
                            }
                        }
                    ],
                    "isHiddenByModes": true
                },
                "skin": "svgshape.v1.svg_8463f60718194af748c49dddbe45b668.HollowCircle",
                "layout": {
                    "width": 102,
                    "height": 102,
                    "x": 10,
                    "y": 10,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.svgshape.SvgShape",
                "data": {
                    "type": "SvgShape",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    }
                },
                "props": {
                    "type": "SvgShapeProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "maintainAspectRatio": false
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "style": {
                        "properties": {
                            "alpha-fillcolor": "1",
                            "alpha-stroke": "1",
                            "fillcolor": "#002E5D",
                            "stroke": "#ED1566",
                            "strokewidth": "0px"
                        },
                        "propertiesSource": {
                            "fillcolor": "value",
                            "stroke": "value",
                            "strokewidth": "value"
                        },
                        "groups": {}
                    },
                    "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "svgshape.v1.svg_8463f60718194af748c49dddbe45b668.HollowCircle"
                },
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"name\":\"Reveal\",\"delay\":0,\"duration\":0.15,\"params\":{\"direction\":\"center\",\"modeIds\":[\"comp-iwatnluq-mode-iwatnlzd1\"]},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"Conceal\",\"delay\":0,\"duration\":0.15,\"params\":{\"direction\":\"center\",\"modeIds\":[\"comp-iwatnluq-mode-iwatnlzd1\"]},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                },
                "mobileStructure": {
                    "type": "Component",
                    "modes": {
                        "definitions": []
                    },
                    "skin": "svgshape.v1.svg_8463f60718194af748c49dddbe45b668.HollowCircle",
                    "layout": {
                        "width": 110,
                        "height": 110,
                        "x": 6,
                        "y": 6,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.svgshape.SvgShape",
                    "data": {
                        "type": "SvgShape",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        }
                    },
                    "props": {
                        "type": "SvgShapeProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "maintainAspectRatio": false
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "properties": {
                                "alpha-fillcolor": "0",
                                "alpha-stroke": "1",
                                "fillcolor": "#FFFFFF",
                                "stroke": "#FFFFFF",
                                "strokewidth": "2"
                            },
                            "propertiesSource": {
                                "alpha-fillcolor": "value",
                                "fillcolor": "value",
                                "stroke": "value",
                                "strokewidth": "value"
                            },
                            "groups": {}
                        },
                        "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "svgshape.v1.svg_8463f60718194af748c49dddbe45b668.HollowCircle"
                    },
                    "behaviors": {
                        "type": "ObsoleteBehaviorsList",
                        "items": "[{\"name\":\"Reveal\",\"delay\":0,\"duration\":0.15,\"params\":{\"direction\":\"center\",\"modeIds\":[\"comp-iwatnluq-mode-iwatnlzd1\"]},\"action\":\"modeIn\",\"targetId\":\"\"},{\"name\":\"Conceal\",\"delay\":0,\"duration\":0.15,\"params\":{\"direction\":\"center\",\"modeIds\":[\"comp-iwatnluq-mode-iwatnlzd1\"]},\"action\":\"modeOut\",\"targetId\":\"\"}]"
                    }
                }
            },
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwatnluq-mode-iwatnlzd"
                            ],
                            "layout": {
                                "width": 92,
                                "height": 49,
                                "x": 14,
                                "y": 36,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "isHiddenByModes": false
                        }
                    ],
                    "isHiddenByModes": true
                },
                "skin": "svgshape.v2.Svg_65805773595b400e886aa02d800827b8",
                "layout": {
                    "width": 93,
                    "height": 49,
                    "x": 14,
                    "y": 36,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.svgshape.SvgShape",
                "data": {
                    "type": "SvgShape",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    }
                },
                "props": {
                    "type": "SvgShapeProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "maintainAspectRatio": true
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "style": {
                        "properties": {
                            "alpha-fillcolor": "1",
                            "alpha-stroke": "1",
                            "fillcolor": "#FFD859",
                            "stroke": "#000000",
                            "strokewidth": "0"
                        },
                        "propertiesSource": {
                            "fillcolor": "value",
                            "stroke": "theme",
                            "strokewidth": "value"
                        },
                        "groups": {}
                    },
                    "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "svgshape.v2.Svg_65805773595b400e886aa02d800827b8"
                },
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"action\":\"modeIn\",\"name\":\"FadeIn\",\"delay\":0,\"duration\":0.35},{\"action\":\"modeOut\",\"name\":\"FadeOut\",\"delay\":0,\"duration\":0.35}]"
                }
            },
            {
                "type": "Component",
                "modes": {
                    "definitions": [],
                    "overrides": [
                        {
                            "modeIds": [
                                "comp-iwatnluq-mode-iwatnlzd1"
                            ],
                            "layout": {
                                "width": 66,
                                "height": 57,
                                "x": 28,
                                "y": 32,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "isHiddenByModes": false
                        }
                    ],
                    "isHiddenByModes": true
                },
                "skin": "svgshape.v2.Svg_65805773595b400e886aa02d800827b8",
                "layout": {
                    "width": 66,
                    "height": 57,
                    "x": 28,
                    "y": 32,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.svgshape.SvgShape",
                "data": {
                    "type": "SvgShape",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    }
                },
                "props": {
                    "type": "SvgShapeProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "maintainAspectRatio": true
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "style": {
                        "properties": {
                            "alpha-fillcolor": "1",
                            "alpha-stroke": "1",
                            "fillcolor": "#FFFFFF",
                            "stroke": "#000000",
                            "strokewidth": "0"
                        },
                        "propertiesSource": {
                            "fillcolor": "value",
                            "stroke": "theme",
                            "strokewidth": "value"
                        },
                        "groups": {}
                    },
                    "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "svgshape.v2.Svg_65805773595b400e886aa02d800827b8"
                },
                "behaviors": {
                    "type": "ObsoleteBehaviorsList",
                    "items": "[{\"action\":\"modeIn\",\"name\":\"FadeIn\",\"delay\":0,\"duration\":0.35},{\"action\":\"modeOut\",\"name\":\"FadeOut\",\"delay\":0,\"duration\":0.35}]"
                },
                "mobileStructure": {
                    "type": "Component",
                    "modes": {
                        "definitions": []
                    },
                    "skin": "svgshape.v2.Svg_65805773595b400e886aa02d800827b8",
                    "layout": {
                        "width": 66,
                        "height": 57,
                        "x": 28,
                        "y": 32,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.svgshape.SvgShape",
                    "data": {
                        "type": "SvgShape",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        }
                    },
                    "props": {
                        "type": "SvgShapeProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "maintainAspectRatio": true
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "properties": {
                                "alpha-fillcolor": "1",
                                "alpha-stroke": "1",
                                "fillcolor": "#FFFFFF",
                                "stroke": "#000000",
                                "strokewidth": "0"
                            },
                            "propertiesSource": {
                                "fillcolor": "value",
                                "stroke": "theme",
                                "strokewidth": "value"
                            },
                            "groups": {}
                        },
                        "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "svgshape.v2.Svg_65805773595b400e886aa02d800827b8"
                    },
                    "behaviors": {
                        "type": "ObsoleteBehaviorsList",
                        "items": "[{\"action\":\"modeIn\",\"name\":\"FadeIn\",\"delay\":0,\"duration\":0.35},{\"action\":\"modeOut\",\"name\":\"FadeOut\",\"delay\":0,\"duration\":0.35}]"
                    }
                }
            }
        ],
        "modes": {
            "definitions": [
                {
                    "modeId": "comp-iwatnluq-mode-iwatnlzd",
                    "type": "DEFAULT",
                    "label": "MODE_LABEL_FROM_PRESET",
                    "params": ""
                },
                {
                    "modeId": "comp-iwatnluq-mode-iwatnlzd1",
                    "type": "HOVER",
                    "label": "MODE_LABEL_FROM_PRESET",
                    "params": ""
                }
            ],
            "overrides": [
                {
                    "modeIds": [
                        "comp-iwatnluq-mode-iwatnlzd1"
                    ],
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "1.0",
                                "isHidden": false
                            },
                            "color": "#FFD859",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0,
                            "showOverlayForMediaType": "WixVideo"
                        },
                        "cssStyle": {
                            "cssBorder": {
                                "width": {
                                    "top": {
                                        "value": 3,
                                        "unit": "px"
                                    },
                                    "right": {
                                        "value": 3,
                                        "unit": "px"
                                    },
                                    "bottom": {
                                        "value": 3,
                                        "unit": "px"
                                    },
                                    "left": {
                                        "value": 3,
                                        "unit": "px"
                                    }
                                },
                                "style": {
                                    "top": "solid",
                                    "right": "solid",
                                    "left": "solid",
                                    "bottom": "solid"
                                },
                                "color": {
                                    "top": {
                                        "red": 255,
                                        "green": 216,
                                        "blue": 89,
                                        "alpha": 1
                                    },
                                    "right": {
                                        "red": 255,
                                        "green": 216,
                                        "blue": 89,
                                        "alpha": 1
                                    },
                                    "left": {
                                        "red": 255,
                                        "green": 216,
                                        "blue": 89,
                                        "alpha": 1
                                    },
                                    "bottom": {
                                        "red": 255,
                                        "green": 216,
                                        "blue": 89,
                                        "alpha": 1
                                    }
                                }
                            },
                            "cssBorderRadius": {
                                "topLeft": {
                                    "value": 500,
                                    "unit": "px"
                                },
                                "topRight": {
                                    "value": 500,
                                    "unit": "px"
                                },
                                "bottomLeft": {
                                    "value": 500,
                                    "unit": "px"
                                },
                                "bottomRight": {
                                    "value": 500,
                                    "unit": "px"
                                }
                            }
                        },
                        "themeMappings": {},
                        "charas": "design-ivw6gi29"
                    }
                },
                {
                    "modeIds": [
                        "comp-iwatnluq-mode-iwatnlzd"
                    ],
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "1.0",
                                "isHidden": false
                            },
                            "color": "#FFFFFF",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0,
                            "showOverlayForMediaType": "WixVideo"
                        },
                        "cssStyle": {
                            "cssBorder": {
                                "width": {
                                    "top": {
                                        "value": 3,
                                        "unit": "px"
                                    },
                                    "right": {
                                        "value": 3,
                                        "unit": "px"
                                    },
                                    "bottom": {
                                        "value": 3,
                                        "unit": "px"
                                    },
                                    "left": {
                                        "value": 3,
                                        "unit": "px"
                                    }
                                },
                                "style": {
                                    "top": "solid",
                                    "right": "solid",
                                    "left": "solid",
                                    "bottom": "solid"
                                },
                                "color": {
                                    "top": {
                                        "red": 255,
                                        "green": 216,
                                        "blue": 89,
                                        "alpha": 1
                                    },
                                    "right": {
                                        "red": 255,
                                        "green": 216,
                                        "blue": 89,
                                        "alpha": 1
                                    },
                                    "left": {
                                        "red": 255,
                                        "green": 216,
                                        "blue": 89,
                                        "alpha": 1
                                    },
                                    "bottom": {
                                        "red": 255,
                                        "green": 216,
                                        "blue": 89,
                                        "alpha": 1
                                    }
                                }
                            },
                            "cssBorderRadius": {
                                "topLeft": {
                                    "value": 500,
                                    "unit": "px"
                                },
                                "topRight": {
                                    "value": 500,
                                    "unit": "px"
                                },
                                "bottomLeft": {
                                    "value": 500,
                                    "unit": "px"
                                },
                                "bottomRight": {
                                    "value": 500,
                                    "unit": "px"
                                }
                            }
                        },
                        "themeMappings": {},
                        "charas": "design-ivw6g17v"
                    }
                }
            ],
            "isHiddenByModes": false
        },
        "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer",
        "layout": {
            "width": 122,
            "height": 122,
            "x": 0,
            "y": 0,
            "scale": 1,
            "rotationInDegrees": 0,
            "fixedPosition": false
        },
        "componentType": "wysiwyg.viewer.components.HoverBox",
        "props": {
            "type": "HoverBoxProperties",
            "metaData": {
                "schemaVersion": "1.0"
            }
        },
        "design": {
            "type": "MediaContainerDesignData",
            "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false
            },
            "background": {
                "type": "BackgroundMedia",
                "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false
                },
                "color": "#FFFFFF",
                "colorOpacity": 1,
                "alignType": "center",
                "fittingType": "fill",
                "scrollType": "none",
                "colorOverlay": "",
                "colorOverlayOpacity": 0,
                "showOverlayForMediaType": "WixVideo"
            },
            "cssStyle": {
                "cssBorder": {
                    "width": {
                        "top": {
                            "value": 3,
                            "unit": "px"
                        },
                        "right": {
                            "value": 3,
                            "unit": "px"
                        },
                        "bottom": {
                            "value": 3,
                            "unit": "px"
                        },
                        "left": {
                            "value": 3,
                            "unit": "px"
                        }
                    },
                    "style": {
                        "top": "solid",
                        "right": "solid",
                        "left": "solid",
                        "bottom": "solid"
                    },
                    "color": {
                        "top": {
                            "red": 248,
                            "green": 227,
                            "blue": 108,
                            "alpha": 1
                        },
                        "right": {
                            "red": 248,
                            "green": 227,
                            "blue": 108,
                            "alpha": 1
                        },
                        "left": {
                            "red": 248,
                            "green": 227,
                            "blue": 108,
                            "alpha": 1
                        },
                        "bottom": {
                            "red": 248,
                            "green": 227,
                            "blue": 108,
                            "alpha": 1
                        }
                    }
                },
                "cssBorderRadius": {
                    "topLeft": {
                        "value": 500,
                        "unit": "px"
                    },
                    "topRight": {
                        "value": 500,
                        "unit": "px"
                    },
                    "bottomLeft": {
                        "value": 500,
                        "unit": "px"
                    },
                    "bottomRight": {
                        "value": 500,
                        "unit": "px"
                    }
                }
            },
            "themeMappings": {},
            "charas": "design-ivv5f98s"
        },
        "style": {
            "type": "TopLevelStyle",
            "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false
            },
            "style": {
                "properties": {
                    "alpha-bg": "1",
                    "alpha-brd": "1",
                    "bg": "206,224,193,1",
                    "boxShadowToggleOn-shd": "false",
                    "brd": "#324158",
                    "brw": "0px",
                    "rd": "0px",
                    "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                },
                "propertiesSource": {
                    "bg": "value",
                    "brd": "value",
                    "brw": "value",
                    "rd": "value",
                    "shd": "value"
                },
                "groups": {}
            },
            "componentClassName": "wysiwyg.viewer.components.HoverBox",
            "pageId": "",
            "compId": "",
            "styleType": "custom",
            "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer"
        },
        "mobileStructure": {
            "type": "Container",
            "modes": {
                "definitions": [
                    {
                        "modeId": "comp-iwatnluq-mode-iwatnlzd",
                        "type": "DEFAULT",
                        "label": "MODE_LABEL_FROM_PRESET",
                        "params": ""
                    },
                    {
                        "modeId": "comp-iwatnluq-mode-iwatnlzd1",
                        "type": "HOVER",
                        "label": "MODE_LABEL_FROM_PRESET",
                        "params": ""
                    }
                ]
            },
            "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer",
            "layout": {
                "width": 122,
                "height": 122,
                "x": 99,
                "y": 10,
                "scale": 1,
                "rotationInDegrees": 0,
                "fixedPosition": false
            },
            "componentType": "wysiwyg.viewer.components.HoverBox",
            "props": {
                "type": "HoverBoxProperties",
                "metaData": {
                    "schemaVersion": "1.0"
                }
            },
            "design": {
                "type": "MediaContainerDesignData",
                "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false
                },
                "background": {
                    "type": "BackgroundMedia",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "color": "#FFD859",
                    "colorOpacity": 1,
                    "alignType": "center",
                    "fittingType": "fill",
                    "scrollType": "none",
                    "colorOverlay": "",
                    "colorOverlayOpacity": 0,
                    "showOverlayForMediaType": "WixVideo"
                },
                "cssStyle": {
                    "cssBorder": {
                        "width": {
                            "top": {
                                "value": 3,
                                "unit": "px"
                            },
                            "right": {
                                "value": 3,
                                "unit": "px"
                            },
                            "bottom": {
                                "value": 3,
                                "unit": "px"
                            },
                            "left": {
                                "value": 3,
                                "unit": "px"
                            }
                        },
                        "style": {
                            "top": "solid",
                            "right": "solid",
                            "left": "solid",
                            "bottom": "solid"
                        },
                        "color": {
                            "top": {
                                "red": 255,
                                "green": 216,
                                "blue": 89,
                                "alpha": 1
                            },
                            "right": {
                                "red": 255,
                                "green": 216,
                                "blue": 89,
                                "alpha": 1
                            },
                            "left": {
                                "red": 255,
                                "green": 216,
                                "blue": 89,
                                "alpha": 1
                            },
                            "bottom": {
                                "red": 255,
                                "green": 216,
                                "blue": 89,
                                "alpha": 1
                            }
                        }
                    },
                    "cssBorderRadius": {
                        "topLeft": {
                            "value": 500,
                            "unit": "px"
                        },
                        "topRight": {
                            "value": 500,
                            "unit": "px"
                        },
                        "bottomLeft": {
                            "value": 500,
                            "unit": "px"
                        },
                        "bottomRight": {
                            "value": 500,
                            "unit": "px"
                        }
                    }
                },
                "themeMappings": {},
                "charas": "design-ivw6gi29"
            },
            "style": {
                "type": "TopLevelStyle",
                "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false
                },
                "style": {
                    "properties": {
                        "alpha-bg": "1",
                        "alpha-brd": "1",
                        "bg": "206,224,193,1",
                        "boxShadowToggleOn-shd": "false",
                        "brd": "#324158",
                        "brw": "0px",
                        "rd": "0px",
                        "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                    },
                    "propertiesSource": {
                        "bg": "value",
                        "brd": "value",
                        "brw": "value",
                        "rd": "value",
                        "shd": "value"
                    },
                    "groups": {}
                },
                "componentClassName": "wysiwyg.viewer.components.HoverBox",
                "pageId": "",
                "compId": "",
                "styleType": "custom",
                "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer"
            }
        },
        "activeModes": {}
    },
    "preset": {
        "rect": {
            "width": 80,
            "height": 104,
            "x": 243,
            "y": 3501
        }
    }
}



const hoverBoxPresets2 = {
    hbPreset1,
    hbPreset2,
    hbPreset3,
    hbPreset4,
    hbPreset5,
    hbPreset6,
    hbPreset7,
    hbPreset8: undefined,
    hbPreset9,
    hbPreset10,
    hbPreset11,
    hbPreset12,
    hbPreset13,
    hbPreset14,
    hbPreset15,
    hbPreset16,
    hbPreset17,
    hbPreset18,
    hbPreset19,
    hbPreset20,
    hbPreset21: undefined,
    hbPreset22,
    hbPreset23,
    hbPreset24,
    hbPreset25,
    hbPreset26,
    hbPreset27,
    hbPreset28,
    hbPreset29,
    hbPreset30,
    hbPreset31,
    hbPreset32,
    hbPreset33,
    hbPreset34
}

module.exports = hoverBoxPresets2
