// @ts-nocheck
import _ from 'lodash';
import * as util from '@/util';
import * as savePublish from '@/savePublish';

const SPECIAL_KEY_VALUE = util.browserUtil.getKeyboardSpecialKey().value;

function addEditorDebugAndQAShortcuts(editorAPI) {
  const debugKeys = {};
  const activeExperiments = _(window.editorModel.runningExperiments)
    .pickBy(function (value) {
      return value === 'new';
    })
    .keys()
    .value();
  debugKeys['ctrl + alt + shift + v'] =
    editorAPI.openEditorVersionInfoPanel.bind(editorAPI);
  debugKeys['ctrl + alt + shift + e'] = function () {
    window.alert(`Active Experiments:\n${activeExperiments.join('\n')}`); //eslint-disable-line no-alert
  };

  const searchValue =
    window.location.search && window.location.search.toLowerCase();
  const isDebugMode =
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/includes
    _.includes(searchValue, 'debug=') || _.includes(searchValue, 'isqa=true');
  if (isDebugMode) {
    debugKeys[`${SPECIAL_KEY_VALUE} + shift + alt + s`] = _.partial(
      savePublish.configureSave,
      editorAPI,
    );
    debugKeys[`${SPECIAL_KEY_VALUE} + shift + alt + p`] =
      editorAPI.publishManager.configureMockPostPublish;
  }
  return debugKeys;
}

export default addEditorDebugAndQAShortcuts;
