import type {
  EditorState,
  PanelDescriptor,
  PreviewPosition,
} from '@/stateManagement';
import * as stateManagement from '@/stateManagement';
import {
  WorkspaceApiKey,
  EditorRestrictionsApiKey,
  FixedStageApiKey,
} from '@/apis';
import constants from '@/constants';
import experiment from 'experiment';
import { modes } from '@/util';
import type {
  MapDispatchToProps,
  MapStateToProps,
  ThunkAction,
  Dispatch,
} from 'types/redux';
import type { ContributedComponent } from '@/apilib';
import type { CompData, CompRef } from 'types/documentServices';
import type { ViewerMouseCoordinates } from '@/util';
import {
  editorAPISel,
  editorStateMouseOpsSel,
} from '../selectors/rootSelectors';
import { isDuringMouseActionSel } from '../selectors/mouseSelectors';
import { createSelector } from '../../selectors/selector';
import type { StageContainerOwnProps } from './stageContainer';
import type { CSSProperties, MouseEvent, MouseEventHandler } from 'react';
import { type MouseMoveAction } from '@/rEditor';
import { AI_TEXT_CREATOR_ENTRY_POINT_ORIGIN } from './stageContainerConstants';

const { isInInteractionMode, showInteractionModeControls } =
  stateManagement.interactions.selectors;
const { isTranslating } = stateManagement.multilingual.selectors;
const { isPerformingMouseMoveAction } = stateManagement.mouseActions.selectors;
const { getSelectedCompsRefs, getFocusedContainer } =
  stateManagement.selection.selectors;
const { isOpen: isPinModeOpen, getPinPreviewRectStyle } =
  stateManagement.pinMode.selectors;
const { getPreviewPosition, translateToViewerCoordinates } =
  stateManagement.domMeasurements.selectors;
const { selectApplyModeFromClipboardSuggestion } =
  stateManagement.applyModeFromClipboard.selectors;
const { closeOpenedPanels } = stateManagement.panels.actions;
const { openContextMenu } = stateManagement.contextMenu.actions;
const { getIsStageLoading } = stateManagement.stageLoader.selectors;

export interface StageContainerStateProps {
  StageComponents: ContributedComponent<{}>[];
  siteScale: EditorState['siteScale'];
  editorIsInit: EditorState['editorIsInit'];
  imageCrop: EditorState['imageCrop'];
  rulersEnabled: boolean;
  pageSections: EditorState['pageSections'];
  halfOpacityTools: EditorState['halfOpacityTools'];
  previewPosition: PreviewPosition;
  performingMouseMoveAction: boolean;
  registeredMouseMoveAction: MouseMoveAction | null;
  pinPreviewRectStyle: CSSProperties;
  isPreviewReady: boolean;
  isMobileEditor: boolean;
  isInteractionMode: boolean;
  shouldShowInteractionModeControls: boolean;
  isTranslationMode: boolean;
  isPinMode: boolean;
  isPopUpMode: boolean;
  isMobileOptimizedSite: boolean;
  isInZoomMode: boolean;
  isStageZoomMode: boolean;
  isZoomModeTransitionActive: boolean;
  isInZoomModeAndDisabledRightClickMenu: boolean;
  isReorganizeHidden: boolean;
  isExtensionsEnabled: boolean;
  selectedComponents: CompRef[];
  focusedContainer: CompRef;
  openPanels: PanelDescriptor[];
  isStageLoading: boolean;
  isStageInteractive: boolean;
  isScalableMouseCatcherVisible: boolean;
  isStyleOnlyMode: boolean;
  isActionsHoveredAndHideSelectionEnabled: boolean;
  isStageFixedWidth: boolean;
  siteCoverWidthForHorizontallyScrollableStage: number;
}

export const mapStateToProps: MapStateToProps<
  StageContainerStateProps,
  StageContainerOwnProps
> = (stateMapperArgs) => {
  const { state: editorState, editorAPI, host } = stateMapperArgs;
  const fixedStageAPI = editorAPI.host.getAPI(FixedStageApiKey);

  const {
    config,
    siteScale,
    editorIsInit,
    hideTools,
    imageCrop,
    tabIndicationState,
    constraintArea,
    viewTools: { rulersEnabled },
    pageSections,
    halfOpacityTools,
  } = editorState;

  const StageComponents = host.getAPI(WorkspaceApiKey).getStageComponents();

  const previewPosition = getPreviewPosition(editorState);
  const performingMouseMoveAction = isPerformingMouseMoveAction(editorState);
  const selectedComponents = getSelectedCompsRefs(editorState);
  const applyModeFromClipboardSuggestion =
    selectApplyModeFromClipboardSuggestion(editorState);
  const isInteractionMode = isInInteractionMode(editorState);
  const shouldShowInteractionModeControls =
    showInteractionModeControls(editorState);
  const isTranslationMode = isTranslating(editorState);
  const isPinMode = isPinModeOpen(editorState);
  const isStageLoading = getIsStageLoading(editorState);
  const pinPreviewRectStyle = getPinPreviewRectStyle(editorState);
  const focusedContainer = getFocusedContainer(editorState);

  const isPopUpMode = editorAPI.isPopUpMode();
  const isMobileOptimizedSite = editorAPI.isMobileOptimizedSite();
  const isInZoomMode = editorAPI.zoomMode.isInZoomMode();
  const isStageZoomMode = editorAPI.zoomMode.isStageZoomMode();
  const isPreviewReady = editorAPI.isPreviewReady();
  const isMobileEditor = editorAPI.isMobileEditor();
  const isInZoomModeAndDisabledRightClickMenu =
    isInZoomMode && !editorAPI.zoomMode.enabledRightClickMenu();

  const registeredMouseMoveAction = performingMouseMoveAction
    ? editorAPI.mouseActions.getRegisteredMouseMoveAction()
    : null;

  const isReorganizeHidden = hideTools || isPopUpMode;
  const openPanels = editorAPI.getOpenPanels();
  const isExtensionsEnabled = experiment.isOpen('se_editorPlugins2');

  const editorRestrictionsApi = editorAPI.host.getAPI(EditorRestrictionsApiKey);
  const isStageInteractive = editorRestrictionsApi.allowed(
    'rEditor_stage-container.interactive',
  );
  const isScalableMouseCatcherVisible = editorRestrictionsApi.allowed(
    'rEditor_mouse-catcher_scalable.visible',
  );

  const isStyleOnlyMode = modes.isStyleOnlyModeEnabled(
    editorAPI,
    selectedComponents,
  );

  const isActionsHoveredAndHideSelectionEnabled =
    stateManagement.selection.selectors.shouldHideSelectionWhenStageActionsHovered(
      editorAPI,
    );

  const isStageFixedWidth = fixedStageAPI.isStageFixedInDesktop();

  const siteCoverWidthForHorizontallyScrollableStage =
    editorAPI.getEditingAreaPosition().width +
    editorAPI.scroll.get().scrollLeft;

  return {
    StageComponents,
    siteScale,
    editorIsInit,
    isPreviewReady,
    isMobileEditor,
    performingMouseMoveAction,
    registeredMouseMoveAction,
    imageCrop,
    tabIndicationState,
    constraintArea,
    rulersEnabled,
    config,
    pageSections,
    halfOpacityTools,
    previewPosition,
    isMobileOptimizedSite,
    isInZoomMode,
    isStageZoomMode,
    isStageLoading,
    selectedComponents,
    applyModeFromClipboardSuggestion,
    isInZoomModeAndDisabledRightClickMenu,
    focusedContainer,
    pinPreviewRectStyle,
    isInteractionMode,
    shouldShowInteractionModeControls,
    isTranslationMode,
    isZoomModeTransitionActive: editorAPI.zoomMode.isZoomModeTransitionActive(),
    isPinMode,
    isPopUpMode,
    isReorganizeHidden,
    openPanels,
    isExtensionsEnabled,
    isStageInteractive,
    isScalableMouseCatcherVisible,
    isStyleOnlyMode,
    isActionsHoveredAndHideSelectionEnabled,
    isStageFixedWidth,
    siteCoverWidthForHorizontallyScrollableStage,
  };
};

const getEditorAPI: ThunkAction = (
  dispatch,
  getState,
  { editorAPI, host },
) => ({
  editorAPI,
  state: getState(),
  host,
});

export interface StageContainerDispatchProps {
  getEditingAreaLayout: () => any;
  translateToViewerCoordinates: (event: MouseEvent) => ViewerMouseCoordinates;
  closeOpenedPanels: () => void;
  openPanel: (panelName: string) => void;
  openContextMenu: (config: any) => void;
  selectComponentByClick: MouseEventHandler;
  getSelectedComponents: () => CompRef[];
  getComponentData: (compRef: CompRef) => CompData;
  getIsSpotlightStageContainer: (focusedContainer: CompRef) => boolean;
  getIsVerticalTextComp: (compRef: CompRef) => boolean;
  fireMouseLeave: MouseEventHandler;
  exitZoomMode: (biParams: { origin: string }) => void;
  openAiTextGeneratorPanel: () => void;
}

export const mapDispatchToProps: MapDispatchToProps<
  StageContainerDispatchProps,
  StageContainerOwnProps
> = (dispatch: Dispatch) => {
  const { editorAPI, host } = dispatch(getEditorAPI);

  return {
    getEditingAreaLayout: () => editorAPI.ui.stage.getEditingAreaLayout(),
    translateToViewerCoordinates: (event: MouseEvent) =>
      translateToViewerCoordinates(editorAPI, event),
    closeOpenedPanels: () => dispatch(closeOpenedPanels()),
    openPanel: (panelName: string) =>
      editorAPI.panelManager.openPanel(panelName),
    openContextMenu: (config: AnyFixMe) => dispatch(openContextMenu(config)),
    selectComponentByClick: (event: MouseEvent) =>
      editorAPI.selection.selectComponentByClick(event),
    // need this because selectComponentByClick doesn't return selected component which we need in the same cycle
    getSelectedComponents: () =>
      getSelectedCompsRefs(editorAPI.store.getState()),
    getComponentData: (compRef: CompRef) =>
      editorAPI.components.data.get(compRef),
    getIsVerticalTextComp: (compRef: CompRef) =>
      editorAPI.components.properties.get(compRef)?.verticalText,
    getIsSpotlightStageContainer: (focusedContainer: CompRef) =>
      editorAPI.components.is.spotlightStageContainer(focusedContainer),
    fireMouseLeave: () => {
      host.getAPI(WorkspaceApiKey).hooks.stageMouseLeave.fire();
    },
    exitZoomMode: (biParams: any) => {
      const isStageZoomMode = editorAPI.zoomMode.isStageZoomMode();
      const isZoomModeAnimation =
        editorAPI.zoomMode.isZoomModeTransitionActive();

      if (isStageZoomMode && !isZoomModeAnimation) {
        editorAPI.zoomMode.exitZoomMode({ biParams });
      }
    },
    openAiTextGeneratorPanel: () => {
      editorAPI.openComponentPanel(constants.componentPanels.aiTextGenerator, {
        origin: AI_TEXT_CREATOR_ENTRY_POINT_ORIGIN,
      });
    },
  };
};

const isPreviewReadySel = createSelector([editorAPISel], (editorAPI) =>
  editorAPI.isPreviewReady(),
);

const stageHeightSel = createSelector(
  [
    isPreviewReadySel,
    editorStateMouseOpsSel,
    editorAPISel,
    isDuringMouseActionSel,
  ],
  (isPreviewReady, editorState, editorAPI, isDuringMouseAction) => {
    if (!isPreviewReady) {
      return '100%';
    }

    const siteHeight = isDuringMouseAction
      ? editorState.config.previewHeight
      : editorAPI.dsRead.site.getHeight();

    return siteHeight * editorState.siteScale;
  },
);

export const siteCoverStyleSel = createSelector([stageHeightSel], (height) => ({
  height,
}));
