// @ts-nocheck
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { translate } from '@/i18n';
import * as platformUtils from '@wix/santa-platform-utils';
import {
  Button,
  Composites,
  ContextMenuAction,
  CustomScroll,
  Illustration,
  RichText,
  SecondaryMediaImage,
  SortByDragList,
  SortByDragListCustomItemRow,
  TextLabel,
} from '@wix/wix-base-ui';
import * as baseUI from '@/baseUI';
import jsonSchemaUtils from '@wix/wix-json-schema-utils';
import * as symbols from '@wix/santa-editor-symbols';
import ObjectPropertyEditor from '../objectPropertyEditor/objectPropertyEditor';

//'santa-editor-symbols',
//const createSymbol = name => React.createElement(symbols.symbol, {name});

const { propertySchemaValidationUtils } = baseUI.utils;

const createSymbol = (name) =>
  React.createElement(
    'span',
    { className: 'symbol', key: name },
    React.createElement(baseUI.symbol, {
      name,
      key: name,
      style: { fill: '#2b5672' },
    }),
  );

const EDIT_SYMBOL = createSymbol('edit_pencle_light_icon');
const BOOLEAN_TRUE_SYMBOL = createSymbol('booleanTrue');
const BOOLEAN_FALSE_SYMBOL = createSymbol('booleanFalse');

//TODO: translation key
const EMPTY_ITEM_PROPS = {
  content: 'Empty',
  className: 'empty',
  label: '',
};

const ITEM_TYPES = _.mapValues(
  jsonSchemaUtils.baseDefinitions,
  (val, key) => key,
);

const VALUE_LABELS = {
  TRUE: 'True',
  FALSE: 'False',
};

const ACTION_OVERRIDES = {
  toggleEditLabel: {
    enable: true,
    label: translate('AppStudio_CustomType_DefaultValues_Edit'),
  },
};

const BASE_ACTION_OVERRIDES = {
  toggleDefault: {
    enable: false,
  },
  duplicate: {
    enable: true,
    label: translate('Appstudio_CustomType_DefaultValues_DuplicateItem'),
  },
  toggleEditLabel: {
    enable: false,
  },
  toggleEditValue: {
    enable: false,
  },
  delete: {
    enable: true,
    label: translate('Appstudio_CustomType_DefaultValues_DeleteItem'),
  },
};

const OBJECT_LIST_PROPS = {
  selectable: true,
};

const CLASS_NAMES = {
  LARGE: 'large',
  NARROW: 'narrow',
  WIDE: 'wide',
};

const createContextMenuAction = ({
  onClick,
  symbol,
  title,
  key,
  priority = 100,
}) =>
  React.createElement(ContextMenuAction, { onClick, priority, key }, [
    symbol,
    title,
  ]);

const convertImageDataToUri = (imageData) => {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/assign
  const mediaItem = _.assign(
    {
      type: platformUtils.mediaItemUtils.types.IMAGE,
      mediaId: imageData.uri,
    },
    _.pick(imageData, ['width', 'height']),
  );

  return platformUtils.mediaSrcHandler.createMediaSrc(mediaItem).item;
};

const valueToString = (value, type) => {
  if (type === ITEM_TYPES.boolean) {
    return value ? VALUE_LABELS.TRUE : VALUE_LABELS.FALSE;
  }
  return String(value);
};

const parseLabel = (label, type) =>
  type === ITEM_TYPES.number ? Number(label) : label;

const getActionOverridesByType = (type) => {
  switch (type) {
    case ITEM_TYPES.text:
    case ITEM_TYPES.number:
    case ITEM_TYPES.dateTime:
    case ITEM_TYPES.url:
      return ACTION_OVERRIDES;
  }
};

const getLabelPlaceholderByType = (type) => {
  switch (type) {
    case ITEM_TYPES.number:
      return 'AppStudio_CustomType_DefaultValues_Number_Placeholder';
    case ITEM_TYPES.url:
      return 'AppStudio_WidgetAPI_URL_DefaultURL_Placeholder';
    case ITEM_TYPES.dateTime:
      return 'AppStudio_CustomType_DefaultValues_Date_Placeholder';
  }
};

const getDefaultNumberByPropertyData = ({
  minimum,
  maximum,
  multipleOf,
  default: defaultValue,
}) => {
  if (minimum) {
    defaultValue = _.max([defaultValue, minimum]);
  }
  if (maximum) {
    defaultValue = _.min([defaultValue, maximum]);
  }
  if (multipleOf) {
    const roundedValue =
      defaultValue === maximum
        ? _.floor(defaultValue / multipleOf) * multipleOf
        : _.ceil(defaultValue / multipleOf) * multipleOf;
    defaultValue = _.round(roundedValue, 1);
  }
  return defaultValue;
};

//TYPE WAS GENERATED, remove this line when reviewed
interface ListPropertyEditorProps {
  value: Array<AnyFixMe>;
  propertyData: AnyFixMe;
  onChange: FunctionFixMe;
  resolveDefinition: FunctionFixMe;
  openMediaManager: FunctionFixMe;
  translations?: {
    emptyStateTitle?: string;
    emptyStateText?: string;
  };
  propertyTitle?: string;
}

class ListPropertyEditor extends React.Component<ListPropertyEditorProps> {
  static displayName = 'listPropertyEditor';

  static propTypes = {
    value: PropTypes.array.isRequired,
    propertyData: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    resolveDefinition: PropTypes.func.isRequired,
    openMediaManager: PropTypes.func.isRequired,
    translations: PropTypes.shape({
      emptyStateTitle: PropTypes.string,
      emptyStateText: PropTypes.string,
    }),
    propertyTitle: PropTypes.string,
  };

  getListProps = () => {
    const props = this.createListPropsByType();
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    return _.assign({ selectable: false }, props);
  };

  getListClass = () => {
    return this.props.propertyTitle ? 'with-title' : '';
  };

  createListPropsByType = () => {
    if (this.isListOfObject()) {
      return OBJECT_LIST_PROPS;
    }
    const itemPropertyData = this.getItemPropertyData();
    return {
      shouldTranslateInvalidLabelMessage: false,
      labelValidator:
        propertySchemaValidationUtils.getValidatorByProp(itemPropertyData),
      labelInvalidMessage:
        propertySchemaValidationUtils.getInvalidMessageByProp(itemPropertyData),
      labelPlaceholder: getLabelPlaceholderByType(this.getItemType()),
    };
  };

  getDefaultValueByPropertyData = () => {
    const itemPropertyData = this.getItemPropertyData();
    if (this.getItemType() === ITEM_TYPES.number) {
      return getDefaultNumberByPropertyData(itemPropertyData);
    }

    return itemPropertyData.default;
  };

  getListItemProps = (item, itemIndex) => {
    const baseProps = {
      id: itemIndex,
      key: String(itemIndex),
      isSelected: itemIndex === this.state.selectedIndex,
    };

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    return _.assign(baseProps, this.getListItemPropsByType(item, itemIndex));
  };

  getListItemPropsByType = (item, itemIndex) => {
    if (this.isListOfObject()) {
      return {
        label: `${translate('AppStudio_CustomType_Item_DefaultText')} ${
          itemIndex + 1
        }`,
      };
    }
    if (this.getItemType() === ITEM_TYPES.image) {
      return {
        content: this.createMediaElement(item, itemIndex),
        className: CLASS_NAMES.LARGE,
      };
    }

    const label = valueToString(item, this.getItemType());
    return _.isEmpty(label) ? EMPTY_ITEM_PROPS : { label };
  };

  getMenuActionsOverrides = () => {
    return _.defaultsDeep(
      {},
      getActionOverridesByType(this.getItemType()),
      BASE_ACTION_OVERRIDES,
    );
  };

  createNewValueAsync = () => {
    if (this.getItemType() === ITEM_TYPES.image) {
      return new Promise((resolve) => {
        this.props.openMediaManager(
          (imagesData) => {
            if (imagesData) {
              // TODO: Fix this the next time the file is edited.
              // eslint-disable-next-line you-dont-need-lodash-underscore/map
              resolve(_.map(imagesData, convertImageDataToUri));
            }
          },
          { submitButton: 'AppStudio_MediaManager_AddItem_Button' },
        );
      });
    }

    return Promise.resolve(this.getDefaultValueByPropertyData());
  };

  selectable = () => {
    return this.isListOfObject();
  };

  selectItem = (index) => {
    if (this.selectable()) {
      this.setState({ selectedIndex: index });
    }
  };

  handleAddItem = () => {
    this.createNewValueAsync().then((newValue) => {
      this.props.onChange([...this.props.value, ..._.castArray(newValue)]);
      this.selectItem(this.props.value.length - 1);
    });
  };

  updateItem = (itemIndex, newValue) => {
    const newList = [...this.props.value];
    newList[itemIndex] = newValue;
    const itemPropertyData = this.getItemPropertyData();
    let shouldForceUpdate = false;
    if (
      this.getItemType() === ITEM_TYPES.number &&
      _.has(itemPropertyData, 'multipleOf')
    ) {
      newList[itemIndex] = this.roundByStep(
        newValue,
        itemPropertyData.multipleOf,
      );
      shouldForceUpdate = true;
    }
    this.props.onChange(newList);
    // forcing update because rounding values (after they were set) to meet validation do not trigger change in base ui component
    if (shouldForceUpdate) {
      this.forceUpdate();
    }
  };

  roundByStep = (n, step) => {
    return Math.round(n / step) * step;
  };

  moveItem = (items, oldIndex, newIndex) => {
    const newItems = [...items];
    newItems.splice(newIndex, 0, newItems.splice(oldIndex, 1)[0]);

    return newItems;
  };

  handleItemMoved = (oldIndex, newIndex) => {
    this.props.onChange(this.moveItem(this.props.value, oldIndex, newIndex));
    this.selectItem(newIndex);
  };

  handleItemChanged = (item, itemIndex) => {
    this.updateItem(itemIndex, parseLabel(item.label, this.getItemType()));
  };

  handleDuplicateItem = (item, itemIndex) => {
    const value = this.props.value[itemIndex];
    const newList = [...this.props.value];
    newList.splice(itemIndex, 0, value);
    this.props.onChange(newList);
    this.selectItem(itemIndex + 1);
  };

  handleDeleteItem = (item, itemIndex) => {
    if (this.state.selectedIndex === this.props.value.length - 1) {
      this.selectItem(this.state.selectedIndex - 1);
    }
    this.props.onChange(
      this.props.value.filter((val, index) => index !== itemIndex),
    );
  };

  handleSelectItem = (item, itemIndex) => {
    this.selectItem(itemIndex);
  };

  handleObjectChange = (newValue) => {
    this.updateItem(this.state.selectedIndex, newValue);
  };

  createMediaElement = (uri, itemIndex) => {
    const mediaItem = platformUtils.mediaItemUtils.parseMediaItemUri(uri);

    const innerImage = React.createElement(baseUI.image, {
      imageData: {
        uri: mediaItem.mediaId,
        width: mediaItem.width,
        height: mediaItem.height,
      },
      imageHeight: 84,
      imageWidth: 156,
    });

    return React.createElement(
      SecondaryMediaImage,
      {
        automationId: 'image-item',
        onClick: () => this.handleReplaceImage(itemIndex),
      },
      innerImage,
    );
  };

  handleReplaceImage = (itemIndex) => {
    this.props.openMediaManager((imagesData) => {
      if (imagesData) {
        const uri = convertImageDataToUri(_.head(imagesData));
        this.updateItem(itemIndex, uri);
      }
    });
  };

  getCustomActions = (itemIndex) => {
    switch (this.getItemType()) {
      case ITEM_TYPES.boolean:
        const currentValue = this.props.value[itemIndex];
        return [
          createContextMenuAction({
            onClick: () => this.updateItem(itemIndex, !currentValue),
            symbol: currentValue ? BOOLEAN_FALSE_SYMBOL : BOOLEAN_TRUE_SYMBOL,
            title: translate(
              currentValue
                ? 'AppStudio_CustomType_DefaultValues_Boolean_ChangeToFalse'
                : 'AppStudio_CustomType_DefaultValues_Boolean_ChangeToTrue',
            ),
            key: 'toggleBoolValue',
          }),
        ];
      case ITEM_TYPES.image:
        return [
          createContextMenuAction({
            onClick: () => this.handleReplaceImage(itemIndex),
            symbol: EDIT_SYMBOL,
            title: translate('Appstudio_CustomType_DefaultValues_ReplaceItem'),
            key: 'replaceImage',
          }),
        ];
    }
  };

  getListClassName = () => {
    return this.shouldShowObjectEditor()
      ? CLASS_NAMES.NARROW
      : CLASS_NAMES.WIDE;
  };

  shouldShowObjectEditor = () => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/is-undefined
    return this.isListOfObject() && !_.isUndefined(this.getSelectedItem());
  };

  getSelectedItem = () => {
    return this.props.value[this.state.selectedIndex];
  };

  getItemPropertyData = () => {
    return this.props.propertyData.items;
  };

  getItemType = () => {
    return this.getItemPropertyData().$id;
  };

  getItemSystemType = () => {
    return this.getItemPropertyData().type;
  };

  isListOfObject = () => {
    return this.getItemSystemType() === 'object';
  };

  state = {
    selectedIndex: this.selectable() ? 0 : -1,
  };

  render() {
    const isEmptyState = this.props.value.length === 0;

    return (
      <div className={`list-view ${this.getListClass()}`}>
        {!isEmptyState
          ? (() => {
              const listClass = this.getListClassName();

              return (
                <>
                  <div className="list-container">
                    <div className={`list-border ${listClass}`}>
                      <CustomScroll
                        heightRelativeToParent="100%"
                        keepAtBottom={true}
                      >
                        <SortByDragList
                          itemMoved={this.handleItemMoved}
                          itemChanged={this.handleItemChanged}
                          selectItem={this.handleSelectItem}
                          deleteItem={this.handleDeleteItem}
                          menuActionsOverrides={this.getMenuActionsOverrides()}
                          duplicateItem={this.handleDuplicateItem}
                          className={`drag-list ${listClass}`}
                          {...this.getListProps()}
                        >
                          {/* eslint-disable-next-line you-dont-need-lodash-underscore/map */}
                          {_.map(this.props.value, (item, itemIndex) => (
                            <SortByDragListCustomItemRow
                              automationId="value-list-item"
                              {...this.getListItemProps(item, itemIndex)}
                            >
                              {this.getCustomActions(itemIndex)}
                            </SortByDragListCustomItemRow>
                          ))}
                        </SortByDragList>
                      </CustomScroll>
                    </div>
                    <Composites.ButtonLeft className="add-item-btn">
                      <Button
                        automationId="add-item-btn"
                        onClick={this.handleAddItem}
                        className="btn-text add-item-btn"
                      >
                        +{' '}
                        {translate(
                          'Appstudio_CustomType_DefaultValues_AddItem_Link',
                        )}
                      </Button>
                    </Composites.ButtonLeft>
                  </div>
                  {this.shouldShowObjectEditor() ? (
                    <div
                      key="object-editor-container"
                      className="object-editor-container"
                    >
                      <ObjectPropertyEditor
                        automationId="object-editor"
                        value={this.getSelectedItem()}
                        propertyData={this.getItemPropertyData()}
                        resolveDefinition={this.props.resolveDefinition}
                        onChange={this.handleObjectChange}
                        openMediaManager={this.props.openMediaManager}
                      />
                    </div>
                  ) : null}
                </>
              );
            })()
          : null}
        {isEmptyState ? (
          <div key="empty-state" data-aid="empty-state" className="empty-state">
            <CustomScroll heightRelativeToParent="100%">
              <Composites.RichTextWithIllustrationVertical className="content-width-xl rich-text-with-illustration-container">
                <Illustration>
                  <symbols.symbol name="listEmptyState" />
                </Illustration>
                <Composites.RichTextLabeled className="empty-state-rich-text-labeled">
                  <TextLabel
                    automationId="empty-state-title"
                    type="T09"
                    value={
                      this.props.translations?.emptyStateTitle ??
                      'AppStudio_CustomType_DefaultValues_EmptyState_Title'
                    }
                    enableEllipsis={false}
                  />
                  <RichText automationId="empty-state-text">
                    <p>
                      {translate(
                        this.props.translations?.emptyStateText ??
                          'AppStudio_CustomType_DefaultValues_EmptyState_Text',
                      )}
                    </p>
                  </RichText>
                </Composites.RichTextLabeled>
              </Composites.RichTextWithIllustrationVertical>
              <Composites.ButtonMedium className="add-property-btn-text-container">
                <Button
                  automationId="add-item-empty-state-btn"
                  onClick={this.handleAddItem}
                  className="btn-text add-property-btn-text"
                >
                  <span>
                    +{' '}
                    {translate(
                      'AppStudio_CustomType_DefaultValues_EmptyState_CTA',
                    )}
                  </span>
                </Button>
              </Composites.ButtonMedium>
            </CustomScroll>
          </div>
        ) : null}
      </div>
    );
  }
}

export default ListPropertyEditor;
