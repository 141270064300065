// @ts-nocheck
import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import * as baseUI from '@/baseUI';
import * as util from '@/util';
import { constants } from '@/textControls';

import {
  Slider,
  Composites,
  InfoIcon,
  TextLabel,
  ButtonsGroup,
  RadioButtons,
} from '@wix/wix-base-ui';

const CUSTOM_VALUE = 'custom';
const AUTO_VALUE = 'auto';

const getSliderValue = (val, defaultValue) =>
  val !== undefined ? val : defaultValue;

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'lineHeight',
  render() {
    let Composite = Composites.RadioButtonsLabeled;
    let OptionsControl = RadioButtons;

    if (this.props.renderButtonsOptions) {
      Composite = Composites.ButtonsGroupLabeled;
      OptionsControl = ButtonsGroup;
    }
    return (
      <div className="control-line-height">
        <Composite
          className={util.cx({
            'no-bottom-padding': this.getSelectedOption() === CUSTOM_VALUE,
          })}
        >
          {this.props.infoText || this.props.infoTitle ? (
            <InfoIcon
              key="lineHeightInfo"
              title={this.props.infoTitle}
              text={this.props.infoText}
            />
          ) : null}
          <TextLabel value="text_editor_line_spacing_label" />
          <OptionsControl
            value={this.getSelectedOption()}
            options={this.props.options || this.getDefaultOptions()}
            onChange={this.selectionChange}
            className="line-spacing"
          />
        </Composite>
        {this.getSelectedOption() === CUSTOM_VALUE ? (
          <div key="line-height-slider" className="slider-wrapper">
            <Composites.SliderLabeled>
              <Slider
                value={this.getLineHeightValue()}
                min={getSliderValue(
                  this.props.min,
                  constants.MIN_VALUE_SLIDER_LINE_HEIGHT,
                )}
                max={getSliderValue(
                  this.props.max,
                  constants.MAX_VALUE_SLIDER_LINE_HEIGHT,
                )}
                inputMin={getSliderValue(
                  this.props.inputMin,
                  constants.MIN_VALUE_INPUT_LINE_HEIGHT,
                )}
                inputMax={getSliderValue(
                  this.props.inputMax,
                  constants.MAX_VALUE_INPUT_LINE_HEIGHT,
                )}
                inputStep={
                  this.props.inputStep ||
                  constants.DEFAULT_STEP_INPUT_LINE_HEIGHT
                }
                step={
                  this.props.step || constants.DEFAULT_STEP_SLIDER_LINE_HEIGHT
                }
                onChange={this.handleSliderChange}
                onSlideEnd={this.props.onSlideEnd}
              />
            </Composites.SliderLabeled>
          </div>
        ) : null}
      </div>
    );
  },
  mixins: [baseUI.inputMixin],
  propTypes: {
    infoText: PropTypes.string,
    infoTitle: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      }),
    ),
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    renderButtonsOptions: PropTypes.bool,
    min: PropTypes.number,
    max: PropTypes.number,
    step: PropTypes.number,
    inputStep: PropTypes.number,
    inputMin: PropTypes.number,
    inputMax: PropTypes.number,
  },
  getDefaultOptions() {
    return [
      { value: AUTO_VALUE, label: 'text_editor_line_spacing_automatic' },
      { value: CUSTOM_VALUE, label: 'text_editor_line_spacing_customize' },
    ];
  },
  isLineHeightDefault() {
    return ['', constants.DEFAULT_LINE_HEIGHT].includes(this.props.value);
  },
  getSelectedOption() {
    return this.isLineHeightDefault() ? AUTO_VALUE : CUSTOM_VALUE;
  },
  handleSliderChange(val) {
    util.valueLink.callOnChangeIfExists(this.props, val);
  },
  getLineHeightValue() {
    return this.isLineHeightDefault()
      ? AUTO_VALUE
      : parseFloat(this.props.value);
  },
  selectionChange(value) {
    if (value === AUTO_VALUE) {
      util.valueLink.callOnChangeIfExists(this.props, value);
    } else if (this.isLineHeightDefault()) {
      util.valueLink.callOnChangeIfExists(
        this.props,
        constants.DEFAULT_CUSTOM_LINE_HEIGHT,
      );
    }
  },
});
