import { keyboardShortcuts, snapDataUtils } from '@/util';

import type { Scope } from '../scope';
import type { CompRef } from 'types/documentServices';

let marginsDisplayTimeout: NodeJS.Timeout = null;
const marginsDisplayTime = 1000;

const drawMarginIndicators = (scope: Scope, selectedComponents: CompRef[]) => {
  const isAltKeyDown = keyboardShortcuts.isPressed.alt();

  if (selectedComponents.length !== 1) return;
  if (!isAltKeyDown) return;

  const [selectedComp] = selectedComponents;
  const container = scope.components.getContainerOrScopeOwner(selectedComp);
  const marginsIndicators = snapDataUtils.getMarginsSnapIndicators(
    scope.editorAPI,
    selectedComp,
    container,
  );
  clearTimeout(marginsDisplayTimeout);
  scope.editorAPI.snapData.set(marginsIndicators);
  marginsDisplayTimeout = setTimeout(
    () => scope.editorAPI.snapData.clear(true),
    marginsDisplayTime,
  );
};

const registerOnSelectionChange = (scope: Scope) => {
  scope.selection.hooks.selectionChanged.tap(({ compRefs }) =>
    drawMarginIndicators(scope, compRefs),
  );
};

export { registerOnSelectionChange };
