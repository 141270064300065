:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._multiplePresetIndication_7a2b9_7 {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  height: 10px;
  width: calc(100% - 14px);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-left: 1px solid #dfe5eb;
  border-right: 1px solid #dfe5eb;
  border-bottom: 1px solid #dfe5eb;
  box-shadow: 0 5px 7px -2px rgba(22, 45, 61, 0.16); }
