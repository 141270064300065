'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')
const mapDragging = true
const util = require('@/util')
const languageCode = util.languages.getLanguageCode()

module.exports = {
    "type": addPanelDataConsts.SECTIONS_TYPES.PRESET,
    "hide": false,
    "title": "add_section_label_googlemap_strip",
    "subNavigationTitle": 'add_section_label_google_maps_full_width',
    "presetTitle": "add_section_label_googlemap_strip",
    "tooltipTitle": "add_section_label_googlemap_strip",
    "automationId": "add-panel-section-googleMapsStripSection",
    "subNavigationHide": false,
    "showSectionHeader": true,
    "additionalBehaviours": {
        "labelMode": addPanelDataConsts.LABEL_BEHAVIOUR.HOVER,
        "infoIcon": addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
        "hoverImageAction": addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
        "iconEnabledForComps": {}
    },
    "props": {
        "image": "addPanelData/sections/googleMapsStripSection_en/googleMapsStripSection_en.png",
        "imageHover": null,
        "items": [
            {
                "id": "Contact_Google_Maps_Strip_1",
                "structure": {
                    "type": "Component",
                    "layout": {
                        "width": 608,
                        "height": 350,
                        "x": -462,
                        "y": 80,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false,
                        "docked": {
                            "left": {
                                "px": 0.5,
                                "vw": 0
                            },
                            "right": {
                                "px": 0,
                                "vw": 0
                            }
                        }
                    },
                    "componentType": "wysiwyg.viewer.components.GoogleMap",
                    "data": {
                        "type": "GeoMap",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "mapStyle": [
                            {
                                "elementType": "geometry",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "color": "#e9e9e9"
                                    },
                                    {
                                        "lightness": 17
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "landscape",
                                "stylers": [
                                    {
                                        "color": "#f5f5f5"
                                    },
                                    {
                                        "lightness": 20
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "road.highway",
                                "stylers": [
                                    {
                                        "color": "#ffffff"
                                    },
                                    {
                                        "lightness": 17
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.stroke",
                                "featureType": "road.highway",
                                "stylers": [
                                    {
                                        "color": "#ffffff"
                                    },
                                    {
                                        "lightness": 29
                                    },
                                    {
                                        "weight": 0.2
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "road.arterial",
                                "stylers": [
                                    {
                                        "color": "#ffffff"
                                    },
                                    {
                                        "lightness": 18
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "road.local",
                                "stylers": [
                                    {
                                        "color": "#ffffff"
                                    },
                                    {
                                        "lightness": 16
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "poi",
                                "stylers": [
                                    {
                                        "color": "#f5f5f5"
                                    },
                                    {
                                        "lightness": 21
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "poi.park",
                                "stylers": [
                                    {
                                        "color": "#dedede"
                                    },
                                    {
                                        "lightness": 21
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.stroke",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    },
                                    {
                                        "color": "#ffffff"
                                    },
                                    {
                                        "lightness": 16
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.fill",
                                "stylers": [
                                    {
                                        "saturation": 36
                                    },
                                    {
                                        "color": "#333333"
                                    },
                                    {
                                        "lightness": 40
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.icon",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "transit",
                                "stylers": [
                                    {
                                        "color": "#f2f2f2"
                                    },
                                    {
                                        "lightness": 19
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "administrative",
                                "stylers": [
                                    {
                                        "color": "#fefefe"
                                    },
                                    {
                                        "lightness": 20
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.stroke",
                                "featureType": "administrative",
                                "stylers": [
                                    {
                                        "color": "#fefefe"
                                    },
                                    {
                                        "lightness": 17
                                    },
                                    {
                                        "weight": 1.2
                                    }
                                ]
                            }
                        ],
                        "locations": [
                            {
                                "address": "500 Terry Francois Street, 6th Floor. San Francisco, CA 94158",
                                "title": "Wix Office",
                                "latitude": 37.77065,
                                "longitude": -122.387301,
                                "description": "",
                                "link": null,
                                "linkTitle": "",
                                "type": "GeoMapLocation",
                                "pinIcon": util.media.getMediaUrl('designPanel/panelTypes/google_maps_pin_icon.png'),
                                "pinColor": ""
                            }
                        ],
                        "defaultLocation": 0
                    },
                    "props": {
                        "type": "GoogleMapProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "showZoom": true,
                        "showPosition": true,
                        "showStreetView": true,
                        "showMapType": true,
                        mapDragging,
                        "mapType": "ROADMAP",
                        "language": languageCode
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "id": "gm1",
                        "metaData": {
                            "isPreset": true,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {},
                            "propertiesSource": {}
                        },
                        "componentClassName": "",
                        "pageId": "",
                        "compId": "",
                        "styleType": "system",
                        "skin": "wysiwyg.viewer.skins.GoogleMapSkin"
                    },
                    "id": "comp-inokew45"
                },
                "preset": {
                    "rect": {
                        "width": 324,
                        "height": 140,
                        "x": 0,
                        "y": 0
                    },
                    "label": "add_preset_Maps_Strips_1",
                    "tags": null
                }
            },
            {
                "id": "Contact_Google_Maps_Strip_2",
                "structure": {
                    "type": "Component",
                    "layout": {
                        "width": 608,
                        "height": 350,
                        "x": -462,
                        "y": 640,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false,
                        "docked": {
                            "left": {
                                "px": 0.5,
                                "vw": 0
                            },
                            "right": {
                                "px": 0.5,
                                "vw": 0
                            }
                        }
                    },
                    "componentType": "wysiwyg.viewer.components.GoogleMap",
                    "data": {
                        "type": "GeoMap",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "mapStyle": [
                            {
                                "elementType": "labels.text.fill",
                                "featureType": "all",
                                "stylers": [
                                    {
                                        "saturation": 36
                                    },
                                    {
                                        "color": "#333333"
                                    },
                                    {
                                        "lightness": 40
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.stroke",
                                "featureType": "all",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    },
                                    {
                                        "color": "#ffffff"
                                    },
                                    {
                                        "lightness": 16
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.icon",
                                "featureType": "all",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "administrative",
                                "stylers": [
                                    {
                                        "color": "#fdfeff"
                                    },
                                    {
                                        "lightness": 20
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.stroke",
                                "featureType": "administrative",
                                "stylers": [
                                    {
                                        "color": "#fefefe"
                                    },
                                    {
                                        "lightness": 17
                                    },
                                    {
                                        "weight": 1.2
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "landscape",
                                "stylers": [
                                    {
                                        "color": "#f5f5f5"
                                    },
                                    {
                                        "lightness": 20
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "landscape",
                                "stylers": [
                                    {
                                        "color": "#fdfeff"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.fill",
                                "featureType": "landscape",
                                "stylers": [
                                    {
                                        "color": "#345f8a"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "landscape.natural",
                                "stylers": [
                                    {
                                        "color": "#fdfeff"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "poi",
                                "stylers": [
                                    {
                                        "color": "#f5f5f5"
                                    },
                                    {
                                        "lightness": 21
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "poi",
                                "stylers": [
                                    {
                                        "color": "#dce0e2"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "poi.park",
                                "stylers": [
                                    {
                                        "color": "#dedede"
                                    },
                                    {
                                        "lightness": 21
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "poi.park",
                                "stylers": [
                                    {
                                        "color": "#fdfeff"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "road.highway",
                                "stylers": [
                                    {
                                        "color": "#ffffff"
                                    },
                                    {
                                        "lightness": 17
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.stroke",
                                "featureType": "road.highway",
                                "stylers": [
                                    {
                                        "color": "#ffffff"
                                    },
                                    {
                                        "lightness": 29
                                    },
                                    {
                                        "weight": 0.2
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "road.arterial",
                                "stylers": [
                                    {
                                        "color": "#ffffff"
                                    },
                                    {
                                        "lightness": 18
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "road.local",
                                "stylers": [
                                    {
                                        "color": "#ffffff"
                                    },
                                    {
                                        "lightness": 16
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "transit",
                                "stylers": [
                                    {
                                        "color": "#f2f2f2"
                                    },
                                    {
                                        "lightness": 19
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "color": "#e9e9e9"
                                    },
                                    {
                                        "lightness": 17
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "color": "#dce0e2"
                                    }
                                ]
                            }
                        ],
                        "locations": [
                            {
                                "address": "500 Terry Francois Street, 6th Floor. San Francisco, CA 94158",
                                "title": "Wix Office",
                                "latitude": 37.77065,
                                "longitude": -122.387301,
                                "description": "",
                                "link": null,
                                "linkTitle": "",
                                "type": "GeoMapLocation",
                                "pinIcon": util.media.getMediaUrl('designPanel/panelTypes/google_maps_pin_icon.png'),
                                "pinColor": ""
                            }
                        ],
                        "defaultLocation": 0
                    },
                    "props": {
                        "type": "GoogleMapProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "showZoom": true,
                        "showPosition": true,
                        "showStreetView": true,
                        "showMapType": true,
                        mapDragging,
                        "mapType": "ROADMAP",
                        "language": languageCode
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "id": "gm1",
                        "metaData": {
                            "isPreset": true,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {},
                            "propertiesSource": {}
                        },
                        "componentClassName": "",
                        "pageId": "",
                        "compId": "",
                        "styleType": "system",
                        "skin": "wysiwyg.viewer.skins.GoogleMapSkin"
                    },
                    "id": "comp-inoki54z"
                },
                "preset": {
                    "rect": {
                        "width": 324,
                        "height": 140,
                        "x": 0,
                        "y": 140
                    },
                    "label": "add_preset_Maps_Strips_2",
                    "tags": null
                }
            },
            {
                "id": "Contact_Google_Maps_Strip_3",
                "structure": {
                    "type": "Component",
                    "layout": {
                        "width": 608,
                        "height": 350,
                        "x": -462,
                        "y": 1200,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false,
                        "docked": {
                            "left": {
                                "px": 0.5,
                                "vw": 0
                            },
                            "right": {
                                "px": 0,
                                "vw": 0
                            }
                        }
                    },
                    "componentType": "wysiwyg.viewer.components.GoogleMap",
                    "data": {
                        "type": "GeoMap",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "mapStyle": [
                            {
                                "elementType": "all",
                                "featureType": "administrative.locality",
                                "stylers": [
                                    {
                                        "hue": "#2c2e33"
                                    },
                                    {
                                        "saturation": 7
                                    },
                                    {
                                        "lightness": 19
                                    },
                                    {
                                        "visibility": "on"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "landscape",
                                "stylers": [
                                    {
                                        "hue": "#ffffff"
                                    },
                                    {
                                        "saturation": -100
                                    },
                                    {
                                        "lightness": 100
                                    },
                                    {
                                        "visibility": "simplified"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "poi",
                                "stylers": [
                                    {
                                        "hue": "#ffffff"
                                    },
                                    {
                                        "saturation": -100
                                    },
                                    {
                                        "lightness": 100
                                    },
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "road",
                                "stylers": [
                                    {
                                        "hue": "#bbc0c4"
                                    },
                                    {
                                        "saturation": -93
                                    },
                                    {
                                        "lightness": 31
                                    },
                                    {
                                        "visibility": "simplified"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels",
                                "featureType": "road",
                                "stylers": [
                                    {
                                        "hue": "#bbc0c4"
                                    },
                                    {
                                        "saturation": -93
                                    },
                                    {
                                        "lightness": 31
                                    },
                                    {
                                        "visibility": "on"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels",
                                "featureType": "road.arterial",
                                "stylers": [
                                    {
                                        "hue": "#bbc0c4"
                                    },
                                    {
                                        "saturation": -93
                                    },
                                    {
                                        "lightness": -2
                                    },
                                    {
                                        "visibility": "simplified"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "road.local",
                                "stylers": [
                                    {
                                        "hue": "#e9ebed"
                                    },
                                    {
                                        "saturation": -90
                                    },
                                    {
                                        "lightness": -8
                                    },
                                    {
                                        "visibility": "simplified"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "transit",
                                "stylers": [
                                    {
                                        "hue": "#e9ebed"
                                    },
                                    {
                                        "saturation": 10
                                    },
                                    {
                                        "lightness": 69
                                    },
                                    {
                                        "visibility": "on"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "hue": "#e9ebed"
                                    },
                                    {
                                        "saturation": -78
                                    },
                                    {
                                        "lightness": 67
                                    },
                                    {
                                        "visibility": "simplified"
                                    }
                                ]
                            }
                        ],
                        "locations": [
                            {
                                "address": "500 Terry Francois Street, 6th Floor. San Francisco, CA 94158",
                                "title": "Wix Office",
                                "latitude": 37.77065,
                                "longitude": -122.387301,
                                "description": "",
                                "link": null,
                                "linkTitle": "",
                                "type": "GeoMapLocation",
                                "pinIcon": util.media.getMediaUrl('designPanel/panelTypes/google_maps_pin_icon.png'),
                                "pinColor": ""
                            }
                        ],
                        "defaultLocation": 0
                    },
                    "props": {
                        "type": "GoogleMapProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "showZoom": true,
                        "showPosition": true,
                        "showStreetView": true,
                        "showMapType": true,
                        mapDragging,
                        "mapType": "ROADMAP",
                        "language": languageCode
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "id": "gm1",
                        "metaData": {
                            "isPreset": true,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {},
                            "propertiesSource": {}
                        },
                        "componentClassName": "",
                        "pageId": "",
                        "compId": "",
                        "styleType": "system",
                        "skin": "wysiwyg.viewer.skins.GoogleMapSkin"
                    },
                    "id": "comp-inokipjn"
                },
                "preset": {
                    "rect": {
                        "width": 324,
                        "height": 140,
                        "x": 0,
                        "y": 280
                    },
                    "label": "add_preset_Maps_Strips_3",
                    "tags": null
                }
            },
            {
                "id": "Contact_Google_Maps_Strip_4",
                "structure": {
                    "type": "Component",
                    "layout": {
                        "width": 608,
                        "height": 350,
                        "x": -462,
                        "y": 1760,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false,
                        "docked": {
                            "left": {
                                "px": 0.5,
                                "vw": 0
                            },
                            "right": {
                                "px": 0,
                                "vw": 0
                            }
                        }
                    },
                    "componentType": "wysiwyg.viewer.components.GoogleMap",
                    "data": {
                        "type": "GeoMap",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "mapStyle": [
                            {
                                "elementType": "geometry.fill",
                                "featureType": "administrative.country",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    },
                                    {
                                        "color": "#a93787"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "landscape",
                                "stylers": [
                                    {
                                        "hue": "#FFBB00"
                                    },
                                    {
                                        "saturation": 43
                                    },
                                    {
                                        "lightness": 37
                                    },
                                    {
                                        "gamma": 1
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "poi",
                                "stylers": [
                                    {
                                        "hue": "#00FF6A"
                                    },
                                    {
                                        "saturation": -1
                                    },
                                    {
                                        "lightness": 11
                                    },
                                    {
                                        "gamma": 1
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.stroke",
                                "featureType": "poi.attraction",
                                "stylers": [
                                    {
                                        "color": "#f52121"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "road.highway",
                                "stylers": [
                                    {
                                        "hue": "#FFC200"
                                    },
                                    {
                                        "saturation": -61
                                    },
                                    {
                                        "lightness": 45
                                    },
                                    {
                                        "gamma": 1
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "road.arterial",
                                "stylers": [
                                    {
                                        "hue": "#FF0300"
                                    },
                                    {
                                        "saturation": -100
                                    },
                                    {
                                        "lightness": 51
                                    },
                                    {
                                        "gamma": 1
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "road.local",
                                "stylers": [
                                    {
                                        "hue": "#FF0300"
                                    },
                                    {
                                        "saturation": -100
                                    },
                                    {
                                        "lightness": 52
                                    },
                                    {
                                        "gamma": 1
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "hue": "#0078FF"
                                    },
                                    {
                                        "saturation": -13
                                    },
                                    {
                                        "lightness": 2
                                    },
                                    {
                                        "gamma": 1
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "color": "#83ccce"
                                    }
                                ]
                            }
                        ],
                        "locations": [
                            {
                                "address": "500 Terry Francois Street, 6th Floor. San Francisco, CA 94158",
                                "title": "Wix Office",
                                "latitude": 37.77065,
                                "longitude": -122.387301,
                                "description": "",
                                "link": null,
                                "linkTitle": "",
                                "type": "GeoMapLocation",
                                "pinIcon": util.media.getMediaUrl('designPanel/panelTypes/google_maps_pin_icon.png'),
                                "pinColor": ""
                            }
                        ],
                        "defaultLocation": 0
                    },
                    "props": {
                        "type": "GoogleMapProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "showZoom": true,
                        "showPosition": true,
                        "showStreetView": true,
                        "showMapType": true,
                        mapDragging,
                        "mapType": "ROADMAP",
                        "language": languageCode
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "id": "gm1",
                        "metaData": {
                            "isPreset": true,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {},
                            "propertiesSource": {}
                        },
                        "componentClassName": "",
                        "pageId": "",
                        "compId": "",
                        "styleType": "system",
                        "skin": "wysiwyg.viewer.skins.GoogleMapSkin"
                    },
                    "id": "comp-inokiw7b"
                },
                "preset": {
                    "rect": {
                        "width": 324,
                        "height": 140,
                        "x": 0,
                        "y": 420
                    },
                    "label": "add_preset_Maps_Strips_4",
                    "tags": null
                }
            },
            {
                "id": "Contact_Google_Maps_Strip_5",
                "structure": {
                    "type": "Component",
                    "layout": {
                        "width": 608,
                        "height": 350,
                        "x": -462,
                        "y": 2320,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false,
                        "docked": {
                            "left": {
                                "px": 0.5,
                                "vw": 0
                            },
                            "right": {
                                "px": 0,
                                "vw": 0
                            }
                        }
                    },
                    "componentType": "wysiwyg.viewer.components.GoogleMap",
                    "data": {
                        "type": "GeoMap",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "mapStyle": [
                            {
                                "elementType": "labels.text.fill",
                                "featureType": "administrative",
                                "stylers": [
                                    {
                                        "color": "#444444"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "administrative.country",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "administrative.province",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "administrative.neighborhood",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "administrative.land_parcel",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "landscape",
                                "stylers": [
                                    {
                                        "color": "#f2f2f2"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "landscape",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "landscape.man_made",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "landscape.natural.landcover",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "landscape.natural.terrain",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "poi",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "poi",
                                "stylers": [
                                    {
                                        "color": "#e10404"
                                    },
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "poi.attraction",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "poi.business",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    },
                                    {
                                        "hue": "#ff0070"
                                    },
                                    {
                                        "saturation": 0
                                    },
                                    {
                                        "lightness": -57
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "poi.government",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "poi.medical",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    },
                                    {
                                        "color": "#eb65a0"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "poi.park",
                                "stylers": [
                                    {
                                        "color": "#eb65a0"
                                    },
                                    {
                                        "visibility": "on"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "poi.school",
                                "stylers": [
                                    {
                                        "hue": "#ff0000"
                                    },
                                    {
                                        "visibility": "on"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "poi.sports_complex",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "road",
                                "stylers": [
                                    {
                                        "saturation": -100
                                    },
                                    {
                                        "lightness": 45
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "road.highway",
                                "stylers": [
                                    {
                                        "visibility": "simplified"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "road.highway",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    },
                                    {
                                        "color": "#bccf02"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text",
                                "featureType": "road.highway",
                                "stylers": [
                                    {
                                        "color": "#ffffff"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "road.highway.controlled_access",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "road.arterial",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    },
                                    {
                                        "color": "#9b539c"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.icon",
                                "featureType": "road.arterial",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "road.local",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    },
                                    {
                                        "hue": "#ff0000"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "road.local",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    },
                                    {
                                        "hue": "#ff0000"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "transit",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "transit.line",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    },
                                    {
                                        "color": "#9b539c"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "transit.station.bus",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "color": "#46bcec"
                                    },
                                    {
                                        "visibility": "on"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "color": "#73c5e1"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    },
                                    {
                                        "hue": "#ff0000"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.fill",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "color": "#ffffff"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.stroke",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            }
                        ],
                        "locations": [
                            {
                                "address": "500 Terry Francois Street, 6th Floor. San Francisco, CA 94158",
                                "title": "Wix Office",
                                "latitude": 37.77065,
                                "longitude": -122.387301,
                                "description": "",
                                "link": null,
                                "linkTitle": "",
                                "type": "GeoMapLocation",
                                "pinIcon": util.media.getMediaUrl('designPanel/panelTypes/google_maps_pin_icon.png'),
                                "pinColor": ""
                            }
                        ],
                        "defaultLocation": 0
                    },
                    "props": {
                        "type": "GoogleMapProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "showZoom": true,
                        "showPosition": true,
                        "showStreetView": true,
                        "showMapType": true,
                        mapDragging,
                        "mapType": "ROADMAP",
                        "language": languageCode
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "id": "gm1",
                        "metaData": {
                            "isPreset": true,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {},
                            "propertiesSource": {}
                        },
                        "componentClassName": "",
                        "pageId": "",
                        "compId": "",
                        "styleType": "system",
                        "skin": "wysiwyg.viewer.skins.GoogleMapSkin"
                    },
                    "id": "comp-inokjpu6"
                },
                "preset": {
                    "rect": {
                        "width": 324,
                        "height": 140,
                        "x": 0,
                        "y": 560
                    },
                    "label": "add_preset_Maps_Strips_5",
                    "tags": null
                }
            },
            {
                "id": "Contact_Google_Maps_Strip_6",
                "structure": {
                    "type": "Component",
                    "layout": {
                        "width": 608,
                        "height": 350,
                        "x": -462,
                        "y": 2880,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false,
                        "docked": {
                            "left": {
                                "px": 0.5,
                                "vw": 0
                            },
                            "right": {
                                "px": 0,
                                "vw": 0
                            }
                        }
                    },
                    "componentType": "wysiwyg.viewer.components.GoogleMap",
                    "data": {
                        "type": "GeoMap",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "mapStyle": [
                            {
                                "elementType": "labels.text.fill",
                                "featureType": "administrative",
                                "stylers": [
                                    {
                                        "color": "#444444"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text",
                                "featureType": "administrative.locality",
                                "stylers": [
                                    {
                                        "visibility": "simplified"
                                    },
                                    {
                                        "lightness": -22
                                    },
                                    {
                                        "gamma": 4.97
                                    },
                                    {
                                        "saturation": -57
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.stroke",
                                "featureType": "administrative.land_parcel",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "landscape",
                                "stylers": [
                                    {
                                        "color": "#f2f2f2"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "poi",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "poi.attraction",
                                "stylers": [
                                    {
                                        "visibility": "simplified"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "poi.attraction",
                                "stylers": [
                                    {
                                        "color": "#ffcece"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.icon",
                                "featureType": "poi.attraction",
                                "stylers": [
                                    {
                                        "color": "#ff0000"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "poi.business",
                                "stylers": [
                                    {
                                        "visibility": "simplified"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.icon",
                                "featureType": "poi.business",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    },
                                    {
                                        "invert_lightness": true
                                    },
                                    {
                                        "gamma": 10
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "poi.park",
                                "stylers": [
                                    {
                                        "color": "#9dd99b"
                                    },
                                    {
                                        "visibility": "simplified"
                                    },
                                    {
                                        "gamma": 2.79
                                    },
                                    {
                                        "lightness": 27
                                    },
                                    {
                                        "saturation": 4
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text",
                                "featureType": "poi.park",
                                "stylers": [
                                    {
                                        "color": "#54734e"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "road",
                                "stylers": [
                                    {
                                        "saturation": -100
                                    },
                                    {
                                        "lightness": 45
                                    },
                                    {
                                        "visibility": "simplified"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "road.highway",
                                "stylers": [
                                    {
                                        "visibility": "simplified"
                                    },
                                    {
                                        "color": "#ff8800"
                                    },
                                    {
                                        "gamma": 1.5
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text",
                                "featureType": "road.highway",
                                "stylers": [
                                    {
                                        "visibility": "simplified"
                                    },
                                    {
                                        "saturation": -92
                                    },
                                    {
                                        "color": "#ffffff"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.icon",
                                "featureType": "road.arterial",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "transit",
                                "stylers": [
                                    {
                                        "visibility": "simplified"
                                    },
                                    {
                                        "saturation": -93
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.icon",
                                "featureType": "transit",
                                "stylers": [
                                    {
                                        "visibility": "simplified"
                                    },
                                    {
                                        "gamma": 1
                                    },
                                    {
                                        "hue": "#ff0000"
                                    },
                                    {
                                        "saturation": 100
                                    },
                                    {
                                        "lightness": 0
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "transit.line",
                                "stylers": [
                                    {
                                        "saturation": -59
                                    },
                                    {
                                        "gamma": 1
                                    },
                                    {
                                        "lightness": -29
                                    },
                                    {
                                        "weight": 0.13
                                    }
                                ]
                            },
                            {
                                "elementType": "labels",
                                "featureType": "transit.line",
                                "stylers": [
                                    {
                                        "visibility": "simplified"
                                    },
                                    {
                                        "gamma": 4.71
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text",
                                "featureType": "transit.line",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.fill",
                                "featureType": "transit.line",
                                "stylers": [
                                    {
                                        "visibility": "simplified"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "color": "#47b6bb"
                                    },
                                    {
                                        "visibility": "on"
                                    },
                                    {
                                        "lightness": 58
                                    },
                                    {
                                        "saturation": 45
                                    }
                                ]
                            }
                        ],
                        "locations": [
                            {
                                "address": "500 Terry Francois Street, 6th Floor. San Francisco, CA 94158",
                                "title": "Wix Office",
                                "latitude": 37.77065,
                                "longitude": -122.387301,
                                "description": "",
                                "link": null,
                                "linkTitle": "",
                                "type": "GeoMapLocation",
                                "pinIcon": util.media.getMediaUrl('designPanel/panelTypes/google_maps_pin_icon.png'),
                                "pinColor": ""
                            }
                        ],
                        "defaultLocation": 0
                    },
                    "props": {
                        "type": "GoogleMapProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "showZoom": true,
                        "showPosition": true,
                        "showStreetView": true,
                        "showMapType": true,
                        mapDragging,
                        "mapType": "ROADMAP",
                        "language": languageCode
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "id": "gm1",
                        "metaData": {
                            "isPreset": true,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {},
                            "propertiesSource": {}
                        },
                        "componentClassName": "",
                        "pageId": "",
                        "compId": "",
                        "styleType": "system",
                        "skin": "wysiwyg.viewer.skins.GoogleMapSkin"
                    },
                    "id": "comp-inokk3gr"
                },
                "preset": {
                    "rect": {
                        "width": 324,
                        "height": 140,
                        "x": 0,
                        "y": 700
                    },
                    "label": "add_preset_Maps_Strips_6",
                    "tags": null
                }
            },
            {
                "id": "Contact_Google_Maps_Strip_7",
                "structure": {
                    "type": "Component",
                    "layout": {
                        "width": 608,
                        "height": 350,
                        "x": -462,
                        "y": 3440,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false,
                        "docked": {
                            "left": {
                                "px": 0.5,
                                "vw": 0
                            },
                            "right": {
                                "px": 0,
                                "vw": 0
                            }
                        }
                    },
                    "componentType": "wysiwyg.viewer.components.GoogleMap",
                    "data": {
                        "type": "GeoMap",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "mapStyle": [
                            {
                                "elementType": "labels.text.fill",
                                "featureType": "administrative",
                                "stylers": [
                                    {
                                        "color": "#444444"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.icon",
                                "featureType": "administrative",
                                "stylers": [
                                    {
                                        "saturation": 15
                                    },
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "administrative.locality",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.icon",
                                "featureType": "administrative.locality",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "landscape",
                                "stylers": [
                                    {
                                        "color": "#f2f2f2"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "poi",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "road",
                                "stylers": [
                                    {
                                        "saturation": -100
                                    },
                                    {
                                        "lightness": 45
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "road.highway",
                                "stylers": [
                                    {
                                        "visibility": "simplified"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.icon",
                                "featureType": "road.arterial",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "transit",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "color": "#fed500"
                                    },
                                    {
                                        "visibility": "on"
                                    }
                                ]
                            }
                        ],
                        "locations": [
                            {
                                "address": "500 Terry Francois Street, 6th Floor. San Francisco, CA 94158",
                                "title": "Wix Office",
                                "latitude": 37.77065,
                                "longitude": -122.387301,
                                "description": "",
                                "link": null,
                                "linkTitle": "",
                                "type": "GeoMapLocation",
                                "pinIcon": util.media.getMediaUrl('designPanel/panelTypes/google_maps_pin_icon.png'),
                                "pinColor": ""
                            }
                        ],
                        "defaultLocation": 0
                    },
                    "props": {
                        "type": "GoogleMapProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "showZoom": true,
                        "showPosition": true,
                        "showStreetView": true,
                        "showMapType": true,
                        mapDragging,
                        "mapType": "ROADMAP",
                        "language": languageCode
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "id": "gm1",
                        "metaData": {
                            "isPreset": true,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {},
                            "propertiesSource": {}
                        },
                        "componentClassName": "",
                        "pageId": "",
                        "compId": "",
                        "styleType": "system",
                        "skin": "wysiwyg.viewer.skins.GoogleMapSkin"
                    },
                    "id": "comp-inokkffq"
                },
                "preset": {
                    "rect": {
                        "width": 324,
                        "height": 140,
                        "x": 0,
                        "y": 840
                    },
                    "label": "add_preset_Maps_Strips_7",
                    "tags": null
                }
            },
            {
                "id": "Contact_Google_Maps_Strip_8",
                "structure": {
                    "type": "Component",
                    "layout": {
                        "width": 608,
                        "height": 350,
                        "x": -462,
                        "y": 4000,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false,
                        "docked": {
                            "left": {
                                "px": 0.5,
                                "vw": 0
                            },
                            "right": {
                                "px": 0,
                                "vw": 0
                            }
                        }
                    },
                    "componentType": "wysiwyg.viewer.components.GoogleMap",
                    "data": {
                        "type": "GeoMap",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "mapStyle": [
                            {
                                "elementType": "labels.text.fill",
                                "featureType": "administrative",
                                "stylers": [
                                    {
                                        "color": "#444444"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "landscape",
                                "stylers": [
                                    {
                                        "color": "#f2f2f2"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "poi",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "road",
                                "stylers": [
                                    {
                                        "saturation": -100
                                    },
                                    {
                                        "lightness": 45
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "road.highway",
                                "stylers": [
                                    {
                                        "visibility": "simplified"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.icon",
                                "featureType": "road.arterial",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "transit",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "color": "#ff6865"
                                    },
                                    {
                                        "visibility": "on"
                                    }
                                ]
                            }
                        ],
                        "locations": [
                            {
                                "address": "500 Terry Francois Street, 6th Floor. San Francisco, CA 94158",
                                "title": "Wix Office",
                                "latitude": 37.77065,
                                "longitude": -122.387301,
                                "description": "",
                                "link": null,
                                "linkTitle": "",
                                "type": "GeoMapLocation",
                                "pinIcon": util.media.getMediaUrl('designPanel/panelTypes/google_maps_pin_icon.png'),
                                "pinColor": ""
                            }
                        ],
                        "defaultLocation": 0
                    },
                    "props": {
                        "type": "GoogleMapProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "showZoom": true,
                        "showPosition": true,
                        "showStreetView": true,
                        "showMapType": true,
                        mapDragging,
                        "mapType": "ROADMAP",
                        "language": languageCode
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "id": "gm1",
                        "metaData": {
                            "isPreset": true,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {},
                            "propertiesSource": {}
                        },
                        "componentClassName": "",
                        "pageId": "",
                        "compId": "",
                        "styleType": "system",
                        "skin": "wysiwyg.viewer.skins.GoogleMapSkin"
                    },
                    "id": "comp-inokkoks"
                },
                "preset": {
                    "rect": {
                        "width": 324,
                        "height": 140,
                        "x": 0,
                        "y": 980
                    },
                    "label": "add_preset_Maps_Strips_8",
                    "tags": null
                }
            },
            {
                "id": "Contact_Google_Maps_Strip_9",
                "structure": {
                    "type": "Component",
                    "layout": {
                        "width": 608,
                        "height": 350,
                        "x": -462,
                        "y": 4560,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false,
                        "docked": {
                            "left": {
                                "px": 0.5,
                                "vw": 0
                            },
                            "right": {
                                "px": 0,
                                "vw": 0
                            }
                        }
                    },
                    "componentType": "wysiwyg.viewer.components.GoogleMap",
                    "data": {
                        "type": "GeoMap",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "mapStyle": [
                            {
                                "elementType": "geometry.stroke",
                                "featureType": "administrative",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.stroke",
                                "featureType": "administrative.country",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels",
                                "featureType": "administrative.country",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.stroke",
                                "featureType": "administrative.province",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.stroke",
                                "featureType": "administrative.locality",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "poi",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "road",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "transit",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "saturation": -7
                                    },
                                    {
                                        "lightness": -20
                                    }
                                ]
                            }
                        ],
                        "locations": [
                            {
                                "address": "500 Terry Francois Street, 6th Floor. San Francisco, CA 94158",
                                "title": "Wix Office",
                                "latitude": 37.77065,
                                "longitude": -122.387301,
                                "description": "",
                                "link": null,
                                "linkTitle": "",
                                "type": "GeoMapLocation",
                                "pinIcon": util.media.getMediaUrl('designPanel/panelTypes/google_maps_pin_icon.png'),
                                "pinColor": ""
                            }
                        ],
                        "defaultLocation": 0
                    },
                    "props": {
                        "type": "GoogleMapProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "showZoom": true,
                        "showPosition": true,
                        "showStreetView": true,
                        "showMapType": true,
                        mapDragging,
                        "mapType": "ROADMAP",
                        "language": languageCode
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "id": "gm1",
                        "metaData": {
                            "isPreset": true,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {},
                            "propertiesSource": {}
                        },
                        "componentClassName": "",
                        "pageId": "",
                        "compId": "",
                        "styleType": "system",
                        "skin": "wysiwyg.viewer.skins.GoogleMapSkin"
                    },
                    "id": "comp-inokra2n"
                },
                "preset": {
                    "rect": {
                        "width": 324,
                        "height": 140,
                        "x": 0,
                        "y": 1120
                    },
                    "label": "add_preset_Maps_Strips_9",
                    "tags": null
                }
            },
            {
                "id": "Contact_Google_Maps_Strip_10",
                "structure": {
                    "type": "Component",
                    "layout": {
                        "width": 608,
                        "height": 350,
                        "x": -462,
                        "y": 5120,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false,
                        "docked": {
                            "left": {
                                "px": 0.5,
                                "vw": 0
                            },
                            "right": {
                                "px": 0,
                                "vw": 0
                            }
                        }
                    },
                    "componentType": "wysiwyg.viewer.components.GoogleMap",
                    "data": {
                        "type": "GeoMap",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "mapStyle": [
                            {
                                "elementType": "geometry",
                                "featureType": "administrative",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels",
                                "featureType": "administrative",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.fill",
                                "featureType": "administrative",
                                "stylers": [
                                    {
                                        "color": "#444444"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "landscape",
                                "stylers": [
                                    {
                                        "color": "#f2f2f2"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "landscape",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    },
                                    {
                                        "hue": "#008aff"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "poi",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "road",
                                "stylers": [
                                    {
                                        "saturation": -100
                                    },
                                    {
                                        "lightness": 45
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "road",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    },
                                    {
                                        "color": "#0ab9c8"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels",
                                "featureType": "road",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "road.highway",
                                "stylers": [
                                    {
                                        "visibility": "simplified"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels",
                                "featureType": "road.highway",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.icon",
                                "featureType": "road.arterial",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "transit",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "transit",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "color": "#5ef141"
                                    },
                                    {
                                        "visibility": "on"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    },
                                    {
                                        "color": "#0ab9c8"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            }
                        ],
                        "locations": [
                            {
                                "address": "500 Terry Francois Street, 6th Floor. San Francisco, CA 94158",
                                "title": "Wix Office",
                                "latitude": 37.77065,
                                "longitude": -122.387301,
                                "description": "",
                                "link": null,
                                "linkTitle": "",
                                "type": "GeoMapLocation",
                                "pinIcon": util.media.getMediaUrl('designPanel/panelTypes/google_maps_pin_icon.png'),
                                "pinColor": ""
                            }
                        ],
                        "defaultLocation": 0
                    },
                    "props": {
                        "type": "GoogleMapProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "showZoom": true,
                        "showPosition": true,
                        "showStreetView": true,
                        "showMapType": true,
                        mapDragging,
                        "mapType": "ROADMAP",
                        "language": languageCode
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "id": "gm1",
                        "metaData": {
                            "isPreset": true,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {},
                            "propertiesSource": {}
                        },
                        "componentClassName": "",
                        "pageId": "",
                        "compId": "",
                        "styleType": "system",
                        "skin": "wysiwyg.viewer.skins.GoogleMapSkin"
                    },
                    "id": "comp-inokrt97"
                },
                "preset": {
                    "rect": {
                        "width": 324,
                        "height": 140,
                        "x": 0,
                        "y": 1260
                    },
                    "label": "add_preset_Maps_Strips_10",
                    "tags": null
                }
            },
            {
                "id": "Contact_Google_Maps_Strip_11",
                "structure": {
                    "type": "Component",
                    "layout": {
                        "width": 608,
                        "height": 350,
                        "x": -462,
                        "y": 5680,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false,
                        "docked": {
                            "left": {
                                "px": 0.5,
                                "vw": 0
                            },
                            "right": {
                                "px": 0,
                                "vw": 0
                            }
                        }
                    },
                    "componentType": "wysiwyg.viewer.components.GoogleMap",
                    "data": {
                        "type": "GeoMap",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "mapStyle": [
                            {
                                "elementType": "geometry",
                                "featureType": "administrative",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "administrative",
                                "stylers": [
                                    {
                                        "hue": "#ff0000"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.stroke",
                                "featureType": "administrative",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    },
                                    {
                                        "color": "#e6007e"
                                    },
                                    {
                                        "weight": 0.3
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.fill",
                                "featureType": "administrative",
                                "stylers": [
                                    {
                                        "color": "#e6007e"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.stroke",
                                "featureType": "administrative",
                                "stylers": [
                                    {
                                        "color": "#fff131"
                                    },
                                    {
                                        "visibility": "on"
                                    },
                                    {
                                        "weight": 6
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.icon",
                                "featureType": "administrative",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    },
                                    {
                                        "color": "#e6007e"
                                    },
                                    {
                                        "weight": 1
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "landscape",
                                "stylers": [
                                    {
                                        "color": "#fff131"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "poi",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "road",
                                "stylers": [
                                    {
                                        "color": "#e6007e"
                                    },
                                    {
                                        "visibility": "simplified"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text",
                                "featureType": "road",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    },
                                    {
                                        "color": "#fff131"
                                    },
                                    {
                                        "weight": 8
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.fill",
                                "featureType": "road",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    },
                                    {
                                        "color": "#e6007e"
                                    },
                                    {
                                        "weight": 8
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.icon",
                                "featureType": "road",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "transit",
                                "stylers": [
                                    {
                                        "visibility": "simplified"
                                    },
                                    {
                                        "color": "#e6007e"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    },
                                    {
                                        "color": "#e6007e"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "visibility": "simplified"
                                    },
                                    {
                                        "color": "#fff131"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.icon",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            }
                        ],
                        "locations": [
                            {
                                "address": "500 Terry Francois Street, 6th Floor. San Francisco, CA 94158",
                                "title": "Wix Office",
                                "latitude": 37.77065,
                                "longitude": -122.387301,
                                "description": "",
                                "link": null,
                                "linkTitle": "",
                                "type": "GeoMapLocation",
                                "pinIcon": util.media.getMediaUrl('designPanel/panelTypes/google_maps_pin_icon.png'),
                                "pinColor": ""
                            }
                        ],
                        "defaultLocation": 0
                    },
                    "props": {
                        "type": "GoogleMapProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "showZoom": true,
                        "showPosition": true,
                        "showStreetView": true,
                        "showMapType": true,
                        mapDragging,
                        "mapType": "ROADMAP",
                        "language": languageCode
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "id": "gm1",
                        "metaData": {
                            "isPreset": true,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {},
                            "propertiesSource": {}
                        },
                        "componentClassName": "",
                        "pageId": "",
                        "compId": "",
                        "styleType": "system",
                        "skin": "wysiwyg.viewer.skins.GoogleMapSkin"
                    },
                    "id": "comp-inokrziq"
                },
                "preset": {
                    "rect": {
                        "width": 324,
                        "height": 140,
                        "x": 0,
                        "y": 1400
                    },
                    "label": "add_preset_Maps_Strips_11",
                    "tags": null
                }
            },
            {
                "id": "Contact_Google_Maps_Strip_12",
                "structure": {
                    "type": "Component",
                    "layout": {
                        "width": 608,
                        "height": 350,
                        "x": -462,
                        "y": 6240,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false,
                        "docked": {
                            "left": {
                                "px": 0.5,
                                "vw": 0
                            },
                            "right": {
                                "px": 0,
                                "vw": 0
                            }
                        }
                    },
                    "componentType": "wysiwyg.viewer.components.GoogleMap",
                    "data": {
                        "type": "GeoMap",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "mapStyle": [
                            {
                                "elementType": "labels.text.fill",
                                "featureType": "all",
                                "stylers": [
                                    {
                                        "saturation": 36
                                    },
                                    {
                                        "color": "#faaf3b"
                                    },
                                    {
                                        "lightness": 40
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.stroke",
                                "featureType": "all",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    },
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 16
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.icon",
                                "featureType": "all",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "administrative",
                                "stylers": [
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 20
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.stroke",
                                "featureType": "administrative",
                                "stylers": [
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 17
                                    },
                                    {
                                        "weight": 1.2
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "landscape",
                                "stylers": [
                                    {
                                        "color": "#18181a"
                                    },
                                    {
                                        "lightness": 20
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "poi",
                                "stylers": [
                                    {
                                        "color": "#faef3b"
                                    },
                                    {
                                        "lightness": 21
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "road.highway",
                                "stylers": [
                                    {
                                        "color": "#faaf3b"
                                    },
                                    {
                                        "lightness": 17
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.stroke",
                                "featureType": "road.highway",
                                "stylers": [
                                    {
                                        "color": "#f9a523"
                                    },
                                    {
                                        "lightness": 29
                                    },
                                    {
                                        "weight": 0.2
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "road.arterial",
                                "stylers": [
                                    {
                                        "color": "#faaf3b"
                                    },
                                    {
                                        "lightness": 18
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "road.local",
                                "stylers": [
                                    {
                                        "color": "#faaf3b"
                                    },
                                    {
                                        "lightness": 16
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "transit",
                                "stylers": [
                                    {
                                        "color": "#faef3b"
                                    },
                                    {
                                        "lightness": 19
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "color": "#18aeeb"
                                    },
                                    {
                                        "lightness": 17
                                    }
                                ]
                            }
                        ],
                        "locations": [
                            {
                                "address": "500 Terry Francois Street, 6th Floor. San Francisco, CA 94158",
                                "title": "Wix Office",
                                "latitude": 37.77065,
                                "longitude": -122.387301,
                                "description": "",
                                "link": null,
                                "linkTitle": "",
                                "type": "GeoMapLocation",
                                "pinIcon": util.media.getMediaUrl('designPanel/panelTypes/google_maps_pin_icon.png'),
                                "pinColor": ""
                            }
                        ],
                        "defaultLocation": 0
                    },
                    "props": {
                        "type": "GoogleMapProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "showZoom": true,
                        "showPosition": true,
                        "showStreetView": true,
                        "showMapType": true,
                        mapDragging,
                        "mapType": "ROADMAP",
                        "language": languageCode
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "id": "gm1",
                        "metaData": {
                            "isPreset": true,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {},
                            "propertiesSource": {}
                        },
                        "componentClassName": "",
                        "pageId": "",
                        "compId": "",
                        "styleType": "system",
                        "skin": "wysiwyg.viewer.skins.GoogleMapSkin"
                    },
                    "id": "comp-inoks70q"
                },
                "preset": {
                    "rect": {
                        "width": 324,
                        "height": 140,
                        "x": 0,
                        "y": 1540
                    },
                    "label": "add_preset_Maps_Strips_12",
                    "tags": null
                }
            },
            {
                "id": "Contact_Google_Maps_Strip_13",
                "structure": {
                    "type": "Component",
                    "layout": {
                        "width": 608,
                        "height": 350,
                        "x": -462,
                        "y": 6800,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false,
                        "docked": {
                            "left": {
                                "px": 0.5,
                                "vw": 0
                            },
                            "right": {
                                "px": 0,
                                "vw": 0
                            }
                        }
                    },
                    "componentType": "wysiwyg.viewer.components.GoogleMap",
                    "data": {
                        "type": "GeoMap",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "mapStyle": [
                            {
                                "elementType": "geometry",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "color": "#333739"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "landscape",
                                "stylers": [
                                    {
                                        "color": "#2ecc71"
                                    }
                                ]
                            },
                            {
                                "featureType": "poi",
                                "stylers": [
                                    {
                                        "color": "#2ecc71"
                                    },
                                    {
                                        "lightness": -7
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "road.highway",
                                "stylers": [
                                    {
                                        "color": "#2ecc71"
                                    },
                                    {
                                        "lightness": -28
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "road.arterial",
                                "stylers": [
                                    {
                                        "color": "#2ecc71"
                                    },
                                    {
                                        "visibility": "on"
                                    },
                                    {
                                        "lightness": -15
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "road.local",
                                "stylers": [
                                    {
                                        "color": "#2ecc71"
                                    },
                                    {
                                        "lightness": -18
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.fill",
                                "stylers": [
                                    {
                                        "color": "#ffffff"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.stroke",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "transit",
                                "stylers": [
                                    {
                                        "color": "#2ecc71"
                                    },
                                    {
                                        "lightness": -34
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "administrative",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    },
                                    {
                                        "color": "#333739"
                                    },
                                    {
                                        "weight": 0.8
                                    }
                                ]
                            },
                            {
                                "featureType": "poi.park",
                                "stylers": [
                                    {
                                        "color": "#2ecc71"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.stroke",
                                "featureType": "road",
                                "stylers": [
                                    {
                                        "color": "#333739"
                                    },
                                    {
                                        "weight": 0.3
                                    },
                                    {
                                        "lightness": 10
                                    }
                                ]
                            }
                        ],
                        "locations": [
                            {
                                "address": "500 Terry Francois Street, 6th Floor. San Francisco, CA 94158",
                                "title": "Wix Office",
                                "latitude": 37.77065,
                                "longitude": -122.387301,
                                "description": "",
                                "link": null,
                                "linkTitle": "",
                                "type": "GeoMapLocation",
                                "pinIcon": util.media.getMediaUrl('designPanel/panelTypes/google_maps_pin_icon.png'),
                                "pinColor": ""
                            }
                        ],
                        "defaultLocation": 0
                    },
                    "props": {
                        "type": "GoogleMapProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "showZoom": true,
                        "showPosition": true,
                        "showStreetView": true,
                        "showMapType": true,
                        mapDragging,
                        "mapType": "ROADMAP",
                        "language": languageCode
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "id": "gm1",
                        "metaData": {
                            "isPreset": true,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {},
                            "propertiesSource": {}
                        },
                        "componentClassName": "",
                        "pageId": "",
                        "compId": "",
                        "styleType": "system",
                        "skin": "wysiwyg.viewer.skins.GoogleMapSkin"
                    },
                    "id": "comp-inokse9z"
                },
                "preset": {
                    "rect": {
                        "width": 324,
                        "height": 140,
                        "x": 0,
                        "y": 1680
                    },
                    "label": "add_preset_Maps_Strips_13",
                    "tags": null
                }
            },
            {
                "id": "Contact_Google_Maps_Strip_14",
                "structure": {
                    "type": "Component",
                    "layout": {
                        "width": 608,
                        "height": 350,
                        "x": -462,
                        "y": 7360,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false,
                        "docked": {
                            "left": {
                                "px": 0.5,
                                "vw": 0
                            },
                            "right": {
                                "px": 0,
                                "vw": 0
                            }
                        }
                    },
                    "componentType": "wysiwyg.viewer.components.GoogleMap",
                    "data": {
                        "type": "GeoMap",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "mapStyle": [
                            {
                                "elementType": "labels.text.fill",
                                "featureType": "all",
                                "stylers": [
                                    {
                                        "saturation": 36
                                    },
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 40
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.stroke",
                                "featureType": "all",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    },
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 16
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.icon",
                                "featureType": "all",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "administrative",
                                "stylers": [
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 20
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.stroke",
                                "featureType": "administrative",
                                "stylers": [
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 17
                                    },
                                    {
                                        "weight": 1.2
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "landscape",
                                "stylers": [
                                    {
                                        "color": "#333333"
                                    },
                                    {
                                        "lightness": 20
                                    },
                                    {
                                        "visibility": "on"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "landscape",
                                "stylers": [
                                    {
                                        "gamma": 0.75
                                    },
                                    {
                                        "weight": 0.01
                                    },
                                    {
                                        "visibility": "on"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "landscape.man_made",
                                "stylers": [
                                    {
                                        "color": "#404040"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "poi",
                                "stylers": [
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 21
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text",
                                "featureType": "road",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.fill",
                                "featureType": "road",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    },
                                    {
                                        "color": "#ffffff"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "road.highway",
                                "stylers": [
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 17
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.stroke",
                                "featureType": "road.highway",
                                "stylers": [
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 29
                                    },
                                    {
                                        "weight": 0.2
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "road.arterial",
                                "stylers": [
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 18
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "road.local",
                                "stylers": [
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 16
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "transit",
                                "stylers": [
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 19
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "color": "#ffffff"
                                    },
                                    {
                                        "lightness": 17
                                    }
                                ]
                            }
                        ],
                        "locations": [
                            {
                                "address": "500 Terry Francois Street, 6th Floor. San Francisco, CA 94158",
                                "title": "Wix Office",
                                "latitude": 37.77065,
                                "longitude": -122.387301,
                                "description": "",
                                "link": null,
                                "linkTitle": "",
                                "type": "GeoMapLocation",
                                "pinIcon": util.media.getMediaUrl('designPanel/panelTypes/google_maps_pin_icon.png'),
                                "pinColor": ""
                            }
                        ],
                        "defaultLocation": 0
                    },
                    "props": {
                        "type": "GoogleMapProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "showZoom": true,
                        "showPosition": true,
                        "showStreetView": true,
                        "showMapType": true,
                        mapDragging,
                        "mapType": "ROADMAP",
                        "language": languageCode
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "id": "gm1",
                        "metaData": {
                            "isPreset": true,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {},
                            "propertiesSource": {}
                        },
                        "componentClassName": "",
                        "pageId": "",
                        "compId": "",
                        "styleType": "system",
                        "skin": "wysiwyg.viewer.skins.GoogleMapSkin"
                    },
                    "id": "comp-inoksjwr"
                },
                "preset": {
                    "rect": {
                        "width": 324,
                        "height": 140,
                        "x": 0,
                        "y": 1820
                    },
                    "label": "add_preset_Maps_Strips_14",
                    "tags": null
                }
            },
            {
                "id": "Contact_Google_Maps_Strip_15",
                "structure": {
                    "type": "Component",
                    "layout": {
                        "width": 608,
                        "height": 350,
                        "x": -462,
                        "y": 7920,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false,
                        "docked": {
                            "left": {
                                "px": 0.5,
                                "vw": 0
                            },
                            "right": {
                                "px": 0,
                                "vw": 0
                            }
                        }
                    },
                    "componentType": "wysiwyg.viewer.components.GoogleMap",
                    "data": {
                        "type": "GeoMap",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "mapStyle": [
                            {
                                "elementType": "all",
                                "featureType": "all",
                                "stylers": [
                                    {
                                        "invert_lightness": true
                                    },
                                    {
                                        "saturation": 0
                                    },
                                    {
                                        "lightness": 33
                                    },
                                    {
                                        "gamma": 0.5
                                    },
                                    {
                                        "hue": "#ffcc00"
                                    },
                                    {
                                        "weight": 1.51
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text",
                                "featureType": "administrative",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.icon",
                                "featureType": "administrative",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text",
                                "featureType": "administrative.country",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text",
                                "featureType": "administrative.province",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text",
                                "featureType": "administrative.locality",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text",
                                "featureType": "administrative.neighborhood",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text",
                                "featureType": "administrative.land_parcel",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text",
                                "featureType": "landscape",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.icon",
                                "featureType": "landscape",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text",
                                "featureType": "landscape.man_made",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text",
                                "featureType": "landscape.natural",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.icon",
                                "featureType": "landscape.natural",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text",
                                "featureType": "landscape.natural.landcover",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text",
                                "featureType": "landscape.natural.terrain",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text",
                                "featureType": "poi",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.icon",
                                "featureType": "poi",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text",
                                "featureType": "poi.attraction",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text",
                                "featureType": "poi.government",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "road",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text",
                                "featureType": "road",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.icon",
                                "featureType": "road",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "transit",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    },
                                    {
                                        "hue": "#ff0000"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text",
                                "featureType": "transit",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text",
                                "featureType": "transit.station",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text",
                                "featureType": "transit.station.rail",
                                "stylers": [
                                    {
                                        "gamma": 1
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.fill",
                                "featureType": "transit.station.rail",
                                "stylers": [
                                    {
                                        "hue": "#ff0000"
                                    },
                                    {
                                        "lightness": 42
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.icon",
                                "featureType": "transit.station.rail",
                                "stylers": [
                                    {
                                        "hue": "#ff0000"
                                    },
                                    {
                                        "invert_lightness": true
                                    },
                                    {
                                        "lightness": -15
                                    },
                                    {
                                        "saturation": 31
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            }
                        ],
                        "locations": [
                            {
                                "address": "500 Terry Francois Street, 6th Floor. San Francisco, CA 94158",
                                "title": "Wix Office",
                                "latitude": 37.77065,
                                "longitude": -122.387301,
                                "description": "",
                                "link": null,
                                "linkTitle": "",
                                "type": "GeoMapLocation",
                                "pinIcon": util.media.getMediaUrl('designPanel/panelTypes/google_maps_pin_icon.png'),
                                "pinColor": ""
                            }
                        ],
                        "defaultLocation": 0
                    },
                    "props": {
                        "type": "GoogleMapProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "showZoom": true,
                        "showPosition": true,
                        "showStreetView": true,
                        "showMapType": true,
                        mapDragging,
                        "mapType": "ROADMAP",
                        "language": languageCode
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "id": "gm1",
                        "metaData": {
                            "isPreset": true,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {},
                            "propertiesSource": {}
                        },
                        "componentClassName": "",
                        "pageId": "",
                        "compId": "",
                        "styleType": "system",
                        "skin": "wysiwyg.viewer.skins.GoogleMapSkin"
                    },
                    "id": "comp-inokzbe8"
                },
                "preset": {
                    "rect": {
                        "width": 324,
                        "height": 140,
                        "x": 0,
                        "y": 1960
                    },
                    "label": "add_preset_Maps_Strips_15",
                    "tags": null
                }
            },
            {
                "id": "Contact_Google_Maps_Strip_16",
                "structure": {
                    "type": "Component",
                    "layout": {
                        "width": 608,
                        "height": 350,
                        "x": -462,
                        "y": 8480,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false,
                        "docked": {
                            "left": {
                                "px": 0.5,
                                "vw": 0
                            },
                            "right": {
                                "px": 0,
                                "vw": 0
                            }
                        }
                    },
                    "componentType": "wysiwyg.viewer.components.GoogleMap",
                    "data": {
                        "type": "GeoMap",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "mapStyle": [
                            {
                                "elementType": "labels",
                                "featureType": "all",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.fill",
                                "featureType": "all",
                                "stylers": [
                                    {
                                        "saturation": 36
                                    },
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 40
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.stroke",
                                "featureType": "all",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    },
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 16
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.icon",
                                "featureType": "all",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "administrative",
                                "stylers": [
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 20
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.stroke",
                                "featureType": "administrative",
                                "stylers": [
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 17
                                    },
                                    {
                                        "weight": 1.2
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.fill",
                                "featureType": "administrative",
                                "stylers": [
                                    {
                                        "color": "#ff0000"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.fill",
                                "featureType": "administrative.country",
                                "stylers": [
                                    {
                                        "color": "#f5f5f5"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.fill",
                                "featureType": "administrative.locality",
                                "stylers": [
                                    {
                                        "color": "#c4c4c4"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.fill",
                                "featureType": "administrative.neighborhood",
                                "stylers": [
                                    {
                                        "color": "#db2222"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "landscape",
                                "stylers": [
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 20
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "poi",
                                "stylers": [
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 21
                                    },
                                    {
                                        "visibility": "on"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "poi.business",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.stroke",
                                "featureType": "road",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "road.highway",
                                "stylers": [
                                    {
                                        "color": "#ff0000"
                                    },
                                    {
                                        "lightness": 0
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.stroke",
                                "featureType": "road.highway",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.fill",
                                "featureType": "road.highway",
                                "stylers": [
                                    {
                                        "color": "#ffffff"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.stroke",
                                "featureType": "road.highway",
                                "stylers": [
                                    {
                                        "color": "#ff0000"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "road.arterial",
                                "stylers": [
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 18
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "road.arterial",
                                "stylers": [
                                    {
                                        "color": "#575757"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.fill",
                                "featureType": "road.arterial",
                                "stylers": [
                                    {
                                        "color": "#ffffff"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.stroke",
                                "featureType": "road.arterial",
                                "stylers": [
                                    {
                                        "color": "#2c2c2c"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "road.local",
                                "stylers": [
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 16
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.fill",
                                "featureType": "road.local",
                                "stylers": [
                                    {
                                        "color": "#999999"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "transit",
                                "stylers": [
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 19
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 17
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "color": "#111111"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.fill",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "color": "#aeaeae"
                                    }
                                ]
                            }
                        ],
                        "locations": [
                            {
                                "address": "500 Terry Francois Street, 6th Floor. San Francisco, CA 94158",
                                "title": "Wix Office",
                                "latitude": 37.77065,
                                "longitude": -122.387301,
                                "description": "",
                                "link": null,
                                "linkTitle": "",
                                "type": "GeoMapLocation",
                                "pinIcon": util.media.getMediaUrl('designPanel/panelTypes/google_maps_pin_icon.png'),
                                "pinColor": ""
                            }
                        ],
                        "defaultLocation": 0
                    },
                    "props": {
                        "type": "GoogleMapProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "showZoom": true,
                        "showPosition": true,
                        "showStreetView": true,
                        "showMapType": true,
                        mapDragging,
                        "mapType": "ROADMAP",
                        "language": languageCode
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "id": "gm1",
                        "metaData": {
                            "isPreset": true,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {},
                            "propertiesSource": {}
                        },
                        "componentClassName": "",
                        "pageId": "",
                        "compId": "",
                        "styleType": "system",
                        "skin": "wysiwyg.viewer.skins.GoogleMapSkin"
                    },
                    "id": "comp-inokzhgq"
                },
                "preset": {
                    "rect": {
                        "width": 324,
                        "height": 140,
                        "x": 0,
                        "y": 2100
                    },
                    "label": "add_preset_Maps_Strips_16",
                    "tags": null
                }
            },
            {
                "id": "Contact_Google_Maps_Strip_17",
                "structure": {
                    "type": "Component",
                    "layout": {
                        "width": 608,
                        "height": 350,
                        "x": -462,
                        "y": 9040,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false,
                        "docked": {
                            "left": {
                                "px": 0.5,
                                "vw": 0
                            },
                            "right": {
                                "px": 0,
                                "vw": 0
                            }
                        }
                    },
                    "componentType": "wysiwyg.viewer.components.GoogleMap",
                    "data": {
                        "type": "GeoMap",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "mapStyle": [
                            {
                                "elementType": "labels.text.fill",
                                "featureType": "all",
                                "stylers": [
                                    {
                                        "saturation": 36
                                    },
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 40
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.stroke",
                                "featureType": "all",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    },
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 16
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.icon",
                                "featureType": "all",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "administrative",
                                "stylers": [
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 20
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.stroke",
                                "featureType": "administrative",
                                "stylers": [
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 17
                                    },
                                    {
                                        "weight": 1.2
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "landscape",
                                "stylers": [
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 20
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "poi",
                                "stylers": [
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 21
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "road.highway",
                                "stylers": [
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 17
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.stroke",
                                "featureType": "road.highway",
                                "stylers": [
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 29
                                    },
                                    {
                                        "weight": 0.2
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "road.highway.controlled_access",
                                "stylers": [
                                    {
                                        "color": "#99c018"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "road.arterial",
                                "stylers": [
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 18
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "road.local",
                                "stylers": [
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 16
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "transit",
                                "stylers": [
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 19
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 17
                                    }
                                ]
                            }
                        ],
                        "locations": [
                            {
                                "address": "500 Terry Francois Street, 6th Floor. San Francisco, CA 94158",
                                "title": "Wix Office",
                                "latitude": 37.77065,
                                "longitude": -122.387301,
                                "description": "",
                                "link": null,
                                "linkTitle": "",
                                "type": "GeoMapLocation",
                                "pinIcon": util.media.getMediaUrl('designPanel/panelTypes/google_maps_pin_icon.png'),
                                "pinColor": ""
                            }
                        ],
                        "defaultLocation": 0
                    },
                    "props": {
                        "type": "GoogleMapProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "showZoom": true,
                        "showPosition": true,
                        "showStreetView": true,
                        "showMapType": true,
                        mapDragging,
                        "mapType": "ROADMAP",
                        "language": languageCode
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "id": "gm1",
                        "metaData": {
                            "isPreset": true,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {},
                            "propertiesSource": {}
                        },
                        "componentClassName": "",
                        "pageId": "",
                        "compId": "",
                        "styleType": "system",
                        "skin": "wysiwyg.viewer.skins.GoogleMapSkin"
                    },
                    "id": "comp-inokzn0e"
                },
                "preset": {
                    "rect": {
                        "width": 324,
                        "height": 140,
                        "x": 0,
                        "y": 2240
                    },
                    "label": "add_preset_Maps_Strips_17",
                    "tags": null
                }
            },
            {
                "id": "Contact_Google_Maps_Strip_18",
                "structure": {
                    "type": "Component",
                    "layout": {
                        "width": 608,
                        "height": 350,
                        "x": -462,
                        "y": 9600,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false,
                        "docked": {
                            "left": {
                                "px": 0.5,
                                "vw": 0
                            },
                            "right": {
                                "px": 0,
                                "vw": 0
                            }
                        }
                    },
                    "componentType": "wysiwyg.viewer.components.GoogleMap",
                    "data": {
                        "type": "GeoMap",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "mapStyle": [
                            {
                                "elementType": "labels.text.fill",
                                "featureType": "all",
                                "stylers": [
                                    {
                                        "color": "#15e0c5"
                                    },
                                    {
                                        "lightness": 0
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.stroke",
                                "featureType": "all",
                                "stylers": [
                                    {
                                        "color": "#000000"
                                    },
                                    {
                                        "lightness": 13
                                    },
                                    {
                                        "weight": 0.01
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "administrative",
                                "stylers": [
                                    {
                                        "color": "#000000"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.stroke",
                                "featureType": "administrative",
                                "stylers": [
                                    {
                                        "color": "#144b53"
                                    },
                                    {
                                        "lightness": 14
                                    },
                                    {
                                        "weight": 1.4
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "administrative.locality",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.icon",
                                "featureType": "administrative.locality",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "landscape",
                                "stylers": [
                                    {
                                        "color": "#0f3944"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "poi",
                                "stylers": [
                                    {
                                        "color": "#0c4152"
                                    },
                                    {
                                        "lightness": 5
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "road.highway",
                                "stylers": [
                                    {
                                        "color": "#000000"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.stroke",
                                "featureType": "road.highway",
                                "stylers": [
                                    {
                                        "color": "#0b434f"
                                    },
                                    {
                                        "lightness": 25
                                    }
                                ]
                            },
                            {
                                "elementType": "labels",
                                "featureType": "road.highway",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.fill",
                                "featureType": "road.arterial",
                                "stylers": [
                                    {
                                        "color": "#000000"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.stroke",
                                "featureType": "road.arterial",
                                "stylers": [
                                    {
                                        "color": "#0b3d51"
                                    },
                                    {
                                        "lightness": 16
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "road.local",
                                "stylers": [
                                    {
                                        "color": "#000000"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "transit",
                                "stylers": [
                                    {
                                        "color": "#146474"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "color": "#0f3944"
                                    },
                                    {
                                        "lightness": -38
                                    }
                                ]
                            }
                        ],
                        "locations": [
                            {
                                "address": "500 Terry Francois Street, 6th Floor. San Francisco, CA 94158",
                                "title": "Wix Office",
                                "latitude": 37.77065,
                                "longitude": -122.387301,
                                "description": "",
                                "link": null,
                                "linkTitle": "",
                                "type": "GeoMapLocation",
                                "pinIcon": util.media.getMediaUrl('designPanel/panelTypes/google_maps_pin_icon.png'),
                                "pinColor": ""
                            }
                        ],
                        "defaultLocation": 0
                    },
                    "props": {
                        "type": "GoogleMapProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "showZoom": true,
                        "showPosition": true,
                        "showStreetView": true,
                        "showMapType": true,
                        mapDragging,
                        "mapType": "ROADMAP",
                        "language": languageCode
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "id": "gm1",
                        "metaData": {
                            "isPreset": true,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {},
                            "propertiesSource": {}
                        },
                        "componentClassName": "",
                        "pageId": "",
                        "compId": "",
                        "styleType": "system",
                        "skin": "wysiwyg.viewer.skins.GoogleMapSkin"
                    },
                    "id": "comp-inol087a"
                },
                "preset": {
                    "rect": {
                        "width": 324,
                        "height": 140,
                        "x": 0,
                        "y": 2380
                    },
                    "label": "add_preset_Maps_Strips_18",
                    "tags": null
                }
            },
            {
                "id": "Contact_Google_Maps_Strip_19",
                "structure": {
                    "type": "Component",
                    "layout": {
                        "width": 608,
                        "height": 350,
                        "x": -462,
                        "y": 10160,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false,
                        "docked": {
                            "left": {
                                "px": 0.5,
                                "vw": 0
                            },
                            "right": {
                                "px": 0,
                                "vw": 0
                            }
                        }
                    },
                    "componentType": "wysiwyg.viewer.components.GoogleMap",
                    "data": {
                        "type": "GeoMap",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "mapStyle": [
                            {
                                "elementType": "geometry",
                                "featureType": "all",
                                "stylers": [
                                    {
                                        "color": "#021747"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.stroke",
                                "featureType": "all",
                                "stylers": [
                                    {
                                        "lightness": -9
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text",
                                "featureType": "all",
                                "stylers": [
                                    {
                                        "lightness": 100
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.fill",
                                "featureType": "all",
                                "stylers": [
                                    {
                                        "gamma": 0.01
                                    },
                                    {
                                        "lightness": 20
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.stroke",
                                "featureType": "all",
                                "stylers": [
                                    {
                                        "saturation": -31
                                    },
                                    {
                                        "lightness": -33
                                    },
                                    {
                                        "weight": 2
                                    },
                                    {
                                        "gamma": 0.8
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.icon",
                                "featureType": "all",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "landscape",
                                "stylers": [
                                    {
                                        "lightness": 30
                                    },
                                    {
                                        "saturation": 30
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "poi",
                                "stylers": [
                                    {
                                        "saturation": 20
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "poi.park",
                                "stylers": [
                                    {
                                        "lightness": 20
                                    },
                                    {
                                        "saturation": -20
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry",
                                "featureType": "road",
                                "stylers": [
                                    {
                                        "lightness": 10
                                    },
                                    {
                                        "saturation": -30
                                    }
                                ]
                            },
                            {
                                "elementType": "geometry.stroke",
                                "featureType": "road",
                                "stylers": [
                                    {
                                        "saturation": 25
                                    },
                                    {
                                        "lightness": 25
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "lightness": -20
                                    }
                                ]
                            }
                        ],
                        "locations": [
                            {
                                "address": "500 Terry Francois Street, 6th Floor. San Francisco, CA 94158",
                                "title": "Wix Office",
                                "latitude": 37.77065,
                                "longitude": -122.387301,
                                "description": "",
                                "link": null,
                                "linkTitle": "",
                                "type": "GeoMapLocation",
                                "pinIcon": util.media.getMediaUrl('designPanel/panelTypes/google_maps_pin_icon.png'),
                                "pinColor": ""
                            }
                        ],
                        "defaultLocation": 0
                    },
                    "props": {
                        "type": "GoogleMapProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "showZoom": true,
                        "showPosition": true,
                        "showStreetView": true,
                        "showMapType": true,
                        mapDragging,
                        "mapType": "ROADMAP",
                        "language": languageCode
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "id": "gm1",
                        "metaData": {
                            "isPreset": true,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {},
                            "propertiesSource": {}
                        },
                        "componentClassName": "",
                        "pageId": "",
                        "compId": "",
                        "styleType": "system",
                        "skin": "wysiwyg.viewer.skins.GoogleMapSkin"
                    },
                    "id": "comp-inol0d8u"
                },
                "preset": {
                    "rect": {
                        "width": 324,
                        "height": 140,
                        "x": 0,
                        "y": 2520
                    },
                    "label": "add_preset_Maps_Strips_19",
                    "tags": null
                }
            },
            {
                "id": "Contact_Google_Maps_Strip_20",
                "structure": {
                    "type": "Component",
                    "layout": {
                        "width": 608,
                        "height": 350,
                        "x": -462,
                        "y": 10720,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false,
                        "docked": {
                            "left": {
                                "px": 0.5,
                                "vw": 0
                            },
                            "right": {
                                "px": 0,
                                "vw": 0
                            }
                        }
                    },
                    "componentType": "wysiwyg.viewer.components.GoogleMap",
                    "data": {
                        "type": "GeoMap",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "mapStyle": [
                            {
                                "elementType": "labels.text.fill",
                                "featureType": "administrative",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    },
                                    {
                                        "color": "#b1b1b3"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.stroke",
                                "featureType": "administrative",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    },
                                    {
                                        "color": "#3c3d41"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "landscape",
                                "stylers": [
                                    {
                                        "color": "#525459"
                                    },
                                    {
                                        "visibility": "on"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "poi",
                                "stylers": [
                                    {
                                        "color": "#f14e4e"
                                    },
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.stroke",
                                "featureType": "poi",
                                "stylers": [
                                    {
                                        "color": "#1c262d"
                                    },
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.icon",
                                "featureType": "poi",
                                "stylers": [
                                    {
                                        "color": "#f14e4e"
                                    },
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "poi.park",
                                "stylers": [
                                    {
                                        "color": "#3c3d41"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "road",
                                "stylers": [
                                    {
                                        "color": "#3c3d41"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.fill",
                                "featureType": "road",
                                "stylers": [
                                    {
                                        "color": "#eeeeee"
                                    },
                                    {
                                        "visibility": "on"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "transit",
                                "stylers": [
                                    {
                                        "color": "#3c3d41"
                                    },
                                    {
                                        "visibility": "simplified"
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.fill",
                                "featureType": "transit",
                                "stylers": [
                                    {
                                        "color": "#f14e4e"
                                    },
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "elementType": "all",
                                "featureType": "water",
                                "stylers": [
                                    {
                                        "color": "#3c3d41"
                                    }
                                ]
                            }
                        ],
                        "locations": [
                            {
                                "address": "500 Terry Francois Street, 6th Floor. San Francisco, CA 94158",
                                "title": "Wix Office",
                                "latitude": 37.77065,
                                "longitude": -122.387301,
                                "description": "",
                                "link": null,
                                "linkTitle": "",
                                "type": "GeoMapLocation",
                                "pinIcon": util.media.getMediaUrl('designPanel/panelTypes/google_maps_pin_icon.png'),
                                "pinColor": ""
                            }
                        ],
                        "defaultLocation": 0
                    },
                    "props": {
                        "type": "GoogleMapProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "showZoom": true,
                        "showPosition": true,
                        "showStreetView": true,
                        "showMapType": true,
                        mapDragging,
                        "mapType": "ROADMAP",
                        "language": languageCode
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "id": "gm1",
                        "metaData": {
                            "isPreset": true,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {},
                            "propertiesSource": {}
                        },
                        "componentClassName": "",
                        "pageId": "",
                        "compId": "",
                        "styleType": "system",
                        "skin": "wysiwyg.viewer.skins.GoogleMapSkin"
                    },
                    "id": "comp-inol0j5o"
                },
                "preset": {
                    "rect": {
                        "width": 324,
                        "height": 140,
                        "x": 0,
                        "y": 2660
                    },
                    "label": "add_preset_Maps_Strips_20",
                    "tags": null
                }
            }
        ],
        "compTypes": ["wysiwyg.viewer.components.GoogleMap"]
    },
    "help": {"hide": false, "text": "add_section_info_text_googlemap_strip"}
}
