// @ts-nocheck
import { array } from '@/util';
import wixFormsConstants from '../../contact/wixForms/constants';
import * as wixFormsUtils from '../../contact/wixForms/utils';

const addForm = (structure) => {
  const form = wixFormsUtils.getFormWidget({
    layout: {
      width: 980,
      height: 249,
      x: 0,
      y: 70,
      scale: 1,
      rotationInDegrees: 0,
      anchors: [
        {
          distance: 78,
          originalValue: 319,
          type: 'BOTTOM_PARENT',
          locked: false,
          targetComponent: 'comp-im0iv0on',
        },
      ],
      fixedPosition: false,
    },
    id: 'comp-isrexasi1',
    mobileLayout: {
      width: 300,
      height: 213,
      x: 21,
      y: 29,
      scale: 1,
      rotationInDegrees: 0,
      fixedPosition: false,
    },
    controllerType: wixFormsConstants.CONTROLLER_TYPES.GET_SUBSCRIBERS,
  });

  // add form to
  // components: [
  //     component_0: {
  //         components: [
  //             form,
  //             ...
  //         ]
  //     },
  // ]
  return {
    ...structure,
    components: array.immutableSplice(structure.components, 0, 1, {
      ...structure.components[0],
      components: array.immutableSplice(
        structure.components[0].components,
        0,
        0,
        form,
      ),
    }),
  };
};

export default (onDrop, onClick) => (item) => {
  onDrop = onDrop(wixFormsConstants.FormPreset.LIGHTBOX_SUBSCRIBE02);
  onClick = onClick(wixFormsConstants.FormPreset.LIGHTBOX_SUBSCRIBE02);

  return {
    ...item,
    onDrop,
    onClick,
    structure: addForm(item.structure),
  };
};
