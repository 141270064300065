import _ from 'lodash';
import constants from '@/constants';
import * as stateManagement from '@/stateManagement';
import type { EditorAPI } from '@/editorAPI';
import type { CompRef } from 'types/documentServices';

const PAGE_COMP_TYPE = 'mobile.core.components.Page';

export function createSelectionPopupApi({
  editorAPI,
}: {
  editorAPI: EditorAPI;
}) {
  const inlinePopupActions = stateManagement.inlinePopup.actions;
  const store = editorAPI.store;

  function closeInlinePopup(inlinePopupRef: CompRef) {
    store.dispatch(inlinePopupActions.close(inlinePopupRef));
  }

  function getSelectedPopup(selectedComps: CompRef[]): CompRef {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/find
    return _.find(
      selectedComps,
      (comp) =>
        editorAPI.components.getType(comp) ===
        constants.COMP_TYPES.POPUP_CONTAINER,
    );
  }

  function isPopupPageSelected(selectedComps: CompRef[]): boolean {
    return (
      Boolean(editorAPI.dsRead.pages.popupPages.getCurrentPopupId()) &&
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/size
      _.size(selectedComps) === 1 &&
      editorAPI.components.getType(_.head(selectedComps)) === PAGE_COMP_TYPE
    );
  }

  return {
    closeInlinePopup,
    getSelectedPopup,
    isPopupPageSelected,
  };
}

export type SelectionPopupApi = ReturnType<typeof createSelectionPopupApi>;
