:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._wrapper_m5ees_7 {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  max-height: 100%;
  -webkit-clip-path: inset(0 -240px 0 0);
          clip-path: inset(0 -240px 0 0);
  position: relative;
  z-index: 1457; }
  .intro-fixparent ._wrapper_m5ees_7 {
    -webkit-clip-path: initial;
            clip-path: initial; }

._list_m5ees_18 {
  position: relative;
  display: block;
  flex-grow: 1;
  padding: 18px 0 12px;
  box-sizing: border-box; }

._item_m5ees_25 {
  text-align: center;
  padding: 0 9px;
  min-height: 45px;
  max-height: 54px;
  display: flex;
  justify-content: center; }
  ._item_m5ees_25._primary_m5ees_32 {
    min-height: 51px;
    max-height: 60px; }
  ._item_m5ees_25:first-of-type:nth-last-of-type(2),
  ._item_m5ees_25:first-of-type:nth-last-of-type(2) ~ ._item_m5ees_25 {
    height: calc(100% / 2); }
  ._item_m5ees_25:first-of-type:nth-last-of-type(3),
  ._item_m5ees_25:first-of-type:nth-last-of-type(3) ~ ._item_m5ees_25 {
    height: calc(100% / 3); }
  ._item_m5ees_25:first-of-type:nth-last-of-type(4),
  ._item_m5ees_25:first-of-type:nth-last-of-type(4) ~ ._item_m5ees_25 {
    height: calc(100% / 4); }
  ._item_m5ees_25:first-of-type:nth-last-of-type(5),
  ._item_m5ees_25:first-of-type:nth-last-of-type(5) ~ ._item_m5ees_25 {
    height: calc(100% / 5); }
  ._item_m5ees_25:first-of-type:nth-last-of-type(6),
  ._item_m5ees_25:first-of-type:nth-last-of-type(6) ~ ._item_m5ees_25 {
    height: calc(100% / 6); }
  ._item_m5ees_25:first-of-type:nth-last-of-type(7),
  ._item_m5ees_25:first-of-type:nth-last-of-type(7) ~ ._item_m5ees_25 {
    height: calc(100% / 7); }
  ._item_m5ees_25:first-of-type:nth-last-of-type(8),
  ._item_m5ees_25:first-of-type:nth-last-of-type(8) ~ ._item_m5ees_25 {
    height: calc(100% / 8); }
  ._item_m5ees_25:first-of-type:nth-last-of-type(9),
  ._item_m5ees_25:first-of-type:nth-last-of-type(9) ~ ._item_m5ees_25 {
    height: calc(100% / 9); }
  ._item_m5ees_25:first-of-type:nth-last-of-type(10),
  ._item_m5ees_25:first-of-type:nth-last-of-type(10) ~ ._item_m5ees_25 {
    height: calc(100% / 10); }
  ._item_m5ees_25:first-of-type:nth-last-of-type(11),
  ._item_m5ees_25:first-of-type:nth-last-of-type(11) ~ ._item_m5ees_25 {
    height: calc(100% / 11); }
  ._item_m5ees_25:first-of-type:nth-last-of-type(12),
  ._item_m5ees_25:first-of-type:nth-last-of-type(12) ~ ._item_m5ees_25 {
    height: calc(100% / 12); }
  ._item_m5ees_25:first-of-type:nth-last-of-type(13),
  ._item_m5ees_25:first-of-type:nth-last-of-type(13) ~ ._item_m5ees_25 {
    height: calc(100% / 13); }
  ._item_m5ees_25:first-of-type:nth-last-of-type(14),
  ._item_m5ees_25:first-of-type:nth-last-of-type(14) ~ ._item_m5ees_25 {
    height: calc(100% / 14); }
  ._item_m5ees_25:first-of-type:nth-last-of-type(15),
  ._item_m5ees_25:first-of-type:nth-last-of-type(15) ~ ._item_m5ees_25 {
    height: calc(100% / 15); }
  ._item_m5ees_25:first-of-type:nth-last-of-type(16),
  ._item_m5ees_25:first-of-type:nth-last-of-type(16) ~ ._item_m5ees_25 {
    height: calc(100% / 16); }
  ._item_m5ees_25:first-of-type:nth-last-of-type(17),
  ._item_m5ees_25:first-of-type:nth-last-of-type(17) ~ ._item_m5ees_25 {
    height: calc(100% / 17); }
  ._item_m5ees_25:first-of-type:nth-last-of-type(18),
  ._item_m5ees_25:first-of-type:nth-last-of-type(18) ~ ._item_m5ees_25 {
    height: calc(100% / 18); }
  ._item_m5ees_25:first-of-type:nth-last-of-type(19),
  ._item_m5ees_25:first-of-type:nth-last-of-type(19) ~ ._item_m5ees_25 {
    height: calc(100% / 19); }
  ._item_m5ees_25:first-of-type:nth-last-of-type(20),
  ._item_m5ees_25:first-of-type:nth-last-of-type(20) ~ ._item_m5ees_25 {
    height: calc(100% / 20); }
