import type { EntryPoint } from '@/apilib';
import { EditorAPIKey, BiApiKey } from '@/apis';
import { createClipboardApi } from './clipboardWrapper';
import { ClipboardApiKey } from './publicApiKey';

export const ClipboardEntrypoint: EntryPoint = {
  name: 'ClipboardApi',
  declareAPIs: () => [ClipboardApiKey],
  getDependencyAPIs: () => [EditorAPIKey, BiApiKey],
  attach(shell) {
    shell.contributeAPI(ClipboardApiKey, () => createClipboardApi(shell));
  },
};
