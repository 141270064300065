import React from 'react';
import { ErrorReporter } from '@wix/editor-error-reporter';
import _ from 'lodash';
import * as util from '@/util';
import { translate } from '@/i18n';
import * as stateManagement from '@/stateManagement';
import PropTypes from 'prop-types';
import tpaConstants from '../../constants/constants';
import * as tpaAddAppService from '../../services/tpaAddAppService';
import * as coreBi from '@/coreBi';

import * as panels from '@/panels';
import ListItem from './components/listItem';
import {
  Composites,
  CustomScroll,
  Divider,
  Preloader,
  Search,
  SortByDragList,
  Button,
  TextLabel,
} from '@wix/wix-base-ui';
import * as symbols from '@wix/santa-editor-symbols';
import { EmptyState } from './components/emptyState/EmptyState';
import { WorkspaceIndicator } from './components/workspaceIndicator/WorkspaceIndicator';
import { isResponsiveEditor } from '@wix/santa-editor-utils';
import {
  EditorType,
  InstallationOriginType,
  InstallInitiator,
} from '@wix/platform-editor-sdk';
import type { EditorAPI } from '@/editorAPI';
import type { DSRead } from 'types/documentServices';
import type { Dispatch } from 'types/redux';
import type { BIEventDefinition, BIEventFields } from 'types/documentServices';

const {
  getIsLoadingAppsError,
  getIsLoading,
  getAvailableApps,
  getInstalledBlocksApps,
  packagesView,
} = stateManagement.platform.selectors;
const { togglePackagesViewRoot } = stateManagement.platform.actions;

const biEvents = coreBi.events;

const APPS_LIST_SEARCH_PLACEHOLDER_TRANSLATION_KEY =
  'AppStudio_Import_App_Modal_Apps_List_Search_Placeholder';

interface AppDescriptor {
  isDraft: boolean;
  isAlpha: boolean;
  canImport: boolean;
  latestVersion: string;
  blocksVersion: string;
  href: string;
  dateCreated?: string;

  appDefinitionId: string;
  appDefinitionName: string;
}

interface AppImportPanelOwnProps {
  displayMode: util.appStudioUtils.DisplayMode;
  panelName: string;
  panelTitle: string;
  helpId: string;
  openAppVariant: boolean;
  noCreatedAppsIllustration: React.ReactNode;
  allAppsAddedIllustration: React.ReactNode;

  // Translation keys
  noCreatedAppsText: string;
  noCreatedAppsTitle: string;
  noCreatedAppsActionLabel: string;
  allAppsAddedText: string;
  allAppsAddedTitle: string;
  allAppsAddedActionLabel: string;

  allAppsAddedOnActionClick: () => void;
  onClickWorkspaceSwitcher: () => void;
}

type AppImportPanelStateProps = ReturnType<typeof mapStateToProps>;
type AppImportPanelDispatchProps = ReturnType<typeof mapDispatchToProps>;

type AppImportPanelProps = AppImportPanelStateProps &
  AppImportPanelDispatchProps &
  AppImportPanelOwnProps;

interface AppsListProps {
  apps: AppDescriptor[];
  openAppVariant: boolean;
  onImport: (app: AppDescriptor) => void;
  closePanel: () => void;
}

function appToDragListItem(app: AppDescriptor, onAction: () => void) {
  return {
    id: app.appDefinitionId,
    label: app.appDefinitionName,
    customAction: (
      <Button onClick={onAction} className="btn-sm primary">
        <a
          key="app-studio-edit-link"
          href={app.href}
          target="_blank"
          className="app-studio-edit-link"
        >
          {translate('AppStudio_OpenApp_Button')}
        </a>
      </Button>
    ),
  };
}

function AppsList(props: AppsListProps) {
  const renderOpenAppList = () => {
    const sortByDragListValue = props.apps.map((app) =>
      appToDragListItem(app, props.closePanel),
    );

    return (
      <SortByDragList
        draggable={false}
        value={sortByDragListValue}
        noBorderTop={true}
        disableEditing={true}
      />
    );
  };

  const renderImportAppList = () => {
    return props.apps.map((app) => (
      <div key={app.appDefinitionId}>
        <ListItem
          automationId="app-item"
          label={app.appDefinitionName}
          onImport={() => {
            props.onImport(app);
          }}
          onEdit={() => {
            props.closePanel();
          }}
          href={app.href}
          target="_blank"
          isDraft={app.isDraft}
          isAlpha={app.isAlpha}
          canImport={app.canImport}
          version={app.latestVersion}
        />
        <Divider long={true} />
      </div>
    ));
  };

  const renderList = props.openAppVariant
    ? renderOpenAppList
    : renderImportAppList;

  return (
    <div data-aid="apps-state" key="apps-state">
      {renderList()}
    </div>
  );
}

class AppImportPanel extends React.Component<AppImportPanelProps> {
  state = {
    searchTerm: '',
  };

  static propTypes: Record<string, PropTypes.Validator<any>>;
  static defaultProps: Partial<AppImportPanelOwnProps>;

  getPanelTitle = () =>
    this.props.panelTitle || translate('AppStudio_ImportDialog_Header');
  getNoCreatedAppsText = () =>
    this.props.noCreatedAppsText ||
    translate('AppStudio_Import_App_Modal_Empty_State_First_App_Text');
  getNoCreatedAppsTitle = () =>
    this.props.noCreatedAppsTitle ||
    translate('AppStudio_Import_App_Modal_Empty_State_First_App_Header');
  getNoCreatedAppsActionLabel = () =>
    this.props.noCreatedAppsActionLabel ||
    translate('AppStudio_Import_App_Modal_Empty_State_First_App_Button');
  getAllAppsAddedText = () =>
    this.props.allAppsAddedText ||
    translate('AppStudio_Import_App_Modal_Empty_State_Apps_Added_Text');
  getAllAppsAddedTitle = () =>
    this.props.allAppsAddedTitle ||
    translate('AppStudio_Import_App_Modal_Empty_State_Apps_Added_Header');
  getAllAppsAddedActionLabel = () =>
    this.props.allAppsAddedActionLabel ||
    translate('AppStudio_Import_App_Modal_Empty_State_Apps_Added_Button');
  onImport = (app: AppDescriptor) => {
    this.props.setIsImportingApp(true);
    this.props.sendBi(biEvents.platform.import_app_click, {
      app_id: app.appDefinitionId,
      appName: app.appDefinitionName,
    });
    this.props
      .onImport(app)
      .then(() => {
        if (!this.props.isPackagesViewExpanded) {
          this.props.togglePackagesView();
        }
      })
      .then(() => {
        this.props.setIsImportingApp(false);
      })
      .catch((data: any) => {
        this.props.setIsImportingApp(false);
        ErrorReporter.captureException(new Error('Import App has Failed'), {
          tags: { data, appImportPanel: true },
        });
        this.props.openErrorPanel();
      });
    this.props.closePanel();
  };

  constructor(props: any) {
    super(props);
    this.props.fetchAvailableApps();
    this.props.fetchAccounts();
  }

  render() {
    const { currentAccount, accounts, onClickWorkspaceSwitcher } = this.props;
    const filteredAppsList = this.props.apps.filter((app: AppDescriptor) =>
      app.appDefinitionName
        .toLowerCase()
        .includes(this.state.searchTerm.toLowerCase()),
    );

    return (
      <panels.frames.FocusPanelFrame
        panelName={this.props.panelName}
        onClose={this.props.closePanel}
        helpId={this.props.helpId}
        title={this.getPanelTitle()}
        width="474px"
        className="app-import-panel"
        automationId="app-import-panel"
      >
        {currentAccount && accounts.length > 1 ? (
          <WorkspaceIndicator
            accountName={currentAccount.accountName}
            onClick={onClickWorkspaceSwitcher}
          />
        ) : null}
        <div
          className="apps-list-search-wrapper"
          data-hook="apps-list-search-wrapper"
        >
          <Search
            value={this.state.searchTerm}
            options={[]}
            onClear={() => this.setState({ searchTerm: '' })}
            onItemClick={() => {}}
            onChange={(searchTerm: string) => this.setState({ searchTerm })}
            placeholder={APPS_LIST_SEARCH_PLACEHOLDER_TRANSLATION_KEY}
          />
        </div>
        <Divider long={true} />
        <CustomScroll>
          <div className="app-Import-panel-content">
            {this.props.isLoading && !this.props.hasApps ? (
              <Composites.Preloader
                automationId="loading-state"
                key="loading-state"
                height={246}
              >
                <Preloader className="small import-loader" />
                <TextLabel
                  value="AppStudio_ImportDialog_Loader_Text"
                  className="loading-text"
                />
              </Composites.Preloader>
            ) : null}
            {this.props.isNoCreatedApps ? (
              <EmptyState
                className="no-created-apps-state"
                title={this.getNoCreatedAppsTitle()}
                text={this.getNoCreatedAppsText()}
                buttonLabel={this.getNoCreatedAppsActionLabel()}
                onButtonClick={() =>
                  util.appStudioUtils.openBlocks({
                    mode: util.appStudioUtils.AppStudioMode.App,
                    isWixUser: this.props.isWixUser,
                    httpReferrer:
                      util.appStudioUtils.httpReferrerOptions.AppImportPanel,
                    isStudioAccount: false,
                  })
                }
                illustration={this.props.noCreatedAppsIllustration}
              />
            ) : null}
            {this.props.isEmptyState ? (
              <EmptyState
                className="empty-state"
                title={this.getAllAppsAddedTitle()}
                text={this.getAllAppsAddedText()}
                buttonLabel={this.getAllAppsAddedActionLabel()}
                onButtonClick={
                  this.props.allAppsAddedOnActionClick || this.props.closePanel
                }
                illustration={this.props.allAppsAddedIllustration}
              />
            ) : null}
            {this.props.hasApps ? (
              <AppsList
                apps={filteredAppsList}
                openAppVariant={this.props.openAppVariant}
                onImport={this.onImport}
                closePanel={this.props.closePanel}
              />
            ) : null}
            {this.props.isLoadingAppsError ? (
              <div
                data-aid="error-state"
                key="error-state"
                className="error-state"
              >
                <symbols.symbol name="error" />
                <TextLabel
                  value="AppStudio_ImportDialog_Error_Text"
                  enableEllipsis={false}
                  className="error-message"
                />
              </div>
            ) : null}
          </div>
        </CustomScroll>
      </panels.frames.FocusPanelFrame>
    );
  }
}

AppImportPanel.defaultProps = {
  openAppVariant: false,
  helpId: '0e554b89-a1e0-4b6d-846f-4201b71b6258',
  noCreatedAppsIllustration: isResponsiveEditor() ? (
    <symbols.symbol name="empty_state_x" />
  ) : (
    <symbols.symbol name="empty_no_aps_yet" />
  ),
  allAppsAddedIllustration: isResponsiveEditor() ? (
    <symbols.symbol name="empty_state_x" />
  ) : (
    <symbols.symbol name="empty_state" />
  ),
  displayMode: util.appStudioUtils.DisplayMode.OnlyWidgets,
};

AppImportPanel.propTypes = {
  apps: PropTypes.array.isRequired,
  closePanel: PropTypes.func.isRequired,
  fetchAvailableApps: PropTypes.func.isRequired,
  hasApps: PropTypes.bool,
  isEmptyState: PropTypes.bool,
  isLoading: PropTypes.bool,
  isLoadingAppsError: PropTypes.bool,
  isNoCreatedApps: PropTypes.bool,
  onImport: PropTypes.func.isRequired,
  sendBi: PropTypes.func.isRequired,
  openErrorPanel: PropTypes.func.isRequired,
  setIsImportingApp: PropTypes.func.isRequired,
  isPackagesViewExpanded: PropTypes.bool.isRequired,
  isWixUser: PropTypes.bool,
  togglePackagesView: PropTypes.func.isRequired,
  openAppVariant: PropTypes.bool,
  helpId: PropTypes.string,
  panelTitle: PropTypes.string,
  noCreatedAppsText: PropTypes.string,
  noCreatedAppsTitle: PropTypes.string,
  noCreatedAppsIllustration: PropTypes.node,
  noCreatedAppsActionLabel: PropTypes.string,
  allAppsAddedText: PropTypes.string,
  allAppsAddedTitle: PropTypes.string,
  allAppsAddedIllustration: PropTypes.node,
  allAppsAddedOnActionClick: PropTypes.func,
  allAppsAddedActionLabel: PropTypes.string,
  displayMode: PropTypes.oneOf([
    util.appStudioUtils.DisplayMode.OnlyWidgets,
    util.appStudioUtils.DisplayMode.OnlyCodePackages,
    util.appStudioUtils.DisplayMode.All,
  ]),
  fetchAccounts: PropTypes.func.isRequired,
  currentAccount: PropTypes.object,
  accounts: PropTypes.array,
  onClickWorkspaceSwitcher: PropTypes.func,
};

const installApp = (
  editorAPI: EditorAPI,
  { appDefinitionId, latestVersion, blocksVersion }: AppDescriptor,
) =>
  new Promise(function (resolve, reject) {
    const type = tpaConstants.APP.TYPE.PLATFORM_ONLY;
    const biInitiator = tpaConstants.BI.initiator.EDITOR;
    const appVersion = util.appStudioUtils.versionToInstall({
      blocksVersion,
      appVersion: latestVersion,
    });
    const options: { appVersion?: string; headlessInstallation?: boolean } =
      !appVersion
        ? {}
        : {
            appVersion,
          };
    options.headlessInstallation = false;
    const callback = (data: any) => {
      if (data?.success) {
        util.fedopsLogger.interactionEnded(
          util.fedopsLogger.INTERACTIONS.BLOCKS.IMPORT_APP,
        );
        return resolve(data);
      }
      return reject(data);
    };
    const platformOrigin = {
      type: EditorType.Classic,
      initiator: InstallInitiator.Editor,
      info: {
        type: InstallationOriginType.IMPORT_PANEL,
      },
    };
    util.fedopsLogger.interactionStarted(
      util.fedopsLogger.INTERACTIONS.BLOCKS.IMPORT_APP,
    );
    tpaAddAppService.addApp(
      editorAPI,
      appDefinitionId,
      null,
      null,
      type,
      biInitiator,
      false,
      {},
      options,
      callback,
      platformOrigin,
    );
  });

const filterBlocksApps = (apps: any) =>
  apps.filter((app: any) => util.appStudioUtils.isBlocksApp(app));

const mapStateToProps = (
  {
    state,
    dsRead,
    editorAPI,
  }: { state: any; dsRead: DSRead; editorAPI: EditorAPI },
  ownProps: AppImportPanelOwnProps,
) => {
  const apps = getAvailableApps(state);
  const availableApps: AppDescriptor[] = apps
    ? util.appStudioUtils.filterAppsByDisplayMode(
        filterBlocksApps(apps),
        ownProps.displayMode,
      )
    : apps;
  const installedApps = getInstalledBlocksApps(dsRead);
  const appsForInstallation = _.differenceBy(
    availableApps,
    installedApps,
    (app) => app.appDefinitionId,
  );
  const isLoading = getIsLoading(state);
  const hasApps = appsForInstallation.length > 0;
  const isLoadingAppsError = getIsLoadingAppsError(state);
  const isNoCreatedApps =
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/size
    _.size(availableApps) === 0 && !isLoading && !isLoadingAppsError;
  const isEmptyState =
    !hasApps && !isLoading && !isLoadingAppsError && !isNoCreatedApps;
  const onImport = (app: AppDescriptor) => installApp(editorAPI, app);
  const isPackagesViewExpanded = packagesView.getIsRootExpanded(state);
  const currentAccount = editorAPI.account.getCurrentAccount();
  const accounts = editorAPI.account.getAccounts();

  return {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/map
    apps: _(appsForInstallation)
      .map((app) =>
        // eslint-disable-next-line you-dont-need-lodash-underscore/assign
        _.assign(
          {
            isDraft: !util.appStudioUtils.isAppMajorVersionBuilt(
              app.latestVersion,
            ),
            isAlpha: !util.appStudioUtils.isResponsiveBlocksVersion(
              app.blocksVersion,
            ),
            href: util.appStudioUtils.createEditAppURL(
              app,
              util.appStudioUtils.httpReferrerOptions.AppImportPanel,
            ),
            canImport: util.appStudioUtils.canImport(app),
          },
          app,
        ),
      )
      .sortBy('dateCreated')
      .reverse()
      .value(),
    hasApps,
    isEmptyState,
    isLoadingAppsError,
    isLoading,
    isNoCreatedApps,
    onImport,
    isPackagesViewExpanded,
    isWixUser: util.appStudioUtils.isWixUser(dsRead),
    currentAccount,
    accounts,
    fetchAccounts: editorAPI.account.fetchAccounts,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: AppImportPanelOwnProps,
) => {
  const errorPanelName = 'tpa.panels.appImportErrorPanel';
  const onCloseErrorPanel = () =>
    dispatch(
      stateManagement.panels.actions.closePanelByNameAction(errorPanelName),
    );
  return {
    closePanel: () => {
      dispatch(
        stateManagement.panels.actions.closePanelByNameAction(
          ownProps.panelName,
        ),
      );
    },
    fetchAvailableApps: () =>
      dispatch(stateManagement.platform.actions.fetchAvailableApps()),
    openErrorPanel: () =>
      dispatch(
        stateManagement.panels.actions.updateOrOpenPanel(errorPanelName, {
          onClose: onCloseErrorPanel,
        }),
      ),
    setIsImportingApp: (isImportingApp: boolean) =>
      dispatch(
        stateManagement.platform.actions.setIsImportingApp(isImportingApp),
      ),
    togglePackagesView: () => dispatch(togglePackagesViewRoot()),
    sendBi: (event: BIEventDefinition, parameters: BIEventFields) =>
      dispatch(stateManagement.bi.actions.event(event, parameters)),
  };
};

export default util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(AppImportPanel);
