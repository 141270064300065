import * as stateManagement from '@/stateManagement';
import { hoc } from '@/util';

const mapDispatchToProps = {
  closePanelByName: stateManagement.panels.actions.closePanelByName,
  openHelpCenter: stateManagement.panels.actions.openHelpCenter,
  sendBi: stateManagement.bi.actions.event,
};

export default hoc.connect(hoc.STORES.EDITOR_API, null, mapDispatchToProps);
