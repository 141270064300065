import { createReduxStore } from '@/apilib';

export interface WizardState {
  isWizardActive: boolean;
}

const getInitialState = (): WizardState => ({
  isWizardActive: false,
});

export const WizardStore = createReduxStore({
  getInitialState,
  selectors: {
    getIsWizardActive: (state: WizardState) => state.isWizardActive,
  },
  actions: {
    setWizardActive: (state: WizardState, isWizardActive: boolean) => {
      return {
        ...state,
        isWizardActive,
      };
    },
  },
});
