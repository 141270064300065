// @ts-nocheck
export default {
  'wysiwyg.viewer.skins.gallery.SlideShowPolaroid':
    'wysiwyg_viewer_skins_gallery_SlideShowPolaroid',
  'wysiwyg.viewer.skins.gallery.SlideShowTextBottom':
    'wysiwyg_viewer_skins_gallery_SlideShowTextBottom',
  'wysiwyg.viewer.skins.gallery.SlideShowTextFloating':
    'wysiwyg_viewer_skins_gallery_SlideShowTextFloating',
  'wysiwyg.viewer.skins.gallery.SlideShowTextOverlay':
    'wysiwyg_viewer_skins_gallery_SlideShowTextOverlay',
  'wysiwyg.viewer.skins.gallery.SlideShowTextRight':
    'wysiwyg_viewer_skins_gallery_SlideShowTextRight',
};
