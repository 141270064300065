import constants from '@/constants';

import { searchModule } from '../searchModule';
import { Integrations } from '../constants';

import type { EditorAPI } from '@/editorAPI';

const {
  BI: {
    HELP: { ORIGIN },
  },
} = constants;

const BI_SOURCE_NAME = 'editorSearch';

export const integrateHelpCenterToSearch = (editorAPI: EditorAPI) => {
  const {
    panelManager: { openHelpCenter },
  } = editorAPI;

  searchModule.addIntegration({
    type: Integrations.HELP_CENTER,
    transform: (result) => {
      const { id } = result;

      return {
        ...result,
        onSubmit: () =>
          openHelpCenter(id, null, {
            origin: ORIGIN.EDITOR_SEARCH,
            panel_name: BI_SOURCE_NAME,
            learn_more: false,
          }),
      };
    },
  });
};
