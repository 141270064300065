import * as util from '@/util';
import type { StateMapperArgs } from 'types/redux';
import * as stateManagement from '@/stateManagement';

function mapStateToProps({ editorAPI, state }: StateMapperArgs) {
  const { publishRC } = editorAPI.publishManager;
  const { publish } = editorAPI.publishManager;
  const { saveAndPublish } = editorAPI.publishManager;
  const { canUserPublish } = editorAPI.savePublish;
  const shouldSaveBeforePublish =
    editorAPI.publishManager.shouldSaveBeforePublish() ||
    editorAPI.publishManager.hasOverriddenSaveAndPublish();
  const isSiteBranchesActive = util.wixCodeUtils.isSiteBranchesActive();
  const shouldShowBranchName =
    isSiteBranchesActive &&
    stateManagement.branches.selectors.getHasBranches(state);
  const branchName =
    stateManagement.branches.selectors.getCurrentBranchName(state);

  return {
    publish,
    publishRC,
    canUserPublish,
    saveAndPublish,
    shouldSaveBeforePublish,
    sendBI: (eventId: AnyFixMe, params: AnyFixMe) =>
      editorAPI.bi.event(eventId, params),
    shouldShowBranchName,
    branchName,
  };
}

export default util.hoc.connect(util.hoc.STORES.EDITOR_API, mapStateToProps);
