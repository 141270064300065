// @ts-nocheck
export default {
  editableTypes: [
    'cssBoxShadow',
    'cssBorderRadius',
    'cssBorder',
    'themeMappings',
  ],

  togglableCategories: ['cssBoxShadow'],

  categoryToggleLabels: {
    cssBoxShadow: 'CustomDesign_Shadow_EnableToggle',
  },

  categoryDefaults: Object.freeze({
    cssBoxShadow: [
      {
        // 'disabled': true,
        inset: false,
        offsetX: { unit: 'px', value: 0 },
        offsetY: { unit: 'px', value: 1 },
        blurRadius: { unit: 'px', value: 4 },
        spreadRadius: { unit: 'px', value: 0 },
        color: { red: 0, green: 0, blue: 0, alpha: 1 },
      },
    ],
    cssBorderRadius: {
      topLeft: { unit: 'px', value: 0 },
      topRight: { unit: 'px', value: 0 },
      bottomRight: { unit: 'px', value: 0 },
      bottomLeft: { unit: 'px', value: 0 },
    },
    cssBorder: {
      width: {
        top: { unit: 'px', value: 0 },
        right: { unit: 'px', value: 0 },
        bottom: { unit: 'px', value: 0 },
        left: { unit: 'px', value: 0 },
      },
      style: {
        top: 'solid',
        left: 'solid',
        right: 'solid',
        bottom: 'solid',
      },
      color: {
        top: { red: 0, green: 0, blue: 0, alpha: 1 },
        right: { red: 0, green: 0, blue: 0, alpha: 1 },
        bottom: { red: 0, green: 0, blue: 0, alpha: 1 },
        left: { red: 0, green: 0, blue: 0, alpha: 1 },
      },
    },
  }),

  designDataExtensionDefualts: Object.freeze({
    cssBoxShadow: [
      {
        angle: 0,
      },
    ],
  }),

  HEIGHT_SMALL: '380px',

  HEIGHT_BIG: '453px',

  MIN_VIEW_PORT_HEIGHT: 500,
};
