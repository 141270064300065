import type {
  CompRef,
  CompVariantPointer,
  Pointer,
} from 'types/documentServices';

export type AnimationType = 'entrance' | 'loop' | 'scroll';

export const triggerTypes = [
  'viewport-enter',
  'page-visible',
  'animation-end',
  'view-progress',
] as const;

export type TriggerType = (typeof triggerTypes)[number];

export interface TriggerParams {
  trigger: TriggerType;
  params?: { [key: string]: any };
}

export type ReactionParams = { type: string } & { [key: string]: any };

export interface Reaction {
  type: 'Play' | 'Scrub';
  component: CompRef | CompVariantPointer;
  pointer: Pointer;
  triggerType: TriggerType;
  effect: Pointer;
  once?: boolean;
}
