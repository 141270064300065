import React from 'react';
import { UpperDeck } from './decs';

function zoomModeLayout(this: AnyFixMe) {
  return (
    <>
      <UpperDeck>
        <div className="top-bar-left-section with-separators">
          {this.renderLogo()}
          {this.renderDeviceSwitch()}
        </div>
        <div className="top-bar-center-section">
          {this.renderNotification()}
        </div>
        <div className="top-bar-right-section with-separators">
          {this.renderUndoRedo()}
          {this.renderZoomModeActionsButtons()}
        </div>
      </UpperDeck>
    </>
  );
}

export default zoomModeLayout;
