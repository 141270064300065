const RECORD_QUERY_PARAMETER = '&alwaysgb';

export function isPageUrlWithSessionRecording(): boolean {
  return window.location.href.includes(RECORD_QUERY_PARAMETER);
}

export function reloadPageWithSessionRecording(): void {
  if (!isPageUrlWithSessionRecording()) {
    window.location.href = getPageUrlWithSessionRecording();
  }
}

export function reloadPageWithoutSessionRecording(): void {
  window.location.href = getPageUrlWithoutSessionRecording();
}

export function getPageUrlWithSessionRecording() {
  return window.location.href + RECORD_QUERY_PARAMETER;
}

export function getPageUrlWithoutSessionRecording(): string {
  return window.location.href.replace(RECORD_QUERY_PARAMETER, '');
}
