'use strict'

module.exports = {
    "id": "add_preset_popups_Sale_4",
    "structure": {
        "type": "Page",
        "id": "x6xmz",
        "componentTypeForBI": "wysiwyg.viewer.components.PopupContainer",
        "components": [{
            "type": "Container",
            "id": "comp-im09lnwx",
            "components": [{
                "type": "Component",
                "id": "comp-im09lnxq",
                "skin": "wysiwyg.viewer.skins.button.BasicButton",
                "layout": {
                    "width": 185,
                    "height": 45,
                    "x": 195,
                    "y": 244,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.PopupCloseTextButton",
                "data": {
                    "type": "LinkableButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "label": "Shop Now",
                    "id": "dataItem-im09lnxr"
                },
                "props": {
                    "type": "ButtonProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "align": "center",
                    "margin": 0,
                    "id": "propItem-im09lnxs"
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-bg": "1",
                            "alpha-bgh": "1",
                            "alpha-brd": "0",
                            "alpha-brdh": "0",
                            "alpha-txt": "1",
                            "alpha-txth": "1",
                            "bg": "#FFFFFF",
                            "bgh": "#EDEDED",
                            "boxShadowToggleOn-shd": "true",
                            "brd": "#000000",
                            "brdh": "#EDEDED",
                            "brw": "1",
                            "fnt": "normal normal normal 16px/1.4em futura-lt-w01-book",
                            "rd": "20px",
                            "shd": "0.00px 4.00px 3px 1px rgba(0,0,0,0.14)",
                            "txt": "#00A2FF",
                            "txth": "#00A2FF"
                        },
                        "propertiesSource": {
                            "alpha-bg": "value",
                            "alpha-brd": "value",
                            "alpha-brdh": "value",
                            "bg": "value",
                            "bgh": "value",
                            "brd": "value",
                            "brdh": "value",
                            "brw": "value",
                            "fnt": "value",
                            "rd": "value",
                            "shd": "value",
                            "txt": "value",
                            "txth": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.PopupCloseTextButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.button.BasicButton",
                    "id": "style-im51m7ct"
                }
            }, {
                "type": "Component",
                "id": "comp-im09ul4c",
                "layout": {
                    "width": 292,
                    "height": 63,
                    "x": 141,
                    "y": 92,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "text": "<h2 class=\"font_2\" style=\"font-size:44px; text-align:center\"><span style=\"color:#FFFFFE;\"><span style=\"font-size:44px\"><span style=\"letter-spacing:0.15em\"><span style=\"font-family:arial black,arial-w01-black,arial-w02-black,arial-w10 black,sans-serif\"><span style=\"font-style:italic\">50% OFF&nbsp;</span></span></span></span></span></h2>\n",
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": [],
                    "id": "dataItem-im09ul4g"
                },
                "props": {
                    "type": "WRichTextProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "brightness": 1,
                    "packed": true,
                    "id": "propItem-im09ul4h"
                },
                "style": "txtNew"
            }, {
                "type": "Component",
                "id": "comp-im09zxsk",
                "layout": {
                    "width": 413,
                    "height": 33,
                    "x": 81,
                    "y": 167,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "text": "<p class=\"font_8\" style=\"font-size:24px; text-align:center\"><span style=\"color:#FFFFFE;\"><span style=\"font-size:24px\"><span style=\"letter-spacing:0.1em\"><span style=\"font-family:futura-lt-w01-book,sans-serif\">HURRY! SALE ENDS TODAY.</span></span></span></span></p>\n",
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": [],
                    "id": "dataItem-im09zxso"
                },
                "props": {
                    "type": "WRichTextProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "brightness": 1,
                    "packed": true,
                    "id": "propItem-im09zxso1"
                },
                "style": "txtNew"
            }, {
                "type": "Component",
                "skin": "svgshape.v2.Svg_001419cc721a440abc4ab12ed150b744",
                "layout": {
                    "width": 30,
                    "height": 30,
                    "x": 527,
                    "y": 18,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.PopupCloseIconButton",
                "data": {
                    "type": "SvgShape",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    }
                },
                "props": {
                    "type": "SvgShapeProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "maintainAspectRatio": true
                },
                "style": {
                    "type": "ComponentStyle",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "style": {
                        "properties": {
                            "alpha-fillcolor": "1",
                            "alpha-stroke": "1",
                            "fillcolor": "#FFFFFF",
                            "stroke": "#00A2FF",
                            "strokewidth": "1"
                        },
                        "propertiesSource": {
                            "alpha-fillcolor": "value",
                            "alpha-stroke": "value",
                            "fillcolor": "value",
                            "stroke": "value",
                            "strokewidth": "value"
                        },
                        "groups": {}
                    },
                    "componentClassName": "wysiwyg.viewer.components.PopupCloseIconButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "svgshape.v2.Svg_001419cc721a440abc4ab12ed150b744"
                },
                "connections": {
                    "type": "ConnectionList",
                    "items": [
                        {
                            "type": "WixCodeConnectionItem",
                            "role": "button1"
                        }
                    ]
                },
                "mobileStructure": {
                    "layout": {
                        "width": 24,
                        "height": 24,
                        "x": 261,
                        "y": 17,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "metaData": {
                        "originalCompId": "comp-isrfgs8u",
                        "author": "duplicate"
                    }
                },
                "activeModes": {}
            }],
            "skin": "wysiwyg.viewer.skins.stripContainer.DefaultStripContainer",
            "layout": {
                "width": 575,
                "height": 348,
                "x": 627,
                "y": 430,
                "scale": 1,
                "rotationInDegrees": 0,
                "fixedPosition": false
            },
            "componentType": "wysiwyg.viewer.components.PopupContainer",
            "props": {
                "type": "PopupContainerProperties",
                "metaData": {"schemaVersion": "1.0"},
                "horizontalAlignment": "right",
                "verticalAlignment": "bottom",
                "alignmentType": "nineGrid",
                "horizontalOffset": 0,
                "verticalOffset": 0,
                "id": "propItem-im09lnx9"
            },
            "design": {
                "type": "MediaContainerDesignData",
                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                "background": {
                    "type": "BackgroundMedia",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "color": "#00A2FF",
                    "colorOpacity": 1,
                    "alignType": "center",
                    "fittingType": "fill",
                    "scrollType": "none",
                    "colorOverlay": "",
                    "colorOverlayOpacity": 0
                },
                "id": "dataItem-im09lnxa"
            },
            "style": {
                "type": "TopLevelStyle",
                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                "style": {
                    "groups": {},
                    "properties": {"alpha-bg": "1", "bg": "rgba(255,255,255,1)"},
                    "propertiesSource": {"alpha-bg": "value", "bg": "value"}
                },
                "componentClassName": "wysiwyg.viewer.components.PopupContainer",
                "pageId": "",
                "compId": "",
                "styleType": "custom",
                "skin": "wysiwyg.viewer.skins.stripContainer.DefaultStripContainer",
                "id": "style-im09lnxc"
            },
            "behaviors": {
                "type": "ObsoleteBehaviorsList",
                "items": "[{\"name\":\"FlyIn\",\"delay\":0,\"duration\":0.55,\"params\":{\"direction\":\"bottom\"},\"action\":\"screenIn\",\"targetId\":\"\"},{\"name\":\"FlyOut\",\"delay\":0,\"duration\":0.55,\"params\":{\"direction\":\"bottom\"},\"action\":\"exit\",\"targetId\":\"\"}]",
                "id": "behavior-iomlwee3"
            }
        }],

        "skin": "skins.core.InlineSkin",
        "layout": {
            "width": 980,
            "height": 778,
            "x": 0,
            "y": 0,
            "scale": 1,
            "rotationInDegrees": 0,
            "fixedPosition": false
        },
        "componentType": "mobile.core.components.Page",
        "data": {
            "type": "Page",
            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
            "title": "add_preset_popups_Sale_4",
            "hideTitle": true,
            "icon": "",
            "descriptionSEO": "",
            "metaKeywordsSEO": "",
            "pageTitleSEO": "",
            "pageUriSEO": "blank-x6xmz",
            "hidePage": true,
            "underConstruction": false,
            "tpaApplicationId": 0,
            "pageSecurity": {"requireLogin": false, "passwordDigest": "", "dialogLanguage": ""},
            "isPopup": true,
            "indexable": false,
            "isLandingPage": false,
            "pageBackgrounds": {
                "desktop": {
                    "ref": {
                        "type": "BackgroundMedia",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "color": "#EDEDED",
                        "colorOpacity": 0.8,
                        "alignType": "center",
                        "fittingType": "fill",
                        "scrollType": "none",
                        "colorOverlay": "",
                        "colorOverlayOpacity": 0
                    }
                },
                "mobile": {
                    "custom": true,
                    "ref": {
                        "type": "BackgroundMedia",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "color": "#EDEDED",
                        "colorOpacity": 0.8,
                        "alignType": "center",
                        "fittingType": "fill",
                        "scrollType": "scroll",
                        "colorOverlay": "",
                        "colorOverlayOpacity": 0
                    },
                    "isPreset": true
                }
            },
            "ignoreBottomBottomAnchors": true
        },
        "props": {
            "type": "PageProperties",
            "metaData": {"schemaVersion": "1.0"},
            "desktop": {"popup": {"closeOnOverlayClick": true}},
            "mobile": {"popup": {"closeOnOverlayClick": true}},
            "id": "x6xmz"
        },
        "style": "p1",
        "tpaComps": []
    },
    "preset": {
        "rect": {"width": 305, "height": 202, "x": 10, "y": 242},
        "label": "add_preset_popups_Sale_4",
        "tags": null
    }
}
