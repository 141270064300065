// @ts-nocheck
import addPanelDataConsts from '@/addPanelDataConsts';
import * as timetableWidgetConst from './timetableWidgetConst';
import editorActionsService from '../../common/editorActionsService';

export default function ({ editorAPI }) {
  const actionsService = editorActionsService({ editorAPI });
  const addPanelConsts = addPanelDataConsts;
  const dailyTimetableWidget = {
    id: timetableWidgetConst.DAILY_TIMETABLE_PRESET_KEY,
    structure: {
      layout: {
        x: 0,
        y: 0,
        width: 980,
        height: 558,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      data: {
        widgetId: timetableWidgetConst.DAILY_TIMETABLE_WIDGET_ID,
        variationId: undefined,
        installationType: 'closed',
      },
      style: {
        type: 'TopLevelStyle',
        metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
        style: {
          properties: {},
          propertiesSource: {},
          groups: {},
        },
        componentClassName: 'wysiwyg.viewer.components.tpapps.TPAWidget',
        pageId: '',
        compId: '',
        styleType: 'custom',
        skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
      },
      overriddenData: timetableWidgetConst.DAILY_TIMETABLE_OVERRIDES,
    },
    preset: {
      rect: { width: 302, height: 157, x: 10, y: 14 },
      label: '',
      tags: null,
    },
  };

  const timetableWidgetItems = [dailyTimetableWidget];

  return {
    id: 'wixBookingsTimetableWidgets',
    type: addPanelConsts.SECTIONS_TYPES.PRESET,
    hide: false,
    title: timetableWidgetConst.DAILY_TIMETABLE_TITLE_KEY,
    subNavigationTitle: timetableWidgetConst.DAILY_TIMETABLE_TITLE_KEY,
    presetTitle: timetableWidgetConst.DAILY_TIMETABLE_TITLE_KEY,
    tooltipTitle: timetableWidgetConst.DAILY_TIMETABLE_TITLE_KEY,
    showSectionHeader: true,
    additionalBehaviours: {
      labelMode: addPanelConsts.LABEL_BEHAVIOUR.ALWAYS,
      infoIcon: addPanelConsts.INFO_ICON_BEHAVIOUR.NONE,
      hoverImageAction: addPanelConsts.HOVER_IMAGE_ACTIONS.NONE,
      scaleProportion: addPanelConsts.DEFAULT_SCALE_FOR_PRESET,
      iconEnabledForComps: [],
    },
    props: {
      image: timetableWidgetConst.BOOKABLE_DAILY_TIMETABLE_WIDGET_IMAGE_V4,
      imageHover: null,
      onDrop: actionsService.onRefWidgetDrop,
      onClick: actionsService.onRefWidgetClick,
      items: timetableWidgetItems,
      compTypes: ['wysiwyg.viewer.components.tpapps.TPAWidget'],
    },
  };
}
