import _ from 'lodash';
import componentsDefaultData, {
  type ComponentDefaultData,
} from '../../addPanel/componentsDefaultData';
import type {
  CompRef,
  CompStructure,
  StyleRefOrStyleRefs,
} from 'types/documentServices';

interface CompData {
  key: string;
  compType: string;
  compPtr?: CompRef;
  styleId?: string;
  style?: string | StyleRefOrStyleRefs;
  width?: number;
  height?: number;
}

interface PreparedCompData extends CompStructure {
  type: 'Component' | 'Container';
  layout: { width: number; height: number };
  compPtr?: CompRef;
  data?: {
    [key: string]: unknown;
  };
  props?: {
    [key: string]: unknown;
  };
  components?: CompStructure[];
  dataQuery?: string;
}

function getCompLayoutOnStage(
  compDef: ComponentDefaultData,
  compData?: CompData,
) {
  if (compDef.takeMeasuredLayout && compData) {
    return {
      width: compData.width,
      height: compData.height,
    };
  }

  if (compDef.stageLayout) {
    return {
      width: compDef.stageLayout.width,
      height: compDef.stageLayout.height,
    };
  }

  return {
    width: compDef.panelLayout.width,
    height: compDef.panelLayout.height,
  };
}

export function prepareCompData(
  compData: CompData,
  parentType?: AnyFixMe,
): PreparedCompData {
  const compDef = componentsDefaultData.getComponentDefault(
    compData.key,
    parentType,
  );

  const newData: PreparedCompData = {
    componentType: compData.compType,
    style: compData.style,
    layout: getCompLayoutOnStage(compDef, compData),
    type: 'Component',
    compPtr: compData.compPtr,
  };

  if (compDef.data || compDef.stageData) {
    newData.data = _.merge({}, _.omit(compDef.data, 'id'), compDef.stageData);
  }

  if (compDef.props || compDef.stageProps) {
    newData.props = _.merge(
      {},
      _.omit(compDef.props, 'id'),
      compDef.stageProps,
    );
  }

  if (compDef.dataQuery) {
    newData.dataQuery = compDef.dataQuery;
    delete newData.data;
  }

  if (compDef.isContainer) {
    newData.components = [];
    newData.type = 'Container';
  }

  return newData;
}
