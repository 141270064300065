import type {
  CompRef,
  DocumentServicesObject,
} from '@wix/document-services-types';

interface Layout {
  x: number;
  y: number;
  width: number;
  height: number;
  top: number;
  left: number;
  bottom: number;
  right: number;
}

const doElementsOverlap = (rectA: Layout, rectB: Layout): boolean => {
  const doHorizontalOverlap =
    rectA.left < rectB.right && rectB.left < rectA.right;
  const doVerticalOverlap =
    rectA.top < rectB.bottom && rectB.top < rectA.bottom;

  return doHorizontalOverlap && doVerticalOverlap;
};

const calculateOverlapList = (
  elements: Array<CompRef>,
  layouts: Record<string, Layout>,
) => {
  const overlapList: any = [];
  const orderedElementsIds = elements.map(({ id }) => id);

  for (let a = 0; a < orderedElementsIds.length - 1; a++) {
    for (let b = a + 1; b < orderedElementsIds.length; b++) {
      const elementIdA = orderedElementsIds[a];
      const elementIdB = orderedElementsIds[b];
      const rectA = layouts[elementIdA];
      const rectB = layouts[elementIdB];

      if (rectA && rectB && doElementsOverlap(rectA, rectB)) {
        overlapList.push([elementIdB, elementIdA]);
      }
    }
  }

  return overlapList;
};

const CHUNK_SIZE = 100_000;
const TIMEOUT = 0;

const everyWithChunks = async <T>(
  arr: Array<T>,
  callback: (item: T) => boolean,
) => {
  for (let i = 0; i < arr.length; i++) {
    const item = arr[i];
    const result = callback(item);

    if (!result) {
      return false;
    }

    if (i !== 0 && i % CHUNK_SIZE === 0) {
      console.log(`arr.length: ${arr.length}, index: ${i}`);
      await new Promise((resolve) => setTimeout(resolve, TIMEOUT));
    }
  }

  return true;
};

const calculateLevelMap = async (
  elements: Array<CompRef>,
  overlapList: Array<Array<string>>,
) => {
  const levelMap = elements.reduce((map: Record<string, number>, { id }) => {
    map[id] = 0;
    return map;
  }, {});

  let level = 1;

  while (overlapList.length) {
    const nextOverlapList: any = [];
    const bottomElements = new Set();
    let lastTs = performance.now();

    for (let i = 0; i < overlapList.length; i++) {
      const bottomId = overlapList[i][1];

      if (overlapList.every(([topId]) => topId !== bottomId)) {
        bottomElements.add(bottomId);
      }
    }

    for (let i = 0; i < overlapList.length; i++) {
      const [topId, bottomId] = overlapList[i];
      if (!bottomElements.has(topId)) {
        levelMap[topId] = level;
      }

      if (!bottomElements.has(bottomId)) {
        nextOverlapList.push([topId, bottomId]);
      }
    }

    console.log(
      `chunk: ${overlapList.length}, time: ${performance.now() - lastTs}}`,
    );
    lastTs = performance.now();
    await new Promise((resolve) => setTimeout(resolve, TIMEOUT));

    overlapList = nextOverlapList;
    level++;
  }

  return levelMap;
};

const calculateZIndexMap = async (
  elements: Array<CompRef>,
  layouts: Record<string, Layout>,
) => {
  const overlapList = calculateOverlapList(elements, layouts);

  return calculateLevelMap(elements, overlapList);
};

const EXCLUDED_COMPONENT_TYPE = 'wysiwyg.viewer.components.BoxSlideShow';

const filterComponentsFromExludedTypes = (
  documentServices: DocumentServicesObject,
  components: Array<CompRef>,
): CompRef[] => {
  const originalComponentMap = new Map();
  const componentsToExclude: string[] = [];
  components.forEach((compRef) => {
    const componentType = documentServices.components.getType(compRef);

    if (componentType !== EXCLUDED_COMPONENT_TYPE) {
      originalComponentMap.set(compRef.id, compRef);
    } else {
      documentServices.components
        .getChildren(compRef, true)
        .forEach(({ id }) => componentsToExclude.push(id));
    }
  });

  componentsToExclude.forEach((id) => originalComponentMap.delete(id));

  return Array.from(originalComponentMap.values());
};

const TEXT_COMPONENT_TYPE = 'wysiwyg.viewer.components.WRichText';
const DEFAULT_PAGE_WIDTH = 980;
const TARGET_SITE_WIDTH = 1920;

export const extractDomData = async (
  documentServices: DocumentServicesObject,
  components: Array<CompRef>,
) => {
  // @ts-ignore
  const masterPageLayout = documentServices.pages.getLayout(
    documentServices.pages.getMasterPageId(),
  );
  const pageWidth = masterPageLayout?.width ?? DEFAULT_PAGE_WIDTH;

  const filteredComponents = filterComponentsFromExludedTypes(
    documentServices,
    components,
  );

  const domLayout = filteredComponents.reduce(
    (acc: Record<string, Layout>, compRef: CompRef) => {
      const { id } = compRef;
      let layout;
      try {
        // for some components it is not possible to get layout (it si undefined)
        // DS doesn't cope these edge case in the getRelativeToStructure methods
        // it causes "Cannot read properties of undefined" error
        layout =
          documentServices.components.layout.getRelativeToStructure(compRef);
      } catch (e) {
        layout = documentServices.components.layout.get(compRef);
      }

      if (layout) {
        const isFullWidth = documentServices.components.is.fullWidth(compRef);
        const { x: rawX, y, width: rawWidth, height, fixedPosition } = layout;
        const width = isFullWidth ? TARGET_SITE_WIDTH : rawWidth;
        let x = isFullWidth ? 0 : rawX + (TARGET_SITE_WIDTH - pageWidth) / 2;

        if (fixedPosition) {
          const fixedLayout =
            documentServices.components.layout.getDock(compRef) ?? {};

          if (fixedLayout.left) {
            // @ts-ignore
            x = fixedLayout.left.px;
          } else if (fixedLayout.right) {
            // @ts-ignore
            x = TARGET_SITE_WIDTH - width - fixedLayout.right.px;
          } else if (fixedLayout.hCenter) {
            // @ts-ignore
            x = (TARGET_SITE_WIDTH - width) / 2 + fixedLayout.hCenter.px;
          }
        }

        acc[id] = {
          x,
          y,
          width,
          height,
          top: y,
          left: x,
          bottom: y + height,
          right: x + width,
        };
      }

      return acc;
    },
    {},
  );

  // use base layout for text components
  const textLayout = filteredComponents.reduce(
    (acc: Record<string, Layout>, compRef: CompRef) => {
      const { id } = compRef;
      const componentType = documentServices.components.getType(compRef);

      if (componentType === TEXT_COMPONENT_TYPE) {
        acc[id] = domLayout[id];
      }

      return acc;
    },
    {},
  );

  const zIndexMap = await calculateZIndexMap(filteredComponents, domLayout);

  return {
    domLayout,
    textLayout,
    zIndexMap,
    isVisibleList: {},
  };
};
