// @ts-nocheck
import _ from 'lodash';
import $ from 'zepto';
import * as textMigrationDomHelper from './textMigrationDomHelper';
import * as textMigrationStylesHelper from './textMigrationStylesHelper';
import CONSTANTS from './constants';

function migrateElement(element) {
  _migrateBold(element);
  _migrateItalic(element);
  _migrateUnderline(element);
  _migrateAlignRight(element);
  _migrateAlignCenter(element);
  _migrateJustify(element);
  _migrateUl(element);
}

function _migrateBold(element) {
  const elements = $(element).find('.bold');
  _removeClassAddTag(elements, 'bold', 'strong');
}

function _migrateItalic(element) {
  const elements = $(element).find('.italic');
  _removeClassAddTag(elements, 'italic', 'em');
}

function _migrateUnderline(element) {
  const elements = $(element).find('.underline');
  _removeClassAddTag(elements, 'underline', 'u');
}

function _migrateAlignRight(element) {
  const elements = $(element).find('.alignRight');
  _removeClassAddInlineStyle(elements, 'alignRight', 'text-align', 'right');
}

function _migrateAlignCenter(element) {
  const elements = $(element).find('.alignCenter');
  _removeClassAddInlineStyle(elements, 'alignCenter', 'text-align', 'center');
}

function _migrateJustify(element) {
  const elements = $(element).find('.alignJustify');
  _removeClassAddInlineStyle(elements, 'alignJustify', 'text-align', 'justify');
}

function _migrateUl(element) {
  const elements = $(element).find('ul, ol');
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
  _.forEach(elements, function (_element) {
    if (!textMigrationStylesHelper.getElementStyleClass(_element)) {
      $(_element).addClass(CONSTANTS.DEFAULT_STYLE_CLASS);
    }
  });
}

function _removeClassAddTag(elements, cssClass, tag) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
  _.forEach(elements, function (el) {
    const formatElement = window.document.createElement(tag);
    textMigrationDomHelper.moveChildren(el, formatElement);
    textMigrationDomHelper.removeClass(el, cssClass);
    if (
      textMigrationDomHelper.isElementHasAttributes(el) ||
      el.nodeName.toLowerCase() !== 'span'
    ) {
      $(el).append($(formatElement));
    } else {
      $(el).replaceWith($(formatElement));
    }
  });
}

function _removeClassAddInlineStyle(elements, cssClass, styleKey, styleValue) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
  _.forEach(elements, function (el) {
    textMigrationDomHelper.removeClass(el, cssClass);
    const styleProperties = {};
    styleProperties[styleKey] = styleValue;
    textMigrationDomHelper.setStyle(el, styleProperties);
  });
}

export { migrateElement };
