import createReactClass from 'create-react-class';
import * as core from '@/core';
import * as util from '@/util';
import * as superApp from './superApp';
import React from 'react';
import * as superAppMenuInfra from '@/superAppMenuInfra';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'WixStorePanel',
  mixins: [
    util.propTypesFilterMixin as React.Mixin<any, any>,
    core.mixins.editorAPIMixin,
  ],
  getSuperApp() {
    const isUserOwner = this.getEditorAPI().account.isUserOwner();
    return superApp.getWixStoresSuperApp(isUserOwner, this.getEditorAPI());
  },
  render() {
    return (
      <superAppMenuInfra.superAppPanel
        superApp={this.getSuperApp()}
        selectedTabName={this.props.selectedTabName}
        panelClass="wix-store-panel"
        {...this.filteredProps()}
      />
    );
  },
});
