'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')

module.exports = {
    'type': addPanelDataConsts.SECTIONS_TYPES.PRESET,
    'hide': false,
    'title': 'add_section_media_player_transparent_label',
    'subNavigationTitle': 'add_section_media_player_transparent_label',
    'presetTitle': 'add_section_media_player_transparent_label',
    'tooltipTitle': 'add_section_media_player_transparent_label',
    "automationId": "add-panel-section-singleVideoAndAlphaSection",
    'subNavigationHide': false,
    'showSectionHeader': true,
    'additionalBehaviours': {
        'labelMode': addPanelDataConsts.LABEL_BEHAVIOUR.HOVER,
        'infoIcon': addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
        'hoverImageAction': addPanelDataConsts.HOVER_IMAGE_ACTIONS.VIDEO,
        'iconEnabledForComps': {}
    },
    'props': {
        'image': 'addPanelData/sections/videoSection_en/transparent-video_en.v1.png',
        'retinaImage': {
            'src': 'addPanelData/sections/videoSection_en/transparent-video_en-@2x.v1.png',
            'dimensions': {
                'width': 648,
                'height': 788
            }
        },
        'imageHover': null,
        'items': [
            {
                'id': 'Video_Video_0',
                'structure': {
                    "layout": {
                        "x": 0,
                        "y": 0,
                        "fixedPosition": false,
                        "width": 260,
                        "height": 422,
                        "scale": 1,
                        "rotationInDegrees": 0
                    },
                    "components": [
                        {
                            "type": "Component",
                            "componentType": "wysiwyg.viewer.components.MediaOverlayControls",
                            "skin": "skins.viewer.mediaOverlayControlsDefaultSkin",
                            "layout": {
                                "x": 90,
                                "y": 171,
                                "fixedPosition": false,
                                "width": 80,
                                "height": 80,
                                "scale": 1,
                                "rotationInDegrees": 0
                            },
                            "props": {
                                "type": "MediaOverlayControlsProperties",
                                "playerId": "comp-jl2exfvc",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false,
                                    "autoGenerated": false
                                }
                            },
                            "design": {
                                "type": "MediaControlsDesignData",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "iconsDefaultDesign": {
                                    "type": "VectorImageDesignData",
                                    "metaData": {
                                        "isPreset": false,
                                        "schemaVersion": "1.0",
                                        "isHidden": false
                                    },
                                    "shapeStyle": {
                                        "opacity": 1,
                                        "strokeOpacity": 1,
                                        "stroke": "#000000",
                                        "enableStroke": false,
                                        "strokeWidth": 1
                                    },
                                    "overrideColors": {
                                        "color1": "#FFFFFF",
                                        "color2": "#000000"
                                    }
                                },
                                "icons": [
                                    {
                                        "type": "ControlIconDesignData",
                                        "name": "playButton",
                                        "svgId": "0da768_513035bd10774dcabdf6af1add823662.svg",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        }
                                    }
                                ],
                                "charas": "design-jl2i49z1"
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "style": {
                                    "groups": {},
                                    "properties": {},
                                    "propertiesSource": {}
                                },
                                "styleType": "custom",
                                "skin": "skins.viewer.mediaOverlayControlsDefaultSkin",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                }
                            }
                        },
                        {
                            "type": "Component",
                            "componentType": "wysiwyg.viewer.components.MediaControls",
                            "skin": "skins.viewer.mediaControlsNoControlsSkin",
                            "layout": {
                                "x": 2,
                                "y": 376,
                                "fixedPosition": false,
                                "width": 256,
                                "height": 44,
                                "scale": 1,
                                "rotationInDegrees": 0
                            },
                            "props": {
                                "type": "MediaControlsProperties",
                                "showStoryboard": "time",
                                "playerId": "comp-jl2exfvc",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false,
                                    "autoGenerated": false
                                }
                            },
                            "design": {
                                "type": "MediaControlsDesignData",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "iconsDefaultDesign": {
                                    "type": "VectorImageDesignData",
                                    "metaData": {
                                        "isPreset": false,
                                        "schemaVersion": "1.0",
                                        "isHidden": false
                                    },
                                    "shapeStyle": {
                                        "opacity": 1,
                                        "strokeOpacity": 1,
                                        "stroke": "#000000",
                                        "enableStroke": false,
                                        "strokeWidth": 1
                                    },
                                    "overrideColors": {
                                        "color1": "#ffffff"
                                    }
                                },
                                "icons": [
                                    {
                                        "type": "ControlIconDesignData",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "name": "volumeOn",
                                        "svgId": "0da768_4026746ebab74627a513aa0df1511dea.svg"
                                    },
                                    {
                                        "type": "ControlIconDesignData",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "name": "volumeOff",
                                        "svgId": "0da768_c6d2d796452644a6a4a243c3afb7b781.svg"
                                    },
                                    {
                                        "type": "ControlIconDesignData",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "name": "noAudio",
                                        "svgId": "0da768_c6d2d796452644a6a4a243c3afb7b781.svg",
                                        "iconDesign": {
                                            "type": "VectorImageDesignData",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "overrideColors": {
                                                "color1": "#a9a9a9"
                                            }
                                        }
                                    }
                                ]
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "style": {
                                    "groups": {},
                                    "properties": {},
                                    "propertiesSource": {}
                                },
                                "styleType": "custom",
                                "skin": "skins.viewer.mediaControlsNoControlsSkin",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                }
                            }
                        }
                    ],
                    "componentType": "wysiwyg.viewer.components.MediaPlayer",
                    "type": "Container",
                    "skin": "wysiwyg.viewer.skins.mediaPlayerSkin",
                    "props": {
                        "autoplay": true,
                        "loop": true,
                        "disableAudio": false,
                        "animatePoster": "none",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false,
                            "autoGenerated": false
                        },
                        "playerInteraction": {
                            "click": "play",
                            "rollIn": "none",
                            "rollOut": "none",
                            "allowReplay": true
                        },
                        "mute": true,
                        "type": "MediaPlayerProperties",
                        "playerAudioInteraction": {
                            "rollIn": "none",
                            "rollOut": "none"
                        }
                    },
                    "design": {
                        "type": "MediaPlayerDesignData",
                        "background": {
                            "colorOverlay": "",
                            "mediaRef": {
                                "type": "WixVideo",
                                "title": "Golden Maneki Neko",
                                "videoId": "11062b_7d8c8fce8ff74e7fad6ade8148e96152",
                                "duration": 5.73,
                                "posterImageRef": {
                                    "type": "Image",
                                    "width": 1080,
                                    "height": 1760,
                                    "uri": "11062b_7d8c8fce8ff74e7fad6ade8148e96152f000.png",
                                    "description": "public/338f22d8-60f8-4470-8dd5-ed4d0e9bf3e1/78b950e1-c26a-4ab4-92c5-9bbc23a8525f/all/",
                                    "metaData": {
                                        "isPreset": false,
                                        "schemaVersion": "1.0",
                                        "isHidden": false
                                    },
                                    "title": "",
                                    "alt": "",
                                    "originalImageDataRef": null
                                },
                                "generatedPosters": [
                                    "11062b_7d8c8fce8ff74e7fad6ade8148e96152f000.png",
                                    "11062b_7d8c8fce8ff74e7fad6ade8148e96152f001.png",
                                    "11062b_7d8c8fce8ff74e7fad6ade8148e96152f002.png",
                                    "11062b_7d8c8fce8ff74e7fad6ade8148e96152f003.png"
                                ],
                                "qualities": [
                                    {
                                        "width": 296,
                                        "height": 480,
                                        "quality": "480p",
                                        "url": "video/11062b_7d8c8fce8ff74e7fad6ade8148e96152/480p/mp4/file.mp4"
                                    },
                                    {
                                        "width": 442,
                                        "height": 720,
                                        "quality": "720p",
                                        "url": "video/11062b_7d8c8fce8ff74e7fad6ade8148e96152/720p/mp4/file.mp4"
                                    },
                                    {
                                        "width": 1080,
                                        "height": 1760,
                                        "quality": "1080p",
                                        "url": "video/11062b_7d8c8fce8ff74e7fad6ade8148e96152/1080p/mp4/file.mp4"
                                    }
                                ],
                                "adaptiveVideo": [
                                    {
                                        "format": "hls",
                                        "url": "files/video/11062b_7d8c8fce8ff74e7fad6ade8148e96152/repackage/hls"
                                    }
                                ],
                                "artist": {
                                    "name": "",
                                    "id": ""
                                },
                                "hasAudio": false,
                                "fps": "60",
                                "mediaFeatures": [
                                    "alpha"
                                ],
                                "opacity": 1,
                                "preload": "auto",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                }
                            },
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "1.0",
                                "isHidden": false
                            },
                            "imageOverlay": null,
                            "fittingType": "fill",
                            "type": "BackgroundMedia",
                            "colorOverlayOpacity": 0,
                            "alignType": "center"
                        },
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "dataChangeBehaviors": [],
                        "cssStyle": {
                            "cssBorder": null,
                            "cssBorderRadius": null,
                            "cssBoxShadow": null
                        },
                        "charas": "design-jl2ix8kf"
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "style": {
                            "groups": {},
                            "properties": {},
                            "propertiesSource": {}
                        },
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.mediaPlayerSkin",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        }
                    },
                    "activeModes": {}
                },
                'preset': {
                    'rect': {'width': 162, 'height': 220, 'x': 0, 'y': 0},
                    'label': 'add_preset_media_player_transparent_1',
                    'tags': null
                }
            },
            {
                'id': 'Video_Video_1',
                'structure': {
                    "layout": {
                        "x": 0,
                        "y": 0,
                        "fixedPosition": false,
                        "width": 360,
                        "height": 468,
                        "scale": 1,
                        "rotationInDegrees": 0
                    },
                    "components": [
                        {
                            "type": "Component",
                            "componentType": "wysiwyg.viewer.components.MediaOverlayControls",
                            "skin": "skins.viewer.mediaOverlayControlsDefaultSkin",
                            "layout": {
                                "x": 155,
                                "y": 209,
                                "fixedPosition": false,
                                "width": 50,
                                "height": 50,
                                "scale": 1,
                                "rotationInDegrees": 0
                            },
                            "props": {
                                "type": "MediaOverlayControlsProperties",
                                "playerId": "comp-jl2exfvc",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false,
                                    "autoGenerated": false
                                }
                            },
                            "design": {
                                "type": "MediaControlsDesignData",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "iconsDefaultDesign": {
                                    "type": "VectorImageDesignData",
                                    "metaData": {
                                        "isPreset": false,
                                        "schemaVersion": "1.0",
                                        "isHidden": false
                                    },
                                    "shapeStyle": {
                                        "opacity": 1,
                                        "strokeOpacity": 1,
                                        "stroke": "#000000",
                                        "enableStroke": false,
                                        "strokeWidth": 1
                                    },
                                    "overrideColors": {
                                        "color1": "#FFFFFF",
                                        "color2": "#071F4E"
                                    }
                                },
                                "icons": [
                                    {
                                        "type": "ControlIconDesignData",
                                        "name": "playButton",
                                        "svgId": "0da768_152a8490c7444b949a51e30f2ac4d9a4.svg",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        }
                                    }
                                ],
                                "charas": "design-jl2i49z1"
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "style": {
                                    "groups": {},
                                    "properties": {},
                                    "propertiesSource": {}
                                },
                                "styleType": "custom",
                                "skin": "skins.viewer.mediaOverlayControlsDefaultSkin",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                }
                            }
                        },
                        {
                            "type": "Component",
                            "componentType": "wysiwyg.viewer.components.MediaControls",
                            "skin": "skins.viewer.mediaControlsNoControlsSkin",
                            "layout": {
                                "x": 2,
                                "y": 314,
                                "fixedPosition": false,
                                "width": 356,
                                "height": 44,
                                "scale": 1,
                                "rotationInDegrees": 0
                            },
                            "props": {
                                "type": "MediaControlsProperties",
                                "showStoryboard": "time",
                                "playerId": "comp-jl2exfvc",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false,
                                    "autoGenerated": false
                                }
                            },
                            "design": {
                                "type": "MediaControlsDesignData",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "iconsDefaultDesign": {
                                    "type": "VectorImageDesignData",
                                    "metaData": {
                                        "isPreset": false,
                                        "schemaVersion": "1.0",
                                        "isHidden": false
                                    },
                                    "shapeStyle": {
                                        "opacity": 1,
                                        "strokeOpacity": 1,
                                        "stroke": "#000000",
                                        "enableStroke": false,
                                        "strokeWidth": 1
                                    },
                                    "overrideColors": {
                                        "color1": "#ffffff"
                                    }
                                },
                                "icons": [
                                    {
                                        "type": "ControlIconDesignData",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "name": "volumeOn",
                                        "svgId": "0da768_4026746ebab74627a513aa0df1511dea.svg"
                                    },
                                    {
                                        "type": "ControlIconDesignData",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "name": "volumeOff",
                                        "svgId": "0da768_c6d2d796452644a6a4a243c3afb7b781.svg"
                                    },
                                    {
                                        "type": "ControlIconDesignData",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "name": "noAudio",
                                        "svgId": "0da768_c6d2d796452644a6a4a243c3afb7b781.svg",
                                        "iconDesign": {
                                            "type": "VectorImageDesignData",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "overrideColors": {
                                                "color1": "#a9a9a9"
                                            }
                                        }
                                    }
                                ]
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "style": {
                                    "groups": {},
                                    "properties": {},
                                    "propertiesSource": {}
                                },
                                "styleType": "custom",
                                "skin": "skins.viewer.mediaControlsNoControlsSkin",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                }
                            }
                        }
                    ],
                    "componentType": "wysiwyg.viewer.components.MediaPlayer",
                    "type": "Container",
                    "skin": "wysiwyg.viewer.skins.mediaPlayerSkin",
                    "props": {
                        "autoplay": false,
                        "loop": true,
                        "disableAudio": false,
                        "animatePoster": "none",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false,
                            "autoGenerated": false
                        },
                        "playerInteraction": {
                            "click": "toggle",
                            "rollIn": "none",
                            "rollOut": "none",
                            "allowReplay": true
                        },
                        "mute": true,
                        "type": "MediaPlayerProperties",
                        "playerAudioInteraction": {
                            "rollIn": "none",
                            "rollOut": "none"
                        }
                    },
                    "design": {
                        "type": "MediaPlayerDesignData",
                        "background": {
                            "colorOverlay": "",
                            "mediaRef": {
                                "type": "WixVideo",
                                "title": "Golden Snake Slithering",
                                "videoId": "11062b_e7c78b44470d42cc8dcdc6f51139fdb1",
                                "duration": 15.98,
                                "posterImageRef": {
                                    "type": "Image",
                                    "width": 1080,
                                    "height": 1440,
                                    "uri": "11062b_e7c78b44470d42cc8dcdc6f51139fdb1f000.png",
                                    "description": "public/338f22d8-60f8-4470-8dd5-ed4d0e9bf3e1/78b950e1-c26a-4ab4-92c5-9bbc23a8525f/all/",
                                    "metaData": {
                                        "isPreset": false,
                                        "schemaVersion": "1.0",
                                        "isHidden": false
                                    },
                                    "title": "",
                                    "alt": "",
                                    "originalImageDataRef": null
                                },
                                "generatedPosters": [
                                    "11062b_e7c78b44470d42cc8dcdc6f51139fdb1f000.png",
                                    "11062b_e7c78b44470d42cc8dcdc6f51139fdb1f001.png",
                                    "11062b_e7c78b44470d42cc8dcdc6f51139fdb1f002.png",
                                    "11062b_e7c78b44470d42cc8dcdc6f51139fdb1f003.png"
                                ],
                                "qualities": [
                                    {
                                        "width": 360,
                                        "height": 480,
                                        "quality": "480p",
                                        "url": "video/11062b_e7c78b44470d42cc8dcdc6f51139fdb1/480p/mp4/file.mp4"
                                    },
                                    {
                                        "width": 540,
                                        "height": 720,
                                        "quality": "720p",
                                        "url": "video/11062b_e7c78b44470d42cc8dcdc6f51139fdb1/720p/mp4/file.mp4"
                                    },
                                    {
                                        "width": 1080,
                                        "height": 1440,
                                        "quality": "1080p",
                                        "url": "video/11062b_e7c78b44470d42cc8dcdc6f51139fdb1/1080p/mp4/file.mp4"
                                    }
                                ],
                                "adaptiveVideo": [
                                    {
                                        "format": "hls",
                                        "url": "files/video/11062b_e7c78b44470d42cc8dcdc6f51139fdb1/repackage/hls"
                                    }
                                ],
                                "artist": {
                                    "name": "",
                                    "id": ""
                                },
                                "hasAudio": false,
                                "fps": "60",
                                "mediaFeatures": [
                                    "alpha"
                                ],
                                "opacity": 1,
                                "preload": "auto",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                }
                            },
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "1.0",
                                "isHidden": false
                            },
                            "imageOverlay": null,
                            "fittingType": "fill",
                            "type": "BackgroundMedia",
                            "colorOverlayOpacity": 0,
                            "alignType": "center"
                        },
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "dataChangeBehaviors": [],
                        "cssStyle": {
                            "cssBorder": null,
                            "cssBorderRadius": null,
                            "cssBoxShadow": null
                        },
                        "charas": "design-jl2im33n"
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "style": {
                            "groups": {},
                            "properties": {},
                            "propertiesSource": {}
                        },
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.mediaPlayerSkin",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        }
                    },
                    "activeModes": {}
                },
                'preset': {
                    'rect': {'width': 162, 'height': 196, 'x': 162, 'y': 12},
                    'label': 'add_preset_media_player_transparent_2',
                    'tags': null
                }
            },
            {
                'id': 'Video_Video_2',
                'structure': {
                    "layout": {
                        "x": 0,
                        "y": 0,
                        "fixedPosition": false,
                        "width": 400,
                        "height": 392,
                        "scale": 1,
                        "rotationInDegrees": 0
                    },
                    "components": [
                        {
                            "type": "Component",
                            "componentType": "wysiwyg.viewer.components.MediaOverlayControls",
                            "skin": "skins.viewer.mediaOverlayControlsDefaultSkin",
                            "layout": {
                                "x": 150,
                                "y": 146,
                                "fixedPosition": false,
                                "width": 100,
                                "height": 100,
                                "scale": 1,
                                "rotationInDegrees": 0
                            },
                            "props": {
                                "type": "MediaOverlayControlsProperties",
                                "playerId": "comp-jl2exfvc",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false,
                                    "autoGenerated": false
                                }
                            },
                            "design": {
                                "type": "MediaControlsDesignData",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "iconsDefaultDesign": {
                                    "type": "VectorImageDesignData",
                                    "metaData": {
                                        "isPreset": false,
                                        "schemaVersion": "1.0",
                                        "isHidden": false
                                    },
                                    "shapeStyle": {
                                        "opacity": 1,
                                        "strokeOpacity": 1,
                                        "stroke": "#000000",
                                        "enableStroke": false,
                                        "strokeWidth": 1
                                    },
                                    "overrideColors": {
                                        "color1": "#414141"
                                    }
                                },
                                "icons": [
                                    {
                                        "type": "ControlIconDesignData",
                                        "name": "playButton",
                                        "svgId": "0da768_661bc0cafffa432db3753ad5d17e4f10.svg",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        }
                                    }
                                ],
                                "charas": "design-jl2i49z1"
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "style": {
                                    "groups": {},
                                    "properties": {},
                                    "propertiesSource": {}
                                },
                                "styleType": "custom",
                                "skin": "skins.viewer.mediaOverlayControlsDefaultSkin",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                }
                            }
                        },
                        {
                            "type": "Component",
                            "componentType": "wysiwyg.viewer.components.MediaControls",
                            "skin": "skins.viewer.mediaControlsNoControlsSkin",
                            "layout": {
                                "x": 2,
                                "y": 346,
                                "fixedPosition": false,
                                "width": 396,
                                "height": 44,
                                "scale": 1,
                                "rotationInDegrees": 0
                            },
                            "props": {
                                "type": "MediaControlsProperties",
                                "showStoryboard": "time",
                                "playerId": "comp-jl2exfvc",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false,
                                    "autoGenerated": false
                                }
                            },
                            "design": {
                                "type": "MediaControlsDesignData",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "iconsDefaultDesign": {
                                    "type": "VectorImageDesignData",
                                    "metaData": {
                                        "isPreset": false,
                                        "schemaVersion": "1.0",
                                        "isHidden": false
                                    },
                                    "shapeStyle": {
                                        "opacity": 1,
                                        "strokeOpacity": 1,
                                        "stroke": "#000000",
                                        "enableStroke": false,
                                        "strokeWidth": 1
                                    },
                                    "overrideColors": {
                                        "color1": "#ffffff"
                                    }
                                },
                                "icons": [
                                    {
                                        "type": "ControlIconDesignData",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "name": "volumeOn",
                                        "svgId": "0da768_4026746ebab74627a513aa0df1511dea.svg"
                                    },
                                    {
                                        "type": "ControlIconDesignData",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "name": "volumeOff",
                                        "svgId": "0da768_c6d2d796452644a6a4a243c3afb7b781.svg"
                                    },
                                    {
                                        "type": "ControlIconDesignData",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "name": "noAudio",
                                        "svgId": "0da768_c6d2d796452644a6a4a243c3afb7b781.svg",
                                        "iconDesign": {
                                            "type": "VectorImageDesignData",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "overrideColors": {
                                                "color1": "#a9a9a9"
                                            }
                                        }
                                    }
                                ]
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "style": {
                                    "groups": {},
                                    "properties": {},
                                    "propertiesSource": {}
                                },
                                "styleType": "custom",
                                "skin": "skins.viewer.mediaControlsNoControlsSkin",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                }
                            }
                        }
                    ],
                    "componentType": "wysiwyg.viewer.components.MediaPlayer",
                    "type": "Container",
                    "skin": "wysiwyg.viewer.skins.mediaPlayerSkin",
                    "props": {
                        "autoplay": true,
                        "loop": true,
                        "disableAudio": false,
                        "animatePoster": "none",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false,
                            "autoGenerated": false
                        },
                        "playerInteraction": {
                            "click": "play",
                            "rollIn": "none",
                            "rollOut": "none",
                            "allowReplay": true
                        },
                        "mute": true,
                        "type": "MediaPlayerProperties",
                        "playerAudioInteraction": {
                            "rollIn": "none",
                            "rollOut": "none"
                        }
                    },
                    "design": {
                        "type": "MediaPlayerDesignData",
                        "background": {
                            "colorOverlay": "",
                            "mediaRef": {
                                "type": "WixVideo",
                                "title": "3D Black & White Swirl",
                                "videoId": "11062b_9658846597a649c294062130b1086d6e",
                                "duration": 8,
                                "posterImageRef": {
                                    "type": "Image",
                                    "width": 1312,
                                    "height": 1280,
                                    "uri": "11062b_9658846597a649c294062130b1086d6ef000.png",
                                    "description": "public/338f22d8-60f8-4470-8dd5-ed4d0e9bf3e1/78b950e1-c26a-4ab4-92c5-9bbc23a8525f/all/",
                                    "metaData": {
                                        "isPreset": false,
                                        "schemaVersion": "1.0",
                                        "isHidden": false
                                    },
                                    "title": "",
                                    "alt": "",
                                    "originalImageDataRef": null
                                },
                                "generatedPosters": [
                                    "11062b_9658846597a649c294062130b1086d6ef000.png",
                                    "11062b_9658846597a649c294062130b1086d6ef001.png",
                                    "11062b_9658846597a649c294062130b1086d6ef002.png",
                                    "11062b_9658846597a649c294062130b1086d6ef003.png"
                                ],
                                "qualities": [
                                    {
                                        "width": 492,
                                        "height": 480,
                                        "quality": "480p",
                                        "url": "video/11062b_9658846597a649c294062130b1086d6e/480p/mp4/file.mp4"
                                    },
                                    {
                                        "width": 738,
                                        "height": 720,
                                        "quality": "720p",
                                        "url": "video/11062b_9658846597a649c294062130b1086d6e/720p/mp4/file.mp4"
                                    },
                                    {
                                        "width": 1312,
                                        "height": 1280,
                                        "quality": "1080p",
                                        "url": "video/11062b_9658846597a649c294062130b1086d6e/1080p/mp4/file.mp4"
                                    }
                                ],
                                "adaptiveVideo": [
                                    {
                                        "format": "hls",
                                        "url": "files/video/11062b_9658846597a649c294062130b1086d6e/repackage/hls"
                                    }
                                ],
                                "artist": {
                                    "name": "",
                                    "id": ""
                                },
                                "hasAudio": false,
                                "fps": "60",
                                "mediaFeatures": [
                                    "alpha"
                                ],
                                "opacity": 1,
                                "preload": "auto",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                }
                            },
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "1.0",
                                "isHidden": false
                            },
                            "imageOverlay": null,
                            "fittingType": "fill",
                            "type": "BackgroundMedia",
                            "colorOverlayOpacity": 0,
                            "alignType": "center"
                        },
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "dataChangeBehaviors": []
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "style": {
                            "groups": {},
                            "properties": {},
                            "propertiesSource": {}
                        },
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.mediaPlayerSkin",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "id": "style-jl2exfx8"
                    },
                    "activeModes": {}
                },
                'preset': {
                    'rect': {'width': 162, 'height': 174, 'x': 0, 'y': 220},
                    'label': 'add_preset_media_player_transparent_3',
                    'tags': null
                }
            },
            {
                'id': 'Video_Video_3',
                'structure': {
                    "layout": {
                        "x": 0,
                        "y": 0,
                        "fixedPosition": false,
                        "width": 400,
                        "height": 400,
                        "scale": 1,
                        "rotationInDegrees": 0
                    },
                    "components": [
                        {
                            "type": "Component",
                            "componentType": "wysiwyg.viewer.components.MediaOverlayControls",
                            "skin": "skins.viewer.mediaOverlayControlsDefaultSkin",
                            "layout": {
                                "x": 158,
                                "y": 158,
                                "fixedPosition": false,
                                "width": 84,
                                "height": 84,
                                "scale": 1,
                                "rotationInDegrees": 0
                            },
                            "props": {
                                "type": "MediaOverlayControlsProperties",
                                "playerId": "comp-jl2exfvc",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false,
                                    "autoGenerated": false
                                }
                            },
                            "design": {
                                "type": "MediaControlsDesignData",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "iconsDefaultDesign": {
                                    "type": "VectorImageDesignData",
                                    "metaData": {
                                        "isPreset": false,
                                        "schemaVersion": "1.0",
                                        "isHidden": false
                                    },
                                    "shapeStyle": {
                                        "opacity": 1,
                                        "strokeOpacity": 1,
                                        "stroke": "#000000",
                                        "enableStroke": false,
                                        "strokeWidth": 1
                                    },
                                    "overrideColors": {
                                        "color1": "#000000"
                                    }
                                },
                                "icons": [
                                    {
                                        "type": "ControlIconDesignData",
                                        "name": "playButton",
                                        "svgId": "0da768_70d326d6112c4597bee7759165a86dc2.svg",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        }
                                    }
                                ],
                                "charas": "design-jl2i49z1"
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "style": {
                                    "groups": {},
                                    "properties": {},
                                    "propertiesSource": {}
                                },
                                "styleType": "custom",
                                "skin": "skins.viewer.mediaOverlayControlsDefaultSkin",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                }
                            }
                        },
                        {
                            "type": "Component",
                            "componentType": "wysiwyg.viewer.components.MediaControls",
                            "skin": "skins.viewer.mediaControlsNoControlsSkin",
                            "layout": {
                                "x": 2,
                                "y": 354,
                                "fixedPosition": false,
                                "width": 396,
                                "height": 44,
                                "scale": 1,
                                "rotationInDegrees": 0
                            },
                            "props": {
                                "type": "MediaControlsProperties",
                                "showStoryboard": "time",
                                "playerId": "comp-jl2exfvc",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false,
                                    "autoGenerated": false
                                }
                            },
                            "design": {
                                "type": "MediaControlsDesignData",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                },
                                "iconsDefaultDesign": {
                                    "type": "VectorImageDesignData",
                                    "metaData": {
                                        "isPreset": false,
                                        "schemaVersion": "1.0",
                                        "isHidden": false
                                    },
                                    "shapeStyle": {
                                        "opacity": 1,
                                        "strokeOpacity": 1,
                                        "stroke": "#000000",
                                        "enableStroke": false,
                                        "strokeWidth": 1
                                    },
                                    "overrideColors": {
                                        "color1": "#ffffff"
                                    }
                                },
                                "icons": [
                                    {
                                        "type": "ControlIconDesignData",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "name": "volumeOn",
                                        "svgId": "0da768_4026746ebab74627a513aa0df1511dea.svg"
                                    },
                                    {
                                        "type": "ControlIconDesignData",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "name": "volumeOff",
                                        "svgId": "0da768_c6d2d796452644a6a4a243c3afb7b781.svg"
                                    },
                                    {
                                        "type": "ControlIconDesignData",
                                        "metaData": {
                                            "isPreset": false,
                                            "schemaVersion": "1.0",
                                            "isHidden": false
                                        },
                                        "name": "noAudio",
                                        "svgId": "0da768_c6d2d796452644a6a4a243c3afb7b781.svg",
                                        "iconDesign": {
                                            "type": "VectorImageDesignData",
                                            "metaData": {
                                                "isPreset": false,
                                                "schemaVersion": "1.0",
                                                "isHidden": false
                                            },
                                            "overrideColors": {
                                                "color1": "#a9a9a9"
                                            }
                                        }
                                    }
                                ]
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "style": {
                                    "groups": {},
                                    "properties": {},
                                    "propertiesSource": {}
                                },
                                "styleType": "custom",
                                "skin": "skins.viewer.mediaControlsNoControlsSkin",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                }
                            }
                        }
                    ],
                    "componentType": "wysiwyg.viewer.components.MediaPlayer",
                    "type": "Container",
                    "skin": "wysiwyg.viewer.skins.mediaPlayerSkin",
                    "props": {
                        "autoplay": true,
                        "loop": true,
                        "disableAudio": false,
                        "animatePoster": "none",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false,
                            "autoGenerated": false
                        },
                        "playerInteraction": {
                            "click": "play",
                            "rollIn": "none",
                            "rollOut": "none",
                            "allowReplay": true
                        },
                        "mute": true,
                        "type": "MediaPlayerProperties",
                        "playerAudioInteraction": {
                            "rollIn": "none",
                            "rollOut": "none"
                        }
                    },
                    "design": {
                        "type": "MediaPlayerDesignData",
                        "background": {
                            "colorOverlay": "",
                            "mediaRef": {
                                "type": "WixVideo",
                                "title": "Transforming Ring",
                                "videoId": "11062b_0508be67970e42edb6413509215e65ab",
                                "duration": 5.02,
                                "posterImageRef": {
                                    "type": "Image",
                                    "width": 1280,
                                    "height": 1280,
                                    "uri": "11062b_0508be67970e42edb6413509215e65abf000.png",
                                    "description": "public/338f22d8-60f8-4470-8dd5-ed4d0e9bf3e1/78b950e1-c26a-4ab4-92c5-9bbc23a8525f/all/",
                                    "metaData": {
                                        "isPreset": false,
                                        "schemaVersion": "1.0",
                                        "isHidden": false
                                    },
                                    "title": "",
                                    "alt": "",
                                    "originalImageDataRef": null
                                },
                                "generatedPosters": [
                                    "11062b_0508be67970e42edb6413509215e65abf000.png",
                                    "11062b_0508be67970e42edb6413509215e65abf001.png",
                                    "11062b_0508be67970e42edb6413509215e65abf002.png",
                                    "11062b_0508be67970e42edb6413509215e65abf003.png"
                                ],
                                "qualities": [
                                    {
                                        "width": 480,
                                        "height": 480,
                                        "quality": "480p",
                                        "url": "video/11062b_0508be67970e42edb6413509215e65ab/480p/mp4/file.mp4"
                                    },
                                    {
                                        "width": 720,
                                        "height": 720,
                                        "quality": "720p",
                                        "url": "video/11062b_0508be67970e42edb6413509215e65ab/720p/mp4/file.mp4"
                                    },
                                    {
                                        "width": 1280,
                                        "height": 1280,
                                        "quality": "1080p",
                                        "url": "video/11062b_0508be67970e42edb6413509215e65ab/1080p/mp4/file.mp4"
                                    }
                                ],
                                "adaptiveVideo": [
                                    {
                                        "format": "hls",
                                        "url": "files/video/11062b_0508be67970e42edb6413509215e65ab/repackage/hls"
                                    }
                                ],
                                "artist": {
                                    "name": "",
                                    "id": ""
                                },
                                "hasAudio": false,
                                "fps": "60",
                                "mediaFeatures": [
                                    "alpha"
                                ],
                                "opacity": 1,
                                "preload": "auto",
                                "metaData": {
                                    "isPreset": false,
                                    "schemaVersion": "1.0",
                                    "isHidden": false
                                }
                            },
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "1.0",
                                "isHidden": false
                            },
                            "imageOverlay": null,
                            "fittingType": "fill",
                            "type": "BackgroundMedia",
                            "colorOverlayOpacity": 0,
                            "alignType": "center"
                        },
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "dataChangeBehaviors": []
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "style": {
                            "groups": {},
                            "properties": {},
                            "propertiesSource": {}
                        },
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.mediaPlayerSkin",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        }
                    },
                    "activeModes": {}
                },
                'preset': {
                    'rect': {'width': 162, 'height': 174, 'x': 162, 'y': 220},
                    'label': 'add_preset_media_player_transparent_4',
                    'tags': null
                }
            }
        ],
        'compTypes': ['wysiwyg.viewer.components.MediaPlayer']
    },
    'help': {'hide': false, 'text': 'add_section_media_player_transparent_label_tooltip_title'}
}

