import React, { type FC } from 'react';
import { workspace, cx } from '@/util';
import { translate } from '@/i18n';
import { Divider, TextLabel, CustomScroll } from '@wix/wix-base-ui';
import { symbol as Symbol } from '@wix/santa-editor-symbols';
import { connectWithScope } from '@/apilib';
import styles from './addSectionCategories.scss';

import type { SectionCategoryDefinition } from '../../types';
import { AddPresetScope } from '../../addPresetApiEntry';

const isNewWorkspace = workspace.isNewWorkspaceEnabled();

const DATA_HOOKS = {
  ADD_BLANK_SECTION: 'add-blank-section',
};

interface AddSectionCategoriesOwnProps {
  selectedCategory: number;
  categories: SectionCategoryDefinition[];
  isCreateSectionWithAIEnabled: boolean;
  onCategoryChanged: (selectedCategory: number) => void;
  addBlankSectionStructure: () => Promise<void>;
}

interface AddSectionCategoriesStateProps {
  isAddBlankSectionVisible: boolean;
}

export interface AddSectionCategoriesProps
  extends AddSectionCategoriesStateProps,
    AddSectionCategoriesOwnProps {}

const AddSectionCategoriesComponent: FC<AddSectionCategoriesProps> = ({
  selectedCategory,
  categories,
  onCategoryChanged,
  isCreateSectionWithAIEnabled,
  addBlankSectionStructure,
  isAddBlankSectionVisible,
}) => {
  const categoryHeight = 100 / (categories.length + 2);

  return (
    <div className={styles.addSectionCategoriesWrapper}>
      <CustomScroll heightRelativeToParent="100%">
        <div
          data-aid="add-section-categories"
          className={cx(
            styles.addSectionCategories,
            styles.addSectionCategories_noScroll,
          )}
          data-selected-category={translate(categories[selectedCategory]?.name)}
        >
          {isAddBlankSectionVisible && (
            <>
              <div
                className={styles.addBlankSection}
                onClick={addBlankSectionStructure}
                data-hook={DATA_HOOKS.ADD_BLANK_SECTION}
              >
                <span className={styles.symbol}>
                  <Symbol name="plus7px" />
                </span>
                <TextLabel
                  className={styles.addBlankSectionLabel}
                  value={'add_section_blank_section_button'}
                />
              </div>
              <li className={styles.categoryDivider}>
                <Divider long={isCreateSectionWithAIEnabled} />
              </li>
            </>
          )}
          {categories.map((category, categoryIndex) => (
            <li
              key={category.id}
              style={getCategoryItemStyle(category, categoryHeight)}
              className={cx(getClassCategoryItem(category))}
            >
              {category.isDivider ? (
                <Divider key={category.id} />
              ) : (
                <span
                  key={`${category.id}_label`}
                  className={cx(
                    getClassCategoryNameWrapper(
                      categoryIndex,
                      selectedCategory,
                    ),
                  )}
                  onClick={() => onCategoryChanged(categoryIndex)}
                >
                  <TextLabel
                    type="T07"
                    value={category.name}
                    className={styles.categoryName}
                    shouldTranslate={true}
                  />
                  {category.icon && (
                    <Symbol
                      className={styles.categoryIcon}
                      name={category.icon}
                    />
                  )}
                </span>
              )}
            </li>
          ))}
        </div>
      </CustomScroll>
    </div>
  );
};

const getCategoryItemStyle = (
  category: SectionCategoryDefinition,
  categoryHeight: number,
) => {
  const margin = isNewWorkspace ? 0 : 2;
  return category.isDivider
    ? {}
    : {
        height: `calc(${categoryHeight}% - ${margin}px)`,
      };
};

const getClassCategoryItem = (category: SectionCategoryDefinition) => {
  return category.isDivider ? styles.categoryDivider : styles.categoryItem;
};

const getClassCategoryNameWrapper = (
  categoryIndex: number,
  selectedCategory: number,
) => {
  const isSelected = categoryIndex === selectedCategory;
  return isSelected
    ? styles.categoryNameWrapperSelected
    : styles.categoryNameWrapper;
};

const mapStateToProps = (
  scope: AddPresetScope,
): AddSectionCategoriesStateProps => {
  const { editorRestrictionsApi } = scope;
  const isAddBlankSectionVisible = editorRestrictionsApi.allowed(
    'add-section_add-blank-section.visible',
  );
  return {
    isAddBlankSectionVisible,
  };
};

const AddSectionCategories = connectWithScope(
  () => AddPresetScope,
  AddSectionCategoriesComponent,
  mapStateToProps,
  undefined,
);
export default AddSectionCategories;
