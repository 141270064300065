// @ts-nocheck
import React from 'react';
import { translate } from '@/i18n';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import * as core from '@/core';
import constants from '@/constants';
import * as symbols from '@wix/santa-editor-symbols';
import { PanelHeader } from '@wix/wix-base-ui';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'pageSettingsFrameNew',
  propTypes: {
    helpId: PropTypes.string,
    title: PropTypes.string,
    displayTitle: PropTypes.string,
    closeSettingsPanel: PropTypes.func.isRequired,
  },
  mixins: [core.mixins.editorAPIMixin],
  render() {
    return (
      <div className="page-settings-panel-pp">
        <div className="page-settings-panel-pp-inner">
          <PanelHeader
            onClose={() => {
              this.props.closeSettingsPanel('back');
            }}
            onHelp={(e) => {
              e.stopPropagation();
              this.openHelpPanel();
            }}
            closeSymbol={this.getCloseSymbol()}
            className="light"
          >
            <span>{this.getDisplayTitle()}</span>
          </PanelHeader>
          {this.props.children}
        </div>
      </div>
    );
  },
  openHelpPanel(helpId) {
    helpId = helpId || this.props.helpId;
    if (helpId) {
      const biHelpParams = {
        learn_more: false,
        panel_name: 'page_settings',
        origin: constants.BI.HELP.ORIGIN.PAGES,
      };
      this.getEditorAPI().panelManager.openHelpCenter(
        helpId,
        null,
        biHelpParams,
      );
    }
  },
  getCloseSymbol() {
    return React.createElement(symbols.symbol, { name: 'arrowLeft' });
  },
  getDisplayTitle() {
    return (
      this.props.displayTitle ||
      `${translate('Pages_Settings_Header_Text')} (${this.props.title})`
    );
  },
});
