import _ from 'lodash';
import * as stateManagement from '@/stateManagement';

import { upgradeTopBarHover } from '@wix/bi-logger-editor/v2';
import { biLogger } from '@/util';
import { EditorRestrictionsApiKey, WixCodeWorkspaceApiKey } from '@/apis';

import {
  dispatchEditorAPI,
  getSendBi,
} from '../../topBarComponent/topBarMapper';

import {
  mapStateToProps as mapStateToToolsMenuProps,
  mapDispatchToProps as mapDispatchToToolsMenuProps,
} from './toolsMenu/toolsMenuStructureMapper';

import {
  mapStateToProps as mapStateToHelpMenuProps,
  mapDispatchToProps as mapDispatchToHelpMenuProps,
} from './helpMenu/helpMenuStructureMapper';

import {
  mapStateToProps as mapStateToSettingsMenuProps,
  mapDispatchToProps as mapDispatchToSettingsMenuProps,
} from './settingsMenu/settingsMenuStructureMapper';

import {
  mapStateToProps as mapStateToSiteMenuProps,
  mapDispatchToProps as mapDispatchToSiteMenuProps,
} from './siteMenu/siteMenuStructureMapper';

import {
  mapStateToProps as mapStateToCodeMenuProps,
  mapDispatchToProps as mapDispatchToCodeMenuProps,
} from './codeMenu/codeMenuStructureMapper';

import type { MapDispatchToPropsFunction, MapStateToProps } from 'types/redux';
import type {
  MenuBarItemsOwnProps,
  MenuBarItemsStateProps,
  MenuBarItemsDispatchProps,
} from './menuStructure';
import type { UIResourceRestriction } from '@/editorRestrictions';

const mapStateToProps: MapStateToProps<
  MenuBarItemsStateProps,
  MenuBarItemsOwnProps
> = (...args) => {
  const [{ editorAPI, state }] = args;
  const overrideGetters =
    stateManagement.topBar.selectors.getOverrideGetters(state);

  return {
    ...mapStateToHelpMenuProps(...args),
    ...mapStateToToolsMenuProps(...args),
    ...mapStateToSiteMenuProps(...args),
    ...mapStateToSettingsMenuProps(...args),
    ...mapStateToCodeMenuProps(...args),
    isDeveloperModeEnabled: Boolean(editorAPI.developerMode.isEnabled()),
    isUserOwner: editorAPI.account.isUserOwner(),
    isInLocalDevMode: editorAPI.host
      .getAPI(WixCodeWorkspaceApiKey)
      .getIsInLocalDevMode(),
    isWixCodeProvisioned: Boolean(_.invoke(editorAPI, 'wixCode.isProvisioned')),
    hasNewReleasesNotification:
      stateManagement.newReleases.selectors.hasNotification(state),
    overriddenMenus: _.invoke(overrideGetters, 'getMenuOverrides', state),
    overriddenSiteMenu: _.invoke(
      overrideGetters,
      'getSiteMenuOverrides',
      state,
    ),
    overriddenSettingsMenu: _.invoke(
      overrideGetters,
      'getSettingsMenuOverrides',
      state,
    ),
    overriddenToolsMenu: _.invoke(
      overrideGetters,
      'getToolsMenuOverrides',
      state,
    ),
    overriddenHelpMenu: _.invoke(
      overrideGetters,
      'getHelpMenuOverrides',
      state,
    ),
    overriddenCodeMenu: _.invoke(
      overrideGetters,
      'getCodeMenuOverrides',
      state,
    ),
  };
};

const mapDispatchToProps: MapDispatchToPropsFunction<
  MenuBarItemsDispatchProps,
  MenuBarItemsOwnProps
> = (dispatch, ownProps) => ({
  ...mapDispatchToHelpMenuProps(dispatch, ownProps),
  ...mapDispatchToToolsMenuProps(dispatch, ownProps),
  ...mapDispatchToSiteMenuProps(dispatch, ownProps),
  ...mapDispatchToSettingsMenuProps(dispatch, ownProps),
  ...mapDispatchToCodeMenuProps(dispatch, ownProps),
  getFeedbackNewCommentsCounter: dispatchEditorAPI(dispatch, (editorAPI) => {
    const newCommentsCounter = editorAPI.feedback.getNewCommentsCounter();
    return newCommentsCounter > 99 ? '99+' : newCommentsCounter;
  }),
  toggleDeveloperMode: dispatchEditorAPI(dispatch, (editorAPI, options) =>
    editorAPI.developerMode.toggle(options),
  ),
  sendBi: getSendBi(dispatch),
  sendBIOnUpgradeHover: dispatchEditorAPI(
    dispatch,
    (editorAPI, hasSaleIndication: boolean) => {
      biLogger.report(
        upgradeTopBarHover({
          has_sale_indication: hasSaleIndication,
          is_sale: editorAPI.campaigns.personalSale.isActiveCampaign(),
        }),
      );
    },
  ),
  isAllowed: dispatchEditorAPI(
    dispatch,
    (editorAPI, uxResourceName: UIResourceRestriction) => {
      return editorAPI.host
        .getAPI(EditorRestrictionsApiKey)
        .allowed(uxResourceName);
    },
  ),
});

export { mapStateToProps, mapDispatchToProps };
