:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._fourthPublishSuccessPanel_wr1hl_7 {
  padding: 12px 30px 30px; }
  ._fourthPublishSuccessPanel__headingWrapper_wr1hl_9 {
    text-align: center;
    margin-bottom: 30px; }
  ._fourthPublishSuccessPanel__mainWrapper_wr1hl_12 > :not(:last-child) {
    margin-bottom: 12px; }
  ._fourthPublishSuccessPanel__linkPanel_wr1hl_14 > :first-child {
    width: 100%;
    display: inline-block; }
  ._fourthPublishSuccessPanel__textButtonContent_wr1hl_17 {
    background-color: #e7f0ff;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    height: 36px; }
  ._fourthPublishSuccessPanel__externalLink_wr1hl_25 {
    fill: #116dff; }
  ._fourthPublishSuccessPanel__actionItemWrapper_wr1hl_27 > :first-child {
    border: 1px solid #d6e6fe;
    border-radius: 8px; }
