:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._nextButton_1c19q_7 {
  background-color: #116dff;
  border-radius: 25px;
  color: #fff;
  padding: 9px 28px;
  min-width: 61px;
  cursor: pointer;
  display: inline;
  justify-content: flex-end;
  align-items: center;
  margin-left: 24px; }
  ._nextButton_1c19q_7 span {
    font-size: 18px;
    line-height: 1.5em;
    font-weight: 400;
    font-family: Madefor; }
  ._nextButton_1c19q_7 svg {
    margin-left: 10px; }
  ._nextButton_1c19q_7:hover {
    background-color: #5999ff; }

._nonPrimaryButtons_1c19q_28 span {
  font-size: 18px;
  color: #162d3d;
  font-family: Madefor; }

._nonPrimaryButtons_1c19q_28:hover {
  cursor: pointer; }

._nonPrimaryButtons_1c19q_28.disabled span {
  color: #939393;
  pointer-events: none; }

._disabled_1c19q_36 {
  background-color: #bcbcbc;
  pointer-events: none; }

._siteCreationBottomSection_1c19q_44 {
  height: 102px;
  padding: 0 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  box-shadow: 0 5px 30px 30px rgba(0, 0, 0, 0.03);
  bottom: 0;
  position: absolute;
  width: 100%;
  box-sizing: border-box; }
  @media only screen and (max-height: 900px) {
    ._siteCreationBottomSection_1c19q_44 {
      height: 82px;
      padding: 0 20px;
      box-shadow: 0 5px 20px 20px rgba(0, 0, 0, 0.03); } }

._preLoader_1c19q_61 {
  margin-right: 15px; }
  @media only screen and (min-height: 900px) {
    ._preLoader_1c19q_61 {
      width: 21px;
      height: 42px; } }
