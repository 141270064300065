import {
  EditorPermissionsApiKey,
  EditorRestrictionsApiKey,
  PanelManagerApiKey,
  WorkspaceModesApiKey,
} from '@/apis';
import { BaseEntryScope, createEntryPoint } from '@/apilib';
import { WorkspaceModes } from '@/workspaceModes';

export class TopBarScope extends BaseEntryScope {
  editorPermissionsApi = this.useDependency(EditorPermissionsApiKey);
  editorRestrictionsApi = this.useDependency(EditorRestrictionsApiKey);
  panelManagerApi = this.useDependency(PanelManagerApiKey);
  workspaceModesApi = this.useDependency(WorkspaceModesApiKey);
}

export type UIRestrictionTopBar =
  | 'top-bar_mobile-device-switch-edit.interactive'
  | 'top-bar_publish-rc.visible'
  | 'top-bar_workspace-modes-toggle.visible'
  | 'top-bar_help-home-button.visible'
  | 'top-bar_menu-settings.visible'
  | 'top-bar_menu-arena.visible'
  | 'top-bar_menu-site-feedback.interactive'
  | 'top-bar_menu-site-release-manager.interactive'
  | 'top-bar_menu-site-history.interactive'
  | 'top-bar_menu-site-duplicate.interactive'
  | 'top-bar_menu-site-transfer.interactive'
  | 'top-bar_menu-site-trash.interactive'
  | 'top-bar_menu-site-back-to-adi.interactive'
  | 'top-bar_menu-site-create-release-candidate.visible'
  | 'top-bar_menu-site-branches.interactive'
  | 'top-bar_menu-help-search.interactive'
  | 'top-bar_menu-help-adi-to-editor-tour.interactive'
  | 'top-bar_menu-help-home.interactive'
  | 'top-bar_menu-help-welcome-tour.interactive'
  | 'top-bar_menu-help-editor-new-users-tour.interactive'
  | 'top-bar_menu-help-videos.interactive'
  | 'top-bar_menu-settings-accessibility.visible'
  | 'top-bar_menu-help-search.visible'
  | 'top-bar_menu-help-welcome-tour.visible'
  | 'top-bar_menu-help-editor-new-users-tour.visible'
  | 'top-bar_menu-help-adi-to-editor-tour.visible'
  | 'top-bar_menu-help-editor-to-editor-tour.visible'
  | 'top-bar_menu-help-kb-shortcuts.visible'
  | 'top-bar_menu-help-new-releases.visible'
  | 'top-bar_menu-code.visible'
  | 'top-bar_zoom-out.visible'
  | 'top-bar_tools.visible'
  | 'top-bar_search.visible'
  | 'top-bar_menu-help-new-releases.interactive'
  | 'top-bar_publish.visible'
  | 'top-bar_test_site.visible';

const setRestrictions = (scope: TopBarScope) => {
  const { editorPermissionsApi, editorRestrictionsApi, workspaceModesApi } =
    scope;
  editorRestrictionsApi.set<UIRestrictionTopBar>(
    [
      'top-bar_mobile-device-switch-edit.interactive',
      'top-bar_publish-rc.visible',
      'top-bar_workspace-modes-toggle.visible',
      'top-bar_help-home-button.visible',
      'top-bar_menu-settings.visible',
      'top-bar_menu-arena.visible',
      'top-bar_menu-site-feedback.interactive',
      'top-bar_menu-site-release-manager.interactive',
      'top-bar_menu-site-history.interactive',
      'top-bar_menu-site-duplicate.interactive',
      'top-bar_menu-site-transfer.interactive',
      'top-bar_menu-site-trash.interactive',
      'top-bar_menu-site-back-to-adi.interactive',
      'top-bar_menu-site-create-release-candidate.visible',
      'top-bar_menu-site-branches.interactive',
      'top-bar_menu-help-search.interactive',
      'top-bar_menu-help-adi-to-editor-tour.interactive',
      'top-bar_menu-help-home.interactive',
      'top-bar_menu-help-welcome-tour.interactive',
      'top-bar_menu-help-editor-new-users-tour.interactive',
      'top-bar_menu-help-videos.interactive',
    ],
    () => editorPermissionsApi.has('CLASSIC-EDITOR.EDIT-DESIGN'),
  );
  editorRestrictionsApi.set<UIRestrictionTopBar>(
    [
      'top-bar_menu-settings-accessibility.visible',
      'top-bar_menu-help-search.visible',
      'top-bar_menu-help-kb-shortcuts.visible',
      'top-bar_menu-help-new-releases.visible',
    ],
    () => workspaceModesApi.isMode(WorkspaceModes.FULL),
  );
  editorRestrictionsApi.set<UIRestrictionTopBar>(
    [
      'top-bar_menu-code.visible',
      'top-bar_zoom-out.visible',
      'top-bar_tools.visible',
      'top-bar_search.visible',
      'top-bar_menu-help-new-releases.interactive',
      'top-bar_menu-help-editor-new-users-tour.visible',
      'top-bar_menu-help-adi-to-editor-tour.visible',
      'top-bar_menu-help-welcome-tour.visible',
      'top-bar_menu-help-editor-to-editor-tour.visible',
    ],
    () =>
      editorPermissionsApi.has('CLASSIC-EDITOR.EDIT-DESIGN') &&
      workspaceModesApi.isMode(WorkspaceModes.FULL),
  );
};

export const TopBarEntryPoint = createEntryPoint({
  name: 'TopBar',
  Scope: TopBarScope,
  publicApi() {},
  async initialize(scope: TopBarScope) {
    setRestrictions(scope);
  },
});
