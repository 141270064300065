import _ from 'lodash';
import { EditorAPIKey, BlocksApiKey } from '@/apis';
import * as stateManagement from '@/stateManagement';
import { fedopsLogger } from '@/util';
import type { Shell } from '@/apilib';

const selectionSelectors = stateManagement.selection.selectors;
const reportEventAction = stateManagement.bi.actions.event;

export function createSelectedComponentApi(shell: Shell) {
  const editorAPI = shell.getAPI(EditorAPIKey);

  return {
    sendSelectedComponentBI(
      eventDefinition: AnyFixMe,
      additionalParams: AnyFixMe,
    ) {
      const { store } = editorAPI;
      const editorState = store.getState();
      const selectedComps =
        selectionSelectors.getSelectedCompsRefs(editorState);
      if (eventDefinition && !_.isEmpty(selectedComps)) {
        const selectedComponentType =
          selectionSelectors.getSelectedComponentType(
            editorState,
            editorAPI.dsRead,
          );
        const parent =
          editorAPI.components.getAncestorRepeater(selectedComps[0]) ||
          editorAPI.components.getAncestors_DEPRECATED_BAD_PERFORMANCE(
            selectedComps[0],
          )?.[0];
        const blocksBiData = editorAPI.host
          .getAPI(BlocksApiKey)
          .getBlocksBiData();

        const params = {
          ...blocksBiData,
          component_id: _.head(selectedComps).id,
          component_type: selectedComponentType,
          parent_item_id: parent?.id,
        };

        if (additionalParams) {
          _.merge(params, additionalParams);
        }
        store.dispatch(reportEventAction(eventDefinition, params));
      }
    },

    copy() {
      return editorAPI.copyPaste.copy(
        editorAPI.selection.getSelectedComponents(),
      );
    },

    duplicate() {
      return editorAPI.copyPaste.duplicate(
        editorAPI.selection.getSelectedComponents(),
      );
    },

    cut() {
      const selectedComps = editorAPI.selection.getSelectedComponents();
      if (editorAPI.selection.isComponentSelected()) {
        editorAPI.components.cut(selectedComps);
      }
      fedopsLogger.interactionEnded(fedopsLogger.INTERACTIONS.CUT_COMPONENT);
      return selectedComps;
    },

    remove(origin?: string): Promise<boolean> {
      if (editorAPI.selection.isComponentSelected()) {
        const selectedComps = editorAPI.selection.getSelectedComponents();
        return editorAPI.components.remove(selectedComps, null, origin);
      }

      return Promise.resolve(false);
    },
  };
}
