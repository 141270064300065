// @ts-nocheck
import * as addPanel from '@/oldAddPanel';
export default {
  getCategorySections() {
    const addPanelVideoSection = addPanel.sections.getSections().video;

    if (addPanelVideoSection.videoBoxSection) {
      addPanelVideoSection.videoBoxSection =
        addPanel.dataUtil.mergeSectionVideos(
          addPanelVideoSection.videoBoxSection,
          addPanelVideoSection.videoBoxSectionVideos,
        );
    }
    if (addPanelVideoSection.videoBoxTransparentSection) {
      addPanelVideoSection.videoBoxTransparentSection =
        addPanel.dataUtil.mergeSectionVideos(
          addPanelVideoSection.videoBoxTransparentSection,
          addPanelVideoSection.videoBoxTransparentSectionVideos,
        );
    }
    if (addPanelVideoSection.singleVideoAndAlphaSection) {
      addPanelVideoSection.singleVideoAndAlphaSection =
        addPanel.dataUtil.mergeSectionVideos(
          addPanelVideoSection.singleVideoAndAlphaSection,
          addPanelVideoSection.singleVideoAndAlphaSectionVideos,
        );
    }
    if (addPanelVideoSection.videoBoxMaskedSection) {
      addPanelVideoSection.videoBoxMaskedSection =
        addPanel.dataUtil.mergeSectionVideos(
          addPanelVideoSection.videoBoxMaskedSection,
          addPanelVideoSection.videoBoxMaskedSectionVideos,
        );
    }

    return addPanel.dataUtil.mergeWithCommonSections(addPanelVideoSection, {});
  },
};
