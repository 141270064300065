// @ts-nocheck
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import constants from '@/constants';
import * as coreBi from '@/coreBi';
import * as stateManagement from '@/stateManagement';
import pageSettingsTabMixin from './pageSettingsTabMixin';

import React from 'react';
import * as baseUI from '@/baseUI';
import * as symbols from '@wix/santa-editor-symbols';
import { translate } from '@/i18n';
import { CustomScroll } from '@wix/wix-base-ui';

const { USER_SET_MOBILE_LANDING_PAGE } = constants.USER_PREFS.MOBILE_EDITOR;

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'tabLayoutNew',
  mixins: [pageSettingsTabMixin],
  propTypes: {
    menuItem: PropTypes.object.isRequired,
  },
  linkLandingPage() {
    const pageId = this.getPageId();
    const editorAPI = this.getEditorAPI();
    const state = editorAPI.store.getState();
    return {
      value: this.getValueFromPageData('isLandingPage'),
      requestChange(newValue) {
        const updatedData = { isLandingPage: newValue };
        const hasChangedMobilePage =
          stateManagement.userPreferences.selectors.getSiteUserPreferences(
            `${USER_SET_MOBILE_LANDING_PAGE}_${pageId}`,
          )(state);
        if (!hasChangedMobilePage) {
          updatedData.isMobileLandingPage = newValue;
        }
        editorAPI.pages.data.update(pageId, updatedData);
        editorAPI.history.add('page property change');

        editorAPI.bi.event(
          coreBi.events.pages.pagesPanel.layout_toggle_landing,
          {
            category: newValue ? 'no_header_and_footer' : 'standard',
          },
        );
      },
    };
  },
  openLearnMore() {
    const biHelpParams = {
      panel_name: 'tab_layout',
      origin: constants.BI.HELP.ORIGIN.PAGES,
      learn_more: true,
    };
    const helpId = '3ff92482-4a9e-48f1-af56-c7ed01571ad2';
    this.getEditorAPI().panelManager.openHelpCenter(helpId, null, biHelpParams);
  },
  render() {
    return (
      <CustomScroll>
        <div className="layout tab-inner">
          <baseUI.tabs.holder
            valueLink={this.linkLandingPage()}
            className="left radio"
          >
            <baseUI.tabs.header>
              <baseUI.tabs.label htmlFor={false}>
                <symbols.symbol name="layoutStandardSelected" />
                <symbols.symbol name="layoutStandardNotSelected" />
                <div>
                  <h3>{translate('Pages_Layouts_Standard_Title')}</h3>

                  <p>{translate('Pages_Layouts_Standard_Text')}</p>
                </div>
              </baseUI.tabs.label>
              <baseUI.tabs.label htmlFor={true}>
                <symbols.symbol name="layoutLandingSelected" />
                <symbols.symbol name="layoutLandingNotSelected" />
                <div>
                  <h3>{translate('Pages_Layouts_Landing_Title')}</h3>

                  <p>
                    {translate('Pages_Layouts_Landing_Text')}
                    <br />
                    <baseUI.button
                      label="Pages_Layouts_Landing_Learn"
                      onClick={this.openLearnMore}
                      className="btn-text"
                    />
                  </p>
                </div>
              </baseUI.tabs.label>
            </baseUI.tabs.header>
          </baseUI.tabs.holder>
        </div>
      </CustomScroll>
    );
  },
});
