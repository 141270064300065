import React from 'react';
import { Trans } from 'react-i18next';
import { Tooltip, TextButton } from '@wix/wix-base-ui';
import styles from './PageHierarchyTitleWithChildPages.scss';

interface PageHierarchyTitleWithChildPagesProps {
  translationKey: string;
  childPageTitles: string[];
}

interface PageHierarchyTitle {
  childPageTitlesList: string;
}

const PageHierarchyTitle: React.FC<PageHierarchyTitle> = ({
  childPageTitlesList,
  children,
}) => (
  <Tooltip content={childPageTitlesList} shouldTranslate={false}>
    <TextButton
      size="medium"
      weight="bold"
      underline="always"
      skin="dark"
      enableEllipsis={false}
      shouldTranslate={false}
    >
      {children}
    </TextButton>
  </Tooltip>
);

const PageHierarchyTitleWithChildPages: React.FC<
  PageHierarchyTitleWithChildPagesProps
> = (props) => {
  const { translationKey, childPageTitles } = props;
  const numberOfChildrens = Number(childPageTitles.length);
  const childPageTitlesList = childPageTitles.join(', ');
  return (
    <div className={styles.root}>
      <Trans i18nKey={translationKey} values={{ numberOfChildrens }}>
        <PageHierarchyTitle childPageTitlesList={childPageTitlesList} />
      </Trans>
    </div>
  );
};

export default PageHierarchyTitleWithChildPages;
