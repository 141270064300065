// @ts-nocheck
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import * as util from '@/util';
import { translate } from '@/i18n';
import linkToMixin from './linkToMixin';
import React from 'react';
import * as baseUI from '@/baseUI';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'linkTo',

  mixins: [linkToMixin],

  propTypes: {
    label: PropTypes.string,
    placeholder: PropTypes.string,
    showButton: PropTypes.bool,
    infoText: PropTypes.string,
    infoTitle: PropTypes.string,
  },
  getDefaultProps() {
    return {
      label: '',
      placeholder: 'Link_To_Control_Label_Placeholder',
      showButton: true,
    };
  },
  shouldShowButton() {
    return (
      this.props.showButton &&
      this.state.validationStatus === this.ValidationStatus.NONE
    );
  },
  shouldShowSuccessSymbol() {
    return this.state.validationStatus === this.ValidationStatus.SUCCESS;
  },
  getTranslatedLink() {
    let value = util.link.getLinkValueAsString(
      this.getEditorAPI(),
      util.valueLink.getValueFromProps(this.props),
    );
    value = value || translate(this.props.placeholder);
    return value;
  },
  handleClick() {
    this.props.onClickCallback?.(this.getTranslatedLink());
    this.openLinkDialogFromPanel();
  },
  render() {
    return (
      <baseUI.labelWithCircleButton
        key="labelWithCircleButton"
        isDisabled={this.isDisabled()}
        label={this.props.label}
        displayedValue={this.getTranslatedLink()}
        onClick={this.handleClick}
        buttonIcon="linkBtnThin"
        showButton={this.shouldShowButton()}
        showSuccessSymbol={this.shouldShowSuccessSymbol()}
        infoIconText={this.props.infoText}
        infoIconTitle={this.props.infoTitle}
        className="input-link-to"
      />
    );
  },
});
