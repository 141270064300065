// @ts-nocheck
import { PagesApiKey } from '@/apis';
import { fedopsLogger, scroll } from '@/util';
import * as stateManagement from '@/stateManagement';
import { t } from '@/i18n';
import constants from '@/constants';
import { convertPageIdToLinkPageId } from '../../utils';
import type { EditorAPI } from '@/editorAPI';
import type { Dispatch } from 'types/redux';
import type { EditorState } from '@/stateManagement';
import type { RouterSiteMapItem } from 'types/documentServices';
import { AddPageButton } from '@/pages';

const openPagesPanelInteraction = fedopsLogger.mapInteraction(
  fedopsLogger.INTERACTIONS.PAGES_PANEL.OPEN_PANEL,
);
const closePagesPanelInteraction = fedopsLogger.mapInteraction(
  fedopsLogger.INTERACTIONS.PAGES_PANEL.CLOSE_PANEL,
);

export const mapStateToProps = ({ editorAPI }: { editorAPI: EditorAPI }) => {
  return {
    currentPageId: editorAPI.dsRead.pages.getPrimaryPageId(),
    isMobileEditor: editorAPI.isMobileEditor(),
  };
};

const getEditorAPI = (
  _dispatch: Dispatch,
  _getState: () => EditorState,
  { editorAPI }: { editorAPI: EditorAPI },
) => editorAPI;

const getRouterSiteMap = (editorAPI: EditorAPI, routerId: string) =>
  new Promise((resolve) => {
    editorAPI.dsRead.routers.getRouterSiteMap(routerId, resolve);
  });

const getPageFromInnerRoute = (
  editorAPI: EditorAPI,
  routerId: string,
  innerRoute: string,
) =>
  new Promise((resolve) => {
    editorAPI.dsRead.routers.getPageFromInnerRoute(
      routerId,
      innerRoute,
      resolve,
    );
  });

export const mapDispatchToProps = (dispatch: Dispatch, ownProps: AnyFixMe) => ({
  async getPageFromInnerRoute(
    routerId: string,
    innerRoute: string,
  ): Promise<{
    pageId: string;
    innerRoute?: string;
    shouldDisableButton?: boolean;
  }> {
    const editorAPI: EditorAPI = dispatch(getEditorAPI);
    const pageFromInnerRoute =
      (await getPageFromInnerRoute(editorAPI, routerId, innerRoute)) ?? null;
    const routerSiteMap = ((await getRouterSiteMap(editorAPI, routerId)) ??
      []) as RouterSiteMapItem[];
    const routerData = editorAPI.dsRead.routers.get.byId(routerId);
    const routerPages =
      (routerData?.pages && Object.values(routerData?.pages)) ?? [];

    if (pageFromInnerRoute === null) {
      if (routerSiteMap?.length) {
        return {
          pageId: routerSiteMap[0].pageName,
          innerRoute: routerSiteMap[0].url,
        }; //return first InnerRoute if exits
      }
      if (routerPages.length) {
        return { pageId: routerPages[0], shouldDisableButton: true }; //return first connected page if exits
      }
    }
    return { pageId: pageFromInnerRoute as string }; // return parsed page from link
  },
  createDefaultData(id: string) {
    const editorAPI: EditorAPI = dispatch(getEditorAPI);

    return editorAPI.dsRead.data.createItem(id);
  },
  openAddPage() {
    openPagesPanelInteraction.start();
    dispatch(
      stateManagement.panels.actions.updateOrOpenPanel(
        constants.ROOT_COMPS.LEFTBAR.MENUS_AND_PAGES_PANEL_NAME,
        {
          openPanelInteraction: openPagesPanelInteraction,
          closePanelInteraction: closePagesPanelInteraction,
          frameType: constants.PANEL_TYPES.LEFT,
        },
        false,
      ),
    );
  },
  addPage(clickOrigin: AddPageButton, target: string) {
    const editorAPI: EditorAPI = dispatch(getEditorAPI);
    const pagesAPI = editorAPI.host.getAPI(PagesApiKey);
    const prevPageId = editorAPI.dsRead.pages.getCurrentPageId();
    const selectedComponentRef =
      stateManagement.selection.selectors.getSingleSelectedComponent(
        editorAPI.store.getState(),
      );

    pagesAPI.openAddPagePanel({
      origin: 'linkPanel',
      biCategory: 'CUSTOM_MAIN_MENU',
      clickOrigin,
      pageAddedCallback: (newPageId: string) => {
        const expectedTPAPageIds = [
          'Bookings',
          'Pricing Plans',
          'Blog',
          'Store',
        ]; // If its a tpa it returns a type rather then an id
        const notificationData = { message: '', type: '', linkText: '' };

        if (expectedTPAPageIds.includes(newPageId)) {
          notificationData.linkText = t('LINK_PANEL_COMMON_BANNER_LINK');
          notificationData.message = t(
            'LINK_PANEL_DYNAMIC_PAGE_ADDED_BANNER_TEXT',
            { DynamicType: newPageId },
          );
          notificationData.type = constants.NOTIFICATIONS.TYPES.INFO;
        } else {
          notificationData.linkText = t('LINK_PANEL_COMMON_BANNER_LINK');
          notificationData.message = t('LINK_PANEL_PAGE_ADDED_BANNER_TEXT');
          notificationData.type = constants.NOTIFICATIONS.TYPES.SUCCESS;
        }

        const linkPageId = convertPageIdToLinkPageId(newPageId);
        const routerId =
          editorAPI.dsRead.routers.getRouterDataForPageIfExist(newPageId)
            ?.routerId;

        if (routerId) {
          new Promise((resolve) => {
            editorAPI.dsRead.routers.getRouterInnerRoutes(
              routerId,
              newPageId,
              resolve,
            );
          }).then((innerRoutes: AnyFixMe) => {
            const innerRoute = innerRoutes?.[0]?.url;

            ownProps?.onPageAdded({
              target,
              routerId,
              innerRoute,
              type: 'DynamicPageLink',
              pageId: linkPageId,
            });
          });
        } else {
          ownProps?.onPageAdded({
            target,
            type: 'PageLink',
            pageId: linkPageId,
          });
        }

        dispatch(
          stateManagement.notifications.actions.showUserActionNotification({
            message: notificationData.message,
            title: null,
            type: notificationData.type,
            shouldTranslate: false,
            link: {
              caption: notificationData.linkText,
              onNotificationLinkClick: async () => {
                dispatch(stateManagement.panels.actions.closeOpenedPanels());

                await new Promise((resolve) =>
                  editorAPI.pages.navigateToAndScroll(
                    prevPageId,
                    selectedComponentRef?.id,
                    resolve,
                  ),
                );

                await scroll.waitForAnimationScrollEnd(() =>
                  editorAPI.site.getScroll(),
                );

                dispatch(
                  stateManagement.selection.actions.selectComponents([
                    selectedComponentRef,
                  ]),
                );
              },
            },
          }),
        );
      },
    });
  },
});
