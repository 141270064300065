import _ from 'lodash';
import * as core from '@/core';
import * as pagesMenu from '@/pagesMenu';

import * as wixStoreDataProvider from '../../../common/wixStoreDataProvider';

import type { PagesData } from 'types/documentServices';
import type { SendBiFunction } from 'types/bi';
import type { MapStateToProps, MapDispatchToProps } from 'types/redux';
import type { EditorAPI } from '@/editorAPI';

import type { WixStorePagesPanelOwnProps } from './wixStorePagesPanel';
import type {
  PageActionHandler,
  PageActionHandlerName,
  PagesMenuItem,
} from './wixStorePagesPanel.types';

const tpaMenuLogic = pagesMenu.specialPages?.tpa;

export interface WixStorePagesPanelStateProps {
  focusedPageId: string;
  isWixStoresInstalled: boolean;
  isDesktop: boolean;
}
export interface WixStorePagesPanelDispatchProps {
  sendBi: SendBiFunction;
  getManagingAppDefId: () => string;
  navigateToPage: (pageId: string) => void;
  isTPASpecialPage(pageData: PagesData): boolean;
  getPagesData: () => {
    pagesList: PagesMenuItem[];
    specialPagesStartingIndex: number;
  };
  getPageActionHandlers: (options: {
    openSettings: () => void;
  }) => Record<PageActionHandlerName, PageActionHandler>;
}

function setPageHiddenDependingOnViewMode(
  editorApi: EditorAPI,
  data: PagesMenuItem,
  value: boolean,
) {
  const isDesktop = editorApi.isDesktopEditor();

  if (isDesktop) {
    pagesMenu.pageMenuItemHelpers.setHidden(editorApi, data, value);
  } else {
    pagesMenu.pageMenuItemHelpers.setHidden(editorApi, data, undefined, value);
  }
}

export const mapStateToProps: MapStateToProps<
  WixStorePagesPanelStateProps,
  WixStorePagesPanelOwnProps
> = ({ editorAPI, dsRead }) => {
  return {
    focusedPageId: dsRead.pages.getFocusedPageId(),
    isWixStoresInstalled: wixStoreDataProvider.isAppInstalled(editorAPI),
    isDesktop: editorAPI.isDesktopEditor(),
  };
};

const getEditorAPI = (
  dispatch: AnyFixMe,
  getState: AnyFixMe,
  { editorAPI }: AnyFixMe,
): EditorAPI => editorAPI;

export const mapDispatchToProps: MapDispatchToProps<
  WixStorePagesPanelDispatchProps,
  WixStorePagesPanelOwnProps
> = (dispatch) => {
  const editorAPI: EditorAPI = dispatch(getEditorAPI);

  const getPagesData = () => {
    const pagesDataList = editorAPI.dsRead.pages.getPagesData();
    const sectionPagesDataList = tpaMenuLogic.getSectionPagesDataList(
      editorAPI,
      pagesDataList,
    );

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/filter
    const storeSectionPagesDataList = _.filter(
      sectionPagesDataList,
      _.partial(core.utils.wixStoreTypeUtils.isWixStore, editorAPI),
    );
    const specialPagesDataList = tpaMenuLogic.getSpecialPagesDataList(
      editorAPI,
      pagesDataList,
    );

    const resultPagesDataList = _(sectionPagesDataList)
      .union(specialPagesDataList)
      .filter(_.partial(core.utils.wixStoreTypeUtils.isWixStore, editorAPI))
      .filter((page) => !wixStoreDataProvider.isMembersPage(page))
      .value();

    const pagesList: PagesMenuItem[] =
      editorAPI.mainMenu.convertPagesDataListToMenuItemsList(
        resultPagesDataList,
      );
    const specialPagesStartingIndex = storeSectionPagesDataList.length;

    return {
      pagesList,
      specialPagesStartingIndex,
    };
  };

  const isTPASpecialPage = (pageData: PagesData): boolean =>
    core.utils.tpaTypeUtils.isTPASpecialPage(editorAPI.dsRead, pageData);

  const getManagingAppDefId = (): string => {
    const pagesDataList = editorAPI.dsRead.pages.getPagesData();
    return pagesDataList
      .filter(isTPASpecialPage)
      .find((page) => page.tpaPageId === 'product_page').managingAppDefId;
  };

  return {
    sendBi: editorAPI.bi.event,
    navigateToPage: editorAPI.pages.navigateTo,
    isTPASpecialPage,
    getPagesData,
    getManagingAppDefId,
    getPageActionHandlers: ({ openSettings }: { openSettings: () => void }) => {
      return {
        settings() {
          openSettings();
        },
        rename(pageMenuItem, additionalData) {
          pagesMenu.pageMenuItemHelpers.renamePage(
            editorAPI,
            pageMenuItem.pageData,
            additionalData,
          );
        },
        unhide: (pageMenuItem) =>
          setPageHiddenDependingOnViewMode(editorAPI, pageMenuItem, false),
        hide: (pageMenuItem) =>
          setPageHiddenDependingOnViewMode(editorAPI, pageMenuItem, true),
        moveup: (pageMenuItem) =>
          pagesMenu.pageMenuItemHelpers.moveUp(editorAPI, pageMenuItem),
        remove: (pageMenuItem) =>
          pagesMenu.pageMenuItemHelpers.removeMenuItem(editorAPI, pageMenuItem),
      };
    },
  };
};
