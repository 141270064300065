import type { EntryPoint } from '@/apilib';
import { EditorAPIKey, EditorCoreApiKey } from '@/apis';
import { AppsPanelSectionsApiKey } from '@/appsPanelSections';
import { translate } from '@/i18n';
import * as stateManagement from '@/stateManagement';
import experiments from 'experiment';
import { createPrivateAppsPanelApi } from './api/api';
import { PrivateAppsPanelApiKey } from './api/publicApiKey';
import PrivateAppsPanelContainer from './components/privateAppsPanelContainer/privateAppsPanelContainer';
import * as util from '@/util';
import { privateAppsPrivateAppsLeftBarClick } from '@wix/bi-logger-blocks-consumption/v2';
import {
  shouldShowUpdateAction,
  updateApp,
  getAllAvailableToUpdateApps,
  type UpdateAppOptions,
} from './utils/updateApp';
import type { AppData } from './components/privateAppsProvider/privateAppsProvider';
import { isEligible } from '@wix/ambassador-premium-features-manager-v1-feature/http';
import { HttpClient } from '@wix/http-client';
import constants from '@/constants';

const { getFreshAppsData } = stateManagement.platform.selectors;

const PrivateAppsEntryPoint: EntryPoint = {
  name: 'PrivateApps',
  getDependencyAPIs: () => [
    AppsPanelSectionsApiKey,
    EditorAPIKey,
    EditorCoreApiKey,
  ],
  declareAPIs: () => [PrivateAppsPanelApiKey],
  attach(shell) {
    shell.contributeAPI(PrivateAppsPanelApiKey, () =>
      createPrivateAppsPanelApi(shell),
    );
  },
  async extend(shell) {
    const appsPanelSectionsApi = shell.getAPI(AppsPanelSectionsApiKey);
    const editorAPI = shell.getAPI(EditorAPIKey);

    if (!experiments.isOpen('se_privateAppsPanel')) {
      editorAPI.store.dispatch(
        stateManagement.platform.actions.fetchAvailableApps,
      );
    }

    await shell.getAPI(EditorCoreApiKey).hooks._dsIsReady.promise;
    const httpClient = new HttpClient({
      getAppToken: () =>
        editorAPI.dsRead.platform.getAppDataByApplicationId(
          constants.APPLICATIONS.META_SITE_APPLICATION_ID,
        )?.instance || '',
    });

    const hasInstalledPrivateApp = () => {
      if (!editorAPI.dsRead) {
        return false;
      }

      const { getInstalledBlocksApps } = stateManagement.platform.selectors;
      const freshAppsData = getFreshAppsData(editorAPI.store.getState());
      const installedApps = getInstalledBlocksApps(editorAPI.dsRead).map(
        (app) => ({
          ...app,
          isPublished: freshAppsData.find(
            ({ appId }: { appId: string }) => appId === app.appDefinitionId,
          )?.isPublished,
        }),
      );

      return Boolean(installedApps.filter((app) => !app.isPublished).length);
    };

    const hasAvailableApps = () => {
      const availableApps = stateManagement.platform.selectors.getAvailableApps(
        editorAPI.store.getState(),
      );

      return availableApps?.length;
    };

    const isStudioAccount = async () => {
      const {
        data: { isEligible: isStudioAccount },
      } = await httpClient.request(
        isEligible({
          uniqueName: 'wix_studio',
          includeParentFeature: false,
        }),
      );

      return isStudioAccount;
    };

    const contributePrivateAppsIfNecessary = async () => {
      const syncPrivateAppsPredicate = () =>
        experiments.isOpen('se_privateAppsPanel') ||
        hasInstalledPrivateApp() ||
        hasAvailableApps();

      async function asyncPrivateAppsPredicate() {
        if (syncPrivateAppsPredicate()) {
          return true;
        }

        return await isStudioAccount();
      }

      const isEnabled = await asyncPrivateAppsPredicate();

      appsPanelSectionsApi.contributeSection(
        shell,
        {
          sectionId: 'private-apps',
          label: translate('AddApps__LeftPanel__PrivateApps'),
          headerText: translate('AddApps__LeftPanel__PrivateApps'),
          component: PrivateAppsPanelContainer,
          helpId: translate(
            'responsive-blocks-packages.Private_Apps_Help_Article',
          ),
          onClick: () => {
            util.biLogger.report(
              privateAppsPrivateAppsLeftBarClick({
                builderType: 'classic',
              }),
            );
          },
        },
        () => isEnabled || syncPrivateAppsPredicate(),
      );
    };

    void contributePrivateAppsIfNecessary();
  },
};

const components = { PrivateAppsPanel: PrivateAppsPanelContainer };

export { TEST_VERSION } from './utils/appDetailsUtils';

export {
  PrivateAppsEntryPoint,
  components,
  updateApp,
  shouldShowUpdateAction,
  getAllAvailableToUpdateApps,
};
export type { UpdateAppOptions, AppData };
