import React from 'react';
import _ from 'lodash';
import * as coreBi from '@/coreBi';
import DropPanelList from './dropPanelList';
import DropPanelIllustration from './dropPanelIllustration';
import { withExtensionSlotMenuItems } from './extensionSlot/withExtensionSlotMenuItems';

import type { SendBiFunction } from 'types/bi';

type TODOAny = any;

interface IllustratedListPanelProps {
  menuKey: string;
  automationId: string;
  isChecklist: boolean;
  closePanel: () => void;
  sendBi: SendBiFunction;
  items: TODOAny[];
  initialSelectedItem: string;
  hasDesignPermissions: boolean;
}

interface IllustratedListPanelState {
  selectedIndex: number;
}

const findItemIndexByKey = (
  items: AnyFixMe,
  key: AnyFixMe,
  defaultIndex = 0,
) => {
  // eslint-disable-next-line you-dont-need-lodash-underscore/find-index
  const index = _.findIndex(items, ['key', key]);
  return index === -1 ? defaultIndex : index;
};

class IllustratedListPanel extends React.Component<
  IllustratedListPanelProps,
  IllustratedListPanelState
> {
  static defaultProps = {
    isChecklist: false,
  };

  state: IllustratedListPanelState = {
    selectedIndex: findItemIndexByKey(
      this.props.items,
      this.props.initialSelectedItem,
    ),
  };

  onItemHover = (itemIndex: AnyFixMe) => {
    this.setState({ selectedIndex: itemIndex }, () => {
      this.props.sendBi(coreBi.events.topbar.top_bar_menu_item_hover, {
        menu_name: this.props.menuKey,
        menu_item_name: this.getSelectedItem().key,
      });
    });
  };

  getSelectedItem() {
    return this.props.items[this.state.selectedIndex];
  }

  render() {
    const selectedItem = this.getSelectedItem();

    return (
      <div data-aid={this.props.automationId} className="drop-panel-content">
        <DropPanelList
          items={this.props.items}
          selectedIndex={this.state.selectedIndex}
          onItemHover={this.onItemHover}
          isChecklist={this.props.isChecklist}
          closePanel={this.props.closePanel}
          hasDesignPermissions={this.props.hasDesignPermissions}
        />
        <DropPanelIllustration
          symbolName={selectedItem.symbolName}
          image={selectedItem.image}
          onClick={selectedItem.onClick}
          isDisabled={selectedItem.isDisabled}
          title={selectedItem.rightTitle}
          translatedTitle={selectedItem.translatedRightTitle}
          translatedDescription={selectedItem.translatedDescription}
          description={selectedItem.description}
          className={selectedItem.illustrationClassName}
          linkText={selectedItem.linkText}
          onLinkClick={selectedItem.onLinkClick}
          disableIllustrationClick={selectedItem.disableIllustrationClick}
        />
      </div>
    );
  }
}

export default withExtensionSlotMenuItems(IllustratedListPanel);
