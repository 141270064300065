import { silentConstants } from './silentInstallConstants';

type Translations =
  | typeof silentConstants.singleAppTranslation
  | typeof silentConstants.multipleAppsTranslation;

const getSilentStateDriver = () => {
  const installConfig: {
    isSingleApp: boolean;
    installedCounter: number;
    currentStep: number;
    stepsIntervalId: number;
    stepsPerApp: number;
    translations: Translations;
  } = {
    isSingleApp: false,
    installedCounter: 0,
    currentStep: 0,
    stepsIntervalId: null,
    stepsPerApp: 0,
    translations: null,
  };

  const getTranslationKey = (key: keyof Omit<Translations, 'steps'>) =>
    installConfig.translations[key];

  const getCurrentStepTranslationKey = () => {
    const cyclicStep = installConfig.currentStep % installConfig.stepsPerApp;
    let res = cyclicStep === 0 ? installConfig.stepsPerApp : cyclicStep;
    res--;
    return installConfig.translations.steps[res];
  };

  const getMaxStep = (appsToInstallLength: number) =>
    appsToInstallLength * installConfig.stepsPerApp + 1;

  const getCurrentStep = () => installConfig.currentStep;

  const setCurrentStep = (currentStep: number) => {
    installConfig.currentStep = currentStep;
  };

  const setStepsPerApp = (appDefIdsToSilentInstall: string[]) => {
    installConfig.isSingleApp = appDefIdsToSilentInstall.length === 1;
    installConfig.translations = installConfig.isSingleApp
      ? silentConstants.singleAppTranslation
      : silentConstants.multipleAppsTranslation;
    installConfig.stepsPerApp = installConfig.isSingleApp
      ? silentConstants.stepsPerSingleInstall
      : silentConstants.stepsPerMultipleInstall;
  };

  const getStepIntervalId = () => installConfig.stepsIntervalId;

  const setStepIntervalId = (intervalId: number) => {
    installConfig.stepsIntervalId = intervalId;
  };

  const getInstalledCounter = () => installConfig.installedCounter;

  const setInstalledCounter = (installedCounter: number) => {
    installConfig.installedCounter = installedCounter;
  };

  const getStepsPerApp = () => installConfig.stepsPerApp;

  const getIsSingleApp = () => installConfig.isSingleApp;

  const increaseInstallCounter = () => {
    installConfig.installedCounter++;
  };

  const increaseCurrentStep = () => {
    installConfig.currentStep++;
  };

  return {
    getTranslationKey,
    getCurrentStepTranslationKey,
    getMaxStep,
    getCurrentStep,
    setCurrentStep,
    increaseCurrentStep,
    getStepIntervalId,
    setStepIntervalId,
    getInstalledCounter,
    setInstalledCounter,
    increaseInstallCounter,
    getStepsPerApp,
    setStepsPerApp,
    getIsSingleApp,
  };
};

export { getSilentStateDriver };
