export default {
  type: 'Container',
  components: [
    {
      type: 'Component',
      skin: 'skins.viewer.VectorImageSkin',
      layout: {
        width: 31,
        height: 35,
        x: 33,
        y: 32,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.VectorImage',
      metaData: {
        pageId: 'x0jmy',
      },
      parent: 'comp-kgt643gn',
      data: {
        type: 'VectorImage',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'x0jmy',
        },
        alt: '',
        svgId: '11062b_21f206b8fa3b466e8ede2038d94720b2.svg',
        title: 'Social',
      },
      props: {
        type: 'VectorImageProperties',
        metaData: {
          schemaVersion: '1.0',
          autoGenerated: false,
          pageId: 'x0jmy',
        },
        displayMode: 'stretch',
        flip: 'none',
      },
      design: {
        type: 'VectorImageDesignData',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'x0jmy',
        },
        overrideColors: {
          color1: '#5C996E',
        },
        shapeStyle: {
          opacity: 1,
          strokeWidth: 4,
          stroke: '#5E97FF',
          strokeOpacity: 1,
          enableStroke: false,
        },
      },
      transitions: {
        type: 'TransitionData',
        'timing-function': 'ease-in-out',
        duration: 0.4,
        delay: 0,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'x0jmy',
        },
      },
      transformations: {
        type: 'TransformData',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'x0jmy',
        },
        origin: {
          x: {
            value: 50,
            type: 'percentage',
          },
          y: {
            value: 50,
            type: 'percentage',
          },
        },
      },
      scopedTransformations: {
        'variants-kgt643hh': {
          type: 'TransformData',
          scale: {
            x: 1.1,
            y: 1.1,
          },
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'x0jmy',
          },
        },
      },
      style: {
        type: 'ComponentStyle',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'x0jmy',
        },
        style: {
          properties: {},
          propertiesSource: {},
          groups: {},
        },
        componentClassName: 'wysiwyg.viewer.components.VectorImage',
        styleType: 'custom',
        skin: 'skins.viewer.VectorImageSkin',
      },
      mobileStructure: {
        layout: {
          width: 30,
          height: 34,
          x: 35,
          y: 33,
          scale: 1,
          rotationInDegrees: 0,
          fixedPosition: false,
        },
        metaData: {
          originalCompId: 'comp-kgt643hm1',
          author: 'studio',
        },
      },
    },
  ],
  skin: 'wysiwyg.viewer.skins.area.CircleArea',
  layout: {
    width: 100,
    height: 100,
    x: 440,
    y: 148,
    scale: 1,
    rotationInDegrees: 0,
    fixedPosition: false,
  },
  componentType: 'mobile.core.components.Container',
  metaData: {
    pageId: 'x0jmy',
  },
  parent: 'x0jmy',
  variants: {
    'variants-kgt643hh': {
      componentId: 'comp-kgt643gn',
      type: 'Hover',
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        pageId: 'x0jmy',
      },
    },
  },
  transitions: {
    type: 'TransitionData',
    'timing-function': 'ease-in-out',
    duration: 0.4,
    delay: 0,
    metaData: {
      isPreset: false,
      schemaVersion: '1.0',
      isHidden: false,
      pageId: 'x0jmy',
    },
  },
  scopedTransformations: {
    'variants-kgt643hh': {
      type: 'TransformData',
      zLayer: 'BELOW_PINNED',
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        pageId: 'x0jmy',
      },
    },
  },
  scopedStyles: {
    'variants-kgt643hh': {
      type: 'ComponentStyle',
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        pageId: 'x0jmy',
      },
      style: {
        properties: {
          'alpha-bg': '1',
          'alpha-brd': '1',
          bg: 'rgba(255,255,255,1)',
          'boxShadowToggleOn-shd': 'false',
          brd: '#72B084',
          brw: '3',
          shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
        },
        propertiesSource: {
          'alpha-bg': 'value',
          'alpha-brd': 'value',
          bg: 'value',
          'boxShadowToggleOn-shd': 'value',
          brd: 'value',
          brw: 'value',
          shd: 'value',
        },
        groups: {},
      },
      componentClassName: 'mobile.core.components.Container',
      pageId: '',
      compId: '',
      styleType: 'custom',
      skin: 'wysiwyg.viewer.skins.area.CircleArea',
    },
  },
  style: {
    type: 'ComponentStyle',
    metaData: {
      isPreset: false,
      schemaVersion: '1.0',
      isHidden: false,
      pageId: 'x0jmy',
    },
    style: {
      properties: {
        'alpha-bg': '1',
        'alpha-brd': '1',
        bg: 'rgba(201,225,208,1)',
        'boxShadowToggleOn-shd': 'false',
        brd: '#72B084',
        brw: '0',
        shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
      },
      propertiesSource: {
        'alpha-bg': 'value',
        'alpha-brd': 'value',
        bg: 'value',
        'boxShadowToggleOn-shd': 'value',
        brd: 'value',
        brw: 'value',
        shd: 'value',
      },
      groups: {},
    },
    componentClassName: 'mobile.core.components.Container',
    pageId: '',
    compId: '',
    styleType: 'custom',
    skin: 'wysiwyg.viewer.skins.area.CircleArea',
  },
  mobileStructure: {
    layout: {
      width: 100,
      height: 100,
      x: 110,
      y: 10,
      scale: 1,
      rotationInDegrees: 0,
      fixedPosition: false,
    },
    metaData: {
      originalCompId: 'comp-kgt643gn',
      author: 'studio',
    },
  },
  activeModes: {},
};
