'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')

const ITEM_WIDTH = 324 / 2
const ITEM_HEIGHT = 823 / 5

module.exports = {
    'type': addPanelDataConsts.SECTIONS_TYPES.PRESET,
    'hide': false,
    'title': 'TODO',
    'showSectionHeader': true,
    'additionalBehaviours': {
        'labelMode': addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
        'infoIcon': addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
        'hoverImageAction': addPanelDataConsts.HOVER_IMAGE_ACTIONS.NONE,
        'iconEnabledForComps': {}
    },
    'props': {
        'image': 'addPanelData/sections/quickActionBar_en/quickActionBarSection_en.v3.jpg',
        'imageHover': null,
        'items': [
            {
                id: 'QuickActionBar_Preset1',
                preset: {
                    rect: {width: ITEM_WIDTH, height: ITEM_HEIGHT, x: 0, y: 0},
                    label: 'preset-1'
                },
                structure: {
                    "type": "Container",
                    "layout": {
                        "width": 320,
                        "height": 47,
                        "x": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": true,
                        "docked": {
                            "bottom": {
                                "px": 0
                            }
                        }
                    },
                    "componentType": "wysiwyg.viewer.components.QuickActionBar",
                    "skin": "wysiwyg.viewer.skins.quickActionBar.anchoredSkin",
                    "components": [],
                    "props": {
                        "type": "QuickActionBarProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "colorScheme": "brand",
                        "invertColors": true,
                        "hideText": true,
                        "alignment": "right"
                    },
                    "style": {
                        "componentClassName": "wysiwyg.viewer.components.QuickActionBar",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "properties": {
                                "alpha-controlButtonBG": "1",
                                "alpha-controlButtonFG": "1",
                                "alpha-controlButtonBorderColor": "1",
                                "alpha-actionsBorderColor": "1",
                                "boxShadowToggleOn-actionsSHD": "false",
                                "boxShadowToggleOn-controlButtonSHD": "false",
                                "actionsBorderColor": "#ffffff",
                                "actionsBorderSize": "0",
                                "controlButtonBG": "#fff",
                                "controlButtonBorderColor": "#ffffff",
                                "controlButtonBorderSize": "0",
                                "controlButtonFG": "#4682b4",
                                "controlButtonSHD": "0px 0px 0px 0px rgba(0,0,0,0.0)",
                                "actionsSHD": "0px 0px 0px 0px rgba(0,0,0,0.0)",
                                "colorScheme": "brand inv"
                            },
                            "propertiesSource": {
                                "actionsBorderColor": "value",
                                "actionsBorderSize": "value",
                                "controlButtonBG": "value",
                                "controlButtonBorderColor": "value",
                                "controlButtonBorderSize": "value",
                                "controlButtonFG": "value",
                                "controlButtonSHD": "value",
                                "colorScheme": "value",
                                "actionsSHD": "value"
                            },
                            "groups": {}
                        },
                        "type": "TopLevelStyle",
                        "skin": "wysiwyg.viewer.skins.quickActionBar.anchoredSkin"
                    },
                    "activeModes": {}
                }
            },
            {
                id: 'QuickActionBar_Preset2',
                preset: {
                    rect: {width: ITEM_WIDTH, height: ITEM_HEIGHT, x: ITEM_WIDTH, y: 0},
                    label: 'preset-2'
                },
                structure: {
                    "type": "Container",
                    "layout": {
                        "width": 320,
                        "height": 47,
                        "x": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": true,
                        "docked": {
                            "bottom": {
                                "px": 0
                            }
                        }
                    },
                    "componentType": "wysiwyg.viewer.components.QuickActionBar",
                    "skin": "wysiwyg.viewer.skins.quickActionBar.anchoredSkin",
                    "components": [],
                    "props": {
                        "type": "QuickActionBarProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "colorScheme": "black",
                        "invertColors": false,
                        "hideText": true,
                        "alignment": "right"
                    },
                    "style": {
                        "componentClassName": "wysiwyg.viewer.components.QuickActionBar",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "properties": {
                                "alpha-controlButtonBG": "1",
                                "alpha-controlButtonFG": "1",
                                "alpha-controlButtonBorderColor": "1",
                                "alpha-actionsBorderColor": "1",
                                "boxShadowToggleOn-actionsSHD": "true",
                                "boxShadowToggleOn-controlButtonSHD": "false",
                                "actionsBorderColor": "#ffffff",
                                "actionsBorderSize": "0",
                                "controlButtonBG": "#fff",
                                "controlButtonBorderColor": "#ffffff",
                                "controlButtonBorderSize": "0",
                                "controlButtonFG": "#4682b4",
                                "controlButtonSHD": "0px 0px 0px 0px rgba(0,0,0,0.0)",
                                "actionsSHD": "0px 0px 20px 0px rgba(0,0,0,0.1)",
                                "colorScheme": "black"
                            },
                            "propertiesSource": {
                                "actionsBorderColor": "value",
                                "actionsBorderSize": "value",
                                "controlButtonBG": "value",
                                "controlButtonBorderColor": "value",
                                "controlButtonBorderSize": "value",
                                "controlButtonFG": "value",
                                "controlButtonSHD": "value",
                                "colorScheme": "value",
                                "actionsSHD": "value"
                            },
                            "groups": {}
                        },
                        "type": "TopLevelStyle",
                        "skin": "wysiwyg.viewer.skins.quickActionBar.anchoredSkin"
                    },
                    "activeModes": {}
                }
            },
            {
                id: 'QuickActionBar_Preset3',
                preset: {
                    rect: {width: ITEM_WIDTH, height: ITEM_HEIGHT, x: 0, y: ITEM_HEIGHT},
                    label: 'preset-3'
                },
                structure: {
                    "type": "Container",
                    "layout": {
                        "width": 298,
                        "height": 47,
                        "x": 12,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": true,
                        "docked": {
                            "bottom": {
                                "px": 12
                            }
                        }
                    },
                    "componentType": "wysiwyg.viewer.components.QuickActionBar",
                    "skin": "wysiwyg.viewer.skins.quickActionBar.rectSkin",
                    "components": [],
                    "props": {
                        "type": "QuickActionBarProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "colorScheme": "black",
                        "invertColors": true,
                        "hideText": true,
                        "alignment": "right"
                    },
                    "style": {
                        "componentClassName": "wysiwyg.viewer.components.QuickActionBar",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "properties": {
                                "alpha-controlButtonBG": "1",
                                "alpha-controlButtonFG": "1",
                                "alpha-controlButtonBorderColor": "1",
                                "alpha-actionsBorderColor": "1",
                                "boxShadowToggleOn-actionsSHD": "false",
                                "boxShadowToggleOn-controlButtonSHD": "false",
                                "actionsBorderColor": "#ffffff",
                                "actionsBorderSize": "0",
                                "controlButtonBG": "#fff",
                                "controlButtonBorderColor": "#ffffff",
                                "controlButtonBorderSize": "0",
                                "controlButtonFG": "#4682b4",
                                "controlButtonSHD": "0px 0px 0px 0px rgba(0,0,0,0.0)",
                                "actionsSHD": "0px 0px 0px 0px rgba(0,0,0,0.0)",
                                "colorScheme": "black inv"
                            },
                            "propertiesSource": {
                                "actionsBorderColor": "value",
                                "actionsBorderSize": "value",
                                "controlButtonBG": "value",
                                "controlButtonBorderColor": "value",
                                "controlButtonBorderSize": "value",
                                "controlButtonFG": "value",
                                "controlButtonSHD": "value",
                                "colorScheme": "value",
                                "actionsSHD": "value"
                            },
                            "groups": {}
                        },
                        "type": "TopLevelStyle",
                        "skin": "wysiwyg.viewer.skins.quickActionBar.rectSkin"
                    },
                    "activeModes": {}
                }
            },
            {
                id: 'QuickActionBar_Preset4',
                preset: {
                    rect: {width: ITEM_WIDTH, height: ITEM_HEIGHT, x: ITEM_WIDTH, y: ITEM_HEIGHT},
                    label: 'preset-4'
                },
                structure: {
                    "type": "Container",
                    "layout": {
                        "width": 50,
                        "height": 50,
                        "x": 254,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": true,
                        "docked": {
                            "bottom": {
                                "px": 16
                            }
                        }
                    },
                    "componentType": "wysiwyg.viewer.components.QuickActionBar",
                    "skin": "wysiwyg.viewer.skins.quickActionBar.floatingSkin",
                    "components": [],
                    "props": {
                        "type": "QuickActionBarProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "colorScheme": "brand",
                        "invertColors": true,
                        "hideText": false,
                        "alignment": "right"
                    },
                    "style": {
                        "componentClassName": "wysiwyg.viewer.components.QuickActionBar",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "properties": {
                                "alpha-controlButtonBG": "1",
                                "alpha-controlButtonFG": "1",
                                "alpha-controlButtonBorderColor": "1",
                                "alpha-actionsBorderColor": "1",
                                "boxShadowToggleOn-actionsSHD": "true",
                                "boxShadowToggleOn-controlButtonSHD": "true",
                                "actionsBorderColor": "#ffffff",
                                "actionsBorderSize": "0",
                                "controlButtonBG": "#008afc",
                                "controlButtonBorderColor": "#ffffff",
                                "controlButtonBorderSize": "0",
                                "controlButtonFG": "#ffffff",
                                "controlButtonSHD": "0px 2px 8px 0px rgba(0,0,0,0.3)",
                                "actionsSHD": "0px 2px 8px 0px rgba(0,0,0,0.3)",
                                "colorScheme": "brand inv"
                            },
                            "propertiesSource": {
                                "actionsBorderColor": "value",
                                "actionsBorderSize": "value",
                                "controlButtonBG": "value",
                                "controlButtonBorderColor": "value",
                                "controlButtonBorderSize": "value",
                                "controlButtonFG": "value",
                                "controlButtonSHD": "value",
                                "colorScheme": "value",
                                "actionsSHD": "value"
                            },
                            "groups": {}
                        },
                        "type": "TopLevelStyle",
                        "skin": "wysiwyg.viewer.skins.quickActionBar.floatingSkin"
                    },
                    "activeModes": {}
                }
            },
            {
                id: 'QuickActionBar_Preset5',
                preset: {
                    rect: {width: ITEM_WIDTH, height: ITEM_HEIGHT, x: 0, y: 2 * ITEM_HEIGHT},
                    label: 'preset-1'
                },
                structure: {
                    "type": "Container",
                    "layout": {
                        "width": 50,
                        "height": 50,
                        "x": 254,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": true,
                        "docked": {
                            "bottom": {
                                "px": 16
                            }
                        }
                    },
                    "componentType": "wysiwyg.viewer.components.QuickActionBar",
                    "skin": "wysiwyg.viewer.skins.quickActionBar.floatingSkin",
                    "components": [],
                    "props": {
                        "type": "QuickActionBarProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "colorScheme": "black",
                        "invertColors": false,
                        "hideText": false,
                        "alignment": "left"
                    },
                    "style": {
                        "componentClassName": "wysiwyg.viewer.components.QuickActionBar",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "properties": {
                                "alpha-controlButtonBG": "1",
                                "alpha-controlButtonFG": "1",
                                "alpha-controlButtonBorderColor": "1",
                                "alpha-actionsBorderColor": "1",
                                "boxShadowToggleOn-actionsSHD": "true",
                                "boxShadowToggleOn-controlButtonSHD": "true",
                                "actionsBorderColor": "#ffffff",
                                "actionsBorderSize": "0",
                                "controlButtonBG": "#fff",
                                "controlButtonBorderColor": "#ffffff",
                                "controlButtonBorderSize": "0",
                                "controlButtonFG": "#1d2d3c",
                                "controlButtonSHD": "0px 2px 8px 0px rgba(0,0,0,0.3)",
                                "actionsSHD": "0px 2px 8px 0px rgba(0,0,0,0.3)",
                                "colorScheme": "black"
                            },
                            "propertiesSource": {
                                "actionsBorderColor": "value",
                                "actionsBorderSize": "value",
                                "controlButtonBG": "value",
                                "controlButtonBorderColor": "value",
                                "controlButtonBorderSize": "value",
                                "controlButtonFG": "value",
                                "controlButtonSHD": "value",
                                "colorScheme": "value",
                                "actionsSHD": "value"
                            },
                            "groups": {}
                        },
                        "type": "TopLevelStyle",
                        "skin": "wysiwyg.viewer.skins.quickActionBar.floatingSkin"
                    },
                    "activeModes": {}
                }
            },
            {
                id: 'QuickActionBar_Preset6',
                preset: {
                    rect: {width: ITEM_WIDTH, height: ITEM_HEIGHT, x: ITEM_WIDTH, y: 2 * ITEM_HEIGHT},
                    label: 'preset-2'
                },
                structure: {
                    "type": "Container",
                    "layout": {
                        "width": 320,
                        "height": 47,
                        "x": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": true,
                        "docked": {
                            "bottom": {
                                "px": 0
                            }
                        }
                    },
                    "componentType": "wysiwyg.viewer.components.QuickActionBar",
                    "skin": "wysiwyg.viewer.skins.quickActionBar.anchoredSkin",
                    "components": [],
                    "props": {
                        "type": "QuickActionBarProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "colorScheme": "brand",
                        "invertColors": false,
                        "hideText": false,
                        "alignment": "right"
                    },
                    "style": {
                        "componentClassName": "wysiwyg.viewer.components.QuickActionBar",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "properties": {
                                "alpha-controlButtonBG": "1",
                                "alpha-controlButtonFG": "1",
                                "alpha-controlButtonBorderColor": "1",
                                "alpha-actionsBorderColor": "1",
                                "boxShadowToggleOn-actionsSHD": "true",
                                "boxShadowToggleOn-controlButtonSHD": "true",
                                "actionsBorderColor": "#ffffff",
                                "actionsBorderSize": "0",
                                "controlButtonBG": "#fff",
                                "controlButtonBorderColor": "#ffffff",
                                "controlButtonBorderSize": "0",
                                "controlButtonFG": "#4682b4",
                                "controlButtonSHD": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                "actionsSHD": "0px 0px 20px 0px rgba(0,0,0,0.1)",
                                "colorScheme": "brand"
                            },
                            "propertiesSource": {
                                "actionsBorderColor": "value",
                                "actionsBorderSize": "value",
                                "controlButtonBG": "value",
                                "controlButtonBorderColor": "value",
                                "controlButtonBorderSize": "value",
                                "controlButtonFG": "value",
                                "controlButtonSHD": "value",
                                "colorScheme": "value",
                                "actionsSHD": "value"
                            },
                            "groups": {}
                        },
                        "type": "TopLevelStyle",
                        "skin": "wysiwyg.viewer.skins.quickActionBar.anchoredSkin"
                    },
                    "activeModes": {}
                }
            },
            {
                id: 'QuickActionBar_Preset7',
                preset: {
                    rect: {width: ITEM_WIDTH, height: ITEM_HEIGHT, x: 0, y: 3 * ITEM_HEIGHT},
                    label: 'preset-3'
                },
                structure: {
                    "type": "Container",
                    "layout": {
                        "width": 210,
                        "height": 47,
                        "x": 55,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": true,
                        "docked": {
                            "bottom": {
                                "px": 12
                            }
                        }
                    },
                    "componentType": "wysiwyg.viewer.components.QuickActionBar",
                    "skin": "wysiwyg.viewer.skins.quickActionBar.ovalSkin",
                    "components": [],
                    "props": {
                        "type": "QuickActionBarProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "colorScheme": "brand",
                        "invertColors": true,
                        "hideText": true,
                        "alignment": "right"
                    },
                    "style": {
                        "componentClassName": "wysiwyg.viewer.components.QuickActionBar",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "properties": {
                                "alpha-controlButtonBG": "1",
                                "alpha-controlButtonFG": "1",
                                "alpha-controlButtonBorderColor": "1",
                                "alpha-actionsBorderColor": "1",
                                "boxShadowToggleOn-actionsSHD": "true",
                                "boxShadowToggleOn-controlButtonSHD": "true",
                                "actionsBorderColor": "#ffffff",
                                "actionsBorderSize": "0",
                                "controlButtonBG": "#fff",
                                "controlButtonBorderColor": "#ffffff",
                                "controlButtonBorderSize": "0",
                                "controlButtonFG": "#4682b4",
                                "controlButtonSHD": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                "actionsSHD": "0px 2px 10px 0px rgba(0,0,0,0.5)",
                                "colorScheme": "brand inv"
                            },
                            "propertiesSource": {
                                "actionsBorderColor": "value",
                                "actionsBorderSize": "value",
                                "controlButtonBG": "value",
                                "controlButtonBorderColor": "value",
                                "controlButtonBorderSize": "value",
                                "controlButtonFG": "value",
                                "controlButtonSHD": "value",
                                "colorScheme": "value",
                                "actionsSHD": "value"
                            },
                            "groups": {}
                        },
                        "type": "TopLevelStyle",
                        "skin": "wysiwyg.viewer.skins.quickActionBar.ovalSkin"
                    },
                    "activeModes": {}
                }
            },
            {
                id: 'QuickActionBar_Preset8',
                preset: {
                    rect: {width: ITEM_WIDTH, height: ITEM_HEIGHT, x: ITEM_WIDTH, y: 3 * ITEM_HEIGHT},
                    label: 'preset-4'
                },
                structure: {
                    "type": "Container",
                    "layout": {
                        "width": 50,
                        "height": 50,
                        "x": 254,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": true,
                        "docked": {
                            "bottom": {
                                "px": 16
                            }
                        }
                    },
                    "componentType": "wysiwyg.viewer.components.QuickActionBar",
                    "skin": "wysiwyg.viewer.skins.quickActionBar.floatingSkin",
                    "components": [],
                    "props": {
                        "type": "QuickActionBarProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "colorScheme": "black",
                        "invertColors": true,
                        "hideText": false,
                        "alignment": "right"
                    },
                    "style": {
                        "componentClassName": "wysiwyg.viewer.components.QuickActionBar",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "properties": {
                                "alpha-controlButtonBG": "1",
                                "alpha-controlButtonFG": "1",
                                "alpha-controlButtonBorderColor": "1",
                                "alpha-actionsBorderColor": "1",
                                "boxShadowToggleOn-actionsSHD": "true",
                                "boxShadowToggleOn-controlButtonSHD": "true",
                                "actionsBorderColor": "#ffffff",
                                "actionsBorderSize": "0",
                                "controlButtonBG": "#1d2d3c",
                                "controlButtonBorderColor": "#ffffff",
                                "controlButtonBorderSize": "0",
                                "controlButtonFG": "#ffffff",
                                "controlButtonSHD": "0px 2px 8px 0px rgba(0,0,0,0.3)",
                                "actionsSHD": "0px 2px 8px 0px rgba(0,0,0,0.3)",
                                "colorScheme": "black inv"
                            },
                            "propertiesSource": {
                                "actionsBorderColor": "value",
                                "actionsBorderSize": "value",
                                "controlButtonBG": "value",
                                "controlButtonBorderColor": "value",
                                "controlButtonBorderSize": "value",
                                "controlButtonFG": "value",
                                "controlButtonSHD": "value",
                                "colorScheme": "value",
                                "actionsSHD": "value"
                            },
                            "groups": {}
                        },
                        "type": "TopLevelStyle",
                        "skin": "wysiwyg.viewer.skins.quickActionBar.floatingSkin"
                    },
                    "activeModes": {}
                }
            },
            {
                id: 'QuickActionBar_Preset9',
                preset: {
                    rect: {width: ITEM_WIDTH, height: ITEM_HEIGHT, x: 0, y: 4 * ITEM_HEIGHT},
                    label: 'preset-3'
                },
                structure: {
                    "type": "Container",
                    "layout": {
                        "width": 298,
                        "height": 47,
                        "x": 12,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": true,
                        "docked": {
                            "bottom": {
                                "px": 12
                            }
                        }
                    },
                    "componentType": "wysiwyg.viewer.components.QuickActionBar",
                    "skin": "wysiwyg.viewer.skins.quickActionBar.rectSkin",
                    "components": [],
                    "props": {
                        "type": "QuickActionBarProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "colorScheme": "grey",
                        "invertColors": true,
                        "hideText": true,
                        "alignment": "right"
                    },
                    "style": {
                        "componentClassName": "wysiwyg.viewer.components.QuickActionBar",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "properties": {
                                "alpha-controlButtonBG": "1",
                                "alpha-controlButtonFG": "1",
                                "alpha-controlButtonBorderColor": "1",
                                "alpha-actionsBorderColor": "1",
                                "boxShadowToggleOn-actionsSHD": "false",
                                "boxShadowToggleOn-controlButtonSHD": "false",
                                "actionsBorderColor": "#ffffff",
                                "actionsBorderSize": "0",
                                "controlButtonBG": "#fff",
                                "controlButtonBorderColor": "#ffffff",
                                "controlButtonBorderSize": "0",
                                "controlButtonFG": "#4682b4",
                                "controlButtonSHD": "0px 0px 0px 0px rgba(0,0,0,0.0)",
                                "actionsSHD": "0px 0px 0px 0px rgba(0,0,0,0.0)",
                                "colorScheme": "grey inv"
                            },
                            "propertiesSource": {
                                "actionsBorderColor": "value",
                                "actionsBorderSize": "value",
                                "controlButtonBG": "value",
                                "controlButtonBorderColor": "value",
                                "controlButtonBorderSize": "value",
                                "controlButtonFG": "value",
                                "controlButtonSHD": "value",
                                "colorScheme": "value",
                                "actionsSHD": "value"
                            },
                            "groups": {}
                        },
                        "type": "TopLevelStyle",
                        "skin": "wysiwyg.viewer.skins.quickActionBar.rectSkin"
                    },
                    "activeModes": {}
                }
            },
            {
                id: 'QuickActionBar_Preset10',
                preset: {
                    rect: {width: ITEM_WIDTH, height: ITEM_HEIGHT, x: ITEM_WIDTH, y: 4 * ITEM_HEIGHT},
                    label: 'preset-4'
                },
                structure: {
                    "type": "Container",
                    "layout": {
                        "width": 210,
                        "height": 47,
                        "x": 55,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": true,
                        "docked": {
                            "bottom": {
                                "px": 12
                            }
                        }
                    },
                    "componentType": "wysiwyg.viewer.components.QuickActionBar",
                    "skin": "wysiwyg.viewer.skins.quickActionBar.ovalSkin",
                    "components": [],
                    "props": {
                        "type": "QuickActionBarProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "colorScheme": "brand",
                        "invertColors": false,
                        "hideText": true,
                        "alignment": "right"
                    },
                    "style": {
                        "componentClassName": "wysiwyg.viewer.components.QuickActionBar",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "properties": {
                                "alpha-controlButtonBG": "1",
                                "alpha-controlButtonFG": "1",
                                "alpha-controlButtonBorderColor": "1",
                                "alpha-actionsBorderColor": "1",
                                "boxShadowToggleOn-actionsSHD": "true",
                                "boxShadowToggleOn-controlButtonSHD": "true",
                                "actionsBorderColor": "#ffffff",
                                "actionsBorderSize": "0",
                                "controlButtonBG": "#fff",
                                "controlButtonBorderColor": "#ffffff",
                                "controlButtonBorderSize": "0",
                                "controlButtonFG": "#4682b4",
                                "controlButtonSHD": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                "actionsSHD": "0px 4px 10px 0px rgba(0,0,0,0.2)",
                                "colorScheme": "brand"
                            },
                            "propertiesSource": {
                                "actionsBorderColor": "value",
                                "actionsBorderSize": "value",
                                "controlButtonBG": "value",
                                "controlButtonBorderColor": "value",
                                "controlButtonBorderSize": "value",
                                "controlButtonFG": "value",
                                "controlButtonSHD": "value",
                                "colorScheme": "value",
                                "actionsSHD": "value"
                            },
                            "groups": {}
                        },
                        "type": "TopLevelStyle",
                        "skin": "wysiwyg.viewer.skins.quickActionBar.ovalSkin"
                    },
                    "activeModes": {}
                }
            }
        ],
        'compTypes': ['']
    }
}


