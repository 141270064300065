import { leftBar, panels } from '@/stateManagement';

import type { MapDispatchToProps, ThunkAction } from 'types/redux';
import type { OwnProps } from './leftPanelFrame';

export interface DispatchProps {
  closeOpenedLeftPanel: () => void;
  unhighlightMenu: () => void;
}

export const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = {
  closeOpenedLeftPanel: (): ThunkAction => (dispatch, getState) => {
    const openedLeftPanels = panels.selectors.selectOpenLeftPanels(getState());

    if (openedLeftPanels.length > 1) {
      dispatch(panels.actions.closePanelByName(openedLeftPanels[0].name));
    }
  },
  unhighlightMenu: (): ThunkAction => (dispatch) => {
    dispatch(leftBar.actions.unhighlightMenu());
  },
};
