export const ACTION_HOOK_PREFIX = `action-`;

type ActionHookPattern = `${typeof ACTION_HOOK_PREFIX}${string}`;

// This is only to achieve better TS typings
const createActionsMap = <T extends Record<any, ActionHookPattern>>(
  obj: T,
): Record<keyof T, ActionHookPattern> => {
  return obj;
};

export const AUTOMATION_IDS = {
  ACTION: createActionsMap({
    MOVE_UP: 'action-move-up',
    MOVE_DOWN: 'action-move-down',
    RENAME: 'action-rename',
    QUICK_EDIT: 'action-quick-edit',
    SWITCH_LAYOUT: 'action-switch-layout',
    SWITCH_LAYOUT_BRUTE_FORCE: 'action-switch-layout-brute-force',
    SWITCH_LAYOUT_COMPARISON: 'action-switch-layout-comarison',
    DUPLICATE: 'action-duplicate',
    REMOVE: 'action-remove',
    HIDE: 'action-hide',
    ACCESSIBILITY: 'action-a11y',
    SHOW_MORE: 'action-show-more',
    SETTINGS: 'action-settings',
    ANIMATION: 'action-animation',
    HELP: 'action-help',
    SCAN_SWITCH_LAYOUT_PRESET: 'action-scan-switch-layout-preset',
    DESIGNER_SCAN_PRESET: 'action-scan-designer-preset',
  }),
  ACTIONS_BAR: 'actions-bar',
};
