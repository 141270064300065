:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._collectionsListContainer_ncxj5_7 {
  height: 100%; }
  ._collectionsListContainer_ncxj5_7 ._collectionsList_ncxj5_7 {
    height: 100%;
    display: flex;
    flex-direction: column; }
  ._collectionsListContainer_ncxj5_7 ._sectionDivider_ncxj5_13 {
    border-bottom: 1px solid #dfe5eb; }
  ._collectionsListContainer_ncxj5_7 ._group-context-menu-symbol_ncxj5_15 path {
    fill: #868aa5; }
  ._collectionsListContainer_ncxj5_7 ._group-context-menu-symbol_ncxj5_15:hover path {
    fill: #116dff; }
  ._collectionsListContainer_ncxj5_7 ._videoSettingsContainer_ncxj5_19 {
    display: flex;
    justify-content: flex-end;
    align-items: center; }
  ._collectionsListContainer_ncxj5_7 ._videoTooltip_ncxj5_23 {
    margin: 1px 16px 0 0; }
  ._collectionsListContainer_ncxj5_7 ._contextMenuButton_ncxj5_25 {
    display: flex;
    align-items: center;
    justify-content: center; }

._contextActionIcon_ncxj5_30 {
  margin-right: 5px; }
