import React from 'react';
import { Heading } from '@wix/wix-base-ui';
import * as symbols from '@wix/santa-editor-symbols';
import { domainSuggestions } from '@/stateManagement';

import * as util from '@/util';
import * as panels from '@/panels';
import * as baseUI from '@/baseUI';
import * as coreBi from '@/coreBi';
import { translate } from '@/i18n';
import constants from '@/constants';
import { biLogger, translationUtils } from '@/util';
import { firstPublishDialogClick } from '@wix/bi-logger-editor/v2';
import experiment from 'experiment';

import type { EditorAPI } from '@/editorAPI';
import type { EditorState } from '@/stateManagement';

const { fetchDomainSuggestions } = domainSuggestions.actions;

interface ChooseDomainPanelComponentProps {
  panelName: string;
  defaultSiteName: string;
  freeDomainPrefix: string;
  freeDomainHandler: FunctionFixMe;
  connectDomainHandler: FunctionFixMe;
  shouldDisplayConnectDomainOption: boolean;
  overrideTitle?: string;
  overrideSubtitle?: string;
  overrideActionButtonLabel?: string;
  overrideFreeDomainRadioButtonLabel?: string; // For use in EditorX
  overrideFreeDomainPrefix?: string; // For use in EditorX
  overrideRadioButton2Label?: string; // For use in EditorX
  overrideRadioButton2Description?: string; // For use in EditorX
  isDomainConnected: boolean;
  isSitePublished: boolean;
  origin: string;
}

interface ValidationResult {
  success: boolean;
}

interface ConnectedChooseDomainPanelComponentProps {
  validateSiteName(siteName: string): ValidationResult;
  closePanel(): void;
  sendBI(descriptor: object, params: object): void;
  fetchDomainSuggestions: (name: string) => void;
}

enum DomainStates {
  FREE = 'free',
  BUY = 'buy',
}

enum FirstPublishConsts {
  RADIO_BUTTON = 'radio_button',
  PUBLISH_AND_CONTINUE = 'publish_and_continue',
  STEP = '1',
  GET_FREE_DOMAIN = 'get_free_domain',
  CONNECT_OWN_DOMAIN = 'connect_own_domain',
}

interface ChooseDomainPanelComponentState {
  siteName: string;
  sanitizedSiteName: string;
  domainChoice: DomainStates;
  isActionDisabled: boolean;
}

interface FooterProps {
  buttonClassName?: string;
}

const TRANSLATION_KEYS = {
  SAVE_CHOOSE_DOMAIN_TITLE_TITLE: 'SAVE_CHOOSE_DOMAIN_TITLE_TITLE',
  SAVE_CHOOSE_DOMAIN_TITLE_SUBTITLE: 'SAVE_CHOOSE_DOMAIN_TITLE_SUBTITLE',
  SAVE_CHOOSE_DOMAIN_BODY_RADIO_BUTTON_1:
    'SAVE_CHOOSE_DOMAIN_BODY_RADIO_BUTTON_1',
  SAVE_CHOOSE_DOMAIN_BODY_RADIO_BUTTON_2_LINE1:
    'SAVE_CHOOSE_DOMAIN_BODY_RADIO_BUTTON_2_LINE1',
  SAVE_CHOOSE_DOMAIN_BODY_RADIO_BUTTON_2_LINE2:
    'SAVE_CHOOSE_DOMAIN_BODY_RADIO_BUTTON_2_LINE2',
  SAVE_CHOOSE_DOMAIN_BUTTON_SAVENCONTINUE:
    'SAVE_CHOOSE_DOMAIN_BUTTON_SAVENCONTINUE',
};

const shouldReverseActionsOrder = experiment.isOpen(
  'se_reverseChooseDomainPanelOrder',
);

class ChooseDomainPanelComponent extends React.Component<
  ChooseDomainPanelComponentProps & ConnectedChooseDomainPanelComponentProps,
  ChooseDomainPanelComponentState
> {
  static displayName = 'chooseDomainPanel';

  constructor(
    props: ChooseDomainPanelComponentProps &
      ConnectedChooseDomainPanelComponentProps,
  ) {
    super(props);

    const { validateSiteName, defaultSiteName } = props;
    const validationResult = validateSiteName(defaultSiteName);

    this.state = {
      domainChoice: shouldReverseActionsOrder
        ? DomainStates.BUY
        : DomainStates.FREE,
      siteName: defaultSiteName,
      sanitizedSiteName: defaultSiteName,
      isActionDisabled: !validationResult.success,
    };
  }

  getTitle() {
    return translate(
      this.props.overrideTitle ||
        TRANSLATION_KEYS.SAVE_CHOOSE_DOMAIN_TITLE_TITLE,
    );
  }

  getSubtitle() {
    return translate(
      this.props.overrideSubtitle ||
        TRANSLATION_KEYS.SAVE_CHOOSE_DOMAIN_TITLE_SUBTITLE,
    );
  }

  getOptions() {
    const { freeDomainPrefix, validateSiteName } = this.props;
    const { siteName, domainChoice } = this.state;

    const overrideFreeDomainRadioButtonLabel =
      this.props.overrideFreeDomainRadioButtonLabel &&
      translationUtils.translateIfNeeded(
        this.props.overrideFreeDomainRadioButtonLabel,
      );

    return {
      [DomainStates.FREE]: {
        value: DomainStates.FREE,
        label:
          overrideFreeDomainRadioButtonLabel ||
          translate(TRANSLATION_KEYS.SAVE_CHOOSE_DOMAIN_BODY_RADIO_BUTTON_1),
        prefix: this.props.overrideFreeDomainPrefix || freeDomainPrefix,
        domain: siteName,
        isEditable: domainChoice === DomainStates.FREE,
        domainValidator: validateSiteName,
      },
      [DomainStates.BUY]: {
        value: DomainStates.BUY,
        label: translate(
          this.props.overrideRadioButton2Label ||
            TRANSLATION_KEYS.SAVE_CHOOSE_DOMAIN_BODY_RADIO_BUTTON_2_LINE1,
        ),
        domain: translate(
          this.props.overrideRadioButton2Description ||
            TRANSLATION_KEYS.SAVE_CHOOSE_DOMAIN_BODY_RADIO_BUTTON_2_LINE2,
        ),
        isEditable: false,
      },
    };
  }

  getNextDomainOptionValue() {
    return this.state.domainChoice === DomainStates.FREE
      ? DomainStates.BUY
      : DomainStates.FREE;
  }

  getActionButtonLabel = () => {
    return translate(
      this.props.overrideActionButtonLabel ||
        TRANSLATION_KEYS.SAVE_CHOOSE_DOMAIN_BUTTON_SAVENCONTINUE,
    );
  };

  handleDomainChanged = (domainChanged: {
    isValid: boolean;
    value: string;
    sanitizedValue: string;
  }) => {
    this.setState({
      isActionDisabled: !domainChanged.isValid,
      siteName: domainChanged.value,
      sanitizedSiteName: domainChanged.sanitizedValue,
    });
  };

  handleDomainOptionSelected = (optionValue: DomainStates) => {
    const { defaultSiteName, validateSiteName } = this.props;

    if (this.state.domainChoice === optionValue) {
      return;
    }

    this.setState({
      domainChoice: optionValue,
      ...(optionValue === DomainStates.BUY && {
        isActionDisabled: false,
        siteName: defaultSiteName,
        sanitizedSiteName: defaultSiteName,
      }),
      ...(optionValue === DomainStates.FREE && {
        isActionDisabled: !validateSiteName(defaultSiteName).success,
      }),
    });

    this.reportDomainClickedBI(optionValue);
  };

  handleAction = () => {
    const { freeDomainHandler, connectDomainHandler } = this.props;
    const { domainChoice, isActionDisabled, sanitizedSiteName } = this.state;

    this.reportActionButtonClickedBI(domainChoice);

    if (isActionDisabled) {
      throw new Error(
        'Should not get to handleAction if continue button is disabled',
      );
    }

    if (domainChoice === DomainStates.FREE) {
      freeDomainHandler(sanitizedSiteName);
      if (util.fakeBrowserUtils.isFakeBrowserEnabled() && sanitizedSiteName) {
        this.props.fetchDomainSuggestions(sanitizedSiteName);
      }
    } else if (domainChoice === DomainStates.BUY) {
      connectDomainHandler();
    }

    this.close();
  };

  getDomainOptionText(domainOption: DomainStates) {
    return domainOption === DomainStates.BUY
      ? 'connect your own domain'
      : 'free WIX domain';
  }

  reportActionButtonClickedBI(domainOption: DomainStates) {
    if (
      this.props.origin === constants.CONNECT_DOMAIN.FLOWS.FIRST_MANUAL_PUBLISH
    ) {
      const selectedValue =
        domainOption === DomainStates.FREE
          ? FirstPublishConsts.GET_FREE_DOMAIN
          : FirstPublishConsts.CONNECT_OWN_DOMAIN;

      biLogger.report(
        firstPublishDialogClick({
          target: FirstPublishConsts.PUBLISH_AND_CONTINUE,
          selectedValue,
          step: FirstPublishConsts.STEP,
        }),
      );
    } else {
      this.props.sendBI(
        coreBi.events.save.chooseDomainPanel.ACTION_BUTTON_CLICKED,
        { value_selected: this.getDomainOptionText(domainOption) },
      );
    }
  }

  reportDomainClickedBI(domainOption: DomainStates) {
    if (
      this.props.origin === constants.CONNECT_DOMAIN.FLOWS.FIRST_MANUAL_PUBLISH
    ) {
      const selectedValue =
        domainOption === DomainStates.FREE
          ? FirstPublishConsts.GET_FREE_DOMAIN
          : FirstPublishConsts.CONNECT_OWN_DOMAIN;

      biLogger.report(
        firstPublishDialogClick({
          target: FirstPublishConsts.RADIO_BUTTON,
          selectedValue,
          step: FirstPublishConsts.STEP,
        }),
      );
    } else {
      this.props.sendBI(
        coreBi.events.save.chooseDomainPanel.DOMAIN_OPTION_CLICKED,
        { selection: this.getDomainOptionText(domainOption) },
      );
    }
  }

  getUserStatus() {
    const publishStatus = this.props.isSitePublished
      ? 'published_'
      : 'not_published';
    const domainConnectionStatus = this.props.isDomainConnected
      ? 'connected'
      : 'not_connected';
    return `${publishStatus}_${domainConnectionStatus}`;
  }

  domainInputBiReporter = (biEventName: string) => {
    const biProps = {
      user_status: this.getUserStatus(),
      panel_name: this.props.panelName,
      save_or_publish: 'save',
      is_first_save_or_publish: 1,
    };
    this.props.sendBI(
      coreBi.events.domainInput[
        biEventName as keyof typeof coreBi.events.domainInput
      ],
      biProps,
    );
  };

  cancel = () => {
    if (this.props.origin === constants.CONNECT_DOMAIN.FLOWS.PUBLISH) {
      util.fedopsLogger.interactionEnded(
        util.fedopsLogger.INTERACTIONS.USER_PUBLISH_SITE,
      );
    }
    this.close();
  };

  close = () => {
    this.props.closePanel();
  };

  getKeyboardShortcuts = () => {
    return {
      enter: () => {
        if (!this.state.isActionDisabled) {
          this.handleAction();
        }
      },
      esc: () => {
        this.cancel();
      },
      tab: (event: KeyboardEvent) => {
        event.preventDefault();
        this.handleDomainOptionSelected(this.getNextDomainOptionValue());
      },
    };
  };

  handleValidityChange = (isValid: boolean) => {
    this.setState({
      isActionDisabled: !isValid,
    });
  };

  handleChange = (value: string) => {
    this.handleDomainChanged({
      isValid: true,
      sanitizedValue: value,
      value,
    });
  };

  logValidationResponseReceived = (params: object) => {
    this.props.sendBI(
      coreBi.events.domainInput.SITE_DOMAIN_VALIDATION_SUCCESS,
      {
        ...params,
        origin: 'first_save',
      },
    );
  };

  handleDomainClick = () => {
    this.domainInputBiReporter('DISABLED_DOMAIN_PART_CLICKED');
  };

  handleInputClick = () => {
    const options = this.getOptions();
    const freeOption = options[DomainStates.FREE];

    if (!freeOption.isEditable) {
      this.domainInputBiReporter('DISABLED_DOMAIN_PART_CLICKED');
    }
  };

  renderBody() {
    const options = this.getOptions();
    const freeOption = options[DomainStates.FREE];
    const connectOption = options[DomainStates.BUY];
    return shouldReverseActionsOrder ? (
      <div className="choose-domain-panel-options">
        {this.props.shouldDisplayConnectDomainOption ? (
          <label
            className={util.cx({
              'domain-option': true,
              checked: this.state.domainChoice === DomainStates.BUY,
            })}
          >
            <span className="domain-option-label">{connectOption.label}</span>
            <input
              type="radio"
              name="domain-options"
              value={connectOption.value}
              checked={this.state.domainChoice === connectOption.value}
              onChange={() => {
                this.handleDomainOptionSelected(connectOption.value);
              }}
              className="domain-option-radio-button"
            />
            <span />
            <span className="connect-domain-example">
              {connectOption.domain}
            </span>
          </label>
        ) : null}
        <label
          className={util.cx({
            'domain-option': true,
            checked: this.state.domainChoice === DomainStates.FREE,
          })}
        >
          <span className="domain-option-label">{freeOption.label}</span>
          <input
            type="radio"
            name="domain-options"
            value={freeOption.value}
            checked={this.state.domainChoice === freeOption.value}
            onChange={() => {
              this.handleDomainOptionSelected(freeOption.value);
            }}
            className="domain-option-radio-button"
          />
          <span />

          <div className="domain-input">
            <baseUI.DomainInput
              value={freeOption.domain}
              prefix={freeOption.prefix}
              isEditable={freeOption.isEditable}
              onValidityChange={this.handleValidityChange}
              onChange={this.handleChange}
              onDomainClick={this.handleDomainClick}
              onInputClick={this.handleInputClick}
              onValidationResponseReceived={this.logValidationResponseReceived}
            />
          </div>
        </label>
      </div>
    ) : (
      <div className="choose-domain-panel-options">
        <label
          className={util.cx({
            'domain-option': true,
            checked: this.state.domainChoice === DomainStates.FREE,
          })}
        >
          <span className="domain-option-label">{freeOption.label}</span>
          <input
            type="radio"
            name="domain-options"
            value={freeOption.value}
            checked={this.state.domainChoice === freeOption.value}
            onChange={() => {
              this.handleDomainOptionSelected(freeOption.value);
            }}
            className="domain-option-radio-button"
          />
          <span />

          <div className="domain-input">
            <baseUI.DomainInput
              value={freeOption.domain}
              prefix={freeOption.prefix}
              isEditable={freeOption.isEditable}
              onValidityChange={this.handleValidityChange}
              onChange={this.handleChange}
              onDomainClick={this.handleDomainClick}
              onInputClick={this.handleInputClick}
              onValidationResponseReceived={this.logValidationResponseReceived}
            />
          </div>
        </label>
        {this.props.shouldDisplayConnectDomainOption ? (
          <label
            className={util.cx({
              'domain-option': true,
              checked: this.state.domainChoice === DomainStates.BUY,
            })}
          >
            <span className="domain-option-label">{connectOption.label}</span>
            <input
              type="radio"
              name="domain-options"
              value={connectOption.value}
              checked={this.state.domainChoice === connectOption.value}
              onChange={() => {
                this.handleDomainOptionSelected(connectOption.value);
              }}
              className="domain-option-radio-button"
            />
            <span />
            <span className="connect-domain-example">
              {connectOption.domain}
            </span>
          </label>
        ) : null}
      </div>
    );
  }

  renderFooter(props: FooterProps = {}) {
    return (
      <baseUI.button
        shouldTranslate={false}
        label={this.getActionButtonLabel()}
        onClick={this.handleAction}
        disabled={this.state.isActionDisabled}
        className={util.cx(
          'choose-domain-action-button',
          props.buttonClassName,
        )}
      />
    );
  }

  renderNewWorkspacePanelFrame() {
    return (
      <panels.frames.CustomPanelFrame
        panelName={this.props.panelName}
        className="new-workspace-choose-domain-panel"
        dataHook="choose-domain-panel"
        footerContent={this.renderFooter({ buttonClassName: 'btn-md' })}
        onCloseButtonClick={this.cancel}
        onOuterClick={this.cancel}
        keyboardShortcuts={this.getKeyboardShortcuts()}
        removeContentPadding
      >
        <header className="choose-domain-header">
          <Heading shouldTranslate={false} appearance="h1" multiline>
            {this.getTitle()}
          </Heading>
          <Heading shouldTranslate={false} appearance="h4" multiline>
            {this.getSubtitle()}
          </Heading>
        </header>
        {this.renderBody()}
      </panels.frames.CustomPanelFrame>
    );
  }

  render() {
    if (util.workspace.isNewWorkspaceEnabled()) {
      return this.renderNewWorkspacePanelFrame();
    }

    return (
      <panels.frames.FocusPanelFrame
        panelName={this.props.panelName}
        shouldHideHeader={true}
        frameClassName="save-publish-panel-frame"
        keyboardShortcuts={this.getKeyboardShortcuts()}
        className="choose-domain-panel save-publish-panel"
      >
        <header className="save-publish-panel-header">
          <button onClick={this.cancel} className="close">
            <symbols.symbol name="headerCloseButton" />
          </button>
          <span className="title">{this.getTitle()}</span>
          <span className="subtitle">{this.getSubtitle()}</span>
        </header>
        {this.renderBody()}
        {this.renderFooter()}
      </panels.frames.FocusPanelFrame>
    );
  }
}

const getEditorAPI = (
  dispatch: FunctionFixMe,
  getState: () => EditorState,
  { editorAPI }: { editorAPI: EditorAPI },
) => editorAPI;

const mapDispatchToProps = (
  dispatch: FunctionFixMe,
  ownProps: ChooseDomainPanelComponentProps,
): ConnectedChooseDomainPanelComponentProps => {
  const editorAPI = dispatch(getEditorAPI);

  return {
    closePanel() {
      editorAPI.panelManager.closePanelByName(ownProps.panelName);
      editorAPI.savePublish.updatePublishEndStatus('IDLE');
    },
    validateSiteName(siteName: string): ValidationResult {
      return editorAPI.siteName.validate(siteName);
    },
    sendBI(descriptor: object, params: object) {
      editorAPI.bi.event(descriptor, params);
    },
    fetchDomainSuggestions: (name: string) => {
      editorAPI.store.dispatch(fetchDomainSuggestions(name));
    },
  };
};

const ChooseDomainPanel = util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  null,
  mapDispatchToProps,
)(ChooseDomainPanelComponent);

ChooseDomainPanel.pure = ChooseDomainPanelComponent;

export default ChooseDomainPanel;
