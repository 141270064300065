import type { EntryPoint } from '@/apilib';
import { EditorAPIKey } from '@/apis';
import { SiteApiKey } from './publicApiKey';
import { createSiteApi } from './siteWrapper';

export type SiteAPI = ReturnType<typeof createSiteApi>;

export const SiteEntrypoint: EntryPoint = {
  name: 'SiteApi',
  declareAPIs: () => [SiteApiKey],
  getDependencyAPIs: () => [EditorAPIKey],
  attach(shell) {
    shell.contributeAPI(SiteApiKey, () => createSiteApi(shell));
  },
};
