// @ts-nocheck
const helper = {
  /**
   * @param {ReactLink} link
   * @param {string} param
   * @returns {*}
   */
  getParam(link, param) {
    const object = link.value[param];
    if (object) {
      return JSON.parse(object.value);
    }
  },

  /**
   * @param {ReactLink} link
   * @param {string} param
   * @param {*} value
   */
  setParam(link, param, value) {
    const linkValue = link.value;
    let object = linkValue[param];
    if (!object) {
      object = { type: 'AppPartParam' };
    }
    object.value = JSON.stringify(value);
    linkValue[param] = object;
  },

  /**
   * @param {ReactLink} link
   * @param {string} param
   * @param {string} prop
   * @returns {*}
   */
  getParamProp(link, param, prop) {
    const object = helper.getParam(link, param);
    if (object) {
      return object[prop];
    }
  },

  /**
   * @param {ReactLink} link
   * @param {string} param
   * @param {string} prop
   * @param {*} value
   */
  setParamProp(link, param, prop, value) {
    let object = helper.getParam(link, param);
    if (!object) {
      object = {};
    }
    object[prop] = value;
    helper.setParam(link, param, object);
  },

  /**
   * @param {ReactLink} link
   * @param {string} param
   * @param {string} prop
   */
  removeParamProp(link, param, prop) {
    const object = helper.getParam(link, param);
    if (object) {
      delete object[prop];
      helper.setParam(link, param, object);
    }
  },

  /**
   * @param {ReactLink} link
   */
  update(link) {
    link.requestChange(link.value);
  },
};

export default helper;
