'use strict'

module.exports = {
    id: 'add_preset_popups_Contact_1',
    structure: {
        type: 'Page',
        id: 'yusqg',
        componentTypeForBI: 'wysiwyg.viewer.components.PopupContainer',
        components: [
            {
                type: 'Container',
                id: 'comp-im0lsb4n',
                components: [
                    {
                        type: 'Component',
                        id: 'comp-im0lz9qj',
                        skin: 'wysiwyg.viewer.skins.photo.NoSkinPhoto',
                        layout: {
                            width: 679,
                            height: 360,
                            x: 11,
                            y: 11,
                            scale: 1,
                            rotationInDegrees: 0,
                            fixedPosition: false
                        },
                        componentType: 'wysiwyg.viewer.components.WPhoto',
                        data: {
                            type: 'Image',
                            metaData: {isPreset: false, schemaVersion: '2.0', isHidden: false},
                            title: "Skater's Horizon",
                            uri: '8958596c192d4f8eb1fcd385d38f4246.jpg',
                            description: '',
                            width: 1920,
                            height: 1280,
                            alt: '',
                            crop: {x: 388, y: 228, width: 1262, height: 701},
                            id: 'dataItem-im0lz9qo'
                        },
                        props: {
                            type: 'WPhotoProperties',
                            metaData: {schemaVersion: '1.0'},
                            displayMode: 'fill',
                            onClickBehavior: 'goToLink',
                            effectName: 'kennedy',
                            id: 'propItem-im0lz9qq'
                        },
                        style: 'wp2'
                    },
                    {
                        type: 'Component',
                        id: 'comp-im0lsb5c',
                        skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
                        layout: {
                            width: 679,
                            height: 52,
                            x: 11,
                            y: 80,
                            scale: 1,
                            rotationInDegrees: 0,
                            fixedPosition: false
                        },
                        componentType: 'wysiwyg.viewer.components.WRichText',
                        data: {
                            type: 'StyledText',
                            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                            text: '<h2 style="font-size: 34px; text-align: center;" class="font_2"><span style="letter-spacing:0.1em;"><span style="color:#FFCD00;"><span style="font-size:34px;"><span style="font-family:lulo-clean-w01-one-bold,sans-serif;">WHAT\'S UP?</span></span></span></span></h2>',
                            stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
                            linkList: [],
                            id: 'dataItem-im0lsb5d'
                        },
                        props: {
                            type: 'WRichTextProperties',
                            metaData: {schemaVersion: '1.0'},
                            brightness: 1,
                            packed: true,
                            id: 'propItem-ipf4rapb'
                        },
                        style: 'txtNew'
                    },
                    {
                        type: 'Component',
                        id: 'comp-im0lsb5r',
                        skin: 'wysiwyg.viewer.skins.LinkBarNoBGSkin',
                        layout: {
                            width: 110,
                            height: 32,
                            x: 295,
                            y: 153,
                            scale: 1,
                            rotationInDegrees: 0,
                            fixedPosition: false
                        },
                        componentType: 'wysiwyg.viewer.components.LinkBar',
                        data: {
                            type: 'ImageList',
                            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                            items: [
                                {
                                    type: 'Image',
                                    metaData: {isPreset: false, schemaVersion: '2.0', isHidden: false},
                                    title: 'White Twitter Icon',
                                    uri: '01ab6619093f45388d66736ec22e5885.png',
                                    width: 200,
                                    height: 200,
                                    alt: '',
                                    link: {
                                        type: 'ExternalLink',
                                        metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                                        url: 'https://twitter.com/wix',
                                        target: '_blank'
                                    }
                                },
                                {
                                    type: 'Image',
                                    metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                                    title: 'White Instagram Icon',
                                    uri: '81af6121f84c41a5b4391d7d37fce12a.png',
                                    width: 200,
                                    height: 200,
                                    alt: '',
                                    link: {
                                        type: 'ExternalLink',
                                        metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                                        url: 'https://www.instagram.com/wix/',
                                        target: '_blank'
                                    }
                                },
                                {
                                    type: 'Image',
                                    metaData: {isPreset: false, schemaVersion: '2.0', isHidden: false},
                                    title: 'White Facebook Icon',
                                    uri: '23fd2a2be53141ed810f4d3dcdcd01fa.png',
                                    width: 200,
                                    height: 200,
                                    alt: '',
                                    link: {
                                        type: 'ExternalLink',
                                        metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                                        url: 'https://www.facebook.com/wix',
                                        target: '_blank'
                                    }
                                }
                            ],
                            id: 'dataItem-im0lsb5r1'
                        },
                        props: {
                            type: 'LinkBarProperties',
                            metaData: {schemaVersion: '1.0'},
                            gallery: 'social_icons',
                            iconSize: 32,
                            spacing: 7,
                            bgScale: 1,
                            orientation: 'HORIZ',
                            id: 'propItem-im0lsb5w'
                        },
                        style: 'lb1'
                    },
                    {
                        'type': 'Component',
                        'skin': 'svgshape.v2.Svg_001419cc721a440abc4ab12ed150b744',
                        'layout': {
                            'width': 30,
                            'height': 30,
                            'x': 650,
                            'y': 19,
                            'scale': 1,
                            'rotationInDegrees': 0,
                            'fixedPosition': false
                        },
                        'componentType': 'wysiwyg.viewer.components.PopupCloseIconButton',
                        'parent': 'comp-im0lsb4n',
                        'metaData': {
                            'pageId': 'yusqg'
                        },
                        'data': {
                            'type': 'SvgShape',
                            'metaData': {
                                'isPreset': false,
                                'schemaVersion': '1.0',
                                'isHidden': false,
                                'pageId': 'yusqg'
                            }
                        },
                        'props': {
                            'type': 'SvgShapeProperties',
                            'metaData': {
                                'schemaVersion': '1.0',
                                'pageId': 'yusqg'
                            },
                            'maintainAspectRatio': true
                        },
                        'style': {
                            'type': 'ComponentStyle',
                            'metaData': {
                                'isPreset': false,
                                'schemaVersion': '1.0',
                                'isHidden': false,
                                'pageId': 'yusqg'
                            },
                            'style': {
                                'properties': {
                                    'alpha-fillcolor': '1',
                                    'alpha-stroke': '1',
                                    'fillcolor': '#FFFFFF',
                                    'stroke': '#696969',
                                    'strokewidth': '1'
                                },
                                'propertiesSource': {
                                    'alpha-fillcolor': 'value',
                                    'alpha-stroke': 'value',
                                    'fillcolor': 'value',
                                    'stroke': 'value',
                                    'strokewidth': 'value'
                                },
                                'groups': {}
                            },
                            'componentClassName': 'wysiwyg.viewer.components.PopupCloseIconButton',
                            'pageId': '',
                            'compId': '',
                            'styleType': 'custom',
                            'skin': 'svgshape.v2.Svg_001419cc721a440abc4ab12ed150b744'
                        },
                        'connections': {
                            'type': 'ConnectionList',
                            'items': [
                                {
                                    'type': 'WixCodeConnectionItem',
                                    'role': 'button2'
                                }
                            ],
                            'metaData': {
                                'pageId': 'yusqg'
                            }
                        },
                        'mobileStructure': {
                            'layout': {
                                'width': 24,
                                'height': 24,
                                'x': 248,
                                'y': 30,
                                'scale': 1,
                                'rotationInDegrees': 0,
                                'fixedPosition': false
                            },
                            'metaData': {
                                'originalCompId': 'comp-isreaw1f',
                                'author': 'duplicate'
                            }
                        },
                        'activeModes': {}
                    }
                ],
                skin: 'wysiwyg.viewer.skins.stripContainer.DefaultStripContainer',
                layout: {
                    width: 701,
                    height: 382,
                    x: 139,
                    y: 198,
                    scale: 1,
                    rotationInDegrees: 0,
                    fixedPosition: false
                },
                componentType: 'wysiwyg.viewer.components.PopupContainer',
                props: {
                    type: 'PopupContainerProperties',
                    metaData: {schemaVersion: '1.0'},
                    horizontalAlignment: 'center',
                    verticalAlignment: 'center',
                    alignmentType: 'nineGrid',
                    horizontalOffset: 0,
                    verticalOffset: 0,
                    id: 'propItem-im0lsb4y'
                },
                design: {
                    type: 'MediaContainerDesignData',
                    metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                    background: {
                        type: 'BackgroundMedia',
                        metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                        color: '#FFFFFF',
                        colorOpacity: 1,
                        alignType: 'center',
                        fittingType: 'fill',
                        scrollType: 'none',
                        colorOverlay: '',
                        colorOverlayOpacity: 0
                    },
                    id: 'dataItem-im0lsb4y1'
                },
                style: {
                    type: 'TopLevelStyle',
                    metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                    style: {
                        groups: {},
                        properties: {'alpha-bg': '1', bg: 'rgba(255,255,255,1)'},
                        propertiesSource: {'alpha-bg': 'value', bg: 'value'}
                    },
                    componentClassName: 'wysiwyg.viewer.components.PopupContainer',
                    pageId: '',
                    compId: '',
                    styleType: 'custom',
                    skin: 'wysiwyg.viewer.skins.stripContainer.DefaultStripContainer',
                    id: 'style-im0lsb50'
                },
                behaviors: {
                    type: 'ObsoleteBehaviorsList',
                    items: '[{"name":"FadeIn","delay":0.1,"duration":0.75,"action":"screenIn","targetId":"","params":{}},{"name":"FadeOut","delay":0.1,"duration":0.75,"action":"exit","targetId":"","params":{}}]',
                    id: 'behavior-iomlwee6'
                }
            }
        ],

        skin: 'skins.core.InlineSkin',
        layout: {
            width: 980,
            height: 580,
            x: 0,
            y: 0,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false
        },
        componentType: 'mobile.core.components.Page',
        data: {
            type: 'Page',
            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
            title: 'add_preset_popups_Contact_1',
            hideTitle: true,
            icon: '',
            descriptionSEO: '',
            metaKeywordsSEO: '',
            pageTitleSEO: '',
            pageUriSEO: 'blank-yusqg',
            hidePage: true,
            underConstruction: false,
            tpaApplicationId: 0,
            pageSecurity: {requireLogin: false, passwordDigest: '', dialogLanguage: ''},
            isPopup: true,
            indexable: false,
            isLandingPage: false,
            pageBackgrounds: {
                desktop: {
                    ref: {
                        type: 'BackgroundMedia',
                        metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                        color: '#C3E0EF',
                        colorOpacity: 0.9,
                        alignType: 'center',
                        fittingType: 'fill',
                        scrollType: 'none',
                        colorOverlay: '',
                        colorOverlayOpacity: 0
                    }
                },
                mobile: {
                    custom: true,
                    ref: {
                        type: 'BackgroundMedia',
                        metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                        color: '#C3E0EF',
                        colorOpacity: 0.9,
                        alignType: 'center',
                        fittingType: 'fill',
                        scrollType: 'scroll',
                        colorOverlay: '',
                        colorOverlayOpacity: 0
                    },
                    isPreset: true
                }
            },
            ignoreBottomBottomAnchors: true
        },
        props: {
            type: 'PageProperties',
            metaData: {schemaVersion: '1.0'},
            desktop: {popup: {closeOnOverlayClick: true}},
            mobile: {popup: {closeOnOverlayClick: true}},
            id: 'yusqg'
        },
        style: 'p1'
    },
    preset: {
        rect: {width: 305, height: 202, x: 10, y: 464},
        label: 'add_preset_popups_Contact_1',
        tags: null
    }
}
