// @ts-nocheck
import _ from 'lodash';
import constants from '@/constants';
import * as stateManagement from '@/stateManagement';
import * as helpIds from '@/helpIds';
import { animations as animationsUtils } from '@/util';
import { AnimationsApiKey } from '@/apis';
import gfppDataUtils from '../../utils/gfppDataUtils';
import { EditorAPI } from '@/editorAPI';
import type {
  CompRef,
  PopupContainerProperties,
} from '@wix/document-services-types';

const { ACTIONS } = constants.ROOT_COMPS.GFPP;
const MOBILE_SETTINGS_PANEL_NAME =
  'compPanels.panels.PopupContainerMobile.settingsPanel';

function openSettingsPanel(editorAPI: EditorAPI, origin: string) {
  if (editorAPI.isDesktopEditor()) {
    gfppDataUtils.togglePanel(editorAPI, ACTIONS.SETTINGS, { origin });
  } else {
    gfppDataUtils.toggleComponentPanel(editorAPI, MOBILE_SETTINGS_PANEL_NAME, {
      origin,
    });
  }
}

function isPopupHasBehaviors(editorAPI) {
  const openPopUpId = editorAPI.dsRead.pages.popupPages.getCurrentPopupId();
  const popupBehaviors =
    editorAPI.pages.popupPages.getOpenBehaviorsForPopupWithId(openPopUpId);

  return popupBehaviors.length !== 0;
}

export default {
  untranslatable: true,
  mainActions: [
    {
      label: 'gfpp_mainaction_popups',
      isApplied: true,
      onClick(editorAPI) {
        editorAPI.openPagesPanel({ origin: 'gfpp_popup' });
      },
    },
    {
      label: 'gfpp_secondaryaction_popups',
      isApplied: true,
      isSelected: gfppDataUtils.getPanelStateFn(ACTIONS.SETTINGS),
      onClick: gfppDataUtils.getTogglePanelFn(ACTIONS.SETTINGS),
    },
  ],
  mobileMainActions(editorAPI) {
    const mobileMainActions = [
      {
        label: 'gfpp_mainaction_popups',
        isApplied: true,
        onClick() {
          editorAPI.openPagesPanel({ origin: 'gfpp_popup' });
        },
      },
    ];

    if (isPopupHasBehaviors(editorAPI)) {
      mobileMainActions.push({
        label: 'gfpp_secondaryaction_popups',
        isApplied: true,
        onClick: gfppDataUtils.getToggleCompPanelFn(MOBILE_SETTINGS_PANEL_NAME),
      });
    }

    return mobileMainActions;
  },
  enabledActions() {
    return [
      ACTIONS.SETTINGS,
      ACTIONS.LAYOUT,
      ACTIONS.DESIGN,
      ACTIONS.ANIMATION,
      ACTIONS.HELP,
    ];
  },
  mobileEnabledActions(editorAPI) {
    if (isPopupHasBehaviors(editorAPI)) {
      return [
        ACTIONS.SETTINGS,
        ACTIONS.HELP,
        ACTIONS.ANIMATION,
        ACTIONS.MOBILE_BACKGROUND_SETTINGS,
      ];
    }

    return [
      ACTIONS.HELP,
      ACTIONS.ANIMATION,
      ACTIONS.MOBILE_BACKGROUND_SETTINGS,
    ];
  },
  presetActions(editorAPI: EditorAPI, selectedComponentsList) {
    const { dispatch } = editorAPI.store;
    const { getState } = editorAPI.store;
    const animationsApi = editorAPI.host.getAPI(AnimationsApiKey);
    const selectedComponents = _.head(selectedComponentsList);
    const popupProps: PopupContainerProperties =
      editorAPI.components.properties.get(selectedComponents);

    const presetActionsItems = {
      settings: {
        onClick: _.partial(openSettingsPanel, editorAPI),
      },
      design: {
        onClick: gfppDataUtils.getTogglePanelFn('design'),
      },
      animation: {
        onClick: animationsUtils.isNewAnimationsEnabled(editorAPI)
          ? () =>
              animationsApi.toggleAnimationPanel({
                helpId: helpIds.ANIMATION.POPUP_CONTAINER,
                customizeHelpId: helpIds.ANIMATION.CUSTOMIZE_POPUP_CONTAINER,
              })
          : gfppDataUtils.getTogglePanelFn('animation', {
              shouldAddExit: true,
              helpId: helpIds.ANIMATION.POPUP_CONTAINER,
              customizeHelpId: helpIds.ANIMATION.CUSTOMIZE_POPUP_CONTAINER,
              allowOnlyShowOnceUI: false,
              disabledAnimationParamsList: ['delay'],
            }),
      },
      help: helpIds.UTILS.getGfppHelpIds(helpIds.COMP_TYPES.POPUP_CONTAINER),
    };

    if (popupProps.alignmentType === 'nineGrid') {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      _.assign(presetActionsItems, {
        layout: {
          onClick() {
            if (stateManagement.pinMode.selectors.isOpen(getState())) {
              dispatch(stateManagement.pinMode.actions.close());
            } else {
              dispatch(stateManagement.pinMode.actions.open('gfpp'));
            }
          },
        },
      });
    } else {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      _.assign(presetActionsItems, {
        layout: {
          isSelected: gfppDataUtils.getPanelStateFn(
            constants.COMP_PANELS.POPUP.CONTAINER.POPUP_FULL_LAYOUT_PANEL.NAME,
          ),
          onClick(editorAPI: EditorAPI, compRef: CompRef, origin: string) {
            gfppDataUtils.toggleComponentPanel(
              editorAPI,
              constants.COMP_PANELS.POPUP.CONTAINER.POPUP_FULL_LAYOUT_PANEL
                .NAME,
              {
                origin: origin || 'gfpp',
                selectedComponents,
              },
            );
          },
        },
      });
    }

    return presetActionsItems;
  },
};
