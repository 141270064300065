// @ts-nocheck
import createReactClass from 'create-react-class';
import * as core from '@/core';
import React from 'react';
import constants from '@/constants';
import PopupTemplate from './popupTemplate';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'dragToHeaderPanel',
  mixins: [core.mixins.editorAPIMixin],
  render() {
    return (
      <PopupTemplate
        panelName={this.props.panelName}
        h1text="Notification_Box_Header_Added_Title"
        h2text="Notification_Box_Header_Added_Subtitle"
        symbolName="showOnAllPages"
        bodyText="Notification_Box_Header_Added_Text"
        helpId="db639aa7-f012-4526-9390-ef466c95f19d"
        dontShowAgainKey={constants.USER_PREFS.DRAG_TO_HEADER.DONT_SHOW_AGAIN}
        userPrefType={constants.USER_PREFS.TYPES.SITE}
      />
    );
  },
});
