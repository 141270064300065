import React, { Component, type ComponentType } from 'react';
import _ from 'lodash';
import * as util from '@/util';
import * as keyboardContextStack from '../../util/keyboardContextStack';

interface WithKeyboardBehaviorProps {
  openedDropPanel: string;
  menuBarItems: any[];
  openDropPanel: (key: string) => string;
  closeDropPanel: (force?: boolean) => string;
  getActiveItemIndex: () => number;
  getNextActiveItemKey: (dir: number) => string;
}

const withKeyboardBehavior = <P extends WithKeyboardBehaviorProps>(
  WrappedComponent: ComponentType<P>,
) => {
  class WithKeyboardBehavior extends Component<WithKeyboardBehaviorProps> {
    componentDidMount() {
      this.registerKeyboardContext();
    }

    UNSAFE_componentWillReceiveProps(nextProps: AnyFixMe) {
      if (this.props.openedDropPanel === nextProps.openedDropPanel) {
        return;
      }

      const { MENU_BAR } = util.keyboardShortcuts.CONTEXTS;
      const currentActiveKeyboardContext = util.keyboardShortcuts.getContext();
      const willDropPanelOpen = nextProps.openedDropPanel !== null;
      const isMenuBarContextActive = currentActiveKeyboardContext === MENU_BAR;
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/some
      const isNextDropPanelAccessible = _.some(nextProps.menuBarItems, [
        'key',
        nextProps.openedDropPanel,
      ]);
      const isMenuBarContextInQueue = keyboardContextStack.hasContext(MENU_BAR);
      const shouldMenuBarContextBeActive =
        willDropPanelOpen && isNextDropPanelAccessible;

      if (
        shouldMenuBarContextBeActive &&
        !isMenuBarContextActive &&
        !isMenuBarContextInQueue
      ) {
        keyboardContextStack.pushAndSetContext(MENU_BAR);
      } else if (
        !shouldMenuBarContextBeActive &&
        (isMenuBarContextActive || isMenuBarContextInQueue)
      ) {
        keyboardContextStack.popAndRestoreLastContext(MENU_BAR, false);
      }
    }

    componentWillUnmount() {
      keyboardContextStack.popAndRestoreLastContext(
        util.keyboardShortcuts.CONTEXTS.MENU_BAR,
        false,
      );
      util.keyboardShortcuts.unregisterContext(
        util.keyboardShortcuts.CONTEXTS.MENU_BAR,
      );
    }

    openDropPanelByDirection = (dir: number) => {
      const newItemKey = this.props.getNextActiveItemKey(dir);
      if (newItemKey) {
        this.props.openDropPanel(newItemKey);
      }
    };

    registerKeyboardContext = () => {
      const menuBarCtx = {
        esc: () => {
          if (this.props.openedDropPanel !== null) {
            const immediately = true;
            this.props.closeDropPanel(immediately);
          }
        },
        left: () => this.openDropPanelByDirection(-1),
        right: () => this.openDropPanelByDirection(1),
      };
      util.keyboardShortcuts.registerContext(
        util.keyboardShortcuts.CONTEXTS.MENU_BAR,
        menuBarCtx,
      );
    };

    render() {
      const { props } = this;

      return React.createElement(WrappedComponent, props as P);
    }
  }

  return WithKeyboardBehavior;
};

export default withKeyboardBehavior;
