import { getEditorVersion } from '../contentInjectionUtil';
import { ErrorReporter } from '@wix/editor-error-reporter';
import { biLogger } from '@/util';
import { contentInjectionContentVersionChanged } from '@wix/bi-logger-editor/v2';

import type { ContentItem, ListContentItem } from '@/presetApi';
import type { LockedContentPerMsid, LockedContent } from '../types';
import type { SiteGlobalDataApi } from '@/siteGlobalData';

enum ContentLockBiEventTriggerName {
  WIX_UI_LANG = 'wix ui lang',
  BUSINESS_TYPE = 'business type',
  BUSINESS_NAME = 'business name',
  EDITOR_VERSION = 'editor version',
  PRESETS_BUNDLE_VERSION = 'presets bundle version',
  INITIAL_VERSION_OF_THE_CONTENT = 'initial version of the content',
}

export const contentInjectionLockedContentKey =
  'CONTENT_INJECTION_LOCKED_CONTENT';

export const getPresetLockedContent = (
  siteGlobalDataAPI: SiteGlobalDataApi,
  msId: string,
  presetId: string,
  isPage: boolean,
  ceType: string,
) => {
  const currentMsidLockedContent = getLocalStorageLockedContentPerMsId(msId);

  if (currentMsidLockedContent) {
    if (
      shouldInvalidateLockedContentCache(
        siteGlobalDataAPI,
        currentMsidLockedContent,
      )
    ) {
      sendContentVersionChangedEvent(
        siteGlobalDataAPI,
        currentMsidLockedContent,
      );
      initLocalStorageMsEntry(siteGlobalDataAPI, msId, true);
      return;
    }
    return getExtractedPreset(
      currentMsidLockedContent,
      presetId,
      isPage,
      ceType,
    );
  }
};

const getExtractedPreset = (
  lockedContentPerMsid: LockedContentPerMsid,
  presetId: string,
  isPage: boolean,
  ceType: string,
) => {
  if (isPage) {
    return getExtractedPagePreset(lockedContentPerMsid, presetId, ceType);
  }
  return getExtractedSectionPreset(lockedContentPerMsid, presetId);
};

const getExtractedPagePreset = (
  lockedContentPerMsid: LockedContentPerMsid,
  presetId: string,
  ceType: string,
) => {
  return lockedContentPerMsid.pagePresets?.[presetId]?.[ceType];
};

const getExtractedSectionPreset = (
  lockedContentPerMsid: LockedContentPerMsid,
  presetId: string,
) => {
  return lockedContentPerMsid.sectionPresets?.[presetId];
};

export const setPresetLockedContent = (
  siteGlobalDataAPI: SiteGlobalDataApi,
  msId: string,
  presetId: string,
  contentToLock: ContentItem | ListContentItem,
  isPage: boolean,
  ceType: string,
) => {
  const currentMsidContent =
    getLocalStorageLockedContentPerMsId(msId) ||
    getNewLockEntryContent(siteGlobalDataAPI);

  const currentMsidContentToUpdate = getUpdatedMsIdContent(
    currentMsidContent,
    contentToLock,
    isPage,
    presetId,
    ceType,
  );

  updateLocalStorageMsIdEntry(currentMsidContentToUpdate, msId);
};

const getUpdatedMsIdContent = (
  lockedContentPerMsId: LockedContentPerMsid,
  contentToLock: ContentItem | ListContentItem,
  isPage: boolean,
  presetId: string,
  ceType: string,
): LockedContentPerMsid => {
  if (isPage) {
    if (!lockedContentPerMsId.pagePresets[presetId]) {
      lockedContentPerMsId.pagePresets[presetId] = {};
    }
    lockedContentPerMsId.pagePresets[presetId][ceType] = contentToLock;
  } else {
    lockedContentPerMsId.sectionPresets[presetId] = contentToLock;
  }
  return lockedContentPerMsId;
};

export const shouldInvalidateLockedContentCache = (
  siteGlobalDataAPI: SiteGlobalDataApi,
  currentMsidLockedContent: LockedContentPerMsid,
): boolean => {
  const {
    language,
    businessName,
    presetsBundleVersion,
    industryId,
    structureId,
    __DO_NOT_INVALIDATE_CONTENT,
  } = currentMsidLockedContent;

  if (__DO_NOT_INVALIDATE_CONTENT) {
    return false;
  }

  return (
    isLanguageCodeChanged(language) ||
    isBusinessNameChanged(businessName, siteGlobalDataAPI) ||
    isPresetsBundleVersionChanged(presetsBundleVersion) ||
    isIndustryIdChanged(industryId, siteGlobalDataAPI) ||
    isStructureIdChanged(structureId, siteGlobalDataAPI)
  );
};

const isLanguageCodeChanged = (language: string) => {
  return language !== window.editorModel.languageCode;
};

const isBusinessNameChanged = (
  businessName: string,
  siteGlobalDataAPI: SiteGlobalDataApi,
) => {
  return businessName !== siteGlobalDataAPI.getBusinessName();
};

const isIndustryIdChanged = (
  industryId: string,
  siteGlobalDataAPI: SiteGlobalDataApi,
) => {
  return industryId !== siteGlobalDataAPI.getBusinessType().industryId;
};

const isStructureIdChanged = (
  structureId: string,
  siteGlobalDataAPI: SiteGlobalDataApi,
) => {
  return structureId !== siteGlobalDataAPI.getBusinessType().structureId;
};

const isPresetsBundleVersionChanged = (presetsBundleVersion: string) => {
  return (
    presetsBundleVersion !==
    window.serviceTopology.scriptsLocationMap['editor-presets-bundle']
  );
};

export const initLocalStorageMsEntry = (
  siteGlobalDataAPI: SiteGlobalDataApi,
  msId: string,
  overrideEntry?: boolean,
) => {
  if (!getLocalStorageLockedContentPerMsId(msId) || overrideEntry) {
    updateLocalStorageMsIdEntry(
      getNewLockEntryContent(siteGlobalDataAPI),
      msId,
    );
  }
};

export const getNewLockEntryContent = (
  siteGlobalDataAPI: SiteGlobalDataApi,
): LockedContentPerMsid => {
  const { industryId, structureId } = siteGlobalDataAPI.getBusinessType();
  return {
    creationTime: Date.now(),
    editorVersion: getEditorVersion(),
    industryId,
    structureId,
    businessName: siteGlobalDataAPI.getBusinessName() || '',
    presetsBundleVersion:
      window.serviceTopology.scriptsLocationMap['editor-presets-bundle'],
    language: window.editorModel.languageCode,
    sectionPresets: {},
    pagePresets: {},
  };
};

export const updateLocalStorage = (updatedContentToLock: LockedContent) => {
  try {
    window.localStorage.setItem(
      contentInjectionLockedContentKey,
      JSON.stringify(updatedContentToLock),
    );
  } catch (err) {
    ErrorReporter.captureException(err, {
      tags: { contentInjection: true, contentLockFlow: true },
    });
    return;
  }
};

export const updateLocalStorageMsIdEntry = (
  updatedContentToLockPerMsId: LockedContentPerMsid,
  msId: string,
  allowMultipleSites?: boolean,
) => {
  const currentLockedContent = allowMultipleSites
    ? getLocalStorageLockedContent()
    : {};
  currentLockedContent[msId] = updatedContentToLockPerMsId;
  updateLocalStorage(currentLockedContent);
};

export const getLocalStorageLockedContent = (): LockedContent => {
  try {
    const lockedContentString = window.localStorage.getItem(
      contentInjectionLockedContentKey,
    );
    if (!lockedContentString) {
      updateLocalStorage({});
      return {};
    }
    return JSON.parse(lockedContentString);
  } catch (err) {
    ErrorReporter.captureException(err, {
      tags: { contentInjection: true, contentLockFlow: true },
    });
    return {};
  }
};

export const getLocalStorageLockedContentPerMsId = (
  msId: string,
): LockedContentPerMsid | undefined => {
  const currentLockedContent = getLocalStorageLockedContent();
  return currentLockedContent[msId];
};

export const cleanCategoryContent = (
  presetIds: string[],
  msId: string,
  isPage: boolean,
) => {
  const currentMsidLockedContent = getLocalStorageLockedContentPerMsId(msId);

  if (currentMsidLockedContent) {
    presetIds.forEach((presetId) => {
      if (isPage) {
        delete currentMsidLockedContent.pagePresets?.[presetId];
      } else {
        delete currentMsidLockedContent.sectionPresets?.[presetId];
      }
    });

    updateLocalStorageMsIdEntry(currentMsidLockedContent, msId);
  }
};

function sendContentVersionChangedEvent(
  siteGlobalDataAPI: SiteGlobalDataApi,
  currentMsidLockedContent: LockedContentPerMsid,
) {
  const { industryId, structureId } = currentMsidLockedContent;
  const triggerName = getBiEventTriggerName(
    siteGlobalDataAPI,
    currentMsidLockedContent,
  );

  biLogger.report(
    contentInjectionContentVersionChanged({
      business_type: JSON.stringify({
        industry_id: industryId,
        structure_id: structureId,
      }),
      triggerName,
    }),
  );
}

function getBiEventTriggerName(
  siteGlobalDataAPI: SiteGlobalDataApi,
  currentMsidLockedContent: LockedContentPerMsid,
): string {
  const {
    language,
    businessName,
    presetsBundleVersion,
    industryId,
    structureId,
  } = currentMsidLockedContent;

  if (isLanguageCodeChanged(language)) {
    return ContentLockBiEventTriggerName.WIX_UI_LANG;
  }
  if (
    isIndustryIdChanged(industryId, siteGlobalDataAPI) ||
    isStructureIdChanged(structureId, siteGlobalDataAPI)
  ) {
    return ContentLockBiEventTriggerName.BUSINESS_TYPE;
  }
  if (isBusinessNameChanged(businessName, siteGlobalDataAPI)) {
    return ContentLockBiEventTriggerName.BUSINESS_NAME;
  }
  if (isPresetsBundleVersionChanged(presetsBundleVersion)) {
    return ContentLockBiEventTriggerName.PRESETS_BUNDLE_VERSION;
  }
  return ContentLockBiEventTriggerName.INITIAL_VERSION_OF_THE_CONTENT;
}
