// @ts-nocheck
import * as addPanel from '@/oldAddPanel';

export default {
  getDesignSections() {
    return {
      titles: ['design_section_label_explore_FWline'],
      sections: [
        addPanel.sections.getSections().vectorShape.fullWidthLinesSection,
      ],
    };
  },
};
