import _ from 'lodash';
import {
  editorDataFunction,
  componentInstanceData,
} from '@wix/editor-component-data-resolver';

import type { ComponentEditorMetaDataDefinition } from '../types';

const LABEL_SKIN_PARAMS = ['txt', 'fnt'];

const metaData: ComponentEditorMetaDataDefinition = {
  skinParamsToIgnore: editorDataFunction(
    componentInstanceData('componentData'),
    (compData: AnyFixMe) => {
      const hasLabel = !_.isEmpty(compData?.label);
      return hasLabel ? [] : LABEL_SKIN_PARAMS;
    },
  ),
  allowConnectToDB: true,
  skipInQuickEditPanel: true,
  overrideProportionalResize: true,
};

export default metaData;
