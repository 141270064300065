import React from 'react';

export interface ItemType {
  id: string;
  [key: string]: any;
}

export interface KeyboardControlProps {
  children: React.ReactNode;

  onEnter?(): void;
  onEscape?(): void;
  onArrowUp?(): void;
  onArrowDown?(): void;
}

export class KeyboardControl extends React.Component<KeyboardControlProps> {
  componentDidMount() {
    this.handlers = {
      Enter: this.props.onEnter,
      Escape: this.props.onEscape,
      ArrowUp: this.props.onArrowUp,
      ArrowDown: this.props.onArrowDown,
    };
    document.addEventListener('keydown', this.handleKeydown, true);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeydown, true);
  }

  private handlers: { [key: string]: () => void };

  handleKeydown = (event: KeyboardEvent) => {
    const handler = this.handlers[event.key];

    if (handler) {
      event.preventDefault();
      event.stopPropagation();
      handler();
    }
  };

  render() {
    return this.props.children;
  }
}
