'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')
const moreBehaviorHelper = require('./moreBehaviorHelper')
module.exports =
  {
    "type": addPanelDataConsts.SECTIONS_TYPES.PRESET,
    "hide": false,
    "title": "add_section_vectorart_featured_label",
    "showSectionHeader": true,
    "additionalBehaviours": {
      "labelMode": addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
      "infoIcon": addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
      "hoverImageAction": addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
      "iconEnabledForComps": {}
    },
    "props": {
      "image": "addPanelData/sections/vectorArt/featuredSection-winter-2023.png",
      "retinaImage": {
        "src": "addPanelData/sections/vectorArt/featuredSection-winter-2023-@2x.png",
        "dimensions": {
          "width": 648,
          "height": 1370
        }
      },
      "imageHover": null,
      "items": [{
        "id": "VectorArt_Featured_1",
        "structure": {
          "type": "Component",
          "skin": "skins.viewer.VectorImageSkin",
          "layout": {
            "width": 171.6,
            "height": 103.2,
            "x": 11,
            "y": 24,
            "scale": 1,
            "rotationInDegrees": 0,
            "fixedPosition": false
          },
          "componentType": "wysiwyg.viewer.components.VectorImage",
          "data": {
            "type": "VectorImage",
            "alt": "",
            "svgId": "11062b_2d8ba21dd8084f12a3155b3927e8c232.svg",
            "title": "Pro Women"
          },
          "props": {
            "type": "VectorImageProperties",
            "displayMode": "fit",
            "flip": "none"
          },
          "design": {
            "type": "VectorImageDesignData",
            "overrideColors": {
              "color1": "#10794D"
            },
            "shapeStyle": {
              "opacity": 1,
              "strokeWidth": 4,
              "stroke": "#5E97FF",
              "strokeOpacity": 1,
              "enableStroke": false
            }
          },
          "style": {
            "type": "ComponentStyle",
            "style": {
              "groups": {},
              "properties": {},
              "propertiesSource": {}
            },
            "componentClassName": "wysiwyg.viewer.components.VectorImage",
            "pageId": "",
            "compId": "i8yjldka_0",
            "styleType": "custom",
            "skin": "skins.viewer.VectorImageSkin"
          },
          "activeModes": {},
          "id": "comp-lceicvo7"
        },
        "preset": {
          "rect": {
            "width": 161,
            "height": 98,
            "x": 11,
            "y": 24
          },
          "tags": null,
          "shape": {
            "name": "poly",
            "coords": "5,10,5,111,159,112,158,11"
          }
        },
        "mediaManager": {
          "path": "public/98285ba8ab164d17a935742bd019e150/230c4bbac7d34d3c86fa276cb8f331f1"
        }
      },
        {
          "id": "VectorArt_Featured_2",
          "structure": {
            "type": "Component",
            "skin": "skins.viewer.VectorImageSkin",
            "layout": {
              "width": 151.2,
              "height": 103.2,
              "x": 185,
              "y": 25,
              "scale": 1,
              "rotationInDegrees": 0,
              "fixedPosition": false
            },
            "componentType": "wysiwyg.viewer.components.VectorImage",
            "data": {
              "type": "VectorImage",
              "alt": "",
              "svgId": "cdf2d3f1094e4587901469959e08acb0.svg",
              "title": "Mittens"
            },
            "props": {
              "type": "VectorImageProperties",
              "displayMode": "fit",
              "flip": "none"
            },
            "design": {
              "type": "VectorImageDesignData",
              "overrideColors": {
                "color1": "#C8ABF5",
                "color2": "#C9C29A",
                "color3": "#FA4830"
              },
              "shapeStyle": {
                "opacity": 1,
                "strokeWidth": 4,
                "stroke": "#5E97FF",
                "strokeOpacity": 1,
                "enableStroke": false
              }
            },
            "style": {
              "type": "ComponentStyle",
              "style": {
                "groups": {},
                "properties": {},
                "propertiesSource": {}
              },
              "componentClassName": "wysiwyg.viewer.components.VectorImage",
              "pageId": "",
              "compId": "i8yjldka_0",
              "styleType": "custom",
              "skin": "skins.viewer.VectorImageSkin"
            },
            "activeModes": {},
            "id": "comp-lcej2hbw"
          },
          "preset": {
            "rect": {
              "width": 134,
              "height": 91,
              "x": 177,
              "y": 24
            },
            "tags": null,
            "shape": {
              "name": "poly",
              "coords": "175,13,175,114,319,116,317,15"
            }
          },
          "mediaManager": {
            "path": "public/98285ba8ab164d17a935742bd019e150/286b7ffbed1443fbbb243a4c3b7b8d57"
          }
        },
        {
          "id": "VectorArt_Featured_3",
          "structure": {
            "type": "Component",
            "skin": "skins.viewer.VectorImageSkin",
            "layout": {
              "width": 193.2,
              "height": 132,
              "x": 151,
              "y": 130,
              "scale": 1,
              "rotationInDegrees": 0,
              "fixedPosition": false
            },
            "componentType": "wysiwyg.viewer.components.VectorImage",
            "data": {
              "type": "VectorImage",
              "alt": "",
              "svgId": "11062b_525938c857d4424d9676ad11df8393fd.svg",
              "title": "Winter Snow"
            },
            "props": {
              "type": "VectorImageProperties",
              "displayMode": "fit",
              "flip": "none"
            },
            "design": {
              "type": "VectorImageDesignData",
              "overrideColors": {
                "color2": "#C9C29A",
                "color3": "#F76543"
              },
              "shapeStyle": {
                "opacity": 1,
                "strokeWidth": 4,
                "stroke": "#5E97FF",
                "strokeOpacity": 1,
                "enableStroke": false
              }
            },
            "style": {
              "type": "ComponentStyle",
              "style": {
                "groups": {},
                "properties": {},
                "propertiesSource": {}
              },
              "componentClassName": "wysiwyg.viewer.components.VectorImage",
              "pageId": "",
              "compId": "i8yjldka_0",
              "styleType": "custom",
              "skin": "skins.viewer.VectorImageSkin"
            },
            "activeModes": {},
            "id": "comp-lceicvoh2"
          },
          "preset": {
            "rect": {
              "width": 170,
              "height": 125,
              "x": 143,
              "y": 120
            },
            "tags": null,
            "shape": {
              "name": "poly",
              "coords": "153,121,312,125,317,241,142,247"
            }
          },
          "mediaManager": {
            "path": "public/98285ba8ab164d17a935742bd019e150/ed8054c8d49f41bb92efb3cb42c5a85c"
          }
        },
        {
          "id": "VectorArt_Featured_4",
          "structure": {
            "type": "Component",
            "skin": "skins.viewer.VectorImageSkin",
            "layout": {
              "width": 156,
              "height": 156,
              "x": 38,
              "y": 123,
              "scale": 1,
              "rotationInDegrees": 0,
              "fixedPosition": false
            },
            "componentType": "wysiwyg.viewer.components.VectorImage",
            "data": {
              "type": "VectorImage",
              "alt": "",
              "svgId": "24c0745c13db4805a5200854e372ade5.svg",
              "title": "Heart and Arrow"
            },
            "props": {
              "type": "VectorImageProperties",
              "displayMode": "fit",
              "flip": "horizontal"
            },
            "design": {
              "type": "VectorImageDesignData",
              "overrideColors": {
                "color1": "#FA4830",
                "color2": "#FA4830"
              },
              "shapeStyle": {
                "opacity": 1,
                "strokeWidth": 4,
                "stroke": "#5E97FF",
                "strokeOpacity": 1,
                "enableStroke": false
              }
            },
            "style": {
              "type": "ComponentStyle",
              "style": {
                "groups": {},
                "properties": {},
                "propertiesSource": {}
              },
              "componentClassName": "wysiwyg.viewer.components.VectorImage",
              "pageId": "",
              "compId": "i8yjldka_0",
              "styleType": "custom",
              "skin": "skins.viewer.VectorImageSkin"
            },
            "activeModes": {},
            "id": "comp-lceicvok1"
          },
          "preset": {
            "rect": {
              "width": 133,
              "height": 86,
              "x": 11,
              "y": 124
            },
            "tags": null,
            "shape": {
              "name": "poly",
              "coords": "22,117,96,118,129,157,124,215,50,181"
            }
          },
          "mediaManager": {
            "path": "public/98285ba8ab164d17a935742bd019e150/12f1ca10ee464d1195ee9a9e41ca5578"
          }
        },
        {
          "id": "VectorArt_Featured_5",
          "structure": {
            "type": "Component",
            "skin": "skins.viewer.VectorImageSkin",
            "layout": {
              "width": 171.6,
              "height": 270,
              "x": 11,
              "y": 185,
              "scale": 1,
              "rotationInDegrees": 0,
              "fixedPosition": false
            },
            "componentType": "wysiwyg.viewer.components.VectorImage",
            "data": {
              "type": "VectorImage",
              "alt": "",
              "svgId": "11062b_e1d387061a7e4e649ae9751fae63cb91.svg",
              "title": "Winter Ski"
            },
            "props": {
              "type": "VectorImageProperties",
              "displayMode": "fit",
              "flip": "none"
            },
            "design": {
              "type": "VectorImageDesignData",
              "overrideColors": {
                "color4": "#249973",
                "color6": "#FFDF51",
                "color8": "#31A881"
              },
              "shapeStyle": {
                "opacity": 1,
                "strokeWidth": 4,
                "stroke": "#5E97FF",
                "strokeOpacity": 1,
                "enableStroke": false
              }
            },
            "style": {
              "type": "ComponentStyle",
              "style": {
                "groups": {},
                "properties": {},
                "propertiesSource": {}
              },
              "componentClassName": "wysiwyg.viewer.components.VectorImage",
              "pageId": "",
              "compId": "",
              "styleType": "custom",
              "skin": "skins.viewer.VectorImageSkin"
            },
            "activeModes": {},
            "id": "comp-lcetwtn4"
          },
          "preset": {
            "rect": {
              "width": 143,
              "height": 207,
              "x": 11,
              "y": 209
            },
            "tags": null,
            "shape": {
              "name": "poly",
              "coords": "35,192,155,277,121,362,166,398,138,420,6,335,9,209"
            }
          },
          "mediaManager": {
            "path": "public/98285ba8ab164d17a935742bd019e150/0103c2cd2db94fa99c4925f802d50097"
          }
        },
        {
          "id": "VectorArt_Featured_6",
          "structure": {
            "type": "Component",
            "skin": "skins.viewer.VectorImageSkin",
            "layout": {
              "width": 273,
              "height": 87,
              "x": 198,
              "y": 257,
              "scale": 1,
              "rotationInDegrees": 180,
              "fixedPosition": false
            },
            "componentType": "wysiwyg.viewer.components.VectorImage",
            "data": {
              "type": "VectorImage",
              "alt": "",
              "svgId": "11062b_262769d8db16490495c490196b124bab.svg",
              "title": "Hugs and Kisses"
            },
            "props": {
              "type": "VectorImageProperties",
              "displayMode": "fit",
              "flip": "horizontal"
            },
            "design": {
              "type": "VectorImageDesignData",
              "overrideColors": {
                "color1": "#F9BC25"
              },
              "shapeStyle": {
                "opacity": 1,
                "strokeWidth": 4,
                "stroke": "#5E97FF",
                "strokeOpacity": 1,
                "enableStroke": false
              }
            },
            "style": {
              "type": "ComponentStyle",
              "style": {
                "groups": {},
                "properties": {},
                "propertiesSource": {}
              },
              "componentClassName": "wysiwyg.viewer.components.VectorImage",
              "pageId": "",
              "compId": "i8yjldka_0",
              "styleType": "custom",
              "skin": "skins.viewer.VectorImageSkin"
            },
            "activeModes": {},
            "id": "comp-lcek2t6d"
          },
          "preset": {
            "rect": {
              "width": 145,
              "height": 55,
              "x": 166,
              "y": 245
            },
            "tags": null,
            "shape": {
              "name": "poly",
              "coords": "184,252,298,249,298,289,185,290"
            }
          },
          "mediaManager": {
            "path": "public/98285ba8ab164d17a935742bd019e150/ed8054c8d49f41bb92efb3cb42c5a85c"
          }
        },
        {
          "id": "VectorArt_Featured_7",
          "structure": {
            "type": "Component",
            "skin": "skins.viewer.VectorImageSkin",
            "layout": {
              "width": 144,
              "height": 165,
              "x": 153,
              "y": 332,
              "scale": 1,
              "rotationInDegrees": 0,
              "fixedPosition": false
            },
            "componentType": "wysiwyg.viewer.components.VectorImage",
            "data": {
              "type": "VectorImage",
              "alt": "",
              "svgId": "09106907098644bfa9b6e992eda69329.svg",
              "title": "Snowflake "
            },
            "props": {
              "type": "VectorImageProperties",
              "displayMode": "fit",
              "flip": "none"
            },
            "design": {
              "type": "VectorImageDesignData",
              "overrideColors": {
                "color1": "#90CBDD"
              },
              "shapeStyle": {
                "opacity": 1,
                "strokeWidth": 4,
                "stroke": "#5E97FF",
                "strokeOpacity": 1,
                "enableStroke": false
              }
            },
            "style": {
              "type": "ComponentStyle",
              "style": {
                "groups": {},
                "properties": {},
                "propertiesSource": {}
              },
              "componentClassName": "wysiwyg.viewer.components.VectorImage",
              "pageId": "",
              "compId": "",
              "styleType": "custom",
              "skin": "skins.viewer.VectorImageSkin"
            },
            "activeModes": {},
            "id": "comp-lceicvox3"
          },
          "preset": {
            "rect": {
              "width": 68,
              "height": 78,
              "x": 143,
              "y": 319
            },
            "tags": null,
            "shape": {
              "name": "poly",
              "coords": "176,315,210,340,208,372,178,398,144,370,143,338"
            }
          },
          "mediaManager": {
            "path": "public/98285ba8ab164d17a935742bd019e150/ae94a2748ec4471b8a3f45e8b4b49cdb"
          }
        },
        {
          "id": "VectorArt_Featured_8",
          "structure": {
            "type": "Component",
            "skin": "skins.viewer.VectorImageSkin",
            "layout": {
              "width": 124.8,
              "height": 222,
              "x": 204,
              "y": 308,
              "scale": 1,
              "rotationInDegrees": 0,
              "fixedPosition": false
            },
            "componentType": "wysiwyg.viewer.components.VectorImage",
            "data": {
              "type": "VectorImage",
              "alt": "",
              "svgId": "a3c153_81beb4e17c73437688a0455a65a7dc6b.svg",
              "title": "wintertree-14.svg"
            },
            "props": {
              "type": "VectorImageProperties",
              "displayMode": "fit",
              "flip": "none"
            },
            "design": {
              "type": "VectorImageDesignData",
              "overrideColors": {
                "color1": "#31A881"
              },
              "shapeStyle": {
                "opacity": 1,
                "strokeWidth": 4,
                "stroke": "#5E97FF",
                "strokeOpacity": 1,
                "enableStroke": false
              }
            },
            "style": {
              "type": "ComponentStyle",
              "style": {
                "groups": {},
                "properties": {},
                "propertiesSource": {}
              },
              "componentClassName": "wysiwyg.viewer.components.VectorImage",
              "pageId": "",
              "compId": "",
              "styleType": "custom",
              "skin": "skins.viewer.VectorImageSkin"
            },
            "activeModes": {},
            "id": "comp-lceicvov"
          },
          "preset": {
            "rect": {
              "width": 122,
              "height": 185,
              "x": 190,
              "y": 319
            },
            "tags": null,
            "shape": {
              "name": "poly",
              "coords": "254,296,316,438,316,491,184,493"
            }
          },
          "mediaManager": {
            "path": "public/98285ba8ab164d17a935742bd019e150/ae94a2748ec4471b8a3f45e8b4b49cdb"
          }
        },
        {
          "id": "VectorArt_Featured_9",
          "structure": {
            "type": "Component",
            "skin": "skins.viewer.VectorImageSkin",
            "layout": {
              "width": 51.6,
              "height": 332.4,
              "x": 20,
              "y": 387,
              "scale": 1,
              "rotationInDegrees": 0,
              "fixedPosition": false
            },
            "componentType": "wysiwyg.viewer.components.VectorImage",
            "data": {
              "type": "VectorImage",
              "alt": "",
              "svgId": "11062b_d43a62467cda421894445721ee9aeafd.svg",
              "title": "Umbrella"
            },
            "props": {
              "type": "VectorImageProperties",
              "displayMode": "fit",
              "flip": "none"
            },
            "design": {
              "type": "VectorImageDesignData",
              "overrideColors": {
                "color1": "#C9C29A",
                "color2": "#FA4830",
                "color3": "#D12008",
                "color4": "#C9C29A"
              },
              "shapeStyle": {
                "opacity": 1,
                "strokeWidth": 4,
                "stroke": "#5E97FF",
                "strokeOpacity": 1,
                "enableStroke": false
              }
            },
            "style": {
              "type": "ComponentStyle",
              "style": {
                "groups": {},
                "properties": {},
                "propertiesSource": {}
              },
              "componentClassName": "wysiwyg.viewer.components.VectorImage",
              "pageId": "",
              "compId": "",
              "styleType": "custom",
              "skin": "skins.viewer.VectorImageSkin"
            },
            "activeModes": {},
            "id": "comp-lceiz0y4"
          },
          "preset": {
            "rect": {
              "width": 68,
              "height": 287,
              "x": 11,
              "y": 379
            },
            "tags": null,
            "shape": {
              "name": "poly",
              "coords": "8,374,75,387,43,673,10,667"
            }
          },
          "mediaManager": {
            "path": "public/98285ba8ab164d17a935742bd019e150/286b7ffbed1443fbbb243a4c3b7b8d57"
          }
        },
        {
          "id": "VectorArt_Featured_10",
          "structure": {
            "type": "Component",
            "skin": "skins.viewer.VectorImageSkin",
            "layout": {
              "width": 104.39999999999999,
              "height": 150,
              "x": 82,
              "y": 440,
              "scale": 1,
              "rotationInDegrees": 0,
              "fixedPosition": false
            },
            "componentType": "wysiwyg.viewer.components.VectorImage",
            "data": {
              "type": "VectorImage",
              "alt": "",
              "svgId": "656c0778970b430fba37afca98034142.svg",
              "title": "Fox"
            },
            "props": {
              "type": "VectorImageProperties",
              "displayMode": "fit",
              "flip": "none"
            },
            "design": {
              "type": "VectorImageDesignData",
              "overrideColors": {
                "color1": "#FA4830",
                "color2": "#C8ABF5",
                "color3": "#26542C",
                "color4": "#C9C29A",
                "color6": "#C9C29A",
                "color7": "#FFDF51"
              },
              "shapeStyle": {
                "opacity": 1,
                "strokeWidth": 4,
                "stroke": "#5E97FF",
                "strokeOpacity": 1,
                "enableStroke": false
              }
            },
            "style": {
              "type": "ComponentStyle",
              "style": {
                "groups": {},
                "properties": {},
                "propertiesSource": {}
              },
              "componentClassName": "wysiwyg.viewer.components.VectorImage",
              "pageId": "",
              "compId": "i8yjldka_0",
              "styleType": "custom",
              "skin": "skins.viewer.VectorImageSkin"
            },
            "activeModes": {},
            "id": "comp-lcen6lx0"
          },
          "preset": {
            "rect": {
              "width": 104,
              "height": 138,
              "x": 79,
              "y": 434
            },
            "tags": null,
            "shape": {
              "name": "poly",
              "coords": "78,433,182,430,168,533,166,566,117,570,78,488"
            }
          },
          "mediaManager": {
            "path": "public/98285ba8ab164d17a935742bd019e150/ae94a2748ec4471b8a3f45e8b4b49cdb"
          }
        },
        {
          "id": "VectorArt_Featured_11",
          "structure": {
            "type": "Component",
            "skin": "skins.viewer.VectorImageSkin",
            "layout": {
              "width": 160.79999999999998,
              "height": 180,
              "x": 175,
              "y": 511,
              "scale": 1,
              "rotationInDegrees": 0,
              "fixedPosition": false
            },
            "componentType": "wysiwyg.viewer.components.VectorImage",
            "data": {
              "type": "VectorImage",
              "alt": "",
              "svgId": "11062b_958a3689da0e405487c50f097b35c148.svg",
              "title": "Snow Angel"
            },
            "props": {
              "type": "VectorImageProperties",
              "displayMode": "fit",
              "flip": "none"
            },
            "design": {
              "type": "VectorImageDesignData",
              "overrideColors": {
                "color1": "#D8EDF8",
                "color2": "#C9C29A",
                "color4": "#31A881"
              },
              "shapeStyle": {
                "opacity": 1,
                "strokeWidth": 4,
                "stroke": "#5E97FF",
                "strokeOpacity": 1,
                "enableStroke": false
              }
            },
            "style": {
              "type": "ComponentStyle",
              "style": {
                "groups": {},
                "properties": {},
                "propertiesSource": {}
              },
              "componentClassName": "wysiwyg.viewer.components.VectorImage",
              "pageId": "",
              "compId": "",
              "styleType": "custom",
              "skin": "skins.viewer.VectorImageSkin"
            },
            "activeModes": {},
            "id": "comp-lceicvp01"
          },
          "preset": {
            "rect": {
              "width": 143,
              "height": 162,
              "x": 168,
              "y": 504
            },
            "tags": null,
            "shape": {
              "name": "poly",
              "coords": "179,523,242,500,313,536,311,654,254,663,190,652,188,601,167,580"
            }
          },
          "mediaManager": {
            "path": "public/98285ba8ab164d17a935742bd019e150/ef944a699cc24493abc40ff6deacd3da"
          }
        },
        {
          "id": "VectorArt_Featured_12",
          "structure": {
            "type": "Component",
            "skin": "skins.viewer.VectorImageSkin",
            "layout": {
              "width": 214,
              "height": 172,
              "x": 67,
              "y": 575,
              "scale": 1,
              "rotationInDegrees": 0,
              "fixedPosition": false
            },
            "componentType": "wysiwyg.viewer.components.VectorImage",
            "data": {
              "type": "VectorImage",
              "alt": "",
              "svgId": "a3c153_be95762fe18b4dd7a82789a9a7f68db7.svg",
              "title": "new year 2023-10.svg"
            },
            "props": {
              "type": "VectorImageProperties",
              "displayMode": "fit",
              "flip": "none"
            },
            "design": {
              "type": "VectorImageDesignData",
              "overrideColors": {
                "color2": "#FED51E"
              },
              "shapeStyle": {
                "opacity": 1,
                "strokeWidth": 4,
                "stroke": "#5E97FF",
                "strokeOpacity": 1,
                "enableStroke": false
              }
            },
            "style": {
              "type": "ComponentStyle",
              "style": {
                "groups": {},
                "properties": {},
                "propertiesSource": {}
              },
              "componentClassName": "wysiwyg.viewer.components.VectorImage",
              "pageId": "",
              "compId": "",
              "styleType": "custom",
              "skin": "skins.viewer.VectorImageSkin"
            },
            "activeModes": {},
            "id": "comp-lceicvp3"
          },
          "preset": {
            "rect": {
              "width": 143,
              "height": 98,
              "x": 47,
              "y": 565
            },
            "tags": null,
            "shape": {
              "name": "poly",
              "coords": "88,553,125,583,141,604,175,606,175,652,88,670,56,650,64,595"
            }
          },
          "mediaManager": {
            "path": "public/98285ba8ab164d17a935742bd019e150/0418e414d7bc43d0b0cbeb8421a7c175"
          }
        }
      ],
      "compTypes": [
        "wysiwyg.viewer.components.VectorImage"
      ]
    },
    "subNavigationTitle": "add_section_vectorart_featured_label",
    "presetTitle": "add_section_vectorart_featured_label",
    "tooltipTitle": "add_section_vectorart_featured_label",
    "automationId": "add-panel-section-campaign_FeaturedSection",
    "subNavigationHide": false,
    "sectionFooter": {
      "openSource": "add_panel_vector_art_all",
      "styleOverride": {
        "paddingTop": "25px"
      },
      "label": "add_section_vectorart_featured_more_tooltip_title",
      "action": moreBehaviorHelper,
      "category": "VECTOR_ART",
      "path": "public/98285ba8ab164d17a935742bd019e150/ed8054c8d49f41bb92efb3cb42c5a85c"
    },
    "help": {
      "hide": false,
      "text": "add_section_vectorart_featured_tooltip_description"
    }
  }
