// @ts-nocheck
import _ from 'lodash';
import * as stateManagement from '@/stateManagement';

function setSiteUserPreferences(
  editorAPI,
  appData,
  token,
  preferences,
): Promise<void> {
  return new Promise(function (resolve, reject) {
    const { appDefinitionId } = appData;
    if (!appDefinitionId) {
      reject(
        new Error(
          'Illegal appData - application is not registered in clientSpecMap',
        ),
      );
    }
    editorAPI.store.dispatch(
      stateManagement.userPreferences.actions.registerSitePreferencesCallbacks(
        function (success) {
          if (!success) {
            reject(new Error('Site Preferences failed to load'));
          }
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
          _.forEach(preferences, function (value, key) {
            editorAPI.store.dispatch(
              stateManagement.userPreferences.actions.setSiteUserPreferences(
                key,
                value,
                appDefinitionId,
              ),
            );
          });
          resolve();
        },
      ),
    );
  });
}

function getSiteUserPreferences(editorAPI, appData, token, keys) {
  return new Promise(function (resolve, reject) {
    const { appDefinitionId } = appData;
    if (!appDefinitionId) {
      reject(
        new Error(
          'Illegal appData - application is not registered in clientSpecMap',
        ),
      );
    }
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/is-array
    const defaults = _.isArray(keys)
      ? _(keys).keyBy().mapValues(_.noop).value()
      : keys;
    editorAPI.store.dispatch(
      stateManagement.userPreferences.actions.registerSitePreferencesCallbacks(
        function (success) {
          if (!success) {
            reject(new Error('Site Preferences failed to load'));
          }
          const prefValues = _.mapValues(defaults, (value, key) =>
            _.cloneDeep(
              stateManagement.userPreferences.selectors.getSiteUserPreferences(
                key,
                appDefinitionId,
              )(editorAPI.store.getState()),
            ),
          );
          resolve(_.defaults(prefValues, defaults));
        },
      ),
    );
  });
}

const site = {
  set: setSiteUserPreferences,
  get: getSiteUserPreferences,
};

export { site };
