:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._actionsContainer_1bgt0_7 {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 60px;
  margin-top: 38px; }

._settingsIcon_1bgt0_14 {
  margin-left: 16px;
  cursor: pointer; }
  ._settingsIcon_1bgt0_14 path {
    fill: #868aa5; }
  ._settingsIcon_1bgt0_14:hover path {
    fill: #116dff; }
