:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._label-box_ig60g_7 {
  background: #fff;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.15), 0 6px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 18px 0 rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  padding: 10px 15px;
  letter-spacing: 0.4px;
  white-space: nowrap;
  cursor: pointer; }
