// @ts-nocheck
import createReactClass from 'create-react-class';
import * as core from '@/core';
import React from 'react';
import { translate } from '@/i18n';
import * as UI from '@/baseUI';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'sectionFooter',
  mixins: [core.mixins.editorAPIMixin],

  getSectionStyle() {
    return this.props.styleOverride;
  },
  onClick() {
    this.props.action(this.getEditorAPI(), this.props);
  },
  showAction() {
    if (this.props.showAction) {
      return this.props.showAction(this.getEditorAPI(), this.props);
    }

    return true;
  },
  render() {
    return (
      <div style={this.getSectionStyle()} className="section-footer">
        {this.showAction() ? (
          <span
            onClick={this.onClick}
            key="action"
            data-aid="section-footer-button"
            className="link"
          >
            {translate(this.props.label)}
            <UI.symbol name="arrowRight" className="arrow" />
          </span>
        ) : null}
      </div>
    );
  },
});
