// @ts-nocheck
import _ from 'lodash';
import { resize, ResizeAPI } from '@wix/thumbnail-generator';
import * as util from '@/util';
import constants from '@/constants';
import * as mediaManagerPanelConstants from '../mediaManagerPanelConstants/mediaManagerPanelConstants';
import * as addComponentData from './mediaManagerPanelAddCompData';
import { translate } from '@/i18n';
import experiment from 'experiment';

const { url } = util;
const mediaManagerMediaTypes = constants.MEDIA_MANAGER_MEDIA_TYPES;
const {
  SEARCH_SECTIONS_MEDIA_FROM_WIX_TITLES_MAP,
  SEARCH_SECTIONS_TITLES_MAP,
  SECTIONS_MEDIA_FROM_WIX_TITLES_MAP,
  SECTIONS_TITLES_MAP,
  MEDIA_SOURCES,
  MEDIA_MANAGER_UPLOAD_SOURCES,
  TRANSCODING_STATUSES,
  PUBLIC_MEDIA_ROOT,
  MEDIA_MANAGER_OPEN_SOURCE_BASE,
  PANEL_BI_SOURCE_SUFFIX,
  MEDIA_MANAGER_PANEL_SECTION_ID,
  MEDIA_PRODUCER,
  SHUTTERSTOCK_MEDIA_TYPE,
} = mediaManagerPanelConstants;

const DEFAUTL_VIDEO_RATIO = 1.777;
const DEFAULT_PICTURE_RATIO = 1;

const getItemMediaBaseHost = (itemSource) => {
  switch (itemSource) {
    case MEDIA_SOURCES.PRIVATE:
    case MEDIA_SOURCES.PUBLIC:
      return 'https://static.wixstatic.com';
    case MEDIA_SOURCES.SHUTTERSTOCK:
    default:
      return '';
  }
};

const isShutterStockItem = (item) => item.source === MEDIA_SOURCES.SHUTTERSTOCK;

const isWixMediaItem = (item) => item.source === MEDIA_SOURCES.PUBLIC;

const isPrivateMediaItem = (item) => item.source === MEDIA_SOURCES.PRIVATE;

const isPicture = (item) => item.mediaType === mediaManagerMediaTypes.PICTURE;

const getResizedPrivateMediaPicture = (
  itemSource,
  pictureUrl,
  width,
  height,
  mode,
) =>
  url.joinURL(
    getItemMediaBaseHost(itemSource),
    resize(pictureUrl, { width, height, mode }, ResizeAPI.Vanbrandt),
  );

const generatePicturePreviewUrl = (item, width, height, mode) => {
  if (isShutterStockItem(item)) {
    return item.previewUrl;
  }

  return getResizedPrivateMediaPicture(
    item.source,
    item.fileUrl,
    width,
    height,
    mode,
  );
};

const generatePictureThumbnailUrl = (item, width, height, mode) => {
  if (isShutterStockItem(item)) {
    return item.thumbnailUrl;
  }

  if (isWixMediaItem(item) && isWixMediaFilePaid(item)) {
    return item.thumbnailUrl;
  }

  const thumbnailUrl = getResizedPrivateMediaPicture(
    item.source,
    item.fileUrl,
    width,
    height,
    mode,
  );

  const isWebp = thumbnailUrl.match(/webp$/i);

  if (isWebp && util.browserUtil.isSafari()) {
    return `${thumbnailUrl}.png`;
  }

  return thumbnailUrl;
};

const getVideoThumbnailUrl = (item, width, height, mode) => {
  const { thumbnailUrl } = item;

  if (isShutterStockItem(item)) {
    return thumbnailUrl;
  }

  const images = item?.fileOutput?.image ?? [];

  const privateMediaImageUrl = images.length
    ? // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/last
      _.last(images).url || thumbnailUrl
    : thumbnailUrl;

  return privateMediaImageUrl === thumbnailUrl
    ? thumbnailUrl
    : getResizedPrivateMediaPicture(
        item.source,
        privateMediaImageUrl,
        width,
        height,
        mode,
      );
};

const getExtension = (str) =>
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/split
  _(str || '')
    .split('.')
    .last();

const getPrivateMediaFileExtension = (item) => {
  const { fileUrl } = item;
  const possibleExtension = getExtension(fileUrl);

  return possibleExtension === fileUrl ? '' : possibleExtension;
};

const getWixMediaFileExtension = (item) =>
  isVideoItem(item) ? 'mp4' : getExtension(item.fileName);

const getShutterstockFileExtension = (item) =>
  isVideoItem(item) ? 'mp4' : 'jpg';

const getFileExtension = (item) => {
  if (isPrivateMediaItem(item)) {
    return getPrivateMediaFileExtension(item);
  }

  if (isWixMediaItem(item)) {
    return getWixMediaFileExtension(item);
  }

  return getShutterstockFileExtension(item);
};

const isItemOfType = (item, mediaType) => item.mediaType === mediaType;

const isItemOneOfTypes = (item, mediaTypes) =>
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/includes
  _.includes(mediaTypes, item.mediaType);

const appendZeroIfFigureSmallerTen = (n) => (n < 10 ? `0${n}` : `${n}`);

const getReadableDuration = (duration) => {
  const seconds = parseInt(duration / 1000, 10);
  const sec = seconds % 60;
  let min = parseInt(seconds / 60, 10);
  let hours = 0;

  if (min > 60) {
    hours = parseInt(min / 60, 10);
    min %= 60;
  }

  return `${
    hours ? `${appendZeroIfFigureSmallerTen(hours)}:` : ''
  }${appendZeroIfFigureSmallerTen(min)}:${appendZeroIfFigureSmallerTen(sec)}`;
};

const getDefaultPreviewRatio = (item) =>
  isItemOfType(item, mediaManagerMediaTypes.VIDEO)
    ? DEFAUTL_VIDEO_RATIO
    : DEFAULT_PICTURE_RATIO;

const getPreviewRatio = (item) =>
  (isShutterStockItem(item)
    ? item?.fileInfo?.aspect
    : item.width / item.height) || getDefaultPreviewRatio(item);

const getDesiredValue = (value, minValue, maxValue) => {
  const minMaxValue = Math.min(value, maxValue);

  return minMaxValue < minValue ? minMaxValue : Math.max(minMaxValue, minValue);
};

const getPreviewDimension = (
  item,
  minWidth,
  maxWidth,
  minHeight,
  maxHeight,
) => {
  const ratio = getPreviewRatio(item);
  const desiredWidth =
    isShutterStockItem(item) || !item.width
      ? maxWidth
      : getDesiredValue(item.width, minWidth, maxWidth);
  const desiredHeight =
    isShutterStockItem(item) || !item.height
      ? maxHeight
      : getDesiredValue(item.height, minHeight, maxHeight);

  let probablyDimension =
    ratio >= 1
      ? {
          width: desiredWidth,
          height: Math.round(desiredWidth / ratio),
        }
      : {
          width: Math.round(desiredHeight * ratio),
          height: desiredHeight,
        };

  if (probablyDimension.width > maxWidth) {
    probablyDimension = {
      width: maxWidth,
      height: Math.round(maxWidth / ratio),
    };
  }

  return probablyDimension;
};

const isRecentlyAddedSection = (sectionId) =>
  sectionId ===
  mediaManagerPanelConstants.MEDIA_MANAGER_PANEL_SECTION_ID.SITE_FILES;

const timeNow = () => Date.now();

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line you-dont-need-lodash-underscore/replace
const filterFileUrl = (str) => _.replace(str, /.*\//, '');

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line you-dont-need-lodash-underscore/replace
const filterDomain = (str) => _.replace(str, /(http:\/\/[^\/]+\/)/i, '');

const getBestQualityTrack = ({ audio }) =>
  (audio || [])
    .filter((track) => track.format === 'mp3')
    .sort((track1, track2) => (track1.key > track2.key ? -1 : 1))[0];

const prepareItemBeforeAddComponent = (item) => {
  const {
    id,
    mediaType,
    name,
    width,
    height,
    fileInput,
    fileOutput,
    embedHtml,
    externalSources,
    labels,
    tags,
    fileUrl = '',
  } = item;

  let fileOutputUrl = fileUrl;
  if (isItemOfType(item, mediaManagerMediaTypes.MUSIC)) {
    const bestQualityTrack = getBestQualityTrack(fileOutput || {});
    fileOutputUrl = bestQualityTrack?.url || fileUrl;
  }

  const mapped = {
    id,
    mediaType,
    fileName: filterFileUrl(fileOutputUrl),
    uri: filterFileUrl(fileOutputUrl), // for TPA-SDK
    fileUrl: fileOutputUrl,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/slice
    title: _.slice(name, 0, 40).join(''),
    fileInput,
    fileOutput,
    labels,
    tags,
  };

  if (
    isItemOneOfTypes(item, [
      mediaManagerMediaTypes.PICTURE,
      mediaManagerMediaTypes.VIDEO,
      mediaManagerMediaTypes.SWF,
    ])
  ) {
    mapped.width = width;
    mapped.height = height;
  }

  if (isItemOfType(item, mediaManagerMediaTypes.VIDEO)) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/is-undefined
    mapped.embedHtml = _.isUndefined(embedHtml) ? null : embedHtml;
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/is-undefined
    mapped.externalSources = _.isUndefined(externalSources)
      ? null
      : externalSources;
    mapped.fileBaseUrl = fileOutputUrl.split('/').slice(0, 2).join('/');
  }

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
  _.forEach(['thumbnailUrl', 'previewUrl', 'source'], (attrName) => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/is-undefined
    if (!_.isUndefined(mapped[attrName])) {
      return;
    }

    mapped[attrName] = /Url/i.test(attrName)
      ? filterDomain(item[attrName])
      : item[attrName];
  });

  return mapped;
};

const isAddMediaSection = (sectionId) =>
  sectionId === MEDIA_MANAGER_PANEL_SECTION_ID.ADD_MEDIA;

const isRecommendedMediaCard = (sectionId) =>
  sectionId === MEDIA_MANAGER_PANEL_SECTION_ID.RECOMMENDED_MEDIA_CARD;

const isRecommendedMediaSection = (sectionId) =>
  sectionId === MEDIA_MANAGER_PANEL_SECTION_ID.RECOMMENDED_MEDIA;

const isCreateMediaSection = (sectionId) =>
  sectionId === MEDIA_MANAGER_PANEL_SECTION_ID.CREATE_MEDIA;

const isUploadAddMediaSource = (source) =>
  source === MEDIA_MANAGER_UPLOAD_SOURCES.COMPUTER;

const isVideoFileTranscodingFailed = (item) => {
  const { transcodingStatus } = item;

  return transcodingStatus === TRANSCODING_STATUSES.FAILED;
};

const isVideoFileTranscodedSuccessfully = (item) => {
  const { transcodingStatus } = item;

  return (
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/is-undefined
    _.isUndefined(transcodingStatus) ||
    transcodingStatus === TRANSCODING_STATUSES.READY
  );
};

const isVideoFileTranscodingInProgress = (item) => {
  const { transcodingStatus } = item;

  return (
    Boolean(transcodingStatus) &&
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/includes
    !_.includes(
      [TRANSCODING_STATUSES.READY, TRANSCODING_STATUSES.FAILED],
      transcodingStatus,
    )
  );
};

const canShowPreviewForItem = (item) =>
  isItemOneOfTypes(item, [
    mediaManagerMediaTypes.PICTURE,
    mediaManagerMediaTypes.SHAPE,
    mediaManagerMediaTypes.VIDEO,
  ]);

const isVideoItem = (item) => item.mediaType === mediaManagerMediaTypes.VIDEO;

const getNthOrFirstItem = (array, index = 0) =>
  array[index] ? array[index] : _.head(array);

const getTopVisibleSectionIndex = (
  sectionsDimensionIntervals,
  containerScrollTop,
  containerHeight,
) => {
  let navigationSelectedSectionIndex = 0;

  if (containerScrollTop > 0) {
    const isBottomReached =
      containerScrollTop + containerHeight >=
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/last
      _.last(sectionsDimensionIntervals)[1];

    navigationSelectedSectionIndex = isBottomReached
      ? sectionsDimensionIntervals.length - 1
      : // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/reduce
        _.reduce(
          sectionsDimensionIntervals,
          (resultIndex, gap, index) =>
            gap[0] <= containerScrollTop && gap[1] > containerScrollTop
              ? index
              : resultIndex,
          0,
        );
  }

  return navigationSelectedSectionIndex;
};

const transformSectionsDOMInstancesToDimensionIntervals = (instances) =>
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/map
  _.map(instances, (instance) => {
    const instanceOffsetTop = Math.floor(instance.offsetTop);

    return [
      instanceOffsetTop,
      instanceOffsetTop +
        Math.floor(instance.offsetHeight) +
        parseInt(window.getComputedStyle(instance).marginBottom, 10),
    ];
  });

const shiftDOMInstanceIntervals = (intervals, shiftSize) =>
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/map
  _.map(intervals, ([intervalStart, intervalEnd]) => [
    intervalStart - shiftSize,
    intervalEnd - shiftSize,
  ]);

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line you-dont-need-lodash-underscore/to-lower
const sectionIdToBiField = (sectionId) => _.toLower(sectionId);

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line you-dont-need-lodash-underscore/join
const tagsToString = (tags = []) => _.join(tags, ',');

const getComponentStructureCreator = (item, options = {}) => {
  const { isSocialIcon } = options;

  if (isSocialIcon) {
    return addComponentData.socialIcon;
  }

  return addComponentData[item.mediaType];
};

const getComposedComponentStructureCreator = ({ isSocialIcon } = {}) => {
  if (isSocialIcon) {
    return addComponentData.socialBar;
  }

  return null;
};

const isSocialIconsRoot = (root) => root === PUBLIC_MEDIA_ROOT.SOCIAL_ICONS;

const _getAllSubcategoriesList = (category) => {
  const { subFolders } = category;

  return subFolders?.length
    ? // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/reduce
      _.reduce(
        subFolders,
        (subCategoriesList, subCategory) => [
          ...subCategoriesList,
          ..._getAllSubcategoriesList(subCategory),
        ],
        [],
      )
    : [category.id];
};

const mapPublicMediaSubcategoryToRoot = (tree) =>
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/reduce
  _.reduce(
    tree,
    (rootsMap, rootCategory) => {
      const { id: publicMediaRoot } = rootCategory;
      const subCategoriesList = _getAllSubcategoriesList(rootCategory);

      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      return _.assign(
        {},
        rootsMap,
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/reduce
        _.reduce(
          subCategoriesList,
          (subcategoriesMap, subCategory) =>
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line you-dont-need-lodash-underscore/assign
            _.assign({}, subcategoriesMap, { [subCategory]: publicMediaRoot }),
          {},
        ),
      );
    },
    {},
  );

const _extractFolderIdFromSearchPath = (chunks) => {
  const source = _.nth(chunks, 1);

  switch (source) {
    case MEDIA_SOURCES.PRIVATE:
      return _.nth(chunks, 3) || '';
    case MEDIA_SOURCES.PUBLIC:
      return _.nth(chunks, 4);
    default:
      return '';
  }
};

const extractFolderIdFromPath = (path) => {
  if (!path) {
    return '';
  }

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/split
  const chunks = _.split(path, '/');
  const probablySource = _.head(chunks);

  if (probablySource === 'search') {
    return _extractFolderIdFromSearchPath(chunks);
  }

  switch (probablySource) {
    case MEDIA_SOURCES.PRIVATE:
      return _.nth(chunks, 1) || '';
    case MEDIA_SOURCES.PUBLIC:
      return _.nth(chunks, 2);
    default:
      return '';
  }
};

const generateMediaManagerOpenSource = (sectionId, isSearching = false) =>
  `${MEDIA_MANAGER_OPEN_SOURCE_BASE}_${PANEL_BI_SOURCE_SUFFIX[sectionId]}${
    isSearching ? '_search' : ''
  }`;

const generateMediaManagerAddMediaSectionOpenSource = (source) =>
  `${MEDIA_MANAGER_OPEN_SOURCE_BASE}_add_media_section_${source}`;

const findFirstParentButtonNode = (childNode) => {
  const _findButtonNode = (node) => {
    if (!node || !node.tagName) {
      return null;
    }

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/to-lower
    return _.toLower(node.tagName) === 'button'
      ? node
      : _findButtonNode(node.parentNode);
  };

  return _findButtonNode(childNode);
};

const getComponentStructureSkin = (structure) =>
  structure?.style?.skin || structure.skin || structure.style;

const isWixMediaFilePaid = (item) => {
  const systemTags = item.systemTags || [];

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/includes
  return _.includes(systemTags, 'paid');
};

const selectExpandedCategories = (selectedCategoriesIds, categories) =>
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/map
  _.map(categories, (category) =>
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/includes
    _.includes(selectedCategoriesIds, category.id) // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      ? _.assign({}, category, { isSelected: true })
      : category,
  );

/**
 *
 * @param {Array} sections
 * @param predicate
 * @returns {*|LodashWrapper|Object}
 */
const filterOutSectionsItemsIds = (sections, predicate = (_item) => true) =>
  _(sections).flatMap('items').compact().filter(predicate).map('id').value();

const sectionsListToObj = (sections) => _.keyBy(sections, 'id');

const isSiteMediaTokenFromTemplateSite = (siteMediaToken) => {
  try {
    const { metasiteId } = JSON.parse(
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/split
      JSON.parse(window.atob(_.split(siteMediaToken, '.')[2])).data,
    );

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/includes
    return _.includes(metasiteId, '_template_');
  } catch (e) {
    return false;
  }
};

const getMediaProducer = (item) =>
  isWixMediaItem(item) ? MEDIA_PRODUCER.WIX : MEDIA_PRODUCER.SHUTTERSTOCK;

const generateBiSectionId = (sectionId, item) => {
  if (!_.isEqual(sectionId, MEDIA_MANAGER_PANEL_SECTION_ID.SITE_FILES)) {
    return sectionId;
  }

  const tags = item?.tags ?? [];
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/includes
  const isShutterstockFileOrigin = _.includes(tags, '_shutterstock');
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/includes
  const isWixstockFileOrigin = _.includes(tags, '_wixstock');

  let fileOrigin = '';

  if (isShutterstockFileOrigin) {
    fileOrigin = MEDIA_PRODUCER.SHUTTERSTOCK;
  } else if (isWixstockFileOrigin) {
    fileOrigin = MEDIA_PRODUCER.WIX;
  }

  return fileOrigin ? `${fileOrigin}_${item.mediaType}` : sectionId;
};

const generateMediaManagerOpenPathFromUrlParam = (param) => {
  const { OPEN_BY_URL_SOURCE } = mediaManagerPanelConstants;

  switch (param) {
    case OPEN_BY_URL_SOURCE.SITE_FILES:
      return MEDIA_SOURCES.PRIVATE;
    case OPEN_BY_URL_SOURCE.WIX_IMAGES:
      return `${MEDIA_SOURCES.PUBLIC}`;
    case OPEN_BY_URL_SOURCE.WIX_VIDEOS:
      return `${MEDIA_SOURCES.PUBLIC}/8e256233-1752-4026-9341-54036e7f875e`;
    case OPEN_BY_URL_SOURCE.WIX_EXCLUSIVES_VIDEOS:
      return `${MEDIA_SOURCES.PUBLIC}/8e256233-1752-4026-9341-54036e7f875e/834e0f2d-9836-40c8-a73a-2a4627a84ca2`;
    case OPEN_BY_URL_SOURCE.SHUTTERSTOCK_IMAGES:
      return `${MEDIA_SOURCES.SHUTTERSTOCK}/${SHUTTERSTOCK_MEDIA_TYPE.IMAGE}`;
    case OPEN_BY_URL_SOURCE.SHUTTERSTOCK_VIDEOS:
      return `${MEDIA_SOURCES.SHUTTERSTOCK}/${SHUTTERSTOCK_MEDIA_TYPE.VIDEO}`;
    case OPEN_BY_URL_SOURCE.UNSPLASH:
      return `${MEDIA_SOURCES.UNSPLASH}`;
    default:
      return MEDIA_SOURCES.PRIVATE;
  }
};

const getMediaManagerSectionIdBasedOnUrlParam = (param) => {
  const { OPEN_BY_URL_SOURCE } = mediaManagerPanelConstants;

  switch (param) {
    case OPEN_BY_URL_SOURCE.SITE_FILES:
      return MEDIA_MANAGER_PANEL_SECTION_ID.SITE_FILES;
    case OPEN_BY_URL_SOURCE.WIX_IMAGES:
      return MEDIA_MANAGER_PANEL_SECTION_ID.WIX_IMAGES;
    case OPEN_BY_URL_SOURCE.WIX_VIDEOS:
      return MEDIA_MANAGER_PANEL_SECTION_ID.WIX_VIDEOS;
    case OPEN_BY_URL_SOURCE.SHUTTERSTOCK_IMAGES:
      return MEDIA_MANAGER_PANEL_SECTION_ID.SHUTTERSTOCK_IMAGES;
    case OPEN_BY_URL_SOURCE.SHUTTERSTOCK_VIDEOS:
      return MEDIA_MANAGER_PANEL_SECTION_ID.SHUTTERSTOCK_VIDEOS;
    case OPEN_BY_URL_SOURCE.UNSPLASH:
      return MEDIA_MANAGER_PANEL_SECTION_ID.SITE_FILES; // TODO: update to unsplash section id, when unsplash section will be ready
    default:
      return MEDIA_MANAGER_PANEL_SECTION_ID.SITE_FILES;
  }
};

const createEmptySection = (
  isSearching: boolean,
  sectionId: AnyFixMe,
  options = {},
) => {
  let titlesMap = null;

  if (experiment.isOpen('se_mediaPanelShowPaidMediaFromWix')) {
    titlesMap = isSearching
      ? SEARCH_SECTIONS_MEDIA_FROM_WIX_TITLES_MAP
      : SECTIONS_MEDIA_FROM_WIX_TITLES_MAP;
  } else {
    titlesMap = isSearching ? SEARCH_SECTIONS_TITLES_MAP : SECTIONS_TITLES_MAP;
  }

  return {
    id: sectionId,
    title: translate(titlesMap[sectionId]),
    items: [] as AnyFixMe,
    action: null as AnyFixMe,
    shouldDisplayPresetName: false,
    presetWidth: 90,
    options,
  };
};

export {
  isRecentlyAddedSection,
  isItemOfType,
  isItemOneOfTypes,
  isPicture,
  isSocialIconsRoot,
  generatePictureThumbnailUrl,
  generatePicturePreviewUrl,
  getVideoThumbnailUrl,
  getPrivateMediaFileExtension,
  getReadableDuration,
  getPreviewRatio,
  getPreviewDimension,
  timeNow,
  prepareItemBeforeAddComponent,
  isAddMediaSection,
  isRecommendedMediaCard,
  isRecommendedMediaSection,
  isCreateMediaSection,
  isUploadAddMediaSource,
  canShowPreviewForItem,
  isVideoFileTranscodingFailed,
  isVideoFileTranscodedSuccessfully,
  isVideoFileTranscodingInProgress,
  getNthOrFirstItem,
  getTopVisibleSectionIndex,
  transformSectionsDOMInstancesToDimensionIntervals,
  shiftDOMInstanceIntervals,
  sectionIdToBiField,
  tagsToString,
  getComponentStructureCreator,
  mapPublicMediaSubcategoryToRoot,
  extractFolderIdFromPath,
  getComposedComponentStructureCreator,
  generateMediaManagerOpenSource,
  generateMediaManagerAddMediaSectionOpenSource,
  findFirstParentButtonNode,
  getComponentStructureSkin,
  isWixMediaFilePaid,
  isWixMediaItem,
  isShutterStockItem,
  selectExpandedCategories,
  addComponentData,
  filterOutSectionsItemsIds,
  isVideoItem,
  sectionsListToObj,
  isSiteMediaTokenFromTemplateSite,
  getMediaProducer,
  getFileExtension,
  generateBiSectionId,
  generateMediaManagerOpenPathFromUrlParam,
  getMediaManagerSectionIdBasedOnUrlParam,
  createEmptySection,
};
