import type { GFPPData } from '@wix/editor-component-types';

function getGfppActionsList(gfppData: GFPPData) {
  return Object.values(gfppData).reduce((acc, gfppBtnData) => {
    if (!gfppBtnData) return acc;

    const isGFPPActionsList = Array.isArray(gfppBtnData);

    if (isGFPPActionsList) {
      return acc.concat(gfppBtnData.map(({ label }) => label));
    }

    const isGFPPActionsDictionary =
      !gfppBtnData.isDisabled && Object.keys(gfppBtnData).length;

    if (isGFPPActionsDictionary) {
      return acc.concat(Object.keys(gfppBtnData));
    }

    return acc;
  }, []);
}

export default {
  getGfppActionsList,
};
