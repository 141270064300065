:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._siteCreationFPD_1fosz_7 {
  background-color: #fff;
  width: 100%;
  height: 100%;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 1;
  visibility: visible;
  transition: visibility 0s 300ms, opacity 300ms linear;
  z-index: 3; }
  ._siteCreationFPD_1fosz_7.hidden {
    opacity: 0;
    visibility: hidden; }

._siteCreationFPDContentWrapper_1fosz_23 {
  position: relative;
  height: calc(100% - 102px);
  overflow: hidden; }
  @media only screen and (max-height: 900px) {
    ._siteCreationFPDContentWrapper_1fosz_23 {
      height: calc(100% - 82px); } }

._siteCreationFPDTitlesWrapper_1fosz_31 {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 82px 0 80px; }
  ._siteCreationFPDTitlesWrapper_1fosz_31 span {
    font-family: Madefor; }
  ._siteCreationFPDTitlesWrapper_1fosz_31 .main-fpd-title {
    font-weight: 700;
    font-size: 32px;
    margin-bottom: 18px; }
  ._siteCreationFPDTitlesWrapper_1fosz_31 .secondary-fpd-title {
    font-size: 18px; }
  @media only screen and (min-width: 1440px) {
    ._siteCreationFPDTitlesWrapper_1fosz_31 .main-fpd-title {
      font-size: 42px; } }

._siteCreationFPDColumnsWrapper_1fosz_48 {
  position: relative;
  width: 100%;
  left: 50%;
  transform-origin: 0 0;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: flex-start; }

._siteCreationFPDPlaceholder_1fosz_58 {
  background-size: 100%;
  background-position: top;
  background-repeat: no-repeat; }
