._illustration_1lml6_1 {
  width: 48px;
  height: 48px; }

._item_1lml6_5:not(:last-child) {
  border-bottom: 1px solid #e1e3e6; }

._fadeAndSlideOut_1lml6_8 {
  opacity: 1; }

._fadeAndSlideOutActive_1lml6_11 {
  opacity: 0;
  height: 0;
  padding-top: 0;
  padding-bottom: 0;
  transition: all 250ms; }
