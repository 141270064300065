'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')
const util = require('@/util')

module.exports = {
    'type': addPanelDataConsts.SECTIONS_TYPES.PRESET,
    'hide': false,
    'title': 'Studio Subscribe Forms',
    'showSectionHeader': true,
    'additionalBehaviours': {
        'labelMode': addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
        'infoIcon': addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
        'hoverImageAction': addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
        'iconEnabledForComps': {}
    },
    'props': {
        'image': `addPanelData/sections/getSubscribersSection_${util.languages.getLanguageCode()}/getSubscribersSection_${util.languages.getLanguageCode()}.png`,
        'imageHover': null,
        'items': [
            {
                id: 'Subscribe_Forms_Studio_1',
                structure: {
                    'type': 'Component',
                    'skin': 'wysiwyg.common.components.subscribeform.viewer.skins.SubscribeFormPlaceholderSkin',
                    'layout': {
                        'width': 405,
                        'height': 96,
                        'x': 10,
                        'y': 7,
                        'scale': 1,
                        'rotationInDegrees': 0,
                        'anchors': [{
                            'distance': 0,
                            'originalValue': 500,
                            'type': 'BOTTOM_PARENT',
                            'locked': true,
                            'targetComponent': 'i1u33'
                        }],
                        'fixedPosition': false
                    },
                    'componentType': 'wysiwyg.common.components.subscribeform.viewer.SubscribeForm',
                    'data': {
                        'type': 'SubscribeForm',
                        'metaData': {
                            'isPreset': true,
                            'schemaVersion': '1.0',
                            'isHidden': false
                        },
                        'toEmailAddress': '',
                        'bccEmailAddress': '',
                        'firstNameFieldLabel': 'Name',
                        'lastNameFieldLabel': 'Last Name',
                        'emailFieldLabel': 'Email',
                        'phoneFieldLabel': 'Mobile Number',
                        'subscribeFormTitle': 'Subscribe for Updates',
                        'textDirection': 'left',
                        'submitButtonLabel': 'Subscribe Now',
                        'successMessage': 'Congrats! You\'re subscribed.',
                        'errorMessage': 'Please add a valid email.',
                        'validationErrorMessage': 'Please add required info.',
                        'onSubmitBehavior': 'message'
                    },
                    'props': {
                        'type': 'SubscribeFormProperties',
                        'metaData': {
                            'schemaVersion': '1.0'
                        },
                        'hiddenFirstNameField': false,
                        'hiddenLastNameField': false,
                        'hiddenEmailField': true,
                        'hiddenPhoneField': false,
                        'requiredFirstNameField': false,
                        'requiredLastNameField': false,
                        'requiredEmailField': true,
                        'requiredPhoneField': false,
                        'useCookie': true
                    },
                    'style': 'SubscribeForm_1',
                    'mobileStructure': {
                        'type': 'Component',
                        'skin': 'wysiwyg.common.components.subscribeform.viewer.skins.SubscribeFormPlaceholderSkin',
                        'layout': {
                            'width': 280,
                            'height': 66,
                            'x': 20,
                            'y': 10,
                            'scale': 1,
                            'rotationInDegrees': 0,
                            'anchors': [{
                                'distance': 10,
                                'topToTop': 10,
                                'originalValue': 86,
                                'type': 'BOTTOM_PARENT',
                                'locked': true,
                                'targetComponent': 'i1u33'
                            }],
                            'fixedPosition': false
                        },
                        'componentType': 'wysiwyg.common.components.subscribeform.viewer.SubscribeForm',
                        'data': {
                            'type': 'SubscribeForm',
                            'metaData': {
                                'isPreset': true,
                                'schemaVersion': '1.0',
                                'isHidden': false
                            },
                            'toEmailAddress': '',
                            'bccEmailAddress': '',
                            'firstNameFieldLabel': 'Name',
                            'lastNameFieldLabel': 'Last Name',
                            'emailFieldLabel': 'Email',
                            'phoneFieldLabel': 'Mobile Number',
                            'subscribeFormTitle': 'Subscribe for Updates',
                            'textDirection': 'left',
                            'submitButtonLabel': 'Subscribe Now',
                            'successMessage': 'Congrats! You\'re subscribed.',
                            'errorMessage': 'Please add a valid email.',
                            'validationErrorMessage': 'Please add required info.',
                            'onSubmitBehavior': 'message'
                        },
                        'props': {
                            'type': 'SubscribeFormProperties',
                            'metaData': {
                                'schemaVersion': '1.0'
                            },
                            'hiddenFirstNameField': false,
                            'hiddenLastNameField': false,
                            'hiddenEmailField': true,
                            'hiddenPhoneField': false,
                            'requiredFirstNameField': false,
                            'requiredLastNameField': false,
                            'requiredEmailField': true,
                            'requiredPhoneField': false,
                            'useCookie': true
                        },
                        'style': 'SubscribeForm_1'
                    }
                },
                preset: {'rect': {width: 324, height: 110, 'x': 0, 'y': 0}, 'label': ''}
            }
        ],
        'compTypes': ['wysiwyg.common.components.subscribeform.viewer.SubscribeForm']
    },
    'help': {'hide': false, 'text': 'add_section_info_text_subscribeform'}
}
