// @ts-nocheck
import * as util from '@/util';
import { runInContext } from '../../../common/utils';

function navigateTo(editorAPI, appData, token, options) {
  const pageId = options?.pageRef?.id;
  const pageLink = options?.pageLink;
  const pageIdOrPageLink = pageId || pageLink;

  if (pageIdOrPageLink) {
    return new Promise(function (resolve) {
      editorAPI.pages.navigateTo(pageIdOrPageLink, function () {
        resolve();
      });
    });
  }
}

function rename(editorAPI, appData, token, options) {
  const pageId = options.pageRef.id;
  const validationError = util.validate.legacyPageNameValidator(options.title);
  if (!validationError) {
    runInContext(appData.appDefinitionId, editorAPI, () =>
      editorAPI.pages.data.update(pageId, { title: options.title }),
    );
  }
  return validationError;
}

function add(editorAPI, appData, token, options) {
  const shouldNavigateToPage =
    typeof options.shouldNavigateToPage === 'undefined'
      ? true
      : Boolean(options.shouldNavigateToPage);
  return new Promise(function (resolve) {
    const pageRef = runInContext(appData.appDefinitionId, editorAPI, () =>
      editorAPI.pages.add(
        options.title,
        options.definition,
        options.shouldAddMenuItem,
        true,
      ),
    );
    if (!pageRef) {
      resolve(null);
      return;
    }

    if (shouldNavigateToPage) {
      editorAPI.pages.navigateTo(pageRef.id, function () {
        resolve(pageRef);
      });
    } else {
      resolve(pageRef);
    }
  });
}

function updateData(editorAPI, appData, token, options) {
  const pageId = options.pageRef.id;
  runInContext(appData.appDefinitionId, editorAPI, () =>
    editorAPI.pages.data.update(pageId, options.data),
  );
}

function remove(editorAPI, appData, token, options) {
  return new Promise((resolve, reject) => {
    const { appDefinitionId } = appData;
    const { pageRef, pageToNavigateAfterRemove, shouldShowEditorRemovePanel } =
      options;

    const pageData = editorAPI.dsRead.pages.data.get(pageRef.id);

    if (!pageData) {
      return reject(new Error(`page with id '${pageRef.id}' doesn't exist`));
    }

    if (appDefinitionId !== pageData.managingAppDefId) {
      return reject(
        new Error(
          `page with id '${pageRef.id}' can not be deleted. page is not managed by this application`,
        ),
      );
    }

    const viewMode = editorAPI.dsRead.viewMode.get();
    if (viewMode === editorAPI.dsRead.viewMode.VIEW_MODES.MOBILE) {
      return reject(
        new Error(
          `page with id '${pageRef.id}' can not be deleted. deletion is not allowed in mobile view`,
        ),
      );
    }

    runInContext(appData.appDefinitionId, editorAPI, () =>
      editorAPI.pages.remove(
        pageRef.id,
        resolve,
        () => reject(new Error('removed canceled')),
        { pageToNavigateAfterRemove, shouldShowEditorRemovePanel },
      ),
    );
  });
}

function duplicateGroupsPermissions(
  editorAPI,
  appData,
  token,
  { sourcePageRef, destinationPageRef },
) {
  return new Promise((resolve, reject) => {
    editorAPI.pages.settings
      .duplicateGroupsPermissions(sourcePageRef.id, destinationPageRef.id)
      .then(
        () => resolve(),
        (e) => reject(e),
      );
  });
}

export default {
  navigateTo,
  rename,
  add,
  remove,
  data: {
    update: updateData,
  },
  permissions: {
    duplicateGroupsPermissions,
  },
};
