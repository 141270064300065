import React from 'react';
import { translate } from '@/i18n';

import { Text, Divider } from '@wix/wix-base-ui';
import collectionsStyles from '../collectionsSection/collectionsSection.scss';
import emptyExternalDbItemStyle from './emptyExternalDbItem.scss';

export interface EmptyExternalDbItemProps {
  namespace: string;
}

export const EmptyExternalDbItem: React.FC<EmptyExternalDbItemProps> = ({
  namespace,
}) => {
  return (
    <>
      <div className={collectionsStyles.sectionHeader}>
        <Text size="small" shouldTranslate={false} dataHook="section-heading">
          {namespace}
        </Text>
      </div>
      <Divider direction="horizontal" long />
      <div className={emptyExternalDbItemStyle.emptyText}>
        <Text
          size="small"
          shouldTranslate={false}
          disabled={true}
          skin="secondary"
          dataHook="empty-section-text"
        >
          {translate(
            'CONTENT_MANAGER_6_BUTTON_EXTERNAL_COLLECTIONS_EMPTY_STATE_TEXT',
          )}
        </Text>
      </div>
    </>
  );
};
