const i18n = require("@/i18n");

module.exports = {
  id: "Stylable_StylableButton_26",
  preset: { rect: { width: 156, height: 71, x: 112, y: 745 }, tags: null },
  structure: {
    parent: "comp-knzvqsjj",
    style: {
      styleType: "custom",
      componentClassName: "wixui.StylableButton",
      style: {
        properties: {
          "$st-css":
            ":import{\n    -st-from: 'wix-ui-santa/index.st.css';\n    -st-named: StylableButton\n}\n.root{\n    -st-extends: StylableButton;\n    transition: all 0.2s ease, visibility 0s;\n    background: linear-gradient(126deg, #5f5bcd 24%, #ff5700 100%);\n    border-radius: 0px;\n    border: 0px solid rgb(71, 67, 197)\n}\n.root:hover {\n    background: linear-gradient(307deg, #5f5bcd 24%, #ff5700 100%);\n}\n.root:disabled {\n    background: #EDE5E5;\n}\n.root[disabled]{\n    background: #E2E2E2\n}\n.root[disabled]::label{\n    color: #8F8F8F\n}\n.root[disabled]::icon{\n    fill: #8F8F8F\n}\n.root::container{\n    transition: inherit\n}\n.root::label{\n    transition: inherit;\n    display: initial;\n    color: #FFFFFF;\n    font-family: helvetica-w01-roman,helvetica-w02-roman,helvetica-lt-w10-roman,sans-serif;\n    font-size: 16px;\n    letter-spacing: 0.15em\n}\n.root::icon{\n    transition: inherit;\n    fill: #993500;\n    width: 34px;\n    height: 34px;\n    display: none\n}",
        },
        propertiesSource: { "$st-css": "value" },
      },
      pageId: "",
      compId: "",
      type: "ComponentStyle",
      metaData: {
        isHidden: false,
        sig: "4ie-531",
        pageId: "c1dmp",
        schemaVersion: "1.0",
        isPreset: false,
      },
      skin: "wixui.skins.Skinless",
    },
    activeModes: {},
    data: {
      type: "StylableButton",
      metaData: {
        isHidden: false,
        sig: "hmj-1193",
        pageId: "c1dmp",
        schemaVersion: "1.0",
        isPreset: false,
      },
      label: i18n.translate("buttons_text_preset_say_hello"),
      svgId: "11062b_a9eb1c9245c8402ba29c31611e4efaf1.svg",
    },
    components: [],
    componentType: "wixui.StylableButton",
    id: "comp-kmdetymr",
    layout: {
      x: 122,
      fixedPosition: false,
      y: 759,
      scale: 1,
      height: 40,
      rotationInDegrees: 0,
      width: 132,
    },
    type: "Component",
    props: {
      type: "StylableButtonProperties",
      metaData: { schemaVersion: "1.0", sig: "hmj-1177", pageId: "c1dmp" },
    },
    metaData: { sig: "inq-338", pageId: "c1dmp" },
    skin: "wixui.skins.Skinless",
  },
};
