import React, { type ComponentType } from 'react';
import experiment from 'experiment';

import EditorSlotsStateProvider from './editorSlotsStateProvider';

export function withEditorSlotsState<TOwnProps>(
  SlotComponent: ComponentType<TOwnProps>,
) {
  const WithEditorSlotsState = (props: TOwnProps) => {
    if (experiment.isOpen('se_editorPlugins2')) {
      return (
        <EditorSlotsStateProvider>
          <SlotComponent {...props} />
        </EditorSlotsStateProvider>
      );
    }

    return null;
  };

  return WithEditorSlotsState;
}
