'use strict'

module.exports = {
    "id": "add_preset_popups_Sale_1",
    "structure": {
        "type": "Page",
        "id": "kjvow",
        "componentTypeForBI": "wysiwyg.viewer.components.PopupContainer",
        "components": [{
            "type": "Container",
            "id": "comp-ilw39qkn",
            "components": [{
                "type": "Component",
                "id": "comp-im0aiycm",
                "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                "layout": {
                    "width": 738,
                    "height": 54,
                    "x": 137,
                    "y": 33,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WRichText",
                "data": {
                    "type": "StyledText",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "text": "<h2 class=\"font_2\" style=\"font-size:36px\"><span style=\"font-family:anton,sans-serif\"><span style=\"color:#FFC940\"><span style=\"font-size:36px\"><span style=\"letter-spacing:0.2em\">50% OFF </span></span></span><span style=\"color:#FFFFFE;\"><span style=\"font-size:36px\"><span style=\"letter-spacing:0.2em\">ALL SUMMER COLLECTION</span></span></span></span></h2>\n",
                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                    "linkList": [],
                    "id": "dataItem-im0aiycp"
                },
                "props": {
                    "type": "WRichTextProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "brightness": 1,
                    "packed": true,
                    "id": "propItem-ipf45m3q"
                },
                "style": "txtNew"
            }, {
                "type": "Component",
                "id": "comp-ilwbclsz",
                "skin": "svgshape.v2.Svg_2ac06149d42a4d698b6e0a0dd0e629f8",
                "layout": {
                    "width": 11,
                    "height": 36,
                    "x": 832,
                    "y": 41,
                    "scale": 1,
                    "rotationInDegrees": 180,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.svgshape.SvgShape",
                "data": {
                    "type": "SvgShape",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "id": "dataItem-ilwbclt2"
                },
                "props": {
                    "type": "SvgShapeProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "maintainAspectRatio": true,
                    "id": "propItem-ilwbclt3"
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-fillcolor": "1",
                            "alpha-stroke": "1",
                            "fillcolor": "#FFFFFF",
                            "stroke": "#FFFFFF",
                            "strokewidth": "4"
                        },
                        "propertiesSource": {
                            "fillcolor": "value",
                            "stroke": "value",
                            "strokewidth": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "svgshape.v2.Svg_2ac06149d42a4d698b6e0a0dd0e629f8",
                    "id": "style-ilwbewkp"
                }
            }, {
                "type": "Component",
                "id": "comp-ilwbf51f",
                "skin": "svgshape.v2.Svg_2ac06149d42a4d698b6e0a0dd0e629f8",
                "layout": {
                    "width": 11,
                    "height": 36,
                    "x": 846,
                    "y": 41,
                    "scale": 1,
                    "rotationInDegrees": 180,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.svgshape.SvgShape",
                "data": {
                    "type": "SvgShape",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "id": "dataItem-ilwbf51j"
                },
                "props": {
                    "type": "SvgShapeProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "maintainAspectRatio": true,
                    "id": "propItem-ilwbf51k"
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-fillcolor": "1",
                            "alpha-stroke": "1",
                            "fillcolor": "#FFFFFF",
                            "stroke": "#FFFFFF",
                            "strokewidth": "4"
                        },
                        "propertiesSource": {
                            "fillcolor": "value",
                            "stroke": "value",
                            "strokewidth": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.svgshape.SvgShape",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "svgshape.v2.Svg_2ac06149d42a4d698b6e0a0dd0e629f8",
                    "id": "style-ilwbewkp"
                }
            }, {
                "type": "Component",
                "skin": "svgshape.v2.Svg_001419cc721a440abc4ab12ed150b744",
                "layout": {
                    "width": 30,
                    "height": 30,
                    "x": 946,
                    "y": 18,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.PopupCloseIconButton",
                "data": {
                    "type": "SvgShape",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    }
                },
                "props": {
                    "type": "SvgShapeProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "maintainAspectRatio": true
                },
                "style": {
                    "type": "ComponentStyle",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "style": {
                        "properties": {
                            "alpha-fillcolor": "1",
                            "alpha-stroke": "1",
                            "fillcolor": "#FFFFFF",
                            "stroke": "#8D5AD5",
                            "strokewidth": "1"
                        },
                        "propertiesSource": {
                            "alpha-fillcolor": "value",
                            "alpha-stroke": "value",
                            "fillcolor": "value",
                            "stroke": "value",
                            "strokewidth": "value"
                        },
                        "groups": {}
                    },
                    "componentClassName": "wysiwyg.viewer.components.PopupCloseIconButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "svgshape.v2.Svg_001419cc721a440abc4ab12ed150b744"
                },
                "mobileStructure": {
                    "layout": {
                        "width": 24,
                        "height": 24,
                        "x": 270,
                        "y": 30,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "metaData": {
                        "originalCompId": "comp-israqtyo",
                        "author": "duplicate"
                    }
                },
                "activeModes": {}
            }],
            "skin": "wysiwyg.viewer.skins.stripContainer.DefaultStripContainer",
            "layout": {
                "width": 1425,
                "height": 117,
                "x": 0,
                "y": 661,
                "scale": 1,
                "rotationInDegrees": 0,
                "fixedPosition": false
            },
            "componentType": "wysiwyg.viewer.components.PopupContainer",
            "props": {
                "type": "PopupContainerProperties",
                "metaData": {"schemaVersion": "1.0"},
                "horizontalAlignment": "center",
                "verticalAlignment": "bottom",
                "alignmentType": "fullWidth",
                "horizontalOffset": 0,
                "verticalOffset": 0,
                "id": "propItem-ilw39qkx"
            },
            "design": {
                "type": "MediaContainerDesignData",
                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                "background": {
                    "type": "BackgroundMedia",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "color": "#8D5AD5",
                    "colorOpacity": 1,
                    "alignType": "center",
                    "fittingType": "fill",
                    "scrollType": "none",
                    "colorOverlay": "",
                    "colorOverlayOpacity": 0
                },
                "id": "dataItem-ilw39qky"
            },
            "style": {
                "type": "TopLevelStyle",
                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                "style": {
                    "groups": {},
                    "properties": {
                        "alpha-bg": "1",
                        "alpha-brd": "1",
                        "bg": "61,155,233,1",
                        "boxShadowToggleOn-shd": "false",
                        "brd": "#324158",
                        "brw": "0px",
                        "rd": "0px",
                        "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                    },
                    "propertiesSource": {
                        "bg": "value",
                        "brd": "value",
                        "brw": "value",
                        "rd": "value",
                        "shd": "value"
                    }
                },
                "componentClassName": "wysiwyg.viewer.components.PopupContainer",
                "pageId": "",
                "compId": "i5zemfji_0",
                "styleType": "custom",
                "skin": "wysiwyg.viewer.skins.stripContainer.DefaultStripContainer",
                "id": "style-ilw39ql0"
            },
            "behaviors": {
                "type": "ObsoleteBehaviorsList",
                "items": "[{\"name\":\"FlyIn\",\"delay\":0.1,\"duration\":0.65,\"params\":{\"direction\":\"bottom\"},\"action\":\"screenIn\",\"targetId\":\"\"},{\"name\":\"FlyOut\",\"delay\":0.1,\"duration\":0.65,\"params\":{\"direction\":\"bottom\"},\"action\":\"exit\",\"targetId\":\"\"}]",
                "id": "behavior-iomlweeh"
            }
        }],

        "skin": "skins.core.InlineSkin",
        "layout": {
            "width": 980,
            "height": 778,
            "x": 0,
            "y": 0,
            "scale": 1,
            "rotationInDegrees": 0,
            "fixedPosition": false
        },
        "componentType": "mobile.core.components.Page",
        "data": {
            "type": "Page",
            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
            "title": "add_preset_popups_Sale_1",
            "hideTitle": true,
            "icon": "",
            "descriptionSEO": "",
            "metaKeywordsSEO": "",
            "pageTitleSEO": "",
            "pageUriSEO": "blank-kjvow",
            "hidePage": true,
            "underConstruction": false,
            "tpaApplicationId": 0,
            "pageSecurity": {"requireLogin": false, "passwordDigest": "", "dialogLanguage": ""},
            "isPopup": true,
            "indexable": false,
            "isLandingPage": false,
            "pageBackgrounds": {
                "desktop": {
                    "ref": {
                        "type": "BackgroundMedia",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "color": "#01989D",
                        "colorOpacity": 0.5,
                        "alignType": "center",
                        "fittingType": "fill",
                        "scrollType": "none",
                        "colorOverlay": "",
                        "colorOverlayOpacity": 0
                    }
                },
                "mobile": {
                    "custom": true,
                    "ref": {
                        "type": "BackgroundMedia",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "color": "#01989D",
                        "colorOpacity": 0.5,
                        "alignType": "center",
                        "fittingType": "fill",
                        "scrollType": "scroll",
                        "colorOverlay": "",
                        "colorOverlayOpacity": 0
                    },
                    "isPreset": true
                }
            },
            "ignoreBottomBottomAnchors": true
        },
        "props": {
            "type": "PageProperties",
            "metaData": {"schemaVersion": "1.0"},
            "desktop": {"popup": {"closeOnOverlayClick": true}},
            "mobile": {"popup": {"closeOnOverlayClick": true}},
            "id": "kjvow"
        },
        "style": "p1",
        "tpaComps": []
    },
    "preset": {
        "rect": {"width": 305, "height": 202, "x": 10, "y": 20},
        "label": "add_preset_popups_Sale_1",
        "tags": null
    }
}
