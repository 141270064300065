import { SiteGlobalDataApiKey } from '@/apis';
import type { AiWriterPanelScope } from '../aiWriterPanelScope';

export const getBusinessData = (
  scope: AiWriterPanelScope,
): { businessName: string; businessType: string } => {
  const { editorAPI } = scope;
  const siteGlobalDataAPI = editorAPI.host.getAPI(SiteGlobalDataApiKey);
  const businessType = siteGlobalDataAPI.getBusinessType()?.displayName ?? '';
  const businessName = siteGlobalDataAPI.getBusinessName();

  return {
    businessType,
    businessName,
  };
};
