import experiment from 'experiment';
import { initCompsDesign } from './initDesignItem';

import type { Scope } from './scope';
import type { CompRef } from '@wix/document-services-types';

export async function init(scope: Scope) {
  const shouldMigrateSiteSegments = experiment.isOpen(
    'se_siteSegmentMediaBackground',
  );
  if (!shouldMigrateSiteSegments) {
    return;
  }

  let compsListForDesignEnforcement: CompRef[] = [];

  await scope.editorCoreApi.hooks.stageIsReadyAndVisible.promise;

  compsListForDesignEnforcement = compsListForDesignEnforcement.concat([
    scope.editorAPI.siteSegments.getHeader(),
    scope.editorAPI.siteSegments.getFooter(),
  ]);

  if (compsListForDesignEnforcement.length) {
    await initCompsDesign(scope, compsListForDesignEnforcement);
  }
}
