import type { Pointer } from 'types/documentServices';
import type {
  AnimationType,
  ReactionParams,
  TriggerParams,
  TriggerType,
} from './types';

const animationTypeToTriggerMap: Record<AnimationType, TriggerType[]> = {
  entrance: ['viewport-enter'],
  loop: ['animation-end', 'page-visible'],
  scroll: ['view-progress'],
};

const triggerParams: Record<
  AnimationType,
  (dependentEffect?: Pointer) => TriggerParams
> = {
  entrance: () => ({
    trigger: 'viewport-enter',
    params: { threshold: 0.15 },
  }),
  loop: (dependentEffect) =>
    dependentEffect
      ? {
          trigger: 'animation-end',
          params: { effect: dependentEffect },
        }
      : { trigger: 'page-visible' },
  scroll: () => ({
    trigger: 'view-progress',
  }),
};

const reactionParams: Record<AnimationType, ReactionParams> = {
  entrance: {
    type: 'Play',
    once: true,
  },
  loop: {
    type: 'Play',
  },
  scroll: {
    type: 'Scrub',
  },
};

const effectTypes: Record<AnimationType, AnyFixMe> = {
  entrance: {
    type: 'TimeAnimation',
    valueType: 'TimeAnimationOptions',
  },
  loop: {
    type: 'TimeAnimation',
    valueType: 'TimeAnimationOptions',
  },
  scroll: {
    type: 'ScrubAnimation',
    valueType: 'ScrubAnimationOptions',
  },
};

const triggerToAnimationTypeMap: Record<TriggerType, AnimationType> = {
  'viewport-enter': 'entrance',
  'page-visible': 'loop',
  'animation-end': 'loop',
  'view-progress': 'scroll',
};

export {
  animationTypeToTriggerMap,
  triggerParams,
  reactionParams,
  effectTypes,
  triggerToAnimationTypeMap,
};
