// @ts-nocheck
import _ from 'lodash';
import coreUtilsLib from 'coreUtilsLib';
import blogLayoutsItems from './blogLayoutsItems/blogLayoutsItemsSection';
import blogLayoutsItem1json from './blogLayoutsItems/blogLayoutsItem1.json';
import blogLayoutsItem2json from './blogLayoutsItems/blogLayoutsItem2.json';
import blogLayoutsItem3json from './blogLayoutsItems/blogLayoutsItem3.json';
import blogLayoutsItem4json from './blogLayoutsItems/blogLayoutsItem4.json';
import blogLayoutsItem5json from './blogLayoutsItems/blogLayoutsItem5.json';
import blogLayoutsItem6json from './blogLayoutsItems/blogLayoutsItem6.json';

const structures = [
  blogLayoutsItem1json,
  blogLayoutsItem2json,
  blogLayoutsItem3json,
  blogLayoutsItem4json,
  blogLayoutsItem5json,
  blogLayoutsItem6json,
];
structures.forEach(function (blogLayoutsItem) {
  blogLayoutsItem.data.appPartName = coreUtilsLib.blogAppPartNames.FEED;
});

export default _.merge(blogLayoutsItems, {
  props: {
    image: 'blog/sections/blogLayoutsSection.png',
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/map
    items: _.map(blogLayoutsItems.props.items, function (item, index) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      return _.assign(item, { structure: structures[index] });
    }),
  },
});
