// @ts-nocheck
import _ from 'lodash';
import gfppDataUtils from '../../utils/gfppDataUtils';
import constants from '@/constants';
import * as helpIds from '@/helpIds';

const { ACTIONS } = constants.ROOT_COMPS.GFPP;

export default {
  mainActions: [
    {
      label: 'gfpp_mainaction_pinpinit',
      label_active: 'gfpp_mainaction_applied_pinpinit',
      isApplied: gfppDataUtils.getDataDefinedFn('uri'),
      onClick(editorAPI, compRef) {
        const { mediaServices } = editorAPI;
        mediaServices.mediaManager.open(
          mediaServices.mediaManager.categories.IMAGE,
          {
            multiSelect: false,
            callback(items) {
              if (!_.isEmpty(items)) {
                const data = editorAPI.components.data.get(compRef);
                data.uri = items[0].fileName;
                editorAPI.components.data.update(compRef, data);
              }
              editorAPI.mediaServices.setFocusToEditor();
            },
          },
        );
      },
    },
  ],
  enabledActions: [
    ACTIONS.SETTINGS,
    ACTIONS.LAYOUT,
    ACTIONS.ANIMATION,
    ACTIONS.HELP,
  ],
  mobileEnabledActions: [
    ACTIONS.LAYOUT,
    ACTIONS.HIDE,
    ACTIONS.HELP,
    ACTIONS.ANIMATION,
  ],
  presetActions: {
    help: {
      helpId: helpIds.GFPP.PINTEREST_PIN_IT,
    },
  },
};
