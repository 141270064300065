import constants from '@/constants';
import { http } from '@/util';
import {
  getPageData,
  composeProtoInput,
  parseProtoOutput,
} from '@wix/editor-prediction-utils';

import type { DocumentServicesObject } from 'types/documentServices';

const PREDICTION_MODEL_ID = 'sections-cv-2-226';

export async function fetchPredictionModel(
  documentServices: DocumentServicesObject,
  pageId: string,
) {
  const metaSiteInstance = documentServices.platform.getAppDataByApplicationId(
    constants.APPLICATIONS.META_SITE_APPLICATION_ID,
  )?.instance;

  const pageData = await getPageData(documentServices, pageId);
  const input = composeProtoInput(pageData);
  const modelId = PREDICTION_MODEL_ID;

  const response = await http.fetch(
    `https://editor.wix.com/_api/predictor-service/v1/invoke/model/${modelId}`,
    {
      method: 'POST',
      credentials: 'same-origin',
      headers: new Headers({
        Authorization: metaSiteInstance,
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({
        model_id: modelId,
        triggered_by: 'editor',
        features: {
          values: {
            enriched_data: input,
          },
        },
      }),
    },
  );

  const { output } = await response.json();

  return parseProtoOutput(output?.values?.groups);
}
