import * as coreBi from '@/coreBi';
import { editorWixRecorder } from '@/util';

import { getStepName } from '../utils/getStepName';

import type { IAssistantTask } from '@wix/tour-maker';
import type { EditorAPI } from '@/editorAPI';

const createTourBIHandler = (editorAPI: EditorAPI) => {
  const getStepNumber = (task: IAssistantTask, args: any) => {
    const { lastActiveActionIndex } = args;

    return typeof lastActiveActionIndex === 'number'
      ? lastActiveActionIndex
      : task.activeActionIndex;
  };

  return (task: IAssistantTask, event: string, args: any) => {
    const name = task.id;
    const stepNumber = getStepNumber(task, args);
    const stepName = getStepName(
      name,
      task.actions[stepNumber]?.id,
      stepNumber,
    );

    switch (event) {
      case 'assistant.action.start':
        editorWixRecorder.addLabel(`${stepName} panel opened`);

        // moved to tourTooltip component in order to have extra properties
        // editorAPI.bi.event(coreBi.events.panels.PANEL_OPENED, {
        //   panel_name: stepName,
        // });
        break;

      // moved to tourTooltip component in order to have extra properties
      // case 'assistant.action.completed':
      //   editorAPI.bi.event(coreBi.events.panels.PANEL_CLOSED, {
      //     panel_name: stepName,
      //   });
      //   break;

      case 'assistant.task.quit':
        editorAPI.bi.event(coreBi.events.tour.ENDED, {
          name,
          panel_name: stepName,
        });
    }
  };
};

export default createTourBIHandler;
