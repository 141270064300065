:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._listContainer_1i9cf_7 {
  padding: 24px 18px 0 18px;
  text-align: center; }

._check_1i9cf_11 {
  fill: #116dff; }

._loading_1i9cf_14 .btn-confirm-primary.btn-confirm-primary {
  background-color: transparent !important; }
