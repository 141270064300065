import React from 'react';
import PropTypes from 'prop-types';

import { cx } from '@/util';

import SectionHeader from './sectionHeader';

import type { FC, ReactNode } from 'react';

interface SectionProps {
  title: string;
  children: ReactNode;
  className?: string;
  headerActions?: Array<AnyFixMe>;
  contentStyle?: React.CSSProperties;
  disabledContentPaddings?: boolean;
  disabledHeaderMargins?: boolean;
  dataHook?: string;
  automationId?: string;
  helpTitle?: string;
  helpDescription?: string;
  showSectionHeader?: boolean;
}

const Section: FC<SectionProps> = ({
  title,
  className,
  headerActions,
  disabledHeaderMargins,
  disabledContentPaddings,
  dataHook,
  automationId,
  helpTitle,
  helpDescription,
  children,
  contentStyle,
  showSectionHeader = true,
}) => {
  return (
    <div
      className={cx('media-manager-panel-section', className)}
      data-hook={dataHook}
      style={contentStyle}
      data-automation-id={automationId}
    >
      {showSectionHeader ? (
        <SectionHeader
          title={title}
          tooltipTitle={helpTitle}
          tooltipText={helpDescription}
          headerActions={headerActions}
          disabledContentPaddings={disabledContentPaddings}
          disabledHeaderMargins={disabledHeaderMargins}
        />
      ) : null}

      <div
        className={cx('media-manager-panel-section__content', {
          'media-manager-panel-section__content-with-paddings':
            !disabledContentPaddings,
        })}
      >
        {children}
      </div>
    </div>
  );
};

Section.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  headerActions: PropTypes.arrayOf(PropTypes.element),
  disabledContentPaddings: PropTypes.bool,
  dataHook: PropTypes.string,
  automationId: PropTypes.string,
};

export default Section;
