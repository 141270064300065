import _ from 'lodash';
import React from 'react';
import InteractionsMobileHiddenItem from './interactionsMobileHiddenItem';
import type { InteractionsMobileHiddenItemDef } from './interactionsMobileHiddenItem';
import type { BiEventDefinition, BiEventFields } from 'types/bi';

interface InteractionsHiddenItemsContainerProps {
  hiddenComps: InteractionsMobileHiddenItemDef[];
  pageId: string;
  sendBi: (event: BiEventDefinition, parameters: BiEventFields) => void;
}

export default class InteractionsHiddenItemsContainer extends React.Component<InteractionsHiddenItemsContainerProps> {
  static displayName = 'InteractionsHiddenItemsContainer';

  render() {
    return (
      <div className="interactions-hidden-items-container">
        <div className="container-comps">
          {/* TODO: Fix this the next time the file is edited. */}
          {/* eslint-disable-next-line you-dont-need-lodash-underscore/map */}
          {_.map(this.props.hiddenComps, (hiddenCompDef) => (
            <InteractionsMobileHiddenItem
              key={hiddenCompDef.compRef.id}
              pageId={this.props.pageId}
              {...hiddenCompDef}
              sendBi={this.props.sendBi}
            />
          ))}
        </div>
      </div>
    );
  }
}
