import { useRef } from 'react';
import type { PresetSectionComponentMetaData } from '@wix/add-panel-component';
import type { AddPanelDispatchProps } from './types';

export const useTrackMaxScroll = (
  trackMaxScrollInner: AddPanelDispatchProps['trackMaxScroll'],
) => {
  const maxScrollItemRef = useRef<PresetSectionComponentMetaData | null>(null);

  const saveMaxScrollItem = (
    params: PresetSectionComponentMetaData | null = null,
  ) => {
    maxScrollItemRef.current = params;
  };

  const trackMaxScroll = () => {
    const maxScrollItem = maxScrollItemRef.current;

    if (
      maxScrollItem &&
      maxScrollItem.sectionIndex > -1 &&
      maxScrollItem.itemIndex > -1
    ) {
      trackMaxScrollInner(maxScrollItem);
      saveMaxScrollItem();
    }
  };

  return {
    saveMaxScrollItem,
    trackMaxScroll,
  };
};
