export const TRANSLATIONS = {
  SECTION_ATTACH_LABEL: 'section_onstage_attach_to_section', // accepts 'section_name' as an argument
  SECTION_NAME: 'section_name_full_label', // accepts 'section_name' as an argument
  ANCHORS_MENU_NAME: 'component_label_VerticalAnchorsMenu_in_page',
  RENAME_TOOLTIP: 'section_name_rename_tooltip',
  SECTION_NAME_COPY: 'section_name_copy',
  NOTIFICATIONS: {
    CANNOT_REMOVE_LAST_SECTION:
      'section_disable_keyboard_delete_last_section_notification',
    CANNOT_REMOVE_LAST_SECTION_MOBILE:
      'section_disable_keyboard_mobile_hide_last_section_notification',
    CANNOT_REMOVE_SECTION_WITH_WIDGET:
      'section_disable_keyboard_delete_TPA_section_notification', // accepts 'widget_name' as an arg
    CANNOT_REMOVE_SECTION_WITH_WIDGET_MOBILE:
      'section_disable_keyboard_mobile_hide_TPA_section_notification', // accepts 'widget_name' as an arg
    CANNOT_DUPLICATE_SECTION_MOBILE:
      'section_disable_keyboard_mobile_duplicate_section_notification',
    CANNOT_DUPLICATE_SECTION_WITH_WIDGET:
      'section_disable_keyboard_duplicate_TPA_section_notification', // accepts 'widget_name' as an arg
    CANNOT_REMOVE_SITE_SEGMENT:
      'section_disable_keyboard_delete_header_footer_notification',
    CANNOT_REMOVE_SITE_SEGMENT_B: {
      TEXT: 'Notifications_Delete_HeaderFooter_Text',
      LINK: 'Notifications_Delete_HeaderFooter_Link',
    },
    CANNOT_DUPLICATE_SITE_SEGMENT:
      'section_disable_keyboard_duplicate_header_footer_notification',
    CANNOT_REDUCE_SITE_SEGMENT: {
      TEXT: 'Notifications_Reduce_HeaderFooter_Text',
      LINK: 'Notifications_Reduce_HeaderFooter_Link',
    },
  },
  ACTIONS_BAR: {
    TOOLTIPS: {
      MOVE_UP: 'section_action_bar_move_up_tooltip',
      MOVE_DOWN: 'section_action_bar_move_down_tooltip',
      RENAME: 'section_action_bar_rename_tooltip',
      QUICK_EDIT: 'section_action_bar_edit_section_tooltip',
      QUICK_EDIT_EMPTY_STATE:
        'section_action_bar_edit_section_empty_section_tooltip_text',
      QUICK_EDIT_EMPTY_STATE_LINK:
        'section_action_bar_edit_section_empty_section_tooltip_cta',
      SWITCH_LAYOUT: 'section_action_bar_section_designs_tooltip',
      SWITCH_LAYOUT_BRUTE_FORCE:
        'section_action_bar_section_designs_brute_force_tooltip',
      SWITCH_LAYOUT_COMPARISON:
        'section_action_bar_section_designs_comparison_tooltip',
      DUPLICATE: 'section_action_bar_duplicate_tooltip',
      CANNOT_DUPLICATE_SECTION_WITH_WIDGET:
        'section_action_bar_disable_duplicate_TPA_section_tooltip', // accepts 'widget_name' as an arg
      REMOVE: 'section_action_bar_delete_tooltip',
      CANNOT_REMOVE_LAST_SECTION:
        'section_action_bar_disable_delete_last_section_tooltip',
      CANNOT_REMOVE_SECTION_WITH_WIDGET:
        'section_action_bar_disable_delete_TPA_section_tooltip', // accepts 'widget_name' as an arg
      HIDE: 'section_action_bar_mobile_hide_tooltip',
      CANNOT_HIDE_SECTION_WITH_WIDGET:
        'section_action_bar_disable_mobile_hide_TPA_section_tooltip', // accepts 'widget_name' as an arg
      SHOW_MORE: 'section_action_bar_more_tooltip',
      ACCESSIBILITY: 'section_action_bar_accessibility_tooltip',
      HEADER_SETTINGS: 'desktop_headerscroll_panel_title',
      FOOTER_SETTINGS: 'section_action_bar_footer_settings_tooltip',
      FOOTER_ANIMATION: 'section_action_bar_mobile_footer_animation_tooltip',
      HELP: 'section_action_bar_zoom_out_header_footer_help_tooltip',
    },
    TOOLTIP_LEARN_MORE: 'section_action_bar_tooltip_learn_more_link',
  },
};
