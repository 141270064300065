:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._container_1hgs0_7 {
  position: absolute;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  pointer-events: none; }
  ._container_1hgs0_7._zoomMode_1hgs0_15 {
    pointer-events: all;
    background-color: transparent; }
    ._container_1hgs0_7._zoomMode_1hgs0_15._hovered_1hgs0_18 {
      z-index: 1; }
  ._container_1hgs0_7._bothSelectedAndHovered_1hgs0_20:before, ._container_1hgs0_7._bothSelectedAndHovered_1hgs0_20:after,
  ._container_1hgs0_7._bothSelectedAndHovered_1hgs0_20 ._frame_1hgs0_21, ._container_1hgs0_7._selected_1hgs0_21:before, ._container_1hgs0_7._selected_1hgs0_21:after,
  ._container_1hgs0_7._selected_1hgs0_21 ._frame_1hgs0_21 {
    border-color: #6f56f9; }
  ._container_1hgs0_7._overlay_1hgs0_24 {
    background-color: rgba(111, 86, 249, 0.1); }
  ._container_1hgs0_7:before, ._container_1hgs0_7:after {
    content: '';
    width: 100%;
    position: absolute;
    pointer-events: none; }
  ._container_1hgs0_7:before {
    border-top: 3px solid #d9d2ff;
    top: 0; }
  ._container_1hgs0_7:after {
    border-bottom: 3px solid #d9d2ff;
    bottom: 0; }
  ._container_1hgs0_7._mouseOpsInProgress_1hgs0_37 {
    background-color: transparent; }
  ._container_1hgs0_7._topMostSection_1hgs0_39 ._labels_1hgs0_39 {
    top: 0; }
  ._container_1hgs0_7._topMostSection_1hgs0_39._zoomMode_1hgs0_15 ._name_1hgs0_41 {
    margin-top: 0; }
  ._container_1hgs0_7._topMostSection_1hgs0_39 ._addSectionButtonContainer_1hgs0_43._positionTop_1hgs0_43 {
    top: 0; }

._resizeHandleContainer_1hgs0_46 {
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  z-index: 100;
  pointer-events: all;
  cursor: pointer; }
  ._resizeHandleContainer_1hgs0_46._mobileEditor_1hgs0_54 {
    left: auto; }

._addSectionButtonContainer_1hgs0_43 {
  position: absolute;
  display: flex;
  align-items: center;
  z-index: 100;
  right: auto;
  pointer-events: all;
  cursor: pointer; }
  ._addSectionButtonContainer_1hgs0_43._positionTop_1hgs0_43 {
    top: calc(30px / -2); }
  ._addSectionButtonContainer_1hgs0_43._positionBottom_1hgs0_67 {
    bottom: calc(30px / -2); }

._labels_1hgs0_39 {
  position: absolute;
  top: -26px;
  height: 29px;
  pointer-events: all;
  display: flex;
  align-items: flex-end;
  z-index: 2; }

._name_1hgs0_41 {
  align-self: flex-start; }

._corvidName_1hgs0_82 {
  -webkit-user-select: none;
  user-select: none;
  height: 16px;
  background-color: #f7f8f8;
  margin-right: 6px;
  margin-bottom: 3px;
  display: flex;
  align-items: center;
  justify-content: space-between; }

._corvidNameLabel_1hgs0_95 {
  padding: 0 6px;
  color: #000624;
  font-size: 12px;
  font-family: Menlo, Monaco, "Courier New", monospace; }

._cloud_1hgs0_101 {
  color: #fff;
  width: 30px;
  background: #000624;
  display: flex;
  justify-content: center; }

._frame_1hgs0_21 {
  border: 3px solid #d9d2ff;
  border-bottom: 0;
  border-top: 0;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

._preloader_1hgs0_119 {
  background-color: #fff;
  opacity: 0.7;
  height: 100%;
  width: 100%; }
