export class Tracer {
  private traces: string[] = [];
  private shouldTrace = true;

  disable() {
    this.traces = [];
    this.shouldTrace = false;
  }

  getTraces() {
    return this.traces;
  }

  trace = (name: string) => {
    if (this.shouldTrace) {
      this.traces.push(name);
    }
  };
}

export const tracer = new Tracer();
