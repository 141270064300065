// @ts-nocheck
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import * as util from '@/util';
import mobileThumbnail from './mobileThumbnail';
import { Composites, TextLabel, Thumbnails } from '@wix/wix-base-ui';
const PATH_TO_IMAGES = 'mobileEditor/mobileWizard';

//TYPE WAS GENERATED, remove this line when reviewed
interface Props {
  headerLabel?: string;
  actionToTakeLabel?: string;
  options?: Array<AnyFixMe>;
  onOptionSelected?: FunctionFixMe;
}

export default class extends React.Component<Props> {
  static displayName = 'mobileWizardSingleStep';

  static propTypes = {
    headerLabel: PropTypes.string,
    actionToTakeLabel: PropTypes.string,
    options: PropTypes.array,
    onOptionSelected: PropTypes.func,
  };

  getThumbnails = () => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/map
    return _.map(this.props.options, (option) => {
      const src = util.media.getMediaUrl(
        `${PATH_TO_IMAGES}/${option.imageSrc}`,
      );
      const isSelected = !!option.isSelected;
      const illustration = React.createElement(mobileThumbnail, {
        src,
        isSelected,
      });

      return {
        value: option,
        automationId: option.automationId,
        key: option.label,
        label: option.label,
        illustration,
      };
    });
  };

  render() {
    return (
      <div
        data-aid={this.props.automationId}
        className="mobile-wizard-single-step"
      >
        <div className="step-header-wrapper">
          <TextLabel
            value={this.props.headerLabel}
            enableEllipsis={false}
            type="T13"
            className="step-header"
          />

          <TextLabel
            value={this.props.actionToTakeLabel}
            enableEllipsis={false}
            type="T03"
            className="step-sub-header"
          />
        </div>

        <Composites.Thumbnails>
          <Thumbnails
            onChange={this.props.onOptionSelected}
            shouldAddTooltipOnEllipsis={true}
            hasBackground={false}
            maxThumbsPerRow={4}
            options={this.getThumbnails()}
            className="mobile-wizard-thumbnails"
          />
        </Composites.Thumbnails>
      </div>
    );
  }
}
