import {
  getPageData,
  getInputDataForTextClassificationPrediction,
} from '@wix/editor-prediction-utils';
import { fedopsLogger, isDebugMode } from '@/util';
import constants from '@/constants';
import fetchPrediction from './fetchPrediction';
import serializeProtoData from './proto/serializeProtoData';
import parseProtoData from './proto/parseProtoData';
import type { CompRef, DocumentServicesObject } from 'types/documentServices';
import type {} from './types';
import type { ListClassificationResponseGroup } from './types';
export { getPageData as getPredictionData };

export async function getPrediction(
  documentServices: DocumentServicesObject,
  pageId: string,
  compRef: CompRef,
): Promise<ListClassificationResponseGroup | {}> {
  fedopsLogger.interactionStarted(
    fedopsLogger.INTERACTIONS.LIST_CLASSIFICATION
      .LIST_CLASSIFICATION_PREDICTION,
  );

  const metaSiteInstance = documentServices.platform.getAppDataByApplicationId(
    constants.APPLICATIONS.META_SITE_APPLICATION_ID,
  )?.instance;

  const containerData = await getInputDataForTextClassificationPrediction(
    documentServices,
    pageId,
    compRef,
  );

  const additionalDataForPrediction = {
    page_id: { strVal: documentServices.generalInfo.getSiteId() },
    meta_site_id: { strVal: documentServices.generalInfo.getMetaSiteId() },
    section_name: {
      strVal: documentServices.components.anchor.get(compRef).name,
    },
    revision: { strVal: 'NA' },
  };

  const predictionInput = serializeProtoData(
    containerData,
    additionalDataForPrediction,
  );

  const predictionOutput = await fetchPrediction(
    metaSiteInstance,
    predictionInput,
  );

  if (isDebugMode()) {
    console.log('List classification prediction INPUT: ', predictionInput);
    console.log('List classification prediction OUTPUT: ', predictionOutput);
  }

  if (predictionOutput) {
    fedopsLogger.interactionEnded(
      fedopsLogger.INTERACTIONS.LIST_CLASSIFICATION
        .LIST_CLASSIFICATION_PREDICTION,
    );
    return parseProtoData(predictionOutput);
  }
}
