._design-panel-tab-tooltip-content_1hayr_1 {
  width: 100%;
  color: #3b4057;
  font-size: 14px;
  line-height: 18px; }
  ._design-panel-tab-tooltip-content_1hayr_1 a {
    color: #3899ec;
    text-decoration: none; }
  ._design-panel-tab-tooltip-content_1hayr_1 ._info-icon-link_1hayr_9 {
    color: #3899ec;
    text-decoration: underline;
    cursor: pointer; }

._design-panel-tab-tooltip-wrapper_1hayr_14 > span {
  display: flex; }
