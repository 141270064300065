import { sectionsOnStage, mouseActions } from '@/stateManagement';
import { getSectionsOnStage } from './utils';
import type { EditorAPI } from '@/editorAPI';
import type { Section } from './types';
export class SectionsManager {
  sections: Section[];
  editorAPI: EditorAPI;

  init(editorAPI: EditorAPI) {
    this.editorAPI = editorAPI;
    editorAPI.registerToViewerChange(this.update);
  }
  update = () => {
    const isPerformingMouseMoveAction =
      mouseActions.selectors.isPerformingMouseMoveAction(
        this.editorAPI.store.getState(),
      );

    if (isPerformingMouseMoveAction) return;

    const sections = getSectionsOnStage(this.editorAPI);

    this.editorAPI.store.dispatch(
      sectionsOnStage.actions.setSections(sections),
    );
  };
}
