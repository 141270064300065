import React, { useEffect, type FC } from 'react';
import * as panels from '@/panels';
import AIAssistantProvider from './AIAssistantContext';
import Chat from './components/chat';
import { hoc } from '@/util';
import {
  mapDispatchToProps,
  mapStateToProps,
  type AiAssistantStateProps,
  type AiAssisntantDispatchProps,
  type AiAssistantOwnProps,
} from './aiAssistantMapper';

type AIAssistantPanelProps = AiAssistantStateProps &
  AiAssisntantDispatchProps &
  AiAssistantOwnProps;

const AIAssistantPanel: FC<AIAssistantPanelProps> = ({
  closePanel,
  sendMessage,
  initAiAssistant,
}) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => initAiAssistant(), []);

  return (
    <panels.frames.FocusPanelFrame
      title="All new AI Assistant"
      panelName="aiAssistantPanel"
      onClose={closePanel}
      shouldAddScroll={false}
      subtitle="Embrace power of all new AI Assistant"
      width="526px"
      height="600px"
      centered={false}
      left={80}
      top={document?.documentElement?.clientHeight - 668}
      draggable={true}
    >
      <AIAssistantProvider>
        <Chat onSendMessage={sendMessage} />
      </AIAssistantProvider>
    </panels.frames.FocusPanelFrame>
  );
};

export default hoc.connect(
  hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(AIAssistantPanel);
