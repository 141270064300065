import { fedopsLogger } from '@/util';
import * as mediaPanelInfra from '@/mediaManagerPanelInfra';
import {
  isMediaManagerImage,
  isMediaManagerShape,
  isMediaManagerVideo,
  type MediaManagerInfo,
  type MediaManagerItem,
} from '@/mediaServices';
import type { EditorAPI } from '@/editorAPI';
import type { CompStructure } from 'types/documentServices';
import * as addPanelUtils from '../../addPanel/addPanelUtils';
import {
  DEFAULT_IMAGE_COMP_TYPE,
  getImageCompDef,
} from '../../sections/listSection/imageSectionUtils';
import { BI_ORIGIN } from '../constants';
import { getShapeCompDef } from './shapeCompDef';
import { getVideoBoxCompDef } from './videoBoxCompDef';
import { constants as mediaPanelConsts } from '@/mediaManagerPanelInfra';

const { ADD_TO_STAGE_METHOD } = mediaPanelConsts;

interface AttemptToAddComponentParams {
  editorAPI: EditorAPI;
  info: MediaManagerInfo;
  categoryId: string;
  sectionTitle: string;
  item: MediaManagerItem;
}

const getCompDef = (
  editorAPI: EditorAPI,
  item: MediaManagerItem,
  info: MediaManagerInfo,
): CompStructure | null => {
  const { mediaType } = item;

  if (isMediaManagerImage(item)) {
    return getImageCompDef(
      DEFAULT_IMAGE_COMP_TYPE,
      item,
      editorAPI.isMobileEditor(),
    );
  }

  if (isMediaManagerVideo(item)) {
    return getVideoBoxCompDef(editorAPI, item, info);
  }

  if (isMediaManagerShape(item)) {
    return getShapeCompDef(item);
  }

  const compDef = mediaPanelInfra.utils.addComponentData[mediaType](
    item,
    info,
    editorAPI.components.buildDefaultComponentStructure,
  );

  return compDef;
};

export const attemptToAddComponentToStage = ({
  item,
  info,
  categoryId,
  sectionTitle,
  editorAPI,
}: AttemptToAddComponentParams) => {
  const compDef = getCompDef(editorAPI, item, info);

  fedopsLogger.interactionStarted(
    fedopsLogger.INTERACTIONS.ADD_COMP_FROM_ADD_PANEL,
  );

  addPanelUtils.attemptToAddComponent(
    editorAPI,
    compDef,
    categoryId,
    sectionTitle,
    [],
    item.id,
    BI_ORIGIN,
    null,
    {},
    ADD_TO_STAGE_METHOD.MEDIA_MANAGER,
  );
};

interface MediaManagerCloseHandlerArgs {
  sectionTitle: string;
  categoryId: string;
  items?: MediaManagerItem[];
  info?: MediaManagerInfo;
}

export const mediaManagerCallback = (
  editorAPI: EditorAPI,
  { sectionTitle, categoryId, items, info }: MediaManagerCloseHandlerArgs,
): void => {
  if (!items) {
    return;
  }

  items.forEach((item) => {
    attemptToAddComponentToStage({
      item,
      info,
      sectionTitle,
      categoryId,
      editorAPI,
    });
  });

  editorAPI.mediaServices.setFocusToEditor();
};
