// @ts-nocheck
import createReactClass from 'create-react-class';
import _ from 'lodash';
import * as util from '@/util';
import React from 'react';
import { cx } from '@/util';
import { BaseUiTextWrapper } from './baseUiTextWrapper';
import type { RCMItemDataType } from '../types';

interface RcmItemWithSubItemsProps {
  item: RCMItemDataType;
  label: string;
  shouldTranslate?: boolean;
  onMouseOver?: (e: React.MouseEvent) => void;
  onClick?: (e: React.MouseEvent) => void;
  subMenuPositionY?: number;
  countTotal?: number;
  automationId?: string;
}

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass<RcmItemWithSubItemsProps>({
  displayName: 'rcmItemWithSubItems',
  mixins: [util.translationMixin],
  render() {
    const label: string = this.translateIfNeeded(this.props.label);
    const countTotal: string = ` (${this.props.countTotal})`;
    const disabled = !this.props.item.enabled;

    return (
      <li
        className={cx({
          disabled,
          hoverable: this.props.item.enabled,
          flex: true,
        })}
        onMouseOver={this.props.onMouseOver || _.noop}
        onClick={this.props.onClick}
        data-aid={this.props.automationId || ''}
      >
        <span className="label label-sub-item">
          <span data-hint-id={this.props.hintId}>
            <BaseUiTextWrapper text={label} disabled={disabled} />
          </span>
          {this.props.item.shouldShowCount ? (
            <span key="count">
              <BaseUiTextWrapper text={countTotal} disabled={disabled} />
            </span>
          ) : null}
        </span>
        <span className="action action-expand margin-left-auto action-relative" />
        <div
          className="rightClickMenu subMenu flex"
          style={{
            top: this.props.subMenuPositionY,
          }}
        >
          <ul>{this.props.children}</ul>
        </div>
      </li>
    );
  },
});
