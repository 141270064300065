:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._siteCreationView_55swg_7 {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  opacity: 0;
  background-color: #fff;
  visibility: hidden;
  transition: visibility 0s 300ms, opacity 300ms linear;
  z-index: 1000000001; }
  ._siteCreationView_55swg_7.visible {
    opacity: 1;
    visibility: visible; }
  ._siteCreationView_55swg_7 .control-label {
    color: #000624; }

._siteCreationViewContent_55swg_24 {
  position: relative;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  transition: visibility 0s 300ms, opacity 300ms linear; }
  ._siteCreationViewContent_55swg_24.visible {
    opacity: 1;
    visibility: visible; }

._siteCreationWixLogo_55swg_35 {
  position: absolute;
  top: 42px;
  left: 42px;
  z-index: 4; }
