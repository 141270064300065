// @ts-nocheck
import createReactClass from 'create-react-class';
import _ from 'lodash';
import * as core from '@/core';
import * as util from '@/util';
import { translate } from '@/i18n';
import React from 'react';
import { ToolPanelFrame } from '../../frames';
import * as baseUI from '@/baseUI';
import { CustomScroll } from '@wix/wix-base-ui';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'mobileActionBarSubPanel',
  mixins: [core.mixins.editorAPIMixin],
  render() {
    return (
      <ToolPanelFrame
        ref="frame"
        headerTitle={translate('mobile_action_bar_social_title')}
        panelName={this.props.panelName}
        isSingleInstance={true}
        contentWrapperClass="actionbar-sub-panel"
        isBlockingLayerFullScreen={this.props.isBlockingLayerFullScreen}
        shouldBlockOuterScroll={this.props.shouldBlockOuterScroll}
        contentStyle={_.pick(this.props, ['top', 'left'])}
        contentClass="actionbar-sub-panel-content-wrapper"
      >
        <CustomScroll
          ref="customScroll"
          heightRelativeToParent="100%"
          addScrolledClass={true}
        >
          <ul>
            {/* TODO: Fix this the next time the file is edited. */}
            {/* eslint-disable-next-line you-dont-need-lodash-underscore/map */}
            {_.map(this.getSocialLinks(), (socialLink) => (
              <li key={socialLink.id} className="social-link-item">
                <baseUI.tooltip
                  value={this.getSocialLinkTooltip(socialLink.id)}
                >
                  <span>
                    <baseUI.symbol
                      name={socialLink.symbol}
                      className={`${socialLink.symbol} symbol`}
                    />
                  </span>
                </baseUI.tooltip>
                <baseUI.textInput
                  validator={this.validateUrlWithEmptyState}
                  invalidMessage="mobile_action_bar_social_error_message"
                  placeholder={this.getSocialLinkPlaceholder(socialLink.id)}
                  valueLink={this.getValueLink(socialLink.id)}
                />
              </li>
            ))}
          </ul>
        </CustomScroll>
      </ToolPanelFrame>
    );
  },
  getSocialLinks() {
    return [
      { id: 'blogger', symbol: 'mba_social_panel_google_blogger' },
      { id: 'facebook', symbol: 'mba_social_panel_facebook' },
      { id: 'flickr', symbol: 'mba_social_panel_youtube_flickr' },
      { id: 'google_plus', symbol: 'mba_social_panel_google_plus' },
      { id: 'linkedin', symbol: 'mba_social_panel_google_linkdin' },
      { id: 'pinterest', symbol: 'mba_social_panel_pinterest' },
      { id: 'tumblr', symbol: 'mba_social_panel_google_tumblr' },
      { id: 'twitter', symbol: 'mba_social_panel_twitter' },
      { id: 'vimeo', symbol: 'mba_social_panel_youtube_vimeo' },
      { id: 'youtube', symbol: 'mba_social_panel_youtube' },
    ];
  },
  getSocialLinkPlaceholder(socialLinkName) {
    socialLinkName =
      socialLinkName === 'google_plus' ? 'google' : socialLinkName;
    return `mobile_action_bar_${socialLinkName}_placeholder`;
  },
  getValueLink(socialLinkName) {
    const actionsAPI = this.getEditorAPI().mobile.actionBar.actions;
    const socialLinksData = actionsAPI.get().socialLinks;

    return {
      value: socialLinksData[socialLinkName],
      requestChange: function (currentSocialLinkName, newSocialLinkData) {
        const setting = {};

        socialLinksData[currentSocialLinkName] = newSocialLinkData;
        setting.socialLinks = socialLinksData;

        const areThereSocialLinks =
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line you-dont-need-lodash-underscore/values
          _(socialLinksData).values().compact().value().length > 0;

        actionsAPI.update(setting);
        actionsAPI.enable({ socialLinks: areThereSocialLinks });
      }.bind(this, socialLinkName),
    };
  },
  getSocialLinkTooltip(socialLinkId) {
    socialLinkId = socialLinkId === 'google_plus' ? 'google' : socialLinkId;
    return `mobile_action_bar_${socialLinkId}_tooltip`;
  },
  validateUrlWithEmptyState(val) {
    return util.validate.url(val) || val === '';
  },
});
