// @ts-nocheck
import createReactClass from 'create-react-class';
import _ from 'lodash';
import PropTypes from 'prop-types';
import * as core from '@/core';
import React from 'react';
import ChangeStyleDesignPanel from '../panelTypes/changeStyleDesignPanel';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'appPartChangeStyleDesignPanel',
  render() {
    return (
      <ChangeStyleDesignPanel
        isWixApp={true}
        shouldForkStyle={false}
        updateStyle={this.updateStyle}
        {...this.getPanelProps()}
      >
        {this.props.children}
      </ChangeStyleDesignPanel>
    );
  },

  mixins: [core.mixins.editorAPIMixin],

  propTypes: {
    updateStyle: PropTypes.func.isRequired,
    setStyleId: PropTypes.func.isRequired,
    onForkStyle: PropTypes.func.isRequired,
  },
  getDefaultProps() {
    return {
      onForkStyle: _.noop,
    };
  },

  updateStyle(styleDef, isTemporary) {
    const editorAPI = this.getEditorAPI();
    if (this.styleForked) {
      this.props.updateStyle(styleDef, isTemporary);
    } else {
      const newStyleDef = editorAPI.dsActions.wixapps.styles.fork(styleDef);
      this.styleForked = true;
      editorAPI.dsActions.waitForChangesApplied(function () {
        this.props.setStyleId(newStyleDef.id);
        this.props.onForkStyle(newStyleDef);
      });
    }
  },

  getPanelProps() {
    return _.omit(this.props, ['updateStyle', 'setStyleId', 'onForkStyle']);
  },
});
