// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ToolPanelFrame } from '../../frames';
import { TextInputMultiline } from '@wix/wix-base-ui';

function template() {
  return (
    <ToolPanelFrame
      panelName={this.props.panelName}
      headerTitle={this.getTitle()}
      onClose={this.props.onClose}
      contentStyle={this.getFrameStyle()}
      contentWrapperClass="stylable-editor-panel stylable-editor-css-panel-wrapper"
      contentClass="stylable-editor-panel-content-wrapper"
      lightHeader
    >
      <TextInputMultiline
        autoSelect={false}
        selectOnFocus={false}
        value={this.state.css}
        onChange={(value) => {
          this.handleOnChange(value);
        }}
        className="stylable-editor-css-panel-input"
      />
    </ToolPanelFrame>
  );
}

const CSS_PANEL_TITLE = 'CSS Panel';

const TAB = '    ';
const PRETTIFY_RULES = [
  {
    description: 'Block open and declaration-end indentation',
    pattern: /([\{;])\s*([^\s\}])/g,
    replacement: `$1\n${TAB}$2`,
  },
  { description: 'Block close', pattern: /\} *(\S)/g, replacement: '\n}\n$1' },
  {
    description: 'Final block close',
    pattern: /([^\n])\}$/g,
    replacement: '$1\n}',
  },
  { description: 'Trim right', pattern: /\s+\n/g, replacement: '\n' },
];
const prettify = _.flow(
  PRETTIFY_RULES.map(
    ({ pattern, replacement }) =>
      (value) =>
        value.replace(pattern, replacement),
  ),
);

class StylableEditorCSSPanel extends React.Component {
  constructor(props) {
    super(props);

    this.getTitle = () => CSS_PANEL_TITLE;
    this.getFrameStyle = () =>
      _.defaults(
        { width: 500, height: 500 },
        _.pick(this.props, ['top', 'left']),
      );
    this.handleOnChange = (css) => {
      this.setState({ css });
      this.props.onChange(css);
    };

    this.state = {
      css: prettify(this.props.value),
    };
  }

  render() {
    return template.call(this);
  }
}

StylableEditorCSSPanel.displayName = 'StylableEditorCSSPanel';
StylableEditorCSSPanel.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func,

  top: PropTypes.string,
  left: PropTypes.string,
};
StylableEditorCSSPanel.defaultProps = {
  top: '400px',
  left: '500px',
};

export default StylableEditorCSSPanel;
