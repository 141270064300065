export default {
  type: 'Container',
  components: [
    {
      type: 'Component',
      skin: 'skins.viewer.VectorImageSkin',
      layout: {
        width: 100,
        height: 100,
        x: 8,
        y: 12,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.VectorImage',
      metaData: {
        pageId: 'l945m',
      },
      parent: 'comp-kgt5rq21',
      data: {
        type: 'VectorImage',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'l945m',
        },
        svgId: 'a5a2d58588ec45b3a58ea380714fd6e0.svg',
      },
      props: {
        type: 'VectorImageProperties',
        metaData: {
          schemaVersion: '1.0',
          pageId: 'l945m',
        },
        displayMode: 'stretch',
        flip: 'none',
      },
      design: {
        type: 'VectorImageDesignData',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'l945m',
        },
        overrideColors: {
          color1: '#FFDE5F',
        },
        shapeStyle: {
          opacity: 1,
          strokeWidth: 4,
          stroke: '#5E97FF',
          strokeOpacity: 1,
          enableStroke: false,
        },
      },
      transitions: {
        type: 'TransitionData',
        'timing-function': 'ease-in-out',
        duration: 0.4,
        delay: 0,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'l945m',
        },
      },
      transformations: {
        type: 'TransformData',
        origin: {
          x: {
            value: 50,
            type: 'percentage',
          },
          y: {
            value: 50,
            type: 'percentage',
          },
        },
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'l945m',
        },
      },
      scopedTransformations: {
        'variants-kgt5rq2h': {
          type: 'TransformData',
          rotate: -98,
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'l945m',
          },
        },
      },
      style: {
        type: 'ComponentStyle',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'l945m',
        },
        style: {
          properties: {},
          propertiesSource: {},
          groups: {},
        },
        componentClassName: 'wysiwyg.viewer.components.VectorImage',
        styleType: 'custom',
        skin: 'skins.viewer.VectorImageSkin',
      },
      mobileStructure: {
        layout: {
          width: 139,
          height: 139,
          x: 11,
          y: 18,
          scale: 1,
          rotationInDegrees: 0,
          fixedPosition: false,
        },
        metaData: {
          originalCompId: 'comp-kgt5rq2k',
          author: 'studio',
        },
      },
    },
    {
      type: 'Component',
      skin: 'skins.viewer.VectorImageSkin',
      layout: {
        width: 51,
        height: 32,
        x: 32,
        y: 46,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.VectorImage',
      metaData: {
        pageId: 'l945m',
      },
      parent: 'comp-kgt5rq21',
      data: {
        type: 'VectorImage',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'l945m',
        },
        svgId: 'b0a00c2b7e164873b225a825a3c8e340.svg',
      },
      props: {
        type: 'VectorImageProperties',
        metaData: {
          schemaVersion: '1.0',
          pageId: 'l945m',
        },
        displayMode: 'fit',
        flip: 'none',
      },
      design: {
        type: 'VectorImageDesignData',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'l945m',
        },
        shapeStyle: {
          opacity: 1,
          strokeWidth: 4,
          stroke: '#5E97FF',
          strokeOpacity: 1,
          enableStroke: false,
        },
      },
      transitions: {
        type: 'TransitionData',
        'timing-function': 'ease-in-out',
        duration: 0.4,
        delay: 0,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'l945m',
        },
      },
      transformations: {
        type: 'TransformData',
        origin: {
          x: {
            value: 50,
            type: 'percentage',
          },
          y: {
            value: 50,
            type: 'percentage',
          },
        },
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'l945m',
        },
      },
      scopedTransformations: {
        'variants-kgt5rq2h': {
          type: 'TransformData',
          scale: {
            x: 1.15,
            y: 1.15,
          },
          rotate: -9,
          skew: {
            x: 0,
            y: 0,
          },
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'l945m',
          },
        },
      },
      style: {
        type: 'ComponentStyle',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'l945m',
        },
        style: {
          properties: {},
          propertiesSource: {},
          groups: {},
        },
        componentClassName: 'wysiwyg.viewer.components.VectorImage',
        styleType: 'custom',
        skin: 'skins.viewer.VectorImageSkin',
      },
      mobileStructure: {
        layout: {
          width: 73,
          height: 45,
          x: 45,
          y: 67,
          scale: 1,
          rotationInDegrees: 0,
          fixedPosition: false,
        },
        metaData: {
          originalCompId: 'comp-kgt5rq2s',
          author: 'studio',
        },
      },
    },
  ],
  layout: {
    width: 113,
    height: 120,
    x: 433,
    y: 190,
    scale: 1,
    rotationInDegrees: 0,
    fixedPosition: false,
  },
  componentType: 'mobile.core.components.Container',
  metaData: {
    pageId: 'l945m',
  },
  parent: 'l945m',
  variants: {
    'variants-kgt5rq2h': {
      componentId: 'comp-kgt5rq21',
      type: 'Hover',
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        pageId: 'l945m',
      },
    },
  },
  style: {
    type: 'ComponentStyle',
    metaData: {
      isPreset: false,
      schemaVersion: '1.0',
      isHidden: false,
      pageId: 'l945m',
    },
    style: {
      properties: {
        'alpha-bg': '0',
        'alpha-brd': '1',
        bg: '#C8EEF5',
        'boxShadowToggleOn-shd': 'false',
        brd: '#423737',
        brw: '0',
        rd: '0px',
        shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
      },
      propertiesSource: {
        'alpha-bg': 'value',
        'alpha-brd': 'value',
        bg: 'value',
        'boxShadowToggleOn-shd': 'value',
        brd: 'value',
        brw: 'value',
        rd: 'value',
        shd: 'value',
      },
      groups: {},
    },
    componentClassName: 'mobile.core.components.Container',
    pageId: '',
    compId: '',
    styleType: 'custom',
    skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
  },
  mobileStructure: {
    layout: {
      width: 164,
      height: 164,
      x: 89,
      y: 8,
      scale: 1,
      rotationInDegrees: 0,
      fixedPosition: false,
    },
    metaData: {
      originalCompId: 'comp-kgt5rq21',
      author: 'studio',
    },
  },
  activeModes: {},
};
