import React from 'react';

import { symbol as Symbol } from '@wix/santa-editor-symbols';
import * as UI from '@/baseUI';
import constants from '@/constants';
import { hoc, cx, type WithReportUIChange } from '@/util';

function getExtraFields(buttonName: string) {
  const fields: { category?: string } = {};
  switch (buttonName) {
    case 'textOutdentLeft':
    case 'textOutdentRight':
      fields.category = 'decrease';
      break;
    case 'textIndentLeft':
    case 'textIndentRight':
      fields.category = 'increase';
      break;
    case 'textWriteDirectionLeft':
      fields.category = 'RTL';
      break;
    case 'textWriteDirectionRight':
      fields.category = 'LTR';
      break;
  }
  return fields;
}

interface IconButtonOwnProps {
  tooltipValue?: unknown;
  style?: React.CSSProperties;
  tooltipStyleType?: ValueOf<typeof constants.UI.TOOLTIP.STYLE_TYPE>;
  disabled?: boolean;
  name?: string;
  popupMode?: boolean;
  isSelected?: boolean;
  className?: string;
  buttonMode?: boolean;
  onClick?: (event: React.MouseEvent) => void;
  biName?: string;
  reportedFields?: object;
  automationId?: string;
  instanceId?: string;
  label?: string;
  smallSize?: boolean;
}

interface IconButtonProps extends IconButtonOwnProps, WithReportUIChange {}

class IconButton extends React.Component<IconButtonProps> {
  static defaultProps = {
    isSelected: false,
    buttonMode: true,
    popupMode: false,
    reportedFields: {},
  } as any;

  getClassName = () => {
    const { className, isSelected, buttonMode, popupMode } = this.props;

    return cx(className, {
      small: this.props.smallSize,
      selected: isSelected,
      'icon-button': buttonMode && !popupMode,
      'icon-button popup': popupMode,
    });
  };

  handleClick: React.MouseEventHandler = (event) => {
    const extraFields = {
      ...this.props.reportedFields,
      ...getExtraFields(this.props.name),
    };
    this.props.reportUIChange(
      extraFields,
      this.props.name || this.props.biName,
    );
    this.props.onClick(event);
  };

  render() {
    return (
      <UI.tooltip
        value={this.props.tooltipValue}
        disabled={!this.props.tooltipValue}
        styleType={constants.UI.TOOLTIP.STYLE_TYPE.SMALL}
      >
        <div className="icon-button-container">
          <button
            onClick={this.handleClick}
            style={this.props.style}
            disabled={this.props.disabled}
            className={this.getClassName()}
            data-aid={this.props.automationId}
          >
            {this.props.name ? (
              <span key={`key${this.props.name}`}>
                <Symbol name={this.props.name} />
              </span>
            ) : null}
            <span>{this.props.children}</span>
            {this.props.popupMode ? (
              <UI.arrowButton key="iconButtonArrow" withoutBorder={true} />
            ) : null}
          </button>
        </div>
      </UI.tooltip>
    );
  }
}

export default hoc.reportUIChange(IconButton);
