// @ts-nocheck
import PropTypes from 'prop-types';
import React from 'react';
import * as BaseUI from '@/baseUI';

//TYPE WAS GENERATED, remove this line when reviewed
interface Props {
  onClick?: FunctionFixMe;
  tooltip?: string;
  symbol: string;
  isDisabled?: boolean;
}

export default class extends React.Component<Props> {
  static displayName = 'sectionControlsButton';

  static propTypes = {
    onClick: PropTypes.func,
    tooltip: PropTypes.string,
    symbol: PropTypes.string.isRequired,
    isDisabled: PropTypes.bool,
  };

  onClick = () => {
    if (!this.isDisabled) {
      this.props.onClick();
    }
  };

  render() {
    return (
      <BaseUI.tooltip
        value={this.props.tooltip}
        styleType="small"
        disabled={!this.props.tooltip}
      >
        <div
          onClick={() => {
            this.onClick();
          }}
          onMouseEnter={this.props.onMouseEnter}
          onMouseLeave={this.props.onMouseLeave}
          className={`section-controls-button ${
            this.props.label ? 'with-label' : ''
          } ${this.props.isDisabled ? 'disabled' : ''}`}
        >
          <BaseUI.symbol name={this.props.symbol} />
          <span>{this.props.label}</span>
        </div>
      </BaseUI.tooltip>
    );
  }
}
