import { EditorParamsApiKey, EditorAPIKey, LayoutApiKey } from '@/apis';
import { createComponentsApi } from './componentsWrapper';
import { ComponentsApiKey } from './publicApiKey';
import type { EntryPoint } from '@/apilib';

export const ComponentsEntrypoint: EntryPoint = {
  name: 'ComponentsApi',
  declareAPIs: () => [ComponentsApiKey],
  getDependencyAPIs: () => [EditorParamsApiKey, EditorAPIKey, LayoutApiKey],
  attach(shell) {
    shell.contributeAPI(ComponentsApiKey, () => createComponentsApi(shell));
  },
};

export type { ComponentsApi, ComponentsAddResult } from './componentsWrapper';
export type {
  ComponentAddedToStageData,
  AddPanelComponentAddedToStageByClick,
  AddPanelComponentAddedToStageByDrag,
} from './types';
