// @ts-nocheck
import _ from 'lodash';
import constants from '@/constants';

const directCustomizeDesignOnly = {
  'wysiwyg.viewer.components.svgshape.SvgShape': true,
  'wysiwyg.viewer.components.VectorImage': true,
  'wysiwyg.viewer.components.MediaOverlayControls': true,
  'wysiwyg.viewer.components.BoxSlideShowSlide': true,
  'wysiwyg.viewer.components.LoginButton': true,
  'wysiwyg.viewer.components.LoginSocialBar': true,
  'wysiwyg.viewer.components.AdminLoginButton': true,
  'wysiwyg.viewer.components.wixhomepage.HomePageLogin': true,
  'wysiwyg.viewer.components.BoxSlideShow': true,
  'wysiwyg.viewer.components.StripContainerSlideShow': true,
  'wysiwyg.viewer.components.StateBox': true,
  'wysiwyg.viewer.components.StateBoxState': true,
  'wysiwyg.viewer.components.StateBoxFormState': true,
  'wysiwyg.viewer.components.StateStrip': true,
  'wysiwyg.viewer.components.StateStripState': true,
  'wysiwyg.viewer.components.MenuContainer': true,
  'wysiwyg.viewer.components.ExpandableMenu': true,
  'wixui.MusicPlayer': true,
  'wixui.VideoPlayer': true,
  'wixui.ToggleSwitch': true,
  'dsgnsys.WutAvatar': true,
  'dsgnsys.WutAvatarGroup': true,
};

const { GALLERIES_COMP_TYPES } = constants.GALLERIES;

let newCustomizeDesignFlow = [
  'mobile.core.components.Container',
  'wysiwyg.viewer.components.SiteButton',
  'core.components.Image',
  'wysiwyg.viewer.components.WPhoto',
  'wysiwyg.viewer.components.mobile.TinyMenu',
  'wysiwyg.viewer.components.QuickActionBar',
  'wysiwyg.viewer.components.svgshape.SvgShape',
  'wysiwyg.viewer.components.inputs.ComboBoxInput',
];

newCustomizeDesignFlow = newCustomizeDesignFlow.concat(GALLERIES_COMP_TYPES);

const nonCustomStyleGalleries = [
  'tpa.viewer.components.StripShowcase',
  'wysiwyg.viewer.components.tpapps.TPA3DCarousel',
  'wysiwyg.viewer.components.tpapps.TPA3DGallery',
];

const regularAdvanceStylePanelGalleries = [
  'wysiwyg.viewer.components.SliderGallery',
  'wysiwyg.viewer.components.SlideShowGallery',
  'wysiwyg.viewer.components.MatrixGallery',
  'wysiwyg.viewer.components.PaginatedGridGallery',
];

export default {
  showAdvancedStylingOnly(editorAPI, compRefs, compType) {
    return (
      directCustomizeDesignOnly[compType] ||
      // eslint-disable-next-line you-dont-need-lodash-underscore/includes
      (_.includes(regularAdvanceStylePanelGalleries, compType) &&
        editorAPI.components.is.repeatedComponent(_.head(compRefs)))
    );
  },
  isTPAGalleryAdvancedStyle(compType) {
    return (
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/includes
      _.includes(GALLERIES_COMP_TYPES, compType) &&
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/includes
      !_.includes(regularAdvanceStylePanelGalleries, compType)
    );
  },
  isFastStylingEnabled(compType) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/includes
    return _.includes(newCustomizeDesignFlow, compType);
  },
  isGalleryCustomizable(compType) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/includes
    return !_.includes(nonCustomStyleGalleries, compType);
  },
};
