function repeaterItemDesignDataChangePlugin(
  editorAPI: AnyFixMe,
  compRef: AnyFixMe,
  designItem: AnyFixMe,
  retainCharas: AnyFixMe,
) {
  const isBackgroundChanged = !!designItem.background;
  if (editorAPI.components.is.repeaterItem(compRef) && !isBackgroundChanged) {
    const siblings = editorAPI.components.getSiblings(compRef);

    siblings.forEach((comp: AnyFixMe) => {
      editorAPI.dsActions.components.design.update(
        comp,
        designItem,
        retainCharas,
      );
    });
  }
}

export default repeaterItemDesignDataChangePlugin;
