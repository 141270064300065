// @ts-nocheck
import createReactClass from 'create-react-class';
import * as compPanelInfra from '@/compPanelInfra';
import React from 'react';
import { Divider } from '@wix/wix-base-ui';
import * as baseUI from '@/baseUI';
import CustomizationsContainer from './customizations/customizationsContainer';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'WixappsLayoutPanel',
  mixins: [compPanelInfra.compPanelMixin, compPanelInfra.wixappsPanelMixin],
  getCustomizations() {
    return this.getAvailableCustomizations('layout');
  },
  getViewOptions() {
    return this.getAvailableViewNames().map(function (viewName) {
      return {
        title: viewName,
        value: viewName,
        symbolName: viewName,
      };
    });
  },
  getLanguageDirectionOptions() {
    const isDesktop =
      this.getEditorAPI().viewMode.get() ===
      this.getEditorAPI().dsRead.viewMode.VIEW_MODES.DESKTOP;
    return (
      isDesktop &&
      this.isRTLAllowed() && [
        {
          value: 'ltr',
          symbolName: 'alignLeft',
        },
        {
          value: 'rtl',
          symbolName: 'alignRight',
        },
      ]
    );
  },
  /**
   * @returns {boolean}
   */
  isDesktopViewMode() {
    return this.getEditorAPI().viewMode.get() === 'DESKTOP';
  },
  render() {
    return (
      <compPanelInfra.compPanelFrame
        title={`${this.getSelectedAppPartDisplayName()}_layout`}
        contentClass="settings-panel"
        helpId={this.getComponentHelpIdByType('layout')}
        {...this.getFrameProps()}
      >
        {this.isDesktopViewMode() && this.getViewOptions().length > 1 ? (
          <div key="desktop-view">
            <baseUI.thumbnails
              maxThumbsPerRow="3"
              title="APP_PART_CHOOSE_LAYOUT"
              options={this.getViewOptions()}
              valueLink={this.linkedData('viewName')}
            />
          </div>
        ) : null}

        {this.isDesktopViewMode() &&
        this.getViewOptions().length > 1 &&
        this.getLanguageDirectionOptions() ? (
          <hr key="wixapps-layout-separator" className="divider-long" />
        ) : null}
        <div>
          {this.getLanguageDirectionOptions()
            ? (() => {
                const langOptions = this.getLanguageDirectionOptions();

                return (
                  <baseUI.thumbnails
                    maxThumbsPerRow="5"
                    key="lang"
                    options={langOptions}
                    title="APP_PART_CHOOSE_LAYOUT_POST_ALIGNMENT"
                    valueLink={this.linkedProperty('direction')}
                  />
                );
              })()
            : null}
        </div>

        <Divider long={true} />

        <CustomizationsContainer
          customizations={this.getCustomizations()}
          compPanel={this}
        />
      </compPanelInfra.compPanelFrame>
    );
  },
});
