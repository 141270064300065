import {
  EditorAPIKey,
  BaseResizeAndPushApiKey,
  EditorPermissionsApiKey,
} from '@/apis';
import { createClassicSectionResizeAndPushApi } from './classicSectionResizeAndPush';
import { ClassicSectionResizeAndPushApiKey } from './publicApiKey';

import type { EntryPoint } from '@/apilib';

export const ClassicSectionResizeAndPushEntryPoint: EntryPoint = {
  name: 'ClassicSectionResizeAndPush',
  declareAPIs: () => [ClassicSectionResizeAndPushApiKey],
  getDependencyAPIs: () => [
    EditorAPIKey,
    BaseResizeAndPushApiKey,
    EditorPermissionsApiKey,
  ],
  attach(shell) {
    shell.contributeAPI(ClassicSectionResizeAndPushApiKey, () =>
      createClassicSectionResizeAndPushApi(shell),
    );
  },
};
