import type { EditorAPI } from '@/editorAPI';
import type { PresetSection } from './types';

export const createTPASectionAPI = (editorAPI: EditorAPI) => {
  const isTPAPreset = (section: PresetSection) => {
    const [firstPreset] = section.props.items;
    return editorAPI.isTpa(firstPreset.structure.componentType);
  };

  return {
    isTPAPreset,
    async add(section: PresetSection) {
      const [firstPreset] = section.props.items;
      const { structure, id } = firstPreset;
      await section.props.onClick(structure, section.title, '', id, editorAPI);
    },
  };
};
