import React, { useEffect, useState } from 'react';
import { cx } from '@/util';
import { Scope } from '../mainEditorScope';
import { connectWithScope } from '@/apilib';
import styles from './siteCreationPagesView.scss';
import SiteCreationFPD from './siteCreationFPD';
import { symbol as Symbol } from '@wix/santa-editor-symbols';
import SiteCreationAdditionalPages from './siteCreationAdditionalPages';
import SiteCreationNavigationEndScreen from './siteCreationNavigationEndScreen';
import {
  mapStateToProps,
  mapDispatchToProps,
  type SiteCreationStateProps,
} from './siteCreationPagesViewMapper';

import type { UserContactInfo } from '@/editorPaas';
import type { PageAlternative } from '@/presetApi';
import type { SiteCreationDispatchProps } from './siteCreationPagesViewMapper';

export interface SiteCreationProps
  extends SiteCreationDispatchProps,
    SiteCreationStateProps {}

const SiteCreationPagesView: React.FC<SiteCreationProps> = (
  props: SiteCreationProps,
) => {
  const { initEditorPaas } = props;

  const [isVisible, setIsVisible] = useState<boolean>(true);
  const [isContentVisible, setIsContentVisible] = useState<boolean>(false);
  const [editorPaasInitFinished, setEditorPaasInitFinished] =
    useState<boolean>(false);
  const [userContactInfo, setUserContactInfo] = useState<UserContactInfo>();
  const [curatedTemplateId, setCuratedTemplateId] = useState<number>(0);
  const [addHomePagePromise, setAddHomePagePromise] =
    useState<Promise<void>>(null);
  const [endScreenVisible, setEndScreenVisible] = useState<boolean>(false);
  const [headerPresetNode, setHeaderPresetNode] = useState(null);
  const [footerPresetNode, setFooterPresetNode] = useState(null);
  const [chosenHomePagePreset, setChosenHomePagePreset] =
    useState<PageAlternative>(null);
  const [creationFinishPromise, setCreationFinishPromise] =
    useState<Promise<void>>(null);

  useEffect(() => {
    if (props.editorIsInit) {
      initEditorPaas().then(async () => {
        await props.setKit();
        setIsContentVisible(true);
        props.setSiteNameAsBusinessName();
        const newContactInfo = props.getUserContactInfo();
        setUserContactInfo(newContactInfo);
        setEditorPaasInitFinished(true);
      });
    } else {
      props.disableKeyboardShortcuts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.editorIsInit]);

  const getBusinessFirstEndScreen = () => (
    <SiteCreationNavigationEndScreen
      {...props}
      pagePreset={chosenHomePagePreset}
      headerPresetNode={headerPresetNode}
      footerPresetNode={footerPresetNode}
      setIsWizardVisible={setIsVisible}
      endScreenVisible={endScreenVisible}
      creationFinishPromise={creationFinishPromise}
    />
  );

  return (
    <div
      className={cx(styles.siteCreationView, {
        visible: isVisible,
      })}
      data-hook="site-creation-pages-view"
    >
      <div
        className={cx(styles.siteCreationViewContent, {
          visible: isContentVisible,
        })}
      >
        {props.businessFirstFlow ? getBusinessFirstEndScreen() : null}
        <SiteCreationAdditionalPages
          {...props}
          setIsWizardVisible={setIsVisible}
          userContactInfo={userContactInfo}
          headerPresetNode={headerPresetNode}
          footerPresetNode={footerPresetNode}
          curatedTemplateId={curatedTemplateId}
          addHomePagePromise={addHomePagePromise}
          setEndScreenVisible={setEndScreenVisible}
          setCreationFinishPromise={setCreationFinishPromise}
        />
        <SiteCreationFPD
          {...props}
          editorPaasInitFinished={editorPaasInitFinished}
          userContactInfo={userContactInfo}
          setHeaderPresetNode={setHeaderPresetNode}
          setFooterPresetNode={setFooterPresetNode}
          setCuratedTemplateId={setCuratedTemplateId}
          setAddHomePagePromise={setAddHomePagePromise}
          setChosenHomePagePreset={setChosenHomePagePreset}
        />
        <Symbol
          className={styles.siteCreationWixLogo}
          name="wixLogoSingleColor"
        />
      </div>
    </div>
  );
};

export default connectWithScope(
  () => Scope,
  SiteCreationPagesView,
  mapStateToProps,
  mapDispatchToProps,
);
