import React from 'react';
import { hoc, cx } from '@/util';
import {
  AccordionSection,
  Button,
  IconButton,
  Text,
  Composites,
  Tooltip,
} from '@wix/wix-base-ui';
import { EmptyTrash as Clear, Refresh } from '@wix/wix-ui-icons-common';

import styles from './copyPasteBar.scss';

import { mapDispatchToProps } from './copyPasteBarMapper';

export interface CopyPasteBarOwnProps {
  copyEnabled: boolean;
  onAfterPaste: () => void;
}

export interface CopyPasteBarPropsStateProps {}

export interface CopyPasteBarDispatchProps {
  copy: () => void;
  paste: () => void;
  clear: () => void;
  getClipboardState: () => void;
}

interface CopyPasteBarProps
  extends CopyPasteBarOwnProps,
    CopyPasteBarPropsStateProps,
    CopyPasteBarDispatchProps {}

interface ClipboardState {
  hasClipboard: boolean;
  siteName: string;
}

class CopyPasteBar extends React.Component<CopyPasteBarProps> {
  state: ClipboardState = {
    hasClipboard: false,
    siteName: null,
  };

  componentDidMount() {
    this.refresh();
  }

  refresh = () => {
    // @ts-expect-error
    this.setState(this.props.getClipboardState());
  };

  handleCopy = () => {
    this.props.copy();
    this.refresh();
  };

  handlePaste = async () => {
    await this.props.paste();
    this.props.onAfterPaste();
  };

  handleClear = () => {
    this.props.clear();
    this.refresh();
  };

  render() {
    return (
      <AccordionSection
        label="Site Sectionizer Clipboard"
        shouldTranslate={false}
        onToggle={this.refresh}
      >
        <div>
          <Composites.ActionSetHorizontalSpaced className={styles.buttons}>
            <Button
              onClick={this.handleCopy}
              className={cx({
                'btn-md': true,
                'btn-inverted': !this.props.copyEnabled,
              })}
            >
              Copy
            </Button>
            <Button
              onClick={this.handlePaste}
              disabled={!this.state.hasClipboard}
              className="btn-md btn-danger-primary"
            >
              Paste
            </Button>
            <Tooltip
              content="Refresh Clipboard"
              interactive={true}
              shouldTranslate={false}
            >
              <IconButton
                onClick={this.refresh}
                skin="standard"
                priority="inverted"
              >
                <Refresh />
              </IconButton>
            </Tooltip>
            <Tooltip
              content="Clear Clipboard"
              interactive={true}
              shouldTranslate={false}
            >
              <IconButton
                onClick={this.handleClear}
                disabled={!this.state.hasClipboard}
                skin="standard"
                priority="inverted"
              >
                <Clear />
              </IconButton>
            </Tooltip>
          </Composites.ActionSetHorizontalSpaced>

          {this.state.hasClipboard ? (
            <Text skin="standard" size="small" shouldTranslate={false}>
              {`Clipboard copied from: ${this.state.siteName}`}
            </Text>
          ) : null}
        </div>
      </AccordionSection>
    );
  }
}

export default hoc.connect(
  hoc.STORES.EDITOR_API,
  null,
  mapDispatchToProps,
)(CopyPasteBar);
