import React, { useEffect } from 'react';
import { hoc, biLogger } from '@/util';
import styles from './workspaceModesToggle.scss';
import {
  TextButton,
  RadioButtonsWithIllustration,
  Text,
} from '@wix/wix-base-ui';
import { WorkspaceModes } from '@/workspaceModes';
import { WorkspaceModesApiKey } from '@/apis';
import * as helpIds from '@/helpIds';
import * as symbols from '@wix/santa-editor-symbols';
import { panelOpenedTopBar } from '@wix/bi-logger-editor/v2';

import type { InferComponentProps } from '@/apilib';
import type { StateMapperArgs, Dispatch } from 'types/redux';
import type { EditorState } from '@/stateManagement';
import type { EditorAPI } from '@/editorAPI';

interface WorkspaceModesToggleTooltipOwnProps {
  closePanel: () => void;
}

type WorkspaceModesToggleTooltipProps = InferComponentProps<
  typeof mapStateToProps,
  typeof mapDispatchToProps,
  WorkspaceModesToggleTooltipOwnProps
>;

const WorkspaceModesToggleTooltip: React.FC<
  WorkspaceModesToggleTooltipProps
> = ({
  closePanel,
  openHelpCenter,
  setWorkspaceMode,
  currentWorkspaceMode,
  reportOpenWorkspaceModesPanel,
}) => {
  useEffect(() => {
    reportOpenWorkspaceModesPanel();
  }, [reportOpenWorkspaceModesPanel]);

  const options = [
    {
      value: WorkspaceModes.LITE,
      image: (
        <div className={styles.symbolWrapper}>
          <symbols.symbol name={'workspaceModeToggleTooltip-Lite'} />
        </div>
      ),
      title: 'lite_editor_topbar_indicator_dropdown_lite_editor_title',
      text: 'lite_editor_topbar_indicator_dropdown_lite_editor_subtitle',
    },
    {
      value: WorkspaceModes.FULL,
      image: (
        <div className={styles.symbolWrapper}>
          <symbols.symbol name={'workspaceModeToggleTooltip-Full'} />
        </div>
      ),
      title: 'lite_editor_topbar_indicator_dropdown_full_editor_title',
      text: 'lite_editor_topbar_indicator_dropdown_full_editor_subtitle',
    },
  ];

  return (
    <div className={styles.workspaceModesDropPanelContent}>
      <div className={styles.toggleTitleRow}>
        <Text size="small" weight="bold">
          lite_editor_topbar_indicator_dropdown_header
        </Text>
        <TextButton
          size="tiny"
          onClick={openHelpCenter}
          underline="none"
          className={styles.textButton}
        >
          lite_editor_topbar_indicator_dropdown_learn_more_CTA
        </TextButton>
      </div>
      <RadioButtonsWithIllustration
        value={currentWorkspaceMode}
        onChange={(mode: WorkspaceModes) => {
          closePanel();
          setWorkspaceMode(mode);
        }}
        options={options}
        selectionArea={true}
        className={styles.radioButtons}
      />
    </div>
  );
};

const mapStateToProps = ({ editorAPI }: StateMapperArgs) => {
  const workspaceModesApi = editorAPI.host.getAPI(WorkspaceModesApiKey);
  return {
    currentWorkspaceMode: workspaceModesApi.getMode(),
  };
};

const getEditorAPI = (
  dispatch: Dispatch,
  getState: () => EditorState,
  { editorAPI }: { editorAPI: EditorAPI },
): EditorAPI => editorAPI;

const mapDispatchToProps = (
  dispatch: Dispatch,
  { closePanel }: WorkspaceModesToggleTooltipOwnProps,
) => {
  const editorAPI = dispatch(getEditorAPI);
  const workspaceModesApi = editorAPI.host.getAPI(WorkspaceModesApiKey);
  return {
    openHelpCenter: () =>
      editorAPI.panelManager.openHelpCenter(helpIds.WORKSPACE_MODES.LEARN_MORE),
    setWorkspaceMode: (newMode: WorkspaceModes) => {
      workspaceModesApi.setMode(newMode, 'top bar');
      closePanel();
    },
    reportOpenWorkspaceModesPanel: () => {
      biLogger.report(
        panelOpenedTopBar({
          menu_name: 'workspaceModes',
          origin: 'top bar',
        }),
      );
    },
  };
};

export default hoc.connect(
  hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(WorkspaceModesToggleTooltip);
