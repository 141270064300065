export function getSessionBiHistoryUrl({
  uuid,
  metaSiteId,
  startTime,
  endTime,
}: {
  uuid: string;
  metaSiteId: string;
  startTime: number;
  endTime: number;
}) {
  return (
    `https://bo.wix.com/bi-ux/#/history?timezone=3` +
    `&fields=date_created,src,evid,evid_desc,error_type,error_info,tags,extra,error_context,origin,uuid,msid,esi,dm_version,os_name,browser_family,browser_name,app_name,country_code` +
    `&uuid=${uuid}` +
    `&start=${String(startTime)}` +
    `&stop=${String(endTime)}` +
    `&wql=msid%3D%22${metaSiteId}%22` +
    `&order=desc` +
    `&levels=1,2,3,4`
  );
}
