// @ts-nocheck
// Depreacted -> see ./languagePanel.tsx
import React from 'react';
import PropTypes from 'prop-types';
import * as coreBi from '@/coreBi';
import * as util from '@/util';
import { translate } from '@/i18n';
import mapper from './languageDropPanelMapper';
import coreMultilingual from 'coreMultilingual';
import constants from '@/constants';
import {
  Bubble,
  Button,
  Composites,
  Divider,
  PanelHeader,
  Tooltip,
} from '@wix/wix-base-ui';
import * as symbols from '@wix/santa-editor-symbols';

import LanguageRow from './languageRow';
import LanguageRowSmall from './languageRowSmall';
import LanguageRowMid from './languageRowMid';

const { LANGUAGE_STATUSES, keyboardContextKey } = constants.MULTILINGUAL;

const BUBBLE_ARROW_STYLE = {
  position: 'absolute',
  right: 'calc(50% - 8px)',
  top: '53px',
};

const BUBBLE_CONTENT_STYLE = {
  position: 'absolute',
  display: 'block',
  top: '60px',
  padding: 0,
  overflowY: 'auto',
  maxHeight: 'calc(100vh - 68px)',
};

const BUBBLE_CONTENT_STYLE_SMALL = {
  ...BUBBLE_CONTENT_STYLE,
  top: '40px',
  left: '80px',
};

const BIOriginToSourceMap = {
  top_bar: 'manage_languages_top_bar_drop_menu',
  secondary_bar: 'manage_languages_secondary_bar_drop_menu',
};

interface LanguageDropPanelProps {
  size?: 'small' | 'medium' | 'large';
  biOrigin?: string;
  onMouseEnter(): void;
  onMouseLeave(): void;
  closeHandle(): void;
}

class LanguageDropPanel extends React.Component<LanguageDropPanelProps> {
  constructor(props) {
    super(props);
    if (props.size === 'small') {
      this.bubbleContentStyle = BUBBLE_CONTENT_STYLE_SMALL;
    } else {
      this.bubbleContentStyle = BUBBLE_CONTENT_STYLE;
      this.bubbleArrowStyle = BUBBLE_ARROW_STYLE;
    }
  }

  componentDidMount() {
    this.registerKeyboardShortcuts();
    this.lastKeyboardContext = util.keyboardShortcuts.getContext();
    util.keyboardShortcuts.getContext(keyboardContextKey);

    if (this.props.biOrigin) {
      this.props.sendBI(
        coreBi.events.multilingual.language_picker_opened,
        { origin: this.props.biOrigin, hosting: this.props.biHosting },
        this.props.siteId,
      );
    }
  }

  componentWillUnmount() {
    util.keyboardShortcuts.setContext(this.lastKeyboardContext);

    if (this.props.biOrigin) {
      this.props.sendBI(
        coreBi.events.multilingual.language_picker_closed,
        { origin: this.props.biOrigin, hosting: this.props.biHosting },
        this.props.siteId,
      );
    }
  }

  setCurrentLanguage(lang) {
    if (lang.languageCode !== this.props.currentLanguageCode) {
      this.props.closeOpenedPanels();
      this.props.deselectAll();
      if (this.props.isPreviewMode) {
        this.props.setCurrentLanguage(lang.languageCode);
      } else {
        this.props.startLanguageChange(lang.languageCode);
      }

      const is_primary =
        lang.languageCode === this.props.originalLanguage.languageCode;

      this.props.sendBI(
        coreBi.events.multilingual.top_bar_drop_panel_choose_lang,
        {
          lang: lang.languageCode,
          origin: this.props.biOrigin,
          hosting: this.props.biHosting,
          is_visible: is_primary ? null : lang.status === 'Active',
          is_primary,
        },
        this.props.siteId,
      );
    }
  }

  updateLanguageStatus(lang, published) {
    const biProps = {
      lang: lang.languageCode,
      origin: 'editor_language_drop_panel',
    };

    if (published) {
      this.props.sendBI(
        coreBi.events.multilingual.top_bar_drop_panel_publish_lang,
        biProps,
        this.props.siteId,
      );

      this.props
        .updateLanguageStatus(lang.code, LANGUAGE_STATUSES.ACTIVE)
        .then(() => {
          if (!this.props.didShowPublishSuccessPanel) {
            this.props.openPublishSuccessPanel(lang.name);
          }
        });
    } else if (!this.props.didShowUnpublishPanel) {
      this.props.openUnpublishPanel(lang);
    } else {
      this.props.sendBI(
        coreBi.events.multilingual.top_bar_drop_panel_unpublish_lang,
        biProps,
        this.props.siteId,
      );
      this.props.updateLanguageStatus(lang.code, LANGUAGE_STATUSES.INACTIVE);
    }
  }

  getLanguageList() {
    return [this.props.originalLanguage, ...this.props.translationLanguages];
  }

  getFlagUrl(lang) {
    return coreMultilingual.utils.getFlagIconUrl(
      util.serviceTopology.scriptsLocationMap['linguist-flags'],
      coreMultilingual.flagCountryIconType.rounded,
      lang.countryCode,
    );
  }

  getLanguageName(lang) {
    return translate(`locale-dataset.languages.${lang.languageCode}`);
  }

  isHidden(lang) {
    return lang.status !== LANGUAGE_STATUSES.ACTIVE;
  }

  isSelected(lang) {
    return lang.languageCode === this.props.currentLanguageCode;
  }

  isPrimaryLanguage(lang) {
    return lang.languageCode === this.props.originalLanguage.languageCode;
  }

  onManagePanelButtonClick = () => {
    this.props.openPanel({});

    const source =
      BIOriginToSourceMap[this.props.biOrigin] ||
      'manage_languages_top_bar_drop_menu';
    this.props.sendBI(
      coreBi.events.multilingual.manage_languages,
      {
        source,
        hosting: this.props.biHosting,
      },
      this.props.siteId,
    );
  };

  registerKeyboardShortcuts() {
    const self = this;
    const currentLanguageSwitchContext = {
      esc() {
        self.props.closeHandle();
      },
    };
    util.keyboardShortcuts.registerContext(
      keyboardContextKey,
      currentLanguageSwitchContext,
    );
  }

  getFlagIcon(lang) {
    return React.createElement(
      'div',
      { className: 'flag-icon' },
      React.createElement('img', { src: this.getFlagUrl(lang), alt: '' }),
    );
  }

  getLanguageStatus(lang) {
    if (this.isHidden(lang)) {
      return React.createElement(
        Tooltip,
        {
          content: 'LangMenu_Manager_Panel_Activate_Tooltip',
          key: 'lang-status-tooltip',
        },
        React.createElement(symbols.symbol, {
          name: 'quickActionsHide',
          className: 'language-status',
        }),
      );
    }

    if (this.isPrimaryLanguage(lang)) {
      return React.createElement(
        'div',
        {
          className: 'main-language-indicator',
        },
        translate('SiteLanguages_Panel_Label_Main'),
      );
    }

    return null;
  }

  getLanguageSubText(lang) {
    return lang.code.toUpperCase();
  }

  makeLanguageRow = (lang, index) => {
    const languageRowProps = {
      key: lang.name,
      index,
      isSelected: this.isSelected(lang),
      isPrimary: this.isPrimaryLanguage(lang),
      flagUrl: this.getFlagUrl(lang),
      languageName: this.getLanguageName(lang),
      published: !this.isHidden(lang),
      onSelect: () => this.setCurrentLanguage(lang),
      onChange: (newStatus) => this.updateLanguageStatus(lang, newStatus),
    };

    switch (this.props.size) {
      case 'small':
        return <LanguageRowSmall {...languageRowProps} />;
      case 'large':
        return (
          <LanguageRowMid
            {...languageRowProps}
            isPreview={this.props.isPreviewMode}
          />
        );
      default:
        return (
          <LanguageRow
            {...languageRowProps}
            languageCode={this.getLanguageSubText(lang)}
          />
        );
    }
  };

  render() {
    return (
      <Bubble
        arrowAlignment="top"
        contentStyle={this.bubbleContentStyle}
        arrowStyle={this.bubbleArrowStyle}
        onMouseLeave={this.props.onMouseLeave}
        maxWidth={750}
        className="drop-panel-top-bar drop-panel-language-bubble"
      >
        <div
          onClick={this.props.closeHandle}
          className="click-outside-overlay"
        />
        <div
          key="MLTopBarDropDown"
          onMouseEnter={this.props.onMouseEnter}
          onMouseLeave={this.props.onMouseLeave}
          className="languages-panel-container"
        >
          {this.props.size === 'large' && (
            <>
              <PanelHeader
                className="light"
                onHelp={this.props.openHelpCenter}
                noCloseBtn
              >
                <span>{translate('SiteLanguages_Panel_Title')}</span>
              </PanelHeader>
              <Divider long />
            </>
          )}

          {this.getLanguageList().map(this.makeLanguageRow)}

          <div
            key="MLTopBarManageLangs"
            className={`manage-panel-button ${
              this.props.size !== 'medium' && 'manage-panel-button-small'
            }`}
          >
            <Composites.ButtonLarge>
              <Button
                className={util.cx({
                  'btn-text': this.props.size === 'small',
                  'btn-confirm-secondary': this.props.size === 'large',
                })}
                onClick={this.onManagePanelButtonClick}
              >
                {translate('LangMenu_Manager_Button_Manage_Lang')}
              </Button>
            </Composites.ButtonLarge>
          </div>
        </div>
      </Bubble>
    );
  }
}

LanguageDropPanel.displayName = 'editorLanguagePanel';

LanguageDropPanel.propTypes = {
  size: PropTypes.string,
  currentLanguageCode: PropTypes.string,
  originalLanguage: PropTypes.object,
  translationLanguages: PropTypes.arrayOf(PropTypes.object),
  isEnabled: PropTypes.bool.isRequired,
  isPreviewMode: PropTypes.bool.isRequired,
  didShowPublishSuccessPanel: PropTypes.bool,
  didShowUnpublishPanel: PropTypes.bool,
  siteId: PropTypes.string.isRequired,
  //actions
  setCurrentLanguage: PropTypes.func.isRequired,
  startLanguageChange: PropTypes.func.isRequired,
  closeOpenedPanels: PropTypes.func.isRequired,
  deselectAll: PropTypes.func.isRequired,
  sendBI: PropTypes.func.isRequired,
  openPanel: PropTypes.func.isRequired,
  closeHandle: PropTypes.func,
  onMouseLeave: PropTypes.func,
};

LanguageDropPanel.defaultProps = {
  closeHandle: () => {},
};

const ConnectedLanguageDropPanel = util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  mapper.mapStateToProps,
  mapper.mapDispatchToProps,
)(LanguageDropPanel);

ConnectedLanguageDropPanel.pure = LanguageDropPanel;

export default ConnectedLanguageDropPanel;
