// @ts-nocheck
import _ from 'lodash';
import * as tpa from '@/tpa';
import constants from '@/constants';
import addPanelDataConsts from '@/addPanelDataConsts';
import * as stateManagement from '@/stateManagement';
import type { EditorAPI } from '@/editorAPI';
import * as util from '@/util';

const { isRefComponentSupported } = stateManagement.platform.selectors;

const WIX_BOOKINGS_APP_DEF_ID = '13d21c63-b5ec-5912-8397-c3a5ddb27a97';
const WIX_STORE_APP_DEF_ID = util.tpaUtils.getStoresAppDefId();
const RELEVANT_POST_REGS = [
  'business',
  'photography',
  'design',
  'events',
  'beauty-wellness',
  'other',
  'all',
  'fitness',
  null,
];

const SETUP_STEPS = {
  createService: 'create-service',
  editService: 'edit-service',
  workingHours: 'working-hours',
  email: 'email',
  payment: 'payment',
  done: 'done',
  undefined: 'undefined',
};

const BOOKINGS_BO_PAGES = {
  offeringList: 'scheduler/owner/offerings',
  calendar: 'scheduler/owner/calendar',
  newForm: 'scheduler/owner/offerings/wizard',
  settings: 'settings',
  availability: 'scheduler/owner/bookings-options/calendar-settings',
  email: 'settings-email',
  payment: 'payments',
};

function getAppData(editorAPI) {
  const appData = editorAPI.dsRead.tpa.app.getDataByAppDefId(
    WIX_BOOKINGS_APP_DEF_ID,
  );

  return appData ? appData : {};
}

function parseInstance(instance) {
  const base64Instance = instance.split('.')[1];
  return base64Instance && JSON.parse(window.atob(base64Instance));
}

const params = {
  WIX_BOOKINGS_APP_DEF_ID,
  WIX_STORE_APP_DEF_ID,
  RELEVANT_POST_REGS,
  SETUP_STEPS,
  BOOKINGS_BO_PAGES,
};

const superAppData = {
  superAppId: 'wixBookings',
  superAppPanelName: constants.SUPER_APPS.BOOKINGS_PANEL_NAME,
  superAppButtonLabel: 'SiteApp_WixBookings_Button_Hover_Caption',
  superAppPanelClassName: 'wix-bookings-panel',
  superAppSymbolName: util.workspace.isNewWorkspaceEnabled()
    ? 'leftBarButtonBookings'
    : 'leftBarWixBookingsPanel',
};

const featureToggles = {
  isBookingsTimetableEnabled: (editorAPI) => isRefComponentSupported(editorAPI),
  isBookingsStaffListWidgetEnabled: (editorAPI) =>
    isRefComponentSupported(editorAPI),
};

const editorApiReliantMethods = {
  getMetaSiteId: (editorAPI) => editorAPI.dsRead.generalInfo.getMetaSiteId(),
  getBiToken: (editorAPI) =>
    parseInstance(getAppData(editorAPI).instance)?.biToken,
  getInstanceId: (editorAPI) => getAppData(editorAPI)?.instanceId,
  getAppInstance: (editorAPI) => parseInstance(getAppData(editorAPI)?.instance),
  getInstance: (editorAPI) => getAppData(editorAPI)?.instance,
  hasBookingsPreInstallationOffer: (editorAPI) =>
    stateManagement.introFunnel.selectors.hasAppIntent(
      editorAPI.store.getState(),
    ),
  isAppInstalled: (editorAPI) =>
    editorAPI.dsRead.tpa.app.isInstalled(WIX_BOOKINGS_APP_DEF_ID),
  isSitePublished: (editorAPI) =>
    editorAPI.dsRead.generalInfo.isSitePublished(),
  getAppData: (editorAPI) => getAppData(editorAPI),
  isWixBookingsInstalled: (editorAPI: EditorAPI) =>
    editorAPI.dsRead.tpa.app.isInstalled(WIX_BOOKINGS_APP_DEF_ID),
  isWixStoresInstalled: (editorAPI) =>
    editorAPI.dsRead.tpa.app.isInstalled(WIX_STORE_APP_DEF_ID),
  isAppProvisioned: (editorAPI) => {
    const isSiteSaved = !editorAPI.dsRead.generalInfo.isFirstSave();
    const isAppProvisionedOnServer =
      editorAPI.dsRead.tpa.app.isAppProvisionedOnServer(
        WIX_BOOKINGS_APP_DEF_ID,
      );
    return (
      isSiteSaved &&
      isAppProvisionedOnServer &&
      !getAppData(editorAPI)?.demoMode
    );
  },
  isTemplate: (editorAPI) => editorAPI.dsRead.generalInfo.isFirstSave(),
  isInPreInstallationFunnel: (editorAPI) => {
    const introFunnelAppIntents =
      stateManagement.introFunnel.selectors.getAppIntents(
        editorAPI.store.getState(),
      );
    return introFunnelAppIntents.find(
      (intent) =>
        intent === stateManagement.introFunnel.IntroFunnelIntents.BOOKINGS,
    );
  },
};

const miscActions = {
  hasPremium: () => tpa.superApps.isPremium(WIX_BOOKINGS_APP_DEF_ID),
  getAddElementCategoryId: () => addPanelDataConsts.CATEGORIES_ID.BOOKINGS,
};

// eslint-disable-next-line you-dont-need-lodash-underscore/assign
export default _.assign(
  miscActions,
  editorApiReliantMethods,
  featureToggles,
  superAppData,
  params,
);
