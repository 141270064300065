import constants from '@/constants';
import * as helpIds from '@/helpIds';

const { ACTIONS } = constants.ROOT_COMPS.GFPP;

const tinyMenuGfppData = {
  mobileEnabledActions: [ACTIONS.SETTINGS],
  presetActions: {
    help: {
      mobileHelpId: helpIds.GFPP.TINY_MENU,
    },
  },
};

export default tinyMenuGfppData;
