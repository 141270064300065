import {
  reportErrorOnInjection,
  reportOnNullOrEmptyContent,
} from '../contentInjectionUtil';
import { caasPropNames } from './injectConsts';

export function isButtonInjection(propName: string): boolean {
  const { BUTTON } = caasPropNames;
  return BUTTON === propName;
}

export function handleButtonInjection(
  structureString: string,
  injectableLabel: string,
  labelToInject: string,
) {
  if (!labelToInject) {
    reportOnNullOrEmptyContent('button');
    return structureString;
  }
  try {
    const structureStringWithLabelInjected = structureString.replace(
      injectableLabel,
      labelToInject,
    );

    JSON.parse(structureStringWithLabelInjected);
    return structureStringWithLabelInjected;
  } catch (e) {
    reportErrorOnInjection(e, labelToInject);
    return structureString;
  }
}
