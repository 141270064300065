// @ts-nocheck
import createReactClass from 'create-react-class';
import _ from 'lodash';
import * as core from '@/core';
import * as coreBi from '@/coreBi';
import React from 'react';
import WelcomeVideoPanel from './welcomeVideoPanel';
import * as baseUI from '@/baseUI';
import { translate } from '@/i18n';

const bi = coreBi;
const videoUrl = translate('Notification_Box_Get_Traffic_Video_URL');
let panelDescriptionText = [];

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  propTypes: {},
  displayName: 'getTrafficPanel',
  mixins: [core.mixins.editorAPIMixin],

  getFoundOnGoogle() {
    this.sendPanelGetFoundEvent('getFoundOnGoogle');
    const getFoundUrl = translate(
      'Notification_Box_Get_Traffic_Submit_Website_Button_Link',
    );
    window.open(getFoundUrl, '_blank');
  },
  getPublicUrl() {
    const editorAPI = this.getEditorAPI();
    let publicUrl = editorAPI.dsRead.generalInfo.getPublicUrl();
    if (publicUrl) {
      publicUrl = publicUrl.replace(/.*?:\/\//g, ''); //remove http:/ or https://
    }
    return publicUrl;
  },
  searchOnGoogle() {
    const searchGooglePrefix = 'https://www.google.co.il/?#q=site:';
    const publicUrl = this.getPublicUrl(searchGooglePrefix);
    const url = searchGooglePrefix + publicUrl;
    if (publicUrl) {
      window.open(url, '_blank');
    }
    this.sendSearchOnGoogleEvent(url);
  },

  getContentClass() {
    return 'only-new';
  },

  linkedVideoData() {
    return videoUrl;
  },
  getDescription(index) {
    if (_.isEmpty(panelDescriptionText)) {
      const url = `site:${this.getPublicUrl()}`;
      const translationString = translate(
        'Notification_Box_Get_Traffic_Bottom_Text',
        { domain_address: url },
      );
      panelDescriptionText = translationString.split(url);
      panelDescriptionText.push(url);
    }
    return panelDescriptionText.length >= index
      ? panelDescriptionText[index]
      : '';
  },
  sendSearchOnGoogleEvent(url) {
    const editorAPI = this.getEditorAPI();
    const BIEvent = bi.events.getTrafficPanel.GET_TRAFFIC_SEARCH_GOOGLE_CLICK;
    const params = {
      site_id: editorAPI.dsRead.generalInfo.getSiteId(),
      url,
    };
    editorAPI.bi.event(BIEvent, params);
  },
  sendPanelGetFoundEvent(origin) {
    const editorAPI = this.getEditorAPI();
    const BIEvent =
      bi.events.getTrafficPanel.GET_TRAFFIC_GET_FOUND_ON_GOOGLE_CLICK;
    const params = {
      site_id: editorAPI.dsRead.generalInfo.getSiteId(),
      origin,
      video_url: videoUrl,
    };
    editorAPI.bi.event(BIEvent, params);
  },
  render() {
    return (
      <WelcomeVideoPanel
        ref="frame"
        title={translate('Notification_Box_Get_Traffic_Title')}
        subtitle={translate('Notification_Box_Get_Traffic_Description')}
        linkedVideoData={this.linkedVideoData()}
        panelName={this.props.panelName}
        shouldNotCloseOnBlur={true}
        className="getTrafficPanel"
      >
        <div className="get-traffic-description content">
          <span className="text-before-link">{this.getDescription(0)}</span>
          <a
            href="#"
            onClick={() => {
              this.searchOnGoogle();
            }}
            className="get-found-link"
          >
            {this.getDescription(2)}
          </a>
          <span className="text-after-link">{this.getDescription(1)}</span>
        </div>
        <div className="buttons">
          <baseUI.button
            label="Notification_Box_Get_Traffic_Submit_Website_Button_Label"
            onClick={() => {
              this.getFoundOnGoogle();
            }}
          />
        </div>
      </WelcomeVideoPanel>
    );
  },
});
