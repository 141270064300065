import React from 'react';
import { translate } from '@/i18n';
import * as util from '@/util';
import { mapStateToProps, mapDispatchToProps } from './upgrade.mapper';
import { EmptyState } from '@wix/wix-base-ui/next';
import { createWixBaseUiEnvironmentProvider } from './createWixBaseUiEnvironmentProvider';
import * as symbols from '@wix/santa-editor-symbols';
import { isResponsiveEditor } from '@wix/santa-editor-utils';
import type { UpgradeOwnProps, UpgradeDispatchProps } from './upgrade.mapper';

interface UpgradeProps extends UpgradeOwnProps, UpgradeDispatchProps {}

const Upgrade: React.FC<UpgradeProps> = ({
  openPremiumPackagePicker,
}: UpgradeProps) => {
  const WixBaseUiEnvironmentProvider = createWixBaseUiEnvironmentProvider();
  return (
    <div>
      <WixBaseUiEnvironmentProvider
        theme={isResponsiveEditor() ? 'responsive' : 'classic'}
      >
        <EmptyState
          dataHook="upgrade-tab-content"
          image={
            isResponsiveEditor() ? undefined : (
              <symbols.symbol name="collectionsManagerPremiumTab" />
            )
          }
          title={translate('CONTENT_MANAGER_6_BUTTON_UPGRADE_TITLE')}
          content={translate('CONTENT_MANAGER_6_BUTTON_UPGRADE_PARAGRAPH')}
          primaryButton={{
            text: translate('CONTENT_MANAGER_6_BUTTON_UPGRADE_BUTTON'),
            onClick: openPremiumPackagePicker,
            skin: 'premium',
          }}
        />
      </WixBaseUiEnvironmentProvider>
    </div>
  );
};

export default util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(Upgrade);
