import addPanelDataConsts from '@/addPanelDataConsts';
import type { EditorAPI } from '@/editorAPI';
import type { CompRef, CompStructure } from 'types/documentServices';
import superAppsConstants from '../../superApps/superAppsConstants';
import { addCompOnClick, addCompOnDrop } from '../addPanelAddCompService';

const appDefinitionId = superAppsConstants.SPOTIFY.APP_DEF_ID;
const widgetId = superAppsConstants.SPOTIFY.WIDGET;
const SECTION_INDEX_IN_EMBEDS_GROUP = 1;
const SECTION_INDEX_IN_MEDIA_GROUP = 3;

const registerSection = function (editorAPI: EditorAPI): void {
  editorAPI.addPanel.registerSectionToCategoryByGroup(
    addPanelDataConsts.CATEGORIES_ID.EMBEDS,
    2,
    SECTION_INDEX_IN_EMBEDS_GROUP,
    () => getSection(addPanelDataConsts.CATEGORIES_ID.EMBEDS),
  );
  editorAPI.addPanel.registerSectionToCategoryByGroup(
    addPanelDataConsts.CATEGORIES_ID.MEDIA,
    1,
    SECTION_INDEX_IN_MEDIA_GROUP,
    () => getSection(addPanelDataConsts.CATEGORIES_ID.MEDIA),
  );
};

const getBiOptions = (category: string) => ({
  origin: 'add_panel',
  sectionTitle: 'add_section_label_spotifyplayer',
  category,
});

const addApp =
  (biCategory: string) =>
  (
    compStructure: CompStructure,
    sectionTitle: string,
    tags: string[],
    id: string,
    editorAPI: EditorAPI,
    addOptions: AnyFixMe,
  ): Promise<CompRef> =>
    addCompOnClick(
      editorAPI,
      appDefinitionId,
      widgetId,
      compStructure,
      tags,
      id,
      getBiOptions(biCategory),
      addOptions,
    );

const onDrop =
  (biCategory: AnyFixMe) =>
  (
    layoutParams: AnyFixMe,
    compPreset: AnyFixMe,
    parentComponent: CompRef,
    editorAPI: EditorAPI,
  ) =>
    addCompOnDrop(
      editorAPI,
      appDefinitionId,
      widgetId,
      layoutParams,
      compPreset,
      parentComponent,
      () => {},
      getBiOptions(biCategory),
    );

const getSection = function (biCategory: string) {
  return {
    type: addPanelDataConsts.SECTIONS_TYPES.PRESET,
    hide: false,
    title: 'add_section_label_spotifyplayer',
    subNavigationTitle: 'add_section_label_spotifyplayer',
    presetTitle: 'add_section_label_spotifyplayer',
    tooltipTitle: 'add_section_label_spotifyplayer',
    sectionName: 'spotifyPlayerSection',
    id: 'add_panel_section_label_spotifyplayer',
    automationId: 'add-panel-section-spotifyPlayerAppSection',
    appDefinitionId,
    subNavigationHide: false,
    showSectionHeader: true,
    additionalBehaviours: {
      labelMode: addPanelDataConsts.LABEL_BEHAVIOUR.HOVER,
      infoIcon: addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
      hoverImageAction: addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
      iconEnabledForComps: {},
    },
    props: {
      onClick: addApp(biCategory),
      onDrop: onDrop(biCategory),
      image: 'compPanels/spotifyApp/spotifyApp.png',
      items: [
        {
          id: 'Music_Spotify_Player_1',
          structure: {
            type: 'Component',
            skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
            layout: {
              width: 250,
              height: 82,
              x: 573,
              y: 381,
              scale: 1,
              rotationInDegrees: 0,
              fixedPosition: false,
            },
            componentType: 'wysiwyg.viewer.components.tpapps.TPAWidget',
            metaData: {
              pageId: 'jodle',
            },
            parent: 'jodle',
            data: {
              type: 'TPAWidget',
              metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false,
                pageId: 'jodle',
              },
              applicationId: '19270',
              appDefinitionId,
              widgetId,
            },
            connections: {
              type: 'ConnectionList',
              metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false,
                pageId: 'jodle',
              },
              items: [
                {
                  type: 'WixCodeConnectionItem',
                  role: '53D0A5Fa3Da5418B84D6E58Bd55Fecad1',
                },
              ],
            },
            style: 'tpaw0',
            mobileStructure: {
              layout: {
                width: 280,
                height: 80,
                x: 20,
                y: 45,
                scale: 1,
                rotationInDegrees: 0,
                fixedPosition: false,
              },
              metaData: {
                originalCompId: 'comp-kgs1umtl',
                author: 'duplicate',
              },
            },
            activeModes: {},
          },
          preset: {
            rect: { width: 324, height: 110, x: 0, y: 0 },
            label: '',
            tags: null as AnyFixMe,
          },
        },
      ],
    },
    help: {
      hide: false,
      text: 'add_section_info_text_spotifyplayer',
    },
  };
};

export { registerSection };
