import * as stateManagement from '@/stateManagement';
import { media } from '@/util';

import type { EditorAPI } from '@/editorAPI';
import type { StateMapperArgs, Dispatch, ThunkAction } from 'types/redux';

export const mapStateToProps = ({ editorAPI }: StateMapperArgs) => ({
  mediaServices: editorAPI.mediaServices,
});

const getEditorAPI: ThunkAction<EditorAPI> = (
  _dispatch,
  _getState,
  { editorAPI },
) => editorAPI;

export const mapDispatchToProps = (dispatch: Dispatch) => {
  const editorAPI: EditorAPI = dispatch(getEditorAPI);

  return {
    openPanel(
      panelName: string,
      panelProps: AnyFixMe,
      leavePanelsOpen?: boolean,
    ) {
      editorAPI.panelManager.openPanel(panelName, panelProps, leavePanelsOpen);
    },
    setLinksAsIndexableForDoc(linkDocId: string) {
      media.setLinksAsIndexableForDoc(editorAPI, linkDocId);
    },
    checkIsIndexablePdf(linkDocId: string) {
      return (
        media.isFilePDF(linkDocId) &&
        media.isPdfAlreadyMarkedIndexable(editorAPI, linkDocId)
      );
    },
    sendBI(eventType: object, biParams: object) {
      dispatch(stateManagement.bi.actions.event(eventType, biParams));
    },
    createDefaultData(id: string) {
      return editorAPI.dsRead.data.createItem(id);
    },
  };
};
