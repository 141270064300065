import * as baseUI from '@/baseUI';
import constants from '@/constants';
import * as core from '@/core';
import * as coreBi from '@/coreBi';
import { translate } from '@/i18n';
import * as stateManagement from '@/stateManagement';
import * as util from '@/util';
import { isCustomMenusEnabled } from '@/util';
import * as symbols from '@wix/santa-editor-symbols';
import {
  CustomScroll,
  Divider,
  RichText,
  ToggleSwitch,
  Composites,
  TextLabel,
  DropDown,
  DropDownOption,
} from '@wix/wix-base-ui';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import React from 'react';
import settingsPanelConstants from '../../utils/constants';
import CONSTANTS from '../../utils/constants';
import pageSettingsTabMixin from './pageSettingsTabMixin';
import {
  mapDispatchToProps,
  type TabPageInfoMainMenuNewDispatchProps,
} from './tabPageInfoMainMenuMapper';
import experiment from 'experiment';

import type { EditorAPI } from '@/editorAPI';

enum SSRCacheDuration {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '1Hour' = 1,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '2Hours' = 2,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '4Hours' = 4,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '6Hours' = 6,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '12Hours' = 12,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '24Hours' = 24,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '7Days' = 168,
  Maximum = -1,
  Disable = 0,
}

const DEFAULT_SSR_CACHE = SSRCacheDuration.Maximum;

function setSitePrefsCacheHours(
  editorAPI: EditorAPI,
  pageId: AnyFixMe,
  cacheMaxHours: AnyFixMe,
) {
  editorAPI.store.dispatch(
    stateManagement.userPreferences.actions.setSiteUserPreferences(
      `${constants.USER_PREFS.SSR_CACHE.MAX_HOURS}_${pageId}`,
      cacheMaxHours || 0,
    ),
  ); // we shouldn't send null values
}

function getSitePrefsCacheHours(editorAPI: EditorAPI, pageId: AnyFixMe) {
  return stateManagement.userPreferences.selectors.getSiteUserPreferences(
    `${constants.USER_PREFS.SSR_CACHE.MAX_HOURS}_${pageId}`,
  )(editorAPI.store.getState());
}

function setSitePrefsAdvancedSetting(
  editorAPI: EditorAPI,
  pageId: string,
  state: AnyFixMe,
) {
  editorAPI.store.dispatch(
    stateManagement.userPreferences.actions.setSiteUserPreferences(
      `${constants.USER_PREFS.SSR_CACHE.ADVANCED_SETTING}_${pageId}`,
      state,
    ),
  );
}

function getSitePrefsAdvancedSetting(editorAPI: EditorAPI, pageId: string) {
  return !!stateManagement.userPreferences.selectors.getSiteUserPreferences(
    `${constants.USER_PREFS.SSR_CACHE.ADVANCED_SETTING}_${pageId}`,
  )(editorAPI.store.getState());
}

function setSitePrefsToggleState(
  editorAPI: EditorAPI,
  pageId: string,
  state: AnyFixMe,
) {
  editorAPI.store.dispatch(
    stateManagement.userPreferences.actions.setSiteUserPreferences(
      `${constants.USER_PREFS.SSR_CACHE.TOGGLE_STATE}_${pageId}`,
      state,
    ),
  );
}

function getSitePrefsToggleState(editorAPI: EditorAPI, pageId: string) {
  return stateManagement.userPreferences.selectors.getSiteUserPreferences(
    `${constants.USER_PREFS.SSR_CACHE.TOGGLE_STATE}_${pageId}`,
  )(editorAPI.store.getState());
}

function getSiteCacheHours(editorAPI: EditorAPI, pageId: string) {
  if (experiment.isOpen('se_siteCacheHoursInDocument')) {
    return (
      // @ts-expect-error
      editorAPI.pages.data.get(pageId)?.cacheMaxAgeHours ?? DEFAULT_SSR_CACHE
    );
  }

  return getSitePrefsCacheHours(editorAPI, pageId);
}

function getSiteToggleState(editorAPI: EditorAPI, pageId: string) {
  if (experiment.isOpen('se_siteCacheHoursInDocument')) {
    // @ts-expect-error
    const cacheMode = editorAPI.pages.data.get(pageId)?.cacheMode;

    return cacheMode === 'manual';
  }

  return getSitePrefsToggleState(editorAPI, pageId);
}

const ssrCacheOptions = [
  {
    key: 'Pages_Info_SSR_Cache_Frequency_Dropdown_Option1',
    value: SSRCacheDuration['1Hour'],
  },
  {
    key: 'Pages_Info_SSR_Cache_Frequency_Dropdown_Option2',
    value: SSRCacheDuration['2Hours'],
  },
  {
    key: 'Pages_Info_SSR_Cache_Frequency_Dropdown_Option3',
    value: SSRCacheDuration['4Hours'],
  },
  {
    key: 'Pages_Info_SSR_Cache_Frequency_Dropdown_Option4',
    value: SSRCacheDuration['6Hours'],
  },
  {
    key: 'Pages_Info_SSR_Cache_Frequency_Dropdown_Option5',
    value: SSRCacheDuration['12Hours'],
  },
  {
    key: 'Pages_Info_SSR_Cache_Frequency_Dropdown_Option6',
    value: SSRCacheDuration['24Hours'],
  },
  {
    key: 'Pages_Info_SSR_Cache_Frequency_Dropdown_Option7',
    value: SSRCacheDuration['7Days'],
  },
  {
    key: 'Pages_Info_SSR_Cache_Frequency_Dropdown_Option8',
    value: SSRCacheDuration.Maximum,
  },
];

if (experiment.isOpen('se_cachingPagesDisable')) {
  ssrCacheOptions.push({
    key: 'Pages_Info_SSR_Cache_Frequency_Dropdown_Option9',
    value: SSRCacheDuration.Disable,
  });
}

interface CachingPageUpdate {
  cacheMaxAgeHours: number;
  cacheMode?: 'auto' | 'manual' | 'disabled';
}

export interface TabPageInfoMainMenuNewOwnProps {
  menuItem: any;
}

interface TabPageInfoMainMenuNewProps
  extends TabPageInfoMainMenuNewOwnProps,
    TabPageInfoMainMenuNewDispatchProps {}

const TabPageInfoMainMenuNewComponent =
  // eslint-disable-next-line react/prefer-es6-class
  createReactClass<TabPageInfoMainMenuNewProps>({
    displayName: 'tabPageInfoMainMenuNew',
    mixins: [pageSettingsTabMixin],
    render() {
      const isHome = this.isHomePage();

      return (
        <CustomScroll keepAtBottom={this.state.scrollingPage}>
          <div className="page-info tab-inner">
            <section className="section-page-title">
              <baseUI.textInput
                label={this.getLabel()}
                maxLength={CONSTANTS.NAME_MAX_LENGTH}
                validator={[
                  util.validate.notEmptyString,
                  util.validate.noHTMLTags,
                  util.validate.noEmoji,
                ]}
                invalidMessage="Pages_Info_Name_Error_Tooltip"
                value={this.getValueFromPageData('title')}
                onChange={this.onChange}
                validateOnBlurOnly={true}
                infoText={this.getTooltipTitle()}
                alwaysShowInfoIcon={true}
                className="control-text-input"
              />
            </section>
            {this.isCustomErrorPage() ? (
              <section
                key="advanced-setting-info-text"
                className="advanced-setting-info-text"
              >
                <RichText>
                  {translate('Pages_Info_Custom404_Description_Text')}
                  <a onClick={this.openCustomErrorPageHelp}>
                    {translate('Pages_Info_Custom404_Description_Link')}
                  </a>
                </RichText>
              </section>
            ) : null}

            {!this.isCustomErrorPage() ? (
              <>
                {!isCustomMenusEnabled() && this.isDynamicPage() && (
                  <section className="section-hide-page">
                    <ToggleSwitch
                      value={this.linkPageProperty('hidePage').value}
                      label="Pages_Info_Hide_Toggle"
                      onChange={this.linkPageProperty('hidePage').requestChange}
                      className="hide-page-toggle-text"
                    />
                  </section>
                )}
                {isHome && (
                  <section
                    key="page-settings-tab-page-home"
                    className="page-settings-control-description"
                  >
                    <span className="label-with-icon">
                      <symbols.symbol name="settingsHome" />
                      {translate('Pages_Info_Home_IsSelected')}
                    </span>

                    <baseUI.infoIcon
                      uniqueId="changeHomePageTooltip"
                      title="Pages_Info_Home_Popup_Title"
                      text="Pages_Info_Home_Popup_Text"
                      className="page-settings-change-homepage-tooltip"
                    />
                  </section>
                )}
                {!isHome && this.isDynamicPage() && (
                  <section
                    key="page-settings-tab-page-non-home"
                    className="page-settings-control-description"
                  >
                    <span className="home-page-span-text">
                      {translate('Pages_Info_Home_NotSelected')}
                    </span>
                    <baseUI.button
                      icon="settingsHome"
                      label="Pages_Info_Home_Set"
                      onClick={this.setAsHomepage}
                      className="home-page-button-set"
                    />
                  </section>
                )}
              </>
            ) : null}

            {this.shouldRenderAdvancedSettings() ? (
              <div
                key="ssrCacheAccordionToggle"
                className="pages-info-advanced-settings-info"
              >
                <div>
                  <section className="pages-info-advanced-settings-info">
                    <baseUI.accordion
                      label="Pages_Info_Advanced_Settings"
                      useNewSymbol={true}
                      onToggle={this.onAccordionToggle}
                      openedOnInit={this.state.advancedSettingsDropDown}
                      forceOpenAccordion={this.state.advancedSettingsDropDown}
                      className="pages-info-advanced-settings-accordion"
                    />
                  </section>

                  {this.state.advancedSettingsDropDown ? (
                    <section
                      key="advanced-setting-info-text"
                      className="advanced-setting-info-text"
                    >
                      <RichText>
                        {translate('Pages_Info_SSR_Cache_Info_Text')}
                        <a onClick={this.openCacheRenderingHelp}>
                          {translate('Pages_Info_SSR_Cache_Info_Link')}
                        </a>
                      </RichText>
                    </section>
                  ) : null}
                </div>

                {this.shouldRenderToggleSwitchForSSR() ? (
                  <div
                    key="advanced-settings-ssr-accordion"
                    className="pages-info-advanced-settings-switch"
                  >
                    <ToggleSwitch
                      value={this.state.ssrToggle}
                      label="Pages_Info_SSR_Cache_Toggle_Label"
                      onChange={this.onSSRToggleChange}
                      className="pages-info-ssr-cache-switch"
                    />
                  </div>
                ) : null}

                {this.shouldRenderToggleSwitchForSSR() ? (
                  <Divider
                    long={!this.state.ssrToggle}
                    key="advanced-setting-warning-divider-1"
                  />
                ) : null}

                {this.state.ssrToggle && this.state.advancedSettingsDropDown ? (
                  <div key="ssr-cache-warning" className="ssr-cache-warning">
                    <div className="ssr-cache-warning-section">
                      <div className="ssr-cache-warning-icon">
                        <symbols.symbol name="symbol-warning" />
                      </div>
                      <div className="ssr-cache-warning-text">
                        <RichText>
                          {translate(
                            'Pages_Info_SSR_Cache_Toggle_Warning_Text',
                          )}
                          <a onClick={this.openCacheRenderingHelp}>
                            {translate(
                              'Pages_Info_SSR_Cache_Toggle_Warning_Link',
                            )}
                          </a>
                        </RichText>
                      </div>
                    </div>
                  </div>
                ) : null}

                {this.state.advancedSettingsDropDown && this.state.ssrToggle ? (
                  <Divider
                    long={!this.state.ssrToggle}
                    key="advanced-setting-warning-divider-2"
                  />
                ) : null}

                {this.state.ssrToggle && this.state.advancedSettingsDropDown ? (
                  <div
                    key="advanced-settings-ssr-toggle"
                    className="ssr-cache-drop-down-container"
                  >
                    <div className="ssr-cache-drop-down">
                      <Composites.DropDownLabeled>
                        <TextLabel value="Pages_Info_SSR_Cache_Frequency_Dropdown_Label" />
                        <DropDown
                          value={this.state.cacheMaxAgeHours}
                          onChange={this.onSSRDurationChange}
                          shouldTranslate={false}
                          onToggle={this.handleDropDownToggle}
                        >
                          {this.getOptions().map((option: AnyFixMe) => (
                            <DropDownOption
                              key={option.key}
                              value={option.value}
                              label={translate(option.key)}
                              shouldTranslate={false}
                            />
                          ))}
                        </DropDown>
                      </Composites.DropDownLabeled>
                    </div>
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>
        </CustomScroll>
      );
    },
    propTypes: {
      menuItem: PropTypes.object.isRequired,
    },
    isSSRCacheEnabled(cacheMaxAgeHours: number) {
      return (
        cacheMaxAgeHours === SSRCacheDuration.Maximum || cacheMaxAgeHours > 0
      );
    },
    isAdvancedSettingsDropdownOpen(pageData: {
      cacheMaxAgeHours: number;
      id: string;
    }) {
      return (
        this.isSSRCacheEnabled(pageData.cacheMaxAgeHours) ||
        getSitePrefsAdvancedSetting(this.getEditorAPI(), pageData.id)
      );
    },
    getInitialState() {
      const editorAPI = this.getEditorAPI();
      const { pageData } = this.props.menuItem;

      return {
        ssrToggle: getSiteToggleState(editorAPI, pageData.id),
        advancedSettingsDropDown: this.isAdvancedSettingsDropdownOpen(pageData),
        homePageId: this.getHomepageId(),
        scrollingPage: false,
        cacheMaxAgeHours: getSiteCacheHours(editorAPI, pageData.id),
      };
    },
    getHomepageId() {
      const editorAPI = this.getEditorAPI();
      return editorAPI.homePage.get();
    },
    UNSAFE_componentWillReceiveProps(newProps) {
      const editorAPI = this.getEditorAPI();
      // @ts-expect-error
      const pageData = newProps.pageData || newProps.menuItem.pageData;
      this.setState({
        ssrToggle: getSiteToggleState(editorAPI, pageData.id),
        advancedSettingsDropDown: this.isAdvancedSettingsDropdownOpen(pageData),
        cacheMaxAgeHours: getSiteCacheHours(editorAPI, pageData.id),
      });
    },
    setAsHomepage() {
      const editorAPI = this.getEditorAPI();
      if (experiment.isOpen('specs.promote-seo.enableStaticPageHierarchy')) {
        editorAPI.homePage.set(this.getPageId(), () => {
          this.setState({
            homePageId: this.getPageId(),
          });
        });
      } else {
        editorAPI.homePage.set(this.getPageId());
        this.setState({
          homePageId: this.getPageId(),
        });
      }

      editorAPI.bi.event(
        coreBi.events.topbar.pages
          .top_bar_PAGES_settings_page_info_home_page_change_set_click,
      );
    },
    isDynamicPage() {
      const editorAPI = this.getEditorAPI();
      const pageId = this.getPageId();
      return !editorAPI.pages.isDynamicPage(pageId);
    },
    onChange(newValue: AnyFixMe) {
      const { pageData } = this.props.menuItem;
      this.getEditorAPI().menus.renamePage(
        pageData.id,
        pageData.title,
        newValue,
      );
    },
    isHomePage() {
      return this.getPageId() === this.state.homePageId;
    },
    openCustomErrorPageHelp() {
      const helpItem = settingsPanelConstants.CUSTOM_ERROR_PAGE_HELP_ID;
      this.getEditorAPI().panelManager.openHelpCenter(helpItem, null, {});
    },
    isCustomErrorPage() {
      const editorAPI = this.getEditorAPI();
      return editorAPI.pages.isCustomErrorPage(this.props.menuItem.pageData.id);
    },
    onAccordionToggle() {
      const editorAPI = this.getEditorAPI();
      if (!this.state.advancedSettingsDropDown) {
        editorAPI.bi.event(
          coreBi.events.ssrCache.click_advanced_page_settings_page_panel,
        );
      }
      this.setState({
        advancedSettingsDropDown: !this.state.advancedSettingsDropDown,
      });
      setSitePrefsAdvancedSetting(
        editorAPI,
        this.props.menuItem.pageData.id,
        !this.state.advancedSettingsDropDown,
      );
    },
    shouldRenderAdvancedSettings() {
      if (this.isCustomErrorPage()) {
        return false;
      }

      const editorAPI = this.getEditorAPI();
      const pageData = editorAPI.pages.data.get(
        this.props.menuItem.pageData.id,
      );
      const shouldShowForBlog = core.utils.blogTypeUtils.isBlogPage(
        editorAPI,
        pageData,
      );
      const shouldShowForStore = core.utils.wixStoreTypeUtils.isWixStore(
        editorAPI,
        pageData,
      );

      if (shouldShowForBlog || shouldShowForStore) {
        return true;
      }

      return !core.utils.tpaTypeUtils.isTPA(pageData);
    },
    shouldRenderToggleSwitchForSSR() {
      return this.state.advancedSettingsDropDown;
    },
    onSSRToggleChange(newValue: AnyFixMe) {
      const editorAPI = this.getEditorAPI();

      const newCacheHours = newValue
        ? getSiteCacheHours(editorAPI, this.props.menuItem.pageData.id) ||
          DEFAULT_SSR_CACHE
        : null;
      const cacheMode = newValue ? 'manual' : 'auto';

      editorAPI.pages.data.update(this.props.menuItem.pageData.id, {
        cacheMaxAgeHours: newCacheHours,
        cacheMode,
      });

      editorAPI.history.add('SSRToggleChange');

      //eslint-disable-next-line @wix/santa/no-falsy-experiment
      if (!experiment.isOpen('se_siteCacheHoursInDocument')) {
        setSitePrefsCacheHours(
          editorAPI,
          this.props.menuItem.pageData.id,
          newCacheHours,
        );

        setSitePrefsToggleState(
          editorAPI,
          this.props.menuItem.pageData.id,
          newValue,
        );
      }

      this.setState({
        ssrToggle: newValue,
        cacheMaxAgeHours: newCacheHours,
      });

      editorAPI.bi.event(
        coreBi.events.ssrCache.enable_ssr_cache_for_a_page_toggle,
        {
          toggle: newValue,
          pageId: this.props.menuItem.pageData.id,
        },
      );
    },
    getOptions() {
      return ssrCacheOptions;
    },
    onClickDropDown() {
      this.props.sendDropDownOpenEvent();
      this.setState({ scrollingPage: true });
    },
    onSSRDurationChange(newValue: AnyFixMe) {
      const editorAPI = this.getEditorAPI();
      const cachingPageUpdate: CachingPageUpdate = {
        cacheMaxAgeHours: newValue,
      };
      if (newValue === SSRCacheDuration.Disable) {
        cachingPageUpdate.cacheMode = 'disabled';
      }
      setSitePrefsCacheHours(
        editorAPI,
        this.props.menuItem.pageData.id,
        newValue,
      );
      editorAPI.pages.data.update(
        this.props.menuItem.pageData.id,
        cachingPageUpdate,
      );
      editorAPI.history.add('SSRDurationChange');
      this.setState({
        cacheMaxAgeHours: newValue,
      });
      editorAPI.bi.event(coreBi.events.ssrCache.chose_timeframe_in_dropdown, {
        selection: newValue,
      });
    },
    openCacheRenderingHelp() {
      const helpItem = 'a218bf29-0948-4a27-ac1d-6bf682d164fe';
      this.getEditorAPI().panelManager.openHelpCenter(helpItem, null, {});
    },
    getLabel() {
      const editorAPI = this.getEditorAPI();
      return editorAPI.pages.isCustomErrorPage(this.props.menuItem.pageData.id)
        ? 'Pages_Info_Custom404_Page_Title'
        : 'Pages_Info_Name_Title';
    },

    getTooltipTitle() {
      const editorAPI = this.getEditorAPI();
      return editorAPI.pages.isCustomErrorPage(this.props.menuItem.pageData.id)
        ? 'Pages_Info_Custom404_Page_Title_Tooltip'
        : 'Pages_Info_Name_Tooltip';
    },
    handleDropDownToggle(isOpen: boolean) {
      return isOpen && this.onClickDropDown(isOpen);
    },
  });

const TabPageInfoMainMenuNew = util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  null,
  mapDispatchToProps,
)(TabPageInfoMainMenuNewComponent);

TabPageInfoMainMenuNew.pure = TabPageInfoMainMenuNewComponent;

export default TabPageInfoMainMenuNew;
