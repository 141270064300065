// @ts-nocheck
import * as wixBookings from '@/wixBookings';
import getHealthDeclarationSection from '../contact/wixForms/sections/healthDeclarationSection';
import type { EditorAPI } from '@/editorAPI';

function getBookingsHealthWaiversSection(editorAPI: EditorAPI) {
  const healthWaiversSection =
    getHealthDeclarationSection(editorAPI).healthDeclarationSection;
  healthWaiversSection.title = 'add_section_label_bookings_health_waiver_input';
  healthWaiversSection.topTitle =
    'add_section_label_bookings_health_waiver_input';
  healthWaiversSection.tooltipTitle =
    'add_section_label_bookings_health_waiver_input';
  healthWaiversSection.subNavigationTitle =
    'add_section_label_bookings_health_waiver_input';
  healthWaiversSection.presetTitle =
    'add_section_label_bookings_health_waiver_input';
  return healthWaiversSection;
}

export default {
  getCategorySections(editorAPI: EditorAPI) {
    return {
      serviceWidget: wixBookings.singleServiceWidget({ editorAPI }),
      offeringListWidget: wixBookings.offeringListWidget({ editorAPI }),
      bookButtonSection: wixBookings.bookButtonWidget({ editorAPI }),
      timetableSection: wixBookings.timetableWidget({ editorAPI }),
      weeklyTimetableWidget: wixBookings.weeklyTimetableWidget({ editorAPI }),
      dailyAgendaWidget: wixBookings.dailyAgendaWidget({ editorAPI }),
      calendarWidget: wixBookings.calendarWidget({ editorAPI }),
      staffListSection: wixBookings.staffListWidget({ editorAPI }),
      healthWaiversSection: getBookingsHealthWaiversSection(editorAPI),
    };
  },
};
