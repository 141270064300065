const AUTOMATION_IDS = {
  RADIO_BUTTONS: 'layout-tab-radio-buttons',
  NOTE_CONTAINER: 'layout-tab-note-container',
  LEARN_MORE_BTN: 'layout-tab-learn-more',
};

enum Layouts {
  StandartPage = 'StandartPage',
  NoHeaderFooter = 'NoHeaderFooter',
}

export { AUTOMATION_IDS, Layouts };
