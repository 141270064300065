// @ts-nocheck
import _ from 'lodash';
import * as util from '@/util';
import * as appMarketUtils from '../utils/appMarketUtils';

const APP_MARKET_PANEL = 'tpa.compPanels.appMarketPanel';

const openAppMarketTabFromUrlParamIfNeeded = function (openPanel) {
  const shouldOpenAppMarketTab = appMarketUtils.shouldOpenAppMarketTab();
  if (shouldOpenAppMarketTab) {
    openAppMarketTab(
      {
        urlParams: {
          openMarketOrigin: util.url.getParameterByName('referral_info'),
        },
      },
      openPanel,
    );
  }
};

const openAppMarketTab = function (props, openPanel) {
  props = props || {};
  props.urlParams = props.urlParams || {};
  const openPanelUrlParams = appMarketUtils.getAppMarketOpenPanelUrlParams();
  _.merge(props.urlParams || {}, openPanelUrlParams);

  openPanel(APP_MARKET_PANEL, props);
};

const closeAppMarketTab = function (closePanelByName) {
  closePanelByName(APP_MARKET_PANEL);
};

export {
  openAppMarketTab,
  closeAppMarketTab,
  openAppMarketTabFromUrlParamIfNeeded,
};
