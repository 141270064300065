import constants from '@/constants';
import * as helpIds from '@/helpIds';
import type { EditorAPI } from '@/editorAPI';
import type { CompRef } from 'types/documentServices';
import type {
  GFPPActionRaw,
  GFPPActionType,
} from '@wix/editor-component-types';
import { isCustomOrAdvancedMenuEnabled } from '@/util';
import gfppDataUtils from '../../utils/gfppDataUtils';
import {
  manageMenuGfppAutomationId,
  openManageMenu,
} from '../../utils/menuUtils';
import { EditorParamsApiKey } from '@/apis';

const { ACTIONS } = constants.ROOT_COMPS.GFPP;

function isTextDefined(editorAPI: EditorAPI, compRef: CompRef[]) {
  const compData = editorAPI.components.data.get(compRef);
  return compData && compData.loggedInMember !== 'avatarOnly';
}

const manageMenuAction: GFPPActionRaw = {
  label: 'gfpp_secondaryaction_loginSocialBar',
  onClick: (editorAPI: EditorAPI, compRef: CompRef[]) => {
    const compData = editorAPI.components.data.get(compRef);

    return openManageMenu(editorAPI, {
      menuId: compData.menuItemsRef.menuRef,
    });
  },
  automationId: manageMenuGfppAutomationId,
};

export default {
  untranslatable: true,
  mainActions: (editorAPI: EditorAPI) => {
    const actions: GFPPActionRaw[] = [
      {
        label: 'gfpp_mainaction_loginSocialBar',
        isSelected: gfppDataUtils.getPanelStateFn(ACTIONS.SETTINGS),
        onClick: gfppDataUtils.getTogglePanelFn(ACTIONS.SETTINGS),
      },
    ];

    const { isInsideEditorX } = editorAPI.host.getAPI(EditorParamsApiKey);

    if (isInsideEditorX || isCustomOrAdvancedMenuEnabled()) {
      actions.push(manageMenuAction);
    }

    return actions;
  },
  enabledActions: [
    ACTIONS.LAYOUT,
    ACTIONS.DESIGN,
    ACTIONS.ANIMATION,
    ACTIONS.HELP,
  ],
  mobileMainActions: [],
  mobileEnabledActions(editorAPI: EditorAPI, compRef: CompRef[]) {
    const doesComponentHaveText = isTextDefined(editorAPI, compRef);
    const mobileActions: GFPPActionType[] = [
      ACTIONS.LAYOUT,
      ACTIONS.HIDE,
      ACTIONS.HELP,
      ACTIONS.ANIMATION,
    ];
    if (doesComponentHaveText) {
      mobileActions.unshift(ACTIONS.SCALE_UP, ACTIONS.SCALE_DOWN);
    }
    return mobileActions;
  },
  presetActions: {
    help: {
      helpId: helpIds.GFPP.LOGIN_SOCIAL_BAR,
      mobileHelpId: helpIds.GFPP.LOGIN_SOCIAL_BAR_MOBILE,
    },
  },
};
