'use strict'

module.exports = [
    {'skin': 'wysiwyg.viewer.skins.mobile.TinyMenuFullScreenSkin', 'style': {'skin': 'wysiwyg.viewer.skins.mobile.TinyMenuFullScreenSkin', 'style': {'properties': {'textAlignment': 'center', 'alpha-borderColorSelected': '0', 'alpha-bordercolor': '0', 'bgOpen': 'transparent', 'borderColorSelected': 'color_13', 'borderWidthSelected': '0px', 'bordercolor': 'color_13', 'borderwidth': '1px', 'fnt': 'normal normal normal 18px/1.4em din-next-w01-light', 'rd': '0px', 'separatorHeight': '0', 'separatorWidthFull': '140', 'strokewidth': '2', 'txts': 'color_13'}}}},
    {'skin': 'wysiwyg.viewer.skins.mobile.TinyMenuFullScreenSkin', 'style': {'skin': 'wysiwyg.viewer.skins.mobile.TinyMenuFullScreenSkin', 'style': {'properties': {'textAlignment': 'center', 'alpha-bordercolor': '0', 'bgDrop': 'color_11', 'bgOpen': 'color_15', 'borderColorSelected': 'color_13', 'borderWidthSelected': '0px', 'bordercolor': 'color_13', 'borderwidth': '0', 'fnt': 'normal normal normal 18px/1.4em din-next-w01-light', 'linewidth': '18', 'rd': '25px', 'separatorWidthFull': '320', 'strokewidth': '2', 'txt': 'color_13', 'txts': 'color_14'}}}},
    {'skin': 'wysiwyg.viewer.skins.mobile.TinyMenuFullScreenSkin', 'style': {'skin': 'wysiwyg.viewer.skins.mobile.TinyMenuFullScreenSkin', 'style': {'properties': {'textAlignment': 'center', 'bg': 'color_11', 'bgDrop': 'color_11', 'bgOpen': 'color_11', 'borderColorSelected': '#000000', 'borderWidthSelected': '1', 'bordercolor': 'color_15', 'borderwidth': '1', 'fnt': 'normal normal normal 18px/1.4em din-next-w01-light', 'iconcolor': 'color_15', 'iconcolorSelected': '#000000', 'rd': '25px', 'separatorWidthFull': '0', 'strokewidth': '2', 'txt': '#000000', 'txts': 'color_13'}}}},
    {'skin': 'wysiwyg.viewer.skins.mobile.TinyMenuPullFromLeftSkin', 'style': {'skin': 'wysiwyg.viewer.skins.mobile.TinyMenuPullFromLeftSkin', 'style': {'properties': {'textAlignment': 'left', 'alpha-bg': '0', 'alpha-bgOpen': '0', 'alpha-borderColorSelected': '0', 'alpha-bordercolor': '0', 'alpha-separatorColor': '0.5', 'bg': 'color_11', 'bgOpen': 'color_11', 'borderColorSelected': 'color_12', 'borderWidthSelected': '2', 'bordercolor': 'color_13', 'borderwidth': '1px', 'fnt': 'normal normal normal 18px/1.4em din-next-w01-light', 'iconcolor': 'color_15', 'iconcolorSelected': '#000000', 'rd': '0px', 'separatorColor': 'color_12', 'separatorWidth': '115', 'strokewidth': '2', 'txts': 'color_12'}}}},

    {'skin': 'wysiwyg.viewer.skins.mobile.TinyMenuFullScreenSkin', 'style': {'skin': 'wysiwyg.viewer.skins.mobile.TinyMenuFullScreenSkin', 'style': {'properties': {'textAlignment': 'center', 'alpha-borderColorSelected': '0', 'alpha-bordercolor': '0', 'alpha-separatorColor': '0', 'bg': '#3D9BE8', 'bgDrop': '#3D9BE8', 'bgOpen': 'transparent', 'borderColorSelected': 'color_13', 'borderWidthSelected': '0px', 'bordercolor': 'color_13', 'borderwidth': '1px', 'fnt': 'normal normal normal 18px/1.4em din-next-w01-light', 'rd': '0px', 'separatorColor': 'color_16', 'separatorWidthFull': '130', 'strokewidth': '2', 'txts': 'color_20'}}}},
    {'skin': 'wysiwyg.viewer.skins.mobile.TinyMenuFullScreenSkin', 'style': {'skin': 'wysiwyg.viewer.skins.mobile.TinyMenuFullScreenSkin', 'style': {'properties': {'textAlignment': 'center', 'alpha-borderColorSelected': '0.92', 'alpha-bordercolor': '0', 'bg': '#3D9BE8', 'bgDrop': 'color_11', 'bgOpen': '#3D9BE8', 'borderColorSelected': '#000000', 'borderWidthSelected': '0px', 'bordercolor': 'color_13', 'borderwidth': '0', 'fnt': 'normal normal normal 18px/1.4em din-next-w01-light', 'linewidth': '18', 'rd': '25px', 'separatorWidthFull': '320', 'strokewidth': '2', 'txt': '#000000', 'txts': '#3D9BE8'}}}},
    {'skin': 'wysiwyg.viewer.skins.mobile.TinyMenuFullScreenSkin', 'style': {'skin': 'wysiwyg.viewer.skins.mobile.TinyMenuFullScreenSkin', 'style': {'properties': {'textAlignment': 'center', 'bg': 'color_11', 'bgDrop': 'color_11', 'bgOpen': 'color_11', 'borderColorSelected': '#002D5C', 'borderWidthSelected': '2', 'bordercolor': '#002D5C', 'borderwidth': '2', 'fnt': 'normal normal normal 18px/1.4em din-next-w01-light', 'iconcolor': '#002D5C', 'iconcolorSelected': '#002D5C', 'linewidth': '18', 'rd': '25px', 'separatorWidthFull': '200px', 'strokewidth': '2', 'txt': '#002D5C', 'txts': 'color_18'}}}},
    {'skin': 'wysiwyg.viewer.skins.mobile.TinyMenuPullFromLeftSkin', 'style': {'skin': 'wysiwyg.viewer.skins.mobile.TinyMenuPullFromLeftSkin', 'style': {'properties': {'textAlignment': 'left', 'alpha-bg': '0', 'alpha-bgOpen': '0', 'alpha-bordercolor': '0', 'bg': 'color_11', 'bgDrop': '#002D5C', 'bgOpen': 'color_11', 'borderColorSelected': 'color_13', 'borderWidthSelected': '0', 'bordercolor': 'color_13', 'borderwidth': '1px', 'fnt': 'font_1', 'iconcolor': '#002D5C', 'iconcolorSelected': '#002D5C', 'separatorHeight': '0', 'separatorWidth': '140px', 'strokewidth': '2', 'txts': 'color_18'}}}},

    {'skin': 'wysiwyg.viewer.skins.mobile.TinyMenuFullScreenSkin', 'style': {'skin': 'wysiwyg.viewer.skins.mobile.TinyMenuFullScreenSkin', 'style': {'properties': {'textAlignment': 'center', 'alpha-borderColorSelected': '0', 'alpha-bordercolor': '0', 'alpha-separatorColor': '0', 'bg': '#ED1566', 'bgDrop': '#ED1566', 'bgOpen': 'transparent', 'borderColorSelected': 'color_12', 'borderWidthSelected': '0px', 'bordercolor': 'color_13', 'borderwidth': '1px', 'fnt': 'normal normal normal 18px/1.4em din-next-w01-light', 'rd': '0px', 'separatorColor': 'color_16', 'separatorWidthFull': '130', 'strokewidth': '2', 'txts': 'color_12'}}}},
    {'skin': 'wysiwyg.viewer.skins.mobile.TinyMenuFullScreenSkin', 'style': {'skin': 'wysiwyg.viewer.skins.mobile.TinyMenuFullScreenSkin', 'style': {'properties': {'textAlignment': 'center', 'alpha-borderColorSelected': '0', 'alpha-bordercolor': '0', 'bg': '#ED1566', 'bgDrop': 'color_11', 'bgOpen': '#ED1566', 'borderColorSelected': 'color_13', 'borderWidthSelected': '0', 'bordercolor': 'color_13', 'borderwidth': '0', 'fnt': 'normal normal normal 18px/1.4em din-next-w01-light', 'rd': '25px', 'separatorWidthFull': '200px', 'strokewidth': '3', 'txt': 'color_15', 'txts': 'color_13'}}}},
    {'skin': 'wysiwyg.viewer.skins.mobile.TinyMenuFullScreenSkin', 'style': {'skin': 'wysiwyg.viewer.skins.mobile.TinyMenuFullScreenSkin', 'style': {'properties': {'textAlignment': 'center', 'bg': 'color_11', 'bgDrop': 'color_11', 'bgOpen': 'color_11', 'borderColorSelected': '#ED1566', 'borderWidthSelected': '2', 'bordercolor': '#ED1566', 'borderwidth': '2', 'fnt': 'normal normal normal 18px/1.4em din-next-w01-light', 'iconcolor': '#ED1566', 'iconcolorSelected': '#ED1566', 'linewidth': '18', 'rd': '25px', 'separatorWidthFull': '200px', 'strokewidth': '3', 'txt': '#000000', 'txts': '#ED1566'}}}},
    {'skin': 'wysiwyg.viewer.skins.mobile.TinyMenuPullFromLeftSkin', 'style': {'skin': 'wysiwyg.viewer.skins.mobile.TinyMenuPullFromLeftSkin', 'style': {'properties': {'textAlignment': 'left', 'alpha-bg': '0', 'alpha-bgOpen': '0', 'alpha-borderColorSelected': '0', 'alpha-bordercolor': '0', 'alpha-separatorColor': '0', 'bg': 'color_11', 'bgDrop': '#D71143', 'bgOpen': 'color_11', 'borderColorSelected': 'color_13', 'borderWidthSelected': '0', 'bordercolor': 'color_13', 'borderwidth': '1px', 'fnt': 'normal normal normal 18px/1.4em din-next-w01-light', 'iconcolor': '#D71143', 'iconcolorSelected': '#D71143', 'rd': '0px', 'separatorWidth': '140px', 'strokewidth': '3', 'txt': '#FFFFFF', 'txts': 'color_15'}}}}
]
