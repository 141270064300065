import type { PromoteScope } from '../../../../scope';

const getBiBaseParams = (scope: PromoteScope) => {
  const packageId = scope.store.getPackageId();
  const isPublished = scope.editorAPI.dsRead.generalInfo.isSitePublished();
  const isPremium = scope.store.getPackageGroup() !== 'Free';

  const msid = scope.editorAPI.dsRead.generalInfo.getMetaSiteId();

  return {
    msid,
    ascend_package: packageId,
    product_name: packageId,
    is_publish: isPublished,
    is_premium: isPremium,
  };
};

const getBiEmailMarketingBaseParams = (scope: PromoteScope) => {
  const packageId = scope.store.getPackageId();
  const packageGroup = scope.store.getPackageGroup();

  const msid = scope.editorAPI.dsRead.generalInfo.getMetaSiteId();

  return {
    msid,
    context: 'editor',
    package_group: packageGroup,
    package_id: packageId,
  };
};

export default {
  getBiBaseParams,
  getBiEmailMarketingBaseParams,
};
