'use strict'

const addPanelDataConsts = require('@/addPanelDataConsts')
const experiment = require("experiment")

module.exports = {
    "type": addPanelDataConsts.SECTIONS_TYPES.PRESET,
    "hide": false,
    "title": "add_section_label_language_menu",
    "subNavigationTitle": "add_section_label_language_menu",
    "topTitle": "add_section_label_language_menu",
    "presetTitle": "add_section_label_language_menu",
    "tooltipTitle": "add_section_label_language_menu",
    "automationId": "add-panel-section-languageSelectorSection",
    "subNavigationHide": false,
    "showSectionHeader": true,
    "additionalBehaviours": {
        "labelMode": addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
        "infoIcon": addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
        "hoverImageAction": addPanelDataConsts.HOVER_IMAGE_ACTIONS.IMAGE,
        "iconEnabledForComps": {}
    },
    "props": {
        'image': 'addPanelData/sections/languageMenusSection_en/languageMenusSection_en.v3.png',
        "imageHover": null,
        "items": [{
            "id": "Button_Text_Buttons_10",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.LanguageSelectorSkin",
                "layout": {
                    "width": 168,
                    "height": 45,
                    "x": experiment.isOpen("se_multilingualModeV2") ? 5 : 280,
                    "y": 30,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.LanguageSelector",
                "props": {
                    "type": "LanguageSelectorProperties",
                    "metaData": {
                        "schemaVersion": "1.0",
                        "autoGenerated": false
                    },
                    "displayMode": "dropdown",
                    "iconType": "none",
                    "iconSize": 22,
                    "itemFormat": "shortName",
                    "alignTabs": "left",
                    "hasArrow": true,
                    "languagesOrder": []
                },
                "style": {
                    "type": "TopLevelStyle",
                    "styleType": "custom",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "style": {
                        "properties": {
                            "alpha-itemTextColor": "1",
                            "alpha-itemTextColorHover": "1",
                            "backgroundColor": "color_11",
                            "backgroundColorActive": "color_12",
                            "backgroundColorHover": "color_11",
                            "borderColor": "rgba(149,149,149,1)",
                            "borderColorActive": "rgba(32, 32, 32, 1)",
                            "borderColorHover": "rgba(32, 32, 32, 1)",
                            "borderRadius": "0px 0px 0px 0px",
                            "borderSides": "none",
                            "borderWidth": "1",
                            "boxShadow": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                            "boxShadowToggleOn-boxShadow": "false",
                            "itemFont": "font_8",
                            "itemSpacing": "5px",
                            "itemTextColor": "color_15",
                            "itemTextColorActive": "color_15",
                            "itemTextColorHover": "color_14",
                            "separatorColor": "color_11"
                        },
                        "propertiesSource": {
                            "alpha-itemTextColor": "value",
                            "alpha-itemTextColorHover": "value",
                            "backgroundColor": "theme",
                            "backgroundColorActive": "theme",
                            "backgroundColorHover": "theme",
                            "borderColor": "value",
                            "borderColorActive": "value",
                            "borderColorHover": "value",
                            "borderRadius": "value",
                            "borderSides": "value",
                            "borderWidth": "value",
                            "boxShadow": "value",
                            "boxShadowToggleOn-boxShadow": "value",
                            "itemFont": "theme",
                            "itemSpacing": "value",
                            "itemTextColor": "theme",
                            "itemTextColorActive": "theme",
                            "itemTextColorHover": "theme",
                            "separatorColor": "theme"
                        },
                        "groups": {}
                    },
                    "componentClassName": "wysiwyg.viewer.components.LanguageSelector",
                    "pageId": "",
                    "compId": "",
                    "skin": "wysiwyg.viewer.skins.LanguageSelectorSkin"
                },
                "connections": {
                    "type": "ConnectionList",
                    "items": [{
                        "type": "WixCodeConnectionItem",
                        "role": "languageSelector17"
                    }]
                },
                "activeModes": {}
            },
            "preset": {"rect": {"width": 0, "height": 0, "x": 0, "y": 0}, "tags": null}
        }, {
            "id": "Button_Text_Buttons_1",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.LanguageSelectorSkin",
                "layout": {
                    "width": 168,
                    "height": 45,
                    "x": 10,
                    "y": 20,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.LanguageSelector",
                "props": {
                    "type": "LanguageSelectorProperties",
                    "metaData": {
                        "schemaVersion": "1.0",
                        "autoGenerated": false
                    },
                    "displayMode": "dropdown",
                    "iconSize": 22,
                    "iconType": "none",
                    "itemFormat": "shortName",
                    "alignTabs": "left",
                    "hasArrow": true,
                    "languagesOrder": []
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "style": {
                        "properties": {
                            "alpha-itemTextColor": "1",
                            "alpha-itemTextColorHover": "1",
                            "backgroundColor": "#FFFFFF",
                            "backgroundColorActive": "#F3F3F3",
                            "backgroundColorHover": "#FFFFFF",
                            "borderColor": "rgba(149,149,149,1)",
                            "borderColorActive": "rgba(32, 32, 32, 1)",
                            "borderColorHover": "rgba(32, 32, 32, 1)",
                            "borderRadius": "0px 0px 0px 0px",
                            "borderSides": "none",
                            "borderWidth": "1",
                            "boxShadow": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                            "boxShadowToggleOn-boxShadow": "false",
                            "itemFont": "normal normal normal 16px/1.4em avenir-lt-w01_35-light1475496",
                            "itemSpacing": "5px",
                            "itemTextColor": "#000000",
                            "itemTextColorActive": "#000000",
                            "itemTextColorHover": "#959595",
                            "separatorColor": "#FFFFFF"
                        },
                        "propertiesSource": {
                            "alpha-itemTextColor": "value",
                            "alpha-itemTextColorHover": "value",
                            "backgroundColor": "value",
                            "backgroundColorActive": "value",
                            "backgroundColorHover": "value",
                            "borderColor": "value",
                            "borderColorActive": "value",
                            "borderColorHover": "value",
                            "borderRadius": "value",
                            "borderSides": "value",
                            "borderWidth": "value",
                            "boxShadow": "value",
                            "boxShadowToggleOn-boxShadow": "value",
                            "itemFont": "value",
                            "itemSpacing": "value",
                            "itemTextColor": "value",
                            "itemTextColorActive": "value",
                            "itemTextColorHover": "value",
                            "separatorColor": "value"
                        },
                        "groups": {}
                    },
                    "componentClassName": "wysiwyg.viewer.components.LanguageSelector",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.LanguageSelectorSkin"
                },
                "connections": {
                    "type": "ConnectionList",
                    "items": [
                        {
                            "type": "WixCodeConnectionItem",
                            "role": "languageSelector8"
                        }
                    ]
                },
                "activeModes": {},
                "id": "comp-jlcaujag"
            },
            "preset": {"rect": {"width": 188, "height": 70, "x": 0, "y": 0}, "tags": null}
        }, {
            "id": "Button_Text_Buttons_2",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.LanguageSelectorSkin",
                "layout": {
                    "width": 111,
                    "height": 45,
                    "x": 203,
                    "y": 20,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.LanguageSelector",
                "props": {
                    "type": "LanguageSelectorProperties",
                    "metaData": {
                        "schemaVersion": "1.0",
                        "autoGenerated": false
                    },
                    "displayMode": "dropdown",
                    "iconSize": 22,
                    "iconType": "circle",
                    "itemFormat": "iconOnly",
                    "alignTabs": "left",
                    "hasArrow": true,
                    "languagesOrder": []
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "style": {
                        "properties": {
                            "alpha-backgroundColorActive": "0.9",
                            "alpha-backgroundColorHover": "1",
                            "alpha-itemTextColor": "1",
                            "alpha-itemTextColorHover": "1",
                            "alpha-separatorColor": "0.3",
                            "backgroundColor": "#323232",
                            "backgroundColorActive": "#323232",
                            "backgroundColorHover": "#323232",
                            "borderColor": "rgba(122,122,122,1)",
                            "borderColorActive": "rgba(32, 32, 32, 1)",
                            "borderColorHover": "rgba(32, 32, 32, 1)",
                            "borderRadius": "10px 10px 10px 10px",
                            "borderSides": "none",
                            "borderWidth": "0",
                            "boxShadow": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                            "boxShadowToggleOn-boxShadow": "false",
                            "itemFont": "normal normal normal 16px/1.4em avenir-lt-w01_35-light1475496",
                            "itemSpacing": "5px",
                            "itemTextColor": "color_11",
                            "itemTextColorActive": "color_11",
                            "itemTextColorHover": "color_11",
                            "separatorColor": "#FFFFFF"
                        },
                        "propertiesSource": {
                            "alpha-backgroundColorActive": "value",
                            "alpha-backgroundColorHover": "value",
                            "alpha-itemTextColor": "value",
                            "alpha-itemTextColorHover": "value",
                            "alpha-separatorColor": "value",
                            "backgroundColor": "value",
                            "backgroundColorActive": "value",
                            "backgroundColorHover": "value",
                            "borderColor": "value",
                            "borderColorActive": "value",
                            "borderColorHover": "value",
                            "borderRadius": "value",
                            "borderSides": "value",
                            "borderWidth": "value",
                            "boxShadow": "value",
                            "boxShadowToggleOn-boxShadow": "value",
                            "itemFont": "value",
                            "itemSpacing": "value",
                            "itemTextColor": "theme",
                            "itemTextColorActive": "theme",
                            "itemTextColorHover": "theme",
                            "separatorColor": "value"
                        },
                        "groups": {}
                    },
                    "componentClassName": "wysiwyg.viewer.components.LanguageSelector",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.LanguageSelectorSkin"
                },
                "connections": {
                    "type": "ConnectionList",
                    "items": [
                        {
                            "type": "WixCodeConnectionItem",
                            "role": "languageSelector25"
                        }
                    ]
                },
                "activeModes": {},
                "id": "comp-jlcaujaj"
            },
            "preset": {"rect": {"width": 136, "height": 70, "x": 188, "y": 0}, "tags": null}
        }, {
            "id": "Button_Text_Buttons_3",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.LanguageSelectorSkin",
                "layout": {
                    "width": 304,
                    "height": 45,
                    "x": 10,
                    "y": 95,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.LanguageSelector",
                "props": {
                    "type": "LanguageSelectorProperties",
                    "metaData": {
                        "schemaVersion": "1.0",
                        "autoGenerated": false
                    },
                    "displayMode": "dropdown",
                    "iconSize": 20,
                    "iconType": "square",
                    "itemFormat": "fullName",
                    "alignTabs": "left",
                    "hasArrow": true,
                    "languagesOrder": []
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "style": {
                        "properties": {
                            "alpha-itemTextColor": "1",
                            "alpha-itemTextColorHover": "1",
                            "alpha-separatorColor": "1",
                            "backgroundColor": "#F3F3F3",
                            "backgroundColorActive": "#F3F3F3",
                            "backgroundColorHover": "color_11",
                            "borderColor": "rgba(232,232,232,1)",
                            "borderColorActive": "rgba(32, 32, 32, 1)",
                            "borderColorHover": "rgba(32, 32, 32, 1)",
                            "borderRadius": "0px 0px 0px 0px",
                            "borderSides": "none",
                            "borderWidth": "1",
                            "boxShadow": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                            "boxShadowToggleOn-boxShadow": "false",
                            "itemFont": "normal normal 700 14px/1.4em poppins-extralight",
                            "itemSpacing": "5px",
                            "itemTextColor": "#000000",
                            "itemTextColorActive": "#323232",
                            "itemTextColorHover": "#323232",
                            "separatorColor": "#E8E8E8"
                        },
                        "propertiesSource": {
                            "alpha-itemTextColor": "value",
                            "alpha-itemTextColorHover": "value",
                            "alpha-separatorColor": "value",
                            "backgroundColor": "value",
                            "backgroundColorActive": "value",
                            "backgroundColorHover": "theme",
                            "borderColor": "value",
                            "borderColorActive": "value",
                            "borderColorHover": "value",
                            "borderRadius": "value",
                            "borderSides": "value",
                            "borderWidth": "value",
                            "boxShadow": "value",
                            "boxShadowToggleOn-boxShadow": "value",
                            "itemFont": "value",
                            "itemSpacing": "value",
                            "itemTextColor": "value",
                            "itemTextColorActive": "value",
                            "itemTextColorHover": "value",
                            "separatorColor": "value"
                        },
                        "groups": {}
                    },
                    "componentClassName": "wysiwyg.viewer.components.LanguageSelector",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.LanguageSelectorSkin"
                },
                "connections": {
                    "type": "ConnectionList",
                    "items": [
                        {
                            "type": "WixCodeConnectionItem",
                            "role": "languageSelector28"
                        }
                    ]
                },
                "activeModes": {},
                "id": "comp-jlcaujan"
            },
            "preset": {"rect": {"width": 324, "height": 66, "x": 0, "y": 70}, "tags": null}
        }, {
            "id": "Button_Text_Buttons_4",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.LanguageSelectorSkin",
                "layout": {
                    "width": 304,
                    "height": 32,
                    "x": 10,
                    "y": 169,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.LanguageSelector",
                "props": {
                    "type": "LanguageSelectorProperties",
                    "metaData": {
                        "schemaVersion": "1.0",
                        "autoGenerated": false
                    },
                    "displayMode": "horizontal",
                    "iconSize": 20,
                    "iconType": "none",
                    "itemFormat": "fullName",
                    "alignTabs": "center",
                    "hasArrow": true,
                    "languagesOrder": []
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "style": {
                        "properties": {
                            "alpha-backgroundColor": "0",
                            "alpha-backgroundColorActive": "0",
                            "alpha-backgroundColorHover": "0",
                            "alpha-itemTextColor": "1",
                            "alpha-itemTextColorHover": "1",
                            "backgroundColor": "#FFFFFF",
                            "backgroundColorActive": "color_11",
                            "backgroundColorHover": "color_11",
                            "borderColor": "rgba(122,122,122,1)",
                            "borderColorActive": "rgba(32, 32, 32, 1)",
                            "borderColorHover": "rgba(32, 32, 32, 1)",
                            "borderRadius": "0px 0px 0px 0px",
                            "borderSides": "none",
                            "borderWidth": "0",
                            "boxShadow": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                            "boxShadowToggleOn-boxShadow": "false",
                            "itemFont": "normal normal normal 15px/1.4em questrial",
                            "itemSpacing": "5px",
                            "itemTextColor": "#333333",
                            "itemTextColorActive": "#FE6161",
                            "itemTextColorHover": "#959595",
                            "separatorColor": "#E6E0D3"
                        },
                        "propertiesSource": {
                            "alpha-backgroundColor": "value",
                            "alpha-backgroundColorActive": "value",
                            "alpha-backgroundColorHover": "value",
                            "alpha-itemTextColor": "value",
                            "alpha-itemTextColorHover": "value",
                            "backgroundColor": "value",
                            "backgroundColorActive": "theme",
                            "backgroundColorHover": "theme",
                            "borderColor": "value",
                            "borderColorActive": "value",
                            "borderColorHover": "value",
                            "borderRadius": "value",
                            "borderSides": "value",
                            "borderWidth": "value",
                            "boxShadow": "value",
                            "boxShadowToggleOn-boxShadow": "value",
                            "itemFont": "value",
                            "itemSpacing": "value",
                            "itemTextColor": "value",
                            "itemTextColorActive": "value",
                            "itemTextColorHover": "value",
                            "separatorColor": "value"
                        },
                        "groups": {}
                    },
                    "componentClassName": "wysiwyg.viewer.components.LanguageSelector",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.LanguageSelectorSkin"
                },
                "connections": {
                    "type": "ConnectionList",
                    "items": [
                        {
                            "type": "WixCodeConnectionItem",
                            "role": "languageSelector32"
                        }
                    ]
                },
                "activeModes": {},
                "id": "comp-jlcaujar"
            },
            "preset": {"rect": {"width": 324, "height": 50, "x": 0, "y": 136}, "tags": null}
        }, {
            "id": "Button_Text_Buttons_5",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.LanguageSelectorSkin",
                "layout": {
                    "width": 304,
                    "height": 45,
                    "x": 10,
                    "y": 233,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.LanguageSelector",
                "props": {
                    "type": "LanguageSelectorProperties",
                    "metaData": {
                        "schemaVersion": "1.0",
                        "autoGenerated": false
                    },
                    "displayMode": "horizontal",
                    "iconSize": 22,
                    "iconType": "none",
                    "itemFormat": "shortName",
                    "alignTabs": "center",
                    "hasArrow": true,
                    "languagesOrder": []
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "style": {
                        "properties": {
                            "alpha-backgroundColor": "1",
                            "alpha-backgroundColorActive": "1",
                            "alpha-backgroundColorHover": "0.9",
                            "alpha-borderColor": "1",
                            "alpha-itemTextColor": "1",
                            "alpha-itemTextColorHover": "1",
                            "alpha-separatorColor": "0.2",
                            "backgroundColor": "#566FB8",
                            "backgroundColorActive": "color_11",
                            "backgroundColorHover": "#566FB8",
                            "borderColor": "rgba(86,111,184,1)",
                            "borderColorActive": "rgba(32, 32, 32, 1)",
                            "borderColorHover": "rgba(32, 32, 32, 1)",
                            "borderRadius": "0px 0px 0px 0px",
                            "borderSides": "none",
                            "borderWidth": "1",
                            "boxShadow": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                            "boxShadowToggleOn-boxShadow": "false",
                            "itemFont": "normal normal 700 13px/1.4em futura-lt-w01-book",
                            "itemSpacing": "5px",
                            "itemTextColor": "color_11",
                            "itemTextColorActive": "#566FB8",
                            "itemTextColorHover": "color_11"
                        },
                        "propertiesSource": {
                            "alpha-backgroundColor": "value",
                            "alpha-backgroundColorActive": "value",
                            "alpha-backgroundColorHover": "value",
                            "alpha-borderColor": "value",
                            "alpha-itemTextColor": "value",
                            "alpha-itemTextColorHover": "value",
                            "alpha-separatorColor": "value",
                            "backgroundColor": "value",
                            "backgroundColorActive": "theme",
                            "backgroundColorHover": "value",
                            "borderColor": "value",
                            "borderColorActive": "value",
                            "borderColorHover": "value",
                            "borderRadius": "value",
                            "borderSides": "value",
                            "borderWidth": "value",
                            "boxShadow": "value",
                            "boxShadowToggleOn-boxShadow": "value",
                            "itemFont": "value",
                            "itemSpacing": "value",
                            "itemTextColor": "theme",
                            "itemTextColorActive": "value",
                            "itemTextColorHover": "theme"
                        },
                        "groups": {}
                    },
                    "componentClassName": "wysiwyg.viewer.components.LanguageSelector",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.LanguageSelectorSkin"
                },
                "connections": {
                    "type": "ConnectionList",
                    "items": [
                        {
                            "type": "WixCodeConnectionItem",
                            "role": "languageSelector37"
                        }
                    ]
                },
                "activeModes": {},
                "id": "comp-jlcaujat"
            },
            "preset": {"rect": {"width": 324, "height": 66, "x": 0, "y": 186}, "tags": null}
        }, {
            "id": "Button_Text_Buttons_6",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.LanguageSelectorSkin",
                "layout": {
                    "width": 304,
                    "height": 45,
                    "x": 10,
                    "y": 309,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.LanguageSelector",
                "props": {
                    "type": "LanguageSelectorProperties",
                    "metaData": {
                        "schemaVersion": "1.0",
                        "autoGenerated": false
                    },
                    "displayMode": "horizontal",
                    "iconSize": 21,
                    "iconType": "circle",
                    "itemFormat": "shortName",
                    "alignTabs": "center",
                    "hasArrow": true,
                    "languagesOrder": []
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "style": {
                        "properties": {
                            "alpha-backgroundColor": "1",
                            "alpha-backgroundColorActive": "1",
                            "alpha-backgroundColorHover": "1",
                            "alpha-itemTextColor": "1",
                            "alpha-itemTextColorHover": "1",
                            "backgroundColor": "color_11",
                            "backgroundColorActive": "color_11",
                            "backgroundColorHover": "color_11",
                            "borderColor": "rgba(217,217,217,1)",
                            "borderColorActive": "rgba(32, 32, 32, 1)",
                            "borderColorHover": "rgba(32, 32, 32, 1)",
                            "borderRadius": "24px 24px 24px 24px",
                            "borderSides": "none",
                            "borderWidth": "0",
                            "boxShadow": "0.00px 6.00px 12px 0px rgba(0,0,0,0.1)",
                            "boxShadowToggleOn-boxShadow": "true",
                            "itemFont": "normal normal normal 14px/1.4em cormorantgaramond-semibold",
                            "itemSpacing": "5px",
                            "itemTextColor": "#959595",
                            "itemTextColorActive": "#000000",
                            "itemTextColorHover": "#524F46"
                        },
                        "propertiesSource": {
                            "alpha-backgroundColor": "value",
                            "alpha-backgroundColorActive": "value",
                            "alpha-backgroundColorHover": "value",
                            "alpha-itemTextColor": "value",
                            "alpha-itemTextColorHover": "value",
                            "backgroundColor": "theme",
                            "backgroundColorActive": "theme",
                            "backgroundColorHover": "theme",
                            "borderColor": "value",
                            "borderColorActive": "value",
                            "borderColorHover": "value",
                            "borderRadius": "value",
                            "borderSides": "value",
                            "borderWidth": "value",
                            "boxShadow": "value",
                            "boxShadowToggleOn-boxShadow": "value",
                            "itemFont": "value",
                            "itemSpacing": "value",
                            "itemTextColor": "value",
                            "itemTextColorActive": "value",
                            "itemTextColorHover": "value"
                        },
                        "groups": {}
                    },
                    "componentClassName": "wysiwyg.viewer.components.LanguageSelector",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.LanguageSelectorSkin"
                },
                "connections": {
                    "type": "ConnectionList",
                    "items": [
                        {
                            "type": "WixCodeConnectionItem",
                            "role": "languageSelector43"
                        }
                    ]
                },
                "activeModes": {},
                "id": "comp-jlcaujax"
            },
            "preset": {"rect": {"width": 324, "height": 64, "x": 0, "y": 252}, "tags": null}
        }],
        "compTypes": ["wysiwyg.viewer.components.LanguageSelector"]
    },
    "help": {"hide": false, "text": "multilingual_tooltip_content_key"}
}
