// @ts-nocheck
import _ from 'lodash';

const IDENTITY = (id) => id;
const EMPTY_ARRAY = () => []; // eslint-disable-line no-unused-vars

const MEDIA_TYPES = {
  // Media representation components
  Image: true,
  VectorImage: true,
  MusicPlayerData: true,
  VideoPlayer: true,

  WixVideo: true, // Background in hoverbox, slideshow, etc.
  WixAudio: true,
  StylableButton: true, // Button with background

  DocumentLink: true,
};

const MEDIA_IDS_GETTERS = {
  Image: (mediaRef) => [mediaRef.uri],
  VectorImage: (mediaRef) => [mediaRef.svgId],
  StylableButton: (mediaRef) => [mediaRef.svgId],
  MusicPlayerData: (mediaRef) => [mediaRef.coverRef.uri, mediaRef.audioRef.uri],
  VideoPlayer: (mediaRef) => [mediaRef.videoRef?.videoId],
  WixVideo: (mediaRef) => [mediaRef.videoId],
  WixAudio: (mediaRef) => [mediaRef.uri],
  DocumentLink: (mediaRef) => [mediaRef.docId],
};

const SEPARATOR = '/';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line you-dont-need-lodash-underscore/last
const removePrefixes = (id) => _.last(id.split(SEPARATOR));

const traverseObjectTree = (obj, func) => {
  if (Array.isArray(obj)) {
    return obj.forEach((o) => traverseObjectTree(o, func));
  }
  if (!_.isObject(obj)) {
    return;
  }

  const stop = func(obj);
  if (!stop) {
    Object.keys(obj).forEach((key) => traverseObjectTree(obj[key], func));
  }
};

const isMediaTypeRef = (obj) => obj.type && MEDIA_TYPES[obj.type];

const getMedia = (ref) => {
  const getMediaIds = MEDIA_IDS_GETTERS[ref.type] || EMPTY_ARRAY;
  return getMediaIds(ref).filter(IDENTITY).map(removePrefixes);
};

/* Because of tons of components that have different internal structure and any level
   of hierarchy, we have to traverse the whole tree looking for media references.
   Media reference is an object with certain type and corresponding data */
const collectMediaIds = (components) => {
  const mediaIds = [];
  traverseObjectTree(components, (object) => {
    if (!isMediaTypeRef(object)) {
      return false;
    }

    mediaIds.push(...getMedia(object));
    if (object.link) {
      mediaIds.push(...collectMediaIds(object.link));
    }

    return true;
  });

  return _.uniq(mediaIds);
};

export default collectMediaIds;
