import { composeProtoInput } from '@wix/editor-prediction-utils';

export default function getSerializedProtoData(
  data: AnyFixMe,
  aditionalData: AnyFixMe,
): any {
  return {
    values: {
      section_comps_data: composeProtoInput(data),
      ...aditionalData,
    },
  };
}
