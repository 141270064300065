import React from 'react';
import * as baseUI from '@/baseUI';

interface SignUpSettingsCommunitySectionOldProps {
  joinCommunityStatus: boolean;
  onJoinCommunityStatusChange: (joinCommunityStatus: boolean) => void;
}

const SignUpSettingsCommunitySectionOld: React.FC<
  SignUpSettingsCommunitySectionOldProps
> = ({ joinCommunityStatus, onJoinCommunityStatusChange }) => (
  <section
    key="communityCheckedByDefaultSection"
    className="option social-option"
  >
    <baseUI.symbol name="communityIcon" className="social-icon community" />
    <baseUI.toggle
      name="switch"
      label="Member_Login_Settings_Community_Join"
      value={joinCommunityStatus}
      onChange={onJoinCommunityStatusChange}
    />
  </section>
);

export default SignUpSettingsCommunitySectionOld;
