//@ts-nocheck
import _ from 'lodash';
import { PAGINATION_RANGE } from './constants';

import type { Link, PagesData } from 'types/documentServices';
import type { EditorAPI } from '@/editorAPI';
import { EditorPlatformHostIntegrationAPI } from '@wix/editor-platform-host-integration-apis';

const convertAnchorLinkToPageLink = (editorAPI, anchorLink, pages) => {
  if (anchorLink.pageId) {
    const pageId = _.has(anchorLink.pageId, 'id')
      ? anchorLink.pageId.id
      : anchorLink.pageId.replace('#', '');

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/some
    if (_.some(pages, { id: pageId })) {
      return {
        type: 'PageLink',
        pageId: anchorLink.pageId,
      };
    }

    const defaultPage = getPageById(pages[0]?.id, pages);
    const pageLinkDataSchema = editorAPI.data.getSchema('PageLink');

    return {
      type: 'PageLink',
      pageId: defaultPage.id,
      target: pageLinkDataSchema?.target?.default,
    };
  }

  return null;
};

const convertExternalLinkToAddressLink = (externalLink) => {
  return {
    type: 'AddressLink',
    address: externalLink.url,
  };
};

export const getRangeStartForRouteIndex = (newIndex: number) => {
  let newRangeStart = 0;
  while (newRangeStart + PAGINATION_RANGE <= newIndex) {
    newRangeStart += PAGINATION_RANGE;
  }
  return newRangeStart;
};

export const addProtocolToExternalUrl = (url: string, protocol) => {
  if (url && !/^\s*\w+:\/\//.test(url)) {
    return `${protocol}://${url.trim()}`;
  }

  return url;
};

const doesAnchorExist = (link, anchors) => {
  if (
    link.anchorDataId === 'SCROLL_TO_TOP' ||
    link.anchorDataId === 'SCROLL_TO_BOTTOM'
  ) {
    return true;
  }

  const anchorDataId = _.has(link.anchorDataId, 'id')
    ? `#${link.anchorDataId?.id}`
    : link.anchorDataId;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/some
  return _.some(anchors, { anchorDataId });
};

export const getPageById = (
  id: string,
  pages: PagesData[],
): PagesData | null => {
  if (id) {
    // TODO: after the viewer is updated
    // temporary fix while editor can get pageId both as resolved ref or as string
    if (_.has(id, 'id')) {
      id = id.id;
    }

    return pages.find((page) => page.id === convertLinkPageIdToPageId(id));
  }

  return null;
};

const isTemplatePage = (page) => {
  return Boolean(page?.repeaterPage);
};

const isPopupLink = (link, popups) => {
  return Boolean(getPageById(link.pageId, popups));
};

const isSectionVisible = (linkType, visibleSections) =>
  !!visibleSections.find((link) => link.for === linkType);

export const getDefaultSelectedTab = (link, popups, visibleSections) => {
  const linkType = link ? link.type : null;

  if (
    linkType === 'AnchorLink' &&
    (link.anchorDataId === 'SCROLL_TO_TOP' ||
      link.anchorDataId === 'SCROLL_TO_BOTTOM')
  ) {
    return 'EdgeAnchorLinks';
  }

  if (linkType === 'PageLink' && isPopupLink(link, popups)) {
    return 'PopupLink';
  }

  const showPageLinkTabAsActive =
    (linkType === 'DynamicPageLink' || linkType === 'TpaPageLink') &&
    !link.anchorDataId;

  if (
    isSectionVisible('PageLink', visibleSections) &&
    (!linkType || showPageLinkTabAsActive)
  ) {
    return 'PageLink';
  }
  if (
    (linkType === 'DynamicPageLink' || linkType === 'TpaPageLink') &&
    link.anchorDataId
  ) {
    return 'AnchorLink';
  }

  if (!visibleSections.find((section) => section.for === linkType)) {
    return 'NoLink';
  }

  return linkType;
};

const updateLinkIfPageWasConvertedToDynamicPage = (
  editorAPI: EditorAPI,
  link,
) => {
  const pageId = _.isString(link.pageId)
    ? link.pageId
    : convertPageIdToLinkPageId(link.pageId?.id);
  const routerData =
    editorAPI.dsRead.routers.getRouterDataForPageIfExist(pageId);
  if (routerData) {
    return {
      type: 'DynamicPageLink',
      routerId: routerData.routerId,
      innerRoute: '',
      anchorDataId: link.anchorDataId || '',
    };
  }
  return link;
};

const shouldConvertExternalLinkToAddressLink = (visibleSections, link) => {
  const isExternalLink = link && link.type === 'ExternalLink';
  return (
    isExternalLink &&
    !visibleSections.ExternalLink &&
    visibleSections.AddressLink
  );
};

export const convertLink = (
  editorAPI: EditorAPI,
  linkToConvert,
  anchors,
  pagesForAnchors,
  visibleSections,
) => {
  if (!linkToConvert) {
    return null;
  }

  let link = _.cloneDeep(linkToConvert);
  if (link.type === 'PageLink' || link.type === 'AnchorLink') {
    link = updateLinkIfPageWasConvertedToDynamicPage(editorAPI, link);
  }
  if (link.type === 'AnchorLink' && !doesAnchorExist(link, anchors)) {
    link = convertAnchorLinkToPageLink(editorAPI, link, pagesForAnchors);
  }

  if (shouldConvertExternalLinkToAddressLink(visibleSections, link)) {
    link = convertExternalLinkToAddressLink(link);
  }

  if (link.type === 'DynamicPageLink') {
    if (!doesAnchorExist(link, anchors)) {
      link.anchorDataId = null;
    }
    if (!editorAPI.dsRead.routers.get.byId(link.routerId)) {
      link = null;
    }
  }

  return link;
};

export const getAnchorType = (link) => {
  switch (link.anchorDataId) {
    case 'SCROLL_TO_TOP':
      return 'page-top';

    case 'SCROLL_TO_BOTTOM':
      return 'page-bottom';

    default:
      return 'anchor';
  }
};

export const getPageLinkManagingAppDefId = (link: Link, pages: PagesData[]) =>
  pages.find((page) => convertLinkPageIdToPageId(link?.pageId) === page.id)
    ?.managingAppDefId;

export const getAllPagesVariations = (
  editorAPI: EditorAPI,
  pages: PagesData[],
) => {
  const platformAPI = editorAPI.host.getAPI(EditorPlatformHostIntegrationAPI);
  return pages.reduce((pageIds: string[], page: PagesData) => {
    const pageId = page.id;
    const pageRef = editorAPI.dsRead.pages.getReference(pageId);
    const allPageVariations = platformAPI.pageReplace.getPageVariations({
      ...pageRef,
      pageId,
    });

    return [
      ...pageIds,
      ...(allPageVariations?.replacers ?? []),
      ...(allPageVariations?.variants ?? []),
    ];
  }, []);
};

export const getNonLinkablePages = (
  editorAPI: EditorAPI,
  pages: PagesData[],
): string[] => {
  const installedApps = editorAPI.dsRead.platform.getInstalledApps();
  const installedAppsMap = _.keyBy(installedApps, (app) => app.appDefinitionId);

  return pages
    .filter((page) => {
      const appDefinitionId = page.appDefinitionId;
      const tpaPageId = page.tpaPageId;

      if (appDefinitionId && tpaPageId) {
        const appData = installedAppsMap[appDefinitionId];
        const widgetsArray = Object.values(appData?.widgets ?? []);
        const widgetWithMatchingTpaPageId = widgetsArray.find(
          (widget) => widget?.appPage?.id === tpaPageId,
        );
        return widgetWithMatchingTpaPageId?.componentFields?.linkable === false;
      }

      return false;
    })
    .map((page) => page.id);
};

export const getMainPagesIds = (pages: PagesData[]) => {
  return pages.filter((page) => !page.managingAppDefId).map((page) => page.id);
};

export const getPagesToShow = (
  pages,
  dynamicPages,
  hidePageLinks,
  pageVariations,
  nonLinkablePages,
) => {
  const pagesList = [];

  pages.forEach((page) => {
    if (
      !isTemplatePage(page) &&
      !pageVariations.includes(page.id) &&
      !nonLinkablePages.includes(page.id)
    ) {
      pagesList.push({
        ...page,
      });
    }
  });

  if (hidePageLinks) {
    const dynamicPageArr = _(dynamicPages).flatMap('pages').map('id').value();

    return pagesList.filter((page) => dynamicPageArr.includes(page.id));
  }

  return pagesList;
};

export const convertPageIdToLinkPageId = (pageId: string) => {
  if (!pageId) {
    return '';
  }

  return `#${pageId}`;
};

export const convertLinkPageIdToPageId = (linkPageId: string): string => {
  if (!linkPageId) {
    return '';
  }
  return linkPageId.charAt(0) === '#' ? linkPageId.slice(1) : linkPageId;
};

export const shouldShowPageSection = (pages: PagesData[]) => {
  return !_.isEmpty(pages);
};

export const getLinkTargetPage = (link): string | null => {
  return link.pageId?.replace(/#/g, '') || null;
};
