._add-preset-previewer-preview_1fnvb_1 {
  height: 100%;
  overflow: hidden;
  border-radius: 4px;
  background-color: #f0f3f5; }
  ._add-preset-previewer-preview_1fnvb_1 ._add-preset-previewer-preview-wrapper_1fnvb_6 {
    transition: opacity 350ms cubic-bezier(0.4, 0, 1, 1);
    opacity: 1; }
    ._add-preset-previewer-preview_1fnvb_1 ._add-preset-previewer-preview-wrapper_1fnvb_6._loading_1fnvb_9 {
      opacity: 0; }
