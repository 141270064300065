import {
  interactions,
  stateMapperArgsSelectors,
  selection,
} from '@/stateManagement';
import * as util from '@/util';
import type { EditorAPI } from '@/editorAPI';

import { canSelectSectionLike } from '../selectors';

const {
  rootSels,
  otherSels,
  selector: { createSelector },
} = stateMapperArgsSelectors;

const { dsReadSel, editorAPIMouseSel, editorAPISel, editorStateSel } = rootSels;

const canShowSiteSegmentBoxSelector = createSelector(
  [otherSels.isPinModeSel, editorStateSel, dsReadSel, editorAPISel],
  (isPinMode, editorState, dsRead, editorAPI) =>
    !isPinMode &&
    !dsRead?.pages.popupPages.isPopupOpened() &&
    !interactions.selectors.isInInteractionMode(editorState) &&
    !editorAPI.componentFocusMode.isEnabled(),
);

const shouldShowBoxWhenSectionActionsAreHovered = (editorAPI: EditorAPI) => {
  const selectedSection = editorAPI.sections.getSelectedSectionLike();
  const hoveredSection = editorAPI.sections.getHoveredSectionLike();
  if (selectedSection?.id === hoveredSection?.id) return true;
  return !selection.selectors.shouldHideSelectionWhenStageActionsHovered(
    editorAPI,
  );
};

export const shouldShowSelectedSiteSegmentBoxSel = createSelector(
  [
    canShowSiteSegmentBoxSelector,
    editorAPIMouseSel,
    editorStateSel,
    canSelectSectionLike,
  ],
  (canShowSiteSegmentBox, editorAPI, editorState, canSelectSectionLike) => {
    const siteSegmentToSelect =
      editorAPI.sections.getSelectedHeaderFooter() ||
      editorAPI.sections.getFocusedHeaderFooter();

    return (
      canShowSiteSegmentBox &&
      canSelectSectionLike &&
      Boolean(siteSegmentToSelect) &&
      util.spotlightStageUtils.canSelectCompConsideringSpotlightStage(
        editorAPI,
        selection.selectors.getFocusedContainer(editorState),
        siteSegmentToSelect,
      ) &&
      shouldShowBoxWhenSectionActionsAreHovered(editorAPI)
    );
  },
);

export const shouldShowHoveredSiteSegmentBoxSel = createSelector(
  [canShowSiteSegmentBoxSelector, editorAPIMouseSel],
  (canShowHeaderFooterBox, editorAPI) => {
    if (!canShowHeaderFooterBox) return false;

    return Boolean(editorAPI.sections.getHoveredHeaderFooter());
  },
);
