import React, { type ReactElement } from 'react';
import * as util from '@/util';
import { InfoIcon } from '@wix/wix-base-ui';
import styles from './videoTooltip.scss';
import { videoHovered } from '../../bi';

import { symbol as Symbol } from '@wix/santa-editor-symbols';
import type { BiEventDefinition, BiEventFields } from 'types/bi';

export interface VideoTooltipProps {
  title: string;
  subtitle: string;
  imageUrl: string;
  openVideoPanel: () => void;
  className?: string;
  videoUrl: string;
  isEmptyState: boolean;
  tabName: string;
  sendBi: (event: BiEventDefinition, params: BiEventFields) => void;
  anchor?: ReactElement;
}

const VideoTooltip: React.FC<VideoTooltipProps> = ({
  title,
  subtitle,
  imageUrl,
  openVideoPanel,
  className,
  videoUrl,
  isEmptyState,
  tabName,
  sendBi,
  anchor,
}) => (
  <InfoIcon
    dataHook="video-tooltip"
    className={className}
    alignment="BOTTOM"
    customSymbol={anchor || <Symbol name="info_video_tooltip_icon" />}
    title={title}
    text={subtitle}
    onIllustrationClick={openVideoPanel}
    illustration={
      <div className={styles.infoIconImageContainer}>
        <img
          data-hook="video-image"
          className={styles.infoIconImage}
          src={util.media.getMediaUrl(imageUrl)}
        />
        <Symbol className={styles.playVideoIcon} name="play-video" />
      </div>
    }
    onTooltipOpen={() => {
      sendBi(videoHovered, { videoUrl, isEmptyState, tabName });
    }}
    closeOnContentMouseClick
  />
);

export default VideoTooltip;
