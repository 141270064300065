import type { EditorAPI } from '@/editorAPI';
import constants from '@/constants';
import * as stateManagement from '@/stateManagement';
import { isSupportedApp } from '@/appManager';
import experiment from 'experiment';
import {
  silentConstants,
  type SilentInstallAppsOptions,
} from './silentInstallConstants';
import { createContainerSectionForWidget } from '../sectionsService';
import type { AppInstallOption } from '@wix/editor-platform-host-integration-apis';
import { WIX_CHAT } from '@wix/app-definition-ids';
import { appMarketUtils, constants as tpaConstants } from '@/tpa';
import type { AppData } from 'types/documentServices';
import { sections } from '@/util';

function openMyBusinessForApprovedApps(
  editorAPI: EditorAPI,
  appsDefIds: string[],
) {
  if (experiment.isOpen('se_openMyBusinessPanelAfterSilentInstall')) {
    for (const appDefId of appsDefIds) {
      if (isSupportedApp(editorAPI, appDefId)) {
        editorAPI.store.dispatch(
          stateManagement.panels.actions.updateOrOpenPanel(
            constants.ROOT_COMPS.LEFTBAR.APP_MANAGER_PANEL_NAME,
            { selectedAppId: appDefId },
            false,
          ),
        );
        break;
      }
    }
  }
}

async function getCommonAppOptions(
  editorAPI: EditorAPI,
  appDefIds: Array<string>,
  options: SilentInstallAppsOptions,
  serialInstallation: boolean,
): Promise<Record<string, Partial<AppInstallOption>>> {
  const { appsOptions } = options;
  const appOptions: Record<string, any> = {};

  for (const [currentIndex, appDefId] of appDefIds.entries()) {
    const containerSectionIfNeeded =
      (await createContainerSectionForWidget(
        editorAPI,
        appDefId,
        currentIndex,
        serialInstallation,
      )) ?? {};

    appOptions[appDefId] = {
      ...appsOptions?.[appDefId],
      ...containerSectionIfNeeded,
    };
  }
  return appOptions;
}

const canInstallAppSilently = (
  serialInstallation: boolean,
  appData: AppData,
) => {
  if (
    experiment.isOpen('se_installWidgetsInSectionSilently') &&
    serialInstallation &&
    sections.isSectionsEnabled()
  ) {
    return true;
  }
  // Temporary check for first phase of pending to silent.
  // Chat is a widget app that can be installed with the silent flow.
  // In the next phase, all apps would be able to install through silent, so the filter would be removed.
  if (appData) {
    const isChat = appData.appDefinitionId === WIX_CHAT;
    const type = appMarketUtils.getAppType(appData);
    return isChat || type !== tpaConstants.APP.TYPE.WIDGET;
  }
};

const handleParallelErrors = async (
  editorAPI: EditorAPI,
  options: SilentInstallAppsOptions,
  errName: string,
) => {
  if (
    !options.disableHistoryRevert &&
    errName === silentConstants.errNames.ADD_APP_ERR
  ) {
    await editorAPI.waitForChangesAppliedAsync();
    await editorAPI.history.performUndoUntilLabel(
      silentConstants.BEFORE_SILENT_INSTALL_HISTORY_LABEL,
      true,
    );
  }
  if (errName !== silentConstants.errNames.TEMPLATE_ERR) {
    editorAPI.history.clear();
    editorAPI.saveManager.save({ preventSiteSavedPanel: true });
  }
  editorAPI.panelHelpers.closeProgressBar();
};

export {
  openMyBusinessForApprovedApps,
  getCommonAppOptions,
  canInstallAppSilently,
  handleParallelErrors,
};
