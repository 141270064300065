'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')

module.exports = {
    'type': addPanelDataConsts.SECTIONS_TYPES.PRESET,
    'hide': false,
    'title': 'design_section_label_explore_page',
    'showSectionHeader': true,
    'additionalBehaviours': {
        'labelMode': addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
        'infoIcon': addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
        'hoverImageAction': addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
        'iconEnabledForComps': {}
    },
    'props': {
        'image': 'addPanelData/sections/pageStyleSection_en/pageStyleSection_en.png',
        'imageHover': null,
        'items': [{
            'id': 'page_0',
            'structure': {
                'type': 'Page',
                'components': [],
                'skin': 'wysiwyg.viewer.skins.page.TransparentPageSkin',
                'layout': {
                    'width': 980,
                    'height': 500,
                    'x': 0,
                    'y': 0,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'anchors': [],
                    'fixedPosition': false
                },
                'componentType': 'mobile.core.components.Page',
                'data': {
                    'type': 'Page',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'title': ' 1',
                    'hideTitle': true,
                    'icon': '',
                    'descriptionSEO': '',
                    'metaKeywordsSEO': '',
                    'pageTitleSEO': '',
                    'pageUriSEO': '1',
                    'hidePage': false,
                    'underConstruction': false,
                    'tpaApplicationId': 0,
                    'pageSecurity': {'requireLogin': false},
                    'indexable': true,
                    'isLandingPage': false,
                    'pageBackgrounds': {
                        'desktop': {
                            'custom': true,
                            'ref': {
                                'type': 'BackgroundMedia',
                                'color': '{color_11}',
                                'alignType': 'top',
                                'fittingType': 'fill',
                                'scrollType': 'fixed'
                            },
                            'isPreset': false
                        },
                        'mobile': {
                            'custom': true,
                            'ref': {
                                'type': 'BackgroundMedia',
                                'color': '{color_11}',
                                'alignType': 'top',
                                'fittingType': 'fill',
                                'scrollType': 'fixed'
                            },
                            'isPreset': true
                        }
                    }
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {'properties': {}, 'propertiesSource': {}, 'groups': {}},
                    'componentClassName': 'mobile.core.components.Page',
                    'pageId': '',
                    'compId': 'c1dmp',
                    'styleType': 'custom',
                    'skin': 'wysiwyg.viewer.skins.page.TransparentPageSkin'
                }
            },
            'preset': {'rect': {'width': 162, 'height': 190, 'x': 0, 'y': 0}}
        }, {
            'id': 'page_1',
            'structure': {
                'type': 'Page',
                'components': [],
                'skin': 'wysiwyg.viewer.skins.page.BasicPageSkin',
                'layout': {
                    'width': 980,
                    'height': 500,
                    'x': 0,
                    'y': 0,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'anchors': [],
                    'fixedPosition': false
                },
                'componentType': 'mobile.core.components.Page',
                'data': {
                    'type': 'Page',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'title': '2',
                    'hideTitle': true,
                    'icon': '',
                    'descriptionSEO': '',
                    'metaKeywordsSEO': '',
                    'pageTitleSEO': '',
                    'pageUriSEO': '2',
                    'hidePage': false,
                    'underConstruction': false,
                    'tpaApplicationId': 0,
                    'pageSecurity': {'requireLogin': false},
                    'indexable': true,
                    'isLandingPage': false,
                    'pageBackgrounds': {
                        'desktop': {
                            'custom': true,
                            'ref': {
                                'type': 'BackgroundMedia',
                                'color': '{color_11}',
                                'alignType': 'top',
                                'fittingType': 'fill',
                                'scrollType': 'fixed'
                            },
                            'isPreset': false
                        },
                        'mobile': {
                            'custom': true,
                            'ref': {
                                'type': 'BackgroundMedia',
                                'color': '{color_11}',
                                'alignType': 'top',
                                'fittingType': 'fill',
                                'scrollType': 'fixed'
                            },
                            'isPreset': true
                        }
                    }
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'properties': {'alpha-bg': '0.6', 'bg': '#FFFFFF'},
                        'propertiesSource': {'bg': 'value'},
                        'groups': {}
                    },
                    'componentClassName': 'mobile.core.components.Page',
                    'pageId': '',
                    'compId': 'ccs3',
                    'styleType': 'custom',
                    'skin': 'wysiwyg.viewer.skins.page.BasicPageSkin'
                }
            },
            'preset': {'rect': {'width': 162, 'height': 190, 'x': 162, 'y': 0}}
        }, {
            'id': 'page_2',
            'structure': {
                'type': 'Page',
                'components': [],
                'skin': 'wysiwyg.viewer.skins.page.BasicPageSkin',
                'layout': {
                    'width': 980,
                    'height': 500,
                    'x': 0,
                    'y': 0,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'anchors': [],
                    'fixedPosition': false
                },
                'componentType': 'mobile.core.components.Page',
                'data': {
                    'type': 'Page',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'title': '3',
                    'hideTitle': true,
                    'icon': '',
                    'descriptionSEO': '',
                    'metaKeywordsSEO': '',
                    'pageTitleSEO': '',
                    'pageUriSEO': '3',
                    'hidePage': false,
                    'underConstruction': false,
                    'tpaApplicationId': 0,
                    'pageSecurity': {'requireLogin': false},
                    'indexable': true,
                    'isLandingPage': false,
                    'pageBackgrounds': {
                        'desktop': {
                            'custom': true,
                            'ref': {
                                'type': 'BackgroundMedia',
                                'color': '{color_11}',
                                'alignType': 'top',
                                'fittingType': 'fill',
                                'scrollType': 'fixed'
                            },
                            'isPreset': false
                        },
                        'mobile': {
                            'custom': true,
                            'ref': {
                                'type': 'BackgroundMedia',
                                'color': '{color_11}',
                                'alignType': 'top',
                                'fittingType': 'fill',
                                'scrollType': 'fixed'
                            },
                            'isPreset': true
                        }
                    }
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'properties': {'alpha-bg': '0.6', 'bg': '#000000'},
                        'propertiesSource': {'bg': 'value'},
                        'groups': {}
                    },
                    'componentClassName': 'mobile.core.components.Page',
                    'pageId': '',
                    'compId': 'c1aqm',
                    'styleType': 'custom',
                    'skin': 'wysiwyg.viewer.skins.page.BasicPageSkin'
                }
            },
            'preset': {'rect': {'width': 162, 'height': 190, 'x': 0, 'y': 190}}
        }, {
            'id': 'page_3',
            'structure': {
                'type': 'Page',
                'components': [],
                'skin': 'wysiwyg.viewer.skins.page.BorderPageSkin',
                'layout': {
                    'width': 980,
                    'height': 500,
                    'x': 0,
                    'y': 0,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'anchors': [],
                    'fixedPosition': false
                },
                'componentType': 'mobile.core.components.Page',
                'data': {
                    'type': 'Page',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'title': '4',
                    'hideTitle': true,
                    'icon': '',
                    'descriptionSEO': '',
                    'metaKeywordsSEO': '',
                    'pageTitleSEO': '',
                    'pageUriSEO': '4',
                    'hidePage': false,
                    'underConstruction': false,
                    'tpaApplicationId': 0,
                    'pageSecurity': {'requireLogin': false},
                    'indexable': true,
                    'isLandingPage': false,
                    'pageBackgrounds': {
                        'desktop': {
                            'custom': true,
                            'ref': {
                                'type': 'BackgroundMedia',
                                'color': '{color_11}',
                                'alignType': 'top',
                                'fittingType': 'fill',
                                'scrollType': 'fixed'
                            },
                            'isPreset': false
                        },
                        'mobile': {
                            'custom': true,
                            'ref': {
                                'type': 'BackgroundMedia',
                                'color': '{color_11}',
                                'alignType': 'top',
                                'fittingType': 'fill',
                                'scrollType': 'fixed'
                            },
                            'isPreset': true
                        }
                    }
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'properties': {'alpha-bg': '1', 'bg': '#FFFFFF'},
                        'propertiesSource': {'bg': 'value'},
                        'groups': {}
                    },
                    'componentClassName': 'mobile.core.components.Page',
                    'pageId': '',
                    'compId': 'ccv0',
                    'styleType': 'custom',
                    'skin': 'wysiwyg.viewer.skins.page.BorderPageSkin'
                }
            },
            'preset': {'rect': {'width': 162, 'height': 190, 'x': 162, 'y': 190}}
        }, {
            'id': 'page_4',
            'structure': {
                'type': 'Page',
                'components': [],
                'skin': 'wysiwyg.viewer.skins.page.BasicPageSkin',
                'layout': {
                    'width': 980,
                    'height': 500,
                    'x': 0,
                    'y': 0,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'anchors': [],
                    'fixedPosition': false
                },
                'componentType': 'mobile.core.components.Page',
                'data': {
                    'type': 'Page',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'title': '5',
                    'hideTitle': true,
                    'icon': '',
                    'descriptionSEO': '',
                    'metaKeywordsSEO': '',
                    'pageTitleSEO': '',
                    'pageUriSEO': '5',
                    'hidePage': false,
                    'underConstruction': false,
                    'tpaApplicationId': 0,
                    'pageSecurity': {'requireLogin': false},
                    'indexable': true,
                    'isLandingPage': false,
                    'pageBackgrounds': {
                        'desktop': {
                            'custom': true,
                            'ref': {
                                'type': 'BackgroundMedia',
                                'color': '{color_11}',
                                'alignType': 'top',
                                'fittingType': 'fill',
                                'scrollType': 'fixed'
                            },
                            'isPreset': false
                        },
                        'mobile': {
                            'custom': true,
                            'ref': {
                                'type': 'BackgroundMedia',
                                'color': '{color_11}',
                                'alignType': 'top',
                                'fittingType': 'fill',
                                'scrollType': 'fixed'
                            },
                            'isPreset': true
                        }
                    }
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'properties': {'alpha-bg': '1', 'bg': '#000000'},
                        'propertiesSource': {'bg': 'value'},
                        'groups': {}
                    },
                    'componentClassName': 'mobile.core.components.Page',
                    'pageId': '',
                    'compId': 'c1c8u',
                    'styleType': 'custom',
                    'skin': 'wysiwyg.viewer.skins.page.BasicPageSkin'
                }
            },
            'preset': {'rect': {'width': 162, 'height': 190, 'x': 0, 'y': 380}}
        }, {
            'id': 'page_5',
            'structure': {
                'type': 'Page',
                'components': [],
                'skin': 'wysiwyg.viewer.skins.page.BasicPageSkin',
                'layout': {
                    'width': 980,
                    'height': 500,
                    'x': 0,
                    'y': 0,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'anchors': [],
                    'fixedPosition': false
                },
                'componentType': 'mobile.core.components.Page',
                'data': {
                    'type': 'Page',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'title': '6',
                    'hideTitle': true,
                    'icon': '',
                    'descriptionSEO': '',
                    'metaKeywordsSEO': '',
                    'pageTitleSEO': '',
                    'pageUriSEO': '6',
                    'hidePage': false,
                    'underConstruction': false,
                    'tpaApplicationId': 0,
                    'pageSecurity': {'requireLogin': false},
                    'indexable': true,
                    'isLandingPage': false,
                    'pageBackgrounds': {
                        'desktop': {
                            'custom': true,
                            'ref': {
                                'type': 'BackgroundMedia',
                                'color': '{color_11}',
                                'alignType': 'top',
                                'fittingType': 'fill',
                                'scrollType': 'fixed'
                            },
                            'isPreset': false
                        },
                        'mobile': {
                            'custom': true,
                            'ref': {
                                'type': 'BackgroundMedia',
                                'color': '{color_11}',
                                'alignType': 'top',
                                'fittingType': 'fill',
                                'scrollType': 'fixed'
                            },
                            'isPreset': true
                        }
                    }
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'properties': {'alpha-bg': '0.07', 'bg': '#000000'},
                        'propertiesSource': {'bg': 'value'},
                        'groups': {}
                    },
                    'componentClassName': 'mobile.core.components.Page',
                    'pageId': '',
                    'compId': 'c1571',
                    'styleType': 'custom',
                    'skin': 'wysiwyg.viewer.skins.page.BasicPageSkin'
                }
            },
            'preset': {'rect': {'width': 162, 'height': 190, 'x': 162, 'y': 380}}
        }, {
            'id': 'page_6',
            'structure': {
                'type': 'Page',
                'components': [],
                'skin': 'wysiwyg.viewer.skins.page.BasicPageSkin',
                'layout': {
                    'width': 980,
                    'height': 500,
                    'x': 0,
                    'y': 0,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'anchors': [],
                    'fixedPosition': false
                },
                'componentType': 'mobile.core.components.Page',
                'data': {
                    'type': 'Page',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'title': '7',
                    'hideTitle': true,
                    'icon': '',
                    'descriptionSEO': '',
                    'metaKeywordsSEO': '',
                    'pageTitleSEO': '',
                    'pageUriSEO': '7',
                    'hidePage': false,
                    'underConstruction': false,
                    'tpaApplicationId': 0,
                    'pageSecurity': {'requireLogin': false},
                    'indexable': true,
                    'isLandingPage': false,
                    'pageBackgrounds': {
                        'desktop': {
                            'custom': true,
                            'ref': {
                                'type': 'BackgroundMedia',
                                'color': '{color_11}',
                                'alignType': 'top',
                                'fittingType': 'fill',
                                'scrollType': 'fixed'
                            },
                            'isPreset': false
                        },
                        'mobile': {
                            'custom': true,
                            'ref': {
                                'type': 'BackgroundMedia',
                                'color': '{color_11}',
                                'alignType': 'top',
                                'fittingType': 'fill',
                                'scrollType': 'fixed'
                            },
                            'isPreset': true
                        }
                    }
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'properties': {'alpha-bg': '1', 'bg': '#2B6CA3'},
                        'propertiesSource': {'bg': 'value'},
                        'groups': {}
                    },
                    'componentClassName': 'mobile.core.components.Page',
                    'pageId': '',
                    'compId': 'che0',
                    'styleType': 'custom',
                    'skin': 'wysiwyg.viewer.skins.page.BasicPageSkin'
                }
            },
            'preset': {'rect': {'width': 162, 'height': 190, 'x': 0, 'y': 570}}
        }, {
            'id': 'page_7',
            'structure': {
                'type': 'Page',
                'components': [],
                'skin': 'wysiwyg.viewer.skins.page.BasicPageSkin',
                'layout': {
                    'width': 980,
                    'height': 500,
                    'x': 0,
                    'y': 0,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'anchors': [],
                    'fixedPosition': false
                },
                'componentType': 'mobile.core.components.Page',
                'data': {
                    'type': 'Page',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'title': '8',
                    'hideTitle': true,
                    'icon': '',
                    'descriptionSEO': '',
                    'metaKeywordsSEO': '',
                    'pageTitleSEO': '',
                    'pageUriSEO': '8',
                    'hidePage': false,
                    'underConstruction': false,
                    'tpaApplicationId': 0,
                    'pageSecurity': {'requireLogin': false},
                    'indexable': true,
                    'isLandingPage': false,
                    'pageBackgrounds': {
                        'desktop': {
                            'custom': true,
                            'ref': {
                                'type': 'BackgroundMedia',
                                'color': '{color_11}',
                                'alignType': 'top',
                                'fittingType': 'fill',
                                'scrollType': 'fixed'
                            },
                            'isPreset': false
                        },
                        'mobile': {
                            'custom': true,
                            'ref': {
                                'type': 'BackgroundMedia',
                                'color': '{color_11}',
                                'alignType': 'top',
                                'fittingType': 'fill',
                                'scrollType': 'fixed'
                            },
                            'isPreset': true
                        }
                    }
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'properties': {'alpha-bg': '1', 'bg': '#002E5D'},
                        'propertiesSource': {'bg': 'value'},
                        'groups': {}
                    },
                    'componentClassName': 'mobile.core.components.Page',
                    'pageId': '',
                    'compId': 'c10si',
                    'styleType': 'custom',
                    'skin': 'wysiwyg.viewer.skins.page.BasicPageSkin'
                }
            },
            'preset': {'rect': {'width': 162, 'height': 190, 'x': 162, 'y': 570}}
        }, {
            'id': 'page_8',
            'structure': {
                'type': 'Page',
                'components': [],
                'skin': 'wysiwyg.viewer.skins.page.LiftedTopPageSkin',
                'layout': {
                    'width': 980,
                    'height': 500,
                    'x': 0,
                    'y': 0,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'anchors': [],
                    'fixedPosition': false
                },
                'componentType': 'mobile.core.components.Page',
                'data': {
                    'type': 'Page',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'title': '9',
                    'hideTitle': true,
                    'icon': '',
                    'descriptionSEO': '',
                    'metaKeywordsSEO': '',
                    'pageTitleSEO': '',
                    'pageUriSEO': '9',
                    'hidePage': false,
                    'underConstruction': false,
                    'tpaApplicationId': 0,
                    'pageSecurity': {'requireLogin': false},
                    'indexable': true,
                    'isLandingPage': false,
                    'pageBackgrounds': {
                        'desktop': {
                            'custom': true,
                            'ref': {
                                'type': 'BackgroundMedia',
                                'color': '{color_11}',
                                'alignType': 'top',
                                'fittingType': 'fill',
                                'scrollType': 'fixed'
                            },
                            'isPreset': false
                        },
                        'mobile': {
                            'custom': true,
                            'ref': {
                                'type': 'BackgroundMedia',
                                'color': '{color_11}',
                                'alignType': 'top',
                                'fittingType': 'fill',
                                'scrollType': 'fixed'
                            },
                            'isPreset': true
                        }
                    }
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'properties': {
                            'alpha-bg': '1',
                            'alpha-brd': '1',
                            'bg': '#ED1566',
                            'brd': '#F2668F',
                            'brw': '0px'
                        }, 'propertiesSource': {'bg': 'value', 'brd': 'value', 'brw': 'value'}, 'groups': {}
                    },
                    'componentClassName': 'mobile.core.components.Page',
                    'pageId': '',
                    'compId': 'cui3',
                    'styleType': 'custom',
                    'skin': 'wysiwyg.viewer.skins.page.LiftedTopPageSkin'
                }
            },
            'preset': {'rect': {'width': 162, 'height': 190, 'x': 0, 'y': 760}}
        }, {
            'id': 'page_9',
            'structure': {
                'type': 'Page',
                'components': [],
                'skin': 'wysiwyg.viewer.skins.page.LiftedTopPageSkin',
                'layout': {
                    'width': 980,
                    'height': 500,
                    'x': 0,
                    'y': 0,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'anchors': [],
                    'fixedPosition': false
                },
                'componentType': 'mobile.core.components.Page',
                'data': {
                    'type': 'Page',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'title': '10',
                    'hideTitle': true,
                    'icon': '',
                    'descriptionSEO': '',
                    'metaKeywordsSEO': '',
                    'pageTitleSEO': '',
                    'pageUriSEO': '10',
                    'hidePage': false,
                    'underConstruction': false,
                    'tpaApplicationId': 0,
                    'pageSecurity': {'requireLogin': false},
                    'indexable': true,
                    'isLandingPage': false,
                    'pageBackgrounds': {
                        'desktop': {
                            'custom': true,
                            'ref': {
                                'type': 'BackgroundMedia',
                                'color': '{color_11}',
                                'alignType': 'top',
                                'fittingType': 'fill',
                                'scrollType': 'fixed'
                            },
                            'isPreset': false
                        },
                        'mobile': {
                            'custom': true,
                            'ref': {
                                'type': 'BackgroundMedia',
                                'color': '{color_11}',
                                'alignType': 'top',
                                'fittingType': 'fill',
                                'scrollType': 'fixed'
                            },
                            'isPreset': true
                        }
                    }
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'properties': {
                            'alpha-bg': '1',
                            'alpha-brd': '1',
                            'bg': '#D61043',
                            'brd': '#F2668F',
                            'brw': '0px'
                        }, 'propertiesSource': {'bg': 'value', 'brd': 'value', 'brw': 'value'}, 'groups': {}
                    },
                    'componentClassName': 'mobile.core.components.Page',
                    'pageId': '',
                    'compId': 'c1ku0',
                    'styleType': 'custom',
                    'skin': 'wysiwyg.viewer.skins.page.LiftedTopPageSkin'
                }
            },
            'preset': {'rect': {'width': 162, 'height': 190, 'x': 162, 'y': 760}}
        }, {
            'id': 'page_10',
            'structure': {
                'type': 'Page',
                'components': [],
                'skin': 'wysiwyg.viewer.skins.page.LiftedBottomPageSkin',
                'layout': {
                    'width': 980,
                    'height': 500,
                    'x': 0,
                    'y': 0,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'anchors': [],
                    'fixedPosition': false
                },
                'componentType': 'mobile.core.components.Page',
                'data': {
                    'type': 'Page',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'title': '11',
                    'hideTitle': true,
                    'icon': '',
                    'descriptionSEO': '',
                    'metaKeywordsSEO': '',
                    'pageTitleSEO': '',
                    'pageUriSEO': '11',
                    'hidePage': false,
                    'underConstruction': false,
                    'tpaApplicationId': 0,
                    'pageSecurity': {'requireLogin': false},
                    'indexable': true,
                    'isLandingPage': false,
                    'pageBackgrounds': {
                        'desktop': {
                            'custom': true,
                            'ref': {
                                'type': 'BackgroundMedia',
                                'color': '{color_11}',
                                'alignType': 'top',
                                'fittingType': 'fill',
                                'scrollType': 'fixed'
                            },
                            'isPreset': false
                        },
                        'mobile': {
                            'custom': true,
                            'ref': {
                                'type': 'BackgroundMedia',
                                'color': '{color_11}',
                                'alignType': 'top',
                                'fittingType': 'fill',
                                'scrollType': 'fixed'
                            },
                            'isPreset': true
                        }
                    }
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'properties': {
                            'alpha-bg': '1',
                            'alpha-brd': '1',
                            'bg': '#68B04D',
                            'brd': '#1C8A43',
                            'brw': '0px'
                        }, 'propertiesSource': {'bg': 'value', 'brd': 'value', 'brw': 'value'}, 'groups': {}
                    },
                    'componentClassName': 'mobile.core.components.Page',
                    'pageId': '',
                    'compId': 'c1znz',
                    'styleType': 'custom',
                    'skin': 'wysiwyg.viewer.skins.page.LiftedBottomPageSkin'
                }
            },
            'preset': {'rect': {'width': 162, 'height': 190, 'x': 0, 'y': 950}}
        }, {
            'id': 'page_11',
            'structure': {
                'type': 'Page',
                'components': [],
                'skin': 'wysiwyg.viewer.skins.page.LiftedBottomPageSkin',
                'layout': {
                    'width': 980,
                    'height': 500,
                    'x': 0,
                    'y': 0,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'anchors': [],
                    'fixedPosition': false
                },
                'componentType': 'mobile.core.components.Page',
                'data': {
                    'type': 'Page',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'title': '12',
                    'hideTitle': true,
                    'icon': '',
                    'descriptionSEO': '',
                    'metaKeywordsSEO': '',
                    'pageTitleSEO': '',
                    'pageUriSEO': '12',
                    'hidePage': false,
                    'underConstruction': false,
                    'tpaApplicationId': 0,
                    'pageSecurity': {'requireLogin': false},
                    'indexable': true,
                    'isLandingPage': false,
                    'pageBackgrounds': {
                        'desktop': {
                            'custom': true,
                            'ref': {
                                'type': 'BackgroundMedia',
                                'color': '{color_11}',
                                'alignType': 'top',
                                'fittingType': 'fill',
                                'scrollType': 'fixed'
                            },
                            'isPreset': false
                        },
                        'mobile': {
                            'custom': true,
                            'ref': {
                                'type': 'BackgroundMedia',
                                'color': '{color_11}',
                                'alignType': 'top',
                                'fittingType': 'fill',
                                'scrollType': 'fixed'
                            },
                            'isPreset': true
                        }
                    }
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'properties': {
                            'alpha-bg': '1',
                            'alpha-brd': '1',
                            'bg': '#1C8A43',
                            'brd': '#BDBF37',
                            'brw': '0px'
                        }, 'propertiesSource': {'bg': 'value', 'brd': 'value', 'brw': 'value'}, 'groups': {}
                    },
                    'componentClassName': 'mobile.core.components.Page',
                    'pageId': '',
                    'compId': 'cued',
                    'styleType': 'custom',
                    'skin': 'wysiwyg.viewer.skins.page.LiftedBottomPageSkin'
                }
            },
            'preset': {'rect': {'width': 162, 'height': 190, 'x': 162, 'y': 950}}
        }, {
            'id': 'page_12',
            'structure': {
                'type': 'Page',
                'components': [],
                'skin': 'wysiwyg.viewer.skins.page.InnerShadowPageSkin',
                'layout': {
                    'width': 980,
                    'height': 500,
                    'x': 0,
                    'y': 0,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'anchors': [],
                    'fixedPosition': false
                },
                'componentType': 'mobile.core.components.Page',
                'data': {
                    'type': 'Page',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'title': '13',
                    'hideTitle': true,
                    'icon': '',
                    'descriptionSEO': '',
                    'metaKeywordsSEO': '',
                    'pageTitleSEO': '',
                    'pageUriSEO': '13',
                    'hidePage': false,
                    'underConstruction': false,
                    'tpaApplicationId': 0,
                    'pageSecurity': {'requireLogin': false},
                    'indexable': true,
                    'isLandingPage': false,
                    'pageBackgrounds': {
                        'desktop': {
                            'custom': true,
                            'ref': {
                                'type': 'BackgroundMedia',
                                'color': '{color_11}',
                                'alignType': 'top',
                                'fittingType': 'fill',
                                'scrollType': 'fixed'
                            },
                            'isPreset': false
                        },
                        'mobile': {
                            'custom': true,
                            'ref': {
                                'type': 'BackgroundMedia',
                                'color': '{color_11}',
                                'alignType': 'top',
                                'fittingType': 'fill',
                                'scrollType': 'fixed'
                            },
                            'isPreset': true
                        }
                    }
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'properties': {
                            'alpha-bg': '1',
                            'alpha-brd': '1',
                            'bg': '#01B1AF',
                            'brd': '#006C6D',
                            'brw': '0px',
                            'rd': '0px'
                        },
                        'propertiesSource': {'bg': 'value', 'brd': 'value', 'brw': 'value', 'rd': 'value'},
                        'groups': {}
                    },
                    'componentClassName': 'mobile.core.components.Page',
                    'pageId': '',
                    'compId': 'c5qs',
                    'styleType': 'custom',
                    'skin': 'wysiwyg.viewer.skins.page.InnerShadowPageSkin'
                }
            },
            'preset': {'rect': {'width': 162, 'height': 190, 'x': 0, 'y': 1140}}
        }, {
            'id': 'page_13',
            'structure': {
                'type': 'Page',
                'components': [],
                'skin': 'wysiwyg.viewer.skins.page.InnerShadowPageSkin',
                'layout': {
                    'width': 980,
                    'height': 500,
                    'x': 0,
                    'y': 0,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'anchors': [],
                    'fixedPosition': false
                },
                'componentType': 'mobile.core.components.Page',
                'data': {
                    'type': 'Page',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'title': '14',
                    'hideTitle': true,
                    'icon': '',
                    'descriptionSEO': '',
                    'metaKeywordsSEO': '',
                    'pageTitleSEO': '',
                    'pageUriSEO': '14',
                    'hidePage': false,
                    'underConstruction': false,
                    'tpaApplicationId': 0,
                    'pageSecurity': {'requireLogin': false},
                    'indexable': true,
                    'isLandingPage': false,
                    'pageBackgrounds': {
                        'desktop': {
                            'custom': true,
                            'ref': {
                                'type': 'BackgroundMedia',
                                'color': '{color_11}',
                                'alignType': 'top',
                                'fittingType': 'fill',
                                'scrollType': 'fixed'
                            },
                            'isPreset': false
                        },
                        'mobile': {
                            'custom': true,
                            'ref': {
                                'type': 'BackgroundMedia',
                                'color': '{color_11}',
                                'alignType': 'top',
                                'fittingType': 'fill',
                                'scrollType': 'fixed'
                            },
                            'isPreset': true
                        }
                    }
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'properties': {
                            'alpha-bg': '1',
                            'alpha-brd': '1',
                            'bg': '#006C6D',
                            'brd': '#01B1AF',
                            'brw': '0px',
                            'rd': '0px'
                        },
                        'propertiesSource': {'bg': 'value', 'brd': 'value', 'brw': 'value', 'rd': 'value'},
                        'groups': {}
                    },
                    'componentClassName': 'mobile.core.components.Page',
                    'pageId': '',
                    'compId': 'c23mp',
                    'styleType': 'custom',
                    'skin': 'wysiwyg.viewer.skins.page.InnerShadowPageSkin'
                }
            },
            'preset': {'rect': {'width': 162, 'height': 190, 'x': 162, 'y': 1140}}
        }, {
            'id': 'page_14',
            'structure': {
                'type': 'Page',
                'components': [],
                'skin': 'wysiwyg.viewer.skins.page.InnerShadowPageSkin',
                'layout': {
                    'width': 980,
                    'height': 500,
                    'x': 0,
                    'y': 0,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'anchors': [],
                    'fixedPosition': false
                },
                'componentType': 'mobile.core.components.Page',
                'data': {
                    'type': 'Page',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'title': '15',
                    'hideTitle': true,
                    'icon': '',
                    'descriptionSEO': '',
                    'metaKeywordsSEO': '',
                    'pageTitleSEO': '',
                    'pageUriSEO': '15',
                    'hidePage': false,
                    'underConstruction': false,
                    'tpaApplicationId': 0,
                    'pageSecurity': {'requireLogin': false},
                    'indexable': true,
                    'isLandingPage': false,
                    'pageBackgrounds': {
                        'desktop': {
                            'custom': true,
                            'ref': {
                                'type': 'BackgroundMedia',
                                'color': '{color_11}',
                                'alignType': 'top',
                                'fittingType': 'fill',
                                'scrollType': 'fixed'
                            },
                            'isPreset': false
                        },
                        'mobile': {
                            'custom': true,
                            'ref': {
                                'type': 'BackgroundMedia',
                                'color': '{color_11}',
                                'alignType': 'top',
                                'fittingType': 'fill',
                                'scrollType': 'fixed'
                            },
                            'isPreset': true
                        }
                    }
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'properties': {
                            'alpha-bg': '1',
                            'alpha-brd': '1',
                            'bg': '#DBB89A',
                            'brd': '#342216',
                            'brw': '0px',
                            'rd': '0px'
                        },
                        'propertiesSource': {'bg': 'value', 'brd': 'value', 'brw': 'value', 'rd': 'value'},
                        'groups': {}
                    },
                    'componentClassName': 'mobile.core.components.Page',
                    'pageId': '',
                    'compId': 'caeb',
                    'styleType': 'custom',
                    'skin': 'wysiwyg.viewer.skins.page.InnerShadowPageSkin'
                }
            },
            'preset': {'rect': {'width': 162, 'height': 190, 'x': 0, 'y': 1330}}
        }, {
            'id': 'page_15',
            'structure': {
                'type': 'Page',
                'components': [],
                'skin': 'wysiwyg.viewer.skins.page.InnerShadowPageSkin',
                'layout': {
                    'width': 980,
                    'height': 500,
                    'x': 0,
                    'y': 0,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'anchors': [],
                    'fixedPosition': false
                },
                'componentType': 'mobile.core.components.Page',
                'data': {
                    'type': 'Page',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'title': '16',
                    'hideTitle': true,
                    'icon': '',
                    'descriptionSEO': '',
                    'metaKeywordsSEO': '',
                    'pageTitleSEO': '',
                    'pageUriSEO': '16',
                    'hidePage': false,
                    'underConstruction': false,
                    'tpaApplicationId': 0,
                    'pageSecurity': {'requireLogin': false},
                    'indexable': true,
                    'isLandingPage': false,
                    'pageBackgrounds': {
                        'desktop': {
                            'custom': true,
                            'ref': {
                                'type': 'BackgroundMedia',
                                'color': '{color_11}',
                                'alignType': 'top',
                                'fittingType': 'fill',
                                'scrollType': 'fixed'
                            },
                            'isPreset': false
                        },
                        'mobile': {
                            'custom': true,
                            'ref': {
                                'type': 'BackgroundMedia',
                                'color': '{color_11}',
                                'alignType': 'top',
                                'fittingType': 'fill',
                                'scrollType': 'fixed'
                            },
                            'isPreset': true
                        }
                    }
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'properties': {
                            'alpha-bg': '1',
                            'alpha-brd': '1',
                            'bg': '#342216',
                            'brd': '#163E3A',
                            'brw': '0px',
                            'rd': '0px'
                        },
                        'propertiesSource': {'bg': 'value', 'brd': 'value', 'brw': 'value', 'rd': 'value'},
                        'groups': {}
                    },
                    'componentClassName': 'mobile.core.components.Page',
                    'pageId': '',
                    'compId': 'c1c5',
                    'styleType': 'custom',
                    'skin': 'wysiwyg.viewer.skins.page.InnerShadowPageSkin'
                }
            },
            'preset': {'rect': {'width': 162, 'height': 190, 'x': 162, 'y': 1330}}
        }, {
            'id': 'page_16',
            'structure': {
                'type': 'Page',
                'components': [],
                'skin': 'wysiwyg.viewer.skins.page.LiftedShadowPageSkin',
                'layout': {
                    'width': 980,
                    'height': 500,
                    'x': 0,
                    'y': 0,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'anchors': [],
                    'fixedPosition': false
                },
                'componentType': 'mobile.core.components.Page',
                'data': {
                    'type': 'Page',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'title': '17',
                    'hideTitle': true,
                    'icon': '',
                    'descriptionSEO': '',
                    'metaKeywordsSEO': '',
                    'pageTitleSEO': '',
                    'pageUriSEO': '17',
                    'hidePage': false,
                    'underConstruction': false,
                    'tpaApplicationId': 0,
                    'pageSecurity': {'requireLogin': false},
                    'indexable': true,
                    'isLandingPage': false,
                    'pageBackgrounds': {
                        'desktop': {
                            'custom': true,
                            'ref': {
                                'type': 'BackgroundMedia',
                                'color': '{color_11}',
                                'alignType': 'top',
                                'fittingType': 'fill',
                                'scrollType': 'fixed'
                            },
                            'isPreset': false
                        },
                        'mobile': {
                            'custom': true,
                            'ref': {
                                'type': 'BackgroundMedia',
                                'color': '{color_11}',
                                'alignType': 'top',
                                'fittingType': 'fill',
                                'scrollType': 'fixed'
                            },
                            'isPreset': true
                        }
                    }
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'properties': {
                            'alpha-bg': '1',
                            'alpha-brd': '1',
                            'bg': '#C65AA5',
                            'brd': '#B22087',
                            'brw': '0px',
                            'rd': '0px'
                        },
                        'propertiesSource': {'bg': 'value', 'brd': 'value', 'brw': 'value', 'rd': 'value'},
                        'groups': {}
                    },
                    'componentClassName': 'mobile.core.components.Page',
                    'pageId': '',
                    'compId': 'cxl8',
                    'styleType': 'custom',
                    'skin': 'wysiwyg.viewer.skins.page.LiftedShadowPageSkin'
                }
            },
            'preset': {'rect': {'width': 162, 'height': 190, 'x': 0, 'y': 1520}}
        }, {
            'id': 'page_17',
            'structure': {
                'type': 'Page',
                'components': [],
                'skin': 'wysiwyg.viewer.skins.page.LiftedShadowPageSkin',
                'layout': {
                    'width': 980,
                    'height': 500,
                    'x': 0,
                    'y': 0,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'anchors': [],
                    'fixedPosition': false
                },
                'componentType': 'mobile.core.components.Page',
                'data': {
                    'type': 'Page',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'title': '18',
                    'hideTitle': true,
                    'icon': '',
                    'descriptionSEO': '',
                    'metaKeywordsSEO': '',
                    'pageTitleSEO': '',
                    'pageUriSEO': '18',
                    'hidePage': false,
                    'underConstruction': false,
                    'tpaApplicationId': 0,
                    'pageSecurity': {'requireLogin': false},
                    'indexable': true,
                    'isLandingPage': false,
                    'pageBackgrounds': {
                        'desktop': {
                            'custom': true,
                            'ref': {
                                'type': 'BackgroundMedia',
                                'color': '{color_11}',
                                'alignType': 'top',
                                'fittingType': 'fill',
                                'scrollType': 'fixed'
                            },
                            'isPreset': false
                        },
                        'mobile': {
                            'custom': true,
                            'ref': {
                                'type': 'BackgroundMedia',
                                'color': '{color_11}',
                                'alignType': 'top',
                                'fittingType': 'fill',
                                'scrollType': 'fixed'
                            },
                            'isPreset': true
                        }
                    }
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'properties': {
                            'alpha-bg': '1',
                            'alpha-brd': '1',
                            'bg': '#7E0C6E',
                            'brd': '#C65AA5',
                            'brw': '0px',
                            'rd': '0px'
                        },
                        'propertiesSource': {'bg': 'value', 'brd': 'value', 'brw': 'value', 'rd': 'value'},
                        'groups': {}
                    },
                    'componentClassName': 'mobile.core.components.Page',
                    'pageId': '',
                    'compId': 'c15th',
                    'styleType': 'custom',
                    'skin': 'wysiwyg.viewer.skins.page.LiftedShadowPageSkin'
                }
            },
            'preset': {'rect': {'width': 162, 'height': 190, 'x': 162, 'y': 1520}}
        }, {
            'id': 'page_18',
            'structure': {
                'type': 'Page',
                'components': [],
                'skin': 'wysiwyg.viewer.skins.page.LiftedShadowPageSkin',
                'layout': {
                    'width': 980,
                    'height': 500,
                    'x': 0,
                    'y': 0,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'anchors': [],
                    'fixedPosition': false
                },
                'componentType': 'mobile.core.components.Page',
                'data': {
                    'type': 'Page',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'title': '19',
                    'hideTitle': true,
                    'icon': '',
                    'descriptionSEO': '',
                    'metaKeywordsSEO': '',
                    'pageTitleSEO': '',
                    'pageUriSEO': '19',
                    'hidePage': false,
                    'underConstruction': false,
                    'tpaApplicationId': 0,
                    'pageSecurity': {'requireLogin': false},
                    'indexable': true,
                    'isLandingPage': false,
                    'pageBackgrounds': {
                        'desktop': {
                            'custom': true,
                            'ref': {
                                'type': 'BackgroundMedia',
                                'color': '{color_11}',
                                'alignType': 'top',
                                'fittingType': 'fill',
                                'scrollType': 'fixed'
                            },
                            'isPreset': false
                        },
                        'mobile': {
                            'custom': true,
                            'ref': {
                                'type': 'BackgroundMedia',
                                'color': '{color_11}',
                                'alignType': 'top',
                                'fittingType': 'fill',
                                'scrollType': 'fixed'
                            },
                            'isPreset': true
                        }
                    }
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'properties': {
                            'alpha-bg': '1',
                            'alpha-brd': '1',
                            'bg': '#E21C21',
                            'brd': '#B7121B',
                            'brw': '0px',
                            'rd': '0px'
                        },
                        'propertiesSource': {'bg': 'value', 'brd': 'value', 'brw': 'value', 'rd': 'value'},
                        'groups': {}
                    },
                    'componentClassName': 'mobile.core.components.Page',
                    'pageId': '',
                    'compId': 'csm9',
                    'styleType': 'custom',
                    'skin': 'wysiwyg.viewer.skins.page.LiftedShadowPageSkin'
                }
            },
            'preset': {'rect': {'width': 162, 'height': 190, 'x': 0, 'y': 1710}}
        }, {
            'id': 'page_19',
            'structure': {
                'type': 'Page',
                'components': [],
                'skin': 'wysiwyg.viewer.skins.page.LiftedShadowPageSkin',
                'layout': {
                    'width': 980,
                    'height': 500,
                    'x': 0,
                    'y': 0,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'anchors': [],
                    'fixedPosition': false
                },
                'componentType': 'mobile.core.components.Page',
                'data': {
                    'type': 'Page',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'title': '20',
                    'hideTitle': true,
                    'icon': '',
                    'descriptionSEO': '',
                    'metaKeywordsSEO': '',
                    'pageTitleSEO': '',
                    'pageUriSEO': '20',
                    'hidePage': false,
                    'underConstruction': false,
                    'tpaApplicationId': 0,
                    'pageSecurity': {'requireLogin': false},
                    'indexable': true,
                    'isLandingPage': false,
                    'pageBackgrounds': {
                        'desktop': {
                            'custom': true,
                            'ref': {
                                'type': 'BackgroundMedia',
                                'color': '{color_11}',
                                'alignType': 'top',
                                'fittingType': 'fill',
                                'scrollType': 'fixed'
                            },
                            'isPreset': false
                        },
                        'mobile': {
                            'custom': true,
                            'ref': {
                                'type': 'BackgroundMedia',
                                'color': '{color_11}',
                                'alignType': 'top',
                                'fittingType': 'fill',
                                'scrollType': 'fixed'
                            },
                            'isPreset': true
                        }
                    }
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'properties': {
                            'alpha-bg': '1',
                            'alpha-brd': '1',
                            'bg': '#B7121B',
                            'brd': '#E21C21',
                            'brw': '0px',
                            'rd': '0px'
                        },
                        'propertiesSource': {'bg': 'value', 'brd': 'value', 'brw': 'value', 'rd': 'value'},
                        'groups': {}
                    },
                    'componentClassName': 'mobile.core.components.Page',
                    'pageId': '',
                    'compId': 'c1wsg',
                    'styleType': 'custom',
                    'skin': 'wysiwyg.viewer.skins.page.LiftedShadowPageSkin'
                }
            },
            'preset': {'rect': {'width': 162, 'height': 190, 'x': 162, 'y': 1710}}
        }, {
            'id': 'page_20',
            'structure': {
                'type': 'Page',
                'components': [],
                'skin': 'wysiwyg.viewer.skins.page.ThreeDeePageSkin',
                'layout': {
                    'width': 980,
                    'height': 500,
                    'x': 0,
                    'y': 0,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'anchors': [],
                    'fixedPosition': false
                },
                'componentType': 'mobile.core.components.Page',
                'data': {
                    'type': 'Page',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'title': '21',
                    'hideTitle': true,
                    'icon': '',
                    'descriptionSEO': '',
                    'metaKeywordsSEO': '',
                    'pageTitleSEO': '',
                    'pageUriSEO': '21',
                    'hidePage': false,
                    'underConstruction': false,
                    'tpaApplicationId': 0,
                    'pageSecurity': {'requireLogin': false},
                    'indexable': true,
                    'isLandingPage': false,
                    'pageBackgrounds': {
                        'desktop': {
                            'custom': true,
                            'ref': {
                                'type': 'BackgroundMedia',
                                'color': '{color_11}',
                                'alignType': 'top',
                                'fittingType': 'fill',
                                'scrollType': 'fixed'
                            },
                            'isPreset': false
                        },
                        'mobile': {
                            'custom': true,
                            'ref': {
                                'type': 'BackgroundMedia',
                                'color': '{color_11}',
                                'alignType': 'top',
                                'fittingType': 'fill',
                                'scrollType': 'fixed'
                            },
                            'isPreset': true
                        }
                    }
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'properties': {
                            'alpha-bg': '1',
                            'alpha-brd': '1',
                            'bg': '#3D9BE9',
                            'brd': '#2B6CA3',
                            'brw': '0px',
                            'rd': '10px'
                        },
                        'propertiesSource': {'bg': 'value', 'brd': 'value', 'brw': 'value', 'rd': 'value'},
                        'groups': {}
                    },
                    'componentClassName': 'mobile.core.components.Page',
                    'pageId': '',
                    'compId': 'c10rd',
                    'styleType': 'custom',
                    'skin': 'wysiwyg.viewer.skins.page.ThreeDeePageSkin'
                }
            },
            'preset': {'rect': {'width': 162, 'height': 190, 'x': 0, 'y': 1900}}
        }, {
            'id': 'page_21',
            'structure': {
                'type': 'Page',
                'components': [],
                'skin': 'wysiwyg.viewer.skins.page.ThreeDeePageSkin',
                'layout': {
                    'width': 980,
                    'height': 500,
                    'x': 0,
                    'y': 0,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'anchors': [],
                    'fixedPosition': false
                },
                'componentType': 'mobile.core.components.Page',
                'data': {
                    'type': 'Page',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'title': '22',
                    'hideTitle': true,
                    'icon': '',
                    'descriptionSEO': '',
                    'metaKeywordsSEO': '',
                    'pageTitleSEO': '',
                    'pageUriSEO': '22',
                    'hidePage': false,
                    'underConstruction': false,
                    'tpaApplicationId': 0,
                    'pageSecurity': {'requireLogin': false},
                    'indexable': true,
                    'isLandingPage': false,
                    'pageBackgrounds': {
                        'desktop': {
                            'custom': true,
                            'ref': {
                                'type': 'BackgroundMedia',
                                'color': '{color_11}',
                                'alignType': 'top',
                                'fittingType': 'fill',
                                'scrollType': 'fixed'
                            },
                            'isPreset': false
                        },
                        'mobile': {
                            'custom': true,
                            'ref': {
                                'type': 'BackgroundMedia',
                                'color': '{color_11}',
                                'alignType': 'top',
                                'fittingType': 'fill',
                                'scrollType': 'fixed'
                            },
                            'isPreset': true
                        }
                    }
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'properties': {
                            'alpha-bg': '1',
                            'alpha-brd': '1',
                            'bg': '#2B6CA3',
                            'brd': '#3D9BE9',
                            'brw': '0px',
                            'rd': '10px'
                        },
                        'propertiesSource': {'bg': 'value', 'brd': 'value', 'brw': 'value', 'rd': 'value'},
                        'groups': {}
                    },
                    'componentClassName': 'mobile.core.components.Page',
                    'pageId': '',
                    'compId': 'cagn',
                    'styleType': 'custom',
                    'skin': 'wysiwyg.viewer.skins.page.ThreeDeePageSkin'
                }
            },
            'preset': {'rect': {'width': 162, 'height': 190, 'x': 162, 'y': 1900}}
        }, {
            'id': 'page_22',
            'structure': {
                'type': 'Page',
                'components': [],
                'skin': 'wysiwyg.viewer.skins.page.ShinyIPageSkin',
                'layout': {
                    'width': 980,
                    'height': 500,
                    'x': 0,
                    'y': 0,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'anchors': [],
                    'fixedPosition': false
                },
                'componentType': 'mobile.core.components.Page',
                'data': {
                    'type': 'Page',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'title': '23',
                    'hideTitle': true,
                    'icon': '',
                    'descriptionSEO': '',
                    'metaKeywordsSEO': '',
                    'pageTitleSEO': '',
                    'pageUriSEO': '23',
                    'hidePage': false,
                    'underConstruction': false,
                    'tpaApplicationId': 0,
                    'pageSecurity': {'requireLogin': false},
                    'indexable': true,
                    'isLandingPage': false,
                    'pageBackgrounds': {
                        'desktop': {
                            'custom': true,
                            'ref': {
                                'type': 'BackgroundMedia',
                                'color': '{color_11}',
                                'alignType': 'top',
                                'fittingType': 'fill',
                                'scrollType': 'fixed'
                            },
                            'isPreset': false
                        },
                        'mobile': {
                            'custom': true,
                            'ref': {
                                'type': 'BackgroundMedia',
                                'color': '{color_11}',
                                'alignType': 'top',
                                'fittingType': 'fill',
                                'scrollType': 'fixed'
                            },
                            'isPreset': true
                        }
                    }
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'properties': {
                            'alpha-bg': '1',
                            'alpha-brd': '1',
                            'bg': '#FFFFFF',
                            'boxShadowToggleOn-shd': 'true',
                            'brd': '#000000',
                            'brw': '0px',
                            'rd': '0px',
                            'shd': '0px 1px 2px 0px rgba(0,0,0,0.4)'
                        },
                        'propertiesSource': {
                            'bg': 'value',
                            'brd': 'value',
                            'brw': 'value',
                            'rd': 'value',
                            'shd': 'value'
                        },
                        'groups': {}
                    },
                    'componentClassName': 'mobile.core.components.Page',
                    'pageId': '',
                    'compId': 'c21mi',
                    'styleType': 'custom',
                    'skin': 'wysiwyg.viewer.skins.page.ShinyIPageSkin'
                }
            },
            'preset': {'rect': {'width': 162, 'height': 190, 'x': 0, 'y': 2090}}
        }, {
            'id': 'page_23',
            'structure': {
                'type': 'Page',
                'components': [],
                'skin': 'wysiwyg.viewer.skins.page.ShinyIPageSkin',
                'layout': {
                    'width': 980,
                    'height': 500,
                    'x': 0,
                    'y': 0,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'anchors': [],
                    'fixedPosition': false
                },
                'componentType': 'mobile.core.components.Page',
                'data': {
                    'type': 'Page',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'title': '24',
                    'hideTitle': true,
                    'icon': '',
                    'descriptionSEO': '',
                    'metaKeywordsSEO': '',
                    'pageTitleSEO': '',
                    'pageUriSEO': '22',
                    'hidePage': false,
                    'underConstruction': false,
                    'tpaApplicationId': 0,
                    'pageSecurity': {'requireLogin': false},
                    'indexable': true,
                    'isLandingPage': false,
                    'pageBackgrounds': {
                        'desktop': {
                            'custom': true,
                            'ref': {
                                'type': 'BackgroundMedia',
                                'color': '{color_11}',
                                'alignType': 'top',
                                'fittingType': 'fill',
                                'scrollType': 'fixed'
                            },
                            'isPreset': false
                        },
                        'mobile': {
                            'custom': true,
                            'ref': {
                                'type': 'BackgroundMedia',
                                'color': '{color_11}',
                                'alignType': 'top',
                                'fittingType': 'fill',
                                'scrollType': 'fixed'
                            },
                            'isPreset': true
                        }
                    }
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'properties': {
                            'alpha-bg': '1',
                            'alpha-brd': '1',
                            'bg': '#F1F1F1',
                            'boxShadowToggleOn-shd': 'true',
                            'brd': '#000000',
                            'brw': '0px',
                            'rd': '0px',
                            'shd': '0px 1px 2px 0px rgba(0,0,0,0.4)'
                        },
                        'propertiesSource': {
                            'bg': 'value',
                            'brd': 'value',
                            'brw': 'value',
                            'rd': 'value',
                            'shd': 'value'
                        },
                        'groups': {}
                    },
                    'componentClassName': 'mobile.core.components.Page',
                    'pageId': '',
                    'compId': 'c1a8d',
                    'styleType': 'custom',
                    'skin': 'wysiwyg.viewer.skins.page.ShinyIPageSkin'
                }
            },
            'preset': {'rect': {'width': 162, 'height': 190, 'x': 162, 'y': 2090}}
        }],
        'compTypes': ['mobile.core.components.Page']
    }
}
