import React from 'react';
import { Text } from '@wix/wix-base-ui';
import * as symbols from '@wix/santa-editor-symbols';

export const Bullets = React.memo<{ bullets: string[] }>(({ bullets }) => {
  return (
    <div className="em-marketing-bullets">
      {bullets.map((bullet: string) => (
        <div className="em-bullet" key={bullet}>
          <div>
            <symbols.symbol
              name="promoteCheckmark"
              className="em-bullet-point"
            />
          </div>
          <Text skin="secondary" size="small" enableEllipsis={false}>
            {bullet}
          </Text>
        </div>
      ))}
    </div>
  );
});

Bullets.displayName = 'Bullets';
