import _ from 'lodash';
import { SectionsApiKey } from '@/apis';
import * as core from '@/core';
import constants from '@/constants';
import * as helpIds from '@/helpIds';
import * as stateManagement from '@/stateManagement';
import {
  isSectionsOnStageEnabled,
  shouldTreatAsSection,
} from '@/sectionsOnStage';
import * as util from '@/util';
import * as boxSlideshow from '@/boxSlideshow';
import * as serializedComponentPlugins from '../../serializedComponentPlugins';
import * as collectThemeUtils from '../../../utils/theme/collectThemeUtils';
import { adaptMenuComponentStructure } from '@/menu';
import { addPanelUtils } from '@/addPanelInfra';
import type { ClipboardCompValue } from '@/clipboard';

import type { EditorAPI } from '@/editorAPI';
import type {
  CompRef,
  CompStructure,
  CompData,
  CompLayout,
  Point,
} from 'types/documentServices';

const { isSectionsEnabled } = util.sections;

const NORMALIZE_CROSSITE_COMPONENT_BY_COMP_TYPE: Record<
  string,
  (editorAPI: EditorAPI, compStructure: CompStructure) => CompStructure
> = {
  'wysiwyg.viewer.components.menus.DropDownMenu': adaptMenuComponentStructure,
  'wysiwyg.common.components.verticalmenu.viewer.VerticalMenu':
    adaptMenuComponentStructure,
  'wysiwyg.viewer.components.mobile.TinyMenu': adaptMenuComponentStructure,
  'wysiwyg.viewer.components.ExpandableMenu': adaptMenuComponentStructure,
  'wixui.StylableHorizontalMenu': adaptMenuComponentStructure,
};

export enum PasteActionOrigins {
  Duplicate = 'duplicate',
  Paste = 'paste',
  AddPanelAdd = 'addPanelAdd',
}

const { getStyleId, getFirstAppWidget } = stateManagement.components.selectors;
const { isInInteractionMode, getInteractionTriggerRef } =
  stateManagement.interactions.selectors;
const { showUserActionNotification } = stateManagement.notifications.actions;

export interface CopyMetaData {
  guid: string;
  copiedOnMobile: boolean;
  metaSiteId: string;
  siteUploadToken: string;
  siteWidth?: number;
}

function createCopyPasteUtils(editorAPI: EditorAPI) {
  const { boxSlideShowUtils } = boxSlideshow.utils;

  function addStyleIdToStructure(compRef: AnyFixMe, structure: AnyFixMe) {
    if (structure?.style?.styleType === 'system') {
      const styleId = getStyleId(compRef, editorAPI.dsRead);
      if (styleId) {
        structure.style = styleId;
      }
    }
  }

  function previousStyleIdEnricher(compStructure: AnyFixMe) {
    if (compStructure.styleId) {
      compStructure.previousStyleId = compStructure.styleId;
    }
  }

  function getCommonContainerWithModes(compRefs: AnyFixMe) {
    if (compRefs && compRefs.length > 0) {
      const firstContainerRef =
        editorAPI.components.modes.getFirstAncestorWithActiveModes(
          _.head(compRefs),
        );
      const equalsFirst = _.curry(_.isEqual)(firstContainerRef);
      if (
        _(compRefs)
          .tail()
          .map(editorAPI.components.modes.getFirstAncestorWithActiveModes)
          .every(equalsFirst)
      ) {
        return firstContainerRef;
      }
    }
  }

  function getAdditionalDataToAddToClipboard(compRef: CompRef) {
    let originalParent = null;
    let originalGrandParent = null;
    let relativeToParentPosition = null;

    if (editorAPI.components.canBeMasterChild(compRef)) {
      //has a slideshow as an ancient parent
      const slideShowParent = boxSlideShowUtils.getSlideShowParent(
        editorAPI,
        compRef,
      );
      const slideShowSlideParent = boxSlideShowUtils.getSlideShowSlideParent(
        editorAPI,
        compRef,
      );
      if (slideShowSlideParent) {
        originalParent = slideShowSlideParent;
        originalGrandParent = slideShowParent;

        const compLayout =
          editorAPI.components.layout.getRelativeToStructure(compRef);
        const slideLayout =
          editorAPI.components.layout.getRelativeToStructure(
            slideShowSlideParent,
          );
        relativeToParentPosition = {
          x: compLayout.x - slideLayout.x,
          y: compLayout.y - slideLayout.y,
        };
      }
    }

    const appWidgetAncestor = getFirstAppWidget(compRef, editorAPI.dsRead);
    const primaryConnection =
      editorAPI.dsRead.platform.controllers.connections.getPrimaryConnection(
        compRef,
      );
    const componentIsDescendantOfWidget =
      appWidgetAncestor &&
      primaryConnection &&
      _.isEqual(appWidgetAncestor, primaryConnection.controllerRef);

    return Object.assign(
      {
        compRef,
      },
      relativeToParentPosition ? { relativeToParentPosition } : null,
      originalParent ? { originalParent } : null,
      originalGrandParent ? { originalGrandParent } : null,
      componentIsDescendantOfWidget ? { appWidgetAncestor } : null,
    );
  }

  function getSnugLayoutRelativeToStructure(
    targetContainer: AnyFixMe,
    snugLayoutRelativeToScreen: AnyFixMe,
  ) {
    if (!editorAPI.utils.isPage(targetContainer)) {
      const containerLayoutRelativeToScreen =
        editorAPI.components.layout.getRelativeToScreen(targetContainer);
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      return _.assign({}, snugLayoutRelativeToScreen, {
        x: snugLayoutRelativeToScreen.x - containerLayoutRelativeToScreen.x,
        y: snugLayoutRelativeToScreen.y - containerLayoutRelativeToScreen.y,
      });
    }
    return snugLayoutRelativeToScreen;
  }

  function prepareComponentsForCopy(
    orderedComponents: AnyFixMe,
    config: AnyFixMe,
    pasteLogicContext: AnyFixMe,
  ) {
    const snugLayoutRelativeToScreen =
      editorAPI.components.layout.getRelativeToScreen(orderedComponents);
    const targetContainer = editorAPI.pages.getPrimaryContainer();
    const snugLayoutRelativeToStructure = getSnugLayoutRelativeToStructure(
      targetContainer,
      snugLayoutRelativeToScreen,
    );
    const originalScrollPosition = editorAPI.ui.scroll.getScroll();

    pasteLogicContext.setPasteData(
      snugLayoutRelativeToStructure,
      targetContainer.id,
      originalScrollPosition,
    );

    const serializedComponents = orderedComponents.reduce(
      (serialized: AnyFixMe, compRef: AnyFixMe) => {
        const useOriginalLanguage = true;

        if (stateManagement.components.selectors.isReferredComponent(compRef)) {
          compRef = editorAPI.components.getContainer(compRef);
        }

        // eslint-disable-next-line @wix/santa-editor/dsReadSerializeIsTooExpensive
        let serializedComp = editorAPI.components.serialize(
          compRef,
          null,
          false,
          config.shouldMaintainIds,
          null,
          previousStyleIdEnricher,
          useOriginalLanguage,
        );
        serializedComp.originCompId = compRef.id;
        serializedComp._dataId = compRef.id;

        delete serializedComp.translations;
        delete serializedComp.anchor?.urlFragment;
        delete serializedComp.data?.urlFragment;

        addStyleIdToStructure(compRef, serializedComp);

        const compLayoutRelativeToScreen =
          editorAPI.components.layout.getRelativeToScreen(compRef);
        serializedComp =
          serializedComponentPlugins.runCopyPluginOnSerializedComponent(
            serializedComp,
            compRef,
            editorAPI,
          );

        const additionalData = getAdditionalDataToAddToClipboard(compRef);

        serialized.components.push(serializedComp);
        serialized.componentsData[serializedComp._dataId] = Object.assign(
          {},
          {
            positionRelativeToSnug: {
              x: compLayoutRelativeToScreen.x - snugLayoutRelativeToScreen.x,
              y: compLayoutRelativeToScreen.y - snugLayoutRelativeToScreen.y,
            },
            isShowOnAllPages: editorAPI.components.isShowOnAllPages(compRef),
          },
          additionalData,
        );

        return serialized;
      },
      { components: [], componentsData: {} },
    );

    if (util.isCustomOrAdvancedMenuEnabled()) {
      serializedComponents.components = normalizeComponents(
        serializedComponents.components,
      );
    }

    const clipboardDataForCopy: ClipboardCompValue = {
      snugLayoutRelativeToStructure,
      originalScrollPosition,
      theme: collectThemeUtils.collectThemeFromSerializedStructure(
        editorAPI,
        serializedComponents.components,
      ),
      components: serializedComponents.components,
      componentsData: serializedComponents.componentsData,
    };

    const commonContainerWithModes =
      getCommonContainerWithModes(orderedComponents);

    return Object.assign(
      clipboardDataForCopy,
      commonContainerWithModes
        ? { containerWithModes: commonContainerWithModes }
        : null,
    );
  }

  function hasComponentInTree(components: AnyFixMe, predicate: AnyFixMe) {
    for (let i = 0; i < components.length; i++) {
      const component = components[i];
      if (predicate(component)) {
        return true;
      }
      if (
        component.components &&
        hasComponentInTree(component.components, predicate)
      ) {
        return true;
      }
    }

    return false;
  }

  function filterComponentsTree(components: AnyFixMe, predicate: AnyFixMe) {
    return components.reduce((accepted: AnyFixMe, component: AnyFixMe) => {
      if (!predicate(component)) {
        return accepted;
      }
      if (component.components) {
        component = Object.assign({}, component, {
          components: filterComponentsTree(component.components, predicate),
        });
      }

      accepted.push(component);
      return accepted;
    }, []);
  }

  function normalizeComponents(components: AnyFixMe) {
    return components.map((component: AnyFixMe) => {
      const normalizedComponent =
        NORMALIZE_CROSSITE_COMPONENT_BY_COMP_TYPE[component.componentType]?.(
          editorAPI,
          component,
        ) || component;

      const children = component.components
        ? normalizeComponents(component.components)
        : component.components;

      return {
        ...normalizedComponent,
        components: children,
      };
    });
  }

  function getMetaDataForCopy(): CopyMetaData {
    return {
      guid: util.guidUtils.getGUID(),
      copiedOnMobile: editorAPI.isMobileEditor(),
      metaSiteId: editorAPI.dsRead.generalInfo.getMetaSiteId(),
      siteUploadToken: editorAPI.mediaServices.getSiteUploadToken(),
      siteWidth: editorAPI.site.getWidth(),
    };
  }

  function isCrossSiteDuplicatable(component: AnyFixMe) {
    return editorAPI.components.is.crossSiteDuplicatableByStructure(component);
  }

  function getCrossSiteDuplicatableComponents(components: AnyFixMe) {
    return filterComponentsTree(components, isCrossSiteDuplicatable);
  }

  function hasCrossSiteUnsupportedComponents(components: AnyFixMe) {
    return hasComponentInTree(
      components,
      (component: AnyFixMe) => !isCrossSiteDuplicatable(component),
    );
  }

  function hasCrossSiteUnsupportedPageComponents(page: AnyFixMe) {
    const { components, mobileComponents } = page;
    return (
      hasCrossSiteUnsupportedComponents(components) ||
      hasCrossSiteUnsupportedComponents(mobileComponents)
    );
  }

  function hasSectionsComponents(page: CompStructure): boolean {
    const { components } = page;

    return components.some(
      (component) =>
        (component as CompStructure)?.componentType ===
        constants.COMP_TYPES.SECTION,
    );
  }

  function isAllowedToPaste(
    components: ClipboardCompValue['components'],
    componentsData: ClipboardCompValue['componentsData'],
  ) {
    if (!components.length) {
      return false;
    }

    const isMobileDisabledPaste =
      editorAPI.isMobileEditor() &&
      components.some(
        (compDef: AnyFixMe) =>
          !editorAPI.components.is.allowedMobileOnlyByType(
            compDef?.componentType,
          ),
      );
    const isAllowedToPastePlugins =
      editorAPI.pluginService.getAllPluginsOfType(
        editorAPI.pluginService.pluginConstants.ALLOWED_TO_PASTE,
      ) || {};

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/every
    const allowedByPlugin = _.every(isAllowedToPastePlugins, (plugin) =>
      plugin(editorAPI, { componentsData, components }),
    );

    return !isMobileDisabledPaste && allowedByPlugin;
  }

  function getDefaultPastePosition(
    action: PasteActionOrigins,
    snugLayoutRelativeToStructure: AnyFixMe,
    guid: AnyFixMe,
    primaryContainerId: AnyFixMe,
    recalculateOnViewportTouch: AnyFixMe,
    scroll: AnyFixMe,
    components: CompStructure[] = [],
  ) {
    let defaultPastePosition;
    if (
      [PasteActionOrigins.Paste, PasteActionOrigins.Duplicate].includes(action)
    ) {
      const logicContext = {
        paste: editorAPI.pasteLogic.pasteContext,
        duplicate: editorAPI.pasteLogic.duplicateContext,
      }[action as PasteActionOrigins.Paste | PasteActionOrigins.Duplicate];
      defaultPastePosition = logicContext.getPastePosition(
        editorAPI,
        snugLayoutRelativeToStructure,
        primaryContainerId,
        recalculateOnViewportTouch,
        components,
      );
      defaultPastePosition.x -= scroll.scrollLeft;
      defaultPastePosition.y -= scroll.scrollTop;
    }

    if (action === PasteActionOrigins.AddPanelAdd) {
      const layout = {
        width: snugLayoutRelativeToStructure.bounding.width,
        height: snugLayoutRelativeToStructure.bounding.height,
      };
      defaultPastePosition =
        editorAPI.pasteLogic.addPanelPasteLogic.getPastePosition(
          editorAPI,
          layout,
          primaryContainerId,
          guid,
        );

      if (util.sections.isSectionsEnabled()) {
        defaultPastePosition.y -= editorAPI.scroll.get().scrollTop;
      }
    }
    return defaultPastePosition;
  }

  function isValidRCPosition(position: AnyFixMe) {
    return (
      position && position.hasOwnProperty('x') && position.hasOwnProperty('y')
    );
  }

  function shouldBePastedToMasterPage(componentType: AnyFixMe) {
    const COMPS_TO_PASTE_IN_MASTE_PAGE = [
      'wysiwyg.viewer.components.tpapps.TPAGluedWidget',
    ];
    return COMPS_TO_PASTE_IN_MASTE_PAGE.includes(componentType);
  }

  function getComponentStageLayout(
    component: AnyFixMe,
    pastePosition: AnyFixMe,
    rightClickPosition: AnyFixMe,
    pasteContainerRef: AnyFixMe,
  ) {
    let layout;

    if (!rightClickPosition) {
      layout = {
        x: pastePosition.x,
        y: pastePosition.y,
        width: component.layout.width,
        height: component.layout.height,
      };

      return layout;
    }

    layout = {
      x: rightClickPosition.x,
      y: rightClickPosition.y,
      width: component.layout.width,
      height: component.layout.height,
    };

    if (!editorAPI.utils.isPage(pasteContainerRef)) {
      const pasteContainerPos =
        editorAPI.components.layout.getRelativeToScreenConsideringScroll(
          pasteContainerRef,
        );
      layout.x -= pasteContainerPos.x;
      layout.y -= pasteContainerPos.y;
    }

    return layout;
  }

  function isCopiedItemFromWithinASlideShowComponent(
    componentData: AnyFixMe,
    layoutRelativeToStructure: AnyFixMe,
  ) {
    if (
      componentData.originalGrandParent &&
      editorAPI.components.is.slidesContainer(componentData.originalGrandParent)
    ) {
      const componentsAtPosition =
        editorAPI.components.get.byXYRelativeToStructure(
          layoutRelativeToStructure.x,
          layoutRelativeToStructure.y,
        );
      return componentsAtPosition.includes(componentData.originalGrandParent);
    }
    return false;
  }

  function getValidRootContaiterRef(appWidgetRootRef: AnyFixMe) {
    const { findCurrentSlide } = stateManagement.boxSlideShow.selectors;
    return editorAPI.components.is.slidesContainer(appWidgetRootRef)
      ? findCurrentSlide(editorAPI.dsRead, appWidgetRootRef)
      : appWidgetRootRef;
  }

  function getValidOverrideParentForAppWidgetChildren(
    candidateRef: AnyFixMe,
    candidateStatus: AnyFixMe,
    appWidgetRef: AnyFixMe,
  ) {
    const { AttachHandler } = core.utils;
    const componentSelectors = stateManagement.components.selectors;
    const [appWidgetRoot] =
      editorAPI.dsRead.deprecatedOldBadPerformanceApis.components.getChildren(
        appWidgetRef,
      );

    if (candidateStatus === AttachHandler.ATTACH_CANDIDATE_STATUSES.VALID) {
      const appWidgetCandidateAncestor = componentSelectors.getFirstAppWidget(
        candidateRef,
        editorAPI.dsRead,
      );
      return _.isEqual(appWidgetCandidateAncestor, appWidgetRef)
        ? candidateRef
        : getValidRootContaiterRef(appWidgetRoot);
    }
    return appWidgetRoot;
  }

  function getOverridePastePosition({
    componentData,
    layoutRelativeToStructure,
    componentLayout,
    positionToPaste,
    rightClickPosition,
    originAction,
    component,
  }: {
    component?: CompStructure;
    componentData: CompData;
    layoutRelativeToStructure: CompLayout;
    componentLayout: CompLayout;
    positionToPaste: { x: number; y: number };
    rightClickPosition: { x: number; y: number } | null;
    originAction: PasteActionOrigins;
  }) {
    const { findCurrentSlide } = stateManagement.boxSlideShow.selectors;

    if (
      isCopiedItemFromWithinASlideShowComponent(
        componentData,
        layoutRelativeToStructure,
      )
    ) {
      const currentSlide = findCurrentSlide(
        editorAPI.dsRead,
        componentData.originalGrandParent,
      );
      return _.isEqual(currentSlide, componentData.originalParent)
        ? null
        : componentData.relativeToParentPosition;
    } else if (componentData.appWidgetAncestor) {
      const appWidgetLayout =
        editorAPI.components.layout.getRelativeToStructure(
          componentData.appWidgetAncestor,
        );
      return componentLayout.width + positionToPaste.x > appWidgetLayout.width
        ? {
            x: appWidgetLayout.width - componentLayout.width,
            y: positionToPaste.y,
          }
        : null;
    }

    if (
      isSectionsEnabled() &&
      (editorAPI.sections.isSection(componentData.compRef) ||
        editorAPI.sections.isSectionByCompType(component?.componentType))
    ) {
      if (originAction !== PasteActionOrigins.Duplicate) {
        const override = calcSectionPositionOverride(
          editorAPI,
          rightClickPosition,
        );

        if (override) {
          return {
            ...positionToPaste,
            ...override,
          };
        }
      }

      const compLayout = editorAPI.components.layout.get_rect(
        componentData.compRef,
      );

      return {
        ...positionToPaste,
        y: compLayout.y + compLayout.height,
      };
    }

    if (
      isSectionsOnStageEnabled() &&
      shouldTreatAsSection(editorAPI, componentData.compRef) &&
      originAction === PasteActionOrigins.Duplicate
    ) {
      const compLayout = editorAPI.components.layout.get_rect(
        componentData.compRef,
      );

      return {
        ...positionToPaste,
        y: compLayout.y + compLayout.height,
      };
    }

    return null;
  }

  function getOverrideParentToPasteInto(
    component: AnyFixMe,
    componentData: AnyFixMe,
    layoutRelativeToStructure: AnyFixMe,
    layout: AnyFixMe,
    pastePosition: Point,
  ) {
    const state = editorAPI.store.getState();
    if (isInInteractionMode(state)) {
      return getInteractionTriggerRef(state);
    }
    if (
      isCopiedItemFromWithinASlideShowComponent(
        componentData,
        layoutRelativeToStructure,
      )
    ) {
      const { findCurrentSlide } = stateManagement.boxSlideShow.selectors;
      return findCurrentSlide(
        editorAPI.dsRead,
        componentData.originalGrandParent,
      );
    }

    const { AttachHandler } = core.utils;
    const attachUtil = new AttachHandler(
      editorAPI,
      {
        comp: component,
        layout: _.pick(layout, ['width', 'height']),
      },
      AttachHandler.TYPES.STRUCTURE,
    );

    const { candidateRef, candidateStatus } =
      attachUtil.getCurrentAttachCandidate(layout, null, pastePosition);

    if (componentData.appWidgetAncestor && !util.appStudioUtils.isAppStudio()) {
      return getValidOverrideParentForAppWidgetChildren(
        candidateRef,
        candidateStatus,
        componentData.appWidgetAncestor,
      );
    }

    if (
      candidateStatus === AttachHandler.ATTACH_CANDIDATE_STATUSES.INVALID ||
      (isSectionsEnabled() &&
        editorAPI.host.getAPI(SectionsApiKey).isSection(componentData.compRef))
    ) {
      return editorAPI.pages.getPrimaryContainer();
    }

    return candidateRef;
  }

  function getPasteContainers(
    component: AnyFixMe,
    componentData: AnyFixMe,
    primaryContainerId: AnyFixMe,
    rightClickPosition: AnyFixMe,
    defaultPastePosition: AnyFixMe,
  ) {
    if (shouldBePastedToMasterPage(component.componentType)) {
      const masterPage = editorAPI.dsRead.pages.getReference(
        editorAPI.pages.getMasterPageId(),
      );
      return {
        overridePasteContainer: masterPage,
        originalPasteContainer: masterPage,
      };
    }

    const originalPasteContainer =
      editorAPI.dsRead.pages.getReference(primaryContainerId);
    const positionToPaste = _.clone(rightClickPosition || defaultPastePosition);
    const layout = getComponentStageLayout(
      component,
      positionToPaste,
      rightClickPosition,
      originalPasteContainer,
    );
    const overridePasteContainer = getOverrideParentToPasteInto(
      component,
      componentData,
      defaultPastePosition,
      layout,
      positionToPaste,
    );

    return { overridePasteContainer, originalPasteContainer };
  }

  // For now it checks only data format, changed after refactoring
  function isValidClipboard(clipboardData: ClipboardCompValue) {
    if (!clipboardData) {
      return false;
    }

    const { components, componentsData } = clipboardData;
    if (components && !componentsData) {
      return false;
    }

    return true;
  }

  function showCantPasteInMobileCopiedDesktopNotification() {
    editorAPI.store.dispatch(
      showUserActionNotification({
        message: 'Notifications_CantPasteElements_MobileToDesktop_Text',
        title: 'Notifications_CantPasteElements_MobileToDesktop_Text',
        type: constants.NOTIFICATIONS.TYPES.WARNING,
        link: {
          caption: 'Notifications_Learn_More_Link',
          onNotificationLinkClick: () => {
            editorAPI.panelManager.openHelpCenter(
              helpIds.MOBILE_ONLY.CANT_PERFORM_COPY_PASTE_CUT,
            );
          },
        },
      }),
    );
  }

  function showCantPasteInDesktopCopiedMobileNotification() {
    editorAPI.store.dispatch(
      showUserActionNotification({
        message: 'Notifications_Mobile_CantPasteElements_Text',
        title: 'Notifications_Mobile_CantPasteElements_Text',
        type: constants.NOTIFICATIONS.TYPES.WARNING,
        link: {
          caption: 'Notifications_Learn_More_Link',
          onNotificationLinkClick: () => {
            editorAPI.panelManager.openHelpCenter(
              helpIds.MOBILE_ONLY.CANT_PERFORM_COPY_PASTE_CUT,
            );
          },
        },
      }),
    );
  }

  function calcSectionPositionOverride(
    editorAPI: EditorAPI,
    rightClickPosition: {
      x: number;
      y: number;
    },
  ) {
    const stageWidth = stateManagement.domMeasurements.selectors.getStageLayout(
      editorAPI.store.getState(),
    ).width;

    let targetComps: CompRef[];

    if (rightClickPosition) {
      targetComps = editorAPI.selection.getComponentsByXY(
        Math.min(rightClickPosition.x, stageWidth - 1),
        rightClickPosition.y,
      );
    } else {
      const compsInCenterOfPreview =
        addPanelUtils.getComponentsInTheCenterOfPreview(editorAPI);
      targetComps = compsInCenterOfPreview.length
        ? compsInCenterOfPreview
        : [
            editorAPI.sections
              .getPageSectionsSortedByStageOrder(
                editorAPI.pages.getCurrentPage(),
              )
              .pop(),
          ];
    }

    const targetComp = targetComps.find((compRef) =>
      editorAPI.host.getAPI(SectionsApiKey).isSectionLike(compRef),
    );

    if (!targetComp) return null;

    let yOverride;

    const targetCompLayout = editorAPI.components.layout.get_rect(targetComp);
    const isHeader = targetComp.id === 'SITE_HEADER';
    const isFooter = targetComp.id === 'SITE_FOOTER';
    const isSection = editorAPI.host
      .getAPI(SectionsApiKey)
      .isSection(targetComp);

    if (isHeader) {
      yOverride = 0;
    }

    if (isFooter) {
      const headerLayout = editorAPI.components.layout.get_size(
        editorAPI.siteSegments.getHeader(),
      );
      yOverride = targetCompLayout.y - headerLayout.height;
    }

    if (isSection) {
      yOverride = targetCompLayout.y + targetCompLayout.height;
    }

    return {
      y: yOverride,
    };
  }

  return {
    addStyleIdToStructure,
    prepareComponentsForCopy,

    getCrossSiteDuplicatableComponents,

    hasCrossSiteUnsupportedComponents,
    hasCrossSiteUnsupportedPageComponents,
    hasSectionsComponents,

    normalizeComponents,

    hasComponentInTree,
    filterComponentsTree,

    isAllowedToPaste,
    isValidRCPosition,
    getDefaultPastePosition,

    getComponentStageLayout,

    getPasteContainers,
    getOverridePastePosition,
    getMetaDataForCopy,
    isValidClipboard,

    showCantPasteInMobileCopiedDesktopNotification,
    showCantPasteInDesktopCopiedMobileNotification,
  };
}

export default createCopyPasteUtils;
