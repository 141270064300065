// @ts-nocheck
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import DotsButton from './dotsButton';
import { ContextMenu, ContextMenuAction, TextLabel } from '@wix/wix-base-ui';

//TYPE WAS GENERATED, remove this line when reviewed
interface Props {
  title?: string;
  dotsActions?: Array<{
    id?: string;
    label?: string;
    onClick?: FunctionFixMe;
  }>;
  subtitle?: string;
  onDotsClick?: FunctionFixMe;
}

export default class extends React.Component<Props> {
  static displayName = 'Card';

  static propTypes = {
    title: PropTypes.string,
    dotsActions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        label: PropTypes.string,
        onClick: PropTypes.func,
      }),
    ),
    subtitle: PropTypes.string,
    onDotsClick: PropTypes.func,
  };

  onDotsClick = () => {
    _.invoke(this.props, 'onDotsClick');
  };

  getThreeDotsButton = () => {
    return React.createElement(DotsButton, {
      onClick: () => this.onDotsClick(),
    });
  };

  shouldDisplayThreeDots = () => {
    return !_.isEmpty(this.getDotsActions());
  };

  getDotsActions = () => {
    return this.props.dotsActions;
  };

  render() {
    return (
      <div className="media-manager-panel-card">
        <div className="media-manager-panel-card__header">
          {this.shouldDisplayThreeDots() ? (
            <ContextMenu
              customButton={this.getThreeDotsButton()}
              closeOnEnterOrSpace={false}
              key="three-dots"
            >
              {/* TODO: Fix this the next time the file is edited. */}
              {/* eslint-disable-next-line you-dont-need-lodash-underscore/map */}
              {_.map(this.getDotsActions(), (action) => (
                <ContextMenuAction
                  onClick={() => {
                    action.onClick();
                  }}
                  key={action.id}
                >
                  <span>{action.label}</span>
                </ContextMenuAction>
              ))}
            </ContextMenu>
          ) : null}
        </div>

        {this.props.title ? (
          <div key="card-title" className="media-manager-panel-card__title">
            <TextLabel
              value={this.props.title}
              type="T09"
              enableEllipsis={false}
              shouldTranslate={false}
            />
          </div>
        ) : null}

        {this.props.subtitle ? (
          <div
            key="card-subtitle"
            className="media-manager-panel-card__subtitle"
          >
            <TextLabel
              value={this.props.subtitle}
              type="T04"
              enableEllipsis={false}
              shouldTranslate={false}
            />
          </div>
        ) : null}

        <div className="media-manager-panel-card__body">
          {this.props.children}
        </div>
      </div>
    );
  }
}
