'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')
const util = require('@/util')

module.exports = {
    "type": addPanelDataConsts.SECTIONS_TYPES.PRESET,
    "hide": false,
    "title": "add_section_label_captcha",
    "subNavigationTitle": "add_section_label_captcha",
    "topTitle": "add_section_title_captcha",
    "presetTitle": "add_section_heading_captcha",
    "tooltipTitle": "add_section_label_captcha_tooltip_title",
    "automationId": "add-panel-section-captchaSection",
    "subNavigationHide": false,
    "showSectionHeader": true,
    "additionalBehaviours": {
        "labelMode": addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
        "infoIcon": addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
        "hoverImageAction": addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
        "iconEnabledForComps": {}
    },
    "props": {
        "image": `addPanelData/sections/addPanelCaptchaSection_${util.languages.getLanguageCode()}/addPanelCaptchaSection_${util.languages.getLanguageCode()}.png`,
        "imageHover": null,
        "items": [{
            "id": "User_Input_Captcha_1",
            "structure": {
                "type": "Component",
                "skin": "wixui.skins.Captcha",
                "layout": {
                    "width": 234,
                    "height": 60,
                    "x": 370,
                    "y": 143,
                    "scale": 0.7,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wixui.Captcha",
                "data": {
                    "type": "Captcha",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "captchaType": "image"
                },
                "props": {
                    "type": "CaptchaProperties",
                    "metaData": {
                        "schemaVersion": "1.0"
                    },
                    "theme": "light",
                    "size": "normal"
                },
                "style": {
                    "type": "ComponentStyle",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "style": {
                        "properties": {}
                    },
                    "componentClassName": "wysiwyg.viewer.components.Captcha",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wixui.skins.Captcha"
                },
                "connections": {
                    "type": "ConnectionList",
                    "items": [
                        {
                            "type": "WixCodeConnectionItem",
                            "role": "captcha1"
                        }
                    ]
                },
                "mobileStructure": {
                    "layout": {
                        "width": 236,
                        "height": 60,
                        "x": 20,
                        "y": 12,
                        "scale": 0.7,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "metaData": {
                        "originalCompId": "comp-jst5d3eo",
                        "author": "duplicate"
                    }
                },
                "activeModes": {}
            },
            "preset": {
                "rect": {
                    "width": 324,
                    "height": 100,
                    "x": 0,
                    "y": 0
                }
            }
        }],
        "compTypes": ["wixui.Captcha"]
    },
    "help": {
        "hide": false,
        "text": "add_section_label_captcha_tooltip_description"
    }
}
