import { createMenuApi } from './API/menuAPI';

import { MenuSetsPanel } from './panels/MenuSetsPanel/MenuSetsPanel';
import { MenuManagePanel } from './panels/MenuManagePanel/MenuManagePanel';
import { DeleteMenuSetPanel } from './panels/DeleteMenuSetPanel/DeleteMenuSetPanel';
import { MenuSetsRadioPanel } from './panels/MenuSetsRadioPanel/MenuSetsRadioPanel';
import { ConnectedMenuList } from './panels/MenuManagePanel/ConnectedMenuList/ConnectedMenuList';
import * as constants from './constants';
import { utils } from './utils';
import {
  setupMenus,
  adaptMenuComponentStructure,
  connectMainMenuToComponent,
} from './setupMenus';
import { getCollapsedItems, setItemCollapsed } from './API/userPreferences';
import fixers from './fixers';

const api = {
  createMenuApi,
};

const panels = {
  MenuSetsPanel,
  MenuManagePanel,
  DeleteMenuSetPanel,
  MenuSetsRadioPanel,
};
export type { IPageMenuItem } from './menuWrapper';
export type {
  GetActionsOptions,
  MenuAddItemAction,
} from './panels/MenuManagePanel/menuActions';
export type { IMenuConfig } from './panels/MenuManagePanel/utils/config.types';

export { MenuEntrypoint } from './menuEntry';

export { AdvancedMenuEntryPoint } from './API/advancedMenuEntryPoint';

export {
  api,
  panels,
  constants,
  utils,
  setupMenus,
  getCollapsedItems,
  setItemCollapsed,
  adaptMenuComponentStructure,
  ConnectedMenuList,
  connectMainMenuToComponent,
  fixers,
};
