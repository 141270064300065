import _ from 'lodash';
import * as stateManagement from '@/stateManagement';
import experiment from 'experiment';
import { isDataModeOn, EVENTS } from '@/wixData';

import type { EditorAPI } from '@/editorAPI';
import type { CompRef } from 'types/documentServices';

const { isConnectedToDataBindingController } =
  stateManagement.platform.selectors;

function isSelectedComponentConnected(
  editorAPI: EditorAPI,
  selectedComps: CompRef[],
) {
  return isConnectedToDataBindingController(
    _.head(selectedComps),
    editorAPI.dsRead,
  );
}

const connectActionDefinition = {
  connect: {
    isSelected(editorAPI: EditorAPI) {
      return editorAPI.panelManager.findPlatformPanelByType('connect');
    },
    isApplied: isSelectedComponentConnected,
    tooltip: 'gfpp_component_connect',
    tooltip_active: 'gfpp_component_connected_to_controllers',
    icon: 'connect_v2',
    rightClickMenuLabel: 'PLATFORM_RightClick_Menu_Connect',
    className(editorAPI: EditorAPI, selectedComps: CompRef[]) {
      return [
        'gfpp_component_connect',
        isSelectedComponentConnected(editorAPI, selectedComps)
          ? 'connected'
          : '',
      ].join(' ');
    },
    automationId: 'gfpp-button-connect',
    onClick: notifyConnectClicked,
    isSupported(editorAPI: EditorAPI, compRefs: CompRef[]) {
      return (
        isDataModeOn(editorAPI) &&
        (!editorAPI.isMobileEditor() ||
          (experiment.isOpen('se_mobileOnlyDataBinding') &&
            compRefs.every(
              editorAPI.mobile.mobileOnlyComponents
                .isMobileOnlyNonNativeComponent,
            ))) &&
        editorAPI.components.is.allowConnectToDB(compRefs)
      );
    },
  },
};

function init(registerGfppActions: any): any {
  registerGfppActions(connectActionDefinition);
}

function notifyConnectClicked(
  editorAPI: EditorAPI,
  components: CompRef[],
  origin = 'gfpp',
) {
  const installedApplications =
    editorAPI.dsRead.platform.getInstalledAppsData();
  const dataBindingAppData =
    installedApplications.length === 1
      ? _.head(installedApplications)
      : undefined;

  if (!dataBindingAppData) return;

  const componentRef = _.head(components);
  const eventType = EVENTS.componentConnectButtonClicked;

  const payload = {
    componentRef,
    componentType: editorAPI.components.getType(componentRef),
    componentSdkType: editorAPI.wixCode.getCompSdkType(componentRef),
    origin,
  };

  editorAPI.dsActions.platform.notifyApplication(
    dataBindingAppData.applicationId,
    {
      eventType,
      eventPayload: _.cloneDeep(payload),
    },
  );
}

export { init, notifyConnectClicked };
