@charset "UTF-8";
:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

#_designPanel_rlgab_1 ._comp-panel-content_rlgab_8 {
  max-height: 504px; }
  #_designPanel_rlgab_1 ._comp-panel-content_rlgab_8._hideOverFlow_rlgab_10 {
    overflow: hidden; }

#_designPanel_rlgab_1 ._sections_rlgab_13 {
  overflow: hidden; }
  #_designPanel_rlgab_1 ._sections_rlgab_13._panel-has-children_rlgab_15 {
    padding-bottom: 84px; }
    #_designPanel_rlgab_1 ._sections_rlgab_13._panel-has-children_rlgab_15 ._section-wrapper_rlgab_17:last-child {
      padding-bottom: 0; }
  #_designPanel_rlgab_1 ._sections_rlgab_13 ._section-header_rlgab_19 {
    padding-top: 18px; }
    #_designPanel_rlgab_1 ._sections_rlgab_13 ._section-header_rlgab_19 ._title_rlgab_21 {
      width: 100%; }

#_designPanel_rlgab_1 ._button-wrapper_rlgab_24 {
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.9) 0, white 90%);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  bottom: 0;
  height: 84px;
  width: 100%;
  position: absolute;
  text-align: center; }
  #_designPanel_rlgab_1 ._button-wrapper_rlgab_24 ._button_rlgab_24 {
    bottom: -23px;
    position: relative;
    line-height: 26px; }
    #_designPanel_rlgab_1 ._button-wrapper_rlgab_24 ._button_rlgab_24._animate-button_rlgab_37 {
      animation-duration: 0.6s;
      animation-timing-function: ease;
      animation-name: _tada_rlgab_1; }

@keyframes _tada_rlgab_1 {
  60% {
    background-color: #d6e6fe;
    transform: scale(1.05); } }

#_designPanel_rlgab_1 ._design-panel-selected-item_rlgab_47 svg {
  position: absolute; }

#_designPanel_rlgab_1 ._design-panel-selected-item_rlgab_47._preset-item_rlgab_50 svg {
  top: 0;
  right: -2px;
  height: 31px;
  width: 31px; }

#_designPanel_rlgab_1 ._design-panel-transparent-skin-item_rlgab_56 > div {
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  color: #000624;
  text-align: center; }

#_designPanel_rlgab_1 ._design-panel-no-frame-skin-item_rlgab_65 > div {
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  color: #fff;
  text-align: center; }
  #_designPanel_rlgab_1 ._design-panel-no-frame-skin-item_rlgab_65 > div > span {
    background-color: rgba(22, 45, 61, 0.5); }

#_designPanel_rlgab_1 ._design-panel-no-frame-skin-item-discover-more_rlgab_76 > div {
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  color: #fff;
  text-align: center; }
  #_designPanel_rlgab_1 ._design-panel-no-frame-skin-item-discover-more_rlgab_76 > div > span {
    background-color: rgba(22, 45, 61, 0.5);
    font-size: 14px; }

#_designPanel_rlgab_1 ._preset-item_rlgab_50._design-panel-transparent-skin-item_rlgab_56 > div, #_designPanel_rlgab_1 ._preset-item_rlgab_50._design-panel-no-frame-skin-item_rlgab_65 > div {
  font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 18px;
  -webkit-font-smoothing: antialiased; }

#_designPanel_rlgab_1 ._section-live-site-wrapper_rlgab_95 ._section-live-site-block-layer_rlgab_95 {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%; }
  #_designPanel_rlgab_1 ._section-live-site-wrapper_rlgab_95 ._section-live-site-block-layer_rlgab_95 ._section-live-site-item_rlgab_101 {
    box-sizing: content-box;
    position: absolute; }
    #_designPanel_rlgab_1 ._section-live-site-wrapper_rlgab_95 ._section-live-site-block-layer_rlgab_95 ._section-live-site-item_rlgab_101._design-panel-transparent-skin-item_rlgab_56 {
      background-color: #f2f2f2;
      background-image: linear-gradient(45deg, #dbdbdb 25%, transparent 25%, transparent 75%, #dbdbdb 75%, #dbdbdb), linear-gradient(45deg, #dbdbdb 25%, transparent 25%, transparent 75%, #dbdbdb 75%, #dbdbdb);
      background-position: 0 0, 4px 4px;
      background-size: 8px 8px; }
      #_designPanel_rlgab_1 ._section-live-site-wrapper_rlgab_95 ._section-live-site-block-layer_rlgab_95 ._section-live-site-item_rlgab_101._design-panel-transparent-skin-item_rlgab_56._section-scaled-in-half_rlgab_109 {
        background-position: 0 0, 16px 16px;
        background-size: 32px 32px; }

._cssContainer_rlgab_113 {
  padding: 18px 24px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center; }
