'use strict'

const mergeWithCommonSections = require('./merge-with-common-sections')
const mergeSectionImageMap = require('./merge-section-image-map')
const mergeSectionVideos = require('./merge-section-videos')
const extendPropsItems = require('./extend-props-items')

module.exports = {
    mergeWithCommonSections,
    mergeSectionImageMap,
    mergeSectionVideos,
    extendPropsItems
}
