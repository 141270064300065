//eslint-disable-next-line @wix/santa-editor/scoped-imports
import {
  mapDispatchToDatabaseWelcomeSectionProps,
  type DatabaseWelcomeDispatchProps,
} from '@/addPanelData/mappers';
import { wrapWithPropsTransformer } from '../utils';

export interface ContentManagerWelcomeProps {
  contentManagerMainAction: () => void;
  contentManagerSecondaryAction: () => void;
}

export const contentManagerWelcomeMapper = wrapWithPropsTransformer<
  DatabaseWelcomeDispatchProps,
  ContentManagerWelcomeProps
>(mapDispatchToDatabaseWelcomeSectionProps, (props) => ({
  contentManagerMainAction: props.turnDataModeOn,
  contentManagerSecondaryAction: props.openWelcomeHelpSection,
}));
