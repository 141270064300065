// @ts-nocheck
import _ from 'lodash';
import * as packagePickerUtils from './packagePickerUtils';
import * as editorRefreshTPADataAfterUpgrade from '../handlers/editorRefreshTPADataAfterUpgrade';
import * as tpaUtils from '../utils/tpaUtils';
import * as tpaAlertsService from '../services/tpaAlertsService';
import { ErrorReporter } from '@wix/editor-error-reporter';

const startUpgradeFlow = function (
  editorAPI,
  appDefinitionId,
  isHandlerFlow,
  options = {},
) {
  const isAsyncFlow = isHandlerFlow || false;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/assign
  options = _.assign({ isAsyncFlow }, options);

  if (!appDefinitionId) {
    ErrorReporter.captureException(
      new Error('app market call without appDefId'),
      {
        tags: {
          appMarketCallNoAppDefId: true,
        },
        extra: {
          flow: 'packagePickerStartUpgradeFlow',
        },
      },
    );
  }
  const marketData = editorAPI.dsRead.tpa.appMarket.getData(appDefinitionId);
  const appData = editorAPI.dsRead.tpa.app.getDataByAppDefId(appDefinitionId);
  if (options.shouldNavigate !== false) {
    tpaUtils.selectComponentAndNavigateIfNeeded(editorAPI, appData);
  }

  if (isSaveSiteNeeded(editorAPI, appData)) {
    saveAndContinueFlow(
      editorAPI,
      options,
      appDefinitionId,
      marketData,
      appData,
    );
  } else {
    openModalOrExternalUpgrade(
      editorAPI,
      appDefinitionId,
      marketData,
      appData,
      options,
    );
  }
};

const saveAndContinueFlow = function (
  editorAPI,
  options,
  appDefinitionId,
  marketData,
  appData,
) {
  const onSuccess = function () {
    if (!appDefinitionId) return;
    editorAPI.dsRead.tpa.appMarket
      .getDataAsync(appDefinitionId)
      .then(function (mktData) {
        options.isAsyncFlow = true;
        openModalOrExternalUpgrade(
          editorAPI,
          appDefinitionId,
          mktData,
          appData,
          options,
        );
      });
  };
  const onError = _.partial(
    tpaAlertsService.openSaveFailedAlertForUpgrade,
    editorAPI,
    appDefinitionId,
  );
  editorAPI.saveManager.saveInBackground(onSuccess, onError, 'upgradeFlow');
};

const openModalOrExternalUpgrade = function (
  editorAPI,
  appDefinitionId,
  marketData,
  appData,
  options,
) {
  if (needsToCallServer(marketData) && appDefinitionId) {
    editorAPI.dsRead.tpa.appMarket
      .getDataAsync(appDefinitionId)
      .then(function (mktData) {
        openModalOrExternalUpgradeImp(editorAPI, mktData, appData, options);
      });
  } else {
    openModalOrExternalUpgradeImp(editorAPI, marketData, appData, options);
  }
};

const needsToCallServer = function (marketData) {
  return !_.has(marketData, 'price');
};

const openModalOrExternalUpgradeImp = function (
  editorAPI,
  marketData,
  appData,
  options,
) {
  const panelData = packagePickerUtils.pickPickerForm(
    editorAPI,
    marketData,
    appData,
    options,
  );
  const { upgradeUrl } = panelData;
  if (
    (panelData.panelName === packagePickerUtils.TYPE.EXTERNAL ||
      panelData.panelName === packagePickerUtils.TYPE.EXTERNAL_PACKAGES) &&
    !options.isAsyncFlow
  ) {
    window.open(upgradeUrl);
    editorRefreshTPADataAfterUpgrade.trackAppUpgrade(
      editorAPI,
      appData.applicationId,
    );
  } else {
    editorAPI.panelManager.openPanel(
      panelData.panelName,
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      _.assign(panelData, options),
      true,
    );
  }
};

const isMostExpensive = function (structure, appData) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/last
  return _.last(structure.packages).id === appData.vendorProductId;
};

const hasOnlyMonthlyPackages = function (structure) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/last
  return !_.last(structure.packages).yearly.price;
};

const isFullyUpgraded = function (editorAPI, appData) {
  if (!appData?.appDefinitionId) {
    ErrorReporter.captureException(
      new Error('app market call without appDefId'),
      {
        tags: {
          appMarketCallNoAppDefId: true,
        },
        extra: {
          flow: 'packagePickerIsFullyUpgraded',
        },
      },
    );
  }
  const marketData = editorAPI.dsRead.tpa.appMarket.getData(
    appData.appDefinitionId,
  );
  if (marketData) {
    const structure = packagePickerUtils.pickPickerForm(
      editorAPI,
      marketData,
      appData,
    );
    const isUpgraded = !!appData.vendorProductId;
    const isYearly = !!marketData.upgradedToYearly;

    // In case external upgrade with packages, the upgrade state is like packages
    if (packagePickerUtils.TYPE.EXTERNAL && structure.externalPackages) {
      structure.panelName = packagePickerUtils.TYPE.PACKAGES;
    }

    switch (structure.panelName) {
      case packagePickerUtils.TYPE.BENEFITS:
        return isUpgraded;
      case packagePickerUtils.TYPE.CYCLES:
        return isUpgraded && isYearly;
      case packagePickerUtils.TYPE.PACKAGES:
        let fullUpgraded = isUpgraded && isMostExpensive(structure, appData);
        fullUpgraded =
          fullUpgraded && (isYearly || hasOnlyMonthlyPackages(structure));
        return fullUpgraded;
      case packagePickerUtils.TYPE.EXTERNAL:
        return isUpgraded;
      default:
        return false;
    }
  }

  return true;
};

const getUpgradeUrlWithUpdatedMarketData = function (
  editorAPI,
  appDefinitionId,
  callback,
) {
  if (!appDefinitionId) return;
  editorAPI.dsRead.tpa.appMarket
    .getDataAsync(appDefinitionId)
    .then(function (marketData) {
      const appData =
        editorAPI.documentServices.tpa.app.getDataByAppDefId(appDefinitionId);
      const panelData = packagePickerUtils.pickPickerForm(
        editorAPI,
        marketData,
        appData,
      );
      callback(panelData.upgradeUrl);
    });
};

const getWixUpgradeUrl = function (editorAPI, appDefinitionId, callback) {
  const appData =
    editorAPI.documentServices.tpa.app.getDataByAppDefId(appDefinitionId);

  if (isSaveSiteNeeded(editorAPI, appData)) {
    editorAPI.saveManager.saveInBackground(
      function () {
        getUpgradeUrlWithUpdatedMarketData(
          editorAPI,
          appDefinitionId,
          callback,
        );
      },
      _.noop,
      'getWixUpgradeUrl',
    );
  } else {
    getUpgradeUrlWithUpdatedMarketData(editorAPI, appDefinitionId, callback);
  }
};

const isSaveSiteNeeded = function (editorAPI, appData) {
  const isDraftMode = editorAPI.dsRead.generalInfo.isDraft();
  return appData?.notProvisioned ?? (appData?.demoMode || isDraftMode);
};

const { upgradeToPremiumAndClosePickerModal, BI_TYPE } = packagePickerUtils;

export {
  startUpgradeFlow,
  isFullyUpgraded,
  getWixUpgradeUrl,
  isSaveSiteNeeded,
  upgradeToPremiumAndClosePickerModal,
  BI_TYPE,
};
