import type { Anchor, CompData, CompStructure } from 'types/documentServices';

export interface ComponentLayout {
  size: { width: number; height: number };
  position: { x: number; y: number };
}

export interface CompFullStructure extends CompStructure {
  styleId: string;
  id: string;
  dataQuery: string;
  layout: {
    width: number;
    height: number;
    x: number;
    y: number;
    scale: number;
    rotationInDegrees: number;
    anchors: Anchor[];
    fixedPosition: boolean;
  };
  propertyQuery: string;
  componentType: string;
  originalStyleId: string;
}

function getComponentDef(
  styleId: string,
  compId: string,
  dataId: string,
  propId: string,
  componentType: string,
  compLayout: ComponentLayout,
  isContainer: boolean,
  originalStyleId: string,
): CompFullStructure {
  const compStructure: CompFullStructure = {
    type: isContainer ? 'Container' : 'Component',
    styleId,
    id: compId,
    dataQuery: dataId,
    layout: {
      width: compLayout.size.width,
      height: compLayout.size.height,
      x: compLayout.position.x,
      y: compLayout.position.y,
      scale: 1.0,
      rotationInDegrees: 0.0,
      anchors: [],
      fixedPosition: false,
    },
    propertyQuery: propId,
    componentType,
    originalStyleId,
  };

  if (isContainer) {
    compStructure.components = [];
  }

  return compStructure;
}

function createBottomTopAnchor(
  fromCompData: CompData,
  toCompData: CompData,
  verticalMarginWithScaling: number,
): Anchor {
  return {
    distance: verticalMarginWithScaling,
    locked: true,
    fromComp: fromCompData.compId,
    targetComponent: toCompData.compId,
    originalValue: toCompData.compPosition.y,
    type: 'BOTTOM_TOP',
  };
}

function createBottomParentAnchor(
  fromCompData: CompData,
  pageHeight: number,
  pageId: string,
): Anchor {
  return {
    distance: 0,
    locked: true,
    fromComp: fromCompData.compId,
    targetComponent: pageId,
    originalValue: pageHeight,
    type: 'BOTTOM_PARENT',
  };
}

export { getComponentDef, createBottomTopAnchor, createBottomParentAnchor };
