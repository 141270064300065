// @ts-nocheck
import createReactClass from 'create-react-class';
import _ from 'lodash';
import bubbleOnHoverMixin from '../mixins/bubbleOnHoverMixin';
import liveComponentSectionMixin from './liveComponentSectionMixin';
import preventDoubleClickMixin from '../mixins/preventDoubleClickMixin';
import React from 'react';
import SectionWrapper from '../sectionWrapper';
import { cx, languages } from '@/util';
import * as santaPreview from '@/santaPreview';
import * as liveComponentStyleAdjuster from './liveComponentStyleAdjuster';
import { fedopsLogger } from '@/util';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'liveComponentSection',
  mixins: [
    liveComponentSectionMixin,
    bubbleOnHoverMixin,
    preventDoubleClickMixin,
  ],
  onMouseDownCapture(e) {
    if (!this.isAnotherMouseActionAllowed(e)) {
      return;
    }
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/includes
    if (_.includes(e.target.className, 'info-icon')) {
      return;
    }
    if (!_.isFunction(this.props.onItemDrag)) {
      return;
    }
    if (e.target.nodeName === 'INPUT' || e.target.nodeName === 'TEXTAREA') {
      e.target.blur();
    }

    const clickedCompData = this.getCompDataByXY(e.clientX, e.clientY);
    if (clickedCompData) {
      clickedCompData.section = 'live';
      this.props.onItemDrag(e, clickedCompData);
    }
  },
  onClickCapture(e) {
    if (!this.isAnotherMouseActionAllowed(e)) {
      return;
    }
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/includes
    if (_.includes(e.target.className, 'info-icon')) {
      return;
    }
    if (e.target.nodeName === 'INPUT' || e.target.nodeName === 'TEXTAREA') {
      e.target.blur();
    }

    const clickedCompData = this.getCompDataByXY(e.clientX, e.clientY);
    if (clickedCompData) {
      this.props.onClick(clickedCompData.structure, this.props.title, '');
      this.setClickTimeStamp(e);
    }

    e.stopPropagation();
    e.preventDefault();
  },
  onMouseUp(e) {
    this.getEditorAPI().mouseActions.onMouseUp(e);
  },
  getExtraEventHandles() {
    return {
      onClickCapture: this.onClickCapture,
      onMouseDownCapture: this.onMouseDownCapture,
      onMouseMove: this.showBubbleOnMouseMove,
      onMouseLeave: this.hideBubbleOnMouseLeave,
      onMouseUp: this.onMouseUp,
    };
  },
  onSiteReady(sectionHeightAfterLayout, measureMap) {
    this.onSiteReadyUpdateLayout(sectionHeightAfterLayout, measureMap);

    this.trackThemedSectionLoaded();
  },
  trackThemedSectionLoaded() {
    fedopsLogger.interactionEnded(
      fedopsLogger.INTERACTIONS.ADD_PANEL.THEMED_SECTION_LOAD,
    );
  },
  componentDidMount() {
    fedopsLogger.interactionStarted(
      fedopsLogger.INTERACTIONS.ADD_PANEL.THEMED_SECTION_LOAD,
    );
  },
  render() {
    return !_.isEmpty(this.props.stylesForSection) && !this.props.hide ? (
      <SectionWrapper
        key="liveComps"
        {...this.props}
        className={cx('live-comps', this.props.className)}
        style={{
          height: `${this.calculateTotalSectionHeight()}px`,
          ...this.props?.style,
        }}
      >
        <div
          key={this.props.id}
          data-automation-id={this.props.automationId}
          data-hook={this.props.automationId}
          ref={`sectionLiveSiteWrapper${this.props.id}`}
          style={this.getStyleProps()}
          className="section-live-site-wrapper"
        >
          <santaPreview.santaPreviewContainer
            rootUrl={window.santaBase}
            languageCode={languages.getLanguageCode()}
            ref={`santaPreview${this.props.id}`}
            key={`santaPreview${this.props.id}`}
            preview={this.getLiveCompsComponentsDef}
            onSiteReady={this.onSiteReady}
            extraEventsHandlers={this.getExtraEventHandles()}
            getColorToReplaceIfNeeded={
              liveComponentStyleAdjuster.getColorTeReplaceIfNeeded
            }
          />
        </div>
      </SectionWrapper>
    ) : null;
  },
});
