import { panels, bi, tpa } from '@/stateManagement';
import constants from '@/constants';

import { HELP_ARTICLE_IDS } from './blogUpgradePanel.constants';
import {
  siteHasNewBlog,
  startMagicMigration,
  newBlogAppDefId,
} from '../utils/blogUtils';

import type { ThunkAction } from 'types/redux';
import type { EditorAPI } from '@/editorAPI';
import type { OwnProps } from './blogUpgradePanel.types';

const BI_ORIGIN = 'blog-migration-panel';
const BI_BLOG_ENDPOINT = 'blog-app-users';

export const OLD_BLOG_FEED_PAGE_ID = '79f391eb-7dfc-4adf-be6e-64434c4838d9';
export const OLD_BLOG_POST_PAGE_ID = '7326bfbb-4b10-4a8e-84c1-73f776051e10';

const getEditorAPI: ThunkAction<EditorAPI> = (
  dispatch,
  getState,
  { editorAPI }: { editorAPI: EditorAPI },
) => editorAPI;

export const mapStateToProps = ({ editorAPI }: AnyFixMe) => ({
  hasNewBlog: siteHasNewBlog(editorAPI),
});

export const mapDispatchToProps = (dispatch: AnyFixMe, ownProps: OwnProps) => {
  const editorAPI: EditorAPI = dispatch(getEditorAPI);

  const isOldBlogPage = (page: any) =>
    (page.type === 'AppPage' && page.appPageId === OLD_BLOG_FEED_PAGE_ID) ||
    (page.type === 'AppPage' && page.appPageId === OLD_BLOG_POST_PAGE_ID);
  const hasOldBlogPages = () => {
    return editorAPI.pages.getPagesData().some((page) => isOldBlogPage(page));
  };
  const sendBi = (event: AnyFixMe, parameters: AnyFixMe) =>
    dispatch(bi.actions.event(event, parameters));
  const deleteApp = (appDefId: string, callback: () => void = () => {}) =>
    dispatch(
      tpa.actions.deleteApp(appDefId, { removal_method: BI_ORIGIN }, callback),
    );
  const removeOldBlogPage = (page: AnyFixMe) => {
    editorAPI.documentServices.pages.remove(
      page.id,
      () => {
        if (!hasOldBlogPages()) {
          setTimeout(() =>
            editorAPI.panelManager.openPanel(
              constants.SUPER_APPS.NEW_BLOG_PANEL_NAME,
            ),
          );
        }
      },
      { shouldShowEditorRemovePanel: false },
    );
  };
  const deleteOldBlog = () => {
    const pages = editorAPI.pages.getPagesData();
    const newBlogFeedPage = pages.find(
      (page: any) =>
        page.type === 'Page' &&
        page.managingAppDefId === newBlogAppDefId &&
        page.tpaPageId === 'blog',
    );

    editorAPI.documentServices.wixapps.classics.blog.deleteAllAppParts();
    editorAPI.pages.getPagesData().forEach((page: any) => {
      if (isOldBlogPage(page)) {
        if (editorAPI.pages.isHomePage(page.id)) {
          editorAPI.homePage.set(newBlogFeedPage.id);
        }

        if (editorAPI.pages.getCurrentPageId() === page.id) {
          return editorAPI.documentServices.pages.navigateTo(
            newBlogFeedPage.id,
            () => removeOldBlogPage(page),
          );
        }

        removeOldBlogPage(page);
      }
    });
  };

  return {
    closePanel: () =>
      dispatch(panels.actions.closePanelByName(ownProps.panelName)),
    openHelpCenter: () =>
      dispatch(panels.actions.openHelpCenter(HELP_ARTICLE_IDS.LEARN_MORE)),
    logBi: (evid: number, params: object) =>
      sendBi({ evid, src: 69, endpoint: BI_BLOG_ENDPOINT }, params),
    startMagicMigrationAndDeleteOldBlog: () =>
      startMagicMigration(editorAPI, null, null, false).then(() =>
        deleteOldBlog(),
      ),
    startMagicMigration: () => startMagicMigration(editorAPI),
    deleteAndStartMagicMigration: () =>
      deleteApp(newBlogAppDefId, () => startMagicMigration(editorAPI)),
  };
};
