import experiment from 'experiment';
import type { SectionsMigrationScope as Scope } from '../scope';

// supportMobile value blocks/unblocks running of mobile algo
export function setSupportMobileFeatures(scope: Scope, supportMobile: boolean) {
  const masterPageRef = scope.editorAPI.components.get.byId(
    scope.editorAPI.pages.getMasterPageId(),
  );

  if (experiment.isOpen('se_blockUnblockRunMobileAlgoInSectionsMigration')) {
    scope.editorAPI.components.data.update(masterPageRef, {
      supportMobile,
    });
  }
}
