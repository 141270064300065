import _ from 'lodash';
import type { ITransformationData } from '../../interactionsTransformFacade';
import type { EffectTypes } from './effects';
import { getSelectedValueFromOrigin } from './customize/customizeUtils';

export function getDiff(
  initialTransformation: ITransformationData,
  transformation: ITransformationData,
  initialEffect: EffectTypes,
  effect: EffectTypes,
) {
  const initialHorizontalSkew = initialTransformation?.skew?.x;
  const initialVerticalSkew = initialTransformation?.skew?.y;
  const initialScale = initialTransformation?.scale?.x;
  const initialOrigin = initialTransformation.origin
    ? getSelectedValueFromOrigin(initialTransformation.origin)
    : initialTransformation.origin;
  const horizontalSkew = transformation?.skew?.x;
  const verticalSkew = transformation?.skew?.y;
  const scale = transformation?.scale?.x;
  const origin = transformation.origin
    ? getSelectedValueFromOrigin(transformation.origin)
    : transformation.origin;

  const diff = {
    effect_origin: initialOrigin !== origin ? origin : null,
    hor_skew: initialHorizontalSkew !== horizontalSkew ? horizontalSkew : null,
    ver_skew: initialVerticalSkew !== verticalSkew ? verticalSkew : null,
    scale: initialScale !== scale ? scale : null,
    rotation_value:
      initialTransformation.rotate !== transformation.rotate
        ? transformation.rotate
        : null,
    behavior_name: initialEffect !== effect ? effect : null,
  };
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/is-null
  return _.pickBy(diff, (val) => !_.isNull(val));
}
