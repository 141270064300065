import { overridable } from '@wix/santa-editor-utils';
import * as componentsLiveSections from './componentsLiveSections';
import * as componentsPresetSections from './componentsPresetSections';

const getDesignPanelTitleByComp = overridable((compType: string) => {
  return componentsLiveSections.getDesignPanelTitleByComp(compType);
});

function getSectionsByComponentType(
  compType: string,
  withAdditionalBehaviors = false,
) {
  const liveSections =
    componentsLiveSections.getLiveComponentSectionsByCompType(compType);
  const presetSections = componentsPresetSections.getPresetSectionsByCompType(
    compType,
    withAdditionalBehaviors,
  );

  return liveSections.concat(presetSections);
}

export { getSectionsByComponentType, getDesignPanelTitleByComp };
