// @ts-nocheck
import createReactClass from 'create-react-class';
import * as core from '@/core';
import _ from 'lodash';
import React from 'react';
import * as baseUI from '@/baseUI';

const SINGLE_POST_PAGE_APP_PAGE_ID = '7326bfbb-4b10-4a8e-84c1-73f776051e10';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'singlePostPageOnlyCompsTooltip',
  mixins: [core.mixins.editorAPIMixin],
  goToSinglePost() {
    const editorAPI = this.getEditorAPI();
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/find
    const singlePostPage = _.find(editorAPI.dsRead.pages.getPagesData(), {
      appPageId: SINGLE_POST_PAGE_APP_PAGE_ID,
    });

    this.close();
    editorAPI.pages.navigateTo(singlePostPage.id);
  },
  close() {
    this.getEditorAPI().hideFloatingBubble();
  },
  render() {
    return (
      <baseUI.popoverTemplates.quickTip
        text="BLOG_RELATED_POSTS_DISABLE_TOOLTIP_TEXT"
        linkActionText="BLOG_RELATED_POSTS_DISABLE_TOOLTIP_LINK"
        symbol="idea-bulb"
        linkAction={this.goToSinglePost}
        closeAction={this.close}
      />
    );
  },
});
