import type { Shell } from '@/apilib';
import type { AppsPanelSection } from './types';
import { AppsPanelSectionsSlot } from './publicApiKey';

export const createAppsPanelSectionsApi = (shell: Shell) => {
  const contributedSections = shell.declareSlot(AppsPanelSectionsSlot);
  type ContributePredicate = Parameters<
    typeof contributedSections.contribute
  >[2];

  function contributeSection(
    contributingShell: Shell,
    section: AppsPanelSection,
    contributePredicate?: ContributePredicate,
  ) {
    contributedSections.contribute(
      contributingShell,
      section,
      contributePredicate,
    );
  }

  function getContributedSections() {
    return contributedSections.getItems().map((item) => item.contribution);
  }

  return {
    contributeSection,
    getContributedSections,
  };
};
