:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._container_142nt_7 {
  padding: 9px 12px;
  border-radius: 4px;
  border: 1px solid var(--elements-color);
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  height: 30px;
  box-shadow: 0px 2px 5px -3px rgba(0, 0, 0, 0.15), 0px 4px 10px 1px rgba(0, 0, 0, 0.11), 0px 3px 14px 2px rgba(0, 0, 0, 0.09);
  background-color: var(--bg-color); }
  ._container_142nt_7 .control-text {
    color: var(--elements-color) !important; }
  ._container_142nt_7._hasIcon_142nt_20 {
    padding-right: 18px; }

._icon_142nt_23 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 3px;
  width: 20px; }
  ._icon_142nt_23 svg {
    fill: var(--elements-color); }

._primary_142nt_32 {
  --bg-color: #6f56f9;
  --elements-color: #ffffff; }
  ._primary_142nt_32:hover {
    --bg-color: #4b31dc; }

._secondary_142nt_38 {
  --bg-color: #ffffff;
  --elements-color: #6f56f9; }
  ._secondary_142nt_38:hover {
    --bg-color: #d9d2ff; }

._lightLarge_142nt_44,
._lightLargeSoap_142nt_45 {
  --bg-color: #fff;
  --elements-color: #2d3034;
  height: 32px;
  border: none; }
  ._lightLarge_142nt_44:hover,
  ._lightLargeSoap_142nt_45:hover {
    --elements-color: #116dff; }

._primarySoap_142nt_54 {
  --bg-color: #fe620f;
  --elements-color: #ffffff; }
  ._primarySoap_142nt_54:hover {
    --bg-color: #dd560e; }

._secondarySoap_142nt_60 {
  --bg-color: #ffffff;
  --elements-color: #fe620f; }
  ._secondarySoap_142nt_60:hover {
    --bg-color: #fee8dc; }
