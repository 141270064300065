import React from 'react';
import { translate } from '@/i18n';
import { DangerMessagePanelFrame } from '../../frames';

import styles from './publishingBlockedForUser.scss';

interface Props {
  onConfirm: FunctionFixMe;
  panelName: string;
  openLearnMore: FunctionFixMe;
  iconName?: string;
}

const PublishingBlockedForUserPanel: React.FC<Props> = (props) => {
  const { panelName, onConfirm, iconName = 'plasterRed' } = props;

  return (
    <DangerMessagePanelFrame
      className={styles.root}
      panelName={panelName}
      title={'PUBLISH_ERROR_BLOCKED_SITE_HEADER_TITLE'}
      confirmLabel={'PUBLISH_ERROR_BLOCKED_SITE_PRIMARY_CTA'}
      iconName={iconName}
      validationError={true}
      closeWithoutCalling={true}
      onConfirm={onConfirm}
    >
      <p>{translate('PUBLISH_ERROR_BLOCKED_SITE_BODY_1')}</p>
      <p>
        {translate('PUBLISH_ERROR_BLOCKED_SITE_BODY_2')}
        &nbsp;
        <a
          href={translate('PUBLISH_ERROR_BLOCKED_SITE_BODY_LINK_URL')}
          target="_blank"
        >
          {translate('PUBLISH_ERROR_BLOCKED_SITE_BODY_LINK')}
        </a>
        {translate('PUBLISH_ERROR_BLOCKED_SITE_BODY_3')}
      </p>
    </DangerMessagePanelFrame>
  );
};

export default PublishingBlockedForUserPanel;
