import { isCustomMenusEnabled, isMainMenuFlowEnabled } from '@/util';
import experiment from 'experiment';
import * as stateManagement from '@/stateManagement';
import {
  fixMenusConnectedToPages,
  fixEmptyPagesMenu,
  makeMainMenu,
  normalizeMenuItemsVisibility,
  fixMenusConnectedToFallback,
} from './utils';
import { revertCustomMenus } from './revertCustomMenus';
import { migrate } from './migration';
import { deduplicateCustomMenus } from './deduplicateCustomMenus';
import { fixPagesMenuSubItems } from './fixPagesMenuSubItems';
import { revertFixPagesMenuSubItems } from './revertPagesMenuSubItems';
import { createMenuApi } from '../../API/menuAPI';
import { isMainMenu } from '../../utils/utils';
import { SHOW_DISABLED_HIDE_ON_PAGES_PANEL } from '../../constants';

import type { EditorAPI } from '@/editorAPI';

const { setGlobalUserPreferences } = stateManagement.userPreferences.actions;
const { loading: multilingualIsLoading } =
  stateManagement.multilingual.selectors;

const getMultilingualLoadingPromise = (editorAPI: EditorAPI) =>
  new Promise<void>((resolve) => {
    const intervalId = setInterval(() => {
      const isLoading = editorAPI.store
        ? multilingualIsLoading(editorAPI.store.getState()).isLoading
        : true;

      if (!isLoading) {
        clearInterval(intervalId);
        resolve();
      }
    }, 500);
  });

export const setupCustomMenus = async (editorAPI: EditorAPI) => {
  const menuAPI = createMenuApi(editorAPI);

  const isNewUser =
    !stateManagement.userPreferences.selectors.getGlobalUserPreferences(
      'didPassNewUserVideoCheck',
    )(editorAPI.store.getState());

  if (!isNewUser && isCustomMenusEnabled()) {
    // We don't want new users to see remove hide action in a pages panel - https://jira.wixpress.com/browse/EK-1443
    editorAPI.store.dispatch(
      setGlobalUserPreferences(SHOW_DISABLED_HIDE_ON_PAGES_PANEL, true),
    );
  }

  const customMenus = menuAPI.getCustomMenus();
  const pagesMenu = menuAPI.getPagesMenu();
  await getMultilingualLoadingPromise(editorAPI);

  if (experiment.isOpen('se_revertCustomMenus')) {
    await revertCustomMenus(editorAPI);
  } else if (customMenus.length === 0) {
    await migrate(editorAPI);
  } else {
    const [firstCustomMenu] = customMenus;
    // in case previous migration failed to reconnect all components
    await fixMenusConnectedToPages(editorAPI, firstCustomMenu);
    await fixMenusConnectedToFallback(editorAPI);

    // fix empty pages menu https://jira.wixpress.com/browse/WEED-18962
    if (
      experiment.isOpen('se_customMenusFixPagesMenu') &&
      pagesMenu?.items.length === 0
    ) {
      await fixEmptyPagesMenu(editorAPI);
    }

    if (isMainMenuFlowEnabled()) {
      const mainMenu = customMenus.find(isMainMenu);

      if (!mainMenu) {
        await makeMainMenu(editorAPI);
        // there was an issue when isVisible/isVisibleMobile flags weren't reseted to `true` state
        // menu manager is using these flags, so we need to set them properly
        customMenus.forEach((menu) =>
          normalizeMenuItemsVisibility(editorAPI, menu),
        );
      }

      deduplicateCustomMenus(editorAPI);
    }

    (window as any).___custom_menus_open___ = true;
  }

  if (isMainMenuFlowEnabled()) {
    await fixPagesMenuSubItems(editorAPI);
  }

  if (experiment.isOpen('se_customMenusRevertPagesPanelMigration')) {
    revertFixPagesMenuSubItems(editorAPI);
  }
};
