import type { CompLayout, PossibleViewModes } from 'types/documentServices';

export interface CompSnapshot {
  id: string;
  componentType: string;
  layout: Pick<CompLayout, 'x' | 'y' | 'width' | 'height'>;
  page: string;
}

export type PageSnapshots = CompSnapshot[][];

export interface InvalidSnapshot {
  page: CompSnapshot['page'];
  viewMode: PossibleViewModes;
  id: CompSnapshot['id'];
  componentType: CompSnapshot['componentType'];
  issueTypes?: {
    visibility: boolean;
    layout: boolean;
    level: boolean;
  };
  before?: Pick<CompSnapshot['layout'], 'y' | 'width' | 'height'> & {
    level: number;
  };
  after?: Pick<CompSnapshot['layout'], 'y' | 'width' | 'height'> & {
    level: number;
  };
}
