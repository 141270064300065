import { selectExternalCollections } from '../../collections';
import constants from '@/constants';

import type { MapStateToProps } from 'types/redux';

import type { Schema } from '@wix/wix-data-schema-types';

export interface ExternalCollectionsListStateProps {
  collections: Schema[];
  instance: string;
}

export const mapStateToProps: MapStateToProps<
  ExternalCollectionsListStateProps
> = ({ state, editorAPI }) => ({
  collections: selectExternalCollections(state),
  instance: editorAPI.dsRead.platform.getAppDataByApplicationId(
    constants.APPLICATIONS.META_SITE_APPLICATION_ID,
  ).instance,
});
