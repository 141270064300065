// @ts-nocheck
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import * as core from '@/core';
import React from 'react';
import PageSettingsFrame from '../pageSettingsFrame';

interface IPageSettingsDesktop {
  title?: string;
  displayTitle?: string;
  closeSettingsPanel: (action: 'back' | 'done' | 'deletePage') => void;
  helpId?: string;
}

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass<IPageSettingsDesktop>({
  mixins: [core.mixins.editorAPIMixin],
  displayName: 'pageSettingsDesktopCommonNew',
  propTypes: {
    title: PropTypes.string,
    displayTitle: PropTypes.string,
    closeSettingsPanel: PropTypes.func.isRequired,
    helpId: PropTypes.string,
  },
  render() {
    return (
      <PageSettingsFrame
        helpId={this.props.helpId}
        title={this.props.title}
        displayTitle={this.props.displayTitle}
        closeSettingsPanel={this.props.closeSettingsPanel}
      >
        {this.props.children}
      </PageSettingsFrame>
    );
  },
});
