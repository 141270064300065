import React from 'react';
import * as util from '@/util';
import { translate } from '@/i18n';
import { Divider, TextLabel, Heading } from '@wix/wix-base-ui';
import styles from './header.scss';
import VideoTooltip from '../videoTooltip/videoTooltip';
import type { BiEventDefinition, BiEventFields } from 'types/bi';
import { getYoutubeUrl } from '../utils/youtubeVideoUrl';
import { MainActionItems } from '../actionItems/mainActionItems';

interface WelcomePageHeaderProps {
  canEditSiteStructure: boolean;
  canSaveSchemas: boolean;
  isFirstTimeExperience: boolean;
  openAddPresetPanel: () => void;
  openCreateCollectionPanel: () => void;
  openVideoPanel: (videoId: string, isEmptyState: boolean) => void;
  sendBi: (event: BiEventDefinition, params: BiEventFields) => void;
  openHelpCenter: (articleId: string) => void;
  onSettingsIconClick: (actionName: string, isEmptyState: boolean) => void;
}

const getMainTitle = () => {
  return translate('CONTENT_MANAGER_6_BUTTON_MAIN_TITLE');
};

const WelcomePageHeader: React.FC<WelcomePageHeaderProps> = ({
  canEditSiteStructure,
  canSaveSchemas,
  isFirstTimeExperience,
  openAddPresetPanel,
  openCreateCollectionPanel,
  openVideoPanel,
  sendBi,
  openHelpCenter,
}) => (
  <div
    className={[styles.welcomePageHeader]
      .concat(isFirstTimeExperience ? styles.isFirstTimeExperience : [])
      .join(' ')}
  >
    <div className={styles.actionsContainer}>
      <VideoTooltip
        openVideoPanel={() => {
          openVideoPanel(
            translate('CONTENT_MANAGER_6_BUTTON_MAIN_MENU_TOOLTIP_VIDEO_ID'),
            isFirstTimeExperience,
          );
        }}
        title="CONTENT_MANAGER_6_BUTTON_MAIN_MENU_TOOLTIP_VIDEO_TITLE"
        subtitle="CONTENT_MANAGER_6_BUTTON_MAIN_MENU_TOOLTIP_VIDEO_SUBTITLE"
        imageUrl={
          'addPanelData/sections/dataBinding/contentManagerVideoThumbnail.png'
        }
        videoUrl={getYoutubeUrl(
          translate('CONTENT_MANAGER_6_BUTTON_MAIN_MENU_TOOLTIP_VIDEO_ID'),
        )}
        isEmptyState={isFirstTimeExperience}
        sendBi={sendBi}
        tabName="main"
      />
    </div>

    <div className={styles.info}>
      <img
        className={styles.illustration}
        src={util.media.getMediaUrl(
          'addPanelData/sections/dataBinding/contentManager.png',
        )}
      />
      <Heading multiline={false} shouldTranslate={false} appearance="h3">
        {getMainTitle()}
      </Heading>
      <div>
        <TextLabel
          type="T02"
          value={'CONTENT_MANAGER_6_BUTTON_MAIN_SUBTITLE'}
          className={styles.description}
          enableEllipsis={false}
        />
      </div>
    </div>
    <Divider />
    <MainActionItems
      canEditSiteStructure={canEditSiteStructure}
      canSaveSchemas={canSaveSchemas}
      isFirstTimeExperience={isFirstTimeExperience}
      openAddPresetPanel={openAddPresetPanel}
      openCreateCollectionPanel={openCreateCollectionPanel}
      openHelpCenter={openHelpCenter}
      sendBi={sendBi}
    />
  </div>
);

export default WelcomePageHeader;
