import constants from '@/constants';
import { isResponsiveEditor } from '@wix/santa-editor-utils';
import StylableEditorCSSPanel from './stylableEditorCSSPanel';
import stylableEditorPanelFactory from './stylableEditorPanelFactory';
import stylableEditorPickerPanelProps from './stylableEditorPickerPanelProps';

const { EDITORX_THEME_REF, DESIGN_PANEL_THEME_REF, DEFAULT_PANEL_WIDTH } =
  constants.STYLABLE.EDITOR;
const THEME_NAME = isResponsiveEditor()
  ? EDITORX_THEME_REF
  : DESIGN_PANEL_THEME_REF;

const {
  FillPickerProps,
  ColorPickerProps,
  BoxShadowPickerProps,
  StateListDialogProps,
} = stylableEditorPickerPanelProps;

export default {
  fillPickerPanel: stylableEditorPanelFactory(
    'FillPicker',
    `stylable-editor-fill-picker ${THEME_NAME}`,
    FillPickerProps,
    {
      width: DEFAULT_PANEL_WIDTH,
    },
  ),
  colorPickerPanel: stylableEditorPanelFactory(
    'ColorPicker',
    `stylable-editor-color-picker ${THEME_NAME}`,
    ColorPickerProps,
    {
      width: DEFAULT_PANEL_WIDTH,
      height: 428,
    },
  ),
  boxShadowPickerPanel: stylableEditorPanelFactory(
    'SingleShadowInput',
    `stylable-editor-box-shadow-picker ${THEME_NAME}`,
    BoxShadowPickerProps,
    {
      width: DEFAULT_PANEL_WIDTH,
      height: 568,
    },
    'stylable-editor-box-shadow-picker-panel-content-wrapper',
  ),
  textShadowPickerPanel: stylableEditorPanelFactory(
    'TextShadowLayerInput',
    `stylable-editor-box-shadow-picker ${THEME_NAME}`,
    BoxShadowPickerProps,
    {
      width: DEFAULT_PANEL_WIDTH,
      height: 418,
    },
    'stylable-editor-box-shadow-picker-panel-content-wrapper',
  ),
  stateListDialog: stylableEditorPanelFactory(
    'StateListDialog',
    `stylable-editor-state-list-dialog ${THEME_NAME}`,
    StateListDialogProps,
    {
      width: DEFAULT_PANEL_WIDTH,
      height: 320,
    },
    `stylable-editor-box-state-list-dialog-content-wrapper ${THEME_NAME}`,
  ),
  cssPanel: StylableEditorCSSPanel,
};
