import type { EditorAPI } from '@/editorAPI';
import type { CompRef } from 'types/documentServices';

export function getStageAnchorLineY(editorAPI: EditorAPI, compRef: CompRef) {
  return editorAPI.components.layout.getRelativeToStructure(compRef).y;
}

export function getStageAnchorLineYConsideringScroll(
  editorAPI: EditorAPI,
  compRef: CompRef,
) {
  return editorAPI.components.layout.getRelativeToScreenConsideringScroll(
    compRef,
  ).y;
}
