// @ts-nocheck
import gfppUtils from './gfppUtils';
import {
  editorDataFunction,
  componentInstanceData,
} from '@wix/editor-component-data-resolver';
import constants from '@/constants';

const { ACTIONS } = constants.ROOT_COMPS.GFPP;

export default {
  getGfppData: editorDataFunction(
    componentInstanceData('componentData'),
    (compData) => ({
      mainActions: [
        {
          label: 'gfpp_mainaction_clipart',
          isApplied: gfppUtils.isDataDefined(compData, 'uri'),
          actionType: ACTIONS.CHANGE_MEDIA,
          category: 'CLIPART',
        },
      ],
      enabledActions: [
        ACTIONS.SETTINGS,
        ACTIONS.CROP,
        ACTIONS.FILTERS,
        ACTIONS.ANIMATION,
        ACTIONS.LINK,
        ACTIONS.HELP,
      ],
      mobileEnabledActions: [
        ACTIONS.HIDE,
        ACTIONS.CROP,
        ACTIONS.HELP,
        ACTIONS.ANIMATION,
      ],
      helpId: '44a1692d-6441-4da6-ab6d-bd7e69bd90f3',
    }),
  ),
};
