// @ts-nocheck
import constants from '@/constants';
import * as helpIds from '@/helpIds';
import * as stateManagement from '@/stateManagement';
import * as coreBi from '@/coreBi';
import { bi } from '@/stateManagement';
import gfppDataUtils from '../../utils/gfppDataUtils';

const { clearHoverBox } = stateManagement.hoverBox.actions;
const { ACTIONS } = constants.ROOT_COMPS.GFPP;
const { popups } = coreBi.events;

const clickCloseAction = (editorAPI) => {
  editorAPI.pages.popupPages.close();
  editorAPI.store.dispatch(clearHoverBox());
  editorAPI.store.dispatch(
    bi.actions.event(popups.exit_lightbox_mode_click, { origin: 'gfpp' }),
  );
};

export default {
  untranslatable: true,
  mainActions: [
    {
      label: 'POPUPS_GFPP_CLOSE_BUTTON',
      onClick: clickCloseAction,
    },
    {
      label: 'POPUPS_X_GFPP_MainAction',
      isSelected: gfppDataUtils.getPanelStateFn(ACTIONS.DESIGN),
      onClick: gfppDataUtils.getTogglePanelFn(ACTIONS.DESIGN),
    },
  ],
  mobileMainActions: [
    {
      label: 'POPUPS_GFPP_CLOSE_BUTTON',
      onClick: clickCloseAction,
    },
  ],
  enabledActions: [ACTIONS.DESIGN, ACTIONS.ANIMATION, ACTIONS.HELP],
  mobileEnabledActions: [ACTIONS.HIDE, ACTIONS.HELP, ACTIONS.ANIMATION],
  presetActions: {
    //TODO: change animation to design like props system
    animation: {
      onClick: gfppDataUtils.getTogglePanelFn('animation', {
        helpId: helpIds.ANIMATION.CLOSE_BUTTON,
        customizeHelpId: helpIds.ANIMATION.CUSTOMIZE_CLOSE_ICON_BUTTON,
      }),
    },
    help: {
      helpId: '3a678416-a3d6-4edd-87f6-3e45e2d22719',
      mobileHelpId: '29d6488a-18ef-47eb-aa6d-439cca31fbf3',
    },
  },
};
