import _ from 'lodash';
import * as stateManagement from '@/stateManagement';
import * as coreBi from '@/coreBi';
import keyboardShortcutsActionMap from '@/keyboardShortcuts';
import selectComponentShortcutsActions from '../actions/selectComponentShortcutsActions';
import type { CompRef } from 'types/documentServices';
import type { EditorAPI } from '@/editorAPI';

const keyboardMap = keyboardShortcutsActionMap.interactions;
const INTERACTIONS_CONTEXT_SHORTCUTS: AnyFixMe = {};

const {
  isShownOnlyOnVariant,
  getCompVariantPointer,
  isLastUndoEnterInteractionMode,
} = stateManagement.interactions.selectors;
const { translateComponentTo } = stateManagement.interactions.actions;
const editorKeyboardMap = keyboardShortcutsActionMap.editor;

function moveComponentToDirection(
  editorAPI: EditorAPI,
  axis: 'x' | 'y',
  amountToMove: number,
  event: KeyboardEvent,
) {
  if (event) {
    event.preventDefault();
  }
  const compRef = editorAPI.selection.getSelectedComponents()[0];
  const compRestrictions = editorAPI.getCompRestrictions(compRef);
  if (
    (axis === 'x' && !compRestrictions.horizontallyMovable) ||
    (axis === 'y' && !compRestrictions.verticallyMovable)
  ) {
    return;
  }
  if (isShownOnlyOnVariant(editorAPI, compRef)) {
    selectComponentShortcutsActions.moveComponent(
      editorAPI,
      axis,
      amountToMove,
    );
  } else {
    translateComponentToDirection(editorAPI, compRef, axis, amountToMove);
  }
}

function translateComponentToDirection(
  editorAPI: EditorAPI,
  compRef: CompRef,
  axis: 'x' | 'y',
  amountToMove: number,
): void {
  const compVariantPointer = getCompVariantPointer(editorAPI.store.getState());
  const currentTranslations =
    editorAPI.components.transformations.get(compVariantPointer)?.translate;

  if (currentTranslations) {
    amountToMove += currentTranslations[axis].value;
  }

  translateComponentTo(editorAPI, compRef, axis, amountToMove);
}

export default {
  init(editorAPI: EditorAPI) {
    INTERACTIONS_CONTEXT_SHORTCUTS[keyboardMap.EXIT_INTERACTION.key] = () => {
      editorAPI.store.dispatch(
        stateManagement.interactions.actions.exitInteraction(),
      );
      editorAPI.store.dispatch(
        stateManagement.bi.actions.event(
          coreBi.events.editor.ESCAPE_BUTTON_CLICKED,
          {
            mode: 'hoverbox',
          },
        ),
      );
    };

    INTERACTIONS_CONTEXT_SHORTCUTS[editorKeyboardMap.MOVE_RIGHT.key] =
      _.partial(moveComponentToDirection, editorAPI, 'x', 1);

    INTERACTIONS_CONTEXT_SHORTCUTS[editorKeyboardMap.MOVE_DOWN.key] = _.partial(
      moveComponentToDirection,
      editorAPI,
      'y',
      1,
    );

    INTERACTIONS_CONTEXT_SHORTCUTS[editorKeyboardMap.MOVE_UP.key] = _.partial(
      moveComponentToDirection,
      editorAPI,
      'y',
      -1,
    );

    INTERACTIONS_CONTEXT_SHORTCUTS[editorKeyboardMap.MOVE_LEFT.key] = _.partial(
      moveComponentToDirection,
      editorAPI,
      'x',
      -1,
    );

    INTERACTIONS_CONTEXT_SHORTCUTS[editorKeyboardMap.SHIFT_RIGHT.key] =
      _.partial(moveComponentToDirection, editorAPI, 'x', 10);

    INTERACTIONS_CONTEXT_SHORTCUTS[editorKeyboardMap.SHIFT_LEFT.key] =
      _.partial(moveComponentToDirection, editorAPI, 'x', -10);

    INTERACTIONS_CONTEXT_SHORTCUTS[editorKeyboardMap.SHIFT_UP.key] = _.partial(
      moveComponentToDirection,
      editorAPI,
      'y',
      -10,
    );

    INTERACTIONS_CONTEXT_SHORTCUTS[editorKeyboardMap.SHIFT_DOWN.key] =
      _.partial(moveComponentToDirection, editorAPI, 'y', 10);

    INTERACTIONS_CONTEXT_SHORTCUTS[keyboardMap.DELETE.key] = () => {
      const selectedComponent = editorAPI.selection.getSelectedComponents()[0];
      if (isShownOnlyOnVariant(editorAPI, selectedComponent)) {
        editorAPI.components.remove([selectedComponent]).then(() => {
          editorAPI.history.add('removed comp on interactions');
        });
      } else {
        editorAPI.store.dispatch(
          stateManagement.interactions.actions.hideComponent({
            origin: 'shortcuts',
          }),
        );
      }
    };

    INTERACTIONS_CONTEXT_SHORTCUTS[editorKeyboardMap.UNDO.key] = () => {
      if (!isLastUndoEnterInteractionMode(editorAPI)) {
        editorAPI.history.undo();
      }
    };
  },
  shortcuts: INTERACTIONS_CONTEXT_SHORTCUTS,
};
