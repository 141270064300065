import { getSectionsToCreate } from './sectionsToCreate';
import {
  removeAnchors,
  replaceAnchorsToSectionsOnMenu,
  clearSectionsFromMasterPageAnchorMenu,
  clearSectionsFromPageAnchorsMenu,
} from './anchorTasks';
import {
  addSections,
  setSectionsContentRole,
  removeRedundantChildren,
  reParentPropertiesOfRedundant,
  reParentDesktopChildren,
  reLayoutDesktopChildren,
} from './commonTasks';
import {
  reLayoutMobileChildren,
  reParentMobileChildren,
  updateComponentsMobileHintsOnAPage,
} from './mobileTasks';
import { MigrationFlow } from '../../types';
import { isSimulateFailureEnabled } from '../../utils';
import { waitForChangesAppliedAndCheckTimeLimit } from '../migrationUtils';

import type { CompRef } from 'types/documentServices';
import type { SendBiFunction } from 'types/bi';
import type { SectionsMigrationScope as Scope } from '../../scope';
import type { SectionDescription, GroupingItem } from '../types';
import {
  ensureEveryPageHasSections,
  ensureOnlySectionsInRoot,
} from './exceptionCatchers';

export async function migratePagesToSectionsTransaction(
  scope: Scope,
  {
    flow,
    pagesRefs,
    pagesGrouping,
  }: {
    flow: MigrationFlow;
    pagesRefs: CompRef[];
    pagesGrouping: GroupingItem[][];
  },
): Promise<{
  createdSections: SectionDescription[][];
  removedComponents: Set<string>;
}> {
  const startTime = performance.now();
  const { documentServices } = scope.editorAPI;
  const sendBI: SendBiFunction = (event, fields) => {
    scope.editorAPI.bi.event(event, {
      ...fields,
      flow,
    });
  };

  const removedComponents = new Set<string>();

  const pagesSections = pagesRefs.map((pageRef, i) => {
    const sectionsToCreate = getSectionsToCreate(documentServices, {
      pageRef,
      grouping: pagesGrouping[i],
      flow,
    });
    return addSections(documentServices, pageRef, sectionsToCreate);
  });

  if (isSimulateFailureEnabled()) {
    throw new Error('Migration failure is simulated by experiment');
  }

  await waitForChangesAppliedAndCheckTimeLimit(
    documentServices,
    startTime,
    flow,
  ); // wait for section adding into the document

  ensureEveryPageHasSections(documentServices, pagesRefs);

  documentServices.mobileConversion.syncMobileSite({
    skipMobileHintsValidation: true,
  }); // add new sections to mobile document

  await waitForChangesAppliedAndCheckTimeLimit(
    documentServices,
    startTime,
    flow,
  ); // wait for section adding into the mobile document

  const pagesSectionsWithReParentedChildren = pagesRefs.map((pageRef, i) => {
    reParentDesktopChildren(
      documentServices,
      pageRef,
      pagesSections[i],
      sendBI,
    );

    return reParentMobileChildren(
      documentServices,
      pageRef,
      pagesSections[i],
      sendBI,
    );
  });

  await waitForChangesAppliedAndCheckTimeLimit(
    documentServices,
    startTime,
    flow,
  ); // wait for re-parenting

  pagesRefs.forEach((pageRef, i) => {
    setSectionsContentRole(documentServices, pagesSections[i]);

    const removedRedundantChildren = removeRedundantChildren(
      documentServices,
      pagesSectionsWithReParentedChildren[i],
    );

    removedRedundantChildren.forEach((id) => removedComponents.add(id));

    reParentPropertiesOfRedundant(
      documentServices,
      pagesSectionsWithReParentedChildren[i],
    );

    reLayoutDesktopChildren(
      documentServices,
      pagesSectionsWithReParentedChildren[i],
    );

    if (flow !== MigrationFlow.ADI) {
      clearSectionsFromPageAnchorsMenu(
        documentServices,
        pageRef,
        pagesSections[i],
      );
    }
  });

  if (flow === MigrationFlow.ADI) {
    replaceAnchorsToSectionsOnMenu(documentServices, pagesRefs, pagesSections);
  } else {
    clearSectionsFromMasterPageAnchorMenu(
      documentServices,
      pagesRefs,
      pagesSections,
    );
  }

  await waitForChangesAppliedAndCheckTimeLimit(
    documentServices,
    startTime,
    flow,
  ); // wait for viewer changes

  ensureOnlySectionsInRoot(documentServices, pagesRefs);

  documentServices.mobileConversion.runMobileMergeOnAllPagesWithoutLayoutOverwrite();

  await waitForChangesAppliedAndCheckTimeLimit(
    documentServices,
    startTime,
    flow,
  ); // wait for mobile algo

  pagesRefs.forEach((pageRef, i) => {
    reLayoutMobileChildren(
      documentServices,
      pageRef,
      pagesSectionsWithReParentedChildren[i],
    );

    updateComponentsMobileHintsOnAPage(documentServices, pageRef); // convert mobile structure to desktop mobileHints

    if (flow === MigrationFlow.ADI) {
      const removedAnchors = removeAnchors(documentServices, pageRef);

      removedAnchors.forEach((id) => removedComponents.add(id));
    }
  });

  await waitForChangesAppliedAndCheckTimeLimit(
    documentServices,
    startTime,
    flow,
  ); // wait for re-layout

  documentServices.mobileConversion.runMobileMergeOnAllPagesWithoutLayoutOverwrite();

  await waitForChangesAppliedAndCheckTimeLimit(
    documentServices,
    startTime,
    flow,
  ); // wait for mobile algo

  ensureOnlySectionsInRoot(documentServices, pagesRefs);

  return {
    createdSections: pagesSections,
    removedComponents,
  };
}
