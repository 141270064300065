import { ErrorReporter } from '@wix/editor-error-reporter';
import { DEBUG } from '../constants';

import type { SectionsMigrationScope as Scope } from '../scope';

export class TimeLimitError extends Error {}

export class PartialMigrationError extends Error {}

export class CanceledMigrationError extends Error {}

export class SaveError extends Error {}

export class MigrationStepError extends Error {}

export function isTimeLimitError(error: unknown): error is TimeLimitError {
  return error instanceof TimeLimitError;
}

export function isPartialMigrationError(
  error: unknown,
): error is PartialMigrationError {
  return error instanceof PartialMigrationError;
}

export function isMigrationCancelled(
  error: unknown,
): error is CanceledMigrationError {
  return error instanceof CanceledMigrationError;
}

export function isSaveError(error: unknown): error is SaveError {
  return error instanceof SaveError;
}

export const errorReporter = (
  error: Error | unknown,
  tags?: { [key: string]: boolean },
) => {
  // avoid time limit and migration canceled by user errors in Sentry
  const skipSentryLogging =
    isTimeLimitError(error) ||
    isPartialMigrationError(error) ||
    isMigrationCancelled(error);

  if (!skipSentryLogging) {
    ErrorReporter.captureException(error, {
      tags: {
        sectionsMigration: true,
        ...tags,
      },
    });
  }

  console.error(error);
};

export const debugReporter = (
  scope: Scope,
  type: DEBUG = DEBUG.LOG,
  message: string,
  ...data: any[]
) => {
  if (scope.editorParamsAPI.isDebug) {
    /* eslint-disable no-console */
    // @ts-expect-error
    console[type](`[Sections Migration]: ${message}`, ...data);
    /* eslint-enable no-console */
  }
};
