/**
 * Please ctrl/cmd + click on biEvents to see the schema :)
 * @type {Object.<String, biEvent>}
 */
const events = {
  MANAGE_PANEL_BUTTON_CLICK: {
    evid: 205,
    src: 38,
    endpoint: 'editor',
    fields: {
      button_name: 'string',
      app_id: 'string',
      app_name: 'string',
    },
  },
};

export { events as biEvents };
