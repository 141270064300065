import * as coreBi from '@/coreBi';
import * as helpIds from '@/helpIds';
import * as tpa from '@/tpa';
import { ErrorReporter } from '@wix/editor-error-reporter';
import * as util from '@/util';
import type { MapDispatchToProps, MapStateToProps } from 'types/redux';
import type {
  MembersWelcomeDispatchProps,
  MembersWelcomeOwnProps,
  MembersWelcomeStateProps,
} from './types';
import {
  EditorType,
  InstallationOriginType,
  InstallInitiator,
} from '@wix/platform-editor-sdk';

export const mapStateToProps: MapStateToProps<
  MembersWelcomeStateProps,
  MembersWelcomeOwnProps
> = ({ editorAPI }) => ({
  welcomeSectionTemplate: editorAPI.addPanelInfra.welcomeSection,
});

const getEditorAPI = (
  dispatch: AnyFixMe,
  getState: AnyFixMe,
  { editorAPI }: AnyFixMe,
) => editorAPI;

export const mapDispatchToProps: MapDispatchToProps<
  MembersWelcomeDispatchProps,
  MembersWelcomeOwnProps
> = (dispatch) => {
  const editorAPI = dispatch(getEditorAPI);
  const { dsRead } = editorAPI;

  return {
    installMembersApp() {
      const errorForFailure = new Error('Error while installing members');
      util.fedopsLogger.interactionStarted(
        util.fedopsLogger.INTERACTIONS.ADD_MEMBERS_FROM_EDITOR,
      );
      const membersAppDefId = dsRead.platform.editorApps.SANTA_MEMBERS.appDefId;
      dsRead.tpa.appMarket
        .getDataAsync(membersAppDefId)
        .then(function (marketData: AnyFixMe) {
          const appName = marketData?.name;
          tpa.services.tpaAppInstallingService.prePageAppInstalling(
            editorAPI,
            appName,
            membersAppDefId,
            { showPageAddedPanel: true },
          );
          const type = tpa.constants.APP.TYPE.PLATFORM_ONLY;
          const biInitiator = tpa.constants.BI.initiator.EDITOR;
          const options = {
            originType: tpa.constants.BI.type.ADD_APP_ADD_PANEL,
          };
          const onSuccess = (data: AnyFixMe) => {
            tpa.services.tpaAppInstallingService.onPageAppInstallingCompleted(
              editorAPI,
              appName,
              membersAppDefId,
              data,
            );
            util.fedopsLogger.interactionEnded(
              util.fedopsLogger.INTERACTIONS.ADD_MEMBERS_FROM_EDITOR,
            );
          };
          const platformOrigin = {
            type: EditorType.Classic,
            initiator: InstallInitiator.Editor,
            info: {
              type: InstallationOriginType.AddPanel,
            },
          };
          tpa.services.tpaAddAppService.addApp(
            editorAPI,
            membersAppDefId,
            appName,
            null,
            type,
            biInitiator,
            false,
            {},
            options,
            onSuccess,
            platformOrigin,
          );
        })
        .catch(function (e: AnyFixMe) {
          if (!e.stack) {
            errorForFailure.message += `${e.message} ${errorForFailure.message}`;
            ErrorReporter.captureException(errorForFailure, {
              tags: { tpaInstallation: 'Members' },
            });
          } else {
            ErrorReporter.captureException(e, {
              tags: { tpaInstallation: 'Members' },
            });
          }
        });
      const biParams = {
        app_id: membersAppDefId,
        app_name: 'members',
        origin: 'addPanel',
      };
      editorAPI.bi.event(
        coreBi.events.addPanel.SITE_APP_INSTALL_AGREE_CLICK,
        biParams,
      );
    },
    openMembersHelpSection() {
      editorAPI.panelManager.openHelpCenter(helpIds.ADD_PANEL.MEMBERS_WELCOME);
    },
  };
};
