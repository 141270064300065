'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')

function getSection({appDefinitionId = null} = {}) {
    const result = {
        'type': addPanelDataConsts.SECTIONS_TYPES.PRESET,
        'hide': false,
        'title': 'add_section_label_site_search',
        'subNavigationTitle': 'add_section_label_site_search',
        'id': 'add_section_label_site_search',
        // 'appDefinitionId': ...,
        'presetTitle': 'add_section_label_site_search',
        "automationId": "add-panel-section-searchBoxSection",
        'subNavigationHide': false,
        'showSectionHeader': true,
        'additionalBehaviours': {
            'labelMode': addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
            'infoIcon': addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
            'hoverImageAction': addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
            'iconEnabledForComps': {}
        },
        'props': {
            'image': 'addPanelData/sections/searchSection_en/searchSection_en.png',
            'imageHover': null,
            'items': [
                {
                    'id': 'none_1',
                    'structure': {
                        'type': 'Component',
                        'skin': 'wixui.skins.SearchBox',
                        'layout': {
                            'width': 305,
                            'height': 46,
                            'x': 9,
                            'y': 18,
                            'scale': 1,
                            'rotationInDegrees': 0,
                            'fixedPosition': false
                        },
                        'componentType': 'wixui.SearchBox',
                        'data': {
                            'type': 'SearchBox',
                            'metaData': {
                                'isPreset': false,
                                'schemaVersion': '1.0',
                                'isHidden': false
                            },
                            'placeholder': 'Search...'
                        },
                        'props': {
                            'type': 'SearchBoxProperties',
                            'metaData': {
                                'schemaVersion': '1.0',
                                'autoGenerated': false
                            },
                            'layout': 'icon',
                            'iconAlignment': 'left',
                            'textAlignment': 'left',
                            'iconSpacing': 14,
                            'textSpacing': 12
                        },
                        'mobileHints': {
                            'type': 'MobileHints',
                            'author': 'duplicate',
                            'originalCompId': 'comp-jvz9flyy',
                            'recommendedScale': 1,
                            'recommendedWidth': 300,
                            'recommendedHeight': 44
                        },
                        'style': {
                            'type': 'ComponentStyle',
                            'metaData': {
                                'isPreset': false,
                                'schemaVersion': '1.0',
                                'isHidden': false
                            },
                            'style': {
                                'properties': {
                                    'alpha-buttonColor': '0',
                                    'alpha-buttonFocusColor': '0',
                                    'alpha-iconColor': '1',
                                    'alpha-iconFocusColor': '1',
                                    'boxShadowToggleOn-inputShadow': 'false',
                                    'buttonBackgroundColor': '#414141',
                                    'buttonFocusBackgroundColor': '#2B2B2B',
                                    'buttonFocusIconColor': '#FFFFFF',
                                    'buttonHoverBackgroundColor': '#2B2B2B',
                                    'buttonHoverIconColor': '#FFFFFF',
                                    'buttonIconColor': '#FFFFFF',
                                    'iconColor': '#000000',
                                    'inputBackgroundColor': '#F2EEE4',
                                    'inputBorderColor': '#323232',
                                    'inputBorderWidth': '0',
                                    'inputFocusBackgroundColor': '#F2EEE4',
                                    'inputFocusBorderColor': '#323232',
                                    'inputFocusBorderWidth': '0px',
                                    'inputFont': 'normal normal 700 16px/1.4em bree-w01-thin-oblique',
                                    'inputHoverBackgroundColor': '#E8E1D1',
                                    'inputHoverBorderColor': '#323232',
                                    'inputHoverBorderWidth': '0px',
                                    'inputIconColor': '#000000',
                                    'inputPlaceholderColor': '#999999',
                                    'inputPlaceholderHoverTextColor': '#999999',
                                    'inputPlaceholderTextColor': '#999999',
                                    'inputShadow': '0px 1px 4px 0px rgba(0,0,0,0.6)',
                                    'inputTextColor': '#000000'
                                },
                                'propertiesSource': {
                                    'alpha-buttonColor': 'value',
                                    'alpha-buttonFocusColor': 'value',
                                    'alpha-iconColor': 'value',
                                    'alpha-iconFocusColor': 'value',
                                    'boxShadowToggleOn-inputShadow': 'value',
                                    'buttonBackgroundColor': 'value',
                                    'buttonFocusBackgroundColor': 'value',
                                    'buttonFocusIconColor': 'value',
                                    'buttonHoverBackgroundColor': 'value',
                                    'buttonHoverIconColor': 'value',
                                    'buttonIconColor': 'value',
                                    'iconColor': 'value',
                                    'inputBackgroundColor': 'value',
                                    'inputBorderColor': 'value',
                                    'inputBorderWidth': 'value',
                                    'inputFocusBackgroundColor': 'value',
                                    'inputFocusBorderColor': 'value',
                                    'inputFocusBorderWidth': 'value',
                                    'inputFont': 'value',
                                    'inputHoverBackgroundColor': 'value',
                                    'inputHoverBorderColor': 'value',
                                    'inputHoverBorderWidth': 'value',
                                    'inputIconColor': 'value',
                                    'inputPlaceholderColor': 'value',
                                    'inputPlaceholderHoverTextColor': 'value',
                                    'inputPlaceholderTextColor': 'value',
                                    'inputShadow': 'value',
                                    'inputTextColor': 'value'
                                }
                            },
                            'componentClassName': 'wixui.SearchBox',
                            'pageId': '',
                            'compId': '',
                            'styleType': 'custom',
                            'skin': 'wixui.skins.SearchBox'
                        },
                        'activeModes': {},
                        'id': 'comp-jyqz1o71'
                    },
                    'preset': {
                        'rect': {
                            'width': 324,
                            'height': 74,
                            'x': 0,
                            'y': 0
                        },
                        'tags': null
                    }
                },
                {
                    'id': 'none_2',
                    'structure': {
                        'type': 'Component',
                        'skin': 'wixui.skins.SearchBox',
                        'layout': {
                            'width': 305,
                            'height': 46,
                            'x': 9,
                            'y': 80,
                            'scale': 1,
                            'rotationInDegrees': 0,
                            'fixedPosition': false
                        },
                        'componentType': 'wixui.SearchBox',
                        'data': {
                            'type': 'SearchBox',
                            'metaData': {
                                'isPreset': false,
                                'schemaVersion': '1.0',
                                'isHidden': false
                            },
                            'placeholder': 'What are you looking for?'
                        },
                        'props': {
                            'type': 'SearchBoxProperties',
                            'metaData': {
                                'schemaVersion': '1.0',
                                'autoGenerated': false
                            },
                            'layout': 'icon',
                            'iconAlignment': 'right',
                            'textAlignment': 'left',
                            'iconSpacing': 7,
                            'textSpacing': 0
                        },
                        'mobileHints': {
                            'type': 'MobileHints',
                            'author': 'duplicate',
                            'originalCompId': 'comp-jvz9flyy',
                            'recommendedScale': 1,
                            'recommendedWidth': 300,
                            'recommendedHeight': 44
                        },
                        'style': {
                            'type': 'ComponentStyle',
                            'metaData': {
                                'isPreset': false,
                                'schemaVersion': '1.0',
                                'isHidden': false
                            },
                            'style': {
                                'properties': {
                                    'alpha-iconColor': '1',
                                    'alpha-inputBackgroundColor': '0',
                                    'alpha-inputHoverBackgroundColor': '0',
                                    'boxShadowToggleOn-inputShadow': 'false',
                                    'buttonBackgroundColor': '#EBE5D6',
                                    'buttonFocusBackgroundColor': '#EBE5D6',
                                    'buttonFocusIconColor': '#000000',
                                    'buttonHoverBackgroundColor': '#C9C3B1',
                                    'buttonHoverIconColor': '#000000',
                                    'buttonIconColor': '#000000',
                                    'iconColor': '#000000',
                                    'inputBackgroundColor': '#F2EEE4',
                                    'inputBorderColor': '#EBE5D6',
                                    'inputBorderWidth': '0px 0px 2px 0px',
                                    'inputFocusBorderColor': '#EBE5D6',
                                    'inputFocusBorderWidth': '0px 0px 2px 0px',
                                    'inputFocusIconColor': '#000000',
                                    'inputFont': 'normal normal normal 14px/1.4em libre+baskerville',
                                    'inputHoverBorderColor': '#C9C3B1',
                                    'inputHoverBorderWidth': '0px 0px 2px 0px',
                                    'inputHoverIconColor': '#000000',
                                    'inputIconColor': '#000000',
                                    'inputPlaceholderTextColor': '#565656',
                                    'inputShadow': '0px 1px 4px 0px rgba(0,0,0,0.6)'
                                },
                                'propertiesSource': {
                                    'alpha-iconColor': 'value',
                                    'alpha-inputBackgroundColor': 'value',
                                    'alpha-inputHoverBackgroundColor': 'value',
                                    'boxShadowToggleOn-inputShadow': 'value',
                                    'buttonBackgroundColor': 'value',
                                    'buttonFocusBackgroundColor': 'value',
                                    'buttonFocusIconColor': 'value',
                                    'buttonHoverBackgroundColor': 'value',
                                    'buttonHoverIconColor': 'value',
                                    'buttonIconColor': 'value',
                                    'iconColor': 'value',
                                    'inputBackgroundColor': 'value',
                                    'inputBorderColor': 'value',
                                    'inputBorderWidth': 'value',
                                    'inputFocusBorderColor': 'value',
                                    'inputFocusBorderWidth': 'value',
                                    'inputFocusIconColor': 'value',
                                    'inputFont': 'value',
                                    'inputHoverBorderColor': 'value',
                                    'inputHoverBorderWidth': 'value',
                                    'inputHoverIconColor': 'value',
                                    'inputIconColor': 'value',
                                    'inputPlaceholderTextColor': 'value',
                                    'inputShadow': 'value'
                                }
                            },
                            'componentClassName': 'wixui.SearchBox',
                            'pageId': '',
                            'compId': '',
                            'styleType': 'custom',
                            'skin': 'wixui.skins.SearchBox'
                        },
                        'activeModes': {},
                        'id': 'comp-jyqz9liv'
                    },
                    'preset': {
                        'rect': {
                            'width': 324,
                            'height': 62,
                            'x': 0,
                            'y': 74
                        },
                        'tags': null
                    }
                },
                {
                    'id': 'none_3',
                    'structure': {
                        'type': 'Component',
                        'skin': 'wixui.skins.SearchBox',
                        'layout': {
                            'width': 305,
                            'height': 43,
                            'x': 9,
                            'y': 151,
                            'scale': 1,
                            'rotationInDegrees': 0,
                            'fixedPosition': false
                        },
                        'componentType': 'wixui.SearchBox',
                        'data': {
                            'type': 'SearchBox',
                            'metaData': {
                                'isPreset': false,
                                'schemaVersion': '1.0',
                                'isHidden': false
                            },
                            'placeholder': 'Search...'
                        },
                        'props': {
                            'type': 'SearchBoxProperties',
                            'metaData': {
                                'schemaVersion': '1.0',
                                'autoGenerated': false
                            },
                            'layout': 'icon',
                            'iconAlignment': 'left',
                            'textAlignment': 'left',
                            'iconSpacing': 14,
                            'textSpacing': 12
                        },
                        'mobileHints': {
                            'type': 'MobileHints',
                            'author': 'duplicate',
                            'originalCompId': 'comp-jvz9flyy',
                            'recommendedScale': 1,
                            'recommendedWidth': 300,
                            'recommendedHeight': 44
                        },
                        'style': {
                            'type': 'ComponentStyle',
                            'metaData': {
                                'isPreset': false,
                                'schemaVersion': '1.0',
                                'isHidden': false
                            },
                            'style': {
                                'properties': {
                                    'alpha-buttonColor': '0',
                                    'alpha-buttonFocusColor': '0',
                                    'alpha-iconColor': '1',
                                    'alpha-iconFocusColor': '1',
                                    'boxShadowToggleOn-inputShadow': 'false',
                                    'buttonBackgroundColor': '#414141',
                                    'buttonFocusBackgroundColor': '#2B2B2B',
                                    'buttonHoverBackgroundColor': '#2B2B2B',
                                    'iconColor': '#000000',
                                    'inputBackgroundColor': '#2B2B2B',
                                    'inputBorderColor': '#000000',
                                    'inputBorderRadius': '4px 4px 4px 4px',
                                    'inputBorderWidth': '0',
                                    'inputFocusBackgroundColor': '#323232',
                                    'inputFocusBorderWidth': '0px',
                                    'inputFocusIconColor': '#FFFFFF',
                                    'inputFont': 'normal normal normal 14px/1.4em libre+baskerville',
                                    'inputHoverBackgroundColor': '#2B2B2B',
                                    'inputHoverBorderWidth': '0px',
                                    'inputHoverIconColor': '#FFFFFF',
                                    'inputIconColor': '#FFFFFF',
                                    'inputPlaceholderColor': '#FFFFFF',
                                    'inputPlaceholderHoverTextColor': '#999999',
                                    'inputPlaceholderTextColor': '#999999',
                                    'inputShadow': '0px 1px 4px 0px rgba(0,0,0,0.6)',
                                    'inputTextColor': '#FFFFFF'
                                },
                                'propertiesSource': {
                                    'alpha-buttonColor': 'value',
                                    'alpha-buttonFocusColor': 'value',
                                    'alpha-iconColor': 'value',
                                    'alpha-iconFocusColor': 'value',
                                    'boxShadowToggleOn-inputShadow': 'value',
                                    'buttonBackgroundColor': 'value',
                                    'buttonFocusBackgroundColor': 'value',
                                    'buttonHoverBackgroundColor': 'value',
                                    'iconColor': 'value',
                                    'inputBackgroundColor': 'value',
                                    'inputBorderColor': 'value',
                                    'inputBorderRadius': 'value',
                                    'inputBorderWidth': 'value',
                                    'inputFocusBackgroundColor': 'value',
                                    'inputFocusBorderWidth': 'value',
                                    'inputFocusIconColor': 'value',
                                    'inputFont': 'value',
                                    'inputHoverBackgroundColor': 'value',
                                    'inputHoverBorderWidth': 'value',
                                    'inputHoverIconColor': 'value',
                                    'inputIconColor': 'value',
                                    'inputPlaceholderColor': 'value',
                                    'inputPlaceholderHoverTextColor': 'value',
                                    'inputPlaceholderTextColor': 'value',
                                    'inputShadow': 'value',
                                    'inputTextColor': 'value'
                                }
                            },
                            'componentClassName': 'wixui.SearchBox',
                            'pageId': '',
                            'compId': '',
                            'styleType': 'custom',
                            'skin': 'wixui.skins.SearchBox'
                        },
                        'activeModes': {},
                        'id': 'comp-jyqziftr'
                    },
                    'preset': {
                        'rect': {
                            'width': 324,
                            'height': 71,
                            'x': 0,
                            'y': 136
                        },
                        'tags': null
                    }
                },
                {
                    'id': 'none_4',
                    'structure': {
                        'type': 'Component',
                        'skin': 'wixui.skins.SearchBox',
                        'layout': {
                            'width': 305,
                            'height': 46,
                            'x': 9,
                            'y': 222,
                            'scale': 1,
                            'rotationInDegrees': 0,
                            'fixedPosition': false
                        },
                        'componentType': 'wixui.SearchBox',
                        'data': {
                            'type': 'SearchBox',
                            'metaData': {
                                'isPreset': false,
                                'schemaVersion': '1.0',
                                'isHidden': false
                            },
                            'placeholder': 'Type here to search'
                        },
                        'props': {
                            'type': 'SearchBoxProperties',
                            'metaData': {
                                'schemaVersion': '1.0',
                                'autoGenerated': false
                            },
                            'layout': 'button',
                            'iconAlignment': 'left',
                            'textAlignment': 'left',
                            'iconSpacing': 30,
                            'textSpacing': 12
                        },
                        'mobileHints': {
                            'type': 'MobileHints',
                            'author': 'duplicate',
                            'originalCompId': 'comp-jvz9flyy',
                            'recommendedScale': 1,
                            'recommendedWidth': 300,
                            'recommendedHeight': 44
                        },
                        'style': {
                            'type': 'ComponentStyle',
                            'metaData': {
                                'isPreset': false,
                                'schemaVersion': '1.0',
                                'isHidden': false
                            },
                            'style': {
                                'properties': {
                                    'alpha-iconColor': '1',
                                    'alpha-inputBackgroundColor': '1',
                                    'boxShadowToggleOn-inputShadow': 'false',
                                    'buttonBackgroundColor': '#566FB8',
                                    'buttonColor': '#566FB8',
                                    'buttonFocusBackgroundColor': '#566FB8',
                                    'buttonFocusIconColor': '#FFF704',
                                    'buttonHoverBackgroundColor': '#4B64AD',
                                    'buttonHoverColor': '#566FB8',
                                    'buttonHoverIconColor': '#FFF704',
                                    'buttonIconColor': '#FFF704',
                                    'iconColor': '#FFFFFF',
                                    'iconFocusColor': '#FFF704',
                                    'iconHoverColor': '#566FB8',
                                    'inputBackgroundColor': '#FFFFFF',
                                    'inputBorderColor': '#566FB8',
                                    'inputBorderRadius': '0px 0px 0px 0px',
                                    'inputBorderWidth': '1',
                                    'inputFocusBackgroundColor': '#FFFFFF',
                                    'inputFocusBorderColor': '#566FB8',
                                    'inputFocusIconColor': '#566FB8',
                                    'inputFocusTextColor': '#566FB8',
                                    'inputFont': 'normal normal normal 16px/1.4em helvetica-w01-roman',
                                    'inputHoverBackgroundColor': '#FFFFFF',
                                    'inputHoverBorderColor': '#4B64AD',
                                    'inputHoverIconColor': '#566FB8',
                                    'inputHoverTextColor': '#FFF704',
                                    'inputIconColor': '#566FB8',
                                    'inputPlaceholderColor': '#566FB8',
                                    'inputPlaceholderHoverTextColor': '#566FB8',
                                    'inputPlaceholderTextColor': '#566FB8',
                                    'inputShadow': '0px 1px 4px 0px rgba(0,0,0,0.6)',
                                    'inputTextColor': '#566FB8'
                                },
                                'propertiesSource': {
                                    'alpha-iconColor': 'value',
                                    'alpha-inputBackgroundColor': 'value',
                                    'boxShadowToggleOn-inputShadow': 'value',
                                    'buttonBackgroundColor': 'value',
                                    'buttonColor': 'value',
                                    'buttonFocusBackgroundColor': 'value',
                                    'buttonFocusIconColor': 'value',
                                    'buttonHoverBackgroundColor': 'value',
                                    'buttonHoverColor': 'value',
                                    'buttonHoverIconColor': 'value',
                                    'buttonIconColor': 'value',
                                    'iconColor': 'value',
                                    'iconFocusColor': 'value',
                                    'iconHoverColor': 'value',
                                    'inputBackgroundColor': 'value',
                                    'inputBorderColor': 'value',
                                    'inputBorderRadius': 'value',
                                    'inputBorderWidth': 'value',
                                    'inputFocusBackgroundColor': 'value',
                                    'inputFocusBorderColor': 'value',
                                    'inputFocusIconColor': 'value',
                                    'inputFocusTextColor': 'value',
                                    'inputFont': 'value',
                                    'inputHoverBackgroundColor': 'value',
                                    'inputHoverBorderColor': 'value',
                                    'inputHoverIconColor': 'value',
                                    'inputHoverTextColor': 'value',
                                    'inputIconColor': 'value',
                                    'inputPlaceholderColor': 'value',
                                    'inputPlaceholderHoverTextColor': 'value',
                                    'inputPlaceholderTextColor': 'value',
                                    'inputShadow': 'value',
                                    'inputTextColor': 'value'
                                }
                            },
                            'componentClassName': 'wixui.SearchBox',
                            'pageId': '',
                            'compId': '',
                            'styleType': 'custom',
                            'skin': 'wixui.skins.SearchBox'
                        },
                        'activeModes': {},
                        'id': 'comp-jysr88u7'
                    },
                    'preset': {
                        'rect': {
                            'width': 324,
                            'height': 74,
                            'x': 0,
                            'y': 207
                        },
                        'tags': null
                    }
                },
                {
                    'id': 'none_5',
                    'structure': {
                        'type': 'Component',
                        'skin': 'wixui.skins.SearchBox',
                        'layout': {
                            'width': 305,
                            'height': 46,
                            'x': 9,
                            'y': 293,
                            'scale': 1,
                            'rotationInDegrees': 0,
                            'fixedPosition': false
                        },
                        'componentType': 'wixui.SearchBox',
                        'data': {
                            'type': 'SearchBox',
                            'metaData': {
                                'isPreset': false,
                                'schemaVersion': '1.0',
                                'isHidden': false
                            },
                            'placeholder': 'Search here...'
                        },
                        'props': {
                            'type': 'SearchBoxProperties',
                            'metaData': {
                                'schemaVersion': '1.0',
                                'autoGenerated': false
                            },
                            'layout': 'icon',
                            'iconAlignment': 'right',
                            'textAlignment': 'left',
                            'iconSpacing': 15,
                            'textSpacing': 12
                        },
                        'mobileHints': {
                            'type': 'MobileHints',
                            'author': 'duplicate',
                            'originalCompId': 'comp-jvz9flyy',
                            'recommendedScale': 1,
                            'recommendedWidth': 300,
                            'recommendedHeight': 44
                        },
                        'style': {
                            'type': 'ComponentStyle',
                            'metaData': {
                                'isPreset': false,
                                'schemaVersion': '1.0',
                                'isHidden': false
                            },
                            'style': {
                                'properties': {
                                    'alpha-buttonFocusColor': '1',
                                    'alpha-iconColor': '1',
                                    'alpha-inputBackgroundColor': '1',
                                    'alpha-inputHoverBorderColor': '1',
                                    'boxShadowToggleOn-inputShadow': 'false',
                                    'buttonBackgroundColor': '#1F2BF3',
                                    'buttonColor': '#FFE4EC',
                                    'buttonFocusBackgroundColor': '#1F2BF3',
                                    'buttonFocusColor': '#FFE4EC',
                                    'buttonFocusIconColor': '#FFE4EC',
                                    'buttonHoverBackgroundColor': '#1D28E0',
                                    'buttonHoverColor': '#FFE4EC',
                                    'buttonHoverIconColor': '#FFE4EC',
                                    'buttonIconColor': '#FFE4EC',
                                    'iconColor': '#FFFFFF',
                                    'iconFocusColor': '#1F2BF3',
                                    'iconHoverColor': '#FFE4EC',
                                    'inputBackgroundColor': '#FFE4EC',
                                    'inputBorderColor': '#1F2BF3',
                                    'inputBorderRadius': '30px 30px 30px 30px',
                                    'inputBorderWidth': '0',
                                    'inputFocusBackgroundColor': '#FFE4EC',
                                    'inputFocusBorderColor': '#1F2BF3',
                                    'inputFocusBorderWidth': '0px',
                                    'inputFocusIconColor': '#1F2BF3',
                                    'inputFocusTextColor': '#1F2BF3',
                                    'inputFont': 'normal normal normal 16px/1.4em futura-lt-w01-book',
                                    'inputHoverBackgroundColor': '#FFE4EC',
                                    'inputHoverBorderColor': '#1F2BF3',
                                    'inputHoverBorderWidth': '0px',
                                    'inputHoverIconColor': '#1F2BF3',
                                    'inputHoverTextColor': '#1F2BF3',
                                    'inputIconColor': '#1F2BF3',
                                    'inputPlaceholderColor': '#1F2BF3',
                                    'inputPlaceholderHoverTextColor': '#1F2BF3',
                                    'inputPlaceholderTextColor': '#1F2BF3',
                                    'inputShadow': '0px 1px 4px 0px rgba(0,0,0,0.6)',
                                    'inputTextColor': '#1F2BF3'
                                },
                                'propertiesSource': {
                                    'alpha-buttonFocusColor': 'value',
                                    'alpha-iconColor': 'value',
                                    'alpha-inputBackgroundColor': 'value',
                                    'alpha-inputHoverBorderColor': 'value',
                                    'boxShadowToggleOn-inputShadow': 'value',
                                    'buttonBackgroundColor': 'value',
                                    'buttonColor': 'value',
                                    'buttonFocusBackgroundColor': 'value',
                                    'buttonFocusColor': 'value',
                                    'buttonFocusIconColor': 'value',
                                    'buttonHoverBackgroundColor': 'value',
                                    'buttonHoverColor': 'value',
                                    'buttonHoverIconColor': 'value',
                                    'buttonIconColor': 'value',
                                    'iconColor': 'value',
                                    'iconFocusColor': 'value',
                                    'iconHoverColor': 'value',
                                    'inputBackgroundColor': 'value',
                                    'inputBorderColor': 'value',
                                    'inputBorderRadius': 'value',
                                    'inputBorderWidth': 'value',
                                    'inputFocusBackgroundColor': 'value',
                                    'inputFocusBorderColor': 'value',
                                    'inputFocusBorderWidth': 'value',
                                    'inputFocusIconColor': 'value',
                                    'inputFocusTextColor': 'value',
                                    'inputFont': 'value',
                                    'inputHoverBackgroundColor': 'value',
                                    'inputHoverBorderColor': 'value',
                                    'inputHoverBorderWidth': 'value',
                                    'inputHoverIconColor': 'value',
                                    'inputHoverTextColor': 'value',
                                    'inputIconColor': 'value',
                                    'inputPlaceholderColor': 'value',
                                    'inputPlaceholderHoverTextColor': 'value',
                                    'inputPlaceholderTextColor': 'value',
                                    'inputShadow': 'value',
                                    'inputTextColor': 'value'
                                }
                            },
                            'componentClassName': 'wixui.SearchBox',
                            'pageId': '',
                            'compId': '',
                            'styleType': 'custom',
                            'skin': 'wixui.skins.SearchBox'
                        },
                        'activeModes': {},
                        'id': 'comp-jysrooqx'
                    },
                    'preset': {
                        'rect': {
                            'width': 324,
                            'height': 71,
                            'x': 0,
                            'y': 281
                        },
                        'tags': null
                    }
                },
                {
                    'id': 'none_6',
                    'structure': {
                        'type': 'Component',
                        'skin': 'wixui.skins.SearchBox',
                        'layout': {
                            'width': 305,
                            'height': 46,
                            'x': 9,
                            'y': 365,
                            'scale': 1,
                            'rotationInDegrees': 0,
                            'fixedPosition': false
                        },
                        'componentType': 'wixui.SearchBox',
                        'data': {
                            'type': 'SearchBox',
                            'metaData': {
                                'isPreset': false,
                                'schemaVersion': '1.0',
                                'isHidden': false
                            },
                            'placeholder': 'Enter search term'
                        },
                        'props': {
                            'type': 'SearchBoxProperties',
                            'metaData': {
                                'schemaVersion': '1.0',
                                'autoGenerated': false
                            },
                            'layout': 'button',
                            'iconAlignment': 'left',
                            'textAlignment': 'left',
                            'iconSpacing': 15,
                            'textSpacing': 12
                        },
                        'mobileHints': {
                            'type': 'MobileHints',
                            'author': 'duplicate',
                            'originalCompId': 'comp-jvz9flyy',
                            'recommendedScale': 1,
                            'recommendedWidth': 300,
                            'recommendedHeight': 44
                        },
                        'style': {
                            'type': 'ComponentStyle',
                            'metaData': {
                                'isPreset': false,
                                'schemaVersion': '1.0',
                                'isHidden': false
                            },
                            'style': {
                                'properties': {
                                    'alpha-buttonFocusColor': '1',
                                    'alpha-iconColor': '1',
                                    'alpha-inputBackgroundColor': '1',
                                    'alpha-inputHoverBorderColor': '1',
                                    'boxShadowToggleOn-inputShadow': 'false',
                                    'buttonBackgroundColor': '#3D9BE9',
                                    'buttonColor': '#3D9BE9',
                                    'buttonFocusBackgroundColor': '#3D9BE9',
                                    'buttonFocusColor': '#3D9BE9',
                                    'buttonFocusIconColor': '#FFFFFF',
                                    'buttonHoverBackgroundColor': '#368FD9',
                                    'buttonHoverColor': '#3D9BE9',
                                    'buttonHoverIconColor': '#FFFFFF',
                                    'buttonIconColor': '#FFFFFF',
                                    'iconColor': '#3D9BE9',
                                    'iconFocusColor': '#3D9BE9',
                                    'iconHoverColor': '#3D9BE9',
                                    'inputBackgroundColor': '#FFFFFF',
                                    'inputBorderColor': '#3D9BE9',
                                    'inputBorderRadius': '6px 6px 6px 6px',
                                    'inputBorderWidth': '1',
                                    'inputFocusBackgroundColor': '#FFFFFF',
                                    'inputFocusBorderColor': '#3D9BE9',
                                    'inputFocusTextColor': '#8F8F8F',
                                    'inputFont': 'normal normal normal 16px/1.4em avenir-lt-w01_35-light1475496',
                                    'inputHoverBackgroundColor': '#FFFFFF',
                                    'inputHoverBorderColor': '#3186CC',
                                    'inputHoverTextColor': '#8F8F8F',
                                    'inputIconColor': '#4B64AD',
                                    'inputPlaceholderColor': '#787878',
                                    'inputPlaceholderHoverTextColor': '#8F8F8F',
                                    'inputPlaceholderTextColor': '#8F8F8F',
                                    'inputShadow': '0px 1px 4px 0px rgba(0,0,0,0.6)',
                                    'inputTextColor': '#323232'
                                },
                                'propertiesSource': {
                                    'alpha-buttonFocusColor': 'value',
                                    'alpha-iconColor': 'value',
                                    'alpha-inputBackgroundColor': 'value',
                                    'alpha-inputHoverBorderColor': 'value',
                                    'boxShadowToggleOn-inputShadow': 'value',
                                    'buttonBackgroundColor': 'value',
                                    'buttonColor': 'value',
                                    'buttonFocusBackgroundColor': 'value',
                                    'buttonFocusColor': 'value',
                                    'buttonFocusIconColor': 'value',
                                    'buttonHoverBackgroundColor': 'value',
                                    'buttonHoverColor': 'value',
                                    'buttonHoverIconColor': 'value',
                                    'buttonIconColor': 'value',
                                    'iconColor': 'value',
                                    'iconFocusColor': 'value',
                                    'iconHoverColor': 'value',
                                    'inputBackgroundColor': 'value',
                                    'inputBorderColor': 'value',
                                    'inputBorderRadius': 'value',
                                    'inputBorderWidth': 'value',
                                    'inputFocusBackgroundColor': 'value',
                                    'inputFocusBorderColor': 'value',
                                    'inputFocusTextColor': 'value',
                                    'inputFont': 'value',
                                    'inputHoverBackgroundColor': 'value',
                                    'inputHoverBorderColor': 'value',
                                    'inputHoverTextColor': 'value',
                                    'inputIconColor': 'value',
                                    'inputPlaceholderColor': 'value',
                                    'inputPlaceholderHoverTextColor': 'value',
                                    'inputPlaceholderTextColor': 'value',
                                    'inputShadow': 'value',
                                    'inputTextColor': 'value'
                                }
                            },
                            'componentClassName': 'wixui.SearchBox',
                            'pageId': '',
                            'compId': '',
                            'styleType': 'custom',
                            'skin': 'wixui.skins.SearchBox'
                        },
                        'activeModes': {},
                        'id': 'comp-jysrrfyk'
                    },
                    'preset': {
                        'rect': {
                            'width': 324,
                            'height': 77,
                            'x': 0,
                            'y': 352
                        },
                        'tags': null
                    }
                }
            ],
            'compTypes': [
                'wixui.SearchBox'
            ]
        },
        'help': {
            'hide': false,
            'text': 'add_section_info_search_box'
        }
    }

    if (appDefinitionId) {
        result.appDefinitionId = appDefinitionId
    }

    return result
}

module.exports = {
    getSection
}
