import constants from '@/constants';
import repeaterGfppUtils from '../../utils/repeaterGfppUtils';
import * as stateManagement from '@/stateManagement';
import * as util from '@/util';
import * as helpIds from '@/helpIds';
import type { GFPPDataRaw } from '@wix/editor-component-types';
import type { CompRef } from 'types/documentServices';
import type { EditorAPI } from '@/editorAPI';
import experiment from 'experiment';
const { validateForSome } = util.array;

const { ACTIONS } = constants.ROOT_COMPS.GFPP;

const gfppData: GFPPDataRaw = {
  untranslatable: true,
  mainActions(editorAPI: EditorAPI, compRefs: CompRef[] | CompRef) {
    function isSelectableRepeater(compRef: CompRef): boolean {
      const [firstChild] =
        editorAPI?.documentServices?.components?.getChildren?.(compRef) || [];

      if (!firstChild) {
        return false;
      }

      const childType = editorAPI.components.getType(firstChild);

      return childType === constants.COMP_TYPES.SELECTABLE_CONTAINER;
    }

    if (
      isSelectableRepeater(Array.isArray(compRefs) ? compRefs[0] : compRefs)
    ) {
      return [];
    }

    if (experiment.isOpen('se_enableDatabindingForAppStudio')) {
      if (
        validateForSome(
          (compRef: CompRef) =>
            stateManagement.platform.selectors.isConnectedToDataBindingController(
              compRef,
              editorAPI.dsRead,
            ),
          compRefs,
        )
      ) {
        return [];
      }
    }

    return [repeaterGfppUtils.getManageItemAction()];
  },
  enabledActions: [
    ACTIONS.LAYOUT,
    ACTIONS.ANIMATION,
    ACTIONS.STRETCH,
    ACTIONS.HELP,
  ],
  mobileEnabledActions: [ACTIONS.LAYOUT, ACTIONS.HIDE, ACTIONS.HELP],
  secondaryLanguageMainActions: [],
  presetActions: {
    help: {
      helpId: helpIds.GFPP.REPEATER,
      mobileHelpId: helpIds.GFPP.REPEATER_MOBILE,
    },
  },
};

export default gfppData;
