import _ from 'lodash';
import React, { type FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { cx, hoc } from '@/util';
import experiment from 'experiment';
import withSendBi from '../hocs/withSendBi';
import TooltipContent from '../tooltipContent';
import Button from '../topBarButton/topBarButtonWithTooltip';
import {
  mapDispatchToProps,
  mapStateToProps,
  type ZoomOutDispatchProps,
  type ZoomOutStateProps,
} from './zoomOutMappers';
import {
  AddCircle,
  MinusCircle,
} from '@wix/wix-ui-icons-common/classic-editor';
import styles from './zoomOut.scss';
import { withRestrictions } from '@/editorRestrictions';

const {
  connect,
  STORES: { EDITOR_API },
} = hoc;

const tooltipData = {
  title: 'TOPBAR_ZoomOut_Tooltip_Title',
  text: 'TOPBAR_ZoomOut_Tooltip_Body',
};

export interface ZoomOutOwnProps {
  disabled?: boolean;
  sendBi: () => void;
}

interface ZoomOutProps
  extends ZoomOutOwnProps,
    ZoomOutDispatchProps,
    ZoomOutStateProps {}

const OldZoomOut: FC<ZoomOutProps> = ({
  isZoomOutMode,
  enterZoomMode,
  exitZoomMode,
  disabled,
}) => (
  <Button
    className="top-bar-button-zoom"
    automationId={
      isZoomOutMode ? 'top-bar-button-zoom-in' : 'top-bar-button-zoom-out'
    }
    symbolName="zoomOut_v3"
    disabled={disabled}
    tooltip={<TooltipContent tooltipData={tooltipData} />}
    onClick={isZoomOutMode ? exitZoomMode : enterZoomMode}
  />
);

const ZoomOut: FC<ZoomOutProps> = ({
  isZoomOutMode,
  siteScale,
  enterZoomMode,
  exitZoomMode,
  disabled,
  isStageShifted,
}) => {
  const { t } = useTranslation();

  const Icon = isZoomOutMode ? AddCircle : MinusCircle;

  useEffect(() => {
    if (isZoomOutMode && disabled) {
      exitZoomMode();
    }
  }, [disabled, exitZoomMode, isZoomOutMode]);

  let tooltipData: { text: string };
  if (disabled) {
    tooltipData = {
      text: 'Topbar_zoomoutsection_tooltip_exitmode',
    };
  } else {
    tooltipData = {
      text: isZoomOutMode
        ? 'TOPBAR_ZoomInSection_Tooltip'
        : 'TOPBAR_ZoomOutSection_Tooltip',
    };
  }

  const getZoomButtonLabel = () => {
    if (experiment.isOpen('se_scaleForStageShiftingPanels') && isStageShifted) {
      return t('TOPBAR_ZoomSection_label', {
        scale_percent: 75,
      });
    }

    if (experiment.isOpen('se_differentStageZoomModes')) {
      const zoomNumber = String(siteScale * 100);
      return t('TOPBAR_ZoomSection_label', {
        scale_percent: zoomNumber,
      });
    }

    return t(
      isZoomOutMode
        ? 'TOPBAR_ZoomInSection_50_label'
        : 'TOPBAR_ZoomOutSection_100_label',
    );
  };

  return (
    <Button
      className={cx({
        [styles.topBarButtonSectionsZoom]: true,
        [styles.disabledTopBarButtonSectionsZoom]: disabled,
      })}
      automationId={
        isZoomOutMode ? 'top-bar-button-zoom-in' : 'top-bar-button-zoom-out'
      }
      dataHook="top-bar-button-zoom"
      customSymbol={<Icon className="btn-symbol" />}
      symbolClassName={styles.symbol}
      label={
        <span data-hook="top-bar-zoom-out-scale">{getZoomButtonLabel()}</span>
      }
      disabled={disabled}
      tooltip={<TooltipContent tooltipData={tooltipData} />}
      onClick={isZoomOutMode ? exitZoomMode : enterZoomMode}
    />
  );
};

const ZoomOutComponent = (props: ZoomOutProps) => {
  const Component = props.isSectionsEnabled ? ZoomOut : OldZoomOut;
  return <Component {...props} />;
};

const ConnectedZoomOut = _.flow(
  withRestrictions('top-bar_zoom-out'),
  connect(EDITOR_API, mapStateToProps, mapDispatchToProps),
  withSendBi,
)(ZoomOutComponent);

ConnectedZoomOut.pure = ZoomOutComponent;

export default ConnectedZoomOut;
