// @ts-nocheck
import createReactClass from 'create-react-class';
import * as core from '@/core';
import React from 'react';
import { OldMessagePanelFrame } from '../../frames';
import { translate } from '@/i18n';
import * as baseUI from '@/baseUI';
import { Composites, Illustration, RichText } from '@wix/wix-base-ui';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'deleteLastPageMessage',
  mixins: [core.mixins.editorAPIMixin],
  render() {
    return (
      <OldMessagePanelFrame
        panelName={this.props.panelName}
        confirmLabel="Pages_Settings_Delete_Last_Page_Message_Confirmation"
        panelTitle={translate('Pages_Settings_Delete_Last_Page_Message_Title')}
        className="delete-page-confirmation"
      >
        <Composites.RichTextWithIllustration>
          <Illustration>
            <baseUI.symbol name="plaster" />
          </Illustration>
          <Composites.RichText>
            <RichText type="T01">
              <p>{translate('Pages_Settings_Delete_Last_Page_Message')}</p>
              <p>{translate('Pages_Settings_Delete_Last_Page_Message2')}</p>
              <p>
                {translate('Pages_Settings_Delete_Last_Page_Message3')}
                <a
                  key="page-settings-tab-privacy-member-url"
                  href="https://www.wix.com/support/html5/"
                  target="_blank"
                >
                  <baseUI.button
                    label="Pages_Settings_Delete_Last_Page_Message_Link"
                    className="btn-text"
                  />
                </a>
                .
              </p>
            </RichText>
          </Composites.RichText>
        </Composites.RichTextWithIllustration>
      </OldMessagePanelFrame>
    );
  },
});
