// @ts-nocheck
import * as addPanel from '@/oldAddPanel';

export default {
  getDesignSections() {
    return {
      titles: ['design_section_label_explore_button'],
      sections: [addPanel.sections.getSections().button.textButtonsSection],
    };
  },
};
