import type { EditorAPI } from '@/editorAPI';
import * as util from '@/util';
import { APP_DEF_IDS } from '../../constants';
import type { AppData } from 'types/documentServices';
import type { convertFAQItems } from './convertFAQItems';

export const updateWixFAQComponentData = async (
  editorAPI: EditorAPI,
  { categories, questions }: ReturnType<typeof convertFAQItems>,
) => {
  await new Promise<void>((resolve) => {
    editorAPI.tpa.app.registerOnInstalled(APP_DEF_IDS.WIX_FAQ, resolve);
  });

  const { instance, applicationId, settingsUrl } =
    editorAPI.tpa.app.getDataByAppDefId(APP_DEF_IDS.WIX_FAQ) as AppData & {
      instance: string;
      settingsUrl: string;
    };

  const faqComponentsOnStage =
    editorAPI.tpa.app.getRenderedReactCompsByApplicationId(applicationId);
  const lastAddedFaqComponent =
    faqComponentsOnStage[faqComponentsOnStage.length - 1];

  const baseUrl = new URL(settingsUrl).origin;
  const query = new URLSearchParams({
    instance,
    compId: lastAddedFaqComponent.id,
    originCompId: '',
    isSite: 'false',
  });

  await util.http.fetchJson(`${baseUrl}/api/faq?${query}`, {
    method: 'PUT',
    headers: new Headers({
      'Content-Type': 'application/json; charset=UTF-8',
    }),
    body: JSON.stringify({
      FAQItems: {
        categories,
        questions,
      },
    }),
  });

  editorAPI.tpa.app.refreshApp(lastAddedFaqComponent);
};
