'use strict'

const _ = require('lodash')
const mergePropsItems = require('./merge-props-items')

function applyItemExtension(item, extension) {
    return _.isFunction(extension) ? extension(item) : Object.assign({}, item, extension)
}

function applyItemsExtensions(items, extensions) {
    return items.map((item, i) => {
        const extension = extensions[i]

        return extension ? applyItemExtension(item, extension) : item
    })
}

/**
 * @param {Object} section
 * @param {Array} itemsExtensions
 * @returns {Object}
 */
function extendPropsItems(section, itemsExtensions) {
    const items = _.get(section, 'props.items')
    const nothingToExtend =
        !items ||
        !items.length ||
        !itemsExtensions ||
        !itemsExtensions.length

    if (nothingToExtend) {
        return section
    }

    const extendedItems = applyItemsExtensions(items, itemsExtensions)

    return mergePropsItems(section, extendedItems)
}

module.exports = extendPropsItems
