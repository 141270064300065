import { media, browserUtil } from '@/util';
import { translate } from '@/i18n';

import { APP_MANAGER_APPS_LIST, POPULAR_APPS_IMAGE_PATHS } from './constants';
import { appMarketUtils } from '@/tpa';
import { isDescriptorExists } from './services/platform/platform';
import type { EditorAPI } from '@/editorAPI';

export const isSupportedApp = (editorAPI: EditorAPI, appDefinitionId: string) =>
  APP_MANAGER_APPS_LIST.includes(appDefinitionId) ||
  (appMarketUtils.isBuilderType(
    editorAPI.platform.getAppDataByAppDefId(appDefinitionId),
  ) &&
    isDescriptorExists(editorAPI, appDefinitionId));

const pixelRatio = browserUtil.getDevicePixelRatio();
export const getPopularAppImageUrl = (appDefId: string) => {
  const values = POPULAR_APPS_IMAGE_PATHS[appDefId];
  const index = Math.min(Math.ceil(pixelRatio) - 1, values.length - 1);
  const path = values[index];

  return media.getMediaUrl(path);
};

export const translateIfExists = (str?: string) =>
  str ? translate(str) : undefined;

export const memoizePromise = <
  T extends (...args: any[]) => Promise<any>,
  P extends Parameters<T>,
>(
  fn: T,
) => {
  let promise: AnyFixMe;

  const memoizedFn = (...args: P) => {
    if (!promise) {
      promise = fn(...args).catch((e) => {
        promise = undefined;
        throw e;
      });
    }

    return promise;
  };

  return memoizedFn as (...args: P) => ReturnType<T>;
};
