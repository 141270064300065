import type {
  CompRef,
  CompVariantPointer,
  Pointer,
  ViewModes,
} from '@wix/document-services-types';
import type { SchemaValues, EffectParams } from '@wix/effects-kit';
import type { PARALLAX_FITTING_EFFECTS } from './constants';

type SettingsValue = SchemaValues['default'];

interface SettingsData {
  [k: string]: SettingsValue;
}

interface BaseEffectData {
  name: string;
  type: 'TimeAnimation';
  value: EffectParams & {
    type: 'TimeAnimationOptions';
    namedEffect: SettingsData & {
      type: string;
    };
  };
}

export interface EntranceEffectData extends BaseEffectData {
  value: BaseEffectData['value'] & { fill: 'backwards' };
}

export interface OngoingEffectData extends BaseEffectData {
  value: BaseEffectData['value'] & { iterations: 0 };
}

export interface ScrollEffectData {
  name: string;
  type: 'ScrubAnimation';
  value: {
    type: 'ScrubAnimationOptions';
    startOffset: {
      name: 'cover';
      offset: { value: number; type: 'percentage' };
    };
    endOffset: {
      name: 'cover';
      offset: { value: number; type: 'percentage' };
    };
    fill: 'both';
    namedEffect: SettingsData & {
      type: string;
    };
  };
}

export type EffectData =
  | EntranceEffectData
  | OngoingEffectData
  | ScrollEffectData;

export type EffectsTriggerType =
  | 'viewport-enter'
  | 'page-visible'
  | 'animation-end'
  | 'view-progress';

export type AnimationType = 'entrance' | 'ongoing' | 'scroll';

export interface Reaction {
  type: 'Play' | 'Scrub';
  component: CompRef | CompVariantPointer;
  pointer: Pointer;
  triggerType: string;
  effect: Pointer;
  once?: boolean;
}

// types for Global Animations
export interface AnimationKitDefinition {
  title: string;
  _id: string;
  animationRules: AnimationRules;
  suggestedLayoutFamily?: string;
  isPublished?: boolean;
  msid?: string;
  isGlobalAnimationsKit?: boolean;
  appliedTo?: AppliedToOptions;
  headerFooterKit?: string;
  firstSectionKit?: string;
}

export type AppliedToOptions = 'firstSection' | 'headerFooter';

export interface SiteAnimationKit {
  title?: string;
  isConnected?: boolean;
}

export interface Behavior {
  targetId?: string;
  sourceId?: string;
  name: string;
  action: string;
  duration: number;
  delay?: number;
  params: any;
  playOnce?: boolean;
  viewMode?: 'MOBILE' | 'DESKTOP';
}

export interface Effects {
  effects: {
    [key in ViewMode]: {
      entrance: EntranceEffectData;
      ongoing: OngoingEffectData;
      scroll: ScrollEffectData;
    };
  };
}

export interface EffectsAnimationRules {
  [compType: string]: Effects;
}

export interface BehaviorsAnimationRules {
  [compType: string]: {
    [compBackground: string]: {
      behaviors: {
        [key in ViewMode]: Behavior;
      };
    };
  };
}

export interface VideoAnimationRules {
  [compType: string]: {
    [videoType: string]: Effects;
  };
}

export interface TextAnimationRules {
  [compType: string]: {
    [textType: string]: Effects;
  };
}

export type AnimationRules =
  | EffectsAnimationRules
  | BehaviorsAnimationRules
  | VideoAnimationRules;

export type ViewMode = keyof ViewModes;

export type ParallaxEffectName = (typeof PARALLAX_FITTING_EFFECTS)[number];
