import _ from 'lodash';

import { sectionsOnStage, bi } from '@/stateManagement';
import constants from '@/constants';
import addPanelDataConsts from '@/addPanelDataConsts';
import { addPanelUtils } from '@/addPanelInfra';
import {
  getComponentsBelowPosition,
  shiftComponents,
  SectionTypes,
} from '@/sectionsOnStage';
import { math, fedopsLogger } from '@/util';

import { AddSectionBiOrigin } from './AddSection.constants';

import type { ThunkAction } from 'types/redux';
import type { EditorAPI } from '@/editorAPI';

export const getCustomSelectedComponents = (editorAPI: AnyFixMe) => {
  const selectedComps = editorAPI.selection.getSelectedComponents();
  if (selectedComps.length > 1) {
    return selectedComps;
  }

  const [firstSelectedComp] = selectedComps;

  if (!firstSelectedComp || editorAPI.components.is.page(firstSelectedComp)) {
    return [];
  }

  return selectedComps;
};

const getEditorAPI: ThunkAction<EditorAPI> = (
  dispatch,
  getState,
  { editorAPI }: { editorAPI: EditorAPI },
) => editorAPI;

export const mapStateToProps = ({ editorAPI }: { editorAPI: EditorAPI }) => {
  const hoveredSection = sectionsOnStage.selectors.getHoveredSection(
    editorAPI.store.getState(),
  );
  const selectedComponents = getCustomSelectedComponents(editorAPI);
  const isIntersectionWithSelected =
    hoveredSection && hoveredSection.type !== SectionTypes.VirtualSection
      ? selectedComponents.some((selectedComponent: AnyFixMe) =>
          math.getSquaresIntersection(
            editorAPI.components.layout.getRelativeToScreen(selectedComponent),
            {
              x: 0,
              y: hoveredSection.top,
              width: hoveredSection.width,
              height: hoveredSection.height,
            },
          ),
        )
      : false;

  return {
    isIntersectionWithSelected,
    hoveredSection,
  };
};

export const mapDispatchToProps = (dispatch: FunctionFixMe) => {
  const editorAPI: EditorAPI = dispatch(getEditorAPI);

  return {
    logButtonBi: (interactionName: 'hover' | 'click') => {
      dispatch(
        bi.actions.event(
          {
            evid: 1108,
          },
          {
            actionName: interactionName,
          },
        ),
      );
    },

    openAddPanel: (shouldAddAfterHoveredSection: boolean) => {
      const sectionIndex = sectionsOnStage.selectors.getHoveredSectionIndex(
        editorAPI.store.getState(),
      );

      const insertionSectionIndex = shouldAddAfterHoveredSection
        ? sectionIndex + 1
        : sectionIndex;

      const sections = sectionsOnStage.selectors.getSectionsOnStage(
        editorAPI.store.getState(),
      );

      const pageLayout = editorAPI.components.layout.getRelativeToScreen(
        editorAPI.pages.getCurrentPage(),
      );

      const addPositionY = sections[insertionSectionIndex].top - pageLayout.y;

      const componentsToShift = getComponentsBelowPosition(
        editorAPI,
        addPositionY,
      );

      const logStartOnce = _.once(() => {
        fedopsLogger.interactionStarted(
          fedopsLogger.INTERACTIONS.SECTIONS_ON_STAGE.ADD_SECTION,
        );
      });

      editorAPI.panelManager.openPanel(
        constants.ROOT_COMPS.LEFTBAR.ADD_PANEL_NAME,
        {
          origin: AddSectionBiOrigin,
          category: addPanelDataConsts.CATEGORIES_ID.STRIP,
          getPastePosition: (compDef: AnyFixMe) => {
            if (addPanelUtils.isStretchedComp(editorAPI, compDef)) {
              logStartOnce();
              return { x: 0, y: addPositionY };
            }
          },
          onComponentAddedToStage: (
            compRef: AnyFixMe,
            compDefinition: AnyFixMe,
          ) => {
            if (addPanelUtils.isStretchedComp(editorAPI, compDefinition)) {
              const componentLayout =
                editorAPI.components.layout.getRelativeToScreen(compRef);

              shiftComponents(
                editorAPI,
                componentsToShift,
                componentLayout.height,
              );

              addPanelUtils.scrollToAddedComponentIfNeeded(editorAPI, compRef);

              editorAPI.history.amend();

              fedopsLogger.interactionEnded(
                fedopsLogger.INTERACTIONS.SECTIONS_ON_STAGE.ADD_SECTION,
              );
            }
          },
        },
      );
    },
  };
};
