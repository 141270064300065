import { createSelector } from 'reselect';
import type { StateMapperArgs } from 'types/redux';

export const shouldShowLayersPanel = createSelector<
  StateMapperArgs,
  boolean,
  boolean
>(
  [(stateMapperArg) => stateMapperArg.editorAPI.zoomMode.isStageZoomMode()],
  (isStageZoomMode) => !isStageZoomMode,
);
