// @ts-nocheck
const BOOK_BUTTON_WIDGET_IMAGE_V1 =
  'wixBookings/addPanel/preset_editor_book_button.v1.jpg';
const PRESET_KEY = 'Wix_Bookings_BookButton_Widget';
const BOOK_BUTTON_WIDGET_ID = 'c7fddce1-ebf5-46b0-a309-7865384ba63f';

const BOOK_BUTTON_TITLE_KEY = 'add_section_label_book_button_input';

export {
  BOOK_BUTTON_WIDGET_IMAGE_V1,
  PRESET_KEY as BOOK_BUTTON_PRESET_KEY,
  BOOK_BUTTON_WIDGET_ID,
  BOOK_BUTTON_TITLE_KEY,
};
