import type {
  AdditionalImageData,
  CompFullStructure,
  MainMenuItem,
} from '@/addPanelInfra';
import type { SystemStyleDef } from '@/core';
import * as stateManagement from '@/stateManagement';
import * as util from '@/util';
import type {
  IconEnabledForComps,
  PropsScaleProportion,
} from '@wix/add-panel-component';
import React, {
  type ComponentType,
  forwardRef,
  type MouseEventHandler,
  useState,
} from 'react';
import type { MapDispatchToProps } from 'types/redux';

export interface Comp {
  styleId: string;
  customStyle: SystemStyleDef;
  compFullStructure: CompFullStructure;
}

export interface PreviewJSON {
  props: {};
  data: {};
  comps: Comp[];
  additionalData: AdditionalImageData[];
  additionalMatserPageData: MainMenuItem[];
  actionsForPreviewExtension: {
    labelMode: string;
    infoIcon: string;
    iconEnabledForComps: IconEnabledForComps;
    scaleProportion: PropsScaleProportion;
    fontSize: number;
    compTypesLabels: {};
  };
  siteId: string;
  compTypes: string[];
  maxFontSize: number;
  categoryId: string;
  totalSectionHeight: number;
  siteWidth: number;
}

export interface MeasureMap {
  top: Record<string, number>;
  height: Record<string, number>;
}

export interface SantaPreviewContainerOwnProps {
  rootUrl: string;
  languageCode: string;
  preview: (pageId: string, langCode: string) => PreviewJSON;
  onSiteReady: (sectionSiteHeight: number, measureMap: MeasureMap) => void;
  extraEventsHandlers: {
    onClickCapture: MouseEventHandler;
    onMouseDownCapture: MouseEventHandler;
    onMouseMove: MouseEventHandler;
    onMouseLeave: MouseEventHandler;
    onMouseUp: MouseEventHandler;
  };
  getColorToReplaceIfNeeded: (
    color: string,
    categoryId: string,
  ) => null | { value: string; wasReplaced: boolean };
}

interface SantaPreviewContainerDispatchProps {
  createSantaPreview: () => ComponentType<SantaPreviewContainerOwnProps>;
}

interface SantaPreviewContainerProps
  extends SantaPreviewContainerOwnProps,
    SantaPreviewContainerDispatchProps {}

/**
 * Supress error of passing ref to preview container
 */
const PreviewContainerComponent: React.ForwardRefRenderFunction<
  AnyFixMe,
  SantaPreviewContainerProps
> = ({ createSantaPreview, ...otherProps }, _ref) => {
  const [SantaComp] = useState(() => createSantaPreview());

  return <SantaComp {...otherProps} />;
};

const mapDispatchToProps: MapDispatchToProps<
  SantaPreviewContainerDispatchProps,
  SantaPreviewContainerOwnProps
> = (dispatch) => ({
  createSantaPreview: () =>
    dispatch(stateManagement.services.actions.createSantaPreview(React)),
});

export default util.hoc.connect<
  {},
  SantaPreviewContainerDispatchProps,
  SantaPreviewContainerOwnProps
>(
  [],
  null,
  mapDispatchToProps,
  false,
)(
  /**
   * Supress error of passing ref to preview container
   */
  forwardRef(PreviewContainerComponent),
);
