// @ts-nocheck
import React from 'react';
import createReactClass from 'create-react-class';
import _ from 'lodash';
import * as core from '@/core';
import * as util from '@/util';
import addPanelDataConsts from '@/addPanelDataConsts';
import * as symbols from '@wix/santa-editor-symbols';
import sectionWrapper from '../sectionWrapper';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  mixins: [core.mixins.editorAPIMixin],
  displayName: 'designDataPresetSection',

  getInitialState() {
    return {
      items: this.getItems(),
    };
  },

  getItemClassName(item) {
    let className = 'design-data-preset-item';
    if (item.empty) {
      className += ' empty-preset';
    }
    return className;
  },

  getItemProps(item, itemId) {
    return {
      key: 'designDataPresetItem',
      className: this.getItemClassName(item),
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      style: _.assign({}, this.renderDesign(item.design)),
      onClick: _.partial(this.props.onClick, item, itemId),
      onMouseEnter: _.partial(this.props.onMouseEnter, item, itemId),
      onMouseLeave: _.partial(this.props.onMouseLeave, item, itemId),
    };
  },

  getItemChildren(item) {
    if (item.empty) {
      return 'No Style';
    }
  },

  isSelectedItem(item) {
    if (this.props.isSelectedItem) {
      return this.props.isSelectedItem(item, this.props.type);
    }
    return false;
  },

  getPresetItems() {
    const editorAPI = this.getEditorAPI();
    const themeColors = editorAPI.theme.colors.getAll();

    const { items } = this.props.props;
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
    _.forEach(items, function (item) {
      util.designData.updateThemeColors(item.structure.design, themeColors);
    });
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/map
    return _.map(items, 'structure');
  },

  getPersonalizedDesignItems() {
    return core.designManager.getPersonalizedDesigns(
      this.getEditorAPI(),
      this.props.compTypes,
    );
  },

  getItems() {
    const SectionTypes = addPanelDataConsts.SECTIONS_TYPES;
    switch (this.props.type) {
      case SectionTypes.DESIGN_DATA_PRESET:
      case SectionTypes.DESIGN_DATA_THEME_PRESET:
        return this.getPresetItems();

      case SectionTypes.PERSONALIZED_DESIGN:
        return this.getPersonalizedDesignItems();
    }
  },

  renderItems(items) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/map
    return _.map(
      items,
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/bind
      _.bind(function (item) {
        const itemId = `themeDesignSectionItem${item.design.charas}`;
        const props = this.getItemProps(item, itemId);
        const children = this.getItemChildren(item);
        const containerChildren = [React.createElement('div', props, children)];
        if (this.isSelectedItem(item) && !item.empty) {
          containerChildren.push(
            React.createElement(symbols.symbol, {
              key: 'selectedItem',
              name: 'selectedItemDesignPanel',
              style: {
                position: 'absolute',
                top: '-12px',
                right: '-12px',
              },
            }),
          );
        }
        return React.createElement(
          'div',
          {
            className: 'design-data-preset-item-wrapper',
            key: itemId,
          },
          containerChildren,
        );
      }, this),
    );
  },

  render() {
    const { items } = this.state;
    if (items.length === 0) {
      return null;
    }
    return React.createElement(
      sectionWrapper,
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      _.assign(
        {
          key: 'designDataPresets',
          className: this.props.props.class || '',
        },
        this.props,
      ),
      React.createElement(
        'div',
        {
          className: 'design-data-preset-items-container',
        },
        this.renderItems(items),
      ),
    );
  },
});
