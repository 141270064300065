import addPanelDataConsts from '@/addPanelDataConsts';
import superAppsConstants from '../../superApps/superAppsConstants';
import { getSuperAppsAddPanelStyles } from '../../superApps/superAppsCustomData';
import { addCompOnClick, addCompOnDrop } from '../addPanelAddCompService';

const wixVideoAppDefId = superAppsConstants.WIX_VIDEO.APP_DEF_ID;
const wixVideoWidgetId = superAppsConstants.WIX_VIDEO.WIDGET;
const SECTION_INDEX_IN_GROUP = 1;

const biOptions = {
  origin: 'add_panel',
  sectionTitle: 'ADD_SECTION_LABEL_WIXVIDEO',
  category: 'media',
};

const addApp = (
  compStructure: AnyFixMe,
  sectionTitle: AnyFixMe,
  tags: AnyFixMe,
  id: AnyFixMe,
  editorAPI: AnyFixMe,
  addOptions: AnyFixMe,
) => {
  return addCompOnClick(
    editorAPI,
    wixVideoAppDefId,
    wixVideoWidgetId,
    compStructure,
    tags,
    id,
    biOptions,
    addOptions,
  );
};

const onDrop = (
  layoutParams: AnyFixMe,
  compPreset: AnyFixMe,
  parentComponent: AnyFixMe,
  editorAPI: AnyFixMe,
) =>
  addCompOnDrop(
    editorAPI,
    wixVideoAppDefId,
    wixVideoWidgetId,
    layoutParams,
    compPreset,
    parentComponent,
    () => {},
    biOptions,
  );

const getPresetItems = () => {
  const compactRect = { width: 162, height: 148, x: 0, y: 0 };
  const classicRect = { width: 162, height: 148, x: 162, y: 0 };
  const stripRect = { width: 324, height: 159, x: 0, y: 290 };
  const sliderBigRect = { width: 324, height: 131, x: 0, y: 569 };
  const sliderSmallRect = { width: 324, height: 110, x: 0, y: 457 };
  const gridRect = { width: 324, height: 125, x: 0, y: 158 };

  return [
    {
      id: 'compact',
      structure: {
        type: 'Component',
        skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
        layout: {
          width: 600,
          height: 368,
          x: 76,
          y: 56,
          scale: 1,
          rotationInDegrees: 0,
          fixedPosition: false,
        },
        componentType: 'wysiwyg.viewer.components.tpapps.TPAWidget',
        data: {
          type: 'TPAWidget',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
          },
          applicationId: '1598',
          referenceId: 'a1ca9dac-7ee4-4d52-a418-68329471105b',
          widgetId: '144097ea-fea0-498e-ade7-e6de40127106',
        },
        style: {
          type: 'TopLevelStyle',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
          },
          style: getSuperAppsAddPanelStyles(wixVideoAppDefId, 'compact'),
          componentClassName: 'wysiwyg.viewer.components.tpapps.TPAWidget',
          pageId: '',
          compId: '',
          styleType: 'custom',
          skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
        },
        mobileStructure: {
          layout: {
            width: 280,
            height: 247,
            x: 20,
            y: 10,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          isCustomPreset: true,
        },
        activeModes: {},
      },
      preset: {
        rect: compactRect,
        label: 'add_preset_WixVideo_Compact',
      },
    },
    {
      id: 'classic',
      structure: {
        type: 'Component',
        skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
        layout: {
          width: 800,
          height: 761,
          x: 90,
          y: 34,
          scale: 1,
          rotationInDegrees: 0,
          fixedPosition: false,
        },
        componentType: 'wysiwyg.viewer.components.tpapps.TPAWidget',
        data: {
          type: 'TPAWidget',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
          },
          applicationId: '1598',
          referenceId: 'a1ca9dac-7ee4-4d52-a418-68329471105b',
          widgetId: '144097ea-fea0-498e-ade7-e6de40127106',
        },
        style: {
          type: 'TopLevelStyle',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
          },
          style: getSuperAppsAddPanelStyles(wixVideoAppDefId, 'classic_new'),
          componentClassName: 'wysiwyg.viewer.components.tpapps.TPAWidget',
          pageId: '',
          compId: '',
          styleType: 'custom',
          skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
        },
        mobileStructure: {
          layout: {
            width: 320,
            height: 279,
            x: 0,
            y: 10,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          isCustomPreset: true,
        },
        activeModes: {},
      },
      preset: {
        rect: classicRect,
        label: 'add_preset_WixVideo_Classic',
      },
    },
    {
      id: 'grid',
      structure: {
        type: 'Component',
        skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
        layout: {
          width: 1920,
          height: 1596,
          x: -470,
          y: 0,
          scale: 1,
          rotationInDegrees: 0,
          fixedPosition: false,
          docked: { left: { px: 0, vw: 0 }, right: { px: 0, vw: 0 } },
        },
        componentType: 'wysiwyg.viewer.components.tpapps.TPAWidget',
        data: {
          type: 'TPAWidget',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
          },
          applicationId: '1598',
          widgetId: '144097ea-fea0-498e-ade7-e6de40127106',
        },
        style: {
          type: 'TopLevelStyle',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
          },
          style: getSuperAppsAddPanelStyles(wixVideoAppDefId, 'grid'),
          componentClassName: 'wysiwyg.viewer.components.tpapps.TPAWidget',
          pageId: '',
          compId: '',
          styleType: 'custom',
          skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
        },
        mobileStructure: {
          layout: {
            width: 280,
            height: 247,
            x: 20,
            y: 10,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          isCustomPreset: true,
        },
        activeModes: {},
      },
      preset: {
        rect: gridRect,
        label: 'add_preset_WixVideo_Grid',
        tags: {
          isFullWidth: true,
        },
      },
    },
    {
      id: 'strip',
      structure: {
        type: 'Component',
        skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
        layout: {
          width: 979,
          height: 410,
          x: 0,
          y: 58,
          scale: 1,
          rotationInDegrees: 0,
          fixedPosition: false,
          docked: {
            left: {
              px: 0.5,
              vw: 0,
            },
            right: {
              px: 0.5,
              vw: 0,
            },
          },
        },
        componentType: 'wysiwyg.viewer.components.tpapps.TPAWidget',
        data: {
          type: 'TPAWidget',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
          },
          applicationId: '1598',
          referenceId: 'a1ca9dac-7ee4-4d52-a418-68329471105b',
          widgetId: '144097ea-fea0-498e-ade7-e6de40127106',
        },
        style: {
          type: 'TopLevelStyle',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
          },
          style: getSuperAppsAddPanelStyles(wixVideoAppDefId, 'strip_new'),
          componentClassName: 'wysiwyg.viewer.components.tpapps.TPAWidget',
          pageId: '',
          compId: '',
          styleType: 'custom',
          skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
        },
        mobileStructure: {
          layout: {
            width: 280,
            height: 247,
            x: 20,
            y: 10,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          isCustomPreset: true,
        },
        activeModes: {},
      },
      preset: {
        rect: stripRect,
        label: 'add_preset_WixVideo_Strip',
        tags: {
          isFullWidth: true,
          googleFontsCssUrl: '',
          uploadFontFaces: '',
        },
      },
    },
    {
      id: 'slider_big',
      structure: {
        type: 'Component',
        skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
        layout: {
          width: 1920,
          height: 572,
          x: -470,
          y: 78,
          scale: 1,
          rotationInDegrees: 0,
          fixedPosition: false,
          docked: { left: { px: 0, vw: 0 }, right: { px: 0, vw: 0 } },
        },
        componentType: 'wysiwyg.viewer.components.tpapps.TPAWidget',
        data: {
          type: 'TPAWidget',
          metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
          applicationId: '1598',
          widgetId: '144097ea-fea0-498e-ade7-e6de40127106',
        },
        style: {
          type: 'TopLevelStyle',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
          },
          style: getSuperAppsAddPanelStyles(wixVideoAppDefId, 'slider_big'),
          componentClassName: 'wysiwyg.viewer.components.tpapps.TPAWidget',
          pageId: '',
          compId: '',
          styleType: 'custom',
          skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
        },
        mobileStructure: {
          layout: {
            width: 280,
            height: 247,
            x: 20,
            y: 10,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          isCustomPreset: true,
        },
        activeModes: {},
      },
      preset: {
        rect: sliderBigRect,
        label: 'add_preset_WixVideo_SliderBig',
        tags: {
          isFullWidth: true,
        },
      },
    },
    {
      id: 'slider_small',
      structure: {
        type: 'Component',
        skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
        layout: {
          width: 1920,
          height: 388,
          x: -470,
          y: 46,
          scale: 1,
          rotationInDegrees: 0,
          fixedPosition: false,
          docked: { left: { px: 0.5, vw: 0 }, right: { px: 0.5, vw: 0 } },
        },
        componentType: 'wysiwyg.viewer.components.tpapps.TPAWidget',
        data: {
          type: 'TPAWidget',
          metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
          applicationId: '1598',
          widgetId: '144097ea-fea0-498e-ade7-e6de40127106',
        },
        style: {
          type: 'TopLevelStyle',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
          },
          style: getSuperAppsAddPanelStyles(wixVideoAppDefId, 'slider_small'),
          componentClassName: 'wysiwyg.viewer.components.tpapps.TPAWidget',
          pageId: '',
          compId: '',
          styleType: 'custom',
          skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
        },
        mobileStructure: {
          layout: {
            width: 280,
            height: 247,
            x: 20,
            y: 10,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          isCustomPreset: true,
        },
        activeModes: {},
      },
      preset: {
        rect: sliderSmallRect,
        label: 'add_preset_WixVideo_SliderSmall',
        tags: {
          isFullWidth: true,
        },
      },
    },
  ];
};

const getSection = () => {
  const image =
    'addPanelData/sections/videoSection_en/wixVideo_addPanelContent_regular.png';
  const imageHover =
    'addPanelData/sections/videoSection_en/wixVideo_addPanelContent_hover.png';

  return {
    title: 'ADD_SECTION_LABEL_WIXVIDEO',
    appDefinitionId: wixVideoAppDefId,
    subNavigationTitle: 'ADD_SECTION_LABEL_WIXVIDEO',
    presetTitle: 'ADD_SECTION_LABEL_WIXVIDEO',
    tooltipTitle: 'ADD_SECTION_LABEL_WIXVIDEO',
    subNavigationHide: false,
    type: addPanelDataConsts.SECTIONS_TYPES.PRESET,
    hide: false,
    sectionName: 'wixVideoSection',
    automationId: 'wix-video-section-automation-id',
    sectionNumber: 1,
    showSectionHeader: true,
    additionalBehaviours: {
      labelMode: addPanelDataConsts.LABEL_BEHAVIOUR.HOVER,
      infoIcon: addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
      hoverImageAction: addPanelDataConsts.HOVER_IMAGE_ACTIONS.IMAGE,
      iconEnabledForComps: [] as any[],
    },
    props: {
      image,
      imageHover,
      items: getPresetItems(),
      onClick: addApp,
      onDrop,
    },
    help: { hide: false, text: 'ADD_SECTION_INFO_TEXT_WIXVIDEO' },
  };
};

const registerSection = (editorAPI: AnyFixMe) => {
  editorAPI.addPanel.registerSectionToCategoryByGroup(
    addPanelDataConsts.CATEGORIES_ID.MEDIA,
    0,
    SECTION_INDEX_IN_GROUP,
    getSection,
  );
};

export { registerSection };
