import addPanelDataConsts from '@/addPanelDataConsts';
import staffListWidgetConst from './staffListWidgetConst';
import editorActionsService from '../../common/editorActionsService';

export default function ({ editorAPI }: AnyFixMe) {
  const actionsService = editorActionsService({ editorAPI });
  const addPanelConsts = addPanelDataConsts;
  const staffListWidget = {
    id: staffListWidgetConst.STAFF_LIST_PRESET_KEY,
    structure: {
      layout: {
        x: 0,
        y: 0,
        width: 980,
        height: 546,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      data: {
        widgetId: staffListWidgetConst.STAFF_LIST_WIDGET_ID,
        variationId: staffListWidgetConst.STAFF_LIST_DEFAULT_VARIATION_ID,
        installationType: 'closed',
      },
      style: {
        type: 'TopLevelStyle',
        metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
        style: {
          properties: {},
          propertiesSource: {},
          groups: {},
        },
        componentClassName: 'wysiwyg.viewer.components.tpapps.TPAWidget',
        pageId: '',
        compId: '',
        styleType: 'custom',
        skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
      },
    },
    preset: {
      rect: { width: 302, height: 170, x: 10, y: 6 },
      label: '',
      tags: null as AnyFixMe,
    },
  };

  const staffListWidgetItems = [staffListWidget];

  return {
    id: 'wixBookingsStaffListWidgets',
    automationId: 'add-panel-section-staffListWidget',
    type: addPanelConsts.SECTIONS_TYPES.PRESET,
    hide: false,
    title: staffListWidgetConst.STAFF_LIST_TITLE_KEY,
    subNavigationTitle: staffListWidgetConst.STAFF_LIST_TITLE_KEY,
    presetTitle: staffListWidgetConst.STAFF_LIST_TITLE_KEY,
    tooltipTitle: staffListWidgetConst.STAFF_LIST_TITLE_KEY,
    showSectionHeader: true,
    additionalBehaviours: {
      labelMode: addPanelConsts.LABEL_BEHAVIOUR.ALWAYS,
      infoIcon: addPanelConsts.INFO_ICON_BEHAVIOUR.NONE,
      hoverImageAction: addPanelConsts.HOVER_IMAGE_ACTIONS.NONE,
      scaleProportion: addPanelConsts.DEFAULT_SCALE_FOR_PRESET,
      iconEnabledForComps: [] as AnyFixMe,
    },
    props: {
      image: staffListWidgetConst.STAFF_LIST_WIDGET_IMAGE_V3,
      imageHover: null as AnyFixMe,
      onDrop: actionsService.onRefWidgetDrop,
      onClick: actionsService.onRefWidgetClick,
      items: staffListWidgetItems,
      compTypes: ['wysiwyg.viewer.components.tpapps.TPAWidget'],
    },
  };
}
