import { getUnlabeledPages as getUnlabeledPagesUtils } from '../utils';

import type { CompRef } from 'types/documentServices';
import type { SectionsMigrationScope as Scope } from '../scope';

export function getUnlabeledPages(scope: Scope): CompRef[] {
  const { documentServices } = scope.editorAPI;

  return getUnlabeledPagesUtils(documentServices);
}
