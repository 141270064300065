import React from 'react';

interface ParentsNegativeScaleAndSkewWrapper {
  parentsNegativeScaleAndSkewString: string[];
  cssPosition?: React.CSSProperties;
  children: React.ReactNode;
}

export const ParentsNegativeScaleAndSkewWrapper = ({
  parentsNegativeScaleAndSkewString,
  cssPosition = {},
  children,
}: ParentsNegativeScaleAndSkewWrapper) => {
  const [current, ...rest] = parentsNegativeScaleAndSkewString;

  if (typeof current === 'undefined') return <>{children}</>;

  return (
    <div style={{ ...cssPosition, transform: current }}>
      {rest.length ? (
        <ParentsNegativeScaleAndSkewWrapper
          parentsNegativeScaleAndSkewString={rest}
          cssPosition={cssPosition}
        >
          {children}
        </ParentsNegativeScaleAndSkewWrapper>
      ) : (
        <>{children}</>
      )}
    </div>
  );
};
