import _ from 'lodash';
import tpaSection from './tpaAddPanelSection';
import addPanelService from './addPanelService';
import addPanelDataConsts from '@/addPanelDataConsts';

const registerAddPanelSection = function (editorAPI: AnyFixMe) {
  const categories = addPanelService.getCategories();
  categories.forEach((categoryId) => {
    editorAPI.addPanel.registerSectionAtEnd(
      categoryId,
      _.partial(getSectionStructure, categoryId, null),
    );
  });
};

const getSectionStructure = function (
  categoryId: AnyFixMe,
  categoryNum: AnyFixMe,
) {
  if (categoryId === 'contact') {
    return {};
  }
  const baseSectionStructure = _.cloneDeep(
    addPanelDataConsts.SECTIONS_STRUCTURE.GENERAL_SECTION_STRUCTURE,
  ) as any;
  baseSectionStructure.help.hide = false;
  baseSectionStructure.help.title = '';
  baseSectionStructure.help.text = 'add_section_info_text_relatedapps_general';
  baseSectionStructure.teaser.hide = true;
  baseSectionStructure.props.class = 'tpaSection';
  baseSectionStructure.props.template = tpaSection;
  baseSectionStructure.props.category = categoryId;
  baseSectionStructure.props.categoryNum =
    categoryNum || getCategoryNumber(categoryId);
  if (categoryId === 'store') {
    if (categoryNum === '1153') {
      baseSectionStructure.help.text =
        'add_section_info_text_relatedapps_store';
    } else {
      baseSectionStructure.help.text =
        'add_section_info_text_relatedapps_store2';
    }
  } else if (categoryId === 'blog') {
    baseSectionStructure.help.text = 'add_section_info_text_relatedapps_blog';
  }
  return baseSectionStructure;
};

const getCategoryNumber = function (categoryId: AnyFixMe) {
  return addPanelService.getCategoryNumber(categoryId);
};

export default {
  registerAddPanelSection,
  getSectionStructure,
};
