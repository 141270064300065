import React from 'react';
import type { MouseEvent } from 'react';
import SnapshotItem from './snapshotItem';
import type { DraggableItem } from '@/addPanelInfra';

interface IPosition {
  x: number;
  y: number;
}

interface IPreviewDimension {
  width: number;
  height: number;
}

interface IItem {
  id: string;
  name: string;
  previewHTML?: string;
  previewDimension: IPreviewDimension;
  isOptimistic?: boolean;
}

export interface ISnapshotSectionProps {
  items: IItem[];
  fetchCollection: () => void;
  fetchItemPreview: (id: string) => void;
  pasteItem: (
    id: string,
    shouldApplyTargetTheme: boolean,
    pastePosition: IPosition,
    addingMethod: 'click' | 'drag',
  ) => void;
  renameItem: (id: string, name: string) => void;
  deleteItem: (id: string, name: string) => void;
  onItemDrag: (e: MouseEvent, item: DraggableItem) => void;
}

class SnapshotSection extends React.PureComponent<ISnapshotSectionProps> {
  static displayName = 'SnapshotSection';

  componentDidMount() {
    this.props.fetchCollection();
  }

  getItemProps(item: IItem) {
    const {
      id,
      name,
      previewDimension: { width, height },
      previewHTML = null,
      isOptimistic = false,
    } = item;
    const { fetchItemPreview, pasteItem, renameItem, deleteItem, onItemDrag } =
      this.props;

    return {
      id,
      name,
      width,
      height,
      previewHTML,
      isOptimistic,
      fetchItemPreview,
      pasteItem,
      renameItem,
      deleteItem,
      onItemDrag,
    };
  }

  render() {
    const { items } = this.props;

    return (
      <div className="snapshot-section">
        {items.map((item) => (
          <SnapshotItem key={item.id} {...this.getItemProps(item)} />
        ))}
      </div>
    );
  }
}

export default SnapshotSection;
