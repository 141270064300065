import { TextClassificationApi } from './textClassificationApi';
import { EditorAPIKey, TextClassificationApiKey } from '@/apis';
import { BaseEntryScope, createEntryPoint } from '@/apilib';

export class Scope extends BaseEntryScope {
  editorAPI = this.useDependency(EditorAPIKey);
}

export const TextClassificationApiEntryPoint = createEntryPoint({
  name: 'TextClassificationApi',
  Scope,
  publicApi({ contributeApi }) {
    contributeApi(TextClassificationApiKey, TextClassificationApi);
  },
  initialize() {},
});
