// @ts-nocheck
export default {
  NO_COLOR: 'noColor',
  AUTOMATION_IDS: {
    COLOR_INDICATORS: {
      INITIAL: 'aid-add-color-content-indicator-initial',
      CURRENT: 'aid-add-color-content-indicator-current',
    },
    BTN: {
      CLOSE: 'aid-add-color-content-btn-close',
      ADD: 'aid-add-color-content-btn-add',
      ADD_CUSTOM_COLOR: 'aid-site-custom-color-add',
      CUSTOM_COLORS: 'aid-site-custom-color',
    },
  },
  ORIGIN: {
    COLOR_PICKER: 'text_color_picker',
    ADD_BUTTON: 'text_color_picker_button',
    ADD_LINK: 'text_color_picker_link',
    SINGLE_COLOR: 'theme_color_picker_single_color',
    COLUMN_COLOR: 'theme_color_picker_column_color',
  },
  COLOR_DEFAULTS_HELP_ID: '22c46b7e-7074-48f4-bb5d-da04dafa92f4',
};
