// @ts-nocheck
import _ from 'lodash';

const Intents = {
  TPA_MESSAGE: 'TPA',
  TPA_MESSAGE2: 'TPA2',
  TPA_RESPONSE: 'TPA_RESPONSE',
  UI_LIB_RESPONSE: 'UI_LIB_RESPONSE',
  PINGPONG_PREFIX: 'pingpong:',
};

const callPostMessage = function (source, data, targetOrigin) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/is-undefined
  if (_.isUndefined(source)) {
    return;
  }

  let msgStr = '';
  try {
    msgStr = JSON.stringify(data);
  } catch (e) {
    return;
  }

  if (!source.postMessage) {
    source = source.contentWindow;
  }
  source.postMessage(msgStr, targetOrigin || '*');
};

const generateResponseFunction = function (source, msg) {
  const uiLibHanlders = [
    'openColorPicker',
    'openFontPicker',
    'openBackgroundPicker',
  ];
  let intent = Intents.TPA_RESPONSE;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/includes
  if (_.includes(uiLibHanlders, msg.type)) {
    intent = Intents.UI_LIB_RESPONSE;
  }
  return function (data) {
    try {
      callPostMessage(source, {
        intent,
        callId: msg.callId,
        type: msg.type,
        res: data,
        status: true,
      });
    } catch (e) {
      // empty
    }
  };
};

const isTPAMessage = function isTPAMessage(msg) {
  return (
    (msg.intent === Intents.TPA_MESSAGE ||
      msg.intent === Intents.TPA_MESSAGE2) &&
    !originatedFromNonTPA(msg)
  );
};

const originatedFromNonTPA = function (msg) {
  return msg.compId === '[UNKNOWN]';
};

const fixOldPingPongMessageType = function (msgType) {
  return msgType.replace(Intents.PINGPONG_PREFIX, '');
};

const handleTPAMessage = function (editorAPI, callHandler, event) {
  let msg;
  try {
    if (event.data) {
      if (typeof event.data === 'string') {
        msg = JSON.parse(event.data);
      }
    } else if (event.originalEvent?.data) {
      event = event.originalEvent;
      if (typeof event.data === 'string') {
        msg = JSON.parse(event.data);
      }
    }
  } catch (e) {
    return;
  }

  if (msg && isTPAMessage(msg)) {
    callHandler(editorAPI, msg, generateResponseFunction(event.source, msg));
  }
};

const getOrigCompIdFromTpaMessage = (msg, selectedComponent?, editorAPI?) => {
  return (
    msg.originalCompId ||
    (selectedComponent
      ? selectedComponent.id
      : editorAPI.selection.getSelectedComponentId())
  );
};

export {
  Intents,
  callPostMessage,
  generateResponseFunction,
  isTPAMessage,
  fixOldPingPongMessageType,
  handleTPAMessage,
  getOrigCompIdFromTpaMessage,
};
