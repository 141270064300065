import type { BiEventDefinition, BiEventFields } from 'types/bi';
import * as util from '@/util';
import { translate } from '@/i18n';
import * as coreBi from '@/coreBi';
import React from 'react';
import { Button } from '@wix/wix-base-ui';
import * as baseUI from '@/baseUI';
import { FocusPanelFrame } from '../frames';
import type { EditorAPI } from '@/editorAPI';
import type { Dispatch } from 'types/redux';
import { ErrorReporter } from '@wix/editor-error-reporter';

const STAY_IN_EDITOR = 'Stay in the editor';
const HISTORY = 'Site history';
const BACK_TO_ADI = 'Back to Wix ADI';

interface BackToADIPanelDispatchProps {
  openSiteHistory: () => void;
  closePanelByName: (panelName: string) => void;
  restoreToADI: () => Promise<void>;
  closeAllPanels: () => void;
  sendBI: (event: BiEventDefinition, parameters: BiEventFields) => void;
}

interface BackToADIPanelStateProps {
  siteId: string;
}
interface BackToADIPanelProps
  extends BackToADIPanelDispatchProps,
    BackToADIPanelStateProps {
  panelName: string;
  onButtonClick?: (bool: boolean) => void;
}

class BackToADIPanel extends React.Component<BackToADIPanelProps> {
  static displayName = 'backToADIPanel';

  openSiteHistory = () => {
    this.props.openSiteHistory();
    this.sendBI(HISTORY);
  };

  close = () => {
    this.sendBI(STAY_IN_EDITOR);
    this.props.closePanelByName(this.props.panelName);
  };

  closeByXButton = () => {
    this.close();
  };

  backToEditorButton = () => {
    this.close();
  };

  redirectToADI = () => {
    this.props
      .restoreToADI()
      .then(() => {
        this.props.closeAllPanels();
        util.fedopsLogger.interactionEnded(
          util.fedopsLogger.INTERACTIONS.BACK_TO_ADI,
        );
        window.location.href = util.editorModel.onboardingEditorUrl;
      })
      .catch((e) => {
        ErrorReporter.captureException(e, { tags: { backToADI: true } });
      });
  };

  backToADI = () => {
    util.fedopsLogger.interactionStarted(
      util.fedopsLogger.INTERACTIONS.BACK_TO_ADI,
    );

    this.sendBI(BACK_TO_ADI);
    this.redirectToADI();
  };
  onConfirm = () => {
    if (this.props.onButtonClick) {
      this.props.onButtonClick(true);
    }
  };
  sendBI = (categoryType: AnyFixMe) => {
    this.props.sendBI(
      coreBi.events.backToADIFromEditor.backToWixADIPanelClicked,
      {
        category: categoryType,
        esi: util.editorModel.editorSessionId,
        site_id: this.props.siteId,
      },
    );
  };
  onPanelClose = () => {
    this.close();
  };

  render() {
    return (
      <FocusPanelFrame
        key="backToADIPanel"
        shouldHideHelp={true}
        onClose={this.onPanelClose}
        title={translate('TOPBAR_BacktoADI_MSG_TITLE')}
        panelName="back-to-adi"
        className="back-to-adi-panel"
      >
        <div className="back-to-adi-panel-content">
          <div className="svg-container">
            <baseUI.symbol name="BackToADIPopUp" />
          </div>
          <div className="description-container">
            <div className="description-top">
              <span className="description-text">
                {translate('TOPBAR_BacktoADI_MSG_BODY1')}
              </span>
            </div>
            <div className="description-bottom">
              <span className="description-text">
                {translate('TOPBAR_BacktoADI_MSG_BODY2')}
              </span>
              <Button
                onClick={this.openSiteHistory}
                className="description-text btn-text description-link"
              >
                {translate('TOPBAR_BacktoADI_MSG_LINK')}
              </Button>
            </div>
          </div>
          <div className="footer-buttons">
            <baseUI.button
              onClick={this.backToEditorButton}
              label="TOPBAR_BacktoADI_MSG_BUTTON2"
              className="btn-confirm-secondary btn-md footer-button"
            />

            <baseUI.button
              onClick={this.backToADI}
              label="TOPBAR_BacktoADI_MSG_BUTTON1"
              className="btn-confirm-primary btn-md footer-button"
            />
          </div>
        </div>
      </FocusPanelFrame>
    );
  }
}

const mapStateToProps = ({
  editorAPI,
}: {
  editorAPI: EditorAPI;
}): BackToADIPanelStateProps => {
  return {
    siteId: editorAPI.dsRead.generalInfo.getSiteId(),
  };
};

const getEditorAPI = (
  dispatch: AnyFixMe,
  getState: AnyFixMe,
  { editorAPI }: AnyFixMe,
): EditorAPI => editorAPI;

const mapDispatchToProps = (
  dispatch: Dispatch,
): BackToADIPanelDispatchProps => {
  const editorAPI = dispatch(getEditorAPI);
  return {
    openSiteHistory: editorAPI.account.openSiteHistory,
    closePanelByName: editorAPI.panelManager.closePanelByName,
    restoreToADI: editorAPI.site.restoreToADI,
    closeAllPanels: editorAPI.panelManager.closeAllPanels,
    sendBI: editorAPI.bi.event,
  };
};

export default util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(BackToADIPanel);
