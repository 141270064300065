import type { addWidgetOptions } from '@wix/platform-editor-sdk';

const REF_COMPONENT_PROPS_TYPE = 'RefComponentProperties';

export function convertDimensionsByDevice(
  dimensionsByDevice: addWidgetOptions['dimensionsByDevice'],
  layout: addWidgetOptions['layout'],
) {
  if (!dimensionsByDevice.desktop && !dimensionsByDevice.mobile) {
    return {};
  }

  const { mobile, desktop } = dimensionsByDevice;

  return {
    ...(desktop
      ? {
          layout: {
            ...layout,
            width: desktop.width.value,
            height: desktop.height.value,
          },
        }
      : { layout }),
    ...(desktop
      ? {
          props: {
            minHeight: desktop.height.value,
            type: REF_COMPONENT_PROPS_TYPE,
          },
        }
      : {}),
    ...(mobile
      ? {
          mobileHints: {
            type: 'MobileHints',
            recommendedHeight: mobile.height.value,
            recommendedWidth: mobile.width.value,
            props: {
              minHeight: mobile.height.value,
              type: REF_COMPONENT_PROPS_TYPE,
            },
          },
        }
      : {}),
  };
}
