import constants from '@/constants';
import {
  SWITCH_LAYOUT_SERVICE_URL,
  SWITCH_LAYOUT_SERVICE_CMS_DIRECT_URL,
  LAYOUT_PREVIEW_WIDTH,
} from './consts';
import type { CompStructure, CompRef } from 'types/documentServices';
import type { EditorAPI } from '@/editorAPI';
import { HttpClient } from '@wix/http-client';
import { ComponentCategory, ParsedLayout } from './bruteForce/parsedLayout';
import { fedopsLogger } from '@/util';
import experiment from 'experiment';
import { ErrorReporter } from '@wix/editor-error-reporter';
import { decodeHtml } from './switchLayoutUtil';
import {
  heuristicsMap,
  componentsMap,
  postMigrationComponentsMap,
} from './switchLayoutHeuristics';
import type { PreviewData } from '@/previewer';

export { LayoutParser } from './bruteForce/layoutParser';
export interface Suggestion {
  layout: CompStructure;
  rules: any;
  title: string;
}

enum Direction {
  FORWARD,
  BACKWARD,
}

const textAlignmentRegex = /text-align: ?[a-z]*;?/g;

const USE_PUBLISHED_SUGGESTIONS_ONLY = !(
  experiment.isOpen('se_scanSwitchLayoutPreset') ||
  experiment.isOpen('se_SwitchLayoutDesignerPresets')
);

const getSwitchLayoutServiceUrl = () => {
  return experiment.isOpen('se_SwitchLayoutDesignerPresets')
    ? SWITCH_LAYOUT_SERVICE_CMS_DIRECT_URL
    : SWITCH_LAYOUT_SERVICE_URL;
};

const removeAdditionalProperties = (component: CompStructure) => {
  delete (component as any).category;
  delete (component as any).slGeneratedId;

  (component as any).components?.forEach((comp: CompStructure) => {
    removeAdditionalProperties(comp);
  });
};

export const fetchSuggestionsBruteForce = async (
  editorAPI: EditorAPI,
  useFilter: boolean,
  originalSerialized: CompStructure,
  layout: ParsedLayout,
) => {
  fedopsLogger.interactionStarted(
    fedopsLogger.SWITCH_LAYOUT.GET_SUGGESTIONS_BRUTE_FORCE,
  );

  const httpClient = new HttpClient();

  const { suggestions, disqualifications } = await httpClient
    .post(getSwitchLayoutServiceUrl(), {
      layout,
      useFilter,
      usePublished: USE_PUBLISHED_SUGGESTIONS_ONLY,
      timeout: 85000,
    })
    .then((res) => res.data)
    .catch((error) =>
      ErrorReporter.captureException(error, {
        tags: { switchLayoutFlow: true, fetchSuggestionsBruteForceFlow: true },
      }),
    );

  suggestions.forEach((suggestion: any) => {
    removeAdditionalProperties(suggestion.layout);
  });

  const filteredSuggestions = suggestions.filter(suggestionsfilter());

  fedopsLogger.interactionEnded(
    fedopsLogger.SWITCH_LAYOUT.GET_SUGGESTIONS_BRUTE_FORCE,
  );

  let normalizedSuggestionsAndTitles = [];
  if (Array.isArray(filteredSuggestions) && filteredSuggestions.length) {
    normalizedSuggestionsAndTitles = await normalizeSuggestions(
      editorAPI,
      originalSerialized.parent,
      filteredSuggestions,
      originalSerialized,
    );
  }

  return {
    suggestions: filteredSuggestions,
    disqualifications,
    normalizedSuggestionsAndTitles,
    originalSerialized,
  };
};

function suggestionsfilter() {
  return (suggestion: any) => {
    let filterValue = true;
    const { experiments, experimentsOff } = suggestion;

    if (experiments || experimentsOff) {
      filterValue =
        filterValue &&
        (experiments || []).every(experiment.isOpen) &&
        (experimentsOff || []).every((exp: string) => !experiment.isOpen(exp));
    }

    return filterValue;
  };
}

export const getBruteForcePresets = async (
  scope: any,
  originalSerialized: CompStructure,
  layout: ParsedLayout,
) => {
  const { disqualifications, normalizedSuggestionsAndTitles } =
    await fetchSuggestionsBruteForce(
      scope.editorAPI,
      true,
      originalSerialized,
      layout,
    );

  const previewsData = normalizedSuggestionsAndTitles?.map((normalized) => {
    const structure: PreviewData = {
      structure: normalized.normalizedSuggestion,
      displayWidth: LAYOUT_PREVIEW_WIDTH,
    };
    return structure;
  });

  const components = await scope.previewerApi.createPreviewComponents({
    previewsData,
  });

  const brutePresets = normalizedSuggestionsAndTitles?.map(
    (normalized, index) => {
      return {
        id: normalized.title,
        preset: normalized.normalizedSuggestion,
        reactPreview: components[index],
        isPaasPreset: false,
      };
    },
  );
  return { disqualifications, brutePresets };
};

export const isLayoutSupportedForBruteForce = async (
  parsedLayout: ParsedLayout,
) => {
  return !parsedLayout.componentsCategories[ComponentCategory.Unsupported];
};

export const normalizeSuggestions = async (
  editorAPI: EditorAPI,
  parentId: string,
  suggestions: Suggestion[],
  originalSerialized: CompStructure,
) => {
  const postNormalizeFunctions = Array(suggestions.length).fill([]);
  Array(suggestions.length).fill(undefined);
  const normalizedSuggestions = await Promise.all(
    suggestions.map((suggestedComp, index) => {
      suggestedComp.layout.layout.y = originalSerialized.layout.y;
      return normalizeSuggested(
        editorAPI,
        parentId,
        suggestedComp.layout,
        originalSerialized,
        suggestedComp.layout,
        postNormalizeFunctions[index],
        suggestedComp.rules,
        suggestedComp.title,
        originalSerialized.componentType,
      );
    }),
  );
  return normalizedSuggestions
    .map((suggestion, index) => {
      if (postNormalizeFunctions[index].length) {
        postNormalizeFunctions[index].forEach((postNormalizeFunction: any) =>
          postNormalizeFunction(suggestion.mergedComp),
        );
      }
      return suggestion;
    })
    .reduce((acc, suggestion) => {
      if (suggestion.isValid) {
        acc.push({
          normalizedSuggestion: suggestion.mergedComp,
          title: suggestion.title,
        });
      }
      return acc;
    }, []);
};

const normalizeSuggested = async (
  editorAPI: EditorAPI,
  parentId: string,
  suggestedComp: CompStructure,
  currentRootSerialized: CompStructure,
  suggestedRootComp: CompStructure,
  currentPostNormalizeFunctions: any[],
  rules: any,
  title: string,
  parentCompType: string,
) => {
  const suggestedCompPointer: CompRef = {
    id: suggestedComp.id,
    type: 'DESKTOP',
  };

  let mergedComp: AnyFixMe;
  let isValid = true;
  if (suggestedComp.id && editorAPI.components.is.exist(suggestedCompPointer)) {
    // eslint-disable-next-line @wix/santa-editor/dsReadSerializeIsTooExpensive
    const currentComp = editorAPI.components.serialize(
      suggestedCompPointer,
      null,
      true,
      true,
    );

    if (
      suggestedComp.componentType !== currentComp.componentType &&
      heuristicsMap[suggestedComp.componentType]
    ) {
      mergedComp = heuristicsMap[suggestedComp.componentType](
        editorAPI,
        suggestedComp,
        parentId,
        currentComp,
        currentRootSerialized,
        currentPostNormalizeFunctions,
        rules,
      );
    } else if (componentsMap[suggestedComp.componentType]) {
      mergedComp = await componentsMap[suggestedComp.componentType](
        editorAPI,
        suggestedComp,
        parentId,
        parentCompType,
        currentComp,
        null,
        null,
        rules,
      );
      if (!mergedComp) {
        return { mergedComp, isValid: false };
      }
    } else {
      mergedComp = {
        ...currentComp,
        style: currentComp.style,
        parent: parentId,
        layout: {
          ...suggestedComp.layout,
        },
        props: {
          ...currentComp.props,
        },
        components: suggestedComp.components,
      };

      if (suggestedComp.props?.fullWidth) {
        mergedComp.props.fullWidth = suggestedComp.props?.fullWidth;
      }

      if (
        suggestedComp.design?.background?.colorLayers &&
        mergedComp.design?.background
      ) {
        mergedComp.design.background.colorLayers =
          suggestedComp.design.background.colorLayers;
      }
    }

    if (mergedComp?.data?.text) {
      let text = getAlignTextBySuggestion(suggestedComp, currentComp);
      if (mergedComp.props.minHeight) {
        mergedComp.props.minHeight = suggestedComp.props.minHeight ?? 0;
      }
      text = trimHtml(text);
      if (mergedComp.data.text !== text) {
        mergedComp.data.text = text;
      }
    }
  } else {
    mergedComp = {
      ...suggestedComp,
      parent: parentId,
    };
  }

  if (
    mergedComp.id === suggestedRootComp.id &&
    suggestedComp.componentType !== suggestedRootComp.componentType
  ) {
    delete mergedComp.id;
  }

  if (
    mergedComp.componentType === constants.COMP_TYPES.STRIP_COLUMNS_CONTAINER &&
    currentRootSerialized.design
  ) {
    mergedComp.design = currentRootSerialized.design;
    if (mergedComp.design.id) {
      delete mergedComp.design.id;
    }
    if (mergedComp.design.background?.mediaRef) {
      delete mergedComp.design.background.mediaRef;
    }
  }

  if (suggestedComp.props?.hasOwnProperty('alignment')) {
    mergedComp.props.alignment = suggestedComp.props.alignment;
  }
  if (suggestedComp.props?.hasOwnProperty('columnsMargin')) {
    mergedComp.props.columnsMargin = suggestedComp.props.columnsMargin;
  }
  if (suggestedComp.props?.hasOwnProperty('frameMargin')) {
    mergedComp.props.frameMargin = suggestedComp.props.frameMargin;
  }
  if (suggestedComp.props?.hasOwnProperty('rowMargin')) {
    mergedComp.props.rowMargin = suggestedComp.props.rowMargin;
  }
  if (suggestedComp.props?.hasOwnProperty('siteMargin')) {
    mergedComp.props.siteMargin = suggestedComp.props.siteMargin;
  }

  if (
    (mergedComp?.componentType === constants.COMP_TYPES.COLUMN ||
      mergedComp?.componentType ===
        constants.COMP_TYPES.STRIP_COLUMNS_CONTAINER) &&
    // @ts-expect-error
    currentRootSerialized?.components?.[0]?.design?.background?.color
  ) {
    mergedComp.design.background.color = (
      currentRootSerialized?.components?.[0] as CompStructure
    )?.design?.background?.color;
  }

  if (mergedComp && postMigrationComponentsMap[suggestedComp.componentType]) {
    mergedComp = await postMigrationComponentsMap[suggestedComp.componentType](
      editorAPI,
      parentId,
      mergedComp,
      null,
      null,
      rules,
    );
  }

  if (mergedComp?.components?.length) {
    mergedComp.components = await Promise.all(
      mergedComp.components.map(async (comp: AnyFixMe) => {
        const { mergedComp: childMergedComp, isValid: isChildValid } =
          await normalizeSuggested(
            editorAPI,
            mergedComp.id,
            comp,
            currentRootSerialized,
            suggestedRootComp,
            currentPostNormalizeFunctions,
            rules,
            title,
            mergedComp.componentType,
          );
        isValid = isValid && isChildValid;
        return childMergedComp;
      }),
    );
    mergedComp.components = mergedComp.components.filter((comp: any) => {
      return !!comp;
    });
  }

  return { mergedComp, isValid, title };
};

const getTextAlignString = (text: string): string | null => {
  const TEXT_ALIGN_REGEX = textAlignmentRegex;
  const textAlignStrDef = text.match(TEXT_ALIGN_REGEX);
  if (textAlignStrDef) {
    return textAlignStrDef[0];
  }
  return null;
};

const getAlignTextBySuggestion = (
  suggestedComp: CompStructure,
  currentComp: CompStructure,
): string => {
  const suggestedTextAlignment = getTextAlignString(suggestedComp.data.text);
  let alignedText = currentComp.data.text;
  alignedText = alignedText.replaceAll(textAlignmentRegex, '');
  if (suggestedTextAlignment?.length > 0) {
    alignedText = `<span style="${suggestedTextAlignment};">${alignedText}</span>`;
  }
  return alignedText;
};

export const trimHtml = (compText: string) => {
  const origHtmlTempParent = document.createElement('div');
  origHtmlTempParent.innerHTML = compText;
  trimHtmlByDirection(origHtmlTempParent, Direction.FORWARD);
  trimHtmlByDirection(origHtmlTempParent, Direction.BACKWARD);
  return origHtmlTempParent.innerHTML;
};

const trimHtmlByDirection = (htmlParent: any, direction: Direction) => {
  for (
    let nodeIterator = 0;
    nodeIterator < htmlParent.childNodes.length;
    nodeIterator++
  ) {
    const currentNodeIndex =
      direction === Direction.FORWARD
        ? nodeIterator
        : htmlParent.childNodes.length - 1 - nodeIterator;
    const currentChild = htmlParent.childNodes[currentNodeIndex];
    if (isEmptyHtmlNode(currentChild)) {
      htmlParent.removeChild(currentChild);
      nodeIterator--;
    }
    trimHtmlByDirection(currentChild, direction);
    if (isEmptyHtmlNode(htmlParent)) {
      htmlParent.parentNode.removeChild(htmlParent);
      return;
    }
  }
};

const isEmptyHtmlNode = (node: any) => {
  const nodeText = decodeHtml((node as AnyFixMe).wholeText).trim();
  if (
    nodeText === '' ||
    nodeText === '​' ||
    (node.nodeType !== Node.TEXT_NODE && node.childNodes.length === 0)
  ) {
    return true;
  }
  return false;
};
