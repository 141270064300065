const map = {
  'tpa.viewer.components.Masonry': {
    'wysiwyg.viewer.skins.TPAMasonrySkin':
      'add_preset_Galleries_Grid_Galleries_1',
  },

  'wysiwyg.viewer.components.MatrixGallery': [
    {
      'wysiwyg.viewer.skins.gallerymatrix.TextBottomCustomHeightSkin':
        'add_preset_Galleries_Grid_Galleries_4',
    },
    {
      'wysiwyg.viewer.skins.gallerymatrix.MatrixGalleryCircleSkin':
        'add_preset_Galleries_Grid_Galleries_6',
    },
    {
      'wysiwyg.viewer.skins.gallerymatrix.MatrixGalleryLiftedShadow':
        'add_preset_Galleries_Grid_Galleries_20',
    },
    {
      'skins.viewer.gallerymatrix.MatrixGalleryIronSkin':
        'add_preset_Galleries_Grid_Galleries_21',
    },
    {
      'skins.viewer.gallerymatrix.MatrixGalleryScotchTapeSkin':
        'add_preset_Galleries_Grid_Galleries_22',
    },
    {
      'wysiwyg.viewer.skins.gallerymatrix.MatrixGalleryTextSlideUpSkin':
        'add_preset_Galleries_Grid_Galleries_10',
    },
    {
      'wysiwyg.viewer.skins.gallerymatrix.MatrixGalleryTransparentSkin':
        'add_preset_Galleries_Grid_Galleries_12',
    },
  ],

  'tpa.viewer.components.StripSlideshow': {
    'wysiwyg.viewer.skins.TPAStripSlideshowSkin':
      'add_preset_Galleries_Full_Width_Galleries_1',
  },

  'wysiwyg.viewer.components.PaginatedGridGallery': [
    {
      'wysiwyg.viewer.skins.paginatedgrid.PaginatedGridArrowsOutside':
        'add_preset_Galleries_Grid_Galleries_7',
    },
    {
      'wysiwyg.viewer.skins.paginatedgrid.PaginatedGridTextBottom':
        'add_preset_Galleries_Grid_Galleries_8',
    },
    {
      'wysiwyg.viewer.skins.paginatedgrid.PaginatedGridDefaultSkin':
        'add_preset_Galleries_Grid_Galleries_15',
    },
    {
      'wysiwyg.viewer.skins.paginatedgrid.PaginatedGridRibbonArrows':
        'add_preset_Galleries_Grid_Galleries_16',
    },
    {
      'wysiwyg.viewer.skins.paginatedgrid.PaginatedGridOverlay':
        'add_preset_Galleries_Grid_Galleries_19',
    },
  ],
  'tpa.viewer.components.StripShowcase': {
    'wysiwyg.viewer.skins.TPAStripShowcaseSkin':
      'add_preset_Galleries_Full_Width_Galleries_2',
  },

  'wysiwyg.viewer.components.SlideShowGallery': [
    {
      'skins.viewer.gallery.SlideShowCleanAndSimple':
        'add_preset_Galleries_Slider_Galleries_2',
    },
    {
      'wysiwyg.viewer.skins.gallery.SlideShowTextBottom':
        'add_preset_Galleries_Slider_Galleries_4',
    },
    {
      'wysiwyg.viewer.skins.gallery.SlideShowTextRight':
        'add_preset_Galleries_Slider_Galleries_5',
    },
    {
      'wysiwyg.viewer.skins.gallery.SlideShowPolaroid':
        'add_preset_Galleries_Slider_Galleries_8',
    },
    {
      'wysiwyg.viewer.skins.gallery.SlideShowTextOverlay':
        'add_preset_Galleries_Slider_Galleries_13',
    },
    {
      'wysiwyg.viewer.skins.gallery.SlideShowGalleryLiftedShadowSkin':
        'add_preset_Galleries_Slider_Galleries_14',
    },
    {
      'skins.viewer.gallery.SlideShowIron':
        'add_preset_Galleries_Slider_Galleries_17',
    },
  ],

  'tpa.viewer.components.Accordion': {
    'wysiwyg.viewer.skins.TPAAccordionSkin':
      'add_preset_Galleries_Slider_Galleries_7',
  },

  'tpa.viewer.components.Impress': {
    'wysiwyg.viewer.skins.TPAImpressSkin':
      'add_preset_Galleries_Slider_Galleries_9',
  },

  'wysiwyg.viewer.components.SliderGallery': [
    {
      'wysiwyg.viewer.skins.galleryslider.SliderGalleryDefaultSkin':
        'add_preset_Galleries_Slider_Galleries_10',
    },
    {
      'wysiwyg.viewer.skins.galleryslider.SliderGalleryCircleSkin':
        'add_preset_Galleries_Slider_Galleries_6',
    },
    {
      'wysiwyg.viewer.skins.galleryslider.SliderGalleryScotchTapeSkin':
        'add_preset_Galleries_Slider_Galleries_15',
    },
    {
      'wysiwyg.viewer.skins.galleryslider.SliderGalleryIronSkin':
        'add_preset_Galleries_Slider_Galleries_16',
    },
  ],

  'wysiwyg.viewer.components.tpapps.TPA3DCarousel': {
    'wysiwyg.viewer.skins.TPA3DCarouselSkin':
      'add_preset_Galleries_3D_Galleries_1',
  },

  'wysiwyg.viewer.components.tpapps.TPA3DGallery': {
    'wysiwyg.viewer.skins.TPA3DGallerySkin':
      'add_preset_Galleries_3D_Galleries_2',
  },

  'tpa.viewer.components.Freestyle': {
    'wysiwyg.viewer.skins.TPAFreestyleSkin':
      'add_preset_Galleries_3D_Galleries_3',
  },
} as const;

export { map };
