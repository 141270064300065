import { translate } from '@/i18n';
import { symbol as Symbol } from '@wix/santa-editor-symbols';
import {
  ActionItem,
  ContextualDivider,
  RichText,
  Tooltip,
} from '@wix/wix-base-ui';
import { AddCircle } from '@wix/wix-ui-icons-common/classic-editor';
import React from 'react';

import { actionInMainTabClicked } from '../../bi';

import type { ActionItemsProps } from './actionItems';

type MainActionItemsProps = Pick<
  ActionItemsProps,
  | 'canEditSiteStructure'
  | 'canSaveSchemas'
  | 'isFirstTimeExperience'
  | 'openAddPresetPanel'
  | 'openCreateCollectionPanel'
  | 'openHelpCenter'
  | 'sendBi'
>;

const startWithPresetDescriptionTranslationKey =
  'CONTENT_MANAGER_6_BUTTON_MAIN_MENU_START_WITH_PRESET_DESCRIPTION';

const createFirstCollectionTranslationKey =
  'CONTENT_MANAGER_6_BUTTON_MAIN_MENU_CREATE_FIRST_COLLECTION_TITLE';

const createFirstCollectionDescriptionTranslationKey =
  'CONTENT_MANAGER_6_BUTTON_MAIN_MENU_CREATE_FIRST_COLLECTION_DESCRIPTION';

export const MainActionItems = ({
  canEditSiteStructure,
  canSaveSchemas,
  isFirstTimeExperience,
  sendBi,
  openAddPresetPanel,
  openCreateCollectionPanel,
  openHelpCenter,
}: MainActionItemsProps) => {
  return (
    <div>
      <Tooltip
        content="CONTENT_MANAGER_6_BUTTON_MAIN_MENU_ADD_PRESET_NO_PERMISSIONS"
        disabled={canEditSiteStructure && canSaveSchemas}
      >
        <ActionItem
          title={
            isFirstTimeExperience
              ? 'CONTENT_MANAGER_6_BUTTON_MAIN_MENU_START_WITH_PRESET_TITLE'
              : 'CONTENT_MANAGER_6_BUTTON_MAIN_MENU_ADD_PRESET'
          }
          size={isFirstTimeExperience ? 'large' : 'small'}
          onClick={() => {
            openAddPresetPanel();
            sendBi(actionInMainTabClicked, {
              actionName: 'add_preset',
              isEmptyState: isFirstTimeExperience,
            });
          }}
          actionIcon={<Symbol name="arrowRightThin" />}
          illustration={
            !isFirstTimeExperience && <AddCircle data-hook="add-preset-icon" />
          }
          disabledIllustration={
            !isFirstTimeExperience && (
              <AddCircle data-hook="add-preset-icon" fillOpacity={0.3} />
            )
          }
          dataHook="add-preset-option"
          description={
            isFirstTimeExperience
              ? startWithPresetDescriptionTranslationKey
              : undefined
          }
          tooltipInteractive={true}
          tooltipContent={
            !isFirstTimeExperience && (
              <RichText>
                {translate(
                  'CONTENT_MANAGER_6_BUTTON_MAIN_MENU_ADD_PRESET_TOOLTIP',
                )}
                &nbsp;
                <a
                  onClick={(e) => {
                    e.stopPropagation();
                    openHelpCenter('c22aec19-068d-430f-963b-cd48ae7186ef');
                  }}
                >
                  {translate(
                    'CONTENT_MANAGER_6_BUTTON_MAIN_MENU_ADD_PRESET_TOOLTIP_LINK',
                  )}
                </a>
              </RichText>
            )
          }
          disabled={!canEditSiteStructure || !canSaveSchemas}
        />
      </Tooltip>
      <ContextualDivider
        value="CONTENT_MANAGER_6_BUTTON_MAIN_MENU_OR"
        size="small"
      />
      <Tooltip
        content="CONTENT_MANAGER_6_BUTTON_MAIN_MENU_CREATE_COLLECTION_NO_PERMISSIONS"
        disabled={canSaveSchemas}
      >
        <ActionItem
          title={
            isFirstTimeExperience
              ? createFirstCollectionTranslationKey
              : 'CONTENT_MANAGER_6_BUTTON_MAIN_MENU_CREATE_COLLECTION'
          }
          size={isFirstTimeExperience ? 'large' : 'small'}
          onClick={() => {
            openCreateCollectionPanel();
            sendBi(actionInMainTabClicked, {
              actionName: 'create_collection',
              isEmptyState: isFirstTimeExperience,
            });
          }}
          actionIcon={<Symbol name="arrowRightThin" />}
          illustration={
            !isFirstTimeExperience && (
              <AddCircle data-hook="create-collection-icon" />
            )
          }
          disabledIllustration={
            !isFirstTimeExperience && (
              <AddCircle data-hook="create-collection-icon" fillOpacity={0.3} />
            )
          }
          dataHook="create-collection-option"
          description={
            isFirstTimeExperience
              ? createFirstCollectionDescriptionTranslationKey
              : undefined
          }
          tooltipInteractive={true}
          tooltipContent={
            !isFirstTimeExperience && (
              <RichText>
                {translate(
                  'CONTENT_MANAGER_6_BUTTON_MAIN_MENU_CREATE_COLLECTION_TOOLTIP',
                )}
                &nbsp;
                <a
                  onClick={(e) => {
                    e.stopPropagation();
                    openHelpCenter('98e6cff3-dd22-47da-9041-5e93bfb215d7');
                  }}
                >
                  {translate(
                    'CONTENT_MANAGER_6_BUTTON_MAIN_MENU_CREATE_COLLECTION_TOOLTIP_LEARN_MORE',
                  )}
                </a>
              </RichText>
            )
          }
          disabled={!canSaveSchemas}
        />
      </Tooltip>
    </div>
  );
};
