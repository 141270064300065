import type { EditorAPI } from '@/editorAPI';
import constants from '@/constants';
import { addPanel as addPanelUtils, biLogger } from '@/util';
import type { CompLayout, CompRef } from 'types/documentServices';
import { sectionEmptyStateClick } from '@wix/bi-logger-editor/v2';
import * as stateManagement from '@/stateManagement';
import experiment from 'experiment';

const { getViewPort } = stateManagement.domMeasurements.selectors;

const AddElementBiOrigin = 'site-segment-add-element_layers-panel';

const scrollToCompAndSelect = (editorAPI: EditorAPI, compRef: CompRef) => {
  const layout = editorAPI.components.layout.getRelativeToStructure(compRef);

  const shouldScroll = experiment.isOpen('se_noScollWithinViewport')
    ? !isComponentWithinViewport(editorAPI, layout)
    : true;

  if (shouldScroll) {
    editorAPI.ui.scroll.animateScroll(
      {
        x: layout.x,
        y: Math.max(layout.y - 30, 0),
      },
      0.5,
    );
  }

  editorAPI.selection.selectComponentByCompRef(compRef, {
    origin: 'layers_panel',
  });
  editorAPI.selection.setIsHiddenComponentDraggedNext(true);
};

const openAddPanel = (
  editorAPI: EditorAPI,
  options: { category?: string; sectionId?: string },
) => {
  const panelName = addPanelUtils.isNewPanelEnabled()
    ? constants.ROOT_COMPS.LEFTBAR.NEW_ADD_PANEL_NAME
    : constants.ROOT_COMPS.LEFTBAR.ADD_PANEL_NAME;

  editorAPI.panelManager.openPanel(panelName, {
    origin: AddElementBiOrigin,
    ...options,
  });
};

const reportAddElementClick = (editorAPI: EditorAPI, compRef: CompRef) => {
  const componentDefaultBIParams = editorAPI.bi.getComponentsBIParams([
    compRef,
  ])[0];
  biLogger.report(
    sectionEmptyStateClick({
      ...componentDefaultBIParams,
      type: 'add-element',
      origin: 'layers_panel',
    }),
  );
};

const getAllNodesEmptyStateContent = (
  editorAPI: EditorAPI,
  compRef: CompRef,
  hasChildNodes: boolean,
) => ({
  SITE_HEADER: !hasChildNodes && {
    titleLabel: 'layers_section_header_text',
    ctaLabel: 'layers_section_header_cta',
    onClick: () => {
      scrollToCompAndSelect(editorAPI, compRef);
      openAddPanel(editorAPI, { category: 'navigation' });
      reportAddElementClick(editorAPI, compRef);
    },
  },
  SITE_FOOTER: !hasChildNodes && {
    titleLabel: 'layers_section_footer_text',
    ctaLabel: 'layers_section_footer_cta',
    onClick: () => {
      scrollToCompAndSelect(editorAPI, compRef);
      openAddPanel(editorAPI, {
        category: 'strip',
        sectionId: 'stripContainerContactSection',
      });
      reportAddElementClick(editorAPI, compRef);
    },
  },
});

const getIsComponentWithinPageOnXAxis = (
  componentLayout: CompLayout,
  widthInsideGridlines: number,
  widthOutsideGridlines: number,
) => {
  const minVisibleXCoordinate = 0 - widthOutsideGridlines;
  const maxVisibleXCoordinate = widthInsideGridlines + widthOutsideGridlines;
  return (
    componentLayout.x + componentLayout.width > minVisibleXCoordinate &&
    componentLayout.x < maxVisibleXCoordinate
  );
};

const getIsComponentWithinPageOnYAxis = (
  componentLayout: CompLayout,
  siteHeight: number,
) => {
  const minVisibleYCoordinate = 0;
  return (
    componentLayout.y + componentLayout.height > minVisibleYCoordinate &&
    componentLayout.y < siteHeight
  );
};

const isComponentWithinPage = (
  editorAPI: EditorAPI,
  comp: CompRef,
): boolean => {
  const siteHeight = editorAPI.documentServices.site.getHeight();
  const widthInsideGridlines = editorAPI.documentServices.site.getWidth();
  const widthOutsideGridlines = editorAPI.documentServices.site.getSiteX();
  const componentLayout =
    editorAPI.components.layout.getRelativeToStructure(comp);

  const isComponentWithinPageOnXAxis = getIsComponentWithinPageOnXAxis(
    componentLayout,
    widthInsideGridlines,
    widthOutsideGridlines,
  );

  const isComponentWithinPageOnYAxis = getIsComponentWithinPageOnYAxis(
    componentLayout,
    siteHeight,
  );

  return isComponentWithinPageOnXAxis && isComponentWithinPageOnYAxis;
};

function isComponentWithinViewport(editorAPI: EditorAPI, element: CompLayout) {
  const viewport = getViewPort(editorAPI.store.getState(), true);

  const isBelowTop = element.bounding.y >= viewport.y;
  const isAboveBottom =
    element.bounding.y + element.bounding.height <= viewport.bottomY;

  return isBelowTop && isAboveBottom;
}

export {
  getAllNodesEmptyStateContent,
  scrollToCompAndSelect,
  isComponentWithinPage,
};
