// eslint-disable-next-line @wix/santa-editor/scoped-imports
import * as baseDoubleClick from '@/rEditor/utils/mouseMoveActions/baseDoubleClick';
import React from 'react';
import * as BaseUI from '@/baseUI';
import * as util from '@/util';
import { cx } from '@/util';
import { getStageAnchorLineY } from './getStageAnchorLineY';
import type { StateMapperArgs } from 'types/redux';
import type { CompRef } from 'types/documentServices';
import type { EditorAPI } from '@/editorAPI';

type StageAnchorHandleStateProps = ReturnType<typeof mapStateToProps>;
type StageAnchorHandleDispatchProps = ReturnType<typeof mapDispatchToProps>;
interface StageAnchorHandleProps
  extends StageAnchorHandleOwnProps,
    StageAnchorHandleStateProps,
    StageAnchorHandleDispatchProps {}

interface StageAnchorHandleState {
  hovered: boolean;
}

class StageAnchorHandle extends React.Component<
  StageAnchorHandleProps,
  StageAnchorHandleState
> {
  static displayName = 'anchor';

  constructor(props: StageAnchorHandleProps) {
    super(props);
    this.state = {
      hovered: false,
    };
  }

  onAnchorMouseEnter = () => {
    this.setState({ hovered: true });
  };

  onAnchorMouseLeave = () => {
    this.setState({ hovered: false });
  };

  isLocked() {
    return false; // todo - integrate with the editor's locksmith
  }
  render() {
    return (
      <div
        style={{
          top: this.props.topPosition,
        }}
        className="anchorWrapper"
      >
        <div
          className={cx('anchor-line before', {
            'anchor-selected': this.props.isSelected,
            'anchor-hovered': this.state.hovered,
          })}
        />
        <div
          onMouseEnter={this.onAnchorMouseEnter}
          onMouseLeave={this.onAnchorMouseLeave}
          className={cx('anchor', {
            'anchor-selected': this.props.isSelected,
            'anchor-hovered': this.state.hovered,
          })}
        >
          <div
            onContextMenu={this.props.onContextMenu}
            onDoubleClick={this.props.onDoubleClick}
            className="anchorContent"
          >
            <BaseUI.symbol name="anchor" className="anchorIcon" />
            <div className="anchorName">{this.props.anchorName}</div>
            <BaseUI.symbol
              name={this.isLocked() ? 'anchorLock' : 'anchorDragHandle'}
              className="rightIcon"
            />
          </div>
        </div>
        <div
          className={cx('anchor-line after', {
            'anchor-selected': this.props.isSelected,
            'anchor-hovered': this.state.hovered,
          })}
        />
      </div>
    );
  }
}

const getAnchorName = (editorAPI: EditorAPI, compRef: CompRef): string => {
  const data = editorAPI.components.data.get(compRef);
  return data.name;
};

interface StageAnchorHandleOwnProps {
  compRef: CompRef;
  onContextMenu: (ev: React.MouseEvent) => void;
}

const mapStateToProps = (
  { editorAPI }: StateMapperArgs,
  ownProps: StageAnchorHandleOwnProps,
) => {
  const topPosition = getStageAnchorLineY(editorAPI, ownProps.compRef);
  const anchorName = getAnchorName(editorAPI, ownProps.compRef);
  const isSelected = editorAPI.selection.isComponentSelected(ownProps.compRef);

  return {
    topPosition,
    anchorName,
    isSelected,
  };
};

const getEditorAPI =
  () =>
  (
    dispatch: AnyFixMe,
    getState: AnyFixMe,
    { editorAPI }: AnyFixMe,
  ): EditorAPI =>
    editorAPI;

const mapDispatchToProps = (
  dispatch: AnyFixMe,
  ownProps: StageAnchorHandleOwnProps,
) => {
  const editorAPI = dispatch(getEditorAPI());
  return {
    onDoubleClick: (evt: AnyFixMe) => {
      const params = {
        evt,
        comp: [ownProps.compRef],
      };
      baseDoubleClick.doubleClick(editorAPI, params);
    },
  };
};

const ConnectedAnchor = util.hoc.connect(
  util.hoc.STORES.MOUSE_OPS,
  mapStateToProps,
  mapDispatchToProps,
)(StageAnchorHandle);

export default ConnectedAnchor;
