import React, {
  useState,
  useMemo,
  useCallback,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { symbol as Symbol } from '@wix/santa-editor-symbols';
import { Tooltip } from '@wix/wix-base-ui';
import { useTranslation } from 'react-i18next';

import { hoc, cx } from '@/util';
import { SectionTypes } from '@/sectionsOnStage';

import { mapStateToProps, mapDispatchToProps } from './AddSection.mapper';
import { shouldShowSelector } from './AddSection.selectors';
import { AUTOMATION_IDS } from './AddSection.constants';

import type { AddSectionImperativeAPI } from './AddSection.types';

const {
  connect,
  STORES: { EDITOR_API },
  withConditionalRender,
} = hoc;

const enum ButtonAlignment {
  TOP = 'TOP',
  BOTTOM = 'BOTTOM',
}
const SECTION_PADDING_FOR_MOUSE = 30;
const INIT_MOUSE_Y = 0;

type AddSectionProps = {
  forwaredRef: React.Ref<AddSectionImperativeAPI>;
  initMousePosition?: { x: number; y: number };
} & ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const AddSectionComponent: React.FC<AddSectionProps> = ({
  isIntersectionWithSelected,
  hoveredSection,

  forwaredRef,
  openAddPanel,
  logButtonBi,

  initMousePosition = { x: 0, y: INIT_MOUSE_Y },
}) => {
  const [translate] = useTranslation();

  const [mousePosition, setMousePosition] = useState(initMousePosition);

  const { y: mouseY } = mousePosition;

  useImperativeHandle(forwaredRef, () => ({
    setMousePosition,
  }));

  const buttonAlignment = useMemo(() => {
    if (hoveredSection.type === SectionTypes.Footer) {
      return ButtonAlignment.TOP;
    }
    if (hoveredSection.type === SectionTypes.Header) {
      return ButtonAlignment.BOTTOM;
    }

    return mouseY - hoveredSection.top < hoveredSection.bottom - mouseY
      ? ButtonAlignment.TOP
      : ButtonAlignment.BOTTOM;
  }, [mouseY, hoveredSection]);

  const buttonTopPosition = useMemo(() => {
    return buttonAlignment === ButtonAlignment.TOP
      ? hoveredSection.top
      : hoveredSection.bottom;
  }, [buttonAlignment, hoveredSection]);

  const stopEvent = useCallback((event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
  }, []);

  const handleButtonClick = useCallback(
    (event: React.MouseEvent) => {
      stopEvent(event);

      const shouldAddAfterHoveredSection =
        buttonAlignment === ButtonAlignment.BOTTOM;

      openAddPanel(shouldAddAfterHoveredSection);
      logButtonBi('click');
    },
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [buttonAlignment, hoveredSection],
  );

  const shouldHideButtons =
    isIntersectionWithSelected &&
    hoveredSection.top + SECTION_PADDING_FOR_MOUSE < mouseY &&
    hoveredSection.bottom - SECTION_PADDING_FOR_MOUSE > mouseY;

  if (mouseY === INIT_MOUSE_Y) {
    return null;
  }

  if (shouldHideButtons) {
    return null;
  }

  return (
    <div
      key={`position-${buttonTopPosition}`}
      className="add-section-container"
      style={{
        top: hoveredSection.top,
        height: hoveredSection.height,
      }}
    >
      <div onMouseUp={stopEvent} onMouseDown={stopEvent}>
        <Tooltip
          content={translate('section_onstage_add_button_tooltip')}
          shouldTranslate={false}
          marginTop={8}
          openDelay={250}
        >
          <div
            data-aid={AUTOMATION_IDS.BUTTON}
            className={cx('add-section-button', {
              'position-top': buttonAlignment === ButtonAlignment.TOP,
              'position-bottom': buttonAlignment === ButtonAlignment.BOTTOM,
            })}
            onClick={handleButtonClick}
            onMouseEnter={() => {
              logButtonBi('hover');
            }}
          >
            <Symbol name="plus10px" />
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

export const ConnectedAddSection = withConditionalRender(
  EDITOR_API,
  shouldShowSelector,
)(
  connect(EDITOR_API, mapStateToProps, mapDispatchToProps)(AddSectionComponent),
);

export const AddSection = forwardRef<AddSectionImperativeAPI>((props, ref) => (
  <ConnectedAddSection {...props} forwaredRef={ref} />
));

AddSection.displayName = 'AddSection';
