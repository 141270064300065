import { SECTIONS_TYPES } from '@/addPanelDataConsts';
import { constants as mediaPanelInfraConstants } from '@/mediaManagerPanelInfra';
import constants from '@/constants';

const { MEDIA_MANAGER_PANEL_SECTION_ID } = mediaPanelInfraConstants;
const { MEDIA_MANAGER_MEDIA_TYPES } = constants;

export const wixImagesSection = {
  type: SECTIONS_TYPES.DYNAMIC_MEDIA_SECTIONS_BOX,
  title: 'add_section_label_wix_images',
  subNavigationTitle: 'add_section_heading_wix_images',
  hide: false,
  automationId: 'addPanel_imageDynamic_wixImagesSection',
  props: {
    biOriginBase: 'santa-editor',
    items: [
      {
        id: MEDIA_MANAGER_PANEL_SECTION_ID.WIX_IMAGES,
        mediaType: MEDIA_MANAGER_MEDIA_TYPES.PICTURE,
        automationId: 'addPanel_imageDynamic_wixImagesSection',
      },
    ],
  },
  help: {
    hide: false,
    text: 'add_section_heading_wix_images_tooltip_description',
  },
};
