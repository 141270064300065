import * as stateManagement from '@/stateManagement';

import type { ProgressStatus } from '@/constants';

import type { MapStateToProps, MapDispatchToProps } from 'types/redux';

import type {
  PublishButtonDispatchProps,
  PublishButtonOwnProps,
  PublishButtonStateProps,
} from './publish';

import { fixedStage } from '@/util';

import constants from '@/constants';
const { selectors: savePublishSelectors } = stateManagement.savePublish;
const { getSiteUserPreferences } = stateManagement.userPreferences.selectors;

export const mapStateToProps: MapStateToProps<
  PublishButtonStateProps,
  PublishButtonOwnProps
> = ({ editorAPI, state }) => {
  const disabled =
    !editorAPI.savePublish.canUserPublish() ||
    editorAPI.savePublish.isPublishInProgress() ||
    editorAPI.savePublish.isSaveInProgress() ||
    savePublishSelectors.getIsBuildInProgress(state);

  const shouldSaveBeforePublish =
    editorAPI.publishManager.shouldSaveBeforePublish() ||
    editorAPI.publishManager.hasOverriddenSaveAndPublish();

  const isMobileEditor = editorAPI.isMobileEditor();
  const isInPreviewMode = editorAPI.preview.isInPreviewMode();
  const isSitePublished = editorAPI.dsRead?.generalInfo.isSitePublished();
  const siteWasOnlyAutoPublished = getSiteUserPreferences(
    constants.USER_PREFS.PUBLISH.SITE_WAS_ONLY_AUTO_PUBLISHED,
  )(editorAPI.store.getState());
  const isFirstManualPublish = siteWasOnlyAutoPublished || !isSitePublished;
  const isPremium = editorAPI.site?.isPremium();
  const numberOfManualPublish = getSiteUserPreferences('numberOfManualPublish')(
    editorAPI.store.getState(),
  ) as number;

  return {
    disabled,
    shouldSaveBeforePublish,
    status: state.publishingStatus as ProgressStatus,
    isMobileEditor,
    isInPreviewMode,
    isSitePublished,
    isFirstManualPublish,
    isPremium,
    numberOfManualPublish,
    topBarStateBIParamValue: fixedStage.getTopBarStateBiParamValue(editorAPI),
  };
};

export const mapDispatchToProps: MapDispatchToProps<
  PublishButtonDispatchProps,
  PublishButtonOwnProps
> = {
  publish: (...args) => stateManagement.savePublish.actions.publish(...args),
  saveAndPublish: (...args) =>
    stateManagement.savePublish.actions.saveAndPublish(...args),
};
