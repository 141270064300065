import { HttpClient } from '@wix/http-client';
import { WixDataConnectorSettings } from '@wix/wix-data-connector-settings-client';

export const createConnectorsService = (instance: string) => {
  const wixDataConnectorSettingsClient = new WixDataConnectorSettings(
    new HttpClient(),
    instance,
  );
  return {
    getAllConnectors: () => wixDataConnectorSettingsClient.getAllConnectors(),
  };
};
