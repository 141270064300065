'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')

module.exports = {
    'type': addPanelDataConsts.SECTIONS_TYPES.DATA,
    'hide': false,
    'title': 'PLATFORM_ADD_SECTION_CMS_HEADING2',
    'showSectionHeader': true,
    'props': {
        items: [
            {
                title: 'PLATFORM_ADD_SECTION_LABEL_NEW_COLLECTION',
                image: 'addPanelData/sections/dataBinding/addCollection.png',
                action: addPanelDataConsts.CLICK_ACTION.DATA.ADD_COLLECTION,
                tooltip: 'PLATFORM_ADD_SECTION_COLLECTION_TOOLTIP',
                desc: 'PLATFORM_ADD_SECTION_NEW_COLLECTION_DESCRIPTION'
            }
        ]
    }
}
