"use strict";
const addPanelDataConsts = require("@/addPanelDataConsts");

module.exports = {
  type: addPanelDataConsts.SECTIONS_TYPES.PRESET,
  hide: false,
  title: "add_section_label_sharebuttons",
  subNavigationTitle: "add_section_label_sharebuttons",
  presetTitle: "add_section_label_sharebuttons",
  tooltipTitle: "add_section_label_sharebuttons_tooltip_description",
  automationId: "add_section_label_sharebuttons",
  subNavigationHide: true,
  showSectionHeader: false,
  additionalBehaviours: {
    labelMode: addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
    infoIcon: addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
    hoverImageAction: addPanelDataConsts.HOVER_IMAGE_ACTIONS.IMAGE,
    iconEnabledForComps: {},
  },
  props: {
    image: "addPanelData/sections/shareButtons_en/shareButtons_en.png",
    imageHover: null,
    items: [
      {
        id: "Social_Share_Buttons_1",
        structure: {
          activeVariants: [],
          referredVariants: {},
          style: {
            styleType: "custom",
            componentClassName: "wixui.ShareButtons",
            style: {
              groups: {},
              properties: {
                alignmentDirection: "center",
                "$st-css":
                  ".root::button::icon {\n    width: 25px;\n    height: 25px;\n}:import {\n    -st-from: 'wix-ui-santa/index.st.css';\n    -st-named: ShareButtons;\n}\n.root{\n    -st-extends: ShareButtons;\n}\n.root::button {\n    background-color: rgba(255,255,255,0);\n    border-radius: 0px;\n    padding: 0px;\n}\n.root::button::label {\n    -st-mixin: font_8;\n    font-size: 10px;\n}\n.root::button:hover::label {\n    text-decoration-line: underline;\n}",
                iconTextSpacing: "8",
                buttonsAlignment: "horizontal",
                horizontalDirection: "ltr",
                buttonsSpacing: "10",
                rowsSpacing: "10",
                iconPosition: "top",
                buttonContent: "Text and Icon",
              },
              propertiesSource: {
                alignmentDirection: "value",
                "$st-css": "value",
                iconTextSpacing: "value",
                buttonsAlignment: "value",
                horizontalDirection: "value",
                buttonsSpacing: "value",
                rowsSpacing: "value",
                iconPosition: "value",
                buttonContent: "value",
              },
            },
            pageId: "",
            compId: "",
            type: "ComponentStyle",
            metaData: {
              isPreset: false,
              schemaVersion: "1.0",
              isHidden: false,
              pageId: "tuckg",
            },
            skin: "wixui.skins.Skinless",
          },
          activeModes: {},
          data: {
            buttons: [
              {
                unavailable: false,
                label: "Facebook",
                isHidden: false,
                platformId: "wixui_ShareButtons_facebook",
                illustration: {
                  svgId: "11062b_30fe5729438347de94ad9836fa5e803e.svg",
                  type: "SVGItem",
                  metaData: {
                    schemaVersion: "1.0",
                    isPreset: false,
                    isHidden: false,
                    pageId: "tuckg",
                  },
                },
                disabled: false,
                type: "IShareButton",
                metaData: {
                  schemaVersion: "1.0",
                  isPreset: false,
                  isHidden: false,
                  pageId: "tuckg",
                },
                value: "wixui_ShareButtons_manage_facebook_share_address",
                isDefault: false,
              },
              {
                unavailable: false,
                label: "X (Twitter)",
                isHidden: false,
                platformId: "wixui_ShareButtons_twitter",
                illustration: {
                  svgId: "11062b_2a5163bb94b641168e0a22e56b23e8d4.svg",
                  type: "SVGItem",
                  metaData: {
                    schemaVersion: "1.0",
                    isPreset: false,
                    isHidden: false,
                    pageId: "tuckg",
                  },
                },
                disabled: false,
                type: "IShareButton",
                metaData: {
                  schemaVersion: "1.0",
                  isPreset: false,
                  isHidden: false,
                  pageId: "tuckg",
                },
                value: "wixui_ShareButtons_manage_twitter_share_address",
                isDefault: false,
              },
              {
                unavailable: false,
                label: "WhatsApp",
                isHidden: false,
                platformId: "wixui_ShareButtons_whatsapp",
                illustration: {
                  svgId: "11062b_65971442c3364e5c9d0e5d430674994b.svg",
                  type: "SVGItem",
                  metaData: {
                    schemaVersion: "1.0",
                    isPreset: false,
                    isHidden: false,
                    pageId: "tuckg",
                  },
                },
                disabled: false,
                type: "IShareButton",
                metaData: {
                  schemaVersion: "1.0",
                  isPreset: false,
                  isHidden: false,
                  pageId: "tuckg",
                },
                value: "wixui_ShareButtons_manage_whatsapp_share_address",
                isDefault: false,
              },
              {
                unavailable: false,
                label: "LinkedIn",
                isHidden: false,
                platformId: "wixui_ShareButtons_linkedin",
                illustration: {
                  svgId: "11062b_ab104dadc4064fc1b76441c4a8fdca27.svg",
                  type: "SVGItem",
                  metaData: {
                    schemaVersion: "1.0",
                    isPreset: false,
                    isHidden: false,
                    pageId: "tuckg",
                  },
                },
                disabled: false,
                type: "IShareButton",
                metaData: {
                  schemaVersion: "1.0",
                  isPreset: false,
                  isHidden: false,
                  pageId: "tuckg",
                },
                value: "wixui_ShareButtons_manage_linkedin_share_address",
                isDefault: false,
              },
              {
                unavailable: false,
                label: "Pinterest",
                isHidden: false,
                platformId: "wixui_ShareButtons_pinterest",
                illustration: {
                  svgId: "11062b_04de07b936da4bffbe6c9d2daaffeceb.svg",
                  type: "SVGItem",
                  metaData: {
                    schemaVersion: "1.0",
                    isPreset: false,
                    isHidden: false,
                    pageId: "tuckg",
                  },
                },
                disabled: false,
                type: "IShareButton",
                metaData: {
                  schemaVersion: "1.0",
                  isPreset: false,
                  isHidden: false,
                  pageId: "tuckg",
                },
                value: "wixui_ShareButtons_manage_pinterest_share_address",
                isDefault: false,
              },
              {
                unavailable: false,
                label: "wixui_ShareButtons_manage_copy_link_default_text",
                isHidden: false,
                platformId: "wixui_ShareButtons_copyButton",
                illustration: {
                  svgId: "11062b_c5c2820390524c06b5aa2793b0eb464d.svg",
                  type: "SVGItem",
                  metaData: {
                    schemaVersion: "1.0",
                    isPreset: false,
                    isHidden: false,
                    pageId: "tuckg",
                  },
                },
                disabled: false,
                type: "IShareButton",
                metaData: {
                  schemaVersion: "1.0",
                  isPreset: false,
                  isHidden: false,
                  pageId: "tuckg",
                },
                value: "wixui_ShareButtons_manage_copy_link_share_address",
                isDefault: false,
              },
            ],
            shareUrl: "",
            pageToShare: "home",
            type: "wixui.ShareButtons",
            metaData: {
              schemaVersion: "1.0",
              isPreset: false,
              isHidden: false,
              pageId: "tuckg",
            },
          },
          componentType: "wixui.ShareButtons",
          id: "comp-lpqroe0x",
          mobileHints: {
            type: "MobileHints",
            metaData: {
              isPreset: false,
              schemaVersion: "1.0",
              isHidden: false,
              pageId: "tuckg",
            },
          },
          layout: {
            x: 10,
            fixedPosition: false,
            y: 18,
            scale: 1,
            height: 44,
            rotationInDegrees: 0,
            width: 304,
          },
          type: "Component",
          metaData: {
            pageId: "tuckg",
          },
          skin: "wixui.skins.Skinless",
        },
        preset: {
          rect: {
            width: 324,
            height: 80,
            x: 0,
            y: 0,
          },
          tags: null,
        },
      },
      {
        id: "Social_Share_Buttons_2",
        structure: {
          activeVariants: [],
          referredVariants: {},
          style: {
            styleType: "custom",
            componentClassName: "wixui.ShareButtons",
            style: {
              groups: {},
              properties: {
                alignmentDirection: "center",
                "$st-css":
                  ".root::button::icon {\n    width: 26px;\n    height: 26px;\n}:import {\n    -st-from: 'wix-ui-santa/index.st.css';\n    -st-named: ShareButtons;\n}\n.root{\n    -st-extends: ShareButtons;\n}\n.root::button {\n    box-shadow: 0 0 5px rgba(0, 0, 0, 0.45);\n    border-radius: 5em;\n    background-color: rgb(255,255,255);\n    padding: 10px;\n}\n.root::button:hover {\n    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.45);\n}\n.root::button::label {\n    -st-mixin: font_8;\n    font-size: 12px;\n}",
                iconTextSpacing: "8",
                buttonsAlignment: "horizontal",
                horizontalDirection: "ltr",
                buttonsSpacing: "5",
                rowsSpacing: "5",
                iconPosition: "top",
                buttonContent: "Icon",
              },
              propertiesSource: {
                alignmentDirection: "value",
                "$st-css": "value",
                iconTextSpacing: "value",
                buttonsAlignment: "value",
                horizontalDirection: "value",
                buttonsSpacing: "value",
                rowsSpacing: "value",
                iconPosition: "value",
                buttonContent: "value",
              },
            },
            pageId: "",
            compId: "",
            type: "ComponentStyle",
            metaData: {
              isPreset: false,
              schemaVersion: "1.0",
              isHidden: false,
              pageId: "tuckg",
            },
            skin: "wixui.skins.Skinless",
          },
          activeModes: {},
          data: {
            buttons: [
              {
                unavailable: false,
                label: "Facebook",
                isHidden: false,
                platformId: "wixui_ShareButtons_facebook",
                illustration: {
                  svgId: "11062b_30fe5729438347de94ad9836fa5e803e.svg",
                  type: "SVGItem",
                  metaData: {
                    schemaVersion: "1.0",
                    isPreset: false,
                    isHidden: false,
                    pageId: "tuckg",
                  },
                },
                disabled: false,
                type: "IShareButton",
                metaData: {
                  schemaVersion: "1.0",
                  isPreset: false,
                  isHidden: false,
                  pageId: "tuckg",
                },
                value: "wixui_ShareButtons_manage_facebook_share_address",
                isDefault: false,
              },
              {
                unavailable: false,
                label: "X (Twitter)",
                isHidden: false,
                platformId: "wixui_ShareButtons_twitter",
                illustration: {
                  svgId: "11062b_2a5163bb94b641168e0a22e56b23e8d4.svg",
                  type: "SVGItem",
                  metaData: {
                    schemaVersion: "1.0",
                    isPreset: false,
                    isHidden: false,
                    pageId: "tuckg",
                  },
                },
                disabled: false,
                type: "IShareButton",
                metaData: {
                  schemaVersion: "1.0",
                  isPreset: false,
                  isHidden: false,
                  pageId: "tuckg",
                },
                value: "wixui_ShareButtons_manage_twitter_share_address",
                isDefault: false,
              },
              {
                unavailable: false,
                label: "WhatsApp",
                isHidden: false,
                platformId: "wixui_ShareButtons_whatsapp",
                illustration: {
                  svgId: "11062b_65971442c3364e5c9d0e5d430674994b.svg",
                  type: "SVGItem",
                  metaData: {
                    schemaVersion: "1.0",
                    isPreset: false,
                    isHidden: false,
                    pageId: "tuckg",
                  },
                },
                disabled: false,
                type: "IShareButton",
                metaData: {
                  schemaVersion: "1.0",
                  isPreset: false,
                  isHidden: false,
                  pageId: "tuckg",
                },
                value: "wixui_ShareButtons_manage_whatsapp_share_address",
                isDefault: false,
              },
              {
                unavailable: false,
                label: "LinkedIn",
                isHidden: false,
                platformId: "wixui_ShareButtons_linkedin",
                illustration: {
                  svgId: "11062b_ab104dadc4064fc1b76441c4a8fdca27.svg",
                  type: "SVGItem",
                  metaData: {
                    schemaVersion: "1.0",
                    isPreset: false,
                    isHidden: false,
                    pageId: "tuckg",
                  },
                },
                disabled: false,
                type: "IShareButton",
                metaData: {
                  schemaVersion: "1.0",
                  isPreset: false,
                  isHidden: false,
                  pageId: "tuckg",
                },
                value: "wixui_ShareButtons_manage_linkedin_share_address",
                isDefault: false,
              },
              {
                unavailable: false,
                label: "Pinterest",
                isHidden: false,
                platformId: "wixui_ShareButtons_pinterest",
                illustration: {
                  svgId: "11062b_04de07b936da4bffbe6c9d2daaffeceb.svg",
                  type: "SVGItem",
                  metaData: {
                    schemaVersion: "1.0",
                    isPreset: false,
                    isHidden: false,
                    pageId: "tuckg",
                  },
                },
                disabled: false,
                type: "IShareButton",
                metaData: {
                  schemaVersion: "1.0",
                  isPreset: false,
                  isHidden: false,
                  pageId: "tuckg",
                },
                value: "wixui_ShareButtons_manage_pinterest_share_address",
                isDefault: false,
              },
              {
                unavailable: false,
                label: "wixui_ShareButtons_manage_copy_link_default_text",
                isHidden: false,
                platformId: "wixui_ShareButtons_copyButton",
                illustration: {
                  svgId: "11062b_c5c2820390524c06b5aa2793b0eb464d.svg",
                  type: "SVGItem",
                  metaData: {
                    schemaVersion: "1.0",
                    isPreset: false,
                    isHidden: false,
                    pageId: "tuckg",
                  },
                },
                disabled: false,
                type: "IShareButton",
                metaData: {
                  schemaVersion: "1.0",
                  isPreset: false,
                  isHidden: false,
                  pageId: "tuckg",
                },
                value: "wixui_ShareButtons_manage_copy_link_share_address",
                isDefault: false,
              },
            ],
            shareUrl: "",
            pageToShare: "home",
            type: "wixui.ShareButtons",
            metaData: {
              schemaVersion: "1.0",
              isPreset: false,
              isHidden: false,
              pageId: "tuckg",
            },
          },
          componentType: "wixui.ShareButtons",
          id: "comp-lpqqx4wv",
          mobileHints: {
            type: "MobileHints",
            metaData: {
              isPreset: false,
              schemaVersion: "1.0",
              isHidden: false,
              pageId: "tuckg",
            },
          },
          layout: {
            x: 10,
            fixedPosition: false,
            y: 93,
            scale: 1,
            height: 46,
            rotationInDegrees: 0,
            width: 304,
          },
          type: "Component",
          metaData: {
            pageId: "tuckg",
          },
          skin: "wixui.skins.Skinless",
        },
        preset: {
          rect: {
            width: 324,
            height: 75,
            x: 0,
            y: 80,
          },
          tags: null,
        },
      },
      {
        id: "Social_Share_Buttons_3",
        structure: {
          activeVariants: [],
          referredVariants: {},
          style: {
            styleType: "custom",
            componentClassName: "wixui.ShareButtons",
            style: {
              groups: {},
              properties: {
                alignmentDirection: "center",
                "$st-css":
                  ".root::button::icon {\n    width: 25px;\n    height: 25px;\n}:import {\n    -st-from: 'wix-ui-santa/index.st.css';\n    -st-named: ShareButtons;\n}\n.root{\n    -st-extends: ShareButtons;\n}\n.root::button {\n    background-color: rgba(255,255,255,0);\n    box-shadow: /*0 0 5px rgba(0, 0, 0, 0.45)*/;\n    border-radius: 0px;\n    padding: 0px;\n}\n.root::button:hover {\n    background-color: rgb(255,255,255);\n}\n.root::button::label {\n    -st-mixin: font_8;\n    font-size: 12px;\n}",
                iconTextSpacing: "8",
                buttonsAlignment: "horizontal",
                horizontalDirection: "ltr",
                buttonsSpacing: "25",
                rowsSpacing: "15",
                iconPosition: "top",
                buttonContent: "Icon",
              },
              propertiesSource: {
                alignmentDirection: "value",
                "$st-css": "value",
                iconTextSpacing: "value",
                buttonsAlignment: "value",
                horizontalDirection: "value",
                buttonsSpacing: "value",
                rowsSpacing: "value",
                iconPosition: "value",
                buttonContent: "value",
              },
            },
            pageId: "",
            compId: "",
            type: "ComponentStyle",
            metaData: {
              isPreset: false,
              schemaVersion: "1.0",
              isHidden: false,
              pageId: "tuckg",
            },
            skin: "wixui.skins.Skinless",
          },
          activeModes: {},
          data: {
            buttons: [
              {
                unavailable: false,
                label: "Facebook",
                isHidden: false,
                platformId: "wixui_ShareButtons_facebook",
                illustration: {
                  svgId: "11062b_f8a153d79a0446ffa5afd751f7e2dba7.svg",
                  type: "SVGItem",
                  metaData: {
                    schemaVersion: "1.0",
                    isPreset: false,
                    isHidden: false,
                    pageId: "tuckg",
                  },
                },
                disabled: false,
                type: "IShareButton",
                metaData: {
                  schemaVersion: "1.0",
                  isPreset: false,
                  isHidden: false,
                  pageId: "tuckg",
                },
                value: "wixui_ShareButtons_manage_facebook_share_address",
                isDefault: false,
              },
              {
                unavailable: false,
                label: "X (Twitter)",
                isHidden: false,
                platformId: "wixui_ShareButtons_twitter",
                illustration: {
                  svgId: "11062b_2a5163bb94b641168e0a22e56b23e8d4.svg",
                  type: "SVGItem",
                  metaData: {
                    schemaVersion: "1.0",
                    isPreset: false,
                    isHidden: false,
                    pageId: "tuckg",
                  },
                },
                disabled: false,
                type: "IShareButton",
                metaData: {
                  schemaVersion: "1.0",
                  isPreset: false,
                  isHidden: false,
                  pageId: "tuckg",
                },
                value: "wixui_ShareButtons_manage_twitter_share_address",
                isDefault: false,
              },
              {
                unavailable: false,
                label: "WhatsApp",
                isHidden: false,
                platformId: "wixui_ShareButtons_whatsapp",
                illustration: {
                  svgId: "11062b_af8104617560492aaae4b7f1da222fd1.svg",
                  type: "SVGItem",
                  metaData: {
                    schemaVersion: "1.0",
                    isPreset: false,
                    isHidden: false,
                    pageId: "tuckg",
                  },
                },
                disabled: false,
                type: "IShareButton",
                metaData: {
                  schemaVersion: "1.0",
                  isPreset: false,
                  isHidden: false,
                  pageId: "tuckg",
                },
                value: "wixui_ShareButtons_manage_whatsapp_share_address",
                isDefault: false,
              },
              {
                unavailable: false,
                label: "LinkedIn",
                isHidden: false,
                platformId: "wixui_ShareButtons_linkedin",
                illustration: {
                  svgId: "11062b_c7e30c6c998746fc94c9e27f5b8c89c6.svg",
                  type: "SVGItem",
                  metaData: {
                    schemaVersion: "1.0",
                    isPreset: false,
                    isHidden: false,
                    pageId: "tuckg",
                  },
                },
                disabled: false,
                type: "IShareButton",
                metaData: {
                  schemaVersion: "1.0",
                  isPreset: false,
                  isHidden: false,
                  pageId: "tuckg",
                },
                value: "wixui_ShareButtons_manage_linkedin_share_address",
                isDefault: false,
              },
              {
                unavailable: false,
                label: "Pinterest",
                isHidden: false,
                platformId: "wixui_ShareButtons_pinterest",
                illustration: {
                  svgId: "11062b_03bc06b75ce44a15a8186371ce37a9b9.svg",
                  type: "SVGItem",
                  metaData: {
                    schemaVersion: "1.0",
                    isPreset: false,
                    isHidden: false,
                    pageId: "tuckg",
                  },
                },
                disabled: false,
                type: "IShareButton",
                metaData: {
                  schemaVersion: "1.0",
                  isPreset: false,
                  isHidden: false,
                  pageId: "tuckg",
                },
                value: "wixui_ShareButtons_manage_pinterest_share_address",
                isDefault: false,
              },
              {
                unavailable: false,
                label: "wixui_ShareButtons_manage_copy_link_default_text",
                isHidden: false,
                platformId: "wixui_ShareButtons_copyButton",
                illustration: {
                  svgId: "11062b_c5c2820390524c06b5aa2793b0eb464d.svg",
                  type: "SVGItem",
                  metaData: {
                    schemaVersion: "1.0",
                    isPreset: false,
                    isHidden: false,
                    pageId: "tuckg",
                  },
                },
                disabled: false,
                type: "IShareButton",
                metaData: {
                  schemaVersion: "1.0",
                  isPreset: false,
                  isHidden: false,
                  pageId: "tuckg",
                },
                value: "wixui_ShareButtons_manage_copy_link_share_address",
                isDefault: false,
              },
            ],
            shareUrl: "",
            pageToShare: "home",
            type: "wixui.ShareButtons",
            metaData: {
              schemaVersion: "1.0",
              isPreset: false,
              isHidden: false,
              pageId: "tuckg",
            },
          },
          componentType: "wixui.ShareButtons",
          id: "comp-lpqr1uy6",
          mobileHints: {
            type: "MobileHints",
            metaData: {
              isPreset: false,
              schemaVersion: "1.0",
              isHidden: false,
              pageId: "tuckg",
            },
          },
          layout: {
            x: 10,
            fixedPosition: false,
            y: 171,
            scale: 1,
            height: 25,
            rotationInDegrees: 0,
            width: 304,
          },
          type: "Component",
          metaData: {
            pageId: "tuckg",
          },
          skin: "wixui.skins.Skinless",
        },
        preset: {
          rect: {
            width: 324,
            height: 60,
            x: 0,
            y: 155,
          },
          tags: null,
        },
      },
    ],
    compTypes: ["wixui.ShareButtons"],
  },
  help: {
    hide: true,
    text: "add_section_label_sharebuttons",
  },
};
