:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._page-variants-panel_1rxej_7 .wbu-action-item {
  border-radius: 8px;
  border: 1px solid #dfe5eb;
  margin-top: 12px;
  height: 90px; }

._page-variants-panel_1rxej_7 ._subtitle_1rxej_13 {
  width: 612px;
  height: 48px; }

._page-variants-panel_1rxej_7 ._link-button_1rxej_17 {
  padding-left: 5px;
  margin-bottom: 3px; }

._page-variants-panel_1rxej_7 ._illustration_1rxej_21 {
  height: 48px;
  width: 48px; }
