// @ts-nocheck
import ReactDOM from 'react-dom';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import * as util from '@/util';
import * as core from '@/core';
import React from 'react';
import { FocusPanelFrame } from '../frames';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'blogManagerDialog',
  mixins: [util.postMessageMixin, core.mixins.editorAPIMixin],
  propTypes: {
    blogManagerAppPathType: PropTypes.string, // path type in the iframe
    api: PropTypes.object, // iframe api
  },

  getInitialState() {
    return {
      src: this.props.api.frameUrl,
    };
  },

  UNSAFE_componentWillMount() {
    this.setPostMessageHandler(null, this.onMessageFromDialog);
  },

  onMessageFromDialog(messageData) {
    switch (messageData.type) {
      case 'ready':
        this.onReady();

        break;
      case 'anchors':
        if (messageData.payload.pageId) {
          this.onAnchorsRequest(messageData.payload.pageId);
        }

        break;
      case 'cancel':
        if (
          messageData.payload === undefined ||
          !messageData.payload.postsUnchanged
        ) {
          this.props.api.reloadAppPartsMethod();
        }
        this.getEditorAPI().panelManager.closePanelByName(this.props.panelName);

        break;
    }
  },

  onAnchorsRequest(pageId) {
    const msgData = JSON.stringify({
      type: 'anchors',
      payload: {
        anchorsData: this.props.api.getAnchorsByPage(pageId),
        pageId,
      },
    });

    ReactDOM.findDOMNode(this.refs.iframe).contentWindow.postMessage(
      msgData,
      '*',
    );
  },

  onReady() {
    const msgData = JSON.stringify({
      type: 'settings',
      payload: {
        isOpenFromSantaEditor: true,
        themeData: this.props.api.themeData,
        themeDataSchema: this.props.api.themeDataSchema,
        WIXAPPS_EDITOR_STYLES: this.props.api.WIXAPPS_EDITOR_STYLES,
        CK_EDITOR_FONT_STYLES: this.props.api.CK_EDITOR_FONT_STYLES,
        CustomUsedFontsUrl: this.props.api.CustomUsedFontsUrl,
        FontsCssUrls: this.props.api.FontsCssUrls,
        FontsData: this.props.api.FontsData,
        FontsMetadata: this.props.api.FontsMetadata,
        pagesData: this.props.api.pagesData,
        currentPageId: this.props.api.currentPageId,
        charSet: this.props.api.charSet,
        siteUrl: this.props.api.siteUrl,
        isSitePublished: this.props.api.isSitePublished,
        imageOrganizerUrl: this.props.api.imageOrganizerUrl,
        urlFormat: this.props.api.urlFormat,
        blogPageTitle: this.props.api.blogPageTitle,
        singlePostLayout: this.props.api.singlePostLayout,
        siteTitle: this.props.api.siteTitle,
        isHeroImageEnabled: this.props.api.isHeroImageEnabled,
        isHttpsEnabled: this.props.api.isHttpsEnabled,
      },
    });

    ReactDOM.findDOMNode(this.refs.iframe).contentWindow.postMessage(
      msgData,
      '*',
    );
  },

  render() {
    return (
      <div className="blog-manager-panel">
        <FocusPanelFrame
          panelName={this.props.panelName}
          shouldHideHeader={true}
          className="blog-manager-panel"
        >
          <iframe
            id="blogManager"
            ref="iframe"
            width="100%"
            height="100%"
            src={this.state.src}
            frameBorder="0"
            allowFullScreen
          />
        </FocusPanelFrame>
      </div>
    );
  },
});
