'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')

const singleTrackPlayerSection = {
    'type': addPanelDataConsts.SECTIONS_TYPES.PRESET,
    'hide': false,
    'title': 'add_section_label_singletrackplayer',
    'subNavigationTitle': 'add_section_label_singletrackplayer',
    'presetTitle': 'add_section_label_singletrackplayer',
    'tooltipTitle': 'add_section_label_singletrackplayer',
    "automationId": "add-panel-section-singleTrackPlayerSectionSection",
    'subNavigationHide': false,
    'showSectionHeader': true,
    'additionalBehaviours': {
        'labelMode': addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
        'infoIcon': addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
        'hoverImageAction': addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
        'iconEnabledForComps': {}
    },
    'props': {
        'image': 'addPanelData/sections/singleTrackPlayerSection_en/singleTrackPlayerSection_en.png',
        'imageHover': null,
        'items': [{
            'id': 'Music_Single_Track_Player_1',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.viewer.skins.audioplayer.SimplePlayer',
                'layout': {
                    'width': 40,
                    'height': 40,
                    'x': 10,
                    'y': 15,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.viewer.components.AudioPlayer',
                'data': {
                    'type': 'AudioPlayer',
                    'metaData': {'isPreset': true, 'schemaVersion': '1.0', 'isHidden': false},
                    'uri': '',
                    'autoPlay': false,
                    'loop': false,
                    'visible': true,
                    'volume': 100,
                    'showTooltip': false
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {
                            'alpha-bg': '1',
                            'alpha-bgh': '1',
                            'alpha-brd': '1',
                            'alpha-brdh': '1',
                            'alpha-icon': '1',
                            'alpha-iconh': '1',
                            'bg': '#FFFFFF',
                            'bgh': '#342216',
                            'boxShadowToggleOn-shd': 'false',
                            'brd': '#342216',
                            'brdh': '#FFFFFF',
                            'brw': '1px',
                            'icon': '#342216',
                            'iconh': '#FFFFFF',
                            'rd': '0px',
                            'shd': '0px 1px 4px 0px rgba(0,0,0,0.6)'
                        },
                        'propertiesSource': {
                            'bg': 'value',
                            'bgh': 'value',
                            'brd': 'value',
                            'brdh': 'value',
                            'brw': 'value',
                            'icon': 'value',
                            'iconh': 'value',
                            'rd': 'value',
                            'shd': 'value'
                        }
                    },
                    'componentClassName': 'wysiwyg.viewer.components.AudioPlayer',
                    'pageId': '',
                    'compId': 'i6g763ve_0',
                    'styleType': 'custom',
                    'skin': 'wysiwyg.viewer.skins.audioplayer.SimplePlayer'
                },
                'id': 'i6g763ve_0'
            },
            'preset': {'rect': {'width': 57, 'height': 70, 'x': 0, 'y': 0}, 'label': '', 'tags': null}
        }, {
            'id': 'Music_Single_Track_Player_2',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.viewer.skins.audioplayer.SimplePlayer',
                'layout': {
                    'width': 40,
                    'height': 40,
                    'x': 64,
                    'y': 15,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.viewer.components.AudioPlayer',
                'data': {
                    'type': 'AudioPlayer',
                    'metaData': {'isPreset': true, 'schemaVersion': '1.0', 'isHidden': false},
                    'uri': '',
                    'autoPlay': false,
                    'loop': false,
                    'visible': true,
                    'volume': 100,
                    'showTooltip': false
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {
                            'alpha-bg': '1',
                            'alpha-bgh': '1',
                            'alpha-brd': '1',
                            'alpha-brdh': '1',
                            'alpha-icon': '1',
                            'alpha-iconh': '1',
                            'bg': '#FFFFFF',
                            'bgh': '#342216',
                            'boxShadowToggleOn-shd': 'false',
                            'brd': '#342216',
                            'brdh': '#FFFFFF',
                            'brw': '1px',
                            'icon': '#342216',
                            'iconh': '#FFFFFF',
                            'rd': '100px',
                            'shd': '0px 1px 4px 0px rgba(0,0,0,0.6)'
                        },
                        'propertiesSource': {
                            'bg': 'value',
                            'bgh': 'value',
                            'brd': 'value',
                            'brdh': 'value',
                            'brw': 'value',
                            'icon': 'value',
                            'iconh': 'value',
                            'rd': 'value',
                            'shd': 'value'
                        }
                    },
                    'componentClassName': 'wysiwyg.viewer.components.AudioPlayer',
                    'pageId': '',
                    'compId': 'i6g763ve',
                    'styleType': 'custom',
                    'skin': 'wysiwyg.viewer.skins.audioplayer.SimplePlayer'
                },
                'id': 'i6g763ve'
            },
            'preset': {'rect': {'width': 54, 'height': 70, 'x': 57, 'y': 0}, 'label': '', 'tags': null}
        }, {
            'id': 'Music_Single_Track_Player_3',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.viewer.skins.audioplayer.SimplePlayer',
                'layout': {
                    'width': 88,
                    'height': 40,
                    'x': 118,
                    'y': 15,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.viewer.components.AudioPlayer',
                'data': {
                    'type': 'AudioPlayer',
                    'metaData': {'isPreset': true, 'schemaVersion': '1.0', 'isHidden': false},
                    'uri': '',
                    'autoPlay': false,
                    'loop': false,
                    'visible': true,
                    'volume': 100,
                    'showTooltip': false
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {
                            'alpha-bg': '1',
                            'alpha-bgh': '1',
                            'alpha-brd': '1',
                            'alpha-brdh': '1',
                            'alpha-icon': '1',
                            'alpha-iconh': '1',
                            'bg': '#FFFFFF',
                            'bgh': '#342216',
                            'boxShadowToggleOn-shd': 'false',
                            'brd': '#342216',
                            'brdh': '#FFFFFF',
                            'brw': '1px',
                            'icon': '#342216',
                            'iconh': '#FFFFFF',
                            'rd': '0',
                            'shd': '0px 1px 4px 0px rgba(0,0,0,0.6)'
                        },
                        'propertiesSource': {
                            'bg': 'value',
                            'bgh': 'value',
                            'brd': 'value',
                            'brdh': 'value',
                            'brw': 'value',
                            'icon': 'value',
                            'iconh': 'value',
                            'rd': 'value',
                            'shd': 'value'
                        }
                    },
                    'componentClassName': 'wysiwyg.viewer.components.AudioPlayer',
                    'pageId': '',
                    'compId': 'i6g763vd_0',
                    'styleType': 'custom',
                    'skin': 'wysiwyg.viewer.skins.audioplayer.SimplePlayer'
                },
                'id': 'i6g763vd_0'
            },
            'preset': {'rect': {'width': 102, 'height': 70, 'x': 111, 'y': 0}, 'label': '', 'tags': null}
        }, {
            'id': 'Music_Single_Track_Player_4',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.viewer.skins.audioplayer.SimplePlayer',
                'layout': {
                    'width': 40,
                    'height': 40,
                    'x': 220,
                    'y': 15,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.viewer.components.AudioPlayer',
                'data': {
                    'type': 'AudioPlayer',
                    'metaData': {'isPreset': true, 'schemaVersion': '1.0', 'isHidden': false},
                    'uri': '',
                    'autoPlay': false,
                    'loop': false,
                    'visible': true,
                    'volume': 100,
                    'showTooltip': false
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {
                            'alpha-bg': '1',
                            'alpha-bgh': '1',
                            'alpha-brd': '1',
                            'alpha-brdh': '1',
                            'alpha-icon': '1',
                            'alpha-iconh': '1',
                            'bg': '#FFFFFF',
                            'bgh': '#342216',
                            'boxShadowToggleOn-shd': 'false',
                            'brd': '#342216',
                            'brdh': '#FFFFFF',
                            'brw': '1px',
                            'icon': '#342216',
                            'iconh': '#FFFFFF',
                            'rd': '10px',
                            'shd': '0px 1px 4px 0px rgba(0,0,0,0.6)'
                        },
                        'propertiesSource': {
                            'bg': 'value',
                            'bgh': 'value',
                            'brd': 'value',
                            'brdh': 'value',
                            'brw': 'value',
                            'icon': 'value',
                            'iconh': 'value',
                            'rd': 'value',
                            'shd': 'value'
                        }
                    },
                    'componentClassName': 'wysiwyg.viewer.components.AudioPlayer',
                    'pageId': '',
                    'compId': 'i6g763vd',
                    'styleType': 'custom',
                    'skin': 'wysiwyg.viewer.skins.audioplayer.SimplePlayer'
                },
                'id': 'i6g763vd'
            },
            'preset': {'rect': {'width': 54, 'height': 70, 'x': 213, 'y': 0}, 'label': '', 'tags': null}
        }, {
            'id': 'Music_Single_Track_Player_5',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.viewer.skins.audioplayer.SimplePlayer',
                'layout': {
                    'width': 40,
                    'height': 40,
                    'x': 274,
                    'y': 15,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.viewer.components.AudioPlayer',
                'data': {
                    'type': 'AudioPlayer',
                    'metaData': {'isPreset': true, 'schemaVersion': '1.0', 'isHidden': false},
                    'uri': '',
                    'autoPlay': false,
                    'loop': false,
                    'visible': true,
                    'volume': 100,
                    'showTooltip': false
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {
                            'alpha-bg': '1',
                            'alpha-bgh': '1',
                            'alpha-brd': '1',
                            'alpha-brdh': '1',
                            'alpha-icon': '1',
                            'alpha-iconh': '1',
                            'bg': '#FFFFFF',
                            'bgh': '#342216',
                            'boxShadowToggleOn-shd': 'false',
                            'brd': '#342216',
                            'brdh': '#FFFFFF',
                            'brw': '1px',
                            'icon': '#342216',
                            'iconh': '#FFFFFF',
                            'rd': '6px',
                            'shd': '0px 1px 4px 0px rgba(0,0,0,0.6)'
                        },
                        'propertiesSource': {
                            'bg': 'value',
                            'bgh': 'value',
                            'brd': 'value',
                            'brdh': 'value',
                            'brw': 'value',
                            'icon': 'value',
                            'iconh': 'value',
                            'rd': 'value',
                            'shd': 'value'
                        }
                    },
                    'componentClassName': 'wysiwyg.viewer.components.AudioPlayer',
                    'pageId': '',
                    'compId': 'i6g763vc',
                    'styleType': 'custom',
                    'skin': 'wysiwyg.viewer.skins.audioplayer.SimplePlayer'
                },
                'id': 'i6g763vc'
            },
            'preset': {'rect': {'width': 57, 'height': 70, 'x': 267, 'y': 0}, 'label': '', 'tags': null}
        }, {
            'id': 'Music_Single_Track_Player_6',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.viewer.skins.audioplayer.SimplePlayer',
                'layout': {
                    'width': 88,
                    'height': 40,
                    'x': 9,
                    'y': 85,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.viewer.components.AudioPlayer',
                'data': {
                    'type': 'AudioPlayer',
                    'metaData': {'isPreset': true, 'schemaVersion': '1.0', 'isHidden': false},
                    'uri': '',
                    'autoPlay': false,
                    'loop': false,
                    'visible': true,
                    'volume': 100,
                    'showTooltip': false
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {
                            'alpha-bg': '1',
                            'alpha-bgh': '0.8',
                            'alpha-brd': '1',
                            'alpha-brdh': '1',
                            'alpha-icon': '1',
                            'alpha-iconh': '1',
                            'bg': '#232323',
                            'bgh': '#232323',
                            'boxShadowToggleOn-shd': 'false',
                            'brd': '#FFFFFF',
                            'brdh': '#232323',
                            'brw': '0px',
                            'icon': '#FFFFFF',
                            'iconh': '#FFFFFF',
                            'rd': '100px',
                            'shd': '0px 1px 4px 0px rgba(0,0,0,0.6)'
                        },
                        'propertiesSource': {
                            'bg': 'value',
                            'bgh': 'value',
                            'brd': 'value',
                            'brdh': 'value',
                            'brw': 'value',
                            'icon': 'value',
                            'iconh': 'value',
                            'rd': 'value',
                            'shd': 'value'
                        }
                    },
                    'componentClassName': 'wysiwyg.viewer.components.AudioPlayer',
                    'pageId': '',
                    'compId': 'i6g763vg',
                    'styleType': 'custom',
                    'skin': 'wysiwyg.viewer.skins.audioplayer.SimplePlayer'
                },
                'id': 'i6g763vg'
            },
            'preset': {'rect': {'width': 111, 'height': 70, 'x': 0, 'y': 70}, 'label': '', 'tags': null}
        }, {
            'id': 'Music_Single_Track_Player_7',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.viewer.skins.audioplayer.SimplePlayer',
                'layout': {
                    'width': 40,
                    'height': 40,
                    'x': 113,
                    'y': 85,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.viewer.components.AudioPlayer',
                'data': {
                    'type': 'AudioPlayer',
                    'metaData': {'isPreset': true, 'schemaVersion': '1.0', 'isHidden': false},
                    'uri': '',
                    'autoPlay': false,
                    'loop': false,
                    'visible': true,
                    'volume': 100,
                    'showTooltip': false
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {
                            'alpha-bg': '1',
                            'alpha-bgh': '0.8',
                            'alpha-brd': '1',
                            'alpha-brdh': '1',
                            'alpha-icon': '1',
                            'alpha-iconh': '1',
                            'bg': '#232323',
                            'bgh': '#232323',
                            'boxShadowToggleOn-shd': 'false',
                            'brd': '#FFFFFF',
                            'brdh': '#232323',
                            'brw': '0px',
                            'icon': '#FFFFFF',
                            'iconh': '#FFFFFF',
                            'rd': '0',
                            'shd': '0px 1px 4px 0px rgba(0,0,0,0.6)'
                        },
                        'propertiesSource': {
                            'bg': 'value',
                            'bgh': 'value',
                            'brd': 'value',
                            'brdh': 'value',
                            'brw': 'value',
                            'icon': 'value',
                            'iconh': 'value',
                            'rd': 'value',
                            'shd': 'value'
                        }
                    },
                    'componentClassName': 'wysiwyg.viewer.components.AudioPlayer',
                    'pageId': '',
                    'compId': 'i6g763vf_0',
                    'styleType': 'custom',
                    'skin': 'wysiwyg.viewer.skins.audioplayer.SimplePlayer'
                },
                'id': 'i6g763vf_0'
            },
            'preset': {'rect': {'width': 54, 'height': 70, 'x': 111, 'y': 70}, 'label': '', 'tags': null}
        }, {
            'id': 'Music_Single_Track_Player_8',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.viewer.skins.audioplayer.SimplePlayer',
                'layout': {
                    'width': 88,
                    'height': 40,
                    'x': 170,
                    'y': 85,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.viewer.components.AudioPlayer',
                'data': {
                    'type': 'AudioPlayer',
                    'metaData': {'isPreset': true, 'schemaVersion': '1.0', 'isHidden': false},
                    'uri': '',
                    'autoPlay': false,
                    'loop': false,
                    'visible': true,
                    'volume': 100,
                    'showTooltip': false
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {
                            'alpha-bg': '1',
                            'alpha-bgh': '0.8',
                            'alpha-brd': '1',
                            'alpha-brdh': '1',
                            'alpha-icon': '1',
                            'alpha-iconh': '1',
                            'bg': '#232323',
                            'bgh': '#232323',
                            'boxShadowToggleOn-shd': 'false',
                            'brd': '#FFFFFF',
                            'brdh': '#232323',
                            'brw': '0px',
                            'icon': '#FFFFFF',
                            'iconh': '#FFFFFF',
                            'rd': '6px',
                            'shd': '0px 1px 4px 0px rgba(0,0,0,0.6)'
                        },
                        'propertiesSource': {
                            'bg': 'value',
                            'bgh': 'value',
                            'brd': 'value',
                            'brdh': 'value',
                            'brw': 'value',
                            'icon': 'value',
                            'iconh': 'value',
                            'rd': 'value',
                            'shd': 'value'
                        }
                    },
                    'componentClassName': 'wysiwyg.viewer.components.AudioPlayer',
                    'pageId': '',
                    'compId': 'i6g763vf',
                    'styleType': 'custom',
                    'skin': 'wysiwyg.viewer.skins.audioplayer.SimplePlayer'
                },
                'id': 'i6g763vf'
            },
            'preset': {'rect': {'width': 102, 'height': 70, 'x': 165, 'y': 70}, 'label': '', 'tags': null}
        }, {
            'id': 'Music_Single_Track_Player_9',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.viewer.skins.audioplayer.SimplePlayer',
                'layout': {
                    'width': 40,
                    'height': 40,
                    'x': 274,
                    'y': 85,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.viewer.components.AudioPlayer',
                'data': {
                    'type': 'AudioPlayer',
                    'metaData': {'isPreset': true, 'schemaVersion': '1.0', 'isHidden': false},
                    'uri': '',
                    'autoPlay': false,
                    'loop': false,
                    'visible': true,
                    'volume': 100,
                    'showTooltip': false
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {
                            'alpha-bg': '1',
                            'alpha-bgh': '0.8',
                            'alpha-brd': '1',
                            'alpha-brdh': '1',
                            'alpha-icon': '1',
                            'alpha-iconh': '1',
                            'bg': '#232323',
                            'bgh': '#232323',
                            'boxShadowToggleOn-shd': 'false',
                            'brd': '#FFFFFF',
                            'brdh': '#232323',
                            'brw': '0px',
                            'icon': '#FFFFFF',
                            'iconh': '#FFFFFF',
                            'rd': '10px',
                            'shd': '0px 1px 4px 0px rgba(0,0,0,0.6)'
                        },
                        'propertiesSource': {
                            'bg': 'value',
                            'bgh': 'value',
                            'brd': 'value',
                            'brdh': 'value',
                            'brw': 'value',
                            'icon': 'value',
                            'iconh': 'value',
                            'rd': 'value',
                            'shd': 'value'
                        }
                    },
                    'componentClassName': 'wysiwyg.viewer.components.AudioPlayer',
                    'pageId': '',
                    'compId': 'i6g763ve_1',
                    'styleType': 'custom',
                    'skin': 'wysiwyg.viewer.skins.audioplayer.SimplePlayer'
                },
                'id': 'i6g763ve_1'
            },
            'preset': {'rect': {'width': 57, 'height': 70, 'x': 267, 'y': 70}, 'label': '', 'tags': null}
        }, {
            'id': 'Music_Single_Track_Player_10',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.viewer.skins.audioplayer.ShinyPlayer',
                'layout': {
                    'width': 40,
                    'height': 40,
                    'x': 10,
                    'y': 155,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.viewer.components.AudioPlayer',
                'data': {
                    'type': 'AudioPlayer',
                    'metaData': {'isPreset': true, 'schemaVersion': '1.0', 'isHidden': false},
                    'uri': '',
                    'autoPlay': false,
                    'loop': false,
                    'visible': true,
                    'volume': 100,
                    'showTooltip': false
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {
                            'alpha-bg': '1',
                            'alpha-bgh': '0.8',
                            'alpha-brd': '1',
                            'alpha-brdh': '1',
                            'alpha-icon': '1',
                            'alpha-iconh': '1',
                            'bg': '#2B6CA3',
                            'bgh': '#2B6CA3',
                            'boxShadowToggleOn-shd': 'false',
                            'brd': '#2B6CA3',
                            'brdh': '#2B6CA3',
                            'brw': '0px',
                            'icon': '#FFFFFF',
                            'iconh': '#FFFFFF',
                            'rd': '0',
                            'shd': '0px 1px 4px 0px rgba(0,0,0,0.6)'
                        },
                        'propertiesSource': {
                            'bg': 'value',
                            'bgh': 'value',
                            'brd': 'value',
                            'brdh': 'value',
                            'brw': 'value',
                            'icon': 'value',
                            'iconh': 'value',
                            'rd': 'value',
                            'shd': 'value'
                        }
                    },
                    'componentClassName': 'wysiwyg.viewer.components.AudioPlayer',
                    'pageId': '',
                    'compId': 'i6g763vi',
                    'styleType': 'custom',
                    'skin': 'wysiwyg.viewer.skins.audioplayer.ShinyPlayer'
                },
                'id': 'i6g763vi'
            },
            'preset': {'rect': {'width': 57, 'height': 70, 'x': 0, 'y': 140}, 'label': '', 'tags': null}
        }, {
            'id': 'Music_Single_Track_Player_11',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.viewer.skins.audioplayer.ShinyPlayer',
                'layout': {
                    'width': 40,
                    'height': 40,
                    'x': 65,
                    'y': 155,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.viewer.components.AudioPlayer',
                'data': {
                    'type': 'AudioPlayer',
                    'metaData': {'isPreset': true, 'schemaVersion': '1.0', 'isHidden': false},
                    'uri': '',
                    'autoPlay': false,
                    'loop': false,
                    'visible': true,
                    'volume': 100,
                    'showTooltip': false
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {
                            'alpha-bg': '1',
                            'alpha-bgh': '0.8',
                            'alpha-brd': '1',
                            'alpha-brdh': '1',
                            'alpha-icon': '1',
                            'alpha-iconh': '1',
                            'bg': '#2B6CA3',
                            'bgh': '#2B6CA3',
                            'boxShadowToggleOn-shd': 'true',
                            'brd': '#FFFFFF',
                            'brdh': '#2B6CA3',
                            'brw': '0px',
                            'icon': '#FFFFFF',
                            'iconh': '#FFFFFF',
                            'rd': '100px',
                            'shd': '0px 1px 4px 0px rgba(0,0,0,0.6)'
                        },
                        'propertiesSource': {
                            'bg': 'value',
                            'bgh': 'value',
                            'brd': 'value',
                            'brdh': 'value',
                            'brw': 'value',
                            'icon': 'value',
                            'iconh': 'value',
                            'rd': 'value',
                            'shd': 'value'
                        }
                    },
                    'componentClassName': 'wysiwyg.viewer.components.AudioPlayer',
                    'pageId': '',
                    'compId': 'i6g763vh_0',
                    'styleType': 'custom',
                    'skin': 'wysiwyg.viewer.skins.audioplayer.ShinyPlayer'
                },
                'id': 'i6g763vh_0'
            },
            'preset': {'rect': {'width': 54, 'height': 70, 'x': 57, 'y': 140}, 'label': '', 'tags': null}
        }, {
            'id': 'Music_Single_Track_Player_12',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.viewer.skins.audioplayer.ShinyPlayer',
                'layout': {
                    'width': 88,
                    'height': 40,
                    'x': 122,
                    'y': 155,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.viewer.components.AudioPlayer',
                'data': {
                    'type': 'AudioPlayer',
                    'metaData': {'isPreset': true, 'schemaVersion': '1.0', 'isHidden': false},
                    'uri': '',
                    'autoPlay': false,
                    'loop': false,
                    'visible': true,
                    'volume': 100,
                    'showTooltip': false
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {
                            'alpha-bg': '1',
                            'alpha-bgh': '0.8',
                            'alpha-brd': '1',
                            'alpha-brdh': '1',
                            'alpha-icon': '1',
                            'alpha-iconh': '1',
                            'bg': '#2B6CA3',
                            'bgh': '#2B6CA3',
                            'boxShadowToggleOn-shd': 'false',
                            'brd': '#FFFFFF',
                            'brdh': '#2B6CA3',
                            'brw': '0px',
                            'icon': '#FFFFFF',
                            'iconh': '#FFFFFF',
                            'rd': '10px',
                            'shd': '0px 1px 4px 0px rgba(0,0,0,0.6)'
                        },
                        'propertiesSource': {
                            'bg': 'value',
                            'bgh': 'value',
                            'brd': 'value',
                            'brdh': 'value',
                            'brw': 'value',
                            'icon': 'value',
                            'iconh': 'value',
                            'rd': 'value',
                            'shd': 'value'
                        }
                    },
                    'componentClassName': 'wysiwyg.viewer.components.AudioPlayer',
                    'pageId': '',
                    'compId': 'i6g763vh',
                    'styleType': 'custom',
                    'skin': 'wysiwyg.viewer.skins.audioplayer.ShinyPlayer'
                },
                'id': 'i6g763vh'
            },
            'preset': {'rect': {'width': 102, 'height': 70, 'x': 111, 'y': 140}, 'label': '', 'tags': null}
        }, {
            'id': 'Music_Single_Track_Player_13',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.viewer.skins.audioplayer.ShinyPlayer',
                'layout': {
                    'width': 88,
                    'height': 40,
                    'x': 226,
                    'y': 155,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.viewer.components.AudioPlayer',
                'data': {
                    'type': 'AudioPlayer',
                    'metaData': {'isPreset': true, 'schemaVersion': '1.0', 'isHidden': false},
                    'uri': '',
                    'autoPlay': false,
                    'loop': false,
                    'visible': true,
                    'volume': 100,
                    'showTooltip': false
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {
                            'alpha-bg': '1',
                            'alpha-bgh': '0.8',
                            'alpha-brd': '1',
                            'alpha-brdh': '1',
                            'alpha-icon': '1',
                            'alpha-iconh': '1',
                            'bg': '#2B6CA3',
                            'bgh': '#2B6CA3',
                            'boxShadowToggleOn-shd': 'true',
                            'brd': '#FFFFFF',
                            'brdh': '#2B6CA3',
                            'brw': '0px',
                            'icon': '#FFFFFF',
                            'iconh': '#FFFFFF',
                            'rd': '100px',
                            'shd': '0 1px 4px rgba(0, 0, 0, 0.6);'
                        },
                        'propertiesSource': {
                            'bg': 'value',
                            'bgh': 'value',
                            'brd': 'value',
                            'brdh': 'value',
                            'brw': 'value',
                            'icon': 'value',
                            'iconh': 'value',
                            'rd': 'value',
                            'shd': 'value'
                        }
                    },
                    'componentClassName': 'wysiwyg.viewer.components.AudioPlayer',
                    'pageId': '',
                    'compId': 'i6g763vg_0',
                    'styleType': 'custom',
                    'skin': 'wysiwyg.viewer.skins.audioplayer.ShinyPlayer'
                },
                'id': 'i6g763vg_0'
            },
            'preset': {'rect': {'width': 111, 'height': 70, 'x': 213, 'y': 140}, 'label': '', 'tags': null}
        }, {
            'id': 'Music_Single_Track_Player_14',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.viewer.skins.audioplayer.Audio3DPlayer',
                'layout': {
                    'width': 40,
                    'height': 40,
                    'x': 11,
                    'y': 225,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.viewer.components.AudioPlayer',
                'data': {
                    'type': 'AudioPlayer',
                    'metaData': {'isPreset': true, 'schemaVersion': '1.0', 'isHidden': false},
                    'uri': '',
                    'autoPlay': false,
                    'loop': false,
                    'visible': true,
                    'volume': 100,
                    'showTooltip': false
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {
                            'alpha-bg': '1',
                            'alpha-bgh': '1',
                            'alpha-icon': '1',
                            'alpha-iconh': '1',
                            'bg': '#002E5D',
                            'bgh': '#2B6CA3',
                            'boxShadowToggleOn-shd': 'true',
                            'icon': '#FFFFFF',
                            'iconh': '#FFFFFF',
                            'rd': '3px',
                            'shd': '0 1px 4px rgba(0, 0, 0, 0.6);'
                        },
                        'propertiesSource': {
                            'bg': 'value',
                            'bgh': 'value',
                            'icon': 'value',
                            'iconh': 'value',
                            'rd': 'value',
                            'shd': 'value'
                        }
                    },
                    'componentClassName': 'wysiwyg.viewer.components.AudioPlayer',
                    'pageId': '',
                    'compId': 'i6g763vk_0',
                    'styleType': 'custom',
                    'skin': 'wysiwyg.viewer.skins.audioplayer.Audio3DPlayer'
                },
                'id': 'i6g763vk_0'
            },
            'preset': {'rect': {'width': 57, 'height': 70, 'x': 0, 'y': 210}, 'label': '', 'tags': null}
        }, {
            'id': 'Music_Single_Track_Player_15',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.viewer.skins.audioplayer.Audio3DPlayer',
                'layout': {
                    'width': 88,
                    'height': 40,
                    'x': 66,
                    'y': 225,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.viewer.components.AudioPlayer',
                'data': {
                    'type': 'AudioPlayer',
                    'metaData': {'isPreset': true, 'schemaVersion': '1.0', 'isHidden': false},
                    'uri': '',
                    'autoPlay': false,
                    'loop': false,
                    'visible': true,
                    'volume': 100,
                    'showTooltip': false
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {
                            'alpha-bg': '1',
                            'alpha-bgh': '1',
                            'alpha-icon': '1',
                            'alpha-iconh': '1',
                            'bg': '#002E5D',
                            'bgh': '#2B6CA3',
                            'boxShadowToggleOn-shd': 'false',
                            'icon': '#FFFFFF',
                            'iconh': '#FFFFFF',
                            'rd': '0px',
                            'shd': '0px 1px 4px 0px rgba(0,0,0,0.6)'
                        },
                        'propertiesSource': {
                            'bg': 'value',
                            'bgh': 'value',
                            'icon': 'value',
                            'iconh': 'value',
                            'rd': 'value',
                            'shd': 'value'
                        }
                    },
                    'componentClassName': 'wysiwyg.viewer.components.AudioPlayer',
                    'pageId': '',
                    'compId': 'i6g763vk',
                    'styleType': 'custom',
                    'skin': 'wysiwyg.viewer.skins.audioplayer.Audio3DPlayer'
                },
                'id': 'i6g763vk'
            },
            'preset': {'rect': {'width': 102, 'height': 70, 'x': 57, 'y': 210}, 'label': '', 'tags': null}
        }, {
            'id': 'Music_Single_Track_Player_16',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.viewer.skins.audioplayer.Audio3DPlayer',
                'layout': {
                    'width': 40,
                    'height': 40,
                    'x': 166,
                    'y': 225,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.viewer.components.AudioPlayer',
                'data': {
                    'type': 'AudioPlayer',
                    'metaData': {'isPreset': true, 'schemaVersion': '1.0', 'isHidden': false},
                    'uri': '',
                    'autoPlay': false,
                    'loop': false,
                    'visible': true,
                    'volume': 100,
                    'showTooltip': false
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {
                            'alpha-bg': '1',
                            'alpha-bgh': '1',
                            'alpha-icon': '1',
                            'alpha-iconh': '1',
                            'bg': '#002E5D',
                            'bgh': '#2B6CA3',
                            'boxShadowToggleOn-shd': 'true',
                            'icon': '#FFFFFF',
                            'iconh': '#FFFFFF',
                            'rd': '100px',
                            'shd': '0 1px 4px rgba(0, 0, 0, 0.6);'
                        },
                        'propertiesSource': {
                            'bg': 'value',
                            'bgh': 'value',
                            'icon': 'value',
                            'iconh': 'value',
                            'rd': 'value',
                            'shd': 'value'
                        }
                    },
                    'componentClassName': 'wysiwyg.viewer.components.AudioPlayer',
                    'pageId': '',
                    'compId': 'i6g763vj_0',
                    'styleType': 'custom',
                    'skin': 'wysiwyg.viewer.skins.audioplayer.Audio3DPlayer'
                },
                'id': 'i6g763vj_0'
            },
            'preset': {'rect': {'width': 54, 'height': 70, 'x': 159, 'y': 210}, 'label': '', 'tags': null}
        }, {
            'id': 'Music_Single_Track_Player_17',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.viewer.skins.audioplayer.Audio3DPlayer',
                'layout': {
                    'width': 40,
                    'height': 40,
                    'x': 220,
                    'y': 225,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.viewer.components.AudioPlayer',
                'data': {
                    'type': 'AudioPlayer',
                    'metaData': {'isPreset': true, 'schemaVersion': '1.0', 'isHidden': false},
                    'uri': '',
                    'autoPlay': false,
                    'loop': false,
                    'visible': true,
                    'volume': 100,
                    'showTooltip': false
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {
                            'alpha-bg': '1',
                            'alpha-bgh': '1',
                            'alpha-icon': '1',
                            'alpha-iconh': '1',
                            'bg': '#002E5D',
                            'bgh': '#2B6CA3',
                            'boxShadowToggleOn-shd': 'false',
                            'icon': '#FFFFFF',
                            'iconh': '#FFFFFF',
                            'rd': '0px',
                            'shd': '0px 1px 4px 0px rgba(0,0,0,0.6)'
                        },
                        'propertiesSource': {
                            'bg': 'value',
                            'bgh': 'value',
                            'icon': 'value',
                            'iconh': 'value',
                            'rd': 'value',
                            'shd': 'value'
                        }
                    },
                    'componentClassName': 'wysiwyg.viewer.components.AudioPlayer',
                    'pageId': '',
                    'compId': 'i6g763vj',
                    'styleType': 'custom',
                    'skin': 'wysiwyg.viewer.skins.audioplayer.Audio3DPlayer'
                },
                'id': 'i6g763vj'
            },
            'preset': {'rect': {'width': 54, 'height': 70, 'x': 213, 'y': 210}, 'label': '', 'tags': null}
        }, {
            'id': 'Music_Single_Track_Player_18',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.viewer.skins.audioplayer.Audio3DPlayer',
                'layout': {
                    'width': 40,
                    'height': 40,
                    'x': 275,
                    'y': 225,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.viewer.components.AudioPlayer',
                'data': {
                    'type': 'AudioPlayer',
                    'metaData': {'isPreset': true, 'schemaVersion': '1.0', 'isHidden': false},
                    'uri': '',
                    'autoPlay': false,
                    'loop': false,
                    'visible': true,
                    'volume': 100,
                    'showTooltip': false
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {
                            'alpha-bg': '1',
                            'alpha-bgh': '1',
                            'alpha-icon': '1',
                            'alpha-iconh': '1',
                            'bg': '#002E5D',
                            'bgh': '#2B6CA3',
                            'boxShadowToggleOn-shd': 'true',
                            'icon': '#FFFFFF',
                            'iconh': '#FFFFFF',
                            'rd': '6px',
                            'shd': '0 1px 4px rgba(0, 0, 0, 0.6);'
                        },
                        'propertiesSource': {
                            'bg': 'value',
                            'bgh': 'value',
                            'icon': 'value',
                            'iconh': 'value',
                            'rd': 'value',
                            'shd': 'value'
                        }
                    },
                    'componentClassName': 'wysiwyg.viewer.components.AudioPlayer',
                    'pageId': '',
                    'compId': 'i6g763vi_0',
                    'styleType': 'custom',
                    'skin': 'wysiwyg.viewer.skins.audioplayer.Audio3DPlayer'
                },
                'id': 'i6g763vi_0'
            },
            'preset': {'rect': {'width': 57, 'height': 70, 'x': 267, 'y': 210}, 'label': '', 'tags': null}
        }],
        'compTypes': ['wysiwyg.viewer.components.AudioPlayer']
    },
    'help': {'hide': false, 'text': 'add_section_info_text_singletrackplayer'}
}

module.exports = {} || singleTrackPlayerSection
