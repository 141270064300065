import { hoc } from '@/util';
import { MULTISELECT_PANEL_NAME } from './multiselect.constants';

import type { ThunkAction, Dispatch } from 'types/redux';
import type { EditorAPI } from '@/editorAPI';
import type { IMultiselectPanelOwnProps } from './multiselect.types';

const getEditorAPI: ThunkAction<EditorAPI> = (
  dispatch,
  getState,
  { editorAPI },
) => editorAPI;

const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: IMultiselectPanelOwnProps,
) => {
  const { onAddPage } = ownProps;

  const editorAPI = dispatch(getEditorAPI);

  const closePanel = () =>
    editorAPI.panelManager.closePanelByName(MULTISELECT_PANEL_NAME);

  const addPage = () => {
    closePanel();
    if (typeof onAddPage === 'function') {
      onAddPage();
    }
  };

  return {
    closePanel,
    addPage,
  };
};

export const multiselectConnect = hoc.connect(
  hoc.STORES.EDITOR_API,
  null,
  mapDispatchToProps,
);
