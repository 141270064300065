import { url as urlUtils, windowInteractionUtils } from '@/util';

import type { ADIMigrationTrigger } from '../constants';

export const isSiteFromADIMigration = (): boolean =>
  urlUtils.parseUrl(window.location.href).query.sectionsMigration === 'true';

export const getADIMigrationTrigger = (): ADIMigrationTrigger =>
  urlUtils.parseUrl(window.location.href).query
    .migrationTrigger as ADIMigrationTrigger;

export const isForceSectionsMigration = (): boolean =>
  urlUtils.parseUrl(window.location.href).query.forceSectionsMigration ===
  'true';

export const isAfterFailedMigration = (): boolean =>
  urlUtils.parseUrl(window.location.href).query.failedMigration === 'true';

export const removeForceSectionsMigrationQuery = () => {
  const updatedUrl = urlUtils.removeUrlParam(
    window.location.href,
    'forceSectionsMigration',
  );

  const historyState = window.history?.state ?? {};

  // @ts-expect-error
  windowInteractionUtils.replaceState(historyState, '', updatedUrl);
};
