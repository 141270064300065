'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')

module.exports = {
    "type": addPanelDataConsts.SECTIONS_TYPES.PRESET,
    "hide": false,
    "title": "add_section_label_popupbutton",
    "showSectionHeader": true,
    "additionalBehaviours": {
        "labelMode": addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
        "infoIcon": addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
        "hoverImageAction": addPanelDataConsts.HOVER_IMAGE_ACTIONS.IMAGE,
        "iconEnabledForComps": {}
    },
    "props": {
        "image": "addPanelData/sections/popUpsButtonsSection_en/popUpsButtonsSection.png",
        "imageHover": "addPanelData/sections/popUpsButtonsSection_en/hover_en/popUpsButtonsSection_hover.png",
        "items": [{
            "id": "PopUps_PopUps_Buttons_1",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.button.BasicButton",
                "layout": {
                    "width": 142,
                    "height": 40,
                    "x": 10,
                    "y": 15,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.PopupCloseTextButton",
                "data": {
                    "type": "LinkableButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "label": "Take Me There!"
                },
                "props": {
                    "type": "ButtonProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "align": "center",
                    "margin": 0
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-bg": "1",
                            "alpha-bgh": "1",
                            "alpha-brd": "1",
                            "alpha-brdh": "1",
                            "alpha-txt": "1",
                            "alpha-txth": "1",
                            "bg": "#3D9BE9",
                            "bgh": "#2B689C",
                            "boxShadowToggleOn-shd": "false",
                            "brd": "#2B689C",
                            "brdh": "#3D9BE9",
                            "brw": "0px",
                            "fnt": "normal normal normal 14px/1.4em avenir-lt-w01_35-light1475496",
                            "rd": "20px",
                            "shd": "0 1px 4px rgba(0, 0, 0, 0.6);",
                            "txt": "#FFFFFF",
                            "txth": "#FFFFFF"
                        },
                        "propertiesSource": {
                            "bg": "value",
                            "bgh": "value",
                            "brd": "value",
                            "brdh": "value",
                            "brw": "value",
                            "fnt": "value",
                            "rd": "value",
                            "shd": "value",
                            "txt": "value",
                            "txth": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.PopupCloseTextButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.button.BasicButton"
                },
                "id": "i67nnhr5_0"
            },
            "preset": {"rect": {"width": 156, "height": 69, "x": 0, "y": 0}, "tags": "Take Me There!"}
        }, {
            "id": "PopUps_PopUps_Buttons_2",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.button.BasicButton",
                "layout": {
                    "width": 142,
                    "height": 40,
                    "x": 172,
                    "y": 15,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.PopupCloseTextButton",
                "data": {
                    "type": "LinkableButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "label": "MAYBE LATER"
                },
                "props": {
                    "type": "ButtonProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "align": "center",
                    "margin": 0
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-bg": "1",
                            "alpha-bgh": "1",
                            "alpha-brd": "1",
                            "alpha-brdh": "1",
                            "alpha-txt": "1",
                            "alpha-txth": "1",
                            "bg": "#3D9BE9",
                            "bgh": "#2B689C",
                            "boxShadowToggleOn-shd": "false",
                            "brd": "#2B689C",
                            "brdh": "#3D9BE9",
                            "brw": "0px",
                            "fnt": "normal normal normal 17px/1.4em signika",
                            "rd": "0px",
                            "shd": "0 1px 4px rgba(0, 0, 0, 0.6);",
                            "txt": "#FFFFFF",
                            "txth": "#FFFFFF"
                        },
                        "propertiesSource": {
                            "bg": "value",
                            "bgh": "value",
                            "brd": "value",
                            "brdh": "value",
                            "brw": "value",
                            "fnt": "value",
                            "rd": "value",
                            "shd": "value",
                            "txt": "value",
                            "txth": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.PopupCloseTextButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.button.BasicButton"
                },
                "id": "i67nnhr7_0"
            },
            "preset": {"rect": {"width": 168, "height": 69, "x": 156, "y": 0}, "tags": "MAYBE LATER"}
        }, {
            "id": "PopUps_PopUps_Buttons_3",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.button.ButtonArrow",
                "layout": {
                    "width": 142,
                    "height": 40,
                    "x": 10,
                    "y": 85,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.PopupCloseTextButton",
                "data": {
                    "type": "LinkableButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "label": "Get Now"
                },
                "props": {
                    "type": "ButtonProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "align": "center",
                    "margin": 0
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-bg": "1",
                            "alpha-bgh": "1",
                            "alpha-txt": "1",
                            "alpha-txth": "1",
                            "bg": "#2B6CA3",
                            "bgh": "#002E5D",
                            "fnt": "normal normal normal 14px/1.4em lulo-clean-w01-one-bold",
                            "txt": "#FFFFFF",
                            "txth": "#FFFFFF"
                        },
                        "propertiesSource": {
                            "bg": "value",
                            "bgh": "value",
                            "fnt": "value",
                            "txt": "value",
                            "txth": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.PopupCloseTextButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.button.ButtonArrow"
                },
                "id": "i67nnhr6"
            },
            "preset": {"rect": {"width": 156, "height": 72, "x": 0, "y": 69}, "tags": "Get Now"}
        }, {
            "id": "PopUps_PopUps_Buttons_4",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.button.BasicButton",
                "layout": {
                    "width": 40,
                    "height": 40,
                    "x": 172,
                    "y": 85,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.PopupCloseTextButton",
                "data": {
                    "type": "LinkableButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "label": "GO!"
                },
                "props": {
                    "type": "ButtonProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "align": "center",
                    "margin": 0
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-bg": "1",
                            "alpha-bgh": "1",
                            "alpha-brd": "1",
                            "alpha-brdh": "1",
                            "alpha-txt": "1",
                            "alpha-txth": "1",
                            "bg": "#2B6CA3",
                            "bgh": "#002E5D",
                            "boxShadowToggleOn-shd": "false",
                            "brd": "#002E5D",
                            "brdh": "#2B6CA3",
                            "brw": "0px",
                            "fnt": "normal normal normal 17px/1.4em reklamescriptw00-medium",
                            "rd": "5px",
                            "shd": "0 1px 4px rgba(0, 0, 0, 0.6);",
                            "txt": "#FFFFFF",
                            "txth": "#FFFFFF"
                        },
                        "propertiesSource": {
                            "bg": "value",
                            "bgh": "value",
                            "brd": "value",
                            "brdh": "value",
                            "brw": "value",
                            "fnt": "value",
                            "rd": "value",
                            "shd": "value",
                            "txt": "value",
                            "txth": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.PopupCloseTextButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.button.BasicButton"
                },
                "id": "i67nnhrg"
            },
            "preset": {"rect": {"width": 66, "height": 72, "x": 156, "y": 69}, "tags": "GO!"}
        }, {
            "id": "PopUps_PopUps_Buttons_5",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.button.BasicButton",
                "layout": {
                    "width": 88,
                    "height": 40,
                    "x": 226,
                    "y": 85,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.PopupCloseTextButton",
                "data": {
                    "type": "LinkableButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "label": "Thanks!"
                },
                "props": {
                    "type": "ButtonProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "align": "center",
                    "margin": 0
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-bg": "1",
                            "alpha-bgh": "1",
                            "alpha-brd": "1",
                            "alpha-brdh": "1",
                            "alpha-txt": "1",
                            "alpha-txth": "1",
                            "bg": "#2B6CA3",
                            "bgh": "#002E5D",
                            "boxShadowToggleOn-shd": "false",
                            "brd": "#002E5D",
                            "brdh": "#2B6CA3",
                            "brw": "0px",
                            "fnt": "normal normal normal 20px/1.4em nimbus-sans-tw01con",
                            "rd": "5px",
                            "shd": "0 1px 4px rgba(0, 0, 0, 0.6);",
                            "txt": "#FFFFFF",
                            "txth": "#FFFFFF"
                        },
                        "propertiesSource": {
                            "bg": "value",
                            "bgh": "value",
                            "brd": "value",
                            "brdh": "value",
                            "brw": "value",
                            "fnt": "value",
                            "rd": "value",
                            "shd": "value",
                            "txt": "value",
                            "txth": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.PopupCloseTextButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.button.BasicButton"
                },
                "id": "i67nnhr9"
            },
            "preset": {"rect": {"width": 102, "height": 72, "x": 222, "y": 69}, "tags": "Thanks!"}
        }, {
            "id": "PopUps_PopUps_Buttons_6",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.button.BasicButton",
                "layout": {
                    "width": 196,
                    "height": 40,
                    "x": 10,
                    "y": 155,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.PopupCloseTextButton",
                "data": {
                    "type": "LinkableButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "label": "Show Me More"
                },
                "props": {
                    "type": "ButtonProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "align": "center",
                    "margin": 0
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-bg": "1",
                            "alpha-bgh": "1",
                            "alpha-brd": "1",
                            "alpha-brdh": "1",
                            "alpha-txt": "1",
                            "alpha-txth": "1",
                            "bg": "#002E5D",
                            "bgh": "#2B6CA3",
                            "boxShadowToggleOn-shd": "false",
                            "brd": "#2B6CA3",
                            "brdh": "#002E5D",
                            "brw": "0",
                            "fnt": "normal normal normal 17px/1.4em noticia+text",
                            "rd": "3px",
                            "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                            "txt": "#FFFFFF",
                            "txth": "#FFFFFF"
                        },
                        "propertiesSource": {
                            "bg": "value",
                            "bgh": "value",
                            "brd": "value",
                            "brdh": "value",
                            "brw": "value",
                            "fnt": "value",
                            "rd": "value",
                            "shd": "value",
                            "txt": "value",
                            "txth": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.PopupCloseTextButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.button.BasicButton"
                },
                "id": "i67nnhr9_0"
            },
            "preset": {"rect": {"width": 215, "height": 72, "x": 0, "y": 141}, "tags": "Show Me More"}
        }, {
            "id": "PopUps_PopUps_Buttons_7",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.button.BasicButton",
                "layout": {
                    "width": 88,
                    "height": 88,
                    "x": 226,
                    "y": 155,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.PopupCloseTextButton",
                "data": {
                    "type": "LinkableButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "label": "Explore"
                },
                "props": {
                    "type": "ButtonProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "align": "center",
                    "margin": 0
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-bg": "1",
                            "alpha-bgh": "1",
                            "alpha-brd": "1",
                            "alpha-brdh": "1",
                            "alpha-txt": "1",
                            "alpha-txth": "1",
                            "bg": "#002E5D",
                            "bgh": "#2B6CA3",
                            "boxShadowToggleOn-shd": "false",
                            "brd": "#2B6CA3",
                            "brdh": "#002E5D",
                            "brw": "0",
                            "fnt": "normal normal normal 19px/1.4em pacifica-w00-condensed",
                            "rd": "3px",
                            "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                            "txt": "#FFFFFF",
                            "txth": "#FFFFFF"
                        },
                        "propertiesSource": {
                            "bg": "value",
                            "bgh": "value",
                            "brd": "value",
                            "brdh": "value",
                            "brw": "value",
                            "fnt": "value",
                            "rd": "value",
                            "shd": "value",
                            "txt": "value",
                            "txth": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.PopupCloseTextButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.button.BasicButton"
                },
                "id": "i67nnhr8"
            },
            "preset": {"rect": {"width": 109, "height": 119, "x": 215, "y": 141}, "tags": "Explore"}
        }, {
            "id": "PopUps_PopUps_Buttons_8",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.button.BasicButton",
                "layout": {
                    "width": 88,
                    "height": 40,
                    "x": 118,
                    "y": 225,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.PopupCloseTextButton",
                "data": {
                    "type": "LinkableButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "label": "Let’s Go"
                },
                "props": {
                    "type": "ButtonProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "align": "center",
                    "margin": 0
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-bg": "1",
                            "alpha-bgh": "1",
                            "alpha-brd": "1",
                            "alpha-brdh": "1",
                            "alpha-txt": "1",
                            "alpha-txth": "1",
                            "bg": "#F2668F",
                            "bgh": "#ED1566",
                            "boxShadowToggleOn-shd": "false",
                            "brd": "#ED1566",
                            "brdh": "#F2668F",
                            "brw": "0px",
                            "fnt": "normal normal normal 22px/1.4em cookie",
                            "rd": "5px",
                            "shd": "0 1px 4px rgba(0, 0, 0, 0.6);",
                            "txt": "#FFFFFF",
                            "txth": "#FFFFFF"
                        },
                        "propertiesSource": {
                            "bg": "value",
                            "bgh": "value",
                            "brd": "value",
                            "brdh": "value",
                            "brw": "value",
                            "fnt": "value",
                            "rd": "value",
                            "shd": "value",
                            "txt": "value",
                            "txth": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.PopupCloseTextButton",
                    "pageId": "",
                    "compId": "i67nnhr4",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.button.BasicButton"
                },
                "id": "i67nnhr4"
            },
            "preset": {"rect": {"width": 108, "height": 64, "x": 107, "y": 212}, "tags": "Let’s Go"}
        }, {
            "id": "PopUps_PopUps_Buttons_9",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.button.BasicButton",
                "layout": {
                    "width": 88,
                    "height": 40,
                    "x": 10,
                    "y": 225,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.PopupCloseTextButton",
                "data": {
                    "type": "LinkableButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "label": "Get Tips"
                },
                "props": {
                    "type": "ButtonProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "align": "center",
                    "margin": 0
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-bg": "1",
                            "alpha-bgh": "1",
                            "alpha-brd": "1",
                            "alpha-brdh": "1",
                            "alpha-txt": "1",
                            "alpha-txth": "1",
                            "bg": "#F2668F",
                            "bgh": "#ED1566",
                            "boxShadowToggleOn-shd": "false",
                            "brd": "#ED1566",
                            "brdh": "#F2668F",
                            "brw": "0",
                            "fnt": "normal normal normal 20px/1.4em soho-w01-thin-condensed",
                            "rd": "0px",
                            "shd": "2px 2px 0px 0px rgba(0,0,0,0.2)",
                            "txt": "#FFFFFF",
                            "txth": "#FFFFFF"
                        },
                        "propertiesSource": {
                            "bg": "value",
                            "bgh": "value",
                            "brd": "value",
                            "brdh": "value",
                            "brw": "value",
                            "fnt": "value",
                            "rd": "value",
                            "shd": "value",
                            "txt": "value",
                            "txth": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.PopupCloseTextButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.button.BasicButton"
                },
                "id": "i67nnhr5"
            },
            "preset": {"rect": {"width": 107, "height": 64, "x": 0, "y": 213}, "tags": "Get Tips"}
        }, {
            "id": "PopUps_PopUps_Buttons_10",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.button.BasicButton",
                "layout": {
                    "width": 88,
                    "height": 62,
                    "x": 226,
                    "y": 273,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.PopupCloseTextButton",
                "data": {
                    "type": "LinkableButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "label": "Join"
                },
                "props": {
                    "type": "ButtonProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "align": "center",
                    "margin": 0
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-bg": "1",
                            "alpha-bgh": "1",
                            "alpha-brd": "1",
                            "alpha-brdh": "1",
                            "alpha-txt": "1",
                            "alpha-txth": "1",
                            "bg": "#ED1566",
                            "bgh": "#AB0535",
                            "boxShadowToggleOn-shd": "false",
                            "brd": "#AB0535",
                            "brdh": "#ED1566",
                            "brw": "0px",
                            "fnt": "normal normal normal 18px/1.4em americantypwrteritcw01--731025",
                            "rd": "15px",
                            "shd": "0 1px 4px rgba(0, 0, 0, 0.6);",
                            "txt": "#FFFFFF",
                            "txth": "#FFFFFF"
                        },
                        "propertiesSource": {
                            "bg": "value",
                            "bgh": "value",
                            "brd": "value",
                            "brdh": "value",
                            "brw": "value",
                            "fnt": "value",
                            "rd": "value",
                            "shd": "value",
                            "txt": "value",
                            "txth": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.PopupCloseTextButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.button.BasicButton"
                },
                "id": "i67nnhr2"
            },
            "preset": {"rect": {"width": 109, "height": 88, "x": 215, "y": 260}, "tags": "Join"}
        }, {
            "id": "PopUps_PopUps_Buttons_11",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.button.BasicButton",
                "layout": {
                    "width": 196,
                    "height": 40,
                    "x": 10,
                    "y": 295,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.PopupCloseTextButton",
                "data": {
                    "type": "LinkableButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "label": "Get in Touch"
                },
                "props": {
                    "type": "ButtonProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "align": "center",
                    "margin": 0
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-bg": "1",
                            "alpha-bgh": "1",
                            "alpha-brd": "1",
                            "alpha-brdh": "1",
                            "alpha-txt": "1",
                            "alpha-txth": "1",
                            "bg": "#ED1566",
                            "bgh": "#AB0535",
                            "boxShadowToggleOn-shd": "false",
                            "brd": "#AB0535",
                            "brdh": "#ED1566",
                            "brw": "0px",
                            "fnt": "normal normal normal 16px/1.4em raleway",
                            "rd": "20px",
                            "shd": "0 1px 4px rgba(0, 0, 0, 0.6);",
                            "txt": "#FFFFFF",
                            "txth": "#FFFFFF"
                        },
                        "propertiesSource": {
                            "bg": "value",
                            "bgh": "value",
                            "brd": "value",
                            "brdh": "value",
                            "brw": "value",
                            "fnt": "value",
                            "rd": "value",
                            "shd": "value",
                            "txt": "value",
                            "txth": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.PopupCloseTextButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.button.BasicButton"
                },
                "id": "i67nnhsd_1"
            },
            "preset": {"rect": {"width": 215, "height": 71, "x": 0, "y": 277}, "tags": "Get in Touch"}
        }, {
            "id": "PopUps_PopUps_Buttons_12",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.button.BasicButton",
                "layout": {
                    "width": 88,
                    "height": 40,
                    "x": 10,
                    "y": 365,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.PopupCloseTextButton",
                "data": {
                    "type": "LinkableButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "label": "Got It"
                },
                "props": {
                    "type": "ButtonProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "align": "center",
                    "margin": 0
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-bg": "1",
                            "alpha-bgh": "1",
                            "alpha-brd": "1",
                            "alpha-brdh": "1",
                            "alpha-txt": "1",
                            "alpha-txth": "1",
                            "bg": "#D61043",
                            "bgh": "#AB0535",
                            "boxShadowToggleOn-shd": "false",
                            "brd": "#AB0535",
                            "brdh": "#D61043",
                            "brw": "0px",
                            "fnt": "normal normal normal 15px/1.4em museo-w01-700",
                            "rd": "0px",
                            "shd": "0 1px 4px rgba(0, 0, 0, 0.6);",
                            "txt": "#FFFFFF",
                            "txth": "#FFFFFF"
                        },
                        "propertiesSource": {
                            "bg": "value",
                            "bgh": "value",
                            "brd": "value",
                            "brdh": "value",
                            "brw": "value",
                            "fnt": "value",
                            "rd": "value",
                            "shd": "value",
                            "txt": "value",
                            "txth": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.PopupCloseTextButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.button.BasicButton"
                },
                "id": "i67nnhr8_0"
            },
            "preset": {"rect": {"width": 107, "height": 72, "x": 0, "y": 348}, "tags": "Got It"}
        }, {
            "id": "PopUps_PopUps_Buttons_13",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.button.ButtonArrow",
                "layout": {
                    "width": 195,
                    "height": 40,
                    "x": 118,
                    "y": 365,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.PopupCloseTextButton",
                "data": {
                    "type": "LinkableButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "label": "Best Buys this Way"
                },
                "props": {
                    "type": "ButtonProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "align": "center",
                    "margin": 0
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-bg": "1",
                            "alpha-bgh": "1",
                            "alpha-txt": "1",
                            "alpha-txth": "1",
                            "bg": "#E21C21",
                            "bgh": "#B80B35",
                            "fnt": "normal normal normal 17px/1.4em proxima-n-w01-reg",
                            "txt": "#FFFFFF",
                            "txth": "#FFFFFF"
                        },
                        "propertiesSource": {
                            "bg": "value",
                            "bgh": "value",
                            "fnt": "value",
                            "txt": "value",
                            "txth": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.PopupCloseTextButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.button.ButtonArrow"
                },
                "id": "i67nnhr6_0"
            },
            "preset": {"rect": {"width": 217, "height": 72, "x": 107, "y": 348}, "tags": "Best Buys this Way"}
        }, {
            "id": "PopUps_PopUps_Buttons_14",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.button.BasicButton",
                "layout": {
                    "width": 88,
                    "height": 88,
                    "x": 10,
                    "y": 435,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.PopupCloseTextButton",
                "data": {
                    "type": "LinkableButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "label": "Done"
                },
                "props": {
                    "type": "ButtonProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "align": "center",
                    "margin": 0
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-bg": "1",
                            "alpha-bgh": "1",
                            "alpha-brd": "1",
                            "alpha-brdh": "1",
                            "alpha-txt": "1",
                            "alpha-txth": "1",
                            "bg": "#BDBF37",
                            "bgh": "#9C9C31",
                            "boxShadowToggleOn-shd": "false",
                            "brd": "#9C9C31",
                            "brdh": "#BDBF37",
                            "brw": "0px",
                            "fnt": "normal normal normal 28px/1.4em belinda-w00-regular",
                            "rd": "0px",
                            "shd": "0 1px 4px rgba(0, 0, 0, 0.6);",
                            "txt": "#FFFFFF",
                            "txth": "#FFFFFF"
                        },
                        "propertiesSource": {
                            "bg": "value",
                            "bgh": "value",
                            "brd": "value",
                            "brdh": "value",
                            "brw": "value",
                            "fnt": "value",
                            "rd": "value",
                            "shd": "value",
                            "txt": "value",
                            "txth": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.PopupCloseTextButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.button.BasicButton"
                },
                "id": "i67nnhrf"
            },
            "preset": {"rect": {"width": 107, "height": 116, "x": 0, "y": 420}, "tags": "Done"}
        }, {
            "id": "PopUps_PopUps_Buttons_15",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.button.BasicButton",
                "layout": {
                    "width": 88,
                    "height": 40,
                    "x": 118,
                    "y": 435,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.PopupCloseTextButton",
                "data": {
                    "type": "LinkableButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "label": "Gotcha"
                },
                "props": {
                    "type": "ButtonProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "align": "center",
                    "margin": 0
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-bg": "1",
                            "alpha-bgh": "1",
                            "alpha-brd": "1",
                            "alpha-brdh": "1",
                            "alpha-txt": "1",
                            "alpha-txth": "1",
                            "bg": "#BDBF37",
                            "bgh": "#9C9C31",
                            "boxShadowToggleOn-shd": "false",
                            "brd": "#9C9C31",
                            "brdh": "#BDBF37",
                            "brw": "0px",
                            "fnt": "normal normal normal 14px/1.4em avenir-lt-w01_35-light1475496",
                            "rd": "0px",
                            "shd": "0 1px 4px rgba(0, 0, 0, 0.6);",
                            "txt": "#FFFFFF",
                            "txth": "#FFFFFF"
                        },
                        "propertiesSource": {
                            "bg": "value",
                            "bgh": "value",
                            "brd": "value",
                            "brdh": "value",
                            "brw": "value",
                            "fnt": "value",
                            "rd": "value",
                            "shd": "value",
                            "txt": "value",
                            "txth": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.PopupCloseTextButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.button.BasicButton"
                },
                "id": "i67nnhrh_0"
            },
            "preset": {"rect": {"width": 107, "height": 72, "x": 107, "y": 420}, "tags": "Gotcha"}
        }, {
            "id": "PopUps_PopUps_Buttons_16",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.button.BasicButton",
                "layout": {
                    "width": 88,
                    "height": 40,
                    "x": 226,
                    "y": 435,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.PopupCloseTextButton",
                "data": {
                    "type": "LinkableButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "label": "Explore"
                },
                "props": {
                    "type": "ButtonProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "align": "center",
                    "margin": 0
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-bg": "1",
                            "alpha-bgh": "1",
                            "alpha-brd": "1",
                            "alpha-brdh": "1",
                            "alpha-txt": "1",
                            "alpha-txth": "1",
                            "bg": "#BDBF37",
                            "bgh": "#9C9C31",
                            "boxShadowToggleOn-shd": "false",
                            "brd": "#9C9C31",
                            "brdh": "#BDBF37",
                            "brw": "0px",
                            "fnt": "normal normal normal 17px/1.4em overlock",
                            "rd": "36px",
                            "shd": "0 1px 4px rgba(0, 0, 0, 0.6);",
                            "txt": "#FFFFFF",
                            "txth": "#FFFFFF"
                        },
                        "propertiesSource": {
                            "bg": "value",
                            "bgh": "value",
                            "brd": "value",
                            "brdh": "value",
                            "brw": "value",
                            "fnt": "value",
                            "rd": "value",
                            "shd": "value",
                            "txt": "value",
                            "txth": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.PopupCloseTextButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.button.BasicButton"
                },
                "id": "i67nnhrh"
            },
            "preset": {"rect": {"width": 110, "height": 72, "x": 214, "y": 420}, "tags": "Explore"}
        }, {
            "id": "PopUps_PopUps_Buttons_17",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.button.BasicButton",
                "layout": {
                    "width": 196,
                    "height": 40,
                    "x": 118,
                    "y": 505,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.PopupCloseTextButton",
                "data": {
                    "type": "LinkableButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "label": "Get Coupon"
                },
                "props": {
                    "type": "ButtonProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "align": "center",
                    "margin": 0
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-bg": "1",
                            "alpha-bgh": "1",
                            "alpha-brd": "1",
                            "alpha-brdh": "1",
                            "alpha-txt": "1",
                            "alpha-txth": "1",
                            "bg": "#68B04D",
                            "bgh": "#1C8A43",
                            "boxShadowToggleOn-shd": "false",
                            "brd": "#1C8A43",
                            "brdh": "#68B04D",
                            "brw": "0px",
                            "fnt": "normal normal normal 24px/1.4em cookie",
                            "rd": "5px",
                            "shd": "0px 1px 1px 0px rgba(0,0,0,0.3)",
                            "txt": "#FFFFFF",
                            "txth": "#FFFFFF"
                        },
                        "propertiesSource": {
                            "bg": "value",
                            "bgh": "value",
                            "brd": "value",
                            "brdh": "value",
                            "brw": "value",
                            "fnt": "value",
                            "rd": "value",
                            "shd": "value",
                            "txt": "value",
                            "txth": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.PopupCloseTextButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.button.BasicButton"
                },
                "id": "i67nnhrf_0"
            },
            "preset": {"rect": {"width": 217, "height": 67, "x": 107, "y": 492}, "tags": "Get Coupon"}
        }, {
            "id": "PopUps_PopUps_Buttons_18",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.button.BasicButton",
                "layout": {
                    "width": 88,
                    "height": 62,
                    "x": 10,
                    "y": 553,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.PopupCloseTextButton",
                "data": {
                    "type": "LinkableButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "label": "Play"
                },
                "props": {
                    "type": "ButtonProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "align": "center",
                    "margin": 0
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-bg": "1",
                            "alpha-bgh": "1",
                            "alpha-brd": "1",
                            "alpha-brdh": "1",
                            "alpha-txt": "1",
                            "alpha-txth": "1",
                            "bg": "#1C8A43",
                            "bgh": "#14632F",
                            "boxShadowToggleOn-shd": "false",
                            "brd": "#14632F",
                            "brdh": "#1C8A43",
                            "brw": "0px",
                            "fnt": "normal normal normal 22px/1.4em pacifica-w00-condensed",
                            "rd": "5px",
                            "shd": "0 1px 4px rgba(0, 0, 0, 0.6);",
                            "txt": "#FFFFFF",
                            "txth": "#FFFFFF"
                        },
                        "propertiesSource": {
                            "bg": "value",
                            "bgh": "value",
                            "brd": "value",
                            "brdh": "value",
                            "brw": "value",
                            "fnt": "value",
                            "rd": "value",
                            "shd": "value",
                            "txt": "value",
                            "txth": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.PopupCloseTextButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.button.BasicButton"
                },
                "id": "i67nnhrg_0"
            },
            "preset": {"rect": {"width": 107, "height": 95, "x": 0, "y": 536}, "tags": "Play"}
        }, {
            "id": "PopUps_PopUps_Buttons_19",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.button.BasicButton",
                "layout": {
                    "width": 196,
                    "height": 40,
                    "x": 117,
                    "y": 575,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.PopupCloseTextButton",
                "data": {
                    "type": "LinkableButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "label": "Get Your Gift"
                },
                "props": {
                    "type": "ButtonProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "align": "center",
                    "margin": 0
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-bg": "1",
                            "alpha-bgh": "1",
                            "alpha-brd": "1",
                            "alpha-brdh": "1",
                            "alpha-txt": "1",
                            "alpha-txth": "1",
                            "bg": "#1C8A43",
                            "bgh": "#14632F",
                            "boxShadowToggleOn-shd": "false",
                            "brd": "#14632F",
                            "brdh": "#1C8A43",
                            "brw": "0px",
                            "fnt": "normal normal normal 16px/1.4em georgia",
                            "rd": "0px",
                            "shd": "0px 1px 1px 0px rgba(0,0,0,0.3)",
                            "txt": "#FFFFFF",
                            "txth": "#FFFFFF"
                        },
                        "propertiesSource": {
                            "bg": "value",
                            "bgh": "value",
                            "brd": "value",
                            "brdh": "value",
                            "brw": "value",
                            "fnt": "value",
                            "rd": "value",
                            "shd": "value",
                            "txt": "value",
                            "txth": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.PopupCloseTextButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.button.BasicButton"
                },
                "id": "i67nnhra"
            },
            "preset": {"rect": {"width": 217, "height": 72, "x": 106, "y": 559}, "tags": "Get Your Gift"}
        }, {
            "id": "PopUps_PopUps_Buttons_20",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.button.BasicButton",
                "layout": {
                    "width": 142,
                    "height": 40,
                    "x": 10,
                    "y": 645,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.PopupCloseTextButton",
                "data": {
                    "type": "LinkableButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "label": "Dive In"
                },
                "props": {
                    "type": "ButtonProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "align": "center",
                    "margin": 0
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-bg": "1",
                            "alpha-bgh": "1",
                            "alpha-brd": "1",
                            "alpha-brdh": "1",
                            "alpha-txt": "1",
                            "alpha-txth": "1",
                            "bg": "#01B1AF",
                            "bgh": "#006C6D",
                            "boxShadowToggleOn-shd": "false",
                            "brd": "#006C6D",
                            "brdh": "#01B1AF",
                            "brw": "0px",
                            "fnt": "normal normal 700 16px/1.4em raleway",
                            "rd": "3px",
                            "shd": "0 1px 4px rgba(0, 0, 0, 0.6);",
                            "txt": "#FFFFFF",
                            "txth": "#FFFFFF"
                        },
                        "propertiesSource": {
                            "bg": "value",
                            "bgh": "value",
                            "brd": "value",
                            "brdh": "value",
                            "brw": "value",
                            "fnt": "value",
                            "rd": "value",
                            "shd": "value",
                            "txt": "value",
                            "txth": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.PopupCloseTextButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.button.BasicButton"
                },
                "id": "i67nnhr7"
            },
            "preset": {"rect": {"width": 156, "height": 69, "x": 0, "y": 631}, "tags": "Dive In"}
        }, {
            "id": "PopUps_PopUps_Buttons_21",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.button.BasicButton",
                "layout": {
                    "width": 142,
                    "height": 40,
                    "x": 171,
                    "y": 645,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.PopupCloseTextButton",
                "data": {
                    "type": "LinkableButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "label": "Start Your Trial"
                },
                "props": {
                    "type": "ButtonProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "align": "center",
                    "margin": 0
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-bg": "1",
                            "alpha-bgh": "1",
                            "alpha-brd": "1",
                            "alpha-brdh": "1",
                            "alpha-txt": "1",
                            "alpha-txth": "1",
                            "bg": "#01B1AF",
                            "bgh": "#006C6D",
                            "boxShadowToggleOn-shd": "false",
                            "brd": "#006C6D",
                            "brdh": "#01B1AF",
                            "brw": "0",
                            "fnt": "normal normal normal 18px/1.4em nimbus-sans-tw01con",
                            "rd": "0px",
                            "shd": "0px 1px 1px 0px rgba(0,0,0,0.3)",
                            "txt": "#FFFFFF",
                            "txth": "#FFFFFF"
                        },
                        "propertiesSource": {
                            "bg": "value",
                            "bgh": "value",
                            "brd": "value",
                            "brdh": "value",
                            "brw": "value",
                            "fnt": "value",
                            "rd": "value",
                            "shd": "value",
                            "txt": "value",
                            "txth": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.PopupCloseTextButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.button.BasicButton"
                },
                "id": "i67nnhse"
            },
            "preset": {"rect": {"width": 168, "height": 69, "x": 156, "y": 631}, "tags": "Start Your Trial"}
        }, {
            "id": "PopUps_PopUps_Buttons_22",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.button.BasicButton",
                "layout": {
                    "width": 142,
                    "height": 40,
                    "x": 10,
                    "y": 715,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.PopupCloseTextButton",
                "data": {
                    "type": "LinkableButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "label": "Get Inspired"
                },
                "props": {
                    "type": "ButtonProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "align": "center",
                    "margin": 0
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-bg": "1",
                            "alpha-bgh": "1",
                            "alpha-brd": "1",
                            "alpha-brdh": "1",
                            "alpha-txt": "1",
                            "alpha-txth": "1",
                            "bg": "#006C6D",
                            "bgh": "#004D4E",
                            "boxShadowToggleOn-shd": "false",
                            "brd": "#004D4E",
                            "brdh": "#006C6D",
                            "brw": "0px",
                            "fnt": "normal normal normal 17px/1.4em lobster",
                            "rd": "20px 0px 20px 0px",
                            "shd": "0 1px 4px rgba(0, 0, 0, 0.6);",
                            "txt": "#FFFFFF",
                            "txth": "#FFFFFF"
                        },
                        "propertiesSource": {
                            "bg": "value",
                            "bgh": "value",
                            "brd": "value",
                            "brdh": "value",
                            "brw": "value",
                            "fnt": "value",
                            "rd": "value",
                            "shd": "value",
                            "txt": "value",
                            "txth": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.PopupCloseTextButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.button.BasicButton"
                },
                "id": "i67nnhri"
            },
            "preset": {"rect": {"width": 162, "height": 69, "x": 0, "y": 700}, "tags": "Get Inspired"}
        }, {
            "id": "PopUps_PopUps_Buttons_23",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.button.RibbonButton",
                "layout": {
                    "width": 142,
                    "height": 40,
                    "x": 172,
                    "y": 715,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.PopupCloseTextButton",
                "data": {
                    "type": "LinkableButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "label": "Talk to Me"
                },
                "props": {
                    "type": "ButtonProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "align": "center",
                    "margin": 0
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-bg": "1",
                            "alpha-bgh": "1",
                            "alpha-txt": "1",
                            "alpha-txth": "1",
                            "bg": "#006C6D",
                            "bgh": "#004D4E",
                            "fnt": "normal normal normal 18px/1.4em caudex",
                            "txt": "#FFFFFF",
                            "txth": "#FFFFFF"
                        },
                        "propertiesSource": {
                            "bg": "value",
                            "bgh": "value",
                            "fnt": "value",
                            "txt": "value",
                            "txth": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.PopupCloseTextButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.button.RibbonButton"
                },
                "id": "i67nnhrj"
            },
            "preset": {"rect": {"width": 162, "height": 69, "x": 162, "y": 700}, "tags": "Talk to Me"}
        }, {
            "id": "PopUps_PopUps_Buttons_24",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.button.BasicButton",
                "layout": {
                    "width": 88,
                    "height": 40,
                    "x": 10,
                    "y": 785,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.PopupCloseTextButton",
                "data": {
                    "type": "LinkableButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "label": "Donate"
                },
                "props": {
                    "type": "ButtonProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "align": "center",
                    "margin": 0
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-bg": "1",
                            "alpha-bgh": "1",
                            "alpha-brd": "1",
                            "alpha-brdh": "1",
                            "alpha-txt": "1",
                            "alpha-txth": "1",
                            "bg": "#DBB89A",
                            "bgh": "#5E3C17",
                            "boxShadowToggleOn-shd": "false",
                            "brd": "#5E3C17",
                            "brdh": "#DBB89A",
                            "brw": "0",
                            "fnt": "normal normal normal 16px/1.4em corben",
                            "rd": "0px",
                            "shd": "0px 1px 1px 0px rgba(0,0,0,0.3)",
                            "txt": "#FFFFFF",
                            "txth": "#FFFFFF"
                        },
                        "propertiesSource": {
                            "bg": "value",
                            "bgh": "value",
                            "brd": "value",
                            "brdh": "value",
                            "brw": "value",
                            "fnt": "value",
                            "rd": "value",
                            "shd": "value",
                            "txt": "value",
                            "txth": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.PopupCloseTextButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.button.BasicButton"
                },
                "id": "i67nnhrk"
            },
            "preset": {"rect": {"width": 107, "height": 69, "x": 0, "y": 769}, "tags": "Donate"}
        }, {
            "id": "PopUps_PopUps_Buttons_25",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.button.BasicButton",
                "layout": {
                    "width": 136,
                    "height": 40,
                    "x": 118,
                    "y": 785,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.PopupCloseTextButton",
                "data": {
                    "type": "LinkableButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "label": "Learn More"
                },
                "props": {
                    "type": "ButtonProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "align": "center",
                    "margin": 0
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-bg": "1",
                            "alpha-bgh": "1",
                            "alpha-brd": "1",
                            "alpha-brdh": "1",
                            "alpha-txt": "1",
                            "alpha-txth": "1",
                            "bg": "#DBB89A",
                            "bgh": "#5E3C17",
                            "boxShadowToggleOn-shd": "false",
                            "brd": "#5E3C17",
                            "brdh": "#DBB89A",
                            "brw": "0",
                            "fnt": "normal normal normal 18px/1.4em americantypwrteritcw01--731025",
                            "rd": "3px",
                            "shd": "0px 1px 1px 0px rgba(0,0,0,0.3)",
                            "txt": "#FFFFFF",
                            "txth": "#FFFFFF"
                        },
                        "propertiesSource": {
                            "bg": "value",
                            "bgh": "value",
                            "brd": "value",
                            "brdh": "value",
                            "brw": "value",
                            "fnt": "value",
                            "rd": "value",
                            "shd": "value",
                            "txt": "value",
                            "txth": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.PopupCloseTextButton",
                    "pageId": "",
                    "compId": "i67nnhrk_0",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.button.BasicButton"
                },
                "id": "i67nnhrk_0"
            },
            "preset": {"rect": {"width": 154, "height": 69, "x": 107, "y": 769}, "tags": "Learn More"}
        }, {
            "id": "PopUps_PopUps_Buttons_26",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.button.BasicButton",
                "layout": {
                    "width": 40,
                    "height": 40,
                    "x": 274,
                    "y": 785,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.PopupCloseTextButton",
                "data": {
                    "type": "LinkableButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "label": "Go!"
                },
                "props": {
                    "type": "ButtonProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "align": "center",
                    "margin": 0
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-bg": "1",
                            "alpha-bgh": "1",
                            "alpha-brd": "1",
                            "alpha-brdh": "1",
                            "alpha-txt": "1",
                            "alpha-txth": "1",
                            "bg": "#DBB89A",
                            "bgh": "#5E3C17",
                            "boxShadowToggleOn-shd": "false",
                            "brd": "#5E3C17",
                            "brdh": "#DBB89A",
                            "brw": "0",
                            "fnt": "normal normal normal 16px/1.4em clarendon-w01-medium-692107",
                            "rd": "5px",
                            "shd": "0px 1px 1px 0px rgba(0,0,0,0.3)",
                            "txt": "#FFFFFF",
                            "txth": "#FFFFFF"
                        },
                        "propertiesSource": {
                            "bg": "value",
                            "bgh": "value",
                            "brd": "value",
                            "brdh": "value",
                            "brw": "value",
                            "fnt": "value",
                            "rd": "value",
                            "shd": "value",
                            "txt": "value",
                            "txth": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.PopupCloseTextButton",
                    "pageId": "",
                    "compId": "i67nnhrj_0",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.button.BasicButton"
                },
                "id": "i67nnhrj_0"
            },
            "preset": {"rect": {"width": 63, "height": 69, "x": 261, "y": 769}, "tags": "Go!"}
        }, {
            "id": "PopUps_PopUps_Buttons_27",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.button.BasicButton",
                "layout": {
                    "width": 196,
                    "height": 40,
                    "x": 10,
                    "y": 855,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.PopupCloseTextButton",
                "data": {
                    "type": "LinkableButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "label": "Maybe Later"
                },
                "props": {
                    "type": "ButtonProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "align": "center",
                    "margin": 0
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-bg": "1",
                            "alpha-bgh": "1",
                            "alpha-brd": "1",
                            "alpha-brdh": "1",
                            "alpha-txt": "1",
                            "alpha-txth": "1",
                            "bg": "#5E3C17",
                            "bgh": "#342216",
                            "boxShadowToggleOn-shd": "false",
                            "brd": "#342216",
                            "brdh": "#5E3C17",
                            "brw": "0",
                            "fnt": "normal normal normal 17px/1.4em verdana",
                            "rd": "3px",
                            "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                            "txt": "#FFFFFF",
                            "txth": "#FFFFFF"
                        },
                        "propertiesSource": {
                            "bg": "value",
                            "bgh": "value",
                            "brd": "value",
                            "brdh": "value",
                            "brw": "value",
                            "fnt": "value",
                            "rd": "value",
                            "shd": "value",
                            "txt": "value",
                            "txth": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.PopupCloseTextButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.button.BasicButton"
                },
                "id": "i67nnhrn"
            },
            "preset": {"rect": {"width": 217, "height": 72, "x": 0, "y": 838}, "tags": "Maybe Later"}
        }, {
            "id": "PopUps_PopUps_Buttons_28",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.button.BasicButton",
                "layout": {
                    "width": 88,
                    "height": 88,
                    "x": 226,
                    "y": 855,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.PopupCloseTextButton",
                "data": {
                    "type": "LinkableButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "label": "Quit"
                },
                "props": {
                    "type": "ButtonProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "align": "center",
                    "margin": 0
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-bg": "1",
                            "alpha-bgh": "1",
                            "alpha-brd": "1",
                            "alpha-brdh": "1",
                            "alpha-txt": "1",
                            "alpha-txth": "1",
                            "bg": "#5E3C17",
                            "bgh": "#342216",
                            "boxShadowToggleOn-shd": "false",
                            "brd": "#342216",
                            "brdh": "#5E3C17",
                            "brw": "0",
                            "fnt": "normal normal normal 20px/1.4em stencil-w01-bold",
                            "rd": "3px",
                            "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                            "txt": "#FFFFFF",
                            "txth": "#FFFFFF"
                        },
                        "propertiesSource": {
                            "bg": "value",
                            "bgh": "value",
                            "brd": "value",
                            "brdh": "value",
                            "brw": "value",
                            "fnt": "value",
                            "rd": "value",
                            "shd": "value",
                            "txt": "value",
                            "txth": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.PopupCloseTextButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.button.BasicButton"
                },
                "id": "i67nnhrl"
            },
            "preset": {"rect": {"width": 107, "height": 116, "x": 217, "y": 838}, "tags": "Quit"}
        }, {
            "id": "PopUps_PopUps_Buttons_29",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.button.BasicButton",
                "layout": {
                    "width": 88,
                    "height": 40,
                    "x": 10,
                    "y": 925,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.PopupCloseTextButton",
                "data": {
                    "type": "LinkableButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "label": "Go Pro"
                },
                "props": {
                    "type": "ButtonProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "align": "center",
                    "margin": 0
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-bg": "1",
                            "alpha-bgh": "1",
                            "alpha-brd": "1",
                            "alpha-brdh": "1",
                            "alpha-txt": "1",
                            "alpha-txth": "1",
                            "bg": "#342216",
                            "bgh": "#5E3C17",
                            "boxShadowToggleOn-shd": "false",
                            "brd": "#5E3C17",
                            "brdh": "#342216",
                            "brw": "0px",
                            "fnt": "normal normal normal 18px/1.4em avenir-lt-w01_85-heavy1475544",
                            "rd": "15px",
                            "shd": "0px 1px 1px 0px rgba(0,0,0,0.3)",
                            "txt": "#FFFFFF",
                            "txth": "#FFFFFF"
                        },
                        "propertiesSource": {
                            "bg": "value",
                            "bgh": "value",
                            "brd": "value",
                            "brdh": "value",
                            "brw": "value",
                            "fnt": "value",
                            "rd": "value",
                            "shd": "value",
                            "txt": "value",
                            "txth": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.PopupCloseTextButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.button.BasicButton"
                },
                "id": "i67nnhrn_0"
            },
            "preset": {"rect": {"width": 110, "height": 72, "x": 0, "y": 910}, "tags": "Go Pro"}
        }, {
            "id": "PopUps_PopUps_Buttons_30",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.button.BasicButton",
                "layout": {
                    "width": 88,
                    "height": 40,
                    "x": 118,
                    "y": 925,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.PopupCloseTextButton",
                "data": {
                    "type": "LinkableButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "label": "Skip Ad"
                },
                "props": {
                    "type": "ButtonProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "align": "center",
                    "margin": 0
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-bg": "1",
                            "alpha-bgh": "1",
                            "alpha-brd": "1",
                            "alpha-brdh": "1",
                            "alpha-txt": "1",
                            "alpha-txth": "1",
                            "bg": "#342216",
                            "bgh": "#5E3C17",
                            "boxShadowToggleOn-shd": "false",
                            "brd": "#5E3C17",
                            "brdh": "#342216",
                            "brw": "0px",
                            "fnt": "normal normal normal 14px/1.4em cinzel",
                            "rd": "0px",
                            "shd": "0 1px 4px rgba(0, 0, 0, 0.6);",
                            "txt": "#FFFFFF",
                            "txth": "#FFFFFF"
                        },
                        "propertiesSource": {
                            "bg": "value",
                            "bgh": "value",
                            "brd": "value",
                            "brdh": "value",
                            "brw": "value",
                            "fnt": "value",
                            "rd": "value",
                            "shd": "value",
                            "txt": "value",
                            "txth": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.PopupCloseTextButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.button.BasicButton"
                },
                "id": "i67nnhrm_0"
            },
            "preset": {"rect": {"width": 107, "height": 72, "x": 110, "y": 910}, "tags": "Skip Ad"}
        }, {
            "id": "PopUps_PopUps_Buttons_31",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.button.BasicButton",
                "layout": {
                    "width": 88,
                    "height": 62,
                    "x": 226,
                    "y": 973,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.PopupCloseTextButton",
                "data": {
                    "type": "LinkableButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "label": "Play"
                },
                "props": {
                    "type": "ButtonProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "align": "center",
                    "margin": 0
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-bg": "1",
                            "alpha-bgh": "1",
                            "alpha-brd": "1",
                            "alpha-brdh": "1",
                            "alpha-txt": "1",
                            "alpha-txth": "1",
                            "bg": "#232323",
                            "bgh": "#5F5F5F",
                            "boxShadowToggleOn-shd": "false",
                            "brd": "#5F5F5F",
                            "brdh": "#232323",
                            "brw": "0px",
                            "fnt": "normal normal normal 16px/1.4em raleway",
                            "rd": "15px",
                            "shd": "0 1px 4px rgba(0, 0, 0, 0.6);",
                            "txt": "#FFFFFF",
                            "txth": "#FFFFFF"
                        },
                        "propertiesSource": {
                            "bg": "value",
                            "bgh": "value",
                            "brd": "value",
                            "brdh": "value",
                            "brw": "value",
                            "fnt": "value",
                            "rd": "value",
                            "shd": "value",
                            "txt": "value",
                            "txth": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.PopupCloseTextButton",
                    "pageId": "",
                    "compId": "i67nnhrl_0",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.button.BasicButton"
                },
                "id": "i67nnhrl_0"
            },
            "preset": {"rect": {"width": 107, "height": 100, "x": 217, "y": 954}, "tags": "Play"}
        }, {
            "id": "PopUps_PopUps_Buttons_32",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.button.BasicButton",
                "layout": {
                    "width": 196,
                    "height": 40,
                    "x": 10,
                    "y": 995,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.PopupCloseTextButton",
                "data": {
                    "type": "LinkableButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "label": "Get on the List"
                },
                "props": {
                    "type": "ButtonProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "align": "center",
                    "margin": 0
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-bg": "1",
                            "alpha-bgh": "1",
                            "alpha-brd": "1",
                            "alpha-brdh": "1",
                            "alpha-txt": "1",
                            "alpha-txth": "1",
                            "bg": "#232323",
                            "bgh": "#5F5F5F",
                            "boxShadowToggleOn-shd": "false",
                            "brd": "#5F5F5F",
                            "brdh": "#232323",
                            "brw": "0px",
                            "fnt": "normal normal normal 17px/1.4em kelly+slab",
                            "rd": "20px",
                            "shd": "0 1px 4px rgba(0, 0, 0, 0.6);",
                            "txt": "#FFFFFF",
                            "txth": "#FFFFFF"
                        },
                        "propertiesSource": {
                            "bg": "value",
                            "bgh": "value",
                            "brd": "value",
                            "brdh": "value",
                            "brw": "value",
                            "fnt": "value",
                            "rd": "value",
                            "shd": "value",
                            "txt": "value",
                            "txth": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.PopupCloseTextButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.button.BasicButton"
                },
                "id": "i67nnhrm"
            },
            "preset": {"rect": {"width": 217, "height": 72, "x": 0, "y": 982}, "tags": "Get on the List"}
        }, {
            "id": "PopUps_PopUps_Buttons_33",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.button.BasicButton",
                "layout": {
                    "width": 88,
                    "height": 80,
                    "x": 10,
                    "y": 1065,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.PopupCloseTextButton",
                "data": {
                    "type": "LinkableButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "label": "Ok"
                },
                "props": {
                    "type": "ButtonProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "align": "center",
                    "margin": 0
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-bg": "0",
                            "alpha-bgh": "0",
                            "alpha-brd": "1",
                            "alpha-brdh": "1",
                            "alpha-txt": "1",
                            "alpha-txth": "1",
                            "bg": "#FFFFFF",
                            "bgh": "#FFFFFF",
                            "boxShadowToggleOn-shd": "false",
                            "brd": "#C65AA5",
                            "brdh": "#7E0C6E",
                            "brw": "3px",
                            "fnt": "normal normal normal 30px/1.4em anton",
                            "rd": "20px",
                            "shd": "2px 2px 0px 0px rgba(0,0,0,0.2)",
                            "txt": "#C65AA5",
                            "txth": "#7E0C6E"
                        },
                        "propertiesSource": {
                            "bg": "value",
                            "bgh": "value",
                            "brd": "value",
                            "brdh": "value",
                            "brw": "value",
                            "fnt": "value",
                            "rd": "value",
                            "shd": "value",
                            "txt": "value",
                            "txth": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.PopupCloseTextButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.button.BasicButton"
                },
                "id": "i67nnhsg_0"
            },
            "preset": {"rect": {"width": 107, "height": 103, "x": 1, "y": 1054}, "tags": "Ok"}
        }, {
            "id": "PopUps_PopUps_Buttons_34",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.button.TextOnlyButtonSkin",
                "layout": {
                    "width": 99,
                    "height": 39,
                    "x": 112,
                    "y": 1064,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.PopupCloseTextButton",
                "data": {
                    "type": "LinkableButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "label": "I’m Good"
                },
                "props": {
                    "type": "ButtonProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "align": "center",
                    "margin": 0
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-txt": "1",
                            "alpha-txth": "1",
                            "fnt": "normal normal normal 18px/1.4em museo-w01-700",
                            "txt": "#C65AA5",
                            "txth": "#7E0C6E"
                        },
                        "propertiesSource": {"fnt": "value", "txt": "value", "txth": "value"}
                    },
                    "componentClassName": "wysiwyg.viewer.components.PopupCloseTextButton",
                    "pageId": "",
                    "compId": "i67nnhtq_0",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.button.TextOnlyButtonSkin"
                },
                "id": "i67nnhtq_0"
            },
            "preset": {"rect": {"width": 109, "height": 60, "x": 108, "y": 1054}, "tags": "I’m Good"}
        }, {
            "id": "PopUps_PopUps_Buttons_35",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.button.BasicButton",
                "layout": {
                    "width": 88,
                    "height": 40,
                    "x": 226,
                    "y": 1065,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.PopupCloseTextButton",
                "data": {
                    "type": "LinkableButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "label": "Close"
                },
                "props": {
                    "type": "ButtonProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "align": "center",
                    "margin": 0
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-bg": "0",
                            "alpha-bgh": "0",
                            "alpha-brd": "1",
                            "alpha-brdh": "1",
                            "alpha-txt": "1",
                            "alpha-txth": "1",
                            "bg": "#FFFFFF",
                            "bgh": "#FFFFFF",
                            "boxShadowToggleOn-shd": "false",
                            "brd": "#C65AA5",
                            "brdh": "#B22087",
                            "brw": "3px",
                            "fnt": "normal normal normal 9px/1.4em lulo-clean-w01-one-bold",
                            "rd": "10px",
                            "shd": "0 1px 4px rgba(0, 0, 0, 0.6);",
                            "txt": "#C65AA5",
                            "txth": "#B22087"
                        },
                        "propertiesSource": {
                            "bg": "value",
                            "bgh": "value",
                            "brd": "value",
                            "brdh": "value",
                            "brw": "value",
                            "fnt": "value",
                            "rd": "value",
                            "shd": "value",
                            "txt": "value",
                            "txth": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.PopupCloseTextButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.button.BasicButton"
                },
                "id": "i67nxist"
            },
            "preset": {"rect": {"width": 107, "height": 60, "x": 217, "y": 1054}, "tags": "Close"}
        }, {
            "id": "PopUps_PopUps_Buttons_36",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.button.BasicButton",
                "layout": {
                    "width": 196,
                    "height": 40,
                    "x": 118,
                    "y": 1135,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.PopupCloseTextButton",
                "data": {
                    "type": "LinkableButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "label": "See Top Trends"
                },
                "props": {
                    "type": "ButtonProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "align": "center",
                    "margin": 0
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-bg": "0",
                            "alpha-bgh": "0",
                            "alpha-brd": "1",
                            "alpha-brdh": "1",
                            "alpha-txt": "1",
                            "alpha-txth": "1",
                            "bg": "#FFFFFF",
                            "bgh": "#FFFFFF",
                            "boxShadowToggleOn-shd": "false",
                            "brd": "#B22087",
                            "brdh": "#DF65B8",
                            "brw": "3px",
                            "fnt": "normal normal bold 15px/1.4em raleway",
                            "rd": "5px",
                            "shd": "0 1px 4px rgba(0, 0, 0, 0.6);",
                            "txt": "#B22087",
                            "txth": "#DF65B8"
                        },
                        "propertiesSource": {
                            "bg": "value",
                            "bgh": "value",
                            "brd": "value",
                            "brdh": "value",
                            "brw": "value",
                            "fnt": "value",
                            "rd": "value",
                            "shd": "value",
                            "txt": "value",
                            "txth": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.PopupCloseTextButton",
                    "pageId": "",
                    "compId": "i67nnhrp",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.button.BasicButton"
                },
                "id": "i67nnhrp"
            },
            "preset": {"rect": {"width": 216, "height": 72, "x": 108, "y": 1114}, "tags": "See Top Trends"}
        }, {
            "id": "PopUps_PopUps_Buttons_37",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.button.BasicButton",
                "layout": {
                    "width": 88,
                    "height": 70,
                    "x": 10,
                    "y": 1175,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.PopupCloseTextButton",
                "data": {
                    "type": "LinkableButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "label": "Upgrade"
                },
                "props": {
                    "type": "ButtonProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "align": "center",
                    "margin": 0
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-bg": "0",
                            "alpha-bgh": "0",
                            "alpha-brd": "1",
                            "alpha-brdh": "1",
                            "alpha-txt": "1",
                            "alpha-txth": "1",
                            "bg": "#FFFFFF",
                            "bgh": "#FFFFFF",
                            "boxShadowToggleOn-shd": "false",
                            "brd": "#7E0C6E",
                            "brdh": "#C65AA5",
                            "brw": "3px",
                            "fnt": "normal normal normal 20px/1.4em reklamescriptw00-medium",
                            "rd": "0px",
                            "shd": "0 1px 4px rgba(0, 0, 0, 0.6);",
                            "txt": "#7E0C6E",
                            "txth": "#C65AA5"
                        },
                        "propertiesSource": {
                            "bg": "value",
                            "bgh": "value",
                            "brd": "value",
                            "brdh": "value",
                            "brw": "value",
                            "fnt": "value",
                            "rd": "value",
                            "shd": "value",
                            "txt": "value",
                            "txth": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.PopupCloseTextButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.button.BasicButton"
                },
                "id": "i67nnhrp_1"
            },
            "preset": {"rect": {"width": 107, "height": 103, "x": 1, "y": 1157}, "tags": "Upgrade"}
        }, {
            "id": "PopUps_PopUps_Buttons_38",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.button.BasicButton",
                "layout": {
                    "width": 88,
                    "height": 40,
                    "x": 118,
                    "y": 1205,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.PopupCloseTextButton",
                "data": {
                    "type": "LinkableButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "label": "Continue"
                },
                "props": {
                    "type": "ButtonProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "align": "center",
                    "margin": 0
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-bg": "0",
                            "alpha-bgh": "0",
                            "alpha-brd": "1",
                            "alpha-brdh": "1",
                            "alpha-txt": "1",
                            "alpha-txth": "1",
                            "bg": "#FFFFFF",
                            "bgh": "#FFFFFF",
                            "boxShadowToggleOn-shd": "false",
                            "brd": "#7E0C6E",
                            "brdh": "#C65AA5",
                            "brw": "3px",
                            "fnt": "normal normal normal 14px/1.4em raleway",
                            "rd": "0px",
                            "shd": "0 1px 4px rgba(0, 0, 0, 0.6);",
                            "txt": "#7E0C6E",
                            "txth": "#C65AA5"
                        },
                        "propertiesSource": {
                            "bg": "value",
                            "bgh": "value",
                            "brd": "value",
                            "brdh": "value",
                            "brw": "value",
                            "fnt": "value",
                            "rd": "value",
                            "shd": "value",
                            "txt": "value",
                            "txth": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.PopupCloseTextButton",
                    "pageId": "",
                    "compId": "i67nnhrq",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.button.BasicButton"
                },
                "id": "i67nnhrq"
            },
            "preset": {"rect": {"width": 109, "height": 74, "x": 108, "y": 1186}, "tags": "Continue"}
        }, {
            "id": "PopUps_PopUps_Buttons_39",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.button.BasicButton",
                "layout": {
                    "width": 88,
                    "height": 40,
                    "x": 226,
                    "y": 1205,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.PopupCloseTextButton",
                "data": {
                    "type": "LinkableButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "label": "Got It"
                },
                "props": {
                    "type": "ButtonProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "align": "center",
                    "margin": 0
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-bg": "0",
                            "alpha-bgh": "0",
                            "alpha-brd": "1",
                            "alpha-brdh": "1",
                            "alpha-txt": "1",
                            "alpha-txth": "1",
                            "bg": "#BDBF37",
                            "bgh": "#BDBF37",
                            "boxShadowToggleOn-shd": "false",
                            "brd": "#7E0C6E",
                            "brdh": "#C65AA5",
                            "brw": "3px",
                            "fnt": "normal normal normal 18px/1.4em lobster",
                            "rd": "5px",
                            "shd": "0 1px 4px rgba(0, 0, 0, 0.6);",
                            "txt": "#7E0C6E",
                            "txth": "#C65AA5"
                        },
                        "propertiesSource": {
                            "bg": "value",
                            "bgh": "value",
                            "brd": "value",
                            "brdh": "value",
                            "brw": "value",
                            "fnt": "value",
                            "rd": "value",
                            "shd": "value",
                            "txt": "value",
                            "txth": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.PopupCloseTextButton",
                    "pageId": "",
                    "compId": "i67nnhrb_0",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.button.BasicButton"
                },
                "id": "i67nnhrb_0"
            },
            "preset": {"rect": {"width": 107, "height": 74, "x": 217, "y": 1186}, "tags": "Got It"}
        }, {
            "id": "PopUps_PopUps_Buttons_40",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.button.BasicButton",
                "layout": {
                    "width": 196,
                    "height": 40,
                    "x": 10,
                    "y": 1276,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.PopupCloseTextButton",
                "data": {
                    "type": "LinkableButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "label": "Thanks, but no Thanks"
                },
                "props": {
                    "type": "ButtonProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "align": "center",
                    "margin": 0
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-bg": "0",
                            "alpha-bgh": "0",
                            "alpha-brd": "1",
                            "alpha-brdh": "1",
                            "alpha-txt": "1",
                            "alpha-txth": "1",
                            "bg": "#FFFFFF",
                            "bgh": "#FFFFFF",
                            "boxShadowToggleOn-shd": "false",
                            "brd": "#E21C21",
                            "brdh": "#88050A",
                            "brw": "3px",
                            "fnt": "normal normal normal 15px/1.4em signika",
                            "rd": "3px",
                            "shd": "0 1px 4px rgba(0, 0, 0, 0.6);",
                            "txt": "#E21C21",
                            "txth": "#88050A"
                        },
                        "propertiesSource": {
                            "bg": "value",
                            "bgh": "value",
                            "brd": "value",
                            "brdh": "value",
                            "brw": "value",
                            "fnt": "value",
                            "rd": "value",
                            "shd": "value",
                            "txt": "value",
                            "txth": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.PopupCloseTextButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.button.BasicButton"
                },
                "id": "i67nnhrp_0"
            },
            "preset": {"rect": {"width": 217, "height": 72, "x": 0, "y": 1260}, "tags": "Thanks, but no Thanks"}
        }, {
            "id": "PopUps_PopUps_Buttons_41",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.button.TextOnlyButtonSkin",
                "layout": {
                    "width": 98,
                    "height": 36,
                    "x": 217,
                    "y": 1277,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.PopupCloseTextButton",
                "data": {
                    "type": "LinkableButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "label": "Get Yours"
                },
                "props": {
                    "type": "ButtonProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "align": "center",
                    "margin": 0
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-txt": "1",
                            "alpha-txth": "1",
                            "fnt": "normal normal 700 23px/1.4em sacramento",
                            "txt": "#E21C21",
                            "txth": "#88050A"
                        },
                        "propertiesSource": {"fnt": "value", "txt": "value", "txth": "value"}
                    },
                    "componentClassName": "wysiwyg.viewer.components.PopupCloseTextButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.button.TextOnlyButtonSkin"
                },
                "id": "i67nnhse_0"
            },
            "preset": {"rect": {"width": 107, "height": 72, "x": 217, "y": 1260}, "tags": "Get Yours"}
        }, {
            "id": "PopUps_PopUps_Buttons_42",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.button.BasicButton",
                "layout": {
                    "width": 142,
                    "height": 40,
                    "x": 10,
                    "y": 1345,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.PopupCloseTextButton",
                "data": {
                    "type": "LinkableButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "label": "Join Now"
                },
                "props": {
                    "type": "ButtonProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "align": "center",
                    "margin": 0
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-bg": "0",
                            "alpha-bgh": "0",
                            "alpha-brd": "1",
                            "alpha-brdh": "1",
                            "alpha-txt": "1",
                            "alpha-txth": "1",
                            "bg": "#FFFFFF",
                            "bgh": "#FFFFFF",
                            "boxShadowToggleOn-shd": "false",
                            "brd": "#B7121B",
                            "brdh": "#FF020C",
                            "brw": "3px",
                            "fnt": "italic normal normal 14px/1.4em georgia",
                            "rd": "0px",
                            "shd": "0 1px 4px rgba(0, 0, 0, 0.6);",
                            "txt": "#B7121B",
                            "txth": "#FF020C"
                        },
                        "propertiesSource": {
                            "bg": "value",
                            "bgh": "value",
                            "brd": "value",
                            "brdh": "value",
                            "brw": "value",
                            "fnt": "value",
                            "rd": "value",
                            "shd": "value",
                            "txt": "value",
                            "txth": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.PopupCloseTextButton",
                    "pageId": "",
                    "compId": "i67nnhrr",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.button.BasicButton"
                },
                "id": "i67nnhrr"
            },
            "preset": {"rect": {"width": 163, "height": 67, "x": 1, "y": 1332}, "tags": "Join Now"}
        }, {
            "id": "PopUps_PopUps_Buttons_43",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.button.BasicButton",
                "layout": {
                    "width": 142,
                    "height": 40,
                    "x": 172,
                    "y": 1345,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.PopupCloseTextButton",
                "data": {
                    "type": "LinkableButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "label": "Get Started"
                },
                "props": {
                    "type": "ButtonProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "align": "center",
                    "margin": 0
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-bg": "0",
                            "alpha-bgh": "0",
                            "alpha-brd": "1",
                            "alpha-brdh": "1",
                            "alpha-txt": "1",
                            "alpha-txth": "1",
                            "bg": "#FFFFFF",
                            "bgh": "#FFFFFF",
                            "boxShadowToggleOn-shd": "false",
                            "brd": "#B7121B",
                            "brdh": "#F80046",
                            "brw": "3px",
                            "fnt": "normal normal normal 17px/1.4em futura-lt-w01-book",
                            "rd": "10px",
                            "shd": "0 1px 4px rgba(0, 0, 0, 0.6);",
                            "txt": "#B7121B",
                            "txth": "#F80046"
                        },
                        "propertiesSource": {
                            "bg": "value",
                            "bgh": "value",
                            "brd": "value",
                            "brdh": "value",
                            "brw": "value",
                            "fnt": "value",
                            "rd": "value",
                            "shd": "value",
                            "txt": "value",
                            "txth": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.PopupCloseTextButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.button.BasicButton"
                },
                "id": "i67nnhrb"
            },
            "preset": {"rect": {"width": 160, "height": 67, "x": 164, "y": 1332}, "tags": "Get Started"}
        }, {
            "id": "PopUps_PopUps_Buttons_44",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.button.TextOnlyButtonSkin",
                "layout": {
                    "width": 155,
                    "height": 40,
                    "x": 4,
                    "y": 1415,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.PopupCloseTextButton",
                "data": {
                    "type": "LinkableButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "label": "DIVE IN NOW"
                },
                "props": {
                    "type": "ButtonProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "align": "center",
                    "margin": 0
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-txt": "1",
                            "alpha-txth": "1",
                            "fnt": "normal normal 700 22px/1.4em brandon-grot-w01-light",
                            "txt": "#01B1AF",
                            "txth": "#024E50"
                        },
                        "propertiesSource": {"fnt": "value", "txt": "value", "txth": "value"}
                    },
                    "componentClassName": "wysiwyg.viewer.components.PopupCloseTextButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.button.TextOnlyButtonSkin"
                },
                "id": "i67nnhsf"
            },
            "preset": {"rect": {"width": 164, "height": 64, "x": 0, "y": 1399}, "tags": "DIVE IN NOW"}
        }, {
            "id": "PopUps_PopUps_Buttons_45",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.button.BasicButton",
                "layout": {
                    "width": 142,
                    "height": 40,
                    "x": 172,
                    "y": 1415,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.PopupCloseTextButton",
                "data": {
                    "type": "LinkableButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "label": "Learn More"
                },
                "props": {
                    "type": "ButtonProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "align": "center",
                    "margin": 0
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-bg": "0",
                            "alpha-bgh": "0",
                            "alpha-brd": "1",
                            "alpha-brdh": "1",
                            "alpha-txt": "1",
                            "alpha-txth": "1",
                            "bg": "#FFFFFF",
                            "bgh": "#FFFFFF",
                            "boxShadowToggleOn-shd": "false",
                            "brd": "#01B1AF",
                            "brdh": "#006C6D",
                            "brw": "3px",
                            "fnt": "normal normal normal 11px/1.4em lulo-clean-w01-one-bold",
                            "rd": "6px",
                            "shd": "0 1px 4px rgba(0, 0, 0, 0.6);",
                            "txt": "#01B1AF",
                            "txth": "#006C6D"
                        },
                        "propertiesSource": {
                            "bg": "value",
                            "bgh": "value",
                            "brd": "value",
                            "brdh": "value",
                            "brw": "value",
                            "fnt": "value",
                            "rd": "value",
                            "shd": "value",
                            "txt": "value",
                            "txth": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.PopupCloseTextButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.button.BasicButton"
                },
                "id": "i67nnhrq_0"
            },
            "preset": {"rect": {"width": 160, "height": 64, "x": 164, "y": 1399}, "tags": "Learn More"}
        }, {
            "id": "PopUps_PopUps_Buttons_46",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.button.BasicButton",
                "layout": {
                    "width": 88,
                    "height": 88,
                    "x": 10,
                    "y": 1485,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.PopupCloseTextButton",
                "data": {
                    "type": "LinkableButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "label": "GO!"
                },
                "props": {
                    "type": "ButtonProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "align": "center",
                    "margin": 0
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-bg": "0",
                            "alpha-bgh": "0",
                            "alpha-brd": "1",
                            "alpha-brdh": "1",
                            "alpha-txt": "1",
                            "alpha-txth": "1",
                            "bg": "#FFFFFF",
                            "bgh": "#FFFFFF",
                            "boxShadowToggleOn-shd": "false",
                            "brd": "#2B6CA3",
                            "brdh": "#3D9BE9",
                            "brw": "3px",
                            "fnt": "normal normal 700 36px/1.4em anton",
                            "rd": "5px",
                            "shd": "0 1px 4px rgba(0, 0, 0, 0.6);",
                            "txt": "#2B6CA3",
                            "txth": "#3D9BE9"
                        },
                        "propertiesSource": {
                            "bg": "value",
                            "bgh": "value",
                            "brd": "value",
                            "brdh": "value",
                            "brw": "value",
                            "fnt": "value",
                            "rd": "value",
                            "shd": "value",
                            "txt": "value",
                            "txth": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.PopupCloseTextButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.button.BasicButton"
                },
                "id": "i67nnhrs"
            },
            "preset": {"rect": {"width": 108, "height": 132, "x": 1, "y": 1463}, "tags": "GO!"}
        }, {
            "id": "PopUps_PopUps_Buttons_47",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.button.BasicButton",
                "layout": {
                    "width": 196,
                    "height": 39,
                    "x": 118,
                    "y": 1486,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.PopupCloseTextButton",
                "data": {
                    "type": "LinkableButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "label": "Get a Quote"
                },
                "props": {
                    "type": "ButtonProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "align": "center",
                    "margin": 0
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-bg": "0",
                            "alpha-bgh": "0",
                            "alpha-brd": "1",
                            "alpha-brdh": "1",
                            "alpha-txt": "1",
                            "alpha-txth": "1",
                            "bg": "#FFFFFF",
                            "bgh": "#FFFFFF",
                            "boxShadowToggleOn-shd": "false",
                            "brd": "#2B6CA3",
                            "brdh": "#3D9BE9",
                            "brw": "3px",
                            "fnt": "normal normal 700 19px/1.4em sail",
                            "rd": "5px",
                            "shd": "0 1px 4px rgba(0, 0, 0, 0.6);",
                            "txt": "#2B6CA3",
                            "txth": "#3D9BE9"
                        },
                        "propertiesSource": {
                            "bg": "value",
                            "bgh": "value",
                            "brd": "value",
                            "brdh": "value",
                            "brw": "value",
                            "fnt": "value",
                            "rd": "value",
                            "shd": "value",
                            "txt": "value",
                            "txth": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.PopupCloseTextButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.button.BasicButton"
                },
                "id": "i67nnhro"
            },
            "preset": {"rect": {"width": 215, "height": 74, "x": 109, "y": 1463}, "tags": "Get a Quote"}
        }, {
            "id": "PopUps_PopUps_Buttons_48",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.button.BasicButton",
                "layout": {
                    "width": 88,
                    "height": 40,
                    "x": 118,
                    "y": 1555,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.PopupCloseTextButton",
                "data": {
                    "type": "LinkableButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "label": "Exit"
                },
                "props": {
                    "type": "ButtonProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "align": "center",
                    "margin": 0
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-bg": "0",
                            "alpha-bgh": "0",
                            "alpha-brd": "1",
                            "alpha-brdh": "1",
                            "alpha-txt": "1",
                            "alpha-txth": "1",
                            "bg": "#FFFFFF",
                            "bgh": "#FFFFFF",
                            "boxShadowToggleOn-shd": "false",
                            "brd": "#002E5D",
                            "brdh": "#3D9BE9",
                            "brw": "3px",
                            "fnt": "normal normal 700 15px/1.4em kepler-w03-light-scd-cp",
                            "rd": "3px",
                            "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                            "txt": "#002E5D",
                            "txth": "#3D9BE9"
                        },
                        "propertiesSource": {
                            "bg": "value",
                            "bgh": "value",
                            "brd": "value",
                            "brdh": "value",
                            "brw": "value",
                            "fnt": "value",
                            "rd": "value",
                            "shd": "value",
                            "txt": "value",
                            "txth": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.PopupCloseTextButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.button.BasicButton"
                },
                "id": "i67nnhsg_1"
            },
            "preset": {"rect": {"width": 108, "height": 74, "x": 109, "y": 1537}, "tags": "Exit"}
        }, {
            "id": "PopUps_PopUps_Buttons_49",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.button.BasicButton",
                "layout": {
                    "width": 88,
                    "height": 40,
                    "x": 226,
                    "y": 1555,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.PopupCloseTextButton",
                "data": {
                    "type": "LinkableButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "label": "Sign Up"
                },
                "props": {
                    "type": "ButtonProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "align": "center",
                    "margin": 0
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-bg": "0",
                            "alpha-bgh": "0",
                            "alpha-brd": "1",
                            "alpha-brdh": "1",
                            "alpha-txt": "1",
                            "alpha-txth": "1",
                            "bg": "#FFFFFF",
                            "bgh": "#FFFFFF",
                            "boxShadowToggleOn-shd": "false",
                            "brd": "#002E5D",
                            "brdh": "#3D9BE9",
                            "brw": "3px",
                            "fnt": "normal normal normal 14px/1.4em overlock",
                            "rd": "29px",
                            "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                            "txt": "#002E5D",
                            "txth": "#3D9BE9"
                        },
                        "propertiesSource": {
                            "bg": "value",
                            "bgh": "value",
                            "brd": "value",
                            "brdh": "value",
                            "brw": "value",
                            "fnt": "value",
                            "rd": "value",
                            "shd": "value",
                            "txt": "value",
                            "txth": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.PopupCloseTextButton",
                    "pageId": "",
                    "compId": "i67nnhrs_0",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.button.BasicButton"
                },
                "id": "i67nnhrs_0"
            },
            "preset": {"rect": {"width": 107, "height": 74, "x": 217, "y": 1537}, "tags": "Sign Up"}
        }, {
            "id": "PopUps_PopUps_Buttons_50",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.button.BasicButton",
                "layout": {
                    "width": 88,
                    "height": 60,
                    "x": 10,
                    "y": 1605,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.PopupCloseTextButton",
                "data": {
                    "type": "LinkableButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "label": "Thanks!"
                },
                "props": {
                    "type": "ButtonProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "align": "center",
                    "margin": 0
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-bg": "0",
                            "alpha-bgh": "0",
                            "alpha-brd": "1",
                            "alpha-brdh": "1",
                            "alpha-txt": "1",
                            "alpha-txth": "1",
                            "bg": "#FFFFFF",
                            "bgh": "#FFFFFF",
                            "boxShadowToggleOn-shd": "false",
                            "brd": "#F2668F",
                            "brdh": "#C51052",
                            "brw": "3px",
                            "fnt": "normal normal normal 16px/1.4em helvetica-w01-light",
                            "rd": "0px",
                            "shd": "0 1px 4px rgba(0, 0, 0, 0.6);",
                            "txt": "#F2668F",
                            "txth": "#C51052"
                        },
                        "propertiesSource": {
                            "bg": "value",
                            "bgh": "value",
                            "brd": "value",
                            "brdh": "value",
                            "brw": "value",
                            "fnt": "value",
                            "rd": "value",
                            "shd": "value",
                            "txt": "value",
                            "txth": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.PopupCloseTextButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.button.BasicButton"
                },
                "id": "i67nnhre"
            },
            "preset": {"rect": {"width": 109, "height": 84, "x": 0, "y": 1595}, "tags": "Thanks!"}
        }, {
            "id": "PopUps_PopUps_Buttons_51",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.button.BasicButton",
                "layout": {
                    "width": 196,
                    "height": 40,
                    "x": 118,
                    "y": 1625,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.PopupCloseTextButton",
                "data": {
                    "type": "LinkableButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "label": "Alrighty Then!"
                },
                "props": {
                    "type": "ButtonProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "align": "center",
                    "margin": 0
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-bg": "0",
                            "alpha-bgh": "0",
                            "alpha-brd": "1",
                            "alpha-brdh": "1",
                            "alpha-txt": "1",
                            "alpha-txth": "1",
                            "bg": "#FFFFFF",
                            "bgh": "#FFFFFF",
                            "boxShadowToggleOn-shd": "false",
                            "brd": "#F2668F",
                            "brdh": "#C51052",
                            "brw": "3px",
                            "fnt": "normal normal normal 18px/1.4em enriqueta",
                            "rd": "5px",
                            "shd": "0 1px 4px rgba(0, 0, 0, 0.6);",
                            "txt": "#F2668F",
                            "txth": "#C51052"
                        },
                        "propertiesSource": {
                            "bg": "value",
                            "bgh": "value",
                            "brd": "value",
                            "brdh": "value",
                            "brw": "value",
                            "fnt": "value",
                            "rd": "value",
                            "shd": "value",
                            "txt": "value",
                            "txth": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.PopupCloseTextButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.button.BasicButton"
                },
                "id": "i67nnhrc"
            },
            "preset": {"rect": {"width": 215, "height": 68, "x": 109, "y": 1611}, "tags": "Alrighty Then!"}
        }, {
            "id": "PopUps_PopUps_Buttons_52",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.button.BasicButton",
                "layout": {
                    "width": 196,
                    "height": 40,
                    "x": 10,
                    "y": 1695,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.PopupCloseTextButton",
                "data": {
                    "type": "LinkableButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "label": "See Top Picks"
                },
                "props": {
                    "type": "ButtonProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "align": "center",
                    "margin": 0
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-bg": "0",
                            "alpha-bgh": "0",
                            "alpha-brd": "1",
                            "alpha-brdh": "1",
                            "alpha-txt": "1",
                            "alpha-txth": "1",
                            "bg": "#FFFFFF",
                            "bgh": "#FFFFFF",
                            "boxShadowToggleOn-shd": "false",
                            "brd": "#BDBF37",
                            "brdh": "#9C9C31",
                            "brw": "3px",
                            "fnt": "normal normal normal 18px/1.4em signika",
                            "rd": "0px",
                            "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                            "txt": "#9C9C31",
                            "txth": "#9C9C31"
                        },
                        "propertiesSource": {
                            "bg": "value",
                            "bgh": "value",
                            "brd": "value",
                            "brdh": "value",
                            "brw": "value",
                            "fnt": "value",
                            "rd": "value",
                            "shd": "value",
                            "txt": "value",
                            "txth": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.PopupCloseTextButton",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.button.BasicButton"
                },
                "id": "i67nnhre_0"
            },
            "preset": {"rect": {"width": 217, "height": 86, "x": 0, "y": 1679}, "tags": "See Top Picks"}
        }, {
            "id": "PopUps_PopUps_Buttons_53",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.button.TextOnlyButtonSkin",
                "layout": {
                    "width": 79,
                    "height": 31,
                    "x": 230,
                    "y": 1700,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.PopupCloseTextButton",
                "data": {
                    "type": "LinkableButton",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "label": "Continue"
                },
                "props": {
                    "type": "ButtonProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "align": "center",
                    "margin": 0
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-txt": "1",
                            "alpha-txth": "1",
                            "fnt": "normal normal normal 14px/1.4em raleway",
                            "txt": "#BDBF37",
                            "txth": "#98992E"
                        },
                        "propertiesSource": {
                            "bg": "theme",
                            "bgh": "theme",
                            "brd": "theme",
                            "brdh": "theme",
                            "brw": "value",
                            "fnt": "value",
                            "shd": "value",
                            "txt": "value",
                            "txth": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.PopupCloseTextButton",
                    "pageId": "",
                    "compId": "i67nnhse_1",
                    "styleType": "custom",
                    "skin": "wysiwyg.viewer.skins.button.TextOnlyButtonSkin"
                },
                "id": "i67nnhse_1"
            },
            "preset": {"rect": {"width": 107, "height": 86, "x": 217, "y": 1679}, "tags": "Continue"}
        }],
        "compTypes": ["wysiwyg.viewer.components.PopupCloseTextButton"]
    },
    "help": {"hide": false, "text": "add_section_info_text_popupbutton"}
}
