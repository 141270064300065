// @ts-nocheck
import PropTypes from 'prop-types';

export default PropTypes.arrayOf(
  PropTypes.oneOfType([
    PropTypes.shape({
      leftTitle: PropTypes.string,
      rightTitle: PropTypes.string,
      description: PropTypes.string,
      onClick: PropTypes.func,
      symbolName: PropTypes.string,
      image: PropTypes.string,
      automationId: PropTypes.string,
      illustrationClassName: PropTypes.string,
      listItemClassName: PropTypes.string,
      isCheckbox: PropTypes.oneOf([true]),
      skipClickClosePanel: PropTypes.bool,
      experiment: PropTypes.string,
      getCounter: PropTypes.func,
      condition: PropTypes.func,
      isDisabled: PropTypes.bool,
      isNested: PropTypes.bool,
      checkBoxValue: PropTypes.bool,
    }),
    PropTypes.shape({
      isSeparator: PropTypes.oneOf([true]).isRequired,
    }),
  ]),
).isRequired;
