import React from 'react';
import _ from 'lodash';
import * as util from '@/util';
import { translate } from '@/i18n';
import * as panels from '@/panels';
import * as leftBar from '@/leftBar';
import * as pageSettingsPanel from '@/pageSettingsPanel';
import PAGE_CATEGORIES from '../utils/pageCategories';
import SignupView from './pagesView/editorAPIConnectors/signupView';
import PopupView from './pagesView/editorAPIConnectors/popupView';
import DynamicPagesView from './pagesView/editorAPIConnectors/dynamicPagesView';
import AppPagesView from './pagesView/editorAPIConnectors/appPagesView';
import MenuView from './menuView/menuView';
import TransitionsView from './transitionsView/transitionsView';
import DynamicPagesIntroView from './dynamicPagesIntroView/dynamicPagesIntroView';
import * as pagesPanelMapper from './pagesPanelMapper';
import PanelTabs from './tabs/panelTabs';

import type { PagesData } from 'types/documentServices';
import type { IPagesPanelMenu } from '../api/menusPages';
import type { IRouter } from '../api/routerPages';
import type { IPopup } from '../api/popupsPages';
import type { IAppPage } from '../api/appsPages';

const {
  connect,
  STORES: { EDITOR_API },
} = util.hoc;

function isDynamicPagesView(id: AnyFixMe, props: AnyFixMe) {
  return (
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/some
    _.some(props.userRouters, { id }) || _.some(props.specialRouters, { id })
  );
}

export interface ISettingsData {
  tabType: string;
  pageId: string;
  formType?: 'signUp' | 'signIn';
  routerId?: string;
  innerRoute?: string;
  menuId?: string;
}

interface IPagesPanelState {
  selectedTabId: string;
  nonPageItemSelected: boolean;
  userSelectedPageId: string;
  settingsData: ISettingsData | null;
  renamedItemId: string | null;
}

interface FedopsInteraction {
  start: () => void;
  end: () => void;
}
interface IPagesPanelProps {
  pagesData: PagesData[];
  menus: IPagesPanelMenu[];
  appPages: IAppPage[];
  popups: IPopup[];
  userRouters: IRouter[];
  specialRouters: IRouter[];

  initialSettingsTabType: string; //todo: enam or union type?

  panelName: string;
  initialTabId: string;
  selectedTabId: string;
  selectedPageId: string;
  selectedMenuItemId?: string;
  renamedItemId: string;
  biPanelName: string;
  selectedTransition: string;
  openPanelInteraction?: FedopsInteraction;
  closePanelInteraction?: FedopsInteraction;
  panelIndex: number;

  isDesktop: boolean;
  selectedPageIsDynamic: boolean;
  selectedPageIsManagedByApp: boolean;
  isForceSelectedTabId?: boolean;
  forceOpenPagesPanelCounter: number;

  hasCustomSignupLightbox: boolean;
  hasCustomSignInLightbox: boolean;
  shouldShowDynamicPagesIntroTab: boolean;
  hasRestrictedPage: boolean;
  isMembersAreaV2Installed: boolean;

  getPageData(arg0: string): PagesData;
  onOpen: () => void;
  onClose: Function; //todo: add actual type
  onHelp: Function; //todo: add actual type
  biEvent: Function; //todo: add actual types
  notifyDataBindingAppTooManyPages: Function; //todo: add actual type
  onClickTransition: Function; //todo: add actual type
  addDynamicPage: () => void;
  openNewDynamicPagePanel: () => void;
  clearForceSelectedTabId: Function; //todo: add actual type
  setInPrefIscollapsedMenuItems: (
    menuItemId: string,
    isCollapsed: boolean,
  ) => void;
  collapsionMap?: Record<string, boolean>;
  resetEditingId: () => void;
  hasUserCollections: boolean;
}

const getInitialSettingsData = (
  tabType?: string,
  pageId?: string,
): ISettingsData | null =>
  tabType
    ? {
        tabType,
        pageId,
      }
    : null;

type PagesPanelPropsKeysToView =
  | 'pagesData'
  | 'onHelp'
  | 'selectedMenuItemId'
  | 'selectedPageId'
  | 'isDesktop'
  | 'popups'
  | 'selectedTransition'
  | 'notifyDataBindingAppTooManyPages'
  | 'onClickTransition'
  | 'addDynamicPage'
  | 'openNewDynamicPagePanel'
  | 'biPanelName'
  | 'setInPrefIscollapsedMenuItems'
  | 'collapsionMap'
  | 'hasUserCollections';

export interface IPagesPanelViewProps
  extends Pick<IPagesPanelProps, PagesPanelPropsKeysToView> {
  appPages: IAppPage[];
  onClose: () => void;
  renamedItemId: string | null;
  title: string | undefined;
  onShowSettings: (settingsData: Partial<ISettingsData>) => void;
  onItemAdded: (itemId: string) => void;
  onRename: () => void;
  menu: IPagesPanelMenu;
  router: IRouter | undefined;
  appData: IAppPage | undefined;
  onSelectNonPageItem: () => void;
  onSelectPageItem: (pageId: string) => void;
  hasDynamicPages: boolean;
}

class PagesPanel extends React.Component<IPagesPanelProps, IPagesPanelState> {
  static displayName = 'PagesPanel';

  static defaultProps = {
    panelName: 'pagesPanel',
  };

  state: IPagesPanelState = {
    selectedTabId: this.props.initialTabId,
    nonPageItemSelected: false,
    userSelectedPageId: null,
    settingsData: getInitialSettingsData(
      this.props.initialSettingsTabType,
      this.props.selectedPageId,
    ),
    renamedItemId: this.props.renamedItemId || null,
  };

  componentDidMount() {
    this.props.openPanelInteraction?.end();
    this.props.onOpen();
  }

  UNSAFE_componentWillReceiveProps(nextProps: AnyFixMe) {
    const {
      isForceSelectedTabId,
      selectedPageId,
      forceOpenPagesPanelCounter,
      selectedTabId,
    } = this.props;
    if (!nextProps.isForceSelectedTabId && isForceSelectedTabId) {
      return;
    }

    const pageChanged = selectedPageId !== nextProps.selectedPageId;
    const persistTab =
      this.state.selectedTabId === PAGE_CATEGORIES.TRANSITIONS.id ||
      this.state.selectedTabId === PAGE_CATEGORIES.SIGNUP.id ||
      this.state.userSelectedPageId === nextProps.selectedPageId ||
      this.state.selectedTabId === nextProps.selectedTabId;

    if (this.state.settingsData) {
      if (pageChanged) {
        if (this.state.settingsData.pageId === nextProps.selectedPageId) {
          this.handleChangeShownSettings({
            tabType: null,
            pageId: nextProps.selectedPageId,
          });
        } else {
          this.setState({ settingsData: null });
        }
      }
    }

    const samePageDifferentTab =
      !pageChanged && nextProps.selectedTabId !== selectedTabId;

    if (
      (nextProps.selectedTabId &&
        !persistTab &&
        pageChanged &&
        !this.state.nonPageItemSelected) ||
      samePageDifferentTab
    ) {
      this.setState({ selectedTabId: nextProps.selectedTabId });
    }

    if (nextProps.forceOpenPagesPanelCounter > forceOpenPagesPanelCounter) {
      if (nextProps.renamedItemId !== this.state.renamedItemId) {
        this.setState({ renamedItemId: nextProps.renamedItemId });
        if (nextProps.selectedTabId !== this.state.selectedTabId) {
          this.setState({ selectedTabId: nextProps.selectedTabId });
        }
      }
      if (nextProps.initialSettingsTabType) {
        this.handleShowSettings({
          tabType: nextProps.initialSettingsTabType,
          pageId: nextProps.selectedPageId,
        });
      }
    }

    this.updateSelectedTabIdIfDeleted(nextProps);
  }

  componentWillUnmount() {
    this.props.closePanelInteraction?.end();
    this.props.resetEditingId();
  }

  readonly isNewWorkspace = util.workspace.isNewWorkspaceEnabled();

  getView = () => {
    let ViewToRender;

    const {
      pagesData,
      onClose,
      onHelp,
      selectedMenuItemId,
      selectedPageId,
      isDesktop,
      popups,
      menus,
      appPages,
      userRouters,
      specialRouters,
      selectedTransition,
      notifyDataBindingAppTooManyPages,
      onClickTransition,
      addDynamicPage,
      openNewDynamicPagePanel,
      biPanelName,
      hasUserCollections,
    } = this.props;

    const id = this.state.selectedTabId;

    if (id === PAGE_CATEGORIES.TRANSITIONS.id) {
      ViewToRender = TransitionsView;
    } else if (id === PAGE_CATEGORIES.DYNAMIC_PAGES_INTRO.id) {
      ViewToRender = DynamicPagesIntroView;
      // eslint-disable-next-line you-dont-need-lodash-underscore/some
    } else if (_.some(menus, { id })) {
      ViewToRender = MenuView;
    } else if (id === PAGE_CATEGORIES.SIGNUP.id) {
      ViewToRender = SignupView;
    } else if (id === PAGE_CATEGORIES.POPUPS.id) {
      ViewToRender = PopupView;
    } else if (isDynamicPagesView(id, this.props)) {
      ViewToRender = DynamicPagesView;
      // eslint-disable-next-line you-dont-need-lodash-underscore/some
    } else if (_.some(appPages, { id })) {
      ViewToRender = AppPagesView;
    }
    const viewToRenderProps: IPagesPanelViewProps = {
      pagesData,
      appPages,
      onHelp,
      selectedMenuItemId,
      selectedPageId,
      isDesktop,
      popups,
      selectedTransition,
      notifyDataBindingAppTooManyPages,
      onClickTransition,
      addDynamicPage,
      openNewDynamicPagePanel,
      biPanelName,
      hasUserCollections,
      onClose: () => {
        this.props.closePanelInteraction?.start();
        onClose();
      },
      renamedItemId: this.state.renamedItemId,
      title: translate(
        (PAGE_CATEGORIES[id as keyof typeof PAGE_CATEGORIES] as AnyFixMe)
          ?.title,
      ),
      onShowSettings: this.handleShowSettings,
      onItemAdded: (renamedItemId) =>
        this.setState({ renamedItemId, settingsData: null }),
      onRename: this.handelRename,
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/find
      menu: _.find(menus, { id }),
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/find
      router: _.find(userRouters, { id }) || _.find(specialRouters, { id }),
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/find
      appData: _.find(appPages, { id }),
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/size
      hasDynamicPages: _.size(userRouters) > 0,
      setInPrefIscollapsedMenuItems: this.props.setInPrefIscollapsedMenuItems,
      collapsionMap: this.props.collapsionMap,
      onSelectNonPageItem: () => {
        this.handleCloseSettings();
        this.setState({
          nonPageItemSelected: true,
          userSelectedPageId: null,
        });
      },
      onSelectPageItem: (pageId) =>
        this.setState({
          nonPageItemSelected: false,
          userSelectedPageId: pageId,
          selectedTabId: this.state.selectedTabId,
        }),
    };

    return <ViewToRender {...viewToRenderProps} />;
  };

  handelRename = () => {
    const { resetEditingId } = this.props;

    resetEditingId();
    this.setState({ renamedItemId: null });
  };

  handleClickTab = (selectedTabId: string) => {
    const { isForceSelectedTabId, clearForceSelectedTabId } = this.props;

    if (isForceSelectedTabId) {
      clearForceSelectedTabId();
    }

    this.setState({ selectedTabId, settingsData: null });
  };

  handleChangeShownSettings = (selectedItemData: AnyFixMe) => {
    if (!selectedItemData) {
      this.handleCloseSettings();
    } else {
      this.setState((prevState) => ({
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/assign
        settingsData: _.assign(prevState.settingsData, {
          tabType: selectedItemData.tabType,
          pageId: selectedItemData.pageId,
        }),
      }));
    }
  };

  handleCloseSettings = () => this.setState({ settingsData: null });
  handleShowSettings = (settingsData: Partial<ISettingsData>) => {
    this.setState((prevState) => ({
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      settingsData: _.assign({}, prevState.settingsData, settingsData),
    }));
  };
  handleOnItemAdded = (renamedItemId: string) => {
    this.setState({ renamedItemId, settingsData: null });
  };
  getSettingsItem = () => {
    const pageId = this.state.settingsData?.pageId || this.props.selectedPageId;
    const pageData = pageId && this.props.getPageData(pageId);

    return {
      pageData,
      label: pageData?.title,
    };
  };
  updateSelectedTabIdIfDeleted = (nextProps: AnyFixMe) => {
    const id = this.state.selectedTabId;

    const isDynamicPageDeleted =
      isDynamicPagesView(id, this.props) && !isDynamicPagesView(id, nextProps);

    if (isDynamicPageDeleted) {
      this.setState({
        selectedTabId: nextProps.selectedTabId,
        nonPageItemSelected: false,
      });
    }
  };

  showSignupSettings = () => this.state.settingsData?.formType === 'signUp';

  showSignInSettings = () => this.state.settingsData?.formType === 'signIn';

  showMobileSettingsPanel = () =>
    !this.props.isDesktop && !!this.state.settingsData;

  showRegularSettings = () => {
    if (!this.state.settingsData) {
      return false;
    }

    return (
      (this.state.settingsData.pageId ||
        this.props.selectedPageIsDynamic ||
        this.props.selectedPageIsManagedByApp) &&
      !this.state.settingsData?.formType
    );
  };

  getCurrentTabHelpId = (): string => {
    // Helpid may come from different places for different panel states and we have an empty string as a default that opens help center
    const TPAhelpId = this.props.appPages.find(
      (i) => i.id === this.state.selectedTabId,
    )?.helpId;

    const pageCategories: any = Object.values(PAGE_CATEGORIES);
    const regularTabHelpId = pageCategories.find(
      (cat: AnyFixMe) => cat.id === this.state.selectedTabId,
    )?.helpId;

    return TPAhelpId || regularTabHelpId || '';
  };

  onHelpNewWorkspace = () => this.props.onHelp(this.getCurrentTabHelpId());

  renderPagesSettings = () => {
    const MainSettingsPanel = pageSettingsPanel.settingsPanels.main;
    const PageSettingsMobile = pageSettingsPanel.settingsPanels.mobile;
    const SignupSettingsPanel = pageSettingsPanel.settingsPanels.signup;
    const SignInSettingsPanel = pageSettingsPanel.settingsPanels.signIn;

    return (
      <>
        {this.showMobileSettingsPanel() ? (
          <PageSettingsMobile
            // todo: remove ts ignore when add types to PageSettingsMobile
            // @ts-expect-error -  Property 'menuItem' does not exist on type 'IntrinsicAttributes & IntrinsicClassAttributes<PageSettingsMobile> & Readonly<{}> & Readonly<{ ...; }>'
            menuItem={this.getSettingsItem()}
            closeSettingsPanel={this.handleCloseSettings}
            actions={{
              closeSettingsPanel: this.handleCloseSettings,
            }}
            key="pageSettingsMobile"
          />
        ) : null}

        {this.props.isDesktop &&
        !this.showSignupSettings() &&
        !this.showSignInSettings() &&
        this.showRegularSettings() ? (
          <MainSettingsPanel
            key="shouldShowMainSettings"
            menuItem={this.getSettingsItem()}
            menuId={this.state.settingsData?.menuId}
            activeTabType={this.state.settingsData.tabType}
            closeSettingsPanel={this.handleCloseSettings}
            token="dffdfadsfa"
            actions={{
              closeSettingsPanel: this.handleCloseSettings,
            }}
          />
        ) : null}
        {this.showSignupSettings() ? (
          <SignupSettingsPanel
            key="shouldShowSignupSettings"
            closeSettingsPanel={this.handleCloseSettings}
            navigateToVeloPanel={this.navigateToVeloPanel}
          />
        ) : null}
        {this.showSignInSettings() ? (
          // Rendering the sub menu panel
          <SignInSettingsPanel
            key="shouldShowSignInSettings"
            closeSettingsPanel={this.handleCloseSettings}
            navigateToVeloPanel={this.navigateToVeloPanel}
          />
        ) : null}
      </>
    );
  };

  navigateToVeloPanel = {
    navigate: this.handleShowSettings,
    shouldNavigateToVeloPanel: () => this.state.settingsData.menuId === 'dev',
  };

  render() {
    const {
      panelName,
      panelIndex,
      hasCustomSignupLightbox,
      hasCustomSignInLightbox,
      shouldShowDynamicPagesIntroTab,
      hasRestrictedPage,
      isMembersAreaV2Installed,
      userRouters,
      specialRouters,
      appPages,
      isDesktop,
      menus,
      biEvent,
      popups,
    } = this.props;

    const panelTabsProps = {
      panelName,
      panelIndex,
      hasCustomSignupLightbox,
      hasCustomSignInLightbox,
      shouldShowDynamicPagesIntroTab,
      hasRestrictedPage,
      isMembersAreaV2Installed,
      userRouters,
      specialRouters,
      appPages,
      isDesktop,
      menus,
      biEvent,
      popups,
      onClickTab: this.handleClickTab,
      selectedTabId: this.state.selectedTabId,
    };

    const LeftPanelFrame = this.isNewWorkspace
      ? leftBar.LeftPanelFrame
      : panels.frames.LeftPanelFrame;

    const label = this.isNewWorkspace
      ? translate('Pages_Panel_Title')
      : undefined;

    return (
      <LeftPanelFrame
        ref="pagesPanel"
        panelName={panelName}
        panelIndex={panelIndex}
        panelClass={util.cx({
          'pages-panel-pp': true,
          'pages-panel-pp-new-workspace': this.isNewWorkspace,
        })}
        className="left-panel-frame-pages-panel-pp"
        label={
          util.isAdvancedMenuOpen()
            ? translate('Site_Pages_Panel_Title')
            : label
        }
        onHelpClicked={
          this.isNewWorkspace ? this.onHelpNewWorkspace : undefined
        }
        sidePanel={this.renderPagesSettings()}
        dynamicWidth
      >
        <PanelTabs {...panelTabsProps} />
        {this.getView()}
        {/* TODO: add token */}
        {!this.isNewWorkspace && this.renderPagesSettings()}
      </LeftPanelFrame>
    );
  }
}

const Connected = connect(
  EDITOR_API,
  pagesPanelMapper.mapStateToProps,
  pagesPanelMapper.mapDispatchToProps,
)(PagesPanel);
Connected.pure = PagesPanel;
export default Connected;
