// @ts-nocheck
import _ from 'lodash';
import createReactClass from 'create-react-class';
import * as blogUtils from '../utils/blogUtils';
import * as panels from '@/panels';
import * as core from '@/core';
import React from 'react';
import * as baseUI from '@/baseUI';
import { translate } from '@/i18n';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'blogDeleteConfirmation',
  mixins: [core.mixins.editorAPIMixin],
  deleteBlog() {
    _.invoke(this.props, 'onConfirm');
    blogUtils.registerNewBlogButton(this.getEditorAPI(), {
      oldBlogDeletion: true,
    });
  },
  close() {
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  },
  render() {
    return (
      <panels.frames.ConfirmationPanelFrame
        type="important"
        panelName={this.props.panelName}
        onCancel={this.close}
        onConfirm={this.deleteBlog}
        confirmLabel="tpa_messages_delete"
        cancelLabel="tpa_messages_cancel"
        panelTitle={translate('delete_page_blog_popup_title')}
      >
        <div className="icon">
          <baseUI.symbol name="deleteBlogConfirmationIcon" />
        </div>
        <div>
          <div className="content">
            <p>{translate('delete_page_blog_popup_description')}</p>
          </div>
        </div>
      </panels.frames.ConfirmationPanelFrame>
    );
  },
});
