'use strict'

const boxSlideShowSection = require('../sections/boxSlideShow/boxSlideShowSection')
const boxSlideShow980Section = require('../sections/boxSlideShow/boxSlideShow980Section')
const stripBoxSlideShowSection = require('../sections/boxSlideShow/stripBoxSlideShowSection')

const popupSaleSection = require('../sections/popup/popupSaleSection')
const popupWelcomeSection = require('../sections/popup/popupWelcomeSection')

const hoverBoxSection = require('../sections/hoverBox/hoverBoxSection')
const stateBoxSection = require('../sections/stateBox/stateBoxSection')
const paginationSection = require('../sections/developer/paginationSection')
const progressBarSection = require('../sections/developer/progressBarSection')

module.exports = {
    boxSlideShowSection,
    boxSlideShow980Section,
    stripBoxSlideShowSection,

    popupSaleSection,
    popupWelcomeSection,

    hoverBoxSection,
    stateBoxSection,

    progressBarSection,
    paginationSection
}
