// @ts-nocheck
import * as core from '@/core';
import createReactClass from 'create-react-class';
import _ from 'lodash';
import PropTypes from 'prop-types';
import * as util from '@/util';
import * as stateManagement from '@/stateManagement';
import addPanelDataConsts from '@/addPanelDataConsts';
import * as styles from '@/styles';
import getMultiComponentAdapter from './multiComponentAdapter';

import React from 'react';
import * as compPanelInfra from '@/compPanelInfra';
import * as addPanelInfra from '@/addPanelInfra';
import AdvancedStylePanel from '../advancedStylePanelComponent';
import * as baseUI from '@/baseUI';
import { cx } from '@/util';

const advancedStyleConstants =
  styles.advancedStyle.advancedStyleConstants.multiComponents;
const { panelPosition } = stateManagement.domMeasurements.hoc;

// eslint-disable-next-line react/prefer-es6-class
const MultiComponentAdvancedStylePanel = createReactClass({
  propTypes: {
    presets: PropTypes.object,
    title: PropTypes.string,
    presetsTitle: PropTypes.string,
    onPresetChanged: PropTypes.func,
    designMapping: PropTypes.object.isRequired,
    customHelpId: PropTypes.string,
    presetHelpId: PropTypes.string,
    groupMapping: PropTypes.object.isRequired,
    shouldTranslate: PropTypes.bool,
  },
  displayName: advancedStyleConstants.displayName,
  render() {
    return (
      <compPanelInfra.compPanelFrame
        id="multiComponentAdvancedStylePanel"
        {...this.getPanelFrameProps()}
        className={cx(
          'multi-component-advanced-style-panel',
          this.getPanelFrameProps()?.className,
        )}
      >
        {!this.state.isCustomDesignOpen ? (
          <addPanelInfra.sectionWrapper
            key="presetsPanelClass"
            {...this.getSectionWrapperProps()}
            style={{
              height: '453px',
              ...this.getSectionWrapperProps()?.style,
            }}
          >
            <div className="presets-wrapper">
              {/* TODO: Fix this the next time the file is edited. */}
              {/* eslint-disable-next-line you-dont-need-lodash-underscore/map */}
              {_.map(this.getPresets(), (preset) => (
                <div
                  style={this.getPresetStyle(preset)}
                  onClick={this.onPresetClicked}
                  key={preset}
                  id={preset}
                  className="preset-image"
                />
              ))}
            </div>
            <div key="customizeDesignBtn" className="button-wrapper">
              <baseUI.button
                ref="customizeDesign"
                label="design_customize_button"
                onClick={this.openCustomizeDesign}
                icon="customize_design_drop_light"
                className="button"
              />
            </div>
          </addPanelInfra.sectionWrapper>
        ) : null}

        {this.state.isCustomDesignOpen ? (
          <div key="advanceStylePanelClass">
            <AdvancedStylePanel {...this.getAdvancedStylingProps()} />
          </div>
        ) : null}
      </compPanelInfra.compPanelFrame>
    );
  },

  mixins: [core.mixins.editorAPIMixin],
  getInitialState() {
    const editorAPI = this.getEditorAPI();
    const groupMapping = _.cloneDeep(this.props.groupMapping);
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/keys
    this.selectedComponent = _(this.props.designMapping)
      .keys()
      .map((compId) => editorAPI.components.get.byId(compId))
      .value();
    this.componentAdapter = getMultiComponentAdapter(
      editorAPI,
      this.selectedComponent,
      groupMapping,
    );
    this.styleId = advancedStyleConstants.styleId;

    this.designMapping = this.props.designMapping;
    this.skinDefByComp = this.componentAdapter.getSkinDefByComp();

    const styleByComp = this.componentAdapter.getStyleByComp();
    const style = this.componentAdapter.getStyle(
      styleByComp,
      this.skinDefByComp,
      this.designMapping,
    );

    return {
      isCustomDesignOpen: !this.props.presets,
      style,
      styleByComp,
    };
  },
  getOnBackClick() {
    const { presets } = this.props;
    const { isCustomDesignOpen } = this.state;
    const shouldShowBackClicked = presets && isCustomDesignOpen;
    return shouldShowBackClicked
      ? () => this.setState({ isCustomDesignOpen: false })
      : undefined;
  },
  getSectionWrapperProps() {
    return _.merge(advancedStyleConstants.sectionWrapperProps, {
      title: this.props.presetsTitle,
      type: addPanelDataConsts.SECTIONS_TYPES.PRESET,
    });
  },
  getHelpId() {
    const { customHelpId, presetHelpId } = this.props;
    return this.state.isCustomDesignOpen
      ? customHelpId
      : presetHelpId || advancedStyleConstants.defaultHelpId;
  },
  getPanelFrameProps() {
    return _.merge(util.panelUtils.getFrameProps(this.props), {
      title: this.props.title,
      backButtonLabel: advancedStyleConstants.backButtonTranslation,
      onBackClick: this.getOnBackClick(),
      helpId: this.getHelpId(),
      style: this.state.style,
      shouldTranslate: false,
    });
  },
  openCustomizeDesign() {
    const styleByComp = this.componentAdapter.getStyleByComp();
    const style = this.componentAdapter.getStyle(
      styleByComp,
      this.skinDefByComp,
      this.designMapping,
    );
    this.setState({ isCustomDesignOpen: true, styleByComp, style });
  },
  getStyle() {
    return this.state.style;
  },
  updateStyle(newStyle) {
    this.componentAdapter
      .updateStyle(this.designMapping, this.state.style, newStyle)
      .then(() =>
        this.setState((state) => ({
          style: _.merge({}, state.style, newStyle),
        })),
      );
  },
  onStyleParamChanged(key, val, source) {
    this.componentAdapter
      .onStyleParamChanged(this.designMapping, key, val, source)
      .then(() =>
        this.setState((state) => ({
          style: _.merge({}, state.style, {
            style: { properties: { [key]: val } },
          }),
        })),
      );
  },
  getPresetStyle(preset) {
    const { layout, src } = this.props.presets[preset];
    return _.merge(
      {
        background: `url(${src}) no-repeat`,
      },
      layout,
    );
  },
  onPresetClicked(e) {
    const presetId = e.target.id;
    this.props.onPresetChanged(presetId);
  },
  getPresets() {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/keys
    return _.keys(this.props.presets);
  },
  getAdvancedStylingProps() {
    const selectedSkin = this.componentAdapter.getSkin();
    const { style } = this.state;
    const props = _.merge({}, this.props, {
      selectedComponent: this.selectedComponent,
      shouldStartClosed: true,
      compType: advancedStyleConstants.compType,
      selectedSkin,
      skinDefinition: this.componentAdapter.getSkinDefinition(
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/keys
        _.keys(style.style.properties),
      ),
      getSkin: this.componentAdapter.getSkin,
      onStyleParamChanged: this.onStyleParamChanged,
      getStyle: this.getStyle,
      updateStyle: this.updateStyle,
      styleId: advancedStyleConstants.styleId,
      shouldTranslate: this.props.shouldTranslate,
    });

    return props;
  },
});

export default panelPosition()(MultiComponentAdvancedStylePanel);
