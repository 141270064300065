import React, { type FunctionComponent } from 'react';
// TODO: There is no "Bubble" export in @wix/wix-base-ui
// @ts-expect-error
import { Bubble } from '@wix/wix-base-ui';
import { cx } from '@/util';

import type { PositionStyle } from './dropDownTypes';

const DEFAULT_ARROW_STYLE: PositionStyle = {
  left: 'calc(50% - 8px)',
  top: '4px',
};

const DEFAULT_CONTENT_STYLE: PositionStyle = {
  left: '0',
  top: '12px',
};

interface DropDownPanelProps {
  className?: string;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  maxWidth?: number;

  panelPositionStyle: PositionStyle;
  arrowPositionStyle?: PositionStyle;
  contentPositionStyle?: PositionStyle;

  nodeRef?: (ref: Node) => void;
}

const DropDownPanel: FunctionComponent<DropDownPanelProps> = (props) => (
  <div
    className={cx('top-bar-drop-down-panel', props.className)}
    style={props.panelPositionStyle}
  >
    <Bubble
      className="tooltip"
      arrowAlignment="top"
      arrowStyle={props.arrowPositionStyle}
      contentStyle={props.contentPositionStyle}
      maxWidth={props.maxWidth}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      onLayoutChange={props.nodeRef}
    >
      <span
        className="top-bar-drop-down-panel-shutter"
        style={{ height: props.contentPositionStyle.top }}
      />
      {props.children}
    </Bubble>
  </div>
);

DropDownPanel.displayName = 'DropDownPanel';
DropDownPanel.defaultProps = {
  maxWidth: 750,
  arrowPositionStyle: DEFAULT_ARROW_STYLE,
  contentPositionStyle: DEFAULT_CONTENT_STYLE,
};

export default DropDownPanel;
