// @ts-nocheck
import addPanelDataConsts from '@/addPanelDataConsts';
import { translate } from '@/i18n';

const resourcePath = 'addPanelData/sections/imageSection/';

export default {
  type: addPanelDataConsts.SECTIONS_TYPES.IMAGE,
  hide: false,
  title: 'image_section_image_title_my_social_images',
  subNavigationTitle: 'image_section_image_title_my_social_images',
  presetTitle: 'image_section_image_title_my_social_images',
  tooltipTitle: 'image_section_image_title_my_social_images',
  subNavigationHide: false,
  showSectionHeader: true,
  props: {
    items: [
      {
        title: 'add_section_label_myfacebook',
        biItemName: 'my_facebook',
        desc: 'add_section_info_title_myfacebook',
        image: `${resourcePath}myFacebook.png`,
        openSource: 'add_panel_facebook',
        path: 'external/facebook',
        translation: {
          submitButton: translate('MMGR_submitbutton_addpanel_social_add'),
        },
      },
      {
        title: 'add_section_label_myinstagram',
        biItemName: 'my_instagram’',
        desc: 'add_section_info_title_myinstagram',
        image: `${resourcePath}myInstagram.png`,
        openSource: 'add_panel_instagram',
        path: 'external/instagram',
        translation: {
          submitButton: translate('MMGR_submitbutton_addpanel_social_add'),
        },
      },
      {
        title: 'add_section_label_myflickr',
        biItemName: 'my_flickr',
        desc: 'add_section_info_title_myflickr',
        image: `${resourcePath}myFlickr.png`,
        openSource: 'add_panel_flickr',
        path: 'external/flickr',
        translation: {
          submitButton: translate('MMGR_submitbutton_addpanel_social_add'),
        },
      },
    ],
  },
  help: {
    hide: false,
    text: 'image_section_image_my_social_images_info_text',
  },
};
