// @ts-nocheck
import _ from 'lodash';
import * as platformEvents from 'platformEvents';
import consts from '../common/constants';

function createPostMessageListener(startConfigurationPayload, panelToken) {
  return function (event) {
    if (
      event.data.intent === consts.PLATFORM_PANEL &&
      event.data.sourcePanel === panelToken
    ) {
      switch (event.data.type) {
        case consts.PLATFORM_SDK_READY:
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line you-dont-need-lodash-underscore/assign
          const payload = _.assign({}, startConfigurationPayload, {
            commonConfig: window.commonConfig,
          });
          callPostMessage(event.source, {
            intent: consts.PLATFORM_ON_EVENT,
            event: platformEvents.factory.startConfiguration(payload),
          });
          break;
        default:
          return;
      }
    }
  };
}

function callPostMessage(source, data, targetOrigin) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/is-undefined
  if (_.isUndefined(source)) {
    return;
  }
  data.event.eventPayload = _.cloneDeep(data.event.eventPayload);
  if (!source.postMessage) {
    source = source.contentWindow;
  }
  source.postMessage(data, targetOrigin || '*');
}

export { createPostMessageListener };
