import * as UA from '@/uiAnimations';
import { cx, hoc } from '@/util';
import React, { type FC } from 'react';
import styles from './openedLeftPanels.scss';
import {
  type DispatchProps,
  mapDispatchToProps,
  mapStateToProps,
  type OwnProps,
  type StateProps,
} from './openedLeftPanelsMapper';
import { useLazyLeftPanel } from './useLazyLeftPanel';
import { useStageShrink } from './useStageShrink';

export interface Props extends OwnProps, StateProps, DispatchProps {}

export const OpenedLeftPanels: FC<Props> = ({
  maxWidth,
  panelName,
  panelProps,
  onShrink,
  onStretch,
  stageBehaviour,
  isZoomedByUser,
}) => {
  const Panel = useLazyLeftPanel({ panelName });

  useStageShrink({
    stageBehaviour,
    onShrink,
    onStretch,
    isZoomedByUser,
  });

  const hasOpenedPanel = Boolean(Panel);

  return (
    <div
      className={cx({
        [styles.panel]: true,
        [styles.opened]: hasOpenedPanel,
      })}
      style={{ maxWidth }}
    >
      <UA.leftBarPanelsAnimation>
        {Panel ? <Panel key={panelName} {...panelProps} /> : null}
      </UA.leftBarPanelsAnimation>
    </div>
  );
};

export default hoc.connect(
  hoc.STORES.MOUSE_OPS,
  mapStateToProps,
  mapDispatchToProps,
)(OpenedLeftPanels) as React.ComponentType<OwnProps>;
