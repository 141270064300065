import aiPageToolsPanel from './panels/aiPageToolsPanel/aiPageToolsPanel';
import welcomePanel from './panels/welcomePanel/welcomePanel';

export { AiEntryPoint } from './scope';
export { AiApiKey } from './publicApiKey';
export type { AiPublicApi } from './api';
export type { AiScope } from './scope';

export const panels = {
  aiPageToolsPanel,
  welcomePanel,
};

export * as ui from './ui';
