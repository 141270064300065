import * as symbols from '@wix/santa-editor-symbols';
import { TextLabel } from '@wix/wix-base-ui';
import React, { type FC } from 'react';

export interface ProductItemProps {
  name: string;
  description: string;
  getLogo: any;
  id: string;
  onClick: any;
}

const ProductItem: FC<ProductItemProps> = ({
  description,
  getLogo,
  id,
  name,
  onClick,
}) => (
  <div
    className="ascend-product-item"
    onClick={onClick}
    // TODO: cleanup later
    // eslint-disable-next-line react/no-unknown-property
    automation-id={`${id}-product-item`}
  >
    <div className="logo">{getLogo().logo}</div>
    <div className="title-and-description">
      <div
        className="name"
        // TODO: cleanup later
        // eslint-disable-next-line react/no-unknown-property
        automation-id={`${id}-name`}
      >
        <TextLabel value={name} type="T19" enableEllipsis={false} />
      </div>
      <div
        className="description"
        // TODO: cleanup later
        // eslint-disable-next-line react/no-unknown-property
        automation-id={`${id}-description`}
      >
        <TextLabel value={description} type="T02" enableEllipsis={false} />
      </div>
    </div>
    <div>
      <symbols.symbol name="arrowWithStates" className="tooltip" />
    </div>
  </div>
);

export default ProductItem;
