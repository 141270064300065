import constants, { RCM_ORIGIN } from '@/constants';
import {
  contextMenu,
  domMeasurements,
  hoverBox,
  mobile,
  mouseActions,
  sections,
  selection,
  stateMapperArgsSelectors,
} from '@/stateManagement';
import { fixedStage, sections as sectionsUtils } from '@/util';
import { layoutUtils } from '@/layoutUtils';

import type { EditorAPI } from '@/editorAPI';
import type { CompRef } from 'types/documentServices';
import type {
  MapDispatchToProps,
  MapStateToProps,
  StateMapperArgs,
  ThunkAction,
} from 'types/redux';
import type { StageSideArea, StageSideAreaOwnProps } from './StageSideArea';
import type { MouseEvent } from 'react';
import type { SectionWithLayout } from '@/sections';

const {
  selectors: { getPreviewPosition, getScrollMeasurements },
} = domMeasurements;
const {
  selectors: { isDesktopEditor },
} = mobile;
const {
  actions: { setHoverBox },
} = hoverBox;
const {
  actions: { setHoveredSectionLike },
  selectors: { getHoveredSectionLikeRef },
} = sections;
const {
  selectors: { isPerformingMouseMoveAction },
} = mouseActions;

const getStageSideAreas = (
  stateMapperArgs: StateMapperArgs,
): StageSideArea[] => {
  const { state, editorAPI } = stateMapperArgs;
  const isDesktop = isDesktopEditor(state);
  const siteScale = editorAPI.getSiteScale();
  const stageXOffset = editorAPI.zoomMode.getStageXOffset();
  const widthForZoomModeAndMobile =
    sectionsUtils.constants.STAGE_SIDE_AREA_WIDTH_IN_ZOOM_MODE_AND_MOBILE;
  const height =
    (stateMapperArgsSelectors.mouseSels.isDuringMouseActionSel(stateMapperArgs)
      ? state.config.previewHeight
      : editorAPI.dsRead.site.getHeight()) * siteScale;

  if (isDesktop) {
    const previewPosition = getPreviewPosition(state);
    const { scrollLeft = 0 } = getScrollMeasurements(state);

    const isStageZoomMode = editorAPI.zoomMode.isStageZoomMode();
    const sectionSegmentWidth = isStageZoomMode
      ? widthForZoomModeAndMobile
      : constants.UI.EDITING_AREA_RIGHT_MARGIN;

    const width = isStageZoomMode
      ? stageXOffset
      : constants.UI.EDITING_AREA_RIGHT_MARGIN;

    const left = {
      left: 0,
      width,
      height,
      sectionSegmentWidth,
    };

    const right = {
      left: stageXOffset + scrollLeft + previewPosition.width * siteScale,
      width,
      height,
      sectionSegmentWidth,
    };
    return isStageZoomMode ? [left, right] : [right];
  }

  const width = editorAPI.site.getWidth();

  const left: StageSideArea = {
    left: 0,
    width: stageXOffset,
    height,
    sectionSegmentWidth: widthForZoomModeAndMobile,
  };

  const right: StageSideArea = {
    width: stageXOffset,
    left: stageXOffset + width * siteScale,
    height,
    sectionSegmentWidth: widthForZoomModeAndMobile,
  };

  return [left, right];
};

export interface StageSideAreaStateProps {
  sectionsLikeWithLayout: SectionWithLayout[];
  areas: StageSideArea[];
  hoveredSectionLikeRef: CompRef | null;
  isMobileEditor: boolean;
  isMouseMoving: boolean;
  isStageZoomMode: boolean;
  showHorizontalRuler: boolean;
  shouldRenderStageSideAreaBorder: boolean;
  hasOpenCompPanels: boolean;
}

export const mapStateToProps: MapStateToProps<
  StageSideAreaStateProps,
  StageSideAreaOwnProps
> = (stateMapperArgs: StateMapperArgs): StageSideAreaStateProps => {
  const { editorAPI, state } = stateMapperArgs;
  const {
    viewTools: { rulersEnabled },
  } = state;
  const siteScale = editorAPI.getSiteScale();
  const isInZoomMode = editorAPI.zoomMode.isInZoomMode();

  const sectionLikeGetter = isInZoomMode
    ? editorAPI.sections.getPageSectionsWithLayout
    : editorAPI.sections.getPageSectionLikeWithLayout;

  const sectionLikeWithLayout = sectionLikeGetter(
    editorAPI.pages.getCurrentPage(),
    true,
  );

  const showHorizontalRuler = rulersEnabled && !isInZoomMode;

  const hasOpenCompPanels =
    !!editorAPI.panelManager
      .getOpenPanels()
      ?.find((panel) => panel.frameType === constants.PANEL_TYPES.COMP) &&
    !isInZoomMode;

  return {
    sectionsLikeWithLayout: sectionLikeWithLayout.map((sectionWithLayout) => {
      const translateYValue = sectionsUtils.getSectionLikeTranslateY(
        editorAPI,
        sectionWithLayout.ref,
        true,
      );

      sectionWithLayout.layout.y += translateYValue;

      sectionWithLayout.layout = layoutUtils.getScaledRect(
        sectionWithLayout.layout,
        siteScale,
      );
      return sectionWithLayout;
    }),
    areas: getStageSideAreas(stateMapperArgs),
    hoveredSectionLikeRef: getHoveredSectionLikeRef(state),
    isMouseMoving: isPerformingMouseMoveAction(state),
    isMobileEditor: editorAPI.isMobileEditor(),
    isStageZoomMode: editorAPI.zoomMode.isStageZoomMode(),
    showHorizontalRuler,
    shouldRenderStageSideAreaBorder: !fixedStage.isFixedStageEnabled(),
    hasOpenCompPanels,
  };
};

const selectIfNeeded =
  (compRef: CompRef, selectionOrigin: string): ThunkAction<void> =>
  (dispatch, getState, { editorAPI }) => {
    const selectedComponents = editorAPI.selection.getSelectedComponents();

    dispatch(selection.actions.setLastSelectionClickPos(null));
    dispatch(selection.actions.setPrevComponentsAction(selectedComponents));

    if (selectedComponents[0]?.id !== compRef.id) {
      editorAPI.selection.selectComponentByCompRef(compRef, {
        origin: selectionOrigin,
      });
    }
  };

const openRightClickMenu =
  (event: MouseEvent, sectionLikeRef: CompRef): ThunkAction =>
  (dispatch) => {
    dispatch(selectIfNeeded(sectionLikeRef, RCM_ORIGIN.STAGE_SIDE_AREA));

    dispatch(
      contextMenu.actions.openContextMenu({
        editorPositionX: event.clientX,
        editorPositionY: event.clientY,
        origin: RCM_ORIGIN.STAGE_SIDE_AREA,
        biOrigin: RCM_ORIGIN.STAGE_SIDE_AREA,
      }),
    );
  };

const isSectionDragToStageInProgress = (editorAPI: EditorAPI) => {
  const registeredMouseMoveAction =
    editorAPI.mouseActions.getRegisteredMouseMoveAction();

  return (
    registeredMouseMoveAction?.type ===
    constants.MOUSE_ACTION_TYPES.ADD_SECTION_PANEL_DRAG_TO_STAGE
  );
};

const updateHoveredSectionIfNeeded =
  (comp: CompRef): ThunkAction =>
  (dispatch, getState, { editorAPI }) => {
    if (isSectionDragToStageInProgress(editorAPI)) return;
    if (
      editorAPI.zoomMode.isLeftShrinkedStageZoomOutActive() &&
      editorAPI.sections.isEmptyState() &&
      editorAPI.sections.isSection(comp)
    )
      return;
    dispatch(setHoveredSectionLike(comp));
    dispatch(setHoverBox(comp, false));
  };

export interface StageSideAreaDispatchProps {
  setHoveredSection: (ref: CompRef) => void;
  selectComponentIfNeeded: (ref: CompRef, origin: string) => void;
  openRightClickMenu: (event: MouseEvent, ref: CompRef) => void;
}

export const mapDispatchToProps: MapDispatchToProps<
  StageSideAreaDispatchProps,
  StageSideAreaOwnProps
> = {
  setHoveredSection: updateHoveredSectionIfNeeded,
  selectComponentIfNeeded: selectIfNeeded,
  openRightClickMenu,
};
