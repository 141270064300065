import * as EditorSDKModule from '@wix/platform-editor-sdk/lib/editorSDK';

import * as utils from '../common/utils';

function initializeEditorSDK() {
  const extraNamespaces = utils.getExtraNamespaces();
  // TODO: take editorType in another way
  EditorSDKModule.__initMainFrame(extraNamespaces, 'CLASSIC');
  return EditorSDKModule;
}

export { initializeEditorSDK };
