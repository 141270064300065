import { useState } from 'react';

export default function useOnlyOnce(...functions: any[]) {
  const [isCalled, setIsCalled] = useState(false);

  return functions.map((fn) => (...args: any[]) => {
    if (isCalled) {
      return;
    }

    setIsCalled(true);

    return fn(...args);
  });
}
