import React from 'react';
import * as util from '@/util';
import { isResponsiveEditor } from '@wix/santa-editor-utils';
import experiment from 'experiment';
import {
  mapDispatchToProps,
  mapStateToProps,
  type WixAppsCollectionsListDispatchProps,
  type WixAppsCollectionsListStateProps,
  type WixAppsCollectionsListOwnProps,
} from './wixAppsCollectionsList.mapper';
import CollectionsList from '../collectionsList/collectionsList';
import { useRedirectToUserCollectionsTab } from '../../hooks/useRedirectToUserCollectionsTab';
import { CollectionsGroup } from '../utils/collections-group';
import { WixAppsCollectionsEmptyState } from './WixAppsCollectionsEmptyState';

interface WixAppsCollectionsListProps
  extends WixAppsCollectionsListOwnProps,
    WixAppsCollectionsListDispatchProps,
    WixAppsCollectionsListStateProps {}

const WixAppsCollectionsList: React.FC<WixAppsCollectionsListProps> = ({
  collections,
  selectTabByName,
  openContentManagerAdvancedSettings,
}) => {
  const showWixAppsEmptyState = experiment.isOpen(
    'specs.wixCode.ShowDisabledWixAppCollections',
  );

  useRedirectToUserCollectionsTab({
    collections,
    selectTabByName,
    redirectEnabled: !showWixAppsEmptyState,
  });

  const titlesInSubTitles = isResponsiveEditor();
  const title = titlesInSubTitles
    ? undefined
    : 'CONTENT_MANAGER_6_BUTTON_WIX_APPS_COLLECTIONS_TITLE';
  const subtitle = titlesInSubTitles
    ? 'EDITORX_CONTENT_MANAGER_6_BUTTON_WIX_APPS_COLLECTIONS_SUBTITLE_WITH_TITLE'
    : 'CONTENT_MANAGER_6_BUTTON_WIX_APPS_COLLECTIONS_SUBTITLE';

  if (collections.length === 0 && showWixAppsEmptyState) {
    return (
      <WixAppsCollectionsEmptyState
        openContentManagerAdvancedSettings={openContentManagerAdvancedSettings}
      />
    );
  }

  return (
    <CollectionsList
      collections={collections}
      title={title}
      subtitle={subtitle}
      showActionButtons={false}
      showVideoTooltip={false}
      selectTabByName={selectTabByName}
      collectionsGroup={CollectionsGroup.APPS}
    />
  );
};

export default util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(WixAppsCollectionsList);
