'use strict'

const footerStyleSection = require('./footerStyleSection')
const headerStyleSection = require('./headerStyleSection')
const tinyMenuStyleSection = require('./tinyMenuStyleSection')
const quickActionBarSection = require('./quickActionBarSection')
const inlinePopupToggleStyleSection = require('./inlinePopupToggle/inlinePopupToggleStyleSection')

module.exports = {
    footerStyleSection,
    headerStyleSection,
    tinyMenuStyleSection,
    quickActionBarSection,
    inlinePopupToggleStyleSection
}
