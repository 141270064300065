'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')
const i18n = require('@/i18n')
module.exports = {
    "type": addPanelDataConsts.SECTIONS_TYPES.PRESET,
    "hide": false,
    "title": "add_section_label_socialbar",
    "subNavigationTitle": "add_section_label_socialbar",
    "presetTitle": "add_section_label_socialbar",
    "tooltipTitle": "add_section_label_socialbar",
    "automationId": "add-panel-section-socialBarSection",
    "subNavigationHide": false,
    "showSectionHeader": true,
    "additionalBehaviours": {
        "labelMode": addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
        "infoIcon": addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
        "hoverImageAction": addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
        "iconEnabledForComps": {}
    },
    "props": {
        "image": "addPanelData/sections/socialBarSection_en/socialBarSectionWithoutGooglePlus_en.png",
        "imageHover": null,
        "items": [{
            "id": "Social_Social_Bar_1",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.LinkBarNoBGSkin",
                "layout": {
                    "width": 284,
                    "height": 39,
                    "x": 19,
                    "y": 30,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.LinkBar",
                "data": {
                    "type": "ImageList",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "items": [{
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "Facebook",
                        "uri": "e316f544f9094143b9eac01f1f19e697.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": "",
                        "link": {
                            "type": "ExternalLink",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "url": i18n.translate("add_preset_social_bar_items_facebook"),
                            "target": "_blank"
                        }
                    }, {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "Twitter",
                        "uri": "9c4b521dd2404cd5a05ed6115f3a0dc8.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": "",
                        "link": {
                            "type": "ExternalLink",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "url": i18n.translate("add_preset_social_bar_items_twitter"),
                            "target": "_blank"
                        }
                    }, {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "YouTube",
                        "uri": "a1b09fe8b7f04378a9fe076748ad4a6a.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": "",
                        "link": {
                            "type": "ExternalLink",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "url": i18n.translate("add_preset_social_bar_items_youtube"),
                            "target": "_blank"
                        }
                    }, {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "Pinterest",
                        "uri": "28e77d0b179d4121891d847ed43de6cc.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": "",
                        "link": {
                            "type": "ExternalLink",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "url": i18n.translate("add_preset_social_bar_items_pinterest"),
                            "target": "_blank"
                        }
                    }, {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "Tumblr Social Icon",
                        "uri": "960fa430622c4b41a07c2c86948f87fb.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": "",
                        "link": {
                            "type": "ExternalLink",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "url": i18n.translate("add_preset_social_bar_items_tumblr"),
                            "target": "_blank"
                        }
                    }, {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "Instagram",
                        "uri": "8d6893330740455c96d218258a458aa4.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": "",
                        "link": {
                            "type": "ExternalLink",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "url": i18n.translate("add_preset_social_bar_items_instagram"),
                            "target": "_blank"
                        }
                    }]
                },
                "props": {
                    "type": "LinkBarProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "gallery": "social_icons",
                    "iconSize": 39,
                    "spacing": 10,
                    "bgScale": 1,
                    "orientation": "HORIZ"
                },
                "style": {
                    "type": "TopLevelStyle",
                    "id": "lb1",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {"displayer": {}}},
                    "componentClassName": "",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.viewer.skins.LinkBarNoBGSkin"
                },
                "activeModes": {},
                "id": "comp-jk10yvgb"
            },
            "preset": {"rect": {"width": 324, "height": 84, "x": 0, "y": 0}, "tags": null}
        }, {
            "id": "Social_Social_Bar_2",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.LinkBarNoBGSkin",
                "layout": {
                    "width": 287,
                    "height": 42,
                    "x": 20,
                    "y": 99,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.LinkBar",
                "data": {
                    "type": "ImageList",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "items": [{
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "Facebook",
                        "uri": "aebe5b6fd55f471a936c72ff2c8289d7.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": "",
                        "link": {
                            "type": "ExternalLink",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "url": i18n.translate("add_preset_social_bar_items_facebook"),
                            "target": "_blank"
                        }
                    }, {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "Twitter",
                        "uri": "aeb686ff41244d85bc1232fcf7325ec0.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": "",
                        "link": {
                            "type": "ExternalLink",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "url": i18n.translate("add_preset_social_bar_items_twitter"),
                            "target": "_blank"
                        }
                    }, {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "YouTube",
                        "uri": "a1fed5f687844a6e8f42e017f71dfcb4.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": "",
                        "link": {
                            "type": "ExternalLink",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "url": i18n.translate("add_preset_social_bar_items_youtube"),
                            "target": "_blank"
                        }
                    }, {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "Pinterest",
                        "uri": "9829b3f85ef647adb29e05b1a70208ba.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": "",
                        "link": {
                            "type": "ExternalLink",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "url": i18n.translate("add_preset_social_bar_items_pinterest"),
                            "target": "_blank"
                        }
                    }, {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "Tumblr Social Icon",
                        "uri": "cb4f5a4e9d894b6780fc8ec1a0faa63f.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": "",
                        "link": {
                            "type": "ExternalLink",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "url": i18n.translate("add_preset_social_bar_items_tumblr"),
                            "target": "_blank"
                        }
                    }, {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "Instagram",
                        "uri": "40898a93cfff4578b1779073137eb1b4.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": "",
                        "link": {
                            "type": "ExternalLink",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "url": i18n.translate("add_preset_social_bar_items_instagram"),
                            "target": "_blank"
                        }
                    }]
                },
                "props": {
                    "type": "LinkBarProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "gallery": "social_icons",
                    "iconSize": 42,
                    "spacing": 7,
                    "bgScale": 1,
                    "orientation": "HORIZ"
                },
                "style": {
                    "type": "TopLevelStyle",
                    "id": "lb1",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {"displayer": {}}},
                    "componentClassName": "",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.viewer.skins.LinkBarNoBGSkin"
                },
                "activeModes": {},
                "id": "comp-jk10yvgo1"
            },
            "preset": {"rect": {"width": 324, "height": 62, "x": 0, "y": 84}, "tags": null}
        }, {
            "id": "Social_Social_Bar_3",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.LinkBarNoBGSkin",
                "layout": {
                    "width": 287,
                    "height": 42,
                    "x": 20,
                    "y": 167,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.LinkBar",
                "data": {
                    "type": "ImageList",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "items": [{
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "Facebook",
                        "uri": "0fdef751204647a3bbd7eaa2827ed4f9.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": "",
                        "link": {
                            "type": "ExternalLink",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "url": i18n.translate("add_preset_social_bar_items_facebook"),
                            "target": "_blank"
                        }
                    }, {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "Twitter",
                        "uri": "c7d035ba85f6486680c2facedecdcf4d.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": "",
                        "link": {
                            "type": "ExternalLink",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "url": i18n.translate("add_preset_social_bar_items_twitter"),
                            "target": "_blank"
                        }
                    }, {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "YouTube",
                        "uri": "78aa2057f0cb42fbbaffcbc36280a64a.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": "",
                        "link": {
                            "type": "ExternalLink",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "url": i18n.translate("add_preset_social_bar_items_youtube"),
                            "target": "_blank"
                        }
                    }, {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "Pinterest",
                        "uri": "8f6f59264a094af0b46e9f6c77dff83e.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": "",
                        "link": {
                            "type": "ExternalLink",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "url": i18n.translate("add_preset_social_bar_items_pinterest"),
                            "target": "_blank"
                        }
                    }, {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "Tumblr Social Icon",
                        "uri": "c32234fc66894bc4ae7a4003deef86fb.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": "",
                        "link": {
                            "type": "ExternalLink",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "url": i18n.translate("add_preset_social_bar_items_tumblr"),
                            "target": "_blank"
                        }
                    }, {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "Instagram",
                        "uri": "01c3aff52f2a4dffa526d7a9843d46ea.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": "",
                        "link": {
                            "type": "ExternalLink",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "url": i18n.translate("add_preset_social_bar_items_instagram"),
                            "target": "_blank"
                        }
                    }]
                },
                "props": {
                    "type": "LinkBarProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "gallery": "social_icons",
                    "iconSize": 42,
                    "spacing": 7,
                    "bgScale": 1,
                    "orientation": "HORIZ"
                },
                "style": {
                    "type": "TopLevelStyle",
                    "id": "lb1",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {"displayer": {}}},
                    "componentClassName": "",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.viewer.skins.LinkBarNoBGSkin"
                },
                "activeModes": {},
                "id": "comp-jk10yvgy"
            },
            "preset": {"rect": {"width": 324, "height": 78, "x": -1, "y": 151}, "tags": null}
        }, {
            "id": "Social_Social_Bar_4",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.LinkBarNoBGSkin",
                "layout": {
                    "width": 284,
                    "height": 39,
                    "x": 18,
                    "y": 231,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.LinkBar",
                "data": {
                    "type": "ImageList",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "items": [{
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "Facebook",
                        "uri": "ce6ec7c11b174c0581e20f42bb865ce3.png",
                        "width": 199,
                        "height": 199,
                        "alt": "",
                        "link": {
                            "type": "ExternalLink",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "url": i18n.translate("add_preset_social_bar_items_facebook"),
                            "target": "_blank"
                        }
                    }, {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "Twitter",
                        "uri": "444f49eac2e348f89128293b0c6432fd.png",
                        "width": 199,
                        "height": 199,
                        "alt": "",
                        "link": {
                            "type": "ExternalLink",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "url": i18n.translate("add_preset_social_bar_items_twitter"),
                            "target": "_blank"
                        }
                    }, {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "YouTube",
                        "uri": "71ac09a5a92848cc943bf2ca2a09a6d0.png",
                        "width": 199,
                        "height": 198,
                        "alt": "",
                        "link": {
                            "type": "ExternalLink",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "url": i18n.translate("add_preset_social_bar_items_youtube"),
                            "target": "_blank"
                        }
                    }, {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "Pinterest",
                        "uri": "2891096df79f43829b310ad815d42011.png",
                        "width": 199,
                        "height": 199,
                        "alt": "",
                        "link": {
                            "type": "ExternalLink",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "url": i18n.translate("add_preset_social_bar_items_pinterest"),
                            "target": "_blank"
                        }
                    }, {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "Tumblr Social Icon",
                        "uri": "244c859def564d958dd68cdec45efa65.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": "",
                        "link": {
                            "type": "ExternalLink",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "url": i18n.translate("add_preset_social_bar_items_tumblr"),
                            "target": "_blank"
                        }
                    }, {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "Instagram",
                        "uri": "fdcfaba150fc427da298a00cb09d91c1.png",
                        "width": 200,
                        "height": 200,
                        "alt": "",
                        "link": {
                            "type": "ExternalLink",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "url": i18n.translate("add_preset_social_bar_items_instagram"),
                            "target": "_blank"
                        }
                    }]
                },
                "props": {
                    "type": "LinkBarProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "gallery": "social_icons",
                    "iconSize": 39,
                    "spacing": 10,
                    "bgScale": 1,
                    "orientation": "HORIZ"
                },
                "style": {
                    "type": "TopLevelStyle",
                    "id": "lb1",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {"displayer": {}}},
                    "componentClassName": "",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.viewer.skins.LinkBarNoBGSkin"
                },
                "activeModes": {},
                "id": "comp-jk10yvgl1"
            },
            "preset": {"rect": {"width": 324, "height": 68, "x": 0, "y": 219}, "tags": null}
        }, {
            "id": "Social_Social_Bar_5",
            "structure": {
                "type": "Component",
                "skin": "wysiwyg.viewer.skins.LinkBarNoBGSkin",
                "layout": {
                    "width": 284,
                    "height": 39,
                    "x": 18,
                    "y": 291,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.LinkBar",
                "data": {
                    "type": "ImageList",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "items": [{
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "Facebook",
                        "uri": "4057345bcf57474b96976284050c00df.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": "",
                        "link": {
                            "type": "ExternalLink",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "url": i18n.translate("add_preset_social_bar_items_facebook"),
                            "target": "_blank"
                        }
                    }, {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "Twitter",
                        "uri": "870f97661ed14a5bb2d96ecbddec0aed.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": "",
                        "link": {
                            "type": "ExternalLink",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "url": i18n.translate("add_preset_social_bar_items_twitter"),
                            "target": "_blank"
                        }
                    }, {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "YouTube",
                        "uri": "45bce1d726f64f1999c49feae57f6298.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": "",
                        "link": {
                            "type": "ExternalLink",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "url": i18n.translate("add_preset_social_bar_items_youtube"),
                            "target": "_blank"
                        }
                    }, {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "Pinterest",
                        "uri": "9e47c827082f40bdb54d0cd16c3b28f6.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": "",
                        "link": {
                            "type": "ExternalLink",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "url": i18n.translate("add_preset_social_bar_items_pinterest"),
                            "target": "_blank"
                        }
                    }, {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "Tumblr Social Icon",
                        "uri": "d355bf5ac7e7402a97242531dff2e30e.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": "",
                        "link": {
                            "type": "ExternalLink",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "url": i18n.translate("add_preset_social_bar_items_tumblr"),
                            "target": "_blank"
                        }
                    }, {
                        "type": "Image",
                        "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                        "title": "Instagram",
                        "uri": "e1aa082f7c0747168d9cf43e77046142.png",
                        "description": "",
                        "width": 200,
                        "height": 200,
                        "alt": "",
                        "link": {
                            "type": "ExternalLink",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "url": i18n.translate("add_preset_social_bar_items_instagram"),
                            "target": "_blank"
                        }
                    }]
                },
                "props": {
                    "type": "LinkBarProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "gallery": "social_icons",
                    "iconSize": 39,
                    "spacing": 10,
                    "bgScale": 1,
                    "orientation": "HORIZ"
                },
                "style": {
                    "type": "TopLevelStyle",
                    "id": "lb1",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {"displayer": {}}},
                    "componentClassName": "",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "wysiwyg.viewer.skins.LinkBarNoBGSkin"
                },
                "activeModes": {},
                "id": "comp-jk10yvgi"
            },
            "preset": {"rect": {"width": 324, "height": 68, "x": 0, "y": 281}, "tags": null}
        }],
        "compTypes": ["wysiwyg.viewer.components.LinkBar"]
    },
    "help": {"hide": false, "text": "add_section_info_text_Socialbar"}
}
