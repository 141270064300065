'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')
const mapDragging = true
const util = require('@/util')
const languageCode = util.languages.getLanguageCode()

module.exports = {
    "type": addPanelDataConsts.SECTIONS_TYPES.PRESET,
    "hide": false,
    "title": "add_section_label_googlemap",
    "automationId": "add-panel-section-googleMapsSection",
    "showSectionHeader": true,
    "additionalBehaviours": {
        "labelMode": addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
        "infoIcon": addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
        "hoverImageAction": addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
        "iconEnabledForComps": {}
    },
    "props": {
        "image": "addPanelData/sections/googleMapsSection_en/googleMapsSection_en.png",
        "imageHover": null,
        "items": [
            {
                "id": "Contact_Google_Maps_1",
                "structure": {
                    "type": "Component",
                    "skin": "wysiwyg.viewer.skins.GoogleMapSkin",
                    "layout": {
                        "width": 608,
                        "height": 280,
                        "x": 20,
                        "y": 30,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.GoogleMap",
                    "data": {
                        "type": "GeoMap",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "locations": [{
                            "address": "500 Terry Francois Street, 6th Floor. San Francisco, CA 94158",
                            "title": "Wix Offices",
                            "latitude": 37.77037420000001,
                            "longitude": -122.38726910000003,
                            "description": "",
                            "link": null,
                            "linkTitle": "",
                            "type": "GeoMapLocation",
                            "pinIcon": util.media.getMediaUrl('designPanel/panelTypes/google_maps_pin_icon.png'),
                            "pinColor": ""
                        }],
                        "defaultLocation": 0
                    },
                    "props": {
                        "type": "GoogleMapProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "showZoom": true,
                        "showPosition": true,
                        "showStreetView": true,
                        "showMapType": true,
                        mapDragging,
                        "mapType": "ROADMAP",
                        "language": languageCode
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "id": "gm1",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {},
                            "propertiesSource": {}
                        },
                        "componentClassName": "",
                        "pageId": "",
                        "compId": "",
                        "styleType": "system",
                        "skin": "wysiwyg.viewer.skins.GoogleMapSkin"
                    },
                    "id": "i9b5told"
                },
                "preset": {
                    "rect": {
                        "width": 324,
                        "height": 100,
                        "x": 0,
                        "y": 0
                    },
                    "tags": null
                }
            },
            {
                "id": "Contact_Google_Maps_2",
                "structure": {
                    "type": "Component",
                    "skin": "wysiwyg.viewer.skins.map.GoogleMapDefault",
                    "layout": {
                        "width": 608,
                        "height": 280,
                        "x": 20,
                        "y": 230,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.GoogleMap",
                    "data": {
                        "type": "GeoMap",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "locations": [{
                            "address": "500 Terry Francois Street, 6th Floor. San Francisco, CA 94158",
                            "title": "Wix Offices",
                            "latitude": 37.77037420000001,
                            "longitude": -122.38726910000003,
                            "description": "",
                            "link": null,
                            "linkTitle": "",
                            "type": "GeoMapLocation",
                            "pinIcon": util.media.getMediaUrl('designPanel/panelTypes/google_maps_pin_icon.png'),
                            "pinColor": ""
                        }],
                        "defaultLocation": 0
                    },
                    "props": {
                        "type": "GoogleMapProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "showZoom": true,
                        "showPosition": true,
                        "showStreetView": true,
                        "showMapType": true,
                        mapDragging,
                        "mapType": "ROADMAP",
                        "language": languageCode
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-brd": "1",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "#2A2A2A",
                                "brw": "6px",
                                "rd": "0px",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                            },
                            "propertiesSource": {
                                "brd": "value",
                                "brw": "value",
                                "rd": "value",
                                "shd": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.viewer.components.GoogleMap",
                        "pageId": "",
                        "compId": "i9b5toli",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.map.GoogleMapDefault"
                    },
                    "id": "i9b5toli"
                },
                "preset": {
                    "rect": {
                        "width": 324,
                        "height": 100,
                        "x": 0,
                        "y": 100
                    },
                    "tags": null
                }
            },
            {
                "id": "Contact_Google_Maps_3",
                "structure": {
                    "type": "Component",
                    "skin": "wysiwyg.viewer.skins.map.GoogleMapDefault",
                    "layout": {
                        "width": 608,
                        "height": 280,
                        "x": 20,
                        "y": 430,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.GoogleMap",
                    "data": {
                        "type": "GeoMap",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "locations": [{
                            "address": "500 Terry Francois Street, 6th Floor. San Francisco, CA 94158",
                            "title": "Wix Offices",
                            "latitude": 37.77037420000001,
                            "longitude": -122.38726910000003,
                            "description": "",
                            "link": null,
                            "linkTitle": "",
                            "type": "GeoMapLocation",
                            "pinIcon": util.media.getMediaUrl('designPanel/panelTypes/google_maps_pin_icon.png'),
                            "pinColor": ""
                        }],
                        "defaultLocation": 0
                    },
                    "props": {
                        "type": "GoogleMapProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "showZoom": true,
                        "showPosition": true,
                        "showStreetView": true,
                        "showMapType": true,
                        mapDragging,
                        "mapType": "ROADMAP",
                        "language": languageCode
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-brd": "1",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "#3D9BE9",
                                "brw": "6px",
                                "rd": "0px",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                            },
                            "propertiesSource": {
                                "brd": "value",
                                "brw": "value",
                                "rd": "value",
                                "shd": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.viewer.components.GoogleMap",
                        "pageId": "",
                        "compId": "i9b5tolh",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.map.GoogleMapDefault"
                    },
                    "id": "i9b5tolh"
                },
                "preset": {
                    "rect": {
                        "width": 324,
                        "height": 100,
                        "x": 0,
                        "y": 200
                    },
                    "tags": null
                }
            },
            {
                "id": "Contact_Google_Maps_4",
                "structure": {
                    "type": "Component",
                    "skin": "wysiwyg.viewer.skins.map.GoogleMapDefault",
                    "layout": {
                        "width": 608,
                        "height": 280,
                        "x": 20,
                        "y": 630,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.GoogleMap",
                    "data": {
                        "type": "GeoMap",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "locations": [{
                            "address": "500 Terry Francois Street, 6th Floor. San Francisco, CA 94158",
                            "title": "Wix Offices",
                            "latitude": 37.77037420000001,
                            "longitude": -122.38726910000003,
                            "description": "",
                            "link": null,
                            "linkTitle": "",
                            "type": "GeoMapLocation",
                            "pinIcon": util.media.getMediaUrl('designPanel/panelTypes/google_maps_pin_icon.png'),
                            "pinColor": ""
                        }],
                        "defaultLocation": 0
                    },
                    "props": {
                        "type": "GoogleMapProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "showZoom": true,
                        "showPosition": true,
                        "showStreetView": true,
                        "showMapType": true,
                        mapDragging,
                        "mapType": "ROADMAP",
                        "language": languageCode
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-brd": "1",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "#01B1AF",
                                "brw": "6px",
                                "rd": "0px",
                                "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                            },
                            "propertiesSource": {
                                "brd": "value",
                                "brw": "value",
                                "rd": "value",
                                "shd": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.viewer.components.GoogleMap",
                        "pageId": "",
                        "compId": "i9b5tolj_0",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.map.GoogleMapDefault"
                    },
                    "id": "i9b5tolj_0"
                },
                "preset": {
                    "rect": {
                        "width": 324,
                        "height": 100,
                        "x": 0,
                        "y": 300
                    },
                    "tags": null
                }
            },
            {
                "id": "Contact_Google_Maps_5",
                "structure": {
                    "type": "Component",
                    "skin": "wysiwyg.viewer.skins.map.GoogleMapDefault",
                    "layout": {
                        "width": 608,
                        "height": 280,
                        "x": 20,
                        "y": 830,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.GoogleMap",
                    "data": {
                        "type": "GeoMap",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "locations": [{
                            "address": "500 Terry Francois Street, 6th Floor. San Francisco, CA 94158",
                            "title": "Wix Offices",
                            "latitude": 37.77065,
                            "longitude": -122.387301,
                            "description": "",
                            "link": null,
                            "linkTitle": "",
                            "type": "GeoMapLocation",
                            "pinIcon": util.media.getMediaUrl('designPanel/panelTypes/google_maps_pin_icon.png'),
                            "pinColor": ""
                        }],
                        "defaultLocation": 0
                    },
                    "props": {
                        "type": "GoogleMapProperties",
                        "metaData": {
                            "schemaVersion": "1.0"
                        },
                        "showZoom": true,
                        "showPosition": true,
                        "showStreetView": true,
                        "showMapType": true,
                        mapDragging,
                        "mapType": "ROADMAP",
                        "language": languageCode
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {
                            "isPreset": false,
                            "schemaVersion": "1.0",
                            "isHidden": false
                        },
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-brd": "1",
                                "boxShadowToggleOn-shd": "true",
                                "brd": "#FFFFFF",
                                "brw": "6px",
                                "rd": "0px",
                                "shd": "2px 2px 4px 0px rgba(0,0,0,0.3)"
                            },
                            "propertiesSource": {
                                "brd": "value",
                                "brw": "value",
                                "rd": "value",
                                "shd": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.viewer.components.GoogleMap",
                        "pageId": "",
                        "compId": "i9b5tolk",
                        "styleType": "custom",
                        "skin": "wysiwyg.viewer.skins.map.GoogleMapDefault"
                    },
                    "id": "i9b5tolk"
                },
                "preset": {
                    "rect": {
                        "width": 324,
                        "height": 100,
                        "x": 0,
                        "y": 400
                    },
                    "tags": null
                }
            }
        ],
        "compTypes": ["wysiwyg.viewer.components.GoogleMap"]
    },
    "help": {"hide": false, "text": "add_section_info_text_googlemap"}
}
