import TitleWithListTemplate from '../common/titleWithListTemplate';
import React from 'react';
import dataProvider from '../../dataProvider';
import { createProduct } from '../common/utils';
import * as util from '@/util';
import * as utils from '../common/utils';
import type { Product } from '../common/types';
import ascendBi from '../common/bi/ascendBi';
import * as stateManagement from '@/stateManagement';
import SectionTitle from '../common/sectionTitle';

interface FinancePanelProps {
  createFinanceProduct(name: string): Product;
  navigateToFinanceHome(): void;
  sendBi(event: object, parameters: object): void;
  biBaseParams: any;
}

class FinancePanel extends React.Component<FinancePanelProps> {
  componentDidMount() {
    const { sendBi, biBaseParams } = this.props;

    sendBi(ascendBi.events.TAB_VIEW, {
      ...biBaseParams,
      tab_name: 'Finance',
    });
  }
  render() {
    return (
      <TitleWithListTemplate
        title="AscendButton_Finance_Title"
        subtitle="AscendButton_Finance_Text"
        sectionTitle={
          <SectionTitle title="AscendButton_Finance_Tools_SectionLabel" />
        }
        items={dataProvider.financeProductItemIds
          .map((name) => this.props.createFinanceProduct(name))
          .filter((product) => product.isAvailable())}
      />
    );
  }
}

const getEditorAPI = (
  dispatch: AnyFixMe,
  getState: AnyFixMe,
  { editorAPI }: AnyFixMe,
) => editorAPI;

const mapStateToProps = ({ editorAPI, state }: AnyFixMe) => {
  const biBaseParams = ascendBi.biUtils.getBiBaseParams({ editorAPI, state });

  return {
    biBaseParams,
  };
};

const mapDispatchToProps = (dispatch: AnyFixMe) => {
  const editorAPI = dispatch(getEditorAPI);
  return {
    enrichCreateFinanceProduct: (bi: AnyFixMe) => (name: AnyFixMe) =>
      createProduct('Finance', name, editorAPI, bi),
    navigateToFinanceHome: () =>
      utils.navigateToAscend(editorAPI, 'financeHome'),
    sendBi: (event: AnyFixMe, parameters: AnyFixMe) =>
      dispatch(stateManagement.bi.actions.event(event, parameters)),
  };
};

const mergeProps = (
  stateProps: AnyFixMe,
  dispatchProps: AnyFixMe,
  ownProps: AnyFixMe,
) => {
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    createFinanceProduct: dispatchProps.enrichCreateFinanceProduct({
      sendBi: dispatchProps.sendBi,
      biBaseParams: stateProps.biBaseParams,
    }),
  };
};

const ConnectedComponent = util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(FinancePanel);
ConnectedComponent.pure = FinancePanel;

export default ConnectedComponent;
