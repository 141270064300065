// @ts-nocheck
import constants from '@/constants';

const { ACTIONS } = constants.ROOT_COMPS.GFPP;

export default {
  mainActions: [],
  enabledActions: [ACTIONS.SETTINGS, ACTIONS.DESIGN, ACTIONS.HELP],
  mobileEnabledActions: [ACTIONS.HIDE, ACTIONS.HELP],
  presetActions: {
    help: {
      helpId: '123', //TODO - change this
    },
  },
};
