import type {
  CompRef,
  CompStructure,
  DocumentServicesObject,
} from '@wix/document-services-types';
import { getComponentData } from './extractComponentData';

const keyBy = (array: any[], key: any) =>
  array.reduce((r: any, x: any) => ({ ...r, [key ? x[key] : x]: x }), {});

const calculateVisibility = (
  type: string,
  compDesign: CompStructure['design'],
  compStyle: CompStructure['style'],
) => {
  if (type === 'Container') {
    const compDesignBackground = compDesign?.background;
    // @ts-ignore
    const compStyleProperties = compStyle?.properties;

    const hasVisibleBackgroundColor =
      compDesignBackground?.color && compDesignBackground?.colorOpacity > 0;
    const hasVisibleBackgroundImage =
      compDesignBackground?.mediaRef && compDesignBackground?.mediaRef?.opacity;
    const hasVisibleStyleBackground =
      compStyleProperties?.bg &&
      parseFloat(compStyleProperties?.['alpha-bg']) > 0;
    const hasVisibleStyleBorder =
      parseFloat(compStyleProperties?.brw) > 0 &&
      parseFloat(compStyleProperties?.['alpha-brd']) > 0;
    const hasVisibleStyleBoxShadow =
      compStyleProperties?.['boxShadowToggleOn-shd'] !== 'false';

    return (
      hasVisibleBackgroundColor ||
      hasVisibleBackgroundImage ||
      hasVisibleStyleBackground ||
      hasVisibleStyleBorder ||
      hasVisibleStyleBoxShadow
    );
  }

  return true;
};

const isComponentIn = (
  documentServices: DocumentServicesObject,
  componentId: string,
) => {
  const checked: Record<string, boolean> = {};
  const checkComponent = (componentRef: CompRef): boolean => {
    if (!componentRef || componentRef.id === 'masterPage') {
      return false;
    }

    if (componentRef.id === componentId) {
      return true;
    }

    if (componentRef.id in checked) {
      return checked[componentRef.id];
    }

    const parent = documentServices.components.getContainer(componentRef);
    const parentsCheck = checkComponent(parent);

    checked[componentRef.id] = parentsCheck;

    return parentsCheck;
  };

  return checkComponent;
};

export const extractSiteData = (
  documentServices: DocumentServicesObject,
  currentPageId: string,
  components: Array<any>,
) => {
  const componentsIds = new Set(components.map((compRef) => compRef.id));
  const masterPageId = documentServices.pages.getMasterPageId();
  const masterPageRef = documentServices.components.get.byId(
    masterPageId,
  ) as CompRef;
  const homePageId = documentServices.homePage.get();
  const fontMap = Object.values(documentServices.theme.fonts.getAll());

  const isComponentInHeader = isComponentIn(documentServices, 'SITE_HEADER');

  const componentsList = components.map((compRef) => {
    const compData = documentServices.components.data.get(compRef);
    const componentType = documentServices.components.getType(compRef);
    const compDesign = documentServices.components.design.get(compRef);
    const compLayout = documentServices.components.layout.get(compRef);
    const compStyle = documentServices.components.style.get(compRef);
    const parentRef = documentServices.components.getContainer(compRef);
    const { type } = documentServices.components.getDefinition(componentType);
    const parentPage = documentServices.components.getPage(compRef);
    const childrenComponents = documentServices.components
      .getChildren(compRef)
      .map(({ id }: any) => id)
      .filter((id: string) => componentsIds.has(id));
    const url = compData?.uri || compDesign?.background?.mediaRef?.uri || null;

    return {
      id: compRef.id,
      type: type,
      parent: parentRef?.id,
      componentType:
        componentType === 'wysiwyg.viewer.components.tpapps.TPAMultiSection' // for some reason TPAMultiSection is ignored in prediction prime
          ? 'wysiwyg.viewer.components.tpapps.TPASection'
          : componentType, // TODO investigate it with Prediction Prime team
      data: getComponentData(compData),
      url,
      pageId: parentPage.id,
      components: childrenComponents,
      // @ts-ignore
      fixed_position: Boolean(compLayout.fixedPosition),
      is_visible: calculateVisibility(type, compDesign, compStyle),
      in_master: parentPage.id === masterPageId,
      in_header: isComponentInHeader(compRef),
      in_footer: false, // SITE_FOOTER is skipped in calculation
      is_responsive: false,
    };
  });

  const siteDataStructure: Record<string, any> = {
    [masterPageId]: {
      id: masterPageId,
      pageId: masterPageId,
      type: 'Document',
      parent: 'SITE_ROOT',
      componentType: 'mobile.core.components.MasterPage',
      fixed_position: false,
      data: null,
      is_visible: true,
      url: null,
      in_master: false,
      in_header: false,
      in_footer: false,
      is_responsive: false,
      components: documentServices.components
        .getChildren(masterPageRef)
        .map(({ id }: any) => id),
    },
    ...keyBy(componentsList, 'id'),
  };

  return {
    components: siteDataStructure,
    globalData: {
      currentPageId,
      homePageId,
      isHomePage: currentPageId === homePageId,
      isTemplate: documentServices.generalInfo.isTemplate(),
      fontMap,
    },
  };
};
