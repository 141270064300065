import * as stateManagement from '@/stateManagement';
import * as platform from '@/platform';
import constants from '@/constants';
import getWidgetDesignComponentAdapter from './widgetDesignComponentAdapter';

import type {
  CompRef,
  BIEventDefinition,
  BIEventFields,
} from '@wix/document-services-types';

import type { WidgetDesignTab } from '@wix/platform-editor-sdk';

import type { Dispatch, MapDispatchToProps, ThunkAction } from 'types/redux';
import type { DSRead } from 'types/documentServices';
import type { EditorAPI } from '@/editorAPI';
import type {
  WidgetDesignPanelProps,
  WidgetDesignProps,
  WRichTextSettingsPanelProps,
} from './types';
import * as util from '@/util';

const { boxSlideShowUtils } = util;
const { isBoxSlideShowType, getComponentsInCurrentSlide } = boxSlideShowUtils;

const closeTextEditor = (dispatch: Dispatch) => {
  dispatch(stateManagement.text.actions.closeTextEditor());
  dispatch(stateManagement.text.actions.textEditor.clearWidgetDesignState());
};

interface WidgetDesignDispatchProps {
  biEvent: (eventType: BIEventDefinition, params: BIEventFields) => void;
  handleHighlight: (compRefs: CompRef[]) => void;
  clearHighlights: (compRefs: CompRef[]) => void;
  loadTextEditor: (
    compRefs: CompRef[],
    panelProps: WRichTextSettingsPanelProps,
  ) => void;
  onBackFromTextPanel: (panelProps: WidgetDesignPanelProps) => void;
  onCloseTextPanel: () => void;
  getCurrentPresetId: (widgetRef: CompRef) => Promise<string | void>;
}

const getEditorAPI: ThunkAction<EditorAPI> = (
  dispatch,
  getState,
  { editorAPI },
) => editorAPI;

export const mapDispatchToProps: MapDispatchToProps<
  WidgetDesignDispatchProps,
  WidgetDesignProps
> = (dispatch: Dispatch) => {
  const editorAPI: EditorAPI = dispatch(getEditorAPI);
  const dsRead: DSRead = editorAPI.dsRead;

  return {
    biEvent(eventType: BIEventDefinition, params: BIEventFields) {
      dispatch(stateManagement.bi.actions.event(eventType, params));
    },
    handleHighlight(compRefs: CompRef[]) {
      dispatch(
        stateManagement.componentLocator.actions.locateComponentAndHighlightMultiComponents(
          compRefs.filter((comp) => {
            return (
              !platform.renderUtils.isGhostCollapsed(editorAPI, comp) &&
              platform.renderUtils.isRendered(editorAPI, comp) &&
              platform.renderUtils.hasVolume(editorAPI, comp)
            );
          }),
          false,
        ),
      );
    },
    clearHighlights(compRefs) {
      dispatch(
        stateManagement.highlights.actions.removeCompsFromHighlights(compRefs),
      );
    },
    loadTextEditor(
      compRefs: CompRef[],
      panelProps: WRichTextSettingsPanelProps,
    ) {
      dispatch(stateManagement.panels.actions.closeOpenedPanels());
      dispatch(
        stateManagement.text.actions.textEditor.loadFromWidgetDesign(
          compRefs,
          panelProps,
        ),
      );
      dispatch(stateManagement.text.actions.openTextEditor(true));
    },
    onBackFromTextPanel(panelProps: WidgetDesignPanelProps) {
      closeTextEditor(dispatch);
      dispatch(
        stateManagement.panels.actions.openComponentPanel(
          platform.widgetDesignUtils.WIDGET_DESIGN_COMP_STYLE_PANEL,
          panelProps,
        ),
      );
    },
    onCloseTextPanel() {
      closeTextEditor(dispatch);
      dispatch(stateManagement.panels.actions.closeOpenedPanels());
    },
    isTextComponent(selectedComponent: CompRef): boolean {
      return Boolean(
        dsRead.components.getType(selectedComponent) ===
          constants.COMP_TYPES.TEXT,
      );
    },
    isStylableComponent(selectedComponent: CompRef): boolean {
      return Boolean(
        stateManagement.components.selectors.isStylable(
          selectedComponent,
          dsRead,
        ),
      );
    },
    getCompType(selectedComponent: CompRef): string {
      return dsRead.components.getType(selectedComponent);
    },
    getWidgetDesignComponentAdapter(
      tabs: WidgetDesignTab[],
      connectedComponents: CompRef[],
    ): any {
      return getWidgetDesignComponentAdapter({
        editorAPI,
        tabs,
        connectedComponents,
      });
    },
    isBoxSlideShowType(containerRef: CompRef): boolean {
      return isBoxSlideShowType(dsRead, containerRef);
    },
    getComponentsInCurrentSlide(containerRef: CompRef): any {
      return getComponentsInCurrentSlide(dsRead, containerRef);
    },
    async getCurrentPresetId(widgetRef: CompRef) {
      return platform.widgetPresetsUtils.getSelectedWidgetPresetId(
        editorAPI,
        widgetRef,
      );
    },
    async waitForPresetChanged() {
      await editorAPI.dsActions.waitForChangesAppliedAsync();
    },
  };
};
