// @ts-nocheck
import hostWorkerInit from 'host-worker-init';

const { workerMessagesService } = hostWorkerInit;

/**
 * Get the worker URL as a blob in order to allow loading worker with a different origin
 * @returns {Promise<string>}
 */
function getWorkerURL() {
  return fetch(
    'https://76897fda-d78f-49dd-bd02-bf9ad52a4f11.dev.wix-code.com/_partials/santa/1.4316.0/node_modules/santa-wix-code/dist/wixcode-worker.js',
  )
    .then((res) => new Response(res.body))
    .then((response) => response.blob())
    .then((blob) => URL.createObjectURL(blob));
}

function createWorkerMessagesAPI() {
  return getWorkerURL().then((workerUrl) => {
    const initOptions = {
      sdkSource:
        'https://static.parastorage.com/services/js-wixcode-sdk/1.242.0/lib/wix.js', // TODO need to bundle only baseUI and $editor
      namespacesSdkSource:
        'https://static.parastorage.com/services/wixcode-namespaces/1.3.0/wixcode-namespaces.js', // external modules, this
      externalComponentsSource:
        'https://rawgit.com/yotamsha/statics/31a1ae3a8f2688ada5a63ea387dfec9a61d4268c/wixcode-components.min.js', // Temporary build which includes SDK for Base-UI components
      //wixCodeNamespacesAndElementorySupportSource: 'https://static.parastorage.com/services/wix-code-platform/1.40.0/wixCodeNamespacesAndElementorySupport.min.js', // used only for data-binding (and userCode?)
      workerProps: {
        workerUrl,
      },
    };

    const workerMessagesAPI = workerMessagesService.getAPI();
    workerMessagesAPI.init(initOptions);
    return workerMessagesAPI;
  });
}

export { createWorkerMessagesAPI };
