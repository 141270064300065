import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { translate } from '@/i18n';
import PresetSection from '../../mediaManagerPanelPresets/components/presetSection';
import type { ItemSectionProps, ItemSectionState } from './itemsSection.types';

class ItemsSection extends React.Component<ItemSectionProps, ItemSectionState> {
  static propTypes = {
    id: PropTypes.string.isRequired,
    automationId: PropTypes.string,
    title: PropTypes.string,
    loadMedia: PropTypes.func.isRequired,
    presetWidth: PropTypes.number,
    shouldDisplayPresetName: PropTypes.bool,
    onItemClick: PropTypes.func,
    getItemPrice: PropTypes.func,
    buyItem: PropTypes.func,
    startItemDrag: PropTypes.func,
    registerItem: PropTypes.func,
  };

  constructor(props: AnyFixMe) {
    super(props);

    this.state = {
      itemsLoading: true,
      items: [],
    };
  }

  componentDidMount() {
    this.initSection();
  }

  initSection() {
    this.loadItems()
      .then((items: AnyFixMe) => {
        this.setState({ items });
      })
      .catch(_.noop)
      .finally(() => {
        this.setState({ itemsLoading: false });
      });
  }

  loadItems() {
    return _.invoke(this.props, 'loadMedia');
  }

  shouldRenderSection() {
    return !this.state.itemsLoading && !_.isEmpty(this.state.items);
  }

  getSectionProps(): ItemSectionProps & { items: AnyFixMe[] } {
    return Object.assign(
      {
        title: translate(this.props.title),
        items: this.state.items,
      },
      _.omit(this.props, ['title']),
    );
  }

  render() {
    return this.shouldRenderSection() ? (
      <PresetSection key={this.props.id} {...this.getSectionProps()} />
    ) : null;
  }
}

export default ItemsSection;
