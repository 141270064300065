// @ts-nocheck
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as core from '@/core';
import constants from '@/constants';
import disableKeyboardShortcutsMixin from '../../mixins/disableKeyboardShortcutsMixin';
import * as stateManagement from '@/stateManagement';
import React from 'react';
import { cx } from '@/util';

// eslint-disable-next-line react/prefer-es6-class
const NotificationPanelFrameSimple = createReactClass({
  displayName: constants.PANEL_TYPES.NOTIFICATION,
  mixins: [disableKeyboardShortcutsMixin, core.mixins.editorAPIMixin],
  propTypes: {
    panelName: PropTypes.string.isRequired,
    width: PropTypes.string,
    height: PropTypes.string,
    timeout: PropTypes.number,
  },
  getPanelProps() {
    const { width } = this.props;
    const { height } = this.props;
    const style = { width, height };
    return _({ style })
      .defaults(this.props)
      .omit('children', 'width', 'height', 'panelName', 'close', 'timeout')
      .value();
  },
  componentDidMount() {
    if (this.props.timeout) {
      window.setTimeout(this.props.close, this.props.timeout);
    }
  },
  render() {
    return (
      <div
        {...this.getPanelProps()}
        className={cx(
          'center-top-position notification-panel-frame',
          this.getPanelProps()?.className,
        )}
      >
        <div className="content">{this.props.children}</div>
      </div>
    );
  },
});

const NotificationPanelFrame = stateManagement.panels.hoc.panelFrame(
  constants.PANEL_TYPES.NOTIFICATION,
)(NotificationPanelFrameSimple);
NotificationPanelFrame.pure = NotificationPanelFrameSimple;
export default NotificationPanelFrame;
