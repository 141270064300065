// @ts-nocheck
import ReactLinkedStateMixin from 'react-addons-linked-state-mixin';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import * as core from '@/core';

import React from 'react';
import * as panels from '@/panels';
import * as baseUI from '@/baseUI';
import { Checkbox, Composites, TextInput, TextLabel } from '@wix/wix-base-ui';
import { cx } from '@/util';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'SaveConfigurationPanel',
  mixins: [ReactLinkedStateMixin, core.mixins.editorAPIMixin], //eslint-disable-line react/no-deprecated
  propTypes: {
    onConfirm: PropTypes.func.isRequired,
    panelName: PropTypes.string,
  },
  getInitialState() {
    return {
      isSitePremium: false,
      isFirstSave: true,
      isDomainConnected: false,
      domain: 'http://www.my-domain.com',
      isSitePublished: false,
      shouldSaveSucceed: true,
    };
  },
  getFrameProps() {
    return {
      panelName: this.props.panelName,
    };
  },
  openConcurrentSave() {
    const editorAPI = this.getEditorAPI();
    editorAPI.panelManager.openPanel(
      'savePublish.panels.save.concurrentSavePanel',
      {},
      true,
    );
  },
  openSaveReminder() {
    const editorAPI = this.getEditorAPI();
    editorAPI.panelManager.openPanel(
      'savePublish.panels.save.saveReminderPanel',
      { saveMethod: this.onConfirm },
      true,
    );
  },
  componentWillUpdate(nextProps, nextState) {
    if (nextState.isFirstSave) {
      if (nextState.isSitePublished) {
        this.setState({
          isSitePublished: false,
          isFirstSave: true,
        });
      }
    }
  },
  premiumToggled() {
    const isSitePremium = !this.state.isSitePremium;
    this.setState({
      isSitePremium,
      isDomainConnected: !isSitePremium ? false : this.state.isDomainConnected,
    });
  },
  domainConnectedToggled() {
    const isDomainConnected = !this.state.isDomainConnected;
    this.setState({
      isDomainConnected,
      isSitePremium: isDomainConnected || this.state.isSitePremium,
    });
  },
  onConfirm() {
    this.props.onConfirm({
      isSitePremium: this.state.isSitePremium,
      isFirstSave: this.state.isFirstSave,
      isDomainConnected: this.state.isDomainConnected,
      domain: this.state.domain,
      isSitePublished: this.state.isSitePublished,
      shouldSaveSucceed: this.state.shouldSaveSucceed,
    });
  },
  close() {
    this.getEditorAPI().panelManager.closePanelByName(this.props.panelName);
  },
  render() {
    return (
      <panels.frames.EmptyPanelFrame
        ref="frame"
        {...this.getFrameProps()}
        className={cx(
          'save-configuration-panel',
          this.getFrameProps()?.className,
        )}
      >
        <a onClick={this.close} className="close-button">
          x
        </a>
        <h2
          style={{
            textAlign: 'center',
          }}
        >
          Save Testing Configuration
        </h2>
        <Checkbox
          label="First save"
          valueLink={this.linkState('isFirstSave')}
          shouldTranslate={false}
          labelAfterSymbol={true}
          className="checkbox-field"
        />
        <Checkbox
          label="Save succeeds"
          valueLink={this.linkState('shouldSaveSucceed')}
          shouldTranslate={false}
          labelAfterSymbol={true}
          className="checkbox-field"
        />
        <p>
          <br />
          Premium Interaction:
        </p>
        <Checkbox
          label="Site is/upgraded-to premium"
          value={this.state.isSitePremium}
          onChange={this.premiumToggled}
          shouldTranslate={false}
          labelAfterSymbol={true}
          className="checkbox-field"
        />
        <Checkbox
          label="Domain is/will-be connected"
          value={this.state.isDomainConnected}
          onChange={this.domainConnectedToggled}
          shouldTranslate={false}
          labelAfterSymbol={true}
          className="checkbox-field"
        />
        <Composites.TextInputLabeled>
          <TextLabel value={'Custom Domain'} shouldTranslate={false} />
          <TextInput
            value={this.linkState('domain').value}
            onChange={this.linkState('domain').requestChange}
            disabled={!this.state.isDomainConnected}
          />
        </Composites.TextInputLabeled>
        <Checkbox
          label="Site is published"
          valueLink={this.linkState('isSitePublished')}
          disabled={this.state.isFirstSave}
          shouldTranslate={false}
          labelAfterSymbol={true}
          className={`checkbox-field${
            this.state.isFirstSave ? ' disabled' : ''
          }`}
        />
        <button
          // TODO: cleanup later
          // eslint-disable-next-line react/no-unknown-property
          shouldTranslate={false}
          onClick={this.openConcurrentSave}
          className="concurrent-save-button"
        >
          Concurrent Save
        </button>
        <button
          // TODO: cleanup later
          // eslint-disable-next-line react/no-unknown-property
          shouldTranslate={false}
          onClick={this.openSaveReminder}
          className="save-reminder-button"
        >
          Save Reminder
        </button>

        <div className="save-configuration-footer">
          <baseUI.button
            shouldTranslate={false}
            label="Start Save"
            onClick={this.onConfirm}
            className="confirm-button"
          />
        </div>
      </panels.frames.EmptyPanelFrame>
    );
  },
});
