// @ts-nocheck
import createReactClass from 'create-react-class';
import * as core from '@/core';
import * as panelUtils from '@/panelUtils';
import * as higherOrderComponents from '@/higherOrderComponents';
import React from 'react';
import { Composites, Divider, TextLabel } from '@wix/wix-base-ui';
import * as baseUI from '@/baseUI';
import { WixBaseUISliderWithBI } from '@/baseUI';

// eslint-disable-next-line react/prefer-es6-class
const SvgShapeAdvancedStylePanel = createReactClass({
  displayName: 'svgShapeAdvancedStylePanel',
  mixins: [core.mixins.editorAPIMixin, panelUtils.linkColorPickerMixin],
  getColorPickerProps() {
    return {
      colorResolver: this.resolveColor,
      openColorPicker: this.openColorPicker,
    };
  },
  render() {
    return (
      <div className="advanced-style-panel only-advanced-styling">
        <Divider long={true} />

        <baseUI.colorPickerInputWithOpacitySlider
          label="CustomDesign_Shape_FillColor&Opacity"
          valueLink={this.props.linkStyleParam('fillcolor', 'BG_COLOR_ALPHA')}
          {...this.getColorPickerProps()}
        />

        <Divider long={true} />

        <baseUI.colorPickerInputWithOpacitySlider
          label="CustomDesign_Shape_BorderColor&Opacity"
          valueLink={this.props.linkStyleParam('stroke', 'BORDER_COLOR_ALPHA')}
          {...this.getColorPickerProps()}
        />

        <Divider long={true} />

        <Composites.SliderLabeled>
          <TextLabel value="CustomDesign_Shape_BorderWidth" />

          <WixBaseUISliderWithBI
            value={
              this.props.linkStyleParam('strokewidth', 'BORDER_SIZE').value
            }
            onChange={
              this.props.linkStyleParam('strokewidth', 'BORDER_SIZE')
                .requestChange
            }
            max={this.props.maxStrokeWidth || 15}
            inputMax={this.props.maxStrokeWidth || 15}
            label="CustomDesign_Shape_BorderWidth"
          />
        </Composites.SliderLabeled>
      </div>
    );
  },
});

export default higherOrderComponents.linkStyle(SvgShapeAdvancedStylePanel);
