:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._wrapper_nonDevMode_pd460_7 {
  margin-top: 18px; }
  ._wrapper_nonDevMode_pd460_7 g {
    fill: #116dff; }
  ._wrapper_nonDevMode_pd460_7 .control-text-button {
    vertical-align: text-bottom; }
  ._wrapper_nonDevMode_pd460_7 .control-text {
    vertical-align: middle; }

._wrapper_devMode_pd460_16 {
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 540px; }
  ._wrapper_devMode_pd460_16 ._list_pd460_21 {
    display: flex;
    justify-content: space-between; }
    ._wrapper_devMode_pd460_16 ._list_name_pd460_24 {
      display: flex;
      flex-direction: column;
      gap: 12px; }
      ._wrapper_devMode_pd460_16 ._list_name_pd460_24 .symbol {
        margin: 6px 12px 0px 6px; }
      ._wrapper_devMode_pd460_16 ._list_name_pd460_24 svg {
        height: 11px;
        width: 14px; }
    ._wrapper_devMode_pd460_16 ._list_result_pd460_33 {
      display: flex;
      flex-direction: column;
      gap: 12px; }

._checkboxWrapper_pd460_38 {
  display: flex;
  gap: 13px;
  width: 245px; }
  ._checkboxWrapper_pd460_38 .control-boolean {
    overflow: visible; }

.control-base-modal-footer {
  align-items: center; }
