import { EditorAPIKey } from '@/apis';

import { serviceTopology, http } from '@/util';
import { PERSONAL_SALE } from './constants';

import type { Shell } from '@/apilib';

const PRODUCT_TYPE_ID = '8b1b47e6-9f58-4e59-b147-cac894bec8da';

export interface PersonalSaleInfo {
  hasSale: boolean;
  discountPercentage: number;
}

export function createCampaignsApi(shell: Shell) {
  const editorAPI = shell.getAPI(EditorAPIKey);

  let personalSaleInfo: PersonalSaleInfo;

  function fetchPersonalSaleInfo() {
    let dealerUrl = `${serviceTopology.dealerUrl}/saleInfo`;

    if (!editorAPI.dsRead.generalInfo.isFirstSave()) {
      dealerUrl += `?siteGuid=${editorAPI.dsRead.generalInfo.getMetaSiteId()}`;
    }

    return http
      .fetchJson(dealerUrl)
      .then((saleData: AnyFixMe) => {
        if (saleData) {
          personalSaleInfo = {
            hasSale: saleData.payload.hasSale,
            discountPercentage: saleData.payload.discountPercentage,
          };

          if (
            personalSaleInfo.hasSale &&
            ![30, 40, 50].includes(personalSaleInfo.discountPercentage)
          ) {
            personalSaleInfo.hasSale = false;
          }
        }
      })
      .catch((e: AnyFixMe) => {
        console.error(e);
      });
  }

  let saleEndDateTimestamp: number | undefined;

  async function fetchSaleEndDate(): Promise<void> {
    const dealerUrl = `${serviceTopology.editorRootUrl}_api/premium-store/v1/sale/${PRODUCT_TYPE_ID}`;

    try {
      const saleData: { saleDetails?: { saleEndDate: string } } =
        await http.fetchJson(dealerUrl);
      const saleEndDate = saleData?.saleDetails?.saleEndDate;

      if (saleEndDate) {
        saleEndDateTimestamp = Date.parse(saleEndDate);
      }
    } catch (e) {
      console.error(e);
    }
  }

  function isActivePersonalSaleCampaign() {
    return personalSaleInfo?.hasSale ?? false;
  }

  function getPersonalSaleDiscountValue() {
    return personalSaleInfo?.discountPercentage;
  }

  function getSaleEndDate() {
    return saleEndDateTimestamp;
  }

  return {
    personalSale: {
      fetchInfo: fetchPersonalSaleInfo,
      getSaleInfo: () => personalSaleInfo,
      isActiveCampaign: isActivePersonalSaleCampaign,
      getDiscountValue: getPersonalSaleDiscountValue,
      getAssetPath: () => PERSONAL_SALE.assetPath,
      fetchSaleEndDate,
      getSaleEndDate,
    },
  };
}
