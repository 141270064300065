import createReactClass from 'create-react-class';
import * as util from '@/util';
import firstTimeTourMixin from '../firstTimeTourMixin';
import React from 'react';
import * as superAppMenuInfra from '@/superAppMenuInfra';
import * as wixStoreEditorActionsService from '../../../../common/wixStoreEditorActionsService';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'manuallyInstalledStep1',

  mixins: [
    firstTimeTourMixin,
    util.propTypesFilterMixin as React.Mixin<any, any>,
  ],

  componentDidMount() {
    wixStoreEditorActionsService.firstTimePanelOpen(
      'welcome_stores_manual',
      this.props.openType,
    );
  },

  render() {
    const mainImage = util.workspace.isNewWorkspaceEnabled()
      ? 'onlineStoreSpecialBtnPromotional_NewWorkspace'
      : 'onlineStoreSpecialBtnPromotional';

    return (
      <superAppMenuInfra.firstTimeTourPanels.getStartedSlide
        title="SiteApp_WixStores_AddAppFTUE_Step1_Title"
        subTitle="SiteApp_WixStores_AddAppFTUE_Step1_SubTitle"
        pros={[
          'SiteApp_WixStores_AddAppFTUE_Step1_Bullet1',
          'SiteApp_WixStores_AddAppFTUE_Step1_Bullet2',
          'SiteApp_WixStores_AddAppFTUE_Step1_Bullet3',
          'SiteApp_WixStores_AddAppFTUE_Step1_Bullet4',
        ]}
        nextButtonLabel="SiteApp_WixStores_AddAppFTUE_Step1_NextButton_Caption"
        mainImage={mainImage}
        footer={this.getUpgradeText()}
        upgradeLink={this.getUpgradeLinkText()}
        {...this.filteredProps()}
      />
    );
  },
});
