// @ts-nocheck
import addPanelDataConsts from '@/addPanelDataConsts';
import * as singleServiceWidgetConsts from './singleServiceWidgetConsts';
import editorActionsService from '../../common/editorActionsService';
import experiments from 'experiment';

export default function ({ editorAPI, isEditorX }) {
  const isWixStudio = experiments.isOpen(
    'specs.responsive-editor.BookingsChangeImagesOnAddPanel',
  );
  const addPanelConsts = addPanelDataConsts;
  const actionsService = editorActionsService({ editorAPI });
  const id = isEditorX
    ? singleServiceWidgetConsts.SINGLE_SERVICE_EDITOR_X_PRESET_KEY
    : singleServiceWidgetConsts.SINGLE_SERVICE_PRESET_KEY;

  const singleServiceWidget = {
    id,
    structure: {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
      layout: {
        width: 850,
        height: 360,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.tpapps.TPAWidget',
      data: {
        type: 'TPAWidget',
        metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
        applicationId: '2269',
        tpaData: {
          type: 'TPAData',
          metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
          content: `{"presetId":"${id}"}`,
        },
        widgetId: 'cc882051-73c9-41a6-8f90-f6ebc9f10fe1',
      },
      style: {
        type: 'TopLevelStyle',
        metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
        style: {
          properties: {},
          propertiesSource: {},
          groups: {},
        },
        componentClassName: 'wysiwyg.viewer.components.tpapps.TPAWidget',
        pageId: '',
        compId: '',
        styleType: 'custom',
        skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
      },
    },
    preset: {
      rect: isWixStudio
        ? { width: 324, height: 143, x: 0, y: 0 }
        : { width: 304, height: 126, x: 10, y: 10 },
      label: '',
      tags: null,
    },
  };

  const singleServiceWidgetItems = [singleServiceWidget];

  const getImagePath = () => {
    if (isWixStudio) {
      return singleServiceWidgetConsts.SINGLE_SERVICE_WIDGET_WIX_STUDIO_IMAGE;
    }
    return isEditorX
      ? singleServiceWidgetConsts.SINGLE_SERVICE_WIDGET_EDITOR_X_IMAGE
      : singleServiceWidgetConsts.SINGLE_SERVICE_WIDGET_IMAGE_V3;
  };

  return {
    id: 'wixBookingsSingleServiceWidgets',
    type: addPanelConsts.SECTIONS_TYPES.PRESET,
    hide: false,
    title: singleServiceWidgetConsts.SINGLE_SERVICE_TITLE_KEY,
    subNavigationTitle: singleServiceWidgetConsts.SINGLE_SERVICE_TITLE_KEY,
    presetTitle: singleServiceWidgetConsts.SINGLE_SERVICE_TITLE_KEY,
    tooltipTitle: singleServiceWidgetConsts.SINGLE_SERVICE_TITLE_KEY,
    showSectionHeader: true,
    additionalBehaviours: {
      labelMode: addPanelConsts.LABEL_BEHAVIOUR.ALWAYS,
      infoIcon: addPanelConsts.INFO_ICON_BEHAVIOUR.NONE,
      hoverImageAction: addPanelConsts.HOVER_IMAGE_ACTIONS.NONE,
      scaleProportion: addPanelConsts.DEFAULT_SCALE_FOR_PRESET,
      iconEnabledForComps: [],
    },
    props: {
      image: getImagePath(),
      imageHover: null,
      onDrop: actionsService.onWidgetDrop,
      onClick: actionsService.onWidgetClick,
      items: singleServiceWidgetItems,
      compTypes: ['wysiwyg.viewer.components.tpapps.TPAWidget'],
    },
  };
}
