// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Bubble } from '@wix/wix-base-ui';
import CodeDropPanelCorvidBanner from './codeDropPanelCorvidBanner';

function template() {
  return (
    <Bubble
      onMouseEnter={this.props.onMouseEnter}
      onMouseLeave={this.props.onMouseLeave}
      arrowAlignment="top"
      contentStyle={this.bubbleContentStyle}
      arrowStyle={this.bubbleArrowStyle}
      maxWidth={600}
      className="corvid-drop-panel-top-bar"
    >
      <CodeDropPanelCorvidBanner
        sendBi={this.props.sendBi}
        isDeveloperModeEnabled={this.props.isDeveloperModeEnabled}
        isWixCodeProvisioned={this.props.isWixCodeProvisioned}
        toggleDeveloperMode={this.props.toggleDeveloperMode}
        devToolsButtonEnabled={this.props.devToolsButtonEnabled}
        overrides={this.props.overrides}
      />
    </Bubble>
  );
}

const BUBBLE_ARROW_STYLE = {
  position: 'absolute',
  right: 'calc(50% - 8px)',
  top: '53px',
};

const DEFAULT_BUBBLE_CONTENT_STYLE = {
  position: 'absolute',
  display: 'block',
  padding: '0',
  minWidth: '360px',
  minHeight: '180px',
  top: '60px',
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line you-dont-need-lodash-underscore/assign
const BUBBLE_CONTENT_STYLE_WITH_SYMBOL = _.assign(
  {},
  DEFAULT_BUBBLE_CONTENT_STYLE,
  { minWidth: '516px', minHeight: '225px' },
);

class CodeDropPanel extends React.Component {
  constructor(props) {
    super(props);

    this.bubbleContentStyle = BUBBLE_CONTENT_STYLE_WITH_SYMBOL;
    this.bubbleArrowStyle = BUBBLE_ARROW_STYLE;
  }

  render() {
    return template.call(this);
  }
}

CodeDropPanel.displayName = 'CodeDropPanelCorvid';

CodeDropPanel.propTypes = {
  sendBi: PropTypes.func.isRequired,
  isDeveloperModeEnabled: PropTypes.bool.isRequired,
  isWixCodeProvisioned: PropTypes.bool.isRequired,
  toggleDeveloperMode: PropTypes.func.isRequired,
  devToolsButtonEnabled: PropTypes.bool.isRequired,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  overrides: PropTypes.object,
};

CodeDropPanel.defaultProps = {
  overrides: {},
};

export default CodeDropPanel;
