import _ from 'lodash';
import internalComponentsGfppMap from './internalComponents/internalComponentsGfppMap';
import componentsGfpp from './components/componentsGfpp';
import externalGfppDataWrapper from '../utils/externalGfppDataWrapper';
import * as util from '@/util';
import type {
  ComponentGFPPDefinition,
  GFPPDataOrResolver,
  GFPPDataRawOrResolver,
} from '@wix/editor-component-types';

const wrappedComponents: Record<string, GFPPDataOrResolver> = _.mapValues(
  componentsGfpp as Record<string, ComponentGFPPDefinition>,
  externalGfppDataWrapper.wrapExternalGfppDef,
);
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line you-dont-need-lodash-underscore/assign
const compsInfo: Record<string, GFPPDataRawOrResolver> = _.assign(
  {},
  wrappedComponents,
  internalComponentsGfppMap,
);

function registerComponentGfppData(
  componentType: string,
  componentGfppData: GFPPDataRawOrResolver,
) {
  compsInfo[componentType] = componentGfppData;
}

function registerComponentGfppDefinition(
  componentType: string,
  componentGFPPDefinition: ComponentGFPPDefinition,
) {
  compsInfo[componentType] = externalGfppDataWrapper.wrapExternalGfppDef(
    componentGFPPDefinition,
  );
}

function getComponentGfppData(componentType: string): GFPPDataRawOrResolver {
  return compsInfo[componentType] ?? compsInfo[util.path.suffix(componentType)];
}

export {
  registerComponentGfppData,
  registerComponentGfppDefinition,
  getComponentGfppData,
};
