import React, { useCallback, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Search, Composites, Text } from '@wix/wix-base-ui';

import { frames } from '@/panels';

import { SECTIONS_NAMES_WITH_ROLES } from './constants';

import styles from './sectionNamePanel.scss';

const { ToolPanelFrame } = frames;

export interface SectionNamePanelProps {
  panelName?: string;
  value?: string;
  onSubmit(value: string): void;
}

const SectionNamePanel: React.FC<SectionNamePanelProps> = (props) => {
  const { onSubmit, value } = props;
  const [translate] = useTranslation();
  const allOptionsMap = useMemo(
    () =>
      SECTIONS_NAMES_WITH_ROLES.reduce(
        (map, { name }) => {
          map[translate(name)] = name;
          return map;
        },
        {} as Record<string, string>,
      ),
    [translate],
  );

  const [selectedName, setSelectedName] = useState(value);
  const [searchInputValue, setSearchInputValue] = useState(
    value ? translate(value) : '',
  );

  const changeHandler = useCallback(
    (nextInputValue) => {
      setSearchInputValue(nextInputValue);
      setSelectedName(null);
    },
    [setSelectedName, setSearchInputValue],
  );
  const selectHandler = useCallback(
    (nameTranslation) => {
      setSearchInputValue(nameTranslation);
      setSelectedName(allOptionsMap[nameTranslation]);
    },
    [allOptionsMap, setSelectedName, setSearchInputValue],
  );
  const submitHandler = useCallback(() => {
    onSubmit(selectedName);
  }, [onSubmit, selectedName]);

  const searchValue = searchInputValue.toLowerCase();
  const searchOptions = Object.keys(allOptionsMap).filter((option) =>
    option.toLowerCase().includes(searchValue),
  );

  return (
    <ToolPanelFrame panelName={props.panelName} headerTitle="Section Name">
      <div className={styles.searchContainer}>
        <Text skin="standard">What is the intent of the section?</Text>
        <Search
          value={searchInputValue}
          options={searchOptions}
          onChange={changeHandler}
          onItemClick={selectHandler}
          onEnter={selectHandler}
          onClear={() => changeHandler('')}
          placeholder="e.g About, Services"
        />
      </div>
      <Composites.ActionSetVertical>
        <Button disabled={!selectedName} onClick={submitHandler}>
          Done
        </Button>
      </Composites.ActionSetVertical>
    </ToolPanelFrame>
  );
};

export default SectionNamePanel;
