'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')

const moreBehaviorHelper = require("./moreBehaviorHelper")
module.exports =
    {
        "type": addPanelDataConsts.SECTIONS_TYPES.PRESET,
        "hide": false,
        "title": "add_section_label_basic",
        "subNavigationTitle": "add_section_label_basic",
        "presetTitle": "add_section_label_basic",
        "tooltipTitle": "add_section_label_basic",
        "automationId": "add-panel-section-basicShapesSection",
        "subNavigationHide": false,
        "showSectionHeader": true,
        "additionalBehaviours": {
            "labelMode": addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
            "infoIcon": addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
            "hoverImageAction": addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
            "iconEnabledForComps": {}
        },
        "props": {
            "image": "addPanelData/sections/basicShapesSection_en/basicShapesSection_en.png",
            "imageHover": null,
            "items": [{
                "id": "Shape_Basic_Shapes_1",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 264,
                        "height": 300,
                        "x": 11,
                        "y": 13,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "08e9266742a9484b90115d29bbfa9360.svg"
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0"},
                        "displayMode": "stretch"
                    },
                    "mobileHints": {
                        "type": "MobileHints",
                        "author": "duplicate",
                        "originalCompId": "comp-j6qle20z",
                        "recommendedScale": 1,
                        "recommendedWidth": 88,
                        "recommendedHeight": 100
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "overrideColors": {"color1": "#393939"},
                        "shapeStyle": {
                            "opacity": 1,
                            "strokeWidth": 1,
                            "stroke": "color_15",
                            "strokeOpacity": 1,
                            "enableStroke": false
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "i8yjldka_0",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jgqifeu1"
                },
                "preset": {"rect": {"width": 109, "height": 126, "x": 0, "y": 1}, "tags": null}
            }, {
                "id": "Shape_Basic_Shapes_2",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 96,
                        "height": 96,
                        "x": 119,
                        "y": 16,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "be029226280349de8ba140f1a7dffd40.svg"
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0"},
                        "displayMode": "stretch"
                    },
                    "mobileHints": {
                        "type": "MobileHints",
                        "author": "duplicate",
                        "originalCompId": "comp-j6qle1zv",
                        "recommendedScale": 1,
                        "recommendedWidth": 32,
                        "recommendedHeight": 32
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "overrideColors": {"color1": "#393939"},
                        "shapeStyle": {
                            "opacity": 1,
                            "strokeWidth": 1,
                            "stroke": "color_15",
                            "strokeOpacity": 1,
                            "enableStroke": false
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "i8yjldk3_0",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jgqifeup"
                },
                "preset": {"rect": {"width": 53, "height": 64, "x": 109, "y": 1}, "tags": null}
            }, {
                "id": "Shape_Basic_Shapes_3",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 102,
                        "height": 90,
                        "x": 172,
                        "y": 16,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "6b1da864ef84b466c566b1aac7ed2e1e_svgshape.v1.Triangle.svg"
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0"},
                        "displayMode": "stretch"
                    },
                    "mobileHints": {
                        "type": "MobileHints",
                        "author": "duplicate",
                        "originalCompId": "comp-j6qle20v",
                        "recommendedScale": 1,
                        "recommendedWidth": 34,
                        "recommendedHeight": 30
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "overrideColors": {"color1": "#393939"},
                        "shapeStyle": {
                            "opacity": 1,
                            "strokeWidth": 1,
                            "stroke": "color_15",
                            "strokeOpacity": 1,
                            "enableStroke": false
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "i8yjldk9",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jgqifee6"
                },
                "preset": {"rect": {"width": 53, "height": 64, "x": 162, "y": 1}, "tags": null}
            }, {
                "id": "Shape_Basic_Shapes_4",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 102,
                        "height": 102,
                        "x": 226,
                        "y": 13,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "149052f33cca489898104e5cc92f8bb1.svg"
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0"},
                        "displayMode": "stretch"
                    },
                    "mobileHints": {
                        "type": "MobileHints",
                        "author": "duplicate",
                        "originalCompId": "comp-j6qle23e",
                        "recommendedScale": 1,
                        "recommendedWidth": 34,
                        "recommendedHeight": 34
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "overrideColors": {"color1": "#393939"},
                        "shapeStyle": {
                            "opacity": 1,
                            "strokeWidth": 1,
                            "stroke": "color_15",
                            "strokeOpacity": 1,
                            "enableStroke": false
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "i8yjldkn_0",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jgqifeef"
                },
                "preset": {"rect": {"width": 57, "height": 64, "x": 215, "y": 1}, "tags": null}
            }, {
                "id": "Shape_Basic_Shapes_5",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 102,
                        "height": 102,
                        "x": 280,
                        "y": 14,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "283bac5c7f3f4b348e0f68e27825aaa0.svg"
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0"},
                        "displayMode": "stretch"
                    },
                    "mobileHints": {
                        "type": "MobileHints",
                        "author": "duplicate",
                        "originalCompId": "comp-j6qle20x",
                        "recommendedScale": 1,
                        "recommendedWidth": 34,
                        "recommendedHeight": 34
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "overrideColors": {"color1": "#393939"},
                        "shapeStyle": {
                            "opacity": 1,
                            "strokeWidth": 1,
                            "stroke": "color_15",
                            "strokeOpacity": 1,
                            "enableStroke": false
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "i8yjldka",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jgqifeel"
                },
                "preset": {"rect": {"width": 52, "height": 64, "x": 272, "y": 1}, "tags": null}
            }, {
                "id": "Shape_Basic_Shapes_6",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 102,
                        "height": 102,
                        "x": 119,
                        "y": 77,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "f33fb02fc25547cf96d673e1c4e871a0.svg"
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0"},
                        "displayMode": "stretch"
                    },
                    "mobileHints": {
                        "type": "MobileHints",
                        "author": "duplicate",
                        "originalCompId": "comp-j6qle20r",
                        "recommendedScale": 1,
                        "recommendedWidth": 34,
                        "recommendedHeight": 34
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "overrideColors": {"color1": "#77E8CA"},
                        "shapeStyle": {
                            "opacity": 1,
                            "strokeWidth": 1,
                            "stroke": "color_15",
                            "strokeOpacity": 1,
                            "enableStroke": false
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "i8yjldk7",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jgqifeeq"
                },
                "preset": {"rect": {"width": 53, "height": 62, "x": 109, "y": 65}, "tags": null}
            }, {
                "id": "Shape_Basic_Shapes_7",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 102,
                        "height": 87,
                        "x": 173,
                        "y": 80,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "24c953b2aea6a0b593fed263b7a116c0_svgshape.v1.Hexagon.svg"
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0"},
                        "displayMode": "stretch"
                    },
                    "mobileHints": {
                        "type": "MobileHints",
                        "author": "duplicate",
                        "originalCompId": "comp-j6qle20t",
                        "recommendedScale": 1,
                        "recommendedWidth": 34,
                        "recommendedHeight": 29
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "overrideColors": {"color1": "#77E8CA"},
                        "shapeStyle": {
                            "opacity": 1,
                            "strokeWidth": 1,
                            "stroke": "color_15",
                            "strokeOpacity": 1,
                            "enableStroke": false
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "i8yjldk8",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jgqifeex"
                },
                "preset": {"rect": {"width": 53, "height": 62, "x": 162, "y": 65}, "tags": null}
            }, {
                "id": "Shape_Basic_Shapes_8",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 264,
                        "height": 102,
                        "x": 227,
                        "y": 76,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "06f469158f5346a3be7e4100e38370cd.svg"
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0"},
                        "displayMode": "stretch"
                    },
                    "mobileHints": {
                        "type": "MobileHints",
                        "author": "duplicate",
                        "originalCompId": "comp-j6qle23k",
                        "recommendedScale": 1,
                        "recommendedWidth": 88,
                        "recommendedHeight": 34
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "overrideColors": {"color1": "#77E8CA"},
                        "shapeStyle": {
                            "opacity": 1,
                            "strokeWidth": 1,
                            "stroke": "color_15",
                            "strokeOpacity": 1,
                            "enableStroke": false
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "i8yjldko",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jgqifef5"
                },
                "preset": {"rect": {"width": 109, "height": 62, "x": 215, "y": 65}, "tags": null}
            }, {
                "id": "Shape_Basic_Shapes_9",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 102,
                        "height": 102,
                        "x": 11,
                        "y": 139,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "ddc27efe3da1483c978c6befaf487e5c.svg"
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0"},
                        "displayMode": "stretch"
                    },
                    "mobileHints": {
                        "type": "MobileHints",
                        "author": "duplicate",
                        "originalCompId": "comp-j6qle211",
                        "recommendedScale": 1,
                        "recommendedWidth": 34,
                        "recommendedHeight": 34
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "overrideColors": {"color1": "#1D2CF3"},
                        "shapeStyle": {
                            "opacity": 1,
                            "strokeWidth": 1,
                            "stroke": "color_15",
                            "strokeOpacity": 1,
                            "enableStroke": false
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "i8yjldkb",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jgqifefd"
                },
                "preset": {"rect": {"width": 53, "height": 64, "x": 0, "y": 127}, "tags": null}
            }, {
                "id": "Shape_Basic_Shapes_10",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 102,
                        "height": 102,
                        "x": 64,
                        "y": 139,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "8463f60718194af748c49dddbe45b668_svgshape.v1.HollowCircle.svg"
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0"},
                        "displayMode": "stretch"
                    },
                    "mobileHints": {
                        "type": "MobileHints",
                        "author": "duplicate",
                        "originalCompId": "comp-j6qle213",
                        "recommendedScale": 1,
                        "recommendedWidth": 34,
                        "recommendedHeight": 34
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "overrideColors": {"color1": "#1D2CF3"},
                        "shapeStyle": {
                            "opacity": 1,
                            "strokeWidth": 1,
                            "stroke": "color_15",
                            "strokeOpacity": 1,
                            "enableStroke": false
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "i8yjldkb_0",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jgqifefm"
                },
                "preset": {"rect": {"width": 56, "height": 64, "x": 53, "y": 127}, "tags": null}
            }, {
                "id": "Shape_Basic_Shapes_11",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 96,
                        "height": 102,
                        "x": 121,
                        "y": 138,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "05b483649121487f8c74988bd75d001b.svg"
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0"},
                        "displayMode": "stretch"
                    },
                    "mobileHints": {
                        "type": "MobileHints",
                        "author": "duplicate",
                        "originalCompId": "comp-j6qle215",
                        "recommendedScale": 1,
                        "recommendedWidth": 32,
                        "recommendedHeight": 34
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "overrideColors": {"color1": "#1D2CF3"},
                        "shapeStyle": {
                            "opacity": 1,
                            "strokeWidth": 1,
                            "stroke": "color_15",
                            "strokeOpacity": 1,
                            "enableStroke": false
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "i8yjldkc",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jgqifefw"
                },
                "preset": {"rect": {"width": 53, "height": 64, "x": 109, "y": 127}, "tags": null}
            }, {
                "id": "Shape_Basic_Shapes_12",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 102,
                        "height": 102,
                        "x": 173,
                        "y": 137,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "162470084018985920a0c4cfb0f50d5e_svgshape.v1.Square.svg"
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0"},
                        "displayMode": "stretch"
                    },
                    "mobileHints": {
                        "type": "MobileHints",
                        "author": "duplicate",
                        "originalCompId": "comp-j6qle219",
                        "recommendedScale": 1,
                        "recommendedWidth": 34,
                        "recommendedHeight": 34
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "overrideColors": {"color1": "#1D2CF3"},
                        "shapeStyle": {
                            "opacity": 1,
                            "strokeWidth": 1,
                            "stroke": "color_11",
                            "strokeOpacity": 1,
                            "enableStroke": false
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "i8yjldkc_0",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jgqifegl"
                },
                "preset": {"rect": {"width": 53, "height": 64, "x": 162, "y": 127}, "tags": null}
            }, {
                "id": "Shape_Basic_Shapes_13",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 264,
                        "height": 126,
                        "x": 226,
                        "y": 137,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "c5364617a0184af19456d25c95059d8f.svg"
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0"},
                        "displayMode": "stretch"
                    },
                    "mobileHints": {
                        "type": "MobileHints",
                        "author": "duplicate",
                        "originalCompId": "comp-j6qle23b",
                        "recommendedScale": 1,
                        "recommendedWidth": 88,
                        "recommendedHeight": 42
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "overrideColors": {"color1": "#1D2CF3"},
                        "shapeStyle": {
                            "opacity": 1,
                            "strokeWidth": 1,
                            "stroke": "color_15",
                            "strokeOpacity": 1,
                            "enableStroke": false
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "i8yjldkn",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jgqifegt"
                },
                "preset": {"rect": {"width": 109, "height": 64, "x": 215, "y": 127}, "tags": null}
            }, {
                "id": "Shape_Basic_Shapes_14",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 105,
                        "height": 90,
                        "x": 10,
                        "y": 206,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "26a3821c7e9018db3b97bfeb246509a7_svgshape.v1.Rhombus.svg"
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0"},
                        "displayMode": "stretch"
                    },
                    "mobileHints": {
                        "type": "MobileHints",
                        "author": "duplicate",
                        "originalCompId": "comp-j6qle21i",
                        "recommendedScale": 1,
                        "recommendedWidth": 35,
                        "recommendedHeight": 30
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "overrideColors": {"color1": "#A2A3E9"},
                        "shapeStyle": {
                            "opacity": 1,
                            "strokeWidth": 1,
                            "stroke": "color_29",
                            "strokeOpacity": 1,
                            "enableStroke": false
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "i8yjldke",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jgqifegv"
                },
                "preset": {"rect": {"width": 53, "height": 64, "x": 0, "y": 190}, "tags": null}
            }, {
                "id": "Shape_Basic_Shapes_15",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 102,
                        "height": 102,
                        "x": 63,
                        "y": 204,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "10935cfc7d81d9889e33410f31433eb2_svgshape.v1.DiamondSilhouette.svg"
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0"},
                        "displayMode": "stretch"
                    },
                    "mobileHints": {
                        "type": "MobileHints",
                        "author": "duplicate",
                        "originalCompId": "comp-j6qle224",
                        "recommendedScale": 1,
                        "recommendedWidth": 34,
                        "recommendedHeight": 34
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "overrideColors": {"color1": "#A2A3E9"},
                        "shapeStyle": {
                            "opacity": 1,
                            "strokeWidth": 1,
                            "stroke": "color_15",
                            "strokeOpacity": 1,
                            "enableStroke": false
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "i8yjldkg_0",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jgqifeh7"
                },
                "preset": {"rect": {"width": 56, "height": 64, "x": 53, "y": 190}, "tags": null}
            }, {
                "id": "Shape_Basic_Shapes_16",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 102,
                        "height": 102,
                        "x": 118,
                        "y": 203,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "4133af5c208b486e815ff1aa4bd6df9d.svg"
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0"},
                        "displayMode": "stretch"
                    },
                    "mobileHints": {
                        "type": "MobileHints",
                        "author": "duplicate",
                        "originalCompId": "comp-j6qle21f",
                        "recommendedScale": 1,
                        "recommendedWidth": 34,
                        "recommendedHeight": 34
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "overrideColors": {"color1": "#A2A3E9"},
                        "shapeStyle": {
                            "opacity": 1,
                            "strokeWidth": 1,
                            "stroke": "color_15",
                            "strokeOpacity": 1,
                            "enableStroke": false
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "i8yjldkd_0",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jgqifehz"
                },
                "preset": {"rect": {"width": 53, "height": 64, "x": 109, "y": 190}, "tags": null}
            }, {
                "id": "Shape_Basic_Shapes_17",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 102,
                        "height": 102,
                        "x": 173,
                        "y": 205,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "6fc14612e7e546538f31712edc5e4f7b.svg"
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0"},
                        "displayMode": "stretch"
                    },
                    "mobileHints": {
                        "type": "MobileHints",
                        "author": "duplicate",
                        "originalCompId": "comp-j6qle21d",
                        "recommendedScale": 1,
                        "recommendedWidth": 34,
                        "recommendedHeight": 34
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "overrideColors": {"color1": "#A2A3E9"},
                        "shapeStyle": {
                            "opacity": 1,
                            "strokeWidth": 1,
                            "stroke": "color_15",
                            "strokeOpacity": 1,
                            "enableStroke": false
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "i8yjldkd",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jgqifeis"
                },
                "preset": {"rect": {"width": 53, "height": 64, "x": 162, "y": 190}, "tags": null}
            }, {
                "id": "Shape_Basic_Shapes_18",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 264,
                        "height": 273,
                        "x": 227,
                        "y": 205,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "6ed8a9528a114cc9818a6e2bb3893bd1.svg"
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0"},
                        "displayMode": "stretch"
                    },
                    "mobileHints": {
                        "type": "MobileHints",
                        "author": "duplicate",
                        "originalCompId": "comp-j6qle227",
                        "recommendedScale": 1,
                        "recommendedWidth": 88,
                        "recommendedHeight": 91
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "overrideColors": {"color1": "#FFD9E4"},
                        "shapeStyle": {
                            "opacity": 1,
                            "strokeWidth": 1,
                            "stroke": "color_15",
                            "strokeOpacity": 1,
                            "enableStroke": false
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "i8yjldkh",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jgqifejw"
                },
                "preset": {"rect": {"width": 109, "height": 121, "x": 215, "y": 190}, "tags": null}
            }, {
                "id": "Shape_Basic_Shapes_19",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 264,
                        "height": 102,
                        "x": 11,
                        "y": 267,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "53baabd3301341e0a5ee2f3327df5632.svg"
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0"},
                        "displayMode": "stretch"
                    },
                    "mobileHints": {
                        "type": "MobileHints",
                        "author": "duplicate",
                        "originalCompId": "comp-j6qle23o",
                        "recommendedScale": 1,
                        "recommendedWidth": 88,
                        "recommendedHeight": 34
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "overrideColors": {"color1": "#B45AD3"},
                        "shapeStyle": {
                            "opacity": 1,
                            "strokeWidth": 1,
                            "stroke": "color_15",
                            "strokeOpacity": 1,
                            "enableStroke": false
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "i8yjldko_0",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jgqifehk"
                },
                "preset": {"rect": {"width": 109, "height": 57, "x": 0, "y": 254}, "tags": null}
            }, {
                "id": "Shape_Basic_Shapes_20",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 102,
                        "height": 96,
                        "x": 118,
                        "y": 265,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "4e07ab1582cefce0ee724a472cf2b633_svgshape.v1.StarSilhouette.svg"
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0"},
                        "displayMode": "stretch"
                    },
                    "mobileHints": {
                        "type": "MobileHints",
                        "author": "duplicate",
                        "originalCompId": "comp-j6qle21n",
                        "recommendedScale": 1,
                        "recommendedWidth": 34,
                        "recommendedHeight": 32
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "overrideColors": {"color1": "#B45AD3"},
                        "shapeStyle": {
                            "opacity": 1,
                            "strokeWidth": 1,
                            "stroke": "color_15",
                            "strokeOpacity": 1,
                            "enableStroke": false
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "i8yjldke_0",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jgqifeid"
                },
                "preset": {"rect": {"width": 53, "height": 57, "x": 109, "y": 254}, "tags": null}
            }, {
                "id": "Shape_Basic_Shapes_21",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 102,
                        "height": 96,
                        "x": 173,
                        "y": 268,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "c81b19619dbbddf0525092d9fa26b9a8_svgshape.v1.Heart.svg"
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0"},
                        "displayMode": "stretch"
                    },
                    "mobileHints": {
                        "type": "MobileHints",
                        "author": "duplicate",
                        "originalCompId": "comp-j6qle21p",
                        "recommendedScale": 1,
                        "recommendedWidth": 34,
                        "recommendedHeight": 32
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "overrideColors": {"color1": "#B45AD3"},
                        "shapeStyle": {
                            "opacity": 1,
                            "strokeWidth": 1,
                            "stroke": "color_15",
                            "strokeOpacity": 1,
                            "enableStroke": false
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "i8yjldkf",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jgqifej6"
                },
                "preset": {"rect": {"width": 53, "height": 57, "x": 162, "y": 254}, "tags": null}
            }, {
                "id": "Shape_Basic_Shapes_22",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 264,
                        "height": 129,
                        "x": 10,
                        "y": 325,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "9c643faa9dbb4b2dbb98121267e2adf8.svg"
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0"},
                        "displayMode": "stretch"
                    },
                    "mobileHints": {
                        "type": "MobileHints",
                        "author": "duplicate",
                        "originalCompId": "comp-j6qle22b",
                        "recommendedScale": 1,
                        "recommendedWidth": 88,
                        "recommendedHeight": 43
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "overrideColors": {"color1": "#E33292"},
                        "shapeStyle": {
                            "opacity": 1,
                            "strokeWidth": 1,
                            "stroke": "color_15",
                            "strokeOpacity": 1,
                            "enableStroke": false
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "i8yjldki",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jgqifekf"
                },
                "preset": {"rect": {"width": 109, "height": 64, "x": 0, "y": 311}, "tags": null}
            }, {
                "id": "Shape_Basic_Shapes_23",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 78,
                        "height": 102,
                        "x": 121,
                        "y": 328,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "6dd121b9bb6ba478de91d2ad83d0dc5d_svgshape.v1.Drop.svg"
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0"},
                        "displayMode": "stretch"
                    },
                    "mobileHints": {
                        "type": "MobileHints",
                        "author": "duplicate",
                        "originalCompId": "comp-j6qle21w",
                        "recommendedScale": 1,
                        "recommendedWidth": 26,
                        "recommendedHeight": 34
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "overrideColors": {"color1": "#E33292"},
                        "shapeStyle": {
                            "opacity": 1,
                            "strokeWidth": 1,
                            "stroke": "color_15",
                            "strokeOpacity": 1,
                            "enableStroke": false
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "i8yjldkf_0",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jgqifekw"
                },
                "preset": {"rect": {"width": 53, "height": 64, "x": 109, "y": 311}, "tags": null}
            }, {
                "id": "Shape_Basic_Shapes_24",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 78,
                        "height": 102,
                        "x": 177,
                        "y": 328,
                        "scale": 1,
                        "rotationInDegrees": 180,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "6dd121b9bb6ba478de91d2ad83d0dc5d_svgshape.v1.Drop.svg"
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0"},
                        "displayMode": "stretch"
                    },
                    "mobileHints": {
                        "type": "MobileHints",
                        "author": "duplicate",
                        "originalCompId": "comp-j6qle221",
                        "recommendedScale": 1,
                        "recommendedWidth": 26,
                        "recommendedHeight": 34
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "overrideColors": {"color1": "#E33292"},
                        "shapeStyle": {
                            "opacity": 1,
                            "strokeWidth": 1,
                            "stroke": "color_15",
                            "strokeOpacity": 1,
                            "enableStroke": false
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "i8yjldkg",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jgqifele"
                },
                "preset": {"rect": {"width": 53, "height": 64, "x": 162, "y": 311}, "tags": null}
            }, {
                "id": "Shape_Basic_Shapes_25",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 90,
                        "height": 114,
                        "x": 230,
                        "y": 326,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "ee4bffd911964b128ae38a7a1b233026.svg"
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0"},
                        "displayMode": "stretch"
                    },
                    "mobileHints": {
                        "type": "MobileHints",
                        "author": "duplicate",
                        "originalCompId": "comp-j6qle22i",
                        "recommendedScale": 1,
                        "recommendedWidth": 30,
                        "recommendedHeight": 38
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "overrideColors": {"color1": "#E33292"},
                        "shapeStyle": {
                            "opacity": 1,
                            "strokeWidth": 1,
                            "stroke": "color_15",
                            "strokeOpacity": 1,
                            "enableStroke": false
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "i8yjldkj_0",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jgqifelw"
                },
                "preset": {"rect": {"width": 57, "height": 64, "x": 215, "y": 311}, "tags": null}
            }, {
                "id": "Shape_Basic_Shapes_26",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 90,
                        "height": 126,
                        "x": 283,
                        "y": 322,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "a9052f11b1594919bf8ca344ba49a03d.svg"
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0"},
                        "displayMode": "stretch"
                    },
                    "mobileHints": {
                        "type": "MobileHints",
                        "author": "duplicate",
                        "originalCompId": "comp-j6qle22e",
                        "recommendedScale": 1,
                        "recommendedWidth": 30,
                        "recommendedHeight": 42
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "overrideColors": {"color1": "#E33292"},
                        "shapeStyle": {
                            "opacity": 1,
                            "strokeWidth": 1,
                            "stroke": "color_15",
                            "strokeOpacity": 1,
                            "enableStroke": false
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "i8yjldki_0",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jgqifemq"
                },
                "preset": {"rect": {"width": 52, "height": 64, "x": 272, "y": 311}, "tags": null}
            }, {
                "id": "Shape_Basic_Shapes_27",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 264,
                        "height": 264,
                        "x": 10,
                        "y": 391,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "5b2b0a3903004b6694d8fa0db50b7f01.svg"
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0"},
                        "displayMode": "stretch"
                    },
                    "mobileHints": {
                        "type": "MobileHints",
                        "author": "duplicate",
                        "originalCompId": "comp-j6qle229",
                        "recommendedScale": 1,
                        "recommendedWidth": 88,
                        "recommendedHeight": 88
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "overrideColors": {"color1": "#FF4F4F"},
                        "shapeStyle": {
                            "opacity": 1,
                            "strokeWidth": 1,
                            "stroke": "color_15",
                            "strokeOpacity": 1,
                            "enableStroke": false
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "i8yjldkh_0",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jgqifena"
                },
                "preset": {"rect": {"width": 109, "height": 122, "x": 0, "y": 375}, "tags": null}
            }, {
                "id": "Shape_Basic_Shapes_28",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 96,
                        "height": 96,
                        "x": 120,
                        "y": 390,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "5941be68212ca23dec788d1f658fe91b_svgshape.v1.RightIsoscelesTriangle.svg"
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0"},
                        "displayMode": "stretch"
                    },
                    "mobileHints": {
                        "type": "MobileHints",
                        "author": "duplicate",
                        "originalCompId": "comp-j6qle22s",
                        "recommendedScale": 1,
                        "recommendedWidth": 32,
                        "recommendedHeight": 32
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "overrideColors": {"color1": "#FFD45A"},
                        "shapeStyle": {
                            "opacity": 1,
                            "strokeWidth": 1,
                            "stroke": "color_15",
                            "strokeOpacity": 1,
                            "enableStroke": false
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "i8yjldkl",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jgqifenw"
                },
                "preset": {"rect": {"width": 53, "height": 64, "x": 109, "y": 375}, "tags": null}
            }, {
                "id": "Shape_Basic_Shapes_29",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 117,
                        "height": 66,
                        "x": 169,
                        "y": 398,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "2199b45a569247d49c22b958a59eacc8.svg"
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0"},
                        "displayMode": "stretch"
                    },
                    "mobileHints": {
                        "type": "MobileHints",
                        "author": "duplicate",
                        "originalCompId": "comp-j6qle22q",
                        "recommendedScale": 1,
                        "recommendedWidth": 39,
                        "recommendedHeight": 22
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "overrideColors": {"color1": "#FFD45A"},
                        "shapeStyle": {
                            "opacity": 1,
                            "strokeWidth": 1,
                            "stroke": "color_15",
                            "strokeOpacity": 1,
                            "enableStroke": false
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "i8yjldkk_0",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jgqifeoh"
                },
                "preset": {"rect": {"width": 53, "height": 64, "x": 162, "y": 375}, "tags": null}
            }, {
                "id": "Shape_Basic_Shapes_30",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 117,
                        "height": 69,
                        "x": 225,
                        "y": 397,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "a3097bb41da44826b6354f37334715cc.svg"
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0"},
                        "displayMode": "stretch"
                    },
                    "mobileHints": {
                        "type": "MobileHints",
                        "author": "duplicate",
                        "originalCompId": "comp-j6qle22n",
                        "recommendedScale": 1,
                        "recommendedWidth": 39,
                        "recommendedHeight": 23
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "overrideColors": {"color1": "#FFD45A"},
                        "shapeStyle": {
                            "opacity": 1,
                            "strokeWidth": 1,
                            "stroke": "color_15",
                            "strokeOpacity": 1,
                            "enableStroke": false
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "i8yjldkk",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jgqifepb"
                },
                "preset": {"rect": {"width": 57, "height": 64, "x": 215, "y": 375}, "tags": null}
            }, {
                "id": "Shape_Basic_Shapes_31",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 96,
                        "height": 93,
                        "x": 282,
                        "y": 391,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "96a90e46359f4a90ab488ea407aeec3f.svg"
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0"},
                        "displayMode": "stretch"
                    },
                    "mobileHints": {
                        "type": "MobileHints",
                        "author": "duplicate",
                        "originalCompId": "comp-j6qle22l",
                        "recommendedScale": 1,
                        "recommendedWidth": 32,
                        "recommendedHeight": 31
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "overrideColors": {"color1": "#FFD45A"},
                        "shapeStyle": {
                            "opacity": 1,
                            "strokeWidth": 1,
                            "stroke": "color_15",
                            "strokeOpacity": 1,
                            "enableStroke": false
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "i8yjldkj_1",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jgqifepw"
                },
                "preset": {"rect": {"width": 52, "height": 64, "x": 272, "y": 375}, "tags": null}
            }, {
                "id": "Shape_Basic_Shapes_32",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 102,
                        "height": 102,
                        "x": 118,
                        "y": 447,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "dfb7d75e5d0fab0297dda44a9e806b60_svgshape.v1.StarShape.svg"
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0"},
                        "displayMode": "stretch"
                    },
                    "mobileHints": {
                        "type": "MobileHints",
                        "author": "duplicate",
                        "originalCompId": "comp-j6qle22v",
                        "recommendedScale": 1,
                        "recommendedWidth": 34,
                        "recommendedHeight": 34
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "overrideColors": {"color1": "#FF8C62"},
                        "shapeStyle": {
                            "opacity": 1,
                            "strokeWidth": 1,
                            "stroke": "color_15",
                            "strokeOpacity": 1,
                            "enableStroke": false
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "i8yjldkl_0",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jgqifeqi"
                },
                "preset": {"rect": {"width": 53, "height": 58, "x": 109, "y": 439}, "tags": null}
            }, {
                "id": "Shape_Basic_Shapes_33",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 93,
                        "height": 102,
                        "x": 173,
                        "y": 449,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "265b17ffe54f4ac68c73afa9fd8932fb.svg"
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0"},
                        "displayMode": "stretch"
                    },
                    "mobileHints": {
                        "type": "MobileHints",
                        "author": "duplicate",
                        "originalCompId": "comp-j6qle22z",
                        "recommendedScale": 1,
                        "recommendedWidth": 31,
                        "recommendedHeight": 34
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "overrideColors": {"color1": "#FF8C62"},
                        "shapeStyle": {
                            "opacity": 1,
                            "strokeWidth": 1,
                            "stroke": "color_15",
                            "strokeOpacity": 1,
                            "enableStroke": false
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "i8yjldkm",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jgqifer8"
                },
                "preset": {"rect": {"width": 53, "height": 58, "x": 162, "y": 439}, "tags": null}
            }, {
                "id": "Shape_Basic_Shapes_34",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 102,
                        "height": 90,
                        "x": 226,
                        "y": 451,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "0056ed7fa85e4a31b7448fc41e898041.svg"
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0"},
                        "displayMode": "stretch"
                    },
                    "mobileHints": {
                        "type": "MobileHints",
                        "author": "duplicate",
                        "originalCompId": "comp-j6qle22g",
                        "recommendedScale": 1,
                        "recommendedWidth": 34,
                        "recommendedHeight": 30
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "overrideColors": {"color1": "#FF8C62"},
                        "shapeStyle": {
                            "opacity": 1,
                            "strokeWidth": 1,
                            "stroke": "color_15",
                            "strokeOpacity": 1,
                            "enableStroke": false
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "i8yjldkj",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jgqifese"
                },
                "preset": {"rect": {"width": 57, "height": 58, "x": 215, "y": 439}, "tags": null}
            }, {
                "id": "Shape_Basic_Shapes_35",
                "structure": {
                    "type": "Component",
                    "skin": "skins.viewer.VectorImageSkin",
                    "layout": {
                        "width": 102,
                        "height": 75,
                        "x": 281,
                        "y": 455,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.VectorImage",
                    "data": {
                        "type": "VectorImage",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "svgId": "bd297fa0af66463794d0d66f3bbc92cf.svg"
                    },
                    "props": {
                        "type": "VectorImageProperties",
                        "metaData": {"schemaVersion": "1.0"},
                        "displayMode": "stretch"
                    },
                    "mobileHints": {
                        "type": "MobileHints",
                        "author": "duplicate",
                        "originalCompId": "comp-j6qle232",
                        "recommendedScale": 1,
                        "recommendedWidth": 34,
                        "recommendedHeight": 25
                    },
                    "design": {
                        "type": "VectorImageDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "overrideColors": {"color1": "#FF8C62"},
                        "shapeStyle": {
                            "opacity": 1,
                            "strokeWidth": 1,
                            "stroke": "color_15",
                            "strokeOpacity": 1,
                            "enableStroke": false
                        }
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                        "componentClassName": "wysiwyg.viewer.components.VectorImage",
                        "pageId": "",
                        "compId": "i8yjldkm_0",
                        "styleType": "custom",
                        "skin": "skins.viewer.VectorImageSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jgqifet2"
                },
                "preset": {"rect": {"width": 52, "height": 58, "x": 272, "y": 439}, "tags": null}
            }],
            "compTypes": ["wysiwyg.viewer.components.VectorImage"]
        },
        "sectionFooter": {
            "label": "add_section_more_basic",
            "action": moreBehaviorHelper,
            "category": "VECTOR_ART",
            "openSource": "add_panel_basic_shapes_all",
            "path": "public/ab62ca24cd194952aad0707eead0c0f7/21555df8496d4c3c86bae2b4fcba13f1"
        },
        "help": {"hide": false, "text": "add_section_info_text_basic"}
    }
