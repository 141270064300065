import _ from 'lodash';
import React from 'react';
import { cx, hoc } from '@/util';
import type { StateMapperArgs } from 'types/redux';
import type { LassoCandidate } from '@/stateManagement';

interface LassoProps {
  lassoLayout: AnyFixMe;
  lassoCandidates: LassoCandidateWithInfo[];
}

const Lasso: React.FC<LassoProps> = (props) => {
  if (!props.lassoLayout) {
    return null;
  }
  return (
    <div className="lasso-layer">
      <svg className="lasso-highlights">
        <rect
          {...props.lassoLayout}
          className={cx(props.lassoLayout?.className, 'lasso-rect')}
        />
        {/* TODO: Fix this the next time the file is edited. */}
        {/* eslint-disable-next-line you-dont-need-lodash-underscore/map */}
        {_.map(
          props.lassoCandidates,
          (candidateComponent, candidateComponentIndex) => (
            <rect
              key={candidateComponentIndex}
              {...candidateComponent.layout.bounding}
              className={cx(candidateComponent.layout.bounding?.className, {
                'lasso-candidate': true,
                'show-on-all-pages': candidateComponent.showOnAllPages,
              })}
            />
          ),
        )}
      </svg>
    </div>
  );
};

type LassoCandidateWithInfo = LassoCandidate & { showOnAllPages: boolean };

const mapStateToProps = ({
  editorAPI,
  state: editorState,
}: StateMapperArgs) => {
  const { lassoLayout } = editorState;
  const lassoCandidates = (editorState.lassoCandidates || []).map(
    (candidate) => ({
      ...candidate,
      showOnAllPages: editorAPI.components.isShowOnAllPages(
        candidate.component,
      ),
    }),
  );

  return { lassoLayout, lassoCandidates };
};

export default hoc.connect(hoc.STORES.MOUSE_OPS, mapStateToProps)(Lasso);
