import React from 'react';
import Preloader from './preloader';

interface WithLoaderProps {
  isLoading: boolean;
  title: string;
}

const WithLoader: React.FC<WithLoaderProps> = ({
  isLoading,
  title,
  children,
}) => {
  if (isLoading) {
    return <Preloader title={title} />;
  }

  return <>{children}</>;
};

export default WithLoader;
