import PropTypes from 'prop-types';
import React from 'react';
import * as symbols from '@wix/santa-editor-symbols';

//TYPE WAS GENERATED, remove this line when reviewed
interface ContextMenuItemProps {
  symbolName: string;
  label: string;
}

class ContextMenuItem extends React.Component<ContextMenuItemProps> {
  static displayName = 'ContextMenuItem';

  static propTypes = {
    symbolName: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  };

  render() {
    return (
      <div className="wix-data-context-menu-item">
        <div className="icon">
          <symbols.symbol name={this.props.symbolName} />
        </div>
        <span className="label">{this.props.label}</span>
      </div>
    );
  }
}

export default ContextMenuItem;
