import React from 'react';
import { Button, IconButton, Tooltip } from '@wix/wix-base-ui';

import { translate } from '@/i18n';
import * as symbols from '@wix/santa-editor-symbols';
import { cx } from '@/util';

interface FloatingModeBarSecondaryActionsProps {
  onExitMode: () => void;
  exitModeLabelKey: string;
  onOpenHelpCenter?: () => void;
  secondaryAction?: () => void;
  secondaryActionContent?: string;
  secondaryActionInProgress?: boolean;
  secondaryActionDuration?: number;
  secondaryActionProgressContent?: string;
  secondaryActionDisabled?: boolean;
}

export const NewWorkspaceFloatingModeBarSecondaryActions: React.FC<
  FloatingModeBarSecondaryActionsProps
> = (props) => {
  return (
    <>
      {props.onOpenHelpCenter && (
        <div className="floating-help-wrapper">
          <IconButton
            skin="standard"
            priority="secondary"
            onClick={props.onOpenHelpCenter}
          >
            <symbols.symbol name="question_mark" />
          </IconButton>
        </div>
      )}
      <div className="floating-mode-bar__btn-wrapper">
        {props.secondaryActionContent &&
          getSecondaryActionButtonWithProgress(props)}
        <Button
          className="floating-mode-bar__btn-close btn-sm"
          automationId="floating-mode-bar__close-btn"
          onClick={props.onExitMode}
        >
          {translate(props.exitModeLabelKey)}
        </Button>
      </div>
    </>
  );
};

export const FloatingModeBarSecondaryActions: React.FC<
  FloatingModeBarSecondaryActionsProps
> = (props) => {
  return (
    <>
      {props.onOpenHelpCenter && (
        <div className="floating-help-wrapper">
          <Button
            className="floating-mode-bar__btn-help"
            onClick={props.onOpenHelpCenter}
          >
            <symbols.symbol name="question_mark" />
          </Button>
        </div>
      )}
      <div className="floating-mode-bar__btn-wrapper">
        {props.secondaryActionContent &&
          getSecondaryActionButtonWithProgress(props)}
        <Button
          className="floating-mode-bar__btn-close btn-sm"
          automationId="floating-mode-bar__close-btn"
          onClick={props.onExitMode}
        >
          {translate(props.exitModeLabelKey)}
        </Button>
      </div>
    </>
  );
};

const getSecondaryActionButtonWithProgress: React.FC<
  FloatingModeBarSecondaryActionsProps
> = (props) => {
  const initialText = translate(props.secondaryActionContent);
  const inProgressText = translate(props.secondaryActionProgressContent);
  const isInitialTextLonger = initialText.length >= inProgressText.length;
  const getSecondaryButtonClasses = () => {
    return {
      'floating-mode-bar__btn-secondary': true,
      'btn-sm': true,
      active: props.secondaryActionInProgress,
    };
  };

  return (
    <Tooltip
      content="interactions_topbar_play_disabled_tooltip"
      interactive={true}
      disabled={!props.secondaryActionDisabled}
      marginTop={10}
    >
      <Button
        disabled={
          props.secondaryActionDisabled && !props.secondaryActionInProgress
        }
        className={cx(getSecondaryButtonClasses())}
        onClick={props.secondaryAction}
      >
        {props.secondaryActionInProgress && (
          <div
            className="playInteractionProgress"
            style={{
              animationDuration: `${props.secondaryActionDuration}s`,
            }}
          />
        )}
        <div
          style={{
            opacity: `${props.secondaryActionInProgress ? 0 : 1}`,
            position: !isInitialTextLonger ? 'absolute' : 'static',
          }}
        >
          <symbols.symbol name="interactionPlayPreview" />
          &nbsp;
          {initialText}
        </div>
        <div
          style={{
            opacity: `${props.secondaryActionInProgress ? '1' : '0'}`,
            position: isInitialTextLonger ? 'absolute' : 'static',
          }}
        >
          <symbols.symbol name="interactionStopPreview" />
          &nbsp;
          {inProgressText}
        </div>
      </Button>
    </Tooltip>
  );
};
