import _ from 'lodash';

import { getDataSkinCustomizations } from '@wix/editor-elements-design-panel-params/skinDataCustomizations';
import advancedStyleDataUtil from './advancedStyle/utils/advancedStyleDataUtil';
import styleUtil from './advancedStyle/utils/styleUtil';
import * as automationAdvancedStyleDataUtil from './advancedStyle/utils/automationAdvancedStyleDataUtil';
import skinDataCustomizations from './advancedStyle/data/skinDataCustomizations.json';
import svgForSkin from './advancedStyle/data/svgForSkin';
import advancedStyleConstants from './advancedStyle/constants/advancedStyleConstants';

const skinData = _.merge(skinDataCustomizations, getDataSkinCustomizations());

advancedStyleDataUtil.init(skinData, svgForSkin);

const advancedStyle = {
  advancedStyleDataUtil,
  styleUtil,
  automationAdvancedStyleDataUtil,
  advancedStyleConstants,
};

export { advancedStyle };
