// @ts-nocheck
import _ from 'lodash';
import * as core from '@/core';
const { tpaTypeUtils } = core.utils;

function getTPASpecialPagesIdsMap(pageDataList, editorAPI) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/filter
  return _(pageDataList)
    .filter((pageData) =>
      tpaTypeUtils.isTPASpecialPage(editorAPI.dsRead, pageData),
    )
    .keyBy('id')
    .value();
}

function getTPAApplicationIdsThatHaveSpecialPages(
  pageDataList,
  TPASpecialPagesIdsMap,
) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/filter
  return _(pageDataList)
    .filter((pageData) => _.has(TPASpecialPagesIdsMap, pageData.id))
    .map('tpaApplicationId')
    .uniq()
    .value();
}

// a page is a section page if and only if it is not a TPA special page in itself AND there is at least 1 TPA special page in it's TPA application (same TPA application id)
function isSectionPage(
  TPASpecialPagesIdsMap,
  TPAApplicationIdsThatHaveSpecialPages,
  pageData,
) {
  return (
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/includes
    _.includes(
      TPAApplicationIdsThatHaveSpecialPages,
      pageData.tpaApplicationId,
    ) && !_.has(TPASpecialPagesIdsMap, pageData.id)
  );
}

const tpaPages = {
  getSectionPagesDataList(editorAPI, pageDataList) {
    //cache the results of isTPASpecialPage to save time
    const TPASpecialPagesIdsMap = getTPASpecialPagesIdsMap(
      pageDataList,
      editorAPI,
    );
    const TPAApplicationIdsThatHaveSpecialPages =
      getTPAApplicationIdsThatHaveSpecialPages(
        pageDataList,
        TPASpecialPagesIdsMap,
      );
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/filter
    return _.filter(
      pageDataList,
      _.partial(
        isSectionPage,
        TPASpecialPagesIdsMap,
        TPAApplicationIdsThatHaveSpecialPages,
      ),
    );
  },

  getSpecialPagesDataList(editorAPI, pageDataList) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/filter
    return _.filter(pageDataList, (pageData) =>
      tpaTypeUtils.isTPASpecialPage(editorAPI.dsRead, pageData, true),
    );
  },

  getSpecialPagesDataListByTPAId(editorAPI, tpaApplicationId, pagesDataList) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/filter
    return _.filter(
      tpaPages.getSpecialPagesDataList(
        editorAPI,
        pagesDataList || editorAPI.dsRead.pages.getPagesData(),
      ),
      ['tpaApplicationId', tpaApplicationId],
    );
  },
};

export default tpaPages;
