// @ts-nocheck
import * as core from '@/core';
import addPanelDataConsts from '@/addPanelDataConsts';
import coreUtilsLib from 'coreUtilsLib';

const { blogAppPartNames } = coreUtilsLib;

export default {
  type: addPanelDataConsts.SECTIONS_TYPES.PRESET,
  hide: false,
  title: 'BLOG_ADD_PANE_RELATED_POSTS_TITLE',
  showSectionHeader: true,
  isDisabledFn(editorAPI) {
    const focusedPageData = editorAPI.dsRead.pages.data.get(
      editorAPI.dsRead.pages.getFocusedPageId(),
    );

    return !core.utils.blogTypeUtils.isBlogSpecialPage(focusedPageData);
  },
  onHoverDisabledTooltipClassPath:
    'compPanels.panels.AppPart.singlePostPageOnlyCompsTooltip',
  additionalBehaviours: {
    labelMode: addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
    infoIcon: addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
    hoverImageAction: addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
    iconEnabledForComps: {},
  },
  props: {
    image: 'blog/sections/relatedPostsSection.png',
    imageHover: null,
    items: [
      {
        id: 'Blog_Related_Post_1',
        structure: {
          type: 'Component',
          skin: 'wysiwyg.viewer.skins.AppPartSkin',
          layout: {
            width: 697,
            height: 297,
            x: 19,
            y: 58,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          componentType: 'wixapps.integration.components.AppPart',
          data: {
            type: 'AppPart',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
            },
            appInnerID: '12',
            appPartName: blogAppPartNames.RELATED_POSTS,
            viewName: 'RelatedPosts',
            appLogicCustomizations: [],
            appLogicParams: {},
          },
          props: {
            type: 'AppPartProperties',
            metaData: { schemaVersion: '1.0' },
            direction: 'ltr',
          },
          style: {
            type: 'TopLevelStyle',
            id: 'blog_e000b4bf-9ff1-4e66-a0d3-d4b365ba3af5_1',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
            },
            style: {
              groups: {},
              properties: { rd: '7px' },
              propertiesSource: { rd: 'value' },
            },
            componentClassName: '',
            pageId: '',
            compId: '',
            styleType: 'system',
            skin: 'wysiwyg.viewer.skins.AppPartSkin',
          },
          id: 'i74zy4hy_1',
        },
        preset: { rect: { width: 324, height: 146, x: 0, y: 0 }, label: '' },
      },
    ],
    compTypes: ['wixapps.integration.components.AppPart'],
  },
  help: { hide: false, text: 'add_section_info_text_blog_related_posts' },
};
