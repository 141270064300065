// @ts-nocheck
import wixFormsConstants from '../../contact/wixForms/constants';
import * as wixFormsUtils from '../../contact/wixForms/utils';
import { array } from '@/util';

const addForm = (structure) => {
  const form = wixFormsUtils.getFormWidget({
    layout: {
      width: 585,
      height: 340,
      x: 201.5,
      y: 343,
      scale: 1,
      rotationInDegrees: 0,
      fixedPosition: false,
    },
    mobileLayout: {
      x: 23,
      y: 185,
      scale: 1,
      rotationInDegrees: 0,
      fixedPosition: false,
    },
  });

  // add form to
  // components: [
  //     component_0: {
  //         components: [
  //             component_00,
  //             component_01,
  //             component_02,
  //             form
  //         ]
  //     },
  // ]
  return {
    ...structure,
    components: array.immutableSplice(structure.components, 0, 1, {
      ...structure.components[0],
      components: array.immutableSplice(
        structure.components[0].components,
        3,
        0,
        form,
      ),
    }),
  };
};

export default (onDrop, onClick) => (item) => {
  onDrop = onDrop(wixFormsConstants.FormPreset.STRIP04);
  onClick = onClick(wixFormsConstants.FormPreset.STRIP04);

  return {
    ...item,
    onDrop,
    onClick,
    structure: addForm(item.structure),
  };
};
