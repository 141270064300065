import stripGfppEnhancer from './stripGfppEnhancer';
import appStudioGfppEnhancer from './appStudioGfppEnhancer';
import dataBindingGfppEnhancer from './dataBindingGfppEnhancer';
import refComponentGfppEnhancer from './refComponentGfppEnhancer';
import permissionsGfppEnhancer from './permissionsGfppEnhancer';

export default [
  stripGfppEnhancer,
  appStudioGfppEnhancer,
  dataBindingGfppEnhancer,
  refComponentGfppEnhancer,
  permissionsGfppEnhancer,
];
