'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')
module.exports = {
    'type': addPanelDataConsts.SECTIONS_TYPES.PRESET,
    'hide': false,
    'title': 'add_section_label_address_input',
    'subNavigationTitle': 'add_section_label_address_input',
    'topTitle': "add_section_label_address_input",
    'presetTitle': "add_section_label_address_input",
    'tooltipTitle': "add_section_label_address_input",
    "automationId": "add-panel-section-addressInputSection",
    'subNavigationHide': false,
    'showSectionHeader': true,
    'additionalBehaviours': {
        'labelMode': addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
        'infoIcon': addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
        'hoverImageAction': addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
        'iconEnabledForComps': {}
    },
    'props': {
        'image': 'addPanelData/sections/developer_en/addressInputSection_en.png',
        'imageHover': null,
        'items': [{
            'id': 'User_Input_AddressInput_1',
            'structure': {
                'type': 'Component',
                'skin': 'wixui.skins.AddressInput',
                'layout': {
                    'width': 304,
                    'height': 43,
                    'x': 10,
                    'y': 20,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wixui.AddressInput',
                'data': {
                    'type': 'AddressInput',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'locale': 'en',
                    'googleMapsApiKey': '',
                    'filter': {},
                    'sorting': {},
                    'placeholder': 'Add Your Address'
                },
                'props': {
                    'type': 'AddressInputProperties',
                    'metaData': {'schemaVersion': '1.0', 'autoGenerated': false},
                    'required': false,
                    'readOnly': false,
                    'labelMargin': 14,
                    'labelPadding': 0,
                    'alignment': 'left',
                    'margin': 15
                },
                'mobileHints': {
                    'type': 'MobileHints',
                    'author': 'duplicate',
                    'originalCompId': 'comp-jjy1cm40',
                    'recommendedScale': 1,
                    'recommendedWidth': 280,
                    'recommendedHeight': 43
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {
                            'alpha-dropdownDividerStrokeColor': '1',
                            'alpha-dropdownHoverBackgroundColor': '1',
                            'alpha-dropdownLocationIconColor': '1',
                            'alpha-inputBackgroundColor': '1',
                            'boxShadowToggleOn-dropdownBoxShadow': 'false',
                            'boxShadowToggleOn-inputBoxShadow': 'false',
                            'dropdownBackgroundColor': '#ffffff',
                            'dropdownBoxShadow': '0px 1px 4px 0px rgba(0,0,0,0.6)',
                            'dropdownDividerStrokeColor': '#4D4D4D',
                            'dropdownDividerStrokeWidth': '1',
                            'dropdownFont': 'normal normal normal 12px/1.4em avenir-lt-w01_85-heavy1475544',
                            'dropdownHoverBackgroundColor': 'rgba(242,242,242,0.7)',
                            'dropdownHoverTextColor': '#000000',
                            'dropdownLocationIconColor': '#4D4D4D',
                            'dropdownOptionPadding': '10',
                            'dropdownStrokeColor': '#000000',
                            'dropdownStrokeWidth': '2',
                            'dropdownTextColor': '#383838',
                            'inputBackgroundColor': '#FFFFFF',
                            'inputBorderRadius': '0px 0px 0px 0px',
                            'inputBoxShadow': '0px 1px 4px 0px rgba(0,0,0,0.6)',
                            'inputDisabledBackgroundColor': '#ffffff',
                            'inputDisabledStrokeColor': '#DBDBDB',
                            'inputDisabledStrokeWidth': '1',
                            'inputDisabledTextColor': '#7F808A',
                            'inputErrorStrokeColor': '#FF4040',
                            'inputFocusBackgroundColor': '#ffffff',
                            'inputFocusStrokeColor': '#000000',
                            'inputFocusStrokeWidth': '2',
                            'inputFont': 'normal normal normal 14px/1.4em avenir-lt-w01_35-light1475496',
                            'inputHoverBackgroundColor': '#F2F2F2',
                            'inputHoverStrokeColor': '#5C5C5C',
                            'inputHoverStrokeWidth': '2',
                            'inputPlaceholderFont': 'normal normal normal 14px/1.4em avenir-lt-w01_35-light1475496',
                            'inputPlaceholderTextColor': '#7F808A',
                            'inputStrokeColor': '#919191',
                            'inputStrokeWidth': '2',
                            'inputTextColor': '#383838',
                            'inputErrorBackgroundColor': '#ffffff',
                            'labelFont': 'normal normal normal 14px/1.4em avenir-lt-w01_35-light1475496',
                            'txtlbl': '#383838',
                            'txtlblrq': '#383838'
                        },
                        'propertiesSource': {
                            'alpha-dropdownDividerStrokeColor': 'value',
                            'alpha-dropdownHoverBackgroundColor': 'value',
                            'alpha-dropdownLocationIconColor': 'value',
                            'alpha-inputBackgroundColor': 'value',
                            'boxShadowToggleOn-dropdownBoxShadow': 'value',
                            'boxShadowToggleOn-inputBoxShadow': 'value',
                            'dropdownBackgroundColor': 'value',
                            'dropdownBoxShadow': 'value',
                            'dropdownDividerStrokeColor': 'value',
                            'dropdownDividerStrokeWidth': 'value',
                            'dropdownFont': 'value',
                            'dropdownHoverBackgroundColor': 'value',
                            'dropdownHoverTextColor': 'value',
                            'dropdownLocationIconColor': 'value',
                            'dropdownOptionPadding': 'value',
                            'dropdownStrokeColor': 'value',
                            'dropdownStrokeWidth': 'value',
                            'dropdownTextColor': 'value',
                            'inputBackgroundColor': 'value',
                            'inputBorderRadius': 'value',
                            'inputBoxShadow': 'value',
                            'inputDisabledBackgroundColor': 'value',
                            'inputDisabledStrokeColor': 'value',
                            'inputDisabledStrokeWidth': 'value',
                            'inputDisabledTextColor': 'value',
                            'inputErrorStrokeColor': 'value',
                            'inputFocusBackgroundColor': 'value',
                            'inputFocusStrokeColor': 'value',
                            'inputFocusStrokeWidth': 'value',
                            'inputFont': 'value',
                            'inputHoverBackgroundColor': 'value',
                            'inputHoverStrokeColor': 'value',
                            'inputHoverStrokeWidth': 'value',
                            'inputPlaceholderFont': 'value',
                            'inputPlaceholderTextColor': 'value',
                            'inputStrokeColor': 'value',
                            'inputStrokeWidth': 'value',
                            'inputTextColor': 'value',
                            'inputErrorBackgroundColor': 'value',
                            'labelFont': 'value',
                            'txtlbl': 'value',
                            'txtlblrq': 'value'
                        }
                    },
                    'componentClassName': 'wixui.AddressInput',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'custom',
                    'skin': 'wixui.skins.AddressInput'
                },
                'activeModes': {},
                'id': 'comp-jnokk737'
            },
            'preset': {'rect': {'width': 324, 'height': 143, 'x': 0, 'y': 0}, 'tags': null}
        }, {
            'id': 'User_Input_AddressInput_2',
            'structure': {
                'type': 'Component',
                'skin': 'wixui.skins.AddressInput',
                'layout': {
                    'width': 304,
                    'height': 46,
                    'x': 10,
                    'y': 159,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wixui.AddressInput',
                'data': {
                    'type': 'AddressInput',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'locale': 'en',
                    'googleMapsApiKey': '',
                    'filter': {},
                    'sorting': {},
                    'placeholder': 'Add Your Address'
                },
                'props': {
                    'type': 'AddressInputProperties',
                    'metaData': {'schemaVersion': '1.0', 'autoGenerated': false},
                    'required': false,
                    'readOnly': false,
                    'labelMargin': 14,
                    'labelPadding': 0,
                    'alignment': 'left',
                    'iconVisible': true,
                    'dividerVisible': false,
                    'margin': 15
                },
                'mobileHints': {
                    'type': 'MobileHints',
                    'author': 'duplicate',
                    'originalCompId': 'comp-jjy1cm40',
                    'recommendedScale': 1,
                    'recommendedWidth': 280,
                    'recommendedHeight': 46
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {
                            'alpha-dropdownDividerStrokeColor': '1',
                            'alpha-dropdownHoverBackgroundColor': '0.2',
                            'alpha-dropdownLocationIconColor': '1',
                            'alpha-inputBackgroundColor': '1',
                            'boxShadowToggleOn-dropdownBoxShadow': 'false',
                            'boxShadowToggleOn-inputBoxShadow': 'false',
                            'dropdownBackgroundColor': '#ffffff',
                            'dropdownBoxShadow': '0px 1px 4px 0px rgba(0,0,0,0.6)',
                            'dropdownDividerStrokeColor': '#4D4D4D',
                            'dropdownDividerStrokeWidth': '1',
                            'dropdownFont': 'normal normal 700 14px/1.4em bree-w01-thin-oblique',
                            'dropdownHoverBackgroundColor': 'rgba(71,219,123,0.7)',
                            'dropdownHoverTextColor': '#000000',
                            'dropdownLocationIconColor': '#C6C6C6',
                            'dropdownOptionPadding': '10',
                            'dropdownStrokeColor': '#47DB7B',
                            'dropdownStrokeWidth': '1',
                            'dropdownTextColor': '#000000',
                            'inputBackgroundColor': '#47DB7B',
                            'inputBorderRadius': '6px 6px 6px 6px',
                            'inputBoxShadow': '0px 1px 4px 0px rgba(0,0,0,0.6)',
                            'inputDisabledBackgroundColor': '#DBDBDB',
                            'inputDisabledStrokeColor': '#DBDBDB',
                            'inputDisabledStrokeWidth': '1',
                            'inputDisabledTextColor': '#FFFFFF',
                            'inputErrorStrokeColor': '#FF4040',
                            'inputFocusBackgroundColor': '#5EE08A',
                            'inputFocusStrokeColor': '#47DB7B',
                            'inputFocusStrokeWidth': '1',
                            'inputFont': 'normal normal normal 15px/1.4em bree-w01-thin-oblique',
                            'inputHoverBackgroundColor': '#5EE08A',
                            'inputHoverStrokeColor': '#5C5C5C',
                            'inputHoverStrokeWidth': '0',
                            'inputPlaceholderFont': 'normal normal normal 14px/1.4em avenir-lt-w01_35-light1475496',
                            'inputPlaceholderTextColor': '#FFFFFF',
                            'inputStrokeColor': '#4D4D4D',
                            'inputStrokeWidth': '0',
                            'inputTextColor': '#FFFFFF',
                            'inputErrorBackgroundColor': '#FF4040',
                            'labelFont': 'normal normal normal 15px/1.4em bree-w01-thin-oblique',
                            'txtlbl': '#4BDB7B',
                            'txtlblrq': '#4BDB7B'
                        },
                        'propertiesSource': {
                            'alpha-dropdownDividerStrokeColor': 'value',
                            'alpha-dropdownHoverBackgroundColor': 'value',
                            'alpha-dropdownLocationIconColor': 'value',
                            'alpha-inputBackgroundColor': 'value',
                            'boxShadowToggleOn-dropdownBoxShadow': 'value',
                            'boxShadowToggleOn-inputBoxShadow': 'value',
                            'dropdownBackgroundColor': 'value',
                            'dropdownBoxShadow': 'value',
                            'dropdownDividerStrokeColor': 'value',
                            'dropdownDividerStrokeWidth': 'value',
                            'dropdownFont': 'value',
                            'dropdownHoverBackgroundColor': 'value',
                            'dropdownHoverTextColor': 'value',
                            'dropdownLocationIconColor': 'value',
                            'dropdownOptionPadding': 'value',
                            'dropdownStrokeColor': 'value',
                            'dropdownStrokeWidth': 'value',
                            'dropdownTextColor': 'value',
                            'inputBackgroundColor': 'value',
                            'inputBorderRadius': 'value',
                            'inputBoxShadow': 'value',
                            'inputDisabledBackgroundColor': 'value',
                            'inputDisabledStrokeColor': 'value',
                            'inputDisabledStrokeWidth': 'value',
                            'inputDisabledTextColor': 'value',
                            'inputErrorStrokeColor': 'value',
                            'inputFocusBackgroundColor': 'value',
                            'inputFocusStrokeColor': 'value',
                            'inputFocusStrokeWidth': 'value',
                            'inputFont': 'value',
                            'inputHoverBackgroundColor': 'value',
                            'inputHoverStrokeColor': 'value',
                            'inputHoverStrokeWidth': 'value',
                            'inputPlaceholderFont': 'value',
                            'inputPlaceholderTextColor': 'value',
                            'inputStrokeColor': 'value',
                            'inputStrokeWidth': 'value',
                            'inputTextColor': 'value',
                            'inputErrorBackgroundColor': 'value',
                            'labelFont': 'value',
                            'txtlbl': 'value',
                            'txtlblrq': 'value'
                        }
                    },
                    'componentClassName': 'wixui.AddressInput',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'custom',
                    'skin': 'wixui.skins.AddressInput'
                },
                'activeModes': {},
                'id': 'comp-jnolgfr5'
            },
            'preset': {'rect': {'width': 324, 'height': 138, 'x': 0, 'y': 142}, 'tags': null}
        }, {
            'id': 'User_Input_AddressInput_3',
            'structure': {
                'type': 'Component',
                'skin': 'wixui.skins.AddressInput',
                'layout': {
                    'width': 304,
                    'height': 46,
                    'x': 10,
                    'y': 295,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wixui.AddressInput',
                'data': {
                    'type': 'AddressInput',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'locale': 'en',
                    'googleMapsApiKey': '',
                    'filter': {},
                    'sorting': {},
                    'placeholder': 'Add Your Address'
                },
                'props': {
                    'type': 'AddressInputProperties',
                    'metaData': {'schemaVersion': '1.0', 'autoGenerated': false},
                    'required': false,
                    'readOnly': false,
                    'labelMargin': 14,
                    'labelPadding': 0,
                    'alignment': 'left',
                    'margin': 15
                },
                'mobileHints': {
                    'type': 'MobileHints',
                    'author': 'duplicate',
                    'originalCompId': 'comp-jjy1cm40',
                    'recommendedScale': 1,
                    'recommendedWidth': 280,
                    'recommendedHeight': 46
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {
                            'alpha-dropdownDividerStrokeColor': '1',
                            'alpha-dropdownHoverBackgroundColor': '1',
                            'alpha-dropdownLocationIconColor': '1',
                            'alpha-inputBackgroundColor': '1',
                            'boxShadowToggleOn-dropdownBoxShadow': 'false',
                            'boxShadowToggleOn-inputBoxShadow': 'false',
                            'dropdownBackgroundColor': '#ffffff',
                            'dropdownBoxShadow': '0px 1px 4px 0px rgba(0,0,0,0.6)',
                            'dropdownDividerStrokeColor': '#4EB7F5',
                            'dropdownDividerStrokeWidth': '1',
                            'dropdownFont': 'normal normal normal 12px/1.4em helvetica-w01-roman',
                            'dropdownHoverBackgroundColor': 'rgba(234,247,255,0.7)',
                            'dropdownHoverTextColor': '#162D3D',
                            'dropdownLocationIconColor': '#959595',
                            'dropdownOptionPadding': '10',
                            'dropdownStrokeColor': '#4EB7F5',
                            'dropdownStrokeWidth': '1',
                            'dropdownTextColor': '#162D3D',
                            'inputBackgroundColor': '#FFFFFF',
                            'inputBorderRadius': '6px 6px 6px 6px',
                            'inputBoxShadow': '0px 1px 4px 0px rgba(0,0,0,0.6)',
                            'inputDisabledBackgroundColor': '#ffffff',
                            'inputDisabledStrokeColor': '#DBDBDB',
                            'inputDisabledStrokeWidth': '1',
                            'inputDisabledTextColor': '#7F808A',
                            'inputErrorStrokeColor': '#FF4040',
                            'inputFocusBackgroundColor': '#FFFFFF',
                            'inputFocusStrokeColor': '#4EB7F5',
                            'inputFocusStrokeWidth': '1',
                            'inputFont': 'normal normal normal 15px/1.4em helvetica-w01-light',
                            'inputHoverBackgroundColor': '#EAF7FF',
                            'inputHoverStrokeColor': '#5C5C5C',
                            'inputHoverStrokeWidth': '0',
                            'inputPlaceholderFont': 'normal normal normal 14px/1.4em avenir-lt-w01_35-light1475496',
                            'inputPlaceholderTextColor': '#B6C1CD',
                            'inputStrokeColor': '#C6E2F7',
                            'inputStrokeWidth': '1',
                            'inputTextColor': '#162D3D',
                            'inputErrorBackgroundColor': '#ffffff',
                            'labelFont': 'normal normal normal 15px/1.4em helvetica-w01-light',
                            'txtlbl': '#162D3D',
                            'txtlblrq': '#162D3D'
                        },
                        'propertiesSource': {
                            'alpha-dropdownDividerStrokeColor': 'value',
                            'alpha-dropdownHoverBackgroundColor': 'value',
                            'alpha-dropdownLocationIconColor': 'value',
                            'alpha-inputBackgroundColor': 'value',
                            'boxShadowToggleOn-dropdownBoxShadow': 'value',
                            'boxShadowToggleOn-inputBoxShadow': 'value',
                            'dropdownBackgroundColor': 'value',
                            'dropdownBoxShadow': 'value',
                            'dropdownDividerStrokeColor': 'value',
                            'dropdownDividerStrokeWidth': 'value',
                            'dropdownFont': 'value',
                            'dropdownHoverBackgroundColor': 'value',
                            'dropdownHoverTextColor': 'value',
                            'dropdownLocationIconColor': 'value',
                            'dropdownOptionPadding': 'value',
                            'dropdownStrokeColor': 'value',
                            'dropdownStrokeWidth': 'value',
                            'dropdownTextColor': 'value',
                            'inputBackgroundColor': 'value',
                            'inputBorderRadius': 'value',
                            'inputBoxShadow': 'value',
                            'inputDisabledBackgroundColor': 'value',
                            'inputDisabledStrokeColor': 'value',
                            'inputDisabledStrokeWidth': 'value',
                            'inputDisabledTextColor': 'value',
                            'inputErrorStrokeColor': 'value',
                            'inputFocusBackgroundColor': 'value',
                            'inputFocusStrokeColor': 'value',
                            'inputFocusStrokeWidth': 'value',
                            'inputFont': 'value',
                            'inputHoverBackgroundColor': 'value',
                            'inputHoverStrokeColor': 'value',
                            'inputHoverStrokeWidth': 'value',
                            'inputPlaceholderFont': 'value',
                            'inputPlaceholderTextColor': 'value',
                            'inputStrokeColor': 'value',
                            'inputStrokeWidth': 'value',
                            'inputTextColor': 'value',
                            'inputErrorBackgroundColor': 'value',
                            'labelFont': 'value',
                            'txtlbl': 'value',
                            'txtlblrq': 'value'
                        }
                    },
                    'componentClassName': 'wixui.AddressInput',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'custom',
                    'skin': 'wixui.skins.AddressInput'
                },
                'activeModes': {},
                'id': 'comp-jnondo6z'
            },
            'preset': {'rect': {'width': 324, 'height': 143, 'x': 0, 'y': 280}, 'tags': null}
        }],
        'compTypes': ['wixui.AddressInput']
    },
    'help': {'hide': false, 'text': 'add_section_label_address_input_tooltip_description'}
}
