// @ts-nocheck
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import * as core from '@/core';
import * as util from '@/util';
import { translate } from '@/i18n';
import React from 'react';
import * as baseUI from '@/baseUI';
import { Composites, Illustration, RichText } from '@wix/wix-base-ui';
import { OldMessagePanelFrame } from '../frames';

const LEARN_MORE_LINK =
  'https://{$lang}.wix.com/support/html5/new-wix-editor-june-2015/using-your-editor/kb/suggestions-and-feedback-new-wix-editor-2015';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'switchToOldEditorConfirmationPanel',
  mixins: [core.mixins.editorAPIMixin],
  propTypes: {
    title: PropTypes.string.isRequired,
    messageBody: PropTypes.string.isRequired,
  },

  handleBackToOld() {
    util.cookie.setUserPetriOverrideCookie('SantaEditorUserOverride', 'old');
    window.onbeforeunload = null;
    window.location.reload(true);
  },

  close() {
    this.getEditorAPI().panelManager.closePanelByName(this.props.panelName);
  },

  linkClick() {
    if (window) {
      const langCode = util.languages.getLanguageCode();
      const link = LEARN_MORE_LINK.replace(
        '{$lang}',
        langCode === 'en' ? 'www' : langCode,
      );
      window.open(link);
    }
  },

  render() {
    return (
      <OldMessagePanelFrame
        ref="frame"
        panelName={this.props.panelName}
        panelTitle={translate(this.props.title)}
        confirmLabel="Notification_Box__ToOldEditor_Button_ToOldEditor"
        onConfirm={this.handleBackToOld}
        cancelLabel="Notification_Box__ToOldEditor_Button_Cancel"
        onCancel={this.close}
        className="switch-to-old-editor"
      >
        <Composites.RichTextWithIllustration>
          <Illustration>
            <baseUI.symbol name="switchToOldEditorPanel" />
          </Illustration>
          <Composites.RichText>
            <RichText type="T01">
              <p>{translate('Notification_Box__ToOldEditor_Text1')}</p>
              <p>
                {translate('Notification_Box__ToOldEditor_Text2')}
                <a
                  onClick={() => {
                    this.linkClick();
                  }}
                >
                  {translate('Notification_Box__ToOldEditor_link')}
                </a>
              </p>
            </RichText>
          </Composites.RichText>
        </Composites.RichTextWithIllustration>
      </OldMessagePanelFrame>
    );
  },
});
