import { ClipboardApiKey } from '@/apis';
import { sections as sectionsUtils } from '@/util';

import type { CompRef } from 'types/documentServices';
import type { MapDispatchToProps, ThunkAction } from 'types/redux';
import type { GroupingItem } from '@/sectionsMigration';
import type {
  CopyPasteBarDispatchProps,
  CopyPasteBarOwnProps,
} from './copyPasteBar';

const CLIPBOARD_TYPE = 'sectionizer';

const mapDispatchToProps: MapDispatchToProps<
  CopyPasteBarDispatchProps,
  CopyPasteBarOwnProps
> = {
  copy:
    (): ThunkAction =>
    (dispatch, getState, { editorAPI, host }) => {
      const clipboardApi = host.getAPI(ClipboardApiKey);

      const siteSections = editorAPI.pages
        .getPageIdList()
        .reduce<Record<string, unknown>>((acc, pageId: string) => {
          const pageSections = editorAPI.components.features.get(
            {
              id: pageId,
              type: 'DESKTOP',
            },
            sectionsUtils.constants.PAGE_SECTIONS_EDITOR_DATA_NAMESPACE,
          )?.sections;

          if (!pageSections) {
            return acc;
          }

          acc[pageId] = pageSections;

          return acc;
        }, {});

      return clipboardApi.setItem(
        {
          siteSections,
          siteName: editorAPI.siteDisplayName.get(),
        },
        CLIPBOARD_TYPE,
      );
    },
  paste:
    (): ThunkAction =>
    (dispatch, getState, { editorAPI, host }) => {
      const clipboardApi = host.getAPI(ClipboardApiKey);

      const hasClipboard = clipboardApi.hasItemOfType(CLIPBOARD_TYPE);
      // @ts-expect-error
      const copiedSiteSections = clipboardApi.getItem()?.value?.siteSections;

      if (hasClipboard && copiedSiteSections) {
        editorAPI.pages.getPageIdList().forEach((pageId) => {
          const copiedPageSections = copiedSiteSections[pageId];

          if (!copiedPageSections) {
            return;
          }

          const pageChildrenIds = editorAPI.components
            .getChildren(editorAPI.components.get.byId(pageId))
            .map(({ id }: CompRef) => id);

          const pageSections = copiedPageSections.map(
            (sectionMetaData: GroupingItem) => ({
              ...sectionMetaData,
              children: sectionMetaData.children.filter((id: string) =>
                pageChildrenIds.includes(id),
              ),
            }),
          );

          editorAPI.components.features.update(
            {
              id: pageId,
              type: 'DESKTOP',
            },
            sectionsUtils.constants.PAGE_SECTIONS_EDITOR_DATA_NAMESPACE,
            {
              sections: pageSections,
            },
          );
        });

        return editorAPI.waitForChangesAppliedAsync();
      }
    },
  clear:
    (): ThunkAction =>
    (dispatch, getState, { host }) => {
      const clipboardApi = host.getAPI(ClipboardApiKey);
      clipboardApi.removeItem();
    },

  getClipboardState:
    (): ThunkAction =>
    (dispatch, getState, { host }) => {
      const clipboardApi = host.getAPI(ClipboardApiKey);
      const hasClipboard = clipboardApi.hasItemOfType(CLIPBOARD_TYPE);
      const clipboardValue = clipboardApi.getItem();

      return {
        hasClipboard,
        // @ts-expect-error
        siteName: clipboardValue?.value?.siteName,
      };
    },
};

export { mapDispatchToProps };
