import { createReduxStore } from '@/apilib';

export interface AiWriterPanelStore {}

export interface AiWriterPanelBiData {
  origin?: string;
}

export interface AiWriterPanelState {
  biData: AiWriterPanelBiData;
}

const getInitialState = (): AiWriterPanelState => ({
  biData: null,
});

export const AiWriterPanelStore = createReduxStore({
  getInitialState,
  selectors: {
    getBiData: (state: AiWriterPanelState) => state.biData,
    getOrigin: (state: AiWriterPanelState) => state.biData?.origin,
  },
  actions: {
    setBiData: (
      state: AiWriterPanelState,
      biData: AiWriterPanelState['biData'],
    ) => {
      return {
        ...state,
        biData: { ...state.biData, ...biData },
      };
    },
  },
});
