import React from 'react';
import experiment from 'experiment';
import { JiraIssueCollector } from './components/JiraIssueCollector';
import type { Scope } from './scope';

export async function init(scope: Scope) {
  // eslint-disable-next-line @wix/santa/no-falsy-experiment
  if (!experiment.isOpen('se_jiraIssueCollector')) {
    return;
  }

  const { dsRead } = await scope.editorCoreApi.hooks._dsIsReady.promise;
  const { metaSiteId, editorSessionId, userProfile } = window.editorModel;

  scope.workspaceApi.contributeWorkspaceComponent(() => (
    <JiraIssueCollector
      collectorParameters={{
        metaSiteId,
        editorSessionId,
        userInfo: {
          firstName: userProfile.firstName,
          lastName: userProfile.lastName,
          userId: dsRead.generalInfo.getUserInfo().userId,
          email: dsRead.generalInfo.getUserInfo().email,
        },
      }}
    />
  ));
}
