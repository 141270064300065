"use strict";
const addPanelDataConsts = require("@/addPanelDataConsts");
module.exports = {
  type: addPanelDataConsts.SECTIONS_TYPES.PRESET,
  hide: false,
  title: "add_section_label_collapsible_text",
  subNavigationTitle: "add_section_label_collapsible_text",
  presetTitle: "add_section_label_collapsible_text",
  tooltipTitle: "add_section_label_collapsible_text",
  automationId: "add-panel-section-collapsibleText-paragraphsSection",
  subNavigationHide: true,
  showSectionHeader: true,
  additionalBehaviours: {
    labelMode: addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
    infoIcon: addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
    hoverImageAction: addPanelDataConsts.HOVER_IMAGE_ACTIONS.IMAGE,
    iconEnabledForComps: {},
  },
  props: {
    image: "addPanelData/sections/CollapsibleTextSection_en/Collapsible_text_Paragraph_Regular@1.png",
    imageHover:
      "addPanelData/sections/CollapsibleTextSection_en/Collapsible_text_Paragraph_Hover@1.png",
    items: [
      {
        id: "CollapsibleText_0",
        structure: {
          type: "Component",
          metaData: {
            pageId: "c1dmp",
          },
          skin: "wixui.skins.Skinless",
          layout: {
            width: 300,
            height: 77,
            x: 10,
            y: 20,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          componentType: "wixui.CollapsibleText",
          data: {
            linkText: "Read more",
            readMoreText: "Read more",
            tag: "p",
            text: "Collapsible text is perfect for longer content like paragraphs and descriptions. It’s a great way to give people more information while keeping your layout clean. Link your text to anything, including an external website or a different page. You can set your text box to expand and collapse when people click, so they can read more or less info.",
            readLessText: "Read less",
            type: "CollapsibleTextData",
            metaData: {
              isHidden: false,
              schemaVersion: "1.0",
              isPreset: false,
              pageId: "c1dmp",
            },
          },
          props: {
            lastUserDefinedWidth: 300,
            maxLines: 2,
            ellipsis: true,
            expandMode: "button",
            align: "left",
            direction: "ltr",
            wrappingStyle: "multi-line",
            expandControlSpacing: 10,
            showExpandControl: true,
            isExpanded: false,
            type: "CollapsibleTextProperties",
            metaData: {
              schemaVersion: "1.0",
              autoGenerated: false,
              pageId: "c1dmp",
            },
          },
          mobileHints: {
            type: "MobileHints",
            metaData: {
              isPreset: false,
              schemaVersion: "1.0",
              isHidden: false,
              pageId: "c1dmp",
            },
            author: "duplicate",
            originalCompId: "comp-kvgq5z5e",
            recommendedScale: 1,
            recommendedWidth: 263,
            recommendedHeight: 67,
          },
          style: {
            type: "ComponentStyle",
            metaData: {
              isPreset: false,
              schemaVersion: "1.0",
              isHidden: false,
              pageId: "c1dmp",
            },
            style: {
              properties: {
                "$st-css":
                  ".root::expand-control {\n    font-family: avenir-lt-w01_85-heavy1475544,avenir-lt-w05_85-heavy,sans-serif;\n    color: #000000;\n}\n.root::expand-control:hover {\n    color: rgba(0,0,0,0.7);\n}\n.root::expand-control:disabled {\n    color: #AAAAAA;\n}:import {-st-from: 'wix-ui-santa/index.st.css'; -st-named: CollapsibleText;} .root { -st-extends: CollapsibleText; color: #dfe6e9; font-size: 16px; } .root::text {\n    font-size: 16px;\n    font-weight: normal;\n    font-style: normal;\n    line-height: 1.5em;\n    font-family: avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif;\n    color: #000000;\n}",
              },
              propertiesSource: {
                "$st-css": "value",
              },
            },
            componentClassName: "wixui.CollapsibleText",
            pageId: "",
            compId: "",
            styleType: "custom",
            skin: "wixui.skins.Skinless",
          },
          mobileStructure: {
            type: "Component",
            metaData: {
              pageId: "c1dmp",
            },
            skin: "wixui.skins.Skinless",
            layout: {
              width: 263,
              height: 67,
              x: 9,
              y: 10,
              scale: 1,
              rotationInDegrees: 0,
              fixedPosition: false,
            },
            componentType: "wixui.CollapsibleText",
            data: {
              linkText: "Read more",
              readMoreText: "Read more",
              tag: "p",
              text: "Collapsible text is perfect for longer content like paragraphs and descriptions. It’s a great way to give people more information while keeping your layout clean. Link your text to anything, including an external website or a different page. You can set your text box to expand and collapse when people click, so they can read more or less info.",
              readLessText: "Read less",
              type: "CollapsibleTextData",
              metaData: {
                isHidden: false,
                schemaVersion: "1.0",
                isPreset: false,
                pageId: "c1dmp",
              },
            },
            props: {
              lastUserDefinedWidth: 300,
              maxLines: 2,
              ellipsis: true,
              expandMode: "button",
              align: "left",
              direction: "ltr",
              wrappingStyle: "multi-line",
              expandControlSpacing: 10,
              showExpandControl: true,
              isExpanded: false,
              type: "CollapsibleTextProperties",
              metaData: {
                schemaVersion: "1.0",
                autoGenerated: false,
                pageId: "c1dmp",
              },
            },
            mobileHints: {
              type: "MobileHints",
              metaData: {
                isPreset: false,
                schemaVersion: "1.0",
                isHidden: false,
                pageId: "c1dmp",
              },
              author: "duplicate",
              originalCompId: "comp-kvgq5z5e",
              recommendedScale: 1,
              recommendedWidth: 263,
              recommendedHeight: 67,
            },
            style: {
              type: "ComponentStyle",
              metaData: {
                isPreset: false,
                schemaVersion: "1.0",
                isHidden: false,
                pageId: "c1dmp",
              },
              style: {
                properties: {
                  "$st-css":
                    ".root::expand-control {\n    font-family: avenir-lt-w01_85-heavy1475544,avenir-lt-w05_85-heavy,sans-serif;\n    color: #000000;\n}\n.root::expand-control:hover {\n    color: rgba(0,0,0,0.7);\n}\n.root::expand-control:disabled {\n    color: #AAAAAA;\n}:import {-st-from: 'wix-ui-santa/index.st.css'; -st-named: CollapsibleText;} .root { -st-extends: CollapsibleText; color: #dfe6e9; font-size: 16px; } .root::text {\n    font-size: 16px;\n    font-weight: normal;\n    font-style: normal;\n    line-height: 1.5em;\n    font-family: avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif;\n    color: #000000;\n}",
                },
                propertiesSource: {
                  "$st-css": "value",
                },
              },
              componentClassName: "wixui.CollapsibleText",
              pageId: "",
              compId: "",
              styleType: "custom",
              skin: "wixui.skins.Skinless",
            },
            components: [],
          },
          activeModes: {},
          id: "comp-kvozgx4z",
        },
        preset: {
          rect: {
            x: 0,
            y: 0,
            width: 320,
            height: 108,
          },
          tags: null,
        },
      },
      {
        id: "CollapsibleText_1",
        structure: {
          type: "Component",
          metaData: {
            pageId: "c1dmp",
          },
          skin: "wixui.skins.Skinless",
          layout: {
            width: 300,
            height: 101,
            x: 10,
            y: 127,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          componentType: "wixui.CollapsibleText",
          data: {
            type: "CollapsibleTextData",
            text: "Collapsible text is perfect for longer content like paragraphs and descriptions. It’s a great way to give people more information while keeping your layout clean. Link your text to anything, including an external website or a different page. You can set your text box to expand and collapse when people click, so they can read more or less info.",
            metaData: {
              isPreset: false,
              schemaVersion: "1.0",
              isHidden: false,
              pageId: "c1dmp",
            },
            readMoreText: "Read more",
            readLessText: "Read less",
            linkText: "Link to full article",
            tag: "p",
          },
          props: {
            type: "CollapsibleTextProperties",
            ellipsis: true,
            showExpandControl: true,
            direction: "ltr",
            align: "left",
            maxLines: 3,
            wrappingStyle: "multi-line",
            expandControlSpacing: 10,
            expandMode: "link",
            isExpanded: false,
            metaData: {
              schemaVersion: "1.0",
              autoGenerated: false,
              pageId: "c1dmp",
            },
            lastUserDefinedWidth: 300,
          },
          mobileHints: {
            type: "MobileHints",
            metaData: {
              isPreset: false,
              schemaVersion: "1.0",
              isHidden: false,
              pageId: "c1dmp",
            },
          },
          style: {
            type: "ComponentStyle",
            metaData: {
              isPreset: false,
              schemaVersion: "1.0",
              isHidden: false,
              pageId: "c1dmp",
            },
            style: {
              properties: {
                "$st-css":
                  '.root::expand-control {\n    color: #000000;\n    font-family: cormorantgaramond-semibold,cormorantgaramond,"cormorant garamond",serif;\n    text-decoration-line: underline;\n}\n.root::expand-control:hover {\n    color: #000000;\n    font-style: italic;\n    text-decoration-line: underline;\n}\n.root::expand-control:disabled {\n    color: #AAAAAA;\n}:import {-st-from: \'wix-ui-santa/index.st.css\'; -st-named: CollapsibleText;} .root { -st-extends: CollapsibleText; color: #dfe6e9; font-size: 16px; } .root::text {\n    font-size: 16px;\n    font-weight: normal;\n    font-style: normal;\n    line-height: 1.5em;\n    color: #000000;\n    font-family: cormorantgaramond-light,cormorantgaramond,"cormorant garamond",serif;\n}',
              },
              propertiesSource: {
                "$st-css": "value",
              },
            },
            componentClassName: "wixui.CollapsibleText",
            pageId: "",
            compId: "",
            styleType: "custom",
            skin: "wixui.skins.Skinless",
          },
          mobileStructure: {
            type: "Component",
            metaData: {
              pageId: "c1dmp",
            },
            skin: "wixui.skins.Skinless",
            layout: {
              width: 263,
              height: 89,
              x: 9,
              y: 17,
              scale: 1,
              rotationInDegrees: 0,
              fixedPosition: false,
            },
            componentType: "wixui.CollapsibleText",
            data: {
              type: "CollapsibleTextData",
              text: "Collapsible text is perfect for longer content like paragraphs and descriptions. It’s a great way to give people more information while keeping your layout clean. Link your text to anything, including an external website or a different page. You can set your text box to expand and collapse when people click, so they can read more or less info.",
              metaData: {
                isPreset: false,
                schemaVersion: "1.0",
                isHidden: false,
                pageId: "c1dmp",
              },
              readMoreText: "Read more",
              readLessText: "Read less",
              linkText: "Link to full article",
              tag: "p",
            },
            props: {
              type: "CollapsibleTextProperties",
              ellipsis: true,
              showExpandControl: true,
              direction: "ltr",
              align: "left",
              maxLines: 3,
              wrappingStyle: "multi-line",
              expandControlSpacing: 10,
              expandMode: "link",
              isExpanded: false,
              metaData: {
                schemaVersion: "1.0",
                autoGenerated: false,
                pageId: "c1dmp",
              },
              lastUserDefinedWidth: 300,
            },
            mobileHints: {
              type: "MobileHints",
              metaData: {
                isPreset: false,
                schemaVersion: "1.0",
                isHidden: false,
                pageId: "c1dmp",
              },
            },
            style: {
              type: "ComponentStyle",
              metaData: {
                isPreset: false,
                schemaVersion: "1.0",
                isHidden: false,
                pageId: "c1dmp",
              },
              style: {
                properties: {
                  "$st-css":
                    '.root::expand-control {\n    color: #000000;\n    font-family: cormorantgaramond-semibold,cormorantgaramond,"cormorant garamond",serif;\n    text-decoration-line: underline;\n}\n.root::expand-control:hover {\n    color: #000000;\n    font-style: italic;\n    text-decoration-line: underline;\n}\n.root::expand-control:disabled {\n    color: #AAAAAA;\n}:import {-st-from: \'wix-ui-santa/index.st.css\'; -st-named: CollapsibleText;} .root { -st-extends: CollapsibleText; color: #dfe6e9; font-size: 16px; } .root::text {\n    font-size: 16px;\n    font-weight: normal;\n    font-style: normal;\n    line-height: 1.5em;\n    color: #000000;\n    font-family: cormorantgaramond-light,cormorantgaramond,"cormorant garamond",serif;\n}',
                },
                propertiesSource: {
                  "$st-css": "value",
                },
              },
              componentClassName: "wixui.CollapsibleText",
              pageId: "",
              compId: "",
              styleType: "custom",
              skin: "wixui.skins.Skinless",
            },
            components: [],
          },
          activeModes: {},
          id: "comp-kvgqd0d2",
        },
        preset: {
          rect: {
            x: 0,
            y: 108,
            width: 320,
            height: 140,
          },
          tags: null,
        },
      },
      {
        id: "CollapsibleText_2",
        structure: {
          type: "Component",
          metaData: {
            pageId: "c1dmp",
          },
          skin: "wixui.skins.Skinless",
          layout: {
            width: 300,
            height: 103,
            x: 10,
            y: 263,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          componentType: "wixui.CollapsibleText",
          data: {
            type: "CollapsibleTextData",
            text: "Collapsible text is perfect for longer content like paragraphs and descriptions. It’s a great way to give people more information while keeping your layout clean. Link your text to anything, including an external website or a different page. You can set your text box to expand and collapse when people click, so they can read more or less info.",
            metaData: {
              isPreset: false,
              schemaVersion: "1.0",
              isHidden: false,
              pageId: "c1dmp",
            },
            readMoreText: "Read more",
            readLessText: "Read less",
            linkText: "Show more",
            tag: "p",
          },
          props: {
            type: "CollapsibleTextProperties",
            ellipsis: true,
            showExpandControl: true,
            direction: "ltr",
            align: "left",
            maxLines: 3,
            wrappingStyle: "multi-line",
            expandControlSpacing: 10,
            expandMode: "link",
            isExpanded: false,
            metaData: {
              schemaVersion: "1.0",
              autoGenerated: false,
              pageId: "c1dmp",
            },
            lastUserDefinedWidth: 300,
          },
          mobileHints: {
            type: "MobileHints",
            metaData: {
              isPreset: false,
              schemaVersion: "1.0",
              isHidden: false,
              pageId: "c1dmp",
            },
            originalCompId: "comp-kvgqf3i2",
            recommendedScale: 1,
            recommendedWidth: 263,
            recommendedHeight: 84,
          },
          style: {
            type: "ComponentStyle",
            metaData: {
              isPreset: false,
              schemaVersion: "1.0",
              isHidden: false,
              pageId: "c1dmp",
            },
            style: {
              properties: {
                "$st-css":
                  ".root::expand-control {\n    color: #000000;\n    font-family: futura-lt-w01-book,futura-lt-w05-book,sans-serif;\n    font-weight: 400;\n    text-decoration-line: underline;\n    font-style: normal;\n}\n.root::expand-control:hover {\n    letter-spacing: 0em;\n    font-weight: 400;\n    font-style: normal;\n    text-decoration-line: underline;\n    color: #2A36FB;\n}\n.root::expand-control:disabled {\n    color: #AAAAAA;\n}:import {-st-from: 'wix-ui-santa/index.st.css'; -st-named: CollapsibleText;} .root { -st-extends: CollapsibleText; color: #dfe6e9; font-size: 16px; } .root::text {\n    font-size: 16px;\n    font-weight: normal;\n    font-style: normal;\n    line-height: 1.5em;\n    color: #000000;\n    font-family: futura-lt-w01-book,futura-lt-w05-book,sans-serif;\n}",
              },
              propertiesSource: {
                "$st-css": "value",
              },
            },
            componentClassName: "wixui.CollapsibleText",
            pageId: "",
            compId: "",
            styleType: "custom",
            skin: "wixui.skins.Skinless",
          },
          mobileStructure: {
            type: "Component",
            metaData: {
              pageId: "c1dmp",
            },
            skin: "wixui.skins.Skinless",
            layout: {
              width: 263,
              height: 91,
              x: 9,
              y: 13,
              scale: 1,
              rotationInDegrees: 0,
              fixedPosition: false,
            },
            componentType: "wixui.CollapsibleText",
            data: {
              type: "CollapsibleTextData",
              text: "Collapsible text is perfect for longer content like paragraphs and descriptions. It’s a great way to give people more information while keeping your layout clean. Link your text to anything, including an external website or a different page. You can set your text box to expand and collapse when people click, so they can read more or less info.",
              metaData: {
                isPreset: false,
                schemaVersion: "1.0",
                isHidden: false,
                pageId: "c1dmp",
              },
              readMoreText: "Read more",
              readLessText: "Read less",
              linkText: "Show more",
              tag: "p",
            },
            props: {
              type: "CollapsibleTextProperties",
              ellipsis: true,
              showExpandControl: true,
              direction: "ltr",
              align: "left",
              maxLines: 3,
              wrappingStyle: "multi-line",
              expandControlSpacing: 10,
              expandMode: "link",
              isExpanded: false,
              metaData: {
                schemaVersion: "1.0",
                autoGenerated: false,
                pageId: "c1dmp",
              },
              lastUserDefinedWidth: 300,
            },
            mobileHints: {
              type: "MobileHints",
              metaData: {
                isPreset: false,
                schemaVersion: "1.0",
                isHidden: false,
                pageId: "c1dmp",
              },
              originalCompId: "comp-kvgqf3i2",
              recommendedScale: 1,
              recommendedWidth: 263,
              recommendedHeight: 84,
            },
            style: {
              type: "ComponentStyle",
              metaData: {
                isPreset: false,
                schemaVersion: "1.0",
                isHidden: false,
                pageId: "c1dmp",
              },
              style: {
                properties: {
                  "$st-css":
                    ".root::expand-control {\n    color: #000000;\n    font-family: futura-lt-w01-book,futura-lt-w05-book,sans-serif;\n    font-weight: 400;\n    text-decoration-line: underline;\n    font-style: normal;\n}\n.root::expand-control:hover {\n    letter-spacing: 0em;\n    font-weight: 400;\n    font-style: normal;\n    text-decoration-line: underline;\n    color: #2A36FB;\n}\n.root::expand-control:disabled {\n    color: #AAAAAA;\n}:import {-st-from: 'wix-ui-santa/index.st.css'; -st-named: CollapsibleText;} .root { -st-extends: CollapsibleText; color: #dfe6e9; font-size: 16px; } .root::text {\n    font-size: 16px;\n    font-weight: normal;\n    font-style: normal;\n    line-height: 1.5em;\n    color: #000000;\n    font-family: futura-lt-w01-book,futura-lt-w05-book,sans-serif;\n}",
                },
                propertiesSource: {
                  "$st-css": "value",
                },
              },
              componentClassName: "wixui.CollapsibleText",
              pageId: "",
              compId: "",
              styleType: "custom",
              skin: "wixui.skins.Skinless",
            },
            components: [],
          },
          activeModes: {},
          id: "comp-l13t6ztf",
        },
        preset: {
          rect: {
            x: 0,
            y: 248,
            width: 320,
            height: 133,
          },
          tags: null,
        },
      },
    ],
    compTypes: ["wixui.CollapsibleText"],
  },
  help: {
    hide: true,
    text: "add_section_label_collapsible_text_tooltip_description",
  },
};
