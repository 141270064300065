import { ToursApiKey } from './api';
import { createNewWorkspaceLeftBarHighlightTour } from './tours/newWorkspaceLeftBarHighlightTour.conf';

import type { EditorAPI } from '@/editorAPI';
import type { StartOrigin } from './tours/tours.types';

export const startNewWorkspaceLeftBarHighlightTour = async (
  editorAPI: EditorAPI,
  startOrigin?: StartOrigin,
) => {
  const tourApi = editorAPI.host.getAPI(ToursApiKey);
  const welcomeTour = tourApi.createTour((shell) =>
    createNewWorkspaceLeftBarHighlightTour(shell, startOrigin),
  );

  return await tourApi.startTour(welcomeTour);
};

export { createNewWorkspaceLeftBarHighlightTour };
