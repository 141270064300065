import type { Scope } from './baseDragScope';
import {
  initDrag as initDragLifecycleScope,
  type StartDragParams,
} from './initDrag';
import {
  type DragDataFromEvent,
  getDragDataFromEvent,
  lifecycle,
} from './lifecycle';
import constants from '@/constants';
import { getKeyboardContextApi } from './keyboardContext';
import type { EditorAPI } from '@/editorAPI';
import type { MousePosition } from './types';

class RealDragTracker {
  private initMousePosition: MousePosition;
  private state: {
    isDuringRealDrag: boolean;
  };
  constructor(
    { initMousePosition }: { initMousePosition: MousePosition },
    private actions: {
      startDrag: () => void;
      onDrag: (dragData: DragDataFromEvent) => void;
      endDrag: (dragData: DragDataFromEvent) => void;
    },
  ) {
    this.state = {
      isDuringRealDrag: false,
    };
    this.initMousePosition = initMousePosition;
  }
  private isRealDrag(mousePosition: MousePosition): boolean {
    if (this.state.isDuringRealDrag) {
      return true;
    }

    return (
      Math.abs(mousePosition.x - this.initMousePosition.x) > 2 ||
      Math.abs(mousePosition.y - this.initMousePosition.y) > 2
    );
  }
  onDrag(dragData: DragDataFromEvent) {
    if (this.isRealDrag(dragData.currentMousePosition)) {
      if (!this.state.isDuringRealDrag) {
        this.state.isDuringRealDrag = true;
        this.actions.startDrag();
      }
      this.actions.onDrag(dragData);
    }
  }
  endDrag(dragData: DragDataFromEvent) {
    if (this.state.isDuringRealDrag) {
      this.state.isDuringRealDrag = false;
      this.actions.endDrag(dragData);
    }
  }
}

class DragApi {
  realDragTracker: RealDragTracker | undefined;
  getDragData: (ev: React.MouseEvent) => DragDataFromEvent;

  constructor(private scope: Scope) {
    const { editorAPI } = scope;
    this.getDragData = getDragDataFromEvent.bind(undefined, { editorAPI });
  }
  startDrag = (_editorAPI: EditorAPI, params: StartDragParams) => {
    let lifecycleScope: ReturnType<typeof initDragLifecycleScope>;

    this.realDragTracker = new RealDragTracker(
      { initMousePosition: params.initMousePosition },
      {
        // NOTE: it's called right after first drag (mouse move)
        startDrag: () => {
          if (!lifecycleScope) {
            lifecycleScope = initDragLifecycleScope(this.scope, params);
          }
          return lifecycle.startDrag(lifecycleScope);
        },
        endDrag: (dragData) => lifecycle.endDrag(lifecycleScope, dragData),
        onDrag: (dragData) => lifecycle.onDrag(lifecycleScope, dragData),
      },
    );
  };

  onDrag = (event: React.MouseEvent) => {
    if (this.realDragTracker) {
      const dragData = this.getDragData(event);
      this.realDragTracker.onDrag(dragData);
    }
  };

  endDrag = (event: React.MouseEvent) => {
    if (this.realDragTracker) {
      const dragData = this.getDragData(event);
      this.realDragTracker.endDrag(dragData);
    }
  };
}

export const createBaseDragApi = (scope: Scope) => {
  const {
    setContext,
    unsetContext,
    // setCancelDrag
  } = getKeyboardContextApi(scope);

  const { endDrag, startDrag, onDrag } = new DragApi(scope);

  return {
    hooks: scope.hooks,
    start: startDrag,
    on: onDrag,
    end: endDrag,
    type: constants.MOUSE_ACTION_TYPES.DRAG,
    setContext,
    unsetContext,
  };
};

export type BaseDragApi = ReturnType<typeof createBaseDragApi>;
