import constants from '@/constants';
import { hoc } from '@/util';
import _ from 'lodash';
import React from 'react';
import { UNSCALED_DROPZONE_HEIGHT } from './constants';
import { SectionsDropZone } from './SectionsDropZone';
import styles from './SectionsDropZoneContainer.scss';
import {
  mapDispatchToProps,
  mapStateToProps,
  type SectionsDropZoneContainerDispatchProps,
  type SectionsDropZoneContainerStateProps,
} from './SectionsDropZoneContainerMapper';
import { panels } from '@/stateManagement';

import type { SectionWithLayout } from '@/sections';

interface SectionsDropZoneContainerOwnProps {}

interface SectionsDropZoneContainerProps
  extends SectionsDropZoneContainerOwnProps,
    SectionsDropZoneContainerStateProps,
    SectionsDropZoneContainerDispatchProps {}

interface SectionsDropZoneContainerState {
  dropzoneIndex: number;
}

const OFF_SCREEN_Y = -1000;
const getSectionWithLayoutHash = ({ ref, layout }: SectionWithLayout) =>
  `${ref.id}-${layout.y}`;

export class SectionsDropZoneContainerComponent extends React.Component<
  SectionsDropZoneContainerProps,
  SectionsDropZoneContainerState
> {
  constructor(props: SectionsDropZoneContainerProps) {
    super(props);

    const { stageEntryIndex } = props;

    const dropzoneIndex = stageEntryIndex ?? this.calcDropzoneIndex();

    this.state = {
      dropzoneIndex,
    };
  }

  componentDidMount() {
    const { dropzoneIndex } = this.state;
    const {
      setAutosaveEnabled,
      setSectionsStageEntryIndex,
      setDropZoneExtraSiteHeight,
    } = this.props;

    setAutosaveEnabled(false);
    setDropZoneExtraSiteHeight();
    this.setTransformations(dropzoneIndex);
    setSectionsStageEntryIndex(dropzoneIndex);
  }

  componentDidUpdate(prevProps: SectionsDropZoneContainerProps) {
    const { isDragOverStageInProgress, scrollTop, sectionsWithLayout } =
      this.props;

    if (isDragOverStageInProgress) {
      if (this.props.shouldDisplayStaticDropZone) {
        return;
      }

      const nextDropzoneIndex = this.calcDropzoneIndexByMousePosition(
        this.props.mousePosition.y,
      );
      this.setDropzoneIndexIfNeeded(nextDropzoneIndex);

      return;
    }

    const isScrollChanged = scrollTop !== prevProps.scrollTop;

    const areSectionsEqual = _.isEqual(
      sectionsWithLayout.map(getSectionWithLayoutHash),
      prevProps.sectionsWithLayout.map(getSectionWithLayoutHash),
    );

    if (!areSectionsEqual || isScrollChanged) {
      if (!areSectionsEqual) {
        this.setDropzoneIndex(this.calcDropzoneIndex());
      }
      this.setDropzoneIndexIfNeeded(this.calcDropzoneIndex());
    }
  }

  componentWillUnmount() {
    this.clearTransformations();
    this.props.setAutosaveEnabled(true);
  }

  setDropzoneIndexIfNeeded(dropzoneNextIndex: number) {
    if (dropzoneNextIndex !== this.state.dropzoneIndex) {
      this.setDropzoneIndex(dropzoneNextIndex);
    }
  }

  setTransformations(insertionIndex: number) {
    const {
      sectionsWithLayout,
      updateComponentTransformation,
      removeComponentTransformation,
      siteFooterRef,
      shouldDisplayStaticDropZone,
      siteScale,
    } = this.props;

    if (insertionIndex === 0 && shouldDisplayStaticDropZone) {
      const hasSpaceForDropzoneText =
        sectionsWithLayout[0].layout.height >= UNSCALED_DROPZONE_HEIGHT;

      if (!hasSpaceForDropzoneText) {
        /* Increase dropzone height to give a space for a dropzone placeholder text */
        const unscaledSectionHeight =
          sectionsWithLayout[0].layout.height / siteScale;
        const diff = UNSCALED_DROPZONE_HEIGHT - unscaledSectionHeight;
        updateComponentTransformation(siteFooterRef, {
          translate: {
            y: { value: diff, type: 'px' },
          },
        });
      } else {
        this.clearTransformations();
      }

      return;
    }

    [...sectionsWithLayout, { ref: siteFooterRef }].forEach((s, index) => {
      const translateYValue =
        insertionIndex <= index ? UNSCALED_DROPZONE_HEIGHT : 0;
      if (translateYValue) {
        updateComponentTransformation(s.ref, {
          translate: { y: { value: translateYValue, type: 'px' } },
        });
      } else {
        removeComponentTransformation(s.ref);
      }
    });
  }

  clearTransformations() {
    this.setTransformations(Infinity);
  }

  setDropzoneIndex(dropzoneIndex: number) {
    this.setTransformations(dropzoneIndex);
    this.setState({ dropzoneIndex });
    this.props.setSectionsStageEntryIndex(dropzoneIndex);
  }

  calcDropzoneOffsetTop() {
    const { sectionsWithLayout } = this.props;
    const { dropzoneIndex } = this.state;

    if (dropzoneIndex === 0) {
      return sectionsWithLayout[0]?.layout?.y ?? OFF_SCREEN_Y;
    }

    const layout = sectionsWithLayout[dropzoneIndex - 1]?.layout;
    return layout ? layout.y + layout.height : OFF_SCREEN_Y;
  }

  calcDropzoneIndex() {
    const { sectionsWithLayout, scrollTop, previewHeight } = this.props;

    if (!sectionsWithLayout.length) return -1;

    if (sectionsWithLayout.length === 1) {
      return 0;
    }

    const absDistances = sectionsWithLayout.map(({ layout }) =>
      Math.abs(layout.y - scrollTop - previewHeight / 2),
    );
    const minDist = Math.min(...absDistances);

    return absDistances.indexOf(minDist);
  }

  findSectionIndex(clientY: number) {
    const { sectionsWithLayout } = this.props;

    const index = sectionsWithLayout.findIndex(({ layout }) => {
      const top = layout.y;
      const bottom = top + layout.height;

      return top <= clientY && bottom >= clientY;
    });

    return index > -1 ? index : sectionsWithLayout.length - 1;
  }

  calcDropzoneIndexByMousePosition(clientY: number) {
    const { sectionsWithLayout } = this.props;
    const sectionIndex = this.findSectionIndex(clientY);
    const sectionLayout = sectionsWithLayout[sectionIndex].layout;
    const isAboveSection = clientY < sectionLayout.y + sectionLayout.height / 2;
    return isAboveSection ? sectionIndex : sectionIndex + 1;
  }

  render() {
    const { shouldDisplayStaticDropZone, sectionsWithLayout, siteScale } =
      this.props;

    const dropZoneStyle = {
      top: this.calcDropzoneOffsetTop(),
      height: shouldDisplayStaticDropZone
        ? Math.max(
            sectionsWithLayout[0].layout.height,
            UNSCALED_DROPZONE_HEIGHT * siteScale,
          )
        : UNSCALED_DROPZONE_HEIGHT * siteScale,
    };

    return (
      <div className={styles.container}>
        <SectionsDropZone
          isDragging={this.props.isSectionDragToStageInProgress}
          style={dropZoneStyle}
        />
      </div>
    );
  }
}

const ConnectedSectionsDropZoneContainer = hoc.connect(
  hoc.STORES.MOUSE_OPS,
  mapStateToProps,
  mapDispatchToProps,
)(SectionsDropZoneContainerComponent);

export const SectionsDropZoneContainer = hoc.withConditionalRender(
  hoc.STORES.EDITOR_API,
  ({ state, editorAPI }) => {
    const isAddSectionPanelOpened = panels.selectors.isPanelOpenedByName(
      state,
      constants.ROOT_COMPS.LEFTBAR.ADD_SECTION_PANEL_NAME,
    );

    return (
      isAddSectionPanelOpened &&
      editorAPI.zoomMode.isStageZoomMode() &&
      !editorAPI.zoomMode.isZoomModeTransitionActive()
    );
  },
)(ConnectedSectionsDropZoneContainer);
