import addPanelDataConsts from '@/addPanelDataConsts';
import savedComponentsWelcome from './savedComponentsWelcome';

const savedComponentsWelcomeSection = {
  type: addPanelDataConsts.SECTIONS_TYPES.GENERAL,
  hide: false,
  title: 'add_section_label_MyDesigns',
  showSectionHeader: true,
  props: {
    class: 'no-space-before-section invisible-header',
    template: savedComponentsWelcome,
  },
  help: {
    hide: false,
    text: 'add_section_info_text_my_general',
  },
};

export default {
  getCategorySections: () => ({ savedComponentsWelcomeSection }),
};
