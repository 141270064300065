'use strict'

module.exports = {
    id: 'add_preset_popups_Subscribe_3',
    structure: {
        type: 'Page',
        id: 'axwmr',
        componentTypeForBI: 'wysiwyg.viewer.components.PopupContainer',
        components: [
            {
                type: 'Container',
                id: 'comp-im0kxzm0',
                components: [
                    {
                        type: 'Component',
                        id: 'comp-im0kxzma',
                        layout: {
                            width: 392,
                            height: 122,
                            x: 83,
                            y: 267,
                            scale: 1,
                            rotationInDegrees: 0,
                            fixedPosition: false
                        },
                        componentType: 'wysiwyg.viewer.components.WRichText',
                        data: {
                            type: 'StyledText',
                            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                            text: '<h2 class="font_2" style="font-size:48px; text-align:center"><span style="color:#FFFFFE;"><span style="font-size:48px"><span style="font-family:georgia,palatino,book antiqua,palatino linotype,serif">JOIN OUR MAILING LIST</span></span></span></h2>\n',
                            stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
                            linkList: [],
                            id: 'dataItem-im0kxzma1'
                        },
                        props: {
                            type: 'WRichTextProperties',
                            metaData: {schemaVersion: '1.0'},
                            brightness: 1,
                            packed: true,
                            id: 'propItem-im0kxzma2'
                        },
                        style: 'txtNew'
                    },
                    {
                        type: 'Component',
                        id: 'comp-im0kxzmc',
                        layout: {
                            width: 470,
                            height: 39,
                            x: 44,
                            y: 415,
                            scale: 1,
                            rotationInDegrees: 0,
                            fixedPosition: false
                        },
                        componentType: 'wysiwyg.viewer.components.WRichText',
                        data: {
                            type: 'StyledText',
                            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                            text: '<p class="font_8" style="font-size:30px; text-align:center"><span style="color:#FFFFFE;"><span style="font-size:30px"><span style="font-family:didot-w01-italic,serif">and never miss an update</span></span></span></p>\n',
                            stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
                            linkList: [],
                            id: 'dataItem-im0kxzmc1'
                        },
                        props: {
                            type: 'WRichTextProperties',
                            metaData: {schemaVersion: '1.0'},
                            brightness: 1,
                            packed: true,
                            id: 'propItem-im0kxzmc2'
                        },
                        style: 'txtNew'
                    },
                    {
                        'type': 'Component',
                        'skin': 'svgshape.v2.Svg_001419cc721a440abc4ab12ed150b744',
                        'layout': {
                            'width': 34,
                            'height': 34,
                            'x': 503,
                            'y': 16,
                            'scale': 1,
                            'rotationInDegrees': 0,
                            'fixedPosition': false
                        },
                        'componentType': 'wysiwyg.viewer.components.PopupCloseIconButton',
                        'parent': 'comp-jv6mgxex',
                        'metaData': {
                            'pageId': 'yusqj'
                        },
                        'data': {
                            'type': 'SvgShape',
                            'metaData': {
                                'isPreset': false,
                                'schemaVersion': '1.0',
                                'isHidden': false,
                                'pageId': 'yusqj'
                            }
                        },
                        'props': {
                            'type': 'SvgShapeProperties',
                            'metaData': {
                                'schemaVersion': '1.0',
                                'pageId': 'yusqj'
                            },
                            'maintainAspectRatio': true
                        },
                        'mobileHints': {
                            'type': 'MobileHints',
                            'author': 'duplicate',
                            'originalCompId': 'comp-isrev5jf',
                            'recommendedScale': 1,
                            'recommendedWidth': 26,
                            'recommendedHeight': 24,
                            'metaData': {
                                'pageId': 'yusqj'
                            }
                        },
                        'style': {
                            'type': 'ComponentStyle',
                            'metaData': {
                                'isPreset': false,
                                'schemaVersion': '1.0',
                                'isHidden': false,
                                'pageId': 'yusqj'
                            },
                            'style': {
                                'properties': {
                                    'alpha-fillcolor': '1',
                                    'alpha-stroke': '1',
                                    'fillcolor': '#FFFFFF',
                                    'stroke': '#00C8A1',
                                    'strokewidth': '1'
                                },
                                'propertiesSource': {
                                    'alpha-fillcolor': 'value',
                                    'alpha-stroke': 'value',
                                    'fillcolor': 'value',
                                    'stroke': 'value',
                                    'strokewidth': 'value'
                                },
                                'groups': {}
                            },
                            'componentClassName': 'wysiwyg.viewer.components.PopupCloseIconButton',
                            'pageId': '',
                            'compId': '',
                            'styleType': 'custom',
                            'skin': 'svgshape.v2.Svg_001419cc721a440abc4ab12ed150b744'
                        },
                        'connections': {
                            'type': 'ConnectionList',
                            'items': [
                                {
                                    'type': 'WixCodeConnectionItem',
                                    'role': 'button1'
                                }
                            ],
                            'metaData': {
                                'pageId': 'yusqj'
                            }
                        },
                        'activeModes': {}
                    }
                ],
                skin: 'wysiwyg.viewer.skins.stripContainer.DefaultStripContainer',
                layout: {
                    width: 557,
                    height: 778,
                    x: 645,
                    y: 0,
                    scale: 1,
                    rotationInDegrees: 0,
                    fixedPosition: false
                },
                componentType: 'wysiwyg.viewer.components.PopupContainer',
                props: {
                    type: 'PopupContainerProperties',
                    metaData: {schemaVersion: '1.0'},
                    horizontalAlignment: 'right',
                    verticalAlignment: 'top',
                    alignmentType: 'fullHeight',
                    horizontalOffset: 0,
                    verticalOffset: 0,
                    id: 'propItem-im0kxzm2'
                },
                design: {
                    type: 'MediaContainerDesignData',
                    metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                    background: {
                        type: 'BackgroundMedia',
                        metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                        color: '#05C8A1',
                        colorOpacity: 1,
                        alignType: 'center',
                        fittingType: 'fill',
                        scrollType: 'none',
                        colorOverlay: '',
                        colorOverlayOpacity: 0
                    },
                    id: 'dataItem-im0kxzm3'
                },
                style: {
                    type: 'TopLevelStyle',
                    metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                    style: {
                        groups: {},
                        properties: {'alpha-bg': '1', bg: 'rgba(255,255,255,1)'},
                        propertiesSource: {'alpha-bg': 'value', bg: 'value'}
                    },
                    componentClassName: 'wysiwyg.viewer.components.PopupContainer',
                    pageId: '',
                    compId: '',
                    styleType: 'custom',
                    skin: 'wysiwyg.viewer.skins.stripContainer.DefaultStripContainer',
                    id: 'style-im0kxzm4'
                },
                behaviors: {
                    type: 'ObsoleteBehaviorsList',
                    items: '[{"name":"SlideIn","delay":0,"duration":0.55,"params":{"direction":"right"},"action":"screenIn","targetId":""},{"name":"SlideOut","delay":0,"duration":0.55,"params":{"direction":"right"},"action":"exit","targetId":""}]',
                    id: 'behavior-iomlwee5'
                }
            }
        ],

        skin: 'skins.core.InlineSkin',
        layout: {
            width: 980,
            height: 778,
            x: 0,
            y: 0,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false
        },
        componentType: 'mobile.core.components.Page',
        data: {
            type: 'Page',
            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
            title: 'add_preset_popups_Subscribe_3',
            hideTitle: true,
            icon: '',
            descriptionSEO: '',
            metaKeywordsSEO: '',
            pageTitleSEO: '',
            pageUriSEO: 'copy-of-sale4',
            hidePage: true,
            underConstruction: false,
            pageSecurity: {requireLogin: false, passwordDigest: '', dialogLanguage: ''},
            isPopup: true,
            indexable: false,
            isLandingPage: false,
            pageBackgrounds: {
                desktop: {
                    ref: {
                        type: 'BackgroundMedia',
                        metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                        color: '#A1C6BF',
                        colorOpacity: 0.58,
                        alignType: 'center',
                        fittingType: 'fill',
                        scrollType: 'none',
                        colorOverlay: '',
                        colorOverlayOpacity: 0
                    }
                },
                mobile: {
                    custom: true,
                    ref: {
                        type: 'BackgroundMedia',
                        metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                        color: '#A1C6BF',
                        colorOpacity: 0.58,
                        alignType: 'center',
                        fittingType: 'fill',
                        scrollType: 'scroll',
                        colorOverlay: '',
                        colorOverlayOpacity: 0
                    },
                    isPreset: true
                }
            },
            ignoreBottomBottomAnchors: true
        },
        props: {
            type: 'PageProperties',
            metaData: {schemaVersion: '1.0'},
            desktop: {popup: {closeOnOverlayClick: true}},
            mobile: {popup: {closeOnOverlayClick: true}},
            id: 'axwmr'
        },
        style: 'p1'
    },
    preset: {
        rect: {width: 305, height: 202, x: 10, y: 464},
        label: 'add_preset_popups_Subscribe_3',
        tags: null
    }
}
