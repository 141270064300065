// @ts-nocheck
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import * as core from '@/core';
import * as superAppBi from '../../../bi/superAppBi';
import React from 'react';
import { translate } from '@/i18n';
import * as baseUI from '@/baseUI';
import * as symbols from '@wix/santa-editor-symbols';

interface GetStartedSlideProps {
  onClose: () => void;
  nextSlide: () => void;
  onNextButtonClick: () => void;
  superApp: unknown;
  title: string;
  subTitle: string;
  pros: string[];
  teaser?: string;
  nextButtonLabel: string;
  mainImage: string;
  footer?: string;
  upgradeLink?: string;
  upgrade?: () => void;
}

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass<GetStartedSlideProps>({
  displayName: 'GetStartedSlide',
  mixins: [core.mixins.editorAPIMixin],
  propTypes: {
    onClose: PropTypes.func.isRequired,
    nextSlide: PropTypes.func.isRequired,
    superApp: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    subTitle: PropTypes.string.isRequired,
    pros: PropTypes.arrayOf(PropTypes.string).isRequired,
    teaser: PropTypes.string,
    nextButtonLabel: PropTypes.string.isRequired,
    mainImage: PropTypes.string.isRequired,
    footer: PropTypes.string,
    upgradeLink: PropTypes.string,
    upgrade: PropTypes.func,
  },
  onClose() {
    const superAppProps = this.props.superApp;
    const params = {
      app_id: superAppProps.appDefId,
      app_name: superAppProps.appName,
    };
    const type = this.props.appWasManuallyInstalled
      ? 'manuallyInstalled'
      : 'template';
    const event =
      superAppBi.events.firstTimeTour[type].STEP_1_WELCOME_CLOSE_CLICK;
    if (event) {
      this.getEditorAPI().bi.event(event, params);
    }
    this.props.onClose();
  },
  onNextButtonClick() {
    const superAppProps = this.props.superApp;
    const params = {
      app_id: superAppProps.appDefId,
      app_name: superAppProps.appName,
    };
    const type = this.props.appWasManuallyInstalled
      ? 'manuallyInstalled'
      : 'template';
    const event =
      superAppBi.events.firstTimeTour[type].STEP_1_WELCOME_CONTINUE_CLICK;
    if (event) {
      this.getEditorAPI().bi.event(event, params);
    }
    this.props.nextSlide();
  },
  openAppPremiumPackagePicker(e) {
    e.preventDefault();
    const superAppProps = this.props.superApp;
    const params = {
      origin: 'TooltipUpgradeLink',
      app_id: superAppProps.appDefId,
      app_name: superAppProps.appName,
    };
    this.getEditorAPI().bi.event(superAppBi.events.UPGRADE_CLICK, params);
    superAppProps.openAppPremiumPackagePicker(this.getEditorAPI());
  },
  render() {
    return (
      <div className="get-started-slide">
        <div className="slides-container">
          <div onClick={this.onClose} className="button">
            <symbols.symbol name="headerCloseButton" />
          </div>
          <symbols.symbol name={this.props.mainImage} className="main-image" />

          <h1 className="title">{translate(this.props.title)}</h1>

          <p className="sub-title">{translate(this.props.subTitle)}</p>

          <div className="bullets-wrapper">
            <ul className="tip-bullets">
              {this.props.pros.map((pro, i) => (
                <li className="bullet" key={i}>
                  <span className="bullet-symbol">
                    <symbols.symbol
                      name="cyclePickerV"
                      className="cyclePickerV"
                    />
                  </span>
                  <p className="bullet-text">{translate(pro)}</p>
                </li>
              ))}
            </ul>
          </div>
          {this.props.teaser ? (
            <p key="teaser" className="teaser">
              {translate(this.props.teaser)}
            </p>
          ) : null}
          <baseUI.button
            label={this.props.nextButtonLabel}
            onClick={this.onNextButtonClick}
            className="get-started-add-btn"
          />
        </div>
        {this.props.footer ? (
          <footer key="footer">
            <p>
              {translate(this.props.footer)}
              <a
                href={``}
                onClick={(e) => {
                  this.openAppPremiumPackagePicker(e);
                }}
              >
                {translate(this.props.upgradeLink)}
              </a>
            </p>
          </footer>
        ) : null}
      </div>
    );
  },
});
