import _ from 'lodash';

/**
 * Please ctrl/cmd + click on biEvents to see the schema :)
 * @type {Object.<String, biEvent>}
 */
export const events = {
  SHOUTOUT_COMPOSE: {
    evid: 11,
    src: 36,
    endpoint: 'shout',
    fields: {
      referral_info: 'string',
      msid: 'guid',
      package_group: 'string',
      package_id: 'string',
      context: 'string',
    },
  },
  SHOUTOUT_OPEN: {
    evid: 76,
    src: 36,
    endpoint: 'shout',
    fields: {
      referral_info: 'string',
      msid: 'guid',
      package_group: 'string',
      package_id: 'string',
      context: 'string',
    },
  },
  COMPARE_PLANS: {
    evid: 377,
    src: 36,
    endpoint: 'shout',
    fields: {
      button_location: 'string',
      button_name: 'string',
      origin: 'string',
      msid: 'guid',
      package_group: 'string',
      package_id: 'string',
      context: 'string',
    },
  },
  TAB_VIEW: {
    evid: 3004,
    src: 38,
    endpoint: 'ascend-panel',
    fields: {
      ascend_package: 'string',
      is_premium: 'bool',
      is_publish: 'bool',
      msid: 'guid',
      tab_name: 'string',
    },
  },
  PRODUCT_CLICK: {
    evid: 3005,
    src: 38,
    endpoint: 'ascend-panel',
    fields: {
      ascend_package: 'string',
      is_premium: 'bool',
      is_publish: 'bool',
      msid: 'guid',
      tab_name: 'string',
      product_name: 'string',
    },
  },
} as const;

export default {
  promoteEvents: events,
  registerEvents: _.noop,
};
