import type { AddPresetScope } from '../../../../addPresetApiEntry';
import type {
  SectionAiCreationQueryOutput,
  TokenUsage,
} from '../aiSectionCreatorTypes';

export const getTokensFromQueryOutput = (
  queryOutputs: SectionAiCreationQueryOutput[],
): TokenUsage[] =>
  queryOutputs
    ?.map((queryOutput) => queryOutput?.completionMetadata?.tokenUsage)
    .flat() || [];

export const createQueryOutputBiData = (
  queryOutputs: SectionAiCreationQueryOutput[],
): {
  tokens: string;
  query_output: string;
  prompt: string;
  gptParans: string;
} => {
  const tokens = getTokensFromQueryOutput(queryOutputs);
  const outlines = queryOutputs.map((queryOutput) => queryOutput.outline);

  return {
    tokens: JSON.stringify(tokens),
    query_output: JSON.stringify(outlines),
    prompt: queryOutputs[0]?.completionMetadata?.promptsVersion,
    gptParans: queryOutputs[0]?.completionMetadata?.gptParamsVersion,
  };
};

export const getCategoryNameById = (
  scope: AddPresetScope,
  categoryId: string,
): string => {
  const { store } = scope;
  const categories = store.getSectionsAiCategories();
  const category = categories.find((category) => category.id === categoryId);
  return `AI: ${category?.title}`;
};
