import { getSendBi } from '../../../topBarComponent/topBarMapper';
import {
  mapStateToProps as mapStateToMenuAPIProps,
  mapDispatchToProps as mapDispatchToMenuAPIProps,
} from './codeMenuAPIMapper';

import type { MapDispatchToPropsFunction, MapStateToProps } from 'types/redux';
import type {
  CodeListItemsOwnProps,
  CodeListItemsStateProps,
  CodeListItemsDispatchProps,
} from './codeMenuStructure';

const mapStateToProps: MapStateToProps<
  CodeListItemsStateProps,
  CodeListItemsOwnProps
> = (...args) => {
  const [{ editorAPI }] = args;

  return {
    ...mapStateToMenuAPIProps(...args),
    isFirstSave: editorAPI.dsRead?.generalInfo.isFirstSave(),
    siteId: editorAPI.dsRead?.generalInfo.getSiteId(),
    isUserOwner: editorAPI.account.isUserOwner(),
  };
};

const mapDispatchToProps: MapDispatchToPropsFunction<
  CodeListItemsDispatchProps,
  CodeListItemsOwnProps
> = (dispatch, ownProps) => ({
  ...mapDispatchToMenuAPIProps(dispatch, ownProps),
  sendBi: getSendBi(dispatch),
});

export { mapStateToProps, mapDispatchToProps };
