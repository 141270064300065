import _ from 'lodash';
import React, { type FC, useCallback, useEffect, useRef } from 'react';

import constants from '@/constants';
import { hoc, cx } from '@/util';
import * as uiAnimations from '@/uiAnimations';

import {
  mapDispatchToProps,
  mapStateToProps,
} from './scrollableEditorStageMapper';

import type { AnimateScrollPosition } from 'types/core';
import type { ScrollableEditorStageTypes } from './scrollableEditorStage.types';

const ScrollableEditorStageComponent: FC<ScrollableEditorStageTypes> = ({
  styleForPushedStageAndPreviewFrame,
  registerNewScrollPosition,
  handleStageScroll,
  isScrollingEnabled,
  animateScrollTo,
  clearScrollTo,
  scrollTo,
  isPagesPanelOpen,
  children,
}) => {
  const scrollableEditorStageRef = useRef<HTMLDivElement>(null);

  const animateScroll = useCallback(
    (
      position: AnimateScrollPosition,
      duration: AnyFixMe,
      onComplete: AnyFixMe,
    ) => {
      const scrollableEditorStageNode = scrollableEditorStageRef.current;

      uiAnimations.scrollTo(
        position,
        duration,
        0,
        scrollableEditorStageNode,
        onComplete,
      );

      clearScrollTo();
    },
    [clearScrollTo],
  );

  const setScroll = useCallback(
    (scroll: AnyFixMe) => {
      const scrollableEditorStageNode = scrollableEditorStageRef.current;

      if (scroll.scrollTop !== undefined) {
        scrollableEditorStageNode.scrollTop = scroll.scrollTop;
      }
      if (scroll.scrollLeft !== undefined) {
        scrollableEditorStageNode.scrollLeft = scroll.scrollLeft;
      }
      clearScrollTo();
    },
    [clearScrollTo],
  );

  useEffect(() => {
    if (scrollTo) {
      setScroll(scrollTo);
    } else if (animateScrollTo) {
      const { position } = animateScrollTo;
      const { duration } = animateScrollTo;
      const { onComplete } = animateScrollTo;

      animateScroll(position, duration, onComplete);
    }
  }, [animateScrollTo, animateScroll, scrollTo, setScroll]);

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, scrollLeft } = scrollableEditorStageRef.current;

    registerNewScrollPosition({ scrollTop, scrollLeft }); // TODO: Consider getting values from the event
    handleStageScroll(event);
  };

  return (
    <div
      ref={scrollableEditorStageRef}
      id={constants.ROOT_COMPS.SELECTOR_ID.SCROLLABLE_EDITOR_STAGE}
      onScroll={handleScroll}
      className={cx('scrollable-editor-stage', {
        'scrolling-disabled': !isScrollingEnabled,
        'main-thread-scroll': !isPagesPanelOpen,
      })}
    >
      <div
        className="scrollable-editor-stage-content"
        id={constants.ROOT_COMPS.SELECTOR_ID.SCROLLABLE_EDITOR_STAGE_CONTENT}
        style={styleForPushedStageAndPreviewFrame}
      >
        {children}
      </div>
    </div>
  );
};

export const ScrollableEditorStage = _.flow(
  hoc.connect(hoc.STORES.MOUSE_OPS, mapStateToProps, mapDispatchToProps),
)(ScrollableEditorStageComponent);

ScrollableEditorStage.pure = ScrollableEditorStageComponent;
