import addPanelDataConsts from '@/addPanelDataConsts';
import type { EditorAPI } from '@/editorAPI';
import * as addPanel from '@/oldAddPanel';
import {
  onClickToContainer,
  onDropToContainer,
} from '../../contact/wixForms/utils';
import getFifthContactItemExtension from './fifthItem';
import getFirstContactItemExtension from './firstItem';
import getFourthContactItemExtension from './fourthItem';
import getSecondContactItemExtension from './secondItem';
import getThirdContactItemExtension from './thirdItem';

export default (
  editorAPI: EditorAPI,
  categoryId: string = addPanelDataConsts.CATEGORIES_ID.POPUP,
) => {
  const { popupContactSection } = addPanel.sections.getSections().popup;
  const sectionTitle = popupContactSection.title;
  const onDrop = onDropToContainer(categoryId, sectionTitle).bind(
    null,
    editorAPI,
  );
  const onClick = onClickToContainer(categoryId, sectionTitle).bind(
    null,
    editorAPI,
  );
  const itemsExtensions = [
    getFirstContactItemExtension(onDrop, onClick),
    getSecondContactItemExtension(onDrop, onClick),
    getThirdContactItemExtension(onDrop, onClick),
    getFourthContactItemExtension(onDrop, onClick),
    getFifthContactItemExtension(onDrop, onClick),
  ];

  return addPanel.dataUtil.extendPropsItems(
    popupContactSection,
    itemsExtensions,
  );
};
