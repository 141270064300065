'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')

module.exports = {
    'type': addPanelDataConsts.SECTIONS_TYPES.PRESET,
    'hide': false,
    'title': 'add_section_label_subscribeform',
    'showSectionHeader': true,
    'additionalBehaviours': {
        'labelMode': addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
        'infoIcon': addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
        'hoverImageAction': addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
        'iconEnabledForComps': {}
    },
    'props': {
        'image': 'addPanelData/sections/subscribeFormsSection_en/subscribeFormsSection_en.png',
        'imageHover': null,
        'items': [{
            'id': 'Contact_Subscribe_Forms_1',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.common.components.subscribeform.viewer.skins.SubscribeFormBoxLayoutFlat',
                'layout': {
                    'width': 608,
                    'height': 144,
                    'x': 20,
                    'y': 30,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.common.components.subscribeform.viewer.SubscribeForm',
                'data': {
                    'type': 'SubscribeForm',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'toEmailAddress': '',
                    'bccEmailAddress': '',
                    'firstNameFieldLabel': 'Name',
                    'lastNameFieldLabel': 'Last Name',
                    'emailFieldLabel': 'Email',
                    'phoneFieldLabel': 'Mobile Number',
                    'subscribeFormTitle': 'Subscribe for Updates',
                    'textDirection': 'left',
                    'submitButtonLabel': 'Subscribe Now',
                    'successMessage': 'Congrats! You’re subscribed',
                    'errorMessage': 'Please provide a valid email',
                    'validationErrorMessage': 'Please fill in all required fields.'
                },
                'props': {
                    'type': 'SubscribeFormProperties',
                    'metaData': {'schemaVersion': '1.0'},
                    'hiddenFirstNameField': false,
                    'hiddenLastNameField': false,
                    'hiddenEmailField': true,
                    'hiddenPhoneField': false,
                    'requiredFirstNameField': false,
                    'requiredLastNameField': false,
                    'requiredEmailField': true,
                    'requiredPhoneField': false,
                    'useCookie': true
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {
                            'alpha-bg1': '1',
                            'alpha-bg2': '1',
                            'alpha-bg3': '1',
                            'alpha-bg4': '1',
                            'alpha-brd': '1',
                            'alpha-labelTxt': '1',
                            'alpha-titleColor': '1',
                            'alpha-txt1': '1',
                            'alpha-txt2': '1',
                            'alpha-txt3': '1',
                            'alpha-txtError': '1',
                            'alpha-txtSuccess': '1',
                            'bg1': '#FFFFFF',
                            'bg2': '#FFFFFF',
                            'bg3': '#2A2A2A',
                            'bg4': '#E0E0E0',
                            'boxShadowToggleOn-shd': 'false',
                            'brd': '#555555',
                            'brw': '0px',
                            'efnt': 'normal normal normal 15px/1.4em open+sans+condensed',
                            'fnt': 'normal normal normal 15px/1.4em open+sans+condensed',
                            'fnt2': 'normal normal normal 16px/1.4em open+sans+condensed',
                            'labelTxt': '#2A2A2A',
                            'shd': '0 1px 4px rgba(0, 0, 0, 0.6);',
                            'tfnt': 'normal normal normal 22px/1.4em open+sans+condensed',
                            'titleColor': '#FFFFFF',
                            'txt1': '#2A2A2A',
                            'txt2': '#2A2A2A',
                            'txt3': '#2A2A2A',
                            'txtError': '#E21C21',
                            'txtSuccess': '#68B04D'
                        },
                        'propertiesSource': {
                            'bg1': 'value',
                            'bg2': 'value',
                            'bg3': 'value',
                            'bg4': 'value',
                            'brd': 'value',
                            'brw': 'value',
                            'efnt': 'value',
                            'fnt': 'value',
                            'fnt2': 'value',
                            'labelTxt': 'value',
                            'shd': 'value',
                            'tfnt': 'value',
                            'titleColor': 'value',
                            'txt1': 'value',
                            'txt2': 'value',
                            'txt3': 'value',
                            'txtError': 'value',
                            'txtSuccess': 'value'
                        }
                    },
                    'componentClassName': 'wysiwyg.common.components.subscribeform.viewer.SubscribeForm',
                    'pageId': '',
                    'compId': 'ia6qm3rn_0',
                    'styleType': 'custom',
                    'skin': 'wysiwyg.common.components.subscribeform.viewer.skins.SubscribeFormBoxLayoutFlat'
                },
                'id': 'ia6qm3rn_0'
            },
            'preset': {'rect': {'width': 324, 'height': 101.5, 'x': 0, 'y': 0}, 'label': '', 'tags': null}
        }, {
            'id': 'Contact_Subscribe_Forms_2',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.common.components.subscribeform.viewer.skins.SubscribeFormBoxLayoutFlat',
                'layout': {
                    'width': 608,
                    'height': 141,
                    'x': 20,
                    'y': 233,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.common.components.subscribeform.viewer.SubscribeForm',
                'data': {
                    'type': 'SubscribeForm',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'toEmailAddress': '',
                    'bccEmailAddress': '',
                    'firstNameFieldLabel': 'Name',
                    'lastNameFieldLabel': 'Last Name',
                    'emailFieldLabel': 'Email',
                    'phoneFieldLabel': 'Mobile Number',
                    'subscribeFormTitle': 'Subscribe for Updates',
                    'textDirection': 'left',
                    'submitButtonLabel': 'Subscribe Now',
                    'successMessage': 'Congrats! You’re subscribed',
                    'errorMessage': 'Please provide a valid email',
                    'validationErrorMessage': 'Please fill in all required fields.'
                },
                'props': {
                    'type': 'SubscribeFormProperties',
                    'metaData': {'schemaVersion': '1.0'},
                    'hiddenFirstNameField': false,
                    'hiddenLastNameField': false,
                    'hiddenEmailField': true,
                    'hiddenPhoneField': false,
                    'requiredFirstNameField': false,
                    'requiredLastNameField': false,
                    'requiredEmailField': true,
                    'requiredPhoneField': false,
                    'useCookie': true
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {
                            'alpha-bg1': '1',
                            'alpha-bg2': '1',
                            'alpha-bg3': '1',
                            'alpha-bg4': '1',
                            'alpha-brd': '1',
                            'alpha-labelTxt': '1',
                            'alpha-titleColor': '1',
                            'alpha-txt1': '1',
                            'alpha-txt2': '1',
                            'alpha-txt3': '1',
                            'alpha-txtError': '1',
                            'alpha-txtSuccess': '1',
                            'bg1': '#FFFFFF',
                            'bg2': '#FFFFFF',
                            'bg3': '#002E5D',
                            'bg4': '#D8D8D8',
                            'boxShadowToggleOn-shd': 'false',
                            'brd': '#2B6CA3',
                            'brw': '0px',
                            'efnt': 'normal normal normal 15px/1.4em helvetica-w01-light',
                            'fnt': 'normal normal normal 15px/1.4em helvetica-w01-light',
                            'fnt2': 'normal normal normal 15px/1.4em helvetica-w01-light',
                            'labelTxt': '#002E5D',
                            'shd': '0 1px 4px rgba(0, 0, 0, 0.6);',
                            'tfnt': 'normal normal normal 20px/1.4em helvetica-w01-light',
                            'titleColor': '#FFFFFF',
                            'txt1': '#2A2A2A',
                            'txt2': '#002E5D',
                            'txt3': '#2A2A2A',
                            'txtError': '#E21C21',
                            'txtSuccess': '#68B04D'
                        },
                        'propertiesSource': {
                            'bg1': 'value',
                            'bg2': 'value',
                            'bg3': 'value',
                            'bg4': 'value',
                            'brd': 'value',
                            'brw': 'value',
                            'efnt': 'value',
                            'fnt': 'value',
                            'fnt2': 'value',
                            'labelTxt': 'value',
                            'shd': 'value',
                            'tfnt': 'value',
                            'titleColor': 'value',
                            'txt1': 'value',
                            'txt2': 'value',
                            'txt3': 'value',
                            'txtError': 'value',
                            'txtSuccess': 'value'
                        }
                    },
                    'componentClassName': 'wysiwyg.common.components.subscribeform.viewer.SubscribeForm',
                    'pageId': '',
                    'compId': 'ia6qm3re',
                    'styleType': 'custom',
                    'skin': 'wysiwyg.common.components.subscribeform.viewer.skins.SubscribeFormBoxLayoutFlat'
                },
                'id': 'ia6qm3re'
            },
            'preset': {'rect': {'width': 324, 'height': 100.5, 'x': 0, 'y': 101.5}, 'label': '', 'tags': null}
        }, {
            'id': 'Contact_Subscribe_Forms_3',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.common.components.subscribeform.viewer.skins.SubscribeFormLineLayoutFlat',
                'layout': {
                    'width': 724,
                    'height': 110,
                    'x': 20,
                    'y': 434,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.common.components.subscribeform.viewer.SubscribeForm',
                'data': {
                    'type': 'SubscribeForm',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'toEmailAddress': '',
                    'bccEmailAddress': '',
                    'firstNameFieldLabel': 'Name',
                    'lastNameFieldLabel': 'Last Name',
                    'emailFieldLabel': 'Email',
                    'phoneFieldLabel': 'Mobile Number',
                    'subscribeFormTitle': 'Subscribe for Updates',
                    'textDirection': 'left',
                    'submitButtonLabel': 'Subscribe Now',
                    'successMessage': 'Congrats! You’re subscribed',
                    'errorMessage': 'Please provide a valid email',
                    'validationErrorMessage': 'Please fill in all required fields.'
                },
                'props': {
                    'type': 'SubscribeFormProperties',
                    'metaData': {'schemaVersion': '1.0'},
                    'hiddenFirstNameField': false,
                    'hiddenLastNameField': false,
                    'hiddenEmailField': true,
                    'hiddenPhoneField': false,
                    'requiredFirstNameField': false,
                    'requiredLastNameField': false,
                    'requiredEmailField': true,
                    'requiredPhoneField': false,
                    'useCookie': true
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {
                            'alpha-llf_bg1': '1',
                            'alpha-llf_bg2': '1',
                            'alpha-llf_bg3': '1',
                            'alpha-llf_bg4': '1',
                            'alpha-llf_brd': '1',
                            'alpha-llf_labelTxt': '1',
                            'alpha-llf_titleColor': '1',
                            'alpha-llf_txt1': '1',
                            'alpha-llf_txt2': '1',
                            'alpha-llf_txt3': '1',
                            'alpha-llf_txtError': '1',
                            'alpha-llf_txtSuccess': '1',
                            'boxShadowToggleOn-llf_shd': 'false',
                            'llf_bg1': '#FFFFFF',
                            'llf_bg2': '#002E5D',
                            'llf_bg3': '#2B6CA3',
                            'llf_bg4': '#3C7EB8',
                            'llf_brd': '#3D9BE9',
                            'llf_brw': '0px',
                            'llf_efnt': 'normal normal normal 12px/1.4em helvetica-w01-roman',
                            'llf_fnt': 'normal normal normal 12px/1.4em helvetica-w01-roman',
                            'llf_fnt2': 'normal normal normal 12px/1.4em helvetica-w01-roman',
                            'llf_labelTxt': '#3D9BE9',
                            'llf_shd': '0 1px 4px rgba(0, 0, 0, 0.6);',
                            'llf_tfnt': 'normal normal normal 19px/1.4em helvetica-w01-roman',
                            'llf_titleColor': '#FFFFFF',
                            'llf_txt1': '#2A2A2A',
                            'llf_txt2': '#FFFFFF',
                            'llf_txt3': '#FFFFFF',
                            'llf_txtError': '#E21C21',
                            'llf_txtSuccess': '#68B04D'
                        },
                        'propertiesSource': {
                            'llf_bg1': 'value',
                            'llf_bg2': 'value',
                            'llf_bg3': 'value',
                            'llf_bg4': 'value',
                            'llf_brd': 'value',
                            'llf_brw': 'value',
                            'llf_efnt': 'value',
                            'llf_fnt': 'value',
                            'llf_fnt2': 'value',
                            'llf_labelTxt': 'value',
                            'llf_shd': 'value',
                            'llf_tfnt': 'value',
                            'llf_titleColor': 'value',
                            'llf_txt1': 'value',
                            'llf_txt2': 'value',
                            'llf_txt3': 'value',
                            'llf_txtError': 'value',
                            'llf_txtSuccess': 'value'
                        }
                    },
                    'componentClassName': 'wysiwyg.common.components.subscribeform.viewer.SubscribeForm',
                    'pageId': '',
                    'compId': 'ia6qm3rr',
                    'styleType': 'custom',
                    'skin': 'wysiwyg.common.components.subscribeform.viewer.skins.SubscribeFormLineLayoutFlat'
                },
                'id': 'ia6qm3rr'
            },
            'preset': {'rect': {'width': 324, 'height': 85, 'x': 0, 'y': 202}, 'label': '', 'tags': null}
        }, {
            'id': 'Contact_Subscribe_Forms_4',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.common.components.subscribeform.viewer.skins.SubscribeFormLineLayoutTransparentWithIcon',
                'layout': {
                    'width': 862,
                    'height': 83,
                    'x': 20,
                    'y': 603,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.common.components.subscribeform.viewer.SubscribeForm',
                'data': {
                    'type': 'SubscribeForm',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'toEmailAddress': '',
                    'bccEmailAddress': '',
                    'firstNameFieldLabel': 'Name',
                    'lastNameFieldLabel': 'Last Name',
                    'emailFieldLabel': 'Email',
                    'phoneFieldLabel': 'Mobile Number',
                    'subscribeFormTitle': 'Subscribe for Updates',
                    'textDirection': 'left',
                    'submitButtonLabel': 'Subscribe Now',
                    'successMessage': 'Congrats! You’re subscribed',
                    'errorMessage': 'Please provide a valid email',
                    'validationErrorMessage': 'Please fill in all required fields.'
                },
                'props': {
                    'type': 'SubscribeFormProperties',
                    'metaData': {'schemaVersion': '1.0'},
                    'hiddenFirstNameField': false,
                    'hiddenLastNameField': false,
                    'hiddenEmailField': true,
                    'hiddenPhoneField': false,
                    'requiredFirstNameField': false,
                    'requiredLastNameField': false,
                    'requiredEmailField': true,
                    'requiredPhoneField': false,
                    'useCookie': true
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {
                            'alpha-llt_bg1': '1',
                            'alpha-llt_bg2': '1',
                            'alpha-llt_bg3': '1',
                            'alpha-llt_bg4': '1',
                            'alpha-llt_brd': '1',
                            'alpha-llt_icon_color': '1',
                            'alpha-llt_labelTxt': '1',
                            'alpha-llt_titleColor': '1',
                            'alpha-llt_txt1': '1',
                            'alpha-llt_txt2': '1',
                            'alpha-llt_txt3': '1',
                            'alpha-llt_txtError': '1',
                            'alpha-llt_txtSuccess': '1',
                            'boxShadowToggleOn-llt_shd': 'false',
                            'llt_bg1': '#FFFFFF',
                            'llt_bg2': '#2B6CA3',
                            'llt_bg3': '#3D9BE9',
                            'llt_bg4': '#225581',
                            'llt_brd': '#2B6CA3',
                            'llt_brw': '0px',
                            'llt_efnt': 'normal normal normal 14px/1.4em amatic+sc',
                            'llt_fnt': 'normal normal normal 14px/1.4em raleway',
                            'llt_fnt2': 'normal normal normal 14px/1.4em raleway',
                            'llt_icon_color': '#2B6CA3',
                            'llt_labelTxt': '#3D9BE9',
                            'llt_shd': '0 1px 4px rgba(0, 0, 0, 0.6);',
                            'llt_tfnt': 'normal normal normal 18px/1.4em raleway',
                            'llt_titleColor': '#FFFFFF',
                            'llt_txt1': '#2A2A2A',
                            'llt_txt2': '#FFFFFF',
                            'llt_txt3': '#FFFFFF',
                            'llt_txtError': '#E21C21',
                            'llt_txtSuccess': '#68B04D'
                        },
                        'propertiesSource': {
                            'llt_bg1': 'value',
                            'llt_bg2': 'value',
                            'llt_bg3': 'value',
                            'llt_bg4': 'value',
                            'llt_brd': 'value',
                            'llt_brw': 'value',
                            'llt_efnt': 'value',
                            'llt_fnt': 'value',
                            'llt_fnt2': 'value',
                            'llt_icon_color': 'value',
                            'llt_labelTxt': 'value',
                            'llt_shd': 'value',
                            'llt_tfnt': 'value',
                            'llt_titleColor': 'value',
                            'llt_txt1': 'value',
                            'llt_txt2': 'value',
                            'llt_txt3': 'value',
                            'llt_txtError': 'value',
                            'llt_txtSuccess': 'value'
                        }
                    },
                    'componentClassName': 'wysiwyg.common.components.subscribeform.viewer.SubscribeForm',
                    'pageId': '',
                    'compId': 'ia6qm3rs_6',
                    'styleType': 'custom',
                    'skin': 'wysiwyg.common.components.subscribeform.viewer.skins.SubscribeFormLineLayoutTransparentWithIcon'
                },
                'id': 'ia6qm3rs_6'
            },
            'preset': {'rect': {'width': 324, 'height': 70.5, 'x': 0, 'y': 287}, 'label': '', 'tags': null}
        }, {
            'id': 'Contact_Subscribe_Forms_5',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.common.components.subscribeform.viewer.skins.SubscribeFormBoxLayoutFlat',
                'layout': {
                    'width': 647,
                    'height': 143,
                    'x': -4,
                    'y': 702,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.common.components.subscribeform.viewer.SubscribeForm',
                'data': {
                    'type': 'SubscribeForm',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'toEmailAddress': '',
                    'bccEmailAddress': '',
                    'firstNameFieldLabel': 'Name',
                    'lastNameFieldLabel': 'Last Name',
                    'emailFieldLabel': 'Email',
                    'phoneFieldLabel': 'Mobile Number',
                    'subscribeFormTitle': 'Subscribe for Updates',
                    'textDirection': 'left',
                    'submitButtonLabel': 'Subscribe Now',
                    'successMessage': 'Congrats! You’re subscribed',
                    'errorMessage': 'Please provide a valid email',
                    'validationErrorMessage': 'Please fill in all required fields.'
                },
                'props': {
                    'type': 'SubscribeFormProperties',
                    'metaData': {'schemaVersion': '1.0'},
                    'hiddenFirstNameField': false,
                    'hiddenLastNameField': false,
                    'hiddenEmailField': true,
                    'hiddenPhoneField': false,
                    'requiredFirstNameField': false,
                    'requiredLastNameField': false,
                    'requiredEmailField': true,
                    'requiredPhoneField': false,
                    'useCookie': true
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {
                            'alpha-bg1': '1',
                            'alpha-bg2': '1',
                            'alpha-bg3': '1',
                            'alpha-bg4': '1',
                            'alpha-brd': '1',
                            'alpha-labelTxt': '1',
                            'alpha-titleColor': '1',
                            'alpha-txt1': '1',
                            'alpha-txt2': '1',
                            'alpha-txt3': '1',
                            'alpha-txtError': '1',
                            'alpha-txtSuccess': '1',
                            'bg1': '#F2668F',
                            'bg2': '#F2668F',
                            'bg3': '#FFFFFF',
                            'bg4': '#ED1566',
                            'boxShadowToggleOn-shd': 'false',
                            'brd': '#ED1566',
                            'brw': '0px',
                            'efnt': 'normal normal normal 15px/1.4em amatic+sc',
                            'fnt': 'normal normal normal 15px/1.4em amatic+sc',
                            'fnt2': 'normal normal normal 16px/1.4em amatic+sc',
                            'labelTxt': '#FFFFFF',
                            'shd': '0 1px 4px rgba(0, 0, 0, 0.6);',
                            'tfnt': 'normal normal normal 22px/1.4em amatic+sc',
                            'titleColor': '#F2668F',
                            'txt1': '#FFFFFF',
                            'txt2': '#FFFFFF',
                            'txt3': '#FFFFFF',
                            'txtError': '#E21C21',
                            'txtSuccess': '#68B04D'
                        },
                        'propertiesSource': {
                            'bg1': 'value',
                            'bg2': 'value',
                            'bg3': 'value',
                            'bg4': 'value',
                            'brd': 'value',
                            'brw': 'value',
                            'efnt': 'value',
                            'fnt': 'value',
                            'fnt2': 'value',
                            'labelTxt': 'value',
                            'shd': 'value',
                            'tfnt': 'value',
                            'titleColor': 'value',
                            'txt1': 'value',
                            'txt2': 'value',
                            'txt3': 'value',
                            'txtError': 'value',
                            'txtSuccess': 'value'
                        }
                    },
                    'componentClassName': 'wysiwyg.common.components.subscribeform.viewer.SubscribeForm',
                    'pageId': '',
                    'compId': 'ia6qm3ri',
                    'styleType': 'custom',
                    'skin': 'wysiwyg.common.components.subscribeform.viewer.skins.SubscribeFormBoxLayoutFlat'
                },
                'id': 'ia6qm3ri'
            },
            'preset': {'rect': {'width': 324, 'height': 65, 'x': 0, 'y': 357.5}, 'label': '', 'tags': null}
        }, {
            'id': 'Contact_Subscribe_Forms_6',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.common.components.subscribeform.viewer.skins.SubscribeFormPlaceholderSkin',
                'layout': {
                    'width': 608,
                    'height': 106,
                    'x': 20,
                    'y': 862,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.common.components.subscribeform.viewer.SubscribeForm',
                'data': {
                    'type': 'SubscribeForm',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'toEmailAddress': '',
                    'bccEmailAddress': '',
                    'firstNameFieldLabel': 'Name',
                    'lastNameFieldLabel': 'Last Name',
                    'emailFieldLabel': 'Email',
                    'phoneFieldLabel': 'Mobile Number',
                    'subscribeFormTitle': 'Subscribe for Updates',
                    'textDirection': 'left',
                    'submitButtonLabel': 'Subscribe Now',
                    'successMessage': 'Congrats! You’re subscribed',
                    'errorMessage': 'Please provide a valid email',
                    'validationErrorMessage': 'Please fill in all required fields.'
                },
                'props': {
                    'type': 'SubscribeFormProperties',
                    'metaData': {'schemaVersion': '1.0'},
                    'hiddenFirstNameField': false,
                    'hiddenLastNameField': false,
                    'hiddenEmailField': true,
                    'hiddenPhoneField': false,
                    'requiredFirstNameField': false,
                    'requiredLastNameField': false,
                    'requiredEmailField': true,
                    'requiredPhoneField': false,
                    'useCookie': true
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {
                            'alpha-bg1': '1',
                            'alpha-bg2': '1',
                            'alpha-brd': '1',
                            'alpha-labelTxt': '1',
                            'alpha-phs_bg4': '1',
                            'alpha-phs_txt3': '1',
                            'alpha-titleColor': '1',
                            'alpha-txt1': '1',
                            'alpha-txt2': '1',
                            'alpha-txtError': '1',
                            'alpha-txtSuccess': '1',
                            'bg1': '#ED1566',
                            'bg2': '#ED1566',
                            'boxShadowToggleOn-shd': 'false',
                            'brd': '#ED1566',
                            'brw': '8px',
                            'efnt': 'normal normal normal 12px/1.4em americantypwrteritcw01--731025',
                            'fnt': 'normal normal normal 12px/1.4em americantypwrteritcw01--731025',
                            'fnt2': 'normal normal normal 12px/1.4em americantypwrteritcw01--731025',
                            'labelTxt': '#FFFFFF',
                            'phs_bg4': '#3D9BE9',
                            'phs_txt3': '#2A2A2A',
                            'shd': '0 1px 4px rgba(0, 0, 0, 0.6);',
                            'tfnt': 'normal normal normal 18px/1.4em americantypwrteritcw01--731025',
                            'titleColor': '#ED1566',
                            'txt1': '#FFFFFF',
                            'txt2': '#FFFFFF',
                            'txtError': '#8B0000',
                            'txtSuccess': 'rgba(186,218,85,1)'
                        },
                        'propertiesSource': {
                            'bg1': 'value',
                            'bg2': 'value',
                            'brd': 'value',
                            'brw': 'value',
                            'efnt': 'value',
                            'fnt': 'value',
                            'fnt2': 'value',
                            'labelTxt': 'value',
                            'phs_bg4': 'value',
                            'phs_txt3': 'value',
                            'shd': 'value',
                            'tfnt': 'value',
                            'titleColor': 'value',
                            'txt1': 'value',
                            'txt2': 'value',
                            'txtError': 'value',
                            'txtSuccess': 'value'
                        }
                    },
                    'componentClassName': 'wysiwyg.common.components.subscribeform.viewer.SubscribeForm',
                    'pageId': '',
                    'compId': 'ia6qm3rh',
                    'styleType': 'custom',
                    'skin': 'wysiwyg.common.components.subscribeform.viewer.skins.SubscribeFormPlaceholderSkin'
                },
                'id': 'ia6qm3rh'
            },
            'preset': {'rect': {'width': 324, 'height': 76, 'x': 0, 'y': 422.5}, 'label': '', 'tags': null}
        }, {
            'id': 'Contact_Subscribe_Forms_7',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.common.components.subscribeform.viewer.skins.SubscribeFormBoxLayoutFlat',
                'layout': {
                    'width': 645,
                    'height': 150,
                    'x': -4,
                    'y': 990,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.common.components.subscribeform.viewer.SubscribeForm',
                'data': {
                    'type': 'SubscribeForm',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'toEmailAddress': '',
                    'bccEmailAddress': '',
                    'firstNameFieldLabel': 'Name',
                    'lastNameFieldLabel': 'Last Name',
                    'emailFieldLabel': 'Email',
                    'phoneFieldLabel': 'Mobile Number',
                    'subscribeFormTitle': 'Subscribe for Updates',
                    'textDirection': 'left',
                    'submitButtonLabel': 'Subscribe Now',
                    'successMessage': 'Congrats! You’re subscribed',
                    'errorMessage': 'Please provide a valid email',
                    'validationErrorMessage': 'Please fill in all required fields.'
                },
                'props': {
                    'type': 'SubscribeFormProperties',
                    'metaData': {'schemaVersion': '1.0'},
                    'hiddenFirstNameField': false,
                    'hiddenLastNameField': false,
                    'hiddenEmailField': true,
                    'hiddenPhoneField': false,
                    'requiredFirstNameField': false,
                    'requiredLastNameField': false,
                    'requiredEmailField': true,
                    'requiredPhoneField': false,
                    'useCookie': true
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {
                            'alpha-bg1': '1',
                            'alpha-bg2': '1',
                            'alpha-bg3': '1',
                            'alpha-bg4': '1',
                            'alpha-brd': '1',
                            'alpha-labelTxt': '1',
                            'alpha-titleColor': '1',
                            'alpha-txt1': '1',
                            'alpha-txt2': '1',
                            'alpha-txt3': '1',
                            'alpha-txtError': '1',
                            'alpha-txtSuccess': '1',
                            'bg1': '#FFFFFF',
                            'bg2': '#D61043',
                            'bg3': '#FFFFFF',
                            'bg4': '#B7121B',
                            'boxShadowToggleOn-shd': 'false',
                            'brd': '#D61043',
                            'brw': '4px',
                            'efnt': 'normal normal normal 15px/1.4em museo-slab-w01-100',
                            'fnt': 'normal normal normal 15px/1.4em museo-slab-w01-100',
                            'fnt2': 'normal normal normal 16px/1.4em museo-slab-w01-100',
                            'labelTxt': '#D61043',
                            'shd': '0 1px 4px rgba(0, 0, 0, 0.6);',
                            'tfnt': 'normal normal normal 22px/1.4em museo-slab-w01-100',
                            'titleColor': '#D61043',
                            'txt1': '#2A2A2A',
                            'txt2': '#FFFFFF',
                            'txt3': '#FFFFFF',
                            'txtError': '#E21C21',
                            'txtSuccess': '#68B04D'
                        },
                        'propertiesSource': {
                            'bg1': 'value',
                            'bg2': 'value',
                            'bg3': 'value',
                            'bg4': 'value',
                            'brd': 'value',
                            'brw': 'value',
                            'efnt': 'value',
                            'fnt': 'value',
                            'fnt2': 'value',
                            'labelTxt': 'value',
                            'shd': 'value',
                            'tfnt': 'value',
                            'titleColor': 'value',
                            'txt1': 'value',
                            'txt2': 'value',
                            'txt3': 'value',
                            'txtError': 'value',
                            'txtSuccess': 'value'
                        }
                    },
                    'componentClassName': 'wysiwyg.common.components.subscribeform.viewer.SubscribeForm',
                    'pageId': '',
                    'compId': 'ia6qm3rj',
                    'styleType': 'custom',
                    'skin': 'wysiwyg.common.components.subscribeform.viewer.skins.SubscribeFormBoxLayoutFlat'
                },
                'id': 'ia6qm3rj'
            },
            'preset': {'rect': {'width': 324, 'height': 70.5, 'x': 0, 'y': 498.5}, 'label': '', 'tags': null}
        }, {
            'id': 'Contact_Subscribe_Forms_8',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.common.components.subscribeform.viewer.skins.SubscribeFormPlaceholderSkin',
                'layout': {
                    'width': 609,
                    'height': 107,
                    'x': 21,
                    'y': 1159,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.common.components.subscribeform.viewer.SubscribeForm',
                'data': {
                    'type': 'SubscribeForm',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'toEmailAddress': '',
                    'bccEmailAddress': '',
                    'firstNameFieldLabel': 'Name',
                    'lastNameFieldLabel': 'Last Name',
                    'emailFieldLabel': 'Email',
                    'phoneFieldLabel': 'Mobile Number',
                    'subscribeFormTitle': 'Subscribe for Updates',
                    'textDirection': 'left',
                    'submitButtonLabel': 'Subscribe Now',
                    'successMessage': 'Congrats! You’re subscribed',
                    'errorMessage': 'Please provide a valid email',
                    'validationErrorMessage': 'Please fill in all required fields.'
                },
                'props': {
                    'type': 'SubscribeFormProperties',
                    'metaData': {'schemaVersion': '1.0'},
                    'hiddenFirstNameField': false,
                    'hiddenLastNameField': false,
                    'hiddenEmailField': true,
                    'hiddenPhoneField': false,
                    'requiredFirstNameField': false,
                    'requiredLastNameField': false,
                    'requiredEmailField': true,
                    'requiredPhoneField': false,
                    'useCookie': true
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {
                            'alpha-bg1': '1',
                            'alpha-bg2': '1',
                            'alpha-brd': '1',
                            'alpha-labelTxt': '1',
                            'alpha-phs_bg4': '1',
                            'alpha-phs_txt3': '1',
                            'alpha-titleColor': '1',
                            'alpha-txt1': '1',
                            'alpha-txt2': '1',
                            'alpha-txtError': '1',
                            'alpha-txtSuccess': '1',
                            'bg1': '#FFFFFF',
                            'bg2': '#BDBF37',
                            'boxShadowToggleOn-shd': 'false',
                            'brd': '#BDBF37',
                            'brw': '4px',
                            'efnt': 'normal normal normal 14px/1.4em helvetica-w01-light',
                            'fnt': 'normal normal normal 14px/1.4em helvetica-w01-light',
                            'fnt2': 'normal normal normal 15px/1.4em helvetica-w01-light',
                            'labelTxt': '#BDBF37',
                            'phs_bg4': '#959629',
                            'phs_txt3': '#FFFFFF',
                            'shd': '0 1px 4px rgba(0, 0, 0, 0.6);',
                            'tfnt': 'normal normal normal 19px/1.4em helvetica-w01-light',
                            'titleColor': '#BDBF37',
                            'txt1': '#2A2A2A',
                            'txt2': '#FFFFFF',
                            'txtError': '#E21C21',
                            'txtSuccess': '#68B04D'
                        },
                        'propertiesSource': {
                            'bg1': 'value',
                            'bg2': 'value',
                            'brd': 'value',
                            'brw': 'value',
                            'efnt': 'value',
                            'fnt': 'value',
                            'fnt2': 'value',
                            'labelTxt': 'value',
                            'phs_bg4': 'value',
                            'phs_txt3': 'value',
                            'shd': 'value',
                            'tfnt': 'value',
                            'titleColor': 'value',
                            'txt1': 'value',
                            'txt2': 'value',
                            'txtError': 'value',
                            'txtSuccess': 'value'
                        }
                    },
                    'componentClassName': 'wysiwyg.common.components.subscribeform.viewer.SubscribeForm',
                    'pageId': '',
                    'compId': 'ia6qm3rj_0',
                    'styleType': 'custom',
                    'skin': 'wysiwyg.common.components.subscribeform.viewer.skins.SubscribeFormPlaceholderSkin'
                },
                'id': 'ia6qm3rj_0'
            },
            'preset': {'rect': {'width': 324, 'height': 78.5, 'x': 0, 'y': 570}, 'label': '', 'tags': null}
        }, {
            'id': 'Contact_Subscribe_Forms_9',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.common.components.subscribeform.viewer.skins.SubscribeFormLineLayoutTransparentWithIcon',
                'layout': {
                    'width': 800,
                    'height': 85,
                    'x': 20,
                    'y': 1327,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.common.components.subscribeform.viewer.SubscribeForm',
                'data': {
                    'type': 'SubscribeForm',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'toEmailAddress': '',
                    'bccEmailAddress': '',
                    'firstNameFieldLabel': 'Name',
                    'lastNameFieldLabel': 'Last Name',
                    'emailFieldLabel': 'Email',
                    'phoneFieldLabel': 'Mobile Number',
                    'subscribeFormTitle': 'Subscribe for Updates',
                    'textDirection': 'left',
                    'submitButtonLabel': 'Subscribe Now',
                    'successMessage': 'Congrats! You’re subscribed',
                    'errorMessage': 'Please provide a valid email',
                    'validationErrorMessage': 'Please fill in all required fields.'
                },
                'props': {
                    'type': 'SubscribeFormProperties',
                    'metaData': {'schemaVersion': '1.0'},
                    'hiddenFirstNameField': false,
                    'hiddenLastNameField': false,
                    'hiddenEmailField': true,
                    'hiddenPhoneField': false,
                    'requiredFirstNameField': false,
                    'requiredLastNameField': false,
                    'requiredEmailField': true,
                    'requiredPhoneField': false,
                    'useCookie': true
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {
                            'alpha-llt_bg1': '1',
                            'alpha-llt_bg2': '1',
                            'alpha-llt_bg3': '0',
                            'alpha-llt_bg4': '1',
                            'alpha-llt_brd': '1',
                            'alpha-llt_icon_color': '1',
                            'alpha-llt_labelTxt': '1',
                            'alpha-llt_titleColor': '1',
                            'alpha-llt_txt1': '1',
                            'alpha-llt_txt2': '1',
                            'alpha-llt_txt3': '1',
                            'alpha-llt_txtError': '1',
                            'alpha-llt_txtSuccess': '1',
                            'boxShadowToggleOn-llt_shd': 'false',
                            'llt_bg1': '#FFFFFF',
                            'llt_bg2': '#68B04D',
                            'llt_bg3': '#FFFFFF',
                            'llt_bg4': '#4E8539',
                            'llt_brd': '#68B04D',
                            'llt_brw': '1px',
                            'llt_efnt': 'normal normal normal 14px/1.4em georgia',
                            'llt_fnt': 'normal normal normal 14px/1.4em georgia',
                            'llt_fnt2': 'normal normal normal 14px/1.4em georgia',
                            'llt_icon_color': '#68B04D',
                            'llt_labelTxt': '#68B04D',
                            'llt_shd': '0 1px 4px rgba(0, 0, 0, 0.6);',
                            'llt_tfnt': 'normal normal normal 18px/1.4em georgia',
                            'llt_titleColor': '#68B04D',
                            'llt_txt1': '#2A2A2A',
                            'llt_txt2': '#FFFFFF',
                            'llt_txt3': '#FFFFFF',
                            'llt_txtError': '#E21C21',
                            'llt_txtSuccess': '#68B04D'
                        },
                        'propertiesSource': {
                            'llt_bg1': 'value',
                            'llt_bg2': 'value',
                            'llt_bg3': 'value',
                            'llt_bg4': 'value',
                            'llt_brd': 'value',
                            'llt_brw': 'value',
                            'llt_efnt': 'value',
                            'llt_fnt': 'value',
                            'llt_fnt2': 'value',
                            'llt_icon_color': 'value',
                            'llt_labelTxt': 'value',
                            'llt_shd': 'value',
                            'llt_tfnt': 'value',
                            'llt_titleColor': 'value',
                            'llt_txt1': 'value',
                            'llt_txt2': 'value',
                            'llt_txt3': 'value',
                            'llt_txtError': 'value',
                            'llt_txtSuccess': 'value'
                        }
                    },
                    'componentClassName': 'wysiwyg.common.components.subscribeform.viewer.SubscribeForm',
                    'pageId': '',
                    'compId': 'ia6qm3rq',
                    'styleType': 'custom',
                    'skin': 'wysiwyg.common.components.subscribeform.viewer.skins.SubscribeFormLineLayoutTransparentWithIcon'
                },
                'id': 'ia6qm3rq'
            },
            'preset': {'rect': {'width': 324, 'height': 72, 'x': 0, 'y': 648.5}, 'label': '', 'tags': null}
        }, {
            'id': 'Contact_Subscribe_Forms_10',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.common.components.subscribeform.viewer.skins.SubscribeFormBoxLayoutFlat',
                'layout': {
                    'width': 608,
                    'height': 133,
                    'x': 20,
                    'y': 1470,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.common.components.subscribeform.viewer.SubscribeForm',
                'data': {
                    'type': 'SubscribeForm',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'toEmailAddress': '',
                    'bccEmailAddress': '',
                    'firstNameFieldLabel': 'Name',
                    'lastNameFieldLabel': 'Last Name',
                    'emailFieldLabel': 'Email',
                    'phoneFieldLabel': 'Mobile Number',
                    'subscribeFormTitle': 'Subscribe for Updates',
                    'textDirection': 'left',
                    'submitButtonLabel': 'Subscribe Now',
                    'successMessage': 'Congrats! You’re subscribed',
                    'errorMessage': 'Please provide a valid email',
                    'validationErrorMessage': 'Please fill in all required fields.'
                },
                'props': {
                    'type': 'SubscribeFormProperties',
                    'metaData': {'schemaVersion': '1.0'},
                    'hiddenFirstNameField': false,
                    'hiddenLastNameField': false,
                    'hiddenEmailField': true,
                    'hiddenPhoneField': false,
                    'requiredFirstNameField': false,
                    'requiredLastNameField': false,
                    'requiredEmailField': true,
                    'requiredPhoneField': false,
                    'useCookie': true
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {
                            'alpha-bg1': '1',
                            'alpha-bg2': '1',
                            'alpha-bg3': '1',
                            'alpha-bg4': '1',
                            'alpha-brd': '1',
                            'alpha-labelTxt': '1',
                            'alpha-titleColor': '1',
                            'alpha-txt1': '1',
                            'alpha-txt2': '1',
                            'alpha-txt3': '1',
                            'alpha-txtError': '1',
                            'alpha-txtSuccess': '1',
                            'bg1': '#F5F5F5',
                            'bg2': '#1C8A43',
                            'bg3': '#FFFFFF',
                            'bg4': '#14612E',
                            'boxShadowToggleOn-shd': 'true',
                            'brd': '#1C8A43',
                            'brw': '0px',
                            'efnt': 'italic normal normal 14px/1.4em georgia',
                            'fnt': 'italic normal normal 14px/1.4em georgia',
                            'fnt2': 'italic normal normal 14px/1.4em georgia',
                            'labelTxt': '#1C8A43',
                            'shd': '0px 1px 3px 0px rgba(0,0,0,0.2)',
                            'tfnt': 'italic normal normal 17px/1.4em georgia',
                            'titleColor': '#1C8A43',
                            'txt1': '#555555',
                            'txt2': '#FFFFFF',
                            'txt3': '#FFFFFF',
                            'txtError': '#E21C21',
                            'txtSuccess': '#68B04D'
                        },
                        'propertiesSource': {
                            'bg1': 'value',
                            'bg2': 'value',
                            'bg3': 'value',
                            'bg4': 'value',
                            'brd': 'value',
                            'brw': 'value',
                            'efnt': 'value',
                            'fnt': 'value',
                            'fnt2': 'value',
                            'labelTxt': 'value',
                            'shd': 'value',
                            'tfnt': 'value',
                            'titleColor': 'value',
                            'txt1': 'value',
                            'txt2': 'value',
                            'txt3': 'value',
                            'txtError': 'value',
                            'txtSuccess': 'value'
                        }
                    },
                    'componentClassName': 'wysiwyg.common.components.subscribeform.viewer.SubscribeForm',
                    'pageId': '',
                    'compId': 'ia6qm3rl',
                    'styleType': 'custom',
                    'skin': 'wysiwyg.common.components.subscribeform.viewer.skins.SubscribeFormBoxLayoutFlat'
                },
                'id': 'ia6qm3rl'
            },
            'preset': {'rect': {'width': 324, 'height': 97, 'x': 0, 'y': 720.5}, 'label': '', 'tags': null}
        }, {
            'id': 'Contact_Subscribe_Forms_11',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.common.components.subscribeform.viewer.skins.SubscribeFormLineLayoutFlat',
                'layout': {
                    'width': 729,
                    'height': 110,
                    'x': 20,
                    'y': 1662,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.common.components.subscribeform.viewer.SubscribeForm',
                'data': {
                    'type': 'SubscribeForm',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'toEmailAddress': '',
                    'bccEmailAddress': '',
                    'firstNameFieldLabel': 'Name',
                    'lastNameFieldLabel': 'Last Name',
                    'emailFieldLabel': 'Email',
                    'phoneFieldLabel': 'Mobile Number',
                    'subscribeFormTitle': 'Subscribe for Updates',
                    'textDirection': 'left',
                    'submitButtonLabel': 'Subscribe Now',
                    'successMessage': 'Congrats! You’re subscribed',
                    'errorMessage': 'Please provide a valid email',
                    'validationErrorMessage': 'Please fill in all required fields.'
                },
                'props': {
                    'type': 'SubscribeFormProperties',
                    'metaData': {'schemaVersion': '1.0'},
                    'hiddenFirstNameField': false,
                    'hiddenLastNameField': false,
                    'hiddenEmailField': true,
                    'hiddenPhoneField': false,
                    'requiredFirstNameField': false,
                    'requiredLastNameField': false,
                    'requiredEmailField': true,
                    'requiredPhoneField': false,
                    'useCookie': true
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {
                            'alpha-llf_bg1': '1',
                            'alpha-llf_bg2': '1',
                            'alpha-llf_bg3': '1',
                            'alpha-llf_bg4': '1',
                            'alpha-llf_brd': '1',
                            'alpha-llf_labelTxt': '1',
                            'alpha-llf_titleColor': '1',
                            'alpha-llf_txt1': '1',
                            'alpha-llf_txt2': '1',
                            'alpha-llf_txt3': '1',
                            'alpha-llf_txtError': '1',
                            'alpha-llf_txtSuccess': '1',
                            'boxShadowToggleOn-llf_shd': 'true',
                            'llf_bg1': '#F2F2F2',
                            'llf_bg2': '#01B1AF',
                            'llf_bg3': '#FFFFFF',
                            'llf_bg4': '#006C6D',
                            'llf_brd': '#01B1AF',
                            'llf_brw': '0px',
                            'llf_efnt': 'normal normal normal 12px/1.4em helvetica-w01-light',
                            'llf_fnt': 'normal normal normal 12px/1.4em helvetica-w01-light',
                            'llf_fnt2': 'normal normal normal 12px/1.4em helvetica-w01-light',
                            'llf_labelTxt': '#01B1AF',
                            'llf_shd': '0px 1px 3px 0px rgba(0,0,0,0.2)',
                            'llf_tfnt': 'normal normal normal 20px/1.4em helvetica-w01-light',
                            'llf_titleColor': '#01B1AF',
                            'llf_txt1': '#2A2A2A',
                            'llf_txt2': '#FFFFFF',
                            'llf_txt3': '#FFFFFF',
                            'llf_txtError': '#E21C21',
                            'llf_txtSuccess': '#68B04D'
                        },
                        'propertiesSource': {
                            'llf_bg1': 'value',
                            'llf_bg2': 'value',
                            'llf_bg3': 'value',
                            'llf_bg4': 'value',
                            'llf_brd': 'value',
                            'llf_brw': 'value',
                            'llf_efnt': 'value',
                            'llf_fnt': 'value',
                            'llf_fnt2': 'value',
                            'llf_labelTxt': 'value',
                            'llf_shd': 'value',
                            'llf_tfnt': 'value',
                            'llf_titleColor': 'value',
                            'llf_txt1': 'value',
                            'llf_txt2': 'value',
                            'llf_txt3': 'value',
                            'llf_txtError': 'value',
                            'llf_txtSuccess': 'value'
                        }
                    },
                    'componentClassName': 'wysiwyg.common.components.subscribeform.viewer.SubscribeForm',
                    'pageId': '',
                    'compId': 'ia6qm3rp_5',
                    'styleType': 'custom',
                    'skin': 'wysiwyg.common.components.subscribeform.viewer.skins.SubscribeFormLineLayoutFlat'
                },
                'id': 'ia6qm3rp_5'
            },
            'preset': {'rect': {'width': 324, 'height': 84.5, 'x': 0, 'y': 817}, 'label': '', 'tags': null}
        }, {
            'id': 'Contact_Subscribe_Forms_12',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.common.components.subscribeform.viewer.skins.SubscribeFormBoxLayoutShiny',
                'layout': {
                    'width': 607,
                    'height': 143,
                    'x': 21,
                    'y': 1833,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.common.components.subscribeform.viewer.SubscribeForm',
                'data': {
                    'type': 'SubscribeForm',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'toEmailAddress': '',
                    'bccEmailAddress': '',
                    'firstNameFieldLabel': 'Name',
                    'lastNameFieldLabel': 'Last Name',
                    'emailFieldLabel': 'Email',
                    'phoneFieldLabel': 'Mobile Number',
                    'subscribeFormTitle': 'Subscribe for Updates',
                    'textDirection': 'left',
                    'submitButtonLabel': 'Subscribe Now',
                    'successMessage': 'Congrats! You’re subscribed',
                    'errorMessage': 'Please provide a valid email',
                    'validationErrorMessage': 'Please fill in all required fields.'
                },
                'props': {
                    'type': 'SubscribeFormProperties',
                    'metaData': {'schemaVersion': '1.0'},
                    'hiddenFirstNameField': false,
                    'hiddenLastNameField': false,
                    'hiddenEmailField': true,
                    'hiddenPhoneField': false,
                    'requiredFirstNameField': false,
                    'requiredLastNameField': false,
                    'requiredEmailField': true,
                    'requiredPhoneField': false,
                    'useCookie': true
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {
                            'alpha-bls_bg1': '1',
                            'alpha-bls_bg2': '1',
                            'alpha-bls_bg3': '0',
                            'alpha-bls_bg4': '1',
                            'alpha-bls_brd': '1',
                            'alpha-bls_labelTxt': '1',
                            'alpha-bls_titleColor': '1',
                            'alpha-bls_txt1': '1',
                            'alpha-bls_txt2': '1',
                            'alpha-bls_txt3': '1',
                            'alpha-bls_txtError': '1',
                            'alpha-bls_txtSuccess': '1',
                            'bls_bg1': '#F5F5F5',
                            'bls_bg2': '#006C6D',
                            'bls_bg3': '#01B1AF',
                            'bls_bg4': '#004B4D',
                            'bls_brd': '#004B4D',
                            'bls_brw': '0px',
                            'bls_efnt': 'normal normal normal 14px/1.4em open+sans+condensed',
                            'bls_fnt': 'normal normal normal 14px/1.4em open+sans+condensed',
                            'bls_fnt2': 'normal normal normal 16px/1.4em open+sans+condensed',
                            'bls_labelTxt': '#FFFFFF',
                            'bls_shd': '0px 2px 3px 0px rgba(50,50,50,0.2)',
                            'bls_tfnt': 'normal normal normal 22px/1.4em open+sans+condensed',
                            'bls_titleColor': '#006C6D',
                            'bls_txt1': '#006C6D',
                            'bls_txt2': '#FFFFFF',
                            'bls_txt3': '#FFFFFF',
                            'bls_txtError': '#E21C21',
                            'bls_txtSuccess': '#68B04D',
                            'boxShadowToggleOn-bls_shd': 'true'
                        },
                        'propertiesSource': {
                            'bls_bg1': 'value',
                            'bls_bg2': 'value',
                            'bls_bg3': 'value',
                            'bls_bg4': 'value',
                            'bls_brd': 'value',
                            'bls_brw': 'value',
                            'bls_efnt': 'value',
                            'bls_fnt': 'value',
                            'bls_fnt2': 'value',
                            'bls_labelTxt': 'value',
                            'bls_shd': 'value',
                            'bls_tfnt': 'value',
                            'bls_titleColor': 'value',
                            'bls_txt1': 'value',
                            'bls_txt2': 'value',
                            'bls_txt3': 'value',
                            'bls_txtError': 'value',
                            'bls_txtSuccess': 'value'
                        }
                    },
                    'componentClassName': 'wysiwyg.common.components.subscribeform.viewer.SubscribeForm',
                    'pageId': '',
                    'compId': 'ia6qm3rl_0',
                    'styleType': 'custom',
                    'skin': 'wysiwyg.common.components.subscribeform.viewer.skins.SubscribeFormBoxLayoutShiny'
                },
                'id': 'ia6qm3rl_0'
            },
            'preset': {'rect': {'width': 324, 'height': 101.5, 'x': 0, 'y': 901.5}, 'label': '', 'tags': null}
        }, {
            'id': 'Contact_Subscribe_Forms_13',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.common.components.subscribeform.viewer.skins.SubscribeFormLineLayoutTransparentWithIcon',
                'layout': {
                    'width': 800,
                    'height': 83,
                    'x': 21,
                    'y': 2036,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.common.components.subscribeform.viewer.SubscribeForm',
                'data': {
                    'type': 'SubscribeForm',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'toEmailAddress': '',
                    'bccEmailAddress': '',
                    'firstNameFieldLabel': 'Name',
                    'lastNameFieldLabel': 'Last Name',
                    'emailFieldLabel': 'Email',
                    'phoneFieldLabel': 'Mobile Number',
                    'subscribeFormTitle': 'Subscribe for Updates',
                    'textDirection': 'left',
                    'submitButtonLabel': 'Subscribe Now',
                    'successMessage': 'Congrats! You’re subscribed',
                    'errorMessage': 'Please provide a valid email',
                    'validationErrorMessage': 'Please fill in all required fields.'
                },
                'props': {
                    'type': 'SubscribeFormProperties',
                    'metaData': {'schemaVersion': '1.0'},
                    'hiddenFirstNameField': false,
                    'hiddenLastNameField': false,
                    'hiddenEmailField': true,
                    'hiddenPhoneField': false,
                    'requiredFirstNameField': false,
                    'requiredLastNameField': false,
                    'requiredEmailField': true,
                    'requiredPhoneField': false,
                    'useCookie': true
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {
                            'alpha-llt_bg1': '1',
                            'alpha-llt_bg2': '1',
                            'alpha-llt_bg3': '1',
                            'alpha-llt_bg4': '1',
                            'alpha-llt_brd': '1',
                            'alpha-llt_icon_color': '1',
                            'alpha-llt_labelTxt': '1',
                            'alpha-llt_titleColor': '1',
                            'alpha-llt_txt1': '1',
                            'alpha-llt_txt2': '1',
                            'alpha-llt_txt3': '1',
                            'alpha-llt_txtError': '1',
                            'alpha-llt_txtSuccess': '1',
                            'boxShadowToggleOn-llt_shd': 'true',
                            'llt_bg1': '#E3E2E2',
                            'llt_bg2': '#DBB89A',
                            'llt_bg3': '#FFFFFF',
                            'llt_bg4': '#B19275',
                            'llt_brd': '#DBB89A',
                            'llt_brw': '0px',
                            'llt_efnt': 'normal normal normal 14px/1.4em museo-slab-w01-100',
                            'llt_fnt': 'normal normal normal 14px/1.4em museo-slab-w01-100',
                            'llt_fnt2': 'normal normal normal 14px/1.4em museo-slab-w01-100',
                            'llt_icon_color': '#DBB89A',
                            'llt_labelTxt': '#555555',
                            'llt_shd': '0px 1px 3px 0px rgba(0,0,0,0.2)',
                            'llt_tfnt': 'normal normal normal 18px/1.4em museo-slab-w01-100',
                            'llt_titleColor': '#2A2A2A',
                            'llt_txt1': '#2A2A2A',
                            'llt_txt2': '#FFFFFF',
                            'llt_txt3': '#FFFFFF',
                            'llt_txtError': '#E21C21',
                            'llt_txtSuccess': '#68B04D'
                        },
                        'propertiesSource': {
                            'llt_bg1': 'value',
                            'llt_bg2': 'value',
                            'llt_bg3': 'value',
                            'llt_bg4': 'value',
                            'llt_brd': 'value',
                            'llt_brw': 'value',
                            'llt_efnt': 'value',
                            'llt_fnt': 'value',
                            'llt_fnt2': 'value',
                            'llt_icon_color': 'value',
                            'llt_labelTxt': 'value',
                            'llt_shd': 'value',
                            'llt_tfnt': 'value',
                            'llt_titleColor': 'value',
                            'llt_txt1': 'value',
                            'llt_txt2': 'value',
                            'llt_txt3': 'value',
                            'llt_txtError': 'value',
                            'llt_txtSuccess': 'value'
                        }
                    },
                    'componentClassName': 'wysiwyg.common.components.subscribeform.viewer.SubscribeForm',
                    'pageId': '',
                    'compId': 'ia6qm3rs_3',
                    'styleType': 'custom',
                    'skin': 'wysiwyg.common.components.subscribeform.viewer.skins.SubscribeFormLineLayoutTransparentWithIcon'
                },
                'id': 'ia6qm3rs_3'
            },
            'preset': {'rect': {'width': 324, 'height': 71.5, 'x': 0.5, 'y': 1003}, 'label': '', 'tags': null}
        }, {
            'id': 'Contact_Subscribe_Forms_14',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.common.components.subscribeform.viewer.skins.SubscribeFormBoxLayoutEnvelope',
                'layout': {
                    'width': 617,
                    'height': 182,
                    'x': 21,
                    'y': 2175,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.common.components.subscribeform.viewer.SubscribeForm',
                'data': {
                    'type': 'SubscribeForm',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'toEmailAddress': '',
                    'bccEmailAddress': '',
                    'firstNameFieldLabel': 'Name',
                    'lastNameFieldLabel': 'Last Name',
                    'emailFieldLabel': 'Email',
                    'phoneFieldLabel': 'Mobile Number',
                    'subscribeFormTitle': 'Subscribe',
                    'textDirection': 'left',
                    'submitButtonLabel': 'Subscribe Now',
                    'successMessage': 'Congrats! You’re subscribed',
                    'errorMessage': 'Please provide a valid email',
                    'validationErrorMessage': 'Please fill in all required fields.'
                },
                'props': {
                    'type': 'SubscribeFormProperties',
                    'metaData': {'schemaVersion': '1.0'},
                    'hiddenFirstNameField': false,
                    'hiddenLastNameField': false,
                    'hiddenEmailField': true,
                    'hiddenPhoneField': false,
                    'requiredFirstNameField': false,
                    'requiredLastNameField': false,
                    'requiredEmailField': true,
                    'requiredPhoneField': false,
                    'useCookie': true
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {
                            'alpha-bg1': '1',
                            'alpha-bg2': '1',
                            'alpha-bg3': '1',
                            'alpha-bg4': '1',
                            'alpha-brd': '1',
                            'alpha-labelTxt': '1',
                            'alpha-titleColor': '1',
                            'alpha-txt1': '1',
                            'alpha-txt2': '1',
                            'alpha-txt3': '1',
                            'alpha-txtError': '1',
                            'alpha-txtSuccess': '1',
                            'bg1': '#FFFFFF',
                            'bg2': '#2A2A2A',
                            'bg3': '#FFFFFF',
                            'bg4': '#555555',
                            'brd': '#2A2A2A',
                            'brw': '0px',
                            'efnt': 'normal normal normal 14px/1.4em americantypwrteritcw01--731025',
                            'fnt': 'normal normal normal 14px/1.4em americantypwrteritcw01--731025',
                            'fnt2': 'normal normal normal 16px/1.4em americantypwrteritcw01--731025',
                            'labelTxt': '#2A2A2A',
                            'tfnt': 'normal normal normal 19px/1.4em americantypwrteritcw01--731025',
                            'titleColor': '#2A2A2A',
                            'txt1': '#2A2A2A',
                            'txt2': '#FFFFFF',
                            'txt3': '#FFFFFF',
                            'txtError': '#E21C21',
                            'txtSuccess': '#68B04D'
                        },
                        'propertiesSource': {
                            'bg1': 'value',
                            'bg2': 'value',
                            'bg3': 'value',
                            'bg4': 'value',
                            'brd': 'value',
                            'brw': 'value',
                            'efnt': 'value',
                            'fnt': 'value',
                            'fnt2': 'value',
                            'labelTxt': 'value',
                            'tfnt': 'value',
                            'titleColor': 'value',
                            'txt1': 'value',
                            'txt2': 'value',
                            'txt3': 'value',
                            'txtError': 'value',
                            'txtSuccess': 'value'
                        }
                    },
                    'componentClassName': 'wysiwyg.common.components.subscribeform.viewer.SubscribeForm',
                    'pageId': '',
                    'compId': 'ia6qm3rg',
                    'styleType': 'custom',
                    'skin': 'wysiwyg.common.components.subscribeform.viewer.skins.SubscribeFormBoxLayoutEnvelope'
                },
                'id': 'ia6qm3rg'
            },
            'preset': {'rect': {'width': 324, 'height': 117.5, 'x': 0.5, 'y': 1074.5}, 'label': '', 'tags': null}
        }, {
            'id': 'Contact_Subscribe_Forms_15',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.common.components.subscribeform.viewer.skins.SubscribeFormBoxLayoutShiny',
                'layout': {
                    'width': 608,
                    'height': 150,
                    'x': 21,
                    'y': 2414,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.common.components.subscribeform.viewer.SubscribeForm',
                'data': {
                    'type': 'SubscribeForm',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'toEmailAddress': '',
                    'bccEmailAddress': '',
                    'firstNameFieldLabel': 'Name',
                    'lastNameFieldLabel': 'Last Name',
                    'emailFieldLabel': 'Email',
                    'phoneFieldLabel': 'Mobile Number',
                    'subscribeFormTitle': 'Subscribe for Updates',
                    'textDirection': 'left',
                    'submitButtonLabel': 'Subscribe Now',
                    'successMessage': 'Congrats! You’re subscribed',
                    'errorMessage': 'Please provide a valid email',
                    'validationErrorMessage': 'Please fill in all required fields.'
                },
                'props': {
                    'type': 'SubscribeFormProperties',
                    'metaData': {'schemaVersion': '1.0'},
                    'hiddenFirstNameField': false,
                    'hiddenLastNameField': false,
                    'hiddenEmailField': true,
                    'hiddenPhoneField': false,
                    'requiredFirstNameField': false,
                    'requiredLastNameField': false,
                    'requiredEmailField': true,
                    'requiredPhoneField': false,
                    'useCookie': true
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {
                            'alpha-bls_bg1': '1',
                            'alpha-bls_bg2': '1',
                            'alpha-bls_bg3': '1',
                            'alpha-bls_bg4': '1',
                            'alpha-bls_brd': '1',
                            'alpha-bls_labelTxt': '1',
                            'alpha-bls_titleColor': '1',
                            'alpha-bls_txt1': '1',
                            'alpha-bls_txt2': '1',
                            'alpha-bls_txt3': '1',
                            'alpha-bls_txtError': '1',
                            'alpha-bls_txtSuccess': '1',
                            'bls_bg1': '#FFFFFF',
                            'bls_bg2': '#8DD0CC',
                            'bls_bg3': '#2A2A2A',
                            'bls_bg4': '#555555',
                            'bls_brd': '#555555',
                            'bls_brw': '0px',
                            'bls_efnt': 'normal normal normal 16px/1.4em amatic+sc',
                            'bls_fnt': 'normal normal normal 16px/1.4em amatic+sc',
                            'bls_fnt2': 'normal normal normal 18px/1.4em amatic+sc',
                            'bls_labelTxt': '#2A2A2A',
                            'bls_shd': '0px 2px 1px 0px rgba(50,50,50,0.2)',
                            'bls_tfnt': 'normal normal normal 26px/1.4em amatic+sc',
                            'bls_titleColor': '#FFFFFF',
                            'bls_txt1': '#2A2A2A',
                            'bls_txt2': '#FFFFFF',
                            'bls_txt3': '#FFFFFF',
                            'bls_txtError': '#E21C21',
                            'bls_txtSuccess': '#68B04D',
                            'boxShadowToggleOn-bls_shd': 'false'
                        },
                        'propertiesSource': {
                            'bls_bg1': 'value',
                            'bls_bg2': 'value',
                            'bls_bg3': 'value',
                            'bls_bg4': 'value',
                            'bls_brd': 'value',
                            'bls_brw': 'value',
                            'bls_efnt': 'value',
                            'bls_fnt': 'value',
                            'bls_fnt2': 'value',
                            'bls_labelTxt': 'value',
                            'bls_shd': 'value',
                            'bls_tfnt': 'value',
                            'bls_titleColor': 'value',
                            'bls_txt1': 'value',
                            'bls_txt2': 'value',
                            'bls_txt3': 'value',
                            'bls_txtError': 'value',
                            'bls_txtSuccess': 'value'
                        }
                    },
                    'componentClassName': 'wysiwyg.common.components.subscribeform.viewer.SubscribeForm',
                    'pageId': '',
                    'compId': 'ia6qm3rn',
                    'styleType': 'custom',
                    'skin': 'wysiwyg.common.components.subscribeform.viewer.skins.SubscribeFormBoxLayoutShiny'
                },
                'id': 'ia6qm3rn'
            },
            'preset': {'rect': {'width': 324, 'height': 122, 'x': 0, 'y': 1192}, 'label': '', 'tags': null}
        }],
        'compTypes': ['wysiwyg.common.components.subscribeform.viewer.SubscribeForm']
    },
    'teaser': {
        'id': 'subscribe_forms_section_teaser',
        'hide': false,
        'title': 'add_section_teaser_title_subscribeform',
        'text': 'add_section_teaser_text_subscribeform',
        'linkText': 'subscribe_forms_section_teaser_link'
    },
    'help': {'hide': false, 'text': 'add_section_info_text_subscribeform'}
}
