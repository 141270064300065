import _ from 'lodash';
import * as stateManagement from '@/stateManagement';

import {
  dispatchEditorAPI,
  getSendBi,
} from '../../../topBarComponent/topBarMapper';
import {
  mapStateToProps as mapStateToMenuAPIProps,
  mapDispatchToProps as mapDispatchToMenuAPIProps,
} from './siteMenuAPIMapper';

import type { MapDispatchToPropsFunction, MapStateToProps } from 'types/redux';
import type {
  SiteListItemsOwnProps,
  SiteListItemsStateProps,
  SiteListItemsDispatchProps,
} from './siteMenuStructure';
import constants from '@/constants';

const { selectors: savePublishSelectors } = stateManagement.savePublish;
const { getSiteUserPreferences } = stateManagement.userPreferences.selectors;

const mapStateToProps: MapStateToProps<
  SiteListItemsStateProps,
  SiteListItemsOwnProps
> = (...args) => {
  const [{ editorAPI, state }] = args;
  const isFirstSave =
    _.invoke(editorAPI, 'dsRead.generalInfo.isFirstSave') || false;
  const isDraftMode = _.invoke(editorAPI, 'dsRead.generalInfo.isDraft');
  const numberOfManualPublish = getSiteUserPreferences('numberOfManualPublish')(
    editorAPI.store.getState(),
  ) as number;

  return {
    ...mapStateToMenuAPIProps(...args),

    isFirstSave,
    isDraftMode,
    userPermissions:
      _.invoke(editorAPI, 'generalInfo.getUserPermissions') || [],
    isWixStudioUser: editorAPI.account.isStudioAccount(),
    isUserOwner: editorAPI.account.isUserOwner(),
    hasSiteBeenADI: editorAPI.site.hasSiteBeenADI(),
    canSiteBeRestoredToADI: editorAPI.site.canRestoreToADI(),
    isDeveloperModeEnabled: editorAPI.developerMode.isEnabled(),
    canUserPublish: editorAPI.savePublish.canUserPublish(),
    shouldSaveBeforePublish:
      isFirstSave ||
      isDraftMode ||
      savePublishSelectors.getOverridingSaveAndPublish(
        editorAPI.store.getState(),
      ),
    isTemplate: _.result(editorAPI, 'dsRead.generalInfo.isTemplate'),
    isSavedInCurrentSession: editorAPI.isSavedInCurrentSession,
    isSitePublished:
      _.invoke(editorAPI, 'dsRead.generalInfo.isSitePublished') || false,
    isMobileEditor: editorAPI.isMobileEditor(),
    isPreviewMode: editorAPI.preview.isInPreviewMode(),
    siteWasOnlyAutoPublished: !getSiteUserPreferences(
      constants.USER_PREFS.PUBLISH.SITE_WAS_ONLY_AUTO_PUBLISHED,
    )(state),
    isPremium: editorAPI.site?.isPremium(),
    numberOfManualPublish,
  };
};

const mapDispatchToProps: MapDispatchToPropsFunction<
  SiteListItemsDispatchProps,
  SiteListItemsOwnProps
> = (dispatch, ownProps) => ({
  ...mapDispatchToMenuAPIProps(dispatch, ownProps),

  sendBi: getSendBi(dispatch),
  getFeedbackNewCommentsCounter: dispatchEditorAPI(dispatch, (editorAPI) => {
    const newCommentsCounter = editorAPI.feedback.getNewCommentsCounter();
    return newCommentsCounter > 99 ? '99+' : newCommentsCounter;
  }),
  getEditorVersionsInfo: dispatchEditorAPI(dispatch, (editorAPI) =>
    editorAPI.account.getEditorVersionsInfo(),
  ),
});

export { mapStateToProps, mapDispatchToProps };
