'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')
const util = require('@/util')

module.exports = {
    "type": addPanelDataConsts.SECTIONS_TYPES.PRESET,
    "hide": false,
    "title": "add_section_label_RadioButtons",
    "subNavigationTitle": "add_section_label_RadioButtons",
    "topTitle": "add_section_title_radio_buttons",
    "presetTitle": "add_section_heading_radio_buttons",
    "tooltipTitle": "add_section_label_RadioButtons_tooltip_title",
    "automationId": "add-panel-section-radioGroupSection",
    "subNavigationHide": false,
    "showSectionHeader": true,
    "additionalBehaviours": {
        "labelMode": addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
        "infoIcon": addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
        "hoverImageAction": addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
        "iconEnabledForComps": {}
    },
    "props": {
        'image': `addPanelData/sections/radioGroupSection_${util.languages.getLanguageCode()}/radioGroupSection_${util.languages.getLanguageCode()}.v3.png`,
        "imageHover": null,
        "items": [{
            "id": "User_Input_RadioGroup_1",
            "structure": {
                "type": "Component",
                "skin": "skins.input.RadioGroupDefaultSkin",
                "layout": {
                    "width": 89,
                    "height": 89,
                    "x": 207,
                    "y": 17,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.inputs.RadioGroup",
                "data": {
                    "type": "RadioGroup",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "value": "Radio button2",
                    "options": [{
                        "type": "RadioButton",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "value": "Radio button1",
                        "label": "18-24"
                    }, {
                        "type": "RadioButton",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "value": "Radio button2",
                        "label": "25-34"
                    }, {
                        "type": "RadioButton",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "value": "Radio button3",
                        "label": "35+"
                    }],
                    "defaultValue": "Radio button2"
                },
                "props": {
                    "type": "RadioGroupProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "alignment": "left",
                    "layout": "vertical",
                    "buttonsMargin": 16,
                    "spacing": 14,
                    "buttonSize": 16
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "properties": {
                            "alpha-bg3e": "0",
                            "alpha-bgf": "0",
                            "alpha-bgh": "0",
                            "bg": "rgba(255, 255, 255, 1)",
                            "bg2": "#828282",
                            "bg3": "#737373",
                            "bg3e": "#FF4040",
                            "bg3f": "#919191",
                            "bg3h": "#525252",
                            "bgd": "#DEDEDE",
                            "bge": "#FF4040",
                            "boxShadowToggleOn-shd": "false",
                            "brd": "#888888",
                            "brw": "2px",
                            "brwd": "0",
                            "brwf": "2",
                            "brwh": "2",
                            "fnt": "normal normal normal 14px/1.4em avenir-lt-w01_35-light1475496",
                            "fnt2": "normal normal 700 14px/1.4em avenir-lt-w01_35-light1475496",
                            "rd": "5px",
                            "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                            "txt": "#525252",
                            "txt2": "#61615F",
                            "txtlblrq": "#61615F"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.inputs.RadioGroup",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "skins.input.RadioGroupDefaultSkin"
                },
                "activeModes": {},
                "id": "comp-itegufvk"
            },
            "preset": {"rect": {"width": 137, "height": 125, "x": 186, "y": 0}, "tags": null}
        }, {
            "id": "User_Input_RadioGroup_2",
            "structure": {
                "type": "Component",
                "skin": "skins.input.RadioGroupDefaultSkin",
                "layout": {
                    "width": 142,
                    "height": 89,
                    "x": 10,
                    "y": 15,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.inputs.RadioGroup",
                "data": {
                    "type": "RadioGroup",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "value": "Radio button1",
                    "options": [{
                        "type": "RadioButton",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "value": "Radio button1",
                        "label": "NY Rockets"
                    }, {
                        "type": "RadioButton",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "value": "Radio button2",
                        "label": "Chicago Sharks"
                    }, {
                        "type": "RadioButton",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "value": "Radio button3",
                        "label": "LA Tigers"
                    }],
                    "defaultValue": "Radio button1"
                },
                "props": {
                    "type": "RadioGroupProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "alignment": "left",
                    "layout": "vertical",
                    "buttonsMargin": 16,
                    "spacing": 15,
                    "buttonSize": 18
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "properties": {
                            "alpha-bg3e": "0",
                            "alpha-bg3h": "0",
                            "alpha-bgf": "1",
                            "alpha-bgh": "1",
                            "alpha-brdd": "1",
                            "bg": "rgba(255, 255, 255, 1)",
                            "bg2": "#49D6D6",
                            "bg3": "#49D6D6",
                            "bg3e": "#FF4040",
                            "bg3f": "#49D6D6",
                            "bg3h": "#525252",
                            "bgd": "#DEDEDE",
                            "bge": "#FF4040",
                            "bgf": "#49D6D6",
                            "bgh": "#49D6D6",
                            "boxShadowToggleOn-shd": "false",
                            "brd": "#888888",
                            "brw": "2px",
                            "brwd": "0",
                            "brwf": "0",
                            "brwh": "2",
                            "fnt": "italic normal normal 14px/1.4em georgia",
                            "fnt2": "italic normal normal 14px/1.4em georgia",
                            "rd": "5px",
                            "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                            "txt": "#000000",
                            "txt2": "#49D6D6",
                            "txtlblrq": "#49D6D6"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.inputs.RadioGroup",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "skins.input.RadioGroupDefaultSkin"
                },
                "activeModes": {},
                "id": "comp-itegufu9"
            },
            "preset": {"rect": {"width": 186, "height": 124, "x": 1, "y": 1}, "tags": null}
        }, {
            "id": "User_Input_RadioGroup_3",
            "structure": {
                "type": "Component",
                "skin": "skins.input.RadioGroupDefaultSkin",
                "layout": {
                    "width": 176,
                    "height": 97,
                    "x": 11,
                    "y": 143,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.inputs.RadioGroup",
                "data": {
                    "type": "RadioGroup",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "value": "Radio button2",
                    "options": [{
                        "type": "RadioButton",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "value": "Radio button1",
                        "label": "Yep, I'll come"
                    }, {
                        "type": "RadioButton",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "value": "Radio button2",
                        "label": "Nope, can’t make it"
                    }, {
                        "type": "RadioButton",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "value": "Radio button3",
                        "label": "Maybe, we’ll see"
                    }],
                    "defaultValue": "Radio button2"
                },
                "props": {
                    "type": "RadioGroupProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "alignment": "left",
                    "layout": "vertical",
                    "buttonsMargin": 17,
                    "spacing": 15,
                    "buttonSize": 18
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "properties": {
                            "alpha-bg3e": "0",
                            "alpha-bg3h": "0",
                            "alpha-bgf": "1",
                            "alpha-bgh": "1",
                            "bg": "rgba(255, 255, 255, 1)",
                            "bg2": "#8C84FA",
                            "bg2h": "#8C84FA",
                            "bg3": "#8C84FA",
                            "bg3e": "#FF4040",
                            "bg3f": "#8C84FA",
                            "bg3h": "#8C84FA",
                            "bgd": "#DEDEDE",
                            "bge": "#FF4040",
                            "bgf": "#FFFFFF",
                            "bgh": "#8C84FA",
                            "boxShadowToggleOn-shd": "false",
                            "brd": "#888888",
                            "brw": "1",
                            "brwd": "0",
                            "brwf": "1",
                            "brwh": "1",
                            "fnt": "normal normal normal 15px/1.4em futura-lt-w01-light",
                            "fnt2": "normal normal normal 15px/1.4em futura-lt-w01-book",
                            "rd": "5px",
                            "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                            "txt": "#000000",
                            "txt2": "#8C84FA",
                            "txtlblrq": "#8C84FA"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.inputs.RadioGroup",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "skins.input.RadioGroupDefaultSkin"
                },
                "activeModes": {},
                "id": "comp-itegufup"
            },
            "preset": {"rect": {"width": 186, "height": 134, "x": 1, "y": 125}, "tags": null}
        }, {
            "id": "User_Input_RadioGroup_4",
            "structure": {
                "type": "Component",
                "skin": "skins.input.RadioGroupDefaultSkin",
                "layout": {
                    "width": 107,
                    "height": 97,
                    "x": 206,
                    "y": 144,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.inputs.RadioGroup",
                "data": {
                    "type": "RadioGroup",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "value": "Radio button3",
                    "options": [{
                        "type": "RadioButton",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "value": "Radio button1",
                        "label": "Awesome"
                    }, {
                        "type": "RadioButton",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "value": "Radio button2",
                        "label": "Nice! "
                    }, {
                        "type": "RadioButton",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "value": "Radio button3",
                        "label": "Meh"
                    }],
                    "defaultValue": "Radio button3"
                },
                "props": {
                    "type": "RadioGroupProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "alignment": "left",
                    "layout": "vertical",
                    "buttonsMargin": 17,
                    "spacing": 14,
                    "buttonSize": 17
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "properties": {
                            "alpha-bgf": "0",
                            "alpha-bgh": "0",
                            "bg": "rgba(94,224,138,1)",
                            "bg2": "#FFFFFF",
                            "bg2h": "#5EE08A",
                            "bg3": "#5EE08A",
                            "bg3e": "#FF4040",
                            "bg3f": "#5EE08A",
                            "bg3h": "#5EE08A",
                            "bge": "#FFFFFF",
                            "bgh": "#4BDB7B",
                            "boxShadowToggleOn-shd": "false",
                            "brd": "#888888",
                            "brw": "1",
                            "brwf": "1",
                            "brwh": "1",
                            "fnt": "normal normal normal 15px/1.4em bree-w01-thin-oblique",
                            "fnt2": "normal normal normal 14px/1.4em bree-w01-thin-oblique",
                            "rd": "5px",
                            "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                            "txt": "#26D15F",
                            "txt2": "#5EE08A",
                            "txtlblrq": "#5EE08A"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.inputs.RadioGroup",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "skins.input.RadioGroupDefaultSkin"
                },
                "activeModes": {},
                "id": "comp-itegufuf"
            },
            "preset": {"rect": {"width": 137, "height": 134, "x": 186, "y": 125}, "tags": null}
        }, {
            "id": "User_Input_RadioGroup_5",
            "structure": {
                "type": "Component",
                "skin": "skins.input.RadioGroupDefaultSkin",
                "layout": {
                    "width": 151,
                    "height": 90,
                    "x": 10,
                    "y": 278,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.inputs.RadioGroup",
                "data": {
                    "type": "RadioGroup",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "value": "Radio button1",
                    "options": [{
                        "type": "RadioButton",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "value": "Radio button1",
                        "label": "Paris"
                    }, {
                        "type": "RadioButton",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "value": "Radio button2",
                        "label": "London"
                    }, {
                        "type": "RadioButton",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "value": "Radio button3",
                        "label": "New York"
                    }],
                    "defaultValue": "Radio button1"
                },
                "props": {
                    "type": "RadioGroupProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "alignment": "left",
                    "layout": "vertical",
                    "buttonsMargin": 18,
                    "spacing": 15,
                    "buttonSize": 18
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "properties": {
                            "alpha-bg3e": "0",
                            "bg": "rgba(255, 255, 255, 1)",
                            "bg2": "#000000",
                            "bg2h": "#000000",
                            "bg3": "#F2F2F2",
                            "bg3f": "#FFFFFF",
                            "bg3h": "#FAFAFA",
                            "bgd": "#D4D4D4",
                            "bge": "#FF4040",
                            "bgf": "#FFFFFF",
                            "bgh": "#FFFFFF",
                            "boxShadowToggleOn-shd": "true",
                            "brd": "#888888",
                            "brdd": "#D4D4D4",
                            "brw": "0",
                            "brwf": "0",
                            "brwh": "0",
                            "fnt": "normal normal normal 12px/1.4em lulo-clean-w01-one-bold",
                            "fnt2": "normal normal normal 10px/1.4em lulo-clean-w01-one-bold",
                            "rd": "5px",
                            "shd": "0.00px 3.00px 9px 1px rgba(0,0,0,0.1)",
                            "txt": "#000000",
                            "txt2": "#000000",
                            "txtlblrq": "#000000"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.inputs.RadioGroup",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "skins.input.RadioGroupDefaultSkin"
                },
                "activeModes": {},
                "id": "comp-ivks1g9y"
            },
            "preset": {"rect": {"width": 187, "height": 130, "x": 0, "y": 259}, "tags": null}
        }, {
            "id": "User_Input_RadioGroup_6",
            "structure": {
                "type": "Component",
                "skin": "skins.input.RadioGroupDefaultSkin",
                "layout": {
                    "width": 107,
                    "height": 94,
                    "x": 207,
                    "y": 278,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.inputs.RadioGroup",
                "data": {
                    "type": "RadioGroup",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "value": "Radio button2",
                    "options": [{
                        "type": "RadioButton",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "value": "Radio button1",
                        "label": "Red"
                    }, {
                        "type": "RadioButton",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "value": "Radio button2",
                        "label": "Yellow"
                    }, {
                        "type": "RadioButton",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "value": "Radio button3",
                        "label": "Blue"
                    }],
                    "defaultValue": "Radio button2"
                },
                "props": {
                    "type": "RadioGroupProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "alignment": "left",
                    "layout": "vertical",
                    "buttonsMargin": 14,
                    "spacing": 15,
                    "buttonSize": 17
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "properties": {
                            "alpha-bg": "1",
                            "alpha-bg2h": "0",
                            "alpha-bg3e": "0",
                            "alpha-bg3h": "1",
                            "alpha-bgd": "0",
                            "alpha-bgf": "0",
                            "alpha-bgh": "1",
                            "bg": "rgba(255,255,255,1)",
                            "bg2": "#00A6FF",
                            "bg2d": "#919191",
                            "bg2h": "#00A6FF",
                            "bg3": "#00A6FF",
                            "bg3e": "#6EB7FD",
                            "bg3f": "#00A6FF",
                            "bg3h": "#00A6FF",
                            "bgd": "#FFFFFF",
                            "bge": "#FF4040",
                            "bgf": "#FFFFFF",
                            "bgh": "#DAEFFE",
                            "boxShadowToggleOn-shd": "false",
                            "brd": "#888888",
                            "brw": "1",
                            "brwd": "0",
                            "brwf": "1",
                            "brwh": "1",
                            "fnt": "normal normal normal 16px/1.4em helvetica-w01-light",
                            "fnt2": "normal normal normal 14px/1.4em helvetica-w01-light",
                            // "rd": "5px",
                            "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                            "txt": "#2B5672",
                            "txt2": "#00A6FF",
                            "txtlblrq": "#00A6FF"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.inputs.RadioGroup",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "skins.input.RadioGroupDefaultSkin"
                },
                "activeModes": {},
                "id": "comp-itegufv6"
            },
            "preset": {"rect": {"width": 137, "height": 130, "x": 186, "y": 259}, "tags": null}
        }],
        "compTypes": ["wysiwyg.viewer.components.inputs.RadioGroup"]
    },
    "help": {"hide": false, "text": "add_section_info_radiobuttons"}
}
