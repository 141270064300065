import type { Scope } from './scope';

export async function init(scope: Scope) {
  const { editorAPI, editorCoreAPI } = scope;

  //TODO use UserPrefs API when it is ready
  const userPrefsLoadedPromise = new Promise((resolve) =>
    editorAPI.registerInitUserPrefsCallback(resolve),
  );

  await editorCoreAPI.hooks.stageIsReadyAndVisible.promise;
  await userPrefsLoadedPromise;

  //TODO get rid of the prerequisiteForWelcomeScreen in order to use dirrect hooks to the related logic
  // the prerequisiteForWelcomeScreen list is filled in asynchronous way
  // that's why the __previewIsReady_TEMPORARY is needed
  // it is needed to be sure that all related vendors register their prerequisites before init of welcome screen
  await editorCoreAPI.hooks.__previewIsReady_TEMPORARY.promise;
  await Promise.all(editorAPI.prerequisiteForWelcomeScreen);

  editorAPI.initWelcomeScreen();
}
