'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')
const util = require('@/util')
const i18n = require('@/i18n')
const _ = require('lodash')
module.exports =
    {
        "type": addPanelDataConsts.SECTIONS_TYPES.PRESET,
        "hide": false,
        "title": "add_section_label_vertical_anchors_menu",
        "subNavigationTitle": "add_section_label_vertical_anchors_menu",
        "presetTitle": "add_section_label_vertical_anchors_menu",
        "tooltipTitle": "add_section_label_vertical_anchors_menu",
        "automationId": "add-panel-section-verticalAnchorsMenuSection",
        "subNavigationHide": false,
        "showSectionHeader": true,
        "additionalBehaviours": {
            "labelMode": addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
            "infoIcon": addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
            "hoverImageAction": addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
            "iconEnabledForComps": {}
        },
        "props": {
            "image": `addPanelData/sections/verticalAnchorsMenuSection_${util.languages.getLanguageCode()}/verticalAnchorsMenuSection_${util.languages.getLanguageCode()}.png`,
            "imageHover": null,
            "items": [{
                "id": "Menu_Vertical_Anchors_Menu_1",
                "structure": {
                    "type": "Component",
                    "skin": "wysiwyg.common.components.verticalanchorsmenu.viewer.skins.VerticalAnchorsMenuSymbolWithHiddenTextSkin",
                    "layout": {
                        "width": 116,
                        "height": 86,
                        "x": 4,
                        "y": 23,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": true,
                        "docked": _.defaults({right: {px: 16}}, util.pinModeUtils.dockMap.RIGHT)
                    },
                    "componentType": "wysiwyg.common.components.verticalanchorsmenu.viewer.VerticalAnchorsMenu",
                    "data": {
                        "type": "VerticalAnchorsMenu",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "hiddenAnchorIds": {"c1dmp": ["PAGE_TOP_ANCHOR"], "njd4e": ["PAGE_TOP_ANCHOR"]},
                        "pageTopLabel": i18n.translate('ANCHOR_MENU_MANAGER_TOP_LABEL'),
                        "pageBottomLabel": "Bottom of Page"
                    },
                    "props": {
                        "type": "VerticalAnchorsMenuProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "itemsAlignment": "right",
                        "orientation": "right",
                        "autoColor": true
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-symbolColor": "1",
                                "symbolColor": "#566FB8",
                                "symbolColorSelected": "#566FB8",
                                "textFont": "normal normal normal 15px/1.4em avenir-lt-w01_35-light1475496",
                                "textSpacing": "14px"
                            },
                            "propertiesSource": {
                                "alpha-symbolColor": "value",
                                "symbolColor": "value",
                                "symbolColorSelected": "value",
                                "textFont": "value",
                                "textSpacing": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.common.components.verticalanchorsmenu.viewer.VerticalAnchorsMenu",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.verticalanchorsmenu.viewer.skins.VerticalAnchorsMenuSymbolWithHiddenTextSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jbgimm2h"
                },
                "preset": {"rect": {"width": 170, "height": 127, "x": 0, "y": 0}, "tags": null}
            }, {
                "id": "Menu_Vertical_Anchors_Menu_2",
                "structure": {
                    "type": "Component",
                    "skin": "wysiwyg.common.components.verticalanchorsmenu.viewer.skins.VerticalAnchorsMenuSymbolWithHiddenTextSkin",
                    "layout": {
                        "width": 145,
                        "height": 90,
                        "x": 168,
                        "y": 21,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": true,
                        "docked": _.defaults({right: {px: 16}}, util.pinModeUtils.dockMap.RIGHT)
                    },
                    "componentType": "wysiwyg.common.components.verticalanchorsmenu.viewer.VerticalAnchorsMenu",
                    "data": {
                        "type": "VerticalAnchorsMenu",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "hiddenAnchorIds": {"c1dmp": ["PAGE_TOP_ANCHOR"], "njd4e": ["PAGE_TOP_ANCHOR"]},
                        "pageTopLabel": i18n.translate('ANCHOR_MENU_MANAGER_TOP_LABEL'),
                        "pageBottomLabel": "Bottom of Page"
                    },
                    "props": {
                        "type": "VerticalAnchorsMenuProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "itemsAlignment": "right",
                        "orientation": "right",
                        "autoColor": true
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-symbolColor": "1",
                                "symbolColor": "#666666",
                                "symbolColorSelected": "#666666",
                                "textFont": "normal normal normal 17px/1.4em playfair+display",
                                "textSpacing": "15"
                            },
                            "propertiesSource": {
                                "alpha-symbolColor": "value",
                                "symbolColor": "value",
                                "symbolColorSelected": "value",
                                "textFont": "value",
                                "textSpacing": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.common.components.verticalanchorsmenu.viewer.VerticalAnchorsMenu",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.verticalanchorsmenu.viewer.skins.VerticalAnchorsMenuSymbolWithHiddenTextSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jbgimm2q"
                },
                "preset": {"rect": {"width": 154, "height": 127, "x": 170, "y": 0}, "tags": null}
            }, {
                "id": "Menu_Vertical_Anchors_Menu_3",
                "structure": {
                    "type": "Component",
                    "skin": "wysiwyg.common.components.verticalanchorsmenu.viewer.skins.VerticalAnchorsMenuSymbolWithTextSkin",
                    "layout": {
                        "width": 116,
                        "height": 92,
                        "x": 4,
                        "y": 153,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": true,
                        "docked": _.defaults({right: {px: 16}}, util.pinModeUtils.dockMap.RIGHT)
                    },
                    "componentType": "wysiwyg.common.components.verticalanchorsmenu.viewer.VerticalAnchorsMenu",
                    "data": {
                        "type": "VerticalAnchorsMenu",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "hiddenAnchorIds": {"c1dmp": ["PAGE_TOP_ANCHOR"], "njd4e": ["PAGE_TOP_ANCHOR"]},
                        "pageTopLabel": i18n.translate('ANCHOR_MENU_MANAGER_TOP_LABEL'),
                        "pageBottomLabel": "Bottom of Page"
                    },
                    "props": {
                        "type": "VerticalAnchorsMenuProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "itemsAlignment": "right",
                        "orientation": "right",
                        "autoColor": true
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "textFont": "normal normal normal 16px/1.4em baskervillemtw01-smbdit",
                                "textSpacing": "14px"
                            },
                            "propertiesSource": {
                                "symbolColor": "value",
                                "symbolColorSelected": "value",
                                "textFont": "value",
                                "textSpacing": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.common.components.verticalanchorsmenu.viewer.VerticalAnchorsMenu",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.verticalanchorsmenu.viewer.skins.VerticalAnchorsMenuSymbolWithTextSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jbgimm2k"
                },
                "preset": {"rect": {"width": 170, "height": 119, "x": 0, "y": 127}, "tags": null}
            }, {
                "id": "Menu_Vertical_Anchors_Menu_4",
                "structure": {
                    "type": "Component",
                    "skin": "wysiwyg.common.components.verticalanchorsmenu.viewer.skins.VerticalAnchorsMenuTextSkin",
                    "layout": {
                        "width": 147,
                        "height": 92,
                        "x": 166,
                        "y": 153,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": true,
                        "docked": _.defaults({right: {px: 16}}, util.pinModeUtils.dockMap.RIGHT)
                    },
                    "componentType": "wysiwyg.common.components.verticalanchorsmenu.viewer.VerticalAnchorsMenu",
                    "data": {
                        "type": "VerticalAnchorsMenu",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "hiddenAnchorIds": {"c1dmp": ["PAGE_TOP_ANCHOR"], "njd4e": ["PAGE_TOP_ANCHOR"]},
                        "pageTopLabel": i18n.translate('ANCHOR_MENU_MANAGER_TOP_LABEL'),
                        "pageBottomLabel": "Bottom of Page"
                    },
                    "props": {
                        "type": "VerticalAnchorsMenuProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "itemsAlignment": "right",
                        "orientation": "right",
                        "autoColor": true
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "symbolColorSelected": "#CEC5B1",
                                "textFont": "normal normal normal 18px/1.4em impact"
                            },
                            "propertiesSource": {
                                "symbolColor": "value",
                                "symbolColorSelected": "value",
                                "textFont": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.common.components.verticalanchorsmenu.viewer.VerticalAnchorsMenu",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.verticalanchorsmenu.viewer.skins.VerticalAnchorsMenuTextSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jbgimm25"
                },
                "preset": {"rect": {"width": 154, "height": 118, "x": 170, "y": 127}, "tags": null}
            }, {
                "id": "Menu_Vertical_Anchors_Menu_5",
                "structure": {
                    "type": "Component",
                    "skin": "wysiwyg.common.components.verticalanchorsmenu.viewer.skins.VerticalAnchorsMenuSymbolSkin",
                    "layout": {
                        "width": 12,
                        "height": 82,
                        "x": 24,
                        "y": 290,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": true,
                        "docked": _.defaults({right: {px: 16}}, util.pinModeUtils.dockMap.RIGHT)
                    },
                    "componentType": "wysiwyg.common.components.verticalanchorsmenu.viewer.VerticalAnchorsMenu",
                    "data": {
                        "type": "VerticalAnchorsMenu",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "hiddenAnchorIds": {"c1dmp": ["PAGE_TOP_ANCHOR"], "njd4e": ["PAGE_TOP_ANCHOR"]},
                        "pageTopLabel": i18n.translate('ANCHOR_MENU_MANAGER_TOP_LABEL'),
                        "pageBottomLabel": "Bottom of Page"
                    },
                    "props": {
                        "type": "VerticalAnchorsMenuProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "itemsAlignment": "center",
                        "orientation": "right",
                        "autoColor": true
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-symbolColor": "1",
                                "alpha-symbolColorSelected": "1",
                                "symbolColor": "#8FEEDD",
                                "symbolColorSelected": "#8FEEDD"
                            },
                            "propertiesSource": {
                                "alpha-symbolColor": "value",
                                "alpha-symbolColorSelected": "value",
                                "symbolColor": "value",
                                "symbolColorSelected": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.common.components.verticalanchorsmenu.viewer.VerticalAnchorsMenu",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.verticalanchorsmenu.viewer.skins.VerticalAnchorsMenuSymbolSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jbgimm2f"
                },
                "preset": {"rect": {"width": 72, "height": 153, "x": 0, "y": 246}, "tags": null}
            }, {
                "id": "Menu_Vertical_Anchors_Menu_6",
                "structure": {
                    "type": "Component",
                    "skin": "wysiwyg.common.components.verticalanchorsmenu.viewer.skins.VerticalAnchorsMenuLinkedNoTextSkin",
                    "layout": {
                        "width": 32,
                        "height": 94,
                        "x": 105,
                        "y": 284,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": true,
                        "docked": _.defaults({right: {px: 16}}, util.pinModeUtils.dockMap.RIGHT)
                    },
                    "componentType": "wysiwyg.common.components.verticalanchorsmenu.viewer.VerticalAnchorsMenu",
                    "data": {
                        "type": "VerticalAnchorsMenu",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "hiddenAnchorIds": {"c1dmp": ["PAGE_TOP_ANCHOR"], "njd4e": ["PAGE_TOP_ANCHOR"]},
                        "pageTopLabel": i18n.translate('ANCHOR_MENU_MANAGER_TOP_LABEL'),
                        "pageBottomLabel": "Bottom of Page"
                    },
                    "props": {
                        "type": "VerticalAnchorsMenuProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "itemsAlignment": "right",
                        "orientation": "right",
                        "autoColor": true
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-symbolColor": "1",
                                "alpha-symbolColorSelected": "1",
                                "symbolColor": "#E43292",
                                "symbolColorSelected": "#E43292"
                            },
                            "propertiesSource": {
                                "alpha-symbolColor": "value",
                                "alpha-symbolColorSelected": "value",
                                "symbolColor": "value",
                                "symbolColorSelected": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.common.components.verticalanchorsmenu.viewer.VerticalAnchorsMenu",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.verticalanchorsmenu.viewer.skins.VerticalAnchorsMenuLinkedNoTextSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jbgimm2c"
                },
                "preset": {"rect": {"width": 98, "height": 153, "x": 72, "y": 246}, "tags": null}
            }, {
                "id": "Menu_Vertical_Anchors_Menu_7",
                "structure": {
                    "type": "Component",
                    "skin": "wysiwyg.common.components.verticalanchorsmenu.viewer.skins.VerticalAnchorsMenuSymbolSkin",
                    "layout": {
                        "width": 14,
                        "height": 80,
                        "x": 207,
                        "y": 290,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": true,
                        "docked": _.defaults({right: {px: 16}}, util.pinModeUtils.dockMap.RIGHT)
                    },
                    "componentType": "wysiwyg.common.components.verticalanchorsmenu.viewer.VerticalAnchorsMenu",
                    "data": {
                        "type": "VerticalAnchorsMenu",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "hiddenAnchorIds": {"c1dmp": ["PAGE_TOP_ANCHOR"], "njd4e": ["PAGE_TOP_ANCHOR"]},
                        "pageTopLabel": i18n.translate('ANCHOR_MENU_MANAGER_TOP_LABEL'),
                        "pageBottomLabel": "Bottom of Page"
                    },
                    "props": {
                        "type": "VerticalAnchorsMenuProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "itemsAlignment": "right",
                        "orientation": "right",
                        "autoColor": true
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-symbolColor": "1",
                                "alpha-symbolColorSelected": "1",
                                "symbolColor": "#20CE88",
                                "symbolColorSelected": "#20CE88"
                            },
                            "propertiesSource": {
                                "alpha-symbolColor": "value",
                                "alpha-symbolColorSelected": "value",
                                "symbolColor": "value",
                                "symbolColorSelected": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.common.components.verticalanchorsmenu.viewer.VerticalAnchorsMenu",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.verticalanchorsmenu.viewer.skins.VerticalAnchorsMenuSymbolSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jbgimm2b"
                },
                "preset": {"rect": {"width": 73, "height": 153, "x": 170, "y": 246}, "tags": null}
            }, {
                "id": "Menu_Vertical_Anchors_Menu_8",
                "structure": {
                    "type": "Component",
                    "skin": "wysiwyg.common.components.verticalanchorsmenu.viewer.skins.VerticalAnchorsMenuLinkedNoTextSkin",
                    "layout": {
                        "width": 26,
                        "height": 94,
                        "x": 291,
                        "y": 284,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": true,
                        "docked": _.defaults({right: {px: 16}}, util.pinModeUtils.dockMap.RIGHT)
                    },
                    "componentType": "wysiwyg.common.components.verticalanchorsmenu.viewer.VerticalAnchorsMenu",
                    "data": {
                        "type": "VerticalAnchorsMenu",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "hiddenAnchorIds": {"c1dmp": ["PAGE_TOP_ANCHOR"], "njd4e": ["PAGE_TOP_ANCHOR"]},
                        "pageTopLabel": i18n.translate('ANCHOR_MENU_MANAGER_TOP_LABEL'),
                        "pageBottomLabel": "Bottom of Page"
                    },
                    "props": {
                        "type": "VerticalAnchorsMenuProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "itemsAlignment": "right",
                        "orientation": "right",
                        "autoColor": true
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-symbolColor": "1",
                                "alpha-symbolColorSelected": "1",
                                "symbolColor": "#323232",
                                "symbolColorSelected": "#323232"
                            },
                            "propertiesSource": {
                                "alpha-symbolColor": "value",
                                "alpha-symbolColorSelected": "value",
                                "symbolColor": "value",
                                "symbolColorSelected": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.common.components.verticalanchorsmenu.viewer.VerticalAnchorsMenu",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.verticalanchorsmenu.viewer.skins.VerticalAnchorsMenuLinkedNoTextSkin"
                    },
                    "activeModes": {},
                    "id": "comp-jbgimm29"
                },
                "preset": {"rect": {"width": 81, "height": 153, "x": 243, "y": 246}, "tags": null}
            }],
            "compTypes": ["wysiwyg.common.components.verticalanchorsmenu.viewer.VerticalAnchorsMenu"]
        },
        "help": {"hide": false, "text": "add_section_info_text_vertical_anchors_menu"}
    }
