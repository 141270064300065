export const getTextComponentStructure = (text: string): any => ({
  type: 'Component',
  skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
  layout: {
    width: 580,
    height: 36,
    x: 250,
    y: 140,
    scale: 1,
    rotationInDegrees: 0,
    fixedPosition: false,
  },
  componentType: 'wysiwyg.viewer.components.WRichText',
  data: {
    type: 'StyledText',
    metaData: {
      isPreset: false,
      schemaVersion: '1.0',
      isHidden: false,
    },
    text: `<h2 class=\"font_2\"><span class=\"color_15\"><span style=\"font-weight:bold;\"><span style=\"font-size:30px;\">${text}</span></span></span></h2>`,
    stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
    linkList: [],
  },
  style: {
    type: 'TopLevelStyle',
    id: 'txtNew',
    metaData: {
      isPreset: false,
      schemaVersion: '1.0',
      isHidden: false,
    },
    style: {
      groups: {},
      properties: {
        f0: 'normal normal normal 45px/1.4em arial+black {color_14}',
        f1: 'normal normal normal 13px/1.4em arial+black {color_11}',
        f10: 'normal normal normal 10px/1.4em Arial {color_15}',
        f2: 'normal normal normal 26px/1.4em arial+black {color_14}',
        f3: 'normal normal normal 40px/1.4em arial+black {color_14}',
        f4: 'normal normal normal 30px/1.4em sacramento {color_14}',
        f5: 'normal normal normal 22px/1.4em Open+Sans {color_14}',
        f6: 'normal normal normal 19px/1.4em Open+Sans {color_14}',
        f7: 'normal normal normal 15px/1.4em helvetica-w01-light {color_15}',
        f8: 'normal normal normal 13px/1.4em helvetica-w01-light {color_15}',
        f9: 'normal normal normal 12px/1.4em Arial {color_15}',
      },
      propertiesSource: {
        f0: 'value',
        f1: 'value',
        f10: 'value',
        f2: 'value',
        f3: 'value',
        f4: 'value',
        f5: 'value',
        f6: 'value',
        f7: 'value',
        f8: 'value',
        f9: 'value',
      },
    },
    componentClassName: '',
    pageId: '',
    compId: '',
    styleType: 'system',
    skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
  },
  activeModes: {},
  id: 'comp-ixr9mw1d',
});
