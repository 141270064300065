import React from 'react';
import { symbol as Symbol } from '@wix/santa-editor-symbols';
import { panelUtils } from '@/util';

import { translate } from '@/i18n';

export const NoLink: React.FC = () => {
  const showNewUi = panelUtils.isLinkingOfNewLightboxEnabled();

  return (
    <>
      {!showNewUi && (
        <div className="symbol-container">
          <Symbol name="noLink" />
        </div>
      )}

      <h3>
        {translate(
          showNewUi
            ? 'LINK_PANEL_PAGE_Nothing_Linked_Title'
            : 'LINK_PANEL_PAGE_DROP_DOWN_Title',
        )}
      </h3>
      <p>
        {translate(
          showNewUi
            ? 'LINK_PANEL_PAGE_Nothing_Linked_Text'
            : 'LINK_PANEL_PAGE_DROP_DOWN_Text',
        )}
      </p>
    </>
  );
};
