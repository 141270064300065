'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')
const i18n = require('@/i18n')

module.exports = {
    "type": addPanelDataConsts.SECTIONS_TYPES.PRESET,
    "hide": false,
    "title": "add_section_label_strip_slide_show",
    "subNavigationTitle": "add_section_label_strip_slide_show",
    "presetTitle": "add_section_label_strip_slide_show",
    "tooltipTitle": "add_section_label_strip_slide_show",
    "automationId": "add-panel-section-stripBoxSlideShowSection",
    "subNavigationHide": false,
    "showSectionHeader": true,
    "additionalBehaviours": {
        "labelMode": addPanelDataConsts.LABEL_BEHAVIOUR.HOVER,
        "infoIcon": addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
        "hoverImageAction": addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
        "iconEnabledForComps": {}
    },
    "props": {
        "image": "addPanelData/sections/stripBoxSlideShowSection_en/stripBoxSlideShowSection_en.png",
        "imageHover": null,
        "items": [{
            "id": "BoxSlideShow_StripBoxSlideShow_1",
            "structure": {
                "type": "Container",
                "skin": "wysiwyg.common.components.stripSlideShow.viewer.skins.squareButtonsSkin",
                "layout": {
                    "width": 980,
                    "height": 650,
                    "x": 0,
                    "y": 101,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.StripContainerSlideShow",
                "components": [{
                    "type": "Container",
                    "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                    "layout": {
                        "width": 1421,
                        "height": 650,
                        "x": 0,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                    "components": [{
                        "type": "Component",
                        "layout": {
                            "width": 30,
                            "height": 36,
                            "x": 474,
                            "y": 66,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WPhoto",
                        "data": {
                            "type": "Image",
                            "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                            "title": "",
                            "uri": "035244_1b89462c8494451aaa6605b579e66caa.png",
                            "description": "",
                            "width": 44,
                            "height": 53,
                            "alt": ""
                        },
                        "props": {
                            "type": "WPhotoProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "displayMode": "fill",
                            "onClickBehavior": "goToLink",
                            "effectName": "none"
                        },
                        "style": "wp2",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 30,
                                "height": 35,
                                "x": 145,
                                "y": 25,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WPhoto",
                            "data": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                                "title": "",
                                "uri": "035244_1b89462c8494451aaa6605b579e66caa.png",
                                "description": "",
                                "width": 44,
                                "height": 53,
                                "alt": ""
                            },
                            "props": {
                                "type": "WPhotoProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "displayMode": "fill",
                                "onClickBehavior": "goToLink",
                                "effectName": "none"
                            },
                            "style": "wp2"
                        }
                    }, {
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.line.SolidLine",
                        "layout": {
                            "width": 32,
                            "height": 5,
                            "x": 471,
                            "y": 114,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.FiveGridLine",
                        "props": {
                            "type": "FiveGridLineProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "fullScreenModeOn": false
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {"alpha-brd": "1", "brd": "#FFFFFF", "lnw": "1px"},
                                "propertiesSource": {"brd": "value", "lnw": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.line.SolidLine"
                        },
                        "mobileStructure": {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.line.SolidLine",
                            "layout": {
                                "width": 32,
                                "height": 5,
                                "x": 144,
                                "y": 73,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.FiveGridLine",
                            "props": {
                                "type": "FiveGridLineProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "fullScreenModeOn": false
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {"alpha-brd": "1", "brd": "#FFFFFF", "lnw": "1px"},
                                    "propertiesSource": {"brd": "value", "lnw": "value"}
                                },
                                "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.line.SolidLine"
                            }
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 860,
                            "height": 111,
                            "x": 59,
                            "y": 248,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<h2 style=\"text-align: center; font-size: 26px; line-height: 1.3em;\" class=\"font_2\"><span style=\"font-size:26px;\"><span style=\"line-height:1.3em;\"><span style=\"letter-spacing:0.05em;\"><span style=\"font-family:didot-w01-italic,serif;\"><span style=\"font-style:italic;\"><span class=\"color_11\">We Are</span></span></span></span></span></span></h2><h2 style=\"text-align: center; font-size: 60px;\" class=\"font_2\"><span style=\"font-size:60px;\"><span style=\"letter-spacing:0.06em;\"><span class=\"color_11\"><span style=\"font-family:raleway,sans-serif;\">MOVERS &amp; SHAKERS</span></span></span></span></h2>",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 210,
                                "height": 130,
                                "x": 55,
                                "y": 109,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 style=\"text-align: center; font-size: 26px; line-height: 1.3em;\" class=\"font_2\"><span style=\"font-size:26px;\"><span style=\"line-height:1.3em;\"><span style=\"letter-spacing:0.05em;\"><span style=\"font-family:didot-w01-italic,serif;\"><span style=\"font-style:italic;\"><span class=\"color_11\">We Are</span></span></span></span></span></span></h2><h2 style=\"text-align: center; font-size: 60px;\" class=\"font_2\"><span style=\"font-size:60px;\"><span style=\"letter-spacing:0.06em;\"><span class=\"color_11\"><span style=\"font-family:raleway,sans-serif;\">MOVERS &amp; SHAKERS</span></span></span></span></h2>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }],
                    "data": {
                        "type": "StripContainerSlideShowSlide",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "1"})
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {"bg": "#B6E8E3", "brd": "color_19", "brw": "0", "rd": "0px"},
                            "propertiesSource": {"bg": "value"}
                        },
                        "componentClassName": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "mediaRef": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "title": "shutterstock_124874422 copy.jpg",
                                "uri": "035244_04cbb98f178e46efb847ad15f5ca060b.jpg",
                                "description": "private/allMedia_picture",
                                "width": 7100,
                                "height": 4500,
                                "alt": "",
                                "artist": {"id": "", "name": ""}
                            },
                            "color": "#20303C",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "#8B143C",
                            "colorOverlayOpacity": 0.15
                        }
                    },
                    "mobileStructure": {
                        "type": "Container",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                        "layout": {
                            "width": 320,
                            "height": 341,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                        "data": {
                            "type": "StripContainerSlideShowSlide",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "1"})
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {"bg": "#B6E8E3", "brd": "color_19", "brw": "0", "rd": "0px"},
                                "propertiesSource": {"bg": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "mediaRef": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "title": "shutterstock_124874422 copy.jpg",
                                    "uri": "035244_04cbb98f178e46efb847ad15f5ca060b.jpg",
                                    "description": "private/allMedia_picture",
                                    "width": 7100,
                                    "height": 4500,
                                    "alt": "",
                                    "artist": {"id": "", "name": ""}
                                },
                                "color": "#20303C",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "#8B143C",
                                "colorOverlayOpacity": 0.15
                            }
                        }
                    }
                }, {
                    "type": "Container",
                    "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                    "layout": {
                        "width": 1421,
                        "height": 650,
                        "x": 0,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                    "components": [{
                        "type": "Component",
                        "layout": {
                            "width": 30,
                            "height": 37,
                            "x": 474,
                            "y": 66,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WPhoto",
                        "data": {
                            "type": "Image",
                            "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                            "title": "",
                            "uri": "035244_1b89462c8494451aaa6605b579e66caa.png",
                            "description": "",
                            "width": 44,
                            "height": 53,
                            "alt": ""
                        },
                        "props": {
                            "type": "WPhotoProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "displayMode": "fill",
                            "onClickBehavior": "goToLink",
                            "effectName": "none"
                        },
                        "style": "wp2",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 30,
                                "height": 37,
                                "x": 147,
                                "y": 22,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WPhoto",
                            "data": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                                "title": "",
                                "uri": "035244_1b89462c8494451aaa6605b579e66caa.png",
                                "description": "",
                                "width": 44,
                                "height": 53,
                                "alt": ""
                            },
                            "props": {
                                "type": "WPhotoProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "displayMode": "fill",
                                "onClickBehavior": "goToLink",
                                "effectName": "none"
                            },
                            "style": "wp2"
                        }
                    }, {
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.line.SolidLine",
                        "layout": {
                            "width": 32,
                            "height": 5,
                            "x": 471,
                            "y": 115,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.FiveGridLine",
                        "props": {
                            "type": "FiveGridLineProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "fullScreenModeOn": false
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {"alpha-brd": "1", "brd": "#FFFFFF", "lnw": "1px"},
                                "propertiesSource": {"brd": "value", "lnw": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.line.SolidLine"
                        },
                        "mobileStructure": {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.line.SolidLine",
                            "layout": {
                                "width": 32,
                                "height": 5,
                                "x": 145,
                                "y": 73,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.FiveGridLine",
                            "props": {
                                "type": "FiveGridLineProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "fullScreenModeOn": false
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {"alpha-brd": "1", "brd": "#FFFFFF", "lnw": "1px"},
                                    "propertiesSource": {"brd": "value", "lnw": "value"}
                                },
                                "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.line.SolidLine"
                            }
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 859,
                            "height": 111,
                            "x": 59,
                            "y": 248,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<h2 style=\"text-align: center; font-size: 26px; line-height: 1.3em;\" class=\"font_2\"><span style=\"font-size:26px;\"><span style=\"line-height:1.3em;\"><span style=\"letter-spacing:0.05em;\"><span style=\"font-family:didot-w01-italic,serif;\"><span style=\"font-style:italic;\"><span class=\"color_11\">Share</span></span></span></span></span></span></h2><h2 style=\"text-align: center; font-size: 60px;\" class=\"font_2\"><span style=\"font-size:60px;\"><span style=\"letter-spacing:0.06em;\"><span class=\"color_11\"><span style=\"font-family:raleway,sans-serif;\">YOUR PASSION</span></span></span></span></h2>",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 201,
                                "height": 130,
                                "x": 59,
                                "y": 109,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 style=\"text-align: center; font-size: 26px; line-height: 1.3em;\" class=\"font_2\"><span style=\"font-size:26px;\"><span style=\"line-height:1.3em;\"><span style=\"letter-spacing:0.05em;\"><span style=\"font-family:didot-w01-italic,serif;\"><span style=\"font-style:italic;\"><span class=\"color_11\">Share</span></span></span></span></span></span></h2><h2 style=\"text-align: center; font-size: 60px;\" class=\"font_2\"><span style=\"font-size:60px;\"><span style=\"letter-spacing:0.06em;\"><span class=\"color_11\"><span style=\"font-family:raleway,sans-serif;\">YOUR PASSION</span></span></span></span></h2>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }],
                    "data": {
                        "type": "StripContainerSlideShowSlide",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "2"})
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {"bg": "#B6E8E3", "brd": "color_19", "brw": "0", "rd": "0px"},
                            "propertiesSource": {"bg": "value"}
                        },
                        "componentClassName": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "mediaRef": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "title": "LJIZlzHgQ7WPSh5KVTCB_Typewriter copy.jpg",
                                "uri": "035244_c4d5c44ed9eb437ebbdf27c76fb9b68f.jpg",
                                "description": "private/allMedia_picture",
                                "width": 7561,
                                "height": 4259,
                                "alt": "",
                                "artist": {"id": "", "name": ""}
                            },
                            "color": "#A5C8DE",
                            "colorOpacity": 1,
                            "alignType": "bottom",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "{color_15}",
                            "colorOverlayOpacity": 0.21
                        }
                    },
                    "mobileStructure": {
                        "type": "Container",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                        "layout": {
                            "width": 320,
                            "height": 341,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                        "data": {
                            "type": "StripContainerSlideShowSlide",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "2"})
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {"bg": "#B6E8E3", "brd": "color_19", "brw": "0", "rd": "0px"},
                                "propertiesSource": {"bg": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "mediaRef": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "title": "LJIZlzHgQ7WPSh5KVTCB_Typewriter copy.jpg",
                                    "uri": "035244_c4d5c44ed9eb437ebbdf27c76fb9b68f.jpg",
                                    "description": "private/allMedia_picture",
                                    "width": 7561,
                                    "height": 4259,
                                    "alt": "",
                                    "artist": {"id": "", "name": ""}
                                },
                                "color": "#A5C8DE",
                                "colorOpacity": 1,
                                "alignType": "bottom",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "{color_15}",
                                "colorOverlayOpacity": 0.21
                            }
                        }
                    }
                }, {
                    "type": "Container",
                    "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                    "layout": {
                        "width": 1421,
                        "height": 650,
                        "x": 0,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                    "components": [{
                        "type": "Component",
                        "layout": {
                            "width": 859,
                            "height": 111,
                            "x": 59,
                            "y": 248,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<h2 style=\"text-align: center; font-size: 26px; line-height: 1.3em;\" class=\"font_2\"><span style=\"font-size:26px;\"><span style=\"line-height:1.3em;\"><span style=\"letter-spacing:0.05em;\"><span style=\"font-family:didot-w01-italic,serif;\"><span style=\"font-style:italic;\"><span class=\"color_11\">Make</span></span></span></span></span></span></h2><h2 style=\"text-align: center; font-size: 60px;\" class=\"font_2\"><span style=\"font-size:60px;\"><span style=\"letter-spacing:0.06em;\"><span class=\"color_11\"><span style=\"font-family:raleway,sans-serif;\">IT HAPPEN</span></span></span></span></h2>",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 213,
                                "height": 82,
                                "x": 55,
                                "y": 110,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 style=\"text-align: center; font-size: 26px; line-height: 1.3em;\" class=\"font_2\"><span style=\"font-size:26px;\"><span style=\"line-height:1.3em;\"><span style=\"letter-spacing:0.05em;\"><span style=\"font-family:didot-w01-italic,serif;\"><span style=\"font-style:italic;\"><span class=\"color_11\">Make</span></span></span></span></span></span></h2><h2 style=\"text-align: center; font-size: 60px;\" class=\"font_2\"><span style=\"font-size:60px;\"><span style=\"letter-spacing:0.06em;\"><span class=\"color_11\"><span style=\"font-family:raleway,sans-serif;\">IT HAPPEN</span></span></span></span></h2>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 30,
                            "height": 37,
                            "x": 474,
                            "y": 66,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WPhoto",
                        "data": {
                            "type": "Image",
                            "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                            "title": "",
                            "uri": "035244_1b89462c8494451aaa6605b579e66caa.png",
                            "description": "",
                            "width": 44,
                            "height": 53,
                            "alt": ""
                        },
                        "props": {
                            "type": "WPhotoProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "displayMode": "fill",
                            "onClickBehavior": "goToLink",
                            "effectName": "none"
                        },
                        "style": "wp2",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 30,
                                "height": 37,
                                "x": 145,
                                "y": 22,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WPhoto",
                            "data": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                                "title": "",
                                "uri": "035244_1b89462c8494451aaa6605b579e66caa.png",
                                "description": "",
                                "width": 44,
                                "height": 53,
                                "alt": ""
                            },
                            "props": {
                                "type": "WPhotoProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "displayMode": "fill",
                                "onClickBehavior": "goToLink",
                                "effectName": "none"
                            },
                            "style": "wp2"
                        }
                    }, {
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.line.SolidLine",
                        "layout": {
                            "width": 32,
                            "height": 5,
                            "x": 471,
                            "y": 115,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.FiveGridLine",
                        "props": {
                            "type": "FiveGridLineProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "fullScreenModeOn": false
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {"alpha-brd": "1", "brd": "#FFFFFF", "lnw": "1px"},
                                "propertiesSource": {"brd": "value", "lnw": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.line.SolidLine"
                        },
                        "mobileStructure": {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.line.SolidLine",
                            "layout": {
                                "width": 32,
                                "height": 5,
                                "x": 145,
                                "y": 73,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.FiveGridLine",
                            "props": {
                                "type": "FiveGridLineProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "fullScreenModeOn": false
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {"alpha-brd": "1", "brd": "#FFFFFF", "lnw": "1px"},
                                    "propertiesSource": {"brd": "value", "lnw": "value"}
                                },
                                "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.line.SolidLine"
                            }
                        }
                    }],
                    "data": {
                        "type": "StripContainerSlideShowSlide",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "3"})
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {"bg": "#B6E8E3", "brd": "color_19", "brw": "0", "rd": "0px"},
                            "propertiesSource": {"bg": "value"}
                        },
                        "componentClassName": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "mediaRef": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "title": "iStock_000070946519_Double copy.jpg",
                                "uri": "035244_7c26cb12ff3145359cd03740d9a8f706.jpg",
                                "description": "private/allMedia_picture",
                                "width": 7000,
                                "height": 4552,
                                "alt": "",
                                "artist": {"id": "", "name": ""}
                            },
                            "color": "#20303C",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "{color_15}",
                            "colorOverlayOpacity": 0.21
                        }
                    },
                    "mobileStructure": {
                        "type": "Container",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                        "layout": {
                            "width": 320,
                            "height": 341,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                        "data": {
                            "type": "StripContainerSlideShowSlide",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "3"})
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {"bg": "#B6E8E3", "brd": "color_19", "brw": "0", "rd": "0px"},
                                "propertiesSource": {"bg": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "mediaRef": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "title": "iStock_000070946519_Double copy.jpg",
                                    "uri": "035244_7c26cb12ff3145359cd03740d9a8f706.jpg",
                                    "description": "private/allMedia_picture",
                                    "width": 7000,
                                    "height": 4552,
                                    "alt": "",
                                    "artist": {"id": "", "name": ""}
                                },
                                "color": "#20303C",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "{color_15}",
                                "colorOverlayOpacity": 0.21
                            }
                        }
                    }
                }],
                "props": {
                    "type": "StripContainerSlideShowProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "transition": "SlideHorizontal",
                    "autoPlayInterval": 3,
                    "autoPlay": true,
                    "transDuration": 1,
                    "pauseAutoPlayOnMouseOver": true,
                    "direction": "RTL",
                    "shouldHideOverflowContent": true,
                    "flexibleBoxHeight": false,
                    "showNavigationButton": true,
                    "showNavigationDots": true,
                    "navigationButtonSize": 25,
                    "navigationButtonMargin": 100,
                    "navigationDotsSize": 6,
                    "navigationDotsMargin": 50,
                    "navigationDotsGap": 12,
                    "navigationDotsAlignment": "center"
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-arrowColor": "1",
                            "alpha-dotsColor": "1",
                            "alpha-dotsSelectedColor": "0.3"
                        },
                        "propertiesSource": {
                            "alpha-arrowColor": "value",
                            "alpha-dotsColor": "value",
                            "alpha-dotsSelectedColor": "value",
                            "arrowColor": "value",
                            "dotsColor": "value",
                            "dotsSelectedColor": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.StripContainerSlideShow",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.common.components.stripSlideShow.viewer.skins.thinArrowsSkin"
                },
                "mobileStructure": {
                    "type": "Container",
                    "skin": "wysiwyg.common.components.stripSlideShow.viewer.skins.thinArrowsLargeSelectedCircleSkin",
                    "layout": {
                        "width": 320,
                        "height": 341,
                        "x": 0,
                        "y": 32,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.StripContainerSlideShow",
                    "props": {
                        "type": "StripContainerSlideShowProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "transition": "SlideHorizontal",
                        "autoPlayInterval": 3,
                        "autoPlay": true,
                        "transDuration": 1,
                        "pauseAutoPlayOnMouseOver": true,
                        "direction": "RTL",
                        "shouldHideOverflowContent": true,
                        "flexibleBoxHeight": false,
                        "showNavigationButton": true,
                        "showNavigationDots": true,
                        "navigationButtonSize": 15,
                        "navigationButtonMargin": 22,
                        "navigationDotsSize": 6,
                        "navigationDotsMargin": 24,
                        "navigationDotsGap": 15,
                        "navigationDotsAlignment": "center"
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-arrowColor": "1",
                                "alpha-dotsColor": "1",
                                "alpha-dotsSelectedColor": "0.3"
                            },
                            "propertiesSource": {
                                "alpha-arrowColor": "value",
                                "alpha-dotsColor": "value",
                                "alpha-dotsSelectedColor": "value",
                                "arrowColor": "value",
                                "dotsColor": "value",
                                "dotsSelectedColor": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.viewer.components.StripContainerSlideShow",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.stripSlideShow.viewer.skins.thinArrowsSkin"
                    }
                },
                "id": "comp-inzv7xsr"
            },
            "preset": {
                "rect": {"width": 324, "height": 170.20000000000002, "x": -0.8, "y": 0},
                "label": "add_preset_StripSlideShow_1",
                "tags": null
            }
        }, {
            "id": "BoxSlideShow_StripBoxSlideShow_2",
            "structure": {
                "type": "Container",
                "skin": "wysiwyg.common.components.stripSlideShow.viewer.skins.squareButtonsSkin",
                "layout": {
                    "width": 980,
                    "height": 651,
                    "x": 0,
                    "y": 951,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.StripContainerSlideShow",
                "components": [{
                    "type": "Container",
                    "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                    "layout": {
                        "width": 1421,
                        "height": 651,
                        "x": 0,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                    "components": [{
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.button.BasicButton",
                        "layout": {
                            "width": 180,
                            "height": 44,
                            "x": 400,
                            "y": 366,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.SiteButton",
                        "data": {
                            "type": "LinkableButton",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "label": "View Collection"
                        },
                        "props": {
                            "type": "ButtonProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "align": "center",
                            "margin": 0
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "alpha-bg": "0",
                                    "alpha-bgh": "1",
                                    "alpha-brd": "1",
                                    "alpha-brdh": "1",
                                    "alpha-txt": "1",
                                    "alpha-txth": "1",
                                    "bg": "#FFFFFF",
                                    "bgh": "#FFFFFF",
                                    "boxShadowToggleOn-shd": "false",
                                    "brd": "#FFFFFF",
                                    "brdh": "#FFFFFF",
                                    "brw": "1",
                                    "fnt": "normal normal normal 16px/1.4em din-next-w01-light",
                                    "rd": "0px",
                                    "shd": "0 1px 4px rgba(0, 0, 0, 0.6);",
                                    "txt": "#FFFFFF",
                                    "txth": "#000000"
                                },
                                "propertiesSource": {
                                    "alpha-bg": "value",
                                    "bg": "value",
                                    "bgh": "value",
                                    "brd": "value",
                                    "brdh": "value",
                                    "brw": "value",
                                    "fnt": "value",
                                    "rd": "value",
                                    "shd": "value",
                                    "txt": "value",
                                    "txth": "value"
                                }
                            },
                            "componentClassName": "wysiwyg.viewer.components.SiteButton",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.button.BasicButton"
                        },
                        "mobileStructure": {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.button.BasicButton",
                            "layout": {
                                "width": 160,
                                "height": 40,
                                "x": 80,
                                "y": 204,
                                "scale": 0.9523809523809523,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.SiteButton",
                            "data": {
                                "type": "LinkableButton",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "label": "View Collection"
                            },
                            "props": {
                                "type": "ButtonProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "align": "center",
                                "margin": 0
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {
                                        "alpha-bg": "0",
                                        "alpha-bgh": "1",
                                        "alpha-brd": "1",
                                        "alpha-brdh": "1",
                                        "alpha-txt": "1",
                                        "alpha-txth": "1",
                                        "bg": "#FFFFFF",
                                        "bgh": "#FFFFFF",
                                        "boxShadowToggleOn-shd": "false",
                                        "brd": "#FFFFFF",
                                        "brdh": "#FFFFFF",
                                        "brw": "1",
                                        "fnt": "normal normal normal 16px/1.4em din-next-w01-light",
                                        "rd": "0px",
                                        "shd": "0 1px 4px rgba(0, 0, 0, 0.6);",
                                        "txt": "#FFFFFF",
                                        "txth": "#000000"
                                    },
                                    "propertiesSource": {
                                        "alpha-bg": "value",
                                        "bg": "value",
                                        "bgh": "value",
                                        "brd": "value",
                                        "brdh": "value",
                                        "brw": "value",
                                        "fnt": "value",
                                        "rd": "value",
                                        "shd": "value",
                                        "txt": "value",
                                        "txth": "value"
                                    }
                                },
                                "componentClassName": "wysiwyg.viewer.components.SiteButton",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.button.BasicButton"
                            }
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 899,
                            "height": 120,
                            "x": 40,
                            "y": 240,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<h2 style=\"font-size: 100px; text-align: center;\" class=\"font_2\"><span style=\"font-size:100px;\"><span style=\"letter-spacing:0.1em;\"><span style=\"font-family:din-next-w01-light,din-next-w02-light,din-next-w10-light,sans-serif;\"><span class=\"color_11\">FALL 2023</span></span></span></span></h2>",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 278,
                                "height": 42,
                                "x": 19,
                                "y": 66,
                                "scale": 0.7,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 style=\"font-size: 100px; text-align: center;\" class=\"font_2\"><span style=\"font-size:100px;\"><span style=\"letter-spacing:0.1em;\"><span style=\"font-family:din-next-w01-light,din-next-w02-light,din-next-w10-light,sans-serif;\"><span class=\"color_11\">FALL 2023</span></span></span></span></h2>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }],
                    "data": {
                        "type": "StripContainerSlideShowSlide",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "1"})
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {"bg": "#B6E8E3", "brd": "color_19", "brw": "0", "rd": "0px"},
                            "propertiesSource": {"bg": "value"}
                        },
                        "componentClassName": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "mediaRef": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "title": "FT6WKWLT96.jpg",
                                "uri": "035244_f5557ef74a8f4067bf97b464c5b37670.jpg",
                                "description": "private/allMedia_picture",
                                "width": 6000,
                                "height": 4000,
                                "alt": "",
                                "artist": {"id": "", "name": ""},
                                "opacity": 0.9
                            },
                            "color": "#605E5E",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "{color_15}",
                            "colorOverlayOpacity": 0.25
                        }
                    },
                    "mobileStructure": {
                        "type": "Container",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                        "layout": {
                            "width": 320,
                            "height": 300,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                        "data": {
                            "type": "StripContainerSlideShowSlide",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "1"})
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {"bg": "#B6E8E3", "brd": "color_19", "brw": "0", "rd": "0px"},
                                "propertiesSource": {"bg": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "mediaRef": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "title": "FT6WKWLT96.jpg",
                                    "uri": "035244_f5557ef74a8f4067bf97b464c5b37670.jpg",
                                    "description": "private/allMedia_picture",
                                    "width": 6000,
                                    "height": 4000,
                                    "alt": "",
                                    "artist": {"id": "", "name": ""},
                                    "opacity": 0.9
                                },
                                "color": "#605E5E",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "{color_15}",
                                "colorOverlayOpacity": 0.25
                            }
                        }
                    }
                }, {
                    "type": "Container",
                    "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                    "layout": {
                        "width": 1421,
                        "height": 651,
                        "x": 0,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                    "components": [{
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.button.BasicButton",
                        "layout": {
                            "width": 180,
                            "height": 44,
                            "x": 400,
                            "y": 367,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.SiteButton",
                        "data": {
                            "type": "LinkableButton",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "label": "View Collection"
                        },
                        "props": {
                            "type": "ButtonProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "align": "center",
                            "margin": 0
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "alpha-bg": "0",
                                    "alpha-bgh": "1",
                                    "alpha-brd": "1",
                                    "alpha-brdh": "1",
                                    "alpha-txt": "1",
                                    "alpha-txth": "1",
                                    "bg": "#FFFFFF",
                                    "bgh": "#FFFFFF",
                                    "boxShadowToggleOn-shd": "false",
                                    "brd": "#FFFFFF",
                                    "brdh": "#FFFFFF",
                                    "brw": "1",
                                    "fnt": "normal normal normal 16px/1.4em din-next-w01-light",
                                    "rd": "0px",
                                    "shd": "0 1px 4px rgba(0, 0, 0, 0.6);",
                                    "txt": "#FFFFFF",
                                    "txth": "#000000"
                                },
                                "propertiesSource": {
                                    "alpha-bg": "value",
                                    "bg": "value",
                                    "bgh": "value",
                                    "brd": "value",
                                    "brdh": "value",
                                    "brw": "value",
                                    "fnt": "value",
                                    "rd": "value",
                                    "shd": "value",
                                    "txt": "value",
                                    "txth": "value"
                                }
                            },
                            "componentClassName": "wysiwyg.viewer.components.SiteButton",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.button.BasicButton"
                        },
                        "mobileStructure": {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.button.BasicButton",
                            "layout": {
                                "width": 160,
                                "height": 40,
                                "x": 80,
                                "y": 204,
                                "scale": 0.9523809523809523,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.SiteButton",
                            "data": {
                                "type": "LinkableButton",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "label": "View Collection"
                            },
                            "props": {
                                "type": "ButtonProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "align": "center",
                                "margin": 0
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {
                                        "alpha-bg": "0",
                                        "alpha-bgh": "1",
                                        "alpha-brd": "1",
                                        "alpha-brdh": "1",
                                        "alpha-txt": "1",
                                        "alpha-txth": "1",
                                        "bg": "#FFFFFF",
                                        "bgh": "#FFFFFF",
                                        "boxShadowToggleOn-shd": "false",
                                        "brd": "#FFFFFF",
                                        "brdh": "#FFFFFF",
                                        "brw": "1",
                                        "fnt": "normal normal normal 16px/1.4em din-next-w01-light",
                                        "rd": "0px",
                                        "shd": "0 1px 4px rgba(0, 0, 0, 0.6);",
                                        "txt": "#FFFFFF",
                                        "txth": "#000000"
                                    },
                                    "propertiesSource": {
                                        "alpha-bg": "value",
                                        "bg": "value",
                                        "bgh": "value",
                                        "brd": "value",
                                        "brdh": "value",
                                        "brw": "value",
                                        "fnt": "value",
                                        "rd": "value",
                                        "shd": "value",
                                        "txt": "value",
                                        "txth": "value"
                                    }
                                },
                                "componentClassName": "wysiwyg.viewer.components.SiteButton",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.button.BasicButton"
                            }
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 899,
                            "height": 120,
                            "x": 40,
                            "y": 240,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<h2 style=\"font-size: 100px; text-align: center;\" class=\"font_2\"><span style=\"font-size:100px;\"><span style=\"letter-spacing:0.1em;\"><span style=\"font-family:din-next-w01-light,din-next-w02-light,din-next-w10-light,sans-serif;\"><span class=\"color_11\">TEXT GOES HERE</span></span></span></span></h2>",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 279,
                                "height": 84,
                                "x": 20,
                                "y": 66,
                                "scale": 0.7,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 style=\"font-size: 100px; text-align: center;\" class=\"font_2\"><span style=\"font-size:100px;\"><span style=\"letter-spacing:0.1em;\"><span style=\"font-family:din-next-w01-light,din-next-w02-light,din-next-w10-light,sans-serif;\"><span class=\"color_11\">TEXT GOES HERE</span></span></span></span></h2>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }],
                    "data": {
                        "type": "StripContainerSlideShowSlide",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "2"})
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {"bg": "#B6E8E3", "brd": "color_19", "brw": "0", "rd": "0px"},
                            "propertiesSource": {"bg": "value"}
                        },
                        "componentClassName": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "mediaRef": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "title": "Camden Guy",
                                "uri": "03d4c33055d2426b80097648eb68fd91.jpg",
                                "description": "public/491df47b-5958-4dc7-9029-37f428755d45/08616385-e843-431b-bd64-b8b845ff47bb",
                                "width": 7360,
                                "height": 4912,
                                "alt": "",
                                "artist": {"id": "", "name": ""},
                                "opacity": 0.6
                            },
                            "color": "#605E5E",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "{color_15}",
                            "colorOverlayOpacity": 0.21
                        }
                    },
                    "mobileStructure": {
                        "type": "Container",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                        "layout": {
                            "width": 320,
                            "height": 300,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                        "data": {
                            "type": "StripContainerSlideShowSlide",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "2"})
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {"bg": "#B6E8E3", "brd": "color_19", "brw": "0", "rd": "0px"},
                                "propertiesSource": {"bg": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "mediaRef": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "title": "Camden Guy",
                                    "uri": "03d4c33055d2426b80097648eb68fd91.jpg",
                                    "description": "public/491df47b-5958-4dc7-9029-37f428755d45/08616385-e843-431b-bd64-b8b845ff47bb",
                                    "width": 7360,
                                    "height": 4912,
                                    "alt": "",
                                    "artist": {"id": "", "name": ""},
                                    "opacity": 0.6
                                },
                                "color": "#605E5E",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "{color_15}",
                                "colorOverlayOpacity": 0.21
                            }
                        }
                    }
                }, {
                    "type": "Container",
                    "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                    "layout": {
                        "width": 1421,
                        "height": 651,
                        "x": 0,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                    "components": [{
                        "type": "Component",
                        "layout": {
                            "width": 899,
                            "height": 120,
                            "x": 40,
                            "y": 240,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<h2 style=\"font-size: 100px; text-align: center;\" class=\"font_2\"><span style=\"font-size:100px;\"><span style=\"letter-spacing:0.1em;\"><span style=\"font-family:din-next-w01-light,din-next-w02-light,din-next-w10-light,sans-serif;\"><span class=\"color_11\">TEXT GOES HERE</span></span></span></span></h2>",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 280,
                                "height": 84,
                                "x": 20,
                                "y": 66,
                                "scale": 0.7,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 style=\"font-size: 100px; text-align: center;\" class=\"font_2\"><span style=\"font-size:100px;\"><span style=\"letter-spacing:0.1em;\"><span style=\"font-family:din-next-w01-light,din-next-w02-light,din-next-w10-light,sans-serif;\"><span class=\"color_11\">TEXT GOES HERE</span></span></span></span></h2>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.button.BasicButton",
                        "layout": {
                            "width": 180,
                            "height": 44,
                            "x": 400,
                            "y": 366,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.SiteButton",
                        "data": {
                            "type": "LinkableButton",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "label": "View Collection"
                        },
                        "props": {
                            "type": "ButtonProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "align": "center",
                            "margin": 0
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "alpha-bg": "0",
                                    "alpha-bgh": "1",
                                    "alpha-brd": "1",
                                    "alpha-brdh": "1",
                                    "alpha-txt": "1",
                                    "alpha-txth": "1",
                                    "bg": "#FFFFFF",
                                    "bgh": "#FFFFFF",
                                    "boxShadowToggleOn-shd": "false",
                                    "brd": "#FFFFFF",
                                    "brdh": "#FFFFFF",
                                    "brw": "1",
                                    "fnt": "normal normal normal 16px/1.4em din-next-w01-light",
                                    "rd": "0px",
                                    "shd": "0 1px 4px rgba(0, 0, 0, 0.6);",
                                    "txt": "#FFFFFF",
                                    "txth": "#000000"
                                },
                                "propertiesSource": {
                                    "alpha-bg": "value",
                                    "bg": "value",
                                    "bgh": "value",
                                    "brd": "value",
                                    "brdh": "value",
                                    "brw": "value",
                                    "fnt": "value",
                                    "rd": "value",
                                    "shd": "value",
                                    "txt": "value",
                                    "txth": "value"
                                }
                            },
                            "componentClassName": "wysiwyg.viewer.components.SiteButton",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.button.BasicButton"
                        },
                        "mobileStructure": {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.button.BasicButton",
                            "layout": {
                                "width": 160,
                                "height": 40,
                                "x": 80,
                                "y": 204,
                                "scale": 0.9523809523809523,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.SiteButton",
                            "data": {
                                "type": "LinkableButton",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "label": "View Collection"
                            },
                            "props": {
                                "type": "ButtonProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "align": "center",
                                "margin": 0
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {
                                        "alpha-bg": "0",
                                        "alpha-bgh": "1",
                                        "alpha-brd": "1",
                                        "alpha-brdh": "1",
                                        "alpha-txt": "1",
                                        "alpha-txth": "1",
                                        "bg": "#FFFFFF",
                                        "bgh": "#FFFFFF",
                                        "boxShadowToggleOn-shd": "false",
                                        "brd": "#FFFFFF",
                                        "brdh": "#FFFFFF",
                                        "brw": "1",
                                        "fnt": "normal normal normal 16px/1.4em din-next-w01-light",
                                        "rd": "0px",
                                        "shd": "0 1px 4px rgba(0, 0, 0, 0.6);",
                                        "txt": "#FFFFFF",
                                        "txth": "#000000"
                                    },
                                    "propertiesSource": {
                                        "alpha-bg": "value",
                                        "bg": "value",
                                        "bgh": "value",
                                        "brd": "value",
                                        "brdh": "value",
                                        "brw": "value",
                                        "fnt": "value",
                                        "rd": "value",
                                        "shd": "value",
                                        "txt": "value",
                                        "txth": "value"
                                    }
                                },
                                "componentClassName": "wysiwyg.viewer.components.SiteButton",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.button.BasicButton"
                            }
                        }
                    }],
                    "data": {
                        "type": "StripContainerSlideShowSlide",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "3"})
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {"bg": "#B6E8E3", "brd": "color_19", "brw": "0", "rd": "0px"},
                            "propertiesSource": {"bg": "value"}
                        },
                        "componentClassName": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "mediaRef": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "title": "Road Crossing",
                                "uri": "73d1da2c4c1d4fb8897b7c3cbc329b69.jpg",
                                "description": "public/491df47b-5958-4dc7-9029-37f428755d45/c2a37dfc-36cf-4bfd-b940-55bac7bdc9e6",
                                "width": 4434,
                                "height": 3729,
                                "alt": "",
                                "artist": {"id": "", "name": ""},
                                "opacity": 0.7
                            },
                            "color": "#605E5E",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "{color_15}",
                            "colorOverlayOpacity": 0.21
                        }
                    },
                    "mobileStructure": {
                        "type": "Container",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                        "layout": {
                            "width": 320,
                            "height": 300,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                        "data": {
                            "type": "StripContainerSlideShowSlide",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "3"})
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {"bg": "#B6E8E3", "brd": "color_19", "brw": "0", "rd": "0px"},
                                "propertiesSource": {"bg": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "mediaRef": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "title": "Road Crossing",
                                    "uri": "73d1da2c4c1d4fb8897b7c3cbc329b69.jpg",
                                    "description": "public/491df47b-5958-4dc7-9029-37f428755d45/c2a37dfc-36cf-4bfd-b940-55bac7bdc9e6",
                                    "width": 4434,
                                    "height": 3729,
                                    "alt": "",
                                    "artist": {"id": "", "name": ""},
                                    "opacity": 0.7
                                },
                                "color": "#605E5E",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "{color_15}",
                                "colorOverlayOpacity": 0.21
                            }
                        }
                    }
                }],
                "props": {
                    "type": "StripContainerSlideShowProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "transition": "SlideHorizontal",
                    "autoPlayInterval": 3,
                    "autoPlay": true,
                    "transDuration": 1,
                    "pauseAutoPlayOnMouseOver": true,
                    "direction": "RTL",
                    "shouldHideOverflowContent": true,
                    "flexibleBoxHeight": false,
                    "showNavigationButton": true,
                    "showNavigationDots": true,
                    "navigationButtonSize": 18,
                    "navigationButtonMargin": 100,
                    "navigationDotsSize": 6,
                    "navigationDotsMargin": 50,
                    "navigationDotsGap": 12,
                    "navigationDotsAlignment": "center"
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-arrowColor": "1",
                            "alpha-dotsColor": "1",
                            "alpha-dotsSelectedColor": "0.3"
                        },
                        "propertiesSource": {
                            "alpha-arrowColor": "value",
                            "alpha-dotsColor": "value",
                            "alpha-dotsSelectedColor": "value",
                            "arrowColor": "value",
                            "dotsColor": "value",
                            "dotsSelectedColor": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.StripContainerSlideShow",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.common.components.stripSlideShow.viewer.skins.thinArrowsSkin"
                },
                "mobileStructure": {
                    "type": "Container",
                    "skin": "wysiwyg.common.components.stripSlideShow.viewer.skins.thinArrowsLargeSelectedCircleSkin",
                    "layout": {
                        "width": 320,
                        "height": 300,
                        "x": 0,
                        "y": 442,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.StripContainerSlideShow",
                    "props": {
                        "type": "StripContainerSlideShowProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "transition": "SlideHorizontal",
                        "autoPlayInterval": 3,
                        "autoPlay": true,
                        "transDuration": 1,
                        "pauseAutoPlayOnMouseOver": true,
                        "direction": "RTL",
                        "shouldHideOverflowContent": true,
                        "flexibleBoxHeight": false,
                        "showNavigationButton": true,
                        "showNavigationDots": true,
                        "navigationButtonSize": 12,
                        "navigationButtonMargin": 20,
                        "navigationDotsSize": 6,
                        "navigationDotsMargin": 24,
                        "navigationDotsGap": 15,
                        "navigationDotsAlignment": "center"
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-arrowColor": "1",
                                "alpha-dotsColor": "1",
                                "alpha-dotsSelectedColor": "0.3"
                            },
                            "propertiesSource": {
                                "alpha-arrowColor": "value",
                                "alpha-dotsColor": "value",
                                "alpha-dotsSelectedColor": "value",
                                "arrowColor": "value",
                                "dotsColor": "value",
                                "dotsSelectedColor": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.viewer.components.StripContainerSlideShow",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.stripSlideShow.viewer.skins.thinArrowsSkin"
                    }
                },
                "id": "comp-im24367x"
            },
            "preset": {
                "rect": {"width": 324, "height": 170.60000000000002, "x": -0.8, "y": 170.20000000000002},
                "label": "add_preset_StripSlideShow_2",
                "tags": null
            }
        }, {
            "id": "BoxSlideShow_StripBoxSlideShow_3",
            "structure": {
                "type": "Container",
                "skin": "wysiwyg.common.components.stripSlideShow.viewer.skins.squareButtonsSkin",
                "layout": {
                    "width": 980,
                    "height": 600,
                    "x": 0,
                    "y": 1802,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.StripContainerSlideShow",
                "components": [{
                    "type": "Container",
                    "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                    "layout": {
                        "width": 1421,
                        "height": 600,
                        "x": 0,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                    "components": [{
                        "type": "Container",
                        "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                        "layout": {
                            "width": 280,
                            "height": 280,
                            "x": 350,
                            "y": 145,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "mobile.core.components.Container",
                        "components": [{
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                            "layout": {
                                "width": 240,
                                "height": 53,
                                "x": 20,
                                "y": 128,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 style=\"text-align: center; line-height: 1.3em; font-size: 40px;\" class=\"font_2\"><span style=\"font-size:40px;\"><span style=\"line-height:1.3em;\"><span class=\"color_11\"><span style=\"font-family:geotica-w01-four-open,fantasy;\">TITLE</span></span></span></span></h2>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew",
                            "mobileStructure": {
                                "type": "Component",
                                "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                                "layout": {
                                    "width": 180,
                                    "height": 36,
                                    "x": 11,
                                    "y": 89,
                                    "scale": 0.9025,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "data": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<h2 style=\"text-align: center; line-height: 1.3em; font-size: 40px;\" class=\"font_2\"><span style=\"font-size:40px;\"><span style=\"line-height:1.3em;\"><span class=\"color_11\"><span style=\"font-family:geotica-w01-four-open,fantasy;\">TITLE</span></span></span></span></h2>",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "props": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                },
                                "style": "txtNew"
                            }
                        }],
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "alpha-bg": "0",
                                    "alpha-brd": "1",
                                    "bg": "#FFFFFF",
                                    "boxShadowToggleOn-shd": "false",
                                    "brd": "#FFFFFF",
                                    "brw": "1px",
                                    "rd": "0px",
                                    "shd": "0 1px 4px rgba(0, 0, 0, 0.6)"
                                },
                                "propertiesSource": {
                                    "alpha-bg": "value",
                                    "alpha-brd": "value",
                                    "bg": "value",
                                    "boxShadowToggleOn-shd": "value",
                                    "brd": "value",
                                    "brw": "value",
                                    "rd": "value",
                                    "shd": "value"
                                }
                            },
                            "componentClassName": "mobile.core.components.Container",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                        },
                        "mobileStructure": {
                            "type": "Container",
                            "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                            "layout": {
                                "width": 202,
                                "height": 200,
                                "x": 59,
                                "y": 100,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "mobile.core.components.Container",
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {
                                        "alpha-bg": "0",
                                        "alpha-brd": "1",
                                        "bg": "#FFFFFF",
                                        "boxShadowToggleOn-shd": "false",
                                        "brd": "#FFFFFF",
                                        "brw": "1px",
                                        "rd": "0px",
                                        "shd": "0 1px 4px rgba(0, 0, 0, 0.6)"
                                    },
                                    "propertiesSource": {
                                        "alpha-bg": "value",
                                        "alpha-brd": "value",
                                        "bg": "value",
                                        "boxShadowToggleOn-shd": "value",
                                        "brd": "value",
                                        "brw": "value",
                                        "rd": "value",
                                        "shd": "value"
                                    }
                                },
                                "componentClassName": "mobile.core.components.Container",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                            }
                        }
                    }],
                    "data": {
                        "type": "StripContainerSlideShowSlide",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "1"})
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {"bg": "#B6E8E3", "brd": "color_19", "brw": "0", "rd": "0px"},
                            "propertiesSource": {"bg": "value"}
                        },
                        "componentClassName": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "mediaRef": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "title": "Boats in the Bay",
                                "uri": "4b9ac2a8513d4218b4e7211e83b1bdbd.jpg",
                                "description": "public/491df47b-5958-4dc7-9029-37f428755d45/c2a37dfc-36cf-4bfd-b940-55bac7bdc9e6",
                                "width": 1920,
                                "height": 1280,
                                "alt": "",
                                "artist": {"id": "", "name": ""},
                                "opacity": 0.9
                            },
                            "color": "{color_15}",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "#8B143C",
                            "colorOverlayOpacity": 0.15
                        }
                    },
                    "mobileStructure": {
                        "type": "Container",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                        "layout": {
                            "width": 320,
                            "height": 400,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                        "data": {
                            "type": "StripContainerSlideShowSlide",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "1"})
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {"bg": "#B6E8E3", "brd": "color_19", "brw": "0", "rd": "0px"},
                                "propertiesSource": {"bg": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "mediaRef": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "title": "Boats in the Bay",
                                    "uri": "4b9ac2a8513d4218b4e7211e83b1bdbd.jpg",
                                    "description": "public/491df47b-5958-4dc7-9029-37f428755d45/c2a37dfc-36cf-4bfd-b940-55bac7bdc9e6",
                                    "width": 1920,
                                    "height": 1280,
                                    "alt": "",
                                    "artist": {"id": "", "name": ""},
                                    "opacity": 0.9
                                },
                                "color": "{color_15}",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "#8B143C",
                                "colorOverlayOpacity": 0.15
                            }
                        }
                    }
                }, {
                    "type": "Container",
                    "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                    "layout": {
                        "width": 1421,
                        "height": 600,
                        "x": 0,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                    "components": [{
                        "type": "Container",
                        "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                        "layout": {
                            "width": 280,
                            "height": 280,
                            "x": 350,
                            "y": 145,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "mobile.core.components.Container",
                        "components": [{
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                            "layout": {
                                "width": 240,
                                "height": 53,
                                "x": 20,
                                "y": 128,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 style=\"text-align: center; line-height: 1.3em; font-size: 40px;\" class=\"font_2\"><span style=\"font-size:40px;\"><span style=\"line-height:1.3em;\"><span class=\"color_11\"><span style=\"font-family:geotica-w01-four-open,fantasy;\">TITLE</span></span></span></span></h2>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew",
                            "mobileStructure": {
                                "type": "Component",
                                "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                                "layout": {
                                    "width": 180,
                                    "height": 39,
                                    "x": 10,
                                    "y": 87,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "data": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<h2 style=\"text-align: center; line-height: 1.3em; font-size: 40px;\" class=\"font_2\"><span style=\"font-size:40px;\"><span style=\"line-height:1.3em;\"><span class=\"color_11\"><span style=\"font-family:geotica-w01-four-open,fantasy;\">TITLE</span></span></span></span></h2>",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "props": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                },
                                "style": "txtNew"
                            }
                        }],
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "alpha-bg": "0",
                                    "alpha-brd": "1",
                                    "bg": "#FFFFFF",
                                    "boxShadowToggleOn-shd": "false",
                                    "brd": "#FFFFFF",
                                    "brw": "1px",
                                    "rd": "0px",
                                    "shd": "0 1px 4px rgba(0, 0, 0, 0.6)"
                                },
                                "propertiesSource": {
                                    "alpha-bg": "value",
                                    "alpha-brd": "value",
                                    "bg": "value",
                                    "boxShadowToggleOn-shd": "value",
                                    "brd": "value",
                                    "brw": "value",
                                    "rd": "value",
                                    "shd": "value"
                                }
                            },
                            "componentClassName": "mobile.core.components.Container",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                        },
                        "mobileStructure": {
                            "type": "Container",
                            "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                            "layout": {
                                "width": 202,
                                "height": 200,
                                "x": 59,
                                "y": 100,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "mobile.core.components.Container",
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {
                                        "alpha-bg": "0",
                                        "alpha-brd": "1",
                                        "bg": "#FFFFFF",
                                        "boxShadowToggleOn-shd": "false",
                                        "brd": "#FFFFFF",
                                        "brw": "1px",
                                        "rd": "0px",
                                        "shd": "0 1px 4px rgba(0, 0, 0, 0.6)"
                                    },
                                    "propertiesSource": {
                                        "alpha-bg": "value",
                                        "alpha-brd": "value",
                                        "bg": "value",
                                        "boxShadowToggleOn-shd": "value",
                                        "brd": "value",
                                        "brw": "value",
                                        "rd": "value",
                                        "shd": "value"
                                    }
                                },
                                "componentClassName": "mobile.core.components.Container",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                            }
                        }
                    }],
                    "data": {
                        "type": "StripContainerSlideShowSlide",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "2"})
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {"bg": "#B6E8E3", "brd": "color_19", "brw": "0", "rd": "0px"},
                            "propertiesSource": {"bg": "value"}
                        },
                        "componentClassName": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "mediaRef": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "title": "Ocean Water",
                                "uri": "eadb0412451740f4ad9a763f9a358ddc.jpg",
                                "description": "public/491df47b-5958-4dc7-9029-37f428755d45/c2a37dfc-36cf-4bfd-b940-55bac7bdc9e6",
                                "width": 1920,
                                "height": 1280,
                                "alt": "",
                                "artist": {"id": "", "name": ""}
                            },
                            "color": "{color_15}",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "{color_15}",
                            "colorOverlayOpacity": 0.21
                        }
                    },
                    "mobileStructure": {
                        "type": "Container",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                        "layout": {
                            "width": 320,
                            "height": 400,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                        "data": {
                            "type": "StripContainerSlideShowSlide",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "2"})
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {"bg": "#B6E8E3", "brd": "color_19", "brw": "0", "rd": "0px"},
                                "propertiesSource": {"bg": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "mediaRef": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "title": "Ocean Water",
                                    "uri": "eadb0412451740f4ad9a763f9a358ddc.jpg",
                                    "description": "public/491df47b-5958-4dc7-9029-37f428755d45/c2a37dfc-36cf-4bfd-b940-55bac7bdc9e6",
                                    "width": 1920,
                                    "height": 1280,
                                    "alt": "",
                                    "artist": {"id": "", "name": ""}
                                },
                                "color": "{color_15}",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "{color_15}",
                                "colorOverlayOpacity": 0.21
                            }
                        }
                    }
                }, {
                    "type": "Container",
                    "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                    "layout": {
                        "width": 1421,
                        "height": 600,
                        "x": 0,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                    "components": [{
                        "type": "Container",
                        "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                        "layout": {
                            "width": 280,
                            "height": 280,
                            "x": 350,
                            "y": 145,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "mobile.core.components.Container",
                        "components": [{
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                            "layout": {
                                "width": 240,
                                "height": 53,
                                "x": 20,
                                "y": 128,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 style=\"text-align: center; line-height: 1.3em; font-size: 40px;\" class=\"font_2\"><span style=\"font-size:40px;\"><span style=\"line-height:1.3em;\"><span class=\"color_11\"><span style=\"font-family:geotica-w01-four-open,fantasy;\">TITLE</span></span></span></span></h2>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew",
                            "mobileStructure": {
                                "type": "Component",
                                "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                                "layout": {
                                    "width": 180,
                                    "height": 39,
                                    "x": 10,
                                    "y": 87,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "data": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<h2 style=\"text-align: center; line-height: 1.3em; font-size: 40px;\" class=\"font_2\"><span style=\"font-size:40px;\"><span style=\"line-height:1.3em;\"><span class=\"color_11\"><span style=\"font-family:geotica-w01-four-open,fantasy;\">TITLE</span></span></span></span></h2>",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "props": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                },
                                "style": "txtNew"
                            }
                        }],
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "alpha-bg": "0",
                                    "alpha-brd": "1",
                                    "bg": "#FFFFFF",
                                    "boxShadowToggleOn-shd": "false",
                                    "brd": "#FFFFFF",
                                    "brw": "1px",
                                    "rd": "0px",
                                    "shd": "0 1px 4px rgba(0, 0, 0, 0.6)"
                                },
                                "propertiesSource": {
                                    "alpha-bg": "value",
                                    "alpha-brd": "value",
                                    "bg": "value",
                                    "boxShadowToggleOn-shd": "value",
                                    "brd": "value",
                                    "brw": "value",
                                    "rd": "value",
                                    "shd": "value"
                                }
                            },
                            "componentClassName": "mobile.core.components.Container",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                        },
                        "mobileStructure": {
                            "type": "Container",
                            "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                            "layout": {
                                "width": 202,
                                "height": 200,
                                "x": 60,
                                "y": 100,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "mobile.core.components.Container",
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {
                                        "alpha-bg": "0",
                                        "alpha-brd": "1",
                                        "bg": "#FFFFFF",
                                        "boxShadowToggleOn-shd": "false",
                                        "brd": "#FFFFFF",
                                        "brw": "1px",
                                        "rd": "0px",
                                        "shd": "0 1px 4px rgba(0, 0, 0, 0.6)"
                                    },
                                    "propertiesSource": {
                                        "alpha-bg": "value",
                                        "alpha-brd": "value",
                                        "bg": "value",
                                        "boxShadowToggleOn-shd": "value",
                                        "brd": "value",
                                        "brw": "value",
                                        "rd": "value",
                                        "shd": "value"
                                    }
                                },
                                "componentClassName": "mobile.core.components.Container",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                            }
                        }
                    }],
                    "data": {
                        "type": "StripContainerSlideShowSlide",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "3"})
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {"bg": "#B6E8E3", "brd": "color_19", "brw": "0", "rd": "0px"},
                            "propertiesSource": {"bg": "value"}
                        },
                        "componentClassName": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "mediaRef": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "title": "The Flock",
                                "uri": "11d09274fb8b4eceb34b6a8b6d16e8b5.jpeg",
                                "description": "public/491df47b-5958-4dc7-9029-37f428755d45/c2a37dfc-36cf-4bfd-b940-55bac7bdc9e6",
                                "width": 3520,
                                "height": 3520,
                                "alt": "",
                                "artist": {"id": "", "name": ""},
                                "opacity": 0.8
                            },
                            "color": "#000000",
                            "colorOpacity": 1,
                            "alignType": "bottom",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "{color_15}",
                            "colorOverlayOpacity": 0.21
                        }
                    },
                    "mobileStructure": {
                        "type": "Container",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                        "layout": {
                            "width": 320,
                            "height": 400,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                        "data": {
                            "type": "StripContainerSlideShowSlide",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "3"})
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {"bg": "#B6E8E3", "brd": "color_19", "brw": "0", "rd": "0px"},
                                "propertiesSource": {"bg": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "mediaRef": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "title": "The Flock",
                                    "uri": "11d09274fb8b4eceb34b6a8b6d16e8b5.jpeg",
                                    "description": "public/491df47b-5958-4dc7-9029-37f428755d45/c2a37dfc-36cf-4bfd-b940-55bac7bdc9e6",
                                    "width": 3520,
                                    "height": 3520,
                                    "alt": "",
                                    "artist": {"id": "", "name": ""},
                                    "opacity": 0.8
                                },
                                "color": "#000000",
                                "colorOpacity": 1,
                                "alignType": "bottom",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "{color_15}",
                                "colorOverlayOpacity": 0.21
                            }
                        }
                    }
                }],
                "props": {
                    "type": "StripContainerSlideShowProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "transition": "CrossFade",
                    "autoPlayInterval": 3,
                    "autoPlay": true,
                    "transDuration": 1.5,
                    "pauseAutoPlayOnMouseOver": true,
                    "direction": "RTL",
                    "shouldHideOverflowContent": true,
                    "flexibleBoxHeight": false,
                    "showNavigationButton": true,
                    "showNavigationDots": true,
                    "navigationButtonSize": 20,
                    "navigationButtonMargin": 100,
                    "navigationDotsSize": 6,
                    "navigationDotsMargin": 40,
                    "navigationDotsGap": 12,
                    "navigationDotsAlignment": "center"
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-arrowColor": "1",
                            "alpha-dotsColor": "1",
                            "alpha-dotsSelectedColor": "0.3"
                        },
                        "propertiesSource": {
                            "alpha-arrowColor": "value",
                            "alpha-dotsColor": "value",
                            "alpha-dotsSelectedColor": "value",
                            "arrowColor": "value",
                            "dotsColor": "value",
                            "dotsSelectedColor": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.StripContainerSlideShow",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.common.components.stripSlideShow.viewer.skins.thinArrowsSkin"
                },
                "mobileStructure": {
                    "type": "Container",
                    "skin": "wysiwyg.common.components.stripSlideShow.viewer.skins.thinArrowsLargeSelectedCircleSkin",
                    "layout": {
                        "width": 320,
                        "height": 400,
                        "x": 0,
                        "y": 818,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.StripContainerSlideShow",
                    "props": {
                        "type": "StripContainerSlideShowProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "transition": "CrossFade",
                        "autoPlayInterval": 3,
                        "autoPlay": true,
                        "transDuration": 1,
                        "pauseAutoPlayOnMouseOver": true,
                        "direction": "RTL",
                        "shouldHideOverflowContent": true,
                        "flexibleBoxHeight": false,
                        "showNavigationButton": true,
                        "showNavigationDots": true,
                        "navigationButtonSize": 14,
                        "navigationButtonMargin": 20,
                        "navigationDotsSize": 6,
                        "navigationDotsMargin": 24,
                        "navigationDotsGap": 15,
                        "navigationDotsAlignment": "center"
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-arrowColor": "1",
                                "alpha-dotsColor": "1",
                                "alpha-dotsSelectedColor": "0.3"
                            },
                            "propertiesSource": {
                                "alpha-arrowColor": "value",
                                "alpha-dotsColor": "value",
                                "alpha-dotsSelectedColor": "value",
                                "arrowColor": "value",
                                "dotsColor": "value",
                                "dotsSelectedColor": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.viewer.components.StripContainerSlideShow",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.stripSlideShow.viewer.skins.thinArrowsSkin"
                    }
                },
                "id": "comp-im0njqrg"
            },
            "preset": {
                "rect": {"width": 324, "height": 160, "x": -0.8, "y": 340.8},
                "label": "add_preset_StripSlideShow_3",
                "tags": null
            }
        }, {
            "id": "BoxSlideShow_StripBoxSlideShow_4",
            "structure": {
                "type": "Container",
                "skin": "wysiwyg.common.components.stripSlideShow.viewer.skins.squareButtonsSkin",
                "layout": {
                    "width": 980,
                    "height": 650,
                    "x": 0,
                    "y": 2602,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.StripContainerSlideShow",
                "components": [{
                    "type": "Container",
                    "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                    "layout": {
                        "width": 1421,
                        "height": 650,
                        "x": 0,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                    "components": [{
                        "type": "Component",
                        "layout": {
                            "width": 860,
                            "height": 134,
                            "x": 59,
                            "y": 229,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<h2 style=\"font-size: 88px; text-align: center;\" class=\"font_2\"><span style=\"font-size:88px;\"><span style=\"letter-spacing:0em;\"><span class=\"color_11\"><span style=\"font-family:lulo-clean-w01-one-bold,sans-serif;\">YOUR</span></span></span></span></h2>",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 280,
                                "height": 73,
                                "x": 20,
                                "y": 27,
                                "scale": 1.0426134958616373,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 style=\"font-size: 88px; text-align: center;\" class=\"font_2\"><span style=\"font-size:88px;\"><span style=\"letter-spacing:0em;\"><span class=\"color_11\"><span style=\"font-family:lulo-clean-w01-one-bold,sans-serif;\">YOUR</span></span></span></span></h2>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 848,
                            "height": 34,
                            "x": 59,
                            "y": 364,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<h2 style=\"font-size: 27px; text-align: center;\" class=\"font_2\"><span style=\"font-size:27px;\"><span style=\"letter-spacing:0.3em;\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif;\"><span class=\"color_11\">STORY GOES HERE</span></span></span></span></h2>",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 279,
                                "height": 19,
                                "x": 20,
                                "y": 101,
                                "scale": 0.5869257791388938,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 style=\"font-size: 27px; text-align: center;\" class=\"font_2\"><span style=\"font-size:27px;\"><span style=\"letter-spacing:0.3em;\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif;\"><span class=\"color_11\">STORY GOES HERE</span></span></span></span></h2>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }],
                    "data": {
                        "type": "StripContainerSlideShowSlide",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "1"})
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {"bg": "#B6E8E3", "brd": "color_19", "brw": "0", "rd": "0px"},
                            "propertiesSource": {"bg": "value"}
                        },
                        "componentClassName": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "mediaRef": {
                                "type": "WixVideo",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "title": "Urban Skaters",
                                "videoId": "video/11062b_9181e09668144a139d3b107a08acd708",
                                "qualities": [{
                                    "quality": "480p",
                                    "width": 1920,
                                    "height": 1080,
                                    "formats": ["mp4", "webm"]
                                }, {
                                    "quality": "720p",
                                    "width": 1920,
                                    "height": 1080,
                                    "formats": ["mp4", "webm"]
                                }, {"quality": "1080p", "width": 1920, "height": 1080, "formats": ["mp4", "webm"]}],
                                "posterImageRef": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "title": "",
                                    "uri": "11062b_9181e09668144a139d3b107a08acd708f000.jpg",
                                    "description": "public/8e256233-1752-4026-9341-54036e7f875e/d6b6b972-952c-4ab0-b46a-a6c05a0a94a5",
                                    "width": 1920,
                                    "height": 1080,
                                    "alt": ""
                                },
                                "opacity": 1,
                                "duration": 12,
                                "loop": true,
                                "autoplay": true,
                                "preload": "auto",
                                "mute": true,
                                "artist": {"id": "vD252_6_128", "name": "dissolve"},
                                "playbackSpeed": 1
                            },
                            "color": "{color_15}",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "{color_15}",
                            "colorOverlayOpacity": 0.25
                        }
                    },
                    "mobileStructure": {
                        "type": "Container",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                        "layout": {
                            "width": 320,
                            "height": 350,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                        "data": {
                            "type": "StripContainerSlideShowSlide",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "1"})
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {"bg": "#B6E8E3", "brd": "color_19", "brw": "0", "rd": "0px"},
                                "propertiesSource": {"bg": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "mediaRef": {
                                    "type": "WixVideo",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "title": "Urban Skaters",
                                    "videoId": "video/11062b_9181e09668144a139d3b107a08acd708",
                                    "qualities": [{
                                        "quality": "480p",
                                        "width": 1920,
                                        "height": 1080,
                                        "formats": ["mp4", "webm"]
                                    }, {
                                        "quality": "720p",
                                        "width": 1920,
                                        "height": 1080,
                                        "formats": ["mp4", "webm"]
                                    }, {
                                        "quality": "1080p",
                                        "width": 1920,
                                        "height": 1080,
                                        "formats": ["mp4", "webm"]
                                    }],
                                    "posterImageRef": {
                                        "type": "Image",
                                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                        "title": "",
                                        "uri": "11062b_9181e09668144a139d3b107a08acd708f000.jpg",
                                        "description": "public/8e256233-1752-4026-9341-54036e7f875e/d6b6b972-952c-4ab0-b46a-a6c05a0a94a5",
                                        "width": 1920,
                                        "height": 1080,
                                        "alt": ""
                                    },
                                    "opacity": 1,
                                    "duration": 12,
                                    "loop": true,
                                    "autoplay": true,
                                    "preload": "auto",
                                    "mute": true,
                                    "artist": {"id": "vD252_6_128", "name": "dissolve"},
                                    "playbackSpeed": 1
                                },
                                "color": "{color_15}",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "{color_15}",
                                "colorOverlayOpacity": 0.25
                            }
                        }
                    }
                }, {
                    "type": "Container",
                    "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                    "layout": {
                        "width": 1421,
                        "height": 650,
                        "x": 0,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                    "components": [{
                        "type": "Component",
                        "layout": {
                            "width": 859,
                            "height": 134,
                            "x": 59,
                            "y": 229,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<h2 style=\"font-size: 88px; text-align: center;\" class=\"font_2\"><span style=\"font-size:88px;\"><span style=\"letter-spacing:0em;\"><span class=\"color_11\"><span style=\"font-family:lulo-clean-w01-one-bold,sans-serif;\">JUST</span></span></span></span></h2>",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 280,
                                "height": 73,
                                "x": 19,
                                "y": 27,
                                "scale": 1.05,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 style=\"font-size: 88px; text-align: center;\" class=\"font_2\"><span style=\"font-size:88px;\"><span style=\"letter-spacing:0em;\"><span class=\"color_11\"><span style=\"font-family:lulo-clean-w01-one-bold,sans-serif;\">JUST</span></span></span></span></h2>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 859,
                            "height": 36,
                            "x": 55,
                            "y": 363,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<h2 style=\"font-size: 28px; text-align: center;\" class=\"font_2\"><span style=\"font-size:28px;\"><span style=\"letter-spacing:0.3em;\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif;\"><span class=\"color_11\">ADD YOUR TEXT</span></span></span></span></h2>",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 280,
                                "height": 19,
                                "x": 19,
                                "y": 100,
                                "scale": 0.57,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 style=\"font-size: 28px; text-align: center;\" class=\"font_2\"><span style=\"font-size:28px;\"><span style=\"letter-spacing:0.3em;\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif;\"><span class=\"color_11\">ADD YOUR TEXT</span></span></span></span></h2>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }],
                    "data": {
                        "type": "StripContainerSlideShowSlide",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "2"})
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {"bg": "#B6E8E3", "brd": "color_19", "brw": "0", "rd": "0px"},
                            "propertiesSource": {"bg": "value"}
                        },
                        "componentClassName": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "mediaRef": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "title": "8DEOAV0DB9.jpg",
                                "uri": "035244_a74c8f313159492aadec43c732830d56.jpg",
                                "description": "private/allMedia_picture",
                                "width": 5760,
                                "height": 3840,
                                "alt": "",
                                "artist": {"id": "", "name": ""}
                            },
                            "color": "{color_15}",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "{color_15}",
                            "colorOverlayOpacity": 0.21
                        }
                    },
                    "mobileStructure": {
                        "type": "Container",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                        "layout": {
                            "width": 320,
                            "height": 350,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                        "data": {
                            "type": "StripContainerSlideShowSlide",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "2"})
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {"bg": "#B6E8E3", "brd": "color_19", "brw": "0", "rd": "0px"},
                                "propertiesSource": {"bg": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "mediaRef": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "title": "8DEOAV0DB9.jpg",
                                    "uri": "035244_a74c8f313159492aadec43c732830d56.jpg",
                                    "description": "private/allMedia_picture",
                                    "width": 5760,
                                    "height": 3840,
                                    "alt": "",
                                    "artist": {"id": "", "name": ""}
                                },
                                "color": "{color_15}",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "{color_15}",
                                "colorOverlayOpacity": 0.21
                            }
                        }
                    }
                }, {
                    "type": "Container",
                    "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                    "layout": {
                        "width": 1421,
                        "height": 650,
                        "x": 0,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                    "components": [{
                        "type": "Component",
                        "layout": {
                            "width": 859,
                            "height": 134,
                            "x": 59,
                            "y": 229,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<h2 style=\"font-size: 88px; text-align: center;\" class=\"font_2\"><span style=\"font-size:88px;\"><span style=\"letter-spacing:0em;\"><span class=\"color_11\"><span style=\"font-family:lulo-clean-w01-one-bold,sans-serif;\">SHARE</span></span></span></span></h2>",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 280,
                                "height": 73,
                                "x": 19,
                                "y": 28,
                                "scale": 1.05,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 style=\"font-size: 88px; text-align: center;\" class=\"font_2\"><span style=\"font-size:88px;\"><span style=\"letter-spacing:0em;\"><span class=\"color_11\"><span style=\"font-family:lulo-clean-w01-one-bold,sans-serif;\">SHARE</span></span></span></span></h2>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 899,
                            "height": 36,
                            "x": 41,
                            "y": 362,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<h2 style=\"font-size: 28px; text-align: center;\" class=\"font_2\"><span style=\"font-size:28px;\"><span style=\"letter-spacing:0.3em;\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif;\"><span class=\"color_11\">YOUR TRUE PASSION</span></span></span></span></h2>",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 280,
                                "height": 19,
                                "x": 19,
                                "y": 100,
                                "scale": 0.57,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 style=\"font-size: 28px; text-align: center;\" class=\"font_2\"><span style=\"font-size:28px;\"><span style=\"letter-spacing:0.3em;\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif;\"><span class=\"color_11\">YOUR TRUE PASSION</span></span></span></span></h2>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }],
                    "data": {
                        "type": "StripContainerSlideShowSlide",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "3"})
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {"bg": "#B6E8E3", "brd": "color_19", "brw": "0", "rd": "0px"},
                            "propertiesSource": {"bg": "value"}
                        },
                        "componentClassName": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "mediaRef": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "title": "Young Skater Relaxing",
                                "uri": "4ef2e0d7704448cc85fea31959c2b88c.jpg",
                                "description": "public/491df47b-5958-4dc7-9029-37f428755d45/c2a37dfc-36cf-4bfd-b940-55bac7bdc9e6",
                                "width": 5760,
                                "height": 3840,
                                "alt": "",
                                "artist": {"id": "", "name": ""},
                                "opacity": 0.7
                            },
                            "color": "#000000",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "{color_15}",
                            "colorOverlayOpacity": 0.21
                        }
                    },
                    "mobileStructure": {
                        "type": "Container",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                        "layout": {
                            "width": 320,
                            "height": 350,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                        "data": {
                            "type": "StripContainerSlideShowSlide",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "3"})
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {"bg": "#B6E8E3", "brd": "color_19", "brw": "0", "rd": "0px"},
                                "propertiesSource": {"bg": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "mediaRef": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "title": "Young Skater Relaxing",
                                    "uri": "4ef2e0d7704448cc85fea31959c2b88c.jpg",
                                    "description": "public/491df47b-5958-4dc7-9029-37f428755d45/c2a37dfc-36cf-4bfd-b940-55bac7bdc9e6",
                                    "width": 5760,
                                    "height": 3840,
                                    "alt": "",
                                    "artist": {"id": "", "name": ""},
                                    "opacity": 0.7
                                },
                                "color": "#000000",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "{color_15}",
                                "colorOverlayOpacity": 0.21
                            }
                        }
                    }
                }],
                "props": {
                    "type": "StripContainerSlideShowProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "transition": "SlideHorizontal",
                    "autoPlayInterval": 3,
                    "autoPlay": true,
                    "transDuration": 1,
                    "pauseAutoPlayOnMouseOver": true,
                    "direction": "RTL",
                    "shouldHideOverflowContent": true,
                    "flexibleBoxHeight": false,
                    "showNavigationButton": true,
                    "showNavigationDots": true,
                    "navigationButtonSize": 18,
                    "navigationButtonMargin": 100,
                    "navigationDotsSize": 6,
                    "navigationDotsMargin": 50,
                    "navigationDotsGap": 12,
                    "navigationDotsAlignment": "center"
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-arrowColor": "1",
                            "alpha-dotsColor": "1",
                            "alpha-dotsSelectedColor": "0.3"
                        },
                        "propertiesSource": {
                            "alpha-arrowColor": "value",
                            "alpha-dotsColor": "value",
                            "alpha-dotsSelectedColor": "value",
                            "arrowColor": "value",
                            "dotsColor": "value",
                            "dotsSelectedColor": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.StripContainerSlideShow",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.common.components.stripSlideShow.viewer.skins.thinArrowsSkin"
                },
                "mobileStructure": {
                    "type": "Container",
                    "skin": "wysiwyg.common.components.stripSlideShow.viewer.skins.thinArrowsLargeSelectedCircleSkin",
                    "layout": {
                        "width": 320,
                        "height": 350,
                        "x": 0,
                        "y": 1283,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.StripContainerSlideShow",
                    "props": {
                        "type": "StripContainerSlideShowProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "transition": "SlideHorizontal",
                        "autoPlayInterval": 3,
                        "autoPlay": true,
                        "transDuration": 1,
                        "pauseAutoPlayOnMouseOver": true,
                        "direction": "RTL",
                        "shouldHideOverflowContent": true,
                        "flexibleBoxHeight": false,
                        "showNavigationButton": true,
                        "showNavigationDots": true,
                        "navigationButtonSize": 14,
                        "navigationButtonMargin": 20,
                        "navigationDotsSize": 6,
                        "navigationDotsMargin": 24,
                        "navigationDotsGap": 15,
                        "navigationDotsAlignment": "center"
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-arrowColor": "1",
                                "alpha-dotsColor": "1",
                                "alpha-dotsSelectedColor": "0.3"
                            },
                            "propertiesSource": {
                                "alpha-arrowColor": "value",
                                "alpha-dotsColor": "value",
                                "alpha-dotsSelectedColor": "value",
                                "arrowColor": "value",
                                "dotsColor": "value",
                                "dotsSelectedColor": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.viewer.components.StripContainerSlideShow",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.stripSlideShow.viewer.skins.thinArrowsSkin"
                    }
                },
                "id": "comp-im0ov628"
            },
            "preset": {
                "rect": {"width": 324, "height": 169.4, "x": -0.8, "y": 500.8},
                "label": "add_preset_StripSlideShow_4",
                "tags": null
            }
        }, {
            "id": "BoxSlideShow_StripBoxSlideShow_5",
            "structure": {
                "type": "Container",
                "skin": "wysiwyg.common.components.stripSlideShow.viewer.skins.thinArrowsLargeSelectedCircleSkin",
                "layout": {
                    "width": 980,
                    "height": 651,
                    "x": 0,
                    "y": 3452,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.StripContainerSlideShow",
                "components": [{
                    "type": "Container",
                    "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                    "layout": {
                        "width": 1421,
                        "height": 651,
                        "x": 0,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                    "components": [{
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.line.SolidLine",
                        "layout": {
                            "width": 31,
                            "height": 5,
                            "x": 40,
                            "y": 149,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.FiveGridLine",
                        "props": {
                            "type": "FiveGridLineProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "fullScreenModeOn": false
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {"alpha-brd": "1", "brd": "#2F2E2E", "lnw": "1px"},
                                "propertiesSource": {"brd": "value", "lnw": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.line.SolidLine"
                        },
                        "mobileStructure": {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.line.SolidLine",
                            "layout": {
                                "width": 30,
                                "height": 5,
                                "x": 60,
                                "y": 109,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.FiveGridLine",
                            "props": {
                                "type": "FiveGridLineProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "fullScreenModeOn": false
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {"alpha-brd": "1", "brd": "#2F2E2E", "lnw": "1px"},
                                    "propertiesSource": {"brd": "value", "lnw": "value"}
                                },
                                "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.line.SolidLine"
                            }
                        }
                    }, {
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                        "layout": {
                            "width": 563,
                            "height": 19,
                            "x": 42,
                            "y": 109,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<h2 style=\"font-size: 14px;\" class=\"font_2\"><span class=\"color_2\"><span style=\"font-size:14px;\"><span style=\"letter-spacing:0.3em;\"><span style=\"font-family:libre baskerville,serif;\">WHAT PEOPLE SAY</span></span></span></span></h2>",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                            "layout": {
                                "width": 238,
                                "height": 19,
                                "x": 60,
                                "y": 58,
                                "scale": 0.95,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 style=\"font-size: 14px;\" class=\"font_2\"><span class=\"color_2\"><span style=\"font-size:14px;\"><span style=\"letter-spacing:0.3em;\"><span style=\"font-family:libre baskerville,serif;\">WHAT PEOPLE SAY</span></span></span></span></h2>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                        "layout": {
                            "width": 368,
                            "height": 284,
                            "x": 41,
                            "y": 188,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<p style=\"font-size: 28px; line-height: 1.3em;\" class=\"font_8\"><span style=\"font-size:28px;\"><span style=\"letter-spacing:0.05em;\"><span class=\"color_2\"><span style=\"font-family:libre baskerville,serif;\">“Testimonials work great. Showing your reviews in quote marks has a powerful effect on customers and makes them more likely to trust you.”&nbsp;</span></span></span></span></p><p class=\"font_8\">&nbsp;</p>",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                            "layout": {
                                "width": 199,
                                "height": 158,
                                "x": 60,
                                "y": 145,
                                "scale": 0.57,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p style=\"font-size: 28px; line-height: 1.3em;\" class=\"font_8\"><span style=\"font-size:28px;\"><span style=\"letter-spacing:0.05em;\"><span class=\"color_2\"><span style=\"font-family:libre baskerville,serif;\">“Testimonials work great. Showing your reviews in quote marks has a powerful effect on customers and makes them more likely to trust you.”&nbsp;</span></span></span></span></p><p class=\"font_8\">&nbsp;</p>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                        "layout": {
                            "width": 545,
                            "height": 22,
                            "x": 42,
                            "y": 529,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<p style=\"font-size: 16px;\" class=\"font_8\"><span class=\"color_2\"><span style=\"font-size:16px;\"><span style=\"letter-spacing:0.1em;\"><span style=\"font-style:italic;\"><span style=\"font-family:libre baskerville,serif;\">—&nbsp;&nbsp;Name, Title</span></span></span></span></span></p>",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                            "layout": {
                                "width": 149,
                                "height": 19,
                                "x": 60,
                                "y": 336,
                                "scale": 0.8573749999999999,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p style=\"font-size: 16px;\" class=\"font_8\"><span class=\"color_2\"><span style=\"font-size:16px;\"><span style=\"letter-spacing:0.1em;\"><span style=\"font-style:italic;\"><span style=\"font-family:libre baskerville,serif;\">—&nbsp;&nbsp;Name, Title</span></span></span></span></span></p>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }],
                    "data": {
                        "type": "StripContainerSlideShowSlide",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "1"})
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {"bg": "#B6E8E3", "brd": "color_19", "brw": "0", "rd": "0px"},
                            "propertiesSource": {"bg": "value"}
                        },
                        "componentClassName": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "mediaRef": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "title": "Man Against the Wall",
                                "uri": "63ee1ebbee2b45e2bae991547524fe42.jpg",
                                "description": "public/491df47b-5958-4dc7-9029-37f428755d45/08616385-e843-431b-bd64-b8b845ff47bb",
                                "width": 2559,
                                "height": 1936,
                                "alt": "",
                                "artist": {"id": "", "name": ""},
                                "opacity": 0.2
                            },
                            "color": "#F2DF0A",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "original_size",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "mobileStructure": {
                        "type": "Container",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                        "layout": {
                            "width": 320,
                            "height": 424,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                        "data": {
                            "type": "StripContainerSlideShowSlide",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "1"})
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {"bg": "#B6E8E3", "brd": "color_19", "brw": "0", "rd": "0px"},
                                "propertiesSource": {"bg": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "mediaRef": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "title": "Man Against the Wall",
                                    "uri": "63ee1ebbee2b45e2bae991547524fe42.jpg",
                                    "description": "public/491df47b-5958-4dc7-9029-37f428755d45/08616385-e843-431b-bd64-b8b845ff47bb",
                                    "width": 2559,
                                    "height": 1936,
                                    "alt": "",
                                    "artist": {"id": "", "name": ""},
                                    "opacity": 0.2
                                },
                                "color": "#F2DF0A",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "original_size",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            }
                        }
                    }
                }, {
                    "type": "Container",
                    "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                    "layout": {
                        "width": 1421,
                        "height": 651,
                        "x": 0,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                    "components": [{
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                        "layout": {
                            "width": 442,
                            "height": 19,
                            "x": 476,
                            "y": 109,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<h2 style=\"font-size: 14px;\" class=\"font_2\"><span class=\"color_11\"><span style=\"font-size:14px;\"><span style=\"letter-spacing:0.3em;\"><span style=\"font-family:libre baskerville,serif;\">TITLE GOES HERE</span></span></span></span></h2>",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                            "layout": {
                                "width": 235,
                                "height": 19,
                                "x": 59,
                                "y": 59,
                                "scale": 0.95,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 style=\"font-size: 14px;\" class=\"font_2\"><span class=\"color_11\"><span style=\"font-size:14px;\"><span style=\"letter-spacing:0.3em;\"><span style=\"font-family:libre baskerville,serif;\">TITLE GOES HERE</span></span></span></span></h2>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.line.SolidLine",
                        "layout": {
                            "width": 30,
                            "height": 5,
                            "x": 474,
                            "y": 149,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.FiveGridLine",
                        "props": {
                            "type": "FiveGridLineProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "fullScreenModeOn": false
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {"alpha-brd": "1", "brd": "#FFFFFF", "lnw": "1px"},
                                "propertiesSource": {"brd": "value", "lnw": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.line.SolidLine"
                        },
                        "mobileStructure": {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.line.SolidLine",
                            "layout": {
                                "width": 30,
                                "height": 5,
                                "x": 59,
                                "y": 113,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.FiveGridLine",
                            "props": {
                                "type": "FiveGridLineProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "fullScreenModeOn": false
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {"alpha-brd": "1", "brd": "#FFFFFF", "lnw": "1px"},
                                    "propertiesSource": {"brd": "value", "lnw": "value"}
                                },
                                "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.line.SolidLine"
                            }
                        }
                    }, {
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                        "layout": {
                            "width": 368,
                            "height": 246,
                            "x": 475,
                            "y": 187,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<p style=\"font-size: 28px; line-height: 1.3em;\" class=\"font_8\"><span style=\"letter-spacing:0.05em;\"><span class=\"color_11\"><span style=\"font-size:28px;\"><span style=\"font-family:libre baskerville,serif;\">“Share the amazing things customers are saying about your business. Double click, or click Edit Text to make it yours.”​</span></span></span></span></p><p class=\"font_8\">&nbsp;</p>",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                            "layout": {
                                "width": 202,
                                "height": 137,
                                "x": 60,
                                "y": 145,
                                "scale": 0.57,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p style=\"font-size: 28px; line-height: 1.3em;\" class=\"font_8\"><span style=\"letter-spacing:0.05em;\"><span class=\"color_11\"><span style=\"font-size:28px;\"><span style=\"font-family:libre baskerville,serif;\">“Share the amazing things customers are saying about your business. Double click, or click Edit Text to make it yours.”​</span></span></span></span></p><p class=\"font_8\">&nbsp;</p>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                        "layout": {
                            "width": 442,
                            "height": 22,
                            "x": 476,
                            "y": 498,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<p style=\"font-size: 16px;\" class=\"font_8\"><span class=\"color_11\"><span style=\"font-size:16px;\"><span style=\"letter-spacing:0.1em;\"><span style=\"font-style:italic;\"><span style=\"font-family:libre baskerville,serif;\">—&nbsp;&nbsp;Name, Title</span></span></span></span></span></p>",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                            "layout": {
                                "width": 149,
                                "height": 19,
                                "x": 59,
                                "y": 338,
                                "scale": 0.88,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p style=\"font-size: 16px;\" class=\"font_8\"><span class=\"color_11\"><span style=\"font-size:16px;\"><span style=\"letter-spacing:0.1em;\"><span style=\"font-style:italic;\"><span style=\"font-family:libre baskerville,serif;\">—&nbsp;&nbsp;Name, Title</span></span></span></span></span></p>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }],
                    "data": {
                        "type": "StripContainerSlideShowSlide",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "2"})
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {"bg": "#B6E8E3", "brd": "color_19", "brw": "0", "rd": "0px"},
                            "propertiesSource": {"bg": "value"}
                        },
                        "componentClassName": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "mediaRef": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "title": "Man Portrait.jpg",
                                "uri": "035244_bea0a5aa9c92439fb8c1c12caea2f5db.jpg",
                                "description": "private/allMedia_picture",
                                "width": 1920,
                                "height": 1146,
                                "alt": "",
                                "artist": {"id": "", "name": ""},
                                "opacity": 0.4
                            },
                            "color": "#005AFF",
                            "colorOpacity": 0.9,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "{color_15}",
                            "colorOverlayOpacity": 0.21
                        }
                    },
                    "mobileStructure": {
                        "type": "Container",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                        "layout": {
                            "width": 320,
                            "height": 424,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                        "data": {
                            "type": "StripContainerSlideShowSlide",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "2"})
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {"bg": "#B6E8E3", "brd": "color_19", "brw": "0", "rd": "0px"},
                                "propertiesSource": {"bg": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "mediaRef": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "title": "Man Portrait.jpg",
                                    "uri": "035244_bea0a5aa9c92439fb8c1c12caea2f5db.jpg",
                                    "description": "private/allMedia_picture",
                                    "width": 1920,
                                    "height": 1146,
                                    "alt": "",
                                    "artist": {"id": "", "name": ""},
                                    "opacity": 0.4
                                },
                                "color": "#005AFF",
                                "colorOpacity": 0.9,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "{color_15}",
                                "colorOverlayOpacity": 0.21
                            }
                        }
                    }
                }, {
                    "type": "Container",
                    "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                    "layout": {
                        "width": 1421,
                        "height": 651,
                        "x": 0,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                    "components": [{
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                        "layout": {
                            "width": 442,
                            "height": 19,
                            "x": 476,
                            "y": 109,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<h2 style=\"font-size: 14px;\" class=\"font_2\"><span class=\"color_11\"><span style=\"font-size:14px;\"><span style=\"letter-spacing:0.3em;\"><span style=\"font-family:libre baskerville,serif;\">TITLE GOES HERE</span></span></span></span></h2>",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                            "layout": {
                                "width": 235,
                                "height": 19,
                                "x": 61,
                                "y": 59,
                                "scale": 0.95,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 style=\"font-size: 14px;\" class=\"font_2\"><span class=\"color_11\"><span style=\"font-size:14px;\"><span style=\"letter-spacing:0.3em;\"><span style=\"font-family:libre baskerville,serif;\">TITLE GOES HERE</span></span></span></span></h2>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.line.SolidLine",
                        "layout": {
                            "width": 30,
                            "height": 5,
                            "x": 474,
                            "y": 149,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.FiveGridLine",
                        "props": {
                            "type": "FiveGridLineProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "fullScreenModeOn": false
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {"alpha-brd": "1", "brd": "#FFFFFF", "lnw": "1px"},
                                "propertiesSource": {"brd": "value", "lnw": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.line.SolidLine"
                        },
                        "mobileStructure": {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.line.SolidLine",
                            "layout": {
                                "width": 30,
                                "height": 5,
                                "x": 61,
                                "y": 113,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.FiveGridLine",
                            "props": {
                                "type": "FiveGridLineProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "fullScreenModeOn": false
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {"alpha-brd": "1", "brd": "#FFFFFF", "lnw": "1px"},
                                    "propertiesSource": {"brd": "value", "lnw": "value"}
                                },
                                "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.line.SolidLine"
                            }
                        }
                    }, {
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                        "layout": {
                            "width": 368,
                            "height": 246,
                            "x": 475,
                            "y": 187,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<p style=\"font-size: 28px; line-height: 1.3em;\" class=\"font_8\"><span style=\"letter-spacing:0.05em;\"><span class=\"color_11\"><span style=\"font-size:28px;\"><span style=\"font-family:libre baskerville,serif;\">“Share the amazing things customers are saying about your business. Double click, or click Edit Text to make it yours.”​</span></span></span></span></p><p class=\"font_8\">&nbsp;</p>",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                            "layout": {
                                "width": 198,
                                "height": 137,
                                "x": 61,
                                "y": 145,
                                "scale": 0.57,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p style=\"font-size: 28px; line-height: 1.3em;\" class=\"font_8\"><span style=\"letter-spacing:0.05em;\"><span class=\"color_11\"><span style=\"font-size:28px;\"><span style=\"font-family:libre baskerville,serif;\">“Share the amazing things customers are saying about your business. Double click, or click Edit Text to make it yours.”​</span></span></span></span></p><p class=\"font_8\">&nbsp;</p>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                        "layout": {
                            "width": 442,
                            "height": 22,
                            "x": 476,
                            "y": 470,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<p style=\"font-size: 16px;\" class=\"font_8\"><span class=\"color_11\"><span style=\"font-size:16px;\"><span style=\"letter-spacing:0.1em;\"><span style=\"font-style:italic;\"><span style=\"font-family:libre baskerville,serif;\">—&nbsp;&nbsp;Name, Title</span></span></span></span></span></p>",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                            "layout": {
                                "width": 234,
                                "height": 19,
                                "x": 61,
                                "y": 337,
                                "scale": 0.88,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p style=\"font-size: 16px;\" class=\"font_8\"><span class=\"color_11\"><span style=\"font-size:16px;\"><span style=\"letter-spacing:0.1em;\"><span style=\"font-style:italic;\"><span style=\"font-family:libre baskerville,serif;\">—&nbsp;&nbsp;Name, Title</span></span></span></span></span></p>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }],
                    "data": {
                        "type": "StripContainerSlideShowSlide",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "3"})
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {"bg": "#B6E8E3", "brd": "color_19", "brw": "0", "rd": "0px"},
                            "propertiesSource": {"bg": "value"}
                        },
                        "componentClassName": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "mediaRef": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "title": "Portrait of a Girl.jpg",
                                "uri": "035244_91e8a764844f40caae347e63cded8f97.jpg",
                                "description": "private/allMedia_picture",
                                "width": 7000,
                                "height": 2832,
                                "alt": "",
                                "artist": {"id": "", "name": ""},
                                "opacity": 0.4
                            },
                            "color": "#8E9918",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "{color_15}",
                            "colorOverlayOpacity": 0.21
                        }
                    },
                    "mobileStructure": {
                        "type": "Container",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                        "layout": {
                            "width": 320,
                            "height": 424,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                        "data": {
                            "type": "StripContainerSlideShowSlide",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "3"})
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {"bg": "#B6E8E3", "brd": "color_19", "brw": "0", "rd": "0px"},
                                "propertiesSource": {"bg": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "mediaRef": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "title": "Portrait of a Girl.jpg",
                                    "uri": "035244_91e8a764844f40caae347e63cded8f97.jpg",
                                    "description": "private/allMedia_picture",
                                    "width": 7000,
                                    "height": 2832,
                                    "alt": "",
                                    "artist": {"id": "", "name": ""},
                                    "opacity": 0.4
                                },
                                "color": "#8E9918",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "{color_15}",
                                "colorOverlayOpacity": 0.21
                            }
                        }
                    }
                }],
                "props": {
                    "type": "StripContainerSlideShowProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "transition": "SlideHorizontal",
                    "autoPlayInterval": 3,
                    "autoPlay": true,
                    "transDuration": 1,
                    "pauseAutoPlayOnMouseOver": true,
                    "direction": "RTL",
                    "shouldHideOverflowContent": true,
                    "flexibleBoxHeight": false,
                    "showNavigationButton": true,
                    "showNavigationDots": true,
                    "navigationButtonSize": 16,
                    "navigationButtonMargin": 100,
                    "navigationDotsSize": 6,
                    "navigationDotsMargin": 40,
                    "navigationDotsGap": 12,
                    "navigationDotsAlignment": "center"
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-arrowColor": "1",
                            "alpha-dotsColor": "1",
                            "arrowColor": "#2F2E2E",
                            "dotsColor": "#2F2E2E"
                        },
                        "propertiesSource": {
                            "alpha-arrowColor": "value",
                            "alpha-dotsColor": "value",
                            "arrowColor": "value",
                            "dotsColor": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.StripContainerSlideShow",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.common.components.stripSlideShow.viewer.skins.thinArrowsLargeSelectedCircleSkin"
                },
                "mobileStructure": {
                    "type": "Container",
                    "skin": "wysiwyg.common.components.stripSlideShow.viewer.skins.thinArrowsLargeSelectedCircleSkin",
                    "layout": {
                        "width": 320,
                        "height": 424,
                        "x": 0,
                        "y": 1696,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.StripContainerSlideShow",
                    "props": {
                        "type": "StripContainerSlideShowProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "transition": "SlideHorizontal",
                        "autoPlayInterval": 3,
                        "autoPlay": true,
                        "transDuration": 1,
                        "pauseAutoPlayOnMouseOver": true,
                        "direction": "RTL",
                        "shouldHideOverflowContent": true,
                        "flexibleBoxHeight": false,
                        "showNavigationButton": true,
                        "showNavigationDots": true,
                        "navigationButtonSize": 14,
                        "navigationButtonMargin": 20,
                        "navigationDotsSize": 6,
                        "navigationDotsMargin": 24,
                        "navigationDotsGap": 15,
                        "navigationDotsAlignment": "center"
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-arrowColor": "1",
                                "alpha-dotsColor": "1",
                                "arrowColor": "#2F2E2E",
                                "dotsColor": "#2F2E2E"
                            },
                            "propertiesSource": {
                                "alpha-arrowColor": "value",
                                "alpha-dotsColor": "value",
                                "arrowColor": "value",
                                "dotsColor": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.viewer.components.StripContainerSlideShow",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.stripSlideShow.viewer.skins.thinArrowsLargeSelectedCircleSkin"
                    }
                },
                "id": "comp-im0py3ah"
            },
            "preset": {
                "rect": {"width": 324, "height": 170.8, "x": -0.8, "y": 670.2},
                "label": "add_preset_StripSlideShow_5",
                "tags": null
            }
        }, {
            "id": "BoxSlideShow_StripBoxSlideShow_6",
            "structure": {
                "type": "Container",
                "skin": "wysiwyg.common.components.stripSlideShow.viewer.skins.squareButtonsSkin",
                "layout": {
                    "width": 980,
                    "height": 650,
                    "x": 0,
                    "y": 4303,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.StripContainerSlideShow",
                "components": [{
                    "type": "Container",
                    "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                    "layout": {
                        "width": 1421,
                        "height": 650,
                        "x": 0,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                    "components": [{
                        "type": "Container",
                        "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                        "layout": {
                            "width": 324,
                            "height": 423,
                            "x": 328,
                            "y": 114,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "mobile.core.components.Container",
                        "components": [{
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.line.SolidLine",
                            "layout": {
                                "width": 231,
                                "height": 5,
                                "x": 47,
                                "y": 88,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.FiveGridLine",
                            "props": {
                                "type": "FiveGridLineProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "fullScreenModeOn": false
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {"alpha-brd": "1", "brd": "#FFFFFF", "lnw": "1px"},
                                    "propertiesSource": {"brd": "value", "lnw": "value"}
                                },
                                "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.line.SolidLine"
                            },
                            "mobileStructure": {
                                "type": "Component",
                                "skin": "wysiwyg.viewer.skins.line.SolidLine",
                                "layout": {
                                    "width": 175,
                                    "height": 5,
                                    "x": 12,
                                    "y": 50,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.FiveGridLine",
                                "props": {
                                    "type": "FiveGridLineProperties",
                                    "metaData": {"schemaVersion": "1.0"},
                                    "fullScreenModeOn": false
                                },
                                "style": {
                                    "type": "TopLevelStyle",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "style": {
                                        "groups": {},
                                        "properties": {"alpha-brd": "1", "brd": "#FFFFFF", "lnw": "1px"},
                                        "propertiesSource": {"brd": "value", "lnw": "value"}
                                    },
                                    "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                                    "pageId": "",
                                    "compId": "",
                                    "styleType": "custom",
                                    "skin": "wysiwyg.viewer.skins.line.SolidLine"
                                }
                            }
                        }],
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "alpha-bg": "1",
                                    "alpha-brd": "1",
                                    "bg": "#2AD1BB",
                                    "boxShadowToggleOn-shd": "false",
                                    "brd": "#605E5E",
                                    "brw": "0px",
                                    "rd": "0px",
                                    "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                                },
                                "propertiesSource": {
                                    "bg": "value",
                                    "brd": "value",
                                    "brw": "value",
                                    "rd": "value",
                                    "shd": "value"
                                }
                            },
                            "componentClassName": "mobile.core.components.Container",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                        },
                        "mobileStructure": {
                            "type": "Container",
                            "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                            "layout": {
                                "width": 200,
                                "height": 318,
                                "x": 59,
                                "y": 52,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "mobile.core.components.Container",
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {
                                        "alpha-bg": "1",
                                        "alpha-brd": "1",
                                        "bg": "#2AD1BB",
                                        "boxShadowToggleOn-shd": "false",
                                        "brd": "#605E5E",
                                        "brw": "0px",
                                        "rd": "0px",
                                        "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                                    },
                                    "propertiesSource": {
                                        "bg": "value",
                                        "brd": "value",
                                        "brw": "value",
                                        "rd": "value",
                                        "shd": "value"
                                    }
                                },
                                "componentClassName": "mobile.core.components.Container",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                            }
                        }
                    }, {
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                        "layout": {
                            "width": 898,
                            "height": 19,
                            "x": 41,
                            "y": 171,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<h2 class=\"font_2\" style=\"font-size:14px; text-align:center\"><span style=\"font-family:libre baskerville,serif\"><span class=\"color_11\"><span style=\"letter-spacing:4.2px\">WHAT PEOPLE SAY</span></span></span></h2>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                            "layout": {
                                "width": 235,
                                "height": 20,
                                "x": 42,
                                "y": 69,
                                "scale": 0.9974999999999999,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 class=\"font_2\" style=\"font-size:14px; text-align:center\"><span style=\"font-family:libre baskerville,serif\"><span class=\"color_11\"><span style=\"letter-spacing:4.2px\">WHAT PEOPLE SAY</span></span></span></h2>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                        "layout": {
                            "width": 549,
                            "height": 190,
                            "x": 216,
                            "y": 258,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<p class=\"font_8\" style=\"font-size:28px; line-height:1.3em; text-align:center\"><span style=\"letter-spacing:0.05em\"><span style=\"font-family:libre baskerville,serif\"><span style=\"font-size:28px\"><span class=\"color_11\">&ldquo;Testimonials work great. Showing your reviews in quotes has a powerful effect on customers and makes them more likely to trust you.&rdquo;&nbsp;</span></span></span></span></p>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                            "layout": {
                                "width": 176,
                                "height": 147,
                                "x": 71,
                                "y": 140,
                                "scale": 0.57,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p class=\"font_8\" style=\"font-size:28px; line-height:1.3em; text-align:center\"><span style=\"letter-spacing:0.05em\"><span style=\"font-family:libre baskerville,serif\"><span style=\"font-size:28px\"><span class=\"color_11\">&ldquo;Testimonials work great. Showing your reviews in quotes has a powerful effect on customers and makes them more likely to trust you.&rdquo;&nbsp;</span></span></span></span></p>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                        "layout": {
                            "width": 898,
                            "height": 22,
                            "x": 41,
                            "y": 489,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<p style=\"text-align: center; font-size: 16px;\" class=\"font_8\"><span style=\"font-size:16px;\"><span style=\"letter-spacing:0.1em;\"><span class=\"color_11\"><span style=\"font-style:italic;\"><span style=\"font-family:libre baskerville,serif;\">—&nbsp;&nbsp;Name, Title</span></span></span></span></span></p>",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                            "layout": {
                                "width": 150,
                                "height": 22,
                                "x": 84,
                                "y": 327,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p style=\"text-align: center; font-size: 16px;\" class=\"font_8\"><span style=\"font-size:16px;\"><span style=\"letter-spacing:0.1em;\"><span class=\"color_11\"><span style=\"font-style:italic;\"><span style=\"font-family:libre baskerville,serif;\">—&nbsp;&nbsp;Name, Title</span></span></span></span></span></p>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }],
                    "data": {
                        "type": "StripContainerSlideShowSlide",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "1"})
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {"bg": "#B6E8E3", "brd": "color_19", "brw": "0", "rd": "0px"},
                            "propertiesSource": {"bg": "value"}
                        },
                        "componentClassName": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "color": "#2E2E2E",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "mobileStructure": {
                        "type": "Container",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                        "layout": {
                            "width": 320,
                            "height": 432,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                        "data": {
                            "type": "StripContainerSlideShowSlide",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "1"})
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {"bg": "#B6E8E3", "brd": "color_19", "brw": "0", "rd": "0px"},
                                "propertiesSource": {"bg": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "#2E2E2E",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            }
                        }
                    }
                }, {
                    "type": "Container",
                    "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                    "layout": {
                        "width": 1421,
                        "height": 650,
                        "x": 0,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                    "components": [{
                        "type": "Container",
                        "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                        "layout": {
                            "width": 324,
                            "height": 425,
                            "x": 328,
                            "y": 114,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "mobile.core.components.Container",
                        "components": [],
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "alpha-bg": "1",
                                    "alpha-brd": "1",
                                    "bg": "#FC8972",
                                    "boxShadowToggleOn-shd": "false",
                                    "brd": "#605E5E",
                                    "brw": "0px",
                                    "rd": "0px",
                                    "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                                },
                                "propertiesSource": {
                                    "bg": "value",
                                    "brd": "value",
                                    "brw": "value",
                                    "rd": "value",
                                    "shd": "value"
                                }
                            },
                            "componentClassName": "mobile.core.components.Container",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                        },
                        "mobileStructure": {
                            "type": "Container",
                            "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                            "layout": {
                                "width": 200,
                                "height": 318,
                                "x": 60,
                                "y": 53,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "mobile.core.components.Container",
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {
                                        "alpha-bg": "1",
                                        "alpha-brd": "1",
                                        "bg": "#FC8972",
                                        "boxShadowToggleOn-shd": "false",
                                        "brd": "#605E5E",
                                        "brw": "0px",
                                        "rd": "0px",
                                        "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                                    },
                                    "propertiesSource": {
                                        "bg": "value",
                                        "brd": "value",
                                        "brw": "value",
                                        "rd": "value",
                                        "shd": "value"
                                    }
                                },
                                "componentClassName": "mobile.core.components.Container",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                            }
                        }
                    }, {
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                        "layout": {
                            "width": 898,
                            "height": 19,
                            "x": 41,
                            "y": 171,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<h2 class=\"font_2\" style=\"font-size:14px; text-align:center\"><span class=\"color_11\"><span style=\"font-size:14px\"><span style=\"letter-spacing:0.3em\"><span style=\"font-family:libre baskerville,serif\">TITLE GOES HERE</span></span></span></span></h2>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                            "layout": {
                                "width": 278,
                                "height": 20,
                                "x": 21,
                                "y": 70,
                                "scale": 0.9974999999999999,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 class=\"font_2\" style=\"font-size:14px; text-align:center\"><span class=\"color_11\"><span style=\"font-size:14px\"><span style=\"letter-spacing:0.3em\"><span style=\"font-family:libre baskerville,serif\">TITLE GOES HERE</span></span></span></span></h2>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.line.SolidLine",
                        "layout": {
                            "width": 230,
                            "height": 5,
                            "x": 375,
                            "y": 203,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.FiveGridLine",
                        "props": {
                            "type": "FiveGridLineProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "fullScreenModeOn": false
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {"alpha-brd": "1", "brd": "#FFFFFF", "lnw": "1px"},
                                "propertiesSource": {"brd": "value", "lnw": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.line.SolidLine"
                        },
                        "mobileStructure": {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.line.SolidLine",
                            "layout": {
                                "width": 175,
                                "height": 5,
                                "x": 12,
                                "y": 49,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.FiveGridLine",
                            "props": {
                                "type": "FiveGridLineProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "fullScreenModeOn": false
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {"alpha-brd": "1", "brd": "#FFFFFF", "lnw": "1px"},
                                    "propertiesSource": {"brd": "value", "lnw": "value"}
                                },
                                "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.line.SolidLine"
                            }
                        }
                    }, {
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                        "layout": {
                            "width": 597,
                            "height": 152,
                            "x": 216,
                            "y": 258,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<p class=\"font_8\" style=\"font-size:28px; line-height:1.3em; text-align:center\"><span style=\"letter-spacing:0.05em\"><span style=\"font-family:libre baskerville,serif\"><span style=\"font-size:28px\"><span class=\"color_11\">&ldquo;Share the amazing things your customers are saying about you. Double click, or click Edit Text to make it yours.&rdquo;</span></span></span></span></p>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                            "layout": {
                                "width": 172,
                                "height": 147,
                                "x": 71,
                                "y": 140,
                                "scale": 0.568575,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p class=\"font_8\" style=\"font-size:28px; line-height:1.3em; text-align:center\"><span style=\"letter-spacing:0.05em\"><span style=\"font-family:libre baskerville,serif\"><span style=\"font-size:28px\"><span class=\"color_11\">&ldquo;Share the amazing things your customers are saying about you. Double click, or click Edit Text to make it yours.&rdquo;</span></span></span></span></p>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                        "layout": {
                            "width": 898,
                            "height": 22,
                            "x": 71,
                            "y": 140,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<p style=\"text-align: center; font-size: 16px;\" class=\"font_8\"><span style=\"font-size:16px;\"><span style=\"letter-spacing:0.1em;\"><span class=\"color_11\"><span style=\"font-style:italic;\"><span style=\"font-family:libre baskerville,serif;\">—&nbsp;&nbsp;Name, Title</span></span></span></span></span></p>",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                            "layout": {
                                "width": 149,
                                "height": 19,
                                "x": 84,
                                "y": 327,
                                "scale": 0.88,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p style=\"text-align: center; font-size: 16px;\" class=\"font_8\"><span style=\"font-size:16px;\"><span style=\"letter-spacing:0.1em;\"><span class=\"color_11\"><span style=\"font-style:italic;\"><span style=\"font-family:libre baskerville,serif;\">—&nbsp;&nbsp;Name, Title</span></span></span></span></span></p>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }],
                    "data": {
                        "type": "StripContainerSlideShowSlide",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "2"})
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {"bg": "#B6E8E3", "brd": "color_19", "brw": "0", "rd": "0px"},
                            "propertiesSource": {"bg": "value"}
                        },
                        "componentClassName": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "color": "#2E2E2E",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "mobileStructure": {
                        "type": "Container",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                        "layout": {
                            "width": 320,
                            "height": 432,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                        "data": {
                            "type": "StripContainerSlideShowSlide",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "2"})
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {"bg": "#B6E8E3", "brd": "color_19", "brw": "0", "rd": "0px"},
                                "propertiesSource": {"bg": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "#2E2E2E",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            }
                        }
                    }
                }, {
                    "type": "Container",
                    "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                    "layout": {
                        "width": 1421,
                        "height": 650,
                        "x": 0,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                    "components": [{
                        "type": "Container",
                        "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                        "layout": {
                            "width": 324,
                            "height": 423,
                            "x": 328,
                            "y": 114,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "mobile.core.components.Container",
                        "components": [],
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "alpha-bg": "1",
                                    "alpha-brd": "1",
                                    "bg": "#4660BD",
                                    "boxShadowToggleOn-shd": "false",
                                    "brd": "#605E5E",
                                    "brw": "0px",
                                    "rd": "0px",
                                    "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                                },
                                "propertiesSource": {
                                    "bg": "value",
                                    "brd": "value",
                                    "brw": "value",
                                    "rd": "value",
                                    "shd": "value"
                                }
                            },
                            "componentClassName": "mobile.core.components.Container",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                        },
                        "mobileStructure": {
                            "type": "Container",
                            "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                            "layout": {
                                "width": 200,
                                "height": 318,
                                "x": 60,
                                "y": 53,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "mobile.core.components.Container",
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {
                                        "alpha-bg": "1",
                                        "alpha-brd": "1",
                                        "bg": "#4660BD",
                                        "boxShadowToggleOn-shd": "false",
                                        "brd": "#605E5E",
                                        "brw": "0px",
                                        "rd": "0px",
                                        "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                                    },
                                    "propertiesSource": {
                                        "bg": "value",
                                        "brd": "value",
                                        "brw": "value",
                                        "rd": "value",
                                        "shd": "value"
                                    }
                                },
                                "componentClassName": "mobile.core.components.Container",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                            }
                        }
                    }, {
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                        "layout": {
                            "width": 898,
                            "height": 19,
                            "x": 41,
                            "y": 171,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<h2 style=\"font-size: 14px; text-align: center;\" class=\"font_2\"><span style=\"font-family: 'libre baskerville', serif;\"><span class=\"color_11\"><span style=\"letter-spacing: 4.2px;\">TITLE GOES HERE</span></span></span></h2>",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                            "layout": {
                                "width": 235,
                                "height": 20,
                                "x": 42,
                                "y": 69,
                                "scale": 0.9765000000000001,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 style=\"font-size: 14px; text-align: center;\" class=\"font_2\"><span style=\"font-family: 'libre baskerville', serif;\"><span class=\"color_11\"><span style=\"letter-spacing: 4.2px;\">TITLE GOES HERE</span></span></span></h2>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.line.SolidLine",
                        "layout": {
                            "width": 230,
                            "height": 5,
                            "x": 375,
                            "y": 202,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.FiveGridLine",
                        "props": {
                            "type": "FiveGridLineProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "fullScreenModeOn": false
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {"alpha-brd": "1", "brd": "#FFFFFF", "lnw": "1px"},
                                "propertiesSource": {"brd": "value", "lnw": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.line.SolidLine"
                        },
                        "mobileStructure": {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.line.SolidLine",
                            "layout": {
                                "width": 175,
                                "height": 5,
                                "x": 12,
                                "y": 49,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.FiveGridLine",
                            "props": {
                                "type": "FiveGridLineProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "fullScreenModeOn": false
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {"alpha-brd": "1", "brd": "#FFFFFF", "lnw": "1px"},
                                    "propertiesSource": {"brd": "value", "lnw": "value"}
                                },
                                "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.line.SolidLine"
                            }
                        }
                    }, {
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                        "layout": {
                            "width": 898,
                            "height": 22,
                            "x": 41,
                            "y": 485,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<p style=\"text-align: center; font-size: 16px;\" class=\"font_8\"><span style=\"font-size:16px;\"><span style=\"letter-spacing:0.1em;\"><span class=\"color_11\"><span style=\"font-style:italic;\"><span style=\"font-family:libre baskerville,serif;\">—&nbsp;&nbsp;Name, Title</span></span></span></span></span></p>",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                            "layout": {
                                "width": 182,
                                "height": 19,
                                "x": 84,
                                "y": 327,
                                "scale": 0.88,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p style=\"text-align: center; font-size: 16px;\" class=\"font_8\"><span style=\"font-size:16px;\"><span style=\"letter-spacing:0.1em;\"><span class=\"color_11\"><span style=\"font-style:italic;\"><span style=\"font-family:libre baskerville,serif;\">—&nbsp;&nbsp;Name, Title</span></span></span></span></span></p>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                        "layout": {
                            "width": 549,
                            "height": 170,
                            "x": 216,
                            "y": 258,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<p style=\"font-size: 28px; line-height: 1.3em; text-align: center;\" class=\"font_8\"><span style=\"letter-spacing:0.05em;\"><span style=\"font-family:libre baskerville,serif;\"><span style=\"font-size:28px;\"><span class=\"color_11\">“Share the amazing things your customers are saying about you. Double click, or click Edit Text to make it yours.”</span></span></span></span></p><p class=\"font_8\">&nbsp;</p>",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                            "layout": {
                                "width": 173,
                                "height": 158,
                                "x": 71,
                                "y": 140,
                                "scale": 0.57,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p style=\"font-size: 28px; line-height: 1.3em; text-align: center;\" class=\"font_8\"><span style=\"letter-spacing:0.05em;\"><span style=\"font-family:libre baskerville,serif;\"><span style=\"font-size:28px;\"><span class=\"color_11\">“Share the amazing things your customers are saying about you. Double click, or click Edit Text to make it yours.”</span></span></span></span></p><p class=\"font_8\">&nbsp;</p>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }],
                    "data": {
                        "type": "StripContainerSlideShowSlide",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "3"})
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {"bg": "#B6E8E3", "brd": "color_19", "brw": "0", "rd": "0px"},
                            "propertiesSource": {"bg": "value"}
                        },
                        "componentClassName": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "color": "#2E2E2E",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "mobileStructure": {
                        "type": "Container",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                        "layout": {
                            "width": 320,
                            "height": 432,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                        "data": {
                            "type": "StripContainerSlideShowSlide",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "3"})
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {"bg": "#B6E8E3", "brd": "color_19", "brw": "0", "rd": "0px"},
                                "propertiesSource": {"bg": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "#2E2E2E",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            }
                        }
                    }
                }],
                "props": {
                    "type": "StripContainerSlideShowProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "transition": "SlideHorizontal",
                    "autoPlayInterval": 3,
                    "autoPlay": true,
                    "transDuration": 1,
                    "pauseAutoPlayOnMouseOver": true,
                    "direction": "RTL",
                    "shouldHideOverflowContent": true,
                    "flexibleBoxHeight": false,
                    "showNavigationButton": true,
                    "showNavigationDots": true,
                    "navigationButtonSize": 16,
                    "navigationButtonMargin": 100,
                    "navigationDotsSize": 6,
                    "navigationDotsMargin": 40,
                    "navigationDotsGap": 12,
                    "navigationDotsAlignment": "center"
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {"alpha-arrowColor": "1", "alpha-dotsColor": "1"},
                        "propertiesSource": {
                            "alpha-arrowColor": "value",
                            "alpha-dotsColor": "value",
                            "arrowColor": "value",
                            "dotsColor": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.StripContainerSlideShow",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.common.components.stripSlideShow.viewer.skins.thinArrowsLargeSelectedCircleSkin"
                },
                "mobileStructure": {
                    "type": "Container",
                    "skin": "wysiwyg.common.components.stripSlideShow.viewer.skins.thinArrowsLargeSelectedCircleSkin",
                    "layout": {
                        "width": 320,
                        "height": 432,
                        "x": 0,
                        "y": 2190,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.StripContainerSlideShow",
                    "props": {
                        "type": "StripContainerSlideShowProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "transition": "SlideHorizontal",
                        "autoPlayInterval": 3,
                        "autoPlay": true,
                        "transDuration": 1,
                        "pauseAutoPlayOnMouseOver": true,
                        "direction": "RTL",
                        "shouldHideOverflowContent": true,
                        "flexibleBoxHeight": false,
                        "showNavigationButton": true,
                        "showNavigationDots": true,
                        "navigationButtonSize": 14,
                        "navigationButtonMargin": 20,
                        "navigationDotsSize": 6,
                        "navigationDotsMargin": 24,
                        "navigationDotsGap": 15,
                        "navigationDotsAlignment": "center"
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {"alpha-arrowColor": "1", "alpha-dotsColor": "1"},
                            "propertiesSource": {
                                "alpha-arrowColor": "value",
                                "alpha-dotsColor": "value",
                                "arrowColor": "value",
                                "dotsColor": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.viewer.components.StripContainerSlideShow",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.stripSlideShow.viewer.skins.thinArrowsLargeSelectedCircleSkin"
                    }
                },
                "id": "comp-im1p5di3"
            },
            "preset": {
                "rect": {"width": 324, "height": 168.20000000000002, "x": -0.8, "y": 841},
                "label": "add_preset_StripSlideShow_6",
                "tags": null
            }
        }, {
            "id": "BoxSlideShow_StripBoxSlideShow_7",
            "structure": {
                "type": "Container",
                "skin": "wysiwyg.common.components.stripSlideShow.viewer.skins.squareButtonsSkin",
                "layout": {
                    "width": 980,
                    "height": 640,
                    "x": 0,
                    "y": 5153,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.StripContainerSlideShow",
                "components": [{
                    "type": "Container",
                    "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                    "layout": {
                        "width": 1421,
                        "height": 640,
                        "x": 0,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                    "components": [{
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                        "layout": {
                            "width": 899,
                            "height": 42,
                            "x": 40,
                            "y": 201,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<h2 style=\"font-size: 32px; text-align: center;\" class=\"font_2\"><span style=\"font-size:32px;\"><span style=\"letter-spacing:0.3em;\"><span style=\"font-family:libre baskerville,serif;\"><span class=\"color_11\">YOUR NAME</span></span></span></span></h2>",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                            "layout": {
                                "width": 280,
                                "height": 23,
                                "x": 20,
                                "y": 77,
                                "scale": 0.63,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 style=\"font-size: 32px; text-align: center;\" class=\"font_2\"><span style=\"font-size:32px;\"><span style=\"letter-spacing:0.3em;\"><span style=\"font-family:libre baskerville,serif;\"><span class=\"color_11\">YOUR NAME</span></span></span></span></h2>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                        "layout": {
                            "width": 399,
                            "height": 90,
                            "x": 290,
                            "y": 271,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<p style=\"text-align: center; font-size: 22px; line-height: 1.3em;\" class=\"font_8\"><span style=\"font-size:22px;\"><span style=\"letter-spacing:0.03em;\"><span style=\"line-height:1.3em;\"><span style=\"font-family:brandon-grot-w01-light,sans-serif;\"><span class=\"color_11\">Tell customers more about you.</span></span></span></span></span></p><p style=\"text-align: center; font-size: 22px; line-height: 1.3em;\" class=\"font_8\"><span style=\"font-size:22px;\"><span style=\"letter-spacing:0.03em;\"><span style=\"line-height:1.3em;\"><span style=\"font-family:brandon-grot-w01-light,sans-serif;\"><span class=\"color_11\">Add a few words and a stunning pic to grab their attention and get them to click.</span></span></span></span></span></p>",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                            "layout": {
                                "width": 226,
                                "height": 96,
                                "x": 46,
                                "y": 125,
                                "scale": 0.77,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p style=\"text-align: center; font-size: 22px; line-height: 1.3em;\" class=\"font_8\"><span style=\"font-size:22px;\"><span style=\"letter-spacing:0.03em;\"><span style=\"line-height:1.3em;\"><span style=\"font-family:brandon-grot-w01-light,sans-serif;\"><span class=\"color_11\">Tell customers more about you.</span></span></span></span></span></p><p style=\"text-align: center; font-size: 22px; line-height: 1.3em;\" class=\"font_8\"><span style=\"font-size:22px;\"><span style=\"letter-spacing:0.03em;\"><span style=\"line-height:1.3em;\"><span style=\"font-family:brandon-grot-w01-light,sans-serif;\"><span class=\"color_11\">Add a few words and a stunning pic to grab their attention and get them to click.</span></span></span></span></span></p>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.button.BasicButton",
                        "layout": {
                            "width": 158,
                            "height": 44,
                            "x": 411,
                            "y": 409,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.SiteButton",
                        "data": {
                            "type": "LinkableButton",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "label": "LEARN MORE"
                        },
                        "props": {
                            "type": "ButtonProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "align": "center",
                            "margin": 0
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "alpha-bg": "0",
                                    "alpha-bgh": "1",
                                    "alpha-brd": "1",
                                    "alpha-brdh": "1",
                                    "alpha-txt": "1",
                                    "alpha-txth": "1",
                                    "bg": "#FFFFFF",
                                    "bgh": "#FFFFFF",
                                    "boxShadowToggleOn-shd": "false",
                                    "brd": "#FFFFFF",
                                    "brdh": "#FFFFFF",
                                    "brw": "1",
                                    "fnt": "normal normal normal 16px/1.4em brandon-grot-w01-light",
                                    "rd": "0px",
                                    "shd": "0 1px 4px rgba(0, 0, 0, 0.6);",
                                    "txt": "#FFFFFF",
                                    "txth": "#000000"
                                },
                                "propertiesSource": {
                                    "alpha-bg": "value",
                                    "bg": "value",
                                    "bgh": "value",
                                    "brd": "value",
                                    "brdh": "value",
                                    "brw": "value",
                                    "fnt": "value",
                                    "rd": "value",
                                    "shd": "value",
                                    "txt": "value",
                                    "txth": "value"
                                }
                            },
                            "componentClassName": "wysiwyg.viewer.components.SiteButton",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.button.BasicButton"
                        },
                        "mobileStructure": {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.button.BasicButton",
                            "layout": {
                                "width": 150,
                                "height": 40,
                                "x": 85,
                                "y": 258,
                                "scale": 0.7835261664684589,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.SiteButton",
                            "data": {
                                "type": "LinkableButton",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "label": "LEARN MORE"
                            },
                            "props": {
                                "type": "ButtonProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "align": "center",
                                "margin": 0
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {
                                        "alpha-bg": "0",
                                        "alpha-bgh": "1",
                                        "alpha-brd": "1",
                                        "alpha-brdh": "1",
                                        "alpha-txt": "1",
                                        "alpha-txth": "1",
                                        "bg": "#FFFFFF",
                                        "bgh": "#FFFFFF",
                                        "boxShadowToggleOn-shd": "false",
                                        "brd": "#FFFFFF",
                                        "brdh": "#FFFFFF",
                                        "brw": "1",
                                        "fnt": "normal normal normal 16px/1.4em brandon-grot-w01-light",
                                        "rd": "0px",
                                        "shd": "0 1px 4px rgba(0, 0, 0, 0.6);",
                                        "txt": "#FFFFFF",
                                        "txth": "#000000"
                                    },
                                    "propertiesSource": {
                                        "alpha-bg": "value",
                                        "bg": "value",
                                        "bgh": "value",
                                        "brd": "value",
                                        "brdh": "value",
                                        "brw": "value",
                                        "fnt": "value",
                                        "rd": "value",
                                        "shd": "value",
                                        "txt": "value",
                                        "txth": "value"
                                    }
                                },
                                "componentClassName": "wysiwyg.viewer.components.SiteButton",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.button.BasicButton"
                            }
                        }
                    }],
                    "data": {
                        "type": "StripContainerSlideShowSlide",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "1"})
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {"bg": "#B6E8E3", "brd": "color_19", "brw": "0", "rd": "0px"},
                            "propertiesSource": {"bg": "value"}
                        },
                        "componentClassName": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "mediaRef": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "title": "Small Green Plants.jpg",
                                "uri": "035244_5eaf5b4d35d946f994c2f0a4d0505b91.jpg",
                                "description": "private/",
                                "width": 5472,
                                "height": 3648,
                                "alt": "",
                                "artist": {"id": "mobile_mobile_", "name": ""},
                                "opacity": 0.8
                            },
                            "color": "{color_15}",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "#8B143C",
                            "colorOverlayOpacity": 0.15
                        }
                    },
                    "mobileStructure": {
                        "type": "Container",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                        "layout": {
                            "width": 320,
                            "height": 355,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                        "data": {
                            "type": "StripContainerSlideShowSlide",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "1"})
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {"bg": "#B6E8E3", "brd": "color_19", "brw": "0", "rd": "0px"},
                                "propertiesSource": {"bg": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "mediaRef": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "title": "Small Green Plants.jpg",
                                    "uri": "035244_5eaf5b4d35d946f994c2f0a4d0505b91.jpg",
                                    "description": "private/",
                                    "width": 5472,
                                    "height": 3648,
                                    "alt": "",
                                    "artist": {"id": "mobile_", "name": ""},
                                    "opacity": 0.8
                                },
                                "color": "{color_15}",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "#8B143C",
                                "colorOverlayOpacity": 0.15
                            }
                        }
                    }
                }, {
                    "type": "Container",
                    "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                    "layout": {
                        "width": 1421,
                        "height": 640,
                        "x": 0,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                    "components": [{
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                        "layout": {
                            "width": 899,
                            "height": 42,
                            "x": 40,
                            "y": 201,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<h2 style=\"font-size: 32px; text-align: center;\" class=\"font_2\"><span style=\"font-size:32px;\"><span style=\"letter-spacing:0.3em;\"><span style=\"font-family:libre baskerville,serif;\"><span class=\"color_11\">WHO YOU ARE</span></span></span></span></h2>",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                            "layout": {
                                "width": 280,
                                "height": 23,
                                "x": 20,
                                "y": 76,
                                "scale": 0.63,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 style=\"font-size: 32px; text-align: center;\" class=\"font_2\"><span style=\"font-size:32px;\"><span style=\"letter-spacing:0.3em;\"><span style=\"font-family:libre baskerville,serif;\"><span class=\"color_11\">WHO YOU ARE</span></span></span></span></h2>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                        "layout": {
                            "width": 399,
                            "height": 90,
                            "x": 290,
                            "y": 271,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<p style=\"text-align: center; font-size: 22px; line-height: 1.3em;\" class=\"font_8\"><span style=\"font-size:22px;\"><span style=\"letter-spacing:0.03em;\"><span style=\"line-height:1.3em;\"><span style=\"font-family:brandon-grot-w01-light,sans-serif;\"><span class=\"color_11\">Tell customers more about you.</span></span></span></span></span></p><p style=\"text-align: center; font-size: 22px; line-height: 1.3em;\" class=\"font_8\"><span style=\"font-size:22px;\"><span style=\"letter-spacing:0.03em;\"><span style=\"line-height:1.3em;\"><span style=\"font-family:brandon-grot-w01-light,sans-serif;\"><span class=\"color_11\">Add a few words and a stunning pic to grab their attention and get them to click.</span></span></span></span></span></p>",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                            "layout": {
                                "width": 231,
                                "height": 96,
                                "x": 42,
                                "y": 126,
                                "scale": 0.77,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p style=\"text-align: center; font-size: 22px; line-height: 1.3em;\" class=\"font_8\"><span style=\"font-size:22px;\"><span style=\"letter-spacing:0.03em;\"><span style=\"line-height:1.3em;\"><span style=\"font-family:brandon-grot-w01-light,sans-serif;\"><span class=\"color_11\">Tell customers more about you.</span></span></span></span></span></p><p style=\"text-align: center; font-size: 22px; line-height: 1.3em;\" class=\"font_8\"><span style=\"font-size:22px;\"><span style=\"letter-spacing:0.03em;\"><span style=\"line-height:1.3em;\"><span style=\"font-family:brandon-grot-w01-light,sans-serif;\"><span class=\"color_11\">Add a few words and a stunning pic to grab their attention and get them to click.</span></span></span></span></span></p>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.button.BasicButton",
                        "layout": {
                            "width": 158,
                            "height": 44,
                            "x": 411,
                            "y": 409,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.SiteButton",
                        "data": {
                            "type": "LinkableButton",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "label": "LEARN MORE"
                        },
                        "props": {
                            "type": "ButtonProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "align": "center",
                            "margin": 0
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "alpha-bg": "0",
                                    "alpha-bgh": "1",
                                    "alpha-brd": "1",
                                    "alpha-brdh": "1",
                                    "alpha-txt": "1",
                                    "alpha-txth": "1",
                                    "bg": "#FFFFFF",
                                    "bgh": "#FFFFFF",
                                    "boxShadowToggleOn-shd": "false",
                                    "brd": "#FFFFFF",
                                    "brdh": "#FFFFFF",
                                    "brw": "1",
                                    "fnt": "normal normal normal 16px/1.4em brandon-grot-w01-light",
                                    "rd": "0px",
                                    "shd": "0 1px 4px rgba(0, 0, 0, 0.6);",
                                    "txt": "#FFFFFF",
                                    "txth": "#000000"
                                },
                                "propertiesSource": {
                                    "alpha-bg": "value",
                                    "bg": "value",
                                    "bgh": "value",
                                    "brd": "value",
                                    "brdh": "value",
                                    "brw": "value",
                                    "fnt": "value",
                                    "rd": "value",
                                    "shd": "value",
                                    "txt": "value",
                                    "txth": "value"
                                }
                            },
                            "componentClassName": "wysiwyg.viewer.components.SiteButton",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.button.BasicButton"
                        },
                        "mobileStructure": {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.button.BasicButton",
                            "layout": {
                                "width": 150,
                                "height": 40,
                                "x": 85,
                                "y": 259,
                                "scale": 0.863837598531476,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.SiteButton",
                            "data": {
                                "type": "LinkableButton",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "label": "LEARN MORE"
                            },
                            "props": {
                                "type": "ButtonProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "align": "center",
                                "margin": 0
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {
                                        "alpha-bg": "0",
                                        "alpha-bgh": "1",
                                        "alpha-brd": "1",
                                        "alpha-brdh": "1",
                                        "alpha-txt": "1",
                                        "alpha-txth": "1",
                                        "bg": "#FFFFFF",
                                        "bgh": "#FFFFFF",
                                        "boxShadowToggleOn-shd": "false",
                                        "brd": "#FFFFFF",
                                        "brdh": "#FFFFFF",
                                        "brw": "1",
                                        "fnt": "normal normal normal 16px/1.4em brandon-grot-w01-light",
                                        "rd": "0px",
                                        "shd": "0 1px 4px rgba(0, 0, 0, 0.6);",
                                        "txt": "#FFFFFF",
                                        "txth": "#000000"
                                    },
                                    "propertiesSource": {
                                        "alpha-bg": "value",
                                        "bg": "value",
                                        "bgh": "value",
                                        "brd": "value",
                                        "brdh": "value",
                                        "brw": "value",
                                        "fnt": "value",
                                        "rd": "value",
                                        "shd": "value",
                                        "txt": "value",
                                        "txth": "value"
                                    }
                                },
                                "componentClassName": "wysiwyg.viewer.components.SiteButton",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.button.BasicButton"
                            }
                        }
                    }],
                    "data": {
                        "type": "StripContainerSlideShowSlide",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "2"})
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {"bg": "#B6E8E3", "brd": "color_19", "brw": "0", "rd": "0px"},
                            "propertiesSource": {"bg": "value"}
                        },
                        "componentClassName": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "mediaRef": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "title": "Cactus Collection.jpg",
                                "uri": "035244_bce13ef5af464c32a75c4112cbb1f028.jpg",
                                "description": "private/allMedia_picture",
                                "width": 5472,
                                "height": 3648,
                                "alt": "",
                                "artist": {"id": "mobile_mobile_", "name": ""},
                                "opacity": 0.8
                            },
                            "color": "#000000",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "{color_15}",
                            "colorOverlayOpacity": 0.21
                        }
                    },
                    "mobileStructure": {
                        "type": "Container",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                        "layout": {
                            "width": 320,
                            "height": 355,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                        "data": {
                            "type": "StripContainerSlideShowSlide",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "2"})
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {"bg": "#B6E8E3", "brd": "color_19", "brw": "0", "rd": "0px"},
                                "propertiesSource": {"bg": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "mediaRef": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "title": "Cactus Collection.jpg",
                                    "uri": "035244_bce13ef5af464c32a75c4112cbb1f028.jpg",
                                    "description": "private/allMedia_picture",
                                    "width": 5472,
                                    "height": 3648,
                                    "alt": "",
                                    "artist": {"id": "mobile_", "name": ""},
                                    "opacity": 0.7
                                },
                                "color": "#000000",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "{color_15}",
                                "colorOverlayOpacity": 0.21
                            }
                        }
                    }
                }, {
                    "type": "Container",
                    "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                    "layout": {
                        "width": 1421,
                        "height": 640,
                        "x": 0,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                    "components": [{
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                        "layout": {
                            "width": 899,
                            "height": 42,
                            "x": 40,
                            "y": 201,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<h2 style=\"font-size: 32px; text-align: center;\" class=\"font_2\"><span style=\"font-size:32px;\"><span style=\"letter-spacing:0.3em;\"><span style=\"font-family:libre baskerville,serif;\"><span class=\"color_11\">WHAT YOU DO</span></span></span></span></h2>",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                            "layout": {
                                "width": 280,
                                "height": 23,
                                "x": 20,
                                "y": 77,
                                "scale": 0.63,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 style=\"font-size: 32px; text-align: center;\" class=\"font_2\"><span style=\"font-size:32px;\"><span style=\"letter-spacing:0.3em;\"><span style=\"font-family:libre baskerville,serif;\"><span class=\"color_11\">WHAT YOU DO</span></span></span></span></h2>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                        "layout": {
                            "width": 399,
                            "height": 90,
                            "x": 290,
                            "y": 271,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<p style=\"text-align: center; font-size: 22px; line-height: 1.3em;\" class=\"font_8\"><span style=\"font-size:22px;\"><span style=\"letter-spacing:0.03em;\"><span style=\"line-height:1.3em;\"><span style=\"font-family:brandon-grot-w01-light,sans-serif;\"><span class=\"color_11\">Tell customers more about you.</span></span></span></span></span></p><p style=\"text-align: center; font-size: 22px; line-height: 1.3em;\" class=\"font_8\"><span style=\"font-size:22px;\"><span style=\"letter-spacing:0.03em;\"><span style=\"line-height:1.3em;\"><span style=\"font-family:brandon-grot-w01-light,sans-serif;\"><span class=\"color_11\">Add a few words and a stunning pic to grab their attention and get them to click.</span></span></span></span></span></p>",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                            "layout": {
                                "width": 226,
                                "height": 96,
                                "x": 47,
                                "y": 126,
                                "scale": 0.77,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p style=\"text-align: center; font-size: 22px; line-height: 1.3em;\" class=\"font_8\"><span style=\"font-size:22px;\"><span style=\"letter-spacing:0.03em;\"><span style=\"line-height:1.3em;\"><span style=\"font-family:brandon-grot-w01-light,sans-serif;\"><span class=\"color_11\">Tell customers more about you.</span></span></span></span></span></p><p style=\"text-align: center; font-size: 22px; line-height: 1.3em;\" class=\"font_8\"><span style=\"font-size:22px;\"><span style=\"letter-spacing:0.03em;\"><span style=\"line-height:1.3em;\"><span style=\"font-family:brandon-grot-w01-light,sans-serif;\"><span class=\"color_11\">Add a few words and a stunning pic to grab their attention and get them to click.</span></span></span></span></span></p>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.button.BasicButton",
                        "layout": {
                            "width": 158,
                            "height": 44,
                            "x": 411,
                            "y": 409,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.SiteButton",
                        "data": {
                            "type": "LinkableButton",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "label": "LEARN MORE"
                        },
                        "props": {
                            "type": "ButtonProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "align": "center",
                            "margin": 0
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "alpha-bg": "0",
                                    "alpha-bgh": "1",
                                    "alpha-brd": "1",
                                    "alpha-brdh": "1",
                                    "alpha-txt": "1",
                                    "alpha-txth": "1",
                                    "bg": "#FFFFFF",
                                    "bgh": "#FFFFFF",
                                    "boxShadowToggleOn-shd": "false",
                                    "brd": "#FFFFFF",
                                    "brdh": "#FFFFFF",
                                    "brw": "1",
                                    "fnt": "normal normal normal 16px/1.4em brandon-grot-w01-light",
                                    "rd": "0px",
                                    "shd": "0 1px 4px rgba(0, 0, 0, 0.6);",
                                    "txt": "#FFFFFF",
                                    "txth": "#000000"
                                },
                                "propertiesSource": {
                                    "alpha-bg": "value",
                                    "bg": "value",
                                    "bgh": "value",
                                    "brd": "value",
                                    "brdh": "value",
                                    "brw": "value",
                                    "fnt": "value",
                                    "rd": "value",
                                    "shd": "value",
                                    "txt": "value",
                                    "txth": "value"
                                }
                            },
                            "componentClassName": "wysiwyg.viewer.components.SiteButton",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.button.BasicButton"
                        },
                        "mobileStructure": {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.button.BasicButton",
                            "layout": {
                                "width": 150,
                                "height": 40,
                                "x": 80,
                                "y": 258,
                                "scale": 0.863837598531476,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.SiteButton",
                            "data": {
                                "type": "LinkableButton",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "label": "LEARN MORE"
                            },
                            "props": {
                                "type": "ButtonProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "align": "center",
                                "margin": 0
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {
                                        "alpha-bg": "0",
                                        "alpha-bgh": "1",
                                        "alpha-brd": "1",
                                        "alpha-brdh": "1",
                                        "alpha-txt": "1",
                                        "alpha-txth": "1",
                                        "bg": "#FFFFFF",
                                        "bgh": "#FFFFFF",
                                        "boxShadowToggleOn-shd": "false",
                                        "brd": "#FFFFFF",
                                        "brdh": "#FFFFFF",
                                        "brw": "1",
                                        "fnt": "normal normal normal 16px/1.4em brandon-grot-w01-light",
                                        "rd": "0px",
                                        "shd": "0 1px 4px rgba(0, 0, 0, 0.6);",
                                        "txt": "#FFFFFF",
                                        "txth": "#000000"
                                    },
                                    "propertiesSource": {
                                        "alpha-bg": "value",
                                        "bg": "value",
                                        "bgh": "value",
                                        "brd": "value",
                                        "brdh": "value",
                                        "brw": "value",
                                        "fnt": "value",
                                        "rd": "value",
                                        "shd": "value",
                                        "txt": "value",
                                        "txth": "value"
                                    }
                                },
                                "componentClassName": "wysiwyg.viewer.components.SiteButton",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.button.BasicButton"
                            }
                        }
                    }],
                    "data": {
                        "type": "StripContainerSlideShowSlide",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "3"})
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {"bg": "#B6E8E3", "brd": "color_19", "brw": "0", "rd": "0px"},
                            "propertiesSource": {"bg": "value"}
                        },
                        "componentClassName": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "mediaRef": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "title": "Plant Shed.jpg",
                                "uri": "035244_114ac78bc54a4ff794f712edaeda400d.jpg",
                                "description": "private/allMedia_picture",
                                "width": 5472,
                                "height": 3648,
                                "alt": "",
                                "artist": {"id": "mobile_mobile_", "name": ""},
                                "opacity": 0.8
                            },
                            "color": "{color_15}",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "{color_15}",
                            "colorOverlayOpacity": 0.21
                        }
                    },
                    "mobileStructure": {
                        "type": "Container",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                        "layout": {
                            "width": 320,
                            "height": 355,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                        "data": {
                            "type": "StripContainerSlideShowSlide",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "3"})
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {"bg": "#B6E8E3", "brd": "color_19", "brw": "0", "rd": "0px"},
                                "propertiesSource": {"bg": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "mediaRef": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "title": "Plant Shed.jpg",
                                    "uri": "035244_114ac78bc54a4ff794f712edaeda400d.jpg",
                                    "description": "private/allMedia_picture",
                                    "width": 5472,
                                    "height": 3648,
                                    "alt": "",
                                    "artist": {"id": "mobile_", "name": ""},
                                    "opacity": 0.7
                                },
                                "color": "{color_15}",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "{color_15}",
                                "colorOverlayOpacity": 0.21
                            }
                        }
                    }
                }],
                "props": {
                    "type": "StripContainerSlideShowProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "transition": "SlideHorizontal",
                    "autoPlayInterval": 3,
                    "autoPlay": true,
                    "transDuration": 1,
                    "pauseAutoPlayOnMouseOver": true,
                    "direction": "RTL",
                    "shouldHideOverflowContent": true,
                    "flexibleBoxHeight": false,
                    "showNavigationButton": true,
                    "showNavigationDots": true,
                    "navigationButtonSize": 20,
                    "navigationButtonMargin": 100,
                    "navigationDotsSize": 6,
                    "navigationDotsMargin": 40,
                    "navigationDotsGap": 12,
                    "navigationDotsAlignment": "center"
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {"alpha-arrowColor": "1", "alpha-dotsColor": "1"},
                        "propertiesSource": {
                            "alpha-arrowColor": "value",
                            "alpha-dotsColor": "value",
                            "arrowColor": "value",
                            "dotsColor": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.StripContainerSlideShow",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.common.components.stripSlideShow.viewer.skins.thinArrowsLargeSelectedCircleSkin"
                },
                "mobileStructure": {
                    "type": "Container",
                    "skin": "wysiwyg.common.components.stripSlideShow.viewer.skins.thinArrowsLargeSelectedCircleSkin",
                    "layout": {
                        "width": 320,
                        "height": 355,
                        "x": 0,
                        "y": 2697,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.StripContainerSlideShow",
                    "props": {
                        "type": "StripContainerSlideShowProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "transition": "SlideHorizontal",
                        "autoPlayInterval": 3,
                        "autoPlay": true,
                        "transDuration": 1,
                        "pauseAutoPlayOnMouseOver": true,
                        "direction": "RTL",
                        "shouldHideOverflowContent": true,
                        "flexibleBoxHeight": false,
                        "showNavigationButton": true,
                        "showNavigationDots": true,
                        "navigationButtonSize": 14,
                        "navigationButtonMargin": 20,
                        "navigationDotsSize": 6,
                        "navigationDotsMargin": 24,
                        "navigationDotsGap": 15,
                        "navigationDotsAlignment": "center"
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {"alpha-arrowColor": "1", "alpha-dotsColor": "1"},
                            "propertiesSource": {
                                "alpha-arrowColor": "value",
                                "alpha-dotsColor": "value",
                                "arrowColor": "value",
                                "dotsColor": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.viewer.components.StripContainerSlideShow",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.stripSlideShow.viewer.skins.thinArrowsLargeSelectedCircleSkin"
                    }
                },
                "id": "comp-im0kf0vb"
            },
            "preset": {
                "rect": {"width": 324, "height": 170.8, "x": -0.8, "y": 1009.2},
                "label": "add_preset_StripSlideShow_7",
                "tags": null
            }
        }, {
            "id": "BoxSlideShow_StripBoxSlideShow_8",
            "structure": {
                "type": "Container",
                "skin": "wysiwyg.common.components.stripSlideShow.viewer.skins.squareButtonsSkin",
                "layout": {
                    "width": 980,
                    "height": 650,
                    "x": 0,
                    "y": 5993,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.StripContainerSlideShow",
                "components": [{
                    "type": "Container",
                    "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                    "layout": {
                        "width": 1421,
                        "height": 650,
                        "x": 0,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                    "components": [{
                        "type": "Component",
                        "layout": {
                            "width": 899,
                            "height": 130,
                            "x": 40,
                            "y": 259,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<h2 class=\"font_2\" style=\"font-size:60px; line-height:1em; text-align:center\"><span style=\"font-size:60px\"><span style=\"letter-spacing:0.02em\"><span><span style=\"font-family:times new roman,times,serif\"><span style=\"color:#FFF000;\">Make</span></span></span></span></span></h2>\n\n<h2 class=\"font_2\" style=\"font-size:60px; line-height:1em; text-align:center\"><span style=\"font-size:60px\"><span style=\"letter-spacing:0.02em\"><span><span style=\"font-family:times new roman,times,serif\"><span class=\"color_11\">Things Happen</span><span style=\"color:#FFF000\">.</span></span></span></span></span></h2>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 201,
                                "height": 184,
                                "x": 59,
                                "y": 137,
                                "scale": 1.1313894853939424,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 class=\"font_2\" style=\"font-size:60px; line-height:1em; text-align:center\"><span style=\"font-size:60px\"><span style=\"letter-spacing:0.02em\"><span><span style=\"font-family:times new roman,times,serif\"><span style=\"color:#FFF000;\">Make</span></span></span></span></span></h2>\n\n<h2 class=\"font_2\" style=\"font-size:60px; line-height:1em; text-align:center\"><span style=\"font-size:60px\"><span style=\"letter-spacing:0.02em\"><span><span style=\"font-family:times new roman,times,serif\"><span class=\"color_11\">Things Happen</span><span style=\"color:#FFF000\">.</span></span></span></span></span></h2>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }],
                    "data": {
                        "type": "StripContainerSlideShowSlide",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "1"})
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {"bg": "#B6E8E3", "brd": "color_19", "brw": "0", "rd": "0px"},
                            "propertiesSource": {"bg": "value"}
                        },
                        "componentClassName": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "mediaRef": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "title": "black-and-white-city-skyline-buildings.jpg",
                                "uri": "035244_325d75fa2d474a02b1315b32a0a19bae.jpg",
                                "description": "private/allMedia_picture",
                                "width": 2560,
                                "height": 1440,
                                "alt": "",
                                "artist": {
                                    "id": "mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_",
                                    "name": ""
                                },
                                "opacity": 0.25
                            },
                            "color": "#3744AD",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "#8B143C",
                            "colorOverlayOpacity": 0.15
                        }
                    },
                    "mobileStructure": {
                        "type": "Container",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                        "layout": {
                            "width": 320,
                            "height": 412,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                        "data": {
                            "type": "StripContainerSlideShowSlide",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "1"})
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {"bg": "#B6E8E3", "brd": "color_19", "brw": "0", "rd": "0px"},
                                "propertiesSource": {"bg": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "mediaRef": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "title": "black-and-white-city-skyline-buildings.jpg",
                                    "uri": "035244_325d75fa2d474a02b1315b32a0a19bae.jpg",
                                    "description": "private/allMedia_picture",
                                    "width": 2560,
                                    "height": 1440,
                                    "alt": "",
                                    "artist": {
                                        "id": "mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_",
                                        "name": ""
                                    },
                                    "opacity": 0.25
                                },
                                "color": "#3744AD",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "#8B143C",
                                "colorOverlayOpacity": 0.15
                            }
                        }
                    }
                }, {
                    "type": "Container",
                    "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                    "layout": {
                        "width": 1421,
                        "height": 650,
                        "x": 0,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                    "components": [{
                        "type": "Component",
                        "layout": {
                            "width": 899,
                            "height": 65,
                            "x": 40,
                            "y": 295,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<h2 class=\"font_2\" style=\"font-size:60px; line-height:1em; text-align:center\"><span style=\"font-family:times new roman,times,serif\"><span style=\"color:#FFFFFF\"><span style=\"letter-spacing:1.2px\">Tell</span></span><span class=\"color_11\"><span style=\"letter-spacing:1.2px\"> </span></span><span style=\"color:#FFF000;\"><span style=\"letter-spacing:1.2px\">Your</span></span><span class=\"color_11\"><span style=\"letter-spacing:1.2px\"> </span></span><span style=\"color:#FFFFFF\"><span style=\"letter-spacing:1.2px\">Story</span></span><span style=\"color:#FFF000\"><span style=\"letter-spacing:1.2px\">.</span></span></span></h2>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 230,
                                "height": 86,
                                "x": 45,
                                "y": 171,
                                "scale": 1.08,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 class=\"font_2\" style=\"font-size:60px; line-height:1em; text-align:center\"><span style=\"font-family:times new roman,times,serif\"><span style=\"color:#FFFFFF\"><span style=\"letter-spacing:1.2px\">Tell</span></span><span class=\"color_11\"><span style=\"letter-spacing:1.2px\"> </span></span><span style=\"color:#FFF000;\"><span style=\"letter-spacing:1.2px\">Your</span></span><span class=\"color_11\"><span style=\"letter-spacing:1.2px\"> </span></span><span style=\"color:#FFFFFF\"><span style=\"letter-spacing:1.2px\">Story</span></span><span style=\"color:#FFF000\"><span style=\"letter-spacing:1.2px\">.</span></span></span></h2>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }],
                    "data": {
                        "type": "StripContainerSlideShowSlide",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "2"})
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {"bg": "#B6E8E3", "brd": "color_19", "brw": "0", "rd": "0px"},
                            "propertiesSource": {"bg": "value"}
                        },
                        "componentClassName": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "mediaRef": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "title": "fold3-original.jpg",
                                "uri": "035244_d78649e9e3a64eeaa5fc004f5ace077a.jpg",
                                "description": "private/allMedia_picture",
                                "width": 5500,
                                "height": 3667,
                                "alt": "",
                                "artist": {
                                    "id": "mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_",
                                    "name": ""
                                },
                                "opacity": 0.85
                            },
                            "color": "#1F2663",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "{color_15}",
                            "colorOverlayOpacity": 0.21
                        }
                    },
                    "mobileStructure": {
                        "type": "Container",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                        "layout": {
                            "width": 320,
                            "height": 412,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                        "data": {
                            "type": "StripContainerSlideShowSlide",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "2"})
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {"bg": "#B6E8E3", "brd": "color_19", "brw": "0", "rd": "0px"},
                                "propertiesSource": {"bg": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "mediaRef": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "title": "fold3-original.jpg",
                                    "uri": "035244_d78649e9e3a64eeaa5fc004f5ace077a.jpg",
                                    "description": "private/allMedia_picture",
                                    "width": 5500,
                                    "height": 3667,
                                    "alt": "",
                                    "artist": {
                                        "id": "mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_",
                                        "name": ""
                                    },
                                    "opacity": 0.85
                                },
                                "color": "#1F2663",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "{color_15}",
                                "colorOverlayOpacity": 0.21
                            }
                        }
                    }
                }, {
                    "type": "Container",
                    "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                    "layout": {
                        "width": 1421,
                        "height": 650,
                        "x": 0,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                    "components": [{
                        "type": "Component",
                        "layout": {
                            "width": 899,
                            "height": 65,
                            "x": 40,
                            "y": 278,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<h2 class=\"font_2\" style=\"font-size:60px; line-height:1em; text-align:center\"><span style=\"font-family:times new roman,times,serif\"><span style=\"letter-spacing:1.2px\"><span style=\"color:#FFF000;\">Make</span><span style=\"color:#FFFFFF\"> Things Happen</span><span style=\"color:rgb(255, 240, 0)\">.</span></span></span></h2>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 201,
                                "height": 138,
                                "x": 59,
                                "y": 160,
                                "scale": 1.13,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 class=\"font_2\" style=\"font-size:60px; line-height:1em; text-align:center\"><span style=\"font-family:times new roman,times,serif\"><span style=\"letter-spacing:1.2px\"><span style=\"color:#FFF000;\">Make</span><span style=\"color:#FFFFFF\"> Things Happen</span><span style=\"color:rgb(255, 240, 0)\">.</span></span></span></h2>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }],
                    "data": {
                        "type": "StripContainerSlideShowSlide",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "3"})
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {"bg": "#B6E8E3", "brd": "color_19", "brw": "0", "rd": "0px"},
                            "propertiesSource": {"bg": "value"}
                        },
                        "componentClassName": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "mediaRef": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "title": "photo-1430165558479-de3cf8cf1478.jpeg",
                                "uri": "035244_5bf357cd7b9e451dade2e1d74dc1e40d.jpeg",
                                "description": "private/",
                                "width": 1080,
                                "height": 720,
                                "alt": "",
                                "artist": {"id": "mobile_mobile_mobile_mobile_mobile_mobile_", "name": ""},
                                "opacity": 0.85
                            },
                            "color": "#1F2663",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "{color_15}",
                            "colorOverlayOpacity": 0.21
                        }
                    },
                    "mobileStructure": {
                        "type": "Container",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                        "layout": {
                            "width": 320,
                            "height": 412,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                        "data": {
                            "type": "StripContainerSlideShowSlide",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "3"})
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {"bg": "#B6E8E3", "brd": "color_19", "brw": "0", "rd": "0px"},
                                "propertiesSource": {"bg": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "mediaRef": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "title": "photo-1430165558479-de3cf8cf1478.jpeg",
                                    "uri": "035244_5bf357cd7b9e451dade2e1d74dc1e40d.jpeg",
                                    "description": "private/",
                                    "width": 1080,
                                    "height": 720,
                                    "alt": "",
                                    "artist": {"id": "mobile_mobile_mobile_mobile_mobile_", "name": ""},
                                    "opacity": 0.85
                                },
                                "color": "#1F2663",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "{color_15}",
                                "colorOverlayOpacity": 0.21
                            }
                        }
                    }
                }],
                "props": {
                    "type": "StripContainerSlideShowProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "transition": "SlideHorizontal",
                    "autoPlayInterval": 3,
                    "autoPlay": true,
                    "transDuration": 1,
                    "pauseAutoPlayOnMouseOver": true,
                    "direction": "RTL",
                    "shouldHideOverflowContent": true,
                    "flexibleBoxHeight": false,
                    "showNavigationButton": true,
                    "showNavigationDots": true,
                    "navigationButtonSize": 22,
                    "navigationButtonMargin": 100,
                    "navigationDotsSize": 6,
                    "navigationDotsMargin": 40,
                    "navigationDotsGap": 12,
                    "navigationDotsAlignment": "center"
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {"alpha-arrowColor": "1", "alpha-dotsColor": "1"},
                        "propertiesSource": {
                            "alpha-arrowColor": "value",
                            "alpha-dotsColor": "value",
                            "arrowColor": "value",
                            "dotsColor": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.StripContainerSlideShow",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.common.components.stripSlideShow.viewer.skins.thinArrowsLargeSelectedCircleSkin"
                },
                "mobileStructure": {
                    "type": "Container",
                    "skin": "wysiwyg.common.components.stripSlideShow.viewer.skins.thinArrowsLargeSelectedCircleSkin",
                    "layout": {
                        "width": 320,
                        "height": 412,
                        "x": 0,
                        "y": 3128,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.StripContainerSlideShow",
                    "props": {
                        "type": "StripContainerSlideShowProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "transition": "SlideHorizontal",
                        "autoPlayInterval": 3,
                        "autoPlay": true,
                        "transDuration": 1,
                        "pauseAutoPlayOnMouseOver": true,
                        "direction": "RTL",
                        "shouldHideOverflowContent": true,
                        "flexibleBoxHeight": false,
                        "showNavigationButton": true,
                        "showNavigationDots": true,
                        "navigationButtonSize": 14,
                        "navigationButtonMargin": 20,
                        "navigationDotsSize": 6,
                        "navigationDotsMargin": 24,
                        "navigationDotsGap": 15,
                        "navigationDotsAlignment": "center"
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {"alpha-arrowColor": "1", "alpha-dotsColor": "1"},
                            "propertiesSource": {
                                "alpha-arrowColor": "value",
                                "alpha-dotsColor": "value",
                                "arrowColor": "value",
                                "dotsColor": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.viewer.components.StripContainerSlideShow",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.stripSlideShow.viewer.skins.thinArrowsLargeSelectedCircleSkin"
                    }
                },
                "id": "comp-im2140wq"
            },
            "preset": {
                "rect": {"width": 324, "height": 168.20000000000002, "x": -0.8, "y": 1180},
                "label": "add_preset_StripSlideShow_8",
                "tags": null
            }
        }, {
            "id": "BoxSlideShow_StripBoxSlideShow_9",
            "structure": {
                "type": "Container",
                "skin": "wysiwyg.common.components.stripSlideShow.viewer.skins.thinArrowsLargeSelectedCircleSkin",
                "layout": {
                    "width": 980,
                    "height": 651,
                    "x": 0,
                    "y": 6843,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.StripContainerSlideShow",
                "components": [{
                    "type": "Container",
                    "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                    "layout": {
                        "width": 1421,
                        "height": 651,
                        "x": 0,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                    "components": [{
                        "type": "Component",
                        "layout": {
                            "width": 863,
                            "height": 99,
                            "x": 77,
                            "y": 266,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<h2 style=\"font-size: 120px; text-align: center; line-height: 0.7em;\" class=\"font_2\"><span style=\"font-size:120px;\"><span style=\"line-height:0.7em;\"><span style=\"color:#FCFF1B;\"><span style=\"font-family:futura-lt-w01-book,sans-serif;\">SALE</span></span></span></span></h2>",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 278,
                                "height": 71,
                                "x": 20,
                                "y": 105,
                                "scale": 1.7116810849027486,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 style=\"font-size: 120px; text-align: center; line-height: 0.7em;\" class=\"font_2\"><span style=\"font-size:120px;\"><span style=\"line-height:0.7em;\"><span style=\"color:#FCFF1B;\"><span style=\"font-family:futura-lt-w01-book,sans-serif;\">SALE</span></span></span></span></h2>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 762,
                            "height": 77,
                            "x": 130,
                            "y": 185,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<h2 class=\"font_2\" style=\"font-size:55px; text-align:center\"><span style=\"color:#FCFF1B;\"><span style=\"font-family:futura-lt-w01-book,sans-serif\"><span style=\"font-size:55px\"><span style=\"letter-spacing:0.07em\">SUMMER</span></span></span></span></h2>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 280,
                                "height": 57,
                                "x": 20,
                                "y": 44,
                                "scale": 1.1767807458706399,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 class=\"font_2\" style=\"font-size:55px; text-align:center\"><span style=\"color:#FCFF1B;\"><span style=\"font-family:futura-lt-w01-book,sans-serif\"><span style=\"font-size:55px\"><span style=\"letter-spacing:0.07em\">SUMMER</span></span></span></span></h2>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Container",
                        "layout": {
                            "width": 280,
                            "height": 87,
                            "x": 371,
                            "y": 376,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "mobile.core.components.Container",
                        "components": [],
                        "style": "c4",
                        "mobileStructure": {
                            "type": "Container",
                            "layout": {
                                "width": 201,
                                "height": 60,
                                "x": 59,
                                "y": 268,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "mobile.core.components.Container",
                            "style": "c4"
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 859,
                            "height": 72,
                            "x": 80,
                            "y": 390,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<h2 style=\"text-align: center;\" class=\"font_2\"><span style=\"font-size:60px;\"><span style=\"line-height:0.7em;\"><span style=\"letter-spacing:0em;\"><span class=\"color_11\"><span style=\"font-family:futura-lt-w01-book,sans-serif;\">50% OFF</span></span></span></span></span></h2>",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 183,
                                "height": 38,
                                "x": 6,
                                "y": 16,
                                "scale": 0.8573749999999999,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 style=\"text-align: center;\" class=\"font_2\"><span style=\"font-size:60px;\"><span style=\"line-height:0.7em;\"><span style=\"letter-spacing:0em;\"><span class=\"color_11\"><span style=\"font-family:futura-lt-w01-book,sans-serif;\">50% OFF</span></span></span></span></span></h2>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }],
                    "data": {
                        "type": "StripContainerSlideShowSlide",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "1"})
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {"bg": "#B6E8E3", "brd": "color_19", "brw": "0", "rd": "0px"},
                            "propertiesSource": {"bg": "value"}
                        },
                        "componentClassName": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "mediaRef": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "title": "Top View of Table & Chair",
                                "uri": "a5f08ab2f8a3414aa4daa927910f1ea5.jpg",
                                "description": "public/491df47b-5958-4dc7-9029-37f428755d45/c2a37dfc-36cf-4bfd-b940-55bac7bdc9e6",
                                "width": 5000,
                                "height": 3333,
                                "alt": "",
                                "artist": {"id": "", "name": ""}
                            },
                            "color": "{color_11}",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "mobileStructure": {
                        "type": "Container",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                        "layout": {
                            "width": 320,
                            "height": 406,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                        "data": {
                            "type": "StripContainerSlideShowSlide",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "1"})
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {"bg": "#B6E8E3", "brd": "color_19", "brw": "0", "rd": "0px"},
                                "propertiesSource": {"bg": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "mediaRef": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "title": "Top View of Table & Chair",
                                    "uri": "a5f08ab2f8a3414aa4daa927910f1ea5.jpg",
                                    "description": "public/491df47b-5958-4dc7-9029-37f428755d45/c2a37dfc-36cf-4bfd-b940-55bac7bdc9e6",
                                    "width": 5000,
                                    "height": 3333,
                                    "alt": "",
                                    "artist": {"id": "", "name": ""}
                                },
                                "color": "{color_11}",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            }
                        }
                    }
                }, {
                    "type": "Container",
                    "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                    "layout": {
                        "width": 1421,
                        "height": 651,
                        "x": 0,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                    "components": [{
                        "type": "Component",
                        "layout": {
                            "width": 898,
                            "height": 83,
                            "x": 41,
                            "y": 133,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<h2 class=\"font_2\" style=\"font-size:100px; line-height:0.7em; text-align:center\"><span style=\"color:#FFFFFF;\"><span style=\"font-size:100px\"><span style=\"letter-spacing:0em\"><span style=\"font-family:futura-lt-w01-book,sans-serif\">New Products</span></span></span></span></h2>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 280,
                                "height": 31,
                                "x": 20,
                                "y": 98,
                                "scale": 0.756,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 class=\"font_2\" style=\"font-size:100px; line-height:0.7em; text-align:center\"><span style=\"color:#FFFFFF;\"><span style=\"font-size:100px\"><span style=\"letter-spacing:0em\"><span style=\"font-family:futura-lt-w01-book,sans-serif\">New Products</span></span></span></span></h2>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 898,
                            "height": 21,
                            "x": 41,
                            "y": 245,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<h2 class=\"font_2\" style=\"font-size:25px; line-height:0.7em; text-align:center\"><span style=\"color:#FCFF1B;\"><span style=\"font-family:futura-lt-w01-book,sans-serif\"><span style=\"font-size:25px\"><span style=\"letter-spacing:0.04em\">FIND OUT MORE &gt;</span></span></span></span></h2>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 280,
                                "height": 13,
                                "x": 20,
                                "y": 148,
                                "scale": 0.64,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 class=\"font_2\" style=\"font-size:25px; line-height:0.7em; text-align:center\"><span style=\"color:#FCFF1B;\"><span style=\"font-family:futura-lt-w01-book,sans-serif\"><span style=\"font-size:25px\"><span style=\"letter-spacing:0.04em\">FIND OUT MORE &gt;</span></span></span></span></h2>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }],
                    "data": {
                        "type": "StripContainerSlideShowSlide",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "2"})
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {"bg": "#B6E8E3", "brd": "color_19", "brw": "0", "rd": "0px"},
                            "propertiesSource": {"bg": "value"}
                        },
                        "componentClassName": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "mediaRef": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "title": "White Sofa",
                                "uri": "c38cd71759f744e1a7ec2d10871410da.jpg",
                                "description": "public/491df47b-5958-4dc7-9029-37f428755d45/47ec63e8-89c6-4665-b7f6-f5be6da222ff",
                                "width": 5000,
                                "height": 3750,
                                "alt": "",
                                "artist": {"id": "mobile_mobile_", "name": ""},
                                "opacity": 0.8
                            },
                            "color": "#000000",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "{color_15}",
                            "colorOverlayOpacity": 0.21
                        }
                    },
                    "mobileStructure": {
                        "type": "Container",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                        "layout": {
                            "width": 320,
                            "height": 406,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                        "data": {
                            "type": "StripContainerSlideShowSlide",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "2"})
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {"bg": "#B6E8E3", "brd": "color_19", "brw": "0", "rd": "0px"},
                                "propertiesSource": {"bg": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "mediaRef": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "title": "White Sofa",
                                    "uri": "c38cd71759f744e1a7ec2d10871410da.jpg",
                                    "description": "public/491df47b-5958-4dc7-9029-37f428755d45/47ec63e8-89c6-4665-b7f6-f5be6da222ff",
                                    "width": 5000,
                                    "height": 3750,
                                    "alt": "",
                                    "artist": {"id": "mobile_", "name": ""},
                                    "opacity": 0.8
                                },
                                "color": "#000000",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "{color_15}",
                                "colorOverlayOpacity": 0.21
                            }
                        }
                    }
                }, {
                    "type": "Container",
                    "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                    "layout": {
                        "width": 1421,
                        "height": 651,
                        "x": 0,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                    "components": [{
                        "type": "Component",
                        "layout": {
                            "width": 898,
                            "height": 78,
                            "x": 41,
                            "y": 138,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<h2 style=\"font-size: 95px; text-align: center; line-height: 0.7em;\" class=\"font_2\"><span style=\"font-size:95px;\"><span style=\"letter-spacing:0em;\"><span class=\"color_2\"><span style=\"font-family: futura-lt-w01-book, sans-serif;\">More Offers</span></span></span></span></h2>",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 280,
                                "height": 32,
                                "x": 20,
                                "y": 97,
                                "scale": 0.8,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 style=\"font-size: 95px; text-align: center; line-height: 0.7em;\" class=\"font_2\"><span style=\"font-size:95px;\"><span style=\"letter-spacing:0em;\"><span class=\"color_2\"><span style=\"font-family: futura-lt-w01-book, sans-serif;\">More Offers</span></span></span></span></h2>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 898,
                            "height": 21,
                            "x": 41,
                            "y": 246,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<h2 class=\"font_2\" style=\"font-size:25px; line-height:0.7em; text-align:center\"><span style=\"color:#FCFF1B;\"><span style=\"font-family:futura-lt-w01-book,sans-serif\"><span style=\"font-size:25px\"><span style=\"letter-spacing:0.04em\">SHOP NOW &gt;</span></span></span></span></h2>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 195,
                                "height": 13,
                                "x": 62,
                                "y": 149,
                                "scale": 0.64,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 class=\"font_2\" style=\"font-size:25px; line-height:0.7em; text-align:center\"><span style=\"color:#FCFF1B;\"><span style=\"font-family:futura-lt-w01-book,sans-serif\"><span style=\"font-size:25px\"><span style=\"letter-spacing:0.04em\">SHOP NOW &gt;</span></span></span></span></h2>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }],
                    "data": {
                        "type": "StripContainerSlideShowSlide",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "3"})
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {"bg": "#B6E8E3", "brd": "color_19", "brw": "0", "rd": "0px"},
                            "propertiesSource": {"bg": "value"}
                        },
                        "componentClassName": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "mediaRef": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "title": "bigstock-White-Vintage-Kitchenware-65266138 (1).jpg",
                                "uri": "035244_44ef5f29bdc446f98f4d5047688b1abd.jpg",
                                "description": "private/",
                                "width": 4137,
                                "height": 2848,
                                "alt": "",
                                "artist": {"id": "mobile_mobile_mobile_", "name": ""},
                                "opacity": 0.85
                            },
                            "color": "{color_15}",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "{color_15}",
                            "colorOverlayOpacity": 0.21
                        }
                    },
                    "mobileStructure": {
                        "type": "Container",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                        "layout": {
                            "width": 320,
                            "height": 406,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                        "data": {
                            "type": "StripContainerSlideShowSlide",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "3"})
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {"bg": "#B6E8E3", "brd": "color_19", "brw": "0", "rd": "0px"},
                                "propertiesSource": {"bg": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "mediaRef": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "title": "bigstock-White-Vintage-Kitchenware-65266138 (1).jpg",
                                    "uri": "035244_44ef5f29bdc446f98f4d5047688b1abd.jpg",
                                    "description": "private/",
                                    "width": 4137,
                                    "height": 2848,
                                    "alt": "",
                                    "artist": {"id": "mobile_mobile_", "name": ""},
                                    "opacity": 0.85
                                },
                                "color": "{color_15}",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "{color_15}",
                                "colorOverlayOpacity": 0.21
                            }
                        }
                    }
                }],
                "props": {
                    "type": "StripContainerSlideShowProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "transition": "SlideHorizontal",
                    "autoPlayInterval": 3,
                    "autoPlay": true,
                    "transDuration": 1,
                    "pauseAutoPlayOnMouseOver": true,
                    "direction": "RTL",
                    "shouldHideOverflowContent": true,
                    "flexibleBoxHeight": false,
                    "showNavigationButton": true,
                    "showNavigationDots": true,
                    "navigationButtonSize": 25,
                    "navigationButtonMargin": 100,
                    "navigationDotsSize": 6,
                    "navigationDotsMargin": 40,
                    "navigationDotsGap": 12,
                    "navigationDotsAlignment": "center"
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {"alpha-arrowColor": "1", "alpha-dotsColor": "1", "dotsColor": "#FFFFFF"},
                        "propertiesSource": {
                            "alpha-arrowColor": "value",
                            "alpha-dotsColor": "value",
                            "arrowColor": "value",
                            "dotsColor": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.StripContainerSlideShow",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.common.components.stripSlideShow.viewer.skins.thinArrowsLargeSelectedCircleSkin"
                },
                "mobileStructure": {
                    "type": "Container",
                    "skin": "wysiwyg.common.components.stripSlideShow.viewer.skins.thinArrowsLargeSelectedCircleSkin",
                    "layout": {
                        "width": 320,
                        "height": 406,
                        "x": 0,
                        "y": 3615,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.StripContainerSlideShow",
                    "props": {
                        "type": "StripContainerSlideShowProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "transition": "SlideHorizontal",
                        "autoPlayInterval": 3,
                        "autoPlay": true,
                        "transDuration": 1,
                        "pauseAutoPlayOnMouseOver": true,
                        "direction": "RTL",
                        "shouldHideOverflowContent": true,
                        "flexibleBoxHeight": false,
                        "showNavigationButton": true,
                        "showNavigationDots": true,
                        "navigationButtonSize": 14,
                        "navigationButtonMargin": 20,
                        "navigationDotsSize": 6,
                        "navigationDotsMargin": 24,
                        "navigationDotsGap": 15,
                        "navigationDotsAlignment": "center"
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {"alpha-arrowColor": "1", "alpha-dotsColor": "1", "dotsColor": "#FFFFFF"},
                            "propertiesSource": {
                                "alpha-arrowColor": "value",
                                "alpha-dotsColor": "value",
                                "arrowColor": "value",
                                "dotsColor": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.viewer.components.StripContainerSlideShow",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.stripSlideShow.viewer.skins.thinArrowsLargeSelectedCircleSkin"
                    }
                },
                "id": "comp-im0rsfet"
            },
            "preset": {
                "rect": {"width": 324, "height": 170.20000000000002, "x": -0.8, "y": 1348.2},
                "label": "add_preset_StripSlideShow_9",
                "tags": null
            }
        }, {
            "id": "BoxSlideShow_StripBoxSlideShow_10",
            "structure": {
                "type": "Container",
                "skin": "wysiwyg.common.components.stripSlideShow.viewer.skins.squareButtonsSkin",
                "layout": {
                    "width": 980,
                    "height": 601,
                    "x": 0,
                    "y": 7693,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.StripContainerSlideShow",
                "components": [{
                    "type": "Container",
                    "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                    "layout": {
                        "width": 1421,
                        "height": 601,
                        "x": 0,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                    "components": [{
                        "type": "Component",
                        "layout": {
                            "width": 449,
                            "height": 58,
                            "x": 265,
                            "y": 204,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<h2 style=\"text-align: center; font-size: 45px;\" class=\"font_2\"><span style=\"font-size:45px;\"><span style=\"letter-spacing:0.1em;\"><span style=\"font-family: avenir-lt-w01_85-heavy1475544, sans-serif;\"><span class=\"color_11\">YOUR BRAND</span></span></span></span></h2>",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 280,
                                "height": 34,
                                "x": 20,
                                "y": 58,
                                "scale": 0.84,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 style=\"text-align: center; font-size: 45px;\" class=\"font_2\"><span style=\"font-size:45px;\"><span style=\"letter-spacing:0.1em;\"><span style=\"font-family: avenir-lt-w01_85-heavy1475544, sans-serif;\"><span class=\"color_11\">YOUR BRAND</span></span></span></span></h2>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.button.BasicButton",
                        "layout": {
                            "width": 158,
                            "height": 44,
                            "x": 411,
                            "y": 279,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.SiteButton",
                        "data": {
                            "type": "LinkableButton",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "label": "SHOP NOW"
                        },
                        "props": {
                            "type": "ButtonProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "align": "center",
                            "margin": 0
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "alpha-bg": "0",
                                    "alpha-bgh": "1",
                                    "alpha-brd": "1",
                                    "alpha-brdh": "1",
                                    "alpha-txt": "1",
                                    "alpha-txth": "1",
                                    "bg": "#FFFFFF",
                                    "bgh": "#FFFFFF",
                                    "boxShadowToggleOn-shd": "false",
                                    "brd": "#FFFFFF",
                                    "brdh": "#B6E8E3",
                                    "brw": "1",
                                    "fnt": "normal normal normal 16px/1.4em avenir-lt-w01_35-light1475496",
                                    "rd": "0px",
                                    "shd": "0 1px 4px rgba(0, 0, 0, 0.6);",
                                    "txt": "#FFFFFF",
                                    "txth": "#000000"
                                },
                                "propertiesSource": {
                                    "alpha-bg": "value",
                                    "bg": "value",
                                    "bgh": "value",
                                    "brd": "value",
                                    "brdh": "value",
                                    "brw": "value",
                                    "fnt": "value",
                                    "rd": "value",
                                    "shd": "value",
                                    "txt": "value",
                                    "txth": "value"
                                }
                            },
                            "componentClassName": "wysiwyg.viewer.components.SiteButton",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.button.BasicButton"
                        },
                        "mobileStructure": {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.button.BasicButton",
                            "layout": {
                                "width": 150,
                                "height": 40,
                                "x": 85,
                                "y": 191,
                                "scale": 0.863837598531476,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.SiteButton",
                            "data": {
                                "type": "LinkableButton",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "label": "SHOP NOW"
                            },
                            "props": {
                                "type": "ButtonProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "align": "center",
                                "margin": 0
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {
                                        "alpha-bg": "0",
                                        "alpha-bgh": "1",
                                        "alpha-brd": "1",
                                        "alpha-brdh": "1",
                                        "alpha-txt": "1",
                                        "alpha-txth": "1",
                                        "bg": "#FFFFFF",
                                        "bgh": "#FFFFFF",
                                        "boxShadowToggleOn-shd": "false",
                                        "brd": "#FFFFFF",
                                        "brdh": "#B6E8E3",
                                        "brw": "1",
                                        "fnt": "normal normal normal 16px/1.4em avenir-lt-w01_35-light1475496",
                                        "rd": "0px",
                                        "shd": "0 1px 4px rgba(0, 0, 0, 0.6);",
                                        "txt": "#FFFFFF",
                                        "txth": "#000000"
                                    },
                                    "propertiesSource": {
                                        "alpha-bg": "value",
                                        "bg": "value",
                                        "bgh": "value",
                                        "brd": "value",
                                        "brdh": "value",
                                        "brw": "value",
                                        "fnt": "value",
                                        "rd": "value",
                                        "shd": "value",
                                        "txt": "value",
                                        "txth": "value"
                                    }
                                },
                                "componentClassName": "wysiwyg.viewer.components.SiteButton",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.button.BasicButton"
                            }
                        }
                    }],
                    "data": {
                        "type": "StripContainerSlideShowSlide",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "1"})
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {"bg": "#B6E8E3", "brd": "color_19", "brw": "0", "rd": "0px"},
                            "propertiesSource": {"bg": "value"}
                        },
                        "componentClassName": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "mediaRef": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "title": "Sport3.jpg",
                                "uri": "035244_1c77d18456f94b1f9628356669c191b9.jpg",
                                "description": "private/allMedia_picture",
                                "width": 1920,
                                "height": 645,
                                "alt": "",
                                "artist": {"id": "", "name": ""}
                            },
                            "color": "#2E2E2E",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "mobileStructure": {
                        "type": "Container",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                        "layout": {
                            "width": 320,
                            "height": 298,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                        "data": {
                            "type": "StripContainerSlideShowSlide",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "1"})
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {"bg": "#B6E8E3", "brd": "color_19", "brw": "0", "rd": "0px"},
                                "propertiesSource": {"bg": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "mediaRef": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "title": "Sport3.jpg",
                                    "uri": "035244_1c77d18456f94b1f9628356669c191b9.jpg",
                                    "description": "private/allMedia_picture",
                                    "width": 1920,
                                    "height": 645,
                                    "alt": "",
                                    "artist": {"id": "", "name": ""}
                                },
                                "color": "#2E2E2E",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            }
                        }
                    }
                }, {
                    "type": "Container",
                    "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                    "layout": {
                        "width": 1421,
                        "height": 601,
                        "x": 0,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                    "components": [{
                        "type": "Component",
                        "layout": {
                            "width": 449,
                            "height": 52,
                            "x": 490,
                            "y": 185,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<h2 style=\"text-align: center; font-size: 40px;\" class=\"font_2\"><span style=\"font-family: avenir-lt-w01_85-heavy1475544, sans-serif;\"><span class=\"color_11\"><span style=\"letter-spacing: 2.8px;\">YOUR BRAND</span></span></span></h2>",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 280,
                                "height": 37,
                                "x": 19,
                                "y": 25,
                                "scale": 0.95,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 style=\"text-align: center; font-size: 40px;\" class=\"font_2\"><span style=\"font-family: avenir-lt-w01_85-heavy1475544, sans-serif;\"><span class=\"color_11\"><span style=\"letter-spacing: 2.8px;\">YOUR BRAND</span></span></span></h2>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 449,
                            "height": 35,
                            "x": 490,
                            "y": 240,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<h2 style=\"text-align: center; font-size: 26px;\" class=\"font_2\"><span style=\"font-size:26px;\"><span style=\"letter-spacing:0.35em;\"><span style=\"font-family:futura-lt-w01-light,sans-serif;\"><span class=\"color_11\">Add your tagline.</span></span></span></span></h2>",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 280,
                                "height": 28,
                                "x": 20,
                                "y": 63,
                                "scale": 0.8145062499999999,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 style=\"text-align: center; font-size: 26px;\" class=\"font_2\"><span style=\"font-size:26px;\"><span style=\"letter-spacing:0.35em;\"><span style=\"font-family:futura-lt-w01-light,sans-serif;\"><span class=\"color_11\">Add your tagline.</span></span></span></span></h2>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.button.BasicButton",
                        "layout": {
                            "width": 158,
                            "height": 44,
                            "x": 635,
                            "y": 307,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.SiteButton",
                        "data": {
                            "type": "LinkableButton",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "label": "SHOP NOW"
                        },
                        "props": {
                            "type": "ButtonProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "align": "center",
                            "margin": 0
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "alpha-bg": "0",
                                    "alpha-bgh": "1",
                                    "alpha-brd": "1",
                                    "alpha-brdh": "1",
                                    "alpha-txt": "1",
                                    "alpha-txth": "1",
                                    "bg": "#FFFFFF",
                                    "bgh": "#FFFFFF",
                                    "boxShadowToggleOn-shd": "false",
                                    "brd": "#FFFFFF",
                                    "brdh": "#000000",
                                    "brw": "1",
                                    "fnt": "normal normal normal 16px/1.4em avenir-lt-w01_35-light1475496",
                                    "rd": "0px",
                                    "shd": "0 1px 4px rgba(0, 0, 0, 0.6);",
                                    "txt": "#FFFFFF",
                                    "txth": "#000000"
                                },
                                "propertiesSource": {
                                    "alpha-bg": "value",
                                    "bg": "value",
                                    "bgh": "value",
                                    "brd": "value",
                                    "brdh": "value",
                                    "brw": "value",
                                    "fnt": "value",
                                    "rd": "value",
                                    "shd": "value",
                                    "txt": "value",
                                    "txth": "value"
                                }
                            },
                            "componentClassName": "wysiwyg.viewer.components.SiteButton",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.button.BasicButton"
                        },
                        "mobileStructure": {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.button.BasicButton",
                            "layout": {
                                "width": 150,
                                "height": 40,
                                "x": 85,
                                "y": 191,
                                "scale": 0.9070294784580498,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.SiteButton",
                            "data": {
                                "type": "LinkableButton",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "label": "SHOP NOW"
                            },
                            "props": {
                                "type": "ButtonProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "align": "center",
                                "margin": 0
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {
                                        "alpha-bg": "0",
                                        "alpha-bgh": "1",
                                        "alpha-brd": "1",
                                        "alpha-brdh": "1",
                                        "alpha-txt": "1",
                                        "alpha-txth": "1",
                                        "bg": "#FFFFFF",
                                        "bgh": "#FFFFFF",
                                        "boxShadowToggleOn-shd": "false",
                                        "brd": "#FFFFFF",
                                        "brdh": "#000000",
                                        "brw": "1",
                                        "fnt": "normal normal normal 16px/1.4em avenir-lt-w01_35-light1475496",
                                        "rd": "0px",
                                        "shd": "0 1px 4px rgba(0, 0, 0, 0.6);",
                                        "txt": "#FFFFFF",
                                        "txth": "#000000"
                                    },
                                    "propertiesSource": {
                                        "alpha-bg": "value",
                                        "bg": "value",
                                        "bgh": "value",
                                        "brd": "value",
                                        "brdh": "value",
                                        "brw": "value",
                                        "fnt": "value",
                                        "rd": "value",
                                        "shd": "value",
                                        "txt": "value",
                                        "txth": "value"
                                    }
                                },
                                "componentClassName": "wysiwyg.viewer.components.SiteButton",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.button.BasicButton"
                            }
                        }
                    }],
                    "data": {
                        "type": "StripContainerSlideShowSlide",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "2"})
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {"bg": "#B6E8E3", "brd": "color_19", "brw": "0", "rd": "0px"},
                            "propertiesSource": {"bg": "value"}
                        },
                        "componentClassName": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "mediaRef": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "title": "Sport2.jpg",
                                "uri": "035244_35afc9862de747f481a2a7069630cc3f.jpg",
                                "description": "private/allMedia_picture",
                                "width": 2000,
                                "height": 1016,
                                "alt": "",
                                "artist": {"id": "", "name": ""}
                            },
                            "color": "#2E2E2E",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "mobileStructure": {
                        "type": "Container",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                        "layout": {
                            "width": 320,
                            "height": 298,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                        "data": {
                            "type": "StripContainerSlideShowSlide",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "2"})
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {"bg": "#B6E8E3", "brd": "color_19", "brw": "0", "rd": "0px"},
                                "propertiesSource": {"bg": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "mediaRef": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "title": "Sport2.jpg",
                                    "uri": "035244_35afc9862de747f481a2a7069630cc3f.jpg",
                                    "description": "private/allMedia_picture",
                                    "width": 2000,
                                    "height": 1016,
                                    "alt": "",
                                    "artist": {"id": "", "name": ""}
                                },
                                "color": "#2E2E2E",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            }
                        }
                    }
                }, {
                    "type": "Container",
                    "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                    "layout": {
                        "width": 1421,
                        "height": 601,
                        "x": 0,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                    "components": [{
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.button.BasicButton",
                        "layout": {
                            "width": 158,
                            "height": 44,
                            "x": 638,
                            "y": 325,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.SiteButton",
                        "data": {
                            "type": "LinkableButton",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "label": "SHOP NOW"
                        },
                        "props": {
                            "type": "ButtonProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "align": "center",
                            "margin": 0
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "alpha-bg": "0",
                                    "alpha-bgh": "1",
                                    "alpha-brd": "1",
                                    "alpha-brdh": "1",
                                    "alpha-txt": "1",
                                    "alpha-txth": "1",
                                    "bg": "#FFFFFF",
                                    "bgh": "#FFFFFF",
                                    "boxShadowToggleOn-shd": "false",
                                    "brd": "#FFFFFF",
                                    "brdh": "#FFFFFF",
                                    "brw": "1",
                                    "fnt": "normal normal normal 16px/1.4em avenir-lt-w01_35-light1475496",
                                    "rd": "0px",
                                    "shd": "0 1px 4px rgba(0, 0, 0, 0.6);",
                                    "txt": "#FFFFFF",
                                    "txth": "#000000"
                                },
                                "propertiesSource": {
                                    "alpha-bg": "value",
                                    "bg": "value",
                                    "bgh": "value",
                                    "brd": "value",
                                    "brdh": "value",
                                    "brw": "value",
                                    "fnt": "value",
                                    "rd": "value",
                                    "shd": "value",
                                    "txt": "value",
                                    "txth": "value"
                                }
                            },
                            "componentClassName": "wysiwyg.viewer.components.SiteButton",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.button.BasicButton"
                        },
                        "mobileStructure": {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.button.BasicButton",
                            "layout": {
                                "width": 150,
                                "height": 40,
                                "x": 84,
                                "y": 191,
                                "scale": 0.9523809523809523,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.SiteButton",
                            "data": {
                                "type": "LinkableButton",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "label": "SHOP NOW"
                            },
                            "props": {
                                "type": "ButtonProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "align": "center",
                                "margin": 0
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {
                                        "alpha-bg": "0",
                                        "alpha-bgh": "1",
                                        "alpha-brd": "1",
                                        "alpha-brdh": "1",
                                        "alpha-txt": "1",
                                        "alpha-txth": "1",
                                        "bg": "#FFFFFF",
                                        "bgh": "#FFFFFF",
                                        "boxShadowToggleOn-shd": "false",
                                        "brd": "#FFFFFF",
                                        "brdh": "#FFFFFF",
                                        "brw": "1",
                                        "fnt": "normal normal normal 16px/1.4em avenir-lt-w01_35-light1475496",
                                        "rd": "0px",
                                        "shd": "0 1px 4px rgba(0, 0, 0, 0.6);",
                                        "txt": "#FFFFFF",
                                        "txth": "#000000"
                                    },
                                    "propertiesSource": {
                                        "alpha-bg": "value",
                                        "bg": "value",
                                        "bgh": "value",
                                        "brd": "value",
                                        "brdh": "value",
                                        "brw": "value",
                                        "fnt": "value",
                                        "rd": "value",
                                        "shd": "value",
                                        "txt": "value",
                                        "txth": "value"
                                    }
                                },
                                "componentClassName": "wysiwyg.viewer.components.SiteButton",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.button.BasicButton"
                            }
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 460,
                            "height": 35,
                            "x": 482,
                            "y": 240,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<h2 style=\"text-align: center; font-size: 26px;\" class=\"font_2\"><span style=\"font-size:26px;\"><span style=\"letter-spacing:0.35em;\"><span style=\"font-family:futura-lt-w01-light,sans-serif;\"><span class=\"color_11\">Add your tagline.</span></span></span></span></h2>",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 280,
                                "height": 28,
                                "x": 20,
                                "y": 62,
                                "scale": 0.8145062499999999,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 style=\"text-align: center; font-size: 26px;\" class=\"font_2\"><span style=\"font-size:26px;\"><span style=\"letter-spacing:0.35em;\"><span style=\"font-family:futura-lt-w01-light,sans-serif;\"><span class=\"color_11\">Add your tagline.</span></span></span></span></h2>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 446,
                            "height": 52,
                            "x": 494,
                            "y": 185,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<h2 style=\"text-align: center; font-size: 40px;\" class=\"font_2\"><span style=\"font-family: avenir-lt-w01_85-heavy1475544, sans-serif;\"><span class=\"color_11\"><span style=\"letter-spacing: 2.8px;\">YOUR BRAND</span></span></span></h2>",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 278,
                                "height": 37,
                                "x": 20,
                                "y": 24,
                                "scale": 0.95,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 style=\"text-align: center; font-size: 40px;\" class=\"font_2\"><span style=\"font-family: avenir-lt-w01_85-heavy1475544, sans-serif;\"><span class=\"color_11\"><span style=\"letter-spacing: 2.8px;\">YOUR BRAND</span></span></span></h2>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }],
                    "data": {
                        "type": "StripContainerSlideShowSlide",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "3"})
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {"bg": "#B6E8E3", "brd": "color_19", "brw": "0", "rd": "0px"},
                            "propertiesSource": {"bg": "value"}
                        },
                        "componentClassName": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "mediaRef": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "title": "Sport1.jpg",
                                "uri": "035244_5599655f001a46ba9751b76615b316be.jpg",
                                "description": "private/allMedia_picture",
                                "width": 2000,
                                "height": 790,
                                "alt": "",
                                "artist": {"id": "", "name": ""}
                            },
                            "color": "#2E2E2E",
                            "colorOpacity": 1,
                            "alignType": "bottom",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "mobileStructure": {
                        "type": "Container",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                        "layout": {
                            "width": 320,
                            "height": 298,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                        "data": {
                            "type": "StripContainerSlideShowSlide",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "3"})
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {"bg": "#B6E8E3", "brd": "color_19", "brw": "0", "rd": "0px"},
                                "propertiesSource": {"bg": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "mediaRef": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "title": "Sport1.jpg",
                                    "uri": "035244_5599655f001a46ba9751b76615b316be.jpg",
                                    "description": "private/allMedia_picture",
                                    "width": 2000,
                                    "height": 790,
                                    "alt": "",
                                    "artist": {"id": "", "name": ""}
                                },
                                "color": "#2E2E2E",
                                "colorOpacity": 1,
                                "alignType": "bottom",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            }
                        }
                    }
                }],
                "props": {
                    "type": "StripContainerSlideShowProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "transition": "SlideHorizontal",
                    "autoPlayInterval": 3,
                    "autoPlay": true,
                    "transDuration": 1,
                    "pauseAutoPlayOnMouseOver": true,
                    "direction": "RTL",
                    "shouldHideOverflowContent": true,
                    "flexibleBoxHeight": false,
                    "showNavigationButton": true,
                    "showNavigationDots": true,
                    "navigationButtonSize": 18,
                    "navigationButtonMargin": 100,
                    "navigationDotsSize": 6,
                    "navigationDotsMargin": 50,
                    "navigationDotsGap": 12,
                    "navigationDotsAlignment": "center"
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {"alpha-arrowColor": "1", "alpha-dotsColor": "1"},
                        "propertiesSource": {
                            "alpha-arrowColor": "value",
                            "alpha-dotsColor": "value",
                            "arrowColor": "value",
                            "dotsColor": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.StripContainerSlideShow",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.common.components.stripSlideShow.viewer.skins.longArrowsLargeSelectedCircleSkin"
                },
                "mobileStructure": {
                    "type": "Container",
                    "skin": "wysiwyg.common.components.stripSlideShow.viewer.skins.thinArrowsLargeSelectedCircleSkin",
                    "layout": {
                        "width": 320,
                        "height": 298,
                        "x": 0,
                        "y": 4098,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.StripContainerSlideShow",
                    "props": {
                        "type": "StripContainerSlideShowProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "transition": "SlideHorizontal",
                        "autoPlayInterval": 3,
                        "autoPlay": true,
                        "transDuration": 1,
                        "pauseAutoPlayOnMouseOver": true,
                        "direction": "RTL",
                        "shouldHideOverflowContent": true,
                        "flexibleBoxHeight": false,
                        "showNavigationButton": true,
                        "showNavigationDots": true,
                        "navigationButtonSize": 14,
                        "navigationButtonMargin": 20,
                        "navigationDotsSize": 6,
                        "navigationDotsMargin": 24,
                        "navigationDotsGap": 15,
                        "navigationDotsAlignment": "center"
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {"alpha-arrowColor": "1", "alpha-dotsColor": "1"},
                            "propertiesSource": {
                                "alpha-arrowColor": "value",
                                "alpha-dotsColor": "value",
                                "arrowColor": "value",
                                "dotsColor": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.viewer.components.StripContainerSlideShow",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.stripSlideShow.viewer.skins.longArrowsLargeSelectedCircleSkin"
                    }
                },
                "id": "comp-im1t3qc6"
            },
            "preset": {
                "rect": {"width": 324, "height": 159.8, "x": -0.8, "y": 1518.4},
                "label": "add_preset_StripSlideShow_10",
                "tags": null
            }
        }, {
            "id": "BoxSlideShow_StripBoxSlideShow_11",
            "structure": {
                "type": "Container",
                "skin": "wysiwyg.common.components.stripSlideShow.viewer.skins.squareButtonsSkin",
                "layout": {
                    "width": 980,
                    "height": 600,
                    "x": 0,
                    "y": 8494,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.StripContainerSlideShow",
                "components": [{
                    "type": "Container",
                    "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                    "layout": {
                        "width": 1421,
                        "height": 600,
                        "x": 0,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                    "components": [{
                        "type": "Container",
                        "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                        "layout": {
                            "width": 800,
                            "height": 347,
                            "x": 90,
                            "y": 110,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "mobile.core.components.Container",
                        "components": [{
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                            "layout": {
                                "width": 711,
                                "height": 54,
                                "x": 44,
                                "y": 83,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 class=\"font_2\" style=\"font-size:38px; text-align:center\"><span style=\"letter-spacing:0.1em\"><span style=\"font-size:38px\"><span style=\"font-family:playfair display,serif\"><span style=\"font-weight:bold\"><span class=\"color_11\">WRITE A CATCHY HEADER</span></span></span></span></span></h2>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew",
                            "mobileStructure": {
                                "type": "Component",
                                "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                                "layout": {
                                    "width": 218,
                                    "height": 56,
                                    "x": 11,
                                    "y": 33,
                                    "scale": 0.627,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "data": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<h2 class=\"font_2\" style=\"font-size:38px; text-align:center\"><span style=\"letter-spacing:0.1em\"><span style=\"font-size:38px\"><span style=\"font-family:playfair display,serif\"><span style=\"font-weight:bold\"><span class=\"color_11\">WRITE A CATCHY HEADER</span></span></span></span></span></h2>\n",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "props": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                },
                                "style": "txtNew"
                            }
                        }, {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                            "layout": {
                                "width": 711,
                                "height": 26,
                                "x": 44,
                                "y": 157,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 class=\"font_2\" style=\"font-size:20px; text-align:center\"><span style=\"letter-spacing:0.1em\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\"><span style=\"font-size:20px\"><span class=\"color_11\">TAGLINE GOES HERE</span></span></span></span></h2>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew",
                            "mobileStructure": {
                                "type": "Component",
                                "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                                "layout": {
                                    "width": 218,
                                    "height": 18,
                                    "x": 11,
                                    "y": 97,
                                    "scale": 0.7124999999999999,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "data": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<h2 class=\"font_2\" style=\"font-size:20px; text-align:center\"><span style=\"letter-spacing:0.1em\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\"><span style=\"font-size:20px\"><span class=\"color_11\">TAGLINE GOES HERE</span></span></span></span></h2>\n",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "props": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                },
                                "style": "txtNew"
                            }
                        }, {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.button.BasicButton",
                            "layout": {
                                "width": 160,
                                "height": 40,
                                "x": 320,
                                "y": 240,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.SiteButton",
                            "data": {
                                "type": "LinkableButton",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "label": "Make a Booking"
                            },
                            "props": {
                                "type": "ButtonProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "align": "center",
                                "margin": 0
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {
                                        "alpha-bg": "0.3",
                                        "alpha-bgh": "1",
                                        "alpha-brd": "1",
                                        "alpha-brdh": "1",
                                        "alpha-txt": "1",
                                        "alpha-txth": "1",
                                        "bg": "#FFFFFF",
                                        "bgh": "#FFFFFF",
                                        "boxShadowToggleOn-shd": "false",
                                        "brd": "#605E5E",
                                        "brdh": "#FFFFFF",
                                        "brw": "0",
                                        "fnt": "italic normal normal 18px/1.4em playfair+display",
                                        "rd": "0px",
                                        "shd": "0 1px 4px rgba(0, 0, 0, 0.6);",
                                        "txt": "#FFFFFF",
                                        "txth": "#605E5E"
                                    },
                                    "propertiesSource": {
                                        "alpha-bg": "value",
                                        "bg": "value",
                                        "bgh": "value",
                                        "brd": "value",
                                        "brdh": "value",
                                        "brw": "value",
                                        "fnt": "value",
                                        "rd": "value",
                                        "shd": "value",
                                        "txt": "value",
                                        "txth": "value"
                                    }
                                },
                                "componentClassName": "wysiwyg.viewer.components.SiteButton",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.button.BasicButton"
                            },
                            "mobileStructure": {
                                "type": "Component",
                                "skin": "wysiwyg.viewer.skins.button.BasicButton",
                                "layout": {
                                    "width": 160,
                                    "height": 35,
                                    "x": 40,
                                    "y": 160,
                                    "scale": 0.863837598531476,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.SiteButton",
                                "data": {
                                    "type": "LinkableButton",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "label": "Make a Booking"
                                },
                                "props": {
                                    "type": "ButtonProperties",
                                    "metaData": {"schemaVersion": "1.0"},
                                    "align": "center",
                                    "margin": 0
                                },
                                "style": {
                                    "type": "TopLevelStyle",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "style": {
                                        "groups": {},
                                        "properties": {
                                            "alpha-bg": "0.3",
                                            "alpha-bgh": "1",
                                            "alpha-brd": "1",
                                            "alpha-brdh": "1",
                                            "alpha-txt": "1",
                                            "alpha-txth": "1",
                                            "bg": "#FFFFFF",
                                            "bgh": "#FFFFFF",
                                            "boxShadowToggleOn-shd": "false",
                                            "brd": "#605E5E",
                                            "brdh": "#FFFFFF",
                                            "brw": "0",
                                            "fnt": "italic normal normal 18px/1.4em playfair+display",
                                            "rd": "0px",
                                            "shd": "0 1px 4px rgba(0, 0, 0, 0.6);",
                                            "txt": "#FFFFFF",
                                            "txth": "#605E5E"
                                        },
                                        "propertiesSource": {
                                            "alpha-bg": "value",
                                            "bg": "value",
                                            "bgh": "value",
                                            "brd": "value",
                                            "brdh": "value",
                                            "brw": "value",
                                            "fnt": "value",
                                            "rd": "value",
                                            "shd": "value",
                                            "txt": "value",
                                            "txth": "value"
                                        }
                                    },
                                    "componentClassName": "wysiwyg.viewer.components.SiteButton",
                                    "pageId": "",
                                    "compId": "",
                                    "styleType": "custom",
                                    "skin": "wysiwyg.viewer.skins.button.BasicButton"
                                }
                            }
                        }],
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "alpha-bg": "0.12",
                                    "alpha-brd": "1",
                                    "bg": "#000000",
                                    "boxShadowToggleOn-shd": "false",
                                    "brd": "#FFFFFF",
                                    "brw": "3",
                                    "rd": "0px",
                                    "shd": "0 1px 4px rgba(0, 0, 0, 0.6)"
                                },
                                "propertiesSource": {
                                    "alpha-bg": "value",
                                    "alpha-brd": "value",
                                    "bg": "value",
                                    "boxShadowToggleOn-shd": "value",
                                    "brd": "value",
                                    "brw": "value",
                                    "rd": "value",
                                    "shd": "value"
                                }
                            },
                            "componentClassName": "mobile.core.components.Container",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                        },
                        "mobileStructure": {
                            "type": "Container",
                            "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                            "layout": {
                                "width": 240,
                                "height": 228,
                                "x": 40,
                                "y": 38,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "mobile.core.components.Container",
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {
                                        "alpha-bg": "0.12",
                                        "alpha-brd": "1",
                                        "bg": "#000000",
                                        "boxShadowToggleOn-shd": "false",
                                        "brd": "#FFFFFF",
                                        "brw": "3",
                                        "rd": "0px",
                                        "shd": "0 1px 4px rgba(0, 0, 0, 0.6)"
                                    },
                                    "propertiesSource": {
                                        "alpha-bg": "value",
                                        "alpha-brd": "value",
                                        "bg": "value",
                                        "boxShadowToggleOn-shd": "value",
                                        "brd": "value",
                                        "brw": "value",
                                        "rd": "value",
                                        "shd": "value"
                                    }
                                },
                                "componentClassName": "mobile.core.components.Container",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                            }
                        }
                    }],
                    "data": {
                        "type": "StripContainerSlideShowSlide",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "1"})
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {"bg": "#B6E8E3", "brd": "color_19", "brw": "0", "rd": "0px"},
                            "propertiesSource": {"bg": "value"}
                        },
                        "componentClassName": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "mediaRef": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "title": "GettyImages-180928679.jpg",
                                "uri": "035244_8a40c3e0d53d41148879ebac9f0b297f.jpg",
                                "description": "private/allMedia_picture",
                                "width": 5616,
                                "height": 3744,
                                "alt": "",
                                "artist": {"id": "mobile_mobile_", "name": ""},
                                "opacity": 0.8
                            },
                            "color": "#000000",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "{color_15}",
                            "colorOverlayOpacity": 0.15
                        }
                    },
                    "mobileStructure": {
                        "type": "Container",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                        "layout": {
                            "width": 320,
                            "height": 330,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                        "data": {
                            "type": "StripContainerSlideShowSlide",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "1"})
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {"bg": "#B6E8E3", "brd": "color_19", "brw": "0", "rd": "0px"},
                                "propertiesSource": {"bg": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "mediaRef": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "title": "GettyImages-180928679.jpg",
                                    "uri": "035244_8a40c3e0d53d41148879ebac9f0b297f.jpg",
                                    "description": "private/allMedia_picture",
                                    "width": 5616,
                                    "height": 3744,
                                    "alt": "",
                                    "artist": {"id": "mobile_", "name": ""},
                                    "opacity": 0.75
                                },
                                "color": "#000000",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "{color_15}",
                                "colorOverlayOpacity": 0.15
                            }
                        }
                    }
                }, {
                    "type": "Container",
                    "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                    "layout": {
                        "width": 1421,
                        "height": 600,
                        "x": 0,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                    "components": [{
                        "type": "Container",
                        "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                        "layout": {
                            "width": 800,
                            "height": 347,
                            "x": 90,
                            "y": 110,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "mobile.core.components.Container",
                        "components": [{
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                            "layout": {
                                "width": 711,
                                "height": 54,
                                "x": 44,
                                "y": 83,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 class=\"font_2\" style=\"font-size:38px; text-align:center\"><span style=\"letter-spacing:0.1em\"><span style=\"font-size:38px\"><span style=\"font-family:playfair display,serif\"><span style=\"font-weight:bold\"><span class=\"color_11\">WRITE A CATCHY HEADER</span></span></span></span></span></h2>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew",
                            "mobileStructure": {
                                "type": "Component",
                                "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                                "layout": {
                                    "width": 188,
                                    "height": 56,
                                    "x": 26,
                                    "y": 32,
                                    "scale": 0.627,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "data": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<h2 class=\"font_2\" style=\"font-size:38px; text-align:center\"><span style=\"letter-spacing:0.1em\"><span style=\"font-size:38px\"><span style=\"font-family:playfair display,serif\"><span style=\"font-weight:bold\"><span class=\"color_11\">WRITE A CATCHY HEADER</span></span></span></span></span></h2>\n",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "props": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                },
                                "style": "txtNew"
                            }
                        }, {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                            "layout": {
                                "width": 711,
                                "height": 26,
                                "x": 44,
                                "y": 157,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 class=\"font_2\" style=\"font-size:20px; text-align:center\"><span style=\"letter-spacing:0.1em\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\"><span style=\"font-size:20px\"><span class=\"color_11\">DESCRIBE WHAT YOU DO</span></span></span></span></h2>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew",
                            "mobileStructure": {
                                "type": "Component",
                                "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                                "layout": {
                                    "width": 220,
                                    "height": 18,
                                    "x": 10,
                                    "y": 98,
                                    "scale": 0.7124999999999999,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "data": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<h2 class=\"font_2\" style=\"font-size:20px; text-align:center\"><span style=\"letter-spacing:0.1em\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\"><span style=\"font-size:20px\"><span class=\"color_11\">DESCRIBE WHAT YOU DO</span></span></span></span></h2>\n",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "props": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                },
                                "style": "txtNew"
                            }
                        }, {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.button.BasicButton",
                            "layout": {
                                "width": 160,
                                "height": 40,
                                "x": 320,
                                "y": 240,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.SiteButton",
                            "data": {
                                "type": "LinkableButton",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "label": "Button "
                            },
                            "props": {
                                "type": "ButtonProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "align": "center",
                                "margin": 0
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {
                                        "alpha-bg": "0.3",
                                        "alpha-bgh": "1",
                                        "alpha-brd": "1",
                                        "alpha-brdh": "1",
                                        "alpha-txt": "1",
                                        "alpha-txth": "1",
                                        "bg": "#FFFFFF",
                                        "bgh": "#FFFFFF",
                                        "boxShadowToggleOn-shd": "false",
                                        "brd": "#605E5E",
                                        "brdh": "#FFFFFF",
                                        "brw": "0",
                                        "fnt": "italic normal normal 18px/1.4em playfair+display",
                                        "rd": "0px",
                                        "shd": "0 1px 4px rgba(0, 0, 0, 0.6);",
                                        "txt": "#FFFFFF",
                                        "txth": "#605E5E"
                                    },
                                    "propertiesSource": {
                                        "alpha-bg": "value",
                                        "bg": "value",
                                        "bgh": "value",
                                        "brd": "value",
                                        "brdh": "value",
                                        "brw": "value",
                                        "fnt": "value",
                                        "rd": "value",
                                        "shd": "value",
                                        "txt": "value",
                                        "txth": "value"
                                    }
                                },
                                "componentClassName": "wysiwyg.viewer.components.SiteButton",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.button.BasicButton"
                            },
                            "mobileStructure": {
                                "type": "Component",
                                "skin": "wysiwyg.viewer.skins.button.BasicButton",
                                "layout": {
                                    "width": 160,
                                    "height": 35,
                                    "x": 40,
                                    "y": 160,
                                    "scale": 0.9070294784580498,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.SiteButton",
                                "data": {
                                    "type": "LinkableButton",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "label": "Button "
                                },
                                "props": {
                                    "type": "ButtonProperties",
                                    "metaData": {"schemaVersion": "1.0"},
                                    "align": "center",
                                    "margin": 0
                                },
                                "style": {
                                    "type": "TopLevelStyle",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "style": {
                                        "groups": {},
                                        "properties": {
                                            "alpha-bg": "0.3",
                                            "alpha-bgh": "1",
                                            "alpha-brd": "1",
                                            "alpha-brdh": "1",
                                            "alpha-txt": "1",
                                            "alpha-txth": "1",
                                            "bg": "#FFFFFF",
                                            "bgh": "#FFFFFF",
                                            "boxShadowToggleOn-shd": "false",
                                            "brd": "#605E5E",
                                            "brdh": "#FFFFFF",
                                            "brw": "0",
                                            "fnt": "italic normal normal 18px/1.4em playfair+display",
                                            "rd": "0px",
                                            "shd": "0 1px 4px rgba(0, 0, 0, 0.6);",
                                            "txt": "#FFFFFF",
                                            "txth": "#605E5E"
                                        },
                                        "propertiesSource": {
                                            "alpha-bg": "value",
                                            "bg": "value",
                                            "bgh": "value",
                                            "brd": "value",
                                            "brdh": "value",
                                            "brw": "value",
                                            "fnt": "value",
                                            "rd": "value",
                                            "shd": "value",
                                            "txt": "value",
                                            "txth": "value"
                                        }
                                    },
                                    "componentClassName": "wysiwyg.viewer.components.SiteButton",
                                    "pageId": "",
                                    "compId": "",
                                    "styleType": "custom",
                                    "skin": "wysiwyg.viewer.skins.button.BasicButton"
                                }
                            }
                        }],
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "alpha-bg": "0.12",
                                    "alpha-brd": "1",
                                    "bg": "#000000",
                                    "boxShadowToggleOn-shd": "false",
                                    "brd": "#FFFFFF",
                                    "brw": "3",
                                    "rd": "0px",
                                    "shd": "0 1px 4px rgba(0, 0, 0, 0.6)"
                                },
                                "propertiesSource": {
                                    "alpha-bg": "value",
                                    "alpha-brd": "value",
                                    "bg": "value",
                                    "boxShadowToggleOn-shd": "value",
                                    "brd": "value",
                                    "brw": "value",
                                    "rd": "value",
                                    "shd": "value"
                                }
                            },
                            "componentClassName": "mobile.core.components.Container",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                        },
                        "mobileStructure": {
                            "type": "Container",
                            "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                            "layout": {
                                "width": 240,
                                "height": 228,
                                "x": 40,
                                "y": 38,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "mobile.core.components.Container",
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {
                                        "alpha-bg": "0.12",
                                        "alpha-brd": "1",
                                        "bg": "#000000",
                                        "boxShadowToggleOn-shd": "false",
                                        "brd": "#FFFFFF",
                                        "brw": "3",
                                        "rd": "0px",
                                        "shd": "0 1px 4px rgba(0, 0, 0, 0.6)"
                                    },
                                    "propertiesSource": {
                                        "alpha-bg": "value",
                                        "alpha-brd": "value",
                                        "bg": "value",
                                        "boxShadowToggleOn-shd": "value",
                                        "brd": "value",
                                        "brw": "value",
                                        "rd": "value",
                                        "shd": "value"
                                    }
                                },
                                "componentClassName": "mobile.core.components.Container",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                            }
                        }
                    }],
                    "data": {
                        "type": "StripContainerSlideShowSlide",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "2"})
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {"bg": "#B6E8E3", "brd": "color_19", "brw": "0", "rd": "0px"},
                            "propertiesSource": {"bg": "value"}
                        },
                        "componentClassName": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "mediaRef": {
                                "type": "WixVideo",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "title": "Gourmet Cooking",
                                "videoId": "video/11062b_0234bac6aabb4d45af0cbe02be372826",
                                "qualities": [{
                                    "quality": "480p",
                                    "width": 854,
                                    "height": 480,
                                    "formats": ["mp4", "webm"]
                                }, {
                                    "quality": "720p",
                                    "width": 1280,
                                    "height": 720,
                                    "formats": ["mp4", "webm"]
                                }, {"quality": "1080p", "width": 1920, "height": 1080, "formats": ["mp4", "webm"]}],
                                "posterImageRef": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "title": "",
                                    "uri": "11062b_0234bac6aabb4d45af0cbe02be372826f000.jpg",
                                    "description": "public/8e256233-1752-4026-9341-54036e7f875e/d6b6b972-952c-4ab0-b46a-a6c05a0a94a5",
                                    "width": 1920,
                                    "height": 1080,
                                    "alt": ""
                                },
                                "opacity": 1,
                                "duration": 17,
                                "loop": true,
                                "autoplay": true,
                                "preload": "auto",
                                "mute": true,
                                "artist": {"id": "mobile_mobile_", "name": ""},
                                "playbackSpeed": 1
                            },
                            "color": "#FAFAFA",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "{color_15}",
                            "colorOverlayOpacity": 0.15
                        }
                    },
                    "mobileStructure": {
                        "type": "Container",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                        "layout": {
                            "width": 320,
                            "height": 330,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                        "data": {
                            "type": "StripContainerSlideShowSlide",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "2"})
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {"bg": "#B6E8E3", "brd": "color_19", "brw": "0", "rd": "0px"},
                                "propertiesSource": {"bg": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "mediaRef": {
                                    "type": "WixVideo",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "title": "Gourmet Cooking",
                                    "videoId": "video/11062b_0234bac6aabb4d45af0cbe02be372826",
                                    "qualities": [{
                                        "quality": "480p",
                                        "width": 854,
                                        "height": 480,
                                        "formats": ["mp4", "webm"]
                                    }, {
                                        "quality": "720p",
                                        "width": 1280,
                                        "height": 720,
                                        "formats": ["mp4", "webm"]
                                    }, {
                                        "quality": "1080p",
                                        "width": 1920,
                                        "height": 1080,
                                        "formats": ["mp4", "webm"]
                                    }],
                                    "posterImageRef": {
                                        "type": "Image",
                                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                        "title": "",
                                        "uri": "11062b_0234bac6aabb4d45af0cbe02be372826f000.jpg",
                                        "description": "public/8e256233-1752-4026-9341-54036e7f875e/d6b6b972-952c-4ab0-b46a-a6c05a0a94a5",
                                        "width": 1920,
                                        "height": 1080,
                                        "alt": ""
                                    },
                                    "opacity": 1,
                                    "duration": 17,
                                    "loop": true,
                                    "autoplay": true,
                                    "preload": "auto",
                                    "mute": true,
                                    "artist": {"id": "mobile_mobile_", "name": ""},
                                    "playbackSpeed": 1
                                },
                                "color": "#FAFAFA",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "{color_15}",
                                "colorOverlayOpacity": 0.15
                            }
                        }
                    }
                }, {
                    "type": "Container",
                    "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                    "layout": {
                        "width": 1421,
                        "height": 600,
                        "x": 0,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                    "components": [{
                        "type": "Container",
                        "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                        "layout": {
                            "width": 800,
                            "height": 347,
                            "x": 90,
                            "y": 110,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "mobile.core.components.Container",
                        "components": [{
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                            "layout": {
                                "width": 711,
                                "height": 54,
                                "x": 44,
                                "y": 83,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 class=\"font_2\" style=\"font-size:38px; text-align:center\"><span style=\"letter-spacing:0.1em\"><span style=\"font-size:38px\"><span style=\"font-family:playfair display,serif\"><span style=\"font-weight:bold\"><span class=\"color_11\">WRITE A CATCHY HEADER</span></span></span></span></span></h2>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew",
                            "mobileStructure": {
                                "type": "Component",
                                "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                                "layout": {
                                    "width": 187,
                                    "height": 56,
                                    "x": 26,
                                    "y": 32,
                                    "scale": 0.63,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "data": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<h2 class=\"font_2\" style=\"font-size:38px; text-align:center\"><span style=\"letter-spacing:0.1em\"><span style=\"font-size:38px\"><span style=\"font-family:playfair display,serif\"><span style=\"font-weight:bold\"><span class=\"color_11\">WRITE A CATCHY HEADER</span></span></span></span></span></h2>\n",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "props": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                },
                                "style": "txtNew"
                            }
                        }, {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                            "layout": {
                                "width": 711,
                                "height": 26,
                                "x": 44,
                                "y": 157,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 class=\"font_2\" style=\"font-size:20px; text-align:center\"><span style=\"letter-spacing:0.1em\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\"><span style=\"font-size:20px\"><span class=\"color_11\">DESCRIBE WHAT YOU DO</span></span></span></span></h2>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew",
                            "mobileStructure": {
                                "type": "Component",
                                "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
                                "layout": {
                                    "width": 218,
                                    "height": 18,
                                    "x": 11,
                                    "y": 97,
                                    "scale": 0.7,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "data": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<h2 class=\"font_2\" style=\"font-size:20px; text-align:center\"><span style=\"letter-spacing:0.1em\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\"><span style=\"font-size:20px\"><span class=\"color_11\">DESCRIBE WHAT YOU DO</span></span></span></span></h2>\n",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "props": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                },
                                "style": "txtNew"
                            }
                        }, {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.button.BasicButton",
                            "layout": {
                                "width": 160,
                                "height": 40,
                                "x": 320,
                                "y": 240,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.SiteButton",
                            "data": {
                                "type": "LinkableButton",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "label": "Button "
                            },
                            "props": {
                                "type": "ButtonProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "align": "center",
                                "margin": 0
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {
                                        "alpha-bg": "0.3",
                                        "alpha-bgh": "1",
                                        "alpha-brd": "1",
                                        "alpha-brdh": "1",
                                        "alpha-txt": "1",
                                        "alpha-txth": "1",
                                        "bg": "#FFFFFF",
                                        "bgh": "#FFFFFF",
                                        "boxShadowToggleOn-shd": "false",
                                        "brd": "#605E5E",
                                        "brdh": "#FFFFFF",
                                        "brw": "0",
                                        "fnt": "italic normal normal 18px/1.4em playfair+display",
                                        "rd": "0px",
                                        "shd": "0 1px 4px rgba(0, 0, 0, 0.6);",
                                        "txt": "#FFFFFF",
                                        "txth": "#605E5E"
                                    },
                                    "propertiesSource": {
                                        "alpha-bg": "value",
                                        "bg": "value",
                                        "bgh": "value",
                                        "brd": "value",
                                        "brdh": "value",
                                        "brw": "value",
                                        "fnt": "value",
                                        "rd": "value",
                                        "shd": "value",
                                        "txt": "value",
                                        "txth": "value"
                                    }
                                },
                                "componentClassName": "wysiwyg.viewer.components.SiteButton",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.button.BasicButton"
                            },
                            "mobileStructure": {
                                "type": "Component",
                                "skin": "wysiwyg.viewer.skins.button.BasicButton",
                                "layout": {
                                    "width": 160,
                                    "height": 35,
                                    "x": 40,
                                    "y": 160,
                                    "scale": 0.9523809523809523,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.SiteButton",
                                "data": {
                                    "type": "LinkableButton",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "label": "Button "
                                },
                                "props": {
                                    "type": "ButtonProperties",
                                    "metaData": {"schemaVersion": "1.0"},
                                    "align": "center",
                                    "margin": 0
                                },
                                "style": {
                                    "type": "TopLevelStyle",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "style": {
                                        "groups": {},
                                        "properties": {
                                            "alpha-bg": "0.3",
                                            "alpha-bgh": "1",
                                            "alpha-brd": "1",
                                            "alpha-brdh": "1",
                                            "alpha-txt": "1",
                                            "alpha-txth": "1",
                                            "bg": "#FFFFFF",
                                            "bgh": "#FFFFFF",
                                            "boxShadowToggleOn-shd": "false",
                                            "brd": "#605E5E",
                                            "brdh": "#FFFFFF",
                                            "brw": "0",
                                            "fnt": "italic normal normal 18px/1.4em playfair+display",
                                            "rd": "0px",
                                            "shd": "0 1px 4px rgba(0, 0, 0, 0.6);",
                                            "txt": "#FFFFFF",
                                            "txth": "#605E5E"
                                        },
                                        "propertiesSource": {
                                            "alpha-bg": "value",
                                            "bg": "value",
                                            "bgh": "value",
                                            "brd": "value",
                                            "brdh": "value",
                                            "brw": "value",
                                            "fnt": "value",
                                            "rd": "value",
                                            "shd": "value",
                                            "txt": "value",
                                            "txth": "value"
                                        }
                                    },
                                    "componentClassName": "wysiwyg.viewer.components.SiteButton",
                                    "pageId": "",
                                    "compId": "",
                                    "styleType": "custom",
                                    "skin": "wysiwyg.viewer.skins.button.BasicButton"
                                }
                            }
                        }],
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "alpha-bg": "0.12",
                                    "alpha-brd": "1",
                                    "bg": "#000000",
                                    "boxShadowToggleOn-shd": "false",
                                    "brd": "#FFFFFF",
                                    "brw": "3",
                                    "rd": "0px",
                                    "shd": "0 1px 4px rgba(0, 0, 0, 0.6)"
                                },
                                "propertiesSource": {
                                    "alpha-bg": "value",
                                    "alpha-brd": "value",
                                    "bg": "value",
                                    "boxShadowToggleOn-shd": "value",
                                    "brd": "value",
                                    "brw": "value",
                                    "rd": "value",
                                    "shd": "value"
                                }
                            },
                            "componentClassName": "mobile.core.components.Container",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                        },
                        "mobileStructure": {
                            "type": "Container",
                            "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                            "layout": {
                                "width": 240,
                                "height": 228,
                                "x": 40,
                                "y": 38,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "mobile.core.components.Container",
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {
                                        "alpha-bg": "0.12",
                                        "alpha-brd": "1",
                                        "bg": "#000000",
                                        "boxShadowToggleOn-shd": "false",
                                        "brd": "#FFFFFF",
                                        "brw": "3",
                                        "rd": "0px",
                                        "shd": "0 1px 4px rgba(0, 0, 0, 0.6)"
                                    },
                                    "propertiesSource": {
                                        "alpha-bg": "value",
                                        "alpha-brd": "value",
                                        "bg": "value",
                                        "boxShadowToggleOn-shd": "value",
                                        "brd": "value",
                                        "brw": "value",
                                        "rd": "value",
                                        "shd": "value"
                                    }
                                },
                                "componentClassName": "mobile.core.components.Container",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                            }
                        }
                    }],
                    "data": {
                        "type": "StripContainerSlideShowSlide",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "3"})
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {"bg": "#B6E8E3", "brd": "color_19", "brw": "0", "rd": "0px"},
                            "propertiesSource": {"bg": "value"}
                        },
                        "componentClassName": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "mediaRef": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "title": "shutterstock_125625743.jpg",
                                "uri": "035244_2c4cc028ca5d4051a6d812409a44ed88.jpg",
                                "description": "private/",
                                "width": 3744,
                                "height": 4680,
                                "alt": "",
                                "artist": {"id": "", "name": ""},
                                "opacity": 0.85
                            },
                            "color": "#000000",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "#8B0000",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "mobileStructure": {
                        "type": "Container",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                        "layout": {
                            "width": 320,
                            "height": 330,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                        "data": {
                            "type": "StripContainerSlideShowSlide",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "3"})
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {"bg": "#B6E8E3", "brd": "color_19", "brw": "0", "rd": "0px"},
                                "propertiesSource": {"bg": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "mediaRef": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "title": "shutterstock_125625743.jpg",
                                    "uri": "035244_2c4cc028ca5d4051a6d812409a44ed88.jpg",
                                    "description": "private/",
                                    "width": 3744,
                                    "height": 4680,
                                    "alt": "",
                                    "artist": {"id": "", "name": ""},
                                    "opacity": 0.8
                                },
                                "color": "#000000",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "#8B0000",
                                "colorOverlayOpacity": 0
                            }
                        }
                    }
                }],
                "props": {
                    "type": "StripContainerSlideShowProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "transition": "SlideHorizontal",
                    "autoPlayInterval": 3,
                    "autoPlay": true,
                    "transDuration": 1,
                    "pauseAutoPlayOnMouseOver": true,
                    "direction": "RTL",
                    "shouldHideOverflowContent": true,
                    "flexibleBoxHeight": false,
                    "showNavigationButton": true,
                    "showNavigationDots": true,
                    "navigationButtonSize": 20,
                    "navigationButtonMargin": 80,
                    "navigationDotsSize": 6,
                    "navigationDotsMargin": 40,
                    "navigationDotsGap": 10,
                    "navigationDotsAlignment": "center"
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {"alpha-arrowColor": "1", "alpha-dotsColor": "1"},
                        "propertiesSource": {
                            "alpha-arrowColor": "value",
                            "alpha-dotsColor": "value",
                            "arrowColor": "value",
                            "dotsColor": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.StripContainerSlideShow",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.common.components.stripSlideShow.viewer.skins.thinArrowsLargeSelectedCircleSkin"
                },
                "mobileStructure": {
                    "type": "Container",
                    "skin": "wysiwyg.common.components.stripSlideShow.viewer.skins.thinArrowsLargeSelectedCircleSkin",
                    "layout": {
                        "width": 320,
                        "height": 330,
                        "x": 0,
                        "y": 4472,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.StripContainerSlideShow",
                    "props": {
                        "type": "StripContainerSlideShowProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "transition": "SlideHorizontal",
                        "autoPlayInterval": 3,
                        "autoPlay": true,
                        "transDuration": 1,
                        "pauseAutoPlayOnMouseOver": true,
                        "direction": "RTL",
                        "shouldHideOverflowContent": true,
                        "flexibleBoxHeight": false,
                        "showNavigationButton": true,
                        "showNavigationDots": true,
                        "navigationButtonSize": 14,
                        "navigationButtonMargin": 13,
                        "navigationDotsSize": 6,
                        "navigationDotsMargin": 30,
                        "navigationDotsGap": 15,
                        "navigationDotsAlignment": "center"
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {"alpha-arrowColor": "1", "alpha-dotsColor": "1"},
                            "propertiesSource": {
                                "alpha-arrowColor": "value",
                                "alpha-dotsColor": "value",
                                "arrowColor": "value",
                                "dotsColor": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.viewer.components.StripContainerSlideShow",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.stripSlideShow.viewer.skins.thinArrowsLargeSelectedCircleSkin"
                    }
                },
                "id": "comp-io18unwq"
            },
            "preset": {
                "rect": {"width": 324, "height": 160.60000000000002, "x": -0.8, "y": 1678.2},
                "label": "add_preset_StripSlideShow_11",
                "tags": null
            }
        }, {
            "id": "BoxSlideShow_StripBoxSlideShow_12",
            "structure": {
                "type": "Container",
                "skin": "wysiwyg.common.components.stripSlideShow.viewer.skins.squareButtonsSkin",
                "layout": {
                    "width": 980,
                    "height": 651,
                    "x": 0,
                    "y": 9294,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.StripContainerSlideShow",
                "components": [{
                    "type": "Container",
                    "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                    "layout": {
                        "width": 1421,
                        "height": 651,
                        "x": 0,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                    "components": [{
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.button.BasicButton",
                        "layout": {
                            "width": 160,
                            "height": 44,
                            "x": 410,
                            "y": 364,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.SiteButton",
                        "data": {
                            "type": "LinkableButton",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "label": "CLICK IT"
                        },
                        "props": {
                            "type": "ButtonProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "align": "center",
                            "margin": 0
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "alpha-bg": "0",
                                    "alpha-bgh": "1",
                                    "alpha-brd": "1",
                                    "alpha-brdh": "1",
                                    "alpha-txt": "1",
                                    "alpha-txth": "1",
                                    "bg": "#FFFFFF",
                                    "bgh": "#FFFFFF",
                                    "boxShadowToggleOn-shd": "false",
                                    "brd": "#FFFFFF",
                                    "brdh": "#FFFFFF",
                                    "brw": "1",
                                    "fnt": "normal normal normal 16px/1.4em avenir-lt-w01_85-heavy1475544",
                                    "rd": "0px",
                                    "shd": "0 1px 4px rgba(0, 0, 0, 0.6);",
                                    "txt": "#FFFFFF",
                                    "txth": "#000000"
                                },
                                "propertiesSource": {
                                    "alpha-bg": "value",
                                    "bg": "value",
                                    "bgh": "value",
                                    "brd": "value",
                                    "brdh": "value",
                                    "brw": "value",
                                    "fnt": "value",
                                    "rd": "value",
                                    "shd": "value",
                                    "txt": "value",
                                    "txth": "value"
                                }
                            },
                            "componentClassName": "wysiwyg.viewer.components.SiteButton",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.button.BasicButton"
                        },
                        "mobileStructure": {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.button.BasicButton",
                            "layout": {
                                "width": 158,
                                "height": 40,
                                "x": 81,
                                "y": 209,
                                "scale": 0.9523809523809523,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.SiteButton",
                            "data": {
                                "type": "LinkableButton",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "label": "CLICK IT"
                            },
                            "props": {
                                "type": "ButtonProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "align": "center",
                                "margin": 0
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {
                                        "alpha-bg": "0",
                                        "alpha-bgh": "1",
                                        "alpha-brd": "1",
                                        "alpha-brdh": "1",
                                        "alpha-txt": "1",
                                        "alpha-txth": "1",
                                        "bg": "#FFFFFF",
                                        "bgh": "#FFFFFF",
                                        "boxShadowToggleOn-shd": "false",
                                        "brd": "#FFFFFF",
                                        "brdh": "#FFFFFF",
                                        "brw": "1",
                                        "fnt": "normal normal normal 16px/1.4em avenir-lt-w01_85-heavy1475544",
                                        "rd": "0px",
                                        "shd": "0 1px 4px rgba(0, 0, 0, 0.6);",
                                        "txt": "#FFFFFF",
                                        "txth": "#000000"
                                    },
                                    "propertiesSource": {
                                        "alpha-bg": "value",
                                        "bg": "value",
                                        "bgh": "value",
                                        "brd": "value",
                                        "brdh": "value",
                                        "brw": "value",
                                        "fnt": "value",
                                        "rd": "value",
                                        "shd": "value",
                                        "txt": "value",
                                        "txth": "value"
                                    }
                                },
                                "componentClassName": "wysiwyg.viewer.components.SiteButton",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.button.BasicButton"
                            }
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 899,
                            "height": 215,
                            "x": 40,
                            "y": 136,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<h2 style=\"font-size: 160px; text-align: center;\" class=\"font_2\"><span style=\"font-size:160px;\"><span class=\"color_11\"><span style=\"font-family:trend-sans-w00-four,sans-serif;\">LOGO</span></span></span></h2>",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 280,
                                "height": 84,
                                "x": 20,
                                "y": 52,
                                "scale": 1.25,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 style=\"font-size: 160px; text-align: center;\" class=\"font_2\"><span style=\"font-size:160px;\"><span class=\"color_11\"><span style=\"font-family:trend-sans-w00-four,sans-serif;\">LOGO</span></span></span></h2>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }],
                    "data": {
                        "type": "StripContainerSlideShowSlide",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "1"})
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {"bg": "#B6E8E3", "brd": "color_19", "brw": "0", "rd": "0px"},
                            "propertiesSource": {"bg": "value"}
                        },
                        "componentClassName": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "mediaRef": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "title": "Empty Room",
                                "uri": "16a2856bfc964b9b8a388ad874b284e1.jpg",
                                "description": "public/491df47b-5958-4dc7-9029-37f428755d45/c2a37dfc-36cf-4bfd-b940-55bac7bdc9e6",
                                "width": 6400,
                                "height": 3600,
                                "alt": "",
                                "artist": {"id": "", "name": ""},
                                "opacity": 0.95
                            },
                            "color": "#2E2E2E",
                            "colorOpacity": 1,
                            "alignType": "bottom",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "mobileStructure": {
                        "type": "Container",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                        "layout": {
                            "width": 320,
                            "height": 305,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                        "data": {
                            "type": "StripContainerSlideShowSlide",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "1"})
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {"bg": "#B6E8E3", "brd": "color_19", "brw": "0", "rd": "0px"},
                                "propertiesSource": {"bg": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "mediaRef": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "title": "Empty Room",
                                    "uri": "16a2856bfc964b9b8a388ad874b284e1.jpg",
                                    "description": "public/491df47b-5958-4dc7-9029-37f428755d45/c2a37dfc-36cf-4bfd-b940-55bac7bdc9e6",
                                    "width": 6400,
                                    "height": 3600,
                                    "alt": "",
                                    "artist": {"id": "", "name": ""},
                                    "opacity": 0.95
                                },
                                "color": "#2E2E2E",
                                "colorOpacity": 1,
                                "alignType": "bottom",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            }
                        }
                    }
                }, {
                    "type": "Container",
                    "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                    "layout": {
                        "width": 1421,
                        "height": 651,
                        "x": 0,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                    "components": [{
                        "type": "Component",
                        "layout": {
                            "width": 899,
                            "height": 215,
                            "x": 40,
                            "y": 136,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<h2 style=\"font-size: 160px; text-align: center;\" class=\"font_2\"><span style=\"font-size:160px;\"><span class=\"color_11\"><span style=\"font-family:trend-sans-w00-four,sans-serif;\">LOGO</span></span></span></h2>",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 280,
                                "height": 84,
                                "x": 20,
                                "y": 53,
                                "scale": 1.25,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 style=\"font-size: 160px; text-align: center;\" class=\"font_2\"><span style=\"font-size:160px;\"><span class=\"color_11\"><span style=\"font-family:trend-sans-w00-four,sans-serif;\">LOGO</span></span></span></h2>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.button.BasicButton",
                        "layout": {
                            "width": 160,
                            "height": 44,
                            "x": 410,
                            "y": 364,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.SiteButton",
                        "data": {
                            "type": "LinkableButton",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "label": "CLICK IT"
                        },
                        "props": {
                            "type": "ButtonProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "align": "center",
                            "margin": 0
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "alpha-bg": "0",
                                    "alpha-bgh": "1",
                                    "alpha-brd": "1",
                                    "alpha-brdh": "1",
                                    "alpha-txt": "1",
                                    "alpha-txth": "1",
                                    "bg": "#FFFFFF",
                                    "bgh": "#FFFFFF",
                                    "boxShadowToggleOn-shd": "false",
                                    "brd": "#FFFFFF",
                                    "brdh": "#FFFFFF",
                                    "brw": "1",
                                    "fnt": "normal normal normal 16px/1.4em avenir-lt-w01_85-heavy1475544",
                                    "rd": "0px",
                                    "shd": "0 1px 4px rgba(0, 0, 0, 0.6);",
                                    "txt": "#FFFFFF",
                                    "txth": "#000000"
                                },
                                "propertiesSource": {
                                    "alpha-bg": "value",
                                    "bg": "value",
                                    "bgh": "value",
                                    "brd": "value",
                                    "brdh": "value",
                                    "brw": "value",
                                    "fnt": "value",
                                    "rd": "value",
                                    "shd": "value",
                                    "txt": "value",
                                    "txth": "value"
                                }
                            },
                            "componentClassName": "wysiwyg.viewer.components.SiteButton",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.button.BasicButton"
                        },
                        "mobileStructure": {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.button.BasicButton",
                            "layout": {
                                "width": 160,
                                "height": 40,
                                "x": 80,
                                "y": 209,
                                "scale": 0.9523809523809523,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.SiteButton",
                            "data": {
                                "type": "LinkableButton",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "label": "CLICK IT"
                            },
                            "props": {
                                "type": "ButtonProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "align": "center",
                                "margin": 0
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {
                                        "alpha-bg": "0",
                                        "alpha-bgh": "1",
                                        "alpha-brd": "1",
                                        "alpha-brdh": "1",
                                        "alpha-txt": "1",
                                        "alpha-txth": "1",
                                        "bg": "#FFFFFF",
                                        "bgh": "#FFFFFF",
                                        "boxShadowToggleOn-shd": "false",
                                        "brd": "#FFFFFF",
                                        "brdh": "#FFFFFF",
                                        "brw": "1",
                                        "fnt": "normal normal normal 16px/1.4em avenir-lt-w01_85-heavy1475544",
                                        "rd": "0px",
                                        "shd": "0 1px 4px rgba(0, 0, 0, 0.6);",
                                        "txt": "#FFFFFF",
                                        "txth": "#000000"
                                    },
                                    "propertiesSource": {
                                        "alpha-bg": "value",
                                        "bg": "value",
                                        "bgh": "value",
                                        "brd": "value",
                                        "brdh": "value",
                                        "brw": "value",
                                        "fnt": "value",
                                        "rd": "value",
                                        "shd": "value",
                                        "txt": "value",
                                        "txth": "value"
                                    }
                                },
                                "componentClassName": "wysiwyg.viewer.components.SiteButton",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.button.BasicButton"
                            }
                        }
                    }],
                    "data": {
                        "type": "StripContainerSlideShowSlide",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "2"})
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {"bg": "#B6E8E3", "brd": "color_19", "brw": "0", "rd": "0px"},
                            "propertiesSource": {"bg": "value"}
                        },
                        "componentClassName": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "mediaRef": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "title": "House Painting Tools",
                                "uri": "d3ae6c2b580e42ba91e3366ea311a3bb.jpg",
                                "description": "public/491df47b-5958-4dc7-9029-37f428755d45/c2a37dfc-36cf-4bfd-b940-55bac7bdc9e6",
                                "width": 5760,
                                "height": 3102,
                                "alt": "",
                                "artist": {"id": "", "name": ""},
                                "opacity": 0.9
                            },
                            "color": "#2E2E2E",
                            "colorOpacity": 1,
                            "alignType": "top",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "mobileStructure": {
                        "type": "Container",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                        "layout": {
                            "width": 320,
                            "height": 305,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                        "data": {
                            "type": "StripContainerSlideShowSlide",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "2"})
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {"bg": "#B6E8E3", "brd": "color_19", "brw": "0", "rd": "0px"},
                                "propertiesSource": {"bg": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "mediaRef": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "title": "House Painting Tools",
                                    "uri": "d3ae6c2b580e42ba91e3366ea311a3bb.jpg",
                                    "description": "public/491df47b-5958-4dc7-9029-37f428755d45/c2a37dfc-36cf-4bfd-b940-55bac7bdc9e6",
                                    "width": 5760,
                                    "height": 3102,
                                    "alt": "",
                                    "artist": {"id": "", "name": ""},
                                    "opacity": 0.9
                                },
                                "color": "#2E2E2E",
                                "colorOpacity": 1,
                                "alignType": "top",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            }
                        }
                    }
                }, {
                    "type": "Container",
                    "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                    "layout": {
                        "width": 1421,
                        "height": 651,
                        "x": 0,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                    "components": [{
                        "type": "Component",
                        "layout": {
                            "width": 899,
                            "height": 215,
                            "x": 40,
                            "y": 136,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<h2 style=\"font-size: 160px; text-align: center;\" class=\"font_2\"><span class=\"color_11\"><span style=\"font-size:160px;\"><span style=\"font-family:trend-sans-w00-four,sans-serif;\">LOGO</span></span></span></h2>",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 281,
                                "height": 84,
                                "x": 20,
                                "y": 53,
                                "scale": 1.25,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 style=\"font-size: 160px; text-align: center;\" class=\"font_2\"><span class=\"color_11\"><span style=\"font-size:160px;\"><span style=\"font-family:trend-sans-w00-four,sans-serif;\">LOGO</span></span></span></h2>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.button.BasicButton",
                        "layout": {
                            "width": 160,
                            "height": 44,
                            "x": 410,
                            "y": 364,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.SiteButton",
                        "data": {
                            "type": "LinkableButton",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "label": "CLICK IT"
                        },
                        "props": {
                            "type": "ButtonProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "align": "center",
                            "margin": 0
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "alpha-bg": "0",
                                    "alpha-bgh": "1",
                                    "alpha-brd": "1",
                                    "alpha-brdh": "1",
                                    "alpha-txt": "1",
                                    "alpha-txth": "1",
                                    "bg": "#FFFFFF",
                                    "bgh": "#FFFFFF",
                                    "boxShadowToggleOn-shd": "false",
                                    "brd": "#FFFFFF",
                                    "brdh": "#FFFFFF",
                                    "brw": "1",
                                    "fnt": "normal normal normal 16px/1.4em avenir-lt-w01_85-heavy1475544",
                                    "rd": "0px",
                                    "shd": "0 1px 4px rgba(0, 0, 0, 0.6);",
                                    "txt": "#FFFFFF",
                                    "txth": "#000000"
                                },
                                "propertiesSource": {
                                    "alpha-bg": "value",
                                    "bg": "value",
                                    "bgh": "value",
                                    "brd": "value",
                                    "brdh": "value",
                                    "brw": "value",
                                    "fnt": "value",
                                    "rd": "value",
                                    "shd": "value",
                                    "txt": "value",
                                    "txth": "value"
                                }
                            },
                            "componentClassName": "wysiwyg.viewer.components.SiteButton",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.button.BasicButton"
                        },
                        "mobileStructure": {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.button.BasicButton",
                            "layout": {
                                "width": 160,
                                "height": 40,
                                "x": 80,
                                "y": 210,
                                "scale": 0.9523809523809523,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.SiteButton",
                            "data": {
                                "type": "LinkableButton",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "label": "CLICK IT"
                            },
                            "props": {
                                "type": "ButtonProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "align": "center",
                                "margin": 0
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {
                                        "alpha-bg": "0",
                                        "alpha-bgh": "1",
                                        "alpha-brd": "1",
                                        "alpha-brdh": "1",
                                        "alpha-txt": "1",
                                        "alpha-txth": "1",
                                        "bg": "#FFFFFF",
                                        "bgh": "#FFFFFF",
                                        "boxShadowToggleOn-shd": "false",
                                        "brd": "#FFFFFF",
                                        "brdh": "#FFFFFF",
                                        "brw": "1",
                                        "fnt": "normal normal normal 16px/1.4em avenir-lt-w01_85-heavy1475544",
                                        "rd": "0px",
                                        "shd": "0 1px 4px rgba(0, 0, 0, 0.6);",
                                        "txt": "#FFFFFF",
                                        "txth": "#000000"
                                    },
                                    "propertiesSource": {
                                        "alpha-bg": "value",
                                        "bg": "value",
                                        "bgh": "value",
                                        "brd": "value",
                                        "brdh": "value",
                                        "brw": "value",
                                        "fnt": "value",
                                        "rd": "value",
                                        "shd": "value",
                                        "txt": "value",
                                        "txth": "value"
                                    }
                                },
                                "componentClassName": "wysiwyg.viewer.components.SiteButton",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.button.BasicButton"
                            }
                        }
                    }],
                    "data": {
                        "type": "StripContainerSlideShowSlide",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "3"})
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {"bg": "#B6E8E3", "brd": "color_19", "brw": "0", "rd": "0px"},
                            "propertiesSource": {"bg": "value"}
                        },
                        "componentClassName": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "mediaRef": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "title": "Orange Wall",
                                "uri": "e5590e78cb3c4a4aa2bd66fce4787a0c.jpg",
                                "description": "public/491df47b-5958-4dc7-9029-37f428755d45/c2a37dfc-36cf-4bfd-b940-55bac7bdc9e6",
                                "width": 2500,
                                "height": 2500,
                                "alt": "",
                                "artist": {"id": "mobile_mobile_mobile_", "name": ""},
                                "opacity": 0.8
                            },
                            "color": "#2E2E2E",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "mobileStructure": {
                        "type": "Container",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                        "layout": {
                            "width": 320,
                            "height": 305,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                        "data": {
                            "type": "StripContainerSlideShowSlide",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "3"})
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {"bg": "#B6E8E3", "brd": "color_19", "brw": "0", "rd": "0px"},
                                "propertiesSource": {"bg": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.StripContainerSlideShowSlide",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "mediaRef": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "title": "Orange Wall",
                                    "uri": "e5590e78cb3c4a4aa2bd66fce4787a0c.jpg",
                                    "description": "public/491df47b-5958-4dc7-9029-37f428755d45/c2a37dfc-36cf-4bfd-b940-55bac7bdc9e6",
                                    "width": 2500,
                                    "height": 2500,
                                    "alt": "",
                                    "artist": {"id": "mobile_mobile_", "name": ""},
                                    "opacity": 0.8
                                },
                                "color": "#2E2E2E",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            }
                        }
                    }
                }],
                "props": {
                    "type": "StripContainerSlideShowProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "transition": "SlideHorizontal",
                    "autoPlayInterval": 3,
                    "autoPlay": true,
                    "transDuration": 1,
                    "pauseAutoPlayOnMouseOver": true,
                    "direction": "RTL",
                    "shouldHideOverflowContent": true,
                    "flexibleBoxHeight": false,
                    "showNavigationButton": true,
                    "showNavigationDots": true,
                    "navigationButtonSize": 18,
                    "navigationButtonMargin": 100,
                    "navigationDotsSize": 6,
                    "navigationDotsMargin": 50,
                    "navigationDotsGap": 16,
                    "navigationDotsAlignment": "center"
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-arrowColor": "1",
                            "alpha-arrowContainerColor": "0.9",
                            "alpha-dotsColor": "0.94",
                            "alpha-dotsSelectedColor": "1",
                            "arrowColor": "#000000",
                            "arrowContainerColor": "#FFFFFF",
                            "dotsColor": "#FFFFFF",
                            "dotsSelectedColor": "#F7CA34"
                        },
                        "propertiesSource": {
                            "alpha-arrowColor": "value",
                            "alpha-arrowContainerColor": "value",
                            "alpha-dotsColor": "value",
                            "alpha-dotsSelectedColor": "value",
                            "arrowColor": "value",
                            "arrowContainerColor": "value",
                            "dotsColor": "value",
                            "dotsSelectedColor": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.StripContainerSlideShow",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.common.components.stripSlideShow.viewer.skins.squareButtonsSkin"
                },
                "mobileStructure": {
                    "type": "Container",
                    "skin": "wysiwyg.common.components.stripSlideShow.viewer.skins.longArrowsLargeSelectedCircleSkin",
                    "layout": {
                        "width": 320,
                        "height": 305,
                        "x": 0,
                        "y": 4881,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.StripContainerSlideShow",
                    "props": {
                        "type": "StripContainerSlideShowProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "transition": "SlideHorizontal",
                        "autoPlayInterval": 3,
                        "autoPlay": true,
                        "transDuration": 1,
                        "pauseAutoPlayOnMouseOver": true,
                        "direction": "RTL",
                        "shouldHideOverflowContent": true,
                        "flexibleBoxHeight": false,
                        "showNavigationButton": true,
                        "showNavigationDots": true,
                        "navigationButtonSize": 14,
                        "navigationButtonMargin": 20,
                        "navigationDotsSize": 6,
                        "navigationDotsMargin": 24,
                        "navigationDotsGap": 15,
                        "navigationDotsAlignment": "center"
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-arrowColor": "1",
                                "alpha-arrowContainerColor": "0.9",
                                "alpha-dotsColor": "0.94",
                                "alpha-dotsSelectedColor": "1",
                                "arrowColor": "#000000",
                                "arrowContainerColor": "#FFFFFF",
                                "dotsColor": "#FFFFFF",
                                "dotsSelectedColor": "#F7CA34"
                            },
                            "propertiesSource": {
                                "alpha-arrowColor": "value",
                                "alpha-arrowContainerColor": "value",
                                "alpha-dotsColor": "value",
                                "alpha-dotsSelectedColor": "value",
                                "arrowColor": "value",
                                "arrowContainerColor": "value",
                                "dotsColor": "value",
                                "dotsSelectedColor": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.viewer.components.StripContainerSlideShow",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.stripSlideShow.viewer.skins.squareButtonsSkin"
                    }
                },
                "id": "comp-im20iz98"
            },
            "preset": {
                "rect": {"width": 324, "height": 170.20000000000002, "x": -0.8, "y": 1838.8000000000002},
                "label": "add_preset_StripSlideShow_12",
                "tags": null
            }
        }],
        "compTypes": ["wysiwyg.viewer.components.StripContainerSlideShow"]
    },
    "help": {"hide": false, "text": "add_section_info_text_strip_slideshow"}
}
