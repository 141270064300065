import React, { type FC, useState } from 'react';
import { PromotionalList, Text, Preloader } from '@wix/wix-base-ui';
import { clickOnPanel } from '@wix/bi-logger-editor/v2';
import { hoc, biLogger } from '@/util';
import { frames } from '@/panels';
import { translate } from '@/i18n';
import { symbol as Symbol } from '@wix/santa-editor-symbols';

import { PANEL_NAME, PANEL_CONTENT } from './constants';
import {
  mapDispatchToProps,
  type DispatchProps,
} from './optionalSectionsMigrationPanelMapper';
import styles from './optionalSectionsMigrationPanel.scss';

export interface OptionalSectionsMigrationPanelProps extends DispatchProps {}

const OptionalSectionsMigrationPanel: FC<
  OptionalSectionsMigrationPanelProps
> = ({ openHelpCenter, closePanel, forceMigrate }) => {
  const [isLoading, setIsLoadig] = useState(false);

  const reportClick = (origin: string) => {
    biLogger.report(
      clickOnPanel({
        action_name: `${origin}_btn`,
        panel_name: PANEL_NAME,
      }),
    );
  };

  const onPrimaryButtonClick = () => {
    setIsLoadig(true);
    reportClick('update_editor');
    forceMigrate();
  };
  const onCloseButtonClick = () => {
    reportClick('close');
    closePanel();
  };

  const onSecondaryButtonClick = () => {
    reportClick('learn_more');
    openHelpCenter();
  };

  const onHelpButtonClick = () => {
    reportClick('help');
    openHelpCenter();
  };

  return (
    <frames.PromotionalPanelFrame
      panelName={PANEL_NAME}
      title={translate(PANEL_CONTENT.title)}
      illustration={
        <Symbol
          name="topBarSiteMenuUpdateEditor"
          className={styles.illustration}
        />
      }
      // subtitle={translate(PANEL_CONTENT.subtitle)}
      primaryButtonText={
        isLoading ? ' ' : translate(PANEL_CONTENT.primaryButtonText)
      }
      primaryButtonProps={
        isLoading ? { prefixIcon: <Preloader className="tiny" /> } : undefined
      }
      secondaryButtonText={translate(PANEL_CONTENT.secondaryButtonText)}
      onPrimaryButtonClick={onPrimaryButtonClick}
      onSecondaryButtonClick={onSecondaryButtonClick}
      onCloseButtonClick={onCloseButtonClick}
      onHelpButtonClick={onHelpButtonClick}
      onOuterClick={onCloseButtonClick}
      footnote={
        <Text enableEllipsis={false} size="small" skin="standard">
          {PANEL_CONTENT.footnote}
        </Text>
      }
      className={isLoading ? styles.loading : undefined}
    >
      <div className={styles.listContainer}>
        <PromotionalList
          items={PANEL_CONTENT.items}
          symbol={<Symbol className={styles.check} name="check" />}
        />
      </div>
    </frames.PromotionalPanelFrame>
  );
};

export default hoc.connect(
  hoc.STORES.EDITOR_API,
  null,
  mapDispatchToProps,
)(OptionalSectionsMigrationPanel);
