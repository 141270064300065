import PropTypes from 'prop-types';
import React from 'react';
import * as baseUI from '@/baseUI';

import type { AppData } from 'types/documentServices';

//TYPE WAS GENERATED, remove this line when reviewed
interface Props {
  className?: string;
  onPostMessage?: FunctionFixMe;
  name?: string;
  src?: string;
  iframeKey?: string;
  onLoad?: FunctionFixMe;
  appData?: AppData;
  iframeLoaded?: boolean;
}

export default class extends React.Component<Props> {
  static displayName = 'applicationIframe';

  static propTypes = {
    className: PropTypes.string,
    onPostMessage: PropTypes.func,
    name: PropTypes.string,
    src: PropTypes.string,
    iframeKey: PropTypes.string,
    onLoad: PropTypes.func,
    appData: PropTypes.object,
    iframeLoaded: PropTypes.bool,
  };

  render() {
    return (
      <div className="container-preloader-iframe">
        <baseUI.iframe
          onPostMessage={this.props.onPostMessage}
          name={this.props.name}
          appData={this.props.appData}
          src={this.props.src}
          // @ts-expect-error
          onLoad={this.props.onLoad}
          key={this.props.iframeKey}
          className={this.props.className}
        />
        {!this.props.iframeLoaded ? (
          <baseUI.preloader key="search-preloader" />
        ) : null}
      </div>
    );
  }
}
