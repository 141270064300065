module.exports = {
  id: "Stylable_StylableButton_9",
  preset: { rect: { width: 66, height: 78, x: 0, y: 296 }, tags: null },
  structure: {
    type: "Component",
    metaData: { sig: "inq-365", pageId: "c1dmp" },
    skin: "wixui.skins.Skinless",
    layout: {
      width: 47,
      height: 47,
      x: 11,
      y: 311,
      scale: 1,
      rotationInDegrees: 0,
      fixedPosition: false,
    },
    componentType: "wixui.StylableButton",
    parent: "comp-knzvqsjj",
    data: {
      type: "StylableButton",
      metaData: {
        isPreset: false,
        schemaVersion: "1.0",
        isHidden: false,
        sig: "q4x-255",
        pageId: "c1dmp",
      },
      label: "Document",
      svgId: "11062b_aabb07aca9ba42769e71ba6252434b3f.svg",
    },
    props: {
      type: "StylableButtonProperties",
      metaData: { schemaVersion: "1.0", sig: "b80-398", pageId: "c1dmp" },
    },
    style: {
      type: "ComponentStyle",
      metaData: {
        isPreset: false,
        schemaVersion: "1.0",
        isHidden: false,
        sig: "4ie-558",
        pageId: "c1dmp",
      },
      style: {
        properties: {
          "$st-css":
            ":import{-st-from: 'wix-ui-santa/index.st.css';-st-named: StylableButton}.root{-st-extends: StylableButton;transition: all 0.2s ease, visibility 0s;background: #2270DF;border-radius: 100px;border: 0px solid rgb(29, 103, 205)}.root:disabled {\n    background: #DBDBDB;\n}.root[disabled]{background: #E2E2E2}.root[disabled]::label{color: #8F8F8F}.root[disabled]::icon{fill: #8F8F8F}.root::container{transition: inherit}.root::label{transition: inherit;font-size: 16px;color: #000000;display: none}.root::icon{transition: inherit;fill: #FFFFFF;width: 25px;height: 25px}.root:hover::icon {\n    width: 23px;\n    height: 23px;\n}",
        },
        propertiesSource: { "$st-css": "value" },
      },
      componentClassName: "wixui.StylableButton",
      pageId: "",
      compId: "",
      styleType: "custom",
      skin: "wixui.skins.Skinless",
    },
    activeModes: {},
    id: "comp-km90rtty",
    components: [],
  },
};
