'use strict'

const text = require('./categories/text')
const image = require('./categories/image')
const gallery = require('./categories/gallery')
const button = require('./categories/button')
const box = require('./categories/box')
const strip = require('./categories/strip')
const stateBox = require('./categories/stateBox')
const vectorShape = require('./categories/vectorShape')
const vectorArt = require('./categories/vectorArt')
const video = require('./categories/video')
const music = require('./categories/music')
const social = require('./categories/social')
const contact = require('./categories/contact')
const menu = require('./categories/menu')
const navigation = require('./categories/navigation')
const decorative = require('./categories/decorative')
const media = require('./categories/media')
const embeds = require('./categories/embeds')
const list = require('./categories/list')
const other = require('./categories/other')
const boxSlideShow = require('./categories/boxSlideShow')
const hoverBox = require('./categories/hoverBox')
const popup = require('./categories/popup')
const page = require('./categories/page')
const developer = require('./categories/developer')
const database = require('./categories/database')
const repeater = require('./categories/repeater')
const designPanelOnly = require('./sections/designPanelOnly/designPanelOnly')

module.exports = {
    text,
    image,
    gallery,
    button,
    box,
    strip,
    stateBox,
    vectorShape,
    vectorArt,
    video,
    music,
    social,
    contact,
    menu,
    navigation,
    decorative,
    media,
    embeds,
    list,
    other,
    boxSlideShow,
    hoverBox,
    popup,
    page,
    developer,
    designPanelOnly,
    repeater,
    database
}
