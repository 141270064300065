export const TRANSLATIONS_MAP = {
  panelHeader: 'custom_menu_manage_all_menus_header_label',
  MAIN_MENU: {
    TOOLTIP: {
      text: 'custom_menu_manage_all_menus_main_tooltip',
      linkTitle: 'custom_menu_manage_all_menus_main_tooltip_link',
    },
  },
  ITEM: {
    migratedMenuTitle: 'custom_menu_manage_menu_choose_menu_option_default',
    migratedMobileMenuTitle:
      'custom_menu_manage_menu_choose_menu_option_mobile',
    initialTitle: 'custom_menu_manage_all_menus_custom_default_text',
    inputPlaceholder: 'custom_menu_manage_all_menus_custom_placeholder_text',
    tooShort: 'custom_menu_manage_all_menus_custom_rename_empty_error',
    renameButton: 'custom_menu_manage_all_menus_custom_rename_done',
    duplicatedTitle: 'custom_menu_manage_all_menus_copied_menu_default_label',
    ACTIONS: {
      rename: 'custom_menu_manage_all_menus_menu_options_rename',
      duplicate: 'custom_menu_manage_all_menus_menu_options_duplicate',
      delete: 'custom_menu_manage_all_menus_menu_options_delete',
    },
  },
  addMenuButtonText: 'custom_menu_manage_all_menus_create_menu_button',
};
