import addPanelDataConsts from '@/addPanelDataConsts';
import eventsWelcome from './eventsWelcome';

const eventsWelcomeSection = {
  type: addPanelDataConsts.SECTIONS_TYPES.GENERAL,
  hide: false,
  title: 'add_section_label_wixevents',
  showSectionHeader: true,
  props: {
    class: 'no-space-before-section invisible-header',
    template: eventsWelcome,
  },
  help: {
    hide: false,
    text: 'add_section_label_membersarea_tooltip_description',
  },
};

export default {
  getCategorySections() {
    return {
      eventsWelcomeSection,
    };
  },
};
