const imagesMap: Record<string, string> = {
  LOGO0: 'platform/widgetSection/widget-color-1.square.png',
  LOGO1: 'platform/widgetSection/widget-color-2.square.png',
  LOGO2: 'platform/widgetSection/widget-color-3.square.png',
  LOGO3: 'platform/widgetSection/widget-color-4.square.png',
  LOGO4: 'platform/widgetSection/widget-color-5.square.png',
  ROBOT: 'platform/widgetSection/add-panel-robot.png',
};

export default imagesMap;
