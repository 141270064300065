import _ from 'lodash';
import { EditorAPIKey, EditorEventRegistryApiKey } from '@/apis';
import { addResources } from '@/i18n';
import constants from './common/constants';
import * as extensions from './extensions/extensions';
import type {
  CompStructure,
  PlatformAppManifestRouters,
  CompRef,
  AppData,
  Connection,
} from 'types/documentServices';
import type { Shell } from '@/apilib';
import {
  getStructureWidgetIdsByAppId,
  addCommonQueryParams,
  getPanelProps,
} from './common/utils';
import type { GroupPermissions } from '@wix/platform-editor-sdk';
import type { GFPPActionPermissions } from '@wix/editor-component-types';
import { EditorPlatformHostIntegrationAPI } from '@wix/editor-platform-host-integration-apis';
import * as util from '@/util';
import * as stateManagement from '@/stateManagement';
import * as platformEvents from 'platformEvents';
import widgetPlugins from './widgetPlugins/widgetPlugins';
import { createPlatformPanelHelpers } from './panels/platformPanelsUtils';
import applications from './applications/applications';

export function createPlatformApi(shell: Shell) {
  const editorAPI = shell.getAPI(EditorAPIKey);
  const editorEventRegistryApi = shell.getAPI(EditorEventRegistryApiKey);
  const platformPanelHelpers = createPlatformPanelHelpers(shell);

  async function beforeRemoveComponents(components: Array<CompRef>) {
    const editorPlatformHostIntegrationAPI = editorAPI.host.getAPI(
      EditorPlatformHostIntegrationAPI,
    );
    try {
      await Promise.all(
        components.map(async (compRef: CompRef) => {
          const compOwner =
            editorPlatformHostIntegrationAPI.components.getComponentApp(
              compRef,
            );
          if (
            compOwner?.appDefinitionId &&
            editorAPI.platform.getAppEditorApi
          ) {
            const appEditorApi = await editorAPI.platform.getAppEditorApi(
              compOwner.appDefinitionId,
            );

            // TODO: do we really need serialize? I saw using here only responsive layout in:
            // https://github.com/wix-private/form-builder/blob/8b09883181f8cebdbafb75d5924e87822fc7eb64/packages/form-builder/src/editor-app/core/layout/api.ts#L771
            // eslint-disable-next-line @wix/santa-editor/dsReadSerializeIsTooExpensive
            const componentDefinition = editorAPI.components.serialize(compRef);
            return appEditorApi?.beforeComponentRemoved({
              componentDefinition,
            });
          }
          return Promise.resolve();
        }),
      );
    } catch (e) {
      console.error(e);
      return Promise.resolve();
    }
  }

  function getDataFromAppManifest(
    appDefinitionId: string,
    path: string | string[],
  ) {
    const appManifest =
      editorAPI.dsRead.platform.getAppManifest(appDefinitionId);
    return _.get(appManifest, path);
  }

  function registerToManifestAdded() {
    editorAPI.dsActions.platform.registerToManifestAdded(function (
      stageData: AnyFixMe,
      appData: AnyFixMe,
    ) {
      const langs = stageData?.controllersStageData?.langs;
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
      _.forEach(langs, function (value, key) {
        const lang: AnyFixMe = {};
        lang[`${appData.applicationId}_${key}`] = value;
        addResources(lang);
      });
    });
  }

  async function canAddAppsInStructure(
    appDefIds: string[],
    compStructure: CompStructure,
  ): Promise<{ canAddApps: boolean; appDefId: string; widgetId: string }> {
    const appsPlatformEditorApisEntry = await Promise.all(
      appDefIds.map(async (appDefId) => ({
        appId: appDefId,
        appPlatformEditorApi:
          await editorAPI.platform.getAppEditorApi(appDefId),
      })),
    );
    const { canAddApps, appDefId, widgetId } = (
      await Promise.all(
        appsPlatformEditorApisEntry
          .filter((app) => !!app.appPlatformEditorApi)
          .map(async ({ appPlatformEditorApi, appId }) => {
            const widgetIds = getStructureWidgetIdsByAppId(
              compStructure,
              appId,
            );
            return (
              (
                await Promise.all(
                  widgetIds.map(async (widgetId) => ({
                    appId,
                    widgetId,
                    canAddApp: await appPlatformEditorApi.canAddWidget({
                      widgetId,
                    }),
                  })),
                )
              ).find(({ canAddApp }) => !canAddApp) || {
                appId: null,
                widgetId: null,
                canAddApp: true,
              }
            );
          }),
      )
    ).reduce(
      (acc, { canAddApp, appId, widgetId }) => ({
        appDefId: acc.appDefId || appId,
        widgetId: acc.widgetId || widgetId,
        canAddApps: acc.canAddApps && canAddApp,
      }),
      { canAddApps: true, appDefId: null, widgetId: null },
    );
    return { canAddApps, appDefId, widgetId };
  }

  editorEventRegistryApi.register(
    editorEventRegistryApi.constants.events.ADD_PANEL_LOADED,
    registerToManifestAdded,
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function isGroupsPermissionsGranted(permissions: GroupPermissions[]) {
    //TODO calculate real permissions when will be implemented in classic
    return true;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function isCustomPermissionsGranted(permissions: string[]) {
    //TODO calculate real permissions when will be implemented in classic
    return true;
  }

  function addGrantedToPermissions(
    permissions?: GFPPActionPermissions,
  ): GFPPActionPermissions {
    const granted =
      editorAPI.platform.permissions.isGroupsPermissionsGranted(
        permissions?.groups,
      ) &&
      editorAPI.platform.permissions.isCustomPermissionsGranted(
        permissions?.custom,
      );
    return { ...permissions, granted };
  }

  function openDashboardPanel(
    appData: AppData,
    {
      closeOtherPanels,
      url,
    }: { closeOtherPanels?: boolean; url?: string } = {},
  ) {
    const bizManagerBaseUrl = util.serviceTopology.businessManagerUrl;
    const metaSiteId = editorAPI.dsRead.generalInfo.getMetaSiteId();
    let fullURL = util.url.joinURL(bizManagerBaseUrl, metaSiteId, url);

    fullURL = util.url.setUrlParam(fullURL, 'viewMode', 'editor');
    fullURL = addCommonQueryParams(fullURL);

    return new Promise<void>(function (resolve) {
      const props = _.merge(
        {},
        {
          url: fullURL,
          onClose: () => {
            const editorState = editorAPI.store.getState();
            const visitedAppsIds =
              stateManagement.tpa.selectors.getAppIdsToRefreshAfterDashboardClose(
                editorState,
              );
            const visitedAppDefIds = visitedAppsIds
              .map(
                (applicationId) =>
                  editorAPI.dsRead.platform.getAppDataByApplicationId(
                    applicationId,
                  )?.appDefinitionId,
              )
              .filter(Boolean);
            const shouldRefreshGeneralInfo =
              stateManagement.tpa.selectors.getShouldRefreshGeneralInfo(
                editorState,
              );
            if (shouldRefreshGeneralInfo) {
              editorAPI.documentServices.generalInfo.sitePropertiesInfo.reload();
              editorAPI.store.dispatch(
                stateManagement.tpa.actions.shouldRefreshGeneralInfo(false),
              );
            }
            editorAPI.dsActions.platform.notifyAppsOnCustomEvent(
              platformEvents.factory.appVisitedInDashboard({
                visitedAppDefIds,
              }),
            );
            editorAPI.store.dispatch(
              stateManagement.tpa.actions.setAppDefIdsToRefreshOnDashboardClose(
                [],
              ),
            );
            resolve();
          },
          isBareMode: true,
          width: '100%',
          height: '100%',
        },
        getPanelProps(editorAPI, appData, {}, undefined),
      );

      editorAPI.store.dispatch(
        stateManagement.panels.actions.openPlatformPanel({
          panelName: constants.panelTypes.DASHBOARD,
          panelProps: props,
          panelMetaData: {
            resolve,
            closeAllOtherPanels: closeOtherPanels,
          },
        }),
      );
    });
  }

  return {
    beforeRemoveComponents,
    canAddAppsInStructure,
    routers: {
      getDataFromRoutersAppManifest<T extends keyof PlatformAppManifestRouters>(
        appDefinitionId: string,
        category: T,
        emptyValue: any = [],
      ): PlatformAppManifestRouters[T]['default'] {
        return (
          getDataFromAppManifest(appDefinitionId, [
            'routers',
            category,
            'default',
          ]) || emptyValue
        );
      },
    },
    controllers: {
      getFirstTimeExperienceFromStageData(componentRef: AnyFixMe) {
        return editorAPI.platform.controllers.getStageData(componentRef)
          ?.firstTimeExperience;
      },
      getConnections(component: CompRef): Connection[] {
        const allConnections =
          editorAPI.platform.controllers.connections.get(component);
        return Array.isArray(allConnections)
          ? allConnections
          : [allConnections];
      },
    },
    connectedComponents: {
      getFirstTimeExperienceFromStageData(componentRef: AnyFixMe) {
        return editorAPI.platform.controllers.getConnectedComponentStageData(
          componentRef,
          //@ts-expect-error
        )?.firstTimeExperience;
      },
    },
    /**
     * This API allow apps to check whether an action define in manifest allowed to be done according to the user's permissions
     * classic not supporting application permissions right now and will return *true* to allow any action.
     */
    permissions: {
      /**
       * @description Checks whether a group permission is granted to the current user.
       * @example const groupPermissionGranted = editorAPI.platform.permissions.isGroupsPermissionsGranted(['EDIT_CONTENT']);
       *  - permissions: The permissions to be checked.
       * @returns a Boolean that is *true* if the permission is granted or *false* if it is not granted.
       * If the permission is not supported by the editor, the function returns a default value of *true*.
       */
      isGroupsPermissionsGranted,
      /**
       * @description Checks whether a custom permission is granted to the current user.
       * @example const customPermissionsGranted = editorAPI.platform.permissions.isCustomPermissionsGranted(['open_tpa_setting']);
       *  - permissions: The permissions to be checked.
       * @returns a Boolean that is *true* if the permission is granted or *false* if it is not granted.
       * If the permission is not supported by the editor, the function returns a default value of *true*.
       */
      isCustomPermissionsGranted,
      addGrantedToPermissions,
    },
    extensions,
    constants,
    panels: {
      openDashboardPanel,
      ...platformPanelHelpers,
    },
    widgetPlugins: widgetPlugins(shell),
    applications: applications(shell),
  };
}
