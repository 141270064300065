// @ts-nocheck
import createReactClass from 'create-react-class';
import * as util from '@/util';
import * as stateManagement from '@/stateManagement';
import * as blogUtils from '../../utils/blogUtils';
import superApp from '../superApp';
import promotionalFlow1 from './firstTimeTour/promotionalFlow/promotionalStep1';
import oldBlogMigrationSlide from '../../newAppMenu/panels/quickMigration/oldBlogMigrationSlide';
import quickMigrationSuccessSlide from '../../newAppMenu/panels/quickMigration/successSlide/quickMigrationSuccessSlide';
import quickMigrationFailureSlide from '../../newAppMenu/panels/quickMigration/failureSlide/quickMigrationFailureSlide';

import React from 'react';
import * as superAppMenuInfra from '@/superAppMenuInfra';

// eslint-disable-next-line react/prefer-es6-class
const BlogMenuPanel = createReactClass({
  displayName: 'BlogMenuPanel',
  mixins: [util.propTypesFilterMixin],
  getSuperApp() {
    return superApp({
      shouldShowOldBlogMigrationPromo:
        this.props.shouldShowOldBlogMigrationPromo(),
      promotionalFlow: this.props.promotionalFlow,
      migrationFlow: this.props.migrationFlow,
    });
  },
  render() {
    return (
      <superAppMenuInfra.superAppPanel
        superApp={this.getSuperApp()}
        panelClass="blog-panel"
        {...this.filteredProps()}
      />
    );
  },
});

const mapStateToProps = ({ editorAPI, state }) => {
  const migrationStatus =
    stateManagement.blog.selectors.getBlogMigrationStatus(state);
  return {
    shouldShowOldBlogMigrationPromo: () =>
      blogUtils.shouldShowOldBlogMigrationPromo(editorAPI),
    promotionalFlow: blogUtils.getOldBlogPromotionalFlow(editorAPI, {
      promotionalFlow1,
      oldBlogMigrationSlide,
    }),
    migrationFlow: blogUtils.getMigrationFlow(
      editorAPI,
      migrationStatus,
      { quickMigrationSuccessSlide, quickMigrationFailureSlide },
      false,
    ),
  };
};

const ConnectedComponent = util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  mapStateToProps,
)(BlogMenuPanel);
ConnectedComponent.pure = BlogMenuPanel;

export default ConnectedComponent;
