'use strict'

const htmlFlashSection = require('../sections/other/htmlFlashSection')
const customEmbedsSection = require('../sections/other/customEmbedsSection')

const iTunesDownloadButtonSection = require('../sections/music/iTunesDownloadButtonSection')
const socialVideoSection = require('../sections/video/socialVideoSection')

const marketingIntegrationsSection = require('../sections/other/marketingIntegrationsSection')
const corvidDevModeSection = require('../sections/other/corvidDevModeSection')

const htmlFlashWithoutCustomElementSection = require('../sections/other/htmlFlashWithoutCustomElementSection')

module.exports = {
    socialVideoSection,
    htmlFlashSection,
    customEmbedsSection,

    iTunesDownloadButtonSection,

    marketingIntegrationsSection,
    corvidDevModeSection,

    htmlFlashWithoutCustomElementSection // App builder only
}
