import React, { useState, useRef, useEffect, useCallback } from 'react';
import { translate } from '@/i18n';
import { symbol as Symbol } from '@wix/santa-editor-symbols';
import { RichText, TextLabel, IconButton } from '@wix/wix-base-ui';

import Block from '../block';

import styles from './mediaCarousel.scss';

import { HelpBlock } from '../../types';
import type { Props } from '../../types';

export const MediaCarousel: React.FC<Props> = ({
  openHowToVideo,
  videoItems,
  onVideoScrollClick,
}) => {
  return (
    <Block
      title="Editor_Help_Panel_Videos_Title"
      type={HelpBlock.MEDIA_CAROUSEL}
      className={styles.block}
    >
      <Slider
        items={videoItems}
        openVideo={openHowToVideo}
        onVideoScrollClick={onVideoScrollClick}
      />
    </Block>
  );
};

interface SliderProps {
  items: Props['videoItems'];
  openVideo: Props['openHowToVideo'];
  onVideoScrollClick: Props['onVideoScrollClick'];
}
const Slider: React.FC<SliderProps> = ({
  items,
  openVideo,
  onVideoScrollClick,
}) => {
  const [current, setCurrent] = useState(0);
  const parentRef = useRef(null);
  const itemsRefs = useRef(items.map(() => React.createRef<HTMLDivElement>()));

  const observerCallback = (entries: AnyFixMe) => {
    entries.forEach((entry: AnyFixMe) => {
      if (entry.isIntersecting) {
        const index = parseInt(entry.target.dataset?.index, 10);

        setCurrent(index);
      }
    });
  };

  useEffect(
    () => {
      if (!parentRef.current) {
        return;
      }

      const options: IntersectionObserverInit = {
        root: null,
        rootMargin: '0px',
        threshold: 0.9,
      };

      const observer = new IntersectionObserver(observerCallback, options);
      itemsRefs.current.forEach((ref) => observer.observe(ref.current));
    }, // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [parentRef.current],
  );

  const scrollTo = useCallback(
    (index) => {
      if (index === current) {
        return;
      }

      const direction = index < current ? -1 : 1;

      parentRef.current.scrollBy({
        left: 100 * direction,
        behavior: 'smooth',
      });
    },
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [current, parentRef.current],
  );

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const next = useCallback(() => scrollTo(current + 1), [current]);

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const prev = useCallback(() => scrollTo(current - 1), [current]);

  const onItemClick = (index: AnyFixMe) => {
    if (current === index) {
      openVideo(items[index].url);
    } else {
      onVideoScrollClick();
    }

    scrollTo(index);
  };

  return (
    <div className={styles.carouselWrapper}>
      <div className={styles.carouselItemsList} ref={parentRef}>
        {items.map(({ title, duration }, index) => {
          return (
            <div
              className={styles.carouselItem}
              key={index}
              ref={itemsRefs.current[index]}
              onClick={() => onItemClick(index)}
              data-index={index}
            >
              <RichText className={styles.carouselItemHeader} type="T09">
                {translate(title)}
              </RichText>
              <TextLabel
                className={styles.carouselItemTiming}
                type="T04"
                value={duration}
                shouldTranslate={false}
              />
              <div className={styles.playWrapper}>
                <Symbol
                  className={styles.playButton}
                  name="editorAssistantPlayButton"
                />
              </div>
            </div>
          );
        })}
      </div>
      <div className={styles.controls}>
        <IconButton
          size="small"
          className={styles.control}
          onClick={prev}
          disabled={current === 0}
        >
          <Symbol name="mediaCarouselArrowLeft" />
        </IconButton>
        <IconButton
          size="small"
          className={styles.control}
          onClick={next}
          disabled={current === items.length - 1}
        >
          <Symbol name="mediaCarouselArrowRight" />
        </IconButton>
      </div>
    </div>
  );
};
