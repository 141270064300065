import createReactClass from 'create-react-class';
import * as core from '@/core';
import _ from 'lodash';
import addPanelDataConsts from '@/addPanelDataConsts';
import * as categoryViewUserPref from './categoryViewMixins/categoryViewUserPref';
import * as coreBi from '@/coreBi';
import constants from '@/constants';
import React from 'react';
import { translate } from '@/i18n';
import { CategoryView } from './categoryView';
import * as symbols from '@wix/santa-editor-symbols';
import { cx, workspace } from '@/util';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'categoryViewWrapper',
  mixins: [core.mixins.editorAPIMixin, categoryViewUserPref as AnyFixMe],
  getInitialState() {
    return {
      isInnerNavigationOpened: false,
      topTitle: this.props.currentSubSectionTitle,
    };
  },
  getStyle() {
    return {
      width: addPanelDataConsts.CATEGORY_VIEW.TOTAL_WIDTH,
    };
  },
  closePanel() {
    this.onCloseClearSectionFromUserPrefExceptSelectedSection(
      this.props.id,
      this.props.allCategories,
    );
    this.props.onClose();
  },
  updateTopTitle(newTopTitle: AnyFixMe) {
    this.setState({ topTitle: newTopTitle });
  },
  shouldTranslateCategory() {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/is-undefined
    return _.isUndefined(this.props.shouldTranslate)
      ? true
      : this.props.shouldTranslate;
  },
  openHelpCenter() {
    const editorAPI = this.getEditorAPI();
    if (this.props.help.helpId) {
      const biHelpParams = {
        origin: constants.BI.HELP.ORIGIN.ADD_PANEL,
        component: this.props.id,
        panel_name: constants.BI.HELP.ORIGIN.ADD_PANEL,
        learn_more: false,
      };
      editorAPI.panelManager.openHelpCenter(
        this.props.help.helpId,
        null,
        biHelpParams,
      );
    }
    editorAPI.bi.event(coreBi.events.addPanel.ADD_MENU_HELP_CATEGORY_CLICK, {
      category: this.props.id,
    });
  },
  changeStateOnInnerNavigationOpen(newState: AnyFixMe) {
    this.setState({ isInnerNavigationOpened: newState });
  },
  render() {
    return (
      <div
        data-aid={`add-panel-${this.props.id}`}
        style={this.getStyle()}
        className={cx('category-view-wrapper', {
          'inner-navigation-open': this.state.isInnerNavigationOpened,
        })}
      >
        {workspace.isNewWorkspaceEnabled() ? null : (
          <div className="header">
            <label className="top-section-title">
              {this.shouldTranslateCategory()
                ? translate(this.props.title)
                : this.props.title}
            </label>

            <div className="actions">
              {!this.props.help.hide && this.props.help.helpId ? (
                <div
                  key="actions"
                  onClick={this.openHelpCenter}
                  className="button help-button"
                >
                  ?
                </div>
              ) : null}
              <div onClick={this.closePanel} className="button close">
                <symbols.symbol name="headerCloseButton" />
              </div>
            </div>
          </div>
        )}
        <CategoryView
          ref="categoryView"
          changeStateOnInnerNavigationOpen={
            this.changeStateOnInnerNavigationOpen
          }
          {...this.props}
        />
      </div>
    );
  },
});
