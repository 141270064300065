import type { ListClassificationResponseGroup } from '../types';
import { parseProtoOutput } from '@wix/editor-prediction-utils';

export default function parseProtoData(
  data: any,
): ListClassificationResponseGroup | {} {
  const groupTree = data?.output?.values?.GroupTree;
  if (groupTree) {
    const parsedOutput = parseProtoOutput(groupTree);
    return parsedOutput;
  }
  return {};
}
