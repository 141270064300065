import * as util from '@/util';
import * as stateManagement from '@/stateManagement';
import type { AppData } from '../components/privateAppsProvider/privateAppsProvider';
import type { Dispatch } from 'types/redux';
import {
  privateAppsUninstallAppClick,
  privateAppsUninstallAppSuccess,
} from '@wix/bi-logger-blocks-consumption/v2';
const biData = { builderType: 'classic' };
import { bulkUninstall } from './bulkUninstall';
import experiment from 'experiment';

const getEditorAPI = (
  dispatch: AnyFixMe,
  getState: AnyFixMe,
  { editorAPI }: AnyFixMe,
) => editorAPI;

export const uninstallApp = (app: AppData) => async (dispatch: Dispatch) => {
  const editorAPI = dispatch(getEditorAPI);
  util.fedopsLogger.interactionStarted(
    util.fedopsLogger.INTERACTIONS.BLOCKS.DELETE_APP,
  );
  util.biLogger.report(
    privateAppsUninstallAppClick({
      appId: app.appDefinitionId,
      ...biData,
    }),
  );
  await dispatch(stateManagement.platform.actions.uninstallApp(app, true));
  if (experiment.isOpen('se_customApps_bulkUninstall')) {
    await bulkUninstall(app.appDefinitionId, editorAPI);
  }
  util.biLogger.report(
    privateAppsUninstallAppSuccess({ appId: app.appDefinitionId, ...biData }),
  );
  util.fedopsLogger.interactionEnded(
    util.fedopsLogger.INTERACTIONS.BLOCKS.DELETE_APP,
  );
};
