:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._highlightedBox_1fqk2_7 {
  position: absolute;
  border: solid #116dff;
  background-color: rgba(231, 240, 255, 0.25);
  animation: _blinkAnimation_1fqk2_1 1s ease-in-out; }

@keyframes _blinkAnimation_1fqk2_1 {
  0%,
  40%,
  80% {
    border-color: rgba(17, 109, 255, 0); }
  20%,
  60% {
    border-color: #116dff; } }
