// @ts-nocheck
import _ from 'lodash';
import * as stateManagement from '@/stateManagement';
import * as coreBi from '@/coreBi';
import constants from '@/constants';

const { translateToViewerCoordinates } =
  stateManagement.domMeasurements.selectors;
let selectedComponent;
let editorAPI;
let isPage;
let gapKey;
let lastUpdatedGap;
let initialGap;
let initialMouseY;

function startDrag(_editorAPI, params) {
  editorAPI = _editorAPI;
  selectedComponent = params.comps;
  isPage = editorAPI.components.is.page(selectedComponent);

  const gaps = editorAPI.siteSegments.getGaps();
  gapKey = isPage ? 'headerToPagesGap' : 'pagesToFooterGap';
  initialGap = gaps[gapKey] || 0;

  const mouseCoordinates = translateToViewerCoordinates(editorAPI, params.evt);
  initialMouseY = mouseCoordinates.pageY;
}

function onDrag(event) {
  const mouseCoordinates = translateToViewerCoordinates(editorAPI, event);
  const mouseDiff = initialMouseY - mouseCoordinates.pageY;
  const newGap = _.max([0, initialGap - mouseDiff]);
  const updatedGap = _.set({}, gapKey, newGap);

  lastUpdatedGap = newGap;
  editorAPI.siteSegments.updateGaps(updatedGap);
}

function endDrag(event) {
  const mouseCoordinates = translateToViewerCoordinates(editorAPI, event);

  if (mouseCoordinates.pageY < initialMouseY) {
    editorAPI.bi.event(coreBi.events.editBox.DRAG_GAP, {
      gap_type: gapKey,
      gap_size_start: initialGap,
      gap_size_end: lastUpdatedGap,
    });
    editorAPI.history.add('gap updated');
  }
}

export default {
  start: startDrag,
  on: onDrag,
  end: endDrag,
  type: constants.MOUSE_ACTION_TYPES.CLOSE_GAPS,
};
