:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._businessTypePanel_1y70k_7 ._businessTypePanelContent_1y70k_7 ._subTitleText_1y70k_7 {
  color: #000624; }

._businessTypePanel_1y70k_7 ._businessTypePanelContent_1y70k_7 ._searchLabel_1y70k_10 {
  padding: 42px 6px 16px;
  color: #000624; }

._businessTypePanel_1y70k_7 ._businessTypePanelContent_1y70k_7 ._searchInput_1y70k_14 {
  max-width: 458px; }

._fields_1y70k_17 {
  margin: 0 -24px; }

._businessTypeInput_1y70k_20 {
  text-align: left; }
  ._businessTypeInput_1y70k_20 .control-search-input > .input-container > .input {
    border-radius: 4px;
    height: 36px;
    padding: 0 11px 0;
    font-size: 16px; }
  ._businessTypeInput_1y70k_20 .control-search-input__prefix {
    display: none; }
