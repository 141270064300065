import { SiteAccessPermission } from '@/constants';
import { HelpClickOrigin } from './siteAccessPanelBi';

export const PASSWORD_INITIAL_VALUE = '';
export const INVALID_MESSAGE_KEY =
  'site_password_modal_password_holders_enter_password_tooltip_error';
export const SITE_ACCESS_HELP_IDS = {
  GENERAL: '7ddbb43e-5aa9-433f-b28e-4a1515ee8114',
  EVERYONE: 'f8b371b4-896c-44af-8edf-97c946f6dbc0',
  PASSWORD: 'dd9f3604-038b-4f62-96c0-f12815e92a19',
};
export const PERMISSION_TO_HELP_ID_MAP = {
  [SiteAccessPermission.Everyone]: SITE_ACCESS_HELP_IDS.EVERYONE,
  [SiteAccessPermission.Password]: SITE_ACCESS_HELP_IDS.PASSWORD,
};
export const PERMISSION_TO_ORIGIN_MAP = {
  [SiteAccessPermission.Everyone]: HelpClickOrigin.EveryoneState,
  [SiteAccessPermission.Password]: HelpClickOrigin.HoldersState,
};
