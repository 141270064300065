import { sections as sectionsUtils } from '@/util';

import type {
  RegularSkinGetter,
  RegularSkinSchema,
  SOAPSkinSchema,
  SOAPSkinGetter,
  GeneralSkinGetter,
} from '../../skins/skins.types';
import {
  getCurrentEditorMode,
  getSectionLikeSelectionType,
  pickSkinFromSchema,
} from '../../skins/skins.utils';

const sectionSchema: RegularSkinSchema = {
  desktop: {
    hovered: 'secondary',
    selected: 'primary',
    focused: 'secondary',
  },
  desktopZoomOut: {
    hovered: 'secondary',
    selected: 'primary',
  },
  mobile: {
    hovered: 'secondary',
    selected: 'primary',
    focused: 'secondary',
  },
  mobileZoomOut: {
    hovered: 'secondary',
    selected: 'primary',
  },
  desktopLeftShrinked: {
    hovered: 'secondary',
    selected: 'secondary',
  },
  desktopRightShrinked: {
    hovered: 'secondary',
    selected: 'secondary',
  },
};

const siteSegmentSchema: SOAPSkinSchema = {
  desktop: {
    hovered: 'secondary-soap',
    selected: 'primary-soap',
    focused: 'secondary-soap',
  },
  mobile: {
    hovered: 'secondary-soap',
    selected: 'primary-soap',
    focused: 'secondary-soap',
  },
};

const getSectionActionsSideActionsSkin: RegularSkinGetter = (
  editorAPI,
  compRef,
  selectionData,
) => {
  if (!sectionsUtils.isSectionsControlsRedesignEnabled()) return 'legacy';

  const mode = getCurrentEditorMode(editorAPI);
  const selectionType = getSectionLikeSelectionType(
    editorAPI,
    compRef,
    selectionData,
  );

  return pickSkinFromSchema(sectionSchema, mode, selectionType);
};

const getSiteSegmentActionsSideActionsSkin: SOAPSkinGetter = (
  editorAPI,
  compRef,
  selectionData,
) => {
  if (!sectionsUtils.isSectionsControlsRedesignEnabled()) return 'legacy-soap';

  const mode = getCurrentEditorMode(editorAPI);
  const selectionType = getSectionLikeSelectionType(
    editorAPI,
    compRef,
    selectionData,
  );

  return pickSkinFromSchema(siteSegmentSchema, mode, selectionType);
};

export const getSectionLikeActionsSkin: GeneralSkinGetter = (
  editorAPI,
  compRef,
  selectionData,
) => {
  return editorAPI.components.is.siteSegment(compRef)
    ? getSiteSegmentActionsSideActionsSkin(editorAPI, compRef, selectionData)
    : getSectionActionsSideActionsSkin(editorAPI, compRef, selectionData);
};
