/* eslint-disable max-lines */
/*this file is huge, please don't make it even bigger, try to decompose it*/

import {
  addPanelDragToStage,
  anchorsNavigation as AnchorsNavigation,
  dragBox,
} from '@/addPanelInfra';
import constants from '@/constants';
import * as coreBi from '@/coreBi';
import { translate } from '@/i18n';
import * as leftBar from '@/leftBar';
import * as mediaManagerPanelInfra from '@/mediaManagerPanelInfra';
import {
  constants as mediaManagerPanelConstants,
  NoSearchResults,
  UploadButton,
  SearchLoader,
} from '@/mediaManagerPanelInfra';
import { frames } from '@/panels';
import * as uiAnimations from '@/uiAnimations';
import * as util from '@/util';
import { cx, sections as sectionsUtils } from '@/util';
import { ErrorReporter } from '@wix/editor-error-reporter';
import {
  createDuplexerChannel,
  createSource,
  PrivateSite,
  Public,
  Recommended,
  Shutterstock,
} from '@wix/media-manager-sdk';
import { TextLabel, Button, CustomScroll } from '@wix/wix-base-ui';
import experiment from 'experiment';
import _ from 'lodash';
import * as MediaImageStudio from '@wix/media-image-studio-opener';
import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'reactDOM';
import * as Symbols from '@wix/santa-editor-symbols';
import * as UserFeedbackOpener from 'UserFeedbackOpener';
import * as VideoMakerOpener from '@wix/video-maker-opener';
import {
  MediaChannelEventType,
  type MediaChannel,
  type MediaManagerPanelProps,
  type MediaManagerPanelState,
  type MediaChannelMessage,
} from './MediaManagerPanel.types';
import { mapDispatchToProps, mapStateToProps } from './mediaManagerPanelMapper';
import type { CompRef, Point } from 'types/documentServices';

const {
  sections: { addMedia: AddMediaSection },
  presets: {
    presetSection: PresetSection,
    search: Search,
    recommendedMediaCard: RecommendedMediaCard,
    recommendedMediaSection: RecommendedMediaSection,
    createMediaSection: CreateMediaSection,
  },
} = mediaManagerPanelInfra;

const { hoc, fedopsLogger } = util;
const {
  connect,
  STORES: { EDITOR_API },
  withDragToStage,
} = hoc;
const {
  utils: mediaManagerPanelUtils,
  purchaseUtils: mediaManagerPurchaseUtils,
  wixMediaUtils,
} = mediaManagerPanelInfra;
const mediaManagerPanelBiEvents = coreBi.events.mediaEditorPanel;
const createMediaChannel = _.once(
  (settings): { channel: MediaChannel; destroy: VoidFunction } =>
    createDuplexerChannel(settings),
);

const { MEDIA_MANAGER_MEDIA_TYPES: mediaManagerMediaTypes } = constants;

const { OpenerEventTypes, CloseReason } = UserFeedbackOpener;

const { MediaImageStudioEvents, MediaImageStudioMode } = MediaImageStudio;

const {
  MEDIA_MANAGER_PANEL_TITLE,
  SHUTTERSTOCK_MEDIA_TYPE,
  MEDIA_MANAGER_PANEL_SECTION_ID,
  SECTIONS_TITLES_MAP,
  ITEMS_TO_LOAD_COUNT,
  RECOMMENDED_ITEMS_TO_LOAD_COUNT,
  RECOMMENDED_SECTION_INITIAL_VISIBLE_ITEMS_COUNT,
  PUBLIC_MEDIA_ROOT,
  PUBLIC_MEDIA_ROOT_MEDIA_TYPE_MAP,
  SHUTTERSTOCK_PAYMENT_API,
  MEDIA_SOURCES,
  TRANSCODING_STATUSES,
  NAVIGATION_TOOLTIP_KEYS,
  NAVIGATION_MFW_TOOLTIP_KEYS,
  MEDIA_MANAGER_OPEN_SOURCE_BASE,
  MEDIA_MANAGER_OPEN_OPTIONS_WHEN_SEARCH,
  MEDIA_MANAGER_OPEN_OPTIONS,
  MEDIA_MANAGER_UPLOAD_SOURCES,
  WIX_MEDIA_SECTIONS_LIST_FOR_FULL_INFO,
  ADD_TO_STAGE_METHOD,
  RECOMMENDED_CARD_TOPICS,
  RECOMMENDED_MEDIA_QUESTION,
  RECOMMENDED_MEDIA_SECTION_ITEMS_WIX_MEDIA_ROOTS,
  RECOMMENDED_MEDIA_BUTTONS,
  MEDIA_PANEL_NEVER_SHOW_RECOMMENDED_CARD_KEY,
  RECOMMENDED_MEDIA_PRESENCE_TYPE,
  WIX_MEDIA_ITEMS_FULL_INFO_LOAD_MAX_RETRY_COUNT,
  ITEMS_LOADING_OPTIONS,
} = mediaManagerPanelConstants;

const { IMAGES, VECTOR_ART, ILLUSTRATIONS, SOCIAL_ICONS, VIDEOS } =
  PUBLIC_MEDIA_ROOT;

const STICKY_SEARCH_SCROLL_TOP_THRESHOLD = 50;

const baseSectionsOrder = [
  MEDIA_MANAGER_PANEL_SECTION_ID.ADD_MEDIA,
  MEDIA_MANAGER_PANEL_SECTION_ID.SITE_FILES,
  MEDIA_MANAGER_PANEL_SECTION_ID.RECOMMENDED_MEDIA_CARD,
  MEDIA_MANAGER_PANEL_SECTION_ID.RECOMMENDED_MEDIA,
  MEDIA_MANAGER_PANEL_SECTION_ID.CREATE_MEDIA,
  MEDIA_MANAGER_PANEL_SECTION_ID.WIX_IMAGES,
  MEDIA_MANAGER_PANEL_SECTION_ID.WIX_VIDEOS,
  MEDIA_MANAGER_PANEL_SECTION_ID.WIX_VECTOR_ART,
  MEDIA_MANAGER_PANEL_SECTION_ID.WIX_ILLUSTRATIONS,
  MEDIA_MANAGER_PANEL_SECTION_ID.WIX_SOCIAL_ICONS,
  MEDIA_MANAGER_PANEL_SECTION_ID.SHUTTERSTOCK_IMAGES,
  MEDIA_MANAGER_PANEL_SECTION_ID.SHUTTERSTOCK_VIDEOS,
];

class MediaManagerPanel extends React.Component<
  MediaManagerPanelProps,
  MediaManagerPanelState
> {
  private openTimeStart: number;
  private regularSectionsLoadingFinishTime: number;
  private recommendedMediaLoadingTime: {
    start: number;
    end: number;
  };
  private shouldRefreshPanelAfterPurchaseItemTransferring: boolean;
  private isWixMediaItemsFullInfoLoaded: boolean;
  private addMediaSection: { id: string; title: string };
  private createMediaSection: { id: string };
  private sectionsOrder: string[];

  private privateMediaAdapter: AnyFixMe;
  private publicMediaAdapter: AnyFixMe;
  private shutterStockAdapter: AnyFixMe;
  private recommendedMediaAdapter: AnyFixMe;
  private mediaChannel: ReturnType<typeof createMediaChannel>;

  private publicMediaFoldersTreePromise: Promise<AnyFixMe>;
  private publicMediaSubCategoryPublicMediaRootMap: AnyFixMe;
  private sectionToPublicMediaSubcategoryPathMap: AnyFixMe;
  private sectionsGaps: AnyFixMe;
  private purchasableItemSearchQuery: AnyFixMe;

  private recommendedMediaCardDotsActions: AnyFixMe;
  private searchTimeStart: number;
  private readonly isNewWorkspace: boolean;

  constructor(props: AnyFixMe) {
    super(props);

    this.isNewWorkspace = util.workspace.isNewWorkspaceEnabled();
    this.openTimeStart = mediaManagerPanelUtils.timeNow();
    this.regularSectionsLoadingFinishTime = null;
    this.recommendedMediaLoadingTime = null;
    this.shouldRefreshPanelAfterPurchaseItemTransferring = false;
    this.isWixMediaItemsFullInfoLoaded = false;

    this.addMediaSection = {
      id: MEDIA_MANAGER_PANEL_SECTION_ID.ADD_MEDIA,
      title: translate(
        SECTIONS_TITLES_MAP[MEDIA_MANAGER_PANEL_SECTION_ID.ADD_MEDIA],
      ),
    };
    this.createMediaSection = {
      id: MEDIA_MANAGER_PANEL_SECTION_ID.CREATE_MEDIA,
    };
    this.sectionsOrder = baseSectionsOrder;

    const previousState = this.getPreviousPanelState() || {};

    this.state = _.defaults(
      _.pick(previousState, [
        'isSearching',
        'searchQuery',
        'recommendedMediaCategories',
        'recommendedMediaCacheId',
        'recommendedTopicsList',
        'recommendedSectionVisibleItemsCount',
        'shouldHideRecommendedMediaCardForCurrentSession',
      ]),
      {
        areAllRegularSectionsLoaded: false,
        isSearching: false,
        isSearchBoxSticky: false,
        searchQuery: '',
        sections: {},
        mediaPriceInfo: null,
        isRecommendedSectionLoadWithMocks: true,
        recommendedTopicsList: [],
        recommendedMediaCacheId: this.generateRecommendedMediaCacheId(),
        recommendedMediaCategories: null,
        recommendedSectionVisibleItemsCount:
          RECOMMENDED_SECTION_INITIAL_VISIBLE_ITEMS_COUNT,
        shouldHideRecommendedMediaCardForCurrentSession: false,
        neverShowRecommendedMedia: props.isRecommendedCardAlwaysHidden,
        selectedNavigationSection: null,
        loadingError: null,
      },
    );

    const mediaManagerSDKAdapterCommonOptions = {
      siteMediaToken: props.siteMediaToken,
      version: 'G6',
      useNewSiteFilesEndpoints: true,
    };
    const mediaManagerSDKPublicMediaAdapterOptions = {
      ...mediaManagerSDKAdapterCommonOptions,
      thumbnailSizes: {
        thumbnail: {
          width: 90,
          height: 90,
        },
      },
    };

    this.privateMediaAdapter = createSource(
      PrivateSite,
      mediaManagerSDKAdapterCommonOptions,
    );
    this.publicMediaAdapter = createSource(
      Public,
      mediaManagerSDKPublicMediaAdapterOptions,
    );
    this.shutterStockAdapter = createSource(Shutterstock, {
      ...mediaManagerSDKAdapterCommonOptions,
      country: util.editorModel.geo,
    });

    if (this.isRecommendedMediaExperimentEnabled && !this.isTemplateSite) {
      const recommendedMediaAdapterOptions = {
        xsrfToken: util.cookie.getCookie('XSRF-TOKEN'),
        itemsCacheCapacity: 1,
        apiUrl: `${window.location.protocol}//editor.wix.com`,
        topicsListApiUrl: `${window.location.protocol}//editor.wix.com`,
        ...mediaManagerSDKAdapterCommonOptions,
      };

      this.recommendedMediaAdapter =
        previousState.recommendedMediaAdapter ||
        createSource(Recommended, recommendedMediaAdapterOptions);
    }

    this.mediaChannel = createMediaChannel({
      siteMediaToken: this.props.siteMediaToken,
    });

    this.publicMediaFoldersTreePromise = null;
    this.publicMediaSubCategoryPublicMediaRootMap = null;
    this.sectionToPublicMediaSubcategoryPathMap = {};
    this.sectionsGaps = null;
    this.purchasableItemSearchQuery = null;

    if (this.isRecommendedMediaExperimentEnabled) {
      const doThisLater = {
        id: RECOMMENDED_MEDIA_BUTTONS.DO_THIS_LATER,
        label: translate('Media_Panel_Recommended_Section_EmptyState_Later'),
        onClick: () => this.handleRecommendedMediaCardDoThisLaterClick(),
      };

      const dontShowThisAgain = {
        id: RECOMMENDED_MEDIA_BUTTONS.DONT_SHOW_AGAIN,
        label: translate(
          'Media_Panel_Recommended_Section_EmptyState_DontShowAgain',
        ),
        onClick: () => this.handleRecommendedMediaCardDontShowThisAgainClick(),
      };

      this.recommendedMediaCardDotsActions = [doThisLater, dontShowThisAgain];
    }

    _.bindAll(this, [
      'setSections',
      'setAllRegularSectionsLoaded',
      'startSearch',
      'clearSearch',
      'loadDataAndCreateSection',
      'loadRecommendedMedia',
      'refreshPanel',
      'getItemPrice',
      'buyItem',
      'startItemDrag',
      'preventRightClick',
      'loadWixMediaItemsFullInfoAndUpdateSections',
      'updateSectionsWithWixMediaItemsFullInfo',
      'handleScroll',
      'getSections',
      'handleLoadingError',
      'handleAddMediaButtonClick',
      'handleUploadMediaButtonClick',
      'handleItemClick',
      'handleSocketMessage',
      'handleSearchBlur',
      'handleRecommendedMediaCardApplyClick',
      'handleRecommendedCategoriesSeeAllButtonClick',
      'handleRecommendedMediaSectionSettingsButtonClick',
      'handleRecommendedMediaCardThreeDotsClick',
      'handleRecommendedMediaSectionShowMoreButtonClick',
      'handleCreateImageActionClicked',
      'handleCreateVideoActionClicked',
      'trackFedopsInteractionStarted',
      'trackFedopsInteractionEnded',
      'openMediaStudio',
      'openMediaStudioOnMountIfNecessary',
      'openVideoMaker',
      'openVideoMakerOnMountIfNecessary',
      'openMediaManagerOnMountIfNecessary',
    ]);
  }

  get panelTitle() {
    return translate(MEDIA_MANAGER_PANEL_TITLE);
  }

  get areAllRegularSectionsLoaded() {
    return this.state.areAllRegularSectionsLoaded;
  }

  get isSearching() {
    return this.state.isSearching;
  }

  get searchQuery() {
    return this.state.searchQuery;
  }

  get isPanelLoadedWithError() {
    return Boolean(this.state.loadingError);
  }

  get hasFoundNothing() {
    return (
      this.isSearching &&
      this.areAllRegularSectionsLoaded &&
      !this.hasSectionItems()
    );
  }

  get tryAgainButtonText() {
    return translate('Media_Panel_Server_Error_Link');
  }

  get isNotAuthorized() {
    const { loadingError } = this.state;

    return (
      (Boolean(loadingError) && loadingError.code === 401) ||
      loadingError.code === 403
    );
  }

  get loaderText() {
    return this.state.isSearching
      ? 'Media_Panel_Results_Loader'
      : 'Media_Panel_Loading';
  }

  get errorTitle() {
    return this.isNotAuthorized
      ? 'Media_Panel_Error_Timed_Out_Title'
      : 'Media_Panel_Server_Error_Title';
  }

  get errorText() {
    return this.isNotAuthorized
      ? 'Media_Panel_Error_Timed_Out_Text'
      : 'Media_Panel_Server_Error_Text';
  }

  get hasRecentlyAddedSection() {
    return Boolean(
      this.getSections()[MEDIA_MANAGER_PANEL_SECTION_ID.SITE_FILES]?.items
        ?.length ?? 0,
    );
  }

  getSectionsJSONData() {
    const { sectionsOrder } = this;

    const data = sectionsOrder.reduce((sectionsData, sectionId) => {
      const section = this.getSections()?.[sectionId] || {};
      const itemIds = (section?.items || []).map(({ id }: AnyFixMe) => id);

      return itemIds.length
        ? {
            ...sectionsData,
            [mediaManagerPanelUtils.sectionIdToBiField(sectionId)]: itemIds,
          }
        : sectionsData;
    }, {});

    return JSON.stringify(data);
  }

  get selectedNavigationSection() {
    return this.state.selectedNavigationSection;
  }

  get customScrollOffsetTop() {
    return this.customScrollInstance.offsetTop || 0;
  }

  get customScrollInstance() {
    return ReactDOM.findDOMNode(this.customScrollRef);
  }

  get customScrollRef() {
    return this.refs.customScroll;
  }

  get visibleSections() {
    const { sections } = this.state;

    return this.sectionsOrder.reduce((visibleSections, sectionId) => {
      if (this.isAddMediaSection(sectionId)) {
        return [...visibleSections, this.addMediaSection];
      } else if (
        this.isCreateMediaSection(sectionId) &&
        this.createMediaSectionCanBeShown()
      ) {
        return [...visibleSections, this.createMediaSection];
      }

      const section = sections[sectionId];

      return section && !_.isEmpty(section.items)
        ? [...visibleSections, section]
        : visibleSections;
    }, []);
  }

  get shouldRenderNavigation() {
    return (
      !this.isSearching &&
      this.areAllRegularSectionsLoaded &&
      this.selectedNavigationSection &&
      !this.isNewWorkspace
    );
  }

  get lastUsedPrivateMediaPath() {
    return (
      this.getSessionUserPreferences(
        `last_media_path_${MEDIA_MANAGER_PANEL_SECTION_ID.SITE_FILES}`,
      ) || MEDIA_SOURCES.PRIVATE
    );
  }

  get isMediaFromWixExperimentEnabled() {
    return experiment.isOpen('se_mediaPanelShowPaidMediaFromWix');
  }

  get isRecommendedMediaExperimentEnabled() {
    return experiment.isOpen('se_mediaPanelShowRecommendedMedia');
  }

  get isShowCreateMediaSectionExperimentEnabled() {
    return experiment.isOpen('se_mediaPanelShowCreateMediaSection');
  }

  get isNewCheckoutFlowExperimentEnabled() {
    return experiment.isOpen('specs.media-manager-g6.NewCheckout');
  }

  get isSearchBoxSticky() {
    return this.state.isSearchBoxSticky;
  }

  get recommendedTopicsList() {
    return this.state.recommendedTopicsList;
  }

  get recommendedCardTopics() {
    const allTopics = _.keyBy(this.recommendedTopicsList, 'id');

    return RECOMMENDED_CARD_TOPICS.map((id) => allTopics[id]).filter(Boolean);
  }

  get isRecommendedSectionLoadWithMocks() {
    return this.state.isRecommendedSectionLoadWithMocks;
  }

  get shouldRenderRecommendedMediaCard() {
    const { recommendedMediaCategories } = this;

    return (
      !this.shouldHideRecommendedMediaCardForCurrentSession &&
      !this.neverShowRecommendedMedia &&
      !this.isSearching &&
      recommendedMediaCategories !== null &&
      _.isEmpty(recommendedMediaCategories)
    );
  }

  get recommendedMediaCategories() {
    return this.state.recommendedMediaCategories;
  }

  get recommendedMediaCacheId() {
    return this.state.recommendedMediaCacheId;
  }

  get recommendedSectionVisibleItemsCount() {
    return this.state.recommendedSectionVisibleItemsCount;
  }

  get shouldHideRecommendedMediaCardForCurrentSession() {
    return this.state.shouldHideRecommendedMediaCardForCurrentSession;
  }

  get neverShowRecommendedMedia() {
    return this.state.neverShowRecommendedMedia;
  }

  get isTemplateSite() {
    return (
      this.props.isTemplateSite ||
      mediaManagerPanelUtils.isSiteMediaTokenFromTemplateSite(
        this.props.siteMediaToken,
      )
    );
  }

  getSections() {
    return this.state.sections;
  }

  isWixVideoItem(item: AnyFixMe) {
    return (
      mediaManagerPanelUtils.isVideoItem(item) &&
      mediaManagerPanelUtils.isWixMediaItem(item)
    );
  }

  setWixMediaItemsFullInfoLoaded(isLoaded: boolean) {
    this.isWixMediaItemsFullInfoLoaded = isLoaded;
  }

  getRecommendedMediaSectionKey() {
    return `${
      MEDIA_MANAGER_PANEL_SECTION_ID.RECOMMENDED_MEDIA
    }_${this.recommendedMediaCategories.join(',')}_${
      this.recommendedSectionVisibleItemsCount
    }`;
  }

  generateRecommendedMediaCacheId() {
    return _.uniqueId('emp-cache-');
  }

  updateRecommendedMediaCacheId() {
    this.setState({
      recommendedMediaCacheId: this.generateRecommendedMediaCacheId(),
    });
  }

  setRecommendedSectionVisibleItemsCount(itemsCount: AnyFixMe) {
    this.setState({ recommendedSectionVisibleItemsCount: itemsCount });
  }

  setRecommendedSectionLoadWithMocks(
    isRecommendedSectionLoadWithMocks: AnyFixMe,
  ) {
    this.setState({ isRecommendedSectionLoadWithMocks });
  }

  resetPurchasableItemSearchQuery() {
    this.purchasableItemSearchQuery = null;
  }

  getSessionUserPreferences(key: AnyFixMe) {
    return this.props.sessionUserPreferences[key];
  }

  setSessionUserPreferences(key: AnyFixMe, value: AnyFixMe) {
    this.props.setSessionUserPreferences(key, value);
  }

  getPreviousPanelState() {
    return this.getSessionUserPreferences('media_editor_panel_previous_state');
  }

  savePanelStateBeforeClose() {
    const { state } = this;

    const stateToSave = _.pick(state, [
      ...(this.isSearching ? ['isSearching', 'searchQuery'] : []),
      ...(this.isRecommendedMediaExperimentEnabled
        ? [
            'shouldHideRecommendedMediaCardForCurrentSession',
            'recommendedMediaCategories',
            'recommendedMediaCacheId',
            'recommendedTopicsList',
            'recommendedSectionVisibleItemsCount',
          ]
        : []),
    ]);

    if (this.isRecommendedMediaExperimentEnabled && !this.isTemplateSite) {
      (stateToSave as any).recommendedMediaAdapter =
        this.recommendedMediaAdapter;
    }

    this.setSessionUserPreferences(
      'media_editor_panel_previous_state',
      stateToSave,
    );
  }

  isAddMediaSection(sectionId: AnyFixMe) {
    return mediaManagerPanelUtils.isAddMediaSection(sectionId);
  }

  isRecommendedMediaCard(sectionId: AnyFixMe) {
    return mediaManagerPanelUtils.isRecommendedMediaCard(sectionId);
  }

  isRecommendedMediaSection(sectionId: AnyFixMe) {
    return mediaManagerPanelUtils.isRecommendedMediaSection(sectionId);
  }

  recommendedMediaSectionCanBeShown(section: AnyFixMe) {
    return (
      !this.isSearching &&
      section &&
      !_.isEmpty(this.recommendedMediaCategories)
    );
  }

  isCreateMediaSection(sectionId: AnyFixMe) {
    return mediaManagerPanelUtils.isCreateMediaSection(sectionId);
  }

  createMediaSectionCanBeShown() {
    return this.isShowCreateMediaSectionExperimentEnabled && !this.isSearching;
  }

  isRegularSection(sectionId: AnyFixMe) {
    return (
      !this.isAddMediaSection(sectionId) &&
      !this.isRecommendedMediaCard(sectionId) &&
      !this.isRecommendedMediaSection(sectionId) &&
      !this.isCreateMediaSection(sectionId)
    );
  }

  sectionCanBeShown(section: AnyFixMe) {
    return section?.items.length;
  }

  isSocialIconsRoot(folderId: AnyFixMe) {
    return mediaManagerPanelUtils.isSocialIconsRoot(
      this.publicMediaSubCategoryPublicMediaRootMap[folderId],
    );
  }

  getSectionRef(id: AnyFixMe) {
    return `Section_${id}`;
  }

  getSectionInstance(id: AnyFixMe) {
    return ReactDOM.findDOMNode(this.refs[this.getSectionRef(id)]);
  }

  setSelectedNavigationSection(selectedNavigationSection: AnyFixMe) {
    this.setState({ selectedNavigationSection });
  }

  setSections(sections: AnyFixMe) {
    this.setState({ sections });
  }

  setRecommendedMediaCategoriesAndClearRecommendedMediaSection(
    categories: AnyFixMe,
  ) {
    this.setState({
      recommendedMediaCategories: categories,
      sections: _.isEmpty(categories)
        ? this.getSections()
        : this.clearRecommendedMediaSection(),
    });
  }

  updateSingleSection(section: AnyFixMe) {
    this.setState({
      sections: { ...this.getSections(), [section.id]: section },
    });
  }

  clearSingleSection(sectionId: AnyFixMe, options = {}) {
    return {
      ...this.getSections(),
      [sectionId]: mediaManagerPanelUtils.createEmptySection(
        this.isSearching,
        sectionId,
        options,
      ),
    };
  }

  setAllRegularSectionsLoaded() {
    this.setState({ areAllRegularSectionsLoaded: true });
  }

  hasSectionItems() {
    const { sections } = this.state;

    return (
      sections &&
      Object.values(sections)
        .map(({ items }) => items)
        .reduce(
          (totalCount, sectionItems) => totalCount + sectionItems.length,
          0,
        )
    );
  }

  shouldRenderAddMediaSection() {
    return !this.isSearching;
  }

  preventRightClick(event: AnyFixMe) {
    if (event.target.nodeName !== 'INPUT') {
      event.preventDefault();
    }
  }

  sendSentryError(error: Error | unknown, operation: string) {
    ErrorReporter.captureException(error, {
      tags: { isMediaPanel: true, operation },
    });
  }

  handleLoadingError(error: AnyFixMe) {
    this.sendSentryError(error, 'panelLoading');
    this.props.sendBiError(util.bi.errors.MEDIA_PANEL.PANEL_FAILED_TO_LOAD, {
      errorMessage: error.message,
    });

    this.setState({
      areAllRegularSectionsLoaded: false,
      isSearching: false,
      searchQuery: '',
      sections: {},
      loadingError: error,
    });
  }

  getRecommendedMediaCardActions() {
    return this.recommendedMediaCardDotsActions;
  }

  updateRecommendedMediaCategories(categories: AnyFixMe) {
    return this.recommendedMediaAdapter.preferences.updateMediaCategories(
      categories,
      {
        msid: this.props.metaSiteId,
      },
    );
  }

  loadSiteRecommendedTopics() {
    if (this.recommendedMediaCategories) {
      return Promise.resolve(this.recommendedMediaCategories);
    }

    return this.recommendedMediaAdapter.preferences
      .mediaCategories({
        msid: this.props.metaSiteId,
      })
      .then((response: AnyFixMe) => response?.data ?? []);
  }

  loadAllRecommendedTopics() {
    if (!_.isEmpty(this.recommendedTopicsList)) {
      return Promise.resolve(this.recommendedTopicsList);
    }

    return this.recommendedMediaAdapter.topics
      .list({ locale: this.props.language })
      .then((response: AnyFixMe) => response.data);
  }

  handleRecommendedCategoriesApply(categories: AnyFixMe) {
    this.setRecommendedSectionVisibleItemsCount(
      RECOMMENDED_SECTION_INITIAL_VISIBLE_ITEMS_COUNT,
    );
    this.setRecommendedMediaCategoriesAndClearRecommendedMediaSection(
      categories,
    );
    this.updateRecommendedMediaCacheId();

    this.updateRecommendedMediaCategories(categories).then(() => {
      this.loadRecommendedMediaDataAndCreateSection();
    });
  }

  handleRecommendedMediaCardApplyClick(categories: AnyFixMe) {
    this.trackRecommendedSectionButtonClicked(
      RECOMMENDED_MEDIA_BUTTONS.APPLY,
      categories,
    );

    this.handleRecommendedCategoriesApply(categories);
  }

  handleRecommendedMediaCardDoThisLaterClick() {
    this.trackRecommendedSectionButtonClicked(
      RECOMMENDED_MEDIA_BUTTONS.DO_THIS_LATER,
      this.recommendedMediaCategories,
    );
    this.setState({ shouldHideRecommendedMediaCardForCurrentSession: true });
  }

  handleRecommendedMediaCardDontShowThisAgainClick() {
    this.trackRecommendedSectionButtonClicked(
      RECOMMENDED_MEDIA_BUTTONS.DONT_SHOW_AGAIN,
      this.recommendedMediaCategories,
    );
    this.props.setSiteUserPreferences(
      MEDIA_PANEL_NEVER_SHOW_RECOMMENDED_CARD_KEY,
      true,
    );
    this.setState({
      neverShowRecommendedMedia: true,
      shouldHideRecommendedMediaCardForCurrentSession: true,
    });
  }

  openRecommendedCategoriesSelectWindow(alreadySelectedCategories: AnyFixMe) {
    const { userFeedback } = this.props;

    userFeedback.showQuestion(
      // @ts-expect-error
      _.merge({}, RECOMMENDED_MEDIA_QUESTION, {
        feedbackModel: {
          answer: {
            tags: mediaManagerPanelUtils.selectExpandedCategories(
              alreadySelectedCategories,
              this.recommendedTopicsList,
            ),
          },
        },
      }),
    );

    const subscription = userFeedback.addListener(
      OpenerEventTypes.Closed,
      ({ closeEventPayload }: AnyFixMe) => {
        subscription.remove();

        const { closeReason, questionResult } = closeEventPayload;

        if (_.isEqual(closeReason, CloseReason.Canceled)) {
          this.trackRecommendedSectionButtonClicked(
            RECOMMENDED_MEDIA_BUTTONS.FEEDBACK_CANCEL,
            this.recommendedMediaCategories,
          );

          return;
        }

        if (!_.isEqual(closeReason, CloseReason.FinishedFlow)) {
          return;
        }

        const categories = questionResult?.questionAnswer?.output?.tags.map(
          ({ id }: AnyFixMe) => id,
        );

        this.trackRecommendedSectionButtonClicked(
          RECOMMENDED_MEDIA_BUTTONS.FEEDBACK_APPLY,
          categories,
        );
        this.handleRecommendedCategoriesApply(categories);
      },
    );
  }

  handleRecommendedCategoriesSeeAllButtonClick(
    alreadySelectedCategories: AnyFixMe,
  ) {
    this.trackRecommendedSectionButtonClicked(
      RECOMMENDED_MEDIA_BUTTONS.SEE_ALL,
      this.recommendedMediaCategories,
    );

    this.openRecommendedCategoriesSelectWindow(alreadySelectedCategories);
  }

  handleRecommendedMediaSectionSettingsButtonClick() {
    this.trackRecommendedSectionButtonClicked(
      RECOMMENDED_MEDIA_BUTTONS.SETTINGS,
      this.recommendedMediaCategories,
    );

    this.openRecommendedCategoriesSelectWindow(this.recommendedMediaCategories);
  }

  handleRecommendedMediaCardThreeDotsClick() {
    this.trackRecommendedSectionButtonClicked(
      RECOMMENDED_MEDIA_BUTTONS.DOTS,
      this.recommendedMediaCategories,
    );
  }

  handleRecommendedMediaSectionShowMoreButtonClick(
    nextVisibleItemsCount: AnyFixMe,
  ) {
    this.trackRecommendedSectionButtonClicked(
      RECOMMENDED_MEDIA_BUTTONS.SHOW_MORE,
      this.recommendedMediaCategories,
    );

    this.setRecommendedSectionVisibleItemsCount(nextVisibleItemsCount);
  }

  loadRecommendedMedia() {
    const recommendedMediaLoadingTimeStart = mediaManagerPanelUtils.timeNow();
    this.recommendedMediaLoadingTime = {
      start: recommendedMediaLoadingTimeStart,
      end: recommendedMediaLoadingTimeStart,
    };

    if (!this.isRecommendedMediaExperimentEnabled) {
      return;
    }

    if (
      this.isSearching ||
      this.shouldHideRecommendedMediaCardForCurrentSession ||
      this.isTemplateSite ||
      this.neverShowRecommendedMedia
    ) {
      return;
    }

    return Promise.all([
      this.loadSiteRecommendedTopics(),
      this.loadAllRecommendedTopics(),
    ]).then(([categories, topicsList]) => {
      if (_.isEmpty(this.recommendedTopicsList)) {
        this.setState({ recommendedTopicsList: topicsList });
      }

      this.setRecommendedMediaCategoriesAndClearRecommendedMediaSection(
        categories,
      );

      this.saveRecommendedMediaLoadingEndTime();

      if (!_.isEmpty(categories)) {
        return this.loadRecommendedMediaDataAndCreateSection();
      }
    });
  }

  loadRecommendedMediaDataAndCreateSection() {
    const recommendedMediaSectionId =
      MEDIA_MANAGER_PANEL_SECTION_ID.RECOMMENDED_MEDIA;

    return this.loadDataAndCreateSection({
      sectionId: recommendedMediaSectionId,
      sectionDataLoader: this.loadRecommendedSectionData(),
      sectionOptions: {
        onItemClick: this.handleItemClick,
        startItemDrag: this.startItemDrag,
        getItemPrice: this.getItemPrice,
        buyItem: this.buyItem,
      },
      action: () => {
        this.handleSectionShowMoreButtonClicked(recommendedMediaSectionId);
      },
    })
      .then((section: AnyFixMe) => {
        this.setRecommendedSectionLoadWithMocks(false);

        return this.updateSingleSection(section);
      })
      .then(() => this.saveRecommendedMediaLoadingEndTime());
  }

  handleAddMediaButtonClick(options: AnyFixMe) {
    this.trackFedopsInteractionStarted(
      fedopsLogger.INTERACTIONS.MEDIA_MANAGER_PANEL_ADD_MEDIA_CLICK,
    );
    const { source } = options;

    const isUploadAddMediaSource =
      mediaManagerPanelUtils.isUploadAddMediaSource(source);

    this.sendMediaPanelBi(mediaManagerPanelBiEvents.UPLOAD_BUTTON_CLICK, {
      button: isUploadAddMediaSource ? 'section_main' : source,
    });

    this.openMediaManager(
      MEDIA_MANAGER_PANEL_SECTION_ID.SITE_FILES,
      _.pick(options, ['path']),
      mediaManagerPanelUtils.generateMediaManagerAddMediaSectionOpenSource(
        source,
      ),
    );

    this.trackFedopsInteractionEnded(
      fedopsLogger.INTERACTIONS.MEDIA_MANAGER_PANEL_ADD_MEDIA_CLICK,
    );
  }

  handleUploadMediaButtonClick() {
    this.trackFedopsInteractionStarted(
      fedopsLogger.INTERACTIONS.MEDIA_MANAGER_PANEL_ADD_MEDIA_CLICK,
    );

    this.sendMediaPanelBi(mediaManagerPanelBiEvents.UPLOAD_BUTTON_CLICK, {
      button: 'main',
    });

    this.openMediaManager(
      MEDIA_MANAGER_PANEL_SECTION_ID.SITE_FILES,
      { path: `external/${MEDIA_MANAGER_UPLOAD_SOURCES.COMPUTER}` },
      `${MEDIA_MANAGER_OPEN_SOURCE_BASE}_upload_media_button`,
    );

    this.trackFedopsInteractionEnded(
      fedopsLogger.INTERACTIONS.MEDIA_MANAGER_PANEL_ADD_MEDIA_CLICK,
    );
  }

  handleSectionShowMoreButtonClicked(sectionId: AnyFixMe) {
    const { searchQuery } = this.state;
    const publicMediaItemsDeepLink =
      this.sectionToPublicMediaSubcategoryPathMap[sectionId];
    let options = this.isSearching
      ? MEDIA_MANAGER_OPEN_OPTIONS_WHEN_SEARCH[sectionId](
          searchQuery,
          publicMediaItemsDeepLink,
        )
      : MEDIA_MANAGER_OPEN_OPTIONS[sectionId](publicMediaItemsDeepLink);

    if (
      mediaManagerPanelUtils.isRecentlyAddedSection(sectionId) &&
      !this.isSearching
    ) {
      options = { ...options, path: this.lastUsedPrivateMediaPath };
    }

    this.sendEditorBi(
      coreBi.events.myMediaPanel.my_uploads_panel_section_click,
      {
        section: mediaManagerPanelUtils.sectionIdToBiField(sectionId),
      },
    );

    this.openMediaManager(sectionId, options);
  }

  componentDidMount() {
    this.loadSections().then(() => this.trackPanelOpened());
    this.loadMediaPricesInfo();

    this.openMediaStudioOnMountIfNecessary();
    this.openVideoMakerOnMountIfNecessary();
    this.openMediaManagerOnMountIfNecessary();
  }

  openMediaStudio() {
    const { mediaStudio } = this.props;

    const uploadedFileSubscription = mediaStudio.once(
      MediaImageStudioEvents.UploadedFile,
      () => {
        this.refreshPanel();
      },
    );

    const closeMediaStudioSubscription = mediaStudio.once(
      MediaImageStudioEvents.Close,
      () => {
        uploadedFileSubscription.remove();
        closeMediaStudioSubscription.remove();
      },
    );

    mediaStudio.show({
      isUpload: true,
      mode: MediaImageStudioMode.Transform,
      initiator: MEDIA_MANAGER_OPEN_SOURCE_BASE,
    });
  }

  openMediaStudioOnMountIfNecessary() {
    if (this.props.shouldOpenMediaStudioOnMount) {
      this.openMediaStudio();
    }
  }

  handleCreateImageActionClicked() {
    this.openMediaStudio();
  }

  openVideoMaker() {
    const { videoMaker, metaSiteInstance } = this.props;

    const params: VideoMakerOpener.EmptyGenericScenarioParams = {
      siteMediaToken: this.props.siteMediaToken,
      metaSiteId: this.props.metaSiteId,
      userId: this.props.userId,
      locale: this.props.language as any,
      showAddToSite: true,
      scenario: VideoMakerOpener.Scenarios.Generic,
      template: VideoMakerOpener.Templates.Empty,
      videoData: {
        script: VideoMakerOpener.VideoScripts.GENERAL_TEMPLATE,
        business: {},
        scriptData: {},
        sources: [] as AnyFixMe,
      },

      opener: MEDIA_MANAGER_OPEN_SOURCE_BASE,
      origin: 'EDITOR',
    };

    this.initSocketIfNecessary();

    videoMaker.withInstanceGetter(() => metaSiteInstance).open(params);

    const unsubscribeAddToSiteEvent = videoMaker.once(
      VideoMakerOpener.parentIncomingActions.addToSite,
      (action) => {
        const videoId = action?.payload?.fileId;

        if (videoId) {
          this.privateMediaAdapter.item
            .get(videoId)
            .then(({ data: item }: AnyFixMe) => {
              const preparedItem = this.prepareItemBeforeAddComponent(item);

              this.addSingleComponent(
                MEDIA_MANAGER_PANEL_SECTION_ID.CREATE_MEDIA,
                null,
                preparedItem,
                {},
                { shouldClosePanelAfterAdd: false },
              ).then(() => {
                videoMaker.emit(
                  VideoMakerOpener.childIncomingActions.addToSiteSuccessful(),
                );
              });
            });
        }
      },
    );

    videoMaker.once(VideoMakerOpener.parentIncomingActions.closeRequest, () => {
      this.refreshPanel();
      unsubscribeAddToSiteEvent();
    });
  }

  openVideoMakerOnMountIfNecessary() {
    if (this.props.shouldOpenVideoMakerOnMount) {
      this.openVideoMaker();
    }
  }

  handleCreateVideoActionClicked() {
    this.openVideoMaker();
  }

  openMediaManagerOnMountIfNecessary() {
    if (this.props.shouldOpenMediaManagerOnMount) {
      this.openMediaManagerByEditorUrl();
    }
  }

  componentWillUnmount() {
    this.savePanelStateBeforeClose();
    this.unsubscribeSocketEvents();
  }

  initSocketIfNecessary() {
    const { channel } = this.mediaChannel;

    if (channel) {
      channel.on('message', this.handleSocketMessage);
    }
  }

  unsubscribeSocketEvents() {
    const { channel } = this.mediaChannel;

    if (channel) {
      channel.off('message', this.handleSocketMessage);
    }
  }

  handleSocketMessage(data: MediaChannelMessage) {
    const { type } = data;

    if (type === MediaChannelEventType.FileStatusUpdate) {
      const { file } = data;
      const { op_status: opStatus, file_name: fileId } = file;

      if (
        opStatus === TRANSCODING_STATUSES.READY ||
        opStatus === TRANSCODING_STATUSES.FAILED
      ) {
        const shouldRefreshPanel =
          this.isFilePresentInSiteFilesSection(fileId) ||
          !this.isSearching ||
          this.searchQuery === this.purchasableItemSearchQuery;

        if (shouldRefreshPanel) {
          this.resetPurchasableItemSearchQuery();
          this.refreshPanel();
        }
      }
    } else if (type === MediaChannelEventType.CopyFile) {
      const { info } = data;
      const {
        data: { op_status: opStatus },
      } = info;

      if (opStatus === TRANSCODING_STATUSES.READY) {
        this.shouldRefreshPanelAfterPurchaseItemTransferring = false;

        this.refreshPanel();
      }
    }
  }

  isFilePresentInSiteFilesSection(fileId: AnyFixMe) {
    const { items } =
      this.getSections()[MEDIA_MANAGER_PANEL_SECTION_ID.SITE_FILES];

    return items.some(({ id }: AnyFixMe) => id === fileId);
  }

  loadMediaPricesInfo() {
    const { shutterStockAdapter } = this;

    shutterStockAdapter.items
      .prices()
      .then(({ video, picture }: AnyFixMe) => {
        this.setState({
          mediaPriceInfo: {
            shutterstock: {
              [SHUTTERSTOCK_MEDIA_TYPE.IMAGE]: picture,
              [SHUTTERSTOCK_MEDIA_TYPE.VIDEO]: video,
              taxSettings: {
                showPricesWithTax: picture.showPricesWithTax,
                displayTaxType: picture.displayTaxType,
              },
            },
          },
        });
      })
      .catch((error: AnyFixMe) => {
        // eslint-disable-next-line
        console.error('Cannot get price for Shutterstock media:', error);
      });
  }

  getItemPrice(item: AnyFixMe) {
    const { mediaPriceInfo } = this.state;

    if (!mediaPriceInfo || !mediaManagerPurchaseUtils.isItemPurchasable(item)) {
      return null;
    }

    return mediaManagerPurchaseUtils.extractPriceForShutterstockItem(
      item,
      mediaPriceInfo.shutterstock,
    );
  }

  buyItem(item: AnyFixMe, sectionId: AnyFixMe) {
    const { siteMediaToken } = this.props;

    this.shouldRefreshPanelAfterPurchaseItemTransferring = true;

    this.sendMediaPanelBi(
      mediaManagerPanelBiEvents.SHUTTERSTOCK_PURCHASE_BUTTON_CLICK,
      {
        fileId: item.id,
        category: item.mediaType,
        section: mediaManagerPanelUtils.sectionIdToBiField(sectionId),
        mediaProducer: mediaManagerPanelUtils.getMediaProducer(item),
      },
    );

    this.initSocketIfNecessary();

    this.trackFedopsInteractionStarted(
      fedopsLogger.INTERACTIONS.MEDIA_MANAGER_PANEL_BUY_ITEM,
    );

    const isWixMediaItem = mediaManagerPanelUtils.isWixMediaItem(item);

    let purchasePromise: Promise<AnyFixMe>;

    if (this.isNewCheckoutFlowExperimentEnabled) {
      purchasePromise = mediaManagerPurchaseUtils.purchaseItemNewFlow(item, {
        siteToken: siteMediaToken,
        origin: MEDIA_MANAGER_OPEN_SOURCE_BASE,
        tab: `${MEDIA_MANAGER_OPEN_SOURCE_BASE}-${sectionId}${
          this.isSearching ? '-search' : ''
        }`,
        locale: this.props.language,
        publicMediaRoot: Object.values(PUBLIC_MEDIA_ROOT).join(','),
      });
    } else {
      purchasePromise = isWixMediaItem
        ? mediaManagerPurchaseUtils.buyWixMediaItem(item, { siteMediaToken })
        : mediaManagerPurchaseUtils.buyShutterStockItem(item, {
            siteMediaToken,
            shutterStockPaymentAPI: SHUTTERSTOCK_PAYMENT_API,
          });
    }

    purchasePromise
      .then(({ item: purchasedItem, isNew }) => {
        this.purchasableItemSearchQuery = this.searchQuery;

        this.sendMediaPanelBi(
          mediaManagerPanelBiEvents.MEDIA_PURCHASE_COMPLETED,
          {
            fileId: purchasedItem.id,
            name: purchasedItem.name,
            section: mediaManagerPanelUtils.sectionIdToBiField(sectionId),
            mediaType: purchasedItem.mediaType,
            mediaProducer:
              mediaManagerPanelUtils.getMediaProducer(purchasedItem),
            fileFormat: mediaManagerPanelUtils.getFileExtension(purchasedItem),
            isNew,
          },
        );

        this.trackFedopsInteractionEnded(
          fedopsLogger.INTERACTIONS.MEDIA_MANAGER_PANEL_BUY_ITEM,
        );

        if (typeof isNew !== 'undefined' && !isNew) {
          setTimeout(() => {
            if (this.shouldRefreshPanelAfterPurchaseItemTransferring) {
              this.refreshPanel();
              this.shouldRefreshPanelAfterPurchaseItemTransferring = false;
            }
          }, 2500);
        }
      })
      .catch(() => {
        this.trackFedopsInteractionEnded(
          fedopsLogger.INTERACTIONS.MEDIA_MANAGER_PANEL_BUY_ITEM,
        );
      });
  }

  startSearch(query: AnyFixMe) {
    const { isSearching, searchQuery: previousSearchQuery } = this.state;
    const queryLength = query.length;

    if (
      (!isSearching && queryLength) ||
      (isSearching && query !== previousSearchQuery)
    ) {
      this.searchTimeStart = mediaManagerPanelUtils.timeNow();

      this.setState(
        {
          isSearching: Boolean(queryLength),
          areAllRegularSectionsLoaded: false,
          searchQuery: query,
        },
        () => {
          this.sendMediaPanelBi(mediaManagerPanelBiEvents.SEARCH_START, {
            target: query,
          });

          this.trackFedopsInteractionStarted(
            fedopsLogger.INTERACTIONS.MEDIA_MANAGER_PANEL_SEARCH,
          );

          this.loadSections().then(() => this.trackSearchFinish());
        },
      );
    }
  }

  trackSearchFinish() {
    this.sendMediaPanelBi(mediaManagerPanelBiEvents.SEARCH_FINISH, {
      target: this.searchQuery,
      success: !this.hasFoundNothing,
      loadingTime: mediaManagerPanelUtils.timeNow() - this.searchTimeStart,
      jsonData: this.getSectionsJSONData(),
    });

    this.trackFedopsInteractionEnded(
      fedopsLogger.INTERACTIONS.MEDIA_MANAGER_PANEL_SEARCH,
    );
  }

  clearSearch() {
    if (this.isSearching) {
      this.trackFedopsInteractionStarted(
        fedopsLogger.INTERACTIONS.MEDIA_MANAGER_PANEL_CLEAR_SEARCH,
      );

      this.sendMediaPanelBi(mediaManagerPanelBiEvents.SEARCH_CANCEL, {
        target: this.state.searchQuery,
      });

      this.setState(
        {
          isSearching: false,
          areAllRegularSectionsLoaded: false,
          searchQuery: '',
        },
        () => {
          this.loadSections();
        },
      );

      this.trackFedopsInteractionEnded(
        fedopsLogger.INTERACTIONS.MEDIA_MANAGER_PANEL_CLEAR_SEARCH,
      );
    }
  }

  clearRecommendedMediaSection() {
    return this.clearSingleSection(
      MEDIA_MANAGER_PANEL_SECTION_ID.RECOMMENDED_MEDIA,
      {
        shouldShowLoaderWhenNoItems: true,
        mockLoadingItemsCount: this.isRecommendedSectionLoadWithMocks
          ? RECOMMENDED_SECTION_INITIAL_VISIBLE_ITEMS_COUNT
          : 0,
      },
    );
  }

  loadDataAndCreateSection({
    sectionId,
    sectionDataLoader,
    sectionOptions = {},
    action,
  }: AnyFixMe) {
    return sectionDataLoader.then(
      ({ data, paging, _folderId: folderId }: AnyFixMe) => {
        const section = mediaManagerPanelUtils.createEmptySection(
          this.isSearching,
          sectionId,
        );

        section.items = data;
        section.shouldDisplayPresetName = Boolean(
          (sectionOptions as AnyFixMe).shouldDisplayPresetName,
        );
        section.options = {
          folderId,
          ..._.omit(sectionOptions, [
            'shouldDisplayPresetName',
            'onItemClick',
            'getItemPrice',
            'buyItem',
          ]),
        };
        // @ts-expect-error
        section.onItemClick = sectionOptions.onItemClick;
        // @ts-expect-error
        section.getItemPrice = sectionOptions.getItemPrice;
        // @ts-expect-error
        section.buyItem = sectionOptions.buyItem;
        // @ts-expect-error
        section.startItemDrag = sectionOptions.startItemDrag;

        if (action) {
          section.action = action;
        } else {
          section.action = paging.cursor
            ? () => {
                this.handleSectionShowMoreButtonClicked(sectionId);
              }
            : null;
        }

        return section;
      },
      (error: AnyFixMe) => {
        if (error.code === 401 || error.code === 403) {
          return Promise.reject(error);
        }

        this.props.sendBiError(
          util.bi.errors.MEDIA_PANEL.SECTION_FAILED_TO_LOAD,
          {
            sectionId,
            errorMessage: error.message,
          },
        );
      },
    );
  }

  loadRecommendedSectionData() {
    return this.recommendedMediaAdapter.items.list({
      cacheId: this.recommendedMediaCacheId,
      wixMediaRoots: RECOMMENDED_MEDIA_SECTION_ITEMS_WIX_MEDIA_ROOTS,
      unsplashMediaTypes: null,
      paging: {
        size: RECOMMENDED_ITEMS_TO_LOAD_COUNT,
      },
    });
  }

  loadRecentlyAddedSectionData() {
    return this.privateMediaAdapter.items.recent({
      ...ITEMS_LOADING_OPTIONS,
      mediaType: Object.values(mediaManagerMediaTypes).join(','),
    });
  }

  searchRecentlyAddedSectionData(query: AnyFixMe) {
    return this.privateMediaAdapter.items.search(null, query, {
      ...ITEMS_LOADING_OPTIONS,
      mediaType: Object.values(mediaManagerMediaTypes).join(','),
    });
  }

  getPublicMediaFoldersTree() {
    if (this.publicMediaFoldersTreePromise) {
      return this.publicMediaFoldersTreePromise;
    }

    this.publicMediaFoldersTreePromise = this.publicMediaAdapter.folders
      .tree(
        `${IMAGES},${VECTOR_ART},${ILLUSTRATIONS},${SOCIAL_ICONS},${VIDEOS}`,
        {
          levels: -1,
        },
      )
      .then(
        _.once(({ data }: AnyFixMe) => {
          this.publicMediaSubCategoryPublicMediaRootMap =
            mediaManagerPanelUtils.mapPublicMediaSubcategoryToRoot(data);

          return data;
        }),
      );

    return this.publicMediaFoldersTreePromise;
  }

  getPublicMediaSubcategoryInfo(
    publicMediaRoot: AnyFixMe,
    categoryIndex = 0,
    subcategoryIndex = 0,
  ) {
    return this.getPublicMediaFoldersTree().then((tree) =>
      wixMediaUtils.getWixMediaSubcategory(
        tree,
        publicMediaRoot,
        categoryIndex,
        subcategoryIndex,
      ),
    );
  }

  saveCategoryInfoToPublicMediaPathsMap(
    subcategoryInfo: AnyFixMe,
    sectionId: AnyFixMe,
  ) {
    this.sectionToPublicMediaSubcategoryPathMap[sectionId] =
      `${subcategoryInfo.categoryId}/${subcategoryInfo.id}`;

    return subcategoryInfo;
  }

  loadWixSectionData(publicMediaRoot: AnyFixMe, sectionId: AnyFixMe) {
    return this.getPublicMediaSubcategoryInfo(publicMediaRoot)
      .then((subcategoryInfo) => {
        this.saveCategoryInfoToPublicMediaPathsMap(subcategoryInfo, sectionId);

        return subcategoryInfo.id;
      })
      .then((folderId) => {
        const reqOptions = {
          ...ITEMS_LOADING_OPTIONS,
          language: this.props.language,
          country: util.editorModel.geo,
          mediaType: PUBLIC_MEDIA_ROOT_MEDIA_TYPE_MAP[publicMediaRoot],
          ...(this.isMediaFromWixExperimentEnabled
            ? { filters: { pricing: 'all' } }
            : { filters: { pricing: 'free' } }),
        };

        return this.publicMediaAdapter.items
          .list(folderId, reqOptions)
          .then((response: AnyFixMe) => ({
            ...response,
            _folderId: folderId,
          }));
      });
  }

  searchWixSectionData(
    publicMediaRoot: AnyFixMe,
    query: AnyFixMe,
    sectionId?: AnyFixMe,
  ) {
    return this.getPublicMediaSubcategoryInfo(publicMediaRoot)
      .then((subcategoryInfo) => {
        this.saveCategoryInfoToPublicMediaPathsMap(subcategoryInfo, sectionId);

        return subcategoryInfo.id;
      })
      .then((folderId) =>
        this.publicMediaAdapter.items
          .search(folderId, query, {
            ...ITEMS_LOADING_OPTIONS,
            language: this.props.language,
            country: util.editorModel.geo,
            mediaType: PUBLIC_MEDIA_ROOT_MEDIA_TYPE_MAP[publicMediaRoot],
            ...(this.isMediaFromWixExperimentEnabled
              ? { filters: { pricing: 'all' } }
              : { filters: { pricing: 'free' } }),
          })
          .then((response: AnyFixMe) => ({
            ...response,
            _folderId: folderId,
          })),
      );
  }

  loadWixMediaItemsFullInfoAndUpdateSectionsForSectionIds(
    sectionIds: string[],
  ) {
    const sections = sectionIds
      .map((id) => this.getSections()[id])
      .filter(Boolean);

    const itemIds = mediaManagerPanelUtils.filterOutSectionsItemsIds(
      sections,
      (item) =>
        mediaManagerPanelUtils.isWixMediaItem(item) &&
        mediaManagerPanelUtils.isVideoItem(item),
    );

    return this.loadWixMediaItemsFullInfoAndUpdateSections(itemIds);
  }

  loadWixMediaItemsFullInfoAndUpdateSectionsOnSectionsLoad() {
    return Promise.all([
      this.loadWixMediaItemsFullInfoAndUpdateSectionsForSectionIds(
        WIX_MEDIA_SECTIONS_LIST_FOR_FULL_INFO,
      ),
      this.loadWixMediaItemsFullInfoAndUpdateSectionsForSectionIds([
        MEDIA_MANAGER_PANEL_SECTION_ID.RECOMMENDED_MEDIA,
      ]),
    ])
      .then(() => this.setWixMediaItemsFullInfoLoaded(true))
      .catch((error) => {
        this.sendSentryError(error, 'loadWixMediaItemsFullInfo');
      });
  }

  loadWixMediaItemsFullInfoAndUpdateSections(itemIds: string[]) {
    if (_.isEmpty(itemIds)) {
      return Promise.resolve();
    }

    return this.loadWixMediaItemsFullInfo(itemIds).then(
      this.updateSectionsWithWixMediaItemsFullInfo,
    );
  }

  loadWixMediaItemsFullInfo(itemsIds: string[], triesCount = 0) {
    if (triesCount >= WIX_MEDIA_ITEMS_FULL_INFO_LOAD_MAX_RETRY_COUNT) {
      return Promise.reject();
    }

    return this.publicMediaAdapter.item
      .get(itemsIds.join(','), { language: this.props.language })
      .then(({ data }: AnyFixMe) => _.keyBy(data, 'id'))
      .catch(() => this.loadWixMediaItemsFullInfo(itemsIds, triesCount + 1));
  }

  /**
   *
   * @param {Object.<string, object>} itemsInfo
   */
  updateSectionsWithWixMediaItemsFullInfo(itemsInfo: AnyFixMe) {
    const sections = this.getSections();

    const sectionsWithUpdatedItems = Object.values(sections).reduce(
      (localSectionsToUpdate, section) => {
        const items = section.items.map(
          (item: AnyFixMe) => itemsInfo[item.id] || item,
        );
        const updatedSection = { ...section, items };

        return { ...localSectionsToUpdate, [section.id]: updatedSection };
      },
      {},
    );

    const updatedSections = {
      ...this.getSections(),
      ...sectionsWithUpdatedItems,
    };

    this.setSections(updatedSections);
  }

  _loadItemsRecursively(
    loadFn: AnyFixMe,
    options: AnyFixMe,
    previousData: AnyFixMe[] = [],
  ) {
    return loadFn(options).then((response: AnyFixMe) => {
      const { data, paging } = response;
      const resultData = _.take(
        [...previousData, ...data],
        ITEMS_TO_LOAD_COUNT,
      );

      if (resultData.length >= ITEMS_TO_LOAD_COUNT || paging.cursor === null) {
        return { ...response, data: resultData };
      }

      return this._loadItemsRecursively(
        loadFn,
        _.merge({ paging }, options),
        resultData,
      );
    });
  }

  loadShutterStockSectionData(mediaType: AnyFixMe) {
    return this._loadItemsRecursively(
      (options: AnyFixMe) =>
        this.shutterStockAdapter.items.list(`${mediaType}_any_any`, options),
      ITEMS_LOADING_OPTIONS,
    );
  }

  searchShutterStockSectionData(mediaType: AnyFixMe, query: AnyFixMe) {
    return this._loadItemsRecursively(
      (options: AnyFixMe) =>
        this.shutterStockAdapter.items.search(
          `${mediaType}_any_any`,
          query,
          options,
        ),
      ITEMS_LOADING_OPTIONS,
    );
  }

  loadSections() {
    this.trackFedopsInteractionStarted(
      fedopsLogger.INTERACTIONS.MEDIA_MANAGER_PANEL_LOAD_DATA,
    );

    this.setRecommendedSectionLoadWithMocks(true);
    this.setWixMediaItemsFullInfoLoaded(false);

    this.sectionsGaps = null;

    const generalSectionSettingsToLoad = this.createGeneralSectionsSettings();
    const additionalSectionSettingsToLoad = this.isSearching
      ? this.createSearchingSectionsSettings()
      : [];
    const sectionLoaders = [
      ...generalSectionSettingsToLoad,
      ...additionalSectionSettingsToLoad,
    ].map(this.loadDataAndCreateSection);

    return Promise.all(sectionLoaders)
      .then((sections) =>
        this.setSections(
          mediaManagerPanelUtils.sectionsListToObj(sections.filter(Boolean)),
        ),
      )
      .then(() => this.setAllRegularSectionsLoaded())
      .then(() => this.saveRegularSectionsLoadingFinishTime())
      .then(() => this.selectNavigationSection(0))
      .then(() => this.loadRecommendedMedia())
      .then(() =>
        this.loadWixMediaItemsFullInfoAndUpdateSectionsOnSectionsLoad(),
      )
      .then(() =>
        this.trackFedopsInteractionEnded(
          fedopsLogger.INTERACTIONS.MEDIA_MANAGER_PANEL_LOAD_DATA,
        ),
      )
      .catch(this.handleLoadingError);
  }

  refreshPanel() {
    this.setState(
      { areAllRegularSectionsLoaded: false, loadingError: null },
      () => {
        this.loadSections();
      },
    );
  }

  createSearchingSectionsSettings() {
    const { searchQuery } = this;

    const wixIllustrationsSectionSettings = {
      sectionId: MEDIA_MANAGER_PANEL_SECTION_ID.WIX_ILLUSTRATIONS,
      sectionDataLoader: this.searchWixSectionData(
        ILLUSTRATIONS,
        searchQuery,
        MEDIA_MANAGER_PANEL_SECTION_ID.WIX_ILLUSTRATIONS,
      ),
      sectionOptions: {
        onItemClick: this.handleItemClick,
        startItemDrag: this.startItemDrag,
        getItemPrice: this.getItemPrice,
        buyItem: this.buyItem,
      },
    };
    const wixVectorArtSectionSettings = {
      sectionId: MEDIA_MANAGER_PANEL_SECTION_ID.WIX_VECTOR_ART,
      sectionDataLoader: this.searchWixSectionData(
        VECTOR_ART,
        searchQuery,
        MEDIA_MANAGER_PANEL_SECTION_ID.WIX_VECTOR_ART,
      ),
      sectionOptions: {
        onItemClick: this.handleItemClick,
        startItemDrag: this.startItemDrag,
        getItemPrice: this.getItemPrice,
        buyItem: this.buyItem,
      },
    };
    const wixSocialIconsSectionSettings = {
      sectionId: MEDIA_MANAGER_PANEL_SECTION_ID.WIX_SOCIAL_ICONS,
      sectionDataLoader: this.searchWixSectionData(
        SOCIAL_ICONS,
        searchQuery,
        MEDIA_MANAGER_PANEL_SECTION_ID.WIX_SOCIAL_ICONS,
      ),
      sectionOptions: {
        onItemClick: this.handleItemClick,
        startItemDrag: this.startItemDrag,
        getItemPrice: this.getItemPrice,
        buyItem: this.buyItem,
        shouldPresetHaveBlueBackground: true,
      },
    };

    return [
      wixIllustrationsSectionSettings,
      wixVectorArtSectionSettings,
      wixSocialIconsSectionSettings,
    ];
  }

  createGeneralSectionsSettings() {
    const { isSearching, searchQuery } = this;

    const recentlyAddedSectionSettings = {
      sectionId: MEDIA_MANAGER_PANEL_SECTION_ID.SITE_FILES,
      sectionDataLoader: isSearching
        ? this.searchRecentlyAddedSectionData(searchQuery)
        : this.loadRecentlyAddedSectionData(),
      sectionOptions: {
        shouldDisplayPresetName: true,
        onItemClick: this.handleItemClick,
        startItemDrag: this.startItemDrag,
      },
      action: () => {
        this.handleSectionShowMoreButtonClicked(
          MEDIA_MANAGER_PANEL_SECTION_ID.SITE_FILES,
        );
      },
    };

    const wixImagesSectionSettings = {
      sectionId: MEDIA_MANAGER_PANEL_SECTION_ID.WIX_IMAGES,
      sectionDataLoader: isSearching
        ? this.searchWixSectionData(IMAGES, searchQuery)
        : this.loadWixSectionData(
            IMAGES,
            MEDIA_MANAGER_PANEL_SECTION_ID.WIX_IMAGES,
          ),
      sectionOptions: {
        onItemClick: this.handleItemClick,
        startItemDrag: this.startItemDrag,
        getItemPrice: this.getItemPrice,
        buyItem: this.buyItem,
      },
    };

    const wixVideosSectionSettings = {
      sectionId: MEDIA_MANAGER_PANEL_SECTION_ID.WIX_VIDEOS,
      sectionDataLoader: isSearching
        ? this.searchWixSectionData(VIDEOS, searchQuery)
        : this.loadWixSectionData(
            VIDEOS,
            MEDIA_MANAGER_PANEL_SECTION_ID.WIX_VIDEOS,
          ),
      sectionOptions: {
        onItemClick: this.handleItemClick,
        startItemDrag: this.startItemDrag,
        getItemPrice: this.getItemPrice,
        buyItem: this.buyItem,
      },
    };

    const shutterstockImagesSectionSettings = {
      sectionId: MEDIA_MANAGER_PANEL_SECTION_ID.SHUTTERSTOCK_IMAGES,
      sectionDataLoader: isSearching
        ? this.searchShutterStockSectionData(
            SHUTTERSTOCK_MEDIA_TYPE.IMAGE,
            searchQuery,
          )
        : this.loadShutterStockSectionData(SHUTTERSTOCK_MEDIA_TYPE.IMAGE),
      sectionOptions: {
        getItemPrice: this.getItemPrice,
        buyItem: this.buyItem,
      },
    };

    const shutterstockVideosSectionSettings = {
      sectionId: MEDIA_MANAGER_PANEL_SECTION_ID.SHUTTERSTOCK_VIDEOS,
      sectionDataLoader: isSearching
        ? this.searchShutterStockSectionData(
            SHUTTERSTOCK_MEDIA_TYPE.VIDEO,
            searchQuery,
          )
        : this.loadShutterStockSectionData(SHUTTERSTOCK_MEDIA_TYPE.VIDEO),
      sectionOptions: {
        getItemPrice: this.getItemPrice,
        buyItem: this.buyItem,
      },
    };

    return [
      recentlyAddedSectionSettings,
      wixImagesSectionSettings,
      wixVideosSectionSettings,
      shutterstockImagesSectionSettings,
      shutterstockVideosSectionSettings,
    ];
  }

  /**
   * Open media manager with the ability to add a component to stage
   * (Similar to Add Panel abilities but with no drag n drop)
   * @param {string} sectionId
   * @param {object} options
   * @param {string} customSource
   */
  openMediaManager(sectionId: AnyFixMe, options = {}, customSource = '') {
    this.props.mediaManager.open(
      this.props.mediaManager.categories.ALL_MEDIA,
      customSource ||
        mediaManagerPanelUtils.generateMediaManagerOpenSource(
          sectionId,
          this.isSearching,
        ),
      {
        multiSelect: true,
        callback: (items: AnyFixMe, info: AnyFixMe) =>
          this.onMediaManagerSessionEnded(sectionId, items, info),
        ...options,
      },
    );
  }

  openMediaManagerByEditorUrl() {
    const { mediaManagerOpenPathSourceOnMount } = this.props;
    const source = `${MEDIA_MANAGER_OPEN_SOURCE_BASE}_by_editor_url`;
    const openOptions = {
      path: mediaManagerPanelUtils.generateMediaManagerOpenPathFromUrlParam(
        mediaManagerOpenPathSourceOnMount,
      ),
    };
    const sectionId =
      mediaManagerPanelUtils.getMediaManagerSectionIdBasedOnUrlParam(
        mediaManagerOpenPathSourceOnMount,
      );

    this.openMediaManager(sectionId, openOptions, source);
  }

  onMediaManagerSessionEnded(
    sectionId: AnyFixMe,
    items: AnyFixMe,
    info: AnyFixMe,
  ) {
    if (!_.isEmpty(items)) {
      this.onItemsSelected(sectionId, items, info);

      return;
    }

    this.onMediaManagerClosed();
  }

  onMediaManagerClosed() {
    this.refreshPanel();
  }

  onItemsSelected(sectionId: AnyFixMe, items: AnyFixMe, info: AnyFixMe) {
    this.props.pasteLogicAPI.addContext.resetPastePosition();

    if (mediaManagerPanelUtils.isRecentlyAddedSection(sectionId)) {
      this.setSessionUserPreferences(
        `last_media_path_${sectionId}`,
        info.path || '',
      );
    }

    const lastItem = items[items.length - 1];
    const folderId = mediaManagerPanelUtils.extractFolderIdFromPath(info.path);
    const shouldAddComposedComponent = this.isSocialIconsRoot(folderId);
    const mediaManagerOptions = {
      addingMethod: ADD_TO_STAGE_METHOD.MEDIA_MANAGER,
    };

    if (shouldAddComposedComponent) {
      this.addComposedComponent(
        sectionId,
        folderId,
        items,
        info,
        mediaManagerOptions,
      );
    } else {
      items.forEach((item: AnyFixMe) => {
        this.addSingleComponent(sectionId, folderId, item, info, {
          isLast: _.isEqual(item, lastItem),
          ...mediaManagerOptions,
        });
      });
    }

    this.props.editorSetFocusToEditor();
  }

  getCompDef(item: AnyFixMe, folderId: AnyFixMe, info = {}) {
    const getCompStructure =
      mediaManagerPanelUtils.getComponentStructureCreator(item, {
        isSocialIcon: this.isSocialIconsRoot(folderId),
      });

    if (_.isFunction(getCompStructure)) {
      return getCompStructure(
        item,
        info,
        this.props.componentsAPI.buildDefaultComponentStructure,
      );
    }

    console.error('Unsupported media type', item.mediaType);
  }

  getComposedCompDef(
    items: AnyFixMe,
    info: AnyFixMe,
    options: { folderId?: string } = {},
  ) {
    const { folderId } = options;
    const getComposedCompStructure =
      mediaManagerPanelUtils.getComposedComponentStructureCreator({
        isSocialIcon: this.isSocialIconsRoot(folderId),
      });

    if (getComposedCompStructure) {
      // @ts-expect-error
      return getComposedCompStructure(items, info);
    }

    console.error(
      'Unsupported composed component definition',
      items.map(({ mediaType }: AnyFixMe) => mediaType).join(','),
    );
  }

  trackComponentAddedToStage({
    presetId,
    category,
    componentId,
    componentType: component_type,
    presetTags,
    sectionId,
    addingMethod = ADD_TO_STAGE_METHOD.CLICK,
    presetSkin,
    targetComponentType = null,
    targetComponentId,
    ...rest
  }: AnyFixMe) {
    const section = mediaManagerPanelUtils.sectionIdToBiField(sectionId);
    util.editorWixRecorder.addLabel(`${component_type} added to stage`);
    this.sendEditorBi(coreBi.events.addPanel.COMPONENT_ADDED_TO_STAGE, {
      origin: MEDIA_MANAGER_OPEN_SOURCE_BASE,
      category,
      section: this.isSearching ? `${section}_search` : section,
      component_type,
      component_id: componentId,
      preset_id: presetId,
      adding_method: addingMethod,
      preset_data_skin: presetSkin,
      preset_data_tags: mediaManagerPanelUtils.tagsToString(presetTags),
      page_id: this.props.focusedPageId,
      target_component: targetComponentType,
      target_component_id: targetComponentId,
      ...rest,
    });
  }

  async addSingleComponent(
    sectionId: AnyFixMe,
    folderId: AnyFixMe,
    item: AnyFixMe,
    info: AnyFixMe,
    options: AnyFixMe = {},
  ) {
    this.trackFedopsInteractionStarted(
      fedopsLogger.INTERACTIONS.ADD_COMP_FROM_MEDIA_MANAGER_PANEL,
    );

    const {
      addingMethod,
      pastePosition,
      isLast = true,
      targetComponentRef = {},
      shouldClosePanelAfterAdd = true,
    } = options;

    const compDef = options.compDef || this.getCompDef(item, folderId, info);

    if (!compDef) {
      return;
    }

    const compRef = await this.addComponent(compDef, info, {
      uri: item.uri,
      pastePosition,
      shouldClosePanelAfterAdd,
      addingMethod,
    });

    if (!compRef) return;

    if (isLast) {
      this.props.selectionAPI.selectComponentByCompRef(compRef);
      this.props.historyAPI.add('added component');
    }

    const compBiParams = this.props.getComponentBiParams(compRef);

    this.trackComponentAddedToStage({
      addingMethod,
      presetId: item.id,
      category: item.mediaType,
      componentId: compRef.id,
      componentType: compDef.componentType,
      presetTags: item.labels,
      presetSkin: mediaManagerPanelUtils.getComponentStructureSkin(compDef),
      sectionId: mediaManagerPanelUtils.generateBiSectionId(sectionId, item),
      targetComponentType: this.props.componentsAPI.getType(targetComponentRef),
      targetComponentId: targetComponentRef.id,
      non_page_top_parent_component_id:
        compBiParams.non_page_top_parent_component_id,
      non_page_top_parent_component_type:
        compBiParams.non_page_top_parent_component_type,
    });

    this.trackFedopsInteractionEnded(
      fedopsLogger.INTERACTIONS.ADD_COMP_FROM_MEDIA_MANAGER_PANEL,
    );

    return compRef;
  }

  addComposedComponent(
    sectionId: AnyFixMe,
    folderId: AnyFixMe,
    items: AnyFixMe,
    info: AnyFixMe,
    options: AnyFixMe,
  ) {
    this.trackFedopsInteractionStarted(
      fedopsLogger.INTERACTIONS.ADD_COMP_FROM_MEDIA_MANAGER_PANEL,
    );

    const compDef = this.getComposedCompDef(items, info, { folderId });

    if (!compDef) {
      return;
    }

    const { addingMethod, shouldClosePanelAfterAdd = true } = options;

    return this.addComponent(compDef, info, {
      shouldClosePanelAfterAdd,
      addingMethod,
    }).then((compRef) => {
      if (!compRef) return;
      const [item] = items;

      this.props.selectionAPI.selectComponentByCompRef(compRef);
      this.props.historyAPI.add('added component');

      const compBiParams = this.props.getComponentBiParams(compRef);

      this.trackComponentAddedToStage({
        presetId: items.map(({ id }: AnyFixMe) => id).join(','),
        category: item.mediaType,
        componentId: compRef.id,
        componentType: compDef.componentType,
        sectionId: mediaManagerPanelUtils.generateBiSectionId(
          sectionId,
          _.head(items),
        ),
        presetSkin: mediaManagerPanelUtils.getComponentStructureSkin(compDef),
        addingMethod,
        non_page_top_parent_component_id:
          compBiParams.non_page_top_parent_component_id,
        non_page_top_parent_component_type:
          compBiParams.non_page_top_parent_component_type,
        targetComponentId: compBiParams.parent_component_id,
        targetComponentType: compBiParams.parent_component_type,
      });

      this.trackFedopsInteractionEnded(
        fedopsLogger.INTERACTIONS.ADD_COMP_FROM_MEDIA_MANAGER_PANEL,
      );
    });
  }

  async addComponent(
    compDef: AnyFixMe,
    info: AnyFixMe,
    options: AnyFixMe = {},
  ): Promise<CompRef | undefined> {
    const { focusedPageReference } = this.props;
    const {
      pastePosition: optionsPastePosition,
      uri,
      shouldClosePanelAfterAdd,
      addingMethod,
    } = options;

    const containerRef = sectionsUtils.isSectionsEnabled()
      ? this.props.getContainerRefWithSectionsEnabled(compDef)
      : focusedPageReference;

    const pastePosition =
      optionsPastePosition ||
      this.props.editorGetPasteComponentPosition(
        compDef.layout,
        containerRef.id,
      );

    _.merge(compDef.layout, pastePosition);

    if (uri) {
      this.setSessionUserPreferences(`last_media_path_${uri}`, info.path || '');
    }

    if (shouldClosePanelAfterAdd) {
      this.closePanel();
    }

    if (
      !this.props.componentsAPI.is.containableByStructure(compDef, containerRef)
    ) {
      this.props.showCannotAddNotification(compDef, containerRef);
      return;
    }

    const compRef: CompRef = await new Promise((resolve) =>
      this.props.componentsAPI.add(containerRef, compDef, undefined, resolve),
    );

    if (addingMethod !== 'drag') {
      this.props.fireComponentAdded({
        compRef,
        type: addingMethod,
        origin: 'mediaPanel',
      });
    }

    return compRef;
  }

  saveRegularSectionsLoadingFinishTime() {
    this.regularSectionsLoadingFinishTime = mediaManagerPanelUtils.timeNow();
  }

  saveRecommendedMediaLoadingEndTime() {
    this.recommendedMediaLoadingTime.end = mediaManagerPanelUtils.timeNow();
  }

  getRecommendedMediaPresenceType() {
    if (
      !this.isRecommendedMediaExperimentEnabled ||
      this.neverShowRecommendedMedia
    ) {
      return RECOMMENDED_MEDIA_PRESENCE_TYPE.NO;
    }

    if (_.isEmpty(this.recommendedMediaCategories)) {
      return RECOMMENDED_MEDIA_PRESENCE_TYPE.AS_CARD;
    }

    return RECOMMENDED_MEDIA_PRESENCE_TYPE.AS_SECTION;
  }

  trackPanelOpened() {
    const { recommendedMediaLoadingTime } = this;
    const loadingTime =
      this.regularSectionsLoadingFinishTime - this.openTimeStart;
    const recommendedMediaLoadingDuration = this.isPanelLoadedWithError
      ? 0
      : recommendedMediaLoadingTime.end - recommendedMediaLoadingTime.start;
    const recommendedMediaPresenceType = this.getRecommendedMediaPresenceType();

    this.sendMediaPanelBi(mediaManagerPanelBiEvents.OPENED, {
      jsonData: this.getSectionsJSONData(),
      loadingTime,
      loadingTimeRecommended: recommendedMediaLoadingDuration,
      recommendedSectionExists: recommendedMediaPresenceType,
      recentlySectionExists: this.hasRecentlyAddedSection,
    });
  }

  trackRecommendedSectionButtonClicked(
    name: AnyFixMe,
    recommendedMediaCategories: AnyFixMe,
  ) {
    this.sendMediaPanelBi(mediaManagerPanelBiEvents.RECOMMENDED_BUTTON_CLICK, {
      button_name: name,
      target: MEDIA_MANAGER_OPEN_SOURCE_BASE,
      origin: MEDIA_MANAGER_OPEN_SOURCE_BASE,
      selection: recommendedMediaCategories.join(','),
      editor_working_mode: this.props.editorWorkingMode,
      viewmode: this.props.editorWorkingMode,
    });
  }

  sendMediaPanelBi(event: AnyFixMe, fields: AnyFixMe) {
    this.props.sendBi(event, { msid: this.props.metaSiteId, ...fields });
  }

  sendEditorBi(event: AnyFixMe, fields: AnyFixMe) {
    this.props.sendBi(event, { site_id: this.props.siteId, ...fields });
  }

  trackFedopsInteractionStarted(interaction: AnyFixMe) {
    fedopsLogger.interactionStarted(interaction);
  }

  trackFedopsInteractionEnded(interaction: AnyFixMe) {
    fedopsLogger.interactionEnded(interaction);
  }

  prepareItemBeforeAddComponent(item: AnyFixMe) {
    return mediaManagerPanelUtils.prepareItemBeforeAddComponent(item);
  }

  async handleItemClick({
    id: sectionId,
    folderId,
    item: maybeCompleteItem,
  }: AnyFixMe) {
    let item = maybeCompleteItem;

    if (!this.isWixMediaItemsFullInfoLoaded && this.isWixVideoItem(item)) {
      try {
        await this.loadWixMediaItemsFullInfoAndUpdateSections([item.id]);

        item = this.getSections()[sectionId].items.find(
          ({ id }: AnyFixMe) => id === maybeCompleteItem.id,
        );
      } catch (error) {
        this.sendSentryError(error, 'itemClick');

        return;
      }
    }

    this.trackFedopsInteractionStarted(
      fedopsLogger.INTERACTIONS.MEDIA_MANAGER_PANEL_ITEM_CLICK,
    );

    const openOptions = this.isSearching
      ? MEDIA_MANAGER_OPEN_OPTIONS_WHEN_SEARCH
      : MEDIA_MANAGER_OPEN_OPTIONS;
    const info = mediaManagerPanelUtils.isRecentlyAddedSection(sectionId)
      ? { path: this.lastUsedPrivateMediaPath }
      : openOptions[sectionId](this.searchQuery);

    const preparedItem = this.prepareItemBeforeAddComponent(item);

    this.addSingleComponent(sectionId, folderId, preparedItem, info);

    this.trackFedopsInteractionEnded(
      fedopsLogger.INTERACTIONS.MEDIA_MANAGER_PANEL_ITEM_CLICK,
    );
  }

  startItemDrag({ id: sectionId, folderId, event, dragItemInfo }: AnyFixMe) {
    const { item, rect } = dragItemInfo;

    if (!this.isWixMediaItemsFullInfoLoaded && this.isWixVideoItem(item)) {
      return;
    }

    this.trackFedopsInteractionStarted(
      fedopsLogger.INTERACTIONS.MEDIA_MANAGER_PANEL_START_DRAG_ITEM,
    );

    const preparedItem = this.prepareItemBeforeAddComponent(item);
    const structure = this.getCompDef(preparedItem, folderId);

    const dragItemData = {
      dragOrigin: MEDIA_MANAGER_OPEN_SOURCE_BASE,
      itemId: preparedItem.id,
      tags: mediaManagerPanelUtils.tagsToString(preparedItem.labels),
      rect,
      structure,
      categoryId: preparedItem.mediaType,
      sectionTitle: mediaManagerPanelUtils.sectionIdToBiField(sectionId),
      onDrop: (
        pastePosition: AnyFixMe,
        dragItem: AnyFixMe,
        targetContainer: AnyFixMe,
      ) => {
        return this.addSingleComponent(
          sectionId,
          folderId,
          preparedItem,
          {},
          {
            isLast: true,
            pastePosition,
            compDef: structure,
            addingMethod: ADD_TO_STAGE_METHOD.DRAG,
            targetComponentRef: targetContainer,
          },
        );
      },
      onAfterDrop: (compRef: CompRef, mousePosition: Point) => {
        this.props.fireComponentAdded({
          origin: 'mediaPanel',
          type: 'drag',
          compRef,
          mousePosition,
        });
      },
    };

    this.props.startItemDrag(event, dragItemData);

    this.trackFedopsInteractionEnded(
      fedopsLogger.INTERACTIONS.MEDIA_MANAGER_PANEL_START_DRAG_ITEM,
    );
  }

  _sectionToAnchorNavigationSection(section: AnyFixMe) {
    const { id } = section;
    const navigationTooltipKeys = this.isMediaFromWixExperimentEnabled
      ? NAVIGATION_MFW_TOOLTIP_KEYS
      : NAVIGATION_TOOLTIP_KEYS;

    return {
      id,
      title: navigationTooltipKeys[id],
      sectionName: section.title,
      help: {
        hide: true,
        text: '',
      },
      hide: false,
      showSectionHeader: true,
      type: 'MEDIA_MANAGER_PANEL_SECTION',
    };
  }

  getAnchorsNavigationProps() {
    if (this.isSearching) {
      return null;
    }

    const navigationSections = this.visibleSections.map((section) =>
      this._sectionToAnchorNavigationSection(section),
    );

    return {
      categoryId: 'MEDIA_MANAGER_PANEL_CATEGORY',
      allSections: navigationSections,
      sectionsWithHeaders: navigationSections,
      selected: this.state.selectedNavigationSection,
      notifyCategoryViewOnStateChange: _.noop,
      setTopSection: this.selectNavigationSection.bind(this),
    };
  }

  toggleSearchBoxState(scrollTop: AnyFixMe) {
    if (
      scrollTop <= STICKY_SEARCH_SCROLL_TOP_THRESHOLD &&
      this.isSearchBoxSticky
    ) {
      this.setState({ isSearchBoxSticky: false });
    }

    if (
      scrollTop > STICKY_SEARCH_SCROLL_TOP_THRESHOLD &&
      !this.isSearchBoxSticky
    ) {
      this.setState({ isSearchBoxSticky: true });
    }
  }

  private animationInProcess: boolean;

  selectNavigationSection(sectionIndex: AnyFixMe) {
    if (this.isSearching) {
      return;
    }

    const selectedNavigationSection = this.visibleSections[sectionIndex];

    if (!selectedNavigationSection) {
      return;
    }

    const scrolledElement = (
      this.customScrollRef as AnyFixMe
    ).getScrolledElement();

    if (!scrolledElement) {
      return;
    }

    this.setSelectedNavigationSection(selectedNavigationSection);

    this.animationInProcess = true;
    const scrollTo =
      this.getSectionInstance(selectedNavigationSection.id).offsetTop -
      this.customScrollOffsetTop;

    this.toggleSearchBoxState(scrollTo);

    uiAnimations.scrollTo({ y: scrollTo }, 0.5, 0, scrolledElement, () => {
      this.animationInProcess = false;
    });
  }

  handleScroll(event: AnyFixMe) {
    if (this.animationInProcess) {
      return;
    }

    const { scrollTop } = event.target;

    this.toggleSearchBoxState(scrollTop);

    if (this.isSearching) {
      return;
    }

    const { visibleSections } = this;
    const { customScrollOffsetTop } = this;

    if (!this.sectionsGaps) {
      this.sectionsGaps = mediaManagerPanelUtils.shiftDOMInstanceIntervals(
        mediaManagerPanelUtils.transformSectionsDOMInstancesToDimensionIntervals(
          visibleSections.map((section) => this.getSectionInstance(section.id)),
        ),
        customScrollOffsetTop,
      );
    }

    const navigationSelectedSectionIndex =
      mediaManagerPanelUtils.getTopVisibleSectionIndex(
        this.sectionsGaps,
        scrollTop,
        this.customScrollInstance.offsetHeight,
      );
    const possibleNextNavigationSection =
      visibleSections[navigationSelectedSectionIndex];

    if (
      possibleNextNavigationSection !== this.state.selectedNavigationSection
    ) {
      this.setSelectedNavigationSection(possibleNextNavigationSection);
    }
  }

  handleSearchBlur(searchQuery: AnyFixMe) {
    this.trackFedopsInteractionStarted(
      fedopsLogger.INTERACTIONS.MEDIA_MANAGER_PANEL_BLUR_SEARCH,
    );

    if (!searchQuery.length) {
      this.clearSearch();
    }

    this.trackFedopsInteractionEnded(
      fedopsLogger.INTERACTIONS.MEDIA_MANAGER_PANEL_BLUR_SEARCH,
    );
  }

  closePanel() {
    this.props.panelManagerAPI.closePanelByName(this.props.panelName);
  }

  getSectionsInfoData(sectionId: AnyFixMe) {
    const sectionHelpInfo = this.getAnchorsNavigationProps()?.allSections?.find(
      (s) => s.id === sectionId,
    );
    const baseTranslationKey = sectionHelpInfo?.title;
    const title =
      baseTranslationKey === undefined
        ? undefined
        : translate(util.keyGenerator.titleKey(baseTranslationKey));
    const description =
      baseTranslationKey === undefined
        ? undefined
        : translate(util.keyGenerator.descriptionKey(baseTranslationKey));

    return { title, description };
  }

  render() {
    const LeftPanelFrame = this.isNewWorkspace
      ? leftBar.LeftPanelFrame
      : frames.LeftPanelFrame;
    return (
      <LeftPanelFrame
        panelClass="media-manager-panel"
        panelName={this.props.panelName}
        panelIndex={this.props.panelIndex}
        helpId="d08b3ca9-adcd-487a-a707-d06a3aa81b85"
        label={this.panelTitle}
      >
        <div
          onContextMenu={this.preventRightClick}
          className={cx('media-manager-panel__content', {
            'search-state': this.isSearching,
          })}
        >
          {this.isPanelLoadedWithError ? (
            <div
              key="errorInfoContainer"
              className="media-manager-panel__error-info-wrapper"
            >
              <Symbols.symbol name="media-manager-panel-error-illustration" />

              <div className="media-manager-panel__error-title">
                <TextLabel
                  value={this.errorTitle}
                  type="T09"
                  enableEllipsis={false}
                />
              </div>

              <div className="media-manager-panel__error-text">
                <TextLabel
                  value={this.errorText}
                  type="T02"
                  enableEllipsis={false}
                />
              </div>

              {!this.isNotAuthorized ? (
                <div key="tryAgainButton">
                  <Button
                    onClick={this.refreshPanel}
                    className="media-manager-panel__error-try-again-button btn-text"
                  >
                    {this.tryAgainButtonText}
                  </Button>
                </div>
              ) : null}
            </div>
          ) : null}

          {this.shouldRenderNavigation ? (
            // @ts-expect-error
            <AnchorsNavigation
              key="navigation"
              {...this.getAnchorsNavigationProps()}
            />
          ) : null}

          {!this.isPanelLoadedWithError ? (
            <div
              key="contentContainer"
              className={cx('media-manager-panel__content-inner', {
                'with-sticky-search-box': this.isSearchBoxSticky,
              })}
            >
              {this.areAllRegularSectionsLoaded || this.isSearching ? (
                <div
                  key="searchBox"
                  className={cx('media-manager-panel__search-box', {
                    sticky: this.isSearchBoxSticky,
                  })}
                >
                  <div className="media-manager-panel__search">
                    <div className="media-manager-panel__extended-search-message">
                      <TextLabel value="Media_Panel_Search_Media_Label" />
                    </div>

                    <div className="media-manager-panel__search-wrapper">
                      <Search
                        value={this.searchQuery}
                        onSearchStart={this.startSearch}
                        onClearSearchButtonClick={this.clearSearch}
                        onBlur={this.handleSearchBlur}
                      />
                    </div>
                  </div>
                </div>
              ) : null}

              {!this.areAllRegularSectionsLoaded ? (
                <SearchLoader label={this.loaderText} />
              ) : null}

              <div className="media-manager-panel__scroll-wrapper">
                {this.areAllRegularSectionsLoaded ? (
                  <CustomScroll
                    ref="customScroll"
                    onScroll={this.handleScroll}
                    heightRelativeToParent="100%"
                    key="dataSections"
                  >
                    {this.hasFoundNothing ? (
                      <NoSearchResults
                        shouldShowButton={
                          this.isNewWorkspace && this.hasFoundNothing
                        }
                        onButtonClick={this.handleUploadMediaButtonClick}
                        buttonLabel={'Media_Panel_Upload_Media_Button'}
                      />
                    ) : null}

                    {this.areAllRegularSectionsLoaded && !this.hasFoundNothing
                      ? (() => {
                          const { addMediaSection } = this;

                          return (
                            <ul
                              key="panelContent"
                              className="media-manager-panel__sections"
                            >
                              {this.sectionsOrder.map(
                                (sectionId, sectionIdIndex) => {
                                  const section = this.getSections()[sectionId];

                                  return (
                                    <React.Fragment key={sectionIdIndex}>
                                      {this.isRegularSection(sectionId) &&
                                      this.sectionCanBeShown(section) ? (
                                        <li
                                          ref={this.getSectionRef(sectionId)}
                                          key={section.id}
                                          className="media-manager-panel__section"
                                        >
                                          <PresetSection
                                            id={section.id}
                                            title={section.title}
                                            items={section.items}
                                            action={section.action}
                                            shouldDisplayPresetName={
                                              section.shouldDisplayPresetName
                                            }
                                            onItemClick={section.onItemClick}
                                            getItemPrice={section.getItemPrice}
                                            buyItem={section.buyItem}
                                            startItemDrag={
                                              section.startItemDrag
                                            }
                                            options={section.options}
                                            presetWidth={90}
                                            helpTitle={
                                              this.getSectionsInfoData(
                                                sectionId,
                                              ).title
                                            }
                                            helpDescription={
                                              this.getSectionsInfoData(
                                                sectionId,
                                              ).description
                                            }
                                            key={section.id}
                                          />
                                        </li>
                                      ) : null}
                                      {this.isRecommendedMediaSection(
                                        sectionId,
                                      ) &&
                                      this.recommendedMediaSectionCanBeShown(
                                        section,
                                      ) ? (
                                        <li
                                          ref={this.getSectionRef(sectionId)}
                                          key={this.getRecommendedMediaSectionKey()}
                                          className="media-manager-panel__section media-manager-panel__recommended-section"
                                        >
                                          <RecommendedMediaSection
                                            id={section.id}
                                            title={section.title}
                                            items={section.items}
                                            action={section.action}
                                            options={section.options}
                                            visibleItemsCount={
                                              this
                                                .recommendedSectionVisibleItemsCount
                                            }
                                            onItemClick={section.onItemClick}
                                            getItemPrice={section.getItemPrice}
                                            startItemDrag={
                                              section.startItemDrag
                                            }
                                            buyItem={section.buyItem}
                                            presetWidth={90}
                                            onSettingsOpen={
                                              this
                                                .handleRecommendedMediaSectionSettingsButtonClick
                                            }
                                            onShowMoreClick={
                                              this
                                                .handleRecommendedMediaSectionShowMoreButtonClick
                                            }
                                            // @ts-expect-error
                                            helpTitle={
                                              this.getSectionsInfoData(
                                                sectionId,
                                              ).title
                                            }
                                            helpDescription={
                                              this.getSectionsInfoData(
                                                sectionId,
                                              ).description
                                            }
                                            key={section.id}
                                          />
                                        </li>
                                      ) : null}
                                      {this.isCreateMediaSection(sectionId) &&
                                      this.createMediaSectionCanBeShown() ? (
                                        <li
                                          ref={this.getSectionRef(sectionId)}
                                          key={sectionId}
                                          className="media-manager-panel__section"
                                        >
                                          <CreateMediaSection
                                            onCreateImageClick={
                                              this
                                                .handleCreateImageActionClicked
                                            }
                                            onCreateVideoClick={
                                              this
                                                .handleCreateVideoActionClicked
                                            }
                                            helpTitle={
                                              this.getSectionsInfoData(
                                                sectionId,
                                              ).title
                                            }
                                            helpDescription={
                                              this.getSectionsInfoData(
                                                sectionId,
                                              ).description
                                            }
                                          />
                                        </li>
                                      ) : null}
                                      {this.isAddMediaSection(sectionId) &&
                                      this.shouldRenderAddMediaSection() ? (
                                        <li
                                          ref={this.getSectionRef(sectionId)}
                                          key={addMediaSection.id}
                                          className="media-manager-panel__section media-manager-panel__add-media-section"
                                        >
                                          <AddMediaSection
                                            openMediaManager={
                                              this.handleAddMediaButtonClick
                                            }
                                            helpTitle={
                                              this.getSectionsInfoData(
                                                sectionId,
                                              ).title
                                            }
                                            helpDescription={
                                              this.getSectionsInfoData(
                                                sectionId,
                                              ).description
                                            }
                                          />
                                        </li>
                                      ) : null}
                                      {this.isRecommendedMediaCard(sectionId) &&
                                      this.shouldRenderRecommendedMediaCard ? (
                                        <li
                                          key="recommended-media-card"
                                          className="media-manager-panel__recommended-media-card"
                                        >
                                          <RecommendedMediaCard
                                            // @ts-expect-error
                                            categories={
                                              this.recommendedCardTopics
                                            }
                                            onDotsClick={
                                              this
                                                .handleRecommendedMediaCardThreeDotsClick
                                            }
                                            dotsActions={this.getRecommendedMediaCardActions()}
                                            onApply={
                                              this
                                                .handleRecommendedMediaCardApplyClick
                                            }
                                            onSeeAll={
                                              this
                                                .handleRecommendedCategoriesSeeAllButtonClick
                                            }
                                            helpTitle={
                                              this.getSectionsInfoData(
                                                sectionId,
                                              ).title
                                            }
                                            helpDescription={
                                              this.getSectionsInfoData(
                                                sectionId,
                                              ).description
                                            }
                                          />
                                        </li>
                                      ) : null}
                                    </React.Fragment>
                                  );
                                },
                              )}
                            </ul>
                          );
                        })()
                      : null}

                    <div className="media-manager-panel__list-upload-button-divider" />
                    {this.isNewWorkspace && this.hasFoundNothing ? null : ( // If we've found nothing we should not render this at the bottom
                      <UploadButton
                        label={'Media_Panel_Upload_Media_Button'}
                        onButtonClick={this.handleUploadMediaButtonClick}
                      />
                    )}
                  </CustomScroll>
                ) : null}
              </div>
            </div>
          ) : null}
        </div>
      </LeftPanelFrame>
    );
  }
}

// @ts-expect-error
MediaManagerPanel.propTypes = {
  siteMediaToken: PropTypes.string.isRequired,
  siteId: PropTypes.string.isRequired,
};

const ConnectedMediaManagerPanel = _.flowRight(
  connect(EDITOR_API, mapStateToProps, mapDispatchToProps),
  withDragToStage(addPanelDragToStage, dragBox),
)(MediaManagerPanel);

ConnectedMediaManagerPanel.pure = MediaManagerPanel;

export default ConnectedMediaManagerPanel;
