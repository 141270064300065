import React from 'react';
import * as reselect from 'reselect';
import _ from 'lodash';
import { withTranslation, type WithTranslation } from 'react-i18next';
import { Scrollable, SectionHeader, Panel, Link } from '@wix/editor-search-ui';

import { hoc, fedopsLogger } from '@/util';

import { SearchItem } from '../../components/SearchItem/SearchItem';
import { KeyboardControl } from '../../components/KeyboardControl';

import { CLOSED_CATEGORY_MAX_ITEMS_COUNT } from '../../constants';
import { TRANSLATIONS } from '../../translations';
import { AUTOMATION_IDS } from '../../automationIds';
import { SearchCategories } from '../../services/searchModule/constants';
import {
  editorSearchBiLogger,
  BILinkButtonNames,
} from '../../services/biLogger/biLogger';

import { mapDispatchToProps } from './SearchResultsList.mapper';

import type { TransformedResultItem } from '../../services/searchModule/types';
import type { BISearchResultData } from '../../services/biLogger/biLogger';

import styles from './SearchResultsList.scss';

const {
  connect,
  STORES: { EDITOR_API },
} = hoc;

enum ItemType {
  EXPANDER = 'EXPANDER',
  REGULAR = 'REGULAR',
}

export interface SearchResultCategory {
  id: SearchCategories;
  items: Partial<TransformedResultItem>[];
}

type ItemForRender = Partial<TransformedResultItem> &
  BISearchResultData & {
    type: ItemType;
  };

interface CategoryForRender extends SearchResultCategory {
  items: ItemForRender[];
  total: number;
}

interface SearchResultListState {
  isSelectedViaKeyboard: boolean;
  selectedIndex: number;
  isTooltipForceShown: boolean;
  openedCategories: Record<string, boolean>;
}

type SearchResultListProps = WithTranslation & {
  searchQuery: string;
  categories: SearchResultCategory[];
  onActionSubmit(): void;
} & ReturnType<typeof mapDispatchToProps>;

const createGetCategoriesForRenderSelector = <
  T extends (
    categories: SearchResultListProps['categories'],
    openedCategories: SearchResultListState['openedCategories'],
  ) => any,
>(
  computeResult: T,
) =>
  reselect.createSelector<
    SearchResultListProps,
    SearchResultListState,
    SearchResultListProps['categories'],
    SearchResultListState['openedCategories'],
    ReturnType<T>
  >(
    (props) => props.categories,
    (_props, state) => state.openedCategories,
    (categories, openedCategories) =>
      computeResult(categories, openedCategories),
  );

class SearchResultListComponent extends React.Component<
  SearchResultListProps,
  SearchResultListState
> {
  displayName = 'SearchResultList';

  constructor(props: AnyFixMe) {
    super(props);

    this.state = {
      isSelectedViaKeyboard: false,
      selectedIndex: -1,
      isTooltipForceShown: undefined,
      openedCategories: {},
    };
  }

  componentDidMount() {
    document.documentElement.addEventListener(
      'mousemove',
      this.handleMouseMove,
      true,
    );

    this.initIntersectionObserver();
  }

  UNSAFE_componentWillReceiveProps(nextProps: AnyFixMe, nextState: AnyFixMe) {
    if (nextProps.categories !== this.props.categories) {
      this.reset();
    }

    if (
      nextState.openedCategories !== this.state.openedCategories ||
      nextProps.categories !== this.props.categories
    ) {
      fedopsLogger.interactionStarted(
        fedopsLogger.INTERACTIONS.EDITOR_SEARCH.RESULT_SHOW,
      );
    }
  }

  componentDidUpdate(
    prevProps: AnyFixMe,
    prevState: Readonly<SearchResultListState>,
  ) {
    const newItem = this.getSelectedItem(this.props, this.state);
    const oldItem = this.getSelectedItem(prevProps, prevState);

    if (oldItem !== newItem) {
      this.checkItemBlur(oldItem);
      this.checkItemFocus(newItem);
    }

    if (
      prevState.openedCategories !== this.state.openedCategories ||
      prevProps.categories !== this.props.categories
    ) {
      fedopsLogger.interactionEnded(
        fedopsLogger.INTERACTIONS.EDITOR_SEARCH.RESULT_SHOW,
      );
    }
  }

  componentWillUnmount() {
    document.documentElement.removeEventListener(
      'mousemove',
      this.handleMouseMove,
      true,
    );

    this.destroyIntersectionObserver();
  }

  private scrollableAreaRef = React.createRef<HTMLDivElement>();
  private intersectionObserver: IntersectionObserver;
  private observedNodes: HTMLElement[] = [];
  private itemIdToIntersectionRatioMap: Record<string, number> = {};

  private readonly VISIBILITY_TIMEOUT = 3000;
  private visibilityTimeoutId: ReturnType<typeof setTimeout>;

  openHelpCenterHome = (category: string) => {
    const { openHelpCenterHome } = this.props;
    openHelpCenterHome();

    editorSearchBiLogger.logLinkClicked(
      BILinkButtonNames.HelpCenterCategory,
      category,
    );
  };

  openAppMarket = (category: string) => {
    const { openAppMarket } = this.props;
    openAppMarket();

    editorSearchBiLogger.logLinkClicked(
      BILinkButtonNames.AppMarketCategory,
      category,
    );
  };

  private categoryLinkMap = {
    [SearchCategories.APP_MARKET]: {
      text: 'Editor_Search_Results_AppMarket_Section_Link',
      callback: this.openAppMarket,
    },
    [SearchCategories.HELP_CENTER]: {
      text: 'Editor_Search_Results_HelpCenter_Section_Link',
      callback: this.openHelpCenterHome,
    },
  };

  getCategoriesForRender = createGetCategoriesForRenderSelector(
    (categories, openedCategories) => {
      const categoriesWithItems = categories
        .filter((category) => category.items.length > 0)
        .map((category) => {
          const isCategoryOpen = openedCategories[category.id];
          return this.mapCategoryForRender(category, isCategoryOpen);
        });

      const selectableItems = _.flatMap(
        categoriesWithItems,
        // eslint-disable-next-line lodash/prop-shorthand
        (category) => category.items,
      );

      const idToItemMap: Record<string, ItemForRender> = selectableItems.reduce(
        (acc, item) => ({
          ...acc,
          [item.id]: item,
        }),
        {},
      );

      return {
        categories: categoriesWithItems,
        selectableItems,
        idToItemMap,
        hasResults: selectableItems.length > 0,
      };
    },
  );

  checkItemBlur(item: ItemForRender) {
    if (item?.onLeave) {
      fedopsLogger.interactionStarted(
        fedopsLogger.INTERACTIONS.EDITOR_SEARCH.RESULT_ITEM_BLUR,
      );
      item.onLeave();
      fedopsLogger.interactionEnded(
        fedopsLogger.INTERACTIONS.EDITOR_SEARCH.RESULT_ITEM_BLUR,
      );
    }
  }

  checkItemFocus(item: ItemForRender) {
    if (item?.onEnter) {
      fedopsLogger.interactionStarted(
        fedopsLogger.INTERACTIONS.EDITOR_SEARCH.RESULT_ITEM_FOCUS,
      );
      item.onEnter();
      fedopsLogger.interactionEnded(
        fedopsLogger.INTERACTIONS.EDITOR_SEARCH.RESULT_ITEM_FOCUS,
      );
    }
  }

  initIntersectionObserver() {
    this.intersectionObserver = new IntersectionObserver(
      this.handleIntersectionChange,
      {
        root: this.scrollableAreaRef.current,
        threshold: [0, 1],
      },
    );

    while (this.observedNodes.length) {
      const node = this.observedNodes.pop();
      this.intersectionObserver.observe(node);
    }
  }

  destroyIntersectionObserver() {
    clearTimeout(this.visibilityTimeoutId);
    this.intersectionObserver.disconnect();
  }

  handleIntersectionChange = (entries: IntersectionObserverEntry[]) => {
    clearTimeout(this.visibilityTimeoutId);

    entries.forEach((entry) => {
      this.itemIdToIntersectionRatioMap[entry.target.id] =
        entry.intersectionRatio;
    });

    this.visibilityTimeoutId = setTimeout(
      this.logVisibleResults,
      this.VISIBILITY_TIMEOUT,
    );
  };

  logVisibleResults = () => {
    const visibleIds = Object.keys(this.itemIdToIntersectionRatioMap).filter(
      (id) => this.itemIdToIntersectionRatioMap[id] === 1,
    );

    const { idToItemMap } = this.getCategoriesForRender(this.props, this.state);

    visibleIds.forEach((id) =>
      editorSearchBiLogger.logItemViewed(idToItemMap[id]),
    );
  };

  handleMouseMove = () => {
    const { isSelectedViaKeyboard } = this.state;

    if (!isSelectedViaKeyboard) {
      return;
    }

    this.setState({
      isSelectedViaKeyboard: false,
    });
  };

  handleEscape = () => {
    const { isTooltipForceShown } = this.state;

    if (isTooltipForceShown) {
      this.setState({ isTooltipForceShown: false });
      return;
    }
  };

  handleItemSubmit = (isKeyboard: boolean) => {
    const selectedItem = this.getSelectedItem();

    if (selectedItem) {
      if (selectedItem.tooltipData) {
        if (!this.state.isTooltipForceShown) {
          this.forceShowTooltip();
          return;
        }
      }

      this.doAction(isKeyboard);
    }
  };

  handleShowMoreFromKeyboard = (categoryID: string) => {
    this.openCategory(categoryID);

    editorSearchBiLogger.logShowMoreClicked(categoryID, true);
  };

  handleShowMoreClick = (categoryID: string) => {
    this.openCategory(categoryID);

    editorSearchBiLogger.logShowMoreClicked(categoryID, false);
  };

  handleCTAHover = () => {
    editorSearchBiLogger.logConditionalResultCTAHovered(this.getSelectedItem());
  };

  doAction = (isKeyboard: boolean = false) => {
    const { onActionSubmit } = this.props;
    const selectedItem = this.getSelectedItem();

    if (selectedItem?.onSubmit) {
      fedopsLogger.interactionStarted(
        fedopsLogger.INTERACTIONS.EDITOR_SEARCH.RESULT_ITEM_SUBMIT,
      );
      selectedItem.onSubmit();
      fedopsLogger.interactionEnded(
        fedopsLogger.INTERACTIONS.EDITOR_SEARCH.RESULT_ITEM_SUBMIT,
      );
    }

    if (selectedItem.type === ItemType.REGULAR) {
      editorSearchBiLogger.logSearchResultCTAClicked(
        selectedItem,
        isKeyboard,
        Boolean(selectedItem.tooltipData),
      );
      onActionSubmit();
    }
  };

  reset() {
    const selectedItem = this.getSelectedItem();
    this.checkItemBlur(selectedItem);

    this.setState({
      selectedIndex: -1,
      isSelectedViaKeyboard: false,
      openedCategories: {},
    });
  }

  mapCategoryForRender(
    category: SearchResultCategory,
    isCategoryOpen: boolean,
  ): CategoryForRender {
    const { items, id } = category;
    const shouldShowLoadMore = items.length > CLOSED_CATEGORY_MAX_ITEMS_COUNT;

    let categoryItems: ItemForRender[] = items.map((item, index) => ({
      ...item,
      id: item.id || '',
      type: ItemType.REGULAR,
      categoryID: id,
      index,
    }));

    if (shouldShowLoadMore && !isCategoryOpen) {
      categoryItems = categoryItems
        .slice(0, CLOSED_CATEGORY_MAX_ITEMS_COUNT)
        .concat(this.createExpanderItem(id));
    }

    return {
      ...category,
      total: items.length,
      items: categoryItems,
    };
  }

  createExpanderItem = (categoryID: string) =>
    ({
      type: ItemType.EXPANDER,
      onSubmit: () => this.handleShowMoreFromKeyboard(categoryID),
      id: `${ItemType.EXPANDER}-${categoryID}`,
      categoryID,
      index: CLOSED_CATEGORY_MAX_ITEMS_COUNT,
    }) as ItemForRender;

  getSelectedItem = (props = this.props, state = this.state) => {
    const { selectableItems } = this.getCategoriesForRender(props, state);
    return selectableItems[state.selectedIndex];
  };

  openCategory = (categoryID: string) => {
    this.setState({
      openedCategories: {
        ...this.state.openedCategories,
        [categoryID]: true,
      },
    });
  };

  selectNextItem = () => {
    const { selectedIndex } = this.state;
    const { selectableItems } = this.getCategoriesForRender(
      this.props,
      this.state,
    );

    this.selectItemWithKeyboard((selectedIndex + 1) % selectableItems.length);
  };

  selectPreviousItem = () => {
    const { selectedIndex } = this.state;
    const { selectableItems } = this.getCategoriesForRender(
      this.props,
      this.state,
    );

    this.selectItemWithKeyboard(
      selectedIndex - 1 < 0 ? selectableItems.length - 1 : selectedIndex - 1,
    );
  };

  selectItemWithKeyboard(selectedIndex: number) {
    this.setState({
      selectedIndex,
      isSelectedViaKeyboard: true,
    });

    this.disableTooltipForceShow();
  }

  selectItemWithMouse = (item: ItemForRender, selectedIndex: number) => {
    if (selectedIndex === this.state.selectedIndex) {
      return;
    }

    const nextState = {
      selectedIndex,
      isSelectedViaKeyboard: false,
    };

    this.setState(nextState);

    if (item.type === ItemType.REGULAR) {
      editorSearchBiLogger.logSearchResultHovered(item);
    }

    this.disableTooltipForceShow();
  };

  forceShowTooltip = () => {
    this.setState({ isTooltipForceShown: true });

    editorSearchBiLogger.logConditionalResultCTAHovered(this.getSelectedItem());
  };

  disableTooltipForceShow = () => {
    if (typeof this.state.isTooltipForceShown === 'boolean') {
      this.setState({ isTooltipForceShown: undefined });
    }
  };

  getIsTooltipForceShown(item: ItemForRender) {
    return (
      typeof this.state.isTooltipForceShown === 'boolean' &&
      this.state.isTooltipForceShown &&
      this.getSelectedItem().id === item.id
    );
  }

  getHandleResultRef = (item: AnyFixMe) => {
    return (ref?: HTMLElement) => {
      if (!ref) {
        return;
      }

      ref.id = item.id;

      if (this.intersectionObserver) {
        this.intersectionObserver.observe(ref);
        return;
      }

      this.observedNodes.push(ref);
    };
  };

  renderSearchCategory = (
    { id, items, total }: CategoryForRender,
    prevCategoryTotal: AnyFixMe,
  ) => {
    const { t } = this.props;
    const link = (this.categoryLinkMap as AnyFixMe)[id];
    const categoryLink = link ? (
      <Link
        key={id}
        dataHook={AUTOMATION_IDS.SEARCH_RESULT_CATEGORY.LINK}
        onClick={() => link.callback(id)}
      >
        {t(link.text)}
      </Link>
    ) : null;

    return (
      <div key={id} data-hook={`category-${id}`}>
        <SectionHeader
          title={t(TRANSLATIONS.CATEGORIES[id].title)}
          link={categoryLink}
        />
        <div>
          {items.map((item, index) =>
            this.renderCategoryItem(item, index + prevCategoryTotal, total, id),
          )}
        </div>
      </div>
    );
  };

  renderCategoryItem = (
    item: ItemForRender,
    index: number,
    total: number,
    categoryID: SearchCategories,
  ) => {
    const { searchQuery, t } = this.props;
    const selectedItem = this.getSelectedItem();
    const isSelected = selectedItem && selectedItem.id === item.id;
    const shouldScrollIntoView = isSelected && this.state.isSelectedViaKeyboard;
    const hiddenItemsCount = total - CLOSED_CATEGORY_MAX_ITEMS_COUNT;

    const handleItemHover = () => this.selectItemWithMouse(item, index);
    const handleExpanderItemClick = () => this.handleShowMoreClick(categoryID);
    const handleRegularItemClick = () => this.handleItemSubmit(false);

    const linkText =
      hiddenItemsCount > 1
        ? t(TRANSLATIONS.CATEGORIES[categoryID].showMorePlural, {
            search_term: searchQuery,
            more_results_count: hiddenItemsCount,
          })
        : t(TRANSLATIONS.CATEGORIES[categoryID].showMore, {
            search_term: searchQuery,
          });

    if (item.type === ItemType.EXPANDER) {
      return (
        <Panel
          theme="theme-more-results"
          key={`item-${item.id}`}
          shouldScrollIntoView={shouldScrollIntoView}
        >
          <Link
            dataHook={AUTOMATION_IDS.SEARCH_RESULT_CATEGORY.SHOW_MORE}
            onClick={handleExpanderItemClick}
            onHover={handleItemHover}
            selected={isSelected}
          >
            {linkText}
          </Link>
        </Panel>
      );
    }
    const defaultTranslationForCTA = t(
      TRANSLATIONS.CATEGORIES[categoryID].itemCTA ||
        'Editor_Search_Result_CTA_Open',
    );
    const ctaText = item.onSubmit
      ? item.translations.cta || defaultTranslationForCTA
      : null;

    return (
      <SearchItem
        key={`item-${item.id}`}
        icon={item.icon}
        title={item.title}
        description={item.description}
        cta={ctaText}
        tooltipData={item.tooltipData}
        shouldScrollIntoView={shouldScrollIntoView}
        isSelected={isSelected}
        isTooltipForceShown={this.getIsTooltipForceShown(item)}
        isSuccessIconVisible={item.isSuccessIconVisible}
        onRef={this.getHandleResultRef(item)}
        onClick={handleRegularItemClick}
        onHover={handleItemHover}
        onConditionalCTAHover={this.handleCTAHover}
        onTooltipEnterLeave={this.disableTooltipForceShow}
        onTooltipCTAClick={this.doAction}
      />
    );
  };

  render() {
    const { isSelectedViaKeyboard } = this.state;
    const { categories } = this.getCategoriesForRender(this.props, this.state);

    return (
      <KeyboardControl
        onEnter={() => this.handleItemSubmit(true)}
        onEscape={this.handleEscape}
        onArrowDown={this.selectNextItem}
        onArrowUp={this.selectPreviousItem}
      >
        <div
          ref={this.scrollableAreaRef}
          className={styles.container}
          style={{
            pointerEvents: isSelectedViaKeyboard ? 'none' : 'all',
          }}
        >
          <Scrollable>
            {
              categories.reduce(
                (categoriesToShow, category, index) => {
                  categoriesToShow.preparedCategories.push(
                    this.renderSearchCategory(
                      category,
                      categoriesToShow.totalItems,
                    ),
                  );
                  categoriesToShow.totalItems +=
                    categories[index]?.items.length || 0;

                  return categoriesToShow;
                },
                { preparedCategories: [], totalItems: 0 },
              ).preparedCategories
            }
          </Scrollable>
        </div>
      </KeyboardControl>
    );
  }
}

export const SearchResultList = _.flow(
  connect(EDITOR_API, null, mapDispatchToProps),
  withTranslation(),
)(SearchResultListComponent);
