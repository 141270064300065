import { sections } from '@/util';
import { waitForAddedCompRef } from '@/componentsAddUtils';
import { getPageSections } from '../../api';

import type { Scope } from '@/sections';
import type { EditorAPI } from '@/editorAPI';
import type { CompRef } from 'types/documentServices';

const wrapPageCompsInSection = async (scope: Scope, pageRef: CompRef) => {
  const { components, editorAPI } = scope;
  const pageSections = getPageSections(scope, pageRef);

  if (pageSections.length) return;

  const { height: pageHeight } = editorAPI.components.layout.get_size(pageRef);

  const pageComponents = components
    .getChildren(pageRef)
    .filter((compRef) => !components.layout.isPinned(compRef));

  const { title } = editorAPI.pages.data.get(pageRef.id);

  const pageHeightByComponents = Math.max(
    ...pageComponents
      .map(components.layout.get)
      .map((layout) => layout.y + layout.height),
  );

  const blankSectionStructure = sections.getBlankSectionStructure({
    y: 0,
    height: Math.max(
      pageHeight,
      pageHeightByComponents,
      sections.constants.SECTION_MIN_HEIGHT,
    ),
    name: title,
  });

  const sectionRef = await waitForAddedCompRef(
    components.add(pageRef, blankSectionStructure),
  );

  const ignorePostUpdateSelection = true;

  pageComponents.forEach((compRef) =>
    components.setContainer([compRef], sectionRef, ignorePostUpdateSelection),
  );

  await editorAPI.waitForChangesAppliedAsync();

  editorAPI.components.hooks.componentAddedToStage.fire({
    type: 'enforceSection',
    compRef: sectionRef,
    origin: 'enforceSection',
  });
};

export const enforceSectionContainer = async (
  scope: Scope,
  pageRef: CompRef,
) => {
  const { editorAPI, history } = scope;
  await wrapPageCompsInSection(scope, { ...pageRef, type: 'DESKTOP' });

  if (pageRef.type === 'MOBILE') {
    if (getPageSections(scope, pageRef).length === 0) {
      editorAPI.mobileConversion.runMobileMergeOnPageById(pageRef.id);
    }
  }

  await editorAPI.waitForChangesAppliedAsync();
  history.amend();
};

export const isAllowedToRunEnforcement = (
  editorAPI: EditorAPI,
  pageRef: CompRef,
) => {
  if (!pageRef?.id) return false;

  const pageData = editorAPI.pages.data.get(pageRef.id);

  if (!pageData) {
    return false;
  }
  const { isPopup, managingAppDefId, tpaPageId } = pageData;

  if (isPopup) return false;
  if (!managingAppDefId && !tpaPageId) return false;

  return true;
};
