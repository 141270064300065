import React from 'react';
import * as symbols from '@wix/santa-editor-symbols';
import {
  Composites,
  Divider,
  Slider,
  TextLabel,
  Thumbnails,
} from '@wix/wix-base-ui';
import * as BaseUI from '@/baseUI';

import type { IEffectsPanelProps } from '../effectsPanel';
import type { ISkew } from '../../../interactionsTransformFacade';

import {
  ALIGNMENT_MODES_XY,
  getOriginFromValue,
  getSelectedValueFromOrigin,
} from './customizeUtils';
import { EFFECT_LIMITS, EffectTypes } from '../effects';
import * as coreBi from '@/coreBi';

const enum SKEW_DIRECTION {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
}

const SKEW_DIRECTIONS = [
  {
    value: SKEW_DIRECTION.HORIZONTAL,
    label: 'interactions_effects_skew_skew_direction_horizontal',
    illustration: React.createElement(symbols.symbol, {
      name: 'interactionsEffectsSkewHorizontal',
    }),
  },
  {
    value: SKEW_DIRECTION.VERTICAL,
    label: 'interactions_effects_skew_skew_direction_vertical',
    illustration: React.createElement(symbols.symbol, {
      name: 'interactionsEffectsSkewVertical',
    }),
  },
];

type IProps = Pick<
  IEffectsPanelProps,
  'skew' | 'skewValue' | 'origin' | 'sendBI' | 'variantId'
>;

interface IState {
  skewValue: ISkew;
  direction: SKEW_DIRECTION;
}

const ALLOWED_ORIGINS = {
  [SKEW_DIRECTION.VERTICAL]: [3, 4, 5],
  [SKEW_DIRECTION.HORIZONTAL]: [1, 4, 7],
};

function getAlignments(direction: SKEW_DIRECTION) {
  return ALIGNMENT_MODES_XY.map((alignment, index) => ({
    ...alignment,
    disabled: ALLOWED_ORIGINS[direction].indexOf(index) === -1, //eslint-disable-line lodash/prefer-includes
  }));
}

export class CustomizeSkewPanel extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      skewValue: props.skewValue,
      direction: SKEW_DIRECTION.HORIZONTAL,
    };
  }

  handleAlignmentChange = (value: string) => {
    const skew = this.state.skewValue;
    const origin = getOriginFromValue(value);
    this.props.sendBI(
      coreBi.events.interactions.effects_panel_change_settings,
      {
        interaction_id: this.props.variantId,
        behavior_name: EffectTypes.SKEW,
        parameter_name: 'effect_origin',
        old_value: getSelectedValueFromOrigin(this.props.origin),
        new_value: value,
      },
    );
    this.props.skew(skew, origin);
  };

  handleSkewChange = (value: number, direction: SKEW_DIRECTION) => {
    const skew =
      direction === SKEW_DIRECTION.HORIZONTAL
        ? {
            x: value,
            y: 0,
          }
        : {
            x: 0,
            y: value,
          };

    this.props.sendBI(
      coreBi.events.interactions.effects_panel_change_settings,
      {
        interaction_id: this.props.variantId,
        behavior_name: EffectTypes.SKEW,
        parameter_name:
          direction === SKEW_DIRECTION.HORIZONTAL ? 'hor_skew' : 'ver_skew',
        old_value: this.getActiveValue(
          this.state.skewValue,
          this.state.direction,
        ),
        new_value: this.getActiveValue(skew, direction),
      },
    );
    this.props.skew(skew, this.props.origin);
    this.setState({ skewValue: skew });
  };

  handleDirectionChange = (direction: SKEW_DIRECTION) => {
    const skewValue = this.getActiveValue(
      this.state.skewValue,
      this.state.direction,
    );
    this.handleSkewChange(skewValue, direction);
    this.setState({ direction });
  };

  getActiveValue(skew: ISkew, direction: SKEW_DIRECTION) {
    return direction === SKEW_DIRECTION.HORIZONTAL ? skew.x : skew.y;
  }

  render() {
    const skewDirection = this.state.direction;
    const skewValue = this.getActiveValue(this.state.skewValue, skewDirection);

    return (
      <div className="customize-effect-panel" data-aid="customize-effect-panel">
        <Divider long={true} />
        <BaseUI.sectionDividerLabeled label="interactions_effects_skew_section_title" />
        <Divider long={true} />

        <Composites.Thumbnails>
          <TextLabel value="interactions_effects_skew_skew_direction_label" />
          <Thumbnails
            value={skewDirection}
            onChange={this.handleDirectionChange}
            maxThumbsPerRow={3}
            fixedRatio={true}
            options={SKEW_DIRECTIONS}
          />
        </Composites.Thumbnails>

        <Divider long={false} />

        <Composites.SliderLabeled>
          <TextLabel value="interactions_effects_skew_skew_by_label" />
          <Slider
            value={skewValue}
            onChange={(value: AnyFixMe) =>
              this.handleSkewChange(value, skewDirection)
            }
            unit={'angle'}
            min={EFFECT_LIMITS.SKEW.MIN}
            max={EFFECT_LIMITS.SKEW.MAX}
            step={1}
          />
        </Composites.SliderLabeled>

        <Divider long={false} />

        <BaseUI.alignment
          options={getAlignments(skewDirection)}
          label={'interactions_effects_skew_skew_from_label'}
          value={getSelectedValueFromOrigin(this.props.origin)}
          onChange={this.handleAlignmentChange}
          infoIconProps={{
            text: 'interactions_effects_skew_skew_from_tooltip',
          }}
        />
      </div>
    );
  }
}
