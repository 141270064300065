import { HttpClient } from '@wix/http-client';
import { WixStorageStrategy, DataCapsule } from '@wix/data-capsule';
import { useState, useEffect, useMemo } from 'react';

const FORMS_BANNER_USER_PREFERENCES_NAMESPACE = 'wixCode.formsMigration';

export const useFormsMigrationState = ({
  userId,
  preferencesKey,
}: {
  userId: string;
  preferencesKey: string;
}): [boolean, (state: boolean) => void] => {
  const [formsNotificationSeen, setFormsNotificationSeen] =
    useState<boolean>(true);

  const capsule = useMemo(() => {
    const httpClient = new HttpClient();
    return new DataCapsule({
      strategy: new WixStorageStrategy({ httpClient }),
      namespace: FORMS_BANNER_USER_PREFERENCES_NAMESPACE,
      scope: userId,
    });
  }, [userId]);

  useEffect(() => {
    capsule
      .getItem(preferencesKey)
      .then((response: boolean) => {
        setFormsNotificationSeen(response);
      })
      .catch(() => {
        setFormsNotificationSeen(false);
      });
  }, [capsule, preferencesKey]);

  const setHaveSeenNotification = (state: boolean) => {
    setFormsNotificationSeen(state);
    capsule.setItem(preferencesKey, state);
  };

  return [formsNotificationSeen, setHaveSeenNotification];
};
