import type { CompSnapshot } from './types';

const hasComponentsOverlap = (
  layoutA: CompSnapshot['layout'],
  layoutB: CompSnapshot['layout'],
): boolean => {
  const hasHorizontalOverlap =
    layoutA.x < layoutB.x + layoutB.width &&
    layoutB.x < layoutA.x + layoutA.width;
  const hasVerticalOverlap =
    layoutA.y < layoutB.y + layoutB.height &&
    layoutB.y < layoutA.y + layoutA.height;

  return hasHorizontalOverlap && hasVerticalOverlap;
};

const calculateOverlapList = (components: CompSnapshot[]) => {
  const overlapList: [string, string][] = []; // [topComponent, bottomComponent][]

  for (let a = 0; a < components.length - 1; a++) {
    for (let b = a + 1; b < components.length; b++) {
      const layoutA = components[a].layout;
      const layoutB = components[b].layout;

      if (layoutA && layoutB && hasComponentsOverlap(layoutA, layoutB)) {
        overlapList.push([components[b].id, components[a].id]);
      }
    }
  }

  return overlapList;
};

export function calculateLevelMap(
  components: CompSnapshot[], // components is sorted by global zIndex
): Map<string, number> {
  // list of pair [topComponent, bottomComponent] of overlap components
  let overlapList = calculateOverlapList(components);

  const levelMap = new Map(
    components.map((compSnapshot) => [compSnapshot.id, 0]),
  );

  let level = 1;

  // each overlap of a component increase its level by one
  while (overlapList.length) {
    const nextOverlapList: [string, string][] = [];
    const bottomComponents = new Set();

    for (const [, bottom] of overlapList) {
      if (overlapList.every(([top]) => top !== bottom)) {
        bottomComponents.add(bottom);
      }
    }

    for (const [top, bottom] of overlapList) {
      if (!bottomComponents.has(top)) {
        levelMap.set(top, level);
      }

      if (!bottomComponents.has(bottom)) {
        nextOverlapList.push([top, bottom]);
      }
    }

    overlapList = nextOverlapList;
    level++;
  }

  return levelMap;
}
