import _ from 'lodash';
import experiment from 'experiment';
import type { AppDataComponent, CompRef } from 'types/documentServices';
import type { EditorAPI } from '@/editorAPI';
import type {
  WidgetSlot,
  WidgetPointer,
  PluginInfo,
} from '@wix/editor-platform-host-integration/ui';

import {
  provisionPluginAppIfNeeded,
  createWidgetPluginComponentDefinition,
  isInViewport,
} from './widgetPluginsUtils';
import type { Origin } from '../api/document/tpa/widgetPlugins';
import { ErrorReporter } from '@wix/editor-error-reporter';

const defaultSlotName = 'slot';
const slotPlaceholderType = 'wixui.SlotsPlaceholder';
const refComponentType = 'wysiwyg.viewer.components.RefComponent';
const appWidget = 'platform.components.AppWidget';

export default (editorAPI: EditorAPI) => {
  function isWidgetInWidget(widgetCompRef: CompRef): boolean {
    widgetCompRef =
      editorAPI.components.getType(widgetCompRef) === appWidget
        ? editorAPI.components.refComponents.getRefHostCompPointer(
            widgetCompRef,
          )
        : widgetCompRef;

    return (
      editorAPI.components.refComponents.isReferredComponent(widgetCompRef) &&
      editorAPI.components.getType(widgetCompRef) === refComponentType
    );
  }

  function hasWidgetSlot(widgetCompRef: CompRef): boolean {
    if (isWidgetInWidget(widgetCompRef)) {
      return false;
    }

    return (
      getWidgetSlotCompRefsForGfpp(getWidgetHostRef(widgetCompRef)).length > 0
    );
  }

  // NOTE: It's quite heavy, but widget slots are not changed during the session, so we can cache the result
  const getWidgetSlotCompRefsCached = _.memoize(
    getWidgetSlotCompRefs,
    (compRef) =>
      compRef &&
      `${compRef.id}-${compRef.type}-${editorAPI.components.is.rendered(
        compRef,
      )}`,
  );

  function isValidStudioVersion(component: AppDataComponent): boolean {
    if (!component) {
      return false;
    }
    // @ts-expect-error TODO add blocksVersion type here
    // for some reason in runtime type comes from csm and it is not a StudioComponentData type
    const blocksVersion = component.data?.blocksVersion ?? '';
    const [major] = blocksVersion?.split('.');
    return parseInt(major, 10) >= 2;
  }

  function isStudioComponent(refComponentPointer: CompRef): boolean {
    const { appDefinitionId, widgetId, type } =
      editorAPI.components.data.get(refComponentPointer);
    if (type !== 'WidgetRef') {
      return false;
    }
    const appData = editorAPI.platform.getAppDataByAppDefId(appDefinitionId);
    if (!appData) return false;
    return isValidStudioVersion(
      appData.components?.find(
        (c) => c.componentId === widgetId && c.type === 'STUDIO_WIDGET',
      ),
    );
  }

  function getWidgetSlotCompRefs(widgetCompRef: CompRef): CompRef[] {
    return getSlotsFromDm(widgetCompRef).map(({ compRef }) => compRef);
  }

  function reportGetWidgetSlotsError(error: any) {
    ErrorReporter.captureException(error, {
      tags: { widgetPlugins: true },
    });
    console.error(error);
  }

  function extractSlots(
    slots: ReturnType<typeof editorAPI.dsRead.components.slots.getWidgetSlots>,
  ) {
    return slots.map(({ interfaces, compRef, role, pluginInfo }) => {
      return {
        role,
        pluginInfo: pluginInfo as PluginInfo,
        compRef: compRef as CompRef,
        interfaces,
      };
    });
  }

  function getSlotsFromDm(
    widgetCompRef: CompRef,
  ): Omit<WidgetSlot, 'placement'>[] {
    if (isStudioComponent(widgetCompRef)) {
      try {
        return extractSlots(
          editorAPI.dsRead.components.slots.getWidgetSlots(widgetCompRef),
        );
      } catch (e) {
        reportGetWidgetSlotsError(e);
        return [];
      }
    }
    return [];
  }

  function getWidgetSlotCompRefsForGfpp(compRef: CompRef): CompRef[] {
    if (experiment.isOpen('se_widgetPlugins_cacheGetWidgetSlotCompRefs')) {
      return getWidgetSlotCompRefsCached(compRef);
    }
    return getWidgetSlotCompRefs(compRef);
  }

  function getWidgetSlots(widgetCompRef: CompRef): WidgetSlot[] {
    const slots = getSlotsFromDm(widgetCompRef);
    return slots.map((slot) => ({
      ...slot,
      placement: getPlacement(slot),
    }));
  }

  function getWidgetSlot(slotCompRef: CompRef): WidgetSlot {
    const hostRef = getWidgetHostRef(slotCompRef);
    const slots = getSlotsFromDm(hostRef);
    const slot = slots.find((s) => s.compRef.id === slotCompRef.id);
    return {
      ...slot,
      placement: getPlacement(slot),
    };
  }

  function getPlacement(slot: Omit<WidgetSlot, 'placement'>) {
    const hostRef = getWidgetHostRef(slot.compRef);
    const { widgetId, appDefinitionId } =
      editorAPI.components.data.get(hostRef);
    return {
      appDefinitionId,
      widgetId,
      slotId: slot.role,
    };
  }

  function scrollToSlot(compRef: CompRef) {
    const compLayout =
      editorAPI.components.layout.getRelativeToScreenConsideringScroll(compRef);

    if (isInViewport(editorAPI, compLayout)) {
      return;
    }

    const positionOffset = 30;
    editorAPI.ui.scroll.animateScroll(
      {
        y: compLayout.y - positionOffset,
      },
      0.8,
    );
  }

  async function highlightSlot(compRef: CompRef) {
    editorAPI.documentMode.setComponentPreviewState(
      compRef.id,
      'slot-highlighted',
    );
    await editorAPI.waitForChangesAppliedAsync();

    scrollToSlot(compRef);
  }

  function unhighlightSlot(compRef: CompRef) {
    editorAPI.documentMode.setComponentPreviewState(compRef.id, null);
  }

  async function installWidgetPlugin(
    slotComponentRef: CompRef,
    widgetPointer: WidgetPointer,
    origin: Origin,
  ): Promise<CompRef> {
    const { applicationId } = await provisionPluginAppIfNeeded(
      editorAPI,
      widgetPointer,
      origin,
    );

    const widgetComponent = await createWidgetPluginComponentDefinition(
      editorAPI,
      widgetPointer,
      applicationId,
    );

    const compRef: CompRef = editorAPI.dsActions.components.slots.populate(
      slotComponentRef,
      defaultSlotName,
      widgetComponent,
    );
    return compRef;
  }

  function uninstallWidgetPlugin(slotComponentRef: CompRef) {
    editorAPI.dsActions.components.slots.remove(
      slotComponentRef,
      defaultSlotName,
    );
  }

  function getWidgetPointer(
    slotPlaceholderCompRef: CompRef,
  ): WidgetPointer | undefined {
    const contentRef = editorAPI.dsRead.components.slots.getSlotsData(
      slotPlaceholderCompRef,
    )[defaultSlotName];

    if (!contentRef) {
      return undefined;
    }

    const { appDefinitionId, widgetId } =
      editorAPI.dsRead.components.data.get(contentRef);

    return { appDefinitionId, widgetId };
  }

  function getWidgetHostRef(compRef: CompRef): CompRef {
    return editorAPI.components.refComponents.isReferredComponent(compRef)
      ? editorAPI.components.refComponents.getRootRefHostCompPointer(compRef)
      : compRef;
  }

  function isWidgetSlotPopulated(slotComponentRef: CompRef) {
    return !!editorAPI.dsRead.components.slots.getSlotsData(slotComponentRef)?.[
      defaultSlotName
    ];
  }

  function isWidgetPluginComponent(compRef: CompRef): boolean {
    const parentRef =
      editorAPI.components.getContainer_DEPRECATED_BAD_PERFORMANCE(compRef);

    return editorAPI.components.getType(parentRef) === slotPlaceholderType;
  }

  function getSlotPlaceholderRefByContentRef(
    widgetPluginCompRef: CompRef,
  ): CompRef {
    return editorAPI.components.getContainer_DEPRECATED_BAD_PERFORMANCE(
      widgetPluginCompRef,
    );
  }

  function getSlotPlaceholderDisplayName(
    slotPlaceholderCompRef: CompRef,
  ): string {
    return editorAPI.components.getDisplayName(slotPlaceholderCompRef);
  }

  return {
    hasWidgetSlot,
    getWidgetSlot,
    getWidgetSlots,
    highlightSlot,
    unhighlightSlot,
    installWidgetPlugin,
    uninstallWidgetPlugin,
    getWidgetPointer,
    getWidgetHostRef,
    isWidgetSlotPopulated,
    isWidgetPluginComponent,
    getSlotPlaceholderRefByContentRef,
    getSlotPlaceholderDisplayName,
    scrollToSlot,
  };
};
