:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._container_14pe8_7 {
  --size: 26px;
  --horizontal-padding: 12px;
  display: inline-flex;
  align-items: center;
  background-color: #d9d2ff;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  padding-left: var(--horizontal-padding);
  height: var(--size); }
  ._container_14pe8_7._zoomMode_14pe8_17 {
    --size: 16px;
    --horizontal-padding: 6px;
    margin-top: 10px;
    border-radius: 0; }
    ._container_14pe8_7._zoomMode_14pe8_17 .control-text {
      line-height: var(--size); }
  ._container_14pe8_7 ._hidden_14pe8_24 {
    position: absolute;
    z-index: -9999;
    visibility: hidden;
    white-space: nowrap; }
  ._container_14pe8_7 ._name_14pe8_29 {
    display: inline-block;
    max-width: 100px; }
  ._container_14pe8_7 ._nameContainer_14pe8_32 {
    position: relative;
    padding-right: var(--horizontal-padding);
    height: inherit;
    display: flex;
    align-items: center; }
    ._container_14pe8_7 ._nameContainer_14pe8_32 ._fullName_14pe8_38 {
      max-width: var(--maxNameWidth); }
  ._container_14pe8_7 ._button_14pe8_40 {
    position: absolute;
    right: 0;
    top: 0;
    display: none; }
  ._container_14pe8_7:not(._zoomMode_14pe8_17):not(._mobile_14pe8_45) {
    cursor: pointer; }
    ._container_14pe8_7:not(._zoomMode_14pe8_17):not(._mobile_14pe8_45):hover {
      padding-right: 0; }
      ._container_14pe8_7:not(._zoomMode_14pe8_17):not(._mobile_14pe8_45):hover ._button_14pe8_40 {
        display: flex; }
      ._container_14pe8_7:not(._zoomMode_14pe8_17):not(._mobile_14pe8_45):hover ._nameContainer_14pe8_32 {
        padding-right: var(--size); }
      ._container_14pe8_7:not(._zoomMode_14pe8_17):not(._mobile_14pe8_45):hover ._fullName_14pe8_38 {
        max-width: calc( var(--maxNameWidth) - var(--size) + var(--horizontal-padding)); }
  ._container_14pe8_7._selected_14pe8_55 {
    background-color: #6f56f9; }
    ._container_14pe8_7._selected_14pe8_55 ._icon_14pe8_57 {
      color: #fff; }
    ._container_14pe8_7._selected_14pe8_55 ._button_14pe8_40 {
      background-color: #6f56f9; }
      ._container_14pe8_7._selected_14pe8_55 ._button_14pe8_40:hover {
        background-color: #4429da; }
  ._container_14pe8_7:not(._selected_14pe8_55) .control-text.skin-premium {
    color: #6f56f9; }

._button_14pe8_40 {
  width: 24px;
  height: 24px;
  border-radius: 2px;
  background-color: #d9d2ff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1px;
  margin-right: 1px; }
  ._button_14pe8_40:hover {
    background-color: #c6bdfb; }

._icon_14pe8_57 {
  color: #6f56f9; }
