import type { DocumentServicesObject } from 'types/documentServices';
import { NEW_SITE_WIDTH } from '../constants';

export function checkIsNewSite(documentServices: DocumentServicesObject) {
  return (
    documentServices.generalInfo.isFirstSave() ||
    documentServices.generalInfo.isDraft()
  );
}

export function isCurrentSiteWidthEqualsToNew(
  documentServices: DocumentServicesObject,
) {
  const currentSiteWidth = documentServices.site.getWidth();

  return currentSiteWidth === NEW_SITE_WIDTH;
}
