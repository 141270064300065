// @ts-nocheck
import createReactClass from 'create-react-class';
import React from 'react';
import _ from 'lodash';
import { CustomScroll } from '@wix/wix-base-ui';

import * as baseUI from '@/baseUI';
import * as core from '@/core';
import addPanelDataConsts from '@/addPanelDataConsts';
import * as util from '@/util';
import * as uiAnimations from '@/uiAnimations';
import * as coreBi from '@/coreBi';

import * as categoryViewUserPref from './categoryViewMixins/categoryViewUserPref';
import * as addPanelUtils from '../addPanel/addPanelUtils';
import { withTrackFirstPresetSectionLoad } from './withTrackFirstPresetSectionLoad';
import BaseGenerator from '../sections/baseGenerator';

const { tooltipManager } = baseUI;

let currentSectionIndexView = 0;

function getSelectedSectionTop() {
  const selectedSection = this.props.section.toLowerCase();
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/find-index
  const selectedIndex = _.findIndex(this.props.items, function (item) {
    return (
      item.sectionName && item.sectionName.toLowerCase() === selectedSection
    );
  });

  return this.getSectionTops()[selectedIndex];
}

// eslint-disable-next-line react/prefer-es6-class
export const CategoryViewComponent = createReactClass({
  displayName: 'categoryView',
  mixins: [
    core.mixins.editorAPIMixin,
    categoryViewUserPref,
    util.blockOuterScrollMixin,
  ],
  getInitialState() {
    this.updateScrollValues();
    return {
      showInnerNavigation: false,
    };
  },
  componentDidMount() {
    this.mounted = true;

    this.showInnerNavigation();
    this.prepareData();
    if (this.props.sectionId) {
      this.scrollToSectionId(this.props.sectionId);
    }
  },
  shouldComponentUpdate(nextProps, nextState) {
    return !(
      _.isEqual(this.props, nextProps) && _.isEqual(this.state, nextState)
    );
  },
  updateScrollValues() {
    this.lastScroll = this.getTopScrollFromUserPrefs(this.props.id);
    this.topSection = this.getTopSectionFromUserPrefs(this.props.id);
  },
  componentDidUpdate(prevProps) {
    this.calcSectionsTop();
    this.calcSectionsHeight();
    if (this.props.id !== prevProps.id) {
      this.prepareData();
    }
    if (this.props.sectionId !== prevProps.sectionId) {
      this.scrollToSectionId(this.props.sectionId);
    }
  },
  prepareData() {
    this.setAllSectionsTops();
    this.updateScrollValues();
    this.notifyMaxScroll();
  },
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.id !== nextProps.id) {
      this.setTopSectionToUserPrefs(
        this.props.id,
        this.lastScroll,
        this.topSection,
      );
    }
  },
  componentWillUnmount() {
    this.mounted = false;
    this.setTopSectionToUserPrefs(
      this.props.id,
      this.lastScroll,
      this.topSection,
    );
  },
  calcSectionsTop() {
    if (this.mounted) {
      this.sectionTops = _.times(
        this.props.items.length,
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/bind
        _.bind(this.getSectionTopByIndex, this),
      );
    }
  },
  calcSectionsHeight() {
    if (this.mounted) {
      this.sectionsHeight = _.times(
        this.props.items.length,
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/bind
        _.bind(this.getSectionHeightByIndex, this),
      );
    }
  },
  setAllSectionsTops() {
    this.calcSectionsTop();
    this.calcSectionsHeight();
    const scrollTop = this.props.section
      ? getSelectedSectionTop.call(this)
      : this.getTopScrollFromUserPrefs(this.props.id);
    this.scrollTo(scrollTop);
    this.setTopSection(scrollTop, false);
  },
  getSectionTops() {
    if (!this.sectionTops) {
      this.calcSectionsTop();
    }
    return this.sectionTops;
  },
  getSectionsHeight() {
    return this.sectionsHeight;
  },
  showInnerNavigation() {
    if (this.getInnerNavigationSavedState()) {
      this.setState({ showInnerNavigation: true });
      return;
    }
    if (!this.state.showInnerNavigation) {
      window.setTimeout(
        function () {
          if (this.mounted) {
            this.setState({ showInnerNavigation: true });
          }
        }.bind(this),
        2000,
      );
    }
  },
  isInnerNavigationShown() {
    return this.state.showInnerNavigation;
  },
  getSectionItemProps(section, sectionIndex) {
    const onMouseEnterInfoIcon = function () {
      this.setState({ overrideTooltipId: section.id });
    }.bind(this);
    const onMouseLeaveInfoIcon = function () {
      const biParams = { section: section.title, category: this.props.id };
      this.getEditorAPI().bi.event(
        coreBi.events.addPanel.HOVER_SECTION_INFO,
        biParams,
      );
      this.setState({ overrideTooltipId: null });
    }.bind(this);
    const videoIdKey = util.keyGenerator.videoIdKey(section.title);
    const useVideoIcon =
      section.showSectionHeader &&
      util.media.getVideoIfAvailable(section.title, videoIdKey);
    const infoIconSymbolName = useVideoIcon
      ? 'info_video_tooltip_icon'
      : 'infoIcon';

    if (this.props.onClickOverride && section.onClick) {
      section.onClick = this.props.onClickOverride;
    }

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    const overrideHelpParams = _.assign(
      {
        onMouseEnter: onMouseEnterInfoIcon,
        onMouseLeave: onMouseLeaveInfoIcon,
        symbolName: infoIconSymbolName,
      },
      section.help,
    );

    const additionalPropClass = this.props?.props?.class;
    if (additionalPropClass && !section?.props?.class) {
      _.set(section, ['props', 'class'], additionalPropClass);
    }

    return Object.assign(
      {
        onClick: this.props.onClickOverride || this.handleItemClick,
        onItemDrag: this.props.onItemDrag,
        openHelpCenter: this.openHelpCenter,
        parentType:
          this.props.origin || addPanelDataConsts.PANEL_TYPES.ADD_PANEL,
        notifyLayoutDone: this.calcSectionsTop,
        sectionIndex,
        nextSibling: this.props.items[sectionIndex + 1],
        categoryId: this.props.id,
        appDefinitionId: this.props.appDefinitionId,
        currentPage: this.props.currentPage,
        currentSectionIndexView,
        hasSubCategory: this.props.hasSubCategory,
        shouldTranslate: this.props.shouldTranslate,
        SectionDragSourceWrapper: this.props.SectionDragSourceWrapper,
        transformStructureToResponsive:
          this.props.transformStructureToResponsive,
        installAppOverride: this.props.installAppOverride,
        dragSourceCreatePayload: this.props.dragSourceCreatePayload,
        mediaManagerCallbackOverride: this.props.mediaManagerCallbackOverride,
        ignoreStyleMutations: this.props.ignoreStyleMutations,
        itemHoverCallback:
          section.type === addPanelDataConsts.SECTIONS_TYPES.PRESET
            ? this.updateMaxHoveredItem
            : _.noop,
        getPastePosition: this.props.getPastePosition,
        onComponentAddedToStage: this.props.onComponentAddedToStage,
      },
      section,
      { help: overrideHelpParams },
    );
  },

  handleItemClick(
    compDef,
    sectionTitle,
    sectionTags,
    itemId,
    editorAPI,
    additionalData,
  ) {
    editorAPI = editorAPI || this.getEditorAPI();

    if (additionalData?.nonDraggable || additionalData?.nonComponent) {
      // BI event 38:122 should be called even if the section doesn't support dnd or provide a component to add
      editorAPI.reportAddMenuDrag({
        preset_id: itemId,
        category: this.props.id,
        section: sectionTitle,
      });
    }

    if (additionalData?.nonComponent) {
      return;
    }

    return this.attemptToAddComponent(
      compDef,
      sectionTitle,
      sectionTags,
      itemId,
      editorAPI,
      additionalData,
    );
  },

  async attemptToAddComponent(
    compDef,
    sectionTitle,
    sectionTags,
    itemId,
    editorAPI,
    additionalData,
  ) {
    editorAPI = editorAPI || this.getEditorAPI();
    const { id: categoryId, origin } = this.props;

    util.fedopsLogger.interactionStarted(
      addPanelUtils.appendAdditionalInteractionNames(
        [util.fedopsLogger.INTERACTIONS.ADD_COMP_FROM_ADD_PANEL],
        compDef,
      ),
      {
        paramsOverrides: {
          component_type: compDef.componentType,
          method: 'click',
        },
      },
    );

    const appDefinitionId =
      additionalData?.appDefinitionId || this.props.appDefinitionId;

    if (appDefinitionId) {
      await editorAPI.tpa.installAppIfNeeded(appDefinitionId, {
        resolveBeforeSave: true,
      });
    }

    const compRef = await addPanelUtils.attemptToAddComponent(
      editorAPI,
      compDef,
      categoryId,
      sectionTitle,
      sectionTags,
      itemId,
      origin,
      appDefinitionId,
      this.props.getPastePosition
        ? {
            position: this.props.getPastePosition(compDef),
          }
        : undefined,
    );

    if (this.props.onComponentAddedToStage) {
      this.props.onComponentAddedToStage(compRef, compDef);
    }
  },

  //TODO move to mapDispatchToProps
  openHelpCenter(helpId: string, props?: AnyFixMe, biParams?: AnyFixMe) {
    this.getEditorAPI().panelManager.openHelpCenter(helpId, props, biParams);
  },
  shouldShowInnerNavigation() {
    return (
      !this.props.hideInnerNavigation &&
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/filter
      _.filter(this.props.items, { showSectionHeader: true }).length > 1
    );
  },
  getSectionsWithHeaders() {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/filter
    return _.filter(this.props.items, 'showSectionHeader');
  },
  hasMoreScroll(node) {
    return node.scrollTop + node.clientHeight < node.scrollHeight;
  },
  onScroll(event) {
    if (
      !this.props.customCategory &&
      // eslint-disable-next-line you-dont-need-lodash-underscore/includes
      (!_.includes(event.target.className, 'inner-container') ||
        this.animationInProcess ||
        !this.shouldShowInnerNavigation())
    ) {
      return;
    }
    const scrollTop = event.target ? event.target.scrollTop : 0;
    const sectionsTop = this.getSectionTops();
    let nextTopSection;
    if (this.lastScroll - scrollTop < 0) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/find-index
      nextTopSection = _.findIndex(sectionsTop, function (sectionTop) {
        return (
          sectionTop !== -1 &&
          sectionTop - scrollTop >= 0 &&
          sectionTop - scrollTop < 145
        );
      });
      if (!this.hasMoreScroll(event.target)) {
        nextTopSection = sectionsTop.length - 1;
      }
    } else {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/find-index
      nextTopSection = _.findIndex(
        this.getSectionsHeight(),
        function (sectionHeight, index) {
          const sectionBottom = sectionsTop[index] + sectionHeight;
          const gapBetweenScrollAndBottom = sectionBottom - scrollTop;
          return (
            sectionBottom !== -1 &&
            gapBetweenScrollAndBottom / sectionHeight >= 0.3 &&
            gapBetweenScrollAndBottom / sectionHeight <= 1
          );
        },
      );
    }
    this.lastScroll = scrollTop;
    const doesSubCategoryHaveSubNavigation = _.invoke(
      this.props,
      'doesSubCategoryHaveSubNavigation',
      nextTopSection,
    );
    if (nextTopSection >= 0) {
      if (
        nextTopSection !== currentSectionIndexView &&
        doesSubCategoryHaveSubNavigation
      ) {
        currentSectionIndexView = nextTopSection;
        _.invoke(this.props, 'subCategoryChangeHandler', nextTopSection);
      } else if (
        nextTopSection < currentSectionIndexView &&
        !doesSubCategoryHaveSubNavigation
      ) {
        currentSectionIndexView = _.invoke(
          this.props,
          'getGroupedSectionIndex',
          nextTopSection,
        );
        _.invoke(
          this.props,
          'subCategoryChangeHandler',
          currentSectionIndexView,
        );
      } else {
        _.invoke(this.props, 'setNewTopTitle', nextTopSection);
      }
    }
    this.setTopSection(nextTopSection);
  } /*, 1000 / 60)*/,
  scrollToSectionId(sectionId) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/find-index
    this.scrollToSection(_.findIndex(this.props.items, { id: sectionId }));
  },
  scrollToSection(index) {
    this.scrollTo(this.getSectionTops()[index]);
  },
  scrollToTop() {
    this.scrollTo(0);
  },
  scrollTo(scrollTo) {
    const scrolledElement = this.refs.customScroll.getScrolledElement();
    if (!scrolledElement) {
      return;
    }
    this.animationInProcess = true;
    this.lastScroll = scrollTo;
    uiAnimations.scrollTo(
      { y: scrollTo },
      0.5,
      0,
      scrolledElement,
      function () {
        this.animationInProcess = false;
      }.bind(this),
    );
  },
  setTopSection(index, scroll) {
    if (index > -1) {
      if (scroll) {
        this.scrollToSection(index);
      } else if (this.refs.innerNavigation) {
        const nextSection = this.getInnerNavigationSection(index);
        const currSection = this.getInnerNavigationSection(this.topSection);
        if (nextSection.id !== currSection.id) {
          tooltipManager.hideAll(function () {
            tooltipManager.showForDuration(nextSection.id, 1500);
          });
        }
      }

      if (index !== this.topSection) {
        this.topSection = index;
        if (this.refs.innerNavigation) {
          this.refs.innerNavigation.setSelectedSection(
            this.getInnerNavigationSection(index),
          );
        }
      }
    }
  },
  getInnerNavigationSection(index) {
    const currSection = this.props.items[index];
    if (!currSection) {
      return _.head(this.props.items);
    }
    return currSection;
  },
  getTopSection() {
    return this.getInnerNavigationSection(this.topSection);
  },
  getSectionByIndex(index) {
    return this.refs[this.getSectionRefByIndex(index)];
  },
  getSectionRefByIndex(index) {
    return `${this.props.id}_section${index}`;
  },
  getSectionHeightByIndex(index) {
    const section = this.getSectionByIndex(index);
    return section && section.getOffsetHeight();
  },
  getSectionTopByIndex(index) {
    const section = this.getSectionByIndex(index);
    return section && section.getOffsetTop();
  },
  notifyMaxScroll() {
    const maxHoveredItem = this.getMaxHoveredItem();
    if (maxHoveredItem.sectionIndex > -1 && maxHoveredItem.itemIndex > -1) {
      this.getEditorAPI().bi.event(
        coreBi.events.addPanel.ADD_MENU_MAX_CATEGORY_SCROLL,
        {
          category: maxHoveredItem.category,
          section: `${maxHoveredItem.sectionIndex}_${maxHoveredItem.sectionTitle}`,
          max_hover_item: maxHoveredItem.itemIndex,
        },
      );
      this.setMaxHoveredItem();
    }
  },
  updateMaxHoveredItem(itemInSection) {
    const maxHoveredItem = this.getMaxHoveredItem();
    if (this.props.id === maxHoveredItem.category) {
      if (itemInSection.sectionIndex < maxHoveredItem.sectionIndex) {
        return;
      } else if (
        itemInSection.sectionIndex === maxHoveredItem.sectionIndex &&
        itemInSection.itemIndex <= maxHoveredItem.itemIndex
      ) {
        return;
      }
    }

    this.setMaxHoveredItem(
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      _.assign(itemInSection, { category: this.props.id }),
    );
  },
  setMaxHoveredItem(item) {
    this.maxHoveredItem = item;
  },
  getMaxHoveredItem() {
    return this.maxHoveredItem || {};
  },
  render() {
    return (
      <div className="category-view">
        <CustomScroll
          ref="customScroll"
          onScroll={(ev) => {
            this.onScroll(ev);
          }}
          heightRelativeToParent="100%"
          addScrolledClass={true}
          freezePosition={this.props.isDragging}
        >
          <div ref="sections" className="sections">
            {/* TODO: Fix this the next time the file is edited. */}
            {/* eslint-disable-next-line you-dont-need-lodash-underscore/map */}
            {_.map(this.props.items, (section, sectionIndex) => (
              <BaseGenerator
                ref={this.getSectionRefByIndex(sectionIndex)}
                key={this.getSectionRefByIndex(sectionIndex)}
                {...this.getSectionItemProps(section, sectionIndex)}
              />
            ))}
          </div>
        </CustomScroll>
      </div>
    );
  },
});

export const CategoryView = withTrackFirstPresetSectionLoad(
  CategoryViewComponent,
);
