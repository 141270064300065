import * as stateManagement from '@/stateManagement';
import _ from 'lodash';
import * as util from '@/util';
import * as coreBi from '@/coreBi';
import type { CompRef } from 'types/documentServices';
import type { InteractionDef } from '@/stateManagement';
import { getOverriddenInteractionsState } from '../../overrideDefinitions/interactionsOverrideDefinitions';

const DELETE_INTERACTION_PANEL_NAME =
  'panels.messagePanels.deleteInteractionMessage';

const { getVariantId, getEditorAvailableInteractions } =
  stateManagement.interactions.selectors;

const exitInteraction = (dispatch: AnyFixMe) => {
  dispatch(stateManagement.interactions.actions.exitInteraction());
  dispatch(
    stateManagement.panels.actions.openComponentPanel(
      'interactions.panels.interactionsPanel',
    ),
  );
};

const getTriggerRef = (editorAPI: AnyFixMe) => {
  const selectedComponent = editorAPI.selection.getSelectedComponents()[0];
  return editorAPI.columns.isStrip(selectedComponent)
    ? editorAPI.columns.getColumnIfStripIsSingleColumn(selectedComponent)
    : selectedComponent;
};

const getInteractionsData = (
  editorAPI: AnyFixMe,
  triggerRef: CompRef,
  availableInteractions: InteractionDef[],
) =>
  availableInteractions
    .filter(
      (interaction) =>
        !_.isEmpty(
          editorAPI.documentServices.components.variants.get(
            triggerRef,
            interaction.name,
          ),
        ),
    )
    .map((interaction) => ({ ...interaction }));

const getInteractions = (
  editorAPI: AnyFixMe,
  availableInteractions: InteractionDef[],
) => {
  const triggerRef = getTriggerRef(editorAPI);
  const interactionsData = getInteractionsData(
    editorAPI,
    triggerRef,
    availableInteractions,
  );
  const hasInteractions = !_.isEmpty(interactionsData);

  if (hasInteractions) {
    return interactionsData.map((interactionData) => ({
      label: interactionData.label,
      name: interactionData.name,
    }));
  }
  return [];
};

const getEditorAPI = (
  dispatch: AnyFixMe,
  getState: AnyFixMe,
  { editorAPI }: AnyFixMe,
) => editorAPI;

export default {
  mapStateToProps: ({ editorAPI }: AnyFixMe) => {
    const availableInteractions = getEditorAvailableInteractions(editorAPI);
    const interactions = getInteractions(editorAPI, availableInteractions);

    return {
      interactions,
      availableInteractions,
      selectedComponent: getTriggerRef(editorAPI),
      renderEditAsSymbol: util.languages.getLanguageCode() !== 'en',
    };
  },
  mapDispatchToProps: (dispatch: AnyFixMe) => {
    const editorAPI = dispatch(getEditorAPI);
    const triggerRef = getTriggerRef(editorAPI);

    function enterInteraction(compRef: AnyFixMe, interactionName: AnyFixMe) {
      util.fedopsLogger.interactionStarted(
        util.fedopsLogger.INTERACTIONS.INTERACTIONS_FEATURE.ENTER_INTERACTIONS,
      );
      const interactionState = getOverriddenInteractionsState(
        editorAPI,
        triggerRef,
      );
      dispatch(
        stateManagement.interactions.actions.enterInteraction(
          interactionName,
          compRef,
          interactionState,
        ),
      );
      const compType = editorAPI.components.getType(compRef);
      const variantId = getVariantId(editorAPI.store.getState());
      editorAPI.bi.event(coreBi.events.interactions.interaction_mode_started, {
        component_id: compRef.id,
        component_type: compType,
        interaction_id: variantId,
      });
    }
    return {
      enterInteraction,
      addInteraction: (compRef: AnyFixMe, interactionName: AnyFixMe) => {
        const editorAPI = dispatch(getEditorAPI);
        util.fedopsLogger.interactionStarted(
          util.fedopsLogger.INTERACTIONS.INTERACTIONS_FEATURE
            .ENTER_INTERACTIONS,
        );
        dispatch(
          stateManagement.interactions.actions.createInteraction(
            interactionName,
            compRef,
          ),
        );
        editorAPI.waitForChangesApplied(() => {
          enterInteraction(compRef, interactionName);
        });
      },
      removeInteraction: (_compRef: AnyFixMe, interactionName: AnyFixMe) => {
        const editorAPI = dispatch(getEditorAPI);
        const panelProps = {
          onConfirm: () => {
            stateManagement.interactions.actions.removeInteraction(
              editorAPI,
              _compRef,
              interactionName,
              true,
            );
          },
        };

        editorAPI.panelManager.openPanel(
          DELETE_INTERACTION_PANEL_NAME,
          panelProps,
          true,
        );
      },
      exitInteraction: () => {
        exitInteraction(dispatch);
      },
      openHelpCenter: () => {},
      onPanelClose: () => {
        dispatch(stateManagement.panels.actions.closeAllPanels());
      },
    };
  },
};
