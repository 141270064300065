import wixAppsDeprecationPanel from './wixAppsDeprecationPanel/wixAppsDeprecationPanel';
import wixappsLayoutPanel from './wixappsLayoutPanel';
import singlePostPageOnlyCompsTooltip from './singlePostPageOnlyCompsTooltip/singlePostPageOnlyCompsTooltip';

export { utils } from './wixAppsDeprecationPanel/appDataExport';

export const AppPart = {
  settingsPanel: wixAppsDeprecationPanel,
  layoutPanel: wixappsLayoutPanel,
  singlePostPageOnlyCompsTooltip,
};
