import * as stateManagement from '@/stateManagement';
import constants from '@/constants';
import { getCanOptimisticDrag } from './DragMoveToPlugin';
import type { BaseDragPlugin } from './common';
import type { EditorAPI } from '@/editorAPI';

const MENU_CONTAINER_TYPE = 'wysiwyg.viewer.components.MenuContainer';

type Direction = 'top' | 'bottom';

const SETTINGS = {
  // pixels
  margin: 50,
  // pixels per ms
  maxSpeed: 2,
  prec: 0.05,
};

const toPrec = (v: number, prec: number) => {
  prec = 1 / prec;
  return Math.ceil(v * prec) / prec;
};

class Scroller {
  lastRaf: number | null = null;
  direction: Direction;

  was: number;
  targetSpeed: number;
  lastSpeed: number;

  constructor(private doScroll: (data: { distance: number }) => boolean) {}
  tick = () => {
    this.lastRaf = null;
    const now = Date.now();
    let speed = this.lastSpeed;
    if (speed !== this.targetSpeed) {
      if (speed > this.targetSpeed) {
        speed -= 0.05;
        speed = Math.max(speed, this.targetSpeed);
      } else {
        speed += 0.05;
        speed = Math.min(speed, this.targetSpeed);
      }
    }
    this.lastSpeed = speed;
    let tickDistance = Math.floor((now - this.was) * speed);

    if (tickDistance > 0) {
      this.was = now;
      tickDistance =
        this.direction === 'top' ? -1 * tickDistance : tickDistance;

      const shouldStop = this.doScroll({ distance: tickDistance });
      if (shouldStop) {
        return;
      }
    }
    this.lastRaf = requestAnimationFrame(this.tick);
  };
  startLoop() {
    if (this.lastRaf) {
      return;
    }
    this.was = Date.now();
    this.lastRaf = requestAnimationFrame(this.tick);
  }
  stop() {
    if (this.lastRaf) {
      cancelAnimationFrame(this.lastRaf);
      this.lastRaf = null;
    }
  }
  scroll({ speed, direction }: { speed: number; direction: Direction }) {
    this.targetSpeed = speed;
    if (this.lastSpeed === undefined) {
      this.lastSpeed = 0;
    }
    this.direction = direction;
    this.startLoop();
  }
}

function subscribeMouseOut(_cb: (type: 'top' | 'bottom') => void) {
  const cb = (e: MouseEvent) => {
    if (e.clientY <= 0) {
      _cb('top');
    } else if (e.clientY >= window.innerHeight) {
      _cb('bottom');
    }
  };

  document.body.addEventListener('mouseleave', cb);

  return () => {
    document.body.removeEventListener('mouseleave', cb);
  };
}

function isMobileMenuPage(editorAPI: EditorAPI) {
  if (!editorAPI.isMobileEditor()) {
    return false;
  }
  const ref = stateManagement.inlinePopup.selectors.getOpenedInlinePopup(
    editorAPI.store.getState(),
  );
  if (!ref) {
    return false;
  }
  return editorAPI.components.getType(ref) === MENU_CONTAINER_TYPE;
}

export const DragAndScrollPlugin: BaseDragPlugin = {
  init: ({ hooks, pluginFactoryScope }) => {
    const { editorAPI, selectedComp } = pluginFactoryScope;
    if (!getCanOptimisticDrag(editorAPI, selectedComp)) {
      return;
    }
    if (isMobileMenuPage(editorAPI)) {
      return;
    }

    if (process.env.NODE_ENV === 'test') {
      // https://github.com/wix-private/santa-editor/pull/52269
      // plugin is not ready for tests after removing the 'tb' conditions
      return;
    }

    const scrollNode = document.getElementById(
      constants.ROOT_COMPS.SELECTOR_ID.SCROLLABLE_EDITOR_STAGE,
    );
    const maxScroll = scrollNode.scrollHeight - scrollNode.clientHeight;

    const rect = scrollNode.getBoundingClientRect();

    const screenHeight = rect.height;

    const scroller = new Scroller(({ distance }) => {
      const { scrollLeft, scrollTop: originalScrollTop } =
        editorAPI.scroll.get();

      const scrollTop = originalScrollTop + distance;
      editorAPI.scroll._fastScrollTo({
        scrollTop,
        scrollLeft,
      });
      editorAPI.notifyViewerUpdate(['scroll']);

      if (distance > 0 && scrollNode.scrollTop >= maxScroll) {
        return true;
      }
      if (distance < 0 && scrollNode.scrollTop <= 0) {
        return true;
      }
      return false;
    });

    const handleEvent = (mousePos: number) => {
      if (mousePos < 0) {
        mousePos = 0;
      }
      if (mousePos > screenHeight) {
        mousePos = screenHeight;
      }
      let direction: Direction;
      let overlapFactor;

      const bottomOverlap = mousePos - (screenHeight - SETTINGS.margin);
      if (mousePos < SETTINGS.margin) {
        direction = 'top';
        overlapFactor = (SETTINGS.margin - mousePos) / SETTINGS.margin;
      } else if (bottomOverlap > 0) {
        direction = 'bottom';
        overlapFactor = bottomOverlap / SETTINGS.margin;
      } else {
        scroller.stop();
        return;
      }
      overlapFactor = Math.pow(overlapFactor, 1.5);
      overlapFactor = toPrec(overlapFactor, SETTINGS.prec);
      const speed = overlapFactor * SETTINGS.maxSpeed;

      scroller.scroll({ speed, direction });
    };

    hooks.onDragStart.tap(() => {
      const unsubscribe = subscribeMouseOut((type) => {
        let mousePos;
        if (type === 'top') {
          mousePos = 0;
        } else {
          mousePos = screenHeight;
        }
        handleEvent(mousePos);
      });
      hooks.endDrag.tap(() => {
        unsubscribe();
        scroller.stop();
      });
    });

    hooks.beforeLayoutUpdate.tap((d) => {
      let mousePos = d.mouseCoordinates.originalClientY;
      mousePos -= rect.top; //todo fitToViewer?? scale??
      handleEvent(mousePos);
    });
  },
};
