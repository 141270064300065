import constants from '@/constants';
import { events } from '@/coreBi';
import { utils as coreUtils } from '@/core';
import experiment from 'experiment';
import { fedopsLogger, sections as sectionsUtils } from '@/util';

import {
  cleanupEditorData,
  debugReporter,
  errorReporter,
  getADIMigrationTrigger,
  isTimeLimitError,
  isPartialMigrationError,
  isMigrationCancelled,
  isSaveError,
  shouldUpdateMigrationVersionAfterFailure,
  isForceSectionsMigration,
  renameRevision,
  removeForceSectionsMigrationQuery,
  hasPagesWithSectionsInRoot,
} from '../utils';
import { MigrationFlow } from '../types';
import { MIGRATION_VERSION } from '../version';
import {
  BEFORE_SECTIONS_MIGRATION_HISTORY_LABEL,
  DEBUG,
  ADIMigrationTrigger,
  MAX_PAGES_TO_MIGRATE,
} from '../constants';
import { saveAfterMigration, saveInBackground } from './saveMigration';
import { reportMigrationNotPossible } from './reportMigrationNotPossible';
import { restoreToADI } from './restoreToADI';
import { restoreSession } from './restoreSession';
import { startTourOrShowNotification } from './startTourOrShowNotification';
import { revertMigrationTransaction } from './revertMigrationTransaction';
import { showErrorNotification } from './showNotification';

import type { CompRef } from 'types/documentServices';
import type { SectionsMigrationScope as Scope } from '../scope';
import type { MigrationOrigin } from '../constants';

export async function afterSuccessMigration(
  scope: Scope,
  {
    flow,
    origin,
    startTime,
    pagesRefs,
  }: {
    flow: MigrationFlow;
    origin: MigrationOrigin;
    startTime: number;
    pagesRefs: CompRef[];
  },
) {
  const shouldRenameRevision = isForceSectionsMigration();

  if (shouldRenameRevision) {
    renameRevision(
      scope,
      'FTE_Tour_partners_update_site_history_pre_update_label',
    );
  }

  const shouldRunAsyncSave =
    flow === MigrationFlow.HeavySite && pagesRefs.length > MAX_PAGES_TO_MIGRATE;

  if (shouldRunAsyncSave) {
    sectionsUtils.updateSiteSectionsEditorData(scope.editorAPI, {
      isSectionsEnabled: true,
      isMigratedFromAdi: false,
      migrationVersion: MIGRATION_VERSION,
    });

    await saveAfterMigration(scope);
  }

  const duration = Math.round(performance.now() - startTime);
  debugReporter(scope, DEBUG.LOG, 'Migration time:', duration);

  scope.editorAPI.bi.event(events.sectionsMigration.MIGRATION_SUCCESS, {
    flow,
    origin,
    duration,
    is_document_changed: true,
    pages_list: pagesRefs.map(({ id }) => id).join(','),
    template_id:
      scope.editorAPI.documentServices.generalInfo.getSiteOriginalTemplateId(),
  });

  if (flow !== MigrationFlow.Editor2Anchors) {
    sectionsUtils.updateSiteSectionsEditorData(scope.editorAPI, {
      isSectionsEnabled: true,
      isMigratedFromAdi: flow === MigrationFlow.ADI,
      migrationVersion: MIGRATION_VERSION,
    });
  }

  scope.editorAPI.history.amend();

  fedopsLogger.interactionEnded(
    fedopsLogger.INTERACTIONS.SECTIONS_MIGRATION.BULK_MIGRATION,
  );

  startTourOrShowNotification(scope, flow);

  finishMigration(scope, {
    flow,
    shouldRenameRevision,
  });
}

export async function afterFailedMigration(
  scope: Scope,
  {
    error,
    flow,
    origin,
    pagesRefs,
    initialHistoryLabel,
  }: {
    error: any;
    flow: MigrationFlow;
    origin: MigrationOrigin;
    pagesRefs: CompRef[];
    initialHistoryLabel: string;
  },
) {
  const { documentServices } = scope.editorAPI;

  const { undoLabelBeforeRevert, undoLabelAfterRevert } =
    await revertMigrationTransaction(scope);

  if (
    shouldUpdateMigrationVersionAfterFailure(scope, {
      error,
      flow,
    })
  ) {
    sectionsUtils.updateSiteSectionsEditorData(scope.editorAPI, {
      isSectionsEnabled: false,
      migrationVersion: MIGRATION_VERSION,
    });
  }

  scope.editorAPI.bi.event(events.sectionsMigration.MIGRATION_ERROR, {
    errorMessage: `Migration error ${error.message}`,
    flow,
    origin,
    template_id:
      scope.editorAPI.documentServices.generalInfo.getSiteOriginalTemplateId(),
  });

  errorReporter(error);

  reportMigrationNotPossible(scope, {
    flow,
    isTimeLimitation: isTimeLimitError(error),
    hasPartialMigrationIssue: isPartialMigrationError(error),
    isCancelledByUser: isMigrationCancelled(error),
    isSaveIssue: isSaveError(error),
    hasMigrationIssue: true,
  });

  const hasSectionsAfterRevert = hasPagesWithSectionsInRoot(
    documentServices,
    pagesRefs,
  );

  const hasRevertIssue =
    undoLabelBeforeRevert !== BEFORE_SECTIONS_MIGRATION_HISTORY_LABEL ||
    undoLabelAfterRevert !== initialHistoryLabel ||
    (flow !== MigrationFlow.Editor2Fix &&
      flow !== MigrationFlow.Editor2Anchors &&
      hasSectionsAfterRevert);

  if (hasRevertIssue) {
    scope.editorAPI.bi.event(
      events.sectionsMigration.MIGRATION_FALLBACK_ERROR,
      {
        revision: documentServices.generalInfo.getRevision(),
        flow,
        origin,
        undo_label: undoLabelBeforeRevert,
        sections_number: documentServices.components.get.byType(
          constants.COMP_TYPES.SECTION,
        ).length,
      },
    );
  }

  if (
    flow === MigrationFlow.ADI &&
    (getADIMigrationTrigger() === ADIMigrationTrigger.OnLoad ||
      getADIMigrationTrigger() === ADIMigrationTrigger.Reminder)
  ) {
    await restoreToADI(scope, {
      isCanceledByUser: isMigrationCancelled(error),
    });
  }

  if (
    ((flow === MigrationFlow.Editor || flow === MigrationFlow.HeavySite) &&
      hasRevertIssue) ||
    isSaveError(error)
  ) {
    restoreSession(isPartialMigrationError(error));
  }

  showErrorNotification(scope, { flow, error });

  finishMigration(scope, { flow });
}

function finishMigration(
  scope: Scope,
  {
    flow,
    shouldRenameRevision,
  }: {
    flow: MigrationFlow;
    shouldRenameRevision?: boolean;
  },
) {
  const { editorAPI, migrationStatus } = scope;
  const { autosaveManager, documentServices, savePublish } = editorAPI;

  if (isForceSectionsMigration()) {
    removeForceSectionsMigrationQuery();
  }

  autosaveManager.init({ enabled: true }, true);
  savePublish.unlockSavePublish();

  const shouldRunBackgroundSave = flow !== MigrationFlow.Template;

  if (shouldRunBackgroundSave) {
    saveInBackground(scope, () => {
      if (shouldRenameRevision) {
        renameRevision(
          scope,
          'FTE_Tour_partners_update_site_history_post_update_label',
        );
      }
    });
  }

  documentServices.documentMode.setShouldKeepChildrenInPlace(true);

  if (flow === MigrationFlow.Template) {
    cleanupEditorData(documentServices);
    if (experiment.isOpen('se_siteCompletionSourceData')) {
      coreUtils.componentSourceFeatureUtils.updateAllChildrenSourceData(
        editorAPI,
        { source: 'template', changedOverride: false },
      );
    }
  }

  migrationStatus.setMigrationFinished();
}
