import _ from 'lodash';

import constants from '@/constants';
import * as coreBi from '@/coreBi';
import * as stateManagement from '@/stateManagement';
import * as util from '@/util';
import { languageSelectorButton } from '@/newAddPanelAPI';
import {
  EditorRestrictionsApiKey,
  WixCodeWorkspaceApiKey,
  WorkspaceApiKey,
  FixedStageApiKey,
} from '@/apis';

import type { EditorAPI } from '@/editorAPI';
import type {
  Dispatch,
  MapDispatchToProps,
  MapStateToProps,
  ThunkAction,
} from 'types/redux';
import type {
  TopBarDispatchProps,
  TopBarOwnProps,
  TopBarStateProps,
} from './topBar';

interface ShowLanguageSelectProps {
  translationLanguages: any[];
  isMultilingualSite: boolean;
  inInteractionMode: boolean;
}

const shouldShowLanguageSelect = (props: ShowLanguageSelectProps) => {
  const { translationLanguages, isMultilingualSite, inInteractionMode } = props;

  return (
    isMultilingualSite && translationLanguages?.length > 0 && !inInteractionMode
  );
};

interface CountryCodeProps {
  currentLanguageCode: string;
  originalLanguage: any;
  translationLanguages: any[];
}

const getCountryCode = (props: CountryCodeProps) => {
  const { currentLanguageCode, originalLanguage, translationLanguages } = props;

  const locales = []
    .concat(originalLanguage, translationLanguages)
    .filter(_.identity)
    .reduce((acc, val) => ({ ...acc, [val.languageCode]: val }), {});

  return locales[currentLanguageCode]
    ? locales[currentLanguageCode].countryCode
    : undefined;
};

const mapStateToProps: MapStateToProps<TopBarStateProps, TopBarOwnProps> = ({
  editorAPI,
  state,
  dsRead,
}) => {
  const {
    publishingStatus,
    testSiteStatus,
    previewingStatus,
    shouldHideToolsBtnBlink,
    hideTools: isHidden,
  } = state;
  const isSectionsExperience = util.sections.isSectionsEnabled();
  const showTopBarBanner =
    stateManagement.topBar.selectors.getBannerVisibility(state);
  const isZoomMode = editorAPI.zoomMode.isInZoomMode();
  const isMinimized = isSectionsExperience ? false : isZoomMode;
  const editorRestrictionsApi = editorAPI.host.getAPI(EditorRestrictionsApiKey);
  const fixedStageAPI = editorAPI.host.getAPI(FixedStageApiKey);

  const props: TopBarStateProps = {
    publishingStatus,
    testSiteStatus,
    previewingStatus,
    shouldHideToolsBtnBlink,
    isHidden,
    currentPageId: dsRead.pages ? dsRead.pages.getFocusedPageId() || '' : '',
    areToolsHiddenAndStagePositionMaintained:
      editorAPI.toolsControl.areToolsHiddenAndStagePositionMaintained() ||
      editorAPI.mobile.mobileWizard.isEnabled(),
    isMobileEditor: editorAPI.isMobileEditor(),
    isPreviewMode: editorAPI.preview.isInPreviewMode(),
    isZoomMode,
    isMinimized,
    shouldDisplayTestSiteButton: editorAPI.host
      .getAPI(EditorRestrictionsApiKey)
      .allowed('top-bar_test_site.visible'),
    shouldDisplayPublishButton: editorAPI.host
      .getAPI(EditorRestrictionsApiKey)
      .allowed('top-bar_publish.visible'),
    shouldDisplaySaveButton: !editorAPI.host
      .getAPI(WixCodeWorkspaceApiKey)
      .getIsInLocalDevMode(),
    contributedLogo: editorAPI.host
      .getAPI(WorkspaceApiKey)
      .getTopBarLogoComponent(
        editorAPI.preview.isInPreviewMode() ? 'preview' : 'main',
      )?.contribution,
    contributedRightSideComponents: editorAPI.host
      .getAPI(WorkspaceApiKey)
      .getTopBarRightSideComponents(
        editorAPI.preview.isInPreviewMode() ? 'preview' : 'main',
      ),
    isSchoolMode: stateManagement.schoolMode.selectors.isEnabled(
      editorAPI.store.getState(),
    ),
    isPublishInProgress: editorAPI.savePublish.isPublishInProgress(),
    isTestSiteInProgress: editorAPI.savePublish.isTestSiteInProgress(),
    isBuildInProgress:
      stateManagement.savePublish.selectors.getIsBuildInProgress(state),
    isSaveInProgress: editorAPI.savePublish.isSaveInProgress(),
    isSitePublished:
      _.invoke(editorAPI, 'dsRead.generalInfo.isSitePublished') || false,
    canUserPublish: editorAPI.savePublish.canUserPublish(),
    isDeveloperModeEnabled: Boolean(editorAPI.developerMode.isEnabled()),
    overriddenQuickNavigation:
      stateManagement.topBar.selectors.getOverriddenQuickNavigation(state),
    openedDropPanel: editorAPI.topBarMenuBar.getOpenedDropDown(),
    forceOpenPagesQuickNavigationEventCounter:
      editorAPI.getForceOpenPagesQuickNavigationEventCounter(),
    showTopBarBanner: showTopBarBanner && !isHidden,
    isEditorSearchEnabled:
      stateManagement.editorSearch.selectors.isEnabled(state),
    topBarBannerTitle: stateManagement.topBar.selectors.getBannerTitle(state),
    branchId: editorAPI.dsRead?.generalInfo?.getBranchId(),
    isLightboxMode:
      _.isFunction(editorAPI.pages.getFocusedPageId) &&
      editorAPI.pages.popupPages.isPopup(editorAPI.pages.getFocusedPageId()),
    isPublishRcVisible: editorRestrictionsApi.allowed(
      'top-bar_publish-rc.visible',
    ),
    isChangeWorkspaceModesVisible: editorRestrictionsApi.allowed(
      'top-bar_workspace-modes-toggle.visible',
    ),
    shouldHideLanguageIcon: fixedStageAPI.isTopBarMinimized(),
    topBarStateBIParamValue:
      util.fixedStage.getTopBarStateBiParamValue(editorAPI),
  };

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/is-nil
  if (!_.isNil(editorAPI.dsRead)) {
    const { language } = editorAPI.dsRead;
    props.currentLanguageCode = language.current.get();
    const currentLanguage = language
      .get()
      .find((language) => language.languageCode === props.currentLanguageCode);
    props.shouldCurrentLanguageInvisible =
      language.isCurrentLanguageSecondary() &&
      util.multilingual.languages.shouldLanguageBeInvisible(
        props.isPreviewMode,
        currentLanguage,
      );

    props.showLanguageSelect = shouldShowLanguageSelect({
      translationLanguages: language.get(),
      isMultilingualSite: language.multilingual.isEnabled(),
      inInteractionMode:
        stateManagement.interactions.selectors.isInInteractionMode(state),
    });
    props.currentCountryCode = getCountryCode({
      currentLanguageCode: language.current.get(),
      originalLanguage: language.original.get(),
      translationLanguages: language.get(),
    });
  } else {
    props.currentLanguageCode = null;
    props.shouldCurrentLanguageInvisible = false;
  }

  props.tpaMlState = stateManagement.appsStore.selectors.getAppStore(
    state,
    constants.MULTILINGUAL.linguistDefId,
  );

  return props;
};

const dispatchEditorAPI =
  <TArgs extends any[]>(
    dispatch: Dispatch<ThunkAction>,
    f: (editorAPI: EditorAPI, ...args: TArgs) => any,
  ) =>
  (...args: TArgs) =>
    dispatch((_dispatch, getState, { editorAPI }) => f(editorAPI, ...args));

const mapDispatchToProps: MapDispatchToProps<
  TopBarDispatchProps,
  TopBarOwnProps
> = (dispatch) => ({
  onMultilingualUpdated: () => {
    // Its here because of https://github.com/wix-private/santa-editor/pull/22652
    // I think it should not be here but it turned out to be rather difficult to refactor
    // TODO: Should be done by appBuilder
    dispatch(
      stateManagement.multilingual.actions.dataUpdated(languageSelectorButton),
    );
  },
  onSyncFlagButton: () =>
    dispatch(
      stateManagement.multilingual.actions.syncIsTranslatingStateThunk(),
    ),
  closeAllPanels: dispatchEditorAPI(dispatch, (editorAPI) =>
    editorAPI.panelManager.closeAllPanels(),
  ),
  toggleHideTools: dispatchEditorAPI(dispatch, (editorAPI) => {
    const areToolsHidden = editorAPI.toolsControl.toggleHideTools();

    dispatch(
      stateManagement.userPreferences.actions.setSessionUserPreferences(
        'hideTools',
        areToolsHidden,
      ),
    );

    const eventName = areToolsHidden
      ? coreBi.events.editor.hide_tools.hide_controls
      : coreBi.events.editor.hide_tools.show_controls;

    dispatch(
      stateManagement.bi.actions.event(eventName, {
        origin: 'handle',
      }),
    );
  }),
  exitEditor: dispatchEditorAPI(dispatch, (editorAPI, exitUrl: string) =>
    editorAPI.exitEditor(exitUrl),
  ),
  openDropPanel: dispatchEditorAPI(
    dispatch,
    (
      editorAPI,
      dropPanelName: string,
      dropPanelActiveItem?: string,
      shouldRemainOpen?: boolean,
    ) =>
      editorAPI.topBarMenuBar.openDropDown(
        dropPanelName,
        dropPanelActiveItem,
        shouldRemainOpen,
      ),
  ),
  closeDropPanel: dispatchEditorAPI(
    dispatch,
    (editorAPI, dropPanelName?: string) =>
      editorAPI.topBarMenuBar.closeDropDown(dropPanelName),
  ),
  deselectComponents: dispatchEditorAPI(dispatch, (editorAPI) =>
    editorAPI.selection.deselectComponents(),
  ),
});

export { mapStateToProps, mapDispatchToProps };
