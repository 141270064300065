module.exports = {
  id: "Stylable_StylableButton_10",
  preset: { rect: { width: 64, height: 78, x: 66, y: 296 }, tags: null },
  structure: {
    type: "Component",
    metaData: { sig: "inq-386", pageId: "c1dmp" },
    skin: "wixui.skins.Skinless",
    layout: {
      width: 47,
      height: 47,
      x: 75,
      y: 311,
      scale: 1,
      rotationInDegrees: 0,
      fixedPosition: false,
    },
    componentType: "wixui.StylableButton",
    parent: "comp-knzvqsjj",
    data: {
      type: "StylableButton",
      metaData: {
        isPreset: false,
        schemaVersion: "1.0",
        isHidden: false,
        sig: "q4x-259",
        pageId: "c1dmp",
      },
      label: "Home",
      svgId: "11062b_899259d6388341529acd02e07f127f74.svg",
    },
    props: {
      type: "StylableButtonProperties",
      metaData: { schemaVersion: "1.0", sig: "b80-492", pageId: "c1dmp" },
    },
    style: {
      type: "ComponentStyle",
      metaData: {
        isPreset: false,
        schemaVersion: "1.0",
        isHidden: false,
        sig: "4ie-572",
        pageId: "c1dmp",
      },
      style: {
        properties: {
          "$st-css":
            ":import{-st-from: 'wix-ui-santa/index.st.css';-st-named: StylableButton}.root{-st-extends: StylableButton;transition: all 0.2s ease, visibility 0s;border-radius: 200px;background: #689ADE;border: 0px solid rgb(104, 154, 222)}.root[disabled]{background: #E2E2E2}.root[disabled]::label{color: #8F8F8F}.root[disabled]::icon{fill: #8F8F8F}.root::container{transition: inherit}.root::label{transition: inherit;font-size: 16px;color: #000000;display: none}.root::icon{transition: inherit;width: 27px;height: 27px;fill: #0B2F5B}.root:hover::icon {\n    width: 25px;\n    height: 25px;\n}",
        },
        propertiesSource: { "$st-css": "value" },
      },
      componentClassName: "wixui.StylableButton",
      pageId: "",
      compId: "",
      styleType: "custom",
      skin: "wixui.skins.Skinless",
    },
    activeModes: {},
    id: "comp-km924nuz",
    components: [],
  },
};
