'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')
const util = require('@/util')
module.exports = {
    "type": addPanelDataConsts.SECTIONS_TYPES.PRESET,
    "hide": false,
    "title": "add_section_label_Dropdown",
    "subNavigationTitle": "add_section_label_Dropdown",
    "topTitle": "add_section_title_dropdown",
    "presetTitle": "add_section_heading_dropdown",
    "tooltipTitle": "add_section_label_Dropdown_tooltip_title",
    "subNavigationHide": false,
    "automationId": "add-panel-section-selectionSection",
    "showSectionHeader": true,
    "additionalBehaviours": {
        "labelMode": addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
        "infoIcon": addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
        "hoverImageAction": addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
        "iconEnabledForComps": {}
    },
    "props": {
        "image": `addPanelData/sections/dropDownSection_${util.languages.getLanguageCode()}/dropDownSection_${util.languages.getLanguageCode()}.v3.png`,
        "imageHover": null,
        "items": [{
            "id": "User_Input_DropDown_1",
            "structure": {
                "type": "Component",
                "skin": "ComboBoxInputSkin",
                "layout": {
                    "width": 304,
                    "height": 46,
                    "x": 9,
                    "y": 20,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.inputs.ComboBoxInput",
                "data": {
                    "type": "SelectableList",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "placeholder": {"value": "What's the best flavor?", "text": "What's the best flavor?"},
                    "value": "",
                    "options": [{
                        "type": "SelectOption",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "value": "Vanilla",
                        "text": "Vanilla",
                        "description": "Vanilla",
                        "disabled": false
                    }, {
                        "type": "SelectOption",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "value": "Chocolate",
                        "text": "Chocolate",
                        "description": "Chocolate",
                        "disabled": false
                    }, {
                        "type": "SelectOption",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "value": "Strawberry",
                        "text": "Strawberry",
                        "description": "Strawberry",
                        "disabled": false
                    }, {
                        "type": "SelectOption",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "value": "Rocky Road",
                        "text": "Rocky Road",
                        "description": "Rocky Road",
                        "disabled": false
                    }]
                },
                "props": {
                    "type": "ComboBoxInputProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "required": true,
                    "textAlignment": "left",
                    "textPadding": 18,
                    "placeholder": {"value": "What's the best flavor?", "text": "What's the best flavor?"}
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "properties": {
                            "alpha-bgd": "0",
                            "arrowColor": "#000000",
                            "bg": "rgba(255, 255, 255, 1)",
                            "bg2": "#aaaaaa",
                            "bgd": "#D4D4D4",
                            "bgh": "#F2FAF9",
                            "boxShadowToggleOn-shd": "false",
                            "brd": "#919191",
                            "brde": "#FF4040",
                            "brdf": "#000000",
                            "brdh": "#525252",
                            "brw": "2px",
                            "fnt": "normal normal normal 14px/1.4em avenir-lt-w01_35-light1475496",
                            "fntlbl": "normal normal 700 14px/1.4em avenir-lt-w01_35-light1475496",
                            "rd": "0px",
                            "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                            "txt": "#000000",
                            "txt2": "#5C5C5C",
                            "txt_placeholder": "#000000",
                            "txtlbl": "#61615F",
                            "txtlblrq": "#61615F",
                            "txtd": "#D4D4D4",
                            "dropdownListBackgroundColor": "rgba(255, 255, 255, 1)",
                            "boxShadowToggleOn-dropdownListBoxShadow": "false",
                            "dropdownListBoxShadow": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                            "dropdownListStrokeColor": "#919191",
                            "dropdownListStrokeWidth": "2px",
                            "dropdownListFont": "normal normal normal 14px/1.4em avenir-lt-w01_35-light1475496",
                            "dropdownListTextColor": "#000000",
                            "dropdownListHoverBackgroundColor": "#F2FAF9",
                            "dropdownListBorderRadius": "0px",
                            "dropdownListHoverTextColor": "#000000"
                        },
                        "propertiesSource": {
                            "bgh": "theme",
                            "shd": "value",
                            "rd": "value",
                            "txtd": "value",
                            "fntlbl": "value",
                            "alpha-bg": "value",
                            "bg": "theme",
                            "txt2": "value",
                            "txtlbl": "value",
                            "txt": "value",
                            "txtlblrq": "value",
                            "brwh": "value",
                            "brw": "value",
                            "txt_placeholder": "value",
                            "fnt": "value",
                            "brd": "value",
                            "brdf": "value",
                            "boxShadowToggleOn-shd": "value",
                            "brdd": "value",
                            "brwe": "value",
                            "bgd": "value",
                            "brdh": "value",
                            "bg2": "value",
                            "brde": "value",
                            "brwf": "value",
                            "arrowColor": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.inputs.ComboBoxInput",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "ComboBoxInputSkin"
                },
                "activeModes": {},
                "id": "comp-iv6pkdve"
            },
            "preset": {"rect": {"width": 324, "height": 79, "x": 0, "y": 0}}
        }, {
            "id": "User_Input_DropDown_2",
            "structure": {
                "type": "Component",
                "skin": "ComboBoxInputSkin",
                "layout": {
                    "width": 304,
                    "height": 37,
                    "x": 9,
                    "y": 96,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.inputs.ComboBoxInput",
                "data": {
                    "type": "SelectableList",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "placeholder": {"value": "Pick a size", "text": "Pick a size"},
                    "value": "",
                    "options": [{
                        "type": "SelectOption",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "value": "Small",
                        "text": "Small",
                        "description": "Small",
                        "disabled": false
                    }, {
                        "type": "SelectOption",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "value": "Medium",
                        "text": "Medium",
                        "description": "Medium",
                        "disabled": false
                    }, {
                        "type": "SelectOption",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "value": "Large",
                        "text": "Large",
                        "description": "Large",
                        "disabled": false
                    }, {
                        "type": "SelectOption",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "value": "X Large",
                        "text": "X Large",
                        "description": "X Large",
                        "disabled": false
                    }]
                },
                "props": {
                    "type": "ComboBoxInputProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "required": false,
                    "textAlignment": "left",
                    "textPadding": 0,
                    "placeholder": {"value": "Pick a size", "text": "Pick a size"}
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "properties": {
                            "alpha-bg": "0",
                            "alpha-bgd": "0",
                            "alpha-bgf": "0",
                            "alpha-bgh": "0",
                            "alpha-brd": "1",
                            "alpha-brdd": "0",
                            "arrowColor": "#8C84FA",
                            "bg": "rgba(255, 255, 255, 1)",
                            "bg2": "#aaaaaa",
                            "boxShadowToggleOn-shd": "true",
                            "brd": "#525252",
                            "brde": "#FF4040",
                            "brdh": "#5C5C5C",
                            "brw": "0",
                            "brwe": "1",
                            "brwf": "0",
                            "brwh": "0",
                            "fnt": "normal normal normal 15px/1.4em futura-lt-w01-book",
                            "fntlbl": "normal normal normal 15px/1.4em futura-lt-w01-book",
                            "rd": "0px",
                            "shd": "0.00px 2.00px 0px 0px rgba(140,132,250,1)",
                            "txt": "#8C84FA",
                            "txt2": "#525252",
                            "txt_placeholder": "#8C84FA",
                            "txtlbl": "#8C84FA",
                            "txtlblrq": "#8C84FA",
                            "txtd": "#D4D4D4",
                            "alpha-dropdownListBackgroundColor": "0",
                            "alpha-dropdownListHoverBackgroundColor": "0",
                            "dropdownListBackgroundColor": "rgba(255, 255, 255, 1)",
                            "boxShadowToggleOn-dropdownListBoxShadow": "true",
                            "dropdownListBoxShadow": "0.00px 2.00px 0px 0px rgba(140,132,250,1)",
                            "dropdownListStrokeColor": "#525252",
                            "dropdownListStrokeWidth": "0",
                            "dropdownListFont": "normal normal normal 15px/1.4em futura-lt-w01-book",
                            "dropdownListTextColor": "#8C84FA",
                            "dropdownListHoverBackgroundColor": "rgba(255, 255, 255, 1)",
                            "dropdownListBorderRadius": "0px",
                            "dropdownListHoverTextColor": "#8C84FA"
                        },
                        "propertiesSource": {
                          "bgh": "theme",
                          "shd": "value",
                          "rd": "value",
                          "txtd": "value",
                          "fntlbl": "value",
                          "alpha-bg": "value",
                          "bg": "theme",
                          "txt2": "value",
                          "txtlbl": "value",
                          "txt": "value",
                          "txtlblrq": "value",
                          "brwh": "value",
                          "brw": "value",
                          "txt_placeholder": "value",
                          "fnt": "value",
                          "brd": "value",
                          "brdf": "value",
                          "boxShadowToggleOn-shd": "value",
                          "brdd": "value",
                          "brwe": "value",
                          "bgd": "value",
                          "brdh": "value",
                          "bg2": "value",
                          "brde": "value",
                          "brwf": "value",
                          "arrowColor": "value"
                      }
                    },
                    "componentClassName": "wysiwyg.viewer.components.inputs.ComboBoxInput",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "ComboBoxInputSkin"
                },
                "activeModes": {},
                "id": "comp-iv7znbn3"
            },
            "preset": {"rect": {"width": 324, "height": 69, "x": 0, "y": 79}}
        }, {
            "id": "User_Input_DropDown_3",
            "structure": {
                "type": "Component",
                "skin": "ComboBoxInputSkin",
                "layout": {
                    "width": 304,
                    "height": 46,
                    "x": 9,
                    "y": 163,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.inputs.ComboBoxInput",
                "data": {
                    "type": "SelectableList",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "placeholder": {"value": "Quantity ", "text": "Quantity "},
                    "value": "",
                    "options": [{
                        "type": "SelectOption",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "value": "1",
                        "text": "1",
                        "description": "1",
                        "disabled": false
                    }, {
                        "type": "SelectOption",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "value": "2",
                        "text": "2",
                        "description": "2",
                        "disabled": false
                    }, {
                        "type": "SelectOption",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "value": "3",
                        "text": "3",
                        "description": "3",
                        "disabled": false
                    }, {
                        "type": "SelectOption",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "value": "4",
                        "text": "4",
                        "description": "4",
                        "disabled": false
                    }]
                },
                "props": {
                    "type": "ComboBoxInputProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "required": false,
                    "textAlignment": "center",
                    "textPadding": 18,
                    "placeholder": {"value": "Quantity ", "text": "Quantity "}
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "properties": {
                            "alpha-bg": "1",
                            "arrowColor": "#17B0B0",
                            "bg": "rgba(255, 255, 255, 1)",
                            "bg2": "#aaaaaa",
                            "bgd": "#FFFFFF",
                            "bgh": "#FAFAFA",
                            "boxShadowToggleOn-shd": "true",
                            "brd": "#49D6D6",
                            "brdd": "#D4D4D4",
                            "brde": "#FF4040",
                            "brdf": "#408F8F",
                            "brdh": "#49D6D6",
                            "brw": "1",
                            "brwe": "1",
                            "brwf": "1",
                            "brwh": "1",
                            "fnt": "normal normal normal 16px/1.4em georgia",
                            "fntlbl": "normal normal normal 14px/1.4em georgia",
                            "rd": "0px",
                            "shd": "0.00px 2.00px 0px 0px rgba(73,214,214,1)",
                            "txt": "#49D6D6",
                            "txt2": "#A3E6E6",
                            "txt_placeholder": "#49D6D6",
                            "txtlbl": "#49D6D6",
                            "txtlblrq": "#49D6D6",
                            "txtd": "#D4D4D4",
                            "alpha-dropdownListBackgroundColor": "1",
                            "dropdownListBackgroundColor": "rgba(255, 255, 255, 1)",
                            "boxShadowToggleOn-dropdownListBoxShadow": "false",
                            "dropdownListBoxShadow": "0.00px 2.00px 0px 0px rgba(73,214,214,1)",
                            "dropdownListStrokeColor": "#49D6D6",
                            "dropdownListStrokeWidth": "1",
                            "dropdownListFont": "normal normal normal 16px/1.4em georgia",
                            "dropdownListTextColor": "#49D6D6",
                            "dropdownListHoverBackgroundColor": "#FAFAFA",
                            "dropdownListBorderRadius": "0px",
                            "dropdownListHoverTextColor": "#49D6D6"
                        },
                        "propertiesSource": {
                          "bgh": "theme",
                          "shd": "value",
                          "rd": "value",
                          "txtd": "value",
                          "fntlbl": "value",
                          "alpha-bg": "value",
                          "bg": "theme",
                          "txt2": "value",
                          "txtlbl": "value",
                          "txt": "value",
                          "txtlblrq": "value",
                          "brwh": "value",
                          "brw": "value",
                          "txt_placeholder": "value",
                          "fnt": "value",
                          "brd": "value",
                          "brdf": "value",
                          "boxShadowToggleOn-shd": "value",
                          "brdd": "value",
                          "brwe": "value",
                          "bgd": "value",
                          "brdh": "value",
                          "bg2": "value",
                          "brde": "value",
                          "brwf": "value",
                          "arrowColor": "value"
                      }
                    },
                    "componentClassName": "wysiwyg.viewer.components.inputs.ComboBoxInput",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "ComboBoxInputSkin"
                },
                "activeModes": {},
                "id": "comp-iv7zoqdv"
            },
            "preset": {"rect": {"width": 324, "height": 78, "x": 0, "y": 148}}
        }, {
            "id": "User_Input_DropDown_4",
            "structure": {
                "type": "Component",
                "skin": "ComboBoxInputSkin",
                "layout": {
                    "width": 304,
                    "height": 46,
                    "x": 9,
                    "y": 241,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.inputs.ComboBoxInput",
                "data": {
                    "type": "SelectableList",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "placeholder": {"value": "Tell us your age", "text": "Tell us your age"},
                    "value": "",
                    "options": [{
                        "type": "SelectOption",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "value": "18-24",
                        "text": "18-24",
                        "description": "18-24",
                        "disabled": false
                    }, {
                        "type": "SelectOption",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "value": "25-34",
                        "text": "25-34",
                        "description": "25-34",
                        "disabled": false
                    }, {
                        "type": "SelectOption",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "value": "35-40",
                        "text": "35-40",
                        "description": "35-40",
                        "disabled": false
                    }, {
                        "type": "SelectOption",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "value": "41-50",
                        "text": "41-50",
                        "description": "41-50",
                        "disabled": false
                    }]
                },
                "props": {
                    "type": "ComboBoxInputProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "required": false,
                    "textAlignment": "left",
                    "textPadding": 18,
                    "placeholder": {"value": "Tell us your age", "text": "Tell us your age"}
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "properties": {
                            "arrowColor": "color_11",
                            "bg": "rgba(75,219,123,1)",
                            "bg2": "#aaaaaa",
                            "bgd": "#DBDBDB",
                            "bge": "#FF4040",
                            "bgf": "#4BDB7B",
                            "bgh": "#5EE08A",
                            "boxShadowToggleOn-shd": "false",
                            "brd": "#66E891",
                            "brdd": "#DBDBDB",
                            "brde": "#FF4040",
                            "brdh": "#408F8F",
                            "brw": "0",
                            "brwe": "0",
                            "brwf": "0",
                            "brwh": "0",
                            "fnt": "normal normal normal 14px/1.4em bree-w01-thin-oblique",
                            "fntlbl": "normal normal normal 14px/1.4em bree-w01-thin-oblique",
                            "rd": "6px",
                            "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                            "txt": "#FFFFFF",
                            "txtlbl": "#5EE08A",
                            "txtlblrq": "#5EE08A",
                            "txt2": "#FFFFFF",
                            "txt_placeholder": "#FFFFFF",
                            "dropdownListBackgroundColor": "rgba(75,219,123,1)",
                            "dropdownListBoxShadow-shd": "false",
                            "dropdownListBoxShadow": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                            "dropdownListStrokeColor": "#66E891",
                            "dropdownListStrokeWidth": "0",
                            "dropdownListFont": "normal normal normal 14px/1.4em bree-w01-thin-oblique",
                            "dropdownListTextColor": "#FFFFFF",
                            "dropdownListHoverBackgroundColor": "#5EE08A",
                            "dropdownListBorderRadius": "6px",
                            "dropdownListHoverTextColor": "#FFFFFF"
                        },
                        "propertiesSource": {
                          "bgh": "theme",
                          "shd": "value",
                          "rd": "value",
                          "txtd": "value",
                          "fntlbl": "value",
                          "alpha-bg": "value",
                          "bg": "theme",
                          "txt2": "value",
                          "txtlbl": "value",
                          "txt": "value",
                          "txtlblrq": "value",
                          "brwh": "value",
                          "brw": "value",
                          "txt_placeholder": "value",
                          "fnt": "value",
                          "brd": "value",
                          "brdf": "value",
                          "boxShadowToggleOn-shd": "value",
                          "brdd": "value",
                          "brwe": "value",
                          "bgd": "value",
                          "brdh": "value",
                          "bg2": "value",
                          "brde": "value",
                          "brwf": "value",
                          "arrowColor": "value"
                      }
                    },
                    "componentClassName": "wysiwyg.viewer.components.inputs.ComboBoxInput",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "ComboBoxInputSkin"
                },
                "activeModes": {},
                "id": "comp-iv7zoz08"
            },
            "preset": {"rect": {"width": 324, "height": 77, "x": 0, "y": 226}}
        }, {
            "id": "User_Input_DropDown_5",
            "structure": {
                "type": "Component",
                "skin": "ComboBoxInputSkin",
                "layout": {
                    "width": 304,
                    "height": 46,
                    "x": 9,
                    "y": 318,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.inputs.ComboBoxInput",
                "data": {
                    "type": "SelectableList",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "placeholder": {},
                    "value": "Koala bear",
                    "options": [{
                        "type": "SelectOption",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "value": "Koala bear",
                        "text": "Koala bear",
                        "description": "Koala bear",
                        "disabled": false
                    }, {
                        "type": "SelectOption",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "value": "Baby seal",
                        "text": "Baby seal",
                        "description": "Baby seal",
                        "disabled": false
                    }, {
                        "type": "SelectOption",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "value": "Dolphin",
                        "text": "Dolphin",
                        "description": "Dolphin",
                        "disabled": false
                    }, {
                        "type": "SelectOption",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "value": "Leopard",
                        "text": "Leopard",
                        "description": "Leopard",
                        "disabled": false
                    }]
                },
                "props": {
                    "type": "ComboBoxInputProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "required": false,
                    "textAlignment": "left",
                    "textPadding": 18,
                    "placeholder": {}
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "properties": {
                            "alpha-bgd": "0",
                            "arrowColor": "#000000",
                            "bg": "rgba(255,255,255,1)",
                            "bg2": "#aaaaaa",
                            "bgd": "#E8E8E8",
                            "bge": "#FFFFFF",
                            "bgf": "#FFFFFF",
                            "bgh": "#FFFFFF",
                            "boxShadowToggleOn-shd": "true",
                            "brd": "#E8E8E8",
                            "brdd": "#CCCCCC",
                            "brde": "#FF4040",
                            "brdf": "#E8E8E8",
                            "brdh": "#F5F5F5",
                            "brw": "0",
                            "brwh": "1",
                            "fnt": "normal normal normal 12px/1.4em lulo-clean-w01-one-bold",
                            "fntlbl": "normal normal normal 10px/1.4em lulo-clean-w01-one-bold",
                            "rd": "6px",
                            "shd": "0.00px 3.00px 10px 1px rgba(0,0,0,0.08)",
                            "txt": "#000000",
                            "txt2": "#000000",
                            "txt_placeholder": "#000000",
                            "txtlbl": "#000000",
                            "txtlblrq": "#000000",
                            "txtd": "#CCCCCC",
                            "dropdownListBackgroundColor": "rgba(255,255,255,1)",
                            "dropdownListBoxShadow-shd": "true",
                            "dropdownListBoxShadow": "0.00px 3.00px 10px 1px rgba(0,0,0,0.08)",
                            "dropdownListStrokeColor": "#E8E8E8",
                            "dropdownListStrokeWidth": "0",
                            "dropdownListFont": "normal normal normal 12px/1.4em lulo-clean-w01-one-bold",
                            "dropdownListTextColor": "#000000",
                            "dropdownListHoverBackgroundColor": "#FFFFFF",
                            "dropdownListBorderRadius": "6px",
                            "dropdownListHoverTextColor": "#000000"
                        },
                        "propertiesSource": {
                          "bgh": "theme",
                          "shd": "value",
                          "rd": "value",
                          "txtd": "value",
                          "fntlbl": "value",
                          "alpha-bg": "value",
                          "bg": "theme",
                          "txt2": "value",
                          "txtlbl": "value",
                          "txt": "value",
                          "txtlblrq": "value",
                          "brwh": "value",
                          "brw": "value",
                          "txt_placeholder": "value",
                          "fnt": "value",
                          "brd": "value",
                          "brdf": "value",
                          "boxShadowToggleOn-shd": "value",
                          "brdd": "value",
                          "brwe": "value",
                          "bgd": "value",
                          "brdh": "value",
                          "bg2": "value",
                          "brde": "value",
                          "brwf": "value",
                          "arrowColor": "value"
                      }
                    },
                    "componentClassName": "wysiwyg.viewer.components.inputs.ComboBoxInput",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "ComboBoxInputSkin"
                },
                "activeModes": {},
                "id": "comp-iv7zp3mf"
            },
            "preset": {"rect": {"width": 324, "height": 77, "x": 0, "y": 303}}
        }, {
            "id": "User_Input_DropDown_6",
            "structure": {
                "type": "Component",
                "skin": "ComboBoxInputSkin",
                "layout": {
                    "width": 304,
                    "height": 46,
                    "x": 8,
                    "y": 394,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.inputs.ComboBoxInput",
                "data": {
                    "type": "SelectableList",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "placeholder": {"value": "Choose your color", "text": "Choose your color"},
                    "value": "",
                    "options": [{
                        "type": "SelectOption",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "value": "Blue",
                        "text": "Blue",
                        "description": "Blue",
                        "disabled": false
                    }, {
                        "type": "SelectOption",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "value": "Black",
                        "text": "Black",
                        "description": "Black",
                        "disabled": false
                    }, {
                        "type": "SelectOption",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "value": "White",
                        "text": "White",
                        "description": "White",
                        "disabled": false
                    }, {
                        "type": "SelectOption",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "value": "Gray",
                        "text": "Gray",
                        "description": "Gray",
                        "disabled": false
                    }]
                },
                "props": {
                    "type": "ComboBoxInputProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "required": false,
                    "textAlignment": "left",
                    "textPadding": 18,
                    "placeholder": {"value": "Choose your color", "text": "Choose your color"}
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "properties": {
                            "alpha-bgd": "1",
                            "arrowColor": "#00A6FF",
                            "bg": "rgba(255,255,255,1)",
                            "bg2": "#aaaaaa",
                            "bgd": "#FFFFFF",
                            "bge": "#FFFFFF",
                            "bgf": "#FFFFFF",
                            "bgh": "#EAF7FF",
                            "boxShadowToggleOn-shd": "false",
                            "brd": "#C6E2F7",
                            "brdd": "#DBDBDB",
                            "brde": "#FF4040",
                            "brdf": "#00A6FF",
                            "brdh": "#C6E2F7",
                            "brw": "1",
                            "brwe": "1",
                            "brwf": "1",
                            "brwh": "1",
                            "fnt": "normal normal normal 16px/1.4em helvetica-w01-light",
                            "fntlbl": "normal normal normal 14px/1.4em helvetica-w01-light",
                            "rd": "6px",
                            "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                            "txt": "#072D3E",
                            "txt2": "#072D3E",
                            "txt_placeholder": "#072D3E",
                            "txtlbl": "#00A6FF",
                            "txtlblrq": "#00A6FF",
                            "txtd": "#D4D4D4",
                            "dropdownListBackgroundColor": "rgba(255,255,255,1)",
                            "dropdownListBoxShadow-shd": "false",
                            "dropdownListBoxShadow": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                            "dropdownListStrokeColor": "#C6E2F7",
                            "dropdownListStrokeWidth": "1",
                            "dropdownListFont": "normal normal normal 16px/1.4em helvetica-w01-light",
                            "dropdownListTextColor": "#072D3E",
                            "dropdownListHoverBackgroundColor": "#EAF7FF",
                            "dropdownListBorderRadius": "6px",
                            "dropdownListHoverTextColor": "#072D3E"
                        },
                        "propertiesSource": {
                          "bgh": "theme",
                          "shd": "value",
                          "rd": "value",
                          "txtd": "value",
                          "fntlbl": "value",
                          "alpha-bg": "value",
                          "bg": "theme",
                          "txt2": "value",
                          "txtlbl": "value",
                          "txt": "value",
                          "txtlblrq": "value",
                          "brwh": "value",
                          "brw": "value",
                          "txt_placeholder": "value",
                          "fnt": "value",
                          "brd": "value",
                          "brdf": "value",
                          "boxShadowToggleOn-shd": "value",
                          "brdd": "value",
                          "brwe": "value",
                          "bgd": "value",
                          "brdh": "value",
                          "bg2": "value",
                          "brde": "value",
                          "brwf": "value",
                          "arrowColor": "value"
                      }
                    },
                    "componentClassName": "wysiwyg.viewer.components.inputs.ComboBoxInput",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "ComboBoxInputSkin"
                },
                "activeModes": {},
                "id": "comp-ivb1sk3j"
            },
            "preset": {"rect": {"width": 324, "height": 78, "x": 0, "y": 380}}
        }],
        "compTypes": ["wysiwyg.viewer.components.inputs.ComboBoxInput"]
    },
    "help": {"hide": false, "text": "add_section_info_text_comboboxinput"}
}
