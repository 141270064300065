const i18n = require("@/i18n");

module.exports = {
  id: "Stylable_StylableButton_29",
  preset: { rect: { width: 107, height: 75, x: 135, y: 816 }, tags: null },
  structure: {
    parent: "comp-knzvqsjj",
    style: {
      styleType: "custom",
      componentClassName: "wixui.StylableButton",
      style: {
        properties: {
          "$st-css":
            ":import{\n    -st-from: 'wix-ui-santa/index.st.css';\n    -st-named: StylableButton\n}\n.root{\n    -st-extends: StylableButton;\n    transition: all 0.2s ease, visibility 0s;\n    background: value(site_1_1);\n    border-radius: 50px;\n    border: 0px solid rgb(95, 91, 205);\n    box-shadow: 0 0 6px rgba(95, 91, 205, 0.7)\n}\n.root:hover {\n    box-shadow: 0 0 6px 1px rgb(95, 91, 205);\n}\n.root[disabled]{\n    background: #E2E2E2\n}\n.root[disabled]::label{\n    color: #8F8F8F\n}\n.root[disabled]::icon{\n    fill: #8F8F8F\n}\n.root::container{\n    transition: inherit\n}\n.root::label{\n    transition: inherit;\n    display: initial;\n    color: #5F5BCD;\n    font-family: avenir-lt-w01_35-light1475496,sans-serif;\n    font-size: 15px\n}\n.root::icon{\n    transition: inherit;\n    fill: #8B6755;\n    width: 20px;\n    height: 20px;\n    display: none\n}",
        },
        propertiesSource: { "$st-css": "value" },
      },
      pageId: "",
      compId: "",
      type: "ComponentStyle",
      metaData: {
        isHidden: false,
        sig: "4ie-213",
        pageId: "c1dmp",
        schemaVersion: "1.0",
        isPreset: false,
      },
      skin: "wixui.skins.Skinless",
    },
    activeModes: {},
    data: {
      type: "StylableButton",
      metaData: {
        isHidden: false,
        sig: "5vr-2079",
        pageId: "c1dmp",
        schemaVersion: "1.0",
        isPreset: false,
      },
      label: i18n.translate("buttons_text_preset_send_rounded"),
      svgId: "11062b_a67643df07304d9bb39d3653dac8bb91.svg",
    },
    components: [],
    componentType: "wixui.StylableButton",
    id: "comp-kmdeyz2v",
    layout: {
      x: 141,
      fixedPosition: false,
      y: 829,
      scale: 1,
      height: 42,
      rotationInDegrees: 0,
      width: 86,
    },
    type: "Component",
    props: {
      type: "StylableButtonProperties",
      metaData: { schemaVersion: "1.0", sig: "hmj-1283", pageId: "c1dmp" },
    },
    metaData: { sig: "inq-344", pageId: "c1dmp" },
    skin: "wixui.skins.Skinless",
  },
};
