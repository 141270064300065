const addPanelDataConsts = require('@/addPanelDataConsts')
const util = require('@/util')
const lang = util.languages.getLanguageCode()
const i18n = require('@/i18n')

module.exports = {
    "type": addPanelDataConsts.SECTIONS_TYPES.PRESET,
    "categoryId": "other",
    "hide": false,
    "subNavigationTitle": "add_section_label_pay_button",
    "title": "add_section_label_pay_button",
    "presetTitle": "add_section_label_pay_button",
    "tooltipTitle": "add_section_label_pay_button_tooltip_title",
    "automationId": "add-panel-section-payButtonSection",
    "subNavigationHide": false,
    "showSectionHeader": true,
    "appDefinitionId": "cdd4b6d5-6fb4-4bd1-9189-791244b5361e",
    "additionalBehaviours": {
        "labelMode": addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
        "infoIcon": addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
        "hoverImageAction": addPanelDataConsts.HOVER_IMAGE_ACTIONS.IMAGE,
        "iconEnabledForComps": {}
    },
    "props": {
        "image": `addPanelData/sections/payButton_${lang}/payButton_${lang}.png`,
        "imageHover": `addPanelData/sections/payButton_${lang}/hover_${lang}/payButton_hover_${lang}.png`,
        "items": [{
            "appDefinitionId": "cdd4b6d5-6fb4-4bd1-9189-791244b5361e",
            "id": "cashierPayButton1",
            "structure": {
                "type": "Container",
                "id": "comp-cashierPayButton1",
                "components": [
                    {
                        "layout": {
                            "width": 142,
                            "height": 44,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.SiteButton",
                        "type": "Component",
                        "id": "comp-jyfm7b6q",
                        "skin": "wysiwyg.viewer.skins.button.BasicButton",
                        "data": {
                            "type": "LinkableButton",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "1.0",
                                "isHidden": false
                            },
                            "label": i18n.translate('paybutton_preset_button_pay_now_2'),
                            "id": "dataItem-jyfm7b6r"
                        },
                        "props": {
                            "type": "ButtonProperties",
                            "metaData": {
                                "schemaVersion": "1.0"
                            },
                            "align": "center",
                            "margin": 0,
                            "id": "propItem-jyfm7b6s"
                        },
                        "style": {
                            "componentClassName": "wysiwyg.viewer.components.SiteButton",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "metaData": {
                                "isPreset": true,
                                "schemaVersion": "1.0",
                                "isHidden": false
                            },
                            "style": {
                                "properties": {
                                    "boxShadowToggleOn-shd": "false",
                                    "alpha-txt": "1",
                                    "bgh": "#4E59F2",
                                    "alpha-brdh": "0",
                                    "bg": "#1A2DF3",
                                    "alpha-brd": "1",
                                    "brw": "0",
                                    "alpha-txth": "1",
                                    "fnt": "normal normal normal 15px/1.4em poppins-extralight",
                                    "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                    "txt": "color_11",
                                    "rd": "200px 200px 200px 200px",
                                    "brdh": "#1A2DF3",
                                    "brd": "#1A2DF3",
                                    "alpha-bg": "1",
                                    "alpha-bgh": "1",
                                    "txth": "#FFFFFF"
                                },
                                "propertiesSource": {
                                    "boxShadowToggleOn-shd": "value",
                                    "alpha-txt": "value",
                                    "bgh": "value",
                                    "alpha-brdh": "value",
                                    "bg": "value",
                                    "alpha-brd": "value",
                                    "brw": "value",
                                    "alpha-txth": "value",
                                    "fnt": "value",
                                    "shd": "value",
                                    "txt": "theme",
                                    "rd": "value",
                                    "brdh": "value",
                                    "brd": "value",
                                    "alpha-bg": "value",
                                    "alpha-bgh": "value",
                                    "txth": "value"
                                },
                                "groups": {}
                            },
                            "type": "ComponentStyle",
                            "skin": "wysiwyg.viewer.skins.button.BasicButton",
                            "id": "style-jyfmmrd9"
                        },
                        "connections": {
                            "type": "ConnectionList",
                            "items": [
                                {
                                    "type": "WixCodeConnectionItem",
                                    "role": "button13"
                                },
                                {
                                    "type": "ConnectionItem",
                                    "role": "PayButton",
                                    "controllerId": "dataItem-jyfm7b67",
                                    "isPrimary": true
                                }
                            ],
                            "id": "connection-jyfm7b77"
                        },
                        "mobileStructure": {
                            "layout": {
                                "width": 160,
                                "height": 44,
                                "x": 0,
                                "y": 0,
                                "scale": 0.9333333333333333,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "metaData": {
                                "originalCompId": "comp-jyfm7b6q",
                                "author": "duplicate"
                            }
                        }
                    }
                ],
                "layout": {
                    "width": 142,
                    "height": 44,
                    "x": 10,
                    "y": 20,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "platform.components.AppWidget",
                "data": {
                    "type": "AppController",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "applicationId": "cdd4b6d5-6fb4-4bd1-9189-791244b5361e",
                    "name": "WixPay",
                    "controllerType": "WixPay",
                    "settings": "{\"productId\":\"3bb1adb2-e230-4b06-912d-1b7eb23b69eb\",\"metaSiteId\":\"4a75e237-7483-4f94-ad9e-7fa576f016cf\"}",
                    "id": "dataItem-jyfm7b67"
                },
                "style": "appWidget1",
                "connections": {
                    "type": "ConnectionList",
                    "items": [
                        {
                            "type": "WixCodeConnectionItem",
                            "role": "wixPay1"
                        }
                    ],
                    "id": "connection-jyfm7b7b"
                },
                "mobileStructure": {
                    "layout": {
                        "width": 160,
                        "height": 44,
                        "x": 80,
                        "y": 95,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "metaData": {
                        "originalCompId": "comp-jyfm7b5f",
                        "author": "duplicate"
                    }
                },
                "activeModes": {}
            }, "preset": {"rect": {"width": 162, "height": 78, "x": 0, "y": 0}, "tags": null}
        }, {
            "appDefinitionId": "cdd4b6d5-6fb4-4bd1-9189-791244b5361e",
            "id": "cashierPayButton2", "structure": {
                "layout": {
                    "width": 142,
                    "height": 44,
                    "x": 172,
                    "y": 20,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "components": [
                    {
                        "layout": {
                            "width": 142,
                            "height": 44,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.SiteButton",
                        "type": "Component",
                        "id": "comp-jyfm93fg",
                        "skin": "wysiwyg.viewer.skins.button.BasicButton",
                        "data": {
                            "type": "LinkableButton",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "1.0",
                                "isHidden": false
                            },
                            "label": i18n.translate('paybutton_preset_button_buy_now_2'),
                            "id": "dataItem-jyfm93fl"
                        },
                        "props": {
                            "type": "ButtonProperties",
                            "metaData": {
                                "schemaVersion": "1.0"
                            },
                            "align": "center",
                            "margin": 0,
                            "id": "propItem-jyfm93fm"
                        },
                        "style": {
                            "type": "ComponentStyle",
                            "metaData": {
                                "isPreset": true,
                                "schemaVersion": "1.0",
                                "isHidden": false
                            },
                            "style": {
                                "properties": {
                                    "alpha-bg": "1",
                                    "alpha-bgh": "1",
                                    "alpha-brd": "1",
                                    "alpha-brdh": "1",
                                    "alpha-txt": "1",
                                    "alpha-txth": "1",
                                    "bg": "#FFD45A",
                                    "bgh": "#FFE18C",
                                    "boxShadowToggleOn-shd": "false",
                                    "brd": "#FFD45A",
                                    "brdh": "#733787",
                                    "brw": "0px",
                                    "fnt": "normal normal normal 15px/1.4em avenir-lt-w01_85-heavy1475544",
                                    "rd": "6px 6px 6px 6px",
                                    "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                    "txt": "#353131",
                                    "txth": "#353131"
                                },
                                "propertiesSource": {
                                    "alpha-bg": "value",
                                    "alpha-bgh": "value",
                                    "alpha-brd": "value",
                                    "alpha-brdh": "value",
                                    "alpha-txt": "value",
                                    "alpha-txth": "value",
                                    "bg": "value",
                                    "bgh": "value",
                                    "boxShadowToggleOn-shd": "value",
                                    "brd": "value",
                                    "brdh": "value",
                                    "brw": "value",
                                    "fnt": "value",
                                    "rd": "value",
                                    "shd": "value",
                                    "txt": "value",
                                    "txth": "value"
                                },
                                "groups": {}
                            },
                            "componentClassName": "wysiwyg.viewer.components.SiteButton",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.button.BasicButton",
                            "id": "style-jyfm9ays"
                        },
                        "connections": {
                            "type": "ConnectionList",
                            "items": [
                                {
                                    "type": "WixCodeConnectionItem",
                                    "role": "button14"
                                },
                                {
                                    "type": "ConnectionItem",
                                    "role": "PayButton",
                                    "controllerId": "dataItem-jyfm93fa",
                                    "isPrimary": true
                                }
                            ],
                            "id": "connection-jyfm93fm2"
                        },
                        "mobileStructure": {
                            "layout": {
                                "width": 160,
                                "height": 44,
                                "x": 0,
                                "y": 0,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "metaData": {
                                "originalCompId": "comp-jyfm93fg",
                                "author": "duplicate"
                            }
                        }
                    }
                ],
                "componentType": "platform.components.AppWidget",
                "type": "Container",
                "id": "comp-cashierPayButton2",
                "data": {
                    "type": "AppController",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "applicationId": "cdd4b6d5-6fb4-4bd1-9189-791244b5361e",
                    "name": "WixPay",
                    "controllerType": "WixPay",
                    "settings": "{\"productId\":\"2fed3bca-5e82-4d5d-ac4f-4fda7c7bfc05\",\"metaSiteId\":\"4a75e237-7483-4f94-ad9e-7fa576f016cf\"}",
                    "id": "dataItem-jyfm93fa"
                },
                "style": "appWidget1",
                "connections": {
                    "type": "ConnectionList",
                    "items": [
                        {
                            "type": "WixCodeConnectionItem",
                            "role": "wixPay2"
                        }
                    ],
                    "id": "connection-jyfm93fb"
                },
                "mobileStructure": {
                    "layout": {
                        "width": 160,
                        "height": 44,
                        "x": 80,
                        "y": 154,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "metaData": {
                        "originalCompId": "comp-jyfm93ef",
                        "author": "duplicate"
                    }
                },
                "activeModes": {}
            }, "preset": {"rect": {"width": 162, "height": 78, "x": 162, "y": 0}, "tags": null}
        }, {
            "appDefinitionId": "cdd4b6d5-6fb4-4bd1-9189-791244b5361e",
            "id": "cashierPayButton3", "structure": {
                "layout": {
                    "width": 190,
                    "height": 44,
                    "x": 10,
                    "y": 94,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "components": [
                    {
                        "layout": {
                            "width": 190,
                            "height": 44,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.SiteButton",
                        "type": "Component",
                        "id": "comp-jyfm9lrd",
                        "skin": "wysiwyg.viewer.skins.button.BasicButton",
                        "data": {
                            "type": "LinkableButton",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "1.0",
                                "isHidden": false
                            },
                            "label": i18n.translate('paybutton_preset_button_purchase_2'),
                            "id": "dataItem-jyfm9lrg"
                        },
                        "props": {
                            "type": "ButtonProperties",
                            "metaData": {
                                "schemaVersion": "1.0"
                            },
                            "align": "center",
                            "margin": 0,
                            "id": "propItem-jyfm9lrg1"
                        },
                        "style": {
                            "type": "ComponentStyle",
                            "metaData": {
                                "isPreset": true,
                                "schemaVersion": "1.0",
                                "isHidden": false
                            },
                            "style": {
                                "properties": {
                                    "alpha-bg": "1",
                                    "alpha-bgh": "1",
                                    "alpha-brd": "1",
                                    "alpha-brdh": "1",
                                    "alpha-txt": "1",
                                    "alpha-txth": "1",
                                    "bg": "#20CE88",
                                    "bgh": "#1DB578",
                                    "boxShadowToggleOn-shd": "false",
                                    "brd": "#20CE88",
                                    "brdh": "#1DB578",
                                    "brw": "0px",
                                    "fnt": "normal normal normal 13px/1.4em lulo-clean-w01-one-bold",
                                    "rd": "0px",
                                    "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                    "txt": "#FFFFFF",
                                    "txth": "#FFFFFF"
                                },
                                "propertiesSource": {
                                    "alpha-bg": "value",
                                    "alpha-bgh": "value",
                                    "alpha-brd": "value",
                                    "alpha-brdh": "value",
                                    "alpha-txt": "value",
                                    "alpha-txth": "value",
                                    "bg": "value",
                                    "bgh": "value",
                                    "boxShadowToggleOn-shd": "value",
                                    "brd": "value",
                                    "brdh": "value",
                                    "brw": "value",
                                    "fnt": "value",
                                    "rd": "value",
                                    "shd": "value",
                                    "txt": "value",
                                    "txth": "value"
                                },
                                "groups": {}
                            },
                            "componentClassName": "wysiwyg.viewer.components.SiteButton",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.button.BasicButton",
                            "id": "style-jyfm9p2k"
                        },
                        "connections": {
                            "type": "ConnectionList",
                            "items": [
                                {
                                    "type": "WixCodeConnectionItem",
                                    "role": "button15"
                                },
                                {
                                    "type": "ConnectionItem",
                                    "role": "PayButton",
                                    "controllerId": "dataItem-jyfm9lr7",
                                    "isPrimary": true
                                }
                            ],
                            "id": "connection-jyfm9lrh1"
                        },
                        "mobileStructure": {
                            "layout": {
                                "width": 190,
                                "height": 44,
                                "x": 0,
                                "y": 0,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "metaData": {
                                "originalCompId": "comp-jyfm9lrd",
                                "author": "duplicate"
                            }
                        }
                    }
                ],
                "componentType": "platform.components.AppWidget",
                "type": "Container",
                "id": "comp-cashierPayButton3",
                "data": {
                    "type": "AppController",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "applicationId": "cdd4b6d5-6fb4-4bd1-9189-791244b5361e",
                    "name": "WixPay",
                    "controllerType": "WixPay",
                    "settings": "{\"productId\":\"7e75e634-16f8-4a43-9a2b-eb4b6446a4c5\",\"metaSiteId\":\"4a75e237-7483-4f94-ad9e-7fa576f016cf\"}",
                    "id": "dataItem-jyfm9lr7"
                },
                "style": "appWidget1",
                "connections": {
                    "type": "ConnectionList",
                    "items": [
                        {
                            "type": "WixCodeConnectionItem",
                            "role": "wixPay3"
                        }
                    ],
                    "id": "connection-jyfm9lr8"
                },
                "mobileStructure": {
                    "layout": {
                        "width": 190,
                        "height": 44,
                        "x": 65,
                        "y": 213,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "metaData": {
                        "originalCompId": "comp-jyfm9lq9",
                        "author": "duplicate"
                    }
                },
                "activeModes": {}
            }, "preset": {"rect": {"width": 210, "height": 78, "x": 0, "y": 78}, "tags": null}
        }, {
            "appDefinitionId": "cdd4b6d5-6fb4-4bd1-9189-791244b5361e",
            "id": "cashierPayButton4", "structure": {
                "layout": {
                    "width": 94,
                    "height": 44,
                    "x": 220,
                    "y": 94,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "components": [
                    {
                        "layout": {
                            "width": 94,
                            "height": 44,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.SiteButton",
                        "type": "Component",
                        "id": "comp-jyfma39t",
                        "skin": "wysiwyg.viewer.skins.button.BasicButton",
                        "data": {
                            "type": "LinkableButton",
                            "metaData": {
                                "isPreset": false,
                                "schemaVersion": "1.0",
                                "isHidden": false
                            },
                            "label": i18n.translate('paybutton_preset_button_get_it_2'),
                            "id": "dataItem-jyfma39v"
                        },
                        "props": {
                            "type": "ButtonProperties",
                            "metaData": {
                                "schemaVersion": "1.0"
                            },
                            "align": "center",
                            "margin": 0,
                            "id": "propItem-jyfma39w"
                        },
                        "style": {
                            "type": "ComponentStyle",
                            "metaData": {
                                "isPreset": true,
                                "schemaVersion": "1.0",
                                "isHidden": false
                            },
                            "style": {
                                "properties": {
                                    "alpha-bg": "0",
                                    "alpha-bgh": "1",
                                    "alpha-brd": "1",
                                    "alpha-brdh": "0",
                                    "alpha-txt": "1",
                                    "alpha-txth": "1",
                                    "bg": "#FFFFFF",
                                    "bgh": "#323232",
                                    "boxShadowToggleOn-shd": "false",
                                    "brd": "#323232",
                                    "brdh": "#323232",
                                    "brw": "2",
                                    "fnt": "normal normal normal 13px/1.4em arial+black",
                                    "rd": "0px 0px 0px 0px",
                                    "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                    "txt": "#323232",
                                    "txth": "#FFFFFF"
                                },
                                "propertiesSource": {
                                    "alpha-bg": "value",
                                    "alpha-bgh": "value",
                                    "alpha-brd": "value",
                                    "alpha-brdh": "value",
                                    "alpha-txt": "value",
                                    "alpha-txth": "value",
                                    "bg": "value",
                                    "bgh": "value",
                                    "boxShadowToggleOn-shd": "value",
                                    "brd": "value",
                                    "brdh": "value",
                                    "brw": "value",
                                    "fnt": "value",
                                    "rd": "value",
                                    "shd": "value",
                                    "txt": "value",
                                    "txth": "value"
                                },
                                "groups": {}
                            },
                            "componentClassName": "wysiwyg.viewer.components.SiteButton",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.button.BasicButton",
                            "id": "style-jyfmafi0"
                        },
                        "connections": {
                            "type": "ConnectionList",
                            "items": [
                                {
                                    "type": "WixCodeConnectionItem",
                                    "role": "button16"
                                },
                                {
                                    "type": "ConnectionItem",
                                    "role": "PayButton",
                                    "controllerId": "dataItem-jyfma39n",
                                    "isPrimary": true
                                }
                            ],
                            "id": "connection-jyfma39x"
                        },
                        "mobileStructure": {
                            "layout": {
                                "width": 100,
                                "height": 44,
                                "x": 0,
                                "y": 0,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "metaData": {
                                "originalCompId": "comp-jyfma39t",
                                "author": "duplicate"
                            }
                        }
                    }
                ],
                "componentType": "platform.components.AppWidget",
                "type": "Container",
                "id": "comp-cashierPayButton4",
                "data": {
                    "type": "AppController",
                    "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false
                    },
                    "applicationId": "cdd4b6d5-6fb4-4bd1-9189-791244b5361e",
                    "name": "WixPay",
                    "controllerType": "WixPay",
                    "settings": "{\"productId\":\"7caa79ff-4eaa-4106-90cb-51e99d9f21ab\",\"metaSiteId\":\"4a75e237-7483-4f94-ad9e-7fa576f016cf\"}",
                    "id": "dataItem-jyfma39n"
                },
                "style": "appWidget1",
                "connections": {
                    "type": "ConnectionList",
                    "items": [
                        {
                            "type": "WixCodeConnectionItem",
                            "role": "wixPay4"
                        }
                    ],
                    "id": "connection-jyfma39o"
                },
                "mobileStructure": {
                    "layout": {
                        "width": 100,
                        "height": 44,
                        "x": 110,
                        "y": 41,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "metaData": {
                        "originalCompId": "comp-jyfma38u",
                        "author": "duplicate"
                    }
                },
                "activeModes": {}
            }, "preset": {"rect": {"width": 114, "height": 78, "x": 210, "y": 78}, "tags": null}
        }],
        "compTypes": ["platform.components.AppWidget"]
    },
    "help": {"hide": false, "text": "add_section_label_pay_button_tooltip_description"}
}
