import { SearchCategories } from './services/searchModule/constants';

export const TRANSLATIONS = {
  INPUT_PLACEHOLDER: 'Editor_Search_Box_Placeholder_Text',
  EMPTY_STATE: {
    TITLE: 'Editor_Search_Results_No_Results_Title',
    TEXT: 'Editor_Search_Results_No_Results_Text',
  },
  ERROR_STATE: {
    ERRORS: {
      NETWORK: {
        TITLE: 'Editor_Search_Results_Error_Internet_Title',
        TEXT: 'Editor_Search_Results_Error_Internet_Text',
      },
      SERVER: {
        TITLE: 'Editor_Search_Results_Error_Server_Title',
        TEXT: 'Editor_Search_Results_Error_Server_Text',
      },
      UNAUTHORIZED: {
        TITLE: 'Editor_Search_Results_Error_401_Title',
        TEXT: 'Editor_Search_Results_Error_401_Text',
      },
    },
  },
  CATEGORIES: {
    [SearchCategories.ADD_TO_YOUR_SITE]: {
      title: 'Editor_Search_Results_AddToSite_Section_Title',
      itemCTA: 'Editor_Search_Results_AddToSite_Section_CTA',
      showMore: 'Editor_Search_Results_Show_More_Add_To_Site_1',
      showMorePlural: 'Editor_Search_Results_Show_More_Add_To_Site',
    },
    [SearchCategories.TOOLS_AND_SETTINGS]: {
      title: 'Editor_Search_Results_ToolsSettings_Section_Title',
      itemCTA: 'Editor_Search_Results_ToolsSettings_Section_CTA',
      showMore: 'Editor_Search_Results_Show_More_Tools_Settings_1',
      showMorePlural: 'Editor_Search_Results_Show_More_Tools_Settings',
    },
    [SearchCategories.QUICK_ACTIONS]: {
      title: 'Editor_Search_Results_SiteActions_Section_Title',
      itemCTA: 'Editor_Search_Results_SiteActions_Section_CTA',
      showMore: 'Editor_Search_Results_Show_More_Site_Actions_1',
      showMorePlural: 'Editor_Search_Results_Show_More_Site_Actions',
    },
    [SearchCategories.APP_MARKET]: {
      title: 'Editor_Search_Results_AppMarket_Section_Title',
      itemCTA: 'Editor_Search_Results_AppMarket_Section_CTA',
      showMore: 'Editor_Search_Results_Show_More_App_Market_1',
      showMorePlural: 'Editor_Search_Results_Show_More_App_Market',
    },
    [SearchCategories.HELP_CENTER]: {
      title: 'Editor_Search_Results_HelpCenter_Section_Title',
      itemCTA: 'Editor_Search_Results_HelpCenter_Section_CTA',
      showMore: 'Editor_Search_Results_Show_More_Help_Center_1',
      showMorePlural: 'Editor_Search_Results_Show_More_Help_Center',
    },
    [SearchCategories.BUSINESS_MANAGER]: {
      title: 'Editor_Search_Results_Dashboard_Section_Title',
      itemCTA: 'Editor_Search_Results_Dashboard_Section_CTA',
      showMore: 'Editor_Search_Results_Show_More_Dashboard_1',
      showMorePlural: 'Editor_Search_Results_Show_More_Dashboard',
    },
  },
  FOOTER: {
    APP_MARKET_LINK: 'Editor_Search_Results_No_Results_AppMarket_Link',
    HELP_CENTER_LINK: 'Editor_Search_Results_No_Results_HelpCenter_Link',
  },
};
