import type {
  DocumentServicesObject,
  CompRef,
} from '@wix/document-services-types';

export const collectComponents = (
  documentServices: DocumentServicesObject,
  pageId: string,
  shouldCollectOnlyRoot: boolean,
): CompRef[] => {
  const masterPageRef = documentServices.components.get.byId(
    documentServices.pages.getMasterPageId(),
  ) as CompRef;

  const masterPageRootComponents = documentServices.components
    .getChildren(masterPageRef)
    .filter(({ id }: CompRef) => id !== 'SITE_FOOTER'); // SITE_FOOTER is skipped in calculation

  const pageComponent = documentServices.components.get.byId(pageId) as CompRef;

  const pageChildren = documentServices.components.getChildren(
    pageComponent,
    !shouldCollectOnlyRoot,
  );

  // the order is important, it used for getting z-index
  return [...masterPageRootComponents, pageComponent, ...pageChildren];
};
