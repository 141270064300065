'use strict'

module.exports = {
    id: 'add_preset_popups_Subscribe_5',
    structure: {
        type: 'Page',
        id: 'a3dkn',
        componentTypeForBI: 'wysiwyg.viewer.components.PopupContainer',
        components: [
            {
                type: 'Container',
                id: 'comp-im0oys5d',
                components: [
                    {
                        type: 'Container',
                        id: 'comp-im3hc1yr',
                        components: [],
                        skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
                        layout: {
                            width: 601,
                            height: 363,
                            x: 9,
                            y: 9,
                            scale: 1,
                            rotationInDegrees: 0,
                            fixedPosition: false
                        },
                        componentType: 'mobile.core.components.Container',
                        style: {
                            type: 'TopLevelStyle',
                            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                            style: {
                                groups: {},
                                properties: {
                                    'alpha-bg': '0',
                                    'alpha-brd': '1',
                                    bg: '#FFFFFF',
                                    'boxShadowToggleOn-shd': 'false',
                                    brd: '#FFFFFF',
                                    brw: '8',
                                    rd: '0px',
                                    shd: '0px 1px 4px 0px rgba(0,0,0,0.6)'
                                },
                                propertiesSource: {
                                    'alpha-bg': 'value',
                                    bg: 'value',
                                    brd: 'value',
                                    brw: 'value',
                                    rd: 'value',
                                    shd: 'value'
                                }
                            },
                            componentClassName: 'mobile.core.components.Container',
                            pageId: '',
                            compId: '',
                            styleType: 'custom',
                            skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
                            id: 'style-imest1lw'
                        }
                    },
                    {
                        'type': 'Component',
                        'skin': 'svgshape.v2.Svg_d9aa4ba7d4cf439eae572bd0a2037a9a',
                        'layout': {
                            'width': 32,
                            'height': 32,
                            'x': 633,
                            'y': -2,
                            'scale': 1,
                            'rotationInDegrees': 0,
                            'fixedPosition': false
                        },
                        'componentType': 'wysiwyg.viewer.components.PopupCloseIconButton',
                        'parent': 'comp-jv6fi7n2',
                        'metaData': {
                            'pageId': 'yusqi'
                        },
                        'data': {
                            'type': 'SvgShape',
                            'metaData': {
                                'isPreset': false,
                                'schemaVersion': '1.0',
                                'isHidden': false,
                                'pageId': 'yusqi'
                            }
                        },
                        'props': {
                            'type': 'SvgShapeProperties',
                            'metaData': {
                                'schemaVersion': '1.0',
                                'pageId': 'yusqi'
                            },
                            'maintainAspectRatio': true
                        },
                        'mobileHints': {
                            'type': 'MobileHints',
                            'author': 'duplicate',
                            'originalCompId': 'comp-isrerf8a',
                            'recommendedScale': 1,
                            'recommendedWidth': 22,
                            'recommendedHeight': 22,
                            'metaData': {
                                'pageId': 'yusqi'
                            }
                        },
                        'style': {
                            'type': 'ComponentStyle',
                            'metaData': {
                                'isPreset': false,
                                'schemaVersion': '1.0',
                                'isHidden': false,
                                'pageId': 'yusqi'
                            },
                            'style': {
                                'properties': {
                                    'alpha-fillcolor': '1',
                                    'alpha-stroke': '1',
                                    'fillcolor': '#000000',
                                    'stroke': '#7E8192',
                                    'strokewidth': '1'
                                },
                                'propertiesSource': {
                                    'alpha-fillcolor': 'value',
                                    'alpha-stroke': 'value',
                                    'fillcolor': 'value',
                                    'stroke': 'value',
                                    'strokewidth': 'value'
                                },
                                'groups': {}
                            },
                            'componentClassName': 'wysiwyg.viewer.components.PopupCloseIconButton',
                            'pageId': '',
                            'compId': '',
                            'styleType': 'custom',
                            'skin': 'svgshape.v2.Svg_d9aa4ba7d4cf439eae572bd0a2037a9a'
                        },
                        'connections': {
                            'type': 'ConnectionList',
                            'items': [
                                {
                                    'type': 'WixCodeConnectionItem',
                                    'role': 'button1'
                                }
                            ],
                            'metaData': {
                                'pageId': 'yusqi'
                            }
                        },
                        'activeModes': {}
                    }
                ],
                skin: 'wysiwyg.viewer.skins.stripContainer.DefaultStripContainer',
                layout: {
                    width: 619,
                    height: 382,
                    x: 180,
                    y: 198,
                    scale: 1,
                    rotationInDegrees: 0,
                    fixedPosition: false
                },
                componentType: 'wysiwyg.viewer.components.PopupContainer',
                props: {
                    type: 'PopupContainerProperties',
                    metaData: {schemaVersion: '1.0'},
                    horizontalAlignment: 'center',
                    verticalAlignment: 'center',
                    alignmentType: 'nineGrid',
                    horizontalOffset: 0,
                    verticalOffset: 0,
                    id: 'propItem-im0oys5e'
                },
                design: {
                    type: 'MediaContainerDesignData',
                    metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                    background: {
                        type: 'BackgroundMedia',
                        metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                        mediaRef: {
                            type: 'Image',
                            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                            title: 'Marble Surface',
                            uri: 'bc3ec7bc825c4e6ca746c659189cea83.jpg',
                            description: 'public/a0c10733-b2a6-4330-a431-537a9f05c353/820351a1-729d-4bd1-856d-d578ca44f29e',
                            width: 1920,
                            height: 1080,
                            alt: '',
                            artist: {id: '', name: ''}
                        },
                        color: '{color_11}',
                        colorOpacity: 1,
                        alignType: 'center',
                        fittingType: 'fill',
                        scrollType: 'none',
                        colorOverlay: '',
                        colorOverlayOpacity: 0
                    },
                    id: 'dataItem-im0oys5f'
                },
                style: {
                    type: 'TopLevelStyle',
                    metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                    style: {
                        groups: {},
                        properties: {'alpha-bg': '1', bg: 'rgba(255,255,255,1)'},
                        propertiesSource: {'alpha-bg': 'value', bg: 'value'}
                    },
                    componentClassName: 'wysiwyg.viewer.components.PopupContainer',
                    pageId: '',
                    compId: '',
                    styleType: 'custom',
                    skin: 'wysiwyg.viewer.skins.stripContainer.DefaultStripContainer',
                    id: 'style-im0oys5g'
                },
                behaviors: {
                    type: 'ObsoleteBehaviorsList',
                    items: '[{"name":"FadeIn","delay":0.1,"duration":0.75,"action":"screenIn","targetId":"","params":{}},{"name":"FadeOut","delay":0.1,"duration":0.75,"action":"exit","targetId":"","params":{}}]',
                    id: 'behavior-iomlwedm'
                }
            }
        ],

        skin: 'skins.core.InlineSkin',
        layout: {
            width: 980,
            height: 580,
            x: 0,
            y: 0,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false
        },
        componentType: 'mobile.core.components.Page',
        data: {
            type: 'Page',
            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
            title: 'add_preset_popups_Subscribe_5',
            hideTitle: true,
            icon: '',
            descriptionSEO: '',
            metaKeywordsSEO: '',
            pageTitleSEO: '',
            pageUriSEO: 'copy-of-subscribe4-a3dkn',
            hidePage: true,
            underConstruction: false,
            pageSecurity: {requireLogin: false, passwordDigest: '', dialogLanguage: ''},
            isPopup: true,
            indexable: false,
            isLandingPage: false,
            pageBackgrounds: {
                desktop: {
                    ref: {
                        type: 'BackgroundMedia',
                        metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                        color: '#83969C',
                        colorOpacity: 0.85,
                        alignType: 'center',
                        fittingType: 'fill',
                        scrollType: 'none',
                        colorOverlay: '',
                        colorOverlayOpacity: 0
                    }
                },
                mobile: {
                    custom: true,
                    ref: {
                        type: 'BackgroundMedia',
                        metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                        color: '#83969C',
                        colorOpacity: 0.85,
                        alignType: 'center',
                        fittingType: 'fill',
                        scrollType: 'scroll',
                        colorOverlay: '',
                        colorOverlayOpacity: 0
                    },
                    isPreset: true
                }
            },
            ignoreBottomBottomAnchors: true
        },
        props: {
            type: 'PageProperties',
            metaData: {schemaVersion: '1.0'},
            desktop: {popup: {closeOnOverlayClick: true}},
            mobile: {popup: {closeOnOverlayClick: true}},
            id: 'a3dkn'
        },
        style: 'p1'
    },
    preset: {
        rect: {width: 305, height: 202, x: 10, y: 20},
        label: 'add_preset_popups_Subscribe_5',
        tags: null
    }
}
