import React from 'react';
import { hoc, cx } from '@/util';
import { useQuery } from 'react-query';
import { stateMapperArgsSelectors, selection } from '@/stateManagement';
import { mapStateToProps, mapDispatchToProps } from './ChildBoxesMapper';
import styles from './ChildBoxes.scss';
import { AUTOMATION_IDS } from './constants';
import { withRestrictions } from '@/editorRestrictions';

import type { StateMapperArgs } from 'types/redux';
import type { ChildBoxesProps, ChildBox } from './ChildBoxesMapper';

const {
  mouseSels: { isResizingSel },
} = stateMapperArgsSelectors;

const Component: React.FC<ChildBoxesProps> = ({
  sectionLikeId,
  isSiteSegment,
  getChildBoxes,
  undoLastSnapshotId,
  previewWidth,
  shouldHideChildBoxes,
  isZoomModeTransitionActive,
}) => {
  /**
   * We need useQuery in order to memoize the getChildBoxes() call, under the following deps:
   * sectionLikeId: when changing a section like, the childBoxes should be updated
   * undoLastSnapshotId: when undo/redo, the childBoxes should be updated
   * previewWidth: when preview width changes, the childBoxes should be updated
   */
  const { data: boxes } = useQuery<ChildBox[]>(
    [
      sectionLikeId,
      undoLastSnapshotId,
      previewWidth,
      isZoomModeTransitionActive,
    ],
    () => getChildBoxes(sectionLikeId),
  );
  if (!boxes?.length || shouldHideChildBoxes) {
    return null;
  }
  return (
    <>
      {boxes.map(({ id, style }) => (
        <div
          style={style}
          data-hook={AUTOMATION_IDS.CHILD_BOX}
          data-comp-id={id}
          key={id}
          className={cx(styles.box, {
            [styles.siteSegment]: isSiteSegment,
          })}
        />
      ))}
    </>
  );
};

const ConnectedComponent = hoc.connect(
  hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(Component);

const shouldShowChildBoxesSel = (stateMapperArgs: StateMapperArgs) => {
  const { editorAPI, state } = stateMapperArgs;
  const sectionLike =
    editorAPI.sections.getSelectedSection() ||
    editorAPI.sections.getSelectedHeaderFooter();

  const lastClickPos = selection.selectors.getLastSelectionClickPos(state);

  const isFirstTimeParentSectionLikeFocused =
    editorAPI.sections.isFirstTimeParentSectionLikeFocused(sectionLike);

  return (
    (isFirstTimeParentSectionLikeFocused || !lastClickPos) &&
    !isResizingSel(stateMapperArgs) &&
    !editorAPI.zoomMode.isStageZoomMode() &&
    Boolean(sectionLike)
  );
};

export const ChildBoxes = withRestrictions('sections_child-boxes')(
  hoc.withConditionalRender(
    hoc.STORES.EDITOR_API,
    shouldShowChildBoxesSel,
  )(ConnectedComponent),
);
