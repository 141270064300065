'use strict'

module.exports = {
    id: 'add_preset_popups_Subscribe_1',
    structure: {
        type: 'Page',
        id: 'xczjb',
        componentTypeForBI: 'wysiwyg.viewer.components.PopupContainer',
        components: [
            {
                type: 'Container',
                id: 'comp-im0ifjp5',
                components: [
                    {
                        'type': 'Component',
                        'skin': 'svgshape.v2.Svg_001419cc721a440abc4ab12ed150b744',
                        'layout': {
                            'width': 30,
                            'height': 30,
                            'x': 911,
                            'y': 28,
                            'scale': 1,
                            'rotationInDegrees': 0,
                            'fixedPosition': false
                        },
                        'componentType': 'wysiwyg.viewer.components.PopupCloseIconButton',
                        'parent': 'comp-jv6omrug',
                        'metaData': {
                            'pageId': 'yusqm'
                        },
                        'data': {
                            'type': 'SvgShape',
                            'metaData': {
                                'isPreset': false,
                                'schemaVersion': '1.0',
                                'isHidden': false,
                                'pageId': 'yusqm'
                            }
                        },
                        'props': {
                            'type': 'SvgShapeProperties',
                            'metaData': {
                                'schemaVersion': '1.0',
                                'pageId': 'yusqm'
                            },
                            'maintainAspectRatio': true
                        },
                        'mobileHints': {
                            'type': 'MobileHints',
                            'author': 'duplicate',
                            'originalCompId': 'comp-isretmbh',
                            'recommendedScale': 1,
                            'recommendedWidth': 22,
                            'recommendedHeight': 22,
                            'metaData': {
                                'pageId': 'yusqm'
                            }
                        },
                        'style': {
                            'type': 'ComponentStyle',
                            'metaData': {
                                'isPreset': false,
                                'schemaVersion': '1.0',
                                'isHidden': false,
                                'pageId': 'yusqm'
                            },
                            'style': {
                                'properties': {
                                    'alpha-fillcolor': '1',
                                    'alpha-stroke': '1',
                                    'fillcolor': '#FFFFFF',
                                    'stroke': '#0082BD',
                                    'strokewidth': '1'
                                },
                                'propertiesSource': {
                                    'alpha-fillcolor': 'value',
                                    'alpha-stroke': 'value',
                                    'fillcolor': 'value',
                                    'stroke': 'value',
                                    'strokewidth': 'value'
                                },
                                'groups': {}
                            },
                            'componentClassName': 'wysiwyg.viewer.components.PopupCloseIconButton',
                            'pageId': '',
                            'compId': '',
                            'styleType': 'custom',
                            'skin': 'svgshape.v2.Svg_001419cc721a440abc4ab12ed150b744'
                        },
                        'connections': {
                            'type': 'ConnectionList',
                            'items': [
                                {
                                    'type': 'WixCodeConnectionItem',
                                    'role': 'button1'
                                }
                            ],
                            'metaData': {
                                'pageId': 'yusqm'
                            }
                        },
                        'activeModes': {}
                    }
                ],
                skin: 'wysiwyg.viewer.skins.stripContainer.DefaultStripContainer',
                layout: {
                    width: 980,
                    height: 224,
                    x: 0,
                    y: 0,
                    scale: 1,
                    rotationInDegrees: 0,
                    fixedPosition: false
                },
                componentType: 'wysiwyg.viewer.components.PopupContainer',
                props: {
                    type: 'PopupContainerProperties',
                    metaData: {schemaVersion: '1.0'},
                    horizontalAlignment: 'center',
                    verticalAlignment: 'top',
                    alignmentType: 'fullWidth',
                    horizontalOffset: 0,
                    verticalOffset: 0,
                    id: 'propItem-im0ifjp9'
                },
                design: {
                    type: 'MediaContainerDesignData',
                    metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                    background: {
                        type: 'BackgroundMedia',
                        metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                        color: '#0084C1',
                        colorOpacity: 1,
                        alignType: 'center',
                        fittingType: 'fill',
                        scrollType: 'none',
                        colorOverlay: '',
                        colorOverlayOpacity: 0
                    },
                    id: 'dataItem-im0ifjp91'
                },
                style: {
                    type: 'TopLevelStyle',
                    metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                    style: {
                        groups: {},
                        properties: {
                            'alpha-bg': '1',
                            'alpha-brd': '1',
                            bg: '61,155,233,1',
                            'boxShadowToggleOn-shd': 'false',
                            brd: '#324158',
                            brw: '0px',
                            rd: '0px',
                            shd: '0px 1px 4px 0px rgba(0,0,0,0.6)'
                        },
                        propertiesSource: {
                            bg: 'value',
                            brd: 'value',
                            brw: 'value',
                            rd: 'value',
                            shd: 'value'
                        }
                    },
                    componentClassName: 'wysiwyg.viewer.components.PopupContainer',
                    pageId: '',
                    compId: 'i5zemfji_0',
                    styleType: 'custom',
                    skin: 'wysiwyg.viewer.skins.stripContainer.DefaultStripContainer',
                    id: 'style-im0ifjpb'
                },
                behaviors: {
                    type: 'ObsoleteBehaviorsList',
                    items: '[{"name":"FloatIn","delay":0.1,"duration":0.4,"params":{"direction":"top"},"action":"screenIn","targetId":""},{"name":"FloatOut","delay":0.1,"duration":0.4,"params":{"direction":"top"},"action":"exit","targetId":""}]',
                    id: 'behavior-iomlwee8'
                }
            }
        ],

        skin: 'skins.core.InlineSkin',
        layout: {
            width: 980,
            height: 224,
            x: 0,
            y: 0,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false
        },
        componentType: 'mobile.core.components.Page',
        data: {
            type: 'Page',
            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
            title: 'add_preset_popups_Subscribe_1',
            hideTitle: true,
            icon: '',
            descriptionSEO: '',
            metaKeywordsSEO: '',
            pageTitleSEO: '',
            pageUriSEO: 'blank-xczjb',
            hidePage: true,
            underConstruction: false,
            pageSecurity: {requireLogin: false, passwordDigest: '', dialogLanguage: ''},
            isPopup: true,
            indexable: false,
            isLandingPage: false,
            pageBackgrounds: {
                desktop: {
                    ref: {
                        type: 'BackgroundMedia',
                        metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                        color: '#EDEDED',
                        colorOpacity: 0.8,
                        alignType: 'center',
                        fittingType: 'fill',
                        scrollType: 'none',
                        colorOverlay: '',
                        colorOverlayOpacity: 0
                    }
                },
                mobile: {
                    custom: true,
                    ref: {
                        type: 'BackgroundMedia',
                        metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                        color: '#EDEDED',
                        colorOpacity: 0.8,
                        alignType: 'center',
                        fittingType: 'fill',
                        scrollType: 'scroll',
                        colorOverlay: '',
                        colorOverlayOpacity: 0
                    },
                    isPreset: true
                }
            },
            ignoreBottomBottomAnchors: true
        },
        props: {
            type: 'PageProperties',
            metaData: {schemaVersion: '1.0'},
            desktop: {popup: {closeOnOverlayClick: true}},
            mobile: {popup: {closeOnOverlayClick: true}},
            id: 'xczjb'
        },
        style: 'p1'
    },
    preset: {
        rect: {width: 305, height: 202, x: 10, y: 242},
        label: 'add_preset_popups_Subscribe_1',
        tags: null
    }
}
