:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._welcome-page_1jeok_7 {
  text-align: initial;
  padding: 24px; }

._shadowed-container_1jeok_11 {
  overflow: hidden;
  border-radius: 4px;
  box-sizing: border-box;
  box-shadow: 0 1px 8px 0 rgba(0, 6, 36, 0.1);
  border: 1px solid #f7f8f8; }
  ._shadowed-container_1jeok_11:not(:first-child) {
    margin-top: 24px; }
