import { PREMIUM_UPGRADE_FORM_CMS_URL } from '@wix/cms-web-premium-helpers/const';

import { getAppState, selectAppState } from './appState';
import {
  selectTotalCollectionItemCount,
  selectUserCollections,
} from './collections';

import type { EditorAPI } from '@/editorAPI';
import type { EditorState } from '@/stateManagement';
import type { PremiumLimits } from './types';

export enum QuotaStatus {
  APPROACHING = 'APPROACHING',
  EXCEEDED = 'EXCEEDED',
  OK = 'OK',
}

export interface PremiumQuotaStatus {
  collectionsQuotaStatus: QuotaStatus;
  itemsQuotaStatus: QuotaStatus;
}

export const getPremiumQuotaStatus = (
  premiumLimits: PremiumLimits,
  collectionCount: number,
  itemCount: number,
): PremiumQuotaStatus => {
  const getPercentage = (count: number, limit: number) => (count / limit) * 100;

  const getQuotaStatus = (percentage: number) => {
    switch (true) {
      case premiumLimits.itemCountLimit === 0: // for legacy users item count limit is 0
        return QuotaStatus.OK;
      case percentage >= 100:
        return QuotaStatus.EXCEEDED;
      case percentage >= 85:
        return QuotaStatus.APPROACHING;
      default:
        return QuotaStatus.OK;
    }
  };

  const collectionsPercentage = getPercentage(
    collectionCount,
    premiumLimits.collectionCountLimit,
  );

  const itemsPercentage = getPercentage(
    itemCount,
    premiumLimits.itemCountLimit,
  );

  return {
    collectionsQuotaStatus: getQuotaStatus(collectionsPercentage),
    itemsQuotaStatus: getQuotaStatus(itemsPercentage),
  };
};

export const selectHasPremium = (state: EditorState) => {
  const { premium } = selectAppState(state);
  return premium === 'PREMIUM' || premium === 'PREMIUM_MAX_PLAN';
};

export const selectHasMaxPremium = (state: EditorState) => {
  const { premium } = selectAppState(state);
  return premium === 'PREMIUM_MAX_PLAN';
};

export const selectIsPremiumEnabled = (state: EditorState) => {
  const { premium, premiumLimits } = selectAppState(state);
  return premium !== undefined && premiumLimits !== undefined;
};

export const selectPremiumStatus = (state: EditorState) => {
  const { premium } = selectAppState(state);
  return premium;
};

export const selectPremiumLimits = (state: EditorState) => {
  const { premiumLimits } = selectAppState(state);
  return premiumLimits;
};

export const selectPremiumQuotaStatus = (state: EditorState) => {
  const collectionCount = selectUserCollections(state).length;
  const itemCount = selectTotalCollectionItemCount(state);
  const premiumLimits = selectPremiumLimits(state);

  return premiumLimits
    ? getPremiumQuotaStatus(premiumLimits, collectionCount, itemCount)
    : undefined;
};

export const selectPremiumNotificationType = (state: EditorState) => {
  const quotaStatus = selectPremiumQuotaStatus(state);
  if (!quotaStatus) {
    return undefined;
  }
  const { collectionsQuotaStatus, itemsQuotaStatus } = quotaStatus;
  if (
    collectionsQuotaStatus === QuotaStatus.EXCEEDED ||
    itemsQuotaStatus === QuotaStatus.EXCEEDED
  ) {
    return QuotaStatus.EXCEEDED;
  }
  if (
    collectionsQuotaStatus === QuotaStatus.APPROACHING ||
    itemsQuotaStatus === QuotaStatus.APPROACHING
  ) {
    return QuotaStatus.APPROACHING;
  }
  return QuotaStatus.OK;
};

export const openPremiumUpgradeFlow = (
  editorAPI: EditorAPI,
  referralAdditionalInfo: string,
) => {
  const { isPremiumBusinessElite } = getAppState(editorAPI);

  if (isPremiumBusinessElite) {
    window.open(PREMIUM_UPGRADE_FORM_CMS_URL, '_blank', 'noopener');
    return;
  }

  editorAPI.account.upgrade(referralAdditionalInfo);
};
