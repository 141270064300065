'use strict'

module.exports = {
    id: 'Columns_ColumnsContact_2',
    structure: {
        type: 'Container',
        components: [
            {
                type: 'Container',
                components: [
                    {
                        type: 'Component',
                        layout: {
                            width: 434,
                            height: 51,
                            x: 28,
                            y: 122,
                            scale: 1,
                            rotationInDegrees: 0,
                            fixedPosition: false
                        },
                        componentType: 'wysiwyg.viewer.components.WRichText',
                        data: {
                            type: 'StyledText',
                            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                            text: '<h2 class="font_2" style="font-size:80px; line-height:0.5em; text-align:center"><span style="color:#BC9B5D"><span style="font-size:80px"><span style="font-family:peaches-and-cream-regular-w00,script">Get in Touch</span></span></span></h2>\n',
                            stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
                            linkList: []
                        },
                        props: {
                            type: 'WRichTextProperties',
                            metaData: {schemaVersion: '1.0'},
                            brightness: 1,
                            packed: true
                        },
                        style: 'txtNew',
                        mobileStructure: {
                            type: 'Component',
                            layout: {
                                width: 263,
                                height: 38,
                                x: 29,
                                y: 43,
                                scale: 1.3400956406250004,
                                rotationInDegrees: 0,
                                fixedPosition: false
                            },
                            componentType: 'wysiwyg.viewer.components.WRichText',
                            data: {
                                type: 'StyledText',
                                metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                                text: '<h2 class="font_2" style="font-size:80px; line-height:0.5em; text-align:center"><span style="color:#BC9B5D"><span style="font-size:80px"><span style="font-family:peaches-and-cream-regular-w00,script">Get in Touch</span></span></span></h2>\n',
                                stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
                                linkList: []
                            },
                            props: {
                                type: 'WRichTextProperties',
                                metaData: {schemaVersion: '1.0', autoGenerated: false},
                                brightness: 1,
                                packed: true
                            },
                            style: 'txtNew'
                        }
                    },
                    {
                        type: 'Component',
                        layout: {
                            width: 490,
                            height: 44,
                            x: 1,
                            y: 216,
                            scale: 1,
                            rotationInDegrees: 0,
                            fixedPosition: false
                        },
                        componentType: 'wysiwyg.viewer.components.WRichText',
                        data: {
                            type: 'StyledText',
                            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                            text: '<p class="font_8" style="line-height:1.4em; text-align:center"><span style="font-family:futura-lt-w01-light,sans-serif">500 Terry Francois Street, San Francisco, CA 94158</span></p>\n\n<p class="font_8" style="line-height:1.4em; text-align:center"><span style="font-family:futura-lt-w01-light,sans-serif">info@mysite.com &nbsp;<span style="color:#BC9B5D">/</span> &nbsp;Tel. 123-456-7890</span></p>\n',
                            stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
                            linkList: []
                        },
                        props: {
                            type: 'WRichTextProperties',
                            metaData: {schemaVersion: '1.0'},
                            brightness: 1,
                            packed: true
                        },
                        style: 'txtNew',
                        mobileStructure: {
                            type: 'Component',
                            layout: {
                                width: 320,
                                height: 76,
                                x: 0,
                                y: 108,
                                scale: 0.897993140625,
                                rotationInDegrees: 0,
                                fixedPosition: false
                            },
                            componentType: 'wysiwyg.viewer.components.WRichText',
                            data: {
                                type: 'StyledText',
                                metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                                text: '<p class="font_8" style="line-height:1.4em; text-align:center"><span style="font-family:futura-lt-w01-light,sans-serif">500 Terry Francois Street, San Francisco, CA 94158</span></p>\n\n<p class="font_8" style="line-height:1.4em; text-align:center"><span style="font-family:futura-lt-w01-light,sans-serif">info@mysite.com &nbsp;<span style="color:#BC9B5D">/</span> &nbsp;Tel. 123-456-7890</span></p>\n',
                                stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
                                linkList: []
                            },
                            props: {
                                type: 'WRichTextProperties',
                                metaData: {schemaVersion: '1.0', autoGenerated: false},
                                brightness: 1,
                                packed: true
                            },
                            style: 'txtNew'
                        }
                    }
                ],
                layout: {
                    width: 490,
                    height: 652,
                    x: -90,
                    y: 0,
                    scale: 1,
                    rotationInDegrees: 0,
                    fixedPosition: false
                },
                componentType: 'wysiwyg.viewer.components.Column',
                props: {type: 'ColumnProperties', metaData: {schemaVersion: '1.0'}, alignment: 50},
                design: {
                    type: 'MediaContainerDesignData',
                    metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                    background: {
                        type: 'BackgroundMedia',
                        metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                        color: '#FCF8ED',
                        colorOpacity: 1,
                        alignType: 'center',
                        fittingType: 'fill',
                        scrollType: 'none',
                        colorOverlay: '',
                        colorOverlayOpacity: 0
                    }
                },
                style: 'mc1',
                mobileStructure: {
                    type: 'Container',
                    layout: {
                        width: 320,
                        height: 506,
                        x: 0,
                        y: 0,
                        scale: 1,
                        rotationInDegrees: 0,
                        fixedPosition: false
                    },
                    componentType: 'wysiwyg.viewer.components.Column',
                    props: {
                        type: 'ColumnProperties',
                        metaData: {schemaVersion: '1.0'},
                        alignment: 50
                    },
                    design: {
                        type: 'MediaContainerDesignData',
                        metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                        background: {
                            type: 'BackgroundMedia',
                            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                            color: '#FCF8ED',
                            colorOpacity: 1,
                            alignType: 'center',
                            fittingType: 'fill',
                            scrollType: 'none',
                            colorOverlay: '',
                            colorOverlayOpacity: 0
                        }
                    },
                    style: 'mc1'
                }
            },
            {
                type: 'Container',
                components: [],
                layout: {
                    width: 490,
                    height: 652,
                    x: 580,
                    y: 0,
                    scale: 1,
                    rotationInDegrees: 0,
                    fixedPosition: false
                },
                componentType: 'wysiwyg.viewer.components.Column',
                props: {type: 'ColumnProperties', metaData: {schemaVersion: '1.0'}, alignment: 50},
                design: {
                    type: 'MediaContainerDesignData',
                    metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                    background: {
                        type: 'BackgroundMedia',
                        metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                        mediaRef: {
                            type: 'Image',
                            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                            title: 'shutterstock_70830589.jpg',
                            uri: '035244_cd7894c5fc3c435095f0ed6510e504d3.jpg',
                            description: 'private/',
                            width: 6048,
                            height: 4032,
                            alt: '',
                            artist: {id: '', name: ''}
                        },
                        color: '{color_18}',
                        colorOpacity: 1,
                        alignType: 'center',
                        fittingType: 'fill',
                        scrollType: 'none',
                        colorOverlay: '',
                        colorOverlayOpacity: 0
                    }
                },
                style: 'mc1',
                mobileStructure: {
                    type: 'Container',
                    layout: {
                        width: 320,
                        height: 231,
                        x: 0,
                        y: 506,
                        scale: 1,
                        rotationInDegrees: 0,
                        fixedPosition: false
                    },
                    componentType: 'wysiwyg.viewer.components.Column',
                    props: {
                        type: 'ColumnProperties',
                        metaData: {schemaVersion: '1.0'},
                        alignment: 50
                    },
                    design: {
                        type: 'MediaContainerDesignData',
                        metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                        background: {
                            type: 'BackgroundMedia',
                            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                            mediaRef: {
                                type: 'Image',
                                metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                                title: 'shutterstock_70830589.jpg',
                                uri: '035244_cd7894c5fc3c435095f0ed6510e504d3.jpg',
                                description: 'private/',
                                width: 6048,
                                height: 4032,
                                alt: '',
                                artist: {id: '', name: ''}
                            },
                            color: '{color_18}',
                            colorOpacity: 1,
                            alignType: 'center',
                            fittingType: 'fill',
                            scrollType: 'none',
                            colorOverlay: '',
                            colorOverlayOpacity: 0
                        }
                    },
                    style: 'mc1'
                }
            }
        ],
        layout: {
            width: 980,
            height: 652,
            x: 0,
            y: 955,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false
        },
        componentType: 'wysiwyg.viewer.components.StripColumnsContainer',
        props: {
            type: 'StripColumnsContainerProperties',
            metaData: {schemaVersion: '1.0'},
            fullWidth: true,
            columnsMargin: 0,
            frameMargin: 0,
            rowMargin: 0,
            siteMargin: 0
        },
        design: {
            type: 'MediaContainerDesignData',
            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
            background: {
                type: 'BackgroundMedia',
                metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                color: '#FFFFFF',
                colorOpacity: 0,
                alignType: 'center',
                fittingType: 'fill',
                scrollType: 'none',
                colorOverlay: '',
                colorOverlayOpacity: 1
            },
            charas: 'design-iv109xyg'
        },
        style: {
            type: 'TopLevelStyle',
            id: 'strc1',
            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
            style: {groups: {}, properties: {}, propertiesSource: {}},
            componentClassName: 'wysiwyg.viewer.components.StripColumnsContainer',
            pageId: '',
            compId: '',
            styleType: 'system',
            skin: 'wysiwyg.viewer.skins.stripContainer.DefaultStripContainer'
        },
        mobileStructure: {
            type: 'Container',
            layout: {
                width: 320,
                height: 737,
                x: 0,
                y: 2337,
                scale: 1,
                rotationInDegrees: 0,
                fixedPosition: false
            },
            componentType: 'wysiwyg.viewer.components.StripColumnsContainer',
            props: {
                type: 'StripColumnsContainerProperties',
                metaData: {schemaVersion: '1.0'},
                fullWidth: true,
                columnsMargin: 0,
                frameMargin: 0,
                rowMargin: 0,
                siteMargin: 0
            },
            design: {
                type: 'MediaContainerDesignData',
                metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                background: {
                    type: 'BackgroundMedia',
                    metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                    color: '#FFFFFF',
                    colorOpacity: 0,
                    alignType: 'center',
                    fittingType: 'fill',
                    scrollType: 'none',
                    colorOverlay: '',
                    colorOverlayOpacity: 1
                },
                charas: 'design-iv109xyf'
            },
            style: 'strc1'
        },
        activeModes: {},
        id: 'comp-ioin6kb8'
    },
    preset: {
        rect: {width: 324, height: 170.4, x: -0.8, y: 171},
        label: 'add_preset_columns_contact_2',
        tags: null
    }
}
