import React from 'react';
import _ from 'lodash';
import * as stateManagement from '@/stateManagement';
import * as util from '@/util';
import { utils as themeUtils } from '@/theme';
import * as coreBi from '@/coreBi';
import type { ThunkAction } from 'types/redux';

export enum SupportedCSSProps {
  FontStyle = 'fontStyle',
  FontWeight = 'fontWeight',
  TextDecoration = 'textDecoration',
  Color = 'color',
  BackgroundColor = 'backgroundColor',
  LetterSpacing = 'letterSpacing',
  LineHeight = 'lineHeight',
}

const { getVariantId } = stateManagement.interactions.selectors;

interface ISupportedCSSValueByType {
  [SupportedCSSProps.FontStyle]: 'italic';
  [SupportedCSSProps.FontWeight]: 'bold';
  [SupportedCSSProps.TextDecoration]: 'underline';
  [SupportedCSSProps.Color]: string;
  [SupportedCSSProps.BackgroundColor]: string;
  [SupportedCSSProps.LetterSpacing]: string;
  [SupportedCSSProps.LineHeight]: string;
}

const SupportedCSSValueByType: Pick<
  ISupportedCSSValueByType,
  | SupportedCSSProps.FontStyle
  | SupportedCSSProps.FontWeight
  | SupportedCSSProps.TextDecoration
> = {
  [SupportedCSSProps.FontStyle]: 'italic',
  [SupportedCSSProps.FontWeight]: 'bold',
  [SupportedCSSProps.TextDecoration]: 'underline',
};

const styleHasChanges = ({
  style: { properties, propertiesSource },
}: AnyFixMe) =>
  Object.values(SupportedCSSProps).some(
    (key) =>
      properties[key] !== undefined || propertiesSource[key] !== undefined,
  );

const getEditorAPI: ThunkAction = (dispatch, getState, { editorAPI }) => ({
  editorAPI,
  state: getState(),
});

function updateOrRemoveProperty<T extends SupportedCSSProps, K>(
  styleDef: any,
  propertyName: T,
  value?: K,
) {
  if (!value) {
    styleDef.style.properties = _.omit(styleDef.style.properties, propertyName);
    styleDef.style.propertiesSource = _.omit(
      styleDef.style.propertiesSource,
      propertyName,
    );
  } else {
    styleDef.style.properties = {
      ...styleDef.style.properties,
      [propertyName]: value,
    };

    styleDef.style.propertiesSource = {
      ...styleDef.style.propertiesSource,
      // TODO: specify the right property source
      [propertyName]: 'value',
    };
  }

  return styleDef;
}

function getPropertyChangedBiLogger(
  editorAPI: AnyFixMe,
  componentDefaults: {
    component_id: string;
    component_type: string;
  },
) {
  return (parameterName: SupportedCSSProps) => {
    editorAPI.bi.event(
      coreBi.events.interactions.interaction_mode_text_style_changing,
      {
        ...componentDefaults,
        parameter_name: parameterName,
      },
    );
  };
}

const mapStateToProps = ({ editorAPI }: AnyFixMe) => {
  const state = editorAPI.store.getState();
  const targetCompRefWithVariant =
    stateManagement.interactions.selectors.getCompVariantPointer(state);
  const styleDef = editorAPI.components.style.get(targetCompRefWithVariant);
  const color = styleDef.style.properties[SupportedCSSProps.Color];
  const colorValue = themeUtils.isThemeColor(color)
    ? editorAPI.theme.colors.get(color)
    : color;
  const backgroundColor =
    styleDef.style.properties[SupportedCSSProps.BackgroundColor];
  const backColorValue = themeUtils.isThemeColor(backgroundColor)
    ? editorAPI.theme.colors.get(backgroundColor)
    : backgroundColor;
  return {
    isBold:
      styleDef.style.properties[SupportedCSSProps.FontWeight] ===
      SupportedCSSValueByType[SupportedCSSProps.FontWeight],
    isItalic:
      styleDef.style.properties[SupportedCSSProps.FontStyle] ===
      SupportedCSSValueByType[SupportedCSSProps.FontStyle],
    isUnderline:
      styleDef.style.properties[SupportedCSSProps.TextDecoration] ===
      SupportedCSSValueByType[SupportedCSSProps.TextDecoration],
    color,
    colorValue,
    backColorValue,
    backgroundColor:
      styleDef.style.properties[SupportedCSSProps.BackgroundColor],
    letterSpacing: styleDef.style.properties[SupportedCSSProps.LetterSpacing]
      ? parseFloat(styleDef.style.properties[SupportedCSSProps.LetterSpacing])
      : 0,
    lineHeight:
      styleDef.style.properties[SupportedCSSProps.LineHeight] &&
      parseFloat(styleDef.style.properties[SupportedCSSProps.LineHeight]),
    styleDef,
  };
};

const mapDispatchToProps = (dispatch: AnyFixMe) => {
  const { editorAPI, state } = dispatch(getEditorAPI);

  const targetCompRefWithVariant =
    stateManagement.interactions.selectors.getCompVariantPointer(state);

  const targetCompRef = editorAPI.components.get.byId(
    targetCompRefWithVariant.id,
  );
  const biDefaults = {
    component_type: editorAPI.components.getType(targetCompRefWithVariant),
    component_id: targetCompRefWithVariant.id,
    interaction_id: getVariantId(editorAPI.store.getState()),
  };

  const logPropertyChangedBI = getPropertyChangedBiLogger(
    editorAPI,
    biDefaults,
  );

  function updateVariantStyle<
    T extends SupportedCSSProps = SupportedCSSProps,
    K = ISupportedCSSValueByType[T],
  >(propertyName: T, value?: K) {
    const styleDef = editorAPI.components.style.get(targetCompRefWithVariant);
    const updatedStyle = updateOrRemoveProperty<T, K>(
      styleDef,
      propertyName,
      value,
    );
    if (styleHasChanges(updatedStyle)) {
      editorAPI.components.style.update(targetCompRefWithVariant, updatedStyle);
    } else {
      editorAPI.components.style.scoped.remove(targetCompRefWithVariant);
    }
    // NOTE: transition API expects compRef and not compWithPointer ref
    stateManagement.interactions.actions.setDefaultTransition(
      editorAPI,
      targetCompRef,
    );
    logPropertyChangedBI(propertyName);
  }

  return {
    italicChanged(isChecked: boolean) {
      const value = isChecked
        ? SupportedCSSValueByType[SupportedCSSProps.FontStyle]
        : undefined;
      updateVariantStyle<SupportedCSSProps.FontStyle>(
        SupportedCSSProps.FontStyle,
        value,
      );
    },
    boldChanged(isChecked: boolean) {
      const value = isChecked
        ? SupportedCSSValueByType[SupportedCSSProps.FontWeight]
        : undefined;
      updateVariantStyle<SupportedCSSProps.FontWeight>(
        SupportedCSSProps.FontWeight,
        value,
      );
    },
    underlineChanged(isChecked: boolean) {
      const value = isChecked
        ? SupportedCSSValueByType[SupportedCSSProps.TextDecoration]
        : undefined;
      updateVariantStyle<SupportedCSSProps.TextDecoration>(
        SupportedCSSProps.TextDecoration,
        value,
      );
    },
    colorChanged(color?: string) {
      updateVariantStyle<SupportedCSSProps.Color>(
        SupportedCSSProps.Color,
        color,
      );
    },
    backgroundColorChanged(color?: string) {
      updateVariantStyle<SupportedCSSProps.BackgroundColor>(
        SupportedCSSProps.BackgroundColor,
        color,
      );
    },
    letterSpacingChanged(spacing: number) {
      const value = spacing ? `${spacing}em` : '';
      updateVariantStyle<SupportedCSSProps.LetterSpacing>(
        SupportedCSSProps.LetterSpacing,
        value,
      );
    },
    lineHeightChanged(height: number | 'auto') {
      const value = _.isNumber(height) ? `${height}em` : undefined;
      updateVariantStyle<SupportedCSSProps.LineHeight>(
        SupportedCSSProps.LineHeight,
        value,
      );
    },
    sendBeforePanelClosedBi() {
      editorAPI.bi.event(
        coreBi.events.interactions.interaction_mode_text_style_changed,
        biDefaults,
      );
    },
  };
};

export function interactionSettingsHOC(BaseComp: AnyFixMe) {
  const TextInteractionSettingsHOC = (props: AnyFixMe) => (
    <BaseComp {...props} />
  );
  return util.hoc.connect(
    util.hoc.STORES.EDITOR_API,
    mapStateToProps,
    mapDispatchToProps,
  )(TextInteractionSettingsHOC);
}
