import React from 'react';
import { NotificationBanner } from '@wix/wix-base-ui';

import styles from './Banner.scss';

type BannerType = 'standard' | 'premium';

export interface BannerProps {
  type: BannerType;
  text: string;
  link: {
    label: string;
    onClick: (event: React.MouseEvent) => void;
  };
}

export const Banner: React.FC<BannerProps> = ({ type, link, text }) => (
  <NotificationBanner
    className={styles.container}
    skin={type}
    text={text}
    link={link}
    shouldTranslate={false}
  />
);
