import React from 'react';
import styles from './styles/innerDrillIn.scss';
import { PanelHeader } from '@wix/wix-base-ui';
import { QUICK_EDIT_PANEL_WIDTH } from '../consts';

interface InnerDrillInProps {
  mainPanelTitle: string;
  zIndex: number;
  isOpened: boolean;
  onBack: () => void;
  panelContent: any[];
  drillInPanelTitle?: string;
  onBackTooltip?: string;
}

export const InnerDrillIn: React.FC<InnerDrillInProps> = ({
  mainPanelTitle,
  zIndex,
  isOpened,
  onBack,
  panelContent,
  drillInPanelTitle,
  onBackTooltip,
}) => {
  return (
    <>
      {panelContent.map((slot, index) => (
        <div
          key={`innerDrillIn${index}`}
          style={{
            width: QUICK_EDIT_PANEL_WIDTH,
            transform: `translateX(${isOpened ? 0 : QUICK_EDIT_PANEL_WIDTH}px)`,
            opacity: isOpened ? 1 : 0,
            zIndex,
          }}
          className={styles.innerDrillInPanel}
          data-hook={`quick-edit-inner-drill-in-${index}`}
        >
          <PanelHeader
            className="dragger dark"
            defaultCursor
            noCloseBtn={true}
            noHelpBtn={true}
            {...(onBack ? { onBack } : {})}
            backTooltipContent={onBackTooltip}
            shouldTranslate={false}
          >
            {drillInPanelTitle ?? mainPanelTitle}
          </PanelHeader>
          <div className={styles.innerDrillInContent}>
            <slot.contribution />
          </div>
        </div>
      ))}
    </>
  );
};
