import type { EntryPoint } from '@/apilib';
import { EditorAPIKey, HomePageApiKey, PagesApiKey } from '@/apis';
import { createHomePageApi } from './homePageApi';

export const HomePageApiEntryPoint: EntryPoint = {
  name: 'HomePageApi',
  declareAPIs: () => [HomePageApiKey],
  getDependencyAPIs: () => [EditorAPIKey, PagesApiKey],
  attach(shell) {
    shell.contributeAPI(HomePageApiKey, () => createHomePageApi(shell));
  },
};
