import React from 'react';
import { Button, Divider, PromotionalList, TextLabel } from '@wix/wix-base-ui';
import * as symbols from '@wix/santa-editor-symbols';
import * as util from '@/util';
import { translate } from '@/i18n';
import * as stateManagement from '@/stateManagement';
import ascendBi from '../common/bi/ascendBi';

interface UpgradeAscendPanelProps {
  superApp: any;
  msid: string;
  ascendPlanText: string;
  premiumPlanText: string;
  sendBi(event: object, parameters: object): void;
  onClickUpgradeButton(msid: string): void;
  biBaseParams: any;
}

class UpgradeAscendPanel extends React.Component<UpgradeAscendPanelProps> {
  componentDidMount() {
    const { sendBi, biBaseParams } = this.props;

    sendBi(ascendBi.events.TAB_VIEW, {
      ...biBaseParams,
      tab_name: 'Upgrade',
    });
  }

  render() {
    const {
      msid,
      ascendPlanText,
      premiumPlanText,
      sendBi,
      onClickUpgradeButton,
      biBaseParams,
      superApp: {
        upgradeProps: { promotionalItems, buttonText },
      },
    } = this.props;
    const upgradeAscendLogo = util.workspace.isNewWorkspaceEnabled()
      ? 'upgradeAscendLogo_NewWorkspace'
      : 'upgradeAscendLogo';

    return (
      <div className="super-app-extra-panel">
        <div className="upgrade-ascend-panel">
          <div className="body">
            <symbols.symbol name={upgradeAscendLogo} className="logo" />
            <div className="title">
              <TextLabel
                value="AscendButton_UpradeAscend_BusinessTools_Title"
                type="T16"
                enableEllipsis={false}
              />
            </div>
            <PromotionalList
              symbol={React.createElement(symbols.symbol, {
                name: 'check',
                style: {
                  fill: '#aa4dc8',
                  marginRight: '6px',
                  height: '10px',
                  width: '11px',
                  padding: '4px 0 0 5px',
                },
              })}
              items={promotionalItems}
              automationId="upgrade_ascend_promotional_list"
            />
            <div>
              <Button
                onClick={() => {
                  sendBi(ascendBi.events.ASCEND_UPGRADE_CLICK, {
                    ...biBaseParams,
                    tab_name: 'Upgrade',
                  });
                  onClickUpgradeButton(msid);
                }}
                className="upgrade-button"
              >
                <symbols.symbol
                  name="ascendLogoButton"
                  className="button-logo"
                />
                {buttonText}
              </Button>
            </div>
          </div>
          <div className="footer">
            <Divider dataHook="footer-divider" long />
            <div className="plans">
              <div className="plan-box">
                <TextLabel
                  type="T15"
                  value="AscendButton_UpgradeAscend_Footer_AscendPlan_Title"
                />
                <TextLabel
                  dataHook="upgrade-ascend-ascend-plan"
                  type="T07"
                  value={ascendPlanText}
                  shouldTranslate={false}
                />
              </div>
              <div className="footer-divider" />
              <div className="plan-box">
                <TextLabel
                  type="T15"
                  value="AscendButton_FooterText_WebsitePlan_Title"
                />
                <TextLabel
                  dataHook="upgrade-ascend-premium-plan"
                  type="T07"
                  value={premiumPlanText}
                  shouldTranslate={false}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const getEditorAPI = (
  dispatch: AnyFixMe,
  getState: AnyFixMe,
  { editorAPI }: AnyFixMe,
) => editorAPI;

const mapStateToProps = ({ editorAPI, state }: AnyFixMe) => {
  return {
    msid: editorAPI.dsRead.generalInfo.getMetaSiteId(),
    ascendPlanText:
      stateManagement.ascend.selectors.getAscendPlan(state) ||
      translate('AscendButton_UpgradeAscend_Footer_AscendPlan_Free_Label'),
    premiumPlanText:
      stateManagement.ascend.selectors.getPremiumPlan(state) ||
      translate('AscendButton_UpgradeAscend_Footer_WebsitePlan_Free_Label'),
    biBaseParams: ascendBi.biUtils.getBiBaseParams({ editorAPI, state }),
  };
};

const mapDispatchToProps = (dispatch: AnyFixMe, ownProps: AnyFixMe) => {
  const {
    superApp: {
      upgradeProps: { onClickUpgradeButton },
    },
  } = ownProps;
  const editorAPI = dispatch(getEditorAPI);
  return {
    sendBi: (event: AnyFixMe, parameters: AnyFixMe) =>
      dispatch(stateManagement.bi.actions.event(event, parameters)),
    onClickUpgradeButton: (msid: AnyFixMe) =>
      onClickUpgradeButton(editorAPI, msid),
  };
};

const ConnectedComponent = util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(UpgradeAscendPanel);
ConnectedComponent.pure = UpgradeAscendPanel;

export default ConnectedComponent;
