import * as stateManagement from '@/stateManagement';
import type {
  MapStateToProps,
  MapDispatchToProps,
  ThunkAction,
} from 'types/redux';
import type {
  SaveButtonDispatchProps,
  SaveButtonOwnProps,
  SaveButtonStateProps,
} from './save';
import type { SaveInteractionStartedSource } from 'types/fedops/saveInteraction';
import { fixedStage } from '@/util';

const { getIsManualSave, getIsBuildInProgress } =
  stateManagement.savePublish.selectors;

export const mapStateToProps: MapStateToProps<
  SaveButtonStateProps,
  SaveButtonOwnProps
> = ({ editorAPI, state }) => {
  const disabled =
    editorAPI.savePublish.isPublishInProgress() ||
    editorAPI.savePublish.isSaveInProgress() ||
    getIsBuildInProgress(state);

  return {
    disabled,
    isManualSave: getIsManualSave(state),
    status: state.savingStatus,
    isZoomMode: editorAPI.zoomMode.isInZoomMode(),
    isSitePublished: editorAPI.dsRead?.generalInfo.isSitePublished(),
    topBarStateBIParamValue: fixedStage.getTopBarStateBiParamValue(editorAPI),
  };
};

export const mapDispatchToProps: MapDispatchToProps<
  SaveButtonDispatchProps,
  SaveButtonOwnProps
> = {
  save:
    (sourceOfStart: SaveInteractionStartedSource): ThunkAction =>
    (dispatch, getState, { editorAPI }) => {
      editorAPI.saveManager.save({ origin: 'topbar', sourceOfStart });
    },
};
