import constants from '@/constants';
import type { EditorAPI } from '@/editorAPI';
import type { ComponentEditorMetaDataDefinition } from '../types';
import type { CompRef } from 'types/documentServices';
import experiments from 'experiment';
import { EditorParamsApiKey } from '@/apis';

const hasResponsiveLayout = ({ dsRead }: EditorAPI, compRef: CompRef) =>
  Boolean(dsRead.components.responsiveLayout.get(compRef));

const getOverridesWhenReferred = (editorAPI: EditorAPI, compRef: CompRef) => {
  const gfpp = hasResponsiveLayout(editorAPI, compRef)
    ? {
        disableActions: [constants.ROOT_COMPS.GFPP.ACTIONS.LAYOUT],
      }
    : {
        disableMainActionsByLabelKey: ['gfpp_mainaction_listsandgrids'],
        presetToBeMainAction: constants.ROOT_COMPS.GFPP.ACTIONS.LAYOUT,
      };

  return {
    gfpp,
  };
};
const getSkinParamsToIgnore = (editorAPI: EditorAPI) => {
  const isResponsiveEditor =
    editorAPI.host.getAPI(EditorParamsApiKey).isInsideEditorX;
  const isEditorX = experiments.isOpen(
    'specs.responsive-editor.filterRepeaterDesignParamsOnEditorX',
  );
  const isBlocks = experiments.isOpen(
    'specs.responsive-blocks.filterRepeaterDesignParamsOnBlocks',
  );
  return isResponsiveEditor && (isEditorX || isBlocks)
    ? ['shd', 'brw', 'rd', 'brd']
    : [];
};

const metaData: ComponentEditorMetaDataDefinition = {
  selectedBeforeDescendants: true,
  focusable: true,
  allowConnectToDB: true,
  overridesWhenReferred: getOverridesWhenReferred,
  skinParamsToIgnore: getSkinParamsToIgnore,
};

export default metaData;
