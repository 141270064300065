import _ from 'lodash';

import { layoutUtils } from '@/layoutUtils';
import { interactions } from '@/stateManagement';
import * as helpIds from '@/helpIds';
import type { CompLayout, CompRef } from 'types/documentServices';
import type { EditorAPI } from '@/editorAPI';
import type { StateMapperArgs, ThunkAction } from 'types/redux';
import {
  selectedComponentsProportionsSel,
  componentLayoutLimitsSel,
  areComponentsMultiSelectedOrGroupedSel,
  compRestrictionsSel,
  componentsWithoutAncestorSel,
  selectedCompsSel,
  selectedCompTypeSel,
  isHeaderFrozenSel,
  isFooterFrozenSel,
} from './toolBarSelectors';
import { showUserActionNotification } from './commonMappers';
import { relativeToStructureOrigLayoutSel } from '../selectors/layoutSelectors';
import { getStateMapperArgsFromEditorAPI } from '../selectors/rootSelectors';

function getLayoutValueFromBoundingValue(
  compLayout: CompLayout,
  key: keyof CompLayout['bounding'],
  boundingValue: number,
) {
  return compLayout[key] + boundingValue - compLayout.bounding[key];
}

const shouldShowNotificationFreezeWarning = (
  mapperArgs: StateMapperArgs,
  height: number,
) => {
  return (
    height > 200 &&
    (isHeaderFrozenSel(mapperArgs) || isFooterFrozenSel(mapperArgs))
  );
};

//used in tests
export function getLayoutUpdaters(
  editorAPI: EditorAPI,
  selectedComponents: CompRef[],
) {
  if (!selectedComponents) {
    return {
      update: _.noop,
      updateAndPreserveProportions: _.noop,
    };
  }

  const shouldUseLayoutRelativeToScreen =
    editorAPI.components.layout.isHorizontallyStretchedToScreen(
      selectedComponents,
    );

  const primaryContainer = editorAPI.pages.getPrimaryContainer();

  const shouldNormalizeToPrimaryContainer =
    !editorAPI.utils.isPage(primaryContainer);

  const primaryContainerLayout = shouldUseLayoutRelativeToScreen
    ? editorAPI.components.layout.getRelativeToScreen(primaryContainer)
    : editorAPI.components.layout.getRelativeToStructure(primaryContainer);

  const updateLayoutRelativeToPrimaryContainer = (updateFunc: AnyFixMe) =>
    shouldNormalizeToPrimaryContainer
      ? (compRefs: AnyFixMe, newLayout: AnyFixMe, ...rest: AnyFixMe[]) => {
          updateFunc(
            compRefs,
            _({
              x: newLayout.x + primaryContainerLayout.x,
              y: newLayout.y + primaryContainerLayout.y,
            })
              // eslint-disable-next-line you-dont-need-lodash-underscore/keys
              .pick(_.keys(newLayout))
              .defaultsDeep(newLayout)
              .value(),
            ...rest,
          );
        }
      : updateFunc;

  return shouldUseLayoutRelativeToScreen
    ? {
        updateLayout: updateLayoutRelativeToPrimaryContainer(
          editorAPI.components.layout.updateRelativeToScreen,
        ),
        updateAndPreserveProportions: updateLayoutRelativeToPrimaryContainer(
          editorAPI.components.layout
            .updateRelativeToScreenAndPreserveProportions,
        ),
      }
    : {
        updateLayout: updateLayoutRelativeToPrimaryContainer(
          editorAPI.components.layout.updateRelativeToStructure,
        ),
        updateAndPreserveProportions: updateLayoutRelativeToPrimaryContainer(
          editorAPI.components.layout
            .updateRelativeToStructureAndPreserveProportions,
        ),
      };
}

const showNotificationFreezeWarning =
  (): ThunkAction =>
  (dispatch, getState, { editorAPI }) => {
    const componentType = selectedCompTypeSel(
      getStateMapperArgsFromEditorAPI(editorAPI),
    );

    const helpId =
      componentType === 'HeaderContainer'
        ? helpIds.NOTIFICATIONS.FROZEN_HEADER
        : helpIds.NOTIFICATIONS.FROZEN_FOOTER;
    const key =
      componentType === 'HeaderContainer'
        ? 'Notifications_Frozen_Header_Text'
        : 'Notifications_Frozen_Footer_Text';

    const onNotificationLinkClick = () => {
      editorAPI.panelManager.openHelpCenter(helpId, null);
    };

    dispatch(
      showUserActionNotification({
        message: key,
        title: key,
        type: 'warning',
        link: {
          caption: 'Notifications_Learn_More_Link',
          onNotificationLinkClick,
        },
      }),
    );
  };

export const setComponentLayoutChange =
  (key: keyof CompLayout, value: number): ThunkAction =>
  (dispatch, getState, { editorAPI }) => {
    const state = getState();
    const mapperArgs = getStateMapperArgsFromEditorAPI(editorAPI);

    const currentLayout = relativeToStructureOrigLayoutSel(mapperArgs);

    if (key === 'y' || key === 'x') {
      value = getLayoutValueFromBoundingValue(currentLayout, key, value);
    }

    if (currentLayout[key] === value) {
      return;
    }

    const componentsProportions = selectedComponentsProportionsSel(mapperArgs);
    const componentLayoutLimits = componentLayoutLimitsSel(mapperArgs);
    const areComponentsMultiSelectedOrGrouped =
      areComponentsMultiSelectedOrGroupedSel(mapperArgs);
    const componentsRestrictions = compRestrictionsSel(mapperArgs);
    const components = componentsWithoutAncestorSel(mapperArgs);
    const selectedComps = selectedCompsSel(mapperArgs);

    let newLayout: Partial<CompLayout> = {};
    //@ts-expect-error
    newLayout[key] = value;
    if (
      interactions.selectors.isInInteractionMode(state) &&
      !layoutUtils.canMoveByInteractionsLayoutConstraints(
        editorAPI,
        selectedComps,
        Object.assign({}, currentLayout, newLayout),
      )
    ) {
      return;
    }

    let aspectRatio;

    const { updateAndPreserveProportions, updateLayout } = getLayoutUpdaters(
      editorAPI,
      selectedComps,
    );

    if (
      areComponentsMultiSelectedOrGrouped &&
      ['height', 'width'].includes(key)
    ) {
      aspectRatio = currentLayout.width / currentLayout.height;
      if (key === 'height') {
        newLayout.width = value * aspectRatio;
      } else if (key === 'width') {
        newLayout.height = value / aspectRatio;
      }
      newLayout = _.mapValues(newLayout, _.round);
      updateAndPreserveProportions(
        components,
        newLayout,
        componentsProportions,
        true,
      );
    } else {
      if (
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/includes
        _.includes(['width', 'height'], key) &&
        componentsRestrictions.resizeOnlyProportionally
      ) {
        aspectRatio = componentLayoutLimits.aspectRatio;
        if (key === 'width') {
          newLayout.width = value;
          newLayout.height = Math.round(value / aspectRatio);
        } else {
          newLayout.height = value;
          newLayout.width = value * aspectRatio;
        }
      }

      updateLayout(components, newLayout, true);

      if (shouldShowNotificationFreezeWarning(mapperArgs, newLayout.height)) {
        dispatch(showNotificationFreezeWarning());
      }
    }

    editorAPI.history.add(`toolbar - set selected component layout - ${key}`);
  };
