:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._sectionsDropZoneWrapper_1f18p_7 {
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  padding: 12px;
  background-color: #ece8ff;
  transition: background-color 0.2s; }
  ._sectionsDropZoneWrapper_1f18p_7._isDragging_1f18p_16 {
    z-index: 1; }
    ._sectionsDropZoneWrapper_1f18p_7._isDragging_1f18p_16:hover {
      background-color: #d4ccfd; }

._sectionsDropZoneBody_1f18p_21 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border: 1px dashed #6f56f9;
  color: #6f56f9; }
  ._sectionsDropZoneBody__text_1f18p_28 {
    color: #6f56f9; }
