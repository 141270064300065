// @ts-nocheck
import PropTypes from 'prop-types';
import React from 'react';
import {
  Button,
  Composites,
  PanelHeaderCenteredText,
  PanelHeaderIllustration,
  PromotionalList,
  TextLabel,
} from '@wix/wix-base-ui';
import * as symbols from '@wix/santa-editor-symbols';

function template(props) {
  return (
    <div data-aid="first-success-section">
      <Composites.PopupMediumSize className="composite-full-height">
        <Composites.BigPanelHeaderWithIllustration>
          <PanelHeaderCenteredText
            automationId="first-success-header"
            title={props.content.header}
            subtitle={props.content.headerSubtitle}
            titleType="T16"
            onHelp={props.onHelp}
            onClose={props.closePanel}
          />
          <PanelHeaderIllustration blueStripHeight={85}>
            <symbols.symbol name="successMessageIllustration" />
          </PanelHeaderIllustration>
        </Composites.BigPanelHeaderWithIllustration>
        <Composites.PanelContent class-name="first-success-panel-content">
          <Composites.PromotionalList>
            <PromotionalList items={props.content.items} />
          </Composites.PromotionalList>
        </Composites.PanelContent>
        <Composites.ActionSetHorizontalSpaced key="first-result-buttons">
          <Button
            onClick={() => {
              props.textButton.onClick();
            }}
            className="btn-text"
          >
            <TextLabel
              value={props.textButton.label}
              automationId="text-button-label"
            />
          </Button>
          <Button
            automationId="primary-button-package-and-update-panel"
            onClick={() => {
              props.primaryButton.onClick();
            }}
            className="primary"
          >
            <TextLabel
              value={props.primaryButton.label}
              automationId="primary-button-label"
            />
          </Button>
        </Composites.ActionSetHorizontalSpaced>
      </Composites.PopupMediumSize>
    </div>
  );
}

const FirstPackageResultSection = (props) => template(props);

FirstPackageResultSection.propTypes = {
  content: PropTypes.shape({
    header: PropTypes.string.isRequired,
    headerSubtitle: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  closePanel: PropTypes.func.isRequired,
  onHelp: PropTypes.func.isRequired,
  primaryButton: PropTypes.shape({
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  }).isRequired,
  textButton: PropTypes.shape({
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  }).isRequired,
};
export default FirstPackageResultSection;
