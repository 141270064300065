import { selectEnterpriseBrandName } from '../../collections';

import type { MapStateToProps } from 'types/redux';

export interface SharedCollectionLabelStateProps {
  enterpriseBrandName?: string;
}

export const mapStateToProps: MapStateToProps<
  SharedCollectionLabelStateProps
> = ({ state }) => ({
  enterpriseBrandName: selectEnterpriseBrandName(state),
});
