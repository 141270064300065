import React from 'react';

import ListSection, { type ListSectionProps } from './listSection';

interface PresetListSectionProps {
  onClick(
    structure: object,
    sectionTitle: string,
    sectionTags: string,
    id: string,
    editorAPI: object,
    additionalData: object,
  ): void;
  onItemDrag: (e: any, item: any) => void;
}

export default class PresetListSection extends React.Component<
  PresetListSectionProps & ListSectionProps
> {
  onItemClick = (item: AnyFixMe) => {
    this.props.onClick(
      item.structure,
      this.props.title,
      /*sectionTags*/ undefined,
      item.id,
      /*editorAPI*/ undefined,
      {
        nonDraggable: true,
      },
    );
  };

  render() {
    return <ListSection callback={this.onItemClick} {...this.props} />;
  }
}
