export const TRANSLATIONS = {
  SECTIONS: {
    LINK_PANEL_SECTION_PAGE_DROP_DOWN_LABEL:
      'LINK_PANEL_SECTION_PAGE_DROP_DOWN_LABEL',
    LINK_PANEL_LINK_TO_SECTION_TITLE: 'LINK_PANEL_LINK_TO_SECTION_TITLE',
    LINK_PANEL_LINK_TO_SECTION_TEXT: 'LINK_PANEL_LINK_TO_SECTION_TEXT',
    LINK_PANEL_SECTION_SECTION_DROP_DOWN_LABEL:
      'LINK_PANEL_SECTION_SECTION_DROP_DOWN_LABEL',
    LINK_PANEL_SECTION_ADD_SECTION_BUTTON:
      'LINK_PANEL_SECTION_ADD_SECTION_BUTTON',
    LINK_PANEL_SECTION_ADD_ANCHOR_BUTTON:
      'LINK_PANEL_SECTION_ADD_ANCHOR_BUTTON',
    LINK_PANEL_LINK_TO_SECTION_LINK: 'LINK_PANEL_LINK_TO_SECTION_LINK',
  },
};
