// @ts-nocheck
import _ from 'lodash';
import propTypes from 'prop-types';
import React from 'react';
import { TextLabel } from '@wix/wix-base-ui';
import * as symbols from '@wix/santa-editor-symbols';
import { cx } from '@/util';

//TYPE WAS GENERATED, remove this line when reviewed
interface Props {
  id: string;
  text: string;
  isSelected?: boolean;
  onClick?: FunctionFixMe;
}

export default class extends React.Component<Props> {
  static displayName = 'Tag';

  static propTypes = {
    id: propTypes.string.isRequired,
    text: propTypes.string.isRequired,
    isSelected: propTypes.bool,
    onClick: propTypes.func,
  };

  isSelected = () => {
    return this.props.isSelected;
  };

  onClick = () => {
    const { props } = this;

    _.invoke(props, 'onClick', props.id);
  };

  render() {
    return (
      <span
        onClick={() => {
          this.onClick();
        }}
        className={cx('media-manager-panel-tag', {
          selected: this.isSelected(),
        })}
      >
        <span className="media-manager-panel-tag__text">
          <TextLabel
            value={this.props.text}
            type="T04"
            shouldTranslate={false}
          />
        </span>

        {this.isSelected() ? (
          <span
            key="check-icon"
            className="media-manager-panel-tag__icon media-manager-panel-tag__icon-check"
          >
            <symbols.symbol
              name="media-manager-panel-check"
              style={{ fill: '#3899ec', width: '9px', height: '7px' }}
            />
          </span>
        ) : null}

        {!this.isSelected() ? (
          <span
            key="plus-icon"
            className="media-manager-panel-tag__icon media-manager-panel-tag__icon-plus"
          >
            <symbols.symbol
              name="plus"
              style={{ fill: '#3899ec', width: '7px', height: '7px' }}
            />
          </span>
        ) : null}
      </span>
    );
  }
}
