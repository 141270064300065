'use strict'

const _ = require('lodash')
const args = [require('./headerFooter1'),
    require('./headerFooter2'),
    require('./headerFooter3'),
    require('./headerFooter4'),
    require('./headerFooter5'),
    require('./headerFooter6'),
    require('./headerFooter7'),
    require('./headerFooter8'),
    require('./headerFooter9'),
    require('./headerFooter10'),
    require('./headerFooter11'),
    require('./headerFooter12'),
    require('./headerFooter13'),
    require('./headerFooter14'),
    require('./headerFooter15'),
    require('./headerFooter16'),
    require('./headerFooter17'),
    require('./headerFooter18'),
    require('./headerFooter19'),
    require('./headerFooter20'),
    require('./headerFooter21'),
    require('./headerFooter22'),
    require('./headerFooter23')]
module.exports = _.map(Array.prototype.slice.call(args, 1), 'footer')
