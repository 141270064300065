// @ts-nocheck
import * as stateManagement from '@/stateManagement';
import * as util from '@/util';
import { recompose } from '@wix/santa-editor-utils';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

export interface ProvidedUserPreferencesProps {
  dontShowAgain: boolean;
  toggleDontShowAgain: (checked: boolean) => void;
}

export interface ConsumedUserPreferencesProps {
  onToggleDontShowAgain?: (checked: boolean) => void;
}

const linkUserPreferences =
  (defaultProps) =>
  (Component): any => {
    class LinkUserPreferences extends React.Component {
      constructor(props, context) {
        super(props, context);

        let dontShowAgain = null;
        if (this.props.userPrefType && this.props.dontShowAgainKey) {
          dontShowAgain =
            !!stateManagement.userPreferences.selectors.getUserPreferences(
              this.props.userPrefType,
            )(this.props.dontShowAgainKey)(this.props._state);
        }

        this.state = { dontShowAgain };
      }

      toggleDontShowAgain(checked: boolean) {
        if (this.props.userPrefType && this.props.dontShowAgainKey) {
          const dontShowAgain = !this.state.dontShowAgain;
          this.props._dispatch(
            stateManagement.userPreferences.actions.setSitePreferences(
              this.props.userPrefType,
            )(this.props.dontShowAgainKey, dontShowAgain),
          );
          this.setState({ dontShowAgain });
          this.props.onToggleDontShowAgain(checked);
        }
      }

      render() {
        const props = Object.assign(
          {},
          _.omit(this.props, ['_dispatch, _state']),
          {
            toggleDontShowAgain: this.toggleDontShowAgain.bind(this),
            dontShowAgain: this.state.dontShowAgain,
          },
        );

        return React.createElement(Component, props);
      }
    }

    LinkUserPreferences.displayName = recompose.wrapDisplayName(
      Component,
      'LinkUserPreferences',
    );
    LinkUserPreferences.propTypes = {
      userPrefType: PropTypes.oneOf(['site', 'session', 'global']),
      dontShowAgainKey: PropTypes.string,
    };

    LinkUserPreferences.defaultProps = defaultProps;

    return util.hoc.connect(
      util.hoc.STORES.STATE_ONLY,
      ({ state }) => ({ _state: state }),
      (dispatch) => ({ _dispatch: dispatch }),
    )(LinkUserPreferences);
  };

export default (defaultProps = {}) =>
  (Component) =>
    linkUserPreferences(defaultProps)(Component);
