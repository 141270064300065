import type { OnDropCallback } from '@/addPanelInfra';
import type { EditorAPI } from '@/editorAPI';
import { addPanelAddCompService, constants as tpaConstants } from '@/tpa';
import type { CompStructure } from 'types/documentServices';

interface AddMembersSuperAppOnClickParams {
  appDefinitionId: string;
  widgetId: string;
  compStructure: CompStructure;
  tags: string;
  itemId: string;
  sectionTitle: string;
  categoryId: string;
  position: { x: number; y: number } | void;
}

export const addMembersSuperAppOnClick = (
  editorAPI: EditorAPI,
  {
    appDefinitionId,
    widgetId,
    compStructure,
    tags,
    itemId,
    sectionTitle,
    categoryId,
    position,
  }: AddMembersSuperAppOnClickParams,
) =>
  addPanelAddCompService
    .addCompOnClick(
      editorAPI,
      appDefinitionId,
      widgetId,
      compStructure,
      tags,
      itemId,
      {
        origin: tpaConstants.BI.type.ADD_WIDGET,
        sectionTitle,
        category: categoryId,
      },
      { position },
    )
    .then((compRef) => {
      return compRef;
    });

interface AddMembersSuperAppOnDropParams {
  appDefinitionId: string;
  widgetId: string;
}

export const addMembersSuperAppOnDrop =
  (
    editorAPI: EditorAPI,
    { appDefinitionId, widgetId }: AddMembersSuperAppOnDropParams,
  ): OnDropCallback =>
  (coords, itemData, parentRef) =>
    addPanelAddCompService.addCompOnDrop(
      editorAPI,
      appDefinitionId,
      widgetId,
      coords,
      itemData,
      parentRef,
      () => {},
      { origin: tpaConstants.BI.type.ADD_WIDGET },
    );
