import _ from 'lodash';

import type { SectionDescription } from '../../types';

export function ensureNamesUniq(
  sectionsDescriptions: SectionDescription[],
): SectionDescription[] {
  const sortedSectionsDescriptions = _.sortBy(sectionsDescriptions, 'name');
  let subsetIndex = 0;

  sortedSectionsDescriptions.forEach((sectionDescription, index) => {
    if (!sectionDescription.name) {
      subsetIndex = 0;
      return;
    }

    const isNextSectionWithSameName =
      sortedSectionsDescriptions[index + 1]?.name === sectionDescription.name;

    if (subsetIndex == 0 && isNextSectionWithSameName) {
      subsetIndex = 1;
    }

    if (subsetIndex > 0) {
      sectionDescription.name = `${sectionDescription.name} ${subsetIndex}`;
    }

    if (isNextSectionWithSameName) {
      subsetIndex += 1;
    } else {
      subsetIndex = 0;
    }
  });

  return sectionsDescriptions;
}
