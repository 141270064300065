:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._tooltipContainer_uy4hb_7 {
  display: flex;
  align-items: center;
  position: absolute; }

._container_uy4hb_12 {
  position: absolute;
  left: -20px;
  width: 40px;
  height: 24px;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.15), 0 6px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 18px 0 rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--bg-color);
  color: var(--icon-color);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  pointer-events: all;
  cursor: ns-resize; }
  ._mobileEditor_uy4hb_28 ._container_uy4hb_12 {
    width: 30px;
    left: auto;
    margin-left: -15px; }

._legacy_uy4hb_33 {
  --bg-color: #6f56f9;
  --icon-color: #fff;
  --border-color: #6f56f9; }

._legacySoap_uy4hb_38 {
  --bg-color: #fe620f;
  --icon-color: #fff;
  --border-color: #fe620f; }

._primary_uy4hb_43 {
  --bg-color: #6f56f9;
  --icon-color: #ffffff;
  --border-color: #ffffff; }
  ._primary_uy4hb_43:hover {
    --bg-color: #4b31dc; }

._secondary_uy4hb_50 {
  --bg-color: #ffffff;
  --icon-color: #6f56f9;
  --border-color: #6f56f9; }
  ._secondary_uy4hb_50:hover {
    --bg-color: #d9d2ff; }

._primarySoap_uy4hb_57 {
  --bg-color: #fe620f;
  --icon-color: #fff;
  --border-color: #ffffff; }
  ._primarySoap_uy4hb_57:hover {
    --bg-color: #dd560e; }

._secondarySoap_uy4hb_64 {
  --bg-color: #ffffff;
  --icon-color: #fe620f;
  --border-color: #fe620f; }
  ._secondarySoap_uy4hb_64:hover {
    --bg-color: #fdd1ba; }
