import React, { type ReactElement } from 'react';
import { translate } from '@/i18n';
import * as util from '@/util';

import TopBarButton from '../../topBarButton/topBarButtonWithTooltip';

interface BackToEditorButtonProps {
  disabled?: boolean;
  primary?: boolean;
  label?: string;
  tooltip?: ReactElement | string;
  onClick: () => void;
}

function BackToEditorButton(props: BackToEditorButtonProps) {
  return (
    <TopBarButton
      disabled={props.disabled}
      label={props.label}
      onClick={props.onClick}
      tooltip={props.tooltip}
      primary={props.primary}
      className={util.cx({
        'top-bar-back-to-editor-btn': !props.primary,
      })}
      automationId="top-bar-button-back-to-editor"
    />
  );
}

BackToEditorButton.defaultProps = {
  label: translate('TOPBAR_BACK_TO_EDITOR'),
};

export default BackToEditorButton;
