// @ts-nocheck
import _ from 'lodash';
import * as platformEvents from 'platformEvents';
import * as stateManagement from '@/stateManagement';
import * as platform from '@/platform';
import gfppDataUtils from '../../utils/gfppDataUtils';
import * as util from '@/util';

const { isAppWidget } = util.appStudioUtils;

function hideGfpp(compGfppData) {
  Object.assign(compGfppData, {
    mainActions: [],
    presetActions: [],
    enabledActions: [],
    mobileEnabledActions: [],
  });
}

export default function (editorAPI, compRefs, compGfppData) {
  if (!_.isEmpty(compRefs) && compRefs.length === 1) {
    const componentRef = compRefs[0];
    const connection =
      editorAPI.platform.controllers.connections.getPrimaryConnection(
        componentRef,
      );

    if (!connection) {
      return;
    }

    const appData = editorAPI.components.data.get(connection.controllerRef);
    const appId = editorAPI.dsRead.tpa.isTpaByCompType(
      editorAPI.components.getType(connection.controllerRef),
    )
      ? appData?.appDefinitionId
      : appData?.applicationId;

    const isDeprecatedConnectionToMembersLoginBar =
      !connection.controllerRef && connection.role === 'members-login-bar';

    const appManifest = editorAPI.dsRead.platform.hasAppManifest(appId);
    if (!appManifest) {
      if (!isDeprecatedConnectionToMembersLoginBar) {
        hideGfpp(compGfppData);
      }
      return compGfppData;
    }

    const gfppDataFromApp =
      editorAPI.platform.controllers.getConnectedComponentStageData(
        componentRef,
      )?.gfpp;
    if (!gfppDataFromApp) {
      return;
    }
    compGfppData.widgetGfppDataFromApp = gfppDataFromApp;
    const { applicationId } =
      editorAPI.platform.getAppDataByAppDefId(appId) || {};
    if (!applicationId) {
      return;
    }

    const deviceType = stateManagement.mobile.selectors.getDeviceType(
      editorAPI.store.getState(),
    );

    const getWidgetGfppClickedEvent = ({ id, targetRef }, origin) => {
      return platformEvents.factory.widgetGfppClicked({
        id,
        componentRef: targetRef,
        gfppOrigin: origin,
      });
    };

    const getComponentGfppClickedEvent = ({ id, targetRef }) => {
      return platformEvents.factory.componentGfppClicked({
        id,
        componentRef: targetRef || componentRef,
        controllerRef: connection.controllerRef,
        role: connection.role,
      });
    };

    const notifyGfppButtonClicked = ({ actionId: id, targetRef }, origin) => {
      const notifyOnClick =
        targetRef && isAppWidget(editorAPI, targetRef)
          ? getWidgetGfppClickedEvent({ id, targetRef }, origin)
          : getComponentGfppClickedEvent({ id, targetRef });
      editorAPI.platform.notifyApplication(applicationId, notifyOnClick);
    };

    const resolveFunction = gfppDataUtils.getGfppDataResolver(
      editorAPI,
      compRefs,
    );

    const upgradeType = platform.platformConnectedGfpp.toUniversalGfppFormat(
      gfppDataFromApp[deviceType]?.iconButtons?.upgrade,
    );

    compGfppData.presetActions = compGfppData.presetActions || {};
    compGfppData.presetActions.upgrade = platform.upgradeUtils.getPremiumGfpp(
      appId,
      upgradeType?.behavior,
    );

    compGfppData.mainActions =
      platform.platformConnectedGfpp.generateMainActions(
        deviceType,
        gfppDataFromApp,
        {
          ...compGfppData,
          mainActions: resolveFunction(compGfppData.mainActions),
          mobileMainActions: resolveFunction(compGfppData.mobileMainActions),
        },
        notifyGfppButtonClicked,
        connection.controllerRef,
        editorAPI,
      );

    compGfppData.presetActionsData =
      platform.platformConnectedGfpp.generateIconButtonsOverride(
        deviceType,
        gfppDataFromApp,
        compGfppData,
        notifyGfppButtonClicked,
        connection.controllerRef,
        editorAPI,
      );

    if (deviceType === 'desktop') {
      compGfppData.enabledActions = compGfppData.enabledActions || [];
      compGfppData.enabledActions.push('upgrade');
      compGfppData.enabledActions =
        platform.platformConnectedGfpp.generateEnabledActions(
          deviceType,
          gfppDataFromApp,
          resolveFunction(compGfppData.enabledActions),
        );
    } else {
      compGfppData.mobileEnabledActions =
        platform.platformConnectedGfpp.generateEnabledActions(
          deviceType,
          gfppDataFromApp,
          resolveFunction(compGfppData.mobileEnabledActions),
        );
    }
  }
}
