import { sections } from '@/util';

import type { EditorAPI } from '@/editorAPI';
import type { CompRef } from 'types/documentServices';
import type { LayoutConstraint } from '@/layoutUtils';

export const footerLayoutConstraint: LayoutConstraint = {
  shouldConstrain(editorAPI: EditorAPI, compRef: CompRef): boolean {
    return editorAPI.sections.isFooter(compRef);
  },
  applyConstraint(_editorAPI, _compRef, newLayout) {
    newLayout.height = Math.max(
      newLayout.height,
      sections.constants.FOOTER_MIN_HEIGHT,
    );
  },
};
