// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';
import { translate } from '@/i18n';
import {
  ActionWithSymbol,
  Composites,
  CustomScroll,
  Divider,
  RichText,
  TextLabel,
} from '@wix/wix-base-ui';
import BackendLoginOnlySettings from './backendLoginOnlySettings';
import OpenSignupSettingsDashboardButton from './OpenSignupSettingsDashboardButton';

function template() {
  return (
    <div className="site-members-settings-pages-panel">
      <Composites.RichText
        key="manage-site-members-container"
        className="top-banner velo"
      >
        <RichText className="light">
          {translate(this.props.translationKeys.topInfoKey)}
        </RichText>
      </Composites.RichText>
      <Divider long={true} />
      <div className="page-settings-panel-pp-content settings">
        <CustomScroll heightRelativeToParent="100%" flex="1">
          <div className="settings-margin">
            <div key="devSignupLink" className="option unnested">
              <Composites.ActionWithSymbolLabeled>
                <TextLabel
                  value="Pages_Custom_Signup_Settings_Custom_Signup_Link_Action_Label"
                  shouldTranslate={true}
                />
                <ActionWithSymbol action={this.openLinkPanel}>
                  <TextLabel
                    value={this.state.pageTitle}
                    shouldTranslate={false}
                  />
                </ActionWithSymbol>
              </Composites.ActionWithSymbolLabeled>
            </div>
          </div>
          <BackendLoginOnlySettings
            disabled={this.props.shouldBackendLoginOnlyDisabled('dev')}
            isBackendLoginOnlyOn={this.props.isBackendLoginOnlyOn}
            setBackendLoginOnlyStatus={this.props.setBackendLoginOnlyStatus}
            navigateToVeloPanel={this.props.navigateToVeloPanel}
          />
          <div className="settings-button-container-dev-mode">
            <OpenSignupSettingsDashboardButton
              onClick={this.props.openDashboard}
            />
          </div>
        </CustomScroll>
        <div className="settings-margin">
          <Divider long={true} />

          {this.props.isSiteSaved() ? (
            <Composites.RichText
              key="manage-site-members-container"
              className="actions"
            >
              <RichText className="light">
                {translate(
                  'NewPages_Member_Signup_Default_Top_Members_Area_Notice',
                )}{' '}
                <a target="_blank" href={this.props.getSiteMembersUrl()}>
                  {translate(
                    'NewPages_Member_Signup_Default_Top_Members_Area_Notice_Link',
                  )}
                </a>
              </RichText>
            </Composites.RichText>
          ) : null}
        </div>
      </div>
    </div>
  );
}

class SignupSettingsDeveloperDesktop extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageTitle: this.props.getCustomSignupPageLabel(
        this.props.getCustomSignupPageId(),
      ),
      link:
        this.props.getCustomSignupLinkFromId(
          this.props.getCustomSignupPageId(),
        ) || null,
    };

    this.openLinkPanel = () => {
      this.props.openLinkPanel({
        visibleSections: {
          PopupLink: true,
          PageLink: false,
          AnchorLink: false,
          NoLink: false,
          PhoneLink: false,
          WhatsAppLink: false,
          ExternalLink: false,
          EmailLink: false,
          DocumentLink: false,
          LoginToWixLink: false,
          EdgeAnchorLinks: false,
        },
        defaultLinkType: 'PopupLink',
        initialTabToDisplay: 'PopupLink',
        link: this.state.link,
        showAllPopups: true,
        hidePageLinks: true,
        pageTabTitle: 'LINK_PANEL_TAB_ANCHOR',
        pageTabPageDropDownTitle: 'PLATFORM_LINK_MENU_PAGE_PAGE_OPTION',
        anchorTabPageDropDownTitle: 'LINK_PANEL_PAGE_DROP_DOWN_PAGELINK_LABEL',
        title: 'Pages_Custom_Signup_Settings_Link_Panel_Header',
        onCancelCallback: () =>
          this.props.sendBiEvent().clickOnCancelLinkPanel(),
        callback: (link) => {
          const newCustomSignupPageId = link?.pageId
            ? link.pageId.replace('#', '')
            : null;
          if (
            this.props.isPageExists(newCustomSignupPageId) &&
            this.props.getCustomSignupPageId() !== newCustomSignupPageId
          ) {
            this.setState(
              {
                link: this.props.getCustomSignupLinkFromId(
                  newCustomSignupPageId,
                ),
                pageTitle: this.props.getCustomSignupPageLabel(
                  newCustomSignupPageId,
                ),
              },
              () => {
                this.props.assignCustomSignup(newCustomSignupPageId);
              },
            );
          }
          this.props.sendBiEvent().clickOnConnectLink('set-link');
        },
        hideLinkTarget: true,
      });
      this.props.sendBiEvent().clickOnConnectLink('click');
    };
  }

  render() {
    return template.call(this);
  }
}

SignupSettingsDeveloperDesktop.displayName = 'signupSettingsDeveloperDesktop';
SignupSettingsDeveloperDesktop.propTypes = {
  isCustomSignupExists: PropTypes.func.isRequired,
  getCustomSignupLinkFromId: PropTypes.func.isRequired,
  assignCustomSignup: PropTypes.func.isRequired,
  sendBiEvent: PropTypes.func.isRequired,
  getCustomSignupPageId: PropTypes.func.isRequired,
  isBackendLoginOnlyOn: PropTypes.func.isRequired,
  openDashboard: PropTypes.func.isRequired,
};

export default SignupSettingsDeveloperDesktop;
