'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')
const util = require('@/util')
const i18n = require('@/i18n')

module.exports = {
    'type': addPanelDataConsts.SECTIONS_TYPES.PRESET,
    'hide': false,
    'title': 'add_preset_list_news_posts',
    'showSectionHeader': true,
    'additionalBehaviours': {
        'labelMode': addPanelDataConsts.LABEL_BEHAVIOUR.HOVER,
        'infoIcon': addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
        'hoverImageAction': addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
        'iconEnabledForComps': {}
    },
    'props': {
        'image': `addPanelData/sections/listNewsPostsSection_${util.languages.getLanguageCode()}/listNewsPostsSection_${util.languages.getLanguageCode()}.png`,
        'imageHover': null,
        'items': [{
            'id': 'Lists_List_News_Posts_1',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.viewer.skins.AppPartSkin',
                'layout': {
                    'width': 538,
                    'height': 749,
                    'x': 59,
                    'y': 38,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wixapps.integration.components.AppPart2',
                'data': {
                    'type': 'AppBuilderComponent',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'appInnerID': '2',
                    'appPartName': 'appparti7ek4o3x6'
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'id': 'app1',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {'groups': {}, 'properties': {'rd': '7px'}, 'propertiesSource': {'rd': 'value'}},
                    'componentClassName': '',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'system',
                    'skin': 'wysiwyg.viewer.skins.AppPartSkin'
                },
                'custom': {
                    'template': {
                        'type': {
                            'version': 0,
                            'baseTypes': [],
                            'displayName': i18n.translate('i7ek4o5y.structure.custom.template.type.displayName'),
                            'name': 'NewsPosts_i6rn81ci379',
                            'fields': [{
                                'displayName': i18n.translate('i7ek4o5y.structure.custom.template.type.fields.0.displayName'),
                                'name': 'title',
                                'searchable': false,
                                'type': 'String',
                                'defaultValue': i18n.translate('i7ek4o5y.structure.custom.template.type.fields.0.defaultValue'),
                                'computed': false
                            }, {
                                'name': 'Strng_sTxt1',
                                'searchable': false,
                                'displayName': i18n.translate('i7ek4o5y.structure.custom.template.type.fields.1.displayName'),
                                'type': 'String',
                                'computed': false,
                                'defaultValue': i18n.translate('i7ek4o5y.structure.custom.template.type.fields.1.defaultValue')
                            }, {
                                'type': 'wix:Date',
                                'searchable': false,
                                'displayName': i18n.translate('i7ek4o5y.structure.custom.template.type.fields.2.displayName'),
                                'name': 'i92pwfil',
                                'computed': false,
                                'defaultValue': {'iso': '2020-01-01T00:00:00.000Z', '_type': 'wix:Date'}
                            }, {
                                'displayName': i18n.translate('i7ek4o5y.structure.custom.template.type.fields.3.displayName'),
                                'defaultValue': {
                                    'version': 1,
                                    '_type': 'wix:RichText',
                                    'links': [],
                                    'text': i18n.translate('i7ek4o5y.structure.custom.template.type.fields.3.defaultValue')
                                },
                                'type': 'wix:RichText',
                                'searchable': false,
                                'name': 'wxRchTxt_sTxt0',
                                'computed': false
                            }, {
                                'metadata': {'showAsHint': 'AsPrice'},
                                'displayName': i18n.translate('i7ek4o5y.structure.custom.template.type.fields.4.displayName'),
                                'searchable': false,
                                'type': 'String',
                                'defaultValue': i18n.translate('i7ek4o5y.structure.custom.template.type.fields.4.defaultValue'),
                                'name': 'i92q5rnm',
                                'computed': false
                            }, {
                                'type': 'wix:Image',
                                'defaultValue': {
                                    'height': 1276,
                                    'src': 'c8381ce0e7a94ad09ceaada2fc64081d.jpg',
                                    'width': 1920,
                                    '_type': 'wix:Image',
                                    'title': ''
                                },
                                'displayName': i18n.translate('i7ek4o5y.structure.custom.template.type.fields.5.displayName'),
                                'searchable': false,
                                'name': 'image',
                                'computed': false
                            }, {
                                'defaultValue': {
                                    'videoId': 'f36EEfI0yr8',
                                    '_type': 'wix:Video',
                                    'videoType': 'YOUTUBE'
                                },
                                'name': 'i6rnai1n',
                                'type': 'wix:Video',
                                'searchable': false,
                                'displayName': i18n.translate('i7ek4o5y.structure.custom.template.type.fields.6.displayName'),
                                'computed': false
                            }, {
                                'name': 'Strng_sBttn0-v1c',
                                'defaultValue': i18n.translate('i7ek4o5y.structure.custom.template.type.fields.7.defaultValue'),
                                'searchable': false,
                                'metadata': {'showAsHint': 'AsButton'},
                                'type': 'String',
                                'displayName': i18n.translate('i7ek4o5y.structure.custom.template.type.fields.7.displayName'),
                                'computed': false
                            }, {
                                'name': 'links',
                                'type': 'wix:Map',
                                'searchable': false,
                                'displayName': '',
                                'defaultValue': {'_type': 'wix:Map'},
                                'computed': false
                            }]
                        },
                        'views': [{
                            'name': 'NewsPostsView_i6rn81ck380_dup_i70h8xip179_dup_i7ek4o1k2',
                            'forType': 'Array',
                            'vars': {'itemSpacing': 45, 'paginationColor': 'color_15'},
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'paginatedlist',
                                    'comp': {
                                        'name': 'PaginatedList',
                                        'hidden': {'$expr': 'eq(Array.length(this), 0)'},
                                        'hidePagination': 'true',
                                        'itemsPerPage': '10',
                                        'templates': {
                                            'item': {
                                                'data': '$positionInParent',
                                                'comp': {
                                                    'name': 'SwitchBox',
                                                    'cases': {
                                                        'default': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'NewsPostsView_i6rn81ck380_dup_i70h8xip179_dup_i7ek4o1k2'}
                                                        }, {
                                                            'id': 'footerSpacer',
                                                            'comp': {
                                                                'name': 'VSpacer',
                                                                'size': {'$expr': '$itemSpacing'}
                                                            }
                                                        }, {
                                                            'id': 'gutterLine',
                                                            'comp': {'name': 'HorizontalLine', 'hidden': 'true'},
                                                            'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                        }],
                                                        'last': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'NewsPostsView_i6rn81ck380_dup_i70h8xip179_dup_i7ek4o1k2'}
                                                        }, {
                                                            'comp': {
                                                                'name': 'VBox',
                                                                'hidden': 'true',
                                                                'items': [{
                                                                    'id': 'footerSpacer',
                                                                    'comp': {
                                                                        'name': 'VSpacer',
                                                                        'size': {'$expr': '$itemSpacing'}
                                                                    }
                                                                }, {
                                                                    'id': 'gutterLine',
                                                                    'comp': {
                                                                        'name': 'HorizontalLine',
                                                                        'hidden': 'true'
                                                                    },
                                                                    'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                                }]
                                                            }, 'id': 'def_7'
                                                        }, {
                                                            'comp': {'name': 'VSpacer', 'size': '9'},
                                                            'id': 'def_10'
                                                        }, {
                                                            'id': 'paginationNavigationBox',
                                                            'comp': {
                                                                'name': 'HBox',
                                                                'hidden': {'$expr': '$hidePagination'},
                                                                'box-align': 'center',
                                                                'items': [{
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_12'
                                                                }, {
                                                                    'value': '◄',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationPrev',
                                                                        'events': {'dom:click': 'prevPageClicked'}
                                                                    },
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_13'
                                                                }, {
                                                                    'value': {'$expr': 'String.concat($currentPage, "/" ,$maxPage)'},
                                                                    'comp': {'name': 'Label'},
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_14'
                                                                }, {
                                                                    'value': '►',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationNext',
                                                                        'events': {'dom:click': 'nextPageClicked'}
                                                                    },
                                                                    'id': 'def_15'
                                                                }, {
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_16'
                                                                }]
                                                            },
                                                            'layout': {'position': 'relative'}
                                                        }]
                                                    },
                                                    'css': {'min-width': '100%'}
                                                },
                                                'id': '$positionInParent'
                                            }
                                        }
                                    }
                                }, {
                                    'id': 'noItemsLabel',
                                    'value': 'There are no items in this list',
                                    'comp': {'name': 'Label', 'hidden': {'$expr': 'gt(String.length(this), 0)'}}
                                }],
                                'editorData': {'isPaginated': true}
                            },
                            'stylesheet': [{
                                '.paginationPrev, .paginationNext': {
                                    'padding': '0 5px',
                                    'display': 'inline-block',
                                    'font-size': '14px',
                                    'cursor': 'pointer',
                                    '-webkit-touch-callout': 'none',
                                    '-webkit-user-select': 'none',
                                    '-khtml-user-select': 'none',
                                    '-moz-user-select': 'none',
                                    '-ms-user-select': 'none',
                                    'user-select': 'none'
                                }
                            }],
                            'customizations': [{
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.hidePagination',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Split your list across multiple pages',
                                    'defaultVal': 'true',
                                    'trueVal': 'false',
                                    'spaceAfter': '10'
                                }
                            }, {
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.itemsPerPage',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '120',
                                    'minVal': '1',
                                    'label': 'Items Per Page',
                                    'spaceAfter': '20',
                                    'hiddenwhen': {
                                        'fieldId': 'paginatedlist',
                                        'key': 'comp.hidePagination',
                                        'value': 'true'
                                    }
                                }
                            }, {
                                'priority': 100,
                                'fieldId': 'vars',
                                'key': 'itemSpacing',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '300',
                                    'minVal': '0',
                                    'label': 'Item Spacing',
                                    'spaceAfter': '20'
                                }
                            }, {
                                'priority': 95,
                                'fieldId': 'gutterLine',
                                'key': 'comp.hidden',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Show item separator',
                                    'defaultVal': 'false',
                                    'trueVal': 'false'
                                }
                            }, {
                                'priority': 90,
                                'fieldId': 'gutterLine',
                                'key': 'comp.style',
                                'input': {
                                    'name': 'changestyle',
                                    'showAs': 'link',
                                    'label': 'Change Item Separator Style',
                                    'spaceAfter': '20'
                                }
                            }],
                            'id': 'i7ek4o1k',
                            'format': 'Mobile'
                        }, {
                            'name': 'NewsPostsView_i6rn81ck380_dup_i70h8xip179_dup_i7ek4o1k2',
                            'forType': 'Array',
                            'vars': {'itemSpacing': 60, 'paginationColor': 'color_15'},
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'paginatedlist',
                                    'comp': {
                                        'name': 'PaginatedList',
                                        'hidden': {'$expr': 'eq(Array.length(this), 0)'},
                                        'hidePagination': 'true',
                                        'itemsPerPage': '10',
                                        'templates': {
                                            'item': {
                                                'data': '$positionInParent',
                                                'comp': {
                                                    'name': 'SwitchBox',
                                                    'cases': {
                                                        'default': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'NewsPostsView_i6rn81ck380_dup_i70h8xip179_dup_i7ek4o1k2'}
                                                        }, {
                                                            'id': 'footerSpacer',
                                                            'comp': {
                                                                'name': 'VSpacer',
                                                                'size': {'$expr': '$itemSpacing'}
                                                            }
                                                        }, {
                                                            'id': 'gutterLine',
                                                            'comp': {'name': 'HorizontalLine', 'hidden': 'true'},
                                                            'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                        }],
                                                        'last': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'NewsPostsView_i6rn81ck380_dup_i70h8xip179_dup_i7ek4o1k2'}
                                                        }, {
                                                            'comp': {
                                                                'name': 'VBox',
                                                                'hidden': 'true',
                                                                'items': [{
                                                                    'id': 'footerSpacer',
                                                                    'comp': {
                                                                        'name': 'VSpacer',
                                                                        'size': {'$expr': '$itemSpacing'}
                                                                    }
                                                                }, {
                                                                    'id': 'gutterLine',
                                                                    'comp': {
                                                                        'name': 'HorizontalLine',
                                                                        'hidden': 'true'
                                                                    },
                                                                    'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                                }]
                                                            }, 'id': 'def_7'
                                                        }, {
                                                            'comp': {'name': 'VSpacer', 'size': '9'},
                                                            'id': 'def_10'
                                                        }, {
                                                            'id': 'paginationNavigationBox',
                                                            'comp': {
                                                                'name': 'HBox',
                                                                'hidden': {'$expr': '$hidePagination'},
                                                                'box-align': 'center',
                                                                'items': [{
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_12'
                                                                }, {
                                                                    'value': '◄',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationPrev',
                                                                        'events': {'dom:click': 'prevPageClicked'}
                                                                    },
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_13'
                                                                }, {
                                                                    'value': {'$expr': 'String.concat($currentPage,\' / \',$maxPage)'},
                                                                    'comp': {'name': 'Label'},
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_14'
                                                                }, {
                                                                    'value': '►',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationNext',
                                                                        'events': {'dom:click': 'nextPageClicked'}
                                                                    },
                                                                    'id': 'def_15'
                                                                }, {
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_16'
                                                                }]
                                                            },
                                                            'layout': {'position': 'relative'}
                                                        }]
                                                    },
                                                    'css': {'min-width': '100%'}
                                                },
                                                'id': '$positionInParent'
                                            }
                                        }
                                    }
                                }, {
                                    'id': 'noItemsLabel',
                                    'value': 'There are no items in this list',
                                    'comp': {'name': 'Label', 'hidden': {'$expr': 'gt(String.length(this), 0)'}}
                                }],
                                'editorData': {'isPaginated': true}
                            },
                            'stylesheet': [{
                                '.paginationPrev, .paginationNext': {
                                    'padding': '0 5px',
                                    'display': 'inline-block',
                                    'font-size': '14px',
                                    'cursor': 'pointer',
                                    '-webkit-touch-callout': 'none',
                                    '-webkit-user-select': 'none',
                                    '-khtml-user-select': 'none',
                                    '-moz-user-select': 'none',
                                    '-ms-user-select': 'none',
                                    'user-select': 'none'
                                }
                            }],
                            'customizations': [{
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.hidePagination',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Split your list across multiple pages',
                                    'defaultVal': 'true',
                                    'trueVal': 'false',
                                    'spaceAfter': '10'
                                }
                            }, {
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.itemsPerPage',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '120',
                                    'minVal': '1',
                                    'label': 'Items Per Page',
                                    'spaceAfter': '20',
                                    'hiddenwhen': {
                                        'fieldId': 'paginatedlist',
                                        'key': 'comp.hidePagination',
                                        'value': 'true'
                                    }
                                }
                            }, {
                                'priority': 100,
                                'fieldId': 'vars',
                                'key': 'itemSpacing',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '300',
                                    'minVal': '0',
                                    'label': 'Item Spacing',
                                    'spaceAfter': '20'
                                }
                            }, {
                                'priority': 95,
                                'fieldId': 'gutterLine',
                                'key': 'comp.hidden',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Show item separator',
                                    'defaultVal': 'false',
                                    'trueVal': 'false'
                                }
                            }, {
                                'priority': 90,
                                'fieldId': 'gutterLine',
                                'key': 'comp.style',
                                'input': {
                                    'name': 'changestyle',
                                    'showAs': 'link',
                                    'label': 'Change Item Separator Style',
                                    'spaceAfter': '20'
                                }
                            }],
                            'id': 'i7ek4o1k'
                        }, {
                            'name': 'NewsPostsView_i6rn81ck380_dup_i70h8xip179_dup_i7ek4o1k2',
                            'forType': 'NewsPosts_i6rn81ci379',
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'Top',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Top section',
                                            'alignment': 'left',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 3}
                                        },
                                        'items': []
                                    },
                                    'layout': {'min-width': 0}
                                }, {
                                    'id': 'topSpacer',
                                    'comp': {'name': 'VSpacer', 'size': '0'}
                                }, {
                                    'comp': {
                                        'name': 'HBox',
                                        'items': [{
                                            'id': 'Left',
                                            'comp': {
                                                'name': 'FieldBox',
                                                'orientation': 'vertical',
                                                'items': [{
                                                    'id': 'fld_i70hnc0q267',
                                                    'data': 'i6rnai1n',
                                                    'comp': {
                                                        'name': 'Field',
                                                        'width': 380,
                                                        'height': 251,
                                                        'items': [{
                                                            'id': 'fld_i70hnc0q267_proxy',
                                                            'data': 'i6rnai1n',
                                                            'comp': {'name': 'Video'},
                                                            'layout': {'position': 'relative'}
                                                        }],
                                                        'hidden': true,
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 24,
                                                            'xax-before': 0,
                                                            'xax-after': 26
                                                        },
                                                        'box-align': 'left'
                                                    }
                                                }, {
                                                    'id': 'fld_i70hfoe6255',
                                                    'data': 'image',
                                                    'comp': {
                                                        'name': 'Field',
                                                        'width': 380,
                                                        'height': 251,
                                                        'items': [{
                                                            'id': 'fld_i70hfoe6255_proxy',
                                                            'data': 'image',
                                                            'comp': {'name': 'Image', 'style': 'wp2'}
                                                        }],
                                                        'hidden': true,
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 30,
                                                            'xax-before': 0,
                                                            'xax-after': 30
                                                        },
                                                        'box-align': 'left'
                                                    }
                                                }],
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'alignment': 'left',
                                                    'displayName': 'Left section',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 0,
                                                        'xax-after': 20
                                                    }
                                                },
                                                'pack': 'center'
                                            },
                                            'layout': {'spacerAfter': 0, 'min-width': 0}
                                        }, {
                                            'id': 'leftToCenterSpacer',
                                            'comp': {'name': 'HSpacer', 'size': '0'}
                                        }, {
                                            'id': 'Center',
                                            'comp': {
                                                'name': 'FieldBox',
                                                'orientation': 'vertical',
                                                'items': [{
                                                    'id': 'fld_i92pwfkc34',
                                                    'data': 'i92pwfil',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0',
                                                        'items': [{
                                                            'id': 'fld_i92pwfkc34_proxy',
                                                            'data': 'i92pwfil',
                                                            'comp': {
                                                                'name': 'Date',
                                                                'style': 'Body L',
                                                                'format': 'mmmm dd, yyyy',
                                                                'color': 'color_14'
                                                            },
                                                            'layout': {'text-align': 'left'}
                                                        }],
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 3,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'hidden': false,
                                                        'labelPosition': 'none'
                                                    },
                                                    'experiment': 'NewListFields'
                                                }, {
                                                    'id': 'text_1',
                                                    'data': 'title',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'width-mode': 'auto',
                                                        'min-lines': 0,
                                                        'max-lines': 0,
                                                        'items': [{
                                                            'id': 'text_1_proxy',
                                                            'data': 'title',
                                                            'comp': {
                                                                'name': 'Label',
                                                                'style': 'Heading S',
                                                                'bold': false,
                                                                'italic': false
                                                            },
                                                            'layout': {'text-align': 'left'}
                                                        }],
                                                        'spacers': {'before': 4, 'after': 0, 'xax-before': 0},
                                                        'hidden': false,
                                                        'box-align': 'start',
                                                        'height-mode': 'auto'
                                                    }
                                                }, {
                                                    'id': 'fld_hfnxgxqo',
                                                    'data': 'Strng_sTxt1',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0',
                                                        'items': [{
                                                            'id': 'fld_hfnxgxqo_proxy',
                                                            'data': 'Strng_sTxt1',
                                                            'comp': {
                                                                'name': 'Label',
                                                                'style': 'Body L',
                                                                'bold': false,
                                                                'italic': false,
                                                                'underline': false
                                                            },
                                                            'layout': {'text-align': 'left'}
                                                        }],
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 0,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'hidden': true,
                                                        'labelPosition': 'none'
                                                    }
                                                }, {
                                                    'id': 'fld_hfxs75de',
                                                    'data': 'wxRchTxt_sTxt0',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'items': [{
                                                            'id': 'fld_hfxs75de_proxy',
                                                            'data': 'wxRchTxt_sTxt0',
                                                            'comp': {'name': 'Label'},
                                                            'layout': {'text-align': 'left'}
                                                        }],
                                                        'hidden': false,
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 8,
                                                            'after': 8,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0'
                                                    }
                                                }, {
                                                    'id': 'fld_i92q5rot44',
                                                    'data': 'i92q5rnm',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'items': [{
                                                            'id': 'fld_i92q5rot44_proxy',
                                                            'data': 'i92q5rnm',
                                                            'comp': {
                                                                'name': 'Label',
                                                                'style': 'Heading S',
                                                                'color': 'color_14'
                                                            },
                                                            'layout': {'text-align': 'left'}
                                                        }],
                                                        'hidden': true,
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 4,
                                                            'after': 0,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0'
                                                    },
                                                    'experiment': 'NewListFields'
                                                }, {
                                                    'id': 'fld_i6ropive245',
                                                    'data': 'Strng_sBttn0-v1c',
                                                    'comp': {
                                                        'name': 'Field',
                                                        'width': 160,
                                                        'height': '40',
                                                        'items': [{
                                                            'id': 'fld_i6ropive245_proxy',
                                                            'data': 'Strng_sBttn0-v1c',
                                                            'comp': {
                                                                'name': 'Button2',
                                                                'style': 'b1',
                                                                'align': 'center'
                                                            },
                                                            'layout': {'position': 'relative'}
                                                        }],
                                                        'hidden': false,
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 16,
                                                            'after': 0,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'box-align': 'left'
                                                    }
                                                }],
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'alignment': 'left',
                                                    'displayName': 'Center section',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 0,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'pack': 'center'
                                            },
                                            'layout': {'box-flex': 1, 'min-width': 0}
                                        }, {
                                            'id': 'centerToRightSpacer',
                                            'comp': {'name': 'HSpacer', 'size': '3'}
                                        }, {
                                            'id': 'Right',
                                            'comp': {
                                                'name': 'FieldBox',
                                                'orientation': 'vertical',
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'displayName': 'Right section',
                                                    'alignment': 'left',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 3,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'items': []
                                            },
                                            'layout': {'min-width': 0}
                                        }]
                                    }, 'id': 'def_3'
                                }, {
                                    'id': 'Bottom',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Bottom section',
                                            'alignment': 'left',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 3}
                                        },
                                        'items': []
                                    },
                                    'layout': {'box-flex-pack': 'start', 'min-width': 0}
                                }, {'id': 'bottomSpacer', 'comp': {'name': 'VSpacer', 'size': '0'}}]
                            },
                            'id': 'def_0',
                            'editorData': {'wasChanged': true}
                        }, {
                            'name': 'NewsPostsView_i6rn81ck380_dup_i70h8xip179_dup_i7ek4o1k2',
                            'forType': 'NewsPosts_i6rn81ci379',
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'Top',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Top section',
                                            'alignment': 'left',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 0}
                                        },
                                        'items': [{
                                            'id': 'fld_i70hnc0q267',
                                            'data': 'i6rnai1n',
                                            'comp': {
                                                'name': 'Field',
                                                'width': 280,
                                                'height': 220,
                                                'items': [{
                                                    'id': 'fld_i70hnc0q267_proxy',
                                                    'data': 'i6rnai1n',
                                                    'comp': {'name': 'Video'},
                                                    'layout': {'position': 'relative'}
                                                }],
                                                'hidden': true,
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 0,
                                                    'after': 3,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'box-align': 'left'
                                            }
                                        }, {
                                            'id': 'fld_i70hfoe6255',
                                            'data': 'image',
                                            'comp': {
                                                'name': 'Field',
                                                'width': 280,
                                                'height': 200,
                                                'items': [{
                                                    'id': 'fld_i70hfoe6255_proxy',
                                                    'data': 'image',
                                                    'comp': {'name': 'Image', 'style': 'wp2'}
                                                }],
                                                'hidden': true,
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 0,
                                                    'after': 3,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'box-align': 'left'
                                            }
                                        }]
                                    },
                                    'layout': {'min-width': 0}
                                }, {
                                    'id': 'topSpacer',
                                    'comp': {'name': 'VSpacer', 'size': 0}
                                }, {
                                    'comp': {
                                        'name': 'VBox',
                                        'items': [{
                                            'id': 'def_3_0',
                                            'comp': {
                                                'name': 'HBox',
                                                'orientation': 'horizontal',
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'displayName': 'def_3_0',
                                                    'alignment': 'left',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 3,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'items': [{
                                                    'id': 'Left',
                                                    'comp': {
                                                        'name': 'VBox',
                                                        'orientation': 'vertical',
                                                        'items': [{
                                                            'id': 'Left_0',
                                                            'comp': {
                                                                'name': 'FieldBox',
                                                                'items': [],
                                                                'orientation': 'horizontal',
                                                                'editorData': {
                                                                    'labelPosition': 'none',
                                                                    'displayName': 'Left_0',
                                                                    'alignment': 'left',
                                                                    'spacers': {
                                                                        'before': 0,
                                                                        'after': 3,
                                                                        'xax-before': 3,
                                                                        'xax-after': 0
                                                                    }
                                                                }
                                                            },
                                                            'layout': {'min-width': 0}
                                                        }, {
                                                            'id': 'Left_1',
                                                            'comp': {
                                                                'name': 'FieldBox',
                                                                'orientation': 'horizontal',
                                                                'editorData': {
                                                                    'labelPosition': 'none',
                                                                    'displayName': 'Left_1',
                                                                    'alignment': 'left',
                                                                    'spacers': {
                                                                        'before': 0,
                                                                        'after': 3,
                                                                        'xax-before': 3,
                                                                        'xax-after': 0
                                                                    }
                                                                },
                                                                'items': []
                                                            },
                                                            'layout': {'min-width': 0}
                                                        }],
                                                        'editorData': {
                                                            'labelPosition': 'none',
                                                            'alignment': 'left',
                                                            'displayName': 'Left section',
                                                            'spacers': {
                                                                'before': 0,
                                                                'after': 3,
                                                                'xax-before': 0,
                                                                'xax-after': 0
                                                            }
                                                        },
                                                        'pack': 'start'
                                                    },
                                                    'layout': {'spacerAfter': 0, 'min-width': 0}
                                                }]
                                            },
                                            'layout': {'min-width': 0}
                                        }, {
                                            'id': 'def_3_1',
                                            'comp': {
                                                'name': 'HBox',
                                                'orientation': 'horizontal',
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'displayName': 'def_3_1',
                                                    'alignment': 'left',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 3,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'items': [{
                                                    'id': 'leftToCenterSpacer',
                                                    'comp': {'name': 'HSpacer', 'size': 0}
                                                }, {
                                                    'id': 'Center',
                                                    'comp': {
                                                        'name': 'FieldBox',
                                                        'orientation': 'vertical',
                                                        'items': [{
                                                            'id': 'fld_i99uh5a4206',
                                                            'data': 'i92pwfil',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': '200',
                                                                'width-mode': 'auto',
                                                                'min-lines': '0',
                                                                'max-lines': '0',
                                                                'items': [{
                                                                    'id': 'fld_i99uh5a4206_proxy',
                                                                    'data': 'i92pwfil',
                                                                    'comp': {
                                                                        'name': 'Date',
                                                                        'format': 'mmmm dd, yyyy',
                                                                        'style': 'Body L',
                                                                        'color': 'color_14'
                                                                    },
                                                                    'layout': {'text-align': 'left'}
                                                                }],
                                                                'spacers': {
                                                                    'before': 0,
                                                                    'after': 9,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                },
                                                                'labelPosition': 'none',
                                                                'hidden': false
                                                            },
                                                            'experiment': 'NewListFields'
                                                        }, {
                                                            'id': 'text_1',
                                                            'data': 'title',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': 86,
                                                                'width-mode': 'auto',
                                                                'min-lines': 0,
                                                                'max-lines': 0,
                                                                'items': [{
                                                                    'id': 'text_1_proxy',
                                                                    'data': 'title',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'style': 'Heading S',
                                                                        'bold': false,
                                                                        'italic': false,
                                                                        'color': 'color_14'
                                                                    },
                                                                    'layout': {'text-align': 'left'}
                                                                }],
                                                                'spacers': {
                                                                    'before': 0,
                                                                    'after': 0,
                                                                    'xax-before': 0
                                                                },
                                                                'box-align': 'start',
                                                                'height-mode': 'auto'
                                                            }
                                                        }, {
                                                            'id': 'fld_hfnxgxqo',
                                                            'data': 'Strng_sTxt1',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': 86,
                                                                'width-mode': 'auto',
                                                                'min-lines': '0',
                                                                'max-lines': '0',
                                                                'items': [{
                                                                    'id': 'fld_hfnxgxqo_proxy',
                                                                    'data': 'Strng_sTxt1',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'style': 'Body L',
                                                                        'bold': false,
                                                                        'italic': false,
                                                                        'underline': false,
                                                                        'color': 'color_14'
                                                                    },
                                                                    'layout': {'text-align': 'left'}
                                                                }],
                                                                'spacers': {
                                                                    'before': 0,
                                                                    'after': 0,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                },
                                                                'hidden': true,
                                                                'labelPosition': 'none'
                                                            }
                                                        }, {
                                                            'id': 'fld_hfxs75de',
                                                            'data': 'wxRchTxt_sTxt0',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': 86,
                                                                'items': [{
                                                                    'id': 'fld_hfxs75de_proxy',
                                                                    'data': 'wxRchTxt_sTxt0',
                                                                    'comp': {'name': 'Label', 'color': 'color_14'},
                                                                    'layout': {'text-align': 'left'}
                                                                }],
                                                                'hidden': false,
                                                                'labelPosition': 'none',
                                                                'spacers': {
                                                                    'before': 8,
                                                                    'after': 8,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                },
                                                                'width-mode': 'auto',
                                                                'min-lines': '0',
                                                                'max-lines': '0'
                                                            }
                                                        }, {
                                                            'id': 'fld_i99uhg9r210',
                                                            'data': 'i92q5rnm',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': '200',
                                                                'width-mode': 'auto',
                                                                'min-lines': '0',
                                                                'max-lines': '0',
                                                                'items': [{
                                                                    'id': 'fld_i99uhg9r210_proxy',
                                                                    'data': 'i92q5rnm',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'style': 'Body L',
                                                                        'color': 'color_14'
                                                                    },
                                                                    'layout': {'text-align': 'left'}
                                                                }],
                                                                'labelPosition': 'none',
                                                                'spacers': {
                                                                    'before': 0,
                                                                    'after': 0,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                },
                                                                'hidden': true
                                                            }
                                                        }, {
                                                            'id': 'fld_i6ropive245',
                                                            'data': 'Strng_sBttn0-v1c',
                                                            'comp': {
                                                                'name': 'Field',
                                                                'width': 280,
                                                                'items': [{
                                                                    'id': 'fld_i6ropive245_proxy',
                                                                    'data': 'Strng_sBttn0-v1c',
                                                                    'comp': {
                                                                        'name': 'Button2',
                                                                        'style': 'b1',
                                                                        'align': 'center'
                                                                    },
                                                                    'layout': {'position': 'relative'}
                                                                }],
                                                                'labelPosition': 'none',
                                                                'spacers': {
                                                                    'before': 15,
                                                                    'after': 0,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                },
                                                                'hidden': false,
                                                                'height': 44,
                                                                'box-align': 'center'
                                                            }
                                                        }],
                                                        'editorData': {
                                                            'labelPosition': 'none',
                                                            'alignment': 'left',
                                                            'displayName': 'Center section',
                                                            'spacers': {
                                                                'before': 0,
                                                                'after': 3,
                                                                'xax-before': 0,
                                                                'xax-after': 0
                                                            }
                                                        },
                                                        'pack': 'start'
                                                    },
                                                    'layout': {'box-flex': 1, 'min-width': 0}
                                                }]
                                            },
                                            'layout': {'min-width': 0}
                                        }, {
                                            'id': 'def_3_2',
                                            'comp': {
                                                'name': 'HBox',
                                                'orientation': 'horizontal',
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'displayName': 'def_3_2',
                                                    'alignment': 'left',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 3,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'items': [{
                                                    'id': 'centerToRightSpacer',
                                                    'comp': {'name': 'HSpacer', 'size': 3}
                                                }, {
                                                    'id': 'Right',
                                                    'comp': {
                                                        'name': 'FieldBox',
                                                        'orientation': 'vertical',
                                                        'editorData': {
                                                            'labelPosition': 'none',
                                                            'displayName': 'Right section',
                                                            'alignment': 'left',
                                                            'spacers': {
                                                                'before': 0,
                                                                'after': 3,
                                                                'xax-before': 0,
                                                                'xax-after': 0
                                                            }
                                                        },
                                                        'items': []
                                                    },
                                                    'layout': {'min-width': 0}
                                                }]
                                            },
                                            'layout': {'min-width': 0}
                                        }],
                                        'orientation': 'vertical'
                                    }, 'id': 'def_3'
                                }, {
                                    'id': 'Bottom',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Bottom section',
                                            'alignment': 'left',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 0}
                                        },
                                        'items': []
                                    },
                                    'layout': {'box-flex-pack': 'start', 'min-width': 0}
                                }, {'id': 'bottomSpacer', 'comp': {'name': 'VSpacer', 'size': 0}}]
                            },
                            'id': 'def_0',
                            'editorData': {'wasChanged': true, 'originalWidth': 601},
                            'format': 'Mobile'
                        }],
                        'items': [{
                            'title': i18n.translate('i7ek4o5y.structure.custom.template.items.0.title'),
                            'image': {
                                '_type': 'wix:Image',
                                'title': '',
                                'src': 'c7ad2b64020342d0ae1a1feace158fe9.jpg',
                                'width': 1920,
                                'height': 1280,
                                'alt': ''
                            },
                            'Strng_sTxt1': i18n.translate('i7ek4o5y.structure.custom.template.items.0.Strng_sTxt1'),
                            'wxRchTxt_sTxt0': {
                                'version': 1,
                                '_type': 'wix:RichText',
                                'links': [],
                                'text': i18n.translate('i7ek4o5y.structure.custom.template.items.0.wxRchTxt_sTxt0')
                            },
                            'links': {'_type': 'wix:Map'},
                            'Strng_sBttn0-v1c': i18n.translate('i7ek4o5y.structure.custom.template.items.0.Strng_sBttn0-v1c'),
                            'i6rnai1n': {
                                '_type': 'wix:Video',
                                'videoId': 'z1pSAwjC4JI',
                                'videoType': 'YOUTUBE',
                                'thumbnail': ''
                            },
                            'i92pwfil': {'_type': 'wix:Date', 'iso': '2023-01-03T00:00:00.000Z'},
                            'i92q5rnm': i18n.translate('i7ek4o5y.structure.custom.template.items.0.i92q5rnm')
                        }, {
                            'title': i18n.translate('i7ek4o5y.structure.custom.template.items.1.title'),
                            'image': {
                                '_type': 'wix:Image',
                                'title': '',
                                'src': '8d13be_36d622561b4c453794c71ba4de8aea99.png',
                                'width': 400,
                                'height': 267,
                                'alt': ''
                            },
                            'Strng_sTxt1': i18n.translate('i7ek4o5y.structure.custom.template.items.1.Strng_sTxt1'),
                            'wxRchTxt_sTxt0': {
                                'version': 1,
                                '_type': 'wix:RichText',
                                'links': [],
                                'text': i18n.translate('i7ek4o5y.structure.custom.template.items.1.wxRchTxt_sTxt0')
                            },
                            'links': {'_type': 'wix:Map'},
                            'Strng_sBttn0-v1c': i18n.translate('i7ek4o5y.structure.custom.template.items.1.Strng_sBttn0-v1c'),
                            'i6rnai1n': {
                                '_type': 'wix:Video',
                                'videoId': 'f36EEfI0yr8',
                                'videoType': 'YOUTUBE',
                                'thumbnail': ''
                            },
                            'i92pwfil': {'_type': 'wix:Date', 'iso': '2023-02-23T00:00:00.000Z'},
                            'i92q5rnm': i18n.translate('i7ek4o5y.structure.custom.template.items.1.i92q5rnm')
                        }, {
                            'title': i18n.translate('i7ek4o5y.structure.custom.template.items.2.title'),
                            'image': {
                                '_type': 'wix:Image',
                                'title': '',
                                'src': '8d13be_0ef0c5f95548458085a267f142ae1697.png',
                                'width': 400,
                                'height': 267,
                                'alt': ''
                            },
                            'Strng_sTxt1': i18n.translate('i7ek4o5y.structure.custom.template.items.2.Strng_sTxt1'),
                            'wxRchTxt_sTxt0': {
                                'version': 1,
                                '_type': 'wix:RichText',
                                'links': [],
                                'text': i18n.translate('i7ek4o5y.structure.custom.template.items.2.wxRchTxt_sTxt0')
                            },
                            'links': {'_type': 'wix:Map'},
                            'Strng_sBttn0-v1c': i18n.translate('i7ek4o5y.structure.custom.template.items.2.Strng_sBttn0-v1c'),
                            'i6rnai1n': {
                                '_type': 'wix:Video',
                                'videoId': 'wGOTBSlYkzU',
                                'videoType': 'YOUTUBE',
                                'thumbnail': ''
                            },
                            'i92pwfil': {'_type': 'wix:Date', 'iso': '2023-05-28T00:00:00.000Z'},
                            'i92q5rnm': i18n.translate('i7ek4o5y.structure.custom.template.items.2.i92q5rnm')
                        }],
                        'displayName': i18n.translate('i7ek4o5y.structure.custom.template.displayName')
                    }
                },
                'id': 'i7ek4o5y'
            },
            'preset': {
                'rect': {'width': 162, 'height': 223, 'x': 0, 'y': 0},
                'label': 'i7ek4o5y.structure.custom.template.displayName',
                'tags': null
            }
        }, {
            'id': 'Lists_List_News_Posts_2',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.viewer.skins.AppPartSkin',
                'layout': {
                    'width': 537,
                    'height': 1576,
                    'x': 695,
                    'y': 24,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wixapps.integration.components.AppPart2',
                'data': {
                    'type': 'AppBuilderComponent',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'appInnerID': '2',
                    'appPartName': 'appparti7ek4o5t9'
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'id': 'app1',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {'groups': {}, 'properties': {'rd': '7px'}, 'propertiesSource': {'rd': 'value'}},
                    'componentClassName': '',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'system',
                    'skin': 'wysiwyg.viewer.skins.AppPartSkin'
                },
                'custom': {
                    'template': {
                        'type': {
                            'version': 0,
                            'baseTypes': [],
                            'name': 'TeamList_i6m8gew5108',
                            'displayName': i18n.translate('i7ek4o5y_0.structure.custom.template.type.displayName'),
                            'fields': [{
                                'displayName': i18n.translate('i7ek4o5y_0.structure.custom.template.type.fields.0.displayName'),
                                'name': 'title',
                                'searchable': false,
                                'type': 'String',
                                'defaultValue': i18n.translate('i7ek4o5y_0.structure.custom.template.type.fields.0.defaultValue'),
                                'computed': false
                            }, {
                                'searchable': false,
                                'displayName': i18n.translate('i7ek4o5y_0.structure.custom.template.type.fields.1.displayName'),
                                'type': 'String',
                                'name': 'Strng_sTxt0',
                                'computed': false,
                                'defaultValue': i18n.translate('i7ek4o5y_0.structure.custom.template.type.fields.1.defaultValue')
                            }, {
                                'type': 'wix:Date',
                                'searchable': false,
                                'displayName': i18n.translate('i7ek4o5y_0.structure.custom.template.type.fields.2.displayName'),
                                'name': 'i92qhuoz',
                                'computed': false,
                                'defaultValue': {'iso': '2020-01-01T00:00:00.000Z', '_type': 'wix:Date'}
                            }, {
                                'displayName': i18n.translate('i7ek4o5y_0.structure.custom.template.type.fields.3.displayName'),
                                'name': 'wxRchTxt_sTxt4',
                                'defaultValue': {
                                    'version': 1,
                                    '_type': 'wix:RichText',
                                    'links': [],
                                    'text': i18n.translate('i7ek4o5y_0.structure.custom.template.type.fields.3.defaultValue')
                                },
                                'type': 'wix:RichText',
                                'searchable': false,
                                'computed': false
                            }, {
                                'metadata': {'showAsHint': 'AsPrice'},
                                'displayName': i18n.translate('i7ek4o5y_0.structure.custom.template.type.fields.4.displayName'),
                                'searchable': false,
                                'type': 'String',
                                'defaultValue': i18n.translate('i7ek4o5y_0.structure.custom.template.type.fields.4.defaultValue'),
                                'computed': false,
                                'name': 'i92qtlb7'
                            }, {
                                'type': 'wix:Image',
                                'defaultValue': {
                                    'height': 1276,
                                    'src': 'c8381ce0e7a94ad09ceaada2fc64081d.jpg',
                                    'width': 1920,
                                    '_type': 'wix:Image',
                                    'title': ''
                                },
                                'displayName': i18n.translate('i7ek4o5y_0.structure.custom.template.type.fields.5.displayName'),
                                'searchable': false,
                                'name': 'image',
                                'computed': false
                            }, {
                                'defaultValue': {
                                    'videoId': 'f36EEfI0yr8',
                                    '_type': 'wix:Video',
                                    'videoType': 'YOUTUBE'
                                },
                                'type': 'wix:Video',
                                'searchable': false,
                                'displayName': i18n.translate('i7ek4o5y_0.structure.custom.template.type.fields.6.displayName'),
                                'name': 'i6m8jrb7',
                                'computed': false
                            }, {
                                'defaultValue': i18n.translate('i7ek4o5y_0.structure.custom.template.type.fields.7.defaultValue'),
                                'name': 'i6m8jzu2',
                                'metadata': {'showAsHint': 'AsButton'},
                                'searchable': false,
                                'type': 'String',
                                'displayName': i18n.translate('i7ek4o5y_0.structure.custom.template.type.fields.7.displayName'),
                                'computed': false
                            }, {
                                'name': 'links',
                                'type': 'wix:Map',
                                'searchable': false,
                                'displayName': '',
                                'defaultValue': {
                                    '_type': 'wix:Map',
                                    'i6m8jzu2': {
                                        'protocol': 'http',
                                        '_type': 'wix:ExternalLink',
                                        'target': '_blank',
                                        'address': 'www.wix.com'
                                    }
                                },
                                'computed': false
                            }]
                        },
                        'views': [{
                            'name': 'TeamListView_i6m8gew6109_dup_i6qm3pfb338_dup_i6ro0rw0157_dup_i70h8xkx181_dup_i7ek4o403',
                            'forType': 'Array',
                            'vars': {'itemSpacing': 50, 'paginationColor': 'color_15'},
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'paginatedlist',
                                    'comp': {
                                        'name': 'PaginatedList',
                                        'hidden': {'$expr': 'eq(Array.length(this), 0)'},
                                        'hidePagination': 'true',
                                        'itemsPerPage': '10',
                                        'templates': {
                                            'item': {
                                                'data': '$positionInParent',
                                                'comp': {
                                                    'name': 'SwitchBox',
                                                    'cases': {
                                                        'default': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'TeamListView_i6m8gew6109_dup_i6qm3pfb338_dup_i6ro0rw0157_dup_i70h8xkx181_dup_i7ek4o403'}
                                                        }, {
                                                            'id': 'footerSpacer',
                                                            'comp': {
                                                                'name': 'VSpacer',
                                                                'size': {'$expr': '$itemSpacing'}
                                                            }
                                                        }, {
                                                            'id': 'gutterLine',
                                                            'comp': {'name': 'HorizontalLine', 'hidden': 'true'},
                                                            'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                        }],
                                                        'last': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'TeamListView_i6m8gew6109_dup_i6qm3pfb338_dup_i6ro0rw0157_dup_i70h8xkx181_dup_i7ek4o403'}
                                                        }, {
                                                            'comp': {
                                                                'name': 'VBox',
                                                                'hidden': 'true',
                                                                'items': [{
                                                                    'id': 'footerSpacer',
                                                                    'comp': {
                                                                        'name': 'VSpacer',
                                                                        'size': {'$expr': '$itemSpacing'}
                                                                    }
                                                                }, {
                                                                    'id': 'gutterLine',
                                                                    'comp': {
                                                                        'name': 'HorizontalLine',
                                                                        'hidden': 'true'
                                                                    },
                                                                    'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                                }]
                                                            }, 'id': 'def_7'
                                                        }, {
                                                            'comp': {'name': 'VSpacer', 'size': '9'},
                                                            'id': 'def_10'
                                                        }, {
                                                            'id': 'paginationNavigationBox',
                                                            'comp': {
                                                                'name': 'HBox',
                                                                'hidden': {'$expr': '$hidePagination'},
                                                                'box-align': 'center',
                                                                'items': [{
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_12'
                                                                }, {
                                                                    'value': '◄',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationPrev',
                                                                        'events': {'dom:click': 'prevPageClicked'}
                                                                    },
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_13'
                                                                }, {
                                                                    'value': {'$expr': 'String.concat($currentPage,\' / \',$maxPage)'},
                                                                    'comp': {'name': 'Label'},
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_14'
                                                                }, {
                                                                    'value': '►',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationNext',
                                                                        'events': {'dom:click': 'nextPageClicked'}
                                                                    },
                                                                    'id': 'def_15'
                                                                }, {
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_16'
                                                                }]
                                                            },
                                                            'layout': {'position': 'relative'}
                                                        }]
                                                    },
                                                    'css': {'min-width': '100%'}
                                                },
                                                'id': '$positionInParent'
                                            }
                                        }
                                    }
                                }, {
                                    'id': 'noItemsLabel',
                                    'value': 'There are no items in this list',
                                    'comp': {'name': 'Label', 'hidden': {'$expr': 'gt(String.length(this), 0)'}}
                                }],
                                'editorData': {'isPaginated': true}
                            },
                            'stylesheet': [{
                                '.paginationPrev, .paginationNext': {
                                    'padding': '0 5px',
                                    'display': 'inline-block',
                                    'font-size': '14px',
                                    'cursor': 'pointer',
                                    '-webkit-touch-callout': 'none',
                                    '-webkit-user-select': 'none',
                                    '-khtml-user-select': 'none',
                                    '-moz-user-select': 'none',
                                    '-ms-user-select': 'none',
                                    'user-select': 'none'
                                }
                            }],
                            'customizations': [{
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.hidePagination',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Split your list across multiple pages',
                                    'defaultVal': 'true',
                                    'trueVal': 'false',
                                    'spaceAfter': '10'
                                }
                            }, {
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.itemsPerPage',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '120',
                                    'minVal': '1',
                                    'label': 'Items Per Page',
                                    'spaceAfter': '20',
                                    'hiddenwhen': {
                                        'fieldId': 'paginatedlist',
                                        'key': 'comp.hidePagination',
                                        'value': 'true'
                                    }
                                }
                            }, {
                                'priority': 100,
                                'fieldId': 'vars',
                                'key': 'itemSpacing',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '300',
                                    'minVal': '0',
                                    'label': 'Item Spacing',
                                    'spaceAfter': '20'
                                }
                            }, {
                                'priority': 95,
                                'fieldId': 'gutterLine',
                                'key': 'comp.hidden',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Show item separator',
                                    'defaultVal': 'false',
                                    'trueVal': 'false'
                                }
                            }, {
                                'priority': 90,
                                'fieldId': 'gutterLine',
                                'key': 'comp.style',
                                'input': {
                                    'name': 'changestyle',
                                    'showAs': 'link',
                                    'label': 'Change Item Separator Style',
                                    'spaceAfter': '20'
                                }
                            }],
                            'id': 'i7ek4o40'
                        }, {
                            'name': 'TeamListView_i6m8gew6109_dup_i6qm3pfb338_dup_i6ro0rw0157_dup_i70h8xkx181_dup_i7ek4o403',
                            'forType': 'Array',
                            'vars': {'itemSpacing': 60, 'paginationColor': 'color_15'},
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'paginatedlist',
                                    'comp': {
                                        'name': 'PaginatedList',
                                        'hidden': {'$expr': 'eq(Array.length(this), 0)'},
                                        'hidePagination': 'true',
                                        'itemsPerPage': '10',
                                        'templates': {
                                            'item': {
                                                'data': '$positionInParent',
                                                'comp': {
                                                    'name': 'SwitchBox',
                                                    'cases': {
                                                        'default': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'TeamListView_i6m8gew6109_dup_i6qm3pfb338_dup_i6ro0rw0157_dup_i70h8xkx181_dup_i7ek4o403'}
                                                        }, {
                                                            'id': 'footerSpacer',
                                                            'comp': {
                                                                'name': 'VSpacer',
                                                                'size': {'$expr': '$itemSpacing'}
                                                            }
                                                        }, {
                                                            'id': 'gutterLine',
                                                            'comp': {'name': 'HorizontalLine', 'hidden': 'true'},
                                                            'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                        }],
                                                        'last': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'TeamListView_i6m8gew6109_dup_i6qm3pfb338_dup_i6ro0rw0157_dup_i70h8xkx181_dup_i7ek4o403'}
                                                        }, {
                                                            'comp': {
                                                                'name': 'VBox',
                                                                'hidden': 'true',
                                                                'items': [{
                                                                    'id': 'footerSpacer',
                                                                    'comp': {
                                                                        'name': 'VSpacer',
                                                                        'size': {'$expr': '$itemSpacing'}
                                                                    }
                                                                }, {
                                                                    'id': 'gutterLine',
                                                                    'comp': {
                                                                        'name': 'HorizontalLine',
                                                                        'hidden': 'true'
                                                                    },
                                                                    'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                                }]
                                                            }, 'id': 'def_7'
                                                        }, {
                                                            'comp': {'name': 'VSpacer', 'size': '9'},
                                                            'id': 'def_10'
                                                        }, {
                                                            'id': 'paginationNavigationBox',
                                                            'comp': {
                                                                'name': 'HBox',
                                                                'hidden': {'$expr': '$hidePagination'},
                                                                'box-align': 'center',
                                                                'items': [{
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_12'
                                                                }, {
                                                                    'value': '◄',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationPrev',
                                                                        'events': {'dom:click': 'prevPageClicked'}
                                                                    },
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_13'
                                                                }, {
                                                                    'value': {'$expr': 'String.concat($currentPage,\' / \',$maxPage)'},
                                                                    'comp': {'name': 'Label'},
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_14'
                                                                }, {
                                                                    'value': '►',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationNext',
                                                                        'events': {'dom:click': 'nextPageClicked'}
                                                                    },
                                                                    'id': 'def_15'
                                                                }, {
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_16'
                                                                }]
                                                            },
                                                            'layout': {'position': 'relative'}
                                                        }]
                                                    },
                                                    'css': {'min-width': '100%'}
                                                },
                                                'id': '$positionInParent'
                                            }
                                        }
                                    }
                                }, {
                                    'id': 'noItemsLabel',
                                    'value': 'There are no items in this list',
                                    'comp': {'name': 'Label', 'hidden': {'$expr': 'gt(String.length(this), 0)'}}
                                }],
                                'editorData': {'isPaginated': true}
                            },
                            'stylesheet': [{
                                '.paginationPrev, .paginationNext': {
                                    'padding': '0 5px',
                                    'display': 'inline-block',
                                    'font-size': '14px',
                                    'cursor': 'pointer',
                                    '-webkit-touch-callout': 'none',
                                    '-webkit-user-select': 'none',
                                    '-khtml-user-select': 'none',
                                    '-moz-user-select': 'none',
                                    '-ms-user-select': 'none',
                                    'user-select': 'none'
                                }
                            }],
                            'customizations': [{
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.hidePagination',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Split your list across multiple pages',
                                    'defaultVal': 'true',
                                    'trueVal': 'false',
                                    'spaceAfter': '10'
                                }
                            }, {
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.itemsPerPage',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '120',
                                    'minVal': '1',
                                    'label': 'Items Per Page',
                                    'spaceAfter': '20',
                                    'hiddenwhen': {
                                        'fieldId': 'paginatedlist',
                                        'key': 'comp.hidePagination',
                                        'value': 'true'
                                    }
                                }
                            }, {
                                'priority': 100,
                                'fieldId': 'vars',
                                'key': 'itemSpacing',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '300',
                                    'minVal': '0',
                                    'label': 'Item Spacing',
                                    'spaceAfter': '20'
                                }
                            }, {
                                'priority': 95,
                                'fieldId': 'gutterLine',
                                'key': 'comp.hidden',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Show item separator',
                                    'defaultVal': 'false',
                                    'trueVal': 'false'
                                }
                            }, {
                                'priority': 90,
                                'fieldId': 'gutterLine',
                                'key': 'comp.style',
                                'input': {
                                    'name': 'changestyle',
                                    'showAs': 'link',
                                    'label': 'Change Item Separator Style',
                                    'spaceAfter': '20'
                                }
                            }],
                            'id': 'i7ek4o40',
                            'format': 'Mobile'
                        }, {
                            'name': 'TeamListView_i6m8gew6109_dup_i6qm3pfb338_dup_i6ro0rw0157_dup_i70h8xkx181_dup_i7ek4o403',
                            'forType': 'TeamList_i6m8gew5108',
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'Top',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Top section',
                                            'alignment': 'left',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 0}
                                        },
                                        'items': [{
                                            'id': 'image_1',
                                            'data': 'image',
                                            'comp': {
                                                'name': 'Field',
                                                'width': 280,
                                                'items': [{
                                                    'id': 'image_1_proxy',
                                                    'data': 'image',
                                                    'comp': {'name': 'Image', 'style': 'wp2'}
                                                }],
                                                'hidden': false,
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 0,
                                                    'after': 10,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'height': 220,
                                                'box-align': 'left'
                                            }
                                        }, {
                                            'id': 'fld_i7suupi319',
                                            'data': 'i6m8jrb7',
                                            'comp': {
                                                'name': 'Field',
                                                'width': 280,
                                                'items': [{
                                                    'id': 'fld_i7suupi319_proxy',
                                                    'data': 'i6m8jrb7',
                                                    'comp': {'name': 'Video'},
                                                    'layout': {'position': 'relative'}
                                                }],
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 0,
                                                    'after': 3,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'hidden': true,
                                                'height': 221,
                                                'box-align': 'left'
                                            }
                                        }]
                                    },
                                    'layout': {'min-width': 0}
                                }, {
                                    'id': 'topSpacer',
                                    'comp': {'name': 'VSpacer', 'size': 0}
                                }, {
                                    'comp': {
                                        'name': 'VBox',
                                        'items': [{
                                            'id': 'def_3_0',
                                            'comp': {
                                                'name': 'HBox',
                                                'orientation': 'horizontal',
                                                'items': [{
                                                    'id': 'Left',
                                                    'comp': {
                                                        'name': 'FieldBox',
                                                        'orientation': 'vertical',
                                                        'items': [],
                                                        'editorData': {
                                                            'labelPosition': 'none',
                                                            'alignment': 'left',
                                                            'displayName': 'Left section',
                                                            'spacers': {
                                                                'before': 0,
                                                                'after': 3,
                                                                'xax-before': 0,
                                                                'xax-after': 0
                                                            }
                                                        }
                                                    },
                                                    'layout': {'spacerAfter': 0, 'min-width': 0}
                                                }, {
                                                    'id': 'leftToCenterSpacer',
                                                    'comp': {'name': 'HSpacer', 'size': 0}
                                                }, {
                                                    'id': 'Center',
                                                    'comp': {
                                                        'name': 'FieldBox',
                                                        'orientation': 'vertical',
                                                        'pack': 'start',
                                                        'items': [{
                                                            'id': 'text_1',
                                                            'data': 'title',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': 280,
                                                                'items': [{
                                                                    'id': 'text_1_proxy',
                                                                    'data': 'title',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'style': 'Heading S',
                                                                        'bold': false,
                                                                        'italic': false,
                                                                        'color': 'color_14'
                                                                    },
                                                                    'layout': {'text-align': 'left'}
                                                                }],
                                                                'hidden': false,
                                                                'width-mode': 'auto',
                                                                'min-lines': 0,
                                                                'max-lines': 0,
                                                                'labelPosition': 'none',
                                                                'spacers': {
                                                                    'before': 0,
                                                                    'after': 3,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                },
                                                                'height-mode': 'auto'
                                                            }
                                                        }, {
                                                            'id': 'fld_i984kltu31',
                                                            'data': 'i92qtlb7',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': 171,
                                                                'items': [{
                                                                    'id': 'fld_i984kltu31_proxy',
                                                                    'data': 'i92qtlb7',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'style': 'Body L',
                                                                        'color': 'color_14'
                                                                    },
                                                                    'layout': {'text-align': 'left'}
                                                                }],
                                                                'hidden': true,
                                                                'width-mode': 'auto',
                                                                'min-lines': '0',
                                                                'max-lines': '0',
                                                                'labelPosition': 'none',
                                                                'spacers': {
                                                                    'before': 0,
                                                                    'after': 6,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                }
                                                            },
                                                            'experiment': 'NewListFields'
                                                        }, {
                                                            'id': 'fld_i984kfsg24',
                                                            'data': 'i92qhuoz',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': 280,
                                                                'items': [{
                                                                    'id': 'fld_i984kfsg24_proxy',
                                                                    'data': 'i92qhuoz',
                                                                    'comp': {
                                                                        'name': 'Date',
                                                                        'format': 'mmmm dd, yyyy',
                                                                        'color': 'color_14'
                                                                    },
                                                                    'layout': {'text-align': 'left'}
                                                                }],
                                                                'hidden': false,
                                                                'width-mode': 'auto',
                                                                'min-lines': '0',
                                                                'max-lines': '0',
                                                                'labelPosition': 'none',
                                                                'spacers': {
                                                                    'before': 0,
                                                                    'after': 3,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                }
                                                            },
                                                            'experiment': 'NewListFields'
                                                        }, {
                                                            'id': 'fld_hfwpt6aj',
                                                            'data': 'Strng_sTxt0',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': 86,
                                                                'items': [{
                                                                    'id': 'fld_hfwpt6aj_proxy',
                                                                    'data': 'Strng_sTxt0',
                                                                    'comp': {'name': 'Label', 'style': 'Body M'},
                                                                    'layout': {'text-align': 'left'}
                                                                }],
                                                                'hidden': true,
                                                                'width-mode': 'auto',
                                                                'min-lines': '0',
                                                                'max-lines': '0',
                                                                'labelPosition': 'none',
                                                                'spacers': {
                                                                    'before': 0,
                                                                    'after': 3,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                }
                                                            }
                                                        }, {
                                                            'id': 'fld_hfxsv1z6',
                                                            'data': 'wxRchTxt_sTxt4',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': 86,
                                                                'items': [{
                                                                    'id': 'fld_hfxsv1z6_proxy',
                                                                    'data': 'wxRchTxt_sTxt4',
                                                                    'comp': {'name': 'Label', 'color': 'color_14'},
                                                                    'layout': {'text-align': 'left'}
                                                                }],
                                                                'hidden': false,
                                                                'width-mode': 'auto',
                                                                'min-lines': '0',
                                                                'max-lines': '0',
                                                                'labelPosition': 'none',
                                                                'spacers': {
                                                                    'before': 8,
                                                                    'after': 0,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                }
                                                            }
                                                        }],
                                                        'editorData': {
                                                            'labelPosition': 'none',
                                                            'alignment': 'left',
                                                            'displayName': 'Center section',
                                                            'spacers': {
                                                                'before': 0,
                                                                'after': 3,
                                                                'xax-before': 0,
                                                                'xax-after': 0
                                                            }
                                                        }
                                                    },
                                                    'layout': {'box-flex': 1, 'min-width': 0}
                                                }],
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'alignment': 'left',
                                                    'displayName': 'def_3_0',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 3,
                                                        'xax-after': 0
                                                    }
                                                }
                                            },
                                            'layout': {'min-width': 0}
                                        }, {
                                            'id': 'def_3_1',
                                            'comp': {
                                                'name': 'HBox',
                                                'orientation': 'horizontal',
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'displayName': 'def_3_1',
                                                    'alignment': 'left',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 3,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'items': [{
                                                    'id': 'centerToRightSpacer',
                                                    'comp': {'name': 'HSpacer', 'size': 0}
                                                }, {
                                                    'id': 'Right',
                                                    'comp': {
                                                        'name': 'FieldBox',
                                                        'orientation': 'vertical',
                                                        'pack': 'center',
                                                        'editorData': {
                                                            'labelPosition': 'none',
                                                            'displayName': 'Right section',
                                                            'alignment': 'left',
                                                            'spacers': {
                                                                'before': 0,
                                                                'after': 3,
                                                                'xax-before': 0,
                                                                'xax-after': 0
                                                            }
                                                        }
                                                    },
                                                    'layout': {'min-width': 0}
                                                }]
                                            },
                                            'layout': {'min-width': 0}
                                        }],
                                        'orientation': 'vertical'
                                    }, 'id': 'def_3'
                                }, {
                                    'id': 'Bottom',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Bottom section',
                                            'alignment': 'left',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 0}
                                        },
                                        'items': [{
                                            'id': 'fld_i6m8jzve190',
                                            'data': 'i6m8jzu2',
                                            'comp': {
                                                'name': 'Field',
                                                'width': 280,
                                                'items': [{
                                                    'id': 'fld_i6m8jzve190_proxy',
                                                    'data': 'i6m8jzu2',
                                                    'comp': {'name': 'Button2', 'style': 'b1', 'align': 'center'},
                                                    'layout': {'position': 'relative'}
                                                }],
                                                'hidden': false,
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 26,
                                                    'after': 0,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'height': 44,
                                                'box-align': 'center'
                                            }
                                        }]
                                    },
                                    'layout': {'box-flex-pack': 'start', 'min-width': 0}
                                }, {'id': 'bottomSpacer', 'comp': {'name': 'VSpacer', 'size': 0}}]
                            },
                            'id': 'def_0',
                            'editorData': {'wasChanged': true, 'originalWidth': 280},
                            'format': 'Mobile'
                        }, {
                            'name': 'TeamListView_i6m8gew6109_dup_i6qm3pfb338_dup_i6ro0rw0157_dup_i70h8xkx181_dup_i7ek4o403',
                            'forType': 'TeamList_i6m8gew5108',
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'Top',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Top section',
                                            'alignment': 'left',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 3}
                                        },
                                        'items': [{
                                            'id': 'fld_i70hbwa6228',
                                            'data': 'i6m8jrb7',
                                            'comp': {
                                                'name': 'Field',
                                                'width': 529,
                                                'items': [{
                                                    'id': 'fld_i70hbwa6228_proxy',
                                                    'data': 'i6m8jrb7',
                                                    'comp': {'name': 'Video'},
                                                    'layout': {'position': 'relative'}
                                                }],
                                                'hidden': true,
                                                'height': 317,
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 0,
                                                    'after': 26,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'box-align': 'left'
                                            }
                                        }, {
                                            'id': 'image_1',
                                            'data': 'image',
                                            'comp': {
                                                'name': 'Field',
                                                'width': 537,
                                                'height': 280,
                                                'items': [{
                                                    'id': 'image_1_proxy',
                                                    'data': 'image',
                                                    'comp': {'name': 'Image', 'style': 'wp2'}
                                                }],
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 0,
                                                    'after': 16,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'box-align': 'left',
                                                'hidden': false
                                            }
                                        }]
                                    },
                                    'layout': {'min-width': 0}
                                }, {
                                    'id': 'topSpacer',
                                    'comp': {'name': 'VSpacer', 'size': '0'}
                                }, {
                                    'comp': {
                                        'name': 'HBox',
                                        'items': [{
                                            'id': 'Left',
                                            'comp': {
                                                'name': 'FieldBox',
                                                'orientation': 'vertical',
                                                'items': [],
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'alignment': 'left',
                                                    'displayName': 'Left section',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 0,
                                                        'xax-after': 10
                                                    }
                                                }
                                            },
                                            'layout': {'spacerAfter': 0, 'min-width': 0}
                                        }, {
                                            'id': 'leftToCenterSpacer',
                                            'comp': {'name': 'HSpacer', 'size': '0'}
                                        }, {
                                            'id': 'Center',
                                            'comp': {
                                                'name': 'FieldBox',
                                                'orientation': 'vertical',
                                                'pack': 'start',
                                                'items': [{
                                                    'id': 'text_1',
                                                    'data': 'title',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'items': [{
                                                            'id': 'text_1_proxy',
                                                            'data': 'title',
                                                            'comp': {
                                                                'name': 'Label',
                                                                'style': 'Heading S',
                                                                'bold': false,
                                                                'italic': false,
                                                                'color': 'color_14'
                                                            },
                                                            'layout': {'text-align': 'left'}
                                                        }],
                                                        'hidden': false,
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 0,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'width-mode': 'auto',
                                                        'min-lines': 0,
                                                        'max-lines': 0,
                                                        'height-mode': 'auto'
                                                    }
                                                }, {
                                                    'id': 'fld_i92qhuqh109',
                                                    'data': 'i92qhuoz',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0',
                                                        'items': [{
                                                            'id': 'fld_i92qhuqh109_proxy',
                                                            'data': 'i92qhuoz',
                                                            'comp': {
                                                                'name': 'Date',
                                                                'format': 'mmmm dd, yyyy',
                                                                'color': 'color_14'
                                                            },
                                                            'layout': {'text-align': 'left'}
                                                        }],
                                                        'hidden': false,
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 3,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        }
                                                    },
                                                    'experiment': 'NewListFields'
                                                }, {
                                                    'id': 'fld_hfwpt6aj',
                                                    'data': 'Strng_sTxt0',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0',
                                                        'items': [{
                                                            'id': 'fld_hfwpt6aj_proxy',
                                                            'data': 'Strng_sTxt0',
                                                            'comp': {
                                                                'name': 'Label',
                                                                'style': 'Body M',
                                                                'color': 'color_14'
                                                            },
                                                            'layout': {'text-align': 'left'}
                                                        }],
                                                        'hidden': true,
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 0,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        }
                                                    }
                                                }],
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'alignment': 'left',
                                                    'displayName': 'Center section',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 0,
                                                        'xax-after': 0
                                                    }
                                                }
                                            },
                                            'layout': {'box-flex': 1, 'min-width': 0}
                                        }, {
                                            'id': 'centerToRightSpacer',
                                            'comp': {'name': 'HSpacer', 'size': '0'}
                                        }, {
                                            'id': 'Right',
                                            'comp': {
                                                'name': 'FieldBox',
                                                'orientation': 'vertical',
                                                'pack': 'start',
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'displayName': 'Right section',
                                                    'alignment': 'left',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 10,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'items': [{
                                                    'experiment': 'NewListFields',
                                                    'id': 'fld_i92qtlci40',
                                                    'data': 'i92qtlb7',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0',
                                                        'items': [{
                                                            'id': 'fld_i92qtlci40_proxy',
                                                            'data': 'i92qtlb7',
                                                            'comp': {
                                                                'name': 'Label',
                                                                'style': 'Heading S',
                                                                'color': 'color_14'
                                                            },
                                                            'layout': {'text-align': 'right'}
                                                        }],
                                                        'hidden': true,
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 3,
                                                            'xax-before': 10,
                                                            'xax-after': 0
                                                        }
                                                    }
                                                }]
                                            },
                                            'layout': {'min-width': 0}
                                        }]
                                    }, 'id': 'def_3'
                                }, {
                                    'id': 'Bottom',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Bottom section',
                                            'alignment': 'left',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 3}
                                        },
                                        'items': [{
                                            'id': 'fld_hfxsv1z6',
                                            'data': 'wxRchTxt_sTxt4',
                                            'comp': {
                                                'name': 'TextField',
                                                'width': '200',
                                                'items': [{
                                                    'id': 'fld_hfxsv1z6_proxy',
                                                    'data': 'wxRchTxt_sTxt4',
                                                    'comp': {'name': 'Label', 'color': 'color_14'},
                                                    'layout': {'text-align': 'left'}
                                                }],
                                                'hidden': false,
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 8,
                                                    'after': 0,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'width-mode': 'auto',
                                                'min-lines': '0',
                                                'max-lines': '0'
                                            }
                                        }, {
                                            'id': 'fld_i6m8jzve190',
                                            'data': 'i6m8jzu2',
                                            'comp': {
                                                'name': 'Field',
                                                'width': 160,
                                                'items': [{
                                                    'id': 'fld_i6m8jzve190_proxy',
                                                    'data': 'i6m8jzu2',
                                                    'comp': {'name': 'Button2', 'style': 'b1', 'align': 'center'},
                                                    'layout': {'position': 'relative'}
                                                }],
                                                'hidden': false,
                                                'labelPosition': 'none',
                                                'spacers': {
                                                    'before': 24,
                                                    'after': 0,
                                                    'xax-before': 0,
                                                    'xax-after': 0
                                                },
                                                'height': '40',
                                                'box-align': 'start'
                                            }
                                        }]
                                    },
                                    'layout': {'box-flex-pack': 'start', 'min-width': 0}
                                }, {'id': 'bottomSpacer', 'comp': {'name': 'VSpacer', 'size': '0'}}]
                            },
                            'id': 'def_0',
                            'editorData': {'wasChanged': true}
                        }],
                        'items': [{
                            'title': i18n.translate('i7ek4o5y_0.structure.custom.template.items.0.title'),
                            'image': {
                                '_type': 'wix:Image',
                                'title': '',
                                'src': 'c7ad2b64020342d0ae1a1feace158fe9.jpg',
                                'width': 1920,
                                'height': 1280,
                                'alt': ''
                            },
                            'Strng_sTxt0': i18n.translate('i7ek4o5y_0.structure.custom.template.items.0.Strng_sTxt0'),
                            'wxRchTxt_sTxt4': {
                                'version': 1,
                                '_type': 'wix:RichText',
                                'links': [],
                                'text': i18n.translate('i7ek4o5y_0.structure.custom.template.items.0.wxRchTxt_sTxt4')
                            },
                            'links': {
                                '_type': 'wix:Map',
                                'i6m8jzu2': {
                                    'type': 'ExternalLink',
                                    'url': 'http://www.wix.com',
                                    'target': '_blank'
                                }
                            },
                            'i6m8jrb7': {
                                '_type': 'wix:Video',
                                'videoId': 'z1pSAwjC4JI',
                                'videoType': 'YOUTUBE',
                                'thumbnail': ''
                            },
                            'i6m8jzu2': i18n.translate('i7ek4o5y_0.structure.custom.template.items.0.i6m8jzu2'),
                            'i92qhuoz': {'_type': 'wix:Date', 'iso': '2023-01-03T00:00:00.000Z'},
                            'i92qtlb7': i18n.translate('i7ek4o5y_0.structure.custom.template.items.0.i92qtlb7')
                        }, {
                            'title': i18n.translate('i7ek4o5y_0.structure.custom.template.items.1.title'),
                            'image': {
                                '_type': 'wix:Image',
                                'title': '',
                                'src': '8d13be_36d622561b4c453794c71ba4de8aea99.png',
                                'width': 400,
                                'height': 267,
                                'alt': ''
                            },
                            'Strng_sTxt0': i18n.translate('i7ek4o5y_0.structure.custom.template.items.1.Strng_sTxt0'),
                            'wxRchTxt_sTxt4': {
                                'version': 1,
                                '_type': 'wix:RichText',
                                'links': [],
                                'text': i18n.translate('i7ek4o5y_0.structure.custom.template.items.1.wxRchTxt_sTxt4')
                            },
                            'links': {
                                '_type': 'wix:Map',
                                'i6m8jzu2': {
                                    'type': 'ExternalLink',
                                    'url': 'http://www.wix.com',
                                    'target': '_blank'
                                }
                            },
                            'i6m8jrb7': {
                                '_type': 'wix:Video',
                                'videoId': 'f36EEfI0yr8',
                                'videoType': 'YOUTUBE',
                                'thumbnail': ''
                            },
                            'i6m8jzu2': i18n.translate('i7ek4o5y_0.structure.custom.template.items.1.i6m8jzu2'),
                            'i92qhuoz': {'_type': 'wix:Date', 'iso': '2023-02-23T00:00:00.000Z'},
                            'i92qtlb7': i18n.translate('i7ek4o5y_0.structure.custom.template.items.1.i92qtlb7')
                        }, {
                            'title': i18n.translate('i7ek4o5y_0.structure.custom.template.items.2.title'),
                            'image': {
                                '_type': 'wix:Image',
                                'title': '',
                                'src': '8d13be_0ef0c5f95548458085a267f142ae1697.png',
                                'width': 400,
                                'height': 267,
                                'alt': ''
                            },
                            'Strng_sTxt0': i18n.translate('i7ek4o5y_0.structure.custom.template.items.2.Strng_sTxt0'),
                            'wxRchTxt_sTxt4': {
                                'version': 1,
                                '_type': 'wix:RichText',
                                'links': [],
                                'text': i18n.translate('i7ek4o5y_0.structure.custom.template.items.2.wxRchTxt_sTxt4')
                            },
                            'links': {
                                '_type': 'wix:Map',
                                'i6m8jzu2': {
                                    'type': 'ExternalLink',
                                    'url': 'http://www.wix.com',
                                    'target': '_blank'
                                }
                            },
                            'i6m8jrb7': {
                                '_type': 'wix:Video',
                                'videoId': 'wGOTBSlYkzU',
                                'videoType': 'YOUTUBE',
                                'thumbnail': ''
                            },
                            'i6m8jzu2': i18n.translate('i7ek4o5y_0.structure.custom.template.items.2.i6m8jzu2'),
                            'i92qhuoz': {'_type': 'wix:Date', 'iso': '2023-05-28T00:00:00.000Z'},
                            'i92qtlb7': i18n.translate('i7ek4o5y_0.structure.custom.template.items.2.i92qtlb7')
                        }],
                        'displayName': i18n.translate('i7ek4o5y_0.structure.custom.template.displayName')
                    }
                },
                'id': 'i7ek4o5y_0'
            },
            'preset': {
                'rect': {'width': 162, 'height': 422, 'x': 162, 'y': 0},
                'label': 'i7ek4o5y_0.structure.custom.template.displayName',
                'tags': null
            }
        }, {
            'id': 'Lists_List_News_Posts_3',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.viewer.skins.AppPartSkin',
                'layout': {
                    'width': 728,
                    'height': 813,
                    'x': -105,
                    'y': 938,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wixapps.integration.components.AppPart2',
                'data': {
                    'type': 'AppBuilderComponent',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'appInnerID': '2',
                    'appPartName': 'appparti7ek4o1g3'
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'id': 'app1',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {'groups': {}, 'properties': {'rd': '7px'}, 'propertiesSource': {'rd': 'value'}},
                    'componentClassName': '',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'system',
                    'skin': 'wysiwyg.viewer.skins.AppPartSkin'
                },
                'custom': {
                    'template': {
                        'type': {
                            'version': 0,
                            'baseTypes': [],
                            'displayName': i18n.translate('i7ek4o5x.structure.custom.template.type.displayName'),
                            'name': 'NewsPosts_i6cku5qm357',
                            'fields': [{
                                'displayName': i18n.translate('i7ek4o5x.structure.custom.template.type.fields.0.displayName'),
                                'name': 'title',
                                'searchable': false,
                                'type': 'String',
                                'defaultValue': i18n.translate('i7ek4o5x.structure.custom.template.type.fields.0.defaultValue'),
                                'computed': false
                            }, {
                                'name': 'Strng_sTxt1',
                                'searchable': false,
                                'displayName': i18n.translate('i7ek4o5x.structure.custom.template.type.fields.1.displayName'),
                                'type': 'String',
                                'computed': false,
                                'defaultValue': i18n.translate('i7ek4o5x.structure.custom.template.type.fields.1.defaultValue')
                            }, {
                                'name': 'i9idp2am',
                                'type': 'wix:Date',
                                'searchable': false,
                                'displayName': i18n.translate('i7ek4o5x.structure.custom.template.type.fields.2.displayName'),
                                'computed': false,
                                'defaultValue': {'iso': '2020-01-01T00:00:00.000Z', '_type': 'wix:Date'}
                            }, {
                                'displayName': i18n.translate('i7ek4o5x.structure.custom.template.type.fields.3.displayName'),
                                'defaultValue': {
                                    'version': 1,
                                    '_type': 'wix:RichText',
                                    'links': [],
                                    'text': i18n.translate('i7ek4o5x.structure.custom.template.type.fields.3.defaultValue')
                                },
                                'type': 'wix:RichText',
                                'searchable': false,
                                'name': 'wxRchTxt_sTxt0',
                                'computed': false
                            }, {
                                'name': 'i9ig8mns',
                                'metadata': {'showAsHint': 'AsPrice'},
                                'displayName': i18n.translate('i7ek4o5x.structure.custom.template.type.fields.4.displayName'),
                                'searchable': false,
                                'type': 'String',
                                'defaultValue': i18n.translate('i7ek4o5x.structure.custom.template.type.fields.4.defaultValue'),
                                'computed': false
                            }, {
                                'type': 'wix:Image',
                                'defaultValue': {
                                    'height': 1276,
                                    'src': 'c8381ce0e7a94ad09ceaada2fc64081d.jpg',
                                    'width': 1920,
                                    '_type': 'wix:Image',
                                    'title': ''
                                },
                                'displayName': i18n.translate('i7ek4o5x.structure.custom.template.type.fields.5.displayName'),
                                'searchable': false,
                                'name': 'image',
                                'computed': false
                            }, {
                                'defaultValue': {
                                    'videoId': 'f36EEfI0yr8',
                                    '_type': 'wix:Video',
                                    'videoType': 'YOUTUBE'
                                },
                                'name': 'i6ckxtsf',
                                'type': 'wix:Video',
                                'searchable': false,
                                'displayName': i18n.translate('i7ek4o5x.structure.custom.template.type.fields.6.displayName'),
                                'computed': false
                            }, {
                                'name': 'Strng_sBttn0-v1c',
                                'defaultValue': i18n.translate('i7ek4o5x.structure.custom.template.type.fields.7.defaultValue'),
                                'searchable': false,
                                'metadata': {'showAsHint': 'AsButton'},
                                'type': 'String',
                                'displayName': i18n.translate('i7ek4o5x.structure.custom.template.type.fields.7.displayName'),
                                'computed': false
                            }, {
                                'name': 'links',
                                'type': 'wix:Map',
                                'searchable': false,
                                'displayName': '',
                                'defaultValue': {'_type': 'wix:Map'},
                                'computed': false
                            }]
                        },
                        'views': [{
                            'name': 'NewsPostsView_i6cku5qn358_dup_i6m55rw984_dup_i6qm3pb3334_dup_i6rnvu26122_dup_i70h8xjn180_dup_i7ek4nze1',
                            'forType': 'Array',
                            'vars': {'itemSpacing': 60, 'paginationColor': 'color_15'},
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'paginatedlist',
                                    'comp': {
                                        'name': 'PaginatedList',
                                        'hidden': {'$expr': 'eq(Array.length(this), 0)'},
                                        'hidePagination': 'true',
                                        'itemsPerPage': '10',
                                        'templates': {
                                            'item': {
                                                'data': '$positionInParent',
                                                'comp': {
                                                    'name': 'SwitchBox',
                                                    'cases': {
                                                        'default': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'NewsPostsView_i6cku5qn358_dup_i6m55rw984_dup_i6qm3pb3334_dup_i6rnvu26122_dup_i70h8xjn180_dup_i7ek4nze1'}
                                                        }, {
                                                            'id': 'footerSpacer',
                                                            'comp': {
                                                                'name': 'VSpacer',
                                                                'size': {'$expr': '$itemSpacing'}
                                                            }
                                                        }, {
                                                            'id': 'gutterLine',
                                                            'comp': {'name': 'HorizontalLine', 'hidden': 'true'},
                                                            'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                        }],
                                                        'last': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'NewsPostsView_i6cku5qn358_dup_i6m55rw984_dup_i6qm3pb3334_dup_i6rnvu26122_dup_i70h8xjn180_dup_i7ek4nze1'}
                                                        }, {
                                                            'comp': {
                                                                'name': 'VBox',
                                                                'hidden': 'true',
                                                                'items': [{
                                                                    'id': 'footerSpacer',
                                                                    'comp': {
                                                                        'name': 'VSpacer',
                                                                        'size': {'$expr': '$itemSpacing'}
                                                                    }
                                                                }, {
                                                                    'id': 'gutterLine',
                                                                    'comp': {
                                                                        'name': 'HorizontalLine',
                                                                        'hidden': 'true'
                                                                    },
                                                                    'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                                }]
                                                            }, 'id': 'def_7'
                                                        }, {
                                                            'comp': {'name': 'VSpacer', 'size': '9'},
                                                            'id': 'def_10'
                                                        }, {
                                                            'id': 'paginationNavigationBox',
                                                            'comp': {
                                                                'name': 'HBox',
                                                                'hidden': {'$expr': '$hidePagination'},
                                                                'box-align': 'center',
                                                                'items': [{
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_12'
                                                                }, {
                                                                    'value': '◄',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationPrev',
                                                                        'events': {'dom:click': 'prevPageClicked'}
                                                                    },
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_13'
                                                                }, {
                                                                    'value': {'$expr': 'String.concat($currentPage,\' / \',$maxPage)'},
                                                                    'comp': {'name': 'Label'},
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_14'
                                                                }, {
                                                                    'value': '►',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationNext',
                                                                        'events': {'dom:click': 'nextPageClicked'}
                                                                    },
                                                                    'id': 'def_15'
                                                                }, {
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_16'
                                                                }]
                                                            },
                                                            'layout': {'position': 'relative'}
                                                        }]
                                                    },
                                                    'css': {'min-width': '100%'}
                                                },
                                                'id': '$positionInParent'
                                            }
                                        }
                                    }
                                }, {
                                    'id': 'noItemsLabel',
                                    'value': 'There are no items in this list',
                                    'comp': {'name': 'Label', 'hidden': {'$expr': 'gt(String.length(this), 0)'}}
                                }],
                                'editorData': {'isPaginated': true}
                            },
                            'stylesheet': [{
                                '.paginationPrev, .paginationNext': {
                                    'padding': '0 5px',
                                    'display': 'inline-block',
                                    'font-size': '14px',
                                    'cursor': 'pointer',
                                    '-webkit-touch-callout': 'none',
                                    '-webkit-user-select': 'none',
                                    '-khtml-user-select': 'none',
                                    '-moz-user-select': 'none',
                                    '-ms-user-select': 'none',
                                    'user-select': 'none'
                                }
                            }],
                            'customizations': [{
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.hidePagination',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Split your list across multiple pages',
                                    'defaultVal': 'true',
                                    'trueVal': 'false',
                                    'spaceAfter': '10'
                                }
                            }, {
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.itemsPerPage',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '120',
                                    'minVal': '1',
                                    'label': 'Items Per Page',
                                    'spaceAfter': '20',
                                    'hiddenwhen': {
                                        'fieldId': 'paginatedlist',
                                        'key': 'comp.hidePagination',
                                        'value': 'true'
                                    }
                                }
                            }, {
                                'priority': 100,
                                'fieldId': 'vars',
                                'key': 'itemSpacing',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '300',
                                    'minVal': '0',
                                    'label': 'Item Spacing',
                                    'spaceAfter': '20'
                                }
                            }, {
                                'priority': 95,
                                'fieldId': 'gutterLine',
                                'key': 'comp.hidden',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Show item separator',
                                    'defaultVal': 'false',
                                    'trueVal': 'false'
                                }
                            }, {
                                'priority': 90,
                                'fieldId': 'gutterLine',
                                'key': 'comp.style',
                                'input': {
                                    'name': 'changestyle',
                                    'showAs': 'link',
                                    'label': 'Change Item Separator Style',
                                    'spaceAfter': '20'
                                }
                            }],
                            'id': 'i7ek4nzf'
                        }, {
                            'name': 'NewsPostsView_i6cku5qn358_dup_i6m55rw984_dup_i6qm3pb3334_dup_i6rnvu26122_dup_i70h8xjn180_dup_i7ek4nze1',
                            'forType': 'Array',
                            'vars': {'itemSpacing': 60, 'paginationColor': 'color_15'},
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'paginatedlist',
                                    'comp': {
                                        'name': 'PaginatedList',
                                        'hidden': {'$expr': 'eq(Array.length(this), 0)'},
                                        'hidePagination': 'true',
                                        'itemsPerPage': '10',
                                        'templates': {
                                            'item': {
                                                'data': '$positionInParent',
                                                'comp': {
                                                    'name': 'SwitchBox',
                                                    'cases': {
                                                        'default': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'NewsPostsView_i6cku5qn358_dup_i6m55rw984_dup_i6qm3pb3334_dup_i6rnvu26122_dup_i70h8xjn180_dup_i7ek4nze1'}
                                                        }, {
                                                            'id': 'footerSpacer',
                                                            'comp': {
                                                                'name': 'VSpacer',
                                                                'size': {'$expr': '$itemSpacing'}
                                                            }
                                                        }, {
                                                            'id': 'gutterLine',
                                                            'comp': {'name': 'HorizontalLine', 'hidden': 'true'},
                                                            'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                        }],
                                                        'last': [{
                                                            'id': 'listItem',
                                                            'comp': {'name': 'NewsPostsView_i6cku5qn358_dup_i6m55rw984_dup_i6qm3pb3334_dup_i6rnvu26122_dup_i70h8xjn180_dup_i7ek4nze1'}
                                                        }, {
                                                            'comp': {
                                                                'name': 'VBox',
                                                                'hidden': 'true',
                                                                'items': [{
                                                                    'id': 'footerSpacer',
                                                                    'comp': {
                                                                        'name': 'VSpacer',
                                                                        'size': {'$expr': '$itemSpacing'}
                                                                    }
                                                                }, {
                                                                    'id': 'gutterLine',
                                                                    'comp': {
                                                                        'name': 'HorizontalLine',
                                                                        'hidden': 'true'
                                                                    },
                                                                    'layout': {'spacerAfter': {'$expr': '$itemSpacing'}}
                                                                }]
                                                            }, 'id': 'def_7'
                                                        }, {
                                                            'comp': {'name': 'VSpacer', 'size': '9'},
                                                            'id': 'def_10'
                                                        }, {
                                                            'id': 'paginationNavigationBox',
                                                            'comp': {
                                                                'name': 'HBox',
                                                                'hidden': {'$expr': '$hidePagination'},
                                                                'box-align': 'center',
                                                                'items': [{
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_12'
                                                                }, {
                                                                    'value': '◄',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationPrev',
                                                                        'events': {'dom:click': 'prevPageClicked'}
                                                                    },
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_13'
                                                                }, {
                                                                    'value': {'$expr': 'String.concat($currentPage,\' / \',$maxPage)'},
                                                                    'comp': {'name': 'Label'},
                                                                    'layout': {'spacerAfter': 4},
                                                                    'id': 'def_14'
                                                                }, {
                                                                    'value': '►',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'cssClass': 'paginationNext',
                                                                        'events': {'dom:click': 'nextPageClicked'}
                                                                    },
                                                                    'id': 'def_15'
                                                                }, {
                                                                    'comp': {'name': 'HSpacer', 'size': '*'},
                                                                    'id': 'def_16'
                                                                }]
                                                            },
                                                            'layout': {'position': 'relative'}
                                                        }]
                                                    },
                                                    'css': {'min-width': '100%'}
                                                },
                                                'id': '$positionInParent'
                                            }
                                        }
                                    }
                                }, {
                                    'id': 'noItemsLabel',
                                    'value': 'There are no items in this list',
                                    'comp': {'name': 'Label', 'hidden': {'$expr': 'gt(String.length(this), 0)'}}
                                }],
                                'editorData': {'isPaginated': true}
                            },
                            'stylesheet': [{
                                '.paginationPrev, .paginationNext': {
                                    'padding': '0 5px',
                                    'display': 'inline-block',
                                    'font-size': '14px',
                                    'cursor': 'pointer',
                                    '-webkit-touch-callout': 'none',
                                    '-webkit-user-select': 'none',
                                    '-khtml-user-select': 'none',
                                    '-moz-user-select': 'none',
                                    '-ms-user-select': 'none',
                                    'user-select': 'none'
                                }
                            }],
                            'customizations': [{
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.hidePagination',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Split your list across multiple pages',
                                    'defaultVal': 'true',
                                    'trueVal': 'false',
                                    'spaceAfter': '10'
                                }
                            }, {
                                'priority': 105,
                                'fieldId': 'paginatedlist',
                                'key': 'comp.itemsPerPage',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '120',
                                    'minVal': '1',
                                    'label': 'Items Per Page',
                                    'spaceAfter': '20',
                                    'hiddenwhen': {
                                        'fieldId': 'paginatedlist',
                                        'key': 'comp.hidePagination',
                                        'value': 'true'
                                    }
                                }
                            }, {
                                'priority': 100,
                                'fieldId': 'vars',
                                'key': 'itemSpacing',
                                'input': {
                                    'name': 'slider',
                                    'maxVal': '300',
                                    'minVal': '0',
                                    'label': 'Item Spacing',
                                    'spaceAfter': '20'
                                }
                            }, {
                                'priority': 95,
                                'fieldId': 'gutterLine',
                                'key': 'comp.hidden',
                                'input': {
                                    'name': 'checkbox',
                                    'falseVal': 'true',
                                    'label': 'Show item separator',
                                    'defaultVal': 'false',
                                    'trueVal': 'false'
                                }
                            }, {
                                'priority': 90,
                                'fieldId': 'gutterLine',
                                'key': 'comp.style',
                                'input': {
                                    'name': 'changestyle',
                                    'showAs': 'link',
                                    'label': 'Change Item Separator Style',
                                    'spaceAfter': '20'
                                }
                            }],
                            'id': 'i7ek4nzf',
                            'format': 'Mobile'
                        }, {
                            'name': 'NewsPostsView_i6cku5qn358_dup_i6m55rw984_dup_i6qm3pb3334_dup_i6rnvu26122_dup_i70h8xjn180_dup_i7ek4nze1',
                            'forType': 'NewsPosts_i6cku5qm357',
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'Top',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Top section',
                                            'alignment': 'left',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 0}
                                        },
                                        'items': []
                                    },
                                    'layout': {'min-width': 0}
                                }, {
                                    'id': 'topSpacer',
                                    'comp': {'name': 'VSpacer', 'size': 0}
                                }, {
                                    'comp': {
                                        'name': 'VBox',
                                        'items': [{
                                            'id': 'def_3_0',
                                            'comp': {
                                                'name': 'HBox',
                                                'orientation': 'horizontal',
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'displayName': 'def_3_0',
                                                    'alignment': 'left',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 3,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'items': [{
                                                    'id': 'Left',
                                                    'comp': {
                                                        'name': 'VBox',
                                                        'orientation': 'vertical',
                                                        'items': [{
                                                            'id': 'Left_0',
                                                            'comp': {
                                                                'name': 'FieldBox',
                                                                'items': [{
                                                                    'id': 'fld_i70imvqs465',
                                                                    'data': 'i6ckxtsf',
                                                                    'comp': {
                                                                        'name': 'Field',
                                                                        'width': 280,
                                                                        'height': 220,
                                                                        'items': [{
                                                                            'id': 'fld_i70imvqs465_proxy',
                                                                            'data': 'i6ckxtsf',
                                                                            'comp': {'name': 'Video'},
                                                                            'layout': {'position': 'relative'}
                                                                        }],
                                                                        'hidden': true,
                                                                        'labelPosition': 'none',
                                                                        'spacers': {
                                                                            'before': 0,
                                                                            'after': 0,
                                                                            'xax-before': 0,
                                                                            'xax-after': 10
                                                                        },
                                                                        'box-align': 'left'
                                                                    }
                                                                }],
                                                                'orientation': 'horizontal',
                                                                'editorData': {
                                                                    'labelPosition': 'none',
                                                                    'displayName': 'Left_0',
                                                                    'alignment': 'left',
                                                                    'spacers': {
                                                                        'before': 0,
                                                                        'after': 3,
                                                                        'xax-before': 3,
                                                                        'xax-after': 0
                                                                    }
                                                                }
                                                            },
                                                            'layout': {'min-width': 0}
                                                        }, {
                                                            'id': 'Left_1',
                                                            'comp': {
                                                                'name': 'FieldBox',
                                                                'orientation': 'horizontal',
                                                                'editorData': {
                                                                    'labelPosition': 'none',
                                                                    'displayName': 'Left_1',
                                                                    'alignment': 'left',
                                                                    'spacers': {
                                                                        'before': 0,
                                                                        'after': 3,
                                                                        'xax-before': 3,
                                                                        'xax-after': 0
                                                                    }
                                                                },
                                                                'items': [{
                                                                    'id': 'fld_i6m58mzj129',
                                                                    'data': 'image',
                                                                    'comp': {
                                                                        'name': 'Field',
                                                                        'width': 280,
                                                                        'height': 227,
                                                                        'items': [{
                                                                            'id': 'fld_i6m58mzj129_proxy',
                                                                            'data': 'image',
                                                                            'comp': {
                                                                                'name': 'Image',
                                                                                'style': 'wp2'
                                                                            }
                                                                        }],
                                                                        'hidden': false,
                                                                        'labelPosition': 'none',
                                                                        'spacers': {
                                                                            'before': 0,
                                                                            'after': 0,
                                                                            'xax-before': 0,
                                                                            'xax-after': 10
                                                                        },
                                                                        'box-align': 'left'
                                                                    }
                                                                }]
                                                            },
                                                            'layout': {'min-width': 0}
                                                        }],
                                                        'editorData': {
                                                            'labelPosition': 'none',
                                                            'alignment': 'left',
                                                            'displayName': 'Left section',
                                                            'spacers': {
                                                                'before': 0,
                                                                'after': 3,
                                                                'xax-before': 0,
                                                                'xax-after': 0
                                                            }
                                                        },
                                                        'pack': 'start'
                                                    },
                                                    'layout': {'spacerAfter': 0, 'min-width': 0}
                                                }]
                                            },
                                            'layout': {'min-width': 0}
                                        }, {
                                            'id': 'def_3_1',
                                            'comp': {
                                                'name': 'HBox',
                                                'orientation': 'horizontal',
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'displayName': 'def_3_1',
                                                    'alignment': 'left',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 3,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'items': [{
                                                    'id': 'leftToCenterSpacer',
                                                    'comp': {'name': 'HSpacer', 'size': 0}
                                                }, {
                                                    'id': 'Center',
                                                    'comp': {
                                                        'name': 'FieldBox',
                                                        'orientation': 'vertical',
                                                        'items': [{
                                                            'id': 'text_1',
                                                            'data': 'title',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'box-align': 'start',
                                                                'width': 86,
                                                                'width-mode': 'auto',
                                                                'min-lines': 0,
                                                                'max-lines': 0,
                                                                'items': [{
                                                                    'id': 'text_1_proxy',
                                                                    'data': 'title',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'style': 'Heading S',
                                                                        'bold': false,
                                                                        'italic': false,
                                                                        'underline': false
                                                                    },
                                                                    'layout': {'text-align': 'left'}
                                                                }],
                                                                'spacers': {
                                                                    'before': 0,
                                                                    'after': 0,
                                                                    'xax-before': 0
                                                                },
                                                                'height-mode': 'auto'
                                                            }
                                                        }, {
                                                            'id': 'fld_hfnxgxqo',
                                                            'data': 'Strng_sTxt1',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': 86,
                                                                'width-mode': 'auto',
                                                                'min-lines': '0',
                                                                'max-lines': '0',
                                                                'items': [{
                                                                    'id': 'fld_hfnxgxqo_proxy',
                                                                    'data': 'Strng_sTxt1',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'color': 'color_14',
                                                                        'style': 'Body L',
                                                                        'bold': false,
                                                                        'underline': false,
                                                                        'italic': false
                                                                    },
                                                                    'layout': {'text-align': 'left'}
                                                                }],
                                                                'spacers': {
                                                                    'before': 0,
                                                                    'after': 0,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                },
                                                                'labelPosition': 'none',
                                                                'hidden': true
                                                            }
                                                        }, {
                                                            'id': 'fld_i9igmhel27',
                                                            'data': 'i9ig8mns',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': '200',
                                                                'width-mode': 'auto',
                                                                'min-lines': '0',
                                                                'max-lines': '0',
                                                                'items': [{
                                                                    'id': 'fld_i9igmhel27_proxy',
                                                                    'data': 'i9ig8mns',
                                                                    'comp': {
                                                                        'name': 'Label',
                                                                        'color': 'color_14',
                                                                        'style': 'Body L'
                                                                    },
                                                                    'layout': {'text-align': 'left'}
                                                                }],
                                                                'spacers': {
                                                                    'before': 0,
                                                                    'after': 3,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                },
                                                                'hidden': true,
                                                                'labelPosition': 'none'
                                                            },
                                                            'experiment': 'NewListFields'
                                                        }, {
                                                            'id': 'fld_i9idw1er133',
                                                            'data': 'i9idp2am',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': '200',
                                                                'items': [{
                                                                    'id': 'fld_i9idw1er133_proxy',
                                                                    'data': 'i9idp2am',
                                                                    'comp': {
                                                                        'name': 'Date',
                                                                        'format': 'mmmm dd, yyyy',
                                                                        'color': 'color_14',
                                                                        'bold': false,
                                                                        'underline': false,
                                                                        'italic': true
                                                                    },
                                                                    'layout': {'text-align': 'left'}
                                                                }],
                                                                'hidden': false,
                                                                'labelPosition': 'none',
                                                                'spacers': {
                                                                    'before': 0,
                                                                    'after': 3,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                },
                                                                'width-mode': 'auto',
                                                                'min-lines': '0',
                                                                'max-lines': '0'
                                                            },
                                                            'experiment': 'NewListFields'
                                                        }, {
                                                            'id': 'fld_hfxs75de',
                                                            'data': 'wxRchTxt_sTxt0',
                                                            'comp': {
                                                                'name': 'TextField',
                                                                'width': 86,
                                                                'items': [{
                                                                    'id': 'fld_hfxs75de_proxy',
                                                                    'data': 'wxRchTxt_sTxt0',
                                                                    'comp': {'name': 'Label'},
                                                                    'layout': {'text-align': 'left'}
                                                                }],
                                                                'hidden': false,
                                                                'labelPosition': 'none',
                                                                'spacers': {
                                                                    'before': 10,
                                                                    'after': 0,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                },
                                                                'width-mode': 'auto',
                                                                'min-lines': '0',
                                                                'max-lines': '0'
                                                            }
                                                        }, {
                                                            'id': 'fld_i70if2w4348',
                                                            'data': 'Strng_sBttn0-v1c',
                                                            'comp': {
                                                                'name': 'Field',
                                                                'width': 280,
                                                                'items': [{
                                                                    'id': 'fld_i70if2w4348_proxy',
                                                                    'data': 'Strng_sBttn0-v1c',
                                                                    'comp': {
                                                                        'name': 'Button2',
                                                                        'style': 'b1',
                                                                        'align': 'center'
                                                                    },
                                                                    'layout': {'position': 'relative'}
                                                                }],
                                                                'hidden': false,
                                                                'labelPosition': 'none',
                                                                'spacers': {
                                                                    'before': 20,
                                                                    'after': 0,
                                                                    'xax-before': 0,
                                                                    'xax-after': 0
                                                                },
                                                                'height': 44,
                                                                'box-align': 'left'
                                                            }
                                                        }],
                                                        'editorData': {
                                                            'labelPosition': 'none',
                                                            'alignment': 'left',
                                                            'displayName': 'Center section',
                                                            'spacers': {
                                                                'before': 0,
                                                                'after': 3,
                                                                'xax-before': 0,
                                                                'xax-after': 0
                                                            }
                                                        },
                                                        'pack': 'start'
                                                    },
                                                    'layout': {'box-flex': 1, 'min-width': 0}
                                                }]
                                            },
                                            'layout': {'min-width': 0}
                                        }, {
                                            'id': 'def_3_2',
                                            'comp': {
                                                'name': 'HBox',
                                                'orientation': 'horizontal',
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'displayName': 'def_3_2',
                                                    'alignment': 'left',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 3,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'items': [{
                                                    'id': 'centerToRightSpacer',
                                                    'comp': {'name': 'HSpacer', 'size': 3}
                                                }, {
                                                    'id': 'Right',
                                                    'comp': {
                                                        'name': 'FieldBox',
                                                        'orientation': 'vertical',
                                                        'editorData': {
                                                            'labelPosition': 'none',
                                                            'displayName': 'Right section',
                                                            'alignment': 'left',
                                                            'spacers': {
                                                                'before': 0,
                                                                'after': 3,
                                                                'xax-before': 0,
                                                                'xax-after': 0
                                                            }
                                                        },
                                                        'items': []
                                                    },
                                                    'layout': {'min-width': 0}
                                                }]
                                            },
                                            'layout': {'min-width': 0}
                                        }],
                                        'orientation': 'vertical'
                                    }, 'id': 'def_3'
                                }, {
                                    'id': 'Bottom',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Bottom section',
                                            'alignment': 'left',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 0}
                                        },
                                        'items': [],
                                        'pack': 'start'
                                    },
                                    'layout': {'box-flex-pack': 'start', 'min-width': 0}
                                }, {'id': 'bottomSpacer', 'comp': {'name': 'VSpacer', 'size': 0}}]
                            },
                            'id': 'def_0',
                            'editorData': {'wasChanged': true, 'originalWidth': 601},
                            'format': 'Mobile'
                        }, {
                            'name': 'NewsPostsView_i6cku5qn358_dup_i6m55rw984_dup_i6qm3pb3334_dup_i6rnvu26122_dup_i70h8xjn180_dup_i7ek4nze1',
                            'forType': 'NewsPosts_i6cku5qm357',
                            'comp': {
                                'name': 'VBox',
                                'items': [{
                                    'id': 'Top',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Top section',
                                            'alignment': 'left',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 3}
                                        },
                                        'items': []
                                    },
                                    'layout': {'min-width': 0}
                                }, {
                                    'id': 'topSpacer',
                                    'comp': {'name': 'VSpacer', 'size': '0'}
                                }, {
                                    'comp': {
                                        'name': 'HBox',
                                        'items': [{
                                            'id': 'Left',
                                            'comp': {
                                                'name': 'FieldBox',
                                                'orientation': 'horizontal',
                                                'items': [{
                                                    'id': 'fld_i70imvqs465',
                                                    'data': 'i6ckxtsf',
                                                    'comp': {
                                                        'name': 'Field',
                                                        'width': 330,
                                                        'height': 227,
                                                        'items': [{
                                                            'id': 'fld_i70imvqs465_proxy',
                                                            'data': 'i6ckxtsf',
                                                            'comp': {'name': 'Video'},
                                                            'layout': {'position': 'relative'}
                                                        }],
                                                        'hidden': true,
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 30,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'box-align': 'left'
                                                    }
                                                }, {
                                                    'id': 'fld_i6m58mzj129',
                                                    'data': 'image',
                                                    'comp': {
                                                        'name': 'Field',
                                                        'width': 330,
                                                        'height': 227,
                                                        'items': [{
                                                            'id': 'fld_i6m58mzj129_proxy',
                                                            'data': 'image',
                                                            'comp': {'name': 'Image', 'style': 'wp2'}
                                                        }],
                                                        'hidden': false,
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 30,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'box-align': 'left'
                                                    }
                                                }],
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'alignment': 'left',
                                                    'displayName': 'Left section',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 0,
                                                        'xax-after': 20
                                                    }
                                                },
                                                'pack': 'start'
                                            },
                                            'layout': {'spacerAfter': 0, 'min-width': 0}
                                        }, {
                                            'id': 'leftToCenterSpacer',
                                            'comp': {'name': 'HSpacer', 'size': '0'}
                                        }, {
                                            'id': 'Center',
                                            'comp': {
                                                'name': 'FieldBox',
                                                'orientation': 'vertical',
                                                'items': [{
                                                    'id': 'text_1',
                                                    'data': 'title',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'box-align': 'start',
                                                        'width': '200',
                                                        'width-mode': 'auto',
                                                        'min-lines': 0,
                                                        'max-lines': 0,
                                                        'items': [{
                                                            'id': 'text_1_proxy',
                                                            'data': 'title',
                                                            'comp': {
                                                                'name': 'Label',
                                                                'style': 'Heading S',
                                                                'bold': false,
                                                                'italic': false,
                                                                'underline': false,
                                                                'color': 'color_14'
                                                            },
                                                            'layout': {'text-align': 'left'}
                                                        }],
                                                        'spacers': {'before': 0, 'after': 0, 'xax-before': 0},
                                                        'height-mode': 'auto'
                                                    }
                                                }, {
                                                    'id': 'fld_hfnxgxqo',
                                                    'data': 'Strng_sTxt1',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0',
                                                        'items': [{
                                                            'id': 'fld_hfnxgxqo_proxy',
                                                            'data': 'Strng_sTxt1',
                                                            'comp': {
                                                                'name': 'Label',
                                                                'style': 'Body L',
                                                                'bold': false,
                                                                'italic': false,
                                                                'underline': false,
                                                                'color': 'color_14'
                                                            },
                                                            'layout': {'text-align': 'left'}
                                                        }],
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 0,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'labelPosition': 'none',
                                                        'hidden': true
                                                    }
                                                }, {
                                                    'id': 'fld_i9idp2c764',
                                                    'data': 'i9idp2am',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'items': [{
                                                            'id': 'fld_i9idp2c764_proxy',
                                                            'data': 'i9idp2am',
                                                            'comp': {
                                                                'name': 'Date',
                                                                'color': 'color_14',
                                                                'format': 'mmmm dd, yyyy',
                                                                'bold': false,
                                                                'underline': false,
                                                                'italic': true
                                                            },
                                                            'layout': {'text-align': 'left'}
                                                        }],
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 3,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'hidden': false,
                                                        'labelPosition': 'none',
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0'
                                                    },
                                                    'experiment': 'NewListFields'
                                                }, {
                                                    'id': 'fld_i9ig8mpe62',
                                                    'data': 'i9ig8mns',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'items': [{
                                                            'id': 'fld_i9ig8mpe62_proxy',
                                                            'data': 'i9ig8mns',
                                                            'comp': {
                                                                'name': 'Label',
                                                                'color': 'color_14',
                                                                'style': 'Body L'
                                                            },
                                                            'layout': {'text-align': 'left'}
                                                        }],
                                                        'hidden': true,
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 0,
                                                            'after': 3,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0'
                                                    },
                                                    'experiment': 'NewListFields'
                                                }, {
                                                    'id': 'fld_i92r8tz421',
                                                    'data': 'wxRchTxt_sTxt0',
                                                    'comp': {
                                                        'name': 'TextField',
                                                        'width': '200',
                                                        'items': [{
                                                            'id': 'fld_i92r8tz421_proxy',
                                                            'data': 'wxRchTxt_sTxt0',
                                                            'comp': {'name': 'Label', 'color': 'color_14'},
                                                            'layout': {'text-align': 'left'}
                                                        }],
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 8,
                                                            'after': 0,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'hidden': false,
                                                        'width-mode': 'auto',
                                                        'min-lines': '0',
                                                        'max-lines': '0'
                                                    }
                                                }, {
                                                    'id': 'fld_i70if2w4348',
                                                    'data': 'Strng_sBttn0-v1c',
                                                    'comp': {
                                                        'name': 'Field',
                                                        'width': 160,
                                                        'items': [{
                                                            'id': 'fld_i70if2w4348_proxy',
                                                            'data': 'Strng_sBttn0-v1c',
                                                            'comp': {
                                                                'name': 'Button2',
                                                                'style': 'b1',
                                                                'align': 'center'
                                                            },
                                                            'layout': {'position': 'relative'}
                                                        }],
                                                        'labelPosition': 'none',
                                                        'spacers': {
                                                            'before': 15,
                                                            'after': 0,
                                                            'xax-before': 0,
                                                            'xax-after': 0
                                                        },
                                                        'hidden': false,
                                                        'height': '40',
                                                        'box-align': 'start'
                                                    }
                                                }],
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'alignment': 'left',
                                                    'displayName': 'Center section',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 0,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'pack': 'start'
                                            },
                                            'layout': {'box-flex': 1, 'min-width': 0}
                                        }, {
                                            'id': 'centerToRightSpacer',
                                            'comp': {'name': 'HSpacer', 'size': '3'}
                                        }, {
                                            'id': 'Right',
                                            'comp': {
                                                'name': 'FieldBox',
                                                'orientation': 'vertical',
                                                'editorData': {
                                                    'labelPosition': 'none',
                                                    'displayName': 'Right section',
                                                    'alignment': 'left',
                                                    'spacers': {
                                                        'before': 0,
                                                        'after': 3,
                                                        'xax-before': 3,
                                                        'xax-after': 0
                                                    }
                                                },
                                                'items': []
                                            },
                                            'layout': {'min-width': 0}
                                        }]
                                    }, 'id': 'def_3'
                                }, {
                                    'id': 'Bottom',
                                    'comp': {
                                        'name': 'FieldBox',
                                        'orientation': 'vertical',
                                        'editorData': {
                                            'labelPosition': 'none',
                                            'displayName': 'Bottom section',
                                            'alignment': 'left',
                                            'spacers': {'before': 0, 'after': 3, 'xax-before': 0, 'xax-after': 3}
                                        },
                                        'items': [],
                                        'pack': 'start'
                                    },
                                    'layout': {'box-flex-pack': 'start', 'min-width': 0}
                                }, {'id': 'bottomSpacer', 'comp': {'name': 'VSpacer', 'size': '0'}}]
                            },
                            'id': 'def_0',
                            'editorData': {'wasChanged': true}
                        }],
                        'items': [{
                            'title': i18n.translate('i7ek4o5x.structure.custom.template.items.0.title'),
                            'image': {
                                '_type': 'wix:Image',
                                'title': '',
                                'src': '8d13be_232af915f9984e0bb5a42dd5382abad8.jpg',
                                'width': 700,
                                'height': 467,
                                'alt': ''
                            },
                            'Strng_sTxt1': i18n.translate('i7ek4o5x.structure.custom.template.items.0.Strng_sTxt1'),
                            'wxRchTxt_sTxt0': {
                                'version': 1,
                                '_type': 'wix:RichText',
                                'links': [],
                                'text': i18n.translate('i7ek4o5x.structure.custom.template.items.0.wxRchTxt_sTxt0')
                            },
                            'links': {'_type': 'wix:Map'},
                            'Strng_sBttn0-v1c': i18n.translate('i7ek4o5x.structure.custom.template.items.0.Strng_sBttn0-v1c'),
                            'i6ckxtsf': {
                                '_type': 'wix:Video',
                                'videoId': 'z1pSAwjC4JI',
                                'videoType': 'YOUTUBE',
                                'thumbnail': ''
                            },
                            'i9idp2am': {'_type': 'wix:Date', 'iso': '2023-01-03T00:00:00.000Z'},
                            'i9ig8mns': i18n.translate('i7ek4o5x.structure.custom.template.items.0.i9ig8mns')
                        }, {
                            'title': i18n.translate('i7ek4o5x.structure.custom.template.items.1.title'),
                            'image': {
                                '_type': 'wix:Image',
                                'title': '',
                                'src': '8d13be_36d622561b4c453794c71ba4de8aea99.png',
                                'width': 400,
                                'height': 267,
                                'alt': ''
                            },
                            'Strng_sTxt1': i18n.translate('i7ek4o5x.structure.custom.template.items.1.Strng_sTxt1'),
                            'wxRchTxt_sTxt0': {
                                'version': 1,
                                '_type': 'wix:RichText',
                                'links': [],
                                'text': i18n.translate('i7ek4o5x.structure.custom.template.items.1.wxRchTxt_sTxt0')
                            },
                            'links': {'_type': 'wix:Map'},
                            'Strng_sBttn0-v1c': i18n.translate('i7ek4o5x.structure.custom.template.items.1.Strng_sBttn0-v1c'),
                            'i6ckxtsf': {
                                '_type': 'wix:Video',
                                'videoId': 'f36EEfI0yr8',
                                'videoType': 'YOUTUBE',
                                'thumbnail': ''
                            },
                            'i9idp2am': {'_type': 'wix:Date', 'iso': '2023-02-23T00:00:00.000Z'},
                            'i9ig8mns': i18n.translate('i7ek4o5x.structure.custom.template.items.1.i9ig8mns')
                        }, {
                            'title': i18n.translate('i7ek4o5x.structure.custom.template.items.2.title'),
                            'image': {
                                '_type': 'wix:Image',
                                'title': '',
                                'src': '8d13be_0ef0c5f95548458085a267f142ae1697.png',
                                'width': 400,
                                'height': 267,
                                'alt': ''
                            },
                            'Strng_sTxt1': i18n.translate('i7ek4o5x.structure.custom.template.items.2.Strng_sTxt1'),
                            'wxRchTxt_sTxt0': {
                                'version': 1,
                                '_type': 'wix:RichText',
                                'links': [],
                                'text': i18n.translate('i7ek4o5x.structure.custom.template.items.2.wxRchTxt_sTxt0')
                            },
                            'links': {'_type': 'wix:Map'},
                            'Strng_sBttn0-v1c': i18n.translate('i7ek4o5x.structure.custom.template.items.2.Strng_sBttn0-v1c'),
                            'i6ckxtsf': {
                                '_type': 'wix:Video',
                                'videoId': 'wGOTBSlYkzU',
                                'videoType': 'YOUTUBE',
                                'thumbnail': ''
                            },
                            'i9idp2am': {'_type': 'wix:Date', 'iso': '2023-05-28T00:00:00.000Z'},
                            'i9ig8mns': i18n.translate('i7ek4o5x.structure.custom.template.items.2.i9ig8mns')
                        }],
                        'displayName': i18n.translate('i7ek4o5x.structure.custom.template.displayName')
                    }
                },
                'id': 'i7ek4o5x'
            },
            'preset': {
                'rect': {'width': 162, 'height': 199, 'x': 0, 'y': 223},
                'label': 'i7ek4o5x.structure.custom.template.displayName',
                'tags': null
            }
        }],
        'compTypes': ['wixapps.integration.components.AppPart2']
    },
    'help': {'hide': false, 'text': 'add_section_info_text_newsevents'}
}
