import { parse } from '@wix/deeplink-core';
import { check, show } from '@wix/deeplink-editor';

export function tryShow(serializedFeature: string): void {
  const feature = parse(serializedFeature);
  check(feature).then((isAvailable) => {
    if (isAvailable) {
      show(feature);
    }
  });
}
