// @ts-nocheck
import _ from 'lodash';

function getPluginsForType(plugins, compType) {
  return [].concat(plugins[compType] || [], plugins['*'] || []);
}

function runPluginsOnSerializedComponent(
  actionPluginsProperty,
  serializedComp,
  editorAPI,
) {
  const componentPlugins = getPluginsForType(
    editorAPI[actionPluginsProperty],
    serializedComp.componentType,
  );
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/reduce
  serializedComp = _.reduce(
    componentPlugins,
    function (comp, plugin) {
      return plugin(editorAPI, comp);
    },
    serializedComp,
  );

  if (serializedComp.type === 'Container') {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/map
    serializedComp.components = _.map(
      serializedComp.components,
      function (serializedChild) {
        return runPluginsOnSerializedComponent(
          actionPluginsProperty,
          serializedChild,
          editorAPI,
        );
      },
    );
  }

  return serializedComp;
}

function runCopyPluginOnSerializedComponent(
  serializedComp,
  compRef,
  editorAPI,
) {
  const compType = serializedComp.componentType;
  if (editorAPI.copyComponentPlugins[compType]) {
    serializedComp = editorAPI.copyComponentPlugins[compType](
      compRef,
      serializedComp,
    );
  }
  return serializedComp;
}

const runPastePluginOnSerializedComponent =
  runPluginsOnSerializedComponent.bind(null, 'pasteComponentPlugins');
const runSerializeComponentPlugin = runPluginsOnSerializedComponent.bind(
  null,
  'serializeComponentPlugins',
);

export {
  runCopyPluginOnSerializedComponent,
  runPastePluginOnSerializedComponent,
  runSerializeComponentPlugin,
};
