import { BaseEntryScope, createEntryPoint } from '@/apilib';
import { init } from './init';
import { SiteSegmentMediaBackgroundPublicApi } from './api';
import { SiteSegmentMediaBackgroundApiKey } from './publicApiKey';
import {
  EditorCoreApiKey,
  ComponentsApiKey,
  EditorAPIKey,
  SectionsApiKey,
} from '@/apis';

export class Scope extends BaseEntryScope {
  editorAPI = this.useDependency(EditorAPIKey);
  editorCoreApi = this.useDependency(EditorCoreApiKey);
  components = this.useDependency(ComponentsApiKey);
  sections = this.useDependency(SectionsApiKey);
}

export const SiteSegmentMediaBackgroundEntryPoint = createEntryPoint({
  name: 'SiteSegmentMediaBackground',
  Scope,
  publicApi({ contributeApi }) {
    contributeApi(
      SiteSegmentMediaBackgroundApiKey,
      SiteSegmentMediaBackgroundPublicApi,
    );
  },
  initialize(scope) {
    init(scope);
  },
});
