import React, { useCallback, useEffect, useState } from 'react';
import * as util from '@/util';
import { TpaSettingsPanelFrame } from '../frames';
import type { EditorAPI } from '@/editorAPI';
import type { MapDispatchToProps, ThunkAction } from 'types/redux';
import type { CompRef } from 'types/documentServices';
import { TreeListItem } from '@wix/wix-base-ui';
import { translate } from '@/i18n';
import {
  rcmMovePluginSelect,
  pluginMoved,
  clickOnMovePluginHoverOnSlot,
} from '@wix/bi-logger-editor/v2';
interface SlotPlaceholder {
  slotRef: CompRef;
  name: string;
}

interface SlotPlaceholderItemProps {
  slotPlaceholder: SlotPlaceholder;
  slotPlaceholderIndex: number;
  highlightSlot: (compRef: CompRef) => void;
  unhighlightSlot: (compRef: CompRef) => void;
  moveToSlot: (compRef: CompRef) => Promise<void>;
}

const SlotPlaceholderItem: React.FC<SlotPlaceholderItemProps> = ({
  highlightSlot,
  unhighlightSlot,
  moveToSlot,
  slotPlaceholder,
  slotPlaceholderIndex,
}) => {
  const onSlotHighlight = useCallback(() => {
    highlightSlot(slotPlaceholder.slotRef);
  }, [highlightSlot, slotPlaceholder]);

  const onSlotUnhighlight = useCallback(() => {
    unhighlightSlot(slotPlaceholder.slotRef);
  }, [unhighlightSlot, slotPlaceholder]);

  const onSlotMove = useCallback(async () => {
    await moveToSlot(slotPlaceholder.slotRef);
  }, [moveToSlot, slotPlaceholder]);

  const getPlaceholderDisplayName = (
    slotPlaceholder: SlotPlaceholder,
    slotPlaceholderIndex: number,
  ) => {
    const slotsPlaceholderDefaultName = 'SlotsPlaceholder';
    if (slotPlaceholder.name === slotsPlaceholderDefaultName) {
      return translate('PLATFORM_Widget_Slots_Move_Plugin_Placeholder_Label', {
        Placeholder_Number: slotPlaceholderIndex,
      });
    }

    return slotPlaceholder.name;
  };

  return (
    <div onMouseEnter={onSlotHighlight} onMouseLeave={onSlotUnhighlight}>
      <TreeListItem
        onClick={onSlotMove}
        shouldTranslate={false}
        dataHook={`slots.movePluginIndex:${slotPlaceholderIndex}`}
        label={getPlaceholderDisplayName(slotPlaceholder, slotPlaceholderIndex)}
      />
    </div>
  );
};

interface SlotSelectionPanelDispatchProps {
  highlightSlot: (compRef: CompRef) => void;
  unhighlightSlot: (compRef: CompRef) => void;
  moveToSlot: (compRef: CompRef) => Promise<void>;
  getSlotsToMove: () => Array<SlotPlaceholder>;
  openHelpArticle: () => void;
  onSlotSelectionPanelOpened: () => void;
}

interface SlotSelectionPanelOwnProps {
  panelName: string;
  style?: Record<string, string>;
  onClose?: () => void;
  slotsPlaceholderCompRef: CompRef;
}

interface SlotSelectionPanelProps
  extends SlotSelectionPanelDispatchProps,
    SlotSelectionPanelOwnProps {}

const SlotSelectionPanel: React.FC<SlotSelectionPanelProps> = (props) => {
  const [slotsToMove] = useState(() => props.getSlotsToMove());

  useEffect(() => {
    props.onSlotSelectionPanelOpened();
  });

  return (
    <TpaSettingsPanelFrame
      panelName={props.panelName}
      title={translate('PLATFORM_Widget_Slots_Move_Plugin_Header')}
      width={288}
      shouldAddScroll
      style={props.style}
      onClose={props.onClose}
      onHelpClicked={props.openHelpArticle}
    >
      {slotsToMove.map((slotPlaceholder, index) => (
        <SlotPlaceholderItem
          key={slotPlaceholder.slotRef.id}
          slotPlaceholderIndex={index + 1}
          slotPlaceholder={slotPlaceholder}
          highlightSlot={props.highlightSlot}
          unhighlightSlot={props.unhighlightSlot}
          moveToSlot={props.moveToSlot}
        />
      ))}
    </TpaSettingsPanelFrame>
  );
};

const getEditorAPI: ThunkAction = (_dispatch, _getState, { editorAPI }) =>
  editorAPI;

const mapDispatchToProps: MapDispatchToProps<
  SlotSelectionPanelDispatchProps,
  SlotSelectionPanelOwnProps
> = (dispatch, { panelName, slotsPlaceholderCompRef }) => {
  const editorAPI: EditorAPI = dispatch(getEditorAPI);

  return {
    onSlotSelectionPanelOpened: () => {
      const slot = editorAPI.platform.widgetPlugins.getWidgetSlot(
        slotsPlaceholderCompRef,
      );

      util.biLogger.report(
        rcmMovePluginSelect({
          slot_id: slotsPlaceholderCompRef.id,
          plugin_id: slot?.pluginInfo?.appDefinitionId,
        }),
      );
    },
    getSlotsToMove: () => {
      return editorAPI.platform.widgetPlugins.getAvailableWidgetSlotsToMoveContent(
        slotsPlaceholderCompRef,
      );
    },
    moveToSlot: async (compRef: CompRef) => {
      editorAPI.panelManager.closePanelByName(panelName);

      await editorAPI.platform.widgetPlugins.moveWidgetToAnotherSlot(
        slotsPlaceholderCompRef,
        compRef,
      );

      const slot = editorAPI.platform.widgetPlugins.getWidgetSlot(
        slotsPlaceholderCompRef,
      );

      util.biLogger.report(
        pluginMoved({
          slot_id_previous: slotsPlaceholderCompRef.id,
          slot_id_current: compRef.id,
          plugin_id: slot?.pluginInfo?.appDefinitionId,
        }),
      );

      editorAPI.platform.widgetPlugins.unhighlightSlot(compRef);
    },
    unhighlightSlot: (compRef: CompRef) => {
      editorAPI.platform.widgetPlugins.unhighlightSlot(compRef);
    },
    highlightSlot: (compRef: CompRef) => {
      editorAPI.platform.widgetPlugins.highlightSlot(compRef);

      const slot = editorAPI.platform.widgetPlugins.getWidgetSlot(
        slotsPlaceholderCompRef,
      );

      util.biLogger.report(
        clickOnMovePluginHoverOnSlot({
          slot_id_current: slotsPlaceholderCompRef.id,
          slot_id_highlighted: compRef.id,
          plugin_id: slot?.pluginInfo?.appDefinitionId,
        }),
      );
    },
    openHelpArticle: () => {
      const moveWidgetPluginHelpArticleId =
        'fbd26fc9-2439-401e-9474-70c7afabea20';
      editorAPI.panelManager.openHelpCenter(moveWidgetPluginHelpArticleId);
    },
  };
};

export default util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  null,
  mapDispatchToProps,
)(SlotSelectionPanel);
