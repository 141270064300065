import React from 'react';
import createReactClass from 'create-react-class';
import _ from 'lodash';
import gfppButton from './gfppButton';
import gfppCustomButton from './gfppCustomButton';

interface GfppButtonGeneratorProps {
  className?: string;
}

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass<GfppButtonGeneratorProps>({
  displayName: 'gfppButtonGenerator',
  render() {
    return React.createElement(
      this.props.template ? gfppCustomButton : gfppButton,
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      _.assign({}, this.props),
    );
  },
});
