// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';
import { Bubble } from '@wix/wix-base-ui';
import DealerUpgradePanel from './dealerUpgradePanel';

function template() {
  const DealerViewer = this.props.dealerViewer;

  return (
    <Bubble
      onMouseEnter={this.props.onMouseEnter}
      onMouseLeave={this.props.onMouseLeave}
      arrowAlignment="top"
      contentStyle={this.bubbleContentStyle}
      arrowStyle={this.bubbleArrowStyle}
    >
      {DealerViewer ? (
        <DealerUpgradePanel
          isFirstSave={this.props.isFirstSave}
          isDraftMode={this.props.isDraftMode}
          sendBi={this.props.sendBi}
          metaSiteId={this.props.metaSiteId}
          metaSiteInstance={this.props.metaSiteInstance}
          fallbackToDefaultUpgradePanel={
            this.props.fallbackToDefaultUpgradePanel
          }
          dealerViewer={DealerViewer}
          key="dealerViewer"
        />
      ) : null}
    </Bubble>
  );
}

const BUBBLE_ARROW_STYLE = {
  position: 'absolute',
  right: 'calc(50% - 8px)',
  top: '53px',
};

const BUBBLE_CONTENT_STYLE = {
  position: 'absolute',
  display: 'block',
  maxWidth: 'none',
  padding: '0',
  top: '60px',
};

class DealerUpgradeDropPanel extends React.Component {
  constructor(props) {
    super(props);
    this.bubbleContentStyle = BUBBLE_CONTENT_STYLE;
    this.bubbleArrowStyle = BUBBLE_ARROW_STYLE;
  }

  render() {
    return template.call(this);
  }
}

DealerUpgradeDropPanel.displayName = 'DealerUpgradeDropPanel';

DealerUpgradeDropPanel.propTypes = {
  onMouseEnter: PropTypes.func.isRequired,
  onMouseLeave: PropTypes.func.isRequired,
  fallbackToDefaultUpgradePanel: PropTypes.func.isRequired,
  isFirstSave: PropTypes.bool.isRequired,
  isDraftMode: PropTypes.bool.isRequired,
  dealerViewer: PropTypes.func.isRequired,
  sendBi: PropTypes.func.isRequired,
  metaSiteId: PropTypes.string.isRequired,
  metaSiteInstance: PropTypes.string.isRequired,
};

export default DealerUpgradeDropPanel;
