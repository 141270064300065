import React from 'react';
import { PanelHeader } from '@wix/wix-base-ui';
import constants from '@/constants';
import { panels } from '@/stateManagement';
import { cx, hoc, workspace } from '@/util';
import type { ThunkAction } from 'types/redux';

interface OwnProps {
  panelClass: string;
  automationId?: string;
  panelIndex: number;
  onClose: FunctionFixMe;
  label?: string;
  styleOverride?: object;
  openPanelBiEvent?: object;
  openPanelBiParams?: object;
  className?: string;
  fullHeight?: boolean;
  dynamicWidth?: boolean;
}

interface HocProps {
  blockOuterScroll: (e: React.MouseEvent) => void;
  onContextMenu: FunctionFixMe;
  close: () => void;
  openHelpCenter: (
    helpId: string,
    props?: object,
    biHelpParams?: object,
  ) => void;
  helpId?: string;
  onHelpClicked?: () => void;
  id?: string;
}

class LeftPanelFrameComponent extends React.Component<
  OwnProps & HocProps & ReturnType<typeof mapDispatchToProps>
> {
  static displayName = constants.PANEL_TYPES.LEFT;

  getFrameStyle = () => this.props.styleOverride;

  UNSAFE_componentWillMount() {
    this.props.closeOpenedLeftPanel();
  }

  render() {
    return (
      <div
        style={this.getFrameStyle()}
        onWheel={this.props.blockOuterScroll}
        onContextMenu={(e) => {
          if (this.props.onContextMenu) {
            this.props.onContextMenu(e);
          }
        }}
        data-hook="left-panel-frame"
        data-aid={this.props.automationId}
        className={cx(
          'left-panel-frame',
          `panel-index-${this.props.panelIndex}`,
          this.props.panelClass,
          {
            'first-panel': this.props.panelIndex === 0,
            'left-panel-frame-full-height': this.props.fullHeight,
            'next-left-panel-frame': workspace.isNewWorkspaceEnabled(),
          },
        )}
      >
        {this.props.label ? (
          <PanelHeader
            key="header"
            onClose={this.props.close}
            // @ts-expect-error
            onHelp={this.props.openHelpCenter}
            noHelpBtn={!this.props.helpId && !this.props.onHelpClicked}
            className="light"
          >
            <span>{this.props.label}</span>
          </PanelHeader>
        ) : null}
        <div
          id={this.props.id}
          className={cx('left-panel-frame-content', this.props.className)}
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}

const WithPanelFrame = panels.hoc.panelFrame(constants.PANEL_TYPES.LEFT)(
  LeftPanelFrameComponent,
) as AnyFixMe;

WithPanelFrame.pure = LeftPanelFrameComponent;

const mapDispatchToProps = (dispatch: FunctionFixMe) => {
  const closeOpenedLeftPanel: ThunkAction = (dispatch, getState) => {
    const openedPanels = panels.selectors.selectOpenPanels(getState());
    const openedLeftPanels = openedPanels.filter(
      (panel) => panel.frameType === constants.PANEL_TYPES.LEFT,
    );

    if (openedLeftPanels.length > 1) {
      dispatch(panels.actions.closePanelByName(openedLeftPanels[0].name));
    }
  };

  return {
    closeOpenedLeftPanel: () => dispatch(closeOpenedLeftPanel),
  };
};

export default hoc.connect(
  hoc.STORES.STATE_ONLY,
  null,
  mapDispatchToProps,
)(WithPanelFrame) as AnyFixMe;
