// @ts-nocheck
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import * as core from '@/core';
import * as coreBi from '@/coreBi';
import React from 'react';
import * as baseUI from '@/baseUI';
import { CustomScroll } from '@wix/wix-base-ui';
import { translate } from '@/i18n';
import BlogSinglePostInfo from './tabs/subPages/blogSinglePostInfo';
import BlogSinglePostSeo from './tabs/subPages/blogSinglePostSeo';
import TpaProductPageInfo from './tabs/subPages/tpaProductPageInfo';
import TpaProductPageSeo from './tabs/subPages/tpaProductPageSeo';
import TpaThankYouPageSeo from './tabs/subPages/tpaThankYouPageSeo';
import TpaCartPageSeo from './tabs/subPages/tpaCartPageSeo';
import TpaCartPageInfo from './tabs/subPages/tpaCartPageInfo';
import TpaThankYouPageInfo from './tabs/subPages/tpaThankYouPageInfo';
import TabLayout from './tabs/tabLayout';
import TabPermissions from './tabs/tabPermissions';
import * as pageMenuItemHelpers from '../utils/pageMenuItemHelpers';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'pageSettingsPanelTabsSubpage',
  mixins: [core.mixins.editorAPIMixin],
  propTypes: {
    menuItem: PropTypes.object.isRequired,
    actions: PropTypes.object, // needed for opening blog
  },
  render() {
    const { pageData } = this.props.menuItem;
    const editorAPI = this.getEditorAPI();
    const isBlog = core.utils.blogTypeUtils.isBlog(
      this.getEditorAPI(),
      this.props.menuItem.pageData,
    );
    const { wixStoreTypeUtils } = core.utils;
    const isWixStoreProductPage =
      wixStoreTypeUtils.isWixStoreProductPage(pageData);
    const isWixStoreCartPage = wixStoreTypeUtils.isWixStoreCartPage(pageData);
    const isWixStoreThankYouPage =
      wixStoreTypeUtils.isWixStoreThankYouPage(pageData);
    const siteName = this.getSiteName();
    const publicUrl = pageMenuItemHelpers.getPublicUrl(editorAPI, pageData);

    return (
      <div>
        <baseUI.tabs.holder
          value={this.state.activeTab}
          onChange={(value) => {
            this.setActiveTab(value);
          }}
          className="top page-settings-tabs"
        >
          <baseUI.tabs.header>
            <baseUI.tabs.label htmlFor="page">
              {translate('Pages_Info_Tab_Title')}
            </baseUI.tabs.label>
            <baseUI.tabs.label htmlFor="layout">
              {translate('Pages_Layouts_Tab_Title')}
            </baseUI.tabs.label>
            <baseUI.tabs.label htmlFor="privacy">
              {translate('Pages_Permissions_Tab_Title')}
            </baseUI.tabs.label>
            <baseUI.tabs.label htmlFor="seo">
              {translate('Pages_SEO_Tab_Title')}
            </baseUI.tabs.label>
          </baseUI.tabs.header>

          <baseUI.tabs.content className="page-settings-panel-content">
            <baseUI.tabs.tab name="page" className="wixapps-subpage">
              <CustomScroll>
                {isBlog ? (
                  <BlogSinglePostInfo
                    key="page-settings-tab-page-blog"
                    {...this.props}
                  />
                ) : null}
                {isWixStoreProductPage ? (
                  <TpaProductPageInfo
                    key="page-settings-tab-page-tpa-product"
                    {...this.props}
                  />
                ) : null}
                {isWixStoreCartPage ? (
                  <TpaCartPageInfo
                    key="page-settings-tab-page-tpa-cart"
                    {...this.props}
                  />
                ) : null}
                {isWixStoreThankYouPage ? (
                  <TpaThankYouPageInfo
                    key="page-settings-tab-page-tpa-thank-you"
                    {...this.props}
                  />
                ) : null}
              </CustomScroll>
            </baseUI.tabs.tab>
            <baseUI.tabs.tab name="layout">
              <TabLayout menuItem={this.props.menuItem} />
            </baseUI.tabs.tab>
            <baseUI.tabs.tab name="privacy">
              <TabPermissions pageData={pageData} />
            </baseUI.tabs.tab>
            <baseUI.tabs.tab name="seo" className="wixapps-subpage">
              <CustomScroll>
                {isBlog ? (
                  <BlogSinglePostSeo
                    key="page-settings-tab-seo-blog-single-post"
                    siteName={siteName}
                    publicUrl={publicUrl}
                    menuItem={this.props.menuItem}
                  />
                ) : null}
                {isWixStoreProductPage ? (
                  <TpaProductPageSeo
                    key="page-settings-tab-seo-tpa-product"
                    siteName={siteName}
                    publicUrl={publicUrl}
                  />
                ) : null}
                {isWixStoreThankYouPage ? (
                  <TpaThankYouPageSeo key="page-settings-tab-seo-tpa-thank-you" />
                ) : null}
                {isWixStoreCartPage ? (
                  <TpaCartPageSeo key="page-settings-tab-seo-tpa-cart" />
                ) : null}
              </CustomScroll>
            </baseUI.tabs.tab>
            {'-->'}
          </baseUI.tabs.content>
        </baseUI.tabs.holder>
      </div>
    );
  },
  getInitialState() {
    return {
      activeTab: 'page',
    };
  },
  setActiveTab(activeTab) {
    this.setState({ activeTab });
    this.getEditorAPI().bi.event(
      coreBi.events.topbar.pages.top_bar_PAGES_settings_tab_click,
      { category: activeTab },
    );
  },
  getSiteName() {
    const editorAPI = this.getEditorAPI();
    return editorAPI.dsRead.seo.title.get() || editorAPI.siteName.get();
  },
});
