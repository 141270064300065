// @ts-nocheck
import PropTypes from 'prop-types';
import _ from 'lodash';

// note that this mixin requires that you also use the editorAPI mixin
// this does not use editorAPI mixin since most panels already use it in one form or another

function getPanelPositionFromContext(position) {
  if (position) {
    return {
      top: `${position.top + 72}px`,
      left: `${position.left - 12}px`,
    };
  }
  return null;
}

function getPanelPosition(parentPositionFromContext, overridePosition) {
  if (overridePosition) {
    return overridePosition;
  }
  const panelPositionFromContext = getPanelPositionFromContext(
    parentPositionFromContext,
  );
  return panelPositionFromContext || { top: null, left: null };
}

export default {
  contextTypes: {
    panelPosition: PropTypes.object,
  },
  componentDidMount() {
    if (!_.isFunction(this.getEditorAPI)) {
      throw new Error(
        'You must use editorAPIMixin to use the linkColorPickerMixin',
      );
    }
  },
  UNSAFE_componentWillUpdate(nextProps, nextState, nextContext) {
    const parentPanelPosition = this.context.panelPosition;
    const parentPanelNextPosition =
      nextContext.panelPosition && nextContext.panelPosition;
    if (
      parentPanelPosition &&
      parentPanelNextPosition &&
      !_.isEqual(parentPanelPosition, parentPanelNextPosition)
    ) {
      const panelNextPosition = getPanelPosition(parentPanelNextPosition);
      this.getEditorAPI().panelManager.updatePanelProps(
        'panels.toolPanels.colorPicker.colorPickerPanel',
        {
          top: panelNextPosition.top,
          left: panelNextPosition.left,
        },
      );
    }
  },
  /**
   *
   * @param {function} onChange
   * @param {string} value
   * @param {boolean} allowPaletteEditing
   * @param {boolean} [displayNoColor=false]
   * @param {{top:number, left:number}} [position]
   * @param {boolean} [previewOnHover=false]
   * @param {boolean} [enableHistory=true]
   */
  openColorPicker(
    onChange,
    value,
    allowPaletteEditing,
    position,
    previewOnHover,
    enableHistory,
    onClose,
    origin,
    panelMode,
  ) {
    const editorAPI = this.getEditorAPI();
    const parentPositionFromContext = this.context.panelPosition;
    const panelPosition = getPanelPosition(parentPositionFromContext, position);
    const options = {
      onChange,
      allowPaletteEditing,
      onClose,
      enableHistory,
      previewOnHover,
      overridePosition: true,
      origin,
      panelMode,
    };

    editorAPI.openColorPicker(value, panelPosition, options);
  },
  updateSitePalette(colors) {
    const editorAPI = this.getEditorAPI();
    colors.updatePaletteColors(editorAPI, colors);
  },
  resolveColor(colorValue) {
    return this.getEditorAPI().theme.colors.render(colorValue);
  },
};
