import React from 'react';
import { sections } from '@/util';
import {
  SiteSegmentIndicationFooter,
  SiteSegmentIndicationHeader,
} from './components/SiteSegmentIndication/SiteSegmentIndication';
import { SectionsDropZoneContainer } from './components/SectionsDropZoneContainer';
import { exitZoomMode } from './api';
import { enterZoomModeAtStartupIfNeeded } from './utils';
import type { Scope } from './scope';

export const bootstrap = async (scope: Scope) => {
  const {
    previewAPI,
    autosaveAPI,
    sectionsAPI,
    workspaceAPI,
    addPresetAPI,
    editorPermissionsApi,
    editorRestrictionsApi,
  } = scope;

  if (!sections.isSectionsEnabled()) return;

  editorRestrictionsApi.set('zoom-mode_site-segment-indication.visible', () =>
    editorPermissionsApi.has('CLASSIC-EDITOR.EDIT-DESIGN'),
  );

  addPresetAPI.sections.hooks.addPresetStart.tap(
    (shouldWaitForZoomMode = true) => {
      if (shouldWaitForZoomMode) {
        scope.store.zoomTransitionStart();
      }
      sectionsAPI.setEnforcementEnabled(false);
    },
  );

  addPresetAPI.sections.hooks.addPresetEnd.tap(() => {
    sectionsAPI.setEnforcementEnabled(true);
  });

  previewAPI.registerToBeforeGoToPreview(async () => {
    autosaveAPI.init({ enabled: false }, true, true);
    await sectionsAPI.clearSectionTransformationsOnCurrentPage();

    await exitZoomMode(scope, {
      animationDuration: 0,
      biParams: { origin: 'autoExitWhenPreview' },
    });
    autosaveAPI.init({ enabled: true }, true, true);
  });

  workspaceAPI.contributeStageComponent(() => <SiteSegmentIndicationHeader />);

  workspaceAPI.contributeStageComponent(() => <SiteSegmentIndicationFooter />);

  workspaceAPI.contributeStageComponent(() => <SectionsDropZoneContainer />);

  await enterZoomModeAtStartupIfNeeded(scope);
};
