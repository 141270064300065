import experiment from 'experiment';

export interface ExperimentsApi {
  all: () => { [key: string]: string | boolean };
  enabled: (spec: string) => boolean;
  getValue: (spec: string) => string | undefined;
}

export function createExperimentsApi(): ExperimentsApi {
  function all(): { [key: string]: string | boolean } {
    return experiment.getRunningExperiments();
  }

  function enabled(spec: string): boolean {
    return experiment.isOpen(spec);
  }

  function getValue(spec: string): string | undefined {
    return experiment.getValue(spec);
  }

  return {
    all,
    enabled,
    getValue,
  };
}
