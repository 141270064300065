'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')
const moreBehaviorHelper = require('./moreBehaviorHelper')

module.exports =
  {
    "type": addPanelDataConsts.SECTIONS_TYPES.PRESET,
    "hide": false,
    "title": "add_section_media_player_label",
    "automationId": "add-panel-section-videoBoxSection",
    "showSectionHeader": true,
    "additionalBehaviours": {
      "labelMode": addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
      "infoIcon": addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
      "hoverImageAction": addPanelDataConsts.HOVER_IMAGE_ACTIONS.VIDEO,
      "iconEnabledForComps": {}
    },
    "props": {
      'image': 'addPanelData/sections/videoSection_en/videoBox_en.v6.png',
      'retinaImage': {
        'src': 'addPanelData/sections/videoSection_en/videoBox_en-@2x.v6.png',
        'dimensions': {
          'width': 648,
          'height': 960
        }
      },
      "imageHover": null,
      "items": [
        {
          "id": "VideoBox_VideoBox_1",
          "structure": {
            "type": "Container",
            "metaData": {
              "sig": "xae-2378",
              "pageId": "gi3kr"
            },
            "components": [
              {
                "type": "Component",
                "metaData": {
                  "sig": "xae-2375",
                  "pageId": "gi3kr"
                },
                "skin": "skins.viewer.mediaOverlayControlsDefaultSkin",
                "layout": {
                  "width": 105,
                  "height": 105,
                  "x": 83,
                  "y": 84,
                  "scale": 1,
                  "rotationInDegrees": 0,
                  "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.MediaOverlayControls",
                "parent": "comp-kpqwmzvn",
                "props": {
                  "type": "MediaOverlayControlsProperties",
                  "metaData": {
                    "schemaVersion": "1.0",
                    "sig": "yje-751",
                    "autoGenerated": false,
                    "pageId": "gi3kr"
                  },
                  "playerId": "comp-kpqwmzvn"
                },
                "design": {
                  "type": "MediaControlsDesignData",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "yys-158",
                    "pageId": "gi3kr"
                  },
                  "iconsDefaultDesign": {
                    "type": "VectorImageDesignData",
                    "metaData": {
                      "isPreset": false,
                      "schemaVersion": "1.0",
                      "isHidden": false,
                      "sig": "yje-748",
                      "pageId": "gi3kr"
                    },
                    "overrideColors": {
                      "color1": "#ffffff"
                    },
                    "shapeStyle": {
                      "opacity": 1,
                      "strokeWidth": 1,
                      "stroke": "#000000",
                      "strokeOpacity": 1,
                      "enableStroke": false
                    }
                  },
                  "icons": [
                    {
                      "type": "ControlIconDesignData",
                      "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false,
                        "sig": "yys-159",
                        "pageId": "gi3kr"
                      },
                      "name": "playButton",
                      "svgId": "0da768_70d326d6112c4597bee7759165a86dc2.svg"
                    }
                  ]
                },
                "style": {
                  "type": "TopLevelStyle",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "ua9-1010",
                    "pageId": "masterPage"
                  },
                  "style": {
                    "groups": {},
                    "properties": {},
                    "propertiesSource": {}
                  },
                  "componentClassName": "wysiwyg.viewer.components.MediaOverlayControls",
                  "pageId": "",
                  "compId": "",
                  "styleType": "system",
                  "skin": "skins.viewer.mediaOverlayControlsDefaultSkin"
                }
              },
              {
                "type": "Component",
                "metaData": {
                  "sig": "xae-2376",
                  "pageId": "gi3kr"
                },
                "skin": "skins.viewer.mediaControlsNoControlsSkin",
                "layout": {
                  "width": 51,
                  "height": 44,
                  "x": 220,
                  "y": 229,
                  "scale": 1,
                  "rotationInDegrees": 0,
                  "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.MediaControls",
                "parent": "comp-kpqwmzvn",
                "props": {
                  "type": "MediaControlsProperties",
                  "metaData": {
                    "schemaVersion": "1.0",
                    "sig": "yje-761",
                    "autoGenerated": false,
                    "pageId": "gi3kr"
                  },
                  "showStoryboard": "time",
                  "playerId": "comp-kpqwmzvn"
                },
                "design": {
                  "type": "MediaControlsDesignData",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "yje-753",
                    "pageId": "gi3kr"
                  },
                  "iconsDefaultDesign": {
                    "type": "VectorImageDesignData",
                    "metaData": {
                      "isPreset": false,
                      "schemaVersion": "1.0",
                      "isHidden": false,
                      "sig": "yje-758",
                      "pageId": "gi3kr"
                    },
                    "overrideColors": {
                      "color1": "#ffffff"
                    },
                    "shapeStyle": {
                      "opacity": 1,
                      "strokeWidth": 1,
                      "stroke": "#000000",
                      "strokeOpacity": 1,
                      "enableStroke": false
                    }
                  },
                  "icons": [
                    {
                      "type": "ControlIconDesignData",
                      "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false,
                        "sig": "yje-757",
                        "pageId": "gi3kr"
                      },
                      "name": "volumeOn",
                      "svgId": "0da768_9c02fee4517e40e1bfe9581b4cfa3750.svg"
                    },
                    {
                      "type": "ControlIconDesignData",
                      "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false,
                        "sig": "yje-756",
                        "pageId": "gi3kr"
                      },
                      "name": "volumeOff",
                      "svgId": "0da768_2a8395c630404213aaf6576ea1baaa70.svg"
                    },
                    {
                      "type": "ControlIconDesignData",
                      "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false,
                        "sig": "yje-754",
                        "pageId": "gi3kr"
                      },
                      "name": "noAudio",
                      "svgId": "0da768_2a8395c630404213aaf6576ea1baaa70.svg",
                      "iconDesign": {
                        "type": "VectorImageDesignData",
                        "metaData": {
                          "isPreset": false,
                          "schemaVersion": "1.0",
                          "isHidden": false,
                          "sig": "yje-755",
                          "pageId": "gi3kr"
                        },
                        "overrideColors": {
                          "color1": "#ACAEAF"
                        }
                      }
                    }
                  ]
                },
                "style": {
                  "type": "TopLevelStyle",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "ua9-1021",
                    "pageId": "masterPage"
                  },
                  "style": {
                    "groups": {},
                    "properties": {
                      "maincolor": "#FFFFFF",
                      "textcolor": "#2d2d2d",
                      "disabledcolor": "#8d8d8d"
                    },
                    "propertiesSource": {}
                  },
                  "componentClassName": "wysiwyg.viewer.components.MediaControls",
                  "pageId": "",
                  "compId": "",
                  "styleType": "system",
                  "skin": "skins.viewer.mediaControlsNoControlsSkin"
                }
              }
            ],
            "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer",
            "layout": {
              "width": 271,
              "height": 273,
              "x": 21,
              "y": 28,
              "scale": 1,
              "rotationInDegrees": 0,
              "fixedPosition": false
            },
            "componentType": "wysiwyg.viewer.components.MediaPlayer",
            "parent": "comp-kpqy238r",
            "props": {
              "type": "MediaPlayerProperties",
              "metaData": {
                "schemaVersion": "1.0",
                "sig": "yje-22",
                "pageId": "gi3kr"
              },
              "autoplay": true,
              "playerInteraction": {
                "click": "toggle",
                "rollIn": "none",
                "rollOut": "none",
                "allowReplay": true
              },
              "mute": true,
              "loop": true,
              "disableAudio": false,
              "playerAudioInteraction": {
                "rollIn": "none",
                "rollOut": "none"
              },
              "animatePoster": "none",
              "flip": "none"
            },
            "design": {
              "type": "MediaPlayerDesignData",
              "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false,
                "sig": "2k1-80",
                "pageId": "gi3kr"
              },
              "background": {
                "type": "BackgroundMedia",
                "metaData": {
                  "isPreset": false,
                  "schemaVersion": "1.0",
                  "isHidden": false,
                  "sig": "2k1-81",
                  "pageId": "gi3kr"
                },
                "mediaRef": {
                  "type": "WixVideo",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "2k1-82",
                    "pageId": "gi3kr"
                  },
                  "title": "Clock",
                  "videoId": "11062b_228cb15c033f46998dc2797f1c7a2d2f",
                  "qualities": [
                    {
                      "quality": "480p",
                      "width": 854,
                      "height": 480,
                      "url": "video/11062b_228cb15c033f46998dc2797f1c7a2d2f/480p/mp4/file.mp4"
                    },
                    {
                      "quality": "720p",
                      "width": 1280,
                      "height": 720,
                      "url": "video/11062b_228cb15c033f46998dc2797f1c7a2d2f/720p/mp4/file.mp4"
                    },
                    {
                      "quality": "1080p",
                      "width": 1920,
                      "height": 1080,
                      "url": "video/11062b_228cb15c033f46998dc2797f1c7a2d2f/1080p/mp4/file.mp4"
                    }
                  ],
                  "posterImageRef": {
                    "type": "Image",
                    "metaData": {
                      "isPreset": false,
                      "schemaVersion": "1.0",
                      "isHidden": false,
                      "sig": "2k1-83",
                      "pageId": "gi3kr"
                    },
                    "title": "",
                    "uri": "11062b_228cb15c033f46998dc2797f1c7a2d2ff000.jpg",
                    "description": "public/8e256233-1752-4026-9341-54036e7f875e/babd428d-abdf-463a-ac71-a23e3cafaffa/all/any/all",
                    "width": 1920,
                    "height": 1080,
                    "alt": ""
                  },
                  "opacity": 1,
                  "duration": 27.84,
                  "preload": "auto",
                  "artist": {
                    "id": "1149203367",
                    "name": "GettyImages"
                  },
                  "generatedPosters": [
                    "11062b_228cb15c033f46998dc2797f1c7a2d2ff000.jpg",
                    "11062b_228cb15c033f46998dc2797f1c7a2d2ff001.jpg",
                    "11062b_228cb15c033f46998dc2797f1c7a2d2ff002.jpg",
                    "11062b_228cb15c033f46998dc2797f1c7a2d2ff003.jpg"
                  ],
                  "hasAudio": false,
                  "fps": "25/1",
                  "adaptiveVideo": [
                    {
                      "format": "hls",
                      "url": "site/media/video/11062b_228cb15c033f46998dc2797f1c7a2d2f/da01a1fc-ad34-44bc-90e8-cc9413c466e6/repackage/hls"
                    }
                  ]
                },
                "color": "#212121",
                "colorOpacity": 1,
                "alignType": "center",
                "fittingType": "fill",
                "scrollType": "none",
                "colorOverlay": "",
                "colorOverlayOpacity": 0,
                "showOverlayForMediaType": "WixVideo"
              },
              "cssStyle": {},
              "themeMappings": {},
              "charas": "design-knwzkxyl"
            },
            "style": {
              "type": "TopLevelStyle",
              "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false,
                "sig": "ua9-1003",
                "pageId": "masterPage"
              },
              "style": {
                "groups": {},
                "properties": {},
                "propertiesSource": {}
              },
              "componentClassName": "wysiwyg.viewer.components.MediaPlayer",
              "pageId": "",
              "compId": "",
              "styleType": "system",
              "skin": "wysiwyg.viewer.skins.mediaPlayerSkin"
            },
            "activeModes": {},
            "id": "comp-kpqwmzvn"
          },
          "preset": {
            "rect": {
              "width": 140,
              "height": 140,
              "x": 0,
              "y": 0
            },
            "label": "add_preset_media_player_1",
            "tags": null,
            "mediaManager": {
              "path": "public/8e256233-1752-4026-9341-54036e7f875e/babd428d-abdf-463a-ac71-a23e3cafaffa"
            }
          }
        },
        {
          "id": "VideoBox_VideoBox_2",
          "structure": {
            "type": "Container",
            "metaData": {
              "sig": "xae-2377",
              "pageId": "gi3kr"
            },
            "components": [
              {
                "type": "Component",
                "metaData": {
                  "sig": "xae-2955",
                  "pageId": "gi3kr"
                },
                "skin": "skins.viewer.mediaOverlayControlsDefaultSkin",
                "layout": {
                  "width": 96,
                  "height": 96,
                  "x": 137,
                  "y": 88,
                  "scale": 1,
                  "rotationInDegrees": 0,
                  "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.MediaOverlayControls",
                "parent": "comp-kpqy4vfh",
                "props": {
                  "type": "MediaOverlayControlsProperties",
                  "metaData": {
                    "schemaVersion": "1.0",
                    "sig": "yje-3353",
                    "autoGenerated": false,
                    "pageId": "gi3kr"
                  },
                  "playerId": "comp-kpqy4vfh"
                },
                "design": {
                  "type": "MediaControlsDesignData",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "xae-2958",
                    "pageId": "gi3kr"
                  },
                  "iconsDefaultDesign": {
                    "type": "VectorImageDesignData",
                    "metaData": {
                      "isPreset": false,
                      "schemaVersion": "1.0",
                      "isHidden": false,
                      "sig": "xae-3044",
                      "pageId": "gi3kr"
                    },
                    "overrideColors": {
                      "color1": "#ffffff",
                      "color2": "color_11"
                    },
                    "shapeStyle": {
                      "opacity": 1
                    }
                  },
                  "icons": [
                    {
                      "type": "ControlIconDesignData",
                      "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false,
                        "sig": "xae-2959",
                        "pageId": "gi3kr"
                      },
                      "name": "playButton",
                      "svgId": "0da768_617d46465fae4f05b06c0dadb251d89c.svg"
                    }
                  ]
                },
                "style": {
                  "type": "TopLevelStyle",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "ua9-1010",
                    "pageId": "masterPage"
                  },
                  "style": {
                    "groups": {},
                    "properties": {},
                    "propertiesSource": {}
                  },
                  "componentClassName": "wysiwyg.viewer.components.MediaOverlayControls",
                  "pageId": "",
                  "compId": "",
                  "styleType": "system",
                  "skin": "skins.viewer.mediaOverlayControlsDefaultSkin"
                }
              },
              {
                "type": "Component",
                "metaData": {
                  "sig": "xae-2370",
                  "pageId": "gi3kr"
                },
                "skin": "skins.viewer.mediaControlsNoControlsSkin",
                "layout": {
                  "width": 51,
                  "height": 44,
                  "x": 319,
                  "y": 229,
                  "scale": 1,
                  "rotationInDegrees": 0,
                  "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.MediaControls",
                "parent": "comp-kpqy4vfh",
                "props": {
                  "type": "MediaControlsProperties",
                  "metaData": {
                    "schemaVersion": "1.0",
                    "sig": "yje-3364",
                    "autoGenerated": false,
                    "pageId": "gi3kr"
                  },
                  "showStoryboard": "time",
                  "playerId": "comp-kpqy4vfh"
                },
                "design": {
                  "type": "MediaControlsDesignData",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "yje-3355",
                    "pageId": "gi3kr"
                  },
                  "iconsDefaultDesign": {
                    "type": "VectorImageDesignData",
                    "metaData": {
                      "isPreset": false,
                      "schemaVersion": "1.0",
                      "isHidden": false,
                      "sig": "yje-3360",
                      "pageId": "gi3kr"
                    },
                    "overrideColors": {
                      "color1": "#ffffff"
                    },
                    "shapeStyle": {
                      "opacity": 1,
                      "strokeWidth": 1,
                      "stroke": "#000000",
                      "strokeOpacity": 1,
                      "enableStroke": false
                    }
                  },
                  "icons": [
                    {
                      "type": "ControlIconDesignData",
                      "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false,
                        "sig": "yje-3359",
                        "pageId": "gi3kr"
                      },
                      "name": "volumeOn",
                      "svgId": "0da768_9c02fee4517e40e1bfe9581b4cfa3750.svg"
                    },
                    {
                      "type": "ControlIconDesignData",
                      "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false,
                        "sig": "yje-3358",
                        "pageId": "gi3kr"
                      },
                      "name": "volumeOff",
                      "svgId": "0da768_2a8395c630404213aaf6576ea1baaa70.svg"
                    },
                    {
                      "type": "ControlIconDesignData",
                      "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false,
                        "sig": "yje-3356",
                        "pageId": "gi3kr"
                      },
                      "name": "noAudio",
                      "svgId": "0da768_2a8395c630404213aaf6576ea1baaa70.svg",
                      "iconDesign": {
                        "type": "VectorImageDesignData",
                        "metaData": {
                          "isPreset": false,
                          "schemaVersion": "1.0",
                          "isHidden": false,
                          "sig": "yje-3357",
                          "pageId": "gi3kr"
                        },
                        "overrideColors": {
                          "color1": "#ACAEAF"
                        }
                      }
                    }
                  ]
                },
                "style": {
                  "type": "TopLevelStyle",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "ua9-1021",
                    "pageId": "masterPage"
                  },
                  "style": {
                    "groups": {},
                    "properties": {
                      "maincolor": "#FFFFFF",
                      "textcolor": "#2d2d2d",
                      "disabledcolor": "#8d8d8d"
                    },
                    "propertiesSource": {}
                  },
                  "componentClassName": "wysiwyg.viewer.components.MediaControls",
                  "pageId": "",
                  "compId": "",
                  "styleType": "system",
                  "skin": "skins.viewer.mediaControlsNoControlsSkin"
                }
              }
            ],
            "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer",
            "layout": {
              "width": 370,
              "height": 273,
              "x": 414,
              "y": 40,
              "scale": 1,
              "rotationInDegrees": 0,
              "fixedPosition": false
            },
            "componentType": "wysiwyg.viewer.components.MediaPlayer",
            "parent": "comp-kpqy238r",
            "props": {
              "type": "MediaPlayerProperties",
              "metaData": {
                "schemaVersion": "1.0",
                "sig": "yje-3338",
                "pageId": "gi3kr"
              },
              "autoplay": true,
              "playerInteraction": {
                "click": "toggle",
                "rollIn": "none",
                "rollOut": "none",
                "allowReplay": true
              },
              "mute": true,
              "loop": true,
              "disableAudio": false,
              "playerAudioInteraction": {
                "rollIn": "none",
                "rollOut": "none"
              },
              "animatePoster": "none",
              "flip": "none"
            },
            "design": {
              "type": "MediaPlayerDesignData",
              "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false,
                "sig": "2k1-86",
                "pageId": "gi3kr"
              },
              "background": {
                "type": "BackgroundMedia",
                "metaData": {
                  "isPreset": false,
                  "schemaVersion": "1.0",
                  "isHidden": false,
                  "sig": "2k1-87",
                  "pageId": "gi3kr"
                },
                "mediaRef": {
                  "type": "WixVideo",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "2k1-88",
                    "pageId": "gi3kr"
                  },
                  "title": "Smile",
                  "videoId": "11062b_b65b88b0710e43d8a6142aac2f606bde",
                  "qualities": [
                    {
                      "quality": "480p",
                      "width": 854,
                      "height": 450,
                      "url": "video/11062b_b65b88b0710e43d8a6142aac2f606bde/480p/mp4/file.mp4"
                    },
                    {
                      "quality": "720p",
                      "width": 1280,
                      "height": 674,
                      "url": "video/11062b_b65b88b0710e43d8a6142aac2f606bde/720p/mp4/file.mp4"
                    },
                    {
                      "quality": "1080p",
                      "width": 1920,
                      "height": 1012,
                      "url": "video/11062b_b65b88b0710e43d8a6142aac2f606bde/1080p/mp4/file.mp4"
                    }
                  ],
                  "posterImageRef": {
                    "type": "Image",
                    "metaData": {
                      "isPreset": false,
                      "schemaVersion": "1.0",
                      "isHidden": false,
                      "sig": "2k1-89",
                      "pageId": "gi3kr"
                    },
                    "title": "",
                    "uri": "11062b_b65b88b0710e43d8a6142aac2f606bdef000.jpg",
                    "description": "public/8e256233-1752-4026-9341-54036e7f875e/a85e50b3-f78c-4792-a226-040ce52b983c/all/any/all",
                    "width": 2050,
                    "height": 1080,
                    "alt": ""
                  },
                  "opacity": 1,
                  "duration": 16.08,
                  "preload": "auto",
                  "artist": {
                    "id": "944755918-002",
                    "name": "GettyImages"
                  },
                  "generatedPosters": [
                    "11062b_b65b88b0710e43d8a6142aac2f606bdef000.jpg",
                    "11062b_b65b88b0710e43d8a6142aac2f606bdef001.jpg",
                    "11062b_b65b88b0710e43d8a6142aac2f606bdef002.jpg",
                    "11062b_b65b88b0710e43d8a6142aac2f606bdef003.jpg"
                  ],
                  "hasAudio": false,
                  "fps": "25/1",
                  "adaptiveVideo": [
                    {
                      "format": "hls",
                      "url": "site/media/video/11062b_b65b88b0710e43d8a6142aac2f606bde/da01a1fc-ad34-44bc-90e8-cc9413c466e6/repackage/hls"
                    }
                  ]
                },
                "color": "#212121",
                "colorOpacity": 1,
                "alignType": "center",
                "fittingType": "fill",
                "scrollType": "none",
                "colorOverlay": "",
                "colorOverlayOpacity": 0,
                "showOverlayForMediaType": "WixVideo"
              },
              "cssStyle": {},
              "themeMappings": {},
              "charas": "design-kocxht8j"
            },
            "style": {
              "type": "TopLevelStyle",
              "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false,
                "sig": "ua9-1003",
                "pageId": "masterPage"
              },
              "style": {
                "groups": {},
                "properties": {},
                "propertiesSource": {}
              },
              "componentClassName": "wysiwyg.viewer.components.MediaPlayer",
              "pageId": "",
              "compId": "",
              "styleType": "system",
              "skin": "wysiwyg.viewer.skins.mediaPlayerSkin"
            },
            "activeModes": {},
            "id": "comp-kpqy4vfh"
          },
          "preset": {
            "rect": {
              "width": 184,
              "height": 140,
              "x": 140,
              "y": 0
            },
            "mediaManager": {
              "path": "public/8e256233-1752-4026-9341-54036e7f875e/a85e50b3-f78c-4792-a226-040ce52b983c"
            }
          }
        },
        {
          "id": "VideoBox_VideoBox_3",
          "structure": {
            "type": "Container",
            "metaData": {
              "sig": "xae-3196",
              "pageId": "gi3kr"
            },
            "components": [
              {
                "type": "Component",
                "metaData": {
                  "sig": "xae-3194",
                  "pageId": "gi3kr"
                },
                "skin": "skins.viewer.mediaOverlayControlsDefaultSkin",
                "layout": {
                  "width": 81,
                  "height": 82,
                  "x": 231,
                  "y": 127,
                  "scale": 1,
                  "rotationInDegrees": 0,
                  "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.MediaOverlayControls",
                "parent": "comp-kpqwmzxn",
                "props": {
                  "type": "MediaOverlayControlsProperties",
                  "metaData": {
                    "schemaVersion": "1.0",
                    "sig": "yje-797",
                    "autoGenerated": false,
                    "pageId": "gi3kr"
                  },
                  "playerId": "comp-kpqwmzxn"
                },
                "design": {
                  "type": "MediaControlsDesignData",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "yje-792",
                    "pageId": "gi3kr"
                  },
                  "iconsDefaultDesign": {
                    "type": "VectorImageDesignData",
                    "metaData": {
                      "isPreset": false,
                      "schemaVersion": "1.0",
                      "isHidden": false,
                      "sig": "yys-66",
                      "pageId": "gi3kr"
                    },
                    "overrideColors": {
                      "color1": "#ffffff",
                      "color2": "#ED5829"
                    },
                    "shapeStyle": {
                      "opacity": 0.8,
                      "strokeWidth": 1,
                      "stroke": "#000000",
                      "strokeOpacity": 1,
                      "enableStroke": false
                    }
                  },
                  "icons": [
                    {
                      "type": "ControlIconDesignData",
                      "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false,
                        "sig": "yje-793",
                        "pageId": "gi3kr"
                      },
                      "name": "playButton",
                      "svgId": "0da768_513035bd10774dcabdf6af1add823662.svg"
                    }
                  ]
                },
                "style": {
                  "type": "TopLevelStyle",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "ua9-1010",
                    "pageId": "masterPage"
                  },
                  "style": {
                    "groups": {},
                    "properties": {},
                    "propertiesSource": {}
                  },
                  "componentClassName": "wysiwyg.viewer.components.MediaOverlayControls",
                  "pageId": "",
                  "compId": "",
                  "styleType": "system",
                  "skin": "skins.viewer.mediaOverlayControlsDefaultSkin"
                }
              },
              {
                "type": "Component",
                "metaData": {
                  "sig": "xae-3195",
                  "pageId": "gi3kr"
                },
                "skin": "skins.viewer.mediaControlsNoControlsSkin",
                "layout": {
                  "width": 51,
                  "height": 44,
                  "x": 492,
                  "y": 293,
                  "scale": 1,
                  "rotationInDegrees": 0,
                  "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.MediaControls",
                "parent": "comp-kpqwmzxn",
                "props": {
                  "type": "MediaControlsProperties",
                  "metaData": {
                    "schemaVersion": "1.0",
                    "sig": "2k1-96",
                    "autoGenerated": false,
                    "pageId": "gi3kr"
                  },
                  "showStoryboard": "time",
                  "playerId": "comp-kpqwmzxn"
                },
                "design": {
                  "type": "MediaControlsDesignData",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "yje-799",
                    "pageId": "gi3kr"
                  },
                  "iconsDefaultDesign": {
                    "type": "VectorImageDesignData",
                    "metaData": {
                      "isPreset": false,
                      "schemaVersion": "1.0",
                      "isHidden": false,
                      "sig": "yje-804",
                      "pageId": "gi3kr"
                    },
                    "overrideColors": {
                      "color1": "#ffffff"
                    },
                    "shapeStyle": {
                      "opacity": 1,
                      "strokeWidth": 1,
                      "stroke": "#000000",
                      "strokeOpacity": 1,
                      "enableStroke": false
                    }
                  },
                  "icons": [
                    {
                      "type": "ControlIconDesignData",
                      "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false,
                        "sig": "yje-803",
                        "pageId": "gi3kr"
                      },
                      "name": "volumeOn",
                      "svgId": "0da768_9c02fee4517e40e1bfe9581b4cfa3750.svg"
                    },
                    {
                      "type": "ControlIconDesignData",
                      "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false,
                        "sig": "yje-802",
                        "pageId": "gi3kr"
                      },
                      "name": "volumeOff",
                      "svgId": "0da768_2a8395c630404213aaf6576ea1baaa70.svg"
                    },
                    {
                      "type": "ControlIconDesignData",
                      "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false,
                        "sig": "yje-800",
                        "pageId": "gi3kr"
                      },
                      "name": "noAudio",
                      "svgId": "0da768_2a8395c630404213aaf6576ea1baaa70.svg",
                      "iconDesign": {
                        "type": "VectorImageDesignData",
                        "metaData": {
                          "isPreset": false,
                          "schemaVersion": "1.0",
                          "isHidden": false,
                          "sig": "yje-801",
                          "pageId": "gi3kr"
                        },
                        "overrideColors": {
                          "color1": "#ACAEAF"
                        }
                      }
                    }
                  ]
                },
                "style": {
                  "type": "TopLevelStyle",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "ua9-1021",
                    "pageId": "masterPage"
                  },
                  "style": {
                    "groups": {},
                    "properties": {
                      "maincolor": "#FFFFFF",
                      "textcolor": "#2d2d2d",
                      "disabledcolor": "#8d8d8d"
                    },
                    "propertiesSource": {}
                  },
                  "componentClassName": "wysiwyg.viewer.components.MediaControls",
                  "pageId": "",
                  "compId": "",
                  "styleType": "system",
                  "skin": "skins.viewer.mediaControlsNoControlsSkin"
                }
              }
            ],
            "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer",
            "layout": {
              "width": 543,
              "height": 337,
              "x": 81,
              "y": 369,
              "scale": 1,
              "rotationInDegrees": 0,
              "fixedPosition": false
            },
            "componentType": "wysiwyg.viewer.components.MediaPlayer",
            "parent": "comp-kpqy238r",
            "props": {
              "type": "MediaPlayerProperties",
              "metaData": {
                "schemaVersion": "1.0",
                "sig": "yje-70",
                "pageId": "gi3kr"
              },
              "autoplay": false,
              "playerInteraction": {
                "click": "toggle",
                "rollIn": "none",
                "rollOut": "none",
                "allowReplay": true
              },
              "mute": false,
              "loop": true,
              "disableAudio": false,
              "playerAudioInteraction": {
                "rollIn": "none",
                "rollOut": "none"
              },
              "animatePoster": "none",
              "flip": "none"
            },
            "design": {
              "type": "MediaPlayerDesignData",
              "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false,
                "sig": "2k1-92",
                "pageId": "gi3kr"
              },
              "background": {
                "type": "BackgroundMedia",
                "metaData": {
                  "isPreset": false,
                  "schemaVersion": "1.0",
                  "isHidden": false,
                  "sig": "2k1-93",
                  "pageId": "gi3kr"
                },
                "mediaRef": {
                  "type": "WixVideo",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "2k1-94",
                    "pageId": "gi3kr"
                  },
                  "title": "Jellyfish",
                  "videoId": "11062b_d14ea38398994dd3af199158a56b311a",
                  "qualities": [
                    {
                      "quality": "480p",
                      "width": 854,
                      "height": 480,
                      "url": "video/11062b_d14ea38398994dd3af199158a56b311a/480p/mp4/file.mp4"
                    },
                    {
                      "quality": "720p",
                      "width": 1280,
                      "height": 720,
                      "url": "video/11062b_d14ea38398994dd3af199158a56b311a/720p/mp4/file.mp4"
                    },
                    {
                      "quality": "1080p",
                      "width": 1920,
                      "height": 1080,
                      "url": "video/11062b_d14ea38398994dd3af199158a56b311a/1080p/mp4/file.mp4"
                    }
                  ],
                  "posterImageRef": {
                    "type": "Image",
                    "metaData": {
                      "isPreset": false,
                      "schemaVersion": "1.0",
                      "isHidden": false,
                      "sig": "2k1-95",
                      "pageId": "gi3kr"
                    },
                    "title": "",
                    "uri": "11062b_d14ea38398994dd3af199158a56b311af000.jpg",
                    "description": "public/8e256233-1752-4026-9341-54036e7f875e/09309800-7aa0-4352-8f8a-101028e04bf1/all/any/all",
                    "width": 1920,
                    "height": 1080,
                    "alt": ""
                  },
                  "opacity": 1,
                  "duration": 9.03,
                  "preload": "auto",
                  "artist": {
                    "id": "117972864",
                    "name": "gettyimages"
                  },
                  "generatedPosters": [
                    "11062b_d14ea38398994dd3af199158a56b311af000.jpg",
                    "11062b_d14ea38398994dd3af199158a56b311af001.jpg",
                    "11062b_d14ea38398994dd3af199158a56b311af002.jpg",
                    "11062b_d14ea38398994dd3af199158a56b311af003.jpg"
                  ],
                  "hasAudio": false,
                  "fps": "30/1",
                  "adaptiveVideo": [
                    {
                      "format": "hls",
                      "url": "site/media/video/11062b_d14ea38398994dd3af199158a56b311a/da01a1fc-ad34-44bc-90e8-cc9413c466e6/repackage/hls"
                    }
                  ]
                },
                "color": "#212121",
                "colorOpacity": 1,
                "alignType": "center",
                "fittingType": "fill",
                "scrollType": "none",
                "colorOverlay": "",
                "colorOverlayOpacity": 0,
                "showOverlayForMediaType": "WixVideo"
              },
              "cssStyle": {},
              "themeMappings": {},
              "charas": "design-knwzms6j"
            },
            "style": {
              "type": "TopLevelStyle",
              "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false,
                "sig": "ua9-1003",
                "pageId": "masterPage"
              },
              "style": {
                "groups": {},
                "properties": {},
                "propertiesSource": {}
              },
              "componentClassName": "wysiwyg.viewer.components.MediaPlayer",
              "pageId": "",
              "compId": "",
              "styleType": "system",
              "skin": "wysiwyg.viewer.skins.mediaPlayerSkin"
            },
            "activeModes": {},
            "id": "comp-kpqwmzxn"
          },
          "preset": {
            "rect": {
              "width": 324,
              "height": 200,
              "x": 0,
              "y": 140
            },
            "mediaManager": {
              "path": "public/8e256233-1752-4026-9341-54036e7f875e/09309800-7aa0-4352-8f8a-101028e04bf1"
            }
          }
        },
        {
          "id": "VideoBox_VideoBox_4",
          "structure": {
            "type": "Container",
            "metaData": {
              "sig": "xae-3212",
              "pageId": "gi3kr"
            },
            "components": [
              {
                "type": "Component",
                "metaData": {
                  "sig": "xae-3201",
                  "pageId": "gi3kr"
                },
                "skin": "skins.viewer.mediaOverlayControlsDefaultSkin",
                "layout": {
                  "width": 83,
                  "height": 84,
                  "x": 91,
                  "y": 114,
                  "scale": 1,
                  "rotationInDegrees": 0,
                  "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.MediaOverlayControls",
                "parent": "comp-kpqwmzxw",
                "props": {
                  "type": "MediaOverlayControlsProperties",
                  "metaData": {
                    "schemaVersion": "1.0",
                    "sig": "yje-843",
                    "autoGenerated": false,
                    "pageId": "gi3kr"
                  },
                  "playerId": "comp-kpqwmzxw"
                },
                "design": {
                  "type": "MediaControlsDesignData",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "yje-838",
                    "pageId": "gi3kr"
                  },
                  "iconsDefaultDesign": {
                    "type": "VectorImageDesignData",
                    "metaData": {
                      "isPreset": false,
                      "schemaVersion": "1.0",
                      "isHidden": false,
                      "sig": "yje-840",
                      "pageId": "gi3kr"
                    },
                    "overrideColors": {
                      "color1": "#ffffff"
                    },
                    "shapeStyle": {
                      "opacity": 1,
                      "strokeWidth": 1,
                      "stroke": "#000000",
                      "strokeOpacity": 1,
                      "enableStroke": false
                    }
                  },
                  "icons": [
                    {
                      "type": "ControlIconDesignData",
                      "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false,
                        "sig": "yje-839",
                        "pageId": "gi3kr"
                      },
                      "name": "playButton",
                      "svgId": "0da768_b94b0c8d9ad140b397386b0796eab836.svg"
                    }
                  ]
                },
                "style": {
                  "type": "TopLevelStyle",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "ua9-1010",
                    "pageId": "masterPage"
                  },
                  "style": {
                    "groups": {},
                    "properties": {},
                    "propertiesSource": {}
                  },
                  "componentClassName": "wysiwyg.viewer.components.MediaOverlayControls",
                  "pageId": "",
                  "compId": "",
                  "styleType": "system",
                  "skin": "skins.viewer.mediaOverlayControlsDefaultSkin"
                }
              },
              {
                "type": "Component",
                "metaData": {
                  "sig": "xae-3202",
                  "pageId": "gi3kr"
                },
                "skin": "skins.viewer.mediaControlsNoControlsSkin",
                "layout": {
                  "width": 51,
                  "height": 44,
                  "x": 214,
                  "y": 269,
                  "scale": 1,
                  "rotationInDegrees": 0,
                  "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.MediaControls",
                "parent": "comp-kpqwmzxw",
                "props": {
                  "type": "MediaControlsProperties",
                  "metaData": {
                    "schemaVersion": "1.0",
                    "sig": "yje-853",
                    "autoGenerated": false,
                    "pageId": "gi3kr"
                  },
                  "showStoryboard": "time",
                  "playerId": "comp-kpqwmzxw"
                },
                "design": {
                  "type": "MediaControlsDesignData",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "yje-845",
                    "pageId": "gi3kr"
                  },
                  "iconsDefaultDesign": {
                    "type": "VectorImageDesignData",
                    "metaData": {
                      "isPreset": false,
                      "schemaVersion": "1.0",
                      "isHidden": false,
                      "sig": "yje-850",
                      "pageId": "gi3kr"
                    },
                    "overrideColors": {
                      "color1": "#ffffff"
                    },
                    "shapeStyle": {
                      "opacity": 1,
                      "strokeWidth": 1,
                      "stroke": "#000000",
                      "strokeOpacity": 1,
                      "enableStroke": false
                    }
                  },
                  "icons": [
                    {
                      "type": "ControlIconDesignData",
                      "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false,
                        "sig": "yje-849",
                        "pageId": "gi3kr"
                      },
                      "name": "volumeOn",
                      "svgId": "0da768_9c02fee4517e40e1bfe9581b4cfa3750.svg"
                    },
                    {
                      "type": "ControlIconDesignData",
                      "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false,
                        "sig": "yje-848",
                        "pageId": "gi3kr"
                      },
                      "name": "volumeOff",
                      "svgId": "0da768_2a8395c630404213aaf6576ea1baaa70.svg"
                    },
                    {
                      "type": "ControlIconDesignData",
                      "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false,
                        "sig": "yje-846",
                        "pageId": "gi3kr"
                      },
                      "name": "noAudio",
                      "svgId": "0da768_2a8395c630404213aaf6576ea1baaa70.svg",
                      "iconDesign": {
                        "type": "VectorImageDesignData",
                        "metaData": {
                          "isPreset": false,
                          "schemaVersion": "1.0",
                          "isHidden": false,
                          "sig": "yje-847",
                          "pageId": "gi3kr"
                        },
                        "overrideColors": {
                          "color1": "#ACAEAF"
                        }
                      }
                    }
                  ]
                },
                "style": {
                  "type": "TopLevelStyle",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "ua9-1021",
                    "pageId": "masterPage"
                  },
                  "style": {
                    "groups": {},
                    "properties": {
                      "maincolor": "#FFFFFF",
                      "textcolor": "#2d2d2d",
                      "disabledcolor": "#8d8d8d"
                    },
                    "propertiesSource": {}
                  },
                  "componentClassName": "wysiwyg.viewer.components.MediaControls",
                  "pageId": "",
                  "compId": "",
                  "styleType": "system",
                  "skin": "skins.viewer.mediaControlsNoControlsSkin"
                }
              }
            ],
            "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer",
            "layout": {
              "width": 265,
              "height": 313,
              "x": 21,
              "y": 803,
              "scale": 1,
              "rotationInDegrees": 0,
              "fixedPosition": false
            },
            "componentType": "wysiwyg.viewer.components.MediaPlayer",
            "parent": "comp-kpqy238r",
            "props": {
              "type": "MediaPlayerProperties",
              "metaData": {
                "schemaVersion": "1.0",
                "sig": "yje-94",
                "pageId": "gi3kr"
              },
              "autoplay": true,
              "playerInteraction": {
                "click": "toggle",
                "rollIn": "none",
                "rollOut": "none",
                "allowReplay": true
              },
              "mute": true,
              "loop": true,
              "disableAudio": false,
              "playerAudioInteraction": {
                "rollIn": "none",
                "rollOut": "none"
              },
              "animatePoster": "none",
              "flip": "none"
            },
            "design": {
              "type": "MediaPlayerDesignData",
              "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false,
                "sig": "2k1-103",
                "pageId": "gi3kr"
              },
              "background": {
                "type": "BackgroundMedia",
                "metaData": {
                  "isPreset": false,
                  "schemaVersion": "1.0",
                  "isHidden": false,
                  "sig": "2k1-104",
                  "pageId": "gi3kr"
                },
                "mediaRef": {
                  "type": "WixVideo",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "2k1-105",
                    "pageId": "gi3kr"
                  },
                  "title": "Pink Green",
                  "videoId": "11062b_360c28335b9249d3bab2085ed8e2cb4a",
                  "qualities": [
                    {
                      "quality": "480p",
                      "width": 480,
                      "height": 480,
                      "url": "video/11062b_360c28335b9249d3bab2085ed8e2cb4a/480p/mp4/file.mp4"
                    },
                    {
                      "quality": "720p",
                      "width": 1080,
                      "height": 1080,
                      "url": "video/11062b_360c28335b9249d3bab2085ed8e2cb4a/720p/mp4/file.mp4"
                    }
                  ],
                  "posterImageRef": {
                    "type": "Image",
                    "metaData": {
                      "isPreset": false,
                      "schemaVersion": "1.0",
                      "isHidden": false,
                      "sig": "2k1-106",
                      "pageId": "gi3kr"
                    },
                    "title": "",
                    "uri": "11062b_360c28335b9249d3bab2085ed8e2cb4af000.jpg",
                    "description": "public/8e256233-1752-4026-9341-54036e7f875e/a85e50b3-f78c-4792-a226-040ce52b983c/all/any/all",
                    "width": 1080,
                    "height": 1080,
                    "alt": ""
                  },
                  "opacity": 1,
                  "duration": 10,
                  "preload": "auto",
                  "artist": {
                    "id": "",
                    "name": ""
                  },
                  "generatedPosters": [
                    "11062b_360c28335b9249d3bab2085ed8e2cb4af000.jpg",
                    "11062b_360c28335b9249d3bab2085ed8e2cb4af001.jpg",
                    "11062b_360c28335b9249d3bab2085ed8e2cb4af002.jpg",
                    "11062b_360c28335b9249d3bab2085ed8e2cb4af003.jpg"
                  ],
                  "hasAudio": false,
                  "fps": "25",
                  "adaptiveVideo": [
                    {
                      "format": "hls",
                      "url": "site/media/video/11062b_360c28335b9249d3bab2085ed8e2cb4a/da01a1fc-ad34-44bc-90e8-cc9413c466e6/repackage/hls"
                    }
                  ]
                },
                "color": "#212121",
                "colorOpacity": 1,
                "alignType": "center",
                "fittingType": "fill",
                "scrollType": "none",
                "colorOverlay": "",
                "colorOverlayOpacity": 0,
                "showOverlayForMediaType": "WixVideo"
              },
              "cssStyle": {},
              "themeMappings": {},
              "charas": "design-kn36g7o4"
            },
            "style": {
              "type": "TopLevelStyle",
              "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false,
                "sig": "ua9-1003",
                "pageId": "masterPage"
              },
              "style": {
                "groups": {},
                "properties": {},
                "propertiesSource": {}
              },
              "componentClassName": "wysiwyg.viewer.components.MediaPlayer",
              "pageId": "",
              "compId": "",
              "styleType": "system",
              "skin": "wysiwyg.viewer.skins.mediaPlayerSkin"
            },
            "activeModes": {},
            "id": "comp-kpqwmzxw"
          },
          "preset": {
            "rect": {
              "width": 140,
              "height": 140,
              "x": 0,
              "y": 340
            },
            "mediaManager": {
              "path": "public/8e256233-1752-4026-9341-54036e7f875e/a85e50b3-f78c-4792-a226-040ce52b983c"
            }
          }
        },
        {
          "id": "VideoBox_VideoBox_5",
          "structure": {
            "type": "Container",
            "metaData": {
              "sig": "xae-3213",
              "pageId": "gi3kr"
            },
            "components": [
              {
                "type": "Component",
                "metaData": {
                  "sig": "xae-2619",
                  "pageId": "gi3kr"
                },
                "skin": "skins.viewer.mediaOverlayControlsDefaultSkin",
                "layout": {
                  "width": 111,
                  "height": 112,
                  "x": 125,
                  "y": 100,
                  "scale": 1,
                  "rotationInDegrees": 0,
                  "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.MediaOverlayControls",
                "parent": "comp-kpqwmzy4",
                "props": {
                  "type": "MediaOverlayControlsProperties",
                  "metaData": {
                    "schemaVersion": "1.0",
                    "sig": "yje-820",
                    "autoGenerated": false,
                    "pageId": "gi3kr"
                  },
                  "playerId": "comp-kpqwmzy4"
                },
                "design": {
                  "type": "MediaControlsDesignData",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "xae-2753",
                    "pageId": "gi3kr"
                  },
                  "iconsDefaultDesign": {
                    "type": "VectorImageDesignData",
                    "metaData": {
                      "isPreset": false,
                      "schemaVersion": "1.0",
                      "isHidden": false,
                      "sig": "xae-2752",
                      "pageId": "gi3kr"
                    },
                    "overrideColors": {
                      "color1": "color_11"
                    },
                    "shapeStyle": {
                      "opacity": 1
                    }
                  },
                  "icons": [
                    {
                      "type": "ControlIconDesignData",
                      "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false,
                        "sig": "xae-2754",
                        "pageId": "gi3kr"
                      },
                      "name": "playButton",
                      "svgId": "0da768_661bc0cafffa432db3753ad5d17e4f10.svg"
                    }
                  ]
                },
                "style": {
                  "type": "TopLevelStyle",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "ua9-1010",
                    "pageId": "masterPage"
                  },
                  "style": {
                    "groups": {},
                    "properties": {},
                    "propertiesSource": {}
                  },
                  "componentClassName": "wysiwyg.viewer.components.MediaOverlayControls",
                  "pageId": "",
                  "compId": "",
                  "styleType": "system",
                  "skin": "skins.viewer.mediaOverlayControlsDefaultSkin"
                }
              },
              {
                "type": "Component",
                "metaData": {
                  "sig": "xae-2620",
                  "pageId": "gi3kr"
                },
                "skin": "skins.viewer.mediaControlsNoControlsSkin",
                "layout": {
                  "width": 51,
                  "height": 44,
                  "x": 311,
                  "y": 269,
                  "scale": 1,
                  "rotationInDegrees": 0,
                  "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.MediaControls",
                "parent": "comp-kpqwmzy4",
                "props": {
                  "type": "MediaControlsProperties",
                  "metaData": {
                    "schemaVersion": "1.0",
                    "sig": "yje-830",
                    "autoGenerated": false,
                    "pageId": "gi3kr"
                  },
                  "showStoryboard": "time",
                  "playerId": "comp-kpqwmzy4"
                },
                "design": {
                  "type": "MediaControlsDesignData",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "yje-822",
                    "pageId": "gi3kr"
                  },
                  "iconsDefaultDesign": {
                    "type": "VectorImageDesignData",
                    "metaData": {
                      "isPreset": false,
                      "schemaVersion": "1.0",
                      "isHidden": false,
                      "sig": "yje-827",
                      "pageId": "gi3kr"
                    },
                    "overrideColors": {
                      "color1": "#ffffff"
                    },
                    "shapeStyle": {
                      "opacity": 1,
                      "strokeWidth": 1,
                      "stroke": "#000000",
                      "strokeOpacity": 1,
                      "enableStroke": false
                    }
                  },
                  "icons": [
                    {
                      "type": "ControlIconDesignData",
                      "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false,
                        "sig": "yje-826",
                        "pageId": "gi3kr"
                      },
                      "name": "volumeOn",
                      "svgId": "0da768_9c02fee4517e40e1bfe9581b4cfa3750.svg"
                    },
                    {
                      "type": "ControlIconDesignData",
                      "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false,
                        "sig": "yje-825",
                        "pageId": "gi3kr"
                      },
                      "name": "volumeOff",
                      "svgId": "0da768_2a8395c630404213aaf6576ea1baaa70.svg"
                    },
                    {
                      "type": "ControlIconDesignData",
                      "metaData": {
                        "isPreset": false,
                        "schemaVersion": "1.0",
                        "isHidden": false,
                        "sig": "yje-823",
                        "pageId": "gi3kr"
                      },
                      "name": "noAudio",
                      "svgId": "0da768_2a8395c630404213aaf6576ea1baaa70.svg",
                      "iconDesign": {
                        "type": "VectorImageDesignData",
                        "metaData": {
                          "isPreset": false,
                          "schemaVersion": "1.0",
                          "isHidden": false,
                          "sig": "yje-824",
                          "pageId": "gi3kr"
                        },
                        "overrideColors": {
                          "color1": "#ACAEAF"
                        }
                      }
                    }
                  ]
                },
                "style": {
                  "type": "TopLevelStyle",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "ua9-1021",
                    "pageId": "masterPage"
                  },
                  "style": {
                    "groups": {},
                    "properties": {
                      "maincolor": "#FFFFFF",
                      "textcolor": "#2d2d2d",
                      "disabledcolor": "#8d8d8d"
                    },
                    "propertiesSource": {}
                  },
                  "componentClassName": "wysiwyg.viewer.components.MediaControls",
                  "pageId": "",
                  "compId": "",
                  "styleType": "system",
                  "skin": "skins.viewer.mediaControlsNoControlsSkin"
                }
              }
            ],
            "skin": "wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer",
            "layout": {
              "width": 362,
              "height": 313,
              "x": 396,
              "y": 803,
              "scale": 1,
              "rotationInDegrees": 0,
              "fixedPosition": false
            },
            "componentType": "wysiwyg.viewer.components.MediaPlayer",
            "parent": "comp-kpqy238r",
            "props": {
              "type": "MediaPlayerProperties",
              "metaData": {
                "schemaVersion": "1.0",
                "sig": "yje-118",
                "pageId": "gi3kr"
              },
              "autoplay": true,
              "playerInteraction": {
                "click": "toggle",
                "rollIn": "none",
                "rollOut": "none",
                "allowReplay": true
              },
              "mute": true,
              "loop": true,
              "disableAudio": false,
              "playerAudioInteraction": {
                "rollIn": "none",
                "rollOut": "none"
              },
              "animatePoster": "none",
              "flip": "none"
            },
            "design": {
              "type": "MediaPlayerDesignData",
              "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false,
                "sig": "2k1-98",
                "pageId": "gi3kr"
              },
              "background": {
                "type": "BackgroundMedia",
                "metaData": {
                  "isPreset": false,
                  "schemaVersion": "1.0",
                  "isHidden": false,
                  "sig": "2k1-99",
                  "pageId": "gi3kr"
                },
                "mediaRef": {
                  "type": "WixVideo",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "2k1-100",
                    "pageId": "gi3kr"
                  },
                  "title": "Abstract Structure",
                  "videoId": "11062b_bbe76b2ad34c418c973b10873fba8e77",
                  "qualities": [
                    {
                      "quality": "480p",
                      "width": 854,
                      "height": 480,
                      "url": "video/11062b_bbe76b2ad34c418c973b10873fba8e77/480p/mp4/file.mp4"
                    },
                    {
                      "quality": "720p",
                      "width": 1280,
                      "height": 720,
                      "url": "video/11062b_bbe76b2ad34c418c973b10873fba8e77/720p/mp4/file.mp4"
                    },
                    {
                      "quality": "1080p",
                      "width": 1920,
                      "height": 1080,
                      "url": "video/11062b_bbe76b2ad34c418c973b10873fba8e77/1080p/mp4/file.mp4"
                    }
                  ],
                  "posterImageRef": {
                    "type": "Image",
                    "metaData": {
                      "isPreset": false,
                      "schemaVersion": "1.0",
                      "isHidden": false,
                      "sig": "2k1-101",
                      "pageId": "gi3kr"
                    },
                    "title": "",
                    "uri": "11062b_bbe76b2ad34c418c973b10873fba8e77f000.jpg",
                    "description": "public/8e256233-1752-4026-9341-54036e7f875e/3892dca7-115f-4b62-ba8d-11a7b220ab76/all/any/all",
                    "width": 3840,
                    "height": 2160,
                    "alt": ""
                  },
                  "opacity": 1,
                  "duration": 20.05,
                  "preload": "auto",
                  "artist": {
                    "id": "1197566792",
                    "name": "GettyImages"
                  },
                  "generatedPosters": [
                    "11062b_bbe76b2ad34c418c973b10873fba8e77f000.jpg",
                    "11062b_bbe76b2ad34c418c973b10873fba8e77f001.jpg",
                    "11062b_bbe76b2ad34c418c973b10873fba8e77f002.jpg",
                    "11062b_bbe76b2ad34c418c973b10873fba8e77f003.jpg"
                  ],
                  "hasAudio": false,
                  "fps": "30000/1001",
                  "adaptiveVideo": [
                    {
                      "format": "hls",
                      "url": "site/media/video/11062b_bbe76b2ad34c418c973b10873fba8e77/da01a1fc-ad34-44bc-90e8-cc9413c466e6/repackage/hls"
                    }
                  ]
                },
                "color": "#212121",
                "colorOpacity": 1,
                "alignType": "center",
                "fittingType": "fill",
                "scrollType": "none",
                "colorOverlay": "",
                "colorOverlayOpacity": 0,
                "showOverlayForMediaType": "WixVideo"
              },
              "cssStyle": {},
              "themeMappings": {},
              "charas": "design-knwzlbfx"
            },
            "style": {
              "type": "TopLevelStyle",
              "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false,
                "sig": "ua9-1003",
                "pageId": "masterPage"
              },
              "style": {
                "groups": {},
                "properties": {},
                "propertiesSource": {}
              },
              "componentClassName": "wysiwyg.viewer.components.MediaPlayer",
              "pageId": "",
              "compId": "",
              "styleType": "system",
              "skin": "wysiwyg.viewer.skins.mediaPlayerSkin"
            },
            "activeModes": {},
            "id": "comp-kpqwmzy4"
          },
          "preset": {
            "rect": {
              "width": 184,
              "height": 140,
              "x": 140,
              "y": 340
            },
            "mediaManager": {
              "path": "public/8e256233-1752-4026-9341-54036e7f875e/3892dca7-115f-4b62-ba8d-11a7b220ab76"
            }
          }
        }
      ],
      "compTypes": [
        "wysiwyg.viewer.components.MediaPlayer"
      ]
    },
    "subNavigationTitle": "add_section_media_player_label",
    "presetTitle": "add_section_media_player_label",
    "tooltipTitle": "add_section_media_player_label",
    "subNavigationHide": false,
    "sectionFooter": {
      "label": "add_section_videobox_more_tooltip_title",
      "styleOverride": {"paddingTop": "25px"},
      "action": moreBehaviorHelper,
      "category": "VIDEO_BOX",
      "openSource": "add_panel_videobox_all",
      "path": "public/8e256233-1752-4026-9341-54036e7f875e/d6b6b972-952c-4ab0-b46a-a6c05a0a94a5"
    },
    "help": {
      "hide": false,
      "text": "add_section_media_player_label_tooltip_title"
    }
  }
