// @ts-nocheck
import constants from '@/constants';
import * as util from '@/util';
import * as helpIds from '@/helpIds';
import gfppDataUtils from '../../utils/gfppDataUtils';
import type { EditorAPI } from '@/editorAPI';
import type { CompRef } from '@wix/document-services-types';

const { ACTIONS } = constants.ROOT_COMPS.GFPP;
const mediaContainerBackgroundPanelName =
  'compPanels.panels.MediaContainer.backgroundPanel';
const mediaContainerDesignPanelName = 'customizeDesignDataPanel.standalone';

const { isWidgetController } = util.appStudioUtils;

export default {
  mainActions: [
    {
      label: 'gfpp_mainaction_background_listsandgrids',
      isApplied: true,
      isSelected: gfppDataUtils.getPanelStateFn(
        mediaContainerBackgroundPanelName,
      ),
      onClick(editorAPI: EditorAPI, compRef: CompRef, origin: string) {
        gfppDataUtils.toggleComponentPanel(
          editorAPI,
          mediaContainerBackgroundPanelName,
          {
            selectedComponent: compRef,
            prependNoFillPreset: true,
            origin,
          },
        );
      },
    },
  ],
  enabledActions: [
    ACTIONS.BACKGROUND,
    ACTIONS.DESIGN,
    ACTIONS.ANIMATION,
    ACTIONS.HELP,
    ACTIONS.ACCESSIBILITY,
  ],
  mobileEnabledActions: [ACTIONS.HELP, ACTIONS.ANIMATION],
  presetActions: {
    background: {
      isSelected: gfppDataUtils.getPanelStateFn(
        mediaContainerBackgroundPanelName,
      ),
      onClick(editorAPI, compRef, origin) {
        gfppDataUtils.toggleComponentPanel(
          editorAPI,
          mediaContainerBackgroundPanelName,
          { selectedComponent: compRef, origin },
        );
      },
      isSupported(editorAPI, compRef) {
        return (
          isWidgetController(editorAPI, compRef) ||
          isWidgetController(
            editorAPI,
            editorAPI.components.getContainer_DEPRECATED_BAD_PERFORMANCE(
              compRef,
            ),
          )
        );
      },
    },
    design: {
      isSelected: gfppDataUtils.getPanelStateFn(mediaContainerDesignPanelName),
      onClick(editorAPI: EditorAPI, compRef: CompRef, origin: string) {
        gfppDataUtils.toggleComponentPanel(
          editorAPI,
          mediaContainerDesignPanelName,
          {
            selectedComponent: compRef,
            overrideTitle: 'lists&grids_design_item_title',
            origin,
          },
        );
      },
    },
    help: {
      helpId: helpIds.GFPP.MEDIA_BOX,
      mobileHelpId: helpIds.GFPP.MEDIA_BOX_MOBILE,
    },
  },
};
