// @ts-nocheck
import _ from 'lodash';
import * as util from '@/util';
import { translate } from '@/i18n';
import addPanelDataConsts from '@/addPanelDataConsts';
import pageCategories from '../../../utils/pageCategories';
import * as coreBi from '@/coreBi';
import pagesView from '../pagesView';
import constants from '@/constants';
import pagesAPI from '../../../api/api';
import { sendNavigateToPageBiEvent } from '../../../utils';

const {
  connect,
  renderWhenMutated,
  STORES: { EDITOR_API },
} = util.hoc;

const withInfo = (editorAPI, popups, pagesData) => {
  const behaviours = editorAPI.pages.popupPages.getOpenBehaviorsForPopups();
  const pageIdToTitle = _.zipObject(
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/map
    _.map(pagesData, 'id'),
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/map
    _.map(pagesData, 'title'),
  );
  const popupIdToBehaviour = _.groupBy(behaviours, 'popupId');
  const MASTER_PAGE_ID = editorAPI.dsRead.siteSegments.getSiteStructureId();

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/map
  return _.map(popups, (popup) => {
    const behaviour = popupIdToBehaviour[popup.id];
    const isBehaviorOnMasterPage =
      !!behaviour && behaviour[0].pageId === MASTER_PAGE_ID;
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/map
    const pageIds = _.map(behaviour, 'pageId');

    if (isBehaviorOnMasterPage) {
      popup.info = translate('Pages_Menu_Popup_Tooltip_Set_To_All');
      return popup;
    }

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/map
    const pageTitles = _.map(pageIds, _.partial(_.get, pageIdToTitle));
    if (pageTitles.length > 0) {
      popup.info = generateInfoFromTitles(editorAPI, pageTitles);
    }
    return popup;
  });
};

const generateInfoFromTitles = (editorAPI, titles) => {
  const pagesAmount = titles.length;
  const start = translate('Pages_Menu_Popup_Tooltip_Start_Section', {
    $PAGE_NAME: _.truncate(_.head(titles)),
  });
  const end = translate('Pages_Menu_Popup_Tooltip_End_Section', {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/last
    $PAGE_NAME: _.truncate(_.last(titles)),
  });
  let info;

  if (pagesAmount === 1) {
    info = start;
  } else if (pagesAmount === 2) {
    info = `${start} ${end}`;
  } else {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/reduce
    const startAndMiddle = _.reduce(
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/slice
      _.slice(titles, 1, -1),
      (res, pageName) =>
        `${res} ${translate('Pages_Menu_Popup_Tooltip_Middle_Section', {
          $PAGE_NAME: _.truncate(pageName),
        })}`,
      start,
    );
    info = `${startAndMiddle} ${end}`;
  }

  return info;
};

const getPopups = (editorAPI, popups, pagesData, isDesktop) => {
  const popupsWithActions = popups.map(({ id, title }) => ({
    id,
    title,
    typeSymbol: 'popupPageType',
  }));
  if (!isDesktop) {
    return popupsWithActions;
  }
  return withInfo(editorAPI, popupsWithActions, pagesData, isDesktop);
};

const mapStateToProps = ({ editorAPI }, { popups, pagesData, isDesktop }) => ({
  pages: getPopups(editorAPI, popups, pagesData, isDesktop),
  renameEnabled: isDesktop,
  titleAdd: pageCategories.POPUPS.titleAdd,
  biCategory: pageCategories.POPUPS.biCategory,
});

const onClickAdd = (dispatch, getState, { editorAPI }) => {
  const category = addPanelDataConsts.CATEGORIES_ID.BOX_SLIDE_SHOW;
  editorAPI.panelManager.openPanel(
    constants.ROOT_COMPS.LEFTBAR.ADD_PANEL_NAME,
    {
      origin: 'popupView',
      category,
      sectionId: 'popupWelcomeSection',
    },
  );
  editorAPI.bi.event(coreBi.events.pages.pagesPanel.add_page_selection_click, {
    origin: 'popupView',
    category,
    source: 'lightbox',
  });
};

const navigateTo =
  (popupId, panelName) =>
  (dispatch, getState, { editorAPI }) => {
    const focusedPageId = editorAPI.dsRead.pages.getFocusedPageId();

    if (focusedPageId !== popupId) {
      editorAPI.pages.navigateTo(popupId, function () {
        editorAPI.selection.selectComponentByCompRef(
          editorAPI.pages.popupPages.getPopupContainer(popupId),
        );
      });
      sendNavigateToPageBiEvent(editorAPI, {
        popupId,
        panelName,
        biCategory: pageCategories.POPUPS.biCategory,
      });
    }
  };

const sendBiEvent =
  (...args) =>
  (dispatch, getState, { editorAPI }) =>
    editorAPI.bi.event(...args);

const getPopupsActions =
  (isDesktop, onItemAdded, onRename) =>
  (dispatch, getState, { editorAPI }) =>
    pagesAPI.popups.getActions(editorAPI, isDesktop, onItemAdded, onRename);

const mapDispatchToProps = (
  dispatch,
  { onHelp, biPanelName, isDesktop, onItemAdded, onRename },
) => {
  const popupsActions = dispatch(
    getPopupsActions(isDesktop, onItemAdded, onRename),
  );

  return {
    onClickAdd: !isDesktop ? null : () => dispatch(onClickAdd),
    navigateTo(popupId) {
      return dispatch(navigateTo(popupId, biPanelName));
    },
    onHelp: isDesktop
      ? _.partial(onHelp, pageCategories.POPUPS.helpId)
      : _.partial(onHelp, pageCategories.POPUPS.mobileHelpId),
    biEvent(...args) {
      dispatch(sendBiEvent(...args));
    },
    popupsActions,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/assign
  const statePropsWithPopupActions = _.assign({}, stateProps, {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/map
    pages: _.map(stateProps.pages, (page) =>
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      _.assign({}, page, { actions: dispatchProps.popupsActions }),
    ),
  });

  const dispatchPropsWithoutPopupsActions = _.omit(dispatchProps, [
    'popupsActions',
  ]);

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/assign
  return _.assign(
    {},
    ownProps,
    statePropsWithPopupActions,
    dispatchPropsWithoutPopupsActions,
  );
};

export default connect(
  EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(renderWhenMutated(pagesView));
