import addPanelDataConsts from '@/addPanelDataConsts';
import * as addPanel from '@/oldAddPanel';
import * as superApps from '../../superApps/superApps';
import superAppsConstants from '../../superApps/superAppsConstants';
import searchAppBox from './searchAppBox';

const SECTION_INDEX_IN_GROUP = 0;
const SEARCH_DEF_ID = superAppsConstants.SITE_SEARCH.APP_DEF_ID;
const CATEGORY = 'other';

function searchTPA() {
  return {
    type: addPanelDataConsts.SECTIONS_TYPES.GENERAL,
    hide: false,
    sectionNumber: addPanelDataConsts.SECTION_NUMBERS.SEARCH[CATEGORY],
    props: {
      template: searchAppBox,
    },
    appDefinitionId: SEARCH_DEF_ID,
    title: 'add_section_label_site_search',
    subNavigationTitle: 'add_section_label_site_search',
    id: 'add_section_label_site_search',
    automationId: 'add-panel-section-site-search',
    sectionName: 'siteSearchSection',
    presetTitle: 'add_section_label_site_search',
    subNavigationHide: false,
    showSectionHeader: true,
    additionalBehaviours: {
      labelMode: addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
      infoIcon: addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
      hoverImageAction: addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
      iconEnabledForComps: {},
    },
  };
}

function searchBoxDesignPresets() {
  const preset = addPanel.sections
    .getSections()
    .developer.searchBoxSection.getSection({
      appDefinitionId: SEARCH_DEF_ID,
    });

  return {
    ...preset,
    sectionName: 'siteSearchSection',
    sectionNumber: addPanelDataConsts.SECTION_NUMBERS.SEARCH[CATEGORY],
  };
}

function registerSection(editorAPI: AnyFixMe) {
  editorAPI.addPanel.registerSectionToCategoryByGroup(
    addPanelDataConsts.CATEGORIES_ID.NAVIGATION,
    1,
    SECTION_INDEX_IN_GROUP,
    () =>
      superApps.isInstalled(SEARCH_DEF_ID)
        ? searchBoxDesignPresets()
        : searchTPA(),
  );
}

export { registerSection };
