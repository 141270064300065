'use strict'

const repeaterSection = require('../sections/repeater/repeaterSection')
const oneItemRepeaterSection = require('../sections/repeater/oneItemRepeaterSection')
const gridSection = require('../sections/developer/gridSection')
const listTestimonialsSection = require('../sections/repeater/listTestimonialsRepeaterSection')

module.exports = {
    repeaterSection,
    oneItemRepeaterSection,
    listTestimonialsSection,
    gridSection
}
