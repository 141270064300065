// @ts-nocheck
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import * as helpIds from '@/helpIds';
import constants from '@/constants';
import React from 'react';
import {
  Composites,
  Illustration,
  RichText,
  TextLabel,
} from '@wix/wix-base-ui';
import * as symbols from '@wix/santa-editor-symbols';
import { translate } from '@/i18n';
import { OldMessagePanelFrame } from '../../frames';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'browserDeprecationMessage',
  propTypes: {
    openHelpCenter: PropTypes.func.isRequired,
    browser: PropTypes.string.isRequired,
  },
  browserKeys: {
    chrome: {
      panelTitle: 'Notification_Box_Chrome_Not_Supported_Header',
      subTitle: 'Notification_Box_Chrome_Not_Supported_Text1',
      description: 'Notification_Box_Chrome_Not_Supported_Text2',
      link: 'Notification_Box_Chrome_Not_Supported_Link',
      confirmLabel: 'Notification_Box_Chrome_Not_Supported_Button',
    },
  },
  openLearnMore() {
    this.props.openHelpCenter(helpIds.LEARN_MORE.BROWSER_DEPRECATED, null, {
      learn_more: true,
      panel_name: this.props.panelName,
      origin: constants.BI.HELP.ORIGIN.PANEL,
    });
  },
  render() {
    const keys = this.browserKeys[this.props.browser];

    return (
      <OldMessagePanelFrame
        panelName={this.props.panelName}
        panelTitle={translate(keys.panelTitle)}
        confirmLabel={keys.confirmLabel}
      >
        <Composites.RichTextWithIllustration>
          <Illustration>
            <symbols.symbol name="browser-not-supported" />
          </Illustration>
          <Composites.RichTextLabeled>
            <TextLabel value={keys.subTitle} />
            <TextLabel value={keys.description} />
            <RichText>
              <a onClick={this.openLearnMore}>{translate(keys.link)}</a>
            </RichText>
          </Composites.RichTextLabeled>
        </Composites.RichTextWithIllustration>
      </OldMessagePanelFrame>
    );
  },
});
