import type { MapDispatchToProps, MapStateToProps } from 'types/redux';
import * as stateManagement from '@/stateManagement';

export interface BranchIndicatorPanelDispatchProps {
  openSiteBranchesManager: () => void;
}

export interface BranchIndicatorPanelStateProps {
  branchName: string;
  branchId: string;
}

const getEditorAPI = (
  dispatch: AnyFixMe,
  getState: AnyFixMe,
  { editorAPI }: AnyFixMe,
) => editorAPI;

export const mapStateToProps: MapStateToProps<
  BranchIndicatorPanelStateProps,
  any
> = ({ editorAPI, state }) => ({
  branchName: stateManagement.branches.selectors.getCurrentBranchName(state),
  branchId: editorAPI.dsRead.generalInfo.getBranchId(),
});

export const mapDispatchToProps: MapDispatchToProps<
  BranchIndicatorPanelDispatchProps,
  any
> = (dispatch) => {
  const editorAPI = dispatch(getEditorAPI);
  return {
    openSiteBranchesManager: () =>
      editorAPI.account.openSiteBranchesManager({ inNewTab: true }),
  };
};
