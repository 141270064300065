export default {
  CONTROLLER_GFPP_CLICK: {
    evid: 26,
    src: 79,
    endpoint: 'platform',
    fields: {
      app_name: 'string',
      action_name: 'string',
      controller_type: 'string',
      component_id: 'string',
      pageId: 'string',
    },
  },
  page_delete_dataset: {
    evid: 91,
    src: 79,
    endpoint: 'platform',
    fields: {
      app_name: 'string',
      controller_type: 'string',
      component_id: 'string',
      pageId: 'string',
    },
  },
  conflicting_pages_actions: {
    evid: 1445,
    src: 38,
    endpoint: 'editor',
    fields: {
      target: 'string',
      page_id: 'string',
      default_page_id: 'string',
      app_id: 'string',
      page_name: 'string',
    },
  },
  pages_replace_with_custom: {
    evid: 1442,
    src: 38,
    endpoint: 'editor',
    fields: {
      target: 'string',
    },
  },
  replacement_page_succeeded: {
    evid: 1443,
    src: 38,
    endpoint: 'editor',
    fields: {
      original_page: 'string',
      replacer_page: 'string',
      app_id: 'string',
      host_app_id: 'string',
    },
  },
  site_pages_actions: {
    evid: 1441,
    src: 38,
    endpoint: 'editor',
    fields: {
      target: 'string',
      page_id: 'string',
      page_name: 'string',
      app_id: 'string',
      page_type: 'string',
      host_app_id: 'string',
    },
  },
  site_pages_details_open: {
    evid: 1440,
    src: 38,
    endpoint: 'editor',
    fields: {
      page_id: 'string',
      page_name: 'string',
      app_id: 'string',
      page_type: 'string',
      host_app_id: 'string',
    },
  },
  addAppVariantPage: {
    evid: 1487,
    fields: {
      target: 'string',
      page_id: 'string',
      page_name: 'string',
      app_id: 'string',
      host_app_id: 'string',
    },
  },
} as const;
