import {
  contextMenu,
  domMeasurements,
  mobile,
  panels,
  sections as sectionsState,
} from '@/stateManagement';
import {
  SwitchLayoutApiKey,
  QuickEditApiKey,
  FixedStageApiKey,
  DesignerPanelsApiKey,
} from '@/apis';
import { biLogger, fedopsLogger, fixedStage } from '@/util';
import constants, { RCM_ORIGIN } from '@/constants';
import { utils } from '@/sections';
import { ErrorReporter } from '@wix/editor-error-reporter';
import {
  duplicateComponentOrTpa,
  removeComponentClick,
  sectionClickOnAction,
  hoverSectionActionBar,
} from '@wix/bi-logger-editor/v2';

import {
  ACTION_BAR_ORIGIN,
  ActionIds,
  ACTIONS_AREA_RIGHT_MARGIN,
  FIXED_STAGE_ACTIONS_OFFSET_LEFT,
} from './Actions.constants';
import { actionsDefinitions } from './Actions.definitions';

import type {
  MapDispatchToProps,
  MapStateToProps,
  ThunkAction,
} from 'types/redux';
import type { EditorAPI } from '@/editorAPI';
import type { CompRef } from 'types/documentServices';
import type {
  ActionsOwnProps,
  ActionsStateProps,
  ActionsDispatchProps,
} from './Actions';
import type { MouseEvent } from 'react';
import { getSectionLikeActionsSkin } from './Actions.skins';
import { getActionsGroups } from './Actions.groups';
import experiment from 'experiment';

const moveInteraction = fedopsLogger.mapInteraction(
  fedopsLogger.INTERACTIONS.CLASSIC_SECTIONS.MOVE,
);
const openMoreInteraction = fedopsLogger.mapInteraction(
  fedopsLogger.INTERACTIONS.CLASSIC_SECTIONS.MORE_ACTIONS.OPEN,
);
const openA11yPanelInteraction = fedopsLogger.mapInteraction(
  fedopsLogger.INTERACTIONS.A11Y.OPEN_PANEL,
);
const STAGE_ACTIONS_MARGIN_RIGHT = 10;
const isLeftPanelHiddenMobileElementsRedesignExperimentDisabled =
  experiment.getValue('se_leftPanelHiddenMobileElementsRedesign') !== 'B' &&
  experiment.getValue('se_leftPanelHiddenMobileElementsRedesign') !== 'C';

type ActionGroupsMap = ActionsStateProps['actions'];

const {
  actions: { openContextMenu },
} = contextMenu;
const {
  selectors: { getStageLayout, getSiteScale },
} = domMeasurements;

type ActionGroupIds = keyof ActionsStateProps['actions'];
export type ActionGroupsWithIds = Record<ActionGroupIds, ActionIds[]>;

const transformActionGroups = (
  editorAPI: EditorAPI,
  actions: ActionGroupsWithIds,
  compRef: CompRef,
): ActionGroupsMap =>
  Object.entries(actions).reduce(
    (acc, [groupKey, groupActionsIds]) => {
      acc[groupKey as keyof ActionGroupsMap] = groupActionsIds
        .filter(
          (actionId) =>
            actionsDefinitions[actionId].isVisible?.(editorAPI, compRef) ??
            true,
        )
        .map((actionId) => {
          const definition = actionsDefinitions[actionId];

          const isAllowed = definition.isEnabled?.(editorAPI, compRef) ?? true;
          const disabled = !isAllowed;

          const tooltipData = definition.getTooltipData(
            editorAPI,
            compRef,
            disabled,
          );

          return {
            id: definition.id,
            dataHook: definition.dataHook,
            icon: definition.icon,
            tooltipText: tooltipData.text,
            tooltipHelpId: tooltipData.helpId,
            tooltipCustomLink: tooltipData.customLink,
            disabled,
          };
        });

      return acc;
    },
    {} as ActionsStateProps['actions'],
  );

const getDisabledActionsIds = (groups: ActionGroupsMap): ActionIds[] =>
  Object.keys(groups).reduce((acc, groupId) => {
    const group = groups[groupId as ActionGroupIds];
    const disabledActionsInGroup = group
      .filter((action) => action.disabled)
      .map((action) => action.id);

    return acc.concat(disabledActionsInGroup);
  }, []);

const shouldShiftActionsRight = (editorAPI: EditorAPI) => {
  const fixedStageAPI = editorAPI.host.getAPI(FixedStageApiKey);
  const isFixedStageEnabled =
    fixedStage.isFixedStageEnabled() &&
    !fixedStageAPI.isStageHorizontallyScrollable();

  if (!isFixedStageEnabled) {
    return false;
  }

  if (editorAPI.isMobileEditor()) {
    return true;
  }

  const stageShiftingPanelWidth = Math.abs(
    editorAPI.zoomMode.getFullPanelSizeShiftX(),
  );
  const thresholdWidthForActionsBarOffsetLeft =
    constants.UI.EDITING_AREA_RIGHT_MARGIN + FIXED_STAGE_ACTIONS_OFFSET_LEFT;

  const stageWidthThatShouldAlwaysRemainVisible =
    editorAPI.site.getWidth() * editorAPI.getSiteScale() +
    thresholdWidthForActionsBarOffsetLeft * 2;

  const editorState = editorAPI.store.getState();
  const stageLayout = getStageLayout(editorState);

  if (
    stageShiftingPanelWidth +
      stageWidthThatShouldAlwaysRemainVisible +
      STAGE_ACTIONS_MARGIN_RIGHT >
    stageLayout.width
  ) {
    return false;
  }

  return true;
};

export const mapStateToProps: MapStateToProps<
  ActionsStateProps,
  ActionsOwnProps
> = ({ state, editorAPI }, ownProps) => {
  const sectionLikeRef = ownProps.sectionLike.ref;

  const currentActions = getActionsGroups(editorAPI, sectionLikeRef);

  const actions = transformActionGroups(
    editorAPI,
    currentActions,
    sectionLikeRef,
  );

  const selectedSectionLike = editorAPI.sections.getSelectedSectionLike();
  const hoveredSectionLike = editorAPI.sections.getHoveredSectionLike();
  const focusedSectionLike = editorAPI.sections.getFocusedSectionLike();
  const skin = getSectionLikeActionsSkin(editorAPI, sectionLikeRef, {
    hoveredSectionLike,
    selectedSectionLike,
    focusedSectionLike,
  });
  const isZoomMode = editorAPI.zoomMode.isInZoomMode();

  return {
    actions,
    disabledActionsIds: getDisabledActionsIds(actions),
    isMobile: mobile.selectors.isMobileEditor(state),
    skin,
    shouldHide: !skin,
    isZoomMode,
    shouldShiftActionsRight: shouldShiftActionsRight(editorAPI),
  };
};

const handleActionClick =
  ({
    id,
    event,
    sectionLikeRef,
  }: {
    id: ActionIds;
    event: MouseEvent;
    sectionLikeRef: CompRef;
  }): ThunkAction =>
  (dispatch, getState, { editorAPI }) => {
    const { left, top, right } = event.currentTarget.getBoundingClientRect();
    const [defaultComponentBiFields] = editorAPI.bi.getComponentsBIParams([
      sectionLikeRef,
    ]);

    const selectIfNeeded = (compRef: CompRef, selectionOrigin: string) => {
      const selectedComponentId = editorAPI.selection.getSelectedComponentId();

      if (selectedComponentId !== compRef.id) {
        editorAPI.selection.selectComponentByCompRef(compRef, {
          origin: selectionOrigin,
        });
      }
    };

    const moveUp = async () => {
      moveInteraction.start();
      selectIfNeeded(sectionLikeRef, 'after_action');
      await editorAPI.sections.moveUp(sectionLikeRef);
      moveInteraction.end();
    };
    const moveDown = async () => {
      moveInteraction.start();
      selectIfNeeded(sectionLikeRef, 'after_action');
      await editorAPI.sections.moveDown(sectionLikeRef);
      moveInteraction.end();
    };
    const rename = () => {
      fedopsLogger.interactionStarted(
        fedopsLogger.INTERACTIONS.CLASSIC_SECTIONS.RENAME_PANEL.OPEN,
      );
      utils.openSectionNamePanel(
        editorAPI,
        sectionLikeRef,
        { origin: ACTION_BAR_ORIGIN },
        {
          left,
          top,
        },
      );
    };

    const openQuickEditPanel = () => {
      const quickEditAPI = editorAPI.host.getAPI(QuickEditApiKey);
      // TODO: use origin from const/enum
      quickEditAPI.openPanel({
        rootControlCompRef: sectionLikeRef,
        origin: ACTION_BAR_ORIGIN,
      });
    };

    const openSwitchLayoutPanel = () => {
      const switchLayoutAPI = editorAPI.host.getAPI(SwitchLayoutApiKey);
      switchLayoutAPI.openSwitchLayoutPanel({
        sectionRef: sectionLikeRef,
        origin: ACTION_BAR_ORIGIN,
      });
    };

    const openScanSwitchLayoutPresetPanel = () => {
      const switchLayoutAPI = editorAPI.host.getAPI(SwitchLayoutApiKey);
      switchLayoutAPI.scanSwitchLayoutPreset({
        sectionRef: sectionLikeRef,
      });
    };

    const renderSwitchLayoutResults = () => {
      const switchLayoutAPI = editorAPI.host.getAPI(SwitchLayoutApiKey);
      switchLayoutAPI.switchLayoutForComparison(
        {
          sectionRef: sectionLikeRef,
          origin: ACTION_BAR_ORIGIN,
        },
        true,
      );
    };

    const openDesignerScanSectionPanel = () => {
      editorAPI.host
        .getAPI(DesignerPanelsApiKey)
        .openDesignerScanPresetPanel(sectionLikeRef);
    };

    const duplicate = async () => {
      fedopsLogger.interactionStarted(
        fedopsLogger.INTERACTIONS.DUPLICATE_COMPONENT_OR_TPA,
      );
      await editorAPI.copyPaste.duplicate(sectionLikeRef);

      editorAPI.history.add('duplicate component from actions bar', {
        isAddingComponent: true,
      });

      // there is also a general event for "sectionLike action click", but also we need to add BI for common duplicate event
      biLogger.report(
        duplicateComponentOrTpa({
          origin: ACTION_BAR_ORIGIN,
          ...defaultComponentBiFields,
        }),
      );
    };

    const remove = async () => {
      fedopsLogger.interactionStarted(
        fedopsLogger.INTERACTIONS.REMOVE_COMPONENT,
      );
      // there is also a general event for "sectionLike action click", but also we need to add BI for common remove event
      biLogger.report(
        removeComponentClick({
          removal_method: ACTION_BAR_ORIGIN,
          ...defaultComponentBiFields,
        }),
      );

      const removed = await editorAPI.components.remove(
        sectionLikeRef,
        null,
        ACTION_BAR_ORIGIN,
      );

      if (removed) {
        editorAPI.history.add('remove sectionLike by action bar');
        fedopsLogger.interactionEnded(
          fedopsLogger.INTERACTIONS.REMOVE_COMPONENT,
        );
      }
    };
    const hide = () => {
      fedopsLogger.interactionStarted(fedopsLogger.INTERACTIONS.HIDE_COMP);
      editorAPI.components
        .remove(sectionLikeRef, null, ACTION_BAR_ORIGIN)
        .then((removed: boolean) => {
          if (removed) {
            if (isLeftPanelHiddenMobileElementsRedesignExperimentDisabled) {
              editorAPI.mobile.hideHiddenItemsCounter();
            }
            editorAPI.history.add('component - hide on mobile');
            fedopsLogger.interactionEnded(fedopsLogger.INTERACTIONS.HIDE_COMP);
          }
        });
    };
    const openAccessibilityPanel = () => {
      openA11yPanelInteraction.start();
      editorAPI.accessibility.openA11yPanel(
        sectionLikeRef,
        { top, left },
        { openPanelInteraction: openA11yPanelInteraction },
      );
    };

    const openSettingsPanel = () =>
      utils.openSiteSegmentSettingsPanel(
        editorAPI,
        sectionLikeRef,
        { origin: ACTION_BAR_ORIGIN },
        {
          left,
          top,
        },
      );

    const openAnimationPanel = () =>
      utils.openSiteSegmentAnimationPanel(editorAPI, sectionLikeRef, {
        origin: ACTION_BAR_ORIGIN,
      });

    const getRightClickMenuX = () => {
      const state = getState();
      const siteScale = getSiteScale(state);
      const isMobile = mobile.selectors.isMobileEditor(state);

      if (isMobile) {
        return event.clientX;
      }

      if (siteScale !== 1) {
        return right + ACTIONS_AREA_RIGHT_MARGIN * siteScale;
      }

      const stageLayout = getStageLayout(state);
      return stageLayout.right - ACTIONS_AREA_RIGHT_MARGIN;
    };

    const openRightClickMenu = () => {
      openMoreInteraction.start();
      selectIfNeeded(sectionLikeRef, 'after_action');
      dispatch(
        openContextMenu({
          editorPositionX: getRightClickMenuX(),
          editorPositionY: event.clientY,
          origin: RCM_ORIGIN.ACTIONS_MORE_ACTION,
          biOrigin: RCM_ORIGIN.ACTIONS_MORE_ACTION,
          openRCMInteraction: openMoreInteraction,
        }),
      );
    };

    const handlersMap: Record<ActionIds, (e: MouseEvent) => void> = {
      [ActionIds.MoveUp]: moveUp,
      [ActionIds.MoveDown]: moveDown,
      [ActionIds.Rename]: rename,
      [ActionIds.QuickEdit]: openQuickEditPanel,
      [ActionIds.SwitchLayout]: openSwitchLayoutPanel,
      [ActionIds.SwitchLayoutComparison]: renderSwitchLayoutResults,
      [ActionIds.ScanPreset]: openDesignerScanSectionPanel,
      [ActionIds.Duplicate]: duplicate,
      [ActionIds.Remove]: remove,
      [ActionIds.ShowMore]: openRightClickMenu,
      [ActionIds.Hide]: hide,
      [ActionIds.Accessibility]: openAccessibilityPanel,
      [ActionIds.Settings]: openSettingsPanel,
      [ActionIds.Animation]: openAnimationPanel,
      [ActionIds.ScanSwitchLayoutPreset]: openScanSwitchLayoutPresetPanel,
    };

    ErrorReporter.breadcrumb('clicked action in sections bar', {
      actionId: id,
      compRef: sectionLikeRef,
    });
    handlersMap[id](event);
  };

const getEditorAPI: ThunkAction<EditorAPI> = (
  dispatch,
  getState,
  { editorAPI },
) => editorAPI;

export const mapDispatchToProps: MapDispatchToProps<
  ActionsDispatchProps,
  ActionsOwnProps
> = (dispatch, ownProps) => ({
  onActionClick: (id, event) =>
    dispatch(
      handleActionClick({
        id,
        event,
        sectionLikeRef: ownProps.sectionLike.ref,
      }),
    ),
  logActionClicked(actionName, disabledActions) {
    const editorAPI: EditorAPI = dispatch(getEditorAPI);
    const sectionLikeRef = ownProps.sectionLike.ref;

    const [defaultComponentFields] = editorAPI.bi.getComponentsBIParams([
      sectionLikeRef,
    ]);

    const openedPanels = editorAPI.panelManager
      .getOpenPanels()
      .map(({ name }) => name)
      .join(',');

    biLogger.report(
      sectionClickOnAction({
        ...defaultComponentFields,
        action_name: actionName,
        actions_disabled: disabledActions.join(','),
        section_name: editorAPI.sections.getSectionName(sectionLikeRef),
        origin: ACTION_BAR_ORIGIN,
        openedPanels,
      }),
    );
  },
  openHelpCenter: (helpId) => dispatch(panels.actions.openHelpCenter(helpId)),
  setIsSectionActionsBarHovered: (hovered) =>
    dispatch(sectionsState.actions.setIsSectionActionsBarHovered(hovered)),
  logActionsHovered: () => {
    const editorAPI: EditorAPI = dispatch(getEditorAPI);
    const sectionLikeRef = ownProps.sectionLike.ref;
    const focusedSectionLike = editorAPI.sections.getFocusedSectionLike();
    const isSectionFocused = focusedSectionLike?.id === sectionLikeRef.id;
    const isGfppVisible = editorAPI.components.is.gfppVisible([sectionLikeRef]);

    const [defaultComponentFields] = editorAPI.bi.getComponentsBIParams([
      sectionLikeRef,
    ]);
    biLogger.report(
      hoverSectionActionBar({
        ...defaultComponentFields,
        is_section_selected: isSectionFocused,
        firstTimeSectionFocus:
          isSectionFocused &&
          editorAPI.sections.isFirstTimeParentSectionLikeFocused(
            sectionLikeRef,
          ),
        isGfppVisible,
      }),
    );
  },
});
