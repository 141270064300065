// @ts-nocheck
import constants from '@/constants';
import gfppDataUtils from '../../utils/gfppDataUtils';

const { ACTIONS } = constants.ROOT_COMPS.GFPP;

export default {
  mainActions: [
    {
      label: 'gfpp_mainaction_anchor',
      isApplied: false,
      isSelected: gfppDataUtils.getPanelStateFn(ACTIONS.SETTINGS),
      onClick: gfppDataUtils.getTogglePanelFn(ACTIONS.SETTINGS),
    },
  ],
  enabledActions: [ACTIONS.SETTINGS, ACTIONS.HELP],
  mobileEnabledActions: [ACTIONS.HIDE, ACTIONS.HELP],
  presetActions: {
    help: {
      helpId: '0a1de6d7-3f69-4d30-8ddc-7aa99bd12edc',
    },
  },
};
