:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._saleIndicatorB_1cppj_7 {
  min-width: 55px;
  height: 18px;
  line-height: 18px;
  border-radius: 2px;
  color: white;
  background: #9a27d5;
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: var(--wbu-font-stack);
  padding: 0 6px;
  box-sizing: border-box; }
  ._saleIndicatorB_active_1cppj_20 {
    background: #c161f0; }
