// @ts-nocheck
import addPanelDataConsts from '@/addPanelDataConsts';

export default {
  type: addPanelDataConsts.SECTIONS_TYPES.THEME,
  hide: true,
  title: 'add_section_label_theme_social',
  showSectionHeader: true,
  additionalBehaviours: {
    labelMode: addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
    infoIcon: addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
    iconEnabledForComps: [],
  },
  props: {
    compTypes: [
      'wysiwyg.viewer.components.WFacebookLike',
      'wysiwyg.viewer.components.WGooglePlusOne',
      'wysiwyg.viewer.components.WTwitterTweet',
    ],
    verticalMargin: 30,
    horizontalMargin: 20,
    maxFontSize: 10,
    scaleProportion: {
      x: 1,
      y: 1,
    },
  },
  help: {
    hide: false,
    text: 'social_section_theme_info_text',
  },
};
