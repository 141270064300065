import { ListClassificationApi } from './listClassificationApi';
import { EditorAPIKey, ListClassificationApiKey } from '@/apis';
import { BaseEntryScope, createEntryPoint } from '@/apilib';

export class Scope extends BaseEntryScope {
  editorAPI = this.useDependency(EditorAPIKey);
}

export const ListClassificationApiEntryPoint = createEntryPoint({
  name: 'ListClassificationApi',
  Scope,
  publicApi({ contributeApi }) {
    contributeApi(ListClassificationApiKey, ListClassificationApi);
  },
  initialize() {},
});
