import constants from '@/constants';
import type { CompStructure } from 'types/documentServices';
import { getTextComponentMeasurement } from './switchLayoutUtil';
import switchLayoutRulesTypes from './switchLayoutRulesTypes';
import type { EditorAPI } from '@/editorAPI';

export interface RulesMap {
  [componentType: string]: (
    editorAPI?: EditorAPI,
    serializedCompToValidate?: CompStructure,
    rulesType?: string,
    compRules?: any,
  ) => Promise<boolean>;
}

export const rulesMap: RulesMap = {
  [constants.COMP_TYPES.TEXT]: async (
    editorAPI,
    serializedCompToValidate,
    rulesType,
    compRules,
  ) => {
    switch (rulesType) {
      case switchLayoutRulesTypes.SIZE: {
        let isValid = true;
        const allowedMaxHeight = compRules?.maxSize?.height;
        const { height } = await getTextComponentMeasurement(
          editorAPI,
          serializedCompToValidate,
        );
        if (allowedMaxHeight && allowedMaxHeight < height) {
          isValid = false;
        }
        return isValid;
      }
      default:
        return true;
    }
  },
};
