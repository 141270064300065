import {
  environment,
  editorDataFunction,
  componentInstanceData,
} from '@wix/editor-component-data-resolver';

const WIX_CHAT_APP_DEF_ID = '14517e1a-3ff0-af98-408e-2bd6953c36a2';
const NON_SELECTABLE_GLUED_TPA_APPS = [WIX_CHAT_APP_DEF_ID];
const NON_DUPLICABLE_GLUED_TPA_APPS = [WIX_CHAT_APP_DEF_ID];

export default {
  pinnable: false,
  duplicatable: editorDataFunction(
    componentInstanceData('tpaAppData'),
    (appData) => {
      return !NON_DUPLICABLE_GLUED_TPA_APPS.includes(appData.appDefinitionId);
    },
  ),
  selectable: editorDataFunction(
    environment('isMobileEditor'),
    componentInstanceData('tpaAppData'),
    (isMobileEditor, appData) => {
      const isNonSelectableTPA = NON_SELECTABLE_GLUED_TPA_APPS.includes(
        appData.appDefinitionId,
      );
      return !isMobileEditor || (isMobileEditor && !isNonSelectableTPA);
    },
  ),
  customizeTranslate: true,
};
