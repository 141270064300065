import React from 'react';
import { Button, Text } from '@wix/wix-base-ui';
import * as symbols from '@wix/santa-editor-symbols';
import { translate } from '@/i18n';
import { connectWithScope, type InferComponentProps } from '@/apilib';
import { PromoteScope } from '../../../scope';
import { Bullets } from '../common/bullets';
import { MarketingPanel } from '../common/marketingPanel';
import promoteBi from '../common/bi/promoteBi';

export interface OwnProps {}

type SocialMediaPanelProps = InferComponentProps<
  typeof mapStateToProps,
  typeof mapDispatchToProps,
  OwnProps
>;

const TAB_NAME = 'SocialMediaPanel';
const PRODUCT_NAME = 'SocialPosts';

const SocialMediaPanel = React.memo<SocialMediaPanelProps>(
  (props: SocialMediaPanelProps) => {
    React.useEffect(() => {
      props.sendBi(promoteBi.events.TAB_VIEW, {
        ...props.biBaseParams,
        tab_name: TAB_NAME,
        product_name: PRODUCT_NAME,
      });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const onPressCTA = () => {
      props.sendBi(promoteBi.events.PRODUCT_CLICK, {
        ...props.biBaseParams,
        tab_name: TAB_NAME,
        product_name: PRODUCT_NAME,
      });
      props.navigateToSocialMedia();
    };

    return (
      <MarketingPanel
        logo={
          <symbols.symbol name="promoteSocialMediaPanelLogo" className="logo" />
        }
        title={
          <Text
            skin="standard"
            size="large"
            weight="bold"
            enableEllipsis={false}
          >
            {'Promote_Social_Media_Marketing_Title'}
          </Text>
        }
        body={
          <Bullets
            bullets={[
              'Promote_Social_Media_Marketing_Bullet1',
              'Promote_Social_Media_Marketing_Bullet2',
              'Promote_Social_Media_Marketing_Bullet3',
            ]}
          />
        }
        actions={
          <Button onClick={onPressCTA} className="btn-md">
            {translate('Promote_Social_Media_Marketing_Main_Button')}
          </Button>
        }
      />
    );
  },
);

const mapStateToProps = (scope: PromoteScope) => {
  const biBaseParams = promoteBi.biUtils.getBiBaseParams(scope);

  return {
    biBaseParams,
    msid: scope.editorAPI.dsRead.generalInfo.getMetaSiteId(),
  };
};

const mapDispatchToProps = (scope: PromoteScope) => {
  return {
    navigateToSocialMedia: () =>
      scope.editorAPI.account.openSettings({ tab: 'SocialPosts' }),
    sendBi: (event: ValueOf<typeof promoteBi.events>, parameters: object) => {
      promoteBi.report(event, parameters);
    },
  };
};

SocialMediaPanel.displayName = 'SocialMediaPanel';

export default connectWithScope(
  () => PromoteScope,
  SocialMediaPanel,
  mapStateToProps,
  mapDispatchToProps,
);
