@charset "UTF-8";
:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._panel_si9rl_8 {
  max-width: 510px;
  padding-left: 6px;
  padding-bottom: 6px; }
  ._panel_si9rl_8._premium_si9rl_12 ._textContainer_si9rl_12 .control-promotional-list .promotional-list-item > svg {
    fill: #9a27d5; }
  ._panel_si9rl_8._premium_si9rl_12 ._footer_si9rl_14 button.btn-confirm-secondary {
    background: #e5c9ee;
    color: #9a27d5; }
    ._panel_si9rl_8._premium_si9rl_12 ._footer_si9rl_14 button.btn-confirm-secondary:hover {
      background: #eedbf4;
      color: #9a27d5; }
  ._panel_si9rl_8._premium_si9rl_12 ._footer_si9rl_14 button {
    background: #9a27d5;
    color: #f7f8f8; }
    ._panel_si9rl_8._premium_si9rl_12 ._footer_si9rl_14 button:hover {
      background: #c161f0;
      color: #f7f8f8; }

._content_si9rl_27 {
  display: flex;
  margin-bottom: 24px; }

._textContainer_si9rl_12 {
  display: inline-block;
  vertical-align: top;
  color: #3b4057;
  min-width: 300px; }
  ._textContainer_si9rl_12 .control-promotional-list .promotional-list-item {
    align-items: center;
    margin-bottom: 9px; }
    ._textContainer_si9rl_12 .control-promotional-list .promotional-list-item > .control-label .control-label-base {
      font-family: HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
      font-family: var(--wbu-font-stack, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
      font-weight: var(--wbu-font-weight-regular);
      font-size: 14px;
      -webkit-font-smoothing: antialiased; }
    ._textContainer_si9rl_12 .control-promotional-list .promotional-list-item > svg {
      width: 11px;
      height: 10px;
      align-self: flex-start;
      margin-right: 13px;
      margin-top: 4px;
      fill: #116dff; }
    ._textContainer_si9rl_12 .control-promotional-list .promotional-list-item:last-child {
      margin-bottom: 0; }

._listContainer_si9rl_55 {
  margin-top: 18px; }

._title_si9rl_58 {
  color: #000624; }

._subtitle_si9rl_61 {
  margin-top: 6px;
  white-space: nowrap;
  color: #3b4057; }

._symbolContainer_si9rl_66 {
  display: inline-block;
  vertical-align: top;
  margin-left: 24px; }
  ._symbolContainer_si9rl_66 svg {
    width: 160px;
    height: 160px; }

._footer_si9rl_14 button {
  height: 30px;
  padding-left: 18px;
  padding-right: 18px;
  font-size: 14px;
  margin-right: 14px; }

._footer_si9rl_14 button:last-of-type {
  margin-right: 0; }
