._header_3sfsj_1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  padding: 18px 24px; }
  ._header_3sfsj_1 ._headerText_3sfsj_7 {
    display: flex;
    flex-direction: column;
    gap: 3px; }
    ._header_3sfsj_1 ._headerText_3sfsj_7 ._title_3sfsj_11 {
      display: flex;
      gap: 4px; }
  ._header_3sfsj_1 ._headerActions_3sfsj_14 {
    display: flex;
    gap: 6px;
    align-items: center;
    height: 24px; }
