import React from 'react';
import { Text } from '@wix/wix-base-ui';

import { frames } from '@/panels';

const { MessagePanelFrame } = frames;

interface OnlyBackendLoginFrameProps {
  panelName: string;
  title: string;
  footnote: string;
  confirmLabel?: string;
  cancelLabel?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
  onHelp?: () => void;
  closeOnEscPress?: boolean;
}

export const OnlyBackendLoginFrame: React.FC<OnlyBackendLoginFrameProps> = (
  props,
) => {
  return (
    <MessagePanelFrame
      className="new-workspace-only-backend-login"
      panelName={props.panelName}
      onConfirm={props.onConfirm}
      onCancel={props.onCancel}
      cancelLabel={props.cancelLabel}
      confirmLabel={props.confirmLabel}
      onHelp={props.onHelp}
      title={props.title}
      footnote={
        <Text
          size="small"
          weight="normal"
          skin="secondary"
          enableEllipsis={false}
          shouldTranslate={false}
        >
          {props.footnote}
        </Text>
      }
      hideCloseButton={false}
      cancelOnClickOutside={false}
      cancelOnEscPress={false}
      illustration={undefined}
    >
      <Text
        size="medium"
        weight="normal"
        skin="secondary"
        enableEllipsis={false}
        shouldTranslate={false}
      >
        {props.children}
      </Text>
    </MessagePanelFrame>
  );
};
