[
  {
    "presetIndex": 1,
    "path": "public/8e256233-1752-4026-9341-54036e7f875e/3892dca7-115f-4b62-ba8d-11a7b220ab76",
    "ref": {
      "type": "BackgroundMedia",
      "mediaRef": {
        "type": "WixVideo",
        "title": "Shadow",
        "videoId": "11062b_e55321c53b02478b9bafa28958160488",
        "qualities": [
          {
            "quality": "480p",
            "width": 854,
            "height": 480,
            "url": "video/11062b_e55321c53b02478b9bafa28958160488/480p/mp4/file.mp4"
          },
          {
            "quality": "720p",
            "width": 1280,
            "height": 720,
            "url": "video/11062b_e55321c53b02478b9bafa28958160488/720p/mp4/file.mp4"
          },
          {
            "quality": "1080p",
            "width": 1920,
            "height": 1080,
            "url": "video/11062b_e55321c53b02478b9bafa28958160488/1080p/mp4/file.mp4"
          }
        ],
        "posterImageRef": {
          "type": "Image",
          "title": "",
          "uri": "11062b_e55321c53b02478b9bafa28958160488f000.jpg",
          "description": "search/public/shadow/8e256233-1752-4026-9341-54036e7f875e/d6b6b972-952c-4ab0-b46a-a6c05a0a94a5/all/any/all",
          "width": 1920,
          "height": 1080
        },
        "opacity": 1,
        "duration": 14.95,
        "loop": true,
        "autoplay": true,
        "preload": "auto",
        "mute": true,
        "artist": {
          "id": "",
          "name": ""
        },
        "generatedPosters": [
          "11062b_e55321c53b02478b9bafa28958160488f000.jpg",
          "11062b_e55321c53b02478b9bafa28958160488f001.jpg",
          "11062b_e55321c53b02478b9bafa28958160488f002.jpg",
          "11062b_e55321c53b02478b9bafa28958160488f003.jpg"
        ],
        "hasAudio": true,
        "fps": "15000/1001",
        "adaptiveVideo": [
          {
            "format": "hls",
            "url": "site/media/video/11062b_e55321c53b02478b9bafa28958160488/da01a1fc-ad34-44bc-90e8-cc9413c466e6/repackage/hls"
          }
        ]
      },
      "color": "{color_11}",
      "alignType": "center",
      "fittingType": "fill",
      "scrollType": "fixed",
      "colorOverlay": "",
      "colorOverlayOpacity": 0,
      "imageOverlay": ""
    }
  },
  {
    "presetIndex": 2,
    "path": "public/6ae967c404004038b5e84dd0a6ab2b65/e841b416588f4d6ba3006b7b7f3ae0f4",
    "ref": {
      "type": "BackgroundMedia",
      "mediaRef": {
        "type": "Image",
        "title": "Wooden Hut",
        "uri": "11062b_61151d0087ad418fa801687a88c78716~mv2.jpg",
        "description": "search/public/hut/6ae967c404004038b5e84dd0a6ab2b65/73d89c137a524437880b113939ca24c3/all/any/all",
        "width": 3365,
        "height": 2318,
        "artist": {
          "id": "",
          "name": ""
        },
        "opacity": 1
      },
      "color": "#DDE8EB",
      "alignType": "center",
      "fittingType": "fill",
      "scrollType": "fixed",
      "colorOverlay": "",
      "colorOverlayOpacity": 0,
      "imageOverlay": ""
    }
  },
  {
    "presetIndex": 3,
    "path": "public/6ae967c404004038b5e84dd0a6ab2b65/60203b12a89f48c397e7aa694220dc32",
    "ref": {
      "type": "BackgroundMedia",
      "mediaRef": {
        "type": "Image",
        "title": "Yoga Practice",
        "uri": "352540af0345423b8d447b3b337f557a.jpg",
        "description": "search/public/yoga/6ae967c404004038b5e84dd0a6ab2b65/7984c5c1d764454da064e0f1bfe82e90/all/any/all",
        "width": 5760,
        "height": 3840,
        "artist": {
          "id": "",
          "name": ""
        }
      },
      "color": "#B9BBBD",
      "alignType": "center",
      "fittingType": "fill",
      "scrollType": "fixed",
      "colorOverlay": "",
      "colorOverlayOpacity": 0,
      "imageOverlay": ""
    }
  },
  {
    "presetIndex": 4,
    "path": "public/6ae967c404004038b5e84dd0a6ab2b65/38cf7446110341aba0f1aa056078f75b",
    "ref": {
      "type": "BackgroundMedia",
      "mediaRef": {
        "type": "Image",
        "title": "Glass of Milk",
        "uri": "11062b_2835936bf489427ba698acf73b59a048~mv2.jpg",
        "description": "search/public/milk/6ae967c404004038b5e84dd0a6ab2b65/38cf7446110341aba0f1aa056078f75b/all/any/all",
        "width": 3380,
        "height": 2252,
        "artist": {
          "id": "",
          "name": ""
        },
        "opacity": 1
      },
      "color": "#A2BECE",
      "alignType": "center",
      "fittingType": "fill",
      "scrollType": "fixed",
      "colorOverlay": "",
      "colorOverlayOpacity": 0,
      "imageOverlay": ""
    }
  },
  {
    "presetIndex": 5,
    "path": "public/6ae967c404004038b5e84dd0a6ab2b65/7984c5c1d764454da064e0f1bfe82e90",
    "ref": {
      "type": "BackgroundMedia",
      "mediaRef": {
        "type": "Image",
        "title": "Ballet Dancer Duo",
        "uri": "2bd0948a487841efae072adebe98019d.jpg",
        "description": "search/public/ballet/6ae967c404004038b5e84dd0a6ab2b65/73d89c137a524437880b113939ca24c3/all/any/all",
        "width": 1920,
        "height": 1280,
        "artist": {
          "id": "",
          "name": ""
        }
      },
      "color": "#A2BECE",
      "alignType": "center",
      "fittingType": "fill",
      "scrollType": "fixed",
      "colorOverlay": "",
      "colorOverlayOpacity": 0,
      "imageOverlay": ""
    }
  },
  {
    "presetIndex": 6,
    "path": "public/6ae967c404004038b5e84dd0a6ab2b65/7e9232fc633740e497997bff2ffc6bdc",
    "ref": {
      "type": "BackgroundMedia",
      "mediaRef": {
        "type": "Image",
        "title": "Glass Buildings",
        "uri": "daa8147c90904123b99659c0169c0d91.jpg",
        "description": "search/public/building/6ae967c404004038b5e84dd0a6ab2b65/73d89c137a524437880b113939ca24c3/all/any/all",
        "width": 4700,
        "height": 1976,
        "artist": {
          "id": "",
          "name": ""
        }
      },
      "color": "#A2CDF2",
      "alignType": "center",
      "fittingType": "fill",
      "scrollType": "fixed",
      "colorOverlay": "",
      "colorOverlayOpacity": 0,
      "imageOverlay": ""
    }
  },
  {
    "presetIndex": 7,
    "path": "public/cf60a933270949719f109d5e5e7d1b7c/7d104a612507427a8894924af01cbdef",
    "ref": {
      "type": "BackgroundMedia",
      "mediaRef": {
        "type": "Image",
        "title": "Painted Greek Island",
        "uri": "11062b_7d8badf22ed9415e8c2974ee130d4943~mv2.jpg",
        "description": "search/public/greek/cf60a933270949719f109d5e5e7d1b7c/7d104a612507427a8894924af01cbdef/all/any/all",
        "width": 4000,
        "height": 2753,
        "artist": {
          "id": "",
          "name": ""
        }
      },
      "color": "#B2CAED",
      "alignType": "center",
      "fittingType": "fill",
      "scrollType": "fixed",
      "colorOverlay": "",
      "colorOverlayOpacity": 0,
      "imageOverlay": ""
    }
  },
  {
    "presetIndex": 8,
    "path": "public/6ae967c404004038b5e84dd0a6ab2b65/38cf7446110341aba0f1aa056078f75b",
    "ref": {
      "type": "BackgroundMedia",
      "mediaRef": {
        "type": "Image",
        "title": "Still Life",
        "uri": "11062b_a80d871866464a069d603044c4de55a8~mv2.jpg",
        "description": "search/public/still life/6ae967c404004038b5e84dd0a6ab2b65/73d89c137a524437880b113939ca24c3/all/any/all",
        "width": 3000,
        "height": 1688,
        "artist": {
          "id": "",
          "name": ""
        }
      },
      "color": "#3A74C9",
      "alignType": "center",
      "fittingType": "fill",
      "scrollType": "fixed",
      "colorOverlay": "",
      "colorOverlayOpacity": 0,
      "imageOverlay": ""
    }
  },
  {
    "presetIndex": 9,
    "path": "public/6ae967c404004038b5e84dd0a6ab2b65/eaee5f5665214772aa5e183cd9956250",
    "ref": {
      "type": "BackgroundMedia",
      "mediaRef": {
        "type": "Image",
        "title": "Tennis Player",
        "uri": "de21f39dfcb70646a0fdc86ff025a7a1.jpg",
        "description": "search/public/tennis player/6ae967c404004038b5e84dd0a6ab2b65/eaee5f5665214772aa5e183cd9956250/all/any/all",
        "width": 1280,
        "height": 853,
        "artist": {
          "id": "",
          "name": ""
        },
        "opacity": 1
      },
      "color": "#27508F",
      "alignType": "center",
      "fittingType": "fill",
      "scrollType": "fixed",
      "colorOverlay": "",
      "colorOverlayOpacity": 0,
      "imageOverlay": ""
    }
  },
  {
    "presetIndex": 10,
    "path": "public/6ae967c404004038b5e84dd0a6ab2b65/7e9232fc633740e497997bff2ffc6bdc",
    "ref": {
      "type": "BackgroundMedia",
      "mediaRef": {
        "type": "Image",
        "title": "Skyscrapers",
        "uri": "62f26520e99441c6804b83aaf5423953.jpg",
        "description": "search/public/building/6ae967c404004038b5e84dd0a6ab2b65/7e9232fc633740e497997bff2ffc6bdc/all/any/all",
        "width": 4300,
        "height": 2867,
        "artist": {
          "id": "",
          "name": ""
        },
        "opacity": 1
      },
      "color": "#6EB0CC",
      "alignType": "center",
      "fittingType": "fill",
      "scrollType": "fixed",
      "colorOverlay": "",
      "colorOverlayOpacity": 0,
      "imageOverlay": ""
    }
  },
  {
    "presetIndex": 11,
    "path": "public/8e256233-1752-4026-9341-54036e7f875e/09309800-7aa0-4352-8f8a-101028e04bf1",
    "ref": {
      "type": "BackgroundMedia",
      "mediaRef": {
        "type": "WixVideo",
        "title": "Stormy Skies",
        "videoId": "11062b_2ccb88c1c6de4151b51879d6c90fca9d",
        "qualities": [
          {
            "quality": "480p",
            "width": 854,
            "height": 480,
            "url": "video/11062b_2ccb88c1c6de4151b51879d6c90fca9d/480p/mp4/file.mp4"
          },
          {
            "quality": "720p",
            "width": 1280,
            "height": 720,
            "url": "video/11062b_2ccb88c1c6de4151b51879d6c90fca9d/720p/mp4/file.mp4"
          },
          {
            "quality": "1080p",
            "width": 1920,
            "height": 1080,
            "url": "video/11062b_2ccb88c1c6de4151b51879d6c90fca9d/1080p/mp4/file.mp4"
          }
        ],
        "posterImageRef": {
          "type": "Image",
          "title": "",
          "uri": "11062b_2ccb88c1c6de4151b51879d6c90fca9df000.jpg",
          "description": "search/public/sky/8e256233-1752-4026-9341-54036e7f875e/09309800-7aa0-4352-8f8a-101028e04bf1/all/any/all",
          "width": 1920,
          "height": 1080
        },
        "opacity": 1,
        "duration": 1.2,
        "loop": true,
        "autoplay": true,
        "preload": "auto",
        "mute": true,
        "artist": {
          "id": "5139200731",
          "name": "come alive"
        },
        "generatedPosters": [
          "11062b_2ccb88c1c6de4151b51879d6c90fca9df000.jpg",
          "11062b_2ccb88c1c6de4151b51879d6c90fca9df001.jpg",
          "11062b_2ccb88c1c6de4151b51879d6c90fca9df002.jpg",
          "11062b_2ccb88c1c6de4151b51879d6c90fca9df003.jpg"
        ],
        "hasAudio": false,
        "fps": "30/1",
        "adaptiveVideo": [
          {
            "format": "hls",
            "url": "files/video/11062b_2ccb88c1c6de4151b51879d6c90fca9d/repackage/hls"
          }
        ]
      },
      "color": "#6EB0CC",
      "alignType": "center",
      "fittingType": "fill",
      "scrollType": "fixed",
      "colorOverlay": "",
      "colorOverlayOpacity": 0,
      "imageOverlay": ""
    }
  },
  {
    "presetIndex": 12,
    "path": "public/6ae967c404004038b5e84dd0a6ab2b65/7984c5c1d764454da064e0f1bfe82e90",
    "ref": {
      "type": "BackgroundMedia",
      "mediaRef": {
        "type": "Image",
        "title": "Surfers",
        "uri": "ee4444bd8a2748188a2c3b2ffaeabe8c.jpg",
        "description": "search/public/surf/6ae967c404004038b5e84dd0a6ab2b65/7984c5c1d764454da064e0f1bfe82e90/all/any/all",
        "width": 3401,
        "height": 2242,
        "artist": {
          "id": "",
          "name": ""
        }
      },
      "color": "#095870",
      "alignType": "center",
      "fittingType": "fill",
      "scrollType": "fixed",
      "colorOverlay": "",
      "colorOverlayOpacity": 0,
      "imageOverlay": ""
    }
  },
  {
    "presetIndex": 13,
    "path": "public/8e256233-1752-4026-9341-54036e7f875e/09309800-7aa0-4352-8f8a-101028e04bf1",
    "ref": {
      "type": "BackgroundMedia",
      "mediaRef": {
        "type": "WixVideo",
        "title": "Ocean",
        "videoId": "11062b_95d34f15761e4114a57b17823228948e",
        "qualities": [
          {
            "quality": "480p",
            "width": 854,
            "height": 480,
            "url": "video/11062b_95d34f15761e4114a57b17823228948e/480p/mp4/file.mp4"
          },
          {
            "quality": "720p",
            "width": 1280,
            "height": 720,
            "url": "video/11062b_95d34f15761e4114a57b17823228948e/720p/mp4/file.mp4"
          },
          {
            "quality": "1080p",
            "width": 1920,
            "height": 1080,
            "url": "video/11062b_95d34f15761e4114a57b17823228948e/1080p/mp4/file.mp4"
          },
          {
            "quality": "storyboard",
            "width": 256,
            "height": 144,
            "url": "video/11062b_95d34f15761e4114a57b17823228948e/storyboard/144p/mp4/file.mp4"
          }
        ],
        "posterImageRef": {
          "type": "Image",
          "title": "",
          "uri": "11062b_95d34f15761e4114a57b17823228948ef000.jpg",
          "description": "search/public/water/8e256233-1752-4026-9341-54036e7f875e/d6b6b972-952c-4ab0-b46a-a6c05a0a94a5/all/any/all",
          "width": 1920,
          "height": 1080
        },
        "opacity": 1,
        "duration": 13,
        "loop": true,
        "autoplay": true,
        "preload": "auto",
        "mute": true,
        "artist": {
          "id": "v17A067_091",
          "name": "dissolve"
        },
        "generatedPosters": [
          "11062b_95d34f15761e4114a57b17823228948ef000.jpg",
          "11062b_95d34f15761e4114a57b17823228948ef001.jpg",
          "11062b_95d34f15761e4114a57b17823228948ef002.jpg",
          "11062b_95d34f15761e4114a57b17823228948ef003.jpg"
        ],
        "hasAudio": false,
        "fps": "25/1",
        "adaptiveVideo": [
          {
            "format": "hls",
            "url": "files/video/11062b_95d34f15761e4114a57b17823228948e/repackage/hls"
          }
        ]
      },
      "color": "{color_11}",
      "alignType": "center",
      "fittingType": "fill",
      "scrollType": "fixed",
      "colorOverlay": "",
      "colorOverlayOpacity": 0,
      "imageOverlay": ""
    }
  },
  {
    "presetIndex": 14,
    "path": "public/6ae967c404004038b5e84dd0a6ab2b65/1cf673459a4f4a2bbb20ffcbb7cecbee",
    "ref": {
      "type": "BackgroundMedia",
      "mediaRef": {
        "type": "Image",
        "title": "Reading a Book",
        "uri": "11062b_e5ddec8114ee4169a971326f54144f2c~mv2.jpg",
        "description": "search/public/reading/6ae967c404004038b5e84dd0a6ab2b65/73d89c137a524437880b113939ca24c3/all/any/all",
        "width": 3840,
        "height": 2190,
        "artist": {
          "id": "",
          "name": ""
        }
      },
      "color": "{color_32}",
      "alignType": "center",
      "fittingType": "fill",
      "scrollType": "fixed",
      "colorOverlay": "",
      "colorOverlayOpacity": 0,
      "imageOverlay": ""
    }
  },
  {
    "presetIndex": 15,
    "path": "public/6ae967c404004038b5e84dd0a6ab2b65/38cf7446110341aba0f1aa056078f75b",
    "ref": {
      "type": "BackgroundMedia",
      "mediaRef": {
        "type": "Image",
        "title": "Healthy Green Smoothies",
        "uri": "b4aada852d3247f6a733b08786e4db14.jpg",
        "description": "search/public/juice/6ae967c404004038b5e84dd0a6ab2b65/38cf7446110341aba0f1aa056078f75b/all/any/all",
        "width": 5616,
        "height": 3744,
        "artist": {
          "id": "",
          "name": ""
        },
        "opacity": 1
      },
      "color": "#8ADBAB",
      "alignType": "center",
      "fittingType": "fill",
      "scrollType": "fixed",
      "colorOverlay": "",
      "colorOverlayOpacity": 0,
      "imageOverlay": ""
    }
  },
  {
    "presetIndex": 16,
    "path": "public/6ae967c404004038b5e84dd0a6ab2b65/38cf7446110341aba0f1aa056078f75b",
    "ref": {
      "type": "BackgroundMedia",
      "mediaRef": {
        "type": "Image",
        "title": "Mellow_Yellow_Top_Table-498.jpg",
        "uri": "a3c153_c2677d5a52264f1fa279ac038c6c1c7b~mv2.jpg",
        "description": "private",
        "width": 3233,
        "height": 1812,
        "artist": {
          "id": "",
          "name": ""
        },
        "opacity": 1
      },
      "color": "#BDD1BB",
      "alignType": "center",
      "fittingType": "fill",
      "scrollType": "fixed",
      "colorOverlay": "",
      "colorOverlayOpacity": 0,
      "imageOverlay": ""
    }
  },
  {
    "presetIndex": 17,
    "path": "public/cf60a933270949719f109d5e5e7d1b7c/7d104a612507427a8894924af01cbdef",
    "ref": {
      "type": "BackgroundMedia",
      "mediaRef": {
        "type": "Image",
        "title": "Green Theme",
        "uri": "11062b_763ed69e6ad84797995ee2b63f68dc68~mv2.png",
        "description": "search/public/green/cf60a933270949719f109d5e5e7d1b7c/7d104a612507427a8894924af01cbdef/all/any/all",
        "width": 2560,
        "height": 1600,
        "artist": {
          "id": "",
          "name": ""
        },
        "opacity": 1
      },
      "color": "#AABD9F",
      "alignType": "center",
      "fittingType": "fill",
      "scrollType": "fixed",
      "colorOverlay": "",
      "colorOverlayOpacity": 0,
      "imageOverlay": ""
    }
  },
  {
    "presetIndex": 18,
    "path": "public/6ae967c404004038b5e84dd0a6ab2b65/e841b416588f4d6ba3006b7b7f3ae0f4",
    "ref": {
      "type": "BackgroundMedia",
      "mediaRef": {
        "type": "Image",
        "title": "Beautiful Landscape",
        "uri": "11062b_24693744a2114dd68e6808179cf6a1ca~mv2.jpg",
        "description": "search/all/beautiful landscape",
        "width": 3907,
        "height": 2762,
        "artist": {
          "id": "",
          "name": ""
        },
        "opacity": 1
      },
      "color": "#7A8F56",
      "alignType": "center",
      "fittingType": "fill",
      "scrollType": "fixed",
      "colorOverlay": "",
      "colorOverlayOpacity": 0,
      "imageOverlay": ""
    }
  },
  {
    "presetIndex": 19,
    "path": "public/6ae967c404004038b5e84dd0a6ab2b65/1cf673459a4f4a2bbb20ffcbb7cecbee",
    "ref": {
      "type": "BackgroundMedia",
      "mediaRef": {
        "type": "Image",
        "title": "Bar Chart",
        "uri": "11062b_c7e84b1693cf44ca9005102adf9c07a7~mv2.jpg",
        "description": "search/public/graph/6ae967c404004038b5e84dd0a6ab2b65/73d89c137a524437880b113939ca24c3/all/any/all",
        "width": 6720,
        "height": 4480,
        "artist": {
          "id": "",
          "name": ""
        }
      },
      "color": "{color_13}",
      "alignType": "center",
      "fittingType": "fill",
      "scrollType": "fixed",
      "colorOverlay": "",
      "colorOverlayOpacity": 0,
      "imageOverlay": ""
    }
  },
  {
    "presetIndex": 20,
    "path": "public/8e256233-1752-4026-9341-54036e7f875e/09309800-7aa0-4352-8f8a-101028e04bf1",
    "ref": {
      "type": "BackgroundMedia",
      "mediaRef": {
        "type": "WixVideo",
        "title": "Man on the Beach",
        "videoId": "11062b_b4af42a6e7a644f787675fa96c59b233",
        "qualities": [
          {
            "quality": "480p",
            "width": 854,
            "height": 480,
            "url": "video/11062b_b4af42a6e7a644f787675fa96c59b233/480p/mp4/file.mp4"
          },
          {
            "quality": "720p",
            "width": 1280,
            "height": 720,
            "url": "video/11062b_b4af42a6e7a644f787675fa96c59b233/720p/mp4/file.mp4"
          },
          {
            "quality": "1080p",
            "width": 1920,
            "height": 1080,
            "url": "video/11062b_b4af42a6e7a644f787675fa96c59b233/1080p/mp4/file.mp4"
          }
        ],
        "posterImageRef": {
          "type": "Image",
          "title": "",
          "uri": "11062b_b4af42a6e7a644f787675fa96c59b233f000.jpg",
          "description": "public/8e256233-1752-4026-9341-54036e7f875e/09309800-7aa0-4352-8f8a-101028e04bf1/all/any/all",
          "width": 1920,
          "height": 1080
        },
        "opacity": 1,
        "duration": 19.81,
        "loop": true,
        "autoplay": true,
        "preload": "auto",
        "mute": true,
        "artist": {
          "id": "",
          "name": ""
        },
        "generatedPosters": [
          "11062b_b4af42a6e7a644f787675fa96c59b233f000.jpg",
          "11062b_b4af42a6e7a644f787675fa96c59b233f001.jpg",
          "11062b_b4af42a6e7a644f787675fa96c59b233f002.jpg",
          "11062b_b4af42a6e7a644f787675fa96c59b233f003.jpg"
        ],
        "hasAudio": false,
        "fps": "1199/50",
        "adaptiveVideo": [
          {
            "format": "hls",
            "url": "files/video/11062b_b4af42a6e7a644f787675fa96c59b233/repackage/hls"
          }
        ]
      },
      "color": "{color_13}",
      "alignType": "center",
      "fittingType": "fill",
      "scrollType": "fixed",
      "colorOverlay": "",
      "colorOverlayOpacity": 0,
      "imageOverlay": ""
    }
  },
  {
    "presetIndex": 21,
    "path": "public/6ae967c404004038b5e84dd0a6ab2b65/e841b416588f4d6ba3006b7b7f3ae0f4",
    "ref": {
      "type": "BackgroundMedia",
      "mediaRef": {
        "type": "Image",
        "title": "Orchid",
        "uri": "8bee7cd5049a4893952d4416f8e9601f.jpg",
        "description": "search/public/orchid/6ae967c404004038b5e84dd0a6ab2b65/e841b416588f4d6ba3006b7b7f3ae0f4/all/any/all",
        "width": 1920,
        "height": 1200,
        "artist": {
          "id": "",
          "name": ""
        },
        "opacity": 1
      },
      "color": "#B0C2BE",
      "alignType": "center",
      "fittingType": "fill",
      "scrollType": "fixed",
      "colorOverlay": "",
      "colorOverlayOpacity": 0,
      "imageOverlay": ""
    }
  },
  {
    "presetIndex": 22,
    "path": "public/6ae967c404004038b5e84dd0a6ab2b65/1cf673459a4f4a2bbb20ffcbb7cecbee",
    "ref": {
      "type": "BackgroundMedia",
      "mediaRef": {
        "type": "Image",
        "title": "Beach Banquet",
        "uri": "aa108816bdf14bbabd474eb74c7c20f5.jpg",
        "description": "search/public/banquet/6ae967c404004038b5e84dd0a6ab2b65/1cf673459a4f4a2bbb20ffcbb7cecbee/all/any/all",
        "width": 1920,
        "height": 1280,
        "artist": {
          "id": "",
          "name": ""
        }
      },
      "color": "#D8CEE0",
      "alignType": "center",
      "fittingType": "fill",
      "scrollType": "fixed",
      "colorOverlay": "",
      "colorOverlayOpacity": 0,
      "imageOverlay": ""
    }
  },
  {
    "presetIndex": 23,
    "path": "public/6ae967c404004038b5e84dd0a6ab2b65/38cf7446110341aba0f1aa056078f75b",
    "ref": {
      "type": "BackgroundMedia",
      "mediaRef": {
        "type": "Image",
        "title": "Cookie Box",
        "uri": "4d6a346600d947888d4403ed7ae7f1fd.jpg",
        "description": "search/public/cookies/6ae967c404004038b5e84dd0a6ab2b65/38cf7446110341aba0f1aa056078f75b/all/any/all",
        "width": 2540,
        "height": 1400,
        "artist": {
          "id": "",
          "name": ""
        },
        "opacity": 1
      },
      "color": "#BEC0E6",
      "alignType": "center",
      "fittingType": "fill",
      "scrollType": "fixed",
      "colorOverlay": "",
      "colorOverlayOpacity": 0,
      "imageOverlay": ""
    }
  },
  {
    "presetIndex": 24,
    "path": "public/6ae967c404004038b5e84dd0a6ab2b65/1cf673459a4f4a2bbb20ffcbb7cecbee",
    "ref": {
      "type": "BackgroundMedia",
      "mediaRef": {
        "type": "Image",
        "title": "Work Desk",
        "uri": "11062b_f68602886d71455a85a9705d586b75a0~mv2.jpg",
        "description": "search/public/work desk/6ae967c404004038b5e84dd0a6ab2b65/73d89c137a524437880b113939ca24c3/all/any/all",
        "width": 3000,
        "height": 2001,
        "artist": {
          "id": "",
          "name": ""
        },
        "opacity": 1
      },
      "color": "#998FC2",
      "alignType": "center",
      "fittingType": "fill",
      "scrollType": "fixed",
      "colorOverlay": "",
      "colorOverlayOpacity": 0,
      "imageOverlay": ""
    }
  },
  {
    "presetIndex": 25,
    "path": "public/6ae967c404004038b5e84dd0a6ab2b65/38cf7446110341aba0f1aa056078f75b",
    "ref": {
      "type": "BackgroundMedia",
      "mediaRef": {
        "type": "Image",
        "title": "Fish",
        "uri": "11062b_6365cc0faa0b41cebea6e95c5a98ed90~mv2.jpg",
        "description": "search/public/fish/6ae967c404004038b5e84dd0a6ab2b65/73d89c137a524437880b113939ca24c3/all/any/all",
        "width": 2885,
        "height": 1915,
        "artist": {
          "id": "",
          "name": ""
        }
      },
      "color": "#CFC7F2",
      "alignType": "center",
      "fittingType": "fill",
      "scrollType": "fixed",
      "colorOverlay": "",
      "colorOverlayOpacity": 0,
      "imageOverlay": ""
    }
  },
  {
    "presetIndex": 26,
    "path": "public/6ae967c404004038b5e84dd0a6ab2b65/1cf673459a4f4a2bbb20ffcbb7cecbee",
    "ref": {
      "type": "BackgroundMedia",
      "mediaRef": {
        "type": "Image",
        "title": "Wave",
        "uri": "11062b_8b22b23092f6424dbc6b99c880e11042~mv2.jpg",
        "description": "search/public/paper/6ae967c404004038b5e84dd0a6ab2b65/21f7305f3fc64cdb9939310fef310b64/all/any/all",
        "width": 6720,
        "height": 4480,
        "artist": {
          "id": "",
          "name": ""
        },
        "opacity": 1
      },
      "color": "#CFD6E8",
      "alignType": "center",
      "fittingType": "fill",
      "scrollType": "fixed",
      "colorOverlay": "",
      "colorOverlayOpacity": 0,
      "imageOverlay": ""
    }
  },
  {
    "presetIndex": 27,
    "path": "public/2f8ef58371ed4cc6bd84cd527d9b391f/4e5a9b3e6a324aa7b5d99c40d1162b9c",
    "ref": {
      "type": "BackgroundMedia",
      "mediaRef": {
        "type": "Image",
        "title": "Marble Surface",
        "uri": "bc3ec7bc825c4e6ca746c659189cea83.jpg",
        "description": "search/public/marble/2f8ef58371ed4cc6bd84cd527d9b391f/f2e48208609f44e19e391c976575c4a3/all/any/all",
        "width": 1920,
        "height": 1080,
        "artist": {
          "id": "",
          "name": ""
        }
      },
      "color": "#E6E9EB",
      "alignType": "center",
      "fittingType": "fill",
      "scrollType": "fixed",
      "colorOverlay": "",
      "colorOverlayOpacity": 0,
      "imageOverlay": ""
    }
  },
  {
    "presetIndex": 28,
    "path": "public/6ae967c404004038b5e84dd0a6ab2b65/eaee5f5665214772aa5e183cd9956250",
    "ref": {
      "type": "BackgroundMedia",
      "mediaRef": {
        "type": "Image",
        "title": "Athlete-crouched",
        "uri": "54ba8a7078c244cd96110e8616f5cf41.jpg",
        "description": "search/public/athlete/6ae967c404004038b5e84dd0a6ab2b65/eaee5f5665214772aa5e183cd9956250/all/any/all",
        "width": 5299,
        "height": 3017,
        "artist": {
          "id": "",
          "name": ""
        },
        "opacity": 1
      },
      "color": "#DEC8D1",
      "alignType": "center",
      "fittingType": "fill",
      "scrollType": "fixed",
      "colorOverlay": "",
      "colorOverlayOpacity": 0,
      "imageOverlay": ""
    }
  },
  {
    "presetIndex": 29,
    "path": "public/6ae967c404004038b5e84dd0a6ab2b65/e841b416588f4d6ba3006b7b7f3ae0f4",
    "ref": {
      "type": "BackgroundMedia",
      "mediaRef": {
        "type": "Image",
        "title": "Cloudy Fog",
        "uri": "d8b91ec474404d93a7be5aefff9abd54.jpg",
        "description": "search/public/fog/6ae967c404004038b5e84dd0a6ab2b65/73d89c137a524437880b113939ca24c3/all/any/all",
        "width": 4928,
        "height": 3264,
        "artist": {
          "id": "",
          "name": ""
        },
        "opacity": 1
      },
      "color": "#F0EDEE",
      "alignType": "center",
      "fittingType": "fill",
      "scrollType": "fixed",
      "colorOverlay": "",
      "colorOverlayOpacity": 0,
      "imageOverlay": ""
    }
  },
  {
    "presetIndex": 30,
    "path": "public/8e256233-1752-4026-9341-54036e7f875e/f40994db-1000-49cb-96a5-373a09496c8f",
    "ref": {
      "type": "BackgroundMedia",
      "mediaRef": {
        "type": "WixVideo",
        "title": "Gentle Breeze Cinemagraph",
        "videoId": "11062b_b846191ac8a3430b90ad130922fee9f0",
        "qualities": [
          {
            "quality": "480p",
            "width": 854,
            "height": 480,
            "url": "video/11062b_b846191ac8a3430b90ad130922fee9f0/480p/mp4/file.mp4"
          },
          {
            "quality": "720p",
            "width": 1280,
            "height": 720,
            "url": "video/11062b_b846191ac8a3430b90ad130922fee9f0/720p/mp4/file.mp4"
          },
          {
            "quality": "1080p",
            "width": 1920,
            "height": 1080,
            "url": "video/11062b_b846191ac8a3430b90ad130922fee9f0/1080p/mp4/file.mp4"
          }
        ],
        "posterImageRef": {
          "type": "Image",
          "title": "",
          "uri": "11062b_b846191ac8a3430b90ad130922fee9f0f000.jpg",
          "description": "public/8e256233-1752-4026-9341-54036e7f875e/f40994db-1000-49cb-96a5-373a09496c8f/all/any/all",
          "width": 1920,
          "height": 1080
        },
        "opacity": 1,
        "duration": 14.68,
        "loop": true,
        "autoplay": true,
        "preload": "auto",
        "mute": true,
        "artist": {
          "id": "",
          "name": ""
        },
        "generatedPosters": [
          "11062b_b846191ac8a3430b90ad130922fee9f0f000.jpg",
          "11062b_b846191ac8a3430b90ad130922fee9f0f001.jpg",
          "11062b_b846191ac8a3430b90ad130922fee9f0f002.jpg",
          "11062b_b846191ac8a3430b90ad130922fee9f0f003.jpg"
        ],
        "hasAudio": false,
        "fps": "25/1",
        "adaptiveVideo": [
          {
            "format": "hls",
            "url": "files/video/11062b_b846191ac8a3430b90ad130922fee9f0/repackage/hls"
          }
        ]
      },
      "color": "#F0EDEE",
      "alignType": "center",
      "fittingType": "fill",
      "scrollType": "fixed",
      "colorOverlay": "",
      "colorOverlayOpacity": 0,
      "imageOverlay": ""
    }
  },
  {
    "presetIndex": 31,
    "path": "public/6ae967c404004038b5e84dd0a6ab2b65/7e9232fc633740e497997bff2ffc6bdc",
    "ref": {
      "type": "BackgroundMedia",
      "mediaRef": {
        "type": "Image",
        "title": "White Structure",
        "uri": "ad7b01298cb542c1bfcf925581b4755a.jpg",
        "description": "search/public/architecture/6ae967c404004038b5e84dd0a6ab2b65/73d89c137a524437880b113939ca24c3/all/any/all",
        "width": 5064,
        "height": 3376,
        "artist": {
          "id": "",
          "name": ""
        },
        "opacity": 1
      },
      "color": "#E9E6EB",
      "alignType": "center",
      "fittingType": "fill",
      "scrollType": "fixed",
      "colorOverlay": "",
      "colorOverlayOpacity": 0,
      "imageOverlay": ""
    }
  },
  {
    "presetIndex": 32,
    "path": "public/6ae967c404004038b5e84dd0a6ab2b65/60203b12a89f48c397e7aa694220dc32",
    "ref": {
      "type": "BackgroundMedia",
      "mediaRef": {
        "type": "Image",
        "title": "Back Massage",
        "uri": "7c35130c2ca54f4a8d1b727cb01e3079.jpg",
        "description": "search/public/massage/6ae967c404004038b5e84dd0a6ab2b65/60203b12a89f48c397e7aa694220dc32/all/any/all",
        "width": 7040,
        "height": 3733,
        "artist": {
          "id": "",
          "name": ""
        },
        "opacity": 1
      },
      "color": "#F2EAE4",
      "alignType": "center",
      "fittingType": "fill",
      "scrollType": "fixed",
      "colorOverlay": "",
      "colorOverlayOpacity": 0,
      "imageOverlay": ""
    }
  },
  {
    "presetIndex": 33,
    "path": "public/6ae967c404004038b5e84dd0a6ab2b65/21f7305f3fc64cdb9939310fef310b64",
    "ref": {
      "type": "BackgroundMedia",
      "mediaRef": {
        "type": "Image",
        "title": "Pink Gradient",
        "uri": "56a0809e4c97404291a848762fdd6e58.jpg",
        "description": "search/public/pink gradiant/6ae967c404004038b5e84dd0a6ab2b65/73d89c137a524437880b113939ca24c3/all/any/all",
        "width": 2000,
        "height": 2000,
        "artist": {
          "id": "",
          "name": ""
        }
      },
      "color": "#EDDFE4",
      "alignType": "center",
      "fittingType": "fill",
      "scrollType": "fixed",
      "colorOverlay": "",
      "colorOverlayOpacity": 0,
      "imageOverlay": ""
    }
  },
  {
    "presetIndex": 34,
    "path": "public/cf60a933270949719f109d5e5e7d1b7c/7d104a612507427a8894924af01cbdef",
    "ref": {
      "type": "BackgroundMedia",
      "mediaRef": {
        "type": "Image",
        "title": "Illustrated White Cats",
        "uri": "2049f7f4ffdc48609efde296172737d8.jpg",
        "description": "search/public/cat/0fe8fef2d044427d8acb157eca90e6e8/7431644314724266afbf10b9e771ebb9/all/any/all",
        "width": 3500,
        "height": 1968,
        "artist": {
          "id": "",
          "name": ""
        },
        "opacity": 1
      },
      "color": "#FACDCD",
      "alignType": "center",
      "fittingType": "fill",
      "scrollType": "fixed",
      "colorOverlay": "",
      "colorOverlayOpacity": 0,
      "imageOverlay": ""
    }
  },
  {
    "presetIndex": 35,
    "path": "public/8e256233-1752-4026-9341-54036e7f875e/09309800-7aa0-4352-8f8a-101028e04bf1",
    "ref": {
      "type": "BackgroundMedia",
      "mediaRef": {
        "type": "WixVideo",
        "title": "trees video.mp4",
        "videoId": "a3c153_d2b67332de5a4639a0aa56cc9ddab7c0",
        "qualities": [
          {
            "quality": "1080p",
            "width": 1920,
            "height": 1012,
            "url": "video/a3c153_d2b67332de5a4639a0aa56cc9ddab7c0/1080p/mp4/file.mp4"
          },
          {
            "quality": "720p",
            "width": 1280,
            "height": 676,
            "url": "video/a3c153_d2b67332de5a4639a0aa56cc9ddab7c0/720p/mp4/file.mp4"
          },
          {
            "quality": "480p",
            "width": 854,
            "height": 450,
            "url": "video/a3c153_d2b67332de5a4639a0aa56cc9ddab7c0/480p/mp4/file.mp4"
          },
          {
            "quality": "storyboard",
            "width": 256,
            "height": 136,
            "url": "video/a3c153_d2b67332de5a4639a0aa56cc9ddab7c0/storyboard/144p/mp4/file.mp4"
          }
        ],
        "posterImageRef": {
          "type": "Image",
          "title": "",
          "uri": "a3c153_d2b67332de5a4639a0aa56cc9ddab7c0f000.jpg",
          "description": "private",
          "width": 4096,
          "height": 2160
        },
        "opacity": 1,
        "duration": 16.82,
        "loop": true,
        "autoplay": true,
        "preload": "auto",
        "mute": true,
        "artist": {
          "id": "",
          "name": ""
        },
        "generatedPosters": [
          "a3c153_d2b67332de5a4639a0aa56cc9ddab7c0f000.jpg",
          "a3c153_d2b67332de5a4639a0aa56cc9ddab7c0f001.jpg",
          "a3c153_d2b67332de5a4639a0aa56cc9ddab7c0f002.jpg",
          "a3c153_d2b67332de5a4639a0aa56cc9ddab7c0f003.jpg"
        ],
        "hasAudio": true,
        "fps": "30000/1001",
        "adaptiveVideo": [
          {
            "format": "hls",
            "url": "site/media/video/a3c153_d2b67332de5a4639a0aa56cc9ddab7c0/6f204de4-a031-47a9-8f9b-c59befbcc760/repackage/hls"
          }
        ]
      },
      "color": "#E9E6EB",
      "alignType": "center",
      "fittingType": "fill",
      "scrollType": "fixed",
      "colorOverlay": "",
      "colorOverlayOpacity": 0,
      "imageOverlay": ""
    }
  },
  {
    "presetIndex": 36,
    "path": "public/6ae967c404004038b5e84dd0a6ab2b65/e841b416588f4d6ba3006b7b7f3ae0f4",
    "ref": {
      "type": "BackgroundMedia",
      "mediaRef": {
        "type": "Image",
        "title": "Pink Sand",
        "uri": "a21fba00102e468798c2aeb9f70d06f5.jpg",
        "description": "search/public/pink desert/6ae967c404004038b5e84dd0a6ab2b65/73d89c137a524437880b113939ca24c3/all/any/all",
        "width": 800,
        "height": 800,
        "artist": {
          "id": "",
          "name": ""
        }
      },
      "color": "#E9E6EB",
      "alignType": "center",
      "fittingType": "fill",
      "scrollType": "fixed",
      "colorOverlay": "",
      "colorOverlayOpacity": 0,
      "imageOverlay": ""
    }
  },
  {
    "presetIndex": 37,
    "path": "public/6ae967c404004038b5e84dd0a6ab2b65/38080ccb16884a639306f5173c5053e3",
    "ref": {
      "type": "BackgroundMedia",
      "mediaRef": {
        "type": "Image",
        "title": "Manicured Hands",
        "uri": "f79ec45469f24ea9baa0ce462117132b.jpg",
        "description": "search/public/hand/6ae967c404004038b5e84dd0a6ab2b65/38080ccb16884a639306f5173c5053e3/all/any/all",
        "width": 5344,
        "height": 4008,
        "artist": {
          "id": "",
          "name": ""
        },
        "opacity": 1
      },
      "color": "#FFE4D9",
      "alignType": "center",
      "fittingType": "fill",
      "scrollType": "fixed",
      "colorOverlay": "",
      "colorOverlayOpacity": 0,
      "imageOverlay": ""
    }
  },
  {
    "presetIndex": 38,
    "path": "public/6ae967c404004038b5e84dd0a6ab2b65/f8452285-cdeb-48cd-9b78-e6ec390032ff",
    "ref": {
      "type": "BackgroundMedia",
      "mediaRef": {
        "type": "Image",
        "title": "Square Stage",
        "uri": "11062b_dbb18569e65f4149abb7bb60eb0656e0~mv2_d_3840_2400_s_4_2.jpg",
        "description": "public/6ae967c404004038b5e84dd0a6ab2b65/f8452285-cdeb-48cd-9b78-e6ec390032ff/all/any/all",
        "width": 3840,
        "height": 2400,
        "artist": {
          "id": "",
          "name": ""
        }
      },
      "color": "#FFE4D9",
      "alignType": "center",
      "fittingType": "fill",
      "scrollType": "fixed",
      "colorOverlay": "",
      "colorOverlayOpacity": 0,
      "imageOverlay": ""
    }
  },
  {
    "presetIndex": 39,
    "path": "public/6ae967c404004038b5e84dd0a6ab2b65/60203b12a89f48c397e7aa694220dc32",
    "ref": {
      "type": "BackgroundMedia",
      "mediaRef": {
        "type": "Image",
        "title": "Woman with Hand on Face",
        "uri": "7fe6b83b5b1d49b39282cb977d925463.jpg",
        "description": "search/public/face/6ae967c404004038b5e84dd0a6ab2b65/60203b12a89f48c397e7aa694220dc32/all/any/all",
        "width": 5142,
        "height": 3428,
        "artist": {
          "id": "",
          "name": ""
        }
      },
      "color": "#F2DCD3",
      "alignType": "center",
      "fittingType": "fill",
      "scrollType": "fixed",
      "colorOverlay": "",
      "colorOverlayOpacity": 0,
      "imageOverlay": ""
    }
  },
  {
    "presetIndex": 40,
    "path": "public/6ae967c404004038b5e84dd0a6ab2b65/eaee5f5665214772aa5e183cd9956250",
    "ref": {
      "type": "BackgroundMedia",
      "mediaRef": {
        "type": "Image",
        "title": "Man Running",
        "uri": "c5307b2c35814378aab6c5f0401a3617.jpg",
        "description": "search/public/running/6ae967c404004038b5e84dd0a6ab2b65/73d89c137a524437880b113939ca24c3/all/any/all",
        "width": 1920,
        "height": 1280,
        "artist": {
          "id": "",
          "name": ""
        }
      },
      "color": "#FFF38C",
      "alignType": "center",
      "fittingType": "fill",
      "scrollType": "fixed",
      "colorOverlay": "",
      "colorOverlayOpacity": 0,
      "imageOverlay": ""
    }
  },
  {
    "presetIndex": 41,
    "path": "public/6ae967c404004038b5e84dd0a6ab2b65/38cf7446110341aba0f1aa056078f75b",
    "ref": {
      "type": "BackgroundMedia",
      "mediaRef": {
        "type": "Image",
        "title": "Lemons",
        "uri": "11062b_e30e30f205bf41119d4ccfe712fb1f7f~mv2.jpg",
        "description": "search/public/lemon/6ae967c404004038b5e84dd0a6ab2b65/73d89c137a524437880b113939ca24c3/all/any/all",
        "width": 6128,
        "height": 3456,
        "artist": {
          "id": "",
          "name": ""
        }
      },
      "color": "#FAD105",
      "alignType": "center",
      "fittingType": "fill",
      "scrollType": "fixed",
      "colorOverlay": "",
      "colorOverlayOpacity": 0,
      "imageOverlay": ""
    }
  },
  {
    "presetIndex": 42,
    "path": "public/6ae967c404004038b5e84dd0a6ab2b65/1cf673459a4f4a2bbb20ffcbb7cecbee",
    "ref": {
      "type": "BackgroundMedia",
      "mediaRef": {
        "type": "Image",
        "title": "Bottles",
        "uri": "11062b_8453616f683b48e9900aa00791b6cc4b~mv2.jpg",
        "description": "search/public/perfume/6ae967c404004038b5e84dd0a6ab2b65/73d89c137a524437880b113939ca24c3/all/any/all",
        "width": 5000,
        "height": 3333,
        "artist": {
          "id": "",
          "name": ""
        },
        "opacity": 1
      },
      "color": "#FABF52",
      "alignType": "center",
      "fittingType": "fill",
      "scrollType": "fixed",
      "colorOverlay": "",
      "colorOverlayOpacity": 0,
      "imageOverlay": ""
    }
  },
  {
    "presetIndex": 43,
    "path": "public/6ae967c404004038b5e84dd0a6ab2b65/38cf7446110341aba0f1aa056078f75b",
    "ref": {
      "type": "BackgroundMedia",
      "mediaRef": {
        "type": "Image",
        "title": "Baking",
        "uri": "11062b_55f5cfb422234b1e927fdf5cb3a97a12~mv2.jpg",
        "description": "search/public/donut/6ae967c404004038b5e84dd0a6ab2b65/73d89c137a524437880b113939ca24c3/all/any/all",
        "width": 6000,
        "height": 4000,
        "artist": {
          "id": "",
          "name": ""
        }
      },
      "color": "#BA6C13",
      "alignType": "center",
      "fittingType": "fill",
      "scrollType": "fixed",
      "colorOverlay": "",
      "colorOverlayOpacity": 0,
      "imageOverlay": ""
    }
  },
  {
    "presetIndex": 44,
    "path": "public/2f8ef58371ed4cc6bd84cd527d9b391f/4e5a9b3e6a324aa7b5d99c40d1162b9c",
    "ref": {
      "type": "BackgroundMedia",
      "mediaRef": {
        "type": "Image",
        "title": "Shadow on Concrete Wall",
        "uri": "11062b_d01cf48782824880a5b8f3229886f9f8~mv2_d_3000_1688_s_2.jpg",
        "description": "search/public/shadow/6ae967c404004038b5e84dd0a6ab2b65/73d89c137a524437880b113939ca24c3/all/any/all",
        "width": 3000,
        "height": 1688,
        "artist": {
          "id": "",
          "name": ""
        }
      },
      "color": "#D16E43",
      "alignType": "center",
      "fittingType": "fill",
      "scrollType": "fixed",
      "colorOverlay": "",
      "colorOverlayOpacity": 0,
      "imageOverlay": ""
    }
  },
  {
    "presetIndex": 45,
    "path": "public/8e256233-1752-4026-9341-54036e7f875e/bfa1c2d0-17a0-425d-9a6b-15cc467f0736",
    "ref": {
      "type": "BackgroundMedia",
      "mediaRef": {
        "type": "WixVideo",
        "title": "Running in Nature",
        "videoId": "11062b_b109368ac4264b688d21b3e361372fb1",
        "qualities": [
          {
            "quality": "480p",
            "width": 854,
            "height": 450,
            "url": "video/11062b_b109368ac4264b688d21b3e361372fb1/480p/mp4/file.mp4"
          },
          {
            "quality": "720p",
            "width": 1280,
            "height": 676,
            "url": "video/11062b_b109368ac4264b688d21b3e361372fb1/720p/mp4/file.mp4"
          },
          {
            "quality": "1080p",
            "width": 1920,
            "height": 1014,
            "url": "video/11062b_b109368ac4264b688d21b3e361372fb1/1080p/mp4/file.mp4"
          }
        ],
        "posterImageRef": {
          "type": "Image",
          "title": "",
          "uri": "11062b_b109368ac4264b688d21b3e361372fb1f000.jpg",
          "description": "search/public/run/8e256233-1752-4026-9341-54036e7f875e/d6b6b972-952c-4ab0-b46a-a6c05a0a94a5/all/any/all",
          "width": 4096,
          "height": 2160
        },
        "opacity": 1,
        "duration": 12.5,
        "loop": true,
        "autoplay": true,
        "preload": "auto",
        "mute": true,
        "artist": {
          "id": "869732776",
          "name": "GettyImages"
        },
        "generatedPosters": [
          "11062b_b109368ac4264b688d21b3e361372fb1f000.jpg",
          "11062b_b109368ac4264b688d21b3e361372fb1f001.jpg",
          "11062b_b109368ac4264b688d21b3e361372fb1f002.jpg",
          "11062b_b109368ac4264b688d21b3e361372fb1f003.jpg"
        ],
        "hasAudio": true,
        "fps": "24/1",
        "adaptiveVideo": [
          {
            "format": "hls",
            "url": "site/media/video/11062b_b109368ac4264b688d21b3e361372fb1/da01a1fc-ad34-44bc-90e8-cc9413c466e6/repackage/hls"
          }
        ]
      },
      "color": "#E9E6EB",
      "alignType": "center",
      "fittingType": "fill",
      "scrollType": "fixed",
      "colorOverlay": "",
      "colorOverlayOpacity": 0,
      "imageOverlay": ""
    }
  },
  {
    "presetIndex": 46,
    "path": "public/6ae967c404004038b5e84dd0a6ab2b65/73d89c137a524437880b113939ca24c3",
    "ref": {
      "type": "BackgroundMedia",
      "mediaRef": {
        "type": "Image",
        "title": "Microphone",
        "uri": "b6bceb24ee73407e8cea9b6c5c8162e7.jpg",
        "description": "search/public/curtain/6ae967c404004038b5e84dd0a6ab2b65/73d89c137a524437880b113939ca24c3/all/any/all",
        "width": 4992,
        "height": 3328,
        "artist": {
          "id": "",
          "name": ""
        },
        "opacity": 1
      },
      "color": "#6E0500",
      "alignType": "center",
      "fittingType": "fill",
      "scrollType": "fixed",
      "colorOverlay": "",
      "colorOverlayOpacity": 0,
      "imageOverlay": ""
    }
  },
  {
    "presetIndex": 47,
    "path": "public/6ae967c404004038b5e84dd0a6ab2b65/73d89c137a524437880b113939ca24c3",
    "ref": {
      "type": "BackgroundMedia",
      "mediaRef": {
        "type": "Image",
        "title": "Red Wall & Stairs",
        "uri": "2f488b5e974349b988e2c688f54e90f5.jpg",
        "description": "search/public/stairs/6ae967c404004038b5e84dd0a6ab2b65/73d89c137a524437880b113939ca24c3/all/any/all",
        "width": 5471,
        "height": 3656,
        "artist": {
          "id": "",
          "name": ""
        },
        "opacity": 1
      },
      "color": "#DB634B",
      "alignType": "center",
      "fittingType": "fill",
      "scrollType": "fixed",
      "colorOverlay": "",
      "colorOverlayOpacity": 0,
      "imageOverlay": ""
    }
  },
  {
    "presetIndex": 48,
    "path": "public/6ae967c404004038b5e84dd0a6ab2b65/1cf673459a4f4a2bbb20ffcbb7cecbee",
    "ref": {
      "type": "BackgroundMedia",
      "mediaRef": {
        "type": "Image",
        "title": "Beauty Products",
        "uri": "11062b_2ad3bd95d87044c4b1fdcfc27d4e5f76~mv2.jpg",
        "description": "search/public/ beauty product/6ae967c404004038b5e84dd0a6ab2b65/1cf673459a4f4a2bbb20ffcbb7cecbee/all/any/all",
        "width": 3212,
        "height": 3794,
        "artist": {
          "id": "",
          "name": ""
        }
      },
      "color": "#F75D3E",
      "alignType": "center",
      "fittingType": "fill",
      "scrollType": "fixed",
      "colorOverlay": "",
      "colorOverlayOpacity": 0,
      "imageOverlay": ""
    }
  },
  {
    "presetIndex": 49,
    "path": "public/6ae967c404004038b5e84dd0a6ab2b65/60203b12a89f48c397e7aa694220dc32",
    "ref": {
      "type": "BackgroundMedia",
      "mediaRef": {
        "type": "Image",
        "title": "Yoga at Home",
        "uri": "29ecd561610d428a8730c5c4c22450ac.jpg",
        "description": "search/public/yoga/6ae967c404004038b5e84dd0a6ab2b65/73d89c137a524437880b113939ca24c3/all/any/all",
        "width": 5448,
        "height": 3632,
        "artist": {
          "id": "",
          "name": ""
        }
      },
      "color": "#F0EDEE",
      "alignType": "center",
      "fittingType": "fill",
      "scrollType": "fixed",
      "colorOverlay": "",
      "colorOverlayOpacity": 0,
      "imageOverlay": ""
    }
  },
  {
    "presetIndex": 50,
    "path": "public/8e256233-1752-4026-9341-54036e7f875e/3892dca7-115f-4b62-ba8d-11a7b220ab76",
    "ref": {
      "type": "BackgroundMedia",
      "mediaRef": {
        "type": "WixVideo",
        "title": "Fabric",
        "videoId": "11062b_62f0356712544a87b392417662c4658d",
        "qualities": [
          {
            "quality": "480p",
            "width": 854,
            "height": 480,
            "url": "video/11062b_62f0356712544a87b392417662c4658d/480p/mp4/file.mp4"
          },
          {
            "quality": "720p",
            "width": 1280,
            "height": 720,
            "url": "video/11062b_62f0356712544a87b392417662c4658d/720p/mp4/file.mp4"
          },
          {
            "quality": "1080p",
            "width": 1920,
            "height": 1080,
            "url": "video/11062b_62f0356712544a87b392417662c4658d/1080p/mp4/file.mp4"
          }
        ],
        "posterImageRef": {
          "type": "Image",
          "title": "",
          "uri": "11062b_62f0356712544a87b392417662c4658df000.jpg",
          "description": "search/public/fabric/8e256233-1752-4026-9341-54036e7f875e/d6b6b972-952c-4ab0-b46a-a6c05a0a94a5/all/any/all",
          "width": 1920,
          "height": 1080
        },
        "opacity": 1,
        "duration": 21.13,
        "loop": true,
        "autoplay": true,
        "preload": "auto",
        "mute": true,
        "artist": {
          "id": "",
          "name": ""
        },
        "generatedPosters": [
          "11062b_62f0356712544a87b392417662c4658df000.jpg",
          "11062b_62f0356712544a87b392417662c4658df001.jpg",
          "11062b_62f0356712544a87b392417662c4658df002.jpg",
          "11062b_62f0356712544a87b392417662c4658df003.jpg"
        ],
        "hasAudio": false,
        "fps": "24/1",
        "adaptiveVideo": [
          {
            "format": "hls",
            "url": "site/media/video/11062b_62f0356712544a87b392417662c4658d/da01a1fc-ad34-44bc-90e8-cc9413c466e6/repackage/hls"
          }
        ]
      },
      "color": "#E9E6EB",
      "alignType": "center",
      "fittingType": "fill",
      "scrollType": "fixed",
      "colorOverlay": "",
      "colorOverlayOpacity": 0,
      "imageOverlay": ""
    }
  },
  {
    "presetIndex": 51,
    "path": "public/8e256233-1752-4026-9341-54036e7f875e/f219085a-ca93-4f05-bc3f-5146ce3e6c8f",
    "ref": {
      "type": "BackgroundMedia",
      "mediaRef": {
        "type": "Image",
        "title": "Pink Living Room",
        "uri": "11062b_9d052ff2528c4ab28ee88eff8d7752c8~mv2_d_5000_3535_s_4_2.jpg",
        "description": "search/public/living room/6ae967c404004038b5e84dd0a6ab2b65/73d89c137a524437880b113939ca24c3/all/any/all",
        "width": 5000,
        "height": 3535,
        "artist": {
          "id": "",
          "name": ""
        },
        "opacity": 1
      },
      "color": "#E8D9CF",
      "alignType": "center",
      "fittingType": "fill",
      "scrollType": "fixed",
      "colorOverlay": "",
      "colorOverlayOpacity": 0,
      "imageOverlay": ""
    }
  },
  {
    "presetIndex": 52,
    "path": "public/6ae967c404004038b5e84dd0a6ab2b65/73d89c137a524437880b113939ca24c3",
    "ref": {
      "type": "BackgroundMedia",
      "mediaRef": {
        "type": "Image",
        "title": "Girl Posing with a Dove",
        "uri": "a6078dae53dc4943b957e037aee775aa.jpg",
        "description": "search/public/dove/6ae967c404004038b5e84dd0a6ab2b65/73d89c137a524437880b113939ca24c3/all/any/all",
        "width": 6144,
        "height": 4096,
        "artist": {
          "id": "",
          "name": ""
        },
        "opacity": 1
      },
      "color": "#FCCFE8",
      "alignType": "center",
      "fittingType": "fill",
      "scrollType": "fixed",
      "colorOverlay": "",
      "colorOverlayOpacity": 0,
      "imageOverlay": ""
    }
  },
  {
    "presetIndex": 53,
    "path": "public/6ae967c404004038b5e84dd0a6ab2b65/38cf7446110341aba0f1aa056078f75b",
    "ref": {
      "type": "BackgroundMedia",
      "mediaRef": {
        "type": "Image",
        "title": "Pots and Pans",
        "uri": "11062b_eeeba94257dc4f0bb061f3a1c277f283~mv2.jpg",
        "description": "search/public/pots/6ae967c404004038b5e84dd0a6ab2b65/73d89c137a524437880b113939ca24c3/all/any/all",
        "width": 3840,
        "height": 4058,
        "artist": {
          "id": "",
          "name": ""
        }
      },
      "color": "#FCCFE8",
      "alignType": "center",
      "fittingType": "fill",
      "scrollType": "fixed",
      "colorOverlay": "",
      "colorOverlayOpacity": 0,
      "imageOverlay": ""
    }
  },
  {
    "presetIndex": 54,
    "path": "public/2f8ef58371ed4cc6bd84cd527d9b391f/4e5a9b3e6a324aa7b5d99c40d1162b9c",
    "ref": {
      "type": "BackgroundMedia",
      "mediaRef": {
        "type": "Image",
        "title": "Gradient",
        "uri": "11062b_4b7c9a8e48334d5aad2fd274fddba3bc~mv2.jpg",
        "description": "search/public/gradient/6ae967c404004038b5e84dd0a6ab2b65/73d89c137a524437880b113939ca24c3/all/any/all",
        "width": 3000,
        "height": 2000,
        "artist": {
          "id": "",
          "name": ""
        }
      },
      "color": "#CFC7F2",
      "alignType": "center",
      "fittingType": "fill",
      "scrollType": "fixed",
      "colorOverlay": "",
      "colorOverlayOpacity": 0,
      "imageOverlay": ""
    }
  },
  {
    "presetIndex": 55,
    "path": "public/2f8ef58371ed4cc6bd84cd527d9b391f/4e5a9b3e6a324aa7b5d99c40d1162b9c",
    "ref": {
      "type": "BackgroundMedia",
      "mediaRef": {
        "type": "Image",
        "title": "Concrete",
        "uri": "a8e1f3e15ccb41b88df85a10bb90531a.jpg",
        "description": "search/public/concrete/2f8ef58371ed4cc6bd84cd527d9b391f/f2e48208609f44e19e391c976575c4a3/all/any/all",
        "width": 2920,
        "height": 2480,
        "artist": {
          "id": "",
          "name": ""
        }
      },
      "color": "{color_14}",
      "alignType": "center",
      "fittingType": "fill",
      "scrollType": "fixed",
      "colorOverlay": "",
      "colorOverlayOpacity": 0,
      "imageOverlay": ""
    }
  },
  {
    "presetIndex": 56,
    "path": "public/6ae967c404004038b5e84dd0a6ab2b65/e841b416588f4d6ba3006b7b7f3ae0f4",
    "ref": {
      "type": "BackgroundMedia",
      "mediaRef": {
        "type": "Image",
        "title": "Black and White Landscape",
        "uri": "11062b_120607725945446aad6be27159b71f7a~mv2_d_2848_1602_s_2.jpg",
        "description": "search/public/black and white landscape/6ae967c404004038b5e84dd0a6ab2b65/e841b416588f4d6ba3006b7b7f3ae0f4/all/any/all",
        "width": 2848,
        "height": 1602,
        "artist": {
          "id": "",
          "name": ""
        }
      },
      "color": "{color_13}",
      "alignType": "center",
      "fittingType": "fill",
      "scrollType": "fixed",
      "colorOverlay": "",
      "colorOverlayOpacity": 0,
      "imageOverlay": ""
    }
  },
  {
    "presetIndex": 57,
    "path": "public/8e256233-1752-4026-9341-54036e7f875e/09309800-7aa0-4352-8f8a-101028e04bf1",
    "ref": {
      "type": "BackgroundMedia",
      "mediaRef": {
        "type": "WixVideo",
        "title": "Smokey Sky",
        "videoId": "11062b_4d1ce144268d4ffda4eb0e5d39af01d0",
        "qualities": [
          {
            "quality": "480p",
            "width": 854,
            "height": 480,
            "url": "video/11062b_4d1ce144268d4ffda4eb0e5d39af01d0/480p/mp4/file.mp4"
          },
          {
            "quality": "720p",
            "width": 1280,
            "height": 720,
            "url": "video/11062b_4d1ce144268d4ffda4eb0e5d39af01d0/720p/mp4/file.mp4"
          },
          {
            "quality": "1080p",
            "width": 1920,
            "height": 1080,
            "url": "video/11062b_4d1ce144268d4ffda4eb0e5d39af01d0/1080p/mp4/file.mp4"
          }
        ],
        "posterImageRef": {
          "type": "Image",
          "title": "",
          "uri": "11062b_4d1ce144268d4ffda4eb0e5d39af01d0f000.jpg",
          "description": "search/public/cloud/8e256233-1752-4026-9341-54036e7f875e/d6b6b972-952c-4ab0-b46a-a6c05a0a94a5/all/any/all",
          "width": 1920,
          "height": 1080
        },
        "opacity": 1,
        "duration": 19.09,
        "loop": true,
        "autoplay": true,
        "preload": "auto",
        "mute": true,
        "artist": {
          "id": "v343380914",
          "name": "Pexels"
        },
        "generatedPosters": [
          "11062b_4d1ce144268d4ffda4eb0e5d39af01d0f000.jpg",
          "11062b_4d1ce144268d4ffda4eb0e5d39af01d0f001.jpg",
          "11062b_4d1ce144268d4ffda4eb0e5d39af01d0f002.jpg",
          "11062b_4d1ce144268d4ffda4eb0e5d39af01d0f003.jpg"
        ],
        "hasAudio": false,
        "fps": "2997/100",
        "adaptiveVideo": [
          {
            "format": "hls",
            "url": "files/video/11062b_4d1ce144268d4ffda4eb0e5d39af01d0/repackage/hls"
          }
        ]
      },
      "color": "{color_14}",
      "alignType": "center",
      "fittingType": "fill",
      "scrollType": "fixed",
      "colorOverlay": "",
      "colorOverlayOpacity": 0,
      "imageOverlay": ""
    }
  },
  {
    "presetIndex": 58,
    "path": "public/6ae967c404004038b5e84dd0a6ab2b65/7e9232fc633740e497997bff2ffc6bdc",
    "ref": {
      "type": "BackgroundMedia",
      "mediaRef": {
        "type": "Image",
        "title": "NYC Skyline BW",
        "uri": "ea66f0de45ec4109b5eb190bed2244d1.jpg",
        "description": "search/all/nyc",
        "width": 1980,
        "height": 1200,
        "artist": {
          "id": "",
          "name": ""
        }
      },
      "color": "{color_12}",
      "alignType": "center",
      "fittingType": "fill",
      "scrollType": "fixed",
      "colorOverlay": "",
      "colorOverlayOpacity": 0,
      "imageOverlay": ""
    }
  },
  {
    "presetIndex": 59,
    "path": "public/6ae967c404004038b5e84dd0a6ab2b65/7e9232fc633740e497997bff2ffc6bdc",
    "ref": {
      "type": "BackgroundMedia",
      "mediaRef": {
        "type": "Image",
        "title": "Concrete Building",
        "uri": "11062b_1d0bcc469def47d2aa6a11ac417208db~mv2_d_2256_1269_s_2.jpg",
        "description": "search/public/building/6ae967c404004038b5e84dd0a6ab2b65/73d89c137a524437880b113939ca24c3/all/any/all",
        "width": 2256,
        "height": 1269,
        "artist": {
          "id": "",
          "name": ""
        }
      },
      "color": "{color_13}",
      "alignType": "center",
      "fittingType": "fill",
      "scrollType": "fixed",
      "colorOverlay": "",
      "colorOverlayOpacity": 0,
      "imageOverlay": ""
    }
  },
  {
    "presetIndex": 60,
    "path": "public/6ae967c404004038b5e84dd0a6ab2b65/221ab911de9e410db3a4facf47245726",
    "ref": {
      "type": "BackgroundMedia",
      "mediaRef": {
        "type": "Image",
        "title": "Musician in the Desert",
        "uri": "7b6a1238d58c4ab785f6710ce30c8778.jpg",
        "description": "search/public/guitar/6ae967c404004038b5e84dd0a6ab2b65/221ab911de9e410db3a4facf47245726/all/any/all",
        "width": 980,
        "height": 446,
        "artist": {
          "id": "",
          "name": ""
        }
      },
      "color": "{color_12}",
      "alignType": "center",
      "fittingType": "fill",
      "scrollType": "fixed",
      "colorOverlay": "",
      "colorOverlayOpacity": 0,
      "imageOverlay": ""
    }
  },
  {
    "presetIndex": 61,
    "path": "public/6ae967c404004038b5e84dd0a6ab2b65/73d89c137a524437880b113939ca24c3",
    "ref": {
      "type": "BackgroundMedia",
      "mediaRef": {
        "type": "Image",
        "title": "Business Conference",
        "uri": "11062b_b50f8c8952004dad9df84d1f0792efe3~mv2_d_4208_2779_s_4_2.jpg",
        "description": "search/public/office/6ae967c404004038b5e84dd0a6ab2b65/73d89c137a524437880b113939ca24c3/all/any/all",
        "width": 4208,
        "height": 2779,
        "artist": {
          "id": "",
          "name": ""
        }
      },
      "color": "{color_12}",
      "alignType": "center",
      "fittingType": "fill",
      "scrollType": "fixed",
      "colorOverlay": "",
      "colorOverlayOpacity": 0,
      "imageOverlay": ""
    }
  },
  {
    "presetIndex": 62,
    "path": "public/6ae967c404004038b5e84dd0a6ab2b65/1cf673459a4f4a2bbb20ffcbb7cecbee",
    "ref": {
      "type": "BackgroundMedia",
      "mediaRef": {
        "type": "Image",
        "title": "Man with Headphones",
        "uri": "11062b_604ded692d29445eafb087501345229d~mv2.jpg",
        "description": "search/public/headphone/6ae967c404004038b5e84dd0a6ab2b65/1cf673459a4f4a2bbb20ffcbb7cecbee/all/any/all",
        "width": 4000,
        "height": 2667,
        "artist": {
          "id": "",
          "name": ""
        }
      },
      "color": "#DDE8EB",
      "alignType": "center",
      "fittingType": "fill",
      "scrollType": "fixed",
      "colorOverlay": "",
      "colorOverlayOpacity": 0,
      "imageOverlay": ""
    }
  },
  {
    "presetIndex": 63,
    "path": "public/cf60a933270949719f109d5e5e7d1b7c/7d104a612507427a8894924af01cbdef",
    "ref": {
      "type": "BackgroundMedia",
      "mediaRef": {
        "type": "Image",
        "title": "Black and White Cactus",
        "uri": "8f805eafaade4faf8b30b5e49114b685.jpg",
        "description": "search/public/cactus/0fe8fef2d044427d8acb157eca90e6e8/7431644314724266afbf10b9e771ebb9/all/any/all",
        "width": 3500,
        "height": 1968,
        "artist": {
          "id": "",
          "name": ""
        }
      },
      "color": "{color_15}",
      "alignType": "center",
      "fittingType": "fill",
      "scrollType": "fixed",
      "colorOverlay": "",
      "colorOverlayOpacity": 0,
      "imageOverlay": ""
    }
  },
  {
    "presetIndex": 64,
    "path": "public/6ae967c404004038b5e84dd0a6ab2b65/38cf7446110341aba0f1aa056078f75b",
    "ref": {
      "type": "BackgroundMedia",
      "mediaRef": {
        "type": "Image",
        "title": "Glass of Drink",
        "uri": "11062b_f2f55c89832843d79dd3bf5d531fa81d~mv2.jpg",
        "description": "search/public/drink/6ae967c404004038b5e84dd0a6ab2b65/38cf7446110341aba0f1aa056078f75b/all/any/all",
        "width": 2500,
        "height": 1658,
        "artist": {
          "id": "",
          "name": ""
        }
      },
      "color": "#162936",
      "alignType": "center",
      "fittingType": "fill",
      "scrollType": "fixed",
      "colorOverlay": "",
      "colorOverlayOpacity": 0,
      "imageOverlay": ""
    }
  },
  {
    "presetIndex": 65,
    "path": "public/6ae967c404004038b5e84dd0a6ab2b65/eaee5f5665214772aa5e183cd9956250",
    "ref": {
      "type": "BackgroundMedia",
      "mediaRef": {
        "type": "Image",
        "title": "Fitness",
        "uri": "11062b_2746e0e5dec64a059a020a7d8d6636fa~mv2.jpg",
        "description": "search/public/fitness/6ae967c404004038b5e84dd0a6ab2b65/60203b12a89f48c397e7aa694220dc32/all/any/all",
        "width": 3000,
        "height": 2000,
        "artist": {
          "id": "",
          "name": ""
        }
      },
      "color": "#37364A",
      "alignType": "center",
      "fittingType": "fill",
      "scrollType": "fixed",
      "colorOverlay": "",
      "colorOverlayOpacity": 0,
      "imageOverlay": ""
    }
  },
  {
    "presetIndex": 66,
    "path": "public/8e256233-1752-4026-9341-54036e7f875e/3892dca7-115f-4b62-ba8d-11a7b220ab76",
    "ref": {
      "type": "BackgroundMedia",
      "mediaRef": {
        "type": "WixVideo",
        "title": "Abstract Waves",
        "videoId": "11062b_c18db2b1461b46f2ad31bae61009fee1",
        "qualities": [
          {
            "quality": "480p",
            "width": 854,
            "height": 480,
            "url": "video/11062b_c18db2b1461b46f2ad31bae61009fee1/480p/mp4/file.mp4"
          },
          {
            "quality": "720p",
            "width": 1280,
            "height": 720,
            "url": "video/11062b_c18db2b1461b46f2ad31bae61009fee1/720p/mp4/file.mp4"
          },
          {
            "quality": "1080p",
            "width": 1920,
            "height": 1080,
            "url": "video/11062b_c18db2b1461b46f2ad31bae61009fee1/1080p/mp4/file.mp4"
          }
        ],
        "posterImageRef": {
          "type": "Image",
          "title": "",
          "uri": "11062b_c18db2b1461b46f2ad31bae61009fee1f000.jpg",
          "description": "search/public/black/8e256233-1752-4026-9341-54036e7f875e/d6b6b972-952c-4ab0-b46a-a6c05a0a94a5/all/any/all",
          "width": 3840,
          "height": 2160
        },
        "opacity": 1,
        "duration": 10,
        "loop": true,
        "autoplay": true,
        "preload": "auto",
        "mute": true,
        "artist": {
          "id": "1032489418",
          "name": "GettyImages"
        },
        "generatedPosters": [
          "11062b_c18db2b1461b46f2ad31bae61009fee1f000.jpg",
          "11062b_c18db2b1461b46f2ad31bae61009fee1f001.jpg",
          "11062b_c18db2b1461b46f2ad31bae61009fee1f002.jpg",
          "11062b_c18db2b1461b46f2ad31bae61009fee1f003.jpg"
        ],
        "hasAudio": false,
        "fps": "30/1",
        "adaptiveVideo": [
          {
            "format": "hls",
            "url": "site/media/video/11062b_c18db2b1461b46f2ad31bae61009fee1/da01a1fc-ad34-44bc-90e8-cc9413c466e6/repackage/hls"
          }
        ]
      },
      "color": "{color_32}",
      "alignType": "center",
      "fittingType": "fill",
      "scrollType": "fixed",
      "colorOverlay": "",
      "colorOverlayOpacity": 0,
      "imageOverlay": ""
    }
  }
]
