import { WIX_EVENTS } from '@wix/app-definition-ids';
import experiment from 'experiment';

import * as helpIds from '@/helpIds';
import * as tpa from '@/tpa';

import type { MapDispatchToPropsFunction, MapStateToProps } from 'types/redux';
import type {
  EventsWelcomeDispatchProps,
  EventsWelcomeOwnProps,
  EventsWelcomeStateProps,
} from './types';
import {
  EditorType,
  InstallationOriginType,
  InstallInitiator,
} from '@wix/platform-editor-sdk';

export const mapStateToProps: MapStateToProps<
  EventsWelcomeStateProps,
  EventsWelcomeOwnProps
> = ({ editorAPI }) => ({
  welcomeSectionTemplate: editorAPI.addPanelInfra.welcomeSection,
});

const getEditorAPI = (
  dispatch: AnyFixMe,
  getState: AnyFixMe,
  { editorAPI }: AnyFixMe,
) => editorAPI;

export const mapDispatchToProps: MapDispatchToPropsFunction<
  EventsWelcomeDispatchProps,
  EventsWelcomeOwnProps
> = (dispatch) => {
  const editorAPI = dispatch(getEditorAPI);

  return {
    installEventsApp: ({ biParams }) =>
      experiment.isOpen('specs.events.ui.PageAsDefaultComponent')
        ? tpa.superApps.addApp(
            tpa.superApps.superAppsConstants.WIX_EVENTS.APP_DEF_ID,
            {
              biOrigin: tpa.constants.BI.type.ADD_APP_APP_BUTTON,
              platformOrigin: {
                type: EditorType.Classic,
                initiator: InstallInitiator.Editor,
                info: {
                  type: InstallationOriginType.AddPanel,
                },
              },
              callback: (data: AnyFixMe) => {
                const pageId = data?.page?.id;

                if (pageId) {
                  editorAPI.pages.navigateTo(pageId, () => {
                    const sectionPointer =
                      tpa.superApps.getSectionRefByPageId(pageId);
                    editorAPI.selection.selectComponentByCompRef(
                      sectionPointer,
                    );
                  });
                }
              },
            },
          )
        : tpa.superApps.addWidget(
            tpa.superApps.superAppsConstants.WIX_EVENTS.APP_DEF_ID,
            tpa.superApps.superAppsConstants.WIX_EVENTS.WIDGET,
            {},
            {
              biOrigin: tpa.constants.BI.type.ADD_APP_APP_BUTTON,
              platformOrigin: {
                type: EditorType.Classic,
                initiator: InstallInitiator.Editor,
                info: {
                  type: InstallationOriginType.AddPanel,
                },
              },
              addingMethod: biParams.addingMethod,
              category: biParams.category,
              section: biParams.section,
            },
          ),
    openHelpCenter: () =>
      editorAPI.panelManager.openHelpCenter(helpIds.ADD_PANEL.EVENTS),
    openAppMarket() {
      const appMarketPanelProps = {
        urlParams: {
          slug: WIX_EVENTS,
        },
      };

      tpa.appMarketTabService.openAppMarketTab(
        appMarketPanelProps,
        editorAPI.panelManager.openPanel,
      );
    },
  };
};
