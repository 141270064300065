export interface TextData {
  minArea?: number;
  maxArea?: number;
  minFontSize?: number;
  maxFontSize?: number;
  nickname?: string;
}

export interface ImageData {
  minWidth?: number;
  maxWidth?: number;
  minHeight?: number;
  maxHeight?: number;
  aspectRatio?: number; //dropdown options - enum
  isImageComponent?: boolean;
  nickname?: string;
}

export type TextOrImageData = TextData | ImageData;

export interface FormData {
  id: string;
  groupLayout: string;
  titleData?: TextData[] | [];
  subtitleData?: TextData[] | [];
  paragraphData?: TextData[] | [];
  imageData?: ImageData[] | [];
}

export interface ScanSwitchLayoutPresetCmsData {
  _id?: string;
  title: string;
  layout: any;
  rules: ScanSwitchLayoutPresetCmsRules;
  metaSiteId: string;
  pageId: string;
  sectionId: string;
  isPublished?: boolean;
}

export interface ScanSwitchLayoutPresetCmsRules {
  title?: TextData[];
  subtitle?: TextData[];
  paragraph?: TextData[];
  image?: ImageData[];
}

export enum ScanSwitchLayoutValuesCategories {
  INITIAL = 'INITIAL',
  UPDATE_ID = 'UPDATE_ID',
  UPDATE_GROUP_LAYOUT = 'UPDATE_GROUP_LAYOUT',
  UPDATE_RULE = 'UPDATE_RULE',
  ADD_RULE = 'ADD_RULE',
  REMOVE_RULE = 'REMOVE_RULE',
  IMAGE = 'image',
  SET_DATA = 'SET_DATA',
  TITLE_DATA = 'titleData',
  SUBTITLE_DATA = 'subtitleData',
  PARAGRAPH_DATA = 'paragraphData',
  IMAGE_DATA = 'imageData',
}

export interface ScanSwitchLayoutPresetData {
  id?: string;
  title: string;
  layout: any;
  rules: ScanSwitchLayoutPresetCmsRules;
  metaSiteId: string;
  pageId: string;
  sectionId: string;
  isPublished?: boolean;
}
