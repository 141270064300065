// @ts-nocheck
import * as addPanel from '@/oldAddPanel';
import menuMySection from '../menu/live/section1';
import menuThemeSection from '../menu/live/section2';
import textThemeSection from '../button/live/section2';

export default {
  getCategorySections() {
    return addPanel.dataUtil.mergeWithCommonSections(
      addPanel.sections.getSections().navigation,
      { textThemeSection, menuMySection, menuThemeSection },
    );
  },
};
