import _ from 'lodash';
import { createSelector } from '../../selectors/selector';
import { editorAPISel, editorAPIMouseSel } from '../selectors/rootSelectors';
import {
  attachCandidateSel,
  singleSelectedCompSel,
} from '../selectors/selectedCompsSelectors';
import { isResizingSel, isDraggingSel } from '../selectors/mouseSelectors';
import { attachCandidate } from '@/stateManagement';
import type { EditorAPI } from '@/editorAPI';

export const getAttachCandidateToIndicate = (editorAPI: EditorAPI) => {
  return selectAttachCandidateComponent(editorAPI.store.getState());
};

const containerToShowMarginsIndicatorSel = createSelector(
  [editorAPISel, attachCandidateSel, singleSelectedCompSel],
  (editorAPI, attachCandidate, selectedComp) => {
    if (attachCandidate) {
      return attachCandidate;
    }
    return editorAPI.components.getContainer(selectedComp);
  },
);
const { selectAttachCandidateComponent } = attachCandidate.selectors;
const shouldShowContainerMarginsIndicatorSel = createSelector(
  [
    editorAPIMouseSel,
    containerToShowMarginsIndicatorSel,
    singleSelectedCompSel,
    isDraggingSel,
    isResizingSel,
  ],
  (editorAPI, container, selectedComp, isDragging, isResizing) => {
    const attachCandidateToIndicate = getAttachCandidateToIndicate(editorAPI);

    const shouldShowAttachCandidateIndicator =
      _.head(editorAPI.selection.getSelectedComponents()) &&
      (isDragging || isResizing) &&
      attachCandidateToIndicate &&
      editorAPI.components.layout.isCompExceedingContainerMargin(
        selectedComp,
        attachCandidateToIndicate,
        0,
      );

    const shouldShowContainerCandidateIndicator =
      (isDragging || isResizing) &&
      !attachCandidateToIndicate &&
      container &&
      editorAPI.components.is.showMarginsIndicator(container) &&
      editorAPI.components.layout.isCompExceedingContainerMargin(
        selectedComp,
        container,
        0,
      );

    return (
      shouldShowAttachCandidateIndicator ||
      shouldShowContainerCandidateIndicator
    );
  },
);

export const containerMarginsIndicatorStylesSel = createSelector(
  [
    editorAPIMouseSel,
    containerToShowMarginsIndicatorSel,
    shouldShowContainerMarginsIndicatorSel,
  ],
  (editorAPI, container, shouldShowContainerMarginsIndicator) => {
    if (!shouldShowContainerMarginsIndicator) {
      return undefined;
    }
    const attachCandidateToIndicate = getAttachCandidateToIndicate(editorAPI);
    const compPointer = attachCandidateToIndicate || container;
    const relativeToScreen =
      editorAPI.components.layout.getRelativeToScreen(compPointer);
    const compMargin = editorAPI.components.layout.getCompMargin(compPointer);

    return {
      indicator: {
        top: relativeToScreen.y,
        left: relativeToScreen.x,
        width: relativeToScreen.width,
        height: relativeToScreen.height,
      },
      marginLeft: Math.max(0, compMargin.left),
      marginRight: Math.max(0, compMargin.right),
      inlineWidth: relativeToScreen.width - _.sum(compMargin as AnyFixMe),
    };
  },
);

export type ContainerMarginsIndicatorStyles = ReturnType<
  typeof containerMarginsIndicatorStylesSel
>;
