export const defaultTranslationKeys = {
  modalTitle: 'feedback_modal_title',
  ratingLabel: 'Feedback_modal_rating_label',
  ratingDescriptions: [
    'feedback_modal_complete_rating_strong_neg_label',
    'feedback_modal_complete_rating_neutral_label',
    'feedback_modal_complete_rating_strong_pos_label',
  ],
  textareaLabel: 'Feedback_modal_review_label',
  textareaPlaceholder: 'Feedback_modal_review_placeholder',
  submitButtonText: 'Feedback_modal_main_cta',
  cancelButtonText: 'Feedback_modal_secondary_cta',
  notificationMessage: 'feedback_modal_success_notification_text',
};

export const HELP_ID = '67c13f8f-4cea-469e-a73f-7cb10b1042cf';

export const enum FeedbackPanelCloseSource {
  OuterOverlay = 'OuterOverlay',
  HeaderCloseButton = 'HeaderCloseButton',
  FooterSubmitButton = 'FooterSubmitButton',
  FooterCancelButton = 'FooterCancelButton',
  KeyboardEscapeKey = 'KeyboardEscapeKey',
}

export const ratingToBiNameMap: Record<number, string> = {
  1: 'negative',
  2: 'neutral',
  3: 'positive',
};

export const dataHooks = {
  root: 'saveFeedbackPanel',
  facesRating: 'saveFeedbackPanel-facesRating',
  textInput: 'saveFeedbackPanel-textInput',
};
