import type { EditorAPI } from '@/editorAPI';

import type { ConditionStructure, ConditionTranslations } from '../types';

const METADATA_KEY = 'isDeveloperModeRequired';
const TRANSLATION_KEY = 'isDeveloperModeCondition';

export interface DevModeRequiredCondition {
  extras: {
    [METADATA_KEY]?: boolean;
  };
  translations: {
    [TRANSLATION_KEY]?: ConditionTranslations;
  };
}

export const getDevModeConditionStructure = (
  editorAPI: EditorAPI,
): ConditionStructure<
  typeof METADATA_KEY,
  typeof TRANSLATION_KEY,
  DevModeRequiredCondition
> => ({
  metadataKey: METADATA_KEY,
  translationsKey: TRANSLATION_KEY,
  check: () => !editorAPI.developerMode.isEnabled(),
  getAction: () => () =>
    editorAPI.panelManager.openPanel('panels.focusPanels.corvidDevModePanel'),
});
