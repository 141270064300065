import constants from '@/constants';
import { attemptToAddComponent } from '../../../addPanel/addPanelUtils';
import { BI_ORIGIN } from '../../constants';

import type { EditorAPI } from '@/editorAPI';
import type { EditorState } from '@/stateManagement';
import type { LogoSectionOwnProps } from './logoSection';
import type { Dispatch, StateMapperArgs } from 'types/redux';
import { fetchDataForLogoComponent, initialLength } from './logoData';
import * as coreBi from '@/coreBi';
const { LOGO } = constants.COMP_TYPES;

export interface LogoSectionDispatchProps {
  handleActionClick: () => Promise<void>;
}

const getEditorAPI = (
  dispatch: Dispatch,
  getState: () => EditorState,
  { editorAPI }: StateMapperArgs,
): EditorAPI => editorAPI;

export const mapDispatchToProps = (
  dispatch: Dispatch,
  { section }: LogoSectionOwnProps,
): LogoSectionDispatchProps => {
  const editorAPI = dispatch(getEditorAPI) as EditorAPI;

  const handleActionClick = async () => {
    const logoStructure =
      editorAPI.components.buildDefaultComponentStructure(LOGO);
    logoStructure.layout.width = initialLength;
    logoStructure.layout.height = initialLength;
    const [{ logoData, logoLayoutOverrides }, compRef] = await Promise.all([
      fetchDataForLogoComponent(editorAPI),
      attemptToAddComponent(
        editorAPI,
        logoStructure,
        'logo',
        section.title,
        '',
        section.sectionName,
        BI_ORIGIN,
        null,
        {},
      ),
    ]);

    if (logoData && compRef) {
      await editorAPI.components.data.update(compRef, logoData);
      if (logoLayoutOverrides.height && logoLayoutOverrides.width) {
        await editorAPI.components.layout.update(compRef, {
          height: logoLayoutOverrides.height,
          width: logoLayoutOverrides.width,
        });
      }

      await editorAPI.components.hooks.componentAddedToStage.fire({
        compRef,
        type: 'click',
        origin: 'addPanel',
      });
    }

    editorAPI.bi.event(coreBi.events.logoPanels.LOGO_ADDED, {
      actionName: 'add',
      userEntry: 'logo_editor_component',
    });
  };

  return {
    handleActionClick,
  };
};
