const i18n = require("@/i18n");

module.exports = {
  id: "Stylable_StylableButton_23",
  preset: { rect: { width: 183, height: 75, x: 0, y: 670 }, tags: null },
  structure: {
    parent: "comp-knzvqsjj",
    style: {
      styleType: "custom",
      componentClassName: "wixui.StylableButton",
      style: {
        properties: {
          "$st-css":
            ":import{-st-from: 'wix-ui-santa/index.st.css';-st-named: StylableButton}.root{-st-extends: StylableButton;transition: all 0.2s ease, visibility 0s;background: linear-gradient(90deg, #ffbc99 0%, #ffdfc9 100%);border: 0px solid rgb(153, 53, 0);border-radius: 5px}.root:hover {\n    background: linear-gradient(90deg, #e09d7a 0%, #eac9b3 100%);\n}.root:disabled {\n    background: #EDE5E5;\n}.root[disabled]{background: #E2E2E2}.root[disabled]::label{color: #8F8F8F}.root[disabled]::icon{fill: #8F8F8F}.root::container{transition: inherit}.root::label{transition: inherit;display: initial;color: #993500;font-family: \"playfair display\",serif;margin: 0px 3px 0px 0px;font-size: 17px}.root::icon{transition: inherit;display: initial;fill: #993500;width: 34px;height: 34px;margin: 0px 0px 0px 3px}",
        },
        propertiesSource: { "$st-css": "value" },
      },
      pageId: "",
      compId: "",
      type: "ComponentStyle",
      metaData: {
        isHidden: false,
        sig: "4ie-188",
        pageId: "c1dmp",
        schemaVersion: "1.0",
        isPreset: false,
      },
      skin: "wixui.skins.Skinless",
    },
    activeModes: {},
    data: {
      type: "StylableButton",
      metaData: {
        isHidden: false,
        sig: "hmj-885",
        pageId: "c1dmp",
        schemaVersion: "1.0",
        isPreset: false,
      },
      label: i18n.translate("buttons_text_preset_chat_now"),
      svgId: "11062b_a9eb1c9245c8402ba29c31611e4efaf1.svg",
    },
    components: [],
    componentType: "wixui.StylableButton",
    id: "comp-kmdea637",
    layout: {
      x: 11,
      fixedPosition: false,
      y: 687,
      scale: 1,
      height: 42,
      rotationInDegrees: 0,
      width: 160,
    },
    type: "Component",
    props: {
      type: "StylableButtonProperties",
      metaData: { schemaVersion: "1.0", sig: "hmj-827", pageId: "c1dmp" },
    },
    metaData: { sig: "inq-332", pageId: "c1dmp" },
    skin: "wixui.skins.Skinless",
  },
};
