import interactionHiddenComponents from './panels/interactionsHiddenComponents/interactionsHiddenComponents';
import interactionsPanel from './panels/interactionsPanel/interactionsPanel';
import { effectsPanel } from './panels/effectsPanel/effectsPanel';
import transitionPanel from './panels/transitionPanel/transitionPanel';
import textSettingsPanel from './panels/textSettingsPanel/textSettingsPanel';
import InteractionNavControls from './controls/interactionsNavControls';
import { PureTextStylingSection } from './panels/textSettingsPanel/textStylingSection';

const controls = {
  InteractionNavControls,
};

const panels = {
  interactionsPanel,
  interactionHiddenComponents,
  effectsPanel,
  transitionPanel,
  textSettingsPanel,
  textStylingSection: PureTextStylingSection,
};

export { panels, controls };
