import experiment from 'experiment';
import type { ComponentPartName } from './componentRegistry';

const componentPartsAllowedMap = new Map<string, boolean>([
  /**
   * Work around to get settings panel from editor-elements-library
   * Should remove this when finish migrating panels
   * Jira Ticket: https://jira.wixpress.com/browse/ECL-5480
   */
  [
    'wixui.StylableHorizontalMenu-stylablePanelReact',
    experiment.isOpen('se_stylableHorizontalMenuStylablePanelReact'),
  ],
  [
    'wysiwyg.viewer.components.Grid-settingsPanel',
    experiment.isOpen('se_gridPanelsMigration'),
  ],
  [
    'wysiwyg.viewer.components.Grid-gfpp',
    experiment.isOpen('se_gridPanelsMigration'),
  ],
  [
    'wysiwyg.viewer.components.gridColumnManagerPanel',
    experiment.isOpen('se_gridPanelsMigration'),
  ],
  [
    'wixui.VideoPlayer-settingsPanel',
    experiment.isOpen('se_SingleVideoPlayer_panels_migration'),
  ],
  //-----
]);

export const isNewStaticsEnabled = () =>
  experiment.isOpen('specs.editor-elements.useNewStatics');

export const isMobileLibraryEnabled = () =>
  experiment.isOpen('specs.mobile-elements.useRegistry');

export function isComponentPartAllowed<TPartName extends ComponentPartName>(
  componentType: string,
  componentPartName: TPartName | string,
): componentPartName is TPartName {
  if (componentPartsAllowedMap.has(`${componentType}-${componentPartName}`)) {
    return componentPartsAllowedMap.get(
      `${componentType}-${componentPartName}`,
    );
  }

  return true;
}
