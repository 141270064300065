// @ts-nocheck
import addPanelDataConsts from '@/addPanelDataConsts';

export default {
  type: addPanelDataConsts.SECTIONS_TYPES.THEME,
  hide: false,
  title: 'add_section_label_theme_contact',
  showSectionHeader: true,
  additionalBehaviours: {
    labelMode: addPanelDataConsts.LABEL_BEHAVIOUR.HOVER,
    infoIcon: addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
    iconEnabledForComps: {
      'wysiwyg.common.components.subscribeform.viewer.SubscribeForm': {
        title: 'preset_info_title_subscribeform',
        text: 'preset_info_text_subscribeform',
      },
    },
  },
  props: {
    compTypes: [
      'wysiwyg.viewer.components.ContactForm',
      'wysiwyg.viewer.components.DynamicContactForm',
      'wysiwyg.viewer.components.GoogleMap',
    ],
    verticalMargin: 30,
    horizontalMargin: 20,
    maxFontSize: 15,
    scaleProportion: {
      x: 0.5,
      y: 0.5,
    },
  },
  help: {
    hide: false,
    text: 'add_section_info_text_theme_general',
  },
};
