import { sections as sectionsUtils, array } from '@/util';

import { isSectionByCompType } from '../../../api';

import type { PluginServicePlugins } from 'types/editorPlugins';
import type { CompStructure } from 'types/documentServices';
import type { Scope } from '../../../scope';

type RealCompStructure = Omit<CompStructure, 'components'> & {
  components: RealCompStructure[];
};

export const createIsAllowedToPastePlugin =
  (_scope: Scope): PluginServicePlugins.AllowedToPastePlugin =>
  (editorAPI, { components }) => {
    if (sectionsUtils.isSectionsEnabled()) {
      return true;
    }
    if (components) {
      const flattenedComps = array.flattenBy(
        components as RealCompStructure[],
        'components',
      );

      const hasSections = flattenedComps.some((structure) =>
        isSectionByCompType(structure.componentType),
      );

      if (hasSections) {
        editorAPI.panelManager.openPanel(
          'panels.messagePanels.prohibitedCrossEditorPasteMessage',
        );
      }

      return !hasSections;
    }

    return true;
  };
