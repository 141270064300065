// @ts-nocheck
import _ from 'lodash';
import createReactClass from 'create-react-class';
import * as core from '@/core';
import * as coreBi from '@/coreBi';
import * as util from '@/util';
import { translate } from '@/i18n';
import React from 'react';
import { OldMessagePanelFrame } from '../../frames';
import * as baseUI from '@/baseUI';
import { Composites, Illustration, RichText } from '@wix/wix-base-ui';
import constants from '@/constants';

const MLBI = coreBi.events.multilingual;

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'changeStyleMessage',
  mixins: [core.mixins.editorAPIMixin],
  render() {
    return (
      <OldMessagePanelFrame
        panelName={this.props.panelName}
        confirmLabel="Notification_Box_Got_It"
        dontShowAgainKey={
          constants.USER_PREFS.MULTILINGUAL.STYLING_COMPONENT.DONT_SHOW_AGAIN
        }
        userPrefType="site"
        panelTitle={translate(
          'Notification_Box_Multilingual_Design_Change_Title',
        )}
        onDontShowAgainChange={this.onDontShowAgainChange}
        onConfirm={this.onConfirm}
        onCancel={this.onCancel}
        className="change-style"
      >
        <Composites.RichTextWithIllustration>
          <Illustration>
            <baseUI.symbol name="plaster" />
          </Illustration>
          <Composites.RichText>
            <RichText type="T01">
              {translate('Notification_Box_Multilingual_Design_Change_Text')}
            </RichText>
          </Composites.RichText>
        </Composites.RichTextWithIllustration>
      </OldMessagePanelFrame>
    );
  },

  sendBI(event, parameters) {
    const editorAPI = this.getEditorAPI();
    editorAPI.bi.event(
      event,
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      _.assign(
        {
          esi: util.editorModel.editorSessionId,
          site_id: editorAPI.dsRead.generalInfo.getSiteId(),
        },
        parameters,
      ),
    );
  },

  onDontShowAgainChange(dontShowAgain) {
    this.sendBI(MLBI.styling_dont_show, { toggle_state: dontShowAgain });
  },
});
