import React from 'react';
import { translate } from '@/i18n';

export default class extends React.Component {
  static displayName = 'tpaCartPageSeo';

  render() {
    return (
      <div className="seo-google tab-inner empty">
        <p>{translate('Pages_Store_Cart_SEO_Text')}</p>
      </div>
    );
  }
}
