import { translate } from '@/i18n';

import { isDropdown } from '../../../utils/utils';
import { TRANSLATIONS_MAP } from './translations';
import type { IMenuItem, IGetMenuItemLocalizations } from '@/baseUI';

const VALIDATION_SUCCESS = '';

const validateDropDownName = (name: string) => {
  if (name.trim().length === 0) {
    return translate(TRANSLATIONS_MAP.ITEM.DROPDOWN.emptyValidationError);
  }

  return VALIDATION_SUCCESS;
};

const validateGeneralMenuItemName = (name: string) => {
  if (name.trim().length === 0) {
    return translate(TRANSLATIONS_MAP.ITEM.LINK.emptyValidationError);
  }

  return VALIDATION_SUCCESS;
};

const validateMenuItemName = (newName: string, menuItem: IMenuItem) => {
  if (isDropdown(menuItem)) {
    return validateDropDownName(newName);
  }

  return validateGeneralMenuItemName(newName);
};

export const getMenuItemLocalizations: IGetMenuItemLocalizations = (
  menuItem,
) => ({
  validateName: (newName) => validateMenuItemName(newName, menuItem),
  submitRenameButton: translate(
    isDropdown(menuItem)
      ? TRANSLATIONS_MAP.ITEM.DROPDOWN.renameDoneButton
      : TRANSLATIONS_MAP.ITEM.LINK.renameDoneButton,
  ),
  inputPlaceholder: translate(
    isDropdown(menuItem)
      ? TRANSLATIONS_MAP.ITEM.DROPDOWN.placeholder
      : TRANSLATIONS_MAP.ITEM.LINK.placeholder,
  ),
});
