// @ts-nocheck
import _ from 'lodash';
import * as stateManagement from '@/stateManagement';

const HELP_END_POINTS = {
  SETTINGS_MODAL: 'settings_modal',
  SETTINGS: 'settings',
  WIDGET: 'widget',
};

const getHelpId = function (editorAPI, appDefId, widgetId, endPoint) {
  return new Promise(function (resolve, reject) {
    let helpId = '';
    let manifestHelpId;
    const componentRef = _.head(editorAPI.selection.getSelectedComponents());

    if (
      editorAPI.dsRead.tpa.isTpaByCompType(
        editorAPI.components.getType(componentRef),
      )
    ) {
      const gfppDataFromApp =
        editorAPI.dsRead.platform.controllers.getStageData(componentRef)?.gfpp;

      const deviceType = stateManagement.mobile.selectors.getDeviceType(
        editorAPI.store.getState(),
      );
      manifestHelpId = gfppDataFromApp?.[deviceType]?.helpId;
    }

    if (manifestHelpId) {
      resolve(manifestHelpId);
    } else if (!_.isEmpty(appDefId)) {
      editorAPI.dsRead.tpa.appMarket.getDataAsync(appDefId).then(function (
        appMarketData,
      ) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/find
        const widgetData = _.find(appMarketData.widgets, { widgetId });
        const settingsHelpId = widgetData?.settings?.helpId ?? '';
        if (endPoint === HELP_END_POINTS.SETTINGS_MODAL) {
          helpId = appMarketData?.dashboard?.helpId ?? settingsHelpId;
        } else if (widgetId) {
          if (endPoint === HELP_END_POINTS.SETTINGS) {
            helpId = settingsHelpId;
          } else if (editorAPI.isMobileEditor()) {
            helpId = widgetData?.mobileHelpId ?? '';
          } else {
            helpId = widgetData?.helpId ?? '';
          }
        }
        resolve(helpId);
      }, reject);
    } else {
      reject(helpId);
    }
  });
};

export { getHelpId, HELP_END_POINTS };
