import type { EntryPoint } from '@/apilib';
import { EditorAPIKey } from '@/apis';
import { createEditorUIApi } from './editorUIApi';
import { EditorUIApiKey } from './publicApiKey';

export const EditorUIEntrypoint: EntryPoint = {
  name: 'EditorUIApi',
  declareAPIs: () => [EditorUIApiKey],
  getDependencyAPIs: () => [EditorAPIKey],
  attach(shell) {
    shell.contributeAPI(EditorUIApiKey, () => createEditorUIApi(shell));
  },
};
