export const TRANSLATIONS_MAP = {
  panelTitle: 'custom_menu_manage_menu_items_main_or_custom_header_label',
  INFO: {
    DESCRIPTION: 'custom_menu_manage_menu_main_or_custom_tooltip',
    LINK_TITLE: 'custom_menu_manage_menu_main_or_custom_tooltip_link',
  },
  OPTIONS: {
    main: 'custom_menu_manage_menu_main_or_custom_option_main',
    custom: 'custom_menu_manage_menu_main_or_custom_option_custom',
    mainDescription:
      'custom_menu_manage_menu_main_or_custom_option_main_description',
    customDescription:
      'custom_menu_manage_menu_main_or_custom_option_custom_description',
  },
  MOBILE_OPTIONS: {
    main: 'custom_menu_mobile_manage_menu_main_or_custom_option_main',
    custom: 'custom_menu_mobile_manage_menu_main_or_custom_option_custom',
    mainDescription:
      'custom_menu_mobile_manage_menu_main_or_custom_option_main_description',
    customDescription:
      'custom_menu_mobile_manage_menu_main_or_custom_option_custom_description',
  },
};
