// @ts-nocheck
import _ from 'lodash';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import * as core from '@/core';
import * as compPanelInfra from '@/compPanelInfra';
import appLogicParamsLinkHelper from '../appLogicParamsLinkHelper/appLogicParamsLinkHelper';

import React from 'react';
import {
  Checkbox,
  Composites,
  Divider,
  DropDown,
  DropDownOption,
  TextLabel,
} from '@wix/wix-base-ui';
import { translate } from '@/i18n';
import { cx } from '@/util';
import { WixBaseUISliderWithBI } from '@/baseUI';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'BlogPostFilter',

  propTypes: {
    appLogicParamsLink: PropTypes.shape({
      value: PropTypes.object.isRequired,
      requestChange: PropTypes.func.isRequired,
    }).isRequired,
    appPartName: PropTypes.string,
    packageName: PropTypes.string,
  },

  mixins: [
    core.mixins.editorAPIMixin,
    compPanelInfra.wixappsViewCacheInvalidationMixin,
  ],

  /**
   * @returns {ReactLink}
   */
  getAppLogicParamsLink() {
    return this.props.appLogicParamsLink;
  },

  /**
   * @returns {ReactLink}
   */
  linkAppLogicParamFilterTags() {
    const self = this;
    const link = self.getAppLogicParamsLink();
    return {
      value:
        appLogicParamsLinkHelper.getParamProp(link, 'filter', 'tags') || '',
      requestChange(value) {
        if (value) {
          appLogicParamsLinkHelper.setParamProp(link, 'filter', 'tags', value);
        } else {
          appLogicParamsLinkHelper.removeParamProp(link, 'filter', 'tags');
        }
        self.invalidateViewCache();
        appLogicParamsLinkHelper.update(link);
      },
    };
  },

  /**
   * @returns {ReactLink}
   */
  linkAppLogicParamFilterFeatured() {
    const self = this;
    const link = this.getAppLogicParamsLink();
    return {
      value:
        appLogicParamsLinkHelper.getParamProp(link, 'filter', 'featured') ||
        false,
      requestChange(value) {
        if (value) {
          appLogicParamsLinkHelper.setParamProp(
            link,
            'filter',
            'featured',
            value,
          );
        } else {
          appLogicParamsLinkHelper.removeParamProp(link, 'filter', 'featured');
        }
        self.invalidateViewCache();
        appLogicParamsLinkHelper.update(link);
      },
    };
  },

  /**
   * @returns {ReactLink}
   */
  linkAppLogicParamLimit() {
    const self = this;
    const link = self.getAppLogicParamsLink();
    return {
      value: appLogicParamsLinkHelper.getParam(link, 'limit') || 10,
      requestChange(value) {
        appLogicParamsLinkHelper.setParam(link, 'limit', value);
        self.invalidateViewCache();
        appLogicParamsLinkHelper.update(link);
      },
    };
  },

  /**
   * @returns {ReactLink}
   */
  linkAppLogicParamFilterCategoryName() {
    const self = this;
    const link = self.getAppLogicParamsLink();
    return {
      value:
        _.head(appLogicParamsLinkHelper.getParam(link, 'categoryNames')) || '',
      requestChange(value) {
        if (value) {
          appLogicParamsLinkHelper.setParam(link, 'categoryNames', [value]);
        } else {
          delete link.value.categoryNames;
        }
        self.invalidateViewCache();
        appLogicParamsLinkHelper.update(link);
      },
    };
  },

  /**
   * @returns {boolean}
   */
  hasAppLogicParamFilter(partName) {
    const editorAPI = this.getEditorAPI();
    if (!editorAPI) {
      return [];
    }
    return (
      editorAPI.dsRead.wixapps.classics.getAppPartRole(
        this.props.packageName,
        this.props.appPartName,
      ) !== partName
    );
  },

  /**
   * @returns {string[]}
   */
  getTagNames() {
    let tagNames = [];

    const editorApi = this.getEditorAPI();
    if (editorApi) {
      tagNames = tagNames.concat(
        editorApi.dsRead.wixapps.classics.getTagNames(),
      );
    }

    const selectedTagName = appLogicParamsLinkHelper.getParamProp(
      this.getAppLogicParamsLink(),
      'filter',
      'tags',
    );
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/includes
    if (selectedTagName && !_.includes(tagNames, selectedTagName)) {
      tagNames.unshift(selectedTagName);
    }

    return tagNames.sort();
  },

  getFlattenedCategories() {
    const selectedCategoryName =
      this.linkAppLogicParamFilterCategoryName().value;
    const editorAPI = this.getEditorAPI();
    if (!editorAPI) {
      return [];
    }
    return editorAPI.dsRead.wixapps.classics.getBlogCategories(
      selectedCategoryName,
    );
  },

  render() {
    return (
      <div className="blog-post-filter">
        <Divider long={true} />

        {this.hasAppLogicParamFilter('FEATURED_POSTS') ? (
          <div key="filter">
            <Composites.DropDownLabeled>
              <TextLabel
                value={translate('BLOG_FILTER_VIEW_FILTER_BY_TAG')}
                shouldTranslate={false}
              />
              <DropDown
                value={this.linkAppLogicParamFilterTags().value}
                onChange={this.linkAppLogicParamFilterTags().requestChange}
                shouldTranslate={false}
              >
                {
                  <DropDownOption
                    value=""
                    label={translate('BLOG_FILTER_VIEW_FILTER_ALL_TAGS')}
                    key="0"
                  />
                }
                {this.getTagNames().map((tagName, tagNameIndex) => (
                  <DropDownOption
                    key={tagNameIndex + 1}
                    value={tagName}
                    label={tagName}
                  />
                ))}
              </DropDown>
            </Composites.DropDownLabeled>

            <Divider long={true} />

            <Composites.DropDownLabeled>
              <TextLabel
                value={translate('BLOG_FILTER_VIEW_FILTER_BY_CATEGORY')}
                shouldTranslate={false}
              />
              <DropDown
                value={this.linkAppLogicParamFilterCategoryName().value}
                onChange={
                  this.linkAppLogicParamFilterCategoryName().requestChange
                }
                shouldTranslate={false}
              >
                {
                  <DropDownOption
                    value=""
                    label={translate('BLOG_FILTER_VIEW_FILTER_ALL_CATEGORIES')}
                    key="0"
                  />
                }
                {this.getFlattenedCategories().map(
                  (category, categoryIndex) => (
                    <DropDownOption
                      key={categoryIndex + 1}
                      value={category.name}
                      label={category.name}
                      className={cx({ level1: category.isSubcategory })}
                    />
                  ),
                )}
              </DropDown>
            </Composites.DropDownLabeled>
            <Divider long={true} />

            <Composites.Checkboxes>
              <Checkbox
                label="BLOG_FILTER_VIEW_SHOW_ONLY_FEATURED"
                labelAfterSymbol={true}
                valueLink={this.linkAppLogicParamFilterFeatured()}
              />
            </Composites.Checkboxes>

            <Divider long={true} />
          </div>
        ) : null}

        {this.hasAppLogicParamFilter('CUSTOM_FEED') && (
          <Composites.SliderLabeled>
            <TextLabel value="BLOG_FILTER_VIEW_MAX_POSTS" />

            <WixBaseUISliderWithBI
              value={this.linkAppLogicParamLimit().value}
              onChange={this.linkAppLogicParamLimit().requestChange}
              min={1}
              max={50}
              inputMin={1}
              inputMax={50}
              key="limit"
              label="BLOG_FILTER_VIEW_MAX_POSTS"
            />
          </Composites.SliderLabeled>
        )}
      </div>
    );
  },
});
