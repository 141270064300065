import type { EditorState } from '@/stateManagement';
import * as stateManagement from '@/stateManagement';
import type {
  MapDispatchToProps,
  MapStateToProps,
  ThunkAction,
} from 'types/redux';
import type { EditorAPI } from '@/editorAPI';
import constants from '@/constants';
import {
  WorkspaceApiKey,
  EditorRestrictionsApiKey,
  ZoomModeApiKey,
  WixCodeWorkspaceApiKey,
  WorkspaceModesApiKey,
  FixedStageApiKey,
  EditorUIApiKey,
} from '@/apis';
import type { SplitterWrapperOwnProps } from './splitterWrapper';
import type { CompRef } from 'types/documentServices';
import type { ComponentType, CSSProperties } from 'react';
import type { ContributedComponent } from '@/apilib';
import { WorkspaceModes } from '@/workspaceModes';
import * as util from '@/util';
import experiment from 'experiment';

const MOBILE_WIZARD_OPENING_STATE =
  constants.MOBILE.MOBILE_WIZARD.OPENING_STATE;

const { isPerformingMouseMoveAction } = stateManagement.mouseActions.selectors;
const { getSelectedCompsRefs } = stateManagement.selection.selectors;
const { getMobileWizardOpeningState, getIsMobileWizardEnabled } =
  stateManagement.mobile.selectors.mobileWizard;
const wixCodeIDESelectors = stateManagement.wixCodeIDE.selectors;
const leftTreeSelectors = stateManagement.leftTree.selectors;
const { getIsSectionizedPanelOpened } = stateManagement.sections.selectors;
const sectionedPanelSelectors = stateManagement.sectionedPanel.selectors;
const {
  selectOpenDockedPlatformPanels,
  selectPlatformSidePanelWidth,
  isPlatformSidePanelAboveCurrentModal,
  hasOpenedLeftPanels: hasOpenedLeftPanelsSel,
} = stateManagement.panels.selectors;
const secondaryLeftAreaSelectors = stateManagement.secondaryLeftArea.selectors;
const { isOpen: isPinModeOpen } = stateManagement.pinMode.selectors;
const { getPreviewMode } = stateManagement.preview.selectors;
const { getBannerVisibility } = stateManagement.topBar.selectors;
const { isLayersPanelDisplayed } = stateManagement.layersPanel.selectors;
const { isTranslating } = stateManagement.multilingual.selectors;
const { isInInteractionMode } = stateManagement.interactions.selectors;
const { initDomMeasurements, initDomMeasurementsAfterAnimationEnd } =
  stateManagement.domMeasurements.actions;
const secondaryLeftAreaActions = stateManagement.secondaryLeftArea.actions;
const { hideLayersPanel } = stateManagement.layersPanel.actions;
const { setPreviewPointerEventsOn } = stateManagement.preview.actions;

export interface SplitterWrapperStateProps {
  WorkspaceRightPanel: ContributedComponent<{}>[];
  veloTreeComponent: ComponentType<any>;
  codeEditorComponent: ComponentType;
  consoleComponent: ComponentType;
  previewMode: boolean;
  isRightPanelHidden: boolean;
  hasOpenedLeftPanels: boolean;
  hasOpenDockedSections: boolean;
  hasDockedPanels: boolean;
  hideTools: EditorState['hideTools'];
  hideToolsAndMaintainStagePosition: EditorState['hideToolsAndMaintainStagePosition'];
  devModeContext: EditorState['devModeContext'];
  devModeContextWithData: any;
  performingMouseMoveAction: boolean;
  renderCounter: number;
  wixCodeLoaded: EditorState['wixCodeLoaded'];
  helpPanelProps: EditorState['helpProps'];
  selectedComponents: CompRef[];
  shouldRenderMobileWizard: boolean;
  shouldShowIdePane: boolean;
  shouldShowDevModePanels: boolean;
  shouldShowLeftPane: boolean;
  mobileWizardOpeningState: keyof typeof MOBILE_WIZARD_OPENING_STATE;
  isMobileEditor: boolean;
  shouldShowSectionedPanel: boolean;
  shouldShowSectionedPanelAppStudioPreview: boolean;
  secondaryLeftAreaWidth: string;
  secondaryLeftAreaContentModule: any;
  isMobileWizardEnabled: boolean;
  isStageZoomMode: boolean;
  developerModeUI: any;
  treePaneSplitPosition: string;
  editorSplitPosition: string;
  isWithTopBarBanner: boolean;
  isOpenTextEditor?: boolean;
  isPinMode: boolean;
  isLightboxMode: boolean;
  isInteractionMode: boolean;
  isTranslationMode: boolean;
  shouldCutEditingArea: boolean;
  loadWixCodeWithRepluggableInClassic: boolean;
  shouldDisplayLayersPanel: boolean;
  shouldDisplaySectionizerPanel: boolean;
  shouldDisplayQrCodeExposer: boolean;
  isZoomOut: boolean;
  sidePanelWidth: number;
  shouldRaiseSidePanelAboveModals: boolean;
  styleForPushedStageAndPreviewFrame: CSSProperties;
  isLeftBarVisible: boolean;
  isFullEditorMode: boolean;
  fixedStageWidth: number;
  shouldRenderStageWithFixedWidth: boolean;
  siteScale: number;
  leftBarWidth: number;
  editingAreaWidth: number;
  shouldRenderFixedStageTopGap: boolean;
  setIsStageHorizontallyScrollable: (newValue: boolean) => void;
  isStageHorizontallyScrollable: boolean;
  baselineWidth?: number;
  isDesktopPreview: boolean;
  previewWidth: number;
  shouldAnimatePreviewWidth: boolean;
  stageHeight: string;
}

export const mapStateToProps: MapStateToProps<
  SplitterWrapperStateProps,
  SplitterWrapperOwnProps
> = (stateMapperArgs) => {
  const { state: editorState, editorAPI, dsRead, host } = stateMapperArgs;

  const {
    wixCodeLoaded,
    hideTools,
    hideToolsAndMaintainStagePosition,
    devModeContext,
    __renderCounter,
    siteScale,
    helpProps: helpPanelProps,
  } = editorState;

  const WorkspaceRightPanel = host
    .getAPI(WorkspaceApiKey)
    .getWorkspaceRightPanel();
  const veloTreeComponent = host
    .getAPI(WixCodeWorkspaceApiKey)
    .getVeloTreeComponent();
  const consoleComponent = host
    .getAPI(WixCodeWorkspaceApiKey)
    .getConsoleComponent();
  const fixedStageAPI = editorAPI.host.getAPI(FixedStageApiKey);
  const isMobileEditor = editorAPI.isMobileEditor();
  const isPreviewReady = editorAPI.isPreviewReady && editorAPI.isPreviewReady();
  const isDeveloperModeEnabled = editorAPI.developerMode.isEnabled();
  const isLightboxMode = editorAPI.dsRead?.pages.popupPages.isPopupOpened();
  const rootCompId = editorAPI.dsRead?.pages.getFocusedPageId();
  const isWidgetPage = editorAPI.dsRead?.pages.isWidgetPage(rootCompId);
  const performingMouseMoveAction = isPerformingMouseMoveAction(editorState);
  const selectedComponents = getSelectedCompsRefs(editorState);
  const previewMode = getPreviewMode(editorState);
  const isMobileWizardEnabled = getIsMobileWizardEnabled(editorState);
  const isSchoolMode =
    stateManagement.schoolMode.selectors.isEnabled(editorState);
  const mobileWizardOpeningState = getMobileWizardOpeningState(editorState);
  const dockedPanels = selectOpenDockedPlatformPanels(editorState);
  const sidePanelWidth = selectPlatformSidePanelWidth(editorState);
  const secondaryLeftAreaWidth =
    secondaryLeftAreaSelectors.width(editorState) || '180px';
  const secondaryLeftAreaContentModule =
    secondaryLeftAreaSelectors.contentModule(editorState);
  const isLeftTreeVisible =
    leftTreeSelectors.getOverriddenVisibility(editorState);
  const shouldShowSectionedPanel =
    sectionedPanelSelectors.shouldShow(editorState);
  const isPinMode = isPinModeOpen(editorState);
  const isPublished = dsRead?.generalInfo?.isSitePublished();
  const shouldRenderMobileWizard =
    isPreviewReady && isMobileEditor && isMobileWizardEnabled;
  const shouldShowDevModePanels =
    wixCodeLoaded && isDeveloperModeEnabled && !hideTools;
  const shouldShowLeftPane =
    shouldShowDevModePanels &&
    !previewMode &&
    isLeftTreeVisible &&
    !isSchoolMode &&
    editorAPI.host
      .getAPI(EditorRestrictionsApiKey)
      .allowed('wix-code_left-pane.visible');
  const overriddenIdeData = wixCodeIDESelectors.getOverriddenItems(editorState);
  const shouldShowIdePane =
    shouldShowDevModePanels &&
    (previewMode || overriddenIdeData.shouldShow) &&
    editorAPI.host
      .getAPI(EditorRestrictionsApiKey)
      .allowed('wix-code_ide-pane.visible');
  const openDockedSections =
    sectionedPanelSelectors.openDockedSections(editorState);
  const shouldDisplayLayersPanel =
    isLayersPanelDisplayed(editorState) && isPreviewReady;
  const shouldDisplaySectionizerPanel =
    getIsSectionizedPanelOpened(editorState) && isPreviewReady;
  const shouldDisplayQrCodeExposer =
    isMobileEditor &&
    previewMode &&
    isPublished &&
    util.workspace.isNewWorkspaceEnabled();
  const isWithTopBarBanner =
    !(hideTools && !hideToolsAndMaintainStagePosition) &&
    getBannerVisibility(editorState);

  const developerModeUI = editorAPI.developerMode.ui;
  const treePaneSplitPosition = developerModeUI.treePane.getSplitPosition();
  const consolePaneSplitPosition =
    developerModeUI.consolePane.getSplitPosition();
  const idePaneSplitPosition = developerModeUI.idePane.getSplitPosition();
  const devModeContextWithData = wixCodeLoaded
    ? editorAPI.developerMode.getContext()
    : devModeContext;
  const isTranslationMode = isTranslating(editorState);
  const isInteractionMode = isInInteractionMode(editorState);
  const isZoomOut = editorAPI.zoomMode.isInZoomMode();
  const isStageZoomMode = editorAPI.zoomMode.isStageZoomMode();
  const shouldRaiseSidePanelAboveModals =
    isPlatformSidePanelAboveCurrentModal(editorState);

  const isFullEditorMode = host
    .getAPI(WorkspaceModesApiKey)
    .isMode(WorkspaceModes.FULL);

  const zoomModeApi = editorAPI.host.getAPI(ZoomModeApiKey);
  const styleForPushedStageAndPreviewFrame =
    zoomModeApi.getStyleForPushedStageAndPreviewFrame();
  const codeEditorComponent = host
    .getAPI(WixCodeWorkspaceApiKey)
    .getCodeEditorComponent();

  const hasOpenedLeftPanels = hasOpenedLeftPanelsSel(editorState);
  const hasOpenDockedSections = openDockedSections.length > 0;
  const hasDockedPanels = dockedPanels.length > 0;

  const isRightPanelHidden = isStageZoomMode;

  const editorSplitPosition = previewMode
    ? consolePaneSplitPosition
    : idePaneSplitPosition;

  const loadWixCodeWithRepluggableInClassic =
    !util.appStudioUtils.isAppStudio();
  const isLeftBarVisible = editorAPI.host
    .getAPI(EditorRestrictionsApiKey)
    .allowed('left-bar.visible');

  const shouldRenderStageWithFixedWidth = fixedStageAPI.isStageFixedInDesktop();
  const leftBarWidth = util.fixedStage.isFixedStageEnabled()
    ? constants.ROOT_COMPS.LEFTBAR.LEFT_BAR_WIDTH_FIXED_STAGE
    : constants.ROOT_COMPS.LEFTBAR.LEFT_BAR_WIDTH;

  const editingAreaWidth = editorAPI.getEditingAreaPosition().width;

  const previewType = previewMode
    ? stateManagement.preview.selectors.getPreviewType(editorState)
    : undefined;
  const previewWidth =
    previewType === 'fullScreen'
      ? stateManagement.domMeasurements.selectors.getEditorContentLayout(
          editorState,
        ).width
      : stateManagement.preview.selectors.getPreviewWidth(editorState);
  const fixedStageWidth = editorAPI.dsRead?.site.getWidth();
  const isDesktopPreview =
    previewMode &&
    util.fixedStage.isFixedStageEnabled() &&
    previewType === 'desktop';
  const shouldRenderFixedStageTopGap = util.fixedStage.shouldRenderFixedTopGap(
    previewMode,
    previewType,
  );
  const shouldAnimatePreviewWidth =
    stateManagement.preview.selectors.getIsAnimatedPreviewResize(editorState) &&
    previewWidth > 0;

  const siteHeight = editorAPI.dsRead?.site.getHeight();

  const stageHeightForZoomedStage =
    !stateManagement.stateMapperArgsSelectors.mouseSels.isDuringMouseActionSel(
      stateMapperArgs,
    ) && siteHeight * siteScale;

  return {
    isRightPanelHidden,
    hasOpenedLeftPanels,
    hasOpenDockedSections,
    hasDockedPanels,
    sidePanelWidth,
    previewMode,
    siteScale,
    performingMouseMoveAction,
    renderCounter: __renderCounter,
    wixCodeLoaded,
    hideTools,
    hideToolsAndMaintainStagePosition,
    devModeContext,
    devModeContextWithData,
    helpPanelProps,
    selectedComponents,
    shouldRenderMobileWizard,
    shouldShowIdePane,
    shouldShowDevModePanels,
    shouldShowLeftPane,
    mobileWizardOpeningState,
    isMobileEditor,
    shouldCutEditingArea: editorAPI.host
      .getAPI(EditorUIApiKey)
      .stage.shouldCutEditingArea(),
    shouldShowSectionedPanel,
    shouldShowSectionedPanelAppStudioPreview: isWidgetPage,
    shouldDisplayLayersPanel,
    shouldDisplaySectionizerPanel,
    shouldDisplayQrCodeExposer,
    codeEditorComponent,
    secondaryLeftAreaWidth,
    secondaryLeftAreaContentModule,
    isMobileWizardEnabled,
    developerModeUI,
    treePaneSplitPosition,
    editorSplitPosition,
    isWithTopBarBanner,
    isPinMode,
    isLightboxMode,
    isInteractionMode,
    loadWixCodeWithRepluggableInClassic,
    isTranslationMode,
    isZoomOut,
    isStageZoomMode,
    isShrinkedStageZoomOutActive:
      zoomModeApi.isLeftShrinkedStageZoomOutActive(),
    isFullEditorMode,
    shouldRaiseSidePanelAboveModals,
    WorkspaceRightPanel,
    veloTreeComponent,
    consoleComponent,

    styleForPushedStageAndPreviewFrame,
    isLeftBarVisible,
    fixedStageWidth,
    shouldRenderStageWithFixedWidth,
    leftBarWidth,
    editingAreaWidth,
    shouldRenderFixedStageTopGap,
    setIsStageHorizontallyScrollable: (newValue: boolean) =>
      fixedStageAPI.setIsStageHorizontallyScrollable(newValue),
    isStageHorizontallyScrollable:
      fixedStageAPI.isStageHorizontallyScrollable(),
    baselineWidth: editorAPI.site.getWidth && editorAPI.site.getWidth(),
    isDesktopPreview,
    previewWidth,
    shouldAnimatePreviewWidth,
    stageHeight:
      siteScale === 1 || !stageHeightForZoomedStage || isNaN(siteHeight)
        ? '100%'
        : `${stageHeightForZoomedStage}px`,
  };
};

const getEditorAPI: ThunkAction<EditorAPI> = (
  dispatct,
  getState,
  { editorAPI },
) => editorAPI;

export interface SplitterWrapperDispatchProps {
  closeAllPanels: () => void;
  initDomMeasurements: () => void;
  initDomMeasurementsAfterAnimationEnd: () => void;
  onRenderDone: (renderCounter: number) => void;
  refreshEditingAreaPosition: (rect: any) => void;
  setSecondaryLeftAreaWidth: (newWidth: any) => void;
  hideLayersPanel: () => void;
  previewResizeStart: () => void;
  previewResizeEnd: () => void;
  toggleMeasureMaps: () => void;
  setIsWixCodeIDELoaded: (isLoaded: boolean) => void;
}

export const mapDispatchToProps: MapDispatchToProps<
  SplitterWrapperDispatchProps,
  SplitterWrapperOwnProps
> = (dispatch) => {
  const editorAPI: EditorAPI = dispatch(getEditorAPI);

  return {
    closeAllPanels: () => editorAPI.panelManager.closeAllPanels(),
    initDomMeasurements: () => dispatch(initDomMeasurements()),
    initDomMeasurementsAfterAnimationEnd: () =>
      dispatch(initDomMeasurementsAfterAnimationEnd()),
    onRenderDone: (renderCounter) => editorAPI.onRenderDone(renderCounter),
    refreshEditingAreaPosition: (editingAreaRect) =>
      editorAPI.refreshEditingAreaPosition(editingAreaRect),
    setSecondaryLeftAreaWidth: (newWidth): void =>
      dispatch(secondaryLeftAreaActions.setWidth(newWidth)),
    hideLayersPanel: () => dispatch(hideLayersPanel()),
    previewResizeStart: () => dispatch(setPreviewPointerEventsOn(false)),
    previewResizeEnd: () => dispatch(setPreviewPointerEventsOn(true)),
    setIsWixCodeIDELoaded: editorAPI.wixCode.setCodeEditorLoaded,
    toggleMeasureMaps: async () => {
      const shouldWaitForWixCodeIDEToEnableMeasureMaps = experiment.isOpen(
        'se_waitForWixCodePanelToEnableMeasureMap',
      );
      const measureMapsAlreadyDisabled =
        experiment.isOpen('se_disableMeasureMapsInZoom') &&
        editorAPI.zoomMode.isInZoomMode();

      if (
        !shouldWaitForWixCodeIDEToEnableMeasureMaps ||
        measureMapsAlreadyDisabled
      ) {
        return;
      }

      editorAPI.documentMode.enableShouldUpdateJsonFromMeasureMap(false);
      try {
        if (!editorAPI.wixCode.isCodeEditorLoaded()) {
          await new Promise((resolve) =>
            editorAPI.wixCode.registerToCodeEditorLoaded(() =>
              // to make sure the panel is already maximized
              setTimeout(resolve, 500),
            ),
          );
        }
        editorAPI.documentMode.enableShouldUpdateJsonFromMeasureMap(true);
      } catch (error) {
        editorAPI.documentMode.enableShouldUpdateJsonFromMeasureMap(true);
      }
    },
  };
};
