const AddPanelSectionTypes = {
  THEME: "SECTIONS_TYPES_THEME",
  PERSONALIZED: "SECTIONS_TYPES_PERSONALIZED",
  PRESET: "SECTIONS_TYPES_PRESET",
  PRESET_LIST: "SECTIONS_TYPES_PRESET_LIST",
  IMAGE: "SECTIONS_TYPES_IMAGE",
  DATA: "SECTIONS_TYPES_DATA",
  GENERIC_LIST_SECTION: "SECTIONS_TYPES_GENERIC_LIST_SECTION",
  GENERAL: "SECTIONS_TYPES_GENERAL",
  WELCOME: "SECTIONS_TYPES_WELCOME",
  PAGE: "ADD_PAGES_SECTION",
  RESPONSIVE_PRESET_MANAGER: "RESPONSIVE_PRESET_MANAGER",
  DYNAMIC_MEDIA_SECTIONS_BOX: "DYNAMIC_MEDIA_SECTIONS_BOX",
  DESIGN_DATA_PRESET: "SECTIONS_TYPES_DESIGN_DATA_PRESET",
  DESIGN_DATA_THEME_PRESET: "SECTIONS_TYPES_DESIGN_DATA_THEME_PRESET",
  PERSONALIZED_DESIGN: "SECTIONS_TYPES_PERSONALIZED_DESIGN",
  ACCOUNT_SETTINGS_LIST: "ACCOUNT_SETTINGS_LIST",
  TOGGLES_LIST: "TOGGLES_LIST",
};

const DataSectionClickActions = {
  ADD_COLLECTION: "add_collection",
  ADD_DATASET: "add_dataset",
  ADD_FORM_DATASET: "add_form_dataset",
  ADD_RICHCONTENT: "add_richcontent",
  ADD_PRESET: "add_preset",
  ADD_DP_DATASET: "add_dp_dataset",
  INSTALL_APP_AND_NOTIFY: "install_app_and_notify",
};

export const CATEGORIES_ID = {
  ALL: "all",
  POPUP: "popup",
  TEXT: "text",
  IMAGE: "image",
  IMAGE_DYNAMIC: "imageDynamic",
  GALLERY: "gallery",
  BUTTON: "button",
  BOX: "box",
  CONTAINER: "container",
  EMBEDS: "embeds",
  DECORATIVE: "decorative",
  NAVIGATION: "navigation",
  MEDIA: "media",
  STRIP: "strip",
  SHAPE: "shape",
  VECTOR_SHAPE: "vectorShape",
  VECTOR_ART: "vectorArt",
  VIDEO: "video",
  MUSIC: "music",
  SOCIAL: "social",
  CONTACT: "contact",
  MENU: "menu",
  LIST: "list",
  BLOG: "blog",
  NEW_BLOG: "newBlog",
  NEW_BLOG_WELCOME: "newBlogWelcome",
  ONLINE_PROGRAMS: "onlinePrograms",
  ONLINE_PROGRAMS_WELCOME: "onlineProgramsWelcome",
  BOOKINGS: "bookings",
  BOOKINGS_WELCOME: "bookingsWelcome",
  STORE: "store",
  MEMBERS: "members",
  MEMBERS_WELCOME: "membersWelcome",
  EVENTS: "events",
  EVENTS_WELCOME: "eventsWelcome",
  COMMUNITY: "community",
  OTHER: "other",
  PAGE: "page",
  COMPOSITES: "composites",
  BOX_SLIDE_SHOW: "boxSlideShow",
  DEVELOPER: "developer",
  REPEATER: "repeater",
  DATABASE: "database",
  DATABASE_WELCOME: "databaseWelcome",
  INSTALLED_APPS: "installedApps",
  SAVED_COMPONENTS: "savedComponents",
  SAVED_COMPONENTS_WELCOME: "savedComponentsWelcome",
};
export const CATEGORY_DIVIDER = "category-divider";
export const CATEGORY_DIVIDERS = {
  VERTICALS_DIVIDER: "verticalsDivider",
  MY_ELEMENTS_WIDGETS_DIVIDER: "myElementsWidgetDivider",
};
export const VALUE_OF_ATTR_FOR_EACH_CATEGORY_ATTR = {
  label: "string",
  title: "string",
  help: {
    hide: "bool",
    link: "string",
    title: "string",
    text: "string",
    linkText: "string",
    openFirstTime: "bool",
  },
  teaser: {
    id: "string",
    hide: "bool",
    title: "string",
    text: "string",
    linkText: "string",
    image: "string",
    other: "string",
  },
};
export const SECTION_HEADER = "sectionHeader";
export const SECTION_DIVIDER = "sectionDivider";
export const SECTIONS_STRUCTURE = {
  GENERAL_SECTION_STRUCTURE: {
    type: "SECTIONS_TYPES_GENERAL",
    hide: false,
    title: "add_section_label_relatedapps",
    id: "add_section_relatedapps",
    sectionName: "relatedAppsSection",
    presetTitle: "add_section_label_relatedapps",
    subNavigationTitle: "add_section_label_relatedapps",
    tooltipTitle: "add_section_label_relatedapps",
    subNavigationHide: true,
    showSectionHeader: true,
    props: {
      template: "",
    },
    help: {
      hide: true,
      text: "container_section_general_info_text",
      linkText: "container_section_general_info_link_text",
      link: "",
      image: "",
      video: "",
      openFirstTime: false,
    },
    teaser: {
      id: "container_section_general_teaser",
      hide: false,
      title: "container_section_general_teaser_title",
      text: "container_section_general_teaser_text",
      linkText: "container_section_general_teaser_link_text",
      image: "",
      video: "",
    },
  },
};
export const CATEGORY_VIEW = {
  TOTAL_WIDTH: 352,
};
export const ADD_PANEL = {
  LIVE_COMPONENT_CATEGORY_CONTENT: 304,
  LIVE_COMPONENT_SECTION_MARGIN: 24,
  SECTION_HEADER_HEIGHT: 25,
};
export const DESIGN_PANEL = {
  LIVE_COMPONENT_CATEGORY_CONTENT: 240,
  LIVE_COMPONENT_SECTION_MARGIN: 24,
  SECTION_HEADER_HEIGHT: 25,
};
export const PANEL_TYPES = {
  ADD_PANEL: "ADD_PANEL",
  DESIGN_PANEL: "DESIGN_PANEL",
};
export const SECTIONS_TYPES = AddPanelSectionTypes;
export const STYLABLE_PRESET_TYPES = {
  STATIC: "static",
  THEME: "theme",
  USER: "user",
};
export const LABEL_BEHAVIOUR = {
  ALWAYS: "always",
  HOVER: "hover",
  NONE: "none",
};
export const CLICK_ACTION = {
  ADD_BLANK_PAGE: "add_blank_page",
  ADD_COMP: "add_comp",
  MEDIA_GALLERY_AUDIO: "media_gallery_audio",
  DATA: DataSectionClickActions,
  EVENT: {
    CORVID_DEV_MODE_TOGGLED: "corvid_dev_mode_toggled",
    APP_CLICKED: "data_section_app_clicked",
    WELCOME_SECTION_MAIN_ACTION_CLICKED: "welcome_section_main_action_clicked",
    WELCOME_SECTION_SECONDARY_ACTION_CLICKED:
      "welcome_section_secondary_action_clicked",
  },
  TOGGLE_CORVID_DEV_MODE: "toggle_corvid_dev_mode",
};
export const INFO_ICON_BEHAVIOUR = {
  HOVER: "hover",
  NONE: "none",
};
export const HOVER_IMAGE_ACTIONS = {
  IMAGE: "image",
  SCALE: "scale",
  NONE: "none",
  VIDEO: "video",
};
export const SECTION_FOOTER_ACTIONS = {
  OPEN_ORGANIZE_IMAGES: "open organize Images",
  OPEN_SOCIAL_SETTINGS: "open social Settings",
  OPEN_APP_STORE: "open app store",
};
export const DEFAULT_SCALE_FOR_PRESET = 1.05;
export const DESIGN_PANEL_SCALE = 0.78;
export const DESIGN_PANEL_SCALE_NARROW = 0.75;
export const LABEL_FONT_SIZE = 12;
export const SECTION_NUMBERS = {
  EVENTS: {
    other: 5,
    contact: 6,
  },
  CHAT: {
    other: 4,
    contact: 3,
  },
  SEARCH: {
    other: 0,
  },
};
export const APP_TYPE = {
  PLATFORM: "PLATFORM",
  TPA_APP: "TPA_APP",
  TPA_WIDGET: "TPA_WIDGET",
};
