'use strict'

module.exports = {
    'header': {
        'id': 'site_header_15',
        'structure': {
            'type': 'Container',
            'components': [],
            'skin': 'wysiwyg.viewer.skins.screenwidthcontainer.DoubleBorderScreen',
            'layout': {
                'width': 980,
                'height': 180,
                'x': 0,
                'y': 0,
                'scale': 1,
                'rotationInDegrees': 0,
                'anchors': [{
                    'distance': 0,
                    'topToTop': 180,
                    'originalValue': 180,
                    'type': 'BOTTOM_TOP',
                    'locked': true,
                    'targetComponent': 'PAGES_CONTAINER'
                }],
                'fixedPosition': false
            },
            'componentType': 'wysiwyg.viewer.components.HeaderContainer',
            'style': {
                'type': 'TopLevelStyle',
                'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                'style': {
                    'properties': {
                        'alpha-bg': '1',
                        'alpha-brd': '1',
                        'alpha-brd2': '1',
                        'bg': '#DBB89A',
                        'bgPosition': '0',
                        'bordersPosition': '9px',
                        'boxShadowToggleOn-shd': 'false',
                        'brd': '#FFFFFF',
                        'brd2': '#FFFFFF',
                        'innerLineSize': '2px',
                        'lineGap': '5',
                        'outerLineSize': '0px',
                        'shd': '0px 0px 5px 0px rgba(0,0,0,0.7)'
                    },
                    'propertiesSource': {
                        'bg': 'value',
                        'bgPosition': 'value',
                        'bordersPosition': 'value',
                        'brd': 'value',
                        'brd2': 'value',
                        'innerLineSize': 'value',
                        'lineGap': 'value',
                        'outerLineSize': 'value',
                        'shd': 'value'
                    },
                    'groups': {}
                },
                'componentClassName': 'wysiwyg.viewer.components.HeaderContainer',
                'pageId': '',
                'compId': 'SITE_HEADER',
                'styleType': 'custom',
                'skin': 'wysiwyg.viewer.skins.screenwidthcontainer.DoubleBorderScreen'
            }
        },
        'preset': {'rect': {'width': 324, 'height': 90, 'x': 0, 'y': 1260}}
    },
    'footer': {
        'id': 'site_footer_15',
        'structure': {
            'type': 'Container',
            'components': [],
            'skin': 'wysiwyg.viewer.skins.screenwidthcontainer.DoubleBorderScreen',
            'layout': {
                'width': 980,
                'height': 180,
                'x': 0,
                'y': 681,
                'scale': 1,
                'rotationInDegrees': 0,
                'anchors': [{
                    'distance': 0,
                    'topToTop': 681,
                    'originalValue': 0,
                    'type': 'BOTTOM_PARENT',
                    'locked': true,
                    'targetComponent': 'masterPage'
                }],
                'fixedPosition': false
            },
            'componentType': 'wysiwyg.viewer.components.FooterContainer',
            'style': {
                'type': 'TopLevelStyle',
                'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                'style': {
                    'properties': {
                        'alpha-bg': '1',
                        'alpha-brd': '1',
                        'alpha-brd2': '1',
                        'bg': '#DBB89A',
                        'bgPosition': '0px',
                        'bordersPosition': '0px',
                        'boxShadowToggleOn-shd': 'false',
                        'brd': '#FFFFFF',
                        'brd2': '#FFFFFF',
                        'innerLineSize': '2px',
                        'lineGap': '14px',
                        'outerLineSize': '0px',
                        'shd': '0px 0px 5px 0px rgba(0,0,0,0.7)'
                    },
                    'propertiesSource': {
                        'bg': 'value',
                        'bgPosition': 'value',
                        'bordersPosition': 'value',
                        'brd': 'value',
                        'brd2': 'value',
                        'innerLineSize': 'value',
                        'lineGap': 'value',
                        'outerLineSize': 'value',
                        'shd': 'value'
                    },
                    'groups': {}
                },
                'componentClassName': 'wysiwyg.viewer.components.FooterContainer',
                'pageId': '',
                'compId': 'SITE_FOOTER',
                'styleType': 'custom',
                'skin': 'wysiwyg.viewer.skins.screenwidthcontainer.DoubleBorderScreen'
            }
        },
        'preset': {'rect': {'width': 324, 'height': 90, 'x': 0, 'y': 1260}}
    },
    'index': 15
}
