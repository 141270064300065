// @ts-nocheck
import React from 'react';
import * as util from '@/util';
import { translate } from '@/i18n';
import * as baseUI from '@/baseUI';
import { Composites, Illustration, RichText } from '@wix/wix-base-ui';
import * as panels from '@/panels';
import constants from '@/constants';
import * as stateManagement from '@/stateManagement';

interface CantMakeChangeMobileDispatchProps {
  onDontShowAgainChange: (value: boolean) => void;
}

interface CantMakeChangeMobileProps extends CantMakeChangeMobileDispatchProps {
  panelName: string;
}

const dontShowAgainKey =
  constants.USER_PREFS.CANT_MAKE_CHANGES
    .MOBILE_CANT_MAKE_CHANGE_DONT_SHOW_AGAIN;

class CantMakeChangeMobile extends React.Component<CantMakeChangeMobileProps> {
  static displayName = 'cantMakeChangeMobile';

  render() {
    return (
      <panels.frames.OldMessagePanelFrame
        ref="frame"
        key="cantMakeChangesOnMobile"
        panelName={this.props.panelName}
        panelTitle={translate('Notification_Box_Changes_Mobile_Title')}
        confirmLabel="Notification_Box_Got_It"
        dontShowAgainKey={dontShowAgainKey}
        onDontShowAgainChange={this.props.onDontShowAgainChange}
        userPrefType="site"
        className="mobile-cant-make-changes"
      >
        <Composites.RichTextWithIllustration>
          <Illustration>
            <baseUI.symbol name="plaster" />
          </Illustration>
          <Composites.RichText>
            <RichText type="T01">
              {translate('Notification_Box_Changes_Mobile_Text')}
            </RichText>
          </Composites.RichText>
        </Composites.RichTextWithIllustration>
      </panels.frames.OldMessagePanelFrame>
    );
  }
}

const mapDispatchToProps = (dispatch): CantMakeChangeMobileDispatchProps => ({
  onDontShowAgainChange: (value: boolean) => {
    dispatch(
      stateManagement.userPreferences.actions.setSiteUserPreferences(
        dontShowAgainKey,
        value,
      ),
    );
  },
});

export default util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  null,
  mapDispatchToProps,
)(CantMakeChangeMobile);
