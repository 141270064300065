._inputSuffix_zfinr_1, ._container_zfinr_1 ._clearButton_zfinr_1, ._container_zfinr_1 ._loader_zfinr_1 {
  position: absolute;
  top: 18px;
  right: 22px;
  bottom: 18px;
  cursor: pointer; }

._container_zfinr_1 {
  position: relative;
  box-shadow: 0 1px 0 #d9e1e8; }
  ._container_zfinr_1 ._clearButton_zfinr_1 {
    line-height: 0;
    background: none;
    border: none;
    padding: 0;
    margin: 0; }
    ._container_zfinr_1 ._clearButton_zfinr_1 svg {
      width: 7px;
      height: 7px; }
  ._container_zfinr_1 ._loader_zfinr_1 {
    width: 9px; }
  ._container_zfinr_1 .input-container .input {
    width: 100%;
    height: 54px;
    border: solid 1px transparent;
    line-height: 22px;
    padding: 6px 59px 6px 19px;
    border-radius: 0; }
    ._container_zfinr_1 .input-container .input:hover:not(:focus) {
      background-color: #eaf7ff; }
      ._container_zfinr_1 .input-container .input:hover:not(:focus)::placeholder {
        color: #b6c1cd; }
    ._container_zfinr_1 .input-container .input:focus {
      background-color: #ffffff;
      border: solid 1px transparent;
      box-shadow: none; }
