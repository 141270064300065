// @ts-nocheck
import _ from 'lodash';
import experiment from 'experiment';
import { translate } from '@/i18n';
import * as superAppMenuInfra from '@/superAppMenuInfra';
import constants from '@/constants';
import coreUtilsLib from 'coreUtilsLib';
import * as pagesMenu from '@/pagesMenu';
import * as stateManagement from '@/stateManagement';
import * as util from '@/util';
import * as addBlog from '../addPanel/addBlog';
import blogCompsDisplayNameMap from './blogCompsDisplayNameMap';
import blogAppPartsHelpIdsMap from './blogAppPartsHelpIdsMap';
import * as newBlogBi from '../newBlogBi/newBlogBi';

import type { CompRef } from 'types/documentServices';
import type { EditorAPI } from '@/editorAPI';

interface MigrationSlides {
  quickMigrationSuccessSlide: Object;
  quickMigrationFailureSlide: Object;
  newBlogMagicMigrationSlide?: Object;
  magicMigrationSuccessSlide?: Object;
  magicMigrationFailureSlide?: Object;
}

const { blogAppPartNames } = coreUtilsLib;

const migrationStatuses = {
  notStarted: 'NOT_STARTED',
  contentSyncStarted: 'CONTENT_SYNC_STARTED',
  contentSyncFailed: 'CONTENT_SYNC_FAILED',
  contentSyncComplete: 'CONTENT_SYNC_COMPLETE',
  migrationDone: 'REDIRECTS_SET_COMPLETE',
};
// Manual blog migration in the editor. Started 1 Sep 2020
const magicMigrationStatuses = {
  notStarted: 'NOT_STARTED',
  started: 'MAGIC_MIGRATION_STARTED',
  contentSyncComplete: 'MAGIC_MIGRATION_CONTENT_SYNCED',
  failed: 'MAGIC_MIGRATION_FAILED',
  done: 'MAGIC_MIGRATION_SUCCEEDED',
};
const superAppId = 'blog';
const superAppPanelName = constants.SUPER_APPS.BLOG_PANEL_NAME;
const superAppButtonLabel = 'SiteApp_Blog_Button_Hover_Caption';
const superAppButtonLabelOnlyOld = 'SiteApp_OldBlog_Button_Hover_Caption_Old';
const superAppButtonLabelWhenMigration =
  'SiteApp_OldBlog_Button_Hover_Caption_Migration';
const superAppButtonPromotionalLabel =
  'SiteApp_Blog_Button_Promotional_Hover_Caption';
const superAppPanelClassName = 'blog-panel';
const superAppSymbolName = util.workspace.isNewWorkspaceEnabled()
  ? 'leftBarButtonBlog'
  : 'leftBarBlogPanel';
let isAddingBlog = false;

const newBlogAppDefId = '14bcded7-0066-7c35-14d7-466cb3f09103';
const newSuperAppId = 'newBlog';
const newSuperAppPanelName = constants.SUPER_APPS.NEW_BLOG_PANEL_NAME;
const newSuperAppPanelClassName = 'new-blog-panel';
const newSuperAppSymbolName = util.workspace.isNewWorkspaceEnabled()
  ? 'leftBarButtonBlog'
  : 'leftBarNewBlogPanel';
const newSuperAppButtonLabel = 'SiteApp_NewBlog_Button_Hover_Caption';
const newSuperAppButtonLabelBeforeInstall =
  'SiteApp_NewBlog_FTUE_Button_Hover_Caption';
const newSuperAppButtonMigrationGetBlog =
  'SiteApp_NewBlog_Button_Hover_Caption_GetNewBlog';
const newSuperAppButtonMigrationNewBlog =
  'SiteApp_NewBlog_Button_Hover_Caption_Migration';
const newSuperAppButtonMagicMigrationNewBlog =
  'SiteApp_NewBlog_Migration_Button_Hover_Caption_UpdateYourBlog';

const FAKE_LOADER_TIMEOUT = 2000;

const SHOW_QUICK_MIGRATION_PROMO_KEY = 'wix_blog_showQuickMigrationPromo';
const QUICK_MIGRATION_FINISHED_KEY = 'wix_blog_quickMigrationFinished';
const MAGIC_MIGRATION_FINISHED_KEY = 'wix_blog_magicMigrationFinished';

const helpArticles = {
  customizeFeed: '2ce51957-d54f-4e7d-8221-108bc98b6cf3',
  deleteOldBlog: 'ac456f04-ed22-4826-894a-3ab31ff25995',
  twoStepMigration: 'fb45e3c5-bcf6-442b-93dc-697d7f1099fb',
  switchToNewBlog: '6f26af10-0e5b-44e8-a92f-f89d424822fe',
  troubleshootMigration: '9f18a6c7-d227-422c-a009-1d0c341466ed',
  aboutNewBlog: '8e47e955-1d8b-4c7d-9fe2-6fc0e31de896',
};

function registerBlogButton(editorAPI: EditorAPI, options?) {
  const can6thButtonBeRegistered = superAppMenuInfra.button.canBeRegistered(
    editorAPI,
    {
      appDefId: newBlogAppDefId,
    },
  );

  if (isNewBlogMagicMigrationEnabled() || !can6thButtonBeRegistered) {
    return;
  }

  const label =
    options?.newBlogDeletion || !siteHasNewBlog(editorAPI)
      ? superAppButtonLabelOnlyOld
      : superAppButtonLabelWhenMigration;
  superAppMenuInfra.button.registerButton(
    editorAPI,
    superAppId,
    siteHasOldBlog(editorAPI),
    superAppPanelName,
    label,
    superAppButtonPromotionalLabel,
    superAppPanelClassName,
    superAppSymbolName,
  );
}

function getCompName(editorAPI: EditorAPI, compRef: CompRef) {
  const compData = editorAPI.components.data.get(compRef);
  return translate(blogCompsDisplayNameMap[compData.appPartName]);
}

function addBlogAndRegisterButton(editorAPI, viewName, onComplete) {
  const currentPage = editorAPI.dsRead.pages.getFocusedPageId();
  if (!isAddingBlog && !siteHasOldBlog(editorAPI)) {
    setIsAddingState(true);
    addBlog.addBlogPage(editorAPI, viewName, 'add_panel', function (pageId) {
      superAppMenuInfra.button.onAppInstalled(
        editorAPI,
        superAppId,
        superAppPanelName,
        superAppButtonLabel,
        superAppPanelClassName,
        superAppSymbolName,
      );

      editorAPI.history.add('adding a blog', { currentPage });
      if (onComplete && _.isFunction(onComplete)) {
        onComplete(pageId);
      }
      setIsAddingState(false);
    });
  }
}

function setIsAddingState(state) {
  isAddingBlog = state;
}

function siteHasOldBlog(editorAPI: EditorAPI) {
  return (
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/size
    _.size(editorAPI.dsRead.wixapps.classics.getAllAppPartComps('blog')) > 0
  );
}

function siteHasOldBlogDeprecationComponents(editorAPI: EditorAPI) {
  return !!editorAPI.components
    .getAllComponents_DEPRECATED_BAD_PERFORMANCE()
    .map(editorAPI.documentServices.components.getType)
    .filter((type) => type === 'wixui.OldBlogDeprecation').length;
}

function getBlogPageId(editorAPI: EditorAPI) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/find
  return _.find(
    editorAPI.dsRead.pages.getPageIdList(),
    function isBlogPage(pageId) {
      const pageData = editorAPI.dsRead.pages.data.get(pageId);
      //TODO: menuItemsRepository has this same logic. Should be refactored into a function in blog package.
      return pageData.appInnerID
        ? editorAPI.dsRead.wixapps.classics.getPackageName(
            pageData.appInnerID,
          ) === 'blog'
        : false;
    },
  );
}

function getBlogMainAppPartCompRef(editorAPI, pageId) {
  const blogPageCompRef = editorAPI.components.get.byId(pageId);
  const blogPageChildrenRefs =
    editorAPI.components.getChildren(blogPageCompRef);

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/find
  return _.find(blogPageChildrenRefs, function (appPartRef) {
    const appPartData = editorAPI.components.data.get(appPartRef);

    // Return the blog's main app part (compare to a constant guid)
    return appPartData && appPartData.appPartName === blogAppPartNames.FEED;
  });
}

function removeBlogButton(editorAPI: EditorAPI) {
  editorAPI.selection.deselectComponents();
  superAppMenuInfra.button.unRegisterButton(editorAPI, superAppPanelName);
}

function registerHooks(editorAPI: EditorAPI) {
  editorAPI.dsActions.wixapps.classics.registerHooks();
}

/**
 *
 * @param {string} appPartName UUID
 * @param {string} platformType (DESKTOP|MOBILE)
 * @param {string} helpType (design|help|layout|settings)
 * @returns {*}
 */
function getAppPartHelpId(appPartName, platformType, helpType) {
  const DEFAULT_APP_PART_HELP_ID = 'c3dd9067-87f2-4044-93b4-f491b35580f6';

  if (!helpType) {
    helpType = 'help';
  }

  if (blogAppPartsHelpIdsMap[appPartName]) {
    if (blogAppPartsHelpIdsMap[appPartName][platformType]) {
      if (blogAppPartsHelpIdsMap[appPartName][platformType][helpType]) {
        return blogAppPartsHelpIdsMap[appPartName][platformType][helpType];
      }
    }
  }
  return DEFAULT_APP_PART_HELP_ID;
}

function siteHasNewBlog(editorAPI: EditorAPI): boolean {
  return editorAPI.tpa.app.isInstalled(newBlogAppDefId);
}

function registerNewBlogButton(editorAPI: EditorAPI, options?) {
  const can6thButtonBeRegistered = superAppMenuInfra.button.canBeRegistered(
    editorAPI,
    {
      appDefId: newBlogAppDefId,
    },
  );

  if (!can6thButtonBeRegistered) {
    return;
  }

  const hasNewBlog = siteHasNewBlog(editorAPI);
  const hasOldBlog = siteHasOldBlog(editorAPI) && !options?.oldBlogDeletion;
  let label = null;
  if (hasOldBlog) {
    const migrationLabel = isNewBlogMagicMigrationEnabled()
      ? newSuperAppButtonMagicMigrationNewBlog
      : newSuperAppButtonMigrationNewBlog;
    label =
      options?.newBlogDeletion || !hasNewBlog
        ? newSuperAppButtonMigrationGetBlog
        : migrationLabel;
  } else {
    label = hasNewBlog
      ? newSuperAppButtonLabel
      : newSuperAppButtonLabelBeforeInstall;
  }

  superAppMenuInfra.button.registerButton(
    editorAPI,
    newSuperAppId,
    hasNewBlog,
    newSuperAppPanelName,
    label,
    label,
    newSuperAppPanelClassName,
    newSuperAppSymbolName,
    false,
    { [newSuperAppId]: options?.isExpanded ?? false },
  );
}

function unregisterNewBlogButton(editorAPI: EditorAPI) {
  superAppMenuInfra.button.unRegisterButton(editorAPI, newSuperAppPanelName);
}

function onNewBlogInstalled(editorAPI: EditorAPI) {
  const can6thButtonBeRegistered = superAppMenuInfra.button.canBeRegistered(
    editorAPI,
    {
      appDefId: newBlogAppDefId,
    },
  );

  if (!can6thButtonBeRegistered) {
    return;
  }

  let label;

  if (!siteHasOldBlog(editorAPI)) {
    label = newSuperAppButtonLabel;
  } else {
    label = isNewBlogMagicMigrationEnabled()
      ? newSuperAppButtonMagicMigrationNewBlog
      : newSuperAppButtonMigrationNewBlog;
  }

  superAppMenuInfra.button.onAppInstalled(
    editorAPI,
    newSuperAppId,
    newSuperAppPanelName,
    label,
    newSuperAppPanelClassName,
    newSuperAppSymbolName,
  );
}

function addNewBlog(editorAPI: EditorAPI, options, callback?) {
  return import('@/tpa').then((tpa) => {
    tpa.superApps.addApp(newBlogAppDefId, {
      origin: options?.origin,
      showProgressBar: true,
      platformOrigin: options?.platformOrigin,
      callback(data) {
        if (_.isError(data)) {
          callback({ error: true });
        } else {
          const pageId = data?.page?.id;
          if (pageId) {
            editorAPI.pages.navigateTo(pageId, () => {
              const sectionPointer =
                tpa.superApps.getSectionRefByPageId(pageId);
              editorAPI.selection.selectComponentByCompRef(sectionPointer);
            });
          }
          _.attempt(callback);
        }
      },
    });
  });
}

function openNewBlogInAppMarket(editorAPI: EditorAPI) {
  import('@/tpa').then((tpa) => {
    const appMarketPanelProps = {
      urlParams: {
        slug: newBlogAppDefId,
      },
    };

    tpa.appMarketTabService.openAppMarketTab(
      appMarketPanelProps,
      editorAPI.panelManager.openPanel,
    );
  });
}

const getPageAppDefId = (editorAPI: EditorAPI, pageData) =>
  editorAPI.dsRead.tpa.section.getPageData(pageData.id)?.appData
    ?.appDefinitionId;

const isBlogPage = (editorAPI: EditorAPI, pageData) =>
  getPageAppDefId(editorAPI, pageData) === newBlogAppDefId;

const getNewBlogPages = (editorAPI: EditorAPI) => {
  const pagesDataList = editorAPI.dsRead.pages.getPagesData();
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/filter
  const blogPagesDataList = _.filter(
    [
      ...pagesMenu.specialPages.tpa.getSectionPagesDataList(
        editorAPI,
        pagesDataList,
      ),
      ...pagesMenu.specialPages.tpa.getSpecialPagesDataList(
        editorAPI,
        pagesDataList,
      ),
    ],
    (pageData) => isBlogPage(editorAPI, pageData),
  );

  return editorAPI.mainMenu.convertPagesDataListToMenuItemsList(
    blogPagesDataList,
  );
};

const isNewBlogQuickMigrationEnabled = () =>
  experiment.isOpen('se_newBlogQuickMigration');

const isNewBlogMagicMigrationEnabled = () =>
  experiment.isOpen('newBlogMagicMigration');

const shouldShowOldBlogMigrationPromo = (editorAPI: EditorAPI) =>
  (isNewBlogQuickMigrationEnabled() || isNewBlogMagicMigrationEnabled()) &&
  siteHasOldBlog(editorAPI) &&
  !siteHasNewBlog(editorAPI) &&
  window.localStorage.getItem(
    getLocalStorageKey(SHOW_QUICK_MIGRATION_PROMO_KEY, editorAPI),
  ) !== 'false';

const markBlogMigrationPromoAsSeen = (
  editorAPI: EditorAPI,
  sendBi,
  biParams,
) => {
  sendBi(
    newBlogBi.events.BLOG_QUICK_MIGRATION_ACTION,
    Object.assign({}, biParams, { instance_id: null }),
  );
  window.localStorage.setItem(
    getLocalStorageKey(SHOW_QUICK_MIGRATION_PROMO_KEY, editorAPI),
    'false',
  );
  editorAPI.panelManager.updatePanelProps(superAppPanelName);
};

const getLocalStorageKey = (baseKey, editorAPI) => {
  const appData =
    editorAPI.dsRead.platform.getAppDataByAppDefId(newBlogAppDefId);
  return appData ? `${baseKey}-${appData.instanceId}` : baseKey;
};

const markQuickMigrationAsSeen = (editorAPI: EditorAPI) => {
  window.localStorage.setItem(
    getLocalStorageKey(QUICK_MIGRATION_FINISHED_KEY, editorAPI),
    'false',
  );
  editorAPI.panelManager.updatePanelProps(newSuperAppPanelName);
  editorAPI.panelManager.updatePanelProps(superAppPanelName);
};

const markMagicMigrationAsSeen = (editorAPI: EditorAPI) => {
  window.localStorage.setItem(
    getLocalStorageKey(MAGIC_MIGRATION_FINISHED_KEY, editorAPI),
    'false',
  );
  editorAPI.panelManager.updatePanelProps(newSuperAppPanelName);
  editorAPI.panelManager.updatePanelProps(superAppPanelName);
};

const onMigrationStatusGet = (editorAPI, promoSlide) => {
  const newBlogFirstTimeTourKey = `${newSuperAppId}/in_first_time_tour`;
  editorAPI.store.dispatch(
    stateManagement.userPreferences.actions.setSiteUserPreferences(
      newBlogFirstTimeTourKey,
      'true',
    ),
  );

  if (promoSlide) {
    const newBlogLastSeenSlideKey = `${newSuperAppId}/first_time_tour_last_seen_slide`;
    editorAPI.store.dispatch(
      stateManagement.userPreferences.actions.setSiteUserPreferences(
        newBlogLastSeenSlideKey,
        promoSlide,
      ),
    );
  }
};

const removeLocalStorageMigrationKeys = (editorAPI: EditorAPI) => {
  window.localStorage.removeItem(
    getLocalStorageKey(QUICK_MIGRATION_FINISHED_KEY, editorAPI),
  );
  window.localStorage.removeItem(
    getLocalStorageKey(SHOW_QUICK_MIGRATION_PROMO_KEY, editorAPI),
  );
  window.localStorage.removeItem(SHOW_QUICK_MIGRATION_PROMO_KEY);
};

const setMigrationStatusSlide = (editorAPI: EditorAPI, promoSlide = null) => {
  const appData =
    editorAPI.dsRead.platform.getAppDataByAppDefId(newBlogAppDefId);
  if (!appData || !appData.instance) {
    return Promise.resolve();
  }
  return onMigrationStatusGet(editorAPI, promoSlide);
};

const shouldFetchMigrationStatus = (editorAPI: EditorAPI) => {
  if (isNewBlogMagicMigrationEnabled()) {
    return true;
  }

  return (
    isNewBlogQuickMigrationEnabled() &&
    !!siteHasOldBlog(editorAPI) &&
    !!siteHasNewBlog(editorAPI)
  );
};

const startQuickMigration = (
  editorAPI: EditorAPI,
  sendBi,
  biParams,
  callback?: () => void,
) => {
  sendBi(
    newBlogBi.events.BLOG_QUICK_MIGRATION_ACTION,
    Object.assign({}, biParams, { instance_id: null }),
  );
  addNewBlog(
    editorAPI,
    { origin: editorAPI.tpa.bi.consts.BI.type.ADD_APP_APP_BUTTON },
    () => {
      removeLocalStorageMigrationKeys(editorAPI);
      setMigrationStatusSlide(editorAPI);
      onNewBlogInstalled(editorAPI);
      callback?.();
    },
  );
};

const createFakeMigrationLoader = (editorAPI: EditorAPI) => {
  let currentStep = 1;
  editorAPI.panelHelpers.openProgressBar({
    title: translate('NewBlog_Migration_Loader_Title'),
    totalSteps: 5,
    currentStep: 1,
    taskDisplayName: translate('NewBlog_Migration_Loader_Text1'),
  });

  return {
    step: (step) => {
      if (currentStep >= step) {
        return Promise.resolve();
      }

      currentStep = step;
      return new Promise((res) => {
        setTimeout(() => {
          editorAPI.panelHelpers.updateProgressBar(
            step,
            translate(`NewBlog_Migration_Loader_Text${step}`),
          );
          res(step);
        }, FAKE_LOADER_TIMEOUT);
      });
    },
    close: () => {
      setTimeout(
        () => editorAPI.panelHelpers.closeProgressBar(),
        FAKE_LOADER_TIMEOUT,
      );
    },
  };
};

const startMagicMigration = (
  editorAPI: EditorAPI,
  sendBi?: FunctionFixMe,
  biParams?: Object,
  shouldAddNewBlog?: boolean = true,
) => {
  const progressBar = createFakeMigrationLoader(editorAPI);
  progressBar
    .step(2)
    .then(() => progressBar.step(3))
    .then(() => progressBar.step(4));

  if (sendBi) {
    sendBi(
      newBlogBi.events.BLOG_MAGIC_MIGRATION_MODAL_ACTION,
      Object.assign({}, biParams, { instance_id: null }),
    );
  }

  if (shouldAddNewBlog) {
    return addNewBlog(
      editorAPI,
      { origin: editorAPI.tpa.bi.consts.BI.type.ADD_APP_APP_BUTTON },
      () => {
        window.localStorage.removeItem(
          getLocalStorageKey(MAGIC_MIGRATION_FINISHED_KEY, editorAPI),
        );

        setMigrationStatusSlide(editorAPI);
        progressBar.step(5).then(() => progressBar.close());
        return onNewBlogInstalled(editorAPI);
      },
    );
  }

  return editorAPI.documentServices.platform
    .migrate(newBlogAppDefId, { migrationId: 'magicMigration' })
    .then(
      () => {
        window.localStorage.removeItem(
          getLocalStorageKey(MAGIC_MIGRATION_FINISHED_KEY, editorAPI),
        );

        setMigrationStatusSlide(editorAPI);
        return progressBar.step(5).then(progressBar.close());
      },
      () => {
        editorAPI.panelHelpers.closeProgressBar();
        editorAPI.panelManager.openPanel(newSuperAppPanelName);
        return Promise.reject();
      },
    );
};

const getOldBlogPromotionalFlow = (editorAPI: EditorAPI, slides) =>
  shouldShowOldBlogMigrationPromo(editorAPI)
    ? slides.oldBlogMigrationSlide
    : slides.promotionalFlow1;

const getNewBlogPromotionalFlow = (editorAPI: EditorAPI, slides) => {
  const hasOnlyOldBlog =
    siteHasOldBlog(editorAPI) && !siteHasNewBlog(editorAPI);

  if (siteHasOldBlog(editorAPI) && isNewBlogMagicMigrationEnabled()) {
    return slides.newBlogMagicMigrationSlide;
  }

  if (hasOnlyOldBlog && isNewBlogQuickMigrationEnabled()) {
    return slides.newBlogMigrationSlide;
  }

  return slides.firstTimeTour;
};

const getMagicMigrationFlow = (
  editorAPI: EditorAPI,
  migrationStatus: string,
  slides: MigrationSlides,
) => {
  const hasSeenSuccessSlide =
    window.localStorage.getItem(
      getLocalStorageKey(MAGIC_MIGRATION_FINISHED_KEY, editorAPI),
    ) === 'false';
  const hasFailedMigration =
    migrationStatus === magicMigrationStatuses.failed ||
    migrationStatus === magicMigrationStatuses.contentSyncComplete;

  if (hasFailedMigration) {
    return slides.magicMigrationFailureSlide;
  }

  if (siteHasOldBlog(editorAPI)) {
    return slides.newBlogMagicMigrationSlide;
  }

  if (!hasSeenSuccessSlide && migrationStatus === magicMigrationStatuses.done) {
    return slides.magicMigrationSuccessSlide;
  }
};

const getQuickMigrationFlow = (
  editorAPI: EditorAPI,
  migrationStatus: string,
  slides: MigrationSlides,
  isNewBlog: boolean,
) => {
  const hasFinishedMigration =
    window.localStorage.getItem(
      getLocalStorageKey(QUICK_MIGRATION_FINISHED_KEY, editorAPI),
    ) === 'false';
  if (hasFinishedMigration) {
    return;
  }

  if (migrationStatus === migrationStatuses.migrationDone && isNewBlog) {
    return slides.quickMigrationSuccessSlide;
  }

  if (migrationStatus === migrationStatuses.contentSyncFailed) {
    return slides.quickMigrationFailureSlide;
  }
};

const getMigrationFlow = (
  editorAPI: EditorAPI,
  migrationStatus: string,
  slides: MigrationSlides,
  isNewBlog: boolean,
) => {
  if (isNewBlogMagicMigrationEnabled() && isNewBlog) {
    return getMagicMigrationFlow(editorAPI, migrationStatus, slides);
  }

  return getQuickMigrationFlow(editorAPI, migrationStatus, slides, isNewBlog);
};

const shouldShowDeleteOldBlogPanel = (editorAPI: EditorAPI) => {
  const migrationStatus = stateManagement.blog.selectors.getBlogMigrationStatus(
    editorAPI.store.getState(),
  );
  return (
    isNewBlogQuickMigrationEnabled() &&
    siteHasNewBlog(editorAPI) &&
    migrationStatus === migrationStatuses.migrationDone
  );
};

const showMigrationPopup = (editorAPI: EditorAPI, props: Object = {}) => {
  editorAPI.panelManager.openPanel('blog.panels.blogUpgradePanel', {
    ...props,
  });
};

export {
  newBlogAppDefId,
  superAppId,
  registerHooks,
  getCompName,
  registerBlogButton,
  addBlogAndRegisterButton,
  siteHasOldBlog,
  siteHasOldBlogDeprecationComponents,
  getBlogPageId,
  superAppPanelName,
  superAppButtonLabel,
  superAppPanelClassName,
  superAppSymbolName,
  getBlogMainAppPartCompRef,
  removeBlogButton,
  getAppPartHelpId,
  setIsAddingState,
  newSuperAppId,
  newSuperAppPanelName,
  newSuperAppButtonLabel,
  newSuperAppButtonMigrationNewBlog,
  newSuperAppButtonMagicMigrationNewBlog,
  newSuperAppPanelClassName,
  newSuperAppSymbolName,
  siteHasNewBlog,
  registerNewBlogButton,
  unregisterNewBlogButton,
  onNewBlogInstalled,
  addNewBlog,
  openNewBlogInAppMarket,
  getNewBlogPages,
  isNewBlogQuickMigrationEnabled,
  isNewBlogMagicMigrationEnabled,
  shouldShowOldBlogMigrationPromo,
  markBlogMigrationPromoAsSeen,
  startQuickMigration,
  startMagicMigration,
  migrationStatuses,
  getOldBlogPromotionalFlow,
  getNewBlogPromotionalFlow,
  getMigrationFlow,
  markQuickMigrationAsSeen,
  markMagicMigrationAsSeen,
  setMigrationStatusSlide,
  helpArticles,
  shouldFetchMigrationStatus,
  shouldShowDeleteOldBlogPanel,
  showMigrationPopup,
};
