import { stateMapperArgsSelectors } from '@/stateManagement';
import * as selectionBoxCommons from '../../../selectionBoxCommons';
import { getLayout, getLayoutStyle } from '../../common';

import {
  getIsIndicatorBottom,
  getIsLabelBottomEdit,
} from '../../../../selectors/isLabelBottom';

import type { EditorAPI } from '@/editorAPI';
import type { CompRef } from 'types/documentServices';

const { createSelector } = stateMapperArgsSelectors.selector;
const { editorAPISel, editorAPIMouseSel } = stateMapperArgsSelectors.rootSels;
const { selectedCompsSel, isMultiselectSel } =
  stateMapperArgsSelectors.selectedCompsSels;

export const { _relativeToStructureLayoutSel } =
  stateMapperArgsSelectors.layoutSels;

function getGroupAncestorStyle(
  editorAPI: EditorAPI,
  comps: CompRef[],
  isMultiselect: boolean,
  isGroupedComponent: boolean,
  isShowOnFixedPosition: boolean,
) {
  const groupAncestor =
    !isMultiselect && isGroupedComponent
      ? selectionBoxCommons.findFirstContainerMatchingConditions(
          editorAPI,
          comps,
          [editorAPI.components.is.group],
        )
      : null;

  if (!groupAncestor) {
    return null;
  }

  const containerLayout =
    editorAPI.components.layout.stage.getRelativeToScreen(groupAncestor);
  return selectionBoxCommons.getBoundingStyle(
    isShowOnFixedPosition,
    containerLayout,
    comps,
    1,
  );
}

export const layoutSel = createSelector(
  [editorAPIMouseSel, selectedCompsSel],
  getLayout,
);

export const isShowOnFixedPositionSel = createSelector(
  [editorAPISel, selectedCompsSel],
  (editorAPI, comps) =>
    editorAPI.components.layout.isShowOnFixedPosition(comps),
);

export const boundingStyleSel = createSelector(
  [isShowOnFixedPositionSel, layoutSel, selectedCompsSel],
  (isShowOnFixedPosition, layout, comps) =>
    selectionBoxCommons.getBoundingStyle(
      isShowOnFixedPosition,
      layout,
      comps,
      1,
    ),
);

const isGroupedComponentSel = createSelector(
  [editorAPISel, selectedCompsSel],
  (editorAPI, comps) => editorAPI.components.is.groupedComponent(comps),
);

export const groupAncestorStyleSel = createSelector(
  [
    editorAPIMouseSel,
    selectedCompsSel,
    isMultiselectSel,
    isGroupedComponentSel,
    isShowOnFixedPositionSel,
  ],
  getGroupAncestorStyle,
);

export const showGroupAncestorStyleSel = createSelector(
  [groupAncestorStyleSel],
  Boolean,
);

export const layoutStyleSel = createSelector(
  [editorAPIMouseSel, boundingStyleSel, layoutSel, isMultiselectSel],
  (editorAPI, boundingStyle, layout, isMultiselect) =>
    isMultiselect ? boundingStyle : getLayoutStyle(editorAPI, layout),
);

export const layoutRotationButtonStyleSel = createSelector(
  [layoutSel],
  (layout) => ({
    transform: `rotate(${
      360 - layout.rotationInDegrees
    }deg) translate3d(0,0,0)`,
  }),
);

export const layoutRotationDegreesSel = createSelector([layoutSel], (layout) =>
  Math.floor(layout.rotationInDegrees),
);

export const isIndicatorBottomSel = createSelector(
  [editorAPIMouseSel, selectedCompsSel],
  getIsIndicatorBottom,
);

export const isLabelBottomEditSel = createSelector(
  [editorAPIMouseSel, selectedCompsSel],
  getIsLabelBottomEdit,
); //TODO is calcing twise the same

export const isSmallSel = createSelector(
  [layoutSel],
  (layout) => layout.width <= 50,
);
