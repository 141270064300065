import type { ThunkAction } from 'types/redux';
import type { EditorAPI } from '@/editorAPI';
import {
  getHelpIdByComponentType,
  getModalToOpenOverridesByComponentType,
} from '@/componentDeprecation';
import type { Dispatch, MapDispatchToProps } from 'types/redux';

export const mapStateToProps = (
  { editorAPI }: { editorAPI: EditorAPI },
  props: AnyFixMe,
) => ({
  ...editorAPI,
  isWelcomePopup: props[0]?.origin === 'welcomeScreen',
});

const getEditorAPI: ThunkAction<EditorAPI> = (
  _dispatch,
  _getState,
  { editorAPI }: { editorAPI: EditorAPI },
) => editorAPI;

export interface ComponentDeprecationPanelDispatchProps {}

export interface ComponentDeprecationPanelOwnProps {
  migratedFrom: string;
}

export const mapDispatchToProps: MapDispatchToProps<
  ComponentDeprecationPanelDispatchProps,
  ComponentDeprecationPanelOwnProps
> = (dispatch: Dispatch, ownProps) => {
  const editorAPI: EditorAPI = dispatch(getEditorAPI);
  const overrides = getModalToOpenOverridesByComponentType(
    ownProps.migratedFrom,
  );
  return {
    onHelp: () => {
      editorAPI.panelManager.openHelpCenter(
        getHelpIdByComponentType(ownProps.migratedFrom),
      );
    },
    onContinue: () => {
      if (overrides?.onContinue) {
        overrides.onContinue();
      } else {
        editorAPI.panelManager.openHelpCenter(
          getHelpIdByComponentType(ownProps.migratedFrom),
        );
      }
    },
    onClose: () => {
      editorAPI.panelManager.closeAllPanels();
    },
    contentOverrides: {
      title: overrides?.title,
      mainCtaText: overrides?.mainCtaText,
      description: overrides?.description,
    },
  };
};
