// @ts-nocheck
import _ from 'lodash';

function assignKeyboardShortcutSpecificBI(biParamsToAssign, biParamsToUpdate) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/assign
  _.assign(biParamsToUpdate, biParamsToAssign);
}

/**
 * @param func the function / action / command to invoke
 * @param editorAPI the editorAPI
 * @param biEvent the BI Event
 * @param biParams the BI Event Params
 * @returns {Function} a new function that will send BI and invoke the passed func.
 */
function sendBIAndAction(editorAPI, biEvent, biParams) {
  if (editorAPI && biEvent) {
    const selectedComps = editorAPI.selection.getSelectedComponents();
    const biCompsParams = editorAPI.bi.getComponentsBIParams(selectedComps);
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
    _.forEach(
      biCompsParams,
      assignKeyboardShortcutSpecificBI.bind(null, biParams),
    );
    if (_.isEmpty(biCompsParams) && biParams) {
      biCompsParams.push(biParams);
    }
    editorAPI.bi.reportBI(biEvent, biCompsParams);
  }
}

export default sendBIAndAction;
