import { useState, useEffect, useRef } from 'react';
import type { ISuggestResult, ContentManager } from '@/presetApi';

export const useInputWithInputSuggestions = (
  businessTypeName: string,
  getContentManager: () => ContentManager,
  initiateContentManager: () => Promise<void>,
  shouldGetContentManagerEvenIfDefined?: boolean,
) => {
  const [value, setInternalValue] = useState(businessTypeName);
  const [suggestions, setInputSuggestions] = useState<
    Record<string, ISuggestResult>
  >({});
  const [isDirty, setIsDirty] = useState(false);

  const setValue = (value: string) => {
    setInternalValue(value);
    setIsDirty(true);
  };

  const contentManager = useRef<ContentManager>(getContentManager());
  const request = useRef<Promise<void>>();

  useEffect(() => {
    const initializeContentManager = async () => {
      if (
        contentManager.current === undefined ||
        shouldGetContentManagerEvenIfDefined
      ) {
        contentManager.current = null;
        await initiateContentManager();
        contentManager.current = getContentManager();
      }
    };

    initializeContentManager();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getContentManager, initiateContentManager]);

  useEffect(() => {
    if (!suggestions[value]) {
      const promise = contentManager.current
        ?.suggest(value)
        ?.then((suggestions: ISuggestResult) => {
          if (request.current === promise && value) {
            setInputSuggestions((state) => ({
              ...state,
              [value]: suggestions,
            }));
          }
        });
      request.current = promise;
    }
  }, [value, suggestions]);

  return {
    value,
    isDirty,
    setValue,
    suggestions: suggestions[value],
  };
};
