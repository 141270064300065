// @ts-nocheck
import _ from 'lodash';
import * as tpaStyleService from '../../services/tpaStyleService';

const onStyleChange = function (editorAPI, compId, wixParam, callback, name) {
  let compStyle = editorAPI.dsRead.tpa.style.get(compId) || {};
  const colorStyle = compStyle.style?.colors[wixParam] || {};
  const alpha = getAlphaFromRgba(colorStyle.value);
  const opacity = alpha ? +alpha : 1;

  const data = {
    key: wixParam,
    param: {
      value: {
        opacity,
      },
    },
    type: 'color',
  };

  if (isHexColor(name)) {
    data.param.value.rgba = convertHex(name, opacity);
  } else {
    data.param.value.color = {
      name,
    };
  }

  tpaStyleService.setStyleParam(editorAPI, compId, data);

  editorAPI.dsActions.waitForChangesApplied(function () {
    compStyle = editorAPI.dsRead.tpa.style.get(compId);
    if (compStyle.style?.colors) {
      callback({
        colorParam: compStyle.style.colors[data.key],
      });
    }
  });
};

const onStyleChangeWithoutWixParam = function (callback, nextColor) {
  const colorParam = {};
  const key = isHexColor(nextColor) ? 'value' : 'themeName';
  colorParam[key] = nextColor;
  callback({ colorParam });
};

const isHexColor = function (color) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/is-undefined
  if (_.isUndefined(color)) {
    return false;
  }
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/includes
  if (_.includes(color, '#')) {
    return true;
  }
  return false;
};

const getAlphaFromRgba = function (rgba) {
  const rgbaRegex = /^rgba\(((\d+),\s*){3}(0?\.\d+)\)$/;
  if (rgbaRegex.test(rgba)) {
    const tokens = rgba.split(',');
    const alpha = tokens[3];
    return alpha.split(')')[0];
  }
  return 1;
};

const convertHex = function (hex, opacity) {
  let result;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/includes
  if (_.includes(hex, 'rgba')) {
    const tokens = hex.split(',');
    tokens[3] = `${opacity})`;
    result = tokens.join(',');
  } else {
    hex = hex.replace('#', '');
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    result = `rgba(${r},${g},${b},${opacity})`;
  }

  return result;
};

export { onStyleChangeWithoutWixParam, onStyleChange };
