"use strict"
const addPanelDataConsts = require('@/addPanelDataConsts')
const i18n = require('@/i18n')

module.exports = {
    type: addPanelDataConsts.SECTIONS_TYPES.PRESET,
    hide: false,
    title: "add_section_label_ratings_read",
    subNavigationTitle: "add_section_label_ratings_read",
    topTitle: "add_section_title_ratings_read",
    presetTitle: "add_section_heading_ratings_read",
    tooltipTitle: "add_section_label_ratings_read_tooltip_title",
    automationId: "add-panel-section-ratingsDisplaySection",
    subNavigationHide: true,
    showSectionHeader: true,
    additionalBehaviours: {
        labelMode: addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
        infoIcon: addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
        hoverImageAction: addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
        iconEnabledForComps: {}
    },
    props: {
        image:
        "addPanelData/sections/developer_en/ratingsDisplaySection_en.png",
        imageHover: null,
        items: [
            {
                id: "User_Input_RatingsDisplay_1",
                structure: {
                    type: "Component",
                    skin: "wixui.skins.Rating",
                    layout: {
                        width: 307,
                        height: 25,
                        x: 10,
                        y: 19,
                        scale: 1,
                        rotationInDegrees: 0,
                        fixedPosition: false
                    },
                    componentType: "wixui.RatingsDisplay",
                    data: {
                        type: "RatingsDisplay",
                        metaData: {
                            isPreset: false,
                            schemaVersion: "1.0",
                            isHidden: false
                        },
                        rating: 3,
                        svgId: "503047d3d3884f6097b82dec7af52fa8.svg",
                        numRatings: 150,
                        reviewsCountLabel: i18n.translate("ratings_read_preset_star_text"),
                        noReviewsPlaceholder: i18n.translate("ratings_read_settings_norating_placeholder_text")
                    },
                    props: {
                        type: "RatingsDisplayProperties",
                        metaData: {
                            schemaVersion: "1.0",
                            autoGenerated: false
                        },
                        showReviewsCount: true,
                        showRating: true,
                        noReviewsMode: "emptyIcons",
                        ratingPosition: "before",
                        shapeSize: 25,
                        shapeSpacing: 8,
                        alignment: "left",
                        direction: "ltr",
                        renderSeo: true
                    },
                    style: {
                        type: "TopLevelStyle",
                        metaData: {
                            isPreset: false,
                            schemaVersion: "1.0",
                            isHidden: false
                        },
                        style: {
                            groups: {},
                            properties: {
                                "alpha-emptyShapeColor": "0",
                                emptyShapeColor: "color_15",
                                filledShapeColor: "#FFCC00",
                                labelFont:
                                    "normal normal 700 12px/1.4em futura-lt-w01-book",
                                labelFontColor: "#414141",
                                shapeBorderColor: "#FFCC00",
                                shapeBorderWidth: "13"
                            },
                            propertiesSource: {
                                "alpha-emptyShapeColor": "value",
                                filledShapeColor: "value",
                                labelFont: "value",
                                labelFontColor: "value",
                                shapeBorderColor: "value",
                                shapeBorderWidth: "value"
                            }
                        },
                        componentClassName: "wixui.RatingsDisplay",
                        pageId: "",
                        compId: "",
                        styleType: "custom",
                        skin: "wixui.skins.Rating"
                    },
                    activeModes: {},
                    id: "comp-jhun52gg"
                },
                preset: {
                    rect: {width: 324, height: 58, x: 0, y: 0},
                    tags: null
                }
            },
            {
                id: "User_Input_RatingsDisplay_2",
                structure: {
                    type: "Component",
                    skin: "wixui.skins.Rating",
                    layout: {
                        width: 305,
                        height: 30,
                        x: 10,
                        y: 70,
                        scale: 1,
                        rotationInDegrees: 0,
                        fixedPosition: false
                    },
                    componentType: "wixui.RatingsDisplay",
                    data: {
                        type: "RatingsDisplay",
                        metaData: {
                            isPreset: false,
                            schemaVersion: "1.0",
                            isHidden: false
                        },
                        rating: 4,
                        svgId: "05378efaa01c474eb81d55b0e0144068.svg",
                        numRatings: 150,
                        reviewsCountLabel: i18n.translate("ratings_read_preset_circle_text"),
                        noReviewsPlaceholder: i18n.translate("ratings_read_settings_norating_placeholder_text")
                    },
                    props: {
                        type: "RatingsDisplayProperties",
                        metaData: {
                            schemaVersion: "1.0",
                            autoGenerated: false
                        },
                        showReviewsCount: true,
                        showRating: true,
                        noReviewsMode: "emptyIcons",
                        ratingPosition: "before",
                        shapeSize: 30,
                        shapeSpacing: 3,
                        alignment: "left",
                        direction: "ltr",
                        renderSeo: true
                    },
                    style: {
                        type: "TopLevelStyle",
                        metaData: {
                            isPreset: false,
                            schemaVersion: "1.0",
                            isHidden: false
                        },
                        style: {
                            groups: {},
                            properties: {
                                "alpha-emptyShapeColor": "0.46",
                                emptyShapeColor: "#42CCCC",
                                filledShapeColor: "#42CCCC",
                                labelFont:
                                    "normal normal normal 12px/1.4em georgia",
                                labelFontColor: "#414141",
                                shapeBorderColor: "#42CCCC",
                                shapeBorderWidth: "0"
                            },
                            propertiesSource: {
                                "alpha-emptyShapeColor": "value",
                                emptyShapeColor: "value",
                                filledShapeColor: "value",
                                labelFont: "value",
                                labelFontColor: "value",
                                shapeBorderColor: "value",
                                shapeBorderWidth: "value"
                            }
                        },
                        componentClassName: "wixui.RatingsDisplay",
                        pageId: "",
                        compId: "",
                        styleType: "custom",
                        skin: "wixui.skins.Rating"
                    },
                    activeModes: {},
                    id: "comp-jhun52gn"
                },
                preset: {
                    rect: {width: 324, height: 50, x: 0, y: 58},
                    tags: null
                }
            },
            {
                id: "User_Input_RatingsDisplay_3",
                structure: {
                    type: "Component",
                    skin: "wixui.skins.Rating",
                    layout: {
                        width: 302,
                        height: 24,
                        x: 11,
                        y: 124,
                        scale: 1,
                        rotationInDegrees: 0,
                        fixedPosition: false
                    },
                    componentType: "wixui.RatingsDisplay",
                    data: {
                        type: "RatingsDisplay",
                        metaData: {
                            isPreset: false,
                            schemaVersion: "1.0",
                            isHidden: false
                        },
                        rating: 4.5,
                        svgId: "df738905bc4f402cb8291135f9310a45.svg",
                        numRatings: 150,
                        reviewsCountLabel: i18n.translate("ratings_read_preset_heart_text"),
                        noReviewsPlaceholder: i18n.translate("ratings_read_settings_norating_placeholder_text")
                    },
                    props: {
                        type: "RatingsDisplayProperties",
                        metaData: {
                            schemaVersion: "1.0",
                            autoGenerated: false
                        },
                        showReviewsCount: true,
                        showRating: true,
                        noReviewsMode: "emptyIcons",
                        ratingPosition: "before",
                        shapeSize: 24,
                        shapeSpacing: 10,
                        alignment: "left",
                        direction: "ltr",
                        renderSeo: true
                    },
                    style: {
                        type: "TopLevelStyle",
                        metaData: {
                            isPreset: false,
                            schemaVersion: "1.0",
                            isHidden: false
                        },
                        style: {
                            groups: {},
                            properties: {
                                "alpha-emptyShapeColor": "0",
                                emptyShapeColor: "#F36F7A",
                                filledShapeColor: "#F36F7A",
                                labelFont:
                                    "normal normal normal 12px/1.4em avenir-lt-w01_35-light1475496",
                                labelFontColor: "#414141",
                                shapeBorderColor: "#F36F7A",
                                shapeBorderWidth: "11"
                            },
                            propertiesSource: {
                                "alpha-emptyShapeColor": "value",
                                emptyShapeColor: "value",
                                filledShapeColor: "value",
                                labelFont: "value",
                                labelFontColor: "value",
                                shapeBorderColor: "value",
                                shapeBorderWidth: "value"
                            }
                        },
                        componentClassName: "wixui.RatingsDisplay",
                        pageId: "",
                        compId: "",
                        styleType: "custom",
                        skin: "wixui.skins.Rating"
                    },
                    activeModes: {},
                    id: "comp-jhun52gu"
                },
                preset: {
                    rect: {width: 324, height: 59, x: 0, y: 108},
                    tags: null
                }
            }
        ],
        compTypes: ["wixui.RatingsDisplay"]
    },
    help: {
        hide: false,
        text: "add_section_label_ratings_read_tooltip_description"
    }
}
