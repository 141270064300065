import type { EditorAPI } from '@/editorAPI';
import type { FeatureWithNamed } from '@wix/deeplink-core';
import type { CompRef } from '@wix/document-services-types';
import constants from '@/constants';
import experiment from 'experiment';

function selectAndOpen(
  editorAPI: EditorAPI,
  compRef: CompRef,
  panelName: FeatureWithNamed<'component'>['named']['panelName'],
) {
  editorAPI.selection.selectComponentByCompRef(compRef);

  if (editorAPI.isTpa(editorAPI.components.getType(compRef))) {
    const tpaPanelHandler = createOpenTpaComponentPanelHandler(
      editorAPI,
      panelName,
    );

    if (tpaPanelHandler) {
      tpaPanelHandler(compRef);

      return;
    }
  }

  if (panelName) {
    editorAPI.openComponentPanel(constants.componentPanels[panelName], {
      experimentIsOpen: experiment.isOpen,
    });
  }
}

function navigateAndOpenPanel(
  editorAPI: EditorAPI,
  compRef: CompRef,
  panelName: FeatureWithNamed<'component'>['named']['panelName'],
) {
  const { id: pageId } = editorAPI.components.getPage(compRef);
  editorAPI.pages.navigateTo(pageId, () => {
    /**
     * using both editorAPI.pages.scrollToComponent and editorAPI.scroll.animateScrollTo because
     * page height can jump and we won't actually scroll to component, but will select it
     */
    editorAPI.pages.scrollToComponent(compRef.id, {
      onComplete: () => {
        const compLayout =
          editorAPI.components.layout.getRelativeToScreen(compRef);

        editorAPI.scroll.animateScrollTo(
          { x: compLayout.x, y: compLayout.y },
          0,
          () => {
            selectAndOpen(editorAPI, compRef, panelName);
          },
        );
      },
      onUpdate: () => {},
    });
  });
}

// TODO implement logic for other panels beside settings
function createOpenTpaComponentPanelHandler(
  editorAPI: EditorAPI,
  panelName: FeatureWithNamed<'component'>['named']['panelName'],
): ((compRef: CompRef) => void) | undefined {
  switch (panelName) {
    case 'settings': {
      return (compRef: CompRef) => {
        editorAPI.tpa.openSettingsPanel(editorAPI, compRef);
      };
    }
    default: {
      return undefined;
    }
  }
}

export { navigateAndOpenPanel, createOpenTpaComponentPanelHandler };
