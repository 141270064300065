// @ts-nocheck
import _ from 'lodash';
import * as platformEvents from 'platformEvents';
import * as stateManagement from '@/stateManagement';

const getApplicationId = (editorAPI, controllerRef, isTPA) => {
  const appData = editorAPI.components.data.get(controllerRef);
  const appDefinitionId = isTPA
    ? appData?.appDefinitionId
    : appData?.applicationId;
  return editorAPI.platform.getAppDataByAppDefId(appDefinitionId)
    ?.applicationId;
};

const notifyApplicationEventType = (
  editorAPI,
  componentRefs,
  originalComponentRefs,
  appWidgetEvent,
  connectedComponentEvent,
) => {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
  _.forEach(componentRefs, (componentRef, index) => {
    const originalComponentRef = originalComponentRefs[index];
    const isTPA = editorAPI.dsRead.tpa.isTpaByCompType(
      editorAPI.components.getType(componentRef),
    );
    const isApplication =
      stateManagement.components.selectors.isAppWidget(
        componentRef,
        editorAPI.dsRead,
      ) || isTPA;
    const isContainer = editorAPI.components.is.container(componentRef);

    if (isContainer && !isApplication) {
      const children =
        editorAPI.components.getChildren_DEPRECATED_BAD_PERFORMANCE(
          componentRef,
        );
      const originalChildren =
        editorAPI.components.getChildren_DEPRECATED_BAD_PERFORMANCE(
          originalComponentRef,
        );
      notifyApplicationEventType(
        editorAPI,
        children,
        originalChildren,
        appWidgetEvent,
        connectedComponentEvent,
      );
    } else if (isApplication) {
      const applicationId = getApplicationId(editorAPI, componentRef, isTPA);
      editorAPI.platform.notifyApplication(
        applicationId,
        platformEvents.factory[appWidgetEvent]({
          componentRef,
          originalComponentRef,
        }),
      );
    } else {
      const connections =
        editorAPI.platform.controllers.connections.get(componentRef);
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
      _.forEach(connections, (connection) => {
        const applicationId = getApplicationId(
          editorAPI,
          connection.controllerRef,
        );
        editorAPI.platform.notifyApplication(
          applicationId,
          platformEvents.factory[connectedComponentEvent]({
            componentRef,
            originalComponentRef,
            connection,
          }),
        );
      });
    }
  });
};

const notifyApplicationAfterComponentPasted = (
  editorAPI,
  componentRefs,
  originalComponentRefs,
) => {
  notifyApplicationEventType(
    editorAPI,
    componentRefs,
    originalComponentRefs,
    'widgetPasted',
    'connectedComponentPasted',
  );
};

const notifyApplicationAfterComponentDuplicated = (
  editorAPI,
  componentRefs,
  originalComponentRefs,
) => {
  notifyApplicationEventType(
    editorAPI,
    componentRefs,
    originalComponentRefs,
    'widgetDuplicated',
    'connectedComponentDuplicated',
  );
};

export {
  notifyApplicationAfterComponentPasted,
  notifyApplicationAfterComponentDuplicated,
};
