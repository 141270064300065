// @ts-nocheck
const CLASSIC_OVERLAPPING_WIDGET_IMAGE =
  'wixBookings/addPanel/offering_list_widget.png';
const CLASSIC_OVERLAPPING_STRIP_GRID_WIDGET_IMAGE_V2 =
  'wixBookings/addPanel/bookings_offering_list_widget_4_presets.v2.png';
const CLASSIC_PRESET_KEY = 'Wix_Bookings_Offering_List_Widget_Classic';
const OVERLAPPING_PRESET_KEY = 'Wix_Bookings_Offering_List_Widget_Overlapping';
const STRIP_PRESET_KEY = 'Wix_Bookings_Offering_List_Widget_Strip';
const GRID_PRESET_KEY = 'Wix_Bookings_Offering_List_Widget_Grid';

const CLASSIC_OVERLAPPING_STRIP_GRID_WIDGET_EDITOR_X_IMAGE =
  'wixBookings/addPanel/bookings_offering_list_widget_Presets_editorX.png';
const CLASSIC_OVERLAPPING_STRIP_GRID_WIDGET_WIX_STUDIO_IMAGE =
  'wixBookings/addPanel/bookings_offering_list_widget_Presets_wix_studio.v2.png';
const CLASSIC_EDITOR_X_PRESET_ID =
  'Wix_Bookings_Offering_List_Widget_Classic_Editor_X';
const OVERLAPPING_EDITOR_X_PRESET_ID =
  'Wix_Bookings_Offering_List_Widget_Overlapping_Editor_X';
const STRIP_EDITOR_X_PRESET_ID =
  'Wix_Bookings_Offering_List_Widget_Strip_Editor_X';
const GRID_EDITOR_X_PRESET_ID =
  'Wix_Bookings_Offering_List_Widget_Grid_Editor_X';

export {
  CLASSIC_OVERLAPPING_WIDGET_IMAGE,
  CLASSIC_OVERLAPPING_STRIP_GRID_WIDGET_IMAGE_V2,
  CLASSIC_PRESET_KEY,
  OVERLAPPING_PRESET_KEY,
  STRIP_PRESET_KEY,
  GRID_PRESET_KEY,
  CLASSIC_OVERLAPPING_STRIP_GRID_WIDGET_EDITOR_X_IMAGE,
  CLASSIC_EDITOR_X_PRESET_ID,
  OVERLAPPING_EDITOR_X_PRESET_ID,
  STRIP_EDITOR_X_PRESET_ID,
  GRID_EDITOR_X_PRESET_ID,
  CLASSIC_OVERLAPPING_STRIP_GRID_WIDGET_WIX_STUDIO_IMAGE,
};
