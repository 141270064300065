import React from 'react';
import createReactClass from 'create-react-class';
import _ from 'lodash';

import * as util from '@/util';
import * as core from '@/core';
import * as coreBi from '@/coreBi';
import * as helpIds from '@/helpIds';
import constants from '@/constants';

import panelConstants from './constants';
import pagePreset from './pagePreset';
import {
  PremiumCustomErrorPagePanelFrame,
  FreemiumCustomErrorPagePanelFrame,
  NewWorkspacePremiumCustomErrorPagePanelFrame,
  NewWorkspaceFreemiumCustomErrorPagePanelFrame,
} from './customErrorPagePanelFrame';

const { actions, displayName } = panelConstants;

export interface CustomErrorPagePanelProps {
  panelName: string;
}

// eslint-disable-next-line react/prefer-es6-class
const CustomErrorPagePanel = createReactClass({
  displayName,
  mixins: [core.mixins.editorAPIMixin],

  componentDidMount() {
    util.fedopsLogger.interactionEnded(
      util.fedopsLogger.INTERACTIONS.CUSTOM_404_OPEN_PANEL,
    );
  },

  componentWillUnmount() {
    util.fedopsLogger.interactionEnded(
      util.fedopsLogger.INTERACTIONS.CUSTOM_404_CLOSE_PANEL,
    );
  },

  openGoPremiumLink() {
    const editorAPI = this.getEditorAPI();
    editorAPI.bi.event(coreBi.events.panels.PANEL_CLOSED, {
      panel_name: this.props.panelName,
      origin: constants.BI.HELP.ORIGIN.PANEL,
    });

    editorAPI.account.upgrade(actions.UPGRADE_PLAN);
    editorAPI.bi.event(coreBi.events.customErrorPage.custom_error_page_panel, {
      action: 'upgrade',
    });
    this.close('close');
  },

  openHelpLink() {
    const editorAPI = this.getEditorAPI();
    const biHelpParams = {
      panel_name: this.props.panelName,
      origin: constants.BI.HELP.ORIGIN.PANEL,
    };
    editorAPI.panelManager.openHelpCenter(
      helpIds.EXTERNAL.CUSTOM_ERROR_PAGE,
      {},
      biHelpParams,
    );
  },

  addNewPage() {
    const editorAPI = this.getEditorAPI();

    if (!editorAPI.pages.hasCustomErrorPage()) {
      const newPage = editorAPI.pages.add(
        pagePreset.title,
        pagePreset.definition,
      );
      if (newPage) {
        editorAPI.bi.event(
          coreBi.events.customErrorPage.custom_error_page_panel,
          {
            action: 'create_custom_error_page',
          },
        );
        util.fedopsLogger.interactionStarted(
          util.fedopsLogger.INTERACTIONS.CUSTOM_404_CREATE_PAGE,
        );
        editorAPI.dsActions.waitForChangesApplied(() => {
          editorAPI.history.add('adding new page', {
            actionType: 'addPage',
            nextPage: newPage.id,
          });
          util.fedopsLogger.interactionEnded(
            util.fedopsLogger.INTERACTIONS.CUSTOM_404_CREATE_PAGE,
          );
          editorAPI.navigateAndOpenPagesPanel(newPage.id, {
            renameEnabled: false,
            origin: 'custom_error_page',
          });
        });
      }
    }
  },

  close() {
    const editorAPI = this.getEditorAPI();

    util.fedopsLogger.interactionStarted(
      util.fedopsLogger.INTERACTIONS.CUSTOM_404_CLOSE_PANEL,
    );
    editorAPI.panelManager.closePanelByName(this.props.panelName);
  },

  openDefault404() {
    const editorAPI = this.getEditorAPI();
    // NOTE: https://github.com/wix-private/santa-editor/issues/21392
    if (!_.invoke(editorAPI, 'dsRead.generalInfo.isFirstSave')) {
      editorAPI.bi.event(
        coreBi.events.customErrorPage.custom_error_page_panel,
        {
          action: 'preview_custom_error_page',
          flow: 'custom_error_page_panel',
        },
      );
      window.open(this.getUrl());
    }
  },

  getUrl() {
    const editorAPI = this.getEditorAPI();
    const [sitePublicUrl, sitePublicUrlQuery] = editorAPI.site
      .getSitePublicUrl()
      .split('?');
    return `${sitePublicUrl}/${panelConstants.defaultUrl}?${sitePublicUrlQuery}`;
  },

  getIsPremium() {
    const editorAPI = this.getEditorAPI();
    return editorAPI.site.isPremium();
  },

  renderPremiumPanelFrame() {
    const PanelFrame = util.workspace.isNewWorkspaceEnabled()
      ? NewWorkspacePremiumCustomErrorPagePanelFrame
      : PremiumCustomErrorPagePanelFrame;

    return (
      <PanelFrame
        panelName={this.props.panelName}
        onClose={this.close}
        onPrimaryButtonClick={this.addNewPage}
        onSecondaryButtonClick={this.openHelpLink}
      />
    );
  },

  renderFreemiumPanelFrame() {
    const PanelFrame = util.workspace.isNewWorkspaceEnabled()
      ? NewWorkspaceFreemiumCustomErrorPagePanelFrame
      : FreemiumCustomErrorPagePanelFrame;

    return (
      <PanelFrame
        panelName={this.props.panelName}
        onClose={this.close}
        onPrimaryButtonClick={this.openGoPremiumLink}
        onSecondaryButtonClick={this.openDefault404}
      />
    );
  },

  render() {
    return this.getIsPremium()
      ? this.renderPremiumPanelFrame()
      : this.renderFreemiumPanelFrame();
  },
});

export default CustomErrorPagePanel;
