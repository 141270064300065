/*eslint max-lines: [2, { "max": 1259, "skipComments": true, "skipBlankLines": true}]*/
import _ from 'lodash';

import {
  EditorAPIKey,
  SiteApiKey,
  PanelManagerApiKey,
  BiApiKey,
  AccountApiKey,
  MobileEditorApiKey,
  SectionizerApiKey,
  EditorCoreApiKey,
} from '@/apis';

import * as util from '@/util';
import { fedopsLogger } from '@/util';
import * as failedPanelUtil from '../utils/failedPanelUtil';
import savePublishErrorPanelData from '../constants/savePublishErrorPanelData';
import INNER_PANELS from '../panels/publish/publishInnerPanelTypes';
import type { PublishInnerPanelType } from '../panels/publish/publishInnerPanelTypes';
import * as stateManagement from '@/stateManagement';
import * as coreBi from '@/coreBi';
import constants from '@/constants';
import experiment from 'experiment';
import type { DocumentServiceError } from 'types/documentServices';
import type { EditorAPI } from '@/editorAPI';
import type * as core from '@/core';
import type {
  PublishInteractionStartedSource,
  PublishRCInteractionStartedSource,
} from 'types/fedops/publishInteraction';
import type {
  PostPublishPanelConfiguration,
  PublishSuccessPanelOpenProps,
} from '../panels/publish/publishSuccessPanel';
import type { SaveOptions } from './saveManagerApi';
import { getPublishSourceOfStartBySavedPanelCloseSource } from './getPublishSourceOfStart';
import { ErrorReporter } from '@wix/editor-error-reporter';
import { isResponsiveEditor } from '@wix/santa-editor-utils';
import { SaveManagerApiKey, SavePublishApiKey } from '../publicApiKey';
import { Hooks, type Shell } from '@/apilib';
import * as qrcode from 'qrcode';
import type { EditorStore } from '@/stateManagement';
import { translate } from '@/i18n';

const domainSelectors = stateManagement.domain.selectors;
const VIEW_PUBLISHED_VERSION_ON_MOBILE_EVENT_TARGET =
  'view_published_version_on_mobile';
const { setSiteUserPreferences } = stateManagement.userPreferences.actions;
const { getSiteUserPreferences } = stateManagement.userPreferences.selectors;
const { turnOnDomainSuggestion } = stateManagement.domainSuggestions.actions;
const { isDomainConnected } = stateManagement.domain.selectors;
const savePublishSelectors = stateManagement.savePublish.selectors;
const savePublishActions = stateManagement.savePublish.actions;
const monthInMs = 2592000000;

interface DSPublishOptions {
  label?: string;
  publishRC?: boolean;
}

export interface PublishOptions {
  // TODO: origin is unused. cleanup
  origin?: string;
  // TODO: change to required when ready
  sourceOfStart?: PublishInteractionStartedSource;
  publish_type?: string;
  isAutoPublish?: boolean;
  dsPublishOptions?: DSPublishOptions;
  shouldShowSuccessPublishPanel?: boolean;
  onPublishFailOverride?: (
    error: unknown | DocumentServiceError,
    origin?: string,
  ) => void;
}

export interface PublishRCOptions {
  // TODO: origin is unused. cleanup
  origin?: string;
  // TODO: change to required when ready
  sourceOfStart?: PublishRCInteractionStartedSource;
}

export interface PublishPipelineFailure {
  deploymentId: string;
  pipelineId: string;
  tasks: { task: string; status: string }[];
}

type PublishRCOrigin = 'top_bar' | 'publish';

export type SaveAndPublishOptions = PublishOptions & {
  // for legacy fedops/bi
  origin?: string;
  // TODO: change to required when ready
  sourceOfStart?: PublishInteractionStartedSource;
  preventSiteSavedPanel?: boolean;
  shouldConnectDomain?: boolean;
  overrideFreeDomainRadioButtonLabel?: string;
  overrideFreeDomainPrefix?: string;
};

type PostPublishPanelConfigurationMock = PostPublishPanelConfiguration & {
  shouldPublishSucceed?: boolean;
  isSiteContributor?: boolean;
};
const biCancelOriginMapping = {
  header_close_button: 'x',
  escape_press: 'esc',
  done_button: 'cancel',
};
const mapBiOrigin = (origin: string) =>
  biCancelOriginMapping[origin as keyof typeof biCancelOriginMapping] || origin;

const setPublicUrlQrCode = (
  publicUrl: string,
  store: EditorStore,
  editorAPI: EditorAPI,
) => {
  const qrCodeUrl = new URL(
    `${util.serviceTopology.editorRootUrl}_serverless/editor-client-service/qrcoderedirect`,
  );
  qrCodeUrl.searchParams.set(
    'eventtarget',
    VIEW_PUBLISHED_VERSION_ON_MOBILE_EVENT_TARGET,
  );
  qrCodeUrl.searchParams.set(
    'uuid',
    editorAPI.dsRead?.generalInfo.getUserInfo()?.userId ||
      util.editorModel.permissionsInfo.ownerId,
  );
  qrCodeUrl.searchParams.set(
    'msid',
    editorAPI.dsRead?.generalInfo.getMetaSiteId() ||
      util.editorModel.metaSiteId,
  );
  qrCodeUrl.searchParams.set('urltoredirect', publicUrl);
  qrcode.toDataURL(qrCodeUrl.href).then((qrCodeUrl: string) => {
    store.dispatch(savePublishActions.setSiteQrCodeUrl(qrCodeUrl));
  });
};

export function createPublishManagerApi(shell: Shell) {
  const hooks = {
    beforePublish: Hooks.createHook(),
    afterPublish: Hooks.createHook(),
  };

  const editorAPI = shell.getAPI(EditorAPIKey);
  const savePublishApi = shell.getAPI(SavePublishApiKey);
  const saveManagerApi = shell.getAPI(SaveManagerApiKey);
  const siteApi = shell.getAPI(SiteApiKey);
  const accountApi = shell.getAPI(AccountApiKey);
  const mobileEditorApi = shell.getAPI(MobileEditorApiKey);
  const biApi = shell.getAPI(BiApiKey);
  const panelManagerApi = shell.getAPI(PanelManagerApiKey);
  const sectionizerApi = shell.getAPI(SectionizerApiKey);
  const editorCoreApi = shell.getAPI(EditorCoreApiKey);
  const { store } = editorAPI;

  editorCoreApi.hooks.stageIsReady.promise.then(() => {
    const publicUrl = editorAPI.dsRead.generalInfo.getPublicUrl();
    setPublicUrlQrCode(publicUrl, store, editorAPI);
  });
  const PUBLISH_ERROR_PANEL = 'savePublish.panels.publish.publishErrorPanel';
  const PUBLISH_CONFIG_PANEL =
    'savePublish.panels.publish.config.publishConfigurationPanel';
  const PUBLISH_SUCCESS_PANEL =
    'savePublish.panels.publish.publishSuccessPanel';
  const PUBLISH_RC_SUCCESS_PANEL =
    'savePublish.panels.publish.publishRCSuccessPanel';
  const PUBLISH_FAILED_PANEL = 'savePublish.panels.common.failPanel';
  const PUBLISH_BLOCKED_PANEL = 'panels.messagePanels.publishingBlockedForUser';
  const PUBLISH_RC_PREPARE_PANEL =
    'savePublish.panels.publish.publishRCPreparePanel';

  if (!savePublishSelectors.getIsDealerPostpublishLoaded(store.getState())) {
    fetchDealerPostPublish();
  }

  async function configureMockPostPublish(): Promise<void> {
    function openPostPublish(configuration: PostPublishPanelConfigurationMock) {
      if (configuration.shouldPublishSucceed) {
        openSuccessPublishPanelByConfig({
          configuration: { ...configuration, isFakePublish: true },
        });
      } else {
        openFailedPublishPanel();
      }
    }

    panelManagerApi.openPanel(PUBLISH_CONFIG_PANEL, {
      onConfirm: openPostPublish,
    });
  }

  interface RcOverridden {
    overridden: boolean;
    revision?: string;
  }
  async function immediatelyOverrideRcIfExists(): Promise<RcOverridden> {
    const dsSaveErrorTypes = editorAPI.dsRead.errors.save;
    try {
      await editorAPI.savePublish.publishRC();
      return { overridden: false };
    } catch (error) {
      if (
        failedPanelUtil.extractDocumentErrorType(error) !==
        dsSaveErrorTypes.RC_EXISTS
      ) {
        throw error;
      }
      const overrideRevisionInfo = getRevisionInfoFromError(error);
      await editorAPI.savePublish.publishRC(overrideRevisionInfo);
      return { overridden: true, revision: overrideRevisionInfo.revision };
    }
  }

  function sendSuccessRCBi(
    origin: string,
    overridden: boolean,
    revision?: string,
  ): void {
    if (experiment.isOpen('specs.wixCode.TestSiteEntryPoint')) {
      biApi.event(coreBi.events.publish.publish_rc_success, {
        origin,
      });
      return;
    }
    if (overridden && revision) {
      biApi.event(coreBi.events.publish.rc_deleted, {
        revision,
      });
    }
    biApi.event(coreBi.events.publish.publish_rc_success, {
      origin: origin && `${origin}${overridden ? '_override' : ''}`,
    });
  }

  async function overrideRcFromServer(origin?: string): Promise<void> {
    fedopsLogger.interactionStarted(fedopsLogger.INTERACTIONS.USER_PUBLISH_RC);

    setTestSiteProgressStarted();

    try {
      await savePublishApi.publishRC();
      sendSuccessRCBi(origin, null);
      fedopsLogger.interactionEnded(fedopsLogger.INTERACTIONS.USER_PUBLISH_RC);

      const rcStaticUrl = `${editorAPI.dsRead.generalInfo.getPublicUrl()}?rc=test-site`;
      window.open(rcStaticUrl, rcStaticUrl)!.focus();
    } catch (error) {
      onPublishFail(error, origin);
    } finally {
      setTestSiteProgressEnded();
    }
  }

  async function publishRC(
    origin?: string,
    overrideRevisionInfo?: { revision: string; branchId?: string },
  ): Promise<void> {
    const isTestSiteExpIsOpen =
      experiment.isOpen('specs.wixCode.TestSiteEntryPoint') &&
      editorAPI.developerMode.isEnabled();

    if (!savePublishApi.canSaveOrPublish()) {
      return;
    }

    try {
      await sectionizerApi.sectionizerValidationPromt();
    } catch (e) {
      return;
    }

    if (isTestSiteExpIsOpen) {
      if (getOverrideRcDontShowAgainUserPref()) {
        overrideRcFromServer(origin);
        return;
      }
      openPrepareTestSiteModal(origin);
      return;
    }
    fedopsLogger.interactionStarted(fedopsLogger.INTERACTIONS.USER_PUBLISH_RC);

    setPublishProgressStarted();

    const prePublishConfig: PostPublishPanelConfiguration = {
      isFirstSave:
        editorAPI.dsRead.generalInfo.isFirstSave() ||
        editorAPI.dsRead.generalInfo.isDraft(),
      isPremiumUser: siteApi.isPremium(),
      isFirstPublish: !editorAPI.dsRead.generalInfo.isSitePublished(),
      rcPublicUrl: editorAPI.dsRead.generalInfo.getCurrentRevisionPublicUrl(),
    };

    const immediatelyOverrideRC =
      !overrideRevisionInfo && getOverrideRcDontShowAgainUserPref();

    try {
      if (immediatelyOverrideRC) {
        const { overridden, revision } = await immediatelyOverrideRcIfExists();
        sendSuccessRCBi(origin, overridden, revision);
      } else {
        await savePublishApi.publishRC(overrideRevisionInfo);
        sendSuccessRCBi(
          origin,
          !!overrideRevisionInfo,
          overrideRevisionInfo?.revision,
        );
      }
      fedopsLogger.interactionEnded(fedopsLogger.INTERACTIONS.USER_PUBLISH_RC);

      openSuccessPublishPanelByConfig({ configuration: prePublishConfig });
    } catch (error) {
      onPublishFail(error, origin);
    } finally {
      setPublishProgressEnded();
    }
  }

  function setSiteWasOnlyAutoPublished(siteWasOnlyAutoPublished: boolean) {
    editorAPI.store.dispatch(
      setSiteUserPreferences(
        constants.USER_PREFS.PUBLISH.SITE_WAS_ONLY_AUTO_PUBLISHED,
        siteWasOnlyAutoPublished,
      ),
    );
  }

  async function publish({
    sourceOfStart,
    origin,
    publish_type,
    isAutoPublish,
    dsPublishOptions,
    shouldShowSuccessPublishPanel = true,
    onPublishFailOverride,
  }: PublishOptions = {}) {
    const isFirstPublish = !editorAPI.dsRead?.generalInfo.isSitePublished();
    const isFirstManualPublishExperimentOpen = experiment.isOpen(
      'se_firstManualPublish',
    );
    const publishAfterSaveSuccessPanel =
      sourceOfStart ===
      constants.CONNECT_DOMAIN.FLOWS.SAVE_SUCCESS_PANEL_CONTINUE;

    if (isAutoPublish && isFirstPublish) {
      setSiteWasOnlyAutoPublished(true);
    }

    const siteWasOnlyAutoPublished = getSiteUserPreferences(
      constants.USER_PREFS.PUBLISH.SITE_WAS_ONLY_AUTO_PUBLISHED,
    )(editorAPI.store.getState());

    const isFirstManualPublish =
      !isAutoPublish && (siteWasOnlyAutoPublished || isFirstPublish);
    const shouldOpenChooseDomainPanel =
      isFirstManualPublishExperimentOpen &&
      isFirstManualPublish &&
      !isDomainConnected(editorAPI.dsRead) &&
      !publishAfterSaveSuccessPanel &&
      !isResponsiveEditor();

    if (shouldOpenChooseDomainPanel) {
      const panelOptions = {
        overrideTitle: 'PUBLISH_BEFORE_SAVE_CHOOSE_DOMAIN_TITLE',
        overrideFreeDomainRadioButtonLabel:
          'PUBLISH_CHOOSE_DOMAIN_BODY_RADIO_BUTTON_1',
        overrideActionButtonLabel: 'PUBLISH_CHOOSE_DOMAIN_BUTTON_PUBNCONTINUE',
        origin: constants.CONNECT_DOMAIN.FLOWS.FIRST_MANUAL_PUBLISH,
        preventSiteWasSavedPanel: true,
      };
      const isFirstSave = editorAPI.dsRead.generalInfo.isFirstSave();
      const isDraftMode = editorAPI.dsRead.generalInfo.isDraft();
      const shouldConnectDomainAfterSilentFirstSave = false;

      const onSuccess = () => {
        excutePublish({
          sourceOfStart,
          origin,
          publish_type,
          dsPublishOptions,
          shouldShowSuccessPublishPanel,
        });
        setSiteWasOnlyAutoPublished(false);
      };

      saveManagerApi
        .openChooseDomainPanel(
          panelOptions,
          isFirstSave,
          isDraftMode,
          shouldConnectDomainAfterSilentFirstSave,
        )
        .then(onSuccess)
        .catch((e) => {
          ErrorReporter.captureException(e, {
            tags: { failedFirstManualPublishFlow: true },
          });
        });

      return;
    }
    if (!isAutoPublish) {
      setSiteWasOnlyAutoPublished(false);
    }

    return excutePublish({
      sourceOfStart,
      origin,
      publish_type,
      isAutoPublish,
      dsPublishOptions,
      shouldShowSuccessPublishPanel,
      onPublishFailOverride,
    });
  }

  async function excutePublish({
    sourceOfStart,
    origin,
    publish_type,
    isAutoPublish,
    dsPublishOptions,
    shouldShowSuccessPublishPanel,
    onPublishFailOverride,
  }: PublishOptions = {}): Promise<void> {
    if (!savePublishApi.canSaveOrPublish()) {
      return;
    }

    try {
      await sectionizerApi.sectionizerValidationPromt();
    } catch (e) {
      return;
    }

    hooks.beforePublish.fire();

    fedopsLogger.interactionStarted(
      fedopsLogger.INTERACTIONS.DEALER_IN_POST_PUBLISH,
    );

    fedopsLogger.interactionStarted(fedopsLogger.INTERACTIONS.EXECUTE_PUBLISH, {
      paramsOverrides: {
        sourceOfStart,
      },
    });

    if (
      !editorAPI.dsRead.generalInfo.isFirstSave() &&
      !editorAPI.dsRead.generalInfo.isDraft()
    ) {
      fedopsLogger.interactionStarted(
        fedopsLogger.INTERACTIONS.USER_PUBLISH_SITE,
        {
          customParams: {
            origin,
          },
        },
      );
    }

    setPublishProgressStarted();

    try {
      const canShowPublishSuccessPanelByPermissions =
        shouldShowSuccessPublishPanel &&
        !isAutoPublish &&
        isUserPermittedToSeePublishSuccessPanel();
      let isOpenPublishSuccessPanelRequested = false;

      if (
        canShowPublishSuccessPanelByPermissions &&
        !savePublishSelectors.getIsDealerPostpublishLoaded(store.getState())
      ) {
        fetchDealerPostPublish();
      }

      const prePublishConfig = {
        isFirstSave:
          editorAPI.dsRead.generalInfo.isFirstSave() ||
          editorAPI.dsRead.generalInfo.isDraft(),
        isPremiumUser: siteApi.isPremium(),
        isFirstPublish: !editorAPI.dsRead.generalInfo.isSitePublished(),
      };

      await savePublishApi.publish({
        publish_type,
        origin,
        sourceOfStart,
        dsPublishOptions,
      });
      const publishPanelConfig = canShowPublishSuccessPanelByPermissions
        ? await getPostPublishPanelConfiguration()
        : {};
      const configuration = Object.assign(prePublishConfig, publishPanelConfig);

      let publicUrl =
        editorAPI.dsRead.generalInfo.getPublicUrl() ||
        domainSelectors.getFreeDomainPrefix(
          store.getState(),
          editorAPI.dsRead,
        ) + editorAPI.siteName.get();
      publicUrl = configuration.isDomainConnected
        ? configuration.sitePremiumDomain
        : publicUrl;

      setPublicUrlQrCode(publicUrl, store, editorAPI);

      store.dispatch(turnOnDomainSuggestion());

      fedopsLogger.interactionEnded(
        fedopsLogger.INTERACTIONS.USER_PUBLISH_SITE,
      );
      fedopsLogger.interactionEnded(fedopsLogger.INTERACTIONS.EXECUTE_PUBLISH, {
        paramsOverrides: {
          sourceOfStart,
        },
      });

      hooks.afterPublish.fire();

      if (canShowPublishSuccessPanelByPermissions) {
        try {
          const callResult = openSuccessPublishPanelByConfig({
            configuration,
            sourceOfStart,
          });

          isOpenPublishSuccessPanelRequested =
            callResult.isOpenPublishSuccessPanelRequested;
        } catch {
          // TODO: handle error for publish success panel
        }
      }

      // if open panel requested - end interaction on the panel did mount
      // if open panel NOT requested - end interaction now
      if (!isOpenPublishSuccessPanelRequested) {
        fedopsLogger.interactionEnded(fedopsLogger.INTERACTIONS.PUBLISH, {
          paramsOverrides: {
            sourceOfStart,
            sourceOfEnd: 'publishManager',
          },
        });
      }
    } catch (error) {
      if (!isAutoPublish) {
        if (onPublishFailOverride) {
          onPublishFailOverride(error, origin);
        } else {
          onPublishFail(error);
        }
      }
    } finally {
      setPublishProgressEnded();
    }
  }

  function isUserPermittedToSeePublishSuccessPanel() {
    return accountApi.isUserOwner();
  }

  function onConfirmRCFailed(origin: AnyFixMe) {
    const metaSiteId = editorAPI.dsRead.generalInfo.getMetaSiteId();
    const BUSINESS_MANAGER = util.serviceTopology.businessManagerUrl;

    biApi.event(coreBi.events.publish.publish_rc_goto_release_manager_click, {
      origin,
    });
    window.open(
      `${BUSINESS_MANAGER}/${metaSiteId}/release-manager-client?referralInfo=EDITOR`,
      '_blank',
    );
  }

  function getOverrideRcDontShowAgainUserPref() {
    const userPreferencesText = experiment.isOpen(
      'specs.wixCode.TestSiteEntryPoint',
    )
      ? constants.USER_PREFS.PUBLISH_RC.PREPARE_PANEL_RC_DONT_SHOW_AGAIN
      : constants.USER_PREFS.PUBLISH_RC.OVERRIDE_RC_DONT_SHOW_AGAIN;
    return stateManagement.editorPlugins.userPreferences.selectors.getSiteUserPreferences(
      userPreferencesText,
    )(store.getState());
  }

  // TODO: Import this interface from document services
  interface OverrideRevisionInfo {
    revision: string;
    branchId?: string;
  }

  function getRevisionInfoFromError(error: AnyFixMe): OverrideRevisionInfo {
    return error?.document?.errorDescription?.revisionInfo || {};
  }

  function onPublishFail(
    error: unknown | DocumentServiceError,
    origin?: string,
  ): void {
    const isPublishPipelineErrorEnabled = experiment.isOpen(
      'specs.wixCode.publishPipeline',
    );
    const errorType = failedPanelUtil.extractDocumentErrorType(error);
    const dsSaveErrorTypes = editorAPI.dsRead.errors.save;

    if (!window.navigator.onLine) {
      openFailedPublishPanel(
        error,
        savePublishErrorPanelData.PUBLISH_CONNECTION_OFFLINE,
      );
      return;
    }

    if (isPublishPipelineErrorEnabled) {
      const errorMock = {
        deploymentId: 'mockid',
        pipelineId: 'mockid',
        tasks: [
          { status: 'done', task: 'Bundling site code' },
          { status: 'done', task: 'Bundling React components...' },
          { status: 'failed', task: 'Deploying site...' },
        ],
      };
      openPublishErrorPanel(errorMock);
      return;
    }

    switch (errorType) {
      case dsSaveErrorTypes.NOT_LOGGED_IN:
        openFailedPublishPanel(
          error,
          savePublishErrorPanelData.PUBLISH_NOT_LOGGED_IN,
        );
        break;
      case dsSaveErrorTypes.SITE_DELETED:
        openFailedPublishPanel(error, savePublishErrorPanelData.SITE_DELETED);
        break;
      case dsSaveErrorTypes.USER_NOT_AUTHORIZED_FOR_SITE:
        openFailedPublishPanel(
          error,
          savePublishErrorPanelData.PUBLISH_USER_NOT_AUTHORIZED_FOR_SITE,
        );
        break;
      case dsSaveErrorTypes.SAVE_PUBLISH_DISABLED_ON_SERVER:
        openFailedPublishPanel(
          error,
          savePublishErrorPanelData.PUBLISH_DISABLED_ON_SERVER_PANEL_DATA,
        );
        break;
      case dsSaveErrorTypes.SAVE_PUBLISH_RC_FAILED_ON_SERVER:
        openFailedPublishPanel(
          error,
          Object.assign(
            { onConfirm: () => onConfirmRCFailed('override rc panel') },
            savePublishErrorPanelData.PUBLISH_RC_FAILED_ON_SERVER_PANEL_DATA,
          ),
        );
        break;
      case dsSaveErrorTypes.RC_EXISTS:
        const { branchId } = getRevisionInfoFromError(error);
        openFailedPublishPanel(
          error,
          Object.assign(
            {
              onConfirm: async () => {
                biApi.event(
                  coreBi.events.publish.publish_rc_override_confirmation,
                  {
                    origin,
                    action_name: 'create_test_site',
                    do_not_show_again: getOverrideRcDontShowAgainUserPref(),
                    branchId,
                  },
                );
                await publishRC(
                  origin as PublishRCOrigin,
                  getRevisionInfoFromError(error),
                );
              },
              onDontShowAgainChange: () => {
                /*bi*/
              },
              onCancel: (cancelOrigin: AnyFixMe) => {
                biApi.event(
                  coreBi.events.publish.publish_rc_override_confirmation,
                  {
                    origin,
                    action_name: cancelOrigin == 'esc' ? 'escape' : 'cancel',
                    do_not_show_again: getOverrideRcDontShowAgainUserPref(),
                    branchId,
                  },
                );
              },
              dontShowAgainKey:
                constants.USER_PREFS.PUBLISH_RC.OVERRIDE_RC_DONT_SHOW_AGAIN,
              userPrefType: 'site',
              className: 'test-site-override-fail-panel',
            },
            savePublishErrorPanelData.PUBLISH_TEST_SITE_EXISTS_PANEL_DATA,
          ),
        );

        break;
      case dsSaveErrorTypes.RC_ROLLED_OUT:
        openFailedPublishPanel(
          error,
          Object.assign(
            {
              onConfirm: () =>
                onConfirmRCFailed('test_site_in_gradual_rollout'),
            },
            savePublishErrorPanelData.PUBLISH_TEST_SITE_FAILED_ON_SERVER_PANEL_DATA,
          ),
        );
        break;
      case dsSaveErrorTypes.USER_BLOCKED_FOR_PUBLISH:
        openBlockedPublishPanel(
          error,
          savePublishErrorPanelData.USER_BLOCKED_FOR_PUBLISH_PANEL_DATA,
        );
        break;
      default:
        openFailedPublishPanel(
          error,
          savePublishErrorPanelData.PUBLISH_ERROR_DEFAULT_PANEL_DATA,
        );
        break;
    }
  }
  function openPrepareTestSiteModal(origin?: string) {
    let dontShowAgainPref = getOverrideRcDontShowAgainUserPref();
    if (dontShowAgainPref === undefined) {
      dontShowAgainPref = true;
      store.dispatch(
        setSiteUserPreferences(
          constants.USER_PREFS.PUBLISH_RC.PREPARE_PANEL_RC_DONT_SHOW_AGAIN,
          dontShowAgainPref,
        ),
      );
    }
    const panelData = Object.assign({
      onConfirm: async () => {
        biApi.event(
          coreBi.events.publish.publish_rc_prepare_modal_confirmation,
          {
            panel_name: PUBLISH_RC_PREPARE_PANEL,
            actionName: 'click',
            element_name: 'deploy_test_site',
            dont_show_this_again:
              getOverrideRcDontShowAgainUserPref() !== undefined,
          },
        );
        await overrideRcFromServer(origin);
      },
      onCancel: (cancelOrigin: AnyFixMe) => {
        biApi.event(
          coreBi.events.publish.publish_rc_prepare_modal_confirmation,
          {
            panel_name: PUBLISH_RC_PREPARE_PANEL,
            actionName: 'click',
            element_name: mapBiOrigin(cancelOrigin),
            dont_show_this_again:
              getOverrideRcDontShowAgainUserPref() !== undefined,
          },
        );
        panelManagerApi.closePanelByName(PUBLISH_RC_PREPARE_PANEL);
      },
      onLearnMore: () => {
        biApi.event(
          coreBi.events.publish.publish_rc_prepare_modal_confirmation,
          {
            evid: 254,
            panel_name: PUBLISH_RC_PREPARE_PANEL,
            actionName: 'click',
            dont_show_this_again:
              getOverrideRcDontShowAgainUserPref() !== undefined,
          },
        );
        window.open(
          translate('WixCode_TestSite_FirstTimeNotes_Modal_LearnMore_Link'),
          '_blank',
        );
      },
      dontShowAgain: dontShowAgainPref,
      toggleDontShowAgain: () => {
        const getDontShowAgain = getOverrideRcDontShowAgainUserPref();
        store.dispatch(
          setSiteUserPreferences(
            constants.USER_PREFS.PUBLISH_RC.PREPARE_PANEL_RC_DONT_SHOW_AGAIN,
            !getDontShowAgain,
          ),
        );
      },
      dontShowAgainKey:
        constants.USER_PREFS.PUBLISH_RC.PREPARE_PANEL_RC_DONT_SHOW_AGAIN,
      userPrefType: 'site',
    });
    editorAPI.panelManager.openPanel(PUBLISH_RC_PREPARE_PANEL, panelData, true);
  }
  function hasOverriddenSaveAndPublish(): boolean {
    return Boolean(
      savePublishSelectors.getOverridingSaveAndPublish(
        editorAPI.store.getState(),
      ),
    );
  }

  function shouldSaveBeforePublish(): boolean {
    if (!editorAPI.dsRead) {
      return;
    }

    const isDraftMode = editorAPI.dsRead.generalInfo.isDraft();

    return isDraftMode || editorAPI.dsRead.generalInfo.isFirstSave();
  }

  async function saveAndPublish(
    saveAndPublishOptions: SaveAndPublishOptions = {},
  ): Promise<void> {
    try {
      await sectionizerApi.sectionizerValidationPromt();
    } catch (e) {
      return;
    }

    const overridingSaveAndPublish =
      savePublishSelectors.getOverridingSaveAndPublish(
        editorAPI.store.getState(),
      );

    if (overridingSaveAndPublish) {
      overridingSaveAndPublish(saveAndPublishOptions.origin);
      return;
    }

    fedopsLogger.interactionStarted(
      fedopsLogger.INTERACTIONS.USER_PUBLISH_SITE,
    );

    if (!savePublishApi.canSaveOrPublish()) {
      return;
    }

    if (
      saveAndPublishOptions.shouldConnectDomain ||
      shouldSaveBeforePublish()
    ) {
      return new Promise((resolve, reject) => {
        const saveOptions: SaveOptions = {
          overrideTitle: 'PUBLISH_BEFORE_SAVE_CHOOSE_DOMAIN_TITLE',
          overrideSubtitle: 'PUBLISH_BEFORE_SAVE_CHOOSE_DOMAIN_SUBTITLE',
          overrideFreeDomainRadioButtonLabel:
            saveAndPublishOptions.overrideFreeDomainRadioButtonLabel,
          overrideFreeDomainPrefix:
            saveAndPublishOptions.overrideFreeDomainPrefix,
          callback: (result) => {
            // NOTE: https://github.com/wix-private/santa-editor/issues/37029
            const publishSourceOfStart =
              getPublishSourceOfStartBySavedPanelCloseSource(
                result?.siteWasSavedPanelResult?.closeSource,
              );

            publish({
              ...saveAndPublishOptions,
              sourceOfStart: publishSourceOfStart,
            }).then(resolve, reject);
          },
          origin: constants.CONNECT_DOMAIN.FLOWS.PUBLISH,
          isPublish: true,
          preventSiteSavedPanel: saveAndPublishOptions.preventSiteSavedPanel,
          shouldConnectDomain: saveAndPublishOptions.shouldConnectDomain,
        };

        saveManagerApi.save(saveOptions);
      });
    }

    try {
      await new Promise((resolve, reject) =>
        saveManagerApi.saveInBackground(
          resolve,
          reject,
          saveAndPublishOptions.origin,
          {
            isPublish: true,
          },
        ),
      );
    } catch (error) {
      if (saveAndPublishOptions.onPublishFailOverride) {
        saveAndPublishOptions.onPublishFailOverride(error, origin);
      } else {
        onPublishFail(error);
      }

      return Promise.reject(error);
    }

    await publish(saveAndPublishOptions);
  }

  function sendDealerBI({ isUsingDealer, description, stage }: AnyFixMe) {
    biApi.event(coreBi.events.dealer.content_presented_by_dealer, {
      description: isUsingDealer ? '' : description,
      content_presented_by_dealer: isUsingDealer,
      stage,
      category: 'upgrade',
      panel_name: PUBLISH_SUCCESS_PANEL,
    });
  }

  function fetchDealerPostPublish() {
    const isDealerReady =
      editorAPI.wixDealerClientApi?.DealerAssetsLoader &&
      editorAPI.dsRead.generalInfo.getMetaSiteId();

    if (isDealerReady) {
      const metaSiteInstance =
        editorAPI.dsRead.platform.getAppDataByApplicationId(
          constants.APPLICATIONS.META_SITE_APPLICATION_ID,
        )?.instance;
      util.dealerUtils
        .loadDealerAsset(
          editorAPI.wixDealerClientApi,
          util.dealerUtils.bannerPositions.POST_PUBLISH,
          null,
          siteApi.getMetaSiteId(),
          metaSiteInstance,
        )
        .then((asset) => {
          const isAssetExists = asset.assetExist();
          const description = isAssetExists
            ? ''
            : 'banner data pre fetch asset does not exist error';

          sendDealerBI({
            isUsingDealer: isAssetExists,
            stage: 'prefetch',
            description,
          });

          store.dispatch(
            savePublishActions.setIsDealerPostpublishLoaded(isAssetExists),
          );
        })
        .catch((e) => {
          sendDealerBI({
            isUsingDealer: false,
            stage: 'prefetch',
            description: `banner data pre fetch ${
              e?.message ?? 'unknown'
            } error`,
          });
        });
    }
  }

  function setPublishProgressStarted(): void {
    savePublishApi.setPublishProgress(true);
  }

  function setPublishProgressEnded(): void {
    savePublishApi.setPublishProgress(false);
  }

  function setTestSiteProgressStarted(): void {
    savePublishApi.setTestSiteProgress(true);
  }

  function setTestSiteProgressEnded(): void {
    savePublishApi.setTestSiteProgress(false);
  }

  const getFinalPanelData = (
    error?: unknown | DocumentServiceError,
    panelData?: Object,
  ): Object => {
    const additionalFailPanelData = {
      errorType: failedPanelUtil.extractDocumentErrorType(error),
      errorCode: failedPanelUtil.extractDocumentErrorCode(error),
      biOrigin: 'Publish',
      biConfirm:
        coreBi.events.publish.Publish_Work_not_Published_Error_ok_Click,
      biHelp:
        coreBi.events.publish
          .Publish_Work_not_Published_Error_Help_Center_Link_Click,
    };

    const finalPanelData = _.chain(panelData)
      .clone()
      .assign(additionalFailPanelData)
      .value();

    return finalPanelData;
  };

  function openPublishErrorPanel(error: PublishPipelineFailure): void {
    editorAPI.panelManager.openPanel(PUBLISH_ERROR_PANEL, error);
  }

  function openFailedPublishPanel(
    error?: unknown | DocumentServiceError,
    panelData?: Object,
  ): void {
    const finalPanelData = getFinalPanelData(error, panelData);

    editorAPI.panelManager.openPanel(
      PUBLISH_FAILED_PANEL,
      finalPanelData,
      true,
    );
  }

  const openBlockedPublishPanel = (
    error?: unknown | DocumentServiceError,
    panelData?: Object,
  ): void => {
    const finalPanelData = getFinalPanelData(error, panelData);

    editorAPI.panelManager.openPanel(
      PUBLISH_BLOCKED_PANEL,
      finalPanelData,
      true,
    );
  };

  function getPublishPanelUserPrefs(): boolean {
    const USER_PREFS_KEYS = constants.USER_PREFS.PUBLISH;
    const userPrefs =
      stateManagement.userPreferences.selectors.getSiteUserPreferences(
        USER_PREFS_KEYS.POST_PUBLISH_DONT_SHOW_AGAIN,
      )(editorAPI.store.getState());
    return monthInMs > Date.now() - (userPrefs as AnyFixMe);
  }

  function retrievePublishSuccessProps({
    sourceOfStart,
    configuration,
    contentPanelType,
    publicUrl,
    USER_PREFS_KEYS,
  }: {
    sourceOfStart:
      | PublishInteractionStartedSource
      | PublishRCInteractionStartedSource;
    configuration: PostPublishPanelConfiguration;
    contentPanelType: PublishInnerPanelType;
    publicUrl: string;
    USER_PREFS_KEYS: typeof constants.USER_PREFS.PUBLISH;
  }): PublishSuccessPanelOpenProps {
    const panelProps: PublishSuccessPanelOpenProps = {
      sourceOfStart,
      isFakePanel: configuration.isFakePublish,
      contentPanelType,
      shouldDisplayInnerPanel: !stateManagement.schoolMode.selectors.isEnabled(
        store.getState(),
      ),
      configuration,
      currentDomain: siteApi.getCurrentSiteDomain(),
      setDontShowPanelValue: (value) =>
        editorAPI.store.dispatch(
          stateManagement.userPreferences.actions.setSessionUserPreferences(
            USER_PREFS_KEYS.POST_PUBLISH_DONT_SHOW_AGAIN,
            value,
          ),
        ),
      sitePublicUrl: publicUrl,
      closePanel,
    };

    if (
      savePublishSelectors.getIsDealerPostpublishLoaded(store.getState()) &&
      editorAPI.isDealerCssLoaded
    ) {
      panelProps.dealerViewer = editorAPI.wixReactDealerViewer.DealerViewer;
    }

    panelProps.setDontShowPanelValue = (value) => {
      if (value) {
        editorAPI.store.dispatch(
          stateManagement.userPreferences.actions.setSiteUserPreferences(
            USER_PREFS_KEYS.POST_PUBLISH_DONT_SHOW_AGAIN,
            Date.now(),
          ),
        );
      }
    };

    return panelProps;
  }

  function openSuccessPublishPanelByConfig({
    configuration,
    sourceOfStart,
  }: {
    configuration: PostPublishPanelConfiguration;
    sourceOfStart?:
      | PublishInteractionStartedSource
      | PublishRCInteractionStartedSource;
  }): {
    isOpenPublishSuccessPanelRequested: boolean;
  } {
    let publicUrl =
      editorAPI.dsRead.generalInfo.getPublicUrl() ||
      domainSelectors.getFreeDomainPrefix(store.getState(), editorAPI.dsRead) +
        editorAPI.siteName.get();

    publicUrl = configuration.isDomainConnected
      ? configuration.sitePremiumDomain
      : publicUrl;

    const USER_PREFS_KEYS = constants.USER_PREFS.PUBLISH;
    const postPublishPanelDontShowAgainUserPrefs = getPublishPanelUserPrefs();
    const panelName = configuration.rcPublicUrl
      ? PUBLISH_RC_SUCCESS_PANEL
      : PUBLISH_SUCCESS_PANEL;
    const dontShowAgainPublishSuccessPanel =
      postPublishPanelDontShowAgainUserPrefs &&
      panelName === PUBLISH_SUCCESS_PANEL;

    const contentPanelType = getContentPanelType(
      configuration,
      postPublishPanelDontShowAgainUserPrefs,
    );

    if (shouldRotatePromotionPanel(configuration)) {
      innerPromotionPanelRotationIndex(
        configuration,
        postPublishPanelDontShowAgainUserPrefs,
      );
    }

    const numberOfManualPublish = getSiteUserPreferences(
      'numberOfManualPublish',
    )(store.getState());
    const shouldShowFourthPublishSuccessPanel =
      numberOfManualPublish === 4 &&
      experiment.isOpen('se_connectDomainOn4thPublish');
    const shouldShowSuccessPanel =
      !_.isEqual(contentPanelType, INNER_PANELS.EMPTY) &&
      !dontShowAgainPublishSuccessPanel &&
      !shouldShowFourthPublishSuccessPanel;

    if (shouldShowSuccessPanel) {
      const panelProps = retrievePublishSuccessProps({
        sourceOfStart,
        configuration,
        contentPanelType,
        publicUrl,
        USER_PREFS_KEYS,
      });

      panelManagerApi.openPanel(panelName, panelProps, true);

      return { isOpenPublishSuccessPanelRequested: true };
    }

    if (shouldShowFourthPublishSuccessPanel)
      editorAPI.panelManager.openPanel(
        constants.PANELS.FOURTH_PUBLISH_SUCCESS_PANEL.PANEL_NAME,
        {},
        true,
      );
    else mobileEditorApi.mobileDiscoverabilityModal.show();

    return {
      isOpenPublishSuccessPanelRequested: false,
    };
  }

  function shouldRotatePromotionPanel(
    config: PostPublishPanelConfiguration,
  ): boolean {
    return !isShowConnectDomainOnly(config);
  }

  function innerPromotionPanelRotationIndex(
    config: PostPublishPanelConfiguration,
    postPublishPanelDontShowAgainUserPrefs: boolean,
  ): void {
    const { getState, dispatch } = editorAPI.store;
    const rotatingPanels = getRotatingPanels(
      config,
      postPublishPanelDontShowAgainUserPrefs,
    );
    const nextIndex =
      (savePublishSelectors.getPublishPanelPromotionIndex(getState()) + 1) %
      (rotatingPanels.length || 1);
    dispatch(savePublishActions.setPublishPanelPromotionIndex(nextIndex));
  }

  function getContentPanelType(
    config: PostPublishPanelConfiguration,
    postPublishPanelDontShowAgainUserPrefs: boolean,
  ): PublishInnerPanelType {
    let contentPanelType: PublishInnerPanelType = INNER_PANELS.EMPTY;
    const isInvalidConfig = !config.isPremiumUser && config.isDomainConnected;
    if (isInvalidConfig) {
      return contentPanelType;
    }
    if (isShowConnectDomainOnly(config)) {
      contentPanelType = INNER_PANELS.CONNECT_DOMAIN;
    } else {
      const rotatingPanels = getRotatingPanels(
        config,
        postPublishPanelDontShowAgainUserPrefs,
      );
      if (rotatingPanels.length) {
        const promotionPanelIndex =
          savePublishSelectors.getPublishPanelPromotionIndex(
            editorAPI.store.getState(),
          );
        contentPanelType =
          rotatingPanels[promotionPanelIndex % rotatingPanels.length] ||
          INNER_PANELS.EMPTY;
      }
    }
    return contentPanelType;
  }

  function isShowConnectDomainOnly(
    config: PostPublishPanelConfiguration,
  ): boolean {
    return !config.isDomainConnected;
  }

  function isShowConnectDomain(
    config: PostPublishPanelConfiguration,
    postPublishPanelDontShowAgainUserPrefs: boolean,
  ): boolean {
    return (
      !config.isDomainConnected &&
      (!postPublishPanelDontShowAgainUserPrefs || config.isFakePublish)
    );
  }

  function isShowShoutout(
    config: PostPublishPanelConfiguration,
    postPublishPanelDontShowAgainUserPrefs: boolean,
  ): boolean {
    return (
      !config.isDomainConnected &&
      (!postPublishPanelDontShowAgainUserPrefs || config.isFakePublish)
    );
  }

  function isShowGrowBusiness(
    config: PostPublishPanelConfiguration,
    postPublishPanelDontShowAgainUserPrefs: boolean,
  ): boolean {
    return (
      config.isPremiumUser &&
      config.isDomainConnected &&
      (!postPublishPanelDontShowAgainUserPrefs || config.isFakePublish)
    );
  }

  function getRotatingPanels(
    config: PostPublishPanelConfiguration,
    postPublishPanelDontShowAgainUserPrefs: boolean,
  ): PublishInnerPanelType[] {
    const rotatingPanels: PublishInnerPanelType[] = [];
    const isConnectDomainDisplayed = isShowConnectDomain(
      config,
      postPublishPanelDontShowAgainUserPrefs,
    );
    const isShoutoutDisplayed = isShowShoutout(
      config,
      postPublishPanelDontShowAgainUserPrefs,
    );
    const isGrowBusinessDisplayed = isShowGrowBusiness(
      config,
      postPublishPanelDontShowAgainUserPrefs,
    );

    if (isConnectDomainDisplayed) {
      rotatingPanels.push(INNER_PANELS.CONNECT_DOMAIN);
    }
    if (isShoutoutDisplayed) {
      rotatingPanels.push(INNER_PANELS.SHOUTOUT);
    }
    if (isGrowBusinessDisplayed) {
      rotatingPanels.push(INNER_PANELS.GROW_BUSINESS);
    }

    return rotatingPanels;
  }

  function getPostPublishPanelConfiguration(): Promise<PostPublishPanelConfiguration> {
    return new Promise((resolve, reject) => {
      const onSiteDomainInfoSuccess = (
        siteDomainInfo: core.premiumServicesAPI.SiteDomainInfo,
      ) => {
        const { isDomainConnected } = siteDomainInfo;
        const sitePremiumDomain = siteDomainInfo.sitePublishedDomain;

        resolve({
          sitePremiumDomain,
          isDomainConnected,
        });
      };

      siteApi.getSiteDomainInfo(onSiteDomainInfoSuccess, reject);
    });
  }

  function closePanel(): void {
    return panelManagerApi.closePanelByName(PUBLISH_SUCCESS_PANEL);
  }

  return {
    hooks,
    publish,
    publishRC,
    hasOverriddenSaveAndPublish,
    shouldSaveBeforePublish,
    saveAndPublish,
    configureMockPostPublish,
  };
}
