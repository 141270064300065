// @ts-nocheck
import * as bi from '../bi/bi';
import * as tpaUtils from '../utils/tpaUtils';
import constants from '../constants/constants';
import * as tpaDeleteService from './tpaDeleteService';
import * as tpaAddRemoveDSService from './tpaAddRemoveDSService';
import {
  EditorType,
  InstallationOriginType,
  InstallInitiator,
} from '@wix/platform-editor-sdk';

const copyComponentPlugin = function (origCompRef, serializedComp) {
  // TODO: mb add `isCopied/isCut` to all components (not only TPA)
  serializedComp._isTPACopied = true;
  return serializedComp;
};

const addComponentPlugin = function (
  editorAPI,
  componentStructure,
  { parentContainerRef },
) {
  if (!componentStructure.data || !componentStructure.data.appDefinitionId) {
    throw new Error(
      'Could not add tpa with empty "component.data.appDefinitionId"',
    );
  }

  const { appDefinitionId, widgetId } = componentStructure.data;

  if (componentStructure._isTPACopied) {
    tpaUtils.bi.reportBIOnAppIntent(
      editorAPI,
      bi.events.APP_INTENT,
      appDefinitionId,
      constants.BI.initiator.EDITOR,
      constants.BI.type.COPY_APP,
    );
  }

  return tpaAddRemoveDSService.addWidgetSync(editorAPI, appDefinitionId, {
    widgetId,
    componentDefinition: componentStructure,
    parentContainerRef,
    invokeAddAppCallbacks: false,
    dontStretch:
      !editorAPI.dsRead.components.layout.isHorizontallyStretchedToScreenByStructure(
        componentStructure,
      ),
    origin: {
      type: EditorType.Classic,
      initiator: InstallInitiator.Editor,
      info: {
        type: InstallationOriginType.COPY_PASTE,
      },
    },
  });
};

const cutComponentPlugin = function (editorAPI, compRef) {
  tpaDeleteService.deleteWidget(editorAPI, compRef, false);
};

export { copyComponentPlugin, addComponentPlugin, cutComponentPlugin };
