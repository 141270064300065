import {
  registerComponentGfppData,
  registerComponentGfppDefinition,
  getComponentGfppData,
} from './data/gfppDataMap';
import gfppDataUtils from './utils/gfppDataUtils';
import columnsGfppUtils from './utils/columnsGfppUtils';
import repeaterGfppUtils from './utils/repeaterGfppUtils';
import animationGfppUtils from './utils/animationGfppUtils';
import backgroundSettingsGfppUtils from './utils/backgroundSettingsGfppUtils';
import externalGfppDataWrapper from './utils/externalGfppDataWrapper';
import gfppActionList from './utils/gfppActionsList';
import * as gfppModel from './gfppModel';

export {
  /**
   * used in editorX and some tests
   * @deprecated use `registerComponentGfppData` or `registerComponentGfppDefinition` instead
   */
  registerComponentGfppData as registerComponent,
  registerComponentGfppData,
  registerComponentGfppDefinition,
  /**
   * used in editorX, tests, etc..
   * @deprecated use `getComponentGfppData`instead
   */
  getComponentGfppData as getComponentData,
  getComponentGfppData,
  columnsGfppUtils as columnsUtils,
  repeaterGfppUtils,
  backgroundSettingsGfppUtils,
  animationGfppUtils,
  gfppDataUtils as utils,
  externalGfppDataWrapper,
  gfppModel,
  gfppActionList,
};
