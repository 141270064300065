import constants from '@/constants';
import { editorModel, fixedStage, sections, url } from '@/util';
import { enterZoomMode, isInZoomMode } from './api';
import type { Scope } from './scope';
import * as stateManagement from '@/stateManagement';

import type { MigrationFlow } from '@/sectionsMigration';

const { OPEN_STAGE_IN_ZOOM_MODE } = constants.LOCAL_STORAGE_TYPE;

export const getShouldOpenInZoomFromLocalStorage = (): boolean => {
  return !!window.localStorage.getItem(OPEN_STAGE_IN_ZOOM_MODE);
};

export const setShouldOpenInZoomFromLocalStorage = (flag: boolean) => {
  window.localStorage.setItem(OPEN_STAGE_IN_ZOOM_MODE, flag ? 'true' : '');
};

function pageIsEmpty({ editorAPI, sectionsAPI }: Scope): boolean {
  const pageChildren = editorAPI.components.getChildren(
    editorAPI.pages.getFocusedPage(),
  );
  if (pageChildren.length === 0) {
    return true;
  }
  if (pageChildren.length > 1) {
    return false;
  }
  return sectionsAPI.isBlankSection(pageChildren[0]);
}

const getShouldOpenInZoomAfterMigration = (scope: Scope) => {
  const { sectionsMigrationFlow } = scope.editorParamsAPI;

  return sectionsMigrationFlow === <MigrationFlow>'adi';
};

export const shouldOpenZoomOnEditorLoad = (scope: Scope) => {
  const isSchoolMode = stateManagement.schoolMode.selectors.isEnabled(
    scope.editorAPI.store.getState(),
  );

  return (
    sections.isSectionsEnabled() &&
    !fixedStage.isFixedStageEnabled() &&
    (getShouldOpenInZoomFromLocalStorage() ||
      getShouldOpenInZoomAfterMigration(scope) ||
      (editorModel.isDraft &&
        !isSchoolMode &&
        !pageIsEmpty(scope) &&
        !url.isQA()))
  );
};

export const enterZoomModeAtStartupIfNeeded = async (scope: Scope) => {
  if (shouldOpenZoomOnEditorLoad(scope)) {
    await Promise.all([
      scope.editorCoreAPI.hooks.workspaceModesInit.promise,
      scope.editorCoreAPI.hooks.stageIsReadyAndVisible.promise,
      scope.editorCoreAPI.hooks.sectionsBootstrapFinished.promise,
    ]);
    if (!isInZoomMode(scope)) {
      // TODO: find a better solution to wait for viewer finish rendering all elements so all of them will get the scale
      await new Promise((resolve) => setTimeout(resolve, 200));
      await scope.editorAPI.waitForChangesAppliedAsync();
      await enterZoomMode(scope, {
        biParams: {
          origin: 'openEditor',
          is_section: true,
          is_site_creation: false,
        },
      });
    }
  }

  scope.editorCoreAPI.hooks.enterZoomModeAtStartup.resolve();

  setShouldOpenInZoomFromLocalStorage(false);
};
