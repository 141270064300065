'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')
const i18n = require('@/i18n')

module.exports = {
    "type": addPanelDataConsts.SECTIONS_TYPES.PRESET,
    "hide": false,
    "title": "",
    "subNavigationHide": true,
    "automationId": "add-panel-section-boxSlideShowSection",
    "showSectionHeader": false,
    "additionalBehaviours": {
        "labelMode": addPanelDataConsts.LABEL_BEHAVIOUR.HOVER,
        "infoIcon": addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
        "hoverImageAction": addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
        "iconEnabledForComps": {}
    },
    "props": {
        "image": "addPanelData/sections/boxSlideShowSection_en/boxSlideShowSection_en.png",
        "imageHover": null,
        "items": [{
            "id": "BoxSlideShow_BoxSlideShow_1",
            "structure": {
                "type": "Container",
                "skin": "wysiwyg.common.components.boxSlideShow.viewer.skins.thinArrowsLargeSelectedCircleSkin",
                "layout": {
                    "width": 490,
                    "height": 380,
                    "x": 116,
                    "y": 80,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.BoxSlideShow",
                "components": [{
                    "type": "Container",
                    "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                    "layout": {
                        "width": 490,
                        "height": 380,
                        "x": 0,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.BoxSlideShowSlide",
                    "components": [{
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.photo.NoSkinPhoto",
                        "layout": {
                            "width": 417,
                            "height": 303,
                            "x": 36,
                            "y": 30,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WPhoto",
                        "data": {
                            "type": "Image",
                            "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                            "title": "The Wedding Aisle",
                            "uri": "387d6927ca6a4bbab2d424ef39f76cab.jpg",
                            "description": "",
                            "width": 3266,
                            "height": 2188,
                            "alt": "",
                            "crop": {"x": 1796, "y": 764, "width": 1163, "height": 684}
                        },
                        "props": {
                            "type": "WPhotoProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "displayMode": "fill",
                            "onClickBehavior": "goToLink",
                            "effectName": "none"
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                            "componentClassName": "wysiwyg.viewer.components.WPhoto",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.photo.NoSkinPhoto"
                        },
                        "mobileStructure": {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.photo.NoSkinPhoto",
                            "layout": {
                                "width": 189,
                                "height": 399,
                                "x": 45,
                                "y": 40,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WPhoto",
                            "data": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                                "title": "The Wedding Aisle",
                                "uri": "387d6927ca6a4bbab2d424ef39f76cab.jpg",
                                "description": "",
                                "width": 3266,
                                "height": 2188,
                                "alt": "",
                                "crop": {"x": 1796, "y": 764, "width": 1163, "height": 684}
                            },
                            "props": {
                                "type": "WPhotoProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "displayMode": "fill",
                                "onClickBehavior": "goToLink",
                                "effectName": "none"
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                                "componentClassName": "wysiwyg.viewer.components.WPhoto",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.photo.NoSkinPhoto"
                            }
                        }
                    }, {
                        "type": "Container",
                        "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                        "layout": {
                            "width": 236,
                            "height": 247,
                            "x": 126,
                            "y": 55,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "mobile.core.components.Container",
                        "components": [{
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.line.SolidLine",
                            "layout": {
                                "width": 23,
                                "height": 5,
                                "x": 106,
                                "y": 94,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.FiveGridLine",
                            "props": {
                                "type": "FiveGridLineProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "fullScreenModeOn": false
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {"alpha-brd": "1", "brd": "#626262", "lnw": "1px"},
                                    "propertiesSource": {"brd": "value", "lnw": "value"}
                                },
                                "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.line.SolidLine"
                            },
                            "mobileStructure": {
                                "type": "Component",
                                "skin": "wysiwyg.viewer.skins.line.SolidLine",
                                "layout": {
                                    "width": 23,
                                    "height": 5,
                                    "x": 65,
                                    "y": 114,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.FiveGridLine",
                                "props": {
                                    "type": "FiveGridLineProperties",
                                    "metaData": {"schemaVersion": "1.0"},
                                    "fullScreenModeOn": false
                                },
                                "style": {
                                    "type": "TopLevelStyle",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "style": {
                                        "groups": {},
                                        "properties": {"alpha-brd": "1", "brd": "#626262", "lnw": "1px"},
                                        "propertiesSource": {"brd": "value", "lnw": "value"}
                                    },
                                    "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                                    "pageId": "",
                                    "compId": "",
                                    "styleType": "custom",
                                    "skin": "wysiwyg.viewer.skins.line.SolidLine"
                                }
                            }
                        }, {
                            "type": "Component",
                            "layout": {
                                "width": 192,
                                "height": 28,
                                "x": 22,
                                "y": 106,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p style=\"text-align: center; font-size: 20px;\" class=\"font_8\"><span style=\"font-style:italic;\"><span style=\"font-family:palatino linotype,serif;\"><span style=\"font-size:20px;\"><span style=\"letter-spacing:0.03em;\"><span style=\"color:#626262;\">3/12/2023</span></span></span></span></span></p>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew",
                            "mobileStructure": {
                                "type": "Component",
                                "layout": {
                                    "width": 120,
                                    "height": 28,
                                    "x": 17,
                                    "y": 127,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "data": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<p style=\"text-align: center; font-size: 20px;\" class=\"font_8\"><span style=\"font-style:italic;\"><span style=\"font-family:palatino linotype,serif;\"><span style=\"font-size:20px;\"><span style=\"letter-spacing:0.03em;\"><span style=\"color:#626262;\">3/12/2023</span></span></span></span></span></p>",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "props": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                },
                                "style": "txtNew"
                            }
                        }, {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.button.BasicButton",
                            "layout": {
                                "width": 120,
                                "height": 35,
                                "x": 58,
                                "y": 174,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.SiteButton",
                            "data": {
                                "type": "LinkableButton",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "label": "RSVP"
                            },
                            "props": {
                                "type": "ButtonProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "align": "center",
                                "margin": 0
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {
                                        "alpha-bg": "0",
                                        "alpha-bgh": "1",
                                        "alpha-brd": "1",
                                        "alpha-brdh": "1",
                                        "bg": "#626262",
                                        "bgh": "#626262",
                                        "boxShadowToggleOn-shd": "false",
                                        "brd": "#626262",
                                        "brdh": "#626262",
                                        "brw": "1",
                                        "fnt": "normal normal normal 14px/1.4em palatino+linotype",
                                        "rd": "0",
                                        "shd": "0 1px 4px rgba(0, 0, 0, 0.6)",
                                        "txt": "#626262",
                                        "txth": "#EBEBEB"
                                    },
                                    "propertiesSource": {
                                        "alpha-bg": "value",
                                        "alpha-bgh": "value",
                                        "alpha-brd": "value",
                                        "alpha-brdh": "value",
                                        "bg": "value",
                                        "bgh": "value",
                                        "boxShadowToggleOn-shd": "value",
                                        "brd": "value",
                                        "brdh": "value",
                                        "brw": "value",
                                        "fnt": "value",
                                        "rd": "value",
                                        "shd": "value",
                                        "txt": "value",
                                        "txth": "value"
                                    }
                                },
                                "componentClassName": "wysiwyg.viewer.components.SiteButton",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.button.BasicButton"
                            },
                            "mobileStructure": {
                                "type": "Component",
                                "skin": "wysiwyg.viewer.skins.button.BasicButton",
                                "layout": {
                                    "width": 120,
                                    "height": 35,
                                    "x": 17,
                                    "y": 177,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.SiteButton",
                                "data": {
                                    "type": "LinkableButton",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "label": "RSVP"
                                },
                                "props": {
                                    "type": "ButtonProperties",
                                    "metaData": {"schemaVersion": "1.0"},
                                    "align": "center",
                                    "margin": 0
                                },
                                "style": {
                                    "type": "TopLevelStyle",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "style": {
                                        "groups": {},
                                        "properties": {
                                            "alpha-bg": "0",
                                            "alpha-bgh": "1",
                                            "alpha-brd": "1",
                                            "alpha-brdh": "1",
                                            "bg": "#626262",
                                            "bgh": "#626262",
                                            "boxShadowToggleOn-shd": "false",
                                            "brd": "#626262",
                                            "brdh": "#626262",
                                            "brw": "1",
                                            "fnt": "normal normal normal 14px/1.4em palatino+linotype",
                                            "rd": "0",
                                            "shd": "0 1px 4px rgba(0, 0, 0, 0.6)",
                                            "txt": "#626262",
                                            "txth": "#EBEBEB"
                                        },
                                        "propertiesSource": {
                                            "alpha-bg": "value",
                                            "alpha-bgh": "value",
                                            "alpha-brd": "value",
                                            "alpha-brdh": "value",
                                            "bg": "value",
                                            "bgh": "value",
                                            "boxShadowToggleOn-shd": "value",
                                            "brd": "value",
                                            "brdh": "value",
                                            "brw": "value",
                                            "fnt": "value",
                                            "rd": "value",
                                            "shd": "value",
                                            "txt": "value",
                                            "txth": "value"
                                        }
                                    },
                                    "componentClassName": "wysiwyg.viewer.components.SiteButton",
                                    "pageId": "",
                                    "compId": "",
                                    "styleType": "custom",
                                    "skin": "wysiwyg.viewer.skins.button.BasicButton"
                                }
                            }
                        }, {
                            "type": "Component",
                            "layout": {
                                "width": 218,
                                "height": 39,
                                "x": 9,
                                "y": 48,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 style=\"text-align: center; font-size: 28px;\" class=\"font_2\"><span style=\"font-size:28px;\"><span style=\"letter-spacing:0.25em;\"><span style=\"font-family:palatino linotype,serif;\"><span style=\"color:#626262;\">IT'S ON</span></span></span></span></h2>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew",
                            "mobileStructure": {
                                "type": "Component",
                                "layout": {
                                    "width": 105,
                                    "height": 72,
                                    "x": 28,
                                    "y": 30,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "data": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<h2 style=\"text-align: center; font-size: 28px;\" class=\"font_2\"><span style=\"font-size:28px;\"><span style=\"letter-spacing:0.25em;\"><span style=\"font-family:palatino linotype,serif;\"><span style=\"color:#626262;\">IT'S ON</span></span></span></span></h2>",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "props": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                },
                                "style": "txtNew"
                            }
                        }],
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "alpha-bg": "0.8",
                                    "alpha-brd": "1",
                                    "bg": "#FFFFFF",
                                    "boxShadowToggleOn-shd": "false",
                                    "brd": "#2F2E2E",
                                    "brw": "0",
                                    "rd": "0px",
                                    "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                                },
                                "propertiesSource": {
                                    "alpha-bg": "value",
                                    "bg": "value",
                                    "brd": "value",
                                    "brw": "value",
                                    "rd": "value",
                                    "shd": "value"
                                }
                            },
                            "componentClassName": "mobile.core.components.Container",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                        },
                        "mobileStructure": {
                            "type": "Container",
                            "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                            "layout": {
                                "width": 154,
                                "height": 252,
                                "x": 63,
                                "y": 117,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "mobile.core.components.Container",
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {
                                        "alpha-bg": "0.8",
                                        "alpha-brd": "1",
                                        "bg": "#FFFFFF",
                                        "boxShadowToggleOn-shd": "false",
                                        "brd": "#2F2E2E",
                                        "brw": "0",
                                        "rd": "0px",
                                        "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                                    },
                                    "propertiesSource": {
                                        "alpha-bg": "value",
                                        "bg": "value",
                                        "brd": "value",
                                        "brw": "value",
                                        "rd": "value",
                                        "shd": "value"
                                    }
                                },
                                "componentClassName": "mobile.core.components.Container",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                            }
                        }
                    }],
                    "data": {
                        "type": "BoxSlideShowSlide",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "1"})
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {"bg": "#001F2C", "brd": "color_19", "brw": "0", "rd": "0px"},
                            "propertiesSource": {"bg": "value"}
                        },
                        "componentClassName": "wysiwyg.viewer.components.BoxSlideShowSlide",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "color": "#EBEBEB",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "mobileStructure": {
                        "type": "Container",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                        "layout": {
                            "width": 280,
                            "height": 497,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.BoxSlideShowSlide",
                        "data": {
                            "type": "BoxSlideShowSlide",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "1"})
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {"bg": "#001F2C", "brd": "color_19", "brw": "0", "rd": "0px"},
                                "propertiesSource": {"bg": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.BoxSlideShowSlide",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "#EBEBEB",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            }
                        }
                    }
                }, {
                    "type": "Container",
                    "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                    "layout": {
                        "width": 490,
                        "height": 380,
                        "x": 0,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.BoxSlideShowSlide",
                    "components": [{
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.line.SolidLine",
                        "layout": {
                            "width": 23,
                            "height": 5,
                            "x": 346,
                            "y": 106,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.FiveGridLine",
                        "props": {
                            "type": "FiveGridLineProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "fullScreenModeOn": false
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {"alpha-brd": "1", "brd": "#626262", "lnw": "1px"},
                                "propertiesSource": {"brd": "value", "lnw": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.line.SolidLine"
                        },
                        "mobileStructure": {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.line.SolidLine",
                            "layout": {
                                "width": 23,
                                "height": 5,
                                "x": 129,
                                "y": 63,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.FiveGridLine",
                            "props": {
                                "type": "FiveGridLineProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "fullScreenModeOn": false
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {"alpha-brd": "1", "brd": "#626262", "lnw": "1px"},
                                    "propertiesSource": {"brd": "value", "lnw": "value"}
                                },
                                "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.line.SolidLine"
                            }
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 215,
                            "height": 28,
                            "x": 248,
                            "y": 122,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<p style=\"text-align: center; font-size: 20px;\" class=\"font_8\"><span style=\"font-style:italic;\"><span style=\"font-family:palatino linotype,serif;\"><span style=\"font-size:20px;\"><span style=\"letter-spacing:0.03em;\"><span style=\"color:#626262;\">3/12/2023</span></span></span></span></span></p>",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 209,
                                "height": 28,
                                "x": 36,
                                "y": 77,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p style=\"text-align: center; font-size: 20px;\" class=\"font_8\"><span style=\"font-style:italic;\"><span style=\"font-family:palatino linotype,serif;\"><span style=\"font-size:20px;\"><span style=\"letter-spacing:0.03em;\"><span style=\"color:#626262;\">3/12/2023</span></span></span></span></span></p>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 198,
                            "height": 39,
                            "x": 260,
                            "y": 54,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<h2 style=\"text-align: center; font-size: 28px;\" class=\"font_2\"><span style=\"font-size:28px;\"><span style=\"letter-spacing:0.25em;\"><span style=\"font-family:palatino linotype,serif;\"><span style=\"color:#626262;\">TITLE</span></span></span></span></h2>",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 150,
                                "height": 36,
                                "x": 65,
                                "y": 17,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 style=\"text-align: center; font-size: 28px;\" class=\"font_2\"><span style=\"font-size:28px;\"><span style=\"letter-spacing:0.25em;\"><span style=\"font-family:palatino linotype,serif;\"><span style=\"color:#626262;\">TITLE</span></span></span></span></h2>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 155,
                            "height": 54,
                            "x": 282,
                            "y": 181,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<p style=\"text-align: center; font-size: 14px; line-height: 1.2em;\" class=\"font_8\"><span style=\"color:#626262;\"><span style=\"font-size:14px;\"><span style=\"line-height:1.2em;\"><span style=\"font-style:italic;\"><span style=\"font-family:palatino linotype,serif;\">Describe your event here. Where's it happening, and when does it start?</span></span></span></span></span></p>",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 209,
                                "height": 36,
                                "x": 36,
                                "y": 113,
                                "scale": 0.9476249999999999,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p style=\"text-align: center; font-size: 14px; line-height: 1.2em;\" class=\"font_8\"><span style=\"color:#626262;\"><span style=\"font-size:14px;\"><span style=\"line-height:1.2em;\"><span style=\"font-style:italic;\"><span style=\"font-family:palatino linotype,serif;\">Describe your event here. Where's it happening, and when does it start?</span></span></span></span></span></p>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.button.BasicButton",
                        "layout": {
                            "width": 130,
                            "height": 35,
                            "x": 294,
                            "y": 267,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.SiteButton",
                        "data": {
                            "type": "LinkableButton",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "label": "RSVP"
                        },
                        "props": {
                            "type": "ButtonProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "align": "center",
                            "margin": 0
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "alpha-bg": "0",
                                    "alpha-bgh": "1",
                                    "alpha-brd": "1",
                                    "alpha-brdh": "1",
                                    "bg": "#626262",
                                    "bgh": "#626262",
                                    "boxShadowToggleOn-shd": "false",
                                    "brd": "#626262",
                                    "brdh": "#626262",
                                    "brw": "1",
                                    "fnt": "normal normal normal 14px/1.4em palatino+linotype",
                                    "rd": "0",
                                    "shd": "0 1px 4px rgba(0, 0, 0, 0.6)",
                                    "txt": "#626262",
                                    "txth": "#EBEBEB"
                                },
                                "propertiesSource": {
                                    "alpha-bg": "value",
                                    "alpha-bgh": "value",
                                    "alpha-brd": "value",
                                    "alpha-brdh": "value",
                                    "bg": "value",
                                    "bgh": "value",
                                    "boxShadowToggleOn-shd": "value",
                                    "brd": "value",
                                    "brdh": "value",
                                    "brw": "value",
                                    "fnt": "value",
                                    "rd": "value",
                                    "shd": "value",
                                    "txt": "value",
                                    "txth": "value"
                                }
                            },
                            "componentClassName": "wysiwyg.viewer.components.SiteButton",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.button.BasicButton"
                        },
                        "mobileStructure": {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.button.BasicButton",
                            "layout": {
                                "width": 130,
                                "height": 35,
                                "x": 74,
                                "y": 404,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.SiteButton",
                            "data": {
                                "type": "LinkableButton",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "label": "RSVP"
                            },
                            "props": {
                                "type": "ButtonProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "align": "center",
                                "margin": 0
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {
                                        "alpha-bg": "0",
                                        "alpha-bgh": "1",
                                        "alpha-brd": "1",
                                        "alpha-brdh": "1",
                                        "bg": "#626262",
                                        "bgh": "#626262",
                                        "boxShadowToggleOn-shd": "false",
                                        "brd": "#626262",
                                        "brdh": "#626262",
                                        "brw": "1",
                                        "fnt": "normal normal normal 14px/1.4em palatino+linotype",
                                        "rd": "0",
                                        "shd": "0 1px 4px rgba(0, 0, 0, 0.6)",
                                        "txt": "#626262",
                                        "txth": "#EBEBEB"
                                    },
                                    "propertiesSource": {
                                        "alpha-bg": "value",
                                        "alpha-bgh": "value",
                                        "alpha-brd": "value",
                                        "alpha-brdh": "value",
                                        "bg": "value",
                                        "bgh": "value",
                                        "boxShadowToggleOn-shd": "value",
                                        "brd": "value",
                                        "brdh": "value",
                                        "brw": "value",
                                        "fnt": "value",
                                        "rd": "value",
                                        "shd": "value",
                                        "txt": "value",
                                        "txth": "value"
                                    }
                                },
                                "componentClassName": "wysiwyg.viewer.components.SiteButton",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.button.BasicButton"
                            }
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 209,
                            "height": 297,
                            "x": 37,
                            "y": 32,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WPhoto",
                        "data": {
                            "type": "Image",
                            "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                            "title": "Vintage Table Setting",
                            "uri": "85607039b8ac4f939ffcd7cb89f35cc9.jpg",
                            "description": "",
                            "width": 1920,
                            "height": 1248,
                            "alt": "",
                            "crop": {"x": 220, "y": 0, "width": 1017, "height": 1248}
                        },
                        "props": {
                            "type": "WPhotoProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "displayMode": "fill",
                            "onClickBehavior": "goToLink",
                            "effectName": "none"
                        },
                        "style": "wp2",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 189,
                                "height": 212,
                                "x": 46,
                                "y": 167,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WPhoto",
                            "data": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                                "title": "Vintage Table Setting",
                                "uri": "85607039b8ac4f939ffcd7cb89f35cc9.jpg",
                                "description": "",
                                "width": 1920,
                                "height": 1248,
                                "alt": "",
                                "crop": {"x": 220, "y": 0, "width": 1017, "height": 1248}
                            },
                            "props": {
                                "type": "WPhotoProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "displayMode": "fill",
                                "onClickBehavior": "goToLink",
                                "effectName": "none"
                            },
                            "style": "wp2"
                        }
                    }],
                    "data": {
                        "type": "BoxSlideShowSlide",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "2"})
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {"bg": "#001F2C", "brd": "color_19", "brw": "0", "rd": "0px"},
                            "propertiesSource": {"bg": "value"}
                        },
                        "componentClassName": "wysiwyg.viewer.components.BoxSlideShowSlide",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "color": "#EBEBEB",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "mobileStructure": {
                        "type": "Container",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                        "layout": {
                            "width": 280,
                            "height": 497,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.BoxSlideShowSlide",
                        "data": {
                            "type": "BoxSlideShowSlide",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "2"})
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {"bg": "#001F2C", "brd": "color_19", "brw": "0", "rd": "0px"},
                                "propertiesSource": {"bg": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.BoxSlideShowSlide",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "#EBEBEB",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            }
                        }
                    }
                }, {
                    "type": "Container",
                    "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                    "layout": {
                        "width": 490,
                        "height": 380,
                        "x": 0,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.BoxSlideShowSlide",
                    "components": [{
                        "type": "Component",
                        "layout": {
                            "width": 209,
                            "height": 297,
                            "x": 37,
                            "y": 32,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WPhoto",
                        "data": {
                            "type": "Image",
                            "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                            "title": "Mojitos",
                            "uri": "edd67875ef8b46efbfe8adb4d26bdaba.jpg",
                            "description": "",
                            "width": 1204,
                            "height": 1920,
                            "alt": "",
                            "crop": {"x": 186, "y": 709, "width": 436, "height": 565}
                        },
                        "props": {
                            "type": "WPhotoProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "displayMode": "fill",
                            "onClickBehavior": "goToLink",
                            "effectName": "none"
                        },
                        "style": "wp2",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 189,
                                "height": 212,
                                "x": 46,
                                "y": 167,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WPhoto",
                            "data": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                                "title": "Mojitos",
                                "uri": "edd67875ef8b46efbfe8adb4d26bdaba.jpg",
                                "description": "",
                                "width": 1204,
                                "height": 1920,
                                "alt": "",
                                "crop": {"x": 186, "y": 709, "width": 436, "height": 565}
                            },
                            "props": {
                                "type": "WPhotoProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "displayMode": "fill",
                                "onClickBehavior": "goToLink",
                                "effectName": "none"
                            },
                            "style": "wp2"
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 230,
                            "height": 39,
                            "x": 244,
                            "y": 54,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<h2 style=\"text-align: center; font-size: 28px;\" class=\"font_2\"><span style=\"font-size:28px;\"><span style=\"letter-spacing:0.25em;\"><span style=\"font-family:palatino linotype,serif;\"><span style=\"color:#626262;\">TITLE</span></span></span></span></h2>",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 150,
                                "height": 36,
                                "x": 65,
                                "y": 17,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 style=\"text-align: center; font-size: 28px;\" class=\"font_2\"><span style=\"font-size:28px;\"><span style=\"letter-spacing:0.25em;\"><span style=\"font-family:palatino linotype,serif;\"><span style=\"color:#626262;\">TITLE</span></span></span></span></h2>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.line.SolidLine",
                        "layout": {
                            "width": 23,
                            "height": 5,
                            "x": 345,
                            "y": 127,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.FiveGridLine",
                        "props": {
                            "type": "FiveGridLineProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "fullScreenModeOn": false
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {"alpha-brd": "1", "brd": "#626262", "lnw": "1px"},
                                "propertiesSource": {"brd": "value", "lnw": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.line.SolidLine"
                        },
                        "mobileStructure": {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.line.SolidLine",
                            "layout": {
                                "width": 23,
                                "height": 5,
                                "x": 129,
                                "y": 63,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.FiveGridLine",
                            "props": {
                                "type": "FiveGridLineProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "fullScreenModeOn": false
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {"alpha-brd": "1", "brd": "#626262", "lnw": "1px"},
                                    "propertiesSource": {"brd": "value", "lnw": "value"}
                                },
                                "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.line.SolidLine"
                            }
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 215,
                            "height": 28,
                            "x": 251,
                            "y": 143,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<p style=\"text-align: center; font-size: 20px;\" class=\"font_8\"><span style=\"font-style:italic;\"><span style=\"font-family:palatino linotype,serif;\"><span style=\"font-size:20px;\"><span style=\"letter-spacing:0.03em;\"><span style=\"color:#626262;\">3/14/2023</span></span></span></span></span></p>",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 209,
                                "height": 28,
                                "x": 36,
                                "y": 76,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p style=\"text-align: center; font-size: 20px;\" class=\"font_8\"><span style=\"font-style:italic;\"><span style=\"font-family:palatino linotype,serif;\"><span style=\"font-size:20px;\"><span style=\"letter-spacing:0.03em;\"><span style=\"color:#626262;\">3/14/2023</span></span></span></span></span></p>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 155,
                            "height": 54,
                            "x": 281,
                            "y": 200,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<p style=\"text-align: center; font-size: 14px; line-height: 1.2em;\" class=\"font_8\"><span style=\"color:#626262;\"><span style=\"font-size:14px;\"><span style=\"line-height:1.2em;\"><span style=\"font-style:italic;\"><span style=\"font-family:palatino linotype,serif;\">Describe your event here. Where's it happening, and when does it start?</span></span></span></span></span></p>",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 209,
                                "height": 36,
                                "x": 36,
                                "y": 113,
                                "scale": 0.95,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p style=\"text-align: center; font-size: 14px; line-height: 1.2em;\" class=\"font_8\"><span style=\"color:#626262;\"><span style=\"font-size:14px;\"><span style=\"line-height:1.2em;\"><span style=\"font-style:italic;\"><span style=\"font-family:palatino linotype,serif;\">Describe your event here. Where's it happening, and when does it start?</span></span></span></span></span></p>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.button.BasicButton",
                        "layout": {
                            "width": 130,
                            "height": 35,
                            "x": 293,
                            "y": 269,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.SiteButton",
                        "data": {
                            "type": "LinkableButton",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "label": "RSVP"
                        },
                        "props": {
                            "type": "ButtonProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "align": "center",
                            "margin": 0
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "alpha-bg": "0",
                                    "alpha-bgh": "1",
                                    "alpha-brd": "1",
                                    "alpha-brdh": "1",
                                    "bg": "#626262",
                                    "bgh": "#626262",
                                    "boxShadowToggleOn-shd": "false",
                                    "brd": "#626262",
                                    "brdh": "#626262",
                                    "brw": "1",
                                    "fnt": "normal normal normal 14px/1.4em palatino+linotype",
                                    "rd": "0",
                                    "shd": "0 1px 4px rgba(0, 0, 0, 0.6)",
                                    "txt": "#626262",
                                    "txth": "#EBEBEB"
                                },
                                "propertiesSource": {
                                    "alpha-bg": "value",
                                    "alpha-bgh": "value",
                                    "alpha-brd": "value",
                                    "alpha-brdh": "value",
                                    "bg": "value",
                                    "bgh": "value",
                                    "boxShadowToggleOn-shd": "value",
                                    "brd": "value",
                                    "brdh": "value",
                                    "brw": "value",
                                    "fnt": "value",
                                    "rd": "value",
                                    "shd": "value",
                                    "txt": "value",
                                    "txth": "value"
                                }
                            },
                            "componentClassName": "wysiwyg.viewer.components.SiteButton",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.button.BasicButton"
                        },
                        "mobileStructure": {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.button.BasicButton",
                            "layout": {
                                "width": 130,
                                "height": 35,
                                "x": 74,
                                "y": 404,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.SiteButton",
                            "data": {
                                "type": "LinkableButton",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "label": "RSVP"
                            },
                            "props": {
                                "type": "ButtonProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "align": "center",
                                "margin": 0
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {
                                        "alpha-bg": "0",
                                        "alpha-bgh": "1",
                                        "alpha-brd": "1",
                                        "alpha-brdh": "1",
                                        "bg": "#626262",
                                        "bgh": "#626262",
                                        "boxShadowToggleOn-shd": "false",
                                        "brd": "#626262",
                                        "brdh": "#626262",
                                        "brw": "1",
                                        "fnt": "normal normal normal 14px/1.4em palatino+linotype",
                                        "rd": "0",
                                        "shd": "0 1px 4px rgba(0, 0, 0, 0.6)",
                                        "txt": "#626262",
                                        "txth": "#EBEBEB"
                                    },
                                    "propertiesSource": {
                                        "alpha-bg": "value",
                                        "alpha-bgh": "value",
                                        "alpha-brd": "value",
                                        "alpha-brdh": "value",
                                        "bg": "value",
                                        "bgh": "value",
                                        "boxShadowToggleOn-shd": "value",
                                        "brd": "value",
                                        "brdh": "value",
                                        "brw": "value",
                                        "fnt": "value",
                                        "rd": "value",
                                        "shd": "value",
                                        "txt": "value",
                                        "txth": "value"
                                    }
                                },
                                "componentClassName": "wysiwyg.viewer.components.SiteButton",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.button.BasicButton"
                            }
                        }
                    }],
                    "data": {
                        "type": "BoxSlideShowSlide",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "3"})
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {"bg": "#001F2C", "brd": "color_19", "brw": "0", "rd": "0px"},
                            "propertiesSource": {"bg": "value"}
                        },
                        "componentClassName": "wysiwyg.viewer.components.BoxSlideShowSlide",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "color": "#EBEBEB",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "mobileStructure": {
                        "type": "Container",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                        "layout": {
                            "width": 280,
                            "height": 497,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.BoxSlideShowSlide",
                        "data": {
                            "type": "BoxSlideShowSlide",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "3"})
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {"bg": "#001F2C", "brd": "color_19", "brw": "0", "rd": "0px"},
                                "propertiesSource": {"bg": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.BoxSlideShowSlide",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "#EBEBEB",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            }
                        }
                    }
                }],
                "props": {
                    "type": "BoxSlideShowProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "transition": "SlideHorizontal",
                    "autoPlayInterval": 3,
                    "autoPlay": true,
                    "transDuration": 1,
                    "pauseAutoPlayOnMouseOver": true,
                    "direction": "RTL",
                    "shouldHideOverflowContent": true,
                    "flexibleBoxHeight": false,
                    "showNavigationButton": true,
                    "showNavigationDots": true,
                    "navigationButtonSize": 10,
                    "navigationButtonMargin": 18,
                    "navigationDotsSize": 6,
                    "navigationDotsMargin": 23,
                    "navigationDotsGap": 11,
                    "navigationDotsAlignment": "center"
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-arrowColor": "1",
                            "alpha-brd": "0",
                            "alpha-dotsColor": "1",
                            "arrowColor": "#626262",
                            "boxShadowToggleOn-shd": "true",
                            "brw": "0",
                            "dotsColor": "#626262",
                            "shd": "0 0 0 rgba(0, 0, 0, 0.6)"
                        },
                        "propertiesSource": {
                            "alpha-arrowColor": "value",
                            "alpha-brd": "value",
                            "alpha-dotsColor": "value",
                            "arrowColor": "value",
                            "boxShadowToggleOn-shd": "value",
                            "brd": "value",
                            "brw": "value",
                            "dotsColor": "value",
                            "shd": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.BoxSlideShow",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.common.components.boxSlideShow.viewer.skins.thinArrowsLargeSelectedCircleSkin"
                },
                "mobileStructure": {
                    "type": "Container",
                    "skin": "wysiwyg.common.components.boxSlideShow.viewer.skins.thinArrowsLargeSelectedCircleSkin",
                    "layout": {
                        "width": 280,
                        "height": 497,
                        "x": 0,
                        "y": 10,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.BoxSlideShow",
                    "props": {
                        "type": "BoxSlideShowProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": true},
                        "transition": "SlideHorizontal",
                        "autoPlayInterval": 2,
                        "autoPlay": false,
                        "transDuration": 1,
                        "pauseAutoPlayOnMouseOver": true,
                        "direction": "LTR",
                        "shouldHideOverflowContent": true,
                        "flexibleBoxHeight": false,
                        "showNavigationButton": true,
                        "showNavigationDots": true,
                        "navigationButtonSize": 15,
                        "navigationButtonMargin": 22,
                        "navigationDotsSize": 6,
                        "navigationDotsMargin": 24,
                        "navigationDotsGap": 15,
                        "navigationDotsAlignment": "center"
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-arrowColor": "1",
                                "alpha-brd": "0",
                                "alpha-dotsColor": "1",
                                "arrowColor": "#626262",
                                "boxShadowToggleOn-shd": "true",
                                "brw": "0",
                                "dotsColor": "#626262",
                                "shd": "0 0 0 rgba(0, 0, 0, 0.6)"
                            },
                            "propertiesSource": {
                                "alpha-arrowColor": "value",
                                "alpha-brd": "value",
                                "alpha-dotsColor": "value",
                                "arrowColor": "value",
                                "boxShadowToggleOn-shd": "value",
                                "brd": "value",
                                "brw": "value",
                                "dotsColor": "value",
                                "shd": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.viewer.components.BoxSlideShow",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.boxSlideShow.viewer.skins.thinArrowsLargeSelectedCircleSkin"
                    }
                },
                "id": "comp-im3f13vb"
            },
            "preset": {
                "rect": {"width": 162, "height": 134.5, "x": 0, "y": 0},
                "label": "add_preset_BoxSlideShow_1",
                "tags": null
            }
        }, {
            "id": "BoxSlideShow_BoxSlideShow_2",
            "structure": {
                "type": "Container",
                "skin": "wysiwyg.common.components.boxSlideShow.viewer.skins.thinArrowsLargeSelectedCircleSkin",
                "layout": {
                    "width": 490,
                    "height": 380,
                    "x": 687,
                    "y": 80,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.BoxSlideShow",
                "components": [{
                    "type": "Container",
                    "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                    "layout": {
                        "width": 490,
                        "height": 380,
                        "x": 0,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.BoxSlideShowSlide",
                    "components": [{
                        "type": "Component",
                        "layout": {
                            "width": 210,
                            "height": 45,
                            "x": 262,
                            "y": 49,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<h2 style=\"font-size: 30px;\" class=\"font_2\"><span style=\"font-size:30px;\"><span class=\"color_11\"><span style=\"font-family:anton,sans-serif;\">ADD YOUR&nbsp;TITLE</span></span></span></h2>",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 213,
                                "height": 40,
                                "x": 20,
                                "y": 16,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 style=\"font-size: 30px;\" class=\"font_2\"><span style=\"font-size:30px;\"><span class=\"color_11\"><span style=\"font-family:anton,sans-serif;\">ADD YOUR&nbsp;TITLE</span></span></span></h2>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 149,
                            "height": 44,
                            "x": 262,
                            "y": 108,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<p class=\"font_8\" style=\"font-size:17px; line-height:1.2em\"><span style=\"font-family:avenir-lt-w01_85-heavy1475544,sans-serif;\"><span style=\"font-size:17px\"><span style=\"letter-spacing:0.02em\"><span class=\"color_11\">Your tagline fits right here...</span></span></span></span></p>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 228,
                                "height": 19,
                                "x": 20,
                                "y": 61,
                                "scale": 0.9025,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p class=\"font_8\" style=\"font-size:17px; line-height:1.2em\"><span style=\"font-family:avenir-lt-w01_85-heavy1475544,sans-serif;\"><span style=\"font-size:17px\"><span style=\"letter-spacing:0.02em\"><span class=\"color_11\">Your tagline fits right here...</span></span></span></span></p>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 162,
                            "height": 100,
                            "x": 262,
                            "y": 170,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<p class=\"font_8\" style=\"font-size: 14px; line-height: 1.4em;\"><span style=\"font-size:14px\"><span><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\"><span class=\"color_11\">Tell customers more about you. Add a few words and a stunning pic to grab their attention and get them to click.</span></span></span></span></p>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 243,
                                "height": 60,
                                "x": 21,
                                "y": 93,
                                "scale": 0.95,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p class=\"font_8\" style=\"font-size: 14px; line-height: 1.4em;\"><span style=\"font-size:14px\"><span><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\"><span class=\"color_11\">Tell customers more about you. Add a few words and a stunning pic to grab their attention and get them to click.</span></span></span></span></p>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 200,
                            "height": 380,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WPhoto",
                        "data": {
                            "type": "Image",
                            "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                            "title": "bigstock-close-up-wooden-cut-texture-16723160.jpg",
                            "uri": "035244_adff98f0ada74c3ab29ca158cb20bce8.jpg",
                            "description": "",
                            "width": 4176,
                            "height": 4224,
                            "alt": "",
                            "crop": {"x": 1288, "y": 911, "width": 1607, "height": 2402}
                        },
                        "props": {
                            "type": "WPhotoProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "displayMode": "fill",
                            "onClickBehavior": "goToLink",
                            "effectName": "kennedy"
                        },
                        "style": "wp2",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 280,
                                "height": 226,
                                "x": 0,
                                "y": 172,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WPhoto",
                            "data": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                                "title": "bigstock-close-up-wooden-cut-texture-16723160.jpg",
                                "uri": "035244_adff98f0ada74c3ab29ca158cb20bce8.jpg",
                                "description": "",
                                "width": 4176,
                                "height": 4224,
                                "alt": "",
                                "crop": {"x": 1288, "y": 911, "width": 1607, "height": 2402}
                            },
                            "props": {
                                "type": "WPhotoProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "displayMode": "fill",
                                "onClickBehavior": "goToLink",
                                "effectName": "kennedy"
                            },
                            "style": "wp2"
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 211,
                            "height": 304,
                            "x": 30,
                            "y": 25,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WPhoto",
                        "data": {
                            "type": "Image",
                            "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                            "title": "Pretty White Blouse",
                            "uri": "592f0fd031bb4693a653dd966e1b75c3.png",
                            "description": "",
                            "width": 1000,
                            "height": 1000,
                            "alt": "",
                            "crop": {"x": 394, "y": 0, "width": 288, "height": 348}
                        },
                        "props": {
                            "type": "WPhotoProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "displayMode": "fill",
                            "onClickBehavior": "goToLink",
                            "effectName": "none"
                        },
                        "style": "wp2",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 199,
                                "height": 226,
                                "x": 0,
                                "y": 172,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WPhoto",
                            "data": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                                "title": "Pretty White Blouse",
                                "uri": "592f0fd031bb4693a653dd966e1b75c3.png",
                                "description": "",
                                "width": 1000,
                                "height": 1000,
                                "alt": "",
                                "crop": {"x": 394, "y": 0, "width": 288, "height": 348}
                            },
                            "props": {
                                "type": "WPhotoProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "displayMode": "fill",
                                "onClickBehavior": "goToLink",
                                "effectName": "none"
                            },
                            "style": "wp2"
                        }
                    }],
                    "data": {
                        "type": "BoxSlideShowSlide",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "1"})
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {"bg": "#001F2C", "brd": "color_19", "brw": "0", "rd": "0px"},
                            "propertiesSource": {"bg": "value"}
                        },
                        "componentClassName": "wysiwyg.viewer.components.BoxSlideShowSlide",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "color": "#363535",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "mobileStructure": {
                        "type": "Container",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                        "layout": {
                            "width": 280,
                            "height": 460,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.BoxSlideShowSlide",
                        "data": {
                            "type": "BoxSlideShowSlide",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "1"})
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {"bg": "#001F2C", "brd": "color_19", "brw": "0", "rd": "0px"},
                                "propertiesSource": {"bg": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.BoxSlideShowSlide",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "#363535",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            }
                        }
                    }
                }, {
                    "type": "Container",
                    "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                    "layout": {
                        "width": 490,
                        "height": 380,
                        "x": 0,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.BoxSlideShowSlide",
                    "components": [{
                        "type": "Component",
                        "layout": {
                            "width": 210,
                            "height": 45,
                            "x": 262,
                            "y": 49,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<h2 style=\"font-size: 30px;\" class=\"font_2\"><span style=\"font-size:30px;\"><span class=\"color_11\"><span style=\"font-family:anton,sans-serif;\">CHOOSE A HEADER</span></span></span></h2>",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 213,
                                "height": 40,
                                "x": 20,
                                "y": 16,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 style=\"font-size: 30px;\" class=\"font_2\"><span style=\"font-size:30px;\"><span class=\"color_11\"><span style=\"font-family:anton,sans-serif;\">CHOOSE A HEADER</span></span></span></h2>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 155,
                            "height": 44,
                            "x": 262,
                            "y": 108,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<p class=\"font_8\" style=\"font-size: 17px; line-height: 1.2em;\"><span style=\"font-family:avenir-lt-w01_85-heavy1475544,sans-serif;\"><span style=\"font-size:17px;\"><span class=\"color_11\"><span style=\"letter-spacing:0.42px\">Now add more info here...</span></span></span></span></p>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 228,
                                "height": 19,
                                "x": 20,
                                "y": 61,
                                "scale": 0.9025,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p class=\"font_8\" style=\"font-size: 17px; line-height: 1.2em;\"><span style=\"font-family:avenir-lt-w01_85-heavy1475544,sans-serif;\"><span style=\"font-size:17px;\"><span class=\"color_11\"><span style=\"letter-spacing:0.42px\">Now add more info here...</span></span></span></span></p>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 156,
                            "height": 100,
                            "x": 262,
                            "y": 172,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<p class=\"font_8\" style=\"font-size: 14px; line-height: 1.4em;\"><span style=\"font-size:14px;\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\"><span class=\"color_11\">Tell customers more about you. Add a few words and a stunning pic to grab their attention and get them to click.</span></span></span></p>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 243,
                                "height": 60,
                                "x": 21,
                                "y": 93,
                                "scale": 0.95,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p class=\"font_8\" style=\"font-size: 14px; line-height: 1.4em;\"><span style=\"font-size:14px;\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\"><span class=\"color_11\">Tell customers more about you. Add a few words and a stunning pic to grab their attention and get them to click.</span></span></span></p>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 200,
                            "height": 381,
                            "x": -1,
                            "y": -1,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WPhoto",
                        "data": {
                            "type": "Image",
                            "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                            "title": "Desert Dune Crop",
                            "uri": "a500fb6c6734679bc1e57eb668e45d91.jpg",
                            "description": "",
                            "width": 635,
                            "height": 295,
                            "alt": "",
                            "crop": {"x": 19, "y": 0, "width": 173, "height": 295}
                        },
                        "props": {
                            "type": "WPhotoProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "displayMode": "fill",
                            "onClickBehavior": "goToLink",
                            "effectName": "ink"
                        },
                        "style": "wp2",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 280,
                                "height": 226,
                                "x": 0,
                                "y": 172,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WPhoto",
                            "data": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                                "title": "Desert Dune Crop",
                                "uri": "a500fb6c6734679bc1e57eb668e45d91.jpg",
                                "description": "",
                                "width": 635,
                                "height": 295,
                                "alt": "",
                                "crop": {"x": 19, "y": 0, "width": 173, "height": 295}
                            },
                            "props": {
                                "type": "WPhotoProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "displayMode": "fill",
                                "onClickBehavior": "goToLink",
                                "effectName": "ink"
                            },
                            "style": "wp2"
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 211,
                            "height": 304,
                            "x": 30,
                            "y": 25,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WPhoto",
                        "data": {
                            "type": "Image",
                            "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                            "title": "Girl by the Sea",
                            "uri": "45febfcf02f543c1950505340713a476.jpg",
                            "description": "",
                            "width": 1920,
                            "height": 1280,
                            "alt": "",
                            "crop": {"x": 418, "y": 234, "width": 690, "height": 860}
                        },
                        "props": {
                            "type": "WPhotoProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "displayMode": "fill",
                            "onClickBehavior": "goToLink",
                            "effectName": "none"
                        },
                        "style": "wp2",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 199,
                                "height": 226,
                                "x": 0,
                                "y": 172,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WPhoto",
                            "data": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                                "title": "Girl by the Sea",
                                "uri": "45febfcf02f543c1950505340713a476.jpg",
                                "description": "",
                                "width": 1920,
                                "height": 1280,
                                "alt": "",
                                "crop": {"x": 418, "y": 234, "width": 690, "height": 860}
                            },
                            "props": {
                                "type": "WPhotoProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "displayMode": "fill",
                                "onClickBehavior": "goToLink",
                                "effectName": "none"
                            },
                            "style": "wp2"
                        }
                    }],
                    "data": {
                        "type": "BoxSlideShowSlide",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "2"})
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {"bg": "#001F2C", "brd": "color_19", "brw": "0", "rd": "0px"},
                            "propertiesSource": {"bg": "value"}
                        },
                        "componentClassName": "wysiwyg.viewer.components.BoxSlideShowSlide",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "color": "#363535",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "mobileStructure": {
                        "type": "Container",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                        "layout": {
                            "width": 280,
                            "height": 460,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.BoxSlideShowSlide",
                        "data": {
                            "type": "BoxSlideShowSlide",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "2"})
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {"bg": "#001F2C", "brd": "color_19", "brw": "0", "rd": "0px"},
                                "propertiesSource": {"bg": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.BoxSlideShowSlide",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "#363535",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            }
                        }
                    }
                }, {
                    "type": "Container",
                    "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                    "layout": {
                        "width": 490,
                        "height": 380,
                        "x": 0,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.BoxSlideShowSlide",
                    "components": [{
                        "type": "Component",
                        "layout": {
                            "width": 223,
                            "height": 45,
                            "x": 262,
                            "y": 49,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<h2 style=\"font-size: 30px;\" class=\"font_2\"><span style=\"font-size:30px;\"><span class=\"color_11\"><span style=\"font-family:anton,sans-serif;\">CHOOSE A HEADER</span></span></span></h2>",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 213,
                                "height": 40,
                                "x": 20,
                                "y": 16,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 style=\"font-size: 30px;\" class=\"font_2\"><span style=\"font-size:30px;\"><span class=\"color_11\"><span style=\"font-family:anton,sans-serif;\">CHOOSE A HEADER</span></span></span></h2>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 144,
                            "height": 44,
                            "x": 262,
                            "y": 119,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<p class=\"font_8\" style=\"font-size: 17px; line-height: 1.2em;\"><span style=\"font-size:17px;\"><span style=\"font-family:avenir-lt-w01_85-heavy1475544,sans-serif;\"><span class=\"color_11\"><span style=\"letter-spacing:0.42px\">Now add more info here...</span></span></span></span></p>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 228,
                                "height": 19,
                                "x": 20,
                                "y": 61,
                                "scale": 0.9025,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p class=\"font_8\" style=\"font-size: 17px; line-height: 1.2em;\"><span style=\"font-size:17px;\"><span style=\"font-family:avenir-lt-w01_85-heavy1475544,sans-serif;\"><span class=\"color_11\"><span style=\"letter-spacing:0.42px\">Now add more info here...</span></span></span></span></p>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 156,
                            "height": 100,
                            "x": 262,
                            "y": 173,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<p class=\"font_8\" style=\"font-size: 14px; line-height: 1.4em;\"><span style=\"font-size:14px\"><span><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\"><span class=\"color_11\">Tell customers more about you. Add a few words and a stunning pic to grab their attention and get them to click.</span></span></span></span></p>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 243,
                                "height": 60,
                                "x": 21,
                                "y": 93,
                                "scale": 0.95,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p class=\"font_8\" style=\"font-size: 14px; line-height: 1.4em;\"><span style=\"font-size:14px\"><span><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif\"><span class=\"color_11\">Tell customers more about you. Add a few words and a stunning pic to grab their attention and get them to click.</span></span></span></span></p>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 200,
                            "height": 380,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WPhoto",
                        "data": {
                            "type": "Image",
                            "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                            "title": "shutterstock_187830401_2final.jpg",
                            "uri": "035244_1b788dd6f1234909896ac96dc72cd8d2.jpg",
                            "description": "",
                            "width": 3766,
                            "height": 3503,
                            "alt": "",
                            "crop": {"x": 963, "y": 403, "width": 1416, "height": 2401}
                        },
                        "props": {
                            "type": "WPhotoProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "displayMode": "fill",
                            "onClickBehavior": "goToLink",
                            "effectName": "ink"
                        },
                        "style": "wp2",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 280,
                                "height": 226,
                                "x": 0,
                                "y": 172,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WPhoto",
                            "data": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                                "title": "shutterstock_187830401_2final.jpg",
                                "uri": "035244_1b788dd6f1234909896ac96dc72cd8d2.jpg",
                                "description": "",
                                "width": 3766,
                                "height": 3503,
                                "alt": "",
                                "crop": {"x": 963, "y": 403, "width": 1416, "height": 2401}
                            },
                            "props": {
                                "type": "WPhotoProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "displayMode": "fill",
                                "onClickBehavior": "goToLink",
                                "effectName": "ink"
                            },
                            "style": "wp2"
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 211,
                            "height": 304,
                            "x": 30,
                            "y": 25,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WPhoto",
                        "data": {
                            "type": "Image",
                            "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                            "title": "Black Coat",
                            "uri": "1a3619ce84d241dea2ce04dd6bd9291d.png",
                            "description": "",
                            "width": 1000,
                            "height": 1000,
                            "alt": ""
                        },
                        "props": {
                            "type": "WPhotoProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "displayMode": "fill",
                            "onClickBehavior": "goToLink",
                            "effectName": "none"
                        },
                        "style": "wp2",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 199,
                                "height": 226,
                                "x": 0,
                                "y": 172,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WPhoto",
                            "data": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                                "title": "Black Coat",
                                "uri": "1a3619ce84d241dea2ce04dd6bd9291d.png",
                                "description": "",
                                "width": 1000,
                                "height": 1000,
                                "alt": ""
                            },
                            "props": {
                                "type": "WPhotoProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "displayMode": "fill",
                                "onClickBehavior": "goToLink",
                                "effectName": "none"
                            },
                            "style": "wp2"
                        }
                    }],
                    "data": {
                        "type": "BoxSlideShowSlide",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "3"})
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {"bg": "#001F2C", "brd": "color_19", "brw": "0", "rd": "0px"},
                            "propertiesSource": {"bg": "value"}
                        },
                        "componentClassName": "wysiwyg.viewer.components.BoxSlideShowSlide",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "color": "#363535",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "mobileStructure": {
                        "type": "Container",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                        "layout": {
                            "width": 280,
                            "height": 460,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.BoxSlideShowSlide",
                        "data": {
                            "type": "BoxSlideShowSlide",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "3"})
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {"bg": "#001F2C", "brd": "color_19", "brw": "0", "rd": "0px"},
                                "propertiesSource": {"bg": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.BoxSlideShowSlide",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "#363535",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            }
                        }
                    }
                }],
                "props": {
                    "type": "BoxSlideShowProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "transition": "CrossFade",
                    "autoPlayInterval": 3,
                    "autoPlay": true,
                    "transDuration": 1,
                    "pauseAutoPlayOnMouseOver": true,
                    "direction": "RTL",
                    "shouldHideOverflowContent": true,
                    "flexibleBoxHeight": false,
                    "showNavigationButton": false,
                    "showNavigationDots": true,
                    "navigationButtonSize": 10,
                    "navigationButtonMargin": 18,
                    "navigationDotsSize": 7,
                    "navigationDotsMargin": 25,
                    "navigationDotsGap": 16,
                    "navigationDotsAlignment": "center"
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-arrowColor": "1",
                            "alpha-brd": "0",
                            "alpha-dotsColor": "1",
                            "arrowColor": "#FFFFFF",
                            "boxShadowToggleOn-shd": "true",
                            "brw": "0",
                            "dotsColor": "#FFFFFF",
                            "shd": "0 0 0 rgba(0, 0, 0, 0.6)"
                        },
                        "propertiesSource": {
                            "alpha-arrowColor": "value",
                            "alpha-brd": "value",
                            "alpha-dotsColor": "value",
                            "arrowColor": "value",
                            "boxShadowToggleOn-shd": "value",
                            "brd": "value",
                            "brw": "value",
                            "dotsColor": "value",
                            "shd": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.BoxSlideShow",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.common.components.boxSlideShow.viewer.skins.thinArrowsLargeSelectedCircleSkin"
                },
                "mobileStructure": {
                    "type": "Container",
                    "skin": "wysiwyg.common.components.boxSlideShow.viewer.skins.thinArrowsLargeSelectedCircleSkin",
                    "layout": {
                        "width": 280,
                        "height": 460,
                        "x": 0,
                        "y": 554,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.BoxSlideShow",
                    "props": {
                        "type": "BoxSlideShowProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": true},
                        "transition": "SlideHorizontal",
                        "autoPlayInterval": 2,
                        "autoPlay": false,
                        "transDuration": 1,
                        "pauseAutoPlayOnMouseOver": true,
                        "direction": "RTL",
                        "shouldHideOverflowContent": true,
                        "flexibleBoxHeight": false,
                        "showNavigationButton": true,
                        "showNavigationDots": true,
                        "navigationButtonSize": 15,
                        "navigationButtonMargin": 22,
                        "navigationDotsSize": 6,
                        "navigationDotsMargin": 24,
                        "navigationDotsGap": 15,
                        "navigationDotsAlignment": "center"
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-arrowColor": "1",
                                "alpha-brd": "0",
                                "alpha-dotsColor": "1",
                                "arrowColor": "#FFFFFF",
                                "boxShadowToggleOn-shd": "true",
                                "brw": "0",
                                "dotsColor": "#FFFFFF",
                                "shd": "0 0 0 rgba(0, 0, 0, 0.6)"
                            },
                            "propertiesSource": {
                                "alpha-arrowColor": "value",
                                "alpha-brd": "value",
                                "alpha-dotsColor": "value",
                                "arrowColor": "value",
                                "boxShadowToggleOn-shd": "value",
                                "brd": "value",
                                "brw": "value",
                                "dotsColor": "value",
                                "shd": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.viewer.components.BoxSlideShow",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.boxSlideShow.viewer.skins.thinArrowsLargeSelectedCircleSkin"
                    }
                },
                "id": "comp-im3gfybh"
            },
            "preset": {
                "rect": {"width": 162, "height": 134.5, "x": 162, "y": 0},
                "label": "add_preset_BoxSlideShow_2",
                "tags": null
            }
        }, {
            "id": "BoxSlideShow_BoxSlideShow_3",
            "structure": {
                "type": "Container",
                "skin": "wysiwyg.common.components.boxSlideShow.viewer.skins.squareButtonsSkin",
                "layout": {
                    "width": 490,
                    "height": 380,
                    "x": 117,
                    "y": 619,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.BoxSlideShow",
                "components": [{
                    "type": "Container",
                    "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                    "layout": {
                        "width": 490,
                        "height": 380,
                        "x": 0,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.BoxSlideShowSlide",
                    "components": [{
                        "type": "Container",
                        "skin": "wysiwyg.viewer.skins.area.RectangleArea",
                        "layout": {
                            "width": 210,
                            "height": 272,
                            "x": 140,
                            "y": 42,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "mobile.core.components.Container",
                        "components": [{
                            "type": "Component",
                            "layout": {
                                "width": 188,
                                "height": 15,
                                "x": 11,
                                "y": 37,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p style=\"text-align: center; font-size: 11px;\" class=\"font_8\"><span style=\"font-size:11px;\"><span style=\"letter-spacing:0.1em;\"><span class=\"color_11\"><span style=\"font-family:libre baskerville,serif;\">WHAT PEOPLE SAY</span></span></span></span></p>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew",
                            "mobileStructure": {
                                "type": "Component",
                                "layout": {
                                    "width": 210,
                                    "height": 19,
                                    "x": 16,
                                    "y": 24,
                                    "scale": 1.1576250000000001,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "data": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<p style=\"text-align: center; font-size: 11px;\" class=\"font_8\"><span style=\"font-size:11px;\"><span style=\"letter-spacing:0.1em;\"><span class=\"color_11\"><span style=\"font-family:libre baskerville,serif;\">WHAT PEOPLE SAY</span></span></span></span></p>",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "props": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                },
                                "style": "txtNew"
                            }
                        }, {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.line.SolidLine",
                            "layout": {
                                "width": 160,
                                "height": 5,
                                "x": 25,
                                "y": 61,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.FiveGridLine",
                            "props": {
                                "type": "FiveGridLineProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "fullScreenModeOn": false
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {"alpha-brd": "1", "brd": "#FFFFFF", "lnw": "1px"},
                                    "propertiesSource": {"brd": "value", "lnw": "value"}
                                },
                                "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.line.SolidLine"
                            },
                            "mobileStructure": {
                                "type": "Component",
                                "skin": "wysiwyg.viewer.skins.line.SolidLine",
                                "layout": {
                                    "width": 160,
                                    "height": 5,
                                    "x": 40,
                                    "y": 55,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.FiveGridLine",
                                "props": {
                                    "type": "FiveGridLineProperties",
                                    "metaData": {"schemaVersion": "1.0"},
                                    "fullScreenModeOn": false
                                },
                                "style": {
                                    "type": "TopLevelStyle",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "style": {
                                        "groups": {},
                                        "properties": {"alpha-brd": "1", "brd": "#FFFFFF", "lnw": "1px"},
                                        "propertiesSource": {"brd": "value", "lnw": "value"}
                                    },
                                    "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                                    "pageId": "",
                                    "compId": "",
                                    "styleType": "custom",
                                    "skin": "wysiwyg.viewer.skins.line.SolidLine"
                                }
                            }
                        }, {
                            "type": "Component",
                            "layout": {
                                "width": 183,
                                "height": 16,
                                "x": 12,
                                "y": 228,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p style=\"text-align: center; font-size: 12px;\" class=\"font_8\"><span style=\"font-size:12px;\"><span style=\"letter-spacing:0.04em;\"><span class=\"color_11\"><span style=\"font-style:italic;\"><span style=\"font-family:libre baskerville,serif;\">— Name, Title</span></span></span></span></span></p>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew",
                            "mobileStructure": {
                                "type": "Component",
                                "layout": {
                                    "width": 115,
                                    "height": 18,
                                    "x": 63,
                                    "y": 226,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "data": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<p style=\"text-align: center; font-size: 12px;\" class=\"font_8\"><span style=\"font-size:12px;\"><span style=\"letter-spacing:0.04em;\"><span class=\"color_11\"><span style=\"font-style:italic;\"><span style=\"font-family:libre baskerville,serif;\">— Name, Title</span></span></span></span></span></p>",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "props": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                },
                                "style": "txtNew"
                            }
                        }],
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {"alpha-bg": "1", "bg": "#4660BD"},
                                "propertiesSource": {"bg": "value"}
                            },
                            "componentClassName": "mobile.core.components.Container",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.area.RectangleArea"
                        },
                        "mobileStructure": {
                            "type": "Container",
                            "skin": "wysiwyg.viewer.skins.area.RectangleArea",
                            "layout": {
                                "width": 240,
                                "height": 269,
                                "x": 20,
                                "y": 20,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "mobile.core.components.Container",
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {"alpha-bg": "1", "bg": "#4660BD"},
                                    "propertiesSource": {"bg": "value"}
                                },
                                "componentClassName": "mobile.core.components.Container",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.area.RectangleArea"
                            }
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 366,
                            "height": 92,
                            "x": 62,
                            "y": 143,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<h2 style=\"line-height: 1.3em; text-align: center; font-size: 17px;\" class=\"font_2\"><span style=\"font-size:17px;\"><span style=\"letter-spacing:0.03em;\"><span style=\"line-height:1.3em;\"><span style=\"font-family:libre baskerville,serif;\"><span class=\"color_11\">“Testimonials work great. Showing your reviews in quote marks has a powerful effect on customers and makes them more likely to trust you.”</span></span></span></span></span></h2>",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 210,
                                "height": 120,
                                "x": 15,
                                "y": 75,
                                "scale": 0.8104388094140624,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 style=\"line-height: 1.3em; text-align: center; font-size: 17px;\" class=\"font_2\"><span style=\"font-size:17px;\"><span style=\"letter-spacing:0.03em;\"><span style=\"line-height:1.3em;\"><span style=\"font-family:libre baskerville,serif;\"><span class=\"color_11\">“Testimonials work great. Showing your reviews in quote marks has a powerful effect on customers and makes them more likely to trust you.”</span></span></span></span></span></h2>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }],
                    "data": {
                        "type": "BoxSlideShowSlide",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "1"})
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {"bg": "#001F2C", "brd": "color_19", "brw": "0", "rd": "0px"},
                            "propertiesSource": {"bg": "value"}
                        },
                        "componentClassName": "wysiwyg.viewer.components.BoxSlideShowSlide",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "color": "#2E2E2E",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "mobileStructure": {
                        "type": "Container",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                        "layout": {
                            "width": 280,
                            "height": 338,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.BoxSlideShowSlide",
                        "data": {
                            "type": "BoxSlideShowSlide",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "1"})
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {"bg": "#001F2C", "brd": "color_19", "brw": "0", "rd": "0px"},
                                "propertiesSource": {"bg": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.BoxSlideShowSlide",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "#2E2E2E",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            }
                        }
                    }
                }, {
                    "type": "Container",
                    "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                    "layout": {
                        "width": 490,
                        "height": 380,
                        "x": 0,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.BoxSlideShowSlide",
                    "components": [{
                        "type": "Container",
                        "skin": "wysiwyg.viewer.skins.area.RectangleArea",
                        "layout": {
                            "width": 210,
                            "height": 274,
                            "x": 140,
                            "y": 45,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "mobile.core.components.Container",
                        "components": [{
                            "type": "Component",
                            "layout": {
                                "width": 188,
                                "height": 15,
                                "x": 11,
                                "y": 34,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p style=\"text-align: center; font-size: 11px;\" class=\"font_8\"><span style=\"font-size:11px;\"><span style=\"letter-spacing:0.1em;\"><span class=\"color_11\"><span style=\"font-family:libre baskerville,serif;\">TITLE GOES HERE</span></span></span></span></p>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew",
                            "mobileStructure": {
                                "type": "Component",
                                "layout": {
                                    "width": 210,
                                    "height": 19,
                                    "x": 16,
                                    "y": 24,
                                    "scale": 1.18,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "data": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<p style=\"text-align: center; font-size: 11px;\" class=\"font_8\"><span style=\"font-size:11px;\"><span style=\"letter-spacing:0.1em;\"><span class=\"color_11\"><span style=\"font-family:libre baskerville,serif;\">TITLE GOES HERE</span></span></span></span></p>",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "props": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                },
                                "style": "txtNew"
                            }
                        }, {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.line.SolidLine",
                            "layout": {
                                "width": 160,
                                "height": 5,
                                "x": 25,
                                "y": 58,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.FiveGridLine",
                            "props": {
                                "type": "FiveGridLineProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "fullScreenModeOn": false
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {"alpha-brd": "1", "brd": "#FFFFFF", "lnw": "1px"},
                                    "propertiesSource": {"brd": "value", "lnw": "value"}
                                },
                                "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.line.SolidLine"
                            },
                            "mobileStructure": {
                                "type": "Component",
                                "skin": "wysiwyg.viewer.skins.line.SolidLine",
                                "layout": {
                                    "width": 160,
                                    "height": 5,
                                    "x": 40,
                                    "y": 55,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.FiveGridLine",
                                "props": {
                                    "type": "FiveGridLineProperties",
                                    "metaData": {"schemaVersion": "1.0"},
                                    "fullScreenModeOn": false
                                },
                                "style": {
                                    "type": "TopLevelStyle",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "style": {
                                        "groups": {},
                                        "properties": {"alpha-brd": "1", "brd": "#FFFFFF", "lnw": "1px"},
                                        "propertiesSource": {"brd": "value", "lnw": "value"}
                                    },
                                    "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                                    "pageId": "",
                                    "compId": "",
                                    "styleType": "custom",
                                    "skin": "wysiwyg.viewer.skins.line.SolidLine"
                                }
                            }
                        }, {
                            "type": "Component",
                            "layout": {
                                "width": 183,
                                "height": 16,
                                "x": 13,
                                "y": 225,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p style=\"text-align: center; font-size: 12px;\" class=\"font_8\"><span style=\"font-size:12px;\"><span style=\"letter-spacing:0.04em;\"><span class=\"color_11\"><span style=\"font-style:italic;\"><span style=\"font-family:libre baskerville,serif;\">— Name, Title</span></span></span></span></span></p>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew",
                            "mobileStructure": {
                                "type": "Component",
                                "layout": {
                                    "width": 115,
                                    "height": 18,
                                    "x": 63,
                                    "y": 227,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "data": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<p style=\"text-align: center; font-size: 12px;\" class=\"font_8\"><span style=\"font-size:12px;\"><span style=\"letter-spacing:0.04em;\"><span class=\"color_11\"><span style=\"font-style:italic;\"><span style=\"font-family:libre baskerville,serif;\">— Name, Title</span></span></span></span></span></p>",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "props": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                },
                                "style": "txtNew"
                            }
                        }],
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {"alpha-bg": "1", "bg": "#2AD1BB"},
                                "propertiesSource": {"bg": "value"}
                            },
                            "componentClassName": "mobile.core.components.Container",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.area.RectangleArea"
                        },
                        "mobileStructure": {
                            "type": "Container",
                            "skin": "wysiwyg.viewer.skins.area.RectangleArea",
                            "layout": {
                                "width": 240,
                                "height": 270,
                                "x": 20,
                                "y": 20,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "mobile.core.components.Container",
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {"alpha-bg": "1", "bg": "#2AD1BB"},
                                    "propertiesSource": {"bg": "value"}
                                },
                                "componentClassName": "mobile.core.components.Container",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.area.RectangleArea"
                            }
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 340,
                            "height": 92,
                            "x": 75,
                            "y": 143,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<h2 style=\"line-height: 1.3em; text-align: center; font-size: 17px;\" class=\"font_2\"><span style=\"font-size:17px;\"><span style=\"letter-spacing:0.03em;\"><span style=\"line-height:1.3em;\"><span style=\"font-family:libre baskerville,serif;\"><span class=\"color_11\">“Share the amazing things customers are saying about your business. Double click, or click Edit Text to make it yours.”</span></span></span></span></span></h2>",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 210,
                                "height": 100,
                                "x": 15,
                                "y": 76,
                                "scale": 0.82,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 style=\"line-height: 1.3em; text-align: center; font-size: 17px;\" class=\"font_2\"><span style=\"font-size:17px;\"><span style=\"letter-spacing:0.03em;\"><span style=\"line-height:1.3em;\"><span style=\"font-family:libre baskerville,serif;\"><span class=\"color_11\">“Share the amazing things customers are saying about your business. Double click, or click Edit Text to make it yours.”</span></span></span></span></span></h2>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }],
                    "data": {
                        "type": "BoxSlideShowSlide",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "2"})
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {"bg": "#001F2C", "brd": "color_19", "brw": "0", "rd": "0px"},
                            "propertiesSource": {"bg": "value"}
                        },
                        "componentClassName": "wysiwyg.viewer.components.BoxSlideShowSlide",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "color": "#2E2E2E",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "mobileStructure": {
                        "type": "Container",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                        "layout": {
                            "width": 280,
                            "height": 338,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.BoxSlideShowSlide",
                        "data": {
                            "type": "BoxSlideShowSlide",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "2"})
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {"bg": "#001F2C", "brd": "color_19", "brw": "0", "rd": "0px"},
                                "propertiesSource": {"bg": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.BoxSlideShowSlide",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "#2E2E2E",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            }
                        }
                    }
                }, {
                    "type": "Container",
                    "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                    "layout": {
                        "width": 490,
                        "height": 380,
                        "x": 0,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.BoxSlideShowSlide",
                    "components": [{
                        "type": "Container",
                        "skin": "wysiwyg.viewer.skins.area.RectangleArea",
                        "layout": {
                            "width": 210,
                            "height": 274,
                            "x": 140,
                            "y": 45,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "mobile.core.components.Container",
                        "components": [{
                            "type": "Component",
                            "layout": {
                                "width": 188,
                                "height": 15,
                                "x": 11,
                                "y": 34,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p style=\"text-align: center; font-size: 11px;\" class=\"font_8\"><span style=\"font-size:11px;\"><span style=\"letter-spacing:0.1em;\"><span class=\"color_11\"><span style=\"font-family:libre baskerville,serif;\">TITLE GOES HERE</span></span></span></span></p>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew",
                            "mobileStructure": {
                                "type": "Component",
                                "layout": {
                                    "width": 210,
                                    "height": 19,
                                    "x": 16,
                                    "y": 24,
                                    "scale": 1.18,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "data": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<p style=\"text-align: center; font-size: 11px;\" class=\"font_8\"><span style=\"font-size:11px;\"><span style=\"letter-spacing:0.1em;\"><span class=\"color_11\"><span style=\"font-family:libre baskerville,serif;\">TITLE GOES HERE</span></span></span></span></p>",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "props": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                },
                                "style": "txtNew"
                            }
                        }, {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.line.SolidLine",
                            "layout": {
                                "width": 160,
                                "height": 5,
                                "x": 25,
                                "y": 58,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.FiveGridLine",
                            "props": {
                                "type": "FiveGridLineProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "fullScreenModeOn": false
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {"alpha-brd": "1", "brd": "#FFFFFF", "lnw": "1px"},
                                    "propertiesSource": {"brd": "value", "lnw": "value"}
                                },
                                "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.line.SolidLine"
                            },
                            "mobileStructure": {
                                "type": "Component",
                                "skin": "wysiwyg.viewer.skins.line.SolidLine",
                                "layout": {
                                    "width": 160,
                                    "height": 5,
                                    "x": 40,
                                    "y": 55,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.FiveGridLine",
                                "props": {
                                    "type": "FiveGridLineProperties",
                                    "metaData": {"schemaVersion": "1.0"},
                                    "fullScreenModeOn": false
                                },
                                "style": {
                                    "type": "TopLevelStyle",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "style": {
                                        "groups": {},
                                        "properties": {"alpha-brd": "1", "brd": "#FFFFFF", "lnw": "1px"},
                                        "propertiesSource": {"brd": "value", "lnw": "value"}
                                    },
                                    "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                                    "pageId": "",
                                    "compId": "",
                                    "styleType": "custom",
                                    "skin": "wysiwyg.viewer.skins.line.SolidLine"
                                }
                            }
                        }, {
                            "type": "Component",
                            "layout": {
                                "width": 183,
                                "height": 16,
                                "x": 13,
                                "y": 225,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p style=\"text-align: center; font-size: 12px;\" class=\"font_8\"><span style=\"font-size:12px;\"><span style=\"letter-spacing:0.04em;\"><span class=\"color_11\"><span style=\"font-style:italic;\"><span style=\"font-family:libre baskerville,serif;\">— Name, Title</span></span></span></span></span></p>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew",
                            "mobileStructure": {
                                "type": "Component",
                                "layout": {
                                    "width": 115,
                                    "height": 18,
                                    "x": 63,
                                    "y": 227,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "data": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<p style=\"text-align: center; font-size: 12px;\" class=\"font_8\"><span style=\"font-size:12px;\"><span style=\"letter-spacing:0.04em;\"><span class=\"color_11\"><span style=\"font-style:italic;\"><span style=\"font-family:libre baskerville,serif;\">— Name, Title</span></span></span></span></span></p>",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "props": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                },
                                "style": "txtNew"
                            }
                        }],
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {"alpha-bg": "1", "bg": "#FC8972"},
                                "propertiesSource": {"bg": "value"}
                            },
                            "componentClassName": "mobile.core.components.Container",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.area.RectangleArea"
                        },
                        "mobileStructure": {
                            "type": "Container",
                            "skin": "wysiwyg.viewer.skins.area.RectangleArea",
                            "layout": {
                                "width": 240,
                                "height": 270,
                                "x": 20,
                                "y": 20,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "mobile.core.components.Container",
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {"alpha-bg": "1", "bg": "#FC8972"},
                                    "propertiesSource": {"bg": "value"}
                                },
                                "componentClassName": "mobile.core.components.Container",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.area.RectangleArea"
                            }
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 340,
                            "height": 92,
                            "x": 75,
                            "y": 143,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<h2 style=\"line-height: 1.3em; text-align: center; font-size: 17px;\" class=\"font_2\"><span style=\"font-size:17px;\"><span style=\"letter-spacing:0.03em;\"><span style=\"line-height:1.3em;\"><span style=\"font-family:libre baskerville,serif;\"><span class=\"color_11\">“Share the amazing things customers are saying about your business. Double click, or click Edit Text to make it yours.”</span></span></span></span></span></h2>",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 210,
                                "height": 100,
                                "x": 15,
                                "y": 76,
                                "scale": 0.798,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 style=\"line-height: 1.3em; text-align: center; font-size: 17px;\" class=\"font_2\"><span style=\"font-size:17px;\"><span style=\"letter-spacing:0.03em;\"><span style=\"line-height:1.3em;\"><span style=\"font-family:libre baskerville,serif;\"><span class=\"color_11\">“Share the amazing things customers are saying about your business. Double click, or click Edit Text to make it yours.”</span></span></span></span></span></h2>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }],
                    "data": {
                        "type": "BoxSlideShowSlide",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "3"})
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {"bg": "#001F2C", "brd": "color_19", "brw": "0", "rd": "0px"},
                            "propertiesSource": {"bg": "value"}
                        },
                        "componentClassName": "wysiwyg.viewer.components.BoxSlideShowSlide",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "color": "#2E2E2E",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "mobileStructure": {
                        "type": "Container",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                        "layout": {
                            "width": 280,
                            "height": 338,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.BoxSlideShowSlide",
                        "data": {
                            "type": "BoxSlideShowSlide",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "3"})
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {"bg": "#001F2C", "brd": "color_19", "brw": "0", "rd": "0px"},
                                "propertiesSource": {"bg": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.BoxSlideShowSlide",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "#2E2E2E",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            }
                        }
                    }
                }],
                "props": {
                    "type": "BoxSlideShowProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "transition": "SlideHorizontal",
                    "autoPlayInterval": 3,
                    "autoPlay": true,
                    "transDuration": 1,
                    "pauseAutoPlayOnMouseOver": true,
                    "direction": "RTL",
                    "shouldHideOverflowContent": true,
                    "flexibleBoxHeight": false,
                    "showNavigationButton": true,
                    "showNavigationDots": true,
                    "navigationButtonSize": 10,
                    "navigationButtonMargin": 18,
                    "navigationDotsSize": 6,
                    "navigationDotsMargin": 28,
                    "navigationDotsGap": 16,
                    "navigationDotsAlignment": "center"
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-arrowColor": "1",
                            "alpha-bg": "1",
                            "alpha-brd": "0",
                            "alpha-dotsColor": "1",
                            "bg": "#FFFFFF",
                            "boxShadowToggleOn-shd": "true",
                            "brw": "0",
                            "shd": "0 0 0 rgba(0, 0, 0, 0.6)"
                        },
                        "propertiesSource": {
                            "alpha-arrowColor": "value",
                            "alpha-bg": "value",
                            "alpha-brd": "value",
                            "alpha-dotsColor": "value",
                            "arrowColor": "value",
                            "bg": "value",
                            "boxShadowToggleOn-shd": "value",
                            "brd": "value",
                            "brw": "value",
                            "dotsColor": "value",
                            "shd": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.BoxSlideShow",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.common.components.boxSlideShow.viewer.skins.thinArrowsLargeSelectedCircleSkin"
                },
                "mobileStructure": {
                    "type": "Container",
                    "skin": "wysiwyg.common.components.boxSlideShow.viewer.skins.thinArrowsLargeSelectedCircleSkin",
                    "layout": {
                        "width": 280,
                        "height": 338,
                        "x": 0,
                        "y": 1041,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.BoxSlideShow",
                    "props": {
                        "type": "BoxSlideShowProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": true},
                        "transition": "CrossFade",
                        "autoPlayInterval": 2,
                        "autoPlay": false,
                        "transDuration": 1,
                        "pauseAutoPlayOnMouseOver": true,
                        "direction": "RTL",
                        "shouldHideOverflowContent": true,
                        "flexibleBoxHeight": false,
                        "showNavigationButton": false,
                        "showNavigationDots": true,
                        "navigationButtonSize": 15,
                        "navigationButtonMargin": 22,
                        "navigationDotsSize": 6,
                        "navigationDotsMargin": 24,
                        "navigationDotsGap": 15,
                        "navigationDotsAlignment": "center"
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-arrowColor": "1",
                                "alpha-bg": "1",
                                "alpha-brd": "0",
                                "alpha-dotsColor": "1",
                                "bg": "#FFFFFF",
                                "boxShadowToggleOn-shd": "true",
                                "brw": "0",
                                "shd": "0 0 0 rgba(0, 0, 0, 0.6)"
                            },
                            "propertiesSource": {
                                "alpha-arrowColor": "value",
                                "alpha-bg": "value",
                                "alpha-brd": "value",
                                "alpha-dotsColor": "value",
                                "arrowColor": "value",
                                "bg": "value",
                                "boxShadowToggleOn-shd": "value",
                                "brd": "value",
                                "brw": "value",
                                "dotsColor": "value",
                                "shd": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.viewer.components.BoxSlideShow",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.boxSlideShow.viewer.skins.thinArrowsLargeSelectedCircleSkin"
                    }
                },
                "id": "comp-im3miy2s"
            },
            "preset": {
                "rect": {"width": 162, "height": 135, "x": 0, "y": 134.5},
                "label": "add_preset_BoxSlideShow_3",
                "tags": null
            }
        }, {
            "id": "BoxSlideShow_BoxSlideShow_4",
            "structure": {
                "type": "Container",
                "skin": "wysiwyg.common.components.boxSlideShow.viewer.skins.squareButtonsSkin",
                "layout": {
                    "width": 490,
                    "height": 381,
                    "x": 686,
                    "y": 617,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.BoxSlideShow",
                "components": [{
                    "type": "Container",
                    "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                    "layout": {
                        "width": 490,
                        "height": 381,
                        "x": 0,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.BoxSlideShowSlide",
                    "components": [{
                        "type": "Container",
                        "skin": "wysiwyg.viewer.skins.area.RectangleArea",
                        "layout": {
                            "width": 411,
                            "height": 251,
                            "x": 39,
                            "y": 35,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "mobile.core.components.Container",
                        "components": [{
                            "type": "Component",
                            "layout": {
                                "width": 327,
                                "height": 26,
                                "x": 42,
                                "y": 43,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p class=\"font_8\" style=\"font-size:17px; text-align:center\"><span style=\"color:#4F4F4F;\"><span style=\"font-size:17px\"><span style=\"font-family:lulo-clean-w01-one-bold,sans-serif\"><span style=\"letter-spacing:0.1em\">WHAT PEOPLE SAY</span></span></span></span></p>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew",
                            "mobileStructure": {
                                "type": "Component",
                                "layout": {
                                    "width": 153,
                                    "height": 78,
                                    "x": 29,
                                    "y": 19,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "data": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<p class=\"font_8\" style=\"font-size:17px; text-align:center\"><span style=\"color:#4F4F4F;\"><span style=\"font-size:17px\"><span style=\"font-family:lulo-clean-w01-one-bold,sans-serif\"><span style=\"letter-spacing:0.1em\">WHAT PEOPLE SAY</span></span></span></span></p>\n",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "props": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                },
                                "style": "txtNew"
                            }
                        }, {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.line.SolidLine",
                            "layout": {
                                "width": 70,
                                "height": 5,
                                "x": 170,
                                "y": 81,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.FiveGridLine",
                            "props": {
                                "type": "FiveGridLineProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "fullScreenModeOn": false
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {"alpha-brd": "1", "brd": "#4F4F4F", "lnw": "2"},
                                    "propertiesSource": {"brd": "value", "lnw": "value"}
                                },
                                "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.line.SolidLine"
                            },
                            "mobileStructure": {
                                "type": "Component",
                                "skin": "wysiwyg.viewer.skins.line.SolidLine",
                                "layout": {
                                    "width": 80,
                                    "height": 5,
                                    "x": 65,
                                    "y": 114,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.FiveGridLine",
                                "props": {
                                    "type": "FiveGridLineProperties",
                                    "metaData": {"schemaVersion": "1.0"},
                                    "fullScreenModeOn": false
                                },
                                "style": {
                                    "type": "TopLevelStyle",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "style": {
                                        "groups": {},
                                        "properties": {"alpha-brd": "1", "brd": "#4F4F4F", "lnw": "2"},
                                        "propertiesSource": {"brd": "value", "lnw": "value"}
                                    },
                                    "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                                    "pageId": "",
                                    "compId": "",
                                    "styleType": "custom",
                                    "skin": "wysiwyg.viewer.skins.line.SolidLine"
                                }
                            }
                        }, {
                            "type": "Component",
                            "layout": {
                                "width": 183,
                                "height": 19,
                                "x": 114,
                                "y": 201,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p class=\"font_8\" style=\"font-size:15px; text-align:center\"><span style=\"color:#4F4F4F;\"><span style=\"font-size:15px\"><span style=\"font-family:dinneuzeitgroteskltw01-_812426,sans-serif\"><span style=\"letter-spacing:0.04em\">&mdash; Name, Title</span></span></span></span></p>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew",
                            "mobileStructure": {
                                "type": "Component",
                                "layout": {
                                    "width": 97,
                                    "height": 18,
                                    "x": 56,
                                    "y": 274,
                                    "scale": 0.9025,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "data": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<p class=\"font_8\" style=\"font-size:15px; text-align:center\"><span style=\"color:#4F4F4F;\"><span style=\"font-size:15px\"><span style=\"font-family:dinneuzeitgroteskltw01-_812426,sans-serif\"><span style=\"letter-spacing:0.04em\">&mdash; Name, Title</span></span></span></span></p>\n",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "props": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                },
                                "style": "txtNew"
                            }
                        }, {
                            "type": "Component",
                            "layout": {
                                "width": 300,
                                "height": 66,
                                "x": 55,
                                "y": 120,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 class=\"font_2\" style=\"font-size:17px; text-align:center\"><span style=\"color:#4F4F4F;\"><span style=\"letter-spacing:0em\"><span style=\"font-family:dinneuzeitgroteskltw01-_812426,sans-serif\"><span style=\"font-size:17px\">&ldquo;Testimonials work great. Showing your reviews in quote marks has a powerful effect on customers and makes them more likely to trust you.&rdquo;</span></span></span></span></h2>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew",
                            "mobileStructure": {
                                "type": "Component",
                                "layout": {
                                    "width": 189,
                                    "height": 110,
                                    "x": 9,
                                    "y": 134,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "data": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<h2 class=\"font_2\" style=\"font-size:17px; text-align:center\"><span style=\"color:#4F4F4F;\"><span style=\"letter-spacing:0em\"><span style=\"font-family:dinneuzeitgroteskltw01-_812426,sans-serif\"><span style=\"font-size:17px\">&ldquo;Testimonials work great. Showing your reviews in quote marks has a powerful effect on customers and makes them more likely to trust you.&rdquo;</span></span></span></span></h2>\n",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "props": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                },
                                "style": "txtNew"
                            }
                        }],
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {"alpha-bg": "1", "bg": "#FFFFFF"},
                                "propertiesSource": {"bg": "value"}
                            },
                            "componentClassName": "mobile.core.components.Container",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.area.RectangleArea"
                        },
                        "mobileStructure": {
                            "type": "Container",
                            "skin": "wysiwyg.viewer.skins.area.RectangleArea",
                            "layout": {
                                "width": 210,
                                "height": 310,
                                "x": 35,
                                "y": 34,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "mobile.core.components.Container",
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {"alpha-bg": "1", "bg": "#FFFFFF"},
                                    "propertiesSource": {"bg": "value"}
                                },
                                "componentClassName": "mobile.core.components.Container",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.area.RectangleArea"
                            }
                        }
                    }],
                    "data": {
                        "type": "BoxSlideShowSlide",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "1"})
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {"bg": "#001F2C", "brd": "color_19", "brw": "0", "rd": "0px"},
                            "propertiesSource": {"bg": "value"}
                        },
                        "componentClassName": "wysiwyg.viewer.components.BoxSlideShowSlide",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "mediaRef": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "title": "pattern_konfeti2.png",
                                "uri": "035244_6c8c79a806304996a2794d90d53b601a.png",
                                "description": "private/allMedia_picture",
                                "width": 43,
                                "height": 42,
                                "alt": "",
                                "artist": {"id": "", "name": ""},
                                "opacity": 0.8
                            },
                            "color": "#EE7575",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "tile",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "mobileStructure": {
                        "type": "Container",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                        "layout": {
                            "width": 280,
                            "height": 398,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.BoxSlideShowSlide",
                        "data": {
                            "type": "BoxSlideShowSlide",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "1"})
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {"bg": "#001F2C", "brd": "color_19", "brw": "0", "rd": "0px"},
                                "propertiesSource": {"bg": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.BoxSlideShowSlide",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "mediaRef": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "title": "pattern_konfeti2.png",
                                    "uri": "035244_6c8c79a806304996a2794d90d53b601a.png",
                                    "description": "private/allMedia_picture",
                                    "width": 43,
                                    "height": 42,
                                    "alt": "",
                                    "artist": {"id": "", "name": ""},
                                    "opacity": 0.8
                                },
                                "color": "#EE7575",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "tile",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            }
                        }
                    }
                }, {
                    "type": "Container",
                    "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                    "layout": {
                        "width": 490,
                        "height": 381,
                        "x": 0,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.BoxSlideShowSlide",
                    "components": [{
                        "type": "Container",
                        "skin": "wysiwyg.viewer.skins.area.RectangleArea",
                        "layout": {
                            "width": 411,
                            "height": 281,
                            "x": 39,
                            "y": 35,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "mobile.core.components.Container",
                        "components": [{
                            "type": "Component",
                            "layout": {
                                "width": 327,
                                "height": 26,
                                "x": 42,
                                "y": 43,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p class=\"font_8\" style=\"font-size:17px; text-align:center\"><span style=\"color:#4F4F4F\"><span style=\"font-size:17px\"><span style=\"font-family:lulo-clean-w01-one-bold,sans-serif\"><span style=\"letter-spacing:0.1em\">TITLE GOES HERE</span></span></span></span></p>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew",
                            "mobileStructure": {
                                "type": "Component",
                                "layout": {
                                    "width": 153,
                                    "height": 78,
                                    "x": 29,
                                    "y": 19,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "data": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<p class=\"font_8\" style=\"font-size:17px; text-align:center\"><span style=\"color:#4F4F4F\"><span style=\"font-size:17px\"><span style=\"font-family:lulo-clean-w01-one-bold,sans-serif\"><span style=\"letter-spacing:0.1em\">TITLE GOES HERE</span></span></span></span></p>\n",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "props": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                },
                                "style": "txtNew"
                            }
                        }, {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.line.SolidLine",
                            "layout": {
                                "width": 70,
                                "height": 5,
                                "x": 170,
                                "y": 82,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.FiveGridLine",
                            "props": {
                                "type": "FiveGridLineProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "fullScreenModeOn": false
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {"alpha-brd": "1", "brd": "#4F4F4F", "lnw": "2"},
                                    "propertiesSource": {"brd": "value", "lnw": "value"}
                                },
                                "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.line.SolidLine"
                            },
                            "mobileStructure": {
                                "type": "Component",
                                "skin": "wysiwyg.viewer.skins.line.SolidLine",
                                "layout": {
                                    "width": 80,
                                    "height": 5,
                                    "x": 65,
                                    "y": 114,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.FiveGridLine",
                                "props": {
                                    "type": "FiveGridLineProperties",
                                    "metaData": {"schemaVersion": "1.0"},
                                    "fullScreenModeOn": false
                                },
                                "style": {
                                    "type": "TopLevelStyle",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "style": {
                                        "groups": {},
                                        "properties": {"alpha-brd": "1", "brd": "#4F4F4F", "lnw": "2"},
                                        "propertiesSource": {"brd": "value", "lnw": "value"}
                                    },
                                    "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                                    "pageId": "",
                                    "compId": "",
                                    "styleType": "custom",
                                    "skin": "wysiwyg.viewer.skins.line.SolidLine"
                                }
                            }
                        }, {
                            "type": "Component",
                            "layout": {
                                "width": 183,
                                "height": 19,
                                "x": 114,
                                "y": 229,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p class=\"font_8\" style=\"font-size:15px; text-align:center\"><span style=\"color:#4F4F4F;\"><span style=\"font-size:15px\"><span style=\"font-family:dinneuzeitgroteskltw01-_812426,sans-serif\"><span style=\"letter-spacing:0.04em\">&mdash; Name, Title</span></span></span></span></p>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew",
                            "mobileStructure": {
                                "type": "Component",
                                "layout": {
                                    "width": 97,
                                    "height": 18,
                                    "x": 56,
                                    "y": 275,
                                    "scale": 0.95,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "data": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<p class=\"font_8\" style=\"font-size:15px; text-align:center\"><span style=\"color:#4F4F4F;\"><span style=\"font-size:15px\"><span style=\"font-family:dinneuzeitgroteskltw01-_812426,sans-serif\"><span style=\"letter-spacing:0.04em\">&mdash; Name, Title</span></span></span></span></p>\n",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "props": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                },
                                "style": "txtNew"
                            }
                        }, {
                            "type": "Component",
                            "layout": {
                                "width": 265,
                                "height": 66,
                                "x": 73,
                                "y": 121,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 class=\"font_2\" style=\"font-size:17px; text-align:center\"><span style=\"color:#4F4F4F\"><span style=\"letter-spacing:0em\"><span style=\"font-family:dinneuzeitgroteskltw01-_812426,sans-serif\"><span style=\"font-size:17px\">&ldquo;Share the amazing things customers are saying about your business. Double click, or click Edit Text to make it yours.&rdquo;</span></span></span></span></h2>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew",
                            "mobileStructure": {
                                "type": "Component",
                                "layout": {
                                    "width": 189,
                                    "height": 88,
                                    "x": 9,
                                    "y": 130,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "data": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<h2 class=\"font_2\" style=\"font-size:17px; text-align:center\"><span style=\"color:#4F4F4F\"><span style=\"letter-spacing:0em\"><span style=\"font-family:dinneuzeitgroteskltw01-_812426,sans-serif\"><span style=\"font-size:17px\">&ldquo;Share the amazing things customers are saying about your business. Double click, or click Edit Text to make it yours.&rdquo;</span></span></span></span></h2>\n",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "props": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                },
                                "style": "txtNew"
                            }
                        }],
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {"alpha-bg": "1", "bg": "#FFFFFF"},
                                "propertiesSource": {"bg": "value"}
                            },
                            "componentClassName": "mobile.core.components.Container",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.area.RectangleArea"
                        },
                        "mobileStructure": {
                            "type": "Container",
                            "skin": "wysiwyg.viewer.skins.area.RectangleArea",
                            "layout": {
                                "width": 210,
                                "height": 310,
                                "x": 35,
                                "y": 34,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "mobile.core.components.Container",
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {"alpha-bg": "1", "bg": "#FFFFFF"},
                                    "propertiesSource": {"bg": "value"}
                                },
                                "componentClassName": "mobile.core.components.Container",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.area.RectangleArea"
                            }
                        }
                    }],
                    "data": {
                        "type": "BoxSlideShowSlide",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "2"})
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {"bg": "#001F2C", "brd": "color_19", "brw": "0", "rd": "0px"},
                            "propertiesSource": {"bg": "value"}
                        },
                        "componentClassName": "wysiwyg.viewer.components.BoxSlideShowSlide",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "mediaRef": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "title": "pattern_konfeti2.png",
                                "uri": "035244_6c8c79a806304996a2794d90d53b601a.png",
                                "description": "private/allMedia_picture",
                                "width": 43,
                                "height": 42,
                                "alt": "",
                                "artist": {"id": "", "name": ""},
                                "opacity": 0.8
                            },
                            "color": "#60B183",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "tile",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "mobileStructure": {
                        "type": "Container",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                        "layout": {
                            "width": 280,
                            "height": 398,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.BoxSlideShowSlide",
                        "data": {
                            "type": "BoxSlideShowSlide",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "2"})
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {"bg": "#001F2C", "brd": "color_19", "brw": "0", "rd": "0px"},
                                "propertiesSource": {"bg": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.BoxSlideShowSlide",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "mediaRef": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "title": "pattern_konfeti2.png",
                                    "uri": "035244_6c8c79a806304996a2794d90d53b601a.png",
                                    "description": "private/allMedia_picture",
                                    "width": 43,
                                    "height": 42,
                                    "alt": "",
                                    "artist": {"id": "", "name": ""},
                                    "opacity": 0.8
                                },
                                "color": "#60B183",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "tile",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            }
                        }
                    }
                }, {
                    "type": "Container",
                    "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                    "layout": {
                        "width": 490,
                        "height": 381,
                        "x": 0,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.BoxSlideShowSlide",
                    "components": [{
                        "type": "Container",
                        "skin": "wysiwyg.viewer.skins.area.RectangleArea",
                        "layout": {
                            "width": 411,
                            "height": 251,
                            "x": 39,
                            "y": 35,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "mobile.core.components.Container",
                        "components": [{
                            "type": "Component",
                            "layout": {
                                "width": 327,
                                "height": 26,
                                "x": 42,
                                "y": 43,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p class=\"font_8\" style=\"font-size:17px; text-align:center\"><span style=\"color:#4F4F4F\"><span style=\"font-size:17px\"><span style=\"font-family:lulo-clean-w01-one-bold,sans-serif\"><span style=\"letter-spacing:0.1em\">TITLE GOES HERE</span></span></span></span></p>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew",
                            "mobileStructure": {
                                "type": "Component",
                                "layout": {
                                    "width": 153,
                                    "height": 78,
                                    "x": 29,
                                    "y": 19,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "data": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<p class=\"font_8\" style=\"font-size:17px; text-align:center\"><span style=\"color:#4F4F4F\"><span style=\"font-size:17px\"><span style=\"font-family:lulo-clean-w01-one-bold,sans-serif\"><span style=\"letter-spacing:0.1em\">TITLE GOES HERE</span></span></span></span></p>\n",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "props": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                },
                                "style": "txtNew"
                            }
                        }, {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.line.SolidLine",
                            "layout": {
                                "width": 70,
                                "height": 5,
                                "x": 170,
                                "y": 81,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.FiveGridLine",
                            "props": {
                                "type": "FiveGridLineProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "fullScreenModeOn": false
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {"alpha-brd": "1", "brd": "#4F4F4F", "lnw": "2"},
                                    "propertiesSource": {"brd": "value", "lnw": "value"}
                                },
                                "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.line.SolidLine"
                            },
                            "mobileStructure": {
                                "type": "Component",
                                "skin": "wysiwyg.viewer.skins.line.SolidLine",
                                "layout": {
                                    "width": 80,
                                    "height": 5,
                                    "x": 65,
                                    "y": 114,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.FiveGridLine",
                                "props": {
                                    "type": "FiveGridLineProperties",
                                    "metaData": {"schemaVersion": "1.0"},
                                    "fullScreenModeOn": false
                                },
                                "style": {
                                    "type": "TopLevelStyle",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "style": {
                                        "groups": {},
                                        "properties": {"alpha-brd": "1", "brd": "#4F4F4F", "lnw": "2"},
                                        "propertiesSource": {"brd": "value", "lnw": "value"}
                                    },
                                    "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                                    "pageId": "",
                                    "compId": "",
                                    "styleType": "custom",
                                    "skin": "wysiwyg.viewer.skins.line.SolidLine"
                                }
                            }
                        }, {
                            "type": "Component",
                            "layout": {
                                "width": 183,
                                "height": 19,
                                "x": 114,
                                "y": 201,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p class=\"font_8\" style=\"font-size:15px; text-align:center\"><span style=\"color:#4F4F4F;\"><span style=\"font-size:15px\"><span style=\"font-family:dinneuzeitgroteskltw01-_812426,sans-serif\"><span style=\"letter-spacing:0.04em\">&mdash; Name, Title</span></span></span></span></p>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew",
                            "mobileStructure": {
                                "type": "Component",
                                "layout": {
                                    "width": 97,
                                    "height": 18,
                                    "x": 56,
                                    "y": 275,
                                    "scale": 0.93,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "data": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<p class=\"font_8\" style=\"font-size:15px; text-align:center\"><span style=\"color:#4F4F4F;\"><span style=\"font-size:15px\"><span style=\"font-family:dinneuzeitgroteskltw01-_812426,sans-serif\"><span style=\"letter-spacing:0.04em\">&mdash; Name, Title</span></span></span></span></p>\n",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "props": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                },
                                "style": "txtNew"
                            }
                        }, {
                            "type": "Component",
                            "layout": {
                                "width": 265,
                                "height": 66,
                                "x": 73,
                                "y": 120,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 class=\"font_2\" style=\"font-size:17px; text-align:center\"><span style=\"color:#4F4F4F\"><span style=\"letter-spacing:0em\"><span style=\"font-family:dinneuzeitgroteskltw01-_812426,sans-serif\"><span style=\"font-size:17px\">&ldquo;Share the amazing things customers are saying about your business. Double click, or click Edit Text to make it yours.&rdquo;</span></span></span></span></h2>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew",
                            "mobileStructure": {
                                "type": "Component",
                                "layout": {
                                    "width": 189,
                                    "height": 88,
                                    "x": 44,
                                    "y": 169,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "data": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<h2 class=\"font_2\" style=\"font-size:17px; text-align:center\"><span style=\"color:#4F4F4F\"><span style=\"letter-spacing:0em\"><span style=\"font-family:dinneuzeitgroteskltw01-_812426,sans-serif\"><span style=\"font-size:17px\">&ldquo;Share the amazing things customers are saying about your business. Double click, or click Edit Text to make it yours.&rdquo;</span></span></span></span></h2>\n",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "props": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                },
                                "style": "txtNew"
                            }
                        }],
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {"alpha-bg": "1", "bg": "#FFFFFF"},
                                "propertiesSource": {"bg": "value"}
                            },
                            "componentClassName": "mobile.core.components.Container",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.area.RectangleArea"
                        },
                        "mobileStructure": {
                            "type": "Container",
                            "skin": "wysiwyg.viewer.skins.area.RectangleArea",
                            "layout": {
                                "width": 210,
                                "height": 310,
                                "x": 35,
                                "y": 34,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "mobile.core.components.Container",
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {"alpha-bg": "1", "bg": "#FFFFFF"},
                                    "propertiesSource": {"bg": "value"}
                                },
                                "componentClassName": "mobile.core.components.Container",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.area.RectangleArea"
                            }
                        }
                    }],
                    "data": {
                        "type": "BoxSlideShowSlide",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "3"})
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {"bg": "#001F2C", "brd": "color_19", "brw": "0", "rd": "0px"},
                            "propertiesSource": {"bg": "value"}
                        },
                        "componentClassName": "wysiwyg.viewer.components.BoxSlideShowSlide",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "mediaRef": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "title": "pattern_konfeti2.png",
                                "uri": "035244_6c8c79a806304996a2794d90d53b601a.png",
                                "description": "private/allMedia_picture",
                                "width": 43,
                                "height": 42,
                                "alt": "",
                                "artist": {
                                    "id": "mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_",
                                    "name": ""
                                },
                                "opacity": 0.8
                            },
                            "color": "#A28FC9",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "tile",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "mobileStructure": {
                        "type": "Container",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                        "layout": {
                            "width": 280,
                            "height": 398,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.BoxSlideShowSlide",
                        "data": {
                            "type": "BoxSlideShowSlide",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "3"})
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {"bg": "#001F2C", "brd": "color_19", "brw": "0", "rd": "0px"},
                                "propertiesSource": {"bg": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.BoxSlideShowSlide",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "mediaRef": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "title": "pattern_konfeti2.png",
                                    "uri": "035244_6c8c79a806304996a2794d90d53b601a.png",
                                    "description": "private/allMedia_picture",
                                    "width": 43,
                                    "height": 42,
                                    "alt": "",
                                    "artist": {
                                        "id": "mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_",
                                        "name": ""
                                    },
                                    "opacity": 0.8
                                },
                                "color": "#A28FC9",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "tile",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            }
                        }
                    }
                }],
                "props": {
                    "type": "BoxSlideShowProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "transition": "SlideHorizontal",
                    "autoPlayInterval": 3,
                    "autoPlay": true,
                    "transDuration": 1,
                    "pauseAutoPlayOnMouseOver": true,
                    "direction": "RTL",
                    "shouldHideOverflowContent": true,
                    "flexibleBoxHeight": false,
                    "showNavigationButton": true,
                    "showNavigationDots": true,
                    "navigationButtonSize": 10,
                    "navigationButtonMargin": 18,
                    "navigationDotsSize": 6,
                    "navigationDotsMargin": 28,
                    "navigationDotsGap": 16,
                    "navigationDotsAlignment": "center"
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-arrowColor": "1",
                            "alpha-bg": "1",
                            "alpha-brd": "0",
                            "alpha-dotsColor": "1",
                            "bg": "#FFFFFF",
                            "boxShadowToggleOn-shd": "true",
                            "brw": "0",
                            "shd": "0 0 0 rgba(0, 0, 0, 0.6)"
                        },
                        "propertiesSource": {
                            "alpha-arrowColor": "value",
                            "alpha-bg": "value",
                            "alpha-brd": "value",
                            "alpha-dotsColor": "value",
                            "arrowColor": "value",
                            "bg": "value",
                            "boxShadowToggleOn-shd": "value",
                            "brd": "value",
                            "brw": "value",
                            "dotsColor": "value",
                            "shd": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.BoxSlideShow",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.common.components.boxSlideShow.viewer.skins.thinArrowsLargeSelectedCircleSkin"
                },
                "mobileStructure": {
                    "type": "Container",
                    "skin": "wysiwyg.common.components.boxSlideShow.viewer.skins.thinArrowsLargeSelectedCircleSkin",
                    "layout": {
                        "width": 280,
                        "height": 398,
                        "x": 0,
                        "y": 1413,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.BoxSlideShow",
                    "props": {
                        "type": "BoxSlideShowProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "transition": "SlideHorizontal",
                        "autoPlayInterval": 2,
                        "autoPlay": false,
                        "transDuration": 1,
                        "pauseAutoPlayOnMouseOver": true,
                        "direction": "RTL",
                        "shouldHideOverflowContent": true,
                        "flexibleBoxHeight": false,
                        "showNavigationButton": true,
                        "showNavigationDots": true,
                        "navigationButtonSize": 13,
                        "navigationButtonMargin": 16,
                        "navigationDotsSize": 6,
                        "navigationDotsMargin": 24,
                        "navigationDotsGap": 15,
                        "navigationDotsAlignment": "center"
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-arrowColor": "1",
                                "alpha-bg": "1",
                                "alpha-brd": "0",
                                "alpha-dotsColor": "1",
                                "bg": "#FFFFFF",
                                "boxShadowToggleOn-shd": "true",
                                "brw": "0",
                                "shd": "0 0 0 rgba(0, 0, 0, 0.6)"
                            },
                            "propertiesSource": {
                                "alpha-arrowColor": "value",
                                "alpha-bg": "value",
                                "alpha-brd": "value",
                                "alpha-dotsColor": "value",
                                "arrowColor": "value",
                                "bg": "value",
                                "boxShadowToggleOn-shd": "value",
                                "brd": "value",
                                "brw": "value",
                                "dotsColor": "value",
                                "shd": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.viewer.components.BoxSlideShow",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.boxSlideShow.viewer.skins.thinArrowsLargeSelectedCircleSkin"
                    }
                },
                "id": "comp-io01cq4k"
            },
            "preset": {
                "rect": {"width": 162, "height": 135, "x": 162, "y": 134.5},
                "label": "add_preset_BoxSlideShow_4",
                "tags": null
            }
        }, {
            "id": "BoxSlideShow_BoxSlideShow_5",
            "structure": {
                "type": "Container",
                "skin": "wysiwyg.common.components.boxSlideShow.viewer.skins.squareButtonsSkin",
                "layout": {
                    "width": 490,
                    "height": 576,
                    "x": 117,
                    "y": 1159,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.BoxSlideShow",
                "components": [{
                    "type": "Container",
                    "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                    "layout": {
                        "width": 490,
                        "height": 576,
                        "x": 0,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.BoxSlideShowSlide",
                    "components": [{
                        "type": "Container",
                        "skin": "wysiwyg.viewer.skins.area.RectangleArea",
                        "layout": {
                            "width": 414,
                            "height": 483,
                            "x": 38,
                            "y": 36,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "mobile.core.components.Container",
                        "components": [{
                            "type": "Component",
                            "layout": {
                                "width": 398,
                                "height": 65,
                                "x": 8,
                                "y": 86,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 style=\"line-height: 1.3em; text-align: center; font-size: 45px;\" class=\"font_2\"><span style=\"color:#FFD614;\"><span style=\"letter-spacing:0.03em;\"><span style=\"font-size:45px;\"><span style=\"font-family:anton,sans-serif;\"><span style=\"font-weight:bold;\">WHAT PEOPLE SAY</span></span></span></span></span></h2>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew",
                            "mobileStructure": {
                                "type": "Component",
                                "layout": {
                                    "width": 239,
                                    "height": 39,
                                    "x": 0,
                                    "y": 19,
                                    "scale": 0.8573749999999999,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "data": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<h2 style=\"line-height: 1.3em; text-align: center; font-size: 45px;\" class=\"font_2\"><span style=\"color:#FFD614;\"><span style=\"letter-spacing:0.03em;\"><span style=\"font-size:45px;\"><span style=\"font-family:anton,sans-serif;\"><span style=\"font-weight:bold;\">WHAT PEOPLE SAY</span></span></span></span></span></h2>",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "props": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                },
                                "style": "txtNew"
                            }
                        }, {
                            "type": "Component",
                            "layout": {
                                "width": 269,
                                "height": 100,
                                "x": 72,
                                "y": 285,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p style=\"text-align: center; font-size: 16px; line-height: 1.5em;\" class=\"font_8\"><span style=\"font-size:16px;\"><span style=\"line-height:1.5em;\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif;\"><span class=\"color_2\">“Testimonials work great. Showing your reviews in quotes&nbsp;has a powerful effect on customers and makes them more likely to trust you.”</span></span></span></span></p>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew",
                            "mobileStructure": {
                                "type": "Component",
                                "layout": {
                                    "width": 220,
                                    "height": 115,
                                    "x": 10,
                                    "y": 147,
                                    "scale": 0.95,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "data": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<p style=\"text-align: center; font-size: 16px; line-height: 1.5em;\" class=\"font_8\"><span style=\"font-size:16px;\"><span style=\"line-height:1.5em;\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif;\"><span class=\"color_2\">“Testimonials work great. Showing your reviews in quotes&nbsp;has a powerful effect on customers and makes them more likely to trust you.”</span></span></span></span></p>",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "props": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                },
                                "style": "txtNew"
                            }
                        }, {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.line.SolidLine",
                            "layout": {
                                "width": 30,
                                "height": 6,
                                "x": 192,
                                "y": 246,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.FiveGridLine",
                            "props": {
                                "type": "FiveGridLineProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "fullScreenModeOn": false
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {"alpha-brd": "1", "brd": "#FFD614", "lnw": "6"},
                                    "propertiesSource": {"brd": "value", "lnw": "value"}
                                },
                                "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.line.SolidLine"
                            },
                            "mobileStructure": {
                                "type": "Component",
                                "skin": "wysiwyg.viewer.skins.line.SolidLine",
                                "layout": {
                                    "width": 30,
                                    "height": 6,
                                    "x": 105,
                                    "y": 126,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.FiveGridLine",
                                "props": {
                                    "type": "FiveGridLineProperties",
                                    "metaData": {"schemaVersion": "1.0"},
                                    "fullScreenModeOn": false
                                },
                                "style": {
                                    "type": "TopLevelStyle",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "style": {
                                        "groups": {},
                                        "properties": {"alpha-brd": "1", "brd": "#FFD614", "lnw": "6"},
                                        "propertiesSource": {"brd": "value", "lnw": "value"}
                                    },
                                    "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                                    "pageId": "",
                                    "compId": "",
                                    "styleType": "custom",
                                    "skin": "wysiwyg.viewer.skins.line.SolidLine"
                                }
                            }
                        }, {
                            "type": "Component",
                            "layout": {
                                "width": 246,
                                "height": 60,
                                "x": 84,
                                "y": 168,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 style=\"line-height: 1.2em; text-align: center; font-size: 23px;\" class=\"font_2\"><span style=\"line-height:1.2em;\"><span class=\"color_2\"><span style=\"font-family:anton,sans-serif;\">Show the world how much you're loved</span></span></span></h2>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew",
                            "mobileStructure": {
                                "type": "Component",
                                "layout": {
                                    "width": 189,
                                    "height": 52,
                                    "x": 24,
                                    "y": 60,
                                    "scale": 0.8145062499999999,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "data": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<h2 style=\"line-height: 1.2em; text-align: center; font-size: 23px;\" class=\"font_2\"><span style=\"line-height:1.2em;\"><span class=\"color_2\"><span style=\"font-family:anton,sans-serif;\">Show the world how much you're loved</span></span></span></h2>",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "props": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                },
                                "style": "txtNew"
                            }
                        }],
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {"alpha-bg": "1", "bg": "#FFFFFF"},
                                "propertiesSource": {"bg": "value"}
                            },
                            "componentClassName": "mobile.core.components.Container",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.area.RectangleArea"
                        },
                        "mobileStructure": {
                            "type": "Container",
                            "skin": "wysiwyg.viewer.skins.area.RectangleArea",
                            "layout": {
                                "width": 240,
                                "height": 286,
                                "x": 20,
                                "y": 20,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "mobile.core.components.Container",
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {"alpha-bg": "1", "bg": "#FFFFFF"},
                                    "propertiesSource": {"bg": "value"}
                                },
                                "componentClassName": "mobile.core.components.Container",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.area.RectangleArea"
                            }
                        }
                    }],
                    "data": {
                        "type": "BoxSlideShowSlide",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "1"})
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {"bg": "#001F2C", "brd": "color_19", "brw": "0", "rd": "0px"},
                            "propertiesSource": {"bg": "value"}
                        },
                        "componentClassName": "wysiwyg.viewer.components.BoxSlideShowSlide",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "mediaRef": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "title": "Paint Print Floor",
                                "uri": "4b638eda91074488962f2ed310eba030.jpg",
                                "description": "public/a0c10733-b2a6-4330-a431-537a9f05c353/820351a1-729d-4bd1-856d-d578ca44f29e",
                                "width": 4288,
                                "height": 2848,
                                "alt": "",
                                "artist": {"id": "", "name": ""}
                            },
                            "color": "#FFFFFF",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "mobileStructure": {
                        "type": "Container",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                        "layout": {
                            "width": 280,
                            "height": 357,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.BoxSlideShowSlide",
                        "data": {
                            "type": "BoxSlideShowSlide",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "1"})
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {"bg": "#001F2C", "brd": "color_19", "brw": "0", "rd": "0px"},
                                "propertiesSource": {"bg": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.BoxSlideShowSlide",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "mediaRef": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "title": "Paint Print Floor",
                                    "uri": "4b638eda91074488962f2ed310eba030.jpg",
                                    "description": "public/a0c10733-b2a6-4330-a431-537a9f05c353/820351a1-729d-4bd1-856d-d578ca44f29e",
                                    "width": 4288,
                                    "height": 2848,
                                    "alt": "",
                                    "artist": {"id": "", "name": ""}
                                },
                                "color": "#FFFFFF",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            }
                        }
                    }
                }, {
                    "type": "Container",
                    "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                    "layout": {
                        "width": 490,
                        "height": 576,
                        "x": 0,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.BoxSlideShowSlide",
                    "components": [{
                        "type": "Container",
                        "skin": "wysiwyg.viewer.skins.area.RectangleArea",
                        "layout": {
                            "width": 414,
                            "height": 482,
                            "x": 38,
                            "y": 36,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "mobile.core.components.Container",
                        "components": [{
                            "type": "Component",
                            "layout": {
                                "width": 398,
                                "height": 65,
                                "x": 8,
                                "y": 86,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 style=\"line-height: 1.3em; text-align: center; font-size: 45px;\" class=\"font_2\"><span style=\"letter-spacing:0.03em;\"><span style=\"color:#0600FF;\"><span style=\"font-size:45px;\"><span style=\"font-family:anton,sans-serif;\"><span style=\"font-weight:bold;\">TITLE GOES HERE</span></span></span></span></span></h2>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew",
                            "mobileStructure": {
                                "type": "Component",
                                "layout": {
                                    "width": 239,
                                    "height": 40,
                                    "x": 0,
                                    "y": 19,
                                    "scale": 0.87,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "data": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<h2 style=\"line-height: 1.3em; text-align: center; font-size: 45px;\" class=\"font_2\"><span style=\"letter-spacing:0.03em;\"><span style=\"color:#0600FF;\"><span style=\"font-size:45px;\"><span style=\"font-family:anton,sans-serif;\"><span style=\"font-weight:bold;\">TITLE GOES HERE</span></span></span></span></span></h2>",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "props": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                },
                                "style": "txtNew"
                            }
                        }, {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.line.SolidLine",
                            "layout": {
                                "width": 30,
                                "height": 6,
                                "x": 192,
                                "y": 260,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.FiveGridLine",
                            "props": {
                                "type": "FiveGridLineProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "fullScreenModeOn": false
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {"alpha-brd": "1", "brd": "#0600FF", "lnw": "6"},
                                    "propertiesSource": {"brd": "value", "lnw": "value"}
                                },
                                "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.line.SolidLine"
                            },
                            "mobileStructure": {
                                "type": "Component",
                                "skin": "wysiwyg.viewer.skins.line.SolidLine",
                                "layout": {
                                    "width": 30,
                                    "height": 6,
                                    "x": 105,
                                    "y": 126,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.FiveGridLine",
                                "props": {
                                    "type": "FiveGridLineProperties",
                                    "metaData": {"schemaVersion": "1.0"},
                                    "fullScreenModeOn": false
                                },
                                "style": {
                                    "type": "TopLevelStyle",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "style": {
                                        "groups": {},
                                        "properties": {"alpha-brd": "1", "brd": "#0600FF", "lnw": "6"},
                                        "propertiesSource": {"brd": "value", "lnw": "value"}
                                    },
                                    "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                                    "pageId": "",
                                    "compId": "",
                                    "styleType": "custom",
                                    "skin": "wysiwyg.viewer.skins.line.SolidLine"
                                }
                            }
                        }, {
                            "type": "Component",
                            "layout": {
                                "width": 246,
                                "height": 100,
                                "x": 84,
                                "y": 299,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p style=\"text-align: center; font-size: 16px; line-height: 1.5em;\" class=\"font_8\"><span style=\"font-size:16px;\"><span style=\"line-height:1.5em;\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif;\"><span class=\"color_2\">“Share the amazing things customers are saying about your business. Double click, or click Edit Text to make it yours.”</span></span></span></span></p>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew",
                            "mobileStructure": {
                                "type": "Component",
                                "layout": {
                                    "width": 220,
                                    "height": 92,
                                    "x": 10,
                                    "y": 148,
                                    "scale": 0.95,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "data": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<p style=\"text-align: center; font-size: 16px; line-height: 1.5em;\" class=\"font_8\"><span style=\"font-size:16px;\"><span style=\"line-height:1.5em;\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif;\"><span class=\"color_2\">“Share the amazing things customers are saying about your business. Double click, or click Edit Text to make it yours.”</span></span></span></span></p>",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "props": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                },
                                "style": "txtNew"
                            }
                        }, {
                            "type": "Component",
                            "layout": {
                                "width": 212,
                                "height": 60,
                                "x": 101,
                                "y": 169,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 style=\"line-height: 1.2em; text-align: center; font-size: 23px;\" class=\"font_2\"><span style=\"font-family: anton, sans-serif;\"><span class=\"color_2\">Now add a bit more info here.</span></span></h2>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew",
                            "mobileStructure": {
                                "type": "Component",
                                "layout": {
                                    "width": 171,
                                    "height": 52,
                                    "x": 35,
                                    "y": 60,
                                    "scale": 0.83,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "data": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<h2 style=\"line-height: 1.2em; text-align: center; font-size: 23px;\" class=\"font_2\"><span style=\"font-family: anton, sans-serif;\"><span class=\"color_2\">Now add a bit more info here.</span></span></h2>",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "props": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                },
                                "style": "txtNew"
                            }
                        }],
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {"alpha-bg": "1", "bg": "#FFFFFF"},
                                "propertiesSource": {"bg": "value"}
                            },
                            "componentClassName": "mobile.core.components.Container",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.area.RectangleArea"
                        },
                        "mobileStructure": {
                            "type": "Container",
                            "skin": "wysiwyg.viewer.skins.area.RectangleArea",
                            "layout": {
                                "width": 240,
                                "height": 287,
                                "x": 20,
                                "y": 20,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "mobile.core.components.Container",
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {"alpha-bg": "1", "bg": "#FFFFFF"},
                                    "propertiesSource": {"bg": "value"}
                                },
                                "componentClassName": "mobile.core.components.Container",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.area.RectangleArea"
                            }
                        }
                    }],
                    "data": {
                        "type": "BoxSlideShowSlide",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "2"})
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {"bg": "#001F2C", "brd": "color_19", "brw": "0", "rd": "0px"},
                            "propertiesSource": {"bg": "value"}
                        },
                        "componentClassName": "wysiwyg.viewer.components.BoxSlideShowSlide",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "mediaRef": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "title": "Paint Print Floor",
                                "uri": "4b638eda91074488962f2ed310eba030.jpg",
                                "description": "public/a0c10733-b2a6-4330-a431-537a9f05c353/820351a1-729d-4bd1-856d-d578ca44f29e",
                                "width": 4288,
                                "height": 2848,
                                "alt": "",
                                "artist": {"id": "", "name": ""}
                            },
                            "color": "#FFFFFF",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "mobileStructure": {
                        "type": "Container",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                        "layout": {
                            "width": 280,
                            "height": 357,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.BoxSlideShowSlide",
                        "data": {
                            "type": "BoxSlideShowSlide",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "2"})
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {"bg": "#001F2C", "brd": "color_19", "brw": "0", "rd": "0px"},
                                "propertiesSource": {"bg": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.BoxSlideShowSlide",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "mediaRef": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "title": "Paint Print Floor",
                                    "uri": "4b638eda91074488962f2ed310eba030.jpg",
                                    "description": "public/a0c10733-b2a6-4330-a431-537a9f05c353/820351a1-729d-4bd1-856d-d578ca44f29e",
                                    "width": 4288,
                                    "height": 2848,
                                    "alt": "",
                                    "artist": {"id": "", "name": ""}
                                },
                                "color": "#FFFFFF",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            }
                        }
                    }
                }, {
                    "type": "Container",
                    "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                    "layout": {
                        "width": 490,
                        "height": 576,
                        "x": 0,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.BoxSlideShowSlide",
                    "components": [{
                        "type": "Container",
                        "skin": "wysiwyg.viewer.skins.area.RectangleArea",
                        "layout": {
                            "width": 414,
                            "height": 482,
                            "x": 38,
                            "y": 36,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "mobile.core.components.Container",
                        "components": [{
                            "type": "Component",
                            "layout": {
                                "width": 398,
                                "height": 65,
                                "x": 8,
                                "y": 86,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 style=\"line-height: 1.3em; text-align: center; font-size: 45px;\" class=\"font_2\"><span style=\"letter-spacing:0.03em;\"><span style=\"color:#44F292;\"><span style=\"font-size:45px;\"><span style=\"font-family:anton,sans-serif;\"><span style=\"font-weight:bold;\">TITLE GOES HERE</span></span></span></span></span></h2>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew",
                            "mobileStructure": {
                                "type": "Component",
                                "layout": {
                                    "width": 239,
                                    "height": 40,
                                    "x": 0,
                                    "y": 19,
                                    "scale": 0.87,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "data": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<h2 style=\"line-height: 1.3em; text-align: center; font-size: 45px;\" class=\"font_2\"><span style=\"letter-spacing:0.03em;\"><span style=\"color:#44F292;\"><span style=\"font-size:45px;\"><span style=\"font-family:anton,sans-serif;\"><span style=\"font-weight:bold;\">TITLE GOES HERE</span></span></span></span></span></h2>",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "props": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                },
                                "style": "txtNew"
                            }
                        }, {
                            "type": "Component",
                            "layout": {
                                "width": 246,
                                "height": 100,
                                "x": 84,
                                "y": 298,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p style=\"text-align: center; font-size: 16px; line-height: 1.5em;\" class=\"font_8\"><span style=\"font-size:16px;\"><span style=\"line-height:1.5em;\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif;\"><span class=\"color_2\">“Share the amazing things customers are saying about your business. Double click, or click Edit Text to make it yours.”</span></span></span></span></p>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew",
                            "mobileStructure": {
                                "type": "Component",
                                "layout": {
                                    "width": 220,
                                    "height": 92,
                                    "x": 10,
                                    "y": 148,
                                    "scale": 0.95,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "data": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<p style=\"text-align: center; font-size: 16px; line-height: 1.5em;\" class=\"font_8\"><span style=\"font-size:16px;\"><span style=\"line-height:1.5em;\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif;\"><span class=\"color_2\">“Share the amazing things customers are saying about your business. Double click, or click Edit Text to make it yours.”</span></span></span></span></p>",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "props": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                },
                                "style": "txtNew"
                            }
                        }, {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.line.SolidLine",
                            "layout": {
                                "width": 30,
                                "height": 6,
                                "x": 192,
                                "y": 259,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.FiveGridLine",
                            "props": {
                                "type": "FiveGridLineProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "fullScreenModeOn": false
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {"alpha-brd": "1", "brd": "#44F292", "lnw": "6"},
                                    "propertiesSource": {"brd": "value", "lnw": "value"}
                                },
                                "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.line.SolidLine"
                            },
                            "mobileStructure": {
                                "type": "Component",
                                "skin": "wysiwyg.viewer.skins.line.SolidLine",
                                "layout": {
                                    "width": 30,
                                    "height": 6,
                                    "x": 105,
                                    "y": 126,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.FiveGridLine",
                                "props": {
                                    "type": "FiveGridLineProperties",
                                    "metaData": {"schemaVersion": "1.0"},
                                    "fullScreenModeOn": false
                                },
                                "style": {
                                    "type": "TopLevelStyle",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "style": {
                                        "groups": {},
                                        "properties": {"alpha-brd": "1", "brd": "#44F292", "lnw": "6"},
                                        "propertiesSource": {"brd": "value", "lnw": "value"}
                                    },
                                    "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                                    "pageId": "",
                                    "compId": "",
                                    "styleType": "custom",
                                    "skin": "wysiwyg.viewer.skins.line.SolidLine"
                                }
                            }
                        }, {
                            "type": "Component",
                            "layout": {
                                "width": 205,
                                "height": 60,
                                "x": 104,
                                "y": 169,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 style=\"line-height: 1.2em; text-align: center; font-size: 23px;\" class=\"font_2\"><span style=\"font-family: anton, sans-serif;\"><span class=\"color_2\">Now add a bit more info here.</span></span></h2>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew",
                            "mobileStructure": {
                                "type": "Component",
                                "layout": {
                                    "width": 166,
                                    "height": 52,
                                    "x": 37,
                                    "y": 60,
                                    "scale": 0.83,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "data": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<h2 style=\"line-height: 1.2em; text-align: center; font-size: 23px;\" class=\"font_2\"><span style=\"font-family: anton, sans-serif;\"><span class=\"color_2\">Now add a bit more info here.</span></span></h2>",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "props": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                },
                                "style": "txtNew"
                            }
                        }],
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {"alpha-bg": "1", "bg": "#FFFFFF"},
                                "propertiesSource": {"bg": "value"}
                            },
                            "componentClassName": "mobile.core.components.Container",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.area.RectangleArea"
                        },
                        "mobileStructure": {
                            "type": "Container",
                            "skin": "wysiwyg.viewer.skins.area.RectangleArea",
                            "layout": {
                                "width": 240,
                                "height": 287,
                                "x": 20,
                                "y": 20,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "mobile.core.components.Container",
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {"alpha-bg": "1", "bg": "#FFFFFF"},
                                    "propertiesSource": {"bg": "value"}
                                },
                                "componentClassName": "mobile.core.components.Container",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.area.RectangleArea"
                            }
                        }
                    }],
                    "data": {
                        "type": "BoxSlideShowSlide",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "3"})
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {"bg": "#001F2C", "brd": "color_19", "brw": "0", "rd": "0px"},
                            "propertiesSource": {"bg": "value"}
                        },
                        "componentClassName": "wysiwyg.viewer.components.BoxSlideShowSlide",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "mediaRef": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "title": "Paint Print Floor",
                                "uri": "4b638eda91074488962f2ed310eba030.jpg",
                                "description": "public/a0c10733-b2a6-4330-a431-537a9f05c353/820351a1-729d-4bd1-856d-d578ca44f29e",
                                "width": 4288,
                                "height": 2848,
                                "alt": "",
                                "artist": {"id": "", "name": ""},
                                "opacity": 1
                            },
                            "color": "#FFFFFF",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "mobileStructure": {
                        "type": "Container",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                        "layout": {
                            "width": 280,
                            "height": 357,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.BoxSlideShowSlide",
                        "data": {
                            "type": "BoxSlideShowSlide",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "3"})
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {"bg": "#001F2C", "brd": "color_19", "brw": "0", "rd": "0px"},
                                "propertiesSource": {"bg": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.BoxSlideShowSlide",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "mediaRef": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "title": "Paint Print Floor",
                                    "uri": "4b638eda91074488962f2ed310eba030.jpg",
                                    "description": "public/a0c10733-b2a6-4330-a431-537a9f05c353/820351a1-729d-4bd1-856d-d578ca44f29e",
                                    "width": 4288,
                                    "height": 2848,
                                    "alt": "",
                                    "artist": {"id": "", "name": ""},
                                    "opacity": 1
                                },
                                "color": "#FFFFFF",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            }
                        }
                    }
                }],
                "props": {
                    "type": "BoxSlideShowProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "transition": "CrossFade",
                    "autoPlayInterval": 3,
                    "autoPlay": true,
                    "transDuration": 1,
                    "pauseAutoPlayOnMouseOver": true,
                    "direction": "RTL",
                    "shouldHideOverflowContent": true,
                    "flexibleBoxHeight": false,
                    "showNavigationButton": true,
                    "showNavigationDots": true,
                    "navigationButtonSize": 16,
                    "navigationButtonMargin": 18,
                    "navigationDotsSize": 6,
                    "navigationDotsMargin": 25,
                    "navigationDotsGap": 16,
                    "navigationDotsAlignment": "center"
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-arrowColor": "1",
                            "alpha-bg": "1",
                            "alpha-brd": "0",
                            "alpha-dotsColor": "1",
                            "bg": "#FFFFFF",
                            "boxShadowToggleOn-shd": "true",
                            "brw": "0",
                            "shd": "0 0 0 rgba(0, 0, 0, 0.6)"
                        },
                        "propertiesSource": {
                            "alpha-arrowColor": "value",
                            "alpha-bg": "value",
                            "alpha-brd": "value",
                            "alpha-dotsColor": "value",
                            "arrowColor": "value",
                            "bg": "value",
                            "boxShadowToggleOn-shd": "value",
                            "brd": "value",
                            "brw": "value",
                            "dotsColor": "value",
                            "shd": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.BoxSlideShow",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.common.components.boxSlideShow.viewer.skins.thinArrowsLargeSelectedCircleSkin"
                },
                "mobileStructure": {
                    "type": "Container",
                    "skin": "wysiwyg.common.components.boxSlideShow.viewer.skins.thinArrowsLargeSelectedCircleSkin",
                    "layout": {
                        "width": 280,
                        "height": 357,
                        "x": 0,
                        "y": 1843,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.BoxSlideShow",
                    "props": {
                        "type": "BoxSlideShowProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "transition": "CrossFade",
                        "autoPlayInterval": 2,
                        "autoPlay": false,
                        "transDuration": 1,
                        "pauseAutoPlayOnMouseOver": true,
                        "direction": "RTL",
                        "shouldHideOverflowContent": true,
                        "flexibleBoxHeight": false,
                        "showNavigationButton": true,
                        "showNavigationDots": true,
                        "navigationButtonSize": 15,
                        "navigationButtonMargin": 22,
                        "navigationDotsSize": 6,
                        "navigationDotsMargin": 24,
                        "navigationDotsGap": 15,
                        "navigationDotsAlignment": "center"
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-arrowColor": "1",
                                "alpha-bg": "1",
                                "alpha-brd": "0",
                                "alpha-dotsColor": "1",
                                "bg": "#FFFFFF",
                                "boxShadowToggleOn-shd": "true",
                                "brw": "0",
                                "shd": "0 0 0 rgba(0, 0, 0, 0.6)"
                            },
                            "propertiesSource": {
                                "alpha-arrowColor": "value",
                                "alpha-bg": "value",
                                "alpha-brd": "value",
                                "alpha-dotsColor": "value",
                                "arrowColor": "value",
                                "bg": "value",
                                "boxShadowToggleOn-shd": "value",
                                "brd": "value",
                                "brw": "value",
                                "dotsColor": "value",
                                "shd": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.viewer.components.BoxSlideShow",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.boxSlideShow.viewer.skins.thinArrowsLargeSelectedCircleSkin"
                    }
                },
                "id": "comp-im4jeksj"
            },
            "preset": {
                "rect": {"width": 162, "height": 183.5, "x": 0, "y": 269.5},
                "label": "add_preset_BoxSlideShow_5",
                "tags": null
            }
        }, {
            "id": "BoxSlideShow_BoxSlideShow_6",
            "structure": {
                "type": "Container",
                "skin": "wysiwyg.common.components.boxSlideShow.viewer.skins.thinArrowsLargeSelectedCircleSkin",
                "layout": {
                    "width": 490,
                    "height": 576,
                    "x": 687,
                    "y": 1159,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.BoxSlideShow",
                "components": [{
                    "type": "Container",
                    "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                    "layout": {
                        "width": 490,
                        "height": 576,
                        "x": 0,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.BoxSlideShowSlide",
                    "components": [{
                        "type": "Component",
                        "layout": {
                            "width": 388,
                            "height": 235,
                            "x": 51,
                            "y": 95,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WPhoto",
                        "data": {
                            "type": "Image",
                            "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                            "title": "Event Tent",
                            "uri": "cedfdef6a25cbed8d633e133c04e780c.jpg",
                            "description": "",
                            "width": 1000,
                            "height": 1500,
                            "alt": "",
                            "crop": {"x": 0, "y": 768, "width": 1000, "height": 549}
                        },
                        "props": {
                            "type": "WPhotoProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "displayMode": "fill",
                            "onClickBehavior": "goToLink",
                            "effectName": "none"
                        },
                        "style": "wp2",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 240,
                                "height": 124,
                                "x": 20,
                                "y": 68,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WPhoto",
                            "data": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                                "title": "Event Tent",
                                "uri": "cedfdef6a25cbed8d633e133c04e780c.jpg",
                                "description": "",
                                "width": 1000,
                                "height": 1500,
                                "alt": "",
                                "crop": {"x": 0, "y": 768, "width": 1000, "height": 549}
                            },
                            "props": {
                                "type": "WPhotoProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "displayMode": "fill",
                                "onClickBehavior": "goToLink",
                                "effectName": "none"
                            },
                            "style": "wp2"
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 387,
                            "height": 30,
                            "x": 51,
                            "y": 360,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<p style=\"text-align: center; font-size: 20px; line-height: 1.4em;\" class=\"font_8\"><span class=\"color_11\"><span style=\"font-size:20px;\"><span style=\"font-family:raleway,sans-serif;\">My Hip Event, MM/DD/YY</span></span></span></p>",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 147,
                                "height": 50,
                                "x": 67,
                                "y": 209,
                                "scale": 0.8573749999999999,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p style=\"text-align: center; font-size: 20px; line-height: 1.4em;\" class=\"font_8\"><span class=\"color_11\"><span style=\"font-size:20px;\"><span style=\"font-family:raleway,sans-serif;\">My Hip Event, MM/DD/YY</span></span></span></p>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 345,
                            "height": 44,
                            "x": 72,
                            "y": 403,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<p style=\"text-align: center; font-size: 15px; line-height: 1.4em;\" class=\"font_8\"><span style=\"font-size:15px;\"><span style=\"line-height:1.4em;\"><span class=\"color_11\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif;\">Describe your event here and generate buzz. Where's it happening, and when does it start?</span></span></span></span></p>",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 240,
                                "height": 60,
                                "x": 20,
                                "y": 276,
                                "scale": 0.9025,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p style=\"text-align: center; font-size: 15px; line-height: 1.4em;\" class=\"font_8\"><span style=\"font-size:15px;\"><span style=\"line-height:1.4em;\"><span class=\"color_11\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif;\">Describe your event here and generate buzz. Where's it happening, and when does it start?</span></span></span></span></p>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.button.BasicButton",
                        "layout": {
                            "width": 118,
                            "height": 32,
                            "x": 185,
                            "y": 469,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.SiteButton",
                        "data": {
                            "type": "LinkableButton",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "label": "RSVP"
                        },
                        "props": {
                            "type": "ButtonProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "align": "center",
                            "margin": 0
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "alpha-bg": "0",
                                    "alpha-bgh": "1",
                                    "alpha-brd": "1",
                                    "alpha-brdh": "1",
                                    "bg": "#626262",
                                    "bgh": "#FFFFFF",
                                    "boxShadowToggleOn-shd": "false",
                                    "brd": "#FFFFFF",
                                    "brdh": "#626262",
                                    "brw": "1",
                                    "fnt": "normal normal normal 14px/1.4em avenir-lt-w01_35-light1475496",
                                    "rd": "0",
                                    "shd": "0 1px 4px rgba(0, 0, 0, 0.6)",
                                    "txt": "#FFFFFF",
                                    "txth": "#000000"
                                },
                                "propertiesSource": {
                                    "alpha-bg": "value",
                                    "alpha-bgh": "value",
                                    "alpha-brd": "value",
                                    "alpha-brdh": "value",
                                    "bg": "value",
                                    "bgh": "value",
                                    "boxShadowToggleOn-shd": "value",
                                    "brd": "value",
                                    "brdh": "value",
                                    "brw": "value",
                                    "fnt": "value",
                                    "rd": "value",
                                    "shd": "value",
                                    "txt": "value",
                                    "txth": "value"
                                }
                            },
                            "componentClassName": "wysiwyg.viewer.components.SiteButton",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.button.BasicButton"
                        },
                        "mobileStructure": {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.button.BasicButton",
                            "layout": {
                                "width": 118,
                                "height": 30,
                                "x": 82,
                                "y": 362,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.SiteButton",
                            "data": {
                                "type": "LinkableButton",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "label": "RSVP"
                            },
                            "props": {
                                "type": "ButtonProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "align": "center",
                                "margin": 0
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {
                                        "alpha-bg": "0",
                                        "alpha-bgh": "1",
                                        "alpha-brd": "1",
                                        "alpha-brdh": "1",
                                        "bg": "#626262",
                                        "bgh": "#FFFFFF",
                                        "boxShadowToggleOn-shd": "false",
                                        "brd": "#FFFFFF",
                                        "brdh": "#626262",
                                        "brw": "1",
                                        "fnt": "normal normal normal 14px/1.4em avenir-lt-w01_35-light1475496",
                                        "rd": "0",
                                        "shd": "0 1px 4px rgba(0, 0, 0, 0.6)",
                                        "txt": "#FFFFFF",
                                        "txth": "#000000"
                                    },
                                    "propertiesSource": {
                                        "alpha-bg": "value",
                                        "alpha-bgh": "value",
                                        "alpha-brd": "value",
                                        "alpha-brdh": "value",
                                        "bg": "value",
                                        "bgh": "value",
                                        "boxShadowToggleOn-shd": "value",
                                        "brd": "value",
                                        "brdh": "value",
                                        "brw": "value",
                                        "fnt": "value",
                                        "rd": "value",
                                        "shd": "value",
                                        "txt": "value",
                                        "txth": "value"
                                    }
                                },
                                "componentClassName": "wysiwyg.viewer.components.SiteButton",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.button.BasicButton"
                            }
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 398,
                            "height": 49,
                            "x": 46,
                            "y": 28,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<h2 style=\"line-height: 1.3em; text-align: center; font-size: 35px;\" class=\"font_2\"><span style=\"font-size:35px;\"><span style=\"letter-spacing:0.2em;\"><span style=\"font-family:raleway,sans-serif;\"><span class=\"color_11\">EVENT</span></span></span></span></h2>",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 240,
                                "height": 38,
                                "x": 20,
                                "y": 21,
                                "scale": 0.9428927976562499,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 style=\"line-height: 1.3em; text-align: center; font-size: 35px;\" class=\"font_2\"><span style=\"font-size:35px;\"><span style=\"letter-spacing:0.2em;\"><span style=\"font-family:raleway,sans-serif;\"><span class=\"color_11\">EVENT</span></span></span></span></h2>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }],
                    "data": {
                        "type": "BoxSlideShowSlide",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "1"})
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {"bg": "#001F2C", "brd": "color_19", "brw": "0", "rd": "0px"},
                            "propertiesSource": {"bg": "value"}
                        },
                        "componentClassName": "wysiwyg.viewer.components.BoxSlideShowSlide",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "color": "#000000",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "mobileStructure": {
                        "type": "Container",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                        "layout": {
                            "width": 280,
                            "height": 445,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.BoxSlideShowSlide",
                        "data": {
                            "type": "BoxSlideShowSlide",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "1"})
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {"bg": "#001F2C", "brd": "color_19", "brw": "0", "rd": "0px"},
                                "propertiesSource": {"bg": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.BoxSlideShowSlide",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "#000000",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            }
                        }
                    }
                }, {
                    "type": "Container",
                    "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                    "layout": {
                        "width": 490,
                        "height": 576,
                        "x": 0,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.BoxSlideShowSlide",
                    "components": [{
                        "type": "Component",
                        "layout": {
                            "width": 398,
                            "height": 49,
                            "x": 46,
                            "y": 28,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<h2 style=\"line-height: 1.3em; text-align: center; font-size: 35px;\" class=\"font_2\"><span style=\"font-size:35px;\"><span style=\"letter-spacing:0.2em;\"><span style=\"font-family:raleway,sans-serif;\"><span class=\"color_11\">EVENT</span></span></span></span></h2>",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 240,
                                "height": 38,
                                "x": 20,
                                "y": 21,
                                "scale": 0.94,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 style=\"line-height: 1.3em; text-align: center; font-size: 35px;\" class=\"font_2\"><span style=\"font-size:35px;\"><span style=\"letter-spacing:0.2em;\"><span style=\"font-family:raleway,sans-serif;\"><span class=\"color_11\">EVENT</span></span></span></span></h2>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 368,
                            "height": 44,
                            "x": 61,
                            "y": 433,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<p style=\"text-align: center; font-size: 15px; line-height: 1.4em;\" class=\"font_8\"><span style=\"font-size:15px;\"><span style=\"line-height:1.4em;\"><span class=\"color_11\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif;\">Describe your event here and generate buzz. Where's it happening, and when does it start?</span></span></span></span></p>",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 240,
                                "height": 60,
                                "x": 20,
                                "y": 276,
                                "scale": 0.93,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p style=\"text-align: center; font-size: 15px; line-height: 1.4em;\" class=\"font_8\"><span style=\"font-size:15px;\"><span style=\"line-height:1.4em;\"><span class=\"color_11\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif;\">Describe your event here and generate buzz. Where's it happening, and when does it start?</span></span></span></span></p>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.button.BasicButton",
                        "layout": {
                            "width": 118,
                            "height": 32,
                            "x": 186,
                            "y": 500,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.SiteButton",
                        "data": {
                            "type": "LinkableButton",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "label": "RSVP"
                        },
                        "props": {
                            "type": "ButtonProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "align": "center",
                            "margin": 0
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "alpha-bg": "0",
                                    "alpha-bgh": "1",
                                    "alpha-brd": "1",
                                    "alpha-brdh": "1",
                                    "bg": "#626262",
                                    "bgh": "#FFFFFF",
                                    "boxShadowToggleOn-shd": "false",
                                    "brd": "#FFFFFF",
                                    "brdh": "#626262",
                                    "brw": "1",
                                    "fnt": "normal normal normal 14px/1.4em avenir-lt-w01_35-light1475496",
                                    "rd": "0",
                                    "shd": "0 1px 4px rgba(0, 0, 0, 0.6)",
                                    "txt": "#FFFFFF",
                                    "txth": "#000000"
                                },
                                "propertiesSource": {
                                    "alpha-bg": "value",
                                    "alpha-bgh": "value",
                                    "alpha-brd": "value",
                                    "alpha-brdh": "value",
                                    "bg": "value",
                                    "bgh": "value",
                                    "boxShadowToggleOn-shd": "value",
                                    "brd": "value",
                                    "brdh": "value",
                                    "brw": "value",
                                    "fnt": "value",
                                    "rd": "value",
                                    "shd": "value",
                                    "txt": "value",
                                    "txth": "value"
                                }
                            },
                            "componentClassName": "wysiwyg.viewer.components.SiteButton",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.button.BasicButton"
                        },
                        "mobileStructure": {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.button.BasicButton",
                            "layout": {
                                "width": 118,
                                "height": 30,
                                "x": 81,
                                "y": 362,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.SiteButton",
                            "data": {
                                "type": "LinkableButton",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "label": "RSVP"
                            },
                            "props": {
                                "type": "ButtonProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "align": "center",
                                "margin": 0
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {
                                        "alpha-bg": "0",
                                        "alpha-bgh": "1",
                                        "alpha-brd": "1",
                                        "alpha-brdh": "1",
                                        "bg": "#626262",
                                        "bgh": "#FFFFFF",
                                        "boxShadowToggleOn-shd": "false",
                                        "brd": "#FFFFFF",
                                        "brdh": "#626262",
                                        "brw": "1",
                                        "fnt": "normal normal normal 14px/1.4em avenir-lt-w01_35-light1475496",
                                        "rd": "0",
                                        "shd": "0 1px 4px rgba(0, 0, 0, 0.6)",
                                        "txt": "#FFFFFF",
                                        "txth": "#000000"
                                    },
                                    "propertiesSource": {
                                        "alpha-bg": "value",
                                        "alpha-bgh": "value",
                                        "alpha-brd": "value",
                                        "alpha-brdh": "value",
                                        "bg": "value",
                                        "bgh": "value",
                                        "boxShadowToggleOn-shd": "value",
                                        "brd": "value",
                                        "brdh": "value",
                                        "brw": "value",
                                        "fnt": "value",
                                        "rd": "value",
                                        "shd": "value",
                                        "txt": "value",
                                        "txth": "value"
                                    }
                                },
                                "componentClassName": "wysiwyg.viewer.components.SiteButton",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.button.BasicButton"
                            }
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 387,
                            "height": 30,
                            "x": 51,
                            "y": 376,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<p style=\"text-align: center; font-size: 20px; line-height: 1.4em;\" class=\"font_8\"><span class=\"color_11\"><span style=\"font-size:20px;\"><span style=\"font-family:raleway,sans-serif;\">My Hip Event, MM/DD/YY</span></span></span></p>",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 147,
                                "height": 50,
                                "x": 67,
                                "y": 209,
                                "scale": 0.85,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p style=\"text-align: center; font-size: 20px; line-height: 1.4em;\" class=\"font_8\"><span class=\"color_11\"><span style=\"font-size:20px;\"><span style=\"font-family:raleway,sans-serif;\">My Hip Event, MM/DD/YY</span></span></span></p>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 388,
                            "height": 236,
                            "x": 51,
                            "y": 110,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WPhoto",
                        "data": {
                            "type": "Image",
                            "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                            "title": "Sparklers",
                            "uri": "c740c43868c444ccbbe75778e9c91a65.jpg",
                            "description": "",
                            "width": 4203,
                            "height": 2802,
                            "alt": ""
                        },
                        "props": {
                            "type": "WPhotoProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "displayMode": "fill",
                            "onClickBehavior": "goToLink",
                            "effectName": "none"
                        },
                        "style": "wp2",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 240,
                                "height": 124,
                                "x": 20,
                                "y": 68,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WPhoto",
                            "data": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                                "title": "Sparklers",
                                "uri": "c740c43868c444ccbbe75778e9c91a65.jpg",
                                "description": "",
                                "width": 4203,
                                "height": 2802,
                                "alt": ""
                            },
                            "props": {
                                "type": "WPhotoProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "displayMode": "fill",
                                "onClickBehavior": "goToLink",
                                "effectName": "none"
                            },
                            "style": "wp2"
                        }
                    }],
                    "data": {
                        "type": "BoxSlideShowSlide",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "2"})
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {"bg": "#001F2C", "brd": "color_19", "brw": "0", "rd": "0px"},
                            "propertiesSource": {"bg": "value"}
                        },
                        "componentClassName": "wysiwyg.viewer.components.BoxSlideShowSlide",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "color": "#000000",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "mobileStructure": {
                        "type": "Container",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                        "layout": {
                            "width": 280,
                            "height": 445,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.BoxSlideShowSlide",
                        "data": {
                            "type": "BoxSlideShowSlide",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "2"})
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {"bg": "#001F2C", "brd": "color_19", "brw": "0", "rd": "0px"},
                                "propertiesSource": {"bg": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.BoxSlideShowSlide",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "#000000",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            }
                        }
                    }
                }, {
                    "type": "Container",
                    "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                    "layout": {
                        "width": 490,
                        "height": 576,
                        "x": 0,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.BoxSlideShowSlide",
                    "components": [{
                        "type": "Component",
                        "layout": {
                            "width": 398,
                            "height": 49,
                            "x": 46,
                            "y": 28,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<h2 style=\"line-height: 1.3em; text-align: center; font-size: 35px;\" class=\"font_2\"><span style=\"font-size:35px;\"><span style=\"letter-spacing:0.2em;\"><span style=\"font-family:raleway,sans-serif;\"><span class=\"color_11\">EVENT</span></span></span></span></h2>",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 240,
                                "height": 38,
                                "x": 20,
                                "y": 21,
                                "scale": 0.94,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 style=\"line-height: 1.3em; text-align: center; font-size: 35px;\" class=\"font_2\"><span style=\"font-size:35px;\"><span style=\"letter-spacing:0.2em;\"><span style=\"font-family:raleway,sans-serif;\"><span class=\"color_11\">EVENT</span></span></span></span></h2>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 388,
                            "height": 236,
                            "x": 51,
                            "y": 95,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WPhoto",
                        "data": {
                            "type": "Image",
                            "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                            "title": "GettyImages-498338296.jpg",
                            "uri": "035244_c9f842682dc04ab0890a26608795e8e3.jpg",
                            "description": "",
                            "width": 6966,
                            "height": 4649,
                            "alt": ""
                        },
                        "props": {
                            "type": "WPhotoProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "displayMode": "fill",
                            "onClickBehavior": "goToLink",
                            "effectName": "none"
                        },
                        "style": "wp2",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 240,
                                "height": 124,
                                "x": 20,
                                "y": 68,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WPhoto",
                            "data": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "2.0", "isHidden": false},
                                "title": "GettyImages-498338296.jpg",
                                "uri": "035244_c9f842682dc04ab0890a26608795e8e3.jpg",
                                "description": "",
                                "width": 6966,
                                "height": 4649,
                                "alt": ""
                            },
                            "props": {
                                "type": "WPhotoProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "displayMode": "fill",
                                "onClickBehavior": "goToLink",
                                "effectName": "none"
                            },
                            "style": "wp2"
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 387,
                            "height": 30,
                            "x": 51,
                            "y": 360,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<p style=\"text-align: center; font-size: 20px; line-height: 1.4em;\" class=\"font_8\"><span class=\"color_11\"><span style=\"font-size:20px;\"><span style=\"font-family:raleway,sans-serif;\">My Hip Event, MM/DD/YY</span></span></span></p>",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 147,
                                "height": 50,
                                "x": 67,
                                "y": 209,
                                "scale": 0.85,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p style=\"text-align: center; font-size: 20px; line-height: 1.4em;\" class=\"font_8\"><span class=\"color_11\"><span style=\"font-size:20px;\"><span style=\"font-family:raleway,sans-serif;\">My Hip Event, MM/DD/YY</span></span></span></p>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 368,
                            "height": 44,
                            "x": 61,
                            "y": 405,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<p style=\"text-align: center; font-size: 15px; line-height: 1.4em;\" class=\"font_8\"><span style=\"font-size:15px;\"><span style=\"line-height:1.4em;\"><span class=\"color_11\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif;\">Describe your event here and generate buzz. Where's it happening, and when does it start?</span></span></span></span></p>",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 240,
                                "height": 60,
                                "x": 20,
                                "y": 276,
                                "scale": 0.93,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p style=\"text-align: center; font-size: 15px; line-height: 1.4em;\" class=\"font_8\"><span style=\"font-size:15px;\"><span style=\"line-height:1.4em;\"><span class=\"color_11\"><span style=\"font-family:avenir-lt-w01_35-light1475496,sans-serif;\">Describe your event here and generate buzz. Where's it happening, and when does it start?</span></span></span></span></p>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "skin": "wysiwyg.viewer.skins.button.BasicButton",
                        "layout": {
                            "width": 118,
                            "height": 32,
                            "x": 186,
                            "y": 468,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.SiteButton",
                        "data": {
                            "type": "LinkableButton",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "label": "RSVP"
                        },
                        "props": {
                            "type": "ButtonProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "align": "center",
                            "margin": 0
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "alpha-bg": "0",
                                    "alpha-bgh": "1",
                                    "alpha-brd": "1",
                                    "alpha-brdh": "1",
                                    "bg": "#626262",
                                    "bgh": "#FFFFFF",
                                    "boxShadowToggleOn-shd": "false",
                                    "brd": "#FFFFFF",
                                    "brdh": "#626262",
                                    "brw": "1",
                                    "fnt": "normal normal normal 14px/1.4em avenir-lt-w01_35-light1475496",
                                    "rd": "0",
                                    "shd": "0 1px 4px rgba(0, 0, 0, 0.6)",
                                    "txt": "#FFFFFF",
                                    "txth": "#000000"
                                },
                                "propertiesSource": {
                                    "alpha-bg": "value",
                                    "alpha-bgh": "value",
                                    "alpha-brd": "value",
                                    "alpha-brdh": "value",
                                    "bg": "value",
                                    "bgh": "value",
                                    "boxShadowToggleOn-shd": "value",
                                    "brd": "value",
                                    "brdh": "value",
                                    "brw": "value",
                                    "fnt": "value",
                                    "rd": "value",
                                    "shd": "value",
                                    "txt": "value",
                                    "txth": "value"
                                }
                            },
                            "componentClassName": "wysiwyg.viewer.components.SiteButton",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.button.BasicButton"
                        },
                        "mobileStructure": {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.button.BasicButton",
                            "layout": {
                                "width": 118,
                                "height": 30,
                                "x": 82,
                                "y": 362,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.SiteButton",
                            "data": {
                                "type": "LinkableButton",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "label": "RSVP"
                            },
                            "props": {
                                "type": "ButtonProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "align": "center",
                                "margin": 0
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {
                                        "alpha-bg": "0",
                                        "alpha-bgh": "1",
                                        "alpha-brd": "1",
                                        "alpha-brdh": "1",
                                        "bg": "#626262",
                                        "bgh": "#FFFFFF",
                                        "boxShadowToggleOn-shd": "false",
                                        "brd": "#FFFFFF",
                                        "brdh": "#626262",
                                        "brw": "1",
                                        "fnt": "normal normal normal 14px/1.4em avenir-lt-w01_35-light1475496",
                                        "rd": "0",
                                        "shd": "0 1px 4px rgba(0, 0, 0, 0.6)",
                                        "txt": "#FFFFFF",
                                        "txth": "#000000"
                                    },
                                    "propertiesSource": {
                                        "alpha-bg": "value",
                                        "alpha-bgh": "value",
                                        "alpha-brd": "value",
                                        "alpha-brdh": "value",
                                        "bg": "value",
                                        "bgh": "value",
                                        "boxShadowToggleOn-shd": "value",
                                        "brd": "value",
                                        "brdh": "value",
                                        "brw": "value",
                                        "fnt": "value",
                                        "rd": "value",
                                        "shd": "value",
                                        "txt": "value",
                                        "txth": "value"
                                    }
                                },
                                "componentClassName": "wysiwyg.viewer.components.SiteButton",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.button.BasicButton"
                            }
                        }
                    }],
                    "data": {
                        "type": "BoxSlideShowSlide",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "3"})
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {"bg": "#001F2C", "brd": "color_19", "brw": "0", "rd": "0px"},
                            "propertiesSource": {"bg": "value"}
                        },
                        "componentClassName": "wysiwyg.viewer.components.BoxSlideShowSlide",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "color": "#000000",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "mobileStructure": {
                        "type": "Container",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                        "layout": {
                            "width": 280,
                            "height": 445,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.BoxSlideShowSlide",
                        "data": {
                            "type": "BoxSlideShowSlide",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "3"})
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {"bg": "#001F2C", "brd": "color_19", "brw": "0", "rd": "0px"},
                                "propertiesSource": {"bg": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.BoxSlideShowSlide",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "color": "#000000",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            }
                        }
                    }
                }],
                "props": {
                    "type": "BoxSlideShowProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "transition": "SlideHorizontal",
                    "autoPlayInterval": 3,
                    "autoPlay": true,
                    "transDuration": 1,
                    "pauseAutoPlayOnMouseOver": true,
                    "direction": "RTL",
                    "shouldHideOverflowContent": true,
                    "flexibleBoxHeight": false,
                    "showNavigationButton": true,
                    "showNavigationDots": true,
                    "navigationButtonSize": 15,
                    "navigationButtonMargin": 25,
                    "navigationDotsSize": 6,
                    "navigationDotsMargin": 36,
                    "navigationDotsGap": 16,
                    "navigationDotsAlignment": "center"
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-arrowColor": "1",
                            "alpha-brd": "0",
                            "alpha-dotsColor": "1",
                            "arrowColor": "#FFFFFF",
                            "boxShadowToggleOn-shd": "true",
                            "brd": "#207EA9",
                            "brw": "0",
                            "dotsColor": "#FFFFFF",
                            "shd": "0 0 0 rgba(0, 0, 0, 0.6)"
                        },
                        "propertiesSource": {
                            "alpha-arrowColor": "value",
                            "alpha-brd": "value",
                            "alpha-dotsColor": "value",
                            "arrowColor": "value",
                            "boxShadowToggleOn-shd": "value",
                            "brd": "value",
                            "brw": "value",
                            "dotsColor": "value",
                            "shd": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.BoxSlideShow",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.common.components.boxSlideShow.viewer.skins.thinArrowsLargeSelectedCircleSkin"
                },
                "mobileStructure": {
                    "type": "Container",
                    "skin": "wysiwyg.common.components.boxSlideShow.viewer.skins.thinArrowsLargeSelectedCircleSkin",
                    "layout": {
                        "width": 280,
                        "height": 445,
                        "x": 0,
                        "y": 2235,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.BoxSlideShow",
                    "props": {
                        "type": "BoxSlideShowProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": true},
                        "transition": "SlideHorizontal",
                        "autoPlayInterval": 2,
                        "autoPlay": false,
                        "transDuration": 1,
                        "pauseAutoPlayOnMouseOver": true,
                        "direction": "RTL",
                        "shouldHideOverflowContent": true,
                        "flexibleBoxHeight": false,
                        "showNavigationButton": true,
                        "showNavigationDots": true,
                        "navigationButtonSize": 15,
                        "navigationButtonMargin": 22,
                        "navigationDotsSize": 6,
                        "navigationDotsMargin": 24,
                        "navigationDotsGap": 15,
                        "navigationDotsAlignment": "center"
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-arrowColor": "1",
                                "alpha-brd": "0",
                                "alpha-dotsColor": "1",
                                "arrowColor": "#FFFFFF",
                                "boxShadowToggleOn-shd": "true",
                                "brd": "#207EA9",
                                "brw": "0",
                                "dotsColor": "#FFFFFF",
                                "shd": "0 0 0 rgba(0, 0, 0, 0.6)"
                            },
                            "propertiesSource": {
                                "alpha-arrowColor": "value",
                                "alpha-brd": "value",
                                "alpha-dotsColor": "value",
                                "arrowColor": "value",
                                "boxShadowToggleOn-shd": "value",
                                "brd": "value",
                                "brw": "value",
                                "dotsColor": "value",
                                "shd": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.viewer.components.BoxSlideShow",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.boxSlideShow.viewer.skins.thinArrowsLargeSelectedCircleSkin"
                    }
                },
                "id": "comp-im4kltbo"
            },
            "preset": {
                "rect": {"width": 162, "height": 183.5, "x": 162, "y": 269.5},
                "label": "add_preset_BoxSlideShow_6",
                "tags": null
            }
        }],
        "compTypes": ["wysiwyg.viewer.components.BoxSlideShow"]
    },
    "help": {"hide": false, "text": "add_section_info_text_regular_slideshow"}
}
