import type { StateMapperArgs } from 'types/redux';
import {
  hoc,
  backgroundUtils,
  url,
  imageTransform,
  serviceTopology,
} from '@/util';
import { leftBar } from '@/stateManagement';
import constants from '@/constants';
import type { EditorAPI } from '@/editorAPI';

import {
  TOP_PANEL_WIDTH,
  TOP_PANEL_HEIGHT,
  MAX_TILE_WIDTH,
  MAX_TILE_HEIGHT,
} from '../constants';

const { MEDIA_TYPES } = constants;
const emptyBackgroundObject: {
  ref: AnyFixMe;
  mediaRef: AnyFixMe;
} = {
  ref: {},
  mediaRef: null,
};

const getCurrentColor = (bgData: AnyFixMe, editorAPI: EditorAPI) => ({
  backgroundColor: backgroundUtils.getColorValue(editorAPI, bgData.ref.color),
});

const getBackgroundStylesByDataRef = (bgData: AnyFixMe) => {
  if (!bgData.ref.mediaRef) return {};
  const imageData =
    backgroundUtils.getMediaType(bgData.ref) === MEDIA_TYPES.IMAGE
      ? bgData.ref.mediaRef
      : bgData.ref.mediaRef.posterImageRef;

  const isSmaller = backgroundUtils.isSmallerFromContainer(
    imageData.width,
    imageData.height,
    MAX_TILE_WIDTH,
    MAX_TILE_HEIGHT,
  );

  const fittingType = isSmaller
    ? imageTransform.fittingTypes.TILE
    : imageTransform.fittingTypes.SCALE_TO_FILL;

  const previewDisplayData = backgroundUtils.getImageDisplayData(
    fittingType,
    bgData.ref.alignType,
    imageData.uri,
    imageData.width,
    imageData.height,
    TOP_PANEL_WIDTH,
    TOP_PANEL_HEIGHT,
  );

  const backgroundImage = `url(${url.joinURL(
    serviceTopology.staticMediaUrl,
    previewDisplayData.uri,
  )})`;

  return {
    backgroundImage,
    opacity: imageData.opacity,
    ...previewDisplayData.css.container,
  };
};

const getCurrentMedia = (bgData: AnyFixMe, editorAPI: EditorAPI) => ({
  ...getBackgroundStylesByDataRef(bgData),
  ...getCurrentColor(bgData, editorAPI),
});

const mapStateToProps = ({ state, editorAPI }: StateMapperArgs) => {
  const device = leftBar.selectors.getDeviceType(state);
  const pageId = editorAPI.pages.getPrimaryPageId();

  const bgData =
    editorAPI.pages.background.get(pageId, device) || emptyBackgroundObject;

  if (!bgData.ref) {
    bgData.ref = {};
  }

  const colorStyle = getCurrentColor(bgData, editorAPI);
  const medialStyle = getCurrentMedia(bgData, editorAPI);
  const overlayStyle = backgroundUtils.getOverlayStyle(bgData.ref, editorAPI);

  return {
    colorStyle,
    medialStyle,
    mediaType: backgroundUtils.getMediaType(bgData.ref),
    colorValue: colorStyle.backgroundColor.replace('#', '') || 'FFFFFF',
    overlayStyle,
  };
};

export const withConnect = hoc.connect(hoc.STORES.EDITOR_API, mapStateToProps);
