import React from 'react';
import _ from 'lodash';
import { Layout } from '@wix/editor-search-ui';
import { withTranslation, type WithTranslation } from 'react-i18next';

import { hoc, fedopsLogger } from '@/util';
import { ErrorReporter } from '@wix/editor-error-reporter';

import { searchModule } from '../services/searchModule/searchModule';
import { ErrorType } from '../services/searchModule/constants';
import { editorSearchBiLogger } from '../services/biLogger/biLogger';

import { KeyboardControl } from '../components/KeyboardControl';
import { SearchInput } from '../components/SearchInput/SearchInput';

import { ErrorState } from '../views/ErrorState/ErrorState';
import { EmptyState } from '../views/EmptyState/EmptyState';
import { SearchResultList } from '../views/SearchResultsList/SearchResultsList';

import { CLOSED_CATEGORY_MAX_ITEMS_COUNT } from '../constants';
import { TRANSLATIONS } from '../translations';

import {
  mapStateToProps,
  mapDispatchToProps,
} from './EditorSearchPanel.mapper';

import type { TransformedCategoryResults } from '../services/searchModule/types';

import styles from './EditorSearchPanel.scss';

const {
  connect,
  STORES: { EDITOR_API },
} = hoc;

interface EditorSearchPanelState {
  searchQuery: string;
  isLoading: boolean;
  errorInfo: {
    type: ErrorType;
    message: string;
    status?: number;
  };
  categories: TransformedCategoryResults[];
}

type EditorSearchPanelProps = WithTranslation &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

class EditorSearchPanelComponent extends React.Component<
  EditorSearchPanelProps,
  EditorSearchPanelState
> {
  static getDerivedStateFromError(error: AnyFixMe) {
    return {
      errorInfo: {
        type: ErrorType.UNKNOWN,
        message: error.message,
      },
    };
  }

  displayName = 'EditorSearchPanel';
  state = {
    searchQuery: '',
    isLoading: false,
    errorInfo: null as AnyFixMe,
    categories: [] as AnyFixMe[],
  };

  componentDidUpdate(
    prevProps: EditorSearchPanelProps,
    prevState: EditorSearchPanelState,
  ) {
    const { searchQuery } = this.state;

    if (searchQuery !== prevState.searchQuery) {
      this.doSearch(searchQuery);
    }
    if (!prevProps.isOpen && this.props.isOpen) {
      this.transformSearchItems();
      fedopsLogger.interactionEnded(
        fedopsLogger.INTERACTIONS.EDITOR_SEARCH.OPEN_PANEL,
      );
    }
  }

  componentDidCatch(error: AnyFixMe) {
    ErrorReporter.captureException(error, {
      tags: {
        editorSearch: true,
      },
    });
  }

  private currentSearch: ReturnType<typeof searchModule.search> = null;

  transformSearchItems() {
    const { categories } = this.state;

    this.setState({
      categories: categories.map((category) => ({
        ...category,
        items: category.rawItems
          .map((item: AnyFixMe) => searchModule.transformItem(item))
          .filter((item: AnyFixMe) => !!item),
      })),
    });
  }

  updateSearchQuery = (searchQuery: string, isKeyboard?: boolean) => {
    if (!searchQuery) {
      editorSearchBiLogger.logSearchQueryCleared(isKeyboard);
    }

    this.setState({
      searchQuery,
    });
  };

  clearSearchQuery = (isKeyboard: boolean = false) => {
    this.updateSearchQuery('', isKeyboard);
  };

  doSearch(searchQuery: string) {
    const { authorizationToken } = this.props;

    if (this.currentSearch) {
      this.currentSearch.abort();
    }

    this.setState({
      isLoading: false,
      errorInfo: null,
      categories: [],
    });

    if (!searchQuery) {
      return;
    }

    this.currentSearch = searchModule.search({
      query: searchQuery,
      authorizationToken,
    });

    editorSearchBiLogger.updateSearchQuery(searchQuery);

    this.setState({
      isLoading: true,
    });

    this.currentSearch.promise
      .then((results) => {
        this.setState({
          isLoading: false,
          categories: results,
        });

        editorSearchBiLogger.logSearchResultReceived({
          success: true,
          count: _.flatMap(results, 'items').length,
          results: _.flatMap(
            results.map((category) =>
              category.items
                .slice(0, CLOSED_CATEGORY_MAX_ITEMS_COUNT)
                .map((item) => item.id),
            ),
          ),
        });
      })
      .catch(({ type, message, status }) => {
        if (type === ErrorType.ABORT) {
          return;
        }

        editorSearchBiLogger.logSearchResultReceived({
          success: false,
          errorMessage: message,
        });

        this.setState({
          isLoading: false,
          errorInfo: {
            type,
            message,
            status,
          },
        });
      });
  }

  handleChange = (searchQuery: string) => {
    this.updateSearchQuery(searchQuery, true);
  };

  handleClearClick = () => {
    this.clearSearchQuery();
  };

  handleEscape = () => {
    if (this.state.searchQuery) {
      this.clearSearchQuery(true);
      return;
    }

    this.props.closeSearch();
  };

  renderContent() {
    const { isLoading, searchQuery, errorInfo, categories } = this.state;

    if (isLoading) {
      return null;
    }

    if (errorInfo) {
      return <ErrorState error={errorInfo} />;
    }

    if (searchQuery && !categories.some((category) => category.items.length)) {
      return <EmptyState query={searchQuery} />;
    }

    return (
      <SearchResultList
        categories={categories}
        searchQuery={searchQuery}
        onActionSubmit={this.props.closeSearch}
      />
    );
  }

  render() {
    const { isOpen, openOrigin, t } = this.props;
    const { searchQuery, isLoading } = this.state;

    if (!isOpen) {
      return null;
    }

    editorSearchBiLogger.setOpenOrigin(openOrigin);

    return (
      <div>
        <div className={styles.overlay} onClick={this.props.closeSearch} />
        <div className={styles.container}>
          <Layout>
            <SearchInput
              value={searchQuery}
              onChange={this.handleChange}
              onClear={this.handleClearClick}
              placeholder={t(TRANSLATIONS.INPUT_PLACEHOLDER)}
              shouldFocus={isOpen}
              isLoading={isLoading}
            />
            <KeyboardControl onEscape={this.handleEscape}>
              {this.renderContent()}
            </KeyboardControl>
          </Layout>
        </div>
      </div>
    );
  }
}

export const EditorSearchPanel = _.flow(
  connect(EDITOR_API, mapStateToProps, mapDispatchToProps),
  withTranslation(),
)(EditorSearchPanelComponent);

EditorSearchPanel.pure = EditorSearchPanelComponent;
