import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import React from 'react';
import EmptyState from './emptyState';
import WithLoader from './preloader/withLoader';
import type { VideoTooltipProps } from './videoTooltip/videoTooltip';
import type { BiEventDefinition, BiEventFields } from 'types/bi';
import styles from './loadCollectionsPlaceholder.scss';

interface LoadCollectionsPlaceholderProps {
  isLoading: boolean;
  hasCollections: boolean;
  emptyStateTitle: string;
  emptyStateDescription: string;
  emptyStateIcon: string;
  dataHook?: string;
  buttonTitle: string;
  videoTooltipProps?: VideoTooltipProps;
  sendBi: (event: BiEventDefinition, params: BiEventFields) => void;
  onSettingsIconClick?: (actionName: string, isEmptyState: boolean) => void;
}

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass<LoadCollectionsPlaceholderProps>({
  displayName: 'LoadCollectionsPlaceholder',

  propTypes: {
    isLoading: PropTypes.bool.isRequired,
    hasCollections: PropTypes.bool.isRequired,
    emptyStateTitle: PropTypes.string.isRequired,
    emptyStateDescription: PropTypes.string.isRequired,
    emptyStateIcon: PropTypes.string.isRequired,
    dataHook: PropTypes.string,
    buttonTitle: PropTypes.string.isRequired,
    videoTooltipProps: PropTypes.object,
    sendBi: PropTypes.func.isRequired,
    onSettingsIconClick: PropTypes.func,
  },

  render() {
    return (
      <div
        key="load-collections-placeholder"
        className={styles.loadCollectionsPlaceholder}
        data-hook={this.props.dataHook}
      >
        <WithLoader
          isLoading={this.props.isLoading}
          title="SiteApp_WixData_DataManager_Tab_Loading_Text"
        >
          <div
            key="application-states-wrapper"
            className={styles.applicationStatesWrapper}
          >
            <div
              key="collectionsManager"
              className={styles.collectionsListWrapper}
            >
              {this.props.hasCollections ? (
                <div
                  key="load-collections-placeholder-children-container"
                  className={styles.loadCollectionsPlaceholderChildrenContainer}
                >
                  {this.props.children}
                </div>
              ) : null}

              {!this.props.hasCollections ? (
                <EmptyState
                  key="emptyState"
                  aid="empty-state"
                  title={this.props.emptyStateTitle}
                  description={this.props.emptyStateDescription}
                  icon={this.props.emptyStateIcon}
                  buttonTitle={this.props.buttonTitle}
                  videoTooltipProps={this.props.videoTooltipProps}
                  sendBi={this.props.sendBi}
                  onSettingsIconClick={this.props.onSettingsIconClick}
                />
              ) : null}
            </div>
          </div>
        </WithLoader>
      </div>
    );
  },
});
