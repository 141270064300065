import _ from 'lodash';
import { EditorAPIKey, BiApiKey } from '@/apis';
import * as util from '@/util';
import constants from '@/constants';
import * as stateManagement from '@/stateManagement';
import savePublishConstants from '../constants/constants';
import type { DocumentServiceError, DSAction } from 'types/documentServices';
import { SavePublishApiKey, SaveManagerApiKey } from '../publicApiKey';
import type { Shell } from '@/apilib';

type AutosaveConfig = Parameters<DSAction['initAutosave']>[0];

export function createAutosaveManagerApi(shell: Shell) {
  const editorAPI = shell.getAPI(EditorAPIKey);
  const savePublishApi = shell.getAPI(SavePublishApiKey);
  const saveManagerApi = shell.getAPI(SaveManagerApiKey);
  const topBarMenuBarApi = editorAPI.topBarMenuBar;
  const biApi = shell.getAPI(BiApiKey);

  function init(
    overrides?: AnyFixMe,
    skipTooltip?: boolean,
    skipSavingToPreferences?: boolean,
  ): false | unknown {
    const autosaveConfig = getAutosaveConfig(overrides);

    if (autosaveConfig.enabled && isAutomationWithoutAutosave()) {
      return false;
    }
    if (!skipSavingToPreferences) {
      setAutosaveInUserPreferences(autosaveConfig.enabled);
    }
    editorAPI.dsActions.initAutosave(autosaveConfig);

    return autosaveConfig;
  }

  function isAutomationWithoutAutosave(): boolean {
    const isQA = util.url.isQA();
    const isForceAutosave =
      util.url.getParameterByName('forceAutosave') === 'true';

    return isQA && !isForceAutosave;
  }

  function updateTestAPI(type: string, success: boolean): void {
    let counterName;

    if (type === 'autosave') {
      counterName = success ? 'autosaveSuccessCount' : 'autosaveFailCount';
    } else {
      counterName = success
        ? 'autoFullSaveSuccessCount'
        : 'autoFullSaveFailCount';
    }

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/is-nan
    if (_.isNaN(Number(window.testApi[counterName]))) {
      window.testApi[counterName] = 0;
    }

    window.testApi[counterName]++;
  }

  function onDiffSaveStarted(): void {
    savePublishApi.setManualSave(false);
  }

  function onDiffSaveFinished(error: DocumentServiceError): void {
    if (
      error &&
      !stateManagement.savePublish.selectors.getIsManualSave(
        editorAPI.store.getState(),
      )
    ) {
      saveManagerApi.handleSaveError(error);
    }

    editorAPI.store.dispatch(
      stateManagement.leavePopup.actions.setLeavePopup(!!error),
    );

    if (util.url.isQA() && typeof window !== 'undefined' && window.testApi) {
      updateTestAPI('autosave', !error);
    }

    notifyTPABI();
  }

  function onPartialSaveFinished(error: DocumentServiceError): void {
    if (
      error &&
      !stateManagement.savePublish.selectors.getIsManualSave(
        editorAPI.store.getState(),
      )
    ) {
      saveManagerApi.handleSaveError(error);
    }

    if (util.url.isQA() && typeof window !== 'undefined' && window.testApi) {
      updateTestAPI('autoFullSave', !error);
    }

    notifyTPABI();
  }

  function notifyTPABI(): void {
    biApi.tryFlushingConditionedEvents();
  }

  function getAutosaveConfig(overrides: unknown): AutosaveConfig {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    return _.assign(
      {},
      {
        enabled: isAutosaveEnabled(),
      },
      {
        onDiffSaveStarted,
        onDiffSaveFinished,
        onPartialSaveFinished,
      },
      savePublishConstants.AUTOSAVE,
      overrides,
    );
  }

  function autosaveStatusTooltip(isDisabled: boolean): void {
    const ds = editorAPI.dsRead;
    const autosaveInfo = ds.getAutoSaveInfo();
    const isAutosaveActive = autosaveInfo?.shouldAutoSave;
    if (!isAutosaveActive) {
      return;
    }

    const autosaveStatusKey = isDisabled
      ? constants.ROOT_COMPS.TOPBAR.DROP_PANELS.CUSTOM.DISABLE_AUTO_SAVE
      : constants.ROOT_COMPS.TOPBAR.DROP_PANELS.CUSTOM.ENABLE_AUTO_SAVE;
    topBarMenuBarApi.openDropDown(autosaveStatusKey);
    window.setTimeout(
      topBarMenuBarApi.closeDropDown.bind(editorAPI, autosaveStatusKey),
      5000,
    );
  }

  function isAutosaveEnabled(): boolean {
    const isEnabled =
      stateManagement.userPreferences.selectors.getSiteUserPreferences<boolean>(
        constants.USER_PREFS.SAVE.AUTOSAVE_ENABLED,
      )(editorAPI.store.getState());
    return isEnabled || isEnabled === undefined;
  }

  function setAutosaveInUserPreferences(isEnabled: boolean): void {
    editorAPI.store.dispatch(
      stateManagement.userPreferences.actions.setSiteUserPreferences(
        constants.USER_PREFS.SAVE.AUTOSAVE_ENABLED,
        isEnabled,
      ),
    );
  }

  return {
    init,
    getAutosaveConfig,
    isAutomationWithoutAutosave,
    autosaveStatusTooltip,
    isAutosaveEnabled,
  };
}
