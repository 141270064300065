import constants from '@/constants';

const { TOOLS_MENU_ITEMS } = constants.ROOT_COMPS.TOPBAR;

export interface ToolsMenuAPIOwnProps {}

export interface ToolsMenuAPIStateProps {}

export interface ToolsMenuAPIDispatchProps {
  toggleToolBar: () => void;
  toggleLayersPanel: () => void;
  toggleRulers: () => void;
  toggleGuidelines: (onSuccess?: () => void, onFail?: () => void) => void;
  toggleSnapTo: (onSuccess?: () => void, onFail?: () => void) => void;
  toggleDeveloperToolBar: () => void;
  toggleDeveloperHiddenComponents: () => void;
}

export interface ToolsMenuAPIProps
  extends ToolsMenuAPIOwnProps,
    ToolsMenuAPIStateProps,
    ToolsMenuAPIDispatchProps {}

export const getToolsMenuAPI = (props: ToolsMenuAPIProps) => ({
  [TOOLS_MENU_ITEMS.TOOLBAR]() {
    props.toggleToolBar();
  },
  [TOOLS_MENU_ITEMS.LAYERS]() {
    props.toggleLayersPanel();
  },
  [TOOLS_MENU_ITEMS.RULERS]() {
    props.toggleRulers();
  },
  [TOOLS_MENU_ITEMS.GUIDELINES]() {
    props.toggleGuidelines();
  },
  [TOOLS_MENU_ITEMS.SNAP]() {
    props.toggleSnapTo();
  },
  [TOOLS_MENU_ITEMS.PROPERTIES_TOOLBAR]() {
    props.toggleDeveloperToolBar();
  },
  [TOOLS_MENU_ITEMS.HIDDEN_ELEMENTS]() {
    props.toggleDeveloperHiddenComponents();
  },
});
