'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')
const i18n = require('@/i18n')

module.exports = {
    "type": addPanelDataConsts.SECTIONS_TYPES.PRESET,
    "hide": false,
    "title": "add_section_label_regularslideshows",
    "subNavigationTitle": "add_section_label_regularslideshows",
    "presetTitle": "add_section_label_regularslideshows",
    "tooltipTitle": "add_section_label_regularslideshows",
    "automationId": "add-panel-section-boxSlideShow980Section",
    "subNavigationHide": false,
    "showSectionHeader": true,
    "additionalBehaviours": {
        "labelMode": addPanelDataConsts.LABEL_BEHAVIOUR.HOVER,
        "infoIcon": addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
        "hoverImageAction": addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
        "iconEnabledForComps": {}
    },
    "props": {
        "image": "addPanelData/sections/boxSlideShow980Section_en/boxSlideShow980Section_en.png",
        "imageHover": null,
        "items": [{
            "id": "BoxSlideShow_BoxSlideShow980_1",
            "structure": {
                "type": "Container",
                "skin": "wysiwyg.common.components.boxSlideShow.viewer.skins.thinArrowsLargeSelectedCircleSkin",
                "layout": {
                    "width": 980,
                    "height": 440,
                    "x": 0,
                    "y": 80,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.BoxSlideShow",
                "components": [{
                    "type": "Container",
                    "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                    "layout": {
                        "width": 980,
                        "height": 440,
                        "x": 0,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.BoxSlideShowSlide",
                    "components": [{
                        "type": "Component",
                        "layout": {
                            "width": 349,
                            "height": 96,
                            "x": 307,
                            "y": 117,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<h2 style=\"text-align: center; font-size: 60px;\" class=\"font_2\"><span class=\"color_11\"><span style=\"font-size:60px;\"><span style=\"font-family:belinda-w00-regular,script;\">Fresh</span></span></span></h2>",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 206,
                                "height": 88,
                                "x": 37,
                                "y": 31,
                                "scale": 1.477455443789063,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 style=\"text-align: center; font-size: 60px;\" class=\"font_2\"><span class=\"color_11\"><span style=\"font-size:60px;\"><span style=\"font-family:belinda-w00-regular,script;\">Fresh</span></span></span></h2>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 612,
                            "height": 103,
                            "x": 184,
                            "y": 195,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<h2 style=\"text-align: center; font-size: 80px;\" class=\"font_2\"><span class=\"color_11\"><span style=\"font-size:80px;\"><span style=\"font-family:dinneuzeitgroteskltw01-_812426,sans-serif;\">MARKET</span></span></span></h2>",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 206,
                                "height": 84,
                                "x": 37,
                                "y": 106,
                                "scale": 1.477455443789063,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 style=\"text-align: center; font-size: 80px;\" class=\"font_2\"><span class=\"color_11\"><span style=\"font-size:80px;\"><span style=\"font-family:dinneuzeitgroteskltw01-_812426,sans-serif;\">MARKET</span></span></span></h2>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }],
                    "data": {
                        "type": "BoxSlideShowSlide",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "1"})
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-brd": "1",
                                "bg": "#001F2C",
                                "brd": "color_11",
                                "brw": "0",
                                "rd": "0px"
                            },
                            "propertiesSource": {"bg": "value"}
                        },
                        "componentClassName": "wysiwyg.viewer.components.BoxSlideShowSlide",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "mediaRef": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "title": "Vegetables",
                                "uri": "05fde66f5fad46b6ac59b8bfca60be8d.jpg",
                                "description": "public/491df47b-5958-4dc7-9029-37f428755d45/32fe43d3-68f4-48fc-8f2d-461e1ddced53",
                                "width": 3000,
                                "height": 2000,
                                "alt": "",
                                "artist": {"id": "", "name": ""},
                                "opacity": 0.88
                            },
                            "color": "#000000",
                            "colorOpacity": 1,
                            "alignType": "bottom",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "{color_15}",
                            "colorOverlayOpacity": 0.21
                        }
                    },
                    "mobileStructure": {
                        "type": "Container",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                        "layout": {
                            "width": 280,
                            "height": 377,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.BoxSlideShowSlide",
                        "data": {
                            "type": "BoxSlideShowSlide",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "1"})
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "alpha-brd": "1",
                                    "bg": "#001F2C",
                                    "brd": "color_11",
                                    "brw": "0",
                                    "rd": "0px"
                                },
                                "propertiesSource": {"bg": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.BoxSlideShowSlide",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "mediaRef": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "title": "Vegetables",
                                    "uri": "05fde66f5fad46b6ac59b8bfca60be8d.jpg",
                                    "description": "public/491df47b-5958-4dc7-9029-37f428755d45/32fe43d3-68f4-48fc-8f2d-461e1ddced53",
                                    "width": 3000,
                                    "height": 2000,
                                    "alt": "",
                                    "artist": {"id": "", "name": ""},
                                    "opacity": 0.88
                                },
                                "color": "#000000",
                                "colorOpacity": 1,
                                "alignType": "bottom",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "{color_15}",
                                "colorOverlayOpacity": 0.21
                            }
                        }
                    }
                }, {
                    "type": "Container",
                    "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                    "layout": {
                        "width": 980,
                        "height": 440,
                        "x": 0,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.BoxSlideShowSlide",
                    "components": [{
                        "type": "Container",
                        "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                        "layout": {
                            "width": 300,
                            "height": 304,
                            "x": 340,
                            "y": 70,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "mobile.core.components.Container",
                        "components": [{
                            "type": "Component",
                            "layout": {
                                "width": 234,
                                "height": 92,
                                "x": 33,
                                "y": 157,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p style=\"text-align: center; line-height: 1.4em;\" class=\"font_8\"><span style=\"line-height:1.4em;\"><span class=\"color_2\"><span style=\"font-family:futura-lt-w01-book,sans-serif;\">Describe what you offer here. add a few choice words and a stunning pic to tantalize your audience and leave them hungry for more.</span></span></span></p>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew",
                            "mobileStructure": {
                                "type": "Component",
                                "layout": {
                                    "width": 164,
                                    "height": 138,
                                    "x": 22,
                                    "y": 118,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "data": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<p style=\"text-align: center; line-height: 1.4em;\" class=\"font_8\"><span style=\"line-height:1.4em;\"><span class=\"color_2\"><span style=\"font-family:futura-lt-w01-book,sans-serif;\">Describe what you offer here. add a few choice words and a stunning pic to tantalize your audience and leave them hungry for more.</span></span></span></p>",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "props": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                },
                                "style": "txtNew"
                            }
                        }, {
                            "type": "Component",
                            "layout": {
                                "width": 275,
                                "height": 45,
                                "x": 12,
                                "y": 91,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 style=\"text-align: center; font-size: 35px;\" class=\"font_2\"><span style=\"letter-spacing:0.02em;\"><span class=\"color_2\"><span style=\"font-size:35px;\"><span style=\"font-family:dinneuzeitgroteskltw01-_812426,sans-serif;\">OF THE DAY</span></span></span></span></h2>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew",
                            "mobileStructure": {
                                "type": "Component",
                                "layout": {
                                    "width": 157,
                                    "height": 36,
                                    "x": 25,
                                    "y": 71,
                                    "scale": 0.95,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "data": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<h2 style=\"text-align: center; font-size: 35px;\" class=\"font_2\"><span style=\"letter-spacing:0.02em;\"><span class=\"color_2\"><span style=\"font-size:35px;\"><span style=\"font-family:dinneuzeitgroteskltw01-_812426,sans-serif;\">OF THE DAY</span></span></span></span></h2>",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "props": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                },
                                "style": "txtNew"
                            }
                        }, {
                            "type": "Component",
                            "layout": {
                                "width": 280,
                                "height": 56,
                                "x": 8,
                                "y": 38,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 style=\"text-align: center; font-size: 35px;\" class=\"font_2\"><span style=\"font-family: belinda-w00-regular, script;\"><span class=\"color_2\">Deal</span></span></h2>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew",
                            "mobileStructure": {
                                "type": "Component",
                                "layout": {
                                    "width": 66,
                                    "height": 47,
                                    "x": 71,
                                    "y": 26,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "data": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<h2 style=\"text-align: center; font-size: 35px;\" class=\"font_2\"><span style=\"font-family: belinda-w00-regular, script;\"><span class=\"color_2\">Deal</span></span></h2>",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "props": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                },
                                "style": "txtNew"
                            }
                        }],
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "alpha-bg": "0.9",
                                    "alpha-brd": "1",
                                    "bg": "#FFFFFF",
                                    "boxShadowToggleOn-shd": "false",
                                    "brd": "#2F2E2E",
                                    "brw": "0",
                                    "rd": "0px",
                                    "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                                },
                                "propertiesSource": {
                                    "alpha-bg": "value",
                                    "bg": "value",
                                    "brd": "value",
                                    "brw": "value",
                                    "rd": "value",
                                    "shd": "value"
                                }
                            },
                            "componentClassName": "mobile.core.components.Container",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                        },
                        "mobileStructure": {
                            "type": "Container",
                            "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                            "layout": {
                                "width": 207,
                                "height": 286,
                                "x": 36,
                                "y": 35,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "mobile.core.components.Container",
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {
                                        "alpha-bg": "0.9",
                                        "alpha-brd": "1",
                                        "bg": "#FFFFFF",
                                        "boxShadowToggleOn-shd": "false",
                                        "brd": "#2F2E2E",
                                        "brw": "0",
                                        "rd": "0px",
                                        "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                                    },
                                    "propertiesSource": {
                                        "alpha-bg": "value",
                                        "bg": "value",
                                        "brd": "value",
                                        "brw": "value",
                                        "rd": "value",
                                        "shd": "value"
                                    }
                                },
                                "componentClassName": "mobile.core.components.Container",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                            }
                        }
                    }],
                    "data": {
                        "type": "BoxSlideShowSlide",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "2"})
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-brd": "1",
                                "bg": "#001F2C",
                                "brd": "color_11",
                                "brw": "0",
                                "rd": "0px"
                            },
                            "propertiesSource": {"bg": "value"}
                        },
                        "componentClassName": "wysiwyg.viewer.components.BoxSlideShowSlide",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "mediaRef": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "title": "Salad",
                                "uri": "c6e39395340740f8ba3c1cd77f051c9e.jpg",
                                "description": "public/491df47b-5958-4dc7-9029-37f428755d45/32fe43d3-68f4-48fc-8f2d-461e1ddced53",
                                "width": 3693,
                                "height": 3444,
                                "alt": "",
                                "artist": {"id": "", "name": ""},
                                "opacity": 0.9
                            },
                            "color": "#000000",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "mobileStructure": {
                        "type": "Container",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                        "layout": {
                            "width": 280,
                            "height": 377,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.BoxSlideShowSlide",
                        "data": {
                            "type": "BoxSlideShowSlide",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "2"})
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "alpha-brd": "1",
                                    "bg": "#001F2C",
                                    "brd": "color_11",
                                    "brw": "0",
                                    "rd": "0px"
                                },
                                "propertiesSource": {"bg": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.BoxSlideShowSlide",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "mediaRef": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "title": "Salad",
                                    "uri": "c6e39395340740f8ba3c1cd77f051c9e.jpg",
                                    "description": "public/491df47b-5958-4dc7-9029-37f428755d45/32fe43d3-68f4-48fc-8f2d-461e1ddced53",
                                    "width": 3693,
                                    "height": 3444,
                                    "alt": "",
                                    "artist": {"id": "", "name": ""},
                                    "opacity": 0.9
                                },
                                "color": "#000000",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            }
                        }
                    }
                }, {
                    "type": "Container",
                    "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                    "layout": {
                        "width": 980,
                        "height": 440,
                        "x": 0,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.BoxSlideShowSlide",
                    "components": [{
                        "type": "Container",
                        "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                        "layout": {
                            "width": 300,
                            "height": 300,
                            "x": 340,
                            "y": 70,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "mobile.core.components.Container",
                        "components": [{
                            "type": "Component",
                            "layout": {
                                "width": 280,
                                "height": 56,
                                "x": 8,
                                "y": 38,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 style=\"text-align: center; font-size: 35px;\" class=\"font_2\"><span class=\"color_2\"><span style=\"font-size:35px;\"><span style=\"font-family:belinda-w00-regular,script;\">Our</span></span></span></h2>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew",
                            "mobileStructure": {
                                "type": "Component",
                                "layout": {
                                    "width": 132,
                                    "height": 47,
                                    "x": 36,
                                    "y": 16,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "data": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<h2 style=\"text-align: center; font-size: 35px;\" class=\"font_2\"><span class=\"color_2\"><span style=\"font-size:35px;\"><span style=\"font-family:belinda-w00-regular,script;\">Our</span></span></span></h2>",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "props": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                },
                                "style": "txtNew"
                            }
                        }, {
                            "type": "Component",
                            "layout": {
                                "width": 275,
                                "height": 45,
                                "x": 12,
                                "y": 90,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 style=\"text-align: center; font-size: 35px;\" class=\"font_2\"><span style=\"letter-spacing:0.02em;\"><span class=\"color_2\"><span style=\"font-size:35px;\"><span style=\"font-family:dinneuzeitgroteskltw01-_812426,sans-serif;\">LATEST SPECIALS</span></span></span></span></h2>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew",
                            "mobileStructure": {
                                "type": "Component",
                                "layout": {
                                    "width": 195,
                                    "height": 34,
                                    "x": 5,
                                    "y": 61,
                                    "scale": 0.947625,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "data": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<h2 style=\"text-align: center; font-size: 35px;\" class=\"font_2\"><span style=\"letter-spacing:0.02em;\"><span class=\"color_2\"><span style=\"font-size:35px;\"><span style=\"font-family:dinneuzeitgroteskltw01-_812426,sans-serif;\">LATEST SPECIALS</span></span></span></span></h2>",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "props": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                },
                                "style": "txtNew"
                            }
                        }, {
                            "type": "Component",
                            "layout": {
                                "width": 234,
                                "height": 92,
                                "x": 33,
                                "y": 157,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p style=\"text-align: center; line-height: 1.4em;\" class=\"font_8\"><span style=\"line-height:1.4em;\"><span class=\"color_2\"><span style=\"font-family:futura-lt-w01-book,sans-serif;\">Describe what you offer here. add a few choice words and a stunning pic to tantalize your audience and leave them hungry for more.</span></span></span></p>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew",
                            "mobileStructure": {
                                "type": "Component",
                                "layout": {
                                    "width": 169,
                                    "height": 138,
                                    "x": 17,
                                    "y": 107,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "data": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<p style=\"text-align: center; line-height: 1.4em;\" class=\"font_8\"><span style=\"line-height:1.4em;\"><span class=\"color_2\"><span style=\"font-family:futura-lt-w01-book,sans-serif;\">Describe what you offer here. add a few choice words and a stunning pic to tantalize your audience and leave them hungry for more.</span></span></span></p>",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "props": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                },
                                "style": "txtNew"
                            }
                        }],
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "alpha-bg": "0.9",
                                    "alpha-brd": "1",
                                    "bg": "#FFFFFF",
                                    "boxShadowToggleOn-shd": "false",
                                    "brd": "#2F2E2E",
                                    "brw": "0",
                                    "rd": "0px",
                                    "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                                },
                                "propertiesSource": {
                                    "alpha-bg": "value",
                                    "bg": "value",
                                    "brd": "value",
                                    "brw": "value",
                                    "rd": "value",
                                    "shd": "value"
                                }
                            },
                            "componentClassName": "mobile.core.components.Container",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                        },
                        "mobileStructure": {
                            "type": "Container",
                            "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                            "layout": {
                                "width": 206,
                                "height": 274,
                                "x": 37,
                                "y": 46,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "mobile.core.components.Container",
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {
                                        "alpha-bg": "0.9",
                                        "alpha-brd": "1",
                                        "bg": "#FFFFFF",
                                        "boxShadowToggleOn-shd": "false",
                                        "brd": "#2F2E2E",
                                        "brw": "0",
                                        "rd": "0px",
                                        "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                                    },
                                    "propertiesSource": {
                                        "alpha-bg": "value",
                                        "bg": "value",
                                        "brd": "value",
                                        "brw": "value",
                                        "rd": "value",
                                        "shd": "value"
                                    }
                                },
                                "componentClassName": "mobile.core.components.Container",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                            }
                        }
                    }],
                    "data": {
                        "type": "BoxSlideShowSlide",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "3"})
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-brd": "1",
                                "bg": "#001F2C",
                                "brd": "color_11",
                                "brw": "0",
                                "rd": "0px"
                            },
                            "propertiesSource": {"bg": "value"}
                        },
                        "componentClassName": "wysiwyg.viewer.components.BoxSlideShowSlide",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "mediaRef": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "title": "Burritos",
                                "uri": "cd5feba0d48b46a1814b99a3e8700724.jpg",
                                "description": "public/491df47b-5958-4dc7-9029-37f428755d45/32fe43d3-68f4-48fc-8f2d-461e1ddced53",
                                "width": 6797,
                                "height": 3897,
                                "alt": "",
                                "artist": {"id": "", "name": ""},
                                "opacity": 0.9
                            },
                            "color": "#000000",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "mobileStructure": {
                        "type": "Container",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                        "layout": {
                            "width": 280,
                            "height": 377,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.BoxSlideShowSlide",
                        "data": {
                            "type": "BoxSlideShowSlide",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "3"})
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "alpha-brd": "1",
                                    "bg": "#001F2C",
                                    "brd": "color_11",
                                    "brw": "0",
                                    "rd": "0px"
                                },
                                "propertiesSource": {"bg": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.BoxSlideShowSlide",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "mediaRef": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "title": "Burritos",
                                    "uri": "cd5feba0d48b46a1814b99a3e8700724.jpg",
                                    "description": "public/491df47b-5958-4dc7-9029-37f428755d45/32fe43d3-68f4-48fc-8f2d-461e1ddced53",
                                    "width": 6797,
                                    "height": 3897,
                                    "alt": "",
                                    "artist": {"id": "", "name": ""},
                                    "opacity": 0.9
                                },
                                "color": "#000000",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            }
                        }
                    }
                }],
                "props": {
                    "type": "BoxSlideShowProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "transition": "SlideHorizontal",
                    "autoPlayInterval": 3,
                    "autoPlay": true,
                    "transDuration": 1,
                    "pauseAutoPlayOnMouseOver": true,
                    "direction": "RTL",
                    "shouldHideOverflowContent": true,
                    "flexibleBoxHeight": false,
                    "showNavigationButton": true,
                    "showNavigationDots": true,
                    "navigationButtonSize": 18,
                    "navigationButtonMargin": 28,
                    "navigationDotsSize": 7,
                    "navigationDotsMargin": 32,
                    "navigationDotsGap": 16,
                    "navigationDotsAlignment": "center"
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-arrowColor": "1",
                            "alpha-brd": "1",
                            "alpha-dotsColor": "1",
                            "boxShadowToggleOn-shd": "true",
                            "brd": "#FFFFFF",
                            "brw": "0",
                            "dotsColor": "#FFFFFF",
                            "shd": "0 0 0 rgba(0, 0, 0, 0.6)"
                        },
                        "propertiesSource": {
                            "alpha-arrowColor": "value",
                            "alpha-brd": "value",
                            "alpha-dotsColor": "value",
                            "arrowColor": "value",
                            "boxShadowToggleOn-shd": "value",
                            "brd": "value",
                            "brw": "value",
                            "dotsColor": "value",
                            "shd": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.BoxSlideShow",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.common.components.boxSlideShow.viewer.skins.thinArrowsLargeSelectedCircleSkin"
                },
                "mobileStructure": {
                    "type": "Container",
                    "skin": "wysiwyg.common.components.boxSlideShow.viewer.skins.thinArrowsLargeSelectedCircleSkin",
                    "layout": {
                        "width": 280,
                        "height": 377,
                        "x": 0,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.BoxSlideShow",
                    "props": {
                        "type": "BoxSlideShowProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "transition": "SlideHorizontal",
                        "autoPlayInterval": 3,
                        "autoPlay": true,
                        "transDuration": 1,
                        "pauseAutoPlayOnMouseOver": true,
                        "direction": "RTL",
                        "shouldHideOverflowContent": true,
                        "flexibleBoxHeight": false,
                        "showNavigationButton": true,
                        "showNavigationDots": true,
                        "navigationButtonSize": 15,
                        "navigationButtonMargin": 18,
                        "navigationDotsSize": 6,
                        "navigationDotsMargin": 24,
                        "navigationDotsGap": 15,
                        "navigationDotsAlignment": "center"
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-arrowColor": "1",
                                "alpha-brd": "1",
                                "alpha-dotsColor": "1",
                                "boxShadowToggleOn-shd": "true",
                                "brd": "#FFFFFF",
                                "brw": "0",
                                "dotsColor": "#FFFFFF",
                                "shd": "0 0 0 rgba(0, 0, 0, 0.6)"
                            },
                            "propertiesSource": {
                                "alpha-arrowColor": "value",
                                "alpha-brd": "value",
                                "alpha-dotsColor": "value",
                                "arrowColor": "value",
                                "boxShadowToggleOn-shd": "value",
                                "brd": "value",
                                "brw": "value",
                                "dotsColor": "value",
                                "shd": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.viewer.components.BoxSlideShow",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.boxSlideShow.viewer.skins.thinArrowsLargeSelectedCircleSkin"
                    }
                },
                "id": "comp-im0kb76b"
            },
            "preset": {
                "rect": {"width": 324, "height": 150.75, "x": 0, "y": 0},
                "label": "add_preset_BoxSlideShow_980_1",
                "tags": null
            }
        }, {
            "id": "BoxSlideShow_BoxSlideShow980_2",
            "structure": {
                "type": "Container",
                "skin": "wysiwyg.common.components.boxSlideShow.viewer.skins.thinArrowsLargeSelectedCircleSkin",
                "layout": {
                    "width": 980,
                    "height": 440,
                    "x": 0,
                    "y": 681,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.BoxSlideShow",
                "components": [{
                    "type": "Container",
                    "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                    "layout": {
                        "width": 980,
                        "height": 440,
                        "x": 0,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.BoxSlideShowSlide",
                    "components": [{
                        "type": "Container",
                        "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                        "layout": {
                            "width": 344,
                            "height": 440,
                            "x": 636,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "mobile.core.components.Container",
                        "components": [{
                            "type": "Component",
                            "layout": {
                                "width": 306,
                                "height": 40,
                                "x": 12,
                                "y": 77,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 style=\"text-align: center; font-size: 28px;\" class=\"font_2\"><span style=\"font-size:28px;\"><span style=\"letter-spacing:0.1em;\"><span style=\"font-family: 'playfair display', serif;\"><span class=\"color_2\">WEDDINGS</span></span></span></span></h2>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew",
                            "mobileStructure": {
                                "type": "Component",
                                "layout": {
                                    "width": 186,
                                    "height": 37,
                                    "x": 13,
                                    "y": 24,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "data": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<h2 style=\"text-align: center; font-size: 28px;\" class=\"font_2\"><span style=\"font-size:28px;\"><span style=\"letter-spacing:0.1em;\"><span style=\"font-family: 'playfair display', serif;\"><span class=\"color_2\">WEDDINGS</span></span></span></span></h2>",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "props": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                },
                                "style": "txtNew"
                            }
                        }, {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.line.SolidLine",
                            "layout": {
                                "width": 25,
                                "height": 5,
                                "x": 152,
                                "y": 136,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.FiveGridLine",
                            "props": {
                                "type": "FiveGridLineProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "fullScreenModeOn": false
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {"alpha-brd": "1", "brd": "#2F2E2E", "lnw": "2"},
                                    "propertiesSource": {"brd": "value", "lnw": "value"}
                                },
                                "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.line.SolidLine"
                            },
                            "mobileStructure": {
                                "type": "Component",
                                "skin": "wysiwyg.viewer.skins.line.SolidLine",
                                "layout": {
                                    "width": 25,
                                    "height": 5,
                                    "x": 94,
                                    "y": 76,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.FiveGridLine",
                                "props": {
                                    "type": "FiveGridLineProperties",
                                    "metaData": {"schemaVersion": "1.0"},
                                    "fullScreenModeOn": false
                                },
                                "style": {
                                    "type": "TopLevelStyle",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "style": {
                                        "groups": {},
                                        "properties": {"alpha-brd": "1", "brd": "#2F2E2E", "lnw": "2"},
                                        "propertiesSource": {"brd": "value", "lnw": "value"}
                                    },
                                    "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                                    "pageId": "",
                                    "compId": "",
                                    "styleType": "custom",
                                    "skin": "wysiwyg.viewer.skins.line.SolidLine"
                                }
                            }
                        }, {
                            "type": "Component",
                            "layout": {
                                "width": 174,
                                "height": 132,
                                "x": 78,
                                "y": 162,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p style=\"text-align: center; line-height: 1.4em; font-size: 16px;\" class=\"font_8\"><span style=\"font-size:16px;\"><span style=\"line-height:1.4em;\"><span style=\"font-family:josefin slab,serif;\"><span class=\"color_2\">Describe what you offer here. add a few choice words and a stunning pic to tantalize your audience and leave them hungry for more.</span></span></span></span></p>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew",
                            "mobileStructure": {
                                "type": "Component",
                                "layout": {
                                    "width": 165,
                                    "height": 132,
                                    "x": 24,
                                    "y": 96,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "data": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<p style=\"text-align: center; line-height: 1.4em; font-size: 16px;\" class=\"font_8\"><span style=\"font-size:16px;\"><span style=\"line-height:1.4em;\"><span style=\"font-family:josefin slab,serif;\"><span class=\"color_2\">Describe what you offer here. add a few choice words and a stunning pic to tantalize your audience and leave them hungry for more.</span></span></span></span></p>",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "props": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                },
                                "style": "txtNew"
                            }
                        }, {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.button.BasicButton",
                            "layout": {
                                "width": 128,
                                "height": 35,
                                "x": 101,
                                "y": 328,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.SiteButton",
                            "data": {
                                "type": "LinkableButton",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "label": "Get In Touch"
                            },
                            "props": {
                                "type": "ButtonProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "align": "center",
                                "margin": 0
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {
                                        "alpha-bg": "0",
                                        "alpha-bgh": "1",
                                        "alpha-brd": "1",
                                        "alpha-brdh": "1",
                                        "bg": "#FFFFFF",
                                        "bgh": "#000000",
                                        "boxShadowToggleOn-shd": "false",
                                        "brd": "#000000",
                                        "brdh": "#2F2E2E",
                                        "brw": "1",
                                        "fnt": "normal normal normal 16px/1.4em josefin+slab",
                                        "rd": "0",
                                        "shd": "0 1px 4px rgba(0, 0, 0, 0.6)",
                                        "txt": "#000000",
                                        "txth": "#FFFFFF"
                                    },
                                    "propertiesSource": {
                                        "alpha-bg": "value",
                                        "alpha-bgh": "value",
                                        "alpha-brd": "value",
                                        "alpha-brdh": "value",
                                        "bg": "value",
                                        "bgh": "value",
                                        "boxShadowToggleOn-shd": "value",
                                        "brd": "value",
                                        "brdh": "value",
                                        "brw": "value",
                                        "fnt": "value",
                                        "rd": "value",
                                        "shd": "value",
                                        "txt": "value",
                                        "txth": "value"
                                    }
                                },
                                "componentClassName": "wysiwyg.viewer.components.SiteButton",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.button.BasicButton"
                            },
                            "mobileStructure": {
                                "type": "Component",
                                "skin": "wysiwyg.viewer.skins.button.BasicButton",
                                "layout": {
                                    "width": 128,
                                    "height": 35,
                                    "x": 43,
                                    "y": 251,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.SiteButton",
                                "data": {
                                    "type": "LinkableButton",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "label": "Get In Touch"
                                },
                                "props": {
                                    "type": "ButtonProperties",
                                    "metaData": {"schemaVersion": "1.0"},
                                    "align": "center",
                                    "margin": 0
                                },
                                "style": {
                                    "type": "TopLevelStyle",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "style": {
                                        "groups": {},
                                        "properties": {
                                            "alpha-bg": "0",
                                            "alpha-bgh": "1",
                                            "alpha-brd": "1",
                                            "alpha-brdh": "1",
                                            "bg": "#FFFFFF",
                                            "bgh": "#000000",
                                            "boxShadowToggleOn-shd": "false",
                                            "brd": "#000000",
                                            "brdh": "#2F2E2E",
                                            "brw": "1",
                                            "fnt": "normal normal normal 16px/1.4em josefin+slab",
                                            "rd": "0",
                                            "shd": "0 1px 4px rgba(0, 0, 0, 0.6)",
                                            "txt": "#000000",
                                            "txth": "#FFFFFF"
                                        },
                                        "propertiesSource": {
                                            "alpha-bg": "value",
                                            "alpha-bgh": "value",
                                            "alpha-brd": "value",
                                            "alpha-brdh": "value",
                                            "bg": "value",
                                            "bgh": "value",
                                            "boxShadowToggleOn-shd": "value",
                                            "brd": "value",
                                            "brdh": "value",
                                            "brw": "value",
                                            "fnt": "value",
                                            "rd": "value",
                                            "shd": "value",
                                            "txt": "value",
                                            "txth": "value"
                                        }
                                    },
                                    "componentClassName": "wysiwyg.viewer.components.SiteButton",
                                    "pageId": "",
                                    "compId": "",
                                    "styleType": "custom",
                                    "skin": "wysiwyg.viewer.skins.button.BasicButton"
                                }
                            }
                        }],
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "alpha-bg": "0.8",
                                    "alpha-brd": "1",
                                    "bg": "#FFFFFF",
                                    "boxShadowToggleOn-shd": "false",
                                    "brd": "#2F2E2E",
                                    "brw": "0",
                                    "rd": "0px",
                                    "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                                },
                                "propertiesSource": {
                                    "alpha-bg": "value",
                                    "bg": "value",
                                    "brd": "value",
                                    "brw": "value",
                                    "rd": "value",
                                    "shd": "value"
                                }
                            },
                            "componentClassName": "mobile.core.components.Container",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                        },
                        "mobileStructure": {
                            "type": "Container",
                            "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                            "layout": {
                                "width": 214,
                                "height": 319,
                                "x": 33,
                                "y": 32,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "mobile.core.components.Container",
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {
                                        "alpha-bg": "0.8",
                                        "alpha-brd": "1",
                                        "bg": "#FFFFFF",
                                        "boxShadowToggleOn-shd": "false",
                                        "brd": "#2F2E2E",
                                        "brw": "0",
                                        "rd": "0px",
                                        "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                                    },
                                    "propertiesSource": {
                                        "alpha-bg": "value",
                                        "bg": "value",
                                        "brd": "value",
                                        "brw": "value",
                                        "rd": "value",
                                        "shd": "value"
                                    }
                                },
                                "componentClassName": "mobile.core.components.Container",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                            }
                        }
                    }],
                    "data": {
                        "type": "BoxSlideShowSlide",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "1"})
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-brd": "1",
                                "bg": "#001F2C",
                                "brd": "color_11",
                                "brw": "0",
                                "rd": "0px"
                            },
                            "propertiesSource": {"bg": "value"}
                        },
                        "componentClassName": "wysiwyg.viewer.components.BoxSlideShowSlide",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "mediaRef": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "title": "Canapes",
                                "uri": "5dd3bd79153f4a169dc88741631afbe8.jpg",
                                "description": "public/491df47b-5958-4dc7-9029-37f428755d45/32fe43d3-68f4-48fc-8f2d-461e1ddced53",
                                "width": 4200,
                                "height": 2800,
                                "alt": "",
                                "artist": {"id": "", "name": ""},
                                "opacity": 0.9
                            },
                            "color": "#FFFFFF",
                            "colorOpacity": 1,
                            "alignType": "bottom",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "{color_15}",
                            "colorOverlayOpacity": 0.21
                        }
                    },
                    "mobileStructure": {
                        "type": "Container",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                        "layout": {
                            "width": 280,
                            "height": 402,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.BoxSlideShowSlide",
                        "data": {
                            "type": "BoxSlideShowSlide",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "1"})
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "alpha-brd": "1",
                                    "bg": "#001F2C",
                                    "brd": "color_11",
                                    "brw": "0",
                                    "rd": "0px"
                                },
                                "propertiesSource": {"bg": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.BoxSlideShowSlide",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "mediaRef": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "title": "Canapes",
                                    "uri": "5dd3bd79153f4a169dc88741631afbe8.jpg",
                                    "description": "public/491df47b-5958-4dc7-9029-37f428755d45/32fe43d3-68f4-48fc-8f2d-461e1ddced53",
                                    "width": 4200,
                                    "height": 2800,
                                    "alt": "",
                                    "artist": {"id": "", "name": ""},
                                    "opacity": 0.9
                                },
                                "color": "#FFFFFF",
                                "colorOpacity": 1,
                                "alignType": "bottom",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "{color_15}",
                                "colorOverlayOpacity": 0.21
                            }
                        }
                    }
                }, {
                    "type": "Container",
                    "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                    "layout": {
                        "width": 980,
                        "height": 440,
                        "x": 0,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.BoxSlideShowSlide",
                    "components": [{
                        "type": "Container",
                        "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                        "layout": {
                            "width": 344,
                            "height": 440,
                            "x": 636,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "mobile.core.components.Container",
                        "components": [{
                            "type": "Component",
                            "layout": {
                                "width": 306,
                                "height": 40,
                                "x": 12,
                                "y": 77,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 style=\"text-align: center; font-size: 28px;\" class=\"font_2\"><span style=\"font-size:28px;\"><span style=\"letter-spacing:0.1em;\"><span style=\"font-family: 'playfair display', serif;\"><span class=\"color_2\">PARTIES</span></span></span></span></h2>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew",
                            "mobileStructure": {
                                "type": "Component",
                                "layout": {
                                    "width": 143,
                                    "height": 37,
                                    "x": 36,
                                    "y": 24,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "data": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<h2 style=\"text-align: center; font-size: 28px;\" class=\"font_2\"><span style=\"font-size:28px;\"><span style=\"letter-spacing:0.1em;\"><span style=\"font-family: 'playfair display', serif;\"><span class=\"color_2\">PARTIES</span></span></span></span></h2>",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "props": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                },
                                "style": "txtNew"
                            }
                        }, {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.line.SolidLine",
                            "layout": {
                                "width": 25,
                                "height": 5,
                                "x": 153,
                                "y": 136,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.FiveGridLine",
                            "props": {
                                "type": "FiveGridLineProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "fullScreenModeOn": false
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {"alpha-brd": "1", "brd": "#2F2E2E", "lnw": "2"},
                                    "propertiesSource": {"brd": "value", "lnw": "value"}
                                },
                                "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.line.SolidLine"
                            },
                            "mobileStructure": {
                                "type": "Component",
                                "skin": "wysiwyg.viewer.skins.line.SolidLine",
                                "layout": {
                                    "width": 25,
                                    "height": 5,
                                    "x": 95,
                                    "y": 76,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.FiveGridLine",
                                "props": {
                                    "type": "FiveGridLineProperties",
                                    "metaData": {"schemaVersion": "1.0"},
                                    "fullScreenModeOn": false
                                },
                                "style": {
                                    "type": "TopLevelStyle",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "style": {
                                        "groups": {},
                                        "properties": {"alpha-brd": "1", "brd": "#2F2E2E", "lnw": "2"},
                                        "propertiesSource": {"brd": "value", "lnw": "value"}
                                    },
                                    "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                                    "pageId": "",
                                    "compId": "",
                                    "styleType": "custom",
                                    "skin": "wysiwyg.viewer.skins.line.SolidLine"
                                }
                            }
                        }, {
                            "type": "Component",
                            "layout": {
                                "width": 174,
                                "height": 132,
                                "x": 78,
                                "y": 162,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p style=\"text-align: center; line-height: 1.4em; font-size: 16px;\" class=\"font_8\"><span style=\"font-size:16px;\"><span style=\"line-height:1.4em;\"><span style=\"font-family:josefin slab,serif;\"><span class=\"color_2\">Describe what you offer here. add a few choice words and a stunning pic to tantalize your audience and leave them hungry for more.</span></span></span></span></p>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew",
                            "mobileStructure": {
                                "type": "Component",
                                "layout": {
                                    "width": 168,
                                    "height": 132,
                                    "x": 23,
                                    "y": 96,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "data": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<p style=\"text-align: center; line-height: 1.4em; font-size: 16px;\" class=\"font_8\"><span style=\"font-size:16px;\"><span style=\"line-height:1.4em;\"><span style=\"font-family:josefin slab,serif;\"><span class=\"color_2\">Describe what you offer here. add a few choice words and a stunning pic to tantalize your audience and leave them hungry for more.</span></span></span></span></p>",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "props": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                },
                                "style": "txtNew"
                            }
                        }, {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.button.BasicButton",
                            "layout": {
                                "width": 128,
                                "height": 35,
                                "x": 101,
                                "y": 328,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.SiteButton",
                            "data": {
                                "type": "LinkableButton",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "label": "Click Me"
                            },
                            "props": {
                                "type": "ButtonProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "align": "center",
                                "margin": 0
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {
                                        "alpha-bg": "0",
                                        "alpha-bgh": "1",
                                        "alpha-brd": "1",
                                        "alpha-brdh": "1",
                                        "bg": "#FFFFFF",
                                        "bgh": "#000000",
                                        "boxShadowToggleOn-shd": "false",
                                        "brd": "#000000",
                                        "brdh": "#2F2E2E",
                                        "brw": "1",
                                        "fnt": "normal normal normal 16px/1.4em josefin+slab",
                                        "rd": "0",
                                        "shd": "0 1px 4px rgba(0, 0, 0, 0.6)",
                                        "txt": "#000000",
                                        "txth": "#FFFFFF"
                                    },
                                    "propertiesSource": {
                                        "alpha-bg": "value",
                                        "alpha-bgh": "value",
                                        "alpha-brd": "value",
                                        "alpha-brdh": "value",
                                        "bg": "value",
                                        "bgh": "value",
                                        "boxShadowToggleOn-shd": "value",
                                        "brd": "value",
                                        "brdh": "value",
                                        "brw": "value",
                                        "fnt": "value",
                                        "rd": "value",
                                        "shd": "value",
                                        "txt": "value",
                                        "txth": "value"
                                    }
                                },
                                "componentClassName": "wysiwyg.viewer.components.SiteButton",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.button.BasicButton"
                            },
                            "mobileStructure": {
                                "type": "Component",
                                "skin": "wysiwyg.viewer.skins.button.BasicButton",
                                "layout": {
                                    "width": 128,
                                    "height": 35,
                                    "x": 43,
                                    "y": 251,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.SiteButton",
                                "data": {
                                    "type": "LinkableButton",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "label": "Click Me"
                                },
                                "props": {
                                    "type": "ButtonProperties",
                                    "metaData": {"schemaVersion": "1.0"},
                                    "align": "center",
                                    "margin": 0
                                },
                                "style": {
                                    "type": "TopLevelStyle",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "style": {
                                        "groups": {},
                                        "properties": {
                                            "alpha-bg": "0",
                                            "alpha-bgh": "1",
                                            "alpha-brd": "1",
                                            "alpha-brdh": "1",
                                            "bg": "#FFFFFF",
                                            "bgh": "#000000",
                                            "boxShadowToggleOn-shd": "false",
                                            "brd": "#000000",
                                            "brdh": "#2F2E2E",
                                            "brw": "1",
                                            "fnt": "normal normal normal 16px/1.4em josefin+slab",
                                            "rd": "0",
                                            "shd": "0 1px 4px rgba(0, 0, 0, 0.6)",
                                            "txt": "#000000",
                                            "txth": "#FFFFFF"
                                        },
                                        "propertiesSource": {
                                            "alpha-bg": "value",
                                            "alpha-bgh": "value",
                                            "alpha-brd": "value",
                                            "alpha-brdh": "value",
                                            "bg": "value",
                                            "bgh": "value",
                                            "boxShadowToggleOn-shd": "value",
                                            "brd": "value",
                                            "brdh": "value",
                                            "brw": "value",
                                            "fnt": "value",
                                            "rd": "value",
                                            "shd": "value",
                                            "txt": "value",
                                            "txth": "value"
                                        }
                                    },
                                    "componentClassName": "wysiwyg.viewer.components.SiteButton",
                                    "pageId": "",
                                    "compId": "",
                                    "styleType": "custom",
                                    "skin": "wysiwyg.viewer.skins.button.BasicButton"
                                }
                            }
                        }],
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "alpha-bg": "0.8",
                                    "alpha-brd": "1",
                                    "bg": "#FFFFFF",
                                    "boxShadowToggleOn-shd": "false",
                                    "brd": "#2F2E2E",
                                    "brw": "0",
                                    "rd": "0px",
                                    "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                                },
                                "propertiesSource": {
                                    "alpha-bg": "value",
                                    "bg": "value",
                                    "brd": "value",
                                    "brw": "value",
                                    "rd": "value",
                                    "shd": "value"
                                }
                            },
                            "componentClassName": "mobile.core.components.Container",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                        },
                        "mobileStructure": {
                            "type": "Container",
                            "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                            "layout": {
                                "width": 215,
                                "height": 318,
                                "x": 32,
                                "y": 32,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "mobile.core.components.Container",
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {
                                        "alpha-bg": "0.8",
                                        "alpha-brd": "1",
                                        "bg": "#FFFFFF",
                                        "boxShadowToggleOn-shd": "false",
                                        "brd": "#2F2E2E",
                                        "brw": "0",
                                        "rd": "0px",
                                        "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                                    },
                                    "propertiesSource": {
                                        "alpha-bg": "value",
                                        "bg": "value",
                                        "brd": "value",
                                        "brw": "value",
                                        "rd": "value",
                                        "shd": "value"
                                    }
                                },
                                "componentClassName": "mobile.core.components.Container",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                            }
                        }
                    }],
                    "data": {
                        "type": "BoxSlideShowSlide",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "2"})
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-brd": "1",
                                "bg": "#001F2C",
                                "brd": "color_11",
                                "brw": "0",
                                "rd": "0px"
                            },
                            "propertiesSource": {"bg": "value"}
                        },
                        "componentClassName": "wysiwyg.viewer.components.BoxSlideShowSlide",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "mediaRef": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "title": "Fresh Salmon",
                                "uri": "5238def5c2624723ab217bfb08a3ef78.jpg",
                                "description": "public/491df47b-5958-4dc7-9029-37f428755d45/32fe43d3-68f4-48fc-8f2d-461e1ddced53",
                                "width": 6000,
                                "height": 4004,
                                "alt": "",
                                "artist": {"id": "", "name": ""}
                            },
                            "color": "#000000",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "mobileStructure": {
                        "type": "Container",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                        "layout": {
                            "width": 280,
                            "height": 402,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.BoxSlideShowSlide",
                        "data": {
                            "type": "BoxSlideShowSlide",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "2"})
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "alpha-brd": "1",
                                    "bg": "#001F2C",
                                    "brd": "color_11",
                                    "brw": "0",
                                    "rd": "0px"
                                },
                                "propertiesSource": {"bg": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.BoxSlideShowSlide",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "mediaRef": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "title": "Fresh Salmon",
                                    "uri": "5238def5c2624723ab217bfb08a3ef78.jpg",
                                    "description": "public/491df47b-5958-4dc7-9029-37f428755d45/32fe43d3-68f4-48fc-8f2d-461e1ddced53",
                                    "width": 6000,
                                    "height": 4004,
                                    "alt": "",
                                    "artist": {"id": "", "name": ""}
                                },
                                "color": "#000000",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            }
                        }
                    }
                }, {
                    "type": "Container",
                    "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                    "layout": {
                        "width": 980,
                        "height": 440,
                        "x": 0,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.BoxSlideShowSlide",
                    "components": [{
                        "type": "Container",
                        "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                        "layout": {
                            "width": 344,
                            "height": 439,
                            "x": 636,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "mobile.core.components.Container",
                        "components": [{
                            "type": "Component",
                            "layout": {
                                "width": 306,
                                "height": 40,
                                "x": 13,
                                "y": 76,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 style=\"text-align: center; font-size: 28px;\" class=\"font_2\"><span style=\"font-size:28px;\"><span style=\"letter-spacing:0.1em;\"><span style=\"font-family: 'playfair display', serif;\"><span class=\"color_2\">AND MORE</span></span></span></span></h2>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew",
                            "mobileStructure": {
                                "type": "Component",
                                "layout": {
                                    "width": 174,
                                    "height": 37,
                                    "x": 20,
                                    "y": 24,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "data": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<h2 style=\"text-align: center; font-size: 28px;\" class=\"font_2\"><span style=\"font-size:28px;\"><span style=\"letter-spacing:0.1em;\"><span style=\"font-family: 'playfair display', serif;\"><span class=\"color_2\">AND MORE</span></span></span></span></h2>",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "props": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                },
                                "style": "txtNew"
                            }
                        }, {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.line.SolidLine",
                            "layout": {
                                "width": 25,
                                "height": 5,
                                "x": 153,
                                "y": 136,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.FiveGridLine",
                            "props": {
                                "type": "FiveGridLineProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "fullScreenModeOn": false
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {"alpha-brd": "1", "brd": "#2F2E2E", "lnw": "2"},
                                    "propertiesSource": {"brd": "value", "lnw": "value"}
                                },
                                "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.line.SolidLine"
                            },
                            "mobileStructure": {
                                "type": "Component",
                                "skin": "wysiwyg.viewer.skins.line.SolidLine",
                                "layout": {
                                    "width": 25,
                                    "height": 5,
                                    "x": 95,
                                    "y": 77,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.FiveGridLine",
                                "props": {
                                    "type": "FiveGridLineProperties",
                                    "metaData": {"schemaVersion": "1.0"},
                                    "fullScreenModeOn": false
                                },
                                "style": {
                                    "type": "TopLevelStyle",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "style": {
                                        "groups": {},
                                        "properties": {"alpha-brd": "1", "brd": "#2F2E2E", "lnw": "2"},
                                        "propertiesSource": {"brd": "value", "lnw": "value"}
                                    },
                                    "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                                    "pageId": "",
                                    "compId": "",
                                    "styleType": "custom",
                                    "skin": "wysiwyg.viewer.skins.line.SolidLine"
                                }
                            }
                        }, {
                            "type": "Component",
                            "layout": {
                                "width": 174,
                                "height": 132,
                                "x": 79,
                                "y": 161,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p style=\"text-align: center; line-height: 1.4em; font-size: 16px;\" class=\"font_8\"><span style=\"font-size:16px;\"><span style=\"line-height:1.4em;\"><span style=\"font-family:josefin slab,serif;\"><span class=\"color_2\">Describe what you offer here. add a few choice words and a stunning pic to tantalize your audience and leave them hungry for more.</span></span></span></span></p>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew",
                            "mobileStructure": {
                                "type": "Component",
                                "layout": {
                                    "width": 169,
                                    "height": 132,
                                    "x": 23,
                                    "y": 96,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "data": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<p style=\"text-align: center; line-height: 1.4em; font-size: 16px;\" class=\"font_8\"><span style=\"font-size:16px;\"><span style=\"line-height:1.4em;\"><span style=\"font-family:josefin slab,serif;\"><span class=\"color_2\">Describe what you offer here. add a few choice words and a stunning pic to tantalize your audience and leave them hungry for more.</span></span></span></span></p>",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "props": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                },
                                "style": "txtNew"
                            }
                        }, {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.button.BasicButton",
                            "layout": {
                                "width": 128,
                                "height": 35,
                                "x": 102,
                                "y": 328,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.SiteButton",
                            "data": {
                                "type": "LinkableButton",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "label": "Click Me"
                            },
                            "props": {
                                "type": "ButtonProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "align": "center",
                                "margin": 0
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {
                                        "alpha-bg": "0",
                                        "alpha-bgh": "1",
                                        "alpha-brd": "1",
                                        "alpha-brdh": "1",
                                        "bg": "#FFFFFF",
                                        "bgh": "#000000",
                                        "boxShadowToggleOn-shd": "false",
                                        "brd": "#000000",
                                        "brdh": "#2F2E2E",
                                        "brw": "1",
                                        "fnt": "normal normal normal 16px/1.4em josefin+slab",
                                        "rd": "0",
                                        "shd": "0 1px 4px rgba(0, 0, 0, 0.6)",
                                        "txt": "#000000",
                                        "txth": "#FFFFFF"
                                    },
                                    "propertiesSource": {
                                        "alpha-bg": "value",
                                        "alpha-bgh": "value",
                                        "alpha-brd": "value",
                                        "alpha-brdh": "value",
                                        "bg": "value",
                                        "bgh": "value",
                                        "boxShadowToggleOn-shd": "value",
                                        "brd": "value",
                                        "brdh": "value",
                                        "brw": "value",
                                        "fnt": "value",
                                        "rd": "value",
                                        "shd": "value",
                                        "txt": "value",
                                        "txth": "value"
                                    }
                                },
                                "componentClassName": "wysiwyg.viewer.components.SiteButton",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.button.BasicButton"
                            },
                            "mobileStructure": {
                                "type": "Component",
                                "skin": "wysiwyg.viewer.skins.button.BasicButton",
                                "layout": {
                                    "width": 128,
                                    "height": 35,
                                    "x": 43,
                                    "y": 253,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.SiteButton",
                                "data": {
                                    "type": "LinkableButton",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "label": "Click Me"
                                },
                                "props": {
                                    "type": "ButtonProperties",
                                    "metaData": {"schemaVersion": "1.0"},
                                    "align": "center",
                                    "margin": 0
                                },
                                "style": {
                                    "type": "TopLevelStyle",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "style": {
                                        "groups": {},
                                        "properties": {
                                            "alpha-bg": "0",
                                            "alpha-bgh": "1",
                                            "alpha-brd": "1",
                                            "alpha-brdh": "1",
                                            "bg": "#FFFFFF",
                                            "bgh": "#000000",
                                            "boxShadowToggleOn-shd": "false",
                                            "brd": "#000000",
                                            "brdh": "#2F2E2E",
                                            "brw": "1",
                                            "fnt": "normal normal normal 16px/1.4em josefin+slab",
                                            "rd": "0",
                                            "shd": "0 1px 4px rgba(0, 0, 0, 0.6)",
                                            "txt": "#000000",
                                            "txth": "#FFFFFF"
                                        },
                                        "propertiesSource": {
                                            "alpha-bg": "value",
                                            "alpha-bgh": "value",
                                            "alpha-brd": "value",
                                            "alpha-brdh": "value",
                                            "bg": "value",
                                            "bgh": "value",
                                            "boxShadowToggleOn-shd": "value",
                                            "brd": "value",
                                            "brdh": "value",
                                            "brw": "value",
                                            "fnt": "value",
                                            "rd": "value",
                                            "shd": "value",
                                            "txt": "value",
                                            "txth": "value"
                                        }
                                    },
                                    "componentClassName": "wysiwyg.viewer.components.SiteButton",
                                    "pageId": "",
                                    "compId": "",
                                    "styleType": "custom",
                                    "skin": "wysiwyg.viewer.skins.button.BasicButton"
                                }
                            }
                        }],
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "alpha-bg": "0.8",
                                    "alpha-brd": "1",
                                    "bg": "#FFFFFF",
                                    "boxShadowToggleOn-shd": "false",
                                    "brd": "#2F2E2E",
                                    "brw": "0",
                                    "rd": "0px",
                                    "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                                },
                                "propertiesSource": {
                                    "alpha-bg": "value",
                                    "bg": "value",
                                    "brd": "value",
                                    "brw": "value",
                                    "rd": "value",
                                    "shd": "value"
                                }
                            },
                            "componentClassName": "mobile.core.components.Container",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                        },
                        "mobileStructure": {
                            "type": "Container",
                            "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                            "layout": {
                                "width": 215,
                                "height": 319,
                                "x": 32,
                                "y": 32,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "mobile.core.components.Container",
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {
                                        "alpha-bg": "0.8",
                                        "alpha-brd": "1",
                                        "bg": "#FFFFFF",
                                        "boxShadowToggleOn-shd": "false",
                                        "brd": "#2F2E2E",
                                        "brw": "0",
                                        "rd": "0px",
                                        "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                                    },
                                    "propertiesSource": {
                                        "alpha-bg": "value",
                                        "bg": "value",
                                        "brd": "value",
                                        "brw": "value",
                                        "rd": "value",
                                        "shd": "value"
                                    }
                                },
                                "componentClassName": "mobile.core.components.Container",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                            }
                        }
                    }],
                    "data": {
                        "type": "BoxSlideShowSlide",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "3"})
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-brd": "1",
                                "bg": "#001F2C",
                                "brd": "color_11",
                                "brw": "0",
                                "rd": "0px"
                            },
                            "propertiesSource": {"bg": "value"}
                        },
                        "componentClassName": "wysiwyg.viewer.components.BoxSlideShowSlide",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "mediaRef": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "title": "Ready to Serve",
                                "uri": "885f815586f74d2493ca39e56aa770dd.jpg",
                                "description": "public/491df47b-5958-4dc7-9029-37f428755d45/32fe43d3-68f4-48fc-8f2d-461e1ddced53",
                                "width": 5760,
                                "height": 3840,
                                "alt": "",
                                "artist": {"id": "", "name": ""}
                            },
                            "color": "#000000",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "mobileStructure": {
                        "type": "Container",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                        "layout": {
                            "width": 280,
                            "height": 402,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.BoxSlideShowSlide",
                        "data": {
                            "type": "BoxSlideShowSlide",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "3"})
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "alpha-brd": "1",
                                    "bg": "#001F2C",
                                    "brd": "color_11",
                                    "brw": "0",
                                    "rd": "0px"
                                },
                                "propertiesSource": {"bg": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.BoxSlideShowSlide",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "mediaRef": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "title": "Ready to Serve",
                                    "uri": "885f815586f74d2493ca39e56aa770dd.jpg",
                                    "description": "public/491df47b-5958-4dc7-9029-37f428755d45/32fe43d3-68f4-48fc-8f2d-461e1ddced53",
                                    "width": 5760,
                                    "height": 3840,
                                    "alt": "",
                                    "artist": {"id": "", "name": ""}
                                },
                                "color": "#000000",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            }
                        }
                    }
                }],
                "props": {
                    "type": "BoxSlideShowProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "transition": "SlideHorizontal",
                    "autoPlayInterval": 3,
                    "autoPlay": true,
                    "transDuration": 1,
                    "pauseAutoPlayOnMouseOver": true,
                    "direction": "RTL",
                    "shouldHideOverflowContent": true,
                    "flexibleBoxHeight": false,
                    "showNavigationButton": true,
                    "showNavigationDots": true,
                    "navigationButtonSize": 20,
                    "navigationButtonMargin": 32,
                    "navigationDotsSize": 8,
                    "navigationDotsMargin": 26,
                    "navigationDotsGap": 19,
                    "navigationDotsAlignment": "center"
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-arrowColor": "1",
                            "alpha-brd": "1",
                            "alpha-dotsColor": "1",
                            "arrowColor": "#000000",
                            "boxShadowToggleOn-shd": "false",
                            "brd": "#FFFFFF",
                            "brw": "0",
                            "dotsColor": "#000000",
                            "shd": "0 0 0 rgba(0, 0, 0, 0.6)"
                        },
                        "propertiesSource": {
                            "alpha-arrowColor": "value",
                            "alpha-brd": "value",
                            "alpha-dotsColor": "value",
                            "arrowColor": "value",
                            "boxShadowToggleOn-shd": "value",
                            "brd": "value",
                            "brw": "value",
                            "dotsColor": "value",
                            "shd": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.BoxSlideShow",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.common.components.boxSlideShow.viewer.skins.thinArrowsLargeSelectedCircleSkin"
                },
                "mobileStructure": {
                    "type": "Container",
                    "skin": "wysiwyg.common.components.boxSlideShow.viewer.skins.thinArrowsLargeSelectedCircleSkin",
                    "layout": {
                        "width": 280,
                        "height": 402,
                        "x": 0,
                        "y": 429,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.BoxSlideShow",
                    "props": {
                        "type": "BoxSlideShowProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "transition": "SlideHorizontal",
                        "autoPlayInterval": 3,
                        "autoPlay": false,
                        "transDuration": 1,
                        "pauseAutoPlayOnMouseOver": true,
                        "direction": "RTL",
                        "shouldHideOverflowContent": true,
                        "flexibleBoxHeight": false,
                        "showNavigationButton": true,
                        "showNavigationDots": true,
                        "navigationButtonSize": 12,
                        "navigationButtonMargin": 16,
                        "navigationDotsSize": 6,
                        "navigationDotsMargin": 24,
                        "navigationDotsGap": 15,
                        "navigationDotsAlignment": "center"
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-arrowColor": "1",
                                "alpha-brd": "1",
                                "alpha-dotsColor": "1",
                                "arrowColor": "#000000",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "#FFFFFF",
                                "brw": "0",
                                "dotsColor": "#000000",
                                "shd": "0 0 0 rgba(0, 0, 0, 0.6)"
                            },
                            "propertiesSource": {
                                "alpha-arrowColor": "value",
                                "alpha-brd": "value",
                                "alpha-dotsColor": "value",
                                "arrowColor": "value",
                                "boxShadowToggleOn-shd": "value",
                                "brd": "value",
                                "brw": "value",
                                "dotsColor": "value",
                                "shd": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.viewer.components.BoxSlideShow",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.boxSlideShow.viewer.skins.thinArrowsLargeSelectedCircleSkin"
                    }
                },
                "id": "comp-im0pjh2c"
            },
            "preset": {
                "rect": {"width": 324, "height": 149.5, "x": 0, "y": 151},
                "label": "add_preset_BoxSlideShow_980_2",
                "tags": null
            }
        }, {
            "id": "BoxSlideShow_BoxSlideShow980_3",
            "structure": {
                "type": "Container",
                "skin": "wysiwyg.common.components.boxSlideShow.viewer.skins.squareButtonsSkin",
                "layout": {
                    "width": 980,
                    "height": 377,
                    "x": 0,
                    "y": 1281,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.BoxSlideShow",
                "components": [{
                    "type": "Container",
                    "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                    "layout": {
                        "width": 980,
                        "height": 377,
                        "x": 0,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.BoxSlideShowSlide",
                    "components": [{
                        "type": "Container",
                        "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                        "layout": {
                            "width": 344,
                            "height": 377,
                            "x": 636,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "mobile.core.components.Container",
                        "components": [{
                            "type": "Component",
                            "layout": {
                                "width": 200,
                                "height": 111,
                                "x": 69,
                                "y": 48,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 style=\"font-size: 33px; text-align: center; line-height: 1em;\" class=\"font_2\"><span style=\"font-size:33px;\"><span style=\"line-height:1em;\"><span style=\"letter-spacing:0.2em;\"><span style=\"font-family:dinneuzeitgroteskltw01-_812426,sans-serif;\"><span class=\"color_11\">MID</span></span></span></span></span></h2><h2 style=\"font-size: 33px; text-align: center; line-height: 1em;\" class=\"font_2\"><span style=\"font-size:33px;\"><span style=\"line-height:1em;\"><span style=\"letter-spacing:0.2em;\"><span style=\"font-family:dinneuzeitgroteskltw01-_812426,sans-serif;\"><span class=\"color_11\">SEASON</span></span></span></span></span></h2><h2 style=\"text-align: center; line-height: 1em; font-size: 33px;\" class=\"font_2\"><span style=\"font-size:33px;\"><span style=\"line-height:1em;\"><span style=\"letter-spacing:0.2em;\"><span style=\"font-family:dinneuzeitgroteskltw01-_812426,sans-serif;\"><span class=\"color_11\">SALE</span></span></span></span></span></h2>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew",
                            "mobileStructure": {
                                "type": "Component",
                                "layout": {
                                    "width": 139,
                                    "height": 87,
                                    "x": 70,
                                    "y": 32,
                                    "scale": 0.97,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "data": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<h2 style=\"font-size: 33px; text-align: center; line-height: 1em;\" class=\"font_2\"><span style=\"font-size:33px;\"><span style=\"line-height:1em;\"><span style=\"letter-spacing:0.2em;\"><span style=\"font-family:dinneuzeitgroteskltw01-_812426,sans-serif;\"><span class=\"color_11\">MID</span></span></span></span></span></h2><h2 style=\"font-size: 33px; text-align: center; line-height: 1em;\" class=\"font_2\"><span style=\"font-size:33px;\"><span style=\"line-height:1em;\"><span style=\"letter-spacing:0.2em;\"><span style=\"font-family:dinneuzeitgroteskltw01-_812426,sans-serif;\"><span class=\"color_11\">SEASON</span></span></span></span></span></h2><h2 style=\"text-align: center; line-height: 1em; font-size: 33px;\" class=\"font_2\"><span style=\"font-size:33px;\"><span style=\"line-height:1em;\"><span style=\"letter-spacing:0.2em;\"><span style=\"font-family:dinneuzeitgroteskltw01-_812426,sans-serif;\"><span class=\"color_11\">SALE</span></span></span></span></span></h2>",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "props": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                },
                                "style": "txtNew"
                            }
                        }, {
                            "type": "Component",
                            "layout": {
                                "width": 169,
                                "height": 20,
                                "x": 84,
                                "y": 182,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p style=\"text-align: center; line-height: 1.4em; font-size: 14px;\" class=\"font_8\"><span style=\"font-size:14px;\"><span style=\"line-height:1.4em;\"><span style=\"letter-spacing:0.1em;\"><span class=\"color_11\"><span style=\"font-family:dinneuzeitgroteskltw01-_812426,sans-serif;\">SAVE UP TO</span></span></span></span></span></p>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew",
                            "mobileStructure": {
                                "type": "Component",
                                "layout": {
                                    "width": 101,
                                    "height": 23,
                                    "x": 61,
                                    "y": 139,
                                    "scale": 1.07,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "data": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<p style=\"text-align: center; line-height: 1.4em; font-size: 14px;\" class=\"font_8\"><span style=\"font-size:14px;\"><span style=\"line-height:1.4em;\"><span style=\"letter-spacing:0.1em;\"><span class=\"color_11\"><span style=\"font-family:dinneuzeitgroteskltw01-_812426,sans-serif;\">SAVE UP TO</span></span></span></span></span></p>",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "props": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                },
                                "style": "txtNew"
                            }
                        }, {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.button.BasicButton",
                            "layout": {
                                "width": 110,
                                "height": 35,
                                "x": 114,
                                "y": 268,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.SiteButton",
                            "data": {
                                "type": "LinkableButton",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "label": "SHOP NOW"
                            },
                            "props": {
                                "type": "ButtonProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "align": "center",
                                "margin": 0
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {
                                        "alpha-bg": "0",
                                        "alpha-bgh": "1",
                                        "alpha-brd": "1",
                                        "alpha-brdh": "1",
                                        "bg": "#FFFFFF",
                                        "bgh": "#FFFFFF",
                                        "boxShadowToggleOn-shd": "false",
                                        "brd": "#FFFFFF",
                                        "brdh": "#2F2E2E",
                                        "brw": "1",
                                        "fnt": "normal normal normal 15px/1.4em dinneuzeitgroteskltw01-_812426",
                                        "rd": "0",
                                        "shd": "0 1px 4px rgba(0, 0, 0, 0.6)",
                                        "txt": "#FFFFFF",
                                        "txth": "#000000"
                                    },
                                    "propertiesSource": {
                                        "alpha-bg": "value",
                                        "alpha-bgh": "value",
                                        "alpha-brd": "value",
                                        "alpha-brdh": "value",
                                        "bg": "value",
                                        "bgh": "value",
                                        "boxShadowToggleOn-shd": "value",
                                        "brd": "value",
                                        "brdh": "value",
                                        "brw": "value",
                                        "fnt": "value",
                                        "rd": "value",
                                        "shd": "value",
                                        "txt": "value",
                                        "txth": "value"
                                    }
                                },
                                "componentClassName": "wysiwyg.viewer.components.SiteButton",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.button.BasicButton"
                            },
                            "mobileStructure": {
                                "type": "Component",
                                "skin": "wysiwyg.viewer.skins.button.BasicButton",
                                "layout": {
                                    "width": 120,
                                    "height": 35,
                                    "x": 80,
                                    "y": 184,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.SiteButton",
                                "data": {
                                    "type": "LinkableButton",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "label": "SHOP NOW"
                                },
                                "props": {
                                    "type": "ButtonProperties",
                                    "metaData": {"schemaVersion": "1.0"},
                                    "align": "center",
                                    "margin": 0
                                },
                                "style": {
                                    "type": "TopLevelStyle",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "style": {
                                        "groups": {},
                                        "properties": {
                                            "alpha-bg": "0",
                                            "alpha-bgh": "1",
                                            "alpha-brd": "1",
                                            "alpha-brdh": "1",
                                            "bg": "#FFFFFF",
                                            "bgh": "#FFFFFF",
                                            "boxShadowToggleOn-shd": "false",
                                            "brd": "#FFFFFF",
                                            "brdh": "#2F2E2E",
                                            "brw": "1",
                                            "fnt": "normal normal normal 15px/1.4em dinneuzeitgroteskltw01-_812426",
                                            "rd": "0",
                                            "shd": "0 1px 4px rgba(0, 0, 0, 0.6)",
                                            "txt": "#FFFFFF",
                                            "txth": "#000000"
                                        },
                                        "propertiesSource": {
                                            "alpha-bg": "value",
                                            "alpha-bgh": "value",
                                            "alpha-brd": "value",
                                            "alpha-brdh": "value",
                                            "bg": "value",
                                            "bgh": "value",
                                            "boxShadowToggleOn-shd": "value",
                                            "brd": "value",
                                            "brdh": "value",
                                            "brw": "value",
                                            "fnt": "value",
                                            "rd": "value",
                                            "shd": "value",
                                            "txt": "value",
                                            "txth": "value"
                                        }
                                    },
                                    "componentClassName": "wysiwyg.viewer.components.SiteButton",
                                    "pageId": "",
                                    "compId": "",
                                    "styleType": "custom",
                                    "skin": "wysiwyg.viewer.skins.button.BasicButton"
                                }
                            }
                        }, {
                            "type": "Component",
                            "layout": {
                                "width": 168,
                                "height": 55,
                                "x": 87,
                                "y": 197,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p style=\"text-align: center; line-height: 1.4em; font-size: 37px;\" class=\"font_8\"><span style=\"font-size:37px;\"><span style=\"line-height:1.4em;\"><span style=\"letter-spacing:0.1em;\"><span class=\"color_11\"><span style=\"font-family:dinneuzeitgroteskltw01-_812426,sans-serif;\">50%</span></span></span></span></span></p>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew",
                            "mobileStructure": {
                                "type": "Component",
                                "layout": {
                                    "width": 55,
                                    "height": 42,
                                    "x": 159,
                                    "y": 129,
                                    "scale": 0.962507365824324,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "data": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<p style=\"text-align: center; line-height: 1.4em; font-size: 37px;\" class=\"font_8\"><span style=\"font-size:37px;\"><span style=\"line-height:1.4em;\"><span style=\"letter-spacing:0.1em;\"><span class=\"color_11\"><span style=\"font-family:dinneuzeitgroteskltw01-_812426,sans-serif;\">50%</span></span></span></span></span></p>",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "props": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                },
                                "style": "txtNew"
                            }
                        }],
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "alpha-bg": "1",
                                    "alpha-brd": "1",
                                    "bg": "#000000",
                                    "boxShadowToggleOn-shd": "false",
                                    "brd": "#2F2E2E",
                                    "brw": "0",
                                    "rd": "0px",
                                    "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                                },
                                "propertiesSource": {
                                    "alpha-bg": "value",
                                    "bg": "value",
                                    "brd": "value",
                                    "brw": "value",
                                    "rd": "value",
                                    "shd": "value"
                                }
                            },
                            "componentClassName": "mobile.core.components.Container",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                        },
                        "mobileStructure": {
                            "type": "Container",
                            "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                            "layout": {
                                "width": 280,
                                "height": 272,
                                "x": 0,
                                "y": 268,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "mobile.core.components.Container",
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {
                                        "alpha-bg": "1",
                                        "alpha-brd": "1",
                                        "bg": "#000000",
                                        "boxShadowToggleOn-shd": "false",
                                        "brd": "#2F2E2E",
                                        "brw": "0",
                                        "rd": "0px",
                                        "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                                    },
                                    "propertiesSource": {
                                        "alpha-bg": "value",
                                        "bg": "value",
                                        "brd": "value",
                                        "brw": "value",
                                        "rd": "value",
                                        "shd": "value"
                                    }
                                },
                                "componentClassName": "mobile.core.components.Container",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                            }
                        }
                    }],
                    "data": {
                        "type": "BoxSlideShowSlide",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "1"})
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-brd": "1",
                                "bg": "#001F2C",
                                "brd": "color_11",
                                "brw": "0",
                                "rd": "0px"
                            },
                            "propertiesSource": {"bg": "value"}
                        },
                        "componentClassName": "wysiwyg.viewer.components.BoxSlideShowSlide",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "mediaRef": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "title": "Surfer - Top View",
                                "uri": "1cf66d9b8a8b49939d60a006ccc81b59.jpeg",
                                "description": "public/491df47b-5958-4dc7-9029-37f428755d45/38266862-d350-4955-8eb0-c93ba62dea8c",
                                "width": 6000,
                                "height": 3376,
                                "alt": "",
                                "artist": {"id": "", "name": ""}
                            },
                            "color": "#FFFFFF",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "{color_15}",
                            "colorOverlayOpacity": 0.21
                        }
                    },
                    "mobileStructure": {
                        "type": "Container",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                        "layout": {
                            "width": 280,
                            "height": 542,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.BoxSlideShowSlide",
                        "data": {
                            "type": "BoxSlideShowSlide",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "1"})
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "alpha-brd": "1",
                                    "bg": "#001F2C",
                                    "brd": "color_11",
                                    "brw": "0",
                                    "rd": "0px"
                                },
                                "propertiesSource": {"bg": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.BoxSlideShowSlide",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "mediaRef": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "title": "Surfer - Top View",
                                    "uri": "1cf66d9b8a8b49939d60a006ccc81b59.jpeg",
                                    "description": "public/491df47b-5958-4dc7-9029-37f428755d45/38266862-d350-4955-8eb0-c93ba62dea8c",
                                    "width": 6000,
                                    "height": 3376,
                                    "alt": "",
                                    "artist": {"id": "", "name": ""}
                                },
                                "color": "#FFFFFF",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "{color_15}",
                                "colorOverlayOpacity": 0.21
                            }
                        }
                    }
                }, {
                    "type": "Container",
                    "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                    "layout": {
                        "width": 980,
                        "height": 377,
                        "x": 0,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.BoxSlideShowSlide",
                    "components": [{
                        "type": "Container",
                        "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                        "layout": {
                            "width": 344,
                            "height": 377,
                            "x": 636,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "mobile.core.components.Container",
                        "components": [{
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.button.BasicButton",
                            "layout": {
                                "width": 110,
                                "height": 35,
                                "x": 114,
                                "y": 268,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.SiteButton",
                            "data": {
                                "type": "LinkableButton",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "label": "SHOP NOW"
                            },
                            "props": {
                                "type": "ButtonProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "align": "center",
                                "margin": 0
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {
                                        "alpha-bg": "0",
                                        "alpha-bgh": "1",
                                        "alpha-brd": "1",
                                        "alpha-brdh": "1",
                                        "bg": "#FFFFFF",
                                        "bgh": "#FFFFFF",
                                        "boxShadowToggleOn-shd": "false",
                                        "brd": "#FFFFFF",
                                        "brdh": "#2F2E2E",
                                        "brw": "1",
                                        "fnt": "normal normal normal 15px/1.4em dinneuzeitgroteskltw01-_812426",
                                        "rd": "0",
                                        "shd": "0 1px 4px rgba(0, 0, 0, 0.6)",
                                        "txt": "#FFFFFF",
                                        "txth": "#000000"
                                    },
                                    "propertiesSource": {
                                        "alpha-bg": "value",
                                        "alpha-bgh": "value",
                                        "alpha-brd": "value",
                                        "alpha-brdh": "value",
                                        "bg": "value",
                                        "bgh": "value",
                                        "boxShadowToggleOn-shd": "value",
                                        "brd": "value",
                                        "brdh": "value",
                                        "brw": "value",
                                        "fnt": "value",
                                        "rd": "value",
                                        "shd": "value",
                                        "txt": "value",
                                        "txth": "value"
                                    }
                                },
                                "componentClassName": "wysiwyg.viewer.components.SiteButton",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.button.BasicButton"
                            },
                            "mobileStructure": {
                                "type": "Component",
                                "skin": "wysiwyg.viewer.skins.button.BasicButton",
                                "layout": {
                                    "width": 120,
                                    "height": 35,
                                    "x": 80,
                                    "y": 181,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.SiteButton",
                                "data": {
                                    "type": "LinkableButton",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "label": "SHOP NOW"
                                },
                                "props": {
                                    "type": "ButtonProperties",
                                    "metaData": {"schemaVersion": "1.0"},
                                    "align": "center",
                                    "margin": 0
                                },
                                "style": {
                                    "type": "TopLevelStyle",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "style": {
                                        "groups": {},
                                        "properties": {
                                            "alpha-bg": "0",
                                            "alpha-bgh": "1",
                                            "alpha-brd": "1",
                                            "alpha-brdh": "1",
                                            "bg": "#FFFFFF",
                                            "bgh": "#FFFFFF",
                                            "boxShadowToggleOn-shd": "false",
                                            "brd": "#FFFFFF",
                                            "brdh": "#2F2E2E",
                                            "brw": "1",
                                            "fnt": "normal normal normal 15px/1.4em dinneuzeitgroteskltw01-_812426",
                                            "rd": "0",
                                            "shd": "0 1px 4px rgba(0, 0, 0, 0.6)",
                                            "txt": "#FFFFFF",
                                            "txth": "#000000"
                                        },
                                        "propertiesSource": {
                                            "alpha-bg": "value",
                                            "alpha-bgh": "value",
                                            "alpha-brd": "value",
                                            "alpha-brdh": "value",
                                            "bg": "value",
                                            "bgh": "value",
                                            "boxShadowToggleOn-shd": "value",
                                            "brd": "value",
                                            "brdh": "value",
                                            "brw": "value",
                                            "fnt": "value",
                                            "rd": "value",
                                            "shd": "value",
                                            "txt": "value",
                                            "txth": "value"
                                        }
                                    },
                                    "componentClassName": "wysiwyg.viewer.components.SiteButton",
                                    "pageId": "",
                                    "compId": "",
                                    "styleType": "custom",
                                    "skin": "wysiwyg.viewer.skins.button.BasicButton"
                                }
                            }
                        }, {
                            "type": "Component",
                            "layout": {
                                "width": 168,
                                "height": 55,
                                "x": 87,
                                "y": 197,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p style=\"text-align: center; line-height: 1.4em; font-size: 37px;\" class=\"font_8\"><span style=\"font-size:37px;\"><span style=\"line-height:1.4em;\"><span style=\"letter-spacing:0.1em;\"><span class=\"color_11\"><span style=\"font-family:dinneuzeitgroteskltw01-_812426,sans-serif;\">50%</span></span></span></span></span></p>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew",
                            "mobileStructure": {
                                "type": "Component",
                                "layout": {
                                    "width": 60,
                                    "height": 42,
                                    "x": 156,
                                    "y": 126,
                                    "scale": 0.97,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "data": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<p style=\"text-align: center; line-height: 1.4em; font-size: 37px;\" class=\"font_8\"><span style=\"font-size:37px;\"><span style=\"line-height:1.4em;\"><span style=\"letter-spacing:0.1em;\"><span class=\"color_11\"><span style=\"font-family:dinneuzeitgroteskltw01-_812426,sans-serif;\">50%</span></span></span></span></span></p>",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "props": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                },
                                "style": "txtNew"
                            }
                        }, {
                            "type": "Component",
                            "layout": {
                                "width": 200,
                                "height": 111,
                                "x": 69,
                                "y": 48,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 style=\"font-size: 33px; text-align: center; line-height: 1em;\" class=\"font_2\"><span style=\"font-size:33px;\"><span style=\"line-height:1em;\"><span style=\"letter-spacing:0.2em;\"><span style=\"font-family:dinneuzeitgroteskltw01-_812426,sans-serif;\"><span class=\"color_11\">MORE</span></span></span></span></span></h2><h2 style=\"font-size: 33px; text-align: center; line-height: 1em;\" class=\"font_2\"><span style=\"font-size:33px;\"><span style=\"line-height:1em;\"><span style=\"letter-spacing:0.2em;\"><span style=\"font-family:dinneuzeitgroteskltw01-_812426,sans-serif;\"><span class=\"color_11\">GREAT</span></span></span></span></span></h2><h2 style=\"text-align: center; line-height: 1em; font-size: 33px;\" class=\"font_2\"><span style=\"font-size:33px;\"><span style=\"line-height:1em;\"><span style=\"letter-spacing:0.2em;\"><span style=\"font-family:dinneuzeitgroteskltw01-_812426,sans-serif;\"><span class=\"color_11\">DEALS</span></span></span></span></span></h2>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew",
                            "mobileStructure": {
                                "type": "Component",
                                "layout": {
                                    "width": 186,
                                    "height": 87,
                                    "x": 47,
                                    "y": 29,
                                    "scale": 0.97,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "data": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<h2 style=\"font-size: 33px; text-align: center; line-height: 1em;\" class=\"font_2\"><span style=\"font-size:33px;\"><span style=\"line-height:1em;\"><span style=\"letter-spacing:0.2em;\"><span style=\"font-family:dinneuzeitgroteskltw01-_812426,sans-serif;\"><span class=\"color_11\">MORE</span></span></span></span></span></h2><h2 style=\"font-size: 33px; text-align: center; line-height: 1em;\" class=\"font_2\"><span style=\"font-size:33px;\"><span style=\"line-height:1em;\"><span style=\"letter-spacing:0.2em;\"><span style=\"font-family:dinneuzeitgroteskltw01-_812426,sans-serif;\"><span class=\"color_11\">GREAT</span></span></span></span></span></h2><h2 style=\"text-align: center; line-height: 1em; font-size: 33px;\" class=\"font_2\"><span style=\"font-size:33px;\"><span style=\"line-height:1em;\"><span style=\"letter-spacing:0.2em;\"><span style=\"font-family:dinneuzeitgroteskltw01-_812426,sans-serif;\"><span class=\"color_11\">DEALS</span></span></span></span></span></h2>",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "props": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                },
                                "style": "txtNew"
                            }
                        }, {
                            "type": "Component",
                            "layout": {
                                "width": 169,
                                "height": 20,
                                "x": 85,
                                "y": 182,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p style=\"text-align: center; line-height: 1.4em; font-size: 14px;\" class=\"font_8\"><span style=\"font-size:14px;\"><span style=\"line-height:1.4em;\"><span style=\"letter-spacing:0.1em;\"><span class=\"color_11\"><span style=\"font-family:dinneuzeitgroteskltw01-_812426,sans-serif;\">SAVE UP TO</span></span></span></span></span></p>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew",
                            "mobileStructure": {
                                "type": "Component",
                                "layout": {
                                    "width": 137,
                                    "height": 23,
                                    "x": 40,
                                    "y": 135,
                                    "scale": 1.07,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "data": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<p style=\"text-align: center; line-height: 1.4em; font-size: 14px;\" class=\"font_8\"><span style=\"font-size:14px;\"><span style=\"line-height:1.4em;\"><span style=\"letter-spacing:0.1em;\"><span class=\"color_11\"><span style=\"font-family:dinneuzeitgroteskltw01-_812426,sans-serif;\">SAVE UP TO</span></span></span></span></span></p>",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "props": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                },
                                "style": "txtNew"
                            }
                        }],
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "alpha-bg": "1",
                                    "alpha-brd": "1",
                                    "bg": "#000000",
                                    "boxShadowToggleOn-shd": "false",
                                    "brd": "#2F2E2E",
                                    "brw": "0",
                                    "rd": "0px",
                                    "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                                },
                                "propertiesSource": {
                                    "alpha-bg": "value",
                                    "bg": "value",
                                    "brd": "value",
                                    "brw": "value",
                                    "rd": "value",
                                    "shd": "value"
                                }
                            },
                            "componentClassName": "mobile.core.components.Container",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                        },
                        "mobileStructure": {
                            "type": "Container",
                            "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                            "layout": {
                                "width": 280,
                                "height": 270,
                                "x": 0,
                                "y": 272,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "mobile.core.components.Container",
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {
                                        "alpha-bg": "1",
                                        "alpha-brd": "1",
                                        "bg": "#000000",
                                        "boxShadowToggleOn-shd": "false",
                                        "brd": "#2F2E2E",
                                        "brw": "0",
                                        "rd": "0px",
                                        "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                                    },
                                    "propertiesSource": {
                                        "alpha-bg": "value",
                                        "bg": "value",
                                        "brd": "value",
                                        "brw": "value",
                                        "rd": "value",
                                        "shd": "value"
                                    }
                                },
                                "componentClassName": "mobile.core.components.Container",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                            }
                        }
                    }],
                    "data": {
                        "type": "BoxSlideShowSlide",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "2"})
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-brd": "1",
                                "bg": "#001F2C",
                                "brd": "color_11",
                                "brw": "0",
                                "rd": "0px"
                            },
                            "propertiesSource": {"bg": "value"}
                        },
                        "componentClassName": "wysiwyg.viewer.components.BoxSlideShowSlide",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "mediaRef": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "title": "SUP, Surfboard",
                                "uri": "83bac34050a54640997901bedd3ec994.jpg",
                                "description": "public/491df47b-5958-4dc7-9029-37f428755d45/38266862-d350-4955-8eb0-c93ba62dea8c",
                                "width": 4724,
                                "height": 3150,
                                "alt": "",
                                "artist": {"id": "", "name": ""}
                            },
                            "color": "#000000",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "mobileStructure": {
                        "type": "Container",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                        "layout": {
                            "width": 280,
                            "height": 542,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.BoxSlideShowSlide",
                        "data": {
                            "type": "BoxSlideShowSlide",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "2"})
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "alpha-brd": "1",
                                    "bg": "#001F2C",
                                    "brd": "color_11",
                                    "brw": "0",
                                    "rd": "0px"
                                },
                                "propertiesSource": {"bg": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.BoxSlideShowSlide",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "mediaRef": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "title": "SUP, Surfboard",
                                    "uri": "83bac34050a54640997901bedd3ec994.jpg",
                                    "description": "public/491df47b-5958-4dc7-9029-37f428755d45/38266862-d350-4955-8eb0-c93ba62dea8c",
                                    "width": 4724,
                                    "height": 3150,
                                    "alt": "",
                                    "artist": {"id": "", "name": ""}
                                },
                                "color": "#000000",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            }
                        }
                    }
                }, {
                    "type": "Container",
                    "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                    "layout": {
                        "width": 980,
                        "height": 377,
                        "x": 0,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.BoxSlideShowSlide",
                    "components": [{
                        "type": "Container",
                        "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                        "layout": {
                            "width": 344,
                            "height": 377,
                            "x": 636,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "mobile.core.components.Container",
                        "components": [{
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.button.BasicButton",
                            "layout": {
                                "width": 110,
                                "height": 35,
                                "x": 114,
                                "y": 268,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.SiteButton",
                            "data": {
                                "type": "LinkableButton",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "label": "SHOP NOW"
                            },
                            "props": {
                                "type": "ButtonProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "align": "center",
                                "margin": 0
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {
                                        "alpha-bg": "0",
                                        "alpha-bgh": "1",
                                        "alpha-brd": "1",
                                        "alpha-brdh": "1",
                                        "bg": "#FFFFFF",
                                        "bgh": "#FFFFFF",
                                        "boxShadowToggleOn-shd": "false",
                                        "brd": "#FFFFFF",
                                        "brdh": "#2F2E2E",
                                        "brw": "1",
                                        "fnt": "normal normal normal 15px/1.4em dinneuzeitgroteskltw01-_812426",
                                        "rd": "0",
                                        "shd": "0 1px 4px rgba(0, 0, 0, 0.6)",
                                        "txt": "#FFFFFF",
                                        "txth": "#000000"
                                    },
                                    "propertiesSource": {
                                        "alpha-bg": "value",
                                        "alpha-bgh": "value",
                                        "alpha-brd": "value",
                                        "alpha-brdh": "value",
                                        "bg": "value",
                                        "bgh": "value",
                                        "boxShadowToggleOn-shd": "value",
                                        "brd": "value",
                                        "brdh": "value",
                                        "brw": "value",
                                        "fnt": "value",
                                        "rd": "value",
                                        "shd": "value",
                                        "txt": "value",
                                        "txth": "value"
                                    }
                                },
                                "componentClassName": "wysiwyg.viewer.components.SiteButton",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.button.BasicButton"
                            },
                            "mobileStructure": {
                                "type": "Component",
                                "skin": "wysiwyg.viewer.skins.button.BasicButton",
                                "layout": {
                                    "width": 120,
                                    "height": 35,
                                    "x": 80,
                                    "y": 197,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.SiteButton",
                                "data": {
                                    "type": "LinkableButton",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "label": "SHOP NOW"
                                },
                                "props": {
                                    "type": "ButtonProperties",
                                    "metaData": {"schemaVersion": "1.0"},
                                    "align": "center",
                                    "margin": 0
                                },
                                "style": {
                                    "type": "TopLevelStyle",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "style": {
                                        "groups": {},
                                        "properties": {
                                            "alpha-bg": "0",
                                            "alpha-bgh": "1",
                                            "alpha-brd": "1",
                                            "alpha-brdh": "1",
                                            "bg": "#FFFFFF",
                                            "bgh": "#FFFFFF",
                                            "boxShadowToggleOn-shd": "false",
                                            "brd": "#FFFFFF",
                                            "brdh": "#2F2E2E",
                                            "brw": "1",
                                            "fnt": "normal normal normal 15px/1.4em dinneuzeitgroteskltw01-_812426",
                                            "rd": "0",
                                            "shd": "0 1px 4px rgba(0, 0, 0, 0.6)",
                                            "txt": "#FFFFFF",
                                            "txth": "#000000"
                                        },
                                        "propertiesSource": {
                                            "alpha-bg": "value",
                                            "alpha-bgh": "value",
                                            "alpha-brd": "value",
                                            "alpha-brdh": "value",
                                            "bg": "value",
                                            "bgh": "value",
                                            "boxShadowToggleOn-shd": "value",
                                            "brd": "value",
                                            "brdh": "value",
                                            "brw": "value",
                                            "fnt": "value",
                                            "rd": "value",
                                            "shd": "value",
                                            "txt": "value",
                                            "txth": "value"
                                        }
                                    },
                                    "componentClassName": "wysiwyg.viewer.components.SiteButton",
                                    "pageId": "",
                                    "compId": "",
                                    "styleType": "custom",
                                    "skin": "wysiwyg.viewer.skins.button.BasicButton"
                                }
                            }
                        }, {
                            "type": "Component",
                            "layout": {
                                "width": 168,
                                "height": 55,
                                "x": 87,
                                "y": 197,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p style=\"text-align: center; line-height: 1.4em; font-size: 37px;\" class=\"font_8\"><span style=\"font-size:37px;\"><span style=\"line-height:1.4em;\"><span style=\"letter-spacing:0.1em;\"><span class=\"color_11\"><span style=\"font-family:dinneuzeitgroteskltw01-_812426,sans-serif;\">50%</span></span></span></span></span></p>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew",
                            "mobileStructure": {
                                "type": "Component",
                                "layout": {
                                    "width": 68,
                                    "height": 42,
                                    "x": 150,
                                    "y": 138,
                                    "scale": 0.97,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "data": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<p style=\"text-align: center; line-height: 1.4em; font-size: 37px;\" class=\"font_8\"><span style=\"font-size:37px;\"><span style=\"line-height:1.4em;\"><span style=\"letter-spacing:0.1em;\"><span class=\"color_11\"><span style=\"font-family:dinneuzeitgroteskltw01-_812426,sans-serif;\">50%</span></span></span></span></span></p>",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "props": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                },
                                "style": "txtNew"
                            }
                        }, {
                            "type": "Component",
                            "layout": {
                                "width": 169,
                                "height": 20,
                                "x": 85,
                                "y": 182,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p style=\"text-align: center; line-height: 1.4em; font-size: 14px;\" class=\"font_8\"><span style=\"font-size:14px;\"><span style=\"line-height:1.4em;\"><span style=\"letter-spacing:0.1em;\"><span class=\"color_11\"><span style=\"font-family:dinneuzeitgroteskltw01-_812426,sans-serif;\">SAVE UP TO</span></span></span></span></span></p>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew",
                            "mobileStructure": {
                                "type": "Component",
                                "layout": {
                                    "width": 100,
                                    "height": 23,
                                    "x": 58,
                                    "y": 148,
                                    "scale": 1.07,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "data": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<p style=\"text-align: center; line-height: 1.4em; font-size: 14px;\" class=\"font_8\"><span style=\"font-size:14px;\"><span style=\"line-height:1.4em;\"><span style=\"letter-spacing:0.1em;\"><span class=\"color_11\"><span style=\"font-family:dinneuzeitgroteskltw01-_812426,sans-serif;\">SAVE UP TO</span></span></span></span></span></p>",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "props": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                },
                                "style": "txtNew"
                            }
                        }, {
                            "type": "Component",
                            "layout": {
                                "width": 200,
                                "height": 111,
                                "x": 69,
                                "y": 48,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 style=\"font-size: 33px; text-align: center; line-height: 1em;\" class=\"font_2\"><span style=\"font-size:33px;\"><span style=\"line-height:1em;\"><span style=\"letter-spacing:0.2em;\"><span style=\"font-family:dinneuzeitgroteskltw01-_812426,sans-serif;\"><span class=\"color_11\">ONLY</span></span></span></span></span></h2><h2 style=\"font-size: 33px; text-align: center; line-height: 1em;\" class=\"font_2\"><span style=\"font-size:33px;\"><span style=\"line-height:1em;\"><span style=\"letter-spacing:0.2em;\"><span style=\"font-family:dinneuzeitgroteskltw01-_812426,sans-serif;\"><span class=\"color_11\">3</span></span></span><span style=\"letter-spacing:0em;\"><span style=\"font-family:dinneuzeitgroteskltw01-_812426,sans-serif;\"><span class=\"color_11\"> </span></span></span><span style=\"letter-spacing:0.2em;\"><span style=\"font-family:dinneuzeitgroteskltw01-_812426,sans-serif;\"><span class=\"color_11\">DAYS</span></span></span></span></span></h2><h2 style=\"text-align: center; line-height: 1em; font-size: 33px;\" class=\"font_2\"><span style=\"font-size:33px;\"><span style=\"line-height:1em;\"><span style=\"letter-spacing:0.2em;\"><span style=\"font-family:dinneuzeitgroteskltw01-_812426,sans-serif;\"><span class=\"color_11\">LEFT!</span></span></span></span></span></h2>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew",
                            "mobileStructure": {
                                "type": "Component",
                                "layout": {
                                    "width": 174,
                                    "height": 87,
                                    "x": 50,
                                    "y": 38,
                                    "scale": 0.97,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "data": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<h2 style=\"font-size: 33px; text-align: center; line-height: 1em;\" class=\"font_2\"><span style=\"font-size:33px;\"><span style=\"line-height:1em;\"><span style=\"letter-spacing:0.2em;\"><span style=\"font-family:dinneuzeitgroteskltw01-_812426,sans-serif;\"><span class=\"color_11\">ONLY</span></span></span></span></span></h2><h2 style=\"font-size: 33px; text-align: center; line-height: 1em;\" class=\"font_2\"><span style=\"font-size:33px;\"><span style=\"line-height:1em;\"><span style=\"letter-spacing:0.2em;\"><span style=\"font-family:dinneuzeitgroteskltw01-_812426,sans-serif;\"><span class=\"color_11\">3</span></span></span><span style=\"letter-spacing:0em;\"><span style=\"font-family:dinneuzeitgroteskltw01-_812426,sans-serif;\"><span class=\"color_11\"> </span></span></span><span style=\"letter-spacing:0.2em;\"><span style=\"font-family:dinneuzeitgroteskltw01-_812426,sans-serif;\"><span class=\"color_11\">DAYS</span></span></span></span></span></h2><h2 style=\"text-align: center; line-height: 1em; font-size: 33px;\" class=\"font_2\"><span style=\"font-size:33px;\"><span style=\"line-height:1em;\"><span style=\"letter-spacing:0.2em;\"><span style=\"font-family:dinneuzeitgroteskltw01-_812426,sans-serif;\"><span class=\"color_11\">LEFT!</span></span></span></span></span></h2>",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "props": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                },
                                "style": "txtNew"
                            }
                        }],
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "alpha-bg": "1",
                                    "alpha-brd": "1",
                                    "bg": "#000000",
                                    "boxShadowToggleOn-shd": "false",
                                    "brd": "#2F2E2E",
                                    "brw": "0",
                                    "rd": "0px",
                                    "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                                },
                                "propertiesSource": {
                                    "alpha-bg": "value",
                                    "bg": "value",
                                    "brd": "value",
                                    "brw": "value",
                                    "rd": "value",
                                    "shd": "value"
                                }
                            },
                            "componentClassName": "mobile.core.components.Container",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                        },
                        "mobileStructure": {
                            "type": "Container",
                            "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                            "layout": {
                                "width": 280,
                                "height": 272,
                                "x": 0,
                                "y": 0,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "mobile.core.components.Container",
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {
                                        "alpha-bg": "1",
                                        "alpha-brd": "1",
                                        "bg": "#000000",
                                        "boxShadowToggleOn-shd": "false",
                                        "brd": "#2F2E2E",
                                        "brw": "0",
                                        "rd": "0px",
                                        "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                                    },
                                    "propertiesSource": {
                                        "alpha-bg": "value",
                                        "bg": "value",
                                        "brd": "value",
                                        "brw": "value",
                                        "rd": "value",
                                        "shd": "value"
                                    }
                                },
                                "componentClassName": "mobile.core.components.Container",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                            }
                        }
                    }],
                    "data": {
                        "type": "BoxSlideShowSlide",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "3"})
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-brd": "1",
                                "bg": "#001F2C",
                                "brd": "color_11",
                                "brw": "0",
                                "rd": "0px"
                            },
                            "propertiesSource": {"bg": "value"}
                        },
                        "componentClassName": "wysiwyg.viewer.components.BoxSlideShowSlide",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "mediaRef": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "title": "Vintage Car",
                                "uri": "a72089407f6a4510b09c2aecc01f0b8f.jpg",
                                "description": "public/491df47b-5958-4dc7-9029-37f428755d45/c2a37dfc-36cf-4bfd-b940-55bac7bdc9e6",
                                "width": 5760,
                                "height": 3840,
                                "alt": "",
                                "artist": {"id": "", "name": ""},
                                "opacity": 1
                            },
                            "color": "#000000",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "mobileStructure": {
                        "type": "Container",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                        "layout": {
                            "width": 280,
                            "height": 542,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.BoxSlideShowSlide",
                        "data": {
                            "type": "BoxSlideShowSlide",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "3"})
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "alpha-brd": "1",
                                    "bg": "#001F2C",
                                    "brd": "color_11",
                                    "brw": "0",
                                    "rd": "0px"
                                },
                                "propertiesSource": {"bg": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.BoxSlideShowSlide",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "mediaRef": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "title": "Vintage Car",
                                    "uri": "a72089407f6a4510b09c2aecc01f0b8f.jpg",
                                    "description": "public/491df47b-5958-4dc7-9029-37f428755d45/c2a37dfc-36cf-4bfd-b940-55bac7bdc9e6",
                                    "width": 5760,
                                    "height": 3840,
                                    "alt": "",
                                    "artist": {"id": "", "name": ""},
                                    "opacity": 1
                                },
                                "color": "#000000",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            }
                        }
                    }
                }],
                "props": {
                    "type": "BoxSlideShowProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "transition": "SlideHorizontal",
                    "autoPlayInterval": 3,
                    "autoPlay": true,
                    "transDuration": 1,
                    "pauseAutoPlayOnMouseOver": true,
                    "direction": "RTL",
                    "shouldHideOverflowContent": true,
                    "flexibleBoxHeight": false,
                    "showNavigationButton": true,
                    "showNavigationDots": true,
                    "navigationButtonSize": 20,
                    "navigationButtonMargin": 32,
                    "navigationDotsSize": 6,
                    "navigationDotsMargin": 26,
                    "navigationDotsGap": 19,
                    "navigationDotsAlignment": "center"
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-arrowColor": "1",
                            "alpha-bg": "1",
                            "alpha-brd": "1",
                            "alpha-dotsColor": "1",
                            "bg": "#FFFFFF",
                            "boxShadowToggleOn-shd": "true",
                            "brd": "#FFFFFF",
                            "brw": "0",
                            "shd": "0 0 0 rgba(0, 0, 0, 0.6)"
                        },
                        "propertiesSource": {
                            "alpha-arrowColor": "value",
                            "alpha-bg": "value",
                            "alpha-brd": "value",
                            "alpha-dotsColor": "value",
                            "arrowColor": "value",
                            "bg": "value",
                            "boxShadowToggleOn-shd": "value",
                            "brd": "value",
                            "brw": "value",
                            "dotsColor": "value",
                            "shd": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.BoxSlideShow",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.common.components.boxSlideShow.viewer.skins.thinArrowsLargeSelectedCircleSkin"
                },
                "mobileStructure": {
                    "type": "Container",
                    "skin": "wysiwyg.common.components.boxSlideShow.viewer.skins.thinArrowsLargeSelectedCircleSkin",
                    "layout": {
                        "width": 280,
                        "height": 542,
                        "x": 0,
                        "y": 888,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.BoxSlideShow",
                    "props": {
                        "type": "BoxSlideShowProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "transition": "SlideHorizontal",
                        "autoPlayInterval": 2,
                        "autoPlay": false,
                        "transDuration": 1,
                        "pauseAutoPlayOnMouseOver": true,
                        "direction": "RTL",
                        "shouldHideOverflowContent": true,
                        "flexibleBoxHeight": false,
                        "showNavigationButton": true,
                        "showNavigationDots": true,
                        "navigationButtonSize": 16,
                        "navigationButtonMargin": 22,
                        "navigationDotsSize": 6,
                        "navigationDotsMargin": 24,
                        "navigationDotsGap": 15,
                        "navigationDotsAlignment": "center"
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-arrowColor": "1",
                                "alpha-bg": "1",
                                "alpha-brd": "1",
                                "alpha-dotsColor": "1",
                                "bg": "#FFFFFF",
                                "boxShadowToggleOn-shd": "true",
                                "brd": "#FFFFFF",
                                "brw": "0",
                                "shd": "0 0 0 rgba(0, 0, 0, 0.6)"
                            },
                            "propertiesSource": {
                                "alpha-arrowColor": "value",
                                "alpha-bg": "value",
                                "alpha-brd": "value",
                                "alpha-dotsColor": "value",
                                "arrowColor": "value",
                                "bg": "value",
                                "boxShadowToggleOn-shd": "value",
                                "brd": "value",
                                "brw": "value",
                                "dotsColor": "value",
                                "shd": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.viewer.components.BoxSlideShow",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.boxSlideShow.viewer.skins.thinArrowsLargeSelectedCircleSkin"
                    }
                },
                "id": "comp-im1mze7v"
            },
            "preset": {
                "rect": {"width": 324, "height": 129.5, "x": 0, "y": 300.75},
                "label": "add_preset_BoxSlideShow_980_3",
                "tags": null
            }
        }, {
            "id": "BoxSlideShow_BoxSlideShow980_4",
            "structure": {
                "type": "Container",
                "skin": "wysiwyg.common.components.boxSlideShow.viewer.skins.longArrowsLargeSelectedCircleSkin",
                "layout": {
                    "width": 980,
                    "height": 460,
                    "x": 0,
                    "y": 1801,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.BoxSlideShow",
                "components": [{
                    "type": "Container",
                    "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                    "layout": {
                        "width": 980,
                        "height": 460,
                        "x": 0,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.BoxSlideShowSlide",
                    "components": [{
                        "type": "Container",
                        "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                        "layout": {
                            "width": 490,
                            "height": 460,
                            "x": 490,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "mobile.core.components.Container",
                        "components": [{
                            "type": "Component",
                            "layout": {
                                "width": 285,
                                "height": 29,
                                "x": 98,
                                "y": 120,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 style=\"font-size: 26px; text-align: center; line-height: 1em;\" class=\"font_2\"><span style=\"font-size:26px;\"><span style=\"letter-spacing:0.6em;\"><span class=\"color_2\"><span style=\"font-family:helvetica-w01-light,helvetica-w02-light,sans-serif;\">SPRING</span></span></span></span></h2>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew",
                            "mobileStructure": {
                                "type": "Component",
                                "layout": {
                                    "width": 206,
                                    "height": 20,
                                    "x": 38,
                                    "y": 42,
                                    "scale": 0.7106410696261031,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "data": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<h2 style=\"font-size: 26px; text-align: center; line-height: 1em;\" class=\"font_2\"><span style=\"font-size:26px;\"><span style=\"letter-spacing:0.6em;\"><span class=\"color_2\"><span style=\"font-family:helvetica-w01-light,helvetica-w02-light,sans-serif;\">SPRING</span></span></span></span></h2>",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "props": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                },
                                "style": "txtNew"
                            }
                        }, {
                            "type": "Container",
                            "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                            "layout": {
                                "width": 170,
                                "height": 35,
                                "x": 147,
                                "y": 230,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "mobile.core.components.Container",
                            "components": [],
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {
                                        "alpha-bg": "1",
                                        "alpha-brd": "1",
                                        "bg": "#DEBCB1",
                                        "boxShadowToggleOn-shd": "false",
                                        "brd": "#2F2E2E",
                                        "brw": "0",
                                        "rd": "0px",
                                        "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                                    },
                                    "propertiesSource": {
                                        "alpha-bg": "value",
                                        "bg": "value",
                                        "brd": "value",
                                        "brw": "value",
                                        "rd": "value",
                                        "shd": "value"
                                    }
                                },
                                "componentClassName": "mobile.core.components.Container",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                            },
                            "mobileStructure": {
                                "type": "Container",
                                "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                                "layout": {
                                    "width": 206,
                                    "height": 28,
                                    "x": 37,
                                    "y": 123,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "mobile.core.components.Container",
                                "style": {
                                    "type": "TopLevelStyle",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "style": {
                                        "groups": {},
                                        "properties": {
                                            "alpha-bg": "1",
                                            "alpha-brd": "1",
                                            "bg": "#DEBCB1",
                                            "boxShadowToggleOn-shd": "false",
                                            "brd": "#2F2E2E",
                                            "brw": "0",
                                            "rd": "0px",
                                            "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                                        },
                                        "propertiesSource": {
                                            "alpha-bg": "value",
                                            "bg": "value",
                                            "brd": "value",
                                            "brw": "value",
                                            "rd": "value",
                                            "shd": "value"
                                        }
                                    },
                                    "componentClassName": "mobile.core.components.Container",
                                    "pageId": "",
                                    "compId": "",
                                    "styleType": "custom",
                                    "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                                }
                            }
                        }, {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.button.BasicButton",
                            "layout": {
                                "width": 132,
                                "height": 35,
                                "x": 167,
                                "y": 326,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.SiteButton",
                            "data": {
                                "type": "LinkableButton",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "label": "SHOP NOW"
                            },
                            "props": {
                                "type": "ButtonProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "align": "center",
                                "margin": 0
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {
                                        "alpha-bg": "0",
                                        "alpha-bgh": "1",
                                        "alpha-brd": "1",
                                        "alpha-brdh": "1",
                                        "bg": "#FFFFFF",
                                        "bgh": "#000000",
                                        "boxShadowToggleOn-shd": "false",
                                        "brd": "#000000",
                                        "brdh": "#2F2E2E",
                                        "brw": "1",
                                        "fnt": "normal normal normal 14px/1.4em helvetica-w01-light",
                                        "rd": "0",
                                        "shd": "0 1px 4px rgba(0, 0, 0, 0.6)",
                                        "txt": "#000000",
                                        "txth": "#EFDBD4"
                                    },
                                    "propertiesSource": {
                                        "alpha-bg": "value",
                                        "alpha-bgh": "value",
                                        "alpha-brd": "value",
                                        "alpha-brdh": "value",
                                        "bg": "value",
                                        "bgh": "value",
                                        "boxShadowToggleOn-shd": "value",
                                        "brd": "value",
                                        "brdh": "value",
                                        "brw": "value",
                                        "fnt": "value",
                                        "rd": "value",
                                        "shd": "value",
                                        "txt": "value",
                                        "txth": "value"
                                    }
                                },
                                "componentClassName": "wysiwyg.viewer.components.SiteButton",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.button.BasicButton"
                            },
                            "mobileStructure": {
                                "type": "Component",
                                "skin": "wysiwyg.viewer.skins.button.BasicButton",
                                "layout": {
                                    "width": 135,
                                    "height": 35,
                                    "x": 73,
                                    "y": 168,
                                    "scale": 0.9070294784580498,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.SiteButton",
                                "data": {
                                    "type": "LinkableButton",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "label": "SHOP NOW"
                                },
                                "props": {
                                    "type": "ButtonProperties",
                                    "metaData": {"schemaVersion": "1.0"},
                                    "align": "center",
                                    "margin": 0
                                },
                                "style": {
                                    "type": "TopLevelStyle",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "style": {
                                        "groups": {},
                                        "properties": {
                                            "alpha-bg": "0",
                                            "alpha-bgh": "1",
                                            "alpha-brd": "1",
                                            "alpha-brdh": "1",
                                            "bg": "#FFFFFF",
                                            "bgh": "#000000",
                                            "boxShadowToggleOn-shd": "false",
                                            "brd": "#000000",
                                            "brdh": "#2F2E2E",
                                            "brw": "1",
                                            "fnt": "normal normal normal 14px/1.4em helvetica-w01-light",
                                            "rd": "0",
                                            "shd": "0 1px 4px rgba(0, 0, 0, 0.6)",
                                            "txt": "#000000",
                                            "txth": "#EFDBD4"
                                        },
                                        "propertiesSource": {
                                            "alpha-bg": "value",
                                            "alpha-bgh": "value",
                                            "alpha-brd": "value",
                                            "alpha-brdh": "value",
                                            "bg": "value",
                                            "bgh": "value",
                                            "boxShadowToggleOn-shd": "value",
                                            "brd": "value",
                                            "brdh": "value",
                                            "brw": "value",
                                            "fnt": "value",
                                            "rd": "value",
                                            "shd": "value",
                                            "txt": "value",
                                            "txth": "value"
                                        }
                                    },
                                    "componentClassName": "wysiwyg.viewer.components.SiteButton",
                                    "pageId": "",
                                    "compId": "",
                                    "styleType": "custom",
                                    "skin": "wysiwyg.viewer.skins.button.BasicButton"
                                }
                            }
                        }, {
                            "type": "Component",
                            "layout": {
                                "width": 285,
                                "height": 77,
                                "x": 91,
                                "y": 151,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 style=\"font-size: 69px; text-align: center; line-height: 1em;\" class=\"font_2\"><span style=\"font-size:69px;\"><span style=\"letter-spacing:0.03em;\"><span class=\"color_2\"><span style=\"font-family:helvetica-w01-light,helvetica-w02-light,sans-serif;\">SALE</span></span></span></span></h2>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew",
                            "mobileStructure": {
                                "type": "Component",
                                "layout": {
                                    "width": 206,
                                    "height": 56,
                                    "x": 37,
                                    "y": 63,
                                    "scale": 1.2528583813530183,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "data": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<h2 style=\"font-size: 69px; text-align: center; line-height: 1em;\" class=\"font_2\"><span style=\"font-size:69px;\"><span style=\"letter-spacing:0.03em;\"><span class=\"color_2\"><span style=\"font-family:helvetica-w01-light,helvetica-w02-light,sans-serif;\">SALE</span></span></span></span></h2>",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "props": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                },
                                "style": "txtNew"
                            }
                        }, {
                            "type": "Component",
                            "layout": {
                                "width": 285,
                                "height": 20,
                                "x": 91,
                                "y": 238,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p style=\"text-align: center; line-height: 1em; font-size: 17px;\" class=\"font_8\"><span style=\"font-size:17px;\"><span style=\"letter-spacing:0.06em;\"><span class=\"color_2\"><span style=\"font-family:helvetica-w01-light,helvetica-w02-light,sans-serif;\">Save Up to 50%</span></span></span></span></p>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew",
                            "mobileStructure": {
                                "type": "Component",
                                "layout": {
                                    "width": 186,
                                    "height": 17,
                                    "x": 11,
                                    "y": 6,
                                    "scale": 0.8588475,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "data": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<p style=\"text-align: center; line-height: 1em; font-size: 17px;\" class=\"font_8\"><span style=\"font-size:17px;\"><span style=\"letter-spacing:0.06em;\"><span class=\"color_2\"><span style=\"font-family:helvetica-w01-light,helvetica-w02-light,sans-serif;\">Save Up to 50%</span></span></span></span></p>",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "props": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                },
                                "style": "txtNew"
                            }
                        }],
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "alpha-bg": "1",
                                    "alpha-brd": "1",
                                    "bg": "#EFDBD4",
                                    "boxShadowToggleOn-shd": "false",
                                    "brd": "#2F2E2E",
                                    "brw": "0",
                                    "rd": "0px",
                                    "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                                },
                                "propertiesSource": {
                                    "alpha-bg": "value",
                                    "bg": "value",
                                    "brd": "value",
                                    "brw": "value",
                                    "rd": "value",
                                    "shd": "value"
                                }
                            },
                            "componentClassName": "mobile.core.components.Container",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                        },
                        "mobileStructure": {
                            "type": "Container",
                            "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                            "layout": {
                                "width": 280,
                                "height": 234,
                                "x": 0,
                                "y": 192,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "mobile.core.components.Container",
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {
                                        "alpha-bg": "1",
                                        "alpha-brd": "1",
                                        "bg": "#EFDBD4",
                                        "boxShadowToggleOn-shd": "false",
                                        "brd": "#2F2E2E",
                                        "brw": "0",
                                        "rd": "0px",
                                        "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                                    },
                                    "propertiesSource": {
                                        "alpha-bg": "value",
                                        "bg": "value",
                                        "brd": "value",
                                        "brw": "value",
                                        "rd": "value",
                                        "shd": "value"
                                    }
                                },
                                "componentClassName": "mobile.core.components.Container",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                            }
                        }
                    }],
                    "data": {
                        "type": "BoxSlideShowSlide",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "1"})
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-brd": "1",
                                "bg": "#001F2C",
                                "brd": "color_11",
                                "brw": "0",
                                "rd": "0px"
                            },
                            "propertiesSource": {"bg": "value"}
                        },
                        "componentClassName": "wysiwyg.viewer.components.BoxSlideShowSlide",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "mediaRef": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "title": "Model_slide3.jpg",
                                "uri": "035244_21a6d9f2a5794a8bae2a2d5e3a78f9e5.jpg",
                                "description": "private/allMedia_picture",
                                "width": 3840,
                                "height": 2160,
                                "alt": "",
                                "artist": {"id": "", "name": ""}
                            },
                            "color": "#FFFFFF",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "{color_15}",
                            "colorOverlayOpacity": 0.21
                        }
                    },
                    "mobileStructure": {
                        "type": "Container",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                        "layout": {
                            "width": 280,
                            "height": 426,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.BoxSlideShowSlide",
                        "data": {
                            "type": "BoxSlideShowSlide",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "1"})
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "alpha-brd": "1",
                                    "bg": "#001F2C",
                                    "brd": "color_11",
                                    "brw": "0",
                                    "rd": "0px"
                                },
                                "propertiesSource": {"bg": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.BoxSlideShowSlide",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "mediaRef": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "title": "Model_slide3.jpg",
                                    "uri": "035244_21a6d9f2a5794a8bae2a2d5e3a78f9e5.jpg",
                                    "description": "private/allMedia_picture",
                                    "width": 3840,
                                    "height": 2160,
                                    "alt": "",
                                    "artist": {"id": "", "name": ""}
                                },
                                "color": "#FFFFFF",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "{color_15}",
                                "colorOverlayOpacity": 0.21
                            }
                        }
                    }
                }, {
                    "type": "Container",
                    "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                    "layout": {
                        "width": 980,
                        "height": 460,
                        "x": 0,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.BoxSlideShowSlide",
                    "components": [{
                        "type": "Container",
                        "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                        "layout": {
                            "width": 490,
                            "height": 460,
                            "x": 490,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "mobile.core.components.Container",
                        "components": [{
                            "type": "Component",
                            "layout": {
                                "width": 285,
                                "height": 29,
                                "x": 98,
                                "y": 120,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 style=\"font-size: 26px; text-align: center; line-height: 1em;\" class=\"font_2\"><span style=\"font-size:26px;\"><span style=\"letter-spacing:0.6em;\"><span class=\"color_2\"><span style=\"font-family:helvetica-w01-light,helvetica-w02-light,sans-serif;\">SPRING</span></span></span></span></h2>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew",
                            "mobileStructure": {
                                "type": "Component",
                                "layout": {
                                    "width": 206,
                                    "height": 20,
                                    "x": 38,
                                    "y": 42,
                                    "scale": 0.69,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "data": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<h2 style=\"font-size: 26px; text-align: center; line-height: 1em;\" class=\"font_2\"><span style=\"font-size:26px;\"><span style=\"letter-spacing:0.6em;\"><span class=\"color_2\"><span style=\"font-family:helvetica-w01-light,helvetica-w02-light,sans-serif;\">SPRING</span></span></span></span></h2>",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "props": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                },
                                "style": "txtNew"
                            }
                        }, {
                            "type": "Container",
                            "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                            "layout": {
                                "width": 170,
                                "height": 35,
                                "x": 147,
                                "y": 230,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "mobile.core.components.Container",
                            "components": [],
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {
                                        "alpha-bg": "1",
                                        "alpha-brd": "1",
                                        "bg": "#DEBCB1",
                                        "boxShadowToggleOn-shd": "false",
                                        "brd": "#2F2E2E",
                                        "brw": "0",
                                        "rd": "0px",
                                        "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                                    },
                                    "propertiesSource": {
                                        "alpha-bg": "value",
                                        "bg": "value",
                                        "brd": "value",
                                        "brw": "value",
                                        "rd": "value",
                                        "shd": "value"
                                    }
                                },
                                "componentClassName": "mobile.core.components.Container",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                            },
                            "mobileStructure": {
                                "type": "Container",
                                "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                                "layout": {
                                    "width": 206,
                                    "height": 28,
                                    "x": 37,
                                    "y": 123,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "mobile.core.components.Container",
                                "style": {
                                    "type": "TopLevelStyle",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "style": {
                                        "groups": {},
                                        "properties": {
                                            "alpha-bg": "1",
                                            "alpha-brd": "1",
                                            "bg": "#DEBCB1",
                                            "boxShadowToggleOn-shd": "false",
                                            "brd": "#2F2E2E",
                                            "brw": "0",
                                            "rd": "0px",
                                            "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                                        },
                                        "propertiesSource": {
                                            "alpha-bg": "value",
                                            "bg": "value",
                                            "brd": "value",
                                            "brw": "value",
                                            "rd": "value",
                                            "shd": "value"
                                        }
                                    },
                                    "componentClassName": "mobile.core.components.Container",
                                    "pageId": "",
                                    "compId": "",
                                    "styleType": "custom",
                                    "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                                }
                            }
                        }, {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.button.BasicButton",
                            "layout": {
                                "width": 132,
                                "height": 35,
                                "x": 167,
                                "y": 326,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.SiteButton",
                            "data": {
                                "type": "LinkableButton",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "label": "ON SALE"
                            },
                            "props": {
                                "type": "ButtonProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "align": "center",
                                "margin": 0
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {
                                        "alpha-bg": "0",
                                        "alpha-bgh": "1",
                                        "alpha-brd": "1",
                                        "alpha-brdh": "1",
                                        "bg": "#FFFFFF",
                                        "bgh": "#000000",
                                        "boxShadowToggleOn-shd": "false",
                                        "brd": "#000000",
                                        "brdh": "#2F2E2E",
                                        "brw": "1",
                                        "fnt": "normal normal normal 14px/1.4em helvetica-w01-light",
                                        "rd": "0",
                                        "shd": "0 1px 4px rgba(0, 0, 0, 0.6)",
                                        "txt": "#000000",
                                        "txth": "#EFDBD4"
                                    },
                                    "propertiesSource": {
                                        "alpha-bg": "value",
                                        "alpha-bgh": "value",
                                        "alpha-brd": "value",
                                        "alpha-brdh": "value",
                                        "bg": "value",
                                        "bgh": "value",
                                        "boxShadowToggleOn-shd": "value",
                                        "brd": "value",
                                        "brdh": "value",
                                        "brw": "value",
                                        "fnt": "value",
                                        "rd": "value",
                                        "shd": "value",
                                        "txt": "value",
                                        "txth": "value"
                                    }
                                },
                                "componentClassName": "wysiwyg.viewer.components.SiteButton",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.button.BasicButton"
                            },
                            "mobileStructure": {
                                "type": "Component",
                                "skin": "wysiwyg.viewer.skins.button.BasicButton",
                                "layout": {
                                    "width": 135,
                                    "height": 35,
                                    "x": 73,
                                    "y": 168,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.SiteButton",
                                "data": {
                                    "type": "LinkableButton",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "label": "ON SALE"
                                },
                                "props": {
                                    "type": "ButtonProperties",
                                    "metaData": {"schemaVersion": "1.0"},
                                    "align": "center",
                                    "margin": 0
                                },
                                "style": {
                                    "type": "TopLevelStyle",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "style": {
                                        "groups": {},
                                        "properties": {
                                            "alpha-bg": "0",
                                            "alpha-bgh": "1",
                                            "alpha-brd": "1",
                                            "alpha-brdh": "1",
                                            "bg": "#FFFFFF",
                                            "bgh": "#000000",
                                            "boxShadowToggleOn-shd": "false",
                                            "brd": "#000000",
                                            "brdh": "#2F2E2E",
                                            "brw": "1",
                                            "fnt": "normal normal normal 14px/1.4em helvetica-w01-light",
                                            "rd": "0",
                                            "shd": "0 1px 4px rgba(0, 0, 0, 0.6)",
                                            "txt": "#000000",
                                            "txth": "#EFDBD4"
                                        },
                                        "propertiesSource": {
                                            "alpha-bg": "value",
                                            "alpha-bgh": "value",
                                            "alpha-brd": "value",
                                            "alpha-brdh": "value",
                                            "bg": "value",
                                            "bgh": "value",
                                            "boxShadowToggleOn-shd": "value",
                                            "brd": "value",
                                            "brdh": "value",
                                            "brw": "value",
                                            "fnt": "value",
                                            "rd": "value",
                                            "shd": "value",
                                            "txt": "value",
                                            "txth": "value"
                                        }
                                    },
                                    "componentClassName": "wysiwyg.viewer.components.SiteButton",
                                    "pageId": "",
                                    "compId": "",
                                    "styleType": "custom",
                                    "skin": "wysiwyg.viewer.skins.button.BasicButton"
                                }
                            }
                        }, {
                            "type": "Component",
                            "layout": {
                                "width": 285,
                                "height": 77,
                                "x": 91,
                                "y": 151,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 style=\"font-size: 69px; text-align: center; line-height: 1em;\" class=\"font_2\"><span style=\"font-size:69px;\"><span style=\"letter-spacing:0.03em;\"><span class=\"color_2\"><span style=\"font-family:helvetica-w01-light,helvetica-w02-light,sans-serif;\">SALE</span></span></span></span></h2>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew",
                            "mobileStructure": {
                                "type": "Component",
                                "layout": {
                                    "width": 206,
                                    "height": 56,
                                    "x": 37,
                                    "y": 63,
                                    "scale": 1.25,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "data": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<h2 style=\"font-size: 69px; text-align: center; line-height: 1em;\" class=\"font_2\"><span style=\"font-size:69px;\"><span style=\"letter-spacing:0.03em;\"><span class=\"color_2\"><span style=\"font-family:helvetica-w01-light,helvetica-w02-light,sans-serif;\">SALE</span></span></span></span></h2>",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "props": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                },
                                "style": "txtNew"
                            }
                        }, {
                            "type": "Component",
                            "layout": {
                                "width": 285,
                                "height": 20,
                                "x": 91,
                                "y": 238,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p style=\"text-align: center; line-height: 1em; font-size: 18px;\" class=\"font_8\"><span style=\"font-size:17px;\"><span style=\"letter-spacing:0.06em;\"><span class=\"color_2\"><span style=\"font-family:helvetica-w01-light,helvetica-w02-light,sans-serif;\">Save Up to 50%</span></span></span></span></p>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew",
                            "mobileStructure": {
                                "type": "Component",
                                "layout": {
                                    "width": 186,
                                    "height": 17,
                                    "x": 11,
                                    "y": 6,
                                    "scale": 0.8580044117647061,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "data": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<p style=\"text-align: center; line-height: 1em; font-size: 18px;\" class=\"font_8\"><span style=\"font-size:17px;\"><span style=\"letter-spacing:0.06em;\"><span class=\"color_2\"><span style=\"font-family:helvetica-w01-light,helvetica-w02-light,sans-serif;\">Save Up to 50%</span></span></span></span></p>",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "props": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                },
                                "style": "txtNew"
                            }
                        }],
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "alpha-bg": "1",
                                    "alpha-brd": "1",
                                    "bg": "#EFDBD4",
                                    "boxShadowToggleOn-shd": "false",
                                    "brd": "#2F2E2E",
                                    "brw": "0",
                                    "rd": "0px",
                                    "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                                },
                                "propertiesSource": {
                                    "alpha-bg": "value",
                                    "bg": "value",
                                    "brd": "value",
                                    "brw": "value",
                                    "rd": "value",
                                    "shd": "value"
                                }
                            },
                            "componentClassName": "mobile.core.components.Container",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                        },
                        "mobileStructure": {
                            "type": "Container",
                            "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                            "layout": {
                                "width": 280,
                                "height": 234,
                                "x": 0,
                                "y": 192,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "mobile.core.components.Container",
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {
                                        "alpha-bg": "1",
                                        "alpha-brd": "1",
                                        "bg": "#EFDBD4",
                                        "boxShadowToggleOn-shd": "false",
                                        "brd": "#2F2E2E",
                                        "brw": "0",
                                        "rd": "0px",
                                        "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                                    },
                                    "propertiesSource": {
                                        "alpha-bg": "value",
                                        "bg": "value",
                                        "brd": "value",
                                        "brw": "value",
                                        "rd": "value",
                                        "shd": "value"
                                    }
                                },
                                "componentClassName": "mobile.core.components.Container",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                            }
                        }
                    }],
                    "data": {
                        "type": "BoxSlideShowSlide",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "2"})
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-brd": "1",
                                "bg": "#001F2C",
                                "brd": "color_11",
                                "brw": "0",
                                "rd": "0px"
                            },
                            "propertiesSource": {"bg": "value"}
                        },
                        "componentClassName": "wysiwyg.viewer.components.BoxSlideShowSlide",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "mediaRef": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "title": "Model_slide.jpg",
                                "uri": "035244_0acf0c9bca5f47d39a55762fed6d7f5d.jpg",
                                "description": "private/allMedia_picture",
                                "width": 3840,
                                "height": 2160,
                                "alt": "",
                                "artist": {"id": "", "name": ""}
                            },
                            "color": "#000000",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "mobileStructure": {
                        "type": "Container",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                        "layout": {
                            "width": 280,
                            "height": 426,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.BoxSlideShowSlide",
                        "data": {
                            "type": "BoxSlideShowSlide",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "2"})
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "alpha-brd": "1",
                                    "bg": "#001F2C",
                                    "brd": "color_11",
                                    "brw": "0",
                                    "rd": "0px"
                                },
                                "propertiesSource": {"bg": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.BoxSlideShowSlide",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "mediaRef": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "title": "Model_slide.jpg",
                                    "uri": "035244_0acf0c9bca5f47d39a55762fed6d7f5d.jpg",
                                    "description": "private/allMedia_picture",
                                    "width": 3840,
                                    "height": 2160,
                                    "alt": "",
                                    "artist": {"id": "", "name": ""}
                                },
                                "color": "#000000",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            }
                        }
                    }
                }, {
                    "type": "Container",
                    "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                    "layout": {
                        "width": 980,
                        "height": 460,
                        "x": 0,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.BoxSlideShowSlide",
                    "components": [{
                        "type": "Container",
                        "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                        "layout": {
                            "width": 490,
                            "height": 460,
                            "x": 490,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "mobile.core.components.Container",
                        "components": [{
                            "type": "Component",
                            "layout": {
                                "width": 285,
                                "height": 29,
                                "x": 98,
                                "y": 120,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 style=\"font-size: 26px; text-align: center; line-height: 1em;\" class=\"font_2\"><span style=\"font-size:26px;\"><span style=\"letter-spacing:0.6em;\"><span class=\"color_2\"><span style=\"font-family:helvetica-w01-light,helvetica-w02-light,sans-serif;\">SPRING</span></span></span></span></h2>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew",
                            "mobileStructure": {
                                "type": "Component",
                                "layout": {
                                    "width": 206,
                                    "height": 20,
                                    "x": 38,
                                    "y": 42,
                                    "scale": 0.69,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "data": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<h2 style=\"font-size: 26px; text-align: center; line-height: 1em;\" class=\"font_2\"><span style=\"font-size:26px;\"><span style=\"letter-spacing:0.6em;\"><span class=\"color_2\"><span style=\"font-family:helvetica-w01-light,helvetica-w02-light,sans-serif;\">SPRING</span></span></span></span></h2>",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "props": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                },
                                "style": "txtNew"
                            }
                        }, {
                            "type": "Container",
                            "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                            "layout": {
                                "width": 170,
                                "height": 35,
                                "x": 147,
                                "y": 230,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "mobile.core.components.Container",
                            "components": [],
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {
                                        "alpha-bg": "1",
                                        "alpha-brd": "1",
                                        "bg": "#DEBCB1",
                                        "boxShadowToggleOn-shd": "false",
                                        "brd": "#2F2E2E",
                                        "brw": "0",
                                        "rd": "0px",
                                        "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                                    },
                                    "propertiesSource": {
                                        "alpha-bg": "value",
                                        "bg": "value",
                                        "brd": "value",
                                        "brw": "value",
                                        "rd": "value",
                                        "shd": "value"
                                    }
                                },
                                "componentClassName": "mobile.core.components.Container",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                            },
                            "mobileStructure": {
                                "type": "Container",
                                "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                                "layout": {
                                    "width": 206,
                                    "height": 28,
                                    "x": 37,
                                    "y": 123,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "mobile.core.components.Container",
                                "style": {
                                    "type": "TopLevelStyle",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "style": {
                                        "groups": {},
                                        "properties": {
                                            "alpha-bg": "1",
                                            "alpha-brd": "1",
                                            "bg": "#DEBCB1",
                                            "boxShadowToggleOn-shd": "false",
                                            "brd": "#2F2E2E",
                                            "brw": "0",
                                            "rd": "0px",
                                            "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                                        },
                                        "propertiesSource": {
                                            "alpha-bg": "value",
                                            "bg": "value",
                                            "brd": "value",
                                            "brw": "value",
                                            "rd": "value",
                                            "shd": "value"
                                        }
                                    },
                                    "componentClassName": "mobile.core.components.Container",
                                    "pageId": "",
                                    "compId": "",
                                    "styleType": "custom",
                                    "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                                }
                            }
                        }, {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.button.BasicButton",
                            "layout": {
                                "width": 132,
                                "height": 35,
                                "x": 166,
                                "y": 326,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.SiteButton",
                            "data": {
                                "type": "LinkableButton",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "label": "BUY ME"
                            },
                            "props": {
                                "type": "ButtonProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "align": "center",
                                "margin": 0
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {
                                        "alpha-bg": "0",
                                        "alpha-bgh": "1",
                                        "alpha-brd": "1",
                                        "alpha-brdh": "1",
                                        "bg": "#FFFFFF",
                                        "bgh": "#000000",
                                        "boxShadowToggleOn-shd": "false",
                                        "brd": "#000000",
                                        "brdh": "#2F2E2E",
                                        "brw": "1",
                                        "fnt": "normal normal normal 14px/1.4em helvetica-w01-light",
                                        "rd": "0",
                                        "shd": "0 1px 4px rgba(0, 0, 0, 0.6)",
                                        "txt": "#000000",
                                        "txth": "#EFDBD4"
                                    },
                                    "propertiesSource": {
                                        "alpha-bg": "value",
                                        "alpha-bgh": "value",
                                        "alpha-brd": "value",
                                        "alpha-brdh": "value",
                                        "bg": "value",
                                        "bgh": "value",
                                        "boxShadowToggleOn-shd": "value",
                                        "brd": "value",
                                        "brdh": "value",
                                        "brw": "value",
                                        "fnt": "value",
                                        "rd": "value",
                                        "shd": "value",
                                        "txt": "value",
                                        "txth": "value"
                                    }
                                },
                                "componentClassName": "wysiwyg.viewer.components.SiteButton",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.button.BasicButton"
                            },
                            "mobileStructure": {
                                "type": "Component",
                                "skin": "wysiwyg.viewer.skins.button.BasicButton",
                                "layout": {
                                    "width": 135,
                                    "height": 35,
                                    "x": 73,
                                    "y": 168,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.SiteButton",
                                "data": {
                                    "type": "LinkableButton",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "label": "BUY ME"
                                },
                                "props": {
                                    "type": "ButtonProperties",
                                    "metaData": {"schemaVersion": "1.0"},
                                    "align": "center",
                                    "margin": 0
                                },
                                "style": {
                                    "type": "TopLevelStyle",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "style": {
                                        "groups": {},
                                        "properties": {
                                            "alpha-bg": "0",
                                            "alpha-bgh": "1",
                                            "alpha-brd": "1",
                                            "alpha-brdh": "1",
                                            "bg": "#FFFFFF",
                                            "bgh": "#000000",
                                            "boxShadowToggleOn-shd": "false",
                                            "brd": "#000000",
                                            "brdh": "#2F2E2E",
                                            "brw": "1",
                                            "fnt": "normal normal normal 14px/1.4em helvetica-w01-light",
                                            "rd": "0",
                                            "shd": "0 1px 4px rgba(0, 0, 0, 0.6)",
                                            "txt": "#000000",
                                            "txth": "#EFDBD4"
                                        },
                                        "propertiesSource": {
                                            "alpha-bg": "value",
                                            "alpha-bgh": "value",
                                            "alpha-brd": "value",
                                            "alpha-brdh": "value",
                                            "bg": "value",
                                            "bgh": "value",
                                            "boxShadowToggleOn-shd": "value",
                                            "brd": "value",
                                            "brdh": "value",
                                            "brw": "value",
                                            "fnt": "value",
                                            "rd": "value",
                                            "shd": "value",
                                            "txt": "value",
                                            "txth": "value"
                                        }
                                    },
                                    "componentClassName": "wysiwyg.viewer.components.SiteButton",
                                    "pageId": "",
                                    "compId": "",
                                    "styleType": "custom",
                                    "skin": "wysiwyg.viewer.skins.button.BasicButton"
                                }
                            }
                        }, {
                            "type": "Component",
                            "layout": {
                                "width": 285,
                                "height": 77,
                                "x": 91,
                                "y": 151,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 style=\"font-size: 69px; text-align: center; line-height: 1em;\" class=\"font_2\"><span style=\"font-size:69px;\"><span style=\"letter-spacing:0.03em;\"><span class=\"color_2\"><span style=\"font-family:helvetica-w01-light,helvetica-w02-light,sans-serif;\">SALE</span></span></span></span></h2>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew",
                            "mobileStructure": {
                                "type": "Component",
                                "layout": {
                                    "width": 206,
                                    "height": 56,
                                    "x": 37,
                                    "y": 63,
                                    "scale": 1.25,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "data": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<h2 style=\"font-size: 69px; text-align: center; line-height: 1em;\" class=\"font_2\"><span style=\"font-size:69px;\"><span style=\"letter-spacing:0.03em;\"><span class=\"color_2\"><span style=\"font-family:helvetica-w01-light,helvetica-w02-light,sans-serif;\">SALE</span></span></span></span></h2>",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "props": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                },
                                "style": "txtNew"
                            }
                        }, {
                            "type": "Component",
                            "layout": {
                                "width": 285,
                                "height": 20,
                                "x": 91,
                                "y": 238,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p style=\"text-align: center; line-height: 1em; font-size: 17px;\" class=\"font_8\"><span style=\"font-size:17px;\"><span style=\"letter-spacing:0.06em;\"><span class=\"color_2\"><span style=\"font-family:helvetica-w01-light,helvetica-w02-light,sans-serif;\">Save Up to 50%</span></span></span></span></p>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew",
                            "mobileStructure": {
                                "type": "Component",
                                "layout": {
                                    "width": 186,
                                    "height": 17,
                                    "x": 11,
                                    "y": 6,
                                    "scale": 0.8797950000000001,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "data": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<p style=\"text-align: center; line-height: 1em; font-size: 17px;\" class=\"font_8\"><span style=\"font-size:17px;\"><span style=\"letter-spacing:0.06em;\"><span class=\"color_2\"><span style=\"font-family:helvetica-w01-light,helvetica-w02-light,sans-serif;\">Save Up to 50%</span></span></span></span></p>",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "props": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                },
                                "style": "txtNew"
                            }
                        }],
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "alpha-bg": "1",
                                    "alpha-brd": "1",
                                    "bg": "#EFDBD4",
                                    "boxShadowToggleOn-shd": "false",
                                    "brd": "#2F2E2E",
                                    "brw": "0",
                                    "rd": "0px",
                                    "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                                },
                                "propertiesSource": {
                                    "alpha-bg": "value",
                                    "bg": "value",
                                    "brd": "value",
                                    "brw": "value",
                                    "rd": "value",
                                    "shd": "value"
                                }
                            },
                            "componentClassName": "mobile.core.components.Container",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                        },
                        "mobileStructure": {
                            "type": "Container",
                            "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                            "layout": {
                                "width": 280,
                                "height": 234,
                                "x": 0,
                                "y": 192,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "mobile.core.components.Container",
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {
                                        "alpha-bg": "1",
                                        "alpha-brd": "1",
                                        "bg": "#EFDBD4",
                                        "boxShadowToggleOn-shd": "false",
                                        "brd": "#2F2E2E",
                                        "brw": "0",
                                        "rd": "0px",
                                        "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                                    },
                                    "propertiesSource": {
                                        "alpha-bg": "value",
                                        "bg": "value",
                                        "brd": "value",
                                        "brw": "value",
                                        "rd": "value",
                                        "shd": "value"
                                    }
                                },
                                "componentClassName": "mobile.core.components.Container",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                            }
                        }
                    }],
                    "data": {
                        "type": "BoxSlideShowSlide",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "3"})
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-brd": "1",
                                "bg": "#001F2C",
                                "brd": "color_11",
                                "brw": "0",
                                "rd": "0px"
                            },
                            "propertiesSource": {"bg": "value"}
                        },
                        "componentClassName": "wysiwyg.viewer.components.BoxSlideShowSlide",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "mediaRef": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "title": "Model_slide2.jpg",
                                "uri": "035244_01807809643a4d2eb76af20b45f39bb7.jpg",
                                "description": "private/allMedia_picture",
                                "width": 3840,
                                "height": 2160,
                                "alt": "",
                                "artist": {"id": "", "name": ""}
                            },
                            "color": "#000000",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "mobileStructure": {
                        "type": "Container",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                        "layout": {
                            "width": 280,
                            "height": 426,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.BoxSlideShowSlide",
                        "data": {
                            "type": "BoxSlideShowSlide",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "3"})
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "alpha-brd": "1",
                                    "bg": "#001F2C",
                                    "brd": "color_11",
                                    "brw": "0",
                                    "rd": "0px"
                                },
                                "propertiesSource": {"bg": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.BoxSlideShowSlide",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "mediaRef": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "title": "Model_slide2.jpg",
                                    "uri": "035244_01807809643a4d2eb76af20b45f39bb7.jpg",
                                    "description": "private/allMedia_picture",
                                    "width": 3840,
                                    "height": 2160,
                                    "alt": "",
                                    "artist": {"id": "", "name": ""}
                                },
                                "color": "#000000",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            }
                        }
                    }
                }],
                "props": {
                    "type": "BoxSlideShowProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "transition": "SlideHorizontal",
                    "autoPlayInterval": 3,
                    "autoPlay": true,
                    "transDuration": 1,
                    "pauseAutoPlayOnMouseOver": true,
                    "direction": "RTL",
                    "shouldHideOverflowContent": true,
                    "flexibleBoxHeight": false,
                    "showNavigationButton": true,
                    "showNavigationDots": true,
                    "navigationButtonSize": 20,
                    "navigationButtonMargin": 54,
                    "navigationDotsSize": 8,
                    "navigationDotsMargin": 31,
                    "navigationDotsGap": 15,
                    "navigationDotsAlignment": "center"
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-arrowColor": "1",
                            "alpha-brd": "1",
                            "alpha-dotsColor": "1",
                            "arrowColor": "#000000",
                            "boxShadowToggleOn-shd": "true",
                            "brd": "#FFFFFF",
                            "brw": "0",
                            "dotsColor": "#000000",
                            "shd": "0 0 0 rgba(0, 0, 0, 0.6)"
                        },
                        "propertiesSource": {
                            "alpha-arrowColor": "value",
                            "alpha-brd": "value",
                            "alpha-dotsColor": "value",
                            "arrowColor": "value",
                            "boxShadowToggleOn-shd": "value",
                            "brd": "value",
                            "brw": "value",
                            "dotsColor": "value",
                            "shd": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.BoxSlideShow",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.common.components.boxSlideShow.viewer.skins.longArrowsLargeSelectedCircleSkin"
                },
                "mobileStructure": {
                    "type": "Container",
                    "skin": "wysiwyg.common.components.boxSlideShow.viewer.skins.longArrowsLargeSelectedCircleSkin",
                    "layout": {
                        "width": 280,
                        "height": 426,
                        "x": 0,
                        "y": 1500,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.BoxSlideShow",
                    "props": {
                        "type": "BoxSlideShowProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "transition": "NoTransition",
                        "autoPlayInterval": 2,
                        "autoPlay": false,
                        "transDuration": 1,
                        "pauseAutoPlayOnMouseOver": true,
                        "direction": "RTL",
                        "shouldHideOverflowContent": false,
                        "flexibleBoxHeight": false,
                        "showNavigationButton": true,
                        "showNavigationDots": false,
                        "navigationButtonSize": 12,
                        "navigationButtonMargin": 38,
                        "navigationDotsSize": 6,
                        "navigationDotsMargin": 18,
                        "navigationDotsGap": 12,
                        "navigationDotsAlignment": "center"
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-arrowColor": "1",
                                "alpha-brd": "1",
                                "alpha-dotsColor": "1",
                                "arrowColor": "#000000",
                                "boxShadowToggleOn-shd": "true",
                                "brd": "#FFFFFF",
                                "brw": "0",
                                "dotsColor": "#000000",
                                "shd": "0 0 0 rgba(0, 0, 0, 0.6)"
                            },
                            "propertiesSource": {
                                "alpha-arrowColor": "value",
                                "alpha-brd": "value",
                                "alpha-dotsColor": "value",
                                "arrowColor": "value",
                                "boxShadowToggleOn-shd": "value",
                                "brd": "value",
                                "brw": "value",
                                "dotsColor": "value",
                                "shd": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.viewer.components.BoxSlideShow",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.boxSlideShow.viewer.skins.longArrowsLargeSelectedCircleSkin"
                    }
                },
                "id": "comp-im1p1f9x"
            },
            "preset": {
                "rect": {"width": 324, "height": 155.25, "x": 0, "y": 430.75},
                "label": "add_preset_BoxSlideShow_980_4",
                "tags": null
            }
        }, {
            "id": "BoxSlideShow_BoxSlideShow980_5",
            "structure": {
                "type": "Container",
                "skin": "wysiwyg.common.components.boxSlideShow.viewer.skins.thinArrowsLargeSelectedCircleSkin",
                "layout": {
                    "width": 980,
                    "height": 468,
                    "x": 0,
                    "y": 2421,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.BoxSlideShow",
                "components": [{
                    "type": "Container",
                    "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                    "layout": {
                        "width": 980,
                        "height": 468,
                        "x": 0,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.BoxSlideShowSlide",
                    "components": [{
                        "type": "Component",
                        "layout": {
                            "width": 326,
                            "height": 48,
                            "x": 582,
                            "y": 293,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<h2 class=\"font_2\" style=\"font-size:35px\"><span style=\"color:#FFFFFF;\"><span style=\"letter-spacing:0em\"><span style=\"font-size:35px\"><span style=\"font-family:futura-lt-w01-light,sans-serif\">OUR NEW PLACE</span></span></span></span></h2>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 240,
                                "height": 34,
                                "x": 10,
                                "y": 10,
                                "scale": 0.86,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 class=\"font_2\" style=\"font-size:35px\"><span style=\"color:#FFFFFF;\"><span style=\"letter-spacing:0em\"><span style=\"font-size:35px\"><span style=\"font-family:futura-lt-w01-light,sans-serif\">OUR NEW PLACE</span></span></span></span></h2>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 330,
                            "height": 44,
                            "x": 582,
                            "y": 347,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<p class=\"font_8\" style=\"font-size: 16px;\"><span style=\"font-size:16px;\"><span style=\"font-family:futura-lt-w01-light,sans-serif;\"><span style=\"color:#FFFFFF\">I&#39;m a paragraph. Click here to add your own text and edit me. Let your users get to know you.</span></span></span></p>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 260,
                                "height": 60,
                                "x": 10,
                                "y": 45,
                                "scale": 0.95,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p class=\"font_8\" style=\"font-size: 16px;\"><span style=\"font-size:16px;\"><span style=\"font-family:futura-lt-w01-light,sans-serif;\"><span style=\"color:#FFFFFF\">I&#39;m a paragraph. Click here to add your own text and edit me. Let your users get to know you.</span></span></span></p>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }],
                    "data": {
                        "type": "BoxSlideShowSlide",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "1"})
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-brd": "1",
                                "bg": "#001F2C",
                                "brd": "color_11",
                                "brw": "0",
                                "rd": "0px"
                            },
                            "propertiesSource": {"bg": "value"}
                        },
                        "componentClassName": "wysiwyg.viewer.components.BoxSlideShowSlide",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "mediaRef": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "title": "Creative Design ",
                                "uri": "1f8d5e3f5aab46f6bd96f6f55c373370.jpg",
                                "description": "public/491df47b-5958-4dc7-9029-37f428755d45/c2a37dfc-36cf-4bfd-b940-55bac7bdc9e6",
                                "width": 6534,
                                "height": 4400,
                                "alt": "",
                                "artist": {"id": "mobile_", "name": ""}
                            },
                            "color": "#FFFFFF",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "{color_15}",
                            "colorOverlayOpacity": 0.21
                        }
                    },
                    "mobileStructure": {
                        "type": "Container",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                        "layout": {
                            "width": 280,
                            "height": 292,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.BoxSlideShowSlide",
                        "data": {
                            "type": "BoxSlideShowSlide",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "1"})
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "alpha-brd": "1",
                                    "bg": "#001F2C",
                                    "brd": "color_11",
                                    "brw": "0",
                                    "rd": "0px"
                                },
                                "propertiesSource": {"bg": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.BoxSlideShowSlide",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "mediaRef": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "title": "Creative Design ",
                                    "uri": "1f8d5e3f5aab46f6bd96f6f55c373370.jpg",
                                    "description": "public/491df47b-5958-4dc7-9029-37f428755d45/c2a37dfc-36cf-4bfd-b940-55bac7bdc9e6",
                                    "width": 6534,
                                    "height": 4400,
                                    "alt": "",
                                    "artist": {"id": "mobile_", "name": ""},
                                    "opacity": 0.75
                                },
                                "color": "{color_15}",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "{color_15}",
                                "colorOverlayOpacity": 0.21
                            }
                        }
                    }
                }, {
                    "type": "Container",
                    "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                    "layout": {
                        "width": 980,
                        "height": 468,
                        "x": 0,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.BoxSlideShowSlide",
                    "components": [{
                        "type": "Component",
                        "layout": {
                            "width": 326,
                            "height": 48,
                            "x": 165,
                            "y": 100,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<h2 class=\"font_2\" style=\"font-size:35px\"><span style=\"color:#FFFFFF\"><span style=\"letter-spacing:0em\"><span style=\"font-size:35px\"><span style=\"font-family:futura-lt-w01-light,sans-serif\">TITLE GOES HERE</span></span></span></span></h2>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 240,
                                "height": 34,
                                "x": 10,
                                "y": 10,
                                "scale": 0.8573749999999999,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 class=\"font_2\" style=\"font-size:35px\"><span style=\"color:#FFFFFF\"><span style=\"letter-spacing:0em\"><span style=\"font-size:35px\"><span style=\"font-family:futura-lt-w01-light,sans-serif\">TITLE GOES HERE</span></span></span></span></h2>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 258,
                            "height": 66,
                            "x": 165,
                            "y": 161,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<p class=\"font_8\" style=\"font-size: 16px;\"><span style=\"font-size:16px;\"><span style=\"font-family:futura-lt-w01-light,sans-serif;\"><span style=\"color:#FFFFFF\">I&#39;m a paragraph. Click here to add your own text and edit me. Let your users get to know you.</span></span></span></p>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 186,
                                "height": 80,
                                "x": 10,
                                "y": 45,
                                "scale": 0.95,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p class=\"font_8\" style=\"font-size: 16px;\"><span style=\"font-size:16px;\"><span style=\"font-family:futura-lt-w01-light,sans-serif;\"><span style=\"color:#FFFFFF\">I&#39;m a paragraph. Click here to add your own text and edit me. Let your users get to know you.</span></span></span></p>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }],
                    "data": {
                        "type": "BoxSlideShowSlide",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "2"})
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-brd": "1",
                                "bg": "#001F2C",
                                "brd": "color_11",
                                "brw": "0",
                                "rd": "0px"
                            },
                            "propertiesSource": {"bg": "value"}
                        },
                        "componentClassName": "wysiwyg.viewer.components.BoxSlideShowSlide",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "mediaRef": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "title": "Being Creative",
                                "uri": "72ec23e5b5054adf8cb103679eb4595e.jpg",
                                "description": "public/491df47b-5958-4dc7-9029-37f428755d45/c2a37dfc-36cf-4bfd-b940-55bac7bdc9e6",
                                "width": 5197,
                                "height": 3459,
                                "alt": "",
                                "artist": {"id": "mobile_", "name": ""}
                            },
                            "color": "#000000",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "mobileStructure": {
                        "type": "Container",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                        "layout": {
                            "width": 280,
                            "height": 292,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.BoxSlideShowSlide",
                        "data": {
                            "type": "BoxSlideShowSlide",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "2"})
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "alpha-brd": "1",
                                    "bg": "#001F2C",
                                    "brd": "color_11",
                                    "brw": "0",
                                    "rd": "0px"
                                },
                                "propertiesSource": {"bg": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.BoxSlideShowSlide",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "mediaRef": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "title": "Being Creative",
                                    "uri": "72ec23e5b5054adf8cb103679eb4595e.jpg",
                                    "description": "public/491df47b-5958-4dc7-9029-37f428755d45/c2a37dfc-36cf-4bfd-b940-55bac7bdc9e6",
                                    "width": 5197,
                                    "height": 3459,
                                    "alt": "",
                                    "artist": {"id": "mobile_", "name": ""}
                                },
                                "color": "#000000",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            }
                        }
                    }
                }, {
                    "type": "Container",
                    "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                    "layout": {
                        "width": 980,
                        "height": 468,
                        "x": 0,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.BoxSlideShowSlide",
                    "components": [{
                        "type": "Component",
                        "layout": {
                            "width": 258,
                            "height": 66,
                            "x": 138,
                            "y": 161,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<p class=\"font_8\" style=\"font-size: 16px;\"><span style=\"font-size:16px;\"><span style=\"font-family:futura-lt-w01-light,sans-serif;\"><span style=\"color:#FFFFFF\">I&#39;m a paragraph. Click here to add your own text and edit me. Let your users get to know you.</span></span></span></p>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 250,
                                "height": 60,
                                "x": 10,
                                "y": 45,
                                "scale": 0.95,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p class=\"font_8\" style=\"font-size: 16px;\"><span style=\"font-size:16px;\"><span style=\"font-family:futura-lt-w01-light,sans-serif;\"><span style=\"color:#FFFFFF\">I&#39;m a paragraph. Click here to add your own text and edit me. Let your users get to know you.</span></span></span></p>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }, {
                        "type": "Component",
                        "layout": {
                            "width": 326,
                            "height": 48,
                            "x": 138,
                            "y": 100,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.WRichText",
                        "data": {
                            "type": "StyledText",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "text": "<h2 class=\"font_2\" style=\"font-size:35px\"><span style=\"color:#FFFFFF\"><span style=\"letter-spacing:0em\"><span style=\"font-size:35px\"><span style=\"font-family:futura-lt-w01-light,sans-serif\">TITLE GOES HERE</span></span></span></span></h2>\n",
                            "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                            "linkList": []
                        },
                        "props": {
                            "type": "WRichTextProperties",
                            "metaData": {"schemaVersion": "1.0"},
                            "brightness": 1,
                            "packed": true
                        },
                        "style": "txtNew",
                        "mobileStructure": {
                            "type": "Component",
                            "layout": {
                                "width": 240,
                                "height": 34,
                                "x": 10,
                                "y": 10,
                                "scale": 0.86,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 class=\"font_2\" style=\"font-size:35px\"><span style=\"color:#FFFFFF\"><span style=\"letter-spacing:0em\"><span style=\"font-size:35px\"><span style=\"font-family:futura-lt-w01-light,sans-serif\">TITLE GOES HERE</span></span></span></span></h2>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew"
                        }
                    }],
                    "data": {
                        "type": "BoxSlideShowSlide",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "3"})
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-brd": "1",
                                "bg": "#001F2C",
                                "brd": "color_11",
                                "brw": "0",
                                "rd": "0px"
                            },
                            "propertiesSource": {"bg": "value"}
                        },
                        "componentClassName": "wysiwyg.viewer.components.BoxSlideShowSlide",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "mediaRef": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "title": "Creating Art",
                                "uri": "454bc6b9a29f46f1810d5c4bd1131339.jpg",
                                "description": "public/491df47b-5958-4dc7-9029-37f428755d45/c2a37dfc-36cf-4bfd-b940-55bac7bdc9e6",
                                "width": 6944,
                                "height": 5050,
                                "alt": "",
                                "artist": {"id": "mobile_", "name": ""}
                            },
                            "color": "#000000",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "mobileStructure": {
                        "type": "Container",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                        "layout": {
                            "width": 280,
                            "height": 292,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.BoxSlideShowSlide",
                        "data": {
                            "type": "BoxSlideShowSlide",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "3"})
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "alpha-brd": "1",
                                    "bg": "#001F2C",
                                    "brd": "color_11",
                                    "brw": "0",
                                    "rd": "0px"
                                },
                                "propertiesSource": {"bg": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.BoxSlideShowSlide",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "mediaRef": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "title": "Creating Art",
                                    "uri": "454bc6b9a29f46f1810d5c4bd1131339.jpg",
                                    "description": "public/491df47b-5958-4dc7-9029-37f428755d45/c2a37dfc-36cf-4bfd-b940-55bac7bdc9e6",
                                    "width": 6944,
                                    "height": 5050,
                                    "alt": "",
                                    "artist": {"id": "mobile_", "name": ""},
                                    "opacity": 0.8
                                },
                                "color": "#000000",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            }
                        }
                    }
                }],
                "props": {
                    "type": "BoxSlideShowProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "transition": "SlideHorizontal",
                    "autoPlayInterval": 3,
                    "autoPlay": true,
                    "transDuration": 1,
                    "pauseAutoPlayOnMouseOver": true,
                    "direction": "RTL",
                    "shouldHideOverflowContent": true,
                    "flexibleBoxHeight": false,
                    "showNavigationButton": true,
                    "showNavigationDots": true,
                    "navigationButtonSize": 20,
                    "navigationButtonMargin": 32,
                    "navigationDotsSize": 8,
                    "navigationDotsMargin": 26,
                    "navigationDotsGap": 19,
                    "navigationDotsAlignment": "center"
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-arrowColor": "1",
                            "alpha-brd": "1",
                            "alpha-dotsColor": "1",
                            "arrowColor": "#000000",
                            "boxShadowToggleOn-shd": "false",
                            "brd": "#FFFFFF",
                            "brw": "0",
                            "dotsColor": "#000000",
                            "shd": "0 0 0 rgba(0, 0, 0, 0.6)"
                        },
                        "propertiesSource": {
                            "alpha-arrowColor": "value",
                            "alpha-brd": "value",
                            "alpha-dotsColor": "value",
                            "arrowColor": "value",
                            "boxShadowToggleOn-shd": "value",
                            "brd": "value",
                            "brw": "value",
                            "dotsColor": "value",
                            "shd": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.BoxSlideShow",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.common.components.boxSlideShow.viewer.skins.thinArrowsLargeSelectedCircleSkin"
                },
                "mobileStructure": {
                    "type": "Container",
                    "skin": "wysiwyg.common.components.boxSlideShow.viewer.skins.thinArrowsLargeSelectedCircleSkin",
                    "layout": {
                        "width": 280,
                        "height": 292,
                        "x": 0,
                        "y": 1980,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.BoxSlideShow",
                    "props": {
                        "type": "BoxSlideShowProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "transition": "SlideHorizontal",
                        "autoPlayInterval": 2,
                        "autoPlay": false,
                        "transDuration": 1,
                        "pauseAutoPlayOnMouseOver": true,
                        "direction": "RTL",
                        "shouldHideOverflowContent": true,
                        "flexibleBoxHeight": false,
                        "showNavigationButton": true,
                        "showNavigationDots": true,
                        "navigationButtonSize": 13,
                        "navigationButtonMargin": 22,
                        "navigationDotsSize": 6,
                        "navigationDotsMargin": 24,
                        "navigationDotsGap": 15,
                        "navigationDotsAlignment": "center"
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-arrowColor": "1",
                                "alpha-brd": "1",
                                "alpha-dotsColor": "1",
                                "arrowColor": "#000000",
                                "boxShadowToggleOn-shd": "false",
                                "brd": "#FFFFFF",
                                "brw": "0",
                                "dotsColor": "#000000",
                                "shd": "0 0 0 rgba(0, 0, 0, 0.6)"
                            },
                            "propertiesSource": {
                                "alpha-arrowColor": "value",
                                "alpha-brd": "value",
                                "alpha-dotsColor": "value",
                                "arrowColor": "value",
                                "boxShadowToggleOn-shd": "value",
                                "brd": "value",
                                "brw": "value",
                                "dotsColor": "value",
                                "shd": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.viewer.components.BoxSlideShow",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.boxSlideShow.viewer.skins.thinArrowsLargeSelectedCircleSkin"
                    }
                },
                "id": "comp-io00lkz5"
            },
            "preset": {
                "rect": {"width": 324, "height": 155.25, "x": 0, "y": 586.25},
                "label": "add_preset_BoxSlideShow_980_5",
                "tags": null
            }
        }, {
            "id": "BoxSlideShow_BoxSlideShow980_6",
            "structure": {
                "type": "Container",
                "skin": "wysiwyg.common.components.boxSlideShow.viewer.skins.thinArrowsLargeSelectedCircleSkin",
                "layout": {
                    "width": 980,
                    "height": 660,
                    "x": 0,
                    "y": 3041,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.BoxSlideShow",
                "components": [{
                    "type": "Container",
                    "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                    "layout": {
                        "width": 980,
                        "height": 660,
                        "x": 0,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.BoxSlideShowSlide",
                    "components": [{
                        "type": "Container",
                        "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                        "layout": {
                            "width": 380,
                            "height": 660,
                            "x": 600,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "mobile.core.components.Container",
                        "components": [{
                            "type": "Component",
                            "layout": {
                                "width": 313,
                                "height": 32,
                                "x": 25,
                                "y": 152,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 class=\"font_2\" style=\"font-size: 24px; line-height: 1.2em; text-align: center;\"><span class=\"color_2\"><span style=\"font-size:24px\"><span style=\"letter-spacing:0em\"><span style=\"font-family:lulo-clean-w01-one-bold,sans-serif\">THE BAKERY</span></span></span></span></h2>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew",
                            "mobileStructure": {
                                "type": "Component",
                                "layout": {
                                    "width": 220,
                                    "height": 64,
                                    "x": 10,
                                    "y": 26,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "data": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<h2 class=\"font_2\" style=\"font-size: 24px; line-height: 1.2em; text-align: center;\"><span class=\"color_2\"><span style=\"font-size:24px\"><span style=\"letter-spacing:0em\"><span style=\"font-family:lulo-clean-w01-one-bold,sans-serif\">THE BAKERY</span></span></span></span></h2>\n",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "props": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                },
                                "style": "txtNew"
                            }
                        }, {
                            "type": "Component",
                            "layout": {
                                "width": 208,
                                "height": 200,
                                "x": 77,
                                "y": 242,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p style=\"text-align: center; line-height: 1.5em; font-size: 16px;\" class=\"font_8\"><span style=\"line-height:1.5em;\"><span class=\"color_2\"><span style=\"font-size:16px;\"><span style=\"font-family:eb garamond,serif;\">Tell your visitors your story. Add catchy text to describe what you do, and what you have to offer. The right words can inspire and intrigue your audience, so they’re ready to take action on your site. To start telling your story, double click or click Edit Text.</span></span></span></span></p>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew",
                            "mobileStructure": {
                                "type": "Component",
                                "layout": {
                                    "width": 220,
                                    "height": 200,
                                    "x": 10,
                                    "y": 131,
                                    "scale": 0.9974999999999999,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "data": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<p style=\"text-align: center; line-height: 1.5em; font-size: 16px;\" class=\"font_8\"><span style=\"line-height:1.5em;\"><span class=\"color_2\"><span style=\"font-size:16px;\"><span style=\"font-family:eb garamond,serif;\">Tell your visitors your story. Add catchy text to describe what you do, and what you have to offer. The right words can inspire and intrigue your audience, so they’re ready to take action on your site. To start telling your story, double click or click Edit Text.</span></span></span></span></p>",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "props": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                },
                                "style": "txtNew"
                            }
                        }, {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.button.BasicButton",
                            "layout": {
                                "width": 145,
                                "height": 35,
                                "x": 109,
                                "y": 490,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.SiteButton",
                            "data": {
                                "type": "LinkableButton",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "label": "GET IN TOUCH"
                            },
                            "props": {
                                "type": "ButtonProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "align": "center",
                                "margin": 0
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {
                                        "alpha-bg": "0",
                                        "alpha-bgh": "1",
                                        "alpha-brd": "1",
                                        "alpha-brdh": "1",
                                        "bg": "#FFFFFF",
                                        "bgh": "#000000",
                                        "boxShadowToggleOn-shd": "false",
                                        "brd": "#000000",
                                        "brdh": "#000000",
                                        "brw": "1",
                                        "fnt": "normal normal normal 14px/1.4em eb+garamond",
                                        "rd": "0",
                                        "shd": "0 1px 4px rgba(0, 0, 0, 0.6)",
                                        "txt": "#000000",
                                        "txth": "#FBF3E8"
                                    },
                                    "propertiesSource": {
                                        "alpha-bg": "value",
                                        "alpha-bgh": "value",
                                        "alpha-brd": "value",
                                        "alpha-brdh": "value",
                                        "bg": "value",
                                        "bgh": "value",
                                        "boxShadowToggleOn-shd": "value",
                                        "brd": "value",
                                        "brdh": "value",
                                        "brw": "value",
                                        "fnt": "value",
                                        "rd": "value",
                                        "shd": "value",
                                        "txt": "value",
                                        "txth": "value"
                                    }
                                },
                                "componentClassName": "wysiwyg.viewer.components.SiteButton",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.button.BasicButton"
                            },
                            "mobileStructure": {
                                "type": "Component",
                                "skin": "wysiwyg.viewer.skins.button.BasicButton",
                                "layout": {
                                    "width": 145,
                                    "height": 35,
                                    "x": 48,
                                    "y": 354,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.SiteButton",
                                "data": {
                                    "type": "LinkableButton",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "label": "GET IN TOUCH"
                                },
                                "props": {
                                    "type": "ButtonProperties",
                                    "metaData": {"schemaVersion": "1.0"},
                                    "align": "center",
                                    "margin": 0
                                },
                                "style": {
                                    "type": "TopLevelStyle",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "style": {
                                        "groups": {},
                                        "properties": {
                                            "alpha-bg": "0",
                                            "alpha-bgh": "1",
                                            "alpha-brd": "1",
                                            "alpha-brdh": "1",
                                            "bg": "#FFFFFF",
                                            "bgh": "#000000",
                                            "boxShadowToggleOn-shd": "false",
                                            "brd": "#000000",
                                            "brdh": "#000000",
                                            "brw": "1",
                                            "fnt": "normal normal normal 14px/1.4em eb+garamond",
                                            "rd": "0",
                                            "shd": "0 1px 4px rgba(0, 0, 0, 0.6)",
                                            "txt": "#000000",
                                            "txth": "#FBF3E8"
                                        },
                                        "propertiesSource": {
                                            "alpha-bg": "value",
                                            "alpha-bgh": "value",
                                            "alpha-brd": "value",
                                            "alpha-brdh": "value",
                                            "bg": "value",
                                            "bgh": "value",
                                            "boxShadowToggleOn-shd": "value",
                                            "brd": "value",
                                            "brdh": "value",
                                            "brw": "value",
                                            "fnt": "value",
                                            "rd": "value",
                                            "shd": "value",
                                            "txt": "value",
                                            "txth": "value"
                                        }
                                    },
                                    "componentClassName": "wysiwyg.viewer.components.SiteButton",
                                    "pageId": "",
                                    "compId": "",
                                    "styleType": "custom",
                                    "skin": "wysiwyg.viewer.skins.button.BasicButton"
                                }
                            }
                        }, {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.line.SolidLine",
                            "layout": {
                                "width": 22,
                                "height": 5,
                                "x": 170,
                                "y": 214,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.FiveGridLine",
                            "props": {
                                "type": "FiveGridLineProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "fullScreenModeOn": false
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {"alpha-brd": "1", "brd": "#2F2E2E", "lnw": "1"},
                                    "propertiesSource": {"brd": "value", "lnw": "value"}
                                },
                                "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.line.SolidLine"
                            },
                            "mobileStructure": {
                                "type": "Component",
                                "skin": "wysiwyg.viewer.skins.line.SolidLine",
                                "layout": {
                                    "width": 22,
                                    "height": 5,
                                    "x": 109,
                                    "y": 110,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.FiveGridLine",
                                "props": {
                                    "type": "FiveGridLineProperties",
                                    "metaData": {"schemaVersion": "1.0"},
                                    "fullScreenModeOn": false
                                },
                                "style": {
                                    "type": "TopLevelStyle",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "style": {
                                        "groups": {},
                                        "properties": {"alpha-brd": "1", "brd": "#2F2E2E", "lnw": "1"},
                                        "propertiesSource": {"brd": "value", "lnw": "value"}
                                    },
                                    "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                                    "pageId": "",
                                    "compId": "",
                                    "styleType": "custom",
                                    "skin": "wysiwyg.viewer.skins.line.SolidLine"
                                }
                            }
                        }],
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "alpha-bg": "1",
                                    "alpha-brd": "1",
                                    "bg": "#FBF3E8",
                                    "boxShadowToggleOn-shd": "false",
                                    "brd": "#2F2E2E",
                                    "brw": "0",
                                    "rd": "0px",
                                    "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                                },
                                "propertiesSource": {
                                    "alpha-bg": "value",
                                    "bg": "value",
                                    "brd": "value",
                                    "brw": "value",
                                    "rd": "value",
                                    "shd": "value"
                                }
                            },
                            "componentClassName": "mobile.core.components.Container",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                        },
                        "mobileStructure": {
                            "type": "Container",
                            "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                            "layout": {
                                "width": 240,
                                "height": 409,
                                "x": 20,
                                "y": 24,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "mobile.core.components.Container",
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {
                                        "alpha-bg": "1",
                                        "alpha-brd": "1",
                                        "bg": "#FBF3E8",
                                        "boxShadowToggleOn-shd": "false",
                                        "brd": "#2F2E2E",
                                        "brw": "0",
                                        "rd": "0px",
                                        "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                                    },
                                    "propertiesSource": {
                                        "alpha-bg": "value",
                                        "bg": "value",
                                        "brd": "value",
                                        "brw": "value",
                                        "rd": "value",
                                        "shd": "value"
                                    }
                                },
                                "componentClassName": "mobile.core.components.Container",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                            }
                        }
                    }],
                    "data": {
                        "type": "BoxSlideShowSlide",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "1"})
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-brd": "1",
                                "bg": "#001F2C",
                                "brd": "color_11",
                                "brw": "0",
                                "rd": "0px"
                            },
                            "propertiesSource": {"bg": "value"}
                        },
                        "componentClassName": "wysiwyg.viewer.components.BoxSlideShowSlide",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "mediaRef": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "title": "shutterstock_215369155.jpg",
                                "uri": "035244_12b6d158aadc494ab9e65c7a3d794cb2.jpg",
                                "description": "private/allMedia_picture",
                                "width": 5616,
                                "height": 3744,
                                "alt": "",
                                "artist": {"id": "", "name": ""}
                            },
                            "color": "#FFFFFF",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "{color_15}",
                            "colorOverlayOpacity": 0.21
                        }
                    },
                    "mobileStructure": {
                        "type": "Container",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                        "layout": {
                            "width": 280,
                            "height": 483,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.BoxSlideShowSlide",
                        "data": {
                            "type": "BoxSlideShowSlide",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "1"})
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "alpha-brd": "1",
                                    "bg": "#001F2C",
                                    "brd": "color_11",
                                    "brw": "0",
                                    "rd": "0px"
                                },
                                "propertiesSource": {"bg": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.BoxSlideShowSlide",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "mediaRef": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "title": "shutterstock_215369155.jpg",
                                    "uri": "035244_12b6d158aadc494ab9e65c7a3d794cb2.jpg",
                                    "description": "private/allMedia_picture",
                                    "width": 5616,
                                    "height": 3744,
                                    "alt": "",
                                    "artist": {"id": "", "name": ""}
                                },
                                "color": "#FFFFFF",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "{color_15}",
                                "colorOverlayOpacity": 0.21
                            }
                        }
                    }
                }, {
                    "type": "Container",
                    "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                    "layout": {
                        "width": 980,
                        "height": 660,
                        "x": 0,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.BoxSlideShowSlide",
                    "components": [{
                        "type": "Container",
                        "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                        "layout": {
                            "width": 380,
                            "height": 660,
                            "x": 600,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "mobile.core.components.Container",
                        "components": [{
                            "type": "Component",
                            "layout": {
                                "width": 313,
                                "height": 32,
                                "x": 25,
                                "y": 150,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 style=\"font-size: 24px; text-align: center; line-height: 1.2em;\" class=\"font_2\"><span style=\"line-height:1.2em;\"><span class=\"color_2\"><span style=\"font-size:24px;\"><span style=\"letter-spacing:0em;\"><span style=\"font-family:lulo-clean-w01-one-bold,sans-serif;\">OUR STORY</span></span></span></span></span></h2>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew",
                            "mobileStructure": {
                                "type": "Component",
                                "layout": {
                                    "width": 202,
                                    "height": 64,
                                    "x": 20,
                                    "y": 27,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "data": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<h2 style=\"font-size: 24px; text-align: center; line-height: 1.2em;\" class=\"font_2\"><span style=\"line-height:1.2em;\"><span class=\"color_2\"><span style=\"font-size:24px;\"><span style=\"letter-spacing:0em;\"><span style=\"font-family:lulo-clean-w01-one-bold,sans-serif;\">OUR STORY</span></span></span></span></span></h2>",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "props": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                },
                                "style": "txtNew"
                            }
                        }, {
                            "type": "Component",
                            "layout": {
                                "width": 207,
                                "height": 200,
                                "x": 78,
                                "y": 238,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p style=\"text-align: center; line-height: 1.5em; font-size: 16px;\" class=\"font_8\"><span style=\"line-height:1.5em;\"><span class=\"color_2\"><span style=\"font-size:16px;\"><span style=\"font-family:eb garamond,serif;\">Tell your visitors your story. Add catchy text to describe what you do, and what you have to offer. The right words can inspire and intrigue your audience, so they’re ready to take action on your site. To start telling your story, double click or click Edit Text.</span></span></span></span></p>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew",
                            "mobileStructure": {
                                "type": "Component",
                                "layout": {
                                    "width": 220,
                                    "height": 200,
                                    "x": 10,
                                    "y": 128,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "data": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<p style=\"text-align: center; line-height: 1.5em; font-size: 16px;\" class=\"font_8\"><span style=\"line-height:1.5em;\"><span class=\"color_2\"><span style=\"font-size:16px;\"><span style=\"font-family:eb garamond,serif;\">Tell your visitors your story. Add catchy text to describe what you do, and what you have to offer. The right words can inspire and intrigue your audience, so they’re ready to take action on your site. To start telling your story, double click or click Edit Text.</span></span></span></span></p>",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "props": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                },
                                "style": "txtNew"
                            }
                        }, {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.button.BasicButton",
                            "layout": {
                                "width": 145,
                                "height": 35,
                                "x": 106,
                                "y": 486,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.SiteButton",
                            "data": {
                                "type": "LinkableButton",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "label": "GET IN TOUCH"
                            },
                            "props": {
                                "type": "ButtonProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "align": "center",
                                "margin": 0
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {
                                        "alpha-bg": "0",
                                        "alpha-bgh": "1",
                                        "alpha-brd": "1",
                                        "alpha-brdh": "1",
                                        "bg": "#FFFFFF",
                                        "bgh": "#000000",
                                        "boxShadowToggleOn-shd": "false",
                                        "brd": "#000000",
                                        "brdh": "#000000",
                                        "brw": "1",
                                        "fnt": "normal normal normal 14px/1.4em eb+garamond",
                                        "rd": "0",
                                        "shd": "0 1px 4px rgba(0, 0, 0, 0.6)",
                                        "txt": "#000000",
                                        "txth": "#FBF3E8"
                                    },
                                    "propertiesSource": {
                                        "alpha-bg": "value",
                                        "alpha-bgh": "value",
                                        "alpha-brd": "value",
                                        "alpha-brdh": "value",
                                        "bg": "value",
                                        "bgh": "value",
                                        "boxShadowToggleOn-shd": "value",
                                        "brd": "value",
                                        "brdh": "value",
                                        "brw": "value",
                                        "fnt": "value",
                                        "rd": "value",
                                        "shd": "value",
                                        "txt": "value",
                                        "txth": "value"
                                    }
                                },
                                "componentClassName": "wysiwyg.viewer.components.SiteButton",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.button.BasicButton"
                            },
                            "mobileStructure": {
                                "type": "Component",
                                "skin": "wysiwyg.viewer.skins.button.BasicButton",
                                "layout": {
                                    "width": 145,
                                    "height": 35,
                                    "x": 48,
                                    "y": 347,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.SiteButton",
                                "data": {
                                    "type": "LinkableButton",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "label": "GET IN TOUCH"
                                },
                                "props": {
                                    "type": "ButtonProperties",
                                    "metaData": {"schemaVersion": "1.0"},
                                    "align": "center",
                                    "margin": 0
                                },
                                "style": {
                                    "type": "TopLevelStyle",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "style": {
                                        "groups": {},
                                        "properties": {
                                            "alpha-bg": "0",
                                            "alpha-bgh": "1",
                                            "alpha-brd": "1",
                                            "alpha-brdh": "1",
                                            "bg": "#FFFFFF",
                                            "bgh": "#000000",
                                            "boxShadowToggleOn-shd": "false",
                                            "brd": "#000000",
                                            "brdh": "#000000",
                                            "brw": "1",
                                            "fnt": "normal normal normal 14px/1.4em eb+garamond",
                                            "rd": "0",
                                            "shd": "0 1px 4px rgba(0, 0, 0, 0.6)",
                                            "txt": "#000000",
                                            "txth": "#FBF3E8"
                                        },
                                        "propertiesSource": {
                                            "alpha-bg": "value",
                                            "alpha-bgh": "value",
                                            "alpha-brd": "value",
                                            "alpha-brdh": "value",
                                            "bg": "value",
                                            "bgh": "value",
                                            "boxShadowToggleOn-shd": "value",
                                            "brd": "value",
                                            "brdh": "value",
                                            "brw": "value",
                                            "fnt": "value",
                                            "rd": "value",
                                            "shd": "value",
                                            "txt": "value",
                                            "txth": "value"
                                        }
                                    },
                                    "componentClassName": "wysiwyg.viewer.components.SiteButton",
                                    "pageId": "",
                                    "compId": "",
                                    "styleType": "custom",
                                    "skin": "wysiwyg.viewer.skins.button.BasicButton"
                                }
                            }
                        }, {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.line.SolidLine",
                            "layout": {
                                "width": 22,
                                "height": 5,
                                "x": 170,
                                "y": 210,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.FiveGridLine",
                            "props": {
                                "type": "FiveGridLineProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "fullScreenModeOn": false
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {"alpha-brd": "1", "brd": "#2F2E2E", "lnw": "1"},
                                    "propertiesSource": {"brd": "value", "lnw": "value"}
                                },
                                "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.line.SolidLine"
                            },
                            "mobileStructure": {
                                "type": "Component",
                                "skin": "wysiwyg.viewer.skins.line.SolidLine",
                                "layout": {
                                    "width": 22,
                                    "height": 5,
                                    "x": 110,
                                    "y": 111,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.FiveGridLine",
                                "props": {
                                    "type": "FiveGridLineProperties",
                                    "metaData": {"schemaVersion": "1.0"},
                                    "fullScreenModeOn": false
                                },
                                "style": {
                                    "type": "TopLevelStyle",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "style": {
                                        "groups": {},
                                        "properties": {"alpha-brd": "1", "brd": "#2F2E2E", "lnw": "1"},
                                        "propertiesSource": {"brd": "value", "lnw": "value"}
                                    },
                                    "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                                    "pageId": "",
                                    "compId": "",
                                    "styleType": "custom",
                                    "skin": "wysiwyg.viewer.skins.line.SolidLine"
                                }
                            }
                        }],
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "alpha-bg": "1",
                                    "alpha-brd": "1",
                                    "bg": "#FBF3E8",
                                    "boxShadowToggleOn-shd": "false",
                                    "brd": "#2F2E2E",
                                    "brw": "0",
                                    "rd": "0px",
                                    "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                                },
                                "propertiesSource": {
                                    "alpha-bg": "value",
                                    "bg": "value",
                                    "brd": "value",
                                    "brw": "value",
                                    "rd": "value",
                                    "shd": "value"
                                }
                            },
                            "componentClassName": "mobile.core.components.Container",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                        },
                        "mobileStructure": {
                            "type": "Container",
                            "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                            "layout": {
                                "width": 240,
                                "height": 402,
                                "x": 20,
                                "y": 23,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "mobile.core.components.Container",
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {
                                        "alpha-bg": "1",
                                        "alpha-brd": "1",
                                        "bg": "#FBF3E8",
                                        "boxShadowToggleOn-shd": "false",
                                        "brd": "#2F2E2E",
                                        "brw": "0",
                                        "rd": "0px",
                                        "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                                    },
                                    "propertiesSource": {
                                        "alpha-bg": "value",
                                        "bg": "value",
                                        "brd": "value",
                                        "brw": "value",
                                        "rd": "value",
                                        "shd": "value"
                                    }
                                },
                                "componentClassName": "mobile.core.components.Container",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                            }
                        }
                    }],
                    "data": {
                        "type": "BoxSlideShowSlide",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "2"})
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-brd": "1",
                                "bg": "#001F2C",
                                "brd": "color_11",
                                "brw": "0",
                                "rd": "0px"
                            },
                            "propertiesSource": {"bg": "value"}
                        },
                        "componentClassName": "wysiwyg.viewer.components.BoxSlideShowSlide",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "mediaRef": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "title": "Prepare yeast dough.jpg",
                                "uri": "035244_3b78f9dfb2e041d4ba0bef975a0f2e7c.jpg",
                                "description": "private/allMedia_picture",
                                "width": 5616,
                                "height": 3744,
                                "alt": "",
                                "artist": {"id": "", "name": ""}
                            },
                            "color": "#000000",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "mobileStructure": {
                        "type": "Container",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                        "layout": {
                            "width": 280,
                            "height": 483,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.BoxSlideShowSlide",
                        "data": {
                            "type": "BoxSlideShowSlide",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "2"})
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "alpha-brd": "1",
                                    "bg": "#001F2C",
                                    "brd": "color_11",
                                    "brw": "0",
                                    "rd": "0px"
                                },
                                "propertiesSource": {"bg": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.BoxSlideShowSlide",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "mediaRef": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "title": "Prepare yeast dough.jpg",
                                    "uri": "035244_3b78f9dfb2e041d4ba0bef975a0f2e7c.jpg",
                                    "description": "private/allMedia_picture",
                                    "width": 5616,
                                    "height": 3744,
                                    "alt": "",
                                    "artist": {"id": "", "name": ""}
                                },
                                "color": "#000000",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            }
                        }
                    }
                }, {
                    "type": "Container",
                    "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                    "layout": {
                        "width": 980,
                        "height": 660,
                        "x": 0,
                        "y": 0,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.BoxSlideShowSlide",
                    "components": [{
                        "type": "Container",
                        "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                        "layout": {
                            "width": 380,
                            "height": 660,
                            "x": 600,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "mobile.core.components.Container",
                        "components": [{
                            "type": "Component",
                            "layout": {
                                "width": 313,
                                "height": 32,
                                "x": 25,
                                "y": 214,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<h2 class=\"font_2\" style=\"font-size: 24px; line-height: 1.2em; text-align: center;\"><span class=\"color_2\"><span style=\"font-size:24px\"><span style=\"letter-spacing:0em\"><span style=\"font-family:lulo-clean-w01-one-bold,sans-serif\">COME ON BY</span></span></span></span></h2>\n",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew",
                            "mobileStructure": {
                                "type": "Component",
                                "layout": {
                                    "width": 193,
                                    "height": 64,
                                    "x": 24,
                                    "y": 32,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "data": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<h2 class=\"font_2\" style=\"font-size: 24px; line-height: 1.2em; text-align: center;\"><span class=\"color_2\"><span style=\"font-size:24px\"><span style=\"letter-spacing:0em\"><span style=\"font-family:lulo-clean-w01-one-bold,sans-serif\">COME ON BY</span></span></span></span></h2>\n",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "props": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                },
                                "style": "txtNew"
                            }
                        }, {
                            "type": "Component",
                            "layout": {
                                "width": 207,
                                "height": 58,
                                "x": 78,
                                "y": 303,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.WRichText",
                            "data": {
                                "type": "StyledText",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "text": "<p style=\"text-align: center; line-height: 1.5em; font-size: 16px;\" class=\"font_8\"><span style=\"font-size:16px;\"><span style=\"font-family:eb garamond,serif;\"><span class=\"color_2\">Mon - Thur 7am-6pm </span></span></span></p><p style=\"font-size: 16px; line-height: 2em; text-align: center;\" class=\"font_8\"><span style=\"font-size:16px;\"><span style=\"font-family:eb garamond,serif;\"><span class=\"color_2\">Weekends 9am-8pm</span></span></span></p>",
                                "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                "linkList": []
                            },
                            "props": {
                                "type": "WRichTextProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "brightness": 1,
                                "packed": true
                            },
                            "style": "txtNew",
                            "mobileStructure": {
                                "type": "Component",
                                "layout": {
                                    "width": 217,
                                    "height": 58,
                                    "x": 12,
                                    "y": 120,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.WRichText",
                                "data": {
                                    "type": "StyledText",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "text": "<p style=\"text-align: center; line-height: 1.5em; font-size: 16px;\" class=\"font_8\"><span style=\"font-size:16px;\"><span style=\"font-family:eb garamond,serif;\"><span class=\"color_2\">Mon - Thur 7am-6pm </span></span></span></p><p style=\"font-size: 16px; line-height: 2em; text-align: center;\" class=\"font_8\"><span style=\"font-size:16px;\"><span style=\"font-family:eb garamond,serif;\"><span class=\"color_2\">Weekends 9am-8pm</span></span></span></p>",
                                    "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
                                    "linkList": []
                                },
                                "props": {
                                    "type": "WRichTextProperties",
                                    "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                                    "brightness": 1,
                                    "packed": true
                                },
                                "style": "txtNew"
                            }
                        }, {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.button.BasicButton",
                            "layout": {
                                "width": 145,
                                "height": 35,
                                "x": 108,
                                "y": 402,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.SiteButton",
                            "data": {
                                "type": "LinkableButton",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "label": "GET IN TOUCH"
                            },
                            "props": {
                                "type": "ButtonProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "align": "center",
                                "margin": 0
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {
                                        "alpha-bg": "0",
                                        "alpha-bgh": "1",
                                        "alpha-brd": "1",
                                        "alpha-brdh": "1",
                                        "bg": "#FFFFFF",
                                        "bgh": "#000000",
                                        "boxShadowToggleOn-shd": "false",
                                        "brd": "#000000",
                                        "brdh": "#000000",
                                        "brw": "1",
                                        "fnt": "normal normal normal 14px/1.4em eb+garamond",
                                        "rd": "0",
                                        "shd": "0 1px 4px rgba(0, 0, 0, 0.6)",
                                        "txt": "#000000",
                                        "txth": "#FBF3E8"
                                    },
                                    "propertiesSource": {
                                        "alpha-bg": "value",
                                        "alpha-bgh": "value",
                                        "alpha-brd": "value",
                                        "alpha-brdh": "value",
                                        "bg": "value",
                                        "bgh": "value",
                                        "boxShadowToggleOn-shd": "value",
                                        "brd": "value",
                                        "brdh": "value",
                                        "brw": "value",
                                        "fnt": "value",
                                        "rd": "value",
                                        "shd": "value",
                                        "txt": "value",
                                        "txth": "value"
                                    }
                                },
                                "componentClassName": "wysiwyg.viewer.components.SiteButton",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.button.BasicButton"
                            },
                            "mobileStructure": {
                                "type": "Component",
                                "skin": "wysiwyg.viewer.skins.button.BasicButton",
                                "layout": {
                                    "width": 145,
                                    "height": 35,
                                    "x": 48,
                                    "y": 189,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.SiteButton",
                                "data": {
                                    "type": "LinkableButton",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "label": "GET IN TOUCH"
                                },
                                "props": {
                                    "type": "ButtonProperties",
                                    "metaData": {"schemaVersion": "1.0"},
                                    "align": "center",
                                    "margin": 0
                                },
                                "style": {
                                    "type": "TopLevelStyle",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "style": {
                                        "groups": {},
                                        "properties": {
                                            "alpha-bg": "0",
                                            "alpha-bgh": "1",
                                            "alpha-brd": "1",
                                            "alpha-brdh": "1",
                                            "bg": "#FFFFFF",
                                            "bgh": "#000000",
                                            "boxShadowToggleOn-shd": "false",
                                            "brd": "#000000",
                                            "brdh": "#000000",
                                            "brw": "1",
                                            "fnt": "normal normal normal 14px/1.4em eb+garamond",
                                            "rd": "0",
                                            "shd": "0 1px 4px rgba(0, 0, 0, 0.6)",
                                            "txt": "#000000",
                                            "txth": "#FBF3E8"
                                        },
                                        "propertiesSource": {
                                            "alpha-bg": "value",
                                            "alpha-bgh": "value",
                                            "alpha-brd": "value",
                                            "alpha-brdh": "value",
                                            "bg": "value",
                                            "bgh": "value",
                                            "boxShadowToggleOn-shd": "value",
                                            "brd": "value",
                                            "brdh": "value",
                                            "brw": "value",
                                            "fnt": "value",
                                            "rd": "value",
                                            "shd": "value",
                                            "txt": "value",
                                            "txth": "value"
                                        }
                                    },
                                    "componentClassName": "wysiwyg.viewer.components.SiteButton",
                                    "pageId": "",
                                    "compId": "",
                                    "styleType": "custom",
                                    "skin": "wysiwyg.viewer.skins.button.BasicButton"
                                }
                            }
                        }, {
                            "type": "Component",
                            "skin": "wysiwyg.viewer.skins.line.SolidLine",
                            "layout": {
                                "width": 22,
                                "height": 5,
                                "x": 170,
                                "y": 272,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "wysiwyg.viewer.components.FiveGridLine",
                            "props": {
                                "type": "FiveGridLineProperties",
                                "metaData": {"schemaVersion": "1.0"},
                                "fullScreenModeOn": false
                            },
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {"alpha-brd": "1", "brd": "#2F2E2E", "lnw": "1"},
                                    "propertiesSource": {"brd": "value", "lnw": "value"}
                                },
                                "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.line.SolidLine"
                            },
                            "mobileStructure": {
                                "type": "Component",
                                "skin": "wysiwyg.viewer.skins.line.SolidLine",
                                "layout": {
                                    "width": 22,
                                    "height": 5,
                                    "x": 109,
                                    "y": 108,
                                    "scale": 1,
                                    "rotationInDegrees": 0,
                                    "fixedPosition": false
                                },
                                "componentType": "wysiwyg.viewer.components.FiveGridLine",
                                "props": {
                                    "type": "FiveGridLineProperties",
                                    "metaData": {"schemaVersion": "1.0"},
                                    "fullScreenModeOn": false
                                },
                                "style": {
                                    "type": "TopLevelStyle",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "style": {
                                        "groups": {},
                                        "properties": {"alpha-brd": "1", "brd": "#2F2E2E", "lnw": "1"},
                                        "propertiesSource": {"brd": "value", "lnw": "value"}
                                    },
                                    "componentClassName": "wysiwyg.viewer.components.FiveGridLine",
                                    "pageId": "",
                                    "compId": "",
                                    "styleType": "custom",
                                    "skin": "wysiwyg.viewer.skins.line.SolidLine"
                                }
                            }
                        }],
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "alpha-bg": "1",
                                    "alpha-brd": "1",
                                    "bg": "#FBF3E8",
                                    "boxShadowToggleOn-shd": "false",
                                    "brd": "#2F2E2E",
                                    "brw": "0",
                                    "rd": "0px",
                                    "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                                },
                                "propertiesSource": {
                                    "alpha-bg": "value",
                                    "bg": "value",
                                    "brd": "value",
                                    "brw": "value",
                                    "rd": "value",
                                    "shd": "value"
                                }
                            },
                            "componentClassName": "mobile.core.components.Container",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                        },
                        "mobileStructure": {
                            "type": "Container",
                            "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
                            "layout": {
                                "width": 240,
                                "height": 262,
                                "x": 20,
                                "y": 90,
                                "scale": 1,
                                "rotationInDegrees": 0,
                                "fixedPosition": false
                            },
                            "componentType": "mobile.core.components.Container",
                            "style": {
                                "type": "TopLevelStyle",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "style": {
                                    "groups": {},
                                    "properties": {
                                        "alpha-bg": "1",
                                        "alpha-brd": "1",
                                        "bg": "#FBF3E8",
                                        "boxShadowToggleOn-shd": "false",
                                        "brd": "#2F2E2E",
                                        "brw": "0",
                                        "rd": "0px",
                                        "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
                                    },
                                    "propertiesSource": {
                                        "alpha-bg": "value",
                                        "bg": "value",
                                        "brd": "value",
                                        "brw": "value",
                                        "rd": "value",
                                        "shd": "value"
                                    }
                                },
                                "componentClassName": "mobile.core.components.Container",
                                "pageId": "",
                                "compId": "",
                                "styleType": "custom",
                                "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
                            }
                        }
                    }],
                    "data": {
                        "type": "BoxSlideShowSlide",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "3"})
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-brd": "1",
                                "bg": "#001F2C",
                                "brd": "color_11",
                                "brw": "0",
                                "rd": "0px"
                            },
                            "propertiesSource": {"bg": "value"}
                        },
                        "componentClassName": "wysiwyg.viewer.components.BoxSlideShowSlide",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                    },
                    "design": {
                        "type": "MediaContainerDesignData",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "background": {
                            "type": "BackgroundMedia",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "mediaRef": {
                                "type": "Image",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "title": "Fresh pretzel.jpg",
                                "uri": "035244_67cddd8be9db449787e6f3e546347576.jpg",
                                "description": "private/allMedia_picture",
                                "width": 3000,
                                "height": 2000,
                                "alt": "",
                                "artist": {"id": "", "name": ""}
                            },
                            "color": "#000000",
                            "colorOpacity": 1,
                            "alignType": "center",
                            "fittingType": "fill",
                            "scrollType": "none",
                            "colorOverlay": "",
                            "colorOverlayOpacity": 0
                        }
                    },
                    "mobileStructure": {
                        "type": "Container",
                        "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
                        "layout": {
                            "width": 280,
                            "height": 483,
                            "x": 0,
                            "y": 0,
                            "scale": 1,
                            "rotationInDegrees": 0,
                            "fixedPosition": false
                        },
                        "componentType": "wysiwyg.viewer.components.BoxSlideShowSlide",
                        "data": {
                            "type": "BoxSlideShowSlide",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "title": i18n.translate("Regular_Slideshow_Slides_New_Slide_Label", {curr_slide: "3"})
                        },
                        "style": {
                            "type": "TopLevelStyle",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "style": {
                                "groups": {},
                                "properties": {
                                    "alpha-brd": "1",
                                    "bg": "#001F2C",
                                    "brd": "color_11",
                                    "brw": "0",
                                    "rd": "0px"
                                },
                                "propertiesSource": {"bg": "value"}
                            },
                            "componentClassName": "wysiwyg.viewer.components.BoxSlideShowSlide",
                            "pageId": "",
                            "compId": "",
                            "styleType": "custom",
                            "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin"
                        },
                        "design": {
                            "type": "MediaContainerDesignData",
                            "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                            "background": {
                                "type": "BackgroundMedia",
                                "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                "mediaRef": {
                                    "type": "Image",
                                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                                    "title": "Fresh pretzel.jpg",
                                    "uri": "035244_67cddd8be9db449787e6f3e546347576.jpg",
                                    "description": "private/allMedia_picture",
                                    "width": 3000,
                                    "height": 2000,
                                    "alt": "",
                                    "artist": {"id": "", "name": ""}
                                },
                                "color": "#000000",
                                "colorOpacity": 1,
                                "alignType": "center",
                                "fittingType": "fill",
                                "scrollType": "none",
                                "colorOverlay": "",
                                "colorOverlayOpacity": 0
                            }
                        }
                    }
                }],
                "props": {
                    "type": "BoxSlideShowProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "transition": "SlideHorizontal",
                    "autoPlayInterval": 3,
                    "autoPlay": true,
                    "transDuration": 1,
                    "pauseAutoPlayOnMouseOver": true,
                    "direction": "RTL",
                    "shouldHideOverflowContent": true,
                    "flexibleBoxHeight": false,
                    "showNavigationButton": true,
                    "showNavigationDots": true,
                    "navigationButtonSize": 15,
                    "navigationButtonMargin": 30,
                    "navigationDotsSize": 6,
                    "navigationDotsMargin": 31,
                    "navigationDotsGap": 15,
                    "navigationDotsAlignment": "center"
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {
                        "groups": {},
                        "properties": {
                            "alpha-arrowColor": "1",
                            "alpha-brd": "1",
                            "alpha-dotsColor": "1",
                            "arrowColor": "#000000",
                            "boxShadowToggleOn-shd": "true",
                            "brd": "#FFFFFF",
                            "brw": "0",
                            "dotsColor": "#000000",
                            "shd": "0 0 0 rgba(0, 0, 0, 0.6)"
                        },
                        "propertiesSource": {
                            "alpha-arrowColor": "value",
                            "alpha-brd": "value",
                            "alpha-dotsColor": "value",
                            "arrowColor": "value",
                            "boxShadowToggleOn-shd": "value",
                            "brd": "value",
                            "brw": "value",
                            "dotsColor": "value",
                            "shd": "value"
                        }
                    },
                    "componentClassName": "wysiwyg.viewer.components.BoxSlideShow",
                    "pageId": "",
                    "compId": "",
                    "styleType": "custom",
                    "skin": "wysiwyg.common.components.boxSlideShow.viewer.skins.thinArrowsLargeSelectedCircleSkin"
                },
                "mobileStructure": {
                    "type": "Container",
                    "skin": "wysiwyg.common.components.boxSlideShow.viewer.skins.thinArrowsLargeSelectedCircleSkin",
                    "layout": {
                        "width": 280,
                        "height": 483,
                        "x": 0,
                        "y": 2329,
                        "scale": 1,
                        "rotationInDegrees": 0,
                        "fixedPosition": false
                    },
                    "componentType": "wysiwyg.viewer.components.BoxSlideShow",
                    "props": {
                        "type": "BoxSlideShowProperties",
                        "metaData": {"schemaVersion": "1.0", "autoGenerated": false},
                        "transition": "SlideHorizontal",
                        "autoPlayInterval": 2,
                        "autoPlay": false,
                        "transDuration": 1,
                        "pauseAutoPlayOnMouseOver": true,
                        "direction": "RTL",
                        "shouldHideOverflowContent": true,
                        "flexibleBoxHeight": false,
                        "showNavigationButton": false,
                        "showNavigationDots": true,
                        "navigationButtonSize": 17,
                        "navigationButtonMargin": 19,
                        "navigationDotsSize": 6,
                        "navigationDotsMargin": 24,
                        "navigationDotsGap": 15,
                        "navigationDotsAlignment": "center"
                    },
                    "style": {
                        "type": "TopLevelStyle",
                        "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                        "style": {
                            "groups": {},
                            "properties": {
                                "alpha-arrowColor": "1",
                                "alpha-brd": "1",
                                "alpha-dotsColor": "1",
                                "arrowColor": "#000000",
                                "boxShadowToggleOn-shd": "true",
                                "brd": "#FFFFFF",
                                "brw": "0",
                                "dotsColor": "#000000",
                                "shd": "0 0 0 rgba(0, 0, 0, 0.6)"
                            },
                            "propertiesSource": {
                                "alpha-arrowColor": "value",
                                "alpha-brd": "value",
                                "alpha-dotsColor": "value",
                                "arrowColor": "value",
                                "boxShadowToggleOn-shd": "value",
                                "brd": "value",
                                "brw": "value",
                                "dotsColor": "value",
                                "shd": "value"
                            }
                        },
                        "componentClassName": "wysiwyg.viewer.components.BoxSlideShow",
                        "pageId": "",
                        "compId": "",
                        "styleType": "custom",
                        "skin": "wysiwyg.common.components.boxSlideShow.viewer.skins.thinArrowsLargeSelectedCircleSkin"
                    }
                },
                "id": "comp-im1y082g"
            },
            "preset": {
                "rect": {"width": 324, "height": 203.5, "x": 0, "y": 741.75},
                "label": "add_preset_BoxSlideShow_980_6",
                "tags": null
            }
        }],
        "compTypes": ["wysiwyg.viewer.components.BoxSlideShow"]
    },
    "help": {"hide": false, "text": "add_section_info_text_regular_slideshow"}
}
