import * as baseDoubleClick from '../../../utils/mouseMoveActions/baseDoubleClick';
import * as stateManagement from '@/stateManagement';
import _ from 'lodash';
import {
  editBoxClassesSel,
  draggableSel,
  controllingParentSel,
  parentRestrictionsSel,
  compDisplayNameSel,
  shouldShowCompDisplayNameSel,
  mobileOnlyNonNativeComponentSel,
  compRestrictionsSel,
  editBoxOtherPropsSel,
  editBoxComponentsSel,
} from './editBoxSelectors';
import { isLabelBottomEditSel } from './layout/selectors';
import { createStructuredSelector } from '../../../selectors/selector';
import { getStateMapperArgsFromEditorAPI } from '../../selectors/rootSelectors';
import type { StateMapperArgs, DispatchMapperArgs } from 'types/redux';
import {
  isResizingSel,
  isDraggingSel,
  isRotatingSel,
  isDuringMouseActionSel,
} from '../../selectors/mouseSelectors';
import { BaseDragApiKey } from '@/apis';

const { getMousePosition } = stateManagement.domMeasurements.selectors;
const { getSelectedCompsRefs } = stateManagement.selection.selectors;

const editBoxPropsSel = createStructuredSelector({
  // layout selectors
  isLabelBottom: isLabelBottomEditSel,
  editBoxClasses: editBoxClassesSel,
  //other
  draggable: draggableSel,
  compDisplayName: compDisplayNameSel,
  shouldShowCompDisplayName: shouldShowCompDisplayNameSel,
  isMobileOnlyNonNativeComponent: mobileOnlyNonNativeComponentSel,
  parentRestrictions: parentRestrictionsSel,
  possibleLayoutActionsMap: compRestrictionsSel,
  isResizing: isResizingSel,
  isDragging: isDraggingSel,
  isRotating: isRotatingSel,
  isDuringMouseAction: isDuringMouseActionSel,
  editBoxComponents: editBoxComponentsSel,
});

const mapStateToProps = (mapperArgs: StateMapperArgs) => {
  return {
    ...editBoxOtherPropsSel(mapperArgs), //TODO remove after spliting into selectors
    ...editBoxPropsSel(mapperArgs),
  };
};

const isSameRef =
  (...args: AnyFixMe[]) =>
  (dispatch: AnyFixMe, getState: AnyFixMe, { editorAPI }: AnyFixMe) =>
    editorAPI.utils.isSameRef(...args);

const isMultiSelectKeyPressed =
  (...args: AnyFixMe[]) =>
  (dispatch: AnyFixMe, getState: AnyFixMe, { editorAPI }: AnyFixMe) =>
    editorAPI.selection.isMultiSelectKeyPressed(...args);
const doubleClick =
  (params: AnyFixMe) =>
  (dispatch: AnyFixMe, getState: AnyFixMe, { editorAPI }: AnyFixMe) =>
    baseDoubleClick.doubleClick(editorAPI, params);
const moveToFooter =
  (...args: AnyFixMe[]) =>
  (dispatch: AnyFixMe, getState: AnyFixMe, { editorAPI }: AnyFixMe) =>
    editorAPI.components.moveToFooter(...args);

const hideApplyModeFromClipboardSuggestion =
  (...args: AnyFixMe[]) =>
  (dispatch: AnyFixMe, getState: AnyFixMe, { editorAPI }: AnyFixMe) =>
    editorAPI.copyPaste.hideApplyModeFromClipboardSuggestion(...args);

const onDisplayNameLabelMouseDown = (event: React.MouseEvent) => {
  return (
    dispatch: AnyFixMe,
    getState: AnyFixMe,
    { editorAPI, host }: DispatchMapperArgs,
  ) => {
    const stateArgs = getStateMapperArgsFromEditorAPI(editorAPI);
    const draggable = draggableSel(stateArgs);
    if (draggable) {
      const initMousePosition = getMousePosition(editorAPI, event);
      const controllingParent = controllingParentSel(stateArgs);
      const comps = getSelectedCompsRefs(getState());

      const componentToBeDragged = controllingParent || comps;

      const baseDrag = host.getAPI(BaseDragApiKey);
      editorAPI.mouseActions.registerMouseMoveAction(baseDrag, {
        selectedComp: _.castArray(componentToBeDragged),
        shouldDragAndCopy: false,
        initMousePosition,
      });
    }
    event.stopPropagation();
  };
};

const mapDispatchToProps = (dispatch: AnyFixMe) => {
  return {
    isSameRef: (...args: AnyFixMe[]) => dispatch(isSameRef(...args)),
    biEvent(eventType: AnyFixMe, params: AnyFixMe) {
      dispatch(stateManagement.bi.actions.event(eventType, params));
    },
    isMultiSelectKeyPressed: (...args: AnyFixMe[]) =>
      dispatch(isMultiSelectKeyPressed(...args)),
    doubleClick: (params: AnyFixMe) => dispatch(doubleClick(params)),
    moveToFooter: (...args: AnyFixMe[]) => dispatch(moveToFooter(...args)),

    hideApplyModeFromClipboardSuggestion: (...args: AnyFixMe[]) =>
      dispatch(hideApplyModeFromClipboardSuggestion(...args)),
    showUserActionNotification: (data: AnyFixMe) =>
      dispatch(
        stateManagement.notifications.actions.showUserActionNotification(data),
      ),
    onDisplayNameLabelMouseDown: (ev: AnyFixMe) =>
      dispatch(onDisplayNameLabelMouseDown(ev)),
    setSessionUserPreferences: (key: AnyFixMe, value: AnyFixMe) =>
      dispatch(
        stateManagement.userPreferences.actions.setSessionUserPreferences(
          key,
          value,
        ),
      ),
  };
};

export { mapStateToProps, mapDispatchToProps };
