import _ from 'lodash';
import * as util from '@/util';
import constants from '@/constants';
import type {
  MapDispatchToProps,
  MapStateToProps,
  ThunkAction,
} from 'types/redux';
import type {
  UpgradePanelDispatchProps,
  UpgradePanelOwnProps,
  UpgradePanelStateProps,
} from './upgradePanel';
import type { SaveInteractionStartedSource } from 'types/fedops/saveInteraction';
export const mapStateToProps: MapStateToProps<
  UpgradePanelStateProps,
  UpgradePanelOwnProps
> = ({ editorAPI }) => {
  const metaSiteId =
    _.invoke(editorAPI, 'dsRead.generalInfo.getMetaSiteId') ||
    util?.editorModel?.metaSiteId;
  const metaSiteInstance = _.invoke(
    editorAPI,
    'dsRead.platform.getAppDataByApplicationId',
    constants.APPLICATIONS.META_SITE_APPLICATION_ID,
  )?.instance;
  const isFirstSave =
    _.invoke(editorAPI, 'dsRead.generalInfo.isFirstSave') || false;
  const isDraftMode =
    _.invoke(editorAPI, 'dsRead.generalInfo.isDraft') || false;
  const isActivePersonalSaleCampaign =
    editorAPI.campaigns.personalSale.isActiveCampaign();
  const personalSaleCampaignDiscountValue = isActivePersonalSaleCampaign
    ? editorAPI.campaigns.personalSale.getDiscountValue()
    : null;
  const dealerViewer = editorAPI?.wixReactDealerViewer?.DealerViewer;

  return {
    isFirstSave,
    isDraftMode,
    isActivePersonalSaleCampaign,
    personalSaleCampaignDiscountValue,
    metaSiteInstance,
    metaSiteId,
    dealerViewer,
  };
};

export const mapDispatchToProps: MapDispatchToProps<
  UpgradePanelDispatchProps,
  UpgradePanelOwnProps
> = {
  save:
    (options: {
      origin?: string;
      sourceOfStart: SaveInteractionStartedSource;
    }): ThunkAction =>
    (dispatch, getState, { editorAPI }) => {
      editorAPI.saveManager.save(options);
    },
  upgrade:
    (referralAdditionalInfo): ThunkAction =>
    (dispatch, getState, { editorAPI }) =>
      editorAPI.account.upgrade(referralAdditionalInfo),
};
