// @ts-nocheck
import * as stateManagement from '@/stateManagement';
import * as coreBi from '@/coreBi';

const getEditorAPI = (dispatch, getState, { editorAPI }) => editorAPI;

const mapDispatchToProps = (dispatch, { biEventOrigin }) => {
  const editorAPI = dispatch(getEditorAPI);

  return {
    closePanel: editorAPI.panelManager.closePanelByName,
    openHelpCenter: editorAPI.panelManager.openHelpCenter,
    sendBiEventForMenuUpgrade: () => {
      if (biEventOrigin) {
        const reportEvent = stateManagement.bi.actions.event;
        editorAPI.store.dispatch(
          reportEvent(coreBi.events.mobileEditor.menuContainer.UPGRADE, {
            origin: biEventOrigin,
          }),
        );
      }
    },
    upgradeToMenuContainer: () => {
      editorAPI.selection.deselectComponents();
      editorAPI.closeRightClickMenu();
      editorAPI.mobile.menuContainer.upgrade();

      editorAPI.waitForChangesApplied(() => {
        editorAPI.store.dispatch(
          stateManagement.inlinePopup.actions.closeAll(),
        );
        editorAPI.store.dispatch(
          stateManagement.selection.actions.selectComponents([
            editorAPI.mobile.menuContainer.toggle.get(),
          ]),
        );
        editorAPI.openFirstTimeOrDeprecationPanel(
          editorAPI.mobile.menuContainer.toggle.get(),
        );
        editorAPI.history.add('tinyMenu upgraded to menuContainer');
      });
    },
  };
};

export { mapDispatchToProps };
