import _ from 'lodash';
import { ErrorReporter } from '@wix/editor-error-reporter';
import { getPageSectionsWithLayout } from '../../api';
import { isMeshLayoutEnabled } from '@/layout';
import { editorWixRecorder } from '@/util';

import type { Scope, SectionWithLayout } from '@/sections';
import type { CompRef } from 'types/documentServices';

interface SectionData {
  yOld: number;
  y: number;
  ref: CompRef;
  height: number;
}

const calculateSequentialLayout = (
  sections: SectionWithLayout[],
): SectionData[] => {
  return sections.reduce<SectionData[]>((acc, curr, index) => {
    const prevSection = acc[index - 1];
    const section: SectionData = {
      yOld: curr.layout.y,
      height: curr.layout.height,
      ref: curr.ref,
      y: prevSection ? prevSection.y + prevSection.height : 0,
    };

    acc.push(section);

    return acc;
  }, []);
};

const getSectionsData = (
  sectionsWithLayout: SectionWithLayout[],
): SectionData[] => {
  // Useless rule
  // eslint-disable-next-line lodash/prop-shorthand
  const sortedSections = _.sortBy(sectionsWithLayout, (sec) => sec.layout.y);

  return calculateSequentialLayout(sortedSections);
};

export const removeSectionsGaps = async (scope: Scope, pageRef: CompRef) => {
  if (isMeshLayoutEnabled()) {
    // mesh handles gaps and overpaps between sections in other way
    return;
  }

  const { editorAPI } = scope;
  const sectionsWithLayout = getPageSectionsWithLayout(scope, pageRef);

  const sectionsData = getSectionsData(sectionsWithLayout);
  const sectionsToUpdate = sectionsData.filter(({ y, yOld }) => y !== yOld);

  if (sectionsToUpdate.length > 0) {
    sectionsToUpdate.forEach(({ y, ref }) => {
      editorAPI.components.layout.update(
        ref,
        {
          y,
        },
        true,
      );
    });

    const shouldReport = sectionsToUpdate.some(({ y, yOld }) => {
      // A lot of cases related to a bit different data from DM
      // Example yOld=6853.0001220703125 and y=6853
      // Ignore cases where difference between old and new less than 0.1px
      const hasSignificantDifference = Math.abs(y - yOld) >= 0.1;
      return hasSignificantDifference;
    });

    if (shouldReport) {
      const sentryData = {
        pageRef,
        sectionsWithLayout: JSON.stringify(sectionsWithLayout),
        sectionsToUpdate: JSON.stringify(sectionsToUpdate),
      };

      ErrorReporter.captureMessage('removing gaps between sections', {
        extra: sentryData,
      });

      ErrorReporter.breadcrumb('removing gaps between section', sentryData);

      editorWixRecorder.addLabel('removing gaps between section');
    }

    await editorAPI.waitForChangesAppliedAsync();
  }
};
