//eslint-disable-next-line @wix/santa-editor/scoped-imports
import {
  type BookingsWelcomeDispatchProps,
  mapDispatchToBookingsWelcomeSectionProps,
} from '@/addPanelData/mappers';
import { wrapWithPropsTransformer } from '../utils';

export interface BookingsWelcomeProps {
  bookingsMainAction: () => void;
}

export const bookingsWelcomeMapper = wrapWithPropsTransformer<
  BookingsWelcomeDispatchProps,
  BookingsWelcomeProps
>(mapDispatchToBookingsWelcomeSectionProps, (props) => ({
  bookingsMainAction: props.installBookings,
}));
