import React, { type FC, useState } from 'react';
import _ from 'lodash';
import { cx } from '@/util';
import withToolsItems, {
  type ToolsItem,
} from '../hocs/withToolsItems/withToolsItems';

import DropPanelList from '../dropPanel/dropPanelList';
import { withExtensionSlotMenuItems } from '../dropPanel/extensionSlot/withExtensionSlotMenuItems';

interface ToolsPanelProps {
  items: ToolsItem[];
  closePanel: () => void;
}

const ToolsPanel: FC<ToolsPanelProps> = (props) => {
  const [selectedIndex, setSelectedIndex] = useState(-1);

  return (
    <DropPanelList
      automationId="top-bar-tools-panel"
      className={cx('tools-panel-content', 'drop-panel-check-list')}
      items={props.items}
      closePanel={props.closePanel}
      onItemHover={setSelectedIndex}
      selectedIndex={selectedIndex}
      isChecklist={false}
      hasDesignPermissions={true}
    />
  );
};

const ConnectedToolsPanel = _.flow(
  withExtensionSlotMenuItems,
  withToolsItems,
)(ToolsPanel);

ConnectedToolsPanel.pure = ToolsPanel;

export default ConnectedToolsPanel;
