import type { Scope } from './contentInjectionEntryPoint';
import { BasePublicApi } from '@/apilib';
import { adapter } from '@/presetApi';
import experiment from 'experiment';
import { getPageContentInjectionMap } from './scan/scanPage';
import type { CompStructure } from '@wix/document-services-types';
import type { ContentInjectionMapOutput } from './scan/scanTypes';
import { handleInjectPresetContent } from './inject/injectPresetContent';
import { editorModel, isDebugMode, fedopsLogger } from '@/util';
import { ErrorReporter } from '@wix/editor-error-reporter';
import {
  initLocalStorageMsEntry,
  cleanCategoryContent,
} from './contentLock/contentLockUtil';

const DEFAULT_LANGUAGE = 'en';
const CONTENT_INJECTION_ORIGIN = 'contentInjection';

const caasConfig = {
  origin: CONTENT_INJECTION_ORIGIN,
  locale: editorModel.languageCode || DEFAULT_LANGUAGE,
};

export function isContentInjectionAvailableForSections(): boolean {
  return (
    experiment.isOpen('se_contentInjectionForSections') ||
    experiment.getValue('se_previewerOrContentInjectionInAddSection') ===
      'contentInjection'
  );
}

export function isContentInjectionAvailableForPages(): boolean {
  return (
    experiment.getValue('se_previewerOrContentInjectionInAddPage') ===
    'contentInjection'
  );
}

export function getCurrentPageContentInjectionMap(
  scope: Scope,
): ContentInjectionMapOutput[] {
  const { editorAPI } = scope;
  const currentPage = editorAPI.pages.getCurrentPage();
  return getPageContentInjectionMap(editorAPI, currentPage);
}

export async function initiateContentManager(scope: Scope): Promise<void> {
  await scope.siteGlobalDataAPI.getIsSiteGlobalDataReadyPromise();
  const businessType = scope.siteGlobalDataAPI.getBusinessType();
  const { industryId, structureId, displayName } = businessType;
  const businessName = await scope.siteGlobalDataAPI.getBusinessName();
  const replacements = {
    GeneralDescription: {
      BusinessName: businessName,
      BusinessType: displayName,
    },
  };
  const metaSiteId = scope.editorAPI.site.getMetaSiteId();
  const { ContentManager } = await adapter();
  const contentManager = new ContentManager(caasConfig);
  initLocalStorageMsEntry(scope.siteGlobalDataAPI, metaSiteId);
  try {
    fedopsLogger.interactionStarted(
      fedopsLogger.INTERACTIONS.CONTENT_INJECTION
        .CONTENT_INJECTION_CAAS_INITIALIZATION,
      { customParams: { industry_id: industryId, structure_id: structureId } },
    );
    await contentManager.initContent(industryId, structureId, replacements);
    fedopsLogger.interactionEnded(
      fedopsLogger.INTERACTIONS.CONTENT_INJECTION
        .CONTENT_INJECTION_CAAS_INITIALIZATION,
      { customParams: { industry_id: industryId, structure_id: structureId } },
    );
    scope.store.setContentManager(contentManager);
    scope.hooks.contentManagerReady.resolve();
  } catch (err) {
    ErrorReporter.captureException(err, {
      tags: { failedToInitiateContentManagerInContentInjection: true },
    });
    if (isDebugMode()) {
      console.log('Content Injection -> initiateContentManager Error: ', err);
    }
  }
}

export function getInjectedPreset(
  scope: Scope,
  compStructure: CompStructure,
  contentInjectionMap: ContentInjectionMapOutput[],
  presetId: string,
): CompStructure {
  return handleInjectPresetContent(
    scope,
    compStructure,
    contentInjectionMap,
    presetId,
  );
}

export function getContentManager(scope: Scope) {
  return scope.store.getContentManager();
}

export function cleanLockCategoryContent(
  scope: Scope,
  presetIds: string[],
  msId: string,
  isPage: boolean,
) {
  cleanCategoryContent(presetIds, msId, isPage);
}

export function getIsSectionsContentInjected(scope: Scope) {
  return scope.store.getIsSectionsContentInjected();
}

export function getIsPagesContentInjected(scope: Scope) {
  return scope.store.getIsPagesContentInjected();
}

export function setIsSectionsContentInjected(
  scope: Scope,
  isSectionsContentInjected: boolean,
) {
  return scope.store.setIsSectionsContentInjected(isSectionsContentInjected);
}

export function setIsPagesContentInjected(
  scope: Scope,
  isPagesContentInjected: boolean,
) {
  return scope.store.setIsPagesContentInjected(isPagesContentInjected);
}

export class ContentInjectionApi extends BasePublicApi<Scope> {
  getCurrentPageContentInjectionMap = this.bindScope(
    getCurrentPageContentInjectionMap,
  );
  getInjectedPreset = this.bindScope(getInjectedPreset);
  initiateContentManager = this.bindScope(initiateContentManager);
  getContentManager = this.bindScope(getContentManager);
  cleanLockCategoryContent = this.bindScope(cleanLockCategoryContent);
  getIsSectionsContentInjected = this.bindScope(getIsSectionsContentInjected);
  getIsPagesContentInjected = this.bindScope(getIsPagesContentInjected);
  setIsSectionsContentInjected = this.bindScope(setIsSectionsContentInjected);
  setIsPagesContentInjected = this.bindScope(setIsPagesContentInjected);
  isContentInjectionAvailableForSections = this.bindScope(
    isContentInjectionAvailableForSections,
  );
  isContentInjectionAvailableForPages = this.bindScope(
    isContentInjectionAvailableForPages,
  );
  hooks = {
    contentManagerReady: this.scope.hooks.contentManagerReady,
  };
}
