import React from 'react';
import { cx, hoc } from '@/util';
import constants from '@/constants';
import { createStructuredSelector } from '../selectors';
import { isMultiselectSel, layoutStyleSel } from '../selectors';

interface LayoutBoxOwnProps {
  componentUIColor: ValueOf<typeof constants.COMPONENT_UI_COLORS>;
  isSiteSegmentComponent: boolean;
  isSectionLikeBehaviour: boolean;
  shouldAddContainerInteraction: boolean;
  children: React.ReactNode;
}

interface LayoutBoxStateProps {
  layoutStyle: React.CSSProperties;
  isMultiselect: boolean;
}

interface LayoutBoxProps extends LayoutBoxOwnProps, LayoutBoxStateProps {}

const LayoutBox: React.FC<LayoutBoxProps> = (props) => {
  return (
    <div
      style={props.layoutStyle}
      data-aid="edit-box-layout"
      className={cx('layout', {
        'container-interaction-layout': props.shouldAddContainerInteraction,
        'secondary-component': props.isMultiselect,
        'components-ui-color-orange':
          props.componentUIColor === constants.COMPONENT_UI_COLORS.ORANGE,
        'site-segments-component': props.isSiteSegmentComponent,
        isSectionLike: props.isSectionLikeBehaviour,
      })}
    >
      {props.children}
    </div>
  );
};

const layoutBoxPropsSel = createStructuredSelector<LayoutBoxStateProps>({
  layoutStyle: layoutStyleSel,
  isMultiselect: isMultiselectSel,
});

export default hoc.connect(hoc.STORES.MOUSE_OPS, layoutBoxPropsSel)(LayoutBox);
