import {
  domMeasurements,
  type EditorState,
  hoverBox,
  sections,
} from '@/stateManagement';
import { fixedStage, sections as sectionsUtils } from '@/util';

import {
  SegmentIndicationAreaKeys,
  SegmentType,
} from './SiteSegmentIndication.constants';
import type { EditorAPI } from '@/editorAPI';
import type { CompRef } from 'types/documentServices';
import type {
  ThunkAction,
  MapStateToProps,
  MapDispatchToProps,
} from 'types/redux';
import type { SiteSegmentIndicationOwnProps } from './SiteSegmentIndication';

interface SegmentIndicationArea {
  key: SegmentIndicationAreaKeys;
  left: number;
  width: number;
  height: number;
  top: number;
}

export interface SiteSegmentIndicationStateProps {
  segmentRef: CompRef;
  isHovered: boolean;
  isMobile: boolean;
  isStageZoomMode: boolean;
  areas: SegmentIndicationArea[];
  shouldRenderStageSideAreaBorder: boolean;
}

export interface SiteSegmentIndicationDispatchProps {
  selectSiteSegment: (comp: CompRef) => ThunkAction;
  setHoveredSegment: (comp: CompRef) => ThunkAction;
  clearHoveredSegment: () => ThunkAction;
}

const {
  selectors: { getPreviewPosition },
} = domMeasurements;
const {
  actions: { setHoverBox },
  selectors: { getHoveredComp },
} = hoverBox;
const {
  actions: { setHoveredSectionLike },
} = sections;

const getAreas = (
  editorAPI: EditorAPI,
  state: EditorState,
  segmentRef: CompRef,
) => {
  const layout = editorAPI.components.layout.get_rect(segmentRef);
  const siteScale = editorAPI.getSiteScale();
  const previewPosition = getPreviewPosition(state);
  const stageXOffset = editorAPI.zoomMode.getStageXOffset();
  const left = previewPosition.width - stageXOffset;
  const top = layout.y * siteScale;
  const height = layout.height * siteScale;
  const width =
    sectionsUtils.constants.STAGE_SIDE_AREA_WIDTH_IN_ZOOM_MODE_AND_MOBILE;

  const leftArea: SegmentIndicationArea = {
    key: SegmentIndicationAreaKeys.Left,
    left: stageXOffset - width,
    width,
    height,
    top,
  };

  const rightArea: SegmentIndicationArea = {
    key: SegmentIndicationAreaKeys.Right,
    width,
    left,
    height,
    top,
  };

  return [leftArea, rightArea];
};

export const mapStateToProps: MapStateToProps<
  SiteSegmentIndicationStateProps,
  SiteSegmentIndicationOwnProps
> = ({ editorAPI, state }, { type }) => {
  const isHeaderSegment = type === SegmentType.Header;
  const segmentRef = isHeaderSegment
    ? editorAPI.siteSegments.getHeader()
    : editorAPI.siteSegments.getFooter();
  const hoveredComp = getHoveredComp(state);
  const isHovered =
    editorAPI.utils.isSameRef(hoveredComp, segmentRef) ||
    editorAPI.components.someAncestor(
      hoveredComp,
      (ancestor) => editorAPI.utils.isSameRef(ancestor, segmentRef),
      { includeScopeOwner: true },
    );
  const isMobile = editorAPI.isMobileEditor();
  const isStageZoomMode = editorAPI.zoomMode.isStageZoomMode();

  return {
    segmentRef,
    areas: getAreas(editorAPI, state, segmentRef),
    isHovered,
    isMobile,
    isStageZoomMode,
    shouldRenderStageSideAreaBorder: !fixedStage.isFixedStageEnabled(),
  };
};

const setHoveredSegment =
  (compRef: CompRef): ThunkAction =>
  (dispatch) => {
    dispatch(setHoveredSectionLike(compRef));
    dispatch(setHoverBox(compRef, false));
  };

const clearHoveredSegment = (): ThunkAction => (dispatch) => {
  dispatch(setHoveredSectionLike(null));
  dispatch(setHoverBox(null, false));
};

const selectSiteSegment =
  (compRef: CompRef): ThunkAction =>
  (dispatch, store, { editorAPI }) => {
    editorAPI.selection.selectComponentByCompRef(compRef);
  };

export const mapDispatchToProps: MapDispatchToProps<
  SiteSegmentIndicationDispatchProps,
  SiteSegmentIndicationOwnProps
> = {
  selectSiteSegment,
  setHoveredSegment,
  clearHoveredSegment,
};
