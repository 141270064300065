:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._wrapper_soo1m_7 {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15%;
  background-color: white;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 2px 10px 0 rgba(0, 0, 0, 0.08), 0 1px 20px 0 rgba(0, 0, 0, 0.08);
  position: absolute;
  right: 6px;
  top: 6px;
  width: 24px;
  height: 24px;
  cursor: pointer; }

._backgroundIcon_soo1m_21:hover {
  color: #0d99ff; }
