import React from 'react';
import styles from './workspaceModesLeftPanel.scss';
import { LeftPanelFrame } from '@/leftBar';
import { hoc } from '@/util';
import * as helpIds from '@/helpIds';
import { Button, Heading, RichText, Text } from '@wix/wix-base-ui';
import * as symbols from '@wix/santa-editor-symbols';
import { translate as t } from '@/i18n';
import { PanelManagerApiKey, WorkspaceModesApiKey } from '@/apis';

import type { EditorAPI } from '@/editorAPI';
import type { InferComponentProps } from '@/apilib';
import type { EditorState } from '@/stateManagement';
import type { StateMapperArgs, Dispatch } from 'types/redux';

interface WorkspaceModesLeftPanelOwnProps {}

type WorkspaceModesLeftPanelProps = InferComponentProps<
  any,
  typeof mapDispatchToProps,
  WorkspaceModesLeftPanelOwnProps
>;

const WorkspaceModesLeftPanel: React.FC<WorkspaceModesLeftPanelProps> = ({
  switchToFullEditor,
  openHelpCenter,
}: WorkspaceModesLeftPanelProps) => {
  return (
    <LeftPanelFrame
      panelName={'rEditor.panels.workspaceModesLeftPanel'}
      panelIndex={3}
      panelClass="workspace-modes-left-panel"
      automationId="workspace-modes-left-panel"
      className={styles.workspaceModesLeftPanel}
      removeDivider={true}
      showHeader={true}
      onHelpClicked={() =>
        openHelpCenter(helpIds.WORKSPACE_MODES.LEARN_MORE, null, {
          origin: 'workspace_modes_left_panel',
        })
      }
    >
      <symbols.symbol
        name={'workspaceModeLeftPanel-illustration'}
        className={styles.icon}
      />
      <RichText>
        <div className={styles['title-container']}>
          <Heading appearance="h4">
            {'LEFTBAR_lite_editor_full_editor_entry_point_title'}
          </Heading>
        </div>
        <div className={styles.subtitle}>
          <Text size="medium" weight="thin" enableEllipsis={false}>
            {'LEFTBAR_lite_editor_full_editor_entry_point_body'}
          </Text>
        </div>
        <Button
          onClick={switchToFullEditor}
          className={styles.button}
          automationId={'switch-to-full-editor-button'}
        >
          {t('LEFTBAR_lite_editor_full_editor_entry_point_CTA')}
        </Button>
      </RichText>
    </LeftPanelFrame>
  );
};

const getEditorAPI = (
  dispatch: Dispatch,
  getState: () => EditorState,
  { editorAPI }: StateMapperArgs,
) => editorAPI;

const mapDispatchToProps = (dispatch: AnyFixMe) => {
  const editorAPI: EditorAPI = dispatch(getEditorAPI);
  const workspaceModesApi = editorAPI.host.getAPI(WorkspaceModesApiKey);
  const panelManagerApi = editorAPI.host.getAPI(PanelManagerApiKey);
  return {
    switchToFullEditor: () => {
      panelManagerApi.closePanelByName(
        'rEditor.panels.workspaceModesLeftPanel',
      );
      workspaceModesApi.switchToFullMode('left bar three dots');
    },
    openHelpCenter: panelManagerApi.openHelpCenter,
  };
};

export default hoc.connect(
  hoc.STORES.EDITOR_API,
  null,
  mapDispatchToProps,
)(WorkspaceModesLeftPanel);
