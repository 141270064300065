import { BasePublicApi } from '@/apilib';
import { WORKSPACE_RIGHT_PANEL_NAMES } from '@/constants';
import { biLogger, keyboardShortcuts } from '@/util';
import { helpClick, panelHeaderClose } from '@wix/bi-logger-editor/v2';

import type { AiWriterPanelScope } from './aiWriterPanelScope';
import type { AiWriterPanelBiData } from './aiWriterPanelStore';

import { translate } from '@/i18n';

export function openAiWriterPanel(
  scope: AiWriterPanelScope,
  biData?: AiWriterPanelBiData,
) {
  const { workspaceRightPanelApi, store, editorAPI } = scope;
  store.setBiData(biData);

  workspaceRightPanelApi.open('aiWriter', {
    panelName: WORKSPACE_RIGHT_PANEL_NAMES.AI_PAGE_WRITER,
    panelWidth: 288,
    title: translate('ai_text_to_page_panel_header'),
    helpId: '6ace9e33-3ebf-4ab7-a3ee-368d9a1d36c5',
    onClose: (closeOrigin: string) => {
      editorAPI.panelManager.closeAllPanels();
      biLogger.report(
        panelHeaderClose({
          panel_name: WORKSPACE_RIGHT_PANEL_NAMES.AI_PAGE_WRITER,
          origin: closeOrigin,
        }),
      );
    },
    onHelp: () => {
      biLogger.report(
        helpClick({
          panel_name: WORKSPACE_RIGHT_PANEL_NAMES.AI_PAGE_WRITER,
          helpId: '6ace9e33-3ebf-4ab7-a3ee-368d9a1d36c5',
          origin: 'AI Writer panel help button click',
        }),
      );
    },
  });
  keyboardShortcuts.setContext(keyboardShortcuts.CONTEXTS.AI_WRITER_PANEL);
}

export class AiWriterApi extends BasePublicApi<AiWriterPanelScope> {
  openAiWriterPanel = this.bindScope(openAiWriterPanel);
}
