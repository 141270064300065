const i18n = require("@/i18n");

module.exports = {
  id: "Stylable_StylableButton_20",
  preset: { rect: { width: 130, height: 71, x: 194, y: 529 }, tags: null },
  structure: {
    parent: "comp-knzvqsjj",
    style: {
      styleType: "custom",
      componentClassName: "wixui.StylableButton",
      style: {
        groups: {},
        properties: {
          "$st-css":
            ":import{\n    -st-from: 'wix-ui-santa/index.st.css';\n    -st-named: StylableButton\n}\n.root{\n    -st-extends: StylableButton;\n    transition: all 0.2s ease, visibility 0s;\n    background: rgba(0,0,0,0);\n    padding: 4px;\n    border-bottom: 1px solid #0C4426\n}\n.root:hover {\n    border-bottom: 2px solid rgb(12, 68, 38);\n}\n.root:disabled{\n    background: rgba(226,226,226,0)\n}\n.root:disabled::label{\n    color: #8F8F8F\n}\n.root:disabled::icon{\n    fill: #8F8F8F\n}\n.root::container{\n    transition: inherit\n}\n.root::label{\n    transition: inherit;\n    font-style: normal;\n    font-family: oswald-extralight,oswald,sans-serif;\n    font-weight: 700;\n    letter-spacing: 0em;\n    font-size: 19px;\n    color: #0C4426;\n    text-decoration-line: none\n}\n.root::icon{\n    transition: inherit;\n    width: 10px;\n    height: 10px;\n    fill: value(site_1_1);\n    display: none\n}",
        },
        propertiesSource: { "$st-css": "value" },
      },
      pageId: "",
      compId: "",
      type: "ComponentStyle",
      metaData: {
        isHidden: false,
        sig: "uhr-872",
        pageId: "c1dmp",
        schemaVersion: "1.0",
        isPreset: false,
      },
      skin: "wixui.skins.Skinless",
    },
    activeModes: {},
    data: {
      type: "StylableButton",
      metaData: {
        isHidden: false,
        sig: "7ud-115",
        pageId: "c1dmp",
        schemaVersion: "1.0",
        isPreset: false,
      },
      label: i18n.translate("buttons_text_preset_back_to_top"),
      svgId: "b861b040274141de9c08999ec3b76edf.svg",
    },
    components: [],
    componentType: "wixui.StylableButton",
    id: "comp-km96x0q6",
    layout: {
      x: 203,
      fixedPosition: false,
      y: 548,
      scale: 1,
      height: 30,
      rotationInDegrees: 0,
      width: 110,
    },
    type: "Component",
    props: {
      type: "StylableButtonProperties",
      metaData: { schemaVersion: "1.0", sig: "hmj-29", pageId: "c1dmp" },
    },
    metaData: { sig: "5vr-2300", pageId: "c1dmp" },
    skin: "wixui.skins.Skinless",
  },
};
