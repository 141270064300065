._wrapper_1tyzj_1 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%; }

._form-wrapper_1tyzj_7 {
  margin: 0 -24px 14px;
  padding-right: 12px; }

._multiline-input_1tyzj_11 {
  height: 108px !important; }

._submit-wrapper_1tyzj_14 {
  display: flex;
  justify-content: center;
  margin-bottom: 24px; }

._terms-wrapper_1tyzj_19 {
  margin: auto -24px 0; }
