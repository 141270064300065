import _ from 'lodash';
import constants from '@/constants';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import * as util from '@/util';
import * as panels from '@/panels';
import { translate } from '@/i18n';
import * as helpIds from '@/helpIds';
import * as leftBar from '@/leftBar';
import { connectWithScope } from '@/apilib';
import { ErrorReporter } from '@wix/editor-error-reporter';
import { addPanelDragToStage } from '@/addPanelInfra';
import { fedopsLogger } from '@/util';
import { CustomScroll, Button } from '@wix/wix-base-ui';
import { BusinessTypePanelLink } from '@/baseUI';
import { cx } from '@/util';

import { getPreviewerPreviewMaxHeight } from '../addPresetUtil';

import AddSectionThumbnail from './components/addSectionThumbnail';
import AddPresetPreviewerPreview from '../previews/addPresetPreviewerPreview';
import AddPresetHtmlPreview from '../previews/addPresetHtmlPreview';
import AddSectionRawHtmlPreview from '../previews/addSectionRawHtmlPreview';

import AddSectionCategories from './components/addSectionCategories';
import SavedSectionEmptyState from './components/savedSectionsEmtpyState';
import { mapStateToProps, mapDispatchToProps } from './addSectionPanelMapper';
import htmlThumbnailDragBox from '../htmlPreviewThumbnail/htmlThumbnailDragBox';
import AISectionCreator from './components/aiSectionCreator';
import experiment from 'experiment';

import styles from './addSectionPanel.scss';

import type { EditorInteractionName } from 'types/fedops';
import type {
  AddSectionPanelDispatchProps,
  AddSectionPanelOwnProps,
  AddSectionPanelStateProps,
} from './addSectionPanelMapper';
import { AddPresetScope } from '../addPresetApiEntry';
import type {
  SectionPresetDefinition,
  ExtraPreviewProps,
  PreviewComponentsEntry,
  PresetReadyEntry,
} from '../types';
import { ADD_SECTION_PANEL_CONTENT_WIDTH } from '../addPresetConsts';
import { CREATE_WITH_AI_CATEGORY_ID, SavedSectionCategory } from './consts';
import { AddSectionCategoryHeader } from './components/addSectionCategoryHeader';
import {
  AddSectionCategoryAdditionalInfo,
  type AddSectionCategoryAdditionalInfoProps,
} from './components/addSectionCategoryAdditionalInfo';

const { withDragToStage } = util.hoc;

interface AddSectionPanelProps
  extends AddSectionPanelStateProps,
    AddSectionPanelOwnProps,
    AddSectionPanelDispatchProps {}

let startLoadTime: number | undefined;
let firstReadyFired = false;

const setStartLoadTime = () => {
  startLoadTime = performance.now();
};

const AddSectionPanel: React.FC<AddSectionPanelProps> = ({
  language,
  panelName,
  categories,
  stageEntryIndex,
  getPreviewComponentsEntry,
  startItemDrag,
  openHelpCenter,
  onSectionClick,
  onSectionDragEnd,
  onSectionAddFromContextMenu,
  currentSiteThemeMap,
  currentSiteFontsUrls,
  updatePanelOpenCount,
  initialSelectedCategory,
  isCreateSectionWithAIEnabled,
  sendCategoryLoadBiEvent,
  cleanContentLockCategoryContent,
  setPanelSelectedCategory,
  getBlankSectionStructure,
  sendContentScrolledEvent,
  addBlankSectionStructure,
  sendSectionsLoadedPhaseEvent,
  onClose,
  sendSectionsTooltipHoverEvent,
  loadSavedSections,
  savedSections,
  isContentInjectionAvailableForSections,
  openPanelInteraction,
  waitForZoomOutAndDisableMeasureMaps,
  enableMeasureMaps,
  loadSections,
  origin,
}: AddSectionPanelProps) => {
  useEffect(() => {
    openPanelInteraction?.end();
    updatePanelOpenCount();
    ErrorReporter.breadcrumb('addSection panel opened');
    // mapDispatchToProps is run 2 times, so we can't add updatePanelOpenCount as a dependency here
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (experiment.isOpen('se_disableMeasureMapsInZoom')) return;
    waitForZoomOutAndDisableMeasureMaps();

    return () => {
      enableMeasureMaps();
    };
    // mapDispatchToProps is run 2 times, so we can not add them as deps here
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [selectedCategoryIndex, setSelectedCategory] = useState<number>(
    initialSelectedCategory,
  );
  const [presetSections, setSections] = useState<SectionPresetDefinition[]>([]);
  const [previewerReactComponents, setPreviewerReactComponents] =
    useState<PreviewComponentsEntry>({});
  const [presetsReady, setPresetsReady] = useState<PresetReadyEntry>({});
  const savedSectionSelected =
    categories[selectedCategoryIndex].id === SavedSectionCategory.id;
  const createWithAiSectionSelected =
    categories[selectedCategoryIndex].id === CREATE_WITH_AI_CATEGORY_ID;
  const sections = savedSectionSelected ? savedSections : presetSections;
  const [isSectionsAllReady, setIsSectionsAllReady] = useState(false);

  const tooltipHoverStartTime = useRef(undefined);
  const allPresetsLoadPromise = useRef<Promise<void>[]>(null);
  const presetsResolveMap = useRef(null);
  const sectionCategoryAdditionalInfoWrapperRef =
    useRef<HTMLInputElement | null>(null);
  const sectionCategoryAdditionalInfoHeight =
    sectionCategoryAdditionalInfoWrapperRef?.current?.clientHeight ?? 0;
  const heightRelativeToParentOfSectionPanelContent =
    sectionCategoryAdditionalInfoHeight
      ? `calc(100% - ${sectionCategoryAdditionalInfoHeight}px)`
      : '100%';

  const onceSendFirstPresetReadyEvent = () => {
    const endTime = performance.now();
    sendSectionsLoadedPhaseEvent(
      endTime - startLoadTime,
      categories[selectedCategoryIndex].title,
      'firstReady',
    );
    fedopsLogger.interactionEnded(
      fedopsLogger.INTERACTIONS.ADD_SECTION_PREVIEW_LOAD
        .ADD_SECTION_PREVIEWER_FIRST_READY,
      {
        customParams: {
          categoryId: categories[selectedCategoryIndex]?.id,
          categoryName: categories[selectedCategoryIndex]?.title,
        },
      },
    );
    firstReadyFired = true;
  };
  const debouncedScrollHandler = useMemo<
    (scrollTop: number, categoryTitle: string) => void
  >(
    () => _.debounce(sendContentScrolledEvent, 300),
    [sendContentScrolledEvent],
  );

  const shouldUseNewPreview =
    isContentInjectionAvailableForSections ||
    experiment.isOpen('se_previewerIntegrationAddSection') ||
    experiment.getValue('se_previewerOrContentInjectionInAddSection') ===
      'previewer';

  const hasSections = sections.length > 0;

  useEffect(() => {
    setIsSectionsAllReady(false);
    return () => {
      setPanelSelectedCategory(selectedCategoryIndex);
    };
  }, [selectedCategoryIndex, setPanelSelectedCategory]);

  const setPreviewerReactComponentsWithBatches = async (
    sections: SectionPresetDefinition[],
    overridePresetReady: boolean = false,
  ) => {
    const firstBatch = sections.slice(0, 3);
    const secondBatch = sections.slice(3, sections.length);
    for (const sectionsBatch of [firstBatch, secondBatch]) {
      await updatePreviewerComponentsWithBatches(
        sectionsBatch,
        overridePresetReady,
      );
    }
  };

  const updatePreviewerComponentsWithBatches = async (
    sections: SectionPresetDefinition[],
    overridePresetReady: boolean = false,
  ) => {
    getPreviewComponentsEntry(
      sections,
      onPreviewReady,
      categories[selectedCategoryIndex],
      overridePresetReady,
    ).then((previewerComponents: PreviewComponentsEntry) => {
      setPreviewerReactComponents(
        (lastPreviewerReactComponents: PreviewComponentsEntry) => ({
          ...lastPreviewerReactComponents,
          ...previewerComponents,
        }),
      );
    });
  };

  const loadPresets = (overridePresetReady?: boolean) => {
    sendCategoryLoadBiEvent(categories[selectedCategoryIndex].title);

    const categoryId = categories[selectedCategoryIndex].id;

    setPresetsReady({});
    setPreviewerReactComponents({});

    if (createWithAiSectionSelected) return;

    const loadSectionsFn = () => {
      if (savedSectionSelected) return loadSavedSections();
      return loadSections(categoryId, language);
    };
    loadSectionsFn().then((sections) => {
      presetsResolveMap.current = {};
      allPresetsLoadPromise.current = sections.map(
        ({ _id }) =>
          new Promise<void>((resolve) => {
            presetsResolveMap.current[_id] = resolve;
          }),
      );

      if (shouldUseNewPreview) {
        setPreviewerReactComponentsWithBatches(sections, overridePresetReady);
      }
      setSections(sections);
      Promise.all(allPresetsLoadPromise.current).then(() => {
        setIsSectionsAllReady(true);
        const endTime = performance.now();
        sendSectionsLoadedPhaseEvent(
          endTime - startLoadTime,
          categories[selectedCategoryIndex].title,
          'allReady',
        );
        fedopsLogger.interactionEnded(
          fedopsLogger.INTERACTIONS.ADD_SECTION_PREVIEW_LOAD
            .ADD_SECTION_PREVIEWER_ALL_READY,
          {
            customParams: {
              categoryId: categories[selectedCategoryIndex]?.id,
              categoryName: categories[selectedCategoryIndex]?.title,
            },
          },
        );
      });
    });
  };

  useEffect(() => {
    util.fedopsLogger.interactionStarted(
      util.fedopsLogger.INTERACTIONS.ADD_SECTION_PREVIEW_LOAD
        .ADD_SECTION_PREVIEWER_FIRST_READY,
      {
        customParams: {
          categoryId: categories[selectedCategoryIndex]?.id,
          categoryName: categories[selectedCategoryIndex]?.title,
        },
      },
    );
    const interaction =
      util.fedopsLogger.INTERACTIONS.ADD_SECTION_PREVIEW_LOAD
        .ADD_SECTION_PREVIEWER_ALL_READY;
    util.fedopsLogger.interactionStarted(interaction, {
      customParams: {
        categoryId: categories[selectedCategoryIndex]?.id,
        categoryName: categories[selectedCategoryIndex]?.title,
      },
    });
    firstReadyFired = false;
    setStartLoadTime();
    loadPresets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategoryIndex]);

  useEffect(() => {
    setStartLoadTime();
    util.keyboardShortcuts.setContext(
      util.keyboardShortcuts.CONTEXTS.ADD_SECTION_PANEL,
    );
    return () => {
      util.keyboardShortcuts.setContext(util.keyboardShortcuts.CONTEXTS.EDITOR);
      onClose?.();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setPresetReady = (id: string, overridePresetReady?: boolean) => {
    if (!presetsReady[id] || overridePresetReady) {
      setPresetsReady((updatedPresetsReady) => ({
        ...updatedPresetsReady,
        [id]: true,
      }));
    }
  };

  const {
    tooltipDescription,
    tooltipTitle,
    longTitle,
    additionalCategoryInfo,
    name,
    isAppInstallRequired,
  } = categories[selectedCategoryIndex];

  const appName = additionalCategoryInfo?.appName || name;

  const onContentScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop } = e.target as HTMLDivElement;
    debouncedScrollHandler(scrollTop, categories[selectedCategoryIndex].title);
  };

  const handleTooltipOpen = () => {
    tooltipHoverStartTime.current = performance.now();
  };

  const handleTooltipClose = () => {
    if (tooltipHoverStartTime) {
      const endTime = performance.now();
      const duration = endTime - tooltipHoverStartTime.current;
      sendSectionsTooltipHoverEvent(
        duration,
        categories[selectedCategoryIndex].title,
      );
    }
    tooltipHoverStartTime.current = undefined;
  };

  const onPreviewReady = (
    sectionId: string,
    fedopsInteractionKey: EditorInteractionName,
    overridePresetReady?: boolean,
  ) => {
    if (!presetsReady[sectionId] || overridePresetReady) {
      setPresetReady(sectionId, overridePresetReady);
      if (!firstReadyFired) {
        onceSendFirstPresetReadyEvent();
      }
      presetsResolveMap?.current?.[sectionId]?.(); // TODO remove when saved section store ready
      fedopsLogger.interactionEnded(fedopsInteractionKey, {
        customParams: {
          presetId: sectionId,
          categoryId: categories[selectedCategoryIndex]?.id || '',
          categoryName: categories[selectedCategoryIndex]?.title || '',
        },
      });
    }
  };

  const getPreview = (
    sectionDefinition: SectionPresetDefinition,
    previewProps: ExtraPreviewProps,
  ) => {
    const isNewPreview =
      sectionDefinition.shouldUsePreviewer && shouldUseNewPreview;

    const sectionId = sectionDefinition._id;

    const isSavedSectionCategory =
      categories[selectedCategoryIndex].id === SavedSectionCategory.id;

    const fedopsSectionsInteractions =
      fedopsLogger.INTERACTIONS.ADD_SECTION_PREVIEW_LOAD;

    if (isSavedSectionCategory) {
      return (
        <AddSectionRawHtmlPreview
          {...previewProps}
          key={sectionId}
          sectionDefinition={sectionDefinition}
          stageEntryIndex={stageEntryIndex}
          onReady={() => {
            onPreviewReady(
              sectionId,
              fedopsSectionsInteractions.ADD_SECTION_RAW_HTML_PREVIEW_LOAD,
            );
          }}
        />
      );
    }

    if (isNewPreview) {
      return (
        <AddPresetPreviewerPreview
          id={sectionId}
          displayMinHeight={getPreviewerPreviewMaxHeight(sectionDefinition)}
          interactionKey={
            fedopsSectionsInteractions.ADD_SECTION_PREVIEWER_PREVIEW_LOAD
          }
          previewComponent={previewerReactComponents?.[sectionId]?.reactElement}
          isPresetReady={presetsReady[sectionId]}
        />
      );
    }

    return (
      <AddPresetHtmlPreview
        key={sectionId}
        previewHtmlUrl={sectionDefinition.previewHtmlUrl}
        fontsUrls={currentSiteFontsUrls}
        containerWidth={ADD_SECTION_PANEL_CONTENT_WIDTH}
        currentSiteThemeMap={currentSiteThemeMap}
        themeMap={sectionDefinition.themeMap}
        onReady={() => {
          onPreviewReady(
            sectionId,
            fedopsSectionsInteractions.ADD_SECTION_HTML_PREVIEW_LOAD,
          );
        }}
        height={previewProps.height}
        interactionKey={
          fedopsSectionsInteractions.ADD_SECTION_HTML_PREVIEW_LOAD
        }
      />
    );
  };

  const getAddSectionThumbnail = (
    sectionDefinition: SectionPresetDefinition,
  ) => (
    <AddSectionThumbnail
      onSectionDragEnd={onSectionDragEnd}
      getBlankSectionStructure={getBlankSectionStructure}
      onSectionClick={onSectionClick}
      compStructureToAdd={
        previewerReactComponents?.[sectionDefinition?._id]?.compStructure
      }
      onSectionAddFromContextMenu={onSectionAddFromContextMenu}
      startItemDrag={startItemDrag}
      key={sectionDefinition._id}
      sectionDefinition={sectionDefinition}
      categoryDefinition={categories[selectedCategoryIndex]}
      stageEntryIndex={stageEntryIndex}
      isAppInstallRequired={isAppInstallRequired}
      appName={appName}
      isPresetReady={presetsReady?.[sectionDefinition._id]}
      renderPreview={(previewProps) =>
        getPreview(sectionDefinition, previewProps)
      }
    />
  );

  const addSectionCategoryAdditionalInfo = () => {
    const addSectionCategoryAdditionalInfoProps: AddSectionCategoryAdditionalInfoProps =
      {
        appName,
        appIcon: additionalCategoryInfo.appIcon,
        description: additionalCategoryInfo.description,
      };

    return (
      <div
        className={styles.sectionCategoryAdditionalInfoWrapper}
        ref={sectionCategoryAdditionalInfoWrapperRef}
      >
        <AddSectionCategoryAdditionalInfo
          {...addSectionCategoryAdditionalInfoProps}
        />
      </div>
    );
  };

  const addSectionPanelContent = () => {
    const className = cx(styles.addSectionPanelContent, {
      [styles.addSectionPanelContent_topIndent]: isAppInstallRequired,
      'add-section-panel-content-all-ready': isSectionsAllReady,
    });

    return (
      <div
        data-aid="add-section-panel-content"
        className={className}
        style={{ width: ADD_SECTION_PANEL_CONTENT_WIDTH }}
        onScroll={onContentScroll}
      >
        {createWithAiSectionSelected
          ? addSectionCreatedWithAI()
          : addSectionPanelDefaultContent()}
      </div>
    );
  };

  const addSectionCreatedWithAI = () => (
    <AISectionCreator
      onSectionDragEnd={onSectionDragEnd}
      onSectionClick={onSectionClick}
      onSectionAddFromContextMenu={onSectionAddFromContextMenu}
      startItemDrag={startItemDrag}
      stageEntryIndex={stageEntryIndex}
      origin={origin}
    />
  );

  const addSectionPanelDefaultContent = () => (
    <>
      {hasSections && !isAppInstallRequired && (
        <AddSectionCategoryHeader
          longTitle={longTitle}
          tooltipTitle={tooltipTitle}
          tooltipDescription={tooltipDescription}
          linkText={categories[selectedCategoryIndex]?.linkText}
          handleTooltipOpen={handleTooltipOpen}
          handleTooltipClose={handleTooltipClose}
          onLinkClick={() =>
            openHelpCenter(categories[selectedCategoryIndex]?.helpId, null, {
              origin: 'saved_section_tooltip',
            })
          }
        />
      )}

      {experiment.isOpen('se_lockRefresh') &&
        hasSections &&
        !isAppInstallRequired && (
          <div
            style={{
              gridTemplateColumns: '1fr 1fr',
              gridGap: '12px',
              margin: 10,
              marginLeft: 0,
              marginBottom: 20,
            }}
          >
            <Button
              fullWidth={true}
              disabled={!presetsReady[sections[1]._id]}
              onClick={() => {
                setPreviewerReactComponents({});
                cleanContentLockCategoryContent(
                  sections.map((sectionDefinition) => sectionDefinition._id),
                  false,
                );
                loadPresets(true);
              }}
            >
              {'Refresh Content'}
            </Button>
          </div>
        )}

      {!hasSections && savedSectionSelected && (
        <SavedSectionEmptyState openHelpCenter={openHelpCenter} />
      )}

      {sections.map((sectionDefinition) =>
        getAddSectionThumbnail(sectionDefinition),
      )}
    </>
  );

  const LeftPanelFrame = util.workspace.isNewWorkspaceEnabled()
    ? leftBar.LeftPanelFrame
    : panels.frames.LeftPanelFrame;

  return (
    <LeftPanelFrame
      panelName={panelName}
      panelIndex={2}
      panelClass="add-section-panel"
      automationId="add-section-panel"
      className={styles.addSectionPanel}
      label={translate('section_add_section_panel_title')}
      onHelpClicked={() =>
        openHelpCenter(helpIds.addSectionPanel.PANEL_HELP_BUTTON, null, {
          origin: 'saved_section_tooltip',
        })
      }
      subtitle={
        isContentInjectionAvailableForSections ? (
          <BusinessTypePanelLink origin="Add Section" />
        ) : null
      }
    >
      <AddSectionCategories
        categories={categories}
        isCreateSectionWithAIEnabled={isCreateSectionWithAIEnabled}
        selectedCategory={selectedCategoryIndex}
        onCategoryChanged={setSelectedCategory}
        addBlankSectionStructure={addBlankSectionStructure}
      />
      <div
        className={cx(
          styles.addSectionPanelContentWrapper,
          styles.addSectionPanelContentWrapper_noScroll,
        )}
      >
        {isAppInstallRequired && addSectionCategoryAdditionalInfo()}
        <CustomScroll
          heightRelativeToParent={heightRelativeToParentOfSectionPanelContent}
        >
          {addSectionPanelContent()}
        </CustomScroll>
      </div>
    </LeftPanelFrame>
  );
};

export default connectWithScope(
  () => AddPresetScope,
  withDragToStage<AddSectionPanelProps>(
    {
      ...addPanelDragToStage,
      type: constants.MOUSE_ACTION_TYPES.ADD_SECTION_PANEL_DRAG_TO_STAGE,
    },
    htmlThumbnailDragBox,
  )(AddSectionPanel),
  mapStateToProps,
  mapDispatchToProps,
);
