import React, { Component } from 'react';
import _ from 'lodash';
import * as util from '@/util';
import * as UA from '@/uiAnimations';

import withCalcPanelPositionBehavior from './behaviors/withCalcPanelPositionBehavior';

import DropDownAnchor from './dropDownAnchor';
import DropDownPanel from './dropDownPanel';
import DropDownPortal from './dropDownPortal';

import type { PositionStyle } from './dropDownTypes';

const { topBarMenuPanelAnimation: DropDownAnimation } = UA;
const OuterClick = util.outerClick;

interface DropDownProps {
  panelClassName?: string;
  isOpen: boolean;
  delayedFadeOut?: boolean;

  panelPositionStyle?: PositionStyle;
  arrowPositionStyle?: PositionStyle;
  contentPositionStyle?: PositionStyle;

  onPanelMouseEnter: () => void;
  onPanelMouseLeave: () => void;
  onPanelOuterClick?: () => void;
  outerClickShouldStopPropagation?: boolean;
  recalcPositions?: () => void;

  panelContent: JSX.Element | null;
  anchorRef?: (ref: Node) => void;
  panelRef?: (ref: Node) => void;
  withOverlay?: boolean;
}

class DropDown extends Component<DropDownProps> {
  renderPanel() {
    const panelContent = React.cloneElement(this.props.panelContent, {
      recalcPositions: this.props.recalcPositions,
    });
    const panel = (
      <DropDownPanel
        className={this.props.panelClassName}
        nodeRef={this.props.panelRef}
        onMouseEnter={this.props.onPanelMouseEnter}
        onMouseLeave={this.props.onPanelMouseLeave}
        arrowPositionStyle={this.props.arrowPositionStyle}
        contentPositionStyle={this.props.contentPositionStyle}
        panelPositionStyle={this.props.panelPositionStyle}
      >
        {panelContent}
      </DropDownPanel>
    );

    return this.props.onPanelOuterClick ? (
      <OuterClick
        outerClickShouldStopPropagation={
          this.props.outerClickShouldStopPropagation
        }
        onOuterClick={this.props.onPanelOuterClick}
      >
        {panel}
      </OuterClick>
    ) : (
      panel
    );
  }

  renderPanelOverlay() {
    return this.props.withOverlay ? (
      <div className="top-bar-drop-down-overlay" />
    ) : null;
  }

  render() {
    const outAnimationDuration = this.props.delayedFadeOut ? 0.2 : 0;

    return (
      <DropDownAnchor nodeRef={this.props.anchorRef}>
        {this.props.children}
        <DropDownPortal>
          <DropDownAnimation outAnimationDuration={outAnimationDuration}>
            {this.props.isOpen ? (
              <>
                {this.renderPanel()}
                {this.renderPanelOverlay()}
              </>
            ) : null}
          </DropDownAnimation>
        </DropDownPortal>
      </DropDownAnchor>
    );
  }
}

const DropDownWithBehaviors = _.flow(withCalcPanelPositionBehavior)(DropDown);

DropDownWithBehaviors.pure = DropDown;

export default DropDownWithBehaviors;
