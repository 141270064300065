import constants from '@/constants';
import styles from './styles/emptyState.scss';
import React, { useEffect } from 'react';
import { Button, RichText, Heading, Text, TextButton } from '@wix/wix-base-ui';
import * as symbols from '@wix/santa-editor-symbols';
import { translate as t } from '@/i18n';
import { connectWithScope, type InferComponentProps } from '@/apilib';
import { QuickEditScope } from '../quickEditEntryPoint';
import { quickEditPanelOrigin } from '../consts';
import {
  reportClickNoComp,
  reportUnsupportedSectionOpened,
} from '../quickEditBi';

type EmptyStateProps = InferComponentProps<
  typeof mapStateToProps,
  typeof mapDispatchToProps,
  {}
>;

const EmptyState: React.FC<EmptyStateProps> = ({
  openAddSectionPanel,
  openAddPanel,
  reportEmptyStateOpened,
  fullEmptyState,
  areDefaultKeysVisible,
  goToFullEditor,
}) => {
  useEffect(() => {
    reportEmptyStateOpened();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!fullEmptyState) {
    return (
      <div
        data-hook="quick-edit-empty-state"
        className={styles.emptyStateContainer}
      >
        <symbols.symbol
          name={'Empty_State_Editor_Section Add_Elements'}
          className={styles.icon}
        />
        <RichText>
          <div className={styles['title-container']}>
            <Heading appearance="h4" multiline={true}>
              {'edit_section_content_permission_empty_state_title'}
            </Heading>
          </div>
          <div className={styles.subtitle}>
            <Text size="small" weight="normal" enableEllipsis={false}>
              {'edit_section_content_permission_empty_state_body'}
            </Text>
          </div>
        </RichText>
      </div>
    );
  }

  //TODO: refactor different panel?
  return (
    <div
      data-hook="quick-edit-empty-state"
      className={styles['empty-state-container']}
    >
      <symbols.symbol
        name={'Empty_State_Editor_Section Add_Elements'}
        className={styles.icon}
      />
      <RichText>
        <div className={styles['title-container']}>
          <Heading appearance="h4" multiline={true}>
            {areDefaultKeysVisible
              ? 'edit_section_empty_state_title'
              : 'lite_editor_blank_section_empty_state_title'}
          </Heading>
        </div>
        <div className={styles.subtitle}>
          <Text size="small" weight="normal" enableEllipsis={false}>
            {areDefaultKeysVisible
              ? 'edit_section_empty_state_body'
              : 'lite_editor_blank_section_empty_state_body'}
          </Text>
        </div>
        <Button
          onClick={areDefaultKeysVisible ? openAddPanel : openAddSectionPanel}
          className={styles.button}
        >
          {t(
            areDefaultKeysVisible
              ? 'edit_section_empty_state_main_cta'
              : 'lite_editor_blank_section_empty_state_main_cta',
          )}
        </Button>
        <TextButton
          size="small"
          className={styles.link}
          onClick={
            areDefaultKeysVisible
              ? openAddSectionPanel
              : () => {
                  goToFullEditor();
                  openAddPanel();
                }
          }
          children={
            areDefaultKeysVisible
              ? 'edit_section_empty_state_secondary_cta'
              : 'lite_editor_blank_section_empty_state_secondary_cta'
          }
        />
      </RichText>
    </div>
  );
};

export const mapStateToProps = (scope: QuickEditScope) => {
  const { editorRestrictionsApi } = scope;
  return {
    areDefaultKeysVisible: editorRestrictionsApi.allowed(
      'quick-edit_empty-state-default-keys.visible',
    ),
    fullEmptyState: editorRestrictionsApi.allowed(
      'quick-edit_full-empty-state.visible',
    ),
  };
};

const mapDispatchToProps = (scope: QuickEditScope) => {
  const { store, editorAPI, workspaceModesApi, workspaceRightPanelApi } = scope;
  return {
    openAddSectionPanel: async () => {
      reportClickNoComp(scope, 'add_section', 'empty_state');
      workspaceRightPanelApi.close('empty_state_link');
      editorAPI.panelManager.openPanel(
        constants.ROOT_COMPS.LEFTBAR.ADD_SECTION_PANEL_NAME,
        { origin: quickEditPanelOrigin },
      );
    },
    openAddPanel: async () => {
      reportClickNoComp(scope, 'add_elements', 'empty_state');
      workspaceRightPanelApi.close('empty_state_button');
      editorAPI.panelManager.openPanel(
        constants.ROOT_COMPS.LEFTBAR.ADD_PANEL_NAME,
        {
          origin: 'empty_state_button',
        },
      );
    },
    reportEmptyStateOpened: () =>
      reportUnsupportedSectionOpened(store, editorAPI),
    goToFullEditor: () => workspaceModesApi.switchToFullMode('quick edit'),
  };
};

export default connectWithScope(
  () => QuickEditScope,
  EmptyState,
  mapStateToProps,
  mapDispatchToProps,
);
