import React from 'react';
import { Text } from '@wix/wix-base-ui';

type TextProps = React.ComponentProps<typeof Text>;

export const GreyLabel: React.FC<TextProps> = ({
  children,
  size = 'tiny',
  shouldTranslate = false,
  ...props
}) => (
  <Text
    size={size}
    skin="placeholder"
    weight="bold"
    shouldTranslate={shouldTranslate}
    {...props}
  >
    {children}
  </Text>
);

export const BoldText: React.FC<TextProps> = ({ children, ...props }) => (
  <Text weight="bold" {...props}>
    {children}
  </Text>
);
