import _ from 'lodash';
import * as core from '@/core';
import * as coreBi from '@/coreBi';
import * as stateManagement from '@/stateManagement';
import type { EditorAPI } from '@/editorAPI';
import type { CompRef } from 'types/documentServices';

const { getStageLayout } = stateManagement.domMeasurements.selectors;
const notSelectableCompId = [
  'PAGES_CONTAINER',
  'SITE_PAGES',
  'BACK_TO_TOP_BUTTON',
];

function getPageComponents(editorAPI: EditorAPI) {
  const currentPageId = editorAPI.dsRead.pages.getFocusedPageId();
  const pageComponents =
    editorAPI.components.getAllComponents_DEPRECATED_BAD_PERFORMANCE(
      currentPageId,
    );
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/filter
  const filteredPageComponents = _.filter(pageComponents, function (comp) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/includes
    return !_.includes(notSelectableCompId, comp.id);
  });

  return filteredPageComponents;
}

function getNextComponent(
  editorAPI: EditorAPI,
  pageComponents: CompRef[],
  selectedComponentId: string,
): CompRef {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/find-index
  const currentComponentIndex = _.findIndex(pageComponents, {
    id: selectedComponentId,
  });
  const isLastIndex = currentComponentIndex + 1 === pageComponents.length;
  const nextIndex = isLastIndex ? 0 : currentComponentIndex + 1;
  if (!editorAPI.components.is.selectable(pageComponents[nextIndex])) {
    return getNextComponent(
      editorAPI,
      pageComponents,
      pageComponents[nextIndex].id,
    );
  }
  return pageComponents[nextIndex];
}

function getPreviousComponent(
  editorAPI: EditorAPI,
  pageComponents: CompRef[],
  selectedComponentId: string,
): CompRef {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/find-index
  const currentComponentIndex = _.findIndex(pageComponents, {
    id: selectedComponentId,
  });
  const prevIndex =
    currentComponentIndex === 0
      ? pageComponents.length - 1
      : currentComponentIndex - 1;
  if (!editorAPI.components.is.selectable(pageComponents[prevIndex])) {
    return getPreviousComponent(
      editorAPI,
      pageComponents,
      pageComponents[prevIndex].id,
    );
  }
  return pageComponents[prevIndex];
}

function getYDistance(stageLayout: { height: number }, componentY: number) {
  const stageMiddle = stageLayout.height / 2;

  return Math.max(componentY - stageMiddle, 0);
}

function scrollToElement(editorAPI: EditorAPI, compRef: CompRef) {
  const editorState = editorAPI.store.getState();
  const stageLayout = getStageLayout(editorState);
  const compAbsoluteLayout =
    editorAPI.components.layout.getRelativeToScreen(compRef);

  core.utils.scrollUtils.setScroll({
    scrollTop: getYDistance(stageLayout, compAbsoluteLayout.y),
  });
}

function selectAndMoveToComponent(editorAPI: EditorAPI, compRef: CompRef) {
  editorAPI.selection.selectComponentByCompRef(compRef);

  scrollToElement(editorAPI, compRef);
}

export default {
  selectNextComponent: function selectNextComponent(editorAPI: EditorAPI) {
    const selectedComponentId = editorAPI.selection.getSelectedComponentId();

    if (!selectedComponentId) {
      return;
    }

    const nextComp = getNextComponent(
      editorAPI,
      getPageComponents(editorAPI),
      selectedComponentId,
    );

    selectAndMoveToComponent(editorAPI, nextComp);
    editorAPI.bi.event(coreBi.events.shortcuts.tab_shift_tab_nav, {
      component_id: nextComp.id,
    });
  },

  selectPreviousComponent(editorAPI: EditorAPI) {
    const selectedComponentId = editorAPI.selection.getSelectedComponentId();

    if (!selectedComponentId) {
      return;
    }

    const prevComp = getPreviousComponent(
      editorAPI,
      getPageComponents(editorAPI),
      selectedComponentId,
    );

    selectAndMoveToComponent(editorAPI, prevComp);
    editorAPI.bi.event(coreBi.events.shortcuts.tab_shift_tab_nav, {
      component_id: prevComp.id,
    });
  },

  async moveComponent(
    editorAPI: EditorAPI,
    axis: 'x' | 'y',
    amountToMove: number,
  ) {
    const selectedComps = editorAPI.selection.getSelectedComponents();

    if (axis !== 'x' && axis !== 'y') {
      throw new Error('Invalid axis. Must be "x" or "y"');
    }

    const delta =
      axis === 'x' ? { deltaX: amountToMove } : { deltaY: amountToMove };

    if (
      selectedComps.length > 0 &&
      selectedComps.every((compRef) =>
        editorAPI.components.layout.canMoveBy(compRef, delta),
      )
    ) {
      await editorAPI.components.layout.moveBy(selectedComps, delta);
    }
  },
};
