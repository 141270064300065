import { pages } from '@/stateManagement';
import type { EditorAPI } from '@/editorAPI';
import type { EditorState } from '@/stateManagement';

export const shouldShowSegmentIndication = ({
  editorAPI,
  state,
}: {
  editorAPI: EditorAPI;
  state: EditorState;
}) => {
  const isStageZoomMode = editorAPI.zoomMode.isStageZoomMode();
  const pageId = pages.selectors.getFocusedPageId(state);
  const isLandingPage = editorAPI.pages.isLandingPage(pageId);

  if (isLandingPage) return false;

  return (
    isStageZoomMode && !editorAPI.zoomMode.isLeftShrinkedStageZoomOutActive()
  );
};
