import { caasPropNames, CAASMediaType } from './injectConsts';
import { reportErrorOnInjection } from '../contentInjectionUtil';

import type { ContentInjectionImage } from '../scan/scanTypes';
import type { ContentItem, MediaItem } from '@/presetApi';

export function isMediaInjection(propName: string): boolean {
  const { MEDIA } = caasPropNames;
  return MEDIA === propName;
}

export function handleMediaInjection(
  presetStructureString: string,
  mediaToReplace: ContentInjectionImage[],
  mediaToInject: ContentItem['media'],
) {
  if (!mediaToInject || !mediaToInject.length) {
    return presetStructureString;
  }
  let updatedStructureString = presetStructureString;

  // can be removed after all presets are rescanned with multiple media support
  const updatedInjectableMediaList =
    updateInjectableMediaListIfNeeded(mediaToReplace);

  try {
    updatedInjectableMediaList.forEach((injectableMediaItem, idx) => {
      const { uri, isSvg, isNoFaceImage } = injectableMediaItem;
      const filteredMediaToInject = maybeFilterMediaToInject(
        mediaToInject,
        isSvg,
        isNoFaceImage,
      );
      const uriToInject =
        filteredMediaToInject[idx]['uri' as keyof Partial<MediaItem>];
      if (uriToInject && uri) {
        updatedStructureString = updatedStructureString.replace(
          uri,
          uriToInject as string,
        );
      }
    });

    JSON.parse(updatedStructureString);
    return updatedStructureString;
  } catch (e) {
    reportErrorOnInjection(e, 'media');
    return presetStructureString;
  }
}

export function maybeFilterMediaToInject(
  mediaToInject: MediaItem[],
  isSvg: boolean,
  isNoFaceImage: boolean,
) {
  const filteredMediaToInject = filterUriListToInject(
    mediaToInject,
    isSvg,
    isNoFaceImage,
  );
  if (!filteredMediaToInject.length) {
    return filterUriListToInject(mediaToInject, isSvg, false);
  }
  return filteredMediaToInject;
}

export function filterUriListToInject(
  mediaToInject: MediaItem[],
  isSvg: boolean,
  isNoFaceImage: boolean,
): MediaItem[] {
  if (isSvg) {
    return mediaToInject.filter(
      (item: MediaItem) => item.mediaType === CAASMediaType.Shape,
    );
  }
  return mediaToInject.filter(
    (item: MediaItem) =>
      item.mediaType === CAASMediaType.Image &&
      maybeFilterNoFaceImage(item, isNoFaceImage),
  );
}

export function maybeFilterNoFaceImage(item: AnyFixMe, isNoFaceImage: boolean) {
  const isNoFaceInImageData = item?.fileInput?.face.length === 0;
  return isNoFaceImage ? isNoFaceInImageData : true;
}

// TODO: can be removed after all presets are rescanned with multiple media support
export function updateInjectableMediaListIfNeeded(
  injectableMedia: ContentInjectionImage | ContentInjectionImage[],
): ContentInjectionImage[] {
  if (Array.isArray(injectableMedia)) {
    return injectableMedia;
  }
  return [injectableMedia];
}
