/* eslint-disable no-console */
import React, { useState } from 'react';
import * as util from '@/util';
import * as symbols from '@wix/santa-editor-symbols';
import { calcRelativeHeight } from '../../addPresetUtil';
import {
  Button,
  TextLabel,
  InfoIcon,
  Tooltip,
  Text,
  TextButton,
} from '@wix/wix-base-ui';
import MultiplePresetIndication from './multiplePresetIndication';

import type {
  AddPageCategory,
  PagePresetDefinition,
  AddPageThumbnailPreviewProps,
} from '../../types';
import AddPageBulletsContent from './addPageBulletsContent';

interface AddPageThumbnailProps extends PagePresetDefinition {
  addPage: () => void;
  openPreview: () => void;
  containerWidth: number;
  isReplacePage: boolean;
  isPremiumSite: boolean;
  upgrade: (category: AddPageCategory, origin?: string) => void;
  category: AddPageCategory;
  tooltipShowEvent: () => void;
  isPresetReady: boolean;
  renderPreview: (props: any) => JSX.Element;
}

function getAddButtonLabel(
  isReplacePage: boolean,
  isMultiplePresets: boolean,
): string {
  if (isMultiplePresets) {
    return 'add_page_preset_add_button_dynamic_pages';
  }
  return isReplacePage
    ? 'add_page_preset_404_replace_popup_replace_button'
    : 'add_page_preset_add_button';
}

export const AddPageThumbnail = React.memo(
  ({
    isPremiumSite,
    upgrade,
    category,
    tooltipShowEvent,
    previewHeight,
    pageTitleKey,
    addPage,
    isPremium: isPremiumThumbnail,
    openPreview,
    isReplacePage,
    containerWidth,
    isMultiplePreset,
    infoIconData,
    isPresetReady,
    renderPreview,
  }: AddPageThumbnailProps) => {
    const [hovered, toggleHover] = useState(false);

    const shouldShowPremiumIcon = isPremiumThumbnail && !isPremiumSite;

    const premiumTooltipIconContent = (
      <div className="premium-icon-tooltip-content">
        <div className="premium-icon-tooltip-title">
          <Text
            size="large"
            skin="standard"
            weight="bold"
            enableEllipsis={false}
            children={'add_page_preset_404_premium_tooltip_title'}
          />
        </div>
        <div className="premium-icon-tooltip-description">
          <Text
            skin="standard"
            weight="thin"
            size="small"
            enableEllipsis={false}
            children={'add_page_preset_404_premium_tooltip_body'}
          />
        </div>
        <div>
          <TextButton
            skin="premium"
            weight="thin"
            size="small"
            onClick={() => upgrade(category, 'AddPagePanel_tooltip')}
            children={'add_page_preset_404_premium_tooltip_cta'}
          />
        </div>
      </div>
    );

    const thumbnailHeight = calcRelativeHeight(previewHeight, containerWidth);

    const previewProps: AddPageThumbnailPreviewProps = {
      height: thumbnailHeight,
    };

    return (
      <div
        data-aid="add-page-thumbnail"
        className={util.cx('add-page-thumbnail', {
          complete: isPresetReady,
          'multiple-preset': isMultiplePreset,
          hovered,
        })}
        data-section-name={pageTitleKey}
      >
        {renderPreview(previewProps)}
        {isMultiplePreset ? <MultiplePresetIndication /> : null}
        <div className="add-page-thumbnail-info-wrapper">
          <TextLabel
            type="T07"
            value={pageTitleKey}
            className="add-page-thumbnail-info-text"
          />
          {infoIconData ? (
            <InfoIcon
              tooltipMarginTop={6}
              tooltipMaxWidth={350}
              tooltipCustomContent={<AddPageBulletsContent {...infoIconData} />}
            />
          ) : null}
        </div>
        {shouldShowPremiumIcon ? (
          <Tooltip
            marginTop={10}
            content={premiumTooltipIconContent}
            interactive={true}
            maxWidth={205}
            onOpen={tooltipShowEvent}
          >
            <div className="premium-symbol">
              <symbols.symbol name="upgradeSmall" />
            </div>
          </Tooltip>
        ) : null}
        <div
          className="add-page-button-overlay"
          onClick={addPage}
          onMouseEnter={() => toggleHover(true)}
          onMouseLeave={() => toggleHover(false)}
        >
          <Button
            onClick={(e: React.MouseEvent) => {
              e.stopPropagation();
              addPage();
            }}
            className="primary add-page-thumbnail-button"
          >
            <TextLabel
              value={getAddButtonLabel(isReplacePage, isMultiplePreset)}
            />
          </Button>
          {!isMultiplePreset ? (
            <Button
              className="btn-confirm-secondary add-page-preview-button"
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation();
                openPreview();
              }}
            >
              <TextLabel value="add_page_preset_preview_button" />
            </Button>
          ) : null}
        </div>
      </div>
    );
  },
  (prevProps, nextProps) => {
    return prevProps.isPresetReady && nextProps.isPresetReady;
  },
);

AddPageThumbnail.displayName = 'AddPageThumbnail';
