"use strict";

const _ = require("lodash");
const mergePropsItems = require("./merge-props-items");

function mergePresetShape(item, shape) {
  // equivalent to `_.merge({}, item, {preset: {shape}}))`, but faster

  return {
    ...item,
    preset: {
      ...item.preset,
      shape,
    },
  };
}

/**
 * @param {Object} section
 * @param {Array} sectionShapes
 * @returns {Object}
 */
function mergeSectionImageMap(section, sectionShapes) {
  const { items } = section.props;
  const itemsWithMaps = _.map(items, (item, index) =>
    mergePresetShape(
      item,
      _.pick(sectionShapes[index], ["name", "coords", "path"])
    )
  );

  return mergePropsItems(section, itemsWithMaps);
}

module.exports = mergeSectionImageMap;
