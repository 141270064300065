import addPanelDataConsts from '@/addPanelDataConsts';
import type { EditorAPI } from '@/editorAPI';
import dailyAgendaWidgetConsts from './dailyAgendaWidgetConsts';
import editorActionsService from '../../common/editorActionsService';
import experiments from 'experiment';

export default function ({ editorAPI }: { editorAPI: EditorAPI }) {
  const isWixStudio = experiments.isOpen(
    'specs.responsive-editor.BookingsChangeImagesOnAddPanel',
  );
  const actionsService = editorActionsService({ editorAPI });
  const addPanelConsts = addPanelDataConsts;

  const dailyAgendaWidget = {
    id: dailyAgendaWidgetConsts.DAILY_AGENDA_PRESET_KEY,
    structure: {
      layout: {
        width: 980,
        height: 724,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.tpapps.TPAWidget',
      data: {
        type: 'TPAWidget',
        metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
        applicationId: '2269',
        tpaData: {
          type: 'TPAData',
          metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
          content: `{"presetId":"${dailyAgendaWidgetConsts.DAILY_AGENDA_PRESET_KEY}"}`,
        },
        widgetId: dailyAgendaWidgetConsts.DAILY_AGENDA_WIDGET_ID,
      },
      style: {
        type: 'TopLevelStyle',
        metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
        style: {
          properties: {},
          propertiesSource: {},
          groups: {},
        },
        componentClassName: 'wysiwyg.viewer.components.tpapps.TPAWidget',
        pageId: '',
        compId: '',
        styleType: 'custom',
        skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
      },
    },
    preset: {
      rect: isWixStudio
        ? { width: 324, height: 188, x: 0, y: 0 }
        : { width: 304, height: 171, x: 10, y: 10 },
      label: '',
      tags: null as any,
    },
  };

  const dailyAgendaWidgetItems = [dailyAgendaWidget];

  return {
    id: 'wixBookingsDailyAgendaWidget',
    type: addPanelConsts.SECTIONS_TYPES.PRESET,
    hide: false,
    title: dailyAgendaWidgetConsts.DAILY_AGENDA_TITLE_KEY,
    subNavigationTitle: dailyAgendaWidgetConsts.DAILY_AGENDA_TITLE_KEY,
    presetTitle: dailyAgendaWidgetConsts.DAILY_AGENDA_TITLE_KEY,
    tooltipTitle: dailyAgendaWidgetConsts.DAILY_AGENDA_TOOLTIP_TITLE_KEY,
    tooltipDescription:
      dailyAgendaWidgetConsts.DAILY_AGENDA_TOOLTIP_DESCRIPTION_KEY,
    showSectionHeader: true,
    additionalBehaviours: {
      labelMode: addPanelConsts.LABEL_BEHAVIOUR.ALWAYS,
      infoIcon: addPanelConsts.INFO_ICON_BEHAVIOUR.NONE,
      hoverImageAction: addPanelConsts.HOVER_IMAGE_ACTIONS.NONE,
      scaleProportion: addPanelConsts.DEFAULT_SCALE_FOR_PRESET,
      iconEnabledForComps: [] as AnyFixMe,
    },
    props: {
      image: isWixStudio
        ? dailyAgendaWidgetConsts.DAILY_AGENDA_WIDGET_WIX_STUDIO_IMAGE
        : dailyAgendaWidgetConsts.DAILY_AGENDA_WIDGET_IMAGE,
      imageHover: null as AnyFixMe,
      onDrop: actionsService.onRefWidgetDrop,
      items: dailyAgendaWidgetItems,
      compTypes: ['wysiwyg.viewer.components.tpapps.TPAWidget'],
    },
    onClick: actionsService.onRefWidgetClick,
  };
}
