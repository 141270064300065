:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._infoIconImageContainer_3fri5_7 {
  position: relative;
  cursor: pointer; }
  ._infoIconImageContainer_3fri5_7 ._infoIconImage_3fri5_7 {
    border: 4px solid #fff;
    border-radius: 6px;
    box-shadow: 0 0 0 1px #dfe5eb;
    width: 204px; }
    ._infoIconImageContainer_3fri5_7 ._infoIconImage_3fri5_7:hover {
      box-shadow: 0 0 0 2px #d6e6fe; }
  ._infoIconImageContainer_3fri5_7 ._playVideoIcon_3fri5_17 {
    position: absolute;
    top: calc(50% - 42px / 2);
    left: calc(50% - 42px / 2);
    width: 42px;
    height: 42px;
    border-radius: 50%;
    background-color: rgba(0, 6, 36, 0.7);
    border: 2px solid #fff; }
    ._infoIconImageContainer_3fri5_7 ._playVideoIcon_3fri5_17:hover {
      border-color: #80b1ff; }
    ._infoIconImageContainer_3fri5_7 ._playVideoIcon_3fri5_17 .st0 {
      fill: #fff; }
      ._infoIconImageContainer_3fri5_7 ._playVideoIcon_3fri5_17:hover .st0 {
        fill: #80b1ff; }
    ._infoIconImageContainer_3fri5_7 ._playVideoIcon_3fri5_17 .st1 {
      fill: transparent; }
