// @ts-nocheck
import _ from 'lodash';
import constants from '@/constants';
import * as helpIds from '@/helpIds';

const { ACTIONS } = constants.ROOT_COMPS.GFPP;
const panelActions = [
  ACTIONS.SETTINGS,
  ACTIONS.BEHAVIORS,
  ACTIONS.DESIGN,
  ACTIONS.MASK,
  ACTIONS.ANIMATION,
  ACTIONS.HELP,
];
const panelMobileActions = [
  ACTIONS.SETTINGS,
  ACTIONS.MASK,
  ACTIONS.HIDE,
  ACTIONS.HELP,
  ACTIONS.ANIMATION,
];

export default {
  untranslatable: true,
  mainActions: [
    {
      label: 'gfpp_mainaction_MediaPlayer',
      isApplied: true,
      onClick(editorAPI, compRef) {
        const { categories } = editorAPI.mediaServices.mediaManager;
        editorAPI.panelManager.closePanelByName(
          'compPanels.panels.MediaPlayer.settingsPanel',
        );
        editorAPI.panelManager.closePanelByName(
          'compPanels.panels.MediaPlayer.maskPanel',
        );
        editorAPI.mediaServices.changeVideo(compRef, categories.VIDEO_BOX);
      },
    },
  ],
  enabledActions: panelActions,
  mobileEnabledActions: panelMobileActions,
  presetActions: {
    help: {
      helpId(editorAPI, compRef) {
        const mediaFeatures =
          editorAPI.components.design.get(compRef)?.background?.mediaRef
            ?.mediaFeatures;
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/includes
        const hasAlpha = _.includes(mediaFeatures, 'alpha');
        const helpId = editorAPI.isMobileEditor()
          ? helpIds.GFPP.MEDIA_PLAYER_MOBILE
          : helpIds.GFPP.MEDIA_PLAYER;
        return hasAlpha ? helpIds.GFPP.MEDIA_PLAYER_ALPHA : helpId;
      },
    },
  },
};
