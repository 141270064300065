import React from 'react';
import { Divider, TextLabel } from '@wix/wix-base-ui';

import * as baseUI from '@/baseUI';
import { events } from '@/coreBi';

import { translate } from '@/i18n';
import { hoc, media } from '@/util';

import { mapStateToProps, mapDispatchToProps } from './DocumentLink.mapper';

const {
  connect,
  STORES: { EDITOR_API },
} = hoc;

export interface TDocumentLink {
  id?: string;
  type: string;
  name: string;
  docId: string;
  indexable?: boolean;
}

type DocumentLinkComponentProps = {
  link: TDocumentLink;
  onValidationUpdate(isValid: boolean): void;
  onLinkChange(link: TDocumentLink): void;
} & ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>;

interface DocumentLinkComponentState {
  prevLink: TDocumentLink;
  link: TDocumentLink;
  isPDFIndexable: boolean;
}

class DocumentLinkComponent extends React.Component<
  DocumentLinkComponentProps,
  DocumentLinkComponentState
> {
  constructor(props: AnyFixMe) {
    super(props);
    const link = props.link || props.createDefaultData('DocumentLink');

    this.state = {
      prevLink: link,
      link,
      isPDFIndexable: link.indexable,
    };
  }

  static getDerivedStateFromProps(
    props: DocumentLinkComponentProps,
    state: DocumentLinkComponentState,
  ) {
    const nextLink =
      state.prevLink?.id !== props.link?.id ? props.link : state.link;

    return {
      prevLink: props.link,
      link: nextLink,
      isPDFIndexable: nextLink.indexable,
    };
  }

  componentDidMount() {
    this.props.onLinkChange(this.state.link);
    this.props.onValidationUpdate(!!this.state.link.docId);
  }

  componentDidUpdate(_prevProps: AnyFixMe, prevState: AnyFixMe) {
    if (prevState.link !== this.state.link) {
      this.props.onLinkChange(this.state.link);
    }
    if (this.state.link.docId !== prevState.link.docId) {
      this.props.onValidationUpdate(!!this.state.link.docId);
    }
  }

  private setDocument = (newDocs: AnyFixMe) => {
    const newDoc = newDocs[0];
    const docId = newDoc.fileUrl;
    this.setState({
      link: {
        type: 'DocumentLink',
        docId,
        name: newDoc.title,
      },
      isPDFIndexable: this.props.checkIsIndexablePdf(docId),
    });
  };

  private handleShowPdfIndexing = () => {
    const { openPanel, sendBI, setLinksAsIndexableForDoc } = this.props;
    openPanel(
      'compPanels.panels.DocumentMedia.confirmationPanel',
      {
        onConfirm: () => {
          setLinksAsIndexableForDoc(this.state.link.docId);
          this.setState({
            isPDFIndexable: true,
            link: { ...this.state.link, indexable: true },
          });
          sendBI(events.documentMedia.PDF_INDEXING, {
            origin: 'linkPanel',
          });
        },
      },
      true,
    );
  };

  private openMediaGallery = () => {
    const { mediaServices } = this.props;
    const { mediaManager } = mediaServices;
    const category = mediaManager.categories.ALL_MEDIA;

    mediaManager.open(category, {
      callback: (_items: AnyFixMe, _info: AnyFixMe) => {
        //this._handleChangeImage(items, info);
        mediaServices.setFocusToEditor();
      },
      multiSelect: false,
    });
  };

  render() {
    return (
      <>
        <TextLabel value="LINK_PANEL_DOCUMENT_LABEL" enableEllipsis={false} />

        <baseUI.changeMediaInput
          buttonLabelWithItems="LINK_PANEL_DOCUMENT_BUTTON"
          buttonLabelWithoutItems="LINK_PANEL_DOCUMENT_BUTTON"
          selectionType="single"
          value={this.state.link ? this.state.link.name : ''}
          onChange={this.setDocument}
          mediaServices={this.props.mediaServices}
        />

        <div key="pdf-indexing-section-link">
          {media.isFilePDF(this.state.link.docId) ? (
            <Divider key="indexableSeparator" />
          ) : null}

          {media.isFilePDF(this.state.link.docId) ? (
            <div key="search-results-pdf" className="search-results-pdf">
              {this.state.isPDFIndexable ? (
                <div
                  key="search-results-pdf-inner-container"
                  className="search-results-pdf-inner-container"
                >
                  <span className="search-results-pdf-info-text">
                    {translate('LINK_PANEL_DOCUMENT_PDF_INDEXINGON_TEXT1')}{' '}
                  </span>
                  <span
                    onClick={this.openMediaGallery}
                    className="search-results-pdf-link-text"
                  >
                    {translate('LINK_PANEL_DOCUMENT_PDF_INDEXINGON_LINK')}
                  </span>
                </div>
              ) : null}

              {!this.state.isPDFIndexable ? (
                <span
                  key="search-results-pdf-text"
                  onClick={this.handleShowPdfIndexing}
                  className="search-results-pdf-text"
                >
                  {translate(
                    'Document_Settings_PDF_SeoSection_ShowPDFinResults_Link',
                  )}
                </span>
              ) : null}
            </div>
          ) : null}
        </div>
      </>
    );
  }
}

export const DocumentLink = connect(
  EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(DocumentLinkComponent);

DocumentLink.pure = DocumentLinkComponent;
