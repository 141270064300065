// @ts-nocheck
import _ from 'lodash';
import constants from '@/constants';
import * as util from '@/util';
import * as helpIds from '@/helpIds';
import * as stateManagement from '@/stateManagement';
import gfppDataUtils from '../../utils/gfppDataUtils';
import type { CompRef } from '@wix/document-services-types';
import type { EditorAPI } from '@/editorAPI';

const { ACTIONS } = constants.ROOT_COMPS.GFPP;
const designPanelName = 'designPanel.designPanel';
const settingsPanelName = 'compPanels.panels.StateBox.settingsPanel';
const bgPanelName = 'compPanels.panels.StateBox.backgroundPanel';
const repeaterCompType = 'wysiwyg.viewer.components.Repeater';

const { isAppController } = util.appStudioUtils;
const { findCurrentSlide } = stateManagement.boxSlideShow.selectors;

function isAppWidgetRoot(editorAPI, compRefs) {
  return (
    isAppController(editorAPI, compRefs) ||
    isAppController(
      editorAPI,
      editorAPI.components.getContainer_DEPRECATED_BAD_PERFORMANCE(compRefs),
    )
  );
}

function getPanelProps(editorAPI, selectedComponent) {
  const compType = editorAPI.components.getType(selectedComponent);
  return {
    selectedComponent,
    compType,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/is-array
    compRef: _.isArray(selectedComponent)
      ? _.head(selectedComponent)
      : selectedComponent,
    helpId: helpIds.DESIGN[compType],
  };
}

function wrapOnClick(actions, newCompRef) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
  _.forEach(actions, (action) => {
    const originalOnClick = action.onClick;
    if (originalOnClick) {
      action.onClick = (editorAPI) => {
        originalOnClick(editorAPI, [newCompRef]);
      };
    }
  });
}

function addCurrentStatePresetActions(stateBoxGfppData, currentSlideGfppData) {
  wrapOnClick(
    currentSlideGfppData.presetActions,
    stateBoxGfppData.additionalCompForGfpp,
  );
  _.defaults(
    stateBoxGfppData.presetActions,
    currentSlideGfppData.presetActions,
  );
}

function addCurrentStateMainActions(stateBoxGfppData, currentSlideGfppData) {
  wrapOnClick(
    currentSlideGfppData.mainActions,
    stateBoxGfppData.additionalCompForGfpp,
  );
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/concat
  stateBoxGfppData.mainActions = _.concat(
    stateBoxGfppData.mainActions,
    currentSlideGfppData.mainActions,
  );
}

const getStateBoxGfppDefinition = (editorAPI, compRefs) => {
  const stateBoxCurrentSlide = findCurrentSlide(
    editorAPI.dsRead,
    _.head(compRefs),
  );
  const isRepeaterState =
    editorAPI.components.getType(stateBoxCurrentSlide) === repeaterCompType;

  const gfppData = {
    untranslatable: false,
    mainActions: (editorAPI, compRef) => {
      const backgroundAction = {
        label: 'gfpp_secondaryaction_multiStateBox',
        isApplied: true,
        isSelected: gfppDataUtils.getPanelStateFn(bgPanelName),
        onClick: (editorAPI: EditorAPI, compRef: CompRef, origin: string) => {
          gfppDataUtils.toggleComponentPanel(
            editorAPI,
            bgPanelName,
            { ...getPanelProps(editorAPI, compRef), origin },
            editorAPI.components.getType(compRef),
          );
        },
        automationId: 'gfpp-button-background',
      };
      const manageStatesAction = {
        label: constants.STATE_BOX.GFPP.ACTIONS.LABELS.MANAGE_STATES,
        isApplied: true,
        automationId: 'gfpp-button-manage-states',
        isSelected: gfppDataUtils.getPanelStateFn(
          'rEditor.panels.stateBoxManageStatesPanel',
        ),
        onClick(editorAPI: EditorAPI, compRef: CompRef, origin: string) {
          const panelProps = {
            selectedComponent: compRef,
            origin,
          };
          gfppDataUtils.toggleComponentPanel(
            editorAPI,
            'rEditor.panels.stateBoxManageStatesPanel',
            panelProps,
          );
        },
      };
      const mainAction = editorAPI.developerMode.isEnabled()
        ? [manageStatesAction]
        : [];

      if (isAppWidgetRoot(editorAPI, compRef) || isRepeaterState) {
        return mainAction;
      }
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/concat
      return _.concat(mainAction, backgroundAction);
    },
    enabledActions: [
      ACTIONS.SETTINGS,
      ACTIONS.DESIGN,
      ACTIONS.ANIMATION,
      ACTIONS.HELP,
      ACTIONS.BACKGROUND,
    ],
    mobileEnabledActions: () => {
      const actions = [
        ACTIONS.SETTINGS,
        ACTIONS.HIDE,
        ACTIONS.HELP,
        ACTIONS.ANIMATION,
      ];
      if (!isRepeaterState) {
        actions.push(ACTIONS.MOBILE_BACKGROUND_SETTINGS);
      }
      return actions;
    },
    presetActions: {
      help: {
        helpId: helpIds.GFPP[helpIds.COMP_TYPES.STATE_BOX],
        mobileHelpId: 'c87be5fd-fa3a-4eb5-887e-13ae266ad306',
      },
      design: {
        isSupported() {
          return !isRepeaterState;
        },
        isSelected(editorAPI) {
          return gfppDataUtils.findPanel(
            editorAPI,
            designPanelName,
            'wysiwyg.viewer.components.StateBox',
          );
        },
        onClick(editorAPI: EditorAPI, compRef: CompRef, origin: string) {
          gfppDataUtils.toggleComponentPanel(
            editorAPI,
            designPanelName,
            { ...getPanelProps(editorAPI, compRef), origin },
            editorAPI.components.getType(compRef),
          );
        },
      },
      settings: {
        isSelected(editorAPI) {
          return gfppDataUtils.findPanel(
            editorAPI,
            settingsPanelName,
            'wysiwyg.viewer.components.StateBox',
          );
        },
        onClick(editorAPI: EditorAPI, compRef: CompRef, origin: string) {
          const compType = editorAPI.components.getType(compRef);
          gfppDataUtils.toggleComponentPanel(
            editorAPI,
            settingsPanelName,
            { ...getPanelProps(editorAPI, compRef), origin },
            compType,
          );
        },
      },
      background: {
        isSupported(editorAPI, compRefs) {
          return isAppWidgetRoot(editorAPI, compRefs) && !isRepeaterState;
        },
        onClick: (editorAPI: EditorAPI, compRefs: CompRef, origin: string) => {
          gfppDataUtils.toggleComponentPanel(
            editorAPI,
            bgPanelName,
            { ...getPanelProps(editorAPI, compRefs), origin },
            editorAPI.components.getType(compRefs),
          );
        },
      },
    },
  };

  if (isRepeaterState) {
    gfppData.enabledActions = _.pull(
      gfppData.enabledActions,
      ACTIONS.BACKGROUND,
    );
    gfppData.additionalCompForGfpp = stateBoxCurrentSlide;
    gfppData.mergeGfpp = (stateBoxGfppData, currentSlideGfppData) => {
      addCurrentStatePresetActions(stateBoxGfppData, currentSlideGfppData);
      if (!editorAPI.isMobileEditor()) {
        addCurrentStateMainActions(stateBoxGfppData, currentSlideGfppData);
      }
    };
  }
  return gfppData;
};

export default getStateBoxGfppDefinition;
