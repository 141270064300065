import _ from 'lodash';
import { calculateSectionsEdge, getLastCompPosition } from './utils';

import type { CompRef, DocumentServicesObject } from 'types/documentServices';
import type { SectionDescription } from '../../types';

function fitTwoSections(
  upperSection: SectionDescription,
  lowerSection: SectionDescription,
): [upperSection: SectionDescription, lowerSection?: SectionDescription] {
  if (
    upperSection.bottom >= lowerSection.bottom &&
    upperSection.top <= lowerSection.top
  ) {
    return [
      {
        ...upperSection,
        children: [...upperSection.children, ...lowerSection.children],
      },
    ];
  }

  let { bottom: upperSectionBottom } = upperSection;
  let { top: lowerSectionTop } = lowerSection;

  const sectionsIntersectionPoint = calculateSectionsEdge(
    upperSectionBottom,
    upperSection.isFullWidthBottom,
    lowerSectionTop,
    lowerSection.isFullWidthTop,
  );

  if (sectionsIntersectionPoint) {
    upperSectionBottom = lowerSectionTop = sectionsIntersectionPoint;
  }

  return [
    { ...upperSection, bottom: upperSectionBottom },
    { ...lowerSection, top: lowerSectionTop },
  ];
}

export function fitSectionsToEachOther(
  sectionsDescriptions: SectionDescription[],
) {
  const fitSections = [sectionsDescriptions[0]];
  let j = 0;

  for (let i = 1; i < sectionsDescriptions.length; i++) {
    const [upperSection, lowerSection] = fitTwoSections(
      fitSections[j],
      sectionsDescriptions[i],
    );

    fitSections[j] = upperSection;

    if (lowerSection) {
      j += 1;
      fitSections[j] = lowerSection;
    }
  }

  return fitSections;
}

function fitSectionsToPage(
  documentServices: DocumentServicesObject,
  pageRef: CompRef,
  sectionsDescriptions: SectionDescription[],
): SectionDescription[] {
  const firstIndex = 0;
  const firstSection = sectionsDescriptions[firstIndex];

  sectionsDescriptions[firstIndex] = {
    ...firstSection,
    top: 0,
  };

  const lastIndex = sectionsDescriptions.length - 1;
  const lastSection = sectionsDescriptions[lastIndex];
  const lastCompBottom = getLastCompPosition(documentServices, pageRef);

  sectionsDescriptions[lastIndex] = {
    ...lastSection,
    bottom: Math.max(lastSection.bottom, lastCompBottom),
  };

  return sectionsDescriptions;
}

function sortSectionsDescriptions(
  sectionsDescriptions: SectionDescription[],
): SectionDescription[] {
  return sectionsDescriptions.sort(
    (sectionA, sectionB) => sectionA.top - sectionB.top,
  );
}

export function collocateDesktopSections(
  documentServices: DocumentServicesObject,
  pageRef: CompRef,
  sectionsDescriptions: SectionDescription[],
): SectionDescription[] {
  return _.flow([
    sortSectionsDescriptions,
    fitSectionsToEachOther,
    (sections) => fitSectionsToPage(documentServices, pageRef, sections),
  ])(sectionsDescriptions);
}
