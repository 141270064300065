import type { EntryPoint } from '@/apilib';
import { EditorAPIKey, BiApiKey } from '@/apis';
import { createCopyPasteApi } from './copyPasteWrapper';
import { CopyPasteApiKey } from './publicApiKey';

export const CopyPasteEntrypoint: EntryPoint = {
  name: 'CopyPasteApi',
  declareAPIs: () => [CopyPasteApiKey],
  getDependencyAPIs: () => [EditorAPIKey, BiApiKey],
  attach(shell) {
    shell.contributeAPI(CopyPasteApiKey, () => createCopyPasteApi(shell));
  },
};
