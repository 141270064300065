//@ts-nocheck
import _ from 'lodash';
import { EditorAPIKey, BiApiKey } from '@/apis';
import { Hooks, Shell } from '@/apilib';
import { ErrorReporter } from '@wix/editor-error-reporter';

import * as core from '@/core';
import constants from '@/constants';
import * as stateManagement from '@/stateManagement';
import { arrayUtils, fedopsLogger, sectionsUtils, fixedStage } from '@/util';
import { utils as themeUtils } from '@/theme';
// eslint-disable-next-line @wix/santa-editor/scoped-imports
import collectMediaIds from '@/rEditor/utils/copyPaste/collectMediaIds';
// eslint-disable-next-line @wix/santa-editor/scoped-imports
import * as collectThemeUtils from '@/rEditor/utils/theme/collectThemeUtils';
// eslint-disable-next-line @wix/santa-editor/scoped-imports
import createPaste from '@/rEditor/app/APISections/copyPasteWrapper/createPaste';
// eslint-disable-next-line @wix/santa-editor/scoped-imports
import createCopyPasteUtils, {
  PasteActionOrigins,
} from '@/rEditor/app/APISections/copyPasteWrapper/createCopyPasteUtils';
// eslint-disable-next-line @wix/santa-editor/scoped-imports
import createCopyPasteBILogger from '@/rEditor/app/APISections/copyPasteWrapper/createCopyPasteBILogger';

// eslint-disable-next-line @wix/santa-editor/scoped-imports
import type { CopyMetaData } from '@/rEditor/app/APISections/copyPasteWrapper/createCopyPasteUtils';
import type { CompRef } from 'types/documentServices';

const { isInInteractionMode, isShownOnlyOnVariant } =
  stateManagement.interactions.selectors;

const DEFAULT_SITE_WIDTH = 980;

export function createCopyPasteApi(shell: Shell) {
  const hooks = {
    beforeDuplicate: Hooks.createHook<CompRef[]>(),
    cannotDuplicate: Hooks.createHook<CompRef[]>(),
  };

  const editorAPI = shell.getAPI(EditorAPIKey);
  const biApi = shell.getAPI(BiApiKey);
  const copyPasteBILogger = createCopyPasteBILogger(biApi);
  const copyPasteUtils = createCopyPasteUtils(editorAPI);
  const pasteWrapper = createPaste(editorAPI);

  function isAllowedToCopy(components: CompRef[]): boolean {
    return editorAPI.components.is.duplicatable(components);
  }

  function runAfterPasteComponentCallbacks(newComponents, originalComponents) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
    _.forEach(editorAPI.afterPasteComponentCallbacks, (cb) => {
      cb(editorAPI, newComponents, originalComponents);
    });
  }

  async function runAfterDuplicateComponentCallbacks(
    newComponents,
    originalComponents,
  ) {
    for await (const callback of editorAPI.afterDuplicateComponentCallbacks) {
      await callback(editorAPI, newComponents, originalComponents);
    }
  }

  function copy(compRefs, shouldMaintainIds?) {
    if (!compRefs) {
      return;
    }

    const components = editorAPI.components.getUniqOrderedComponents(compRefs);
    const compType = editorAPI.components.getType(compRefs);

    if (!isAllowedToCopy(components)) {
      const cannotCopyPlugin = editorAPI.pluginService.getPlugin(
        editorAPI.pluginService.pluginConstants.CANNOT_COPY,
        compType,
      );

      if (cannotCopyPlugin) {
        return cannotCopyPlugin(editorAPI, { compRefs });
      }

      if (editorAPI.isMobileEditor()) {
        editorAPI.mobile.notifyCantCopyNonMobileOnly();
      }
      return;
    }
    const clipboardCompData = copyPasteUtils.prepareComponentsForCopy(
      components,
      { shouldMaintainIds },
      editorAPI.pasteLogic.pasteContext,
    );

    if (isInInteractionMode(editorAPI.store.getState())) {
      if (isShownOnlyOnVariant(editorAPI, components[0])) {
        clipboardCompData.shouldRemoveInteraction = true;
      }
    }

    editorAPI.clipboard.setItem(
      clipboardCompData,
      constants.CLIPBOARD_ITEM_TYPE.COMPONENT,
    );
  }

  function copyPage(pageId, { origin } = {}) {
    if (!canCopyPage(pageId)) {
      return;
    }

    if (editorAPI.isMobileEditor()) {
      editorAPI.openCantCopyPastePanel();
      return;
    }

    // Needed serialize to save in clipboard
    // eslint-disable-next-line @wix/santa-editor/dsReadSerializeIsTooExpensive
    const sourcePage = editorAPI.pages.serialize(pageId);
    const theme = collectThemeUtils.collectThemeFromSerializedStructure(
      editorAPI,
      sourcePage,
    );

    editorAPI.clipboard.setItem(
      {
        page: sourcePage,
        theme,
      },
      constants.CLIPBOARD_ITEM_TYPE.PAGE,
    );

    copyPasteBILogger.copyPage({
      pageId,
      origin,
    });
  }

  function getPagePermissionsCopy(sourcePageSecurity) {
    const hasPasswordPermissions =
      !sourcePageSecurity.requireLogin && !!sourcePageSecurity.dialogLanguage;

    // NOTE: we don't need to reset members groups, because it wired only to source page id

    if (hasPasswordPermissions) {
      // NOTE: reset permission "password holders" to "everyone"
      return {
        requireLogin: false,
        // `dialogLanguage` used as flag for "require password"
        dialogLanguage: '',
      };
    }

    return sourcePageSecurity;
  }

  function getPageComponentsCopy(
    components,
    { shouldApplyTargetTheme, isCrossSite, theme },
  ) {
    if (!isCrossSite || !components) {
      return components;
    }

    const supportedComponents =
      copyPasteUtils.getCrossSiteDuplicatableComponents(components);

    if (shouldApplyTargetTheme) {
      return supportedComponents;
    }

    return supportedComponents.map((component) =>
      themeUtils.applyThemeToSerializedComponents(component, theme),
    );
  }

  /**
   * Extract color theme name from ref and return color based on theme
   * Example: {color_15} => #CACACA
   */
  function getColorValue(colorRef, colorsTheme) {
    const match = colorRef.match(/{(color_\d+)}/);
    if (!match) {
      return colorRef;
    }
    const colorName = match[1];
    return colorsTheme[colorName] || colorRef;
  }

  function getPageBackgroundsCopy(
    pageBackgrounds,
    { theme, isCrossSite, shouldApplyTargetTheme },
  ) {
    if (!isCrossSite || shouldApplyTargetTheme) {
      return pageBackgrounds;
    }
    const desktopColorRef = pageBackgrounds.desktop.ref.color;
    const mobileColorRef = pageBackgrounds.mobile.ref.color;

    return _.merge({}, pageBackgrounds, {
      desktop: {
        ref: {
          color: getColorValue(desktopColorRef, theme.colors),
        },
      },
      mobile: {
        ref: {
          color: getColorValue(mobileColorRef, theme.colors),
        },
      },
    });
  }

  function getStyleCopy(
    styleRef,
    { theme, isCrossSite, shouldApplyTargetTheme },
  ) {
    if (!isCrossSite) {
      return styleRef;
    }

    if (shouldApplyTargetTheme) {
      return typeof styleRef !== 'string' ? styleRef : theme.styles[styleRef];
    }

    return themeUtils.applyThemeToStyle(styleRef, theme);
  }

  function convertPageNameToUrl(pageName) {
    return editorAPI.dsRead.generalInfo.urlFormat.isSlash()
      ? editorAPI.pages.data.pageUriSEO.convertPageNameToUrl(pageName)
      : core.utils.pageTitleUtils.convertPageNameToUrl(pageName);
  }

  function getPageCopy(
    sourcePage,
    { isCrossSite, shouldApplyTargetTheme, theme },
  ) {
    const newPageName = core.utils.pageTitleUtils.generateDuplicatedTitle(
      sourcePage.data.title,
    );
    const newPageUriSEO = convertPageNameToUrl(newPageName);
    const newPageData = Object.assign(
      {},
      sourcePage.data,
      {
        title: newPageName,
        pageUriSEO: newPageUriSEO,
        pageSecurity: getPagePermissionsCopy(sourcePage.data.pageSecurity),
        pageBackgrounds: getPageBackgroundsCopy(
          sourcePage.data.pageBackgrounds,
          { theme, isCrossSite, shouldApplyTargetTheme },
        ),
        pageTitleSEO: '',
        descriptionSEO: '',
        ogImage: undefined,
        ogImageRef: undefined,
        advancedSeoData: undefined,
      },
      isCrossSite
        ? {
            cacheMaxAgeHours: null,
            cacheMode: 'auto',
          }
        : undefined,
    );

    if (isCrossSite && newPageData.parentPageId) {
      delete newPageData.parentPageId;
    }

    const newPageStyle = getStyleCopy(sourcePage.style, {
      theme,
      isCrossSite,
      shouldApplyTargetTheme,
    });
    const newPageComponents = getPageComponentsCopy(sourcePage.components, {
      shouldApplyTargetTheme,
      isCrossSite,
      theme,
    });
    const newPageMobileComponents = getPageComponentsCopy(
      sourcePage.mobileComponents,
      { shouldApplyTargetTheme, isCrossSite, theme },
    );

    return Object.assign({}, sourcePage, {
      data: newPageData,
      style: newPageStyle,
      components: newPageComponents,
      mobileComponents: newPageMobileComponents,
    });
  }

  function pastePage({
    menuItemToPasteAfter,
    shouldApplyTargetTheme = false,
    origin,
  }: {
    menuItemToPasteAfter?: any;
    shouldApplyTargetTheme?: boolean;
    origin?: string;
  } = {}) {
    return Promise.resolve()
      .then(() => {
        if (!hasPageToPaste()) {
          return Promise.resolve();
        }

        if (editorAPI.isMobileEditor()) {
          editorAPI.openCantCopyPastePanel();
          return Promise.resolve();
        }

        const { value, metaData } = editorAPI.clipboard.getItem();
        const { page: sourcePage, theme } = value;
        const isCrossSite = editorAPI.clipboard.isCrossSite();

        if (
          isCrossSite &&
          !sectionsUtils.isSectionsEnabled() &&
          copyPasteUtils.hasSectionsComponents(sourcePage)
        ) {
          editorAPI.panelManager.openPanel(
            'panels.messagePanels.prohibitedCrossEditorPasteMessage',
          );

          return Promise.resolve();
        }

        const defaultPasteBIParams = {
          sourceMetaSiteId: metaData.metaSiteId,
          sourcePageId: sourcePage.id,
          shouldApplyTargetTheme,
          origin,
        };

        function logPasteBI({ success, error, hasMediaContent }) {
          copyPasteBILogger.pastePage(
            Object.assign(defaultPasteBIParams, {
              success,
              error,
              hasMediaContent,
            }),
          );
        }

        const sourcePageCopy = getPageCopy(sourcePage, {
          isCrossSite,
          shouldApplyTargetTheme,
          theme,
        });

        const crossSiteMediaIds = isCrossSite
          ? collectMediaIds(sourcePageCopy)
          : [];
        const hasCrossSiteMediaContent = crossSiteMediaIds.length > 0;
        const hasCrossSiteUnsupportedComponents =
          isCrossSite &&
          copyPasteUtils.hasCrossSiteUnsupportedPageComponents(sourcePage);
        if (!hasCrossSiteMediaContent && hasCrossSiteUnsupportedComponents) {
          pasteWrapper.showCantPasteNotification();
        }

        const newPageRef = editorAPI.pages.add(
          sourcePageCopy.data.title,
          sourcePageCopy,
        );

        if (!newPageRef) {
          return Promise.reject(`Can't create a copy of page ${sourcePage.id}`);
        }

        editorAPI.history.add('page pasted');

        return new Promise((resolve) =>
          editorAPI.dsActions.waitForChangesApplied(resolve),
        )
          .then(() => {
            if (sectionsUtils.isSectionsEnabled()) {
              return editorAPI.sections.__forceEnforceContainerOnPage(
                newPageRef,
              );
            }
          })
          .then(() =>
            Promise.all([
              moveMenuItem(newPageRef.id, menuItemToPasteAfter),
              navigateToPage(newPageRef.id),
            ]),
          )
          .then(() => {
            // For same site flow just open pages panel, no files transfer needed
            if (!isCrossSite) {
              logPasteBI({
                success: true,
              });

              openPagesMenu();
              return;
            }

            if (!hasCrossSiteMediaContent) {
              logPasteBI({
                success: true,
                hasMediaContent: false,
              });

              openPagesMenu();
              return;
            }

            // NOTE: we should send page paste success bi before files transfer modal closed,
            // because user could close site with opened modal
            logPasteBI({
              success: true,
              hasMediaContent: true,
            });

            pasteWrapper
              .transferMediaFiles({
                mediaIds: crossSiteMediaIds,
                uploadToken: metaData.siteUploadToken,
              })
              .then(() => {
                if (hasCrossSiteUnsupportedComponents) {
                  pasteWrapper.showCantPasteNotification();
                }
                openPagesMenu();
              });
          });
      })
      .catch((reason) => {
        copyPasteBILogger.pastePage({
          success: false,
          errorType: String(reason),
        });
        // reject promise in order to handle fedops interactions correctly
        return Promise.reject(reason);
      });
  }

  function openPagesMenu() {
    editorAPI.openPagesPanel({
      renameEnabled: true,
    });
  }

  function navigateToPage(pageId) {
    return new Promise((resolve) => {
      editorAPI.pages.navigateTo(pageId, resolve);
    });
  }

  function moveMenuItem(pageId, menuItemToPasteAfter) {
    if (!menuItemToPasteAfter) {
      return Promise.resolve();
    }

    const targetItemId = menuItemToPasteAfter.parent
      ? menuItemToPasteAfter.parent.id
      : menuItemToPasteAfter.id;
    const mainMenu = editorAPI.dsRead.menu.getById('CUSTOM_MAIN_MENU');
    const pageMenuItem = editorAPI.menus.getMenuItemByPageId(
      pageId,
      mainMenu.items,
    );
    const pageMenuItemId = pageMenuItem.id;

    return new Promise((resolve) => {
      editorAPI.menus.moveMenuItemAfterTarget(
        mainMenu,
        pageMenuItemId,
        targetItemId,
        resolve,
      );
    });
  }

  function canCopyPage(pageId) {
    if (!pageId) {
      return false;
    }

    return (
      !editorAPI.dsRead.pages.isWidgetPage(pageId) &&
      editorAPI.dsRead.pages.isDuplicable(pageId)
    );
  }

  async function enlargeContainerForCompleteContainment(result) {
    const compRefs = result.newComponentPointers;

    if (compRefs?.length === undefined) return result;

    if (compRefs.length < 2) {
      return result;
    }

    for await (const ref of compRefs) {
      const parent =
        editorAPI.components.getContainer_DEPRECATED_BAD_PERFORMANCE(ref);

      const compLayout = editorAPI.components.layout.get_rect(ref);
      const parentHeight = editorAPI.components.layout.get_size(parent).height;

      const compBottom = compLayout.height + compLayout.y;

      if (compBottom > parentHeight) {
        editorAPI.components.layout._updateAndAdjustLayoutForSingle(
          parent,
          {
            height: compBottom,
          },
          true,
        );

        await editorAPI.waitForChangesAppliedAsync();
      }
    }

    return result;
  }

  function paste(rightClickPosition, shouldApplyTargetTheme, origin) {
    if (!hasComponentToPaste()) {
      return Promise.resolve();
    }

    const clipboardItem = editorAPI.clipboard.getItem();
    const clipboardMetaData: CopyMetaData = clipboardItem.metaData;
    const clipboardCompData = clipboardItem.value;

    if (editorAPI.isMobileEditor() && !clipboardMetaData?.copiedOnMobile) {
      copyPasteUtils.showCantPasteInDesktopCopiedMobileNotification();
      return Promise.resolve();
    }

    if (clipboardMetaData?.copiedOnMobile && editorAPI.isDesktopEditor()) {
      copyPasteUtils.showCantPasteInMobileCopiedDesktopNotification();
      return Promise.resolve();
    }

    if (!copyPasteUtils.isValidClipboard(clipboardCompData)) {
      return Promise.resolve();
    }

    const state = editorAPI.store.getState();
    if (isInInteractionMode(state)) {
      const itemId = _.head(clipboardCompData.components)?._dataId;
      const compRef = editorAPI.components.get.byId(itemId);
      if (compRef && !isShownOnlyOnVariant(editorAPI, compRef)) {
        return Promise.resolve();
      }
    }
    if (editorAPI.componentFocusMode.isEnabled()) {
      if (
        !editorAPI.componentFocusMode.canBePasted(
          clipboardCompData.components.map((comp) => comp.componentType),
        )
      ) {
        return Promise.resolve();
      }
    }

    const sourceMetaSiteId = clipboardMetaData?.metaSiteId;

    if (!sourceMetaSiteId) {
      copyPasteBILogger.unknownSource({
        content: JSON.stringify(clipboardItem),
      });
    }

    const sourceComponents = clipboardCompData?.components;

    const defaultPasteBIParams = {
      origin,
      sourceMetaSiteId,
      sourceComponents,
      shouldApplyTargetTheme,
    };
    const siteWidthCopiedFrom =
      clipboardMetaData?.siteWidth ?? DEFAULT_SITE_WIDTH;
    const copiedFromSiteWithDifferentWidth =
      siteWidthCopiedFrom !== editorAPI.site.getWidth();

    if (copiedFromSiteWithDifferentWidth) {
      if (!fixedStage.isFixedStageEnabled()) {
        editorAPI.closeRightClickMenu();

        editorAPI.panelManager.openPanel(
          'panels.messagePanels.prohibitedPasteFromFixedStageMessage',
        );
        return;
      }

      const mostLeftX = Math.min(
        ...clipboardCompData.components.map((comp) => comp.layout.x),
      );
      const stageXOffset =
        clipboardCompData.snugLayoutRelativeToStructure.x - mostLeftX;
      clipboardCompData.snugLayoutRelativeToStructure.x -= stageXOffset;
      clipboardCompData.snugLayoutRelativeToStructure.bounding.x -=
        stageXOffset;
    }

    return pasteWrapper
      .pasteComponentWrappers(
        clipboardCompData.components,
        clipboardCompData.componentsData,
        {
          theme: clipboardCompData.theme,
          originMetaSiteId: sourceMetaSiteId,
          guid: clipboardMetaData?.guid,
          siteUploadToken: clipboardMetaData?.siteUploadToken,
          snugLayoutRelativeToStructure:
            clipboardCompData.snugLayoutRelativeToStructure,
          originalScrollPosition: clipboardCompData.originalScrollPosition,
        },
        {
          shouldApplyTargetTheme,
          rightClickPosition,
          isCrossSite: editorAPI.clipboard.isCrossSite(),
          originAction: PasteActionOrigins.Paste,
        },
      )
      .then((result) => {
        if (
          !isInInteractionMode(editorAPI.store.getState()) &&
          clipboardCompData.shouldRemoveInteraction
        ) {
          editorAPI.components.transformations.remove(
            result.newComponentPointers[0],
          );
          editorAPI.components.transitions.remove(
            result.newComponentPointers[0],
          );
        }
        copyPasteBILogger.pasteComponent(
          Object.assign(defaultPasteBIParams, {
            success: result.success,
            error: result.reason,
            hasMediaContent: result.hasMediaContent,
            newComponentPointers: result.newComponentPointers,
          }),
        );

        return result;
      })
      .then((result) => enlargeContainerForCompleteContainment(result))
      .then((result) => {
        if (result.success) {
          runAfterPasteComponentCallbacks(
            result.newComponentPointers,
            result.originalComponentPointers,
          );
        }
        ErrorReporter.breadcrumb('component pasted', {
          origin,
          newComponentPointers: result.newComponentPointers,
          originalComponentPointers: result.originalComponentPointers,
        });
        fedopsLogger.interactionEnded(
          fedopsLogger.INTERACTIONS.PAST_COMPONENT_OR_TPA,
        );

        return result;
      });
  }

  function hideApplyModeFromClipboardSuggestion() {
    editorAPI.updateState({ applyModeFromClipboardSuggestion: undefined });
  }

  function removePastedComponentAndApplyModeFromClipboard() {
    const state = editorAPI.store.getState();
    const applyModeFromClipboardState =
      stateManagement.applyModeFromClipboard.selectors.selectApplyModeFromClipboardSuggestion(
        state,
      );
    const selectedComponents = applyModeFromClipboardState.container;
    hideApplyModeFromClipboardSuggestion();
    const removePromise = editorAPI.components.remove(
      applyModeFromClipboardState.selectedComponents,
    );
    editorAPI.dsActions.waitForChangesApplied(function () {
      editorAPI.copyPaste.applyModeFromClipboard(selectedComponents);
    });
    return removePromise;
  }

  function applyModeFromClipboard(selectedComponents) {
    const clipboardItem = editorAPI.clipboard.getItem();
    const clipboardCompData = clipboardItem.value;

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/map
    const components = _.map(
      clipboardCompData.components,
      function (component) {
        return {
          compRef: clipboardCompData.componentsData[component._dataId].compRef,
          compDefinition: component,
        };
      },
    );

    if (selectedComponents && selectedComponents.length === 1) {
      const componentsApi = editorAPI.components;
      const selectedComponent = selectedComponents[0];

      if (_.isEqual(selectedComponent, clipboardCompData.containerWithModes)) {
        const modes = componentsApi.modes.getModes(selectedComponent);

        if (modes && modes.length > 0) {
          const activeModeIds =
            componentsApi.modes.getComponentActiveModeIds(selectedComponent);
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line you-dont-need-lodash-underscore/size
          if (_.size(activeModeIds) === 1) {
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line you-dont-need-lodash-underscore/keys
            const activeModeId = _.keys(activeModeIds)[0];

            componentsApi.modes.applyComponentToMode(components, activeModeId);
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line you-dont-need-lodash-underscore/map
            const compRefs = _.map(components, 'compRef');
            editorAPI.dsActions.waitForChangesApplied(function () {
              editorAPI.selection.selectComponentByCompRef(compRefs);
            });
          }
        }
      }
    }

    editorAPI.history.add('apply mode from clipboard');
    editorAPI.tabIndicationState.activateApplyAnimation(true);
  }

  async function duplicate(compRefs): Promise<CompRef[]> {
    if (_.isEmpty(compRefs)) {
      return [];
    }

    editorAPI.panelManager.closeAllPanels();
    let selectedComps = editorAPI.selection.getSelectedComponents();
    let components = arrayUtils.asArray(compRefs);

    await Promise.all(hooks.beforeDuplicate.fire(components));

    const compType = editorAPI.components.getType(compRefs);

    if (editorAPI.duplicatePlugins[compType]) {
      return editorAPI.duplicatePlugins[compType](compRefs);
    }

    selectedComps =
      editorAPI.components.getComponentsWhichDontHaveAncestorsInTheArray(
        selectedComps,
      );

    components =
      editorAPI.components.getComponentsWhichDontHaveAncestorsInTheArray(
        components,
      );

    const container =
      editorAPI.components.getContainer_DEPRECATED_BAD_PERFORMANCE(compRefs);

    if (!editorAPI.components.is.duplicatable(components, container)) {
      hooks.cannotDuplicate.fire(components);

      return [];
    }

    if (_.isEqual(components, selectedComps)) {
      editorAPI.selection.deselectComponents();
    }
    components = editorAPI.components.normalizeOrder(components);
    const clipboardCompData = copyPasteUtils.prepareComponentsForCopy(
      components,
      { shouldMaintainIds: false },
      editorAPI.pasteLogic.duplicateContext,
    );
    const newComponents = await pasteWrapper.pasteAndReturnNewComponents({
      originAction: PasteActionOrigins.Duplicate,
      components: clipboardCompData.components,
      componentsData: clipboardCompData.componentsData,
      guid: null,
      snugLayoutRelativeToStructure:
        clipboardCompData.snugLayoutRelativeToStructure,
    });
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/map
    const newComponentPointers = _.map(newComponents, 'newPointer');
    const originalComponentPointers = newComponents.map((newComponent) =>
      editorAPI.components.get.byId(newComponent.originCompId),
    );

    await editorAPI.dsActions.waitForChangesAppliedAsync();

    editorAPI.selection.selectComponentByCompRef(newComponentPointers);
    editorAPI.openFirstTimeOrDeprecationPanel(newComponentPointers);
    await runAfterDuplicateComponentCallbacks(
      newComponentPointers,
      originalComponentPointers,
    );
    ErrorReporter.breadcrumb('component duplicated', {
      newComponentPointers,
      originalComponentPointers,
    });
    fedopsLogger.interactionEnded(
      fedopsLogger.INTERACTIONS.DUPLICATE_COMPONENT_OR_TPA,
    );

    return newComponentPointers;
  }

  function hasComponentToPaste() {
    return editorAPI.clipboard.hasItemOfType(
      constants.CLIPBOARD_ITEM_TYPE.COMPONENT,
    );
  }

  function hasPageToPaste() {
    return editorAPI.clipboard.hasItemOfType(
      constants.CLIPBOARD_ITEM_TYPE.PAGE,
    );
  }

  return {
    hooks,
    copy,
    paste,
    hideApplyModeFromClipboardSuggestion,
    canApplyModeFromClipboard: pasteWrapper.canApplyModeFromClipboard,
    removePastedComponentAndApplyModeFromClipboard,
    applyModeFromClipboard,
    duplicate,
    canCopyPage,
    copyPage,
    pastePage,
    hasComponentToPaste,
    hasPageToPaste,
  };
}
