import {
  attachCandidate,
  domMeasurements,
  interactions,
} from '@/stateManagement';
import { sections as sectionsUtils } from '@/util';
import { translate, getFallbackTranslation } from '@/i18n';

import { getPosition as getSelectionBoxPosition } from '../selectionBox/selectionBoxCommons';

import type { EditorState } from '@/stateManagement';
import type {
  MapDispatchToProps,
  MapStateToProps,
  ThunkAction,
} from 'types/redux';
import type { CompRef } from 'types/documentServices';
import type { EditorAPI } from '@/editorAPI';
import type { AttachHighlightOwnProps } from './attachHighlight';

const { isInInteractionMode, getVariantPointers } = interactions.selectors;
const { getPreviewPosition } = domMeasurements.selectors;
const { selectAttachCandidateComponent, selectAttachCandidateUseAnimation } =
  attachCandidate.selectors;
const { updateAttachCandidate } = attachCandidate.actions;

enum CompTypeSuffixes {
  Column = 'Column',
  StripContainer = 'StripContainer',
  RepeaterItem = 'RepeaterItem',
  HeaderContainer = 'HeaderContainer',
  FooterContainer = 'FooterContainer',
  Page = 'Page',
  Container = 'Container',
  MediaBox = 'MediaBox',
  FormContainer = 'FormContainer',
  HoverBox = 'HoverBox',
  BoxSlideShowSlide = 'BoxSlideShowSlide',
  StripContainerSlideShowSlide = 'StripContainerSlideShowSlide',
  StateBoxState = 'StateBoxState',
  StateBoxFormState = 'StateBoxFormState',
  StateStripState = 'StateStripState',
  PopupContainer = 'PopupContainer',
  SiteRegionContainer = 'SiteRegionContainer',
  SiteStructure = 'WSiteStructure',
}

const compTypeSuffixToTranslationKeys: Record<CompTypeSuffixes, string> = {
  [CompTypeSuffixes.HeaderContainer]: 'OnStage_AttachTo_Header',
  [CompTypeSuffixes.Page]: 'OnStage_AttachTo_Page',
  [CompTypeSuffixes.FooterContainer]: 'OnStage_AttachTo_Footer',
  [CompTypeSuffixes.SiteStructure]: 'OnStage_AttachTo_Page',
  [CompTypeSuffixes.Container]: 'OnStage_AttachTo_Box',
  [CompTypeSuffixes.MediaBox]: 'OnStage_AttachTo_Box',
  [CompTypeSuffixes.FormContainer]: 'OnStage_AttachTo_Form',
  [CompTypeSuffixes.HoverBox]: 'OnStage_AttachTo_Hover_Box',
  [CompTypeSuffixes.BoxSlideShowSlide]: 'OnStage_AttachTo_Slide',
  [CompTypeSuffixes.StripContainerSlideShowSlide]: 'OnStage_AttachTo_Slide',
  [CompTypeSuffixes.StateBoxState]: 'OnStage_AttachTo_multiStateBox',
  [CompTypeSuffixes.StateBoxFormState]: 'OnStage_AttachTo_multiStateBox',
  [CompTypeSuffixes.StateStripState]: 'OnStage_AttachTo_multiStateBox',
  [CompTypeSuffixes.StripContainer]: 'OnStage_AttachTo_Strip',
  [CompTypeSuffixes.Column]: 'OnStage_AttachTo_Column',
  [CompTypeSuffixes.PopupContainer]: 'OnStage_AttachTo_PopUp',
  [CompTypeSuffixes.SiteRegionContainer]: 'OnStage_AttachTo_members_bar',
  [CompTypeSuffixes.RepeaterItem]: 'OnStage_AttachTo_Repeater_Item',
};

function getCompTypeSuffix(editorAPI: EditorAPI, compRef: CompRef) {
  const compTypeSuffix = editorAPI.components.getType(compRef).split('.').pop();

  if (
    compTypeSuffix === CompTypeSuffixes.Column &&
    !editorAPI.components.getSiblings(compRef).length
  ) {
    return CompTypeSuffixes.StripContainer;
  }

  if (editorAPI.components.is.repeaterItem(compRef)) {
    return CompTypeSuffixes.RepeaterItem;
  }

  return compTypeSuffix as CompTypeSuffixes;
}

function getLabel(editorAPI: EditorAPI, attachCandidate: CompRef) {
  if (!attachCandidate) {
    return;
  }

  const attachLabelPlugin = editorAPI.pluginService.getPlugin(
    editorAPI.pluginService.pluginConstants.ATTACH_LABEL,
    editorAPI.components.getType(attachCandidate),
  );

  if (attachLabelPlugin) {
    return attachLabelPlugin({ compRef: attachCandidate });
  }

  const compType = editorAPI.components
    .getType(attachCandidate)
    .replace(/\./gi, '_');

  /**
   * Convention from editor-elements:
   * https://github.com/wix-private/editor-elements/blob/master/packages/lean-components-infra/src/buildtime/translations/getCompDisplayName.ts
   */
  const externalKey = `attach_highlight_area_${compType}`;

  const externalKeyTranslation = translate(externalKey);
  const fallbackKey = getFallbackTranslation(externalKey);

  if (externalKeyTranslation !== fallbackKey) {
    return externalKeyTranslation;
  }

  const compTypeSuffix = getCompTypeSuffix(editorAPI, attachCandidate);
  const labelKey = compTypeSuffixToTranslationKeys[compTypeSuffix];

  return translate(labelKey);
}

function getLayout(editorAPI: EditorAPI, compRef: CompRef) {
  const layout = editorAPI.components.layout.getRelativeToScreen(compRef);
  const state = editorAPI.store.getState();

  if (editorAPI.components.layout.isShowOnFixedPosition(compRef)) {
    const previewPosition = getPreviewPosition(state);
    layout.y += previewPosition.top;
    layout.bounding.y += previewPosition.top;
    layout.x += previewPosition.left;
    layout.bounding.x += previewPosition.left;
  }

  if (editorAPI.utils.isSiteStructure(compRef)) {
    return editorAPI.components.layout.getRelativeToScreen(
      editorAPI.pages.getCurrentPage(),
    );
  }

  return layout;
}

function getPosition(
  editorAPI: EditorAPI,
  attachCandidate: CompRef,
  state: EditorState,
) {
  const compLayout = getLayout(editorAPI, attachCandidate);
  const isFixedPosition =
    editorAPI.components.layout.isShowOnFixedPosition(attachCandidate);
  const position = getSelectionBoxPosition(isFixedPosition);

  if (isInInteractionMode(state)) {
    const candidateVariantPointer = editorAPI.components.variants.getPointer(
      attachCandidate,
      getVariantPointers(state),
    );
    const triggerTransformations =
      editorAPI.documentServices.components.transformations.get(
        candidateVariantPointer,
      );
    if (
      triggerTransformations?.translate?.x?.value ||
      triggerTransformations?.translate?.y?.value
    ) {
      const xOffset = triggerTransformations.translate.x.value;
      const yOffset = triggerTransformations.translate.y.value;
      compLayout.x += xOffset;
      compLayout.y += yOffset;
    }
  }

  let top = sectionsUtils.isSectionsEnabled() ? compLayout.y : compLayout.y - 2;
  const left = sectionsUtils.isSectionsEnabled()
    ? compLayout.x
    : compLayout.x - 2;

  let { height } = compLayout;

  const { scrollTop } = editorAPI.scroll.get();
  const hiddenHeight = scrollTop - top;

  if (hiddenHeight > 0) {
    height -= hiddenHeight;
    top = scrollTop;
  }

  return {
    top,
    left,
    height,
    width: compLayout.width,
    position,
  };
}

function getIsMaster(editorAPI: EditorAPI, attachCandidate: CompRef) {
  return (
    editorAPI.components.isShowOnAllPages(attachCandidate) ||
    editorAPI.components.isShowOnSomePages(attachCandidate)
  );
}

export interface AttachHighlightStateProps {
  attachCandidateRef: CompRef;
  isAttachingToSection: boolean;
  label: React.ReactNode;
  position: {
    top: number;
    left: number;
    height: number;
    width: number;
  };
  isMaster: boolean;
  useInOutAnimation: boolean;
}

export interface AttachHighlightDispatchProps {
  clearAttachCandidate: () => void;
}

export const mapStateToProps: MapStateToProps<
  AttachHighlightStateProps,
  AttachHighlightOwnProps
> = ({ editorAPI, state }) => {
  const attachCandidateRef = selectAttachCandidateComponent(state);
  const useAttachAnimation = selectAttachCandidateUseAnimation(state);

  return {
    attachCandidateRef,
    isAttachingToSection:
      sectionsUtils.isSectionsEnabled() &&
      editorAPI.sections.isSection(attachCandidateRef),
    label: getLabel(editorAPI, attachCandidateRef),
    position: getPosition(editorAPI, attachCandidateRef, state),
    isMaster: getIsMaster(editorAPI, attachCandidateRef),
    useInOutAnimation: sectionsUtils.isSectionsEnabled()
      ? useAttachAnimation
      : false,
  };
};

export const mapDispatchToProps: MapDispatchToProps<
  AttachHighlightDispatchProps,
  AttachHighlightOwnProps
> = {
  clearAttachCandidate: (): ThunkAction => (dispatch) =>
    dispatch(
      updateAttachCandidate({
        comp: null,
        useAnimation: false,
      }),
    ),
};
