// @ts-nocheck
import addPanelDataConsts from '@/addPanelDataConsts';

export default {
  type: addPanelDataConsts.SECTIONS_TYPES.PERSONALIZED,
  hide: false,
  additionalBehaviours: {
    labelMode: addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
    infoIcon: addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
    iconEnabledForComps: [],
  },
  title: 'add_section_label_my_shape',
  subNavigationTitle: 'add_section_label_my_shape',
  presetTitle: 'add_section_label_my_shape',
  tooltipTitle: 'add_section_label_my_shape',
  subNavigationHide: false,
  showSectionHeader: true,
  props: {
    compTypes: [
      'wysiwyg.viewer.components.FiveGridLine',
      'fiveGridLineFullWidth',
    ],
    verticalMargin: 30,
    horizontalMargin: 20,
    maxFontSize: 10,
    scaleProportion: {
      x: 1,
      y: 1,
    },
  },
  help: {
    hide: false,
    text: 'add_section_info_text_my_general',
  },
};
