import React from 'react';
import { cx, hoc } from '@/util';
import constants from '@/constants';
import { createStructuredSelector } from '../selectors';
import { boundingStyleSel, layoutStyleSel } from '../selectors';
import { getIsInteractionModeSel } from '../../interactionMode/selectors';

interface LayoutBoundingBoxOwnProps {
  componentUIColor: ValueOf<typeof constants.COMPONENT_UI_COLORS>;
  children: React.ReactNode;
}

interface LayoutBoundingBoxStateProps {
  isInInteractionMode: boolean;
  boundingStyle: React.CSSProperties;
  layoutStyle: React.CSSProperties;
}

interface LayoutBoundingBoxProps
  extends LayoutBoundingBoxOwnProps,
    LayoutBoundingBoxStateProps {}

const LayoutBoundingBox: React.FC<LayoutBoundingBoxProps> = (props) => {
  return (
    <div
      style={
        props.isInInteractionMode ? props.layoutStyle : props.boundingStyle
      }
      className={cx('bounding', {
        'components-ui-color-orange':
          props.componentUIColor === constants.COMPONENT_UI_COLORS.ORANGE,
      })}
    >
      {props.children}
    </div>
  );
};

const layoutBoundingBoxPropsSel =
  createStructuredSelector<LayoutBoundingBoxStateProps>({
    isInInteractionMode: getIsInteractionModeSel,
    boundingStyle: boundingStyleSel,
    layoutStyle: layoutStyleSel,
  });

export default hoc.connect(
  hoc.STORES.MOUSE_OPS,
  layoutBoundingBoxPropsSel,
)(LayoutBoundingBox);
