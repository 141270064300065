:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._leftPanelFrame_107ko_7 {
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 2px 10px 0 rgba(0, 0, 0, 0.08), 0 1px 20px 0 rgba(0, 0, 0, 0.08);
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  max-height: 100%;
  max-width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  background-color: #fff;
  border-radius: 0;
  border-left: 1px solid #dfe5eb;
  z-index: 1456;
  display: flex;
  flex-direction: row; }
  ._leftPanelFrame_107ko_7:after {
    border-radius: 0; }
  ._leftPanelFrame_107ko_7:not(.dynamic-height) {
    max-height: 100%; }
  ._leftPanelFrame_107ko_7.left-panel-frame-full-height {
    height: 100%;
    max-height: 100%; }
  ._leftPanelFrame_107ko_7.app-market-panel-new {
    min-width: auto;
    max-width: 100%; }
    ._leftPanelFrame_107ko_7.app-market-panel-new .left-panel-frame-content {
      overflow: auto; }
  .opened-panels ._leftPanelFrame_107ko_7 {
    display: none; }
  ._leftPanelFrame_107ko_7 .left-panel-frame-content {
    flex: 1;
    overflow: hidden;
    height: 100%; }
  ._leftPanelFrame_107ko_7 ._emptyHeader_107ko_41 {
    border-bottom: 0;
    height: 6px;
    min-height: 6px; }
  ._leftPanelFrame_107ko_7.app-manager-panel .panel-header {
    border-top-color: #5a48f5; }
  ._leftPanelFrame_107ko_7.app-manager-panel-new .panel-header {
    border-top-color: #1b3689; }
  ._leftPanelFrame_107ko_7.am-leftBarButtonAppManagerFitness .panel-header {
    border-top-color: #5a48f5; }
  ._leftPanelFrame_107ko_7.pages-panel-pp .panel-header {
    border-top-color: #00A87E; }
  ._leftPanelFrame_107ko_7.theme-panel .panel-header {
    border-top-color: #14348e; }
  ._leftPanelFrame_107ko_7.bg-panel .panel-header {
    border-top-color: #faad4d; }
  ._leftPanelFrame_107ko_7.add-panel .panel-header {
    border-top-color: #116dff; }
  ._leftPanelFrame_107ko_7.add-panel-sections .panel-header {
    border-top-color: #116dff; }
  ._leftPanelFrame_107ko_7.app-market-panel .panel-header {
    border-top-color: #e3169a; }
  ._leftPanelFrame_107ko_7.app-market-panel-20 .panel-header {
    border-top-color: #e3169a; }
  ._leftPanelFrame_107ko_7.app-market-panel-new .panel-header {
    border-top-color: #e3169a; }
  ._leftPanelFrame_107ko_7.app-market-panel-no-header .panel-header {
    border-top-color: #e3169a; }
  ._leftPanelFrame_107ko_7.my-uploads-panel .panel-header {
    border-top-color: #fb6413; }
  ._leftPanelFrame_107ko_7.media-manager-panel .panel-header {
    border-top-color: #fb6413; }
  ._leftPanelFrame_107ko_7.media-manager-panel-icon-b .panel-header {
    border-top-color: #fb6413; }
  ._leftPanelFrame_107ko_7.media-manager-panel-icon-c .panel-header {
    border-top-color: #fb6413; }
  ._leftPanelFrame_107ko_7.wix-data-panel .panel-header {
    border-top-color: #1c7962; }
  ._leftPanelFrame_107ko_7.wix-data-panel-icon-b .panel-header {
    border-top-color: #1c7962; }
  ._leftPanelFrame_107ko_7.interactions-hidden-components .panel-header {
    border-top-color: #e3169a; }
  ._leftPanelFrame_107ko_7.blog-panel .panel-header {
    border-top-color: #faad4d; }
  ._leftPanelFrame_107ko_7.new-blog-panel .panel-header {
    border-top-color: #faad4d; }
  ._leftPanelFrame_107ko_7.wix-store-panel .panel-header {
    border-top-color: #569eff; }
  ._leftPanelFrame_107ko_7.wix-bookings-panel .panel-header {
    border-top-color: #5a48f5; }
  ._leftPanelFrame_107ko_7.hidden-items-panel .panel-header {
    border-top-color: #e3169a; }
  ._leftPanelFrame_107ko_7.mobile-elements-panel .panel-header {
    border-top-color: #569eff; }
  ._leftPanelFrame_107ko_7.layout-optimizer-panel .panel-header {
    border-top-color: #5a48f5; }
  ._leftPanelFrame_107ko_7.ascend-panel .panel-header {
    border-top-color: #1863da; }
  ._leftPanelFrame_107ko_7.add-section-panel .panel-header {
    border-top-color: #6f56f9; }
  ._leftPanelFrame_107ko_7.design-panel-button .panel-header {
    border-top-color: #ff9900; }
  ._leftPanelFrame_107ko_7 .control-button.btn-header.btn-back {
    background-color: #fff; }
  ._leftPanelFrame_107ko_7 .control-button.btn-header.btn-back:hover {
    background-color: #f7f8f8; }

._contentContainer_107ko_108 {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column; }
