:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._container_i1trk_7 {
  --horizontal-padding: 12px;
  --size: 26px;
  --border-radius: 2px;
  position: absolute;
  top: 0;
  pointer-events: all;
  display: flex;
  align-items: center;
  z-index: 2;
  background-color: #fdd1ba;
  border-bottom-left-radius: var(--border-radius);
  padding: 0 var(--horizontal-padding);
  height: var(--size); }
  ._container_i1trk_7._zoomMode_i1trk_21 {
    --horizontal-padding: 6px;
    --size: 16px;
    --border-radius: 0; }
    ._container_i1trk_7._zoomMode_i1trk_21 .control-text {
      line-height: var(--size); }
  ._container_i1trk_7:not(._zoomMode_i1trk_21):not(._mobile_i1trk_27) {
    cursor: pointer; }
  ._container_i1trk_7._selected_i1trk_29 {
    background-color: #fe620f; }
  ._container_i1trk_7:not(._header_i1trk_31) {
    top: calc(-1 * var(--size));
    border-top-left-radius: var(--border-radius);
    border-bottom-left-radius: 0; }
  ._container_i1trk_7:not(._selected_i1trk_29) .control-text {
    color: #fe620f; }
