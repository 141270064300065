// @ts-nocheck
import addPanelDataConsts from '@/addPanelDataConsts';
import experiment from 'experiment';

const icons = experiment.isOpen('se_audioPlayerIconLayoutPresets')
  ? [
      'wixui.MusicPlayer.customProps4',
      'wixui.MusicPlayer.customProps5',
      'wixui.MusicPlayer.customProps6',
    ]
  : [];

const compTypes = [
  'wixui.MusicPlayer',
  'wixui.MusicPlayer.customProps1',
  'wixui.MusicPlayer.customProps2',
  'wixui.MusicPlayer.customProps3',
  ...icons,
];

export default {
  type: addPanelDataConsts.SECTIONS_TYPES.THEME,
  hide: false,
  title: 'add_section_label_theme_music',
  subNavigationTitle: 'add_section_label_theme_music',
  presetTitle: 'add_section_label_theme_music',
  tooltipTitle: 'add_section_label_theme_music',
  automationId: 'add-panel-section-audioThemeSection',
  subNavigationHide: false,
  showSectionHeader: true,
  additionalBehaviours: {
    labelMode: addPanelDataConsts.LABEL_BEHAVIOUR.HOVER,
    infoIcon: addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
    iconEnabledForComps: [],
  },
  props: {
    compTypes,
    verticalMargin: 30,
    horizontalMargin: 20,
    maxFontSize: 14,
    scaleProportion: {
      x: 1,
      y: 1,
    },
  },
  help: {
    hide: false,
    text: 'add_section_info_text_theme_general',
  },
};
