import type { EditorAPI } from '@/editorAPI';
import * as stateManagement from '@/stateManagement';

export function onPreviewModeChanged(
  editorAPI: EditorAPI,
  isPreview: boolean,
  disableMeasurement: boolean = false,
) {
  const isMobileView =
    editorAPI.dsRead.viewMode?.get() ===
    editorAPI.dsRead.viewMode.VIEW_MODES.MOBILE;
  const isDesktopPreview = isPreview && !isMobileView;
  const documentModeSetters = editorAPI.dsActions.documentMode;

  if (!isPreview) {
    editorAPI.dsActions.components.modes.resetAllActiveModes();
  }

  documentModeSetters.enablePlaying(isPreview);
  documentModeSetters.enableZoom(isPreview);
  documentModeSetters.enableSocialInteraction(false);
  documentModeSetters.enableExternalNavigation(false);
  documentModeSetters.enableSlideShowGalleryClick(isPreview);
  documentModeSetters.enableTinyMenuOpening(isPreview);
  documentModeSetters.enableRenderFixedPositionContainers(isPreview);
  documentModeSetters.enableRenderFixedPositionBackgrounds(isPreview);
  documentModeSetters.enableBackToTopButton(isPreview);
  documentModeSetters.enablePageProtection(false);
  documentModeSetters.toggleWixAds(
    isPreview && !isWixAdAlwaysHidden(editorAPI),
  );
  documentModeSetters.enableSiteMembersDialogsOpening(isPreview);
  documentModeSetters.enableResetGalleryToOriginalState(isPreview);
  documentModeSetters.enableResetComponent(!isPreview);
  documentModeSetters.resetBehaviors(isPreview);
  documentModeSetters.enableAction('screenIn', isPreview);
  documentModeSetters.enableAction('viewportEnter', isPreview);
  documentModeSetters.enableAction('viewportLeave', isPreview);
  documentModeSetters.enableAction('load', isPreview);
  documentModeSetters.enableAction('scrollScrub', isPreview);
  documentModeSetters.enableAction('modeChange', isPreview); //TODO change back to isDesktopPreview
  documentModeSetters.enableAction('exit', isPreview);
  documentModeSetters.enableAction('bgScrub', isDesktopPreview);
  documentModeSetters.setExtraSiteHeight(
    isPreview ? 0 : editorAPI.editorConfig.extraSiteHeight,
  );
  documentModeSetters.enableShouldUpdateJsonFromMeasureMap(
    !isPreview && !disableMeasurement,
  );
  documentModeSetters.allowSiteOverflow(isPreview);

  if (!isPreview) {
    editorAPI.dsActions.pages.animations.stopAll();
    documentModeSetters.runtime.reset();
  }
  documentModeSetters.setComponentViewMode(isPreview ? 'preview' : 'editor');
  documentModeSetters.allowWixCodeInitialization(isPreview);
  documentModeSetters.showHiddenComponents(
    isPreview ? !isPreview : editorAPI.getViewTools().showHiddenComponents,
  );
  documentModeSetters.showControllers(
    isPreview ? !isPreview : editorAPI.getViewTools().developerModeEnabled,
  );

  if (isPreview) {
    editorAPI.dsActions.components.modes.resetAllActiveModes();
  }
  documentModeSetters.setRenderSticky(isPreview);
}

function isWixAdAlwaysHidden(editorAPI: EditorAPI): boolean {
  return stateManagement.wixAd.selectors.getOverriddenItems(
    editorAPI.store.getState(),
  ).alwaysHidden;
}
