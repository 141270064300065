import {
  BEFORE_SECTIONS_MIGRATION_HISTORY_LABEL,
  MigrationOrigin,
} from '../constants';
import { moveAnchorsIntoSectionsTransaction } from '../migration';
import { beforeMigration } from './beforeMigration';
import { afterSuccessMigration, afterFailedMigration } from './afterMigration';

import type { CompRef } from 'types/documentServices';
import type { SectionsMigrationScope as Scope } from '../scope';
import type { MigrationFlow } from '../types';

export async function runAnchorMigration(
  scope: Scope,
  {
    origin,
    flow,
    pagesRefs,
  }: {
    origin: MigrationOrigin;
    flow: MigrationFlow;
    pagesRefs: CompRef[];
  },
) {
  if (pagesRefs.length === 0) {
    return;
  }

  const { documentServices, history: historyAPI } = scope.editorAPI;
  const startTime = performance.now();

  beforeMigration(scope, { flow, origin, pagesRefs });
  const initialHistoryLabel = historyAPI.getUndoLastSnapshotLabel();

  try {
    historyAPI.add(BEFORE_SECTIONS_MIGRATION_HISTORY_LABEL);

    await documentServices.transactions.runAndWaitForApproval(async () => {
      await moveAnchorsIntoSectionsTransaction(scope, { pagesRefs });
      await afterSuccessMigration(scope, {
        flow,
        origin,
        startTime,
        pagesRefs,
      });
    });
  } catch (error) {
    await afterFailedMigration(scope, {
      error,
      flow,
      origin,
      pagesRefs,
      initialHistoryLabel,
    });

    return Promise.reject(error);
  }
}
