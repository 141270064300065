import { http } from '@/util';
import { ErrorReporter } from '@wix/editor-error-reporter';
import experiment from 'experiment';

const PREDICTOR_SERVICE_ORIG_VERSION =
  'https://editor.wix.com/_api/predictor-service/v1/invoke/model/layout-sectiongrouping-1-6';

const PREDICTOR_SERVICE_NEW_VERSION =
  'https://editor.wix.com/_api/predictor-service/v1/invoke/model/layout-sectiongrouping-2-66';

const PREDICTOR_SERVICE_NEW_VERSION_27 =
  'https://editor.wix.com/_api/predictor-service/v1/invoke/model/layout-sectiongrouping-2-744';

const getPredictionServiceUrl = () => {
  if (experiment.isOpen('se_NewListPredictionService27')) {
    return PREDICTOR_SERVICE_NEW_VERSION_27;
  }
  if (experiment.isOpen('se_NewListPredictionService')) {
    return PREDICTOR_SERVICE_NEW_VERSION;
  }
  return PREDICTOR_SERVICE_ORIG_VERSION;
};

export default async function fetchPrediction(
  metaSiteInstance: string,
  predictionInput: AnyFixMe,
) {
  const response = await http
    .fetchJson(getPredictionServiceUrl(), {
      method: 'POST',
      credentials: 'same-origin',
      headers: new Headers({
        Authorization: metaSiteInstance,
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({
        model_id: 'layout-sectiongrouping-1-6',
        triggered_by: 'editor',
        predictionEntities: {
          uuid: {
            entity_id: '',
          },
        },
        features: predictionInput,
      }),
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      ErrorReporter.captureException(error, {
        tags: {
          listClassificationFlow: true,
        },
      });
    });

  return response;
}
