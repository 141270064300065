// @ts-nocheck
import * as util from '@/util';
import React from 'react';
import * as UA from '@/uiAnimations';
import LazyComponent from '@/lazyComponent';
import * as Panels from '@/panels';
import {
  mapStateToProps,
  OpenedPanelOwnProps,
  OpenedPanelStateProps,
} from './openedPanelsMapper';

function getPanelKey(panelProps) {
  let panelKey = panelProps.key;
  if (LazyComponent.isLoaded(panelProps.name)) {
    panelKey += '-loading';
  }
  return panelKey;
}

class OpenedPanels extends React.Component<
  OpenedPanelStateProps & OpenedPanelOwnProps
> {
  static displayName = 'openedPanels';

  static defaultProps = {
    withHelpPanel: true,
  };

  getPanelProps = (panel) => {
    return Object.assign({}, panel.props, {
      selectedComponents: this.props.selectedComponents,
      key: getPanelKey(panel.props),
    });
  };

  render() {
    return (
      <div className="opened-panels">
        <UA.panelsAnimation>
          {this.props.withHelpPanel && this.props.helpPanelProps ? (
            <Panels.frames.HelpPanelFrame
              key="helpPanel"
              isMobileEditor={this.props.isMobileEditor}
              {...this.props.helpPanelProps}
            />
          ) : null}
          {this.props.openPanels.map((panel) => (
            <LazyComponent
              moduleName={panel.name}
              key={panel.name}
              componentLoader={panel.panelLoader}
              onModuleLoaded={() => {
                this.forceUpdate();
              }}
              {...this.getPanelProps(panel)}
            />
          ))}
        </UA.panelsAnimation>
      </div>
    );
  }
}

export default util.hoc.connect(
  util.hoc.STORES.MOUSE_OPS,
  mapStateToProps,
)(OpenedPanels);
