import { useRef } from 'react';
import type { AddPanelDispatchProps } from './types';

export const UNIT_TEST_TRACK_CATEGORY_OPEN_TIMEOUT = 100;
const PRODUCTION_TRACK_OPEN_DELAY = 1000;

const TRACK_OPEN_TIMEOUT =
  process.env.NODE_ENV === 'test'
    ? UNIT_TEST_TRACK_CATEGORY_OPEN_TIMEOUT
    : PRODUCTION_TRACK_OPEN_DELAY;

const useTrackOpen = <T extends [...any]>(
  fn: (...args: T) => void,
  timeout: number = TRACK_OPEN_TIMEOUT,
) => {
  const trackCategoryOpenTimeoutIDRef = useRef<number | undefined>();

  return (...args: T) => {
    if (trackCategoryOpenTimeoutIDRef.current) {
      window.clearTimeout(trackCategoryOpenTimeoutIDRef.current);
    }

    trackCategoryOpenTimeoutIDRef.current = window.setTimeout(() => {
      fn(...args);
    }, timeout);
  };
};

export const useTrackSectionOpen = (
  trackSectionOpen: AddPanelDispatchProps['trackSectionOpen'],
): ((params: { categoryId: string; sectionName: string }) => void) => {
  const fn =
    useTrackOpen<Parameters<AddPanelDispatchProps['trackSectionOpen']>>(
      trackSectionOpen,
    );

  return ({ categoryId, sectionName }) => fn(categoryId, sectionName);
};

export const useTrackCategoryOpen = (
  trackCategoryOpen: AddPanelDispatchProps['trackCategoryOpen'],
): ((params: {
  categoryId: string;
  origin: string;
  biParams: {
    is_vertical_installed: boolean | null;
    category_under_scroll: boolean;
    isScrollable: boolean | null;
    window_width: number;
    window_height: number;
  };
}) => void) => {
  const fn =
    useTrackOpen<Parameters<AddPanelDispatchProps['trackCategoryOpen']>>(
      trackCategoryOpen,
    );

  return ({ categoryId, origin, biParams }) => fn(categoryId, origin, biParams);
};
