'use strict'
const addPanelDataConsts = require("@/addPanelDataConsts")
module.exports = 
{
  "type": addPanelDataConsts.SECTIONS_TYPES.PRESET,
  "hide": false,
  "title": "add_section_label_breadcrumbs",
  "subNavigationTitle": "add_section_heading_breadcrumbs",
  "presetTitle": "add_section_heading_breadcrumbs",
  "tooltipTitle": "add_section_heading_breadcrumbs",
  "automationId": "add-panel-section-breadcrumbsSection",
  "subNavigationHide": false,
  "showSectionHeader": true,
  "additionalBehaviours": {
    "labelMode": addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
    "infoIcon": addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
    "hoverImageAction": addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
    "iconEnabledForComps": {}
  },
  "props": {
    "image": "addPanelData/sections/breadcrumbs_en/breadcrumbs_en_v2.png",
    "imageHover": null,
    "items": [
      {
        "id": "none_0",
        "structure": {
          "type": "Component",
          "metaData": {
            "pageId": "my67s"
          },
          "skin": "wixui.skins.Skinless",
          "layout": {
            "width": 304,
            "height": 36,
            "x": 20,
            "y": 20,
            "scale": 1,
            "rotationInDegrees": 0,
            "fixedPosition": false
          },
          "componentType": "wixui.Breadcrumbs",
          "data": {
            "svgId": "f2ac30_5b22d370308a4890a7c229cfd3660239.svg",
            "menuRef": "#dataItem-kj9z4f5o",
            "type": "BreadcrumbsData",
            "metaData": {
              "isHidden": false,
              "schemaVersion": "1.0",
              "isPreset": false,
              "pageId": "my67s"
            }
          },
          "props": {
            "shouldWrap": true,
            "verticalMargin": 0,
            "separator": "slash",
            "showCurrentPage": true,
            "direction": "ltr",
            "showHomePageAsIcon": false,
            "showOnlyPreviousPageOnMobile": false,
            "horizontalMargin": 0,
            "alignment": "flex-start",
            "itemsBeforeCollapse": 1,
            "itemsAfterCollapse": 1,
            "isCollapsedByDefault": true,
            "type": "BreadcrumbsProperties",
            "metaData": {
              "schemaVersion": "1.0",
              "autoGenerated": false,
              "pageId": "my67s"
            },
            "showHomePage": true
          },
          "mobileHints": {
            "type": "MobileHints",
            "metaData": {
              "isPreset": false,
              "schemaVersion": "1.0",
              "isHidden": false,
              "pageId": "my67s"
            }
          },
          "style": {
            "type": "ComponentStyle",
            "metaData": {
              "isPreset": false,
              "schemaVersion": "1.0",
              "isHidden": false,
              "pageId": "my67s"
            },
            "style": {
              "properties": {
                "$st-css": ".root::anchor {}\n.root::breadcrumb {\n    font-size: 16px;\n    font-family: \"libre baskerville\",serif;\n}.root::breadcrumb-content {\n    padding: 10px;\n    font-size: 16px;\n    font-family: \"libre baskerville\",serif;\n    color: #000000;\n}.root::breadcrumb-content:isCurrent {\n    color: #D83501;\n}.root::breadcrumb-content:hover {\n    color: #D83501;\n}/* START STYLABLE DIRECTIVE RULES */\n:import {-st-from: '/site.st.css'; -st-named: font_0, font_1, font_2, font_3, font_4, font_5, font_6, font_7, font_8, font_9, font_10;}\n\n/* END STYLABLE DIRECTIVE RULES */:import {-st-from: 'wix-ui-santa/index.st.css'; -st-named: Breadcrumbs;} .root { -st-extends: Breadcrumbs; border: 0px solid rgb(50, 50, 50); border-radius: 0px; background: value(site_1_1); } .root::list {\n    display: initial;\n    font-style: normal;\n    font-size: 16px;\n    text-decoration-line: none;\n    color: #323232;\n    font-family: \"libre baskerville\",serif;\n    font-weight: 400;\n} .root::separator {\n    color: #323232;\n    font-family: \"libre baskerville\",serif;\n    font-size: 16px;\n}"
              },
              "propertiesSource": {
                "$st-css": "value"
              }
            },
            "componentClassName": "wixui.Breadcrumbs",
            "pageId": "",
            "compId": "",
            "styleType": "custom",
            "skin": "wixui.skins.Skinless"
          },
          "mobileStructure": {
            "layout": {
              "width": 280,
              "height": 34,
              "x": 20,
              "y": 81,
              "scale": 1,
              "rotationInDegrees": 0,
              "fixedPosition": false
            },
            "metaData": {
              "originalCompId": "comp-ksymkpml",
              "author": "duplicate"
            }
          },
          "activeModes": {},
          "id": "comp-ksymkpml",
          "components": []
        },
        "preset": {
          "rect": {
            "x": 0,
            "y": 0,
            "width": 324,
            "height": 70
          },
          "tags": null
        }
      },
      {
        "id": "none_1",
        "structure": {
          "type": "Component",
          "metaData": {
            "pageId": "my67s"
          },
          "skin": "wixui.skins.Skinless",
          "layout": {
            "width": 304,
            "height": 36,
            "x": 20,
            "y": 91,
            "scale": 1,
            "rotationInDegrees": 0,
            "fixedPosition": false
          },
          "componentType": "wixui.Breadcrumbs",
          "data": {
            "type": "BreadcrumbsData",
            "metaData": {
              "isPreset": false,
              "schemaVersion": "1.0",
              "isHidden": false,
              "pageId": "my67s"
            },
            "menuRef": "#dataItem-kj9z4f5o",
            "svgId": "f2ac30_c19cbfdc3fd342b1a0cf021c54e4e779.svg"
          },
          "props": {
            "shouldWrap": true,
            "verticalMargin": 0,
            "separator": "slash",
            "showCurrentPage": true,
            "direction": "ltr",
            "showHomePageAsIcon": false,
            "showOnlyPreviousPageOnMobile": false,
            "horizontalMargin": 0,
            "alignment": "flex-start",
            "itemsBeforeCollapse": 1,
            "itemsAfterCollapse": 1,
            "isCollapsedByDefault": true,
            "type": "BreadcrumbsProperties",
            "metaData": {
              "schemaVersion": "1.0",
              "autoGenerated": false,
              "pageId": "my67s"
            },
            "showHomePage": true
          },
          "mobileHints": {
            "type": "MobileHints",
            "metaData": {
              "isPreset": false,
              "schemaVersion": "1.0",
              "isHidden": false,
              "pageId": "my67s"
            }
          },
          "style": {
            "type": "ComponentStyle",
            "metaData": {
              "isPreset": false,
              "schemaVersion": "1.0",
              "isHidden": false,
              "pageId": "my67s"
            },
            "style": {
              "properties": {
                "$st-css": ".root::breadcrumb {\n    font-size: 16px;\n    font-weight: 400;\n    font-family: futura-lt-w01-light,futura-lt-w05-light,sans-serif;\n}.root::breadcrumb-content {\n    padding: 10px;\n    font-size: 16px;\n    letter-spacing: 0.03em;\n    font-family: futura-lt-w01-light,futura-lt-w05-light,sans-serif;\n    color: #FFFFFF;\n}.root::breadcrumb-content:isCurrent {\n    text-decoration-line: underline;\n}.root::breadcrumb-content:hover {\n    text-decoration-line: underline;\n}/* START STYLABLE DIRECTIVE RULES */\n:import {-st-from: '/site.st.css'; -st-named: font_0, font_1, font_2, font_3, font_4, font_5, font_6, font_7, font_8, font_9, font_10;}\n\n/* END STYLABLE DIRECTIVE RULES */:import {-st-from: 'wix-ui-santa/index.st.css'; -st-named: Breadcrumbs;} .root { -st-extends: Breadcrumbs; background: #323232; border: 0px solid rgb(50, 50, 50); border-radius: 0px; } .root::list {\n    display: initial;\n    font-style: normal;\n    font-size: 16px;\n    color: #FFFFFF;\n    text-decoration-line: none;\n    font-family: futura-lt-w01-light,futura-lt-w05-light,sans-serif;\n    font-weight: 400;\n} .root::separator {\n    color: #FFFFFF;\n    display: initial;\n    font-family: futura-lt-w01-light,futura-lt-w05-light,sans-serif;\n    font-size: 16px;\n}"
              },
              "propertiesSource": {
                "$st-css": "value"
              }
            },
            "componentClassName": "wixui.Breadcrumbs",
            "pageId": "",
            "compId": "",
            "styleType": "custom",
            "skin": "wixui.skins.Skinless"
          },
          "mobileStructure": {
            "layout": {
              "width": 280,
              "height": 34,
              "x": 20,
              "y": 186,
              "scale": 1,
              "rotationInDegrees": 0,
              "fixedPosition": false
            },
            "metaData": {
              "originalCompId": "comp-ksylc4kj",
              "author": "duplicate"
            }
          },
          "activeModes": {},
          "id": "comp-ksylc4kj",
          "components": []
        },
        "preset": {
          "rect": {
            "x": 0,
            "y": 70,
            "width": 324,
            "height": 70
          },
          "tags": null
        }
      },
      {
        "id": "none_2",
        "structure": {
          "type": "Component",
          "metaData": {
            "pageId": "my67s"
          },
          "skin": "wixui.skins.Skinless",
          "layout": {
            "width": 304,
            "height": 36,
            "x": 20,
            "y": 158,
            "scale": 1,
            "rotationInDegrees": 0,
            "fixedPosition": false
          },
          "componentType": "wixui.Breadcrumbs",
          "data": {
            "type": "BreadcrumbsData",
            "metaData": {
              "isPreset": false,
              "schemaVersion": "1.0",
              "isHidden": false,
              "pageId": "my67s"
            },
            "menuRef": "#dataItem-kj9z4f5o",
            "svgId": "f2ac30_574fd53816aa4d46b3f9c3ab27385c0b.svg"
          },
          "props": {
            "shouldWrap": true,
            "verticalMargin": 0,
            "separator": "slash",
            "showCurrentPage": true,
            "direction": "ltr",
            "showHomePageAsIcon": true,
            "showOnlyPreviousPageOnMobile": false,
            "horizontalMargin": 16,
            "alignment": "flex-start",
            "itemsBeforeCollapse": 1,
            "itemsAfterCollapse": 1,
            "isCollapsedByDefault": true,
            "type": "BreadcrumbsProperties",
            "metaData": {
              "schemaVersion": "1.0",
              "autoGenerated": false,
              "pageId": "my67s"
            },
            "showHomePage": true
          },
          "mobileHints": {
            "type": "MobileHints",
            "metaData": {
              "isPreset": false,
              "schemaVersion": "1.0",
              "isHidden": false,
              "pageId": "my67s"
            }
          },
          "style": {
            "type": "ComponentStyle",
            "metaData": {
              "isPreset": false,
              "schemaVersion": "1.0",
              "isHidden": false,
              "pageId": "my67s"
            },
            "style": {
              "properties": {
                "$st-css": ".root::anchor {}\n.root::breadcrumb {\n    font-size: 16px;\n    font-family: helvetica-w01-roman,helvetica-w02-roman,helvetica-lt-w10-roman,sans-serif;\n}.root::breadcrumb-content {\n    padding: 10px;\n    font-size: 16px;\n    font-family: helvetica-w01-roman,helvetica-w02-roman,helvetica-lt-w10-roman,sans-serif;\n    letter-spacing: 0.02em;\n    color: #000000;\n}.root::breadcrumb-content:isCurrent {\n    text-decoration-line: underline;\n}.root::breadcrumb-content:hover {\n    text-decoration-line: underline;\n}/* START STYLABLE DIRECTIVE RULES */\n:import {-st-from: '/site.st.css'; -st-named: font_0, font_1, font_2, font_3, font_4, font_5, font_6, font_7, font_8, font_9, font_10;}\n\n/* END STYLABLE DIRECTIVE RULES */:import {-st-from: 'wix-ui-santa/index.st.css'; -st-named: Breadcrumbs;} .root { -st-extends: Breadcrumbs; border: 0px solid rgb(50, 50, 50); border-radius: 0px; background: value(site_1_1); } .root::list {\n    display: initial;\n    font-style: normal;\n    font-size: 16px;\n    text-decoration-line: none;\n    color: #323232;\n    font-weight: 400;\n    font-family: helvetica-w01-roman,helvetica-w02-roman,helvetica-lt-w10-roman,sans-serif;\n} .root::separator {\n    color: #323232;\n    font-family: helvetica-w01-roman,helvetica-w02-roman,helvetica-lt-w10-roman,sans-serif;\n    font-size: 16px;\n}"
              },
              "propertiesSource": {
                "$st-css": "value"
              }
            },
            "componentClassName": "wixui.Breadcrumbs",
            "pageId": "",
            "compId": "",
            "styleType": "custom",
            "skin": "wixui.skins.Skinless"
          },
          "mobileStructure": {
            "layout": {
              "width": 280,
              "height": 34,
              "x": 20,
              "y": 291,
              "scale": 1,
              "rotationInDegrees": 0,
              "fixedPosition": false
            },
            "props": {
              "shouldWrap": true,
              "verticalMargin": 0,
              "separator": "slash",
              "showCurrentPage": true,
              "direction": "ltr",
              "showHomePageAsIcon": true,
              "showOnlyPreviousPageOnMobile": false,
              "horizontalMargin": 16,
              "alignment": "flex-start",
              "itemsBeforeCollapse": 1,
              "itemsAfterCollapse": 1,
              "isCollapsedByDefault": true,
              "type": "BreadcrumbsProperties",
              "metaData": {
                "schemaVersion": "1.0",
                "autoGenerated": false,
                "pageId": "my67s"
              },
              "showHomePage": true
            },
            "metaData": {
              "originalCompId": "comp-kszzfgsx",
              "author": "duplicate"
            }
          },
          "activeModes": {},
          "id": "comp-kszzfgsx",
          "components": []
        },
        "preset": {
          "rect": {
            "x": 0,
            "y": 140,
            "width": 324,
            "height": 59
          },
          "tags": null
        }
      },
      {
        "id": "none_3",
        "structure": {
          "type": "Component",
          "metaData": {
            "pageId": "my67s"
          },
          "skin": "wixui.skins.Skinless",
          "layout": {
            "width": 304,
            "height": 38,
            "x": 20,
            "y": 227,
            "scale": 1,
            "rotationInDegrees": 0,
            "fixedPosition": false
          },
          "componentType": "wixui.Breadcrumbs",
          "data": {
            "type": "BreadcrumbsData",
            "metaData": {
              "isPreset": false,
              "schemaVersion": "1.0",
              "isHidden": false,
              "pageId": "my67s"
            },
            "menuRef": "#dataItem-kj9z4f5o",
            "svgId": "f2ac30_15026993e7e34044be0fab8d7ed65dfc.svg"
          },
          "props": {
            "shouldWrap": true,
            "verticalMargin": 0,
            "separator": "slash",
            "showCurrentPage": true,
            "direction": "ltr",
            "showHomePageAsIcon": false,
            "showOnlyPreviousPageOnMobile": false,
            "horizontalMargin": 0,
            "alignment": "flex-start",
            "itemsBeforeCollapse": 1,
            "itemsAfterCollapse": 1,
            "isCollapsedByDefault": true,
            "type": "BreadcrumbsProperties",
            "metaData": {
              "schemaVersion": "1.0",
              "autoGenerated": false,
              "pageId": "my67s"
            },
            "showHomePage": true
          },
          "mobileHints": {
            "type": "MobileHints",
            "metaData": {
              "isPreset": false,
              "schemaVersion": "1.0",
              "isHidden": false,
              "pageId": "my67s"
            }
          },
          "style": {
            "type": "ComponentStyle",
            "metaData": {
              "isPreset": false,
              "schemaVersion": "1.0",
              "isHidden": false,
              "pageId": "my67s"
            },
            "style": {
              "properties": {
                "$st-css": ".root::anchor {}\n.root::breadcrumb {\n    font-size: 16px;\n    font-family: avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif;\n    color: #000000;\n}.root::breadcrumb-content {\n    padding: 10px;\n    font-size: 16px;\n    font-family: avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif;\n    color: #000000;\n}.root::breadcrumb-content:isCurrent {\n    color: #757575;\n}.root::breadcrumb-content:hover {\n    color: #757575;\n}/* START STYLABLE DIRECTIVE RULES */\n:import {-st-from: '/site.st.css'; -st-named: font_0, font_1, font_2, font_3, font_4, font_5, font_6, font_7, font_8, font_9, font_10;}\n\n/* END STYLABLE DIRECTIVE RULES */:import {-st-from: 'wix-ui-santa/index.st.css'; -st-named: Breadcrumbs;} .root { -st-extends: Breadcrumbs; border-radius: 0px; background: value(site_1_1); border: 1px solid #757575; border-left: 0px solid value(site_1_4); border-right: 0px solid value(site_1_4); } .root::list {\n    display: initial;\n    font-style: normal;\n    font-size: 16px;\n    text-decoration-line: none;\n    font-weight: 700;\n    font-family: avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif;\n    color: #757575;\n} .root::separator {\n    color: #000000;\n    font-family: avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif;\n    font-size: 16px;\n}"
              },
              "propertiesSource": {
                "$st-css": "value"
              }
            },
            "componentClassName": "wixui.Breadcrumbs",
            "pageId": "",
            "compId": "",
            "styleType": "custom",
            "skin": "wixui.skins.Skinless"
          },
          "mobileStructure": {
            "layout": {
              "width": 280,
              "height": 36,
              "x": 20,
              "y": 396,
              "scale": 1,
              "rotationInDegrees": 0,
              "fixedPosition": false
            },
            "metaData": {
              "originalCompId": "comp-kszz90ea",
              "author": "duplicate"
            }
          },
          "activeModes": {},
          "id": "comp-kszz90ea",
          "components": []
        },
        "preset": {
          "rect": {
            "x": 0,
            "y": 199,
            "width": 324,
            "height": 72
          },
          "tags": null
        }
      },
      {
        "id": "none_4",
        "structure": {
          "type": "Component",
          "metaData": {
            "pageId": "my67s"
          },
          "skin": "wixui.skins.Skinless",
          "layout": {
            "width": 304,
            "height": 36,
            "x": 20,
            "y": 296,
            "scale": 1,
            "rotationInDegrees": 0,
            "fixedPosition": false
          },
          "componentType": "wixui.Breadcrumbs",
          "data": {
            "type": "BreadcrumbsData",
            "metaData": {
              "isPreset": false,
              "schemaVersion": "1.0",
              "isHidden": false,
              "pageId": "my67s"
            },
            "menuRef": "#dataItem-kj9z4f5o",
            "svgId": "f2ac30_5b22d370308a4890a7c229cfd3660239.svg"
          },
          "props": {
            "shouldWrap": true,
            "verticalMargin": 0,
            "separator": "greater-than",
            "showCurrentPage": true,
            "direction": "ltr",
            "showHomePageAsIcon": true,
            "showOnlyPreviousPageOnMobile": false,
            "horizontalMargin": 0,
            "alignment": "flex-start",
            "itemsBeforeCollapse": 1,
            "itemsAfterCollapse": 1,
            "isCollapsedByDefault": true,
            "type": "BreadcrumbsProperties",
            "metaData": {
              "schemaVersion": "1.0",
              "autoGenerated": false,
              "pageId": "my67s"
            },
            "showHomePage": true
          },
          "mobileHints": {
            "type": "MobileHints",
            "metaData": {
              "isPreset": false,
              "schemaVersion": "1.0",
              "isHidden": false,
              "pageId": "my67s"
            }
          },
          "style": {
            "type": "ComponentStyle",
            "metaData": {
              "isPreset": false,
              "schemaVersion": "1.0",
              "isHidden": false,
              "pageId": "my67s"
            },
            "style": {
              "properties": {
                "$st-css": ".root::anchor {}\n.root::breadcrumb {\n    font-size: 16px;\n    font-family: barlow-extralight,barlow,sans-serif;\n}.root::breadcrumb-content {\n    padding: 10px;\n    font-size: 16px;\n    color: #000000;\n}.root::breadcrumb-content:isCurrent {\n    color: #0267D4;\n}.root::breadcrumb-content:hover {\n    color: #0267D4;\n}/* START STYLABLE DIRECTIVE RULES */\n:import {-st-from: '/site.st.css'; -st-named: font_0, font_1, font_2, font_3, font_4, font_5, font_6, font_7, font_8, font_9, font_10;}\n\n/* END STYLABLE DIRECTIVE RULES */:import {-st-from: 'wix-ui-santa/index.st.css'; -st-named: Breadcrumbs;} .root { -st-extends: Breadcrumbs; border: 0px solid rgb(50, 50, 50); border-radius: 0px; background: value(site_1_1); } .root::list {\n    display: initial;\n    font-style: normal;\n    font-size: 16px;\n    text-decoration-line: none;\n    font-family: barlow-extralight,barlow,sans-serif;\n    font-weight: 700;\n    color: #323232;\n} .root::separator {\n    color: #000000;\n    font-size: 19px;\n}"
              },
              "propertiesSource": {
                "$st-css": "value"
              }
            },
            "componentClassName": "wixui.Breadcrumbs",
            "pageId": "",
            "compId": "",
            "styleType": "custom",
            "skin": "wixui.skins.Skinless"
          },
          "mobileStructure": {
            "layout": {
              "width": 280,
              "height": 34,
              "x": 20,
              "y": 503,
              "scale": 1,
              "rotationInDegrees": 0,
              "fixedPosition": false
            },
            "metaData": {
              "originalCompId": "comp-kszzccy6",
              "author": "duplicate"
            }
          },
          "activeModes": {},
          "id": "comp-kszzccy6",
          "components": []
        },
        "preset": {
          "rect": {
            "x": 0,
            "y": 271,
            "width": 324,
            "height": 72
          },
          "tags": null
        }
      },
      {
        "id": "none_5",
        "structure": {
          "type": "Component",
          "metaData": {
            "pageId": "my67s"
          },
          "skin": "wixui.skins.Skinless",
          "layout": {
            "width": 304,
            "height": 44,
            "x": 20,
            "y": 353,
            "scale": 1,
            "rotationInDegrees": 0,
            "fixedPosition": false
          },
          "componentType": "wixui.Breadcrumbs",
          "data": {
            "type": "BreadcrumbsData",
            "metaData": {
              "isPreset": false,
              "schemaVersion": "1.0",
              "isHidden": false,
              "pageId": "my67s"
            },
            "menuRef": "#dataItem-kj9z4f5o",
            "svgId": "f2ac30_5b22d370308a4890a7c229cfd3660239.svg"
          },
          "props": {
            "shouldWrap": true,
            "verticalMargin": 0,
            "separator": "slash",
            "showCurrentPage": true,
            "direction": "ltr",
            "showHomePageAsIcon": false,
            "showOnlyPreviousPageOnMobile": false,
            "horizontalMargin": 2,
            "alignment": "flex-start",
            "itemsBeforeCollapse": 1,
            "itemsAfterCollapse": 1,
            "isCollapsedByDefault": true,
            "type": "BreadcrumbsProperties",
            "metaData": {
              "schemaVersion": "1.0",
              "autoGenerated": false,
              "pageId": "my67s"
            },
            "showHomePage": true
          },
          "mobileHints": {
            "type": "MobileHints",
            "metaData": {
              "isPreset": false,
              "schemaVersion": "1.0",
              "isHidden": false,
              "pageId": "my67s"
            }
          },
          "style": {
            "type": "ComponentStyle",
            "metaData": {
              "isPreset": false,
              "schemaVersion": "1.0",
              "isHidden": false,
              "pageId": "my67s"
            },
            "style": {
              "properties": {
                "$st-css": ".root::anchor {}\n.root::breadcrumb {\n    font-size: 16px;\n    font-family: avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif;\n}.root::breadcrumb-content {\n    padding: 13px;\n    padding-left: 20px;\n    padding-right: 20px;\n    border-radius: 0px;\n    background: value(site_1_1);\n    box-shadow: 4.99px -0.26px 6px -4px rgba(50,50,50,0);\n    border: 1px solid #000000;\n    padding-top: 8px;\n    padding-bottom: 8px;\n    font-size: 16px;\n    color: #000000;\n    font-family: avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif;\n}.root::breadcrumb-content:isCurrent {\n    background: #C9C7FF;\n}.root::breadcrumb-content:hover {\n    background: #C9C7FF;\n}/* START STYLABLE DIRECTIVE RULES */\n:import {-st-from: '/site.st.css'; -st-named: font_0, font_1, font_2, font_3, font_4, font_5, font_6, font_7, font_8, font_9, font_10;}\n\n/* END STYLABLE DIRECTIVE RULES */:import {-st-from: 'wix-ui-santa/index.st.css'; -st-named: Breadcrumbs;} .root { -st-extends: Breadcrumbs; border: 5px solid rgb(183, 243, 232); background: #B7F3E8; border-radius: 0px; } .root::list {\n    display: initial;\n    font-style: normal;\n    font-size: 16px;\n    text-decoration-line: none;\n    color: #000000;\n    font-family: avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif;\n    font-weight: 400;\n} .root::separator {\n    color: #323232;\n    display: none;\n}"
              },
              "propertiesSource": {
                "$st-css": "value"
              }
            },
            "componentClassName": "wixui.Breadcrumbs",
            "pageId": "",
            "compId": "",
            "styleType": "custom",
            "skin": "wixui.skins.Skinless"
          },
          "mobileStructure": {
            "layout": {
              "width": 280,
              "height": 42,
              "x": 20,
              "y": 608,
              "scale": 1,
              "rotationInDegrees": 0,
              "fixedPosition": false
            },
            "metaData": {
              "originalCompId": "comp-kszz33kc",
              "author": "duplicate"
            }
          },
          "activeModes": {},
          "id": "comp-kszz33kc",
          "components": []
        },
        "preset": {
          "rect": {
            "x": 0,
            "y": 343,
            "width": 324,
            "height": 72
          },
          "tags": null
        }
      },
      {
        "id": "none_6",
        "structure": {
          "type": "Component",
          "metaData": {
            "pageId": "my67s"
          },
          "skin": "wixui.skins.Skinless",
          "layout": {
            "width": 242,
            "height": 42,
            "x": 20,
            "y": 428,
            "scale": 1,
            "rotationInDegrees": 0,
            "fixedPosition": false
          },
          "componentType": "wixui.Breadcrumbs",
          "data": {
            "type": "BreadcrumbsData",
            "metaData": {
              "isPreset": false,
              "schemaVersion": "1.0",
              "isHidden": false,
              "pageId": "my67s"
            },
            "menuRef": "#dataItem-kj9z4f5o",
            "svgId": "f2ac30_6f1facbb605847debdd784ebda97a311.svg"
          },
          "props": {
            "shouldWrap": true,
            "verticalMargin": 0,
            "separator": "slash",
            "showCurrentPage": true,
            "direction": "ltr",
            "showHomePageAsIcon": false,
            "showOnlyPreviousPageOnMobile": false,
            "horizontalMargin": 0,
            "alignment": "flex-start",
            "itemsBeforeCollapse": 1,
            "itemsAfterCollapse": 1,
            "isCollapsedByDefault": true,
            "type": "BreadcrumbsProperties",
            "metaData": {
              "schemaVersion": "1.0",
              "autoGenerated": false,
              "pageId": "my67s"
            },
            "showHomePage": true
          },
          "mobileHints": {
            "type": "MobileHints",
            "metaData": {
              "isPreset": false,
              "schemaVersion": "1.0",
              "isHidden": false,
              "pageId": "my67s"
            }
          },
          "style": {
            "type": "ComponentStyle",
            "metaData": {
              "isPreset": false,
              "schemaVersion": "1.0",
              "isHidden": false,
              "pageId": "my67s"
            },
            "style": {
              "properties": {
                "$st-css": ".root::anchor {}\n.root::breadcrumb {\n    font-size: 16px;\n    font-family: avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif;\n    font-weight: 400;\n}.root::breadcrumb-content {\n    border-radius: 0px 100px 100px 0px;\n    box-shadow: 4.99px -0.26px 6px -4px rgba(50,50,50,0.39);\n    padding: 13px;\n    padding-left: 20px;\n    padding-right: 20px;\n    font-size: 16px;\n    font-family: avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif;\n    color: #1F29E7;\n}.root::breadcrumb-content:hover {\n    text-decoration-line: underline;\n}/* START STYLABLE DIRECTIVE RULES */\n:import {-st-from: '/site.st.css'; -st-named: font_0, font_1, font_2, font_3, font_4, font_5, font_6, font_7, font_8, font_9, font_10;}\n\n/* END STYLABLE DIRECTIVE RULES */:import {-st-from: 'wix-ui-santa/index.st.css'; -st-named: Breadcrumbs;} .root { -st-extends: Breadcrumbs; border: 0px solid rgb(50, 50, 50); background: #F1F3FF; border-radius: 0px 100px 100px 0px; } .root::list {\n    display: initial;\n    font-style: normal;\n    font-size: 16px;\n    text-decoration-line: none;\n    font-weight: 700;\n    color: #1F29E7;\n    font-family: avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif;\n} .root::separator {\n    color: #323232;\n    display: none;\n}"
              },
              "propertiesSource": {
                "$st-css": "value"
              }
            },
            "componentClassName": "wixui.Breadcrumbs",
            "pageId": "",
            "compId": "",
            "styleType": "custom",
            "skin": "wixui.skins.Skinless"
          },
          "mobileStructure": {
            "layout": {
              "width": 243,
              "height": 40,
              "x": 20,
              "y": 721,
              "scale": 1,
              "rotationInDegrees": 0,
              "fixedPosition": false
            },
            "metaData": {
              "originalCompId": "comp-kszys2wq",
              "author": "duplicate"
            }
          },
          "activeModes": {},
          "id": "comp-kszys2wq",
          "components": []
        },
        "preset": {
          "rect": {
            "x": 0,
            "y": 415,
            "width": 324,
            "height": 72
          },
          "tags": null
        }
      },
      {
        "id": "none_7",
        "structure": {
          "type": "Component",
          "metaData": {
            "pageId": "my67s"
          },
          "skin": "wixui.skins.Skinless",
          "layout": {
            "width": 304,
            "height": 36,
            "x": 20,
            "y": 500,
            "scale": 1,
            "rotationInDegrees": 0,
            "fixedPosition": false
          },
          "componentType": "wixui.Breadcrumbs",
          "data": {
            "type": "BreadcrumbsData",
            "metaData": {
              "isPreset": false,
              "schemaVersion": "1.0",
              "isHidden": false,
              "pageId": "my67s"
            },
            "menuRef": "#dataItem-kj9z4f5o",
            "svgId": "f2ac30_701af8c9f3954abc81f109931d447fe6.svg"
          },
          "props": {
            "shouldWrap": true,
            "verticalMargin": 0,
            "separator": "slash",
            "showCurrentPage": true,
            "direction": "ltr",
            "showHomePageAsIcon": false,
            "showOnlyPreviousPageOnMobile": false,
            "horizontalMargin": 0,
            "alignment": "flex-start",
            "itemsBeforeCollapse": 1,
            "itemsAfterCollapse": 1,
            "isCollapsedByDefault": true,
            "type": "BreadcrumbsProperties",
            "metaData": {
              "schemaVersion": "1.0",
              "autoGenerated": false,
              "pageId": "my67s"
            },
            "showHomePage": true
          },
          "mobileHints": {
            "type": "MobileHints",
            "metaData": {
              "isPreset": false,
              "schemaVersion": "1.0",
              "isHidden": false,
              "pageId": "my67s"
            }
          },
          "style": {
            "type": "ComponentStyle",
            "metaData": {
              "isPreset": false,
              "schemaVersion": "1.0",
              "isHidden": false,
              "pageId": "my67s"
            },
            "style": {
              "properties": {
                "$st-css": ".root::anchor {}\n.root::breadcrumb {\n    font-size: 16px;\n    font-family: \"arial black\",arial-w01-black,arial-w02-black,\"arial-w10 black\",sans-serif;\n}.root::breadcrumb-content {\n    border-radius: 0px;\n    box-shadow: 4.99px -0.26px 6px -4px rgba(50,50,50,0);\n    padding-top: 8px;\n    padding-bottom: 8px;\n    border: 0px solid value(site_1_5);\n    background: rgba(255,255,255,0);\n    padding-left: 8px;\n    padding-right: 8px;\n    padding: 10px;\n    font-size: 16px;\n    color: #000000;\n    font-family: \"arial black\",arial-w01-black,arial-w02-black,\"arial-w10 black\",sans-serif;\n}.root::breadcrumb-content:isCurrent {\n    color: #6059D4;\n}.root::breadcrumb-content:hover {\n    color: #6059D4;\n}/* START STYLABLE DIRECTIVE RULES */\n:import {-st-from: '/site.st.css'; -st-named: font_0, font_1, font_2, font_3, font_4, font_5, font_6, font_7, font_8, font_9, font_10;}\n\n/* END STYLABLE DIRECTIVE RULES */:import {-st-from: 'wix-ui-santa/index.st.css'; -st-named: Breadcrumbs;} .root { -st-extends: Breadcrumbs; border-radius: 0px; background: #F7F7F7; border: 0px solid rgb(247, 247, 247); } .root::list {\n    display: initial;\n    font-style: normal;\n    font-size: 16px;\n    text-decoration-line: none;\n    color: #000000;\n    font-weight: 400;\n    font-family: \"arial black\",arial-w01-black,arial-w02-black,\"arial-w10 black\",sans-serif;\n} .root::separator {\n    display: initial;\n    color: #000000;\n    font-family: \"arial black\",arial-w01-black,arial-w02-black,\"arial-w10 black\",sans-serif;\n}"
              },
              "propertiesSource": {
                "$st-css": "value"
              }
            },
            "componentClassName": "wixui.Breadcrumbs",
            "pageId": "",
            "compId": "",
            "styleType": "custom",
            "skin": "wixui.skins.Skinless"
          },
          "mobileStructure": {
            "layout": {
              "width": 280,
              "height": 34,
              "x": 20,
              "y": 832,
              "scale": 1,
              "rotationInDegrees": 0,
              "fixedPosition": false
            },
            "metaData": {
              "originalCompId": "comp-kszzjavd",
              "author": "duplicate"
            }
          },
          "activeModes": {},
          "id": "comp-kszzjavd",
          "components": []
        },
        "preset": {
          "rect": {
            "x": 0,
            "y": 487,
            "width": 324,
            "height": 72
          },
          "tags": null
        }
      },
      {
        "id": "none_8",
        "structure": {
          "type": "Component",
          "metaData": {
            "pageId": "my67s"
          },
          "skin": "wixui.skins.Skinless",
          "layout": {
            "width": 304,
            "height": 36,
            "x": 20,
            "y": 566,
            "scale": 1,
            "rotationInDegrees": 0,
            "fixedPosition": false
          },
          "componentType": "wixui.Breadcrumbs",
          "data": {
            "type": "BreadcrumbsData",
            "metaData": {
              "isPreset": false,
              "schemaVersion": "1.0",
              "isHidden": false,
              "pageId": "my67s"
            },
            "menuRef": "#dataItem-kj9z4f5o",
            "svgId": "f2ac30_1e816ad4363b43659d76b72e76f69a42.svg"
          },
          "props": {
            "shouldWrap": true,
            "verticalMargin": 0,
            "separator": "slash",
            "showCurrentPage": true,
            "direction": "ltr",
            "showHomePageAsIcon": true,
            "showOnlyPreviousPageOnMobile": false,
            "horizontalMargin": 0,
            "alignment": "flex-start",
            "itemsBeforeCollapse": 1,
            "itemsAfterCollapse": 1,
            "isCollapsedByDefault": true,
            "type": "BreadcrumbsProperties",
            "metaData": {
              "schemaVersion": "1.0",
              "autoGenerated": false,
              "pageId": "my67s"
            },
            "showHomePage": true
          },
          "mobileHints": {
            "type": "MobileHints",
            "metaData": {
              "isPreset": false,
              "schemaVersion": "1.0",
              "isHidden": false,
              "pageId": "my67s"
            }
          },
          "style": {
            "type": "ComponentStyle",
            "metaData": {
              "isPreset": false,
              "schemaVersion": "1.0",
              "isHidden": false,
              "pageId": "my67s"
            },
            "style": {
              "properties": {
                "$st-css": ".root::anchor {}\n.root::breadcrumb {\n    font-size: 16px;\n    font-family: futura-lt-w01-light,futura-lt-w05-light,sans-serif;\n    letter-spacing: 0.05em;\n}.root::breadcrumb-content {\n    border-radius: 0px;\n    box-shadow: 4.99px -0.26px 6px -4px rgba(50,50,50,0);\n    padding-top: 8px;\n    padding-bottom: 8px;\n    border: 0px solid value(site_1_5);\n    background: rgba(255,255,255,0);\n    padding-left: 8px;\n    padding-right: 8px;\n    padding: 10px;\n    font-size: 16px;\n    color: #A2502C;\n    font-family: futura-lt-w01-light,futura-lt-w05-light,sans-serif;\n}.root::breadcrumb-content:isCurrent {\n    color: #000000;\n}.root::breadcrumb-content:hover {\n    color: #000000;\n}/* START STYLABLE DIRECTIVE RULES */\n:import {-st-from: '/site.st.css'; -st-named: font_0, font_1, font_2, font_3, font_4, font_5, font_6, font_7, font_8, font_9, font_10;}\n\n/* END STYLABLE DIRECTIVE RULES */:import {-st-from: 'wix-ui-santa/index.st.css'; -st-named: Breadcrumbs;} .root { -st-extends: Breadcrumbs; border-radius: 0px; border: 0px solid rgb(247, 247, 247); background: #FFF5EF; } .root::list {\n    display: initial;\n    font-style: normal;\n    font-size: 16px;\n    text-decoration-line: none;\n    font-family: futura-lt-w01-light,futura-lt-w05-light,sans-serif;\n    font-weight: 700;\n    color: #A2502C;\n} .root::separator {\n    display: initial;\n    color: #A2502C;\n    font-family: futura-lt-w01-light,futura-lt-w05-light,sans-serif;\n}"
              },
              "propertiesSource": {
                "$st-css": "value"
              }
            },
            "componentClassName": "wixui.Breadcrumbs",
            "pageId": "",
            "compId": "",
            "styleType": "custom",
            "skin": "wixui.skins.Skinless"
          },
          "mobileStructure": {
            "layout": {
              "width": 280,
              "height": 34,
              "x": 20,
              "y": 937,
              "scale": 1,
              "rotationInDegrees": 0,
              "fixedPosition": false
            },
            "metaData": {
              "originalCompId": "comp-kszzm5b3",
              "author": "duplicate"
            }
          },
          "activeModes": {},
          "id": "comp-kszzm5b3",
          "components": []
        },
        "preset": {
          "rect": {
            "x": 0,
            "y": 567,
            "width": 324,
            "height": 80
          },
          "tags": null
        }
      },
      {
        "id": "none_9",
        "structure": {
          "type": "Component",
          "metaData": {
            "pageId": "my67s"
          },
          "skin": "wixui.skins.Skinless",
          "layout": {
            "width": 304,
            "height": 44,
            "x": 20,
            "y": 633,
            "scale": 1,
            "rotationInDegrees": 0,
            "fixedPosition": false
          },
          "componentType": "wixui.Breadcrumbs",
          "data": {
            "type": "BreadcrumbsData",
            "metaData": {
              "isPreset": false,
              "schemaVersion": "1.0",
              "isHidden": false,
              "pageId": "my67s"
            },
            "menuRef": "#dataItem-kj9z4f5o",
            "svgId": "f2ac30_c2f931ea1fbb4e139f227b6a97f4ce12.svg"
          },
          "props": {
            "shouldWrap": true,
            "verticalMargin": 0,
            "separator": "slash",
            "showCurrentPage": true,
            "direction": "ltr",
            "showHomePageAsIcon": false,
            "showOnlyPreviousPageOnMobile": false,
            "horizontalMargin": 6,
            "alignment": "flex-start",
            "itemsBeforeCollapse": 1,
            "itemsAfterCollapse": 1,
            "isCollapsedByDefault": true,
            "type": "BreadcrumbsProperties",
            "metaData": {
              "schemaVersion": "1.0",
              "autoGenerated": false,
              "pageId": "my67s"
            },
            "showHomePage": true,
          },
          "mobileHints": {
            "type": "MobileHints",
            "metaData": {
              "isPreset": false,
              "schemaVersion": "1.0",
              "isHidden": false,
              "pageId": "my67s"
            }
          },
          "style": {
            "type": "ComponentStyle",
            "metaData": {
              "isPreset": false,
              "schemaVersion": "1.0",
              "isHidden": false,
              "pageId": "my67s"
            },
            "style": {
              "properties": {
                "$st-css": ".root::anchor {}\n.root::breadcrumb {\n    font-size: 16px;\n    font-family: poppins-extralight,poppins,sans-serif;\n    font-weight: 700;\n}.root::breadcrumb-content {\n    box-shadow: 4.99px -0.26px 6px -4px rgba(50,50,50,0);\n    border: 0px solid value(site_1_5);\n    background: rgba(255,255,255,0);\n    padding: 10px;\n    font-size: 16px;\n    color: #000000;\n    font-family: poppins-extralight,poppins,sans-serif;\n    padding-left: 14px;\n    padding-right: 14px;\n    padding-top: 8px;\n    padding-bottom: 8px;\n}.root::breadcrumb-content:isCurrent {\n    background: value(site_1_5);\n    color: #FFFFFF;\n    border-radius: 30px;\n    border: 0px solid value(site_1_5);\n}.root::breadcrumb-content:hover {\n    background: value(site_1_5);\n    font-size: 16px;\n    color: #FFFFFF;\n    border-radius: 30px;\n    border: 0px solid value(site_1_5);\n    padding-left: 14px;\n    padding-right: 14px;\n    padding-top: 8px;\n    padding-bottom: 8px;\n}/* START STYLABLE DIRECTIVE RULES */\n:import {-st-from: '/site.st.css'; -st-named: font_0, font_1, font_2, font_3, font_4, font_5, font_6, font_7, font_8, font_9, font_10;}\n\n/* END STYLABLE DIRECTIVE RULES */:import {-st-from: 'wix-ui-santa/index.st.css'; -st-named: Breadcrumbs;} .root { -st-extends: Breadcrumbs; background: #EFEFF4; border-radius: 100px; border: 6px solid rgb(239, 239, 244); } .root::list {\n    display: initial;\n    font-style: normal;\n    font-size: 16px;\n    text-decoration-line: none;\n    font-family: futura-lt-w01-light,futura-lt-w05-light,sans-serif;\n    font-weight: 700;\n    color: #000000;\n} .root::separator {\n    display: initial;\n    font-family: poppins-extralight,poppins,sans-serif;\n    color: #000000;\n}"
              },
              "propertiesSource": {
                "$st-css": "value"
              }
            },
            "componentClassName": "wixui.Breadcrumbs",
            "pageId": "",
            "compId": "",
            "styleType": "custom",
            "skin": "wixui.skins.Skinless"
          },
          "mobileStructure": {
            "layout": {
              "width": 280,
              "height": 34,
              "x": 20,
              "y": 1042,
              "scale": 1,
              "rotationInDegrees": 0,
              "fixedPosition": false
            },
            "metaData": {
              "originalCompId": "comp-kszzq0bn",
              "author": "duplicate"
            }
          },
          "activeModes": {},
          "id": "comp-kszzq0bn",
          "components": []
        },
        "preset": {
          "rect": {
            "x": 0,
            "y": 639,
            "width": 324,
            "height": 72
          },
          "tags": null
        }
      },
      {
        "id": "none_10",
        "structure": {
          "type": "Component",
          "metaData": {
            "pageId": "my67s"
          },
          "skin": "wixui.skins.Skinless",
          "layout": {
            "width": 304,
            "height": 38,
            "x": 20,
            "y": 708,
            "scale": 1,
            "rotationInDegrees": 0,
            "fixedPosition": false
          },
          "componentType": "wixui.Breadcrumbs",
          "data": {
            "type": "BreadcrumbsData",
            "metaData": {
              "isPreset": false,
              "schemaVersion": "1.0",
              "isHidden": false,
              "pageId": "my67s"
            },
            "menuRef": "#dataItem-kj9z4f5o",
            "svgId": "f2ac30_39d190c69f1447589f415aa2eb8cf608.svg"
          },
          "props": {
            "shouldWrap": true,
            "verticalMargin": 0,
            "separator": "greater-than",
            "showCurrentPage": true,
            "direction": "ltr",
            "showHomePageAsIcon": false,
            "showOnlyPreviousPageOnMobile": false,
            "horizontalMargin": 8,
            "alignment": "flex-start",
            "itemsBeforeCollapse": 1,
            "itemsAfterCollapse": 1,
            "isCollapsedByDefault": true,
            "type": "BreadcrumbsProperties",
            "metaData": {
              "schemaVersion": "1.0",
              "autoGenerated": false,
              "pageId": "my67s"
            },
            "showHomePage": true
          },
          "mobileHints": {
            "type": "MobileHints",
            "metaData": {
              "isPreset": false,
              "schemaVersion": "1.0",
              "isHidden": false,
              "pageId": "my67s"
            }
          },
          "style": {
            "type": "ComponentStyle",
            "metaData": {
              "isPreset": false,
              "schemaVersion": "1.0",
              "isHidden": false,
              "pageId": "my67s"
            },
            "style": {
              "properties": {
                "$st-css": ".root::anchor {}\n.root::breadcrumb {\n    font-size: 16px;\n    font-family: questrial,sans-serif;\n}.root::breadcrumb-content {\n    border-radius: 0px;\n    box-shadow: 4.99px -0.26px 6px -4px rgba(50,50,50,0);\n    padding-top: 8px;\n    padding-bottom: 8px;\n    background: rgba(255,255,255,0);\n    border: 0px solid value(site_1_5);\n    padding: 6px;\n    font-size: 16px;\n    color: #FCF7E6;\n    padding-left: 14px;\n    padding-right: 14px;\n}.root::breadcrumb-content:isCurrent {\n    background: #FCF7E6;\n    color: #ED6B4F;\n}.root::breadcrumb-content:hover {\n    color: #ED6B4F;\n    background: #FCF7E6;\n}/* START STYLABLE DIRECTIVE RULES */\n:import {-st-from: '/site.st.css'; -st-named: font_0, font_1, font_2, font_3, font_4, font_5, font_6, font_7, font_8, font_9, font_10;}\n\n/* END STYLABLE DIRECTIVE RULES */:import {-st-from: 'wix-ui-santa/index.st.css'; -st-named: Breadcrumbs;} .root { -st-extends: Breadcrumbs; border-radius: 0px; background: #ED6B4F; border: 5px solid #ED6B4F; } .root::list {\n    display: initial;\n    font-style: normal;\n    font-size: 16px;\n    text-decoration-line: none;\n    font-weight: 400;\n    font-family: questrial,sans-serif;\n    color: #FCF7E6;\n} .root::separator {\n    display: initial;\n    color: #FCF7E6;\n}"
              },
              "propertiesSource": {
                "$st-css": "value"
              }
            },
            "componentClassName": "wixui.Breadcrumbs",
            "pageId": "",
            "compId": "",
            "styleType": "custom",
            "skin": "wixui.skins.Skinless"
          },
          "mobileStructure": {
            "layout": {
              "width": 280,
              "height": 36,
              "x": 20,
              "y": 1147,
              "scale": 1,
              "rotationInDegrees": 0,
              "fixedPosition": false
            },
            "metaData": {
              "originalCompId": "comp-kszzubc9",
              "author": "duplicate"
            }
          },
          "activeModes": {},
          "id": "comp-kszzubc9",
          "components": []
        },
        "preset": {
          "rect": {
            "x": 0,
            "y": 711,
            "width": 324,
            "height": 72
          },
          "tags": null
        }
      }
    ],
    "compTypes": [
      "wixui.Breadcrumbs"
    ]
  },
  "help": {
    "hide": false,
    "text": "add_section_info_custom"
  }
};