import _ from 'lodash';
import * as util from '@/util';
import * as rulersComputations from './rulersComputations';
import * as coreBi from '@/coreBi';
import { withRestrictions } from '@/editorRestrictions';
import { mapStateToProps, mapDispatchToProps } from './rulersMapper';

import React from 'react';
import Guide from './guide';
import { cx } from '@/util';

import type {
  RulersStateProps,
  RulersDispatchProps,
  RulersOwnProps,
  RulersDirection,
} from './rulersMapper';

const RULERS_NUMBER_DISTANCE_FROM_TICKS_VERTICAL = 4;
const DESKTOP_TICKS_POSITION_IN_BACKGROUND = '5px -21px';
const MOBILE_AND_FIXED_STAGE_TICKS_POSITION_IN_BACKGROUND = '5px 0';

interface RulersProps
  extends RulersStateProps,
    RulersDispatchProps,
    RulersOwnProps {}
class Rulers extends React.Component<RulersProps> {
  static displayName = 'rulers';

  private isMouseOver: boolean;
  private verticalRulersComponentWidth: number;
  private rulerContainerRef = React.createRef<HTMLDivElement>();
  private verticalTicksContainerRef = React.createRef<HTMLDivElement>();

  constructor(props: RulersProps) {
    super(props);

    this.isMouseOver = false;
    this.verticalRulersComponentWidth = 0;

    const extendedShortcuts = util.keyboardShortcuts.extendContext(
      util.keyboardShortcuts.CONTEXTS.EDITOR,
      this.getRulersKeyboardShortcuts(),
    );
    util.keyboardShortcuts.registerContext(
      util.keyboardShortcuts.CONTEXTS.RULERS,
      extendedShortcuts,
    );

    this.state = {};
  }

  componentDidMount() {
    if (this.props.rulerDirection === 'vertical') {
      this.verticalRulersComponentWidth = this.getVerticalRulerWidth();
    }
  }

  componentDidUpdate(prevProps: RulersProps) {
    if (
      prevProps.siteScroll.x !== this.props.siteScroll.x &&
      this.verticalTicksContainerRef?.current
    ) {
      this.verticalTicksContainerRef.current.scrollLeft =
        this.props.siteScroll.x;
    }
    if (
      !prevProps.isHovered &&
      this.props.isHovered &&
      prevProps.rulerDirection === 'vertical'
    ) {
      this.props.sendBIEvent('RULERS_HOVER', {});
    }

    if (
      _.isEmpty(prevProps.selectedComponents) &&
      !_.isEmpty(this.props.selectedComponents) &&
      this.props.selectedGuideId
    ) {
      this.props.deselectGuide();
    }
    if (this.props.rulerDirection === 'vertical') {
      this.verticalRulersComponentWidth = this.getVerticalRulerWidth();
    }
  }

  componentWillUnmount() {
    util.keyboardShortcuts.unregisterContext(
      util.keyboardShortcuts.CONTEXTS.RULERS,
    );
  }

  getRulersKeyboardShortcuts = () => {
    return {
      'del,backspace': this.props.removeGuideViaDelBtn,
      'shift + right': _.partial(
        this.changeGuidePositionViaKeyboard,
        'vertical',
        10,
      ),
      'shift + left': _.partial(
        this.changeGuidePositionViaKeyboard,
        'vertical',
        -10,
      ),
      'shift + up': _.partial(
        this.changeGuidePositionViaKeyboard,
        'horizontal',
        -10,
      ),
      'shift + down': _.partial(
        this.changeGuidePositionViaKeyboard,
        'horizontal',
        10,
      ),
      left: _.partial(this.changeGuidePositionViaKeyboard, 'vertical', -1),
      right: _.partial(this.changeGuidePositionViaKeyboard, 'vertical', 1),
      up: _.partial(this.changeGuidePositionViaKeyboard, 'horizontal', -1),
      down: _.partial(this.changeGuidePositionViaKeyboard, 'horizontal', 1),
    };
  };

  changeGuidePositionViaKeyboard = (
    rulersDirection: RulersDirection,
    pixels: number,
  ) => {
    if (
      !this.isGuideOutOfLimits(
        this.props.selectedGuideData.guidePosition + pixels,
        rulersDirection,
      )
    ) {
      this.props.changeGuidePositionByPixels(rulersDirection, pixels);
    }
  };

  getVerticalRulerWidth = () => {
    const rulersContainerNode = this.rulerContainerRef;
    if (rulersContainerNode.current) {
      return rulersContainerNode.current.getBoundingClientRect().width;
    }
    return 0;
  };

  getRulerNumbers = () => {
    const siteHeight = this.props.previewHeight;
    const {
      rulerDirection,
      previewPosition,
      isMobileEditor,
      pagesContainerAbsLayout,
      isFixedStageEnabled,
    } = this.props;
    return rulersComputations.getRulerNumbers(
      isMobileEditor,
      pagesContainerAbsLayout,
      siteHeight,
      previewPosition,
      rulerDirection,
      isFixedStageEnabled,
    );
  };

  getRulersNumbersStyle = () => {
    if (this.props.rulerDirection === 'vertical') {
      return {
        left: this.props.isMobileEditor
          ? RULERS_NUMBER_DISTANCE_FROM_TICKS_VERTICAL
          : this.props.pagesContainerAbsLayout.x +
            RULERS_NUMBER_DISTANCE_FROM_TICKS_VERTICAL,
      };
    }
    return {
      top:
        this.props.isMobileEditor || this.props.isFixedStageEnabled
          ? 1
          : Math.floor(this.props.previewPosition.top / 100) * 100 + 80 + 1,
    };
  };

  getRootClasses = () => {
    return cx('rulers', {
      'mobile-editor-rulers': this.props.isMobileEditor,
      'for-sections': util.sections.isSectionsEnabled(),
      'vertical-ruler': this.props.rulerDirection === 'vertical',
      'horizontal-ruler': this.props.rulerDirection === 'horizontal',
      'rulers-in-fixed-stage': this.props.isFixedStageEnabled,
      'rulers-in-horizontally-scrolllable-stage':
        this.props.isStageHorizontallyScrollable,
    });
  };

  getRulersStyle = () => {
    if (this.props.rulerDirection === 'vertical') {
      const pagesContainerLayout = this.props.pagesContainerAbsLayout;

      const verticalTicksStyle = {
        width: rulersComputations.getVerticalTicksWidth(
          this.props.isMobileEditor,
          pagesContainerLayout,
        ),

        ...(this.props.isMobileEditor && {
          left: pagesContainerLayout.x,
        }),
      };

      return verticalTicksStyle;
    } else if (
      this.props.isMobileEditor &&
      !util.sections.isSectionsEnabled()
    ) {
      return {
        height: rulersComputations.getMobileHorizontalTicksHeight(
          this.props.viewPort,
        ),
      };
    }
    return {};
  };

  getTicksPositionInBackgroundStyle = () => {
    if (this.props.rulerDirection === 'vertical') {
      const pagesContainerLayout = this.props.pagesContainerAbsLayout;
      return {
        backgroundPosition: this.props.isMobileEditor
          ? 0
          : pagesContainerLayout.x + 1,
      };
    }
    return this.props.isMobileEditor || this.props.isFixedStageEnabled
      ? {
          backgroundPosition:
            MOBILE_AND_FIXED_STAGE_TICKS_POSITION_IN_BACKGROUND,
        }
      : { backgroundPosition: DESKTOP_TICKS_POSITION_IN_BACKGROUND };
  };

  isGuideOutOfLimits = (
    guideLocation: number,
    direction = this.props.rulerDirection,
  ) => {
    const { pagesContainerAbsLayout } = this.props;

    return rulersComputations.isGuideOutOfLimits(
      this.props.isMobileEditor,
      pagesContainerAbsLayout,
      this.props.viewPort,
      this.verticalRulersComponentWidth,
      direction,
      guideLocation,
      this.props.isFixedStageEnabled,
    );
  };

  handleMouseEnter: React.MouseEventHandler = (e) => {
    const target = e.target as Element;

    if (
      target.classList.contains('vertical-guide') ||
      target.classList.contains('horizontal-guide')
    ) {
      return;
    }

    this.isMouseOver = true;
    this.props.updateIsHovered(true);
  };

  handleMouseLeave = () => {
    this.isMouseOver = false;
    if (!this.props.isDragging) {
      this.props.updateIsHovered(false);
    }
  };

  handleMouseDown: React.MouseEventHandler = (e) => {
    if (
      [
        'rulers-vertical-ticks',
        'rulers-horizontal-ticks',
        'ruler-number',
        'ruler-numbers-container',
      ].includes((e.target as Element).className)
    ) {
      const newGuidePos = rulersComputations.calcNewGuidePosition(
        this.props.previewPosition,
        this.props.siteScroll,
        this.props.pagesContainerAbsLayout,
        this.props.rulerDirection,
        e,
      );
      if (!this.isGuideOutOfLimits(newGuidePos)) {
        this.setNewGuide(newGuidePos);
      }
    }
  };

  handleRightClick: React.MouseEventHandler = (e) => {
    e.preventDefault();
    this.props.sendBi(coreBi.events.rightClickMenu.RIGHT_CLICK_MENU_OPEN, {
      component: 'ruler_scale',
    });
    if (this.props.guidesInRuler.length !== 0) {
      this.props.openRightClickMenu(e, this, 'rulers');
    }
  };

  setNewGuide = (position: number) => {
    let BIParams = {};
    if (this.props.rulerDirection === 'vertical') {
      this.props.setVerticalGuide(position);
      BIParams = { xCoordinate: position, yCoordinate: 0 };
    } else {
      this.props.setHorizontalGuide(position);
      BIParams = { xCoordinate: 0, yCoordinate: position };
    }
    this.props.sendBIEvent('RULERS_CLICKED', BIParams);
  };

  render() {
    return (
      <div
        ref={this.rulerContainerRef}
        style={this.getRulersStyle()}
        onMouseDown={this.handleMouseDown}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        onContextMenu={this.handleRightClick}
        className={this.getRootClasses()}
      >
        {!this.props.toolsHidden ? (
          <div
            key={`${this.props.rulerDirection}-ruler`}
            className={`${this.props.rulerDirection}-ticks`}
            ref={this.verticalTicksContainerRef}
          >
            {!this.props.isMobileEditor &&
            !this.props.isFixedStageEnabled &&
            this.props.rulerDirection === 'vertical' ? (
              <div
                key="rulers-separator-blocker"
                className="rulers-separator-blocker"
              />
            ) : null}

            {!this.props.isMobileEditor &&
            !this.props.isFixedStageEnabled &&
            this.props.rulerDirection === 'horizontal' ? (
              <div
                key="rulers-separator"
                className={cx('rulers-separator', {
                  'rulers-separator-hover': this.props.isHovered,
                })}
              />
            ) : null}

            <div
              className={cx('rulers-background', {
                'rulers-background-hover': this.props.isHovered,
                'rulers-background-mobile': this.props.isMobileEditor,
              })}
            >
              <div
                style={this.getTicksPositionInBackgroundStyle()}
                className={`rulers-${this.props.rulerDirection}-ticks`}
              />
              <div
                style={this.getRulersNumbersStyle()}
                className="ruler-numbers-container"
              >
                {/* TODO: Fix this the next time the file is edited. */}
                {/* eslint-disable-next-line you-dont-need-lodash-underscore/map */}
                {_.map(this.getRulerNumbers(), (number) => (
                  <div key={`number-${number}`} className="ruler-number">
                    {number}
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : null}
        {this.props.guidesInRuler.map((guideline) => (
          <Guide
            key={guideline.guideId}
            rulerDirection={this.props.rulerDirection}
            guidePosition={guideline.guidePosition}
            guideId={guideline.guideId}
            isSelected={this.props.selectedGuideId === guideline.guideId}
            isGuideOutOfLimitsFunc={this.isGuideOutOfLimits}
            isMouseOverRulers={this.isMouseOver}
            toolsHidden={this.props.toolsHidden}
            startGuideDrag={this.props.startGuideDrag}
            disableInteraction={this.props.isDragging}
            pagesContainerAbsLayout={this.props.pagesContainerAbsLayout}
            stageLayout={this.props.stageLayout}
            isMobileEditor={this.props.isMobileEditor}
            sendBIEvent={this.props.sendBIEvent}
            selectGuide={this.props.selectGuide}
            deselectGuide={this.props.deselectGuide}
            removeGuideById={this.props.removeGuideById}
            updateStateOnDragEnd={this.props.updateStateOnDragEnd}
            changeGuidePosition={this.props.changeGuidePosition}
            previewSize={this.props.previewPosition}
            openRightClickMenu={this.props.openRightClickMenu}
            sendBi={this.props.sendBi}
            ruler={this}
            scrollLeft={this.props.siteScroll.x}
            editingAreaWidth={this.props.editingAreaWidth}
            isFixedStageTopGapEnabled={this.props.isFixedStageTopGapEnabled}
          />
        ))}
      </div>
    );
  }
}

const ConnectedRulers = withRestrictions('rEditor_rulers')(
  util.hoc.connect(
    util.hoc.STORES.EDITOR_API,
    mapStateToProps,
    mapDispatchToProps,
  )(Rulers),
);

export default ConnectedRulers;
