// @ts-nocheck
import _ from 'lodash';
import * as constants from '@/constants';
import * as gfppData from '@/gfppData';
import * as stateManagement from '@/stateManagement';
import experiment from 'experiment';

const { isReferredComponent } = stateManagement.components.selectors;

export default function (editorAPI, compRefs, compGfppData) {
  function isSelectableContainer(comRef) {
    return (
      editorAPI.components.getType(comRef) === constants.SELECTABLE_CONTAINER
    );
  }

  function isAncestorRepeaterConnected(compRef: CompRef) {
    const repeaterRef = editorAPI.components.getAncestorRepeater(compRef);

    return stateManagement.platform.selectors.isConnectedToDataBindingController(
      repeaterRef,
      editorAPI.dsRead,
    );
  }

  const isRepeaterItem =
    editorAPI.components.is.repeaterItem(compRefs) &&
    !isSelectableContainer(Array.isArray(compRefs) ? compRefs[0] : compRefs);

  if (
    isRepeaterItem &&
    compGfppData.mainActions &&
    !isReferredComponent(_.head(compRefs)) &&
    (experiment.isOpen('se_enableDatabindingForAppStudio')
      ? !isAncestorRepeaterConnected(_.head(compRefs))
      : true)
  ) {
    const action = gfppData.repeaterGfppUtils.getManageItemAction();

    if (gfppData.utils.isChangeBackgroundWithIconOnlyEnabled()) {
      compGfppData.mainActions.unshift(action);
      return;
    }
    compGfppData.mainActions.push(action);
  }
}
