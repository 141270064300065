//@ts-nocheck
import _ from 'lodash';
import AddPanelDragHandler from './AddPanelDragHandler';

import * as core from '@/core';

const { sectionDrag, sectionsReorganizeUtil } = core.utils;

function getCurrentPagePosition(editorAPI) {
  const page = editorAPI.pages.getFocusedPage();
  return editorAPI.components.layout.getRelativeToScreen(page);
}

function createDraggedItemSection(editorAPI, layout) {
  const pageLayout = getCurrentPagePosition(editorAPI);
  const offsetToPageTop = Math.max(pageLayout.y - layout.y, 0);

  return {
    origin: 'addPanel',
    comps: [],
    layout: _.pick(layout, ['y', 'height']),
    position: {
      top: layout.y + offsetToPageTop,
      bottom: layout.y + layout.height + offsetToPageTop,
    },
  };
}

// TODO: rename to AddPanelStripDragHandler because is not related to Section
class AddPanelSectionDragHandler extends AddPanelDragHandler {
  public draggedSection;

  init() {}
  getFixedDragPosition(e) {
    return {
      x: e.clientX,
      y: e.clientY,
    };
  }

  handleDragOutsidePanelArea(e) {
    const dragBoxStageLayout = this.getDragBoxStageLayout();

    this.handleSectionDrag(e, dragBoxStageLayout);
  }
  handleDragInPanelArea() {
    this.cancelSectionDragIfNeeded();
  }
  handleSectionDrag(e, layout) {
    if (!this.isSectionDrag) {
      this.initSectionDrag(e, layout);
      this.isSectionDrag = true;
    }

    this.draggedSection = {
      ...this.draggedSection,
      layout: {
        ...this.draggedSection.layout,
        y: layout.y,
      },
    };
    sectionDrag.on(e);
  }
  initSectionDrag(e, layout) {
    this.draggedSection = createDraggedItemSection(this.editorAPI, layout);

    let sections = sectionsReorganizeUtil.getPageSections(this.editorAPI);
    sections = sectionsReorganizeUtil.addSectionByPosition(
      sections,
      this.draggedSection,
    );

    this.editorAPI.setPageSections(sections, null);

    sectionDrag.start(this.editorAPI, {
      sections,
      evt: e,
      draggedSection: this.draggedSection,
    });
  }
  cancelSectionDragIfNeeded() {
    if (!this.isSectionDrag) {
      return;
    }

    this.isSectionDrag = false;
    this.editorAPI.history.add('sections reorganize');
    this.editorAPI.history.undo();

    this.editorAPI.clearPageSections();
  }
  clearDrag() {
    if (this.isSectionDrag) {
      this.editorAPI.clearPageSections();
    }
  }

  getDropContainer(/*dragBoxStageLayout*/) {
    return this.editorAPI.pages.getFocusedPage();
  }
  getCompPositionRelativeToContainer(
    pagesContainerAbsPosition,
    dropContainerLayout,
  ) {
    const compPosRelativeToContainer =
      AddPanelDragHandler.prototype.getCompPositionRelativeToContainer.call(
        this,
        pagesContainerAbsPosition,
        dropContainerLayout,
      );

    const deltaY =
      this.draggedSection.position.top - this.draggedSection.layout.y;
    compPosRelativeToContainer.y += deltaY;

    return compPosRelativeToContainer;
  }
}

export default AddPanelSectionDragHandler;
