'use strict'

const addPanelDataConsts = require('@/addPanelDataConsts')
const firstContactItem = require('./firstItem')
const secondContactItem = require('./secondItem')
const thirdContactItem = require('./thirdItem')
const fourthContactItem = require('./fourthItem')
const fifthContactItem = require('./fifthItem')
const sixthContactItem = require('./sixthItem')

const sectionTitle = 'add_section_label_columns_contact'

module.exports = {
    type: addPanelDataConsts.SECTIONS_TYPES.PRESET,
    hide: false,
    title: sectionTitle,
    subNavigationTitle: sectionTitle,
    presetTitle: sectionTitle,
    tooltipTitle: sectionTitle,
    automationId: 'add-panel-section-columnsContactSection',
    subNavigationHide: false,
    showSectionHeader: true,
    additionalBehaviours: {
        labelMode: addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
        infoIcon: addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
        hoverImageAction: addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
        iconEnabledForComps: {}
    },
    props: {
        image: 'addPanelData/sections/columnsContactSection_en/columnsContactSection_en.png',
        imageHover: null,
        items: [
            firstContactItem,
            secondContactItem,
            thirdContactItem,
            fourthContactItem,
            fifthContactItem,
            sixthContactItem
        ],
        compTypes: ['wysiwyg.viewer.components.StripColumnsContainer']
    },
    help: {hide: false, text: 'add_section_info_text_column_contact'}
}
