'use strict'

module.exports = {
    'header': {
        'id': 'site_header_3',
        'structure': {
            'type': 'Container',
            'components': [],
            'skin': 'wysiwyg.viewer.skins.screenwidthcontainer.DefaultScreen',
            'layout': {
                'width': 980,
                'height': 180,
                'x': 0,
                'y': 0,
                'scale': 1,
                'rotationInDegrees': 0,
                'anchors': [{
                    'distance': 0,
                    'topToTop': 180,
                    'originalValue': 180,
                    'type': 'BOTTOM_TOP',
                    'locked': true,
                    'targetComponent': 'PAGES_CONTAINER'
                }],
                'fixedPosition': false
            },
            'componentType': 'wysiwyg.viewer.components.HeaderContainer',
            'style': {
                'type': 'TopLevelStyle',
                'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                'style': {
                    'properties': {
                        'alpha-bg': '0.6',
                        'alpha-bgctr': '0',
                        'alpha-brd': '1',
                        'bg': '#FFFFFF',
                        'bgctr': '#FFFFFF',
                        'boxShadowToggleOn-shd': 'true',
                        'brd': '#2F2E2E',
                        'brwb': '0px',
                        'brwt': '0px',
                        'rd': '0',
                        'shd': '0 0 5px rgba(0, 0, 0, 0.7)'
                    },
                    'propertiesSource': {
                        'bg': 'value',
                        'bgctr': 'value',
                        'brd': 'value',
                        'brwb': 'value',
                        'brwt': 'value',
                        'rd': 'value',
                        'shd': 'value'
                    },
                    'groups': {}
                },
                'componentClassName': 'wysiwyg.viewer.components.HeaderContainer',
                'pageId': '',
                'compId': 'SITE_HEADER',
                'styleType': 'custom',
                'skin': 'wysiwyg.viewer.skins.screenwidthcontainer.DefaultScreen'
            }
        },
        'preset': {'rect': {'width': 324, 'height': 90, 'x': 0, 'y': 180}}
    },
    'footer': {
        'id': 'site_footer_3',
        'structure': {
            'type': 'Container',
            'components': [],
            'skin': 'wysiwyg.viewer.skins.screenwidthcontainer.DefaultScreen',
            'layout': {
                'width': 980,
                'height': 180,
                'x': 0,
                'y': 681,
                'scale': 1,
                'rotationInDegrees': 0,
                'anchors': [{
                    'distance': 0,
                    'topToTop': 681,
                    'originalValue': 0,
                    'type': 'BOTTOM_PARENT',
                    'locked': true,
                    'targetComponent': 'masterPage'
                }],
                'fixedPosition': false
            },
            'componentType': 'wysiwyg.viewer.components.FooterContainer',
            'style': {
                'type': 'TopLevelStyle',
                'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                'style': {
                    'properties': {
                        'alpha-bg': '0.6',
                        'alpha-bgctr': '0',
                        'alpha-brd': '1',
                        'bg': '#FFFFFF',
                        'bgctr': '#FFFFFF',
                        'boxShadowToggleOn-shd': 'true',
                        'brd': '#2F2E2E',
                        'brwb': '0px',
                        'brwt': '0px',
                        'rd': '0',
                        'shd': '0 0 5px rgba(0, 0, 0, 0.7)'
                    },
                    'propertiesSource': {
                        'bg': 'value',
                        'bgctr': 'value',
                        'brd': 'value',
                        'brwb': 'value',
                        'brwt': 'value',
                        'rd': 'value',
                        'shd': 'value'
                    },
                    'groups': {}
                },
                'componentClassName': 'wysiwyg.viewer.components.FooterContainer',
                'pageId': '',
                'compId': 'SITE_FOOTER',
                'styleType': 'custom',
                'skin': 'wysiwyg.viewer.skins.screenwidthcontainer.DefaultScreen'
            }
        },
        'preset': {'rect': {'width': 324, 'height': 90, 'x': 0, 'y': 180}}
    },
    'index': 3
}
