const LOGIN_COMP_APP_WIDGET = {
  componentType: 'platform.components.AppWidget',
  style: {
    skin: 'platform.components.skins.AppWidgetSkin',
    style: {},
  },
  type: 'Container',
  layout: {
    width: 230,
    height: 40,
    x: 685,
    y: 69,
    scale: 1,
    rotationInDegrees: 0,
    fixedPosition: false,
  },
  data: {
    type: 'AppController',
    applicationId: '14cc59bc-f0b7-15b8-e1c7-89ce41d0e0c9',
    name: 'login-bar-controller',
    controllerType: 'members-login-bar',
    id: 'login-bar-controller-id',
  },
};

const LOGIN_APP_WIDGET_COMPONENT = {
  type: 'Component',
  skin: 'wysiwyg.viewer.skins.LoginSocialBarSkin',
  componentType: 'wysiwyg.viewer.components.LoginSocialBar',
  data: {
    role: 'members_login',
    type: 'LoginSocialBar',
    language: 'en',
    loggedInMember: 'avatarAndText',
    loggedOutText: 'Log In',
    showLoggedInText: false,
    loggedInText: 'Hello',
    logOutText: 'Log Out',
  },
  props: {
    type: 'LoginSocialBarProperties',
    buttonsDirection: 'ltr',
    iconSize: 26,
    dropDownTextAlignment: 'left',
    buttonsAlignment: 'right',
    showBellIcon: true,
    arrowShape: 'line',
    arrowSize: 14,
  },
  style: {
    type: 'TopLevelStyle',
    style: {
      properties: {
        'alpha-bg': '0',
        'alpha-brd': '1',
        'alpha-brd-dd': '0.2',
        'badge-bg': '#E21C21',
        'badge-txt': 'color_11',
        bg: 'color_11',
        'bg-dd': 'color_11',
        'boxShadowToggleOn-shd': 'false',
        'boxShadowToggleOn-shd-dd': 'false',
        brd: 'color_15',
        'brd-dd': 'color_15',
        brw: '0',
        'brw-dd': '1px',
        fillcolor: 'color_18',
        fnt: 'font_8',
        'fnt-size-dd': '15px',
        rd: '0px',
        'rd-dd': '0px',
        shd: '0 0 0 transparent',
        'shd-dd': '0 0 0 transparent',
        txt: 'color_18',
        'txt-dd': 'color_15',
        txth: 'color_19',
        'txth-dd': 'color_19',
        'txt-slct-dd': 'color_19',
        avatarAndArrowStrokeWidth: '0',
        avatarCornerRadius: '100px',
      },
      propertiesSource: {},
      groups: {},
    },
    componentClassName: 'wysiwyg.viewer.components.LoginSocialBar',
    pageId: '',
    styleType: 'custom',
    skin: 'wysiwyg.viewer.skins.LoginSocialBarSkin',
  },
  layout: {
    width: 120,
    height: 40,
    scale: 1,
    rotationInDegrees: 0,
    fixedPosition: false,
    x: 0,
    y: 0,
  },
  connections: {
    type: 'ConnectionList',
    items: [
      {
        type: 'ConnectionItem',
        controllerId: 'login-bar-controller-id',
        isPrimary: true,
        config: '{}',
        role: 'members-login-bar',
      },
    ],
  },
  activeModes: {},
};

const MEMBERS_MENU_APP_WIDGET_COMPONENT = {
  type: 'Component',
  layout: {
    width: 250,
    height: 94,
    x: 20,
    y: 275,
    scale: 1,
    rotationInDegrees: 0,
    fixedPosition: false,
  },
  componentType: 'wysiwyg.common.components.verticalmenu.viewer.VerticalMenu',
  data: {
    type: 'CustomMenuDataRef',
    menuRef: '#MEMBERS_SUB_MENU',
    role: 'members_menu',
  },
  props: {
    type: 'VerticalMenuProperties',
    itemsAlignment: 'left',
    subMenuOpenSide: 'right',
    menuItemHeight: 40,
  },
  style: {
    type: 'TopLevelStyle',
    style: {
      properties: {
        SKINS_bgSubmenu: '#FFFFFF',
        SKINS_fntSubmenu: 'font_8',
        SKINS_submenuBR: '0',
        SKINS_submenuMargin: '0',
        'alpha-SKINS_bgSubmenu': '1',
        'alpha-bg': '1',
        'alpha-bgh': '1',
        'alpha-bgs': '1',
        'alpha-brd': '0.2',
        'alpha-sep': '1',
        bg: 'color_11',
        bgh: 'color_11',
        bgs: 'color_11',
        'boxShadowToggleOn-shd': 'false',
        brd: 'color_15',
        brw: '1px',
        fnt: 'font_8',
        rd: '0',
        sep: 'color_15',
        sepw: '0',
        shd: '0',
        textSpacing: '30',
        txt: 'color_15',
        txth: 'color_14',
        txts: 'color_18',
      },
      propertiesSource: {
        SKINS_bgSubmenu: 'theme',
        SKINS_fntSubmenu: 'theme',
        SKINS_submenuBR: 'value',
        SKINS_submenuMargin: 'value',
        'alpha-SKINS_bgSubmenu': 'value',
        'alpha-bg': 'value',
        'alpha-bgh': 'value',
        'alpha-bgs': 'value',
        'alpha-brd': 'value',
        'alpha-sep': 'value',
        bg: 'theme',
        bgh: 'theme',
        bgs: 'theme',
        'boxShadowToggleOn-shd': 'value',
        brd: 'theme',
        brw: 'value',
        fnt: 'theme',
        rd: 'value',
        sep: 'theme',
        sepw: 'value',
        shd: 'value',
        textSpacing: 'value',
        txt: 'theme',
        txth: 'value',
        txts: 'value',
      },
      groups: {},
    },
    componentClassName:
      'wysiwyg.common.components.verticalmenu.viewer.VerticalMenu',
    pageId: '',
    compId: 'i765dc0m',
    styleType: 'custom',
    skin: 'wysiwyg.common.components.verticalmenu.viewer.skins.VerticalMenuSolidColorSkin',
  },
  id: 'i765dc0m',
  connections: {
    type: 'ConnectionList',
    items: [
      {
        type: 'ConnectionItem',
        controllerId: 'members-menu-controller-id',
        isPrimary: true,
        config: '{}',
        role: 'members-menu',
      },
    ],
  },
  activeModes: {},
};

const MEMBERS_MENU_APP_WIDGET = {
  componentType: 'platform.components.AppWidget',
  type: 'Container',
  style: {
    skin: 'platform.components.skins.AppWidgetSkin',
    style: {},
  },
  layout: {
    x: 20,
    y: 275,
    width: 250,
    height: 94,
    scale: 1,
    rotationInDegrees: 0,
    fixedPosition: false,
  },
  data: {
    type: 'AppController',
    applicationId: '14cc59bc-f0b7-15b8-e1c7-89ce41d0e0c9',
    name: 'members-menu-controller',
    controllerType: 'members-menu',
    id: 'members-menu-controller-id',
  },
};

const MEMBERS_MENU_COMP_APP_WIDGET_DEF = {
  ...MEMBERS_MENU_APP_WIDGET,
  components: [MEMBERS_MENU_APP_WIDGET_COMPONENT],
};

const LOGIN_COMP_APP_WIDGET_DEF = {
  ...LOGIN_COMP_APP_WIDGET,
  components: [LOGIN_APP_WIDGET_COMPONENT],
};

export const LOGIN_BAR_ADD_PANEL_APP_WIDGET = {
  id: 'Login_Button_1',
  structure: LOGIN_COMP_APP_WIDGET_DEF,
  preset: { rect: { width: 324, height: 86, x: 0, y: 0 } },
};

export const MEMBERS_MENU_ADD_PANEL_APP_WIDGET = {
  id: 'Member_Menu_1',
  structure: MEMBERS_MENU_COMP_APP_WIDGET_DEF,
  preset: { rect: { width: 324, height: 166, x: 0, y: 0 } },
};
