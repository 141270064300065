// @ts-nocheck
import createReactClass from 'create-react-class';
import * as core from '@/core';
import React from 'react';
import PopupTemplate from './popupTemplate';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'createNewTextBoxPanel',
  mixins: [core.mixins.editorAPIMixin],
  render() {
    return (
      <PopupTemplate
        panelName={this.props.panelName}
        h1text="text_box_too_much_text_error_message_title"
        symbolName="createNewTextBox"
        bodyText="text_box_too_much_text_error_message_text"
        hideDontShowCheckbox={true}
        isSingleInstance={this.props.isSingleInstance}
      />
    );
  },
});
