/*eslint max-lines: [2, { "max": 1221, "skipComments": true, "skipBlankLines": true}]*/

import { translate } from '@/i18n';
export default {
  type: 'Container',
  components: [
    {
      type: 'Container',
      components: [
        {
          type: 'Component',
          skin: 'skins.viewer.VectorImageSkin',
          layout: {
            width: 20,
            height: 20,
            x: 5,
            y: 4,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          componentType: 'wysiwyg.viewer.components.VectorImage',
          metaData: {
            pageId: 'ngep2',
          },
          parent: 'comp-kgt5ytud',
          data: {
            type: 'VectorImage',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
              pageId: 'ngep2',
            },
            svgId:
              '8463f60718194af748c49dddbe45b668_svgshape.v1.HollowCircle.svg',
          },
          props: {
            type: 'VectorImageProperties',
            metaData: {
              schemaVersion: '1.0',
              pageId: 'ngep2',
            },
            displayMode: 'stretch',
            flip: 'none',
          },
          design: {
            type: 'VectorImageDesignData',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
              pageId: 'ngep2',
            },
            overrideColors: {
              color1: '#FFDE5F',
            },
            shapeStyle: {
              opacity: 1,
              strokeWidth: 1,
              stroke: '#423737',
              strokeOpacity: 1,
              enableStroke: false,
            },
          },
          transitions: {
            type: 'TransitionData',
            'timing-function': 'ease-in-out',
            duration: 0.4,
            delay: 0,
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
              pageId: 'ngep2',
            },
          },
          scopedTransformations: {
            'variants-kgt5ytuc': {
              type: 'TransformData',
              rotate: 0,
              metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false,
                pageId: 'ngep2',
              },
              translate: {
                x: {
                  type: 'px',
                  value: 100,
                },
                y: {
                  type: 'px',
                  value: 0,
                },
              },
            },
          },
          style: {
            type: 'ComponentStyle',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
              pageId: 'ngep2',
            },
            style: {
              properties: {},
              propertiesSource: {},
              groups: {},
            },
            componentClassName: 'wysiwyg.viewer.components.VectorImage',
            pageId: '',
            compId: 'i8yjldkb_0',
            styleType: 'custom',
            skin: 'skins.viewer.VectorImageSkin',
          },
          mobileStructure: {
            layout: {
              width: 20,
              height: 20,
              x: 6,
              y: 3,
              scale: 1,
              rotationInDegrees: 0,
              fixedPosition: false,
            },
            metaData: {
              originalCompId: 'comp-kgt5ytue1',
              author: 'studio',
            },
          },
        },
      ],
      skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
      layout: {
        width: 132,
        height: 28,
        x: 20,
        y: 48,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'mobile.core.components.Container',
      metaData: {
        pageId: 'ngep2',
      },
      parent: 'comp-kgt5yttz',
      scopedTransformations: {
        'variants-kgt5ytuc': {
          type: 'TransformData',
          rotate: 0,
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'ngep2',
          },
        },
      },
      style: {
        type: 'ComponentStyle',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'ngep2',
        },
        style: {
          properties: {
            'alpha-bg': '1',
            'alpha-brd': '1',
            bg: '#000000',
            'boxShadowToggleOn-shd': 'false',
            brd: '#423737',
            brw: '0',
            rd: '53px 53px 53px 53px',
            shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
          },
          propertiesSource: {
            'alpha-bg': 'value',
            'alpha-brd': 'value',
            bg: 'value',
            'boxShadowToggleOn-shd': 'value',
            brd: 'value',
            brw: 'value',
            rd: 'value',
            shd: 'value',
          },
          groups: {},
        },
        componentClassName: 'mobile.core.components.Container',
        pageId: '',
        compId: '',
        styleType: 'custom',
        skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
      },
      mobileStructure: {
        layout: {
          width: 93,
          height: 27,
          x: 14,
          y: 34,
          scale: 1,
          rotationInDegrees: 0,
          fixedPosition: false,
        },
        metaData: {
          originalCompId: 'comp-kgt5ytud',
          author: 'studio',
        },
      },
    },
    {
      type: 'Component',
      skin: 'skins.viewer.VectorImageSkin',
      layout: {
        width: 48,
        height: 73,
        x: 122,
        y: 83,
        scale: 1,
        rotationInDegrees: 70.64843156912411,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.VectorImage',
      metaData: {
        pageId: 'ngep2',
      },
      parent: 'comp-kgt5yttz',
      data: {
        type: 'VectorImage',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'ngep2',
        },
        alt: '',
        svgId: '11062b_931845114d0f4a96aa1f50385d27ae7f.svg',
        title: 'Hand',
      },
      props: {
        type: 'VectorImageProperties',
        metaData: {
          schemaVersion: '1.0',
          autoGenerated: false,
          pageId: 'ngep2',
        },
        displayMode: 'fit',
        flip: 'none',
      },
      design: {
        type: 'VectorImageDesignData',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'ngep2',
        },
        overrideColors: {
          color1: '#FFFFFF',
          color2: '#FFFFFF',
        },
        shapeStyle: {
          opacity: 1,
          strokeWidth: 4,
          stroke: '#5E97FF',
          strokeOpacity: 1,
          enableStroke: false,
        },
      },
      transitions: {
        type: 'TransitionData',
        'timing-function': 'ease-in-out',
        duration: 0.4,
        delay: 0,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'ngep2',
        },
      },
      transformations: {
        type: 'TransformData',
        hidden: true,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'ngep2',
        },
      },
      scopedTransformations: {
        'variants-kgt5ytuc': {
          type: 'TransformData',
          hidden: false,
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'ngep2',
          },
          rotate: 0,
        },
      },
      style: {
        type: 'ComponentStyle',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'ngep2',
        },
        style: {
          properties: {},
          propertiesSource: {},
          groups: {},
        },
        componentClassName: 'wysiwyg.viewer.components.VectorImage',
        pageId: '',
        compId: '',
        styleType: 'custom',
        skin: 'skins.viewer.VectorImageSkin',
      },
      mobileStructure: {
        layout: {
          width: 50,
          height: 76,
          x: 86,
          y: 92,
          scale: 1,
          rotationInDegrees: 70.64843156912411,
          fixedPosition: false,
        },
        metaData: {
          originalCompId: 'comp-kgt5ytug',
          author: 'studio',
        },
      },
    },
    {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
      layout: {
        width: 202,
        height: 46,
        x: 172,
        y: 35,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.WRichText',
      metaData: {
        pageId: 'ngep2',
      },
      parent: 'comp-kgt5yttz',
      data: {
        type: 'StyledText',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'ngep2',
        },
        text: `<h2 class="font_2" style="font-size:40px"><span style="color:#000000"><span style="font-size:40px"><span style="font-family:palatino linotype,serif">${translate(
          'interactions_preset_say_hello_text_1',
        )}</span></span></span></h2>`,
        stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
        linkList: [],
      },
      props: {
        type: 'WRichTextProperties',
        metaData: {
          schemaVersion: '1.0',
          autoGenerated: false,
          pageId: 'ngep2',
        },
        brightness: 1,
        packed: true,
        verticalText: false,
      },
      style: 'txtNew',
      mobileStructure: {
        layout: {
          width: 132,
          height: 36,
          x: 121,
          y: 25,
          scale: 0.9050505050505051,
          rotationInDegrees: 0,
          fixedPosition: false,
        },
        props: {
          type: 'WRichTextProperties',
          metaData: {
            schemaVersion: '1.0',
            autoGenerated: false,
            pageId: 'ngep2',
          },
          brightness: 1,
          packed: true,
          verticalText: false,
        },
        metaData: {
          originalCompId: 'comp-kgt5ytui',
          author: 'studio',
        },
      },
    },
    {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
      layout: {
        width: 78,
        height: 46,
        x: 24,
        y: 94,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.WRichText',
      metaData: {
        pageId: 'ngep2',
      },
      parent: 'comp-kgt5yttz',
      data: {
        type: 'StyledText',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'ngep2',
        },
        text: `<h2 class="font_2" style="font-size:40px"><span style="color:#000000"><span style="font-size:40px"><span style="font-family:palatino linotype,serif">${translate(
          'interactions_preset_say_hello_text_2',
        )}</span></span></span></h2>`,
        stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
        linkList: [],
      },
      props: {
        type: 'WRichTextProperties',
        metaData: {
          schemaVersion: '1.0',
          autoGenerated: false,
          pageId: 'ngep2',
        },
        brightness: 1,
        packed: true,
        verticalText: false,
      },
      style: 'txtNew',
      mobileStructure: {
        layout: {
          width: 43,
          height: 36,
          x: 16,
          y: 78,
          scale: 0.9050505050505051,
          rotationInDegrees: 0,
          fixedPosition: false,
        },
        props: {
          type: 'WRichTextProperties',
          metaData: {
            schemaVersion: '1.0',
            autoGenerated: false,
            pageId: 'ngep2',
          },
          brightness: 1,
          packed: true,
          verticalText: false,
        },
        metaData: {
          originalCompId: 'comp-kgt5ytui3',
          author: 'studio',
        },
      },
    },
    {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
      layout: {
        width: 106,
        height: 46,
        x: 98,
        y: 94,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.WRichText',
      metaData: {
        pageId: 'ngep2',
      },
      parent: 'comp-kgt5yttz',
      data: {
        type: 'StyledText',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'ngep2',
        },
        text: `<h2 class="font_2" style="font-size:40px"><span style="color:#000000"><span style="font-size:40px"><span style="font-family:palatino linotype,serif">${translate(
          'interactions_preset_say_hello_text_3',
        )}</span></span></span></h2>`,
        stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
        linkList: [],
      },
      props: {
        type: 'WRichTextProperties',
        metaData: {
          schemaVersion: '1.0',
          autoGenerated: false,
          pageId: 'ngep2',
        },
        brightness: 1,
        packed: true,
        verticalText: false,
      },
      transitions: {
        type: 'TransitionData',
        'timing-function': 'ease-out',
        duration: 0.2,
        delay: 0,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'ngep2',
        },
      },
      scopedTransformations: {
        'variants-kgt5ytuc': {
          type: 'TransformData',
          hidden: true,
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'ngep2',
          },
        },
      },
      style: 'txtNew',
      mobileStructure: {
        layout: {
          width: 69,
          height: 36,
          x: 69,
          y: 78,
          scale: 0.9050505050505051,
          rotationInDegrees: 0,
          fixedPosition: false,
        },
        props: {
          type: 'WRichTextProperties',
          metaData: {
            schemaVersion: '1.0',
            autoGenerated: false,
            pageId: 'ngep2',
          },
          brightness: 1,
          packed: true,
          verticalText: false,
        },
        metaData: {
          originalCompId: 'comp-kgt5ytuj2',
          author: 'studio',
        },
      },
    },
    {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
      layout: {
        width: 180,
        height: 46,
        x: 204,
        y: 94,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.WRichText',
      metaData: {
        pageId: 'ngep2',
      },
      parent: 'comp-kgt5yttz',
      data: {
        type: 'StyledText',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'ngep2',
        },
        text: `<h2 class="font_2" style="font-size:40px"><span style="color:#000000"><span style="font-size:40px"><span style="font-family:palatino linotype,serif">${translate(
          'interactions_preset_say_hello_text_4',
        )}</span></span></span></h2>`,
        stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
        linkList: [],
      },
      props: {
        type: 'WRichTextProperties',
        metaData: {
          schemaVersion: '1.0',
          autoGenerated: false,
          pageId: 'ngep2',
        },
        brightness: 1,
        packed: true,
        verticalText: false,
      },
      style: 'txtNew',
      mobileStructure: {
        layout: {
          width: 120,
          height: 36,
          x: 144,
          y: 78,
          scale: 0.9050505050505051,
          rotationInDegrees: 0,
          fixedPosition: false,
        },
        props: {
          type: 'WRichTextProperties',
          metaData: {
            schemaVersion: '1.0',
            autoGenerated: false,
            pageId: 'ngep2',
          },
          brightness: 1,
          packed: true,
          verticalText: false,
        },
        metaData: {
          originalCompId: 'comp-kgt5ytul',
          author: 'studio',
        },
      },
    },
    {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
      layout: {
        width: 90,
        height: 46,
        x: 24,
        y: 159,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.WRichText',
      metaData: {
        pageId: 'ngep2',
      },
      parent: 'comp-kgt5yttz',
      data: {
        type: 'StyledText',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'ngep2',
        },
        text: `<h2 class="font_2" style="font-size:40px"><span style="color:#000000"><span style="font-size:40px"><span style="font-family:palatino linotype,serif">${translate(
          'interactions_preset_say_hello_text_5',
        )}</span></span></span></h2>`,
        stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
        linkList: [],
      },
      props: {
        type: 'WRichTextProperties',
        metaData: {
          schemaVersion: '1.0',
          autoGenerated: false,
          pageId: 'ngep2',
        },
        brightness: 1,
        packed: true,
        verticalText: false,
      },
      style: 'txtNew',
      mobileStructure: {
        layout: {
          width: 60,
          height: 36,
          x: 16,
          y: 128,
          scale: 0.9050505050505051,
          rotationInDegrees: 0,
          fixedPosition: false,
        },
        props: {
          type: 'WRichTextProperties',
          metaData: {
            schemaVersion: '1.0',
            autoGenerated: false,
            pageId: 'ngep2',
          },
          brightness: 1,
          packed: true,
          verticalText: false,
        },
        metaData: {
          originalCompId: 'comp-kgt5ytul3',
          author: 'studio',
        },
      },
    },
    {
      type: 'Container',
      components: [
        {
          type: 'Component',
          skin: 'skins.viewer.VectorImageSkin',
          layout: {
            width: 20,
            height: 20,
            x: 226,
            y: 3,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          componentType: 'wysiwyg.viewer.components.VectorImage',
          metaData: {
            pageId: 'ngep2',
          },
          parent: 'comp-kgt5ytum2',
          data: {
            type: 'VectorImage',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
              pageId: 'ngep2',
            },
            svgId:
              '8463f60718194af748c49dddbe45b668_svgshape.v1.HollowCircle.svg',
          },
          props: {
            type: 'VectorImageProperties',
            metaData: {
              schemaVersion: '1.0',
              pageId: 'ngep2',
            },
            displayMode: 'stretch',
            flip: 'none',
          },
          design: {
            type: 'VectorImageDesignData',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
              pageId: 'ngep2',
            },
            overrideColors: {
              color1: '#FFDE5F',
            },
            shapeStyle: {
              opacity: 1,
              strokeWidth: 1,
              stroke: '#423737',
              strokeOpacity: 1,
              enableStroke: false,
            },
          },
          transitions: {
            type: 'TransitionData',
            'timing-function': 'ease-in-out',
            duration: 0.4,
            delay: 0,
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
              pageId: 'ngep2',
            },
          },
          scopedTransformations: {
            'variants-kgt5ytuc': {
              type: 'TransformData',
              rotate: 0,
              metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false,
                pageId: 'ngep2',
              },
              translate: {
                x: {
                  type: 'px',
                  value: -216,
                },
                y: {
                  type: 'px',
                  value: 0,
                },
              },
            },
          },
          style: {
            type: 'ComponentStyle',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
              pageId: 'ngep2',
            },
            style: {
              properties: {},
              propertiesSource: {},
              groups: {},
            },
            componentClassName: 'wysiwyg.viewer.components.VectorImage',
            pageId: '',
            compId: 'i8yjldkb_0',
            styleType: 'custom',
            skin: 'skins.viewer.VectorImageSkin',
          },
          mobileStructure: {
            layout: {
              width: 20,
              height: 20,
              x: 152,
              y: 3,
              scale: 1,
              rotationInDegrees: 0,
              fixedPosition: false,
            },
            metaData: {
              originalCompId: 'comp-kgt5ytun4',
              author: 'studio',
            },
          },
        },
      ],
      skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
      layout: {
        width: 250,
        height: 26,
        x: 126,
        y: 172,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'mobile.core.components.Container',
      metaData: {
        pageId: 'ngep2',
      },
      parent: 'comp-kgt5yttz',
      scopedTransformations: {
        'variants-kgt5ytuc': {
          type: 'TransformData',
          rotate: 0,
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'ngep2',
          },
        },
      },
      style: {
        type: 'ComponentStyle',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'ngep2',
        },
        style: {
          properties: {
            'alpha-bg': '1',
            'alpha-brd': '1',
            bg: '#000000',
            'boxShadowToggleOn-shd': 'false',
            brd: '#423737',
            brw: '0',
            rd: '53px 53px 53px 53px',
            shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
          },
          propertiesSource: {
            'alpha-bg': 'value',
            'alpha-brd': 'value',
            bg: 'value',
            'boxShadowToggleOn-shd': 'value',
            brd: 'value',
            brw: 'value',
            rd: 'value',
            shd: 'value',
          },
          groups: {},
        },
        componentClassName: 'mobile.core.components.Container',
        pageId: '',
        compId: '',
        styleType: 'custom',
        skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
      },
      mobileStructure: {
        layout: {
          width: 176,
          height: 27,
          x: 88,
          y: 134,
          scale: 1,
          rotationInDegrees: 0,
          fixedPosition: false,
        },
        metaData: {
          originalCompId: 'comp-kgt5ytum2',
          author: 'studio',
        },
      },
    },
    {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
      layout: {
        width: 346,
        height: 116,
        x: 24,
        y: 219,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.WRichText',
      metaData: {
        pageId: 'ngep2',
      },
      parent: 'comp-kgt5yttz',
      data: {
        type: 'StyledText',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'ngep2',
        },
        text: `<h2 class="font_2" style="font-size:40px"><span style="color:#000000"><span style="font-size:40px"><span style="font-family:palatino linotype,serif">${translate(
          'interactions_preset_say_hello_text_6',
        )}</span></span></span></h2>`,
        stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
        linkList: [],
      },
      props: {
        type: 'WRichTextProperties',
        metaData: {
          schemaVersion: '1.0',
          autoGenerated: false,
          pageId: 'ngep2',
        },
        brightness: 1,
        packed: false,
        minHeight: 116,
        verticalText: false,
      },
      style: 'txtNew',
      mobileStructure: {
        layout: {
          width: 214,
          height: 72,
          x: 16,
          y: 175,
          scale: 0.9050505050505051,
          rotationInDegrees: 0,
          fixedPosition: false,
        },
        props: {
          type: 'WRichTextProperties',
          metaData: {
            schemaVersion: '1.0',
            autoGenerated: false,
            pageId: 'ngep2',
          },
          brightness: 1,
          packed: true,
          verticalText: false,
        },
        metaData: {
          originalCompId: 'comp-kgt5ytup4',
          author: 'studio',
        },
      },
    },
    {
      type: 'Component',
      skin: 'skins.viewer.VectorImageSkin',
      layout: {
        width: 64,
        height: 52,
        x: 278,
        y: 293,
        scale: 1,
        rotationInDegrees: 12.236611456419723,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.VectorImage',
      metaData: {
        pageId: 'ngep2',
      },
      parent: 'comp-kgt5yttz',
      data: {
        type: 'VectorImage',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'ngep2',
        },
        svgId: 'ded7bd8c3a8146cc8f0abf1c0123a3d2.svg',
      },
      props: {
        type: 'VectorImageProperties',
        metaData: {
          schemaVersion: '1.0',
          pageId: 'ngep2',
        },
        displayMode: 'fit',
        flip: 'none',
      },
      design: {
        type: 'VectorImageDesignData',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'ngep2',
        },
        overrideColors: {
          color1: '#FF5E3D',
        },
        shapeStyle: {
          opacity: 1,
          strokeWidth: 4,
          stroke: '#5E97FF',
          strokeOpacity: 1,
          enableStroke: false,
        },
      },
      transitions: {
        type: 'TransitionData',
        'timing-function': 'ease-in-out',
        duration: 0.4,
        delay: 0,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'ngep2',
        },
      },
      transformations: {
        type: 'TransformData',
        origin: {
          x: {
            value: 50,
            type: 'percentage',
          },
          y: {
            value: 50,
            type: 'percentage',
          },
        },
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'ngep2',
        },
      },
      scopedTransformations: {
        'variants-kgt5ytuc': {
          type: 'TransformData',
          rotate: -45,
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'ngep2',
          },
        },
      },
      style: {
        type: 'ComponentStyle',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'ngep2',
        },
        style: {
          properties: {},
          propertiesSource: {},
          groups: {},
        },
        componentClassName: 'wysiwyg.viewer.components.VectorImage',
        styleType: 'custom',
        skin: 'skins.viewer.VectorImageSkin',
      },
      mobileStructure: {
        layout: {
          width: 60,
          height: 49,
          x: 188,
          y: 228,
          scale: 1,
          rotationInDegrees: 12.236611456419723,
          fixedPosition: false,
        },
        metaData: {
          originalCompId: 'comp-kgt5ytuq1',
          author: 'studio',
        },
      },
    },
  ],
  skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
  layout: {
    width: 396,
    height: 365,
    x: 290,
    y: 84,
    scale: 1,
    rotationInDegrees: 0,
    fixedPosition: false,
  },
  componentType: 'mobile.core.components.Container',
  metaData: {
    pageId: 'ngep2',
  },
  parent: 'ngep2',
  variants: {
    'variants-kgt5ytuc': {
      componentId: 'comp-kgt5yttz',
      type: 'Hover',
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        pageId: 'ngep2',
      },
    },
  },
  scopedTransformations: {
    'variants-kgt5ytuc': {
      type: 'TransformData',
      rotate: 0,
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        pageId: 'ngep2',
      },
    },
  },
  style: {
    type: 'ComponentStyle',
    metaData: {
      isPreset: false,
      schemaVersion: '1.0',
      isHidden: false,
      pageId: 'ngep2',
    },
    style: {
      properties: {
        'alpha-bg': '0',
        'alpha-brd': '1',
        bg: '#C8EEF5',
        'boxShadowToggleOn-shd': 'false',
        brd: '#423737',
        brw: '0',
        rd: '0px',
        shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
      },
      propertiesSource: {
        'alpha-bg': 'value',
        'alpha-brd': 'value',
        bg: 'value',
        'boxShadowToggleOn-shd': 'value',
        brd: 'value',
        brw: 'value',
        rd: 'value',
        shd: 'value',
      },
      groups: {},
    },
    componentClassName: 'mobile.core.components.Container',
    pageId: '',
    compId: '',
    styleType: 'custom',
    skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
  },
  mobileStructure: {
    layout: {
      width: 280,
      height: 319,
      x: 20,
      y: 11,
      scale: 1,
      rotationInDegrees: 0,
      fixedPosition: false,
    },
    metaData: {
      originalCompId: 'comp-kgt5yttz',
      author: 'studio',
    },
  },
  activeModes: {},
};
