export const sortAccordingToOrder = (orderedExtensionsIds: string[]) => {
  const slotExtensionsOrder = (orderedExtensionsIds || []).reduce(
    (orderMap, extensionId, index) => {
      return {
        ...orderMap,
        [extensionId]: index,
      };
    },
    {} as { [extensionId: string]: number },
  );

  return (extensionsIds: string[]): string[] => {
    const orderedResult = [...extensionsIds];

    orderedResult.sort((a, b) => {
      if (a in slotExtensionsOrder) {
        return b in slotExtensionsOrder
          ? slotExtensionsOrder[a] - slotExtensionsOrder[b]
          : -1;
      }

      if (b in slotExtensionsOrder) {
        return a in slotExtensionsOrder
          ? slotExtensionsOrder[a] - slotExtensionsOrder[b]
          : 1;
      }

      return 0;
    });

    return orderedResult;
  };
};
