'use strict'
const i18n = require('@/i18n')
const addPanelDataConsts = require('@/addPanelDataConsts')

module.exports = {
    'type': addPanelDataConsts.SECTIONS_TYPES.PRESET,
    'hide': false,
    'title': 'add_section_label_3dgalleries',
    'showSectionHeader': true,
    'additionalBehaviours': {
        'labelMode': addPanelDataConsts.LABEL_BEHAVIOUR.HOVER,
        'infoIcon': addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
        'hoverImageAction': addPanelDataConsts.HOVER_IMAGE_ACTIONS.VIDEO,
        'iconEnabledForComps': {}
    },
    'props': {
        'image': 'addPanelData/sections/3DGalleriesSection_en/3DGalleriesSection_en.png',
        'imageHover': null,
        'items': [{
            'id': 'Gallery_3D_Galleries_1',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.viewer.skins.TPA3DCarouselSkin',
                'layout': {
                    'width': 960,
                    'height': 316,
                    'x': 8,
                    'y': 54,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.viewer.components.tpapps.TPA3DCarousel',
                'data': {
                    'type': 'ImageList',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'items': [{
                        'type': 'Image',
                        'metaData': {'isPreset': false, 'schemaVersion': '2.0', 'isHidden': false},
                        'title': i18n.translate('add_default_text1_image1'),
                        'uri': '1359d0df7c934f6e94a34a29ff4f599d.jpg',
                        'description': i18n.translate('add_default_text1_gallerydescription'),
                        'width': 1920,
                        'height': 1280,
                        'alt': ''
                    }, {
                        'type': 'Image',
                        'metaData': {'isPreset': false, 'schemaVersion': '2.0', 'isHidden': false},
                        'title': i18n.translate('add_default_text1_image2'),
                        'uri': 'c3adb8201ae64fa1bef80353d419840a.jpg',
                        'description': i18n.translate('add_default_text1_gallerydescription'),
                        'width': 1920,
                        'height': 1922,
                        'alt': ''
                    }, {
                        'type': 'Image',
                        'metaData': {'isPreset': false, 'schemaVersion': '2.0', 'isHidden': false},
                        'title': i18n.translate('add_default_text1_image3'),
                        'uri': '66fd048d38ed448aa6e38973a330b13a.jpg',
                        'description': i18n.translate('add_default_text1_gallerydescription'),
                        'width': 1920,
                        'height': 1280,
                        'alt': ''
                    }, {
                        'type': 'Image',
                        'metaData': {'isPreset': false, 'schemaVersion': '2.0', 'isHidden': false},
                        'title': i18n.translate('add_default_text1_image4'),
                        'uri': '3cd1de924697419088c1e033bb3384ef.jpg',
                        'description': i18n.translate('add_default_text1_gallerydescription'),
                        'width': 1920,
                        'height': 1280,
                        'alt': ''
                    }, {
                        'type': 'Image',
                        'metaData': {'isPreset': false, 'schemaVersion': '2.0', 'isHidden': false},
                        'title': i18n.translate('add_default_text1_image5'),
                        'uri': '57f4afdda0984f34bb43cec022bda1ec.jpg',
                        'description': i18n.translate('add_default_text1_gallerydescription'),
                        'width': 1920,
                        'height': 1280,
                        'alt': ''
                    }, {
                        'type': 'Image',
                        'metaData': {'isPreset': false, 'schemaVersion': '2.0', 'isHidden': false},
                        'title': i18n.translate('add_default_text1_image6'),
                        'uri': '99ec98fdb81945c29c25a3ad6c5606b1.jpg',
                        'description': i18n.translate('add_default_text1_gallerydescription'),
                        'width': 1920,
                        'height': 1280,
                        'alt': ''
                    }, {
                        'type': 'Image',
                        'metaData': {'isPreset': false, 'schemaVersion': '2.0', 'isHidden': false},
                        'title': i18n.translate('add_default_text1_image7'),
                        'uri': 'fc2328a60c4c4a1a87a223e1831bf13d.jpg',
                        'description': i18n.translate('add_default_text1_gallerydescription'),
                        'width': 1920,
                        'height': 1280,
                        'alt': ''
                    }, {
                        'type': 'Image',
                        'metaData': {'isPreset': false, 'schemaVersion': '2.0', 'isHidden': false},
                        'title': i18n.translate('add_default_text1_image8'),
                        'uri': '5a47467975164707be86bfeac844e3a2.jpg',
                        'description': i18n.translate('add_default_text1_gallerydescription'),
                        'width': 1920,
                        'height': 1920,
                        'alt': ''
                    }, {
                        'type': 'Image',
                        'metaData': {'isPreset': false, 'schemaVersion': '2.0', 'isHidden': false},
                        'title': i18n.translate('add_default_text1_image9'),
                        'uri': '267b10ce1c64449ca2fd329b226b540f.jpg',
                        'description': i18n.translate('add_default_text1_gallerydescription'),
                        'width': 1920,
                        'height': 1280,
                        'alt': ''
                    }]
                },
                'props': {
                    'type': 'SlideShowGalleryProperties',
                    'metaData': {'schemaVersion': '1.0'},
                    'expandEnabled': true,
                    'galleryImageOnClickAction': 'disabled',
                    'goToLinkText': 'Go to link',
                    'imageMode': 'clipImage',
                    'transition': 'swipeHorizontal',
                    'autoplayInterval': 1,
                    'autoplay': true,
                    'transDuration': 1,
                    'bidirectional': true,
                    'reverse': false,
                    'showAutoplay': true,
                    'showNavigation': true,
                    'showCounter': true,
                    'showExpand': false,
                    'showSocial': false
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'id': 'tfc1',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {
                            'alpha-color1': '1',
                            'alpha-color2': '1',
                            'alpha-color3': '1',
                            'alpha-color4': '1',
                            'alpha-color5': '1',
                            'color1': '#2F2E2E',
                            'color2': '#2F2E2E',
                            'color3': '#2F2E2E',
                            'color4': '#2F2E2E',
                            'color5': '#2F2E2E',
                            'version': '1'
                        },
                        'propertiesSource': {
                            'color1': 'value',
                            'color2': 'value',
                            'color3': 'value',
                            'color4': 'value',
                            'color5': 'value',
                            'version': 'value'
                        }
                    },
                    'componentClassName': '',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'system',
                    'skin': 'wysiwyg.viewer.skins.TPA3DCarouselSkin'
                },
                'id': 'i5hylqvg'
            },
            'preset': {
                'rect': {'width': 324, 'height': 140, 'x': 0, 'y': 0},
                'label': 'add_preset_Galleries_3D_Galleries_1',
                'tags': null
            }
        }, {
            'id': 'Gallery_3D_Galleries_2',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.viewer.skins.TPA3DGallerySkin',
                'layout': {
                    'width': 666,
                    'height': 528,
                    'x': -9,
                    'y': 580,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'wysiwyg.viewer.components.tpapps.TPA3DGallery',
                'data': {
                    'type': 'ImageList',
                    'metaData': {'isPreset': true, 'schemaVersion': '1.0', 'isHidden': false},
                    'items': [{
                        'type': 'Image',
                        'metaData': {'isPreset': false, 'schemaVersion': '2.0', 'isHidden': false},
                        'title': i18n.translate('add_default_text1_image1'),
                        'uri': '1359d0df7c934f6e94a34a29ff4f599d.jpg',
                        'description': i18n.translate('add_default_text1_gallerydescription'),
                        'width': 1920,
                        'height': 1280,
                        'alt': ''
                    }, {
                        'type': 'Image',
                        'metaData': {'isPreset': false, 'schemaVersion': '2.0', 'isHidden': false},
                        'title': i18n.translate('add_default_text1_image2'),
                        'uri': 'c3adb8201ae64fa1bef80353d419840a.jpg',
                        'description': i18n.translate('add_default_text1_gallerydescription'),
                        'width': 1920,
                        'height': 1922,
                        'alt': ''
                    }, {
                        'type': 'Image',
                        'metaData': {'isPreset': false, 'schemaVersion': '2.0', 'isHidden': false},
                        'title': i18n.translate('add_default_text1_image3'),
                        'uri': '66fd048d38ed448aa6e38973a330b13a.jpg',
                        'description': i18n.translate('add_default_text1_gallerydescription'),
                        'width': 1920,
                        'height': 1280,
                        'alt': ''
                    }, {
                        'type': 'Image',
                        'metaData': {'isPreset': false, 'schemaVersion': '2.0', 'isHidden': false},
                        'title': i18n.translate('add_default_text1_image4'),
                        'uri': '3cd1de924697419088c1e033bb3384ef.jpg',
                        'description': i18n.translate('add_default_text1_gallerydescription'),
                        'width': 1920,
                        'height': 1280,
                        'alt': ''
                    }, {
                        'type': 'Image',
                        'metaData': {'isPreset': false, 'schemaVersion': '2.0', 'isHidden': false},
                        'title': i18n.translate('add_default_text1_image5'),
                        'uri': '57f4afdda0984f34bb43cec022bda1ec.jpg',
                        'description': i18n.translate('add_default_text1_gallerydescription'),
                        'width': 1920,
                        'height': 1280,
                        'alt': ''
                    }, {
                        'type': 'Image',
                        'metaData': {'isPreset': false, 'schemaVersion': '2.0', 'isHidden': false},
                        'title': i18n.translate('add_default_text1_image6'),
                        'uri': '99ec98fdb81945c29c25a3ad6c5606b1.jpg',
                        'description': i18n.translate('add_default_text1_gallerydescription'),
                        'width': 1920,
                        'height': 1280,
                        'alt': ''
                    }, {
                        'type': 'Image',
                        'metaData': {'isPreset': false, 'schemaVersion': '2.0', 'isHidden': false},
                        'title': i18n.translate('add_default_text1_image7'),
                        'uri': 'fc2328a60c4c4a1a87a223e1831bf13d.jpg',
                        'description': i18n.translate('add_default_text1_gallerydescription'),
                        'width': 1920,
                        'height': 1280,
                        'alt': ''
                    }, {
                        'type': 'Image',
                        'metaData': {'isPreset': false, 'schemaVersion': '2.0', 'isHidden': false},
                        'title': i18n.translate('add_default_text1_image8'),
                        'uri': '5a47467975164707be86bfeac844e3a2.jpg',
                        'description': i18n.translate('add_default_text1_gallerydescription'),
                        'width': 1920,
                        'height': 1920,
                        'alt': ''
                    }, {
                        'type': 'Image',
                        'metaData': {'isPreset': false, 'schemaVersion': '2.0', 'isHidden': false},
                        'title': i18n.translate('add_default_text1_image9'),
                        'uri': '267b10ce1c64449ca2fd329b226b540f.jpg',
                        'description': i18n.translate('add_default_text1_gallerydescription'),
                        'width': 1920,
                        'height': 1280,
                        'alt': ''
                    }, {
                        'type': 'Image',
                        'metaData': {'isPreset': false, 'schemaVersion': '2.0', 'isHidden': false},
                        'title': i18n.translate('add_default_text1_image10'),
                        'uri': '00c710ecc8c34cfd9739ecca0ce3fac0.jpg',
                        'description': i18n.translate('add_default_text1_gallerydescription'),
                        'width': 1920,
                        'height': 1280,
                        'alt': ''
                    }, {
                        'type': 'Image',
                        'metaData': {'isPreset': false, 'schemaVersion': '2.0', 'isHidden': false},
                        'title': i18n.translate('add_default_text1_image11'),
                        'uri': '0d5a8583187a44688b918f91298eba80.jpg',
                        'description': i18n.translate('add_default_text1_gallerydescription'),
                        'width': 1920,
                        'height': 1280,
                        'alt': ''
                    }, {
                        'type': 'Image',
                        'metaData': {'isPreset': false, 'schemaVersion': '2.0', 'isHidden': false},
                        'title': i18n.translate('add_default_text1_image12'),
                        'uri': 'a207194d9fc44d5db99669ca24de65a1.jpg',
                        'description': i18n.translate('add_default_text1_gallerydescription'),
                        'width': 1920,
                        'height': 1280,
                        'alt': ''
                    }, {
                        'type': 'Image',
                        'metaData': {'isPreset': false, 'schemaVersion': '2.0', 'isHidden': false},
                        'title': i18n.translate('add_default_text1_image13'),
                        'uri': 'bf34565329864215a1218bcb84781dd7.jpg',
                        'description': i18n.translate('add_default_text1_gallerydescription'),
                        'width': 1920,
                        'height': 1280,
                        'alt': ''
                    }, {
                        'type': 'Image',
                        'metaData': {'isPreset': false, 'schemaVersion': '2.0', 'isHidden': false},
                        'title': i18n.translate('add_default_text1_image14'),
                        'uri': '04aec757f12248798eb528718e20405a.jpg',
                        'description': i18n.translate('add_default_text1_gallerydescription'),
                        'width': 1920,
                        'height': 1280,
                        'alt': ''
                    }]
                },
                'props': {
                    'type': 'SlideShowGalleryProperties',
                    'metaData': {'schemaVersion': '1.0'},
                    'expandEnabled': true,
                    'galleryImageOnClickAction': 'disabled',
                    'goToLinkText': 'Go to link',
                    'imageMode': 'clipImage',
                    'transition': 'swipeHorizontal',
                    'autoplayInterval': 3,
                    'autoplay': false,
                    'transDuration': 1,
                    'bidirectional': true,
                    'reverse': false,
                    'showAutoplay': true,
                    'showNavigation': true,
                    'showCounter': true,
                    'showExpand': false,
                    'showSocial': false
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'id': 'tfg1',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {
                            'alpha-color1': '1',
                            'alpha-color2': '1',
                            'alpha-color3': '1',
                            'alpha-color4': '1',
                            'alpha-color5': '1',
                            'color1': '#2F2E2E',
                            'color2': '#2F2E2E',
                            'color3': '#2F2E2E',
                            'color4': '#2F2E2E',
                            'color5': '#2F2E2E',
                            'version': '1'
                        },
                        'propertiesSource': {
                            'color1': 'value',
                            'color2': 'value',
                            'color3': 'value',
                            'color4': 'value',
                            'color5': 'value',
                            'version': 'value'
                        }
                    },
                    'componentClassName': '',
                    'pageId': '',
                    'compId': '',
                    'styleType': 'system',
                    'skin': 'wysiwyg.viewer.skins.TPA3DGallerySkin'
                },
                'id': 'i5hylqvd_0'
            },
            'preset': {
                'rect': {'width': 162, 'height': 140, 'x': 0, 'y': 140},
                'label': 'add_preset_Galleries_3D_Galleries_2',
                'tags': null
            }
        }, {
            'id': 'Gallery_3D_Galleries_3',
            'structure': {
                'type': 'Component',
                'skin': 'wysiwyg.viewer.skins.TPAFreestyleSkin',
                'layout': {
                    'width': 785,
                    'height': 600,
                    'x': 657,
                    'y': 580,
                    'scale': 1,
                    'rotationInDegrees': 0,
                    'fixedPosition': false
                },
                'componentType': 'tpa.viewer.components.Freestyle',
                'data': {
                    'type': 'ImageList',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'items': [{
                        'type': 'Image',
                        'metaData': {'isPreset': false, 'schemaVersion': '2.0', 'isHidden': false},
                        'title': i18n.translate('add_default_text1_image1'),
                        'uri': '1359d0df7c934f6e94a34a29ff4f599d.jpg',
                        'description': i18n.translate('add_default_text1_gallerydescription'),
                        'width': 1920,
                        'height': 1280,
                        'alt': ''
                    }, {
                        'type': 'Image',
                        'metaData': {'isPreset': false, 'schemaVersion': '2.0', 'isHidden': false},
                        'title': i18n.translate('add_default_text1_image2'),
                        'uri': 'c3adb8201ae64fa1bef80353d419840a.jpg',
                        'description': i18n.translate('add_default_text1_gallerydescription'),
                        'width': 1920,
                        'height': 1922,
                        'alt': ''
                    }, {
                        'type': 'Image',
                        'metaData': {'isPreset': false, 'schemaVersion': '2.0', 'isHidden': false},
                        'title': i18n.translate('add_default_text1_image3'),
                        'uri': '66fd048d38ed448aa6e38973a330b13a.jpg',
                        'description': i18n.translate('add_default_text1_gallerydescription'),
                        'width': 1920,
                        'height': 1280,
                        'alt': ''
                    }, {
                        'type': 'Image',
                        'metaData': {'isPreset': false, 'schemaVersion': '2.0', 'isHidden': false},
                        'title': i18n.translate('add_default_text1_image4'),
                        'uri': '3cd1de924697419088c1e033bb3384ef.jpg',
                        'description': i18n.translate('add_default_text1_gallerydescription'),
                        'width': 1920,
                        'height': 1280,
                        'alt': ''
                    }, {
                        'type': 'Image',
                        'metaData': {'isPreset': false, 'schemaVersion': '2.0', 'isHidden': false},
                        'title': i18n.translate('add_default_text1_image5'),
                        'uri': '57f4afdda0984f34bb43cec022bda1ec.jpg',
                        'description': i18n.translate('add_default_text1_gallerydescription'),
                        'width': 1920,
                        'height': 1280,
                        'alt': ''
                    }, {
                        'type': 'Image',
                        'metaData': {'isPreset': false, 'schemaVersion': '2.0', 'isHidden': false},
                        'title': i18n.translate('add_default_text1_image6'),
                        'uri': '99ec98fdb81945c29c25a3ad6c5606b1.jpg',
                        'description': i18n.translate('add_default_text1_gallerydescription'),
                        'width': 1920,
                        'height': 1280,
                        'alt': ''
                    }, {
                        'type': 'Image',
                        'metaData': {'isPreset': false, 'schemaVersion': '2.0', 'isHidden': false},
                        'title': i18n.translate('add_default_text1_image7'),
                        'uri': 'fc2328a60c4c4a1a87a223e1831bf13d.jpg',
                        'description': i18n.translate('add_default_text1_gallerydescription'),
                        'width': 1920,
                        'height': 1280,
                        'alt': ''
                    }, {
                        'type': 'Image',
                        'metaData': {'isPreset': false, 'schemaVersion': '2.0', 'isHidden': false},
                        'title': i18n.translate('add_default_text1_image8'),
                        'uri': '5a47467975164707be86bfeac844e3a2.jpg',
                        'description': i18n.translate('add_default_text1_gallerydescription'),
                        'width': 1920,
                        'height': 1920,
                        'alt': ''
                    }, {
                        'type': 'Image',
                        'metaData': {'isPreset': false, 'schemaVersion': '2.0', 'isHidden': false},
                        'title': i18n.translate('add_default_text1_image9'),
                        'uri': '267b10ce1c64449ca2fd329b226b540f.jpg',
                        'description': i18n.translate('add_default_text1_gallerydescription'),
                        'width': 1920,
                        'height': 1280,
                        'alt': ''
                    }]
                },
                'props': {
                    'type': 'FreestyleProperties',
                    'metaData': {'schemaVersion': '1.0'},
                    'galleryImageOnClickAction': 'zoomMode',
                    'layoutSeed': 50,
                    'cropAndFitStage': 'fit',
                    'imageDecoration': 'none',
                    'borderWidth': 0,
                    'orientation': 'horizontal',
                    'applyRotationInLayout': true,
                    'borderColor': '#2F2E2E',
                    'alphaBorderColor': '1'
                },
                'style': {
                    'type': 'TopLevelStyle',
                    'metaData': {'isPreset': false, 'schemaVersion': '1.0', 'isHidden': false},
                    'style': {
                        'groups': {},
                        'properties': {
                            'alpha-color1': '1',
                            'alpha-color2': '1',
                            'alpha-color3': '1',
                            'alpha-color4': '1',
                            'alpha-color5': '1',
                            'color1': '#2F2E2E',
                            'color2': '#2F2E2E',
                            'color3': '#2F2E2E',
                            'color4': '#2F2E2E',
                            'color5': '#2F2E2E',
                            'version': '1'
                        },
                        'propertiesSource': {
                            'color1': 'value',
                            'color2': 'value',
                            'color3': 'value',
                            'color4': 'value',
                            'color5': 'value',
                            'version': 'value'
                        }
                    },
                    'componentClassName': 'tpa.viewer.components.Freestyle',
                    'pageId': '',
                    'compId': 'i5hylqvi',
                    'styleType': 'custom',
                    'skin': 'wysiwyg.viewer.skins.TPAFreestyleSkin'
                },
                'id': 'i5hylqvi'
            },
            'preset': {
                'rect': {'width': 162, 'height': 140, 'x': 162, 'y': 140},
                'label': 'add_preset_Galleries_3D_Galleries_3',
                'tags': null
            }
        }],
        'compTypes': ['tpa.viewer.components.Freestyle', 'wysiwyg.viewer.components.tpapps.TPA3DCarousel', 'wysiwyg.viewer.components.tpapps.TPA3DGallery']
    },
    'help': {'hide': false, 'text': 'add_section_info_text_3dgalleries'}
}
