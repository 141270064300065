// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { translate } from '@/i18n';
import settingsButton from './settingsButton';
import * as mediaManagerPanelTypes from '../../mediaManagerPanelTypes/mediaManagerPanelTypes';
import * as mediaManagerPanelConstants from '../../mediaManagerPanelConstants/mediaManagerPanelConstants';
import PresetSection from './presetSection';

const VISIBLE_ITEMS_INITIAL_COUNT =
  mediaManagerPanelConstants.RECOMMENDED_SECTION_INITIAL_VISIBLE_ITEMS_COUNT;
const VISIBLE_ITEMS_COUNT_STEP = 9;
const VISIBLE_ITEMS_MAX_COUNT =
  mediaManagerPanelConstants.RECOMMENDED_ITEMS_TO_LOAD_COUNT;

//TYPE WAS GENERATED, remove this line when reviewed
interface Props {}

export default class extends React.Component<Props> {
  static displayName = 'RecommendedMediaSection';

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/assign
  static propTypes = _.assign({}, mediaManagerPanelTypes.presetSection, {
    visibleItemsCount: PropTypes.number,
    pagingOptions: PropTypes.shape({
      initial: PropTypes.number,
      max: PropTypes.number,
      step: PropTypes.number,
    }),
    onShowMoreClick: PropTypes.func,
    onSettingsOpen: PropTypes.func,
  });

  // eslint-disable-next-line react/sort-comp
  getPagingOptions = () => {
    return this.props.pagingOptions || {};
  };

  state = {
    visibleItemsCount:
      this.props.visibleItemsCount ||
      this.getPagingOptions().initial ||
      VISIBLE_ITEMS_INITIAL_COUNT,
  };

  getSectionOptions = () => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    return _.assign(
      {
        shouldShowLoaderWhenNoItems: true,
        shouldPresetHaveBlueBackground: true,
        loaderContainerHeightInRows: 3,
      },
      this.props.options,
    );
  };

  getVisibleItemsCount = () => {
    return this.state.visibleItemsCount;
  };

  getVisibleItemsMaxCount = () => {
    return this.getPagingOptions().max || VISIBLE_ITEMS_MAX_COUNT;
  };

  getVisibleItemsStep = () => {
    return this.getPagingOptions().step || VISIBLE_ITEMS_COUNT_STEP;
  };

  isAllItemsShown = () => {
    const visibleItemsCount = this.getVisibleItemsCount();

    return (
      visibleItemsCount === this.getVisibleItemsMaxCount() ||
      visibleItemsCount === this.getTotalItemsCount()
    );
  };

  getTotalItemsCount = () => {
    return this.props?.items?.length;
  };

  getItems = () => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/slice
    return _.slice(this.props.items, 0, this.getVisibleItemsCount());
  };

  action = () => {
    if (this.isAllItemsShown()) {
      this.showAllItems();
    } else {
      this.showMoreItems();
    }
  };

  showMoreItems = () => {
    const nextVisibleItemsCount = Math.min(
      this.getVisibleItemsCount() + this.getVisibleItemsStep(),
      Math.min(this.getVisibleItemsMaxCount(), this.getTotalItemsCount()),
    );

    _.invoke(this.props, 'onShowMoreClick', nextVisibleItemsCount);

    this.setState({
      visibleItemsCount: nextVisibleItemsCount,
    });
  };

  showAllItems = () => {
    _.invoke(this.props, 'action');
  };

  getActionLabel = () => {
    return this.isAllItemsShown()
      ? translate('Media_Panel_Results_Show_All_Label_OnHover')
      : translate('Media_Panel_Results_Show_More_Label_OnHover');
  };

  getHeaderActions = () => {
    return [
      React.createElement(settingsButton, {
        onClick: this.props.onSettingsOpen,
        key: 'settings-button',
      }),
    ];
  };

  render() {
    return (
      <PresetSection
        id={this.props.id}
        title={this.props.title}
        items={this.getItems()}
        onItemClick={this.props.onItemClick}
        getItemPrice={this.props.getItemPrice}
        startItemDrag={this.props.startItemDrag}
        buyItem={this.props.buyItem}
        action={this.isAllItemsShown() ? null : () => this.action()}
        presetWidth={this.props.presetWidth}
        actionLabel={this.getActionLabel()}
        headerActions={this.getHeaderActions()}
        options={this.getSectionOptions()}
      />
    );
  }
}
