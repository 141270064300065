._deprecationPanelIllustration_57pfq_1 {
  margin: auto;
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding-top: 18px; }

._deprecationPanelTitle_57pfq_7 {
  text-align: center; }
