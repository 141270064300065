// @ts-nocheck
import _ from 'lodash';
import { waitForAddedCompRef } from '@/componentsAddUtils';
import { runInContext, dataUpdateHook } from '../../../common/utils';
import type { CompRef } from 'types/documentServices';
import type { EditorAPI } from '@/editorAPI';

function updateCompLayout(editorAPI, componentDefinition, options) {
  const pageId =
    options?.pageRef?.id || editorAPI.dsRead.pages.getFocusedPageId();
  const position = editorAPI.pasteLogic.addContext.getPastePosition(
    editorAPI,
    componentDefinition.layout,
    pageId,
  );
  _.merge(componentDefinition.layout, position);
}

function addComponent(editorAPI: EditorAPI, appData, token, options) {
  return new Promise<CompRef>(function (resolve, reject) {
    if (_.isEmpty(options.componentDefinition.componentType)) {
      reject(new Error('componentDefinition must contain componentType'));
    }

    const componentDefinition =
      editorAPI.components.buildDefaultComponentStructure(
        options.componentDefinition.componentType,
      );
    updateCompLayout(editorAPI, componentDefinition, options);
    _.merge(componentDefinition, options.componentDefinition);

    const addInner = () =>
      editorAPI.components.add(
        options.pageRef,
        componentDefinition,
        options.customId,
        (ref) =>
          editorAPI.components.hooks.componentAddedToStage.fire({
            type: 'editorSdk',
            compRef: ref,
          }),
        'editorSdk',
        {
          showAddToHeaderPanel: options.showAddToHeaderPanel,
          optionalIndex: options.optionalIndex,
        },
        null,
        'application',
        appData.appDefinitionId,
      );

    try {
      waitForAddedCompRef(
        runInContext(appData.appDefinitionId, editorAPI, addInner),
      ).then((compRef) => {
        editorAPI.pasteLogic.addContext.setLastAddedComponent(compRef);
        resolve(compRef);
      }, reject);
    } catch (error) {
      reject(error);
    }
  });
}

function getProps(editorAPI, appData, token, options) {
  return editorAPI.components.properties.get(options.componentRef);
}

function updateProps(editorAPI, appData, token, options) {
  const dontAddToUndoRedoStack = true;
  runInContext(appData.appDefinitionId, editorAPI, () =>
    editorAPI.components.properties.update(
      options.componentRef,
      options.props,
      dontAddToUndoRedoStack,
    ),
  );
}

function getPage(editorAPI, appData, token, options) {
  return editorAPI.components.getPage(options.componentRef);
}

function getData(editorAPI, appData, token, options) {
  return editorAPI.components.data.get(options.componentRef);
}

function updateData(editorAPI, appData, token, options) {
  runInContext(appData.appDefinitionId, editorAPI, () => {
    editorAPI.components.data.update(options.componentRef, options.data, true);
    dataUpdateHook(editorAPI, options.componentRef);
  });
}

function getLayout(editorAPI, appData, token, options) {
  return editorAPI.components.layout.get(options.componentRef);
}

export default {
  add: addComponent,
  getPage,
  properties: {
    get: getProps,
    update: updateProps,
  },
  data: {
    get: getData,
    update: updateData,
  },
  layout: {
    get: getLayout,
  },
};
