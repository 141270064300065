// @ts-nocheck
import * as util from '@/util';
import { translate } from '@/i18n';
import * as stateManagement from '@/stateManagement';
import * as newBlogBi from '../../../../newBlogBi/newBlogBi';
import * as blogUtils from '../../../../utils/blogUtils';
import React from 'react';
import * as baseUI from '@/baseUI';
import { CustomScroll } from '@wix/wix-base-ui';
import * as symbols from '@wix/santa-editor-symbols';

class QuickMigrationFailureSlide extends React.Component {
  static displayName = 'QuickMigrationFailureSlide';

  onClose = () => {
    this.props.markQuickMigrationAsSeen();
    this.props.onClose();
    this.props.openParentPanel();
    this.props.sendBi(newBlogBi.events.BLOG_QUICK_MIGRATION_FAILED, {
      is_first: true,
      migration_status: 'Failed',
      role: 'owner',
      origin: this.props.biOrigin,
    });
  };

  handleHelpClick = (e) => {
    e.preventDefault();
    this.props.openHelpCenter();
  };

  render() {
    return (
      <div className="blog-migration-failure-slide">
        <CustomScroll heightRelativeToParent="100%">
          <div className="migration-failed-banner">
            <symbols.symbol name="migrationFailed" />
          </div>

          <div className="content">
            <h1 className="title">
              {translate('SiteApp_NewBlog_Migration_Panel_Error_Title')}
            </h1>

            <p className="subtitle">
              {translate('SiteApp_NewBlog_Migration_Panel_Error_Description')}
              <a
                href="#"
                onClick={(e) => {
                  this.handleHelpClick(e);
                }}
                className="article-link"
              >
                {translate('SiteApp_NewBlog_Migration_Panel_Error_LearnMore')}
              </a>
            </p>

            <baseUI.button
              label="SiteApp_NewBlog_Migration_Panel_Error_CTA_GotIt"
              onClick={this.onClose}
              className="btn-confirm-primary btn-switch"
            />
          </div>

          <div className="footer">
            {translate('SiteApp_NewBlog_Migration_Panel_Error_Footer_Support')}
            <a
              target="_blank"
              href="https://www.wix.com/contact/en?subOptionId=no-name28550"
              className="footer-link"
            >
              {translate(
                'SiteApp_NewBlog_Migration_Panel_Error_Footer_SupportLink',
              )}
            </a>
          </div>
        </CustomScroll>
      </div>
    );
  }
}

const mapStateToProps = ({ editorAPI }, { superApp }) => {
  const isNewBlogPanel = superApp.id === blogUtils.newSuperAppId;
  const parentPanel = isNewBlogPanel
    ? blogUtils.newSuperAppPanelName
    : blogUtils.superAppPanelName;
  const biOrigin = isNewBlogPanel
    ? newBlogBi.ORIGINS.NEW_BLOG_6TH_BUTTON
    : newBlogBi.ORIGINS.OLD_BLOG_6TH_BUTTON;

  return {
    biOrigin,
    openParentPanel: () => editorAPI.panelManager.openPanel(parentPanel),
    markQuickMigrationAsSeen: () =>
      blogUtils.markQuickMigrationAsSeen(editorAPI),
  };
};

const mapDispatchToProps = (dispatch) => ({
  openHelpCenter: () =>
    dispatch(
      stateManagement.panels.actions.openHelpCenter(
        blogUtils.helpArticles.twoStepMigration,
      ),
    ),
  sendBi: (event, parameters) =>
    dispatch(stateManagement.bi.actions.event(event, parameters)),
});

const ConnectedComponent = util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(QuickMigrationFailureSlide);
ConnectedComponent.pure = QuickMigrationFailureSlide;

export default ConnectedComponent;
