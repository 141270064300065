:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._root_2x2d6_7 {
  width: 240px; }

._content_2x2d6_10 {
  padding: 12px 24px;
  position: relative; }

._colors_2x2d6_14 {
  padding: 12px 24px 18px 24px; }

._colorsPreview_2x2d6_17 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: 12px;
  gap: 8px; }

._footer_2x2d6_24 {
  padding: 24px 41px;
  display: flex;
  justify-content: center; }
