import { appStudioUtils, biLogger } from '@/util';
import { privateAppsCreateNewAppClick } from '@wix/bi-logger-blocks-consumption/v2';
import { TEST_VERSION } from './appDetailsUtils';

export enum OpenBlocksEditorOrigins {
  EMPTY_STATE = 'empty state',
  PRIVATE_APPS_PANEL = 'private apps panel',
}

export const openBlocksEditor = (
  origin: OpenBlocksEditorOrigins,
  isStudioAccount: boolean = false,
) => {
  biLogger.report(
    privateAppsCreateNewAppClick({
      builderType: 'classic',
      origin,
    }),
  );
  appStudioUtils.openBlocks({
    mode: appStudioUtils.AppStudioMode.App,
    isWixUser: true,
    httpReferrer: appStudioUtils.httpReferrerOptions.PrivateAppPanel,
    isStudioAccount,
  });
};

export const isMinorUpdatesOn = (version?: string) =>
  version?.substring(0, 1) === '^';

export const resetTestVersion = (version?: string) =>
  version ? `${version.substring(0, version.lastIndexOf('.') + 1)}0` : '';

export const parseAppVersion = (version: string = '') =>
  version
    .split('.')
    .reverse()
    .filter((_, idx) => idx)
    .reverse()
    .join('.');

export const isAlphaApp = (blocksVersion: string) =>
  !blocksVersion || blocksVersion === '1.0.0';

export const isAutoUpdatePending = (
  currentVersion: string,
  latestVersion: string,
  installedVersion: string,
) => {
  if (installedVersion === TEST_VERSION && currentVersion < latestVersion) {
    return true;
  }
  const currVersionObj = appStudioUtils.getVersion(currentVersion);
  const latestVersionObj = appStudioUtils.getVersion(latestVersion);
  return (
    isMinorUpdatesOn(installedVersion) &&
    currVersionObj.major === latestVersionObj.major &&
    currVersionObj.minor < latestVersionObj.minor
  );
};
