"use strict"
const addPanelDataConsts = require('@/addPanelDataConsts')
const i18n = require('@/i18n')

module.exports = {
    type: addPanelDataConsts.SECTIONS_TYPES.PRESET,
    hide: false,
    title: "add_section_heading_selection_tags",
    topTitle: "add_section_heading_selection_tags",
    presetTitle: "add_section_heading_selection_tags",
    subNavigationTitle: "add_section_label_selection_tags",
    showSectionHeader: true,
    subNavigationHide: false,
    additionalBehaviours: {
        labelMode: addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
        infoIcon: addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
        hoverImageAction: addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
        iconEnabledForComps: {}
    },
    props: {
        image:
            "addPanelData/sections/selectionTagsListSection_en/selectionTagsList_en.v3.png",
        imageHover: null,
        items: [
            {
                id: "User_Input_SelectionTagsList_1",
                structure: {
                    type: "Component",
                    skin: "wixui.skins.SelectionTagsList",
                    layout: {
                        width: 324,
                        height: 78,
                        x: 11,
                        y: 73,
                        scale: 1,
                        rotationInDegrees: 0,
                        fixedPosition: false
                    },
                    componentType: "wixui.SelectionTagsList",
                    parent: "tsa8o",
                    metaData: {
                        pageId: "tsa8o"
                    },
                    data: {
                        type: "SelectionTagsList",
                        metaData: {
                            isPreset: false,
                            schemaVersion: "1.0",
                            isHidden: false,
                            pageId: "tsa8o"
                        },
                        options: [
                            {
                                type: "SelectionTag",
                                metaData: {
                                    isPreset: false,
                                    schemaVersion: "1.0",
                                    isHidden: false,
                                    pageId: "tsa8o"
                                },
                                value: 'Classics',
                                label: i18n.translate(
                                    "selection_tags_preset_1_tag1"
                                )
                            },
                            {
                                type: "SelectionTag",
                                metaData: {
                                    isPreset: false,
                                    schemaVersion: "1.0",
                                    isHidden: false,
                                    pageId: "tsa8o"
                                },
                                value: 'Memoirs',
                                label: i18n.translate(
                                    "selection_tags_preset_1_tag2"
                                )
                            },
                            {
                                type: "SelectionTag",
                                metaData: {
                                    isPreset: false,
                                    schemaVersion: "1.0",
                                    isHidden: false,
                                    pageId: "tsa8o"
                                },
                                value: 'Historical Fiction',
                                label: i18n.translate(
                                    "selection_tags_preset_1_tag3"
                                )
                            },
                            {
                                type: "SelectionTag",
                                metaData: {
                                    isPreset: false,
                                    schemaVersion: "1.0",
                                    isHidden: false,
                                    pageId: "tsa8o"
                                },
                                value: 'Novels',
                                label: i18n.translate(
                                    "selection_tags_preset_1_tag4"
                                )
                            },
                            {
                                type: "SelectionTag",
                                metaData: {
                                    isPreset: false,
                                    schemaVersion: "1.0",
                                    isHidden: false,
                                    pageId: "tsa8o"
                                },
                                value: 'Mysteries',
                                label: i18n.translate(
                                    "selection_tags_preset_1_tag5"
                                )
                            },
                            {
                                type: "SelectionTag",
                                metaData: {
                                    isPreset: false,
                                    schemaVersion: "1.0",
                                    isHidden: false,
                                    pageId: "tsa8o"
                                },
                                value: 'Comedy',
                                label: i18n.translate(
                                    "selection_tags_preset_1_tag6"
                                )
                            }
                        ],
                        value: []
                    },
                    props: {
                        type: "SelectionTagsListProperties",
                        metaData: {
                            schemaVersion: "1.0",
                            pageId: "tsa8o"
                        },
                        direction: "ltr",
                        alignment: "left"
                    },
                    style: {
                        type: "ComponentStyle",
                        metaData: {
                            isPreset: false,
                            schemaVersion: "1.0",
                            isHidden: false,
                            pageId: "tsa8o"
                        },
                        style: {
                            properties: {
                                bg: "#3899EC",
                                bg_d: "#CCCCCC",
                                bg_e: "#FF4040",
                                bg_h: "#4EB7F5",
                                bg_s: "#FFFFFF",
                                "boxShadowToggleOn-shd": "false",
                                brd: "#3899EC",
                                brd_d: "#CCCCCC",
                                brd_e: "#FF4040",
                                brd_h: "#4EB7F5",
                                brd_s: "#3899EC",
                                brw: "1",
                                brw_d: "1px",
                                brw_e: "1px",
                                brw_h: "1px",
                                brw_s: "1px",
                                desktopHorizontalMargin: "6",
                                desktopHorizontalPadding: "12",
                                desktopVerticalMargin: "6",
                                desktopVerticalPadding: "6",
                                fnt: "normal normal normal 14px/1.4em helvetica-w01-light",
                                mobileHorizontalMargin: "6",
                                mobileHorizontalPadding: "12",
                                mobileVerticalMargin: "6",
                                mobileVerticalPadding: "6",
                                rd: "20px 20px 20px 20px",
                                shd: "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                txt: "#FFFFFF",
                                txt_d: "#FFFFFF",
                                txt_e: "#FFFFFF",
                                txt_h: "#FFFFFF",
                                txt_s: "#3899EC"
                            },
                            propertiesSource: {
                                bg: "value",
                                bg_d: "value",
                                bg_e: "value",
                                bg_h: "value",
                                bg_s: "value",
                                "boxShadowToggleOn-shd": "value",
                                brd: "value",
                                brd_d: "value",
                                brd_e: "value",
                                brd_h: "value",
                                brd_s: "value",
                                brw: "value",
                                brw_d: "value",
                                brw_e: "value",
                                brw_h: "value",
                                brw_s: "value",
                                desktopHorizontalMargin: "value",
                                desktopHorizontalPadding: "value",
                                desktopVerticalMargin: "value",
                                desktopVerticalPadding: "value",
                                fnt: "value",
                                mobileHorizontalMargin: "value",
                                mobileHorizontalPadding: "value",
                                mobileVerticalMargin: "value",
                                mobileVerticalPadding: "value",
                                rd: "value",
                                shd: "value",
                                txt: "value",
                                txt_d: "value",
                                txt_e: "value",
                                txt_h: "value",
                                txt_s: "value",
                                fntlbl: "value",
                                txtlbl: "value",
                                txtlblrq:"value"
                            }
                        },
                        componentClassName: "wixui.SelectionTagsList",
                        pageId: "",
                        compId: "",
                        styleType: "custom",
                        skin: "wixui.skins.SelectionTagsList"
                    },
                    activeModes: {},
                    id: "comp-k4wqtb70"
                },
                preset: {
                    rect: {
                        width: 324,
                        height: 94,
                        x: 0,
                        y: 0
                    },
                    tags: null
                }
            },
            {
                id: "User_Input_SelectionTagsList_2",
                structure: {
                    type: "Component",
                    skin: "wixui.skins.SelectionTagsList",
                    layout: {
                        width: 324,
                        height: 78,
                        x: 11,
                        y: -36,
                        scale: 1,
                        rotationInDegrees: 0,
                        fixedPosition: false
                    },
                    componentType: "wixui.SelectionTagsList",
                    parent: "tsa8o",
                    metaData: {
                        pageId: "tsa8o"
                    },
                    data: {
                        type: "SelectionTagsList",
                        metaData: {
                            isPreset: false,
                            schemaVersion: "1.0",
                            isHidden: false,
                            pageId: "tsa8o"
                        },
                        options: [
                            {
                                type: "SelectionTag",
                                metaData: {
                                    isPreset: false,
                                    schemaVersion: "1.0",
                                    isHidden: false,
                                    pageId: "tsa8o"
                                },
                                value: 'Golf',
                                label: i18n.translate(
                                    "selection_tags_preset_2_tag1"
                                )
                            },
                            {
                                type: "SelectionTag",
                                metaData: {
                                    isPreset: false,
                                    schemaVersion: "1.0",
                                    isHidden: false,
                                    pageId: "tsa8o"
                                },
                                value: 'Tennis',
                                label: i18n.translate(
                                    "selection_tags_preset_2_tag2"
                                )
                            },
                            {
                                type: "SelectionTag",
                                metaData: {
                                    isPreset: false,
                                    schemaVersion: "1.0",
                                    isHidden: false,
                                    pageId: "tsa8o"
                                },
                                value: 'Swimming',
                                label: i18n.translate(
                                    "selection_tags_preset_2_tag3"
                                )
                            },
                            {
                                type: "SelectionTag",
                                metaData: {
                                    isPreset: false,
                                    schemaVersion: "1.0",
                                    isHidden: false,
                                    pageId: "tsa8o"
                                },
                                value: 'Basketball',
                                label: i18n.translate(
                                    "selection_tags_preset_2_tag4"
                                )
                            },
                            {
                                type: "SelectionTag",
                                metaData: {
                                    isPreset: false,
                                    schemaVersion: "1.0",
                                    isHidden: false,
                                    pageId: "tsa8o"
                                },
                                value: 'Track',
                                label: i18n.translate(
                                    "selection_tags_preset_2_tag5"
                                )
                            },
                            {
                                type: "SelectionTag",
                                metaData: {
                                    isPreset: false,
                                    schemaVersion: "1.0",
                                    isHidden: false,
                                    pageId: "tsa8o"
                                },
                                value: 'Judo',
                                label: i18n.translate(
                                    "selection_tags_preset_2_tag6"
                                )
                            }
                        ],
                        value: []
                    },
                    props: {
                        type: "SelectionTagsListProperties",
                        metaData: {
                            schemaVersion: "1.0",
                            pageId: "tsa8o"
                        },
                        direction: "ltr",
                        alignment: "left"
                    },
                    style: {
                        type: "ComponentStyle",
                        metaData: {
                            isPreset: false,
                            schemaVersion: "1.0",
                            isHidden: false,
                            pageId: "tsa8o"
                        },
                        style: {
                            properties: {
                                bg: "#FFFFFF",
                                bg_d: "#CCCCCC",
                                bg_e: "#FF4040",
                                bg_h: "#919191",
                                bg_s: "#67D6D6",
                                "boxShadowToggleOn-shd": "false",
                                brd: "#858585",
                                brd_d: "#CCCCCC",
                                brd_e: "#FF4040",
                                brd_h: "#919191",
                                brd_s: "#67D6D6",
                                brw: "2",
                                brw_d: "2px",
                                brw_e: "2px",
                                brw_h: "2px",
                                brw_s: "2px",
                                desktopHorizontalMargin: "14",
                                desktopHorizontalPadding: "12",
                                desktopVerticalMargin: "8",
                                desktopVerticalPadding: "6",
                                fnt:
                                    "normal normal normal 14px/1.4em avenir-lt-w01_35-light1475496",
                                mobileHorizontalMargin: "14",
                                mobileHorizontalPadding: "12",
                                mobileVerticalMargin: "8",
                                mobileVerticalPadding: "6",
                                rd: "0px 0px 0px 0px",
                                shd: "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                txt: "#858585",
                                txt_d: "#F5F5F5",
                                txt_e: "#FFFFFF",
                                txt_h: "#FFFFFF",
                                txt_s: "#FFFFFF"
                            },
                            propertiesSource: {
                                bg: "value",
                                bg_d: "value",
                                bg_e: "value",
                                bg_h: "value",
                                bg_s: "value",
                                "boxShadowToggleOn-shd": "value",
                                brd: "value",
                                brd_d: "value",
                                brd_e: "value",
                                brd_h: "value",
                                brd_s: "value",
                                brw: "value",
                                brw_d: "value",
                                brw_e: "value",
                                brw_h: "value",
                                brw_s: "value",
                                desktopHorizontalMargin: "value",
                                desktopHorizontalPadding: "value",
                                desktopVerticalMargin: "value",
                                desktopVerticalPadding: "value",
                                fnt: "value",
                                mobileHorizontalMargin: "value",
                                mobileHorizontalPadding: "value",
                                mobileVerticalMargin: "value",
                                mobileVerticalPadding: "value",
                                rd: "value",
                                shd: "value",
                                txt: "value",
                                txt_d: "value",
                                txt_e: "value",
                                txt_h: "value",
                                txt_s: "value",
                                fntlbl: "value",
                                txtlbl: "value",
                                txtlblrq:"value"
                            }
                        },
                        componentClassName: "wixui.SelectionTagsList",
                        pageId: "",
                        compId: "",
                        styleType: "custom",
                        skin: "wixui.skins.SelectionTagsList"
                    },
                    activeModes: {},
                    id: "comp-k4wqtb6t"
                },
                preset: {
                    rect: {
                        width: 272,
                        height: 100,
                        x: 0,
                        y: 95
                    },
                    tags: null
                }
            },
            {
                id: "User_Input_SelectionTagsList_3",
                structure: {
                    type: "Component",
                    skin: "wixui.skins.SelectionTagsList",
                    layout: {
                        width: 324,
                        height: 78,
                        x: 11,
                        y: 73,
                        scale: 1,
                        rotationInDegrees: 0,
                        fixedPosition: false
                    },
                    componentType: "wixui.SelectionTagsList",
                    parent: "tsa8o",
                    metaData: {
                        pageId: "tsa8o"
                    },
                    data: {
                        type: "SelectionTagsList",
                        metaData: {
                            isPreset: false,
                            schemaVersion: "1.0",
                            isHidden: false,
                            pageId: "tsa8o"
                        },
                        options: [
                            {
                                type: "SelectionTag",
                                metaData: {
                                    isPreset: false,
                                    schemaVersion: "1.0",
                                    isHidden: false,
                                    pageId: "tsa8o"
                                },
                                value: 'Food',
                                label: i18n.translate(
                                    "selection_tags_preset_3_tag1"
                                )
                            },
                            {
                                type: "SelectionTag",
                                metaData: {
                                    isPreset: false,
                                    schemaVersion: "1.0",
                                    isHidden: false,
                                    pageId: "tsa8o"
                                },
                                value: 'Hotels',
                                label: i18n.translate(
                                    "selection_tags_preset_3_tag2"
                                )
                            },
                            {
                                type: "SelectionTag",
                                metaData: {
                                    isPreset: false,
                                    schemaVersion: "1.0",
                                    isHidden: false,
                                    pageId: "tsa8o"
                                },
                                value: 'Nightlife',
                                label: i18n.translate(
                                    "selection_tags_preset_3_tag3"
                                )
                            },
                            {
                                type: "SelectionTag",
                                metaData: {
                                    isPreset: false,
                                    schemaVersion: "1.0",
                                    isHidden: false,
                                    pageId: "tsa8o"
                                },
                                value: 'Family Activities',
                                label: i18n.translate(
                                    "selection_tags_preset_3_tag4"
                                )
                            },
                            {
                                type: "SelectionTag",
                                metaData: {
                                    isPreset: false,
                                    schemaVersion: "1.0",
                                    isHidden: false,
                                    pageId: "tsa8o"
                                },
                                value: 'Tours',
                                label: i18n.translate(
                                    "selection_tags_preset_3_tag5"
                                )
                            },
                            {
                                type: "SelectionTag",
                                metaData: {
                                    isPreset: false,
                                    schemaVersion: "1.0",
                                    isHidden: false,
                                    pageId: "tsa8o"
                                },
                                value: 'Hiking',
                                label: i18n.translate(
                                    "selection_tags_preset_3_tag6"
                                )
                            }
                        ],
                        value: []
                    },
                    props: {
                        type: "SelectionTagsListProperties",
                        metaData: {
                            schemaVersion: "1.0",
                            pageId: "tsa8o"
                        },
                        direction: "ltr",
                        alignment: "left"
                    },
                    style: {
                        type: "ComponentStyle",
                        metaData: {
                            isPreset: false,
                            schemaVersion: "1.0",
                            isHidden: false,
                            pageId: "tsa8o"
                        },
                        style: {
                            properties: {
                                bg: "#8C84FA",
                                bg_d: "#CCCCCC",
                                bg_e: "#FF4040",
                                bg_h: "#7D74F2",
                                bg_s: "#000000",
                                "boxShadowToggleOn-shd": "false",
                                brd: "#8C84FA",
                                brd_d: "#CCCCCC",
                                brd_e: "#FF4040",
                                brd_h: "#7D74F2",
                                brd_s: "#000000",
                                brw: "2",
                                brw_d: "2px",
                                brw_e: "2px",
                                brw_h: "2px",
                                brw_s: "2px",
                                desktopHorizontalMargin: "6",
                                desktopHorizontalPadding: "16",
                                desktopVerticalMargin: "6",
                                desktopVerticalPadding: "6",
                                fnt:
                                    "normal normal normal 14px/1.4em avenir-lt-w01_35-light1475496",
                                mobileHorizontalMargin: "6",
                                mobileHorizontalPadding: "16",
                                mobileVerticalMargin: "6",
                                mobileVerticalPadding: "6",
                                rd: "0px 0px 0px 0px",
                                shd: "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                txt: "#FFFFFF",
                                txt_d: "#F5F5F5",
                                txt_e: "#FFFFFF",
                                txt_h: "#FFFFFF",
                                txt_s: "#FFFFFF"
                            },
                            propertiesSource: {
                                bg: "value",
                                bg_d: "value",
                                bg_e: "value",
                                bg_h: "value",
                                bg_s: "value",
                                "boxShadowToggleOn-shd": "value",
                                brd: "value",
                                brd_d: "value",
                                brd_e: "value",
                                brd_h: "value",
                                brd_s: "value",
                                brw: "value",
                                brw_d: "value",
                                brw_e: "value",
                                brw_h: "value",
                                brw_s: "value",
                                desktopHorizontalMargin: "value",
                                desktopHorizontalPadding: "value",
                                desktopVerticalMargin: "value",
                                desktopVerticalPadding: "value",
                                fnt: "value",
                                mobileHorizontalMargin: "value",
                                mobileHorizontalPadding: "value",
                                mobileVerticalMargin: "value",
                                mobileVerticalPadding: "value",
                                rd: "value",
                                shd: "value",
                                txt: "value",
                                txt_d: "value",
                                txt_e: "value",
                                txt_h: "value",
                                txt_s: "value",
                                fntlbl: "value",
                                txtlbl: "value",
                                txtlblrq:"value"
                            }
                        },
                        componentClassName: "wixui.SelectionTagsList",
                        pageId: "",
                        compId: "",
                        styleType: "custom",
                        skin: "wixui.skins.SelectionTagsList"
                    },
                    activeModes: {},
                    id: "comp-k4wqtb70"
                },
                preset: {
                    rect: {
                        width: 324,
                        height: 92,
                        x: 0,
                        y: 195
                    },
                    tags: null
                }
            },
            {
                id: "User_Input_SelectionTagsList_4",
                structure: {
                    type: "Component",
                    skin: "wixui.skins.SelectionTagsList",
                    layout: {
                        width: 324,
                        height: 72,
                        x: 11,
                        y: 181,
                        scale: 1,
                        rotationInDegrees: 0,
                        fixedPosition: false
                    },
                    componentType: "wixui.SelectionTagsList",
                    parent: "tsa8o",
                    metaData: {
                        pageId: "tsa8o"
                    },
                    data: {
                        type: "SelectionTagsList",
                        metaData: {
                            isPreset: false,
                            schemaVersion: "1.0",
                            isHidden: false,
                            pageId: "tsa8o"
                        },
                        options: [
                            {
                                type: "SelectionTag",
                                metaData: {
                                    isPreset: false,
                                    schemaVersion: "1.0",
                                    isHidden: false,
                                    pageId: "tsa8o"
                                },
                                value: 'Chili',
                                label: i18n.translate(
                                    "selection_tags_preset_4_tag1"
                                )
                            },
                            {
                                type: "SelectionTag",
                                metaData: {
                                    isPreset: false,
                                    schemaVersion: "1.0",
                                    isHidden: false,
                                    pageId: "tsa8o"
                                },
                                value: 'Onions',
                                label: i18n.translate(
                                    "selection_tags_preset_4_tag2"
                                )
                            },
                            {
                                type: "SelectionTag",
                                metaData: {
                                    isPreset: false,
                                    schemaVersion: "1.0",
                                    isHidden: false,
                                    pageId: "tsa8o"
                                },
                                value: 'Pepperoni',
                                label: i18n.translate(
                                    "selection_tags_preset_4_tag3"
                                )
                            },
                            {
                                type: "SelectionTag",
                                metaData: {
                                    isPreset: false,
                                    schemaVersion: "1.0",
                                    isHidden: false,
                                    pageId: "tsa8o"
                                },
                                value: 'Mushrooms',
                                label: i18n.translate(
                                    "selection_tags_preset_4_tag4"
                                )
                            },
                            {
                                type: "SelectionTag",
                                metaData: {
                                    isPreset: false,
                                    schemaVersion: "1.0",
                                    isHidden: false,
                                    pageId: "tsa8o"
                                },
                                value: 'Olives',
                                label: i18n.translate(
                                    "selection_tags_preset_4_tag5"
                                )
                            },
                            {
                                type: "SelectionTag",
                                metaData: {
                                    isPreset: false,
                                    schemaVersion: "1.0",
                                    isHidden: false,
                                    pageId: "tsa8o"
                                },
                                value: 'Cheese',
                                label: i18n.translate(
                                    "selection_tags_preset_4_tag6"
                                )
                            }
                        ],
                        value: []
                    },
                    props: {
                        type: "SelectionTagsListProperties",
                        metaData: {
                            schemaVersion: "1.0",
                            pageId: "tsa8o"
                        },
                        direction: "ltr",
                        alignment: "left"
                    },
                    style: {
                        type: "ComponentStyle",
                        metaData: {
                            isPreset: false,
                            schemaVersion: "1.0",
                            isHidden: false,
                            pageId: "tsa8o"
                        },
                        style: {
                            properties: {
                                bg: "#FFFFFF",
                                bg_d: "#FFFFFF",
                                bg_e: "#FF4040",
                                bg_h: "#FFFFFF",
                                bg_s: "#000000",
                                "boxShadowToggleOn-shd": "true",
                                brd: "#F5F5F5",
                                brd_d: "#F5F5F5",
                                brd_e: "#FF4040",
                                brd_h: "#E8E8E8",
                                brd_s: "#000000",
                                brw: "1",
                                brw_d: "2px",
                                brw_e: "2px",
                                brw_h: "2px",
                                brw_s: "2px",
                                desktopHorizontalMargin: "6",
                                desktopHorizontalPadding: "19",
                                desktopVerticalMargin: "6",
                                desktopVerticalPadding: "9",
                                fnt:
                                    "normal normal normal 9px/1.4em lulo-clean-w01-one-bold",
                                mobileHorizontalMargin: "6",
                                mobileHorizontalPadding: "19",
                                mobileVerticalMargin: "6",
                                mobileVerticalPadding: "9",
                                rd: "6px 6px 6px 6px",
                                shd: "0.00px 3.00px 10px 1px rgba(0,0,0,0.1)",
                                txt: "#000000",
                                txt_d: "#C7C7C7",
                                txt_e: "#FFFFFF",
                                txt_h: "#000000",
                                txt_s: "#FFFFFF"
                            },
                            propertiesSource: {
                                bg: "value",
                                bg_d: "value",
                                bg_e: "value",
                                bg_h: "value",
                                bg_s: "value",
                                "boxShadowToggleOn-shd": "value",
                                brd: "value",
                                brd_d: "value",
                                brd_e: "value",
                                brd_h: "value",
                                brd_s: "value",
                                brw: "value",
                                brw_d: "value",
                                brw_e: "value",
                                brw_h: "value",
                                brw_s: "value",
                                desktopHorizontalMargin: "value",
                                desktopHorizontalPadding: "value",
                                desktopVerticalMargin: "value",
                                desktopVerticalPadding: "value",
                                fnt: "value",
                                mobileHorizontalMargin: "value",
                                mobileHorizontalPadding: "value",
                                mobileVerticalMargin: "value",
                                mobileVerticalPadding: "value",
                                rd: "value",
                                shd: "value",
                                txt: "value",
                                txt_d: "value",
                                txt_e: "value",
                                txt_h: "value",
                                txt_s: "value",
                                fntlbl: "value",
                                txtlbl: "value",
                                txtlblrq:"value"
                            }
                        },
                        componentClassName: "wixui.SelectionTagsList",
                        pageId: "",
                        compId: "",
                        styleType: "custom",
                        skin: "wixui.skins.SelectionTagsList"
                    },
                    activeModes: {},
                    id: "comp-k4wqtb76"
                },
                preset: {
                    rect: {
                        width: 324,
                        height: 100,
                        x: 0,
                        y: 285
                    },
                    tags: null
                }
            },
            {
                id: "User_Input_SelectionTagsList_5",
                structure: {
                    type: "Component",
                    skin: "wixui.skins.SelectionTagsList",
                    layout: {
                        width: 324,
                        height: 78,
                        x: 11,
                        y: 284,
                        scale: 1,
                        rotationInDegrees: 0,
                        fixedPosition: false
                    },
                    componentType: "wixui.SelectionTagsList",
                    parent: "tsa8o",
                    metaData: {
                        pageId: "tsa8o"
                    },
                    data: {
                        type: "SelectionTagsList",
                        metaData: {
                            isPreset: false,
                            schemaVersion: "1.0",
                            isHidden: false,
                            pageId: "tsa8o"
                        },
                        options: [
                            {
                                type: "SelectionTag",
                                metaData: {
                                    isPreset: false,
                                    schemaVersion: "1.0",
                                    isHidden: false,
                                    pageId: "tsa8o"
                                },
                                value: 'Nuts',
                                label: i18n.translate(
                                    "selection_tags_preset_5_tag1"
                                )
                            },
                            {
                                type: "SelectionTag",
                                metaData: {
                                    isPreset: false,
                                    schemaVersion: "1.0",
                                    isHidden: false,
                                    pageId: "tsa8o"
                                },
                                value: 'Fudge',
                                label: i18n.translate(
                                    "selection_tags_preset_5_tag2"
                                )
                            },
                            {
                                type: "SelectionTag",
                                metaData: {
                                    isPreset: false,
                                    schemaVersion: "1.0",
                                    isHidden: false,
                                    pageId: "tsa8o"
                                },
                                value: 'Chocolate',
                                label: i18n.translate(
                                    "selection_tags_preset_5_tag3"
                                )
                            },
                            {
                                type: "SelectionTag",
                                metaData: {
                                    isPreset: false,
                                    schemaVersion: "1.0",
                                    isHidden: false,
                                    pageId: "tsa8o"
                                },
                                value: 'Sprinkles',
                                label: i18n.translate(
                                    "selection_tags_preset_5_tag4"
                                )
                            },
                            {
                                type: "SelectionTag",
                                metaData: {
                                    isPreset: false,
                                    schemaVersion: "1.0",
                                    isHidden: false,
                                    pageId: "tsa8o"
                                },
                                value: 'Whipped Cream',
                                label: i18n.translate(
                                    "selection_tags_preset_5_tag5"
                                )
                            },
                            {
                                type: "SelectionTag",
                                metaData: {
                                    isPreset: false,
                                    schemaVersion: "1.0",
                                    isHidden: false,
                                    pageId: "tsa8o"
                                },
                                value: 'Berries',
                                label: i18n.translate(
                                    "selection_tags_preset_5_tag6"
                                )
                            }
                        ],
                        value: []
                    },
                    props: {
                        type: "SelectionTagsListProperties",
                        metaData: {
                            schemaVersion: "1.0",
                            pageId: "tsa8o"
                        },
                        direction: "ltr",
                        alignment: "left"
                    },
                    style: {
                        type: "ComponentStyle",
                        metaData: {
                            isPreset: false,
                            schemaVersion: "1.0",
                            isHidden: false,
                            pageId: "tsa8o"
                        },
                        style: {
                            properties: {
                                "alpha-bg_h": "1",
                                bg: "#4BDB7B",
                                bg_d: "#CCCCCC",
                                bg_e: "#FF4040",
                                bg_h: "#66E891",
                                bg_s: "#FFFFFF",
                                "boxShadowToggleOn-shd": "false",
                                brd: "#5EE08A",
                                brd_d: "#CCCCCC",
                                brd_e: "#FF4040",
                                brd_h: "#66E891",
                                brd_s: "#5EE08A",
                                brw: "1",
                                brw_d: "2px",
                                brw_e: "2px",
                                brw_h: "2px",
                                brw_s: "2px",
                                desktopHorizontalMargin: "8",
                                desktopHorizontalPadding: "14",
                                desktopVerticalMargin: "8",
                                desktopVerticalPadding: "7",
                                fnt:
                                    "normal normal normal 14px/1.4em bree-w01-thin-oblique",
                                mobileHorizontalMargin: "4",
                                mobileHorizontalPadding: "14",
                                mobileVerticalMargin: "4",
                                mobileVerticalPadding: "7",
                                rd: "10px 10px 10px 10px",
                                shd: "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                txt: "#FFFFFF",
                                txt_d: "#FFFFFF",
                                txt_e: "#FFFFFF",
                                txt_h: "#FFFFFF",
                                txt_s: "#5EE08A"
                            },
                            propertiesSource: {
                                "alpha-bg_h": "value",
                                bg: "value",
                                bg_d: "value",
                                bg_e: "value",
                                bg_h: "value",
                                bg_s: "value",
                                "boxShadowToggleOn-shd": "value",
                                brd: "value",
                                brd_d: "value",
                                brd_e: "value",
                                brd_h: "value",
                                brd_s: "value",
                                brw: "value",
                                brw_d: "value",
                                brw_e: "value",
                                brw_h: "value",
                                brw_s: "value",
                                desktopHorizontalMargin: "value",
                                desktopHorizontalPadding: "value",
                                desktopVerticalMargin: "value",
                                desktopVerticalPadding: "value",
                                fnt: "value",
                                mobileHorizontalMargin: "value",
                                mobileHorizontalPadding: "value",
                                mobileVerticalMargin: "value",
                                mobileVerticalPadding: "value",
                                rd: "value",
                                shd: "value",
                                txt: "value",
                                txt_d: "value",
                                txt_e: "value",
                                txt_h: "value",
                                txt_s: "value",
                                fntlbl: "value",
                                txtlbl: "value",
                                txtlblrq:"value"
                            }
                        },
                        componentClassName: "wixui.SelectionTagsList",
                        pageId: "",
                        compId: "",
                        styleType: "custom",
                        skin: "wixui.skins.SelectionTagsList"
                    },
                    activeModes: {},
                    id: "comp-k4wqtb7d"
                },
                preset: {
                    rect: {
                        width: 324,
                        height: 100,
                        x: 0,
                        y: 385
                    },
                    tags: null
                }
            }
        ],
        compTypes: ["wixui.SelectionTagsList"]
    },
    help: {
        hide: false,
        text: "add_section_heading_selection_tags_tooltip_description"
    }
}
