// @ts-nocheck
import _ from 'lodash';
import * as stateManagement from '@/stateManagement';

function setTopSectionToUserPrefs(
  categoryId,
  lastScrollPosition,
  sectionIndex,
) {
  this.getEditorAPI().store.dispatch(
    stateManagement.userPreferences.actions.setSessionUserPreferences(
      `category_${categoryId}_topScroll`,
      lastScrollPosition,
    ),
  );
  this.getEditorAPI().store.dispatch(
    stateManagement.userPreferences.actions.setSessionUserPreferences(
      `category_${categoryId}_topSection`,
      sectionIndex,
    ),
  );
}

function onCloseClearSectionFromUserPrefExceptSelectedSection(
  selectedCategoryId,
  allCategories,
) {
  const { dispatch } = this.getEditorAPI().store;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
  _.forEach(allCategories, function (categoryObj) {
    if (categoryObj.id !== selectedCategoryId) {
      dispatch(
        stateManagement.userPreferences.actions.setSessionUserPreferences(
          `category_${categoryObj.id}_topScroll`,
          null,
        ),
      );
      dispatch(
        stateManagement.userPreferences.actions.setSessionUserPreferences(
          `category_${categoryObj.id}_topSection`,
          null,
        ),
      );
    }
  });
}

function getTopScrollFromUserPrefs(categoryId) {
  return (
    stateManagement.userPreferences.selectors.getSessionUserPreferences(
      `category_${categoryId}_topScroll`,
    )(this.getEditorAPI().store.getState()) || 0
  );
}

function getTopSectionFromUserPrefs(categoryId) {
  return (
    stateManagement.userPreferences.selectors.getSessionUserPreferences(
      `category_${categoryId}_topSection`,
    )(this.getEditorAPI().store.getState()) || 0
  );
}

function getInnerNavigationSavedState() {
  return (
    stateManagement.userPreferences.selectors.getSessionUserPreferences(
      'innerNavigationState',
    )(this.getEditorAPI().store.getState()) || null
  );
}

export {
  setTopSectionToUserPrefs,
  onCloseClearSectionFromUserPrefExceptSelectedSection,
  getTopScrollFromUserPrefs,
  getTopSectionFromUserPrefs,
  getInnerNavigationSavedState,
};
