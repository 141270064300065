import type { MapDispatchToProps } from 'types/redux';
import type { SearchAppBoxDispatchProps } from './types';

const getDataAsync =
  (appDefId: AnyFixMe) =>
  (dispatch: AnyFixMe, getState: AnyFixMe, { editorAPI }: AnyFixMe) =>
    appDefId ? editorAPI.dsRead.tpa.appMarket.getDataAsync(appDefId) : null;

export const mapDispatchToProps: MapDispatchToProps<
  SearchAppBoxDispatchProps,
  {}
> = (dispatch) => ({
  getDataAsync: (appDefId) => dispatch(getDataAsync(appDefId)),
});
