import type { EntryPoint } from '@/apilib';
import { EditorAPIKey } from '@/apis';
import { createConcurrentUsersApi } from './concurrentUsersWrapper';
import { ConcurrentUsersApiKey } from './publicApiKey';

export const ConcurrentUsersEntrypoint: EntryPoint = {
  name: 'ConcurrentUsersApi',
  declareAPIs: () => [ConcurrentUsersApiKey],
  getDependencyAPIs: () => [EditorAPIKey],
  attach(shell) {
    shell.contributeAPI(ConcurrentUsersApiKey, () =>
      createConcurrentUsersApi(shell),
    );
  },
};
