// @ts-nocheck
const MASKS = [
  {
    index: 2,
    id: '909695c1e003409ba70b5561666c7c4d.svg',
    thumbId: 'shape-01-02',
    thumbnail:
      '<svg fill="#fff" viewBox="0 0 72 72" height="72" width="72" xmlns="http://www.w3.org/2000/svg">\n<g>\n<path d="M63 9v54H9V9h54z"/>\n</g>\n</svg>',
  },
  {
    index: 3,
    id: 'cf545b123b6e4523b228d62bfa0dea9b.svg',
    thumbId: 'shape-01-03',
    thumbnail:
      '<svg fill="#fff" viewBox="0 0 72 72" height="72" width="72" xmlns="http://www.w3.org/2000/svg">\n    <g>\n        <path d="M66 36c0 16.569-13.431 30-30 30C19.431 66 6 52.569 6 36 6 19.431 19.431 6 36 6c16.569 0 30 13.431 30 30z"/>\n    </g>\n</svg>\n',
  },
  {
    index: 4,
    id: '9cca9e9c03ec45e28cb046998aedd32d.svg',
    thumbId: 'shape-01-04',
    thumbnail:
      '<svg fill="#fff" viewBox="0 0 72 72" height="72" width="72" xmlns="http://www.w3.org/2000/svg">\n    <g>\n        <path d="M36 8.955L4.643 63.045h62.714L36 8.955z"/>\n    </g>\n</svg>\n',
  },
  {
    index: 5,
    id: 'ef6999c24fec4324876f32507978d911.svg',
    thumbId: 'shape-01-05',
    thumbnail:
      '<svg fill="#fff" viewBox="0 0 72 72" height="72" width="72" xmlns="http://www.w3.org/2000/svg">\n    <g>\n        <path d="M45.31 7.287H26.69L11.626 18.254 5.872 36l5.754 17.746L26.69 64.713h18.62l15.064-10.967L66.128 36l-5.754-17.746L45.31 7.287z"/>\n    </g>\n</svg>\n',
  },
  {
    index: 6,
    id: '0dc68a4a1c6440e285913033e4d0a0fe.svg',
    thumbId: 'shape-01-06',
    thumbnail:
      '<svg fill="#fff" viewBox="0 0 72 72" height="72" width="72" xmlns="http://www.w3.org/2000/svg">\n    <g>\n        <path d="M36.053 5.185L9.009 20.799v31.228l27.044 15.614 27.044-15.614V20.799L36.053 5.185z"/>\n    </g>\n</svg>\n',
  },
  {
    index: 7,
    id: 'aeb0df3014a44462815acf283a823208.svg',
    thumbId: 'shape-01-07',
    thumbnail:
      '<svg fill="#fff" viewBox="0 0 72 72" height="72" width="72" xmlns="http://www.w3.org/2000/svg">\n    <g>\n        <path d="M63.702 63.904H8.298L20.054 8.096h31.892l11.756 55.808z"/>\n    </g>\n</svg>\n',
  },
  {
    index: 8,
    id: 'c5e4941a7b6546258e2ef4c1807eaf7b.svg',
    thumbId: 'shape-01-08',
    thumbnail:
      '<svg fill="#fff" viewBox="0 0 72 72" height="72" width="72" xmlns="http://www.w3.org/2000/svg">\n    <g>\n        <path d="M64.538 36L36 68.644 7.462 36 36 3.356 64.538 36z"/>\n    </g>\n</svg>\n',
  },
  {
    index: 9,
    id: 'd3bcfabb79fb4bb193e17e0e5996fdfe.svg',
    thumbId: 'shape-01-09',
    thumbnail:
      '<svg fill="#fff" viewBox="0 0 72 72" height="72" width="72" xmlns="http://www.w3.org/2000/svg">\n    <g>\n        <path d="M52.343 63.904H8.096L19.657 8.096h44.247L52.343 63.904z"/>\n    </g>\n</svg>\n',
  },
  {
    index: 10,
    id: '2428012b6769448081719f3fde9f0f78.svg',
    thumbId: 'shape-01-10',
    thumbnail:
      '<svg fill="#fff" viewBox="0 0 72 72" height="72" width="72" xmlns="http://www.w3.org/2000/svg">\n    <g>\n        <path d="M36 6.883c-12.842 0-23.253 9.699-23.253 21.663v36.571h46.507V28.546C59.253 16.582 48.842 6.883 36 6.883z"/>\n    </g>\n</svg>\n',
  },
  {
    index: 11,
    id: '72df8655830f4db6b8efbc852291ce0b.svg',
    thumbId: 'shape-01-11',
    thumbnail:
      '<svg fill="#fff" viewBox="0 0 72 72" height="72" width="72" xmlns="http://www.w3.org/2000/svg">\n    <g>\n        <path d="M47.92 23.092c-1.913 0-3.726.422-5.357 1.174V4.537c-17.308 0-31.34 14.087-31.34 31.463s14.031 31.463 31.34 31.463v-19.73a12.76 12.76 0 0 0 5.357 1.174c7.101 0 12.857-5.779 12.857-12.908S55.021 23.092 47.92 23.092z"/>\n    </g>\n</svg>\n',
  },
  {
    index: 12,
    id: '3813763a9dc144ce9324e280c91136d1.svg',
    thumbId: 'shape-01-12',
    thumbnail:
      '<svg fill="#fff" viewBox="0 0 72 72" height="72" width="72" xmlns="http://www.w3.org/2000/svg">\n    <g>\n        <path d="M56.041 6.33A31.586 31.586 0 0 0 45.52 4.537C28.09 4.537 13.959 18.623 13.959 36S28.09 67.463 45.52 67.463c3.689 0 7.23-.633 10.52-1.793V6.33z"/>\n    </g>\n</svg>\n',
  },
  {
    index: 13,
    id: '906322ee0e2b45e6941997bdb0790e82.svg',
    thumbId: 'shape-01-13',
    thumbnail:
      '<svg fill="#fff" viewBox="0 0 72 72" height="72" width="72" xmlns="http://www.w3.org/2000/svg">\n    <g>\n        <path d="M65.029 35.72L44.481 6l-8.372 12.059L27.882 6.16 6.971 36.28 27.519 66l8.372-12.059 8.227 11.899 20.911-30.12z"/>\n    </g>\n</svg>\n',
  },
  {
    index: 14,
    id: 'd8e1899d7ddf4197baf56ef8b22a5e8a.svg',
    thumbId: 'shape-01-14',
    thumbnail:
      '<svg fill="#fff" viewBox="0 0 72 72" height="72" width="72" xmlns="http://www.w3.org/2000/svg">\n    <g>\n        <path d="M45.557 7.833L36 36.107 26.442 7.833 7.4 64.167h57.2L45.557 7.833z"/>\n    </g>\n</svg>\n',
  },
  {
    index: 15,
    id: '088d89c2b06642468a8418992fe38e73.svg',
    thumbId: 'shape-01-15',
    thumbnail:
      '<svg fill="#fff" viewBox="0 0 72 72" height="72" width="72" xmlns="http://www.w3.org/2000/svg">\n    <g>\n        <path d="M57.4 6.351L36 24.536 14.6 6.351v59.298L36 47.464l21.4 18.185V6.351z"/>\n    </g>\n</svg>\n',
  },
  {
    index: 16,
    id: 'c4a2e6079ca84783af925ba9e640c851.svg',
    thumbId: 'shape-01-16',
    thumbnail:
      '<svg fill="#fff" viewBox="0 0 72 72" height="72" width="72" xmlns="http://www.w3.org/2000/svg">\n    <g>\n        <path d="M45.018 45.018V26.982H26.982V8.947H8.947v36.49h17.597v17.616h36.509V45.018H45.018z"/>\n    </g>\n</svg>\n',
  },
  {
    index: 17,
    id: 'b4d71b7aa00c4d6b8d87f5ce84df8e0a.svg',
    thumbId: 'shape-01-17',
    thumbnail:
      '<svg fill="#fff" viewBox="0 0 72 72" height="72" width="72" xmlns="http://www.w3.org/2000/svg">\n    <g>\n        <path d="M66.311 46.072V25.928H46.072V5.689H25.928v20.239H5.689v20.144h20.239v20.239h20.144V46.072h20.239z"/>\n    </g>\n</svg>\n',
  },
  {
    index: 18,
    id: '591183f2d0454ddaa23a7f20dc8cdb18.svg',
    thumbId: 'shape-01-18',
    thumbnail:
      '<svg fill="#fff" viewBox="0 0 72 72" height="72" width="72" xmlns="http://www.w3.org/2000/svg">\n    <g>\n        <path d="M45.273 45.273V26.727H26.727V8.18H8.18v55.64h55.64V45.273H45.273z"/>\n    </g>\n</svg>\n',
  },
  {
    index: 19,
    id: '92254bf1c7e34693b107d3c194cc8943.svg',
    thumbId: 'shape-01-19',
    thumbnail:
      '<svg fill="#fff" viewBox="0 0 72 72" height="72" width="72" xmlns="http://www.w3.org/2000/svg">\n    <g>\n        <path d="M48.166 38.361c-1.107 5.674-6.119 9.971-12.12 9.971-6.81 0-12.35-5.532-12.35-12.332 0-6.8 5.54-12.332 12.35-12.332l.091.002V5.565l-.091-.001c-8.142 0-15.797 3.166-21.554 8.915S5.564 27.87 5.564 36s3.171 15.773 8.928 21.522 13.412 8.915 21.554 8.915c8.142 0 15.797-3.166 21.554-8.915 5.196-5.189 8.284-11.921 8.837-19.161H48.166z"/>\n    </g>\n</svg>\n',
  },
  {
    index: 20,
    id: 'a8f6af7057234dfe9d49916ec1f792e7.svg',
    thumbId: 'shape-01-20',
    thumbnail:
      '<svg fill="#fff" viewBox="0 0 72 72" height="72" width="72" xmlns="http://www.w3.org/2000/svg">\n    <g>\n        <path d="M36.075 7.395L4 60.605h17.816l14.236-24.607L50.22 60.605H68L36.075 7.395z"/>\n    </g>\n</svg>\n',
  },
  {
    index: 21,
    id: 'e753c5d3d3f84d089e54418e7ad99f6a.svg',
    thumbId: 'shape-01-21',
    thumbnail:
      '<svg fill="#fff" viewBox="0 0 72 72" height="72" width="72" xmlns="http://www.w3.org/2000/svg">\n    <g>\n        <path style="transform: translateX(100%) scale(-1, 1)" d="M16.529 7.5h34.942v57H33.623V25.432H16.529V7.5z"/>\n    </g>\n</svg>\n',
  },
  {
    index: 22,
    id: 'fae9b72843234469a0564d8d62d22125.svg',
    thumbId: 'shape-01-22',
    thumbnail:
      '<svg fill="#fff" viewBox="0 0 72 72" height="72" width="72" xmlns="http://www.w3.org/2000/svg">\n    <g>\n        <path d="M62.128 31.675L36 5.547 9.872 31.675h16.234v34.778h19.788V31.675h16.234z"/>\n    </g>\n</svg>\n',
  },
  {
    index: 23,
    id: '6cfa70f524284653a74fb4c67937649b.svg',
    thumbId: 'shape-01-23',
    thumbnail:
      '<svg fill="#fff" xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 72 72">\n    <g>\n        <path d="M36.002 22.962l3.931-5.49c2.165-3.024 4.332-6.047 6.49-9.076.17-.239.342-.347.647-.347 6.08.009 12.16.007 18.241.007h.34c-.148.213-.253.371-.364.524-6.516 9.037-13.031 18.075-19.558 27.104-.247.341-.237.555.004.891 6.364 8.88 12.717 17.768 19.071 26.655.104.145.201.295.338.498-.161.014-.268.031-.374.032-5.914.062-11.827.119-17.741.191-.326.004-.503-.107-.686-.361-3.312-4.613-6.634-9.218-9.954-13.825-.125-.174-.256-.343-.426-.57-.137.182-.254.33-.364.483-3.34 4.634-6.68 9.267-10.015 13.904-.163.226-.313.374-.634.37-5.947-.073-11.894-.13-17.841-.192-.082-.001-.163-.011-.32-.023.109-.169.187-.303.277-.429C13.431 54.43 19.796 45.55 26.18 36.684c.32-.444.297-.708-.015-1.139-6.522-9.011-13.026-18.037-19.533-27.06-.084-.117-.159-.241-.283-.429h.547c5.981 0 11.961.003 17.942-.008.358-.001.564.116.769.403 3.334 4.678 6.68 9.348 10.024 14.019.105.144.215.285.371.492z"/>\n    </g>\n</svg>\n',
  },
  {
    index: 24,
    id: '4bc9a1d5c9e4470e8abfdc87132b7952.svg',
    thumbId: 'shape-01-24',
    thumbnail:
      '<svg fill="#fff" viewBox="0 0 72 72" height="72" width="72" xmlns="http://www.w3.org/2000/svg">\n    <g>\n        <path d="M36 67.435c-8.397 0-16.291-3.27-22.228-9.207C7.835 52.291 4.565 44.397 4.565 36c0-8.397 3.27-16.291 9.207-22.228S27.603 4.565 36 4.565c8.397 0 16.291 3.27 22.228 9.207 5.937 5.937 9.207 13.831 9.207 22.228 0 8.397-3.27 16.291-9.207 22.228-5.937 5.937-13.831 9.207-22.228 9.207zm0-47.906c-9.082 0-16.471 7.389-16.471 16.471S26.918 52.471 36 52.471 52.471 45.082 52.471 36 45.082 19.529 36 19.529z"/>\n    </g>\n</svg>\n',
  },
  {
    index: 25,
    id: 'a9215c4b0e6c4fe580319d3242598adf.svg',
    thumbId: 'shape-01-25',
    thumbnail:
      '<svg fill="#fff" viewBox="0 0 72 72" height="72" width="72" xmlns="http://www.w3.org/2000/svg">\n    <g>\n        <path d="M46.253 66.162l-3.79-4.099c-8.12 4.408-14.075 5.259-18.329 5.259-13.224 0-20.107-8.275-20.107-17.014 0-7.889 5.646-12.297 12.606-16.241-2.707-3.635-4.718-7.734-4.718-12.374 0-9.513 8.43-17.014 18.406-17.014 9.822 0 18.097 7.27 18.097 16.241 0 7.192-5.259 11.755-10.518 14.849l6.497 7.038c3.712-3.016 6.96-5.955 9.048-8.043l10.905 10.44c-3.481 3.403-6.729 6.264-9.745 8.662l11.369 12.297H46.253zM32.1 51.004l-7.579-8.198c-3.557 2.243-5.104 4.253-5.104 6.341 0 2.321 1.856 4.022 5.027 4.022 2.242 0 4.872-.851 7.656-2.165zm-2.552-24.052c3.557-2.165 5.181-3.867 5.181-5.723 0-2.088-2.088-3.557-4.176-3.557-2.32 0-4.253 1.779-4.253 3.867 0 1.546 1.005 3.015 3.248 5.413z"/>\n    </g>\n</svg>\n',
  },
  {
    index: 26,
    id: '4641461a029148659a58e0f1fbe62195.svg',
    thumbId: 'shape-01-26',
    thumbnail:
      '<svg fill="#fff" viewBox="0 0 72 72" height="72" width="72" xmlns="http://www.w3.org/2000/svg">\n    <g>\n        <path d="M12.412 18.367h9.899l2.243-12.528h12.761l-2.243 12.528h7.889l2.243-12.528h12.76l-2.243 12.528h7.657v13.302h-9.977l-1.47 8.739h7.657v13.225h-9.977l-2.166 12.529h-12.76l2.166-12.529h-7.889l-2.165 12.529H14.036l2.166-12.529h-7.58V40.408h9.899l1.47-8.739h-7.579V18.367zm18.87 22.041h7.889l1.469-8.739h-7.888l-1.47 8.739z"/>\n    </g>\n</svg>\n',
  },
  {
    index: 27,
    id: '72f99a4575514335b116fea04a2cf903.svg',
    thumbId: 'shape-01-27',
    thumbnail:
      '<svg fill="#fff" viewBox="0 0 72 72" height="72" width="72" xmlns="http://www.w3.org/2000/svg">\n    <g>\n        <path d="M30.857 44.43v.077c0 2.011 1.315 4.718 5.646 4.718 3.325 0 3.712-1.547 3.712-2.166 0-.851-.774-1.779-6.264-4.331-6.419-3.016-17.788-7.502-17.788-17.556 0-6.728 5.182-11.987 12.838-13.92V3.905h13.766v7.502c7.579 2.165 12.838 7.811 12.838 15.699v.464H40.524v-.077c0-2.011-1.16-4.718-5.027-4.718-3.016 0-3.325 1.624-3.325 2.088 0 .851.928 2.165 5.8 4.099s18.252 6.573 18.252 17.71c0 7.193-5.491 12.452-13.457 14.231v7.192H29.001v-7.502c-7.889-2.165-13.225-7.656-13.225-15.7v-.463h15.081z"/>\n    </g>\n</svg>\n',
  },
  {
    index: 28,
    id: 'c156ade1a0c5419593b933eacb9debc8.svg',
    thumbId: 'shape-01-28',
    thumbnail:
      '<svg fill="#fff" viewBox="0 0 72 72" height="72" width="72" xmlns="http://www.w3.org/2000/svg">\n    <g>\n        <path d="M36.003 61.436c9.892-6.356 20.149-13.632 25.528-24.489 2.279-4.599 3.421-9.904 2.392-14.936-1.372-6.709-6.551-12.179-13.617-11.366-6.828.786-9.737 2.776-14.303 7.51h-.007c-4.567-4.734-7.475-6.724-14.303-7.51-7.066-.813-12.244 4.657-13.616 11.366-1.029 5.032.114 10.337 2.392 14.936 5.379 10.856 15.635 18.133 25.528 24.489h.006z"/>\n    </g>\n</svg>\n',
  },
  {
    index: 29,
    id: '877c2faf8d604dd282a7920f8f310bb7.svg',
    thumbId: 'shape-01-29',
    thumbnail:
      '<svg fill="#fff" viewBox="0 0 72 72" height="72" width="72" xmlns="http://www.w3.org/2000/svg">\n    <g>\n        <path d="M56.523 9.549c-6.767 0-12.253 5.487-12.253 12.255 0 .616.047 1.22.134 1.811a20.337 20.337 0 0 0-8.405-1.811c-3.012 0-5.868.657-8.441 1.828.11-.661.171-1.339.171-2.032 0-6.768-5.486-12.255-12.253-12.255S3.224 14.832 3.224 21.6 8.71 33.855 15.477 33.855c.671 0 1.329-.056 1.971-.16a20.354 20.354 0 0 0-1.869 8.534c0 11.281 9.143 20.426 20.421 20.426S56.421 53.51 56.421 42.23a20.35 20.35 0 0 0-1.768-8.312c.61.093 1.234.142 1.87.142 6.767 0 12.253-5.487 12.253-12.255S63.29 9.549 56.523 9.549z"/>\n    </g>\n</svg>\n',
  },
  {
    index: 30,
    id: 'a244381225fe4e538771607be81bbec9.svg',
    thumbId: 'shape-01-30',
    thumbnail:
      '<svg fill="#fff" viewBox="0 0 72 72" height="72" width="72" xmlns="http://www.w3.org/2000/svg">\n    <g>\n        <path d="M32.016 35.834L12.667 16.599c10.74-10.587 28.078-10.56 38.783.082 10.733 10.67 10.733 27.969 0 38.638s-28.135 10.67-38.868 0l-.082-.084 19.516-19.401z"/>\n    </g>\n</svg>',
  },
  {
    index: 31,
    id: 'e8bb85e9af6547b0b4c96e3553bc38a6.svg',
    thumbId: 'shape-01-31',
    thumbnail:
      '<svg fill="#fff" viewBox="0 0 72 72" height="72" width="72" xmlns="http://www.w3.org/2000/svg">\n    <g>\n        <path d="M59.739 11.739L32.88 8.175 7 29.284l16.287 34.541 29.605-4.6L63 39.153l-3.261-27.414z"/>\n    </g>\n</svg>\n',
  },
  {
    index: 32,
    id: 'adb57ae1122b49098d773454be6161a9.svg',
    thumbId: 'shape-01-32',
    thumbnail:
      '<svg fill="#fff" viewBox="0 0 72 72" height="72" width="72" xmlns="http://www.w3.org/2000/svg">\n    <g>\n        <path d="M47.86 60.204l17.655-23.629-11.417-21.183-25.395-8.917L6.515 46.593l19.887 18.984 21.458-5.373z"/>\n    </g>\n</svg>\n',
  },
  {
    index: 33,
    id: 'bfc18cc253b24c21954f53dbd9644fb9.svg',
    thumbId: 'shape-01-33',
    thumbnail:
      '<svg fill="#fff" viewBox="0 0 72 72" height="72" width="72" xmlns="http://www.w3.org/2000/svg">\n    <g>\n        <path d="M65 31.794L37.653 7.363 7 27.789l5.109 26.435 32.756 10.413L65 31.794z"/>\n    </g>\n</svg>\n',
  },
  {
    index: 34,
    id: '7f78de979be645a882019d5ffce8b8ef.svg',
    thumbId: 'shape-01-34',
    thumbnail:
      '<svg fill="#fff" viewBox="0 0 72 72" height="72" width="72" xmlns="http://www.w3.org/2000/svg">\n    <g>\n        <path d="M59.204 53.943a22.707 22.707 0 0 1-4.485 5.774c-9.849 9.114-28.125 6.445-37.11-1.897-5.342-4.96-7.551-11.339-7.562-17.568s2.029-12.37 4.479-18.355c2.045-4.995 4.796-10.319 11.004-13.119 5.14-2.318 11.653-2.421 17.727-2.025 3.769.246 7.65.69 10.814 2.155s5.425 4.197 4.558 6.797c-.63 1.889-2.707 3.337-4.169 4.981-3.13 3.522-3.328 8.243-.497 11.888 2.622 3.377 7.77 6.04 7.969 10.17.175 3.623-.761 7.6-2.728 11.199z"/>\n    </g>\n</svg>\n',
  },
  {
    index: 35,
    id: '3a365dbcf90a4cdb9038ed2c682abc91.svg',
    thumbId: 'shape-01-35',
    thumbnail:
      '<svg fill="#fff" viewBox="0 0 72 72" height="72" width="72" xmlns="http://www.w3.org/2000/svg">\n    <g>\n        <path d="M11.395 21.207c-6.433 9.196-3.25 23.054 1.418 32.263 2.887 5.695 8.111 13.329 17.139 11.88 2.052-.33 3.932-1.176 5.963-1.586 6.297-1.272 13.379 1.734 19.228-.568 2.314-.911 4.091-2.559 5.455-4.349 4.304-5.647 4.824-13.131 1.332-19.159-2.45-4.228-6.694-7.731-8.326-12.219-1.602-4.402-.478-9.189-1.664-13.679-1.443-5.461-7.604-9.376-13.944-6.151-2.395 1.218-4.448 2.855-7.059 3.783-2.718.966-5.733 1.219-8.492 2.107-5.179 1.667-8.736 4.371-11.05 7.678z"/>\n    </g>\n</svg>\n',
  },
  {
    index: 36,
    id: 'dd44237911db45a79ca681bd532aaab9.svg',
    thumbId: 'shape-01-36',
    thumbnail:
      '<svg fill="#fff" viewBox="0 0 72 72" height="72" width="72" xmlns="http://www.w3.org/2000/svg">\n    <g>\n        <path d="M60.165 19.501c-.657-1.746-1.739-3.407-3.287-4.882-4.076-3.882-11.223-6.32-17.763-7.371-17.269-2.774-27.374 9.87-28.042 20.443-.318 5.035 2.509 9.948 2.731 14.998.238 5.419-2.03 10.766-.942 16.222.607 3.041 3.124 4.813 7.307 5.522 12.513 2.12 29.264-.418 35.545-9.2 1.759-2.459 2.532-5.267 2.804-7.998.321-3.224-.43-6.431-.533-9.649-.098-3.064.949-5.991 2.063-8.928 1.127-2.967 1.236-6.185.117-9.157z"/>\n    </g>\n</svg>\n',
  },
];

export default {
  MASKS,
  MASK_THUMB_SIZE: { width: 72, height: 72 },
};
