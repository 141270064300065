import React from 'react';
import * as util from '@/util';
import { ContextMenu, ContextMenuAction } from '@wix/wix-base-ui';
import type { AppData } from '../privateAppsProvider/privateAppsProvider';
import type { Dispatch, MapDispatchToProps } from 'types/redux';
import {
  editInBlocks,
  isTestVersion,
  TEST_VERSION,
} from '../../utils/appDetailsUtils';
import { useAppVersionsListContext } from '../AppVersionsList/AppVersionsListContext';
import { translate } from '@/i18n';
import {
  updateApp,
  type UpdateAppOptions,
  shouldShowUpdateAction,
} from '../../utils/updateApp';
import { uninstallApp } from '../../utils/uninstallApp';
import { isAlphaApp } from '../../utils/utils';
import { privateAppsInstalledAppsContextMenuClick } from '@wix/bi-logger-blocks-consumption/v2';
import experiment from 'experiment';

interface InstalledAppActionsProps extends DispatchProps {
  app: AppData;
  origin: 'app item' | 'app details page';
}

interface DispatchProps {
  uninstallApp: (app: AppData) => Promise<void>;
  updateApp: (updateAppOptions: UpdateAppOptions) => Promise<void>;
  contextMenuProps?: Partial<React.ComponentProps<typeof ContextMenu>>;
}

const InstalledAppActionsComponent: React.FC<InstalledAppActionsProps> = ({
  app,
  uninstallApp,
  updateApp,
  origin,
  contextMenuProps = {},
}) => {
  const { setSelectedVersionListApp } = useAppVersionsListContext();

  const showChangeToTestVersion = () => {
    if (util.appStudioUtils.isAppMajorVersionBuilt(app.version)) {
      if (experiment.isOpen('specs.responsive-blocks.ShareableLink')) {
        return app.canEdit;
      }
      return true;
    }

    return false;
  };

  const biReport = (action: string) =>
    util.biLogger.report(
      privateAppsInstalledAppsContextMenuClick({
        appId: app.appDefinitionId,
        origin,
        action,
        builderType: 'classic',
      }),
    );

  const renderToggleInstalledVersionsButton = (app: AppData) => (
    <ContextMenuAction
      onClick={() => {
        updateApp({
          app,
          version: isTestVersion(app) ? undefined : TEST_VERSION,
        });
        biReport('change to test version');
      }}
    >
      {isTestVersion(app)
        ? translate(
            'blocks-private-apps.AppDiscovery_App_List_Installed_Apps_Context_Menu_Item7',
            {
              versionNumber: util.appStudioUtils.formatVersion(
                app.latestVersion,
              ),
            },
          )
        : translate(
            'blocks-private-apps.AppDiscovery_App_List_Installed_Apps_Context_Menu_Item2',
          )}
    </ContextMenuAction>
  );

  const uninstallAction = (
    <ContextMenuAction
      onClick={() => {
        uninstallApp(app);
        biReport('uninstall');
      }}
    >
      <span>
        {translate(
          'blocks-private-apps.AppDiscovery_App_List_Installed_Apps_Context_Menu_Item4',
        )}
      </span>
    </ContextMenuAction>
  );

  if (
    experiment.isOpen('se_appBuilderSunset_P2') &&
    isAlphaApp(app.blocksVersion)
  ) {
    return <ContextMenu {...contextMenuProps}>{uninstallAction}</ContextMenu>;
  }

  return (
    <ContextMenu {...contextMenuProps}>
      {shouldShowUpdateAction(app) ? (
        <ContextMenuAction
          onClick={() => {
            updateApp({ app });
            biReport('change to test version');
          }}
        >
          {translate(
            'blocks-private-apps.AppDiscovery_App_List_Installed_Apps_Context_Menu_Item5',
            {
              versionNumber: util.appStudioUtils.formatVersion(
                app.latestVersion,
              ),
            },
          )}
        </ContextMenuAction>
      ) : null}
      {!isAlphaApp(app.blocksVersion) ? (
        <ContextMenuAction
          dataHook={'see-all-app-versions'}
          onClick={() => {
            setSelectedVersionListApp(app);
            biReport('see all versions');
          }}
        >
          <span>
            {translate(
              'blocks-private-apps.AppDiscovery_App_List_Installed_Apps_Context_Menu_Item1',
            )}
          </span>
        </ContextMenuAction>
      ) : null}
      {showChangeToTestVersion()
        ? renderToggleInstalledVersionsButton(app)
        : null}
      {app.canEdit && (
        <ContextMenuAction
          onClick={() => {
            editInBlocks(
              app.appDefinitionId,
              util.appStudioUtils.httpReferrerOptions.PrivateAppPanel,
            );
            biReport('edit in blocks');
          }}
        >
          <span>
            {translate(
              'blocks-private-apps.AppDiscovery_App_List_Installed_Apps_Context_Menu_Item3',
            )}
          </span>
        </ContextMenuAction>
      )}
      {uninstallAction}
    </ContextMenu>
  );
};

export const mapDispatchToProps: MapDispatchToProps<DispatchProps, {}> = (
  dispatch: Dispatch,
): DispatchProps => {
  return {
    updateApp: (...updateParams) => dispatch(updateApp(...updateParams)),
    uninstallApp: (app) => dispatch(uninstallApp(app)),
  };
};

export const InstalledAppActions = util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  null,
  mapDispatchToProps,
)(InstalledAppActionsComponent);
