import type { CompRef } from '@wix/document-services-types';
import {
  getAllowedTriggers,
  isAnimatable,
  type Trigger,
} from '@wix/effects-kit';
import {
  type Effect,
  cleanupEffectsAndCompare,
  type PanelEffects,
} from '@wix/motion-effects-panels';
import * as gfppData from '@/gfppData';

import { array } from '@/util';

import type { AnimationType } from '..';
import type { AnimationsScope } from '../scope';
import {
  isLoopEnabled,
  isScrollEnabled,
  getEffectObject,
  getConfig,
} from './utils';

const noSelectionAnimation = { name: '' };

export const createAnimationPanelApi = ({
  editorAPI,
  animationsApiV3: animationsAPI,
}: AnimationsScope) => {
  function getAllowedTypes(compRefs: CompRef[]) {
    const compTypes = compRefs.map((c) =>
      editorAPI.components.getType(c).split('.').at(-1),
    );
    const permissions = getAllowedTriggers(compTypes, {
      editor: compRefs[0].type,
    });
    return {
      entrance: permissions.entrance,
      ongoing: isLoopEnabled() && permissions.ongoing,
      scroll: isScrollEnabled() && permissions.scroll,
    };
  }

  function getComponentAnimations(compRefs: CompRef[]) {
    const allowedTypes = getAllowedTypes(compRefs);
    const allowedT = Object.keys(allowedTypes) as Trigger[]; //.filter( (type) => allowedTypes[type] === true,);

    return compRefs.map((compRef) =>
      allowedT.reduce(
        (acc, animationType) => ({
          ...acc,
          [animationType]: allowedTypes[animationType]
            ? getEffectObject(animationsAPI.get(compRef, animationType))
            : noSelectionAnimation,
        }),
        {} as PanelEffects,
      ),
    );
  }

  function getPanelEffectDataForAllComps(compRefs: CompRef[]) {
    const allComponentsEffects = getComponentAnimations(compRefs);

    const isEffectTheSame = (
      animationType: AnimationType,
      effectData: Effect,
    ) => {
      return cleanupEffectsAndCompare(
        {
          effect: effectData,
          config: getConfig(animationType, effectData.name),
        },
        {
          effect: allComponentsEffects[0][animationType],
          config: getConfig(
            animationType,
            allComponentsEffects[0][animationType].name,
          ),
        },
      );
    };

    const allEffectsAreTheSame = allComponentsEffects.every((effects) =>
      Object.entries(effects).every(([animationType, effectData]) =>
        isEffectTheSame(animationType as AnimationType, effectData),
      ),
    );

    return allEffectsAreTheSame
      ? allComponentsEffects[0]
      : {
          entrance: noSelectionAnimation,
          ongoing: noSelectionAnimation,
          scroll: noSelectionAnimation,
        };
  }

  function componentHasAnimations(effects: PanelEffects) {
    return Object.values(effects).some(
      ({ name }) => name !== 'None' && name !== '',
    );
  }

  function isGfppHighlighted(compRefs: CompRef[]) {
    const allEffects = getComponentAnimations(compRefs);
    return allEffects.every(componentHasAnimations);
  }

  function isGfppDisabled(compRefs: CompRef[] | CompRef) {
    return gfppData.columnsUtils.isBackgroundEffectApplied(editorAPI, compRefs);
  }

  function isGfppShown(compRefs: CompRef[] | CompRef) {
    const editor = editorAPI.isMobileEditor() ? 'MOBILE' : 'DESKTOP';
    const compTypes = array
      .asArray(compRefs)
      .map((c) => editorAPI.components.getType(c).split('.').at(-1));
    return isAnimatable(compTypes, { editor });
  }

  function hasAnimations(compRefs: CompRef[] | CompRef) {
    const allEffects = getComponentAnimations(array.asArray(compRefs));
    return allEffects.some(componentHasAnimations);
  }

  return {
    getPanelEffectDataForAllComps,
    getAllowedTypes,
    isGfppHighlighted,
    isGfppDisabled,
    isGfppShown,
    hasAnimations,
  };
};
