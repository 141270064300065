import React, { useEffect, useState, useReducer } from 'react';
import { Scope } from '../../switchLayoutEntryPoint';
import { connectWithScope, type InferComponentProps } from '@/apilib';
import { Button, Divider, Preloader } from '@wix/wix-base-ui';
import { ScanSwitchLayoutPresetForm } from './scanSwitchLayoutPresetForm';
import styles from './scanSwitchLayoutPreset.scss';
import {
  mapDispatchToProps,
  mapStateToProps,
} from './scanSwitchLayoutPresetPanelMapper';
import { scanSwitchLayoutValuesReducer } from './scanSwitchLayoutValuesReducer';
import {
  imageDataInitialValues,
  textDataInitialValues,
  textFormKeys,
  imageFormKeys,
  formInitialValues,
  MAX_TITLE_DATA_RULES,
  MAX_SUBTITLE_DATA_RULES,
  MAX_PARAGRAPH_DATA_RULES,
  MAX_IMAGE_DATA_RULES,
  booleanRuleKeys,
  falseIncludedNumericRuleKeys,
  stringRuleKeys,
} from './scanSwitchLayoutPresetConsts';
import { ScanSwitchLayoutValuesCategories } from './scanSwitchLayoutPresetTypes';
import {
  cleanLeadingZeros,
  createSectionScanCmsRules,
  enrichSectionScanForm,
  isLeadingZeros,
  validateNumberInput,
  calcInput,
  isCalcInput,
} from './scanSwitchLayoutPresetUtil';

const {
  SET_DATA,
  ADD_RULE,
  REMOVE_RULE,
  UPDATE_RULE,
  UPDATE_GROUP_LAYOUT,
  INITIAL,
  TITLE_DATA,
  SUBTITLE_DATA,
  PARAGRAPH_DATA,
  IMAGE_DATA,
  UPDATE_ID,
} = ScanSwitchLayoutValuesCategories;

interface OwnProps {}

type SectionScanUtilPanelProps = InferComponentProps<
  typeof mapStateToProps,
  typeof mapDispatchToProps,
  OwnProps
>;

export const SectionScanUtilPanelPure: React.FC<SectionScanUtilPanelProps> = (
  props,
) => {
  const {
    selectedSectionSerialized,
    pageId,
    sectionId,
    metaSiteId,
    handleScannedSection,
    getSectionScanData,
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState('');
  const [scanSwitchLayoutValues, scanSwitchLayoutValuesDispatch] = useReducer(
    scanSwitchLayoutValuesReducer,
    formInitialValues,
  );

  useEffect(() => {
    setIsLoading(true);
    setIsError(false);
    setMessage('');
    scanSwitchLayoutValuesDispatch({
      category: INITIAL,
      value: formInitialValues,
    });
    getSectionScanData(pageId, sectionId)
      .then((data) => {
        if (data && data.hasOwnProperty('_id')) {
          const enrichedData = enrichSectionScanForm(data);
          scanSwitchLayoutValuesDispatch({
            category: SET_DATA,
            value: enrichedData,
          });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [getSectionScanData, pageId, sectionId]);

  const handleSubmit = async (e: any): Promise<void> => {
    e.preventDefault();

    if (message) {
      handleCloseMessage();
    }
    const cmsSectionScanRules = createSectionScanCmsRules(
      scanSwitchLayoutValues,
    );
    const { id, groupLayout } = scanSwitchLayoutValues;
    if (!groupLayout.trim()) {
      setIsError(true);
      setMessage('Please enter a Group Layout');
      return;
    }
    const cmsSectionScanData = {
      id,
      title: groupLayout,
      layout: JSON.stringify(selectedSectionSerialized),
      rules: cmsSectionScanRules,
      metaSiteId,
      pageId,
      sectionId,
    };

    const res = await handleScannedSection(cmsSectionScanData);
    if (res.isError) {
      setIsError(true);
    }
    setMessage(res.message);
    if (!cmsSectionScanData.id && res.id) {
      scanSwitchLayoutValuesDispatch({
        category: UPDATE_ID,
        value: res.id,
      });
    }
  };

  const handleInputChange = (rule: string, idx: number, event: any): void => {
    event.persist();

    if (message) {
      handleCloseMessage();
    }

    const { name, type } = event.target;
    const value =
      type === 'checkbox' ? event.target.checked : event.target.value;

    const allowedInputs = [
      ...booleanRuleKeys,
      ...falseIncludedNumericRuleKeys,
      ...stringRuleKeys,
    ];

    if (!allowedInputs.includes(name) && !validateNumberInput(value)) {
      setIsError(true);
      setMessage('Please enter a valid number');
      return;
    }

    scanSwitchLayoutValuesDispatch({
      category: UPDATE_RULE,
      rule,
      idx,
      value,
      name,
    });
  };

  const handleCleanInput = (rule: string, idx: number, event: any): void => {
    const value = event.target.value;
    const { name } = event.target;
    const maybeCleanedValue = isLeadingZeros(value)
      ? cleanLeadingZeros(value)
      : value;
    const maybeCleanedAndCalcedValue = isCalcInput(maybeCleanedValue)
      ? calcInput(maybeCleanedValue)
      : maybeCleanedValue;

    if (isCalcInput(value) || isLeadingZeros(value)) {
      scanSwitchLayoutValuesDispatch({
        category: UPDATE_RULE,
        rule,
        idx,
        value: maybeCleanedAndCalcedValue,
        name,
      });
    }
  };

  const handleAddRule = (rule: string, event: any): void => {
    event.preventDefault();

    if (message) {
      handleCloseMessage();
    }

    const value =
      rule === IMAGE_DATA ? imageDataInitialValues : textDataInitialValues;

    scanSwitchLayoutValuesDispatch({
      category: ADD_RULE,
      rule,
      value,
    });
  };

  const handleRemoveRule = (rule: string, event: any): void => {
    event.preventDefault();

    if (message) {
      handleCloseMessage();
    }

    const idx = scanSwitchLayoutValues[rule].length - 1;
    scanSwitchLayoutValuesDispatch({
      category: REMOVE_RULE,
      rule,
      idx,
    });
  };

  const handleCloseMessage = (): void => {
    setIsError(false);
    setMessage('');
  };

  return isLoading ? (
    <div className={styles.scanSwitchLayoutPresetLoaderContainer}>
      <div className={styles.scanSwitchLayoutPresetLoader}>
        <Preloader className="medium" />
      </div>
    </div>
  ) : (
    <div className={styles.scanSwitchLayoutPresetPanel}>
      {!!message && (
        <div
          className={styles.scanSwitchLayoutPresetMessage}
          style={{ backgroundColor: isError ? '#f44336' : '#4caf50' }}
        >
          <p>{message}</p>
          <span onClick={handleCloseMessage}>X</span>
        </div>
      )}
      <form
        className={styles.scanSwitchLayoutPresetForm}
        onSubmit={handleSubmit}
      >
        <div className={styles.scanSwitchLayoutPresetTitle}>Group Layout</div>
        <input
          type="text"
          value={scanSwitchLayoutValues.groupLayout}
          onChange={(event) =>
            scanSwitchLayoutValuesDispatch({
              category: UPDATE_GROUP_LAYOUT,
              value: event.target.value,
            })
          }
          onFocus={handleCloseMessage}
          className={styles.scanSwitchLayoutPresetInput}
          style={{ maxWidth: '325px' }}
          autoComplete="off"
        />

        <Divider long />

        <div className={styles.scanSwitchLayoutPresetTitle}>
          <span>Title</span>
          <div>
            <Button
              className={`btn-sm ${styles.scanSwitchLayoutPresetTitleButton}`}
              onClick={(event) => handleAddRule(TITLE_DATA, event)}
              disabled={
                scanSwitchLayoutValues.titleData.length >= MAX_TITLE_DATA_RULES
              }
            >
              +
            </Button>
            <Button
              className={`btn-sm btn-danger-primary ${styles.scanSwitchLayoutPresetTitleButton}`}
              onClick={(event) => handleRemoveRule(TITLE_DATA, event)}
              disabled={scanSwitchLayoutValues.titleData.length < 1}
            >
              -
            </Button>
          </div>
        </div>
        {!!scanSwitchLayoutValues.titleData.length &&
          scanSwitchLayoutValues.titleData.map((item: any, idx: number) => (
            <ScanSwitchLayoutPresetForm
              key={`${TITLE_DATA}${idx}`}
              handleChange={handleInputChange}
              handleCloseMessage={handleCloseMessage}
              handleCleanInput={handleCleanInput}
              formState={item}
              rule={TITLE_DATA}
              formKeys={textFormKeys}
              ruleCountIdx={idx}
              ruleCount={scanSwitchLayoutValues.titleData.length}
            />
          ))}

        <Divider long />

        <div className={styles.scanSwitchLayoutPresetTitle}>
          <span>Subtitle</span>
          <div>
            <Button
              className={`btn-sm ${styles.scanSwitchLayoutPresetTitleButton}`}
              onClick={(event) => handleAddRule(SUBTITLE_DATA, event)}
              disabled={
                scanSwitchLayoutValues.subtitleData.length >=
                MAX_SUBTITLE_DATA_RULES
              }
            >
              +
            </Button>
            <Button
              className={`btn-sm btn-danger-primary ${styles.scanSwitchLayoutPresetTitleButton}`}
              onClick={(event) => handleRemoveRule(SUBTITLE_DATA, event)}
              disabled={scanSwitchLayoutValues.subtitleData.length < 1}
            >
              -
            </Button>
          </div>
        </div>
        {!!scanSwitchLayoutValues.subtitleData.length &&
          scanSwitchLayoutValues.subtitleData.map((item: any, idx: number) => (
            <ScanSwitchLayoutPresetForm
              key={`${SUBTITLE_DATA}${idx}`}
              handleChange={handleInputChange}
              handleCloseMessage={handleCloseMessage}
              handleCleanInput={handleCleanInput}
              formState={item}
              rule={SUBTITLE_DATA}
              formKeys={textFormKeys}
              ruleCountIdx={idx}
              ruleCount={scanSwitchLayoutValues.subtitleData.length}
            />
          ))}

        <Divider long />

        <div className={styles.scanSwitchLayoutPresetTitle}>
          <span>Paragraph</span>
          <div>
            <Button
              className={`btn-sm ${styles.scanSwitchLayoutPresetTitleButton}`}
              onClick={(event) => handleAddRule(PARAGRAPH_DATA, event)}
              disabled={
                scanSwitchLayoutValues.paragraphData.length >=
                MAX_PARAGRAPH_DATA_RULES
              }
            >
              +
            </Button>
            <Button
              className={`btn-sm btn-danger-primary ${styles.scanSwitchLayoutPresetTitleButton}`}
              onClick={(event) => handleRemoveRule(PARAGRAPH_DATA, event)}
              disabled={scanSwitchLayoutValues.paragraphData.length < 1}
            >
              -
            </Button>
          </div>
        </div>
        {!!scanSwitchLayoutValues.paragraphData.length &&
          scanSwitchLayoutValues.paragraphData.map((item: any, idx: number) => (
            <ScanSwitchLayoutPresetForm
              key={`${PARAGRAPH_DATA}${idx}`}
              handleChange={handleInputChange}
              handleCloseMessage={handleCloseMessage}
              handleCleanInput={handleCleanInput}
              formState={item}
              rule={PARAGRAPH_DATA}
              formKeys={textFormKeys}
              ruleCountIdx={idx}
              ruleCount={scanSwitchLayoutValues.paragraphData.length}
            />
          ))}

        <Divider long />

        <div className={styles.scanSwitchLayoutPresetTitle}>
          <span>Image</span>
          <div>
            <Button
              className={`btn-sm ${styles.scanSwitchLayoutPresetTitleButton}`}
              onClick={(event) => handleAddRule(IMAGE_DATA, event)}
              disabled={
                scanSwitchLayoutValues.imageData.length >= MAX_IMAGE_DATA_RULES
              }
            >
              +
            </Button>
            <Button
              className={`btn-sm btn-danger-primary ${styles.scanSwitchLayoutPresetTitleButton}`}
              onClick={(event) => handleRemoveRule(IMAGE_DATA, event)}
              disabled={scanSwitchLayoutValues.imageData.length < 1}
            >
              -
            </Button>
          </div>
        </div>
        {!!scanSwitchLayoutValues.imageData.length &&
          scanSwitchLayoutValues.imageData.map((item: any, idx: number) => (
            <ScanSwitchLayoutPresetForm
              key={`${IMAGE_DATA}${idx}`}
              handleChange={handleInputChange}
              handleCloseMessage={handleCloseMessage}
              handleCleanInput={handleCleanInput}
              formState={item}
              rule={IMAGE_DATA}
              formKeys={imageFormKeys}
              ruleCountIdx={idx}
              ruleCount={scanSwitchLayoutValues.imageData.length}
            />
          ))}
        <Divider long />
        <br />
        <br />
        <Button>Save</Button>
      </form>
    </div>
  );
};

export const ScanSwitchLayoutPresetPanel = connectWithScope(
  () => Scope,
  SectionScanUtilPanelPure,
  mapStateToProps,
  mapDispatchToProps,
);
