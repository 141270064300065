import * as util from '@/util';
import * as stateManagement from '@/stateManagement';
import toggleMobileEditor from '../thunks/toggleMobileEditor';

import type { MapStateToProps, MapDispatchToProps } from 'types/redux';
import type { EditorState } from '@/stateManagement';

const { isSiteCreationFullyDone, getIsSessionInitializedWithWizard } =
  stateManagement.siteCreation.selectors;

interface OwnProps {}
export interface DeviceSwitchStateProps {
  isPreviewMode: boolean;
  isMobileEditor: boolean;
  isGoToMobileDisabled: boolean;
  isFixedStageEnabled: boolean;
}
export interface DeviceSwitchDispatchProps {
  toggleMobileEditor: typeof toggleMobileEditor;
}

function isGoToMobileDisabled(state: EditorState): boolean {
  return (
    getIsSessionInitializedWithWizard(state) && !isSiteCreationFullyDone(state)
  );
}

const mapStateToProps: MapStateToProps<DeviceSwitchStateProps, OwnProps> = ({
  editorAPI,
  state,
}) => ({
  isPreviewMode: stateManagement.preview.selectors.getPreviewMode(state),
  isMobileEditor: editorAPI.isMobileEditor(),
  isGoToMobileDisabled: isGoToMobileDisabled(state),
  isFixedStageEnabled: util.fixedStage.isFixedStageEnabled(),
});

const mapDispatchToProps: MapDispatchToProps<
  DeviceSwitchDispatchProps,
  OwnProps
> = {
  toggleMobileEditor,
};

const {
  connect,
  STORES: { EDITOR_API },
} = util.hoc;

export default connect(EDITOR_API, mapStateToProps, mapDispatchToProps);
