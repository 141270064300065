// @ts-nocheck
import constants from '@/constants';

export default {
  allowConnectToDB: true,
  skipInQuickEditPanel: true,
  overridesWhenReferred: {
    gfpp: {
      disableActions: [constants.ROOT_COMPS.GFPP.ACTIONS.DESIGN],
    },
  },
};
