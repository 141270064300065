import constants from '@/constants';
import * as stateManagement from '@/stateManagement';
import * as util from '@/util';
import * as coreBi from '@/coreBi';

import { createSelectionFocusStore } from './createSelectionFocusStore';

import type { CompRef } from 'types/documentServices';
import type { EditorAPI } from '@/editorAPI';
import type { SelectionHooks } from '../createSelectionHooks';
import type { SelectionPopupApi } from '../createSelectionPopupApi';

const MENU_CONTAINER_TYPE = 'wysiwyg.viewer.components.MenuContainer';

export function createSelectionFocusApi({
  editorAPI,
  selectionHooks,
  selectionPopupApi,
}: {
  editorAPI: EditorAPI;
  selectionHooks: SelectionHooks;
  selectionPopupApi: SelectionPopupApi;
}) {
  const selectionSelectors = stateManagement.selection.selectors;
  const componentsSelectors = stateManagement.components.selectors;
  const store = editorAPI.store;
  const selectionFocusStore = createSelectionFocusStore(store);

  function isFocusableAndSelectable(compPointer: CompRef): boolean {
    return (
      (editorAPI.components.is.focusable(compPointer) ||
        selectionSelectors.isFocusableModeContainer(
          compPointer,
          editorAPI.dsRead,
        )) &&
      editorAPI.components.is.selectable(compPointer)
    );
  }

  function getAncestorsMatchingConditionUntilLimit(
    comp: CompRef,
    limitAncestor: CompRef,
    conditionFunction: (ancestorRef: CompRef) => boolean,
  ): CompRef[] {
    const ancestorsMatchingCondition = [];

    let currentAncestor = editorAPI.components.getContainerOrScopeOwner(comp);
    while (
      currentAncestor &&
      !editorAPI.utils.isSameRef(currentAncestor, limitAncestor)
    ) {
      if (conditionFunction(currentAncestor)) {
        ancestorsMatchingCondition.push(currentAncestor);
      }
      currentAncestor =
        editorAPI.components.getContainerOrScopeOwner(currentAncestor);
    }

    return ancestorsMatchingCondition;
  }

  function getFocusableAndSelectableAncestors(
    compToBeSelected: CompRef,
    appContainer: CompRef,
  ): CompRef[] {
    return getAncestorsMatchingConditionUntilLimit(
      compToBeSelected,
      appContainer,
      (ancestorRef) =>
        isFocusableAndSelectable(ancestorRef) &&
        !(
          editorAPI.components.getType(ancestorRef) ===
            constants.COMP_TYPES.CONTAINER &&
          componentsSelectors.isDescendantOfAppWidget(
            ancestorRef,
            editorAPI.dsRead,
          )
        ),
    );
  }

  function getParentToFocus(compRefOrRefs: CompRef | CompRef[]): CompRef {
    return editorAPI.components.findAncestor(
      compRefOrRefs,
      (ancestorRef) => isFocusableAndSelectable(ancestorRef),
      { includeScopeOwner: true, includeSelf: true },
    );
  }

  function keepSpotlightFocused(
    compsToBeSelected: CompRef[],
    comp: CompRef,
  ): boolean {
    const target = util.inlinePopupUtils.getToggleTarget(
      editorAPI,
      compsToBeSelected,
    );
    const toggleOfContainer = target && editorAPI.utils.isSameRef(target, comp);
    const spotlightStageOpen =
      editorAPI.components.is.spotlightStageContainer(comp);
    return (
      (compsToBeSelected.length !== 0 && spotlightStageOpen) ||
      toggleOfContainer
    );
  }

  const handleMenuContainerUnfocused = (
    dsRead: EditorAPI['dsRead'],
    nextFocusedContainer: CompRef,
    currentFocusedContainer: CompRef,
  ): void => {
    const currentFocusedContainerType =
      dsRead.components.is.exist(currentFocusedContainer) &&
      dsRead.components.getType(currentFocusedContainer);
    const shouldCloseInlinePopupContainer =
      currentFocusedContainer &&
      currentFocusedContainerType === MENU_CONTAINER_TYPE;
    if (!nextFocusedContainer && shouldCloseInlinePopupContainer) {
      const mobileActions = stateManagement.mobile.actions;
      const mobileSelectors = stateManagement.mobile.selectors;
      const isUnfocusOriginKeyboard =
        mobileSelectors.mobileKeyboard.getIsCollapsingMenuFromKeyboard(
          editorAPI.store.getState(),
        );

      if (!isUnfocusOriginKeyboard) {
        editorAPI.bi.event(coreBi.events.panels.PANEL_CLOSED, {
          panel_name: 'mobileMenuCollapse',
          origin: 'overlayClick',
        });
      }

      selectionPopupApi.closeInlinePopup(currentFocusedContainer);
      store.dispatch(
        mobileActions.mobileKeyboard.setIsCollapsingMenuFromKeyboard(false),
      );
    }
  };

  function setFocusedContainer(
    nextFocusedContainer: CompRef,
    currentFocusedContainer?: CompRef,
    biParams?: { origin?: string; allGfppActions?: string },
  ) {
    selectionFocusStore.setFocusedContainer(
      nextFocusedContainer,
      currentFocusedContainer,
      handleMenuContainerUnfocused,
      biParams,
    );
  }

  selectionHooks.selectComponentByCompClickInterceptor.tap(
    ({ compToBeSelected }, { cancel }) => {
      if (editorAPI.componentFocusMode.isEnabled() && !compToBeSelected) {
        editorAPI.componentFocusMode.handleBlockingLayerClick();
        cancel();
      }
    },
  );

  return {
    setFocusedContainer,
    getFocusedContainer: selectionFocusStore.getFocusedContainer,
    getFocusableAndSelectableAncestors,
    getParentToFocus,
    keepSpotlightFocused,
  };
}

export type SelectionFocusApi = ReturnType<typeof createSelectionFocusApi>;
