'use strict'

module.exports = {
    id: 'Columns_ColumnsContact_1',
    structure: {
        type: 'Container',
        components: [
            {
                type: 'Container',
                components: [
                    {
                        type: 'Container',
                        components: [
                            {
                                type: 'Container',
                                components: [
                                    {
                                        type: 'Component',
                                        layout: {
                                            width: 320,
                                            height: 27,
                                            x: 6,
                                            y: 221,
                                            scale: 1,
                                            rotationInDegrees: 0,
                                            fixedPosition: false
                                        },
                                        componentType: 'wysiwyg.viewer.components.WRichText',
                                        data: {
                                            type: 'StyledText',
                                            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                                            text: '<p class="font_8" style="font-size:16px; line-height:1.6em; text-align:center"><span style="font-family:bree-w01-thin-oblique,sans-serif;"><span style="font-size:16px"><span style="letter-spacing:0.1em"><span style="color:#000000">info@mysite.com</span></span></span></span></p>\n',
                                            stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
                                            linkList: []
                                        },
                                        props: {
                                            type: 'WRichTextProperties',
                                            metaData: {schemaVersion: '1.0'},
                                            brightness: 1,
                                            packed: true
                                        },
                                        style: 'txtNew',
                                        mobileStructure: {
                                            type: 'Component',
                                            layout: {
                                                width: 200,
                                                height: 27,
                                                x: 60,
                                                y: 121,
                                                scale: 1,
                                                rotationInDegrees: 0,
                                                fixedPosition: false
                                            },
                                            componentType: 'wysiwyg.viewer.components.WRichText',
                                            data: {
                                                type: 'StyledText',
                                                metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                                                text: '<p class="font_8" style="font-size:16px; line-height:1.6em; text-align:center"><span style="font-family:bree-w01-thin-oblique,sans-serif;"><span style="font-size:16px"><span style="letter-spacing:0.1em"><span style="color:#000000">info@mysite.com</span></span></span></span></p>\n',
                                                stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
                                                linkList: []
                                            },
                                            props: {
                                                type: 'WRichTextProperties',
                                                metaData: {schemaVersion: '1.0', autoGenerated: false},
                                                brightness: 1,
                                                packed: true
                                            },
                                            style: 'txtNew'
                                        }
                                    },
                                    {
                                        type: 'Component',
                                        skin: 'svgshape.v2.Svg_58bb40ed125a42be911d795d88a3d570',
                                        layout: {
                                            width: 67,
                                            height: 57,
                                            x: 133,
                                            y: 138,
                                            scale: 1,
                                            rotationInDegrees: 0,
                                            fixedPosition: false
                                        },
                                        componentType: 'wysiwyg.viewer.components.svgshape.SvgShape',
                                        data: {
                                            type: 'SvgShape',
                                            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false}
                                        },
                                        props: {
                                            type: 'SvgShapeProperties',
                                            metaData: {schemaVersion: '1.0'},
                                            maintainAspectRatio: true
                                        },
                                        style: {
                                            type: 'TopLevelStyle',
                                            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                                            style: {
                                                groups: {},
                                                properties: {
                                                    'alpha-fillcolor': '1',
                                                    'alpha-stroke': '1',
                                                    fillcolor: '#4F4F4F',
                                                    stroke: '#4F4F4F',
                                                    strokewidth: '1'
                                                },
                                                propertiesSource: {
                                                    'alpha-fillcolor': 'value',
                                                    fillcolor: 'value',
                                                    stroke: 'value',
                                                    strokewidth: 'value'
                                                }
                                            },
                                            componentClassName: 'wysiwyg.viewer.components.svgshape.SvgShape',
                                            pageId: '',
                                            compId: '',
                                            styleType: 'custom',
                                            skin: 'svgshape.v2.Svg_58bb40ed125a42be911d795d88a3d570'
                                        },
                                        mobileStructure: {
                                            type: 'Component',
                                            skin: 'svgshape.v2.Svg_58bb40ed125a42be911d795d88a3d570',
                                            layout: {
                                                width: 67,
                                                height: 57,
                                                x: 126,
                                                y: 51,
                                                scale: 1,
                                                rotationInDegrees: 0,
                                                fixedPosition: false
                                            },
                                            componentType: 'wysiwyg.viewer.components.svgshape.SvgShape',
                                            data: {
                                                type: 'SvgShape',
                                                metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false}
                                            },
                                            props: {
                                                type: 'SvgShapeProperties',
                                                metaData: {schemaVersion: '1.0'},
                                                maintainAspectRatio: true
                                            },
                                            style: {
                                                type: 'TopLevelStyle',
                                                metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                                                style: {
                                                    groups: {},
                                                    properties: {
                                                        'alpha-fillcolor': '1',
                                                        'alpha-stroke': '1',
                                                        fillcolor: '#4F4F4F',
                                                        stroke: '#4F4F4F',
                                                        strokewidth: '1'
                                                    },
                                                    propertiesSource: {
                                                        'alpha-fillcolor': 'value',
                                                        fillcolor: 'value',
                                                        stroke: 'value',
                                                        strokewidth: 'value'
                                                    }
                                                },
                                                componentClassName: 'wysiwyg.viewer.components.svgshape.SvgShape',
                                                pageId: '',
                                                compId: '',
                                                styleType: 'custom',
                                                skin: 'svgshape.v2.Svg_58bb40ed125a42be911d795d88a3d570'
                                            }
                                        }
                                    }
                                ],
                                layout: {
                                    width: 333,
                                    height: 360,
                                    x: 0,
                                    y: 0,
                                    scale: 1,
                                    rotationInDegrees: 0,
                                    fixedPosition: false
                                },
                                componentType: 'wysiwyg.viewer.components.Column',
                                props: {
                                    type: 'ColumnProperties',
                                    metaData: {schemaVersion: '1.0'},
                                    alignment: 50
                                },
                                design: {
                                    type: 'MediaContainerDesignData',
                                    metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                                    background: {
                                        type: 'BackgroundMedia',
                                        metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                                        mediaRef: {
                                            type: 'Image',
                                            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                                            title: 'Modern Office Kitchen',
                                            uri: '49c1daaca6ff444aa34f5ecc66d8ae1f.jpg',
                                            description: 'public/491df47b-5958-4dc7-9029-37f428755d45/c2a37dfc-36cf-4bfd-b940-55bac7bdc9e6',
                                            width: 1500,
                                            height: 2250,
                                            alt: '',
                                            artist: {
                                                id: 'mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_',
                                                name: ''
                                            },
                                            opacity: 0.14
                                        },
                                        color: '#F0F0F0',
                                        colorOpacity: 1,
                                        alignType: 'center',
                                        fittingType: 'fill',
                                        scrollType: 'none',
                                        colorOverlay: '',
                                        colorOverlayOpacity: 0
                                    }
                                },
                                style: 'mc1',
                                mobileStructure: {
                                    type: 'Container',
                                    layout: {
                                        width: 320,
                                        height: 201,
                                        x: 0,
                                        y: 0,
                                        scale: 1,
                                        rotationInDegrees: 0,
                                        fixedPosition: false
                                    },
                                    componentType: 'wysiwyg.viewer.components.Column',
                                    props: {
                                        type: 'ColumnProperties',
                                        metaData: {schemaVersion: '1.0'},
                                        alignment: 50
                                    },
                                    design: {
                                        type: 'MediaContainerDesignData',
                                        metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                                        background: {
                                            type: 'BackgroundMedia',
                                            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                                            mediaRef: {
                                                type: 'Image',
                                                metaData: {
                                                    isPreset: false,
                                                    schemaVersion: '1.0',
                                                    isHidden: false
                                                },
                                                title: 'Modern Office Kitchen',
                                                uri: '49c1daaca6ff444aa34f5ecc66d8ae1f.jpg',
                                                description: 'public/491df47b-5958-4dc7-9029-37f428755d45/c2a37dfc-36cf-4bfd-b940-55bac7bdc9e6',
                                                width: 1500,
                                                height: 2250,
                                                alt: '',
                                                artist: {
                                                    id: 'mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_',
                                                    name: ''
                                                },
                                                opacity: 0.14
                                            },
                                            color: '#F0F0F0',
                                            colorOpacity: 1,
                                            alignType: 'center',
                                            fittingType: 'fill',
                                            scrollType: 'none',
                                            colorOverlay: '',
                                            colorOverlayOpacity: 0
                                        }
                                    },
                                    style: 'mc1'
                                }
                            },
                            {
                                type: 'Container',
                                components: [
                                    {
                                        type: 'Component',
                                        layout: {
                                            width: 323,
                                            height: 28,
                                            x: 0,
                                            y: 221,
                                            scale: 1,
                                            rotationInDegrees: 0,
                                            fixedPosition: false
                                        },
                                        componentType: 'wysiwyg.viewer.components.WRichText',
                                        data: {
                                            type: 'StyledText',
                                            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                                            text: '<p class="font_8" style="font-size:16px; line-height:1.7em; text-align:center"><span style="font-family:bree-w01-thin-oblique,sans-serif;"><span style="font-size:16px"><span style="letter-spacing:0.1em"><span style="color:#FFFFFF">123-456-7890</span></span></span></span></p>\n',
                                            stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
                                            linkList: []
                                        },
                                        props: {
                                            type: 'WRichTextProperties',
                                            metaData: {schemaVersion: '1.0'},
                                            brightness: 1,
                                            packed: true
                                        },
                                        style: 'txtNew',
                                        mobileStructure: {
                                            type: 'Component',
                                            layout: {
                                                width: 193,
                                                height: 28,
                                                x: 63,
                                                y: 131,
                                                scale: 1,
                                                rotationInDegrees: 0,
                                                fixedPosition: false
                                            },
                                            componentType: 'wysiwyg.viewer.components.WRichText',
                                            data: {
                                                type: 'StyledText',
                                                metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                                                text: '<p class="font_8" style="font-size:16px; line-height:1.7em; text-align:center"><span style="font-family:bree-w01-thin-oblique,sans-serif;"><span style="font-size:16px"><span style="letter-spacing:0.1em"><span style="color:#FFFFFF">123-456-7890</span></span></span></span></p>\n',
                                                stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
                                                linkList: []
                                            },
                                            props: {
                                                type: 'WRichTextProperties',
                                                metaData: {schemaVersion: '1.0', autoGenerated: false},
                                                brightness: 1,
                                                packed: true
                                            },
                                            style: 'txtNew'
                                        }
                                    },
                                    {
                                        type: 'Component',
                                        skin: 'svgshape.v2.Svg_196bf913381d43db84971bea67d95518',
                                        layout: {
                                            width: 67,
                                            height: 66,
                                            x: 128,
                                            y: 122,
                                            scale: 1,
                                            rotationInDegrees: 0,
                                            fixedPosition: false
                                        },
                                        componentType: 'wysiwyg.viewer.components.svgshape.SvgShape',
                                        data: {
                                            type: 'SvgShape',
                                            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false}
                                        },
                                        props: {
                                            type: 'SvgShapeProperties',
                                            metaData: {schemaVersion: '1.0'},
                                            maintainAspectRatio: true
                                        },
                                        style: {
                                            type: 'TopLevelStyle',
                                            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                                            style: {
                                                groups: {},
                                                properties: {
                                                    'alpha-fillcolor': '1',
                                                    'alpha-stroke': '1',
                                                    fillcolor: '#FFFFFF',
                                                    stroke: '#ED1566',
                                                    strokewidth: '0'
                                                },
                                                propertiesSource: {
                                                    fillcolor: 'value',
                                                    stroke: 'value',
                                                    strokewidth: 'value'
                                                }
                                            },
                                            componentClassName: 'wysiwyg.viewer.components.svgshape.SvgShape',
                                            pageId: '',
                                            compId: '',
                                            styleType: 'custom',
                                            skin: 'svgshape.v2.Svg_196bf913381d43db84971bea67d95518'
                                        },
                                        mobileStructure: {
                                            type: 'Component',
                                            skin: 'svgshape.v2.Svg_196bf913381d43db84971bea67d95518',
                                            layout: {
                                                width: 67,
                                                height: 66,
                                                x: 130,
                                                y: 51,
                                                scale: 1,
                                                rotationInDegrees: 0,
                                                fixedPosition: false
                                            },
                                            componentType: 'wysiwyg.viewer.components.svgshape.SvgShape',
                                            data: {
                                                type: 'SvgShape',
                                                metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false}
                                            },
                                            props: {
                                                type: 'SvgShapeProperties',
                                                metaData: {schemaVersion: '1.0'},
                                                maintainAspectRatio: true
                                            },
                                            style: {
                                                type: 'TopLevelStyle',
                                                metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                                                style: {
                                                    groups: {},
                                                    properties: {
                                                        'alpha-fillcolor': '1',
                                                        'alpha-stroke': '1',
                                                        fillcolor: '#FFFFFF',
                                                        stroke: '#ED1566',
                                                        strokewidth: '0'
                                                    },
                                                    propertiesSource: {
                                                        fillcolor: 'value',
                                                        stroke: 'value',
                                                        strokewidth: 'value'
                                                    }
                                                },
                                                componentClassName: 'wysiwyg.viewer.components.svgshape.SvgShape',
                                                pageId: '',
                                                compId: '',
                                                styleType: 'custom',
                                                skin: 'svgshape.v2.Svg_196bf913381d43db84971bea67d95518'
                                            }
                                        }
                                    }
                                ],
                                layout: {
                                    width: 323,
                                    height: 360,
                                    x: 333,
                                    y: 0,
                                    scale: 1,
                                    rotationInDegrees: 0,
                                    fixedPosition: false
                                },
                                componentType: 'wysiwyg.viewer.components.Column',
                                props: {
                                    type: 'ColumnProperties',
                                    metaData: {schemaVersion: '1.0'},
                                    alignment: 50
                                },
                                design: {
                                    type: 'MediaContainerDesignData',
                                    metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                                    background: {
                                        type: 'BackgroundMedia',
                                        metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                                        mediaRef: {
                                            type: 'Image',
                                            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                                            title: 'Modern Kitchen',
                                            uri: 'ff2c0fa76a5347f3b0f298d9feb685de.jpg',
                                            description: 'public/491df47b-5958-4dc7-9029-37f428755d45/47ec63e8-89c6-4665-b7f6-f5be6da222ff',
                                            width: 2000,
                                            height: 1334,
                                            alt: '',
                                            artist: {
                                                id: 'mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_',
                                                name: ''
                                            },
                                            opacity: 0.2
                                        },
                                        color: '#E21C21',
                                        colorOpacity: 1,
                                        alignType: 'center',
                                        fittingType: 'fill',
                                        scrollType: 'none',
                                        colorOverlay: '',
                                        colorOverlayOpacity: 0
                                    }
                                },
                                style: 'mc1',
                                mobileStructure: {
                                    type: 'Container',
                                    layout: {
                                        width: 320,
                                        height: 200,
                                        x: 0,
                                        y: 201,
                                        scale: 1,
                                        rotationInDegrees: 0,
                                        fixedPosition: false
                                    },
                                    componentType: 'wysiwyg.viewer.components.Column',
                                    props: {
                                        type: 'ColumnProperties',
                                        metaData: {schemaVersion: '1.0'},
                                        alignment: 50
                                    },
                                    design: {
                                        type: 'MediaContainerDesignData',
                                        metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                                        background: {
                                            type: 'BackgroundMedia',
                                            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                                            mediaRef: {
                                                type: 'Image',
                                                metaData: {
                                                    isPreset: false,
                                                    schemaVersion: '1.0',
                                                    isHidden: false
                                                },
                                                title: 'Modern Kitchen',
                                                uri: 'ff2c0fa76a5347f3b0f298d9feb685de.jpg',
                                                description: 'public/491df47b-5958-4dc7-9029-37f428755d45/47ec63e8-89c6-4665-b7f6-f5be6da222ff',
                                                width: 2000,
                                                height: 1334,
                                                alt: '',
                                                artist: {
                                                    id: 'mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_mobile_',
                                                    name: ''
                                                },
                                                opacity: 0.2
                                            },
                                            color: '#E21C21',
                                            colorOpacity: 1,
                                            alignType: 'center',
                                            fittingType: 'fill',
                                            scrollType: 'none',
                                            colorOverlay: '',
                                            colorOverlayOpacity: 0
                                        }
                                    },
                                    style: 'mc1'
                                }
                            },
                            {
                                type: 'Container',
                                components: [
                                    {
                                        type: 'Component',
                                        skin: 'wysiwyg.viewer.skins.LinkBarNoBGSkin',
                                        layout: {
                                            width: 61,
                                            height: 60,
                                            x: 131,
                                            y: 134,
                                            scale: 1,
                                            rotationInDegrees: 0,
                                            fixedPosition: false
                                        },
                                        componentType: 'wysiwyg.viewer.components.LinkBar',
                                        data: {
                                            type: 'ImageList',
                                            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                                            items: [
                                                {
                                                    type: 'Image',
                                                    metaData: {isPreset: false, schemaVersion: '2.0', isHidden: false},
                                                    title: 'White Facebook Icon',
                                                    uri: '23fd2a2be53141ed810f4d3dcdcd01fa.png',
                                                    width: 200,
                                                    height: 200,
                                                    alt: ''
                                                }
                                            ]
                                        },
                                        props: {
                                            type: 'LinkBarProperties',
                                            metaData: {schemaVersion: '1.0'},
                                            gallery: 'social_icons',
                                            iconSize: 61,
                                            spacing: 8,
                                            bgScale: 1,
                                            orientation: 'HORIZ'
                                        },
                                        style: 'lb1',
                                        mobileStructure: {
                                            type: 'Component',
                                            skin: 'wysiwyg.viewer.skins.LinkBarNoBGSkin',
                                            layout: {
                                                width: 52,
                                                height: 53,
                                                x: 133,
                                                y: 61,
                                                scale: 1,
                                                rotationInDegrees: 0,
                                                fixedPosition: false
                                            },
                                            componentType: 'wysiwyg.viewer.components.LinkBar',
                                            data: {
                                                type: 'ImageList',
                                                metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                                                items: [
                                                    {
                                                        type: 'Image',
                                                        metaData: {
                                                            isPreset: false,
                                                            schemaVersion: '2.0',
                                                            isHidden: false
                                                        },
                                                        title: 'White Facebook Icon',
                                                        uri: '23fd2a2be53141ed810f4d3dcdcd01fa.png',
                                                        width: 200,
                                                        height: 200,
                                                        alt: ''
                                                    }
                                                ]
                                            },
                                            props: {
                                                type: 'LinkBarProperties',
                                                metaData: {schemaVersion: '1.0', autoGenerated: false},
                                                gallery: 'social_icons',
                                                iconSize: 53,
                                                spacing: 9,
                                                bgScale: 1,
                                                orientation: 'HORIZ'
                                            },
                                            style: 'lb1'
                                        }
                                    },
                                    {
                                        type: 'Component',
                                        layout: {
                                            width: 323,
                                            height: 9,
                                            x: 0,
                                            y: 230,
                                            scale: 1,
                                            rotationInDegrees: 0,
                                            fixedPosition: false
                                        },
                                        componentType: 'wysiwyg.viewer.components.WRichText',
                                        data: {
                                            type: 'StyledText',
                                            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                                            text: '<p class="font_8" style="font-size:16px; line-height:0.5em; text-align:center"><span style="font-family:bree-w01-thin-oblique,sans-serif;"><span style="font-size:16px"><span style="letter-spacing:0.05em"><span style="color:#FFFFFF">Find us on Facebook</span></span></span></span></p>\n',
                                            stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
                                            linkList: []
                                        },
                                        props: {
                                            type: 'WRichTextProperties',
                                            metaData: {schemaVersion: '1.0'},
                                            brightness: 1,
                                            packed: true
                                        },
                                        style: 'txtNew',
                                        mobileStructure: {
                                            type: 'Component',
                                            layout: {
                                                width: 226,
                                                height: 9,
                                                x: 48,
                                                y: 131,
                                                scale: 1,
                                                rotationInDegrees: 0,
                                                fixedPosition: false
                                            },
                                            componentType: 'wysiwyg.viewer.components.WRichText',
                                            data: {
                                                type: 'StyledText',
                                                metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                                                text: '<p class="font_8" style="font-size:16px; line-height:0.5em; text-align:center"><span style="font-family:bree-w01-thin-oblique,sans-serif;"><span style="font-size:16px"><span style="letter-spacing:0.05em"><span style="color:#FFFFFF">Find us on Facebook</span></span></span></span></p>\n',
                                                stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
                                                linkList: []
                                            },
                                            props: {
                                                type: 'WRichTextProperties',
                                                metaData: {schemaVersion: '1.0', autoGenerated: false},
                                                brightness: 1,
                                                packed: true
                                            },
                                            style: 'txtNew'
                                        }
                                    }
                                ],
                                layout: {
                                    width: 323,
                                    height: 360,
                                    x: 657,
                                    y: 0,
                                    scale: 1,
                                    rotationInDegrees: 0,
                                    fixedPosition: false
                                },
                                componentType: 'wysiwyg.viewer.components.Column',
                                props: {
                                    type: 'ColumnProperties',
                                    metaData: {schemaVersion: '1.0'},
                                    alignment: 50
                                },
                                design: {
                                    type: 'MediaContainerDesignData',
                                    metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                                    background: {
                                        type: 'BackgroundMedia',
                                        metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                                        mediaRef: {
                                            type: 'Image',
                                            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                                            title: 'Meeting Room',
                                            uri: 'f22d3579840e47d8bf1a793790603d98.jpg',
                                            description: 'public/491df47b-5958-4dc7-9029-37f428755d45/47ec63e8-89c6-4665-b7f6-f5be6da222ff',
                                            width: 2000,
                                            height: 1334,
                                            alt: '',
                                            artist: {id: 'mobile_mobile_mobile_mobile_', name: ''},
                                            opacity: 0.2
                                        },
                                        color: '#045184',
                                        colorOpacity: 1,
                                        alignType: 'center',
                                        fittingType: 'fill',
                                        scrollType: 'none',
                                        colorOverlay: '',
                                        colorOverlayOpacity: 0
                                    }
                                },
                                style: 'mc1',
                                mobileStructure: {
                                    type: 'Container',
                                    layout: {
                                        width: 320,
                                        height: 204,
                                        x: 0,
                                        y: 401,
                                        scale: 1,
                                        rotationInDegrees: 0,
                                        fixedPosition: false
                                    },
                                    componentType: 'wysiwyg.viewer.components.Column',
                                    props: {
                                        type: 'ColumnProperties',
                                        metaData: {schemaVersion: '1.0'},
                                        alignment: 50
                                    },
                                    design: {
                                        type: 'MediaContainerDesignData',
                                        metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                                        background: {
                                            type: 'BackgroundMedia',
                                            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                                            mediaRef: {
                                                type: 'Image',
                                                metaData: {
                                                    isPreset: false,
                                                    schemaVersion: '1.0',
                                                    isHidden: false
                                                },
                                                title: 'Meeting Room',
                                                uri: 'f22d3579840e47d8bf1a793790603d98.jpg',
                                                description: 'public/491df47b-5958-4dc7-9029-37f428755d45/47ec63e8-89c6-4665-b7f6-f5be6da222ff',
                                                width: 2000,
                                                height: 1334,
                                                alt: '',
                                                artist: {id: 'mobile_mobile_mobile_', name: ''},
                                                opacity: 0.2
                                            },
                                            color: '#045184',
                                            colorOpacity: 1,
                                            alignType: 'center',
                                            fittingType: 'fill',
                                            scrollType: 'none',
                                            colorOverlay: '',
                                            colorOverlayOpacity: 0
                                        }
                                    },
                                    style: 'mc1'
                                }
                            }
                        ],
                        layout: {
                            width: 980,
                            height: 360,
                            x: 0,
                            y: 218,
                            scale: 1,
                            rotationInDegrees: 0,
                            fixedPosition: false
                        },
                        componentType: 'wysiwyg.viewer.components.StripColumnsContainer',
                        props: {
                            type: 'StripColumnsContainerProperties',
                            metaData: {schemaVersion: '1.0'},
                            fullWidth: false,
                            columnsMargin: 0,
                            frameMargin: 0,
                            rowMargin: 0,
                            siteMargin: 0
                        },
                        design: {
                            type: 'MediaContainerDesignData',
                            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                            background: {
                                type: 'BackgroundMedia',
                                metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                                color: '#FFFFFF',
                                colorOpacity: 0,
                                alignType: 'center',
                                fittingType: 'fill',
                                scrollType: 'none',
                                colorOverlay: '',
                                colorOverlayOpacity: 1
                            },
                            charas: 'design-iv109f1b'
                        },
                        style: 'strc1',
                        mobileStructure: {
                            type: 'Container',
                            layout: {
                                width: 320,
                                height: 605,
                                x: 0,
                                y: 189,
                                scale: 1,
                                rotationInDegrees: 0,
                                fixedPosition: false
                            },
                            componentType: 'wysiwyg.viewer.components.StripColumnsContainer',
                            props: {
                                type: 'StripColumnsContainerProperties',
                                metaData: {schemaVersion: '1.0'},
                                fullWidth: false,
                                columnsMargin: 0,
                                frameMargin: 0,
                                rowMargin: 0,
                                siteMargin: 0
                            },
                            design: {
                                type: 'MediaContainerDesignData',
                                metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                                background: {
                                    type: 'BackgroundMedia',
                                    metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                                    color: '#FFFFFF',
                                    colorOpacity: 0,
                                    alignType: 'center',
                                    fittingType: 'fill',
                                    scrollType: 'none',
                                    colorOverlay: '',
                                    colorOverlayOpacity: 1
                                },
                                charas: 'design-iv109f1a'
                            },
                            style: 'strc1'
                        }
                    },
                    {
                        type: 'Component',
                        layout: {
                            width: 680,
                            height: 60,
                            x: 150,
                            y: 70,
                            scale: 1,
                            rotationInDegrees: 0,
                            fixedPosition: false
                        },
                        componentType: 'wysiwyg.viewer.components.WRichText',
                        data: {
                            type: 'StyledText',
                            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                            text: '<h2 class="font_2" style="font-size:46px; text-align:center"><span style="font-size:46px"><span style="color:#D4D4D4"><span style="font-family:helvetica-w01-bold,helvetica-w02-bold,helvetica-lt-w10-bold,sans-serif"><span style="font-weight:bold"><span style="letter-spacing:0.15em">GET IN TOUCH</span></span></span></span></span></h2>\n',
                            stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
                            linkList: []
                        },
                        props: {
                            type: 'WRichTextProperties',
                            metaData: {schemaVersion: '1.0'},
                            brightness: 1,
                            packed: true
                        },
                        style: 'txtNew',
                        mobileStructure: {
                            type: 'Component',
                            layout: {
                                width: 273,
                                height: 84,
                                x: 23,
                                y: 35,
                                scale: 1,
                                rotationInDegrees: 0,
                                fixedPosition: false
                            },
                            componentType: 'wysiwyg.viewer.components.WRichText',
                            data: {
                                type: 'StyledText',
                                metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                                text: '<h2 class="font_2" style="font-size:46px; text-align:center"><span style="font-size:46px"><span style="color:#D4D4D4"><span style="font-family:helvetica-w01-bold,helvetica-w02-bold,helvetica-lt-w10-bold,sans-serif"><span style="font-weight:bold"><span style="letter-spacing:0.15em">GET IN TOUCH</span></span></span></span></span></h2>\n',
                                stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
                                linkList: []
                            },
                            props: {
                                type: 'WRichTextProperties',
                                metaData: {schemaVersion: '1.0', autoGenerated: false},
                                brightness: 1,
                                packed: true
                            },
                            style: 'txtNew'
                        }
                    },
                    {
                        type: 'Component',
                        layout: {
                            width: 680,
                            height: 24,
                            x: 150,
                            y: 139,
                            scale: 1,
                            rotationInDegrees: 0,
                            fixedPosition: false
                        },
                        componentType: 'wysiwyg.viewer.components.WRichText',
                        data: {
                            type: 'StyledText',
                            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                            text: '<h2 class="font_2" style="font-size:20px; line-height:1.2em; text-align:center"><span style="font-size:20px"><span style="font-family:bree-w01-thin-oblique,sans-serif"><span style="letter-spacing:0.08em"><span style="color:#BFBFBF">We&#39;d love to hear from you</span></span></span></span></h2>\n',
                            stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
                            linkList: []
                        },
                        props: {
                            type: 'WRichTextProperties',
                            metaData: {schemaVersion: '1.0'},
                            brightness: 1,
                            packed: true
                        },
                        style: 'txtNew',
                        mobileStructure: {
                            type: 'Component',
                            layout: {
                                width: 319,
                                height: 24,
                                x: 1,
                                y: 129,
                                scale: 1,
                                rotationInDegrees: 0,
                                fixedPosition: false
                            },
                            componentType: 'wysiwyg.viewer.components.WRichText',
                            data: {
                                type: 'StyledText',
                                metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                                text: '<h2 class="font_2" style="font-size:20px; line-height:1.2em; text-align:center"><span style="font-size:20px"><span style="font-family:bree-w01-thin-oblique,sans-serif"><span style="letter-spacing:0.08em"><span style="color:#BFBFBF">We&#39;d love to hear from you</span></span></span></span></h2>\n',
                                stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
                                linkList: []
                            },
                            props: {
                                type: 'WRichTextProperties',
                                metaData: {schemaVersion: '1.0', autoGenerated: false},
                                brightness: 1,
                                packed: true
                            },
                            style: 'txtNew'
                        }
                    }
                ],
                layout: {
                    width: 980,
                    height: 655,
                    x: 0,
                    y: 0,
                    scale: 1,
                    rotationInDegrees: 0,
                    fixedPosition: false
                },
                componentType: 'wysiwyg.viewer.components.Column',
                props: {type: 'ColumnProperties', alignment: 50},
                design: {
                    type: 'MediaContainerDesignData',
                    metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                    background: {
                        type: 'BackgroundMedia',
                        metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                        color: '#FCFCFC',
                        colorOpacity: 1,
                        alignType: 'center',
                        fittingType: 'fill',
                        scrollType: 'none',
                        colorOverlay: '',
                        colorOverlayOpacity: 0
                    }
                },
                style: 'mc1',
                mobileStructure: {
                    type: 'Container',
                    layout: {
                        width: 320,
                        height: 689,
                        x: 0,
                        y: 0,
                        scale: 1,
                        rotationInDegrees: 0,
                        fixedPosition: false
                    },
                    componentType: 'wysiwyg.viewer.components.Column',
                    props: {type: 'ColumnProperties', alignment: 50},
                    design: {
                        type: 'MediaContainerDesignData',
                        metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                        background: {
                            type: 'BackgroundMedia',
                            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                            color: '#FCFCFC',
                            colorOpacity: 1,
                            alignType: 'center',
                            fittingType: 'fill',
                            scrollType: 'none',
                            colorOverlay: '',
                            colorOverlayOpacity: 0
                        }
                    },
                    style: 'mc1'
                }
            }
        ],
        layout: {
            width: 980,
            height: 655,
            x: 0,
            y: 100,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false
        },
        componentType: 'wysiwyg.viewer.components.StripColumnsContainer',
        props: {
            type: 'StripColumnsContainerProperties',
            fullWidth: true,
            columnsMargin: 0,
            frameMargin: 0,
            rowMargin: 0,
            siteMargin: 0
        },
        design: {
            type: 'MediaContainerDesignData',
            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
            background: {
                type: 'BackgroundMedia',
                metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                color: '#FFFFFF',
                colorOpacity: 0,
                alignType: 'center',
                fittingType: 'fill',
                scrollType: 'none',
                colorOverlay: '',
                colorOverlayOpacity: 1
            }
        },
        style: {
            type: 'TopLevelStyle',
            id: 'strc1',
            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
            style: {groups: {}, properties: {}, propertiesSource: {}},
            componentClassName: 'wysiwyg.viewer.components.StripColumnsContainer',
            pageId: '',
            compId: '',
            styleType: 'system',
            skin: 'wysiwyg.viewer.skins.stripContainer.DefaultStripContainer'
        },
        mobileStructure: {
            type: 'Container',
            layout: {
                width: 320,
                height: 689,
                x: 0,
                y: 3102,
                scale: 1,
                rotationInDegrees: 0,
                fixedPosition: false
            },
            componentType: 'wysiwyg.viewer.components.StripColumnsContainer',
            props: {
                type: 'StripColumnsContainerProperties',
                fullWidth: true,
                columnsMargin: 0,
                frameMargin: 0,
                rowMargin: 0,
                siteMargin: 0
            },
            design: {
                type: 'MediaContainerDesignData',
                metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                background: {
                    type: 'BackgroundMedia',
                    metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                    color: '#FFFFFF',
                    colorOpacity: 0,
                    alignType: 'center',
                    fittingType: 'fill',
                    scrollType: 'none',
                    colorOverlay: '',
                    colorOverlayOpacity: 1
                }
            },
            style: 'strc1'
        },
        activeModes: {},
        id: 'comp-iowj895b'
    },
    preset: {
        rect: {width: 324, height: 171, x: -0.8, y: 0},
        label: 'add_preset_columns_contact_1',
        tags: null
    }
}
