export const AUTOMATION_IDS = {
  PANEL: 'multiselect-panel',
  SELECT_ALL: 'multiselect-select-all',
  SECTION_LABEL: 'multiselect-section-label',
  GROUP: 'multiselect-group',
  GROUP_LABEL: 'multiselect-group-label',
  ITEM: 'multiselect-item',
  SUBMIT_BUTTON: 'multiselect-submit-button',
  getSectionAid: (automationId: string) =>
    `multiselect-section ${automationId || ''}`,
};

export const MULTISELECT_PANEL_NAME = 'panels.toolPanels.MultiselectPanel';
