{
  "structure": {
    "comp-jnsts8ic": {
      "componentType": "mobile.core.components.Container",
      "layout": {
        "x": 0,
        "y": 0,
        "fixedPosition": false,
        "width": 500,
        "height": 296,
        "scale": 1,
        "rotationInDegrees": 0
      },
      "type": "Container",
      "id": "comp-jnsts8ic",
      "components": [
        "comp-jnswm1j8",
        "comp-jnu2by66",
        "comp-jnu2c2jy"
      ],
      "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
      "styleId": "style-jnstsebu"
    },
    "comp-jnswm1j8": {
      "parent": "comp-jnsts8ic",
      "layout": {
        "x": 162,
        "y": 200,
        "fixedPosition": false,
        "width": 177.5,
        "height": 52.5,
        "scale": 1,
        "rotationInDegrees": 0
      },
      "previousStyleId": "style-jnlr387o",
      "componentType": "wysiwyg.viewer.components.SiteButton",
      "type": "Component",
      "id": "comp-jnswm1j8",
      "skin": "wysiwyg.viewer.skins.button.BasicButton",
      "dataQuery": "dataItem-jnswm1k9",
      "propertyQuery": "propItem-jnswm1kb",
      "styleId": "style-jnsxfutq",
      "connectionQuery": "connection-jnswby0v"
    },
    "comp-jnu2by66": {
      "layout": {
        "x": 30,
        "y": 35,
        "fixedPosition": false,
        "width": 178,
        "height": 53,
        "scale": 1,
        "rotationInDegrees": 0
      },
      "parent": "comp-jnsts8ic",
      "previousStyleId": "style-jnlr387e",
      "componentType": "wysiwyg.viewer.components.SiteButton",
      "type": "Component",
      "id": "comp-jnu2by66",
      "skin": "wysiwyg.viewer.skins.button.BasicButton",
      "dataQuery": "dataItem-jnu2by72",
      "propertyQuery": "propItem-jnu2by721",
      "styleId": "style-jnu2by73",
      "connectionQuery": "connection-jnu2by731"
    },
    "comp-jnu2c2jy": {
      "parent": "comp-jnsts8ic",
      "id": "comp-jnu2c2jy",
      "type": "Component",
      "skin": "wysiwyg.viewer.skins.WRichTextNewSkin",
      "layout": {
        "x": 230,
        "y": 30,
        "fixedPosition": false,
        "width": 250,
        "height": 53,
        "scale": 1,
        "rotationInDegrees": 0
      },
      "componentType": "wysiwyg.viewer.components.WRichText",
      "dataQuery": "dataItem-jnu2c2kx",
      "propertyQuery": "propItem-jnu2c2nq",
      "style": "txtNew",
      "connectionQuery": "connection-jnu2c2lf"
    }
  },
  "data": {
    "document_data": {
      "dataItem-jnswm1k9": {
        "id": "dataItem-jnswm1k9",
        "type": "LinkableButton",
        "metaData": {
          "isPreset": false,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "label": "Add Text",
        "link": null
      },
      "dataItem-jnu2by72": {
        "id": "dataItem-jnu2by72",
        "type": "LinkableButton",
        "metaData": {
          "isPreset": false,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "label": "Generate Label",
        "link": null
      },
      "dataItem-jnu2c2kx": {
        "id": "dataItem-jnu2c2kx",
        "type": "StyledText",
        "metaData": {
          "isPreset": false,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "text": "<h1 class=\"font_0\" style=\"font-size:48px; text-align:center\"><span style=\"font-weight:bold\"><span style=\"font-size:48px\">Label</span></span></h1>",
        "stylesMapId": "CK_EDITOR_PARAGRAPH_STYLES",
        "linkList": []
      }
    },
    "design_data": {
    },
    "behaviors_data": {
    },
    "connections_data": {
      "connection-jnswby0v": {
        "id": "connection-jnswby0v",
        "type": "ConnectionList",
        "items": [
          {
            "type": "WixCodeConnectionItem",
            "role": "button1"
          }
        ],
        "metaData": {
          "isPreset": false,
          "schemaVersion": "1.0",
          "isHidden": false
        }
      },
      "connection-jnu2by731": {
        "id": "connection-jnu2by731",
        "type": "ConnectionList",
        "items": [
          {
            "type": "WixCodeConnectionItem",
            "role": "button2"
          }
        ],
        "metaData": {
          "isPreset": false,
          "schemaVersion": "1.0",
          "isHidden": false
        }
      },
      "connection-jnu2c2lf": {
        "id": "connection-jnu2c2lf",
        "type": "ConnectionList",
        "items": [
          {
            "type": "WixCodeConnectionItem",
            "role": "text1"
          }
        ],
        "metaData": {
          "isPreset": false,
          "schemaVersion": "1.0",
          "isHidden": false
        }
      }
    },
    "theme_data": {
      "SingleAudioPlayer_1": {
        "type": "TopLevelStyle",
        "id": "SingleAudioPlayer_1",
        "metaData": {
          "isPreset": true,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "style": {
          "properties": {
            "alpha-brd": "1",
            "audioIcons": "color_11",
            "audioText": "color_11",
            "bg": "color_18",
            "boxShadowToggleOn-shd": "false",
            "brd": "color_15",
            "brw": "0",
            "fnt": "font_9",
            "fnts": "font_10",
            "prog": "color_11",
            "rd": "0",
            "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
            "vol": "color_11"
          },
          "propertiesSource": {
            "alpha-brd": "value",
            "audioIcons": "theme",
            "bg": "theme",
            "prog": "theme",
            "vol": "theme"
          },
          "groups": {}
        },
        "componentClassName": "wysiwyg.common.components.singleaudioplayer.viewer.SingleAudioPlayer",
        "pageId": "",
        "compId": "",
        "styleType": "system",
        "skin": "wysiwyg.common.components.singleaudioplayer.viewer.skins.SingleAudioPlayerSkin"
      },
      "THEME_DATA": {
        "type": "WFlatTheme",
        "id": "THEME_DATA",
        "metaData": {
          "isPreset": true,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "color": [
          "#FFFFFF",
          "#FFFFFF",
          "#000000",
          "#ED1C24",
          "#0088CB",
          "#FFCB05",
          "#727272",
          "#B0B0B0",
          "#FFFFFF",
          "#727272",
          "#B0B0B0",
          "#FFFFFF",
          "#E8E6E6",
          "#C7C7C7",
          "#999997",
          "#414141",
          "#D9F0FD",
          "#BEE5FB",
          "#7FCCF7",
          "#40667C",
          "#213D4D",
          "#ADC6F8",
          "#83A8F0",
          "#155DE9",
          "#0E3E9B",
          "#071F4E",
          "#C4AEDD",
          "#9C7FBA",
          "#663898",
          "#442565",
          "#221333",
          "#E5FAD1",
          "#CAE5AF",
          "#9BCB6C",
          "#4E6636",
          "#27331B"
        ],
        "siteBg": "none 0px 0px center top cover no-repeat no-repeat fixed {color_11}",
        "mobileBg": "none 0px 0px center top cover no-repeat no-repeat fixed {color_11}",
        "font": [
          "normal normal normal 40px/1.4em proxima-n-w01-reg {color_15}",
          "normal normal normal 16px/1.4em din-next-w01-light {color_14}",
          "normal normal normal 28px/1.4em proxima-n-w01-reg {color_15}",
          "normal normal normal 60px/1.4em proxima-n-w01-reg {color_15}",
          "normal normal normal 40px/1.4em proxima-n-w01-reg {color_15}",
          "normal normal normal 25px/1.4em proxima-n-w01-reg {color_15}",
          "normal normal normal 22px/1.4em proxima-n-w01-reg {color_15}",
          "normal normal normal 17px/1.4em proxima-n-w01-reg {color_15}",
          "normal normal normal 15px/1.4em proxima-n-w01-reg {color_15}",
          "normal normal normal 14px/1.4em proxima-n-w01-reg {color_15}",
          "normal normal normal 12px/1.4em din-next-w01-light {color_14}"
        ],
        "border": [
          "0.15em solid [color_1]",
          "0.25em solid [color_2]",
          "0.35em solid [color_3]"
        ],
        "padding1": "0 0 0 0",
        "padding2": "0.0em 0.5em 0.0em 0.5em",
        "padding3": "1.0em 0.0em 1.0em 0.0em",
        "iconSize": "3.2",
        "bulletSize": "1.5",
        "headerSpacing": "2.25em",
        "componentSpacing": "0.45em",
        "itemSpacing": "0.75em",
        "thumbSpacing": "0.23em",
        "iconSpacing": "0.75em",
        "themePresets": {
          "mobileBg": true
        },
        "THEME_DIRECTORY": "photography",
        "BG_DIRECTORY": "photography",
        "CONTACT_DIRECTORY": "photography/contact",
        "NETWORKS_DIRECTORY": "photography/network",
        "EXTERNAL_LINKS_DIRECTORY": "photography/external",
        "PAGES_DIRECTORY": "photography/pages",
        "WEB_THEME_DIRECTORY": "viewer",
        "BASE_THEME_DIRECTORY": "base"
      },
      "ap1": {
        "type": "TopLevelStyle",
        "id": "ap1",
        "metaData": {
          "isPreset": true,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "style": {
          "properties": {
            "bg": "color_18",
            "bgh": "color_17",
            "boxShadowToggleOn-shd": "false",
            "brd": "color_15",
            "brdh": "color_15",
            "brw": "0",
            "icon": "color_11",
            "iconh": "color_11",
            "rd": "0",
            "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
          },
          "propertiesSource": {
            "bg": "theme",
            "bgh": "theme",
            "icon": "theme",
            "iconh": "theme"
          },
          "groups": {
          }
        },
        "componentClassName": "wysiwyg.viewer.components.AudioPlayer",
        "pageId": "",
        "compId": "",
        "styleType": "system",
        "skin": "wysiwyg.viewer.skins.audioplayer.SimplePlayer"
      },
      "ap2": {
        "type": "TopLevelStyle",
        "id": "ap2",
        "metaData": {
          "isPreset": true,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "style": {
          "properties": {
            "bg": "color_18",
            "bgh": "color_17",
            "boxShadowToggleOn-shd": "false",
            "brd": "color_15",
            "brdh": "color_15",
            "brw": "0",
            "icon": "color_11",
            "iconh": "color_11",
            "rd": "0",
            "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
            "shd1": "inset 0 1px 4px 1px rgba(0, 0, 0, 0.2)"
          },
          "propertiesSource": {
            "bg": "theme",
            "bgh": "theme",
            "icon": "theme",
            "iconh": "theme"
          },
          "groups": {
          }
        },
        "componentClassName": "wysiwyg.viewer.components.AudioPlayer",
        "pageId": "",
        "compId": "",
        "styleType": "system",
        "skin": "wysiwyg.viewer.skins.audioplayer.ShinyPlayer"
      },
      "b1": {
        "type": "TopLevelStyle",
        "id": "b1",
        "metaData": {
          "isPreset": true,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "style": {
          "properties": {
            "alpha-bg": "1",
            "alpha-bgh": "1",
            "alpha-brd": "1",
            "alpha-brdh": "0",
            "alpha-txt": "1",
            "alpha-txth": "1",
            "bg": "color_18",
            "bgh": "color_17",
            "boxShadowToggleOn-shd": "false",
            "brd": "color_14",
            "brdh": "color_12",
            "brw": "0",
            "fnt": "font_8",
            "rd": "0px",
            "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
            "txt": "color_8",
            "txth": "color_11"
          },
          "propertiesSource": {
            "alpha-brdh": "value",
            "bg": "theme",
            "bgh": "theme",
            "brd": "theme",
            "brdh": "theme",
            "brw": "value",
            "fnt": "theme",
            "rd": "value",
            "shd": "value",
            "txt": "theme",
            "txth": "theme"
          },
          "groups": {}
        },
        "componentClassName": "",
        "pageId": "",
        "compId": "",
        "styleType": "system",
        "skin": "wysiwyg.viewer.skins.button.BasicButton"
      },
      "b2": {
        "type": "TopLevelStyle",
        "id": "b2",
        "metaData": {
          "isPreset": true,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "style": {
          "properties": {
            "alpha-txt": "1",
            "alpha-txth": "1",
            "fnt": "font_8",
            "txt": "color_15",
            "txth": "color_14"
          },
          "propertiesSource": {
            "fnt": "theme",
            "txt": "theme",
            "txth": "theme"
          },
          "groups": {}
        },
        "componentClassName": "",
        "pageId": "",
        "compId": "",
        "styleType": "system",
        "skin": "wysiwyg.viewer.skins.button.TextOnlyButtonSkin"
      },
      "b3": {
        "type": "TopLevelStyle",
        "id": "b3",
        "metaData": {
          "isPreset": true,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "style": {
          "properties": {
            "alpha-bg": "1",
            "alpha-bgh": "1",
            "alpha-brd": "1",
            "alpha-brdh": "1",
            "alpha-txt": "1",
            "alpha-txth": "1",
            "bg": "color_18",
            "bgh": "color_17",
            "boxShadowToggleOn-shd": "false",
            "brd": "color_12",
            "brdh": "color_14",
            "brw": "0",
            "fnt": "font_8",
            "rd": "0px",
            "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
            "txt": "color_11",
            "txth": "color_11"
          },
          "propertiesSource": {
            "bg": "theme",
            "bgh": "theme",
            "brd": "theme",
            "brdh": "theme",
            "brw": "value",
            "fnt": "theme",
            "rd": "value",
            "shd": "value",
            "txt": "theme",
            "txth": "theme"
          },
          "groups": {}
        },
        "componentClassName": "",
        "pageId": "",
        "compId": "",
        "styleType": "system",
        "skin": "wysiwyg.viewer.skins.button.ShinyButtonISkin"
      },
      "b4": {
        "type": "TopLevelStyle",
        "id": "b4",
        "metaData": {
          "isPreset": true,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "style": {
          "properties": {
            "alpha-bg": "1",
            "alpha-bgh": "1",
            "alpha-brd": "1",
            "alpha-brdh": "1",
            "alpha-txt": "1",
            "alpha-txth": "1",
            "bg": "color_18",
            "bgh": "color_17",
            "brd": "color_11",
            "brdh": "color_11",
            "brw": "0",
            "fnt": "font_8",
            "rd": "5px",
            "txt": "color_11",
            "txth": "color_11"
          },
          "propertiesSource": {
            "bg": "theme",
            "bgh": "theme",
            "brd": "theme",
            "brdh": "theme",
            "brw": "value",
            "fnt": "theme",
            "rd": "value",
            "txt": "theme",
            "txth": "theme"
          },
          "groups": {
          }
        },
        "componentClassName": "",
        "pageId": "",
        "compId": "",
        "styleType": "system",
        "skin": "wysiwyg.viewer.skins.button.ButtonThreeD"
      },
      "bgis1": {
        "type": "TopLevelStyle",
        "id": "bgis1",
        "metaData": {
          "isPreset": true,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "style": {
          "properties": {
            "alpha-bg": "1",
            "alpha-brd": "1",
            "bg": "color_12",
            "boxShadowToggleOn-shd": "false",
            "brd": "color_15",
            "brwb": "0px",
            "brwt": "0px",
            "shd": "0 0 5px rgba(0, 0, 0, 0.7)"
          },
          "propertiesSource": {
            "bg": "theme",
            "brd": "theme",
            "brwb": "value",
            "brwt": "value",
            "shd": "value"
          },
          "groups": {
          }
        },
        "componentClassName": "",
        "pageId": "",
        "compId": "",
        "styleType": "system",
        "skin": "skins.viewer.bgimagestrip.BgImageStripSkin"
      },
      "bgis2": {
        "type": "TopLevelStyle",
        "id": "bgis2",
        "metaData": {
          "isPreset": true,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "style": {
          "properties": {
            "alpha-bg": "1",
            "alpha-brd": "1",
            "bg": "color_13",
            "boxShadowToggleOn-shd": "false",
            "brd": "color_15",
            "brwb": "0px",
            "brwt": "0px",
            "shd": "0 0 5px rgba(0, 0, 0, 0.7)"
          },
          "propertiesSource": {
            "bg": "theme",
            "brd": "theme",
            "brwb": "value",
            "brwt": "value",
            "shd": "value"
          },
          "groups": {}
        },
        "componentClassName": "",
        "pageId": "",
        "compId": "",
        "styleType": "system",
        "skin": "skins.viewer.bgimagestrip.BgImageStripSkin"
      },
      "bgis3": {
        "type": "TopLevelStyle",
        "id": "bgis3",
        "metaData": {
          "isPreset": true,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "style": {
          "properties": {
            "alpha-bg": "1",
            "alpha-brd": "1",
            "bg": "color_14",
            "boxShadowToggleOn-shd": "false",
            "brd": "color_15",
            "brwb": "0px",
            "brwt": "0px",
            "shd": "0 0 5px rgba(0, 0, 0, 0.7)"
          },
          "propertiesSource": {
            "bg": "theme",
            "brd": "theme",
            "brwb": "value",
            "brwt": "value",
            "shd": "value"
          },
          "groups": {}
        },
        "componentClassName": "",
        "pageId": "",
        "compId": "",
        "styleType": "system",
        "skin": "skins.viewer.bgimagestrip.BgImageStripSkin"
      },
      "bgis4": {
        "type": "TopLevelStyle",
        "id": "bgis4",
        "metaData": {
          "isPreset": true,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "style": {
          "properties": {
            "alpha-bg": "1",
            "alpha-brd": "1",
            "bg": "color_15",
            "boxShadowToggleOn-shd": "false",
            "brd": "color_15",
            "brwb": "0px",
            "brwt": "0px",
            "shd": "0 0 5px rgba(0, 0, 0, 0.7)"
          },
          "propertiesSource": {
            "bg": "theme",
            "brd": "theme",
            "brwb": "value",
            "brwt": "value",
            "shd": "value"
          },
          "groups": {}
        },
        "componentClassName": "",
        "pageId": "",
        "compId": "",
        "styleType": "system",
        "skin": "skins.viewer.bgimagestrip.BgImageStripSkin"
      },
      "blog_33a9f5e0-b083-4ccc-b55d-3ca5d241a6eb_1": {
        "type": "TopLevelStyle",
        "id": "blog_33a9f5e0-b083-4ccc-b55d-3ca5d241a6eb_1",
        "metaData": {
          "isPreset": true,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "style": {
          "properties": {
            "rd": "7px"
          },
          "propertiesSource": {
            "rd": "value"
          },
          "groups": {}
        },
        "componentClassName": "",
        "pageId": "",
        "compId": "",
        "styleType": "system",
        "skin": "wysiwyg.viewer.skins.AppPartSkin"
      },
      "blog_4656a63c-b316-4315-ab16-e2003f5935ca_1": {
        "type": "TopLevelStyle",
        "id": "blog_4656a63c-b316-4315-ab16-e2003f5935ca_1",
        "metaData": {
          "isPreset": true,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "style": {
          "properties": {
            "rd": "7px"
          },
          "propertiesSource": {
            "rd": "value"
          },
          "groups": {}
        },
        "componentClassName": "",
        "pageId": "",
        "compId": "",
        "styleType": "system",
        "skin": "wysiwyg.viewer.skins.AppPartSkin"
      },
      "blog_4de5abc5-6da2-4f97-acc3-94bb74285072_1": {
        "type": "TopLevelStyle",
        "id": "blog_4de5abc5-6da2-4f97-acc3-94bb74285072_1",
        "metaData": {
          "isPreset": true,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "style": {
          "properties": {
            "rd": "7px"
          },
          "propertiesSource": {
            "rd": "value"
          },
          "groups": {}
        },
        "componentClassName": "",
        "pageId": "",
        "compId": "",
        "styleType": "system",
        "skin": "wysiwyg.viewer.skins.AppPartSkin"
      },
      "blog_56ab6fa4-95ac-4391-9337-6702b8a77011_1": {
        "type": "TopLevelStyle",
        "id": "blog_56ab6fa4-95ac-4391-9337-6702b8a77011_1",
        "metaData": {
          "isPreset": true,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "style": {
          "properties": {
            "rd": "7px"
          },
          "propertiesSource": {
            "rd": "value"
          },
          "groups": {
          }
        },
        "componentClassName": "",
        "pageId": "",
        "compId": "",
        "styleType": "system",
        "skin": "wysiwyg.viewer.skins.AppPartSkin"
      },
      "blog_e000b4bf-9ff1-4e66-a0d3-d4b365ba3af5_1": {
        "type": "TopLevelStyle",
        "id": "blog_e000b4bf-9ff1-4e66-a0d3-d4b365ba3af5_1",
        "metaData": {
          "isPreset": true,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "style": {
          "properties": {
            "rd": "7px"
          },
          "propertiesSource": {
            "rd": "value"
          },
          "groups": {}
        },
        "componentClassName": "",
        "pageId": "",
        "compId": "",
        "styleType": "system",
        "skin": "wysiwyg.viewer.skins.AppPartSkin"
      },
      "blog_f72fe377-8abc-40f2-8656-89cfe00f3a22_1": {
        "type": "TopLevelStyle",
        "id": "blog_f72fe377-8abc-40f2-8656-89cfe00f3a22_1",
        "metaData": {
          "isPreset": true,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "style": {
          "properties": {
            "rd": "7px"
          },
          "propertiesSource": {
            "rd": "value"
          },
          "groups": {}
        },
        "componentClassName": "",
        "pageId": "",
        "compId": "",
        "styleType": "system",
        "skin": "wysiwyg.viewer.skins.AppPartSkin"
      },
      "c1": {
        "type": "TopLevelStyle",
        "id": "c1",
        "metaData": {
          "isPreset": true,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "style": {
          "properties": {
            "alpha-bg": "1",
            "alpha-brd": "1",
            "bg": "color_11",
            "boxShadowToggleOn-shd": "false",
            "brd": "color_15",
            "brw": "0",
            "rd": "0px",
            "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
          },
          "propertiesSource": {
            "bg": "theme",
            "brd": "theme",
            "brw": "value",
            "rd": "value",
            "shd": "value"
          },
          "groups": {}
        },
        "componentClassName": "",
        "pageId": "",
        "compId": "",
        "styleType": "system",
        "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
      },
      "c2": {
        "type": "TopLevelStyle",
        "id": "c2",
        "metaData": {
          "isPreset": true,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "style": {
          "properties": {
            "alpha-bg": "1",
            "alpha-brd": "1",
            "bg": "color_15",
            "boxShadowToggleOn-shd": "false",
            "brd": "color_14",
            "brw": "0px",
            "rd": "0px",
            "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
          },
          "propertiesSource": {
            "bg": "theme",
            "brd": "theme",
            "brw": "value",
            "rd": "value",
            "shd": "value"
          },
          "groups": {
          }
        },
        "componentClassName": "",
        "pageId": "",
        "compId": "",
        "styleType": "system",
        "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin"
      },
      "c3": {
        "type": "TopLevelStyle",
        "id": "c3",
        "metaData": {
          "isPreset": true,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "style": {
          "properties": {
            "alpha-bg": "1",
            "bg": "color_18"
          },
          "propertiesSource": {
            "bg": "theme"
          },
          "groups": {}
        },
        "componentClassName": "",
        "pageId": "",
        "compId": "",
        "styleType": "system",
        "skin": "wysiwyg.viewer.skins.area.RectangleArea"
      },
      "c4": {
        "type": "TopLevelStyle",
        "id": "c4",
        "metaData": {
          "isPreset": true,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "style": {
          "properties": {
            "alpha-bg": "1",
            "bg": "color_28"
          },
          "propertiesSource": {
            "bg": "theme"
          },
          "groups": {}
        },
        "componentClassName": "",
        "pageId": "",
        "compId": "",
        "styleType": "system",
        "skin": "wysiwyg.viewer.skins.area.RectangleArea"
      },
      "ca1": {
        "type": "TopLevelStyle",
        "id": "ca1",
        "metaData": {
          "isPreset": true,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "style": {
          "properties": {},
          "propertiesSource": {},
          "groups": {}
        },
        "componentClassName": "",
        "pageId": "",
        "compId": "",
        "styleType": "system",
        "skin": "wysiwyg.viewer.skins.photo.NoSkinPhoto"
      },
      "cf1": {
        "type": "TopLevelStyle",
        "id": "cf1",
        "metaData": {
          "isPreset": true,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "style": {
          "properties": {
            "alpha-bg1": "1",
            "alpha-bg2": "1",
            "alpha-brd": "1",
            "alpha-labelTxt": "1",
            "alpha-txt1": "1",
            "alpha-txt2": "1",
            "alpha-txtError": "1",
            "alpha-txtSuccess": "1",
            "bg1": "color_11",
            "bg2": "color_18",
            "boxShadowToggleOn-shd": "false",
            "brd": "color_15",
            "brw": "1px",
            "fnt": "font_8",
            "fnt2": "font_8",
            "labelTxt": "color_15",
            "rd": "0px",
            "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
            "txt1": "color_15",
            "txt2": "color_11",
            "txtError": "rgba(139,0,0,1)",
            "txtSuccess": "rgba(186,218,85,1)"
          },
          "propertiesSource": {
            "bg1": "theme",
            "bg2": "theme",
            "brd": "theme",
            "brw": "value",
            "fnt": "theme",
            "fnt2": "theme",
            "labelTxt": "theme",
            "rd": "value",
            "shd": "value",
            "txt1": "theme",
            "txt2": "theme",
            "txtError": "value",
            "txtSuccess": "value"
          },
          "groups": {}
        },
        "componentClassName": "",
        "pageId": "",
        "compId": "",
        "styleType": "system",
        "skin": "wysiwyg.viewer.skins.contactform.DefaultContactForm"
      },
      "cf2": {
        "type": "TopLevelStyle",
        "id": "cf2",
        "metaData": {
          "isPreset": true,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "style": {
          "properties": {
            "alpha-bg1": "1",
            "alpha-bg2": "1",
            "alpha-brd": "1",
            "alpha-labelTxt": "1",
            "alpha-txt1": "1",
            "alpha-txt2": "1",
            "alpha-txtError": "1",
            "alpha-txtSuccess": "1",
            "bg1": "color_11",
            "bg2": "color_18",
            "boxShadowToggleOn-shd": "false",
            "brd": "color_15",
            "brw": "1px",
            "fnt": "font_7",
            "fnt2": "font_7",
            "labelTxt": "color_15",
            "rd": "0px",
            "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
            "txt1": "color_15",
            "txt2": "color_11",
            "txtError": "rgba(139,0,0,1)",
            "txtSuccess": "rgba(186,218,85,1)"
          },
          "propertiesSource": {
            "bg1": "theme",
            "bg2": "theme",
            "brd": "theme",
            "brw": "value",
            "fnt": "theme",
            "fnt2": "theme",
            "labelTxt": "theme",
            "rd": "value",
            "shd": "value",
            "txt1": "theme",
            "txt2": "theme",
            "txtError": "value",
            "txtSuccess": "value"
          },
          "groups": {}
        },
        "componentClassName": "",
        "pageId": "",
        "compId": "",
        "styleType": "system",
        "skin": "wysiwyg.viewer.skins.contactform.BasicContactFormSkin"
      },
      "controller1": {
        "type": "TopLevelStyle",
        "id": "controller1",
        "metaData": {
          "isPreset": false,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "style": {
          "properties": {},
          "propertiesSource": {
          },
          "groups": {}
        },
        "componentClassName": "platform.components.AppController",
        "pageId": "",
        "compId": "",
        "styleType": "system",
        "skin": "platform.components.skins.controllerSkin"
      },
      "ddm1": {
        "type": "TopLevelStyle",
        "id": "ddm1",
        "metaData": {
          "isPreset": true,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "style": {
          "properties": {
            "alpha-bgDrop": "1",
            "alpha-txt": "1",
            "alpha-txth": "1",
            "alpha-txts": "1",
            "bgDrop": "color_11",
            "boxShadowToggleOn-shd": "false",
            "fnt": "normal normal normal 16px/1.4em proxima-n-w01-reg",
            "pad": "5px",
            "rd": "0px",
            "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
            "txt": "color_15",
            "txth": "color_18",
            "txts": "color_18"
          },
          "propertiesSource": {
            "bgDrop": "theme",
            "fnt": "value",
            "pad": "value",
            "rd": "value",
            "shd": "value",
            "txt": "theme",
            "txth": "theme",
            "txts": "theme"
          },
          "groups": {
          }
        },
        "componentClassName": "",
        "pageId": "",
        "compId": "",
        "styleType": "system",
        "skin": "wysiwyg.common.components.dropdownmenu.viewer.skins.TextOnlyMenuButtonSkin"
      },
      "ddm2": {
        "type": "TopLevelStyle",
        "id": "ddm2",
        "metaData": {
          "isPreset": true,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "style": {
          "properties": {
            "alpha-bg": "1",
            "alpha-bgDrop": "1",
            "alpha-bgh": "1",
            "alpha-bgs": "1",
            "alpha-brd": "1",
            "alpha-sep": "1",
            "alpha-txt": "1",
            "alpha-txth": "1",
            "alpha-txts": "1",
            "bg": "color_11",
            "bgDrop": "color_11",
            "bgh": "color_18",
            "bgs": "color_18",
            "boxShadowToggleOn-shd": "false",
            "brd": "color_11",
            "brw": "0px",
            "fnt": "normal normal normal 16px/1.4em proxima-n-w01-reg",
            "pad": "5px",
            "rd": "0px",
            "rdDrop": "0px",
            "sep": "color_15",
            "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
            "txt": "color_15",
            "txth": "color_11",
            "txts": "color_11"
          },
          "propertiesSource": {
            "alpha-bg": "value",
            "bg": "theme",
            "bgDrop": "theme",
            "bgh": "theme",
            "bgs": "theme",
            "brd": "theme",
            "brw": "value",
            "fnt": "value",
            "pad": "value",
            "rd": "value",
            "rdDrop": "value",
            "sep": "theme",
            "shd": "value",
            "txt": "theme",
            "txth": "theme",
            "txts": "theme"
          },
          "groups": {}
        },
        "componentClassName": "",
        "pageId": "",
        "compId": "",
        "styleType": "system",
        "skin": "wysiwyg.common.components.dropdownmenu.viewer.skins.SolidColorMenuButtonSkin"
      },
      "ddm3": {
        "type": "TopLevelStyle",
        "id": "ddm3",
        "metaData": {
          "isPreset": true,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "style": {
          "properties": {
            "alpha-bgDrop": "1",
            "alpha-brd": "1",
            "alpha-brdh": "1",
            "alpha-brds": "1",
            "bgDrop": "color_11",
            "boxShadowToggleOn-shd": "false",
            "brd": "color_15",
            "brdh": "color_18",
            "brds": "color_18",
            "fnt": "normal normal normal 16px/1.4em proxima-n-w01-reg",
            "menuTotalBordersX": "0",
            "pad": "5px",
            "rd": "0px",
            "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
            "txt": "color_15",
            "txth": "color_18",
            "txts": "color_18"
          },
          "propertiesSource": {
            "alpha-bgDrop": "value",
            "alpha-brd": "value",
            "alpha-brdh": "value",
            "alpha-brds": "value",
            "bgDrop": "theme",
            "boxShadowToggleOn-shd": "value",
            "brd": "theme",
            "brdh": "theme",
            "brds": "theme",
            "fnt": "value",
            "menuTotalBordersX": "value",
            "pad": "value",
            "rd": "value",
            "shd": "value",
            "txt": "theme",
            "txth": "theme",
            "txts": "theme"
          },
          "groups": {
          }
        },
        "componentClassName": "",
        "pageId": "",
        "compId": "",
        "styleType": "system",
        "skin": "wysiwyg.common.components.dropdownmenu.viewer.skins.OverlineMenuButtonSkin"
      },
      "eib1": {
        "type": "TopLevelStyle",
        "id": "eib1",
        "metaData": {
          "isPreset": true,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "style": {
          "properties": {
            "alpha-backgroundColor": "1",
            "alpha-borderColor": "1",
            "alpha-fontColor": "1",
            "alpha-headerColor": "1",
            "alpha-linkColor": "1",
            "backgroundColor": "color_1",
            "borderColor": "color_4",
            "brightness-backgroundColor": "0",
            "brightness-borderColor": "0",
            "brightness-fontColor": "0",
            "brightness-headerColor": "0",
            "brightness-linkColor": "0",
            "fontColor": "color_9",
            "headerColor": "color_5",
            "linkColor": "color_2",
            "saturation-backgroundColor": "0",
            "saturation-borderColor": "0",
            "saturation-fontColor": "0",
            "saturation-headerColor": "0",
            "saturation-linkColor": "0"
          }
        },
        "componentClassName": "",
        "pageId": "",
        "compId": "",
        "styleType": "system",
        "skin": "wysiwyg.viewer.skins.EbayItemsBySellerSkin"
      },
      "fbc1": {
        "type": "TopLevelStyle",
        "id": "fbc1",
        "metaData": {
          "isPreset": true,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "style": {
          "properties": {
          },
          "propertiesSource": {
          },
          "groups": {
          }
        },
        "componentClassName": "",
        "pageId": "",
        "compId": "",
        "styleType": "system",
        "skin": "skins.core.FacebookCommentSkin"
      },
      "fbl1": {
        "type": "TopLevelStyle",
        "id": "fbl1",
        "metaData": {
          "isPreset": true,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "style": {
          "properties": {},
          "propertiesSource": {
          },
          "groups": {
          }
        },
        "componentClassName": "",
        "pageId": "",
        "compId": "",
        "styleType": "system",
        "skin": "skins.core.FacebookLikeSkin"
      },
      "fc1": {
        "type": "TopLevelStyle",
        "id": "fc1",
        "metaData": {
          "isPreset": true,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "style": {
          "properties": {},
          "propertiesSource": {},
          "groups": {}
        },
        "componentClassName": "",
        "pageId": "",
        "compId": "",
        "styleType": "system",
        "skin": "wysiwyg.viewer.skins.screenwidthcontainer.TransparentScreen"
      },
      "fc2": {
        "type": "TopLevelStyle",
        "id": "fc2",
        "metaData": {
          "isPreset": true,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "style": {
          "properties": {
            "alpha-bg": "1",
            "alpha-bgctr": "1",
            "alpha-brd": "1",
            "bg": "color_13",
            "bgctr": "color_13",
            "boxShadowToggleOn-shd": "false",
            "brd": "color_14",
            "brwb": "0px",
            "brwt": "5px",
            "rd": "0",
            "shd": "0 0 5px rgba(0, 0, 0, 0.7)"
          },
          "propertiesSource": {
            "bg": "theme",
            "bgctr": "theme",
            "brd": "theme",
            "brwb": "value",
            "brwt": "value",
            "rd": "value",
            "shd": "value"
          },
          "groups": {}
        },
        "componentClassName": "",
        "pageId": "",
        "compId": "",
        "styleType": "system",
        "skin": "wysiwyg.viewer.skins.screenwidthcontainer.DefaultScreen"
      },
      "fc3": {
        "type": "TopLevelStyle",
        "id": "fc3",
        "metaData": {
          "isPreset": true,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "style": {
          "properties": {
            "alpha-bg": "0",
            "alpha-bgctr": "1",
            "alpha-brd": "1",
            "bg": "color_12",
            "bgctr": "color_13",
            "boxShadowToggleOn-shd": "false",
            "brd": "color_13",
            "brwb": "0px",
            "brwt": "0px",
            "rd": "0px",
            "shd": "0 0 5px rgba(0, 0, 0, 0.7)"
          },
          "propertiesSource": {
            "bg": "theme",
            "bgctr": "theme",
            "brd": "theme",
            "brwb": "value",
            "brwt": "value",
            "rd": "value",
            "shd": "value"
          },
          "groups": {}
        },
        "componentClassName": "",
        "pageId": "",
        "compId": "",
        "styleType": "system",
        "skin": "wysiwyg.viewer.skins.screenwidthcontainer.DefaultScreen"
      },
      "fk1": {
        "type": "TopLevelStyle",
        "id": "fk1",
        "metaData": {
          "isPreset": true,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "style": {
          "properties": {
            "alpha-backgroundColor": "0",
            "alpha-borderColor": "0",
            "backgroundColor": "color_6",
            "borderColor": "color_1",
            "brightness-backgroundColor": "0",
            "brightness-borderColor": "0",
            "saturation-backgroundColor": "0",
            "saturation-borderColor": "0"
          }
        },
        "componentClassName": "",
        "pageId": "",
        "compId": "",
        "styleType": "system",
        "skin": "wysiwyg.viewer.skins.FlickrBadgeWidgetSkin"
      },
      "gm1": {
        "type": "TopLevelStyle",
        "id": "gm1",
        "metaData": {
          "isPreset": true,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "style": {
          "properties": {
          },
          "propertiesSource": {},
          "groups": {
          }
        },
        "componentClassName": "",
        "pageId": "",
        "compId": "",
        "styleType": "system",
        "skin": "wysiwyg.viewer.skins.GoogleMapSkin"
      },
      "gp1": {
        "type": "TopLevelStyle",
        "id": "gp1",
        "metaData": {
          "isPreset": true,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "style": {
          "properties": {
          },
          "propertiesSource": {
          },
          "groups": {
          }
        },
        "componentClassName": "",
        "pageId": "",
        "compId": "",
        "styleType": "system",
        "skin": "skins.core.GooglePlusOneSkin"
      },
      "hc1": {
        "type": "TopLevelStyle",
        "id": "hc1",
        "metaData": {
          "isPreset": true,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "style": {
          "properties": {},
          "propertiesSource": {
          },
          "groups": {
          }
        },
        "componentClassName": "",
        "pageId": "",
        "compId": "",
        "styleType": "system",
        "skin": "wysiwyg.viewer.skins.screenwidthcontainer.TransparentScreen"
      },
      "hc2": {
        "type": "TopLevelStyle",
        "id": "hc2",
        "metaData": {
          "isPreset": true,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "style": {
          "properties": {
            "alpha-bg": "1",
            "alpha-bgctr": "1",
            "alpha-brd": "1",
            "bg": "color_11",
            "bgctr": "color_11",
            "boxShadowToggleOn-shd": "false",
            "brd": "color_13",
            "brwb": "5px",
            "brwt": "0px",
            "rd": "0",
            "shd": "0 0 5px rgba(0, 0, 0, 0.7)"
          },
          "propertiesSource": {
            "bg": "theme",
            "bgctr": "theme",
            "brd": "theme",
            "brwb": "value",
            "brwt": "value",
            "rd": "value",
            "shd": "value"
          },
          "groups": {}
        },
        "componentClassName": "",
        "pageId": "",
        "compId": "",
        "styleType": "system",
        "skin": "wysiwyg.viewer.skins.screenwidthcontainer.DefaultScreen"
      },
      "hc3": {
        "type": "TopLevelStyle",
        "id": "hc3",
        "metaData": {
          "isPreset": true,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "style": {
          "properties": {
            "alpha-bg": "1",
            "alpha-bgctr": "1",
            "alpha-brd": "1",
            "bg": "color_12",
            "bgctr": "color_12",
            "boxShadowToggleOn-shd": "false",
            "brd": "color_12",
            "brwb": "0px",
            "brwt": "0px",
            "rd": "0",
            "shd": "0 0 5px rgba(0, 0, 0, 0.7)"
          },
          "propertiesSource": {
            "bg": "theme",
            "bgctr": "theme",
            "brd": "theme",
            "brwb": "value",
            "brwt": "value",
            "rd": "value",
            "shd": "value"
          },
          "groups": {}
        },
        "componentClassName": "",
        "pageId": "",
        "compId": "",
        "styleType": "system",
        "skin": "wysiwyg.viewer.skins.screenwidthcontainer.DefaultScreen"
      },
      "hl1": {
        "type": "TopLevelStyle",
        "id": "hl1",
        "metaData": {
          "isPreset": true,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "style": {
          "properties": {
            "alpha-brd": "1",
            "brd": "color_13",
            "lnw": "1px"
          },
          "propertiesSource": {
            "brd": "theme",
            "lnw": "value"
          },
          "groups": {}
        },
        "componentClassName": "",
        "pageId": "",
        "compId": "",
        "styleType": "system",
        "skin": "wysiwyg.viewer.skins.line.SolidLine"
      },
      "hl2": {
        "type": "TopLevelStyle",
        "id": "hl2",
        "metaData": {
          "isPreset": true,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "style": {
          "properties": {
            "alpha-brd": "1",
            "brd": "color_14",
            "lnw": "1px"
          },
          "propertiesSource": {
            "brd": "theme",
            "lnw": "value"
          },
          "groups": {}
        },
        "componentClassName": "",
        "pageId": "",
        "compId": "",
        "styleType": "system",
        "skin": "wysiwyg.viewer.skins.line.SolidLine"
      },
      "hl3": {
        "type": "TopLevelStyle",
        "id": "hl3",
        "metaData": {
          "isPreset": true,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "style": {
          "properties": {
            "alpha-brd": "1",
            "brd": "color_15",
            "lnw": "3"
          },
          "propertiesSource": {
            "brd": "theme",
            "lnw": "value"
          },
          "groups": {}
        },
        "componentClassName": "",
        "pageId": "",
        "compId": "",
        "styleType": "system",
        "skin": "wysiwyg.viewer.skins.line.SolidLine"
      },
      "hl4": {
        "type": "TopLevelStyle",
        "id": "hl4",
        "metaData": {
          "isPreset": true,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "style": {
          "properties": {
          },
          "propertiesSource": {},
          "groups": {
          }
        },
        "componentClassName": "",
        "pageId": "",
        "compId": "",
        "styleType": "system",
        "skin": "wysiwyg.viewer.skins.line.NotchDashedLine"
      },
      "htco1": {
        "type": "TopLevelStyle",
        "id": "htco1",
        "metaData": {
          "isPreset": true,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "style": {
          "properties": {
          },
          "propertiesSource": {
          },
          "groups": {
          }
        },
        "componentClassName": "",
        "pageId": "",
        "compId": "",
        "styleType": "system",
        "skin": "wysiwyg.viewer.skins.HtmlComponentSkin"
      },
      "lb1": {
        "type": "TopLevelStyle",
        "id": "lb1",
        "metaData": {
          "isPreset": true,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "style": {
          "groups": {
            "displayer": {
            }
          }
        },
        "componentClassName": "",
        "pageId": "",
        "compId": "",
        "styleType": "system",
        "skin": "wysiwyg.viewer.skins.LinkBarNoBGSkin"
      },
      "lgn0": {
        "type": "TopLevelStyle",
        "id": "lgn0",
        "metaData": {
          "isPreset": true,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "style": {
          "properties": {
            "fnt": "font_7",
            "txt": "color_15",
            "txth": "color_14"
          },
          "propertiesSource": {},
          "groups": {}
        },
        "componentClassName": "wysiwyg.viewer.components.LoginButton",
        "pageId": "",
        "compId": "",
        "styleType": "system",
        "skin": "wysiwyg.viewer.skins.button.LoginButtonSkin"
      },
      "mg1": {
        "type": "TopLevelStyle",
        "id": "mg1",
        "metaData": {
          "isPreset": true,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "style": {
          "properties": {
            "alpha-bgh": "0.8",
            "alpha-brd": "1",
            "alpha-ttl": "1",
            "alpha-txt": "1",
            "bgh": "color_11",
            "boxShadowToggleOn-shd": "false",
            "brd": "color_13",
            "brw": "5px",
            "fntds": "font_8",
            "fntt": "font_6",
            "rd": "0px",
            "shd": "0 1px 4px rgba(0, 0, 0, 0.6);",
            "ttl": "color_14",
            "txt": "color_14"
          },
          "propertiesSource": {
            "bgh": "theme",
            "brd": "theme",
            "brw": "value",
            "fntds": "theme",
            "fntt": "theme",
            "rd": "value",
            "shd": "value",
            "ttl": "theme",
            "txt": "theme"
          },
          "groups": {}
        },
        "componentClassName": "",
        "pageId": "",
        "compId": "",
        "styleType": "system",
        "skin": "wysiwyg.viewer.skins.gallerymatrix.MatrixGalleryDefaultSkin"
      },
      "mg2": {
        "type": "TopLevelStyle",
        "id": "mg2",
        "metaData": {
          "isPreset": true,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "style": {
          "properties": {
            "alpha-bgh": "0.95",
            "alpha-brd": "1",
            "alpha-ttl": "1",
            "alpha-txt": "1",
            "bgh": "color_11",
            "boxShadowToggleOn-shd": "false",
            "brd": "color_11",
            "brw": "0px",
            "fntds": "font_9",
            "fntt": "font_8",
            "rd": "0px",
            "shd": "0 1px 4px rgba(0, 0, 0, 0.6);",
            "ttl": "color_14",
            "txt": "color_14"
          },
          "propertiesSource": {
            "bgh": "theme",
            "brd": "theme",
            "brw": "value",
            "fntds": "theme",
            "fntt": "theme",
            "rd": "value",
            "shd": "value",
            "ttl": "theme",
            "txt": "theme"
          },
          "groups": {}
        },
        "componentClassName": "",
        "pageId": "",
        "compId": "",
        "styleType": "system",
        "skin": "wysiwyg.viewer.skins.gallerymatrix.MatrixGalleryDefaultSkin"
      },
      "mg3": {
        "type": "TopLevelStyle",
        "id": "mg3",
        "metaData": {
          "isPreset": true,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "style": {
          "properties": {
            "alpha-bg": "1",
            "alpha-bgh": "0.7",
            "alpha-ttl": "1",
            "alpha-txt": "1",
            "bg": "color_13",
            "bgh": "color_11",
            "boxShadowToggleOn-shd": "false",
            "brw": "5px",
            "fntds": "font_8",
            "fntt": "font_6",
            "rd": "0px",
            "shd": "0 1px 4px rgba(0, 0, 0, 0.6);",
            "ttl": "color_11",
            "txt": "color_11"
          },
          "propertiesSource": {
            "bg": "theme",
            "bgh": "theme",
            "brw": "value",
            "fntds": "theme",
            "fntt": "theme",
            "rd": "value",
            "shd": "value",
            "ttl": "theme",
            "txt": "theme"
          },
          "groups": {
          }
        },
        "componentClassName": "",
        "pageId": "",
        "compId": "",
        "styleType": "system",
        "skin": "wysiwyg.viewer.skins.gallerymatrix.MatrixGalleryPolaroidSkin"
      },
      "p1": {
        "type": "TopLevelStyle",
        "id": "p1",
        "metaData": {
          "isPreset": true,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "style": {
          "properties": {},
          "propertiesSource": {
          },
          "groups": {
          }
        },
        "componentClassName": "",
        "pageId": "",
        "compId": "",
        "styleType": "system",
        "skin": "wysiwyg.viewer.skins.page.TransparentPageSkin"
      },
      "p2": {
        "type": "TopLevelStyle",
        "id": "p2",
        "metaData": {
          "isPreset": true,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "style": {
          "properties": {
            "alpha-bg": "1",
            "bg": "color_11"
          },
          "propertiesSource": {
            "bg": "theme"
          },
          "groups": {}
        },
        "componentClassName": "",
        "pageId": "",
        "compId": "",
        "styleType": "system",
        "skin": "wysiwyg.viewer.skins.page.BasicPageSkin"
      },
      "p3": {
        "type": "TopLevelStyle",
        "id": "p3",
        "metaData": {
          "isPreset": true,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "style": {
          "properties": {
            "alpha-bg": "1",
            "bg": "color_16"
          },
          "propertiesSource": {
            "bg": "theme"
          },
          "groups": {}
        },
        "componentClassName": "",
        "pageId": "",
        "compId": "",
        "styleType": "system",
        "skin": "wysiwyg.viewer.skins.page.BasicPageSkin"
      },
      "pagg1": {
        "type": "TopLevelStyle",
        "id": "pagg1",
        "metaData": {
          "isPreset": true,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "style": {
          "properties": {
            "alpha-bg": "0.8",
            "alpha-bgh": "1",
            "alpha-linkColor": "1",
            "alpha-txt": "1",
            "bg": "color_11",
            "bgh": "color_12",
            "fntds": "font_8",
            "fntt": "font_6",
            "linkColor": "color_15",
            "txt": "color_15"
          },
          "propertiesSource": {
            "bg": "theme",
            "bgh": "theme",
            "fntds": "theme",
            "fntt": "theme",
            "linkColor": "theme",
            "txt": "theme"
          },
          "groups": {
          }
        },
        "componentClassName": "",
        "pageId": "",
        "compId": "",
        "styleType": "system",
        "skin": "wysiwyg.viewer.skins.paginatedgrid.PaginatedGridOverlay"
      },
      "pagg2": {
        "type": "TopLevelStyle",
        "id": "pagg2",
        "metaData": {
          "isPreset": true,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "style": {
          "properties": {
            "alpha-bg": "0.8",
            "alpha-linkColor": "1",
            "alpha-txt": "1",
            "bg": "color_11",
            "fntds": "font_8",
            "fntt": "font_6",
            "linkColor": "color_15",
            "txt": "color_15"
          },
          "propertiesSource": {
            "bg": "theme",
            "fntds": "theme",
            "fntt": "theme",
            "linkColor": "theme",
            "txt": "theme"
          },
          "groups": {
          }
        },
        "componentClassName": "",
        "pageId": "",
        "compId": "",
        "styleType": "system",
        "skin": "wysiwyg.viewer.skins.paginatedgrid.PaginatedGridArrowsOutside"
      },
      "pagg3": {
        "type": "TopLevelStyle",
        "id": "pagg3",
        "metaData": {
          "isPreset": true,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "style": {
          "properties": {
            "alpha-bg": "0.6",
            "alpha-bg1": "1",
            "alpha-linkColor": "1",
            "alpha-txt": "1",
            "bg": "color_12",
            "bg1": "color_13",
            "fntds": "font_8",
            "fntt": "font_6",
            "linkColor": "color_15",
            "txt": "color_15"
          },
          "propertiesSource": {
            "bg": "theme",
            "bg1": "theme",
            "fntds": "theme",
            "fntt": "theme",
            "linkColor": "theme",
            "txt": "theme"
          },
          "groups": {}
        },
        "componentClassName": "",
        "pageId": "",
        "compId": "",
        "styleType": "system",
        "skin": "wysiwyg.viewer.skins.paginatedgrid.PaginatedGridRibbonArrows"
      },
      "pc1": {
        "type": "TopLevelStyle",
        "id": "pc1",
        "metaData": {
          "isPreset": true,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "style": {
          "properties": {
          },
          "propertiesSource": {},
          "groups": {
          }
        },
        "componentClassName": "",
        "pageId": "",
        "compId": "",
        "styleType": "system",
        "skin": "wysiwyg.viewer.skins.screenwidthcontainer.BlankScreen"
      },
      "pc2": {
        "type": "TopLevelStyle",
        "id": "pc2",
        "metaData": {
          "isPreset": true,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "style": {
          "properties": {
            "alpha-bg": "1",
            "alpha-bgctr": "1",
            "alpha-brd": "1",
            "bg": "color_11",
            "bgctr": "color_11",
            "boxShadowToggleOn-shd": "true",
            "brd": "color_15",
            "brwb": "0px",
            "brwt": "0px",
            "shd": "0 0 5px rgba(0, 0, 0, 0.7)"
          }
        },
        "componentClassName": "",
        "pageId": "",
        "compId": "",
        "styleType": "system",
        "skin": "wysiwyg.viewer.skins.screenwidthcontainer.DefaultScreen"
      },
      "sc1": {
        "type": "TopLevelStyle",
        "id": "sc1",
        "metaData": {
          "isPreset": true,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "style": {
          "properties": {
            "alpha-bg": "1",
            "alpha-bgctr": "1",
            "alpha-brd": "1",
            "bg": "color_15",
            "bgctr": "color_15",
            "boxShadowToggleOn-shd": "false",
            "brd": "color_14",
            "brwb": "0px",
            "brwt": "0px",
            "shd": "0 0 5px rgba(0, 0, 0, 0.7)"
          }
        },
        "componentClassName": "",
        "pageId": "",
        "compId": "",
        "styleType": "system",
        "skin": "wysiwyg.viewer.skins.screenwidthcontainer.DefaultScreen"
      },
      "sc2": {
        "type": "TopLevelStyle",
        "id": "sc2",
        "metaData": {
          "isPreset": true,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "style": {
          "properties": {
            "alpha-bg": "1",
            "alpha-bgctr": "1",
            "alpha-brd": "1",
            "bg": "color_11",
            "bgctr": "color_11",
            "boxShadowToggleOn-shd": "true",
            "brd": "color_14",
            "brwb": "0px",
            "brwt": "0px",
            "shd": "0 0 5px rgba(0, 0, 0, 0.7)"
          }
        },
        "componentClassName": "",
        "pageId": "",
        "compId": "",
        "styleType": "system",
        "skin": "wysiwyg.viewer.skins.screenwidthcontainer.DefaultScreen"
      },
      "sc3": {
        "type": "TopLevelStyle",
        "id": "sc3",
        "metaData": {
          "isPreset": true,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "style": {
          "properties": {
            "alpha-bg": "1",
            "alpha-brd": "1",
            "bg": "color_10",
            "brd": "color_14",
            "brwb": "0px",
            "brwt": "0px"
          }
        },
        "componentClassName": "",
        "pageId": "",
        "compId": "",
        "styleType": "system",
        "skin": "wysiwyg.viewer.skins.screenwidthcontainer.BevelScreen"
      },
      "sc4": {
        "type": "TopLevelStyle",
        "id": "sc4",
        "metaData": {
          "isPreset": true,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "style": {
          "properties": {
            "alpha-bg": "1",
            "alpha-bgctr": "1",
            "alpha-brd": "1",
            "bg": "color_10",
            "bgctr": "color_10",
            "boxShadowToggleOn-shd": "false",
            "brd": "color_12",
            "brwb": "2px",
            "brwt": "2px",
            "shd": "0 0 5px rgba(0, 0, 0, 0.7)"
          }
        },
        "componentClassName": "",
        "pageId": "",
        "compId": "",
        "styleType": "system",
        "skin": "wysiwyg.viewer.skins.screenwidthcontainer.DefaultScreen"
      },
      "scw1": {
        "type": "TopLevelStyle",
        "id": "scw1",
        "metaData": {
          "isPreset": true,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "style": {
          "properties": {},
          "propertiesSource": {},
          "groups": {}
        },
        "componentClassName": "",
        "pageId": "",
        "compId": "",
        "styleType": "system",
        "skin": "wysiwyg.viewer.skins.SoundCloudWidgetSkin"
      },
      "sg1": {
        "type": "TopLevelStyle",
        "id": "sg1",
        "metaData": {
          "isPreset": true,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "style": {
          "properties": {
            "alpha-bg": "0.6",
            "alpha-brd": "1",
            "alpha-txt": "1",
            "bg": "color_12",
            "brd": "color_6",
            "brw": "5px",
            "fntds": "font_8",
            "rd": "0px",
            "txt": "color_15"
          },
          "propertiesSource": {
            "bg": "theme",
            "brd": "theme",
            "brw": "value",
            "fntds": "theme",
            "rd": "value",
            "txt": "theme"
          },
          "groups": {}
        },
        "componentClassName": "",
        "pageId": "",
        "compId": "",
        "styleType": "system",
        "skin": "wysiwyg.viewer.skins.galleryslider.SliderGalleryDefaultSkin"
      },
      "sg2": {
        "type": "TopLevelStyle",
        "id": "sg2",
        "metaData": {
          "isPreset": true,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "style": {
          "properties": {
            "alpha-bg": "0.6",
            "alpha-brd": "1",
            "alpha-txt": "1",
            "bg": "color_12",
            "brd": "color_14",
            "brw": "0px",
            "fntds": "font_8",
            "rd": "0px",
            "txt": "color_15"
          },
          "propertiesSource": {
            "bg": "theme",
            "brd": "theme",
            "brw": "value",
            "fntds": "theme",
            "rd": "value",
            "txt": "theme"
          },
          "groups": {}
        },
        "componentClassName": "",
        "pageId": "",
        "compId": "",
        "styleType": "system",
        "skin": "wysiwyg.viewer.skins.galleryslider.SliderGalleryDefaultSkin"
      },
      "sg3": {
        "type": "TopLevelStyle",
        "id": "sg3",
        "metaData": {
          "isPreset": true,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "style": {
          "properties": {
            "alpha-bg": "0.6",
            "alpha-brd": "1",
            "alpha-txt": "1",
            "bg": "color_11",
            "brd": "color_12",
            "brw": "10px",
            "fntds": "font_8",
            "rd": "0px",
            "txt": "color_14"
          },
          "propertiesSource": {
            "bg": "theme",
            "brd": "theme",
            "brw": "value",
            "fntds": "theme",
            "rd": "value",
            "txt": "theme"
          },
          "groups": {}
        },
        "componentClassName": "",
        "pageId": "",
        "compId": "",
        "styleType": "system",
        "skin": "wysiwyg.viewer.skins.galleryslider.SliderGalleryNoArrow"
      },
      "ssg1": {
        "type": "TopLevelStyle",
        "id": "ssg1",
        "metaData": {
          "isPreset": true,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "style": {
          "properties": {
            "alpha-bg": "0.7",
            "alpha-brd": "1",
            "alpha-txt": "1",
            "bg": "color_11",
            "boxShadowToggleOn-shd": "false",
            "brd": "color_13",
            "brw": "5px",
            "fntds": "font_8",
            "fntt": "font_6",
            "rd": "0px",
            "shd": "0 1px 3px rgba(0, 0, 0, 0.5);",
            "txt": "color_15"
          },
          "propertiesSource": {
            "bg": "theme",
            "brd": "theme",
            "brw": "value",
            "fntds": "theme",
            "fntt": "theme",
            "rd": "value",
            "shd": "value",
            "txt": "theme"
          },
          "groups": {}
        },
        "componentClassName": "",
        "pageId": "",
        "compId": "",
        "styleType": "system",
        "skin": "wysiwyg.viewer.skins.gallery.SlideShowTextOverlay"
      },
      "ssg2": {
        "type": "TopLevelStyle",
        "id": "ssg2",
        "metaData": {
          "isPreset": true,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "style": {
          "properties": {
            "alpha-bg": "0.8",
            "alpha-brd": "1",
            "alpha-txt": "1",
            "bg": "color_11",
            "boxShadowToggleOn-shd": "false",
            "brd": "color_11",
            "brw": "0px",
            "fntds": "font_8",
            "fntt": "font_6",
            "rd": "0px",
            "shd": "0px 1px 3px 0px rgba(0,0,0,0.5)",
            "txt": "color_14"
          },
          "propertiesSource": {
            "bg": "theme",
            "brd": "theme",
            "brw": "value",
            "fntds": "theme",
            "fntt": "theme",
            "rd": "value",
            "shd": "value",
            "txt": "theme"
          },
          "groups": {}
        },
        "componentClassName": "",
        "pageId": "",
        "compId": "",
        "styleType": "system",
        "skin": "wysiwyg.viewer.skins.gallery.SlideShowTextOverlay"
      },
      "ssg3": {
        "type": "TopLevelStyle",
        "id": "ssg3",
        "metaData": {
          "isPreset": true,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "style": {
          "properties": {
            "alpha-bg": "1",
            "alpha-txt": "1",
            "bg": "color_12",
            "boxShadowToggleOn-shd": "false",
            "brw": "5px",
            "fntds": "font_8",
            "fntt": "font_6",
            "rd": "0px",
            "shd": "0px 1px 3px 0px rgba(0,0,0,0.5)",
            "txt": "color_15"
          },
          "propertiesSource": {
            "bg": "theme",
            "brw": "value",
            "fntds": "theme",
            "fntt": "theme",
            "rd": "value",
            "shd": "value",
            "txt": "theme"
          },
          "groups": {
          }
        },
        "componentClassName": "",
        "pageId": "",
        "compId": "",
        "styleType": "system",
        "skin": "wysiwyg.viewer.skins.gallery.SlideShowPolaroid"
      },
      "tf1": {
        "type": "TopLevelStyle",
        "id": "tf1",
        "metaData": {
          "isPreset": true,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "style": {
          "properties": {},
          "propertiesSource": {},
          "groups": {}
        },
        "componentClassName": "",
        "pageId": "",
        "compId": "",
        "styleType": "system",
        "skin": "skins.core.TwitterFollowSkin"
      },
      "tm1": {
        "type": "TopLevelStyle",
        "id": "tm1",
        "metaData": {
          "isPreset": true,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "style": {
          "properties": {
            "alpha-bg": "0",
            "alpha-bgDrop": "1",
            "alpha-bgOpen": "0",
            "alpha-borderColorSelected": "1",
            "alpha-bordercolor": "1",
            "alpha-iconcolor": "1",
            "alpha-iconcolorSelected": "1",
            "alpha-separatorColor": "0.5",
            "bg": "color_14",
            "bgDrop": "color_12",
            "bgOpen": "color_14",
            "borderColorSelected": "color_11",
            "borderWidthSelected": "0px",
            "bordercolor": "color_11",
            "borderwidth": "0",
            "fnt": "normal normal normal 16px/1.4em proxima-n-w01-reg",
            "iconcolor": "color_15",
            "iconcolorSelected": "color_15",
            "linewidth": "20px",
            "rd": "4px",
            "separatorColor": "color_15",
            "separatorHeight": "1px",
            "separatorWidthFull": "160",
            "strokewidth": "3",
            "textAlignment": "center",
            "txt": "color_15",
            "txts": "color_18"
          },
          "propertiesSource": {
            "alpha-bg": "value",
            "alpha-bgDrop": "value",
            "alpha-bgOpen": "value",
            "alpha-borderColorSelected": "value",
            "alpha-bordercolor": "value",
            "alpha-iconcolor": "value",
            "alpha-iconcolorSelected": "value",
            "alpha-separatorColor": "value",
            "bg": "theme",
            "bgDrop": "theme",
            "bgOpen": "value",
            "borderColorSelected": "theme",
            "borderWidthSelected": "value",
            "bordercolor": "theme",
            "borderwidth": "value",
            "fnt": "value",
            "iconcolor": "theme",
            "iconcolorSelected": "theme",
            "linewidth": "value",
            "rd": "value",
            "separatorColor": "theme",
            "separatorHeight": "value",
            "separatorWidthFull": "value",
            "strokewidth": "value",
            "textAlignment": "value",
            "txt": "theme",
            "txts": "theme"
          },
          "groups": {
          }
        },
        "componentClassName": "",
        "pageId": "",
        "compId": "",
        "styleType": "system",
        "skin": "wysiwyg.viewer.skins.mobile.TinyMenuFullScreenSkin"
      },
      "tm2": {
        "type": "TopLevelStyle",
        "id": "tm2",
        "metaData": {
          "isPreset": true,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "style": {
          "properties": {
            "alpha-bg": "1",
            "alpha-bgDrop": "1",
            "alpha-bgOpen": "0",
            "alpha-borderColorSelected": "1",
            "alpha-bordercolor": "1",
            "alpha-iconcolor": "1",
            "alpha-iconcolorSelected": "1",
            "alpha-separatorColor": "0.5",
            "bg": "color_15",
            "bgDrop": "color_15",
            "bgOpen": "color_11",
            "borderColorSelected": "color_13",
            "borderWidthSelected": "0",
            "bordercolor": "color_13",
            "borderwidth": "0",
            "fnt": "normal normal normal 16px/1.4em proxima-n-w01-reg",
            "iconcolor": "color_11",
            "iconcolorSelected": "color_11",
            "linewidth": "20px",
            "rd": "0px 0px 0px 0px",
            "separatorColor": "color_15",
            "separatorHeight": "1px",
            "separatorWidth": "90",
            "strokewidth": "3",
            "textAlignment": "right",
            "txt": "color_11",
            "txts": "color_18"
          },
          "propertiesSource": {
            "alpha-bg": "value",
            "alpha-bgDrop": "value",
            "alpha-bgOpen": "value",
            "alpha-borderColorSelected": "value",
            "alpha-bordercolor": "value",
            "alpha-iconcolor": "value",
            "alpha-iconcolorSelected": "value",
            "alpha-separatorColor": "value",
            "bg": "theme",
            "bgDrop": "theme",
            "bgOpen": "theme",
            "borderColorSelected": "theme",
            "borderWidthSelected": "value",
            "bordercolor": "theme",
            "borderwidth": "value",
            "fnt": "value",
            "iconcolor": "theme",
            "iconcolorSelected": "theme",
            "linewidth": "value",
            "rd": "value",
            "separatorColor": "theme",
            "separatorHeight": "value",
            "separatorWidth": "value",
            "strokewidth": "value",
            "textAlignment": "value",
            "txt": "theme",
            "txts": "theme"
          },
          "groups": {}
        },
        "componentClassName": "",
        "pageId": "",
        "compId": "",
        "styleType": "system",
        "skin": "wysiwyg.viewer.skins.mobile.TinyMenuPullFromRightSkin"
      },
      "tmFull1": {
        "type": "TopLevelStyle",
        "id": "tmFull1",
        "metaData": {
          "isPreset": true,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "style": {
          "properties": {
            "alpha-bg": "0",
            "alpha-bgDrop": "1",
            "alpha-bgOpen": "0",
            "alpha-borderColorSelected": "1",
            "alpha-bordercolor": "1",
            "alpha-iconcolor": "1",
            "alpha-iconcolorSelected": "1",
            "alpha-separatorColor": "0.5",
            "bg": "color_14",
            "bgDrop": "color_12",
            "bgOpen": "color_14",
            "borderColorSelected": "color_11",
            "borderWidthSelected": "0px",
            "bordercolor": "color_11",
            "borderwidth": "0",
            "fnt": "normal normal normal 16px/1.4em proxima-n-w01-reg",
            "iconcolor": "color_15",
            "iconcolorSelected": "color_15",
            "linewidth": "20px",
            "rd": "4px",
            "separatorColor": "color_15",
            "separatorHeight": "1px",
            "separatorWidthFull": "160",
            "strokewidth": "3",
            "textAlignment": "center",
            "txt": "color_15",
            "txts": "color_18"
          },
          "propertiesSource": {
            "alpha-bg": "value",
            "alpha-bgDrop": "value",
            "alpha-bgOpen": "value",
            "alpha-borderColorSelected": "value",
            "alpha-bordercolor": "value",
            "alpha-iconcolor": "value",
            "alpha-iconcolorSelected": "value",
            "alpha-separatorColor": "value",
            "bg": "theme",
            "bgDrop": "theme",
            "bgOpen": "value",
            "borderColorSelected": "theme",
            "borderWidthSelected": "value",
            "bordercolor": "theme",
            "borderwidth": "value",
            "fnt": "value",
            "iconcolor": "theme",
            "iconcolorSelected": "theme",
            "linewidth": "value",
            "rd": "value",
            "separatorColor": "theme",
            "separatorHeight": "value",
            "separatorWidthFull": "value",
            "strokewidth": "value",
            "textAlignment": "value",
            "txt": "theme",
            "txts": "theme"
          },
          "groups": {
          }
        },
        "pageId": "",
        "compId": "",
        "styleType": "system",
        "skin": "wysiwyg.viewer.skins.mobile.TinyMenuFullScreenSkin"
      },
      "tmFull2": {
        "type": "TopLevelStyle",
        "id": "tmFull2",
        "metaData": {
          "isPreset": true,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "style": {
          "properties": {
            "alpha-bg": "0",
            "alpha-bgDrop": "1",
            "alpha-bgOpen": "0",
            "alpha-borderColorSelected": "1",
            "alpha-bordercolor": "1",
            "alpha-iconcolor": "1",
            "alpha-iconcolorSelected": "1",
            "alpha-separatorColor": "0.5",
            "bg": "color_14",
            "bgDrop": "color_12",
            "bgOpen": "color_14",
            "borderColorSelected": "color_11",
            "borderWidthSelected": "0px",
            "bordercolor": "color_11",
            "borderwidth": "0",
            "fnt": "normal normal normal 16px/1.4em proxima-n-w01-reg",
            "iconcolor": "color_15",
            "iconcolorSelected": "color_15",
            "linewidth": "20px",
            "rd": "4px",
            "separatorColor": "color_15",
            "separatorHeight": "1px",
            "separatorWidthFull": "160",
            "strokewidth": "3",
            "textAlignment": "center",
            "txt": "color_15",
            "txts": "color_18"
          },
          "propertiesSource": {
            "alpha-bg": "value",
            "alpha-bgDrop": "value",
            "alpha-bgOpen": "value",
            "alpha-borderColorSelected": "value",
            "alpha-bordercolor": "value",
            "alpha-iconcolor": "value",
            "alpha-iconcolorSelected": "value",
            "alpha-separatorColor": "value",
            "bg": "theme",
            "bgDrop": "theme",
            "bgOpen": "value",
            "borderColorSelected": "theme",
            "borderWidthSelected": "value",
            "bordercolor": "theme",
            "borderwidth": "value",
            "fnt": "value",
            "iconcolor": "theme",
            "iconcolorSelected": "theme",
            "linewidth": "value",
            "rd": "value",
            "separatorColor": "theme",
            "separatorHeight": "value",
            "separatorWidthFull": "value",
            "strokewidth": "value",
            "textAlignment": "value",
            "txt": "theme",
            "txts": "theme"
          },
          "groups": {}
        },
        "pageId": "",
        "compId": "",
        "styleType": "system",
        "skin": "wysiwyg.viewer.skins.mobile.TinyMenuFullScreenSkin"
      },
      "tpas0": {
        "type": "TopLevelStyle",
        "id": "tpas0",
        "metaData": {
          "isPreset": true,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "style": {
          "properties": {},
          "propertiesSource": {},
          "groups": {
          }
        },
        "componentClassName": "wysiwyg.viewer.components.tpapps.TPASection",
        "pageId": "",
        "compId": "",
        "styleType": "system",
        "skin": "wysiwyg.viewer.skins.TPASectionSkin"
      },
      "tpaw0": {
        "type": "TopLevelStyle",
        "id": "tpaw0",
        "metaData": {
          "isPreset": true,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "style": {
          "properties": {},
          "propertiesSource": {},
          "groups": {}
        },
        "componentClassName": "wysiwyg.viewer.components.tpapps.TPAWidget",
        "pageId": "",
        "compId": "",
        "styleType": "system",
        "skin": "wysiwyg.viewer.skins.TPAWidgetSkin"
      },
      "twf1": {
        "type": "TopLevelStyle",
        "id": "twf1",
        "metaData": {
          "isPreset": true,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "style": {
          "properties": {
            "alpha-bg": "1",
            "alpha-bg2": "1",
            "alpha-linkColor": "1",
            "alpha-txt1": "1",
            "alpha-txt2": "1",
            "bg": "color_4",
            "bg2": "color_4",
            "brightness-bg": "0",
            "brightness-bg2": "0",
            "brightness-linkColor": "0",
            "brightness-txt1": "0",
            "brightness-txt2": "0",
            "linkColor": "color_8",
            "saturation-bg": "0",
            "saturation-bg2": "0",
            "saturation-linkColor": "0",
            "saturation-txt1": "0",
            "saturation-txt2": "0",
            "txt1": "color_8",
            "txt2": "color_8"
          }
        },
        "componentClassName": "",
        "pageId": "",
        "compId": "",
        "styleType": "system",
        "skin": "wysiwyg.viewer.skins.TwitterFeedSkin"
      },
      "twt1": {
        "type": "TopLevelStyle",
        "id": "twt1",
        "metaData": {
          "isPreset": true,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "style": {
          "properties": {},
          "propertiesSource": {},
          "groups": {
          }
        },
        "componentClassName": "",
        "pageId": "",
        "compId": "",
        "styleType": "system",
        "skin": "skins.core.TwitterTweetSkin"
      },
      "txt1": {
        "type": "TopLevelStyle",
        "id": "txt1",
        "metaData": {
          "isPreset": true,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "style": {
          "properties": {
            "f0": "font_0",
            "f1": "font_1",
            "f10": "font_10",
            "f2": "font_2",
            "f3": "font_3",
            "f4": "font_4",
            "f5": "font_5",
            "f6": "font_6",
            "f7": "font_7",
            "f8": "font_8",
            "f9": "font_9"
          },
          "propertiesSource": {
            "f0": "theme",
            "f1": "theme",
            "f10": "theme",
            "f2": "theme",
            "f3": "theme",
            "f4": "theme",
            "f5": "theme",
            "f6": "theme",
            "f7": "theme",
            "f8": "theme",
            "f9": "theme"
          }
        },
        "componentClassName": "",
        "pageId": "",
        "compId": "",
        "styleType": "system",
        "skin": "wysiwyg.viewer.skins.WRichTextSkin"
      },
      "txtNew": {
        "type": "TopLevelStyle",
        "id": "txtNew",
        "metaData": {
          "isPreset": true,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "style": {
          "properties": {
            "f0": "font_0",
            "f1": "font_1",
            "f10": "font_10",
            "f2": "font_2",
            "f3": "font_3",
            "f4": "font_4",
            "f5": "font_5",
            "f6": "font_6",
            "f7": "font_7",
            "f8": "font_8",
            "f9": "font_9"
          },
          "propertiesSource": {
            "f0": "theme",
            "f1": "theme",
            "f10": "theme",
            "f2": "theme",
            "f3": "theme",
            "f4": "theme",
            "f5": "theme",
            "f6": "theme",
            "f7": "theme",
            "f8": "theme",
            "f9": "theme"
          },
          "groups": {}
        },
        "componentClassName": "",
        "pageId": "",
        "compId": "",
        "styleType": "system",
        "skin": "wysiwyg.viewer.skins.WRichTextNewSkin"
      },
      "v1": {
        "type": "TopLevelStyle",
        "id": "v1",
        "metaData": {
          "isPreset": true,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "style": {
          "properties": {
          },
          "propertiesSource": {
          },
          "groups": {
          }
        },
        "componentClassName": "",
        "pageId": "",
        "compId": "",
        "styleType": "system",
        "skin": "wysiwyg.viewer.skins.VideoSkin"
      },
      "v2": {
        "type": "TopLevelStyle",
        "id": "v2",
        "metaData": {
          "isPreset": true,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "style": {
          "properties": {
            "brd": "color_15",
            "brw": "0px",
            "rd": "0px",
            "shd": "0 1px 4px rgba(0, 0, 0, 0.6);"
          },
          "propertiesSource": {
            "brd": "theme",
            "brw": "value",
            "rd": "value",
            "shd": "value"
          },
          "groups": {
          }
        },
        "componentClassName": "",
        "pageId": "",
        "compId": "",
        "styleType": "system",
        "skin": "wysiwyg.viewer.skins.video.VideoDefault"
      },
      "vl1": {
        "type": "TopLevelStyle",
        "id": "vl1",
        "metaData": {
          "isPreset": true,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "style": {
          "properties": {
            "alpha-brd": "1",
            "brd": "color_14",
            "lnw": "1px"
          },
          "propertiesSource": {
            "brd": "theme",
            "lnw": "value"
          },
          "groups": {
          }
        },
        "componentClassName": "",
        "pageId": "",
        "compId": "",
        "styleType": "system",
        "skin": "wysiwyg.viewer.skins.line.VerticalSolidLine"
      },
      "vl2": {
        "type": "TopLevelStyle",
        "id": "vl2",
        "metaData": {
          "isPreset": true,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "style": {
          "properties": {
            "alpha-brd": "1",
            "brd": "color_12",
            "lnw1": "3px",
            "lnw2": "2px"
          }
        },
        "componentClassName": "",
        "pageId": "",
        "compId": "",
        "styleType": "system",
        "skin": "wysiwyg.viewer.skins.line.VerticalDoubleLine"
      },
      "vl3": {
        "type": "TopLevelStyle",
        "id": "vl3",
        "metaData": {
          "isPreset": true,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "style": {
          "properties": {
            "alpha-brd": "1",
            "brd": "color_15",
            "lnw": "3px"
          },
          "propertiesSource": {
            "brd": "theme",
            "lnw": "value"
          },
          "groups": {}
        },
        "componentClassName": "",
        "pageId": "",
        "compId": "",
        "styleType": "system",
        "skin": "wysiwyg.viewer.skins.line.VerticalSolidLine"
      },
      "vl4": {
        "type": "TopLevelStyle",
        "id": "vl4",
        "metaData": {
          "isPreset": true,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "style": {
          "properties": {},
          "propertiesSource": {},
          "groups": {}
        },
        "componentClassName": "",
        "pageId": "",
        "compId": "",
        "styleType": "system",
        "skin": "wysiwyg.viewer.skins.line.VerticalNotchDashedLine"
      },
      "wp1": {
        "type": "TopLevelStyle",
        "id": "wp1",
        "metaData": {
          "isPreset": true,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "style": {
          "properties": {
            "alpha-brd": "1",
            "boxShadowToggleOn-shd": "false",
            "brd": "color_14",
            "brw": "5px",
            "rd": "0px",
            "shd": "0 1px 3px rgba(0, 0, 0, 0.5);"
          },
          "propertiesSource": {
            "brd": "theme",
            "brw": "value",
            "rd": "value",
            "shd": "value"
          },
          "groups": {}
        },
        "componentClassName": "",
        "pageId": "",
        "compId": "",
        "styleType": "system",
        "skin": "wysiwyg.viewer.skins.photo.RoundPhoto"
      },
      "wp2": {
        "type": "TopLevelStyle",
        "id": "wp2",
        "metaData": {
          "isPreset": true,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "style": {
          "properties": {},
          "propertiesSource": {
          },
          "groups": {}
        },
        "componentClassName": "",
        "pageId": "",
        "compId": "",
        "styleType": "system",
        "skin": "wysiwyg.viewer.skins.photo.NoSkinPhoto"
      },
      "wp3": {
        "type": "TopLevelStyle",
        "id": "wp3",
        "metaData": {
          "isPreset": true,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "style": {
          "properties": {
            "alpha-brd": "1",
            "brd": "color_15",
            "brw": "0px",
            "rd": "0px"
          }
        },
        "componentClassName": "",
        "pageId": "",
        "compId": "",
        "styleType": "system",
        "skin": "wysiwyg.viewer.skins.photo.LiftedShadowPhoto"
      },
      "wp4": {
        "type": "TopLevelStyle",
        "id": "wp4",
        "metaData": {
          "isPreset": true,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "style": {
          "properties": {
            "alpha-brd": "1",
            "boxShadowToggleOn-shd": "true",
            "brd": "color_13",
            "brw": "0px",
            "rd": "0px",
            "shd": "0 2px 5px rgba(0, 0, 0, 0.15);"
          },
          "propertiesSource": {
            "brd": "theme",
            "brw": "value",
            "rd": "value",
            "shd": "value"
          },
          "groups": {
          }
        },
        "componentClassName": "",
        "pageId": "",
        "compId": "",
        "styleType": "system",
        "skin": "wysiwyg.viewer.skins.photo.ScotchDoubleVertical"
      },
      "style-jfv0wogk": {
        "type": "ComponentStyle",
        "id": "style-jfv0wogk",
        "metaData": {
          "isPreset": true,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "style": {
          "properties": {
            "alpha-bg": "0",
            "alpha-bgDrop": "1",
            "alpha-bgOpen": "0",
            "alpha-borderColorSelected": "1",
            "alpha-bordercolor": "1",
            "alpha-iconcolor": "1",
            "alpha-iconcolorSelected": "1",
            "alpha-separatorColor": "0.5",
            "bg": "color_14",
            "bgDrop": "color_12",
            "bgOpen": "color_14",
            "borderColorSelected": "color_11",
            "borderWidthSelected": "0px",
            "bordercolor": "color_11",
            "borderwidth": "0",
            "fnt": "normal normal normal 16px/1.4em proxima-n-w01-reg",
            "iconcolor": "color_15",
            "iconcolorSelected": "color_15",
            "linewidth": "20px",
            "rd": "4px",
            "separatorColor": "color_15",
            "separatorHeight": "1px",
            "separatorWidthFull": "160",
            "strokewidth": "3",
            "textAlignment": "center",
            "txt": "color_15",
            "txts": "color_18"
          },
          "propertiesSource": {
            "alpha-bg": "value",
            "alpha-bgDrop": "value",
            "alpha-bgOpen": "value",
            "alpha-borderColorSelected": "value",
            "alpha-bordercolor": "value",
            "alpha-iconcolor": "value",
            "alpha-iconcolorSelected": "value",
            "alpha-separatorColor": "value",
            "bg": "theme",
            "bgDrop": "theme",
            "bgOpen": "value",
            "borderColorSelected": "theme",
            "borderWidthSelected": "value",
            "bordercolor": "theme",
            "borderwidth": "value",
            "fnt": "value",
            "iconcolor": "theme",
            "iconcolorSelected": "theme",
            "linewidth": "value",
            "rd": "value",
            "separatorColor": "theme",
            "separatorHeight": "value",
            "separatorWidthFull": "value",
            "strokewidth": "value",
            "textAlignment": "value",
            "txt": "theme",
            "txts": "theme"
          },
          "groups": {}
        },
        "componentClassName": "wysiwyg.viewer.components.mobile.TinyMenu",
        "pageId": "",
        "compId": "",
        "styleType": "custom",
        "skin": "wysiwyg.viewer.skins.mobile.TinyMenuFullScreenSkin"
      },
      "style-jnstsebu": {
        "type": "ComponentStyle",
        "styleType": "custom",
        "metaData": {
          "isPreset": true,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "style": {
          "properties": {
            "alpha-bg": 0,
            "alpha-brd": "1",
            "bg": "color_11",
            "boxShadowToggleOn-shd": "false",
            "brd": "color_15",
            "brw": "0",
            "rd": "0px",
            "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)"
          },
          "propertiesSource": {
            "alpha-bg": "value",
            "alpha-brd": "value",
            "bg": "theme",
            "boxShadowToggleOn-shd": "value",
            "brd": "theme",
            "brw": "value",
            "rd": "value",
            "shd": "value"
          },
          "groups": {}
        },
        "componentClassName": "mobile.core.components.Container",
        "pageId": "",
        "compId": "",
        "skin": "wysiwyg.viewer.skins.area.DefaultAreaSkin",
        "id": "style-jnstsebu"
      },
      "style-jnsxfutq": {
        "type": "ComponentStyle",
        "styleType": "custom",
        "metaData": {
          "isPreset": false,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "style": {
          "properties": {
            "alpha-bg": "1",
            "alpha-bgh": "1",
            "alpha-brd": "1",
            "alpha-brdh": "1",
            "alpha-txt": "1",
            "alpha-txth": "1",
            "bg": "#5293DE",
            "bgh": "#5293DE",
            "boxShadowToggleOn-shd": "false",
            "brd": "#3AB5CD",
            "brdh": "#FFFFFF",
            "brw": "0px",
            "fnt": "font_8",
            "rd": "8px",
            "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
            "txt": "#FFFFFF",
            "txth": "#FFFFFF"
          },
          "propertiesSource": {
            "alpha-bg": "value",
            "alpha-bgh": "value",
            "alpha-brd": "value",
            "alpha-brdh": "value",
            "alpha-txt": "value",
            "alpha-txth": "value",
            "bg": "value",
            "bgh": "value",
            "boxShadowToggleOn-shd": "value",
            "brd": "value",
            "brdh": "value",
            "brw": "value",
            "fnt": "theme",
            "rd": "value",
            "shd": "value",
            "txt": "value",
            "txth": "value"
          },
          "groups": {}
        },
        "componentClassName": "wysiwyg.viewer.components.SiteButton",
        "pageId": "",
        "compId": "",
        "skin": "wysiwyg.viewer.skins.button.BasicButton",
        "id": "style-jnsxfutq"
      },
      "style-jnu2by73": {
        "id": "style-jnu2by73",
        "type": "ComponentStyle",
        "metaData": {
          "isPreset": false,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "style": {
          "properties": {
            "alpha-bg": "0",
            "alpha-bgh": "1",
            "alpha-brd": "1",
            "alpha-brdh": "1",
            "alpha-txt": "1",
            "alpha-txth": "1",
            "bg": "#5293DE",
            "bgh": "#4E59F2",
            "boxShadowToggleOn-shd": "false",
            "brd": "color_23",
            "brdh": "#FFFFFF",
            "brw": "1",
            "fnt": "font_8",
            "rd": "8px",
            "shd": "0px 1px 4px 0px rgba(0,0,0,0.6)",
            "txt": "color_23",
            "txth": "#FFFFFF"
          },
          "propertiesSource": {
            "alpha-bg": "value",
            "alpha-bgh": "value",
            "alpha-brd": "value",
            "alpha-brdh": "value",
            "alpha-txt": "value",
            "alpha-txth": "value",
            "bg": "value",
            "bgh": "value",
            "boxShadowToggleOn-shd": "value",
            "brd": "theme",
            "brdh": "value",
            "brw": "value",
            "fnt": "theme",
            "rd": "value",
            "shd": "value",
            "txt": "theme",
            "txth": "value"
          },
          "groups": {}
        },
        "componentClassName": "wysiwyg.viewer.components.SiteButton",
        "pageId": "",
        "compId": "",
        "styleType": "custom",
        "skin": "wysiwyg.viewer.skins.button.BasicButton"
      }
    },
    "component_properties": {
      "propItem-jnswm1kb": {
        "id": "propItem-jnswm1kb",
        "type": "ButtonProperties",
        "metaData": {
          "isPreset": false,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "align": "center",
        "margin": 0
      },
      "propItem-jneclmwc": {
        "id": "propItem-jneclmwc",
        "type": "FiveGridLineProperties",
        "metaData": {
          "isPreset": false,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "fullScreenModeOn": false
      },
      "propItem-jnu2by721": {
        "id": "propItem-jnu2by721",
        "type": "ButtonProperties",
        "metaData": {
          "isPreset": false,
          "schemaVersion": "1.0",
          "isHidden": false
        },
        "align": "center",
        "margin": 0
      },
      "propItem-jnu2c2nq": {
        "id": "propItem-jnu2c2nq",
        "type": "WRichTextProperties",
        "packed": false,
        "brightness": 1,
        "metaData": {
          "isPreset": false,
          "schemaVersion": "1.0",
          "isHidden": false,
          "autoGenerated": false
        },
        "minHeight": 48
      }
    },
    "mobile_hints": {
    }
  }
}
