'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')
const experiment = require('experiment')
const util = require('@/util')

module.exports = {
    "type": addPanelDataConsts.SECTIONS_TYPES.PRESET,
    "hide": false,
    "title": "add_section_label_twitter",
    "subNavigationTitle": "add_section_label_twitter",
    "presetTitle": "add_section_label_twitter",
    "tooltipTitle": "add_section_label_twitter",
    "automationId": "add-panel-section-twitterSection",
    "subNavigationHide": false,
    "showSectionHeader": true,
    "additionalBehaviours": {
        "labelMode": addPanelDataConsts.LABEL_BEHAVIOUR.HOVER,
        "infoIcon": addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
        "hoverImageAction": addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
        "iconEnabledForComps": {}
    },
    "props": experiment.isOpen('se_newSocialCategoryAddPanel') && util.languages.getLanguageCode() === 'en' ? {
        "image": `addPanelData/sections/twitterSection_${util.languages.getLanguageCode()}/twitterSection_${util.languages.getLanguageCode()}.png`,
        "imageHover": null,
        "items": [{
            "id": "Social_Twitter_1",
            "structure": {
                "type": "Component",
                "skin": "skins.core.TwitterFollowSkin",
                "layout": {
                    "width": 184,
                    "height": 20,
                    "x": 77,
                    "y": 51,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WTwitterFollow",
                "data": {
                    "type": "TwitterFollow",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "accountToFollow": "wix"
                },
                "props": {
                    "type": "WTwitterFollowProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "showCount": true,
                    "width": 100,
                    "dataLang": util.languages.getLanguageCode(),
                    "showScreenName": true
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": true, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "skins.core.TwitterFollowSkin"
                },
                "activeModes": {},
                "id": "comp-jp1cyt0m"
            },
            "preset": {
                "rect": {"width": 171, "height": 60, "x": 0, "y": 0},
                "label": "add_preset_Social_Twitter_2",
                "tags": null,
                "labelClasses": "align-left"
            }
        }, {
            "id": "Social_Twitter_2",
            "structure": {
                "type": "Component",
                "skin": "skins.core.TwitterFollowSkin",
                "layout": {
                    "width": 65,
                    "height": 20,
                    "x": 391,
                    "y": 51,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WTwitterFollow",
                "data": {
                    "type": "TwitterFollow",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "accountToFollow": "wix"
                },
                "props": {
                    "type": "WTwitterFollowProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "showCount": false,
                    "width": 100,
                    "dataLang": util.languages.getLanguageCode(),
                    "showScreenName": false
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": true, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "skins.core.TwitterFollowSkin"
                },
                "activeModes": {},
                "id": "comp-jp1cyxb4"
            },
            "preset": {
                "rect": {"width": 81.5, "height": 60, "x": 171, "y": 0},
                "label": "add_preset_Social_Twitter_1",
                "tags": null,
                "labelClasses": "align-left"
            }
        }, {
            "id": "Social_Twitter_3",
            "structure": {
                "type": "Component",
                "skin": "skins.core.TwitterTweetSkin",
                "layout": {
                    "width": 61,
                    "height": 20,
                    "x": 540,
                    "y": 51,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WTwitterTweet",
                "data": {
                    "type": "TwitterTweet",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "defaultText": "",
                    "accountToFollow": "",
                    "urlFormat": "slash",
                    "isHttpsEnabled": false
                },
                "props": {
                    "type": "WTwitterTweetProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "dataLang": util.languages.getLanguageCode(),
                    "dataCount": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    "metaData": {"isPreset": true, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "skins.core.TwitterTweetSkin"
                },
                "activeModes": {},
                "id": "comp-jp1cz20s"
            },
            "preset": {
                "rect": {"width": 71.5, "height": 60, "x": 252.5, "y": 0},
                "label": "add_preset_Social_Twitter_3",
                "tags": null,
                "labelClasses": "align-left"
            }
        }],
        "compTypes": ["wysiwyg.viewer.components.WTwitterFollow", "wysiwyg.viewer.components.WTwitterTweet"]
    } : {
        "image": util.languages.getLanguageCode() === 'en' ? `addPanelData/sections/twitterSection_en/twitterSection_en.v4.png` :
            `addPanelData/sections/twitterSection_${util.languages.getLanguageCode()}/twitterSection_${util.languages.getLanguageCode()}.png`,
        "imageHover": null,
        "items": [{
            "id": "Social_Twitter_1",
            "structure": {
                "type": "Component",
                "skin": "skins.core.TwitterFollowSkin",
                "layout": {
                    "width": 65,
                    "height": 20,
                    "x": 9,
                    "y": 19,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WTwitterFollow",
                "data": {
                    "type": "TwitterFollow",
                    "metaData": {"isPreset": true, "schemaVersion": "1.0", "isHidden": false},
                    "accountToFollow": "wix"
                },
                "props": {
                    "type": "WTwitterFollowProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "showCount": false,
                    "width": 100,
                    "dataLang": util.languages.getLanguageCode(),
                    "showScreenName": false
                },
                "style": {
                    "type": "TopLevelStyle",
                    "id": "tf1",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "skins.core.TwitterFollowSkin"
                },
                "id": "ic208ds7"
            },
            "preset": {
                "rect": {"width": 324, "height": 58, "x": 0, "y": 0},
                "label": "add_preset_Social_Twitter_1",
                "tags": null,
                "labelClasses": "align-left"
            }
        }, {
            "id": "Social_Twitter_2",
            "structure": {
                "type": "Component",
                "skin": "skins.core.TwitterFollowSkin",
                "layout": {
                    "width": 184,
                    "height": 20,
                    "x": 9,
                    "y": 78,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WTwitterFollow",
                "data": {
                    "type": "TwitterFollow",
                    "metaData": {"isPreset": true, "schemaVersion": "1.0", "isHidden": false},
                    "accountToFollow": "wix"
                },
                "props": {
                    "type": "WTwitterFollowProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "showCount": true,
                    "width": 100,
                    "dataLang": util.languages.getLanguageCode(),
                    "showScreenName": true
                },
                "style": {
                    "type": "TopLevelStyle",
                    "id": "tf1",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "skins.core.TwitterFollowSkin"
                },
                "id": "ic207rbd"
            },
            "preset": {
                "rect": {"width": 324, "height": 58, "x": 0, "y": 58},
                "label": "add_preset_Social_Twitter_2",
                "tags": null,
                "labelClasses": "align-left"
            }
        }, {
            "id": "Social_Twitter_3",
            "structure": {
                "type": "Component",
                "skin": "skins.core.TwitterTweetSkin",
                "layout": {
                    "width": 62,
                    "height": 20,
                    "x": 9,
                    "y": 134,
                    "scale": 1,
                    "rotationInDegrees": 0,
                    "fixedPosition": false
                },
                "componentType": "wysiwyg.viewer.components.WTwitterTweet",
                "data": {
                    "type": "TwitterTweet",
                    "metaData": {"isPreset": true, "schemaVersion": "1.0", "isHidden": false},
                    "defaultText": "",
                    "urlFormat": "hashBang",
                    "accountToFollow": ""
                },
                "props": {
                    "type": "WTwitterTweetProperties",
                    "metaData": {"schemaVersion": "1.0"},
                    "dataLang": util.languages.getLanguageCode(),
                    "dataCount": "none"
                },
                "style": {
                    "type": "TopLevelStyle",
                    "id": "twt1",
                    "metaData": {"isPreset": false, "schemaVersion": "1.0", "isHidden": false},
                    "style": {"groups": {}, "properties": {}, "propertiesSource": {}},
                    "componentClassName": "",
                    "pageId": "",
                    "compId": "",
                    "styleType": "system",
                    "skin": "skins.core.TwitterTweetSkin"
                },
                "id": "ic208434"
            },
            "preset": {
                "rect": {"width": 324, "height": 58, "x": 0, "y": 116},
                "label": "add_preset_Social_Twitter_3",
                "tags": null,
                "labelClasses": "align-left"
            }
        }],
        "compTypes": ["wysiwyg.viewer.components.WTwitterFollow", "wysiwyg.viewer.components.WTwitterTweet"]
    }
}
