import React from 'react';
import ListSection, { type ListSectionProps } from './listSection';

export default class extends React.Component<ListSectionProps> {
  static displayName = 'genericListSection';

  onItemClick = (item: AnyFixMe) => {
    item.onItemClick();
  };

  render() {
    return <ListSection callback={this.onItemClick} {...this.props} />;
  }
}
