:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._wrapper_xq3y9_7 {
  position: relative;
  height: 100%;
  background: #fff;
  min-width: 300px; }

._root_xq3y9_13 {
  height: 100%; }
