// @ts-nocheck
import ReactLinkedStateMixin from 'react-addons-linked-state-mixin';
import _ from 'lodash';
import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import * as coreBi from '@/coreBi';
import * as util from '@/util';
import { translate } from '@/i18n';
import pageSettingsTabMixin from './pageSettingsTabMixin';
import * as pageUtils from '../../utils/pageUtils';
import constants from '../../utils/constants';
import experiment from 'experiment';
import {
  Button,
  Composites,
  CustomScroll,
  Divider,
  GooglePreview,
  InfoIcon,
  RichText,
  SecondaryMediaImage,
  TextInput,
  TextLabel,
  ToggleSwitch,
  Tooltip,
} from '@wix/wix-base-ui';
import socialSharePreviewTooltip from './socialSharePreviewTooltip';
import * as SEOPanelMapper from './SEO/SEOPanelMapper';

import * as baseUI from '@/baseUI';
import CONSTANTS from '../../utils/constants';
import * as symbols from '@wix/santa-editor-symbols';

function getValidators({ isUriForbidden, isSlashFormat, isUriDuplicate }) {
  const initialChecks = [
    {
      callback: util.validate.notEmptyString,
      message: 'Pages_Info_URL_Error_Tooltip',
    },
    {
      callback: util.validate.byInvalidCharacters(
        util.validationExpressions.invalidUrlCharacters,
      ),
      message: 'Pages_Info_URL_Error_Tooltip',
    },
  ];
  if (isSlashFormat) {
    return initialChecks.concat([
      {
        callback: _.negate(isUriForbidden),
        message: 'Pages_Info_Forbidden_URI_SEO_Error_Tooltip',
      },
      {
        callback: _.negate(isUriDuplicate),
        message: 'Pages_Info_Duplicate_URI_SEO_Error_Tooltip',
      },
    ]);
  }
  return initialChecks;
}

// eslint-disable-next-line react/prefer-es6-class
const dumbSeoTab = createReactClass({
  displayName: 'tabSEONew',
  mixins: [pageSettingsTabMixin, ReactLinkedStateMixin], //eslint-disable-line react/no-deprecated
  render() {
    const tr = translate;

    return (
      <CustomScroll key="newSeoTab">
        <div className="tab-seo-new">
          <div className="tab-seo-title">
            <Composites.RichTextLabeled className="composite-centered">
              <TextLabel
                value={tr('Pages_SEO_Wizard_Title')}
                type="T03"
                shouldTranslate={false}
              />
              <RichText>
                <p>{translate('Pages_SEO_Wizard_Description')}</p>
                <p>
                  <Button
                    onClick={this.onSeoWizardClick}
                    className="btn-confirm-secondary"
                  >
                    {translate('Pages_SEO_Wizard_NewButton_Label')}
                  </Button>
                </p>
              </RichText>
            </Composites.RichTextLabeled>
          </div>

          <div key="seoSettingsSection">
            <Divider key="longDivider1" long={true} />

            <baseUI.sectionDividerLabeled
              key="SEOSettings"
              label="seo_seoSettings_section_header"
            />

            <Divider key="longDivider2" long={true} />
          </div>

          <div>
            <Composites.GooglePreviewWithRichText>
              <RichText>
                {translate('Pages_SEO_Preview_Caption')}
                <Tooltip
                  content={this.getTooltipContent()}
                  shouldTranslate={false}
                  interactive={true}
                  onOpen={this.reportTooltipOpened}
                  openDelay={350}
                >
                  <a>{translate('Pages_SEO_Preview_HoverLink_Caption')}</a>
                </Tooltip>
              </RichText>
              <GooglePreview
                title={this.getTitle()}
                url={this.props.publicURL}
                description={
                  this.props.previewDescriptionText ||
                  this.linkGooglePreviewProperty('descriptionSEO').value ||
                  tr('Pages_SEO_Description_Placeholder')
                }
                shouldTranslate={false}
              />
            </Composites.GooglePreviewWithRichText>
          </div>

          <Divider long={false} />

          <div className="seo-tab-title-input-section">
            <Composites.TextInputLabeled>
              <InfoIcon text="Pages_SEO_Title_Tooltip" />
              <TextLabel value="Pages_SEO_Title_NewCaption" />
              <Tooltip
                content="Pages_SEO_Title_NewTooltip"
                isOpen={this.state.enableTitleTooltip}
                openOnMouseEnter={false}
                alignment="TOP"
                shouldTranslate={true}
                marginTop={CONSTANTS.TITLE_TOOLTIP_MARGIN_TOP}
                marginLeft={CONSTANTS.TITLE_TOOLTIP_MARGIN_LEFT}
              >
                <TextInput
                  placeholder={this.getPageTitlePlaceHolder()}
                  shouldTranslate={false}
                  maxLength={CONSTANTS.TITLE_SEO_MAX_LENGTH}
                  validator={[util.validate.noHTMLTags, util.validate.noEmoji]}
                  invalidMessage={tr('Pages_SEO_Title_Error_Tooltip')}
                  onValidationStatus={this.onTitleInputValidation}
                  value={this.linkGooglePreviewProperty('pageTitleSEO').value}
                  onChange={this.changeSeoTitle}
                  onFocus={this.enableTitleTooltip}
                  onBlur={this.disableTitleTooltip}
                  className="control-text-input"
                />
              </Tooltip>
            </Composites.TextInputLabeled>
          </div>

          <Divider long={false} />

          <div className="seo-tab-input-section">
            <baseUI.textInput
              isMultiLine={true}
              label="Pages_SEO_Description_Caption"
              placeholder="Pages_SEO_Description_Placeholder"
              maxLength={CONSTANTS.DESCRIPTION_SEO_MAX_LENGTH}
              validator={[util.validate.noHTMLTags, util.validate.noEmoji]}
              invalidMessage="Pages_SEO_Description_Error_Tooltip"
              value={this.linkGooglePreviewProperty('descriptionSEO').value}
              onChange={
                this.linkGooglePreviewProperty('descriptionSEO').requestChange
              }
              infoText="Pages_SEO_Description_Tooltip"
              onBlur={this.onPagesSEODescBlur}
              className="control-text-input"
            />
          </div>

          <Divider long={false} />

          <div
            key="section-page-address"
            className="seo-tab-page-address-section"
          >
            <baseUI.textInput
              disabled={this.isUriInputDisabled()}
              label="Pages_Info_Address_Title"
              maxLength={CONSTANTS.USI_SEO_MAX_LENGTH}
              validator={this.getPageUriSEOValidators()}
              invalidMessage={this.getPageUriSEOInvalidMessages()}
              valueLink={this.linkUrlProperty('pageUriSEO')}
              onBlur={this.onPageAddressBlur}
              infoText="Pages_Info_Address_Tooltip"
              className="control-text-input"
            />
            <span className="page-address-prefix">https://www.../</span>
            {this.getEditorAPI().dsRead.generalInfo.isSitePublished() ? (
              <a
                key="page-web-address"
                href={this.props.publicURL}
                onClick={this.onGoToUrlClicked}
                target="_blank"
                className="page-address-link"
              >
                <baseUI.button
                  label="Pages_SEO_Goto_URL"
                  className="btn-text"
                />
              </a>
            ) : null}
            {this.isUriSEOTranslated() ? (
              <span key="googleAttribution" className="translated-by">
                {translate('Pages_Info_URL_Popup_Google')}{' '}
                <baseUI.symbol name="google-logo" />
              </span>
            ) : null}
          </div>

          <Divider long={false} />

          <Composites.ToggleSwitch>
            <ToggleSwitch
              value={!this.linkGooglePreviewProperty('indexable').value}
              label="Pages_SEO_Hide_Toggle"
              onChange={this.onHidePageChanged}
            />
          </Composites.ToggleSwitch>

          <Divider long={false} />

          <div className="seo-tab-input-section">
            <baseUI.textInput
              label="Pages_SEO_Keywords_Caption"
              placeholder="Pages_SEO_Keywords_Placeholder"
              maxLength={CONSTANTS.KEYWORD_SEO_MAX_LENGTH}
              validator={this.getValidatorsForSeoKeywords()}
              invalidMessage="Pages_SEO_Keywords_Error_Tooltip"
              valueLink={this.linkPageProperty('metaKeywordsSEO')}
              infoText="Pages_SEO_Keywords_New_Tooltip"
              validateOnBlurOnly={true}
              onBlur={this.onPagesSEOKeywordsBlur}
              className="control-text-input"
            />
          </div>

          <Divider long={false} />

          <Composites.RichText>
            <RichText type="T02">
              {translate('Pages_SEO_Advanced_SEO_Text')}
              <a onClick={this.reportAdvancedSEOLinkClicked}>
                {translate('Pages_SEO_Advanced_SEO_Link')}
              </a>
            </RichText>
          </Composites.RichText>

          <div key="socialShareSection">
            <Divider key="longDivider3" long={true} />

            <baseUI.sectionDividerLabeled
              key="socialShare"
              label="seo_socialShare_section_header"
            />

            <Divider key="longDivider4" long={true} />
            <Composites.RichText key="socialShareSubtitle">
              <RichText type="T02">
                <p>{translate('seo_socialShare_subtitle')}</p>
              </RichText>
            </Composites.RichText>

            <Composites.SecondaryMedia key="socialShareMedia">
              <SecondaryMediaImage
                key="socialShareMediaImage"
                src={this.getPageSEOImage()}
                onClick={this.onUploadImageClick}
              />
              <Button onClick={this.onUploadImageClick}>
                <symbols.symbol name={this.getUploadButtonSymbol()} />
              </Button>
              <Button onClick={this.onDeleteImageClick}>
                <symbols.symbol name="delete_icon" />
              </Button>
            </Composites.SecondaryMedia>
            <RichText className="seo-image-desc">
              {translate('seo_socialShare_image_select_dashboard_line')}
              <a onClick={this.openSEOPreviewHelp}>
                {translate('seo_socialShare_image_select_dashboard_link')}
              </a>
            </RichText>
            <div className="preview-link-container">
              <Tooltip
                closeOnMouseLeave={true}
                content={this.getSocialShareImageTooltipContent()}
                interactive={true}
                key="SocialShareImagePreviewTooltip"
                maxWidth={438}
                onOpen={this.reportPreviewTooltipOpened}
              >
                <symbols.symbol name="showComp" />
                <RichText>
                  <a>{translate('seoTab_socialShare_image_preview_link')}</a>
                </RichText>
              </Tooltip>
            </div>
          </div>
        </div>
      </CustomScroll>
    );
  },
  propTypes: {
    pageData: PropTypes.object.isRequired,
    isUriForbidden: PropTypes.func.isRequired,
    isUriDuplicate: PropTypes.func.isRequired,
    openHelpCenter: PropTypes.func.isRequired,
    getPageDataById: PropTypes.func.isRequired,
    updatePage: PropTypes.func.isRequired,
    sendBI: PropTypes.func.isRequired,
    setMediaServicesFocusToEditor: PropTypes.func.isRequired,
    language: PropTypes.object.isRequired,
    pageId: PropTypes.string.isRequired,
    isSlashFormat: PropTypes.bool.isRequired,
    uri: PropTypes.string.isRequired,
    convertPageNameToUrl: PropTypes.func.isRequired,
    wixSeoUrl: PropTypes.string.isRequired,
    publicURL: PropTypes.string.isRequired,
    isHomePage: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    siteName: PropTypes.string.isRequired,
    mediaManager: PropTypes.object.isRequired,
    sessionUserPreferencesuserPref: PropTypes.object.isRequired,
    siteDisplayName: PropTypes.string.isRequired,
  },
  getInitialStateFromPageData(pageID) {
    const pageData = this.props.getPageDataById(pageID);
    return {
      enableTitleTooltip: false,
      isTitleSEOValid: true,
      pageSEOImage: pageData?.ogImageRef?.uri,
      pageSEOImageWidth: pageData?.ogImageRef?.width,
      pageSEOImageHeight: pageData?.ogImageRef?.height,
    };
  },

  getInitialState() {
    return this.getInitialStateFromPageData(this.props.pageId);
  },

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.pageId !== nextProps.pageId) {
      this.setState(this.getInitialStateFromPageData(nextProps.pageId));
    }
  },

  UNSAFE_componentWillMount() {
    this.debouncedUpdateEditorAPI = _.debounce(function (pageId, newObjValue) {
      this.props.updatePage(pageId, newObjValue);
    }, 20);
    this.seoInfo = {
      pageTitleSEO: this.getValueFromPageData('pageTitleSEO'),
      pageDescSeo: this.getValueFromPageData('descriptionSEO'),
      pageKeywordsSEO: this.getValueFromPageData('metaKeywordsSEO'),
    };
  },

  getTooltipContent() {
    const text = React.createElement(
      RichText,
      {},
      React.createElement(
        'p',
        {},
        translate('Pages_SEO_Preview_Hoverlink_Tooltip_Description'),
      ),
      React.createElement(
        'a',
        { onClick: this.onLearnMoreClickOpenHelpCenter },
        translate('Pages_SEO_Preview_Hoverlink_Tooltip_Link'),
      ),
    );

    const title = React.createElement(TextLabel, {
      type: 'T03',
      value: 'Pages_SEO_Preview_Hoverlink_Tooltip_Title',
      enableEllipsis: false,
    });

    return React.createElement(
      'span',
      { className: 'info-icon-content' },
      title,
      text,
    );
  },

  reportTooltipOpened() {
    this.props.sendBI(
      coreBi.events.topbar.pages
        .top_bar_PAGES_settings_seo_google_preview_tooltip_hover,
    );
  },
  onSocialShareImageTooltipOpen() {
    this.socialShareImageTooltip = window.setTimeout(
      function () {
        this.props.sendBI(
          coreBi.events.topbar.pages
            .pages_panel_page_settings_SEO_tab_hover_preview_image,
        );
      }.bind(this),
      3000,
    );
  },

  onSocialShareImageTooltipClose() {
    window.clearTimeout(this.socialShareImageTooltip);
  },

  onLearnMoreClickOpenHelpCenter() {
    this.props.openHelpCenter(constants.GOOGLE_PREVIEW_HELP_ID);
  },

  linkGooglePreviewProperty(prop) {
    const newObjValue = {};

    return {
      value: this.getValueFromPageData(prop),
      requestChange: function (newValue) {
        const { pageId } = this.props;
        newObjValue[prop] = newValue;
        this.debouncedUpdateEditorAPI(pageId, newObjValue);
      }.bind(this),
    };
  },

  changeSeoDescription(newValue) {
    const { pageId } = this.props;
    const newObjValue = { descriptionSEO: newValue };
    this.setState(newObjValue);

    this.props.updatePage(pageId, newObjValue);
  },

  changeSeoTitle(newValue) {
    const { pageId } = this.props;
    const newObjValue = { pageTitleSEO: newValue };
    this.setState(newObjValue);

    this.props.updatePage(pageId, newObjValue);
  },

  onPagesSEOTitleBlur() {
    const previousPageTitleSeo = this.seoInfo.pageTitleSEO;
    const newPageTitleSeo = this.getValueFromPageData('pageTitleSEO');
    if (newPageTitleSeo !== previousPageTitleSeo) {
      this.props.sendBI(
        coreBi.events.topbar.pages.top_bar_PAGES_settings_seo_title_set,
        {
          field: 'page title',
        },
      );
      this.seoInfo.pageTitleSEO = newPageTitleSeo;
    }
  },

  onPagesSEODescBlur() {
    const previousPageDescSeo = this.seoInfo.pageDescSeo;
    const newPageDescSeo = this.getValueFromPageData('descriptionSEO');
    if (newPageDescSeo !== previousPageDescSeo) {
      this.props.sendBI(
        coreBi.events.topbar.pages.top_bar_PAGES_settings_seo_description_set,
      );
      this.seoInfo.pageDescSeo = newPageDescSeo;
    }
  },

  onPagesSEOKeywordsBlur() {
    const previousPageKeywordsSeo = this.seoInfo.pageKeywordsSEO;
    const newPageKeywordsSEO = this.getValueFromPageData('metaKeywordsSEO');
    if (newPageKeywordsSEO !== previousPageKeywordsSeo) {
      this.props.sendBI(
        coreBi.events.topbar.pages.top_bar_PAGES_settings_seo_keywords_set,
      );
      this.seoInfo.pageKeywordsSEO = newPageKeywordsSEO;
    }
  },

  linkUrlProperty(prop) {
    const pageId = this.getPageId();
    const newObjValue = {};
    const converter = this.props.isSlashFormat
      ? this.props.convertPageNameToUrl
      : pageUtils.convertPageNameToUrl;

    return {
      value: this.getValueFromPageData(prop),
      requestChange: function (newValue) {
        this.props.sendBI(
          coreBi.events.topbar.pages.top_bar_PAGES_settings_seo_title_set,
          {
            field: 'page url',
          },
        );
        newObjValue[prop] = newValue && converter(newValue);
        this.debouncedUpdateEditorAPI(pageId, newObjValue);
      }.bind(this),
    };
  },
  onTitleInputValidation(isValid) {
    if (!isValid && this.state.enableTitleTooltip) {
      this.setState({ isTitleSEOValid: false });
      this.disableTitleTooltip();
    } else if (!this.state.isTitleSEOValid && this.state.enableTitleTooltip) {
      this.setState({ isTitleSEOValid: true });
    }
  },
  isUriSEOTranslated() {
    const translationData = this.getValueFromPageData('translationData');
    return (
      experiment.isOpen('urlFormatGoogleTranslate') &&
      translationData &&
      translationData.uriSEOTranslated
    );
  },
  getValidatorsForSeoKeywords() {
    return [
      _.partial(
        util.validate.rangeOfWords,
        0,
        constants.KEYWORD_SEO_MAX_WORDS,
        ',',
      ),
      util.validate.noEmoji,
    ];
  },
  getPageUriSEOValidators() {
    const validators = getValidators(this.props);
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/map
    return _.map(validators, 'callback');
  },
  getPageUriSEOInvalidMessages() {
    const validators = getValidators(this.props);
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/map
    return _.map(validators, 'message');
  },
  getWixSEOWizURL() {
    return this.props.wixSeoUrl;
  },
  reportSeoWizardLinkClicked() {
    this.props.sendBI(
      coreBi.events.topbar.pages.top_bar_PAGES_settings_seo_wiz_lets_go_click,
    );
  },
  reportAdvancedSEOLinkClicked() {
    this.props.sendBI(coreBi.events.help.HELP_CLICK, {
      learn_more: true,
      panel_name: 'tab_seo_advanced',
      origin: 'pages',
    });
    this.props.openHelpCenter(translate('Pages_SEO_Advanced_SEO_HelpID'));
  },
  onSeoWizardClick() {
    this.reportSeoWizardLinkClicked();
    const SEOWizURL = this.getWixSEOWizURL();
    window.open(SEOWizURL);
  },
  onGoToUrlClicked() {
    this.props.sendBI(
      coreBi.events.topbar.pages.top_bar_PAGES_settings_seo_go_to_url_click,
    );
  },
  enableTitleTooltip() {
    if (this.state.isTitleSEOValid) {
      this.setState({ enableTitleTooltip: true });
    }
  },
  disableTitleTooltip() {
    this.setState({ enableTitleTooltip: false });
  },
  onHidePageChanged(value) {
    const pageId = this.getPageId();
    const newObj = { indexable: !value };
    this.debouncedUpdateEditorAPI(pageId, newObj);
    this.props.sendBI(
      coreBi.events.topbar.pages.top_bar_PAGES_settings_hide_page_clicked,
      {
        title: this.getPageData().title,
        toggle: value,
      },
    );
  },
  getPageTitlePlaceHolder() {
    const { siteDisplayName, isHomePage } = this.props;
    return siteDisplayName
      ? siteDisplayName +
          (isHomePage ? '' : ` | ${this.getValueFromPageData('title')}`)
      : translate('Pages_SEO_Title_NewPlaceholder');
  },
  getTitle() {
    return this.getValueFromPageData('pageTitleSEO')
      ? this.getValueFromPageData('pageTitleSEO')
      : (this.props.title ||
          this.props.siteDisplayName ||
          this.props.siteName) +
          (this.props.isHomePage
            ? ''
            : ` | ${this.getValueFromPageData('title')}`);
  },
  onUploadImageClick() {
    const { mediaManager } = this.props;
    const lastMediaPath = this.props.sessionUserPreferencesuserPref;
    const path =
      lastMediaPath.type === mediaManager.categories.BG_IMAGE
        ? lastMediaPath.path || ''
        : '';
    const pageId = this.getPageId();
    this.props.sendBI(
      coreBi.events.topbar.pages
        .pages_panel_page_settings_SEO_tab_click_upload_image,
    );
    mediaManager.open(mediaManager.categories.IMAGE, {
      callback: (items) => {
        if (items) {
          const uri = items[0].fileName;
          const { width } = items[0];
          const { height } = items[0];
          const ogImageRef = { uri, width, height, type: 'Image' };
          this.props.updatePage(pageId, { ogImageRef });
          this.setState({
            pageSEOImage: uri,
            pageSEOImageWidth: width,
            pageSEOImageHeight: height,
          });
          this.props.setMediaServicesFocusToEditor();
          this.props.sendBI(
            coreBi.events.topbar.pages
              .pages_panel_page_settings_SEO_tab_upload_image_success,
          );
        }
      },
      translation: {
        submitButton: translate(
          'MMGR_API_IMAGE_SEO_SOCIAL_SHARE_PAGE_SUBMIT_BUTTON',
        ),
      },
      multiSelect: false,
      path,
      restrictContent: true,
    });
  },
  getPageSEOImage(targetImageWidth, targetImageHeight) {
    const ogImage =
      this.state.pageSEOImage || util.editorModel.metaSiteData?.ogImage;
    const maxImageWidth = targetImageWidth || 154;
    const maxImageHeight = targetImageHeight || 82;
    const { fittingTypes } = util.imageTransform;

    if (ogImage) {
      const src = {
        id: ogImage,
        width: this.state.pageSEOImageWidth,
        height: this.state.pageSEOImageHeight,
      };
      const target = { width: maxImageWidth, height: maxImageHeight };
      const previewData = util.imageTransform.getData(
        fittingTypes.SCALE_TO_FIT,
        src,
        target,
      );
      return `${util.serviceTopology.staticMediaUrl}/${previewData.uri}`;
    }
  },
  onDeleteImageClick() {
    if (this.state.pageSEOImage) {
      const { pageId } = this.props;
      this.props.sendBI(
        coreBi.events.topbar.pages
          .pages_panel_page_settings_SEO_tab_click_delete_image,
      );
      this.props.updatePage(pageId, { ogImageRef: null });
      this.setState({
        pageSEOImage: null,
        pageSEOImageWidth: null,
        pageSEOImageHeight: null,
      });
    }
  },
  getUploadButtonSymbol() {
    return this.state.pageSEOImage ? 'image-change' : 'upload-btn';
  },
  getSocialShareImageTooltipContent() {
    return React.createElement(socialSharePreviewTooltip, {
      title: 'socialShare_image_preview_title',
      image:
        this.getPageSEOImage(412, 214) ||
        'https://images-wixmp-a87e9a901094cb6400f2e0ce.wixmp.com/images/site-defualt-icon.png/v1/fit/w_412,h_214/file.jpg',
      imageWidth: 412,
      ImageHeight: 214,
      pageURL: this.props.publicURL,
      pageTitle:
        this.linkGooglePreviewProperty('pageTitleSEO').value ||
        this.getPageTitlePlaceHolder(),
      pageDesc:
        /*this.props.previewDescriptionText || */ this.linkGooglePreviewProperty(
          'descriptionSEO',
        ).value || translate('Pages_SEO_Description_Placeholder'),
      openHelp: this.openSEOPreviewHelp,
      onOpen: this.onSocialShareImageTooltipOpen,
      onClose: this.onSocialShareImageTooltipClose,
    });
  },
  openSEOPreviewHelp() {
    const helpItem = 'e9add2d8-76f7-453d-b42f-203a58a628fb';
    this.props.openHelpCenter(helpItem);
  },
  isUriInputDisabled() {
    const currentLanguage = this.props.language.current;
    const originalLanguage = this.props.language.original;
    return currentLanguage !== null && currentLanguage !== originalLanguage;
  },
});

const Connected = util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  SEOPanelMapper.mapStateToProps,
  SEOPanelMapper.mapDispatchToProps,
)(dumbSeoTab);

Connected.pure = dumbSeoTab;

export default Connected;
