import React from 'react';

import { workspace } from '@/util';

import { connectCorvidDevModePanel } from './corvidDevModePanel.mapper';
import { HELP_ARTICLE_IDS } from './corvidDevModePanel.constants';
import {
  CorvidDevModePanelFrame,
  NewWorkspaceCorvidDevModePanelFrame,
} from './corvidDevModePanelFrame';

interface CorvidDevModePanelProps {
  panelName: string;
  closePanel(panelName: string): void;
  enableDeveloperMode(options?: { notifyApps: boolean }): void;
  openHelpCenter(helpId: string, props?: object, biHelpParams?: object): void;
}

class CorvidDevModePanel extends React.Component<CorvidDevModePanelProps> {
  static displayName = 'CorvidDevModePanel';

  private isNewWorkspace = workspace.isNewWorkspaceEnabled();

  getKeyboardShortcuts = () => {
    return {
      esc: this.handleClose,
    };
  };

  handleClose = () => {
    this.props.closePanel(this.props.panelName);
  };

  handleCTAClick = () => {
    this.props.enableDeveloperMode();
    this.handleClose();
  };

  handleLearnMoreClick = () => {
    this.props.openHelpCenter(HELP_ARTICLE_IDS.LEARN_MORE_CORVID);
  };

  render() {
    const PanelFrame = this.isNewWorkspace
      ? NewWorkspaceCorvidDevModePanelFrame
      : CorvidDevModePanelFrame;

    return (
      <PanelFrame
        panelName={this.props.panelName}
        onClose={this.handleClose}
        onPrimaryButtonClick={this.handleCTAClick}
        onSecondaryButtonClick={this.handleLearnMoreClick}
        keyboardShortcuts={this.getKeyboardShortcuts()}
      />
    );
  }
}

export default connectCorvidDevModePanel(CorvidDevModePanel);
