import type { EditorAPI } from '@/editorAPI';
import type { ImageSection } from './types';
import {
  addPanelUtils,
  imageSectionUtils,
  mediaManagerUtils,
} from '@/addPanelInfra';
import constants from '@/constants';

export const createImageSectionAPI = (editorAPI: EditorAPI) => {
  const openMediaManager = (
    category: AnyFixMe,
    openSource: AnyFixMe,
    options: AnyFixMe,
  ) => {
    return mediaManagerUtils.openMediaManager(
      editorAPI,
      category,
      openSource,
      options,
    );
  };

  return {
    async add(section: ImageSection) {
      const [firstPreset] = section.props.items;
      const {
        category,
        openSource,
        path,
        translation,
        compType = constants.COMP_TYPES.PHOTO,
      } = firstPreset;

      const { items } = await openMediaManager(category, openSource, {
        multiSelect: true,
        path,
        translation,
      });

      if (!items) {
        return;
      }

      const addImagePromises = items.map((image) => {
        const compDef = imageSectionUtils.getImageCompDef(
          compType,
          image,
          editorAPI.isMobileEditor(),
        );
        return addPanelUtils.attemptToAddComponent(editorAPI, compDef);
      });

      await Promise.all(addImagePromises);

      editorAPI.mediaServices.setFocusToEditor();
    },
  };
};
