// @ts-nocheck
import _ from 'lodash';

const MINIMUM = 'minimum';
const MAXIMUM = 'maximum';
const DEFAULT = 'default';
let lastFWGalleryWasStretched = false;

const propertiesToMaintain = [
  'columns',
  'rows',
  'margin',
  'textAlignment',
  'showMoreLabel',
  'autoplay',
  'autoplayInterval',
  'galleryImageOnClickAction',
];

const GALLERY_PROPERTIES_MAP = {
  MatrixGalleryProperties: {
    columns: 'numCols',
    rows: 'maxRows',
    margin: 'margin',
    textAlignment: 'alignText',
    showMoreLabel: 'showMoreLabel',
    autoplay: '',
    autoplayInterval: '',
    galleryImageOnClickAction: 'galleryImageOnClickAction',
  },
  PaginatedGridGalleryProperties: {
    columns: 'numCols',
    rows: 'maxRows',
    margin: 'margin',
    textAlignment: '',
    showMoreLabel: '',
    autoplay: 'autoplay',
    autoplayInterval: 'autoplayInterval',
    galleryImageOnClickAction: 'galleryImageOnClickAction',
  },
  SlideShowGalleryProperties: {
    columns: '',
    rows: '',
    margin: '',
    textAlignment: '',
    showMoreLabel: '',
    autoplay: 'autoplay',
    autoplayInterval: 'autoplayInterval',
    galleryImageOnClickAction: 'galleryImageOnClickAction',
  },
  SliderGalleryProperties: {
    columns: '',
    rows: '',
    margin: 'margin',
    textAlignment: '',
    showMoreLabel: '',
    autoplay: '',
    autoplayInterval: '',
    galleryImageOnClickAction: 'galleryImageOnClickAction',
  },
  HoneycombProperties: {
    columns: 'numOfColumns',
    rows: '',
    margin: 'margin',
    textAlignment: 'alignText',
    showMoreLabel: '',
    autoplay: '',
    autoplayInterval: '',
    galleryImageOnClickAction: 'galleryImageOnClickAction',
  },
  CollageProperties: {
    columns: '',
    rows: '',
    margin: 'margin',
    textAlignment: 'alignText',
    showMoreLabel: '',
    autoplay: '',
    autoplayInterval: '',
    galleryImageOnClickAction: 'galleryImageOnClickAction',
  },
  MasonryProperties: {
    columns: 'numCols',
    rows: '',
    margin: 'margin',
    textAlignment: 'alignText',
    showMoreLabel: '',
    autoplay: '',
    autoplayInterval: '',
    galleryImageOnClickAction: 'galleryImageOnClickAction',
  },
  ThumbnailsProperties: {
    columns: '',
    rows: '',
    margin: 'margin',
    textAlignment: 'alignText',
    showMoreLabel: '',
    autoplay: 'autoplay',
    autoplayInterval: 'autoplayInterval',
    galleryImageOnClickAction: 'galleryImageOnClickAction',
  },
  AccordionProperties: {
    columns: '',
    rows: '',
    margin: '',
    textAlignment: 'alignText',
    showMoreLabel: '',
    autoplay: '',
    autoplayInterval: '',
    galleryImageOnClickAction: 'galleryImageOnClickAction',
  },
  FreestyleProperties: {
    columns: '',
    rows: '',
    margin: '',
    textAlignment: '',
    showMoreLabel: '',
    autoplay: '',
    autoplayInterval: '',
    galleryImageOnClickAction: 'galleryImageOnClickAction',
  },
  StripSlideshowProperties: {
    columns: '',
    rows: '',
    margin: '',
    textAlignment: 'alignText',
    showMoreLabel: '',
    autoplay: 'autoplay',
    autoplayInterval: 'autoplayInterval',
  },
  StripShowcaseProperties: {
    columns: '',
    rows: '',
    margin: 'imageMargin',
    textAlignment: '',
    showMoreLabel: '',
    autoplay: 'autoplay',
    autoplayInterval: 'autoplayInterval',
    galleryImageOnClickAction: 'galleryImageOnClickAction',
  },
  ImpressProperties: {
    columns: '',
    rows: '',
    margin: '',
    textAlignment: 'alignText',
    showMoreLabel: '',
    autoplay: 'autoplay',
    autoplayInterval: 'autoplayInterval',
    galleryImageOnClickAction: 'galleryImageOnClickAction',
  },
};

const DEFAULT_WIDTH_FOR_FULL_WIDTH = 568;

function handleMinimumMaximumValues(schemaForProperty, newValue) {
  let valueToChange = newValue;
  if (schemaForProperty.type === 'number' && _.isNumber(newValue)) {
    const minimum = schemaForProperty[MINIMUM];
    const maximum = schemaForProperty[MAXIMUM];

    if (_.isNumber(minimum) && _.isNumber(maximum)) {
      if (newValue > maximum) {
        valueToChange = maximum;
      } else if (newValue < minimum) {
        valueToChange = minimum;
      }
    } else {
      console.info('minimum and maximum are missing or not numbers typed');
    }
  }
  return valueToChange;
}

function convertGalleryProperties(
  origProperties,
  selectedPresetProp,
  editorAPI,
) {
  const originalPropertiesType = origProperties.type;
  const schema = editorAPI.properties.getSchema(
    selectedPresetProp.type,
  ).properties;

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
  _.forEach(propertiesToMaintain, function (propertyName) {
    const origPropertyName =
      GALLERY_PROPERTIES_MAP[originalPropertiesType][propertyName];
    if (origPropertyName) {
      const origPropertyValue = origProperties[origPropertyName];
      const newPropertyName =
        GALLERY_PROPERTIES_MAP[selectedPresetProp.type][propertyName];
      if (newPropertyName) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/is-undefined
        const valueToSet = _.isUndefined(origPropertyValue)
          ? schema[newPropertyName][DEFAULT]
          : handleMinimumMaximumValues(
              schema[newPropertyName],
              origPropertyValue,
            );
        selectedPresetProp[newPropertyName] = valueToSet;
      }
    }
  });

  return selectedPresetProp;
}

function isFullWidthGallery(editorAPI, compPointer) {
  return (
    editorAPI.components.is.fullWidth(compPointer) ||
    editorAPI.components.layout.isHorizontallyStretchedToScreen(compPointer) ||
    lastFWGalleryWasStretched
  );
}

function getLayoutForStretchComponent(editorAPI, compPointer, currentLayout) {
  let docked;

  if (
    editorAPI.components.layout.isHorizontallyStretchedToScreen(compPointer)
  ) {
    // eslint-disable-next-line @wix/santa-editor/deprecatedDSApi
    docked = editorAPI.components.layout.getDock(compPointer);
  } else {
    docked = { left: { vw: 0, px: 0 }, right: { vw: 0, px: 0 } };
  }

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/assign
  return _.assign({}, currentLayout, { docked });
}

function getFallbackLayoutToNofFullWidthComp(editorAPI, currentLayout) {
  const pagesContainerRef = editorAPI.dsRead.siteSegments.getPagesContainer();
  const layoutRelativeToScreen =
    editorAPI.components.layout.getRelativeToScreen(pagesContainerRef);

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/assign
  return _.assign({}, currentLayout, {
    width: DEFAULT_WIDTH_FOR_FULL_WIDTH,
    x: (layoutRelativeToScreen.width - DEFAULT_WIDTH_FOR_FULL_WIDTH) / 2,
  });
}

function convertGalleryLayouts(editorAPI, compPointer, compStructure) {
  const currentLayout = compStructure.layout;
  if (editorAPI.components.is.fullWidthByStructure(compStructure)) {
    return currentLayout;
  }

  if (!isFullWidthGallery(editorAPI, compPointer)) {
    lastFWGalleryWasStretched = false;
    return currentLayout;
  }
  if (editorAPI.components.layout.canBeStretchedByStructure(compStructure)) {
    lastFWGalleryWasStretched =
      editorAPI.components.is.fullWidthByStructure(compStructure);
    return getLayoutForStretchComponent(editorAPI, compPointer, currentLayout);
  }

  lastFWGalleryWasStretched = true;
  return getFallbackLayoutToNofFullWidthComp(editorAPI, currentLayout);
}

export { convertGalleryProperties, convertGalleryLayouts };
