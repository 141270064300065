import React from 'react';
import {
  WorkspaceRightPanelApiKey,
  WorkspaceApiKey,
  EditorAPIKey,
  ZoomModeApiKey,
  EditorPermissionsApiKey,
  EditorRestrictionsApiKey,
  FixedStageApiKey,
} from '@/apis';
import { WorkspaceRightPanelApi } from './workspaceRightPanelApi';
import { WorkspaceRightPanelStore } from './workspaceRightPanelStore';
import { BaseEntryScope, createEntryPoint } from '@/apilib';
import { WorkspaceRightPanel } from './components/workspaceRightPanel';

export class Scope extends BaseEntryScope {
  editorAPI = this.useDependency(EditorAPIKey);
  workspaceApi = this.useDependency(WorkspaceApiKey);
  store = this.declareStore(WorkspaceRightPanelStore);
  zoomModeApi = this.useDependency(ZoomModeApiKey);
  fixedStageApi = this.useDependency(FixedStageApiKey);
  contentSlot = this.declareComponentSlot();
  editorPermissionsApi = this.useDependency(EditorPermissionsApiKey);
  editorRestrictionsApi = this.useDependency(EditorRestrictionsApiKey);
  drillInContentSlot = this.declareComponentSlot();
}

export const WorkspaceRightPanelEntryPoint = createEntryPoint({
  name: 'WorkspaceRightPanel',
  Scope,
  publicApi({ contributeApi }) {
    contributeApi(WorkspaceRightPanelApiKey, WorkspaceRightPanelApi);
  },
  initialize({
    editorPermissionsApi,
    editorRestrictionsApi,
    workspaceApi,
  }: Scope) {
    editorRestrictionsApi.set('workspace-right-panel-close-btn.visible', () =>
      editorPermissionsApi.has('CLASSIC-EDITOR.EDIT-DESIGN'),
    );
    workspaceApi.contributeWorkspaceRightPanel(() => <WorkspaceRightPanel />);
  },
});
