import _ from 'lodash';
import styles from './switchLayoutPanel.scss';
import React, { useState, useMemo } from 'react';
import { ResultsContainer } from './resultsContainer';
import { ResultThumbnail } from './resultThumbnail';
import { ResultPreview } from './resultPreview';
import { Scope } from '../switchLayoutEntryPoint';
import { connectWithScope, type InferComponentProps } from '@/apilib';
import { mapStateToProps, mapDispatchToProps } from './switchLayoutPanelMapper';
import { SwitchLayoutHeader } from './switchLayoutHeader';
import { Preloader, Composites, TextLabel } from '@wix/wix-base-ui';
import { EmptyState } from './emptyState';

export interface OwnProps {}

type SwitchLayoutPanelProps = InferComponentProps<
  typeof mapStateToProps,
  typeof mapDispatchToProps,
  OwnProps
>;

export const SwitchLayoutPanelPure: React.FC<SwitchLayoutPanelProps> = (
  props,
) => {
  const {
    onPresetPreviewBeforeRender,
    layoutOptions,
    selectedId,
    isSelecting,
    originalLayout,
    isLoading,
    onLayoutSelected,
    onOriginalSelected,
    openAddSectionPanel,
    originalSectionTooltipOpen,
    layoutOptionsTooltipOpen,
    isSwitchLayoutPanelOpen,
    sendContentScrolledEvent,
    sectionCorrelationId,
  } = props;

  const [originalPreviewHeight, setOriginalPreviewHeight] = useState(0);
  const debouncedScrollHandler = useMemo<(scrollTop: number) => void>(
    () => _.debounce(sendContentScrolledEvent, 300),
    [sendContentScrolledEvent],
  );
  const onContentScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop } = e.target as HTMLDivElement;
    debouncedScrollHandler(scrollTop);
  };

  let content;
  if (isLoading) {
    content = (
      <Composites.Preloader
        className={styles.preloader}
        automationId="switch-layout-loader"
      >
        <Preloader className="large" />
        <TextLabel type="T02" value="section_designs_loader_title" />
      </Composites.Preloader>
    );
  } else if (layoutOptions.length) {
    content = (
      <div data-hook="switch-layout-content">
        <SwitchLayoutHeader
          longTitle="section_designs_panel_current_title"
          tooltipDescription="section_designs_panel_current_tooltip"
          onTooltipOpen={originalSectionTooltipOpen}
        />
        <ResultThumbnail
          onClick={() => {
            if (!isSelecting) {
              onOriginalSelected(originalLayout.id);
            }
          }}
          resultItem={originalLayout}
          isSelected={selectedId === originalLayout.id}
          isSelecting={isSelecting}
          render={(resultItem) => {
            return (
              <div
                className={styles.resultPreview}
                style={{ maxHeight: `${originalPreviewHeight}px` }}
              >
                <ResultPreview
                  isOriginal={true}
                  onScaled={(scaledHeight) => {
                    setOriginalPreviewHeight(scaledHeight);
                  }}
                  resultItem={resultItem}
                  onPresetPreviewBeforeRender={onPresetPreviewBeforeRender}
                />
              </div>
            );
          }}
        />
        <SwitchLayoutHeader
          longTitle="section_designs_panel_more_designs_title"
          tooltipDescription="section_designs_panel_more_designs_tooltip"
          onTooltipOpen={layoutOptionsTooltipOpen}
        />
        <ResultsContainer
          onPresetPreviewBeforeRender={onPresetPreviewBeforeRender}
          onLayoutSelected={onLayoutSelected}
          layoutOptions={layoutOptions}
          selectedId={selectedId}
          isSelecting={isSelecting}
          sectionCorrelationId={sectionCorrelationId}
        />
      </div>
    );
  } else if (isSwitchLayoutPanelOpen) {
    content = (
      <EmptyState
        iconName={'Empty_State_Editor_Section Design_Browse'}
        title={`section_designs_empty_state_title`}
        subtitle={`section_designs_empty_state_body`}
        linkText={`section_designs_empty_state_main_cta`}
        onLinkClick={openAddSectionPanel}
      />
    );
  }
  return (
    <div
      data-hook="switch-layout-panel"
      className={styles.switchLayoutPanel}
      onScroll={onContentScroll}
    >
      {content}
    </div>
  );
};

export const SwitchLayoutPanel = connectWithScope(
  () => Scope,
  SwitchLayoutPanelPure,
  mapStateToProps,
  mapDispatchToProps,
);
