import React from 'react';
import TextAndLinkPanel, { type PanelData } from './textAndLinkPanel';
import DynamicPanel from './dynamicPanel';

interface CustomPanelProps {
  panelData?: PanelData;
  panelPath?: string;
}

function CustomPanel(props: CustomPanelProps) {
  const { panelData, panelPath } = props;

  return panelPath ? (
    <DynamicPanel panelPath={panelPath} />
  ) : (
    <TextAndLinkPanel panelData={panelData} />
  );
}

export default CustomPanel;
