import _ from 'lodash';
import { arrayUtils, sectionsUtils } from '@/util';
import { layoutUtils } from '@/layoutUtils';
import { isMeshLayoutEnabled } from '@/layoutOneDockMigration';
import { superAppsConstants } from '@/tpa';
import { ErrorReporter } from '@wix/editor-error-reporter';
import { getComponentsWithoutAncestorsInArray } from '@/documentServices';
import constants from '@/constants';

import type { CompLayout, CompRef } from 'types/documentServices';
import type { EditorAPI } from '@/editorAPI';
import type { ComponentsGetApi } from './componentsGetApi';

export function createComponentsShowOnAllPagesApi({
  editorAPI,
  componentsGetApi,
}: {
  editorAPI: EditorAPI;
  componentsGetApi: ComponentsGetApi;
}) {
  function isShowOnAllPages(compRefs: CompRef | CompRef[]): boolean {
    return arrayUtils
      .asArray(compRefs)
      .every(editorAPI.dsRead.components.isShowOnAllPages);
  }

  function isShowOnSomePages(compRefs: CompRef | CompRef[]): boolean {
    return arrayUtils
      .asArray(compRefs)
      .every(editorAPI.dsRead.components.isShowOnSomePages);
  }

  function isAbovePage(selectedComp: CompRef | CompRef[]): boolean {
    const compPage = editorAPI.components.getPage(selectedComp);
    if (compPage.id !== 'masterPage') {
      return false;
    }
    const masterPage = editorAPI.dsRead.siteSegments.getSiteStructure();
    const lastContainerBeforeMaster = componentsGetApi.findAncestor(
      selectedComp,
      (compRef) =>
        editorAPI.utils.isSameRef(
          masterPage,
          editorAPI.components.getContainer(compRef),
        ),
    );

    const pagesContainer = editorAPI.dsRead.siteSegments.getPagesContainer();
    const masterPageChildren = editorAPI.components.getChildren(masterPage);
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/find-index
    const lastContainerBeforeMasterIndex = _.findIndex(
      masterPageChildren,
      function (childComp) {
        return editorAPI.utils.isSameRef(childComp, lastContainerBeforeMaster);
      },
    );
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/find-index
    const pagesContainerIndex = _.findIndex(
      masterPageChildren,
      function (childComp) {
        return editorAPI.utils.isSameRef(childComp, pagesContainer);
      },
    );

    return lastContainerBeforeMasterIndex > pagesContainerIndex;
  }

  function canShowOnCurrentPage(compRefs: CompRef | CompRef[]): boolean {
    const potentialContainer = editorAPI.dsRead.pages.getFocusedPage();

    const isFooterChild = editorAPI.components.isDescendantOfComp(
      compRefs,
      editorAPI.dsRead.siteSegments.getFooter(),
    );

    return (
      editorAPI.components.is.containable(compRefs, potentialContainer) &&
      !isFooterChild
    );
  }

  function canShowOnAllPages(components: CompRef | CompRef[]): boolean {
    components = arrayUtils.asArray(components);
    const potentialContainer = editorAPI.dsRead.siteSegments.getSiteStructure();
    const isComponentContainable =
      editorAPI.components.is.containable(components, potentialContainer) &&
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/some
      !_.some(components, editorAPI.components.is.controlledByParent);
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/some
    const containsGroupedComponents = _.some(
      components,
      editorAPI.dsRead.components.is.groupedComponent,
    );
    const isDescendantOfRepeater =
      editorAPI.components.is.descendantOfRepeaterItem(components);

    return (
      !editorAPI.isCurrentPageLandingPage() &&
      isComponentContainable &&
      !containsGroupedComponents &&
      !editorAPI.dsRead.pages.popupPages.isPopupOpened() &&
      !isDescendantOfRepeater &&
      // eslint-disable-next-line you-dont-need-lodash-underscore/some
      !_.some(components, editorAPI.components.isShowOnSomePages)
    );
  }

  function canToggleShowOnAllPages(compRefs: CompRef | CompRef[]): boolean {
    return (
      compRefs && canShowOnAllPages(compRefs) && canShowOnCurrentPage(compRefs)
    );
  }

  function shouldDisableShowOnAllPages(comp: CompRef | CompRef[]): boolean {
    const compRefs = arrayUtils.asArray(comp);
    if (
      editorAPI.isMobileEditor() &&
      compRefs.some(
        editorAPI.mobile.mobileOnlyComponents.isMobileOnlyNonNativeComponent,
      )
    ) {
      return true;
    }

    if (!comp || !editorAPI.components.isShowOnAllPages(comp)) {
      return false;
    }

    return (
      editorAPI.components.isDescendantOfFrozen(
        // @ts-expect-error
        comp,
        editorAPI.dsRead.siteSegments.getHeader(),
      ) ||
      editorAPI.components.isDescendantOfFrozen(
        // @ts-expect-error
        comp,
        editorAPI.dsRead.siteSegments.getFooter(),
      ) ||
      isAbovePage(comp)
    );
  }

  function shouldHideShowOnAllPages(compRefs: CompRef | CompRef[]): boolean {
    const HIDE_SHOW_ON_ALL_PAGES_TPA_APPS = [
      superAppsConstants.WIX_CHAT.WIDGET,
    ];
    const [firstComponent] = arrayUtils.asArray(compRefs);
    const compData = editorAPI.components.data.get(firstComponent);

    if (!compData?.widgetId) {
      const isBackToTopButton =
        editorAPI.components.getType(firstComponent) ===
        constants.COMP_TYPES.MOBILE_BACK_TO_TOP_BUTTON;
      return isBackToTopButton;
    }

    return HIDE_SHOW_ON_ALL_PAGES_TPA_APPS.includes(compData.widgetId);
  }

  function getSectionForUnSOAPedComp(
    pageSections: { ref: CompRef; layout: CompLayout }[],
    component: CompRef,
  ): CompRef {
    const compLayout = editorAPI.components.layout.get_rect(component);
    const bottom = compLayout.y + compLayout.height;
    const middleY = (compLayout.y + bottom) / 2;

    const section = editorAPI.sections.getSectionAtY(middleY);
    if (section) return section;

    const firstOverlappingSection = pageSections.find((section: any) =>
      layoutUtils.doBoxesOverlap(compLayout, section.layout),
    )?.ref;

    return firstOverlappingSection || pageSections[0]?.ref;
  }

  async function enableShowOnAllPages(compRefs: CompRef[]) {
    const showOnAllPagesContainerRef =
      editorAPI.dsRead.siteSegments.getSiteStructure();

    if (isMeshLayoutEnabled()) {
      const { compRefUpdatedMap } =
        await editorAPI.components.layout.__mesh.reparentAndUpdateContainersGrids(
          compRefs,
          new Map(
            compRefs.map((compRef) => [compRef.id, showOnAllPagesContainerRef]),
          ),
        );

      return compRefs.map(
        (compRef) => compRefUpdatedMap.get(compRef.id) ?? compRef,
      );
    }

    return arrayUtils.asArray(
      editorAPI.components.setContainer(compRefs, showOnAllPagesContainerRef),
    );
  }

  async function disableShowOnAllPages(compRefs: CompRef[]) {
    if (!sectionsUtils.isSectionsEnabled()) {
      const containerToRef = editorAPI.dsRead.pages.getReference(
        editorAPI.dsRead.pages.getPrimaryPageId(),
      );

      return arrayUtils.asArray(
        editorAPI.components.setContainer(compRefs, containerToRef),
      );
    }

    const pageSectionsWithLayout =
      editorAPI.sections.getPageSectionsWithLayoutSortedByStageOrder(
        editorAPI.pages.getCurrentPage(),
        /* useRelativeToStructureLayout: */ true,
      );

    const containerToByCompId = new Map<string, CompRef>(
      compRefs.map((component) => {
        const section = getSectionForUnSOAPedComp(
          pageSectionsWithLayout,
          component,
        );

        const isContainableInSection = editorAPI.components.is.containable(
          component,
          section,
        );
        const primaryPage = editorAPI.dsRead.pages.getReference(
          editorAPI.dsRead.pages.getPrimaryPageId(),
        );

        const container = isContainableInSection ? section : primaryPage;

        return [component.id, container];
      }),
    );

    if (isMeshLayoutEnabled()) {
      const { compRefUpdatedMap } =
        await editorAPI.components.layout.__mesh.reparentAndUpdateContainersGrids(
          compRefs,
          containerToByCompId,
        );

      return compRefs.map(
        (compRef) => compRefUpdatedMap.get(compRef.id) ?? compRef,
      );
    }

    compRefs.forEach((component) => {
      const container = containerToByCompId.get(component.id);

      editorAPI.components.setContainer(component, container);

      if (compRefs.length === 1 && editorAPI.sections.isSection(container)) {
        editorAPI.setAttachCandidate(
          { comp: container, useAnimation: true },
          false,
        );
      }
    });

    return compRefs;
  }

  async function setShowOnAllPages(
    compRefs: CompRef[],
    showOnAllPagesValue: boolean,
  ) {
    compRefs = getComponentsWithoutAncestorsInArray(editorAPI.dsRead, compRefs);

    ErrorReporter.breadcrumb('set show on all pages', {
      components: compRefs,
      value: showOnAllPagesValue,
    });

    return showOnAllPagesValue
      ? enableShowOnAllPages(compRefs)
      : disableShowOnAllPages(compRefs);
  }

  async function toggleShowOnAllPages(compRefOrRefs: CompRef | CompRef[]) {
    const selectedComp = editorAPI.selection.getSelectedComponents();
    const newShowOnAllPages =
      !editorAPI.components.isShowOnAllPages(compRefOrRefs);

    const compRefsUpdated = await setShowOnAllPages(
      arrayUtils.asArray(compRefOrRefs),
      newShowOnAllPages,
    );

    if (selectedComp === compRefOrRefs) {
      editorAPI.selection.selectComponentByCompRef(compRefsUpdated);
    }

    editorAPI.history.add(
      newShowOnAllPages
        ? 'Show component on all pages'
        : 'Show component on current page',
    );

    return newShowOnAllPages;
  }

  return {
    isShowOnAllPages,
    isShowOnSomePages,
    canToggleShowOnAllPages,
    shouldDisableShowOnAllPages,
    shouldHideShowOnAllPages,
    toggleShowOnAllPages,
  };
}
