import React from 'react';
import * as symbols from '@wix/santa-editor-symbols';
import { TextLabel, Button } from '@wix/wix-base-ui';
import * as util from '@/util';
import * as stateManagement from '@/stateManagement';
import { translate } from '@/i18n';
import * as coreBi from '@/coreBi';
import { FocusPanelFrame } from '../../frames';
import { editorWixRecorder } from '@/util';
import {
  startNewWorkspaceTour,
  startWelcomeTour,
  startHelpTour,
} from '@/editorTours';
import type { MapDispatchToProps } from 'types/redux';
import type {
  SendBiFunction,
  BiEventDefinition,
  BiEventFields,
} from 'types/bi';

const {
  hoc: {
    connect,
    STORES: { EDITOR_API },
  },
} = util;

const backgroundImageSrc = util.media.getMediaUrl(
  util.browserUtil.getDevicePixelRatio() > 1
    ? './editorTours/welcomeTour/welcomeTourModalRetina.jpg'
    : './editorTours/welcomeTour/welcomeTourModal.jpg',
);

interface DispatchProps {
  sendBI: SendBiFunction;
  close: () => void;
  startTour: () => void;
  startHelpTour: () => void;
  highlightLeftBar: () => void;
  unhighlightLeftBar: () => void;
}

interface OwnProps {
  title: string;
  description: string;
  primaryButtonLabel: string;
  secondaryButtonLabel: string;
  panelName: string;
  tourToStart: 'welcomeTour' | 'newWorkspaceTour';
}

interface WelcomeTourProps extends DispatchProps, OwnProps {}

class WelcomeTourPanel extends React.Component<WelcomeTourProps> {
  displayName: 'welcomeTourPanel';

  componentDidMount() {
    editorWixRecorder.addLabel(`welcomeTourPanel panel opened`);
    this.props.sendBI(coreBi.events.panels.PANEL_OPENED);
    this.props.unhighlightLeftBar();
  }

  closeByX = () => {
    this.props.sendBI(coreBi.events.panels.PANEL_CLOSED, {
      origin: 'closed',
    });

    this.props.close();
    this.props.startHelpTour();

    this.props.highlightLeftBar();
  };

  closeByESC = () => {
    this.props.sendBI(coreBi.events.panels.PANEL_CLOSED, {
      origin: 'closed',
    });

    this.props.close();
    this.props.startHelpTour();

    this.props.highlightLeftBar();
  };

  onStart = () => {
    this.props.sendBI(coreBi.events.panels.PANEL_CLOSED, {
      origin: 'start_tour',
    });

    this.props.close();
    this.props.startTour();
  };

  onSkip = () => {
    this.props.sendBI(coreBi.events.panels.PANEL_CLOSED, {
      origin: 'maybe_later',
    });

    this.props.close();
    this.props.startHelpTour();

    this.props.highlightLeftBar();
  };

  keyboardShortcuts = {
    esc: this.closeByESC,
  };

  render() {
    return (
      <FocusPanelFrame
        panelName={this.props.panelName}
        shouldHideHeader={true}
        shouldHideHelp={true}
        keyboardShortcuts={this.keyboardShortcuts}
        automationId="welcome-tour-panel"
        className="welcome-tour-panel"
      >
        <div className="panel-header">
          <button onClick={this.closeByX} className="close">
            <symbols.symbol name="headerCloseButton" />
          </button>
          <div className="welcome-tour-panel__cover">
            <img src={backgroundImageSrc} />
          </div>
        </div>

        <div className="welcome-tour-panel__content">
          <TextLabel
            type={'T13'}
            enableEllipsis={false}
            className="welcome-tour-panel__title"
            value={this.props.title}
          />

          <TextLabel
            type={'T01'}
            enableEllipsis={false}
            className="welcome-tour-panel__description"
            value={this.props.description}
          />
        </div>
        <div className="welcome-tour-panel__footer">
          <Button
            automationId="welcome-tour-panel-btn-primary"
            onClick={this.onStart}
            className="welcome-tour-panel__btn-primary"
          >
            {translate(this.props.primaryButtonLabel)}
          </Button>

          <Button
            automationId="welcome-tour-panel-btn-secondary"
            onClick={this.onSkip}
            className="btn-text welcome-tour-panel__btn-secondary"
          >
            {translate(this.props.secondaryButtonLabel)}
          </Button>
        </div>
      </FocusPanelFrame>
    );
  }
}

const getEditorAPI = (
  dispatch: AnyFixMe,
  getState: AnyFixMe,
  { editorAPI }: AnyFixMe,
) => editorAPI;

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
  dispatch,
  props,
) => {
  const editorAPI = dispatch(getEditorAPI);

  return {
    close: () =>
      dispatch(
        stateManagement.panels.actions.closePanelByNameAction(props.panelName),
      ),
    sendBI: (event: BiEventDefinition, params: BiEventFields) =>
      dispatch(
        stateManagement.bi.actions.event(
          event,
          Object.assign(
            {
              panel_name: props.panelName,
            },
            params,
          ),
        ),
      ),
    // Async import are here to work around circular dependency
    startTour: async () => {
      switch (props.tourToStart) {
        case 'newWorkspaceTour':
          return startNewWorkspaceTour(editorAPI);
        case 'welcomeTour':
          return startWelcomeTour(editorAPI);
        default:
          return;
      }
    },
    startHelpTour: async () => {
      if (props.tourToStart === 'newWorkspaceTour') {
        return;
      }
      return startHelpTour(editorAPI);
    },
    highlightLeftBar: () =>
      dispatch(stateManagement.leftBar.actions.highlightMenu()),
    unhighlightLeftBar: () =>
      dispatch(stateManagement.leftBar.actions.unhighlightMenu()),
  };
};

export default connect(
  EDITOR_API,
  undefined,
  mapDispatchToProps,
)(WelcomeTourPanel);
