import {
  DropMode,
  type ICheckCanDrop,
  type IMenuItem,
  type IMenuItemProps,
  menuItemTree,
} from '@/baseUI';

import {
  isDropdown,
  isMembersLoginMenuId,
  isMembersMenuId,
  isSupportsSubsubItems,
} from '../../../utils/utils';
import { isSlotContainer, isSlotItem } from './slotUtils';

import type { MenuData, MenuItem } from '@wix/document-services-types';
import type { EditorAPI } from '@/editorAPI';
import { isAdvancedMenuOpen } from '@/util';

const { getTreeHeight } = menuItemTree;

const MAX_MENU_ITEM_TREE_HEIGHT = 2;
const ADVANCED_MENU_ITEM_TREE_HEIGHT = 3;

const canDropWithSubnesting = (
  nextHeight: number,
  isEmptyDropdown: boolean,
) => {
  const maxMenuItemTreeHeight = isAdvancedMenuOpen()
    ? ADVANCED_MENU_ITEM_TREE_HEIGHT
    : MAX_MENU_ITEM_TREE_HEIGHT;

  return isEmptyDropdown
    ? maxMenuItemTreeHeight > nextHeight
    : maxMenuItemTreeHeight >= nextHeight;
};

export const canDropBeforeOrAfter = (
  dropTargetProps: IMenuItemProps,
  droppedItem: Partial<IMenuItem>,
  isSubnestingAllowed: boolean,
) => {
  if (isSlotItem(dropTargetProps.item.item)) {
    return false;
  }

  if (isSubnestingAllowed) {
    // cannot nest mega menu inside other items
    if (dropTargetProps.nestingLevel > 0 && isSlotContainer(droppedItem)) {
      return false;
    }
    // @ts-expect-error
    const droppedItemHeight = getTreeHeight(droppedItem);

    const nextHeight = droppedItemHeight + dropTargetProps.nestingLevel;
    const isEmptyDropdown = isDropdown(droppedItem) && droppedItemHeight === 1;

    return canDropWithSubnesting(nextHeight, isEmptyDropdown);
  }

  // target item is nested
  if (dropTargetProps.nestingLevel > 0) {
    // cannot drop folder
    if (isDropdown(droppedItem)) {
      return false;
    }

    // cannot drop items with children
    if (droppedItem.items.length > 0) {
      return false;
    }
    // cannot nest mega menu inside other items
    if (isSlotContainer(droppedItem)) {
      return false;
    }
  }

  return true;
};

export const getCheckCanDrop: (
  menu: MenuData,
  editorAPI: EditorAPI,
) => ICheckCanDrop<IMenuItemProps, IMenuItem> = (menu, editorAPI) => {
  const blockDropOn = isMembersLoginMenuId(menu.id);
  const isSubnestingAllowed =
    !isMembersMenuId(menu.id) &&
    isAdvancedMenuOpen() &&
    isSupportsSubsubItems(editorAPI);

  return (dropTargetProps, droppedItem, dropMode = DropMode.on) => {
    // cannot drop on yourself
    if (!droppedItem || droppedItem.id === dropTargetProps.item.id) {
      return false;
    }

    switch (dropMode) {
      case DropMode.on:
        if (blockDropOn) {
          return false;
        }

        // cannot drop on slot container or slot itself
        if (
          isSlotContainer(dropTargetProps.item.item) ||
          isSlotItem(dropTargetProps.item.item)
        ) {
          return false;
        }

        // cannot drop slot container inside other items
        if (isSlotContainer(droppedItem)) {
          return false;
        }

        if (isSubnestingAllowed) {
          // @ts-expect-error
          const droppedItemHeight = getTreeHeight(droppedItem as MenuItem);

          // dropMode = ON, so we add 1 considering that this item is placed inside
          const nextHeight =
            droppedItemHeight + dropTargetProps.nestingLevel + 1;
          const isEmptyDropdown =
            isDropdown(droppedItem) && droppedItemHeight === 1;

          return canDropWithSubnesting(nextHeight, isEmptyDropdown);
        }

        if (dropTargetProps.nestingLevel > 0) {
          return false;
        }

        // cannot drop folder or item with children
        if (isDropdown(droppedItem) || droppedItem.items.length > 0) {
          return false;
        }

        return true;
      case DropMode.after:
      case DropMode.before:
        return canDropBeforeOrAfter(
          dropTargetProps,
          droppedItem,
          isSubnestingAllowed,
        );
    }
  };
};
