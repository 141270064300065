import * as util from '@/util';
import experiment from 'experiment';

const locale = util.languages.getLanguageCode();

const getWixTipsBlogLink = () => {
  const localeLinkMap: Record<string, string> = {
    pt: 'https://pt.wix.com/blog/category/google-seo/',
    es: 'https://es.wix.com/blog/category/google-y-seo/',
    en: 'https://www.wix.com/blog/category/promote-your-site/',
    fr: 'https://fr.wix.com/blog/category/promouvoir-votre-site/',
    it: 'https://it.wix.com/blog/category/promozione-del-sito',
    ru: 'https://ru.wix.com/blog/category/маркетинг',
    de: 'https://de.wix.com/blog/category/vermarkte-deine-website',
    tr: 'https://tr.wix.com/blog/category/dijital-pazarlama',
    ko: 'https://ko.wix.com/blog/category/promote-your-site',
    nl: 'https://nl.wix.com/blog/category/promoot-je-website',
    pl: 'https://pl.wix.com/blog/category/promocja-strony',
    ja: 'https://ja.wix.com/blog/category/マーケティング',
  };

  return localeLinkMap[locale] || localeLinkMap.en;
};

const splitChatAndInbox = experiment.isOpen('se_Ascend_splitChatAndInbox');
const crmProductItemIds = ['Forms'];

if (splitChatAndInbox) {
  crmProductItemIds.push('Chat', 'Inbox');
} else {
  crmProductItemIds.push('ChatInbox');
}
crmProductItemIds.push(
  'ContactList',
  'Automations',
  'Tasks',
  'Workflows',
  'Members',
);

export default {
  helpData: {
    viewA: {
      helpCenterPopUp: { isHidden: true },
      supportForumsPopUp: {
        link: 'https://support.wix.com/en/ascend-by-wix/ascend-plans',
        image: util.media.getMediaUrl('superAppMenuInfra/AscendHelpCenter.png'),
      },
      wixTipsBlog: {
        link: getWixTipsBlogLink(),
        image: util.media.getMediaUrl(
          'superAppMenuInfra/AscendMarketingTips.png',
        ),
      },
      wixVideos: {
        link: 'https://www.youtube.com/playlist?list=PL0y_aclKYoYhj6xhceBS_9huLHBGwK43N',
        image: util.media.getMediaUrl('superAppMenuInfra/AscendTools.png'),
      },
    },
  },
  ascendUpgradePromotilalListItems: [
    'AscendButton_UpradeAscend_BusinessTools_Bullet1',
    'AscendButton_UpradeAscend_BusinessTools_Bullet2',
    'AscendButton_UpradeAscend_BusinessTools_Bullet3',
    'AscendButton_UpradeAscend_BusinessTools_Bullet4',
  ],

  marketingProductItemIds: [
    'EmailMarketing',
    'SEO',
    'FacebookAds',
    'Coupons',
    'SocialPosts',
    'VideoMaker',
    'MarketingIntegrations',
  ],

  crmProductItemIds,

  financeProductItemIds: ['PriceQuotes', 'Invoices', 'FinancialIntegrations'],
};
