// @ts-nocheck
import ReactDOM from 'reactDOM';
import React from 'react';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as core from '@/core';
import * as baseUI from '@/baseUI';
import constants from '@/constants';
import topBarDropPanel from '../../dropPanels/topBarDropPanel';
import LazyComponent from '@/lazyComponent';
import * as BaseUI from '@/baseUI';
import { cx } from '@/util';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'topBarButton',
  mixins: [PureRenderMixin, core.mixins.editorAPIMixin],

  propTypes: {
    label: PropTypes.string,
    classes: PropTypes.string,
    iconSymbol: PropTypes.string,
    dropPanelProps: PropTypes.object,
    actions: PropTypes.array,
    bi: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    biFunc: PropTypes.func,
    isDisabled: PropTypes.bool,
    onMenuOpened: PropTypes.func,
    onMenuClosed: PropTypes.func,
    buttonStyle: PropTypes.object,
    dropPanelContent: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    tooltipData: PropTypes.object,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/values
    progressStatus: PropTypes.oneOf(_.values(constants.PROGRESS_STATUS)),
  },
  getInitialState() {
    this._domNode = null;
    return {
      isDropDownDisplayed: false,
      isMouseOverButton: false,
    };
  },
  getDefaultProps() {
    return {
      buttonStyle: {},
    };
  },
  componentDidMount() {
    this.mounted = true;
    this._domNode = ReactDOM.findDOMNode(this);
    this.showTooltipIfNeeded();
  },
  componentDidUpdate() {
    this.showTooltipIfNeeded();
  },
  componentWillUnmount() {
    this.mounted = false;
    this._domNode = null;
  },
  getClasses() {
    let classes = `top-bar-button ${this.props.classes}`;
    if (this.state.isDropDownDisplayed) {
      classes += ' top-bar-button-selected';
    }
    if (this.isInProgress() || this.props.isDisabled) {
      classes += ' disabled-button';
    } else if (this.isInIdleSaved()) {
      classes += ' saved-button';
    }
    return classes;
  },
  getTooltipValue() {
    //eslint-disable-line react/display-name
    const { tooltipData } = this.props;
    if (tooltipData?.presenter) {
      return React.createElement(tooltipData.presenter, tooltipData.props);
    }
  },

  hideTooltip() {
    if (!this.props.tooltipData) {
      return;
    }

    const onClose = _.isFunction(this.props.tooltipData.onClose)
      ? this.props.tooltipData.onClose
      : _.noop;
    baseUI.tooltipManager.hide(this.props.tooltipData.id, onClose);
  },
  showTooltipIfNeeded() {
    const { tooltipData } = this.props;
    if (!tooltipData) {
      return;
    }
    if (
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/includes
      _.includes(
        tooltipData.openTriggers,
        constants.UI.TOOLTIP.TRIGGERS.IMMEDIATE,
      )
    ) {
      baseUI.tooltipManager.show(tooltipData.id);
    }
  },
  onClick() {
    if (this.props.isDisabled) {
      return;
    }

    if (this.props.dropPanelContent) {
      this.toggleDropDown();
    }

    this.hideTooltip();

    const editorAPI = this.getEditorAPI();
    const selectedComponents = editorAPI.selection.getSelectedComponents();
    const biParams = editorAPI.bi.getComponentsBIParams(selectedComponents);

    if (this.props.actions) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
      _.forEach([].concat(this.props.actions), function (action) {
        action();
      });
    }
    this.sendButtonBI(biParams);
  },
  handleMouseOverButton() {
    this.setState({ isMouseOverButton: true });
  },
  handleMouseLeftButton() {
    this.setState({ isMouseOverButton: false });
  },
  getDropDownId() {
    return `top-bar-dropdown-${this.props.label || this.props.iconSymbol}`;
  },
  getDropDownProps() {
    if (!this._domNode) {
      return {};
    }
    const dropDownContent = this.getDropDownContent();
    return {
      id: this.getDropDownId(),
      value: dropDownContent,
      width: '1000px',
      interactive: true,
      closeTriggers: [constants.UI.TOOLTIP.TRIGGERS.MOUSE_LEAVE],
      className: 'top-bar-drop-down',
      overridePositionAdjustments:
        this.props.dropPanelProps &&
        this.props.dropPanelProps.overridePositionAdjustments,
      targetNode: this._domNode,
      hideMethod: this.handleDropDownWantsToClose,
      mouseLeftTargetNode: !this.state.isMouseOverButton,
      timeoutForMouseOverBubble: 500,
    };
  },
  getDropDownContent() {
    const leftContent = this.getDropPanelLeftContent();
    const rightContentOptions = { main: this.getDropPanelRightContent() };

    if (leftContent) {
      return React.createElement(topBarDropPanel, {
        closeHandle: this.closeDropDown,
        leftContent,
        rightContentOptions,
        dropPanelClass:
          this.props.dropPanelProps && this.props.dropPanelProps.dropPanelClass,
      });
    }
    return null;
  },
  getDropPanelLeftContent() {
    const { dropPanelContent } = this.props;
    const { dropPanelProps } = this.props;
    if (dropPanelContent) {
      dropPanelProps.closeHandle = this.closeDropDown;
      return React.createElement(dropPanelContent, this.props.dropPanelProps);
    }
    return null;
  },
  getDropPanelRightContent() {
    const dropPanelPromotion =
      this.props.dropPanelProps && this.props.dropPanelProps.dropPanelPromotion;
    if (dropPanelPromotion) {
      let props = _.cloneDeep(dropPanelPromotion.props);
      props = _.merge({ moduleName: dropPanelPromotion.type }, props);
      return React.createElement(
        'div',
        { className: dropPanelPromotion.classes }, //eslint-disable-line react/no-deprecated
        [React.createElement(LazyComponent, props)],
      );
    }
    return null;
  },
  openDropDown() {
    this.setState({
      isDropDownDisplayed: true,
      isMouseOverButton: true,
    });
    if (this.props.onMenuOpened) {
      this.props.onMenuOpened();
    }
  },
  closeDropDown() {
    this.setState({
      isDropDownDisplayed: false,
    });
    if (this.props.onMenuClosed) {
      this.props.onMenuClosed();
    }
  },
  toggleDropDown() {
    if (this.state.isDropDownDisplayed) {
      this.closeDropDown();
    } else {
      this.openDropDown();
    }
  },
  handleDropDownWantsToClose() {
    if (this.mounted && !this.state.isMouseOverButton) {
      this.closeDropDown();
    }
  },
  sendButtonBI(biParams) {
    if (this.props.bi) {
      //gets bi props from topBarWidgetDefinitions
      const biEvents = [].concat(this.props.bi);
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
      _.forEach(
        biEvents,
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/bind
        _.bind(function (biEvent) {
          if (biEvent.type) {
            this.getEditorAPI().bi.event(biEvent.type, biEvent.fields);
          }
        }, this),
      );
    }
    if (this.props.biFunc) {
      this.props.biFunc(biParams);
    }
  },
  getTooltipProps() {
    const tooltipValue = this.getTooltipValue();

    const tooltipProps = {
      value: tooltipValue,
      disabled: !tooltipValue || this.state.isDropDownDisplayed,
    };

    const tooltipData = this.props.tooltipData || {};
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    _.assign(
      tooltipProps,
      _.pick(tooltipData, [
        'id',
        'interactive',
        'onClose',
        'closeDelay',
        'openTriggers',
        'closeTriggers',
      ]),
    );

    return tooltipProps;
  },
  isInIdleSaved() {
    return this.props.progressStatus === constants.PROGRESS_STATUS.IDLE_SAVED;
  },

  isInProgress() {
    return this.props.progressStatus === constants.PROGRESS_STATUS.IN_PROGRESS;
  },

  isProgressDone() {
    return this.isDoneSuccessfully() || this.isDoneUnSuccessfully();
  },

  isDoneSuccessfully() {
    return this.props.progressStatus === constants.PROGRESS_STATUS.DONE_SUCCESS;
  },

  isDoneUnSuccessfully() {
    return this.props.progressStatus === constants.PROGRESS_STATUS.DONE_FAILED;
  },
  render() {
    const isDoneSuccess = this.isDoneSuccessfully();

    return (
      <div style={this.props.style} className={this.getClasses()}>
        <BaseUI.tooltip {...this.getTooltipProps()}>
          <div className="wrapper-to-perserve-ref">
            <BaseUI.button2
              key={`buttonContent-${this.props.iconSymbol}`}
              label={this.props.label}
              style={this.props.buttonStyle}
              symbolName={this.props.iconSymbol}
              customLabelClass="top-bar-button-label"
              onClick={this.onClick}
              onMouseOver={this.handleMouseOverButton}
              onMouseLeave={this.handleMouseLeftButton}
              disabled={this.props.isDisabled}
              className={cx({
                'top-bar-button-content': true,
                'icon-and-label': this.props.iconSymbol && this.props.label,
                invisible: isDoneSuccess,
              })}
            />
            {isDoneSuccess ? (
              <BaseUI.symbol
                key="successSymbol"
                name="topBarButtonVSign"
                className="button-symbol vSign"
              />
            ) : null}
            {this.isInProgress() ? (
              <div key="progressIndicator" className="progress-indicator" />
            ) : null}
          </div>
        </BaseUI.tooltip>

        {this.state.isDropDownDisplayed ? (
          <BaseUI.bubble
            key="topBarButtonDropDown"
            {...this.getDropDownProps()}
          />
        ) : null}
      </div>
    );
  },
});
