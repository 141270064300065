:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._container_1mnv0_7:first-of-type ._savedSectionItem_1mnv0_7 {
  margin-top: 48px; }

._addSectionThumbnail_1mnv0_10 {
  position: relative;
  opacity: 0.5;
  margin: 0 auto 34px;
  transition: 0.3s ease-in-out all;
  cursor: pointer;
  border: 1px solid #dfe5eb;
  border-radius: 3px;
  width: 304px; }
  ._addSectionThumbnail_1mnv0_10._savedSectionItem_1mnv0_7 {
    margin-bottom: 42px;
    box-sizing: border-box;
    border: var(--borderWidth) solid #e7f0ff;
    background-color: #e7f0ff; }
  ._addSectionThumbnail_1mnv0_10 ._addSectionThumbnailTitleTop_1mnv0_24 {
    position: absolute;
    top: calc(-24px - var(--borderWidth));
    left: calc(var(--borderWidth) * -1); }
  ._addSectionThumbnail_1mnv0_10 ._addSectionThumbnailTitleBottom_1mnv0_28 {
    position: absolute;
    bottom: -22px;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s ease-in-out; }
  ._addSectionThumbnail_1mnv0_10._hover_1mnv0_35, ._addSectionThumbnail_1mnv0_10._active_1mnv0_35 {
    transform: translateY(-3px);
    box-shadow: 0 10px 15px -14px rgba(49, 53, 59, 0.24); }
    ._addSectionThumbnail_1mnv0_10._hover_1mnv0_35 ._addSectionThumbnailTitleBottom_1mnv0_28, ._addSectionThumbnail_1mnv0_10._active_1mnv0_35 ._addSectionThumbnailTitleBottom_1mnv0_28 {
      opacity: 1; }
    ._addSectionThumbnail_1mnv0_10._hover_1mnv0_35 ._contextMenuWrapper_1mnv0_40, ._addSectionThumbnail_1mnv0_10._active_1mnv0_35 ._contextMenuWrapper_1mnv0_40 {
      display: block; }
  ._addSectionThumbnail_1mnv0_10 ._contextMenuWrapper_1mnv0_40 {
    display: none; }
  ._addSectionThumbnail_1mnv0_10.complete {
    opacity: 1; }
  ._addSectionThumbnail_1mnv0_10 > ._videoIcon_1mnv0_46 {
    color: #fff;
    width: 25px;
    height: 25px;
    position: absolute;
    bottom: 6px;
    left: 6px; }
