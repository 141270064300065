import _ from 'lodash';
import addPanelDataConsts from '@/addPanelDataConsts';

const category = {
  '1024': addPanelDataConsts.CATEGORIES_ID.GALLERY,
  '1025': addPanelDataConsts.CATEGORIES_ID.VIDEO,
  '1026': addPanelDataConsts.CATEGORIES_ID.MUSIC,
  '1027': addPanelDataConsts.CATEGORIES_ID.SOCIAL,
  '1028': addPanelDataConsts.CATEGORIES_ID.CONTACT,
  '1029': addPanelDataConsts.CATEGORIES_ID.IMAGE,
  '1030': addPanelDataConsts.CATEGORIES_ID.OTHER,
  '1031': addPanelDataConsts.CATEGORIES_ID.BUTTON,
  '65590': addPanelDataConsts.CATEGORIES_ID.EMBEDS,
  '78264': addPanelDataConsts.CATEGORIES_ID.DECORATIVE,
  '46014': addPanelDataConsts.CATEGORIES_ID.MEDIA,
  '1032': addPanelDataConsts.CATEGORIES_ID.BOX,
  '1034': addPanelDataConsts.CATEGORIES_ID.NAVIGATION,
  '1035': addPanelDataConsts.CATEGORIES_ID.VECTOR_SHAPE,
  '1036': addPanelDataConsts.CATEGORIES_ID.TEXT,
  '1037': addPanelDataConsts.CATEGORIES_ID.LIST,
  '1038': addPanelDataConsts.CATEGORIES_ID.BLOG,
  '2558': addPanelDataConsts.CATEGORIES_ID.MEMBERS,
  '2559': addPanelDataConsts.CATEGORIES_ID.MEMBERS_WELCOME,
  '20213': addPanelDataConsts.CATEGORIES_ID.NEW_BLOG,
  '89377': addPanelDataConsts.CATEGORIES_ID.NEW_BLOG_WELCOME,
  '2589': addPanelDataConsts.CATEGORIES_ID.BOOKINGS,
  '60124': addPanelDataConsts.CATEGORIES_ID.BOOKINGS_WELCOME,
  '90517': addPanelDataConsts.CATEGORIES_ID.EVENTS,
  '60724': addPanelDataConsts.CATEGORIES_ID.EVENTS_WELCOME,
  '1039': addPanelDataConsts.CATEGORIES_ID.STORE,
  '1153': addPanelDataConsts.CATEGORIES_ID.STORE,
};

const apps: AnyFixMe = {};

const getCategoryNumber = function (categoryName: AnyFixMe) {
  switch (categoryName) {
    case addPanelDataConsts.CATEGORIES_ID.GALLERY:
      return '1024';
    case addPanelDataConsts.CATEGORIES_ID.DECORATIVE:
      return '78264';
    case addPanelDataConsts.CATEGORIES_ID.MEDIA:
      return '46014';
    case addPanelDataConsts.CATEGORIES_ID.EMBEDS:
      return '65590';
    case addPanelDataConsts.CATEGORIES_ID.EVENTS:
      return '90517';
    case addPanelDataConsts.CATEGORIES_ID.EVENTS_WELCOME:
      return '60724';
    case addPanelDataConsts.CATEGORIES_ID.VIDEO:
      return '1025';
    case addPanelDataConsts.CATEGORIES_ID.MUSIC:
      return '1026';
    case addPanelDataConsts.CATEGORIES_ID.SOCIAL:
      return '1027';
    case addPanelDataConsts.CATEGORIES_ID.CONTACT:
      return '1028';
    case addPanelDataConsts.CATEGORIES_ID.IMAGE:
      return '1029';
    case addPanelDataConsts.CATEGORIES_ID.OTHER:
      return '1030';
    case addPanelDataConsts.CATEGORIES_ID.BUTTON:
      return '1031';
    case addPanelDataConsts.CATEGORIES_ID.BOX:
      return '1032';
    case addPanelDataConsts.CATEGORIES_ID.MENU:
    case addPanelDataConsts.CATEGORIES_ID.NAVIGATION:
      return '1034';
    case addPanelDataConsts.CATEGORIES_ID.VECTOR_SHAPE:
      return '1035';
    case addPanelDataConsts.CATEGORIES_ID.TEXT:
      return '1036';
    case addPanelDataConsts.CATEGORIES_ID.LIST:
      return '1037';
    case addPanelDataConsts.CATEGORIES_ID.BLOG:
      return '1038';
    case addPanelDataConsts.CATEGORIES_ID.MEMBERS:
      return '2558';
    case addPanelDataConsts.CATEGORIES_ID.MEMBERS_WELCOME:
      return '2559';
    case addPanelDataConsts.CATEGORIES_ID.NEW_BLOG:
      return '20213';
    case addPanelDataConsts.CATEGORIES_ID.NEW_BLOG_WELCOME:
      return '89377';
    case addPanelDataConsts.CATEGORIES_ID.STORE:
      return '1153';
    case `${addPanelDataConsts.CATEGORIES_ID.STORE}_post`:
      return '1039';
    case addPanelDataConsts.CATEGORIES_ID.BOOKINGS:
      return '2589';
    case addPanelDataConsts.CATEGORIES_ID.BOOKINGS_WELCOME:
      return '60124';
  }
};

const getCategoriesNums = function () {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/keys
  return _.keys(category);
};

let _addPanelCategoriesToOmit: AnyFixMe;

const init = function (
  editorAPI: AnyFixMe,
  addPanelCategoriesToOmit: AnyFixMe,
  callback: AnyFixMe,
) {
  _addPanelCategoriesToOmit = addPanelCategoriesToOmit;
  editorAPI.dsRead.tpa.appMarket.getRelatedApps(function (
    relatedApps: AnyFixMe,
  ) {
    initRelatedApps(relatedApps);
    callback();
  });
};

const initRelatedApps = function (relatedApps: AnyFixMe) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
  _.forEach(relatedApps, function (app) {
    const appCategoriesNums = _.intersection(
      app.categories,
      getCategoriesNums(),
    );
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
    _.forEach(appCategoriesNums, function (categoryNum) {
      addToApps(categoryNum, app);
    });
  });
};

const addToApps = function (categoryNum: AnyFixMe, app: AnyFixMe) {
  apps[categoryNum] = apps[categoryNum] ? apps[categoryNum] : [];
  apps[categoryNum].push(app);
};

const getCategories = function () {
  const categories = _(category)
    // eslint-disable-next-line you-dont-need-lodash-underscore/keys
    .pick(_.keys(apps))
    .values()
    .xor(_addPanelCategoriesToOmit)
    .value();
  return categories;
};

const getSortedCategoryApps = function (categoryNum: AnyFixMe) {
  return _.sortBy(apps[categoryNum], function (app) {
    return app.weights ? -app.weights.categories[categoryNum] : 0;
  });
};

const getCategoryApps = function (
  editorAPI: AnyFixMe,
  categoryNum: AnyFixMe,
  callback: AnyFixMe,
) {
  if (_.isEmpty(apps)) {
    init(editorAPI, [], function () {
      callback(getSortedCategoryApps(categoryNum));
    });
  } else {
    const sortedApps = getSortedCategoryApps(categoryNum);
    callback(sortedApps);
  }
};

const getApps = function () {
  return apps;
};

export default {
  init,
  initRelatedApps,
  getApps,
  getCategories,
  getCategoryApps,
  getCategoryNumber,
};
