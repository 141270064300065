import React from 'react';
import styles from './switchLayoutPanel.scss';
import { Text, InfoIcon } from '@wix/wix-base-ui';

export const SwitchLayoutHeader = (props: any) => {
  const { longTitle, tooltipDescription, onTooltipOpen } = props;
  return (
    <div className={styles['switch-layout-header']}>
      <Text size="small">{longTitle}</Text>
      <InfoIcon
        onTooltipOpen={onTooltipOpen}
        shouldTranslate={true}
        text={tooltipDescription}
      />
    </div>
  );
};
