import { ConditionsPriority, Conditions } from './constants';

import { getDesktopEditorConditionStructure } from './conditions/desktopEditor';
import { getMobileEditorConditionStructure } from './conditions/mobileEditor';
import { getDataModeConditionStructure } from './conditions/dataMode';
import { getDevModeConditionStructure } from './conditions/devMode';
import { getMultilingualConditionStructure } from './conditions/multilingual';
import { getRequiredAppConditionStructure } from './conditions/requiredApp';
import { getSitePublishConditionStructure } from './conditions/publishedSite';

import type { EditorAPI } from '@/editorAPI';

import type { SearchResultItem } from '../types';
import type { SearchResultConditions } from './types';

interface ConditionStructuresMap {
  [Conditions.InstalledApp]: ReturnType<
    typeof getRequiredAppConditionStructure
  >;
  [Conditions.Multilingual]: ReturnType<
    typeof getMultilingualConditionStructure
  >;
  [Conditions.DesktopEditor]: ReturnType<
    typeof getDesktopEditorConditionStructure
  >;
  [Conditions.MobileEditor]: ReturnType<
    typeof getMobileEditorConditionStructure
  >;
  [Conditions.DataMode]: ReturnType<typeof getDataModeConditionStructure>;
  [Conditions.DevMode]: ReturnType<typeof getDevModeConditionStructure>;
  [Conditions.SitePublish]: ReturnType<typeof getSitePublishConditionStructure>;
}

export class ConditionsManager {
  private readonly conditions: ConditionStructuresMap;

  constructor(editorAPI: EditorAPI) {
    this.conditions = {
      [Conditions.InstalledApp]: getRequiredAppConditionStructure(editorAPI),
      [Conditions.Multilingual]: getMultilingualConditionStructure(editorAPI),
      [Conditions.DesktopEditor]: getDesktopEditorConditionStructure(editorAPI),
      [Conditions.MobileEditor]: getMobileEditorConditionStructure(editorAPI),
      [Conditions.DataMode]: getDataModeConditionStructure(editorAPI),
      [Conditions.DevMode]: getDevModeConditionStructure(editorAPI),
      [Conditions.SitePublish]: getSitePublishConditionStructure(editorAPI),
    };
  }

  getConditionalResult = <TResult extends SearchResultItem>(
    result: TResult,
    condition: Conditions,
  ) => {
    const structure = this.conditions[condition];
    const translations = result.translations[structure.translationsKey];

    return {
      ...result,
      //TODO: Check only for migration, must be removed after all items use new format
      tooltipData: translations
        ? { ...translations }
        : {
            cta: result.translations.conditionCTA,
            description: result.translations.conditionDescription,
          },
      onSubmit: this.conditions[condition].getAction(result),
    };
  };

  getFailedConditionForResult(result: SearchResultConditions) {
    if (!result) {
      return null;
    }

    const keysInExtras = Object.keys(result.extras);
    const failedCondition = ConditionsPriority.find((condition) => {
      const structure = this.conditions[condition];

      return (
        keysInExtras.includes(structure.metadataKey) && structure.check(result)
      );
    });

    return failedCondition;
  }
}
