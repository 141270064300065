import React from 'react';
import _ from 'lodash';

import * as util from '@/util';

import useKeyboardShortcuts from '../../hooks/useKeyboardShortcuts';

const OuterClick = util.outerClick;

export interface PanelFrameContainerProps {
  overlayDataHook?: string;
  className?: string;
  onOuterClick?: () => void;
  onEscKeyPress?: (event: KeyboardEvent) => void;
  keyboardShortcuts?: {
    esc?: (event: KeyboardEvent) => void;
    enter?: (event: KeyboardEvent) => void;
    tab?: (event: KeyboardEvent) => void;
  };
}

export const PanelFrameContainer: React.FC<PanelFrameContainerProps> = (
  props,
) => {
  let { keyboardShortcuts } = props;

  if (props.onEscKeyPress) {
    keyboardShortcuts = {
      esc: props.onEscKeyPress,
    };
  }

  useKeyboardShortcuts(keyboardShortcuts);

  const className = util.cx(
    'panel-frame-container',
    'screen-centered-frame',
    'message-panel-frame-z-index',
    props.className,
  );

  return (
    <div className={className}>
      <div className="dark-frame-overlay" data-hook={props.overlayDataHook} />
      <OuterClick onOuterClick={props.onOuterClick}>
        {props.children}
      </OuterClick>
    </div>
  );
};

PanelFrameContainer.defaultProps = {
  onOuterClick: _.noop,
};

export default PanelFrameContainer;
