import React from 'react';
import * as panels from '@/panels';
import { translate } from '@/i18n';
import { TextLabel } from '@wix/wix-base-ui';
import * as symbols from '@wix/santa-editor-symbols';

interface ReplacePageModalProps {
  panelName: string;
  replacePage: () => void;
  closeModal: () => void;
  openHelpCenter: (helpId: string) => void;
}

const ReplacePageModal = ({
  panelName,
  openHelpCenter,
  replacePage,
  closeModal,
}: ReplacePageModalProps) => {
  return (
    <panels.frames.MessagePanelFrame
      panelName={panelName}
      className="replace-page-modal"
      dataHook="replace-page-modal"
      onConfirm={replacePage}
      confirmLabel={translate(
        'add_page_preset_404_replace_popup_replace_button',
      )}
      onCancel={closeModal}
      cancelLabel={translate('add_page_preset_404_replace_popup_cancel_button')}
      onHelp={() => openHelpCenter('d041f0d3-dffc-4675-b12d-e50527124d69')}
      title={translate('add_page_preset_404_replace_popup_title')}
      illustration={<symbols.symbol name="404Premium" />}
    >
      <TextLabel
        type="T06"
        enableEllipsis={false}
        value="add_page_preset_404_replace_popup_text"
      />
    </panels.frames.MessagePanelFrame>
  );
};

export default ReplacePageModal;
