// @ts-nocheck
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import * as core from '@/core';
import React from 'react';
import PageSettingsFrame from '../pageSettingsFrame';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  mixins: [core.mixins.editorAPIMixin],
  displayName: 'pageSettingsMobileCommon',
  propTypes: {
    title: PropTypes.string,
    closeSettingsPanel: PropTypes.func.isRequired,
  },
  getHelpId() {
    const editorAPI = this.getEditorAPI();
    if (editorAPI.developerMode.isEnabled()) {
      return '2d9fb141-61ac-45e6-809d-a8f178c6c1bc';
    }
    return '24b83680-5690-488d-b382-ac051e329c23';
  },
  render() {
    return (
      <PageSettingsFrame
        title={this.props.title}
        closeSettingsPanel={this.props.closeSettingsPanel}
        helpId={this.getHelpId()}
      >
        <div className="page-setting-mobile page-settings-panel-content">
          {this.props.children}
        </div>
      </PageSettingsFrame>
    );
  },
});
