'use strict'

const textButtonsSection = require('../sections/button/textButtonsSection')
const iconButtonsSection = require('../sections/button/iconButtonsSection')
const stylableButtonSection = require('../sections/button/stylableButtonSectionRedesign')
const fileDownloadButtonsSection = require('../sections/other/fileDownloadButtonsSection')
const fileUploaderSection = require('../sections/developer/fileUploaderSection')
const iTunesDownloadButtonSection = require('../sections/music/iTunesDownloadButtonSection')
const payButtonSection = require('../sections/other/payButtonSection')
const paypalButtonsSection = require('../sections/other/paypalButtonsSection')

module.exports = {
  iTunesDownloadButtonSection,
  fileUploaderSection,
  fileDownloadButtonsSection,
  textButtonsSection,
  iconButtonsSection,
  stylableButtonSection,

  payButtonSection,
  paypalButtonsSection
}
