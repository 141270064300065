import type { EditorAPI } from '@/editorAPI';
import type { CompRef } from 'types/documentServices';
import * as coreBi from '@/coreBi';
import * as stateManagement from '@/stateManagement';
import { moveWidgetPluginAction } from './shared/widgetPlugin';

function notifyApp(editorAPI: EditorAPI, compPointer: CompRef) {
  const appDefId = editorAPI.components.data.get(compPointer).appDefinitionId;
  const appData = editorAPI.dsRead.platform.getAppDataByAppDefId(appDefId);
  if (!appData) {
    return;
  }
  editorAPI.dsActions.platform.notifyApplication(appData.applicationId, {
    eventType: 'resetWidgetOverrides',
    eventPayload: {
      componentRef: compPointer,
    },
  });
}

export default [
  moveWidgetPluginAction,
  {
    label: 'RightClick_Menu_Reset_Customization_Label',
    onClick: (editorAPI: EditorAPI, compPointer: CompRef) => {
      editorAPI.documentServices.components.refComponents.removeAllOverrides(
        compPointer,
      );
      notifyApp(editorAPI, compPointer);
      editorAPI.closeRightClickMenu();
      editorAPI.history.add('Reset Customization clicked');
      editorAPI.store.dispatch(
        stateManagement.bi.actions.event(
          coreBi.events.refComponent.resetCustomization,
          {
            component_id: compPointer.id,
            component_type: editorAPI.components.getType(compPointer),
          },
        ),
      );
    },
    tooltip: 'RightClick_Menu_Reset_Customization_Tooltip',
    enabled: true,
    automationId: 'rcmResetCustomization',
  },
];
