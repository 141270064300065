import { createReduxStore } from '@/apilib';
import { WorkspaceModes } from './consts';
import { getModeFromLocalStorage } from './utils';

export interface WorkspaceModesStore {}

interface WorkspaceModesState {
  selectionInterceptorEnabled: boolean;
  activeWorkspaceMode: WorkspaceModes;
}

const getInitialState = (): WorkspaceModesState => ({
  selectionInterceptorEnabled: true,
  activeWorkspaceMode: getModeFromLocalStorage() ?? WorkspaceModes.FULL,
});

export const WorkspaceModesStore = createReduxStore({
  getInitialState,
  selectors: {
    getIsSelectionInterceptorEnabled: (state: WorkspaceModesState) =>
      state.selectionInterceptorEnabled,
    getActiveWorkspaceMode: (state: WorkspaceModesState) =>
      state.activeWorkspaceMode,
  },
  actions: {
    setSelectionInterceptorEnabled: (
      state: WorkspaceModesState,
      selectionInterceptorEnabled: boolean,
    ) => {
      return {
        ...state,
        selectionInterceptorEnabled,
      };
    },
    setActiveWorkspaceMode: (
      state: WorkspaceModesState,
      activeWorkspaceMode: WorkspaceModes,
    ) => {
      return {
        ...state,
        activeWorkspaceMode,
      };
    },
  },
});
