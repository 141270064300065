'use strict'

const basicShapesSection = require('../sections/vectorShape/basicShapesSection')
const arrowsSection = require('../sections/vectorShape/arrowsSection')
const horizontalLinesSection = require('../sections/vectorShape/horizontalLinesSection')
const verticalLinesSection = require('../sections/vectorShape/verticalLinesSection')

module.exports = {
    basicShapesSection,
    arrowsSection,
    horizontalLinesSection,
    verticalLinesSection
}
