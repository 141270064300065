// @ts-nocheck
import * as wixStoreEditorActionsService from '../common/wixStoreEditorActionsService';

function handleDeepLink(etpaState) {
  if (isDashboardType(etpaState)) {
    wixStoreEditorActionsService.openAppDashboardInEditor({
      state: etpaState.state,
      origin: 'wixstores_deep_link',
    });
  }
}

function isDashboardType(reason) {
  return !!reason && reason.type === 'wixstores.openDashboard';
}

export { handleDeepLink };
