._siteCreationHorizontalLoader_s7gr5_1 {
  position: relative;
  height: 5px;
  width: 100%;
  margin: 0 auto;
  content: '';
  display: block;
  bottom: 5px;
  background-size: 100%;
  background-repeat: no-repeat; }
