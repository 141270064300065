import React, { Component, type ComponentType, type ReactElement } from 'react';
import _ from 'lodash';
import constants from '@/constants';
import BranchIndicatorPanel from '../../dropPanel/branchIndicatorPanel/branchIndicatorPanel';

const { LABELS } = constants.ROOT_COMPS.TOPBAR;

interface WithBranchIndicatorDropPanelProps {}

interface ComponentWithBranchIndicatorDropPanelProps {
  dropPanelContent?: ReactElement | null;
  dropPanelKey?: string;
}

const withBranchIndicatorDropPanel = <
  P extends WithBranchIndicatorDropPanelProps,
>(
  WrappedComponent: ComponentType<
    P & ComponentWithBranchIndicatorDropPanelProps
  >,
) => {
  class WithBranchIndicatorDropPanel extends Component<WithBranchIndicatorDropPanelProps> {
    render() {
      const { props } = this;
      return React.createElement(
        WrappedComponent,
        _.defaults({}, props as P, {
          dropPanelKey: LABELS.VERSION_INDICATOR,
          dropPanelContent: <BranchIndicatorPanel />,
          panelTopMargin: 15,
        }),
      );
    }
  }

  return WithBranchIndicatorDropPanel;
};

export { withBranchIndicatorDropPanel };
