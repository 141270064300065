// @ts-nocheck
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as fontUtils from '../utils/fontUtils';
import * as core from '@/core';
import * as util from '@/util';
import { SafeInjectHtml } from '@/util';
import themeFontsLoaderMapper from './themeFontsLoaderMapper';
import React from 'react';

const {
  connect,
  STORES: { STATE_ONLY },
} = util.hoc;

// eslint-disable-next-line react/prefer-es6-class
const ThemeFontsLoader = createReactClass({
  displayName: 'themeFontsLoader',
  mixins: [core.mixins.editorAPIMixin],
  propTypes: {
    uploadedFontsList: PropTypes.array,
    themeFonts: PropTypes.object,
  },
  getCssLinks() {
    return this.getEditorAPI().dsRead.fonts.css.getUrls();
  },
  render() {
    return (
      <div>
        {/* TODO: Fix this the next time the file is edited. */}
        {/* eslint-disable-next-line you-dont-need-lodash-underscore/map */}
        {_.map(this.getCssLinks(), (link, linkIndex) => (
          <link type="text/css" key={linkIndex} href={link} rel="stylesheet" />
        ))}
        {/* TODO: Fix this the next time the file is edited. */}
        {/* eslint-disable-next-line you-dont-need-lodash-underscore/map */}
        {_.map(
          this.props.uploadedFontsList,
          (uploadedFont, uploadedFontIndex) => (
            <SafeInjectHtml
              tag="style"
              key={uploadedFontIndex}
              html={uploadedFont.fontFace}
            />
          ),
        )}
        {/* TODO: Fix this the next time the file is edited. */}
        {/* eslint-disable-next-line you-dont-need-lodash-underscore/map */}
        {_.map(
          this.getUploadedThemeFonts(this.props.themeFonts),
          (themeUploadedFont, themeUploadedFontIndex) => (
            <SafeInjectHtml
              tag="style"
              key={themeUploadedFontIndex}
              html={this.createUploadedFontFace(themeUploadedFont)}
            />
          ),
        )}
      </div>
    );
  },
  shouldComponentUpdate(nextProps) {
    return !_.isEqual(nextProps, this.props);
  },
  createUploadedFontFace(uploadedFont) {
    return fontUtils.getUploadedFontFace([uploadedFont]);
  },
  getUploadedThemeFonts() {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/filter
    return _.filter(
      fontUtils.getThemeFontFamilies(this.props.themeFonts),
      function (fontFamily) {
        return fontUtils.isUploadedFont(fontFamily);
      },
    );
  },
});

const Connected = connect(STATE_ONLY, themeFontsLoaderMapper)(ThemeFontsLoader);

Connected.pure = ThemeFontsLoader;

export default Connected;
