export enum BookingsServiceType {
  INDIVIDUAL = 'INDIVIDUAL',
  GROUP = 'GROUP',
  COURSE = 'COURSE',
}

export interface BookingsServiceCategory {
  id: string;
  name: string;
  sortOrder: number;
  status: string;
}

export interface BookingsResource {
  id: string;
  schedules: BookingsSchedule[];
}

export interface BookingsServiceSkeleton {
  info: {
    name: string;
    tagLine?: string;
    description: string;
    images: BookingsServiceImage[];
  };
  type: BookingsServiceType;
  categoryId: string;
  staffMember: BookingsResource;
}

interface BookingsServiceImage {
  url: string;
  height: number;
  width: number;
}

interface BookingsSchedule {
  id: string;
}
