// @ts-nocheck
import * as addPanel from '@/oldAddPanel';
import section1 from './live/section1';
import section2 from './live/section2';
const { dataUtil } = addPanel;

export default {
  getCategorySections() {
    const addPanelGallerySection = addPanel.sections.getSections().gallery;

    const gallerySections = {
      gridGalleriesSection: dataUtil.mergeSectionVideos(
        addPanelGallerySection.gridGalleriesSection,
        addPanelGallerySection.gridGalleriesSectionVideos,
      ),
      sliderGalleriesSection: dataUtil.mergeSectionVideos(
        addPanelGallerySection.sliderGalleriesSection,
        addPanelGallerySection.sliderGalleriesSectionVideos,
      ),
      '3DGalleriesSection': dataUtil.mergeSectionVideos(
        addPanelGallerySection.threeDGalleriesSection,
        addPanelGallerySection.threeDGalleriesSectionVideos,
      ),
      fullWidthGalleriesSection: dataUtil.mergeSectionVideos(
        addPanelGallerySection.fullWidthGalleriesSection,
        addPanelGallerySection.fullWidthGalleriesSectionVideos,
      ),
      moreGalleriesSection: dataUtil.mergeSectionVideos(
        addPanelGallerySection.moreGalleriesSection,
        addPanelGallerySection.moreGalleriesSectionVideos,
      ),
    };

    return dataUtil.mergeWithCommonSections(gallerySections, {
      section_1: section1,
      section_2: section2,
    });
  },
};
