// @ts-nocheck
import createReactClass from 'create-react-class';
import _ from 'lodash';
import React from 'react';
import * as symbols from '@wix/santa-editor-symbols';
import {
  Composites,
  TextLabel,
  Thumbnails,
  Slider,
  Divider,
} from '@wix/wix-base-ui';
const POSITIONS = {
  none: 'none',
  full: 'full',
  bottom: 'bottom',
};

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'borderPositionControl',
  render() {
    return (
      <div>
        <Composites.Thumbnails>
          <TextLabel value="CustomDesign_LangMenu_Border_Position" />
          <Thumbnails
            value={this.state.currSelected}
            onChange={this.onBorderPositionChange}
            maxThumbsPerRow={3}
            fixedRatio={true}
            options={this.borderPositions}
            className="border-position-switch"
          />
        </Composites.Thumbnails>
        {!this.isNoBorder() ? <Divider long={false} /> : null}
        {!this.isNoBorder() ? (
          <Composites.SliderLabeled key="borderWidthSlider">
            <TextLabel value="CustomDesign_Borders_Width" />
            <Slider
              value={Math.round(this.getWidth())}
              min={0}
              max={15}
              onChange={this.onWidthChange}
              unit={this.getUnit()}
            />
          </Composites.SliderLabeled>
        ) : null}
      </div>
    );
  },
  getInitialState() {
    const currentValue = this.props.valueLink.value;
    let currSelected = POSITIONS.full;

    if (
      currentValue === '0spx' ||
      currentValue === '0px' ||
      +currentValue === 0
    ) {
      currSelected = POSITIONS.none;
    } else if (!_.isNumber(currentValue)) {
      const listOfWidths = this.listOfWidth(this.props.valueLink.value);
      if (listOfWidths.length > 1) {
        currSelected = POSITIONS.bottom;
      }
    }

    return { currSelected };
  },
  listOfWidth(border) {
    return border
      .replace(/(s)*px/g, '')
      .split(' ')
      .map((width) => Number(width));
  },
  getWidth(value = this.props.valueLink.value) {
    const getNumberFromBorder = (border) => {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/filter
      const widths = _.filter(this.listOfWidth(border), (width) => width > 0);
      return widths.length ? widths[0] : 0;
    };
    return _.isNumber(value) ? value : getNumberFromBorder(value);
  },
  getUnit(value = this.props.valueLink.value) {
    return value.includes('spx') ? 'spx' : 'px';
  },
  changeBottomBorderWidth(borderWidth) {
    const unit = this.getUnit();
    this.props.valueLink.requestChange(
      `0${unit} 0${unit} ${borderWidth}${unit} 0${unit}`,
    );
  },
  changeFullBorderWidth(borderWidth) {
    this.props.valueLink.requestChange(`${borderWidth}${this.getUnit()}`);
  },
  changeWidth(currSelected, borderWidth) {
    switch (currSelected) {
      case POSITIONS.bottom:
        this.changeBottomBorderWidth(borderWidth);
        break;
      default:
        this.changeFullBorderWidth(borderWidth);
        break;
    }
  },
  onWidthChange(borderWidth) {
    this.changeWidth(this.state.currSelected, borderWidth);
  },
  onBorderPositionChange(newSelection) {
    if (this.state.currSelected === newSelection) {
      return;
    }

    if (newSelection === POSITIONS.none) {
      this.changeWidth(newSelection, 0);
    } else {
      const borderWidth = this.getWidth(
        this.isNoBorder() ? 1 : this.props.valueLink.value,
      );
      this.changeWidth(newSelection, borderWidth);
    }
    this.setState({ currSelected: newSelection });
  },

  isNoBorder() {
    return this.state.currSelected === POSITIONS.none;
  },

  borderPositions: [
    {
      value: POSITIONS.none,
      illustration: React.createElement(symbols.symbol, {
        name: 'bp-no-border',
      }),
      label: 'CustomDesign_LangMenu_Border_Position_Label_None',
    },
    {
      value: POSITIONS.full,
      illustration: React.createElement(symbols.symbol, {
        name: 'bp-all-sides-border',
      }),
      label: 'CustomDesign_LangMenu_Border_Position_Label_Full',
    },
    {
      value: POSITIONS.bottom,
      illustration: React.createElement(symbols.symbol, {
        name: 'bp-single-bottom-border',
      }),
      label: 'CustomDesign_LangMenu_Border_Position_Label_Bottom',
    },
  ],

  oldBorderPositions: [
    {
      value: POSITIONS.full,
      illustration: React.createElement(symbols.symbol, {
        name: 'all-sides-border',
      }),
      label: 'CustomDesign_LangMenu_Border_Position_Label_Full',
    },
    {
      value: POSITIONS.bottom,
      illustration: React.createElement(symbols.symbol, {
        name: 'single-bottom-border',
      }),
      label: 'CustomDesign_LangMenu_Border_Position_Label_Bottom',
    },
  ],
});
