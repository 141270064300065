// @ts-nocheck
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as core from '@/core';
import { translate } from '@/i18n';
import * as coreBi from '@/coreBi';
import constants from '@/constants';
import experiment from 'experiment';
import React from 'react';
import * as BaseUI from '@/baseUI';
import { cx, editorWixRecorder, array } from '@/util';
import { gfppActionList } from '@/gfppData';
import * as stateManagement from '@/stateManagement';
import { utils } from '@/gfppData';

const GFPP_ORIGIN = 'GFPP';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'GFPP Button',
  propTypes: {
    selectedComponent: PropTypes.arrayOf(PropTypes.object).isRequired,
  },
  mixins: [core.mixins.editorAPIMixin],
  translateIfNeeded(text) {
    if (
      _.isBoolean(this.props.shouldTranslate) &&
      !this.props.shouldTranslate
    ) {
      return text;
    }

    return translate(text);
  },
  getCloseTriggers() {
    return this.props.keepTooltipOpenOnClick && ['onMouseLeave'];
  },
  onClick() {
    if (!this.props.isDisabled) {
      const editorAPI = this.getEditorAPI();
      const selectedComponent: CompRef[] = this.props.selectedComponent;

      if (!editorAPI.components.is.exist(selectedComponent)) {
        return;
      }

      /* getting this info before invoking the click handler,
             because in cases like "hide" (in mobile), the component
             gets deleted.
             No comp - no selected comp id\type. */
      const compId = (
        selectedComponent.length ? _.head(selectedComponent) : selectedComponent
      ).id;
      const compData = editorAPI.components.data.get(selectedComponent);

      const compType = editorAPI.components.getType(selectedComponent);
      this.props.onClick(
        editorAPI,
        selectedComponent,
        constants.USER_ACTIONS.ORIGIN.GFPP,
        {
          title: this.props.title,
          experimentIsOpen: experiment.isOpen,
        },
      );
      const action_name =
        this.props.action_name || this.props.label || this.props.tooltip;
      const component_type = compData?.sourceType || compType;

      const allGfppActions = gfppActionList.getGfppActionsList(
        this.props.gfppData,
      );

      editorWixRecorder.addLabel(`${action_name} ${component_type} gfpp click`);

      const componentsSelectors = stateManagement.components.selectors;

      const isWithinBlocksWidget =
        componentsSelectors.isReferredId(compId) &&
        componentsSelectors.isDescendantOfBlocksWidget(
          selectedComponent,
          editorAPI.dsRead,
        );

      editorAPI.bi.event(
        coreBi.events.actions.CLICK,
        this.props.customBI || {
          ...editorAPI.bi.getComponentsBIParams(
            array.asArray(selectedComponent),
          )[0],
          action_name,
          component_id: compId,
          component_type,
          icon: this.props.tooltip,
          app_Id: this.props.appId,
          origin: GFPP_ORIGIN,
          all_gfpp_actions: allGfppActions,
          isWithinBlocksWidget,
        },
      );
    }
  },
  buttonHasBackgroundIcon() {
    return (
      utils.isChangeBackgroundEnabled() &&
      this.props.icon &&
      typeof this.props.icon !== 'string'
    );
  },
  renderIcon() {
    if (this.buttonHasBackgroundIcon()) {
      return (
        <BaseUI.GfppBackgroundIcon
          iconInfo={this.props.icon}
          isStandaloneIcon={
            utils.isChangeBackgroundWithIconOnlyEnabled() && !this.props.label
          }
          buttonClassName={this.props.className}
        />
      );
    }
    return <BaseUI.symbol key="gfppButtonIcon" name={this.props.icon} />;
  },
  render() {
    return (
      <BaseUI.tooltip
        value={this.props.tooltip}
        styleType="small"
        interactive={this.props.interactiveTooltip}
        width={this.props.tooltipMaxWidth}
        disabled={!this.props.tooltip}
        closeTriggers={this.getCloseTriggers()}
        shouldTranslate={this.props.shouldTranslate}
      >
        <li
          onClick={this.onClick}
          data-aid={this.props.automationId}
          className={cx({
            'gfpp-btn': true,
            'gfpp-btn_icon': this.props.icon,
            'gfpp-btn_label': this.props.label,
            'with-background-icon': this.buttonHasBackgroundIcon(),
            [this.props.className]: true,
          })}
        >
          {this.props.icon ? this.renderIcon() : null}
          {this.props.label ? (
            <span key="gfppButtonLabel" className="gfpp-label">
              {this.translateIfNeeded(this.props.label)}
            </span>
          ) : null}
        </li>
      </BaseUI.tooltip>
    );
  },
});
