import type { FormData } from './scanSwitchLayoutPresetTypes';

export const SCAN_SWITCH_LAYOUT_PRESET_URL =
  'https://idanyael.wixsite.com/switch-layout-cms/_functions/scannedSectionPreset';

export const MAX_TITLE_DATA_RULES = 1;
export const MAX_SUBTITLE_DATA_RULES = 1;
export const MAX_PARAGRAPH_DATA_RULES = 1;
export const MAX_IMAGE_DATA_RULES = 1;

export const textDataInitialValues = {
  minArea: 0,
  maxArea: 0,
  minFontSize: 0,
  maxFontSize: 0,
  nickname: '',
};

export const imageDataInitialValues = {
  minWidth: 0,
  maxWidth: 0,
  minHeight: 0,
  maxHeight: 0,
  aspectRatio: 0,
  isImageComponent: false,
  nickname: '',
};

export const formInitialValues: FormData = {
  id: '',
  groupLayout: '',
  titleData: [],
  subtitleData: [],
  paragraphData: [],
  imageData: [],
};

export const textFormKeys = [
  'minArea',
  'maxArea',
  'minFontSize',
  'maxFontSize',
  'nickname',
];

export const imageFormKeys = [
  'minWidth',
  'maxWidth',
  'minHeight',
  'maxHeight',
  'aspectRatio',
  'nickname',
];

export const aspectRatioValues = [
  { value: 0, label: 'Square' },
  { value: 2, label: 'Horizontal' },
  { value: 4, label: 'Vertical' },
  { value: 6, label: 'squareOrHorizontal' },
  { value: 8, label: 'squareOrVertical' },
  { value: 10, label: 'verticalOrHorizontal' },
  { value: 12, label: 'squareOrVerticalOrHorizontal' },
];

export const booleanRuleKeys = ['isImageComponent'];
export const falseIncludedNumericRuleKeys = ['aspectRatio'];
export const stringRuleKeys = ['nickname'];
