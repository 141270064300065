'use strict'

const dataBindingSection = require('../sections/database/dataBindingSection')
const collectionsSection = require('../sections/database/collectionsSection')
const presetsSection = require('../sections/database/presetsSection')
const richContent = require('../sections/database/richContent')

module.exports = {
    collectionsSection,
    dataBindingSection,
    presetsSection,
    richContent
}
