import React from 'react';
import { useSelectedApp } from '../selectedAppContext/selectedAppContext';
import { Text } from '@wix/wix-base-ui';
import styles from './appDescription.scss';

export const AppDescription: React.FC = () => {
  const { description } = useSelectedApp().selectedApp;

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Text
          weight="thin"
          size="large"
          enableEllipsis={false}
          shouldTranslate={false}
        >
          {description}
        </Text>
      </div>
    </div>
  );
};
