// @ts-nocheck
import constants from '@/constants';
import gfppDataUtils from '../../utils/gfppDataUtils';

const { ACTIONS } = constants.ROOT_COMPS.GFPP;

export default {
  mainActions: [
    {
      label: 'gfpp_mainaction_SoundCloud',
      isApplied: gfppDataUtils.getDataDefinedFn('skypeName'),
      isSelected: gfppDataUtils.getPanelStateFn(ACTIONS.SETTINGS),
      onClick: gfppDataUtils.getTogglePanelFn(ACTIONS.SETTINGS),
    },
  ],
  enabledActions: [
    ACTIONS.SETTINGS,
    ACTIONS.LAYOUT,
    ACTIONS.ANIMATION,
    ACTIONS.HELP,
  ],
  mobileEnabledActions: [ACTIONS.HIDE, ACTIONS.HELP, ACTIONS.ANIMATION],
  presetActions: {
    help: {
      helpId: 'eea0fa24-fab0-40a5-a9d2-43915ba536d1',
    },
  },
};
