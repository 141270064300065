// @ts-nocheck
import _ from 'lodash';
import constants from '@/constants';
import * as stateManagement from '@/stateManagement';
import gfppDataUtils from '../../utils/gfppDataUtils';
import type { EditorAPI } from '@/editorAPI';
import type { CompRef } from '@wix/document-services-types';

const { ACTIONS } = constants.ROOT_COMPS.GFPP;

const BACKGROUND_PANEL = 'compPanels.panels.MenuContainer.backgroundPanel';
export default {
  mobileMainActions: [
    {
      label: 'gfpp_mainaction_mobile_menu_box_background',
      isSelected: gfppDataUtils.getPanelStateFn(BACKGROUND_PANEL),
      onClick(editorAPI: EditorAPI, compRef: CompRef, origin: string) {
        gfppDataUtils.toggleComponentPanel(editorAPI, BACKGROUND_PANEL, {
          origin,
        });
      },
    },
  ],
  mainActions: [
    {
      label: 'gfpp_mainaction_mobile_menu_box_background',
      isSelected: gfppDataUtils.getPanelStateFn(BACKGROUND_PANEL),
      onClick(editorAPI: EditorAPI, compRef: CompRef, origin: string) {
        gfppDataUtils.toggleComponentPanel(editorAPI, BACKGROUND_PANEL, {
          origin,
        });
      },
    },
  ],
  mobileEnabledActions: [
    ACTIONS.LAYOUT,
    ACTIONS.DESIGN,
    ACTIONS.ANIMATION,
    ACTIONS.HELP,
  ],
  enabledActions: [
    ACTIONS.LAYOUT,
    ACTIONS.DESIGN,
    ACTIONS.ANIMATION,
    ACTIONS.HELP,
  ],
  presetActions: {
    [ACTIONS.ANIMATION]: {
      onClick: (editorAPI, compRef) =>
        gfppDataUtils.togglePanel(editorAPI, 'animation', {
          panelAction: 'inlinePopupIn',
          panelExitAction: 'inlinePopupOut',
          shouldAddExit: true,
          disabledAnimationParamsList: ['delay'],
          allowOnlyShowOnceUI: false,
          customPreviewAnimation: (
            selectedComponent,
            animation,
            onComplete,
          ) => {
            editorAPI.store
              .dispatch(
                stateManagement.inlinePopup.actions.open(_.head(compRef), true),
              )
              .then(() => {
                editorAPI.dsActions.components.behaviors.execute(
                  _.head(selectedComponent),
                  'animate',
                  { animation, callbacks: { onComplete } },
                );
              });
          },
          dataOverridesKey: 'InlinePopup',
        }),
    },
    [ACTIONS.DESIGN]: {
      isSupported: (editorAPI, compRefs) =>
        !editorAPI.components.layout.isHorizontallyStretched(_.head(compRefs)),
      onClick: gfppDataUtils.getTogglePanelFn('design', {
        title: 'Mobile_Menu_Menu_Design_Header',
      }),
    },
    [ACTIONS.HELP]: {
      helpId: '3ab8a6d4-580a-498d-b391-7beaa7567c33',
      mobileHelpId: '3ab8a6d4-580a-498d-b391-7beaa7567c33',
    },
  },
};
