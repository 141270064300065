// @ts-nocheck
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as util from '@/util';
import * as mediaManagerPanelTypes from '../../../mediaManagerPanelTypes/mediaManagerPanelTypes';
import * as mediaManagerUtils from '../../../mediaManagerPanelUtils/mediaManagerPanelUtils';
import React from 'react';
import constants from '@/constants';
import * as symbols from '@wix/santa-editor-symbols';
import ExtensionLabel from './presetThumbnailExtensionLabel';
import { cx } from '@/util';

const { imageTransform } = util;

const PRESET_HEIGHT = 90;
const PRESET_WIDTH = 88;

//TYPE WAS GENERATED, remove this line when reviewed
interface Props {
  item: AnyFixMe;
  onPictureError: FunctionFixMe;
  width?: number;
  isBackgroundBlue?: boolean;
}

export default class extends React.Component<Props> {
  static displayName = 'MediaManagerPanelItemThumbnail';

  static propTypes = {
    item: mediaManagerPanelTypes.item.isRequired,
    onPictureError: PropTypes.func.isRequired,
    width: PropTypes.number,
    isBackgroundBlue: PropTypes.bool,
  };

  getWidth = () => {
    return this.props.width || PRESET_WIDTH;
  };

  isBackgroundBlue = () => {
    return this.props.isBackgroundBlue;
  };

  getMediaType = () => {
    return this.props.item.mediaType;
  };

  getPictureThumbnailUrl = () => {
    return mediaManagerUtils.generatePictureThumbnailUrl(
      this.props.item,
      this.getWidth(),
      PRESET_HEIGHT,
      imageTransform.fittingTypes.SCALE_TO_FILL,
    );
  };

  getVideoThumbnailUrl = () => {
    return mediaManagerUtils.getVideoThumbnailUrl(
      this.props.item,
      this.getWidth(),
      PRESET_HEIGHT,
      imageTransform.fittingTypes.SCALE_TO_FILL,
    );
  };

  hasExtension = () => {
    return Boolean(
      mediaManagerUtils.getPrivateMediaFileExtension(this.props.item),
    );
  };

  getExtension = () => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/to-upper
    return `.${_.toUpper(
      mediaManagerUtils.getPrivateMediaFileExtension(this.props.item),
    )}`;
  };

  isItemOfType = (mediaType) => {
    return mediaManagerUtils.isItemOfType(this.props.item, mediaType);
  };

  isItemOneOfTypes = (mediaTypes) => {
    return mediaManagerUtils.isItemOneOfTypes(this.props.item, mediaTypes);
  };

  handlePictureError = () => {
    _.invoke(this.props, 'onPictureError');
  };

  render() {
    const mediaManagerMediaTypes = constants.MEDIA_MANAGER_MEDIA_TYPES;

    return (
      <div
        style={{ width: this.getWidth() }}
        className={cx('media-manager-panel-preset-thumbnail', {
          'with-blue-background': this.isBackgroundBlue(),
        })}
      >
        {this.isItemOneOfTypes([
          mediaManagerMediaTypes.PICTURE,
          mediaManagerMediaTypes.SHAPE,
        ]) ? (
          <div key="picture" className={cx({ [this.getMediaType()]: true })}>
            <img
              src={this.getPictureThumbnailUrl()}
              onError={this.handlePictureError}
              className="media-manager-panel-preset-thumbnail__picture-image"
            />
          </div>
        ) : null}

        {this.isItemOfType(mediaManagerMediaTypes.VIDEO) ? (
          <div
            style={{
              backgroundImage: `url('${this.getVideoThumbnailUrl()}')`,
            }}
            key="video"
            className={cx({ [this.getMediaType()]: true })}
          >
            <div className="media-manager-panel-preset-thumbnail__video-symbol">
              <symbols.symbol name="bgVideoIndicator" />
            </div>

            <div className="media-manager-panel-preset-thumbnail__video-bottom-overlay" />
          </div>
        ) : null}

        {this.isItemOfType(mediaManagerMediaTypes.MUSIC) ? (
          <div key="music" className={cx({ [this.getMediaType()]: true })}>
            <symbols.symbol name="empty-document-illustration" />

            <div className="media-manager-panel-preset-thumbnail__music-control-wrapper">
              <div className="media-manager-panel-preset-thumbnail__music-symbol">
                <symbols.symbol name="music" />
              </div>
            </div>
          </div>
        ) : null}

        {this.isItemOfType(mediaManagerMediaTypes.ARCHIVE) ? (
          <div key="music" className={cx({ [this.getMediaType()]: true })}>
            <symbols.symbol name="media-manager-panel-archive-file-illustration" />
          </div>
        ) : null}

        {this.isItemOneOfTypes([
          mediaManagerMediaTypes.DOCUMENT,
          mediaManagerMediaTypes.SWF,
        ]) ? (
          <div key="document" className={cx({ [this.getMediaType()]: true })}>
            <symbols.symbol name="document-illustration" />
          </div>
        ) : null}

        {this.isItemOneOfTypes([
          mediaManagerMediaTypes.DOCUMENT,
          mediaManagerMediaTypes.MUSIC,
          mediaManagerMediaTypes.SWF,
          mediaManagerMediaTypes.ARCHIVE,
        ]) && this.hasExtension() ? (
          <div
            key="label"
            className="media-manager-panel-preset-thumbnail__extension-label"
          >
            <ExtensionLabel label={this.getExtension()} />
          </div>
        ) : null}
      </div>
    );
  }
}
