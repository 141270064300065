import { translate } from '@/i18n';
export default {
  type: 'Container',
  components: [
    {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.photo.NoSkinPhoto',
      layout: {
        width: 305,
        height: 442,
        x: 10,
        y: 7,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.WPhoto',
      metaData: {
        pageId: 'e1xue',
      },
      parent: 'comp-kguvuz2d',
      data: {
        type: 'Image',
        metaData: {
          isPreset: false,
          schemaVersion: '2.0',
          isHidden: false,
          pageId: 'e1xue',
        },
        title: '',
        uri: '11062b_71563e487c294d468708fad070eec7bd~mv2.jpg',
        description: '',
        width: 4480,
        height: 6720,
        alt: 'Food and Flower',
        name: 'Food and Flower',
      },
      props: {
        type: 'WPhotoProperties',
        metaData: {
          schemaVersion: '1.0',
          pageId: 'e1xue',
        },
        displayMode: 'fill',
        onClickBehavior: 'goToLink',
        autoFill: false,
      },
      mobileHints: {
        type: 'MobileHints',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'e1xue',
        },
        hidden: false,
      },
      style: {
        type: 'ComponentStyle',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'e1xue',
        },
        style: {
          properties: {},
          propertiesSource: {},
          groups: {},
        },
        componentClassName: 'wysiwyg.viewer.components.WPhoto',
        pageId: '',
        compId: '',
        styleType: 'custom',
        skin: 'wysiwyg.viewer.skins.photo.NoSkinPhoto',
      },
    },
    {
      type: 'Container',
      components: [],
      skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
      layout: {
        width: 264,
        height: 385,
        x: 29,
        y: 35,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'mobile.core.components.Container',
      metaData: {
        pageId: 'e1xue',
      },
      parent: 'comp-kguvuz2d',
      transitions: {
        type: 'TransitionData',
        'timing-function': 'ease-in-out',
        duration: 0.2,
        delay: 0,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'e1xue',
        },
      },
      transformations: {
        type: 'TransformData',
        origin: {
          x: {
            value: 50,
            type: 'percentage',
          },
          y: {
            value: 50,
            type: 'percentage',
          },
        },
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'e1xue',
        },
      },
      scopedTransformations: {
        'variants-kguvuz32': {
          type: 'TransformData',
          scale: {
            x: 1.16,
            y: 1.16,
          },
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'e1xue',
          },
          translate: {
            x: {
              type: 'px',
              value: 1,
            },
            y: {
              type: 'px',
              value: 2,
            },
          },
        },
      },
      scopedStyles: {
        'variants-kguvuz32': {
          type: 'ComponentStyle',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'e1xue',
          },
          style: {
            properties: {
              'alpha-bg': '1',
              'alpha-brd': '1',
              bg: '#E76D5B',
              'boxShadowToggleOn-shd': 'false',
              brd: '#000000',
              brw: '2',
              rd: '0px',
              shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
            },
            propertiesSource: {
              'alpha-bg': 'value',
              'alpha-brd': 'value',
              bg: 'value',
              'boxShadowToggleOn-shd': 'value',
              brd: 'value',
              brw: 'value',
              rd: 'value',
              shd: 'value',
            },
            groups: {},
          },
          componentClassName: 'mobile.core.components.Container',
          pageId: '',
          compId: '',
          styleType: 'custom',
          skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
        },
      },
      style: {
        type: 'ComponentStyle',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'e1xue',
        },
        style: {
          properties: {
            'alpha-bg': '0',
            'alpha-brd': '1',
            bg: '#F58144',
            'boxShadowToggleOn-shd': 'false',
            brd: '#FFFFFF',
            brw: '2',
            rd: '0px',
            shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
          },
          propertiesSource: {
            'alpha-bg': 'value',
            'alpha-brd': 'value',
            bg: 'value',
            'boxShadowToggleOn-shd': 'value',
            brd: 'value',
            brw: 'value',
            rd: 'value',
            shd: 'value',
          },
          groups: {},
        },
        componentClassName: 'mobile.core.components.Container',
        pageId: '',
        compId: '',
        styleType: 'custom',
        skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
      },
    },
    {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
      layout: {
        width: 223,
        height: 167,
        x: 40,
        y: 36,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.WRichText',
      metaData: {
        pageId: 'e1xue',
      },
      parent: 'comp-kguvuz2d',
      data: {
        type: 'StyledText',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'e1xue',
        },
        text: `<p class="font_7" style="font-size:26px"><span style="font-size:26px"><span style="font-family:proxima-n-w01-reg"><span style="font-weight:normal"><span style="font-style:normal"><span style="color:#FFFFFF"><span style="font-family:avenir-lt-w01_85-heavy1475544,sans-serif">${translate(
          'interactions_preset_wine_party_date',
        )}</span></span></span></span></span></span></p>\n\n<p class="font_7" style="font-size:26px"><span style="font-size:26px"><span style="font-family:proxima-n-w01-reg"><span style="font-weight:normal"><span style="font-style:normal"><span style="color:#FFFFFF"><span style="font-family:avenir-lt-w01_85-heavy1475544,sans-serif">${translate(
          'interactions_preset_wine_party_description',
        )}</span></span></span></span></span></span></p>`,
        stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
        linkList: [],
      },
      props: {
        type: 'WRichTextProperties',
        metaData: {
          schemaVersion: '1.0',
          autoGenerated: false,
          pageId: 'e1xue',
        },
        brightness: 1,
        packed: false,
        minHeight: 167,
        verticalText: false,
      },
      mobileHints: {
        type: 'MobileHints',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'e1xue',
        },
        hidden: true,
      },
      transitions: {
        type: 'TransitionData',
        'timing-function': 'ease-in-out',
        duration: 0.4,
        delay: 0,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'e1xue',
        },
      },
      transformations: {
        type: 'TransformData',
        hidden: true,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'e1xue',
        },
      },
      scopedTransformations: {
        'variants-kguvuz32': {
          type: 'TransformData',
          hidden: false,
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'e1xue',
          },
          rotate: 0,
        },
      },
      style: 'txtNew',
    },
    {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
      layout: {
        width: 233,
        height: 22,
        x: 45,
        y: 384,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.WRichText',
      metaData: {
        pageId: 'e1xue',
      },
      parent: 'comp-kguvuz2d',
      data: {
        type: 'StyledText',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'e1xue',
        },
        text: `<h2 class="font_2" style="font-size:18px; text-align:center"><span style="font-size:18px"><span style="color:#FFFFFF"><span style="font-family:avenir-lt-w01_35-light1475496,sans-serif">${translate(
          'interactions_preset_wine_party_title',
        )}</span></span></span></h2>`,
        stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
        linkList: [],
      },
      props: {
        type: 'WRichTextProperties',
        metaData: {
          schemaVersion: '1.0',
          autoGenerated: false,
          pageId: 'e1xue',
        },
        brightness: 1,
        packed: false,
        minHeight: 21,
        verticalText: false,
      },
      transitions: {
        type: 'TransitionData',
        'timing-function': 'ease-out',
        duration: 0.2,
        delay: 0,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'e1xue',
        },
      },
      scopedTransformations: {
        'variants-kguvuz32': {
          type: 'TransformData',
          hidden: true,
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'e1xue',
          },
        },
      },
      style: 'txtNew',
    },
    {
      type: 'Component',
      skin: 'wixui.skins.Skinless',
      layout: {
        width: 257,
        height: 42,
        x: 36,
        y: 384,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'wixui.StylableButton',
      metaData: {
        pageId: 'e1xue',
      },
      parent: 'comp-kguvuz2d',
      data: {
        type: 'StylableButton',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'e1xue',
        },
        label: translate('interactions_preset_wine_party_CTA'),
        svgId: 'b861b040274141de9c08999ec3b76edf.svg',
      },
      props: {
        type: 'StylableButtonProperties',
        metaData: {
          schemaVersion: '1.0',
          pageId: 'e1xue',
        },
      },
      transitions: {
        type: 'TransitionData',
        'timing-function': 'ease-in-out',
        duration: 0.4,
        delay: 0,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'e1xue',
        },
      },
      transformations: {
        type: 'TransformData',
        hidden: true,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'e1xue',
        },
      },
      scopedTransformations: {
        'variants-kguvuz32': {
          type: 'TransformData',
          hidden: false,
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'e1xue',
          },
          rotate: 0,
        },
      },
      style: {
        type: 'ComponentStyle',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'e1xue',
        },
        style: {
          properties: {
            '$st-css':
              ":import{-st-from: 'wix-ui-santa/index.st.css';-st-named: StylableButton}.root{-st-extends: StylableButton;transition: all 0.2s ease, visibility 0s;background: rgba(0,0,0,0);border: 1px solid #FFFFFF}.root:hover{\n    background: #FFFFFF}.root[disabled]{background: #E2E2E2}.root[disabled]::label{color: #8F8F8F}.root[disabled]::icon{fill: #8F8F8F}.root::container{transition: inherit}.root::label{transition: inherit;font-family: avenir-lt-w01_85-heavy1475544,sans-serif;color: #FFFFFF;font-size: 16px;margin: 0px 10.5px 0px 0px;font-style: normal}.root::icon{transition: inherit;width: 14px;height: 14px;fill: #000000;margin: 0px 0px 0px 10.5px;display: none}.root:hover::label{\n    color: #F75E5E;\n    text-decoration-line: none}",
          },
          propertiesSource: {
            '$st-css': 'value',
          },
        },
        componentClassName: 'wixui.StylableButton',
        pageId: '',
        compId: '',
        styleType: 'custom',
        skin: 'wixui.skins.Skinless',
      },
    },
  ],
  skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
  layout: {
    width: 323,
    height: 457,
    x: 327,
    y: 21,
    scale: 1,
    rotationInDegrees: 0,
    fixedPosition: false,
  },
  componentType: 'mobile.core.components.Container',
  metaData: {
    pageId: 'e1xue',
  },
  parent: 'e1xue',
  variants: {
    'variants-kguvuz32': {
      componentId: 'comp-kguvuz2d',
      type: 'Hover',
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        pageId: 'e1xue',
      },
    },
  },
  scopedTransformations: {
    'variants-kguvuz32': {
      type: 'TransformData',
      rotate: 0,
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        pageId: 'e1xue',
      },
    },
  },
  style: {
    type: 'ComponentStyle',
    metaData: {
      isPreset: false,
      schemaVersion: '1.0',
      isHidden: false,
      pageId: 'e1xue',
    },
    style: {
      properties: {
        'alpha-bg': '0',
        'alpha-brd': '1',
        bg: '#C8EEF5',
        'boxShadowToggleOn-shd': 'false',
        brd: '#423737',
        brw: '0',
        rd: '0px',
        shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
      },
      propertiesSource: {
        'alpha-bg': 'value',
        'alpha-brd': 'value',
        bg: 'value',
        'boxShadowToggleOn-shd': 'value',
        brd: 'value',
        brw: 'value',
        rd: 'value',
        shd: 'value',
      },
      groups: {},
    },
    componentClassName: 'mobile.core.components.Container',
    pageId: '',
    compId: '',
    styleType: 'custom',
    skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
  },
  activeModes: {},
};
