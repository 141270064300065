import { BaseEntryScope, createEntryPoint } from '@/apilib';
import { init } from './init';
import { ImageToBackgroundPublicApi } from './api';
import { ImageToBackgroundApiKey } from './publicApiKey';
import {
  ComponentsApiKey,
  ColumnsApiKey,
  SelectionApiKey,
  PlatformApiKey,
  WorkspaceApiKey,
  EditorAPIKey,
  PagesApiKey,
  SectionsApiKey,
  BiApiKey,
} from '@/apis';

export class Scope extends BaseEntryScope {
  workspace = this.useDependency(WorkspaceApiKey);
  components = this.useDependency(ComponentsApiKey);
  pages = this.useDependency(PagesApiKey);
  columns = this.useDependency(ColumnsApiKey);
  selection = this.useDependency(SelectionApiKey);
  platform = this.useDependency(PlatformApiKey);
  editorAPI = this.useDependency(EditorAPIKey);
  sections = this.useDependency(SectionsApiKey);
  bi = this.useDependency(BiApiKey);
}

export const ImageToBackgroundEntryPoint = createEntryPoint({
  name: 'imageToBackgroundPackage',
  Scope,
  publicApi({ contributeApi }) {
    contributeApi(ImageToBackgroundApiKey, ImageToBackgroundPublicApi);
  },
  initialize(scope) {
    init(scope);
  },
});
