// @ts-nocheck
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import * as symbols from '@wix/santa-editor-symbols';

//TYPE WAS GENERATED, remove this line when reviewed
interface Props {
  onClick?: FunctionFixMe;
}

export default class extends React.Component<Props> {
  static displayName = 'dotsButton';

  static propTypes = {
    onClick: PropTypes.func,
  };

  onClick = () => {
    _.invoke(this.props, 'onClick');
  };

  render() {
    return (
      <button
        onClick={this.onClick}
        className="media-manager-panel-three-dots-button"
      >
        <symbols.symbol
          name="freeIcon_3dots"
          style={{ width: '25px', height: '25px', fill: '#162d3d' }}
        />
      </button>
    );
  }
}
