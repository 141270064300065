// @ts-nocheck
import * as blogSections from './blogSections';
import * as blogUtils from '../utils/blogUtils';

function getSections(editorAPI) {
  let sections, amendItem;
  if (blogUtils.siteHasOldBlog(editorAPI)) {
    sections = blogSections.siteHasOldBlog;
    amendItem = function (_item) {
      // if (item.structure.data) {
      //   item.structure.data.appInnerID =
      //     editorAPI.dsRead.wixapps.classics.account.getApplicationId('blog'); //account was removed from dm
      // }
    };
  } else {
    sections = blogSections.siteDoesNotHaveBlog;
    amendItem = function (item) {
      item.onDrop = blogUtils.addBlogAndRegisterButton.bind(
        null,
        editorAPI,
        item.viewName,
      );
      item.onClick = blogUtils.addBlogAndRegisterButton.bind(
        null,
        editorAPI,
        item.viewName,
      );
    };
  }
  //TODO: add tests that amendItem works
  sections.forEach(function (section) {
    section.props.items.forEach(amendItem);
  });
  return sections;
}

export { getSections };
