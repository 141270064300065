import * as frames from './frames';
import PanelCloseOrigin from './frames/panelCloseOrigin';
import * as mixins from './mixins/mixins';
import * as helpPanels from './helpPanels/helpPanels';
import * as errorPages from './errorPages/errorPagesPanels';
import * as focusPanels from './focusPanels/focusPanels';
import * as widgetPanels from './widgetPanels/widgetPanels';
import * as promotionalPanels from './promotionalPanels/promotionalPanels';
import type { LinkPanelComponentOwnProps } from './toolPanels/linkPanel/LinkPanel';
import * as messagePanels from './messagePanels/messagePanels';
import * as definitions from './panelsDefinitions/panelsDefinitions';
import toolPanels, {
  type IMultiselectPanelItem,
  type IMultiselectPanelSection,
  type IMultiselectPanelGroup,
  type IMultiselectPanelOwnProps,
  type IReplaceRemovePanelProps,
} from './toolPanels/toolPanels';
import linkTo from './commonControls/linkTo/linkTo';
import linkToButton from './commonControls/linkTo/linkToButton';
import linkToMixin from './commonControls/linkTo/linkToMixin';
import OpenedRightPanels from './rightPanels/openedRightPanels/openedRightPanels';
import {
  getBusinessTypeByString,
  suggestItemsToOptions,
  useInputWithInputSuggestions,
} from './focusPanels/changeBusinessTypePanel/withSuggestions';
import type * as ColorPickerTypes from './toolPanels/colorPicker/colorPickerPanelWithGradient/types';
import type * as BusinessTypeTypes from './focusPanels/changeBusinessTypePanel/types';
const commonControls = {
  linkTo,
  linkToButton,
};

const businessTypeUtils = {
  getBusinessTypeByString,
  suggestItemsToOptions,
  useInputWithInputSuggestions,
};

export {
  definitions,
  frames,
  PanelCloseOrigin,
  mixins,
  helpPanels,
  errorPages,
  focusPanels,
  promotionalPanels,
  messagePanels,
  toolPanels,
  commonControls,
  linkToMixin,
  OpenedRightPanels,
  widgetPanels,
  businessTypeUtils,
};

export type {
  IMultiselectPanelItem,
  IMultiselectPanelSection,
  IMultiselectPanelGroup,
  IMultiselectPanelOwnProps,
  LinkPanelComponentOwnProps,
  ColorPickerTypes,
  IReplaceRemovePanelProps,
  BusinessTypeTypes,
};
