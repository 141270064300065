import {staticAssetsMap} from '@/staticAssets' //eslint-disable-line import/no-unresolved

function joinURL(...args) {
  let url = args[0];

  for (let i = 1; i < args.length; ++i) {
    if (args[i] !== undefined) {
      url = url.replace(/\/$/, "") + "/" + args[i].replace(/^\//, ""); //eslint-disable-line prefer-template
    }
  }

  return url;
}

const OFFSET = 20;

const dockMap = {
  TOP_LEFT: { top: { px: OFFSET }, left: { px: OFFSET } },
  TOP: { top: { px: OFFSET }, hCenter: { px: 0 } },
  TOP_RIGHT: { top: { px: OFFSET }, right: { px: OFFSET } },
  LEFT: { left: { px: OFFSET }, vCenter: { px: 0 } },
  MIDDLE: { hCenter: { px: 0 }, vCenter: { px: 0 } },
  RIGHT: { right: { px: OFFSET }, vCenter: { px: 0 } },
  BOTTOM_LEFT: { bottom: { px: OFFSET }, left: { px: OFFSET } },
  BOTTOM: { bottom: { px: OFFSET }, hCenter: { px: 0 } },
  BOTTOM_RIGHT: { bottom: { px: OFFSET }, right: { px: OFFSET } },
};

export const pinModeUtils = {
  dockMap,
};

function getMediaUrl(mediaPath, fallback) {
  const baseUrl = joinURL(
    window.serviceTopology.scriptsDomainUrl,
    "services",
    "santa-resources",
    "dist",
    "editor"
  );
  let assetPath =
    staticAssetsMap[mediaPath] ||
    staticAssetsMap[fallback] ||
    fallback ||
    mediaPath;

  if (assetPath && assetPath[0] === "/") {
    assetPath = assetPath.substr(1);
  }
  return [baseUrl, assetPath].join("/");
}

export const languages = {
  getLanguageCode: () => window.editorModel.languageCode,
};
export const media = {
  getMediaUrl,
};
