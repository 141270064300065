import { newAddPanelAPI } from '@/newAddPanelAPI';
import { EditorCoreApiKey } from '@/apis';
import type { EditorAPI } from '@/editorAPI';
import type { EditorParams } from '@/editorParams';
import { panels } from '@/stateManagement';
import { addPanel, fedopsLogger, sections as sectionsUtils } from '@/util';
import { ErrorReporter } from '@wix/editor-error-reporter';

let newAddPanelReadyPromise: Promise<void>;

export const onNewAddPanelReady = async () => {
  if (!newAddPanelReadyPromise) {
    throw new Error(
      '`onNewAddPanelReady` function called before `bootstrapNewAddPanel`.\n Ensure `bootstrapNewAddPanel` is called.',
    );
  }

  return newAddPanelReadyPromise;
};

interface BootstrapNewAddPanelParams {
  editorAPI: EditorAPI;
  editorParams: EditorParams;
}

const logAddPanelLoadError = (error: MaybeError) => {
  console.error('[new-add-panel]: init failed', error);
  ErrorReporter.captureException(error, {
    tags: { operation: 'new-add-panel' },
  });
};

export const bootstrapNewAddPanel = ({
  editorAPI,
  editorParams,
}: BootstrapNewAddPanelParams) => {
  if (!addPanel.isNewPanelEnabled()) {
    newAddPanelReadyPromise = Promise.resolve();
    return;
  }

  fedopsLogger.appLoadingPhaseStart('new-add-panel-init');

  panels.panelsPropsTransformers.registerNewAddPanelPropsTransformers();

  // check isSectionsEnabled or it may be enabled after migration
  const predictedIsSectionsEnabled =
    sectionsUtils.predictIsSectionsEnabled(editorParams);

  newAddPanelReadyPromise = newAddPanelAPI
    .initAndStartLoadData({
      editorAPI,
      isSectionsEnabled: predictedIsSectionsEnabled,
    })
    .then(() => fedopsLogger.appLoadingPhaseFinish('new-add-panel-init'))
    .catch(logAddPanelLoadError);

  editorAPI.host
    .getAPI(EditorCoreApiKey)
    .hooks.sectionsMigrationReady.promise.then(() => {
      if (addPanel.isAddPanelRegistrySectionEnabled()) {
        newAddPanelAPI
          .initRegistryAddPanelData({ editorAPI })
          .catch((error) =>
            console.error('[registry-add-panel]: init failed', error),
          );
      }

      // check isSectionsEnabled after migration
      const isSectionsEnabled = sectionsUtils.isSectionsEnabled();

      if (isSectionsEnabled !== predictedIsSectionsEnabled) {
        fedopsLogger.appLoadingPhaseStart('new-add-panel-init');

        newAddPanelAPI
          .initAndStartLoadData({
            editorAPI,
            isSectionsEnabled,
            reload: true,
          })
          .then(() => {
            fedopsLogger.appLoadingPhaseFinish('new-add-panel-init');
          })
          .catch(logAddPanelLoadError);
      }
    });
};
