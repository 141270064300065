import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { CustomScroll, RichText, Text } from '@wix/wix-base-ui';
import * as stateManagement from '@/stateManagement';
import * as util from '@/util';
import { translate } from '@/i18n';
import * as baseUI from '@/baseUI';
import { Hint } from '@wix/wix-ui-icons-common/classic-editor';
import * as panels from '@/panels';
import * as leftBar from '@/leftBar';
import * as coreBi from '@/coreBi';

import * as hiddenItemsUtils from '../hiddenItemsUtils/hiddenItemsUtils';
import HiddenItemsContainer from './hiddenItemsContainer/hiddenItemsContainer';
import InteractionsHiddenItemsContainer from './interactionsHiddenItemsContainer/interactionsHiddenItemsContainer';

import type { InteractionsMobileHiddenItemDef } from './interactionsHiddenItemsContainer/interactionsMobileHiddenItem';
import type { CompRef } from 'types/documentServices';
import type { BiEventDefinition, BiEventFields } from 'types/bi';
import experiment from 'experiment';

const selectors = stateManagement.mobile.selectors.hiddenItems;
const actions = stateManagement.mobile.actions.hiddenItems;
const isRedesignWithTooltipEnabled =
  experiment.getValue('se_leftPanelHiddenMobileElementsRedesign') === 'B';
const isRedesignWithoutTooltipEnabled =
  experiment.getValue('se_leftPanelHiddenMobileElementsRedesign') === 'C';
const withLeftPanelHiddenElementsRedesignExperiment =
  isRedesignWithTooltipEnabled || isRedesignWithoutTooltipEnabled;
const isNewWorkspace = util.workspace.isNewWorkspaceEnabled();

const DEFAULT_TOOLTIP_LOCATION = {
  left: 180,
  width: 352,
};

function getPropsForPage(
  hiddenComps: AnyFixMe,
  pageId: AnyFixMe,
  title: AnyFixMe,
  sectionType: AnyFixMe,
) {
  return {
    hiddenComps: _.cloneDeep(hiddenComps),
    pageId,
    title,
    sectionType,
  };
}

//TYPE WAS GENERATED, remove this line when reviewed

interface HiddenItemsPanelProps {
  initHiddenItemsPanel: FunctionFixMe;
  clearHiddenItems: FunctionFixMe;
  masterPageHiddenComponents: Array<AnyFixMe>;
  currentPageHiddenComponents: Array<AnyFixMe>;
  focusedPageId?: string;
  focusedPageTitle?: string;
  interactionsHiddenItems: InteractionsMobileHiddenItemDef[];
  showInteractionComponent: (compRef: CompRef) => void;
  showLayersPanel: () => void;
  updateNotificationsCount: (notificationsCount: number) => void;
  closeHiddenItemsPanel: () => void;
  sendBi: (event: BiEventDefinition, parameters: BiEventFields) => void;
}

interface HiddenItemsPanelState {
  notificationsCount: number;
}

class HiddenItemsPanel extends React.Component<
  HiddenItemsPanelProps,
  HiddenItemsPanelState
> {
  static displayName = 'hiddenItemsPanel';

  static propTypes = {
    initHiddenItemsPanel: PropTypes.func.isRequired,
    clearHiddenItems: PropTypes.func.isRequired,
    masterPageHiddenComponents: PropTypes.arrayOf(PropTypes.object).isRequired,
    currentPageHiddenComponents: PropTypes.arrayOf(PropTypes.object).isRequired,
    focusedPageId: PropTypes.string,
    focusedPageTitle: PropTypes.string,
    sendBi: PropTypes.func,
  };

  constructor(props: any) {
    super(props);
    this.state = { notificationsCount: 0 };
  }

  componentDidMount() {
    this.props.initHiddenItemsPanel();
    this.updateHiddenItemsCount();
  }

  componentDidUpdate() {
    this.updateHiddenItemsCount();
  }

  updateHiddenItemsCount() {
    const hiddenItemsCount = this.getAllHiddenItemsCount();
    if (hiddenItemsCount !== this.state.notificationsCount) {
      this.setState({ notificationsCount: hiddenItemsCount });
      if (withLeftPanelHiddenElementsRedesignExperiment) {
        this.props.updateNotificationsCount(hiddenItemsCount);
      }
    }
  }

  getMasterPageProps = () => {
    const title = translate('mobile_hidden_items_section_title_allpages');
    return getPropsForPage(
      this.props.masterPageHiddenComponents,
      'masterPage',
      title,
      'masterPage',
    );
  };

  getCurrentPageProps = () => {
    const { focusedPageId, focusedPageTitle } = this.props;
    const title = translate('mobile_hidden_items_section_title_currentpage', {
      PAGE: focusedPageTitle,
    });

    return getPropsForPage(
      this.props.currentPageHiddenComponents,
      focusedPageId,
      title,
      'currentPage',
    );
  };

  getAllHiddenItemsCount = () => {
    return (
      this.props.masterPageHiddenComponents.length +
      this.props.currentPageHiddenComponents.length +
      this.props.interactionsHiddenItems.length
    );
  };

  hasHiddenItemsCurrPage = () => {
    return !_.isEmpty(this.props.currentPageHiddenComponents);
  };

  hasHiddenItemsMasterPage = () => {
    return !_.isEmpty(this.props.masterPageHiddenComponents);
  };

  hasHiddenItemsInteraction = () => {
    return !_.isEmpty(this.props.interactionsHiddenItems);
  };

  hasDeletedItemsOnPanel = () => {
    return (
      this.hasHiddenItemsMasterPage() ||
      this.hasHiddenItemsCurrPage() ||
      this.hasHiddenItemsInteraction()
    );
  };

  //looks like a dead code
  getTooltipsPanelBounds = () => {
    const domNode = ReactDOM.findDOMNode(this);
    const clientRect = domNode
      ? //@ts-expect-error  ¯\_(ツ)_/¯
        domNode.getBoundingClientRect()
      : DEFAULT_TOOLTIP_LOCATION;
    return _.pick(clientRect, ['left', 'width']);
  };

  shouldShowInteractionsHiddenSection = () => {
    return this.props.interactionsHiddenItems.length > 0;
  };

  openLayersPanel = () => {
    this.props.closeHiddenItemsPanel();
    this.props.showLayersPanel();
  };

  componentWillUnmount() {
    this.props.clearHiddenItems();
  }

  render() {
    const LeftPanelFrame = isNewWorkspace
      ? leftBar.LeftPanelFrame
      : panels.frames.LeftPanelFrame;

    return (
      <LeftPanelFrame
        ref="hiddenItemsPanel"
        panelName={'mobileEditor.hiddenItemsPanel'}
        panelClass={util.cx({
          'hidden-items-panel': true,
          'hidden-items-panel-new-workspace': isNewWorkspace,
          'left-panel-hidden-elements-redesign-experiment':
            withLeftPanelHiddenElementsRedesignExperiment,
        })}
        helpId="769b8807-30b7-4ee4-8a7b-93674099732f"
        label={`${translate('mobile_hidden_items_panel_title')} (${
          this.state.notificationsCount
        })`}
        onContextMenu={(e: AnyFixMe) => {
          e.preventDefault();
        }}
        frameContentStyle={{ overflow: 'visible' }}
        className="hidden-items-panel"
      >
        <CustomScroll addScrolledClass={true}>
          <div className="content">
            {this.hasDeletedItemsOnPanel() ? (
              <div className="rich-text-subtitle">
                <Text size="small" weight="thin" enableEllipsis={false}>
                  {'mobile_hidden_items_panel_description'}
                </Text>
              </div>
            ) : null}
            <div className="scrollable">
              {this.hasHiddenItemsMasterPage() ? (
                <HiddenItemsContainer
                  key="hiddenItemsMP"
                  {...this.getMasterPageProps()}
                  sendBi={this.props.sendBi}
                />
              ) : null}

              {this.hasHiddenItemsCurrPage() ||
              this.hasHiddenItemsInteraction() ? (
                <HiddenItemsContainer
                  key="hiddenItemsCP"
                  {...this.getCurrentPageProps()}
                  sendBi={this.props.sendBi}
                />
              ) : null}

              {!this.hasDeletedItemsOnPanel() ? (
                <div key="hiddenItemsNoItems" className="no-items">
                  {translate('mobile_hidden_items_no_elements')}
                </div>
              ) : null}

              {this.shouldShowInteractionsHiddenSection() && (
                <InteractionsHiddenItemsContainer
                  hiddenComps={this.props.interactionsHiddenItems}
                  pageId={this.props.focusedPageId}
                  sendBi={this.props.sendBi}
                />
              )}
            </div>
          </div>
        </CustomScroll>
        <footer className="hidden-panel-message">
          {withLeftPanelHiddenElementsRedesignExperiment && (
            <>
              <Hint />
              <RichText className="footer-content">
                <div className="title">
                  <Text weight="bold" size="small">
                    {'mobile_hidden_items_panel_layers_entry_title'}
                  </Text>
                </div>
                <p>
                  {translate('mobile_hidden_items_panel_layers_entry_text')}
                  <a onClick={this.openLayersPanel}>
                    {` ${translate(
                      'mobile_hidden_items_panel_layers_entry_link',
                    )}`}
                  </a>
                </p>
              </RichText>
            </>
          )}
          {!withLeftPanelHiddenElementsRedesignExperiment && (
            <RichText className="footer-content">
              <div className="title">
                <baseUI.symbol name="infoSmall" className="infoSmall" />
                <Text weight="bold" size="small">
                  {'mobile_hidden_items_panel_layers_entry_title'}
                </Text>
              </div>
              <p>
                {translate('mobile_hidden_items_panel_layers_entry_text')}
                <a onClick={this.openLayersPanel}>
                  {` ${translate(
                    'mobile_hidden_items_panel_layers_entry_link',
                  )}`}
                </a>
              </p>
            </RichText>
          )}
        </footer>
      </LeftPanelFrame>
    );
  }
}

const stateToProps = ({ editorAPI, state }: AnyFixMe) => ({
  interactionsHiddenItems:
    selectors.getInteractionsMobileHiddenItems(editorAPI),
  masterPageHiddenComponents: selectors.getMasterPageHiddenComponents(state),
  currentPageHiddenComponents: selectors.getCurrentPageHiddenComponents(state),
  focusedPageId: stateManagement.pages.selectors.getFocusedPageId(state),
  focusedPageTitle: stateManagement.pages.selectors.getFocusedPageTitle(state),
});

const getEditorAPI = (
  dispatch: AnyFixMe,
  getState: AnyFixMe,
  { editorAPI }: AnyFixMe,
) => editorAPI;

const dispatchToProps = (dispatch: AnyFixMe) => ({
  sendBi: dispatch(getEditorAPI).bi.event,

  updateNotificationsCount: (notificationsCount: number) => {
    dispatch(
      stateManagement.leftBar.actions.setNotificationsCount(
        'mobileEditor.hiddenItemsPanel',
        notificationsCount,
      ),
    );
  },

  initHiddenItemsPanel: () => {
    dispatch(actions.initHiddenItemsPanel(hiddenItemsUtils));
    dispatch(
      stateManagement.leftBar.actions.updateDidDismissMobileHiddenItemsTooltip(
        true,
      ),
    );
  },

  clearHiddenItems: () => dispatch(actions.clearHiddenItems()),

  showLayersPanel: () => {
    const editorAPI = dispatch(getEditorAPI);
    const origin = 'hidden items panel';
    editorAPI.bi.event(coreBi.events.layers.layers_panel_click);
    editorAPI.store.dispatch(
      stateManagement.layersPanel.actions.showLayersPanel(origin),
    );
  },

  closeHiddenItemsPanel: () => {
    dispatch(
      stateManagement.actions.closePanelByName('mobileEditor.hiddenItemsPanel'),
    );
  },
});

const Connected = util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  stateToProps,
  dispatchToProps,
)(HiddenItemsPanel);
Connected.pure = HiddenItemsPanel;

export default Connected;
