"use strict"
const addPanelDataConsts = require('@/addPanelDataConsts')
module.exports = {
    type: addPanelDataConsts.SECTIONS_TYPES.PRESET,
    hide: false,
    title: "add_section_label_signatureInput",
    subNavigationTitle: "add_section_label_signatureInput",
    presetTitle: "add_section_heading_signatureInput",
    tooltipTitle: "add_section_label_signatureInput_tooltip_title",
    automationId: "add-panel-section-signatureInputSection",
    subNavigationHide: false,
    showSectionHeader: true,
    additionalBehaviours: {
        labelMode: addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
        infoIcon: addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
        hoverImageAction: addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
        iconEnabledForComps: {}
    },
    props: {
        image:
            "addPanelData/sections/developer_en/signatureInputSection_en.png",
        imageHover: null,
        items: [
            {
                id: "none_1",
                structure: {
                    layout: {
                        width: 304,
                        height: 130,
                        x: 10,
                        y: 12,
                        scale: 1,
                        rotationInDegrees: 0,
                        fixedPosition: false
                    },
                    componentType: "wixui.SignatureInput",
                    type: "Component",
                    skin: "wixui.skins.SignatureInput",
                    parent: "comp-k4tss5s5",
                    metaData: {
                        pageId: "k60a9"
                    },
                    data: {
                        type: "SignatureInput",
                        metaData: {
                            isPreset: false,
                            schemaVersion: "1.0",
                            isHidden: false,
                            pageId: "k60a9"
                        },
                        titleText: "Your Signature",
                        clearButtonText: "Clear"
                    },
                    props: {
                        type: "SignatureInputProperties",
                        metaData: {
                            schemaVersion: "1.0",
                            autoGenerated: false,
                            pageId: "k60a9"
                        },
                        direction: "ltr",
                        required: false,
                        titlePaddingStart: 1,
                        titleMarginBottom: 8
                    },
                    mobileHints: {
                        type: "MobileHints",
                        author: "duplicate",
                        originalCompId: "comp-k0wiiosg",
                        recommendedScale: 1,
                        recommendedWidth: 263,
                        recommendedHeight: 112,
                        metaData: {
                            pageId: "k60a9"
                        }
                    },
                    style: {
                        componentClassName: "wixui.SignatureInput",
                        pageId: "",
                        compId: "",
                        styleType: "custom",
                        metaData: {
                            isPreset: false,
                            schemaVersion: "1.0",
                            isHidden: false,
                            pageId: "k60a9"
                        },
                        style: {
                            groups: {},
                            properties: {
                                clearButtonColorDisabled: "#CCCCCC",
                                padLineColorHover: "#000000",
                                padBorderColorDisabled: "#DBDBDB",
                                padBackground: "#FFFFFF",
                                padBorderWidthDisabled: "2px",
                                titleFontDisabled:
                                    "normal normal normal 14px/1.4em avenir-lt-w01_35-light1475496",
                                padBorderColor: "#919191",
                                padBorderColorError: "#FF4040",
                                clearButtonColor: "#999997",
                                "boxShadowToggleOn-padBoxShadow": "false",
                                padBorderWidth: "2",
                                titleFont:
                                    "normal normal normal 14px/1.4em avenir-lt-w01_35-light1475496",
                                titleColorDisabled: "#CCCCCC",
                                padLineWidthError: "1",
                                clearButtonFontDisabled:
                                    "normal normal normal 13px/1.4em avenir-lt-w01_35-light1475496",
                                signatureColor: "#000000",
                                titleColor: "#7F808A",
                                padLineColorError: "#919191",
                                clearButtonFont:
                                    "normal normal normal 13px/1.4em avenir-lt-w01_35-light1475496",
                                padBorderRadius: "0px 0px 0px 0px",
                                padBorderWidthFocus: "2",
                                clearButtonColorFocus: "#000000",
                                padBorderColorFocus: "#000000",
                                titleAsteriskColorDisabled: "#CCCCCC",
                                padBorderWidthHover: "2",
                                padLineColorDisabled: "#CCCCCC",
                                padBoxShadow: "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                padBorderColorHover: "#5C5C5C",
                                signatureSize: "1",
                                padLineWidthFocus: "1",
                                titleAsteriskColor: "#7F808A",
                                padLineWidthDisabled: "1",
                                padBackgroundHover: "#F2FAF9",
                                padLineColor: "#000000",
                                padLineColorFocus: "#000000",
                                padLineWidthHover: "1",
                                padLineWidth: "1"
                            },
                            propertiesSource: {
                                clearButtonColorDisabled: "value",
                                padLineColorHover: "value",
                                padBorderColorDisabled: "value",
                                padBackground: "value",
                                padBorderWidthDisabled: "value",
                                titleFontDisabled: "value",
                                padBorderColor: "value",
                                padBorderColorError: "value",
                                clearButtonColor: "value",
                                "boxShadowToggleOn-padBoxShadow": "value",
                                padBorderWidth: "value",
                                titleFont: "value",
                                titleColorDisabled: "value",
                                padLineWidthError: "value",
                                clearButtonFontDisabled: "value",
                                signatureColor: "value",
                                titleColor: "value",
                                padLineColorError: "value",
                                clearButtonFont: "value",
                                padBorderRadius: "value",
                                padBorderWidthFocus: "value",
                                clearButtonColorFocus: "value",
                                padBorderColorFocus: "value",
                                titleAsteriskColorDisabled: "value",
                                padBorderWidthHover: "value",
                                padLineColorDisabled: "value",
                                padBoxShadow: "value",
                                padBorderColorHover: "value",
                                signatureSize: "value",
                                padLineWidthFocus: "value",
                                titleAsteriskColor: "value",
                                padLineWidthDisabled: "value",
                                padBackgroundHover: "value",
                                padLineColor: "value",
                                padLineColorFocus: "value",
                                padLineWidthHover: "value",
                                padLineWidth: "value"
                            }
                        },
                        type: "ComponentStyle",
                        skin: "wixui.skins.SignatureInput"
                    },
                    activeModes: {},
                    id: "comp-k4tss5tq"
                },
                preset: {
                    rect: {
                        width: 324,
                        height: 148,
                        x: 0,
                        y: 0
                    },
                    tags: null
                }
            },
            {
                id: "none_2",
                structure: {
                    layout: {
                        width: 304,
                        height: 130,
                        x: 10,
                        y: 159,
                        scale: 1,
                        rotationInDegrees: 0,
                        fixedPosition: false
                    },
                    componentType: "wixui.SignatureInput",
                    type: "Component",
                    skin: "wixui.skins.SignatureInput",
                    parent: "comp-k4tss5s5",
                    metaData: {
                        pageId: "k60a9"
                    },
                    data: {
                        type: "SignatureInput",
                        metaData: {
                            isPreset: false,
                            schemaVersion: "1.0",
                            isHidden: false,
                            pageId: "k60a9"
                        },
                        titleText: "Your Signature",
                        clearButtonText: "Clear"
                    },
                    props: {
                        type: "SignatureInputProperties",
                        metaData: {
                            schemaVersion: "1.0",
                            autoGenerated: false,
                            pageId: "k60a9"
                        },
                        direction: "ltr",
                        required: false,
                        titlePaddingStart: 1,
                        titleMarginBottom: 8
                    },
                    mobileHints: {
                        type: "MobileHints",
                        author: "duplicate",
                        originalCompId: "comp-k0wiiotf",
                        recommendedScale: 1,
                        recommendedWidth: 260,
                        recommendedHeight: 111,
                        metaData: {
                            pageId: "k60a9"
                        }
                    },
                    style: {
                        componentClassName: "wixui.SignatureInput",
                        pageId: "",
                        compId: "",
                        styleType: "custom",
                        metaData: {
                            isPreset: false,
                            schemaVersion: "1.0",
                            isHidden: false,
                            pageId: "k60a9"
                        },
                        style: {
                            groups: {},
                            properties: {
                                clearButtonColorDisabled: "#CCCCCC",
                                padLineColorHover: "#67D6D6",
                                padBorderColorDisabled: "#DBDBDB",
                                padBackground: "#FFFFFF",
                                padBorderWidthDisabled: "2",
                                titleFontDisabled:
                                    "italic normal normal 16px/1.4em georgia",
                                padBorderColor: "#67D6D6",
                                padBorderColorError: "#FF4040",
                                clearButtonColor: "#999997",
                                "boxShadowToggleOn-padBoxShadow": "true",
                                padBorderWidth: "1",
                                titleFont:
                                    "italic normal normal 16px/1.4em georgia",
                                titleColorDisabled: "#CCCCCC",
                                padLineWidthError: "1",
                                clearButtonFontDisabled:
                                    "normal normal normal 13px/1.4em avenir-lt-w01_35-light1475496",
                                signatureColor: "#5C5C5C",
                                titleColor: "#49D6D6",
                                padLineColorError: "#A3E6E6",
                                clearButtonFont:
                                    "normal normal normal 13px/1.4em avenir-lt-w01_35-light1475496",
                                padBorderRadius: "0px 0px 0px 0px",
                                padBorderWidthFocus: "1",
                                clearButtonColorFocus: "#000000",
                                padBorderColorFocus: "#408F8F",
                                titleAsteriskColorDisabled: "#CCCCCC",
                                padBorderWidthHover: "2",
                                padLineColorDisabled: "#CCCCCC",
                                padBoxShadow:
                                    "0.00px 2.00px 0px 0px rgba(103,214,214,1)",
                                padBorderColorHover: "#59BABA",
                                signatureSize: "2",
                                padLineWidthFocus: "1",
                                titleAsteriskColor: "#49D6D6",
                                padLineWidthDisabled: "1",
                                padBackgroundHover: "#FAFAFA",
                                padLineColor: "#A3E6E6",
                                padLineColorFocus: "#67D6D6",
                                padLineWidthHover: "1",
                                padLineWidth: "1"
                            },
                            propertiesSource: {
                                clearButtonColorDisabled: "value",
                                padLineColorHover: "value",
                                padBorderColorDisabled: "value",
                                padBackground: "value",
                                padBorderWidthDisabled: "value",
                                titleFontDisabled: "value",
                                padBorderColor: "value",
                                padBorderColorError: "value",
                                clearButtonColor: "value",
                                "boxShadowToggleOn-padBoxShadow": "value",
                                padBorderWidth: "value",
                                titleFont: "value",
                                titleColorDisabled: "value",
                                padLineWidthError: "value",
                                clearButtonFontDisabled: "value",
                                signatureColor: "value",
                                titleColor: "value",
                                padLineColorError: "value",
                                clearButtonFont: "value",
                                padBorderRadius: "value",
                                padBorderWidthFocus: "value",
                                clearButtonColorFocus: "value",
                                padBorderColorFocus: "value",
                                titleAsteriskColorDisabled: "value",
                                padBorderWidthHover: "value",
                                padLineColorDisabled: "value",
                                padBoxShadow: "value",
                                padBorderColorHover: "value",
                                signatureSize: "value",
                                padLineWidthFocus: "value",
                                titleAsteriskColor: "value",
                                padLineWidthDisabled: "value",
                                padBackgroundHover: "value",
                                padLineColor: "value",
                                padLineColorFocus: "value",
                                padLineWidthHover: "value",
                                padLineWidth: "value"
                            }
                        },
                        type: "ComponentStyle",
                        skin: "wixui.skins.SignatureInput"
                    },
                    activeModes: {},
                    id: "comp-k4tss5v2"
                },
                preset: {
                    rect: {
                        width: 324,
                        height: 148,
                        x: 0,
                        y: 148
                    },
                    tags: null
                }
            },
            {
                id: "none_3",
                structure: {
                    layout: {
                        width: 304,
                        height: 130,
                        x: 10,
                        y: 308,
                        scale: 1,
                        rotationInDegrees: 0,
                        fixedPosition: false
                    },
                    componentType: "wixui.SignatureInput",
                    type: "Component",
                    skin: "wixui.skins.SignatureInput",
                    parent: "comp-k4tss5s5",
                    metaData: {
                        pageId: "k60a9"
                    },
                    data: {
                        type: "SignatureInput",
                        metaData: {
                            isPreset: false,
                            schemaVersion: "1.0",
                            isHidden: false,
                            pageId: "k60a9"
                        },
                        titleText: "Your Signature",
                        clearButtonText: "Clear"
                    },
                    props: {
                        type: "SignatureInputProperties",
                        metaData: {
                            schemaVersion: "1.0",
                            autoGenerated: false,
                            pageId: "k60a9"
                        },
                        direction: "ltr",
                        required: false,
                        titlePaddingStart: 4,
                        titleMarginBottom: 8
                    },
                    mobileHints: {
                        type: "MobileHints",
                        author: "duplicate",
                        originalCompId: "comp-k0wiiotv",
                        recommendedScale: 1,
                        recommendedWidth: 260,
                        recommendedHeight: 111,
                        metaData: {
                            pageId: "k60a9"
                        }
                    },
                    style: {
                        componentClassName: "wixui.SignatureInput",
                        pageId: "",
                        compId: "",
                        styleType: "custom",
                        metaData: {
                            isPreset: false,
                            schemaVersion: "1.0",
                            isHidden: false,
                            pageId: "k60a9"
                        },
                        style: {
                            groups: {},
                            properties: {
                                clearButtonColorDisabled: "#CCCCCC",
                                padLineColorHover: "#FFFFFF",
                                padBorderColorDisabled: "#DBDBDB",
                                padBackground: "#4BDB7B",
                                padBorderWidthError: "0",
                                padBorderWidthDisabled: "0",
                                titleFontDisabled:
                                    "normal normal normal 14px/1.4em bree-w01-thin-oblique",
                                padBorderColor: "#FFFFFF",
                                padBorderColorError: "#FF4040",
                                clearButtonColor: "#999997",
                                padBackgroundError: "#FF4040",
                                "boxShadowToggleOn-padBoxShadow": "false",
                                padBorderWidth: "0",
                                titleFont:
                                    "normal normal normal 14px/1.4em bree-w01-thin-oblique",
                                titleColorDisabled: "#CCCCCC",
                                padLineWidthError: "1",
                                clearButtonFontDisabled:
                                    "normal normal normal 13px/1.4em avenir-lt-w01_35-light1475496",
                                signatureColor: "#FFFFFF",
                                titleColor: "#4BDB7B",
                                padLineColorError: "#FFFFFF",
                                clearButtonFont:
                                    "normal normal normal 13px/1.4em avenir-lt-w01_35-light1475496",
                                padBorderRadius: "6px 6px 6px 6px",
                                padBorderWidthFocus: "0",
                                clearButtonColorFocus: "#000000",
                                padBorderColorFocus: "#FFFFFF",
                                titleAsteriskColorDisabled: "#CCCCCC",
                                padBorderWidthHover: "0",
                                padBackgroundFocus: "#4BDB7B",
                                padLineColorDisabled: "#FFFFFF",
                                padBoxShadow: "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                padBorderColorHover: "#FFFFFF",
                                signatureSize: "2",
                                padLineWidthFocus: "1",
                                titleAsteriskColor: "#4BDB7B",
                                padLineWidthDisabled: "1",
                                padBackgroundHover: "#5EE08A",
                                padLineColor: "#FFFFFF",
                                padLineColorFocus: "#FFFFFF",
                                padLineWidthHover: "1",
                                padBackgroundDisabled: "#DBDBDB",
                                padLineWidth: "1"
                            },
                            propertiesSource: {
                                clearButtonColorDisabled: "value",
                                padLineColorHover: "value",
                                padBorderColorDisabled: "value",
                                padBackground: "value",
                                padBorderWidthError: "value",
                                padBorderWidthDisabled: "value",
                                titleFontDisabled: "value",
                                padBorderColor: "value",
                                padBorderColorError: "value",
                                clearButtonColor: "value",
                                padBackgroundError: "value",
                                "boxShadowToggleOn-padBoxShadow": "value",
                                padBorderWidth: "value",
                                titleFont: "value",
                                titleColorDisabled: "value",
                                padLineWidthError: "value",
                                clearButtonFontDisabled: "value",
                                signatureColor: "value",
                                titleColor: "value",
                                padLineColorError: "value",
                                clearButtonFont: "value",
                                padBorderRadius: "value",
                                padBorderWidthFocus: "value",
                                clearButtonColorFocus: "value",
                                padBorderColorFocus: "value",
                                titleAsteriskColorDisabled: "value",
                                padBorderWidthHover: "value",
                                padBackgroundFocus: "value",
                                padLineColorDisabled: "value",
                                padBoxShadow: "value",
                                padBorderColorHover: "value",
                                signatureSize: "value",
                                padLineWidthFocus: "value",
                                titleAsteriskColor: "value",
                                padLineWidthDisabled: "value",
                                padBackgroundHover: "value",
                                padLineColor: "value",
                                padLineColorFocus: "value",
                                padLineWidthHover: "value",
                                padBackgroundDisabled: "value",
                                padLineWidth: "value"
                            }
                        },
                        type: "ComponentStyle",
                        skin: "wixui.skins.SignatureInput"
                    },
                    activeModes: {},
                    id: "comp-k4tss5vq"
                },
                preset: {
                    rect: {
                        width: 324,
                        height: 144,
                        x: 0,
                        y: 295
                    },
                    tags: null
                }
            },
            {
                id: "none_4",
                structure: {
                    layout: {
                        width: 304,
                        height: 130,
                        x: 10,
                        y: 447,
                        scale: 1,
                        rotationInDegrees: 0,
                        fixedPosition: false
                    },
                    componentType: "wixui.SignatureInput",
                    type: "Component",
                    skin: "wixui.skins.SignatureInput",
                    parent: "comp-k4tss5s5",
                    metaData: {
                        pageId: "k60a9"
                    },
                    data: {
                        type: "SignatureInput",
                        metaData: {
                            isPreset: false,
                            schemaVersion: "1.0",
                            isHidden: false,
                            pageId: "k60a9"
                        },
                        titleText: "Your Signature",
                        clearButtonText: "Clear"
                    },
                    props: {
                        type: "SignatureInputProperties",
                        metaData: {
                            schemaVersion: "1.0",
                            autoGenerated: false,
                            pageId: "k60a9"
                        },
                        direction: "ltr",
                        required: false,
                        titlePaddingStart: 1,
                        titleMarginBottom: 8
                    },
                    mobileHints: {
                        type: "MobileHints",
                        author: "duplicate",
                        originalCompId: "comp-k0wiiosy",
                        recommendedScale: 1,
                        recommendedWidth: 260,
                        recommendedHeight: 111,
                        metaData: {
                            pageId: "k60a9"
                        }
                    },
                    style: {
                        componentClassName: "wixui.SignatureInput",
                        pageId: "",
                        compId: "",
                        styleType: "custom",
                        metaData: {
                            isPreset: false,
                            schemaVersion: "1.0",
                            isHidden: false,
                            pageId: "k60a9"
                        },
                        style: {
                            groups: {},
                            properties: {
                                padLineColorHover: "#8C84FA",
                                padBorderColorDisabled: "#DBDBDB",
                                padBackground: "#FFFFFF",
                                padBorderWidthDisabled: "2",
                                titleFontDisabled:
                                    "normal normal normal 15px/1.4em futura-lt-w01-light",
                                padBorderColor: "#8C84FA",
                                padBorderColorError: "#FF4040",
                                clearButtonColor: "#999997",
                                "boxShadowToggleOn-padBoxShadow": "false",
                                padBorderWidth: "1",
                                titleFont:
                                    "normal normal normal 15px/1.4em futura-lt-w01-light",
                                titleColorDisabled: "#CCCCCC",
                                padLineWidthError: "1",
                                clearButtonFontDisabled:
                                    "normal normal normal 13px/1.4em avenir-lt-w01_35-light1475496",
                                signatureColor: "#5C5C5C",
                                titleColor: "#8C84FA",
                                padLineColorError: "#8C84FA",
                                clearButtonFont:
                                    "normal normal normal 13px/1.4em avenir-lt-w01_35-light1475496",
                                padBorderRadius: "0px 0px 0px 0px",
                                padBorderWidthFocus: "1",
                                clearButtonColorFocus: "#000000",
                                padBorderColorFocus: "#6055E6",
                                titleAsteriskColorDisabled: "#CCCCCC",
                                padBorderWidthHover: "1",
                                padBackgroundFocus: "#FFFFFF",
                                padLineColorDisabled: "#CCCCCC",
                                padBoxShadow: "0px 1px 4px 0px rgba(0,0,0,0.6)",
                                padBorderColorHover: "#8C84FA",
                                signatureSize: "1",
                                padLineWidthFocus: "1",
                                titleAsteriskColor: "#8C84FA",
                                padLineWidthDisabled: "1",
                                padBackgroundHover: "#FEFAFF",
                                padLineColor: "#8C84FA",
                                padLineColorFocus: "#8C84FA",
                                padLineWidthHover: "1",
                                padBackgroundDisabled: "#FFFFFF",
                                padLineWidth: "1"
                            },
                            propertiesSource: {
                                padLineColorHover: "value",
                                padBorderColorDisabled: "value",
                                padBackground: "value",
                                padBorderWidthDisabled: "value",
                                titleFontDisabled: "value",
                                padBorderColor: "value",
                                padBorderColorError: "value",
                                clearButtonColor: "value",
                                "boxShadowToggleOn-padBoxShadow": "value",
                                padBorderWidth: "value",
                                titleFont: "value",
                                titleColorDisabled: "value",
                                padLineWidthError: "value",
                                clearButtonFontDisabled: "value",
                                signatureColor: "value",
                                titleColor: "value",
                                padLineColorError: "value",
                                clearButtonFont: "value",
                                padBorderRadius: "value",
                                padBorderWidthFocus: "value",
                                clearButtonColorFocus: "value",
                                padBorderColorFocus: "value",
                                titleAsteriskColorDisabled: "value",
                                padBorderWidthHover: "value",
                                padBackgroundFocus: "value",
                                padLineColorDisabled: "value",
                                padBoxShadow: "value",
                                padBorderColorHover: "value",
                                signatureSize: "value",
                                padLineWidthFocus: "value",
                                titleAsteriskColor: "value",
                                padLineWidthDisabled: "value",
                                padBackgroundHover: "value",
                                padLineColor: "value",
                                padLineColorFocus: "value",
                                padLineWidthHover: "value",
                                padBackgroundDisabled: "value",
                                padLineWidth: "value"
                            }
                        },
                        type: "ComponentStyle",
                        skin: "wixui.skins.SignatureInput"
                    },
                    activeModes: {},
                    id: "comp-k4tss5xe"
                },
                preset: {
                    rect: {
                        width: 324,
                        height: 144,
                        x: 0,
                        y: 439
                    },
                    tags: null
                }
            }
        ],
        compTypes: ["wixui.SignatureInput"]
    },
    help: {
        hide: false,
        text: "add_section_label_signatureInput_tooltip_description"
    }
}
