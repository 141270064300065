// this hook is inspired by panels/mixins/disableKeyboardShortcutsMixin
// it uses the same logic as disableKeyboardShortcutsMixin and
// provides more generic way to add keyboardShortcuts in the component logic

import { useEffect } from 'react';
import _ from 'lodash';
import { keyboardShortcuts } from '@/util';

type ShortcutKey = string;
type ShortcutAction = (keyEvent: any) => void;
export type Shortcuts = Record<ShortcutKey, ShortcutAction>;

const DYNAMIC_CONTEXT_PREFIX = 'DYNAMIC_CONTEXT';
const generateDynamicContext = () => _.uniqueId(DYNAMIC_CONTEXT_PREFIX);

const prevContextsStack: AnyFixMe = [];

const enableKeyboardShortcuts = (shortcuts: Shortcuts) => {
  const shortcutsIsEnabled = keyboardShortcuts.isEnabled();
  const context = generateDynamicContext();

  if (!shortcutsIsEnabled) {
    keyboardShortcuts.enable();
  }

  prevContextsStack.push(keyboardShortcuts.getContext());

  keyboardShortcuts.registerContext(context, shortcuts);
  keyboardShortcuts.setContext(context);

  return () => {
    const curContext = keyboardShortcuts.getContext();
    const prevContext = prevContextsStack.pop();

    if (curContext === context) {
      keyboardShortcuts.setContext(prevContext);
    }

    keyboardShortcuts.unregisterContext(context);

    if (!shortcutsIsEnabled) {
      keyboardShortcuts.disable();
    }
  };
};

export default function useKeyboardShortcuts(shortcuts: Shortcuts) {
  useEffect(
    () => {
      if (!shortcuts || !Object.keys(shortcuts).length) {
        return;
      }

      return enableKeyboardShortcuts(shortcuts);
    }, // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
}
