import { editorModel } from '@/util';
import { MIGRATION_VERSION } from '../version';

import type { SectionsMigrationScope as Scope } from '../scope';

export const restoreToADI = async (
  scope: Scope,
  { isCanceledByUser }: { isCanceledByUser: boolean },
): Promise<void> => {
  const { onboardingEditorUrl: adiUrl } = editorModel;
  const trimmedAdiUrl = adiUrl.split('#')[0];
  const failedMigrationFlag = `&failedSectionsMigrationVersion=${MIGRATION_VERSION}`;
  const canceledMigrationFlag = `&failedSectionsMigration=true`;
  const migrationFlag = isCanceledByUser
    ? canceledMigrationFlag
    : failedMigrationFlag;
  const formattedAdiUrl = trimmedAdiUrl + migrationFlag;
  window.onbeforeunload = null;

  await scope.editorAPI.site.restoreToADI();

  window.location.href = formattedAdiUrl;

  return;
};
