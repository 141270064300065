// @ts-nocheck
import pageCategories from '../../../utils/pageCategories';
import { isCustomMenusEnabled } from '@/util';

const MAIN_MENU_TITLE = 'CUSTOM_MAIN_MENU';

const getMenuTabNameIfApplicable = (
  membersAppDefId,
  menuId,
  menuAppDefId,
  menuName,
  isFlatMenusView,
) => {
  if (menuId === MAIN_MENU_TITLE) {
    return isFlatMenusView
      ? pageCategories.MAIN_MENU.flatMenuTitle
      : pageCategories.MAIN_MENU.title;
  } else if (menuAppDefId === membersAppDefId) {
    return pageCategories.MEMBERS_MENU.title;
  }

  return menuName;
};

const getHelpIdForMenu = (menuId, menuAppDefId, membersAppDefId, isDesktop) => {
  if (menuId === MAIN_MENU_TITLE) {
    if (isCustomMenusEnabled()) {
      return pageCategories.MAIN_MENU.customMenusHelpId;
    }
    return isDesktop
      ? pageCategories.MAIN_MENU.helpId
      : pageCategories.MAIN_MENU.mobileHelpId;
  } else if (menuAppDefId === membersAppDefId) {
    return isDesktop
      ? pageCategories.MEMBERS_MENU.helpId
      : pageCategories.MEMBERS_MENU.mobileHelpId;
  }

  return pageCategories.MENUS.helpId;
};

export { getMenuTabNameIfApplicable, getHelpIdForMenu };
