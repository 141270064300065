// @ts-nocheck
function init(editorAPI) {
  const resizeWindow = function (msg, response, settingModal) {
    editorAPI.panelManager.updatePanelProps(settingModal.name, {
      width: msg.data.width,
      height: msg.data.height,
    });
  };

  return {
    resizeWindow,
  };
}

export default init;
