:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._illustration_1u6lg_7 {
  height: 140px;
  margin-top: 25px; }

._listContainer_1u6lg_11 {
  padding: 24px 18px 0 18px; }

._check_1u6lg_14 {
  fill: #116dff; }
