// @ts-nocheck
import React from 'react';
import ReactDOM from 'react-dom';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { CustomScroll, Divider, PanelHeader } from '@wix/wix-base-ui';
import $ from 'zepto';

import * as utils from '@wix/santa-editor-utils';

import constants from '@/constants';
import * as util from '@/util';
import * as stateManagement from '@/stateManagement';

import disableKeyboardShortcutsMixin from '../../mixins/disableKeyboardShortcutsMixin';
import PanelCloseOrigin, { Origin } from '../panelCloseOrigin';
import type { StageLayout } from '@/stateManagement';

const { measuring } = utils.hoc;

const isNewWorkspace = util.workspace.isNewWorkspaceEnabled();

function extractIntCSS(cssValue, absoluteForCalc) {
  if (/%$/.test(cssValue)) {
    const percent = parseInt(cssValue, 10);
    return parseInt(absoluteForCalc * (percent / 100), 10) || 0;
  }
  return parseInt(cssValue, 10) || 0;
}
// eslint-disable-next-line react/prefer-es6-class
const ToolPanelFrame = createReactClass({
  displayName: constants.PANEL_TYPES.TOOL,
  propTypes: {
    panelName: PropTypes.string.isRequired,
    selectedComponentType: PropTypes.string,
    headerTitle: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    helpId: PropTypes.string,
    top: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    left: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isBlockingLayerFullScreen: PropTypes.bool,
    shouldBlockOuterScroll: PropTypes.bool,
    lightHeader: PropTypes.bool,
    contentClass: PropTypes.string,
    contentWrapperClass: PropTypes.string,
    onClose: PropTypes.func,
    openHelpCenter: PropTypes.func,
    onBack: PropTypes.func,
    disableOverlay: PropTypes.bool,
    removeOverlay: PropTypes.bool,
    contentStyle: PropTypes.object,
    style: PropTypes.object,
    close: PropTypes.func,
    onContentElementReady: PropTypes.func,
    reportMeasurements: PropTypes.func.isRequired,
    measurements: PropTypes.shape({
      contentHeight: PropTypes.number,
      contentWidth: PropTypes.number,
      stageLayout: PropTypes.object,
      editorContentLayout: PropTypes.object,
      initialPos: PropTypes.shape({
        top: PropTypes.number,
        left: PropTypes.number,
      }),
    }),
    automationId: PropTypes.string,
    headerAutomationId: PropTypes.string,
    className: PropTypes.string,
    stageLayout: PropTypes.object,
    editorContentLayout: PropTypes.object,
    renderContent: PropTypes.func,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    useScroll: PropTypes.bool,
    withHeaderDivider: PropTypes.bool,
    withHeaderStripe: PropTypes.bool, // available only in new workspace
    isPositionCentered: PropTypes.bool,
  },
  mixins: [util.draggableMixin, disableKeyboardShortcutsMixin],
  getDefaultProps() {
    return {
      withHeaderDivider: isNewWorkspace, // enabled by default only in new workspace
      withHeaderStripe: !isNewWorkspace, // disabled by default only in new workspace
    };
  },
  componentDidMount() {
    if (this.props.onContentElementReady && this.contentWrapper) {
      this.props.onContentElementReady(this.contentWrapper);
    }
    this.measureContentElement();
  },
  measureContentElement() {
    const $contentElm = $(
      ReactDOM.findDOMNode(this.getContentWrapperElement()),
    );
    const { stageLayout } = this.props;
    const { editorContentLayout } = this.props;
    const contentHeight = $contentElm.height() || 0;
    const contentWidth = $contentElm.width() || 0;

    const contentTop = extractIntCSS(
      $contentElm.css('top'),
      stageLayout.height + stageLayout.top,
    );
    const contentLeft = extractIntCSS(
      $contentElm.css('left'),
      stageLayout.width + stageLayout.left,
    );
    const initialPos = this.calcInitialContentPos(
      contentTop,
      contentLeft,
      stageLayout,
      contentWidth,
      contentHeight,
      editorContentLayout,
    );
    this.props.reportMeasurements({ initialPos });
  },

  calcInitialContentPos(
    currentTop: number,
    currentLeft: number,
    stageLayout: StageLayout,
    contentWidth: number,
    contentHeight: number,
    editorContentLayout: StageLayout,
  ) {
    const scrollTop = $(window).scrollTop() || 0;

    const panelMaxTop = this.props.isPositionCentered
      ? scrollTop + (editorContentLayout.bottom - contentHeight) / 2
      : scrollTop + (editorContentLayout.bottom - contentHeight);
    const panelMaxLeft = stageLayout.right - contentWidth;
    const minPositionFromTop = this.getDragLimits().y[0];

    return {
      top: Math.max(Math.min(currentTop, panelMaxTop), minPositionFromTop),
      left: Math.min(Math.max(currentLeft, 0), panelMaxLeft),
    };
  },

  getContentWrapperProps() {
    return {
      style: this.getContentWrapperStyle(),
      className: util.cx({
        'tool-panel-frame': true,
        [this.props.contentWrapperClass]: true,
        hidden: _.isEmpty(this.props.measurements),
      }),
    };
  },

  getContentWrapperStyle() {
    if (!this.state.isCustomPosition && this.props?.measurements?.initialPos) {
      return _.defaults(
        {},
        this.props.measurements.initialPos,
        this.props.contentStyle,
      );
    }

    return this.props.contentStyle;
  },

  getDragLimits() {
    const $contentElm = $(
      ReactDOM.findDOMNode(this.getContentWrapperElement()),
    );
    const { stageLayout } = this.props;
    const { editorContentLayout } = this.props;
    const contentHeight = $contentElm.height() || 0;
    const contentWidth = $contentElm.width() || 0;

    return util.panelUtils.getPanelDragLimits(
      contentHeight,
      contentWidth,
      stageLayout,
      editorContentLayout,
    );
  },

  getContentClass() {
    return `content ${this.props.contentClass || ''}`;
  },

  getContentWrapperElement() {
    return this.contentWrapper;
  },

  setContentWrapper(contentWrapperElm) {
    this.contentWrapper = contentWrapperElm;
  },
  blockOuterScrollIfNecessary() {
    if (this.props.shouldBlockOuterScroll) {
      this.blockOuterScroll.apply(this, arguments);
    }
  },

  closePanel(origin: Origin) {
    if (this.props.onClose) {
      this.props.onClose(origin);
    }
    this.props.close();
  },

  onOverlayClick() {
    this.closePanel(PanelCloseOrigin.CLICK_OUTSIDE);
  },

  onEscape() {
    this.closePanel(PanelCloseOrigin.ESCAPE_PRESS);
  },
  startContentDrag(e) {
    return this.startDrag(e, this.getDragLimits(), this.contentWrapper);
  },

  getChildren() {
    return _.isFunction(this.props.renderContent)
      ? this.props.renderContent(this.startContentDrag)
      : this.props.children;
  },

  renderContent() {
    const withDivider = !isNewWorkspace && this.props.withHeaderDivider;
    const children = this.getChildren();

    return (
      <>
        {withDivider ? <Divider long /> : null}
        {children}
      </>
    );
  },
  render() {
    const noOverlay = {
      ...(this.props.removeOverlay && { width: 'fit-content' }),
    };

    return (
      <div
        onWheel={this.blockOuterScrollIfNecessary}
        data-aid={this.props.automationId}
        style={{ ...this.props.style, ...noOverlay }}
        className={`panel-frame-container tool-panel-frame-container tool-panel-frame-z-index${
          this.props.isBlockingLayerFullScreen ? ' full-screen' : ''
        } ${
          this.props.disableOverlay
            ? 'panel-frame-container-disable-blocking'
            : ''
        } ${this.props.className || ''}`}
      >
        {!this.props.disableOverlay && (
          <div
            onClick={this.onOverlayClick}
            ref="closeButton"
            className="transparent-frame-overlay"
            style={noOverlay}
          />
        )}
        <div ref={this.setContentWrapper} {...this.getContentWrapperProps()}>
          {this.props.headerTitle ? (
            <PanelHeader
              ref="header"
              key="toolFrameHeader"
              onMouseDown={this.startContentDrag}
              onClose={this.closePanel}
              onHelp={this.props.openHelpCenter}
              noHelpBtn={!this.props.helpId}
              onBack={this.props.onBack}
              className={`dragger ${this.props.lightHeader ? 'light' : 'dark'}`}
              automationId={this.props.headerAutomationId}
              removeStripe={!this.props.withHeaderStripe}
              removeDivider={!this.props.withHeaderDivider}
            >
              {this.props.headerTitle ? (
                <span key="headerTitle">{this.props.headerTitle}</span>
              ) : null}
            </PanelHeader>
          ) : null}
          {this.props.useScroll ? (
            <CustomScroll
              key="scrolled-content"
              heightRelativeToParent="100%"
              keepAtBottom={true}
            >
              <div className={`scrolled-content ${this.getContentClass()}`}>
                {this.renderContent()}
              </div>
            </CustomScroll>
          ) : null}

          {!this.props.useScroll ? (
            <div key="content" className={this.getContentClass()}>
              {this.renderContent()}
            </div>
          ) : null}
        </div>
      </div>
    );
  },
});

export default _.flowRight(
  stateManagement.panels.hoc.panelFrame(constants.PANEL_TYPES.TOOL),
  measuring,
)(ToolPanelFrame) as AnyFixMe;
