// @ts-nocheck
import PropTypes from 'prop-types';
import constants from '@/constants';
import * as stateManagement from '@/stateManagement';
import * as util from '@/util';
import { translate } from '@/i18n';
import * as coreBi from '@/coreBi';
import React from 'react';
import { FocusPanelFrame } from '../frames';
import {
  Button,
  Composites,
  Illustration,
  PanelHeader,
  RichText,
} from '@wix/wix-base-ui';
import * as symbols from '@wix/santa-editor-symbols';

const { setSiteUserPreferences } = stateManagement.userPreferences.actions;
const { top_bar_drop_panel_unpublish_lang } = coreBi.events.multilingual;
const { DONT_SHOW_AGAIN } = constants.USER_PREFS.MULTILINGUAL.UNPUBLISH_PANEL;
const { INACTIVE } = constants.MULTILINGUAL.LANGUAGE_STATUSES;

//TYPE WAS GENERATED, remove this line when reviewed
interface LanguageUnpublishPanelProps {
  panelName?: string;
  lang: AnyFixMe;
}

class LanguageUnpublishPanel extends React.Component<LanguageUnpublishPanelProps> {
  static displayName = 'languageUnpublishPanel';

  static propTypes = {
    panelName: PropTypes.string,
    lang: PropTypes.object.isRequired,
  };

  componentDidMount() {
    this.props.setDontShowAgain(true);
  }

  getTitle = () => {
    return translate('Hide_Language_Popup_Title', {
      language: this.props.lang.name,
    });
  };

  getMessage = () => {
    return translate('Hide_Language_Popup_Text', {
      language: this.props.lang.name,
    });
  };

  getConfirmButtonLabel = () => {
    return translate('Hide_Language_Popup_Hide_Button');
  };

  getCancelButtonLabel = () => {
    return translate('Hide_Language_Popup_Cancel_Button');
  };

  onClose = () => {
    this.props.closePanel();
  };

  onConfirm = () => {
    this.props.sendUnpublishBI();
    this.props.unpublishLanguage(this.props.lang.code);
    this.props.closePanel(); // optimistically close
  };

  getKeyboardShortcuts = () => {
    return {
      esc: this.onClose,
    };
  };

  render() {
    return (
      <FocusPanelFrame
        ref="panelFrame"
        shouldHideHeader
        shouldNotCloseOnBlur
        keyboardShortcuts={this.getKeyboardShortcuts()}
        panelName={this.props.panelName}
        className="language-unpublish-panel"
      >
        <Composites.PopupSmallSize>
          <PanelHeader noHelpBtn onClose={this.onClose} className="dark">
            <span>{this.getTitle()}</span>
          </PanelHeader>

          <Composites.PanelContent>
            <Composites.RichTextWithIllustration>
              <Illustration>
                <symbols.symbol name="languageUnpublished" />
              </Illustration>

              <Composites.RichText>
                <RichText type="T01">{this.getMessage()}</RichText>
              </Composites.RichText>
            </Composites.RichTextWithIllustration>
          </Composites.PanelContent>

          <Composites.ActionSetHorizontal>
            <Button onClick={this.onClose} className="secondary">
              <span>{this.getCancelButtonLabel()}</span>
            </Button>
            <Button onClick={this.onConfirm} className="primary">
              <span>{this.getConfirmButtonLabel()}</span>
            </Button>
          </Composites.ActionSetHorizontal>
        </Composites.PopupSmallSize>
      </FocusPanelFrame>
    );
  }
}

const mapStateToProps = ({ dsRead }) => ({
  siteId: dsRead.generalInfo.getSiteId(),
});

const mapDispatchToProps = (dispatch, { panelName, siteId, lang }) => ({
  closePanel: () =>
    dispatch(stateManagement.panels.actions.closePanelByNameAction(panelName)),
  unpublishLanguage: (langCode) =>
    dispatch(
      stateManagement.multilingual.actions.updateLanguageStatusThunk(
        langCode,
        INACTIVE,
      ),
    ),
  setDontShowAgain: (value) =>
    dispatch(setSiteUserPreferences(DONT_SHOW_AGAIN, value)),
  sendUnpublishBI: () =>
    dispatch(
      stateManagement.bi.actions.event(top_bar_drop_panel_unpublish_lang, {
        lang: lang.languageCode,
        origin: 'editor_language_unpublish_panel',
        esi: util.editorModel.editorSessionId,
        site_id: siteId,
      }),
    ),
});

export default util.hoc.connect(
  util.hoc.STORES.DS_ONLY,
  mapStateToProps,
  mapDispatchToProps,
)(LanguageUnpublishPanel);
