import experiment from 'experiment';

export const isForcedSiteWidthMigration = () =>
  experiment.isOpen('se_fixedStage');

export const isSiteWidthMigrationEnabled = () => {
  return experiment.isOpen('se_siteWidthMigration');
};

export const isSiteCreationSiteWidthMigrationEnabled = () =>
  experiment.isOpen('se_siteWidthMigrationSiteCreation');
