import { cx } from '@/util';
import React, { type FC } from 'react';
import type { GridLine } from 'types/core';

interface GridLinesProps {
  dataHook?: string;
  gridLines: GridLine[];
  fade?: boolean;
}

const GridLines: FC<GridLinesProps> = ({
  fade = false,
  gridLines,
  dataHook = '',
}) => (
  <svg data-hook={dataHook} className={cx('gridLines', { fade })}>
    {gridLines.map((gridLine, gridLineIndex) => (
      <line
        key={`${gridLineIndex}backLine`}
        x1={gridLine.x1}
        y1={gridLine.y1}
        x2={gridLine.x2}
        y2={gridLine.y2}
        className="backLine"
      />
    ))}
    {gridLines.map((gridLine, gridLineIndex) => (
      <line
        key={`${gridLineIndex}frontLine`}
        x1={gridLine.x1}
        y1={gridLine.y1}
        x2={gridLine.x2}
        y2={gridLine.y2}
        className="frontLine"
      />
    ))}
  </svg>
);

export default GridLines;
