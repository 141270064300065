// @ts-nocheck
import createReactClass from 'create-react-class';
import * as core from '@/core';
import React from 'react';
import { cx } from '@/util';
import type { CompRef } from 'types/documentServices';

function getTransition(overlayProps, animationPhase) {
  if (overlayProps[animationPhase]) {
    return {
      transitionDuration: `${overlayProps[animationPhase].duration || 0}ms`,
      transitionDelay: `${overlayProps[animationPhase].delay || 0}ms`,
    };
  }
  return {};
}

function getAnimationPhase() {
  return this.leaveAnimationId ? 'leave' : 'enter';
}

interface OverlayProps {
  hoveredComp?: CompRef;
}

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass<OverlayProps>({
  displayName: 'overlay',
  mixins: [core.mixins.editorAPIMixin],

  getInitialState() {
    return {
      shouldShowOverlay: false,
    };
  },

  overlayProps: {},

  getOverlay() {
    return (
      this.getEditorAPI().components.is.getOverlay(this.state.hoveredComp) || ''
    );
  },

  getStyles() {
    this.overlayProps = this.getEditorAPI().components.is.getOverlayProperties(
      this.state.hoveredComp,
    );

    return this.overlayProps
      ? getTransition(this.overlayProps, getAnimationPhase.call(this))
      : {};
  },

  UNSAFE_componentWillReceiveProps(nextProps) {
    const shouldShowOverlay =
      nextProps.hoveredComp &&
      this.getEditorAPI().components.is.exist(nextProps.hoveredComp) &&
      this.getEditorAPI().components.is.shouldBeOverlayed(
        nextProps.hoveredComp,
      );

    /* Handle case with keyboard's undo - you don't have this component anymore so let's hide overlay immediately */
    if (
      this.state.hoveredComp &&
      !this.getEditorAPI().components.is.exist(this.state.hoveredComp)
    ) {
      this.setState({
        shouldShowOverlay: false,
        hoveredComp: null,
      });
      return;
    }

    /* Handle case, for example, when you want to display tooltip over your overlay. When you hover on tooltip
         nextProps.hoveredComp will be empty but you still want to display overlay.
        */
    if (!this.getEditorAPI().overlay.shouldUpdate()) {
      return;
    }

    if (shouldShowOverlay) {
      this.componentWillEnter(nextProps.hoveredComp);
    } else if (this.state.shouldShowOverlay) {
      this.componentWillLeave();
    } else {
      return;
    }

    this.setState({
      shouldShowOverlay,
    });
  },

  componentWillEnter(nextHoveredComp) {
    if (this.leaveAnimationId) {
      this.leaveAnimationId = window.clearTimeout(this.leaveAnimationId);
    }

    this.setState({
      hoveredComp: nextHoveredComp,
    });
  },

  componentWillLeave() {
    this.leaveAnimationId = window.setTimeout(
      this.componentHasLeft,
      this.overlayProps.leave.duration,
    );
  },

  componentHasLeft() {
    this.leaveAnimationId = null;
    this.setState({
      hoveredComp: null,
    });
  },

  render() {
    return (
      <div
        style={this.getStyles()}
        className={cx({ overlay: true, visible: this.state.shouldShowOverlay })}
      >
        {this.getOverlay()}
      </div>
    );
  },
});
