import type { MapStateToProps, MapDispatchToProps } from 'types/redux';

import togglePreview from '../../thunks/togglePreview';

import type {
  PreviewButtonOwnProps as OwnProps,
  PreviewButtonStateProps as StateProps,
  PreviewButtonDispatchProps as DispatchProps,
} from './preview';

import { fixedStage } from '@/util';

export const mapStateToProps: MapStateToProps<StateProps, OwnProps> = ({
  state,
  editorAPI,
}) => ({
  status: state.previewingStatus,
  isSitePublished: editorAPI.dsRead?.generalInfo.isSitePublished(),
  isDevModeOn: editorAPI.getViewTools().developerModeEnabled,
  topBarStateBIParamValue: fixedStage.getTopBarStateBiParamValue(editorAPI),
  isFixedStageEnabled: fixedStage.isFixedStageEnabled(),
});

export const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = {
  togglePreview,
};
