// @ts-nocheck
import _ from 'lodash';
import * as stateManagement from '@/stateManagement';
import * as appMarketTabService from './services/appMarketTabService';
import * as appMarketUtils from './utils/appMarketUtils';
import * as util from '@/util';
import * as pendingAppsService from '../services/pendingAppsService';
import navigateAndOpenAppService from '../services/navigateAndOpenAppService';
import * as tpaUtils from '../utils/tpaUtils';
import constants from '../constants/constants';
import * as bi from '../bi/bi';
import * as tpaAddAppService from '../services/tpaAddAppService';
import * as tpaAlertsService from '../services/tpaAlertsService';
import type { EditorAPI } from '@/editorAPI';
import {
  EditorType,
  InstallationOriginType,
  InstallInitiator,
} from '@wix/platform-editor-sdk';
import type { PlatformOrigin } from '@/platform';

export interface AppMarketAddAppParams {
  appDefId: string;
  biData?: Object;
  appVersion?: string;
  settings?: AnyFixMe;
  platformOrigin?: PlatformOrigin;
}

const closeAppMarket = function (editorAPI) {
  appMarketUtils.closeAppMarket(editorAPI.panelManager.closePanelByName);
};

const openAppMarketHelp = function (editorAPI) {
  appMarketUtils.openAppMarketHelp(editorAPI);
};

const getUserDetails = function (editorAPI, options, callback) {
  const user = editorAPI.dsRead.generalInfo.getUserInfo();
  const userName = user?.name || '';
  callback(userName);
};

const appIsInstalled = function (editorAPI, options, callback) {
  const appDefinitionId = options?.params?.appDefinitionId;
  const includingDemoMode = options?.params?.includingDemoMode;
  callback(
    tpaAddAppService.isAppInstalled(
      editorAPI,
      appDefinitionId,
      includingDemoMode,
    ),
  );
};

const getPendingAppsList = function (editorAPI, options, callback) {
  callback(pendingAppsService.getPendingApps(editorAPI));
};

const removePendingApp = function (editorAPI, options) {
  pendingAppsService.dismiss(editorAPI, options.params.id);
};

const addPendingApp = function (editorAPI, options) {
  pendingAppsService.add(editorAPI, options.params.id);
};

const save = function (editorAPI: EditorAPI, options, callback) {
  const isFirstSave = editorAPI.dsRead.generalInfo.isFirstSave();
  const isDraft = editorAPI.dsRead.generalInfo.isDraft();

  if (isFirstSave || isDraft) {
    editorAPI.saveManager.saveInBackground(
      function () {
        callback({
          response: 'success',
          metaSiteId: editorAPI.dsRead.generalInfo.getMetaSiteId(),
        });
      },
      function onSaveError() {
        tpaAlertsService.openSaveFailedAlertForAppMarket(editorAPI);
        callback({
          response: 'site was not saved.',
        });
      },
      'appMarket',
      {
        sourceOfStart: 'appMarket_bgSave',
      },
    );
  } else {
    callback({
      response: 'success',
      metaSiteId: editorAPI.dsRead.generalInfo.getMetaSiteId(),
    });
  }
};

const deleteApp = function (editorAPI, options, callback) {
  const appDefId = options?.params?.appDefinitionId;
  editorAPI.store.dispatch(
    stateManagement.tpa.actions.deleteApp(
      appDefId,
      { removal_method: 'app_market' },
      callback,
    ),
  );
};

const uninstallPlatformApp = function (editorAPI, appDefinitionId) {
  const appData =
    editorAPI.dsRead.platform.getAppDataByAppDefId(appDefinitionId);
  return editorAPI.store.dispatch(
    stateManagement.platform.actions.uninstallApp(appData, false),
  );
};

const openApp = function (editorAPI, options) {
  const appDefId = options?.params?.appDefinitionId;
  tpaUtils.bi.reportBIOnAppIntent(
    editorAPI,
    bi.events.APP_INTENT,
    appDefId,
    constants.BI.initiator.EDITOR,
    constants.BI.type.OPENAPP_SETTINGS,
  );
  appMarketTabService.closeAppMarketTab(
    editorAPI.panelManager.closePanelByName,
  );
  navigateAndOpenAppService.navigateAndOpenSettingsForAppDefId(
    editorAPI,
    appDefId,
  );
};

const updateApp = function (editorAPI, options, callback = _.noop) {
  const appDefId = options?.params?.appDefinitionId;
  const appVersion = options?.params?.appVersion;
  const appData = editorAPI.dsRead.tpa.app.getDataByAppDefId(appDefId);
  const applicationId = appData?.applicationId;
  editorAPI.store
    .dispatch(
      stateManagement.platform.actions.updateApp({
        applicationId,
        appVersion,
      }),
    )
    .then((result) => {
      if (result) {
        callback();
      } else {
        callback({ error: 'Error during update' });
      }
    })
    .catch(() => {
      callback({ error: 'Error during update' });
    });
};

const setAppMarketState = function (editorAPI, options) {
  const state = options?.params?.state ?? '';
  appMarketUtils.setAppMarketSessionUserPrefs(editorAPI, { state });
};

const isDevMode = function (editorAPI, options, callback) {
  callback({
    isDevMode: tpaUtils.isInDevMode(),
  });
};

const openBMInEditor = function (editorAPI: EditorAPI, path: string) {
  editorAPI.account.openSettings({ path });
};

const addApp = async function (
  editorAPI: EditorAPI,
  params: AppMarketAddAppParams,
): Promise<void> {
  const biData = Object.assign(params.biData || {}, {
    adding_method: 'direct_add',
    market_version: 'new',
  });
  const appDefId = params?.appDefId;
  if (!appDefId) return;
  const marketData =
    await editorAPI.dsRead.tpa.appMarket.getDataAsync(appDefId);
  const shouldNavigate = marketData?.appFields?.shouldNavigate ?? true;
  const type = appMarketUtils.getAppType(marketData);
  const initiator = constants.BI.initiator.APP_MARKET;
  const options = {
    shouldNavigate,
    headlessInstallation: Boolean(
      appMarketUtils.isHeadlessInstallation(marketData),
    ),
    showPageAddedPanel: !marketData?.doNotShowPageAddedPanel,
    appVersion: params?.appVersion,
    settings: params?.settings,
  };

  if (util.sections.isSectionsEnabled()) {
    const sectionLikeContainerToPaste =
      util.sections.getSectionLikeContainerToPaste(editorAPI, []);
    options.containerRef = sectionLikeContainerToPaste;
    options.useRelativeToContainerLayout = true;
  }

  const platformOrigin = params.platformOrigin || {
    initiator: InstallInitiator.Editor,
    type: EditorType.Classic,
    info: {
      type: InstallationOriginType.AppMarket,
    },
  };
  return new Promise((resolve) => {
    tpaAddAppService.addApp(
      editorAPI,
      appDefId,
      marketData.name,
      null,
      type,
      initiator,
      false,
      biData,
      options,
      async () => {
        if (util.sections.isSectionsEnabled()) {
          await editorAPI.waitForChangesAppliedAsync();
          const selectedComponents =
            editorAPI.selection.getSelectedComponents();
          if (selectedComponents.length === 1) {
            editorAPI.components.hooks.componentAddedToStage.fire({
              compRef: selectedComponents[0],
              type: 'click',
              origin: 'appMarket/appManager',
            });
          }
        }
        resolve();
      },
      platformOrigin,
    );
    appMarketTabService.closeAppMarketTab(
      editorAPI.panelManager.closePanelByName,
    );
  });
};

export {
  closeAppMarket,
  openAppMarketHelp,
  getUserDetails,
  getPendingAppsList,
  appIsInstalled,
  removePendingApp,
  addPendingApp,
  save,
  deleteApp,
  uninstallPlatformApp,
  openApp,
  updateApp,
  setAppMarketState,
  isDevMode,
  addApp,
  openBMInEditor,
};
