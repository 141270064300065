import _ from 'lodash';

import type { PluginServicePlugins } from 'types/editorPlugins';

interface PluginsMap {
  doubleClick: PluginServicePlugins.DoubleClickPlugin;
  cannotCopyPlugin: PluginServicePlugins.CannotCopyPlugin;
  attachLabelPlugin: PluginServicePlugins.AttachLabelPlugin;
  allowToPastePlugin: PluginServicePlugins.AllowedToPastePlugin;
  addPanelDrop: any;
  addPanelAddByClickContainer: any;
}

const pluginConstants = {
  DOUBLE_CLICK: 'doubleClick',
  ALLOWED_TO_PASTE: 'allowToPastePlugin',
  CANNOT_COPY: 'cannotCopyPlugin',
  ATTACH_LABEL: 'attachLabelPlugin',
  ADD_PANEL_ADD_BY_CLICK_CONTAINER: 'addPanelAddByClickContainer',
  ADD_PANEL_DROP: 'addPanelDrop',
} as const;

type PluginNames = ValueOf<typeof pluginConstants> | string;
type PluginFunction<K> = K extends keyof PluginsMap ? PluginsMap[K] : any;

export function createPluginServiceApi() {
  const pluginMap: Record<string, AnyFixMe> = {};

  const registerPlugin = <T extends PluginNames>(
    pluginName: T,
    pluginKey: string,
    func: PluginFunction<T>,
  ) => {
    _.set(pluginMap, [pluginName, pluginKey], func);
  };

  const getPlugin = <T extends PluginNames>(
    pluginName: T,
    pluginKey: any,
  ): PluginFunction<T> | undefined => pluginMap?.[pluginName]?.[pluginKey];

  const getAllPluginsOfType = <T extends PluginNames>(
    pluginName: T,
  ): Record<string, PluginFunction<T>> | undefined => pluginMap?.[pluginName];

  return {
    pluginConstants,

    registerPlugin,
    getPlugin,
    getAllPluginsOfType,
  };
}
