import { ToursApiKey } from './api';
import {
  createWelcomeTour,
  TOUR_NAME as welcomeTourName,
} from './tours/welcomeTour.conf';

import type { StartOrigin } from './tours/tours.types';
import type { EditorAPI } from '@/editorAPI';

export const startWelcomeTour = async (
  editorAPI: EditorAPI,
  startOrigin?: StartOrigin,
) => {
  // TODO: when we have panels and topbar api's
  // should be mooved from (editorAPI) => ... to its own package / api
  const tourApi = editorAPI.host.getAPI(ToursApiKey);
  const welcomeTour = tourApi.createTour((shell) =>
    createWelcomeTour(shell, startOrigin),
  );

  return await tourApi.startTour(welcomeTour);
};

export { welcomeTourName };
