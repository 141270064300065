'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')

module.exports =
  {
  type: addPanelDataConsts.SECTIONS_TYPES.PRESET,
  hide: false,
  title: 'add_section_label_text_effects',
  automationId: 'add-panel-section-TextMaskSection',
  showSectionHeader: true,
  additionalBehaviours: {
    labelMode: addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
    infoIcon: addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
    hoverImageAction: addPanelDataConsts.HOVER_IMAGE_ACTIONS.VIDEO,
    iconEnabledForComps: {},
  },
  props: {
    image: 'addPanelData/sections/TextEffects/textMask.v4.png',
    imageHover: null,
    items: [
      {
        id: 'TextMask_TextMask_1',
        structure: {
          type: 'Component',
          metaData: {
            sig: '108-761',
            pageId: 'c1dmp',
          },
          skin: 'wixui.skins.Skinless',
          layout: {
            width: 600,
            height: 262,
            x: 0,
            y: 0,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          componentType: 'wixui.TextMask',
          parent: 'comp-lbnrwgt2',
          data: {
            isDecorative: false,
            direction: 'ltr',
            text: 'Explore',
            headingLevel: 1,
            type: 'TextFxData',
            metaData: {
              isHidden: false,
              sig: '108-745',
              schemaVersion: '1.0',
              isPreset: false,
              pageId: 'c1dmp',
            },
          },
          props: {
            type: 'DefaultProperties',
            metaData: {
              schemaVersion: '1.0',
              sig: '108-746',
              pageId: 'c1dmp',
            },
            isHidden: false,
            isCollapsed: false,
          },
          design: {
            weight: 'normal',
            shadow: {},
            outlineOpacity: 1,
            size: 188,
            style: 'normal',
            viewBox: '0 0 644 283',
            align: 'middle',
            mediaFlip: 'none',
            letterSpacing: {
              value: 0,
              type: 'em',
            },
            lineHeight: 1.2,
            media: {
              "type": "BackgroundMedia",
              "metaData": {
                "isPreset": false,
                "schemaVersion": "1.0",
                "isHidden": false,
                "sig": "5zo-72",
                "pageId": "c1dmp"
              },
              "mediaRef": {
                "type": "WixVideo",
                "metaData": {
                  "isPreset": false,
                  "schemaVersion": "1.0",
                  "isHidden": false,
                  "sig": "5zo-73",
                  "pageId": "c1dmp"
                },
                "title": "Blue Smoke",
                "videoId": "11062b_407e13c69b8041af88725714590d991a",
                "qualities": [
                  {
                    "quality": "1080p",
                    "width": 1920,
                    "height": 1080,
                    "url": "video/11062b_407e13c69b8041af88725714590d991a/1080p/mp4/file.mp4"
                  },
                  {
                    "quality": "720p",
                    "width": 1280,
                    "height": 720,
                    "url": "video/11062b_407e13c69b8041af88725714590d991a/720p/mp4/file.mp4"
                  },
                  {
                    "quality": "480p",
                    "width": 854,
                    "height": 480,
                    "url": "video/11062b_407e13c69b8041af88725714590d991a/480p/mp4/file.mp4"
                  }
                ],
                "posterImageRef": {
                  "type": "Image",
                  "metaData": {
                    "isPreset": false,
                    "schemaVersion": "1.0",
                    "isHidden": false,
                    "sig": "5zo-74",
                    "pageId": "c1dmp"
                  },
                  "title": "",
                  "uri": "11062b_407e13c69b8041af88725714590d991af000.jpg",
                  "description": "public/8e256233-1752-4026-9341-54036e7f875e/3892dca7-115f-4b62-ba8d-11a7b220ab76/all/any/all",
                  "width": 1920,
                  "height": 1080,
                  "alt": ""
                },
                "opacity": 1,
                "duration": 5.58,
                "loop": true,
                "autoplay": true,
                "preload": "auto",
                "mute": true,
                "artist": {
                  "id": "276326045",
                  "name": "Adobestock"
                },
                "generatedPosters": [
                  "11062b_407e13c69b8041af88725714590d991af000.jpg",
                  "11062b_407e13c69b8041af88725714590d991af001.jpg",
                  "11062b_407e13c69b8041af88725714590d991af002.jpg",
                  "11062b_407e13c69b8041af88725714590d991af003.jpg"
                ],
                "hasAudio": false,
                "fps": "24/1",
                "adaptiveVideo": [
                  {
                    "format": "hls",
                    "url": "site/media/video/11062b_407e13c69b8041af88725714590d991a/da01a1fc-ad34-44bc-90e8-cc9413c466e6/repackage/hls"
                  }
                ]
              },
              color: '#FAFAFA',
              colorOpacity: 0,
              alignType: 'center',
              fittingType: 'fill',
              scrollType: 'none',
              colorOverlay: '',
              colorOverlayOpacity: 0,
              showOverlayForMediaType: 'all',
              colorLayers: [
                {
                  fill: {
                    type: 'SolidColor',
                    color: '#FFFFFF',
                  },
                  opacity: 0,
                  type: 'SolidColorLayer',
                  metaData: {
                    isHidden: false,
                    sig: '108-749',
                    schemaVersion: '1.0',
                    isPreset: false,
                    pageId: 'c1dmp',
                  },
                },
              ],
            },
            type: 'TextFxMask',
            outlineWidth: 0,
            metaData: {
              isHidden: false,
              sig: '108-1976',
              schemaVersion: '1.0',
              isPreset: false,
              pageId: 'c1dmp',
            },
            family: 'oswald-medium',
            transform: 'uppercase',
            outlineColor: 'color_15',
            decoration: 'none',
          },
          scopedStyles: {},
          style: {
            type: 'ComponentStyle',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
              sig: '108-754',
              pageId: 'c1dmp',
            },
            style: {
              properties: {},
              propertiesSource: {},
              groups: {},
            },
            componentClassName: 'wixui.TextMask',
            pageId: '',
            compId: '',
            styleType: 'custom',
            skin: 'wixui.skins.Skinless',
          },
        },
        preset: {
          rect: {
            width: 324,
            height: 114,
            x: 0,
            y: 0,
          },
          tags: null,
        },
      },
      {
        id: 'TextMask_TextMask_2',
        structure: {
          type: 'Component',
          metaData: {
            sig: '108-760',
            pageId: 'c1dmp',
          },
          skin: 'wixui.skins.Skinless',
          layout: {
            width: 600,
            height: 280,
            x: 0,
            y: 0,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          componentType: 'wixui.TextMask',
          parent: 'comp-lbnrwgt2',
          data: {
            direction: 'ltr',
            text: 'Interactive \nExperience\n',
            headingLevel: 2,
            type: 'TextFxData',
            metaData: {
              isHidden: false,
              sig: '108-734',
              schemaVersion: '1.0',
              isPreset: false,
              pageId: 'c1dmp',
            },
          },
          props: {
            type: 'DefaultProperties',
            metaData: {
              schemaVersion: '1.0',
              sig: '108-735',
              pageId: 'c1dmp',
            },
            isHidden: false,
            isCollapsed: false,
          },
          design: {
            weight: 'normal',
            shadow: {},
            outlineOpacity: 1,
            size: 127,
            style: 'normal',
            viewBox: '0 0 644 298',
            align: 'start',
            mediaFlip: 'none',
            letterSpacing: {
              value: 0,
              type: 'em',
            },
            lineHeight: 1,
            media: {
              type: 'BackgroundMedia',
              metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false,
                sig: '108-737',
                pageId: 'c1dmp',
              },
              color: '#FAFAFA',
              colorOpacity: 0,
              alignType: 'center',
              fittingType: 'fill',
              scrollType: 'none',
              colorOverlay: '',
              colorOverlayOpacity: 0,
              showOverlayForMediaType: 'all',
              colorLayers: [
                {
                  fill: {
                    type: 'GradientLinear',
                    colorStops: [
                      {
                        size: {
                          value: 13.512659072875977,
                          type: 'percentage',
                        },
                        color: '#5E59D0',
                        opacity: 1,
                      },
                      {
                        size: {
                          value: 73.22959899902344,
                          type: 'percentage',
                        },
                        color: '#CB8971',
                        opacity: 1,
                      },
                    ],
                    angle: 167,
                  },
                  opacity: 1,
                  type: 'GradientLinearLayer',
                  metaData: {
                    isHidden: false,
                    sig: '108-738',
                    schemaVersion: '1.0',
                    isPreset: false,
                    pageId: 'c1dmp',
                  },
                },
              ],
            },
            type: 'TextFxMask',
            outlineWidth: 0,
            metaData: {
              isHidden: false,
              sig: '108-736',
              schemaVersion: '1.0',
              isPreset: false,
              pageId: 'c1dmp',
            },
            family: 'playfair display',
            transform: 'none',
            outlineColor: 'color_15',
            decoration: 'none',
          },
          scopedStyles: {},
          style: {
            type: 'ComponentStyle',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
              sig: '108-741',
              pageId: 'c1dmp',
            },
            style: {
              properties: {},
              propertiesSource: {},
              groups: {},
            },
            componentClassName: 'wixui.TextMask',
            pageId: '',
            compId: '',
            styleType: 'custom',
            skin: 'wixui.skins.Skinless',
          },
        },
        preset: {
          rect: {
            width: 324,
            height: 122,
            x: 0,
            y: 114,
          },
        },
      },
      {
        id: 'TextMask_TextMask_3',
        structure: {
          type: 'Component',
          metaData: {
            sig: '108-759',
            pageId: 'c1dmp',
          },
          skin: 'wixui.skins.Skinless',
          layout: {
            width: 600,
            height: 323,
            x: 0,
            y: 0,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          componentType: 'wixui.TextMask',
          parent: 'comp-lbnrwgt2',
          data: {
            direction: 'ltr',
            text: 'Hello',
            headingLevel: 1,
            type: 'TextFxData',
            metaData: {
              isHidden: false,
              sig: '108-721',
              schemaVersion: '1.0',
              isPreset: false,
              pageId: 'c1dmp',
            },
          },
          props: {
            type: 'DefaultProperties',
            metaData: {
              schemaVersion: '1.0',
              sig: '108-722',
              pageId: 'c1dmp',
            },
            isHidden: false,
            isCollapsed: false,
          },
          design: {
            weight: 'normal',
            shadow: {},
            outlineOpacity: 1,
            size: 284,
            style: 'normal',
            viewBox: '0 0 644 343',
            align: 'middle',
            mediaFlip: 'none',
            letterSpacing: {
              value: 0,
              type: 'em',
            },
            lineHeight: 1.2,
            media: {
              type: 'BackgroundMedia',
              metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false,
                sig: '108-724',
                pageId: 'c1dmp',
              },
              mediaRef: {
                type: 'WixVideo',
                metaData: {
                  isPreset: false,
                  schemaVersion: '1.0',
                  isHidden: false,
                  sig: '108-726',
                  pageId: 'c1dmp',
                },
                title: 'Fabric',
                videoId: '11062b_62f0356712544a87b392417662c4658d',
                qualities: [
                  {
                    quality: '480p',
                    width: 854,
                    height: 480,
                    url: 'video/11062b_62f0356712544a87b392417662c4658d/480p/mp4/file.mp4',
                  },
                  {
                    quality: '720p',
                    width: 1280,
                    height: 720,
                    url: 'video/11062b_62f0356712544a87b392417662c4658d/720p/mp4/file.mp4',
                  },
                  {
                    quality: '1080p',
                    width: 1920,
                    height: 1080,
                    url: 'video/11062b_62f0356712544a87b392417662c4658d/1080p/mp4/file.mp4',
                  },
                ],
                posterImageRef: {
                  type: 'Image',
                  metaData: {
                    isPreset: false,
                    schemaVersion: '1.0',
                    isHidden: false,
                    sig: '108-727',
                    pageId: 'c1dmp',
                  },
                  title: '',
                  uri: '11062b_62f0356712544a87b392417662c4658df000.jpg',
                  description:
                    'public/8e256233-1752-4026-9341-54036e7f875e/3892dca7-115f-4b62-ba8d-11a7b220ab76/all/any/all',
                  width: 1920,
                  height: 1080,
                  alt: '',
                },
                opacity: 1,
                duration: 21.13,
                loop: true,
                autoplay: true,
                preload: 'auto',
                mute: true,
                artist: {
                  id: '',
                  name: '',
                },
                generatedPosters: [
                  '11062b_62f0356712544a87b392417662c4658df000.jpg',
                  '11062b_62f0356712544a87b392417662c4658df001.jpg',
                  '11062b_62f0356712544a87b392417662c4658df002.jpg',
                  '11062b_62f0356712544a87b392417662c4658df003.jpg',
                ],
                hasAudio: false,
                fps: '24/1',
                adaptiveVideo: [
                  {
                    format: 'hls',
                    url: 'site/media/video/11062b_62f0356712544a87b392417662c4658d/da01a1fc-ad34-44bc-90e8-cc9413c466e6/repackage/hls',
                  },
                ],
                mediaFeatures: [],
              },
              color: '#FAFAFA',
              colorOpacity: 0,
              alignType: 'center',
              fittingType: 'fill',
              scrollType: 'none',
              colorOverlay: '#BF7951',
              colorOverlayOpacity: 1,
              showOverlayForMediaType: 'all',
              overlayBlending: {
                blendMode: 'multiply',
              },
              colorLayers: [
                {
                  fill: {
                    type: 'SolidColor',
                    color: '#FFFFFF',
                  },
                  opacity: 0,
                  type: 'SolidColorLayer',
                  metaData: {
                    isHidden: false,
                    sig: '108-725',
                    schemaVersion: '1.0',
                    isPreset: false,
                    pageId: 'c1dmp',
                  },
                },
              ],
            },
            type: 'TextFxMask',
            outlineWidth: 0,
            metaData: {
              isHidden: false,
              sig: '108-1977',
              schemaVersion: '1.0',
              isPreset: false,
              pageId: 'c1dmp',
            },
            family: 'impact',
            transform: 'uppercase',
            outlineColor: 'color_15',
            decoration: 'none',
          },
          scopedStyles: {},
          style: {
            type: 'ComponentStyle',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
              sig: '108-730',
              pageId: 'c1dmp',
            },
            style: {
              properties: {},
              propertiesSource: {},
              groups: {},
            },
            componentClassName: 'wixui.TextMask',
            pageId: '',
            compId: '',
            styleType: 'custom',
            skin: 'wixui.skins.Skinless',
          },
        },
        preset: {
          rect: {
            width: 324,
            height: 150,
            x: 0,
            y: 235,
          },
        },
      },
      {
        id: 'TextMask_TextMask_4',
        structure: {
          type: 'Component',
          metaData: {
            sig: '108-758',
            pageId: 'c1dmp',
          },
          skin: 'wixui.skins.Skinless',
          layout: {
            width: 500,
            height: 90,
            x: 0,
            y: 0,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          componentType: 'wixui.TextMask',
          data: {
            direction: 'ltr',
            text: 'Unleash Your Best',
            headingLevel: 2,
            type: 'TextFxData',
            metaData: {
              isHidden: false,
              sig: '108-710',
              schemaVersion: '1.0',
              isPreset: false,
              pageId: 'c1dmp',
            },
          },
          props: {
            type: 'DefaultProperties',
            metaData: {
              schemaVersion: '1.0',
              sig: '108-711',
              pageId: 'c1dmp',
            },
            isHidden: false,
            isCollapsed: false,
          },
          design: {
            weight: 'bold',
            shadow: {},
            outlineOpacity: 1,
            size: 76,
            style: 'normal',
            viewBox: '0 0 536 92',
            align: 'middle',
            mediaFlip: 'none',
            letterSpacing: {
              value: 0,
              type: 'em',
            },
            lineHeight: 1.2,
            media: {
              type: 'BackgroundMedia',
              metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false,
                sig: '108-1052',
                pageId: 'c1dmp',
              },
              color: '#FAFAFA',
              colorOpacity: 0,
              alignType: 'center',
              fittingType: 'fill',
              scrollType: 'none',
              colorOverlay: '',
              colorOverlayOpacity: 0,
              showOverlayForMediaType: 'all',
              colorLayers: [
                {
                  fill: {
                    type: 'GradientLinear',
                    colorStops: [
                      {
                        size: {
                          value: 3.973388671875,
                          type: 'percentage',
                        },
                        color: '#B88352',
                        opacity: 1,
                      },
                      {
                        size: {
                          value: 52.660369873046875,
                          type: 'percentage',
                        },
                        color: '#B88352',
                        opacity: 1,
                      },
                      {
                        size: {
                          value: 100,
                          type: 'percentage',
                        },
                        color: '#FFBC6D',
                        opacity: 1,
                      },
                    ],
                    angle: 135,
                  },
                  opacity: 1,
                  type: 'GradientLinearLayer',
                  metaData: {
                    isHidden: false,
                    sig: '108-1053',
                    schemaVersion: '1.0',
                    isPreset: false,
                    pageId: 'c1dmp',
                  },
                },
              ],
            },
            type: 'TextFxMask',
            outlineWidth: 0,
            metaData: {
              isHidden: false,
              sig: '108-1978',
              schemaVersion: '1.0',
              isPreset: false,
              pageId: 'c1dmp',
            },
            family: 'fraunces_120pt-light',
            transform: 'none',
            outlineColor: 'color_15',
            decoration: 'none',
          },
          scopedStyles: {},
          style: {
            type: 'ComponentStyle',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
              sig: '108-717',
              pageId: 'c1dmp',
            },
            style: {
              properties: {},
              propertiesSource: {},
              groups: {},
            },
            componentClassName: 'wixui.TextMask',
            pageId: '',
            compId: '',
            styleType: 'custom',
            skin: 'wixui.skins.Skinless',
          },
        },
        preset: {
          rect: {
            width: 324,
            height: 58,
            x: 0,
            y: 385,
          },
        },
      },
      {
        id: 'TextMask_TextMask_5',
        structure: {
          type: 'Component',
          metaData: {
            sig: '108-836',
            pageId: 'c1dmp',
          },
          skin: 'wixui.skins.Skinless',
          layout: {
            width: 230,
            height: 243,
            x: 0,
            y: 0,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          componentType: 'wixui.TextMask',
          parent: 'comp-lbnrwgt2',
          data: {
            direction: 'ltr',
            text: '03',
            type: 'TextFxData',
            metaData: {
              isHidden: false,
              sig: '108-838',
              schemaVersion: '1.0',
              isPreset: false,
              pageId: 'c1dmp',
            },
          },
          props: {
            type: 'DefaultProperties',
            metaData: {
              schemaVersion: '1.0',
              sig: '108-827',
              pageId: 'c1dmp',
            },
            isHidden: false,
            isCollapsed: false,
          },
          design: {
            weight: 'bold',
            shadow: {},
            outlineOpacity: 1,
            size: 210,
            style: 'normal',
            viewBox: '0 0 247 254',
            align: 'middle',
            mediaFlip: 'none',
            letterSpacing: {
              value: 0,
              type: 'em',
            },
            lineHeight: 1.2,
            media: {
              type: 'BackgroundMedia',
              metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false,
                sig: '108-1080',
                pageId: 'c1dmp',
              },
              mediaRef: {
                type: 'Image',
                metaData: {
                  isPreset: false,
                  schemaVersion: '1.0',
                  isHidden: false,
                  sig: '108-1081',
                  pageId: 'c1dmp',
                },
                title: 'Purple Orchid',
                uri: '11062b_dae510dbbfb2461085b266558f4795f5~mv2.jpg',
                description:
                  'search/public/Purple Orchid/6ae967c404004038b5e84dd0a6ab2b65/73d89c137a524437880b113939ca24c3',
                width: 3333,
                height: 5000,
                alt: '',
                artist: {
                  id: '',
                  name: '',
                },
              },
              color: '#FAFAFA',
              colorOpacity: 0,
              alignType: 'center',
              fittingType: 'fill',
              scrollType: 'none',
              colorOverlay: '',
              colorOverlayOpacity: 0,
              showOverlayForMediaType: 'all',
              colorLayers: [
                {
                  fill: {
                    type: 'SolidColor',
                    color: '#FFFFFF',
                  },
                  opacity: 0,
                  type: 'SolidColorLayer',
                  metaData: {
                    isHidden: false,
                    sig: '108-830',
                    schemaVersion: '1.0',
                    isPreset: false,
                    pageId: 'c1dmp',
                  },
                },
              ],
            },
            type: 'TextFxMask',
            outlineWidth: 0,
            metaData: {
              isHidden: false,
              sig: '108-1089',
              schemaVersion: '1.0',
              isPreset: false,
              pageId: 'c1dmp',
            },
            family: 'fraunces_120pt-semibold',
            transform: 'none',
            outlineColor: 'color_15',
            decoration: 'none',
          },
          scopedStyles: {},
          style: {
            type: 'ComponentStyle',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
              sig: '108-834',
              pageId: 'c1dmp',
            },
            style: {
              properties: {},
              propertiesSource: {},
              groups: {},
            },
            componentClassName: 'wixui.TextMask',
            pageId: '',
            compId: '',
            styleType: 'custom',
            skin: 'wixui.skins.Skinless',
          },
        },
        preset: {
          rect: {
            width: 141,
            height: 107,
            x: 0,
            y: 443,
          },
        },
      },
      {
        id: 'VideoBox_VideoBox_6',
        structure: {
          type: 'Component',
          metaData: {
            sig: '108-895',
            pageId: 'c1dmp',
          },
          skin: 'wixui.skins.Skinless',
          layout: {
            width: 300,
            height: 174,
            x: 0,
            y: 0,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          componentType: 'wixui.TextMask',
          parent: 'comp-lbnrwgt2',
          data: {
            direction: 'ltr',
            text: 'The worst \ndays make the \nbest stories',
            headingLevel: 0,
            type: 'TextFxData',
            metaData: {
              isHidden: false,
              sig: '108-904',
              schemaVersion: '1.0',
              isPreset: false,
              pageId: 'c1dmp',
            },
          },
          props: {
            type: 'DefaultProperties',
            metaData: {
              schemaVersion: '1.0',
              sig: '108-886',
              pageId: 'c1dmp',
            },
            isHidden: false,
            isCollapsed: false,
          },
          design: {
            weight: 'bold',
            shadow: {},
            outlineOpacity: 1,
            size: 44,
            style: 'normal',
            viewBox: '0 0 322 183',
            align: 'start',
            mediaFlip: 'none',
            letterSpacing: {
              value: 0,
              type: 'em',
            },
            lineHeight: 1.4,
            media: {
              type: 'BackgroundMedia',
              metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false,
                sig: '108-1128',
                pageId: 'c1dmp',
              },
              color: '#FAFAFA',
              colorOpacity: 0,
              alignType: 'center',
              fittingType: 'fill',
              scrollType: 'none',
              colorOverlay: '',
              colorOverlayOpacity: 0,
              showOverlayForMediaType: 'all',
              colorLayers: [
                {
                  fill: {
                    type: 'GradientLinear',
                    colorStops: [
                      {
                        size: {
                          value: 0,
                          type: 'percentage',
                        },
                        color: '#EFBEAC',
                        opacity: 1,
                      },
                      {
                        size: {
                          value: 100,
                          type: 'percentage',
                        },
                        color: '#DE6848',
                        opacity: 1,
                      },
                    ],
                    angle: 180,
                  },
                  opacity: 1,
                  type: 'GradientLinearLayer',
                  metaData: {
                    isHidden: false,
                    sig: '108-1129',
                    schemaVersion: '1.0',
                    isPreset: false,
                    pageId: 'c1dmp',
                  },
                },
              ],
            },
            type: 'TextFxMask',
            outlineWidth: 0,
            metaData: {
              isHidden: false,
              sig: '108-1127',
              schemaVersion: '1.0',
              isPreset: false,
              pageId: 'c1dmp',
            },
            family: 'poppins-extralight',
            transform: 'uppercase',
            outlineColor: 'color_15',
            decoration: 'none',
          },
          scopedStyles: {},
          style: {
            type: 'ComponentStyle',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
              sig: '108-893',
              pageId: 'c1dmp',
            },
            style: {
              properties: {},
              propertiesSource: {},
              groups: {},
            },
            componentClassName: 'wixui.TextMask',
            pageId: '',
            compId: '',
            styleType: 'custom',
            skin: 'wixui.skins.Skinless',
          },
        },
        preset: {
          rect: {
            width: 184,
            height: 107,
            x: 140,
            y: 443,
          },
        },
      },
      {
        id: 'TextMask_TextMask_7',
        structure: {
          type: 'Component',
          metaData: {
            sig: '108-1117',
            pageId: 'c1dmp',
          },
          skin: 'wixui.skins.Skinless',
          layout: {
            width: 600,
            height: 512,
            x: 0,
            y: 0,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          componentType: 'wixui.TextMask',
          parent: 'comp-lbnrwgt2',
          data: {
            direction: 'ltr',
            text: 'Untold \nStories',
            headingLevel: 1,
            type: 'TextFxData',
            metaData: {
              isHidden: false,
              sig: '108-1120',
              schemaVersion: '1.0',
              isPreset: false,
              pageId: 'c1dmp',
            },
          },
          props: {
            type: 'DefaultProperties',
            metaData: {
              schemaVersion: '1.0',
              sig: '108-1108',
              pageId: 'c1dmp',
            },
            isHidden: false,
            isCollapsed: false,
          },
          design: {
            weight: 'normal',
            shadow: {},
            outlineOpacity: 1,
            size: 221,
            style: 'normal',
            viewBox: '0 0 644 542',
            align: 'start',
            mediaFlip: 'none',
            letterSpacing: {
              value: 0,
              type: 'em',
            },
            lineHeight: 1,
            media: {
              type: 'BackgroundMedia',
              metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false,
                sig: '108-1131',
                pageId: 'c1dmp',
              },
              mediaRef: {
                type: 'WixVideo',
                metaData: {
                  isPreset: false,
                  schemaVersion: '1.0',
                  isHidden: false,
                  sig: '108-1132',
                  pageId: 'c1dmp',
                },
                title: 'Forest',
                videoId: '11062b_d578b9d4ffba48c68d086ec29fe9e6f0',
                qualities: [
                  {
                    quality: '480p',
                    width: 854,
                    height: 480,
                    url: 'video/11062b_d578b9d4ffba48c68d086ec29fe9e6f0/480p/mp4/file.mp4',
                  },
                  {
                    quality: '720p',
                    width: 1280,
                    height: 720,
                    url: 'video/11062b_d578b9d4ffba48c68d086ec29fe9e6f0/720p/mp4/file.mp4',
                  },
                  {
                    quality: '1080p',
                    width: 1920,
                    height: 1080,
                    url: 'video/11062b_d578b9d4ffba48c68d086ec29fe9e6f0/1080p/mp4/file.mp4',
                  },
                ],
                posterImageRef: {
                  type: 'Image',
                  metaData: {
                    isPreset: false,
                    schemaVersion: '1.0',
                    isHidden: false,
                    sig: '108-1133',
                    pageId: 'c1dmp',
                  },
                  title: '',
                  uri: '11062b_d578b9d4ffba48c68d086ec29fe9e6f0f000.jpg',
                  description:
                    'public/8e256233-1752-4026-9341-54036e7f875e/d6b6b972-952c-4ab0-b46a-a6c05a0a94a5/all/any/all',
                  width: 1920,
                  height: 1080,
                  alt: '',
                },
                opacity: 1,
                duration: 40.8,
                loop: true,
                autoplay: true,
                preload: 'auto',
                mute: true,
                artist: {
                  id: '117972864',
                  name: 'gettyimages',
                },
                generatedPosters: [
                  '11062b_d578b9d4ffba48c68d086ec29fe9e6f0f000.jpg',
                  '11062b_d578b9d4ffba48c68d086ec29fe9e6f0f001.jpg',
                  '11062b_d578b9d4ffba48c68d086ec29fe9e6f0f002.jpg',
                  '11062b_d578b9d4ffba48c68d086ec29fe9e6f0f003.jpg',
                ],
                hasAudio: false,
                fps: '25/1',
                adaptiveVideo: [
                  {
                    format: 'hls',
                    url: 'site/media/video/11062b_d578b9d4ffba48c68d086ec29fe9e6f0/da01a1fc-ad34-44bc-90e8-cc9413c466e6/repackage/hls',
                  },
                ],
                mediaFeatures: [],
              },
              color: '#FAFAFA',
              colorOpacity: 0,
              alignType: 'center',
              fittingType: 'fill',
              scrollType: 'none',
              colorOverlay: '',
              colorOverlayOpacity: 0,
              showOverlayForMediaType: 'all',
              colorLayers: [
                {
                  fill: {
                    type: 'SolidColor',
                    color: '#FFFFFF',
                  },
                  opacity: 0,
                  type: 'SolidColorLayer',
                  metaData: {
                    isHidden: false,
                    sig: '108-1111',
                    schemaVersion: '1.0',
                    isPreset: false,
                    pageId: 'c1dmp',
                  },
                },
              ],
            },
            type: 'TextFxMask',
            outlineWidth: 0,
            metaData: {
              isHidden: false,
              sig: '108-1134',
              schemaVersion: '1.0',
              isPreset: false,
              pageId: 'c1dmp',
            },
            family: 'anton',
            transform: 'uppercase',
            outlineColor: 'color_15',
            decoration: 'none',
          },
          scopedStyles: {},
          style: {
            type: 'ComponentStyle',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
              sig: '108-1115',
              pageId: 'c1dmp',
            },
            style: {
              properties: {},
              propertiesSource: {},
              groups: {},
            },
            componentClassName: 'wixui.TextMask',
            pageId: '',
            compId: '',
            styleType: 'custom',
            skin: 'wixui.skins.Skinless',
          },
        },
        preset: {
          rect: {
            width: 324,
            height: 212,
            x: 0,
            y: 551,
          },
        },
      },
      {
        id: 'TextMask_TextMask_8',
        structure: {
          type: 'Component',
          metaData: {
            sig: '108-1208',
            pageId: 'c1dmp',
          },
          skin: 'wixui.skins.Skinless',
          layout: {
            width: 600,
            height: 80,
            x: 0,
            y: 0,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          componentType: 'wixui.TextMask',
          parent: 'comp-lbnrwgt2',
          data: {
            direction: 'ltr',
            text: 'Be The Exception',
            headingLevel: 2,
            type: 'TextFxData',
            metaData: {
              isHidden: false,
              sig: '108-1211',
              schemaVersion: '1.0',
              isPreset: false,
              pageId: 'c1dmp',
            },
          },
          props: {
            type: 'DefaultProperties',
            metaData: {
              schemaVersion: '1.0',
              sig: '108-1199',
              pageId: 'c1dmp',
            },
            isHidden: false,
            isCollapsed: false,
          },
          design: {
            weight: 'normal',
            shadow: {},
            outlineOpacity: 1,
            size: 79,
            style: 'normal',
            viewBox: '0 0 644 95',
            align: 'middle',
            mediaFlip: 'none',
            letterSpacing: {
              value: 0,
              type: 'em',
            },
            lineHeight: 1.2,
            media: {
              type: 'BackgroundMedia',
              metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false,
                sig: '108-1782',
                pageId: 'c1dmp',
              },
              color: '#FAFAFA',
              colorOpacity: 0,
              alignType: 'center',
              fittingType: 'fill',
              scrollType: 'none',
              colorOverlay: '',
              colorOverlayOpacity: 0,
              showOverlayForMediaType: 'all',
              colorLayers: [
                {
                  fill: {
                    type: 'GradientLinear',
                    colorStops: [
                      {
                        size: {
                          value: 0,
                          type: 'percentage',
                        },
                        color: '#004625',
                        opacity: 1,
                      },
                      {
                        size: {
                          value: 72.64137268066406,
                          type: 'percentage',
                        },
                        color: '#706F6F',
                        opacity: 1,
                      },
                      {
                        size: {
                          value: 100,
                          type: 'percentage',
                        },
                        color: '#8E8E8E',
                        opacity: 1,
                      },
                    ],
                    angle: 80,
                  },
                  opacity: 1,
                  type: 'GradientLinearLayer',
                  metaData: {
                    isHidden: false,
                    sig: '108-1783',
                    schemaVersion: '1.0',
                    isPreset: false,
                    pageId: 'c1dmp',
                  },
                },
              ],
            },
            type: 'TextFxMask',
            outlineWidth: 0,
            metaData: {
              isHidden: false,
              sig: '108-1979',
              schemaVersion: '1.0',
              isPreset: false,
              pageId: 'c1dmp',
            },
            family: 'avenir-lt-w01_85-heavy1475544',
            transform: 'none',
            outlineColor: 'color_15',
            decoration: 'none',
          },
          scopedStyles: {},
          style: {
            type: 'ComponentStyle',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
              sig: '108-1206',
              pageId: 'c1dmp',
            },
            style: {
              properties: {},
              propertiesSource: {},
              groups: {},
            },
            componentClassName: 'wixui.TextMask',
            pageId: '',
            compId: '',
            styleType: 'custom',
            skin: 'wixui.skins.Skinless',
          },
        },
        preset: {
          rect: {
            width: 324,
            height: 56,
            x: 0,
            y: 764,
          },
        },
      },
      {
        id: 'TextMask_TextMask_9',
        structure: {
          type: 'Component',
          metaData: {
            sig: '108-1524',
            pageId: 'c1dmp',
          },
          skin: 'wixui.skins.Skinless',
          layout: {
            width: 330,
            height: 283,
            x: 0,
            y: 0,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          componentType: 'wixui.TextMask',
          parent: 'comp-lbnrwgt2',
          data: {
            direction: 'ltr',
            text: 'Let’s\ntalk',
            headingLevel: 2,
            type: 'TextFxData',
            metaData: {
              isHidden: false,
              sig: '108-1528',
              schemaVersion: '1.0',
              isPreset: false,
              pageId: 'c1dmp',
            },
          },
          props: {
            type: 'DefaultProperties',
            metaData: {
              schemaVersion: '1.0',
              sig: '108-1515',
              pageId: 'c1dmp',
            },
            isHidden: false,
            isCollapsed: false,
          },
          design: {
            weight: 'bold',
            shadow: {},
            outlineOpacity: 1,
            size: 129,
            style: 'normal',
            viewBox: '0 0 354 300',
            align: 'start',
            mediaFlip: 'none',
            letterSpacing: {
              value: 0,
              type: 'em',
            },
            lineHeight: 1,
            media: {
              type: 'BackgroundMedia',
              metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false,
                sig: 'sqv-4',
                pageId: 'c1dmp',
              },
              mediaRef: {
                type: 'WixVideo',
                metaData: {
                  isPreset: false,
                  schemaVersion: '1.0',
                  isHidden: false,
                  sig: 'sqv-5',
                  pageId: 'c1dmp',
                },
                title: 'White Scratches',
                videoId: '11062b_47e99a65fe474b159516ea5aea634f53',
                qualities: [
                  {
                    quality: '1080p',
                    width: 1920,
                    height: 1080,
                    url: 'video/11062b_47e99a65fe474b159516ea5aea634f53/1080p/mp4/file.mp4',
                  },
                  {
                    quality: '720p',
                    width: 1280,
                    height: 720,
                    url: 'video/11062b_47e99a65fe474b159516ea5aea634f53/720p/mp4/file.mp4',
                  },
                  {
                    quality: '480p',
                    width: 854,
                    height: 480,
                    url: 'video/11062b_47e99a65fe474b159516ea5aea634f53/480p/mp4/file.mp4',
                  },
                ],
                posterImageRef: {
                  type: 'Image',
                  metaData: {
                    isPreset: false,
                    schemaVersion: '1.0',
                    isHidden: false,
                    sig: 'sqv-6',
                    pageId: 'c1dmp',
                  },
                  title: '',
                  uri: '11062b_47e99a65fe474b159516ea5aea634f53f000.jpg',
                  description:
                    'public/8e256233-1752-4026-9341-54036e7f875e/5abaf36e-05be-41b2-8a0c-e9999bc48c51/all/any/all',
                  width: 1920,
                  height: 1080,
                  alt: '',
                },
                opacity: 1,
                duration: 4.93,
                loop: true,
                autoplay: true,
                preload: 'auto',
                mute: true,
                artist: {
                  id: '462979592',
                  name: 'AdobeStock',
                },
                generatedPosters: [
                  '11062b_47e99a65fe474b159516ea5aea634f53f000.jpg',
                  '11062b_47e99a65fe474b159516ea5aea634f53f001.jpg',
                  '11062b_47e99a65fe474b159516ea5aea634f53f002.jpg',
                  '11062b_47e99a65fe474b159516ea5aea634f53f003.jpg',
                ],
                hasAudio: true,
                fps: '30/1',
                adaptiveVideo: [
                  {
                    format: 'hls',
                    url: 'site/media/video/11062b_47e99a65fe474b159516ea5aea634f53/da01a1fc-ad34-44bc-90e8-cc9413c466e6/repackage/hls',
                  },
                ],
                mediaFeatures: [],
              },
              color: '#FAFAFA',
              colorOpacity: 0,
              alignType: 'center',
              fittingType: 'fill',
              scrollType: 'none',
              colorOverlay: '#424242',
              colorOverlayOpacity: 1,
              showOverlayForMediaType: 'all',
              overlayBlending: {
                blendMode: 'screen',
              },
              colorLayers: [
                {
                  fill: {
                    type: 'SolidColor',
                    color: '#FFFFFF',
                  },
                  opacity: 0,
                  type: 'SolidColorLayer',
                  metaData: {
                    isHidden: false,
                    sig: '108-1518',
                    schemaVersion: '1.0',
                    isPreset: false,
                    pageId: 'c1dmp',
                  },
                },
              ],
            },
            type: 'TextFxMask',
            outlineWidth: 0,
            metaData: {
              isHidden: false,
              sig: 'sqv-3',
              schemaVersion: '1.0',
              isPreset: false,
              pageId: 'c1dmp',
            },
            family: 'playfair display',
            transform: 'uppercase',
            outlineColor: 'color_15',
            decoration: 'none',
          },
          scopedStyles: {},
          style: {
            type: 'ComponentStyle',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
              sig: '108-1522',
              pageId: 'c1dmp',
            },
            style: {
              properties: {},
              propertiesSource: {},
              groups: {},
            },
            componentClassName: 'wixui.TextMask',
            pageId: '',
            compId: '',
            styleType: 'custom',
            skin: 'wixui.skins.Skinless',
          },
        },
        preset: {
          rect: {
            width: 198,
            height: 140,
            x: 0,
            y: 820,
          },
        },
      },
      {
        id: 'TextMask_TextMask_10',
        structure: {
          type: 'Component',
          metaData: {
            sig: '108-1436',
            pageId: 'c1dmp',
          },
          skin: 'wixui.skins.Skinless',
          layout: {
            width: 220,
            height: 355,
            x: 0,
            y: 0,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          componentType: 'wixui.TextMask',
          parent: 'comp-lbnrwgt2',
          data: {
            direction: 'ltr',
            text: '&',
            type: 'TextFxData',
            metaData: {
              isHidden: false,
              sig: '108-1438',
              schemaVersion: '1.0',
              isPreset: false,
              pageId: 'c1dmp',
            },
          },
          props: {
            type: 'DefaultProperties',
            metaData: {
              schemaVersion: '1.0',
              sig: '108-1427',
              pageId: 'c1dmp',
            },
            isHidden: false,
            isCollapsed: false,
          },
          design: {
            weight: 'bold',
            shadow: {},
            outlineOpacity: 1,
            size: 318,
            style: 'normal',
            viewBox: '0 0 236 378',
            align: 'start',
            mediaFlip: 'none',
            letterSpacing: {
              value: 0,
              type: 'em',
            },
            lineHeight: 1.2,
            media: {
              type: 'BackgroundMedia',
              metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false,
                sig: '108-1507',
                pageId: 'c1dmp',
              },
              mediaRef: {
                type: 'Image',
                metaData: {
                  isPreset: false,
                  schemaVersion: '1.0',
                  isHidden: false,
                  sig: '108-1464',
                  pageId: 'c1dmp',
                },
                title: 'Modern Structure',
                uri: 'b20aec57875f4296846e56393a3a39cf.jpg',
                description:
                  'public/6ae967c404004038b5e84dd0a6ab2b65/21f7305f3fc64cdb9939310fef310b64/all/any/all',
                width: 3000,
                height: 2750,
                alt: '',
                artist: {
                  id: '',
                  name: '',
                },
              },
              color: '#FAFAFA',
              colorOpacity: 0,
              alignType: 'center',
              fittingType: 'fill',
              scrollType: 'none',
              colorOverlay: 'color_15',
              colorOverlayOpacity: 1,
              showOverlayForMediaType: 'all',
              overlayBlending: {
                blendMode: 'overlay',
              },
              colorLayers: [
                {
                  fill: {
                    type: 'SolidColor',
                    color: '#FFFFFF',
                  },
                  opacity: 0,
                  type: 'SolidColorLayer',
                  metaData: {
                    isHidden: false,
                    sig: '108-1430',
                    schemaVersion: '1.0',
                    isPreset: false,
                    pageId: 'c1dmp',
                  },
                },
              ],
            },
            type: 'TextFxMask',
            outlineWidth: 0,
            metaData: {
              isHidden: false,
              sig: '108-2068',
              schemaVersion: '1.0',
              isPreset: false,
              pageId: 'c1dmp',
            },
            family: 'avenir-lt-w01_85-heavy1475544',
            transform: 'none',
            outlineColor: 'color_15',
            decoration: 'none',
          },
          scopedStyles: {},
          style: {
            type: 'ComponentStyle',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
              sig: '108-1434',
              pageId: 'c1dmp',
            },
            style: {
              properties: {},
              propertiesSource: {},
              groups: {},
            },
            componentClassName: 'wixui.TextMask',
            pageId: '',
            compId: '',
            styleType: 'custom',
            skin: 'wixui.skins.Skinless',
          },
        },
        preset: {
          rect: {
            width: 127,
            height: 139,
            x: 197,
            y: 820,
          },
        },
      },
      {
        id: 'TextMask_TextMask_11',
        structure: {
          type: 'Component',
          metaData: {
            sig: '108-1794',
            pageId: 'c1dmp',
          },
          skin: 'wixui.skins.Skinless',
          layout: {
            width: 700,
            height: 164,
            x: 0,
            y: 0,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          componentType: 'wixui.TextMask',
          parent: 'comp-lbnrwgt2',
          data: {
            direction: 'ltr',
            text: 'Culture',
            headingLevel: 2,
            type: 'TextFxData',
            metaData: {
              isHidden: false,
              sig: '108-1796',
              schemaVersion: '1.0',
              isPreset: false,
              pageId: 'c1dmp',
            },
          },
          props: {
            type: 'DefaultProperties',
            metaData: {
              schemaVersion: '1.0',
              sig: '108-1785',
              pageId: 'c1dmp',
            },
            isHidden: false,
            isCollapsed: false,
          },
          design: {
            weight: 'bold',
            shadow: {},
            outlineOpacity: 1,
            size: 157,
            style: 'normal',
            viewBox: '0 0 751 170',
            align: 'middle',
            mediaFlip: 'none',
            letterSpacing: {
              value: 0,
              type: 'em',
            },
            lineHeight: 1.2,
            media: {
              type: 'BackgroundMedia',
              metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false,
                sig: '108-1926',
                pageId: 'c1dmp',
              },
              mediaRef: {
                type: 'WixVideo',
                metaData: {
                  isPreset: false,
                  schemaVersion: '1.0',
                  isHidden: false,
                  sig: '108-1861',
                  pageId: 'c1dmp',
                },
                title: 'White Bubbles',
                videoId: '11062b_57eee0be03fd4f26b8819bc60ef5b3c9',
                qualities: [
                  {
                    quality: '480p',
                    width: 854,
                    height: 480,
                    url: 'video/11062b_57eee0be03fd4f26b8819bc60ef5b3c9/480p/mp4/file.mp4',
                  },
                  {
                    quality: '720p',
                    width: 1280,
                    height: 720,
                    url: 'video/11062b_57eee0be03fd4f26b8819bc60ef5b3c9/720p/mp4/file.mp4',
                  },
                  {
                    quality: '1080p',
                    width: 1920,
                    height: 1080,
                    url: 'video/11062b_57eee0be03fd4f26b8819bc60ef5b3c9/1080p/mp4/file.mp4',
                  },
                ],
                posterImageRef: {
                  type: 'Image',
                  metaData: {
                    isPreset: false,
                    schemaVersion: '1.0',
                    isHidden: false,
                    sig: '108-1862',
                    pageId: 'c1dmp',
                  },
                  title: '',
                  uri: '11062b_57eee0be03fd4f26b8819bc60ef5b3c9f000.jpg',
                  description:
                    'public/8e256233-1752-4026-9341-54036e7f875e/3892dca7-115f-4b62-ba8d-11a7b220ab76/all/any/all',
                  width: 1920,
                  height: 1080,
                  alt: '',
                },
                opacity: 1,
                duration: 2.3,
                loop: true,
                autoplay: true,
                preload: 'auto',
                mute: true,
                artist: {
                  id: '',
                  name: '',
                },
                generatedPosters: [
                  '11062b_57eee0be03fd4f26b8819bc60ef5b3c9f000.jpg',
                  '11062b_57eee0be03fd4f26b8819bc60ef5b3c9f001.jpg',
                  '11062b_57eee0be03fd4f26b8819bc60ef5b3c9f002.jpg',
                  '11062b_57eee0be03fd4f26b8819bc60ef5b3c9f003.jpg',
                ],
                hasAudio: false,
                fps: '30',
                adaptiveVideo: [
                  {
                    format: 'hls',
                    url: 'site/media/video/11062b_57eee0be03fd4f26b8819bc60ef5b3c9/da01a1fc-ad34-44bc-90e8-cc9413c466e6/repackage/hls',
                  },
                ],
                mediaFeatures: [],
              },
              color: '#FAFAFA',
              colorOpacity: 0,
              alignType: 'center',
              fittingType: 'fill',
              scrollType: 'none',
              colorOverlay: '#000000',
              colorOverlayOpacity: 0.3,
              showOverlayForMediaType: 'all',
              overlayBlending: {
                blendMode: 'normal',
              },
              colorLayers: [
                {
                  fill: {
                    type: 'SolidColor',
                    color: '#FFFFFF',
                  },
                  opacity: 0,
                  type: 'SolidColorLayer',
                  metaData: {
                    isHidden: false,
                    sig: '108-1788',
                    schemaVersion: '1.0',
                    isPreset: false,
                    pageId: 'c1dmp',
                  },
                },
              ],
            },
            type: 'TextFxMask',
            outlineWidth: 0,
            metaData: {
              isHidden: false,
              sig: '108-1980',
              schemaVersion: '1.0',
              isPreset: false,
              pageId: 'c1dmp',
            },
            family: 'times new roman',
            transform: 'uppercase',
            outlineColor: 'color_15',
            decoration: 'none',
          },
          scopedStyles: {},
          style: {
            type: 'ComponentStyle',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
              sig: '108-1792',
              pageId: 'c1dmp',
            },
            style: {
              properties: {},
              propertiesSource: {},
              groups: {},
            },
            componentClassName: 'wixui.TextMask',
            pageId: '',
            compId: '',
            styleType: 'custom',
            skin: 'wixui.skins.Skinless',
          },
        },
        preset: {
          rect: {
            width: 324,
            height: 82,
            x: 0,
            y: 959,
          },
        },
      },
      {
        id: 'TextMask_TextMask_12',
        structure: {
          type: 'Component',
          metaData: {
            sig: '108-1960',
            pageId: 'c1dmp',
          },
          skin: 'wixui.skins.Skinless',
          layout: {
            width: 732,
            height: 86,
            x: 0,
            y: 0,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          componentType: 'wixui.TextMask',
          parent: 'comp-lbnrwgt2',
          data: {
            direction: 'ltr',
            text: 'crème de la crème',
            headingLevel: 0,
            type: 'TextFxData',
            metaData: {
              isHidden: false,
              sig: '108-1962',
              schemaVersion: '1.0',
              isPreset: false,
              pageId: 'c1dmp',
            },
          },
          props: {
            type: 'DefaultProperties',
            metaData: {
              schemaVersion: '1.0',
              sig: '108-1951',
              pageId: 'c1dmp',
            },
            isHidden: false,
            isCollapsed: false,
          },
          design: {
            weight: 'normal',
            shadow: {},
            outlineOpacity: 1,
            size: 72,
            style: 'normal',
            viewBox: '0 0 785 89',
            align: 'middle',
            mediaFlip: 'none',
            letterSpacing: {
              value: 0,
              type: 'em',
            },
            lineHeight: 1.2,
            media: {
              type: 'BackgroundMedia',
              metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false,
                sig: '108-2064',
                pageId: 'c1dmp',
              },
              color: '#FAFAFA',
              colorOpacity: 0,
              alignType: 'center',
              fittingType: 'fill',
              scrollType: 'none',
              colorOverlay: '',
              colorOverlayOpacity: 0,
              showOverlayForMediaType: 'all',
              colorLayers: [
                {
                  fill: {
                    type: 'GradientLinear',
                    colorStops: [
                      {
                        size: {
                          value: 12.646222114562988,
                          type: 'percentage',
                        },
                        color: '#747474',
                        opacity: 1,
                      },
                      {
                        size: {
                          value: 100,
                          type: 'percentage',
                        },
                        color: '#EBEBEB',
                        opacity: 1,
                      },
                    ],
                    angle: 110,
                  },
                  opacity: 1,
                  type: 'GradientLinearLayer',
                  metaData: {
                    isHidden: false,
                    sig: '108-2055',
                    schemaVersion: '1.0',
                    isPreset: false,
                    pageId: 'c1dmp',
                  },
                },
              ],
            },
            type: 'TextFxMask',
            outlineWidth: 0,
            metaData: {
              isHidden: false,
              sig: '108-2063',
              schemaVersion: '1.0',
              isPreset: false,
              pageId: 'c1dmp',
            },
            family: 'sarina',
            transform: 'none',
            outlineColor: 'color_15',
            decoration: 'none',
          },
          scopedStyles: {},
          style: {
            type: 'ComponentStyle',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
              sig: '108-1958',
              pageId: 'c1dmp',
            },
            style: {
              properties: {},
              propertiesSource: {},
              groups: {},
            },
            componentClassName: 'wixui.TextMask',
            pageId: '',
            compId: '',
            styleType: 'custom',
            skin: 'wixui.skins.Skinless',
          },
        },
        preset: {
          rect: {
            width: 324,
            height: 51,
            x: 0,
            y: 1041,
          },
        },
      },
    ],
    compTypes: ['wixui.TextMask'],
  },
  subNavigationTitle: 'add_section_label_text_effects',
  presetTitle: 'add_section_label_text_effects',
  tooltipTitle: 'add_section_label_text_mask_tooltip_title',
  sectionId: 'text-mask',
  subNavigationHide: false,
  help: {
    hide: false,
    text: 'add_section_label_text_mask_tooltip_description',
  },
};
