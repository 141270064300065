// @ts-nocheck
import _ from 'lodash';

function addApp(editorAPI, token, options) {
  //as we don't have provision yet we must dumy provision wix code to get the viewer worker running
  editorAPI.wixCode.provision(_.noop, _.noop);
  editorAPI.dsActions.platform.initApp({
    appDefinitionId: options?.appDefinitionId,
    editorUrl: options?.editorUrl,
    viewerUrl: options?.viewerUrl,
  });
}

export { addApp };
