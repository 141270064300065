// @ts-nocheck
import constants from '@/constants';
import gfppDataUtils from '../../utils/gfppDataUtils';

const { ACTIONS } = constants.ROOT_COMPS.GFPP;

export default {
  untranslatable: true,
  mainActions: [
    {
      label: 'gfpp_mainaction_googleplus',
      isApplied: true,
      isSelected: gfppDataUtils.getPanelStateFn(ACTIONS.LAYOUT),
      onClick: gfppDataUtils.getTogglePanelFn(ACTIONS.LAYOUT),
    },
  ],
  enabledActions: [ACTIONS.LAYOUT, ACTIONS.ANIMATION, ACTIONS.HELP],
  mobileEnabledActions: [
    ACTIONS.LAYOUT,
    ACTIONS.HIDE,
    ACTIONS.HELP,
    ACTIONS.ANIMATION,
  ],
  presetActions: {
    help: {
      helpId: '440a413f-50fc-4dd8-b49d-f5d9b6c4c99f',
    },
  },
};
