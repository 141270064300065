import React from 'react';
import { Heading } from '@wix/wix-base-ui';
import styles from './appDetailsHeader.scss';
import { AvailableAppActionsButton } from '../availableAppActions/availableAppActionsButton';
import { useSelectedApp } from '../selectedAppContext/selectedAppContext';
import { InstalledAppActionsButton } from '../installedAppActions/installedAppActionsButton';
import { InstalledAppIndicator } from '../InstalledAppIndicator/InstalledAppIndicator';
import { InstallAppButton } from '../installAppButton/installAppButton';
import { GreyLabel } from '../helperComponents/helperComponents';
import {
  InstallStatus,
  usePrivateApps,
  ListView,
} from '../privateAppsProvider/privateAppsProvider';
import { VersionedAppActionsButton } from '../versionedAppActions/versionedAppActionsButton';
import { useAppVersionsListContext } from '../AppVersionsList/AppVersionsListContext';
import * as util from '@/util';
import { translate } from '@/i18n';
import { shouldShowUpdateAction } from '../../utils/updateApp';
import { UpdateAppButton } from '../versionedAppActions/updateAppButton';
import { TEST_VERSION } from '@/privateAppsPanel';

const UPDATE_BTN_TRANSLATION_KEY =
  'blocks-private-apps.AppDiscovery_App_Details_Update_CTA';

const AppDetailsActions = () => {
  const { app, selectedVersion } = useSelectedApp().selectedApp;
  const { selectedVersionListApp } = useAppVersionsListContext();

  switch (app?.installStatus) {
    case InstallStatus.Installed:
      return (
        <>
          {shouldShowUpdateAction(app) ? (
            <UpdateAppButton
              buttonText={translate(UPDATE_BTN_TRANSLATION_KEY)}
            />
          ) : (
            <InstalledAppIndicator />
          )}
          {!selectedVersionListApp.app && <InstalledAppActionsButton />}
        </>
      );
    case InstallStatus.Available:
      return (
        <>
          <InstallAppButton versionToInstall={selectedVersion} />
          <AvailableAppActionsButton />
        </>
      );
    case InstallStatus.Versioned:
      return <VersionedAppActionsButton />;
    default:
      return null;
  }
};

export const AppDetailsHeader: React.FC = () => {
  const { selectedListView } = usePrivateApps();
  const { app, codePackage, selectedVersion } = useSelectedApp().selectedApp;

  const isVersionedView = selectedListView === ListView.AppVersions;

  const buildSelectedAppVersion = () =>
    app
      ? `${util.appStudioUtils.formatVersion(
          selectedVersion || app?.version || app?.latestVersion,
        )} ${
          app.version === app.latestVersion && selectedVersion !== TEST_VERSION
            ? translate(
                'blocks-private-apps.AppDiscovery_App_Details_Latest_Version_Label',
              )
            : ''
        }`
      : '';

  return (
    <div className={styles.appDetailsHeader}>
      <div className={styles.leftContainer}>
        <Heading
          shouldTranslate={false}
          appearance="h1"
          dataHook="app-details-header-name"
        >
          {`${app?.appDefinitionName}${
            isVersionedView && selectedVersion
              ? ` ${util.appStudioUtils.formatVersion(selectedVersion)}`
              : ''
          }`}
        </Heading>
        <GreyLabel>{codePackage?.name}</GreyLabel>
      </div>
      <div className={styles.rightContainer}>
        <div className={styles.versionContainer}>
          <GreyLabel>{!isVersionedView && buildSelectedAppVersion()}</GreyLabel>
        </div>
        <div className={styles.actionsContainer}>
          <AppDetailsActions />
        </div>
      </div>
    </div>
  );
};
