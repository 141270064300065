import type { EditorApiAgentTool } from './types';
import openEditorPanelTool from './tools/openEditorPanel';
import userUISelectedComponent from './tools/userUISelectedComponent';
import pageContextTool from './tools/pageContext';

const toolsRegistry: EditorApiAgentTool<any>[] = [
  userUISelectedComponent,
  openEditorPanelTool,
  pageContextTool,
];

export default toolsRegistry;
