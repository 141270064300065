:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._highlightContainer_j2c97_7 {
  width: 300px;
  height: 300px;
  position: absolute;
  text-align: center;
  border: 2px dashed #35eaff;
  background-color: rgba(53, 234, 255, 0.1); }
  ._highlightContainer_j2c97_7 ._label_j2c97_14 {
    position: relative;
    border: none;
    display: inline;
    top: 8px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.7);
    border-radius: 3px;
    font-weight: bold;
    padding: 5px 7px;
    color: #fff;
    background-color: rgba(53, 234, 255, 0.7); }
  ._highlightContainer_j2c97_7._master_j2c97_25 {
    border-color: #ff9900;
    background-color: rgba(255, 153, 0, 0.1); }
    ._highlightContainer_j2c97_7._master_j2c97_25 ._label_j2c97_14 {
      background-color: rgba(255, 153, 0, 0.7); }
  ._highlightContainer_j2c97_7._newContainer_j2c97_30 {
    z-index: 1;
    border: none;
    text-align: center;
    pointer-events: none; }
    ._highlightContainer_j2c97_7._newContainer_j2c97_30 ._label_j2c97_14 {
      display: inline-block;
      position: relative;
      border-radius: 2px;
      padding: 6px 9px;
      color: #fff;
      margin-top: 12px;
      box-sizing: border-box;
      font-weight: initial;
      border: none;
      box-shadow: none;
      top: initial; }
    ._highlightContainer_j2c97_7._newContainer_j2c97_30._isSection_j2c97_47 {
      background-color: rgba(111, 86, 249, 0.05);
      box-shadow: inset #6f56f9 0 0 0 2px, inset #d9d2ff 0 0 0 4px;
      /**
        ANIMATIONS
       */ }
      ._highlightContainer_j2c97_7._newContainer_j2c97_30._isSection_j2c97_47 ._label_j2c97_14 {
        background-color: #6f56f9; }
      ._highlightContainer_j2c97_7._newContainer_j2c97_30._isSection_j2c97_47 ._newContainerAppear_j2c97_55 {
        box-shadow: none; }
        ._highlightContainer_j2c97_7._newContainer_j2c97_30._isSection_j2c97_47 ._newContainerAppear_j2c97_55 ._label_j2c97_14 {
          opacity: 0; }
      ._highlightContainer_j2c97_7._newContainer_j2c97_30._isSection_j2c97_47 ._newContainerAppearActive_j2c97_59 {
        box-shadow: inset #6f56f9 0 0 0 2px, inset #d9d2ff 0 0 0 4px;
        transition: box-shadow calc(var(--animation-duration) * 1ms); }
        ._highlightContainer_j2c97_7._newContainer_j2c97_30._isSection_j2c97_47 ._newContainerAppearActive_j2c97_59 ._label_j2c97_14 {
          transition: opacity calc(var(--animation-duration) * 1ms);
          opacity: 1; }
      ._highlightContainer_j2c97_7._newContainer_j2c97_30._isSection_j2c97_47 ._newContainerAppearDone_j2c97_65 {
        box-shadow: inset #6f56f9 0 0 0 2px, inset #d9d2ff 0 0 0 4px; }
        ._highlightContainer_j2c97_7._newContainer_j2c97_30._isSection_j2c97_47 ._newContainerAppearDone_j2c97_65 ._label_j2c97_14 {
          opacity: 1; }
      ._highlightContainer_j2c97_7._newContainer_j2c97_30._isSection_j2c97_47 ._newContainerExit_j2c97_69 {
        box-shadow: inset #6f56f9 0 0 0 2px, inset #d9d2ff 0 0 0 4px; }
        ._highlightContainer_j2c97_7._newContainer_j2c97_30._isSection_j2c97_47 ._newContainerExit_j2c97_69 ._label_j2c97_14 {
          opacity: 1; }
      ._highlightContainer_j2c97_7._newContainer_j2c97_30._isSection_j2c97_47 ._newContainerExitActive_j2c97_73 {
        box-shadow: none;
        transition: box-shadow calc(var(--animation-duration) * 1ms); }
        ._highlightContainer_j2c97_7._newContainer_j2c97_30._isSection_j2c97_47 ._newContainerExitActive_j2c97_73 ._label_j2c97_14 {
          transition: opacity calc(var(--animation-duration) * 1ms);
          opacity: 0; }
      ._highlightContainer_j2c97_7._newContainer_j2c97_30._isSection_j2c97_47 ._newContainerExitDone_j2c97_79 {
        box-shadow: none; }
        ._highlightContainer_j2c97_7._newContainer_j2c97_30._isSection_j2c97_47 ._newContainerExitDone_j2c97_79 ._label_j2c97_14 {
          opacity: 0; }
    ._highlightContainer_j2c97_7._newContainer_j2c97_30:not(._isSection_j2c97_47) {
      background-color: rgba(17, 109, 255, 0.05);
      box-shadow: inset #116dff 0 0 0 2px, inset #80b1ff 0 0 0 4px;
      /**
        ANIMATIONS
       */ }
      ._highlightContainer_j2c97_7._newContainer_j2c97_30:not(._isSection_j2c97_47) ._label_j2c97_14 {
        background-color: #116dff; }
      ._highlightContainer_j2c97_7._newContainer_j2c97_30:not(._isSection_j2c97_47) ._newContainerAppear_j2c97_55 {
        box-shadow: none; }
        ._highlightContainer_j2c97_7._newContainer_j2c97_30:not(._isSection_j2c97_47) ._newContainerAppear_j2c97_55 ._label_j2c97_14 {
          opacity: 0; }
      ._highlightContainer_j2c97_7._newContainer_j2c97_30:not(._isSection_j2c97_47) ._newContainerAppearActive_j2c97_59 {
        box-shadow: inset #116dff 0 0 0 2px, inset #80b1ff 0 0 0 4px;
        transition: box-shadow calc(var(--animation-duration) * 1ms); }
        ._highlightContainer_j2c97_7._newContainer_j2c97_30:not(._isSection_j2c97_47) ._newContainerAppearActive_j2c97_59 ._label_j2c97_14 {
          transition: opacity calc(var(--animation-duration) * 1ms);
          opacity: 1; }
      ._highlightContainer_j2c97_7._newContainer_j2c97_30:not(._isSection_j2c97_47) ._newContainerAppearDone_j2c97_65 {
        box-shadow: inset #116dff 0 0 0 2px, inset #80b1ff 0 0 0 4px; }
        ._highlightContainer_j2c97_7._newContainer_j2c97_30:not(._isSection_j2c97_47) ._newContainerAppearDone_j2c97_65 ._label_j2c97_14 {
          opacity: 1; }
      ._highlightContainer_j2c97_7._newContainer_j2c97_30:not(._isSection_j2c97_47) ._newContainerExit_j2c97_69 {
        box-shadow: inset #116dff 0 0 0 2px, inset #80b1ff 0 0 0 4px; }
        ._highlightContainer_j2c97_7._newContainer_j2c97_30:not(._isSection_j2c97_47) ._newContainerExit_j2c97_69 ._label_j2c97_14 {
          opacity: 1; }
      ._highlightContainer_j2c97_7._newContainer_j2c97_30:not(._isSection_j2c97_47) ._newContainerExitActive_j2c97_73 {
        box-shadow: none;
        transition: box-shadow calc(var(--animation-duration) * 1ms); }
        ._highlightContainer_j2c97_7._newContainer_j2c97_30:not(._isSection_j2c97_47) ._newContainerExitActive_j2c97_73 ._label_j2c97_14 {
          transition: opacity calc(var(--animation-duration) * 1ms);
          opacity: 0; }
      ._highlightContainer_j2c97_7._newContainer_j2c97_30:not(._isSection_j2c97_47) ._newContainerExitDone_j2c97_79 {
        box-shadow: none; }
        ._highlightContainer_j2c97_7._newContainer_j2c97_30:not(._isSection_j2c97_47) ._newContainerExitDone_j2c97_79 ._label_j2c97_14 {
          opacity: 0; }
    ._highlightContainer_j2c97_7._newContainer_j2c97_30._master_j2c97_25 {
      background-color: rgba(254, 98, 15, 0.05);
      box-shadow: inset #fe620f 0 0 0 2px, inset #fdd1ba 0 0 0 4px;
      /**
        ANIMATIONS
       */ }
      ._highlightContainer_j2c97_7._newContainer_j2c97_30._master_j2c97_25 ._label_j2c97_14 {
        background-color: #fe620f; }
      ._highlightContainer_j2c97_7._newContainer_j2c97_30._master_j2c97_25 ._newContainerAppear_j2c97_55 {
        box-shadow: none; }
        ._highlightContainer_j2c97_7._newContainer_j2c97_30._master_j2c97_25 ._newContainerAppear_j2c97_55 ._label_j2c97_14 {
          opacity: 0; }
      ._highlightContainer_j2c97_7._newContainer_j2c97_30._master_j2c97_25 ._newContainerAppearActive_j2c97_59 {
        box-shadow: inset #fe620f 0 0 0 2px, inset #fdd1ba 0 0 0 4px;
        transition: box-shadow calc(var(--animation-duration) * 1ms); }
        ._highlightContainer_j2c97_7._newContainer_j2c97_30._master_j2c97_25 ._newContainerAppearActive_j2c97_59 ._label_j2c97_14 {
          transition: opacity calc(var(--animation-duration) * 1ms);
          opacity: 1; }
      ._highlightContainer_j2c97_7._newContainer_j2c97_30._master_j2c97_25 ._newContainerAppearDone_j2c97_65 {
        box-shadow: inset #fe620f 0 0 0 2px, inset #fdd1ba 0 0 0 4px; }
        ._highlightContainer_j2c97_7._newContainer_j2c97_30._master_j2c97_25 ._newContainerAppearDone_j2c97_65 ._label_j2c97_14 {
          opacity: 1; }
      ._highlightContainer_j2c97_7._newContainer_j2c97_30._master_j2c97_25 ._newContainerExit_j2c97_69 {
        box-shadow: inset #fe620f 0 0 0 2px, inset #fdd1ba 0 0 0 4px; }
        ._highlightContainer_j2c97_7._newContainer_j2c97_30._master_j2c97_25 ._newContainerExit_j2c97_69 ._label_j2c97_14 {
          opacity: 1; }
      ._highlightContainer_j2c97_7._newContainer_j2c97_30._master_j2c97_25 ._newContainerExitActive_j2c97_73 {
        box-shadow: none;
        transition: box-shadow calc(var(--animation-duration) * 1ms); }
        ._highlightContainer_j2c97_7._newContainer_j2c97_30._master_j2c97_25 ._newContainerExitActive_j2c97_73 ._label_j2c97_14 {
          transition: opacity calc(var(--animation-duration) * 1ms);
          opacity: 0; }
      ._highlightContainer_j2c97_7._newContainer_j2c97_30._master_j2c97_25 ._newContainerExitDone_j2c97_79 {
        box-shadow: none; }
        ._highlightContainer_j2c97_7._newContainer_j2c97_30._master_j2c97_25 ._newContainerExitDone_j2c97_79 ._label_j2c97_14 {
          opacity: 0; }
