'use strict'

const addPanelDataConsts = require('@/addPanelDataConsts')

const presets = {
    type: addPanelDataConsts.SECTIONS_TYPES.DESIGN_DATA_THEME_PRESET,
    hide: false,
    props: {
        items: [
            {
                id: 'HoverBoxThemePreset_#0',
                structure: {
                    empty: true,
                    componentType: 'wysiwyg.viewer.components.HoverBox',
                    design: {
                        charas: 'HoverBoxThemePreset_#0',
                        themeMappings: {},
                        cssStyle: {
                            cssBorderRadius: null,
                            cssBorder: null,
                            cssBoxShadow: null
                        }
                    }
                }
            },

            {
                id: 'HoverBoxThemePreset_#1',
                structure: {
                    componentType: 'wysiwyg.viewer.components.HoverBox',
                    design: {
                        charas: 'HoverBoxThemePreset_#1',

                        themeMappings: {
                            'cssBorder.color.bottom': 'color_28',
                            'cssBorder.color.left': 'color_28',
                            'cssBorder.color.right': 'color_28',
                            'cssBorder.color.top': 'color_28'
                        },

                        cssStyle: {

                            cssBorderRadius: {
                                'topLeft': {'unit': 'px', 'value': 25},
                                'topRight': {'unit': 'px', 'value': 25},
                                'bottomRight': {'unit': 'px', 'value': 25},
                                'bottomLeft': {'unit': 'px', 'value': 25}
                            },

                            cssBorder: {
                                'width': {
                                    'top': {unit: 'px', value: 4},
                                    'right': {unit: 'px', value: 4},
                                    'bottom': {unit: 'px', value: 4},
                                    'left': {unit: 'px', value: 4}
                                },

                                'style': {
                                    'top': 'solid',
                                    'left': 'solid',
                                    'right': 'solid',
                                    'bottom': 'solid'
                                },

                                'color': {
                                    'top': {'red': 127, 'green': 32, 'blue': 32, 'alpha': 0.88},
                                    'right': {'red': 127, 'green': 32, 'blue': 32, 'alpha': 0.88},
                                    'bottom': {'red': 127, 'green': 32, 'blue': 32, 'alpha': 0.88},
                                    'left': {'red': 127, 'green': 32, 'blue': 32, 'alpha': 0.88}
                                }
                            },

                            cssBoxShadow: null
                        }
                    }
                }
            },

            {
                id: 'HoverBoxThemePreset_#2',
                structure: {
                    componentType: 'wysiwyg.viewer.components.HoverBox',
                    design: {
                        charas: 'HoverBoxThemePreset_#2',

                        themeMappings: {
                            'cssBoxShadow.0.color': 'color_28',
                            'cssBoxShadow.1.color': 'color_28'
                        },

                        cssStyle: {

                            cssBorder: null,
                            cssBorderRadius: null,
                            cssBoxShadow: [
                                {
                                    'inset': false,
                                    'offsetX': {'unit': 'em', 'value': 0.2},
                                    'offsetY': {'unit': 'em', 'value': 0.2},
                                    'blurRadius': {'unit': 'px', 'value': 4},
                                    'spreadRadius': {'unit': 'px', 'value': 5},
                                    'color': {'red': 127, 'green': 172, 'blue': 32, 'alpha': 0.5}
                                },

                                {
                                    'inset': true,
                                    'offsetX': {'unit': 'em', 'value': 0.2},
                                    'offsetY': {'unit': 'em', 'value': 0.23},
                                    'blurRadius': {'unit': 'px', 'value': 2},
                                    'spreadRadius': {'unit': 'px', 'value': 5},
                                    'color': {'red': 127, 'green': 172, 'blue': 32, 'alpha': 0.5}
                                }
                            ]
                        }
                    }
                }
            }
        ]
    }
}

module.exports = presets
