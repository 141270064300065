// @ts-nocheck
import PropTypes from 'prop-types';
import * as util from '@/util';
import React from 'react';
import { Composites, ToggleSwitch, Divider } from '@wix/wix-base-ui';
import * as panelUtils from '@/panelUtils';

//TYPE WAS GENERATED, remove this line when reviewed
interface Props {
  paramKey: string;
  styleDataItem: AnyFixMe;
  forceUpdateParentStyle: FunctionFixMe;
}

export default class extends React.Component<Props> {
  static displayName = 'advancedStyleShadowControl';

  static propTypes = {
    paramKey: PropTypes.string.isRequired,
    styleDataItem: PropTypes.object.isRequired,
    forceUpdateParentStyle: PropTypes.func.isRequired,
  };

  render() {
    return (
      <div>
        <Composites.ToggleSwitch>
          <ToggleSwitch
            value={this.getEnabledShadowValue()}
            onChange={this.onShadowEnabledChange}
            shouldTranslate={true}
            label="CustomDesign_Shadow_EnableToggle"
          />
        </Composites.ToggleSwitch>
        <Divider long={false} />
        <panelUtils.shadowControl
          valueLink={this.props.valueLink}
          disabled={!this.getEnabledShadowValue()}
          key="shadowControl"
        />
      </div>
    );
  }

  getEnabledParamKey = () => {
    return `boxShadowToggleOn-${this.props.paramKey}`;
  };

  getEnabledShadowValue = () => {
    return util.stringUtils.isTrue(
      this.props.styleDataItem.style.properties[this.getEnabledParamKey()],
    );
  };

  onShadowEnabledChange = (newVal) => {
    const { styleDataItem } = this.props;
    const param = this.getEnabledParamKey();
    const value = newVal.toString();
    styleDataItem.style.properties[param] = value;
    this.props.forceUpdateParentStyle(styleDataItem, param, value);
  };
}
