import constants from '@/constants';
import addPanelDataConsts from '@/addPanelDataConsts';
import * as stateManagement from '@/stateManagement';
import * as coreBi from '@/coreBi';
import * as wixData from '@/wixData';

import type {
  MapDispatchToProps,
  MapStateToProps,
  ThunkAction,
} from 'types/redux';

import type {
  TogglesListSectionDispatchProps,
  TogglesListSectionStateProps,
  TogglesListSectionOwnProps,
} from './togglesListSection';

import type { TogglesListItem } from './togglesListSectionType';

const sendBi = stateManagement.bi.actions.event;

const { TOGGLE_CORVID_DEV_MODE } = addPanelDataConsts.CLICK_ACTION;
const { ADD_PANEL_NAME } = constants.ROOT_COMPS.LEFTBAR;

const { closePanelByName } = stateManagement.panels.actions;

const actionsMap = {
  [TOGGLE_CORVID_DEV_MODE]: {
    isToggled: (state: AnyFixMe, editorAPI: AnyFixMe) =>
      Boolean(editorAPI.developerMode.isEnabled()),
    toggle:
      (): ThunkAction =>
      (dispatch, getState, { editorAPI }) => {
        const isDeveloperModeEnabled = Boolean(
          editorAPI.developerMode.isEnabled(),
        );
        const isWixCodeProvisioned = Boolean(
          editorAPI.wixCode?.isProvisioned(),
        );

        editorAPI.developerMode.toggle();

        dispatch(
          sendBi(coreBi.events.addPanel.DEV_MODE_TOGGLE, {
            origin: 'add_panel_manual',
            status: !isDeveloperModeEnabled,
            is_data_on: wixData.isDataModeOn(editorAPI),
            is_first: !isWixCodeProvisioned,
          }),
        );
      },
  },
};

export const mapStateToProps: MapStateToProps<
  TogglesListSectionStateProps,
  TogglesListSectionOwnProps
> = ({ state, editorAPI }, ownProps) => {
  const items = ownProps.props.items.map((item) => {
    const { action, toggledState } = item;
    const toggled = actionsMap[action]?.isToggled(state, editorAPI);

    return toggled ? { ...item, ...toggledState } : item;
  });

  return {
    props: {
      ...ownProps.props,
      items,
    },
  };
};

export const mapDispatchToProps: MapDispatchToProps<
  TogglesListSectionDispatchProps,
  TogglesListSectionOwnProps
> = (dispatch) => ({
  toggle: (item: TogglesListItem) => {
    const toggleActionCreator = actionsMap[item.action].toggle;

    return dispatch(toggleActionCreator());
  },
  closePanel: () => dispatch(closePanelByName(ADD_PANEL_NAME)),
});
