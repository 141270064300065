import React, { useEffect, useState } from 'react';
import * as util from '@/util';
import { TpaSettingsPanelFrame } from '../../frames';
import type { EditorAPI } from '@/editorAPI';
import type { MapDispatchToProps, ThunkAction } from 'types/redux';
import type { CompRef } from 'types/documentServices';
import { Text, SectionDivider, Divider } from '@wix/wix-base-ui';
import { translate } from '@/i18n';
import {
  rcmMovePluginSelect,
  pluginMoved,
  clickOnMovePluginHoverOnSlot,
} from '@wix/bi-logger-editor/v2';
import styles from './slotSelectionExtendedPanel.scss';
import SlotPlaceholderItem from './slotPlaceholderItem';
import type {
  SlotSelectionPanelProps,
  SlotSelectionPanelDispatchProps,
  SlotSelectionPanelOwnProps,
} from './types';

const SlotSelectionExtendedPanel: React.FC<SlotSelectionPanelProps> = (
  props,
) => {
  const [currentSlotCompRef, setCurrentSlotCompRef] = useState(
    () => props.slotsPlaceholderCompRef,
  );
  const [slotsToMove, setSlotsToMove] = useState(() =>
    props.getSlotsToMove(props.slotsPlaceholderCompRef),
  );

  const handleMoveToSlot = async (slotCompRef: CompRef) => {
    await props.moveToSlot(currentSlotCompRef, slotCompRef);

    const slots = props.getSlotsToMove(slotCompRef);
    setSlotsToMove(slots);
    setCurrentSlotCompRef(slotCompRef);
  };

  const handleHighlightSlot = (slotCompRef: CompRef) => {
    props.highlightSlot(currentSlotCompRef, slotCompRef);
  };

  useEffect(() => {
    props.onSlotSelectionPanelOpened();
  });

  return (
    <TpaSettingsPanelFrame
      panelName={props.panelName}
      title={translate('PLATFORM_Widget_Slots_Move_Plugin_Header')}
      width={288}
      shouldAddScroll
      style={props.style}
      className={styles.slotSelectionPanelFrame}
      onClose={props.onClose}
      onHelpClicked={props.openHelpArticle}
    >
      <div className={styles.slotSelectionPanelDescription}>
        <Text size="small" weight="thin" skin="standard" enableEllipsis={false}>
          {'PLATFORM_Widget_Slots_Move_Plugin_InfoText'}
        </Text>
      </div>

      <Divider direction="horizontal" long />
      <SectionDivider>
        {translate('PLATFORM_Widget_Slots_Move_Plugin_Placement_Divider')}
      </SectionDivider>
      <Divider direction="horizontal" long />

      {slotsToMove.map((slotPlaceholder, index) => (
        <SlotPlaceholderItem
          key={slotPlaceholder.slotRef.id}
          slotPlaceholderIndex={index + 1}
          slotPlaceholder={slotPlaceholder}
          pluginName={slotPlaceholder.pluginInfo?.name}
          isPopulated={slotPlaceholder.isPopulated}
          isSelected={slotPlaceholder.isSelected}
          highlightSlot={handleHighlightSlot}
          unhighlightSlot={props.unhighlightSlot}
          moveToSlot={handleMoveToSlot}
          openWidgetPluginsPanel={props.openWidgetPluginsPanel}
        />
      ))}
    </TpaSettingsPanelFrame>
  );
};

const getEditorAPI: ThunkAction = (_dispatch, _getState, { editorAPI }) =>
  editorAPI;

const mapDispatchToProps: MapDispatchToProps<
  SlotSelectionPanelDispatchProps,
  SlotSelectionPanelOwnProps
> = (dispatch, { slotsPlaceholderCompRef }) => {
  const editorAPI: EditorAPI = dispatch(getEditorAPI);

  return {
    onSlotSelectionPanelOpened: () => {
      const slot = editorAPI.platform.widgetPlugins.getWidgetSlot(
        slotsPlaceholderCompRef,
      );

      util.biLogger.report(
        rcmMovePluginSelect({
          slot_id: slotsPlaceholderCompRef.id,
          plugin_id: slot?.pluginInfo?.appDefinitionId,
        }),
      );
    },
    getSlotsToMove: (compRef: CompRef) => {
      return editorAPI.platform.widgetPlugins.getWidgetSlotsToMoveContent(
        compRef,
      );
    },
    moveToSlot: async (
      fromSlotComponentRef: CompRef,
      toSlotComponentRef: CompRef,
    ) => {
      await editorAPI.platform.widgetPlugins.moveWidgetToAnotherSlot(
        fromSlotComponentRef,
        toSlotComponentRef,
      );

      editorAPI.selection.selectComponentByCompRef(toSlotComponentRef);

      const slot =
        editorAPI.platform.widgetPlugins.getWidgetSlot(fromSlotComponentRef);

      util.biLogger.report(
        pluginMoved({
          slot_id_previous: fromSlotComponentRef.id,
          slot_id_current: toSlotComponentRef.id,
          plugin_id: slot?.pluginInfo?.appDefinitionId,
        }),
      );
    },
    unhighlightSlot: (compRef: CompRef) => {
      editorAPI.platform.widgetPlugins.unhighlightSlot(compRef);
    },
    highlightSlot: (
      fromSlotComponentRef: CompRef,
      toSlotComponentRef: CompRef,
    ) => {
      editorAPI.platform.widgetPlugins.highlightSlot(toSlotComponentRef);

      const slot =
        editorAPI.platform.widgetPlugins.getWidgetSlot(fromSlotComponentRef);

      util.biLogger.report(
        clickOnMovePluginHoverOnSlot({
          slot_id_current: fromSlotComponentRef.id,
          slot_id_highlighted: toSlotComponentRef.id,
          plugin_id: slot?.pluginInfo?.appDefinitionId,
        }),
      );
    },
    openHelpArticle: () => {
      const moveWidgetPluginHelpArticleId =
        'fbd26fc9-2439-401e-9474-70c7afabea20';
      editorAPI.panelManager.openHelpCenter(moveWidgetPluginHelpArticleId);
    },
    openWidgetPluginsPanel: (compRef: CompRef) => {
      const hostRef =
        editorAPI.platform.widgetPlugins.getWidgetHostRefBySlotRef(
          slotsPlaceholderCompRef,
        );

      editorAPI.selection.selectComponentByCompRef(hostRef);
      editorAPI.platform.widgetPlugins.unhighlightSlot(compRef);
      editorAPI.panelManager.openPanel('panels.widgetPanels.pluginsPanel');
    },
  };
};

export default util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  null,
  mapDispatchToProps,
)(SlotSelectionExtendedPanel);
