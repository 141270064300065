// @ts-nocheck
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import * as core from '@/core';
import React from 'react';
import * as panels from '@/panels';
import { translate } from '@/i18n';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  propTypes: {
    onClose: PropTypes.func.isRequired,
  },
  displayName: 'appMarketAppModal',
  mixins: [core.mixins.editorAPIMixin],
  render() {
    return (
      <panels.frames.FocusPanelFrame
        panelName={this.props.panelName}
        title={translate('app_market_title')}
        shouldHideHelp={true}
        onClose={this.onClose}
      >
        <div className="app-market-app-modal-container">
          <iframe
            src={this.props.iframeUrl}
            width="100%"
            height="100%"
            frameBorder="0"
          />
        </div>
      </panels.frames.FocusPanelFrame>
    );
  },
  onClose() {
    const editorAPI = this.getEditorAPI();
    editorAPI.panelManager.closePanelByName(this.props.panelName);
    if (this.props.onClose) {
      this.props.onClose();
    }
  },
});
