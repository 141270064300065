// @ts-nocheck
import _ from 'lodash';
import React from 'react';
import { ToolPanelFrame } from '../frames';

export default class extends React.Component {
  static displayName = 'exampleToolPanel';

  static defaultProps = {
    top: '200px',
    left: '300px',
  };

  getFrameStyle = () => {
    return _.defaults(
      { width: 500, height: 200 },
      _.pick(this.props, ['top', 'left']),
    );
  };

  render() {
    return (
      <ToolPanelFrame
        panelName={this.props.panelName}
        headerTitle="Tools Panel"
        contentStyle={this.getFrameStyle()}
      >
        <div>content here</div>
      </ToolPanelFrame>
    );
  }
}
