export const createCollectionInEmptyStateClicked = {
  src: 38,
  evid: 1262,
};

export const createCollectionInNotEmptyStateClicked = {
  src: 38,
  evid: 1263,
};

export const actionInMainTabClicked = {
  src: 38,
  evid: 1264,
};

export const collectionClicked = {
  src: 38,
  evid: 1265,
};

export const settingsClicked = {
  src: 38,
  evid: 1266,
};

export const videoHovered = {
  src: 38,
  evid: 1267,
};

export const videoClicked = {
  src: 38,
  evid: 1268,
};

export const premiumBannerInteractions = {
  src: 83,
  evid: 340,
  endpoint: 'platform-cm',
};

export const upgradeClicked = {
  src: 83,
  evid: 344,
  endpoint: 'platform-cm',
};

export const formCollectionsMovement = {
  src: 83,
  evid: 349,
  endpoint: 'platform-cm',
};

export const premiumPackagePickerLoad = {
  src: 17,
  evid: 940,
  endpoint: 'pre',
};

export const cmsAdvancedSettingsClicked = {
  src: 83,
  evid: 236,
  endpoint: 'platform-cm',
};
