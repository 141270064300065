// @ts-nocheck
import { overridable } from '@wix/santa-editor-utils';

let compInProgress = null;
const queuedSetOperations = {};

const onSetStyleEndEmptyQueue = function (editorAPI) {
  if (queuedSetOperations[compInProgress]) {
    const datas = [];
    const callbacks = [];
    const compId = compInProgress;

    queuedSetOperations[compId].forEach((item) => {
      datas.push(item.data);
      callbacks.push(item.callback);
    });

    delete queuedSetOperations[compId];
    compInProgress = null;
    setStyleParam(editorAPI, compId, datas, callbacks);
  } else {
    compInProgress = null;
  }
};

const resolveCallbacks = function (callback, styleProperties) {
  if (Array.isArray(callback)) {
    callback.forEach((item) => {
      if (item) {
        item(styleProperties);
      }
    });
  } else if (callback) {
    callback(styleProperties);
  }
};

const getStyleParam = overridable(() => {});

const setStyleParam = overridable((editorAPI, compRefOrId, data, callback) => {
  const compRef =
    typeof compRefOrId === 'string'
      ? editorAPI.dsRead.components.get.byId(compRefOrId)
      : compRefOrId;
  const compId = compRef.id;
  if (compInProgress === compId) {
    //TODO - change compInProgress Q to support multiple components
    if (!queuedSetOperations[compId]) {
      queuedSetOperations[compId] = [];
    }
    queuedSetOperations[compId].push({
      data,
      callback,
    });
    return;
  }

  editorAPI.dsActions.tpa.style.setCompStyleParam(
    compRef,
    data,
    function (styleProperties) {
      resolveCallbacks(callback, styleProperties);
    },
  );

  editorAPI.dsActions.waitForChangesApplied(
    onSetStyleEndEmptyQueue.bind(null, editorAPI),
  );
  compInProgress = compId;
});

export { setStyleParam, getStyleParam };
