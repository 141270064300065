// @ts-nocheck
import _ from 'lodash';
import { ErrorReporter } from '@wix/editor-error-reporter';

const getCompName = function (editorAPI, compRef) {
  const stageData = editorAPI.dsRead.platform.controllers.getStageData(compRef);
  if (stageData?.displayName) {
    return stageData.displayName;
  }

  const compData = editorAPI.components.data.get(compRef);
  const appData = editorAPI.dsRead.tpa.app.getData(compData.applicationId);
  if (!appData?.appDefinitionId) {
    ErrorReporter.captureException(
      new Error('app market call without appDefId'),
      {
        tags: {
          appMarketCallNoAppDefId: true,
        },
        extra: {
          compRef,
          flow: 'tpaCompNameService',
        },
      },
    );
    return '';
  }
  const appMarketData = editorAPI.dsRead.tpa.appMarket.getData(
    appData.appDefinitionId,
  );
  if (appMarketData) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/find
    const widgetMarketData = _.find(appMarketData.widgets, {
      widgetId: compData.widgetId,
    });
    if (widgetMarketData) {
      return widgetMarketData.title;
    }
    return appMarketData.name;
  }
  return appData.appDefinitionName;
};

export { getCompName };
