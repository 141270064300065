import { searchModule } from '../searchModule';
import { Integrations } from '../constants';
import { isValidUrl } from '../utils/utils';

import type { EditorAPI } from '@/editorAPI';

const BI_SOURCE_NAME = 'editorSearch';

export const integrateBusinessManagerToSearch = (editorAPI: EditorAPI) => {
  const {
    account: { openSettings },
  } = editorAPI;

  searchModule.addIntegration({
    type: Integrations.BUSINESS_MANAGER,
    transform: (result) => {
      const {
        extras: { path, businessManagerType },
      } = result;

      return {
        ...result,
        onSubmit: () => {
          if (businessManagerType === 'Url') {
            const urlToOpen = isValidUrl(path)
              ? path
              : `http://www.wix.com${path}`;

            window.open(urlToOpen, '_blank');
          } else {
            openSettings({ path, referrer: BI_SOURCE_NAME });
          }
        },
      };
    },
  });
};
