import React from 'react';
import { Text, RichText, Spacer, Divider } from '@wix/wix-base-ui';
import { frames } from '@/panels';

export interface SaveSectionizedTemplatePromtProps {
  onConfirm: () => void;
  onCancel: () => void;
  panelName?: string;
}

const SaveSectionizedTemplatePromt = (
  props: SaveSectionizedTemplatePromtProps,
) => {
  return (
    <frames.MessagePanelFrame
      panelName={props.panelName}
      title={'Section Detected on this Site\n'}
      confirmLabel={'Back to Editing'}
      onConfirm={props.onConfirm}
      cancelLabel={'Publish Anyway'}
      onCancel={props.onCancel}
      illustration={null}
      theme="standard"
    >
      <RichText>
        There is at least one section somewhere in this site. <br />
        <strong>
          If this is an original template, all users starting from this template
          will see it.
        </strong>
        This can happen if you accidentally apply the migration, or add sections
        in any way.
      </RichText>
      <Spacer type="Spacer04" />
      <Divider long />
      <Spacer type="Spacer04" />

      <Text disabled size="large" shouldTranslate={false}>
        What's next?
      </Text>

      <Spacer type="Spacer04" />
      <RichText>
        1. <strong>Revert</strong> back to the last version from before your
        recent change. <br />
        2. Open the <strong>Layers</strong> panel to make sure no sections are
        on the page. <br />
        3. <strong>Use only se_sectionizer</strong> on an original template.
      </RichText>
    </frames.MessagePanelFrame>
  );
};

export default SaveSectionizedTemplatePromt;
