import React, { Component } from 'react';

import * as util from '@/util';
import * as stateManagement from '@/stateManagement';

import connectWithEditor from '../../util/connectWithEditor';

import type { MapDispatchToProps } from 'types/redux';

const { setTopBarHeight } = stateManagement.config.actions;
const { isPropChanged } = util;

interface WithSetHeightBehaviorOwnProps {
  isPreviewMode: boolean;
  isMinimized: boolean;
}

interface WithSetHeightBehaviorDispatchProps {
  setTopBarHeight: (val: number) => void;
}

interface WithSetHeightBehaviorProps
  extends WithSetHeightBehaviorOwnProps,
    WithSetHeightBehaviorDispatchProps {}

const mapDispatchToProps: MapDispatchToProps<
  WithSetHeightBehaviorDispatchProps,
  WithSetHeightBehaviorOwnProps
> = {
  setTopBarHeight,
};

const withSetHeightBehavior = (WrappedComponent: AnyFixMe) => {
  class WithSetHeightBehavior extends Component<WithSetHeightBehaviorProps> {
    componentDidMount() {
      this.setTwoDecksTopBarHeight();
    }

    componentDidUpdate(prevProps: Readonly<WithSetHeightBehaviorProps>) {
      if (
        isPropChanged(prevProps, this.props, ['isPreviewMode', 'isMinimized'])
      ) {
        if (this.props.isPreviewMode || this.props.isMinimized) {
          this.setOneDeckTopBarHeight();
        } else {
          this.setTwoDecksTopBarHeight();
        }
      }
    }

    setTwoDecksTopBarHeight = () => {
      this.props.setTopBarHeight(util.topBar.getHeightConst());
    };

    setOneDeckTopBarHeight = () => {
      this.props.setTopBarHeight(util.topBar.getUpperDeckHeightConst());
    };

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return connectWithEditor(null, mapDispatchToProps)(WithSetHeightBehavior);
};

export default withSetHeightBehavior;
