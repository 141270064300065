import _ from 'lodash';
import React, { useMemo, useRef, useState } from 'react';
import { cx } from '@/util';
import constants from '@/constants';
import styles from './siteCreationFPDThumbnail.scss';
import { PresetPreviewComponent } from '@/presetApi';
import {
  disableScrollTransition,
  resetScrollTransition,
  getCurrentTranslatePosition,
  getThumbnailWidth,
} from './siteCreationUtils';
import { symbol as Symbol } from '@wix/santa-editor-symbols';
import type { SiteCreationProps } from './siteCreationPagesView';

const { SITE_CREATION: SITE_CREATION_CONSTS } = constants;

export interface SiteCreationFPDThumbnailProps {
  scale: number;
  pagePreset: any;
  isSelected: boolean;
  onClick?: (actionSource: string) => void;
  thumbnailHeight: number;
  presetIndex: number;
  fireContentScrolled: SiteCreationProps['fireContentScrolled'];
  nonInteractive?: boolean;
  singlePage?: boolean;
}

const SiteCreationFPDThumbnail: React.FC<SiteCreationFPDThumbnailProps> = ({
  scale,
  onClick,
  singlePage,
  pagePreset,
  isSelected,
  presetIndex,
  nonInteractive,
  thumbnailHeight,
  fireContentScrolled,
}) => {
  const debouncedScrollEvent = useMemo(
    () =>
      _.debounce(
        () =>
          fireContentScrolled(
            SITE_CREATION_CONSTS.SCREENS.HOMEPAGE,
            presetIndex,
            'manual',
          ),
        300,
      ),
    [fireContentScrolled, presetIndex],
  );
  const thumbnailRef = useRef<HTMLDivElement>();
  const scrollBarRef = useRef<HTMLDivElement>();
  const [overflowHeight, setOverflowHeight] = useState<number>(0);
  const [scrollBarHeight, setScrollBarHeight] =
    useState<React.CSSProperties['height']>(0);

  const afterPresetRender = () => {
    const thumbnailScrollHeight = thumbnailRef.current.scrollHeight;
    if (thumbnailScrollHeight > thumbnailHeight) {
      setScrollBarHeight(`${(thumbnailHeight / thumbnailScrollHeight) * 100}%`);
    }

    const overflowHeight = thumbnailScrollHeight - thumbnailHeight;
    setOverflowHeight(overflowHeight > 0 ? overflowHeight : 0);
  };

  const startAutoScroll = (): void => {
    if (!overflowHeight) return;
    fireContentScrolled(
      SITE_CREATION_CONSTS.SCREENS.HOMEPAGE,
      presetIndex,
      'autoscroll',
    );
    transformElement(Math.min(overflowHeight, 60));
  };

  const resetScroll = (): void => {
    resetScrollTransition(thumbnailRef, scrollBarRef);
    transformElement(0);
  };

  const transformElement = (position: number) => {
    const thumbFrame = thumbnailRef.current.firstElementChild as HTMLDivElement;
    thumbFrame.style.transform = `translateY(-${position}px)`;

    const scrollPosition =
      (position * thumbnailHeight) / thumbFrame.scrollHeight || 0;
    scrollBarRef.current.style.top = `${scrollPosition}px`;
  };

  const onIntentScroll = (event: React.WheelEvent<HTMLDivElement>) => {
    if (!overflowHeight) return;
    debouncedScrollEvent();

    const translatePosition = getCurrentTranslatePosition(
      thumbnailRef.current.firstElementChild as HTMLDivElement,
    );

    const newPosition = Math.max(translatePosition + event.deltaY, 0);
    const translateTo =
      newPosition > overflowHeight ? overflowHeight : newPosition;

    disableScrollTransition(thumbnailRef, scrollBarRef);
    transformElement(translateTo);
  };

  return (
    <div
      className={cx(styles.fpdColumnContent, {
        [styles.singlePage]: singlePage,
      })}
      onMouseEnter={startAutoScroll}
      onMouseLeave={resetScroll}
    >
      <div
        className={cx(styles.siteCreationFpdThumbnailWrapper, {
          selected: isSelected,
        })}
      >
        {isSelected ? (
          <Symbol name="CircleVBlue" className={styles.fpdSelectedV} />
        ) : null}
        <div
          onClick={() => onClick('page_thumbnail')}
          onWheel={onIntentScroll}
          className={cx(styles.fpdPresetWrapper, { nonInteractive })}
          style={{
            maxHeight: thumbnailHeight,
            width: getThumbnailWidth(scale),
          }}
          ref={thumbnailRef}
          data-hook="fpd-thumbnail"
        >
          <PresetPreviewComponent
            scale={scale}
            presetPreview={pagePreset.presetPreviews}
            onAfterRender={afterPresetRender}
            kit={pagePreset.kit}
          />
          <div
            ref={scrollBarRef}
            className={styles.fpdScrollbar}
            style={{
              top: 0,
              height: scrollBarHeight,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default SiteCreationFPDThumbnail;
