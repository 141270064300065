// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';
import * as util from '@/util';
import * as baseUI from '@/baseUI';
import * as symbols from '@wix/santa-editor-symbols';
import { cx } from '@/util';

function template() {
  return (
    <baseUI.tooltip
      value={
        this.props.isHidden
          ? 'Toolbars_show_hover_label'
          : 'Toolbars_hide_hover_label'
      }
      key="hideAllTools"
    >
      <div
        onClick={() => {
          this.props.toggleHideTools();
        }}
        style={{
          zIndex: this.props.zIndex,
        }}
        className={cx({
          'hide-tools-button': true,
          'tools-hidden': this.props.isHidden,
          'stand-out': this.props.shouldHideToolsBtnBlink,
        })}
        data-hook="hide-tools-button"
      >
        <symbols.symbol name="hideToolsArrow" className="hide-tools-symbol" />
      </div>
    </baseUI.tooltip>
  );
}

class HideToolsButton extends React.Component {
  render() {
    return template.call(this);
  }
}

HideToolsButton.displyName = 'HideToolsButton';

HideToolsButton.propTypes = {
  isHidden: PropTypes.bool.isRequired,
  toggleHideTools: PropTypes.func.isRequired,
  shouldHideToolsBtnBlink: PropTypes.bool.isRequired,
  zIndex: PropTypes.number,
};

export default util.hoc.renderWhenMutated(HideToolsButton);
