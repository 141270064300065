import React, { type FC } from 'react';
import { Text } from '@wix/wix-base-ui';

interface BaseUiTextWrapperProps {
  text: string;
  disabled: boolean;
}

export const BaseUiTextWrapper: FC<BaseUiTextWrapperProps> = ({
  text,
  disabled,
}) => {
  return (
    <Text
      shouldTranslate={false}
      size="small"
      enableEllipsis={false}
      disabled={disabled}
    >
      {text}
    </Text>
  );
};
