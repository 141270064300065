// injection to master page (header/footer) is allowed for this templates
export const INJECTION_MASTER_PAGE_WHITE_LIST = new Set([
  '78e1c67c-91f6-42ab-ba50-8f9a1afa8237',
  'eea928bf-523f-4230-b2c7-4f251333ec6e',
  '34e2bf86-1076-4637-9172-3780ec2d0de6',
  'cf50aba6-c2fd-4736-827d-f0f607de4723',
  '49e5ba2a-7e6b-4f2c-b08e-9da4e83f1096',
  'eb6d64a8-0bcc-4919-976d-f6a60d884a18',
  'a73d1c2c-d7cc-4827-b4f2-36bf96168682',
  'bf6aeb61-6d03-4b41-9d17-41510aea2db8',
  'dd124d90-ed5a-4d49-ab59-e7e6647e53ae',
  '677be04d-41ef-40e7-8402-302256e1b0f1',
  'f2f3c43c-e472-4b61-ad24-162ad6839dc1',
  'f079a559-b478-4643-a17b-13edb1ee067b',
  'f65bef84-0623-4916-965f-ab997e56822a',
  '0dd8adc3-2c78-47dd-a37f-93acc5994425',
  '4803d699-47ce-4724-909a-4175dabaf24d',
  '77331e56-84fa-4f84-a4c1-3bd40b0349c9',
  '4d2ee0f5-e393-41e4-b864-ea31b21f71d8',
  '6739270e-5a40-4e84-8a05-66f8276e161a',
  '3830c793-bc68-4ab7-a054-852e9c9193c5',
  'ce38e842-ec56-4a09-87e7-0cca8fd4ba54',
  '34571e98-35d6-4012-9630-7ea2e183b71b',
  '26f031ff-bf63-435a-8cbf-f0a55ca66fb3',
  '3f25f0ab-506f-448b-a2d2-d43208ecb21e',
  'ae86808c-70b0-41bc-9ef9-a9d53c04e185',
  '432793fc-6119-4f82-ad3e-ff52efa9746c',
  '9d88a900-daba-4a08-adc0-222ec2454f4d',
  '15bd3b7e-da0f-433f-9c92-370a40838b50',
  '4a8533be-36f0-4156-8920-79cc5d9c4948',
  'db985ed4-f7ab-420a-8611-f24aca1b2d90',
  'e6c9ad83-5b8b-403f-95ac-44bb999ed727',
  '793427a4-df97-4932-b66e-c75109b72c2c',
  '8204c1ea-6b09-47e2-a3d3-46425aa97d56',
  'f3b2f82b-4eef-4d1a-bcbb-9b4113eab7d0',
  '617a4e3f-9a32-4a4a-bbdc-1a90cc261ed2',
  '648ae4f3-0bc8-46c6-becc-8bbf3ccc2ab3',
  'f0eba113-eb57-408d-93cb-ce87f3b0e4ad',
  'c623c5a3-7ab3-4269-a839-79c475db914b',
  '94759fd1-e7a4-4d8c-b16f-e18a3a1ec4ec',
  '6e484091-c0b5-445e-912b-9196e1aeb326',
  'b17104b8-93ae-4cdf-810c-a1db8065f011',
  'b7d73208-d8ee-4340-bf37-b47688493e45',
  'f9121607-d060-431e-a708-5ae610ee5f9a',
  '36e4a599-4a2a-45a5-b573-e713d4501232',
  'bfbf7579-c5fb-45d4-8768-d9d41a0f2c67',
  '35053ddd-ea55-450a-bc45-a94471422a25',
  '52f9a1d5-b48f-4cfa-b58c-0d54db31b2e6',
  'fb8a6eae-8804-46df-b45f-a39c83ea9657',
  'd33e63f1-1d28-4e4b-b360-6a2dcba5e428',
  '9cae9c84-3103-4032-b6c2-471eb38e226c',
  '183bbc40-14d1-49dc-b31d-d370b64cbaa2',
  '3044e9e1-7c19-4f7b-ae79-20a2fac23be5',
  '1d02239f-9aa0-43ff-8bc4-f8f03689461d',
  '51db40ea-f40e-4a15-af14-5779b0b9f9b7',
  '26f767e9-32c3-476f-b07d-c02f569961de',
  '5c09fa92-28d2-4f19-9d2c-8631e313d349',
  '76e69566-ac4c-44b5-a053-52847a8c1c4b',
  'e4fe7d43-9b3c-4c69-ad17-17702eab5bc3',
  '2b2445c4-c655-4ec6-b4c1-711fee2cb601',
]);
