:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._panel_1lyq5_7 {
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  left: 100%;
  width: 0;
  transition: 0.5s width;
  overflow: hidden;
  pointer-events: none; }
  .sections-experience ._panel_1lyq5_7 {
    left: var(--left-bar-width); }
  ._panel_1lyq5_7 > * {
    pointer-events: auto; }

._opened_1lyq5_22 {
  transition: 0s width;
  width: 100vw; }
