import React from 'react';
import type { MapDispatchToProps, Dispatch } from 'types/redux';
import { Button } from '@wix/wix-base-ui';

import * as util from '@/util';
import { translate } from '@/i18n';
import * as stateManagement from '@/stateManagement';

import { useSelectedApp } from '../selectedAppContext/selectedAppContext';
import type { AppData } from '../privateAppsProvider/privateAppsProvider';
import {
  installApp,
  getAppInstallVersion,
  type InstallAppDispatchProps,
} from '../../utils/installApp';

const INSTALL_BTN_TRANSLATION_KEY =
  'blocks-private-apps.AppDiscovery_App_Details_Install_CTA';

interface InstallAppButtonProps extends InstallAppDispatchProps {
  versionToInstall?: string;
}

const InstallAppButtonComponent: React.FC<InstallAppButtonProps> = ({
  versionToInstall,
  installApp,
}) => {
  const { app } = useSelectedApp().selectedApp;

  return (
    <Button
      dataHook="install-private-app-button"
      onClick={() => installApp(app, versionToInstall)}
    >
      {translate(INSTALL_BTN_TRANSLATION_KEY)}
    </Button>
  );
};

type IMapDispatchToProps = MapDispatchToProps<InstallAppDispatchProps, {}>;

const mapDispatchToProps: IMapDispatchToProps = (dispatch: Dispatch) => ({
  installApp: (app: AppData, versionToInstall: string): Promise<unknown> => {
    const version = getAppInstallVersion(app, versionToInstall);
    return dispatch(installApp({ app, version }));
  },
  fetchAvailableApps: () =>
    dispatch(stateManagement.platform.actions.fetchAvailableApps()),
});

export const InstallAppButton = util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  undefined,
  mapDispatchToProps,
)(InstallAppButtonComponent);
