// @ts-nocheck
import iframeAPI from './iframeApi';

const BLOG_MANAGER_PANEL_NAME = 'panels.focusPanels.blogManagerPanel';
let isSaving = false;

/**
 * @param editorAPI
 * @param blogManagerAppPathType
 *
 * @returns {{
 * frameUrl: *,
 * themeData: !Object,
 * themeDataSchema: !Object,
 * WIXAPPS_EDITOR_STYLES: !{id: string, items: !Object.<!{cssClass?: string, label?: string, seoTag?: string, tag?: string}>, metaData: !Object, type: string},
 * CK_EDITOR_FONT_STYLES: !{id: string, items: !Object.<!{cssClass?: string, label?: string, seoTag?: string, tag?: string}>, metaData: !Object, type: string},
 * CustomUsedFontsUrl: *,
 * FontsCssUrls: !Array.<string>,
 * FontsData: !Object.<!{cdnName: string, characterSets: !Array.<string>, displayName: string, fallbacks: string, fontFamily: string, genericFamily: string, permissions: string, provider: string, spriteIndex: number}>,
 * FontsMetadata: (*|!Object.<!{cdnName: string, characterSets: !Array.<string>, displayName: string, fallbacks: string, fontFamily: string, genericFamily: string, permissions: string, provider: string, spriteIndex: number}>),
 * pagesData: *,
 * currentPageId: *,
 * anchorsData: *,
 * charSet: !Array.<string>,
 * reloadAppPartsMethod: Function}}
 */
function getIframeAPI(editorAPI, blogManagerAppPathType, referral, compId) {
  return {
    frameUrl: iframeAPI.getFrameUrl(
      editorAPI,
      blogManagerAppPathType,
      referral,
      compId,
    ),
    themeData: iframeAPI.getThemeData(editorAPI),
    themeDataSchema: iframeAPI.getThemeDataSchema(editorAPI),
    WIXAPPS_EDITOR_STYLES: iframeAPI.getWixAppsEditorStyles(),
    CK_EDITOR_FONT_STYLES: iframeAPI.getCkEditorFontStyles(),
    CustomUsedFontsUrl: iframeAPI.getCustomUsedFontsUrl(editorAPI),
    FontsCssUrls: iframeAPI.getFontsCssUrls(editorAPI),
    FontsData: iframeAPI.getFontsData(editorAPI),
    FontsMetadata: iframeAPI.getFontsMetadata(editorAPI),
    pagesData: iframeAPI.getPages(editorAPI),
    /**@deprecated*/
    // todo: 29/2/14 DimaR: remove if the blog iframe can't open on top of a popup, use primaryPageId instead
    currentPageId: iframeAPI.getCurrentUrlPageId(editorAPI),
    primaryPageId: iframeAPI.getPrimaryPageId(editorAPI),
    charSet: iframeAPI.getCharSet(editorAPI),
    siteUrl: iframeAPI.getPublicUrl(editorAPI),
    isSitePublished: iframeAPI.isSitePublished(editorAPI),
    imageOrganizerUrl: iframeAPI.getImageOrganizerUrl(),
    getAnchorsByPage(pageId) {
      return iframeAPI.getAnchorsByPage(editorAPI, pageId);
    },
    reloadAppPartsMethod() {
      return iframeAPI.reloadAppParts(editorAPI);
    },
    urlFormat: editorAPI.dsRead.generalInfo.urlFormat.get(),
    blogPageTitle: iframeAPI.getBlogPageTitle(editorAPI),
    singlePostLayout: iframeAPI.getSinglePostLayout(editorAPI),
    siteTitle: iframeAPI.getSiteTitle(editorAPI),
    isHeroImageEnabled: false,
    isHttpsEnabled: iframeAPI.isHttpsEnabled(editorAPI),
  };
}

/**
 * @param editorAPI
 * @param blogManagerAppPathType
 */
function openBlogManagerFrame(
  editorAPI,
  blogManagerAppPathType,
  referral,
  compRef,
) {
  const openBlogManager = function () {
    isSaving = false;
    editorAPI.panelManager.openPanel(
      BLOG_MANAGER_PANEL_NAME,
      {
        api: getIframeAPI(editorAPI, blogManagerAppPathType, referral, compRef),
      },
      true,
    );
  };

  const onSaveError = function () {
    isSaving = false;
    editorAPI.panelManager.openPanel(
      'savePublish.panels.common.failPanel',
      {
        titleKey: 'SAVE_ERROR_TITLE_LABEL',
        description: 'SAVE_PUBLISH_ERROR_BODY_DESCRIPTION',
        steps: [
          'SAVE_PUBLISH_SAVE_ERROR_OPTION_1',
          'SAVE_PUBLISH_SAVE_ERROR_OPTION_2',
        ],
        helpMessage: 'SAVE_ERROR_LEARN_MORE_LABEL',
        helpLinkMessage: 'SAVE_PUBLISH_ERROR_LEARN_MORE_LINK',
        helpLink: '',
      },
      true,
    );
  };

  const isSiteSaved = !editorAPI.dsRead.generalInfo.isFirstSave();
  const isDraftMode = editorAPI.dsRead.generalInfo.isDraft();
  if (!isSiteSaved || isDraftMode) {
    if (!isSaving) {
      isSaving = true;
      editorAPI.saveManager.saveInBackground(
        openBlogManager,
        onSaveError,
        'blogManagerFrame',
        {
          sourceOfStart: 'blogManager_bgSave',
        },
      );
    }
    //eslint-disable-next-line no-constant-condition
  } else if (false) {
    //!editorAPI.dsRead.wixapps.classics.account.getBlogInstanceId() was removed from dm
    // Report missing storeId
    editorAPI.bi.event({
      evid: 141,
      src: 12,
    });
    onSaveError();
  } else {
    openBlogManager();
  }
}

export { openBlogManagerFrame as open };
