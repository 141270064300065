import { fedopsLogger, serviceTopology } from '@/util';
import { ErrorReporter } from '@wix/editor-error-reporter';
import loadComponentsInitialStageModel from './loadComponentsInitialStageModel';
import { initComponentsRegistry } from './componentRegistry';

let componentModelReadyPromise: Promise<void>;

export async function onComponentModelReady() {
  if (!componentModelReadyPromise) {
    throw Error(
      '`onReady` function called before `bootstrap`.\n Ensure `bootstrap` is called.',
    );
  }

  return componentModelReadyPromise;
}

export default function bootstrap() {
  componentModelReadyPromise = (async () => {
    fedopsLogger.appLoadingPhaseStart('editor-components-model-init');

    fedopsLogger.appLoadingPhaseStart('editor-elements-registry-init');
    await initComponentsRegistry({ serviceTopology });
    fedopsLogger.appLoadingPhaseFinish('editor-elements-registry-init');

    // NOTE: temporary catch `loadComponentsInitialStageModel` model, until it safe enough and editor uses more components
    try {
      await loadComponentsInitialStageModel();
    } catch (error) {
      console.error(
        '[editor-elements-registry]: load initial stage model failed',
        error,
      );
      ErrorReporter.captureException(error, {
        tags: { operation: 'editor-elements-registry' },
      });
    }

    fedopsLogger.appLoadingPhaseFinish('editor-components-model-init');
  })();

  componentModelReadyPromise.catch((error) => {
    console.error('[editor-elements-registry]: init failed', error);
    ErrorReporter.captureException(error, {
      tags: { operation: 'editor-elements-registry' },
    });
  });
}
