import React from 'react';
import _ from 'lodash';
import { AppReadme } from '../appReadme/appReadme';
import { AppDetailsHeader } from '../appDetailsHeader/appDetailsHeader';
import { AppReleaseNotes } from '../appReleaseNotes/appReleaseNotes';
import { Text, Divider, Preloader } from '@wix/wix-base-ui';
import styles from './appDetails.scss';
import { useSelectedApp } from '../selectedAppContext/selectedAppContext';
import { AppDescription } from '../appDescription/appDescription';

export const AppDetails: React.FC = () => {
  const { listNotes, description, readme, selectedAppReady, app } =
    useSelectedApp().selectedApp;
  const hasDetails = Boolean(!_.isEmpty(listNotes) || readme || description);

  return (
    <div className={styles.appDetailsContainer}>
      <AppDetailsHeader />
      {!hasDetails && <Divider />}
      {selectedAppReady ? (
        <AppDetailsBody hasDetails={hasDetails} isContributor={app.canEdit} />
      ) : (
        <div className={styles.loaderContainer}>
          <Preloader className={'medium'} />
        </div>
      )}
    </div>
  );
};

const AppDetailsBody: React.FC<{
  hasDetails: boolean;
  isContributor: boolean;
}> = ({ hasDetails, isContributor }) =>
  hasDetails ? (
    <AppDetailsContent />
  ) : (
    <EmptyState isContributor={isContributor} />
  );

const AppDetailsContent: React.FC = () => (
  <>
    <AppDescription />
    <Divider />
    <AppReleaseNotes />
    <AppReadme />
  </>
);

const EmptyState: React.FC<{ isContributor: boolean }> = ({
  isContributor,
}) => (
  <div className={styles.emptyState}>
    <div>
      <Text
        weight="bold"
        dataHook={
          isContributor
            ? 'app-details-contributer-empty-state-title'
            : 'app-details-not-contributer-empty-state-title'
        }
      >
        {isContributor
          ? 'blocks-private-apps.AppDiscovery_No_App_Details_Empty_State_Title'
          : 'blocks-private-apps.AppDiscovery_No_App_Details_Site_Owner_Empty_State_Title'}
      </Text>
    </div>
    <div>
      <Text
        size="small"
        dataHook={
          isContributor
            ? 'app-details-contributer-empty-state-text'
            : 'app-details-not-contributer-empty-state-text'
        }
      >
        {isContributor
          ? 'blocks-private-apps.AppDiscovery_No_App_Details_Empty_State_Text'
          : 'blocks-private-apps.AppDiscovery_No_App_Details_Site_Owner_Empty_State_Text'}
      </Text>
    </div>
  </div>
);
