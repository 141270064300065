import * as util from '@/util';
import * as stateManagement from '@/stateManagement';
import type { StateMapperArgs, ThunkAction } from 'types/redux';
import type { BiEventDefinition, BiEventFields } from 'types/bi';

const mapStateToProps = ({ state, editorAPI }: StateMapperArgs) => {
  const allMenuItems = stateManagement.leftBar.selectors.getMenuItems(
    state,
    editorAPI.isPopUpMode(),
  );

  const overriddenItems = stateManagement.leftBar.selectors.getOverrideItems(
    state,
    editorAPI.isMobileEditor(),
  );

  const openPanels = editorAPI.getOpenPanels();
  const selectedPanelName = openPanels.find(
    (panel) => panel.frameType === 'leftPanelFrame',
  )?.name;

  return {
    superAppsDesktopCount:
      stateManagement.leftBar.selectors.getDesktopSuperAppsCount(state),
    isCollapsed: stateManagement.leftBar.selectors.isCollapsed(state),
    leftBarMenuItems: util.overrideUtils.applyOverrides(
      allMenuItems,
      overriddenItems,
      editorAPI.isMobileEditor() ? 'panelName' : 'key',
    ),
    isMobileEditor: editorAPI.isMobileEditor(),
    openPanels: editorAPI.getOpenPanels(),
    selectedPanelName,
    isEnabled: editorAPI.editorIsInit(),
    halfOpacityTools: editorAPI.toolsControl.isHalfOpacityTools(),
    isHidden: editorAPI.toolsControl.areToolsHidden(),
    leftBarNotifications:
      stateManagement.leftBar.selectors.getNotifications(state),
  };
};

const sendBIButtonsCount: ThunkAction = (dispatch, getState, { editorAPI }) => {
  dispatch(
    stateManagement.leftBar.actions.sendBIButtonsCount(editorAPI.isPopUpMode()),
  );
};

const hideFloatingBubble: ThunkAction = (dispatch, getState, { editorAPI }) => {
  editorAPI.hideFloatingBubble();
};

const mapDispatchToProps = (dispatch: FunctionFixMe) => ({
  sendBIButtonsCount() {
    dispatch(sendBIButtonsCount);
  },
  sendBIButtonClick(category: string) {
    dispatch(stateManagement.leftBar.actions.sendBIButtonClick(category));
  },
  sendBIButtonHover(element_name: string) {
    dispatch(stateManagement.leftBar.actions.sendBIButtonHover(element_name));
  },
  reportBI(eventType: BiEventDefinition, params: BiEventFields) {
    dispatch(stateManagement.bi.actions.event(eventType, params));
  },
  collapseMenu() {
    dispatch(stateManagement.leftBar.actions.collapseMenu());
  },
  openPanel(panelName: string, panelProps: object, leavePanelsOpen?: boolean) {
    dispatch(
      stateManagement.panels.actions.updateOrOpenPanel(
        panelName,
        panelProps,
        leavePanelsOpen,
      ),
    );
  },
  updatePanel(panelName: string, panelProps: object) {
    dispatch(stateManagement.panels.actions.updatePanel(panelName, panelProps));
  },
  closePanel(panelName: string) {
    dispatch(stateManagement.panels.actions.closePanelByName(panelName));
  },
  collapseButton(panelName: string) {
    dispatch(stateManagement.leftBar.actions.collapseButton(panelName));
  },
  hideFloatingBubble() {
    dispatch(hideFloatingBubble);
  },
  updateLeftBarButtonsBoundingRect(boundingRect: object) {
    dispatch(
      stateManagement.leftBar.actions.updateButtonsBoundingRect(boundingRect),
    );
  },
});

export { mapStateToProps, mapDispatchToProps };
