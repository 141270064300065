'use strict'
const addPanelDataConsts = require('@/addPanelDataConsts')
const firstItem = require('./firstItem')
const secondItem = require('./secondItem')
const thirdItem = require('./thirdItem')
const fourthItem = require('./fourthItem')
const fifthItem = require('./fifthItem')
const sixthItem = require('./sixthItem')

module.exports = {
    "type": addPanelDataConsts.SECTIONS_TYPES.PRESET,
    "hide": false,
    "title": "Popups_Add_Category_Promotion_Title",
    "subNavigationTitle": "Popups_Add_Category_Promotion_Title",
    "presetTitle": "Popups_Add_Category_Promotion_Title",
    "tooltipTitle": "Popups_Add_Category_Promotion_Title",
    "automationId": "add-panel-section-popupSaleSection",
    "subNavigationHide": false,
    "showSectionHeader": true,
    "additionalBehaviours": {
        "labelMode": addPanelDataConsts.LABEL_BEHAVIOUR.HOVER,
        "infoIcon": addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
        "hoverImageAction": addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
        "iconEnabledForComps": {}
    },
    "props": {
        "image": "addPanelData/sections/popups_en/popups_sale.v3.png",
        "imageHover": null,
        "items": [firstItem, secondItem, thirdItem, fourthItem, fifthItem, sixthItem],
        "compTypes": ["mobile.core.components.Page"]
    },
    "help": {"hide": false, "text": "Popups_Add_Category_Promotion_Text"}
}
