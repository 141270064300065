// @ts-nocheck
import constants from '@/constants';
import * as helpIds from '@/helpIds';
import * as stateManagement from '@/stateManagement';
import gfppDataUtils from '../../utils/gfppDataUtils';

const { ACTIONS } = constants.ROOT_COMPS.GFPP;
const componentsSelectors = stateManagement.components.selectors;

function isWithLabel(dsRead, compRef) {
  const compData = componentsSelectors.getData(compRef, dsRead);
  return compData?.label;
}

export default {
  untranslatable: true,
  mainActions: [
    {
      label: 'gfpp_mainaction_checkbox',
      isSelected: gfppDataUtils.getPanelStateFn(ACTIONS.SETTINGS),
      onClick: gfppDataUtils.getTogglePanelFn(ACTIONS.SETTINGS),
    },
  ],

  enabledActions(editorAPI, compRef) {
    let actions = [
      ACTIONS.SETTINGS,
      ACTIONS.DESIGN,
      ACTIONS.ANIMATION,
      ACTIONS.HELP,
    ];

    if (isWithLabel(editorAPI.dsRead, compRef)) {
      actions = actions.concat(ACTIONS.LAYOUT);
    }
    return actions;
  },
  mobileEnabledActions(editorAPI, compRef) {
    return isWithLabel(editorAPI.dsRead, compRef)
      ? [
          ACTIONS.SCALE_UP,
          ACTIONS.SCALE_DOWN,
          ACTIONS.HIDE,
          ACTIONS.HELP,
          ACTIONS.ANIMATION,
        ]
      : [ACTIONS.HIDE, ACTIONS.HELP, ACTIONS.ANIMATION];
  },
  presetActions: {
    help: {
      helpId: helpIds.GFPP.CHECKBOX,
    },
  },
};
