import type { EditorAPI } from '@/editorAPI';
import { serviceTopology, http } from '@/util';
import constants from '@/constants';

const STORES_CATEGORY_STATUS_URL = `${serviceTopology.editorRootUrl}_serverless/wixstores-categories-rollout-manager/siteRolloutStatus`;

interface StoresCategoryStatusForSite {
  result: { value: boolean };
}

const getStoresCategoryStatusForSiteHeaders = (editorAPI: EditorAPI): Headers =>
  new Headers({
    Authorization: editorAPI.dsRead.platform.getAppDataByApplicationId(
      constants.APPLICATIONS.META_SITE_APPLICATION_ID,
    )?.instance,
  });

const getStoresCategoryStatusForSiteUrl = (editorAPI: EditorAPI): string => {
  const metaSiteId = editorAPI.site.getMetaSiteId();
  return `${STORES_CATEGORY_STATUS_URL}?metaSiteId=${metaSiteId}`;
};

export const getStoresCategoryStatusForSite = async (
  editorAPI: EditorAPI,
): Promise<StoresCategoryStatusForSite> => {
  const url = getStoresCategoryStatusForSiteUrl(editorAPI);

  return http.fetchJson(url, {
    headers: getStoresCategoryStatusForSiteHeaders(editorAPI),
    method: 'GET',
  });
};
