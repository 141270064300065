export const enum SectionTypes {
  VirtualSection = 'virtual',
  Section = 'section',
  Header = 'header',
  Footer = 'footer',
}

export const AddSectionBiOrigin = 'add_section';

export const AUTOMATION_IDS = {
  BUTTON: 'add-section-button',
};
