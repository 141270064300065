// @ts-nocheck
const mobileOnlyHiddenItemsData = {
  TINY_MENU: {
    id: 'TINY_MENU',
    className: 'wysiwyg.viewer.components.mobile.TinyMenu',
    label: 'component_label_tinyMenu',
  },
  MENU_AS_CONTAINER_TOGGLE: {
    id: 'MENU_AS_CONTAINER_TOGGLE',
    className: 'wysiwyg.viewer.components.mobile.TinyMenu',
    label: 'component_label_MenuToggle',
  },
  MENU_AS_CONTAINER_EXPANDABLE_MENU: {
    id: 'MENU_AS_CONTAINER_EXPANDABLE_MENU',
    className: 'wysiwyg.viewer.components.mobile.TinyMenu',
    label: 'component_label_expandableMenu',
  },
};

export default {
  getMobileItemData(id) {
    return mobileOnlyHiddenItemsData[id];
  },
};
