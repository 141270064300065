'use strict'

const mapDragging = true

module.exports = {
    id: 'add_preset_popups_Contact_5',
    structure: {
        type: 'Page',
        id: 'on0t8',
        componentTypeForBI: 'wysiwyg.viewer.components.PopupContainer',
        components: [
            {
                type: 'Container',
                id: 'comp-im2561ps',
                components: [
                    {
                        type: 'Container',
                        id: 'comp-im2561qg',
                        components: [
                            {
                                type: 'Component',
                                id: 'comp-im25hxps',
                                skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
                                layout: {
                                    width: 429,
                                    height: 102,
                                    x: 6,
                                    y: 56,
                                    scale: 1,
                                    rotationInDegrees: 0,
                                    fixedPosition: false
                                },
                                componentType: 'wysiwyg.viewer.components.WRichText',
                                data: {
                                    type: 'StyledText',
                                    metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                                    text: '<h2 style="line-height: 1.1em; text-align: center; font-size: 40px;" class="font_2"><span style="font-size:40px;"><span style="letter-spacing:0.02em;"><span style="line-height:1.1em;"><span style="color:#18C1C0;"><span style="font-family:lulo-clean-w01-one-bold,sans-serif;">Let\'s get</span></span></span></span></span></h2><h2 style="line-height: 1.1em; text-align: center; font-size: 40px;" class="font_2"><span style="font-size:40px;"><span style="letter-spacing:0.02em;"><span style="line-height:1.1em;"><span style="color:#18C1C0;"><span style="font-family:lulo-clean-w01-one-bold,sans-serif;">together</span></span></span></span></span></h2>',
                                    stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
                                    linkList: [],
                                    id: 'dataItem-im25hxpx'
                                },
                                props: {
                                    type: 'WRichTextProperties',
                                    metaData: {schemaVersion: '1.0'},
                                    brightness: 1,
                                    packed: true,
                                    id: 'propItem-ipf5liy8'
                                },
                                style: 'txtNew'
                            }
                        ],
                        skin: 'wysiwyg.viewer.skins.area.RectangleArea',
                        layout: {
                            width: 432,
                            height: 540,
                            x: 0,
                            y: 0,
                            scale: 1,
                            rotationInDegrees: 0,
                            fixedPosition: false
                        },
                        componentType: 'mobile.core.components.Container',
                        style: {
                            type: 'TopLevelStyle',
                            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                            style: {
                                groups: {},
                                properties: {'alpha-bg': '1', bg: '#000000'},
                                propertiesSource: {bg: 'value'}
                            },
                            componentClassName: 'mobile.core.components.Container',
                            pageId: '',
                            compId: '',
                            styleType: 'custom',
                            skin: 'wysiwyg.viewer.skins.area.RectangleArea',
                            id: 'style-im2561qh'
                        }
                    },
                    {
                        type: 'Component',
                        id: 'comp-im2561q9',
                        skin: 'wysiwyg.viewer.skins.LinkBarNoBGSkin',
                        layout: {
                            width: 202,
                            height: 26,
                            x: 475,
                            y: 203,
                            scale: 1,
                            rotationInDegrees: 0,
                            fixedPosition: false
                        },
                        componentType: 'wysiwyg.viewer.components.LinkBar',
                        data: {
                            type: 'ImageList',
                            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                            items: [
                                {
                                    type: 'Image',
                                    metaData: {isPreset: false, schemaVersion: '2.0', isHidden: false},
                                    title: 'Black Facebook Icon',
                                    uri: '0fdef751204647a3bbd7eaa2827ed4f9.png',
                                    width: 200,
                                    height: 200,
                                    alt: '',
                                    link: {
                                        type: 'ExternalLink',
                                        metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                                        url: 'http://www.facebook.com/wix',
                                        target: '_blank'
                                    }
                                },
                                {
                                    type: 'Image',
                                    metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                                    title: 'Black Instagram Icon',
                                    uri: '01c3aff52f2a4dffa526d7a9843d46ea.png',
                                    width: 200,
                                    height: 200,
                                    alt: '',
                                    link: {
                                        type: 'ExternalLink',
                                        metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                                        url: 'https://www.instagram.com/wix/',
                                        target: '_blank'
                                    }
                                },
                                {
                                    type: 'Image',
                                    metaData: {isPreset: false, schemaVersion: '2.0', isHidden: false},
                                    title: 'Black Twitter Icon',
                                    uri: 'c7d035ba85f6486680c2facedecdcf4d.png',
                                    width: 200,
                                    height: 200,
                                    alt: '',
                                    link: {
                                        type: 'ExternalLink',
                                        metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                                        url: 'http://instagram.com/wix',
                                        target: '_blank'
                                    }
                                },
                                {
                                    type: 'Image',
                                    metaData: {isPreset: false, schemaVersion: '2.0', isHidden: false},
                                    title: 'Black YouTube Icon',
                                    uri: '78aa2057f0cb42fbbaffcbc36280a64a.png',
                                    width: 200,
                                    height: 200,
                                    alt: '',
                                    link: {
                                        type: 'ExternalLink',
                                        metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                                        url: 'http://youtube.com/user/Wix',
                                        target: '_blank'
                                    }
                                },
                                {
                                    type: 'Image',
                                    metaData: {isPreset: false, schemaVersion: '2.0', isHidden: false},
                                    title: 'Black SoundCloud Icon',
                                    uri: '03ea2af965014a3eb32df628a4c18c0e.png',
                                    width: 200,
                                    height: 200,
                                    alt: '',
                                    link: {
                                        type: 'ExternalLink',
                                        metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                                        url: 'https://soundcloud.com/',
                                        target: '_blank'
                                    }
                                }
                            ],
                            id: 'dataItem-im2561q91'
                        },
                        props: {
                            type: 'LinkBarProperties',
                            metaData: {schemaVersion: '1.0'},
                            gallery: 'social_icons',
                            iconSize: 26,
                            spacing: 18,
                            bgScale: 1,
                            orientation: 'HORIZ',
                            id: 'propItem-im2561qb1'
                        },
                        style: 'lb1'
                    },
                    {
                        type: 'Component',
                        id: 'comp-im2561qd',
                        layout: {
                            width: 364,
                            height: 102,
                            x: 477,
                            y: 101,
                            scale: 1,
                            rotationInDegrees: 0,
                            fixedPosition: false
                        },
                        componentType: 'wysiwyg.viewer.components.WRichText',
                        data: {
                            type: 'StyledText',
                            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                            text: '<p class="font_8" style="font-size:15px; line-height:0.8em">&nbsp;</p>\n\n<p class="font_8" style="font-size:15px; line-height:0.8em"><span style="color:#000000;"><span style="font-size:15px"><span style="font-family:spinnaker,sans-serif">500 Terry Francois Street </span></span></span></p>\n\n<p class="font_8" style="font-size:15px; line-height:0.8em">&nbsp;</p>\n\n<p class="font_8" style="font-size:15px; line-height:0.8em"><span style="color:#000000;"><span style="font-size:15px"><span style="font-family:spinnaker,sans-serif">San Francisco, CA 94158 </span></span></span></p>\n\n<p class="font_8" style="font-size:15px; line-height:0.8em">&nbsp;</p>\n\n<p class="font_8" style="font-size:15px; line-height:0.8em"><span style="color:#000000;"><span style="font-size:15px"><span style="font-family:spinnaker,sans-serif">info@mysite.com &nbsp; \\\\ &nbsp;Tel: 123-456-7890</span></span></span></p>\n\n<p class="font_8" style="font-size:15px; line-height:0.8em">&nbsp;</p>\n\n<p class="font_8" style="line-height:1em">&nbsp;</p>\n',
                            stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
                            linkList: [],
                            id: 'dataItem-im2561qd1'
                        },
                        props: {
                            type: 'WRichTextProperties',
                            metaData: {schemaVersion: '1.0'},
                            brightness: 1,
                            packed: true,
                            id: 'propItem-im2561qe'
                        },
                        style: 'txtNew'
                    },
                    {
                        type: 'Component',
                        id: 'comp-im25smt2',
                        layout: {
                            width: 217,
                            height: 30,
                            x: 475,
                            y: 56,
                            scale: 1,
                            rotationInDegrees: 0,
                            fixedPosition: false
                        },
                        componentType: 'wysiwyg.viewer.components.WRichText',
                        data: {
                            type: 'StyledText',
                            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                            text: '<p style="font-size: 20px;" class="font_8"><span style="color:#18C1C0;"><span style="font-size:20px;"><span style="font-family:lulo-clean-w01-one-bold,sans-serif;">address</span></span></span></p>',
                            stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
                            linkList: [],
                            id: 'dataItem-im25smt8'
                        },
                        props: {
                            type: 'WRichTextProperties',
                            metaData: {schemaVersion: '1.0'},
                            brightness: 1,
                            packed: true,
                            id: 'propItem-im25smta'
                        },
                        style: 'txtNew'
                    },
                    {
                        type: 'Component',
                        id: 'comp-im263gbp',
                        skin: 'wysiwyg.viewer.skins.map.GoogleMapDefault',
                        layout: {
                            width: 343,
                            height: 223,
                            x: 478,
                            y: 268,
                            scale: 1,
                            rotationInDegrees: 0,
                            fixedPosition: false
                        },
                        componentType: 'wysiwyg.viewer.components.GoogleMap',
                        data: {
                            type: 'GeoMap',
                            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                            address: '500 Terry Francois Street, 6th Floor. San Francisco, CA 94158',
                            latitude: 37.77065,
                            longitude: -122.387301,
                            addressInfo: 'Wix Offices',
                            id: 'dataItem-im263gc3'
                        },
                        props: {
                            type: 'GoogleMapProperties',
                            metaData: {schemaVersion: '1.0'},
                            showZoom: false,
                            showPosition: false,
                            showStreetView: false,
                            showMapType: false,
                            mapDragging,
                            mapType: 'ROADMAP',
                            language: 'en',
                            id: 'propItem-im263gc4'
                        },
                        style: {
                            type: 'TopLevelStyle',
                            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                            style: {
                                groups: {},
                                properties: {
                                    'alpha-brd': '0',
                                    'boxShadowToggleOn-shd': 'false',
                                    brd: '#DEC328',
                                    brw: '0',
                                    rd: '0px',
                                    shd: '2px 2px 4px 0px rgba(0,0,0,0.3)'
                                },
                                propertiesSource: {
                                    'alpha-brd': 'value',
                                    brd: 'value',
                                    brw: 'value',
                                    rd: 'value',
                                    shd: 'value'
                                }
                            },
                            componentClassName: 'wysiwyg.viewer.components.GoogleMap',
                            pageId: '',
                            compId: '',
                            styleType: 'custom',
                            skin: 'wysiwyg.viewer.skins.map.GoogleMapDefault',
                            id: 'style-im269wmq'
                        }
                    },
                    {
                        'type': 'Component',
                        'skin': 'svgshape.v2.Svg_001419cc721a440abc4ab12ed150b744',
                        'layout': {
                            'width': 36,
                            'height': 36,
                            'x': 828,
                            'y': -49,
                            'scale': 1,
                            'rotationInDegrees': 0,
                            'fixedPosition': false
                        },
                        'componentType': 'wysiwyg.viewer.components.PopupCloseIconButton',
                        'parent': 'comp-im2561ps',
                        'metaData': {
                            'pageId': 'on0t8'
                        },
                        'data': {
                            'type': 'SvgShape',
                            'metaData': {
                                'isPreset': false,
                                'schemaVersion': '1.0',
                                'isHidden': false,
                                'pageId': 'on0t8'
                            }
                        },
                        'props': {
                            'type': 'SvgShapeProperties',
                            'metaData': {
                                'schemaVersion': '1.0',
                                'pageId': 'on0t8'
                            },
                            'maintainAspectRatio': true
                        },
                        'style': {
                            'type': 'ComponentStyle',
                            'metaData': {
                                'isPreset': false,
                                'schemaVersion': '1.0',
                                'isHidden': false,
                                'pageId': 'on0t8'
                            },
                            'style': {
                                'properties': {
                                    'alpha-fillcolor': '1',
                                    'alpha-stroke': '1',
                                    'fillcolor': '#FFFFFF',
                                    'stroke': '#484343',
                                    'strokewidth': '2'
                                },
                                'propertiesSource': {
                                    'alpha-fillcolor': 'value',
                                    'alpha-stroke': 'value',
                                    'fillcolor': 'value',
                                    'stroke': 'value',
                                    'strokewidth': 'value'
                                },
                                'groups': {}
                            },
                            'componentClassName': 'wysiwyg.viewer.components.PopupCloseIconButton',
                            'pageId': '',
                            'compId': '',
                            'styleType': 'custom',
                            'skin': 'svgshape.v2.Svg_001419cc721a440abc4ab12ed150b744'
                        },
                        'connections': {
                            'type': 'ConnectionList',
                            'items': [
                                {
                                    'type': 'WixCodeConnectionItem',
                                    'role': 'button2'
                                }
                            ],
                            'metaData': {
                                'pageId': 'on0t8'
                            }
                        },
                        'mobileStructure': {
                            'layout': {
                                'width': 26,
                                'height': 26,
                                'x': 275,
                                'y': -41,
                                'scale': 1,
                                'rotationInDegrees': 0,
                                'fixedPosition': false
                            },
                            'metaData': {
                                'originalCompId': 'comp-isrempjz',
                                'author': 'duplicate'
                            }
                        },
                        'activeModes': {}
                    }
                ],
                skin: 'wysiwyg.viewer.skins.stripContainer.DefaultStripContainer',
                layout: {
                    width: 864,
                    height: 540,
                    x: 58,
                    y: 119,
                    scale: 1,
                    rotationInDegrees: 0,
                    fixedPosition: false
                },
                componentType: 'wysiwyg.viewer.components.PopupContainer',
                props: {
                    type: 'PopupContainerProperties',
                    metaData: {schemaVersion: '1.0'},
                    horizontalAlignment: 'center',
                    verticalAlignment: 'center',
                    alignmentType: 'nineGrid',
                    horizontalOffset: 0,
                    verticalOffset: 0,
                    id: 'propItem-im2561pv'
                },
                design: {
                    type: 'MediaContainerDesignData',
                    metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                    background: {
                        type: 'BackgroundMedia',
                        metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                        color: '#FFFFFF',
                        colorOpacity: 1,
                        alignType: 'center',
                        fittingType: 'fill',
                        scrollType: 'none',
                        colorOverlay: '',
                        colorOverlayOpacity: 0
                    },
                    id: 'dataItem-im2561pv1'
                },
                style: {
                    type: 'TopLevelStyle',
                    metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                    style: {
                        groups: {},
                        properties: {'alpha-bg': '1', bg: 'rgba(255,255,255,1)'},
                        propertiesSource: {'alpha-bg': 'value', bg: 'value'}
                    },
                    componentClassName: 'wysiwyg.viewer.components.PopupContainer',
                    pageId: '',
                    compId: '',
                    styleType: 'custom',
                    skin: 'wysiwyg.viewer.skins.stripContainer.DefaultStripContainer',
                    id: 'style-im2561pw'
                },
                behaviors: {
                    type: 'ObsoleteBehaviorsList',
                    items: '[{"name":"FadeIn","delay":0.1,"duration":0.75,"action":"screenIn","targetId":"","params":{}},{"name":"FadeOut","delay":0.1,"duration":0.75,"action":"exit","targetId":"","params":{}}]',
                    id: 'behavior-iomlweed'
                }
            }
        ],

        skin: 'skins.core.InlineSkin',
        layout: {
            width: 980,
            height: 659,
            x: 0,
            y: 0,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false
        },
        componentType: 'mobile.core.components.Page',
        data: {
            type: 'Page',
            metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
            title: 'add_preset_popups_Contact_5',
            hideTitle: true,
            icon: '',
            descriptionSEO: '',
            metaKeywordsSEO: '',
            pageTitleSEO: '',
            pageUriSEO: 'copy-of-contact4',
            hidePage: true,
            underConstruction: false,
            tpaApplicationId: 0,
            pageSecurity: {requireLogin: false, passwordDigest: '', dialogLanguage: ''},
            isPopup: true,
            indexable: false,
            isLandingPage: false,
            pageBackgrounds: {
                desktop: {
                    ref: {
                        type: 'BackgroundMedia',
                        metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                        color: '#363636',
                        colorOpacity: 0.9,
                        alignType: 'center',
                        fittingType: 'fill',
                        scrollType: 'none',
                        colorOverlay: '',
                        colorOverlayOpacity: 0
                    }
                },
                mobile: {
                    custom: true,
                    ref: {
                        type: 'BackgroundMedia',
                        metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false},
                        color: '#363636',
                        colorOpacity: 0.9,
                        alignType: 'center',
                        fittingType: 'fill',
                        scrollType: 'scroll',
                        colorOverlay: '',
                        colorOverlayOpacity: 0
                    },
                    isPreset: true
                }
            },
            ignoreBottomBottomAnchors: true
        },
        props: {
            type: 'PageProperties',
            metaData: {schemaVersion: '1.0'},
            desktop: {popup: {closeOnOverlayClick: true}},
            mobile: {popup: {closeOnOverlayClick: true}},
            id: 'on0t8'
        },
        style: 'p1',
        tpaComps: []
    },
    preset: {
        rect: {width: 305, height: 202, x: 10, y: 908},
        label: 'add_preset_popups_Contact_5',
        tags: null
    }
}
