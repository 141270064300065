import React from 'react';
import { useSelectedApp } from '../selectedAppContext/selectedAppContext';
import { InstallAppButton } from '../installAppButton/installAppButton';
import { UpdateAppButton } from '../versionedAppActions/updateAppButton';

export const VersionedAppActionsButton = () => {
  const { app, selectedVersion } = useSelectedApp().selectedApp;
  const isAppInstalled = app?.appFields?.installedVersion;
  return isAppInstalled ? (
    <UpdateAppButton version={selectedVersion} />
  ) : (
    <InstallAppButton versionToInstall={selectedVersion} />
  );
};
