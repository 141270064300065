export enum ActionIds {
  MoveUp = 'MoveUp',
  MoveDown = 'MoveDown',
  Rename = 'Rename',
  QuickEdit = 'QuickEdit',
  SwitchLayout = 'SwitchLayout',
  SwitchLayoutComparison = 'SwitchLayoutComparison',
  Duplicate = 'Duplicate',
  Remove = 'Remove',
  Hide = 'Hide',
  ShowMore = 'ShowMore',
  ScanPreset = 'ScanPreset',
  Accessibility = 'Accessibility',
  Settings = 'Settings',
  Animation = 'Animation',
  ScanSwitchLayoutPreset = 'ScanSwitchLayoutPreset',
}

export const ACTIONS_AREA_VERTICAL_PADDING = 3;
export const ACTIONS_AREA_RIGHT_MARGIN = 18;
export const FIXED_STAGE_ACTIONS_OFFSET_LEFT = 18;

export const ACTION_BAR_ORIGIN = 'sections_action_bar';
