import _ from 'lodash';
import getContactSections from './sections/contactSection';
import getJobApplicationSection from './sections/jobApplicationSection';
import getFeedbackSection from './sections/feedbackSection';
import getRegistrationsSection from './sections/registrationsSection';
import getRequestSection from './sections/requestSection';
import getSubscribersSection from './sections/subscribersSection';
import getPaymentSection from './sections/paymentSection';
import getDonationsSection from './sections/donationsSection';
import getWaiverSection from './sections/waiverSection';

export default (editorAPI: AnyFixMe) => {
  const contactSection = getContactSections(editorAPI);
  const jobApplicationSection = getJobApplicationSection(editorAPI);
  const feedbackSection = getFeedbackSection(editorAPI);
  const registrationsSection = getRegistrationsSection(editorAPI);
  const requestSection = getRequestSection(editorAPI);
  const subscribersSection = getSubscribersSection(editorAPI);
  const paymentSection = getPaymentSection(editorAPI);
  const donationsSection = getDonationsSection(editorAPI);
  const waiverSection = getWaiverSection(editorAPI);

  return _.merge(
    {},
    contactSection,
    jobApplicationSection,
    feedbackSection,
    registrationsSection,
    requestSection,
    subscribersSection,
    paymentSection,
    donationsSection,
    waiverSection,
  );
};
