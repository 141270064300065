import type { Account } from '@wix/ambassador-identity-account-v2-account/types';
import {
  SET_ACCOUNTS,
  SET_IS_STUDIO_ACCOUNT,
  type AccountActions,
} from './accountActions';

export interface AccountStateFull {
  account: AccountState;
}

export interface AccountState {
  accounts: Account[];
  isStudioAccount: boolean;
}

const accountInitialState: AccountState = {
  accounts: [],
  isStudioAccount: false,
};

export const accountReducer = (
  state = accountInitialState,
  action: AccountActions,
): AccountState => {
  switch (action.type) {
    case SET_ACCOUNTS: {
      return { ...state, accounts: action.payload.accounts };
    }
    case SET_IS_STUDIO_ACCOUNT: {
      return { ...state, isStudioAccount: action.isStudioAccount };
    }
    default:
      return state;
  }
};
