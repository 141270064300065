import React, { useEffect, useRef, useState } from 'react';
import { cx, fakeBrowserUtils, hoc } from '@/util';
import { translate } from '@/i18n';
import {
  mapStateToProps,
  mapDispatchToProps,
  type UrlBarStateProps,
  type UrlBarDispatchProps,
} from './urlBarMapper';
import styles from './urlBar.scss';
import { Tooltip } from '@wix/wix-base-ui';
import TooltipContent from '../tooltipContent';
import { symbol as Symbol } from '@wix/santa-editor-symbols';

export interface UrlBarProps extends UrlBarStateProps, UrlBarDispatchProps {}

function getFirstSaveTooltipContent() {
  return {
    title: 'Topbar_Connect_Domain_Unsaved_Hover_Tooltip_Title',
    text: 'Topbar_Connect_Domain_Unsaved_Hover_Tooltip_Text',
  };
}

function getPublicUrlTooltipContent(
  isSitePublished: boolean,
  isFirstSave: boolean,
) {
  const savedSiteData = isSitePublished
    ? {
        title: 'Topbar_Connect_Domain_Published_Hover_Tooltip_Title',
        text: 'Topbar_Connect_Domain_Published_Hover_Tooltip_Text',
      }
    : {
        title: 'Topbar_Connect_Domain_Unpublished_Hover_Tooltip_Title',
        text: 'Topbar_Connect_Domain_Unpublished_Hover_Tooltip_Text',
      };
  const tooltipData = isFirstSave
    ? getFirstSaveTooltipContent()
    : savedSiteData;
  return <TooltipContent tooltipData={tooltipData} />;
}

function getConnectDomainSuggestionText(
  isFirstSave: boolean,
  shouldRenderDomainSuggestion: boolean,
  isSitePremium: boolean,
) {
  if (shouldRenderDomainSuggestion) {
    return (
      <>
        <span className={styles.connectDomainTeaser}>
          &nbsp;{translate('Topbar_Connect_Domain_Promotion_Text')}
        </span>
        <Tooltip
          marginBottom={20}
          content={
            <TooltipContent
              tooltipData={
                isFirstSave
                  ? getFirstSaveTooltipContent()
                  : {
                      title:
                        'Topbar_Connect_Domain_Hover_Tooltip_Promotion_Title',
                      text: 'Topbar_Connect_Domain_Hover_Tooltip_Text',
                    }
              }
            />
          }
        >
          <span
            data-aid="url-bar-handler"
            className={cx(styles.connectDomainHandler, {
              [styles.premiumHandler]: isSitePremium,
              interactive: !isFirstSave,
            })}
          >
            {translate('Topbar_Connect_Domain_Link')}
          </span>
        </Tooltip>
      </>
    );
  }
  return (
    <Tooltip
      content={
        <TooltipContent
          tooltipData={
            isFirstSave
              ? getFirstSaveTooltipContent()
              : {
                  title: 'Topbar_Connect_Domain_Hover_Tooltip_Title',
                  text: 'Topbar_Connect_Domain_Hover_Tooltip_Text',
                }
          }
        />
      }
      marginBottom={20}
      maxWidth={220}
    >
      <span
        data-aid="url-bar-handler"
        className={cx(styles.connectDomainHandler, {
          [styles.premiumHandler]: isSitePremium,
          interactive: !isFirstSave,
        })}
      >
        {translate('Topbar_Connect_Domain_Link')}
      </span>
    </Tooltip>
  );
}

const UrlBar: React.FC<UrlBarProps> = ({
  publicUrl,
  isFirstSave,
  isSitePremium,
  isSitePublished,
  domainSuggestion,
  publicUrlHandler,
  isDomainConnected,
  nonInteractivePublicUrl,
  connectDomainUrlHandler,
  connectDomainCTAHandler,
  onCloseDomainSuggestion,
  shouldRenderOnlyPublicUrl,
  sendBiDomainSuggestionShow,
  shouldRenderDomainSuggestion,
}: UrlBarProps) => {
  const connectDomainRef = useRef(null);
  const [connectDomainWidth, setConnectDomainWidth] =
    useState<React.CSSProperties['width']>(0);
  useEffect(() => {
    setConnectDomainWidth(getComputedStyle(connectDomainRef.current)?.width);
    if (shouldRenderDomainSuggestion) {
      sendBiDomainSuggestionShow();
    }
  }, [
    isSitePublished,
    domainSuggestion,
    shouldRenderOnlyPublicUrl,
    sendBiDomainSuggestionShow,
    shouldRenderDomainSuggestion,
  ]);

  const wrapWithTooltipIfNeeded = (Comp: React.FunctionComponent) => {
    // TODO: when we want whole flows with tooltips, to revert this change because first we want to compare to ADI, to show tooltips only if domain connected.
    if (isDomainConnected || isFirstSave) {
      return (
        <Tooltip
          maxWidth={220}
          marginBottom={12}
          content={getPublicUrlTooltipContent(isSitePublished, isFirstSave)}
        >
          <Comp />
        </Tooltip>
      );
    }
    return <Comp />;
  };

  const getUrlText = () => {
    const interactiveStandalonePublicUrl =
      shouldRenderOnlyPublicUrl && isSitePublished && !nonInteractivePublicUrl;
    return (
      <div
        style={
          shouldRenderOnlyPublicUrl && connectDomainWidth
            ? {}
            : {
                width: `calc(100% - ${connectDomainWidth})`,
              }
        }
        onClick={nonInteractivePublicUrl ? null : publicUrlHandler}
        data-aid="url-bar-url-text"
        className={cx(styles.urlBarSiteUrl, {
          [styles.standaloneUrl]: shouldRenderOnlyPublicUrl,
          [styles.nonInteractivePublicUrl]: nonInteractivePublicUrl,
        })}
      >
        {interactiveStandalonePublicUrl ? (
          <Symbol name="languagesEarth" />
        ) : null}
        {wrapWithTooltipIfNeeded(() => (
          <span>{publicUrl}</span>
        ))}
      </div>
    );
  };

  const getPromotionText = (shouldRenderClosePromotion: boolean) => {
    return !shouldRenderOnlyPublicUrl ? (
      <>
        <div
          onClick={
            isFirstSave
              ? null
              : connectDomainCTAHandler.bind(null, domainSuggestion)
          }
          ref={connectDomainRef}
          className={styles.connectDomainExposer}
        >
          {getConnectDomainSuggestionText(
            isFirstSave,
            shouldRenderDomainSuggestion,
            isSitePremium,
          )}
          {shouldRenderClosePromotion ? (
            <Symbol
              name="closeSmall"
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation();
                onCloseDomainSuggestion();
              }}
            />
          ) : null}
        </div>
        {connectDomainWidth ? (
          <div
            className={styles.connectDomainExposerPlaceholder}
            style={{ width: `calc(${connectDomainWidth} + 1px)` }}
          />
        ) : null}
      </>
    ) : null;
  };

  const getStandardUrlWithCTA = () => {
    return (
      <div
        className={cx(styles.urlBarTextWrapping, {
          [styles.withCta]: !shouldRenderOnlyPublicUrl,
        })}
      >
        {getUrlText()}
        {getPromotionText(false)}
      </div>
    );
  };

  const getDomainSuggestionWithCTA = () => {
    return (
      <div className={styles.urlBarSuggestion}>
        <div
          style={{ width: `calc(100% - ${connectDomainWidth})` }}
          className={styles.domainSuggestion}
          onClick={() => connectDomainUrlHandler(domainSuggestion)}
          data-aid={'url-bar-suggestion'}
        >
          <Symbol
            className={cx({ 'premium-site': isSitePremium })}
            name="languagesEarth"
          />
          <span
            data-aid="url-bar-url-text"
            className={styles.domainSuggestionText}
          >
            {domainSuggestion}
          </span>
        </div>
        {getPromotionText(true)}
      </div>
    );
  };

  return (
    <div data-aid="url-bar" className={styles.urlBar}>
      {shouldRenderDomainSuggestion
        ? getDomainSuggestionWithCTA()
        : getStandardUrlWithCTA()}
    </div>
  );
};

const shouldRenderUrlBar = (): boolean => {
  return fakeBrowserUtils.isFakeBrowserEnabled();
};

const ConnectedComponent = hoc.connect(
  hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(UrlBar);

export default hoc.withConditionalRender(
  hoc.STORES.STATE_ONLY,
  shouldRenderUrlBar,
)(ConnectedComponent);
