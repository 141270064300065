import type { EntryPoint } from '@/apilib';
import { EditorAPIKey, SelectionApiKey, ComponentsApiKey } from '@/apis';
import { TextEditorApiKey } from './publicApiKey';
import { createTextEditorApi } from './textEditorApi';

export const TextEditorApiEntryPoint: EntryPoint = {
  name: 'TextEditorApi',
  declareAPIs: () => [TextEditorApiKey],
  getDependencyAPIs: () => [EditorAPIKey, SelectionApiKey, ComponentsApiKey],
  attach(shell) {
    shell.contributeAPI(TextEditorApiKey, () => createTextEditorApi(shell));
  },
};
