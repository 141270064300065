import _ from 'lodash';
import { AppSetup } from './AppSetup';
import type { SetupStepDealerDataWithSkipFlag, SetupCallbacks } from './types';

export class SiteSetup {
  private _appSetups: Record<string, AppSetup>;
  private _emptyAppSetup: AppSetup;

  constructor(
    stepsData: SetupStepDealerDataWithSkipFlag[],
    callbacks: SetupCallbacks,
  ) {
    this._appSetups = _.mapValues(
      _.groupBy(stepsData, 'appDefId'),
      (steps) => new AppSetup(steps, callbacks),
    );
    this._emptyAppSetup = new AppSetup([], callbacks);
  }

  getAppSetup(appDefId: string) {
    return this._appSetups[appDefId] || this._emptyAppSetup;
  }
}
