import type { EntryPoint } from '@/apilib';
import { EditorAPIKey, WixCodeApiKey } from '@/apis';
import { createWixCodeApi } from './wixCode';
import { init } from './init';

export const WixCodeEntrypoint: EntryPoint = {
  name: 'WixCodeApi',
  declareAPIs: () => [WixCodeApiKey],
  getDependencyAPIs: () => [EditorAPIKey],
  attach(shell) {
    shell.contributeAPI(WixCodeApiKey, () => createWixCodeApi(shell));
  },
  extend(shell) {
    void init(shell);
  },
};
