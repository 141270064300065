// @ts-nocheck
import addPanelDataConsts from '@/addPanelDataConsts';
import * as paginationCustomizationsProvider from '../paginationCustomizationsProvider';
import coreUtilsLib from 'coreUtilsLib';

const { blogAppPartNames } = coreUtilsLib;

export default {
  type: addPanelDataConsts.SECTIONS_TYPES.PRESET,
  hide: false,
  title: 'add_section_label_customfeed',
  showSectionHeader: true,
  additionalBehaviours: {
    labelMode: addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
    infoIcon: addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
    hoverImageAction: addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
    iconEnabledForComps: {},
  },
  props: {
    image: 'blog/sections/customFeedSection.png',
    imageHover: null,
    items: [
      {
        id: 'Blog_Custom_Feed_1',
        structure: {
          type: 'Component',
          skin: 'wysiwyg.viewer.skins.AppPartSkin',
          layout: {
            width: 670,
            height: 1231,
            x: 30,
            y: 31,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          componentType: 'wixapps.integration.components.AppPart',
          data: {
            type: 'AppPart',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
            },
            appInnerID: '12',
            appPartName: blogAppPartNames.CUSTOM_FEED,
            viewName: 'MediaLeft',
            appLogicCustomizations: [
              {
                type: 'AppPartCustomization',
                forType: 'PhotoPost',
                view: 'MediaInnerCustom',
                key: 'comp.style',
                value: 'wp1',
                fieldId: 'photo',
                format: '*',
              },
              {
                type: 'AppPartCustomization',
                forType: 'Post',
                view: 'MediaLeft',
                key: 'comp.style',
                value: 'Body M',
                fieldId: 'text',
                format: '*',
              },
              {
                type: 'AppPartCustomization',
                forType: 'Post',
                view: 'MediaLeft',
                key: 'comp.style',
                value: 'Body M',
                fieldId: 'date',
                format: '*',
              },
              {
                type: 'AppPartCustomization',
                forType: 'Post',
                view: 'MediaLeft',
                key: 'readMorePosition',
                value: 'right',
                fieldId: 'vars',
                format: '',
              },
              {
                type: 'AppPartCustomization',
                forType: 'Post',
                view: 'MediaLeft',
                key: 'comp.max-chars',
                value: '430',
                fieldId: 'text',
                format: '',
              },
            ].concat(
              paginationCustomizationsProvider.getCustomizationsForCustomFeed(),
            ),
            appLogicParams: {},
          },
          props: {
            type: 'AppPartProperties',
            metaData: { schemaVersion: '1.0' },
            direction: 'ltr',
          },
          style: {
            type: 'TopLevelStyle',
            id: 'blog_31c0cede-09db-4ec7-b760-d375d62101e6_1',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
            },
            style: {
              groups: {},
              properties: { rd: '7px' },
              propertiesSource: { rd: 'value' },
            },
            componentClassName: '',
            pageId: '',
            compId: '',
            styleType: 'system',
            skin: 'wysiwyg.viewer.skins.AppPartSkin',
          },
          id: 'i74ywvnz_1',
        },
        preset: { rect: { width: 162, height: 251, x: 0, y: 0 }, label: '' },
      },
      {
        id: 'Blog_Custom_Feed_2',
        structure: {
          type: 'Component',
          skin: 'wysiwyg.viewer.skins.AppPartSkin',
          layout: {
            width: 670,
            height: 936,
            x: 760,
            y: 31,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          componentType: 'wixapps.integration.components.AppPart',
          data: {
            type: 'AppPart',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
            },
            appInnerID: '12',
            appPartName: blogAppPartNames.CUSTOM_FEED,
            viewName: 'Masonry',
            appLogicCustomizations: [
              {
                type: 'AppPartCustomization',
                forType: 'Post',
                view: 'MediaRight',
                key: 'comp.style',
                value: 'Body M',
                fieldId: 'date',
                format: '*',
              },
              {
                type: 'AppPartCustomization',
                forType: 'Post',
                view: 'MediaRight',
                key: 'comp.style',
                value: 'Body M',
                fieldId: 'text',
                format: '*',
              },
              {
                type: 'AppPartCustomization',
                forType: 'Post',
                view: 'MediaTop',
                key: 'comp.max-chars',
                value: '800',
                fieldId: 'text',
                format: '',
              },
              {
                type: 'AppPartCustomization',
                forType: 'Post',
                view: 'MediaZigzag',
                key: 'comp.max-chars',
                value: '430',
                fieldId: 'text',
                format: '',
              },
            ].concat(
              paginationCustomizationsProvider.getCustomizationsForCustomFeed(),
            ),
            appLogicParams: {},
          },
          props: {
            type: 'AppPartProperties',
            metaData: { schemaVersion: '1.0' },
            direction: 'ltr',
          },
          style: {
            type: 'TopLevelStyle',
            id: 'blog_31c0cede-09db-4ec7-b760-d375d62101e6_1',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
            },
            style: {
              groups: {},
              properties: { rd: '7px' },
              propertiesSource: { rd: 'value' },
            },
            componentClassName: '',
            pageId: '',
            compId: '',
            styleType: 'system',
            skin: 'wysiwyg.viewer.skins.AppPartSkin',
          },
          id: 'i74ywvnz_2',
        },
        preset: { rect: { width: 162, height: 251, x: 162, y: 0 }, label: '' },
      },
    ],
    compTypes: ['wixapps.integration.components.AppPart'],
  },
  help: { hide: false, text: 'add_section_info_text_customfeed' },
};
