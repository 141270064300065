// @ts-nocheck
import _ from 'lodash';
import * as textMigrationDomHelper from './textMigrationDomHelper';
import * as fontUtils from './fontUtils';

function getElementStyleClass(element) {
  return (
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/find
    _.find(element.classList, function (cls) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/includes
      return _.includes(cls, 'font_');
    }) || null
  );
}

function getStyledSpanWithItsParents(element, unstyledWrapper) {
  if (getElementStyleClass(element)) {
    return element;
  }
  const wrappedStyledSpan = element.cloneNode();

  const childNodes = textMigrationDomHelper.collectionToArray(
    element.childNodes,
  );
  for (let i = 0; i < childNodes.length; i++) {
    const child = childNodes[i];
    if (!getElementStyleClass(child) && !isContainStyleSpans(child)) {
      unstyledWrapper.appendChild(child);
    } else {
      //this is element contains different style
      const childUnstyledWrapper = child.cloneNode();
      const childWrappedStyledSpan = getStyledSpanWithItsParents(
        child,
        childUnstyledWrapper,
      );
      wrappedStyledSpan.appendChild(childWrappedStyledSpan);
      if (childUnstyledWrapper.firstChild) {
        unstyledWrapper.appendChild(childUnstyledWrapper);
      }
      return wrappedStyledSpan;
    }
  }
  return wrappedStyledSpan;
}

/**
 * for <s1>bla<a><b/><s2><c/></a></s1> - > <s1>bla<a><b/></a></s1> <a><s2/></a> <s1><a><c/></a></s1>
 * @param styledSpan
 * @private
 */
function flattenStylesInSpan(styledSpan) {
  if (!isContainStyleSpans(styledSpan)) {
    return;
  }
  const styledSpanBefore = styledSpan.cloneNode();

  const childNodes = textMigrationDomHelper.collectionToArray(
    styledSpan.childNodes,
  );
  for (let i = 0; i < childNodes.length; i++) {
    const child = childNodes[i];
    if (!getElementStyleClass(child) && !isContainStyleSpans(child)) {
      styledSpanBefore.appendChild(child);
    } else {
      //this is element contains different style
      const unstyledWrapper = child.cloneNode();

      const wrappedStyledSpan = getStyledSpanWithItsParents(
        child,
        unstyledWrapper,
      );
      if (unstyledWrapper.firstChild) {
        styledSpanBefore.appendChild(unstyledWrapper);
      }
      styledSpan.parentNode.insertBefore(styledSpanBefore, styledSpan);
      styledSpan.parentNode.insertBefore(wrappedStyledSpan, styledSpan);
      flattenStyledSpans(wrappedStyledSpan); //s2
      flattenStylesInSpan(styledSpan); //s1 that is after s2
      return;
    }
  }
}

function isContainStyleSpans(element) {
  return (
    textMigrationDomHelper.isDomElement(element) &&
    element.querySelectorAll('span[class*="font_"]').length
  );
}

/**
 * we get here only when all the text in element in styled
 * @param {Element} element
 * @param {Array<Element>} styledSpans - will add the styled spans in element to this param
 * @return {Array<Element>} all the styled spans in element
 * @private
 */
function getStyledSpansRecursive(element, styledSpans) {
  //if the element is not text
  if (textMigrationDomHelper.isDomElement(element)) {
    const elementStyleClass = getElementStyleClass(element);
    if (elementStyleClass && element.tagName.toLowerCase() === 'span') {
      styledSpans.push(element);
    } else {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
      _.forEach(element.children, function (child) {
        styledSpans.concat(getStyledSpansRecursive(child, styledSpans));
      });
    }
  }
  return styledSpans;
}

function flattenStyledSpans(element) {
  if (getElementStyleClass(element)) {
    flattenStylesInSpan(element);
  }
  if (!isContainStyleSpans(element)) {
    return;
  }
  const styledSpansFirstLevel = getStyledSpansRecursive(element, []);
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
  _.forEach(styledSpansFirstLevel, flattenStylesInSpan);
}

function isBlockContainUnstyledText(element) {
  if (textMigrationDomHelper.isTextNode(element)) {
    return true;
  }
  if (getElementStyleClass(element)) {
    return false;
  }
  const children = textMigrationDomHelper.collectionToArray(element.childNodes);
  for (let i = 0; i < children.length; i++) {
    if (isBlockContainUnstyledText(children[i])) {
      return true;
    }
  }
  return false;
}

function getEntryWithMaxValue(map) {
  let max = 0;
  let selectedEntry = null;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
  _.forEach(map, function (value, key) {
    if (value > max) {
      max = value;
      selectedEntry = key;
    }
  });
  return selectedEntry;
}

function getMostCommonStyle(styledSpans) {
  const stylesLengthMap = {};
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
  _.forEach(styledSpans, function (element) {
    const elementStyle = getElementStyleClass(element);
    stylesLengthMap[elementStyle] = stylesLengthMap[elementStyle] || 0;
    stylesLengthMap[elementStyle] += element.textContent.length;
  });

  return getEntryWithMaxValue(stylesLengthMap);
}

function getColorDelta(
  blockStyleClass,
  elementStyleClass,
  themeFonts,
  themeColors,
) {
  const blockStyle =
    blockStyleClass &&
    fontUtils.parseStyleFont(blockStyleClass, themeFonts, themeColors);
  const elementStyle = fontUtils.parseStyleFont(
    elementStyleClass,
    themeFonts,
    themeColors,
  );
  if (!elementStyle) {
    return null;
  }

  if (!blockStyle || blockStyle.color !== elementStyle.color) {
    if (elementStyle.color.charAt(0) === '#') {
      //hex
      return {
        isRef: false,
        value: elementStyle.color,
      };
    }

    //color from palette
    return {
      isRef: true,
      value: elementStyle.color.replace('{', '').replace('}', ''),
    };
  }
  return null;
}

/**
 *
 * @param {String|Null} blockStyleClass - the block style or null in case of default style
 * @param {String} elementStyleClass
 * @return {Object}
 * @private
 */
const styleKeys = {
  'font-family': 'family',
  'font-size': 'size',
  'font-style': 'style',
  'font-variant': 'variant',
  'font-weight': 'weight',
  'line-height': 'lineHeight',
};
function getStyleDelta(
  blockStyleClass,
  elementStyleClass,
  themeFonts,
  themeColors,
) {
  const blockStyle =
    blockStyleClass &&
    fontUtils.parseStyleFont(blockStyleClass, themeFonts, themeColors);
  const elementStyle = fontUtils.parseStyleFont(
    elementStyleClass,
    themeFonts,
    themeColors,
  );
  const elementStyleValue = {};
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
  _.forEach(styleKeys, function (value, key) {
    if (!blockStyle || blockStyle[value] !== elementStyle[value]) {
      elementStyleValue[key] = elementStyle[value];
    }
  });
  return elementStyleValue;
}

export {
  flattenStyledSpans,
  isBlockContainUnstyledText,
  getStyledSpansRecursive,
  getMostCommonStyle,
  getElementStyleClass,
  getStyleDelta,
  getColorDelta,
};
